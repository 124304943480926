import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Row,
  Col,
  Card,
  CardBody,
  CardText,
  Nav,
  NavItem,
  NavLink,
  TabPane,
  TabContent
} from 'reactstrap';
import classNames from 'classnames';
import qs from 'query-string';

// import Paginations from '../../../components/Paginations';
// import PaginationSummaries from '../../../components/Paginations/PaginationSummaries';
import { CardHeaderWithToolbars,Title } from '../../../components/Card/CardHeaderWithToolbars';
// import Toolbars from './Filters/Toolbars';
// import FiltersPanel from '../Filters';
// import FilterContainer from '../../../components/Container/FilterContainer/FilterContainer';
// import { Tbl, TblHead, TblBody } from '../../../components/Tbl';
// import { ModalDeliver } from './Modals';
// import { ModalInfoCreditCard } from '../../../components/Modals';
// import { generateNumber } from '../../../utils';
// import { DEFAULT_SORT_BY, DEFAULT_PER_PAGE } from '../../../constants/settings';
// import { getSaleList, doDeliverSneakerSale } from '../../../actions/saleAction';
import privateView from '../../../components/hocs/privateView';
// import RowItem from './RowItem';
// import RowHeader from './RowHeader';
// import ModalPurchasePrice from '../ModalPurchasePrice';
import AllFollowUp from './AllFollowUp';
import FollowUpApproval from './FollowUpApproval';
import Analytics from "../../../services/Analytics";

class FollowUp extends Component{
  constructor(props){
    super(props);
    const DEFAULT_STATE = {
      activeTab:'followUpList'
    }
    const queries = Object.assign(DEFAULT_STATE,qs.parse(props.location.search));
    this.state = {
      activeTab: queries.activeTab
    }
  }
  componentDidMount(){
    const { email } = this.props.injectedProps;
    Analytics.recordPageView('followup_sales', email, {
      url: window.location.href,
      location: this.props.location,
      match: this.props.match
    });

  }
  onActiveTabChange = async (id) => {
    await this.setState({activeTab:id});
    const { activeTab } = this.state;
    const { pathname } = this.props.location;
    const search = qs.stringify({
      activeTab
    });
    this.props.history.push({ pathname, search })
  }
  render() {
    return(
      <div className="animated fadeIn">
        <Row>
          <Col xs={12}>
            <Card>
              <CardHeaderWithToolbars>
                <Title>
                  <i className="fa fa-align-justify"></i>
                  <span>Follow-up Sales</span>
                </Title>
              </CardHeaderWithToolbars>
              <CardBody>
                <CardText>
                  All your follow up sales.
                </CardText>
                <Nav className="nav-tabs--custom" tabs>
                  <NavItem>
                    <NavLink
                      className={classNames({ active: this.state.activeTab === 'followUpList' })}
                      onClick={() => this.onActiveTabChange('followUpList') }
                    >
                      All Follow-up
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classNames({ active: this.state.activeTab === 'followUpApproval' })}
                      onClick={() => this.onActiveTabChange('followUpApproval') }
                    >
                      Follow-up Approval
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent className="tab-content--custom" activeTab={this.state.activeTab}>
                  <TabPane className="tab-content--custom__tab-pane" tabId="followUpList">
                    <Card body className="tab-content--custom__card">
                      <AllFollowUp/>
                    </Card>
                  </TabPane>
                </TabContent>
                <TabContent className="tab-content--custom" activeTab={this.state.activeTab}>
                  <TabPane className="tab-content--custom__tab-pane" tabId="followUpApproval">
                    <Card body className="tab-content--custom__card">
                      <FollowUpApproval/>
                    </Card>
                  </TabPane>
                </TabContent>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    )
  }
}
const mapStateToProps= ({sale, auth: {id: authId, email, isFetch, isLogged, roles}}) => {
	return {
    sale,
    guardData: {isFetch, isLogged, roles, id: authId, email}
	}
};
const enhance = connect(mapStateToProps);
export default enhance(privateView(FollowUp, ['supervisor','sale_supervisor']));
