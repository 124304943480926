import React from 'react';
import { connect } from 'react-redux';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ListGroup,
  ListGroupItem,
  Label,
  Input
} from 'reactstrap';
import { getListUserShipping } from '../../../../actions/userShippingAction';
import { ButtonXs } from '../../../../components/Button/Buttons';

class ModalShipping extends React.Component{
  constructor(props){
    super(props);
    this.state={
      list: [],
      userId: null
    }
  }

  componentDidUpdate(lastProps){
    if(lastProps.isOpen===false&&lastProps.isOpen!==this.props.isOpen){
      if(this.state.userId!=this.props.userId){
        this.setState({
          userId: this.props.userId,
          list: []
        }, ()=>this.props.getList(this.props.userId));
      }
    }
    if(lastProps.userShipping.isFetch&&lastProps.userShipping.isFetch!==this.props.userShipping.isFetch){
      if(this.props.userShipping.list.status_code===200){
        const { data } = this.props.userShipping.list;
        this.setState({list: data});
      }
    }
  }

  render(){
    return(
      <Modal isOpen={this.props.isOpen} toggle={this.props.onCancel} className="modal-dialog modal-md">
        <ModalHeader toggle={this.props.onCancel}>Please choose one: </ModalHeader>
        <ModalBody>
          <div className="mb-2" style={{display: 'flex', justifyContent: 'flex-end'}}>
            <ButtonXs color="primary" onClick={() => this.props.onSelectChanged(null)}>Create another one</ButtonXs>
          </div>
          <ListGroup style={{maxHeight:300, overflow:'auto'}}>
          {
            this.state.list
            .map((item, index)=>
              <ListGroupItem key={`${item.id}__${index+1}`}>
                <Label check style={{width: "100%", cursor: "pointer"}}>
                  <div style={{display: 'flex', alignItems: 'center'}}>
                    <Input type="radio" value="true" onChange={()=>this.props.onSelectChanged(item)}/>
                    <div style={{fontSize: "1.5rem", padding: '0.75rem 1rem'}}>
                      <i className="icon-home"></i>
                    </div>
                    <div>
                      <p style={{marginBottom: 0}}>
                        <span style={{fontSize: ".75rem", fontWeight:'bold'}}>
                          {item.alias}<br/>
                        </span>
                        <span style={{fontSize: "1rem"}}>{item.full_name}</span>
                        <br/>
                        <span style={{fontSize: ".75rem"}}><i>{item.street_address}{item.postal_code? ` - Phone: ${item.phone_number}`: '' }</i></span>
                      </p>
                    </div>
                  </div>
                </Label>
              </ListGroupItem>
            )
          }
          </ListGroup>
        </ModalBody>
      </Modal>
    )
  }
}
const mapStateToProps = ({userShipping}) => ({
  userShipping,
})
const mapDispatchToProps = dispatch => ({
  getList: (userId) => dispatch(getListUserShipping(userId)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ModalShipping);