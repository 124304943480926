import React, { Component } from "react";
import { connect } from "react-redux";
import uuidv4 from "uuid/v4";
import {
    Alert,
    Form,
    Label,
    Row,
    Col,
    Input,
    FormGroup,
    ButtonGroup,
    Button,
    Card,
    CardBody,
    CardHeader,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from "reactstrap";
import classNames from "classnames";
import { hasError, textValue, dateValue, dateChange } from "../../utils/form";
import {
    createPushNotification,
    deletePushNotification,
    getDetailPushNotification,
    updatePushNotification,
    addImage,
    deleteImage,
} from "../../actions/pushNotificationAction";
import {
    clearImageUpload,
    uploadImage,
    deleteImage as deleteImageS3,
} from "../../actions/imageUploadAction";
import {
    ImagesCompressor,
    createUploadFileForm,
} from "../../utils/imageHelper";
import { ValidationMessage } from "../../components/Form/ValidationMessage";
import { AWS_BUCKET, AWS_BUCKET_REGION } from "../../config/storageBucket";
import { getPathS3, getOriginFromSignedUrl } from "../../utils/AWS";
import Analytics from "../../services/Analytics";
import DraggableUploader from "../../components/Form/Uploader/DraggableUploader";
import InputDatetime from "../../components/Form/InputDatetime";
import SmartPhoneAndroid from "../../assets/img/smartphone-android.png";
import InputWithEmojiPicker from "../../components/Form/InputWithEmojiPicker/InputWithEmojiPicker";
import { ModalEditImage } from "../../components/Modals";
import { getCacheImage } from "../../utils/imageHelper";
import Select from "react-select";

const DEFAULT_IMAGE_ASPECT_PORTRAIT = 1 / 1;
const AWS_ENDPOINT = "amazonaws.com";
const INITIAL_CUSTOM_DATA = [
    {
        key: "",
        value: "",
    },
];

const getDefaultStartedAt = () => {
    const now = new Date();
    now.setHours(0);
    now.setMinutes(0);
    now.setSeconds(0);
    now.setMilliseconds(0);

    return now;
};

const getStorageName = (imgUrl) => {
    if (new RegExp(AWS_BUCKET).test(imgUrl)) return AWS_BUCKET;
    else if (/kickavenue-assets/.test(imgUrl)) return "kickavenue-assets";
    return "external-link";
};

const getUrlKey = (imgUrl) => {
    const regexStorage = new RegExp(AWS_BUCKET);
    if (regexStorage.test(imgUrl)) {
        const firstPattern = new RegExp(
            `s3.${AWS_BUCKET_REGION}.${AWS_ENDPOINT}/${AWS_BUCKET}/`
        );
        const secondPattern = new RegExp(
            `${AWS_BUCKET}.s3.${AWS_BUCKET_REGION}.${AWS_ENDPOINT}/`
        );
        const thirdPattern = new RegExp(`${AWS_BUCKET}.s3.${AWS_ENDPOINT}/`);
        let parsedUrl = imgUrl.trim().replace(/^(https|http):\/\//, "");
        if (firstPattern.test(parsedUrl)) {
            return parsedUrl.replace(firstPattern, "");
        } else if (secondPattern.test(parsedUrl)) {
            return parsedUrl.replace(secondPattern, "");
        } else if (thirdPattern.test(parsedUrl)) {
            return parsedUrl.replace(thirdPattern, "");
        }
    }
    return "";
};

const ModalProgress = (props) => (
    <Modal size="sm" className="modal-confirmation" isOpen={props.isOpen}>
        <ModalHeader className="modal-header--center">
            {props.isDeleting ? "Deleting Progress" : "Submitting Progress."}
        </ModalHeader>
        <ModalBody>
            {props.renderModalBody ? props.renderModalBody() : props.modalBody}
        </ModalBody>
        <ModalFooter>
            <Button
                className={classNames({ "d-none": props.isPrimaryButtonHide })}
                color="primary"
                onClick={props.onPrimaryClick}
            >
                Gotchas
            </Button>
        </ModalFooter>
    </Modal>
);

class PushNotificationForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            _edit: this.props.edit,
            _readOnly: this.props.readOnly,
            _hasId: this.props.match.params.id ? true : false,
            _images: [],
            _form: {
                id: this.props.match.params.id,
                title: "",
                body: "",
                active: false,
                started_at: getDefaultStartedAt(),
                updated_at: "",
                URL: "",
                status: "draft",
            },
            _imageUploadList: [],
            _formSubmit: false,
            _isDeleting: false,
            _modalDelete: false,
            _modalDeleteProgressOpen: false,
            _faqDeleteProgress: false,
            _isModalProgress: false,
            _taskAvailable: 0,
            _taskDone: 0,
            isEditImageOpen: false,
            editImageRatio: DEFAULT_IMAGE_ASPECT_PORTRAIT,
            imageSourceToEdit: "",
            imageSourceToEdits: [],
            isImmediately: false,
            customData: [...INITIAL_CUSTOM_DATA],
        };

        this.onSubmit = this.onSubmit.bind(this);
        this.handleCheckedChange = this.handleCheckedChange.bind(this);
        this.handleChangeEditor = this.handleChangeEditor.bind(this);
        this._handleDropzoneOnDrop = this._handleDropzoneOnDrop.bind(this);
        this._handleRemoveImage = this._handleRemoveImage.bind(this);
        this._handleUploadImages = this._handleUploadImages.bind(this);
    }

    componentDidMount() {
        if (this.state._hasId) {
            this.props.find(this.state._form.id);
        }
    }

    componentWillUnmount() {
        this.props.clearImageUpload();
    }

    componentDidUpdate = async (lastProps) => {
        const {
            pushNotification: lastPushNotification,
            imageUpload: lastImageUpload,
        } = lastProps;
        const { pushNotification, imageUpload } = this.props;

        if (
            lastPushNotification.isFetch &&
            !pushNotification.isFetch &&
            !pushNotification.error
        ) {
            const newForm = {};
            const {
                id,
                title,
                body,
                active,
                started_at,
                updated_at,
                URL,
                status,
                data,
            } = this.props.pushNotification.detail;
            newForm.id = id;
            newForm.title = title;
            newForm.body = body;
            newForm.active = active;
            newForm.started_at = started_at;
            newForm.updated_at = updated_at;
            newForm.URL = URL;
            newForm.status = status;
            let customData;
            if(data){
                const parsedData = JSON.parse(data);
                customData = Object.keys(parsedData).map((el) => ({
                    key: el,
                    value: parsedData[el],
                }));
                customData.push([...INITIAL_CUSTOM_DATA]);
            }
            
            if(!data){
                customData = [...INITIAL_CUSTOM_DATA];
            }

            const _images = [];

            if (URL) {
                const identifier = uuidv4();
                _images.push({
                    id,
                    url: URL,
                    storageName: getStorageName(URL),
                    urlKey: getUrlKey(URL),
                    status: true,
                    isUploading: false,
                    updatedAt: updated_at,
                    identifier,
                });
            }

            this.setState({
                ...this.state,
                customData,
                _images,
                _form: { ...this.state.form, ...newForm },
                isImmediately: !started_at,
            });
        }

        if (
            lastPushNotification.isSubmit &&
            !pushNotification.isSubmit &&
            !pushNotification.error &&
            !this.state._isDeleting
        ) {
            const newForm = {};
            const {
                id,
                title,
                body,
                active,
                started_at,
                updated_at,
                URL,
                status,
            } = this.props.pushNotification.detail;
            newForm.id = id;
            newForm.title = title;
            newForm.body = body;
            newForm.active = active;
            newForm.started_at = started_at;
            newForm.updated_at = updated_at;
            newForm.URL = URL;
            newForm.status = status;

            this.setState({
                _form: { ...this.state.form, ...newForm },
                _taskDone: this.state._taskDone + 1,
            });
            this._handleUploadImages();
        }

        if (
            lastImageUpload.isUpload &&
            !imageUpload.isUpload &&
            !imageUpload.error
        ) {
            if (imageUpload.success.data.success) {
                const { id, title, body, active, started_at, status } =
                    this.state._form;
                const URL = imageUpload.success.data.url;
                const data = this.constructCustomData();
                const submitForm = {
                    title,
                    body,
                    active,
                    started_at: started_at || null,
                    URL,
                    status,
                    data,
                };

                const image = this.state._imageUploadList[0];
                const identifier = image.identifier;
                this.props.saveImage(identifier, id, submitForm);
            }

            if (!imageUpload.success.data.success) {
                this.setState({
                    _isModalProgress: false,
                });
            }
        }

        if (
            lastPushNotification.isSubmittingImage &&
            !pushNotification.isSubmittingImage &&
            !pushNotification.error
        ) {
            const { id, URL, updated_at } = this.props.pushNotification.detail;
            const url = imageUpload.success.data.url || URL;
            this.setState({
                _taskDone: this.state._taskDone + 1,
                _isModalProgress: false,
                _imageUploadList: [],
                ...(!this.props.edit && {
                    _images: [],
                    _form: {
                        id: "",
                        title: "",
                        body: "",
                        active: false,
                        started_at: getDefaultStartedAt(),
                        updated_at: "",
                        URL: "",
                        status: false,
                    },
                    isImmediately: false,
                    customData: [...INITIAL_CUSTOM_DATA],
                }),
                ...(this.props.edit && {
                    _images: [
                        {
                            ...this.state._images[0],
                            id,
                            url,
                            storageName: getStorageName(url),
                            urlKey: getUrlKey(url),
                            status: true,
                            isUploading: false,
                            updatedAt: updated_at,
                        },
                    ],
                }),
            });
        }

        if (
            lastPushNotification.isSubmit &&
            !pushNotification.isSubmit &&
            !pushNotification.error &&
            !this.props.edit &&
            this.state._images.length === 0
        ) {
            this.setState({
                _form: {
                    id: "",
                    title: "",
                    body: "",
                    active: false,
                    started_at: getDefaultStartedAt(),
                    updated_at: "",
                    URL: "",
                    status: false,
                },
                isImmediately: false,
            });
        }

        if (
            lastPushNotification.isDeletingImage &&
            !pushNotification.isDeletingImage &&
            !pushNotification.error
        ) {
            this.removeImageFromS3();
        }

        if (
            lastImageUpload.isDeleteProgress &&
            !imageUpload.isDeleteProgress &&
            !imageUpload.error
        ) {
            this.setState({
                _form: {
                    ...this.state._form,
                    URL: "",
                },
            });
        }
    };

    handleChange(e) {
        const newState = { ...this.state._form };
        newState[e.target.name] = e.target.value;
        this.setState({ _form: newState });
    }

    handleCheckedChange(e) {
        const newState = { ...this.state._form };
        newState[e.target.name] = e.target.checked;
        this.setState({ _form: newState });
    }

    onSelectChange(val, attributeForm) {
        const { _form } = this.state;
        if (val) {
            _form[attributeForm] = val.value;
            this.setState({ _form });
        }
    }

    constructCustomData = () => {
        const customData = {};
        const newCustomData = [];

        this.state.customData.forEach(({ key, value }) => {
            if (key && value) {
                customData[key] = value;
                newCustomData.push({
                    key,
                    value,
                });
            }
        });

        newCustomData.push([...INITIAL_CUSTOM_DATA]);

        this.setState({ customData: newCustomData });

        return Object.keys(customData).length
            ? JSON.stringify(customData)
            : null;
    };

    onSubmit(e) {
        e.preventDefault();
        this.setState({ _formSubmit: true }, () => {
            const { id, title, body, active, started_at, URL, status } =
                this.state._form;
            const data = this.constructCustomData();
            const submitForm = {
                title,
                body,
                active,
                started_at: started_at || null,
                URL,
                status,
                data,
            };

            const execute = () => {
                if (this.props.edit && this.state._hasId) {
                    delete submitForm.delete;
                    this.props.update(id, submitForm);
                }

                if (!this.props.edit && !this.state._hasId) {
                    this.props.create(submitForm);
                }
            };

            const hasImages = this.state._images.filter(
                (item) => item.storageName === "in-local"
            ).length;

            if (hasImages) {
                const _imageUploadList = this.state._images
                    .filter((item) => item.storageName === "in-local")
                    .map(({ identifier, fileInput }) => ({
                        identifier,
                        fileInput,
                    }));
                this.setState(
                    {
                        _imageUploadList,
                        _isModalProgress: true,
                        _taskAvailable: 1 + _imageUploadList.length,
                        _taskDone: 0,
                    },
                    () => execute()
                );

                return;
            }

            if (!hasImages) {
                execute();
            }
        });
    }

    _handleDropzoneOnDrop(files) {
        if (files.length) {
            const droppedImages = files.map((file) => {
                const identifier = uuidv4();
                return {
                    id: null,
                    url: null,
                    storageName: "in-local",
                    status: false,
                    isUploading: false,
                    fileInput: file,
                    updatedAt: null,
                    identifier,
                };
            });
            const image = droppedImages[0];
            const fileInput = image.fileInput;
            const imageDataUrl = fileInput.preview;
            const clonedImages = [...droppedImages];
            this.setState({
                _images: clonedImages,
                isEditImageOpen: true,
                imageSourceToEdits: [
                    { identifier: image.identifier, imageDataUrl },
                ],
                imageSourceToEdit: imageDataUrl,
                editImageRatio: DEFAULT_IMAGE_ASPECT_PORTRAIT,
            });
        }
    }

    onSuccessEditImage = async (blob, ratio) => {
        const blobUrl = URL.createObjectURL(blob);
        let fileName = blobUrl.substr(blobUrl.lastIndexOf("/") + 1);

        if (fileName == "") {
            fileName = "local";
        }

        const { _images, imageSourceToEdits } = this.state;
        let identifier = 0;

        if (imageSourceToEdits.length) {
            identifier = imageSourceToEdits[0].identifier;
        }

        const newImages = _images.map((image) => {
            if (image.identifier === identifier) {
                const fileInput = new File([blob], `${fileName}.jpeg`, {
                    type: "image/jpeg",
                });
                fileInput["preview"] = blobUrl;
                return {
                    ...image,
                    fileInput,
                    imageDataUrl: blobUrl,
                    orientation: ratio.ratio <= 1 ? "portrait" : "landscape",
                };
            }
            return image;
        });

        this.setState(
            {
                _images: [...newImages],
                isEditImageOpen: !this.state.isEditImageOpen,
            },
            () => this._handleToggleEditImageCallback()
        );
    };

    _handleToggleEditImageCallback = () => {
        const { imageSourceToEdits, isEditImageOpen } = this.state;
        if (!isEditImageOpen) {
            let slices = [];
            if (imageSourceToEdits.length > 1) {
                slices = imageSourceToEdits
                    .slice(1)
                    .map(({ identifier, imageDataUrl }) => ({
                        identifier,
                        imageDataUrl,
                    }));
            }
            if (slices.length) {
                setTimeout(() => {
                    this.setState({
                        isEditImageOpen: !this.state.isEditImageOpen,
                        imageSourceToEdits: [...slices],
                        imageSourceToEdit: slices.length
                            ? slices[0].imageDataUrl
                            : null,
                    });
                }, 1500);
            } else {
                this.setState({
                    imageSourceToEdits: [],

                    imageSourceToEdit: null,
                });
            }
        }
    };

    removeImageFromS3 = async () => {
        const { deletedImages } = this.props.pushNotification;

        if (deletedImages.length) {
            const _images = [...this.state._images];
            const images = _images.filter((image) =>
                deletedImages.includes(image.identifier)
            );
            if (images.length) {
                const { identifier, urlKey, url } = images[0];
                if (urlKey !== "") {
                    const payload = {
                        identifier,
                        bucket_type: "assets",
                        name: getPathS3(getOriginFromSignedUrl(url)),
                    };
                    this.props.deleteImageS3(payload);
                }
                this.setState({
                    _images: _images.filter(
                        (item) => item.identifier !== identifier
                    ),
                });
            }
        }
    };

    _handleRemoveImage(e, imageIdentifier) {
        e.preventDefault();
        const { title, body, active, started_at, status } = this.state._form;
        const { _images } = this.state;
        const imageId = _images[0].id;
        if (imageId) {
            this.setState({
                _images: _images.map((item) =>
                    item.identifier === imageIdentifier
                        ? { ...item, deleted: true }
                        : item
                ),
            });

            const data = this.constructCustomData();
            const submitForm = {
                title,
                body,
                active,
                started_at: started_at || null,
                status,
                data,
            };

            this.props.removeImage(imageIdentifier, imageId, submitForm);
        } else {
            this.setState({
                _images: _images.filter(
                    ({ identifier }) => identifier !== imageIdentifier
                ),
            });
        }
    }

    _handleUploadImages = async () => {
        const { _images, _imageUploadProgress } = this.state;

        if (!_imageUploadProgress && _images.length) {
            const firstImage = _images[0];
            let fileInput;

            try {
                fileInput = await ImagesCompressor(firstImage.fileInput);
            } catch (e) {
                fileInput = firstImage.fileInput;
            }

            const newImages = _images.map((item) => {
                if (item.identifier == firstImage.identifier) {
                    return {
                        ...item,
                        is_uploading: true,
                    };
                }
                return {
                    ...item,
                };
            });
            this.setState({
                _images: newImages,
                _imageUploadProgress: !_imageUploadProgress,
                _imageUploadList: _images.map((item) => item.identifier),
            });
            const tempPayload = createUploadFileForm(fileInput, false, {
                name: "push_notification_images/" + this.state._form.id,
                bucket_type: "assets",
            });
            this.props.uploadImage(tempPayload, firstImage);
        }
    };

    handleChangeEditor(value) {
        this.setState({
            _form: {
                ...this.state._form,
                body: value.editor.getData(),
            },
        });
    }

    handleDatePickerChange(value, name) {
        const _form = dateChange(value, name, this.state._form);
        this.setState({ ..._form });
    }

    renderLoading() {
        return (
            <div className="row">
                <div className="col-sm-12">
                    <Alert color="info" className="text-center">
                        Getting all data...
                    </Alert>
                </div>
            </div>
        );
    }

    renderInfo() {
        let infoMessage = [];
        if (this.props.pushNotification.isSubmit)
            infoMessage.push(
                <Alert color="info" key="info">
                    <strong>Heads up!</strong> Submitting...
                </Alert>
            );
        if (this.props.pushNotification.success)
            infoMessage.push(
                <Alert color="success" key="success">
                    <strong>Well done!</strong>{" "}
                    {this.props.pushNotification.success}
                    {this.props.pushNotification.success === "Created." &&
                        " Let's create another one"}
                </Alert>
            );
        if (this.props.pushNotification.error !== null) {
            const { status_code } = this.props.pushNotification.error;
            switch (status_code) {
                case 422:
                    infoMessage.push(
                        <Alert color="danger" key="error">
                            <strong>Oh Snap!</strong> Please fullfill your form.{" "}
                        </Alert>
                    );
                    break;
                case 404:
                    infoMessage.push(
                        <Alert color="danger" key="error">
                            <strong>Oh Snap!</strong> Resource not found.{" "}
                        </Alert>
                    );
                    break;
                default:
                    infoMessage.push(
                        <Alert color="danger" key="error">
                            <strong>Oh Snap!</strong> We've got something errors{" "}
                        </Alert>
                    );
                    break;
            }
        }
        return (
            <div className="row">
                <div className="col-sm-12">{infoMessage}</div>
            </div>
        );
    }

    validateError(name) {
        if (this.hasError(name)) {
            let errorList = this.props.pushNotification.error.errors;
            return <ValidationMessage message={errorList[name][0]} />;
        }
        return "";
    }

    hasError(name) {
        if (this.props.pushNotification.error) {
            if (this.props.pushNotification.error.errors) {
                let errorList = this.props.pushNotification.error.errors;
                let errList = Object.keys(errorList).filter(
                    (key) => key == name
                );
                if (errList.length) return true;
            }
        }
        return false;
    }

    toggle() {
        this.setState({ _modalDelete: !this.state._modalDelete });
    }

    onDelete() {
        const _assetsToDelete = this.state._images.filter(
            (item) => item.urlKey !== ""
        );
        this.setState(
            {
                _formSubmit: true,
                _isDeleting: true,
                _modalDelete: false,
                _modalDeleteProgressOpen: true,
                _faqDeleteProgress: true,
                _taskAvailable: 1 + _assetsToDelete.length,
                _taskDone: 0,
                _assetsToDelete,
            },
            async () => {
                const uploadedImage = this.state._images.filter(
                    (image) => image.storageName !== "in-local"
                );

                if (uploadedImage.length) {
                    const { identifier, url } = uploadedImage[0];
                    const payload = {
                        identifier,
                        bucket_type: "assets",
                        name: getPathS3(getOriginFromSignedUrl(url)),
                    };

                    await this.props.deleteImageS3(payload);
                }
                await this.props.delete(this.state._form.id);
                await this.props.history.replace("/push_notification");
            }
        );
    }

    onChangeCustomData = (index, key, value) => {
        const { customData } = this.state;
        customData[index][key] = value;

        this.setState({ customData }, () => {
            const lastIndex = customData.length - 1;
            if (
                customData[lastIndex].key &&
                customData[lastIndex].value
            ) {
                this.setState({
                    customData: [...customData, { key: "", value: "" }],
                });
            }
            
            if (
                customData.length > 1 &&
                customData[lastIndex - 1].key === "" &&
                customData[lastIndex - 1].value === ""
            ) {
                customData.pop();
                this.setState({
                    customData,
                });
            }
        });
    };

    renderNotificationPreviewMessage = () => {
        if (
            this.state._form.title ||
            this.state._form.body ||
            this.state._images.length > 0
        ) {
            return (
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        position: "absolute",
                        top: 75,
                        backgroundColor: "#eceff1",
                        zIndex: 99,
                        padding: 8,
                        borderRadius: 6,
                        width: 218,
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            flex: 1,
                            flexDirection: "column",
                            alignItems: "flex-start",
                            paddingRight:
                                this.state._images.length > 0 ? 10 : 0,
                        }}
                    >
                        <div
                            style={{
                                fontSize: 11,
                                textAlign: "left",
                                width: "100%",
                                display: "-webkit-box",
                                WebkitBoxOrient: "vertical",
                                WebkitLineClamp: 1,
                                lineClamp: 1,
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                wordBreak: "break-all",
                            }}
                        >
                            <b>{this.state._form.title}</b>
                        </div>
                        <div
                            style={{
                                fontSize: 10,
                                textAlign: "left",
                                display: "-webkit-box",
                                WebkitBoxOrient: "vertical",
                                WebkitLineClamp: 2,
                                lineClamp: 2,
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                wordBreak: "break-all",
                            }}
                        >
                            {this.state._form.body}
                        </div>
                    </div>
                    {this.state._images.length > 0 && (
                        <img
                            src={
                                this.state._images[0].url
                                    ? this.state._images[0].url
                                    : getCacheImage(
                                          this.state._images[0].fileInput
                                              .preview
                                      )
                            }
                            height={48}
                            width={48}
                        />
                    )}
                </div>
            );
        }

        return null;
    };

    render() {
        return (
            <Row>
                <ModalEditImage
                    isOpen={this.state.isEditImageOpen}
                    toggle={() => {
                        const hasId =
                            this.state._images.length > 0 &&
                            this.state._images[0].id;

                        this.setState({
                            isEditImageOpen: false,
                            ...(!hasId && { _images: [] }),
                        });
                    }}
                    imageSource={this.state.imageSourceToEdit}
                    showGrid
                    ratio={this.state.editImageRatio}
                    onCropSucceeded={this.onSuccessEditImage}
                    activeRatio={[DEFAULT_IMAGE_ASPECT_PORTRAIT]}
                    isSkipVisible={false}
                />
                <ModalProgress
                    isDeleting={this.state._isDeleting}
                    isOpen={
                        this.state._isModalProgress ||
                        this.state._modalDeleteProgressOpen
                    }
                    renderModalBody={() =>
                        this.state._isDeleting ? (
                            <p className="text-center">
                                {this.state._taskAvailable >
                                this.state._taskDone
                                    ? "Deleting..."
                                    : this.state._taskAvailable > 0
                                    ? "Delete successfully..."
                                    : "Not available..."}{" "}
                                ({this.state._taskDone}/
                                {this.state._taskAvailable})
                            </p>
                        ) : (
                            <p className="text-center">
                                {this.state._taskAvailable >
                                this.state._taskDone
                                    ? "Please waiting..."
                                    : this.state._taskAvailable > 0
                                    ? "Submit successfully..."
                                    : "Not available..."}{" "}
                                ({this.state._taskDone}/
                                {this.state._taskAvailable})
                            </p>
                        )
                    }
                    isPrimaryButtonHide={
                        this.state._taskAvailable > this.state._taskDone
                    }
                    onPrimaryClick={() =>
                        this.state._isDeleting
                            ? this.props.history.replace(`/push_notification`)
                            : this.props.history.replace(
                                  `/push_notification/${this.state._form.id}`
                              )
                    }
                />
                <Modal
                    isOpen={this.state._modalDelete}
                    toggle={this.toggle.bind(this)}
                    className="modal-dialog modal-sm"
                >
                    <ModalHeader toggle={this.toggle.bind(this)}>
                        Confirmation
                    </ModalHeader>
                    <ModalBody>
                        {this.state._modalDelete &&
                        this.props.pushNotification.isSubmit ? (
                            <div className="row">
                                <div className="col-sm-12">
                                    <Alert color="info" className="text-center">
                                        Deleting...
                                    </Alert>
                                </div>
                            </div>
                        ) : (
                            <span>Are you sure to delete this?</span>
                        )}
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color="primary"
                            onClick={this.onDelete.bind(this)}
                        >
                            Yes
                        </Button>{" "}
                        <Button
                            color="secondary"
                            onClick={this.toggle.bind(this)}
                        >
                            Cancel
                        </Button>
                    </ModalFooter>
                </Modal>
                <div className="col-lg-6">
                    <Card>
                        <CardHeader>
                            <i className="fa fa-align-justify"></i>{" "}
                            {this.props.title}
                        </CardHeader>
                        <CardBody>
                            {this.renderInfo()}
                            {this.props.pushNotification.isFetch ? (
                                this.renderLoading()
                            ) : (
                                <Form onSubmit={this.onSubmit}>
                                    <FormGroup
                                        className={
                                            this.hasError("title")
                                                ? " has-danger has-feedback"
                                                : ""
                                        }
                                    >
                                        <Label for="formTitle">Title</Label>
                                        <InputWithEmojiPicker
                                            inputProps={{
                                                type: "text",
                                                id: "formTitle",
                                                name: "title",
                                                placeholder: "Enter a title*",
                                                value: this.state._form.title,
                                                onChange:
                                                    this.handleChange.bind(
                                                        this
                                                    ),
                                            }}
                                            emojiPickerProps={{
                                                onEmojiClick: (emoji) => {
                                                    this.setState({
                                                        ...this.state,
                                                        _form: {
                                                            ...this.state._form,
                                                            title:
                                                                this.state._form
                                                                    .title +
                                                                emoji,
                                                        },
                                                    });
                                                },
                                            }}
                                        />
                                        {this.validateError("title")}
                                    </FormGroup>

                                    <FormGroup
                                        className={
                                            this.hasError("body")
                                                ? " has-danger has-feedback"
                                                : ""
                                        }
                                    >
                                        <Label for="formPushNotificationBody">
                                            Body
                                        </Label>
                                        <InputWithEmojiPicker
                                            inputProps={{
                                                type: "textarea",
                                                id: "formBody",
                                                name: "body",
                                                placeholder: "Enter a body*",
                                                rows: 4,
                                                value: this.state._form.body,
                                                onChange:
                                                    this.handleChange.bind(
                                                        this
                                                    ),
                                            }}
                                            emojiPickerProps={{
                                                onEmojiClick: (emoji) => {
                                                    this.setState({
                                                        ...this.state,
                                                        _form: {
                                                            ...this.state._form,
                                                            body:
                                                                this.state._form
                                                                    .body +
                                                                emoji,
                                                        },
                                                    });
                                                },
                                            }}
                                        />
                                        {this.validateError("body")}
                                    </FormGroup>

                                    <Row>
                                        <Col xs={12} md={6}>
                                            <FormGroup
                                                className={
                                                    this.hasError("status")
                                                        ? " has-danger has-feedback"
                                                        : ""
                                                }
                                            >
                                                <Label for="formStatus">
                                                    Select Status
                                                </Label>
                                                <Select
                                                    id="formStatus"
                                                    name="status"
                                                    value={
                                                        this.state._form.status
                                                    }
                                                    options={[
                                                        {
                                                            label: "Draft",
                                                            value: "draft",
                                                        },
                                                        {
                                                            label: "Scheduled",
                                                            value: "scheduled",
                                                        },
                                                        {
                                                            label: "Completed",
                                                            value: "completed",
                                                            disabled: true,
                                                        },
                                                    ]}
                                                    onChange={(val) => {
                                                        this.onSelectChange(
                                                            val,
                                                            "status"
                                                        );
                                                    }}
                                                    clearable={false}
                                                />
                                                {this.validateError("status")}
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                    <FormGroup
                                        className={classNames({
                                            "has-danger has-feedback": hasError(
                                                "active",
                                                this.state._error
                                            ),
                                        })}
                                    >
                                        <Label for="formActive">
                                            Set to Active &nbsp;
                                        </Label>
                                        <label className="switch switch-text switch-success-outline-alt">
                                            <input
                                                type="checkbox"
                                                className="switch-input"
                                                name="active"
                                                id="formActive"
                                                value="true"
                                                checked={textValue(
                                                    "active",
                                                    this.state._form
                                                )}
                                                onChange={
                                                    this.handleCheckedChange
                                                }
                                            />
                                            <span
                                                className="switch-label"
                                                data-on="Yes"
                                                data-off="No"
                                            ></span>
                                            <span className="switch-handle"></span>
                                        </label>
                                        {this.validateError("active")}
                                    </FormGroup>

                                    <FormGroup
                                        className={classNames({
                                            "has-danger has-feedback": hasError(
                                                "isImmediately",
                                                this.state._error
                                            ),
                                        })}
                                    >
                                        <Label for="formIsImmediately">
                                            Send Immediately &nbsp;
                                        </Label>
                                        <label className="switch switch-text switch-success-outline-alt">
                                            <input
                                                type="checkbox"
                                                className="switch-input"
                                                name="isImmediately"
                                                id="formIsImmediately"
                                                value="true"
                                                checked={
                                                    this.state.isImmediately
                                                }
                                                onChange={() => {
                                                    this.setState(
                                                        {
                                                            isImmediately:
                                                                !this.state
                                                                    .isImmediately,
                                                        },
                                                        () => {
                                                            if (
                                                                this.state
                                                                    .isImmediately
                                                            ) {
                                                                this.setState({
                                                                    _form: {
                                                                        ...this
                                                                            .state
                                                                            ._form,
                                                                        started_at:
                                                                            null,
                                                                    },
                                                                });
                                                            }

                                                            if (
                                                                !this.state
                                                                    .isImmediately
                                                            ) {
                                                                this.setState({
                                                                    _form: {
                                                                        ...this
                                                                            .state
                                                                            ._form,
                                                                        started_at:
                                                                            getDefaultStartedAt(),
                                                                    },
                                                                });
                                                            }
                                                        }
                                                    );
                                                }}
                                            />
                                            <span
                                                className="switch-label"
                                                data-on="Yes"
                                                data-off="No"
                                            ></span>
                                            <span className="switch-handle"></span>
                                        </label>
                                        {this.validateError("active")}
                                    </FormGroup>

                                    {!this.state.isImmediately && (
                                        <Row>
                                            <Col xs={12} md={6}>
                                                <FormGroup
                                                    className={classNames({
                                                        "has-danger has-feedback":
                                                            hasError(
                                                                "started_at",
                                                                this.state
                                                                    ._error
                                                            ),
                                                    })}
                                                >
                                                    <Label for="formStartedAt">
                                                        Started At
                                                    </Label>
                                                    <InputDatetime
                                                        inputProps={{
                                                            placeholder:
                                                                "Choose start date",
                                                            id: "formStartedAt",
                                                            autoComplete: "off",
                                                            readOnly: true,
                                                            style: {
                                                                backgroundColor:
                                                                    "#fff",
                                                            },
                                                        }}
                                                        value={dateValue(
                                                            "started_at",
                                                            this.state._form
                                                        )}
                                                        onChange={(date) =>
                                                            this.handleDatePickerChange(
                                                                date,
                                                                "started_at"
                                                            )
                                                        }
                                                        timeFormat="HH:mm"
                                                    />
                                                    {this.validateError(
                                                        "started_at"
                                                    )}
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    )}

                                    <FormGroup
                                        className={classNames({
                                            "has-danger has-feedback": hasError(
                                                "URL",
                                                this.state._error
                                            ),
                                        })}
                                    >
                                        <Label for="formURL">
                                            Notification Image
                                        </Label>
                                        <DraggableUploader
                                            label="Upload Notification Image"
                                            hintText="Drop your image here or click to select image to upload."
                                            onDrop={this._handleDropzoneOnDrop}
                                            images={this.state._images}
                                            multiple={false}
                                            onRemoveImage={
                                                this._handleRemoveImage
                                            }
                                            hideUploader={
                                                this.state._images.length
                                            }
                                        />
                                        {this.validateError("formURL")}
                                    </FormGroup>

                                    <FormGroup>
                                        <ButtonGroup>
                                            <Button
                                                color="primary"
                                                disabled={
                                                    this.props.pushNotification
                                                        .isSubmit
                                                }
                                            >
                                                Submit
                                            </Button>
                                            {this.state._hasId && (
                                                <Button
                                                    color="danger"
                                                    onClick={this.toggle.bind(
                                                        this
                                                    )}
                                                    disabled={
                                                        this.props
                                                            .pushNotification
                                                            .isSubmit ||
                                                        this.props
                                                            .pushNotification
                                                            .detail.status ===
                                                            "completed"
                                                    }
                                                >
                                                    Delete
                                                </Button>
                                            )}
                                        </ButtonGroup>
                                        {this.props.edit &&
                                            this.state._form.status ===
                                                "completed" && (
                                                <div
                                                    style={{
                                                        fontSize: 10,
                                                        color: "#777",
                                                    }}
                                                >
                                                    *You cannot delete a
                                                    notification that has been
                                                    completed.
                                                </div>
                                            )}
                                    </FormGroup>
                                </Form>
                            )}
                        </CardBody>
                    </Card>
                </div>
                <div className="col-lg-6">
                    <Row>
                        <Card style={{ width: "100%" }}>
                            <CardHeader>
                                <i className="fa fa-database"></i> Notification
                                Custom Data
                            </CardHeader>
                            <CardBody>
                                {this.state.customData.map((_, index) => {
                                    return (
                                        <Row
                                            style={
                                                this.state.customData.length >
                                                    1 &&
                                                index !=
                                                    this.state.customData - 1
                                                    ? { marginBottom: 15 }
                                                    : {}
                                            }
                                        >
                                            <div className="col-lg-6">
                                                <Input
                                                    type="text"
                                                    name={`custom_data_key_[${index}]`}
                                                    placeholder="Enter a key"
                                                    value={
                                                        this.state.customData[
                                                            index
                                                        ].key
                                                    }
                                                    onChange={({
                                                        target: { value },
                                                    }) =>
                                                        this.onChangeCustomData(
                                                            index,
                                                            "key",
                                                            value
                                                        )
                                                    }
                                                />
                                            </div>
                                            <div className="col-lg-6">
                                                <Input
                                                    type="text"
                                                    name={`custom_data_value_[${index}]`}
                                                    placeholder="Enter a value"
                                                    value={
                                                        this.state.customData[
                                                            index
                                                        ].value
                                                    }
                                                    onChange={({
                                                        target: { value },
                                                    }) =>
                                                        this.onChangeCustomData(
                                                            index,
                                                            "value",
                                                            value
                                                        )
                                                    }
                                                />
                                            </div>
                                        </Row>
                                    );
                                })}
                            </CardBody>
                        </Card>
                    </Row>
                    <Row>
                        <Card style={{ width: "100%" }}>
                            <CardHeader>
                                <i className="fa fa-eye"></i> Notification
                                Preview
                            </CardHeader>
                            {!this.props.pushNotification.isFetch && (
                                <CardBody>
                                    <Row justify={"center"} align={"middle"}>
                                        <Col>
                                            <div
                                                style={{ width: "fit-content" }}
                                            >
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        justifyContent:
                                                            "center",
                                                        backgroundImage: `url(${SmartPhoneAndroid})`,
                                                        width: 280,
                                                        height: 550,
                                                        backgroundRepeat:
                                                            "round",
                                                    }}
                                                >
                                                    {this.renderNotificationPreviewMessage()}
                                                </div>

                                                {/* <img
                                                    src={SmartPhoneAndroid}
                                                    width={280}
                                                /> */}
                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                            )}
                        </Card>
                    </Row>
                </div>
            </Row>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        pushNotification: state.pushNotification,
        imageUpload: state.imageUpload,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        find: (id) => dispatch(getDetailPushNotification(id)),
        delete: (id) => dispatch(deletePushNotification(id)),
        update: (id, payload) => dispatch(updatePushNotification(id, payload)),
        create: (payload) => dispatch(createPushNotification(payload)),
        clearImageUpload: () => dispatch(clearImageUpload()),
        uploadImage: (payload, rawImages) =>
            dispatch(uploadImage(payload, rawImages)),
        saveImage: (identifier, id, payload) =>
            dispatch(addImage(identifier, id, payload)),
        removeImage: (identifierImg, id, payload) =>
            dispatch(deleteImage(identifierImg, id, payload)),
        deleteImageS3: (payload) => dispatch(deleteImageS3(payload)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PushNotificationForm);
