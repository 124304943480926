import {
  LIST_MARKETING_BUDGET,
  DETAIL_MARKETING_BUDGET,
  DELETE_MARKETING_BUDGET,
  UPDATE_MARKETING_BUDGET,
  CREATE_MARKETING_BUDGET,
  MARKETING_BUDGET_ERROR,
  MARKETING_BUDGET_REQUEST,
} from '../constants';

const initialState = {
  list: {
    isFetch: false,
    data: [],
    error: null
  },
  details: {
    isFetch: false,
    isSubmit: false,
    data: {
      id: null,
      deleted: false,
      images: []
    },
    error: null
  }
}

const onFetched = (lastState, { requestType, value }) => {
  switch(requestType){
    case LIST_MARKETING_BUDGET: return {...lastState, list: { ...lastState.list, isFetch: value }}
    case DETAIL_MARKETING_BUDGET: return {...lastState, details: { ...lastState.details, isFetch: value }}
    case CREATE_MARKETING_BUDGET:
    case UPDATE_MARKETING_BUDGET:
    case DELETE_MARKETING_BUDGET: return {...lastState, details: { ...lastState.details, isSubmit: value }}
    default: return lastState;
  }
}

const onError = (lastState, { requestType, error, ...rest }) => {
  switch(requestType){
    case LIST_MARKETING_BUDGET: return {...lastState, list: { ...lastState.list, error: { ...error, ...rest } }}
    case DETAIL_MARKETING_BUDGET:
    case CREATE_MARKETING_BUDGET:
    case UPDATE_MARKETING_BUDGET:
    case DELETE_MARKETING_BUDGET: return {...lastState, details: { ...lastState.details, error: { ...error, ...rest } }}
    default: return lastState;
  }
}

const reducer = (state = initialState, { type, payload }) => {
  switch(type) {
    case MARKETING_BUDGET_REQUEST: return onFetched(state, payload)
    case MARKETING_BUDGET_ERROR: return onError(state, payload)
    case LIST_MARKETING_BUDGET: {
      return {
        ...state,
        list: {
          ...state.list,
          ...payload,
          error: null
        }
      }
    }
    case CREATE_MARKETING_BUDGET:
    case UPDATE_MARKETING_BUDGET:
    case DELETE_MARKETING_BUDGET:
    case DETAIL_MARKETING_BUDGET: {
      return {
        ...state,
        details: {
          ...state.details,
          ...payload,
          deleted: type === DELETE_MARKETING_BUDGET,
          error: null
        }
      }
    }
    default: return state;
  }
}

export default reducer;