import React,{ Component } from 'react';
import {
  CardBody,
  CardText
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { Tbl, TblHead, TblBody } from '../../../components/Tbl';
import { FontAwesomeIcon } from '../../../components/Icons';
import { doGetListSizeOfStock, doChangeStockKeyword } from '../../../actions/productStockAction'
import BadgeThin from '../../../components/Badges/BadgeThin';
import { TextRupiah } from '../../../components/Text';
import ContentWrapper from './ContentWrapper';
import FilterPanel from './stockSizes/FilterPanel';

const dataHeader=[
    'No.','Size (US)' ,'Minimum Price', 'Price By','Total Qty', 'Action'
];

class StockSizes extends Component{
  constructor(props){
    super(props);
    this.state={
      _id:this.props.match.params.id,
      _allSize: true,
      _keyword: this.props.stockKeyword.keyword.length>0 && this.props.stockKeyword.keywordFor==='users'?this.props.stockKeyword.keyword: '',
      _list: []
    }
    this._handleAllSizeCheckedChange = this._handleAllSizeCheckedChange.bind(this);
    this._handleTextChange = this._handleTextChange.bind(this, "_keyword");
    this._handleOnSubmit = this._handleOnSubmit.bind(this);
  }

  componentDidMount(){
    this._refreshList();
  }

  componentDidUpdate(prevProps){
    if(prevProps.stockSizeList.isFetch&&prevProps.stockSizeList.isFetch!==this.props.stockSizeList.isFetch){
      const { data } = this.props.stockSizeList;
      const { _allSize } = this.state;
      if(_allSize){
        this.setState({
          _list: data.sort((a,b)=>a.US-b.US)
        })
      }
      else{
        this.setState({
          _list: data.sort((a,b)=>a.US-b.US).sort((a,b)=>a.US-b.US).filter(item=>item.total_quantity>0)
        })
      }
    }
  }

  _refreshList(){
    let params = {};
    const { _keyword } = this.state;
    if(_keyword)
      params.keyword = _keyword
    this.props.findById(this.state._id, { params });
  }

  onRenderRowItem(){
    if(this.props.stockSizeList.status_code===200&&this.state._list.length>0){
      return this.state._list.map((item,index)=>
        <tr key={parseInt(index)+1}>
            <th scope="row">{ parseInt(index)+1 }</th>
            <td>{ `${item.US} [${item.sex}]`}{' '}
              {item.is_wrong_size==true?<BadgeThin style={{marginRight: 5}} color="danger">WRONG SIZE</BadgeThin>:''}
              {item.sneakers_condition?<BadgeThin style={{marginRight: 5}}>{item.sneakers_condition}</BadgeThin>:''}
              {item.pre_order?<BadgeThin style={{marginRight: 5}}>PREORDER</BadgeThin>:''}
            </td>
            <td>
              <TextRupiah prefix="Rp. " bold value={item.minimum_price}/> {item.minimum_price_count>1?<span>({item.minimum_price_count})</span>:null}
              {
                (item.pre_verified || item.rack) && (<div style={{fontSize: '.65rem'}}>
                  {item.pre_verified? <i className="fa fa-barcode mr-1"></i>:null}
                  {item.consignment_id? item.consignment_id: null}<br />
                  {item.rack? <i className="fa fa-barcode mr-1"></i>:null}
                  {item.rack? item.rack + ' (RACK)':null}
                </div>)
            }
            </td>
            <td>{ item.email }</td>
            <td>{ item.total_quantity }</td>
            <td>
              <div className="btn-group">
                <Link
                  to={`${this.state._id}/sizes/${item.id}`}
                  className="btn btn-secondary btn-sm"
                  title="Show"
                >
                  <FontAwesomeIcon iconType="search"/>
                </Link>
                <Link
                  to={`${this.state._id}/add?size_id=${item.id}`}
                  className="btn btn-primary btn-sm"
                  title="Add Stock"
                >
                  <FontAwesomeIcon iconType="plus"/>
                </Link>
                {
                  item.email&&~item.email.indexOf('@kickavenue.com')?
                  <Link
                    to={`/sell_request/${item.user_sell_id}`}
                    className="btn btn-danger btn-sm"
                    title="Edit Price"
                  >
                    <FontAwesomeIcon iconType="pencil"/>
                  </Link>
                  :null
                }
              </div>
            </td>
        </tr>
      )
    }
    return '';
  }

  _handleAllSizeCheckedChange(e){
    const { data } = this.props.stockSizeList;
    const { checked } = e.target;
    this.setState({
      _allSize: checked,
      _list: checked? data: data.sort((a,b)=>a.US-b.US).filter(item=>item.total_quantity>0)
    })
  }

  _handleTextChange(name, e){
    const lastState = this.state;
    lastState[name] = e.target.value;
    this.setState({
      ...lastState
    })
  }

  _handleOnSubmit(e){
    e.preventDefault();
    this._refreshList();
    this.props.changeParams(this.state._keyword);
  }

  render(){
    return (
      <ContentWrapper>
        <CardBody>
          <FilterPanel
            keyword={this.state._keyword}
            allSize={this.state._allSize}
            onHandleOnSubmit={this._handleOnSubmit}
            onHandleTextChange={this._handleTextChange}
            onHandleAllSizeCheckedChange={this._handleAllSizeCheckedChange}
          />
          <CardText>
            Showing <i>{this.props.detailProductVariant.display_name}</i>'s size:
          </CardText>
          <div className="table-responsive">
            <Tbl>
              <TblHead
                dataHeader={dataHeader}
              />
              <TblBody
                isLoad={this.props.stockSizeList.isFetch}
                hasRow={this.props.stockSizeList.status_code===200&&this.state._list.length>0}
                columnCount={dataHeader.length}
              >
              { this.onRenderRowItem() }
              </TblBody>
            </Tbl>
          </div>
        </CardBody>
      </ContentWrapper>
    )
  }
}

const mapStateToProps = ({ productStock, productVariant })=> ({
  detailProductVariant: productVariant.detail,
  stockKeyword: {
    keyword: productStock.keyword,
    keywordFor: productStock.keywordFor
  },
  stockSizeList: productStock.stockSizeList
});

const mapDispatchToProps = (dispatch) => ({
  findById: (id, query) => dispatch(doGetListSizeOfStock(id, query)),
  changeParams: (keyword) => dispatch(doChangeStockKeyword(keyword, 'users'))
});
export default connect(mapStateToProps,mapDispatchToProps)(StockSizes);