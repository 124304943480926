import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
    Col,
    Row,
    CardText,
    Card,
    CardBody
} from 'reactstrap';

import DateToHuman from '../../components/DateToHuman';
import Paginations from '../../components/Paginations';
import PaginationSummaries from '../../components/Paginations/PaginationSummaries';
import { Tbl, TblHead, TblBody, Th } from '../../components/Tbl';
import FilterPanel from './Filters';
import FilterContainer from '../../components/Container/FilterContainer/FilterContainer';
import { CardHeaderWithToolbars,Title } from '../../components/Card/CardHeaderWithToolbars';
import Toolbars from './Filters/Toolbars';
import CheckReadOnly from '../../components/CheckReadOnly';
import privateView from '../../components/hocs/privateView';
import { generateNumber } from '../../utils';
import { getListSlider } from '../../actions/sliderAction';
import { getOptionList } from '../../actions/categoriesAction';
import { TitleColumn } from './videoIndex/Columns';
import Analytics from "../../services/Analytics";
import { DEFAULT_PER_PAGE, DEFAULT_SORT_BY } from '../../constants/settings';

const defaultParams = {
  per_page : DEFAULT_PER_PAGE,
  sort_by: DEFAULT_SORT_BY,
  type: 'VIDEO',
}

class VideoIndex extends Component{
  constructor(){
    super();
    this.state={
      _params:{
        ...defaultParams
      },
      _filterOpen: false
    }
    this._handleToggleFilterPanel = this._handleToggleFilterPanel.bind(this);
    this._handleParamsChangeAndSubmit = this._handleParamsChangeAndSubmit.bind(this);
    this._handleGoto = this._handleGoto.bind(this);
    this._handleRefresh = this._handleRefresh.bind(this);
    this._handleChange = this._handleChange.bind(this);
    this._handleResetFilter = this._handleResetFilter.bind(this);
  }

  componentDidMount(){
    const { email } = this.props.injectedProps;
    this._getList(this.state._params);
    this.props.getCategoriesOptions()
    Analytics.recordPageView('video_list', email, {
      url: window.location.href,
      location: this.props.location,
      match: this.props.match
    });
  }

  _toggleFilterPanel(){
    this.setState({_filterOpen:!this.state._filterOpen})
  }

  _handleToggleFilterPanel(e){
    e.preventDefault();
    this._toggleFilterPanel();
  }

  _onRenderRowItem(){
    if(this.props.slider.status_code===200 && this.props.slider.data.data.length>0)
		{
			const { current_page,per_page } =  this.props.slider.data;
			return this.props.slider.data.data.map((item,idx)=>
				(
					<tr key={parseInt((per_page*(current_page-1))+idx+1)}>
						<th className="v-center" scope="row">{ parseInt((per_page*(current_page-1))+idx+1) }</th>
            <td className="v-center">
              <TitleColumn
                id={item.id}
                name={item.name}
                categories={item.category}
                videoUrl={item.redirect_url}
                createdAt={item.created_at}
              />
            </td>
            <td className="v-center">
              <CheckReadOnly check={item.active}/>
            </td>
            <td className="v-center">
              <DateToHuman  value={item.updated_at} id={`tooltip_updated_at_${generateNumber(per_page,current_page,idx)}`}/>
            </td>
            <td className="v-center">
							<Link to={`/videos/${item.id}`} className="btn btn-link">Details</Link>
						</td>
					</tr>
				)
			)
		}
		return null;
  }

	_onRenderPagination(){
    const {status_code, data} = this.props.slider;
    const hasData = (status_code===200 && data.data.length)||null;
    return hasData && (
      <Paginations
        size="sm"
        lastPage={data.last_page}
        perPage={data.per_page}
        total={data.total}
        maxPage={8}
        path={data.path}
        currentPage={data.current_page}
        prevPageUrl={data.prev_page_url}
        nextPageUrl={data.next_page_url}
        handleGotoCallback={this._handleGoto}
      />
    )
  }

	_handleGoto(page){
    const _params=this.state._params;
    _params.page=page;
    this.setState({_params},()=>this._getList(this.state._params));
  }

  _getList(rawParams = {}){
    let params = {};
    Object.keys(rawParams)
    .filter(key => rawParams[key]!='')
    .forEach(key=> params[key] = rawParams[key]);
    this.props.getList({params});
  }

  _handleParamsChangeAndSubmit(e){
    // Exclude page...
    const { page , ..._params } = this.state._params;
    this.setState({
      _params: {
        ..._params,
        [e.target.name]: e.target.value
      }
    }, () => {
      this._getList(this.state._params);
    })
  }

  _handleRefresh(e){
    e.preventDefault();
    this._refreshList();
  }

  _handleChange(e){
    const { _params } = this.state;
    this.setState({
      _params: {
        ..._params,
        [e.target.name]: e.target.value
      }
    })
  }

  _refreshList(){
    const {page, ..._params} = this.state._params;
    this.setState({
      _params
    },()=>this._getList(this.state._params));
  }

  _handleResetFilter(e){
    e.preventDefault();
    const newState = this.state._filterOpen?{_filterOpen:!this.state._filterOpen}:{};
    this.setState({
      ...newState,
      _params:{
        ...defaultParams
      }
    },()=>this._getList(this.state._params));
  }

  render(){
    return(
      <div className="animated fadeIn">
        <Row>
          <Col xs={12}>
            <Card>
              <CardHeaderWithToolbars>
                <Title>
                  <i className="fa fa-align-justify"></i>
                  <span>Video List</span>
                </Title>
                <Toolbars
                  isOpen={this.state._filterOpen}
                  onRefreshList={this._handleRefresh}
                  onResetFilter={this._handleResetFilter}
                  onToggleFilter={this._handleToggleFilterPanel}
                />
              </CardHeaderWithToolbars>
              <FilterContainer>
                <FilterPanel
                  isOpen={this.state._filterOpen}
                  {...this.state._params}
                  categoryOptions={this.props.dataCategories}
                  onHandleChange={this._handleChange}
                  onHandleSearch={this._handleRefresh}
                  onHandleChangeAndSubmit={this._handleParamsChangeAndSubmit}
                />
              </FilterContainer>
              <CardBody>
                <CardText>
                  In this section will display our videos.
                </CardText>
                <div className="table-responsive">
                  <PaginationSummaries
                    from={this.props.slider.data.from}
                    to={this.props.slider.data.to}
                    total={this.props.slider.data.total}
                  />
                  <Tbl>
                    <TblHead
                      renderChildren={()=>(
                        <tr>
                          <Th>No.</Th>
                          <Th>Name</Th>
                          <Th>Active</Th>
                          <Th>Updated</Th>
                          <Th>Action</Th>
                        </tr>
                      )}
                    />
                    <TblBody
                      isLoad={this.props.slider.isFetch}
                      hasRow={this.props.slider.status_code===200&&this.props.slider.data.data.length>0}
                      columnCount={6}
                    >
                    { this._onRenderRowItem() }
                    </TblBody>
                  </Tbl>
                </div>
                { this._onRenderPagination() }
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    )
  }
}

const mapStateToProps= ({ slider, masterCategories:{options}, auth: { isFetch, roles, isLogged, id, email } }) => {
	return {
    guardData: { isFetch, roles, isLogged, id, email },
    dataCategories: options,
		slider
	}
};

const mapDispatchToProps = (dispatch) => {
	return{
		getList:(query) => dispatch(getListSlider(query)),
    getCategoriesOptions: () => dispatch(getOptionList())
	}

};

const enhance = connect(mapStateToProps, mapDispatchToProps);

export default enhance(privateView(VideoIndex));