import mime from 'mime-types';
import uuidv4  from 'uuid/v4';
import {
	AUTH_TOKEN_KEY,
	UPDATE_STATUS_USER_SELL,
	ADD_USER_SELL,
	GET_DETAIL_USER_SELL,
	GET_LIST_USER_SELL,
	GET_LIST_USER_SELL_WITH_SIZE,
	FETCHING_USER_SELL,
	USER_SELL_ERROR,
	UPDATE_SELL_REQUEST_PRICE,
	DELETE_USER_SELL,
	UPDATE_STATUS_USER_SELL_ERROR,
	PROCESS_CHANGED_IMAGE_SELL,
	PROCESS_IMAGE_SELL_MODIFIED_STATE,
	PROCESS_IMAGE_SELL_DELETED_STATE,
	UPLOADING_IMAGE_SELL,
	CHANGING_IMAGE_SELL,
	DELETE_IMAGES_SELL,
	IMAGE_SELL_S3_ERROR,
	IMAGE_SELL_ERROR,
	GET_LIST_USER_SELL_JOURNAL,
	USER_SELL_JOURNAL_ERROR,
	FETCHING_USER_SELL_JOURNAL,
	GET_EXPRESS_LOWEST_ASK_LOADING,
	GET_EXPRESS_LOWEST_ASK_ERROR,
	GET_EXPRESS_LOWEST_ASK_SUCCESS
 }from '../constants';
import { manipulateErrors, checkAuthorized } from '../utils';
import { AWS_BUCKET_USER, AWS_BUCKET_USER_URL } from '../config/storageBucket'
import api from '../utils/Api';
import { logout } from './authAction';
import { s3 } from '../utils/AWS'
import slugify from '../utils/slugify';

const regexAWSHost = new RegExp(`^(https:\/\/${AWS_BUCKET_USER}.s3.amazonaws.com\/)`);
const folderAffix = 'seller-upload-products';

export const createUserSell = (payload) => {
	return dispatch=>(
		dispatch(isFetch({type:'submit',status:true})),
		api({
			Authorization:'Bearer '+localStorage.getItem(AUTH_TOKEN_KEY)
		}).post(`/admins/sells`,payload)
		.then(res => {
				dispatch(onSuccess(res.data,ADD_USER_SELL));
				dispatch(isFetch({type:'submit',status:false}));
		})
		.catch(err => {
			dispatch(onError(err));
			dispatch(isFetch({type:'submit',status:false}));
		})
	)
}

// Update Status User Sell Action
export const updateStatusUserSell=(id,payload)=>{
	return dispatch=>(
		dispatch(isFetch({type:'submit',status:true})),
		api({
			Authorization:'Bearer '+localStorage.getItem(AUTH_TOKEN_KEY)
		}).put(`/admins/sells/${id}/status`,payload)
		.then(res => {
				dispatch(onSuccess(res.data,UPDATE_STATUS_USER_SELL));
				dispatch(isFetch({type:'submit',status:false}));
		})
		.catch(err => {
			dispatch(onUpdateStatusError(id, err));
			dispatch(isFetch({type:'submit',status:false}));
		})
	)
}

// Update User Sell-Request
export const updateSellRequest = (id, payload) => {
	return dispatch => (
    dispatch(isFetch({type:'submit',status:true})),
		api({
			Authorization: 'Bearer '+ localStorage.getItem(AUTH_TOKEN_KEY)
		}).put(`/admins/sells/${id}`, payload)
		.then(res => {
      dispatch({type: UPDATE_SELL_REQUEST_PRICE, payload: res.data})
      dispatch(isFetch({type:'submit',status:false}));
    })
		.catch(err => {
			dispatch(onError(err));
      dispatch(isFetch({type:'submit',status:false}));
		})
	);
}

// Delete User Sell-Request
export const deleteSellRequest = (id) => {
	return dispatch => (
    dispatch(isFetch({type:'submit',status:true})),
		api({
			Authorization: 'Bearer '+ localStorage.getItem(AUTH_TOKEN_KEY)
		}).delete(`/admins/sells/${id}`)
		.then(res => {
      dispatch({type: DELETE_USER_SELL, payload: {...res.data, id}})
      dispatch(isFetch({type:'submit',status:false}));
    })
		.catch(err => {
			dispatch(onError(err));
      dispatch(isFetch({type:'submit',status:false}));
		})
	);
}

// Get Detail User Sell Action
export const getDetailUserSell=(id)=>{
	return dispatch=>(
		dispatch(isFetch({type:'fetch',status:true})),
		api({
			Authorization:'Bearer '+localStorage.getItem(AUTH_TOKEN_KEY)
		}).get(`/admins/sells/${id}`)
		.then(res => {
			dispatch(onSuccess(res.data,GET_DETAIL_USER_SELL));
			dispatch(isFetch({type:'fetch',status:false}));
		})
		.catch(err => {
			dispatch(onError(err));
			dispatch(isFetch({type:'fetch',status:false}));
		})
	)
}

// Get List User Sell Action
export const getListUserSell=(query)=>{
	return dispatch=>(
		dispatch(isFetch({type:'fetch',status:true})),
		api({
			Authorization:'Bearer '+localStorage.getItem(AUTH_TOKEN_KEY)
		}).get('/admins/sells',query)
		.then(res => {
			dispatch(onSuccess(res.data,GET_LIST_USER_SELL));
			dispatch(isFetch({type:'fetch',status:false}));
		})
		.catch(err => {
			dispatch(onError(err));
			dispatch(isFetch({type:'fetch',status:false}));
		})
	)
}

// Get List User Sell Action
export const getListUserSellWithSize=(size_id,query)=>{
	return dispatch=>(
		dispatch(isFetch({type:'fetch',status:true})),
		api({
			Authorization:'Bearer '+localStorage.getItem(AUTH_TOKEN_KEY)
		}).get(`/admins/sells/sizes/${size_id}`,query)
		.then(res => {
			dispatch(onSuccess(res.data,GET_LIST_USER_SELL_WITH_SIZE));
			dispatch(isFetch({type:'fetch',status:false}));
		})
		.catch(err => {
			dispatch(onError(err));
			dispatch(isFetch({type:'fetch',status:false}));
		})
	)
}

// Indicates when start fetch api or end fetch api
export const isFetch=(bool)=>(
	{
		type:FETCHING_USER_SELL,
		payload:bool
	}
)

// onSuccess callback
export const onSuccess=(res,type)=>
(
	{
		type:type,
		payload:res
	}
)

// On Error fetching api
export const onError=(err)=>{
	if(!checkAuthorized(manipulateErrors(err)))
		return logout();
	return {
		type:USER_SELL_ERROR,
		payload:manipulateErrors(err)
	}
}

export const onErrorRequest=(type, err)=>{
	if(!checkAuthorized(manipulateErrors(err)))
		return logout();
	return {
		type,
		payload: manipulateErrors(err)
	}
}

const onUpdateStatusError = (id, err) => {
	if(!checkAuthorized(manipulateErrors(err)))
		return logout();
	return {
		type: UPDATE_STATUS_USER_SELL_ERROR,
		payload: { id, ...manipulateErrors(err) }
	}
}

const putObjectS3 = (fileInput, userId, userSellId, oldFileName=null) => {
	return new Promise((resolve, reject) => {
		let objectKey = null;
		if(oldFileName){
			objectKey = oldFileName;
		}
		else{
			const uuid=uuidv4();
			const fileName=`${slugify((fileInput.name.substr(0, fileInput.name.lastIndexOf('.'))||fileInput.name))}-${uuid.substr(0,uuid.indexOf('-'))}`;
			objectKey = `${folderAffix}/${userId}/${userSellId}/${fileName}.${mime.extension(fileInput.type)}`;
		}
		s3().putObject({
			Bucket: AWS_BUCKET_USER,
			Key: objectKey,
			Body: fileInput,
			ContentType: mime.lookup(fileInput.name),
			ACL: 'public-read'
		}, (err) => {
			if(err) reject(err)
			else {
				resolve({
					objectKey,
					userId,
					userSellId,
					isCustomUrl: oldFileName !== null
				})
			}
		})
	})
}

export const deleteObjectS3 = (url) => {
	return new Promise((resolve, reject)=>{
		if(url.match(regexAWSHost)){
			const objectKey = url.replace(regexAWSHost, "");
			s3().deleteObject({
				Bucket: AWS_BUCKET_USER,
				Key: objectKey,
			},(err)=>{
				if(err) reject(err)
				else {
					resolve({
						objectKey,
						deleted: true
					})
				}
			})
		}
		else{
			resolve({
				objectKey: url,
				deleted: false
			})
		}
	})
}

/**
 * when upload to s3
 * @author zani
 * @param {number} user_sell_id
 * @param {object} files
 * @param {number} numUpload
 */
export const addAndUploadSellImage = (userId, userSellId, image) => {
	return dispatch => {
		dispatch({
			type: PROCESS_CHANGED_IMAGE_SELL,
			payload: {
				isImageProcessLoading: true,
				imageProcessedState: PROCESS_IMAGE_SELL_MODIFIED_STATE
			}
		});
		putObjectS3(image.fileInput, userId, userSellId)
		.then(result => {
			const payload = {
				user_sell_id: result.userSellId,
				URL:`${AWS_BUCKET_USER_URL}${result.objectKey}`,
			}
			dispatch(addSellImageApi(image, payload, result))
		})
		.catch(err => {
			dispatch(onS3Error(image, err));
		})
	}
}

 /**
  * when onSucess after upload post to kickavenue
  * @author zani
  * @param {object} Body
  */

export const addSellImageApi = (image, payload, dataS3Response) => {
	return dispatch => {
		dispatch({
			type: PROCESS_CHANGED_IMAGE_SELL,
			payload: {
				isImageProcessLoading: true,
				imageProcessedState: PROCESS_IMAGE_SELL_MODIFIED_STATE
			}
		});
		api({
			Authorization:'Bearer '+localStorage.getItem(AUTH_TOKEN_KEY)
		}).post('/admins/sells/images', payload)
		.then(res => {
			dispatch(onAddImageSuccess(image, res.data, dataS3Response))
		})
		.catch(err=>{
			dispatch(onSellImageError(image, err))
		})
	}
}

const onAddImageSuccess = (image, dataApi, dataS3) => ({
	type: UPLOADING_IMAGE_SELL,
	payload: { image, isNew: true, dataApi, dataS3 }
})

export const modifyAndUploadSellImage = (userId, userSellId, image)=> {
	return dispatch => {
		let fileName  = null;
		if(image.URL.match(regexAWSHost)) fileName = image.URL.replace(regexAWSHost, '');
		dispatch({
			type: PROCESS_CHANGED_IMAGE_SELL,
			payload: {
				isImageProcessLoading: true,
				imageProcessedState: PROCESS_IMAGE_SELL_MODIFIED_STATE
			}
		});
		putObjectS3(image.fileInput, userId, userSellId, fileName)
		.then(result => {
			const payload = {
				user_sell_id: result.userSellId,
				URL:`${AWS_BUCKET_USER_URL}${result.objectKey}`,
			}
			dispatch(updateSellImageApi(image.id, image, payload, result))
		})
		.catch(err => {
			dispatch(onS3Error(image, err));
		})
	}
}

export const updateSellImageApi  = (id, image, payload, dataS3) => dispatch => {
	api({
		Authorization:'Bearer '+localStorage.getItem(AUTH_TOKEN_KEY)
	}).put(`/admins/sells/images/${id}`, payload)
	.then(res => {
		dispatch(onUpdateImageSuccess(image, res.data, dataS3))
	})
	.catch(err => {
		dispatch(onSellImageError(image, err));
	})
}

export const updateSellImage  = (id, image, payload, dataS3) => dispatch => {
	dispatch({
		type: PROCESS_CHANGED_IMAGE_SELL,
		payload: {
			isImageProcessLoading: true,
			imageProcessedState: PROCESS_IMAGE_SELL_MODIFIED_STATE
		}
	});
	api({
		Authorization:'Bearer '+localStorage.getItem(AUTH_TOKEN_KEY)
	}).put(`/admins/sells/images/${id}`, payload)
	.then(res => {
		dispatch(onUpdateImageSuccess(image, res.data, dataS3))
	})
	.catch(err => {
		dispatch(onSellImageError(image, err));
	})
}


const onUpdateImageSuccess = (image, dataApi, dataS3) => ({
	type: CHANGING_IMAGE_SELL,
	payload: { image, isNew: false, dataApi, dataS3 }
})

export const deleteSellImage = (image) => dispatch => {
	dispatch({
		type: PROCESS_CHANGED_IMAGE_SELL,
		payload: {
			isImageProcessLoading: true,
			imageProcessedState: PROCESS_IMAGE_SELL_DELETED_STATE
		}
	});
	deleteObjectS3(image.URL)
	.then(() => {
		dispatch(deleteSellImageApi(image))
	})
	.catch(e => {
		dispatch(onS3Error(image, e));
	})
}

// export const deleteSellImageApi = image => dispatch => {
// 	api({
// 		Authorization:'Bearer '+localStorage.getItem(AUTH_TOKEN_KEY)
// 	}).delete(`/admins/sells/images/${image.id}`)
// 	.then(res => {
// 		return dispatch({
// 			type: DELETE_IMAGES_SELL,
// 			payload: {
// 				image,
// 				dataApi: res.data,
// 				deleted: true
// 			}
// 		})
// 	})
// 	.catch(err => {
// 		dispatch(onSellImageError(image, err));
// 	})
// }

export const deleteSellImageApi = image => dispatch => {
	dispatch({
		type: PROCESS_CHANGED_IMAGE_SELL,
		payload: {
			isImageProcessLoading: true,
			imageProcessedState: PROCESS_IMAGE_SELL_DELETED_STATE
		}
	});
	api({
		Authorization:'Bearer '+localStorage.getItem(AUTH_TOKEN_KEY)
	}).delete(`/admins/sells/images/${image.id}`)
	.then(res => {
		return dispatch({
			type: DELETE_IMAGES_SELL,
			payload: {
				image,
				dataApi: res.data,
				deleted: true
			}
		})
	})
	.catch(err => {
		dispatch(onSellImageError(image, err));
	})
}

const onSellImageError = (image, error)=>({
	type: IMAGE_SELL_ERROR,
	payload: {
		image,
		errorType: "ERROR_API",
		error: manipulateErrors(error)
	}
})

const onS3Error = (image, error)=>({
	type: IMAGE_SELL_S3_ERROR,
	payload: {
		image,
		errorType: "ERROR_S3_API",
		error
	}
})

export const getUserSellJournal = (id, query) => dispatch => {
	dispatch({ type: FETCHING_USER_SELL_JOURNAL, status:true });
	api({
		Authorization:'Bearer '+localStorage.getItem(AUTH_TOKEN_KEY)
	}).get(`/admins/sells/${id}/journals`, query)
	.then(res => {
		dispatch(onSuccess(res.data, GET_LIST_USER_SELL_JOURNAL));
		dispatch({ type: FETCHING_USER_SELL_JOURNAL, status:false });
	})
	.catch(err => {
		dispatch(onErrorRequest(USER_SELL_JOURNAL_ERROR, err));
		dispatch({ type: FETCHING_USER_SELL_JOURNAL, status:false });
	})
}

export const getExpressLowestAskBySellId = (id) => dispatch => {
	dispatch({ type: GET_EXPRESS_LOWEST_ASK_LOADING, payload: { id } });
	api({
		Authorization:'Bearer '+localStorage.getItem(AUTH_TOKEN_KEY)
	}).get(`/admins/sells/${id}/lowest-ask-express`)
	.then(res => {
		dispatch(onSuccess(res.data, GET_EXPRESS_LOWEST_ASK_SUCCESS));
	})
	.catch(err => {
		dispatch({ 
			type: GET_EXPRESS_LOWEST_ASK_ERROR, 
			payload: { 
				id, 
				error: err
			} 
		});
	})
}