import React from 'react';
import propTypes from 'prop-types';
import { TabPane } from 'reactstrap';
import SneakerDetail from '../SaleDetails/SneakerDetail';

const TabPaneSneakerDetail = props => (
  <TabPane className="tab-content--custom__tab-pane" tabId={props.tabId}>
    <SneakerDetail
      isLoading={props.isLoading}
      isLegit={props.isLegit}
      product={props.product}
      userSellImages={props.userSellImages}
      userSellId={props.userSellId}
    />
  </TabPane>
)

export default TabPaneSneakerDetail;

TabPaneSneakerDetail.defaultProps = {
  isLoading: false
}

TabPaneSneakerDetail.propTypes = {
  tabId: propTypes.string.isRequired,
  isLoading: propTypes.bool,
  isLegit: propTypes.bool,
  product: propTypes.object,
  userSellId: propTypes.number.isRequired
}