import React from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
	Button,
	Alert,
  Badge,
  ModalFooter
} from 'reactstrap';
import Loading from 'react-loading-animation';
import {
  FieldRow,
  FieldColumn,
  FieldLabel,
  FieldValue
} from '../../styled/Fields';
import { _getValue } from '../../utils/form';
import propTypes from 'prop-types';

const ModalTrackingShipment = props => {
  const { isOpen, isLoading, toggle, shippingUpdate } = props
  const renderShippingStatus = (status = 'DELIVERED') => (
    <Badge color={status === 'ON PROCESS' ? 'warning' : 'success'}>{status}</Badge>
  )
  let sellerHistory = [];
  let buyerHistory = [];
  if(shippingUpdate && shippingUpdate.history && shippingUpdate.history.length){
    sellerHistory = shippingUpdate.history.filter(item => item.destination === 'warehouse')
    buyerHistory = shippingUpdate.history.filter(item => item.destination === 'buyer')
  }
  return(
    <Modal size="md" className="modal-confirmation" isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle} className="modal-header--left">
        Tracking Details
      </ModalHeader>
      <ModalBody className="px-3">
        <Loading isLoading={isLoading}>
          <FieldRow style={{ maxHeight: "375px", overflow:'auto'}}>
            <FieldColumn className="col-xs-12 col-md-6">
              <FieldLabel>Seller Courier</FieldLabel>
              <FieldValue>
                <span>{_getValue(shippingUpdate && shippingUpdate.seller_courier)}</span>
              </FieldValue>
            </FieldColumn>
            <FieldColumn className="col-xs-12 col-md-6">
              <FieldLabel>Seller AWB Numb.</FieldLabel>
              <FieldValue>
                <span>{_getValue(shippingUpdate && shippingUpdate.seller_awb)}</span>
              </FieldValue>
            </FieldColumn>
            <FieldColumn className="col-xs-12 col-md-6">
              <FieldLabel>KA Courier</FieldLabel>
              <FieldValue>
                <span>{_getValue(shippingUpdate && shippingUpdate.ka_courier)}</span>
              </FieldValue>
            </FieldColumn>
            <FieldColumn className="col-xs-12 col-md-6">
              <FieldLabel>KA AWB Numb.</FieldLabel>
              <FieldValue>
                <span>{_getValue(shippingUpdate && shippingUpdate.ka_awb)}</span>
              </FieldValue>
            </FieldColumn>
            <FieldColumn className="col-xs-12 col-md-6">
              <FieldLabel>Delivered at</FieldLabel>
              <FieldValue>
                <span>{_getValue(shippingUpdate && shippingUpdate.delivered_at)}</span>
              </FieldValue>
            </FieldColumn>
            <FieldColumn className="col-xs-12 col-md-6">
              <FieldLabel>ETA</FieldLabel>
              <FieldValue>
                <span>{_getValue(shippingUpdate && shippingUpdate.eta+' days')}</span>
              </FieldValue>
            </FieldColumn>
            <FieldColumn className="col-xs-12 col-md-12">
              <FieldLabel>Delivery Status  {renderShippingStatus(shippingUpdate && shippingUpdate.status)}</FieldLabel>
              <p>Buyer Shipping {shippingUpdate && shippingUpdate.buyer_receiver? (<span style={{fontSize: '.65rem'}}>(Penerima: {shippingUpdate && shippingUpdate.buyer_receiver})</span>):null}</p>
              {
                buyerHistory.length?
                <ul>
                  {buyerHistory && buyerHistory.map((item, i) => (
                    <li key={i}>
                      <span>{item.description.indexOf(' ') <= 2 ? item.location : item.description}</span>
                      <p>{item.logged_at}</p>
                    </li>
                  ))}
                </ul>
                :
                <Alert color="danger" className="text-center">No Tracking Data Found</Alert>
              }
              <hr/>
              <p>Seller Shipping</p>
              {
                sellerHistory.length? (
                  <ul>
                    {sellerHistory && sellerHistory.map((item, i) => (
                      <li key={i}>
                        <span>{item.description.indexOf(' ') <= 2 ? item.location : item.description}</span>
                        <p>{item.logged_at}</p>
                      </li>
                    ))}
                  </ul>
                ):
                <Alert color="danger" className="text-center">No Tracking Data Found</Alert>
              }
            </FieldColumn>
          </FieldRow>
        </Loading>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>Close</Button>
      </ModalFooter>
    </Modal>
  )
}

export default ModalTrackingShipment;

ModalTrackingShipment.propTypes = {
  toggle: propTypes.func,
  isOpen: propTypes.bool,
  shippingUpdate: propTypes.object
}

ModalTrackingShipment.defaultProps = {
  isOpen: false,
}