import {createUploadFileForm} from "../../../../utils/imageHelper";
import {uploadImageNoRedux} from "../../../../actions/imageUploadAction";

export function useUploadImageS3() {
    const upload = async (name, file) => {
        const tempPayload = createUploadFileForm(file.fileInput, false, { name, bucket_type: "assets" });

        return uploadImageNoRedux(tempPayload).then(res => res?.data?.data);
    };

    return {
        upload,
    }
}
