import React, { Component } from 'react';
import propTypes from 'prop-types';
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
    Input,
    Row,
    Col,
    FormGroup
} from 'reactstrap';
import Loading from 'react-loading-animation';
import { genderSizes } from '../../../constants/kickavenue';
import Size, { SizeList } from '../Size';

export default class ModalAttachSize extends Component{
    constructor(props){
        super(props);
        this.state={
            selectedBrand:'',
            selectedGender: 'M',
            selectedChild:'',
            sizeList: [],
            brandList: [],
            childBrandOptions:[]
        }
        this.onClickSelectAll = this.onClickSelectAll.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleBrandChange = this.handleBrandChange.bind(this);
        this.handleTogle = this.handleTogle.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    onClickSelectAll(e){
      e.preventDefault();
      const { sizeList } = this.state;
      if(sizeList.length){
        const hasGroup = sizeList[0].items !== undefined;
        if(hasGroup){
          this.setState({
            sizeList: sizeList.map(sizeGroup => ({
              ...sizeGroup,
              items: sizeGroup.items.map(size => ({
                ...size,
                taken: true
              }))
            }))
          })
        }
        else{
          this.setState({ sizeList: sizeList.map(s=>({...s,taken:true})) })
        }
      }
    }

    componentDidUpdate(prevProps){
      if(!prevProps.isOpen&&this.props.isOpen){
        // const {  } = this.props;
        const { selectedBrand, selectedGender } = this.state;
        if(selectedBrand !== ''){
          this.props.onFiltered({brand_id: selectedBrand, sex: selectedGender})
        }
        let brands = this.props.brands.sort((a, b) => {
          let fa = a.label.toLowerCase(),
              fb = b.label.toLowerCase();

          if (fa < fb) {
              return -1;
          }
          if (fa > fb) {
              return 1;
          }
          return 0;
        })
        this.setState({ brandList: brands })
      }
      if(prevProps.isLoading&&!this.props.isLoading){
        const { selectedBrand, selectedChild, sizeList } = this.state;
        if(selectedBrand !== '' && selectedChild !=='')
          this.setState({
            sizeList:[
              ...sizeList.filter(item => item.brand.id == selectedBrand),
              ...this.props.dataSizes
            ],
          });
        else
          this.setState({sizeList:[...this.props.dataSizes]});
      }
    }

    handleChange(e){
        e.preventDefault();
        const lastState={...this.state};
        lastState[e.target.name]=e.target.value;
        this.setState({...lastState},()=>{
            this.props.onFiltered({
              brand_id:this.state.selectedChild ? this.state.selectedChild : this.state.selectedBrand,
              sex:this.state.selectedGender
            })
        })
    }

    async handleBrandChange(e){
      e.preventDefault();
      const control = e.target.name;
      await this.setState({
        [control]: e.target.value,
        selectedChild: ''
      })
      if(control === 'selectedBrand')
        this.props.onRootBrandChanged(this.state.selectedBrand)
      this.props.onFiltered({
        brand_id: this.state.selectedBrand,
        sex:this.state.selectedGender
      });
    }

    onSubmit(e){
      e.preventDefault();
      const { sizeList } = this.state;
      let selected_sizes = [];
      if(sizeList.length){
        const hasGroup = sizeList[0].items !== undefined;
        if(hasGroup){
          selected_sizes = sizeList.reduce((arr, sizeGroup)=>{
            const sizes = sizeGroup.items.filter(size => size.taken && !size.disabled && size.sourceClick !== null);
            return [
              ...arr.filter(add => !sizes.map(size => size.id).includes(add.id)),
              ...sizes
            ]
          }, []);
        }
        else{
          selected_sizes = sizeList.filter(item => item.taken && !item.disabled);
        }
      }
      this.props.onSubmit(selected_sizes);
    }

    handleTogle(){
      this.setState({
        ...this.state,
        selectedBrand:'',
        selectedGender: 'M',
        selectedChild:'',
        sizeList: []
      }, ()=>{
        this.props.toggle();
      });
    }

    handleCheckedChangeItem(e, index, item, hasGroup=false){
      const sizeList = [...this.state.sizeList];
      if(hasGroup){
        this.setState({
          sizeList: sizeList.map(sizeGroup => ({
            ...sizeGroup,
            items: sizeGroup.items.map(size=> {
              if(size.id == item.id){
                return {
                  ...size,
                  sourceClick: item.alias === size.alias? item.alias: null,
                  taken: e.target.checked
                }
              }
              return { ...size };
            })
          }))
        })
        return;
      }
      sizeList[index].taken = e.target.checked;
      this.setState({
        sizeList
      })
    }

    _renderSizesItem(){
      const { sizeList } = this.state;
      if(sizeList.length){
        const hasGroup = sizeList[0].items !== undefined;
        if(hasGroup){
          return(
            this.state.sizeList.map((sizeGroup, grpKey) => (
              <SizeList key={grpKey}>
                <p>{sizeGroup.brand.name} - {sizeGroup.group}</p>
                <Row>
                  {
                    sizeGroup.items.map((item, key)=>(
                      <Size
                        {...item}
                        key={key}
                        onCheckedChange={(e)=>this.handleCheckedChangeItem(e, key,item, hasGroup)}
                      />
                    ))
                  }
                </Row>
              </SizeList>
            ))
          )
        }
        else{
          return(
            <SizeList>
              <Row>
                {
                  sizeList.map((item, key)=>(
                    <Size
                      {...item}
                      key={key}
                      onCheckedChange={(e)=>this.handleCheckedChangeItem(e, key, item, hasGroup)}
                    />
                  ))
                }
              </Row>
            </SizeList>
          )
        }
      }
      return null;
    }

    render(){
        return(
            <Modal isOpen={this.props.isOpen} toggle={this.handleTogle} size={this.props.size}>
                <ModalHeader toggle={this.handleTogle}>Attach Sizes</ModalHeader>
                <ModalBody>
                    <p>
                        Pick sizes for {this.props.productName}, we suggest pick brand based on your product.
                    </p>
                    <Row>
                        <Col xs={12} md={6}>
                            <FormGroup>
                                <Input
                                    type="select"
                                    name="selectedBrand"
                                    value={this.state.selectedBrand}
                                    onChange={this.handleBrandChange}
                                >
                                    <option>Pilih brands</option>
                                    {
                                        this.state.brandList.map((fb)=>(<option key={fb.value} value={fb.value}>{fb.label}</option>))
                                    }
                                </Input>
                            </FormGroup>
                        </Col>
                        <Col xs={12} md={6}>
                            <FormGroup>
                                <Input
                                    type="select"
                                    name="selectedGender"
                                    value={this.state.selectedGender}
                                    onChange={this.handleChange}
                                >
                                    {
                                        genderSizes.map((gender, index) => <option key={index} value={gender.value}>{gender.label}</option>)
                                    }
                                </Input>
                            </FormGroup>
                        </Col>
                        {
                          this.props.categoryName!=='sneakers'?
                          <Col xs={12} md={6}>
                            <FormGroup>
                              <Input
                                type="select"
                                name="selectedChild"
                                value={this.state.selectedChild}
                                onChange={this.handleChange}
                              >
                              <option>Pilih Child brands</option>
                              {
                                this.props.childBrands.map((fb)=>(<option key={fb.value} value={fb.value}>{fb.label}</option>))
                              }
                              </Input>
                            </FormGroup>
                          </Col>
                          :
                          null
                        }
                        <Col xs={12} md={6}>
                          <Button block onClick={this.onClickSelectAll} style={{marginBottom:15}}>Select All</Button>
                        </Col>
                    </Row>
                    <Loading isLoading={this.props.isLoading}>
                      <div style={{maxHeight:300, overflow:'auto'}}>
                        { this._renderSizesItem() }
                      </div>
                    </Loading>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={this.onSubmit}>Save</Button>{' '}
                    <Button color="secondary" onClick={this.handleTogle}>Cancel</Button>
                </ModalFooter>
            </Modal>
        )
    }
}
ModalAttachSize.propTypes = {
    isOpen: propTypes.bool.isRequired,
    toggle: propTypes.func.isRequired,
    size: propTypes.oneOf(['lg','md','sm']),
    brands: propTypes.array,
    dataSizes: propTypes.array,
    isLoading: propTypes.bool,
    onSubmit: propTypes.func.isRequired,
    onFiltered: propTypes.func,
    productName: propTypes.string,
    categoryName: propTypes.string
}
ModalAttachSize.defaultProps = {
    size: 'md'
}
