import {
	AUTH_LOGIN,
	AUTH_LOGOUT,
	AUTH_PROFILES,
	AUTH_FETCH,
	AUTH_LOGIN_ERROR,
	AUTH_TOKEN_KEY,

	AUTH_CHANGE_PASSWORD,
	AUTH_CHANGE_PASSWORD_ERROR,
	AUTH_CHANGE_PASSWORD_SUBMIT,

	AUTH_UPDATE_PROFILE,
	AUTH_UPDATE_PROFILE_ERROR,
	AUTH_UPDATE_PROFILE_SUBMIT,

	AUTH_EXTEND_TKN,
	// AUTH_EXTEND_TKN_ERROR,
	AUTH_EXTEND_TKN_SUBMIT,
	AUTH_ACCESS_VALIDATED,
	API_BASE_URL
} from '../constants';
import { availableRoles } from '../config';
import { checkAuthorized, manipulateErrors,jwtDecode,jwtPayloads,InvalidRoleException } from '../utils';
import api from '../utils/Api';
import { httpRegex } from '../helpers/regex'
import moment from 'moment-timezone';

// maxDiff in minutes 24*60*3 = 3 hari
export const validateToken = (maxDiff=4320) =>(
	dispatch=>{
		const _token = localStorage.getItem(AUTH_TOKEN_KEY)
		moment.tz('Asia/Jakarta');
		if(_token){
			const { iss, exp } = JSON.parse(jwtDecode(jwtPayloads(_token)));
			const issued = iss.replace(httpRegex, '');
			const serverUrl = API_BASE_URL.replace(httpRegex, '');
			// const now = moment().add(7, 'days');
			const now = moment();
			const expJwt = moment.unix(exp);
			// console.log(expJwt.format());
			const diff = expJwt.diff(now,'minutes');
			// console.log(diff);
			if(!~issued.indexOf(serverUrl)){
				dispatch({
					type: AUTH_ACCESS_VALIDATED,
					payload: {
						isValid: false,
						status_code: 401,
						reasonMsg: 'Invalid token'
					}
				});
			}
			// else if(maxDiff >= diff && diff>0){
			// 	dispatch({
			// 		type: AUTH_ACCESS_VALIDATED,
			// 		payload: {
			// 			isValid: false,
			// 			status_code: 403,
			// 			reasonMsg: 'Need extend token'
			// 		}
			// 	});
			// }
			else if(diff<=0){
				dispatch({
					type: AUTH_ACCESS_VALIDATED,
					payload: {
						isValid: false,
						status_code: 401,
						reasonMsg: 'Reauthentication needed'
					}
				});
			}
			else
				dispatch({
					type: AUTH_ACCESS_VALIDATED,
					payload: true
				})
		}
		else{
			dispatch({
				type: AUTH_ACCESS_VALIDATED,
				payload: {
					isValid: false,
					status_code: 401,
					reasonMsg: 'Token is required'
				}
			})
		}
	}

)

export const extendToken = () => (
	dispatch => (
		dispatch({ type: AUTH_EXTEND_TKN_SUBMIT, payload:true }),api({
			Authorization:'Bearer '+localStorage.getItem(AUTH_TOKEN_KEY)
		}).get('/auth/token/extend')
		.then(res => {
			const decodes=JSON.parse(jwtDecode(jwtPayloads(res.data.data.token))).roles;
			// console.log(decodes);
			const validatedRoles=decodes.split(',').filter(role=>availableRoles.filter(arole=>arole==role).length);
			if(validatedRoles.length){
				dispatch({type: AUTH_EXTEND_TKN, payload: res.data});
			}
			else
				throw new InvalidRoleException('Sorry you\'re unauthorized.!');
			dispatch({ type: AUTH_EXTEND_TKN_SUBMIT, payload:false })
		})
		.catch(err => {
			dispatch(onFetchError(err))
			dispatch({ type: AUTH_EXTEND_TKN_SUBMIT, payload:false })
		})
	)
)

export const loginUser=(payload)=>(
	dispatch=>(
		dispatch(isFetch(true)),
		api().post('/auth',payload)
		.then(res => {
			const decodes=JSON.parse(jwtDecode(jwtPayloads(res.data.data.token))).roles;
			// console.log(decodes);
			const validatedRoles=decodes.split(',').filter(role=>availableRoles.includes(role));
			if(validatedRoles.length){
				dispatch(onLoginResult(res.data));
			}
			else
				throw new InvalidRoleException('Sorry you\'re unauthorized.!');
			dispatch(isFetch(false))
		})
		.catch(err => {
			// console.log('error unautorized',err);
			dispatch(onFetchError(err))
			dispatch(isFetch(false))
		})
	)
)
export const getProfile=() =>{
	return dispatch=>(
		dispatch(isFetch(true)),
		api({
			Authorization:'Bearer '+localStorage.getItem(AUTH_TOKEN_KEY)
		})
		.get('/auth')
		.then((res) => {
			dispatch(onFetchProfileResult(res.data.data))
			dispatch(isFetch(false))
		})
		.catch((err) => {
			dispatch(onFetchError(err))
			dispatch(isFetch(false))
		})
	)
}

export const doUpdateProfile = (payload) => (
	dispatch=>(
		dispatch(onStart(AUTH_UPDATE_PROFILE_SUBMIT,true)),
		api({
			Authorization:'Bearer '+localStorage.getItem(AUTH_TOKEN_KEY)
		})
		.put('/auth/update', payload)
		.then(res=>{
			dispatch(onPutUpdateProfile(res.data))
			dispatch(onStart(AUTH_UPDATE_PROFILE_SUBMIT, false))
		})
		.catch((err) => {
			dispatch(onErrorResult(AUTH_UPDATE_PROFILE_ERROR, err))
			dispatch(onStart(AUTH_UPDATE_PROFILE_SUBMIT, false))
		})
	)
)

export const changePassword = (payload)=>(
	dispatch=>(
		dispatch(onStart(AUTH_CHANGE_PASSWORD_SUBMIT,true)),
		api({
			Authorization:'Bearer '+localStorage.getItem(AUTH_TOKEN_KEY)
		})
		.put('/auth/password/change', payload)
		.then(res=>{
			dispatch(onPutChangePasswordResult(res.data))
			dispatch(onStart(AUTH_CHANGE_PASSWORD_SUBMIT, false))
		})
		.catch((err) => {
			dispatch(onErrorResult(AUTH_CHANGE_PASSWORD_ERROR, err))
			dispatch(onStart(AUTH_CHANGE_PASSWORD_SUBMIT, false))
		})
	)
)
const onPutChangePasswordResult =  payload => ({
	type: AUTH_CHANGE_PASSWORD,
	payload
})

const onPutUpdateProfile =  payload => ({
	type: AUTH_UPDATE_PROFILE,
	payload
})
// On Login Result / Success
export const onLoginResult=(payload)=>{
	return {
		type:AUTH_LOGIN,
		payload
	}
}

const onErrorResult = (type,err) =>{
	if(!checkAuthorized(manipulateErrors(err)))
		return logout();
	return {
		type: type,
		payload:manipulateErrors(err)
	}
}

const onStart = (type, payload) => ({
	type,
	payload
})

// On Login Error fetching api
export const onFetchError=(err, type = AUTH_LOGIN_ERROR)=>{
	if(!checkAuthorized(manipulateErrors(err)))
		return logout();
	return {
		type,
		payload:manipulateErrors(err)
	}
}

// Action when logout
export const logout=()=>{
	return {
		type:AUTH_LOGOUT,
		payload:{isLogged:false}
	}
}

// Indicates when start fetch api or end fetch api
export const isFetch=(bool)=>{
	return {
		type:AUTH_FETCH,
		payload:bool

	}
}

export const onFetchProfileResult=(user)=>{
	return {
		type:AUTH_PROFILES,
		payload:user
	}
}