import api from "../../../utils/Api";
import { AUTH_TOKEN_KEY, AUTH_USER_KEY } from "../../../constants";
import { useEffect, useState } from "react";
import { authorization } from "../../../utils/authorization";
import { checkAuthorized, jwtDecode, manipulateErrors } from "../../../utils";
import { logout } from "../../../actions/authAction";

const getRoles = (userJwtPayload) => {
    if (userJwtPayload) {
        const { roles } = JSON.parse(jwtDecode(userJwtPayload));
        return roles ? roles.split(',') : [];
    }
    return [];
}

export const useFetchProfile = () => {
    const [isFetch, setIsFetch] = useState(false);
    const [error, setError] = useState({ error: { message: 'I got something undefined errors', status_code: 500 } });
    const [roles, setRoles] = useState(getRoles(localStorage.getItem(AUTH_USER_KEY)));
    const [authRoutes] = useState(authorization(roles));
    const [email, setEmail] = useState('');
    const [user, setUser] = useState({});
    const [id, setId] = useState({});

    const fetchProfile = () => {
        setIsFetch(true);
        api({
            Authorization: 'Bearer ' + localStorage.getItem(AUTH_TOKEN_KEY)
        })
            .get('/auth')
            .then((res) => {
                setIsFetch(false);
                setEmail(res.data.data.email);
                setUser(res.data.data);
                setRoles(res.data.data.roles);
                setId(res.data.data.id)
            })
            .catch((err) => {
                setIsFetch(false);
                if (!checkAuthorized(manipulateErrors(err))) {
                    return logout();
                }
                setError(manipulateErrors(err));
            })
    };

    useEffect(fetchProfile, []);

    return { isFetch, error, roles, authRoutes, email, fetchProfile, user, id };
}
