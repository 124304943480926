import React from 'react';
import {render} from 'react-dom';
// import  registerServiceWorker  from './registerServiceWorker';

import {Provider} from 'react-redux';
import store from './store';
import AppRoute from './AppRoute';
// import * as serviceWorker from './serviceWorker';

console.log(process.env.REACT_APP_ENV_STAGE); //eslint-disable-line

render((
	<Provider store={store}>
		<AppRoute/>
	</Provider>
), document.getElementById('root'))

// registerServiceWorker();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
