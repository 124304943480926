import React, { PureComponent as Component } from 'react';
import propTypes from 'prop-types';
import { CardHeader } from 'reactstrap';

export default class CardHeaderWithToolbars extends Component{
  render(){
    return(
      <CardHeader className="with-toolbars">
        { this.props.children }
      </CardHeader>
    )
  }
}

CardHeaderWithToolbars.propTypes={
  children: propTypes.node
}