import React from 'react';
import propTypes from 'prop-types';
import { Card } from 'reactstrap';
import Loading from 'react-loading-animation';
const rootRoles = ['superadministrator', 'administrator'];
const privateView = (ViewComponent, restrictedRoles=rootRoles) => {
  return class extends React.Component {
    constructor(props){
      super(props);
      this.state = {
        granted: null,
      }
    }

    isGranted({roles}){
      // console.log('ROLENYAAA ', roles);
      const isRootContains = restrictedRoles.filter(restricted => (restricted == 'superadministrator' || restricted == 'administrator')).length > 0
      const availableRoles = isRootContains ? restrictedRoles : [ ...rootRoles, ...restrictedRoles ];
      // console.log('Available Roles ',availableRoles);
      if(roles && roles.length){
        return roles.filter(userRole => {
          return availableRoles.filter(role=>role===userRole).length
        }).length > 0;
      }
      return false;
    }

    componentWillReceiveProps(nextProps){
      // console.log('MASUCK WAW ', nextProps.guardData.isFetch);
      if(nextProps.guardData.isFetch===false && nextProps.guardData.isFetch!==this.props.guardData.isFetch && nextProps.guardData.isLogged===true){
        // console.log('MASUCK WAW ', nextProps.guardData.roles);
        if(nextProps.guardData.roles){
          // console.log('GRANTED WAW ');
          const granted = this.isGranted(nextProps.guardData)
          // console.log('GRANTED ',granted);
          this.setState({
            granted
          })
        }
      }
    }

    componentDidMount(){
      // console.log('did mounting');
      if(!this.props.guardData.isFetch){
        const granted = this.isGranted(this.props.guardData)
        this.setState({
          granted
        })
      }
      // console.log('GRANTED ');
    }

    render(){
      const { guardData, ...viewProps } = this.props;
      // console.log('AUTH OYY ', this.props);
      if(guardData.isFetch ||  this.state.granted === null)
        return (
          <Card body>
            <Loading/>
            <h5 style={{marginTop:'1.5rem'}} className="text-center">Holding on, we're setting up your arena.</h5>
          </Card>
        )
      return (
        <div className="animated fadeIn">
          { 
            this.state.granted? <ViewComponent injectedProps={{...guardData}} {...viewProps}/>: 
            <Card body>
              <div className="d-flex" style={{flexDirection:'column',alignItems:'center'}}>
                <div style={{flex:1, padding:'1rem'}}>
                  <i className="fa fa-4x fa-exclamation-triangle text-danger"></i>
                </div>
                <div style={{flex:1}}>
                  <h5 className="text-center">Sorry, you were unable to access this page.</h5>
                  <p>Please contact our staff to access this page.</p>
                </div>
              </div>            
            </Card>
          }
        </div>
      )
    }
  }
}

export default privateView;

privateView.propTypes={
  guardData: propTypes.shape({
    isFetch: propTypes.bool,
    roles: propTypes.arrayOf(propTypes.string),
    isLogged: propTypes.bool
  }),
  history: propTypes.object.isRequired,
  location: propTypes.object.isRequired
}

privateView.defaultProps = {
  guardData: {
    isFetch: false,
    isLogged: false
  }
}