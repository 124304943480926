import firebase from 'firebase/app';
import 'firebase/analytics';

const firebaseConfig = {
  apiKey: "AIzaSyCnl0dk-zBZzPUjo_5yfwzKrmNypLEJawo",
    authDomain: "kickavenue-1517894696142.firebaseapp.com",
    databaseURL: "https://kickavenue-1517894696142.firebaseio.com",
    projectId: "kickavenue-1517894696142",
    storageBucket: "kickavenue-1517894696142.appspot.com",
    messagingSenderId: "594858415021",
    appId: "1:594858415021:web:22f94f76e28c4a45684724",
    measurementId: "G-981MWXXBFD"
};

const fire = firebase.initializeApp(firebaseConfig)
const analytics = firebase.analytics()
export default analytics