import React, { PureComponent as Component } from 'react';
import propTypes from 'prop-types';
import {
  Card,
  CardHeader,
  CardBody,
  CardImg,
  CardSubtitle,
  Button
} from 'reactstrap'
import Loading from 'react-loading-animation';
import noImage from '../../../assets/img/no-image.png';
import cx from 'classnames';
import {
  FieldRow,
  FieldColumn,
  FieldLabel,
  FieldValue,
} from '../../../styled/Fields';
import { _getValue } from '../../../utils/form';
import { getCacheImage } from '../../../utils/imageHelper'
import ModalEditKaSent from './Modals/ModalEditKaSent';
import styled from 'styled-components';
import ModalSaleLogs from './Modals/ModalSaleLogs';
import { isLegitApproved } from './GeneralInfo.utils';
import OverflownText from '../../OverflownText/OverflownText';
import Styles from './GeneralInfo.module.css';
const ButtonWrapperRight = styled.div`
  display: flex;
  flex:1;
  flex-direction: column;
  justify-content: center;
  padding:0 1rem;
`

export default class GeneralInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      _modalOpen: false,
      _isViewLogsModalOpen: false
    }

    this.toggleModalKaSent = this.toggleModalKaSent.bind(this)
  }

  toggleModalViewLog() {
    this.setState({ _isViewLogsModalOpen: !this.state._isViewLogsModalOpen })
  }

  toggleModalKaSent() {
    this.setState({ _modalOpen: !this.state._modalOpen })
  }

  renderVerifiedAt(legitCheckLogIndex) {
    const legit_check_logs = this.props?.order?.legit_check_logs;
    const legit_check_status = this.props?.order?.legit_check_status;
    const legitApproved = isLegitApproved(
      legit_check_logs,
      legit_check_status,
      legitCheckLogIndex
    )
    return (
      <FieldLabel>
        Verified At{" "}
        <i
          className={cx(Styles["approval-status-icon"], {
            [`${Styles["green"]} fa fa-check`]: legitApproved,
            [`${Styles["red"]} fa fa-remove`]: !legitApproved,
          })}
        ></i>
      </FieldLabel>
    );
  }

  render(){
    const { legit_check_logs } = this.props.order;
    const content = !this.props.isLoading ? (
      <FieldRow>
        <FieldColumn className="col-xs-12 col-6">
          <FieldLabel>ID</FieldLabel>
          <FieldValue>
            <span>{_getValue(this.props.order.id)}</span>
          </FieldValue>
        </FieldColumn>
        <ButtonWrapperRight>
          <div style={{display: 'flex', justifyContent: 'flex-start'}}>
            <Button onClick={() => this.toggleModalViewLog()}>View Logs</Button>
          </div>
        </ButtonWrapperRight>
        <FieldColumn className="col-12 col-md-6">
          <FieldLabel>Consignment ID</FieldLabel>
          <FieldValue>
            <span>{_getValue(this.props.order.consignment_number)}</span>
          </FieldValue>
        </FieldColumn>
        <FieldColumn className="col-12 col-md-6">
          <FieldLabel>Rack</FieldLabel>
          <FieldValue>
            <span>{_getValue(this.props.order.rack_name)}</span>
          </FieldValue>
        </FieldColumn>
        <FieldColumn className="col-12">
          <FieldLabel>Request Date</FieldLabel>
          <FieldValue>
            <span>{_getValue(this.props.order.created_at)}</span>
          </FieldValue>
        </FieldColumn>
        <FieldColumn className="col-12">
          <FieldLabel>Seller Email</FieldLabel>
          <FieldValue>
            <span>{_getValue(this.props.seller.email)}</span>
          </FieldValue>
        </FieldColumn>
        <FieldColumn className="col-12">
          <FieldLabel>Consignment Price</FieldLabel>
          <FieldValue>
            <span>{_getValue(this.props.order.price)}</span>
          </FieldValue>
        </FieldColumn>
        <FieldColumn className="col-xs-12 col-md-6">
          <FieldLabel>Seller Sent</FieldLabel>
          <FieldValue>
            <span>{_getValue(this.props.order.seller_sent)}</span>
          </FieldValue>
        </FieldColumn>
        <FieldColumn className="col-xs-12 col-md-6">
          <FieldLabel>Receive At</FieldLabel>
          <FieldValue>
            <span>{_getValue(this.props.order.ka_received)}</span>
          </FieldValue>
        </FieldColumn>
        <FieldColumn className="col-xs-12 col-md-6">
          <FieldLabel>Seller Courier</FieldLabel>
          <FieldValue>
            <span>{_getValue(this.props.order.seller_courier)}</span>
          </FieldValue>
        </FieldColumn>
        <FieldColumn className="col-xs-12 col-md-6">
          <FieldLabel>Seller AWB</FieldLabel>
          <FieldValue>
            <span>{_getValue(this.props.order.seller_awb_number)}</span>
          </FieldValue>
        </FieldColumn>
        <hr/>
        <FieldColumn className="col-xs-12 col-md-6">
          <FieldLabel>On Warehouse</FieldLabel>
          <FieldValue>
            <span>{_getValue(this.props.order.ka_received)}</span>
          </FieldValue>
        </FieldColumn>
        <FieldColumn className="col-xs-12 col-md-12">
          <FieldLabel>Receiver Staff</FieldLabel>
          <FieldValue>
            <span>{_getValue(this.props.order.ka_received_by, '-')}</span>
          </FieldValue>
        </FieldColumn>
        {legit_check_logs &&
          legit_check_logs.map((item, index) => {
            return (
              <div key={index} className="col-xs-12 col-md-6">
                <FieldColumn>
                  <FieldLabel>Verified By (LC) {index + 1}</FieldLabel>
                  <FieldValue>
                    <OverflownText id={`${index}`}>
                      {_getValue(item.user.email, "-")}
                    </OverflownText>
                  </FieldValue>
                </FieldColumn>
                <FieldColumn>
                  {this.renderVerifiedAt(index)}
                  <FieldValue>
                    <span>{_getValue(item.created_at)}</span>
                  </FieldValue>
                </FieldColumn>
              </div>
            );
          })}
        <hr/>
        <FieldColumn className="col-xs-12 col-md-6">
          <FieldLabel>Force Cancelled At</FieldLabel>
          <FieldValue>
            <span>{_getValue(this.props.order.force_cancelled_at)}</span>
          </FieldValue>
        </FieldColumn>
        <FieldColumn className="col-xs-12 col-md-6">
          <FieldLabel>Auto Cancelled At</FieldLabel>
          <FieldValue>
            <span>{_getValue(this.props.order.auto_cancelled_at)}</span>
          </FieldValue>
        </FieldColumn>
        <FieldColumn className="col-xs-12 col-md-6">
          <FieldLabel>Disbursed At</FieldLabel>
          <FieldValue>
            <span>{_getValue(this.props.order.money_disbursed)}</span>
          </FieldValue>
        </FieldColumn>
      </FieldRow>
    ) : <Loading/>;
    const imageUrl = this.props.productConsignment && this.props.productConsignment.product_variant_images && this.props.productConsignment.product_variant_images[0] && this.props.productConsignment.product_variant_images[0].URL;
    const getImage = (!this.props.isLoading && getCacheImage(imageUrl)) || noImage;
    return (      
      <>
      <ModalEditKaSent
        id={this.props.order.id}
        deliveredAt={this.props.order.ka_sent}
        isOpen={this.state._modalOpen}
        toggle={this.toggleModalKaSent}
      />
      <ModalSaleLogs
        isOpen={this.state._isViewLogsModalOpen}
        toggle={() => this.setState({_isViewLogsModalOpen: !this.state._isViewLogsModalOpen})}
        isLoading={this.props.isLoading}
        data={this.props.order.logs}
        rollback={this.props.order.rollback}
        total={1}
      />
      <Card>
        <CardHeader>
          {this.props.headerText}
        </CardHeader>
        <CardImg top width="100%" src={ getImage } alt="Card image cap" />
        <CardBody>
          <CardSubtitle style={{marginBottom:15}}>{this.props.order?.product_variant?.display_name}</CardSubtitle>
          {content}
        </CardBody>
      </Card>
      </>
    );
  }
}
GeneralInfo.propTypes = {
  headerText: propTypes.oneOfType([propTypes.string, propTypes.node]),
  isLoading: propTypes.bool,
  order: propTypes.object,
  product: propTypes.object,
  seller: propTypes.object
}
GeneralInfo.defaultProps = {
  isLoading: false,  
  headerText: "General",
  order: {},
  product: {},
  seller: {}
}