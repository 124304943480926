import React from 'react';
import propTypes from 'prop-types';
import classNames from 'classnames';
import styled from 'styled-components';
import ReactLoading from 'react-loading';

const LoadingContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(255,255,255,0.7)
`
const LoadingPlaceholder = ({ isVisible, loadingText, loadingHeight, loadingWidth, loadingType, loadingColor}) => (
  <LoadingContainer className={classNames({'d-none': !isVisible})}>
    <div className="loading-wrapper loading-wrapper--flex">
      <ReactLoading type={loadingType} color={loadingColor} height={loadingHeight} width={loadingWidth}  className="loading-center"/>
      <p>{loadingText}</p>
    </div>
  </LoadingContainer>
)

LoadingPlaceholder.propTypes = {
  isVisible: propTypes.bool,
  loadingText: propTypes.string,
  loadingHeight: propTypes.number,
  loadingWidth: propTypes.number,
  loadingType: propTypes.string,
  loadingColor: propTypes.string
}

LoadingPlaceholder.defaultProps = {
  isVisible: false,
  loadingColor: 'green',
  loadingHeight: 60,
  loadingWidth: 60,
  loadingType: 'spin'
}

export default LoadingPlaceholder;
