import React from 'react';
import propTypes from 'prop-types';
import {
  Form,
  FormGroup,
  Button,
  Row,
  Col,
  Input,
  Label
} from 'reactstrap';

// import { sellRequestStatusOptions } from '../../../../constants/kickavenue';
import SelectRack from '../../../../components/Form/Select/Async/SelectRack';
import SellerFilterForm from '../../../Sales/Filters/SellerFilterForm';
import { warehouseStockStatusOptions } from '../FilterPanel';

// const filterStatusOptions =[{value:"", label:'All'},...sellRequestStatusOptions];

class FilterForm extends React.Component {
  render(){
    return (
      <Form className="form-filter" onSubmit={this.props.onSubmit}>
        <Row>
          <Col xs={12}>
            <p>Filter By :</p>
            <div className="d-flex" style={{flexDirection: 'column'}}>
              <div style={{padding: "0px 1rem"}}>
                <FormGroup row>
                  <Col xs={12} md={6}>
                    <Label className="mr-sm-3" style={{fontWeight: 700}}>
                      Seller
                    </Label>
                    <div>
                      <SellerFilterForm
                        seller={this.props.seller}
                        onSellerChange={this.props.onSellerChange}
                      />
                    </div>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col xs={12} md={6}>
                    <Label className="mr-sm-3" style={{fontWeight: 700}}>
                      Rack
                    </Label>
                    <div style={{width: "100%"}}>
                      <SelectRack
                        isMulti
                        defaultOptions
                        id={this.props.filterRackId}
                        ref={this.props.filterRackRef}
                        value={this.props.rack}
                        paramsApi={{ limit: 50 }}
                        placeholder="Type and select a rack..."
                        noResultsText="Cannot find rack."
                        loadOptions={this.props.getRackOptions}
                        onSelectSearch={this.props.onSelectSearch}
                        onSelectChange={this.props.handleSelectRack}
                        onSelectOpen={this.props.handleOnSelectRackOpen}
                      />
                    </div>
                  </Col>
                </FormGroup>
              </div>
              <div className="d-flex" style={{padding: "0px 1rem"}}>
                <FormGroup className="d-flex" style={{flexDirection: 'column', alignItems: 'flex-start'}}>
                  <Label className="mb-2" style={{fontWeight: 700}}>
                    Stock Label
                  </Label>
                  <div className="d-flex ml-2 mr-2">
                    <FormGroup check className="mr-2">
                      <Label check>
                        <Input type="radio" name="stock_label" value="" checked={this.props.stock_label === ''} onChange={this.props.onCheckedChange} /> All
                      </Label>
                    </FormGroup>
                    <FormGroup check className="mr-2">
                      <Label check>
                        <Input type="radio" name="stock_label" value="ka_stock" checked={this.props.stock_label === 'ka_stock'} onChange={this.props.onCheckedChange} /> KA Stock
                      </Label>
                    </FormGroup>
                    <FormGroup check className="mr-2">
                      <Label check>
                        <Input type="radio" name="stock_label" value="seller_stock" checked={this.props.stock_label === 'seller_stock'} onChange={this.props.onCheckedChange} /> Seller Stock
                      </Label>
                    </FormGroup>
                  </div>
                </FormGroup>
              </div>
              <div className="d-flex" style={{padding: "0px 1rem"}}>
                <FormGroup className="d-flex" style={{flexDirection: 'column', alignItems: 'flex-start'}}>
                  <Label className="mb-2" style={{fontWeight: 700}}>
                    Stock Condition
                  </Label>
                  <div className="d-flex ml-2 mr-2">
                    <FormGroup check className="mr-2">
                      <Label check>
                        <Input type="radio" name="sneakers_condition" value="" checked={this.props.sneakers_condition === ''} onChange={this.props.onCheckedChange} /> All
                      </Label>
                    </FormGroup>
                    <FormGroup check className="mr-2">
                      <Label check>
                        <Input type="radio" name="sneakers_condition" value="BARU" checked={this.props.sneakers_condition === 'BARU'} onChange={this.props.onCheckedChange} /> Brand New
                      </Label>
                    </FormGroup>
                    <FormGroup check className="mr-2">
                      <Label check>
                        <Input type="radio" name="sneakers_condition" value="STANDARD_99" checked={this.props.sneakers_condition === 'STANDARD_99'} onChange={this.props.onCheckedChange} /> Standard 99%
                      </Label>
                    </FormGroup>
                    <FormGroup check className="mr-2">
                      <Label check>
                        <Input type="radio" name="sneakers_condition" value="BEKAS" checked={this.props.sneakers_condition === 'BEKAS'} onChange={this.props.onCheckedChange} /> Used
                      </Label>
                    </FormGroup>
                  </div>
                </FormGroup>
              </div>
              <div className="d-flex" style={{padding: "0px 1rem"}}>
                <FormGroup className="d-flex" style={{flexDirection: 'column', alignItems: 'flex-start'}}>
                  <Label className="mb-2" style={{fontWeight: 700}}>
                    Stock Label
                  </Label>
                  <div className="d-flex ml-2 mr-2">
                    <FormGroup check className="mr-2">
                      <Label check className="mr-3">
                        <Input
                          type="checkbox"
                          name="status"
                          value="default"
                          checked={this.props.isCheckedStatus('default')}
                          onChange={(e) => this.props.onStatusesInputChange(e)}
                        /> Default
                      </Label>
                    </FormGroup>
                    <FormGroup check className="mr-2">
                      <Label check className="mr-3">
                        <Input
                          type="checkbox"
                          name="status"
                          value="all"
                          checked={this.props.isCheckedStatus('all')}
                          onChange={(e) => this.props.onStatusesInputChange(e)}
                        /> All
                      </Label>
                    </FormGroup>
                    {warehouseStockStatusOptions.map((option, i) => (
                    <Label check className="mr-3" key={i}>
                      <Input
                        type="checkbox"
                        name="status"
                        value={option.value}
                        checked={this.props.isCheckedStatus(option.value)}
                        onChange={(e) => this.props.onStatusesInputChange(e)}
                      /> {option.label}
                    </Label>
                    ))}
                  </div>
                </FormGroup>
              </div>
              <FormGroup style={{alignSelf: 'flex-start', padding: "0px 1rem"}}>
                <Button color="primary">Apply Filter</Button>
              </FormGroup>
            </div>
          </Col>
        </Row>
      </Form>
    )
  }
}
export default FilterForm;

FilterForm.propTypes= {
  sneakers_condition: propTypes.oneOf(['','BARU', 'BEKAS']),
  status: propTypes.string,
  pre_order: propTypes.string,
  onInputChange: propTypes.func.isRequired,
  onSubmit: propTypes.func.isRequired
}

FilterForm.defaultProps= {
  sneakers_condition: '',
  status: '',
  pre_order: ''
}