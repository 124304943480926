import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Card,
  CardBody,
  CardText,
  Button,
  Container
} from 'reactstrap';
import { getRaffleCouponList, deleteRaffleEntry } from '../../../actions/raffleAction';
import { CardHeaderWithToolbars, Title } from '../../../components/Card/CardHeaderWithToolbars';
import { FontAwesomeIcon } from '../../../components/Icons';
import { Toolbars as ToolBarContainer } from '../../../components/Card/CardHeaderWithToolbars';
import { Tbl, TblHead, TblBody } from '../../../components/Tbl';
import Paginations from '../../../components/Paginations';
import PaginationSummaries from '../../../components/Paginations/PaginationSummaries';
import { generateNumber } from '../../../utils';
import DateToHuman from '../../../components/DateToHuman';
import Filters from './raffleCoupon/Filters';
import { DEFAULT_PER_PAGE, DEFAULT_SORT_BY } from '../../../constants/settings';
import ModalCreateCoupon from './raffleCoupon/ModalCreateCoupon';
import ModalCoupon from './raffleCoupon/ModalCoupon';

const sortOptions = [{
  value:"" , label:"Default"
},
{
  value:"updatedAt_desc" , label:"Last Updated"
},
{
  value:"createdAt_desc" , label:"Newest"
},
{
  value:"createdAt_asc" , label:"Older"
}];

const dataHeader=[
	'No.', 'Raffle Code', 'Redeemed', 'Updated', 'Action'
];

const Toolbars = props => (
  <ToolBarContainer>
    <Button
      title="Generate new code"
      className="with-toolbars__toolbars__btn"
      size="sm"
      color="info"
      onClick={props.onGenerateCodeClick}>
      Generate new code
    </Button>
    <Button
      title="Refresh"
      className="with-toolbars__toolbars__btn"
      size="sm"
      color="info"
      onClick={props.onRefreshList}>
      <FontAwesomeIcon iconType="refresh"/>
    </Button>
    <Button
      title="Reset filter"
      className="with-toolbars__toolbars__btn"
      size="sm"
      color="warning"
      onClick={props.onResetFilter}>
      <FontAwesomeIcon iconType="ban"/>
    </Button>
  </ToolBarContainer>
)

const defaultParams = {
  keyword: '',
  sort_by : DEFAULT_SORT_BY,
  // per_page : 1
  per_page : DEFAULT_PER_PAGE
}

class RaffleCouponList extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      _params: {...defaultParams},
      isResetParams: false,
      modalCreateOpen: false,
      modalDetailOpen: false,
      modalDetailData: null
    }
  }

  componentDidMount(){
    if(this.props.raffleId){
      this._getList(this.state._params);
    }
  }

  componentDidUpdate(prevProps){
    if(prevProps.dataRaffle.details.isSubmit && !this.props.dataRaffle.details.isSubmit){
      const { error, data } = this.props.dataRaffle.details;
      if(!error){
        this._handleGoto(1)
        // if(!this.state.modalDetailOpen){
          this.setState({
            modalDetailOpen: true,
            modalDetailData: {
              ...data
            }
          })
        // }
      }
    }
  }


  _getList(rawParams = {}){
    const params = Object.keys(rawParams)
    .filter(key => rawParams[key]!='')
    .reduce((obj, current)=> ({...obj, [current]: rawParams[current]}), []);
    const { raffleId } = this.props;
    this.props.getRaffleCouponList(raffleId, {params});
  }

  _handleRefresh = (e) => {
    e.preventDefault();
    this._refreshList();
  }

  _refreshList = () => {
    const { _page, ..._params } = this.state._params;
    this.setState({
      _params
    },()=>this._getList(this.state._params));
  }

  // _handleToggleFilterPanel = () =>{
  //   this.setState({_filterOpen: !this.state._filterOpen})
  // }

  _handleApplyFilter = p => {
    const { _params } = this.state;
    this.setState({
      _params: {
        ..._params,
        ...p,
        page: 1
      }
    }, () => {
      this._getList(this.state._params)
    })
  }

  _handleResetFilter = (e) =>{
    e.preventDefault();
    this.setState({
      _params:{
        ...defaultParams
      },
      isResetParams: true
    }, () => {
      this.setState({ isResetParams: false })
      this._getList(this.state._params)
    })
  }

	_handleGoto = page =>{
    this.setState({
      _params: {
        ...this.state._params,
        page
      }
    }, ()=> this._getList(this.state._params) );
  }

  render(){
    return (
      <Card>
        <ModalCreateCoupon raffleId={this.props.raffleId} isOpen={this.state.modalCreateOpen} toggle={() => this.setState({ modalCreateOpen: !this.state.modalCreateOpen })}/>
        <ModalCoupon
          raffleId={this.props.raffleId}
          isOpen={this.state.modalDetailOpen}
          toggle={() => this.setState({ modalDetailOpen: !this.state.modalDetailOpen })}
          data={this.state.modalDetailData}
        />
        <CardHeaderWithToolbars>
          <Title>
            <FontAwesomeIcon iconType="align-justify"/>{' '}
            <span>Raffle Coupon List</span>
          </Title>
          <Toolbars
            onRefreshList={this._handleRefresh}
            onResetFilter={this._handleResetFilter}
            onGenerateCodeClick={() => this.setState({ modalCreateOpen: true })}
          />
        </CardHeaderWithToolbars>
        <Container>
          <Filters
            {...this.state._params}
            isResetFields={this.state.isResetParams}
            sortOptions={sortOptions}
            onApplyFilter={this._handleApplyFilter}
          />
        </Container>
        <CardBody>
          <CardText>
            Generated raffle coupon codes that user have been participated.
          </CardText>
          <PaginationSummaries
            from={this.props.dataRaffle.data.from}
            to={this.props.dataRaffle.data.to}
            total={this.props.dataRaffle.data.total}
          />
          <div className="table-responsive" ref={tableRef => this.tableRef = tableRef}>
            <Tbl>
              <TblHead
                dataHeader={dataHeader}
              />
              <TblBody
                isLoad={this.props.dataRaffle.isFetch}
                hasRow={this.props.dataRaffle.status_code===200&&this.props.dataRaffle.data.data.length>0}
                columnCount={dataHeader.length}
                renderChildren={() =>{
                  const { current_page, per_page, data } =  this.props.dataRaffle.data;
                  return data.map((item, key)=> {
                    const rowNumber = generateNumber(per_page,current_page,key);
                    return(
                      <tr key={item.id}>
                        <th className="v-center" scope="row">{ rowNumber }</th>
                        <td className="v-center">
                          <p className="mb-0">{item.raffle_code}</p>
                          <p className="mb-0" style={{fontSize: '.75rem'}}>No. Ref: {item.external_id}</p>
                          <p className="mb-0">
                            {item.user_id?(
                              <Link to={`/users/${item.user_id}`} className="btn btn-link">
                                {item.user.typed_email? item.user.typed_email: item.user.email}
                              </Link>
                            ): <span>{item.email}</span>}
                            <br/>
                            <span style={{fontSize: '.75rem'}}>
                              Qty: {item.quantity}
                            </span>
                          </p>
                        </td>
                        <td className="v-center">
                          {item.redeemed_at?(
                            <DateToHuman  value={item.redeemed_at} id={`tooltip_raffleCoupon_updated_at_${rowNumber}`}/>
                          ): <div style={{fontSize: '.75rem', fontStyle: 'italic'}}>Not redeemed yet.</div>}
                        </td>
                        <td className="v-center">
                          <DateToHuman  value={item.updated_at} id={`tooltip_raffleCoupon_updated_at_${rowNumber}`}/>
                        </td>
                        <td className="v-center">
                          <a
                            className="btn btn-link"
                            href="#"
                            onClick={e => {
                              e.preventDefault();
                              this.setState({
                                modalDetailData: {
                                  ...item
                                },
                                modalDetailOpen: true
                              })
                            }}
                          >
                            Details
                          </a>
                        </td>
                      </tr>
                    )
                  })
                }}
              />
            </Tbl>
          </div>
          { this.props.dataRaffle.status_code===200 && (
            <Paginations
              size="sm"
              lastPage={this.props.dataRaffle.data.last_page}
              perPage={this.props.dataRaffle.data.per_page}
              total={this.props.dataRaffle.data.total}
              maxPage={8}
              path={this.props.dataRaffle.data.path}
              currentPage={this.props.dataRaffle.data.current_page}
              prevPageUrl={this.props.dataRaffle.data.prev_page_url}
              nextPageUrl={this.props.dataRaffle.data.next_page_url}
              handleGotoCallback={this._handleGoto}
              onCurrentPageChanged={() => {
                window.scrollTo({
                  top: this.tableRef.offsetTop+25,
                  behavior: 'smooth'
                })
              }}
            />
          )}
        </CardBody>
      </Card>
    )
  }
}


const mapStateToProps=({ raffle })=>({
  dataRaffle: raffle.coupons,
})
const mapDispatchToProps=(dispatch)=>({
  getRaffleCouponList: (raffleId, queries) => dispatch(getRaffleCouponList(raffleId, queries)),
  deleteRaffleEntry: (raffleId, id) => dispatch(deleteRaffleEntry(raffleId, id))
})

export default connect(mapStateToProps, mapDispatchToProps)(RaffleCouponList)