import React from 'react';
import propTypes from 'prop-types';
import Select from 'react-select';
import api from '../../../../utils/Api';
import { AUTH_TOKEN_KEY } from '../../../../constants';

class SelectRack extends React.Component{
  constructor(props){
    super(props);
    this.loadSelectOptions = this.loadSelectOptions.bind(this);
    this._loadRackOption = this._loadRackOption.bind(this);
  }

  loadSelectOptions(input){
    return this.refs[this.props.id].loadOptions(input);
  }

  _loadRackOption(input){
    const myToken = localStorage.getItem(AUTH_TOKEN_KEY);
    const headers = {
      'Authorization': `Bearer ${myToken}`
    };
    const params = {
      keyword: input,
      no_limit:true,
      // limit:50,
      ...this.props.paramsApi
    }
    return api(headers).get('/admins/sell_racks', { params })
    .then(({ data }) => {
      let filteredData = data.data;
      if(this.props.isAvailable) filteredData = data.data.filter(item => (parseInt(item.user_sells_count) + this.props.sellQty) <= item.max_capacity);
      const racks = filteredData.map(item => ({
        id: item.id,
        name: item.name,
        max: item.max_capacity,
        count: item.user_sells_count
      }));
      if(this.props.onSelectSearch){
        this.props.onSelectSearch(input)
      }
      return { options: racks };
    });
  }

  render(){
    return(
      <Select.Async
        key={this.props.key}
        valueKey="name"
        labelKey="name"
        defaultOptions
        ref={this.props.id}
        loadOptions={this._loadRackOption}
        cacheOptions={this.props.cacheOptions}
        name={this.props.name}
        value={this.props.value}
        placeholder={this.props.placeholder}
        noResultsText={this.props.noResultsText}
        onChange={this.props.onSelectChange}
        onOpen={this.props.onSelectOpen}
        multi={this.props.isMulti}
      />
    )
  }
}

export default SelectRack;

SelectRack.defaultProps = {
  cacheOptions: false,
  isAvailable:false,
  value: null,
  placeholder: 'Select a rack...',
  noResultsText: 'No Result!',
  paramsApi: {},
  isMulti: false,
  sellQty: 0
}

SelectRack.propTypes = {
  name: propTypes.string,
  value: propTypes.oneOfType([propTypes.object, propTypes.array, propTypes.string]),
  placeholder: propTypes.string,
  noResultsText: propTypes.string,
  cacheOptions: propTypes.bool,
  isMulti: propTypes.bool,
  paramsApi: propTypes.object,
  onSelectSearch: propTypes.func,
  onSelectChange: propTypes.func,
  onSelectOpen: propTypes.func,
  sellQty: propTypes.number
}