import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Row,
  Col,
  Card,
  CardBody,
  CardText,
} from 'reactstrap';

import Paginations from '../../../components/Paginations';
import PaginationSummaries from '../../../components/Paginations/PaginationSummaries';
import { CardHeaderWithToolbars,Title } from '../../../components/Card/CardHeaderWithToolbars';
import Toolbars from './Filters/Toolbars';
import FiltersPanel from '../Filters';
import FilterContainer from '../../../components/Container/FilterContainer/FilterContainer';
import {Tbl, TblHead, TblBody} from '../../../components/Tbl';
import ModalLegitCheck from './ModalLegitCheck';
import { ModalInfoCreditCard } from '../../../components/Modals';
import { generateNumber } from '../../../utils';
import { DEFAULT_SORT_BY, DEFAULT_PER_PAGE } from '../../../constants/settings';
import { getSaleList, doLegitPassedSneakerSale, doLegitFailedSneakerSale } from '../../../actions/saleAction';
import privateView from '../../../components/hocs/privateView';
import RowItem from './RowItem';
import RowHeader from './RowHeader';
import ModalPurchasePrice from '../ModalPurchasePrice';
import ModalFollowUp from '../ModalFollowUp';
import Analytics from "../../../services/Analytics";


const defaultParams = {
  sort_by : DEFAULT_SORT_BY,
  per_page : DEFAULT_PER_PAGE,
  type:'verification_process'
}

class ShoeVerification extends Component{
  constructor(props){
    super(props);
    this.state={
      _filterOpen: false,
      _params:{
        ...defaultParams
      },
      _selectedRow: null,
      _isLegitModalOpen: false,
      _formLegitCheck: null,
      _legitProcessSubmit: false,
      _isLegit: false,
      _modalCreditCard: false,
      _invoiceNumber: null,
      _dataModalPurchasePrice: null,
      _isModalPurchasePrice: false,
      _dataModalFollowUp: null,
      _isModalFollowUp: false,
      isDataLoaded: false,
    }

    this._handleApplyFilter = this._handleApplyFilter.bind(this);
    this._handleToggleFilterPanel = this._handleToggleFilterPanel.bind(this);
    this._handleGoto = this._handleGoto.bind(this);
    this._refreshList = this._refreshList.bind(this);
    this._handleRefresh = this._handleRefresh.bind(this);
    this._handleResetFilter = this._handleResetFilter.bind(this);
    // this._onHiddenRejectModal = this._onHiddenRejectModal.bind(this);
    this._handleCategoryChanged = this._handleCategoryChanged.bind(this);
    this._handleToggleModalCreditCard = this._handleToggleModalCreditCard.bind(this);
  }

  componentDidMount(){
    const { email } = this.props.injectedProps;
    this._getList(this.state._params).then(() => {
      this.setState({
        isDataLoaded: true
      })
    });
    Analytics.recordPageView('legit_check_sales', email, {
      url: window.location.href,
      location: this.props.location,
      match: this.props.match
    });
  }
  componentDidUpdate(prevProps){
    if(this.state._legitProcessSubmit && prevProps.sale.isSubmit && !this.props.sale.isSubmit){
      const { detail: { status_code }} = this.props.sale;
      if(status_code === 200){
        this.setState({
          _legitProcessSubmit: false,
          _formLegitCheck: !this.state._formLegitCheck,
          _isLegitModalOpen: !this.state._isLegitModalOpen,
          _selectedRow: null
        }, () => {
          this._getList(this.state._params);
        });
      } else{
        this.setState({ _legitProcessSubmit: false })
      }
    }
  }
  // componentWillReceiveProps(nextProps){
  //   if(this.state._rejectProcess
  //     &&!nextProps.sale.isSubmit
  //     &&this.props.sale.isSubmit!==nextProps.sale.isSubmit
  //   ) {
  //     if(nextProps.sale.detail.status_code===200){
  //       this._getList(this.state._params);
  //       this.setState({
  //         _rejectProcess:!this.state._rejectProcess,
  //         _modalReject:!this.state._modalReject,
  //         _selectedRow: null
  //       });
  //     }
  //     else
  //       this.setState({_rejectProcess:!this.state._rejectProcess});

  //   }
  //   else if(this.state._verifiedProcess
  //     &&!nextProps.sale.isSubmit
  //     &&this.props.sale.isSubmit!==nextProps.sale.isSubmit) {
  //     if(nextProps.sale.detail.status_code===200){
  //       this._getList(this.state._params);
  //     }
  //     this.setState({_verifiedProcess:!this.state._verifiedProcess});
  //   }
  // }

  _getList(rawParams = {}){
    let p = { ...rawParams }
    if(p.voucher){
      const { voucher, ...other } = p;
      if(Number(voucher.id)){
        p = { ...other, voucher_id: voucher.id }
      }
      else{
        p = { ...other, voucher_code: voucher.code }
      }
    }
    if(p.seller && p.seller.id){
      const { seller, ...other } = p;
      p = { ...other, seller_id: seller.id }
    }
    const params = Object.keys(p)
    .filter(key => p[key]!='')
    .reduce((obj, current)=> ({...obj, [current]: p[current]}), {});
    return this.props.getList({params});
  }

  _refreshList(){
    // Exclude page...
    const { page, ..._params } = this.state._params;
    this.setState({
      _params:{ ..._params }
    },()=>this._getList(this.state._params));
  }

  _toggleFilterPanel(){
    this.setState({_filterOpen:!this.state._filterOpen})
  }

  _handleToggleFilterPanel(e){
    e.preventDefault();
    this._toggleFilterPanel();
  }

  _handleRefresh(e){
    e.preventDefault();
    this._refreshList();
  }

  _handleApplyFilter(payloadFilter){
    this.setState({
      _params: {
        ...this.state._params,
        ...payloadFilter,
        page: 1
      }
    },()=>this._getList(this.state._params));
  }

  _handleResetFilter(e){
    e.preventDefault();
    const newState = this.state._filterOpen?{_filterOpen:!this.state._filterOpen}:{};
    this.setState({
      ...newState,
      _params:{ ...defaultParams },
      _isResetFilter: true
    },()=>this._getList(this.state._params));
  }

  _onRefresh(e){
    e.preventDefault();
    this._getList(this.state._params);
  }

  // _onVerified(item){
  //   this.setState({_verifiedProcess:true},()=>{
  //     this.props.legitPassed(item.id, { ka_verified:true });
  //   })
  // }

  // _onReject(id, data){
  //   this.setState({_rejectProcess:true},()=>{
  //     this.props.legitFailed(id, data);
  //   })
  // }

  // _onOpenRejectModal(selected){
  //   this.setState({
  //     _formRejectSelected: selected,
  //     _selectedRow: selected.id
  //   },
  //     ()=>this._toggle()
  //   );
  // }

  // _onHiddenRejectModal(){
  //   this.setState({_formRejectSelected: null, _selectedRow: null},()=>this._toggle());
  // }

  _onOpenCreditCardModal(item){
    this.setState({_invoiceNumber: item.invoice_number},()=>this._handleToggleModalCreditCard());
  }

  _handleToggleModalCreditCard() {
    this.setState({_modalCreditCard:!this.state._modalCreditCard});
  }

  _toggle(){
    this.setState({_modalReject:!this.state._modalReject})
  }

  _onOpenLegitModal = async (selected, legit) =>{
    await this.setState({
      _formLegitCheck: selected,
      _isLegit: legit,
      _selectedRow: selected.id
    });
    this._toggleLegitModal();
  }

  _toggleLegitModal = () => {
    this.setState({_isLegitModalOpen: !this.state._isLegitModalOpen})
  }

  _onHideLegitModal = async() => {
    this.setState({
      _formLegitCheck: null,
      _selectedRow: null
    },()=> this._toggleLegitModal() );
  }

  _onLegitCheckSubmit(id, data){
    const { email } = this.props;
    const { _formLegitCheck } = this.state;
    
    this.setState({ _legitProcessSubmit:true },()=>{
      const { _isLegit } = this.state;
      if(_isLegit){
        this.props.legitPassed(id, { ka_verified:true });
        Analytics.recordLegitCheckPassed(
          email,
          data.notes,
          _formLegitCheck.invoice_number,
          _formLegitCheck.buyer_email,
          _formLegitCheck.price,
        );
      }
      else{
        this.props.legitFailed(id, data);
      }
    })
  }

  _togglePurchasePriceModal(value, payloads = null){
    this.setState({
      _isModalPurchasePrice: value,
      _dataModalPurchasePrice: payloads? { ...payloads }: null
    })
  }

  _toggleFollowUpModal = (value, payloads = null) => {
    this.setState({
      _isModalFollowUp: value,
      _dataModalFollowUp: payloads? { ...payloads }: null
    })
  }

  _onRenderRowItem(){
      if(this.props.sale.list.status_code===200&&this.props.sale.list.data.length>0) {
        const { current_page,per_page } =  this.props.sale.list;
        return this.props.sale.list.data.map((item,index)=>{
          const rowId = generateNumber(per_page,current_page,index);
          return(
            <RowItem
              key={rowId}
              rowId={rowId}
              id={item.id}
              data={item}
              buyerEmail={item.buyer_email}
              quantity={item.offer.quantity}              
              invoiceNumber={item.invoice_number}
              orderDate={item.created_at}
              orderStatus={item.status}
              updatedAt={item.updated_at}
              totalAmount={item.total_price}
              displayName={item.sale_product.display_name}
              SKU={item.sale_product.SKU}
              colour={item.sale_product.colour}
              preOrder={item.sale_product.pre_order}
              preVerified={item.sale_product.pre_verified}
              consignmentId={item.sale_product.consignment_id}
              sneakersCondition={item.sale_product.sneakers_condition}
              category={item.sale_product.category_name}
              size={item.sale_product.size.US}
              hijacked={item.hijacked}
              autoDisburse={item.autodisburse}
              isSelected={this.state._selectedRow === item.id}
              onVerified={()=>this._onOpenLegitModal(item, true)}
              onOpenRejectModal={()=>this._onOpenLegitModal(item, false)}
              bank={item.offer.bank_code}
              paymentVia={item.offer.payment_via}
              paymentMethod={item.offer.payment_method}
              onOpenCreditCard={()=>this._onOpenCreditCardModal(item)}
              status={item.status}
              marked={item.marked}
              notes={item.sale_notes}
              loginId={this.props.injectedProps.id}
              rollback={item.rollback}
              legitCheckLogs={item.legit_check_logs}
              markedAt={item.open_marked_at}
              markedBy={item.open_marked_by}
              closeMarkedAt={item.close_marked_at}
              unreadNoteCount={item.unread_notes_count}
              rack={item.offer.user_sell.rack}
              purchasePrice={item.purchase_price}
              compensation={item.compensation_amount}
              followUpStatus={item.follow_up_status}
              sellerEmail={item.offer.user_sell.user.email}
              adminEmail={this.props.email}
              adminRoles={this.props.roles}
              voucherCode={item.voucher_code? item.voucher_code: item.voucherable? item.voucherable.code: item.voucherable_id}
              openPurchasePriceModal={(payloads) => this._togglePurchasePriceModal(true, payloads)}
              openFollowUpModal={(payloads) => this._toggleFollowUpModal(true, payloads)}
            />
          )
        })
      }
    return null;
  }

  _handleGoto(page){
    const _params=this.state._params;
    _params.page=page;
    this.setState({_params}, ()=>this._getList(this.state._params));
  }

  _onRenderPagination(){
    const { status_code,data } = this.props.sale.list;
    const hasData = (status_code===200 && data.length) || null;
    return hasData && (
      <Paginations
        size="sm"
        lastPage={this.props.sale.list.last_page}
        perPage={this.props.sale.list.per_page}
        total={this.props.sale.list.total}
        maxPage={8}
        path={this.props.sale.list.path}
        currentPage={this.props.sale.list.current_page}
        prevPageUrl={this.props.sale.list.prev_page_url}
        nextPageUrl={this.props.sale.list.next_page_url}
        handleGotoCallback={this._handleGoto}
      />
    );
  }

  async _handleCategoryChanged(category){
    await this.setState({
      _params: {
        ...this.state._params,
        category: category === ''? category: category.value,
        page: 1,
      },
    });
    const { _params } = this.state;
    this._getList(_params);
  }

  render(){
    return (
      <div className="animated fadeIn">
        <Row>
          <ModalFollowUp
            data={this.state._dataModalFollowUp}
            isOpen={this.state._isModalFollowUp}
            toggle={() => this._toggleFollowUpModal(false)}
          />
          <ModalPurchasePrice
            data={this.state._dataModalPurchasePrice}
            isOpen={this.state._isModalPurchasePrice}
            toggle={() => this._togglePurchasePriceModal(false)}
          />
          <ModalLegitCheck
            isLegit={this.state._isLegit}
            isOpen={this.state._isLegitModalOpen}
            onCancel={this._onHideLegitModal}
            onSubmit={(id,form)=>this._onLegitCheckSubmit(id,form)}
            sale={this.state._formLegitCheck}
            isSubmit={this.state._legitProcessSubmit}
            error={this.props.sale.error}
          />
          <ModalInfoCreditCard
            isOpen={this.state._modalCreditCard}
            toggle={this._handleToggleModalCreditCard}
            invoiceNumber={this.state._invoiceNumber}
          />
          <Col xs={12}>
            <Card>
              <CardHeaderWithToolbars>
                <Title>
                  <i className="fa fa-align-justify"></i>
                  <span>Legit Check Available List</span>
                </Title>
                {this.state.isDataLoaded && <Toolbars
                  isOpen={this.state._filterOpen}
                  onToggleFilter={this._handleToggleFilterPanel}
                  onRefreshList={this._handleRefresh}
                  onResetFilter={this._handleResetFilter}
                />}
              </CardHeaderWithToolbars>
              <FilterContainer>
                <FiltersPanel
                  {...this.state._params}
                  onApplyFilter={this._handleApplyFilter}
                  isReset={this.state._isResetFilter}
                  onResetFilterCallback={()=>this.setState({_isResetFilter: false, _filterOpen: false})}
                  isOpen={this.state._filterOpen}
                />
              </FilterContainer>
              <CardBody>
                <CardText>
                  After seller accept the order and send their sneakers to our office, we must to identify the order must be legit or authentics.
                </CardText>
                <div className="table-responsive">
                  <PaginationSummaries
                    from={this.props.sale.list.from}
                    to={this.props.sale.list.to}
                    total={this.props.sale.list.total}
                  />
                  <Tbl>
                    <TblHead>
                      <RowHeader
                        categoryName={this.state._params.category}
                        categoryValue={this.state._params.category}
                        onCategoryChanged={this._handleCategoryChanged}
                      />
                    </TblHead>
                    <TblBody
                      isLoad={this.props.sale.isFetch}
                      hasRow={this.props.sale.list.status_code===200&&this.props.sale.list.data.length>0}
                      columnCount={7}
                    >
                    { this._onRenderRowItem() }
                    </TblBody>
                  </Tbl>
                </div>
                {this._onRenderPagination()}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    )
  }
}

const mapStateToProps= ({sale, auth: {id: authId, isFetch, isLogged, roles, email}}) => {
	return {
    sale,
    email,
    roles,
    guardData: {isFetch, isLogged, roles, id: authId, email}
	}
};

const mapDispatchToProps = (dispatch) => {
	return {
		legitPassed:(id,payload) => dispatch(doLegitPassedSneakerSale(id,payload)),
		legitFailed:(id,payload) => dispatch(doLegitFailedSneakerSale(id,payload)),
		getList:(query) => dispatch(getSaleList(query))
	}
};
const enhance = connect(mapStateToProps,mapDispatchToProps);

export default enhance(privateView(ShoeVerification,['supervisor','sale_supervisor']));