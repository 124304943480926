import React from 'react';
import { Badge } from 'reactstrap';
import classNames from 'classnames';

const BadgeThin = props => {
  const { children, className, ...otherProps} = props;
  return(    
    <Badge 
      className={classNames("badge--thin", className)} 
      {...otherProps}
    >
      {children}
    </Badge>
  )
}
export default BadgeThin;