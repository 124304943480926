import React from 'react';
import { FontAwesomeIcon } from '../../../components/Icons';
import Paragraph from '../../../styled/Typograph/Paragraph';
import SmallInline from '../../../styled/Typograph/Small/SmallInline';
import BadgeThin from '../../../components/Badges/BadgeThin';

export const TitleColumn = props => {
  const { id, name, categories, videoUrl, createdAt } = props;
  return(
    <div>
      <SmallInline className="text-muted mr-1">
        #{id}
      </SmallInline>
      <Paragraph>
        {name}
      </Paragraph>
      <Paragraph>
        <BadgeThin color="success">{videoUrl}</BadgeThin>
      </Paragraph>
      <SmallInline>
        {categories? <span className="mr-1"><BadgeThin>{categories.name}</BadgeThin></span>:null}
        <span className="mr-1">
          <FontAwesomeIcon iconType="calendar" className="mr-1"/>{ createdAt }
        </span>
        <span>
          <a href={`${videoUrl}`} title="Open Link" target="_blank">
            <FontAwesomeIcon iconType="external-link"/> Open
          </a>
        </span>
      </SmallInline>
    </div>
  )
}
