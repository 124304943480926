import React from 'react';
import propTypes from 'prop-types';
import { Tbl, TblHead, TblBody } from '../../../components/Tbl';
import RowItem from './RowItem';
import { generateNumber } from '../../../utils';
import PaginationSummary from '../Components/PaginationSummary';
import api from '../../../utils/Api';
import { AUTH_TOKEN_KEY } from '../../../constants';
class List extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      _data: [],
      rackOptions: []
    };
    this._loadRackOption = this._loadRackOption.bind(this);
    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;
    this._loadRackOption();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentWillReceiveProps(nextProps) {
    if (!nextProps.isLoading && nextProps.isLoading !== this.props.isLoading) {
      this.setState({
        _data: nextProps.data
      }, this._loadRackOption);
    }
  }

  _loadRackOption() {
    const myToken = localStorage.getItem(AUTH_TOKEN_KEY);
    const headers = {
      'Authorization': `Bearer ${myToken}`,
    };
    const params = {
      keyword: '',
      no_limit: true,
      availables: true
    };

    api(headers).get('/admins/sell_racks', { params })
      .then(({ data }) => {
        if (this._isMounted) {  
          let filteredData = data.data;
          const racks = filteredData.map(item => ({
            id: item.id,
            name: item.name,
            max: item.max_capacity,
            count: item.user_sells_count,
          }));

          this.setState({ rackOptions: racks });
        }
      });
  }

  render() {
    return (
      <div className="table-responsive" ref={this.props.innerRefs}>
        <PaginationSummary
          from={this.props.from}
          to={this.props.to}
          total={this.props.total}
          currentPage={this.props.currentPage}
          isOpenPrint={this.props.isOpenPrint}
          setSelectedData={this.props.setSelectedData}
          selectedData={this.props.selectedData}
          data={this.state._data}
        />
        <Tbl>
          <TblHead renderChildren={this.props.onRenderHeader} />
          <TblBody
            isLoad={this.props.isLoading}
            hasRow={this.state._data.length > 0}
            columnCount={7}
            renderChildren={() => {
              if (!this.props.isLoading && this.props.statusCode === 200) {
                const { currentPage, perPage } = this.props;
                return (
                  this.state._data.map((item, index) => {
                    const number = generateNumber(perPage, currentPage, index);
                    const defectsCount = item.defects.filter(item => item.value === true).length;
                    const sneakerDefectCount = [
                      item.yellowing,
                      item.display_item,
                      item.missing_accessories,
                      defectsCount === 0 ? item.sneakers_defect : false
                    ].filter(item => item === true).length;
                    return (
                      <RowItem
                        key={item.id}
                        id={item.id}
                        rowId={number}
                        category={item.product_variant.product.category ? item.product_variant.product.category.name : '-'}
                        productVariantId={item.product_variant_id}
                        displayName={item.product_variant.display_name}
                        SKU={item.product_variant.SKU}
                        colour={item.product_variant.colour}
                        userEmail={item.user.email}
                        qty={item.quantity}
                        sneakersCondition={item.sneakers_condition}
                        askingPrice={item.asking_price}
                        preOrder={item.pre_order}
                        preVerified={item.pre_verified}
                        isExpired={item.status === 'approved' ? item.is_expired : false}
                        size={item.size ? item.size : undefined}
                        status={item.status}
                        isSellerHoliday={item.is_seller_holiday}
                        updatedAt={item.updated_at}
                        approvedAt={item.approved_at}
                        consignmentId={item.consignment_id}
                        rack={item.rack}
                        sellRack={item.sell_racks}
                        defaultRoute={this.props.defaultRoute}
                        defectsCount={defectsCount + sneakerDefectCount}
                        selectedData={this.props.selectedData}
                        onSelectDataFromCheckbox={() => {
                          this.props.onSelectDataFromCheckbox(item);
                        }}
                        isOpenPrint={this.props.isOpenPrint}
                        rackOptions={this.state.rackOptions} // Pass rack options here
                      />
                    )
                  })
                )
              }
              return null;
            }}
          />
        </Tbl>
      </div>
    )
  }
}

export default List;

List.propTypes = {
  from: propTypes.number,
  to: propTypes.number,
  total: propTypes.number,
  currentPage: propTypes.number,
  perPage: propTypes.oneOfType([propTypes.number, propTypes.string]),
  statusCode: propTypes.any,
  defaultRoute: propTypes.string.isRequired,
  isLoading: propTypes.bool
}

List.defaultProps = {
  from: 0,
  to: 0,
  total: 0,
  currentPage: 0,
  perPage: 0
}