import React from 'react';
import propTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  Button,
  ButtonGroup
} from 'reactstrap';
import Paginations from '../../../components/Paginations';
import FontAwesomeIcon from '../../../components/Icons/FontAwesomeIcon';
import PaginationSummaries from '../../../components/Paginations/PaginationSummaries';
import { Tbl, TblHead, TblBody } from '../../../components/Tbl';
import { generateNumber } from '../../../utils';
import RowItem from './processedList/RowItem';
import RowHeader from './processedList/RowHeader';
import ModalAwbNumber from './Modals/ModalAwbNumber';
import ModalDisburseSeller from './Modals/ModalDisburseSeller';
import { ModalInfoCreditCard,  ModalDelete as ModalDoneByAdmin } from '../../../components/Modals';
import classNames from 'classnames';
import ModalPurchasePrice from '../ModalPurchasePrice';
import ModalFollowUp from '../ModalFollowUp';
const SALE_DETAIL_ROUTE = '/sales';

class ProcessedList extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      _list: [],
      _awbNumberModalOpen: false,
      _processedModalOpen: false,
      _modalDoneSale: false,
      _updateAwbNumberIsSubmit: false,
      _completionSaleIsSubmit: false,
      _modalCreditCard: false,
      _invoiceNumber: null,
      _dataModalPurchasePrice: null,
      _isModalPurchasePrice: false,
      _formItemSelected: null,
      _dataModalFollowUp: null,
      _isModalFollowUp: false,
    }
    this._onHideAwbNumberModal = this._onHideAwbNumberModal.bind(this);
    this._handleSubmitUpdateAwbNumber = this._handleSubmitUpdateAwbNumber.bind(this);
    this._onOpenAwbNumberModal = this._onOpenAwbNumberModal.bind(this);
    this._toggleOpenProcessedModal = this._toggleOpenProcessedModal.bind(this);
    this._handleSubmitCompletionSale = this._handleSubmitCompletionSale.bind(this);
    this._handleToggleModalCreditCard = this._handleToggleModalCreditCard.bind(this);
  }

  componentDidUpdate(prevProps){
    if(prevProps.isLoading&&prevProps.isLoading!==this.props.isLoading){
      this.setState({
        _list: this.props.list
      })
    }
    if(this.state._updateAwbNumberIsSubmit){
      if(prevProps.dataUpdatedSale.isSubmit != this.props.dataUpdatedSale.isSubmit
        && prevProps.dataUpdatedSale.isSubmit){
        const { error, detail } = this.props.dataUpdatedSale;
        if(error){
          this.setState({
            _updateAwbNumberIsSubmit: !this.state._updateAwbNumberIsSubmit
          })
        }
        else{
          const { id } = this.state._formItemSelected;
          const _list = this.state._list.map(item=>{
            if(item.id == id){
              const newItem = { ...item };
              newItem.updated_at= detail.updated_at;
              newItem.sale_shipping.awb_number = detail.sale_shipping.awb_number;
              return newItem;
            }
            return { ...item }
          })
          this.setState({
            _updateAwbNumberIsSubmit: !this.state._updateAwbNumberIsSubmit,
            _awbNumberModalOpen: false,
            _formItemSelected: null,
            _list
          })
        }
      }
    }
    else if(this.state._completionSaleIsSubmit){
      if(prevProps.dataUpdatedSale.isSubmit != this.props.dataUpdatedSale.isSubmit
        && prevProps.dataUpdatedSale.isSubmit){
        const { error, detail } = this.props.dataUpdatedSale;
        if(error){
          this.setState({
            _completionSaleIsSubmit: !this.state._completionSaleIsSubmit
          })
        }
        else{
          const { id } = this.state._formItemSelected;
          const _list = this.state._list.map(item=>{
            if(item.id == id){
              const newItem = { ...item };
              newItem.status= detail.status;
              newItem.updated_at= detail.updated_at;
              newItem.money_disbursed = detail.money_disbursed;
              return newItem;
            }
            return { ...item }
          })
          this.setState({
            _completionSaleIsSubmit: !this.state._completionSaleIsSubmit,
            _processedModalOpen: false,
            _modalDoneSale: false,
            _formItemSelected: null,
            _list
          })
        }
      }
    }
  }

  _onRenderPagination(){
    const { _list } = this.state;
    const hasData = (this.props.statusCode===200&&_list.length)||null;
    return hasData && (
      <Paginations
        size="sm"
        lastPage={this.props.lastPage}
        perPage={this.props.perPage}
        total={this.props.total}
        maxPage={8}
        path={this.props.path}
        currentPage={this.props.currentPage}
        prevPageUrl={this.props.prevPageUrl}
        nextPageUrl={this.props.nextPageUrl}
        handleGotoCallback={this.props.handleGotoList}
      />
    )
  }

  _onOpenAwbNumberModal(item){
    this.setState({_formItemSelected: item, _awbNumberModalOpen:true});
  }

  _onHideAwbNumberModal(){
    const { _updateAwbNumberIsSubmit } = { ...this.state };
    if(!_updateAwbNumberIsSubmit){
      this.setState({
        _formItemSelected: null,
        _awbNumberModalOpen:false
      });
    }
  }

  _handleOpenProcessedModal(item){
    this.setState({
      _formItemSelected:item,
      _processedModalOpen: !this.state._processedModalOpen
    });
  }

  _handleOpenDoneSaleModal(item){
    this.setState({
      _formItemSelected:item,
      _modalDoneSale : !this.state._modalDoneSale
    })
  }

  _toggleOpenProcessedModal(){
    const { _completionSaleIsSubmit } = this.state;
    // prevents close modal...
    if(_completionSaleIsSubmit) return;

    if(this.state._processedModalOpen){
      this.setState({
        _processedModalOpen: !this.state._processedModalOpen,
        _formItemSelected: null
      })
    }
    else{

      this.setState({
        _processedModalOpen: !this.state._processedModalOpen
      })
    }
  }

  _handleSubmitCompletionSale(id,payload){
    const { _completionSaleIsSubmit, _formItemSelected } = { ...this.state };
    if(!_completionSaleIsSubmit){
      const saleId = _formItemSelected.id;
      const payloads = payload ? payload : {'status':_formItemSelected.status, 'amount':_formItemSelected.seller_price, 'type': 'seller_disbursement', 'money_disbursed': true}; 
      this.setState({_completionSaleIsSubmit: !_completionSaleIsSubmit}, ()=>{
        this.props.handleCompletionSale(saleId, payloads);
      })
    }
  }
  

  _handleSubmitUpdateAwbNumber(payload){
    const { _formItemSelected, _updateAwbNumberIsSubmit } = { ...this.state };
    if(!_updateAwbNumberIsSubmit){
      this.setState({_updateAwbNumberIsSubmit: !_updateAwbNumberIsSubmit}, ()=>{
        const { id }  = _formItemSelected;
        this.props.handleUpdateAwbNumber(id, payload);
      })
    }
  }

  _onOpenCreditCardModal(item){
    this.setState({_invoiceNumber: item.invoice_number},()=>this._handleToggleModalCreditCard());
  }

  _handleToggleModalCreditCard() {
    this.setState({_modalCreditCard: !this.state._modalCreditCard});
  }

  _togglePurchasePriceModal(value, payloads = null){
    this.setState({
      _isModalPurchasePrice: value,
      _dataModalPurchasePrice: payloads? { ...payloads }: null
    })
  }

  _toggleFollowUpModal = (value, payloads = null) => {
    this.setState({
      _isModalFollowUp: value,
      _dataModalFollowUp: payloads? { ...payloads }: null
    })
  }

  render(){
    return(
      <div className="animated fadeIn">
        <ModalFollowUp
          data={this.state._dataModalFollowUp}
          isOpen={this.state._isModalFollowUp}
          toggle={() => this._toggleFollowUpModal(false)}
        />
        <ModalPurchasePrice
          data={this.state._dataModalPurchasePrice}
          isOpen={this.state._isModalPurchasePrice}
          toggle={() => this._togglePurchasePriceModal(false)}
        />
        <ModalAwbNumber
          sale={this.state._formItemSelected}
          error={this.props.dataUpdatedSale.error}
          isSubmit={this.state._updateAwbNumberIsSubmit}
          isOpen={this.state._awbNumberModalOpen}
          onSubmit={this._handleSubmitUpdateAwbNumber}
          onCancel = {this._onHideAwbNumberModal}
        />
        <ModalDisburseSeller
          isOpen={this.state._processedModalOpen}
          isSubmit={this.props.dataUpdatedSale.isSubmit}
          error={this.props.dataUpdatedSale.error}
          sale={this.state._formItemSelected}
          onCancel={this._toggleOpenProcessedModal}
          onSubmit={this._handleSubmitCompletionSale}
          email={this.props.email}
        />
        <ModalInfoCreditCard
          isOpen={this.state._modalCreditCard}
          toggle={this._handleToggleModalCreditCard}
          invoiceNumber={this.state._invoiceNumber}
        />
        <ModalDoneByAdmin
          isOpen={this.state._modalDoneSale}
          modalTitle="Confirmation."
          modalBody="Are you sure to done this sale?"
          onDelete={this._handleSubmitCompletionSale}
          toggle={() => this.setState({_modalDoneSale:false, _formItemSelected:null})}
        />
        <div className="table-responsive">
            <PaginationSummaries
              from={this.props.from}
              to={this.props.to}
              total={this.props.total}
            />
            <Tbl>
              <TblHead>
                <RowHeader
                  categoryName={this.props.categoryValue}
                  categoryValue={this.props.categoryValue}
                  onCategoryChanged={this.props.onCategoryChanged}
                />
              </TblHead>
              <TblBody
                isLoad={this.props.isLoading}
                hasRow={this.props.statusCode===200&&this.state._list.length>0}
                columnCount={7}
                renderChildren={()=>{
                  const { currentPage, perPage } =  this.props;
                  return this.state._list.map((item, index)=>{
                    const number = generateNumber(perPage,currentPage,index);
                    return(
                      <RowItem
                        key={`${number}__${item.id}`}
                        dataItem={item}
                        number={number}
                        id={item.id}
                        quantity={item.offer.quantity}
                        invoiceNumber={item.invoice_number}
                        displayName={item.sale_product.display_name}
                        SKU={item.sale_product.SKU}
                        colour={item.sale_product.colour}
                        orderDate={item.created_at}
                        kaSent={item.ka_sent}
                        awbNumber={item.sale_shipping.awb_number}
                        kaCourier={item.sale_shipping.ka_courier}
                        buyerEmail={item.buyer_email}
                        sellerId={item.offer.user_sell.user.id}
                        sellerEmail={item.offer.user_sell.user.email}
                        size={item.sale_product.size.US}
                        preOrder={item.sale_product.pre_order}
                        preVerified={item.sale_product.pre_verified}
                        status={item.status}
                        sneakersCondition={item.sale_product.sneakers_condition}
                        totalPrice={item.total_price}
                        updatedAt={item.updated_at}
                        consignmentId={item.sale_product.consignment_id}
                        category={item.sale_product.category_name}
                        autoDisburse={item.autodisburse}
                        selectedId={this.state._formItemSelected && this.state._formItemSelected.id}
                        isSelected={this.state._formItemSelected!==null&&this.state._formItemSelected.id===item.id}
                        onOpenAwbNumberModal={()=>this._onOpenAwbNumberModal(item)}
                        showActionLoading={this.state._formItemSelected!==null&&this.state._formItemSelected.id==item.id}
                        renderActionButton = {()=>(
                          <ButtonGroup>
                            <Link to={`${SALE_DETAIL_ROUTE}/${item.id}`} className="btn btn-sm btn-secondary" target="_blank" title="Detail"><FontAwesomeIcon iconType="external-link"/></Link>
                            {/* <Button className={classNames({'d-none': item.money_disbursed!==null})} color="success" size="sm" title="Processed" onClick={()=>this._handleOpenProcessedModal(item)}><FontAwesomeIcon iconType="check"/></Button> */}
                            <Button className={classNames({'d-none':  item.status!=='KA_SENT_BUYER' })} color="success" size="sm" title="Processed" onClick={()=>this._handleOpenDoneSaleModal(item)}><FontAwesomeIcon iconType="check"/></Button>
                          </ButtonGroup>
                        )}
                        bank={item.offer.bank_code}
                        paymentVia={item.offer.payment_via}
                        paymentMethod={item.offer.payment_method}
                        onOpenCreditCard={()=>this._onOpenCreditCardModal(item)}
                        marked={item.marked}
                        notes={item.sale_notes}
                        loginId={this.props.loginId}
                        legitCheckLogs={item.legit_check_logs}
                        // markedNotes={item.marked_notes}
                        markedAt={item.open_marked_at}
                        markedBy={item.open_marked_by}
                        closeMarkedAt={item.close_marked_at}
                        unreadNoteCount={item.unread_notes_count}
                        rack={item.offer.user_sell.rack}
                        purchasePrice={item.purchase_price}
                        moneyDisbursed={item.money_disbursed}
                        compensation={item.compensation_amount}
                        followUpStatus={item.follow_up_status}
                        sellerEmail={item.offer.user_sell.user.email}
                        voucherCode={item.voucher_code? item.voucher_code: item.voucherable? item.voucherable.code: item.voucherable_id}
                        openPurchasePriceModal={(payloads) => this._togglePurchasePriceModal(true, payloads)}
                        openFollowUpModal={(payloads) => this._toggleFollowUpModal(true, payloads)}
                      />
                    )
                  })
                }}
              />
            </Tbl>
        </div>
        { this._onRenderPagination() }
      </div>
    )
  }
}

export default ProcessedList;

ProcessedList.propTypes = {
  currentPage: propTypes.number,
  from: propTypes.number,
  to: propTypes.number,
  total: propTypes.number,
  statusCode: propTypes.oneOfType([propTypes.string, propTypes.number]),
  prevPageUrl: propTypes.string,
  nextPageUrl: propTypes.string,
  lastPage: propTypes.number,
  path: propTypes.string,
  list: propTypes.arrayOf(propTypes.object),
  isLoading: propTypes.bool,
  dataUpdatedSale: propTypes.object, //Props from redux
  handleGotoList: propTypes.func.isRequired,
  handleUpdateAwbNumber: propTypes.func,
  handleCompletionSale: propTypes.func
}

ProcessedList.defaultProps = {
  currentPage: 0,
  from: 0,
  to: 0,
  total: 0,
  statusCode: null,
  prevPageUrl: null,
  nextPageUrl: null,
  lastPage: 0,
  list: [],
  isLoading: false,
}
