import React from "react";
import { Col, Label, Row } from "reactstrap";
import { Radio } from "../../../../../../components/Form/Radios";

const Defects = (props) => {
    const { formValue, onChangeValue, resetDefectDetail } = props;

    return (
        <div>
            {formValue.product &&
                (formValue.product.category === "apparels" ||
                    formValue.product.category === "lifestyles") && (
                    <Row>
                        <Col xs={12} style={{ marginTop: 16 }}>
                            <Label>Defects:</Label>
                        </Col>
                        <Col xs={6} style={{ marginTop: 16 }}>
                            <Radio
                                name="defects.no_tags"
                                id="defects.no_tags"
                                value={formValue.defects.no_tags}
                                checked={formValue.defects.no_tags}
                                onChange={onChangeValue}
                            />
                            <Label for="defects.no_tags">&nbsp; No Tags</Label>
                        </Col>
                        <Col xs={6} style={{ marginTop: 16 }}>
                            <Radio
                                name="defects.missing_original"
                                id="defects.missing_original"
                                value={formValue.defects.missing_original}
                                checked={formValue.defects.missing_original}
                                onChange={onChangeValue}
                            />
                            <Label for="defects.missing_original">
                                &nbsp; Missing Original Packaging
                            </Label>
                        </Col>
                        <Col xs={6} style={{ marginTop: 16 }}>
                            <Radio
                                name="defects.manufacture_defect"
                                id="defects.manufacture_defect"
                                value={formValue.defects.manufacture_defect}
                                checked={formValue.defects.manufacture_defect}
                                onChange={onChangeValue}
                            />
                            <Label for="defects.manufacture_defect">
                                &nbsp; Defect From Manufacture
                            </Label>
                        </Col>
                    </Row>
                )}
            {formValue.product && formValue.product.category === "sneakers" && (
                <Row>
                    <Col xs={12} style={{ marginTop: 16 }}>
                        <Label>Defects:</Label>
                    </Col>
                    <Col xs={6} style={{ marginTop: 16 }}>
                        <Radio
                            name="defects.missing_accessories"
                            id="defects.missing_accessories"
                            value={formValue.defects.missing_accessories}
                            checked={formValue.defects.missing_accessories}
                            onChange={(e) => {
                                resetDefectDetail("accessories");
                                onChangeValue(e);
                            }}
                        />
                        <Label for="defects.missing_accessories">
                            &nbsp; Missing Accessories
                        </Label>
                    </Col>
                    <Col xs={6} style={{ marginTop: 16 }}>
                        <Radio
                            name="defects.display_item"
                            id="defects.display_item"
                            value={formValue.defects.display_item}
                            checked={formValue.defects.display_item}
                            onChange={onChangeValue}
                        />
                        <Label for="defects.display_item">
                            &nbsp; Display Item
                        </Label>
                    </Col>
                    <Col xs={6} style={{ marginTop: 16 }}>
                        <Radio
                            name="defects.yellowing"
                            id="defects.yellowing"
                            value={formValue.defects.yellowing}
                            checked={formValue.defects.yellowing}
                            onChange={onChangeValue}
                        />
                        <Label for="defects.yellowing">&nbsp; Yellowing</Label>
                    </Col>
                    <Col xs={6} style={{ marginTop: 16 }}>
                        <Radio
                            name="defects.sneakers_defect"
                            id="defects.sneakers_defect"
                            value={formValue.defects.sneakers_defect}
                            checked={formValue.defects.sneakers_defect}
                            onChange={onChangeValue}
                        />
                        <Label for="defects.sneakers_defect">
                            &nbsp; Defect From Manufacture
                        </Label>
                    </Col>
                </Row>
            )}
            {formValue.product &&
                formValue.product.category === "sneakers" &&
                formValue.defects.missing_accessories && (
                    <Row>
                        <Col xs={12} style={{ marginTop: 16 }}>
                            <Label>Missing Accessories Details:</Label>
                        </Col>
                        <Col xs={6} style={{ marginTop: 16 }}>
                            <Radio
                                name="missing_accessories_list.no_extra_laces"
                                id="missing_accessories_list.no_extra_laces"
                                value={
                                    formValue.missing_accessories_list
                                        .no_extra_laces
                                }
                                checked={
                                    formValue.missing_accessories_list
                                        .no_extra_laces
                                }
                                onChange={onChangeValue}
                            />
                            <Label for="missing_accessories_list.no_extra_laces">
                                &nbsp; Extra Lace
                            </Label>
                        </Col>
                        <Col xs={6} style={{ marginTop: 16 }}>
                            <Radio
                                name="missing_accessories_list.no_tags"
                                id="missing_accessories_list.no_tags"
                                value={
                                    formValue.missing_accessories_list.no_tags
                                }
                                checked={
                                    formValue.missing_accessories_list.no_tags
                                }
                                onChange={onChangeValue}
                            />
                            <Label for="missing_accessories_list.no_tags">
                                &nbsp; Tag
                            </Label>
                        </Col>
                        <Col xs={6} style={{ marginTop: 16 }}>
                            <Radio
                                name="missing_accessories_list.no_wrap_paper"
                                id="missing_accessories_list.no_wrap_paper"
                                value={
                                    formValue.missing_accessories_list
                                        .no_wrap_paper
                                }
                                checked={
                                    formValue.missing_accessories_list
                                        .no_wrap_paper
                                }
                                onChange={onChangeValue}
                            />
                            <Label for="missing_accessories_list.no_wrap_paper">
                                &nbsp; Wrapping Paper
                            </Label>
                        </Col>
                    </Row>
                )}
            {formValue.product && formValue.product.category === "handbags" && (
                <Row>
                    <Col xs={12} style={{ marginTop: 16 }}>
                        <Label>Defects:</Label>
                    </Col>
                    <Col xs={6} style={{ marginTop: 16 }}>
                        <Radio
                            name="defects.missing_accessories"
                            id="defects.missing_accessories"
                            value={formValue.defects.missing_accessories}
                            checked={formValue.defects.missing_accessories}
                            onChange={onChangeValue}
                        />
                        <Label for="defects.missing_accessories">
                            &nbsp; Missing Accessories
                        </Label>
                    </Col>
                    <Col xs={6} style={{ marginTop: 16 }}>
                        <Radio
                            name="defects.discoloration"
                            id="defects.discoloration"
                            value={formValue.defects.discoloration}
                            checked={formValue.defects.discoloration}
                            onChange={onChangeValue}
                        />
                        <Label for="defects.discoloration">
                            &nbsp; Discoloration
                        </Label>
                    </Col>
                    <Col xs={6} style={{ marginTop: 16 }}>
                        <Radio
                            name="defects.scratches"
                            id="defects.scratches"
                            value={formValue.defects.scratches}
                            checked={formValue.defects.scratches}
                            onChange={onChangeValue}
                        />
                        <Label for="defects.scratches">&nbsp; Scratches</Label>
                    </Col>
                    <Col xs={6} style={{ marginTop: 16 }}>
                        <Radio
                            name="defects.transferred_color"
                            id="defects.transferred_color"
                            value={formValue.defects.transferred_color}
                            checked={formValue.defects.transferred_color}
                            onChange={onChangeValue}
                        />
                        <Label for="defects.transferred_color">
                            &nbsp; Transferred Color
                        </Label>
                    </Col>
                    <Col xs={6} style={{ marginTop: 16 }}>
                        <Radio
                            name="defects.sign_of_wear"
                            id="defects.sign_of_wear"
                            value={formValue.defects.sign_of_wear}
                            checked={formValue.defects.sign_of_wear}
                            onChange={onChangeValue}
                        />
                        <Label for="defects.sign_of_wear">
                            &nbsp; Sign Of Wear
                        </Label>
                    </Col>
                    <Col xs={12} style={{ marginTop: 16 }}>
                        <Label>Inclusions:</Label>
                    </Col>
                    <Col xs={6} style={{ marginTop: 16 }}>
                        <Radio
                            name="inclusions.box"
                            id="inclusions.box"
                            value={formValue.inclusions.box}
                            checked={formValue.inclusions.box}
                            onChange={onChangeValue}
                        />
                        <Label for="inclusions.box">&nbsp; Box</Label>
                    </Col>
                    <Col xs={6} style={{ marginTop: 16 }}>
                        <Radio
                            name="inclusions.mirror"
                            id="inclusions.mirror"
                            value={formValue.inclusions.mirror}
                            checked={formValue.inclusions.mirror}
                            onChange={onChangeValue}
                        />
                        <Label for="inclusions.mirror">&nbsp; Mirror</Label>
                    </Col>
                    <Col xs={6} style={{ marginTop: 16 }}>
                        <Radio
                            name="inclusions.dust_bag"
                            id="inclusions.dust_bag"
                            value={formValue.inclusions.dust_bag}
                            checked={formValue.inclusions.dust_bag}
                            onChange={onChangeValue}
                        />
                        <Label for="inclusions.dust_bag">&nbsp; Dust Bag</Label>
                    </Col>
                    <Col xs={6} style={{ marginTop: 16 }}>
                        <Radio
                            name="inclusions.straps"
                            id="inclusions.straps"
                            value={formValue.inclusions.straps}
                            checked={formValue.inclusions.straps}
                            onChange={onChangeValue}
                        />
                        <Label for="inclusions.straps">&nbsp; Straps</Label>
                    </Col>
                    <Col xs={6} style={{ marginTop: 16 }}>
                        <Radio
                            name="inclusions.tag"
                            id="inclusions.tag"
                            value={formValue.inclusions.tag}
                            checked={formValue.inclusions.tag}
                            onChange={onChangeValue}
                        />
                        <Label for="inclusions.tag">&nbsp; Tag</Label>
                    </Col>
                    <Col xs={6} style={{ marginTop: 16 }}>
                        <Radio
                            name="inclusions.authentication_card"
                            id="inclusions.authentication_card"
                            value={formValue.inclusions.authentication_card}
                            checked={formValue.inclusions.authentication_card}
                            onChange={onChangeValue}
                        />
                        <Label for="inclusions.authentication_card">
                            &nbsp; Authentication Card
                        </Label>
                    </Col>
                </Row>
            )}
        </div>
    );
};

export default Defects;
