import React from 'react';
import { connect } from 'react-redux'
import { Link } from 'react-router-dom';
import {
  Row,
  Col,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  ButtonGroup,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Alert,
} from 'reactstrap';
import classNames from 'classnames';
import uuidv4 from 'uuid/v4';
import moment from 'moment-timezone';
import qs from 'query-string';
import { getRaffleById, saveRaffle, deleteRaffleById, uploadRaffleImage, getRaffleGroups } from '../../../actions/raffleAction';
import { FontAwesomeIcon } from '../../../components/Icons';
import { Radio, RadioText, FormGroupRadio } from '../../../components/Form/Radios';
import { ButtonLoading } from '../../../components/Button';
import { CardHeaderWithToolbars, Title } from '../../../components/Card/CardHeaderWithToolbars';
import { addErrorMessage, errorMessage, hasError ,  textValue } from '../../../utils/form';
import { ModalDelete, ModalBlockLoading as ModalLoading, ModalInfo, ModalEditImage } from '../../../components/Modals';
import { ImageFile } from '../../../components/Images';
import { DropzonePlusButton } from '../../../components/Form/DropzoneFile';
import { ImagesCompressor, createUploadFileForm } from '../../../utils/imageHelper';
import { isRequired } from '../../../helpers/regex';
import InputDatetime from '../../../components/Form/InputDatetime/InputDatetime';
import RichTextEditor from 'react-rte';
import { toolbarConfig } from '../../../config/rte';
import ModalBrowseVoucher from './ModalBrowseVoucher';
import VoucherCard from './VoucherCard';
import TabEntryCoupon from './TabEntryCoupon';
import ProductVariantList from './ProductVariantList';
import RaffleTierList from './RaffleTierList';
import { getPathS3, getOriginFromSignedUrl } from '../../../utils/AWS'

const DEFAULT_IMAGE_ASPECT_LANDSCAPE = 16/5;

const parseRteValue = valueStr => {
  return valueStr !== null && valueStr !== ''? RichTextEditor.createValueFromString(valueStr, 'html'):
    RichTextEditor.createEmptyValue()
}

const isEmptyRteValue = rteHtml => {
  return rteHtml === '<p><br></p>'
}

const addImageRawObject = (src, file) => {
  return {
    status: false,
    identifier: uuidv4(),
    fileInput: file,
    URL: src,
    isUploading: false
  }
}

class RaffleForm extends React.Component{
  constructor(props){
    super(props)
    const DEFAULT_STATE = {
      activeTab:'raffle',
      _ref: null
    }
    const queries = Object.assign(DEFAULT_STATE,
      qs.parse(props.location.search)
    );
    this.state = {
      ...queries,
      _hasId: this.props.match.params.id?true:false,
      _form:{
        id: this.props.match.params.id||0,
        name: '',
        group_name: '',
        promo_code: '',
        img_url: '',
        oldImageUrl: null,
        note: '',
        termsRte: parseRteValue(null),
        descriptionRte: parseRteValue(null),
        description:'',
        instructionsRte: parseRteValue(null),
        instructions: '',
        terms: '',
        active: false,
        started_at: '',
        expiry:  '',
        join_method: '',
        voucher_id: null,
        voucher: null,
        golden: false,
      },
      _errorForm: null,
      _formSubmit: false,
      _alertErrorOpen: false,
      _alertSuccessOpen: false,
      _deleteConfirmationOpen: false,
      _rawImage: null,
      _modalInfo:{
        isOpen: false,
        modalBody: null
      },
      _modalBrowseVoucherOpen: false,
      _modalEditImage: {
        isOpen: false,
        defaultImageRatio: DEFAULT_IMAGE_ASPECT_LANDSCAPE,
        imageSourceEdits: [],
        purpose: "default"
      },
      events: null,
      isReadOnly: false
    }
    moment.tz('Asia/Jakarta');
    this.handleChangeEditor=this.handleChangeEditor.bind(this);
  }

  componentDidMount(){
    let raffleId = null;
    if(this.props.match.params.id){
      raffleId = this.props.match.params.id;
    } else if(this.state._ref){
      raffleId = this.state._ref;
    }
    if(raffleId){
      this.props.getRaffleById(raffleId)
      const { roles } = this.props.injectedProps;
      const isReadOnly = !['superadministrator', 'administrator'].map(item => roles.includes(item))
        .some(value => value);
      this.setState({
        isReadOnly
      })
    }
    this.props.getRaffleGroups()
  }

  componentDidUpdate(prevProps){
    if(prevProps.dataRaffle.isFetch&&!this.props.dataRaffle.isFetch){
      const { data, error, status_code } = this.props.dataRaffle;
      if(error){
        if(error.status_code === 404)
          this.props.history.replace('/404')
      } else if(status_code === 200){
        if(!this.props.match.params.id && !this.state._ref){
          this.props.history.replace(`/raffles/${data.id}`)
        }
        const { _ref } = this.state;
        this.setState({
          ...this._bindRafflePropsToState(data)
        }, () => {
          const imgUrl = data.signed_url || data.img_url
          if(_ref && imgUrl){
            this.getImageData(imgUrl)
          }
        });
      }
    }
    if(prevProps.dataUploaded.isPending && !this.props.dataUploaded.isPending){
      const { results, error } = this.props.dataUploaded;
      if(error){
        const { id } = this.state._form;
        if(!this.props.match.params.id){
          this.props.history.replace(`/raffles/${id}`)
          return;
        }
        this.setState({
          events: null,
          _formSubmit: false,
          _alertSuccessOpen: false,
          _alertErrorOpen: true,
          _errorMessage: this.translateMessage(500, 'Error occurred when uploading your image.')
        })
      } else if(this.state.events === 'RESUBMIT'){
        this.setState({
          _form: {
            ...this.state._form,
            img_url: results.url,
            oldImageUrl: results.url
          },
          // _errorForm: null,
          _alertErrorOpen: false,
          // _alertSuccessOpen: false,
          _rawImage: null,
        }, () => {
          this._submitData();
        })
      }
    }
    if(prevProps.dataRaffle.isSubmit && !this.props.dataRaffle.isSubmit){
      const { data, error, status_code, message } = this.props.dataRaffle;
      if(error){
        this.setState({
          _formSubmit: false,
          _errorForm: {
            ...error
          },
          events: null,
          _alertSuccessOpen: false,
          _alertErrorOpen: true,
          _errorMessage: this.translateMessage(error.status_code, error.message)
        })
      }
      else if(status_code === 200){
        const { events } = this.state;
        if(data.deleted)
          this.props.history.replace(`/raffles`)
        if(events === null && !this.props.match.params.id){
          this.props.history.replace(`/raffles/${data.id}`)
        }
        const { _form: dataForm } = this._bindRafflePropsToState(data);
        this.setState({
          _form: {
            ...dataForm,
            id: data.id,
            img_url: this.state._rawImage? this.state._rawImage.URL: dataForm.img_url
          },
          _formSubmit: events !== null,
          _errorForm: null,
          _alertErrorOpen: false,
          _alertSuccessOpen: events === null,
          _successMessage: this.translateMessage(status_code, message),
        }, () => {
          if(events === 'UPLOAD'){
            const { _form: { id }, _rawImage } = this.state;
            this.setState({
              _rawImage: {
                ..._rawImage,
                isUploading: true
              },
              events: 'RESUBMIT'
            })
            let key = getPathS3(getOriginFromSignedUrl(dataForm.img_url))
            if(!key){
              key = `raffles/${id}`;
            }
            const formData = createUploadFileForm(_rawImage.fileInput, false, {name: key, bucket_type: "assets"});
            this.props.uploadRaffleImage(formData)
          }
        });
      }
    }
  }

  _bindRafflePropsToState = (data = null) => {
    const { data: dataProps } = this.props.dataRaffle;
    const dataApi = data? data: dataProps;
    const { _ref } = this.state;
    const startAtParsed = moment(dataApi.started_at);
    const endedAtParsed = moment(dataApi.expiry);
    const termsRte = parseRteValue(dataApi.terms);
    const descriptionRte = parseRteValue(dataApi.description);
    const instructionsRte = parseRteValue(dataApi.instructions);
    const { _form } = this.state;
    let voucher = dataApi.voucher_id? dataApi.voucher: null;
    if(_form.voucher_id === dataApi.voucher_id && dataApi.voucher_id){
      voucher = _form.voucher;
    }
    const body = {
      name: dataApi.name,
      group_name: dataApi.group_name,
      promo_code: dataApi.promo_code,
      note: dataApi.note,
      termsRte,
      descriptionRte,
      instructionsRte,
      terms: dataApi.terms,
      description: dataApi.description,
      instructions: dataApi.instructions,
      active: dataApi.active,
      started_at: startAtParsed.isValid()? startAtParsed: '',
      expiry: endedAtParsed.isValid()? endedAtParsed: '',
      voucher_id: dataApi.voucher_id,
      voucher,
      join_method: !voucher? '': 'usingVoucher',
      golden: dataApi.golden,
      raffle_group_id: dataApi.raffle_group_id? dataApi.raffle_group_id: (dataApi.golden? 'gold': '')
    }
    if(this.props.match.params.id){
      body.id = dataApi.id;
      body.img_url = dataApi.signed_url? dataApi.signed_url: dataApi.img_url;
      body.oldImageUrl = dataApi.signed_url? dataApi.signed_url: dataApi.img_url;
      body.created_at = dataApi.created_at;
      body.updated_at = dataApi.updated_at;
    }
    return {
      _ref: _ref? null: _ref,
      _form:{
        ...this.state._form,
        ...body
      }
    }
  }

  getImageData = async (url) => {
    try{
      const data = await this.downloadImageToBlob(url)
      const blobUrl = URL.createObjectURL(data.blob);
      let fileName = blobUrl.substr(blobUrl.lastIndexOf('/')+1)
      if(fileName == '') fileName = 'local'
      const raw = addImageRawObject(blobUrl, null)
      this.setState({
        _form: {
          ...this.state._form,
          img_url: blobUrl,
          oldImageUrl: null
        },
        _rawImage: {
          ...raw,
          fileInput: new File([data.blob], `${fileName}.jpeg`, {
            type: 'image/jpeg', lastModified: new Date()
          })
        }
      })
    }catch(e){
      console.error("Error: ", e)
    }
  }

  downloadImageToBlob = url => {
    return new Promise((resolve, reject)=>{
      const xhr = new XMLHttpRequest();
      xhr.responseType = 'arraybuffer';
      xhr.open('GET', url , true);

      xhr.onreadystatechange = async () => {
        if (xhr.readyState == xhr.DONE) {
          if(!xhr.status){
            reject(new Error('Cannot load image.'));
          }
          //When request is done
          //xhr.response will be a Blob ready to save
          const blob = new Blob([xhr.response], {type:'image/jpeg'});
          resolve({blob})
        }
      };
      xhr.send();
    })
  }

  _hasInputError = name => {
    const { _errorForm } = this.state;
    return _errorForm && _errorForm.errors && hasError(name, _errorForm)
  }

  _handleDatePickerChange = (date, name) => {
    this.setState({
      _form:{
        ...this.state._form,
        [name]: date
      }
    })
  }

  _handleInputChange = ({ target: { name, value, checked, type } }) => {
    this.setState({
      _form:{
        ...this.state._form,
        [name]: type === 'checkbox'? checked: value
      }
    })
  }

  _handleInputMethodChange = e => {
    const { _form, _modalBrowseVoucherOpen } = this.state;
    let payloads = {
      ..._form,
      [e.target.name]: e.target.value
    }
    if(e.target.value === ''){
      payloads.voucher_id = null;
    } else{
      payloads.voucher_id = payloads.voucher? payloads.voucher.id: null;
    }
    let forceOpen = _modalBrowseVoucherOpen;
    if(e.target.value === 'usingVoucher'){
      forceOpen = payloads.voucher_id === null;
    }
    this.setState({
      _form: payloads,
      _modalBrowseVoucherOpen: forceOpen
    })
  }

  _renderErrorMessage = name => {
    const { _errorForm } = this.state;
    return errorMessage(name, _errorForm)
  }

  _handleModalDeleteToggle = () => {
    const { _formSubmit } = this.state;
    const { injectedProps: {roles} } = this.props;
    if(!_formSubmit){
      if(!roles.includes('superadministrator')){
        const modalBody =  "Sorry, only super administrator can delete the raffle.";
        this.setState({
          _modalInfo: {
            ...this.state._modalInfo,
            modalBody,
            isOpen: true
          }
        })
        return;
      }
      this.setState({
        _deleteConfirmationOpen: !this.state._deleteConfirmationOpen
      })
    }
  }
  
  handleChangeEditor(value, name) {
    let newState = {...this.state._form};
    switch (name) {
      case "description":
        newState = {descriptionRte: value, description:value.toString("html")}
        break;
      case "terms" :
        newState = {termsRte: value, terms:value.toString("html")}
        break;
      case "instructions" :
        newState = {instructionsRte: value, instructions:value.toString("html")}
        break;
      default:
        break;
    }
    this.setState({_form:{...this.state._form, ...newState}})
  }

  _handleDropzoneChange = (files) => {
    if(files.length){
      const [file] = files;
      const { _form, _modalEditImage } = this.state;
      const fileObj = addImageRawObject(file.preview, file)
      let modalEditImage = _modalEditImage;
      if(this.props.useCrop){
        modalEditImage = {
          ..._modalEditImage,
          isOpen: true,
          imageSourceEdits: [{
            imgId: fileObj.identifier,
            src: fileObj.URL
          }]
        }
      }
      this.setState({
        _form: {
          ..._form,
          img_url: file.preview
        },
        _rawImage: fileObj,
        _modalEditImage: {
          ...modalEditImage,
        }
      });
    }
  }

  translateMessage = (statusCode, message) => {
    let messageStr = ''
    switch(statusCode){
      case 200: messageStr = 'Success...'; break;
      case 422: messageStr = 'Please fullfill your form.'; break;
      case 404: messageStr = 'Resource not found.'; break;
      case 500: messageStr = "We've got something errors"; break;
      default: messageStr = message; break;
    }
    return messageStr
  }

  _validateForm = () => {
    const { _form: dataForm } = this.state;
    let errors={};
    if(!isRequired(dataForm.name)){
      errors['name'] = addErrorMessage(errors, 'name', 'The name field is required.');
    }
    // if(!isRequired(dataForm.img_url)){
    //   errors['img_url'] = addErrorMessage(errors, 'img_url', 'Please upload any image.');
    // }
    if(!moment.isMoment(dataForm.expiry) && dataForm.expiry !== ''){
      errors['expiry'] = addErrorMessage(errors, 'expiry', 'Invalid date.');
    }
    if(!moment.isMoment(dataForm.started_at) && dataForm.started_at !== ''){
      errors['started_at'] = addErrorMessage(errors, 'started_at', 'Invalid date.');
    }
    if(dataForm.active && dataForm.expiry === ''){
      errors['expiry'] = addErrorMessage(errors, 'expiry', 'Please select an expiry date.');
    }
    if(moment.isMoment(dataForm.started_at) && moment.isMoment(dataForm.expiry)){
      // console.log("Validate: ", dataForm.started_at.isBefore(dataForm.expiry))
      if(!dataForm.started_at.isBefore(dataForm.expiry)){
        errors['started_at'] = addErrorMessage(errors, 'started_at', 'Invalid date. Started date < expiry date.');
      }
    }
    let errorState = null;
    if(Object.keys(errors).length){
      errorState = {
        errors: { ...errors },
        status_code: 422,
        message: 'Please full fill form'
      }
      this.setState({
        _errorForm: errorState? {...errorState}: null,
        _errorMessage: errorState? errorState.message: null,
        _alertErrorOpen: true,
        _alertSuccessOpen: false,
        _errorMessage: 'Please full fill form'
      })
    }
    // console.log("Error: ", errors);
    return Object.keys(errors).length>0;
  }

  _compressImage = async (image) => {
    let fileInput = image
    try{
      //compress file
      fileInput = await ImagesCompressor(image);
    }
    catch(e){
      alert('error while compress the images')
      fileInput = image
    }
    return fileInput;
  }

  _handleSelectVoucher = voucher => {
    const { _form } = this.state;
    this.setState({
      _form:{
        ..._form,
        voucher: { ...voucher },
        voucher_id: voucher.id
      },
      _modalBrowseVoucherOpen: !this.state._modalBrowseVoucherOpen
    })
  }

  _submitData = async() => {
    const { data } = this.props.dataRaffle;
    const { _form: { voucher_id, ...dataForm }, _rawImage } = this.state;
    let expiry = dataForm.expiry === ''? null:(
      moment.isMoment(dataForm.expiry)? dataForm.expiry.format("YYYY-MM-DD HH:mm:00"):
      dataForm.expiry);
    let rawImage = _rawImage;
    if(rawImage && !rawImage.compressed){
      const compFileInput = await this._compressImage(rawImage.fileInput);
      rawImage = {
        ...rawImage,
        fileInput: compFileInput,
        compressed: true
      }
    }

    //prevent error expiry required and active == false
    if(!dataForm.active && expiry === null && dataForm.id > 0){
      expiry = data.expiry? data.expiry: data.updated_at;
    }
    let promoCode = dataForm.promo_code === ''? null: dataForm.promo_code;
    if(promoCode && voucher_id){
      promoCode = null;
    }
    let payloads = {
      name: dataForm.name,
      group_name: dataForm.group_name,
      promo_code: promoCode? promoCode.toUpperCase(): promoCode,
      img_url: getOriginFromSignedUrl(dataForm.oldImageUrl),
      note: dataForm.note,
      terms: isEmptyRteValue(dataForm.terms)?null: dataForm.terms,
      description: isEmptyRteValue(dataForm.description)?null: dataForm.description,
      instructions: isEmptyRteValue(dataForm.instructions)?null: dataForm.instructions,
      active: dataForm.active,
      started_at: dataForm.started_at === ''? null:(
        moment.isMoment(dataForm.started_at)? dataForm.started_at.format("YYYY-MM-DD HH:mm:00"):
        dataForm.started_at),
      expiry,
      voucher_id: voucher_id,
      golden: dataForm.golden,
      group_id: dataForm.raffle_group_id === '' || dataForm.raffle_group_id === 'gold'? null: Number(dataForm.raffle_group_id)
    }
    if(dataForm.id > 0){
      payloads = { ...payloads, id: dataForm.id }
    }
    const hasNext = rawImage !== null
    // console.log("My Payloads: ", payloads)
    this.setState({
      _formSubmit: true,
      _rawImage: rawImage?{
        ...rawImage
      }: null,
      events: hasNext? 'UPLOAD': null
    });
    this.props.saveRaffle(payloads)
  }

  _handleSubmitForm = (e) => {
    e.preventDefault();
    if(this.state._formSubmit) return;
    if(!this._validateForm()){
      // alert("Asala");
      this._submitData()
    }
  }

  _handleDeleteRaffle = () =>{
    const { _form: { id, _formSubmit } } = this.state;
    if(!_formSubmit){
      this.setState({
        _formSubmit: !_formSubmit,
      })
      this.props.deleteRaffleById(id);
    }
    this._handleModalDeleteToggle();
  }

  _renderLoading(){
    const { _formSubmit } = this.state;
    if(!_formSubmit) return null;
    return (
      <Row>
        <Col xs={12}>
          <Alert color="info">
            <strong>Heads up!</strong> Submitting your data...
          </Alert>
        </Col>
      </Row>
    )
  }


  onCropSucceeded = async (blob, ratio) => {
    const blobUrl = URL.createObjectURL(blob);
    let fileName = blobUrl.substr(blobUrl.lastIndexOf('/')+1)
    if(fileName == '') fileName = 'local'
    const { _modalEditImage, _form, _rawImage } = this.state;
    if(_modalEditImage.purpose === 'default'){
      this.setState({
        _form: {
          ..._form,
          img_url: blobUrl
        },
        _rawImage: {
          ..._rawImage,
          fileInput: new File([blob], `${fileName}.jpeg`, {
            type: 'image/jpeg'
          }),
          URL: blobUrl,
          orientation: ratio.ratio <=1? 'portrait': 'landscape'
        },
        _modalEditImage: {
          ..._modalEditImage,
          isOpen: false,
          imageSourceEdits: []
        }
      })
    }
  }

  _onActiveTabChange = id => {
    this.setState({activeTab:id}, ()=>{
      const { activeTab } = this.state;
      const { pathname } = this.props.location;
      const search = qs.stringify({
        activeTab
      });
      this.props.history.replace({ pathname, search })
    });
  }

  _handleAdjustedDate = (e, fieldName, type) => {
    e.preventDefault();
    const { _form } = this.state;
    const dateValue = _form[fieldName];
    if(dateValue && dateValue !== '' && moment.isMoment(dateValue)){
      this.setState({
        _form:{
          ..._form,
          [fieldName]: type === 'start'? moment(dateValue).startOf('day'): moment(dateValue).endOf('day')
        }
      })
    }
  }

  render(){
    return(
      <div className="animated fadeIn">
        <Row>
          <ModalDelete
            isOpen={this.state._deleteConfirmationOpen}
            modalTitle="Confirmation."
            modalBody="Are you sure to delete this?"
            onDelete={this._handleDeleteRaffle}
            toggle={this._handleModalDeleteToggle}
          />
          <ModalInfo
            modalTitle="Info"
            {...this.state._modalInfo}
            toggle={() => {
              const { _modalInfo } = this.state;
              this.setState({ _modalInfo: { ..._modalInfo, isOpen: !_modalInfo.isOpen } })
            }}
          />
          <ModalLoading isOpen={this.state.events!==null}/>
          <ModalEditImage
            isOpen={this.state._modalEditImage.isOpen}
            imageSource={this.state._modalEditImage.imageSourceEdits.length? this.state._modalEditImage.imageSourceEdits[0].src: null}
            showGrid
            ratio={this.state._modalEditImage.defaultImageRatio}
            onCropSucceeded={this.onCropSucceeded}
          />
          <ModalBrowseVoucher
            isOpen={this.state._modalBrowseVoucherOpen}
            toggle={() => this.setState({ _modalBrowseVoucherOpen: !this.state._modalBrowseVoucherOpen })}
            onSelectedItem={this._handleSelectVoucher}
          />
          <Col xs={12}>
            <Card body>
              <Nav className="nav-tabs--custom" tabs>
                <NavItem>
                  <NavLink
                    className={classNames({ active: this.state.activeTab === 'raffle' })}
                    onClick={() => this._onActiveTabChange('raffle')}
                  >
                    Details
                  </NavLink>
                </NavItem>
                <NavItem className={classNames({'d-none': !this.props.edit})}>
                  <NavLink
                    className={classNames({ active: this.state.activeTab === 'raffleEntry' })}
                    onClick={() => this._onActiveTabChange('raffleEntry') }
                  >
                    Entries & Coupons
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent className="tab-content--custom" activeTab={this.state.activeTab}>
                <TabPane className="tab-content--custom__tab-pane" tabId="raffle">
                  <Card body className="tab-content--custom__card">
                    <Row>
                      <Col xs={12} sm={6} md={6}>
                        <Card>
                          <CardHeaderWithToolbars>
                            <Title>
                              <FontAwesomeIcon iconType="align-justify"/>{' '}
                              <span>{this.props.title}</span>
                              {this.props.edit?
                                <div>
                                  <Link className="mr-3" style={{fontSize: '.65rem'}} to="/raffles/create">Create new Raffle</Link>
                                  <Link style={{fontSize: '.65rem'}} to={`/raffles/create?_ref=${this.props.match.params.id}`}>Duplicate Raffle</Link>
                                </div>
                              :null}
                            </Title>
                          </CardHeaderWithToolbars>
                          <CardBody>
                            <Form onSubmit={this._handleSubmitForm}>
                              {this._renderLoading()}
                              <Row>
                                <Col xs={12}>
                                  <Alert color="danger" isOpen={this.state._alertErrorOpen} toggle={() => this.setState({_alertErrorOpen: false})}>
                                    <strong>Oh Snap!</strong> { this.state._errorMessage }
                                  </Alert>
                                </Col>
                                <Col xs={12}>
                                  <Alert color="success" isOpen={this.state._alertSuccessOpen} toggle={() => this.setState({_alertSuccessOpen: false})}>
                                    <strong>Done!</strong> { this.state._successMessage }
                                  </Alert>
                                </Col>
                              </Row>
                              <FormGroup
                                row
                                className={classNames({ 'has-danger has-feedback': this._hasInputError('img_url') })}
                              >
                                <Col sm={12}>
                                  <Label>Image Banner (Landscape)</Label>
                                </Col>
                                <Col className="d-flex">
                                  <ImageFile
                                    style={{padding: 0}}
                                    alt="Raffle's image banner"
                                    src={this.state._form.img_url? this.state._form.img_url : null}
                                    showDelete={this.state._rawImage !== null}
                                    renderEmptyImageText={() => (
                                      <p className="mb-0 text-center">
                                        No Image <br/>
                                        Size: 1280 x 400 in pixels
                                      </p>
                                    )}
                                    onDelete={() => {
                                      const { _form } = this.state;
                                      this.setState({
                                        _form: {
                                          ..._form,
                                          img_url: this.state._form.oldImageUrl
                                        },
                                        _rawImage: null
                                      })
                                    }}
                                  />
                                <div className={classNames('ml-3', {'d-none': this.state.isReadOnly})}>
                                  <DropzonePlusButton mulitple={false} onDrop={this._handleDropzoneChange}/>
                                </div>
                              </Col>
                              <Col xs={12}>
                                { this._renderErrorMessage('img_url') }
                              </Col>
                            </FormGroup>
                            <Row>
                              <Col xs={12} sm={12}>
                                <FormGroup
                                  className={classNames({ 'has-danger has-feedback': this._hasInputError('name') })}
                                >
                                  <Label>Raffle Name</Label>
                                  <Input
                                    readOnly={this.state.isReadOnly}
                                    placeholder="Enter a name"
                                    name="name"
                                    type="text"
                                    value={textValue('name', this.state._form)}
                                    onChange={this._handleInputChange}
                                  />
                                  { this._renderErrorMessage('name') }
                                </FormGroup>
                              </Col>
                              <Col xs={12} sm={6}>
                                <FormGroup
                                  className={classNames({ 'has-danger has-feedback': this._hasInputError('group_id') })}
                                >
                                  <Label>Group - optional</Label>
                                  <Input
                                    type="select"
                                    name="raffle_group_id"
                                    value={textValue('raffle_group_id', this.state._form)}
                                    onChange={(e) =>{
                                      this.setState({
                                        _form: {
                                          ...this.state._form,
                                          golden: e.target.value === 'gold'? true: false,
                                          raffle_group_id: e.target.value
                                        }
                                      })
                                    }}
                                  >
                                    <option value="">Default</option>
                                    <option value="gold">Gold</option>
                                    {this.props.dataRaffleGroup.data && this.props.dataRaffleGroup.data.data?(
                                      this.props.dataRaffleGroup.data.data.map(item => (
                                        <option key={item.id} value={item.id}>{item.name}</option>
                                      ))
                                    ):null}
                                  </Input>
                                  { this._renderErrorMessage('group_id') }
                                </FormGroup>
                              </Col>
                              <div className="w-100"></div>
                              <Col xs={12} sm={12}>
                                <FormGroup
                                  className={classNames({ 'has-danger has-feedback': this._hasInputError('group_name') })}
                                >
                                  <Label>Group Header</Label>
                                  <Input
                                    placeholder="Enter a group header"
                                    name="group_name"
                                    type="text"
                                    value={textValue('group_name', this.state._form)}
                                    onChange={this._handleInputChange}
                                  />
                                  { this._renderErrorMessage('group_name') }
                                </FormGroup>
                              </Col>
                              <Col xs={12} sm={12}>
                                <FormGroup
                                  row
                                  className={classNames({ 'has-danger has-feedback': this._hasInputError('promo_code') })}
                                >
                                  <Col xs={12}>
                                    <Label>
                                      Raffle Code - optional
                                    </Label>
                                  </Col>
                                  <Col className={classNames({'d-none': this.state.isReadOnly})} xs={6} sm={4}>
                                    <div className="mb-1">
                                      <Input style={{fontSize: '.75rem'}} type="select" name="join_method" value={textValue('join_method', this.state._form)} onChange={this._handleInputMethodChange}>
                                        <option value="">Public Raffle</option>
                                        <option value="usingVoucher">Use Voucher</option>
                                      </Input>
                                    </div>
                                  </Col>
                                  <Col xs={12}>
                                    {this.state._form.join_method === 'usingVoucher'?(
                                      <VoucherCard
                                        readOnly={this.state.isReadOnly}
                                        voucher_id={this.state._form.voucher_id}
                                        voucher={this.state._form.voucher}
                                        onBrowseVoucherClick={e => {
                                          e.preventDefault();
                                          this.setState({
                                            _modalBrowseVoucherOpen: !this.state._modalBrowseVoucherOpen
                                          });
                                        }}
                                      />
                                    ):(
                                      <Input
                                        readOnly={this.state.isReadOnly}
                                        placeholder="Enter a raffle code"
                                        name="promo_code"
                                        type="text"
                                        value={textValue('promo_code', this.state._form)}
                                        onChange={this._handleInputChange}
                                      />
                                    )}
                                    { this._renderErrorMessage('promo_code') }
                                  </Col>
                                </FormGroup>
                              </Col>
                              <Col xs={12} sm={12}>
                                <FormGroup
                                  className={classNames({ 'has-danger has-feedback': this._hasInputError('description') })}
                                >
                                  <Label>Description - optional</Label>
                                  <RichTextEditor
                                    readOnly={this.state.isReadOnly}
                                    value={this.state._form.descriptionRte}
                                    onChange={(value) => this.handleChangeEditor(value, "description")}
                                    toolbarConfig={toolbarConfig}
                                  />
                                  { this._renderErrorMessage('description') }
                                </FormGroup>
                              </Col>
                              <Col xs={12} sm={12}>
                                <FormGroup
                                  className={classNames({ 'has-danger has-feedback': this._hasInputError('terms') })}
                                >
                                  <Label>Terms - optional</Label>
                                  <RichTextEditor
                                    readOnly={this.state.isReadOnly}
                                    value={this.state._form.termsRte}
                                    onChange={(value) => this.handleChangeEditor(value, "terms")}
                                    toolbarConfig={toolbarConfig}
                                  />
                                  { this._renderErrorMessage('terms') }
                                </FormGroup>
                              </Col>
                              <Col xs={12} sm={12}>
                                <FormGroup
                                  className={classNames({ 'has-danger has-feedback': this._hasInputError('instructions') })}
                                >
                                  <Label>Instructions - optional</Label>
                                  <RichTextEditor
                                    readOnly={this.state.isReadOnly}
                                    value={this.state._form.instructionsRte}
                                    onChange={(value) => this.handleChangeEditor(value, "instructions")}
                                    toolbarConfig={toolbarConfig}
                                  />
                                  { this._renderErrorMessage('instructions') }
                                </FormGroup>
                              </Col>
                              <Col xs={12} sm={12}>
                                <FormGroup
                                  className={classNames({ 'has-danger has-feedback': this._hasInputError('note') })}
                                >
                                  <Label>Note - optional</Label>
                                  <Input
                                    readOnly={this.state.isReadOnly}
                                    placeholder="Enter a note"
                                    name="note"
                                    type="textarea"
                                    value={textValue('note', this.state._form)}
                                    onChange={this._handleInputChange}
                                  />
                                  { this._renderErrorMessage('note') }
                                </FormGroup>
                              </Col>
                              <Col xs={12} md={6}>
                                <FormGroup
                                  className={classNames({ 'has-danger has-feedback': this._hasInputError('started_at') })}
                                >
                                  <Label>Started At</Label>
                                  <InputDatetime
                                    readOnly={this.state.isReadOnly}
                                    inputProps={{placeholder:'Pick a Start Date', name: "started_at", autoComplete: "off", readOnly: this.state.isReadOnly}}
                                    value={textValue('started_at', this.state._form)}
                                    onChange={ date => this._handleDatePickerChange(date, 'started_at') }
                                    timeFormat="HH:mm"
                                  />
                                  <p className="mb-0">
                                    <a href="#" onClick={(e) => this._handleAdjustedDate(e, 'started_at', 'start')} style={{fontSize: '.75rem', textDecoration: 'underline'}} className="mr-3">start of day</a>
                                    <a href="#" onClick={(e) => this._handleAdjustedDate(e, 'started_at', 'end')} style={{fontSize: '.75rem', textDecoration: 'underline'}} className="mr-3">end of day</a>
                                  </p>
                                  { this._renderErrorMessage('started_at') }
                                </FormGroup>
                              </Col>
                              <Col xs={12} md={6}>
                                <FormGroup
                                  className={classNames({ 'has-danger has-feedback': this._hasInputError('expiry') })}
                                >
                                  <Label>Ended At</Label>
                                  <InputDatetime
                                    inputProps={{placeholder:'Pick an Expiry', name: "expiry", autoComplete: "off", readOnly: this.state.isReadOnly}}
                                    value={textValue('expiry', this.state._form)}
                                    onChange={ date => this._handleDatePickerChange(date, 'expiry') }
                                    timeFormat="HH:mm"
                                  />
                                  <p className="mb-0">
                                    <a href="#" onClick={(e) => this._handleAdjustedDate(e, 'expiry', 'start')} style={{fontSize: '.75rem', textDecoration: 'underline'}} className="mr-3">start of day</a>
                                    <a href="#" onClick={(e) => this._handleAdjustedDate(e, 'expiry', 'end')} style={{fontSize: '.75rem', textDecoration: 'underline'}} className="mr-3">end of day</a>
                                  </p>
                                  { this._renderErrorMessage('expiry') }
                                </FormGroup>
                              </Col>
                              {/* <Col xs={12} md={6}>
                                <FormGroupRadio
                                  className={classNames({ 'has-danger has-feedback': this._hasInputError('golden') })}
                                >
                                  <Radio
                                    disabled={this.state.isReadOnly}
                                    type="checkbox"
                                    name="golden"
                                    value={true}
                                    checked={textValue('golden', this.state._form)}
                                    dataOnText="Yes"
                                    dataOffText="No"
                                    onChange={this._handleInputChange}
                                  />
                                  <RadioText text="Set to Golden"/>
                                  { this._renderErrorMessage('golden') }
                                </FormGroupRadio>
                              </Col> */}
                              <Col xs={12} sm={6}>
                                <FormGroupRadio
                                  className={classNames({ 'has-danger has-feedback': this._hasInputError('active') })}
                                >
                                  <Radio
                                    disabled={this.state.isReadOnly}
                                    type="checkbox"
                                    name="active"
                                    value={true}
                                    checked={textValue('active', this.state._form)}
                                    dataOnText="Yes"
                                    dataOffText="No"
                                    onChange={this._handleInputChange}
                                  />
                                  <RadioText text="Set to Active"/>
                                  { this._renderErrorMessage('active') }
                                </FormGroupRadio>
                              </Col>
                            </Row>
                            {
                              this.state._hasId?(
                                <Row>
                                  <Col xs={12} md={6}>
                                    <FormGroup>
                                      <Label>Created</Label>
                                      <Input
                                        readOnly
                                        name="created_at"
                                        type="text"
                                        value={textValue('created_at', this.state._form)}
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col xs={12} md={6}>
                                    <FormGroup>
                                      <Label>Updated</Label>
                                      <Input
                                        readOnly
                                        name="updated_at"
                                        type="text"
                                        value={textValue('updated_at', this.state._form)}
                                      />
                                    </FormGroup>
                                  </Col>
                                </Row>
                              ):null
                            }
                            {
                              this.state._errorForm?(
                                <p className="text-danger">Opps. Please check your form!</p>
                              ): null
                            }
                            <FormGroup className={classNames({'d-none': this.state.isReadOnly})} row>
                              <Col>
                                <ButtonGroup>
                                  <ButtonLoading
                                    disabled={this.state._formSubmit}
                                    isLoading={this.state._formSubmit}
                                    // isLoading={this.state._formSubmit && this.state._submitFormStatus === SUBMIT_SLIDER_FORM_STATUS}
                                    loadingMessage="Submitting..."
                                    color="primary"
                                  >
                                    Submit
                                  </ButtonLoading>
                                  {
                                    this.state._hasId?(
                                      <ButtonLoading
                                        disabled={this.state._formSubmit}
                                        isLoading={this.state._formSubmit}
                                        loadingMessage="Deleting..."
                                        onClick={this._handleModalDeleteToggle}
                                        color="danger"
                                      >
                                        Delete
                                      </ButtonLoading>
                                    )
                                    :null
                                  }
                                </ButtonGroup>
                              </Col>
                            </FormGroup>
                          </Form>
                          </CardBody>
                        </Card>
                      </Col>
                      {
                        this.props.edit?(
                        <CardBody style={{padding:0}}>
                          <Col xs={12}>
                            <RaffleTierList id={this.props.match.params.id}/>
                          </Col>
                          <Col xs={12}>
                            <ProductVariantList id={this.props.match.params.id}/>
                          </Col>
                        </CardBody>
                        ): null
                      }
                    </Row>
                  </Card>
                </TabPane>
                <TabPane className="tab-content--custom__tab-pane" tabId="raffleEntry">
                  <Card body className="tab-content--custom__card">
                    {this.state._hasId?(
                      <TabEntryCoupon raffleId={this.props.match.params.id}/>
                    ): null}
                  </Card>
                </TabPane>
              </TabContent>
            </Card>
          </Col>
        </Row>
      </div>
    )
  }
}

const mapStateToProps=({ raffle })=>({
  dataRaffle: raffle.details,
  dataRaffleGroup: raffle.raffleGroups,
  dataUploaded: raffle.uploadedImage
})
const mapDispatchToProps=(dispatch)=>({
  deleteRaffleById: id => dispatch(deleteRaffleById(id)),
  saveRaffle: (payloads) => dispatch(saveRaffle(payloads)),
  getRaffleById: id => dispatch(getRaffleById(id)),
  uploadRaffleImage: (form) => dispatch(uploadRaffleImage(form)),
  getRaffleGroups: q => dispatch(getRaffleGroups(q))
})

export default connect(mapStateToProps, mapDispatchToProps)(RaffleForm)