import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import uuidv4 from 'uuid/v4';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Input,
  Button,
  Label,
  Row,
  Col
} from 'reactstrap';
import { hasError, errorMessage } from '../../../utils/form';
import { DropzonePlusButton } from '../../../components/Form/DropzoneFile';
import { ImageFrame, ImageFileListContainer, ImageFile } from '../../../components/Images';
import { uploadSaleImageS3, createSaleImages, getSaleImages } from '../../../actions/saleAction';
import Select from "react-select"

const statusTypeOption = [
  {
    value: "FAKE",
    label: "Fake (-2)",
  },
  {
    value: "SKU_NOT_MATCH",
    label: "Produk yang dikirim tidak sesuai SKU (-1)",
  },
  {
    value: "NOT_SUITABLE",
    label: "Produk tidak sesuai kondisi listing (-1)",
  },
  {
    value: "MINOR_DEFECT",
    label: "Minor Defect (0)"
  }
]

const addImageRawObject = file => {
  return {
    identifier: uuidv4(),
    fileInputOrigin: file, //for rollback.
    fileInput: file,
    URL: file.preview,
    status: false,
    isUploading: false,
    compressed: false
  }
}

class ModalLegitCheck extends React.Component{
  constructor(props){
    super(props)
    this.state = {
      id: null,
      notes: '',
      seller_points: 0,
      type: '',
      error: null,
      rawImages: [],
      images: [],
      imageStored: [],
      _isPendingSubmit: false,
      _xIndex: 0,
      _xTo: 0
    }
  }

  componentDidUpdate(prevProps) {
    if(!prevProps.isOpen && this.props.isOpen){
      this.setState({
        id: this.props.sale.id,
        error:null,
        notes:'',
        seller_points:0,
        type: '',
        // isSubmit: false,
        rawImages: [],
        images: [],
        _isPendingSubmit: false,
        _xIndex: 0,
        _xTo: 0
      });
      this.props.getSaleImages(this.props.sale.id);
    }
    if(prevProps.isSubmit && !this.props.isSubmit){
      this.setState({
        error: this.props.error,
        // isSubmit: !this.props.error
      })
    }
    if(prevProps.dataImages.isFetch && !this.props.dataImages.isFetch){
      const { error, data } = this.props.dataImages;
      if(!error) this.setState({ imageStored: [...data] })
    }
    if(prevProps.dataImages.isSubmit && !this.props.dataImages.isSubmit){
      const { error, status_code } = this.props.dataImages;
      this.setState({
        _isPendingSubmit: false,
        error,
        rawImages: status_code === 200? []: this.state.rawImages
      })
      if(status_code === 200){
        const {id, notes, seller_points } = this.state;
        this.props.onSubmit(id, { notes, seller_points });
        this.props.getSaleImages(id);
      }
    }
    if(prevProps.dataUploaded.isPending && !this.props.dataUploaded.isPending){
      const { error, results } = this.props.dataUploaded;
      if(!error) {
        const { _xIndex, _xTo, images } = this.state;
        if(_xIndex + 1 < _xTo){
          this.setState({
            _xIndex: _xIndex + 1,
            images: [ ...images, results.url ]
          }, () => {
            const { id, rawImages, _xIndex } = this.state;
            this.props.uploadSaleImageS3(`sales/${id}/`, rawImages[_xIndex])
          })
        } else{
          this.setState({
            images: [ ...images, results.url ]
          }, () => {
            const { id, images } = this.state;
            this.props.createSaleImages(id, {
              urls: [ ...images ]
            })
          })
        }
      } else{
        this.setState({
          _isPendingSubmit: false,
          error
        })
      }
    }
  }

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  onSubmit = e => {
    e.preventDefault();
    const { rawImages, id, _isPendingSubmit } = this.state;
    if(_isPendingSubmit) return;
    if(rawImages.length){
      const [first] = rawImages;
      this.setState({
        _xIndex: 0,
        _xTo: rawImages.length,
        _isPendingSubmit: true
      }, () => {
        // console.log("first: ", first)
        this.props.uploadSaleImageS3(`sales/${id}/`, first)
      })
    }
    else{
      const { type } = this.state;
      this.props.onSubmit(id, { type });
    }
  }

  onToggle = () => {
    if(!this.props.onCancel) return;
    if(!this.props.isSubmit && !this.state._isPendingSubmit){
      this.props.onCancel();
    }
  }

  onDropFiles = files => {
    if(this.state._isPendingSubmit) return;
    if(files.length){
      const fileInputs = files.map(c => ({
        ...addImageRawObject(c),
      }));
      this.setState({
        rawImages: [
          ...this.state.rawImages,
          ...fileInputs
        ]
      });
    }
  }

  render(){
    return(
      <div>
        <Modal isOpen={this.props.isOpen} toggle={this.onToggle} size="md">
          <ModalHeader>
            Confirmation.
          </ModalHeader>
          <ModalBody>
            {
              this.props.sale?(
                <p>
                  Crew: {this.props.dataAuth.email} <br/>
                  <b>({this.props.sale.invoice_number})</b> <br/>
                  {this.props.isLegit? 'Legit': 'Reject'} order <i>{this.props.sale.sale_product.display_name}</i>
                </p>
              ): null
            }
            <p>
              You cannot undo this action, {this.props.isLegit? 'this order will be accepted because its legit': 'this order will be rejected because its not legit'}.
              <br/>
              Are you really sure?
            </p>
            <Form onSubmit={this.onSubmit}>
              {!this.props.isLegit?(
                <div>
                  <FormGroup
                    className={
                      hasError("type")
                        ? " has-danger has-feedback"
                        : ""
                    }
                  >
                    <Label for="formBrandTitle">Select Type</Label>
                    <Select
                      id="formCategoryName"
                      name="type"
                      value={this.state.type}
                      options={statusTypeOption}
                      onChange={(val) => {
                        this.setState({ type: val.value })
                      }}
                    />
                    {errorMessage('type',this.state.error)}
                  </FormGroup>
                </div>
              ):null}
              <FormGroup>
                <Label>Upload Images - optional</Label>
                <p className="mb-0" style={{fontStyle: 'italic', fontSize: '.65rem'}}>Try drop any images or click to select any images.</p>
                <ImageFileListContainer>
                  <ImageFrame>
                    <DropzonePlusButton onDrop={this.onDropFiles}/>
                  </ImageFrame>
                  {this.state.rawImages.map((img, key)=>(
                    <ImageFile
                      showDelete
                      key={key}
                      size={60}
                      src={img.URL}
                      onDelete={() => {
                        if(this.state._isPendingSubmit) return;
                        this.setState({
                          rawImages: this.state.rawImages
                            .filter((_img, idx) => idx !== key)
                        })
                      }}
                    />
                  ))}
                </ImageFileListContainer>
              </FormGroup>
              {this.state.imageStored.length? (
                <div>
                  <p>Already uploaded.</p>
                  <Row style={{maxHeight: 150, overflow: 'auto'}}>
                    {this.state.imageStored.map(item => (
                      <Col xs={6} sm={4} key={item.id}>
                        <img width="100%" src={item.URL} alt={item.URL}/>
                      </Col>
                    ))}
                  </Row>
                </div>
              ): null}
            </Form>
          </ModalBody>
          {
            !this.props.isSubmit && !this.state._isPendingSubmit?(
              <ModalFooter>
                <Button color="secondary" onClick={this.onToggle}>Cancel</Button>
                <Button color="primary" onClick={this.onSubmit}>Submit</Button>
              </ModalFooter>
            ):null
          }
        </Modal>
      </div>
    )
  }
}

const mapStateToProps=({ sale: { detailImages, detailImageS3 }, auth })=>({
  dataImages: detailImages,
  dataUploaded: detailImageS3,
  dataAuth: auth
})
const mapDispatchToProps=(dispatch)=>({
  getSaleImages: (id) => dispatch(getSaleImages(id)),
  uploadSaleImageS3: (folder, file, customFilename) => dispatch(uploadSaleImageS3(folder, file, customFilename)),
  createSaleImages: (saleId, payload) => dispatch(createSaleImages(saleId, payload)),
})
export default connect(mapStateToProps, mapDispatchToProps)(ModalLegitCheck);

ModalLegitCheck.propTypes={
  sale:PropTypes.object,
  isSubmit:PropTypes.bool,
  error:PropTypes.object,
  isOpen:PropTypes.bool,
  onSubmit:PropTypes.func,
  onCancel:PropTypes.func
}