import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Card,
  CardBody,
  CardText,
  Button,
  Container
} from 'reactstrap';
import { getRaffleEntryList, deleteRaffleEntry } from '../../../actions/raffleAction';
import { CardHeaderWithToolbars, Title } from '../../../components/Card/CardHeaderWithToolbars';
import { FontAwesomeIcon, SimpleLineIcon } from '../../../components/Icons';
import { Toolbars as ToolBarContainer } from '../../../components/Card/CardHeaderWithToolbars';
import { Tbl, TblHead, TblBody } from '../../../components/Tbl';
import Paginations from '../../../components/Paginations';
import PaginationSummaries from '../../../components/Paginations/PaginationSummaries';
import { generateNumber } from '../../../utils';
import DateToHuman from '../../../components/DateToHuman';
import Filters from './raffleEntry/Filters';
import { DEFAULT_PER_PAGE, DEFAULT_SORT_BY } from '../../../constants/settings';

const sortOptions = [{
  value:"" , label:"Default"
},
{
  value:"updatedAt_desc" , label:"Last Updated"
},
{
  value:"createdAt_desc" , label:"Newest"
},
{
  value:"createdAt_asc" , label:"Older"
}];

const dataHeader=[
	'No.', 'User', 'Updated', 'Action'
];

const Toolbars = props => (
  <ToolBarContainer>
    <Button
      title="Refresh"
      className="with-toolbars__toolbars__btn"
      size="sm"
      color="info"
      onClick={props.onRefreshList}>
      <FontAwesomeIcon iconType="refresh"/>
    </Button>
    <Button
      title="Reset filter"
      className="with-toolbars__toolbars__btn"
      size="sm"
      color="warning"
      onClick={props.onResetFilter}>
      <FontAwesomeIcon iconType="ban"/>
    </Button>
  </ToolBarContainer>
)

const defaultParams = {
  keyword: '',
  sort_by : DEFAULT_SORT_BY,
  // per_page : 1
  per_page : DEFAULT_PER_PAGE
}

class RaffleEntryList extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      _params: {...defaultParams},
      isResetParams: false
    }
  }

  componentDidMount(){
    if(this.props.raffleId){
      this._getList(this.state._params);
    }
  }

  componentDidUpdate(prevProps){
    if(prevProps.dataRaffle.details.isSubmit && !this.props.dataRaffle.details.isSubmit){
      const { error } = this.props.dataRaffle.details;
      if(error){
        alert("Error while delete a raffle entry!")
      } else{
        this._handleGoto(1)
      }
    }
  }


  _getList(rawParams = {}){
    const params = Object.keys(rawParams)
    .filter(key => rawParams[key]!='')
    .reduce((obj, current)=> ({...obj, [current]: rawParams[current]}), []);
    const { raffleId } = this.props;
    this.props.getRaffleEntryList(raffleId, {params});
  }

  _handleRefresh = (e) => {
    e.preventDefault();
    this._refreshList();
  }

  _refreshList = () => {
    const { _page, ..._params } = this.state._params;
    this.setState({
      _params
    },()=>this._getList(this.state._params));
  }

  // _handleToggleFilterPanel = () =>{
  //   this.setState({_filterOpen: !this.state._filterOpen})
  // }

  _handleApplyFilter = p => {
    const { _params } = this.state;
    this.setState({
      _params: {
        ..._params,
        ...p,
        page: 1
      }
    }, () => {
      this._getList(this.state._params)
    })
  }

  _handleResetFilter = (e) =>{
    e.preventDefault();
    this.setState({
      _params:{
        ...defaultParams
      },
      isResetParams: true
    }, () => {
      this.setState({ isResetParams: false })
      this._getList(this.state._params)
    })
  }

	_handleGoto = page =>{
    this.setState({
      _params: {
        ...this.state._params,
        page
      }
    }, ()=> this._getList(this.state._params) );
  }

  render(){
    return (
      <Card>
        <CardHeaderWithToolbars>
          <Title>
            <FontAwesomeIcon iconType="align-justify"/>{' '}
            <span>Raffle Entry List</span>
          </Title>
          <Toolbars
            onRefreshList={this._handleRefresh}
            onResetFilter={this._handleResetFilter}
          />
        </CardHeaderWithToolbars>
        <Container>
          <Filters
            {...this.state._params}
            isResetFields={this.state.isResetParams}
            sortOptions={sortOptions}
            onApplyFilter={this._handleApplyFilter}
          />
        </Container>
        <CardBody>
          <CardText>
            Raffle entries data that user have been participated.
          </CardText>
          <PaginationSummaries
            from={this.props.dataRaffle.data.from}
            to={this.props.dataRaffle.data.to}
            total={this.props.dataRaffle.data.total}
          />
          <div className="table-responsive" ref={tableRef => this.tableRef = tableRef}>
            <Tbl>
              <TblHead
                dataHeader={dataHeader}
              />
              <TblBody
                isLoad={this.props.dataRaffle.isFetch}
                hasRow={this.props.dataRaffle.status_code===200&&this.props.dataRaffle.data.data.length>0}
                columnCount={dataHeader.length}
                renderChildren={() =>{
                  const { current_page, per_page, data } =  this.props.dataRaffle.data;
                  return data.map((item, key)=> {
                    const rowNumber = generateNumber(per_page,current_page,key);
                    return(
                      <tr key={item.id}>
                        <th className="v-center" scope="row">{ rowNumber }</th>
                        <td className="v-center">
                          <div>
                            {
                              item.variant_id?(
                                <div>
                                  {item.product_variant.display_name}
                                </div>
                              ): null
                            }
                            {item.size_id?(
                              <div className="mb-2">Size (US): {item.size.US}</div>
                            ): null}
                          </div>
                          <p className="mb-0">
                            <span className="text-muted mr-1">#{item.user_id}</span>
                            <Link to={`/users/${item.user_id}`} className="btn btn-link">
                              {item.user.typed_email? item.user.typed_email: item.user.email}
                            </Link><br/>
                            <span style={{fontSize: '.65rem'}}>
                              Note: {item.short_note?item.short_note: '-'}
                            </span>
                          </p>
                        </td>
                        <td className="v-center">
                          <DateToHuman  value={item.updated_at} id={`tooltip_updated_at_${rowNumber}`}/>
                        </td>
                        <td className="v-center">
                          <Button color="danger" onClick={() => this.props.deleteRaffleEntry(this.props.raffleId, item.id)}>
                            <SimpleLineIcon iconType="trash"/>
                          </Button>
                        </td>
                      </tr>
                    )
                  })
                }}
              />
            </Tbl>
          </div>
          { this.props.dataRaffle.status_code===200 && (
            <Paginations
              size="sm"
              lastPage={this.props.dataRaffle.data.last_page}
              perPage={this.props.dataRaffle.data.per_page}
              total={this.props.dataRaffle.data.total}
              maxPage={8}
              path={this.props.dataRaffle.data.path}
              currentPage={this.props.dataRaffle.data.current_page}
              prevPageUrl={this.props.dataRaffle.data.prev_page_url}
              nextPageUrl={this.props.dataRaffle.data.next_page_url}
              handleGotoCallback={this._handleGoto}
              onCurrentPageChanged={() => {
                window.scrollTo({
                  top: this.tableRef.offsetTop+25,
                  behavior: 'smooth'
                })
              }}
            />
          )}
        </CardBody>
      </Card>
    )
  }
}


const mapStateToProps=({ raffle })=>({
  dataRaffle: raffle.entries,
})
const mapDispatchToProps=(dispatch)=>({
  getRaffleEntryList: (raffleId, queries) => dispatch(getRaffleEntryList(raffleId, queries)),
  deleteRaffleEntry: (raffleId, id) => dispatch(deleteRaffleEntry(raffleId, id))
})

export default connect(mapStateToProps, mapDispatchToProps)(RaffleEntryList)