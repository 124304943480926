import React,{ Component } from 'react';
import { connect } from 'react-redux';
import Select from '../../../components/Form/Select';
import {Creatable} from 'react-select';
import 'react-select/dist/react-select.css';
import classNames from 'classnames';
import propTypes from 'prop-types';

import Loading from 'react-loading-animation';
import { Row, Col, Card, CardHeader, CardBody, Form, FormGroup, Label, Input, 
    Alert, Collapse, Button, InputGroup, InputGroupAddon, InputGroupText, UncontrolledTooltip } from 'reactstrap';
import ButtonLoading from '../../../components/Button/ButtonLoading';
import { getListRole } from '../../../actions/roleAction';
import { getDetailUser,saveUser, getUserRoles, updateUserCommission } from '../../../actions/userAction';
import { getOptionList } from '../../../actions/categoriesAction';
import { forceHolidayMode } from '../../../actions/userSettingAction';
import { errorMessage, hasError, textValue, textChange, _getValue } from '../../../utils/form';
import { USER_CLEAR_FORM } from '../../../constants';
import { isNumeric } from '../../../utils/regex';
import { ModalInfo } from '../../../components/Modals';
import SelectRoleAsync from '../../../components/Form/Select/Async/SelectRole';
import Analytics from "../../../services/Analytics";

import ModalUserStockExport from './ModalUserStockExport';
import { FontAwesomeIcon } from '../../../components/Icons';
import { FormGroupRadio, Radio, RadioText } from '../../../components/Form/Radios';

class FormCard extends Component{
constructor(props){
    super(props);
    this.state={
      _roles:[],
      _notes:[
        { value: 'sering nanya lewat line', label: 'sering nanya lewat line' },
        { value: 'minat vapormax', label: 'minat vapormax' },
        { value: 'ramah orangnya', label: 'ramah orangnya' },
        { value: 'best sellers', label: 'best sellers' },
        { value: 'best buyer', label: 'best buyer' }
      ],
      username:'',
      first_name:'',
      last_name:'',
      email:'',
      roles:[],
      gender:'',
      debt_limit: 0,
      _isDebtLimitOpen: false,
      _isSellerPointsOpen: false,
      _submitProcess: false,
      _error: null,
      _successMessage: null,
      _errorMessage: null,
      _alertError: false,
      _alertSuccess: false,
      _isChangePassword: !this.props.isEdit,
      _isCommissionChange: false,
      password: null,
      confirm_password: null,
      userCommissions: [],
      _modalInfo:{
        isOpen: false,
        modalBody: null
      },
      modalStockExport: {
        userId: null,
        email: null,
        isOpen: false
      }
    }
    this._handleSelectChange=this._handleSelectChange.bind(this);
    this._handleOnSelectRoleOpen=this._handleOnSelectRoleOpen.bind(this);
    this._handleSelectNoteChange=this._handleSelectNoteChange.bind(this);
    this._handleTextChange = this._handleTextChange.bind(this);
    this._onSubmit = this._onSubmit.bind(this);
    this._toggleIsChangePassword = this._toggleIsChangePassword.bind(this);
    this._handleHolidayModeCheckedChange = this._handleHolidayModeCheckedChange.bind(this);
  }

  componentDidMount(){
    this.props.getRole();
    if(this.props.isEdit){
      this.props.find(this.props.params.id);
      this.props.findRoles(this.props.params.id);
    }
    if(!this.props.categories.length){
      this.props.getCategories()
    } else{
      this.bindUserCommissionData()
    }
  }

  bindUserCommissionData = () => {
    const { user: { detail }, categories } = this.props;
    let commissions = [];
    let settings = [];
    const defaultRate = {
      sneakers: 7,
      apparels: 8,
      handbags: 10,
      lifestyles:6
    }
    if(detail.id){
      commissions = [...detail.current_commission];
      settings = [...detail.commissions];
    }
    const data = categories.filter(category => ['sneakers', 'apparels', 'handbags', 'lifestyles'].includes(category.label.toLowerCase())).map(category => {
      const userCommission = commissions.find(item => item.category_id === category.value);
      const settingCommission = settings.find(item => item.id === category.value);
      const name = category.label.toLowerCase();
      const rate = defaultRate[name]?defaultRate[name]: 5;
      const commissionAdd = userCommission? userCommission.rate - userCommission.min_rate: 0;
      return {
        id: category.value,
        name,
        rate: userCommission? Number(userCommission.rate): rate,
        settingRate: settingCommission? Number(settingCommission.pivot.rate): rate,
        defaultRate: userCommission? Number(userCommission.default_rate) : rate,
        fixed: settingCommission? Boolean(settingCommission.pivot.fixed) : false,
        isSubmit:false,
        commissionAdd,
        notSet: userCommission === undefined
      }
    })
    this.setState({
      userCommissions: data,
      _isCommissionChange: false,
      seller_points_status: detail.seller_points_status
    })
  }

  componentWillReceiveProps(nextProps){
    const { user_setting } = nextProps.user;
    if(nextProps.role.isFetch===false 
      && nextProps.role.isFetch!==this.props.role.isFetch
      && nextProps.role.list.status_code===200
    ){
      this.setState({
        _roles:nextProps.role.list.data.map(item=>({value:item.id,label:item.name}))
      })
    }
    else if(nextProps.user.isFetch===false 
      && nextProps.user.isFetch!==this.props.user.isFetch
      && nextProps.user.detail.status_code===200
    ){
      const { family_name, sex, message, roles, seller_verifications, status_code, email_token, note, is_holiday, ...rest } = nextProps.user.detail;
      // Get and manipulate necessary fields from the result.
      const noteToArray = note && (note.trim() == ''? null: note.trim().split(',').map(n=> ({value: n.trim(), label: n.trim()})))
      const { _notes } = this.state;
      let newNoteOptions = [..._notes];
      if(noteToArray !== null && noteToArray.length){
        noteToArray.forEach(item => {
          const filtered = newNoteOptions.filter(i=> i.value==item.value);
          if(filtered.length === 0){
            newNoteOptions.push(item);
          }
        })
      }
      this.setState({
        ...rest,
        last_name: family_name,
        gender: sex,
        note: noteToArray,
        _notes: newNoteOptions,
        holiday_mode: is_holiday
      }, () => {
        this.bindUserCommissionData()
      })
    }
    else if(nextProps.user.detail.isRoleFetch===false 
      && nextProps.user.detail.isRoleFetch!==this.props.user.detail.isRoleFetch
      && nextProps.user.detail.roles.status_code===200
    ){
      const { data } = nextProps.user.detail.roles;
      let manipulated = data.map(role=>({value:role.id,label:role.name}))
      const _isDebtLimitOpen = data.some( role =>  role.name === 'debtor');
      const _isSellerPointsOpen = data.some( role =>  role.name === 'seller');
      // Get and manipulate necessary fields from the result.
      this.setState({
        roles: [...manipulated],
        _isDebtLimitOpen,
        _isSellerPointsOpen
      })
    }
    else if(nextProps.user.isSubmit===false&&nextProps.user.isSubmit!==this.props.user.isSubmit){
      if(nextProps.user.error!==null){
        let ubahErrors = {}
        if(nextProps.user.error.status_code===422){
          const { errors } = nextProps.user.error;
          Object.keys(errors).forEach(err=>{
            if(err === 'family_name')
              ubahErrors['last_name'] = errors[err];
            else if(err === 'sex')
              ubahErrors['gender'] = errors[err];
            else
              ubahErrors[err] = errors[err];
          })
        }
        this.setState({
          _submitProcess:!this.state._submitProcess,
          _error:{...nextProps.user.error, errors: {...ubahErrors}},
          _alertError: true,
          _errorMessage: nextProps.user.error.status_code===422?'Please fulfill your form.':nextProps.user.error.message
        })
      }
      else if(nextProps.user.detail.status_code===200){                
        if(this.state._submitProcess){
          if(!this.props.isEdit){
            this.props.history.replace(`/users/${nextProps.user.detail.id}`);                        
          }
          else{
            this.setState({
              _submitProcess:!this.state._submitProcess,
              _alertSuccess: true,
              _successMessage: 'Success update user.',
              _isChangePassword: false,
              password: null,
              confirm_password: null
            },()=>{
              this.bindUserCommissionData();
            })

            const  { userCommissions, _isCommissionChange } = this.state;
            if(_isCommissionChange){
              // console.log("TEST: ", userCommissions)
              const payload = userCommissions.filter(item => Number(item.rate) !== Number(item.defaultRate))
              .map(item => ({
                id: item.id,
                rate: item.notSet? item.defaultRate: (
                  (item.fixed) ? Number(item.rate) :
                  (item.rate - item.commissionAdd) < 0? item.commissionAdd: item.rate - item.commissionAdd
                ),
                fixed: item.fixed
              }))
              if(payload.length>0)
                this.props.updateUserCommission(this.state.id, { categories: payload })
            }
          }
        }
        else if(nextProps.user.detail.deleted){
          this.setState({
            _submitProcess: false,
            _alertSuccess: true,
            _successMessage: 'Success Deleted.'
          },()=>{
            this.props.clear();
            this.props.history.replace('/users');
          })
        }
      }
    }
    if(!user_setting.isSubmit&&user_setting.isSubmit!==this.props.user.user_setting.isSubmit){
      if(user_setting.error){
        this.setState({
          holiday_mode: !this.state.holiday_mode,
        })
      }
      else{
        if(user_setting.data && !user_setting.isSubmit && user_setting.status_code===200){
          const { holiday_mode } = user_setting.data;
          this.setState({holiday_mode})
        }
      }
    }      
  }

  componentDidUpdate(prevProps){
    if(prevProps.categories.length !== this.props.categories.length){
      this.bindUserCommissionData()
    }
    if(prevProps.user.userCommissions.isSubmit !== this.props.user.userCommissions.isSubmit && !this.props.user.userCommissions.isSubmit){
      const { error } = this.props.user.userCommissions;
      if(!error){
        this.props.find(this.props.params.id);
      }
      else{
        alert("An error occurred")
      }
    }
  }

  shouldComponentUpdate(nextProps){
    if(nextProps.user.user_setting.isSubmit===true){
      return false;
    }
    return true;
  }

  _handleHolidayModeCheckedChange(e){
    const { holiday_mode } = {...this.state};
    const { user: { detail }, userData: { roles }} = this.props;
    const checked = holiday_mode===true ? !holiday_mode : e.target.checked;
    if (!roles.includes('superadministrator') && detail.balance_with_fee < 0) {
      alert("This user seller credit is negative")
    } else {
      this.setState({ holiday_mode: checked }, 
        () => {
          this.props.forceHolidayMode({
            'active' : checked,
            'user_id' : detail.id
          });
        }
      );
    }
  }

  _handleOnSelectRoleOpen(){
    if(this.state.roles){
      return this.state._recentRoleSearch&&this.refs.selectRole.loadSelectOptions(this.state._recentRoleSearch);
    }
    return this.refs.selectRole.loadSelectOptions('');
  }

  _handleSelectChange(val){
    const _isDebtLimitOpen = val.some(i => i.label === 'debtor');
    const _isSellerPointsOpen = val.some(i => i.label === 'seller');
    this.setState({
      roles: val,
      _isDebtLimitOpen,
      _isSellerPointsOpen,
      _recentRoleSearch: val && this.state._recentRoleSearch
    });
  }

  _handleSelectNoteChange(value){
    if(value!=''){
      this.setState({'note': value.split(',').map(item=>({value: item.trim(), label: item}))})
    }
    else
      this.setState({'note': null})
  }

  _handleTextChange(e){
    this.setState({...textChange(e, this.state)});
  }

  removeAlerts(){
    this.setState({
      _error: null,
      _alertError:false,
      _alertSuccess:false
    });
  }

  toggleAlert(key){
    const lastState = {...this.state};
    lastState[key] = !lastState[key];
    this.setState({...lastState});
  }
    
  _toggleIsChangePassword(){
    this.setState({
      _isChangePassword:!this.state._isChangePassword
    })
  }

  _renderAlerts(){
    return(
      <div>
        <Alert color="success" isOpen={this.state._alertSuccess} toggle={()=>this.toggleAlert('_alertSuccess')}>
          <strong>Well done!</strong> { this.state._successMessage }
        </Alert>
        <Alert color="danger" isOpen={this.state._alertError} toggle={()=>this.toggleAlert('_alertError')}>
          <strong>Oh Snap!</strong> { this.state._errorMessage }
        </Alert>
      </div>
    )
  }

  _validateUserAction = async(action = 'update') => {
    const { userData: { roles, email }, validateScopeOnSubmit, scopes } = this.props;
    let emails = [];
    let granted = true;
    if(scopes && scopes[action]) emails = scopes[action];
    if(!roles.includes('superadministrator')){
      if(validateScopeOnSubmit && emails.length && emails.includes(email)){
        granted = true;
      }
      else{
        granted = false;
      }
    } else if(validateScopeOnSubmit && emails.length && !emails.includes(email)){
      granted = false;
    }
    if(!granted){
      const modalBody =  `ERROR: sorry, you CANNOT ${action.toUpperCase()} the data. Forbidden access.`;
      await this.setState({
        _modalInfo: {
          ...this.state._modalInfo,
          modalBody,
          isOpen: true
        }
      })
    }
    return granted;
  }

  _validateForm = async () => {
    let errors = {};
    await this.removeAlerts();
    if(this.state._isChangePassword){
      if( (this.state.password!== ''|| this.state.password!== null) && this.state.password !== this.state.confirm_password){
        errors = {
          ...errors,
          password: ['Password tidak sama dengan confirm password.']
        }
      }
    }
    if(this.state._isCommissionChange){
      const { userCommissions } = this.state;
      const commissionsErrors = userCommissions.reduce((curr, item) =>{
        if(!item.notSet && item.rate !== '') {
          let rate = String(item.rate)
          let decimal_point = rate.split(".")
          if(!(isNumeric(item.rate) || isNumeric(decimal_point[1]))){
            curr = {...curr, [`userCommission_${item.id}`]: ['This field must be numeric.']}
          }
          else if(Number(item.rate)>100){
            curr = {...curr, [`userCommission_${item.id}`]: ['Input ranges between 0 - 100']}
          }
        }
        return curr;
      }, {})
      errors = {
        ...errors,
        ...commissionsErrors
      }
    }
    if(this.state._isSellerPointsOpen){
      const { seller_points } = this.state
      let decimal_point = [seller_points, 0];
      if (seller_points % 1 !== 0) {
        decimal_point = seller_points.split(".")
      }
      if(!(isNumeric(seller_points) || isNumeric(decimal_point[1]))){
        errors = {
          ...errors,
          seller_points: ['This field must be numeric.']
        }
      }
      if(Number(seller_points)>10 || Number(seller_points)<0){
        errors = {
          ...errors,
          seller_points: ['Input ranges between 0 - 10.']
        }
      }
    }
    const hasErrors = Object.keys(errors).length>0;
    const error = hasErrors? {
      errors: { ...errors },
      status_code: 422,
      message:'Error from local.'
    }:null
    this.setState({
      _error: error,
      _alertError: hasErrors,
      _errorMessage: hasErrors? 'Please fulfill your form.': null
    })
    return !hasErrors;
  }

  async _onSubmit(e){
    e.preventDefault();
    const { id } = this.state;
    const { email } = this.props;
    if(id){
      const bool = await this._validateUserAction('update');
      if(!bool) return;
    }
    const valid = await this._validateForm();
    if(valid){
      this.setState({_submitProcess:true, _error:null},()=>{
        const getRoles = this.state.roles.map(role => ({id:role.value}));
        const isDebtorRole = this.state.roles.some(({ label }) => label === 'debtor');
        const getNote = this.state.note && this.state.note.map(({value}) => value ).reduce((accumulator, currentValue) => accumulator+', ' +currentValue);
        let payload = {
          id: this.props.params.id,
          username:this.state.username,
          email: this.state.email,
          typed_email: this.state.typed_email,
          first_name: this.state.first_name,
          family_name: this.state.last_name,
          sex: this.state.gender,
          debt_limit: isDebtorRole? this.state.debt_limit: 0,
          seller_points : this.state.seller_points,
          note:  getNote,
          roles: getRoles
        }
        const payloads ={
          userEmail: payload.email, 
          typedEmail: payload.typed_email, 
          firstName: payload.first_name,
          familyName: payload.family_name, 
          sex: payload.sex, 
          debtLimit: payload.debt_limit, 
          sellerPoints: payload.seller_points,
          note: payload.note, 
          roles: payload.roles
        }
        const type = id ? 'update' : 'create';
        Analytics.recordActionUser(
          type,
          payloads,
          email
        );
        if(this.state._isChangePassword===true)
          payload['password'] = this.state.password;
        this.props.save(payload);
      });
    }
  }

  _renderFooter(){
    const buttons = [
      {
        show:true,                
        component:<ButtonLoading type="submit" key="btnSubmit" isLoading={this.state._submitProcess} loadingMessage="Submitting...">Submit</ButtonLoading>
      },
      {
        show:this.props.isEdit,
        component:<Button key="btnDelete" color="danger" type="button" onClick={this._handleDeleteUser}>Delete</Button>
      }
    ]
    return(
      <FormGroup>
        {
          buttons.filter(btn => btn.show).map(({component}) => component)
        }
      </FormGroup>

    )
  }

  _handleDeleteUser = async () => {
    const { id, typed_email, first_name, last_name, sex, debt_limit, note, roles } = this.state;
    const { email } = this.props;

    const payloads ={
      userEmail: this.state.email, 
      typedEmail: typed_email, 
      firstName: first_name, 
      familyName: last_name, 
      sex: sex, 
      debtLimit: debt_limit, 
      note: note, 
      roles: roles, 
    }

    if(id){
      const bool = await this._validateUserAction('delete');
      if(!bool) return;
    }
    this.props.onDelete();
    Analytics.recordCreateUser(
      payloads,
      email
    );
  }

  _handleModalStockExportToggle = (payload = {}) =>{
    this.setState({
      modalStockExport: {
        ...this.state.modalStockExport,
        ...payload,
        isOpen: !this.state.modalStockExport.isOpen
      }
    })
  }

    render(){
      return(
        <Card>
          <ModalInfo
            modalTitle="Info"
            {...this.state._modalInfo}
            toggle={() => {
              const { _modalInfo } = this.state;
              this.setState({ _modalInfo: { ..._modalInfo, isOpen: !_modalInfo.isOpen } })
            }}
          />
          <ModalUserStockExport
            userId={this.state.modalStockExport.userId}
            email={this.state.modalStockExport.email}
            isOpen={this.state.modalStockExport.isOpen}
            toggle={this._handleModalStockExportToggle}
          />
          <CardHeader>
            <i className="fa fa-align-justify"></i> { this.props.formTitle }
          </CardHeader>
          <CardBody>
            {this._renderAlerts()}
            <Loading isLoading={this.props.user.isFetch}>
              <Form id="withScope" onSubmit={this._onSubmit}>
                <Row>
                  {this.state.buyer_points_status?(
                  <Col xs={6} md={6}>
                    <div style={{padding: '6px 12px', fontSize: '.65rem', margin: "0px 0px .75rem", background: '#ddd'}}>
                      <strong className="text-uppercase">Buyer : {this.state.buyer_points_status.tier_label}</strong><br/>
                      Points: {this.state.buyer_points_status.points}/{this.state.buyer_points_status.max_point? Number(this.state.buyer_points_status.max_point): '-'}
                    </div>
                  </Col>
                  ):null}
                  {this.state.seller_points_status?(
                  <Col xs={6} md={6}>
                    <div style={{padding: '6px 12px', fontSize: '.65rem', margin: "0px 0px .75rem", background: '#ddd'}}>
                      <strong className="text-uppercase">Seller: {this.state.seller_points_status}</strong><br/>
                      Points: {Number(this.state.seller_points)}
                    </div>
                  </Col>
                  ):null}
                  <div className="w-100"></div>
                  <Col xs={6} md={6}>
                    <FormGroup className={classNames({'has-danger has-feedback':hasError('username',this.state._error)})}>
                      <Label for="formUserName">
                        Username
                      </Label>
                      <Input
                        type="text"
                        id="formUserName"
                        name="username"
                        value={textValue('username',this.state)}
                        onChange={this._handleTextChange}
                        placeholder="Enter a username"
                      />
                      {errorMessage('username',this.state._error)}
                    </FormGroup>
                  </Col>
                  <Col xs={6} md={6}>
                    <FormGroup className={classNames({'has-danger has-feedback':hasError('holiday_mode', this.state._error)})}>
                      <label className="switch switch-text switch-success-outline-alt">
                        <input
                          type="checkbox"
                          className="switch-input"
                          name="holiday_mode"
                          value="true" checked={_getValue(this.state.holiday_mode)}
                          onChange={this._handleHolidayModeCheckedChange}
                        />
                        <span className="switch-label" data-on="On" data-off="Off"></span>
                        <span className="switch-handle"></span>
                      </label>
                      <Label style={{marginLeft:15, marginTop:3}}>Holiday-mode</Label>
                      {errorMessage('holiday_mode', this.state._error)}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={6}>
                    <FormGroup className={classNames({'has-danger has-feedback':hasError('first_name',this.state._error)})}>
                      <Label for="formFirstname">
                        First name
                      </Label>
                      <Input
                        type="text"
                        id="formFirstname"
                        name="first_name"
                        value={textValue('first_name',this.state)}
                        onChange={this._handleTextChange}
                        placeholder="Enter a firstname"
                      />
                      {errorMessage('first_name',this.state._error)}
                    </FormGroup>                                        
                  </Col>
                  <Col xs={12} md={6}>
                    <FormGroup className={classNames({'has-danger has-feedback':hasError('last_name',this.state._error)})}>
                      <Label for="formLastname">
                        Last name
                      </Label>
                      <Input
                        type="text"
                        id="formLastname"
                        name="last_name"
                        value={textValue('last_name',this.state)}
                        onChange={this._handleTextChange}
                        placeholder="Enter a lastname"
                      />
                      {errorMessage('last_name',this.state._error)}
                    </FormGroup>                                        
                  </Col>
                </Row>
                <Row>
                  <Col md={6} xs={12}>
                    <FormGroup className={classNames({'has-danger has-feedback':hasError('email',this.state._error)})}>
                      <Label for="formEmail">
                        Email
                      </Label>
                      <Input
                        type="text"
                        id="formEmail"
                        name="email"
                        value={textValue('email',this.state)}
                        onChange={this._handleTextChange}
                        placeholder="Enter a email*"
                      />
                      {errorMessage('email',this.state._error)}
                    </FormGroup>
                  </Col>
                  <Col md={6} xs={12}>
                    <FormGroup className={classNames({'has-danger has-feedback':hasError('typed_email',this.state._error)})}>
                      <Label for="formTypedEmail">
                        Typed Email
                      </Label>
                      <Input
                        type="text"
                        id="formTypedEmail"
                        name="typed_email"
                        value={textValue('typed_email',this.state)}
                        onChange={this._handleTextChange}
                        placeholder="Enter a typed email*"
                      />
                      {errorMessage('typed_email',this.state._error)}
                    </FormGroup>
                  </Col>
                  <Col md={6} xs={12}>
                    <FormGroup className={classNames({'has-danger has-feedback':hasError('gender',this.state._error)})}>
                      <Label for="formGender">
                        Gender
                      </Label>
                      <Input
                        type="select"
                        id="formGender"
                        name="gender"
                        value={textValue('gender',this.state)}
                        onChange={this._handleTextChange}
                        placeholder="Enter a gender"
                      >
                        <option value="M">Male</option>
                        <option value="F">Female</option>
                      </Input>
                      {errorMessage('gender',this.state._error)}
                    </FormGroup>
                  </Col>
                  <Col md={6} xs={12}>
                    <FormGroup className={classNames({'has-danger has-feedback':hasError('roles',this.state._error)})}>
                      <Label for="formRoles">
                        Roles
                      </Label>
                      <SelectRoleAsync
                        isMulti
                        defaultOptions
                        id="_formSelectRole"
                        ref="selectRole"
                        value={this.state.roles}
                        placeholder="Type and select a role..."
                        noResultsText="Cannot find role."
                        loadOptions={this._getRoleOptions}
                        onSelectSearch={(input)=>this.setState({_recentRoleSearch: input})}
                        onSelectChange={this._handleSelectChange}
                        onSelectOpen={this._handleOnSelectRoleOpen}
                      />
                      {errorMessage('roles',this.state._error)}
                    </FormGroup>
                  </Col>
                  <Col md={6} xs={12}>
                    <Collapse isOpen={this.state._isDebtLimitOpen}>
                      <FormGroup className={classNames({'has-danger has-feedback':hasError('debt_limit',this.state._error)})}>
                        <Label for="formDebtLimit">
                          Debt Limit
                        </Label>
                        <Input
                          type="text"
                          id="formDebtLimit"
                          name="debt_limit"
                          value={textValue('debt_limit',this.state)}
                          onChange={this._handleTextChange}
                          placeholder="Enter a debt limit."
                        />
                        {errorMessage('debt_limit',this.state._error)}
                      </FormGroup>
                    </Collapse>
                  </Col>
                  <Col md={6} xs={12}>
                    <Collapse isOpen={this.state._isSellerPointsOpen}>
                      <FormGroup className={classNames({'has-danger has-feedback':hasError('seller_points',this.state._error)})}>
                        <Label for="formSellerPoints">
                          Seller Points
                        </Label>
                        <Input
                          type="number"
                          id="formSellerPoints"
                          name="seller_points"
                          value={textValue('seller_points',this.state)}
                          onChange={this._handleTextChange}
                          placeholder="Enter a seller points."
                        />
                        {errorMessage('seller_points',this.state._error)}
                      </FormGroup>
                    </Collapse>
                  </Col>
                  <Col md={12} xs={12}>
                    <FormGroup className={classNames({'has-danger has-feedback':hasError('roles',this.state._error)})}>
                      <Label for="formNote">
                        Note
                      </Label>
                      <Creatable 
                        className="kickAveReactSelect"
                        options={this.state._notes}
                        pageSize={3}
                        multi 
                        simpleValue
                        value={this.state.note}
                        onChange={this._handleSelectNoteChange} 
                        placeholder="Put your notes..."
                      />
                      {/* <Input 
                        type="textarea" 
                        placeholder="Put your note..." 
                        id="formNote"
                        name="note"
                        value={textValue('note',this.state)}
                        onChange={this._handleTextChange}
                        rows={5}
                      /> */}
                      {errorMessage('roles',this.state._error)}
                    </FormGroup>
                  </Col>
                </Row>
                <div style={{marginBottom:15}} className={classNames({'d-none':!this.props.isEdit,'d-flex justify-content-end': this.props.isEdit})}>
                  <Button color="link" onClick={this._toggleIsChangePassword} type="button">{this.state._isChangePassword===true?'Batal':'Ganti Password'}</Button>
                </div>
                <Collapse isOpen={this.state._isChangePassword}>
                  <Row>
                    <Col md={6} xs={12}>
                      <FormGroup className={classNames({'has-danger has-feedback':hasError('password',this.state._error)})}>
                        <Label for="formPassword">
                          Password
                        </Label>
                        <Input
                          type="password"
                          id="formPassword"
                          name="password"
                          value={textValue('password',this.state)}
                          onChange={this._handleTextChange}
                          placeholder="Enter a password"
                        />
                        {errorMessage('password',this.state._error)}
                      </FormGroup>
                    </Col>
                    <Col md={6} xs={12} >
                      <FormGroup className={classNames({'has-danger has-feedback':hasError('confirm_password',this.state._error)})}>
                        <Label for="formConfirmPassword">
                          Confirm Password
                        </Label>
                        <Input
                          type="password"
                          id="formConfirmPassword"
                          name="confirm_password"
                          value={textValue('confirm_password',this.state)}
                          onChange={this._handleTextChange}
                          placeholder="Confirm password"
                        />
                        {errorMessage('confirm_password',this.state._error)}
                      </FormGroup>
                    </Col>
                  </Row>
                </Collapse>
                {this.state.id?(
                <div>
                  <hr/>
                  <Row>
                    <Col md={6} xs={12}>
                      <FormGroup>
                        <Label for="formSuspendedAt">
                          Last Suspended
                        </Label>
                        <Input
                          readOnly
                          type="text"
                          id="formSuspendedAt"
                          value={textValue('suspended_at',this.state)}
                        />
                      </FormGroup>
                    </Col>
                    <div className="w-100"></div>
                    <Col md={6} xs={12}>
                      <FormGroup>
                        <Label for="formCreatedAt">
                          Created At
                        </Label>
                        <Input
                          readOnly
                          type="text"
                          id="formCreatedAt"
                          value={textValue('created_at',this.state)}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={6} xs={12}>
                      <FormGroup>
                        <Label for="formUpdatedAt">
                          Updated At
                        </Label>
                        <Input
                          readOnly
                          type="text"
                          id="formUpdatedAt"
                          value={textValue('updated_at',this.state)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <hr/>
                  <p id="UncontrolledTooltipExample" className="mb-0">
                    <strong>Seller Comissions</strong>
                    <FontAwesomeIcon className="ml-1" style={{cursor: 'pointer'}} iconType="info-circle"/>
                  </p>
                  <UncontrolledTooltip target="UncontrolledTooltipExample">
                    <p className="text-left mb-0">
                      Commisson rules (coming soon, ask our dev):
                      <ul className="text-left" style={{fontSize: '.65rem', margin: 0, padding: 0, paddingLeft: '1rem'}}>
                        <li>Excellent: min. commission + 0%</li>
                        <li>Good: min. commission + 1%</li>
                      </ul>
                    </p>
                  </UncontrolledTooltip>
                  {this.state.userCommissions.map(item => (
                    <FormGroup key={item.id} row className={classNames({'has-danger has-feedback':hasError(`userCommission_${item.id}`,this.state._error)})}>
                      <Label for={`userCommission-${item.id}`} sm={4}>
                        For {item.name}<br/>
                        <span style={{fontSize: ".65rem"}}>Default Min: {item.defaultRate}% *</span><br />
                        {
                          this.state.seller_points_status && item.commissionAdd > 0?(
                            <div>
                              <span style={{fontSize: ".65rem"}}>
                                Additional: {item.commissionAdd}%
                              </span><br/>
                              <span style={{fontSize: ".65rem"}}>
                                Min: {item.settingRate}%
                              </span><br/>
                              {/* <span style={{fontSize: ".65rem"}}>Total: {item.settingRate}% {item.commissionAdd > 0 ? '+ ' + item.commissionAdd + '%' : null} *</span> */}
                            </div>
                          )
                          :null
                        }
                      </Label>
                      <Col sm={8} style={{display: "flex", alignItems: "center"}}>
                        <Row>
                          {!item.notSet?(
                            <Col xs={6}>
                              <InputGroup>
                                <Input
                                  type="text"
                                  id={`userCommission-${item.id}`}
                                  placeholder="numeric value"
                                  value={item.rate}
                                  className="text-right"
                                  onChange={e => {
                                    this.setState({
                                      userCommissions: this.state.userCommissions.map(d => {
                                        if(d.id === item.id){
                                          return {
                                            ...d,
                                            rate: e.target.value,
                                            isSubmit: true
                                          }
                                        }
                                        return d;
                                      }),
                                      _isCommissionChange: true
                                    })
                                  }}
                                />
                                <InputGroupAddon addonType="append">
                                  <InputGroupText>%</InputGroupText>
                                </InputGroupAddon>
                              </InputGroup>
                            </Col>
                          ): (
                            <Col xs={6} style={{display: "flex", alignItems: "center"}}>
                              <Button
                                type="button"
                                size="sm"
                                style={{fontSize: ".75rem"}}
                                onClick={() => {
                                  this.setState({
                                    userCommissions: this.state.userCommissions.map(d => {
                                      if(d.id === item.id){
                                        return {
                                          ...d,
                                          notSet: false
                                        }
                                      }
                                      return d;
                                    }),
                                    _isCommissionChange: true
                                  })
                                }}
                              >
                                Add Custom
                              </Button>
                            </Col>
                          )}
                          {!item.notSet?(
                            <Col xs={6} style={{display: "flex", alignItems: "center"}} className="mb-3">
                              <Button
                                type="button"
                                size="sm"
                                color="danger"
                                style={{fontSize: ".75rem"}}
                                onClick={() => {
                                  this.setState({
                                    userCommissions: this.state.userCommissions.map(d => {
                                      if(d.id === item.id){
                                        return {
                                          ...d,
                                          notSet: true,
                                          isSubmit: true
                                        }
                                      }
                                      return d;
                                    }),
                                    _isCommissionChange: true
                                  })
                                }}
                              >
                                Use Default
                              </Button>
                            </Col>
                          ): null}
                          {!item.notSet ? (
                            <Col xs={6}>
                              <FormGroupRadio
                                className={classNames('mb-0', { 'has-danger has-feedback': hasError('fixed') })}
                              >
                                <Radio
                                  type="checkbox"
                                  name="fixed"
                                  checked={item.fixed}
                                  dataOnText="Yes"
                                  dataOffText="No"
                                  onChange={() => {
                                    this.setState({
                                    userCommissions: this.state.userCommissions.map(d => {
                                      if(d.id === item.id){
                                        return {
                                          ...d,
                                          fixed: !item.fixed
                                        }
                                      }
                                      return d;
                                    }),
                                    _isCommissionChange: true
                                  })
                                  }}
                                />
                                <RadioText text="Fixed"/>
                                { errorMessage('fixed',this.state._error) }
                              </FormGroupRadio>
                            </Col>
                          ): null}
                          <Col>
                            {errorMessage(`userCommission_${item.id}`,this.state._error)}
                          </Col>
                          {
                            !item.notSet && item.commissionAdd>0 && !item.fixed?
                            <div className="w-100" style={{padding: '6px 12px'}}>
                              <p className="mb-0" style={{fontSize:'.65rem'}}>Included additional percentages.</p>
                            </div>
                            :null
                          }
                        </Row>
                      </Col>
                    </FormGroup>
                  ))}
                </div>
                ):null}
                {this._renderFooter()}
              </Form>
              {this.state.id && (
                <div>
                  <Button size="sm" onClick={() => this._handleModalStockExportToggle({userId: this.state.id, email: this.state.email})}>
                    <i className="icon-share-alt"></i> Export Stock
                  </Button>
                </div>
              )}
            </Loading>
          </CardBody>
        </Card>
      )
    }
}

const mapStateToProps = (state) => {
  return{
    role: state.role,
    user: state.user,
    categories: state.masterCategories.options,
    email: state.auth.email
  };
}

const mapDispatchToProps = (dispatch) => {
  return{
    getRole:(payload)=>dispatch(getListRole(payload)),
    find:(id)=>dispatch(getDetailUser(id)),
    save:(payload)=>dispatch(saveUser(payload)),
    clear: ()=> dispatch({type:USER_CLEAR_FORM}),
    findRoles: (id) => dispatch(getUserRoles(id)),
    forceHolidayMode: (payload) => dispatch(forceHolidayMode(payload)),
    getCategories: () => dispatch(getOptionList()),
    updateUserCommission: (userId, payload) => dispatch(updateUserCommission(userId, payload)),
  };
}

export default connect(mapStateToProps,mapDispatchToProps)(FormCard);
FormCard.propTypes = {
  isEdit : propTypes.bool,
  params : propTypes.object,
  history : propTypes.object,
  onDelete : propTypes.func,
  formTitle : propTypes.string.isRequired
}

FormCard.defaultProps = {
  isEdit : false
}