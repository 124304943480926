import React from 'react';
import propTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import noImage from '../../../assets/img/no-image.png';
import { getCacheImage } from '../../../utils/imageHelper';

const ProductLink = styled(Link)`
  text-decoration: none;
  color: black;
  display: block;
  &:hover, &:focus, &:active{
    color: black;
    text-decoration: none;
  }
`;

const ProducBtn = styled.button`
  display: block;
  background: none;
  padding: 0;
  border: none;
  text-align: left;
  width: 100%;
`;

const ProductWrapper = styled.div`
  display: flex;
  padding: .5rem 0px;
  border-bottom: 1px solid #ddd;
  margin-bottom: .5rem;
`;

const ProductContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  justify-content: center;
  > img {
    height: 80px;
    object-fit: contain;
    max-width: 112px;
  }
`;

const ProductDescription = styled.div`
  display: flex;
  margin-left: .5rem;
  width: 100%;
  flex-direction: column;
  p{
    margin: 0;
    text-decoration: underline;
  }
`;

const StyledDropdown = styled.div`
  position: absolute;
  top: 10px;
  right: 0;
  z-index: 10;
  p{
    margin-bottom: 8px;
  }
  select {
    border: solid 1px #d9d9d9;
    width: 118px;
    padding: 9px;
  }
`
const SequenceWrapper = styled.div`
  position: relative;
  .sequence-product-text{
    max-width: calc(100% - 188px);
    width: 100%;
  }
`

const Product = ({ title, onClick, id, linkType, children, className }) =>
  linkType === 'link' ? (
    <ProductLink
      to={`/product_variants/${id}`}
      title={title}
      target="_blank"
      className={className}
    >
      {children}
    </ProductLink>
  ) : (
    <ProducBtn title={title} onClick={onClick}>
      {children}
    </ProducBtn>
  )

const getPublicImageUrl = image => {
  return image.signed_url || image.URL
}

const SeekProductDetail = props => {
  const thumb = props.images && props.images.length ? getPublicImageUrl(props.images[0]) : noImage;
  const useSequence = props.useSequence
  return !useSequence ?
    (<Product id={props.id} linkType={props.linkType} onClick={props.onClick} title={props.title}>
      <ProductWrapper>
        <ProductContent>
          <img alt="Image Thumbnail" src={getCacheImage(thumb)} />
        </ProductContent>
        <ProductDescription>
          <ProductContent>
            <p>
              {props.displayName}
            </p>
            <div style={{ fontSize: '.65rem', fontStyle: 'italic' }}>
              {props.colour}
            </div>
            <div style={{ fontSize: '.65rem' }}>
              {props.SKU}
            </div>
          </ProductContent>
        </ProductDescription>
      </ProductWrapper>
    </Product>)
    : (
      <SequenceWrapper>
        <Product id={props.id} linkType={props.linkType} onClick={props.onClick} title={props.title}>
          <ProductWrapper>
            <ProductContent>
              <img alt="Image Thumbnail" src={getCacheImage(thumb)} />
            </ProductContent>
            <ProductDescription>
              <ProductContent className="sequence-product-text" >
                <p>
                  {props.displayName}
                </p>
                <div style={{ fontSize: '.65rem', fontStyle: 'italic' }}>
                  {props.colour}
                </div>
                <div style={{ fontSize: '.65rem' }}>
                  {props.SKU}
                </div>
              </ProductContent>
            </ProductDescription>
          </ProductWrapper>
        </Product>

        <StyledDropdown>
          <p>Sequence</p>
          <select onChange={(e) => {
            props.updatePosition({...props.currentData, currentIdx: e.target.value})
          }}>
            {
              props.list?.length ? props.list.map((l, key) => {
                return <option value={key} selected={l.pivot.position == props.selected} key={`position-${key}`}>{l.pivot.position}</option>
              }) : null
            }
          </select>
        </StyledDropdown>
      </SequenceWrapper>
    )
}
export default SeekProductDetail;

SeekProductDetail.propTypes = {
  id: propTypes.number.isRequired,
  linkType: propTypes.oneOf(['link', 'button']),
  images: propTypes.arrayOf(propTypes.object),
  displayName: propTypes.string,
  colour: propTypes.string,
  SKU: propTypes.string,
  title: propTypes.string
}

SeekProductDetail.defaultProps = {
  linkType: 'link',
  images: [],
  displayName: '-',
  SKU: '',
  colour: '',
  title: 'Go to product details'
}