import React from 'react';
import _ from 'lodash';
import propTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Card, CardHeader, CardBody, Input
} from 'reactstrap';
import { getUserShippingList } from '../../../actions/userAction';
import { Tbl, TblHead, TblBody } from '../../../components/Tbl';
import { generateNumber } from '../../../utils';
import RowItem from '../UserForm/userShippingListCard/RowItem';
import Paginations from '../../../components/Paginations';
import { DEFAULT_SORT_BY } from '../../../constants/settings';

const dataHeader = ['No.', 'Address', 'Updated'];

const defaultParams = {
  sort_by : DEFAULT_SORT_BY,
  per_page : 3
}

class UserShippingListCard extends React.Component{
  constructor(props){
    super(props);
    this.state={
      _params:{
        ...defaultParams,
      },
      _filterOpen:false
    }
    this._handleGoto = this._handleGoto.bind(this);
    this._throttleGetList = _.debounce(this._getList.bind(this), 500)
  }

  componentDidMount(){
    this._getList(this.state._params);
  }

  _getList(rawParams = {} ){
    let params = {};
    Object.keys(rawParams)
    .filter(key => rawParams[key]!='')
    .forEach(key=> params[key] = rawParams[key]);
    this.props.getList(this.props.userId, {params});
  }

  _handleGoto(page){
		const _params=this.state._params;
		_params.page=page;
		this.setState({_params},()=>this._getList(this.state._params));
	}

  _onRenderPagination(){
    const { list } = this.props.dataUserShipping;
    const hasData = (this.props.dataUserShipping.status_code===200 && list.data.length) || null;
    return hasData && (
      <Paginations 
        size="sm" 
        lastPage={list.last_page} 
        perPage={list.per_page}
        total={list.total}
        maxPage={8}
        path={list.path}
        currentPage={list.current_page} 
        prevPageUrl={list.prev_page_url} 
        nextPageUrl={list.next_page_url} 
        handleGotoCallback={this._handleGoto}
      />
    )
  }

  _renderRowItem(){
    if(this.props.dataUserShipping.isFetch === false && this.props.dataUserShipping.status_code===200){
      const { data, current_page, per_page } = this.props.dataUserShipping.list;
      return data.map((item, index)=>{
        return (
          <RowItem
            key={item.id}
            id={item.id}
            default={item.is_default}
            number={generateNumber(per_page, current_page, index)}
            alias={item.alias}
            address={item.street_address}
            fullName={item.full_name}
            phoneNumber={item.phone_number}
            country={item.country}
            province={item.province}
            city={item.city}
            district={item.district}
            subDistrict={item.sub_district}
            postalCode={item.postal_code}
            note={item.note}
            updatedAt={item.updated_at}
            createdAt={item.created_at}
          />
        )
      })
    }
    return null;
  }

  render(){
    // const { _params } = this.state;
    const { dataUserShipping } = this.props;
    return(
      <Card>
        <CardHeader><i className="fa fa-align-justify"></i>User Shipping</CardHeader>
        <CardBody>
          <div className="d-flex mb-3">
            <Input
              type="text"
              placeholder="Search by alias..."
              name="keyword"
              value={this.state._params.keyword || ""}
              onChange={async(e) => {
                await this.setState({_params: { ...this.state._params, [e.target.name]: e.target.value, page: 1 }})
                this._throttleGetList(this.state._params)
              }}
            />
          </div>
          <div className="table-responsive" style={{maxHeight: 500, overflow: 'auto'}}>
            <Tbl>
              <TblHead dataHeader={dataHeader}/>
              <TblBody
                isLoad={dataUserShipping.isFetch}
                hasRow={dataUserShipping.status_code===200 && dataUserShipping.list.data.length}
                columnCount={dataHeader.length}
              >
              { this._renderRowItem() }
              </TblBody>
            </Tbl>
          </div>
          { this._onRenderPagination() }
        </CardBody> 
      </Card>
    )
  }
}

const mapStateToProps = ({user:{ detail }}) => ({
  dataUser: detail,
  dataUserShipping: detail.user_shipping
})
const mapDispatchToProps = dispatch => ({
  getList: (userId, params) => dispatch(getUserShippingList(userId, params)),
})
export default connect(mapStateToProps,mapDispatchToProps)(UserShippingListCard);
UserShippingListCard.propTypes={
  userId: propTypes.oneOfType([propTypes.number, propTypes.string]).isRequired
}