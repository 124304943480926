import React from 'react';
import propTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  Row,
  Col,
  Card,
  CardBody,
  CardText
} from 'reactstrap';

import { CardHeaderWithToolbars,Title } from '../../components/Card/CardHeaderWithToolbars';
import {Tbl, TblHead, TblBody} from '../../components/Tbl';
import Paginations from '../../components/Paginations';
import PaginationSummaries from '../../components/Paginations/PaginationSummaries';
import FontAwesomeIcon from '../../components/Icons/FontAwesomeIcon';

import { getBidList, deleteBid } from '../../actions/bidAction';

import { generateNumber } from '../../utils';
import { DEFAULT_PER_PAGE } from '../../constants/settings';
import privateView from '../../components/hocs/privateView';
import RowItem from './bidIndex/RowItem';
import Toolbars from './bidIndex/Toolbars';
import FilterPanel from './bidIndex/FilterPanel';
import DropdownProductCategories from '../../components/Dropdowns/DropdownProductCategories';
import { Th } from '../../components/Tbl';
import Analytics from "../../services/Analytics";

const defaultParams={
  sort_by : 'createdAt_desc',
  per_page : DEFAULT_PER_PAGE
}

class BidIndex extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      _isFilterOpen: false,
      _isResetFilter: false,
      _params: {
        ...defaultParams
      },
      _selectedCategory: null
    }
    this._handleGoto = this._handleGoto.bind(this);
    this._refreshList = this._refreshList.bind(this);
    this._handleRefresh = this._handleRefresh.bind(this);
    this._handleResetFilter = this._handleResetFilter.bind(this);
    this._handleApplyFilter = this._handleApplyFilter.bind(this);
    this._handleCategoryChanged = this._handleCategoryChanged.bind(this);
  }

  componentDidMount(){
    const { email } = this.props.injectedProps;
    this._getList(this.state._params);
    Analytics.recordPageView('offers_list', email, {
      url: window.location.href,
      location: this.props.location,
      match: this.props.match
    });
  }

  _getList(rawParams = {}){
    let params = {};
    Object.keys(rawParams)
    .filter(key => rawParams[key]!='')
    .forEach(key=> params[key] = rawParams[key]);
    this.props.getList({params});
  }
  _handleRefresh(e){
    e.preventDefault();
    this._refreshList();
  }

  _handleApplyFilter(payloadFilter){
    // e.preventDefault();
    // const { sort_by, ..._params } = payloadFilter;
    this.setState({
      _params: {
        ...this.state._params,
        ...payloadFilter,
        page: 1
      },
    },()=>this._getList(this.state._params));
  }

  _refreshList(){
    const { _params } = this.state;
    this.setState({
      _params:{ ..._params, page: 1 },
    },()=>this._getList(this.state._params));
  }

  _handleResetFilter(e){
    e.preventDefault();
    this.setState({
      _params:{
        ...defaultParams
      },
      _isResetFilter: true,
      _selectedCategory: null
    },()=>this._getList(this.state._params));
  }

  _handleGoto(page){
    const _params = this.state._params;
    _params.page = page;
    this.setState({ _params },()=>this._getList(this.state._params));
  }

  async _handleCategoryChanged(category){
    await this.setState({
      _selectedCategory: category === ''? null: category.label,
      _params: {
        ...this.state._params,
        category_id: category === ''? category: category.value,
        page: 1,
      }
    });
    const { _params } = this.state;
    this._getList(_params);
  }

  _onRenderPagination(){
    const { status_code, data } = this.props.bidList;
    const hasData = (status_code===200 && data.length)||null;
    return hasData && (
      <Paginations
        isLoading={this.props.bidList.isFetch}
        size="sm"
        lastPage={this.props.bidList.last_page}
        perPage={this.props.bidList.per_page}
        total={this.props.bidList.total}
        maxPage={8}
        path={this.props.bidList.path}
        currentPage={this.props.bidList.current_page}
        prevPageUrl={this.props.bidList.prev_page_url}
        nextPageUrl={this.props.bidList.next_page_url}
        handleGotoCallback={this._handleGoto}
        onCurrentPageChanged={() => {
          window.scrollTo({
            top: this.tableRef.offsetTop+25,
            behavior: 'smooth'
          })
        }}
      />
    )
	}

  render() {
    return (
      <div className="animated fadeIn">
        <Row>
          <Col xs={12}>
            <Card>
              <CardHeaderWithToolbars>
                <Title>
                  <FontAwesomeIcon iconType="align-justify"/>
                  <span>User Offer List</span>
                </Title>
                <Toolbars
                  isOpen={this.state._isFilterOpen}
                  onToggleFilter={()=> this.setState({ _isFilterOpen: !this.state._isFilterOpen })}
                  onRefreshList={this._handleRefresh}
                  onResetFilter={this._handleResetFilter}
                />
              </CardHeaderWithToolbars>
              <FilterPanel
                {...this.state._params}
                isOpen={this.state._isFilterOpen}
                isReset={this.state._isResetFilter}
                onApplyFilter={this._handleApplyFilter}
                onResetFilterCallback={()=>this.setState({_isResetFilter: false, _isFilterOpen: false})}
              />
              <CardBody>
                <CardText>
                  In this section will display our user's offers.
                </CardText>
                <div className="table-responsive" ref={tableRef => this.tableRef = tableRef}>
                  <PaginationSummaries
                    from={this.props.bidList.from}
                    to={this.props.bidList.to}
                    total={this.props.bidList.total}
                  />
                  <Tbl>
                    <TblHead
                      renderChildren={()=>(
                        <tr>
                          <Th>No.</Th>
                          <Th>
                            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'flex-end'}}>
                              <p style={{marginRight: 10, marginBottom: 0}}>
                                Offer
                                {
                                  this.state._selectedCategory !== null?
                                  <span style={{display: 'block', fontSize: '.5rem'}}>{this.state._selectedCategory}</span>
                                  : null
                                }
                              </p>
                              <DropdownProductCategories
                                value={this.state._params.category_id}
                                onCategoryChanged={this._handleCategoryChanged}
                              />
                            </div>
                          </Th>
                          <Th>User</Th>
                          <Th>Payment</Th>
                          <Th>Status</Th>
                          <Th>Updated</Th>
                          <Th>Action</Th>
                        </tr>
                      )}
                    />
                    <TblBody
                      isLoad={this.props.bidList.isFetch}
                      hasRow={this.props.bidList.status_code===200&&this.props.bidList.data.length>0}
                      columnCount={7}
                      renderChildren={()=>{
                        const { current_page,per_page } =  this.props.bidList;
                        return this.props.bidList.data.map((item, index)=> {
                          const rowNumber = generateNumber(per_page,current_page,index);
                          return (
                            <RowItem
                              key={item.id}
                              id={item.id}
                              rowNumber={rowNumber}
                              category={item.product_variant.product.category&&item.product_variant.product.category.name}
                              code={item.code}
                              user={item.user}
                              seller={item.user_sell && item.user_sell.user}
                              amount={item.amount}
                              paymentMethod={item.payment_method}
                              updatedAt={item.updated_at}
                              createdAt={item.created_at}
                              expiredAt={item.expired_at}
                              deletedAt={item.deleted_at}
                              status={item.status}
                              payment={item.offer}
                              size={item.size.US}
                              displayName={item.product_variant.display_name}
                              colour={item.product_variant.colour}
                              SKU={item.product_variant.SKU}
                              onDeleteBid={(id)=>this.props.deleteBid(id)}
                            />
                          )
                        })
                      }}
                    >
                    </TblBody>
                  </Tbl>
                  { this._onRenderPagination() }
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    )
  }
}


const mapStateToProps = ({ bid: { list }, auth: { isFetch, isLogged, roles, id, email } }) => ({
  bidList: list,
  guardData: { isFetch, isLogged, roles, id, email }
})

const mapDispatchToProps = dispatch => ({
  getList: query => dispatch(getBidList(query)),
  deleteBid: id => dispatch(deleteBid(id))
})

const enhance = connect(mapStateToProps,mapDispatchToProps);

export default enhance(privateView(BidIndex));

BidIndex.defaultProps = {

}

BidIndex.propTypes = {

}