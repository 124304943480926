import React, { Component } from 'react';
import propTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import {
  Row,
  Col,
  Card,
  Button,
  Alert,
  ButtonGroup,
  CardBody,
  Form,
  InputGroup,
  InputGroupAddon,
  Input
} from 'reactstrap';
import { Tbl, TblHead, TblBody } from '../../../components/Tbl';
import Paginations from '../../../components/Paginations';
import DateToHuman from '../../../components/DateToHuman';
import CheckReadOnly from '../../../components/CheckReadOnly';
import { CardHeaderWithToolbars, Title } from '../../../components/Card/CardHeaderWithToolbars';
import { generateNumber } from '../../../utils';
import { ModalUserVoucher } from './Modals';
import { ModalDelete } from '../../../components/Modals';

const dataHeader = ['No. ', 'User', 'Used', 'Count', 'Started At', 'Ended At', 'Last Used', 'Action'];
export default class UserVoucherList extends Component{
  constructor(props){
    super(props);
    this.state={
      _params:{
        keyword: ''
      },
      _error:null,
      _selectedId:null,
      _modalDelete:false,
      _modalUserVoucher:false,
      _userVoucherSelected:null,
      _selectedRow:null,
      _isAlertErrorOpen:false
    }
    this._toggle = this._toggle.bind(this);
    this._handleGoto = this._handleGoto.bind(this);
    this._handleSubmit=this._handleSubmit.bind(this);
    this._onRemoveSelected=this._onRemoveSelected.bind(this);
    this._handleModalDeleteToggle = this._handleModalDeleteToggle.bind(this);
    this._handleDeleteUserVoucher = this._handleDeleteUserVoucher.bind(this);
    this._handleAlertErrorToggle = this._handleAlertErrorToggle.bind(this);
    this._getList = this._getList.bind(this);
  }
  componentDidMount(){
    this._getList(this.state._params)
  }

  _getList(rawParams = {} ){
    const params = Object.keys(rawParams)
    .filter(key => rawParams[key]!='')
    .reduce((current, key) =>{
      return {...current, [key]: rawParams[key]}
    },{});
    this.props.onGetList(this.props.id, {params});
  }

  componentWillReceiveProps(nextProps) {
    const { dataUserVouchers } = nextProps;
    if(this.state._modalDelete&&dataUserVouchers.isSubmit!==this.props.dataUserVouchers.isSubmit
      &&dataUserVouchers.isSubmit===false){
        if(dataUserVouchers.isDelete){
          this.setState({
            _modalDelete:false,
            _selectedId:null,
            _params:{
              ...this.state._params,
              page: 1
          }}, ()=>{
            this._onRemoveSelected();
            this._getList(this.state._params);
          });
        }
    }
    if(dataUserVouchers.isSubmit!==this.props.dataUserVouchers.isSubmit
      &&dataUserVouchers.isSubmit===false){
        const { error } = dataUserVouchers;
        if(error)
          this.setState({_modalDelete:false, _error:{...dataUserVouchers.error}}, ()=>this._handleAlertErrorToggle());
    }
  }

  _onOpenModal(item){
    this.setState({_isAlertErrorOpen:false, _userVoucherSelected: item, _selectedRow: item.id},()=>this._toggle());
  }

  _onRemoveSelected(){
    this.setState({
      _isAlertErrorOpen:false,
      _userVoucherSelected:null,
      _selectedRow:null,
      _error:null
    });
  }

  _renderButtonUserVoucher(item){
    if(this.props.voucherType == 'private'){
      return(
        <ButtonGroup>
          <Button color="success" onClick={()=>this._onOpenModal(item)}><i className="icon-pencil"></i></Button>
          <Button color="danger" onClick={()=>this._handleModalDeleteToggle(item)}><i className="icon-trash"></i></Button>
        </ButtonGroup>
      )
    }
  }
  _onRenderRowItem(){
    if(this.props.dataUserVouchers.status_code===200 && this.props.dataUserVouchers.list.length>0)
    {
      const { current_page, per_page, list } =  this.props.dataUserVouchers;
      return list.map((item,index)=>(
          <tr key={generateNumber(per_page,current_page,index)}>
            <th scope="row">{ generateNumber(per_page,current_page,index) }</th>
            <td><Link title="User Details..." target="_blank" to={`/users/${item.user.id}`}>{ item.user.email }</Link></td>
            <td><CheckReadOnly check={item.used}/></td>
            <td>{ item.used_count }</td>
            <td>{ item.started_at? item.started_at: '-' }</td>
            <td>{ item.ended_at? item.ended_at: '-' }</td>
            <td>
              {
                item.used_at ?
                  <DateToHuman value={item.used_at} id={`tooltip_userVoucher_rowItem_${generateNumber(per_page,current_page,index)}`}/>
                :
                '-'
              }
            </td>
            <td>{ this._renderButtonUserVoucher(item) }</td>
          </tr>
        )
      )
    }
    return null;
  }

  async _handleGoto(page){
    const _params=this.state._params;
    _params.page=page;
    await this.setState({_params});
    this._getList(this.state._params)
  }

  _toggle() {
    this.setState({
      _modalUserVoucher: !this.state._modalUserVoucher
    });
  }

  _handleDeleteUserVoucher(){
    const { _selectedId, _userVoucherSelected } = this.state;
    this.props.onDelete(_selectedId, _userVoucherSelected.voucher_id);
  }

  _handleModalDeleteToggle(item){
      this.setState({
        _isAlertErrorOpen:false,
        _userVoucherSelected: item? item: null,
        _selectedId:item? item.id: null,
        _modalDelete: !this.state._modalDelete
      });
  }

  _handleAlertErrorToggle(){
    this.setState({
      _isAlertErrorOpen: !this.state._isAlertErrorOpen
    })
  }

  _handleSubmit(payload) {
    this.props.saveUserVoucher(payload);
  }

  _onRenderPagination(){
    const { status_code, list, ...paginations } = this.props.dataUserVouchers;
    const hasData = (status_code===200 && list.length) || null;
    return hasData && (
      <Paginations
        size="sm"
        lastPage={paginations.last_page}
        perPage={paginations.per_page}
        total={paginations.total}
        maxPage={8}
        path={paginations.path}
        currentPage={paginations.current_page}
        prevPageUrl={paginations.prev_page_url}
        nextPageUrl={paginations.next_page_url}
        handleGotoCallback={this._handleGoto}
      />
    )
  }

  _renderErrorMsg(){
    const { _isAlertErrorOpen, _error } = this.state;
    if(_isAlertErrorOpen && _error!==null){
      switch(_error.status_code){
        case 422:
          return <Alert color="danger" isOpen={_isAlertErrorOpen} toggle={this._handleAlertErrorToggle}>Please fulfill your form</Alert>
        default:
          return <Alert color="danger" isOpen={_isAlertErrorOpen} toggle={this._handleAlertErrorToggle}>Houston, we have a problem!. Please inform our friendly staff via LINE @kickavenue</Alert>
      }
    }
    return null;
  }

  render(){
    return(
      <Card>
        <Col xs={12}>
          <ModalDelete
            isOpen={this.state._modalDelete}
            toggle={this._handleModalDeleteToggle}
            onDelete={this._handleDeleteUserVoucher}
          />
          <ModalUserVoucher
            isOpen={this.state._modalUserVoucher}
            onCancel={this._toggle}
            onRemoveSelected={this._onRemoveSelected}
            dataUserVouchers={this.props.dataUserVouchers}
            voucherId={this.props.id}
            dataAuth={this.props.dataAuth}
            onSubmit={this._handleSubmit}
            userVoucherSelected={this.state._userVoucherSelected}
            onGetUserVoucherList={async () => {
              await this.setState({
                _params:{
                  ...this.state._params,
                  page: 1
                }
              });
              this._getList(this.state._params)
            }}
          />
        </Col>
        <CardHeaderWithToolbars>
          <Title>
            <i className="fa fa-align-justify"></i>
            <span>User Vouchers</span>
          </Title>
        </CardHeaderWithToolbars>
        <CardBody>
          {
            this.props.voucherType == 'private' ?
            <div className="mb-3">
              <Button title="New User Voucher" onClick={this._toggle}><i className="fa fa-plus"></i> New User Voucher</Button>
            </div>
            :
            null
          }
          <Row>
            <Col xs={12} md={6} className="mb-3">
              <Form onSubmit={e=>{
                e.preventDefault();
                this._getList(this.state._params)
              }}>
                <InputGroup size="md">
                  <Input
                    value={this.state._params.keyword}
                    placeholder="Enter user's email..."
                    name="keyword"
                    onChange={e=>{
                      const { _params } = this.state;
                      this.setState({
                        _params:{
                          ..._params,
                          [e.target.name]: e.target.value,
                          page: 1
                        }
                      })
                    }}
                  />
                  <InputGroupAddon addonType="append">
                    <Button
                      className={classNames({"d-none": this.state._params.keyword.length === 0})}
                      title="Clear"
                      type="button"
                      onClick={async () => {
                        const { _params } = this.state;
                        await this.setState({
                          _params: {
                            ..._params,
                            keyword: '',
                            page: 1
                          }
                        });
                        this._getList(this.state._params)
                      }}
                    >
                      <i className="fa fa-times"></i>
                    </Button>
                    <Button color="primary" style={{display: 'flex', height: '100%'}}>
                      <i className="fa fa-search"></i>
                      <span className="ml-1 d-none d-md-block">Search</span>
                    </Button>
                  </InputGroupAddon>
                </InputGroup>
              </Form>
            </Col>
          </Row>
          { this._renderErrorMsg() }
          <div className="table-responsive">
            <Tbl>
              <TblHead
                  dataHeader={dataHeader}
              />
              <TblBody
                isLoad={this.props.dataUserVouchers.isFetch}
                hasRow={this.props.dataUserVouchers.status_code===200&&this.props.dataUserVouchers.list.length>0}
                columnCount={dataHeader.length}
              >
                { this._onRenderRowItem() }
              </TblBody>
            </Tbl>
          </div>
          { this._onRenderPagination() }
        </CardBody>
      </Card>
    )
  }
}
UserVoucherList.propTypes = {
  id: propTypes.number.isRequired,
  onGetList: propTypes.func.isRequired,
  onDelete: propTypes.func.isRequired,
  dataUserVouchers: propTypes.object,
  dataAuth: propTypes.object,
  saveUserVoucher: propTypes.func.isRequired,
  voucherType: propTypes.string,
}
UserVoucherList.defaultProps = {

}