import React from 'react';
import { Th } from '../../../../../components/Tbl';
import { DropdownCategories } from '../../../Components';

const RowHeader = props => (
    <tr>
        <Th>No.</Th>
        {
            props.timer ?
                <Th>Spend Time</Th>
                :
                null
        }
        <Th>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end' }}>
                <p style={{ marginRight: 10, marginBottom: 0 }}>
                    Product
                    {
                        props.categoryName !== null ?
                            <span className="text-capitalize" style={{ display: 'block', fontSize: '.5rem' }}>{props.categoryName}</span>
                            : null
                    }
                </p>
                <DropdownCategories
                    value={props.categoryValue}
                    onCategoryChanged={props.onCategoryChanged}
                />
            </div>
        </Th>
        <Th>Consignment Price</Th>
        <Th>Shipping Method</Th>
        <Th>Last Updated</Th>
        <Th>Action</Th>
    </tr>
)

export default RowHeader;
