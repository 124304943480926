import React from 'react';
import { Link } from 'react-router-dom';
import {
  Alert,
  Card,
  CardText,
  CardSubtitle
} from 'reactstrap';

const VoucherCard = props => (
  <Card body className="mb-0">
    {props.voucher?(
      <div>
        <div style={{fontSize: '.65rem'}} className="mb-3">
          <div>
            VOUCHER (ID: {props.voucher_id}) {' '}
            {
              !props.readOnly?(
                <a
                  href="#"
                  style={{textDecoration: 'underline'}}
                  onClick={props.onBrowseVoucherClick}
                >
                  Choose another voucher.
                </a>
              ):null
            }
          </div>
          <div>
            ({props.voucher.active? 'active': 'inactive'}){' '}
            <Link
              to={`/vouchers/${props.voucher_id}`}
              target="_blank"
            >
              More details.
            </Link>
          </div>
        </div>
        <CardSubtitle>
          <strong>{props.voucher_id? props.voucher? props.voucher.code: props.voucher_id: '-'}</strong>
          {' '}
        </CardSubtitle>
        <CardText style={{fontSize: '.75rem'}} className="mb-3">
          {props.voucher.description && props.voucher.description.length? props.voucher.description:'-'}<br/>
          start: {props.voucher.started_at? props.voucher.started_at:'-'};{' '}
          end: {props.voucher.ended_at? props.voucher.ended_at:'-'};{' '}
        </CardText>
      </div>
    ):(
      <Alert isOpen color="info">
        <strong>Info: </strong>
        You haven't selected a voucher. {' '}
        <a
          href="#"
          style={{textDecoration: 'underline'}}
          onClick={props.onBrowseVoucherClick}
        >
          Choose a voucher.
        </a>
      </Alert>
    )}
    <p className="mb-0" style={{fontSize: '.75rem', fontStyle: 'italic'}}>
      User that use the voucher in their purchases can join and get raffle tickets every Rp.500.000,- when transaction succeeded automatically.
    </p>
  </Card>
)

export default VoucherCard;