import React from 'react';
import {
  Card,
  CardHeader,
  CardBody,
  Col,
  Row,
  Alert,
  Button,
  Collapse
} from 'reactstrap';
import propTypes from 'prop-types';
import ImageCard from './imageListCard/ImageCard';
import BasicDropzone from '../../../../components/Form/DropzoneFile/BasicDropzone';
import CardNewImageThumbnail from '../../../../components/Card/ImageList/CardNewImageThumbnail';
import Icon from '../../../../components/Icons/FontAwesomeIcon';
import classNames from 'classnames';

const ImageListCard = props => {
  const content = props.images.length?
    props.images.map((item,index)=>
      <Col key={item.id} xs={12} sm={6} md={3}>
        <ImageCard
          {...item}
          alt={item.URL}
          onRemoveImage={(evt)=> props.onRemoveImage(evt, index)}
          onChangeImage={props.onChangeImage}
        />
      </Col>
    ):
    (
      <Col>
        <Alert color="danger" style={{flex:1, textAlign:'center'}} className="mb-0"><strong>Ooops.. </strong> No Images Found!</Alert>
      </Col>
    )

  return (
    <Card>
      <CardHeader>
        <Icon iconType="picture-o"/> {props.isConsignment ? "Consignment" : "Sell"} Request Images
      </CardHeader>
      <CardBody>
        <Row>
          <Col xs={12}>
            <Button
              color={!props.isUploadImageCollapse?"info": "danger"}
              onClick={props.onToggleUploadImageCollapse}
              style={{marginBottom: '1rem'}}
              disabled={props.isUploadImageLoading}
            >
              <Icon iconType={!props.isUploadImageCollapse?"plus": "times"}/>
              {!props.isUploadImageCollapse?" Upload New": " Close"}
            </Button>
          </Col>
        </Row>
        <Collapse
          isOpen={props.isUploadImageCollapse}
        >
          <Row className="d-flex align-items-center">
            <Col xs={6} md={3} className={classNames({"d-none": props.isUploadImageLoading})}>
              <BasicDropzone
                disabled={false}
                onDrop={props.onDropMultipleFiles}
                totalUpload={props.draftImages.length}
                onUploadImages={props.onStartUploadClick}
              />
            </Col>
            {
              props.draftImages.map((draft, index)=>(
                <Col key={index} xs={12} sm={6} md={3}>
                  <CardNewImageThumbnail
                    id={draft.id}
                    fileInput={draft.fileInput}
                    url={draft.previewURL}
                    onRemoveImage={(evt)=>props.onDeleteDraftImage(evt, draft.id)}
                    isUploading={draft.isProcessed}
                    statusCodeUpload={draft.statusCode}
                  />
                </Col>
              ))
            }
          </Row>
        </Collapse>
        <Collapse isOpen={!props.isUploadImageCollapse}>
          <Row>
            { content }
          </Row>
        </Collapse>
      </CardBody>
    </Card>
  )
}

// const UploadCard = props => {
//   return (

//   )
// }

export default ImageListCard;

ImageListCard.propTypes = {
  userSellId: propTypes.number,
  images: propTypes.arrayOf(propTypes.object),
  draftImages: propTypes.arrayOf(propTypes.object),
  isUploadImageLoading: propTypes.bool,
  isUploadImageCollapse: propTypes.bool,
  onToggleUploadImageCollapse: propTypes.func,
  onDropMultipleFiles: propTypes.func,
  onDeleteDraftImage: propTypes.func,
  onStartUploadClick: propTypes.func,
  onChangeImage: propTypes.func,
  onRemoveImage: propTypes.func
}

ImageListCard.defaultProps = {
  userSellId: 0,
  images: [],
  draftImages: [],
  isUploadImageLoading: false,
  isUploadImageCollapse: false,
}
