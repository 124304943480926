import React from 'react';
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Alert,
  Collapse
} from 'reactstrap';
import uuidv4 from 'uuid/v4';
import classNames from 'classnames';
import { connect } from 'react-redux';
import Select from 'react-select';
import 'react-select/dist/react-select.css';
import { createConcierge } from '../../../actions/conciergeAction';
import { doFetchProvinces, doFetchCities } from '../../../actions/userShippingAction';
import privateView from '../../../components/hocs/privateView';
import { errorMessage, hasError, textValue, textChange, parsePhone } from '../../../utils/form';
import FontAwesomeIcon from '../../../components/Icons/FontAwesomeIcon';
import ButtonLoading from '../../../components/Button/ButtonLoading';
import SelectUserAsync from '../../../components/Form/Select/Async/SelectUser';
import { Radio, RadioText, FormGroupRadio } from '../../../components/Form/Radios';
import ModalShipping from './Modals/ModalShipping';
import Analytics from "../../../services/Analytics";
import { DropzonePlusButton } from '../../../components/Form/DropzoneFile';
import { ImagesCompressor } from '../../../utils/imageHelper';
import { ImageFile } from '../../../components/Images';

const addImageRawObject = (src, file) => {
  return {
    status: false,
    identifier: uuidv4(),
    fileInput: file,
    URL: src,
    isUploading: false
  }
}

class ConciergeCreate extends React.Component{
  constructor(props){
    super(props);
    this.state={
      _user: null,
      _productName: null,
      _condition:[{
        name: "BARU",
        en: "BARU",
        label: "BARU"
      },
      {
        name: "BEKAS",
        en: "BEKAS",
        label: "BEKAS"
      }],
      _image:null,
      _rawImage:null,
      _shipping_id:null,
      _alias:'',
      _fullName:'',
      _phoneNumber:'',
      _mobileNumber:'',
      _shippingAddress:'',
      _province:'',
      _provinceOption:[{value:"",label:'Provinsi*'}],
      _city:'',
      _cityOption:[{value:"",label:'Kabupaten/Kota*'}],
      _stillfetchCity: false,
      _shippingNote:'',
      _modalShippingOpen: false,
      _errorForm:null,
      _isSubmit: false,
      _errorMessage: '',
      _successMessage: '',
      _isAlertErrorOpen: false,
      _isAlertSuccessOpen: false,
      _isShippingCollapseOpen: true,
    }
    this._handleShippingChanged = this._handleShippingChanged.bind(this);
    this._handleSelectProvinceChange = this._handleSelectProvinceChange.bind(this);
    this._handleSelectCityChange = this._handleSelectCityChange.bind(this);
    this._handleOpenModalShipping = this._handleOpenModalShipping.bind(this);
    this._handleTextChange = this._handleTextChange.bind(this);
    this._handleConditionsCheckedChange = this._handleConditionsCheckedChange.bind(this);
    this._handleDropzoneChange = this._handleDropzoneChange.bind(this);
    this._handleSubmitForm = this._handleSubmitForm.bind(this);
    this._handleOnSelectUserChange = this._handleOnSelectUserChange.bind(this);
    this._handleOnSelectUserOpen = this._handleOnSelectUserOpen.bind(this);
  }

  componentDidMount(){
    const { email } = this.props.injectedProps;
    this._buildProvinceOptions();
    Analytics.recordPageView('concierge_create', email, {
      url: window.location.href,
      location: this.props.location,
      match: this.props.match
    });
  }

  _handleSelectProvinceChange(selected){
    this.setState({
      _province:selected?selected.value:'',
      _city:''
    },()=>{
      this._buildCityOptions(this._getProvinceId());
    });
  }

  _handleSelectCityChange(selected){
    this.setState({ _city:selected?selected.value:'' });
  }

  _getProvinceId(){
    const { _province } = this.state;
    const { data } = this.props.dataProvinces;
    if(_province >=0 && _province !== "" && data.length) return data[_province].id;
    return 0;
  }

  _buildProvinceOptions(){
    if(this.props.dataProvinces.status_code !== 200) this.props.getProvinces();
    else{
      const {data}=this.props.dataProvinces;
      const provinces = data.map((province,index)=>({
        value:index,
        label:province.name
      }));
      this.setState({_provinceOption:[ {value:"",label:"Provinsi*"} , ...provinces ]});
    }
  }

  _buildCityOptions(provinceId){
    if(provinceId){
      const province = this.props.dataProvinces.data.find(p=>p.id===provinceId);
      const { data, status_code } = this.props.dataCities;
      const params = {province:province.name}
      if(status_code !== 200) this.props.getCities({params});
      else if(data.length){
        if(province && data[0].province_id === province.id){
          const { _stillfetchCity, _city } = this.state;
          const cities = [];
          data.forEach(item =>{
            const duplicate=cities.filter(d => d.label===item.name).length>0;
            if(duplicate){
              cities.push({value:item.id,label:`${item.name} (${item.type})`})
            }
            else
              cities.push({value:item.id,label:`${item.name}`})
          })
          if(_city && _stillfetchCity){
            if(typeof _city === "string"){
              const getCity = cities.find(city=>city.label === _city);
              const cityId = getCity? getCity.value : null;
              this.setState({
                _stillfetchCity: false,
                _city: cityId
              })
            }
          }
          this.setState({ _cityOption: [ { value:"",label:"Kabupaten/Kota*" } , ...cities ] });
        }
        else {
          const params = {province:province.name}
          this.props.getCities({params});
        }
      }
    }
  }

  async _handleOnSelectUserChange(selected){
    const lastId = this.state._user? this.state._user.id: null;
    const nextId = selected? selected.id: null;
    if(lastId != nextId){
      await this.clearShipping();
    }
    const _user=this.state._user = selected;
    this.setState({
      _user,
      _recentUserSearch: selected && this.state._recentUserSearch
    });
  }

  clearShipping(){
    this.setState({
      _shipping_id: null,
      _alias:'',
      _fullName:'',
      _phoneNumber:'',
      _shippingAddress:'',
      _shippingNote:'',
      _province:'',
      _city:'',
      _postalCode:'',
      _shippingNote:''
    });
  }

  _handleOnSelectUserOpen(){
    if(this.state._user){
      return this.state._recentUserSearch&&this.refs.selectUser.loadSelectOptions(this.state._recentUserSearch);
    }
    return this.refs.selectUser.loadSelectOptions('');
  }

  componentDidUpdate(lastProps){
    const { dataCities, dataProvinces, dataConcierge } = this.props;
    if(lastProps.dataCities.isFetch
      && lastProps.dataCities.isFetch!==dataCities.isFetch){
      if(!dataCities.error){
        this._buildCityOptions(this._getProvinceId())
      }
    }

    if(lastProps.dataProvinces.isFetch && lastProps.dataProvinces.isFetch !== dataProvinces.isFetch){
      const { error } = dataProvinces;
      if(!error){
        this._buildProvinceOptions();
      }
    }

    if(lastProps.dataConcierge.isSubmit
      &&lastProps.dataConcierge.isSubmit!==dataConcierge.isSubmit){
      const { concierge } = dataConcierge;
      if(!dataConcierge.error){
        this.setState({_isSubmit:false,  _isAlertSuccessOpen:true, _successMessage:concierge.message},
        ()=>{
          this.props.history.replace(`/concierge/${concierge.id}`)
        })
      }
      else{
        if(dataConcierge.error){
          const  { error } =  dataConcierge;
          this.setState({
            _isSubmit:false,
            _isAlertErrorOpen: true,
            _errorMessage:error.message,
            _errorForm: {
              ...error,
            }
          })
        }
      }    
    }
  }

  _handleShippingChanged(shipping){
    if(!shipping){
      this.setState({
        _modalShippingOpen: false
      });
      this.clearShipping();
      return;
    }
    const { dataProvinces } = this.props;
    let checkingIdProvince = dataProvinces.data.filter((a) => {
      return a.name === shipping.province
    })
    this.setState({
      _shipping_id:shipping.id,
      _alias: shipping.alias,
      _fullName: shipping.full_name,
      _mobileNumber: shipping.phone_number,
      _shippingAddress: shipping.street_address,
      _shippingNote: shipping.note,
      _province: checkingIdProvince?checkingIdProvince[0].id - 1:'',
      _postalCode: shipping.postal_code,
      _stillfetchCity: true,
      _city: shipping.city,
      _modalShippingOpen: false
    },()=>{
      this._buildCityOptions(this._getProvinceId())
    });
  }

  _handleOpenModalShipping(){
    if(!this.state._user){
      alert('Please select user!');
    }
    else{
      this.setState({
        _modalShippingOpen: !this.state._modalShippingOpen,
      })
    }
  }

  _validatePayloads(){
    let objErrors = {};
    const condition = this.state._condition.filter(item => item.value === true);
    if(!this.state._user){
      objErrors['user_id'] = ["Please select a buyer."];
    }
    if(!this.state._name){
      objErrors['name'] = ["Please insert name."];
    }
    if(!this.state._productName){
      objErrors['product_name'] = ["Please insert product name."];
    }
    if(!condition.length){
      objErrors['condition'] = ["Please pick a condition."];
    }
    if(!this.state._alias){
      objErrors['alias'] = ["Please insert alias."];
    }
    if(!this.state._fullName){
      objErrors['full_name'] = ["Please insert recipient name."];
    }
    if(!this.state._phoneNumber){
      objErrors['phone_number'] = ["Please insert phone number."];
    }
    if(!this.state._mobileNumber){
      objErrors['mobile_number'] = ["Please insert mobile number."];
    }
    if(!this.state._shippingAddress){
      objErrors['street_address'] = ["Please insert address."];
    }
    if(!this.state._province){
      objErrors['province'] = ["Please insert province."];
    }
    if(!this.state._city){
      objErrors['city'] = ["Please insert city."];
    }
    if(!this.state._postalCode){
      objErrors['postal_code'] = ["Please insert postal code."];
    }
    if(!this.state._image){
      objErrors['image'] = ["Please select a image."];
    }
    if(Object.keys(objErrors).length){
      this.setState({
        _errorForm:{
          errors: objErrors,
          statusCode: 422
        },
        _errorMessage: 'Please fulfill your form!',
        _isAlertErrorOpen: true
      })
    }
    return objErrors;
  }

  _clearMessage(){
    this.setState({_errorForm:null, _isAlertErrorOpen:false, _errorMessage:'', _isAlertSuccessOpen:false, _successMessage:''});
  }

  _compressImage = async (image) => {
    let fileInput = image
    try{
      //compress file
      fileInput = await ImagesCompressor(image);
    }
    catch(e){
      alert('error while compress the images')
      fileInput = image
    }
    return fileInput;
  }

  _handleSubmitForm = async (e) => {
    const email = this.props.guardData;
    e.preventDefault();
    this._clearMessage();
    const getLocalErrors = this._validatePayloads();
    if(Object.keys(getLocalErrors).length===0){
      const getCity =this.state._cityOption.filter(city => city.value===this.state._city);
      const getCondition =this.state._condition.filter(item => item.value===true);
      const { data } = this.props.dataProvinces;
      const {  _name, _phoneNumber, _productName, _user, _rawImage, _shipping_id } = this.state;
      let rawImage = _rawImage? { ..._rawImage } : _rawImage;
      if(_rawImage){
        if(!_rawImage.compressed){
          const compFileInput = await this._compressImage(rawImage.fileInput);
          rawImage = {
            ...rawImage,
            fileInput: compFileInput,
            compressed: true
          }
        }
      }
      const shippingDetails = {
        alias:this.state._alias,
        full_name:this.state._fullName,
        phone_number: parseInt(parsePhone(this.state._mobileNumber, false)),
        street_address:this.state._shippingAddress,
        note:this.state._shippingNote,
        province:data.length?data[this.state._province].name:0,
        province_id: this.state._province,
        city:getCity.length?getCity[0].label:null,
        city_id: this.state._city,
        postal_code:this.state._postalCode,
        country:'IDN'
      }
      if(_shipping_id){
        shippingDetails.id = _shipping_id
      }
      let dataPayload = new FormData();
      dataPayload.append("name", _name);
      dataPayload.append("user_id", _user.id);
      dataPayload.append("phone_number", parseInt(parsePhone(_phoneNumber), false));
      dataPayload.append("product_name", _productName);
      dataPayload.append("condition", getCondition.length && getCondition[0].name === "BARU" ? "BARU" : null);
      dataPayload.append("condition2",  getCondition.length && getCondition[0].name === "BEKAS" ? "BEKAS" : null);
      if(this.state._shipping_id)
        dataPayload.append("shipping_id", _shipping_id);
      else
        dataPayload.append("shipping", { ...shippingDetails });
      dataPayload.append("image", _rawImage.fileInput);
      const payloads = {
        userId: dataPayload.user_id,
        name: dataPayload.name,
        product_name: dataPayload.product_name,
        phoneNumber: dataPayload.phone_number,
        condition: dataPayload.condition,
        condition2: dataPayload.condition2,
        shipping: dataPayload.shipping,
        image: dataPayload.image,
      }
      this.setState({
        _isSubmit: true,
        _isAlertErrorOpen: false
      }, ()=>this.props.save(dataPayload))
      Analytics.recordCreateConcierge(
        payloads,
        email
      );
    }
  }

  _handleTextChange(e){
    this.setState({
      ...textChange(e, this.state)
    })
  }

  _handleConditionsCheckedChange(e, index){
    const { _condition } = this.state;
    const item = _condition[index];
    const arr = _condition.filter(item => item.name !== e.target.name);
    arr.splice(index, 0, {
      ...item,
      value: e.target.checked
    })
    this.setState({
      _condition: arr
    });
  }

  _handleDropzoneChange(files){
    if(files.length){
      const [file] = files;
      const { _modalEditImage } = this.state;
      const fileObj = addImageRawObject(file.preview, file)
      let modalEditImage = _modalEditImage;
      if(this.props.useCrop){
        modalEditImage = {
          ..._modalEditImage,
          isOpen: true,
          imageSourceEdits: [{
            imgId: fileObj.identifier,
            src: fileObj.URL
          }]
        }
      }
      this.setState({
        _image: file.preview,
        _rawImage: fileObj,
        _modalEditImage: {
          ...modalEditImage,
        }
      });
    }
  }

  render(){
    const { 
      _user,
      _province,
      _city,
      _errorMessage,
      _modalShippingOpen,
      _isSubmit,
      _errorForm,
      _isShippingCollapseOpen,
      _provinceOption,
      _cityOption,
      _successMessage,
      _isAlertSuccessOpen,
      _isAlertErrorOpen,
    } = this.state;
    return(
      <Row className="animated fadeIn">
        <ModalShipping
          isOpen={_modalShippingOpen}
          userId={_user?_user.id: null}
          onCancel={()=> this.setState({_modalShippingOpen: !_modalShippingOpen})}
          onSelectChanged={this._handleShippingChanged}
        />
        <Col xs={12} md={6}>
          <Card>
            <CardHeader>
              <FontAwesomeIcon iconType="align-justify"/>Concierge Form
            </CardHeader>
            <CardBody>
              <Alert
                color="danger"
                isOpen={_isAlertErrorOpen}
                toggle={()=> this.setState({_isAlertErrorOpen: !_isAlertErrorOpen, _errorForm:null})}
              >
                <strong>Oppps... </strong>{_errorMessage}
              </Alert>
              <Alert
                color="success"
                isOpen={_isAlertSuccessOpen}
                toggle={()=> this.setState({_isAlertSuccessOpen: !_isAlertSuccessOpen})}
              >
                <strong>Well done! </strong>{_successMessage}
              </Alert>
              <Form onSubmit={this._handleSubmitForm}>
                <Row>
                  <Col xs={12} md={6}>
                    <FormGroup className={classNames({'has-danger has-feedback':hasError('user_id', _errorForm)})}>
                      <Label for="_formSelectUser">Buyer Email</Label>
                      <SelectUserAsync
                        defaultOptions
                        id="_formSelectUser"
                        userId={this.props.auth.id}
                        ref="selectUser"
                        value={_user}
                        paramsApi={{ roles: 'user,seller,administrator', role_query: 'or', scope: 'all' }}
                        placeholder="Type and select a user..."
                        noResultsText="Cannot find user."
                        loadOptions={this._getUserOptions}
                        onSelectSearch={(input)=>this.setState({_recentUserSearch: input})}
                        onSelectChange={this._handleOnSelectUserChange}
                        onSelectOpen={this._handleOnSelectUserOpen}
                      />
                       {errorMessage('user_id', _errorForm)}
                    </FormGroup>
                  </Col>
                  <Col xs={12} md={6}>
                    <FormGroup className={classNames({'has-danger has-feedback':hasError('product_name', _errorForm)})}>
                      <Label>Product Name</Label>
                      <Input
                        name="_productName"
                        onChange={this._handleTextChange}
                        value={textValue("_productName", this.state)}
                        placeholder="Product Name..."
                      />
                      {errorMessage('product_name', _errorForm)}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={6}>
                    <FormGroup className={classNames({'has-danger has-feedback':hasError('name', _errorForm)})}>
                      <Label>Name</Label>
                      <Input
                        name="_name"
                        onChange={this._handleTextChange}
                        value={textValue("_name", this.state)}
                        placeholder="Name..."
                      />
                      {errorMessage('name', _errorForm)}
                    </FormGroup>
                  </Col>
                  <Col xs={12} md={6}>
                    <FormGroup className={classNames({'has-danger has-feedback':hasError('phone_number', _errorForm)})}>
                      <Label>Phone Number</Label>
                      <Input
                        name="_phoneNumber"
                        onChange={this._handleTextChange}
                        value={textValue("_phoneNumber", this.state)}
                        placeholder="Phone Number..."
                      />
                      {errorMessage('phone_number', _errorForm)}
                    </FormGroup>
                  </Col>
                  </Row>
                  <Row>
                    <Col xs={12}>
                      <FormGroup className={classNames({'has-danger has-feedback':hasError('condition', _errorForm)})}>
                        <Label>Conndition:</Label>
                        {errorMessage('condition', _errorForm)}
                      </FormGroup>
                    </Col>
                    {
                      this.state._condition.map((condition, index) => (
                        <Col xs={12} md={6} key={index} className="d-flex align-items-center">
                          <FormGroupRadio>
                            <Radio
                              type="checkbox"
                              name={condition.name}
                              value={true}
                              checked={condition.value}
                              dataOnText="Yes"
                              dataOffText="No"
                              onChange={e => this._handleConditionsCheckedChange(e, index)}
                            />
                            <RadioText text={condition.en}/>
                          </FormGroupRadio>
                        </Col>
                      ))
                    }
                  </Row>
                  <Row>
                    <Col xs={12}>
                      <Collapse isOpen={_isShippingCollapseOpen}>
                        <Row>
                          <Col xs={12}>
                            <hr/>
                          </Col>
                          <Col xs={12}>
                            <FormGroup>
                              <Label>Shipping To (Buyer)</Label>
                              <br/>
                              <Button
                                color="primary"
                                type="button"
                                onClick={this._handleOpenModalShipping}
                              >
                                Choose a Shipping
                              </Button>
                            </FormGroup>
                          </Col>
                          <Col xs={12} md={6}>
                            <FormGroup className={classNames({'has-danger has-feedback':hasError('alias', _errorForm)})}>
                              <Label>Alias</Label>
                              <Input
                                name="_alias"
                                onChange={this._handleTextChange}
                                value={textValue("_alias", this.state)}
                                placeholder="Alias..."
                              />
                              {errorMessage('alias', _errorForm)}
                            </FormGroup>
                          </Col>
                          <div className="w-100"></div>
                          <Col xs={12} md={6}>
                            <FormGroup className={classNames({'has-danger has-feedback':hasError('full_name', _errorForm)})}>
                              <Label>Recipient Name</Label>
                              <Input
                                name="_fullName"
                                onChange={this._handleTextChange}
                                value={textValue("_fullName", this.state)}
                                placeholder="Recipient name..."
                              />
                              {errorMessage('full_name', _errorForm)}
                            </FormGroup>
                          </Col>
                          <Col xs={12} md={6}>
                            <FormGroup className={classNames({'has-danger has-feedback':hasError('mobile_number', _errorForm)})}>
                              <Label>Mobile Phone</Label>
                              <Input
                                name="_mobileNumber"
                                onChange={this._handleTextChange}
                                value={textValue("_mobileNumber", this.state)}
                                placeholder="Mobile number..."
                              />
                              {errorMessage('mobile_number', _errorForm)}
                            </FormGroup>
                          </Col>
                          <div className="w-100"></div>
                          <Col xs={12}>
                            <FormGroup className={classNames({'has-danger has-feedback':hasError('street_address', _errorForm)})}>
                              <Label>Address</Label>
                              <Input
                                type="textarea"
                                name="_shippingAddress"
                                onChange={this._handleTextChange}
                                value={textValue("_shippingAddress", this.state)}
                                placeholder="Address..."
                              />
                              {errorMessage('street_address', _errorForm)}
                            </FormGroup>
                          </Col>
                          <Col xs={12}>
                            <FormGroup className={classNames({'has-danger has-feedback':hasError('note', _errorForm)})}>
                              <Label>Shipping Note</Label>
                              <Input
                                name="_shippingNote"
                                onChange={this._handleTextChange}
                                value={textValue("_shippingNote", this.state)}
                                type="textarea"
                                placeholder="Notes..."
                              />
                              {errorMessage('note', _errorForm)}
                            </FormGroup>
                          </Col>
                          <Col xs={12} md={4}>
                            <FormGroup className={classNames({'has-danger has-feedback':hasError('province', _errorForm)})}>
                              <Label for="_formSelecProvice">Province</Label>
                              <Select
                                id="_formSelecProvice"
                                name="_province"
                                value={_province}
                                options={_provinceOption.length?_provinceOption:[{value:'',label:'Loading...'}]}
                                onChange={this._handleSelectProvinceChange}
                              />
                              {errorMessage('province', _errorForm)}
                            </FormGroup>
                          </Col>
                          <Col xs={12} md={4}>
                            <FormGroup className={classNames({'has-danger has-feedback':hasError('city', _errorForm)})}>
                              <Label for="_formSelectCity">City</Label>
                              <Select
                                id="_formSelectCity"
                                name="_city"
                                value={_city}
                                options={_cityOption.length?_cityOption:[{value:'',label:'Loading...'}]}
                                onChange={this._handleSelectCityChange}
                              />
                              {errorMessage('city', _errorForm)}
                            </FormGroup>
                          </Col>
                          <Col xs={12} md={4}>
                            <FormGroup className={classNames({'has-danger has-feedback':hasError('postal_code', _errorForm)})}>
                              <Label for="_formPostalCode">Postal Code</Label>
                              <Input
                                id="_formPostalCode"
                                name="_postalCode"
                                onChange={this._handleTextChange}
                                value={textValue("_postalCode", this.state)}
                                placeholder="Postal code..."
                              />
                              {errorMessage('postal_code', _errorForm)}
                            </FormGroup>
                          </Col>
                          <Col xs={12}>
                            <hr/>
                          </Col>
                      </Row>
                    </Collapse>
                  </Col>
                </Row>
                <Row>
                  <Col sm={12}>
                    <FormGroup className={classNames({'has-danger has-feedback': hasError('image', _errorForm)})}>
                      <Label for="_formImage">Image</Label>
                      <Col className="d-flex" style={{padding:0}}>
                        <ImageFile
                          style={{padding: 0}}
                          alt="Product addon's image banner"
                          src={this.state._image}
                          showDelete={this.state._rawImage !== null}
                          renderEmptyImageText={() => (
                            <p className="mb-0 text-center">
                              No Image <br/>
                              Size: 700 x 700 in pixels
                            </p>
                          )}
                          onDelete={() => {
                            this.setState({
                              _image:null,
                              _rawImage: null
                            })
                          }}
                        />
                      <div className="ml-3">
                        <DropzonePlusButton mulitple={false} onDrop={this._handleDropzoneChange}/>
                      </div>
                    </Col>
                    {errorMessage('image', _errorForm)}
                    </FormGroup>
                  </Col>
                </Row>
                <ButtonLoading
                  isLoading={_isSubmit}
                  disabled={_isSubmit}
                  loadingMessage="Submitting..."
                  color="primary"
                >
                  Create
                </ButtonLoading>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    )
  }
}

const mapStateToProps= ({userShipping, auth, concierge}) => ({
  auth,
  guardData: {
    isFetch: auth.isFetch,
    isLogged: auth.isLogged,
    roles: auth.roles,
    id: auth.id,
    email: auth.email,
  },
  detail:userShipping.detail,
  dataCities:userShipping.cities,
  dataProvinces:userShipping.provinces,
  dataConcierge:concierge,
});

const mapDispatchToProps = (dispatch) => ({
  getProvinces : () => dispatch(doFetchProvinces()),
  getCities : (payload) => dispatch(doFetchCities(payload)),
  save: (payload) => dispatch(createConcierge(payload))
});

const enhance = connect(mapStateToProps, mapDispatchToProps);
export default enhance(privateView(ConciergeCreate));