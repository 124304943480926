import React from 'react';
import { connect } from 'react-redux';
import { MetaForm } from './Form';
import privateView from '../../components/hocs/privateView';
import Analytics from "../../services/Analytics";

class MetaDetail extends React.Component{
	componentDidMount(){
    const { email } = this.props.injectedProps;
    Analytics.recordPageView('meta_detail', email, {
      url: window.location.href,
      location: this.props.location,
      match: this.props.match
    });
  }
  render(){
    return(
      <div className="animate fadeIn">
        <MetaForm
          edit
          useCrop
          title="Meta Details"
          {...this.props}
        />
      </div>
    )
  }
}

const mapStateToProps = ({auth: { isFetch, roles, isLogged, id, email } })=>({
  guardData: { isFetch, roles, isLogged, id, email }
})

const enhance = connect(mapStateToProps);

export default enhance(privateView(MetaDetail, ['supervisor', 'sale_supervisor', 'customer_service', 'blog_admin']));
