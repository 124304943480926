import React from 'react';
import styled from 'styled-components';
import noImage from '../../../assets/img/no-image.png';
import BadgeThin from '../../../components/Badges/BadgeThin';
import { FontAwesomeIcon, SimpleLineIcon } from '../../../components/Icons';
import Paragraph from '../../../styled/Typograph/Paragraph';

const Div = styled.div`
  display: flex;
  border: 1px solid #ddd;
  padding: .5rem .75rem;
  position: relative;
  margin-bottom: .5rem;
  .button-expand{
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    padding: .5rem .75rem;
    transform: ${props => props.isExpanded? "translateY(-7px) rotate(180deg)": "none"};
  }
`

const ImageWrapper = styled.div`
  display: ${props => !props.isExpanded? "none": "flex"};
  justify-content: center;
  align-items: center;
  margin-right: .5rem;
  width: 120px;
  > img {
    width: 100%;
  }
`
const ImageCaption = styled.div`
  display: flex;
  flex-direction: column;
  .brands-section {
    display: ${props => !props.isExpanded? "none": "initial"};
  }
  .user-section{
    display: ${props => !props.isExpanded? "none": "initial"};
    font-size: .75rem;
  }
`
const SkuRequestDetail = props => {
  const url = props.imgUrl === ''|| props.imgUrl === null ? noImage: props.imgUrl;
  return (
    <Div isExpanded={props.isExpanded}>
      {
        props.toggle && (
          <div className="button-expand" onClick={props.toggle}>
            <SimpleLineIcon iconType="arrow-down"/>
          </div>
        )
      }
      <ImageWrapper isExpanded={props.isExpanded}>
        <img src={url} alt="Image submitted from user"/>
      </ImageWrapper>
      <ImageCaption isExpanded={props.isExpanded}>
        <div>
          {
            props.categoryId && (
              <BadgeThin color="primary" className="text-uppercase mr-1">
                <FontAwesomeIcon iconType="tags"/> {props.category.name}
              </BadgeThin>
            )
          }
          <BadgeThin className="text-uppercase">{props.requestNumber}</BadgeThin>
        </div>
        <div className="brands-section">
          {
            props.brand? props.brand.flat.map((b, i) => <BadgeThin key={i} className="mr-1">{b}</BadgeThin>) : null
          }
          {
            props.otherBrand && (
              <BadgeThin className="mr-1">OTH BRAND: {props.otherBrand}</BadgeThin>
            )
          }
        </div>
        <Paragraph>
          {props.displayName}<br/>
          SKU: {props.SKU}
        </Paragraph>
        <div className="user-section">
          <Paragraph>
            <FontAwesomeIcon iconType="user"/> {props.user? props.user.email :'-'}
          </Paragraph>
          <Paragraph>
            <FontAwesomeIcon iconType="calendar"/> {props.createdAt}
          </Paragraph>
        </div>
      </ImageCaption>
    </Div>
  )
}

export default SkuRequestDetail;