import React from 'react';
import propTypes from 'prop-types';
import PaginationSummaries from '../../../components/Paginations/PaginationSummaries';
import { Tbl, TblHead, TblBody } from '../../../components/Tbl';
import RowItem from './RowItem';
import { generateNumber } from '../../../utils';
import { userSellHandbagConditions } from '../../../utils/userSell';

const dataHeader=[
  'No.','Product','Price','Updated','Status', 'Action'
];
class List extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      _data: []
    }
  }
  componentWillReceiveProps(nextProps){
    if(!nextProps.isLoading && nextProps.isLoading != this.props.isLoading){
      this.setState({
        _data: nextProps.data
      })
    }
  }
  render (){
    return (
      <div className="table-responsive" ref={this.props.innerRefs}>
        <PaginationSummaries
          from={this.props.from}
          to={this.props.to}
          total={this.props.total}
        />
        <Tbl>
          <TblHead
            renderChildren={this.props.onRenderHeader}
          />
          <TblBody
            isLoad={this.props.isLoading}
            hasRow={this.state?._data?.length>0}
            columnCount={8}
            renderChildren={()=> {
              if(!this.props.isLoading&&this.props.statusCode===200){
                const { currentPage, perPage } = this.props;
                // console.log('Starting Render Item')
                return (
                  this.state._data.map((item,index)=>{
                    const number = generateNumber(perPage,currentPage,index);
                    const inclusions = userSellHandbagConditions.filter(item => item.inclusions === true)
                      .map(item => item.name);
                    const defectsCount = item.defects.filter(item =>
                      item.value === true && !inclusions.includes(item.name.toLowerCase())
                    ).length;
                    const inclusionsCount = item.defects.filter(item =>
                      item.value === true && inclusions.includes(item.name.toLowerCase())
                    ).length;
                    const sneakerDefectCount = [
                      item.yellowing,
                      item.display_item,
                      item.missing_accessories,
                      // exclude sneakers_defect if already has defects list, always be true.
                      defectsCount === 0? item.sneakers_defect : false
                    ].filter(item => item === true).length
                    let boxCondition = ""
                    switch (item.box_condition) {
                      case 'NO_BOX':
                      boxCondition = "MISSING BOX"
                      break
                      case 'CACAT':
                      boxCondition = "DAMAGED BOX"
                      break

                      default:
                      boxCondition = "PERFECT BOX"
                    }
                    return (
                      <RowItem
                        key={item.id}
                        id={item.id}
                        rowId={number}
                        category={item.product_variant.product.category? item.product_variant.product.category.name: '-'}
                        productVariantId={item.product_variant_id}
                        displayName={item.product_variant.display_name}
                        SKU={item.product_variant.SKU}
                        colour={item.product_variant.colour}
                        userEmail={item.user.email}
                        qty={item.quantity}
                        sneakersCondition={item.sneakers_condition}
                        askingPrice={item.asking_price}
                        preOrder={item.pre_order}
                        preVerified={item.pre_verified}
                        isExpired={item.status === 'approved' ? item.is_expired: false}
                        size={item.size?item.size:undefined}
                        status={item.status}
                        isSellerHoliday={item.is_seller_holiday}
                        updatedAt={item.updated_at}
                        approvedAt={item.approved_at}
                        consignmentId={item.consignment_id}
                        rack={item.rack}
                        defaultRoute={this.props.defaultRoute}
                        defectsCount={defectsCount + sneakerDefectCount}
                        inclusionsCount={inclusionsCount}
                        requestType={item.request_type}
                        consignmentx={item.consignment}
                        boxCondition={boxCondition}
                        isNewNoDefect={item.is_new_no_defect}
                      />
                    )
                  })
                )
              }
              return null;
            }}
          />
        </Tbl>
      </div>
    )
  }
}
export default List;

List.propTypes = {
  from: propTypes.number,
  to: propTypes.number,
  total: propTypes.number,
  currentPage: propTypes.number,
  perPage: propTypes.oneOfType([propTypes.number, propTypes.string]),
  statusCode: propTypes.any,
  defaultRoute: propTypes.string.isRequired,
  isLoading: propTypes.bool
}
List.defaultProps = {
  from: 0,
  to: 0,
  total: 0,
  currentPage: 0,
  perPage: 0
}