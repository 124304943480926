import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Row,
  Col,
  Card,
  CardBody,
  CardText,
  Alert,
  UncontrolledTooltip
} from 'reactstrap';
import { CardHeaderWithToolbars,Title } from '../../components/Card/CardHeaderWithToolbars';
import Toolbars from './Filters/Toolbars';
import FiltersPanel from './Filters';
import FilterContainer from '../../components/Container/FilterContainer/FilterContainer';
import ModalBulkUpload from './Modals/ModalBulkUpload';
import { bulkVariantFormats } from '../../config';
import fetch from '../../utils/Api';
import { DEFAULT_SORT_BY, DEFAULT_PER_PAGE, MAX_PRODUCT_VARIANT_EDITORS_CHOICE } from '../../constants/settings';
import { getProductVariantList, updateProductVariant } from '../../actions/productVariantAction';
import { doBulkProducts, doUploadBulkProducts } from '../../actions/bulkUploadAction';
import privateView from '../../components/hocs/privateView';
import List from './productVariantIndex/List';
import ModalInfo from '../../components/Modals/ModalInfo';
import DropdownProductCategories from '../../components/Dropdowns/DropdownProductCategories';
import { Th } from '../../components/Tbl';
import { AUTH_TOKEN_KEY } from '../../constants'
import Analytics from "../../services/Analytics";
import Radio from '../../components/Form/Radios/Radio';
import ModalScrape from './Modals/ModalScrape';
import ModalEditorsPosition from './Modals/ModalEditorsPosition';

const defaultParams = {
  sort_by : DEFAULT_SORT_BY,
  per_page : DEFAULT_PER_PAGE
}

const DEFAULT_ROUTE = '/product_variants';

class ProductVariantIndex extends Component {
  constructor(){
    super();
    this.state={
      _filterOpen: false,
      _modalBulkUploadOpen: false,
      _params:{
        ...defaultParams
      },
      _isResetFilter: false,
      _sortOptions:[
        { value:"" , label:"Default" },
        { value:"editorsChoice_asc" , label:"Hot Product" },
        { value:"updatedAt_desc" , label:"Last Updated" },
        { value:"createdAt_desc" , label:"Newest" },
        { value:"createdAt_asc" , label:"Older" },
        { value:"displayName_asc" , label:"A-Z" },
        { value:"displayName_desc" , label:"Z-A" },
        { value:"active_desc" , label:"Active" },
        { value:"active_asc" , label:"Inactive" }
      ],
      _list:[],
      _pagination:{},
      _successAlertOpen: false,
      _editorsChoiceTotal: {
        'sneakers': {
          'total': 0,
          'threshold': MAX_PRODUCT_VARIANT_EDITORS_CHOICE
        },
        'apparels': {
          'total': 0,
          'threshold': MAX_PRODUCT_VARIANT_EDITORS_CHOICE
        }
      },
      _isFetchData: false,
      _modalInfo: {
        isOpen: false,
        type: 'sneakers'
      },
      _selectedCategory: null,
      _modalScrape: {
        isOpen: false,
        product: {
          id: null,
          displayName: "",
          priceSource: ""
        }
      },
      _modalPosition: {
        isOpen: false,
        product: {
          id: null,
          displayName: "",
          position: ""
        }
      }
    }
    this._handleToggleFilterPanel = this._handleToggleFilterPanel.bind(this);
    // this._handleChange = this._handleChange.bind(this);
    // this._handleChangeAndSubmit = this._handleChangeAndSubmit.bind(this);
    this._handleGoto = this._handleGoto.bind(this);
    this._refreshList = this._refreshList.bind(this);
    this._handleRefresh = this._handleRefresh.bind(this);
    this._handleResetFilter = this._handleResetFilter.bind(this);
    this._handleToggleModalBulkUpload = this._handleToggleModalBulkUpload.bind(this);
    this._handleApplyFilter = this._handleApplyFilter.bind(this);
    this._handleRemoveSuccessAlert = this._handleRemoveSuccessAlert.bind(this);
    this._handleEditorsChoiceChange = this._handleEditorsChoiceChange.bind(this);
    this._toggleModalInfo = this._toggleModalInfo.bind(this);
    this._handleCategoryChanged = this._handleCategoryChanged.bind(this);
    this._getEditorsChoices = this._getEditorsChoices.bind(this);
  }

  async componentDidMount(){
    const { email } = this.props.injectedProps;
    this._refreshList();
    await this._getEditorsChoiceCountData();
    Analytics.recordPageView('product_variant_list', email, {
      url: window.location.href,
      location: this.props.location,
      match: this.props.match
    });
  }

  _getEditorsChoiceCountData = async () => {
    const { dataCategories } = this.props;
    if(dataCategories.length){
      const promises = dataCategories.map((category) =>
        this._getEditorsChoiceList({ id: category.value, name: category.label })
      )
      await Promise.all(promises)
    }
  }

  _getEditorsChoiceList({ id, name }){
    return fetch({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
    }).get('admins/productvariants', {
      params: {
        category_id: id,
        per_page: 1,
        editors_choice:1
      }
    })
    .then(({ data })=> {
      const { _editorsChoiceTotal } = this.state;
      let payloads = {};
      if(_editorsChoiceTotal[name.toLowerCase()]){
        payloads = { ..._editorsChoiceTotal[name.toLowerCase()] }
      } else{
        payloads = { total: 0, threshold: MAX_PRODUCT_VARIANT_EDITORS_CHOICE }
      }
      this.setState({
        _editorsChoiceTotal: {
          ..._editorsChoiceTotal,
          [name.toLowerCase()]: {
            ...payloads,
            total: data.data.total
          }}
      })
    })
  }

  _getList(rawParams = {}){
    let params = {};
    Object.keys(rawParams)
    .filter(key => rawParams[key]!='')
    .forEach(key=> params[key] = rawParams[key]);
    this.props.getList({params});
  }

  _getEditorsChoices(category, type){
    const { _editorsChoiceTotal } = this.state;
    const name = category && category.toLowerCase();
    const isNotEmpty = name && name !== '';
    switch(name){
      case 'sneakers': return _editorsChoiceTotal.sneakers[type];
      case 'apparels': return _editorsChoiceTotal.apparels[type];
      default:{
        if(isNotEmpty && _editorsChoiceTotal[name]){
          return _editorsChoiceTotal[name][type];
        }
        return Object.keys(_editorsChoiceTotal).reduce((total, keyName)=> {
          return total += _editorsChoiceTotal[keyName][type]
        }, 0);
      }
    }
  }

  shouldComponentUpdate(nextProps){
    if(nextProps.productVariant.isSubmit&&nextProps.productVariant.isSubmit!==this.props.productVariant.isSubmit){
      return false;
    }
    return true;
  }

  async componentDidUpdate(prevProps, prevState) {
    if(prevState._isFetchData && prevProps.productVariant.isFetch !== this.props.productVariant.isFetch && prevProps.productVariant.isFetch){
      if(prevProps.productVariant.error===null){
        const { data, ...pagination  } = this.props.productVariant.list;
        this.setState({
          _list: data,
          _pagination: pagination,
          _isFetchData: false
        })
      }
    }
    if(prevProps.dataCategories.length !== this.props.dataCategories.length){
      await this._getEditorsChoiceCountData();
    }
    if(prevProps.productVariant.isSubmit !== this.props.productVariant.isSubmit && prevProps.productVariant.isSubmit){
      if(prevProps.productVariant.error===null){
        this.setState({
          _successAlertOpen: true
        })
      }
    }
  }

  _handleRemoveSuccessAlert(){
    this.setState({
      _successAlertOpen: !this.state._successAlertOpen
    })
  }

  _renderSuccessInfo(){
    return(
      <Alert color="info" isOpen={this.state._successAlertOpen} toggle={this._handleRemoveSuccessAlert}>
        <strong>Your list has been updated,</strong> maybe it can affect with your filter. Click (<i className="fa fa-refresh"></i>) button to refresh list.
      </Alert>
    )
  }

    /**
	 * Handle callback when user click pagination items
	 * @author pewe
	 * @param {integer} page
	 */
	_handleGoto(page) {
		const _params=this.state._params;
		_params.page=page;
		this.setState({
      _params,
      _isFetchData: true
    }, ()=> this._getList(this.state._params));
  }

  _handleRefresh(e){
    e.preventDefault();
    this._refreshList();
  }

  _handleApplyFilter(payloadFilter){
    // e.preventDefault();
    // const { sort_by, ..._params } = payloadFilter;
    this.setState({
      _params: {
        ...this.state._params,
        ...payloadFilter,
      },
      _isFetchData: true
    },()=>this._getList(this.state._params));
  }

  _refreshList(){
    const {page, ..._params} = this.state._params;
    this.setState({
      _params:{..._params},
      _isFetchData: true
    },()=>this._getList(this.state._params));
  }

  _handleResetFilter(e){
    e.preventDefault();
    const newOptions = this.state._sortOptions.map(item=>{
      if(item.value==="")
        return {value:item.value, label:'Default'}
      return item;
    })
    this.setState({
      _params:{
        ...defaultParams
      },
      _sortOptions: newOptions,
      _isResetFilter: true,
      _isFetchData: true,
      _selectedCategory: null
    },()=>this._getList(this.state._params));
  }

  _toggleFilterPanel(){
    this.setState({_filterOpen:!this.state._filterOpen})
  }

  _handleToggleFilterPanel(e){
    e.preventDefault();
    this._toggleFilterPanel();
  }

  _handleToggleModalBulkUpload(){
    this.setState({
      _modalBulkUploadOpen: !this.state._modalBulkUploadOpen
    })
  }

  _handleEditorsChoiceChange(name, type){
    const { _editorsChoiceTotal } = this.state;
    if(type==='DEC'){
      if(_editorsChoiceTotal-1<=0){
        this.setState({
          _editorsChoiceTotal:{
            ...this.state._editorsChoiceTotal,
            [name]: {
              ...this.state._editorsChoiceTotal[name],
              total: 0
            }
          }
        })
      }
      else{
        this.setState({
          _editorsChoiceTotal:{
            ...this.state._editorsChoiceTotal,
            [name]: {
              ...this.state._editorsChoiceTotal[name],
              total: this.state._editorsChoiceTotal[name].total - 1
            }
          }
        })
      }
    }
    else if(type==='INC'){
      this.setState({
        _editorsChoiceTotal:{
          ...this.state._editorsChoiceTotal,
          [name]: {
            ...this.state._editorsChoiceTotal[name],
            total: this.state._editorsChoiceTotal[name].total + 1
          }
        }
        // _editorsChoiceTotal: _editorsChoiceTotal + 1
      })
    }
  }



  _handleChangeAndSubmit(e){
    const { page,..._params } = this.state._params;
    _params[e.target.name] = e.target.value;
    this.setState({
      _params,
      _isFetchData: true
    },()=>{
      this._getList(this.state._params);
    });
  }
	/**
	 * Handle to change local state from input uset like textfield
	 * @author pewe
	 * @param {event} e
	 */
	_handleChange(e) {
    const { _params } = this.state;
    _params[e.target.name] = e.target.value;
    this.setState({_params});
  }

  _toggleModalInfo(type = null){
    this.setState({_modalInfo: {
      ...this.state._modalInfo,
      type: type===null ? this.state._modalInfo.type: type,
      isOpen: !this.state._modalInfo.isOpen
    }})
  }

  async _handleCategoryChanged(category){
    await this.setState({
      _selectedCategory: category === ''? null: category.label,
      _params: {
        ...this.state._params,
        category_id: category === ''? category: category.value,
        page: 1,
      },
      _isFetchData: true
    });
    const { _selectedCategory, _params } = this.state;
    this._getList(_params);
    if(_selectedCategory !== null){
      this._getEditorsChoiceList({
        id: category.value, name: category.label
      });
    }
  }

  _handleAutoSyncFilterChanged = async(e) =>{
    await this.setState({
      _params: {
        ...this.state._params,
        page: 1,
        [e.target.name]: e.target.checked? 1: 0
      },
      _isFetchData: true
    });
    this._getList(this.state._params);
  }

  _handleToggleModalScrape = (product = null) => {
    const { _modalScrape } = this.state
    this.setState({
      _modalScrape: {
        ..._modalScrape,
        isOpen: !_modalScrape.isOpen,
        product: product !== null? { ...product }: _modalScrape.product
      }
    })
  }

  _handleToggleModalPosition = (product = null) => {
    const { _modalPosition } = this.state
    this.setState({
      _modalPosition: {
        ..._modalPosition,
        isOpen: !_modalPosition.isOpen,
        product: product !== null? { ...product }: _modalPosition.product
      }
    })
  }

  render(){
    return (
      <div className="animated fadeIn">
        <ModalBulkUpload
          isOpen={this.state._modalBulkUploadOpen}
          onToggle={this._handleToggleModalBulkUpload}
          dataBulk={this.props.bulkUpload}
          refreshAfterDone={true}
          onRefresh={this._refreshList}
          onClear={this.props.clearBulkForm}
          onUpload={this.props.uploadBulk}
          onSubmit={this.props.postBulkUpload}
          bulkFormats={bulkVariantFormats}
          bulkUrl={'variants'}
        />
        <ModalInfo
          isOpen={this.state._modalInfo.isOpen}
          toggle={() => this._toggleModalInfo()}
          renderModalBody={()=>(
            <p>
              Ooops. You're reaching hot product's limit.
              {' '}Only ({this._getEditorsChoices(this.state._modalInfo.type,'threshold')}) items for {this.state._modalInfo.type} category.
            </p>
          )}
        />
        <ModalScrape
          id={this.state._modalScrape.product.id}
          displayName={this.state._modalScrape.product.displayName}
          priceSource={this.state._modalScrape.product.priceSource}
          isOpen={this.state._modalScrape.isOpen}
          toggle={() => this._handleToggleModalScrape()}
        />

        <ModalEditorsPosition
          size="sm"
          id={this.state._modalPosition.product.id}
          displayName={this.state._modalPosition.product.displayName}
          position={this.state._modalPosition.product.position}
          productPayloads={this.state._modalPosition.product.payloads}
          onSubmit={payload => this.props.updateProductVariant(this.state._modalPosition.product.id, payload)}
          isOpen={this.state._modalPosition.isOpen}
          toggle={() => this._handleToggleModalPosition()}
        />
        <Row>
          <Col xs={12}>
            <Card>
              <CardHeaderWithToolbars>
                <Title>
                  <i className="fa fa-align-justify"></i>
                  <span>Product Variant List</span>
                </Title>
                <Toolbars
                  isOpen={this.state._filterOpen}
                  onToggleModalBulkUpload={this._handleToggleModalBulkUpload}
                  onToggleFilter={this._handleToggleFilterPanel}
                  onRefreshList={this._handleRefresh}
                  onResetFilter={this._handleResetFilter}
                />
              </CardHeaderWithToolbars>
              <FilterContainer>
                <FiltersPanel
                  {...this.state._params}
                  sortOptions={this.state._sortOptions}
                  isOpen={this.state._filterOpen}
                  onApplyFilter={this._handleApplyFilter}
                  isReset={this.state._isResetFilter}
                  onResetFilterCallback={()=>this.setState({_isResetFilter: false, _filterOpen: false})}
                />
              </FilterContainer>
              <CardBody>
                <CardText>
                  In this section will display our product variants. Your hot product
                  {' '}({this._getEditorsChoices(this.state._selectedCategory, 'total')}/{this._getEditorsChoices(this.state._selectedCategory,'threshold')})
                </CardText>
                {this._renderSuccessInfo()}
                <List
                  data={this.state._list}
                  isLoading={this.state._isFetchData}
                  from={this.state._pagination.from}
                  to={this.state._pagination.to}
                  perPage={this.state._pagination.per_page}
                  currentPage={this.state._pagination.current_page}
                  lastPage={this.state._pagination.last_page}
                  total={this.state._pagination.total}
                  statusCode={this.state._pagination.status_code}
                  defaultRoute={DEFAULT_ROUTE}
                  findEditorChoices={this._getEditorsChoices}
                  // editorsChoiceTotal={this.state._editorsChoiceTotal}
                  // maxEditorsChoice={this.state._maxEditorsChoice}
                  onRenderHeader={()=>(
                    <tr>
                      <Th>No.</Th>
                      <Th>Brands</Th>
                      <Th>
                        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'flex-end'}}>
                          <p style={{marginRight: 10, marginBottom: 0}}>
                            Product Title
                            {
                              this.state._selectedCategory !== null?
                              <span style={{display: 'block', fontSize: '.5rem'}}>{this.state._selectedCategory}</span>
                              : null
                            }
                          </p>
                          <DropdownProductCategories
                            value={this.state._params.category_id}
                            onCategoryChanged={this._handleCategoryChanged}
                          />
                        </div>
                      </Th>
                      <Th>Hot Product</Th>
                      <Th>Updated</Th>
                      <Th>
                        <div>Action</div>
                        <div style={{fontSize: '.5rem', display: 'flex', alignItems: 'center'}}>
                          <p className="mb-0 mr-1" style={{cursor: 'pointer'}} id="label-icon-question">auto scrape <i className="icon-question" id="icon-question"></i></p>
                          <Radio value={true} name="auto_sync" onChange={this._handleAutoSyncFilterChanged} checked={this.state._params.auto_sync || false}  radioType="3d" size="xs" wrapperClassName="switch-success-outline-alt mb-0" usingLabelText={false}/>
                          <UncontrolledTooltip innerClassName="text-left" placement="right" target="label-icon-question">
                            Show data by auto scrape was enabled only.
                          </UncontrolledTooltip>
                        </div>
                      </Th>
                    </tr>
                  )}
                  onEditorsChoiceChange={this._handleEditorsChoiceChange}
                  onGotoPageList={this._handleGoto}
                  onToggleModalInfo={this._toggleModalInfo}
                  onToggleModalScrape={product => this._handleToggleModalScrape(product)}
                  onToggleModalPosition={product => this._handleToggleModalPosition(product)}
                  // onCategoryChanged={this._handleCategoryChanged}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    )
  }
}
const mapStateToProps= ({ masterCategories: { options: dataCategories }, productVariant, bulkUpload, auth: { isFetch, roles, isLogged, id, email }}) => {
	return {
    dataCategories,
    productVariant,
    bulkUpload,
    email,
    guardData: { isFetch, roles, isLogged, id, email }
	}
};
const mapDispatchToProps = (dispatch) => {
	return{
		getList:(query) => dispatch(getProductVariantList(query)),
    updateProductVariant: (id, payload) => dispatch(updateProductVariant(id, payload)),
    postBulkUpload:(payload) => dispatch(doBulkProducts(payload, 'variants')),
    uploadBulk:(file,folder) => dispatch(doUploadBulkProducts(file,folder)),
    clearBulkForm:()=>dispatch({type:'CLEAR_BULK_PRODUCT'}),
	}
};
const enhance = connect(mapStateToProps,mapDispatchToProps);
export default enhance(privateView(ProductVariantIndex,['supervisor','sale_supervisor','inventory_admin']));
