import React, { Component } from 'react';
import { connect } from 'react-redux';
import VideoForm from './VideoForm';
import privateView from '../../components/hocs/privateView';
import Analytics from "../../services/Analytics";

class VideoCreate extends Component{
	componentDidMount(){
    const { email } = this.props.injectedProps;
    Analytics.recordPageView('slider_detail', email, {
      url: window.location.href,
      location: this.props.location,
      match: this.props.match
    });
  }
  render(){
    return (
      <div>
        <VideoForm
          title="Create New Video"
          {...this.props}
        />
      </div>
    )
  }
}

const mapStateToProps = ({auth: { isFetch, roles, isLogged, id, email } })=>({
  guardData: { isFetch, roles, isLogged, id, email }
})

const enhance = connect(mapStateToProps);
export default enhance(privateView(VideoCreate))