import {
  COUNTRY_ALL_FETCH,
  COUNTRY_BATCH_UPDATE,
  COUNTRY_ERROR,
  COUNTRY_FETCHING,
  COUNTRY_LIST_FETCH,
  COUNTRY_ROUTE_API,
  INTERNATIONAL_SHIPPING_UPDATE,
} from "../constants"

import { apiClient } from "../utils/Api"
import { checkAuthorized, manipulateErrors } from "../utils"
import { logout } from "./authAction"

/**
 * Indicates when start fetch api or end fetch api
 * @author Haikal
 * @param {boolean} bool
 */
export const isFetch = (payload) => ({
  type: COUNTRY_FETCHING,
  payload: payload,
})

/**
 * When onSuccess triggered
 * @author Haikal
 * @param {object} res
 * @param {string} type
 */
export const onSuccess = (res, type) => ({
  type: type,
  payload: res,
})

/**
 * When error fetch or connect to api
 * @author Haikal
 * @param {object} err
 */
export const onError = (err) => {
  if (!checkAuthorized(manipulateErrors(err))) return logout()
  return {
    type: COUNTRY_ERROR,
    payload: manipulateErrors(err),
  }
}

export const getCountryList = (query) => (dispatch) => {
  let FETCH = COUNTRY_LIST_FETCH
  if (query.params?.no_limit === true) {
    FETCH = COUNTRY_ALL_FETCH
  }
  dispatch(isFetch({ type: "fetch", status: true }))
  apiClient()
    .get(COUNTRY_ROUTE_API, query)
    .then((res) => {
      dispatch(onSuccess(res.data, FETCH))
      dispatch(isFetch({ type: "fetch", status: false }))
    })
    .catch((err) => {
      dispatch(onError(err))
      dispatch(isFetch({ type: "fetch", status: false }))
    })
}

export const updateInternationalShipping=(payload)=>{
	return dispatch => (
		dispatch(isFetch({type:'submit',status:true})),
		apiClient()
      .post(`${COUNTRY_ROUTE_API}/international-shipping`,payload)
      .then(res=>{
        dispatch(onSuccess(res.data,INTERNATIONAL_SHIPPING_UPDATE))
        dispatch(isFetch({type:'submit',status:false}))
      })
      .catch(err => {
        dispatch(onError(err));
        dispatch(isFetch({type:'submit',status:false}));
      })
	)
}

export const updateBatchCountry=(payload)=>{
	return dispatch => (
		dispatch(isFetch({type:'submit',status:true})),
		apiClient()
      .post(COUNTRY_ROUTE_API,payload)
      .then(res=>{
        dispatch(onSuccess(res.data,COUNTRY_BATCH_UPDATE))
        dispatch(isFetch({type:'submit',status:false}))
      })
      .catch(err => {
        dispatch(onError(err));
        dispatch(isFetch({type:'submit',status:false}));
      })
	)
}