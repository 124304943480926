import React, {PureComponent as Component} from 'react';
import propTypes from 'prop-types';
import { Row, Col, Card } from 'reactstrap';

import { SizeDetail } from '../../../components/Products';
import DetailReadOnly from '../../../components/Products/DetailReadOnly';
import SellDetail from '../../../components/Products/SellDetail';
import ImageList from '../../../components/Card/ImageList';
import Image from '../../../components/Card/ImageList/Image';

const getPublicImageUrl = image => {
  return image.signed_url || image.URL
}

export default class TabProductDetail extends Component{
  _renderImages(data, prefixAlt){
    return(
      <Row>
        {
          data.map((image, index)=>
            <Col key={index} xs={6} md={3}>
              <Image                
                url={getPublicImageUrl(image)}
                alt={`${prefixAlt}-${image.id}`}
                updatedAt={image.updated_at}
              />
            </Col>
          )
        }
      </Row>
    )
  }

  render(){
    const { 
      product_variant:{ 
        product:{ 
          category, brand, ...product 
        }, 
        product_variant_images, 
        ...variant 
      },
      user_sell_images,
      size,
      ...userSell
    } = this.props.userSell;
    const featureImage = product_variant_images && product_variant_images.length? getPublicImageUrl(product_variant_images[0]): null
    return(
      <Card body className="tab-content--custom__card">
        <Row>
          <Col xs={12} md={6}>
            <DetailReadOnly
              {...variant}
              category={category}
              brand={brand}
              product={product}
              headerText="Variant Details"
              featureImage={featureImage}
              isLoading={this.props.isLoading}
            />
          </Col>
          <Col xs={12} md={6}>
            <Row>
              <Col xs={12}>
                <SizeDetail
                  renderHeaderText={()=> <span>Selected Size</span>}
                  {...size}
                  isLoading={this.props.isLoading}
                />
              </Col>
              <Col xs={12}>
                <SellDetail
                  {...userSell}
                  isLoading={this.props.isLoading}
                  listingCategory={category&&category.name.toUpperCase()}
                />
              </Col>
            </Row>
          </Col>
          <Col xs={12}>
            <ImageList
              data={product_variant_images}
              isEmpty={product_variant_images.length==0}
              isLoading={this.props.isLoading}
              headerText="Variant Images"
              onRenderItems={(data)=>this._renderImages(data, variant.slug)}
            />
          </Col>
          <Col xs={12}>
            <ImageList
              data={user_sell_images}
              isEmpty={user_sell_images.length==0}
              isLoading={this.props.isLoading}
              headerText="Selling Images"
              onRenderItems={(data)=>this._renderImages(data, variant.slug)}
            />
          </Col>
          
        </Row>
      </Card>
    )
  }
}
TabProductDetail.propTypes = {
  userSell: propTypes.shape({
    id: propTypes.number,
    user_sell_images: propTypes.array,
    product_variant:propTypes.shape({
      id:propTypes.number.isRequired,
      product_variant_images:propTypes.array,
      product: propTypes.shape({
        id: propTypes.number,
        brand: propTypes.object,
        category: propTypes.object
      })
    }),
    size: propTypes.shape({
      id: propTypes.number.isRequired,
      US: propTypes.any,
      EUR: propTypes.any,
      UK: propTypes.any,
      cm: propTypes.any
    })
  })
}
TabProductDetail.defaultProps={
  userSell:{
    id: 0,
    user_sell_images:[],
    size:{
      id:0,
      US: '-',
      EUR: '-',
      UK: '-',
      cm: '-'
    },
    product_variant:{
      id: 0,
      product_variant_images: [],
      product: {
        id: 0,
        brand: {},
        category: {
          id: 0,
          name: ''
        }
      }
    }
  }
}