import React from 'react';
import { connect } from 'react-redux';
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Form,
  Row,
  Col,
  FormGroup,
  Input,
  Label,
  Collapse,
  Button,
  Alert
} from 'reactstrap';
import classNames from 'classnames';
import { createRaffleCoupon } from '../../../../actions/raffleAction';
import { isRequired, isEmail } from '../../../../helpers/regex';
import { addErrorMessage, errorMessage, hasError ,  textValue } from '../../../../utils/form';
import { ButtonLoading } from '../../../../components/Button';
import SelectUserAsync from '../../../../components/Form/Select/Async/SelectUser';
import { ModalConfirmation } from '../../../../components/Modals';

class ModalCreateCoupon extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      formData: {
        reference_number: '',
        user: null,
        notes: '',
        quantity: 1,
        nominal: 0,
        per_tix: 40000,
      },
      modalConfirm: {
        modalBody: `Are you sure?`,
        isOpen: false
      },
      isSubmit: false,
      useQuantity: false,
      error: null,
      alertErrorOpen: false,
      errorMessage: null
    }
  }

  _handleInputChange = ({ target: { name, value, checked, type } }) => {
    this.setState({
      formData:{
        ...this.state.formData,
        [name]: type === 'checkbox'? checked: value
      }
    })
  }

  _renderErrorMessage = name => {
    const { error } = this.state;
    return errorMessage(name, error)
  }

  _hasInputError = name => {
    const { error } = this.state;
    return error && error.errors && hasError(name, error)
  }

  componentDidUpdate(prevProps) {
    if(!prevProps.isOpen && this.props.isOpen){
      this.setState({
        formData: {
          ...this.state.formData,
          reference_number: '',
          email: '',
          notes: '',
          quantity: 1,
          nominal: 0,
          per_tix: 40000
        },
        error: null,
        alertErrorOpen: false
      })
    }
    if(this.state.isSubmit && prevProps.dataRaffleCoupon.isSubmit && !this.props.dataRaffleCoupon.isSubmit){
      const { error } = this.props.dataRaffleCoupon;
      if(error){
        this.setState({
          isSubmit: false,
          error: { ...error },
          alertErrorOpen: true,
          errorMessage: this.translateMessage(error.status_code)
        })
      }
      else{
        this.setState({
          isSubmit: false,
          alertErrorOpen: false
        },
          () => this._handleToggle());
      }
    }
  }

  translateMessage = (statusCode, message) => {
    let messageStr = ''
    switch(statusCode){
      case 200: messageStr = 'Success...'; break;
      case 422: messageStr = 'Please fullfill your form.'; break;
      case 404: messageStr = 'Resource not found.'; break;
      case 500: messageStr = "We've got something errors"; break;
      default: messageStr = message; break;
    }
    return messageStr
  }

  _validateForm = () => {
    const { formData, useQuantity } = this.state;
    let errors={};
    if(!isRequired(formData.reference_number)){
      errors['reference_number'] = addErrorMessage(errors, 'reference_number', 'The reference number field is required.');
    }
    if(!formData.user || !isRequired(formData.user.email)){
      errors['email'] = addErrorMessage(errors, 'email', 'The email field is required.');
    }
    if(formData.user && !isEmail(formData.user.email)){
      errors['email'] = addErrorMessage(errors, 'email', 'The email field is invalid.');
    }
    if(useQuantity){
      if(formData.quantity < 1){
        errors['quantity'] = addErrorMessage(errors, 'quantity', 'The quantity field must be greater than 0.');
      }
    } else{
      if(formData.per_tix < 1){
        errors['per_tix'] = addErrorMessage(errors, 'per_tix', 'The per tix field must be greater than 0.');
      }
      if(formData.nominal < 1){
        errors['nominal'] = addErrorMessage(errors, 'nominal', 'The nominal field must be greater than 0.');
      }
      if(Number(formData.per_tix) > Number(formData.nominal))
        errors['nominal'] = addErrorMessage(errors, 'nominal', 'The nominal field is invalid.');
    }
    let errorState = null;
    if(Object.keys(errors).length){
      errorState = {
        errors: { ...errors },
        status_code: 422,
        message: this.translateMessage(422)
      }
      this.setState({
        error: errorState? {...errorState}: null,
        errorMessage: errorState? errorState.message: null,
        alertErrorOpen: true
      })
    }
    // console.log("Error: ", errors);
    return Object.keys(errors).length>0;
  }

  _handleOnSubmit = e => {
    e.preventDefault()
    if(this.state.isSubmit) return;
    if(!this._validateForm()){
      // alert("Asala");
      const { formData, useQuantity } = this.state;
      let quantity = formData.quantity;
      if(!useQuantity){
        const { per_tix, nominal } = formData;
        quantity = Math.floor(Number(nominal)/Number(per_tix));
      }
      const modalBody = `Confirmation. Create ${quantity} raffle entries for ${this.state.formData.user.email}. Are you really sure?`;
      this.setState({
        formData: {
          ...formData,
          quantity
        },
        modalConfirm: {
          modalBody,
          isOpen: true
        }
      })
    }
  }

  onConfirmClick = () => {
    this.setState({
      isSubmit: true,
      error: null,
      alertErrorOpen: false,
      modalConfirm: {
        ...this.state.modalConfirm,
        isOpen: false
      }
    });
    const { formData } = this.state;
    const { raffleId } = this.props;
    const payloads = {
      reference_number: formData.reference_number.toUpperCase(),
      user_id: formData.user? (formData.user.isNew? null: formData.user.id): null,
      email: formData.user? formData.user.email: null,
      notes: formData.notes? formData.notes: null,
      quantity: formData.quantity,
    }
    this.props.save(raffleId, payloads)
  }

  _handleToggle = () => {
    if(this.state.isSubmit) return;
    this.props.toggle && this.props.toggle();
  }

  _handleOnSelectUserChange = selected => {
    const { error, formData } = this.state;
    let errors = {};
    let errorState = null;
    if(error && error.errors){
      errors = { ...error.errors };
    }
    if(selected){
      if(!isEmail(selected.email)){
        errors['email'] = ['The email field is invalid.'];
      } else{
        errors = Object.keys(errors).reduce((err, errorKey) => {
          if(errorKey !== 'email'){
            return { ...err, [errorKey]: errors[errorKey] }
          }
          return err;
        }, {})
      }
    }
    if(Object.keys(errors).length){
      errorState = {
        errors: { ...errors },
        status_code: 422,
        message: 'Please full fill form'
      }
    }
    let user = null;
    if(selected){
      user = {
        id: selected.id,
        email: selected.email,
        isNew: selected.className? true: false
      }
    }
    this.setState({
      formData: {
        ...formData,
        user
      },
      error: errorState? {...errorState}: null,
    });
  }


  render(){
    return(
      <div>
        <ModalConfirmation
          isOpen={this.state.modalConfirm.isOpen}
          modalBody={this.state.modalConfirm.modalBody}
          onSubmit={this.onConfirmClick}
          toggle={() => {
            this.setState({
              modalConfirm: {
                ...this.state.modalConfirm,
                isOpen: false
              }
            });
          }}
        />
        <Modal isOpen={this.props.isOpen} size={this.props.size}>
          <Form onSubmit={this._handleOnSubmit}>
            <ModalHeader toggle={this._handleToggle}>Create New Code.</ModalHeader>
            <ModalBody>
              <Row>
                <Col xs={12}>
                  <Alert color="danger" isOpen={this.state.alertErrorOpen} toggle={() => this.setState({alertErrorOpen: false})}>
                    <strong>Oh Snap!</strong> { this.state.errorMessage }
                  </Alert>
                </Col>
                <Col xs={12} sm={12}>
                  <FormGroup
                    className={classNames({ 'has-danger has-feedback': this._hasInputError('reference_number') })}
                  >
                    <Label>Reference Number</Label>
                    <Input
                      placeholder="Enter a reference number."
                      name="reference_number"
                      type="text"
                      value={textValue('reference_number', this.state.formData)}
                      onChange={this._handleInputChange}
                    />
                    { this._renderErrorMessage('reference_number') }
                  </FormGroup>
                </Col>
                <Col xs={12} sm={12}>
                  <FormGroup
                    className={classNames({ 'has-danger has-feedback': this._hasInputError('email') })}
                  >
                    <Label>Email</Label>
                    <SelectUserAsync
                      create
                      defaultOptions
                      id="formSelectSeller"
                      value={this.state.formData.user}
                      // paramsApi={{ roles: 'seller,administrator', role_query: 'or', scope: 'all' }}
                      placeholder="Enter a user's email."
                      onSelectChange={this._handleOnSelectUserChange}
                    />
                    { this._renderErrorMessage('email') }
                  </FormGroup>
                </Col>
                <Col xs={12} sm={12}>
                  <FormGroup
                    className={classNames({ 'has-danger has-feedback': this._hasInputError('notes') })}
                  >
                    <Label>Notes - optional</Label>
                    <Input
                      placeholder="Enter a notes."
                      name="notes"
                      type="textarea"
                      value={textValue('notes', this.state.formData)}
                      onChange={this._handleInputChange}
                    />
                    { this._renderErrorMessage('notes') }
                  </FormGroup>
                </Col>
              </Row>
              <Collapse isOpen={!this.state.useQuantity}>
                <Row>
                  <Col xs={6} sm={6}>
                    <FormGroup
                      className={classNames({ 'has-danger has-feedback': this._hasInputError('per_tix') })}
                    >
                      <Label>
                        Per Tix {' '}
                        <Button style={{padding: '2px 6px', fontSize: '.65rem'}} size="sm" type="button" onClick={() => this.setState({useQuantity: !this.state.useQuantity})}>
                          Use Quantity
                        </Button>
                      </Label>
                      <Input
                        placeholder="Enter a per tix."
                        name="per_tix"
                        disabled
                        type="number"
                        value={textValue('per_tix', this.state.formData)}
                        onChange={this._handleInputChange}
                      />
                      { this._renderErrorMessage('per_tix') }
                    </FormGroup>
                  </Col>
                  <Col xs={6} sm={6}>
                    <FormGroup
                      className={classNames({ 'has-danger has-feedback': this._hasInputError('nominal') })}
                    >
                      <Label>Nominal</Label>
                      <Input
                        placeholder="Enter a nominal."
                        name="nominal"
                        type="number"
                        value={textValue('nominal', this.state.formData)}
                        onChange={this._handleInputChange}
                      />
                      { this._renderErrorMessage('nominal') }
                    </FormGroup>
                  </Col>
                </Row>
              </Collapse>
              <Collapse isOpen={this.state.useQuantity}>
                <Row>
                  <Col xs={6} sm={6}>
                    <FormGroup
                      className={classNames({ 'has-danger has-feedback': this._hasInputError('quantity') })}
                    >
                      <Label>Quantity{' '}
                        <Button style={{padding: '2px 6px', fontSize: '.65rem'}} size="sm" type="button" onClick={() => this.setState({useQuantity: !this.state.useQuantity})}>
                          Use Nominal
                        </Button>
                      </Label>
                      <Input
                        placeholder="Enter a quantity."
                        name="quantity"
                        type="number"
                        value={textValue('quantity', this.state.formData)}
                        onChange={this._handleInputChange}
                      />
                      { this._renderErrorMessage('quantity') }
                    </FormGroup>
                  </Col>
                </Row>
              </Collapse>
            </ModalBody>
            <ModalFooter>
              <ButtonLoading
                disabled={this.state.isSubmit}
                isLoading={this.state.isSubmit}
                loadingMessage="Submitting..."
                color="primary"
                >
                Submit
              </ButtonLoading>
            </ModalFooter>
          </Form>
        </Modal>
      </div>
    )
  }
}

const mapStateToProps= ({ raffle: { coupons } }) => ({
  dataRaffleCoupon: coupons.details
});
const mapDispatchToProps = (dispatch) => ({
  save: (raffleId, payloads) => dispatch(createRaffleCoupon(raffleId, payloads))
});
export default connect(mapStateToProps,mapDispatchToProps)(ModalCreateCoupon);
