import React from 'react';
import { FontAwesomeIcon } from '../../../components/Icons';
import Paragraph from '../../../styled/Typograph/Paragraph';
import SmallInline from '../../../styled/Typograph/Small/SmallInline';
import BadgeThin from '../../../components/Badges/BadgeThin';

export const TitleColumn = props => {
  const { id, slug, name, createdAt, pointType, itemCount } = props;
  return(
    <div>
      <SmallInline className="text-muted mr-1">#{id} {slug}</SmallInline>
      <Paragraph>
        {name} {pointType? (
        <BadgeThin>{pointType==='variants'? 'variants': pointType}</BadgeThin>)
        :null}
      </Paragraph>
      <SmallInline>
        <span className="mr-1">
          <FontAwesomeIcon iconType="calendar" className="mr-1"/>{ createdAt }
        </span>
        ({itemCount} items)
      </SmallInline>
    </div>
  )
}
