import {
  TOP_UP_ERROR,  
  UPDATE_TOP_UP,
  FETCHING_TOP_UP, 
  GET_LIST_TOP_UP,
  TOP_UP_ROUTE_API,   
  GET_DETAIL_TOP_UP,  
  FETCH_TOP_UP_ERROR,
  AUTH_TOKEN_KEY
} from '../constants';

/**
 * Initial State
 */
const initialState={
	list:[],
  detail:{},
	isFetch:false,
	isSubmit:false,
	error:null
}

export const onFetchingTopUp = (lastState,payload) => {
	if(payload.type==='fetch')
		return { ...lastState, isFetch:payload.status }
	else if(payload.type==='submit')
		return { ...lastState, isSubmit:payload.status }

	return { ...lastState, isFetch:false, isSubmit:false}
}
/**
 * Function to manipulate payload when success get top-up list
 * @author sopyan
 * @param {object} lastState 
 * @param {object} payload 
 */
const onGetTopUpList = (lastState,payload) => {
	const {data, message, status_code}=payload;
	return { ...lastState, error:null, success:null, list:{ ...data, message, status_code } };	
}

/**
 * Function to manipulate payload when success get top-up Details
 * @author sopyan
 * @param {object} lastState 
 * @param {object} payload 
 */
const onGetDetailTopUp = (lastState,payload) => {
	const {data, message, status_code}=payload;
	return {...lastState, error:null, success:null, detail:{ ...data, message, status_code} };
}

/**
 * Function to handle when fetching top-up error triggered
 * @author sopyan
 * @param {object} lastState 
 * @param {object} error 
 */
const onTopUpError = (lastState,error) => {
	return { ...lastState, ...error };
}

/**
 * TopUp Reducer
 * @author sopyan
 * @param {object} state 
 * @param {object} action 
 */
const topUpReducer= (state=initialState, action) => {
	switch(action.type)
	{
		case FETCHING_TOP_UP : return onFetchingTopUp(state,action.payload);
		case GET_LIST_TOP_UP : return onGetTopUpList(state,action.payload);
		case GET_DETAIL_TOP_UP : return onGetDetailTopUp(state,action.payload);
		case UPDATE_TOP_UP : return onGetDetailTopUp(state,action.payload);
		case TOP_UP_ERROR : return onTopUpError(state,action.payload);
    default : return state;
	}
}

export default topUpReducer;