import React from 'react';
import propTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import DateToHuman from '../../../../components/DateToHuman';
import { TextRupiah } from '../../../../components/Text';
import { updateSale } from '../../../../actions/saleAction';
import {
  getDetailConsignment,
  attachRackConsignmentV2,
  getConsignmentNotes,
  setReadConsignmentNotes,
  doMarkedConsignment,
} from "../../../../actions/consignmentAction";
import { checkChange } from '../../../../utils/form';
import OrderSummariesColumn from '../../RowItem/OrderSummariesColumn';
import SelectRackV2 from '../../../../components/Form/Select/Async/SelectRackV2';
import { ModalMarkedConsignment } from '../../../../components/Modals';
import ButtonStarred from '../../RowItem/ButtonStarred';

class RowItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checkedItems: {},
      autoDisburse: props.autoDisburse,
      _autoDisburseEdit: props.autoDisburse,
      autoCancel: props.autoCancel ? false : true,
      _autoCancelEdit: props.autoCancel ? false : true,
      updatedAt: props.updatedAt,
      _isModalMarked: false,
      marked: props.marked,
      _markedEdit: props.marked,
      markedAt: props.markedAt || '',
      markedBy: props.markedBy || '',
      closeMarkedAt: props.closeMarkedAt || '',
      closeMarkedBy: props.closeMarkedBy || '',
      notes: props.notes || [],
      notesPage: 1,
      noteNextPage: null,
      unreadNoteCount: props.unreadNoteCount || 0,
      isConfirmation: false,
      purchasePrice: props.purchasePrice,
      _isModalTracking: false,
      shippingUpdate: null,
      isUpdateOptions: null,
      rack: props.rack ? {
        id: props.rack.id,
        name: props.rack.name,
        max: props.rack.max,
        count: 0
      } : null,
      error: null
    }
    this._handleCheckedChange = this._handleCheckedChange.bind(this);
    this._handleCheckboxChange = this._handleCheckboxChange.bind(this);
    this._handleSelectRack = this._handleSelectRack.bind(this)
    this._handleMarkedChange = this._handleMarkedChange.bind(this);
    this._handleModalMarkedOnSubmit = this._handleModalMarkedOnSubmit.bind(this);
  }

  async _handleSelectRack(option) {
    const { id, data: { user_sell_id } } = this.props;
  
    try {
      await this.setState({
        rack: option,
        error: null
      });
  
      if (option) {
        await this.props.saveRack(id, { sell_id: user_sell_id, rack_id: option.id });
      }

      this.props.onRefreshList();
  
    } catch (error) {
      this.setState({ error: error.response ? error.response.data.error.message : error.message });
    }
  }  

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.allSelected !== this.props.allSelected) {
      if (this.props.allSelected) {
        const selectedItem = this.props.selectedItems.filter(item => item.id === this.props.id).length > 0;
        this.setState((prevState) => ({
          checkedItems: {
            ...prevState.checkedItems,
            [this.props.id]: selectedItem,
          },
        }));
      } else {
        this.setState({ checkedItems: {} });
      }
    }
    if (prevProps.consignment.isSubmit !== this.props.consignment.isSubmit && prevProps.consignment.isSubmit) {
      if (prevProps.consignment.error === null) {
        const { id, autodisburse, auto_cancelled_at, updated_at, marked, open_marked_at, open_marked_by, sale_notes, unread_notes_count, close_marked_at, close_marked_by, purchase_price } = this.props.consignment.detail;
        if (prevProps.id === id) {
          let autoCancelEdit = auto_cancelled_at ? false : true;
          this.setState({
            autoDisburse: autodisburse,
            _autoDisburseEdit: autodisburse,
            autoCancel: autoCancelEdit,
            _autoCancelEdit: autoCancelEdit,
            marked: marked,
            _markedEdit: marked,
            markedAt: open_marked_at || '',
            markedBy: open_marked_by || '',
            closeMarkedAt: close_marked_at || '',
            closeMarkedBy: close_marked_by || '',
            notes: sale_notes,
            unreadNoteCount: unread_notes_count,
            updatedAt: updated_at,
            purchasePrice: purchase_price
          })
        }
      }
      else {
        const { id } = this.props.consignment.error;
        if (id && this.props.id === id) {
          this.setState({
            _autoDisburseEdit: this.state.autoDisburse,
            _autoCancelEdit: this.state.autoCancel,
            _markedEdit: this.state.marked,
          })
        }
      }
    }

    if (prevProps.consignment.isSubmit && !this.props.consignment.isSubmit) {
      const { list: { data }, consignmentId, error } = this.props.consignment;
      if (this.props.id === consignmentId && !error) {
        if (data.total > 0) {
          this.props.getDetailConsignment(this.props.id);
        }
      }
    }

    if (prevProps.consignment.notes.isFetch && !this.props.consignment.notes.isFetch) {
      const { list: { data, next_page_url, error }, sell_consignment_id } = this.props.consignment.notes;
      if (this.props.id === sell_consignment_id) {
        if (error) {
          alert('Error while fetching notes');
        } else {
          const { loginId } = this.props;
          const ids = data.filter(item => !item.read && item.user_id !== loginId).map(({ id }) => id);
          if (ids.length) {
            this.props.setReadConsignmentNotes(this.props.id, { ids });
          }
          this.setState({
            notes: this.state.notesPage === 1 ? [...data] : [...data, ...this.state.notes],
            noteNextPage: next_page_url ? this.state.notesPage + 1 : null
          });
        }
      }
    }

    if (prevProps.consignment.notes.isSubmit && !this.props.consignment.notes.isSubmit) {
      const { sell_consignment_id } = this.props.consignment.notes;
      this.setState({
        unreadNoteCount: sell_consignment_id === this.props.id ? 0 : this.state.unreadNoteCount,
      });
    }

    if (!prevState._isModalMarked && this.state._isModalMarked && !this.state.isConfirmation) {
      this.setState({
        notesPage: 1,
        noteNextPage: null
      }, () => {
        this.props.getConsignmentNotes(this.props.id, {
          params: { per_page: 3, page: this.state.notesPage }
        });
      });
    }

    if (
      prevProps.consignment?.detail?.marked !==
        this.props.consignment?.detail?.marked &&
      prevProps.id === this.props.consignment?.detail?.id
    ) {
      this.setState({
        marked: !!this.props.consignment?.detail?.marked,
        closeMarkedAt: this.props.consignment?.detail?.close_marked_at || '',
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.consignment.isSubmit) {
      return false;
    }
    if (!nextProps.consignment.isSubmit && nextProps.consignment.isSubmit !== this.props.consignment.isSubmit) {
      if (nextProps.consignment.error) {
        const { error: { id } } = nextProps.consignment;
        if (id && id != this.props.id) {
          return false;
        }
      } else {
        if (nextProps.consignment.detail.id && nextProps.consignment.detail.id != this.props.id) {
          return false;
        }
      }
    }

    if (!nextProps.consignment.rackDetails.isSubmit && nextProps.consignment.rackDetails.isSubmit !== this.props.consignment.rackDetails.isSubmit) {
      if (nextProps.consignment.rackDetails.error) {
        if (nextProps.consignment.rackDetails.error.errors.sell_id && nextProps.consignment.rackDetails.error.errors.sell_id === this.props.id) {
          const { error } = nextProps.consignment.rackDetails;
          this.setState({
            isUpdateOptions: Date.now().toString(),
            error: error.message
          });
        }
      }
    }

    if (this.props.consignment.rackDetails.isSubmit && this.props.consignment.rackDetails.isSubmit !== nextProps.consignment.rackDetails.isSubmit) {
      if (!nextProps.consignment.rackDetails.error) {
        if (nextProps.consignment.rackDetails && nextProps.consignment.rackDetails.data && nextProps.consignment.rackDetails.data.user_sells_count + 1 >= this.props.consignment.rackDetails.data.max_capacity) {
          this.setState({
            isUpdateOptions: Date.now().toString()
          });
        }
      }
    }
    return true;
  }

  _handleCheckedChange(e) {
    this.setState({
      ...checkChange(e, this.state)
    }, () => {
      const payload = {
        autodisburse: this.state._autoDisburseEdit,
      }
      this.props.update(this.props.id, payload);
    });
  }

  _handleCheckboxChange(event) {
    const { name, checked } = event.target;
    this.setState((prevState) => ({
      checkedItems: {
        ...prevState.checkedItems,
        [name]: checked,
      },
    }), () => {
      const payload = {
        ...this.props.data,
        selected: checked
      };
      this.props.printSelectedItems(payload);
    });
  }

  _handleMarkedChange() {
    this.setState({
      _isModalMarked: !this.state._isModalMarked,
      _markedEdit: this.state.marked
    });
  }

  _handleModalMarkedOnSubmit({ marked_notes, marked = null }) {
    if (marked === false) {
      const payload = { marked };
      if (marked_notes) {
        payload['marked_notes'] = marked_notes;
      }
      this.setState({
        _markedEdit: marked,
        _isModalMarked: false,
        isConfirmation: false
      });
      this.props.doMarkedConsignment(this.props.id, payload);
      return;
    }
    this.setState({
      _markedEdit: this.state.closeMarkedAt ? (marked !== null ? marked : this.state._markedEdit) : true,
      _isModalMarked: false,
      markedNotes: marked_notes
    }, () => {
      const payload = {
        marked: this.state._markedEdit,
        marked_notes
      }
      this.props.doMarkedConsignment(this.props.id, payload);
    });
  }

  render() {
    const {
      id,
      rowId,
      data,
      bank,
      quantity,
      buyerEmail,
      sellerEmail,
      invoiceNumber,
      totalAmount,
      orderDate,
      orderStatus,
      displayName,
      SKU,
      size,
      colour,
      preOrder,
      preVerified,
      consignmentId,
      updatedAt,
      sneakersCondition,
      category,
      paymentMethod,
      rack,
      followUpStatus,
      voucherCode,
      legitCheckLogs,
      approvedAt,
      stockStatus
    } = this.props;
    return (
      <tr>
        <ModalMarkedConsignment
          isOpen={this.state._isModalMarked}
          isConfirmation={this.state.isConfirmation}
          toggle={this._handleMarkedChange}
          invoiceNumber={invoiceNumber}
          marked={this.state.marked}
          markedAt={this.state.markedAt}
          markedBy={this.state.markedBy}
          closeMarkedAt={this.state.closeMarkedAt}
          markedNotes={this.state.markedNotes}
          notes={this.state.notes}
          unreadNoteCount={this.state.unreadNoteCount}
          hasNextPage={this.state.noteNextPage}
          loginId={this.props.loginId}
          onSubmit={this._handleModalMarkedOnSubmit}
          onLoadMoreNotes={() => {
            const { notesPage, noteNextPage } = this.state;
            if (!noteNextPage) return;
            this.setState({
              noteNextPage: null,
              notesPage: notesPage + 1
            }, () => {
              this.props.getConsignmentNotes(this.props.id, {
                params: { per_page: 3, page: this.state.notesPage }
              });
            })
          }}
        />
        <th className="v-center" scope="row">
          {
            !this.props.isOpenPrint ?
              rowId
              :
              <input
                className="print-order-checkbox"
                name={id}
                type="checkbox"
                checked={this.state.checkedItems[id] || false}
                onChange={this._handleCheckboxChange}
              />
          }
        </th>
        <td className="v-center">
          <OrderSummariesColumn
            isRackAssignment
            id={id}
            quantity={quantity}
            invoiceNumber={invoiceNumber}
            orderDate={orderDate}
            buyerEmail={buyerEmail}
            sellerEmail={sellerEmail}
            displayName={displayName}
            size={size}
            SKU={SKU}
            colour={colour}
            preOrder={preOrder}
            preVerified={preVerified}
            consignmentId={consignmentId}
            sneakersCondition={sneakersCondition}
            category={category}
            unreadNoteCount={this.state.unreadNoteCount}
            legitCheckLogs={legitCheckLogs}
            stockStatus={stockStatus}
          />
        </td>
        <td className="v-center" width={200}>
          <SelectRackV2
            key={this.state.isUpdateOptions}
            id="warehouse_rack"
            ref="selectRack"
            value={this.state.rack}
            rackOptions={this.props.rackOptions}
            placeholder="Select a rack..."
            noResultsText="Cannot find rack."
            onSelectSearch={this.props.onSelectSearch}
            onSelectChange={this._handleSelectRack}
            onSelectOpen={this.props.handleOnSelectRackOpen}
            isClearable
          />
          {this.state.error && (
            <p className='text-danger mb-0'>{this.state.error}</p>
          )}
        </td>
        <td className="v-center">
          <div>
            <TextRupiah prefix="Rp. " bold value={totalAmount} />
            <div className="issued-wrapper" style={{ minWidth: 95 }}>
              <a
                href="#"
                className="mr-1"
                style={{ textDecoration: 'underline', color: '#4a4a4a', fontSize: '.75rem' }}
                onClick={e => {
                  e.preventDefault();
                  this.setState({ _isModalMarked: true })
                }}
              >
                <i className="fa fa-pencil"></i> {this.state.markedAt ? 'view' : 'add'} notes
              </a>
              <ButtonStarred
                title={this.state._markedEdit ? "Remove mark!" : "Mark!"}
                value={this.state._markedEdit}
                onClick={() => {
                  const value = !this.state._markedEdit;
                  this.setState({
                    _isModalMarked: true,
                    isConfirmation: !value,
                    _markedEdit: value
                  })
                }}
              />
            </div>
            {
              approvedAt && (<div style={{ fontSize: '.65rem' }}>
                Approved:<br />
                {approvedAt}
              </div>)
            }
            {
              consignmentId && (<div style={{ fontSize: '.65rem' }}> <i className="fa fa-barcode mr-1"></i>
                {consignmentId}
              </div>)
            }
          </div>
        </td>
        <td className="v-center">
          <DateToHuman value={updatedAt} id={`tooltip_${rowId}`} />
        </td>
        <td className="v-center">
          <div>{orderStatus}</div>
        </td>
        <td className="v-center">
          <div>
            <Link to={`/consignment/${id}`} className="btn btn-sm btn-secondary" target="_blank" title="Detail"><i className="fa fa-external-link"></i> Details</Link>
          </div>
        </td>
      </tr>
    )
  }
}

const mapStateToProps = ({ sale, consignment }) => ({
  sale,
  consignment,
})

const mapDispatchToProps = (dispatch) => ({
  getDetailConsignment: (id) => dispatch(getDetailConsignment(id)),
  update: (id, payload) => dispatch(updateSale(id, payload)),
  saveRack: (id, payload) => dispatch(attachRackConsignmentV2(id, payload)),
  getConsignmentNotes: (id, payload) => dispatch(getConsignmentNotes(id, payload)),
  setReadConsignmentNotes: (consignmentId, noteIds) => dispatch(setReadConsignmentNotes(consignmentId, noteIds)),
  doMarkedConsignment: (consignmentId, payload) => dispatch(doMarkedConsignment(consignmentId, payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(RowItem);

RowItem.defaultProps = {
  totalAmount: 0,
  marked: false,
}

RowItem.propTypes = {
  rowId: propTypes.number.isRequired,
  id: propTypes.number.isRequired,
  buyerEmail: propTypes.string,
  sellerEmail: propTypes.string,
  autoDisburse: propTypes.number,
  marked: propTypes.bool,
  totalAmount: propTypes.oneOfType([propTypes.number, propTypes.string]),
  orderDate: propTypes.string,
  orderStatus: propTypes.string,
  status: propTypes.string,
  invoiceNumber: propTypes.string,
  size: propTypes.string,
  displayName: propTypes.string,
  SKU: propTypes.string,
  colour: propTypes.string,
  preOrder: propTypes.oneOfType([propTypes.number, propTypes.string, propTypes.bool]),
  preVerified: propTypes.oneOfType([propTypes.bool, propTypes.number]),
  consignmentId: propTypes.string,
  updatedAt: propTypes.string,
  onOpenCreditCard: propTypes.func.isRequired,
  markedNotes: propTypes.string,
  markedAt: propTypes.string,
  rack: propTypes.string,
}
