import React from 'react';
import { connect } from 'react-redux';
import {
  Row,
  Col,
  Card,
  CardBody
} from 'reactstrap';
import Loading from 'react-loading-animation';
import { doGetDetailDisbursement, doDeleteDisbursement, doUpdateDisbursement, doGetBankAccountList, doGetCashoutFee, doGetCashoutSetting } from '../../actions/disbursementUserAction';
import privateView from '../../components/hocs/privateView';
import Form from './disbursementDetails/Form';
import ReadOnlyForm from './disbursementDetails/ReadOnlyForm';
import Analytics from "../../services/Analytics";

const LoadingWrapper = () => (
  <Card>
    <CardBody>
      <Loading/>
    </CardBody>
  </Card>
)
class DisbursementDetails extends React.Component{
  constructor(props){
    super(props);
    this.state={
      _id: this.props.match.params.id,
      _disbursementUser: {},
      _isFetchingData: true,
      _isSubmittingData: false,
      _content: 'EDITABLE'
    }
    this._handleOnDeleteDisbursement = this._handleOnDeleteDisbursement.bind(this);
    this._handleOnUpdateDisbursement = this._handleOnUpdateDisbursement.bind(this);
    this._handleOnRedirect = this._handleOnRedirect.bind(this);
  }

  componentDidMount(){
    const { email } = this.props.injectedProps;
    this.props.getById(this.state._id)
    Analytics.recordPageView('disbursement_detail', email, {
      url: window.location.href,
      location: this.props.location,
      match: this.props.match
    });
    this.props.getFeeSetting();
  }

  componentDidUpdate(prevProps) {
    if(prevProps.detail.isFetch && prevProps.detail.isFetch!==this.props.detail.isFetch){
      const { error, data } = this.props.detail;
      if(error){
        const { status_code } = error;
        if(status_code){
          this._handleOnRedirect('/404');
        }
        this.setState({
          _isFetchingData: false
        })
      }
      else{
        let newData = data;
        newData.disabled_payout=data.payout_type==='MIDTRANS';
        this.setState({
          _disbursementUser: data,
          _content: data.status==='NEW'?'EDITABLE': 'READONLY',
          _isFetchingData: false
        })
      }
    }
    if(prevProps.detail.isSubmit && prevProps.detail.isSubmit!==this.props.detail.isSubmit){
      const { error, data, status_code, message } = this.props.detail;
      if(error){
        this.setState({
          _isSubmittingData: false,
          _error: error
        })
      }
      else{
        let newData = data;
        newData.disabled_payout=data.payout_type==='MIDTRANS';
        this.setState({
          _disbursementUser: { ...newData, status_code, message },
          _error: null,
          _isSubmittingData: false
        })
      }
    }
  }

  _handleOnDeleteDisbursement(id){
    const email = this.props;

    this.setState({
      _isSubmittingData: true
    }, ()=> this.props.delete(id))
    Analytics.recordDeleteDisbursement(
      id,
      email
    );
  }

  _handleOnUpdateDisbursement(id, payloads){
    this.setState({
      _isSubmittingData: true
    }, ()=> this.props.update(id, payloads))
  }

  _handleOnRedirect(link){
    this.props.history.replace(link)
  }

  _renderContent(){
    if(this.state._isFetchingData){
      return (
        <Row>
          <Col xs={12}>
            <LoadingWrapper/>
          </Col>
        </Row>
      )
    }
    else{
      if(this.state._content === 'EDITABLE'){
        return (
          <Form
            id={this.state._id}
            isSubmit={this.state._isSubmittingData}
            dataCashoutFee={this.props.cashoutFee}
            dataDisbursementUser={this.state._disbursementUser}
            dataDisbursementUserError={this.state._error}
            onDeleteDisbursement={this._handleOnDeleteDisbursement}
            onUpdateDisbursement={this._handleOnUpdateDisbursement}
            onGetCashoutFee={this.props.getFee}
            onRedirect={this._handleOnRedirect}
            getList={this.props.getList}
            bankAccount={this.props.bankAccount}
            email={this.props.email}
          />
        )
      }
      else if(this.state._content === 'READONLY'){
        // console.log(this.state._disbursementUser.audits)
        return(
          <ReadOnlyForm
            id={parseInt(this.state._id)}
            history={this.props.history}
            dataDisbursementUser={this.state._disbursementUser}
          />
        )
      }
    }
    return null;
  }
  render(){
    return(
      <div className="animated fadeIn">
        { this._renderContent() }
      </div>
    )
  }
}

const mapStateToProps= ({ disbursementUser: { detail, midtrans, cashoutFee }, auth:{isFetch, isLogged, roles, id, email}}) => ({
  detail,
  guardData: {isFetch, isLogged, roles, id},
  email,
  cashoutFee,
  bankAccount:midtrans.bankAccount
});

const mapDispatchToProps = (dispatch) => ({
  getById: id => dispatch(doGetDetailDisbursement(id)),
	getFee: params => dispatch(doGetCashoutFee(params)),
	getFeeSetting: () => dispatch(doGetCashoutSetting()),
  update: (id, payloads) => dispatch(doUpdateDisbursement(id, payloads)),
  delete: id => dispatch(doDeleteDisbursement(id)),
  getList: () => dispatch(doGetBankAccountList())
});

// const enhance = connect(mapStateToProps);
const enhance = connect(mapStateToProps, mapDispatchToProps);
export default enhance(privateView(DisbursementDetails));