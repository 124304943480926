import React from 'react';
import { Th } from '../../../../components/Tbl';
import DropdownCategories from '../../Filters/DropdownCategories';
import ButtonStarred from '../../RowItem/ButtonStarred';

const RowHeader = props => (
  <tr>
    <Th>No.</Th>
    <Th style={{ width: '20vw' }}>
      <div style={{display: 'flex', flexDirection: 'row', alignItems: 'flex-end'}}>
        <p style={{marginRight: 10, marginBottom: 0}}>
          Order Detail
          {
            props.categoryName !== null?
            <span className="text-capitalize" style={{display: 'block', fontSize: '.5rem'}}>{props.categoryName}</span>
            : null
          }
        </p>
        <DropdownCategories
          value={props.categoryValue}
          onCategoryChanged={props.onCategoryChanged}
        />
      </div>
    </Th>
    <Th>Sell ID</Th>
    <Th>Rack</Th>
    <Th>
      <div className="d-flex" style={{alignItems: 'center'}}>
        <span className="mr-3">Price</span>
        <ButtonStarred title="Show only your issued sales." value={props.marked} onClick={() => props.onMarkedClick(!props.marked)}/>
      </div>
    </Th>
    <Th>Payment</Th>
    <Th>Status</Th>
    <Th>Updated</Th>
    <Th>Action</Th>
  </tr>
)

export default RowHeader;