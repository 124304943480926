import React from 'react';
import {
  Card,
  CardText
} from 'reactstrap';

const TabListingDetail = props => (
  <Card body className="tab-content--custom__card">
    <CardText>About seller's listing sneaker.</CardText>
  </Card>
)

export default TabListingDetail;