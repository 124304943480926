import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import FontAwesomeIcon from '../../../../components/Icons/FontAwesomeIcon';
import { TextRupiah } from '../../../../components/Text';
import {convertToRupiah} from '../../../../utils';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const IconWrapper = styled.div`
  padding: 20px;
`;

class ProductOption extends React.Component{
  constructor(props){
    super(props);
    this.handleMouseDown = this.handleMouseDown.bind(this);
    this.handleMouseEnter = this.handleMouseEnter.bind(this);
    this.handleMouseMove = this.handleMouseMove.bind(this);
  }
  handleMouseDown (event) {
		event.preventDefault();
		event.stopPropagation();
		this.props.onSelect(this.props.option, event);
  }
  handleMouseEnter (event) {
		this.props.onFocus(this.props.option, event);
  }
  handleMouseMove (event) {
		if (this.props.isFocused) return;
		this.props.onFocus(this.props.option, event);
  }
  render () {
    const subsidy_price = this.props.option.subsidy_price;
		return (
			<div className={this.props.className}
				onMouseDown={this.handleMouseDown}
				onMouseEnter={this.handleMouseEnter}
				onMouseMove={this.handleMouseMove}
				title={this.props.option.email}>
        <Wrapper>
          <IconWrapper>
            <FontAwesomeIcon iconSize="2x" iconType="user"/>
          </IconWrapper>
          <div>            
            { this.props.option.email }<br/>
            { this.props.option.product } ({ this.props.option.size })<br/>
            <strong><TextRupiah prefix="Rp. " value={this.props.option.price}/></strong>
            {subsidy_price ? 
              <><small>{`Rp. ${convertToRupiah(parseFloat(subsidy_price))}`} (Subsidy Price)</small></>
              :
              null
            }
          </div>
        </Wrapper>
			</div>
		);
	}
}

ProductOption.propTypes = {
  option: propTypes.object,
  onFocus: propTypes.func,
  onSelect: propTypes.func,
  className: propTypes.string
}

ProductOption.defaultProps = {};

export default ProductOption;