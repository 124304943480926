import {
  LIST_PRODUCT_ADDON,
  DETAIL_PRODUCT_ADDON,
  DELETE_PRODUCT_ADDON,
  UPDATE_PRODUCT_ADDON,
  CREATE_PRODUCT_ADDON,
  PRODUCT_ADDON_ERROR,
  PRODUCT_ADDON_REQUEST,
  SUBMITING_PRODUCT_ADDON_ITEMS,
  SEARCH_PRODUCT_ADDON_ITEMS,
  GET_LIST_PRODUCT_ADDON_ITEMS,
  PRODUCT_ADDON_CHILDS_REQUEST,
  PRODUCT_ADDON_CHILDS_ERROR
} from '../constants';

const initialState = {
  list: {
    isFetch: false,
    data: [],
    error: null
  },
  details: {
    isFetch: false,
    isSubmit: false,
    data: {
      id: null,
      deleted: false,
      images: []
    },
    error: null
  },
  productAddonItems: {
		dataSearch: [],
		data: [],
		isSearchFetch: false,
		isFetch: false,
		isSubmit: false,
		error: null
	}
}

const onFetched = (lastState, { requestType, value }) => {
  switch(requestType){
    case LIST_PRODUCT_ADDON: return {...lastState, list: { ...lastState.list, isFetch: value }}
    case DETAIL_PRODUCT_ADDON: return {...lastState, details: { ...lastState.details, isFetch: value }}
    case CREATE_PRODUCT_ADDON:
    case UPDATE_PRODUCT_ADDON:
    case DELETE_PRODUCT_ADDON: return {...lastState, details: { ...lastState.details, isSubmit: value }}
    default: return lastState;
  }
}

const onError = (lastState, { requestType, error, ...rest }) => {
  switch(requestType){
    case LIST_PRODUCT_ADDON: return {...lastState, list: { ...lastState.list, error: { ...error, ...rest } }}
    case DETAIL_PRODUCT_ADDON:
    case CREATE_PRODUCT_ADDON:
    case UPDATE_PRODUCT_ADDON:
    case DELETE_PRODUCT_ADDON: return {...lastState, details: { ...lastState.details, error: { ...error, ...rest } }}
    default: return lastState;
  }
}

const onProductAddonItemSucceeded = (lastState, { type, payload }) => {
	const { data, message, status_code } = payload
	switch(type){
		case SUBMITING_PRODUCT_ADDON_ITEMS: return {
			...lastState,
			productAddonItems: {
				...lastState.productAddonItems,
				error: null,
				message,
				status_code
			}
		};
		case SEARCH_PRODUCT_ADDON_ITEMS: return {
			...lastState,
			productAddonItems: {
				...lastState.productAddonItems,
				error: null,
				dataSearch: data,
				message,
				status_code
			}
		}
		case GET_LIST_PRODUCT_ADDON_ITEMS: return {
			...lastState,
			productAddonItems: {
				...lastState.productAddonItems,
				error: null,
				data,
				message,
				status_code
			}
		};
		default: return lastState;
	}
}

const onErrorChildRequest = (lastState, { requestType, error, ...rest }) => {
	switch(requestType){
		case GET_LIST_PRODUCT_ADDON_ITEMS:
		case SUBMITING_PRODUCT_ADDON_ITEMS:
		case SEARCH_PRODUCT_ADDON_ITEMS:
		return {
			...lastState,
			productAddonItems: {
				...lastState.productAddonItems,
				error: { ...error, ...rest }
			}
		}
		default: return lastState;
	}
}


const onValidateChildRequest = (lastState, { requestType, value }) => {
	switch(requestType){
		case GET_LIST_PRODUCT_ADDON_ITEMS: return {
			...lastState,
			productAddonItems: {
				...lastState.productAddonItems,
				isFetch: value
			}
		}
		case SEARCH_PRODUCT_ADDON_ITEMS: return {
			...lastState,
			productAddonItems: {
				...lastState.productAddonItems,
				isSearchFetch: value
			}
		}
		case SUBMITING_PRODUCT_ADDON_ITEMS: return {
			...lastState,
			productAddonItems: {
				...lastState.productAddonItems,
				isSubmit: value
			}
		}
		default: return lastState;
	}
}


const reducer = (state = initialState, action) => {
  switch(action.type) {
    case PRODUCT_ADDON_REQUEST: return onFetched(state, action.payload)
    case PRODUCT_ADDON_ERROR: return onError(state, action.payload)
    case LIST_PRODUCT_ADDON: {
      return {
        ...state,
        list: {
          ...state.list,
          ...action.payload,
          error: null
        }
      }
    }
    case CREATE_PRODUCT_ADDON:
    case UPDATE_PRODUCT_ADDON:
    case DELETE_PRODUCT_ADDON:
    case DETAIL_PRODUCT_ADDON: {
      return {
        ...state,
        details: {
          ...state.details,
          ...action.payload,
          deleted: action.type === DELETE_PRODUCT_ADDON,
          error: null
        }
      }
    }
    case PRODUCT_ADDON_CHILDS_REQUEST: return onValidateChildRequest(state,action.payload);
		case GET_LIST_PRODUCT_ADDON_ITEMS:
		case SUBMITING_PRODUCT_ADDON_ITEMS:
    case SEARCH_PRODUCT_ADDON_ITEMS:return onProductAddonItemSucceeded(state,action);
    case PRODUCT_ADDON_CHILDS_ERROR: return onErrorChildRequest(state, action.payload);
    default: return state;
  }
}

export default reducer;