import {
	PRODUCT_ROUTE_API,
	GET_LIST_PRODUCT,
	FETCHING_PRODUCT,
	GET_DETAIL_PRODUCT,
	UPDATE_PRODUCT,
    PRODUCT_ERROR,
    ADD_PRODUCT,
    NEW_PRODUCT,
	DELETE_PRODUCT,
	GET_OPTION_LIST_PRODUCT,
	AUTH_TOKEN_KEY
} from '../constants';

import { logout } from './authAction';

import api from '../utils/Api';
import { manipulateErrors, checkAuthorized } from '../utils';

/**
 * Get list of offers
 * @author pewe
 * @guards jwt-token 
 * @param {object?} query 
 */
export const getProductList=(query)=>{
	return dispatch =>(
		dispatch(isFetch({type:'fetch',status:true})),
		api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).get(PRODUCT_ROUTE_API,query)
		.then(res=>{
			dispatch(onSuccess(res.data,GET_LIST_PRODUCT))
			dispatch(isFetch({type:'fetch',status:false}))
		})
		.catch(err => {
			dispatch(onError(err));
			dispatch(isFetch({type:'fetch',status:false}));
		})
	)
}

export const getOptionList=()=>{
	return dispatch =>(
		dispatch(isFetch({type:'fetch',status:true})),
		api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).get(PRODUCT_ROUTE_API,{params:{no_limit:true}})
		.then(res=>{
			dispatch(onSuccess(res.data,GET_OPTION_LIST_PRODUCT))
			dispatch(isFetch({type:'fetch',status:false}))
		})
		.catch(err => {
			dispatch(onError(err));
			dispatch(isFetch({type:'fetch',status:false}));
		})
	)
}

/**
 * Get detail of Offer
 * @guards jwt-token
 * @param {integer} id 
 */
export const getDetailProduct=(id)=>{
	return dispatch =>(
		dispatch(isFetch({type:'fetch',status:true})),
		api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).get(`${PRODUCT_ROUTE_API}/${id}`)
		.then(res=>{
			dispatch(onSuccess(res.data,GET_DETAIL_PRODUCT))
			dispatch(isFetch({type:'fetch',status:false}))
		})
		.catch(err => {
			dispatch(onError(err));
			dispatch(isFetch({type:'fetch',status:false}));
		})
	)
	
}
export const saveProduct = (payload,id) =>{
	return dispatch =>{
		if(id===undefined)
			dispatch(createProduct(payload));
		else 
			dispatch(updateProduct(id,payload));

	}
}
export const createProduct=(payload)=>{
	return dispatch => (
		dispatch(isFetch({type:'submit',status:true})),
        api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).post(`${PRODUCT_ROUTE_API}`,payload)
		.then(res=>{
			dispatch(onSuccess(res.data,ADD_PRODUCT))
			dispatch(isFetch({type:'submit',status:false}))
		})
		.catch(err => {
			dispatch(onError(err));
			dispatch(isFetch({type:'submit',status:false}));
		})
	)
}

/**
 * Update an offer
 * @author pewe
 * @guards jwt-token
 * @param {integer} id 
 * @param {object} payload 
 */
export const updateProduct = (id,payload) =>{
	return dispatch => (
		dispatch(isFetch({type:'submit',status:true})),
		api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).put(`${PRODUCT_ROUTE_API}/${id}`,payload)
		.then(res=>{
			dispatch(onSuccess(res.data,UPDATE_PRODUCT))
			dispatch(isFetch({type:'submit',status:false}))
		})
		.catch(err => {
			dispatch(onError(err));
			dispatch(isFetch({type:'submit',status:false}));
		})
	)
}

export const deleteProduct = (id) =>{
	return dispatch => (
		dispatch(isFetch({type:'submit',status:true})),
		api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).delete(`${PRODUCT_ROUTE_API}/${id}`)
		.then(res=>{
			dispatch(onSuccess(res.data,DELETE_PRODUCT))
			dispatch(isFetch({type:'submit',status:false}))
		})
		.catch(err => {
			dispatch(onError(err));
			dispatch(isFetch({type:'submit',status:false}));
		})
	)
}

/**
 * Indicates when start fetch api or end fetch api
 * @author pewe
 * @param {boolean} bool 
 */
export const isFetch=(payload)=>(
	{
		type:FETCHING_PRODUCT,
		payload:payload		
	}
)

/**
 * When onSuccess triggered
 * @author pewe
 * @param {object} res 
 * @param {string} type 
 */
export const onSuccess=(res,type)=>
(
	{
		type:type,
		payload:res		
	}
)
/**
 * When error fetch or connect to api
 * @author pewe
 * @param {object} err 
 */
// On Error fetching api
export const onError=(err)=>{
	if(!checkAuthorized(manipulateErrors(err)))
		return logout();
	return {
		type:PRODUCT_ERROR,
		payload:manipulateErrors(err)
	}
}

export const newProduct=()=>{
    return {
        type:NEW_PRODUCT
    }
}