import {
  MENU_ROUTE_API,
  FETCHING_MENU,
  UPDATE_MENU,
  NEW_MENU,
  ADD_MENU,
  MENU_ERROR,
  DELETE_MENU,  
  GET_LIST_MENU,
  GET_LIST_ROOT_MENU,
  GET_OPTION_LIST_MENU,
  GET_DETAIL_MENU,
  MOVE_POSITION_MENU,
	AUTH_TOKEN_KEY,
	FIX_MENU,
	FIX_MENU_SUBMIT,
	FIX_MENU_ERROR,
} from '../constants';
import api from '../utils/Api';
import { manipulateErrors, checkAuthorized } from '../utils';
import { logout } from './authAction';

export const getMenuList=(query)=>{
	return dispatch =>(
		dispatch(isFetch({type:'fetch',status:true})),
		api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).get(MENU_ROUTE_API,query)
		.then(res=>{
			dispatch(onSuccess(res.data,GET_LIST_MENU))
			dispatch(isFetch({type:'fetch',status:false}))
		})
		.catch(err => {
			dispatch(onError(err));
			dispatch(isFetch({type:'fetch',status:false}));
		})
	)
}

export const getRootMenuList=()=>{
	return dispatch =>(
		dispatch(isFetch({type:'fetch',status:true})),
		api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).get(`${MENU_ROUTE_API}/roots`)
		.then(res=>{
			dispatch(onSuccess(res.data,GET_LIST_ROOT_MENU))
			dispatch(isFetch({type:'fetch',status:false}))
		})
		.catch(err => {
			dispatch(onError(err));
			dispatch(isFetch({type:'fetch',status:false}));
		})
	)
}

export const getOptionList=()=>{
	return dispatch =>(
		dispatch(isFetch({type:'fetch',status:true})),
		api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).get(MENU_ROUTE_API,{params:{no_limit:true}})
		.then(res=>{
			dispatch(onSuccess(res.data,GET_OPTION_LIST_MENU))
			dispatch(isFetch({type:'fetch',status:false}))
		})
		.catch(err => {
			dispatch(onError(err));
			dispatch(isFetch({type:'fetch',status:false}));
		})
	)
}

export const getDetailMenu=(id)=>{
	return dispatch =>(
		dispatch(isFetch({type:'fetch',status:true})),
		api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).get(`${MENU_ROUTE_API}/${id}`)
		.then(res=>{
			dispatch(onSuccess(res.data,GET_DETAIL_MENU))
			dispatch(isFetch({type:'fetch',status:false}))
		})
		.catch(err => {
			dispatch(onError(err));
			dispatch(isFetch({type:'fetch',status:false}));
		})
	)
}
export const saveMenu = (payload) =>{
	const {id,title,parent,category_id,is_active,url}=payload;
	return dispatch =>{
		if(payload.id===undefined)
			return dispatch(createMenu({title,parent,category_id,is_active,url}));
		else 
			return dispatch(updateMenu(id,{title,parent,category_id,is_active,url}));
	}
}
export const createMenu=(payload)=>{
	return dispatch => (
		dispatch(isFetch({type:'submit',status:true})),
        api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).post(`${MENU_ROUTE_API}`,payload)
		.then(res=>{
			dispatch(onSuccess(res.data,ADD_MENU))
			dispatch(isFetch({type:'submit',status:false}))
		})
		.catch(err => {
			dispatch(onError(err));
			dispatch(isFetch({type:'submit',status:false}));
		})
	)
}

export const updateMenu = (id,payload) =>{
	return dispatch => (
		dispatch(isFetch({type:'submit',status:true})),
		api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).put(`${MENU_ROUTE_API}/${id}`,payload)
		.then(res=>{
			dispatch(onSuccess(res.data,UPDATE_MENU))
			dispatch(isFetch({type:'submit',status:false}))
		})
		.catch(err => {
			dispatch(onError(err));
			dispatch(isFetch({type:'submit',status:false}));
		})
	)
}
export const moveMenu = (id,sibling_id,after=true) =>{
	let payload={after,sibling_id};
	return dispatch => (
		dispatch(isFetch({type:'submit',status:true})),
		api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).put(`${MENU_ROUTE_API}/${id}`,payload)
		.then(res=>{
			dispatch(onSuccess(res.data,MOVE_POSITION_MENU))
			dispatch(isFetch({type:'submit',status:false}))
		})
		.catch(err => {
			dispatch(onError(err));
			dispatch(isFetch({type:'submit',status:false}));
		})
	)
}

export const deleteMenu = (id) =>{
	return dispatch => (
		dispatch(isFetch({type:'submit',status:true})),
		api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).delete(`${MENU_ROUTE_API}/${id}`)
		.then(res=>{
			dispatch(onSuccess(res.data,DELETE_MENU))
			dispatch(isFetch({type:'submit',status:false}))
		})
		.catch(err => {
			dispatch(onError(err));
			dispatch(isFetch({type:'submit',status:false}));
		})
	)
}

export const isFetch=(payload)=>(
	{
		type:FETCHING_MENU,
		payload:payload		
	}
)

export const onSuccess=(res,type)=>
(
	{
		type:type,
		payload:res		
	}
)

export const onError=(err)=>{
	if(!checkAuthorized(manipulateErrors(err)))
		return logout();
	return {
		type:MENU_ERROR,
		payload:manipulateErrors(err)
	}
}

export const newMenu=()=>{
  return {
    type:NEW_MENU
  }
}

export const fixMenu = () => (
	dispatch => {
		dispatch({
			type: FIX_MENU_SUBMIT,
			status: true
		});
		api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		})
    .post(`${MENU_ROUTE_API}/fix-tree`)
    .then(res => {
			dispatch({
				type: FIX_MENU,
				payload: res.data
			});
			dispatch({
				type: FIX_MENU_SUBMIT,
				status: false
			});
    })
    .catch(err => {
      dispatch({
				type: FIX_MENU_ERROR,
				payload: manipulateErrors(err)
			});
			dispatch({
				type: FIX_MENU_SUBMIT,
				status: false
			});
    });
	}
)
