import React, { useState, useEffect, useCallback } from 'react'
import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  ModalFooter,
  ListGroup,
  ListGroupItem,
  Col,
  Row,
  Card,
  Container,
  Collapse
} from 'reactstrap';
import { Radio, RadioText, FormGroupRadio } from '../../components/Form/Radios'
import { SimpleLineIcon } from '../../components/Icons'
import {
  SortableContainer,
  SortableElement,
  SortableHandle
} from 'react-sortable-hoc';
import styled from 'styled-components';
const Wrapper = styled.div`
  position: absolute;
  display: ${props => props.isOpen ? "block" : "none"};
  bottom: 0px;
  z-index: 999;
  margin: 0px;
  height: 100%;
  left: 0px;
  right: 0px;
  background: rgba(0,0,0,.5);
`;

const DragHandle = SortableHandle(() => <i className="fa fa-reorder" style={{cursor:'move'}}></i>);
const SortableItem = SortableElement(({item}) =>
	<ListGroupItem  style={{WebkitUserSelect: 'none', zIndex: 99999}}>
		<DragHandle /> {item.title? item.title: item.name}
	</ListGroupItem>
);

const SortableList = SortableContainer(({items}) => {
	return (
		<ListGroup>
			{items.map((item, index) => (
        <SortableItem key={item.id} index={index} item={item}/>
			))}
		</ListGroup>
	);
});

function ModalEditPopular(props){
  const [items, setItems] = useState([]);
  const [selected, setSelected] = useState([]);
  const [childDrawer, toggleChildDrawer] = useState(false);
  const [parentBrand, setParentBrand] = useState(null);
  const [formStep, setFormStep] = useState(0);
  useEffect(()=>{
    if(props.isOpen){
      const { items: dataItems } = props;
      setItems(dataItems.map(item => ({
        id: item.id,
        iconUrl: item.img_url,
        name: item.name,
        title: item.title,
        isPopular: false,
        children: item.children.map(ch => ({
          id: ch.id,
          iconUrl: ch.img_url,
          name: ch.name,
          title: ch.title,
          isPopular: false,
        }))
      })))
      setSelected([])
      setFormStep(0)
      toggleChildDrawer(false)
    }
  }, [props.isOpen])
  const onSubmitClick = useCallback(() => {
    if(formStep === 0){
      const arr = items.reduce((ar, item) => {
        let brands = [];
        if(item.isPopular){
          brands.push({
            id: item.id,
            title: item.title,
            name: item.name
          })
        }
        const childrenSelected = item.children.filter(ch => ch.isPopular)
        .map(ch => ({
          id: ch.id,
          parent: {
            id: item.id,
            title: item.title,
            name: item.name
          },
          title: ch.title,
          name: ch.name
        }));
        brands = [...brands, ...childrenSelected]
        return [...ar, ...brands]
      }, [])
      setSelected(arr)
      setFormStep(1)
    }
    else if(formStep === 1){
      props.onSubmit(selected.map(item => item.id))
    }
  }, [items, formStep, selected])

  const getPopularTotal = useCallback(() => {
    return items.reduce((accumulator, item) => {
      let total = item.isPopular? 1: 0;
      const chds = item.children.filter(ch => ch.isPopular);
      if(chds.length) total += chds.length;
      return accumulator + total;
    }, 0);
  }, [items])
  return (
    <Modal size="md" style={{}} className="modal-confirmation" isOpen={props.isOpen} toggle={props.toggle}>
      <ModalHeader toggle={props.toggle} className="modal-header--center">
        Edit Popular. ({getPopularTotal()}/10)
      </ModalHeader>
      <ModalBody style={{maxHeight: 500, overflow: 'auto', position: 'relative', overflowY: childDrawer?'hidden': 'auto'}}>
        <Collapse isOpen={formStep === 0} style={{position: 'relative'}}>
          <Container>
            <p>Pilih brands:</p>
            <Row>
              {items.map(item => {
                return(
                  <Col style={{padding: 0}} key={item.id} xs={6} sm={4}>
                    <Card className="mb-0" style={{maxHeight: 100, minHeight: 100}} body>
                      <FormGroupRadio className="mb-0">
                        <Radio checked={item.isPopular} onChange={e => {
                          const popularTotal = getPopularTotal();
                          const value = e.target.checked;
                          if(value && popularTotal +1 <= 10){
                            setItems(items.map(i => ({
                              ...i,
                              isPopular: i.id === item.id? true: i.isPopular
                            })))
                          } else if(!value){
                            setItems(items.map(i => ({
                              ...i,
                              isPopular: i.id === item.id? false: i.isPopular
                            })))
                          }
                        }} radioType="3d" size="xs" usingLabelText={false}/>
                        <RadioText style={{fontSize: '.65rem'}} text={item.title? item.title: item.name}/>
                      </FormGroupRadio>
                      {item.children.length?(
                        <div style={{borderTop: '1px solid #ddd', padding: '6px 0px'}}>
                          <a
                            style={{fontSize: '.75rem', color: '#4a4a4a'}}
                            href="#"
                            onClick={e => {
                              e.preventDefault();
                              toggleChildDrawer(true);
                              setParentBrand(item)
                            }}
                          >
                            See brand's child <SimpleLineIcon iconType="arrow-right"/>
                          </a>
                        </div>
                      ): null}
                    </Card>
                  </Col>
                )
              })}
            </Row>
          </Container>
        </Collapse>
        <Collapse isOpen={formStep===1}>
          <Container>
            <p>Reorder popular brands:</p>
            <SortableList
              items={selected}
              lockAxis="y"
              useDragHandle={true}
              onSortEnd={({oldIndex, newIndex}) => {
                if(oldIndex!==newIndex){
                  let newArr = selected.filter(item => item.id !== selected[oldIndex].id);
                  newArr.splice(newIndex, 0, selected[oldIndex])
                  setSelected(newArr)
                }
              }}
            />
          </Container>
        </Collapse>
        <Wrapper isOpen={childDrawer}>
          <div onClick={() => toggleChildDrawer(false)} style={{background: 'transparent', position: 'absolute', left: 0, bottom: 0, right: 0, cursor: "pointer", height: '100%' }}></div>
          <Card style={{position: 'fixed', bottom: '0px', marginBottom: 0, width: '100%', height: '60%'}}>
            <div style={{padding: '6px 12px', borderBottom: '1px solid #ddd', display: 'flex', alignItems: 'center', position: 'absolute', top: 0, width: '100%', zIndex: 90}}>
              <button onClick={() => toggleChildDrawer(false)} style={{background: 'none', padding: '6px 12px', margin: 0, border: 'none'}}>
                <SimpleLineIcon iconType="arrow-left"/>
              </button>
              {parentBrand?(
                <h5 className="mb-0">{parentBrand.title? parentBrand.title: parentBrand.name}</h5>
              ): null}
            </div>
            {parentBrand && parentBrand.children.length?
              <ListGroup style={{marginTop: 45, overflow: 'auto'}}>
                {parentBrand.children.map((item) =>(
                  <ListGroupItem key={item.id}>
                    <FormGroupRadio className="mb-0">
                      <Radio checked={item.isPopular} onChange={e => {
                        const popularTotal = getPopularTotal();
                        const value = e.target.checked;
                        const children = parentBrand.children.map(ch => ({
                          ...ch,
                          isPopular: ch.id === item.id? value && popularTotal +1 <= 10: ch.isPopular
                        }))
                        setParentBrand({
                          ...parentBrand,
                          children: [...children]
                        })
                        setItems(items.map(i => ({
                          ...i,
                          children: i.id === parentBrand.id? [...children]:i.children
                        })))
                      }} radioType="3d" size="xs" usingLabelText={false}/>
                      <RadioText style={{fontSize: '.65rem'}} text={item.title? item.title: item.name}/>
                    </FormGroupRadio>
                  </ListGroupItem>
                ))}
              </ListGroup>
            :null}
          </Card>
        </Wrapper>
      </ModalBody>

      <ModalFooter>
        {formStep > 0?
          <Button type="button" onClick={()=>setFormStep(0)}>
            Back
          </Button>
        :null}
        {
          getPopularTotal() > 0?(
          <Button color="primary" onClick={onSubmitClick}>
            {formStep === 0? "Next": "Submit"}
          </Button>
          ): null
        }
      </ModalFooter>
    </Modal>
  )
}

export default ModalEditPopular;