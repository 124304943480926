import {
    SPIN_WHEEL_LIST_FETCH,
    SPIN_WHEEL_FETCHING,
    SPIN_WHEEL_DETAIL_FETCH,
    SPIN_WHEEL_ADD,
    SPIN_WHEEL_UPDATE,
    SPIN_WHEEL_DELETE,
    SPIN_WHEEL_PRIZE_FETCHING,
    SPIN_WHEEL_PRIZE_ADD,
    SPIN_WHEEL_PRIZE_UPDATE,
    SPIN_WHEEL_PRIZE_DETAIL_FETCH,
    SPIN_WHEEL_ERROR,
    SPIN_WHEEL_PRIZE_ERROR,
    SPIN_WHEEL_PRIZE_DELETE
} from '../constants';

const initialState={
	list:[],
	detail: {
    spinning_wheel_prizes: []
  },
  prize: {
    detail: {
      voucher: {}
    },
    isFetch: false,
    isSubmit: false,
    success: null,
    error: null,
    delete: false
  },
  isFetch:false,
  isSubmit:false,
  success:null,
  error:null,
  delete: false
}

const onFetching = (lastState,payload) => {
  if(payload.type==='fetch')
    return { ...lastState, isFetch:payload.status, success: null, error: null }
  else if(payload.type==='submit')
    return { ...lastState, isSubmit:payload.status }

  return { ...lastState, isFetch:false,isSubmit:false }
}

const onFetchingPrize = (lastState,payload) => {
  if(payload.type ==='fetch')
    return { ...lastState, prize: {...lastState.prize, isFetch: payload.status} }
  else if(payload.type ==='submit')
    return { ...lastState, prize: {...lastState.prize, isSubmit: payload.status} }

  return { ...lastState, prize: {...lastState.prize, isFetch: false, isSubmit: false} }
}

const reducer=(state=initialState, action)=>{
  switch(action.type){
    case SPIN_WHEEL_LIST_FETCH: {
      const {data, status_code, message}=action.payload;
      return {...state, list:{...data, message, status_code}, detail: {spinning_wheel_prizes: []}, error:null}
    }
    case SPIN_WHEEL_DETAIL_FETCH:{
      const {data, status_code, message}=action.payload;
      return { ...state, detail:{...state.detail, ...data, message, status_code,deleted:false}, error:null }
    }
    case SPIN_WHEEL_ADD : {
      const {data, status_code, message}=action.payload;
      return {...state, detail:{...state.detail,...data, message, status_code}, success: message, error:null}
    }
    case SPIN_WHEEL_ERROR:
            return { ...state, ...action.payload, success: null }
    case SPIN_WHEEL_UPDATE : {
        const {data, status_code, message}=action.payload;
        return {...state, detail:{...state.detail,...data, message, status_code}, success: message, error:null}
    }
    case SPIN_WHEEL_DELETE : {
      const {data, status_code, message}=action.payload;
      return {...state, detail:{...state.detail,...data, message, status_code}, success: message, delete: true, error:null}
  }
    case SPIN_WHEEL_PRIZE_ADD : {
      const {data, status_code, message}=action.payload;
      return {...state, prize:{...state.prize,...data, message, success:message, status_code, error:null}}
    }
    case SPIN_WHEEL_PRIZE_UPDATE : {
      const {data, status_code, message}=action.payload;
      return {...state, prize:{...state.prize,...data, message, success:message, status_code, error:null}}
    }
    case SPIN_WHEEL_PRIZE_DELETE : {
      const {data, status_code, message}=action.payload;
      return {...state, prize:{...state.prize,...data, message, success:message, status_code, delete: true, error:null}}
    }
    case SPIN_WHEEL_PRIZE_ERROR:
            return { ...state, prize: {...state.prize, ...action.payload, success: null} }
    case SPIN_WHEEL_PRIZE_DETAIL_FETCH:{
      const {data, status_code, message}=action.payload;
      return { ...state, prize:{...state.prize, detail: data, message, status_code, error:null} }
    }
    case SPIN_WHEEL_FETCHING:  return onFetching(state, action.payload);
    case SPIN_WHEEL_PRIZE_FETCHING:  return onFetchingPrize(state, action.payload);
    default:return state;
  }

}

export default reducer;