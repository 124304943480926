import React, { Component } from "react";
import { connect } from "react-redux";
import uuidv4 from "uuid/v4";
import {
  Alert,
  Form,
  Label,
  Row,
  Col,
  Input,
  FormGroup,
  ButtonGroup,
  Button,
  Card,
  CardBody,
  CardHeader,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import classNames from "classnames";
import { hasError, textValue } from "../../utils/form";
import UploadImages from "./SurveyImages/UploadImages";

import {
  createSurvey,
  deleteSurvey,
  getDetailSurvey,
  updateSurvey,
  addImageSurvey,
  deleteImageSurvey,
} from "../../actions/surveyAction";
import {
  clearImageUpload,
  uploadImage,
  deleteImage as deleteImageS3,
} from "../../actions/imageUploadAction";
import {
  ImagesCompressor,
  createUploadFileForm,
} from "../../utils/imageHelper";
import { ValidationMessage } from "../../components/Form/ValidationMessage";
import { getPathS3, getOriginFromSignedUrl } from "../../utils/AWS";
import Analytics from "../../services/Analytics";
import SelectSurveyParent from "../../components/Form/Select/Async/SelectSurveyParent";
import SurveyImage from "./SurveyImages";
import { AWS_BUCKET, AWS_BUCKET_REGION } from "../../config/storageBucket";

const AWS_ENDPOINT = "amazonaws.com";

const getStorageName = (imgUrl) => {
  if (new RegExp(AWS_BUCKET).test(imgUrl)) return AWS_BUCKET;
  else if (/kickavenue-assets/.test(imgUrl)) return "kickavenue-assets";
  return "external-link";
};

const getUrlKey = (imgUrl) => {
  const regexStorage = new RegExp(AWS_BUCKET);
  if (regexStorage.test(imgUrl)) {
    const firstPattern = new RegExp(
      `s3.${AWS_BUCKET_REGION}.${AWS_ENDPOINT}/${AWS_BUCKET}/`
    );
    const secondPattern = new RegExp(
      `${AWS_BUCKET}.s3.${AWS_BUCKET_REGION}.${AWS_ENDPOINT}/`
    );
    const thirdPattern = new RegExp(`${AWS_BUCKET}.s3.${AWS_ENDPOINT}/`);
    let parsedUrl = imgUrl.trim().replace(/^(https|http):\/\//, "");
    if (firstPattern.test(parsedUrl)) {
      return parsedUrl.replace(firstPattern, "");
    } else if (secondPattern.test(parsedUrl)) {
      return parsedUrl.replace(secondPattern, "");
    } else if (thirdPattern.test(parsedUrl)) {
      return parsedUrl.replace(thirdPattern, "");
    }
  }
  return "";
};

const ModalProgress = (props) => (
  <Modal size="sm" className="modal-confirmation" isOpen={props.isOpen}>
    <ModalHeader className="modal-header--center">
      {props.isDeleting ? "Deleting Progress" : "Submitting Progress."}
    </ModalHeader>
    <ModalBody>
      {props.renderModalBody ? props.renderModalBody() : props.modalBody}
    </ModalBody>
    <ModalFooter>
      <Button
        className={classNames({ "d-none": props.isPrimaryButtonHide })}
        color="primary"
        onClick={props.onPrimaryClick}
      >
        Gotchas
      </Button>
    </ModalFooter>
  </Modal>
);

class SurveyForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      _edit: this.props.edit,
      _readOnly: this.props.readOnly,
      _hasId: this.props.match.params.id ? true : false,
      _images: [],
      _form: {
        binding: false,
        id: this.props.match.params.id,
        question: "",
        multiple_option: "",
        parent: null,
        parent_id: null,
        details: "",
        order: 0,
        active: false,
        created_at: "",
        updated_at: "",
      },
      _imageUploadList: [],
      _formSubmit: false,
      _isDeleting: false,
      _modalDelete: false,
      _modalDeleteProgressOpen: false,
      _surveyDeleteProgress: false,
      _isModalProgress: false,
      _taskAvailable: 0,
      _taskDone: 0,
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.handleCheckedChange = this.handleCheckedChange.bind(this);
    this.handleChangeEditor = this.handleChangeEditor.bind(this);
    this._handleDropzoneOnDrop = this._handleDropzoneOnDrop.bind(this);
    this._handleRemoveImage = this._handleRemoveImage.bind(this);
    this._handleUploadImages = this._handleUploadImages.bind(this);
  }

  componentDidMount() {
    let pageSlug = "survey_create";
    if (this.state._hasId) {
      this.props.find(this.state._form.id);
      pageSlug = "survey_update";
    }
    const { email } = this.props.injectedProps;
    Analytics.recordPageView(pageSlug, email, {
      url: window.location.href,
      location: this.props.location,
      match: this.props.match,
    });
  }

  componentWillUnmount() {
    this.props.clearImageUpload();
  }

  componentDidUpdate = async (lastProps) => {
    const { detail, isFetch, isSubmit, error } = this.props.survey;
    // Edit Form
    if (this.state._edit && this.state._hasId) {
      if (
        lastProps.survey.isSubmittingImage &&
        !this.props.survey.isSubmittingImage
      ) {
        const _images = [...this.state._images];
        const { addedImages } = this.props.survey;
        Object.keys(addedImages).map((index) => {
          const filtered = _images.filter((item) => item.identifier === index);
          if (filtered.length) {
            filtered[0].id = addedImages[index].id;
            filtered[0].url = addedImages[index].img_url;
            filtered[0].updated_at = addedImages[index].updated_at;
          }
        });
        this.setState({ _images });
      }

      if (detail.status_code === 200 && error === null) {
        if (!isFetch && !this.state._form.binding) {
          let _images = [];
          if (detail.img_url) {
            let imgdata = {
              id: detail.id,
              URL: detail.img_url,
              created_at: detail.created_at,
              updated_at: detail.updated_at,
            };
            _images = this.bindSurveyImageData([imgdata]);
          }
          let _newForm = {
            ...this.state._form,
            ...detail,
            binding: true,
          };
          if (detail.parent) {
            _newForm = {
              ..._newForm,
              parent: {
                id: detail.parent.id,
                name: detail.parent.question,
              },
            };
          }
          this.setState({
            _images,
            _form: _newForm,
          });
        }
      }

      // After get detail Survey
      if (isSubmit !== lastProps.survey.isSubmit) {
        if (this.state._surveyDeleteProgress) {
          if (error) {
            this.setState({
              _modalDeleteProgressOpen: false,
              _surveyDeleteProgress: false,
            });
          } else if (detail.delete) {
            const { _taskAvailable, _taskDone } = this.state;
            this.setState(
              {
                _delete: detail.delete,
                _surveyDeleteProgress: _taskAvailable > _taskDone + 1,
                _taskDone: _taskDone + 1,
              },
              () => {
                const { _assetsToDelete, _surveyDeleteProgress } = this.state;
                if (_surveyDeleteProgress && _assetsToDelete.length) {
                  const { identifier, url } = _assetsToDelete[0];
                  const payload = {
                    identifier,
                    bucket_type: "assets",
                    name: getPathS3(getOriginFromSignedUrl(url)),
                  };
                  this.props.deleteImageS3(payload);
                }
              }
            );
          }
        }
      }
      if (
        this.props.imageUpload.isDeleteProgress !==
        lastProps.imageUpload.isDeleteProgress
      ) {
        const { deleted } = this.props.imageUpload;
        //&& this.state._delete === true
        if (this.state._surveyDeleteProgress && deleted) {
          const _assetsToDelete = this.state._assetsToDelete.filter(
            ({ identifier }) => identifier !== deleted.identifier
          );
          let _taskDone = this.state._taskDone + 1;
          this.setState(
            {
              _surveyDeleteProgress: this.state._taskAvailable > _taskDone,
              _taskDone: _taskDone,
              _assetsToDelete,
            },
            () => {
              const { _assetsToDelete, _surveyDeleteProgress } = this.state;
              if (_surveyDeleteProgress && _assetsToDelete.length) {
                const { identifier, url } = _assetsToDelete[0];
                const payload = {
                  identifier,
                  bucket_type: "assets",
                  name: getPathS3(getOriginFromSignedUrl(url)),
                };
                this.props.deleteImageS3(payload);
              }
            }
          );
        }
      }
      if (
        !this.props.imageUpload.isUpload &&
        this.props.imageUpload.isUpload !== lastProps.imageUpload.isUpload
      ) {
        const { success, error } = this.props.imageUpload;
        if (!error) {
          const { _images, _imageUploadList } = this.state;
          let myIdentifier = null;
          const filtered = _images.filter(
            (img) =>
              img.identifier === success.data.identifier &&
              !img.status &&
              success.data.url !== null
          );
          if (filtered.length) {
            let { _imageUploadProgress } = this.state;
            myIdentifier = filtered[0].identifier;
            const imageUploadList = _imageUploadList.filter(
              (id) => id !== myIdentifier
            );
            let nextUploadId = null;
            if (imageUploadList.length <= 0) {
              _imageUploadProgress = false;
            } else {
              nextUploadId = imageUploadList[0];
            }
            this.setState(
              {
                _images: _images.map((item) => {
                  if (item.identifier == myIdentifier) {
                    return {
                      ...item,
                      status: true,
                      is_uploading: false,
                      storageName: getStorageName(success.data.url),
                      urlKey: getUrlKey(success.data.url),
                      url: success.data.url,
                    };
                  } else if (item.identifier == nextUploadId) {
                    return {
                      ...item,
                      is_uploading: true,
                    };
                  }
                  return item;
                }),
                _imageUploadList: imageUploadList,
                _imageUploadProgress,
              },
              async () => {
                this.props.saveImage(myIdentifier, this.state._form.id, {
                  ...detail,
                  img_url: success.data.url
                });
                const nextItemUpload = _images.filter(
                  (item) => item.identifier === nextUploadId
                );
                if (nextItemUpload.length && nextUploadId !== null) {
                  let { identifier, fileInput } = nextItemUpload[0];

                  try {
                    //compress file
                    fileInput = await ImagesCompressor(fileInput);
                  } catch (e) {}
                  const tempPayload = createUploadFileForm(fileInput, false, {
                    name: "survey/" + this.state._form.id,
                    bucket_type: "assets",
                  });
                  this.props.uploadImage(tempPayload, nextItemUpload[0]);
                }
              }
            );
          }
        }
      }
      // After deleting image's url from kick api
      if (
        !this.props.survey.isDeletingImage &&
        lastProps.survey.isDeletingImage
      ) {
        const { deletedImages } = this.props.survey;
        if (deletedImages.length) {
          const _images = [...this.state._images];
          const lastUniqueID = deletedImages[deletedImages.length - 1];
          const lastImage = _images.filter(
            (item) => item.identifier === lastUniqueID
          );
          if (lastImage.length) {
            const { identifier, urlKey, url } = lastImage[0];
            if (urlKey !== "") {
              const payload = {
                identifier,
                bucket_type: "assets",
                name: getPathS3(getOriginFromSignedUrl(url)),
              };
              this.props.deleteImageS3(payload);
            }
            this.setState({
              _images: _images.filter(
                (item) => item.identifier !== lastUniqueID
              ),
            });
          }
        }
      }
    }
    // Create Form
    else {
      if (
        lastProps.survey.isSubmit &&
        lastProps.survey.isSubmit !== this.props.survey.isSubmit
      ) {
        if (error === null && detail.status_code === 200) {
          if (!this.state._imageUploadList.length) {
            this.props.history.replace(`/survey`);
          } else {
            const { _imageUploadList } = this.state;
            if (_imageUploadList.length) {
              let { identifier, fileInput } = _imageUploadList[0];
              //compress file
              try {
                fileInput = await ImagesCompressor(fileInput);
              } catch (e) {}

              const _images = this.state._images.map((item) => {
                if (item.identifier === identifier)
                  return { ...item, isUploading: true };
                return item;
              });
              this.setState(
                {
                  _images,
                  _taskDone: this.state._taskDone + 1,
                  _form: {
                    ...this.state._form,
                    id: detail.id,
                  },
                },
                () => {
                  const tempPayload = createUploadFileForm(fileInput, false, {
                    name: "survey/" + this.state._form.id,
                    bucket_type: "assets",
                  });
                  this.props.uploadImage(tempPayload, _imageUploadList[0]);
                }
              );
            }
          }
        } else {
          this.setState({
            _isModalProgress: false,
            _taskAvailable: 0,
            _taskDone: false,
          });
        }
      }
      if (
        lastProps.imageUpload.isUpload &&
        lastProps.imageUpload.isUpload !== this.props.imageUpload.isUpload
      ) {
        const { success, error } = this.props.imageUpload;
        if (!error) {
          const { _images } = this.state;
          let myIdentifier = null;
          const filtered = _images.filter(
            (img) =>
              img.identifier === success.data.identifier &&
              !img.status &&
              success.data.url !== null
          );
          if (filtered.length) {
            myIdentifier = filtered[0].identifier;
            this.setState(
              {
                _images: _images.map((item) => {
                  if (item.identifier == myIdentifier) {
                    return {
                      ...item,
                      storageName: getStorageName(success.data.url),
                      urlKey: getUrlKey(success.data.url),
                      url: success.data.url,
                    };
                  }
                  return item;
                }),
              },
              () => {
                const { question, multiple_option, parent_id, order, active } = this.state._form
                this.props.saveImage(myIdentifier, this.state._form.id, {
                  img_url: success.data.url,
                  question, multiple_option, parent_id, order, active
                });
              }
            );
          }
        }
      }
      if (
        lastProps.survey.isSubmittingImage &&
        !this.props.survey.isSubmittingImage
      ) {
        const _images = [...this.state._images];
        const { addedImages } = this.props.survey;
        let getIdentifier = null;
        let count = this.state._taskDone;
        Object.keys(addedImages).map((index) => {
          const filtered = _images.filter(
            (item) => item.identifier === index && item.isUploading
          );
          if (filtered.length) {
            getIdentifier = index;
            filtered[0].id = addedImages[index].id;
            filtered[0].updatedAt = addedImages[index].updated_at;
            filtered[0].status = true;
            filtered[0].isUploading = false;
            count++;
          }
        });
        const nextImageUpload = this.state._imageUploadList.filter(
          (item) => item.identifier !== getIdentifier
        );
        if (nextImageUpload.length) {
          let { identifier, fileInput } = nextImageUpload[0];

          //compress file
          try {
            fileInput = await ImagesCompressor(fileInput);
          } catch (e) {}

          _images.map((item) => {
            if (item.identifier === identifier) {
              item.isUploading = true;
            }
          });
          const tempPayload = createUploadFileForm(fileInput, false, {
            name: "survey/" + this.state._form.id,
            bucket_type: "assets",
          });
          this.props.uploadImage(tempPayload, nextImageUpload[0]);
        }
        this.setState({
          _images,
          _imageUploadList: this.state._imageUploadList.filter(
            ({ identifier }) => identifier !== getIdentifier
          ),
          _taskDone: count,
        });
      }
    }
  };

  /**
   * Handle to change local state from input uset like textfield
   * @author haikal
   * @param {event} e
   */
  handleChange(e) {
    const newState = { ...this.state._form };
    newState[e.target.name] = e.target.value;
    this.setState({ _form: newState });
  }
  handleCheckedChange(e) {
    const newState = { ...this.state._form };
    newState[e.target.name] = e.target.checked;
    this.setState({ _form: newState });
  }
  onSelectChange(val, attributeForm) {
    const { _form } = this.state;
    if (val && val.id) {
      _form[attributeForm] = val;
      _form["parent_id"] = val.id;
      this.setState({ _form });
    }
  }
  onSubmit(e) {
    e.preventDefault();
    this.setState({ _formSubmit: true }, () => {
      const {
        id,
        question,
        answer,
        parent_id,
        order,
        multiple_option,
        active,
      } = this.state._form;
      if (this.props.edit && this.state._hasId) {
        this.props.update(id, {
          question,
          answer,
          parent_id,
          order,
          multiple_option,
          active,
        });
      } else {
        if (
          this.state._images.filter((item) => item.storageName === "in-local")
            .length
        ) {
          const _imageUploadList = this.state._images
            .filter((item) => item.storageName === "in-local")
            .map(({ identifier, fileInput }) => ({ identifier, fileInput }));
          this.setState(
            {
              _imageUploadList,
              _isModalProgress: true,
              _taskAvailable: 1 + _imageUploadList.length,
              _taskDone: 0,
            },
            () =>
              this.props.create({
                question,
                answer,
                parent_id,
                order,
                multiple_option,
                active,
              })
          );
        } else
          this.props.create({
            question,
            answer,
            parent_id,
            order,
            multiple_option,
            active,
          });
      }
    });
  }

  _handleDropzoneOnDrop(files) {
    if (files.length) {
      const file = files[0]
      const droppedImages = [file].map((file) => {
        const identifier = uuidv4();
        return {
          id: null,
          url: null,
          storageName: "in-local",
          status: false,
          isUploading: false,
          fileInput: file,
          updatedAt: null,
          identifier,
        };
      });
      const clonedImages = [...droppedImages];
      this.setState({ _images: clonedImages });
    }
  }

  _handleRemoveImage(e, imageIdentifier, imageId) {
    e.preventDefault();
    const { _images, _form } = this.state;
    if (imageId) {
      this.setState({
        _images: _images.map((item) =>
          item.identifier === imageIdentifier
            ? { ...item, deleted: true }
            : item
        ),
      });
      this.props.removeImage(imageIdentifier,this.state._form.id, {
        ...this.props.survey.detail,
        img_url: null
      });
    } else {
      this.setState({
        _images: _images.filter(
          ({ identifier }) => identifier !== imageIdentifier
        ),
      });
    }
  }

  _handleUploadImages = async () => {
    const { _images, _imageUploadProgress } = this.state;
    const notUploadedImages = _images.filter((item) => item.id === null);
    if (!_imageUploadProgress && notUploadedImages.length) {
      const firstImage = notUploadedImages[0];
      let fileInput;

      try {
        //compress file
        fileInput = await ImagesCompressor(firstImage.fileInput);
      } catch (e) {
        fileInput = firstImage.fileInput;
      }

      const newImages = _images.map((item) => {
        if (item.identifier == firstImage.identifier) {
          return {
            ...item,
            is_uploading: true,
          };
        }
        return {
          ...item,
        };
      });
      this.setState({
        _images: newImages,
        _imageUploadProgress: !_imageUploadProgress,
        _imageUploadList: notUploadedImages.map((item) => item.identifier),
      });
      const tempPayload = createUploadFileForm(fileInput, false, {
        name: "survey/" + this.state._form.id,
        bucket_type: "assets",
      });
      this.props.uploadImage(tempPayload, firstImage);
    }
  };

  handleChangeEditor(value) {
    this.setState({
      _form: {
        ...this.state._form,
        answer: value.editor.getData(),
      },
    });
  }

  bindSurveyImageData = (imagesProps) => {
    return imagesProps.map((image) => ({
      identifier: uuidv4(),
      id: image.id,
      position: image.position,
      storageName: getStorageName(image.URL),
      urlKey: getUrlKey(image.URL),
      url: image.URL,
      updated_at: image.updated_at,
      status: true,
      is_uploading: false,
    }));
  };

  renderLoading() {
    return (
      <div className="row">
        <div className="col-sm-12">
          <Alert color="info" className="text-center">
            Getting all data...
          </Alert>
        </div>
      </div>
    );
  }
  renderInfo() {
    let infoMessage = [];
    if (this.props.survey.isSubmit)
      infoMessage.push(
        <Alert color="info" key="info">
          <strong>Heads up!</strong> Submitting...
        </Alert>
      );
    if (this.props.survey.success)
      infoMessage.push(
        <Alert color="success" key="success">
          <strong>Well done!</strong> {this.props.survey.success}
        </Alert>
      );
    if (this.props.survey.error !== null) {
      const { status_code } = this.props.survey.error;
      switch (status_code) {
        case 422:
          infoMessage.push(
            <Alert color="danger" key="error">
              <strong>Oh Snap!</strong> Please fullfill your form.{" "}
            </Alert>
          );
          break;
        case 404:
          infoMessage.push(
            <Alert color="danger" key="error">
              <strong>Oh Snap!</strong> Resource not found.{" "}
            </Alert>
          );
          break;
        default:
          infoMessage.push(
            <Alert color="danger" key="error">
              <strong>Oh Snap!</strong> We've got something errors{" "}
            </Alert>
          );
          break;
      }
    }
    return (
      <div className="row">
        <div className="col-sm-12">{infoMessage}</div>
      </div>
    );
  }

  validateError(name) {
    if (this.hasError(name)) {
      let errorList = this.props.survey.error.errors;
      return <ValidationMessage message={errorList[name][0]} />;
    }
    return "";
  }

  hasError(name) {
    if (this.props.survey.error) {
      if (this.props.survey.error.errors) {
        let errorList = this.props.survey.error.errors;
        let errList = Object.keys(errorList).filter((key) => key == name);
        if (errList.length) return true;
      }
    }
    return false;
  }

  toggle() {
    this.setState({ _modalDelete: !this.state._modalDelete });
  }

  onDelete() {
    const _assetsToDelete = this.state._images.filter(
      (item) => item.urlKey !== ""
    );
    this.setState(
      {
        _formSubmit: true,
        _isDeleting: true,
        _modalDelete: false,
        _modalDeleteProgressOpen: true,
        _surveyDeleteProgress: true,
        _taskAvailable: 1 + _assetsToDelete.length,
        _taskDone: 0,
        _assetsToDelete,
      },
      () => {
        this.props.delete(this.state._form.id);
      }
    );
  }

  render() {
    return (
      <Row>
        <ModalProgress
          isDeleting={this.state._isDeleting}
          isOpen={
            this.state._isModalProgress || this.state._modalDeleteProgressOpen
          }
          renderModalBody={() =>
            this.state._isDeleting ? (
              <p className="text-center">
                {this.state._taskAvailable > this.state._taskDone
                  ? "Deleting..."
                  : this.state._taskAvailable > 0
                  ? "Delete successfully..."
                  : "Not available..."}{" "}
                ({this.state._taskDone}/{this.state._taskAvailable})
              </p>
            ) : (
              <p className="text-center">
                {this.state._taskAvailable > this.state._taskDone
                  ? "Please waiting..."
                  : this.state._taskAvailable > 0
                  ? "Submit successfully..."
                  : "Not available..."}{" "}
                ({this.state._taskDone}/{this.state._taskAvailable})
              </p>
            )
          }
          isPrimaryButtonHide={this.state._taskAvailable > this.state._taskDone}
          onPrimaryClick={() =>
            this.state._isDeleting
              ? this.props.history.replace(`/survey`)
              : this.props.history.replace(`/survey/${this.state._form.id}`)
          }
        />
        <Modal
          isOpen={this.state._modalDelete}
          toggle={this.toggle.bind(this)}
          className="modal-dialog modal-sm"
        >
          <ModalHeader toggle={this.toggle.bind(this)}>
            Confirmation
          </ModalHeader>
          <ModalBody>
            {this.state._modalDelete && this.props.survey.isSubmit ? (
              <div className="row">
                <div className="col-sm-12">
                  <Alert color="info" className="text-center">
                    Deleting...
                  </Alert>
                </div>
              </div>
            ) : (
              <span>Are you sure to delete this?</span>
            )}
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.onDelete.bind(this)}>
              Yes
            </Button>{" "}
            <Button color="secondary" onClick={this.toggle.bind(this)}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
        <Col xs={12} md={{ size: 6, offset: this.state._hasId? 0: 3 }}>
          <Card>
            <CardHeader>
              <i className="fa fa-align-justify"></i> {this.props.title}
            </CardHeader>
            <CardBody>
              {this.renderInfo()}
              {this.props.survey.isFetch ? (
                this.renderLoading()
              ) : (
                <Form onSubmit={this.onSubmit}>
                  <FormGroup
                    className={
                      this.hasError("question")
                        ? " has-danger has-feedback"
                        : ""
                    }
                  >
                    <Label for="formQuestion">Question</Label>
                    <Input
                      type="text"
                      id="formQuestion"
                      name="question"
                      placeholder="Enter a question*"
                      value={this.state._form.question}
                      onChange={this.handleChange.bind(this)}
                    />
                    {this.validateError("question")}
                  </FormGroup>
                  <FormGroup
                    className={
                      this.hasError("parent_id")
                        ? " has-danger has-feedback"
                        : ""
                    }
                  >
                    <Label for="formSurveyParent">Parent</Label>
                    <SelectSurveyParent
                      id="formSurveyParent"
                      name="parent_id"
                      defaultOptions
                      ref="selectSurveyParent"
                      paramsApi={{
                        sort_by: "createdAt_desc",
                        is_parent: true,
                        except_ids: this.state._form.id
                          ? this.state._form.id
                          : null,
                      }}
                      placeholder="Select a parent..."
                      noResultsText="Cannot find survey parent."
                      value={this.state._form.parent || null}
                      onSelectChange={(val) => {
                        this.onSelectChange(val, "parent");
                      }}
                    />
                    {this.validateError("parent_id")}
                  </FormGroup>
                  <FormGroup
                    className={
                      this.hasError("order") ? " has-danger has-feedback" : ""
                    }
                  >
                    <Label for="formOrder">Order</Label>
                    <Input
                      type="number"
                      id="formOrder"
                      name="order"
                      value={this.state._form.order}
                      onChange={this.handleChange.bind(this)}
                    />
                    {this.validateError("order")}
                  </FormGroup>
                  <FormGroup
                    className={classNames({
                      "has-danger has-feedback": hasError(
                        "multiple_option",
                        this.state._error
                      ),
                    })}
                  >
                    <Label for="formMultipleOption">
                      Multiple option &nbsp;
                    </Label>
                    <label className="switch switch-text switch-success-outline-alt">
                      <input
                        type="checkbox"
                        className="switch-input"
                        name="multiple_option"
                        id="formMultipleOption"
                        value="true"
                        checked={textValue("multiple_option", this.state._form)}
                        onChange={this.handleCheckedChange}
                      />
                      <span
                        className="switch-label"
                        data-on="On"
                        data-off="Off"
                      ></span>
                      <span className="switch-handle"></span>
                    </label>
                    {this.validateError("multiple_option")}
                  </FormGroup>
                  <FormGroup
                    className={classNames({
                      "has-danger has-feedback": hasError(
                        "active",
                        this.state._error
                      ),
                    })}
                  >
                    <Label for="formSurveyActive">Set to Active &nbsp;</Label>
                    <label className="switch switch-text switch-success-outline-alt">
                      <input
                        type="checkbox"
                        className="switch-input"
                        name="active"
                        id="formSurveyActive"
                        value="true"
                        checked={textValue("active", this.state._form)}
                        onChange={this.handleCheckedChange}
                      />
                      <span
                        className="switch-label"
                        data-on="On"
                        data-off="Off"
                      ></span>
                      <span className="switch-handle"></span>
                    </label>
                    {this.validateError("active")}
                  </FormGroup>
                  {!this.state._hasId && (
                    <FormGroup>
                      <UploadImages
                        onDrop={this._handleDropzoneOnDrop}
                        images={this.state._images}
                        onRemoveImage={this._handleRemoveImage}
                      />
                    </FormGroup>
                  )}
                  <FormGroup>
                    <ButtonGroup>
                      <Button
                        color="primary"
                        disabled={this.props.survey.isSubmit}
                      >
                        Submit
                      </Button>
                      {this.state._hasId ? (
                        <Button
                          color="danger"
                          onClick={this.toggle.bind(this)}
                          disabled={this.props.survey.isSubmit}
                        >
                          Delete
                        </Button>
                      ) : (
                        ""
                      )}
                    </ButtonGroup>
                  </FormGroup>
                </Form>
              )}
            </CardBody>
          </Card>
        </Col>
        {this.state._hasId && (
          <SurveyImage
            isLoading={
              this.props.survey.isFetch ||
              this.props.survey.detail.status_code !== 200
            }
            isShow={this.state._edit}
            images={this.state._images}
            onDrop={this._handleDropzoneOnDrop}
            onRemoveImage={this._handleRemoveImage}
            onUploadImages={this._handleUploadImages}
          />
        )}
      </Row>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    survey: state.survey,
    imageUpload: state.imageUpload,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    find: (id) => dispatch(getDetailSurvey(id)),
    delete: (id) => dispatch(deleteSurvey(id)),
    update: (id, payload) => dispatch(updateSurvey(id, payload)),
    create: (payload) => dispatch(createSurvey(payload)),
    clearImageUpload: () => dispatch(clearImageUpload()),
    uploadImage: (payload, rawImages) =>
      dispatch(uploadImage(payload, rawImages)),
    saveImage: (identifier, id, payload) =>
      dispatch(addImageSurvey(identifier,id, payload)),
    removeImage: (identifierImg, id, payload) =>
      dispatch(deleteImageSurvey(identifierImg, id, payload)),
    deleteImageS3: (payload) => dispatch(deleteImageS3(payload)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SurveyForm);
