import React, {PureComponent as Component} from 'react';
import propTypes from 'prop-types';

import SneakerDetailContent from '../../../components/Order/SneakerDetail';

export default class SneakerDetail extends Component {
  render(){
    return(
      <SneakerDetailContent className="tab-content--custom__card" {...this.props}/>
    )
  }
}
SneakerDetail.propTypes = {
  isLoading: propTypes.bool,
  userSellId: propTypes.number.isRequired,
  product: propTypes.shape({
    sale_id: propTypes.number.isRequired,
    display_name: propTypes.string,
    quantity: propTypes.number,
    image_url: propTypes.string
  })
}
SneakerDetail.defaultProps = {
  isLoading: false,
  userSellId: 0,
  product:{
    sale_id: 0,
    display_name: "",
    quantity: 0,
    image_url: ""
  }
}