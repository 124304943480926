import React, { PureComponent as Component } from "react";
import {
    Col,
    Form,
    FormGroup,
    Input,
    InputGroup,
    InputGroupAddon,
    Button,
} from "reactstrap";
import propTypes from "prop-types";

export default class SearchForm extends Component {
    render() {
        return (
            <Form onSubmit={this.props.onSubmit}>
                <FormGroup>
                    <Col xs={12} sm={12} md={6} style={{ padding: 0 }}>
                        <InputGroup>
                            <Input
                                type="text"
                                placeholder="Search notification's title"
                                name="keyword"
                                value={this.props.keyword}
                                onChange={this.props.onInputChange}
                            />
                            <InputGroupAddon addonType="append">
                                <Button color="primary">Search</Button>
                            </InputGroupAddon>
                        </InputGroup>
                    </Col>
                </FormGroup>
            </Form>
        );
    }
}

SearchForm.propTypes = {
    keyword: propTypes.string,
    onInputChange: propTypes.func,
    onSubmit: propTypes.func.isRequired,
};

SearchForm.defaultProps = {
    keyword: "",
};
