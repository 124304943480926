import React, { useState } from 'react';
import { Collapse } from 'reactstrap';
import classNames from 'classnames';
import { ImageList } from './ImageList';
import { DropzonePlusButton } from '../../../components/Form/DropzoneFile';
import { ImageFrame, ImageFileListContainer, ImageFile } from '../../../components/Images';
import { ButtonXs } from '../../../components/Button';

export const ImageVoucher = props => {
  const [isOpen, toggle] = useState(props.showItems)
  return (
    <div style={{marginBottom: '.75rem'}}>
      <div className="d-flex" style={{justifyContent: 'space-between', borderBottom: '1px solid #ddd'}}>
        <p className="text-uppercase">
          <strong>UPLOADS</strong>
        </p>
        <div>
          <span style={{fontSize: '.65rem'}} className="ml-3">total: {props.images.length}</span>
          {props.imageFiles.length>0?
            <ButtonXs
              color="danger"
              outline
              disabled={props.isLoading}
              className={classNames("ml-3", {"disabled": props.isLoading})}
              onClick={props.onClearImageFiles}
            >
              clear all
            </ButtonXs>
          :null}
          {props.showItems?(
            <ButtonXs color="gray" outline className="ml-3" onClick={() => toggle(!isOpen)}>{isOpen? "hide": "open"}</ButtonXs>
          ):null}
        </div>
      </div>
      <ImageFileListContainer>
        <ImageFrame>
          <DropzonePlusButton onDrop={props.onDropFiles}/>
        </ImageFrame>
        {props.imageFiles.map((img, key)=>(
          <ImageFile
            showDelete
            key={key}
            size={60}
            src={img.URL}
            onDelete={() => props.onDeleteFile(key)}
          />
        ))}
      </ImageFileListContainer>
      <Collapse isOpen={isOpen}>
        <div style={{overflow: "auto", maxHeight: 350}}>
          <ImageList
            images={props.images}
            isLoading={props.isLoading}
            onSaveImage={props.onSaveImage}
            message="No Images for this voucher."
          />
        </div>
      </Collapse>
    </div>
  )
}