import React from 'react';
import propTypes from 'prop-types';
import { Badge } from 'reactstrap';
import FontAwesomeIcon from '../../../components/Icons/FontAwesomeIcon';

import {
  DISBURSEMENT_BALANCE_OUT,
  DISBURSEMENT_BALANCE_IN,
  getDisbursementTypeStr
} from '../helpers';

const BadgeDisbursementType = (props) => {
  return (
    <Badge color={props.type===DISBURSEMENT_BALANCE_IN?"primary"
    :props.type===DISBURSEMENT_BALANCE_OUT?"success"
    :"default"}
    style={{marginRight: ".5rem"}}
    >
      <FontAwesomeIcon iconType="exchange"/> {getDisbursementTypeStr(props.type)}
    </Badge>
  );
}

export default BadgeDisbursementType;

BadgeDisbursementType.propTypes = {
  type: propTypes.string
}

BadgeDisbursementType.defaultProps = {
  type: DISBURSEMENT_BALANCE_IN
}