  import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Alert,
} from "reactstrap";
import { Link } from "react-router-dom";
import {
  saveVariantCourier,
  saveVariantPaymentMethod,
  getDetailProductVariant,
  saveProductVariant,
  deleteProductVariant,
  newProductVariant,
  attachSize,
  detachSize,
  addImage,
  deleteImage,
  saveSubsidy,
  saveCommission,
  deleteSubsidy,
  deleteCommission,
  getSubsidyList,
  updateImagePositions
} from "../../actions/productVariantAction";
import { getSettingPaymentMethod } from '../../actions/settingAction'
import { ImagesCompressor, createUploadFileForm } from "../../utils/imageHelper";
import { getSizeList, getBrandSizeList } from "../../actions/sizeAction";
import {
  getBrandList
} from "../../actions/brandAction";

import {
  clearImageUpload,
  upload,
  deleteObjectS3,
  uploadImage,
  deleteImage as deleteImageS3,
} from "../../actions/imageUploadAction";
import uuidv4 from "uuid/v4";
import PropTypes from "prop-types";
import ModalDelete from "../../components/Modals/ModalDelete";
import ModalDeleteProgess from "./Modals/ModalDeleteProgress";
import ProductVariantImages from "./ProductVariantImages";
import ProductVariantCollections from "./ProductVariantCollections";
import ProductVariantVouchers from "./ProductVariantVouchers";
import Detail from "./ProductVariant";
import ModalAttachSize from "./Modals/ModalAttachSize";
import ModalShippingRate from "./Modals/ModalShippingRate";
import ProductVariantSizes from "./ProductVariantSizes";
import ProductVariantCouriers from "./ProductVariantCouriers";
import Subsidy from "./Subsidy";
import Commission from "./Commission";
import fetch from '../../utils/Api';
import { AUTH_TOKEN_KEY } from '../../constants';
import ProductVariantPaymentMethods from "./ProductVariantPaymentMethods";
import { AWS_BUCKET, AWS_BUCKET_REGION } from "../../config/storageBucket";
import { getPathS3, getOriginFromSignedUrl } from '../../utils/AWS';
import { shippingRate } from '../../utils';
import { MAX_PRODUCT_VARIANT_EDITORS_CHOICE } from '../../constants/settings';
import { sleep } from "../../helpers/misc";
const AWS_ENDPOINT = "amazonaws.com";

const getStorageName = imgUrl => {
  if (new RegExp(AWS_BUCKET).test(imgUrl)) return AWS_BUCKET;
  else if (/kickavenue-assets/.test(imgUrl)) return "kickavenue-assets";
  return "external-link";
};

const getUrlKey = imgUrl => {
  const regexStorage = new RegExp(AWS_BUCKET);
  if (regexStorage.test(imgUrl)) {
    const firstPattern = new RegExp(
      `s3.${AWS_BUCKET_REGION}.${AWS_ENDPOINT}/${AWS_BUCKET}/`
    );
    const secondPattern = new RegExp(
      `${AWS_BUCKET}.s3.${AWS_BUCKET_REGION}.${AWS_ENDPOINT}/`
    );
    const thirdPattern = new RegExp(`${AWS_BUCKET}.s3.${AWS_ENDPOINT}/`);
    let parsedUrl = imgUrl.trim().replace(/^(https|http):\/\//, "");
    if (firstPattern.test(parsedUrl)) {
      return parsedUrl.replace(firstPattern, "");
    } else if (secondPattern.test(parsedUrl)) {
      return parsedUrl.replace(secondPattern, "");
    } else if (thirdPattern.test(parsedUrl)) {
      return parsedUrl.replace(thirdPattern, "");
    }
  }
  return "";
};

class ProductVariantForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      _edit: this.props.edit,
      _readOnly: this.props.readOnly,
      _hasId: this.props.match.params.id ? true : false,
      _selectImage:'img',
      _img_url:'',
      _form: {
        binding: false,
        id: this.props.match.params.id,
      },
      _isMaxEditorsChoice:false,
      _editorsChoiceTotal: {
        'sneakers': {
          'total': 0,
          'threshold': MAX_PRODUCT_VARIANT_EDITORS_CHOICE
        },
        'apparels': {
          'total': 0,
          'threshold': MAX_PRODUCT_VARIANT_EDITORS_CHOICE
        }
      },
      _rootBrands: [],
      _childBrands: [],
      _images: [],
      _imageUploadList: [],
      _imageUploadProgress: false,
      _collections: [],
      _sizesAvailable: [],
      _formSubmit: false,
      _modalDelete: false,
      _modalDeleteProgressOpen: false,
      _taskDeletedDone: 0,
      _taskDeletedAvailable: 0,
      _variantDeleteProgress: false,

      _modalCollection: false,
      _collectionFetched: false,
      _modalSize: false,
      _sizeFetched: false,
      _redirect: "/product_variants",
      _categoryName: null,
      _category_id: null,
      _shippingRates: [],
      _paymentMethods: [],
      _isModalShippingRate:false,
      _dataModalShippingRate: null,
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.handleOpenBrowseSizeModal = this.handleOpenBrowseSizeModal.bind(this);
    this.handleCloseBrowseSizeModal = this.handleCloseBrowseSizeModal.bind(
      this
    );
    this.handleSubmitBrowseSizeModal = this.handleSubmitBrowseSizeModal.bind(
      this
    );
    this.handleDetachAllSize = this.handleDetachAllSize.bind(this);
    this.filterSizeBrands = this.filterSizeBrands.bind(this);
    this.handleRemoveImage = this.handleRemoveImage.bind(this);
    this.handleUploadImages = this.handleUploadImages.bind(this);
    this.handleSelectImage = this.handleSelectImage.bind(this);
    this.getEditorsChoices = this.getEditorsChoices.bind(this);
    this.handleOnChangeInput = this.handleOnChangeInput.bind(this);
    this.handleSubmitImgrUrl = this.handleSubmitImgrUrl.bind(this);
    this.handleEditorsChoiceChange = this.handleEditorsChoiceChange.bind(this);
  }

  componentDidMount() {
    if (this.state._hasId) {
      this.props.find(this.state._form.id);
    } else this.props.new();
    this.props.getSettingPaymentMethod();
  }

  getEditorsChoiceList(id, name){
    return fetch({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
    }).get('admins/productvariants', {
      params: {
        category_id: id,
        per_page: 1,
        editors_choice:1
      }
    })
    .then(({ data })=> {
      const { _editorsChoiceTotal } = this.state;
      let payloads = {};
      if(_editorsChoiceTotal[name.toLowerCase()]){
        payloads = { ..._editorsChoiceTotal[name.toLowerCase()] }
      } else{
        payloads = { total: 0, threshold: MAX_PRODUCT_VARIANT_EDITORS_CHOICE }
      }
      this.setState({
        _editorsChoiceTotal: {
          ..._editorsChoiceTotal,
          [name.toLowerCase()]: {
            ...payloads,
            total: data.data.total
          }}
      })
    })
  }

  componentDidUpdate(prevProps){
    const { productVariant } = this.props;
    const { productVariant: prevProductVariant } = prevProps;
    // After submit image's url info from kick api
    if(prevProductVariant.isSubmittingImage &&
      !productVariant.isSubmittingImage){
      const _images = [...this.state._images];
      const { addedImages } = productVariant;
      Object.keys(addedImages).map(index => {
        const filtered = _images.filter(item => item.identifier === index);
        if (filtered.length) {
          filtered[0].id = addedImages[index].id;
          filtered[0].position = addedImages[index].position;
          filtered[0].url = addedImages[index].signed_url || addedImages[index].URL;
          filtered[0].updated_at = addedImages[index].updated_at;
        }
      });
      this.setState({ _images });
      const allSubmit = _images.every(item => item.id !== null)
      // console.log("allSubmit: ", allSubmit, _images)
      if(allSubmit){
        //Fix reorder image's position after submit upload image.
        this.props.updateImagePositions({
          images: _images.map((item, key) => ({
            id: item.id,
            position: key + 1
          })),
          product_variant_id: Number(this.props.match.params.id)
        })
      }
    }
    //reorder images...
    if(prevProductVariant.reorderImages.isSubmit && !productVariant.reorderImages.isSubmit){
      // console.log("data masuk: ", productVariant.reorderImages)
      const { manyImages, data, error } = productVariant.reorderImages
      if(error){
        alert("Error reorder images!")
        return
      }
      if(data){
        let { _images } = this.state;
        if(manyImages){
          _images = this.bindProductVariantImageData(data)
        } else{
          _images = _images.map(item => {
            const first = data.find(d => item.id === d.id)
            const updated_at = first? first.updated_at: item.updated_at
            return {
              ...item,
              position: item.positionEdit? item.positionEdit: item.position,
              updated_at
            }
          })
        }
        this.setState({ _images })
      }
    }
  }

  bindVariantCourierData = () => {
    const { _categoryName } = this.state;
    const { detail } = this.props.productVariant;
    const courierRate = shippingRate(_categoryName.toLowerCase());
    const defaultRate = [
      {
        province_id:[3,6],
        location_group: 1,
        name:'Jakarta & Banten',
        price : courierRate[0]
      },
      {
        province_id:[5,9,10,11],
        location_group: 2,
        name:'Pulau Jawa',
        price : courierRate[1]
      },
      {
        province_id:[],
        location_group: 3,
        name:'Luar Pulau Jawa',
        price : courierRate[2]
      },
      {
        province_id:[24, 25],
        location_group: 4,
        name:'Papua & Papua Barat',
        price :200000
      },
      {
        province_id:[34],
        location_group: 5,
        name:'Luar Negri',
        price :200000
      }
    ];
    const slug = detail.product.brand && detail.product.brand.ancestors.length>0? detail.product.brand.ancestors[0].slug : null;
    const data = defaultRate.map(rate => {
      var variantCourier = Object.assign({}, rate);
        detail.variant_couriers.find(item => {
          if((item.location_group == rate.location_group) ||
            (item.province_id && rate.province_id.includes(item.province_id) 
            && item.location_group == rate.location_group) || 
            (item.province_id && item.location_group && item.location_group == 3)){
            variantCourier.id = item.id;
            variantCourier.price = item.price;
          }
        });
        if(_categoryName.toLowerCase()==='lifestyles' && slug 
        && slug!=='bike' && !variantCourier.id)
          variantCourier.price = rate.price/2;
      return variantCourier;
    });
    
    const { paymentMethods } = this.props;
    const dataPayments = paymentMethods.map(payments =>{
      var variantPaymentMethod = Object.assign({}, payments);
      const get = detail?.variant_payment_methods.find(item=>{
        return item.payment_method === payments.value;
      });
      if(get){
        variantPaymentMethod.id = get.id;
        variantPaymentMethod.active = true;
      } 
      if(!variantPaymentMethod.id && detail?.variant_payment_methods?.length > 0) {
        variantPaymentMethod.active = false;
      }
      return variantPaymentMethod;
    })
    this.setState({
      _shippingRates: data,
      _paymentMethods: dataPayments
    })
  }

  componentWillReceiveProps(nextProps) {
    const { detail, isFetch, isSubmit, error } = nextProps.productVariant;
    if (this.state._edit) {
      // After get detail product
      if (detail.status_code === 200 && !isFetch && !this.state._form.binding) {
        // let { _form, _collections } = this.state;
        const { product_variant_images, product } = detail;
        const _images = this.bindProductVariantImageData(product_variant_images)
        this.setState(
          {
            _form: {
              ...this.state._form,
              editors_choice:detail.editors_choice,
              binding: !this.state._form.biding,
              display_name: detail.display_name,
              receive_sell:detail.receive_sell,
              biddable:detail.biddable,
            },
            _images,
            _categoryName: product.category.name.toLowerCase(),
            _category_id: product.category.id
          },
          () => {
            this.getEditorsChoiceList(product.category.id, product.category.name.toLowerCase());
            this.bindVariantCourierData();
            this.props.bindBrandOption({
              params: { category_id: this.state._category_id }
            });
          }
        );
      }
      if (!isSubmit && isSubmit !== this.props.productVariant.isSubmit) {
        if (this.state._variantDeleteProgress) {
          if (error) {
            this.setState({
              _modalDeleteProgressOpen: false,
              _variantDeleteProgress: false
            });
          } else if (detail.delete === true) {
            const { _taskDeletedAvailable, _taskDeletedDone } = this.state;
            this.setState(
              {
                _delete: detail.deleted,
                _variantDeleteProgress:
                  _taskDeletedAvailable > _taskDeletedDone + 1,
                _taskDeletedDone: _taskDeletedDone + 1
              },
              () => {
                const { _assetsToDelete, _variantDeleteProgress } = this.state;
                if (_variantDeleteProgress && _assetsToDelete.length) {
                  const { identifier, url } = _assetsToDelete[0];
                  const payload = {
                    identifier,
                    bucket_type : 'assets',
                    name : getPathS3(getOriginFromSignedUrl(url))
                  };
                  this.props.deleteImageS3(payload);
                }
              }
            );
          }
        }
        // after update get brand again
        if (detail.status_code === 200 && !isSubmit && error == null && !this.state._variantDeleteProgress) {
          // let { _form, _collections } = this.state;
          const { product_variant_images, product, updatedKey } = detail;
          if(updatedKey) return;
          const _images = this.bindProductVariantImageData(product_variant_images)
          this.setState(
            {
              _images,
              _categoryName: product.category.name.toLowerCase(),
              _category_id: product.category.id
            },
            () => {
              this.bindVariantCourierData();
              this.props.bindBrandOption({
                params: { category_id: this.state._category_id }
              });
            }
          );
        }
      }
      if (
        !nextProps.imageUpload.isDeleteProgress &&
        nextProps.imageUpload.isDeleteProgress !==
          this.props.imageUpload.isDeleteProgress
      ) {
        const { deleted } = nextProps.imageUpload;
        //&& this.state._delete === true
        if (this.state._variantDeleteProgress && deleted) {
          const _assetsToDelete = this.state._assetsToDelete.filter(
            ({ identifier }) => identifier !== deleted.identifier
          );
          let _taskDeletedDone = this.state._taskDeletedDone + 1;
          this.setState(
            {
              _variantDeleteProgress:
                this.state._taskDeletedAvailable > _taskDeletedDone,
              _taskDeletedDone: _taskDeletedDone,
              _assetsToDelete
            },
            () => {
              const { _assetsToDelete, _variantDeleteProgress } = this.state;
              if (_variantDeleteProgress && _assetsToDelete.length) {
                const { identifier, url } = _assetsToDelete[0];
                const payload = {
                  identifier,
                  bucket_type : 'assets',
                  name : getPathS3(getOriginFromSignedUrl(url))
                };
                this.props.deleteImageS3(payload);
              }
            }
          );
        }
      }
      // After get list of sizes from api
      if (
        this.state._sizeFetched &&
        this.props.masterSize.isFetch &&
        !nextProps.masterSize.isFetch &&
        this.state._form.binding
      ) {
        const sizes = this.props.productVariant.detail.sizes.map(
          ({ id }) => id
        );
        let _sizesAvailable = [];
        if (this.state._categoryName !== "sneakers") {
          if (this.props.masterSize.brandSizeList.status_code == 200) {
            _sizesAvailable = this.props.masterSize.brandSizeList.data
              .reduce((arr, item) => {
                if (!arr.includes(item.alias)) return [...arr, item.alias];
                return arr;
              }, [])
              .map(alias => ({
                group: alias,
                brand: {
                  id: this.props.masterSize.brandSizeList.data[0].brand.id,
                  name: this.props.masterSize.brandSizeList.data[0].brand.name,
                  title: this.props.masterSize.brandSizeList.data[0].brand.title
                },
                items: this.props.masterSize.brandSizeList.data
                  .filter(item => item.alias === alias)
                  .map(item => ({
                    ...item.size,
                    alias: item.alias,
                    type: item.type,
                    order: item.order,
                    taken: sizes.includes(item.size.id),
                    disabled: sizes.includes(item.size.id)
                  }))
              }))
              .sort((a, b) => (a.group < b.group ? -1 : 1));
          }
        } else {
          if (this.props.masterSize.list.status_code === 200) {
            _sizesAvailable = this.props.masterSize.list.data.map(item => ({
              ...item,
              taken: sizes.includes(item.id),
              disabled: sizes.includes(item.id)
            }));
          }
        }
        this.setState({
          _sizesAvailable,
          _sizeFetched: !this.state._sizeFetched
        });
      }
      if(!nextProps.imageUpload.isUpload && nextProps.imageUpload.isUpload !== this.props.imageUpload.isUpload){
        const { success, error } = this.props.imageUpload;
        if(!error){
          const { data } = success.data;
          const { _images, _imageUploadList } = this.state;
          let myIdentifier = null;
            const filtered = _images.filter(
              img =>
                img.identifier === success.data.identifier &&
                !img.status &&
                success.data.url !== null
            );
            if (filtered.length) {
              let { _imageUploadProgress } = this.state;
              myIdentifier = filtered[0].identifier;
              const imageUploadList = _imageUploadList.filter(
                id => id !== myIdentifier
              );
              let nextUploadId = null;
              if (imageUploadList.length <= 0) {
                _imageUploadProgress = false;
              } else {
                nextUploadId = imageUploadList[0];
              }
              this.setState(
                {
                  _images: _images.map(item => {
                    if (item.identifier == myIdentifier) {
                      return {
                        ...item,
                        status: true,
                        is_uploading: false,
                        storageName: getStorageName(
                          success.data.url
                        ),
                        urlKey: getUrlKey(
                          success.data.url
                        ),
                        url:
                          success.data.url
                      };
                    } else if (item.identifier == nextUploadId) {
                      return {
                        ...item,
                        is_uploading: true
                      };
                    }
                    return item;
                  }),
                  _imageUploadList: imageUploadList,
                  _imageUploadProgress
                },
                async () => {
                  await sleep(2000);
                  this.props.saveImage(myIdentifier, {
                    product_variant_id: this.state._form.id,
                    URL: success.data.url,
                    position: filtered[0].positionEdit !== undefined? filtered[0].positionEdit: filtered[0].position
                  });
                  const nextItemUpload = _images.filter(
                    item => item.identifier === nextUploadId
                  );
                  if (nextItemUpload.length && nextUploadId !== null) {
                    let { identifier, fileInput } = nextItemUpload[0];
  
                    try {
                      //compress file
                      fileInput = await ImagesCompressor(fileInput);
                    } catch (e) {}
                    const tempPayload = createUploadFileForm(fileInput, false, {name: "products/"+this.state._form.id, bucket_type: "assets"});
                    this.props.uploadImage(tempPayload, nextItemUpload[0]);
                  }
                }
              );
            }
        }
      }
      // After deleting image's url from kick api
      if (
        !nextProps.productVariant.isDeletingImage &&
        this.props.productVariant.isDeletingImage
      ) {
        const { deletedImages } = nextProps.productVariant;
        if (deletedImages.length) {
          const _images = [...this.state._images];
          const lastUniqueID = deletedImages[deletedImages.length - 1];
          const lastImage = _images.filter(
            item => item.identifier === lastUniqueID
          );
          if (lastImage.length) {
            const { identifier, urlKey, url } = lastImage[0];
            if (urlKey !== "") {
              const payload = {
                identifier,
                bucket_type : 'assets',
                name : getPathS3(getOriginFromSignedUrl(url))
              };
              this.props.deleteImageS3(payload);
            }
            this.setState({
              _images: _images.filter(item => item.identifier !== lastUniqueID)
            });
          }
        }
      }
      if (
        nextProps.brand.isFetch === false &&
        nextProps.brand.isFetch !== this.props.brand.isFetch &&
        nextProps.brand.list.status_code === 200
      ) {
        if (nextProps.brand.list.data.length) {
          this.setState({
            _rootBrands: nextProps.brand.list.data.map(brand => ({
              value: brand.id,
              label:
                brand.title && brand.title !== "" ? brand.title : brand.name
            }))
          });
        }
      }
    } else {
      // Checking if already ok after submit
      if (
        detail.status_code === 200 &&
        error === null &&
        this.state._formSubmit &&
        !isSubmit
      ) {
        this.setState({
          _form: this.clearForm(),
          _formSubmit: !this.state._formSubmit
        });
      }
    }
  }

  componentWillUnmount() {
    this.props.clearImageUpload();
  }

  getEditorsChoices(category, type){
    const { _editorsChoiceTotal } = this.state;
    const name = category && category.toLowerCase();
    const isNotEmpty = name && name !== '';
    switch(name){
      case 'sneakers': return _editorsChoiceTotal.sneakers[type];
      case 'apparels': return _editorsChoiceTotal.apparels[type];
      default:{
        if(isNotEmpty && _editorsChoiceTotal[name]){
          return _editorsChoiceTotal[name][type];
        }
        return Object.keys(_editorsChoiceTotal).reduce((total, keyName)=> {
          return total += _editorsChoiceTotal[keyName][type]
        }, 0);
      }
    }
  }  
  
  handleEditorsChoiceChange(name, type){
    const { _editorsChoiceTotal } = this.state;
    if(type==='DEC'){
      if(_editorsChoiceTotal-1<=0){
        this.setState({
          _isMaxEditorsChoice: false,
          _editorsChoiceTotal:{
            ...this.state._editorsChoiceTotal,
            [name]: {
              ...this.state._editorsChoiceTotal[name],
              total: 0
            }
          }
        })
      }
      else{
        this.setState({
          _isMaxEditorsChoice: false,
          _editorsChoiceTotal:{
            ...this.state._editorsChoiceTotal,
            [name]: {
              ...this.state._editorsChoiceTotal[name],
              total: this.state._editorsChoiceTotal[name].total - 1
            }
          }
        })
      }
    }
    else if(type==='INC'){
      const total = this.state._editorsChoiceTotal[name].total + 1;
      this.setState({
        _isMaxEditorsChoice: false,
        _editorsChoiceTotal:{
          ...this.state._editorsChoiceTotal,
          [name]: {
            ...this.state._editorsChoiceTotal[name],
            total: this.state._editorsChoiceTotal[name].total + 1
          }
        }
      },()=> {
        if(total >= MAX_PRODUCT_VARIANT_EDITORS_CHOICE)
          this.setState({_isMaxEditorsChoice:true})
      })
    }
  }



  bindProductVariantImageData = (imagesProps) =>{
    return imagesProps.map(image => ({
      identifier: uuidv4(),
      id: image.id,
      position: image.position,
      storageName: getStorageName(image.URL),
      urlKey: getUrlKey(image.URL),
      url: image.signed_url || image.URL,
      updated_at: image.updated_at,
      status: true,
      is_uploading: false
    }));
  }

  clearForm() {
    const { binding, id } = this.state._form;
    return { id, binding };
  }

  handleOnChangeInput(e){
    const lastState = {...this.state};
    lastState[e.target.name] = e.target.value;
    this.setState({...lastState});
  }

  handleSubmitImgrUrl(){
    if(this.state._img_url){
      this.props.saveImage(null, {
        product_variant_id: this.state._form.id,
        URL: this.state._img_url,
      });
    }
  }

  handleCheckedChange(e) {
    const newState = { ...this.state._form };
    newState[e.target.name] = e.target.checked;
    this.setState({ _form: newState });
  }

  toggle(stateName) {
    const lastState = this.state;
    lastState[stateName] = !this.state[stateName];
    this.setState({ ...lastState });
  }

  onDelete() {
    const _assetsToDelete = this.state._images.filter(
      item => item.urlKey !== ""
    );
    this.setState(
      {
        _modalDelete: false,
        _modalDeleteProgressOpen: true,
        _variantDeleteProgress: true,
        _taskDeletedAvailable: 1 + _assetsToDelete.length,
        _taskDeletedDone: 0,
        _assetsToDelete
      },
      () => {
        this.props.delete(this.state._form.id);
      }
    );
  }

  handleRemoveImage(e, identifier, imageId) {
    e.preventDefault();
    const { _images } = this.state;
    if (imageId) {
      this.setState({
        _images: _images.map(item =>
          item.identifier === identifier ? { ...item, deleted: true } : item
        )
      });
      this.props.removeImage(identifier, imageId);
    } else {
      this.setState({
        _images: _images.filter(item => item.identifier !== identifier)
      });
    }
  }

  onSubmit(payload) {
    const {
      id,
      product_id,
      nickname,
      display_name,
      type,
      dimension,
      colour,
      weight,
      SKU,
      sex,
      active,
      details,
      price_source,
      retail_price,
      retail_price_indonesia,
      release_date,
      expiry_date,
      currency,
      editors_choice,
      hide_box_info,
      receive_sell,
      biddable,
      auto_sync,
      vintage,
      editors_position,
      voucher_applicable,
      ribbon_tag
    } = payload;
    this.props.save(
      {
        product_id,
        nickname,
        display_name,
        type,
        dimension,
        colour,
        weight,
        SKU,
        sex,
        active,
        details,
        price_source,
        retail_price,
        retail_price_indonesia,
        release_date,
        expiry_date,
        currency,
        editors_choice,
        hide_box_info,
        receive_sell,
        biddable,
        auto_sync,
        vintage,
        editors_position: editors_choice? editors_position:0,
        voucher_applicable,
        ribbon_tag      },
      id
    );
  }

  // Event for Product Variant Collections Section
  handleOpenBrowseCollectionModal() {
    this.toggle("_modalCollection");
    this.setState({ _collectionFetched: true });
    this.props.getCollectionList();
  }

  handleCloseBrowseCollectionModal() {
    this.toggle("_modalCollection");
  }

  handleSubmitBrowseCollectionModal() {
    const collection_id = [];
    this.state._collections.forEach(item => {
      if (item.taken) collection_id.push(item.id);
    });
    if (collection_id.length) {
      this.props.attachCollection(this.state._form.id, { collection_id });
      this.toggle("_modalCollection");
    }
  }

  handleCheckedChangeBrowseCollectionItem(e, identifier) {
    const { _collections } = this.state;
    _collections[identifier].taken = e.target.checked;
    this.setState({ _collections });
  }

  handleDetachCollection(e, id) {
    e.preventDefault();
    this.props.detachCollection(this.state._form.id, { collection_id: [id] });
  }

  handleOpenBrowseSizeModal() {
    this.toggle("_modalSize");
  }

  filterSizeBrands({ brand_id, ...rest }) {
    const { _categoryName, _category_id } = this.state;
    if (brand_id) {
      this.setState({ _sizeFetched: true }, () => {
        _categoryName.toLowerCase() !== "sneakers"
          ? this.props.getBrandSizeList({
              params: {
                no_limit: true,
                brand_id,
                category_id: _category_id,
                ...rest
              }
            })
          : this.props.getSizeList({
              params: { no_limit: true, brand_id, ...rest }
            });
      });
    }
  }

  handleCloseBrowseSizeModal() {
    this.toggle("_modalSize");
    this.setState({ _sizesAvailable: [] });
  }

  handleDetachSize(id) {
    this.props.detachSize(this.state._form.id, { size_id: [id] });
  }

  handleDetachAllSize() {
    const size_id = this.props.productVariant.detail.sizes.map(item => item.id);
    if (size_id.length) this.props.detachSize(this.state._form.id, { size_id });
  }

  handleSubmitBrowseSizeModal(selected_sizes) {
    const size_id = selected_sizes.map(item => {
      if (item.alias !== undefined) {
        return {
          id: item.id,
          size_type: item.alias ? item.alias : "DEFAULT",
          size_order: item.type ? item.type : 0
        };
      }
      return item.id;
    });
    if (size_id.length) {
      this.props.attachSize(this.state._form.id, { size_id });
      this.toggle("_modalSize");
    }
  }

  // Event for Product Variant Images sections
  onDropImage(files) {
    if (files.length) {
      const { _images } = this.state;
      const droppedImages = files.map((file, i) => {
        const identifier = uuidv4();
        return {
          id: null,
          url: null,
          position: _images.length + i + 1,
          storageName: "in-local",
          status: false,
          is_uploading: false,
          fileInput: file,
          updated_at: null,
          identifier
        };
      });
      const clonedImages = [..._images, ...droppedImages];
      this.setState({ _images: clonedImages });
    }
  }

  handleUploadImages = async e => {
    const { _images, _imageUploadProgress } = this.state;
    const notUploadedImages = _images.filter(item => item.id === null);
    if (!_imageUploadProgress && notUploadedImages.length) {
      const firstImage = notUploadedImages[0];
      let fileInput;

      try {
        //compress file
        fileInput = await ImagesCompressor(firstImage.fileInput);
      } catch (e) {
        fileInput = firstImage.fileInput;
      }

      const newImages = _images.map(item => {
        if (item.identifier == firstImage.identifier) {
          return {
            ...item,
            is_uploading: true
          };
        }
        return {
          ...item
        };
      });
      this.setState({
        _images: newImages,
        _imageUploadProgress: !_imageUploadProgress,
        _imageUploadList: notUploadedImages.map(item => item.identifier)
      });
      const tempPayload = createUploadFileForm(fileInput, false, {name: "products/"+this.state._form.id, bucket_type: "assets"});
      this.props.uploadImage(tempPayload, firstImage);
    }
  };

  handleSelectImage(e){
    e.preventDefault();
    this.setState({_selectImage:e.target.value});
  }

  _toggleShippingRateModal(value, payloads = null){
    this.setState({
      _isModalShippingRate: value,
      _dataModalShippingRate: payloads? { ...payloads }: null
    })
  }

  renderInfo() {
    let infoMessage = [];
    if (this.props.productVariant.isSubmit)
      infoMessage.push(
        <Alert color="info" key="info">
          <strong>Heads up!</strong> Submitting...
        </Alert>
      );
    if (this.props.productVariant.success)
      infoMessage.push(
        <Alert color="success" key="success">
          <strong>Well done!</strong> {this.props.productVariant.success}
        </Alert>
      );
    if (
      this.props.productVariant.error !== null &&
      !this.props.productVariant.isFetch
    ) {
      const { status_code, message } = this.props.productVariant.error;
      switch (status_code) {
        case 422:
          infoMessage.push(
            <Alert color="danger" key="error">
              <strong>Oh Snap!</strong> Please fullfill your form.{" "}
            </Alert>
          );
          break;
        case 404:
          infoMessage.push(
            <Alert color="danger" key="error">
              <strong>Oh Snap!</strong> Resource not found.{" "}
            </Alert>
          );
          break;
        default:
          infoMessage.push(
            <Alert color="danger" key="error">
              <strong>Oh Snap!</strong> We've got something errors{" "}
            </Alert>
          );
          break;
      }
    } else{
      const { _isMaxEditorsChoice } = this.state;
      if(_isMaxEditorsChoice) 
        infoMessage.push(
          <Alert color="danger" key="error">
            <strong>Oh Snap!</strong> You're reaching hot product's limit. Only ({MAX_PRODUCT_VARIANT_EDITORS_CHOICE}) items per category.{" "}
          </Alert>
        );
    }
    return (
      <div className="row">
        <div className="col-sm-12">{infoMessage}</div>
      </div>
    );
  }

  renderLoading() {
    return (
      <div className="row">
        <div className="col-sm-12">
          <Alert color="info" className="text-center">
            Getting all data...
          </Alert>
        </div>
      </div>
    );
  }

  onSortEnd = (newArray, id, afterId) => {
    this.setState({
      _images: [ ...newArray ]
    })
    //check submit only doesnt have uploaded images.
    const submit = newArray.every(item => item.id !== null)
    if(submit){
      let payloads = {
        product_variant_id: Number(this.props.match.params.id)
      }
      if(id){
        payloads.id = id;
        payloads.after_id = afterId
      } else{
        payloads.images = newArray.map((item, key) => ({
          id: item.id,
          position: key + 1
        }))
      }
      this.props.updateImagePositions(payloads)
    }
  }

  render() {
    return (
      <Row>
        <ModalDelete
          isOpen={this.state._modalDelete}
          toggle={() => this.toggle("_modalDelete")}
          onDelete={this.onDelete}
        />
        <ModalDeleteProgess
          isOpen={this.state._modalDeleteProgressOpen}
          renderModalBody={() => (
            <p className="text-center">
              {this.state._taskDeletedAvailable > this.state._taskDeletedDone
                ? "Please waiting..."
                : this.state._taskDeletedAvailable > 0
                ? "Delete successfully..."
                : "Not available..."}{" "}
              ({this.state._taskDeletedDone}/{this.state._taskDeletedAvailable})
            </p>
          )}
          isPrimaryButtonHide={this.state._variantDeleteProgress}
          onPrimaryClick={() => this.props.history.replace("/product_variants")}
        />
        <ModalShippingRate
          id={this.state._form.id}
          data={this.state._dataModalShippingRate}
          isOpen={this.state._isModalShippingRate}
          toggle={() => this._toggleShippingRateModal(false)}
          saveVariantCourier={this.props.saveVariantCourier}
        />
        <ModalAttachSize
          isOpen={this.state._modalSize}
          toggle={this.handleCloseBrowseSizeModal}
          onSubmit={this.handleSubmitBrowseSizeModal}
          brands={this.state._rootBrands}
          childBrands={this.state._childBrands}
          dataSizes={this.state._sizesAvailable}
          isLoading={this.state._sizeFetched}
          productName={this.state._form.display_name}
          categoryName={this.state._categoryName}
          onFiltered={this.filterSizeBrands}
          onRootBrandChanged={id => {
            if (this.state._categoryName !== "sneakers") {
              const { status_code } = this.props.brand.list;
              if (status_code === 200) {
                const selectedBrand = this.props.brand.list.data.find(
                  item => item.id == id
                );
                if (selectedBrand) {
                  this.setState({
                    _childBrands: selectedBrand.children.map(c => ({
                      label: c.title && c.title !== "" ? c.title : c.name,
                      value: c.id
                    }))
                  });
                }
              }
            } else {
              this.setState({ _childBrands: [] });
            }
          }}
        />
        <Col
          xs={12}
          md={{
            size: this.props.edit ? 6 : 6,
            offset: this.props.edit ? 0 : 3
          }}
        >
          <Card>
            <CardHeader>
              <i className="fa fa-align-justify"></i> {this.props.title}{" "}
              {this.props.edit ? (
                <Link
                  to="/product_variants/create"
                  title="Create New Variant"
                  className="text-right"
                >
                  Create New
                </Link>
              ) : null}
            </CardHeader>
            <CardBody>
              {this.renderInfo()}
              <Detail
                isLoading={this.props.productVariant.isFetch}
                isMaxEditorsChoice={this.state._isMaxEditorsChoice}
                usingDelete={this.state._hasId}
                isSubmitProgress={this.props.productVariant.isSubmit}
                products={this.props.productOptions}
                productVariant={this.props.productVariant.detail}
                error={this.props.productVariant.error}
                onDelete={() => this.toggle("_modalDelete")}
                onSubmit={this.onSubmit}
                // setEditorsChoice={(bool) => this.setState({_form:{...this.state._form, editors_choice:bool}})}
                setEditorsChoice={this.handleEditorsChoiceChange}
              />
            </CardBody>
          </Card>
          {
            this.state._edit? (
              <div>
                <ProductVariantCollections
                  productIsFetch={this.props.productVariant.isFetch}
                  productVariantId={this.props.match.params.id}
                  dataSource={
                    this.props.productVariant.detail.status_code === 200 &&
                    this.props.productVariant.detail.collection_variants !==
                      undefined
                      ? this.props.productVariant.detail.collection_variants
                      : []
                  }
                />
                <ProductVariantVouchers
                  productIsFetch={this.props.productVariant.isFetch}
                  productVariantId={this.props.match.params.id}
                  dataSource={
                    this.props.productVariant.detail.status_code === 200 &&
                    this.props.productVariant.detail.variant_vouchers !==
                      undefined
                      ? this.props.productVariant.detail.variant_vouchers
                      : []
                  }
                />
              </div>
            ): null
          }
        </Col>
        <Col xs={12} md={6} className={!this.state._edit ? "d-none" : ""}>
          <Row>
            <Col xs={12}>
              <Subsidy
                isFecth={this.props.productVariant.subsidy.isFetch}
                productVariantId={this.props.match.params.id}
                getSubsidyList={this.props.getSubsidyList}
                dataSubsidy={this.props.productVariant.subsidy}
                saveSubsidy={this.props.saveSubsidy}
                onDelete={this.props.deleteSubsidy}
              />
            </Col>
            <Col xs={12}>
              <Commission
                isFecth={this.props.productVariant.isFetch}
                productVariantId={this.props.match.params.id}
                getCommissionList={this.props.find}
                dataCommission={this.props.productVariant.commission}
                saveCommission={this.props.saveCommission}
                onDelete={this.props.deleteCommission}
              />
            </Col>
            <Col xs={12}>
              <ProductVariantPaymentMethods
                isLoading={this.props.productVariant.isFetch}
                productVariantId={this.props.match.params.id}
                isSubmit={this.props.productVariant.isSubmit}
                paymentMethods={this.state._paymentMethods}
                onTogglePaymentMethod={(payloads) => this.setState({
                  _paymentMethods:payloads
                })}
                saveVariantPaymentMethod={this.props.saveVariantPaymentMethod}
              />
            </Col>
            <Col xs={12}>
              <ProductVariantCouriers
                isLoading={this.props.productVariant.isFetch}
                isSubmit={this.props.productVariant.isSubmit}
                shippingRates={this.state._shippingRates}
                openShippingRateModal={(payloads) => this._toggleShippingRateModal(true, payloads)}
              />
            </Col>
            <Col xs={12}>
              <ProductVariantSizes
                isLoading={this.props.productVariant.isFetch}
                isSubmit={this.props.productVariant.isSubmit}
                sizes={this.props.productVariant.detail.sizes}
                onOpenBrowseAllSizeModal={this.handleCloseBrowseSizeModal}
                onDetachSizes={payload =>
                  this.props.detachSize(this.state._form.id, payload)
                }
                // isLoading={this.props.productVariant.isFetch}
              />
            </Col>
          </Row>
        </Col>
        <ProductVariantImages
          isLoading={
            this.props.productVariant.isFetch ||
            this.props.productVariant.detail.status_code !== 200
          }
          isShow={this.state._edit}
          images={this.state._images}
          imgUrl={this.state._img_url}
          selectImage={this.state._selectImage}
          onSortEnd={this.onSortEnd}
          onDrop={this.onDropImage.bind(this)}
          onRemoveImage={this.handleRemoveImage}
          onUploadImages={this.handleUploadImages}
          onSelectImage={this.handleSelectImage}
          onInputChange = {this.handleOnChangeInput}
          onSubmit={this.handleSubmitImgrUrl}
        />
      </Row>
    );
  }
}

const mapStateToProps = state => ({
  productVariant: state.productVariant,
  // masterCollection:state.masterCollection,
  masterSize: state.size,
  productOptions: state.product.options,
  imageUpload: state.imageUpload,
  brand: state.masterBrand,
  paymentMethods: state.setting.paymentMethods.data
});

const mapDispatchToProps = dispatch => ({
  find: id => dispatch(getDetailProductVariant(id)),
  delete: id => dispatch(deleteProductVariant(id)),
  save: (payload, id) => dispatch(saveProductVariant(payload, id)),
  new: () => dispatch(newProductVariant()),
  getSizeList: query => dispatch(getSizeList(query)),
  getBrandSizeList: query => dispatch(getBrandSizeList(query)),
  attachSize: (id, payload) => dispatch(attachSize(id, payload)),
  detachSize: (id, payload) => dispatch(detachSize(id, payload)),
  upload: (file, folder) => dispatch(upload(file, folder)),
  saveImage: (identifier, payload) => dispatch(addImage(identifier, payload)),
  removeImage: (identifierImg, id) => dispatch(deleteImage(identifierImg, id)),
  deleteObjectS3: (uniqueID, url) => dispatch(deleteObjectS3(uniqueID, url)),
  clearImageUpload: () => dispatch(clearImageUpload()),
  bindBrandOption: payload => dispatch(getBrandList(payload)),
  uploadImage: (payload, rawImages) => dispatch(uploadImage(payload, rawImages)),
  deleteImageS3: (payload) => dispatch(deleteImageS3(payload)),
  saveVariantCourier: (id, payload) => dispatch(saveVariantCourier(id, payload)),
  saveVariantPaymentMethod: (id, payload) => dispatch(saveVariantPaymentMethod(id, payload)),
  saveSubsidy: (id,payload) => dispatch(saveSubsidy(id, payload)),
  saveCommission: (id,payload) => dispatch(saveCommission(id, payload)),
  deleteSubsidy: id => dispatch(deleteSubsidy(id)),
  deleteCommission: id => dispatch(deleteCommission(id)),
  getSubsidyList: query => dispatch(getSubsidyList(query)),
  updateImagePositions: payloads => dispatch(updateImagePositions(payloads)),
  getSettingPaymentMethod: () => dispatch(getSettingPaymentMethod()),
});
export default connect(mapStateToProps, mapDispatchToProps)(ProductVariantForm);
ProductVariantForm.propTypes = {
  edit: PropTypes.bool,
  readOnly: PropTypes.bool,
  title: PropTypes.string
};
