import React from 'react';
import { Link } from 'react-router-dom';
import {
  Card,
  CardHeader,
  CardBody,
  Badge,
  Dropdown, DropdownToggle, DropdownMenu, DropdownItem
} from 'reactstrap';
import classNames from 'classnames';
import Loading from 'react-loading-animation';
import propTypes from 'prop-types';
import { _getValue } from '../../../../../utils/form';
import { TextRupiah } from '../../../../../components/Text';
import { BadgeFlexContainer, BadgeMedium } from '../../../../../styled/Badge';
import {
  FieldRow,
  FieldColumn,
  FieldLabel,
  FieldValue
} from '../../../../../styled/Fields';
import AlertNotFound from '../../../../../components/Card/AlertNotFound';

const SellDetailCard = props =>(
  <Card>
    <CardHeader>
      { props.renderHeaderText? props.renderHeaderText(): props.headerText }
    </CardHeader>
    <CardBody>
      {
        props.isLoading?
        <Loading/>:
        (
          props.isEmptyData?
          <AlertNotFound message="Seller listing data not found!"/>
          :
          <div>
            <FieldRow>
              <FieldColumn className="col-12" style={{display: 'flex', justifyContent: 'flex-end'}}>
                <Dropdown
                  isOpen={props.dropdownSellOpen}
                  toggle={props.toggleDropdownSell}
                >
                  <DropdownToggle caret>
                    More...
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem>
                      <Link to={`/sell_request/${props.id}`} title="Go to Sell & Consignment Request Details...">Go to Sell Request</Link>
                    </DropdownItem>
                    <DropdownItem>
                      <Link to={`/product_variants/${props.product_variant_id}`} title="Go to Variant Info Details...">Go to Variant</Link>
                    </DropdownItem>
                    <DropdownItem>
                      <Link to={`/users/${props.user_id}`} title="Go to Seller Info Details...">Go to Seller</Link>
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </FieldColumn>
            </FieldRow>
            <FieldRow>
              <FieldColumn className="col-xs-12 col-md-6">
                <FieldLabel>
                  Sell ID <Link to={`/sell_request/${props.id}`} title="Go to Sell & Consignment Request Details...">See more...</Link>
                </FieldLabel>
                <FieldValue>
                  <span>{props.id}</span>
                </FieldValue>
              </FieldColumn>
            </FieldRow>
            <FieldRow>
              <FieldColumn className="col-12">
                <FieldLabel>
                  Selling By
                </FieldLabel>
                <FieldValue>
                  <span>{props.user.email}</span>
                </FieldValue>
              </FieldColumn>
              <FieldColumn className="col-xs-12 col-md-6">
                <FieldLabel>
                  Asking Price
                </FieldLabel>
                <FieldValue>
                  <TextRupiah prefix="Rp. " value={props.asking_price}/>
                </FieldValue>
              </FieldColumn>
              <FieldColumn className="col-xs-12 col-md-6">
                <FieldLabel>
                  Currency
                </FieldLabel>
                <FieldValue>
                  <span>{ props.currency }</span>
                </FieldValue>
              </FieldColumn>
              <FieldColumn className="col-xs-12 col-md-6">
                <FieldLabel>
                  Box Condition
                </FieldLabel>
                <FieldValue>
                  <span>{_getValue(props.box_condition)}</span>
                </FieldValue>
              </FieldColumn>
              <FieldColumn className="col-xs-12 col-md-6">
                <FieldLabel>
                  Sneaker Condition
                </FieldLabel>
                <FieldValue>
                  <span>{_getValue(props.sneakers_condition)}</span>
                </FieldValue>
              </FieldColumn>
              <FieldColumn className="col-12">
                <BadgeFlexContainer>
                  <BadgeMedium>
                    <Badge color={(props.pre_order?"success":"default")}>
                      <i
                        className={
                          classNames("fa",{
                          "fa-times":!props.pre_order,
                          "fa-check":props.pre_order})
                          }
                      >
                      </i>
                      &nbsp; PRE-ORDER
                    </Badge>
                  </BadgeMedium>
                  <BadgeMedium>
                    <Badge color={(props.pre_verified?"success":"default")}>
                      <i
                        className={
                          classNames("fa",{
                            "fa-times":!props.pre_verified,
                            "fa-check":props.pre_verified})
                          }
                      >
                      </i>
                      &nbsp; PRE-VERIFIED
                    </Badge>
                  </BadgeMedium>
                  <BadgeMedium>
                    <Badge color={(props.missing_accessories?"success":"default")}>
                      <i
                        className={
                          classNames("fa",{
                            "fa-times":!props.missing_accessories,
                            "fa-check":props.missing_accessories})
                          }
                      >
                      </i>
                      &nbsp; MISS ACCESSORIES
                    </Badge>
                  </BadgeMedium>
                  <BadgeMedium>
                    <Badge color={(props.yellowing?"success":"default")}>
                      <i
                        className={
                          classNames("fa",{
                            "fa-times":!props.yellowing,
                            "fa-check":props.yellowing})
                          }
                      >
                      </i>
                      &nbsp; YELLOWING
                    </Badge>
                  </BadgeMedium>
                  <BadgeMedium>
                    <Badge color={(props.display_item?"success":"default")}>
                      <i
                        className={
                          classNames("fa",{
                            "fa-times":!props.display_item,
                            "fa-check":props.display_item})
                          }
                      >
                      </i>
                      &nbsp; DISPLAY ITEM
                    </Badge>
                  </BadgeMedium>
                  <BadgeMedium>
                    <Badge color={(props.sneakers_defect?"success":"default")}>
                      <i
                        className={
                          classNames("fa",{
                            "fa-times":!props.sneakers_defect,
                            "fa-check":props.sneakers_defect})
                          }
                      >
                      </i>
                      &nbsp; SNEAKER DEFECT
                    </Badge>
                  </BadgeMedium>
                </BadgeFlexContainer>
              </FieldColumn>
              <FieldColumn className="col-12">
                <FieldLabel>
                  Note
                </FieldLabel>
                <FieldValue>
                  <span>
                    {_getValue(props.note)}
                  </span>
                </FieldValue>
              </FieldColumn>
              <FieldColumn className="col-xs-12 col-md-6">
                <FieldLabel>
                  Status
                </FieldLabel>
                <FieldValue>
                  <span>
                    {props.status}
                  </span>
                </FieldValue>
              </FieldColumn>
              <FieldColumn className="col-xs-12 col-md-6">
                <FieldLabel>
                  Last Updated
                </FieldLabel>
                <FieldValue>
                  <span>
                    {props.updated_at}
                  </span>
                </FieldValue>
              </FieldColumn>
            </FieldRow>
          </div>
        )
      }
    </CardBody>
  </Card>
)

export default SellDetailCard;

SellDetailCard.propTypes={
  renderHeaderText: propTypes.func,
  headerText: propTypes.oneOfType([propTypes.string, propTypes.node]),
  isLoading: propTypes.bool,
  id: propTypes.number,
  user: propTypes.object,
  asking_price: propTypes.oneOfType([propTypes.string, propTypes.number]),
  status: propTypes.string,
  box_condition: propTypes.string,
  sneakers_condition: propTypes.string,
  pre_order: propTypes.oneOfType([propTypes.bool, propTypes.number]),
  pre_verified: propTypes.oneOfType([propTypes.bool, propTypes.number]),
  updated_at: propTypes.string,
  sneakers_defect: propTypes.oneOfType([propTypes.bool, propTypes.number]),
  missing_accessories: propTypes.oneOfType([propTypes.bool, propTypes.number]),
  yellowing: propTypes.oneOfType([propTypes.bool, propTypes.number]),
  display_item: propTypes.oneOfType([propTypes.bool, propTypes.number]),
}
SellDetailCard.defaultProps={
  headerText:"Sell Details",
  isLoading: false,
  id: 0,
  user: {
    id: 0,
    email: ''
  },
  asking_price: 0,
  status: 'draft',
  sneakers_defect: false,
  missing_accessories: false,
  yellowing: false,
  display_item: false,
}