import React, { PureComponent as  Component } from 'react';
import moment from 'moment';
import {
  Col,
  Label,
  Form,
  Alert,
  FormGroup,
  Input,
  InputGroup,
  Row} from 'reactstrap';
import Select from 'react-select';
import propTypes from 'prop-types';
import classNames from 'classnames';
import { ButtonLoading } from '../../../components/Button';
import InputDatetime from '../../../components/Form/InputDatetime/InputDatetime';
import { dateChange } from '../../../utils/form';

export default class ExportForm extends Component{
  constructor(props){
    super(props);
    this.state={
      keyword:'',
      category:null,
      started_at:null,
      ended_at:null,
      quantity: true,
      pre_verified: true,
      not_expired: true,
      _error: null,
      _successMessage: null,
      _errorMessage: null,
      _alertSuccess: false,
      _alertError: false,
      _alertValidate: false,
      _formSubmit: false
    }
    this._onSubmit=this._onSubmit.bind(this);
    this._handleChange=this._handleChange.bind(this);
    this._handleCheckedChange=this._handleCheckedChange.bind(this);
    this._onSelectChange=this._onSelectChange.bind(this);
  }


  componentWillReceiveProps(nextProps){
    if(nextProps.data.status_code===200 && nextProps.data.status_code!==this.props.data.status_code
      && nextProps.data.error===null){
        this.setState({
          _successMessage: nextProps.data.message,
          _alertSuccess: true,
          _formSubmit: false
        })
    }
    if(nextProps.data.error!==this.props.data.error && nextProps.data.status_code!==200
      && nextProps.data.error!==null){
      const { error } = nextProps.data;
      if(nextProps.data.status_code===422&&nextProps.data.message==='422 Unprocessable Entity'){
        this.setState({
          _errorMessage : error.errors.date,
          _alertError : true,
          _formSubmit: false
        })
      }
      else{
        this.setState({
          _errorMessage : error.message,
          _alertError : true,
          _formSubmit: false
        })
      }
    }
  }

  _handleCheckedChange(e){
    const lastState = {...this.state};
    lastState[e.target.name] = e.target.checked;
    this.setState({...lastState});
  }

  _onSelectChange(val){
    this.setState({category:val});
  }

  handleDatePickerChange(value,name){
    const _form=dateChange(value,name,{...this.state},'YYYY-MM-DD');
    this.setState({..._form})
  }

  _validateForm(){
    const {_error} = this.state;
    let errors={};
    if(!this.state.started_at)
        errors['started_at']=['Harap isi Awal Tanggal.'];
    if(!this.state.ended_at)
        errors['ended_at']=['Harap isi Akhir Tanggal.'];
    if(Object.keys(errors).length){
        this.setState({
            _alertValidate:true,
            _error:{errors:{...errors}, status_code:422,message:'Harap isi semua column'}
        })
    }
    return errors;
  }

  removeAlerts(){
    this.setState({
      _error: null,
      _alertError:false,
      _alertSuccess:false,
      _alertValidate:false,
      _successMessage: null,
      _errorMessage: null
    });
  }

  _onSubmit(e){
    e.preventDefault();
    this.removeAlerts();
    // const valid= this._validateForm();
    // if(Object.keys(valid).length===0){
      const {started_at, ended_at, keyword, pre_verified, quantity, not_expired, category} = this.state;
      const started = started_at ? moment(started_at).format('YYYY-MM-DD') : null;
      const ended = ended_at ? moment(ended_at).format('YYYY-MM-DD') : null;
      // let payload={
      //   'start_date': started,
      //   'end_date': ended,
      //   'pre_verified':true
      // }
      // if(this.state.keyword){
      const payload={
        'start_date': started,
        'end_date': ended,
        'keyword': keyword,
        pre_verified,
        quantity,
        not_expired,
        'category' : category?category.label:category
      }
      // }
      this.setState({'_formSubmit':true},()=>this.props.onSubmit(payload));
    // }
  }

  toggleAlert(key){
    const lastState = {...this.state};
    lastState[key] = !lastState[key];
    this.setState({...lastState, _formSubmit: false,...this.clearForm()});
  }

  clearForm(){
    return {
      keyword:'',
      started_at:null,
      ended_at:null,
      _error: null,
      _successMessage: null,
      _errorMessage: null,
      _alertSuccess: false,
      _alertError: false,
      _alertValidate: false,
      _formSubmit: false
    };
  }

  _handleChange(e){
    this.setState({
      keyword:e.target.value
    })
  }

  _renderAlerts(){
    const {_error} = this.state;
    if(_error!==null&&this.state._alertValidate){
      switch(_error.status_code){
        case 422:
            return <Alert color="danger" isOpen={this.state._alertValidate} toggle={()=>this.toggleAlert('_alertValidate')}>Please fulfill your form</Alert>
        default:
            return <Alert color="danger" isOpen={this.state._alertValidate} toggle={()=>this.toggleAlert('_alertValidate')}>Houston, we have a problem!. Please inform our friendly staff via LINE @kickavenue</Alert>
      }
    }
    else{
      return(
        <div>
          <Alert color="success" isOpen={this.state._alertSuccess} toggle={()=>this.toggleAlert('_alertSuccess')}>
            <strong>Well done!</strong> { this.state._successMessage }
          </Alert>
          <Alert color="danger" isOpen={this.state._alertError} toggle={()=>this.toggleAlert('_alertError')}>
            <strong>Oh Snap!</strong> { this.state._errorMessage }
          </Alert>
        </div>
      )
    }
  }

  hasError(name){
    if(this.state._error!==null&&this.state._error.status_code===422){
      return Object.keys(this.state._error.errors).filter(err=>err===name).length>0;
    }
    return false;
  }

  render(){
    return(
      <div>
        {this._renderAlerts()}
        <Form onSubmit={this._onSubmit} className="form-filter">
          <Row>
            <Col xs={12} md={6}>
              <Row>
                <Col xs={12} md={6}>
                  <FormGroup className={classNames({'has-danger has-feedback':this.hasError('started_at')})}>
                    <Label for="formStartedAt" className="mr-sm-3">Started At</Label>
                    <InputDatetime
                      name="started_at"
                      inputProps={{placeholder:'Pick a Start At', id:'formStartedAt'}}
                      value={this.state.started_at}
                      dateFormat={('YYYY-MM-DD')}
                      timeFormat={false}
                      onChange={(date)=>this.handleDatePickerChange(date,'started_at')}
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} md={6}>
                  <FormGroup className={classNames({'has-danger has-feedback':this.hasError('ended_at')})}>
                    <Label for="formEndedAt" className="mr-sm-3">End At</Label>
                    <InputDatetime
                      name="ended_at"
                      inputProps={{placeholder:'Pick a End At', id:'formEndedAt'}}
                      value={this.state.ended_at}
                      dateFormat={('YYYY-MM-DD')}
                      timeFormat={false}
                      onChange={(date)=>this.handleDatePickerChange(date,'ended_at')}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={3} md={3}>
              <FormGroup className={classNames({'has-danger has-feedback':this.hasError('category')})}>
                <Label for="SelectCategoryName"className="mr-sm-3">
                  Category
                </Label>
                <Select
                  id="formCategoryName"
                  isClearable={false}
                  name="category"
                  value={this.state.category}
                  options={this.props.categoryOptions.options.length?this.props.categoryOptions.options:[{value:'',label:'Loading...'}]}
                  onChange={(val)=>{this._onSelectChange(val)}}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <div className="d-flex" style={{padding: "0px 1rem"}}>
              <FormGroup className="d-flex" style={{flexDirection: 'column', alignItems: 'flex-start'}}>
                <Label className="mb-2">
                  Sneakers Condition
                </Label>
                <div className="d-flex ml-2 mr-2">
                  <FormGroup check className="mr-2">
                    <Label check>
                      <Input type="checkbox" name="not_expired" value="not_expired" checked={this.state.not_expired} onChange={this._handleCheckedChange} /> Only Not Expired
                    </Label>
                  </FormGroup>
                  <FormGroup check className="mr-2">
                    <Label check>
                      <Input type="checkbox" name="pre_verified" value="pre_verified" checked={this.state.pre_verified} onChange={this._handleCheckedChange} /> Only Pre verified
                    </Label>
                  </FormGroup>
                  <FormGroup check className="mr-2">
                    <Label check>
                      <Input type="checkbox" name="quantity" value="quantity" checked={this.state.quantity} onChange={this._handleCheckedChange} /> Only Available Stock
                    </Label>
                  </FormGroup>
                </div>
              </FormGroup>
            </div>
          </Row>
          <Row>
            <Col xs={12} md={6}>
              <InputGroup>
                <Input
                  type="text"
                  placeholder="Search rack, display name or seller email..."
                  name="keyword"
                  value={this.state.keyword}
                  onChange={this._handleChange}
                />
                <ButtonLoading isLoading={this.state._formSubmit}>Export</ButtonLoading>
              </InputGroup>
            </Col>
          </Row>
        </Form>
      </div>
    )
  }
}

ExportForm.propTypes= {
  onSubmit: propTypes.func.isRequired
}
