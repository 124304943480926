import React, {PureComponent as Component} from 'react';
import { Card,CardText } from 'reactstrap';
import propTypes from 'prop-types';

import {Tbl, TblHead, TblBody} from '../../../components/Tbl';
import { TextRupiah } from '../../../components/Text';
import DateToHuman from '../../../components/DateToHuman';

const dataHeader=['No','Name','Date','Amount', 'Updated'];
export default class TabPaymentConfirmation extends Component{
  onRenderItems(){
    return this.props.data.map((item,index)=>
      (
        <tr key={index}>
          <th scope="row">{ index+1 }</th>
          <td>{ item.name }</td>
          <td><DateToHuman  value={item.transfer_date} id={`tooltip_paymentDetail_paymentConfirmations_transferdAt_${index}`}/></td>
          <td><TextRupiah prefix="Rp. " value={item.transfer_amount}/></td>
          <td><DateToHuman  value={item.updated_at} id={`tooltip_paymentDetail_paymentConfirmations_updatedAt_${index}`}/></td>
        </tr>
      )
    )
  }
  render(){
    return(
      <Card body className="tab-content--custom__card">
        <Card body>
          <CardText>
            About their payment confirmation only using for <strong>Bank Transfer</strong> payment method.
          </CardText>
          <div className="table-responsive">
              <Tbl>
                <TblHead dataHeader={dataHeader}/>
                <TblBody
                  isLoad={this.props.isLoading}
                  hasRow={this.props.data.length>0}
                  columnCount={dataHeader.length}
                >
                  {this.onRenderItems()}
                </TblBody>
              </Tbl>
          </div>
        </Card>
      </Card>
    )
  }
}
TabPaymentConfirmation.propTypes={
  data: propTypes.array,
  isLoading: propTypes.bool
}
TabPaymentConfirmation.defaultProps={
  data:[],
  isLoading: false
}