import {
  AUTH_TOKEN_KEY,
  OPTION_ADD,
  OPTION_DELETE,
  OPTION_DETAIL_FETCH,
  OPTION_ERROR,
  OPTION_FETCHING,
  OPTION_LIST_FETCH,
  OPTION_ROUTE_API,
  OPTION_UPDATE,
} from "../constants";
import api from "../utils/Api";
import { manipulateErrors, checkAuthorized } from "../utils";
import { logout } from "./authAction";

/**
 * Indicates when start fetch api or end fetch api
 * @author haikal
 * @param {boolean} bool
 */
export const isFetch = (payload) => ({
  type: OPTION_FETCHING,
  payload: payload,
});

/**
 * When onSuccess triggered
 * @author haikal
 * @param {object} res
 * @param {string} type
 */
export const onSuccess = (res, type) => ({
  type: type,
  payload: res,
});
/**
 * When error fetch or connect to api
 * @author haikal
 * @param {object} err
 */
// On Error fetching api
export const onError = (err) => {
  if (!checkAuthorized(manipulateErrors(err))) return logout();
  return {
    type: OPTION_ERROR,
    payload: manipulateErrors(err),
  };
};

export const getOptionlList = (query) => {
  return (dispatch) => (
    dispatch(isFetch({ type: "fetch", status: true })),
    api({
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`,
    })
      .get(OPTION_ROUTE_API, query)
      .then((res) => {
        dispatch(onSuccess(res.data, OPTION_LIST_FETCH));
        dispatch(isFetch({ type: "fetch", status: false }));
      })
      .catch((err) => {
        dispatch(onError(err));
        dispatch(isFetch({ type: "fetch", status: false }));
      })
  );
};

export const getDetailOption = (id) => {
  return (dispatch) => (
    dispatch(isFetch({ type: "detail", status: true })),
    api({
      Authorization: "Bearer " + localStorage.getItem(AUTH_TOKEN_KEY),
    })
      .get(`${OPTION_ROUTE_API}/${id}`)
      .then((res) => {
        dispatch(onSuccess(res.data, OPTION_DETAIL_FETCH));
        dispatch(isFetch({ type: "detail", status: false }));
      })
      .catch((err) => {
        dispatch(onError(err));
        dispatch(isFetch({ type: "detail", status: false }));
      })
  );
};

export const saveOption = (payload, id) => {
  return (dispatch) => {
    if (id === undefined) dispatch(createOption(payload));
    else dispatch(updateOption(id, payload));
  };
};

export const createOption = (payload) => {
  return (dispatch) => (
    dispatch(isFetch({ type: "submit", status: true })),
    api({
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`,
    })
      .post(`${OPTION_ROUTE_API}`, payload)
      .then((res) => {
        dispatch(onSuccess(res.data, OPTION_ADD));
        dispatch(isFetch({ type: "submit", status: false }));
      })
      .catch((err) => {
        dispatch(onError(err));
        dispatch(isFetch({ type: "submit", status: false }));
      })
  );
};

export const updateOption = (id, payload) => {
  return (dispatch) => (
    dispatch(isFetch({ type: "detail", status: true })),
    api({
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`,
    })
      .put(`${OPTION_ROUTE_API}/${id}`, payload)
      .then((res) => {
        dispatch(onSuccess(res.data, OPTION_UPDATE));
        dispatch(isFetch({ type: "detail", status: false }));
      })
      .catch((err) => {
        dispatch(onError(err));
        dispatch(isFetch({ type: "detail", status: false }));
      })
  );
};

export const deleteOption = (id) => {
  return (dispatch) => (
    dispatch(isFetch({ type: "detail", status: true })),
    api({
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`,
    })
      .delete(`${OPTION_ROUTE_API}/${id}`)
      .then((res) => {
        dispatch(onSuccess(res.data, OPTION_DELETE));
        dispatch(isFetch({ type: "detail", status: false }));
      })
      .catch((err) => {
        dispatch(onError(err));
        dispatch(isFetch({ type: "detail", status: false }));
      })
  );
};
