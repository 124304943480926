import {
    ROLE_FETCHING,
    ROLE_DETAIL_FETCH,
    ROLE_LIST_FETCH,
    ROLE_ERROR,
	ROLE_ADD,
	ROLE_UPDATE,
    ROLE_DELETE,
    ROLE_CLEAR_FORM
} from '../constants';
const initialState={
	list:[],
	detail:{},
	isFetch:false,
	isSubmit:false,
	error:null
}

const onFetching = (lastState,payload) => {
    if(payload.type==='fetch')
        return { ...lastState, isFetch:payload.status }
    else if(payload.type==='submit')
        return { ...lastState, isSubmit:payload.status }

    return { ...lastState, isFetch:false,isSubmit:false }
}

const onAddRole=(lastState,payload)=>{
	const {data, message, status_code}=payload;
    return { ...lastState, detail:{ ...data, message, status_code }, success:'Insert has been successfully!',error:null }
}

const onUpdateRole=(lastState,payload)=>{
	const {data, message, status_code}=payload;
    return { ...lastState, detail:{ ...data, message, status_code }, success:'Update has been successfully!',error:null }
}

const onDeleteRole=(lastState,payload)=>{
	const {data, message, status_code}=payload;
    return { ...lastState, detail:{ ...data, message, status_code, delete:true }, success:'Delete has been successfully!',error:null }
}

const reducer=(state=initialState, action)=>{
    switch(action.type){
        case ROLE_LIST_FETCH:{
            const {data, status_code, message}=action.payload;
            return {...state, list:{...data, message, status_code}, error:null, success:null }
        }
        case ROLE_DETAIL_FETCH:{
            const {data, status_code, message}=action.payload;
            return { ...state, detail:{...data, message, status_code}, error:null, success:null }
        }
        case ROLE_FETCHING:  return onFetching(state, action.payload);      
        case ROLE_ERROR:
            return { ...state, ...action.payload }
        case ROLE_ADD: return onAddRole(state, action.payload);
        case ROLE_UPDATE: return onUpdateRole(state, action.payload);
        case ROLE_DELETE: return onDeleteRole(state, action.payload);
        case ROLE_CLEAR_FORM:return {...initialState};
        default:return state;
    }
}
export default reducer;