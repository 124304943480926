import React from 'react';
import propTypes from 'prop-types';
import classNames from 'classnames';
const Radio = props  => {
  const { wrapperClassName, className, dataOnText, dataOffText, radioType, usingLabelText, size, style = {}, ...inputProps } = props;
  const switchType = `switch-${radioType}`;
  let switchSize = '';
  if(size !== null && size !== ''){
    switchSize = `switch-${size}`;
  }
  return (
    <label style={{...style}} className={classNames("switch switch-default", switchType, switchSize, wrapperClassName)}>
      <input
        type="checkbox"
        className={classNames("switch-input", className)}
        {...inputProps}
      />
      {
        usingLabelText?(
          <span className="switch-label" data-on={dataOnText} data-off={dataOffText}></span>
        ): <span className="switch-label"></span>
      }
      <span className="switch-handle"></span>
      </label>
  )
}

export default Radio;

Radio.propTypes = {
  wrapperClassName: propTypes.string,
  className: propTypes.string,
  dataOnText: propTypes.string,
  dataOffText: propTypes.string,
  radioType: propTypes.string,
  size: propTypes.string,
  usingLabelText: propTypes.bool
}

Radio.defaultProps = {
  wrapperClassName: "switch-success-outline-alt",
  dataOnText: "On",
  dataOffText: "Off",
  usingLabelText: true,
  radioType: "text",
  size: null
}
