import {
  GET_LIST_SKU_REQUEST,
  GET_DETAIL_SKU_REQUEST,
  DELETE_SKU_REQUEST,
  UPDATE_SKU_REQUEST,
  CREATE_SKU_REQUEST,
  SKU_REQUEST_ERROR,
  SKU_REQUEST_REQUEST
} from '../constants';


const initialState = {
  list: {
    data: {
      data:[]
    },
    error: null,
    isFetch: false
  },
  details: {
    id: 0,
    error: null,
    isFetch: false,
    isSubmit: false
  }
}

const onFetched = (lastState, { requestType, value }) => {
  switch(requestType){
    case GET_LIST_SKU_REQUEST: {
      return {
        ...lastState,
        list: {
          ...lastState.list,
          isFetch: value
        }
      }
    }
    case GET_DETAIL_SKU_REQUEST: {
      return {
        ...lastState,
        details: {
          ...lastState.details,
          isFetch: value
        }
      }
    }
    case CREATE_SKU_REQUEST:
    case UPDATE_SKU_REQUEST:
    case DELETE_SKU_REQUEST: {
      return {
        ...lastState,
        details: {
          ...lastState.details,
          isSubmit: value
        }
      }
    }
    default: return { ...lastState }
  }
}

const onError = (lastState, { requestType, error, ...rest }) => {
  switch(requestType){
    case GET_LIST_SKU_REQUEST:{
      return {
        ...lastState,
        list: {
          ...lastState.list,
          error: { ...error, ...rest }
        }
      }
    }
    case GET_DETAIL_SKU_REQUEST:
    case CREATE_SKU_REQUEST:
    case UPDATE_SKU_REQUEST:
    case DELETE_SKU_REQUEST:{
      return {
        ...lastState,
        details: {
          ...lastState.details,
          error: { ...error, ...rest }
        }
      }
    }
    default: return { ...lastState }
  }
}

const reducer = (state = initialState, { type, payload }) => {
  switch(type){
    case SKU_REQUEST_REQUEST: return onFetched(state, payload);
    case GET_LIST_SKU_REQUEST:{
      return {
        ...state,
        list: {
          ...state.list,
          ...payload,
          error: null
        }
      }
    }
    case CREATE_SKU_REQUEST:
    case UPDATE_SKU_REQUEST:
    case DELETE_SKU_REQUEST:
    case GET_DETAIL_SKU_REQUEST: {
      return {
        ...state,
        details: {
          ...state.details,
          ...payload,
          error: null
        }
      }
    }
    case SKU_REQUEST_ERROR: return onError(state, payload);
    default: return { ...state }
  }
}

export default reducer;