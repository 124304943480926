import React, { Component } from 'react';
import MaintenanceIcon from '../../../assets/img/503.png';

class Page503 extends Component {
  render() {
    return (
      <div className="app flex-row align-items-center">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-6 d-flex align-items-center flex-column">
              <img style={{width: 350}} src={MaintenanceIcon} alt="Maintenance Icon"/>
              <div className="clearfix w-100">
                <h1 className="float-left display-3 mr-4 pt-3">503</h1>
                <h4 className="pt-3">We are under maintenance</h4>
                <p className="text-muted">
                  Our website is currently undergoing scheduled maintenance.<br/>
                  We should be back shortly. Thank you for your patience.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Page503;
