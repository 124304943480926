import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { 
    Alert,
    Form,
    Label,
    Col, 
    Row,
    Input,
    FormGroup,
    ButtonGroup,
    Button,
    Card,
    CardBody,
    CardHeader,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter
} from 'reactstrap';

import { 
    getDetailAccountBank,
    saveAccountBank,
    deleteAccountBank,
    clearFormAccountBank
} from '../../actions/accountBankAction';

import Loading from 'react-loading-animation';

import { errorMessage, hasError, textValue, textChange } from '../../utils/form';
import { ButtonLoading } from '../../components/Button';

class AccountBankForm extends Component{
    constructor(props){
        super(props);
        this.state={
            _edit:this.props.edit,
            _readOnly:this.props.readOnly,
            _hasId:this.props.match.params.id?true:false,
            _form:{
                binding:false,
                id:this.props.match.params.id,
                account_name:'',
                branch:'',
                account_number:'',
                bank_name:'',
                created_at:'',
                updated_at:''
            },
            _redirect:'/bank_accounts',
            _hasDeleted:false,
            _deleteProcess:false,
            _errors:[],
            _success:[],
            _formSubmit:false,
            _modalDelete:false
        }
        this.onSubmit=this.onSubmit.bind(this);
        this.onDelete=this.onDelete.bind(this);
        this.toggle=this.toggle.bind(this);
        this.handleChange=this.handleChange.bind(this);
    }

    componentDidMount()
    {
        if(this.state._hasId)
            this.props.find(this.state._form.id);
        else
            this.props.new();
    }

    componentWillReceiveProps(nextProps)
    {
        const {detail,isFetch,isSubmit,error} = nextProps.accountBank;
        if((!isFetch&&isFetch!==this.props.accountBank.isFetch)||(!isSubmit&&isSubmit!==this.props.accountBank.isSubmit)){
            // Edit Form
            if(this.state._hasId){
                // Checking if already ok after get detail from api
                if(detail.status_code===200&&error===null){
                    // Binding data when load page and after get detail from api
                    if(!this.state._form.binding){
                        const _form  ={ ...this.state._form };
                        _form.binding=true; 
                        this.setState({_form},()=>{
                            const _newForm  ={ 
                                ...this.state._form,
                                ...detail
                            };
                            this.setState({_form:_newForm});
                        });
                    }
                    else if(this.state._formSubmit){
                        const _form  ={ ...this.state._form };
                        this.setState({_formSubmit:!this.state._formSubmit},()=>{
                            const _errors = this.state._errors.filter(item=>item.key!=='accountBankForm');
                            const _newForm  ={ ...this.state._form,...detail };
                            const {_success}=this.state;
                            _success.push({key:'accountBankForm',message:nextProps.accountBank.success});
                            this.setState({_form:_newForm,_success,_errors});
                        });
                    }
                    else if(this.state._deleteProcess&&detail.delete===true){
                        this.setState({_hasDeleted:true,_deleteProcess:!this.state._deleteProcess});
                        if(this.state._modalDelete){
                            this.toggle();
                        }
                    }
                }
                else{
                    const _errors = this.state._errors.filter(item=>item.key!=='accountBankForm');
                    _errors.push({key:'accountBankForm',...error});
                    this.setState({_errors,_formSubmit:false,_deleteProcess:false});
                }
            }
            else{
                if(detail.status_code===200&&error===null){
                    if(this.state._formSubmit){
                        const {_success}=this.state;
                        const _errors = this.state._errors.filter(item=>item.key!=='accountBankForm');
                        _success.push({key:'accountBankForm',message:nextProps.accountBank.success});
                        this.props.new();
                        this.setState({_formSubmit:!this.state._formSubmit,_success,_errors});
                    }
                }
                else{
                    const _errors = this.state._errors.filter(item=>item.key!=='accountBankForm');
                    _errors.push({key:'accountBankForm',...error});
                    this.setState({_errors,_formSubmit:false});
                }
            }
        }
        else if(detail!==this.props.accountBank.detail){
            this.setState({_form:{
                ...this.state._form,
                account_name:'',
                branch:'',
                account_number:'',
                bank_name:'',
                created_at:'',
                updated_at:''
            }})
        }
    }

    onSubmit(e){
        e.preventDefault();
        this.setState({_formSubmit:true},()=>{
            const {id,account_name,branch,bank_name,account_number}= this.state._form;
            this.props.save({id,account_name,branch,bank_name,account_number});
        })
    }

    renderLoading(){
        return (
			<div className="row">
				<div className="col-sm-12">
                    <Alert color='info' className="text-center">Getting all data...</Alert>
				</div>
			</div>
		)
    }

    renderInfo(key){
        let infoMessage=[];
        const {_errors,_success}=this.state;
        if(this.state._formSubmit)
            infoMessage.push(
                <Alert color='info' key="info"><strong>Heads up!</strong> Submitting...</Alert>
            );
        if(_success.filter(item=>item.key==key).length)
            infoMessage.push(
                <Alert color='success' key="success"isOpen={true}
                toggle={()=>{
                    this.setState({_success: _success.filter(item=>item.key!=key)})
                }}><strong>Well done!</strong> { _success.filter(item=>item.key==key)[0].message }</Alert>
            );
        if(_errors.filter(item=>item.key==key).length)
        {
            const {status_code,message} = _errors.filter(item=>item.key==key)[0];
            switch(status_code)
            {
                case 422:
                    infoMessage.push(
                        <Alert color='danger' 
                        key="error422"   
                        isOpen={true} 
                        toggle={()=>{
                            this.setState({_errors: _errors.filter(item=>item.key!=key)})
                        }}>
                            <strong>Oh Snap!</strong> Please fullfill your form. 
                        </Alert>
                    );
                    break;
                case 404:
                    infoMessage.push(
                        <Alert color='danger' key="error404"  isOpen={true}
                        toggle={()=>{
                            this.setState({_errors: _errors.filter(item=>item.key!=key)})
                        }}><strong>Oh Snap!</strong> Resource not found. </Alert>
                    );
                    break;
                default:
                    infoMessage.push(
                        <Alert color='danger' key={`error${status_code}`} isOpen={true}
                        toggle={()=>{
                            this.setState({_errors: _errors.filter(item=>item.key!=key)})
                        }}><strong>Oh Snap!</strong> We've got something errors </Alert>
                    );
                    break;
            }
        }
        return(
            <div className="row">
                <div className="col-sm-12">
                    {infoMessage}   
                </div>
            </div>
        )
    }

    getErrorFormState(key){
        const filtered=this.state._errors.filter(item=>item.key===key);
        return filtered.length?{...filtered[0]}:null;
    }
    
    toggle(){
        this.setState({_modalDelete:!this.state._modalDelete});
    }

    onDelete(){
        this.props.delete(this.state._form.id);
        this.setState({_deleteProcess:true});
    }

    handleChange(e){
        const _form=textChange(e,this.state._form);
        this.setState({_form});
    }

    renderForm(){
        if(this.props.accountBank.isFetch)
            return <Loading/>
        return(
            <Form onSubmit={this.onSubmit}>
                <Row>
                    <Col xs={12} md={6}>
                        <FormGroup className={hasError('bank_name',this.getErrorFormState('accountBankForm'))?' has-danger has-feedback':''}>
                            <Label for="formBankName">Bank</Label>
                            <Input
                                type="text"
                                id="formBankName"
                                name="bank_name"
                                value={textValue('bank_name',this.state._form)}
                                onChange={this.handleChange}
                                placeholder="Example: BCA*"
                            />
                            {errorMessage('bank_name',this.getErrorFormState('accountBankForm'))}
                        </FormGroup>
                    </Col>
                    <Col xs={12} md={6}>
                        <FormGroup className={hasError('account_name',this.getErrorFormState('accountBankForm'))?' has-danger has-feedback':''}>
                            <Label for="formAccountName">Account Name</Label>
                            <Input
                                type="text"
                                id="formAccountName"
                                name="account_name"
                                placeholder="Enter a name*"
                                value={textValue('account_name',this.state._form)}
                                onChange={this.handleChange}
                            />
                            {errorMessage('account_name',this.getErrorFormState('accountBankForm'))}
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={6}>
                        <FormGroup className={hasError('branch',this.getErrorFormState('accountBankForm'))?' has-danger has-feedback':''}>
                            <Label for="formBranch">Branch</Label>
                            <Input
                                type="text"
                                id="formBranch"
                                name="branch"
                                value={textValue('branch',this.state._form)}
                                onChange={this.handleChange}
                                placeholder="Example: KCP. Alam Sutera*"
                            />
                            {errorMessage('branch',this.getErrorFormState('accountBankForm'))}
                        </FormGroup>
                    </Col>
                    <Col xs={12} md={6}>
                        <FormGroup className={hasError('account_number',this.getErrorFormState('accountBankForm'))?' has-danger has-feedback':''}>
                            <Label for="formAccountNumber">Account Number</Label>
                            <Input
                                type="text"
                                id="formAccountNumber"
                                name="account_number"
                                placeholder="Enter a account number*"
                                value={textValue('account_number',this.state._form)}
                                onChange={this.handleChange}
                            />
                            {errorMessage('account_number',this.getErrorFormState('accountBankForm'))}
                        </FormGroup>
                    </Col>
                </Row>
                <FormGroup>
                    <Label>Created At</Label>
                    <Input
                        type="text"
                        readOnly
                        value={this.state._form.created_at}
                    />                                    
                </FormGroup>
                <FormGroup>
                    <Label>Updated At</Label>
                    <Input
                        type="text"
                        readOnly
                        value={this.state._form.updated_at}
                    />                                    
                </FormGroup>
                <FormGroup>
                    <ButtonGroup>
                        <ButtonLoading isLoading={this.state._formSubmit}>Submit</ButtonLoading>
                        {
                            this.state._hasId?
                            <Button  color="danger" onClick={this.toggle}>Delete</Button>
                            :
                            ''
                        }
                    </ButtonGroup>
                </FormGroup>
            </Form>
        )
    }
    render(){
        if(this.state._hasDeleted===true)
            return <Redirect to={this.state._redirect}/>
        return(
            <Row>
                <Modal isOpen={this.state._modalDelete} toggle={this.toggle} className="modal-dialog modal-sm">
                    <ModalHeader toggle={this.toggle}>Confirmation</ModalHeader>
                    <ModalBody>
                        Are you sure to delete this?
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.onDelete}>Yes</Button>{' '}
                        <Button color="secondary" onClick={this.toggle}>Cancel</Button>
                    </ModalFooter>
                </Modal>
                <Col xs={12} md={{size:8, offset:2}}>
                    <Card>
                        <CardHeader>
                            <i className="fa fa-align-justify"></i> {this.props.title}
                        </CardHeader>
                        <CardBody>
                            { this.renderInfo('accountBankForm') }
                            { this.renderForm() }
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        )
    }
}
const mapStateToProps= (state) => {
	return {
		accountBank:state.accountBank
	}
};
const mapDispatchToProps = (dispatch) => {
	return{
		find:(id) => dispatch(getDetailAccountBank(id)),
		delete:(id) => dispatch(deleteAccountBank(id)),
		save:(payload) => dispatch(saveAccountBank(payload)),
        new:() => dispatch(clearFormAccountBank())
	}
};
export default connect(mapStateToProps,mapDispatchToProps)(AccountBankForm);