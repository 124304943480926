import React from 'react';
import { connect } from 'react-redux';
import {
  Alert,
  Row,
  Col,
  Card,
	TabContent,
	TabPane,
	Nav,
  NavItem,
	NavLink
} from 'reactstrap';
import qs from 'query-string';
import classnames from 'classnames';
import Header from './conciergeDetail/Header';
import Analytics from "../../../services/Analytics";
import privateView from '../../../components/hocs/privateView';
import TabConciergeDetail from './conciergeDetail/TabConciergeDetail';
import TabPaymentDetail from './conciergeDetail/TabPaymentDetail';
import TabSneakerDetail from './conciergeDetail/TabSneakerDetail';
import TabUserDetail from './conciergeDetail/TabUserDetail';
import TabSellerDetail from './conciergeDetail/TabSellerDetail';
import { getDetailConcierge, updateConcierge } from '../../../actions/conciergeAction';
import { doPostApplyVoucher, doPostShippingCost, doFetchProvinces } from '../../../actions/userShippingAction';

const DEFAULT_ERROR_500_MSG = 'We got something errors. Please ask our staff!';
const DEFAULT_ERROR_404_MSG = 'Sorry, cannot find what you are looking for.';

class ConciergeDetail extends React.Component {
  constructor(props){
    super(props)
    const queries = Object.assign({}, qs.parse(props.location.search));
    this.state = {
      _id: parseInt(this.props.match.params.id),
      activeTab: 'conciergeDetail',
      _error: null,
      _errorLoadData: false,
      _errorMsg: '',
      ...queries,
      _isLoading: true,
    }
  }

  componentDidMount(){
    const { email } = this.props.injectedProps;
    this.props.getDetail(this.state._id)
    this.props.getProvinces();
    Analytics.recordPageView('concierge_detail', email, {
      url: window.location.href,
      location: this.props.location,
      match: this.props.match
    });
  }

  componentDidUpdate(prevProps){
    if(prevProps.dataConcierge.isFetch && prevProps.dataConcierge.isFetch !== this.props.dataConcierge.isFetch){
      const { error } = this.props.dataConcierge;
      if(error){
        let msg = null;
        switch(error.status_code){
          case 500:
            msg = DEFAULT_ERROR_500_MSG; break;
          case 404:
            msg = DEFAULT_ERROR_404_MSG; break;
          default:
            msg = error.message;
        }
        this.setState({
          _error: error,
          _isLoading:false,
          _errorLoadData: true,
          _errorMsg: msg
        })
      }
      else{
        this.setState({
          _error: null,
          _errorLoadData: null,
          _isLoading: false,
          _errorMsg: '',
        })
      }
    }
  }

  _renderHeaderContent(){
    if(this.props.details && this.props.details.offer){
      let { user, offer } = this.props.details;
      user = user || {};
      let user_sell = offer && offer.user_sell || {};
      let product_variant = offer && offer.user_sell.product_variant || {};
      let size = offer && offer.user_sell.size || {};
      return(
        <Header
          isLoading={this.state._isLoading}
          email={user.email}
          createdAt={offer.created_at}
          displayName={product_variant.display_name}
          SKU={product_variant.SKU}
          colour={product_variant.colour}
          sizeUS={size.US}
          payment={offer}
          paymentMethod={offer.payment_method}
          status={offer.status}
          sneakerCondition={user_sell.sneakers_condition}
          boxCondition={user_sell.box_condition}
          preOrder={user_sell.pre_order}
          expiredAt={offer.expired_at}
          totalAmount={offer.total_amount}
          category={product_variant.product&&product_variant.product.category.name}
        />
      )
    }
    return null;
  }

  toggleTab(tab) {
		if (this.state.activeTab !== tab) {
			this.setState({
        activeTab: tab
      });
      const { pathname } = this.props.location;
      const search = qs.stringify({
        activeTab: tab
      });
      this.props.history.replace({ pathname, search })
		}
  }

  render(){
    return(
      <div className="animated fadeIn">
        <Row>
          <Col xs={12} style={{padding:0}}>
          {
            this.state._errorLoadData?
            <Card body>
              <Alert
                color="danger"
                className="text-center"
                isOpen={this.state._errorAfterFetched}
              >
                <strong>Ooops. </strong>{this.state._errorMsg}
              </Alert>
            </Card>
            :
            <Card body>
              { this._renderHeaderContent() }
              <Nav className="nav-tabs--custom" tabs>
                <NavItem>
                  <NavLink
                    className={classnames({ active: this.state.activeTab === 'conciergeDetail' })}
                    onClick={() => this.toggleTab('conciergeDetail')}
                  >
                    Concierge Details
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: this.state.activeTab === 'paymentDetail' })}
                    onClick={() => this.toggleTab('paymentDetail')}
                  >
                    Payment Details
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: this.state.activeTab === 'orderDetail' })}
                    onClick={() => this.toggleTab('orderDetail')}
                  >
                    Order Details
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: this.state.activeTab === 'productDetail' })}
                    onClick={() => this.toggleTab('productDetail')}
                  >
                    Product Details
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: this.state.activeTab === 'userDetail' })}
                    onClick={() => this.toggleTab('userDetail')}
                  >
                    User & Shipping
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: this.state.activeTab === 'sellerDetail' })}
                    onClick={() => this.toggleTab('sellerDetail')}
                  >
                    Seller
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent className="tab-content--custom" activeTab={this.state.activeTab}>
                <TabPane className="tab-content--custom__tab-pane" tabId="conciergeDetail">
                  <TabConciergeDetail
                    id={this.state._id}
                    isLoading={this.state._isLoading}
                    isSubmit={this.props.dataConcierge.isSubmit}
                    details={this.props.details}
                    error={this.props.dataConcierge.error}
                    updateConcierge={this.props.updateConcierge}
                    applyVoucher={this.props.applyVoucher}
                    getShippingFee={this.props.getShippingFee}
                    dataCouriers={this.props.dataCouriers}
                    dataVoucher={this.props.dataVoucher}
                    dataProvinces={this.props.dataProvinces}
                  />
                </TabPane>
                <TabPane className="tab-content--custom__tab-pane" tabId="paymentDetail">
                  <TabPaymentDetail
                    isLoading={this.state._isLoading}
                    paymentId={this.props.details && this.props.details.offer_id}
                    payment={this.props.details && this.props.details.offer}
                    productVariant={this.props.details.offer? this.props.details.offer.user_sell.product_variant:{}}
                  />
                </TabPane>
                <TabPane className="tab-content--custom__tab-pane" tabId="orderDetail">
                  <TabPaymentDetail
                    isLoading={this.state._isLoading}
                    paymentId={this.props.details && this.props.details.order_id}
                    payment={this.props.details && this.props.details.order}
                    productVariant={this.props.details.offer? this.props.details.offer.user_sell.product_variant:{}}
                  />
                </TabPane>
                <TabPane className="tab-content--custom__tab-pane" tabId="productDetail">
                  <TabSneakerDetail
                    isLoading={this.state._isLoading}
                    payment={this.props.details && this.props.details.offer}
                    userSellId={this.props.details.offer && this.props.details.offer.user_sell_id}
                    userSell={this.props.details.offer && this.props.details.offer.user_sell}
                    productVariant={this.props.details.offer? this.props.details.offer.user_sell.product_variant: undefined}
                    size={this.props.details.offer? this.props.details.offer.user_sell.size: undefined}
                  />
                </TabPane>
                <TabPane className="tab-content--custom__tab-pane" tabId="userDetail">
                  <TabUserDetail
                    isLoading={this.state._isLoading}
                    shipping={this.props.details && this.props.details.shipping_id !== null ? this.props.details.shipping: undefined}
                  />
                </TabPane>
                <TabPane className="tab-content--custom__tab-pane" tabId="sellerDetail">
                  <TabSellerDetail
                    isEmpty={this.props.details.offer && this.props.details.offer.user_sell_id === null}
                    isLoading={this.state._isLoading}
                    seller={this.props.details.offer && this.props.details.offer.user_sell_id ? this.props.details.offer.user_sell.user : undefined}
                  />
                </TabPane>
              </TabContent>
            </Card>
          }
          </Col>
        </Row>
      </div>
    )
  }
}

const mapStateToProps =  ({ auth: { isFetch, isLogged, roles, id, email }, concierge, userShipping}) => ({
  guardData: { isFetch, isLogged, roles, id, email },
  dataConcierge: concierge,
  details: concierge.detail,
  dataCouriers:userShipping.couriers,
  dataVoucher:userShipping.voucher,
  dataProvinces:userShipping.provinces,
})

const mapDispatchToProps = dispatch => ({
  getDetail: id => dispatch(getDetailConcierge(id)),
  updateConcierge: (id, payload) => dispatch(updateConcierge(id, payload)),
  getShippingFee : ({destination,origin=153,weight=1000,courier='FLAT_RATE', product_variant_id}) => dispatch(doPostShippingCost({destination,origin,weight,courier,product_variant_id})),
  getProvinces : () => dispatch(doFetchProvinces()),
  applyVoucher : (payload) => dispatch(doPostApplyVoucher(payload)),
})

const enhance = connect(mapStateToProps, mapDispatchToProps);

export default enhance(privateView(ConciergeDetail));