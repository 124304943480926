import React, { Component } from "react";
import { connect } from "react-redux";
import { Container } from "reactstrap";
import FaqForm from "./FaqForm";
import privateView from "../../components/hocs/privateView";
import Analytics from "../../services/Analytics";

class FaqDetail extends Component {
  componentDidMount() {
    const { email } = this.props.injectedProps;
    Analytics.recordPageView("faq_detail", email, {
      url: window.location.href,
      location: this.props.location,
      match: this.props.match,
    });
  }
  render() {
    return (
      <Container fluid>
        <FaqForm edit title="FAQ Detail" {...this.props} />
      </Container>
    );
  }
}

const mapStateToProps = ({
  auth: { isFetch, roles, isLogged, id, email },
}) => ({
  guardData: { isFetch, roles, isLogged, id, email },
});

const enhance = connect(mapStateToProps);
export default enhance(privateView(FaqDetail));
