import React from 'react';
import propTypes from 'prop-types';
import Loading from 'react-loading-animation';
import classNames from 'classnames';
import {
  Row,
  Col,
  Card,
  CardHeader,
  Form,
  FormGroup,
  Input,
  Alert,
  Label,
  CardBody
} from 'reactstrap';
import { getPaymentType, paymentStatusOptions } from '../../../../constants/kickavenue';
import { ButtonLoading } from '../../../../components/Button';
import { TextRupiah } from '../../../../components/Text';
import { errorMessage, hasError, _getValue, _getFloatValue, textChange } from '../../../../utils/form';
import {
  FieldRow,
  FieldColumn,
  FieldLabel,
  FieldValue
} from '../../../../styled/Fields';
import Analytics from "../../../../services/Analytics";

const DEFAULT_SUCCESS_MSG = 'Updating top-up successfully.';
const DEFAULT_ERROR_422_MSG = 'Please fulfill your form.';
export default class PaymentDetailCard extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      id: props.id,
      _isSubmit: false,
      _error: null,
      _isSuccessAlertDisplayed: false,
      _isErrorAlertDisplayed: false,
      _successMsg: DEFAULT_SUCCESS_MSG,
      _errorMsg: DEFAULT_ERROR_422_MSG,
      _disabledDropdownStatus: false
    }
    this._onSubmit = this._onSubmit.bind(this);
    this._handleTextChange = this._handleTextChange.bind(this);
    this._alertSuccessToggle = this._alertSuccessToggle.bind(this);
    this._alertErrorToggle = this._alertErrorToggle.bind(this);
  }

  componentWillReceiveProps(nextProps){
    if(nextProps.isLoading!==this.props.isLoading && !nextProps.isLoading){
      const { dataError, id, status, remarks, updated_at } = nextProps;
      let { _disabledDropdownStatus } = this.state;
      if(status == 'COMPLETED' || status == 'REJECTED' || status =='REFUNDED')
        _disabledDropdownStatus = true;
      this.setState({
        _error: dataError,
        updated_at,
        id,
        status,
        remarks,
        _disabledDropdownStatus
      })
    }
    if(this.state._isSubmit && nextProps.isSubmit!==this.props.isSubmit && !nextProps.isSubmit){
      const { dataError, id, remarks, status, updated_at } = nextProps;
      let { _disabledDropdownStatus } = this.state;
      if(status == 'COMPLETED' || status == 'REJECTED' || status =='REFUNDED')
        _disabledDropdownStatus = true;
      if(dataError){
        this.setState({
          _isSubmit: false,
          _error: dataError,
          _isErrorAlertDisplayed: true,
          _errorMsg: dataError.status_code === 422? DEFAULT_ERROR_422_MSG: dataError.message
        })
      }
      else{
        this.setState({
          _error: null,
          _isSubmit: false,
          _isSuccessAlertDisplayed: true,
          _disabledDropdownStatus,
          id,
          status,
          remarks,
          updated_at
        })
      }
    }
  }

  _handleTextChange(e){
    const lastState = this.state;
    // lastState[e.target.name] = e.target.value;
    this.setState({
      // ...lastState
      ...textChange(e, lastState)
    });
  }

  _onSubmit(e){
    e.preventDefault();
    this.setState({
      _isSubmit: true,
      _error: null
    },()=>{
      const { id, status, remarks, _isErrorAlertDisplayed, _isSuccessAlertDisplayed } = this.state;
      const email = this.props;
      this.props.onUpdatePayment(id, { status, remarks })
      const { invoice_number } = this.props;
      Analytics.recordChangeStatusTopUpPayment(
        status, 
        remarks,
        invoice_number,
        email
			)
      if(_isErrorAlertDisplayed)
        this._alertErrorToggle();
      if(_isSuccessAlertDisplayed)
        this._alertSuccessToggle()
    })
  }

  _alertSuccessToggle(){
    this.setState({
      _isSuccessAlertDisplayed: !this.state._isSuccessAlertDisplayed
    })
  }

  _alertErrorToggle(){
    this.setState({
      _isErrorAlertDisplayed: !this.state._isErrorAlertDisplayed
    })
  }

  render(){
    return(
      <Card>
        <CardHeader>
          Top-up Details
        </CardHeader>
        <CardBody>
        { this.props.isLoading?
          <Loading/>:
          <Form
            onSubmit={this._onSubmit}
          >
            <FieldRow>
              <FieldColumn className="col-xs-12 col-md-6">
                <FieldLabel>Top-up ID</FieldLabel>
                <FieldValue>
                  <span>{_getValue(this.state.id)}</span>
                </FieldValue>
              </FieldColumn>
            </FieldRow>
            <FieldRow>
              <FieldColumn className="col-12">
                <FieldLabel>Invoice Number</FieldLabel>
                <FieldValue>
                  <span>{ _getValue(this.props.invoice_number) }</span>
                </FieldValue>
              </FieldColumn>
              <FieldColumn className="col-xs-12 col-md-6">
                <FieldLabel>Currency</FieldLabel>
                <FieldValue>
                  <span>{ _getValue(this.props.currency) }</span>
                </FieldValue>
              </FieldColumn>
              <FieldColumn className="col-xs-12 col-md-6">
                <FieldLabel>Payment Method</FieldLabel>
                <FieldValue>
                  <span>{ getPaymentType(this.props.payment_method) }</span>
                </FieldValue>
              </FieldColumn>
              <FieldColumn className="col-12">
                <FieldLabel>Invoice URL</FieldLabel>
                <FieldValue>
                  <span className="break-all"> { _getValue(this.props.invoice_url, '-') }</span>
                </FieldValue>
              </FieldColumn>
            </FieldRow>
            <FieldRow>
              <FieldColumn className="col-xs-12 col-md-6">
                <FieldLabel>Amount</FieldLabel>
                <FieldValue>
                  <TextRupiah
                    prefix="Rp. "
                    value={_getFloatValue(this.props.amount)}
                  />
                </FieldValue>
              </FieldColumn>
              <FieldColumn className="col-xs-12 col-md-6">
                <FieldLabel>Unique Amount</FieldLabel>
                <FieldValue>
                  <TextRupiah
                    prefix="Rp. "
                    value={_getFloatValue(this.props.unique_amount)}
                  />
                </FieldValue>
              </FieldColumn>
              <FieldColumn className="col-xs-12 col-md-6">
                <FieldLabel>Administration Fee</FieldLabel>
                <FieldValue>
                  <TextRupiah
                    prefix="Rp. "
                    value={_getFloatValue(this.props.administration_fee)}
                  />
                </FieldValue>
              </FieldColumn>
              <FieldColumn className="col-xs-12 col-md-6">
                <FieldLabel>Expiry</FieldLabel>
                <FieldValue>
                  <span>{ _getValue(this.props.expiry) }</span>
                </FieldValue>
              </FieldColumn>
            </FieldRow>
            <FieldRow>
              <FieldColumn className="col-xs-12 col-md-6">
                <FieldLabel>Created</FieldLabel>
                <FieldValue>
                  <span>{ _getValue(this.props.created_at) }</span>
                </FieldValue>
              </FieldColumn>
              <FieldColumn className="col-xs-12 col-md-6">
                <FieldLabel>Updated</FieldLabel>
                <FieldValue>
                  <span>{ _getValue(this.state.updated_at) }</span>
                </FieldValue>
              </FieldColumn>
            </FieldRow>
            <Row>
              <Col xs={12}>
                <Alert
                  color="success"
                  isOpen={this.state._isSuccessAlertDisplayed}
                  toggle={this._alertSuccessToggle}
                >
                  <strong>Success. </strong> {this.state._successMsg}
                </Alert>
                <Alert
                  color="danger"
                  isOpen={this.state._isErrorAlertDisplayed}
                  toggle={this._alertErrorToggle}
                >
                  <strong>Opps. </strong> {this.state._errorMsg}
                </Alert>
              </Col>
              <Col xs={12} md={6}>
                <FormGroup
                  className={classNames({'has-danger has-feedback':hasError('status',this.state._error)})}
                >
                  <Label for="_formPaymentDetailStatus">
                    Status
                  </Label>
                  <Input
                    id="_formPaymentDetailStatus"
                    type="select"
                    name="status"
                    disabled={this.state._disabledDropdownStatus}
                    value={_getValue(this.state.status)}
                    onChange={this._handleTextChange}
                  >
                    {
                      paymentStatusOptions.filter(item => item.value!=='VERIFYING_BID')
                      .map((item, index)=><option value={item.value} key={index}>{item.name}</option>)
                    }
                  </Input>
                  { errorMessage('status',this.state._error) }
                </FormGroup>
              </Col>
              <Col xs={12}>
                <FormGroup
                   className={classNames({'has-danger has-feedback':hasError('remarks',this.state._error)})}
                >
                  <Label for="_formPaymentDetailRemarks">
                    Remarks
                  </Label>
                  <Input
                    id="_formPaymentDetailRemarks"
                    type="textarea"
                    name="remarks"
                    value={_getValue(this.state.remarks)}
                    onChange={this._handleTextChange}
                  />
                  { errorMessage('remarks',this.state._error) }
                </FormGroup>
              </Col>
            </Row>
            <FormGroup>
              <ButtonLoading
               isLoading={this.state._isSubmit}
              >
                Update
              </ButtonLoading>
            </FormGroup>
          </Form>
        }
        </CardBody>
    </Card>
    )
  }
}

PaymentDetailCard.propTypes = {
  id: propTypes.number.isRequired,
  dataError: propTypes.object,
  onUpdatePayment: propTypes.func.isRequired,
  isLoading: propTypes.bool,
  isSubmit: propTypes.bool,
}

PaymentDetailCard.defaultProps = {
  isLoading: false,
  isSubmit: false
}