import React from 'react';
import { Link } from 'react-router-dom';
import propTypes from 'prop-types';
import classNames from 'classnames'

const availableStatus = ['NEW', 'SELLER_SENT', 'KA_RECEIVED', 'VERIFICATION_PASSED', 'VERIFICATION_FAILED', 'KA_RETURNED_SELLER', 'CANCELLED'];

const ButtonChangeSeller = ({ style, className, ...props }) => (
  props.isDisplayed === true && availableStatus.filter(item => item === props.status).length>0 ?
    <Link
      style={{fontSize: '.75rem', ...style}}
      to={`/sales/${props.id}/change_seller`}
      className={classNames("btn btn-sm btn-secondary", className)}
      title="Change Seller"
    >
      <i className="fa fa-exchange"></i> Change Seller
    </Link>
  : null
)

export default ButtonChangeSeller;

ButtonChangeSeller.propTypes = {
  id: propTypes.number.isRequired,
  isDisplayed: propTypes.bool,
  status: propTypes.string,
  style: propTypes.object,
  className: propTypes.string
}

ButtonChangeSeller.defaultProps = {
  isDisplayed: true,
  status: 'NEW',
  style: {}
}