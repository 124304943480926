import React from 'react';
const Th = props => {
  const { children, ...otherProps } = props;
  return (
    <th 
      {...otherProps} 
    >
      { children }
    </th>
  )
}

export default Th;