import { manipulateErrors, checkAuthorized } from '../utils';
import { logout } from './authAction';

export const onRequest = (actionType, requestType, value) => ({
  type: actionType,
  payload: {
    requestType,
    value
  }
})

export const onSuccessRequest = (actionType, payload) => ({
  type: actionType, payload
})

export const onFailedRequest = (actionType, requestType, error, id) => {
  const translateError = manipulateErrors(error);
  if(!checkAuthorized(translateError)) return logout();
  return {
    type: actionType,
    payload: {
      id,
      requestType,
      ...translateError
    }
  }
}