import React from 'react';
import propTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import { SimpleLineIcon } from '../../../../components/Icons';

const styles={
  dropzoneContent:{
    width: '100%',
    height: 'auto',
    // borderWidth: 2,
    borderColor: 'rgb(102, 102, 102)',
    borderRadius:0,
    borderStyle: 'dashed'
  },
  p:{
    padding:15
  },
  h1:{
    fontSize:70
  }
}

const DropzonePlaceholder = props =>(
  <Dropzone
    accept={props.accept}
    onDrop={props.onDrop}
    disabled={props.disabled}
    style={styles.dropzoneContent}
    multiple={props.multiple}
    inputProps={props.inputProps}
  >
    <p style={styles.p}>{props.placeholderText}</p>
    <h1 className="text-center" style={styles.h1}><SimpleLineIcon iconType="plus"/></h1>
  </Dropzone>
)

DropzonePlaceholder.propTypes = {
  onDrop: propTypes.func,
  placeholderText: propTypes.string,
  accept: propTypes.string,
  disabled: propTypes.bool,
  multiple: propTypes.bool,
  inputProps: propTypes.object
}

DropzonePlaceholder.defaultProps = {
  placeholderText: "Try dropping your images here, or click to select image to upload.",
  accept: "image/jpeg, image/png",
  disabled: false,
  multiple: true
}

export default DropzonePlaceholder;