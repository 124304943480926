export const isNumeric = str => (/^\d+$/).test(str)
export const isEmail = str => (/^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/).test(str)
export const isValidPassword = str => (/(^(?=.*[0-9])|^(?=.*[A-Z]))[a-zA-Z\d.!\-]{8,}$/).test(str)
export const isGmail = str => (/^[a-z0-9](\.?[a-z0-9]){5,}@g(oogle)?mail\.com$/).test(str)
export const isValidDate = date =>(/^20\d{2}(-|\/)((0[1-9])|(1[0-2]))(-|\/)((0[1-9])|([1-2][0-9])|(3[0-1]))(T|\s)(([0-1][0-9])|(2[0-3])):([0-5][0-9]):([0-5][0-9])/).test(date)
export const dotRegex = /\./g;
export const isINA_Phone = numString => numString.replace(/(\+62|62|^0)/,'')
export const isRequired = str => str && str!==''
export const isConfirm = (strA, strB) => strA === strB
export const httpRegex = /(https:\/\/)|(http:\/\/)/
export const isCompletedSale = status => (/^DONE_\w+/).test(status)
