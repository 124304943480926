import React from 'react';
import propTypes from 'prop-types';
import {
  Col,
  Card,
  CardHeader,
  CardBody,
  Row,
  Form,
  Button,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
} from 'reactstrap';
import Loading from 'react-loading-animation';
import CardThumbnail from './productVariantImages/CardThumbnail';
import DropzoneWithButton from './productVariantImages/DropzoneWithButton';
import { FontAwesomeIcon } from '../../components/Icons';
import { SortableContainer, SortableElement, arrayMove } from 'react-sortable-hoc';

const SortableList = SortableContainer(({items, onSubmit, imgUrl, selectImage, onDrop, onUploadImages, onRemoveImage, onSelectImage, onInputChange}) => {
  return (
    <Row className="d-flex align-items-center">
      <Col xs={12} sm={6} md={3}>
        <div className="d-flex mb-3 align-items-center">
          <Input
            style={{width: 150}}
            type="select"
            name="select_image"
            value={selectImage}
            onChange={e=> onSelectImage(e)}
          >
            <option value="img">Upload Image</option>
            <option value="url">Upload URL</option>
          </Input>
        </div>
        {
          selectImage!=='img' ?
          <Form onSubmit={onSubmit}>
            <Row>
              <FormGroup>
                <InputGroup style={{marginLeft:'12px'}}>
                  <Input type="text" placeholder="Enter a image url" name="_img_url" value={imgUrl} onChange={onInputChange}/>
                  <InputGroupAddon addonType="append">
                    <Button color="primary">Submit</Button>
                  </InputGroupAddon>
                </InputGroup>
              </FormGroup>
            </Row>
          </Form>
          :
          <DropzoneWithButton
            onDrop={onDrop}
            onUploadImages={onUploadImages}
            totalUpload={items.filter(img=> img.status===false).length}
          />
        }
      </Col>
      {
        items.map((image, index) => (
          <SortableItem image={image} key={index} index={index}>
            <CardThumbnail
              id={image.id}
              url={image.url}
              position={image.position}
              positionEdit={image.positionEdit}
              updatedAt={image.updated_at}
              storageName={image.storageName}
              fileInput={image.fileInput}
              isUploading={image.is_uploading && !image.status}
              isDeleting={image.deleted}
              onRemoveImage={e => onRemoveImage(e, image.identifier, image.id)}
            />
          </SortableItem>
        ))
      }
    </Row>
  )
});


const SortableItem = SortableElement(({children}) =>
  <Col xs={12} sm={6} md={3}>
    {children}
  </Col>
);

const ProductVariantImages = ({ isLoading, isShow, images, onDrop, onRemoveImage, onUploadImages, onSortEnd, selectImage, onSelectImage, imgUrl, onInputChange, onSubmit })=>{
  return (
    <Col xs={12} className={!isShow?'d-none':''}>
      <Card>
        <CardHeader>
          <FontAwesomeIcon iconType="align-justify"/> Images
        </CardHeader>
        <CardBody>
          <Loading isLoading={isLoading}>
            <SortableList onSubmit={onSubmit} selectImage={selectImage} imgUrl={imgUrl} onSelectImage={onSelectImage} onDrop={onDrop} onUploadImages={onUploadImages} items={images} onRemoveImage={onRemoveImage} onInputChange={onInputChange} onSortEnd={({oldIndex, newIndex, ...inputs}) => {
              if(oldIndex !== newIndex){
                let data = [ ...images ]
                const id = images[oldIndex].id;
                const afterId = newIndex>0? images[newIndex].id: null;
                data = arrayMove(data, oldIndex, newIndex).map((item, key) => ({
                  ...item,
                  positionEdit: key + 1
                }))
                onSortEnd(data, id, afterId)
              }
            }} axis="xy"/>
          </Loading>
        </CardBody>
      </Card>
    </Col>
  )
}

ProductVariantImages.propTypes = {
  isLoading: propTypes.bool,
  isShow: propTypes.bool,
  images: propTypes.arrayOf(propTypes.shape({
    identifier: propTypes.string.isRequired,
    id: propTypes.number,
    url: propTypes.string,
    updated_at: propTypes.string,
    fileInput: propTypes.object,
    is_uploading: propTypes.bool,
    status: propTypes.bool,
    deleted: propTypes.bool
  })),
  onDrop: propTypes.func,
  onUploadImages: propTypes.func,
  onRemoveImage: propTypes.func,
  onSelectImage: propTypes.func
}

ProductVariantImages.defaultProps = {
  isLoading: false,
  isShow: false
}

export default ProductVariantImages;