import classnames from 'classnames';
import propTypes from 'prop-types';
import React, { useState } from 'react';
import Loading from 'react-loading-animation';
import {
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import { ButtonLoading } from '../Button';

const ModalBulkUpload = ({
  isOpen,
  toggle,
  onSubmit,
  isLoading,
  bulkFormat,
  errorMessage,
  successMessage,
}) => {
  const [link, setLink] = useState('');

  const handleOnChangeLinkText = (e) => {
    setLink(e.target.value);
  };

  const handleSubmit = () => {
    onSubmit({ link });
  };

  const renderBulkUploadMessage = () => {
    if (errorMessage) {
      return (
        <div className='form-control-feedback'>
          {errorMessage.split('\\n').map((m, key) => {
            return <div key={key}>{m}</div>;
          })}
        </div>
      );
    }
    if (successMessage) {
      return <div className='form-control-feedback'>{successMessage}</div>;
    }
    return null;
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>
        <i className='fa fa-upload'></i> Bulk Upload (.csv)
      </ModalHeader>
      <ModalBody>
        <p>
          For Mac user with Microsoft Excel, please choose Windows comma
          separated when saving or export the csv file.
          <br />
          The columns for CSV are:
          <br />
          <small>{bulkFormat}</small>
        </p>
        <Loading isLoading={isLoading}>
          <FormGroup check>
            <Label check>
              <Input
                type='radio'
                name='radio_link'
                value='link'
                defaultChecked
              />{' '}
              Using a link
            </Label>
          </FormGroup>
          <FormGroup
            className={classnames({
              'has-danger has-feedback': !!errorMessage,
              'has-success': !!successMessage,
            })}
          >
            <Input
              type='text'
              name='text_link'
              onChange={handleOnChangeLinkText}
              placeholder='Input a link.'
              value={link}
              disabled={isLoading}
            />
            {renderBulkUploadMessage()}
          </FormGroup>
        </Loading>
      </ModalBody>
      <ModalFooter>
        <ButtonLoading
          color='primary'
          isLoading={isLoading}
          onClick={handleSubmit}
          disabled={!link}
        >
          Submit
        </ButtonLoading>{' '}
        <ButtonLoading
          color='secondary'
          isLoading={isLoading}
          onClick={toggle}
          loadingMessage='Cancel'
        >
          Cancel
        </ButtonLoading>
      </ModalFooter>
    </Modal>
  );
};

ModalBulkUpload.propTypes = {
  isOpen: propTypes.bool.isRequired,
  toggle: propTypes.func.isRequired,
  bulkFormat: propTypes.string.isRequired,
  onSubmit: propTypes.func.isRequired,
  isLoading: propTypes.bool,
  errorMessage: propTypes.string,
  successMessage: propTypes.string,
};

ModalBulkUpload.defaultProps = {
  isLoading: false,
  errorMessage: '',
  successMessage: '',
};

export default ModalBulkUpload;
