import React from "react";
import propTypes from "prop-types";
import { Row, Col, Form, FormGroup, Button, Input, Label } from "reactstrap";
import { settingTypeOptions } from "../../../constants/kickavenue";

const statusOptions = [
  {
    value: "",
    label: "Default",
  },
  {
    value: "1",
    label: "Active",
  },
  {
    value: "0",
    label: "Inactive",
  },
];

const FilterForm = (props) => (
  <Form className="form-filter" onSubmit={props.onSubmit}>
    <Row>
      <Col xs={12} md={6}>
        <p>Filter By :</p>
        <div className="d-flex" style={{ flexDirection: "column" }}>
          <div className="d-flex" style={{ padding: "0px 1rem" }}>
            <FormGroup
              className="d-flex"
              style={{ flexDirection: "column", alignItems: "flex-start" }}
            >
              <Label className="mb-2" style={{ fontWeight: 700 }}>
                Status
              </Label>
              <div className="d-flex ml-2 mr-2">
                {statusOptions.map((option, i) => (
                  <FormGroup check className="mr-2" key={i}>
                    <Label check>
                      <Input
                        type="radio"
                        name="active"
                        value={option.value}
                        checked={props.active == option.value}
                        onChange={props.onCheckedChange}
                      /> {option.label}
                    </Label>
                  </FormGroup>
                ))}
              </div>
            </FormGroup>
          </div>
          <div className="d-flex" style={{ padding: "0px 1rem" }}>
            <FormGroup className="d-flex" style={{flexDirection: 'column', alignItems: 'flex-start'}} check>
              <Label className="mr-sm-3" style={{fontWeight: 700}}>
                Type
              </Label>
              <div className="ml-2 mr-2">
                <div className="w-100"></div>
                <Label check className="mr-3">
                  <Input
                    type="checkbox"
                    name="type"
                    value="all"
                    checked={props.isCheckedType('all')}
                    onChange={(e) => props.onTypeInputChange(e)}
                  /> All
                </Label>
                {settingTypeOptions.map((option, i) => (
                  <Label check className="mr-3" key={i}>
                    <Input
                      type="checkbox"
                      name="type"
                      value={option.value}
                      checked={props.isCheckedType(option.value)}
                      onChange={(e) => props.onTypeInputChange(e)}
                    /> {option.label}
                  </Label>
                ))}
              </div>
            </FormGroup>
          </div>
          <FormGroup className="mb-2" style={{ alignSelf: "flex-end" }}>
            <Button color="primary">Apply Filter</Button>
          </FormGroup>
        </div>
      </Col>
    </Row>
  </Form>
);
export default FilterForm;
FilterForm.defaultProps = {
  active: "",
  duration: "",
  type: "",
};
FilterForm.propTypes = {
  onSubmit: propTypes.func.isRequired,
  onCheckedChange: propTypes.func.isRequired,
  voucher_type: propTypes.string,
  type: propTypes.string,
  type: propTypes.string,
};
