import React from 'react';
import { Row, Col } from "reactstrap";
import Paginations from '../../../components/Paginations';
import PaginationSummaries from '../../../components/Paginations/PaginationSummaries';
import SeekProductDetail from '../../SeekStock/seekList/SeekProductDetail';
import AlertNotFound from '../../../components/Card/AlertNotFound';
import { Radio, RadioText, FormGroupRadio } from '../../../components/Form/Radios';

export const ProductVariantItem = props => (
  <div>
    <Row>
      <Col xs={12} md={12}>
        <FormGroupRadio style={{marginLeft: '2px', marginBottom: 0, marginTop: '.25rem'}}>
          <Radio
            wrapperClassName="switch-danger"
            radioType="3d"
            size="xs"
            value={true}
            checked={props.checked}
            usingLabelText={false}
            onChange={props.onSelectedChange}
          />
          <div className="switch-label-wrapper" style={{fontSize: '.75rem'}}>
            <RadioText className="text-muted" text={`#${props.data.id}`}/>
            <b className="mr-1" style={{marginTop: '.2rem', marginBottom: '.5rem'}}>Points:</b>
            <span style={{marginTop: '.2rem', marginBottom: '.5rem'}}>
              {parseInt(props.data.pivot.points)}
            </span>
          </div>
        </FormGroupRadio>
      </Col>
    </Row>
    <SeekProductDetail
      id={props.data.id}
      displayName={props.data.display_name}
      SKU={props.data.SKU}
      colour={props.data.colour}
      images={props.data.product_variant_images}
    />
  </div>
)

const ItemList = props => (
  <div>
    {!props.hidePagination?(
      <PaginationSummaries
        from={props.paginations.from}
        to={props.paginations.to}
        total={props.paginations.total}
      />
    ):null}
    <div style={{paddingTop: '.5rem', borderTop: '1px solid #ddd', position: 'relative'}}>
      {
        props.isLoading?(
          <div style={{position: 'absolute', width: '100%', zIndex: 5}}>
            <div style={{padding: '3px 6px', background: 'rgba(255,255,255,.85)', width: 150, textAlign: 'center', margin: 'auto'}}>
              Loading...
            </div>
          </div>
        ): null
      }
      <div style={{maxHeight: 400, overflow: 'auto'}}>
        {props.list.length === 0? (
          <div style={{padding: '0px .5rem'}}>
            <AlertNotFound message="No Record Found!"/>
          </div>
        ):null}
        {props.list.map(props.renderItems)}
      </div>
    </div>
    <div style={{padding: '6px 0px', position: 'relative'}}>
      <div style={{position: 'absolute', top: '-2px', zIndex: 10, background: '#FFF', boxShadow: '0px -5px 5px -3px rgba(0,0,0,0.5)', height: 8, width: '100%'}}></div>
      {!props.hidePagination?(
        <Paginations
          size="sm"
          lastPage={props.paginations.lastPage}
          perPage={props.paginations.perPage}
          total={props.paginations.total}
          maxPage={8}
          currentPage={props.paginations.currentPage}
          handleGotoCallback={props.onGotoPage}
        />
      ):null}
    </div>
  </div>
)
export default ItemList;