import React from 'react';
import propTypes from 'prop-types';
import Paragraph from '../../../../styled/Typograph/Paragraph';
import SmallInline from '../../../../styled/Typograph/Small/SmallInline';
import BadgeThin from '../../../../components/Badges/BadgeThin';
import classNames from 'classnames';

const SummariesColumn = props =>(
  <div>
    {
      props.brand?
      <Paragraph>
        {props.brand.flat.map((item, index)=><BadgeThin key={`brand_${props.id}_${index}`} style={{marginRight:5}}>{item}</BadgeThin>)}
      </Paragraph>
      :null
    }
    <Paragraph>
      {props.displayName}
      <br/>
      <SmallInline>({props.SKU})</SmallInline>
      <SmallInline className="text-muted">{props.colour}</SmallInline>
    </Paragraph>
    <Paragraph>
      {props.categoryName && (
        <BadgeThin
          color="primary"
          className="text-uppercase mr-1"
        >
          <i className="fa fa-tags"></i> {props.categoryName}
        </BadgeThin>
      )}
      <BadgeThin 
        color="primary"
      >
        #{props.id}
      </BadgeThin>
      {' '}
      <BadgeThin 
        color={props.active?'success':'default'}
      >
        <i className={classNames('fa',{'fa-times':!props.active, 'fa-check': props.active})}></i> Active
      </BadgeThin>
      {' '}
      <BadgeThin
        color={props.editorsChoice?'success':'default'}
      >
        <i className={classNames('fa',{'fa-times':!props.editorsChoice, 'fa-check': props.editorsChoice})}></i> Hot Product
      </BadgeThin>
      {' '}
      <BadgeThin 
        color={props.vintage?'success':'default'}
      >
        <i className={classNames('fa',{'fa-times':!props.vintage, 'fa-check': props.vintage})}></i> Vintage
      </BadgeThin>
    </Paragraph>
  </div>
)
export default SummariesColumn;
SummariesColumn.propTypes = {
  id: propTypes.number.isRequired,
  displayName: propTypes.string,
  SKU: propTypes.string,
  colour: propTypes.string,
  active: propTypes.oneOfType([propTypes.bool, propTypes.number]),
  editorsChoice: propTypes.oneOfType([propTypes.bool, propTypes.number]),
}
SummariesColumn.defaultProps = {
  active: false,
  editorsChoice: false
}