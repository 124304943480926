import React from 'react';
import propTypes from 'prop-types';
import { Button } from 'reactstrap';
import classNames from 'classnames';
import Dropzone from './basicDropzone/DropzonePlaceholder';
import { FontAwesomeIcon } from '../../../components/Icons';

const BasicDropzone = props => (
  <div className={classNames("dropzone d-flex flex-column", props.className)} style={{marginBottom:'1rem'}}>
    <Dropzone
      onDrop={props.onDrop}
      accept={props.accept}
      disabled={props.disabled}
      placeholderText={props.placeholderText}
      multiple={props.multiple}
      inputProps={props.inputProps}
    />
    <Button
      color="primary"
      onClick={props.onUploadImages}
      disabled={props.disabled}
      className={classNames("mt-2 mb-2", {'d-none':props.totalUpload===0})}
    >
      <FontAwesomeIcon iconType="upload"/> Start Upload ({props.totalUpload})
    </Button>
  </div>
)

BasicDropzone.propTypes = {
  onDrop: propTypes.func,
  onUploadImages: propTypes.func,
  totalUpload: propTypes.number,
  accept: propTypes.string,
  placeholderText: propTypes.string,
  className: propTypes.string,
  disabled: propTypes.bool,
  multiple: propTypes.bool,
  inputProps: propTypes.object
}

BasicDropzone.defaultProps = {
  totalUpload: 0,
  disabled: false,
  multiple: true
}

export default BasicDropzone;