import mime from 'mime-types';
import {
	COLLECTION_ROUTE_API,
	GET_LIST_COLLECTION,
	UPDATE_POSITION,
	FETCHING_COLLECTION,
	GET_DETAIL_COLLECTION,
	UPDATE_COLLECTION,
	COLLECTION_ERROR,
	ADD_COLLECTION,
	NEW_COLLECTION,
	DELETE_COLLECTION,
	AUTH_TOKEN_KEY,

	COLLECTION_CHILDS_REQUEST,
	COLLECTION_CHILDS_ERROR,
	SUBMITING_COLLECTION_CATEGORIES,
	GET_LIST_COLLECTION_CATEGORIES,

	SUBMITING_COLLECTION_ITEMS,
	GET_LIST_COLLECTION_ITEMS,
	SEARCH_COLLECTION_ITEMS,

	COLLECTIONS_S3,
	CREATE_COLLECTION_IMAGE,
	UPDATE_COLLECTION_IMAGE,
	DELETE_COLLECTION_IMAGE,
	CREATE_COLLECTION_SELLER,
	GET_LIST_COLLECTION_SELLER,
	DELETE_COLLECTION_SELLER,
} from '../constants';

import { onRequest, onFailedRequest, onSuccessRequest } from './helpers';
import { logout } from './authAction';

import api from '../utils/Api';
import { manipulateErrors, checkAuthorized } from '../utils';

import { clientPutObject } from '../utils/AWS';
import {
	AWS_BUCKET,
	AWS_BUCKET_URL
} from '../config/storageBucket';

export const S3_FOLDER_PATH = 'collections/';

/**
 * Get list of offers
 * @author pewe
 * @guards jwt-token 
 * @param {object?} query 
 */
export const getCollectionList = (query) => {
	return dispatch => (
		dispatch(isFetch({ type: 'fetch', status: true })),
		api({
			Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).get(COLLECTION_ROUTE_API, query)
			.then(res => {
				dispatch(onSuccess(res.data, GET_LIST_COLLECTION))
				dispatch(isFetch({ type: 'fetch', status: false }))
			})
			.catch(err => {
				dispatch(onError(err));
				dispatch(isFetch({ type: 'fetch', status: false }));
			})
	)
}

/**
 * Get detail of Offer
 * @guards jwt-token
 * @param {integer} id 
 */
export const getDetailCollection = (id) => {
	return dispatch => (
		dispatch(isFetch({ type: 'fetch', status: true })),
		api({
			Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).get(`${COLLECTION_ROUTE_API}/${id}`)
			.then(res => {
				dispatch(onSuccess(res.data, GET_DETAIL_COLLECTION))
				dispatch(isFetch({ type: 'fetch', status: false }))
			})
			.catch(err => {
				dispatch(onError(err));
				dispatch(isFetch({ type: 'fetch', status: false }));
			})
	)
}
export const saveCollection = (payload) => {
	const { id, ...body } = payload;
	return dispatch => {
		if (payload.id === undefined)
			dispatch(createCollection(body));
		else
			dispatch(updateCollection(id, body));

	}
}
export const createCollection = (payload) => {
	return dispatch => (
		dispatch(isFetch({ type: 'submit', status: true })),
		api({
			Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).post(`${COLLECTION_ROUTE_API}`, payload)
			.then(res => {
				dispatch(onSuccess(res.data, ADD_COLLECTION))
				dispatch(isFetch({ type: 'submit', status: false }))
			})
			.catch(err => {
				dispatch(onError(err));
				dispatch(isFetch({ type: 'submit', status: false }));
			})
	)
}

/**
 * Update an offer
 * @author pewe
 * @guards jwt-token
 * @param {integer} id 
 * @param {object} payload 
 */
export const updateCollection = (id, payload) => {
	return dispatch => (
		dispatch(isFetch({ type: 'submit', status: true })),
		api({
			Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).put(`${COLLECTION_ROUTE_API}/${id}`, payload)
			.then(res => {
				dispatch(onSuccess(res.data, UPDATE_COLLECTION))
				dispatch(isFetch({ type: 'submit', status: false }))
			})
			.catch(err => {
				dispatch(onError(err));
				dispatch(isFetch({ type: 'submit', status: false }));
			})
	)
}

export const deleteCollection = (id) => {
	return dispatch => (
		dispatch(isFetch({ type: 'submit', status: true })),
		api({
			Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).delete(`${COLLECTION_ROUTE_API}/${id}`)
			.then(res => {
				dispatch(onSuccess(res.data, DELETE_COLLECTION))
				dispatch(isFetch({ type: 'submit', status: false }))
			})
			.catch(err => {
				dispatch(onError(err));
				dispatch(isFetch({ type: 'submit', status: false }));
			})
	)
}

/**
 * Indicates when start fetch api or end fetch api
 * @author pewe
 * @param {boolean} bool 
 */
export const isFetch = (payload) => (
	{
		type: FETCHING_COLLECTION,
		payload: payload
	}
)

/**
 * When onSuccess triggered
 * @author pewe
 * @param {object} res 
 * @param {string} type 
 */
export const onSuccess = (res, type) =>
(
	{
		type: type,
		payload: res
	}
)
/**
 * When error fetch or connect to api
 * @author pewe
 * @param {object} err 
 */
// On Error fetching api
export const onError = (err) => {
	if (!checkAuthorized(manipulateErrors(err)))
		return logout();
	return {
		type: COLLECTION_ERROR,
		payload: manipulateErrors(err)
	}
}
export const onErrorWithType = (type, err) => {
	if (!checkAuthorized(manipulateErrors(err)))
		return logout();
	return {
		type,
		payload: manipulateErrors(err)
	}
}

export const newCollection = () => {
	return {
		type: NEW_COLLECTION
	}
}

export const saveCollectionCategories = (id, payload) => (
	dispatch => {
		dispatch({
			type: COLLECTION_CHILDS_REQUEST,
			payload: {
				requestType: SUBMITING_COLLECTION_CATEGORIES,
				value: true
			}
		});
		api({
			Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).post(`${COLLECTION_ROUTE_API}/${id}/categories`, payload)
			.then(res => {
				dispatch(onSuccess(res.data, SUBMITING_COLLECTION_CATEGORIES))
				api({
					Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
				}).get(`${COLLECTION_ROUTE_API}/${id}`)
					.then(res => {
						dispatch(onSuccess(res.data, GET_LIST_COLLECTION_CATEGORIES))
						dispatch({
							type: COLLECTION_CHILDS_REQUEST,
							payload: {
								requestType: SUBMITING_COLLECTION_CATEGORIES,
								value: false
							}
						})
					}).catch(err => {
						dispatch(onFailedRequest(COLLECTION_CHILDS_ERROR, SUBMITING_COLLECTION_CATEGORIES, err, id));
						dispatch({
							type: COLLECTION_CHILDS_REQUEST,
							payload: {
								requestType: SUBMITING_COLLECTION_CATEGORIES,
								value: false
							}
						})
					})
			})
			.catch(err => {
				dispatch(onFailedRequest(COLLECTION_CHILDS_ERROR, SUBMITING_COLLECTION_CATEGORIES, err, id));
				dispatch({
					type: COLLECTION_CHILDS_REQUEST,
					payload: {
						requestType: SUBMITING_COLLECTION_CATEGORIES,
						value: false
					}
				});
			})
	}
)

export const getCollectionItems = (id, collectionType, query) => (
	dispatch => {
		let actionType = GET_LIST_COLLECTION_ITEMS;
		if (query.params.exclude === true) {
			actionType = SEARCH_COLLECTION_ITEMS;
		}
		dispatch(onRequest(
			COLLECTION_CHILDS_REQUEST,
			actionType,
			true
		))
		api({
			Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).get(`${COLLECTION_ROUTE_API}/${id}/${collectionType}`, query)
			.then(res => {
				dispatch(onSuccess(res.data, actionType))
				dispatch(onRequest(
					COLLECTION_CHILDS_REQUEST,
					actionType,
					false
				))
			})
			.catch(err => {
				dispatch(onFailedRequest(COLLECTION_CHILDS_ERROR, actionType, err, { id, query }));
				dispatch(onRequest(
					COLLECTION_CHILDS_REQUEST,
					actionType,
					false
				))
			})
	}
)

export const saveCollectionItems = (operation, id, collectionType, payload) => (
	dispatch => {
		dispatch(onRequest(
			COLLECTION_CHILDS_REQUEST,
			SUBMITING_COLLECTION_ITEMS,
			true
		))
		let request = null;
		if (operation === 'attach') {
			request = api({
				Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
			}).post(`${COLLECTION_ROUTE_API}/${id}/${collectionType}`, payload)
		} else { //detach
			request = api({
				Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
			}).put(`${COLLECTION_ROUTE_API}/${id}/${collectionType}`, payload)
		}
		request
			.then(res => {
				dispatch(onSuccess(res.data, SUBMITING_COLLECTION_ITEMS))
				dispatch(onRequest(
					COLLECTION_CHILDS_REQUEST,
					SUBMITING_COLLECTION_ITEMS,
					false
				))
			})
			.catch(err => {
				dispatch(onFailedRequest(COLLECTION_CHILDS_ERROR, SUBMITING_COLLECTION_ITEMS, err, id));
				dispatch(onRequest(
					COLLECTION_CHILDS_REQUEST,
					SUBMITING_COLLECTION_ITEMS,
					false
				))
			})
	}
)


export const uploadCollectionImage = (file, folder = null, customFileName = null) => (
	dispatch => {
		let defaultFolder = S3_FOLDER_PATH;
		if (folder) defaultFolder = folder
		let key = `${defaultFolder}${file.identifier}.${mime.extension(file.fileInput.type)}`;
		if (customFileName) {
			key = `${defaultFolder}${customFileName}`;
		}
		dispatch({
			type: COLLECTIONS_S3,
			payload: { isPending: true }
		})
		clientPutObject({
			Bucket: AWS_BUCKET,
			Key: key,
			Body: file.fileInput,
			ContentType: 'image/jpeg',
			ACL: 'public-read', // your permisions
		}).then(res => {
			const results = {
				identifier: file.identifier,
				prefix: AWS_BUCKET_URL,
				folder: defaultFolder,
				url: `${AWS_BUCKET_URL}${res.key}`
			}
			dispatch({
				type: COLLECTIONS_S3,
				payload: { results, isPending: false }
			});
		}).catch(err => {
			dispatch(onFailedRequest(COLLECTION_CHILDS_ERROR,
				COLLECTIONS_S3, {
				type: COLLECTIONS_S3,
				payload: { error: err }
			})
			);
		})
	}
)

export const deleteCollectionImageById = (collectionId, id) => (
	dispatch => {
		dispatch(onRequest(COLLECTION_CHILDS_REQUEST, DELETE_COLLECTION_IMAGE, true));
		api({
			Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		})
			.delete(`${COLLECTION_ROUTE_API}/${collectionId}/images/${id}`)
			.then(res => {
				dispatch(onSuccessRequest(DELETE_COLLECTION_IMAGE, res.data));
				dispatch(onRequest(COLLECTION_CHILDS_REQUEST, DELETE_COLLECTION_IMAGE, false));
			})
			.catch(err => {
				dispatch(onFailedRequest(COLLECTION_CHILDS_ERROR, DELETE_COLLECTION_IMAGE, err, id));
				dispatch(onRequest(COLLECTION_CHILDS_REQUEST, DELETE_COLLECTION_IMAGE, false));
			});
	}
)

export const saveCollectionImage = (collectionId, payload) => {
	const { id, ...body } = payload;
	return id ? updateCollectionImage(collectionId, id, body) : createCollectionImage(collectionId, body)
}

const updateCollectionImage = (collectionId, id, payload) => (
	dispatch => {
		dispatch(onRequest(COLLECTION_CHILDS_REQUEST, UPDATE_COLLECTION_IMAGE, true));
		api({
			Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		})
			.put(`${COLLECTION_ROUTE_API}/${collectionId}/images/${id}`, payload)
			.then(res => {
				dispatch(onSuccessRequest(UPDATE_COLLECTION_IMAGE, res.data));
				dispatch(onRequest(COLLECTION_CHILDS_REQUEST, UPDATE_COLLECTION_IMAGE, false));
			})
			.catch(err => {
				dispatch(onFailedRequest(COLLECTION_CHILDS_ERROR, UPDATE_COLLECTION_IMAGE, err, id));
				dispatch(onRequest(COLLECTION_CHILDS_REQUEST, UPDATE_COLLECTION_IMAGE, false));
			});
	}
)

const createCollectionImage = (collectionId, payload) => (
	dispatch => {
		dispatch(onRequest(COLLECTION_CHILDS_REQUEST, CREATE_COLLECTION_IMAGE, true));
		api({
			Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		})
			.post(`${COLLECTION_ROUTE_API}/${collectionId}/images`, payload)
			.then(res => {
				dispatch(onSuccessRequest(CREATE_COLLECTION_IMAGE, res.data));
				dispatch(onRequest(COLLECTION_CHILDS_REQUEST, CREATE_COLLECTION_IMAGE, false));
			})
			.catch(err => {
				dispatch(onFailedRequest(COLLECTION_CHILDS_ERROR, CREATE_COLLECTION_IMAGE, err));
				dispatch(onRequest(COLLECTION_CHILDS_REQUEST, CREATE_COLLECTION_IMAGE, false));
			});
	}
)

export const getCollectionSellerList = (collectionId, q) => (
	dispatch => {
		dispatch(onRequest(COLLECTION_CHILDS_REQUEST, GET_LIST_COLLECTION_SELLER, true));
		api({
			Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		})
			.get(`${COLLECTION_ROUTE_API}/${collectionId}/users`, q)
			.then(res => {
				dispatch(onSuccessRequest(GET_LIST_COLLECTION_SELLER, res.data));
				dispatch(onRequest(COLLECTION_CHILDS_REQUEST, GET_LIST_COLLECTION_SELLER, false));
			})
			.catch(err => {
				dispatch(onFailedRequest(COLLECTION_CHILDS_ERROR, GET_LIST_COLLECTION_SELLER, err));
				dispatch(onRequest(COLLECTION_CHILDS_REQUEST, GET_LIST_COLLECTION_SELLER, false));
			});
	}
)

export const createCollectionSeller = (collectionId, payload) => (
	dispatch => {
		dispatch(onRequest(COLLECTION_CHILDS_REQUEST, CREATE_COLLECTION_SELLER, true));
		api({
			Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		})
			.post(`${COLLECTION_ROUTE_API}/${collectionId}/users`, payload)
			.then(res => {
				dispatch(onSuccessRequest(CREATE_COLLECTION_SELLER, res.data));
				dispatch(onRequest(COLLECTION_CHILDS_REQUEST, CREATE_COLLECTION_SELLER, false));
			})
			.catch(err => {
				dispatch(onFailedRequest(COLLECTION_CHILDS_ERROR, CREATE_COLLECTION_SELLER, err));
				dispatch(onRequest(COLLECTION_CHILDS_REQUEST, CREATE_COLLECTION_SELLER, false));
			});
	}
)

export const deleteCollectionSeller = (id) => (
	dispatch => {
		dispatch(onRequest(COLLECTION_CHILDS_REQUEST, DELETE_COLLECTION_SELLER, true));
		api({
			Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		})
			.delete(`${COLLECTION_ROUTE_API}/users/${id}`)
			.then(res => {
				dispatch(onSuccessRequest(DELETE_COLLECTION_SELLER, res.data));
				dispatch(onRequest(COLLECTION_CHILDS_REQUEST, DELETE_COLLECTION_SELLER, false));
			})
			.catch(err => {
				dispatch(onFailedRequest(COLLECTION_CHILDS_ERROR, DELETE_COLLECTION_SELLER, err, id));
				dispatch(onRequest(COLLECTION_CHILDS_REQUEST, DELETE_COLLECTION_SELLER, false));
			});
	}
)

export const updatePosition = (id, payload) => {
	return dispatch => (
		dispatch(isFetch({ type: 'fetch', status: true })),
		api({
			Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).put(`${COLLECTION_ROUTE_API}/${id}/product_variants/position`, payload)
			.then(res => {
				console.log(res)
			})
			.catch(err => {
				console.log(err)
			})
	)
}