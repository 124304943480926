import React from 'react';
import syled from 'styled-components';
import { TextRupiah } from '../../../components/Text';

const CompensationAmount = syled.div`
  display: inline-block;
  padding: 6px 12px;
  font-size: .75rem;
  color: #fff;
  border: ${props => props.color?(props.color === 'danger'? '1px solid #f86c6b': props.color === 'success'?'1px solid #4dbd74': '1px solid #f8cb00'): '1px solid #f8cb00'};
  background-color: ${props => props.color?(props.color === 'danger'? ' #f86c6b': props.color === 'success'?' #4dbd74': ' #f8cb00'): '#f8cb00'};
`;

const Compensation = props => (
  <div style={{marginBottom: '.65rem'}}>
    {
      props.status?
        <CompensationAmount color={props.status === 'APPROVED'? 'success': props.status === 'REJECTED'? 'danger':'default'}>
          <span style={{fontSize: '.5rem', fontWeight: 700}}>COMPENSATION</span> <br/>
          <TextRupiah tag="span" prefix="Rp. " value={props.compensation?props.compensation: '0'}/>
        </CompensationAmount>
      :null
    }
    <div className="w-100"></div>
    {
      props.showButton?(
        <a
          href="#"
          style={{color: '#4a4a4a', fontSize: '.75rem', marginBottom: '.65rem'}}
          onClick={props.onFollowUpClick}
        >
          <i className="icon-share-alt"></i> {props.status? 'process': 'set'} follow-up
        </a>
      ): null
    }
  </div>
)
export default Compensation;
