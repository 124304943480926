import React from 'react';
import moment from 'moment-timezone';
import {
  Col,
  Card,
  CardText,
  Alert,
  Button,
  CardBody,
  ButtonGroup,
  CardHeader,
} from 'reactstrap';
import { Tbl, TblHead, TblBody } from '../../components/Tbl';
import { generateNumber, convertToRupiah } from '../../utils';
import Paginations from '../../components/Paginations';
import { ModalSubsidy }  from './Modals/ModalSubsidy';
import { TextRupiah } from '../../components/Text';
import { ModalDelete } from '../../components/Modals';

const dataHeader = ['No. ', 'Condition', 'Price', 'Action'];
moment.tz('Asia/Jakarta');

const getConditionName = (conditions, preOrder) => {
  switch(conditions){
    case "BARU": return preOrder? "Pre-Order": "Brand New"
    case "BEKAS": return "Pre-Owned"
    case "PRISTINE": return "Pristine"
    case "GOOD": return "Good"
    case "WELL_USED": return "Well Used"
    case "LIKE_NEW": return "Like New"
    case "VINTAGE": return "Vintage"
    default: return "Undefined"
  }
}

export default class Subsidy extends React.Component{
  constructor(props){
    super(props);
    this.state={
      _params:{
        variant_id: props.productVariantId,
        per_page: 5
      },
      _error:null,
      _selectedId:null,
      _modalDelete:false,
      _modalSubsidy:false,
      _subsidySelected:null,
      _selectedRow:null,
      _isAlertErrorOpen:false
    }
    this._toggle = this._toggle.bind(this);
    this._handleGoto = this._handleGoto.bind(this);
    this._onRemoveSelected=this._onRemoveSelected.bind(this);
    this._handleModalDeleteToggle = this._handleModalDeleteToggle.bind(this);
    this._handleDeleteSubsidy = this._handleDeleteSubsidy.bind(this);
    this._handleAlertErrorToggle = this._handleAlertErrorToggle.bind(this);
    this._getList = this._getList.bind(this);
  }

  componentDidMount(){
    this._getList(this.state._params)
  }

  componentDidUpdate(prevProps) {
    const { dataSubsidy } = this.props;
    if(this.state._modalDelete&&dataSubsidy.isSubmit!==prevProps.dataSubsidy.isSubmit
      &&dataSubsidy.isSubmit===false){
        if(dataSubsidy.isDelete){
          this.setState({
            _modalDelete:false,
            _selectedId:null,
            _params:{
              ...this.state._params,
              page: 1
          }}, ()=>{
            this._onRemoveSelected();
            this._getList(this.state._params);
          });
        }
    }
    if(dataSubsidy.isSubmit!==prevProps.dataSubsidy.isSubmit
      &&dataSubsidy.isSubmit===false){
        const { error } = dataSubsidy;
        if(error)
          this.setState({_modalDelete:false, _error:{...dataSubsidy.error}}, ()=>this._handleAlertErrorToggle());
    }
  }

  _getList(rawParams = {} ){
    const params = Object.keys(rawParams)
    .filter(key => rawParams[key]!='')
    .reduce((current, key) =>{
      return {...current, [key]: rawParams[key]}
    },{});
    this.props.getSubsidyList({params});
  }

  _onRemoveSelected(){
    this.setState({
      _isAlertErrorOpen:false,
      _subsidySelected:null,
      _selectedRow:null,
      _error:null
    });
  }

  _toggle() {
    this.setState({
      _modalSubsidy: !this.state._modalSubsidy
    });
  }

  _handleAlertErrorToggle(){
    this.setState({
      _isAlertErrorOpen: !this.state._isAlertErrorOpen
    })
  }

  async _handleGoto(page){
    const _params=this.state._params;
    _params.page=page;
    await this.setState({_params});
    this._getList(this.state._params)
  }

  _handleDeleteSubsidy(){
    const { _selectedId } = this.state;
    this.props.onDelete(_selectedId);
  }

  _handleModalDeleteToggle(item){
    this.setState({
      _isAlertErrorOpen:false,
      _subsidySelected: item? item: null,
      _selectedId:item? item.id: null,
      _modalDelete: !this.state._modalDelete
    });
  }

  _onOpenModal(item){
    this.setState({_isAlertErrorOpen:false, _subsidySelected: item, _selectedRow: item.id},()=>this._toggle());
  }

  _renderButtonAction(item){
    return(
      <ButtonGroup>
        <Button color="success" onClick={()=>this._onOpenModal(item)}><i className="icon-pencil"></i></Button>
        <Button color="danger" onClick={()=>this._handleModalDeleteToggle(item)}><i className="icon-trash"></i></Button>
      </ButtonGroup>
    )
  }

  _onRenderPagination(){
    const { status_code, list, ...paginations } = this.props.dataSubsidy;
    const hasData = (status_code===200 && list.length) || null;
    return hasData && (
      <Paginations
        size="sm"
        lastPage={paginations.last_page}
        perPage={paginations.per_page}
        total={paginations.total}
        maxPage={8}
        path={paginations.path}
        currentPage={paginations.current_page}
        prevPageUrl={paginations.prev_page_url}
        nextPageUrl={paginations.next_page_url}
        handleGotoCallback={this._handleGoto}
      />
    )
  }

  _onRenderRowItem(){
    if(this.props.dataSubsidy.status_code===200&&this.props.dataSubsidy.list.length>0)
    {
      const { current_page, per_page, list } =  this.props.dataSubsidy;
      return list.map((item,index)=>(
          <tr key={generateNumber(per_page,current_page,index)}>
            <th scope="row">{ generateNumber(per_page,current_page,index) }</th>
            <td className="v-center">
              <p className="mb-0">
                {getConditionName(item.sneakers_condition, item.pre_order)}
              </p>
              <p className="mb-0" style={{fontSize: '.65rem'}}>
                Type: {item.type} <br/>
                Max amount: {item.max_amount && item.max_amount > 0 ? convertToRupiah(parseFloat(item.max_amount)):"-"}<br/>
                start: {item.started_at? moment(item.started_at).format('DD MMM YY hh:mm a'):'always open'} <br/>
                end: {item.ended_at? moment(item.ended_at).format('DD MMM YY hh:mm a'):'no expiry'}
              </p>
              <small>#{item.id} <i className="fa fa-calendar"></i> {item.updated_at}</small>
            </td>
            <td className="v-center">
              {item.type === 'percentage'?  parseInt(item.price) + '%' : <TextRupiah prefix="Rp. " value={item.price}/>}
            </td>
            <td className="v-center">{ this._renderButtonAction(item) }</td>
          </tr>
        )
      )
    }
    return null;
  }

  _renderErrorMsg(){
    const { _isAlertErrorOpen, _error } = this.state;
    if(_isAlertErrorOpen && _error!==null){
      switch(_error.status_code){
        case 422:
          return <Alert color="danger" isOpen={_isAlertErrorOpen} toggle={this._handleAlertErrorToggle}>Please fulfill your form</Alert>
        default:
          return <Alert color="danger" isOpen={_isAlertErrorOpen} toggle={this._handleAlertErrorToggle}>Houston, we have a problem!. Please inform our friendly staff via LINE @kickavenue</Alert>
      }
    }
    return null;
  }

  render(){
    return(
      <Card>
      <Col xs={12}>
        <ModalDelete
          isOpen={this.state._modalDelete}
          toggle={this._handleModalDeleteToggle}
          onDelete={this._handleDeleteSubsidy}
        />
        <ModalSubsidy
          isOpen={this.state._modalSubsidy}
          onCancel={this._toggle}
          onRemoveSelected={this._onRemoveSelected}
          dataSubsidy={this.props.dataSubsidy}
          variantId={this.props.productVariantId}
          onSubmit={this.props.saveSubsidy}
          subsidySelected={this.state._subsidySelected}
          onGetSubsidyList={async () => {
            await this.setState({
              _params:{
                ...this.state._params,
                page: 1
              }
            });
            this._getList(this.state._params)
          }}
        />
      </Col>
      <CardHeader>
        <i className="fa fa-align-justify"></i> Subsidy
      </CardHeader>
      <CardBody>
        <Alert color="info">
          <strong>Info</strong> subsidies only affect <strong>hot product</strong>.
        </Alert>
        <div className="mb-3">
          <Button title="New Subsidy" onClick={this._toggle}><i className="fa fa-plus"></i> New Subsidy</Button>
        </div>
        { this._renderErrorMsg() }
        <div className="table-responsive">
          <Tbl>
            <TblHead
                dataHeader={dataHeader}
            />
            <TblBody
              isLoad={this.props.dataSubsidy.isFetch}
              hasRow={this.props.dataSubsidy.status_code===200&&this.props.dataSubsidy.list.length>0}
              columnCount={dataHeader.length}
            >
              { this._onRenderRowItem() }
            </TblBody>
          </Tbl>
        </div>
        { this._onRenderPagination() }
      </CardBody>
    </Card>
    )
  }
}