import React from 'react';
import {
  Card,
  CardImg,
  CardText,
  CardLink,
  CardBody,
} from 'reactstrap';
import styled from 'styled-components';
import { getCacheImage } from '../../../utils/imageHelper'

const CardFooter = styled.div`
  justify-content: center;
  display: flex;
  align-items: center;
`;

const getPublicImageUrl = image => {
  return image.signed_url || image.URL
}

const ImageCard = props => (
  <Card body  style={{'padding': 0}}>
    <CardImg src={getCacheImage(getPublicImageUrl(props))} alt={props.alt}/>
    <CardBody style={{paddingTop: 0}}>
      <CardText>
        <small className="text-muted">Last updated {props.updated_at}</small>
      </CardText>
      <CardFooter>
        <CardLink href={getCacheImage(getPublicImageUrl(props))} className="text-default text-center" target="_blank"><i className="fa fa-external-link"></i> Open</CardLink>
      </CardFooter>
    </CardBody>
  </Card>
)

export default ImageCard;
