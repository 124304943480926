import React from 'react';
import { Button, Col, Container, Form, FormGroup, Input, Label, Row } from "reactstrap";
import {
    consignmentShippingMethodList,
    consignmentStatusOptions,
} from "../../../../../constants/kickavenue";
import SelectRack from "../../../../../components/Form/Select/Async/SelectRack";
import { UserSelectAsync } from "../../../../Components/UserSelectAsync";

const filterShippingMethodOptions = [{ name: 'All', value: '' }, ...consignmentShippingMethodList];

export default function SearchForm(props) {
    const {
        keyword,
        onInputChange,
        seller,
        onSellerChange,
        shippingMethod,
        onSubmit,
        onSearch,
    } = props;

    const onApplyFilter = (e) => {
        e.preventDefault();
        onSubmit();
    };

    const onPressedSearch = (e) => {
        e.preventDefault();
        onSearch();
    };

    const onKeyPress = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            onSearch();
        }
    }

    return (
        <Form inline>
            <Container style={{ padding: 0 }}>
                <Row className="mb-1">
                    <Col xs={10} sm={6}>
                        <FormGroup row className="mb-3">
                            <Col xs={12} sm={12}>
                                <Input
                                    className="w-100"
                                    type="text"
                                    placeholder="Search CG ID, SKU, or Product Name..."
                                    name="keyword"
                                    value={keyword}
                                    onChange={(e) => onInputChange(e, 'keyword')}
                                    onKeyDown={onKeyPress}
                                />
                            </Col>
                        </FormGroup>
                    </Col>
                    <Col xs={2} sm={6}>
                        <FormGroup row className="mb-3">
                            <Col xs={12}>
                                <Button color="primary" onClick={onPressedSearch}>Search</Button>
                            </Col>
                        </FormGroup>
                    </Col>
                </Row>
                <Row className="mb-1">
                    <Col xs={12} sm={6}>
                        <FormGroup className="mb-3">
                            <Col xs={12} sm={12}>
                                <Label className="justify-content-start mb-1">
                                    Seller
                                </Label>
                            </Col>
                            <Col xs={12} sm={12}>
                                <UserSelectAsync
                                    onChange={onSellerChange}
                                    value={seller}
                                />
                            </Col>
                        </FormGroup>
                    </Col>
                </Row>
                <Row className="mb-1">
                    <Col xs={12} sm={6}>
                        <FormGroup className="mb-3">
                            <Col xs={12}>
                                <Label className="justify-content-start mb-1">
                                    Shipping Method
                                </Label>
                            </Col>
                            <Col xs={12}>
                                <Input
                                    className="w-100"
                                    type="select"
                                    name="shippingMethod"
                                    onChange={(e) => onInputChange(e, 'shippingMethod')}
                                    value={shippingMethod}
                                >
                                    {filterShippingMethodOptions.map((option, i) => <option value={option.value} key={i}>{option.name}</option>)}
                                </Input>
                            </Col>
                        </FormGroup>
                    </Col>
                </Row>
                <Row className="mb-1">
                    <Col xs={12}>
                        <FormGroup>
                            <Col xs={12}>
                                <Button color="primary" onClick={onApplyFilter}>Apply Filter</Button>
                            </Col>
                        </FormGroup>
                    </Col>
                </Row>
            </Container>
        </Form>
    )
}
