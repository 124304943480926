import React from 'react';
import propTypes from 'prop-types';
import {
  Card,
  CardHeader,
  CardBody,
  CardText,
  Alert
} from 'reactstrap';
import FontAwesomeIcon from '../../../components/Icons/FontAwesomeIcon';
import CardItem from './itemList/CardItem';

const ItemList = props => (
  <Card>
    <CardHeader>
      <FontAwesomeIcon iconType="align-justify"/>Disbursement Items
    </CardHeader>
    <CardBody>
      <CardText>Display all items for your references.</CardText>
      <Card className="mb-0" body style={{padding: 0, border: 'none'}}>
        {
          props.list.length>0? props.list.map((item, index)=>(
            <CardItem
              key={index}
              itemId={item.disbursementable_id}
              invoiceNumber={item.disbursementable ? item.disbursementable.ref_number : '-'}
              amount={parseInt(item.amount)}
              itemType={item.item_type}
              // itemType="UNKNOWN"
              status={item.disbursementable ? item.disbursementable.status : '-'}
              createdAt={item.disbursementable ? item.disbursementable.created_at: '-'}
              updatedAt={item.disbursementable ? item.disbursementable.updated_at : '-'}
              onMoreDetailClick={(link)=>props.onRedirect(link)}
            />
          )) :
          <Alert color="danger" className="text-center">
            <FontAwesomeIcon iconType="exclamation-triangle"/> No Record Found!
          </Alert>
        }
      </Card>
    </CardBody>
  </Card>
)

export default ItemList;

ItemList.defaultProps={
  list: []
}

ItemList.propTypes={
  list: propTypes.arrayOf(propTypes.object),
  onRedirect: propTypes.func.isRequired
}