import React, { PureComponent as Component } from 'react';
import{
  Card,
  CardText,
  Row,
  Col
} from 'reactstrap';
import propTypes from 'prop-types';

import { UserDetail } from '../Users';
import SellerVerificationList from './sellerDetail/SellerVerificationList';
import UserBankAccountList from './sellerDetail/UserBankAccountList';

export default class SellerDetail extends Component{
  render(){    
    return(      
      <Card body className={this.props.className}>
        <CardText>Your seller details.</CardText>
        <Row>
          <Col xs={12} md={6}>
            <UserDetail
              headerText={this.props.headerText}
              isLoading={this.props.isLoading}
              user={this.props.seller}
              displayRole
            />
          </Col>
          <Col xs={12}>
            <UserBankAccountList
              userId={this.props.seller.id}
              isUserFetching={this.props.isLoading}
              dataBankAccount={this.props.dataBankAccount.list}
              onGotoPage={this.props.handleGotoPageBankAccountList}
            />
          </Col>
          <Col xs={12}>
            <SellerVerificationList
              data={this.props.seller.seller_verification_info}
            />
          </Col>
        </Row>
      </Card>
    )
  }
}
SellerDetail.propTypes= {
  headerText: propTypes.string,
  isLoading: propTypes.bool,
  seller: propTypes.object,
  dataBankAccount: propTypes.object,
  handleGotoPageBankAccountList: propTypes.func
}
SellerDetail.defaultProps = {
  seller:{
    id: null
  },
  headerText: "Seller Details",
  isLoading: false,
  dataBankAccount: {
    list: {}
  }
}