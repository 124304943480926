import React from 'react';
import {
  Card,
  CardHeader,
  CardBody,
  // Button,
  Row,
  Label,
  Input
} from 'reactstrap';
import Loading from 'react-loading-animation';
import Size, { SizeList } from './Size';

export default class ProductVariantSizes extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      sizeList: [],
      hasGroup: false
    }
    this._handleDetachAllSize = this._handleDetachAllSize.bind(this);
  }

  componentDidUpdate(prevProps){
    if((prevProps.isLoading && !this.props.isLoading)
      ||(prevProps.isSubmit && !this.props.isSubmit)){
      const { sizes } = this.props;
      if(sizes){
        let sizeList = [];
        sizeList = this.props.sizes.reduce((arr, item)=>{
          const { pivot } = item;
          if(!arr.includes(pivot.size_type)) return [...arr, pivot.size_type];
          return arr;
        },[]).map(alias => ({
          group: alias,
          items: this.props.sizes
            .filter(item => item.pivot.size_type === alias)
            .map(item => {
              const { pivot, ...size } = item;
              return {
                ...size,
                alias: pivot.size_type,
                order: pivot.size_order,
                taken: false,
                disabled: false
              }
            })
            .sort((a, b) => {
              if(a.US.length === 0) return 1;
              let usA = a.US;
              let usB = b.US;
              if((/-/).test(usA)){
                const splitted = usA.split('-');
                usA = splitted[0];
              }
              if((/-/).test(usB)){
                const splitted = usB.split('-');
                usB = splitted[0];
              }
              return usA - usB;
            })
        }))
        .sort((a, b)=> a.group < b.group? -1: 1);
        if(sizeList.length === 1) {
          sizeList = sizeList[0].items.map(item => ({...item}));
        }
        this.setState({ sizeList, hasGroup: sizeList.length && sizeList[0].items !== undefined })
      }
    }
  }

  _handleCheckedChangeItem(e, key, item, groupKey){
    const sizeList = [...this.state.sizeList];
    const { hasGroup } = this.state;
    if(hasGroup){
      sizeList[groupKey].items[key] = { ...item, taken: e.target.checked }
    }
    else{
      sizeList[key] = { ...item, taken: e.target.checked }
    }
    this.setState({
      sizeList: [ ...sizeList ]
    });
  }

  _handleCheckedChangeGroupItem(e, groupKey) {
    const sizeList = [...this.state.sizeList];
    sizeList[groupKey].items = sizeList[groupKey].items.map(item => ({
      ...item,
      taken: e.target.checked
    }));
    this.setState({
      sizeList: [ ...sizeList ]
    });
  }

  // onDetachSizes
  async _handleDeleteSizeItem(e, item, groupKey = null){
    e.preventDefault();
    let sizeList = [...this.state.sizeList];
    if(groupKey)
      sizeList[groupKey].items = sizeList[groupKey].items
        .filter(size => size.id !== item.id);
    else
      sizeList = sizeList.filter(size => size.id !== item.id);
    await this.setState({ sizeList })
    this.props.onDetachSizes({ size_id: [item.id] })
  }

  async _handleDetachAllSize(e){
    e.preventDefault();
    const { sizeList, hasGroup } = this.state;
    if(sizeList.length){
      const selectedList = (
        hasGroup? sizeList.reduce((arr, size)=>{
          return [ ...arr, ...size.items ];
        }, []):
        sizeList
      ).filter(size => size.taken === true)
      .map(({id}) => id);
      if(selectedList.length){
        const not = (hasGroup? sizeList.reduce((arr, size)=>{
            return [ ...arr, ...size.items ];
          }, [])
          : sizeList)
          .filter(size => size.taken === false);
        const _sizeList = [...not]
          .reduce((arr, item) => {
            if(!arr.includes(item.alias)) return [...arr, item.alias];
            return arr;
          }, [])
          .map(group =>({
            group,
            items: not.filter(n => n.alias === group)
          }));
        await this.setState({
          sizeList: _sizeList.length === 1 && _sizeList[0].items !== undefined?
            [..._sizeList[0].items]: [..._sizeList],
          hasGroup: _sizeList.length > 1 && _sizeList[0].items !== undefined
        });
        this.props.onDetachSizes({size_id: [ ...selectedList ]});
      }
      else{
        this.setState({
          sizeList: [],
          hasGroup: false
        });
        this.props.onDetachSizes({
          size_id: (hasGroup? sizeList.reduce((arr, size)=>{
              return [ ...arr, ...size.items ];
            }, []): sizeList)
            .map(({id}) => id)
        });
      }
    }
  }

  _renderSizesItem(){
    const { sizeList, hasGroup } = this.state;
    if(hasGroup){
      return(
        this.state.sizeList.map((sizeGroup, grpKey) => (
          <SizeList key={grpKey}>
            <p style={{fontWeight: 700}}>
              <Label check style={{display: 'flex', alignItems: 'flex-start', cursor: 'pointer'}}>
                <Input type="checkbox"
                  onChange={e => this._handleCheckedChangeGroupItem(e, grpKey)}
                  checked={sizeList[grpKey].items.filter(({taken})=>taken === true).length === sizeList[grpKey].items.length}
                  value="true"
                />
                <span>{sizeGroup.group}</span>
              </Label>
            </p>
            <Row>
              {
                sizeGroup.items
                  .map((item, key)=>(
                  <Size
                    {...item}
                    withDelete
                    key={key}
                    onCheckedChange={(e)=>this._handleCheckedChangeItem(e, key, item, grpKey)}
                    onDeleteItem={e => this._handleDeleteSizeItem(e, item, grpKey)}
                  />
                ))
              }
            </Row>
          </SizeList>
        ))
      )
    }
    else{
      return(
        <SizeList>
          <Row>
            {
              sizeList.map((item, key)=>(
                <Size
                  {...item}
                  withDelete
                  key={key}
                  onCheckedChange={(e)=>this._handleCheckedChangeItem(e, key, item)}
                  onDeleteItem={e => this._handleDeleteSizeItem(e, item)}
                />
              ))
            }
          </Row>
        </SizeList>
      )
    }
  }

  _renderSelectCount(){
    const { sizeList, hasGroup } = this.state;
    let count = 0;
    let total = sizeList.length;
    if(hasGroup){
      const itemsArr = sizeList.reduce((arr, {items}) => ([...arr, ...items]), []);
      total = itemsArr.length;
      count = itemsArr.filter(({ taken }) => taken === true).length;
    }
    else{
      count = sizeList.filter(({ taken }) => taken === true).length;
    }
    return (
      <span>Remove {count? (count !== total ? `(${count})` : 'All'): 'All'}</span>
    )
  }

  render(){
    return(
      <Card>
        <CardHeader>
          <i className="fa fa-align-justify"></i> Sizes
        </CardHeader>
        <CardBody>
          <Loading isLoading={this.props.isLoading}>
            <p>
              <a
                href="#"
                className="link text-primary"
                onClick={e => {
                  e.preventDefault();
                  this.props.onOpenBrowseAllSizeModal()
                }}
              >
                <i className="icon-plus"></i> Browse Sizes
              </a>
              &nbsp;
              &nbsp;
              <a
                href="#"
                className="link text-danger"
                onClick={this._handleDetachAllSize}
              >
                <i className="icon-trash mr-1"></i>
                {this._renderSelectCount()}
              </a>
            </p>
            { this._renderSizesItem() }
            {/* <td><Button color="danger" onClick={()=>this.handleDetachSize(item.id)}><i className="icon-trash"></i></Button></td> */}
          </Loading>
        </CardBody>
      </Card>
    )
  }
}