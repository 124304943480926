import React from 'react';
import propTypes from 'prop-types';
import { Link } from 'react-router-dom';

import DateToHuman from '../../../components/DateToHuman';
import BrandColumn from '../../../views/ProductVariants/productVariantIndex/rowItem/BrandColumn';
import DisplayNameColumn from '../../../views/ProductVariants/productVariantIndex/rowItem/SummariesColumn';

const RowItem = props =>{
  const { rowId, id, brand, displayName, colour, editorsChoiceEdit, SKU, quantity, active, updatedAt, category } = props;
  return(
    <tr>
      <th scope="row" className="v-center">{ rowId }</th>
      <td className="v-center">
        <BrandColumn
          id={id}
          brand={brand}
        />
      </td>
      <td className="v-center">
        <DisplayNameColumn
          id={id}
          categoryName={category}
          displayName={displayName}
          SKU={SKU}
          colour={colour}
          active={active}
          editorsChoice={editorsChoiceEdit}
        />
      </td>
      <td className="v-center">{ quantity }</td>
      <td className="v-center"><DateToHuman value={updatedAt} id={`tooltip_${rowId}`}/></td>
      <td className="v-center">
        <div className="btn-group">
          <Link to={`stock/${id}`} className="btn btn-secondary btn-sm"><i className="fa fa-folder-open-o"></i> Open</Link>
          <Link to={`stock/${id}/add`} className="btn btn-primary btn-sm"><i className="fa fa-plus"></i> Stock</Link>
        </div>
      </td>
    </tr>
  )
}
export default RowItem;
RowItem.defaultProps = {
  active: false,
  editorsChoice: false,
  quantity: 0,
}
RowItem.propTypes = {
  id: propTypes.number.isRequired,
  brand: propTypes.object,
  rowId: propTypes.number.isRequired,
  displayName: propTypes.string,
  colour: propTypes.string,
  active: propTypes.oneOfType([propTypes.bool, propTypes.number]),
  updatedAt: propTypes.string,
  editorsChoice: propTypes.oneOfType([propTypes.number, propTypes.bool])
}