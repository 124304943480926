import React from 'react';
import propTypes from 'prop-types';
import{
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input, 
  Button,
  Row,
  Col,
  Container
} from 'reactstrap';
import classnames from 'classnames';
import ButtonLoading from '../../../../components/Button/ButtonLoading';

import { hasError, errorMessage, textChange, textValue } from '../../../../utils/form';

export default class ModalUserBankAccount extends React.Component{
  constructor(props){
    super(props);
    this.state={
      mode: 'NEW',
      error: null
    }
    this.handleChange = this.handleChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  handleChange(e){
    this.setState(textChange(e,{...this.state}));
  }

  onSubmit(e){
    e.preventDefault();
    const { 
      userId,
      id,
      bankName,
      accountName,
      accountNumber,
      mode
    } = this.state;
    const payload = { bank_name: bankName, account_name: accountName, account_number: accountNumber };
    if(mode==='EDIT'){
      payload.id = id;
    }
    this.setState({
      error: null
    })
    this.props.onSubmit(userId, payload);
  }
  componentDidUpdate(lastProps){
    if(lastProps.isOpen!=this.props.isOpen && this.props.isOpen){
      const {
        userId,
        id, 
        bankName,
        accountName,
        accountNumber,
        mode
      } = this.props;
      this.setState({
        userId,
        id, 
        bankName,
        accountName,
        accountNumber,
        mode,
        error: null
      })
    }
  }
  componentWillReceiveProps(nextProps){    
    if(nextProps.dataBankAccount.isSubmit !== this.props.dataBankAccount.isSubmit && !nextProps.dataBankAccount.isSubmit){
      console.log(nextProps.dataBankAccount.error!==null);
      
      if(nextProps.dataBankAccount.error!==null){
        this.setState({
          error: nextProps.dataBankAccount.error
        })
      }
    }
  }

  render(){
    return(
      <Modal
        isOpen={this.props.isOpen}
        toggle={this.props.onCancel} 
        className="modal-dialog modal-md"
      >
        <ModalHeader toggle={this.props.onCancel}>
          User Bank Account Form
        </ModalHeader>
        <Form onSubmit={this.onSubmit}>
          <ModalBody>
            <Container>
              <Row>
                <Col xs={12}>
                  <FormGroup  className={classnames({'has-danger has-feedback':hasError('bank_name',this.state.error)})}>
                    <Label for="formBankAccountBankName">Bank Name</Label>
                    <Input
                      type="text"
                      id="formBankAccountBankName"
                      name="bankName"
                      placeholder="Bank Name example: BCA"
                      value={textValue('bankName',this.state)}
                      onChange={this.handleChange}
                    />
                    {errorMessage('bank_name',this.state.error)}
                  </FormGroup>
                </Col>
                <Col xs={12}>
                  <FormGroup  className={classnames({'has-danger has-feedback':hasError('account_number',this.state.error)})}>
                    <Label for="formBankAccountAccountNumber">Account Number*</Label>
                    <Input
                      type="text"
                      id="formBankAccountAccountNumber"
                      name="accountNumber"
                      placeholder="Account Number"
                      value={textValue('accountNumber',this.state)}
                      onChange={this.handleChange}
                    />
                    {errorMessage('account_number',this.state.error)}
                  </FormGroup>
                </Col>
                <Col xs={12}>
                  <FormGroup  className={classnames({'has-danger has-feedback':hasError('account_name',this.state.error)})}>
                    <Label for="formBankAccountAccountName">Account Name*</Label>
                    <Input
                      type="text"
                      id="formBankAccountAccountName"
                      name="accountName"
                      placeholder="Pemilik No. Rekening"
                      value={textValue('accountName',this.state)}
                      onChange={this.handleChange}
                    />
                    {errorMessage('account_name',this.state.error)}
                  </FormGroup>
                </Col>
              </Row>
            </Container>
          </ModalBody>
          <ModalFooter>
            <ButtonLoading
              color="primary" 
              isLoading={this.props.isSubmit}
            >
              Submit
            </ButtonLoading>{' '}
            <Button type="button" color="secondary" onClick={this.props.onCancel}>Cancel</Button>
          </ModalFooter>
        </Form>      
      </Modal>
    )
  }
}
ModalUserBankAccount.defaultProps={
  mode: 'NEW',
  isOpen: false,
  isSubmit: false
}
ModalUserBankAccount.propTypes={
  mode: propTypes.oneOf(['NEW', 'EDIT']),
  isOpen: propTypes.bool,
  isSubmit: propTypes.bool,
  userId: propTypes.number.isRequired,
  id: propTypes.number,
  bankName: propTypes.string,
  accountName: propTypes.string,
  accountNumber: propTypes.string,
  onCancel: propTypes.func,
  onSubmit: propTypes.func,
  dataBankAccount: propTypes.object.isRequired
}