import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Alert,
  Form,
  Label,
  Row,
  Col,
  Input,
  FormGroup,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import classNames from "classnames";
import { hasError, textValue } from "../../../utils/form";

import {
  createArticleCategory,
  deleteArticleCategory,
  getDetailArticleCategory,
  updateArticleCategory,
} from "../../../actions/articleAction";

import { ValidationMessage } from "../../../components/Form/ValidationMessage";
import privateView from "../../../components/hocs/privateView";

const initialState = {
  _edit: false,
  _hasId: false,
  _form: {
    binding: false,
    id: "",
    name: "",
    slug: "",
    description: "",
    active: false,
    created_at: "",
    updated_at: "",
  },
  _formSubmit: false,
  _modalDelete: false,
}

class ModalArticleCategoryForm extends Component {
  constructor(props) {
    super(props);
    console.log(this.props.edit);
    this.state = {...initialState};

    this.handleCheckedChange = this.handleCheckedChange.bind(this);
    this.onCloseModal = this.onCloseModal.bind(this);
    this.onSubmit = this.onSubmit.bind(this)
    this._handleToggleModal = this._handleToggleModal.bind(this)
  }

  componentDidUpdate(prevProps) {
    const { detail, isSubmit, error } = this.props.article.category;

    if (this.props.categoryId && this.props.edit && !this.state._hasId) {
      this.setState({
        _edit: true,
        _hasId: true
      }, () => {
        this.props.find(this.props.categoryId);
      })
    }

    // Edit Form
    if (this.state._hasId) {
      // Checking if already ok after get detail from api
      if (prevProps.article.category.detail.isFetch !== detail.isFetch 
        && !detail.isFetch && detail.status_code === 200 && error === null) {
        // Binding data when load page and after get detail from api
        if (!detail.isFetch && !this.state._form.binding) {
          const _form = { ...this.state._form };
          _form.binding = true;
          this.setState({ _form }, () => {
            const _newForm = { ...this.state._form, ...detail };
            this.setState({ _form: _newForm });
          });
        }
        // Binding data again when after success update in api
        else if (!isSubmit && this.state._formSubmit) {
          if (this.state._modalDelete) {
            this.toggle();
          }
          const _form = { ...this.state._form };
          _form.binding = true;
          const _newForm = { ...this.state._form, ...detail };
          this.setState({ _formSubmit: !this.state._formSubmit, _form: _newForm }, () => {
            this._handleToggleModal(true)
          });
        }
      }
    }
    // Create Form
    else {
      // Checking if already ok after submit
      if (
        detail.status_code === 200 &&
        error === null &&
        this.state._formSubmit &&
        !isSubmit
      ) {
        this.setState({
          _form: { name: "", created_at: "", updated_at: "" },
          _formSubmit: !this.state._formSubmit
        });

        this._handleToggleModal(true)
      }
    }
  }

  /**
   * Handle to change local state from input uset like textfield
   * @author haikal
   * @param {event} e
   */
  handleChange(e) {
    const newState = { ...this.state._form };
    newState[e.target.name] = e.target.value;
    this.setState({ _form: newState });
  }
  handleCheckedChange(e) {
    const newState = { ...this.state._form };
    newState[e.target.name] = e.target.checked;
    this.setState({ _form: newState });
  }
  onSubmit(e) {
    e.preventDefault();
    this.setState({ _formSubmit: true }, () => {
      const { id, name, description, active } = this.state._form;
      if (this.props.edit && this.state._hasId) {
        this.props.update(id, { name, description, active });
      } else {
        this.props.create({ name, description, active });
      }
    });
  }
  renderLoading() {
    return (
      <div className="row">
        <div className="col-sm-12">
          <Alert color="info" className="text-center">
            Submitting...
          </Alert>
        </div>
      </div>
    );
  }
  renderInfo() {
    let infoMessage = [];
    if (this.props.article.category.isSubmit)
      infoMessage.push(
        <Alert color="info" key="info">
          <strong>Heads up!</strong> Submitting...
        </Alert>
      );
    if (this.props.article.category.success)
      infoMessage.push(
        <Alert color="success" key="success">
          <strong>Well done!</strong> {this.props.article.category.success}
        </Alert>
      );
    if (this.props.article.category.error !== null) {
      const { status_code } = this.props.article.category.error;
      switch (status_code) {
        case 422:
          infoMessage.push(
            <Alert color="danger" key="error">
              <strong>Oh Snap!</strong> Please fullfill your form.{" "}
            </Alert>
          );
          break;
        case 404:
          infoMessage.push(
            <Alert color="danger" key="error">
              <strong>Oh Snap!</strong> Resource not found.{" "}
            </Alert>
          );
          break;
        default:
          infoMessage.push(
            <Alert color="danger" key="error">
              <strong>Oh Snap!</strong> We've got something errors{" "}
            </Alert>
          );
          break;
      }
    }
    return (
      <div className="row">
        <div className="col-sm-12">{infoMessage}</div>
      </div>
    );
  }

  validateError(name) {
    if (this.hasError(name)) {
      let errorList = this.props.article.category.error.errors;
      return <ValidationMessage message={errorList[name][0]} />;
    }
    return "";
  }

  hasError(name) {
    if (this.props.article.category.error) {
      if (this.props.article.category.error.errors) {
        let errorList = this.props.article.category.error.errors;
        let errList = Object.keys(errorList).filter((key) => key == name);
        if (errList.length) return true;
      }
    }
    return false;
  }

  toggle() {
    this.setState({ _modalDelete: !this.state._modalDelete });
  }

  onCloseModal () {
    this.setState({ ...initialState })
  }

  onDelete() {
    this.setState({ _formSubmit: true, _modalDelete:false }, function () {
      this.props.delete(this.state._form.id);
    });
  }

  _handleToggleModal(update = false) {
    this.props.toggle(update)
  }

  render() {
    return (
      <Modal isOpen={this.props.isOpen} toggle={() => this._handleToggleModal(false)} onClosed={this.onCloseModal}>
        <ModalHeader toggle={() => this._handleToggleModal(false)}>
          {this.props.edit ? "Edit Article Category" : "Create Article Category"}
        </ModalHeader>
        <Modal
          isOpen={this.state._modalDelete}
          toggle={this.toggle.bind(this)}
          className="modal-dialog modal-sm"
        >
          <ModalHeader toggle={this.toggle.bind(this)}>
            Confirmation
          </ModalHeader>
          <ModalBody>
            {this.state._modalDelete && this.props.article.category.isSubmit ? (
              <div className="row">
                <div className="col-sm-12">
                  <Alert color="info" className="text-center">
                    Deleting...
                  </Alert>
                </div>
              </div>
            ) : (
              <span>Are you sure to delete this?</span>
            )}
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.onDelete.bind(this)}>
              Yes
            </Button>{" "}
            <Button color="secondary" onClick={this.toggle.bind(this)}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
        <Form onSubmit={this.onSubmit.bind(this)}>
          <ModalBody>
            {this.renderInfo()}
            {this.props.article.category.detail.isFetch ? (
              this.renderLoading()
            ) : (
              <Row>
                <Col xs={12}>
                  <FormGroup
                    className={
                      this.hasError("name") ? " has-danger has-feedback" : ""
                    }
                  >
                    <Label for="formCategorysName">Category Name</Label>
                    <Input
                      type="text"
                      id="formCategorysName"
                      name="name"
                      placeholder="Enter a name*"
                      value={this.state._form.name}
                      onChange={this.handleChange.bind(this)}
                    />
                    {this.validateError("name")}
                  </FormGroup>
                  {this.props.edit && (
                    <FormGroup
                    className={
                      this.hasError("slug") ? " has-danger has-feedback" : ""
                    }
                  >
                    <Label for="formCategorysName">Slug</Label>
                    <Input
                      readOnly
                      type="text"
                      id="formCategorysName"
                      name="slug"
                      placeholder="Enter a slug*"
                      value={this.state._form.slug}
                      onChange={this.handleChange.bind(this)}
                    />
                    {this.validateError("slug")}
                  </FormGroup>
                  )}
                  <FormGroup className={
                      this.hasError("desccription") ? " has-danger has-feedback" : ""
                  }>
                    <Label for="formDescription">Desccription</Label>
                    <Input
                      type="textarea"
                      id="formDescription"
                      name="description"
                      placeholder="Put your description here"
                      value={this.state._form.description}
                      onChange={this.handleChange.bind(this)}
                    />
                    {this.validateError("desccription")}
                  </FormGroup>
                  <FormGroup
                    className={classNames({
                      "has-danger has-feedback": hasError(
                        "active",
                        this.state._error
                      ),
                    })}
                  >
                    <Label for="formCategoryActive">
                      Set to Active &nbsp;
                    </Label>
                    <label className="switch switch-text switch-success-outline-alt">
                      <input
                        type="checkbox"
                        className="switch-input"
                        name="active"
                        id="formCategoryActive"
                        value="true"
                        checked={textValue("active", this.state._form)}
                        onChange={this.handleCheckedChange}
                      />
                      <span
                        className="switch-label"
                        data-on="On"
                        data-off="Off"
                      ></span>
                      <span className="switch-handle"></span>
                    </label>
                    {this.validateError("active")}
                  </FormGroup>
                  <FormGroup>
                    <Label>Created At</Label>
                    <Input
                      type="text"
                      readOnly
                      value={this.state._form.created_at}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>Updated At</Label>
                    <Input
                      type="text"
                      readOnly
                      value={this.state._form.updated_at}
                    />
                  </FormGroup>
                </Col>
              </Row>
            )}
          </ModalBody>
          <ModalFooter>
            {this.props.edit && this.state._form.id && (
              <Button
                color="danger"
                onClick={this.toggle.bind(this)}
                disabled={this.props.article.category.detail.isFetch}
              >
                Delete
              </Button>
            )}
            <Button
              type="submit"
              color="primary"
              disabled={this.props.article.category.detail.isFetch}
              onClick={this.onSubmit}
            >
              {this.state.isSubmit ? "Submitting..." : "Submit"}
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    );
  }
}
const mapStateToProps = ({ auth: { isFetch, roles, isLogged, id, email }, article }) => {
  return {
    article: article,
    guardData: { isFetch, roles, isLogged, id, email }
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    find: (id) => dispatch(getDetailArticleCategory(id)),
    delete: (id) => dispatch(deleteArticleCategory(id)),
    update: (id, payload) => dispatch(updateArticleCategory(id, payload)),
    create: (payload) => dispatch(createArticleCategory(payload)),
  };
};

const enhance = connect(mapStateToProps, mapDispatchToProps);

export default enhance(privateView(ModalArticleCategoryForm, ['blog_admin']));
