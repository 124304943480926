import {
	GET_LIST_BRAND,
	GET_DETAIL_BRAND,
	ADD_BRAND,
	UPDATE_BRAND,
	MOVE_POSITION_BRAND,
	DELETE_BRAND,
	BRAND_ERROR,
	// SUBMITING_BRAND,
	NEW_BRAND,
	FETCHING_BRAND,
	GET_OPTION_LIST_BRAND,
	GET_LIST_ROOT_BRAND,
	BRAND_IMAGE_S3,
  CREATE_BRAND_IMAGE,
  DELETE_BRAND_IMAGE,
  UPDATE_BRAND_IMAGE,
	BRAND_IMAGE_ERROR,
	BRAND_IMAGE_REQUEST,
	UPDATE_POPULAR_BRANDS,
	UPDATE_POPULAR_BRANDS_ERROR,
	UPDATE_POPULAR_BRANDS_SUBMIT,
	GET_POPULAR_BRANDS,
	GET_POPULAR_BRANDS_ERROR,
	GET_POPULAR_BRANDS_FETCH,
	FIX_BRAND,
	FIX_BRAND_ERROR,
	FIX_BRAND_SUBMIT,
	ATTACH_VOUCHER_BRANDS,
	DETACH_VOUCHER_BRANDS
} from '../constants';

/**
 * Initial State
 */
const initialState={
	list:[],
	options:[],
	roots:[],
	detail:{},
	uploadedImage:{
    isPending: false,
    results: null,
    error: null
	},
	isImageDataSubmit: false,
	isFetch:false,
	isSubmit:false,
	error:null,
	success:null,
	popularBrands:{
		isSubmit: false,
		isFetch: false,
		error: null,
		data: []
	},
	fixBrands: {
		isSubmit: false,
		error: null,
		status_code: 0
	}
}

/**
 * Function to manipulate payload when success get offer list
 * @author pewe
 * @param {object} lastState 
 * @param {object} payload 
 */
const onGetBrandList = (lastState,payload) => {
	const {data, message, status_code}=payload;
	return { ...lastState, error:null, success:null, list:{ data, message, status_code } };	
}

/**
 * Function to manipulate payload when success get offer details
 * @author pewe
 * @param {object} lastState 
 * @param {object} payload 
 */
const onGetDetailBrand = (lastState,payload,key=null) => {
	const {data, message, status_code}=payload;
	let body = { ...data, updatedKey: null };
	if(key){
		body = { ...lastState.detail, [key]: data[key], updatedKey: key }
	}
	return { ...lastState, error:null, success:null, detail:{ ...lastState.detail, ...body, message, status_code } };	
}

/**
 * Function to handle when start or end fetching offer api
 * @author pewe
 * @param {object} lastState 
 * @param {objec} bool 
 */
const onFetchingBrand = (lastState,payload) => {
    if(payload.type==='fetch')
        return { ...lastState, isFetch:payload.status }
    else if(payload.type==='submit')
        return { ...lastState, isSubmit:payload.status }

    return { ...lastState, isFetch:false,isSubmit:false }
}

/**
 * Function to handle when fetching offer error triggered
 * @author pewe
 * @param {object} lastState 
 * @param {object} error 
 */
const onBrandError = (lastState,error) => {
	return { ...lastState, ...error, success:null };		
}

const onAddBrand = (lastState,payload) => {
	const {data, message, status_code}=payload;
    return { ...lastState, detail:{ ...data, message, status_code }, success:'Insert has been successfully!',error:null }
}

const onUpdateBrand = (lastState,payload) => {
	const {data, message, status_code}=payload;
    return { ...lastState, detail:{ ...data, message, status_code }, success:'Update has been successfully!',error:null }
	
}
const onDeleteBrand = (lastState,payload) => {
	const {data, message, status_code}=payload;
    return { ...lastState, detail:{ ...data, message, status_code, delete:true }, success:'Delete has been successfully!',error:null }
}

const onGetOption=(lastState,payload)=>{
	const {data, message, status_code}=payload;
	const options = data.map((item)=>{
		return {value:item.id,label:item.name}
	});
    return { ...lastState, options, success:null,error:null }

}

const onGetRootBrands=(lastState,payload)=>{
	const {data, message, status_code}=payload;
    return { ...lastState, roots:[...data], success:null,error:null }

}

const onValidateBrandImgRequest = (lastState, { requestType, value }) => {
	switch(requestType){
    case CREATE_BRAND_IMAGE:
    case UPDATE_BRAND_IMAGE:
    case DELETE_BRAND_IMAGE:
			return {...lastState, isImageDataSubmit: value}
		default: return lastState;
	}
}

const onErrorBrandImgRequest = (lastState, { requestType, error, ...rest }) => {
	switch(requestType){
		case CREATE_BRAND_IMAGE:
    case UPDATE_BRAND_IMAGE:
    case DELETE_BRAND_IMAGE: return {
			...lastState, details: { ...lastState.details, error: { ...error, ...rest } }
		}
    case BRAND_IMAGE_S3: return { ...lastState, uploadedImage: { ...lastState.uploadedImage, isPending: false, error }}
		default: return lastState;
	}
}

/**
 * Offer Reducer
 * @author pewe
 * @param {object} state 
 * @param {object} action 
 */
const brandReducer= (state=initialState, action) => {
	switch(action.type)
	{
		case GET_LIST_BRAND: return onGetBrandList(state,action.payload);
		case GET_DETAIL_BRAND: return onGetDetailBrand(state,action.payload);
		case FETCHING_BRAND: return onFetchingBrand(state,action.payload);
		case BRAND_ERROR:return onBrandError(state,action.payload);
		case UPDATE_BRAND:return onUpdateBrand(state,action.payload);
		case MOVE_POSITION_BRAND:return onUpdateBrand(state,action.payload);
		case ADD_BRAND:return onAddBrand(state,action.payload);
		case DELETE_BRAND:return onDeleteBrand(state,action.payload);
		case GET_OPTION_LIST_BRAND:return onGetOption(state,action.payload)
		case GET_LIST_ROOT_BRAND:return onGetRootBrands(state,action.payload)

		case BRAND_IMAGE_REQUEST: return onValidateBrandImgRequest(state, action.payload);
		case BRAND_IMAGE_ERROR: return onErrorBrandImgRequest(state, action.payload);

		case CREATE_BRAND_IMAGE:
    case UPDATE_BRAND_IMAGE:
    case DELETE_BRAND_IMAGE: {
      const { status_code, message } = action.payload;
      return {
        ...state,
        details: {
          ...state.details,
          status_code,
          message,
          error: null
        }
      }
		}

		case BRAND_IMAGE_S3: {
      return {
        ...state,
        uploadedImage: {
          ...state.uploadedImage,
          error: null,
          ...action.payload,
        }
      }
    }
		case NEW_BRAND:return initialState;
		case UPDATE_POPULAR_BRANDS_SUBMIT: {
			return  {...state, popularBrands: { ...state.popularBrands, isSubmit: action.payload }}
		}
		case GET_POPULAR_BRANDS_ERROR:
		case UPDATE_POPULAR_BRANDS_ERROR: {
			return  {...state, popularBrands: { ...state.popularBrands, error: { ...action.payload } }}
		}
		case GET_POPULAR_BRANDS:
		case UPDATE_POPULAR_BRANDS: {
			return  {...state, popularBrands: { ...state.popularBrands, error: null, ...action.payload }}
		}
		case GET_POPULAR_BRANDS_FETCH: {
			return  {...state, popularBrands: { ...state.popularBrands, isFetch: action.payload }}
		}

		case FIX_BRAND_SUBMIT:{
			return  {...state, fixBrands: { ...state.fixBrands, isSubmit: action.payload }}
		}
		case FIX_BRAND_ERROR:{
			return  {...state, fixBrands: { ...state.fixBrands, error: { ...action.payload } }}
		}
		case FIX_BRAND:{
			return  {...state, fixBrands: { ...state.fixBrands, error: null, ...action.payload }}
		}
		case ATTACH_VOUCHER_BRANDS:return onGetDetailBrand(state,action.payload, 'brand_vouchers');
		case DETACH_VOUCHER_BRANDS:return onGetDetailBrand(state,action.payload, 'brand_vouchers');
		default:return state;
	}
}

export default brandReducer;