import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import InputFile from '../../../../../components/Form/InputFile';
import FontAwesomeIcon from '../../../../../components/Icons/FontAwesomeIcon';

const Wrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(255,255,255,0.7);
  z-index: 1;
  display: ${props => props.isOpen?"flex" : "none"};
  justify-content: center;
  align-items: center;
  flex-direction: column;
`
const ButtonWrapper = styled.div`
  width: 100%;
  text-align: right;
  margin-top: .5rem;
`

const ButtonCancel = styled.button`
  color: #f86c6b;
  font-size: .75rem;
  padding: 6px 12px;
  background: none;
  border: none;
  text-decoration: underline;
  text-align: right;
`

const UploadCard = props => (
  <Wrapper isOpen={props.isOpen}>
    <InputFile
      accept="image/jpeg, image/png"
      multiple={false}
      inputProps={{id: `inputFile-${props.id}`}}
      onChange={props.onChangeImage}
    >
      <FontAwesomeIcon iconType="upload"/> Browse...
    </InputFile>
    <ButtonWrapper>
      <ButtonCancel onClick={props.onCancel}>Cancel</ButtonCancel>
    </ButtonWrapper>
  </Wrapper>
)

export default UploadCard;

UploadCard.defaultProps = {
  isOpen: false
}

UploadCard.propTypes={
  id: propTypes.oneOfType([propTypes.number, propTypes.string]).isRequired,
  isOpen: propTypes.bool,
  onCancel: propTypes.func,
  onChangeImage: propTypes.func
}
