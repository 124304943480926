import React from 'react';
import {
  Alert,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input, 
  Button,
  Row,
  Col
} from 'reactstrap';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import SelectCollection from '../../../../components/Form/Select/Async/SelectCollection';
import SelectRaffleGroup from '../../../../components/Form/Select/Async/SelectRaffleGroup';
import { hasError, errorMessage, textChange, textValue } from '../../../../utils/form';

export class ModalCollection extends React.Component{
  constructor(props){
    super(props);
    moment.tz.setDefault("Asia/Jakarta");
    this.state={
      value:null,
      collection:null,
      raffle_group:null,
      total_ticket:null,
      isSubmit:false,
      isAletError:false,
      error:null
    }
    this._handleOnSelectCollectionChange = this._handleOnSelectCollectionChange.bind(this);
    this._handleOnSelectRaffleGroupChange = this._handleOnSelectRaffleGroupChange.bind(this);
    this._handleOnSelectCollectionOpen = this._handleOnSelectCollectionOpen.bind(this);
    this._handleOnSelectRaffleGroupOpen = this._handleOnSelectRaffleGroupOpen.bind(this);
    this.handleChange=this.handleChange.bind(this);
    this.onSubmit=this.onSubmit.bind(this);
    this.onDismiss = this.onDismiss.bind(this);
  }

  _handleOnSelectCollectionChange(selected){
    let { collection } = this.state;
    collection = selected;
    this.setState({
      collection,
      _recentSearchCollection: selected && this.state._recentSearchCollection
    })
  }

  _handleOnSelectRaffleGroupChange(selected){
    let { raffle_group } = this.state;
    raffle_group = selected;
    this.setState({
      raffle_group,
      _recentSearchRaffleGroup: selected && this.state._recentSearchRaffleGroup
    })
  }

  _handleOnSelectCollectionOpen(){
    if(this.state.collection){
      if(this.state._recentSearchCollection){
        this.refs.selectCollection.loadSelectOptions(this.state._recentSearchCollection)
      }
      else{
        this.refs.selectCollection.loadSelectOptions(this.state.collection.name)
      }
    }
    else{
      this.refs.selectCollection.loadSelectOptions('')
    }
  }

  _handleOnSelectRaffleGroupOpen(){
    if(this.state.raffle_group){
      if(this.state._recentSearchRaffleGroup){
        this.refs.selectRaffleGroup.loadSelectOptions(this.state._recentSearchRaffleGroup)
      }
      else{
        this.refs.selectRaffleGroup.loadSelectOptions(this.state.raffle_group.name)
      }
    }
    else{
      this.refs.selectRaffleGroup.loadSelectOptions('')
    }
  }

  handleChange(e){
    this.setState(textChange(e,{...this.state}));
  }
  componentWillReceiveProps(nextProps){
    const { dataRaffleTickets } = nextProps;
    if(nextProps.isOpen===false&&nextProps.isOpen!==this.props.isOpen) {
      this.setState({
        error: null,
        collection:[],
        isSubmit:false,
        raffle_group:null,
        total_ticket:null,
      },()=>this.props.onRemoveSelected());
    }
    if(dataRaffleTickets.isSubmit!==this.props.dataRaffleTickets.isSubmit
      &&dataRaffleTickets.isSubmit===false&&this.props.isOpen){
        const { error } = dataRaffleTickets;
        if(error){
          this.setState({
            error:{...dataRaffleTickets.error},
            isSubmit:false, isAlertError:true},
            ()=>{
              this.props.onCancelCollection()
            }
          );
        } else {
          this.setState({
            isSubmit:false,
          },()=>{
            this.props.onCancelCollection()
          });
        }
    }
  }

  onDismiss() {
    this.setState({ 
      isAlertError: false, 
      error: null 
    }, ()=>{this.props.onRemoveSelected()});
  }

  _validateForm(){
    let errors={};
    if(this.state.collection.length==0)
      errors['collection']=['The collection field is required..'];
    if(!this.state.raffle_group)
      errors['raffle_group']=['The raffle group field is required..'];
    if(Object.keys(errors).length){
      this.setState({
        isAlertError: true,
        error:{errors:{...errors}, status_code:422,message:'Please fulfill your form *'}
      })
    }
    return errors;
  }

  onSubmit(e){
    e.preventDefault();
    const { dataRaffleTickets } = this.props;
    const valid= this._validateForm();
    const getCollection = this.state.collection.map(collection =>collection.name);
    if(Object.keys(valid).length===0){
      if(dataRaffleTickets.list.data.length > 0){
        const { data } = dataRaffleTickets.list;
        const values = JSON.parse(data[0].value);
        let newValues = values.concat([{
          key:'collection',
          value:getCollection,
          raffle_group:this.state.raffle_group?this.state.raffle_group.name:null,
          total_ticket:this.state.total_ticket,
        }]);
        const payload = {
          name : data[0].name,
          value: JSON.stringify(newValues),
          value_type: data[0].value_type,
          active: data[0].active
        }
        this.setState({
          isSubmit:true,
          error:null,
        },()=>{
          this.props.onSubmit(this.props.settingId,payload);
        });
      }
    }
  }

  _renderError(){
    const { error, isAlertError } = this.state;
    if(error!==null){
      switch(error.status_code){
        case 422:
          return <Alert color="danger" isOpen={isAlertError} toggle={this.onDismiss}>Please fulfill your form</Alert>
        default:
          return <Alert color="danger" isOpen={isAlertError} toggle={this.onDismiss}>Houston, we have a problem!. Please inform our friendly staff via LINE @kickavenue</Alert>
      }
    }
    return null;
  }

  render(){
    return(        
      <Modal isOpen={this.props.isOpen} toggle={this.props.onCancelCollection} className="modal-dialog modal-md">
        <ModalHeader toggle={this.props.onCancelCollection}>{this.state.id === null? 'Add ': ''}Collection</ModalHeader>
        <ModalBody>
        <Form>
          <Row>
            <Col xs={12} md={8}>
              <FormGroup  className={classnames({'has-danger has-feedback':hasError('raffle_group',this.state.error)})}>
                <Label for="formRaffleGroup">Raffle Group*</Label>
                <SelectRaffleGroup
                  defaultOptions
                  cacheOptions={false}
                  value={this.state.raffle_group}        
                  id="_formSelectRaffleGroup"
                  ref="selectRaffleGroup"
                  placeholder="Select a raffle group..."
                  noResultsText="Cannot find group."
                  onSelectSearch={(input)=>this.setState({_recentSearchRaffleGroup: input})}
                  onSelectChange={this._handleOnSelectRaffleGroupChange}
                  onSelectOpen={this._handleOnSelectRaffleGroupOpen}
                />
                {errorMessage('user',this.state.error)}
              </FormGroup>
            </Col>
            <Col xs={4}>
              <FormGroup className={classnames({'has-danger has-feedback':hasError('total_ticket',this.state.error)})}>
                <Label for="formTotalTicket">Total Ticket</Label>
                <Input
                  type="number"
                  id="formTotalTicket"
                  name="total_ticket"
                  placeholder="Enter total ticket*"
                  value={textValue('total_ticket',this.state)}
                  onChange={this.handleChange}
                  disabled={this.state.id?true:false}
                />
                {errorMessage('total_ticket',this.state.error)}
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={12}>
              <FormGroup  className={classnames({'has-danger has-feedback':hasError('collection',this.state.error)})}>
                <Label for="formCollection">Collection*</Label>
                <SelectCollection
                  isMulti
                  defaultOptions
                  cacheOptions={false}
                  value={this.state.collection}        
                  id="_formSelectCollection"
                  ref="selectCollection"
                  placeholder="Select a collection..."
                  noResultsText="Cannot find collection."
                  onSelectSearch={(input)=>this.setState({_recentSearchCollection: input})}
                  onSelectChange={this._handleOnSelectCollectionChange}
                  onSelectOpen={this._handleOnSelectCollectionOpen}
                />
                {errorMessage('collection',this.state.error)}
              </FormGroup>
            </Col>
          </Row>       
        </Form>
        <span className="text-sm">Fields marked with * are required</span><br/>
        <span>{this._renderError()}</span>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" disabled={this.state.isSubmit} onClick={this.onSubmit}>{this.state.isSubmit?'Submitting...':'Submit'}</Button>{' '}
          <Button color="secondary" onClick={this.props.onCancelCollection}>Cancel</Button>
        </ModalFooter>
      </Modal>
    )
  }
}
ModalCollection.propTypes={
  dataRaffleTickets: PropTypes.object,
  dataAuth: PropTypes.object,
  productSelected: PropTypes.object,
  onRemoveSelected: PropTypes.func.isRequired,
  isOpen:PropTypes.bool,
  onSubmit:PropTypes.func,
  onCancelCollection:PropTypes.func
}