import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

function ModalTokenExpired({ isOpen, toggle, onClosed }) {
  return <Modal isOpen={isOpen} toggle={toggle} onClosed={onClosed}>
    <ModalHeader toggle={toggle}>Token Expired</ModalHeader>
    <ModalBody>
      Your token are expired, please login again.
    </ModalBody>
    <ModalFooter>
      <Button color="primary" onClick={toggle}>Re-Login</Button>
    </ModalFooter>
  </Modal>;
}

export default ModalTokenExpired;
