import {
    ACCOUNT_BANK_FETCHING,
    ACCOUNT_BANK_DETAIL_FETCH,
    ACCOUNT_BANK_LIST_FETCH,
    ACCOUNT_BANK_ERROR,
	ACCOUNT_BANK_ADD,
	ACCOUNT_BANK_UPDATE,
    ACCOUNT_BANK_DELETE,
    ACCOUNT_BANK_CLEAR_FORM
} from '../constants';
const initialState={
	list:[],
	detail:{},
	isFetch:false,
	isSubmit:false,
	error:null
}

const onFetching = (lastState,payload) => {
    if(payload.type==='fetch')
        return { ...lastState, isFetch:payload.status }
    else if(payload.type==='submit')
        return { ...lastState, isSubmit:payload.status }

    return { ...lastState, isFetch:false,isSubmit:false }
}

const onAddAccountBank=(lastState,payload)=>{
	const {data, message, status_code}=payload;
    return { ...lastState, detail:{ ...data, message, status_code }, success:'Insert has been successfully!',error:null }
}

const onUpdateAccountBank=(lastState,payload)=>{
	const {data, message, status_code}=payload;
    return { ...lastState, detail:{ ...data, message, status_code }, success:'Update has been successfully!',error:null }
}

const onDeleteAccountBank=(lastState,payload)=>{
	const {data, message, status_code}=payload;
    return { ...lastState, detail:{ ...data, message, status_code, delete:true }, success:'Delete has been successfully!',error:null }
}

const reducer=(state=initialState, action)=>{
    switch(action.type){
        case ACCOUNT_BANK_LIST_FETCH:{
            const {data, status_code, message}=action.payload;
            return {...state, list:{...data, message, status_code}, error:null, success:null }
        }
        case ACCOUNT_BANK_DETAIL_FETCH:{
            const {data, status_code, message}=action.payload;
            return { ...state, detail:{...data, message, status_code}, error:null, success:null }
        }
        case ACCOUNT_BANK_FETCHING:  return onFetching(state, action.payload);      
        case ACCOUNT_BANK_ERROR:
            return { ...state, ...action.payload }
        case ACCOUNT_BANK_ADD: return onAddAccountBank(state, action.payload);
        case ACCOUNT_BANK_UPDATE: return onUpdateAccountBank(state, action.payload);
        case ACCOUNT_BANK_DELETE: return onDeleteAccountBank(state, action.payload);
        case ACCOUNT_BANK_CLEAR_FORM:return {...initialState};
        default:return state;
    }
}
export default reducer;