import React from 'react';
import { connect } from 'react-redux';
import Form from './Form';
import { Container } from 'reactstrap';
import privateView from '../../components/hocs/privateView';
import Analytics from "../../services/Analytics";

class SettingDetail extends React.Component{
	componentDidMount(){
    const { email } = this.props.injectedProps;
    Analytics.recordPageView('setting_detail', email, {
      url: window.location.href,
      location: this.props.location,
      match: this.props.match
    });
  }
  render(){
    return(
      <Container>
        <Form
          title="Detail Setting"
          {...this.props}
          edit
          validateScopeOnSubmit
        />
      </Container>
    )
  }
}

const mapStateToProps=({auth:{ isFetch, isLogged, roles, id, email }})=>({
  guardData: { isFetch, isLogged, roles, id, email }
})
const enhance = connect(mapStateToProps);
export default enhance(privateView(SettingDetail));