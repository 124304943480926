import React, { Component } from "react";
import propTypes from "prop-types";
import Loading from "react-loading-animation";
import Select, { Creatable } from "react-select";
import "react-select/dist/react-select.css";
import {
  Form,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
  ButtonGroup,
  Alert
} from "reactstrap";
import InputDatetime from "../../components/Form/InputDatetime/InputDatetime";
import {
  errorMessage,
  hasError,
  textValue,
  textChange,
  dateChange,
  dateValue,
  checkChange
} from "../../utils/form";
import classNames from "classnames";
import { ButtonLoading } from "../../components/Button";
import SelectProduct from "../../components/Form/Select/Async/SelectProduct";
import { Redirect } from "react-router-dom";
import RichTextEditor, {getTextAlignBlockMetadata, getTextAlignClassName, getTextAlignStyles} from "react-rte";
import moment from 'moment-timezone';
import BadgeThin from "../../components/Badges/BadgeThin";

// import {convertToRaw} from 'draft-js';

export default class ProductVariant extends Component {
  constructor(props) {
    super(props);
    this.state = {
      _form: {
        product: null,
        weight: 3492,
        sex: "M",
        currency: "USD",
        detailsValue: RichTextEditor.createEmptyValue(),
        details: "",
        auto_sync: false,
        vintage: false,
        receive_sell: true,
        biddable: true
      },
      _errorForm: [],
      _errorMsg: null,
      _statusCode: 0,
      _defaultRibbonTags:[
        {
          label: "collection",
          value: "collection"
        },
        {
          label: "best-selling",
          value: "best-selling"
        },
        {
          label: "search",
          value: "search"
        },
      ],
      _defaultTags: [
        {
          label: "nike",
          value: "nike"
        },
        {
          label: "adidas",
          value: "adidas"
        },
        {
          label: "vapormax",
          value: "vapormax"
        },
        {
          label: "airjordan",
          value: "airjordan"
        },
        {
          label: "ultraboost",
          value: "ultraboost"
        },
        {
          label: "yeezy",
          value: "yeezy"
        }
      ],
      _isSubmitProgress: false,
      _isDeleteProgress: false
    };
    this.handleChangeEditor = this.handleChangeEditor.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleCheckedChange = this.handleCheckedChange.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this._handleOnSelectedProductChange = this._handleOnSelectedProductChange.bind(
      this
    );
    this._handleOnSelectProductOpen = this._handleOnSelectProductOpen.bind(
      this
    );
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.isLoading === false &&
      nextProps.isLoading !== this.props.isLoading
    ) {
      // After load product details
      if (nextProps.productVariant.status_code === 200) {
        let { nickname, ribbon_tag } = nextProps.productVariant;
        const tags =
          nickname !== null
            ? nickname.split(",").map(tag => ({ label: tag, value: tag }))
            : [];
        const ribbons =
          ribbon_tag && ribbon_tag !== null
              ? ribbon_tag.split(",").map(tag => ({ label: tag, value: tag }))
              : [];
        const { retail_price, retail_price_indonesia } = nextProps.productVariant;
        this.setState({
          _form: {
            ...nextProps.productVariant,
            retail_price: parseInt(retail_price || 0),
            retail_price_indonesia: parseInt(retail_price_indonesia || 0),
            product: nextProps.productVariant.product_id && {
              id: nextProps.productVariant.product_id,
              name: nextProps.productVariant.product.name,
              category: nextProps.productVariant.product.category,
              brand: nextProps.productVariant.product.brand
            },
            detailsValue: nextProps.productVariant.details
              ? RichTextEditor.createValueFromString(
                  nextProps.productVariant.details,
                  "html",
									{
										customBlockFn: getTextAlignBlockMetadata,
									}
                )
              : RichTextEditor.createEmptyValue(),
            tags,
            ribbons
          }
        });
      }
    }
    if (
      this.state._isSubmitProgress &&
      nextProps.isSubmitProgress === false &&
      nextProps.isSubmitProgress !== this.props.isSubmitProgress
    ) {
      // After submit to api
      if (
        nextProps.productVariant.status_code === 200 &&
        nextProps.error === null
      ) {
        this.setState({ _isSubmitProgress: false }, () => {
          if (
            this.state._form.id === undefined ||
            this.state._form.id === null
          ) {
            this.setState({
              _redirectToDetail: true,
              _form: { ...this.state._form, id: nextProps.productVariant.id }
            });
          } else {
            let { _form } = this.state;
            _form.updated_at = nextProps.productVariant.updated_at;
            this.setState({ _form });
          }
        });
      } else {
        this.setState({
          _isSubmitProgress: false,
          _errorForm: { ...nextProps.error }
        });
      }
    }
    if(nextProps.error !==this.props.error 
      && nextProps.error){
      this.setState({
        _isSubmitProgress: false,
        _errorForm: { ...nextProps.error }
      });
    }
  }

  handleChangeEditor(value) {
		const formatHtml = value.toString('html', {blockStyleFn: getTextAlignStyles});
		this.setState(prevState => ({
			_form: {
				...prevState._form,
				detailsValue: value,
				details: formatHtml,
			}
		}));
  }

  handleOnChange(value, name) {
    const { _form } = this.state;
    _form[name] = value;
    this.setState({ _form });
  }
  handleChange(e) {
    const _form = textChange(e, this.state._form);
    this.setState({ _form });
  }

  handleCheckedChange(e) {
    const { product } = this.state._form;
    if(e.target.name==='editors_choice'){
      if(e.target.checked)
        this.props.setEditorsChoice(product.category.name.toLowerCase(), 'INC');
      else
        this.props.setEditorsChoice(product.category.name.toLowerCase(), 'DEC');
    }
    const _form = checkChange(e, this.state._form);
    this.setState({ _form: { ..._form } });
  }

  handleDatePickerChange(value, name) {
    const date = name === 'ended_at' && value ? moment(value).endOf('day') : value;
    const _form = dateChange(date, name, this.state._form);
    this.setState({ _form });
  }

  handleSelectedChange(option, name) {
    const { _form } = this.state;
    _form[name] = option === null ? option : option.value;
    this.setState({ _form });
  }

  _validateForm = () => {
    const { product,display_name,colour,SKU }= this.state._form;
    let errors={};
    if(!product)
      errors['product_id']=['The product id field is required.'];
    if(!display_name)
      errors['display_name']=['The display name field is required.'];
    if(!colour)
      errors['colour']=['The colour field is required.'];
    if(!SKU)
      errors['SKU']=['The SKU field is required.'];
    if(Object.keys(errors).length){
      this.setState({
        _errorForm:{errors:{...errors}},
        _errorMsg:'Please fulfill your form.',
        _statusCode: 422
      })
      return true;
    }
    return false;
  }

  async onSubmit(e) {
    e.preventDefault();
    const hasError = await this._validateForm();
    if(hasError || this.props.isMaxEditorsChoice){
      return;
    }
    const _form = { ...this.state._form };
    const { product } = this.state._form
    _form.product_id = _form.product !== null ? _form.product.id : undefined;
    // _form.product = undefined;
    _form.nickname =
      _form.tags && typeof _form.tags === typeof [] && _form.tags.length
        ? _form.tags
            .map(tag => tag.value)
            .reduce((concat, tag) => concat + "," + tag)
        : null;
    _form.ribbon_tag =
      _form.ribbons && typeof _form.ribbons === typeof [] && _form.ribbons.length
        ? _form.ribbons
            .map(ribbon => ribbon.value)
            .reduce((concat, ribbon) => concat + "," + ribbon)
        : null;
    _form.active =
      _form.active === undefined || _form.active === null
        ? false
        : _form.active;
    _form.editors_choice =
      _form.editors_choice === undefined || _form.editors_choice === null
        ? false
        : _form.editors_choice;
    _form.hide_box_info =
      _form.hide_box_info === undefined || _form.hide_box_info === null
        ? false
        : _form.hide_box_info;
    _form.vintage =
      _form.vintage === undefined || _form.vintage === null
        ? false
        : _form.vintage;
    _form.receive_sell =
      _form.receive_sell === undefined || _form.receive_sell === null
        ? false
        : _form.receive_sell;
    _form.biddable =
      _form.biddable === undefined || _form.biddable === null
        ? false
        : _form.biddable;
    //force to vintage=false if product not apparels category
    if(product && product.category && product.category.name.toLowerCase() !== "apparels"){
      _form.vintage = false;
    }
    _form.voucher_applicable =
    _form.voucher_applicable === undefined || _form.voucher_applicable === null
      ? false
      : _form.voucher_applicable;
    this.setState({ _isSubmitProgress: true, _errorForm: [] }, () => {
      this.props.onSubmit({ ..._form });
    });
  }

  onDelete() {
    this.setState({ _isDeleteProgress: true }, () => this.props.onDelete());
  }

  _handleOnSelectedProductChange(option) {
    const { _form } = this.state;
    _form.product = option;
    this.setState({ _form });
  }

  _handleOnSelectProductOpen() {
    if (this.state._form.product) {
      if (this.state._recentSearchProduct) {
        this.refs.selectProduct.loadSelectOptions(
          this.state._recentSearchProduct
        );
      } else {
        this.refs.selectProduct.loadSelectOptions(
          this.state._form.product.name
        );
      }
    } else {
      this.refs.selectProduct.loadSelectOptions("");
    }
  }

  render() {
    if (this.state._redirectToDetail) {
      return <Redirect to={`/product_variants/${this.state._form.id}`} />;
    }
    const { product } = this.props.productVariant
    const { product: productForm } = this.state._form
    const category = product && product.category_id? product.category.name.toLowerCase(): null;
    const categoryEdit = productForm && productForm.category? productForm.category.name.toLowerCase(): null;
    const brandName = productForm && productForm.brand? productForm.brand.name: null;
    
    return (
      <Loading isLoading={this.props.isLoading}>
        <Col>
          <Alert color="danger" isOpen={this.state._statusCode === 422} toggle={() => this.setState({_statusCode: 0})}>
            <strong>Opps...</strong> {this.state._errorMsg}
          </Alert>
          <Form onSubmit={this.onSubmit}>
            <FormGroup
              className={classNames({
                "has-danger has-feedback": hasError(
                  "product_id",
                  this.state._errorForm
                )
              })}
            >
              <Label for="formProductId">
                Product{' '}{
                categoryEdit ? 
                  <span>
                    <BadgeThin>{categoryEdit}</BadgeThin> 
                    <BadgeThin style={{backgroundColor: '#4fa1b7', marginLeft: 4}}>{brandName}</BadgeThin>
                  </span> : null
                }
              </Label>
              <SelectProduct
                id="formProductId"
                name="product"
                ref="selectProduct"
                placeholder="Select a product..."
                noResultsText="Cannot find product."
                cacheOptions={false}
                value={this.state._form.product}
                onSelectSearch={input =>
                  this.setState({ _recentSearchProduct: input })
                }
                onSelectChange={this._handleOnSelectedProductChange}
                onSelectOpen={this._handleOnSelectProductOpen}
              />
              {errorMessage("product_id", this.state._errorForm)}
            </FormGroup>
            <FormGroup
              className={classNames({
                "has-danger has-feedback": hasError(
                  "display_name",
                  this.state._errorForm
                )
              })}
            >
              <Label for="formProductDisplayName">Display Name</Label>
              <Input
                type="text"
                id="formProductDisplayName*"
                name="display_name"
                placeholder="Enter a Display Name"
                value={textValue("display_name", this.state._form)}
                onChange={this.handleChange}
              />
              {errorMessage("display_name", this.state._errorForm)}
            </FormGroup>
            <FormGroup
              className={classNames({
                "has-danger has-feedback": hasError(
                  "type",
                  this.state._errorForm
                )
              })}
            >
              <Label for="formProductType">Type / Material</Label>
              <Input
                type="text"
                id="formProductType"
                name="type"
                placeholder="Enter a Type"
                value={textValue("type", this.state._form)}
                onChange={this.handleChange}
              />
              {errorMessage("type", this.state._errorForm)}
            </FormGroup>
            <FormGroup
              className={classNames({
                "has-danger has-feedback": hasError(
                  "dimension",
                  this.state._errorForm
                )
              })}
            >
              <Label for="formProductDimension">Dimension</Label>
              <Input
                type="text"
                id="formProductDimension"
                name="dimension"
                placeholder="Enter product dimension"
                value={textValue("dimension", this.state._form)}
                onChange={this.handleChange}
              />
              {errorMessage("dimension", this.state._errorForm)}
            </FormGroup>
            <FormGroup
              className={classNames({
                "has-danger has-feedback": hasError(
                  "nickname",
                  this.state._errorForm
                )
              })}
            >
              <Label for="formProductNickName">Nickname Or Tags</Label>
              <Creatable
                multi
                options={this.state._defaultTags}
                value={this.state._form.tags}
                onChange={val => this.handleOnChange(val, 'tags')}
                placeholder="Enter a nickname or tags"
              />
              {errorMessage("nickname", this.state._errorForm)}
            </FormGroup>
            <FormGroup
              className={classNames({
                "has-danger has-feedback": hasError(
                  "ribbon_tag",
                  this.state._errorForm
                )
              })}
            >
              <Label for="formProductRibbonTag">Ribbon Tags</Label>
              <Creatable
                multi
                options={this.state._defaultRibbonTags}
                value={this.state._form.ribbons}
                onChange={ val => this.handleOnChange (val, 'ribbons')}
                placeholder="Enter a ribbon tags"
              />
              {errorMessage("ribbon_tag", this.state._errorForm)}
            </FormGroup>
            <Row>
              <Col xs={12} md={6}>
                <FormGroup
                  className={classNames({
                    "has-danger has-feedback": hasError(
                      "colour",
                      this.state._errorForm
                    )
                  })}
                >
                  <Label for="formProductColour">Colour</Label>
                  <Input
                    type="text"
                    id="formProductColour"
                    name="colour"
                    placeholder="Enter a Colour*"
                    value={textValue("colour", this.state._form)}
                    onChange={this.handleChange}
                  />
                  {errorMessage("colour", this.state._errorForm)}
                </FormGroup>
              </Col>
              <Col xs={12} md={6}>
                <FormGroup
                  className={classNames({
                    "has-danger has-feedback": hasError(
                      "weight",
                      this.state._errorForm
                    )
                  })}
                >
                  <Label for="formProductWeight">Weight</Label>
                  <Input
                    type="text"
                    id="formProductWeight"
                    name="weight"
                    placeholder="Enter a Weight*"
                    value={textValue("weight", this.state._form)}
                    onChange={this.handleChange}
                  />
                  {errorMessage("weight", this.state._errorForm)}
                </FormGroup>
              </Col>
            </Row>
            <FormGroup
              className={classNames({
                "has-danger has-feedback": hasError(
                  "SKU",
                  this.state._errorForm
                )
              })}
            >
              <Label for="formProductSKU">SKU</Label>
              <Input
                type="text"
                id="formProductSKU"
                name="SKU"
                placeholder="Enter a SKU*"
                value={textValue("SKU", this.state._form)}
                onChange={this.handleChange}
              />
              {errorMessage("SKU", this.state._errorForm)}
            </FormGroup>
            <Row>
              <Col xs={12} md={6}>
                <FormGroup
                  className={classNames({
                    "has-danger has-feedback": hasError(
                      "sex",
                      this.state._errorForm
                    )
                  })}
                >
                  <Label for="formProductSex">Sex</Label>
                  <Select
                    id="formProductSex"
                    name="sex"
                    value={textValue("sex", this.state._form)}
                    options={[
                      { value: "U", label: "Unisex" },
                      { value: "F", label: "Female" },
                      { value: "M", label: "Male" },
                      { value: "P", label: "Primary School" },
                      { value: "GS", label: "Grade School" },
                      { value: "IT", label: "Infant Toddler" }
                    ]}
                    onChange={val => {
                      this.handleSelectedChange(val, "sex");
                    }}
                  />
                  {errorMessage("sex", this.state._errorForm)}
                </FormGroup>
              </Col>
            </Row>
            <FormGroup
              className={classNames({
                "has-danger has-feedback": hasError(
                  "active",
                  this.state._errorForm
                )
              })}
            >
              <Label for="formProductActive">Set to Active &nbsp;</Label>
              <label className="switch switch-text switch-success-outline-alt">
                <input
                  type="checkbox"
                  className="switch-input"
                  name="active"
                  id="formProductActive"
                  value="true"
                  checked={textValue("active", this.state._form)}
                  onChange={this.handleCheckedChange}
                />
                <span
                  className="switch-label"
                  data-on="Yes"
                  data-off="No"
                ></span>
                <span className="switch-handle"></span>
              </label>
              {errorMessage("active", this.state._errorForm)}
            </FormGroup>
            <FormGroup
              className={classNames({
                "has-danger has-feedback": hasError(
                  "auto_sync",
                  this.state._errorForm
                )
              })}
            >
              <Label for="formProductAutoScrape">
                Set to Auto Scrape &nbsp;
              </Label>
              <label className="switch switch-text switch-success-outline-alt">
                <input
                  type="checkbox"
                  className="switch-input"
                  name="auto_sync"
                  id="formProductAutoScrape"
                  value="true"
                  checked={textValue("auto_sync", this.state._form)}
                  onChange={this.handleCheckedChange}
                />
                <span
                  className="switch-label"
                  data-on="Yes"
                  data-off="No"
                ></span>
                <span className="switch-handle"></span>
              </label>
              {errorMessage("auto_sync", this.state._errorForm)}
            </FormGroup>
            <Row>
              <Col xs={12} md={6}>
                <FormGroup
                  className={classNames({
                    "has-danger has-feedback": hasError(
                      "editors_choice",
                      this.state._errorForm
                    )
                  })}
                >
                  <Label for="formProductEditorChoice">
                    Set to Hot Product &nbsp;
                  </Label>
                  <label className="switch switch-text switch-success-outline-alt">
                    <input
                      type="checkbox"
                      className="switch-input"
                      name="editors_choice"
                      id="formProductEditorChoice"
                      value="true"
                      checked={textValue("editors_choice", this.state._form)}
                      onChange={this.handleCheckedChange}
                    />
                    <span
                      className="switch-label"
                      data-on="Yes"
                      data-off="No"
                    ></span>
                    <span className="switch-handle"></span>
                  </label>
                  {errorMessage("editors_choice", this.state._errorForm)}
                </FormGroup>
              </Col>
              <Col xs={12} md={6}>
                <FormGroup
                  className={classNames({
                    "has-danger has-feedback": hasError(
                      "editors_position",
                      this.state._errorForm
                    )
                  })}
                >
                  <Label for="formProductEditorsPosition">Position</Label>
                  <Input
                    type="number"
                    id="formProductEditorsPosition"
                    name="editors_position"
                    placeholder="Enter a hot product's position"
                    value={textValue("editors_position", this.state._form)}
                    onChange={this.handleChange}
                  />
                  {errorMessage("editors_position", this.state._errorForm)}
                </FormGroup>
              </Col>
            </Row>
            <FormGroup
              className={classNames({
                "has-danger has-feedback": hasError(
                  "vintage",
                  this.state._errorForm
                )
              })}
            >
              <Label for="formProductVintage">
                Set to Vintage &nbsp;
              </Label>
              <label className="switch switch-text switch-success-outline-alt">
                <input
                  type="checkbox"
                  className="switch-input"
                  name="vintage"
                  id="formProductVintage"
                  disabled={category !== "apparels"}
                  value="true"
                  checked={textValue("vintage", this.state._form)}
                  onChange={this.handleCheckedChange}
                />
                <span
                  className="switch-label"
                  data-on="Yes"
                  data-off="No"
                ></span>
                <span className="switch-handle"></span>
              </label>
              {errorMessage("vintage", this.state._errorForm)}
            </FormGroup>
            <FormGroup
              className={classNames({
                "has-danger has-feedback": hasError(
                  "biddable",
                  this.state._errorForm
                )
              })}
            >
              <Label for="formProductMakeOffer">
                Can Make Offer &nbsp;
              </Label>
              <label className="switch switch-text switch-success-outline-alt">
                <input
                  type="checkbox"
                  className="switch-input"
                  name="biddable"
                  id="formProductMakeOffer"
                  value="true"
                  checked={textValue("biddable", this.state._form)}
                  onChange={this.handleCheckedChange}
                />
                <span
                  className="switch-label"
                  data-on="Yes"
                  data-off="No"
                ></span>
                <span className="switch-handle"></span>
              </label>
              {errorMessage("biddable", this.state._errorForm)}
            </FormGroup>
            <FormGroup
              className={classNames({
                "has-danger has-feedback": hasError(
                  "hide_box_info",
                  this.state._errorForm
                )
              })}
            >
              <Label for="formProductHideBoxInfo">Hide Box Info &nbsp;</Label>
              <label className="switch switch-text switch-success-outline-alt">
                <input
                  type="checkbox"
                  className="switch-input"
                  name="hide_box_info"
                  id="formProductHideBoxInfo"
                  value="true"
                  checked={textValue("hide_box_info", this.state._form)}
                  onChange={this.handleCheckedChange}
                />
                <span
                  className="switch-label"
                  data-on="Yes"
                  data-off="No"
                ></span>
                <span className="switch-handle"></span>
              </label>
              {errorMessage("hide_box_info", this.state._errorForm)}
            </FormGroup>
            <FormGroup
              className={classNames({
                "has-danger has-feedback": hasError(
                  "receive_sell",
                  this.state._errorForm
                )
              })}
            >
              <Label for="formReceiveSell">Receive Sell Request &nbsp;</Label>
              <label className="switch switch-text switch-success-outline-alt">
                <input
                  type="checkbox"
                  className="switch-input"
                  name="receive_sell"
                  id="formReceiveSell"
                  value="true"
                  checked={textValue("receive_sell", this.state._form)}
                  onChange={this.handleCheckedChange}
                />
                <span
                  className="switch-label"
                  data-on="Yes"
                  data-off="No"
                ></span>
                <span className="switch-handle"></span>
              </label>
              {errorMessage("receive_sell", this.state._errorForm)}
            </FormGroup>
            <FormGroup
              className={classNames({
                "has-danger has-feedback": hasError(
                  "voucher_applicable",
                  this.state._errorForm
                )
              })}
            >
              <Label for="formVoucherApplicable">Voucher Applicable &nbsp;</Label>
              <label className="switch switch-text switch-success-outline-alt">
                <input
                  type="checkbox"
                  className="switch-input"
                  name="voucher_applicable"
                  id="formVoucherApplicable"
                  value="true"
                  checked={textValue("voucher_applicable", this.state._form)}
                  onChange={this.handleCheckedChange}
                />
                <span
                  className="switch-label"
                  data-on="Yes"
                  data-off="No"
                ></span>
                <span className="switch-handle"></span>
              </label>
              {errorMessage("voucher_applicable", this.state._errorForm)}
            </FormGroup>
            <FormGroup>
              <Label for="formProductDetail">Details</Label>
              <RichTextEditor
                value={this.state._form.detailsValue}
                onChange={this.handleChangeEditor}
								blockStyleFn={getTextAlignClassName}
              />
              {/* <div>{this.state._form.details}</div> */}
            </FormGroup>
            <Row>
              <Col xs={12} md={12}>
                <FormGroup
                  className={classNames({
                    "has-danger has-feedback": hasError(
                      "price_source",
                      this.state._errorForm
                    )
                  })}
                >
                  <Label for="formProductPriceSource">Price Source</Label>
                  <Input
                    type="text"
                    id="formProductPriceSource"
                    name="price_source"
                    placeholder="Enter a price source"
                    value={textValue("price_source", this.state._form)}
                    onChange={this.handleChange}
                  />
                  {errorMessage("price_source", this.state._errorForm)}
                </FormGroup>
              </Col>
              <Col xs={12} md={6}>
                <FormGroup
                  className={classNames({
                    "has-danger has-feedback": hasError(
                      "retail_price",
                      this.state._errorForm
                    )
                  })}
                >
                  <Label for="formProductRetailPrice">Retail Price</Label>
                  <Input
                    type="text"
                    id="formProductRetailPrice"
                    name="retail_price"
                    placeholder="Enter a retail price"
                    value={textValue("retail_price", this.state._form)}
                    onChange={this.handleChange}
                  />
                  {errorMessage("retail_price", this.state._errorForm)}
                </FormGroup>
              </Col>
              <Col xs={12} md={6}>
                <FormGroup
                  className={classNames({
                    "has-danger has-feedback": hasError(
                      "currency",
                      this.state._errorForm
                    )
                  })}
                >
                  <Label for="formProductCurrency">Currency</Label>
                  <Input
                    type="text"
                    id="formProductCurrency"
                    name="currency"
                    placeholder="Enter a retail price's currency"
                    value={textValue("currency", this.state._form)}
                    onChange={this.handleChange}
                  />
                  {errorMessage("currency", this.state._errorForm)}
                </FormGroup>
              </Col>
              <Col xs={12} md={6}>
                <FormGroup
                  className={classNames({
                    "has-danger has-feedback": hasError(
                      "retail_price_indonesia",
                      this.state._errorForm
                    )
                  })}
                >
                  <Label for="formProductIndonesiaRetailPrice">Indonesia Retail Price</Label>
                  <Input
                    type="text"
                    id="formProductIndonesiaRetailPrice"
                    name="retail_price_indonesia"
                    placeholder="Enter a indonesia retail price"
                    value={textValue("retail_price_indonesia", this.state._form)}
                    onChange={this.handleChange}
                  />
                  {errorMessage("retail_price_indonesia", this.state._errorForm)}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={6}>
                <FormGroup
                  className={classNames({
                    "has-danger has-feedback": hasError(
                      "release_date",
                      this.state._errorForm
                    )
                  })}
                >
                  <Label for="formProductReleaseDate">Release Date</Label>
                  <InputDatetime
                    inputProps={{ placeholder: "Pick a Release Date" }}
                    value={dateValue("release_date", this.state._form)}
                    onChange={date =>
                      this.handleDatePickerChange(date, "release_date")
                    }
                    timeFormat="HH:mm"
                  />
                  {errorMessage("release_date", this.state._errorForm)}
                </FormGroup>
              </Col>
              <Col xs={12} md={6}>
                <FormGroup
                  className={classNames({
                    "has-danger has-feedback": hasError(
                      "expiry_date",
                      this.state._errorForm
                    )
                  })}
                >
                  <Label for="formProductExpiryDate">Expiry Date</Label>
                  <InputDatetime
                    inputProps={{ placeholder: "Pick a Expiry Date" }}
                    value={dateValue("expiry_date", this.state._form)}
                    onChange={date =>
                      this.handleDatePickerChange(date, "expiry_date")
                    }
                    timeFormat="HH:mm"
                  />
                  {errorMessage("expiry_date", this.state._errorForm)}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={6}>
                <FormGroup>
                  <Label>Created At</Label>
                  <Input
                    type="text"
                    readOnly
                    value={textValue("created_at", this.state._form)}
                  />
                </FormGroup>
              </Col>
              <Col xs={12} md={6}>
                <FormGroup>
                  <Label>Updated At</Label>
                  <Input
                    type="text"
                    readOnly
                    value={textValue("updated_at", this.state._form)}
                  />
                </FormGroup>
              </Col>
            </Row>
            <FormGroup>
              <ButtonGroup>
                <ButtonLoading isLoading={this.state._isSubmitProgress}>
                  Submit
                </ButtonLoading>
                {/* {this.props.usingDelete ? (
                  <Button color="danger" onClick={this.onDelete}>
                    Delete
                  </Button>
                ) : (
                  ""
                )} */}
              </ButtonGroup>
            </FormGroup>
          </Form>
        </Col>
      </Loading>
    );
  }
}

ProductVariant.propTypes = {
  usingDelete: propTypes.bool,
  isLoading: propTypes.bool,
  isSubmitProgress: propTypes.bool,
  products: propTypes.array,
  productVariant: propTypes.shape({
    id: propTypes.number,
    product_id: propTypes.number,
    display_name: propTypes.string,
    type: propTypes.string,
    dimension: propTypes.string,
    nickname: propTypes.string,
    ribbon_tag: propTypes.string,
    colour: propTypes.string,
    weight: propTypes.any,
    SKU: propTypes.string,
    sex: propTypes.string,
    active: propTypes.any,
    details: propTypes.string,
    price_source: propTypes.string,
    // editors_choice:propTypes.any,
    release_date: propTypes.string,
    expiry_date: propTypes.string,
    created_at: propTypes.string,
    updated_at: propTypes.string
  }),
  onDelete: propTypes.func,
  onSubmit: propTypes.func
};
