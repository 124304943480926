import React from 'react';
import propTypes from 'prop-types';
import {
  Row,
  Col,
  Form,
  FormGroup,
  Button,
  Input,
  Label
} from 'reactstrap';

const FilterForm = props => (
  <Form className="form-filter" onSubmit={props.onSubmit}>
    <Row>
      <Col xs={12} md={6}>
        <p>Filter By :</p>
        <div className="d-flex" style={{flexDirection: 'column'}}>
          <div className="d-flex" style={{padding: "0px 1rem"}}>
            <FormGroup className="d-flex" style={{flexDirection: 'column', alignItems: 'flex-start'}}>
                <Label className="mb-2" style={{fontWeight: 700}}>
                  Active
                </Label>
                <div className="d-flex ml-2 mr-2">
                  <FormGroup check className="mr-2">
                    <Label check>
                      <Input type="radio" name="active" value="" checked={props.active == ''} onChange={props.onCheckedChange} /> All
                    </Label>
                  </FormGroup>
                  <FormGroup check className="mr-2">
                    <Label check>
                      <Input type="radio" name="active" value="1" checked={props.active == '1'} onChange={props.onCheckedChange} /> Active
                    </Label>
                  </FormGroup> 
                  <FormGroup check className="mr-2">
                    <Label check>
                      <Input type="radio" name="active" value="0" checked={props.active == '0'} onChange={props.onCheckedChange} /> Inactive
                    </Label>
                  </FormGroup>   
                </div>         
            </FormGroup>      
          </div>
          <div className="d-flex" style={{padding: "0px 1rem"}}>
            <FormGroup className="d-flex" style={{flexDirection: 'column', alignItems: 'flex-start'}}>
                <Label className="mb-2" style={{fontWeight: 700}}>
                  Hot Product
                </Label>
                <div className="d-flex ml-2 mr-2">
                  <FormGroup check className="mr-2">
                    <Label check>
                      <Input type="radio" name="editors_choice" value="" checked={props.editors_choice == ''} onChange={props.onCheckedChange} /> All
                    </Label>
                  </FormGroup>
                  <FormGroup check className="mr-2">
                    <Label check>
                      <Input type="radio" name="editors_choice" value="1" checked={props.editors_choice == '1'} onChange={props.onCheckedChange} /> Only Hot Product
                    </Label>
                  </FormGroup> 
                  <FormGroup check className="mr-2">
                    <Label check>
                      <Input type="radio" name="editors_choice" value="0" checked={props.editors_choice == '0'} onChange={props.onCheckedChange} /> Not Hot Product
                    </Label>
                  </FormGroup>   
                </div>         
            </FormGroup>      
          </div>
          <div className="d-flex" style={{padding: "0px 1rem"}}>
            <FormGroup className="d-flex" style={{flexDirection: 'column', alignItems: 'flex-start'}}>
                <Label className="mb-2" style={{fontWeight: 700}}>
                  Vintage
                </Label>
                <div className="d-flex ml-2 mr-2">
                  <FormGroup check className="mr-2">
                    <Label check>
                      <Input type="radio" name="vintage" value="" checked={props.vintage == ''} onChange={props.onCheckedChange} /> All
                    </Label>
                  </FormGroup>
                  <FormGroup check className="mr-2">
                    <Label check>
                      <Input type="radio" name="vintage" value="1" checked={props.vintage == '1'} onChange={props.onCheckedChange} /> Only Vintage
                    </Label>
                  </FormGroup> 
                  <FormGroup check className="mr-2">
                    <Label check>
                      <Input type="radio" name="vintage" value="0" checked={props.vintage == '0'} onChange={props.onCheckedChange} /> Not Vintage
                    </Label>
                  </FormGroup>   
                </div>         
            </FormGroup>      
          </div>
          <FormGroup className="mb-2" style={{alignSelf: 'flex-end'}}>
            <Button color="primary" >Apply Filter</Button>
          </FormGroup>    
        </div>
      </Col>
    </Row>
  </Form>
)
export default FilterForm;
FilterForm.defaultProps = {
  active: "",
  editors_choice: "",
  vintage: ""
}
FilterForm.propTypes = {
  onSubmit: propTypes.func.isRequired,
  onCheckedChange: propTypes.func.isRequired,
  active: propTypes.string,
  editors_choice: propTypes.string,
  vintage: propTypes.string,
}