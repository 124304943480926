import React from 'react';
import propTypes from 'prop-types';
import { Button } from 'reactstrap';
import FontAwesomeIcon from '../../../components/Icons/FontAwesomeIcon';
import {
  UNVERIFIED,
  UNDER_REVIEW,
  VERIFIED,
} from '../helpers';

const StatusFilter = props => (
  <div className="d-flex mb-3" style={{justifyContent: 'flex-end'}}>
    <Button
      size="sm"
      onClick={()=> props.onClick("")}
      color={props.status===""?"primary": "link"}
    >
      { props.status===""&&<FontAwesomeIcon className="mr-1" iconType="check"/> }
      All
    </Button>
    <Button
      size="sm"
      onClick={()=> props.onClick(UNVERIFIED)}
      color={props.status===UNVERIFIED?"primary": "link"}
    >
      { props.status===UNVERIFIED&&<FontAwesomeIcon className="mr-1" iconType="check"/> }
      Unverified
    </Button>
    <Button
      size="sm"
      onClick={()=> props.onClick(UNDER_REVIEW)}
      color={props.status===UNDER_REVIEW?"primary": "link"}
    >
      { props.status===UNDER_REVIEW&&<FontAwesomeIcon className="mr-1" iconType="check"/> }
      Under Review
    </Button>
    <Button
      size="sm"
      onClick={()=> props.onClick(VERIFIED)}
      color={props.status===VERIFIED?"primary": "link"}
    >
      { props.status===VERIFIED&&<FontAwesomeIcon className="mr-1" iconType="check"/> }
      Verified
    </Button>
  </div>
)
export default StatusFilter;
StatusFilter.defaultProps = {
  status: ""
}

StatusFilter.propTypes = {
  status: propTypes.string,
  onClick: propTypes.func
}