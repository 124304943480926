import React from 'react';
import { connect } from 'react-redux';
import {
  Card,
  CardHeader,
  CardBody
} from 'reactstrap';
import styled from 'styled-components';
import { getListVoucher } from '../../actions/voucherAction';
import {
  attachVoucher,
  detachVoucher,
} from '../../actions/brandAction';
import Loading from 'react-loading-animation';
import { FontAwesomeIcon, SimpleLineIcon } from '../../components/Icons';
import ModalBrowseVoucher from '../../components/Modals/ModalBrowseVoucher';
import CardVoucherList from '../../components/Card/CardVoucherList';

class BrandVouchers extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      modalBrowseVoucher: false,
      dataInputs: []
    }
    this._handleOpenBrowseVoucherModal = this._handleOpenBrowseVoucherModal.bind(this);
    this._handleDetachAllVoucher = this._handleDetachAllVoucher.bind(this);
  }

  componentDidUpdate(prevProps){
    if(prevProps.brandIsSubmit && prevProps.brandIsSubmit !== this.props.brandIsSubmit){
      this.setState({
        dataInputs: []
      })
    }
  }

  _openBrowseVoucherModal(){
    this.setState({
      modalBrowseVoucher: !this.state.modalBrowseVoucher
    });
  }

  _handleOpenBrowseVoucherModal(e) {
    e.preventDefault();
    this._openBrowseVoucherModal();
  }

  _getVoucherList(payloads){
    this.props.getListVoucher({
      params: {
        brand_id: this.props.brandId,
        exclude: true,
        active: 1,
        voucher_type: 'public',
        ...payloads
      }
    })
  }

  _handleDetachVoucher = async (e, id) => {
    e.preventDefault();
    const { brandId } = this.props
    await this.props.detachVoucher(brandId,{
      voucher_id: [id]
    });
  }

  _handleDetachAllVoucher(e){
    e.preventDefault();
    const { dataInputs } = this.state;
    const { brandId, dataSource } = this.props
    if(dataInputs.length){
      this.props.detachVoucher(brandId,{
        voucher_id: [...dataInputs]
      });
    } else {
      const ids = dataSource.map(({id}) => id);
      if(ids.length)
        this.props.detachVoucher(brandId,{
          voucher_id: [...ids]
        });
    }
  }

  render(){
    return(
      <div>
        <ModalBrowseVoucher
          isOpen={this.state.modalBrowseVoucher}
          isLoading={this.props.masterVoucher.isFetch}
          error={this.props.masterVoucher.error}
          data={this.props.masterVoucher.list.length !== 0? this.props.masterVoucher.list: this.props.masterVoucher.list}
          toggle={() => this._openBrowseVoucherModal()}
          onGetVoucherList={payloads=>this._getVoucherList(payloads)}
          onSubmitAttachVoucher={payloads => this.props.attachVoucher(this.props.brandId, payloads)}
        />
        <Card>
          <CardHeader>
            <FontAwesomeIcon iconType="align-justify"/> Vouchers ({this.props.dataSource.length})
          </CardHeader>
          <CardBody>
            <Loading isLoading={this.props.brandIsFetch}>
              <p>
                <a
                  href="#"
                  className="link link-default mr-1"
                  onClick={this._handleOpenBrowseVoucherModal}
                >
                  <SimpleLineIcon iconType="plus"/> Browse Vouchers
                </a>
                {
                  this.props.dataSource && this.props.dataSource.length? (
                    <a
                      href="#"
                      className="link text-danger mr-2"
                      onClick={this._handleDetachAllVoucher}
                    >
                      <SimpleLineIcon iconType="trash" className="mr-1"/>
                      Remove {this.state.dataInputs.length ? `(${this.state.dataInputs.length})`: 'All'}
                    </a>
                  ): null
                }
                {
                  this.state.dataInputs.length? (
                    <a
                      href="#"
                      className="link text-danger"
                      onClick={e => {
                        e.preventDefault();
                        this.setState({ dataInputs: [] })
                      }}
                    >
                      <SimpleLineIcon iconType="action-undo" className="mr-1"/>
                      Undo Select
                    </a>
                  ): null
                }
              </p>
              <CardVoucherList
                dataSource={this.props.dataSource}
                dataInputs={this.state.dataInputs}
                onCheckedChange={ (e, item) => {
                  const { checked } = e.target;
                  const list  = this.state.dataInputs.filter(i => i !== item.id)
                  if(checked) list.push(item.id)
                  this.setState({
                    dataInputs: [...list]
                  })
                }}
                onDetachVoucher={this._handleDetachVoucher}
              />
            </Loading>
          </CardBody>
        </Card>
      </div>
    )
  }
}

const mapStateToProps= ({ masterBrand: { isSubmit }, voucher }) => ({
  masterVoucher: voucher,
  brandIsSubmit: isSubmit
});

const mapDispatchToProps = (dispatch) => ({
  getListVoucher:(query)=>dispatch(getListVoucher(query)),
  attachVoucher:(id,payload)=>dispatch(attachVoucher(id,payload)),
  detachVoucher:(id,payload)=>dispatch(detachVoucher(id,payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BrandVouchers);