import React from 'react';
import { connect } from 'react-redux'
import { Link } from 'react-router-dom';
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  ButtonGroup,
  Alert,
  CardText,
  UncontrolledTooltip
} from 'reactstrap';
import { getPathS3 } from '../../../utils/AWS';
import classNames from 'classnames';
import uuidv4 from 'uuid/v4';
import { getOptionList as getCategoryOptionList } from '../../../actions/categoriesAction';
import { getAppSectionById, saveAppSection, deleteAppSectionById, uploadAppSectionImage, saveAppSectionImage, deleteAppSectionImageById } from '../../../actions/appSectionAction';
import { FontAwesomeIcon, SimpleLineIcon } from '../../../components/Icons';
import { Radio, RadioText, FormGroupRadio } from '../../../components/Form/Radios';
import BadgeThin from '../../../components/Badges/BadgeThin';
import { ButtonLoading } from '../../../components/Button';
import { errorMessage, hasError ,textValue } from '../../../utils/form';
import { ModalDelete, ModalBlockLoading as ModalLoading, ModalInfo, ModalConfirmation, ModalEditImage } from '../../../components/Modals';
import { ImageFile } from '../../../components/Images';
import { DropzonePlusButton } from '../../../components/Form/DropzoneFile';
import { ImagesCompressor, createUploadFileForm } from '../../../utils/imageHelper';
import { getOriginFromSignedUrl } from '../../../utils/AWS';
import { ImageCategories } from './ImageCategories';
import { uploadImage, deleteImage } from '../../../actions/imageUploadAction';

const DEFAULT_IMAGE_ASPECT_LANDSCAPE = 16/5;
const DEFAULT_IMAGE_ASPECT_PORTRAIT = 1/1;

const addImageRawObject = file => {
  return {
    identifier: uuidv4(),
    fileInputOrigin: file, //for rollback.
    fileInput: file,
    URL: file.preview,
    status: false,
    isUploading: false,
    compressed: false
  }
}

const NEXT_SUBMIT_TYPES = ['SUBMIT_FORM_DATA', 'UPLOAD_MAIN_IMG', 'UPLOAD_CHILD_IMG', 'SUBMIT_IMG_DATA', 'SYNC_IMAGES']

class AppSectionForm extends React.Component{
  constructor(props){
    super(props)
    this.state = {
      _hasId: this.props.match.params.id?true:false,
      _formSubmit: false,
      _form:{
        id: 0,
        _rawImage: null,
        img_url: '',
        oldImageUrl: null,
        slug: '',
        title: '',
        description: '',
        active: false,
        created_at: '',
        updated_at: '',
        images: []
      },
      _uploadImagesConfirmationOpen: false,
      _uploadImageFromMainForm: false,
      _deleteConfirmationOpen: false,
      _errorForm: null,
      _alertErrorOpen: false,
      _alertSuccessOpen: false,
      _errorMessage: '',
      _successMessage: '',
      _uploadSubmit: false,
      _nextSubmit: null,
      _isModalLoadingOpen: false,
      _rawImages: [],
      _images: [],
      _selected: null,
      modalEditImage: {
        isOpen: false,
        defaultImageRatio: DEFAULT_IMAGE_ASPECT_LANDSCAPE,
        imageSourceEdits: [],
        purpose: "default"
      }
    }
  }

  componentDidMount(){
    if(this.props.dataCategories.length === 0)
      this.props.getCategoryOptionList()
    if(this.props.match.params.id){
      const { id } = this.props.match.params;
      this.props.getAppSectionById(id)
    }
  }

  translateMessage = (statusCode, message) => {
    let messageStr = ''
    switch(statusCode){
      case 200: messageStr = 'Success...'; break;
      case 422: messageStr = 'Please fullfill your form.'; break;
      case 404: messageStr = 'Resource not found.'; break;
      case 500: messageStr = "We've got something errors"; break;
      default: messageStr = message; break;
    }
    return messageStr
  }

  async componentDidUpdate(prevProps){
    /**
     * 0 => SUBMIT_FORM_DATA
     * 1 => UPLOAD_MAIN_IMG
     * 2 => UPLOAD_CHILD_IMG
     * 3 => SUBMIT_IMG_DATA
     * 4 => SYNC_IMAGES
     */
    if(prevProps.dataAppSection.isFetch !== this.props.dataAppSection.isFetch && !this.props.dataAppSection.isFetch){
      const { data, error, status_code } = this.props.dataAppSection;
      const { _nextSubmit } = this.state;
      if(error){
        if(error.status_code === 404)
          this.props.history.replace('/404')
        this.setState({
          _errorForm: {...error},
          _alertErrorOpen: true,
          _alertSuccessOpen: false,
          _errorMessage: this.translateMessage(error.status_code, error.message)
        })
      }
      else if(status_code === 200){
        if(!this.props.match.params.id && _nextSubmit === NEXT_SUBMIT_TYPES[4]){
          this.props.history.replace(`/app_sections/${data.id}`)
        }
        if(_nextSubmit === NEXT_SUBMIT_TYPES[4]){
          this.setState({
            _form:{
              ...this.state._form,
              id: data.id,
              images: [...data.images]
            },
            _nextSubmit: null,
            _images: [],
            _isModalLoadingOpen: false,
            _uploadSubmit: false,
            _formSubmit: false
          })
        }
        else{
          this.setState({
            _form:{
              ...this.state._form,
              id: data.id,
              _rawImage: null,
              img_url: data.signed_url? data.signed_url: data.img_url,
              oldImageUrl: data.signed_url? data.signed_url: data.img_url,
              slug: data.slug,
              title: data.title,
              description: data.description || '',
              active: data.active,
              images: [...data.images],
              created_at: data.created_at,
              updated_at: data.updated_at
            },
            _rawImages: [],
            _errorForm: null,
            _alertErrorOpen: false,
            _isModalLoadingOpen: false,
            _formSubmit: false
          })
        }
      }
    }
    if(this.state._formSubmit && prevProps.dataAppSection.isSubmit !== this.props.dataAppSection.isSubmit && !this.props.dataAppSection.isSubmit){
      const { data, error, status_code, message } = this.props.dataAppSection;
      if(error){
        if(error.status_code === 404)
          this.props.history.replace('/404')
        this.setState({
          _errorForm: {...error},
          _alertErrorOpen: true,
          _alertSuccessOpen: false,
          _errorMessage: this.translateMessage(error.status_code, error.message),
          _formSubmit: false,
          _uploadSubmit: false,
          _isModalLoadingOpen: false
        })
      }
      else if(status_code === 200){
        const { deleted } = this.props.dataAppSection;
        const { _nextSubmit } = this.state;
        if(deleted){
          this.props.history.replace(`/app_sections`)
        }
        if(!this.props.match.params.id && _nextSubmit === null){
          this.props.history.replace(`/app_sections/${data.id}`)
        }
        const { _form: { _rawImage }, _rawImages } = this.state;
        if(_nextSubmit !== null){
          switch(_nextSubmit) {
            case NEXT_SUBMIT_TYPES[1]:{
              this.setState({
                _form:{
                  ...this.state._form,
                  id: data.id,
                  _rawImage: {..._rawImage, isUploading: true}
                },
                _nextSubmit: NEXT_SUBMIT_TYPES[0],
              })
              const tempPayload = createUploadFileForm(_rawImage.fileInput, false, {name: "app-sections/"+data.id, bucket_type: "assets"});
              this.props.uploadImage(tempPayload, _rawImage);
              break;
            };
            case NEXT_SUBMIT_TYPES[2]:{
              this.setState({
                _rawImages: [..._rawImages.map((img) => ({
                  ...img,
                  isUploading: true
                }))],
                _nextSubmit: NEXT_SUBMIT_TYPES[3],
              })
              const tempPayload = createUploadFileForm(
                _rawImages.map(img => img.fileInput),
                true,
                { name: "app-sections/"+data.id, bucket_type: "assets" }
              );
              this.props.uploadImage(tempPayload, _rawImages);
              break;
            };
          }
        }
        else{
          this.setState({
            _form:{
              ...this.state._form,
              id: data.id,
              slug: data.slug,
              title: data.title,
              img_url: data.signed_url? data.signed_url: data.img_url,
              oldImageUrl: data.signed_url? data.signed_url: data.img_url,
              description: data.description || '',
              active: data.active,
              created_at: data.created_at,
              updated_at: data.updated_at
            },
            _formSubmit: false,
            _errorForm: null,
            _alertErrorOpen: false,
            _alertSuccessOpen: true,
            _isModalLoadingOpen: false,
            _successMessage: this.translateMessage(status_code, message),
          })
        }
      }
    }
    if(prevProps.dataAppSection.isImageDataSubmit && prevProps.dataAppSection.isImageDataSubmit !== this.props.dataAppSection.isImageDataSubmit){
      const { error } = this.props.dataAppSection;
      if(error){
        this.setState({
          _uploadSubmit: false,
          _isModalLoadingOpen: false,
          _isErrorModalOpen: true
        })
      }
      if(!error){
        this.setState({
          _nextSubmit: NEXT_SUBMIT_TYPES[4],
        })
        this.props.getAppSectionById(this.state._form.id)
      }
    }
    if(prevProps.dataUploaded.isUpload && prevProps.dataUploaded.isUpload !== this.props.dataUploaded.isUpload) {
      const { success, error } = this.props.dataUploaded;
      if(error){
        this.setState({
          _uploadSubmit: false,
          _isModalLoadingOpen: false,
          _isErrorModalOpen: true
        })
      }
      if(!error){
        const { data } = success.data;
        const { _form, _nextSubmit, _rawImages, _uploadImageFromMainForm } = this.state;
        if(_nextSubmit === NEXT_SUBMIT_TYPES[0]){
          const payloads = {
            id: _form.id,
            title: _form.title,
            img_url: success.data.url,
            description: _form.description,
            active: _form.active,
          }
          this.setState({
            _formSubmit: true,
            _form: {
              ...this.state._form,
              _rawImage: null,
              img_url: payloads.img_url
            },
            _nextSubmit: _rawImages.length> 0 && _uploadImageFromMainForm? NEXT_SUBMIT_TYPES[2]: null
          }, () =>{
            this.props.saveAppSection(payloads)
          })
        }
        else if(_nextSubmit === NEXT_SUBMIT_TYPES[2] || _nextSubmit === NEXT_SUBMIT_TYPES[3]){
          if(data.length > 0){
            const payloads = [];
            _rawImages.map(raw => {
              data.map(item => {
                if(raw.identifier===item.identifier)
                payloads.push({url:item.url, orientation:raw.orientation, category_id:raw.category_id}); 
              })             
            })
            if(payloads.length>0){
              this.setState({
                _rawImages: [],
                _images: [],
                _nextSubmit: NEXT_SUBMIT_TYPES[3],
                _uploadImageFromMainForm: false
              });
              this.props.saveAppSectionImage(_form.id, { images: [...payloads] })
            }
          }
        }
      }
    }
    if(prevProps.dataUploaded.isDeleteProgress && prevProps.dataUploaded.isDeleteProgress !== this.props.dataUploaded.isDeleteProgress) {
      const { deleted, error } = this.props.dataUploaded;
      const { _form, _selected, _formSubmit } = this.state;
      if(error){
        if(_formSubmit && !_selected){
          this.props.deleteAppSectionById(_form.id);
        }
        else{
          this.setState({
            _deleteConfirmationOpen: false,
            _errorForm: {...error},
            _alertErrorOpen: true,
            _alertSuccessOpen: false,
            _errorMessage: this.translateMessage(error.status_code, error.message),
            _formSubmit: false,
          })
        }
      }
      if(_formSubmit && !error && deleted.data){
        this.props.deleteAppSectionById(_form.id);
      }
      if(!error && deleted.data && !_formSubmit && _selected){
        this.setState({
          _selected: null,
        },() => {
          this.props.deleteAppSectionImageById(_form.id, _selected.id)
        });
      }
    }
  }

  _compressImage = async (image) => {
    let fileInput = image
    try{
      //compress file
      fileInput = await ImagesCompressor(image);
    }
    catch(e){
      alert('error while compress the images')
      fileInput = image
    }
    return fileInput;
  }

  _compressMultipleImages = async (images) => {
    const imgs = images.filter(img => !img.commpressed)
    if(imgs.length){
      const compressedPromises = images.map(async(img) =>{
        const compressed = await this._compressImage(img.fileInput)
        return {
          ...img,
          fileInput: compressed,
          compressed: true
        }
      })
      const results = await Promise.all(compressedPromises)
      let temp = [];
      if(results.length){
        temp = images.map(draft => {
          const data = results.find(r => r.identifier === draft.identifier);
          if(data){
            return data
          }
          return draft
        })
      }
      return temp;
    }
    return images;
  }

  _handleInputChange = ({ target: { name, value, checked, type } }) => {
    this.setState({
      _form:{
        ...this.state._form,
        [name]: type === 'checkbox'? checked: value
      }
    })
  }

  _hasInputError = name => {
    const { _errorForm } = this.state;
    return _errorForm && _errorForm.errors && hasError(name, _errorForm)
  }

  _renderErrorMessage = name => {
    const { _errorForm } = this.state;
    return errorMessage(name, _errorForm)
  }

  _validateRawImages = async (withChild) => {
    const { _form: { _rawImage }, _rawImages } = this.state;
    let imageDrafts = [];
    if(_rawImage){
      imageDrafts = [_rawImage]
    }
    if(_rawImages.length && withChild) {
      imageDrafts = [...imageDrafts, ..._rawImages]
    }
    imageDrafts = await this._compressMultipleImages(imageDrafts)
    if(!withChild){
      return [...imageDrafts, ..._rawImages]
    }
    return imageDrafts;
  }

  _buildSubmitEvents = async () => {
    const { _form, _uploadImageFromMainForm } = this.state;
    let imageDrafts = [];
    let hasMainImage = false;
    const forceUploadImageFromMainForm = _uploadImageFromMainForm || !this.props.match.params.id
    if(_form._rawImage){
      hasMainImage = !hasMainImage;
    }
    imageDrafts = await this._validateRawImages(forceUploadImageFromMainForm);
    let _rawImage = null;
    if(hasMainImage && imageDrafts.length > 0){
      const [first, ...childs] = imageDrafts;
      _rawImage = first;
      imageDrafts = [...childs]
    }
    const payloads = {
      img_url: getOriginFromSignedUrl(_form.oldImageUrl),
      title: _form.title,
      description: _form.description,
      active: _form.active,
    }
    if(_form.id){
      payloads.id = _form.id;
    }
    let _nextSubmit = null;
    if(_rawImage) _nextSubmit = NEXT_SUBMIT_TYPES[1];
    else if(imageDrafts.length && forceUploadImageFromMainForm)
      _nextSubmit = NEXT_SUBMIT_TYPES[2];
    this.setState({
      _form:{
        ..._form,
        _rawImage
      },
      _nextSubmit,
      _rawImages: [...imageDrafts],
      _images: [],
      _formSubmit: true,
      _uploadSubmit: forceUploadImageFromMainForm,
      _uploadImageFromMainForm: forceUploadImageFromMainForm,
      _isModalLoadingOpen: _rawImage !== null || (imageDrafts.length > 0 && forceUploadImageFromMainForm)
    }, () => {
      this.props.saveAppSection(payloads)
    })
  }

  _handleSubmitForm = e => {
    e.preventDefault()
    if(this.state._rawImages.length>0 && this.props.match.params.id){
      this.setState({
        _uploadImagesConfirmationOpen: true
      })
      return;
    }
    this._buildSubmitEvents();
  }

  _handleModalDeleteToggle = () => {
    const { _formSubmit } = this.state;
    if(!_formSubmit){
      this.setState({
        _deleteConfirmationOpen: !this.state._deleteConfirmationOpen
      })
    }
  }

  _handleDeleteData = () => {
    this.setState({
      _formSubmit: true
    }, () => {
      const { _form: {id, img_url} } = this.state;
      const name = getPathS3(getOriginFromSignedUrl(img_url));
      if(name){
        const payload = {
          bucket_type : 'assets',
          name
        };
        this.props.deleteImage(payload);
      }
      else
        this.props.deleteAppSectionById(id);
    })
  }

  _renderLoading(){
    const { _formSubmit } = this.state;
    if(!_formSubmit) return null;
    return (
      <Row>
        <Col xs={12}>
          <Alert color="info">
            <strong>Heads up!</strong> Submitting your data...
          </Alert>
        </Col>
      </Row>
    )
  }

  _handleDropzoneChange = (files) => {
    if(files.length){
      const [img] = files;
      const rawObj = addImageRawObject(img);
      this.setState({
        _form: {
          ...this.state._form,
          _rawImage: { ...rawObj },
          img_url: img.preview
        },
        modalEditImage: {
          ...this.state.modalEditImage,
          isOpen: true,
          imageSourceEdits: [
            ...this.state.modalEditImage.imageSourceEdits,
            {
              imgId: rawObj.identifier,
              src: rawObj.URL
            }
          ],
          purpose: 'default',
          defaultImageRatio: DEFAULT_IMAGE_ASPECT_LANDSCAPE
        }
      });
    }
  }

  _handleDropzoneItemChange = (files, categoryId) =>{
    if(files.length){
      const fileInputs = files.map(c => ({
        ...addImageRawObject(c),
        category_id: categoryId
      }));
      this.setState({
        _rawImages: [
          ...this.state._rawImages,
          ...fileInputs
        ],
        modalEditImage: {
          ...this.state.modalEditImage,
          isOpen: true,
          imageSourceEdits: [
            ...this.state.modalEditImage.imageSourceEdits,
            ...fileInputs.map(f => ({
              imgId: f.identifier,
              src: f.URL
            }))
          ],
          purpose: 'child',
          defaultImageRatio: DEFAULT_IMAGE_ASPECT_PORTRAIT
        }
      });
    }
  }

  onCropSucceeded = async (blob, ratio) => {
    const blobUrl = URL.createObjectURL(blob);
    let fileName = blobUrl.substr(blobUrl.lastIndexOf('/')+1)
    if(fileName == '') fileName = 'local'
    const { modalEditImage, _form, _rawImages } = this.state;
    if(modalEditImage.purpose === 'default'){
      this.setState({
        _form: {
          ..._form,
          _rawImage: {
            ..._form._rawImage,
            fileInput: new File([blob], `${fileName}.jpeg`, {
              type: 'image/jpeg'
            }),
            URL: blobUrl,
            orientation: ratio.ratio <=1? 'portrait': 'landscape'
          },
          img_url: blobUrl
        },
        modalEditImage: {
          ...modalEditImage,
          isOpen: false,
          imageSourceEdits: []
        }
      })
    }
    else{
      let identifier = 0;
      if(modalEditImage.imageSourceEdits.length)
        identifier = modalEditImage.imageSourceEdits[0].imgId;

      this.setState({
        _rawImages: _rawImages.map(item => {
          if(item.identifier === identifier){
            return {
              ...item,
              fileInput: new File([blob], `${fileName}.jpeg`, {
                type: 'image/jpeg'
              }),
              URL: blobUrl,
              orientation: ratio.ratio <=1? 'portrait': 'landscape'
            }
          }
          return item;
        }),
        modalEditImage: {
          ...modalEditImage,
          isOpen: false,
          imageSourceEdits: modalEditImage.imageSourceEdits
            .filter(item => item.imgId !== identifier)
        }
      }, () => {
        const { modalEditImage, modalEditImage: {imageSourceEdits} } = this.state;
        if(imageSourceEdits.length){
          setTimeout(() => {
            this.setState({
              modalEditImage: {
                ...modalEditImage,
                isOpen: true
              }
            })
          }, 1500)
        }
      })
    }
  }

  render(){
    return(
      <Row>
        <ModalEditImage
          isOpen={this.state.modalEditImage.isOpen}
          imageSource={this.state.modalEditImage.imageSourceEdits.length? this.state.modalEditImage.imageSourceEdits[0].src: null}
          showGrid
          ratio={this.state.modalEditImage.defaultImageRatio}
          onCropSucceeded={this.onCropSucceeded}
        />
        <ModalInfo
          modalTitle="Oh snap."
          isOpen={this.state._isErrorModalOpen}
          toggle={() => this.setState({_isErrorModalOpen: !this.state._isErrorModalOpen})}
          renderModalBody={() => (<p className="text-center">An error occurred.</p>)}
        />
        <ModalConfirmation
          isOpen={this.state._uploadImagesConfirmationOpen}
          modalBody="You've not uploaded images (in App Section Images), press `Yes` to also upload your images."
          secondaryText="Later"
          onSubmit={() => {
            this.setState({
              _uploadImageFromMainForm: true,
              _uploadImagesConfirmationOpen: false
            }, () => {
              this._buildSubmitEvents();
            });
          }}
          toggle={() => {
            this.setState({
              _uploadImageFromMainForm: false,
              _uploadImagesConfirmationOpen: false
            }, () => {
              this._buildSubmitEvents();
            });
          }}
        />
        <ModalDelete
          isOpen={this.state._deleteConfirmationOpen}
          modalTitle="Confirmation."
          modalBody="Are you sure to delete this?"
          onDelete={this._handleDeleteData}
          toggle={this._handleModalDeleteToggle}
        />
        <ModalLoading isOpen={this.state._isModalLoadingOpen}/>
        <Col xs={12} sm={6} md={6}>
          <Card>
            <CardHeader>
              <FontAwesomeIcon iconType="align-justify"/> {this.props.title}
              {this.props.edit?
                <div>
                  <Link style={{fontSize: '.65rem'}} to="/app_sections/create">Create new App Section</Link>
                </div>
              :null}
            </CardHeader>
            <CardBody>
              {this._renderLoading()}
              <Row>
                <Col xs={12}>
                  <Alert color="danger" isOpen={this.state._alertErrorOpen} toggle={() => this.setState({_alertErrorOpen: false})}>
                    <strong>Oh Snap!</strong> { this.state._errorMessage }
                  </Alert>
                </Col>
                <Col xs={12}>
                  <Alert color="success" isOpen={this.state._alertSuccessOpen} toggle={() => this.setState({_alertSuccessOpen: false})}>
                    <strong>Done!</strong> { this.state._successMessage }
                  </Alert>
                </Col>
              </Row>
              {this.state._form.id? (
                <div>
                  <BadgeThin color="primary">#{this.state._form.id}</BadgeThin>
                  <BadgeThin>{this.state._form.slug}</BadgeThin>
                </div>
              ): null}
              <Form onSubmit={this._handleSubmitForm}>
                <CardText>
                  One section can be visible in all categories. So don’t create duplicate with the same section every category.
                </CardText>
                <FormGroup
                  className={classNames({ 'has-danger has-feedback': this._hasInputError('img_url') })}
                >
                  <Label>Default Image - optional</Label>
                  <div className="d-flex">
                    <ImageFile
                      style={{padding: 0}}
                      alt="App section's default image"
                      src={this.state._form.img_url? this.state._form.img_url : 'http://via.placeholder.com/350x150'}
                      showDelete={this.state._form._rawImage !== null}
                      onDelete={() => {
                        this.setState({
                          _form:{
                            ...this.state._form,
                            img_url: this.state._form.oldImageUrl,
                            _rawImage: null
                          }
                        })
                      }}
                    />
                    <div className="ml-3">
                      <DropzonePlusButton mulitple={false} onDrop={this._handleDropzoneChange}/>
                      <ul className="image-guides" style={{fontSize: '.75rem', marginTop: '.75rem', paddingInlineStart: '1rem'}}>
                        <li className="d-none">You can click the image for preview the image.</li>
                        <li>It will be display on Web only.</li>
                        <li>Use 1280x400 (16:5),  1280x720 (16:9) or  1280x465,45 (11:4) resolution of your image as our recommended. (In pixels).</li>
                        <li>Or any resolution that you want, but make sure its <strong>landscape</strong> orientation.</li>
                        <li>If you not provide/upload any images in “App Section Images Form”. By default, this image will be display on our platforms (Web or App).</li>
                        <li>By changing the image, click Submit button for save your changes.</li>
                      </ul>
                    </div>
                  </div>
                  { this._renderErrorMessage('img_url') }
                </FormGroup>
                <FormGroup
                  className={classNames({ 'has-danger has-feedback': this._hasInputError('title') })}
                >
                  <Label>Section Title</Label>
                  <Input
                    placeholder="Enter a title"
                    name="title"
                    type="text"
                    value={textValue('title', this.state._form)}
                    onChange={this._handleInputChange}
                  />
                  { this._renderErrorMessage('title') }
                </FormGroup>
                <FormGroup
                  className={classNames({ 'has-danger has-feedback': this._hasInputError('description') })}
                >
                  <Label>Section Description - optional</Label>
                  <Input
                    placeholder="Enter a description"
                    name="description"
                    type="textarea"
                    value={textValue('description', this.state._form)}
                    onChange={this._handleInputChange}
                  />
                  { this._renderErrorMessage('description') }
                </FormGroup>
                {
                  this.state._hasId? (
                    <FormGroupRadio
                      className={classNames({ 'has-danger has-feedback': this._hasInputError('active') })}
                    >
                      <Radio
                        type="checkbox"
                        name="active"
                        value={true}
                        checked={textValue('active', this.state._form)}
                        dataOnText="Yes"
                        dataOffText="No"
                        onChange={this._handleInputChange}
                      />
                      <div className="switch-label-wrapper">
                        <RadioText text="Set to Active"/>
                        <span id="formActiveTip" style={{marginLeft: 0, marginRight: 0}} className="switch-label-span">
                          <SimpleLineIcon iconType="question"/>
                        </span>
                        <UncontrolledTooltip innerClassName="text-left" placement="right" target="formActiveTip">If it's active it also visible in all categories. Verse versa.</UncontrolledTooltip>
                      </div>
                      { this._renderErrorMessage('active') }
                    </FormGroupRadio>
                  ): null
                }
                {
                  this.state._hasId?(
                    <Row>
                      <Col xs={12} md={6}>
                        <FormGroup>
                          <Label>Created</Label>
                          <Input
                            readOnly
                            name="created_at"
                            type="text"
                            value={textValue('created_at', this.state._form)}
                          />
                        </FormGroup>
                      </Col>
                      <Col xs={12} md={6}>
                        <FormGroup>
                          <Label>Updated</Label>
                          <Input
                            readOnly
                            name="updated_at"
                            type="text"
                            value={textValue('updated_at', this.state._form)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  ):null
                }
                <FormGroup row>
                  <Col>
                    <ButtonGroup>
                      <ButtonLoading
                        disabled={this.state._formSubmit}
                        isLoading={this.state._formSubmit}
                        loadingMessage="Submitting..."
                        color="primary"
                      >
                        Submit
                      </ButtonLoading>
                      {
                        this.state._hasId
                        && this.props.injectedProps
                          .roles.includes('superadministrator')?(
                          <ButtonLoading
                            type="button"
                            disabled={this.state._formSubmit}
                            loadingMessage="Deleting..."
                            onClick={this._handleModalDeleteToggle}
                            color="danger"
                          >
                            Delete
                          </ButtonLoading>
                        )
                        :null
                      }
                    </ButtonGroup>
                  </Col>
                </FormGroup>
              </Form>
            </CardBody>
          </Card>
        </Col>
        <Col xs={12} sm={6} md={6}>
          <Card>
            <CardHeader>
              <FontAwesomeIcon className="mr-2" iconType="image"/>
              <span className="mr-1">App Section Images</span>
              <SimpleLineIcon id="appSectionImageTitleTip" iconType="question"/>
              <UncontrolledTooltip innerClassName="text-left" placement="right" target="appSectionImageTitleTip">
                Please provide at least one image every category!
              </UncontrolledTooltip>
            </CardHeader>
            <CardBody>
              <ul className="image-guides" style={{fontSize: '.75rem', paddingInlineStart: '1rem'}}>
                <li className="d-none">You can click the image for preview the image.</li>
                <li>It will be display on Web and App.</li>
                <li>Every category can display different images.</li>
                <li>Use 1280x400 (16:5),  1280x720 (16:9) or  1280x465,45 (11:4) resolution of your image as our recommended for our web. (In pixels). Or any resolution that you want, but make sure its <strong>landscape</strong> orientation.</li>
                <li>Use 700x700 (1:1),  700x933,33 (3:4) or  700x1050 (4:6) resolution of your image as our recommended for our app display. (In pixels). Or any resolution that you want, but make sure its <strong>portrait</strong> orientation.</li>
                <li>If you not provide/upload any images in this form. By default, <strong>Default image</strong> will be display on our platforms (Web or App).</li>
                <li>You can upload multiple images, it will be sort by latest uploaded. (By default).</li>
                <li>By changing the images, click Upload button below this form or Submit button in "General Form" for save your changes.</li>
              </ul>
              <div>
                {this.props.dataCategories.map(category => ({
                  id: category.value,
                  name: category.label.toLowerCase()
                })).map(category => (
                  <ImageCategories
                    key={category.id}
                    showItems={!(!this.props.match.params.id)}
                    isLoading={this.state._uploadSubmit}
                    onClearImageFiles={() => {
                      const { _rawImages } = this.state;
                      this.setState({
                        _rawImages: _rawImages.filter(img => img.category_id !== category.id)
                      })
                    }}
                    onDeleteFile={index => {
                      const { _rawImages } = this.state;
                      this.setState({
                        _rawImages: _rawImages.filter((_img, idx) => idx !== index)
                      })
                    }}
                    onSaveImage={(data) => {
                      this.setState({_uploadSubmit: true, _selected: data}, () => {
                        if(data.deleted){
                          const payload = {
                            bucket_type : 'assets',
                            name : getPathS3(data.url)
                          };
                          this.props.deleteImage(payload);
                        }
                        else{
                          this.props.saveAppSectionImage(this.state._form.id, data)
                        }
                      })
                    }}
                    onDropFiles={files => this._handleDropzoneItemChange(files, category.id)}
                    category={category}
                    imageFiles={this.state._rawImages.filter(img => img.category_id === category.id)}
                    images={this.state._form.images.filter(img => img.category_id === category.id)}
                  />
                ))}
              </div>
              <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                {this.props.edit && this.state._form.id && this.state._rawImages.length?
                  <ButtonLoading
                    isLoading={this.state._uploadSubmit}
                    onClick={async () => {
                      const { _rawImages, _form: { id } } = this.state;
                      const compressedImages = await this._compressMultipleImages(_rawImages);
                      if(compressedImages.length){
                        const [next] = compressedImages;
                        this.setState({
                          _uploadSubmit: true,
                          _rawImages: [...compressedImages.map((img, key) => ({
                            ...img,
                            isUploading: key===0
                          }))],
                          _images: [],
                          _nextSubmit: 'UPLOAD_CHILD_IMG',
                          _isModalLoadingOpen: true
                        })
                        const tempPayload = createUploadFileForm(
                          compressedImages.map(img => img.fileInput),
                          true,
                          { name: "app-sections/"+id, bucket_type: "assets" }
                        );
                        this.props.uploadImage(tempPayload, _rawImages);
                      }
                    }}
                  >
                    Upload
                  </ButtonLoading>
                :null}
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    )
  }
}

const mapStateToProps=({ masterCategories, appSection, imageUpload })=>({
  dataCategories: masterCategories.options,
  dataAppSection: appSection.details,
  dataUploaded: imageUpload
})
const mapDispatchToProps=(dispatch)=>({
  uploadImage: (payload, rawImages) => dispatch(uploadImage(payload, rawImages)),
  deleteImage: (payload) => dispatch(deleteImage(payload)),
  deleteAppSectionById: id => dispatch(deleteAppSectionById(id)),
  saveAppSection: (payloads) => dispatch(saveAppSection(payloads)),
  getAppSectionById: id => dispatch(getAppSectionById(id)),
  getCategoryOptionList: ()=> dispatch(getCategoryOptionList()),
  uploadAppSectionImage: (folder, file, customFilename) => dispatch(uploadAppSectionImage(folder, file, customFilename)),
  saveAppSectionImage: (sectionId, payload) => dispatch(saveAppSectionImage(sectionId, payload)),
  deleteAppSectionImageById: (sectionId, id) => dispatch(deleteAppSectionImageById(sectionId, id))
})

export default connect(mapStateToProps, mapDispatchToProps)(AppSectionForm)