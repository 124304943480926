import React, {Component} from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  Button
} from 'reactstrap';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import { TextRupiah } from '../../../../components/Text';
import Radio from '../../../../components/Form/Radios/Radio';
import RadioText from '../../../../components/Form/Radios/RadioText';
import {
  hasError,
  errorMessage,
  textChange,
  textValue
} from '../../../../utils/form';
import Analytics from "../../../../services/Analytics";

export default class ModalDisburseSeller extends Component{
  constructor(props){
    super(props);
    this.state={
      id:null,
      amount:0,
      status:'',
      points:0.2,
      notes:'',
      error:null,
      with_balance_out: false
    }
    this.handleChange=this.handleChange.bind(this);
    this.onSubmit=this.onSubmit.bind(this);
  }

  componentWillReceiveProps(nextProps){
    if(nextProps.isOpen===true&&nextProps.isOpen!==this.props.isOpen&&nextProps.sale!==null){
      const initalValue={
        points:0.2,
        notes:'',
        amount:0,
        status:'',
        error:null,
        with_balance_out: false
      }
      this.setState({id:nextProps.sale.id,...initalValue, amount:nextProps.sale.seller_price,status:nextProps.sale.status});
    }
    if(nextProps.isSubmit!==this.props.isSubmit&&nextProps.isSubmit===false){
      this.setState({error:this.props.error});
    }
  }

  handleChange(e){
    this.setState(textChange(e,{...this.state}));
  }

  onSubmit(e){
    e.preventDefault();
    const { sale, email } = this.props;
    const {
      id,
      notes,
      points,
      amount,
      status,
      with_balance_out
    } = this.state;
    const payload = { notes, points, amount, status, with_balance_out };
    this.props.onSubmit(id, payload);
    Analytics.recordProcessedOrder(
      email,
      notes,
      sale.invoice_number,
      amount,
      points
    );
  }

    render(){
      return(
        <Modal
          isOpen={this.props.isOpen}
          toggle={this.props.onCancel}
          className="modal-dialog modal-md"
        >
          <ModalHeader toggle={this.props.onCancel} >
            Confirmation
          </ModalHeader>
          <ModalBody>
            {
              this.props.sale!==null?
              <p>
                Invoice: <i>({this.props.sale.invoice_number})</i> <br/>
                Email: <i>({this.props.sale.offer.user_sell.user.email})</i> <br/>
                Total: <TextRupiah prefix="Rp. " value={this.props.sale.seller_price} bold/>
              </p>
              :''
            }
            <p>
              By <strong>Confirm</strong> this form, giving user's points,
              increasing their balance and this order will be completed.
              So please validate carefully, you cannot undoing your changes.<br/>
              If you want to disburse their funds go to {' '}
              <Link to="/disbursements/create" title="Disbursements Page" className="link-secondary text-underline">Disbursements Page</Link>.
            </p>
            <Form onSubmit={this.onSubmit}>
              <FormGroup className={classnames({'has-danger has-feedback':hasError('amount',this.state.error)})}>
                <Label for="formDisburseAmount">Amount*</Label>
                <Input
                  type="number"
                  id="formDisburseAmount"
                  name="amount"
                  placeholder="Amount to disburse"
                  value={textValue('amount',this.state)}
                  onChange={this.handleChange}
                />
                {errorMessage('amount',this.state.error)}
              </FormGroup>
              {this.props.sale?.shipper && this.props.sale.seller_courier_price > 0 ? (
                <FormGroup>
                  <Label for="sellerCourierAmount">Shipping Fee</Label>
                  <Input
                    readOnly
                    type="number"
                    id="sellerCourierAmount"
                    name="seller_courier"
                    value={this.props.sale.seller_courier_price}
                  />
                  <span className="text-sm">Since the seller is using shipper, the balance will be reduced by the shipping fee</span>
                </FormGroup>
              ): null}
              <FormGroup className={classnames({'has-danger has-feedback':hasError('points',this.state.error)})}>
                <Label for="formDisburseSellerPoint">Increase Seller Point*</Label>
                <Input
                  type="number"
                  id="formDisburseSellerPoint"
                  name="points"
                  placeholder="Put your points to seller here"
                  value={textValue('points',this.state)}
                  onChange={this.handleChange}
                />
                {errorMessage('points',this.state.error)}
              </FormGroup>
              <FormGroup className={classnames({'has-danger has-feedback':hasError('notes',this.state.error)})}>
                <Label for="formDisburseSellerNotes">Notes</Label>
                <Input
                  id="formDisburseSellerNotes"
                  type="textarea"
                  name="notes"
                  placeholder="Put your notes here"
                  value={textValue('notes',this.state)}
                  onChange={(e)=>this.handleChange(e)}
                />
                {errorMessage('notes',this.state.error)}
              </FormGroup>
              <FormGroup className={classnames({'has-danger has-feedback':hasError('with_balance_out',this.state.error)})}>
                <Radio value="true" checked={this.state.with_balance_out} onChange={e=>this.setState({ with_balance_out: e.target.checked })}/>
                <RadioText text="Also create a balance out disbursement?"/>
                {errorMessage('with_balance_out',this.state.error)}
              </FormGroup>
            </Form>
            <span className="text-sm">Fields marked with * are required</span>
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              disabled={this.props.isSubmit}
              onClick={this.onSubmit}
            >
              {this.props.isSubmit?'Submitting...':'Confirm'}
            </Button>{' '}
            <Button
              color="secondary"
              disabled={this.props.isSubmit}
              onClick={this.props.onCancel}
            >
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      )
    }
}

ModalDisburseSeller.propTypes={
  sale:PropTypes.object,
  isSubmit:PropTypes.bool,
  error:PropTypes.object,
  isOpen:PropTypes.bool,
  onSubmit:PropTypes.func,
  onCancel:PropTypes.func
}
