import React from 'react';
import PropTypes from 'prop-types';
import {
  Alert,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input, 
  Button,
  Row,
  Col
} from 'reactstrap';
import Select from 'react-select';
import api from '../../../utils/Api';
import classnames from 'classnames';
import Analytics from "../../../services/Analytics";
import { ButtonLoading } from '../../../components/Button';
import ProductValue from '../ConciergeDetail/conciergeDetail/ProductValue';
import ProductOption from '../ConciergeDetail/conciergeDetail/ProductOption';
import SelectVoucher from '../../../components/Form/Select/Async/SelectVoucher';
import { conciergeStatusOptions, paymentTypeOptions } from '../../../constants/kickavenue';
import { AUTH_TOKEN_KEY, VOUCHER_PRODUCT_TYPE, VOUCHER_COURIER_TYPE } from '../../../constants';
import { hasError, errorMessage, textChange, textValue, dateValue, dateChange, getAdmFees } from '../../../utils/form';

const DEFAULT_SUCCESS_MSG = 'Updating concierge successfully.';
const DEFAULT_ERROR_422_MSG = 'Please fulfill your form.';
export class ModalPaymentConcierge extends React.Component {
  constructor(props){
    super(props);
    const _uniqueAmountDef=this._generateUniqueAmount();
    this.state={
      _id: props.id,
      _status:props.data ? props.data.status:null,
      _user:props.user,
      _product:null,
      _province:'',
      _shipping_id:null,
      _paymentMethod: 'bank_transfer',
      _adminFeeDiscount:0,
      _uniqueAmount:_uniqueAmountDef,
      _uniqueAmountDef,
      _administrationFee:0,
      _kaCourierOption:'FLAT_RATE',
      _discount:0,
      _quantity:1,
      _isDisabled:false,
      _offerAmount:0,
      _productPromo: 0,
      _kickAmount: 0,
      _shippingFee: 0,
      _voucherInput: null,
      _voucherInputTemp: null,
      _voucherValidated:null,
      _isSubmit: false,
      _isCheck: false,
      _error: null,
      _isSuccessAlertDisplayed: false,
      _isErrorAlertDisplayed: false,
      _successMsg: DEFAULT_SUCCESS_MSG,
      _errorMsg: DEFAULT_ERROR_422_MSG,
    }
    this.setValue = this.setValue.bind(this);
    this._onSubmit = this._onSubmit.bind(this);
    this._applyVoucher = this._applyVoucher.bind(this);
    this._handleChange = this._handleChange.bind(this);
    this._handleSelectVoucher = this._handleSelectVoucher.bind(this);
    this._alertSuccessToggle = this._alertSuccessToggle.bind(this);
    this._alertErrorToggle = this._alertErrorToggle.bind(this);
    this._getProductOption = this._getProductOption.bind(this);
    this._handlePaymentTypeChange = this._handlePaymentTypeChange.bind(this);
  }

  componentDidUpdate(prevProps){
    const { error, dataVoucher, dataCourier, id, status } = this.props;
    if(!prevProps.isOpen && prevProps.isOpen !== this.props.isOpen && this.props.selected!==null){
      const { updated_at, shipping, offer, user } = this.props.selected;
      const offerAmount = offer ? parseInt(offer.user_sell.asking_price - offer.offer_amount) : 0;
      const product = offer ? {
        id: offer.user_sell_id, 
        price: offer.user_sell.price, 
        product_variant_id: offer.user_sell.product_variant_id
      } : null;
      this.setState({
        _error: error,
        _updated_at:updated_at,
        _user:user,
        _id:id,
        _offerAmount: offerAmount,
        _status:status,
        _product:product,
        _province:shipping.province,
        _shipping_id:shipping.id,
      },()=>{
        if(shipping)
          this._calculateShippingFee(this._getProvinceId());
      })
    }
    if(this.state._isSubmit && prevProps.isSubmit && !this.props.isSubmit){
      if(error){
        this.setState({
          _isSubmit: false,
          _error: error,
          _isErrorAlertDisplayed: true,
          _errorMsg: error.status_code === 422? DEFAULT_ERROR_422_MSG: error.message
        })
      }
      else{
        const { status, updated_at, user } = this.props.details;
        this.setState({
          _error: null,
          _product:null,
          _offerAmount:null,
          _isSubmit: false,
          _isSuccessAlertDisplayed: true,
          _updated_at:updated_at,
          _id:id,
          _status:status,
          _user:user,
        },()=>{
          this.props.onCancel();
          this.props.getList();
        })

      }
    }
    if(prevProps.dataCourier.isFetch
      && prevProps.dataCourier.isFetch!==dataCourier.isFetch){
      if(dataCourier.status_code===200){
        let {_paymentMethod, _product, _voucherValidated}=this.state;
        const { cost } = dataCourier;
        const _discount = this._getVoucherAmount(_voucherValidated, _product?_product.price:0, cost);
        this.setState({
          _shippingFee: cost,
          _administrationFee:this._getAdmFee(_paymentMethod, _product?_product.price:0, cost, _discount),
          _discount
        })
      }
    }
    if(prevProps.dataVoucher.isFetch
      &&prevProps.dataVoucher.isFetch!==dataVoucher.isFetch){
      const { _product:{ price } } = this.state;
      if(!dataVoucher.error && dataVoucher.status_code===200){
        const { code, type, amount, deduct_type, minimum_purchase, max_amount } = dataVoucher.data;
        const _discount = this._getVoucherAmount({ code, type, amount, deduct_type, max_amount }, price, this.state._shippingFee);
        this.setState({
          _isCheck:false,
          _isValidVoucher:true,
          _isSuccessAlertDisplayed: true,
          _successMsg : dataVoucher.message,
          _voucherValidated: { code, type, amount, deduct_type, minimum_purchase, max_amount },
          _administrationFee: this._getAdmFee(this.state._paymentType, price,this.state._shippingFee,_discount),
          _discount
        });
      }
      else{
        let _error=null;
        switch(dataVoucher.error.status_code){
          case 422:{
            _error={
              errors:{
                voucher: Object.keys(dataVoucher.error.errors)
                  .filter(err => err==='code')
                  .map(err => {
                    if(dataVoucher.error.errors[err][0] === 'Voucher can only use on app.'){
                      return ['Sorry this voucher can only apply through our application. You can download it in App Store or Play Store.']
                    }
                    return dataVoucher.error.errors[err]
                  })
              },
              status_code:422
            }
          }
          break;
          case 404:{
            _error={
              errors:{
                voucher:['Voucher tidak ditemukan.']
              },
              status_code:422
            }
          }
          break;
          default:{
            _error={
              errors:{
                voucher:['Oopss ada error. Please inform our staff via our official Line account.']
              }
            }
            break;
          }
        }
        this.setState({
          _error:_error,
          _isCheck:false,
          _isErrorAlertDisplayed: true,
          _errorMsg:dataVoucher.error.message,
          _discount:0,
          _voucherInput: null,
          _voucherValidated:null,
          _isValidVoucher: false
        })
      }
    }
    if(prevProps.dataProvince.isFetch && prevProps.dataProvince.isFetch !== this.props.dataProvince.isFetch){
      const { error } = this.props.dataProvince;
      if(!error){
        this._calculateShippingFee(this._getProvinceId());
      }
    }
  }

  _getProvinceId(){
    const { _province } = this.state;
    const { data } = this.props.dataProvince;
    const provinceId = data.filter(item => item.name === _province);
    if(_province && provinceId.length) return provinceId[0].id;
    return 0;
  }

  _calculateShippingFee = async (provinceId)=>{
    const { _product } = this.state;
    if(_product){
      const { product_variant_id, weight } = _product;
      await this.setState({
        _isErrorShippingFetch: false
      });
      this.props.getShippingFee({
        destination: provinceId,
        weight: weight || 1000,
        product_variant_id
      });
    }
  }

  _getVoucherAmount(voucherObj, price, courierPrice){
    let discount = 0;
    if(voucherObj !== null){
      if(voucherObj.deduct_type === VOUCHER_PRODUCT_TYPE){
        if(voucherObj.type==='percentage'){
          discount = parseInt(Math.ceil((voucherObj.amount / 100) * price));
        }
        else{
          discount = voucherObj.amount;
        }
        discount = voucherObj.max_amount && parseInt(discount) > parseInt(voucherObj.max_amount)?
          voucherObj.max_amount: discount;
        return discount>price? price : discount;
      }
      else if(voucherObj.deduct_type === VOUCHER_COURIER_TYPE){
        if(voucherObj.type==='percentage'){
          discount = parseInt(Math.ceil((voucherObj.amount / 100) * courierPrice));
        }
        else{
          discount = voucherObj.amount;
        }
        discount = voucherObj.max_amount && parseInt(discount) > parseInt(voucherObj.max_amount)?
          voucherObj.max_amount: discount;
        return discount>courierPrice? courierPrice : discount;
      }
    }
    return discount;
  }

  _validatePayloads(){
    let objErrors = {};
    const { offer, order_id } = this.props.selected;
    if(['AWAITING_DP','AWAITING_FP'].includes(this.state._status)){
      if(!this.state._product){
        objErrors['user_sell_id'] = ["Please select a product."];
      }
      if(!this.state._paymentMethod){
        objErrors['payment_method'] = ["Please insert payment method."];
      }
      if((offer && offer.status === 'PENDING' && this.state._status==='AWAITING_FP') || 
       (!order_id && this.state._status==='SALE_CREATED')){
        objErrors['status'] = ["Cant change status before payment paid"];
      }
    }
    if(Object.keys(objErrors).length){
      this.setState({
        _error:{
          errors: objErrors,
          statusCode: 422
        },
        _errorMsg: 'Please fulfill your form!',
        _isErrorAlertDisplayed: true
      })
    }
    return objErrors;
  }

  _clearMessage(){
    this.setState({_error:null, _isErrorAlertDisplayed:false, _errorMsg:'', _isSuccessAlertDisplayed:false, _successMessage:''});
  }

  _generateUniqueAmount(){
    return Math.floor(Math.random() * 100);
  }

  _getAdmFee(paymentType, price, courierPrice, discount){
    return getAdmFees(paymentType, price, courierPrice, discount);
  }

  _handlePaymentTypeChange(e){
    const lastState={...this.state};
    lastState[e.target.name]=e.target.value;
    lastState._administrationFee=this._getAdmFee(e.target.value,this.state._product?this.state._product.price:0,this.state._shippingFee,this.state._discount);
    switch(e.target.value){
      case 'bca_va':
      case 'bri_va':
      case 'bni_va':
      case 'mandiri_va':
      case 'credit_card':
      case 'permata_va':{
        lastState._uniqueAmount=0;
        break;
      }
      case 'full_wallet':{
        lastState._uniqueAmount = 0;
        lastState._kickAmount = 0;
        lastState.walletPayment = false;
        break;
      }
      default:{
        lastState._uniqueAmount=lastState._uniqueAmountDef;
        break;
      }
    }
    this.setState({...lastState});
  }

  _getProductOption(input){
    const myToken = localStorage.getItem(AUTH_TOKEN_KEY);
    const headers = {
      'Authorization': `Bearer ${myToken}`
    };
    const params = {
      availables:true,
      keyword: input,
      sort_by: 'updatedAt_desc'
    }
    return api(headers).get('/admins/sells', { params })
      .then(({ data }) => {
      return { options: data.data.data.map(item=>({ 
        id: item.id, 
        email: item.user.email, 
        product_variant_id:item.product_variant_id,
        weight:item.product_variant.weight,
        product: item.product_variant.display_name,
        subsidy_price: item.subsidy_price,
        price: item.asking_price,
        size: item.size.US
      }))};
    });
  }

  _calculateAllAmount = () =>{
    const {_paymentMethod, _product, _shippingFee, _voucherInput}=this.state;
    const _discount = 0;
    this.setState({
      _administrationFee:this._getAdmFee(_paymentMethod, _product?_product.price:0, _shippingFee, _discount),
      _discount,
      _voucherValidated: null,
      _voucherInput: null,
      _voucherInputTemp: _voucherInput
    })
  }

  setValue (value) {
    const { _product: lastProduct, _status } = this.state;
    const lastId = lastProduct? lastProduct.id: null;
    const nextId = value? value.id: null;
    const amount = value? Math.round(value.price * (100-50)/100) : 0;
		this.setState({ _product: value, _quantity: 1, _offerAmount: parseInt(amount)}, () =>{
      if(_status==='AWAITING_DP')
        this._calculateShippingFee(this._getProvinceId());
      if(nextId !== lastId)
        this._calculateAllAmount();
    });
  }

  _applyVoucher(){
    this._clearMessage();
    const getLocalErrors = this._validatePayloads();
    if(Object.keys(getLocalErrors).length===0){
      const { _voucherInput, _product, _user } = this.state;
      if(_voucherInput){
        this.setState({_isCheck:true},()=>{
          this.props.applyVoucher({ code:_voucherInput?_voucherInput.code:null, user_id:_user?_user.id:null, user_sell_id: _product.id, total_amount: parseFloat(_product.price) });
        });
      }
    }
    else{
      this.setState({
        _voucherInput: null
      })
    }
  }

  _handleChange(e){
    const lastState = this.state;
    this.setState({
      ...textChange(e, lastState),
    });
  }

  _handleSelectVoucher(option){
    this.setState({
      _voucherInput: option,
      _voucherInputTemp: option === null && this.state._voucherInput? this.state._voucherInput:null
    });
  }

  _getTotalWithoutWallet = () => {
    const { _offerAmount, _discount, _shippingFee, _productPromo, _quantity, _administrationFee }=this.state;
    const totalAmount=((_offerAmount*_quantity)+(_shippingFee*_quantity)-_discount+_administrationFee+_productPromo);
    return parseFloat(totalAmount)
  }

  _onSubmit(e){
    e.preventDefault();
    this._clearMessage();
    const getLocalErrors = this._validatePayloads();
    if(Object.keys(getLocalErrors).length===0){
      this.setState({
        _isSubmit: true,
        _error: null
      },()=>{
        const { 
          _id,
          _user,
          _status,
          _shipping_id, 
          _shippingFee,
          _offerAmount,
          _uniqueAmount,
          _paymentMethod,
          _administrationFee,
          _kaCourierOption,
          _kickAmount,
          _quantity,
          _product,
          _isErrorAlertDisplayed, 
          _isSuccessAlertDisplayed, 
        } = this.state;
        Analytics.recordChangeStatusConcierge(
          _id,
          _status, 
          _offerAmount,
          _user.email,
        )
        let dataPayload = {
          status: _status
        }
        const isUsingFullWallet = _paymentMethod === 'full_wallet' ? true
        : (parseFloat(_kickAmount) === this._getTotalWithoutWallet())?
        true : false;
        if(_status === 'AWAITING_DP'){
          const id = _product ? _product.id : null;
          dataPayload.user_sell_id=id;
          dataPayload.currency='IDR';
          dataPayload.quantity=_quantity;
          dataPayload.shipping_id=_shipping_id;
          dataPayload.payment_method=isUsingFullWallet? 'full_wallet' : _paymentMethod;
          dataPayload.ka_courier_price=_shippingFee;
          dataPayload.offer_amount=_offerAmount;
          dataPayload.unique_amount=isUsingFullWallet? 0 : _uniqueAmount;
          dataPayload.administration_fee=isUsingFullWallet? 0 : _administrationFee;
          dataPayload.ka_courier_option=_kaCourierOption;
          dataPayload.wallet_amount=isUsingFullWallet? this._getTotalWithoutWallet(): _kickAmount
        }
        else{
          if(_status === 'AWAITING_FP'){
            const id = _product ? _product.id : null;
            dataPayload.user_sell_id=id;
            dataPayload.currency='IDR';
            dataPayload.quantity=_quantity;
            dataPayload.payment_method=isUsingFullWallet? 'full_wallet' : _paymentMethod;
            dataPayload.ka_courier_price=0;
            dataPayload.offer_amount=_offerAmount;
            dataPayload.unique_amount=isUsingFullWallet? 0 : _uniqueAmount;
            dataPayload.administration_fee=isUsingFullWallet? 0 : _administrationFee;
            dataPayload.ka_courier_option=_kaCourierOption;
            dataPayload.wallet_amount=isUsingFullWallet? this._getTotalWithoutWallet(): _kickAmount
          }
        }
        this.props.updateConcierge(_id, dataPayload)
        if(_isErrorAlertDisplayed)
          this._alertErrorToggle();
        if(_isSuccessAlertDisplayed)
          this._alertSuccessToggle()
      })
    }
  }

  _alertSuccessToggle(){
    this.setState({
      _isSuccessAlertDisplayed: !this.state._isSuccessAlertDisplayed
    })
  }

  _alertErrorToggle(){
    this._clearMessage();
  }

  render(){
    const payments = ['atome', 'akulaku', 'kredivo', 'installments', 'virtual_account'];
    return (
      <Modal isOpen={this.props.isOpen} toggle={this.props.onCancel} className="modal-dialog modal-md">
        <ModalHeader toggle={this.props.onCancel}>{this.props.title}</ModalHeader>
        <ModalBody>
          <Form>
            <Row>
              <Col xs={12}>
                <Alert
                  color="success"
                  isOpen={this.state._isSuccessAlertDisplayed}
                  toggle={this._alertSuccessToggle}
                >
                  <strong>Success. </strong> {this.state._successMsg}
                </Alert>
                <Alert
                  color="danger"
                  isOpen={this.state._isErrorAlertDisplayed}
                  toggle={this._alertErrorToggle}
                >
                  <strong>Opps. </strong> {this.state._errorMsg}
                </Alert>
              </Col>
              <Col xs={12} md={6}>
                <FormGroup
                  className={classnames({'has-danger has-feedback':hasError('status',this.state._error)})}
                >
                  <Label for="_formStatus">
                    Status
                  </Label>
                  <Input
                    id="_formStatus"
                    type="select"
                    name="_status"
                    disabled={this.props.isDisabled}
                    value={textValue('_status',this.state)}
                    onChange={this._handleChange}
                  >
                    {
                      conciergeStatusOptions.map((item, index)=>{
                        if(this.props.failedStatus && 
                          (this.props.failedStatus.includes(item.value) || this.props.failedStatus.length === 0))
                          return <option value={item.value} key={index}>{item.name}</option>
                    })
                    }
                  </Input>
                  { errorMessage('status',this.state._error) }
                </FormGroup>
              </Col>
              <Col xs={12} md={6} style={{ display: !['AWAITING_FP', 'AWAITING_DP'].includes(this.state._status) ? 'none':'block' }}>
                <FormGroup className={classnames({'has-danger has-feedback':hasError('payment_method', this.state._error)})}>
                  <Label for="_formPaymentMethod">Payment Method</Label>
                  <Input
                    id="_formPaymentMethod"
                    type="select"
                    name="_paymentMethod"
                    value={textValue('_paymentMethod',this.state)}
                    onChange={this._handlePaymentTypeChange}
                  >
                    {
                      paymentTypeOptions.map((item, index)=>{
                        if(!payments.includes(item.value)){
                          return <option value={item.value} key={index}>{item.name}</option>
                        }
                      })
                    }
                  </Input>
                  {errorMessage('payment_method', this.state._error)}
                </FormGroup>
              </Col>
              <Col xs={12} style={{display: !['AWAITING_DP'].includes(this.state._status) ? 'none':'block'}}>
                <FormGroup className={classnames({'has-danger has-feedback':hasError('user_sell_id', this.state._error)})}>
                  <Label>Product</Label>
                  <Select.Async
                    style={{height:this.state.product?65:45}}
                    className="_customSelect"
                    valueKey="id"
                    labelKey="product"
                    noResultsText="Cannot find product."
                    loadOptions={this._getProductOption}
                    optionComponent={ProductOption}
                    valueComponent={ProductValue}
                    onChange={this.setValue}
                    value={this.state._product}
                    placeholder="Type and select a product..."
                  />
                  {errorMessage('user_sell_id', this.state._error)}
                </FormGroup>
              </Col>
              <Col xs={12} md={6} style={{display: !['AWAITING_FP', 'AWAITING_DP'].includes(this.state._status) ? 'none':'block'}}>
                <FormGroup className={classnames({'has-danger has-feedback':hasError('offer_amount', this.state._error)})}>
                  <Label for="_formAmount">Amount</Label>
                  <Input
                    id="_formAmount"
                    name="_offerAmount"
                    onChange={this._handleChange}
                    value={textValue('_offerAmount',this.state)}
                    type="number"
                    placeholder="Amount..."
                  />
                  {errorMessage('offer_amount', this.state._error)}
                </FormGroup>
              </Col>
              <Col xs={8} md={8} style={{display: !['AWAITING_DP'].includes(this.state._status) ? 'none':'block'}}>
                <FormGroup className={classnames({'has-danger has-feedback':hasError('voucher', this.state._error)})}>
                  <Label for="_formSelectVoucher">Voucher</Label>
                  <SelectVoucher
                    defaultOptions
                    id="_formSelectVoucher"
                    ref="selectVoucher"
                    value={this.state._voucherInput}
                    paramsApi={{ sort_by: 'createdAt_desc' }}
                    placeholder="Select a voucher and click check..."
                    noResultsText="Cannot find voucher."
                    onSelectChange={this._handleSelectVoucher}
                  />
                  {this.state._voucherInputTemp?(
                    <div style={{fontSize: '.65rem'}}>
                      Click to re-apply: <a href="#" onClick={e => {
                        e.preventDefault();
                        this.setState({
                          _voucherInput: { ...this.state._voucherInputTemp },
                          _voucherInputTemp: null
                        }, () => this._applyVoucher())
                      }}>{this.state._voucherInputTemp.code}</a>
                    </div>
                  ):null}
                  {errorMessage('voucher', this.state._error)}
                </FormGroup>
              </Col>
              <Col xs={4} md={4} style={{display: !['AWAITING_DP'].includes(this.state._status) ? 'none':'block'}}>
                <FormGroup style={{margin:'1.7rem 0 0 0'}}>
                  <ButtonLoading
                    isLoading={this.state._isCheck}
                    disabled={this.state._isCheck||!this.state._voucherInput}
                    onClick={this._applyVoucher}
                    loadingMessage="Submitting..."
                    color={this.state._isCheck||!this.state._voucherInput?"secondary":"primary"}
                  >
                    Check
                  </ButtonLoading>
                </FormGroup>
              </Col>
            </Row>
          </Form>
          <span className="text-sm">Fields marked with * are required</span>  
        </ModalBody>
        <ModalFooter>
          <Button color="primary" disabled={this.props.isSubmit} onClick={this._onSubmit}>{this.props.isSubmit?'Submitting...':'Save'}</Button>{' '}
          <Button color="secondary" onClick={this.props.onCancel}>Cancel</Button>
        </ModalFooter>
      </Modal>
    )
  }
}

ModalPaymentConcierge.defaultProps = {
  failedStatus: [],
  status: 'NEW',
}

ModalPaymentConcierge.propTypes={
  failedStatus:PropTypes.array,
  selected:PropTypes.object,
  details:PropTypes.object,
  isSubmit:PropTypes.bool,
  error:PropTypes.object,
  isOpen:PropTypes.bool,
  onSubmit:PropTypes.func,
  onCancel:PropTypes.func
}