import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import {
  Badge,
  Row,
  Label,
  Col,
  Card,
  CardBody,
  CardText,
  CardHeader,
  ListGroup,
  ListGroupItem
} from 'reactstrap';
import ReactLoading from 'react-loading';
import Loading from 'react-loading-animation';
import { getMenuList, moveMenu, fixMenu } from '../../actions/menuAction';
import privateView from '../../components/hocs/privateView';
import { getOptionList as getCategoryOptionList } from '../../actions/categoriesAction';
import { SortableContainer, SortableElement, arrayMove, SortableHandle } from 'react-sortable-hoc';
import Analytics from "../../services/Analytics";

const DragHandle = SortableHandle(() => <i className="fa fa-bars" style={{cursor:'move'}}></i>);

const SortableItem = SortableElement(({item}) =>
	<ListGroupItem  style={{WebkitUserSelect: 'none'}}>
    <div style={{display: 'flex', flexDirection: 'row', alignItems:'center', justifyContent: 'space-between'}}>
      <DragHandle />
      <div style={{flex: 1, margin: "0px .5rem"}}>
        <p style={{margin: 0}}>
          { item.title } { !item.is_active? <Badge color="danger">inactive</Badge>: null} <br/>
          <span style={{fontSize: '.75rem', fontStyle: 'italic'}}>{ item.url }</span>
        </p>
      </div>
      <Link to={`/menus/${item.id}`} className="pull-right text-gray-dark"><i className="fa fa-pencil"></i></Link>
    </div>
	</ListGroupItem>
);

const RootItem=({item,key})=>{
	return (
		<ListGroupItem key={key} style={{WebkitUserSelect: 'none', background:'#ddd'}}>
      <div style={{display: 'flex', flexDirection: 'row', alignItems:'center', justifyContent: 'space-between'}}>
        <i className="fa fa-tags"></i>
        <div style={{flex: 1, margin: "0px .5rem"}}>
          <p style={{margin: 0}}>
            <strong>{item.title}</strong> { !item.is_active? <Badge color="danger">inactive</Badge>: null} <br/>
            <span style={{fontSize: '.75rem', fontStyle: 'italic'}}>{ item.url }</span>
          </p>
        </div>
        <Link to={`/menus/${item.id}`} className="pull-right text-gray-dark"><i className="fa fa-pencil"></i></Link>
      </div>
		</ListGroupItem>
	)
}

const SortableList = SortableContainer(({items}) => {
	return (
		<ListGroup>
			{items.map((item, index) => (
				<div key={`collection-${index}`}>
					<RootItem item={item}/>
					{
						item.children.map((child,indexChild)=><SortableItem key={`item-${indexChild}`} index={indexChild} item={child} collection={index} />)
					}
				</div>
			))}
		</ListGroup>
	);
});

class MenuIndex extends Component {
  constructor(props){
    super(props);
    this.state={
      _params:{
        category_id : '',
      },
      _columnCount:5,
      items:[],
    }
  }

  componentDidMount(){
    const { email } = this.props.injectedProps;
    this.props.bindCategoryOption();
    Analytics.recordPageView('menu_list', email, {
      url: window.location.href,
      location: this.props.location,
      match: this.props.match
    });
  }

  async componentDidUpdate(prevProps){
    const { menu, categoryOptions } = this.props;
    if(categoryOptions.isFetch!=prevProps.categoryOptions.isFetch
      && !categoryOptions.error && categoryOptions.options.length > 0){
      await this.setState({
        _params: {
          ...this.state._params,
          category_id: categoryOptions.options[0].value
        }
      })
      const { _params } =this.state;
      const query={ params: {..._params} };
      this.props.getList(query);
    }
    if(menu.isFetch!=prevProps.menu.isFetch && !menu.isFetch &&
      menu.list.data !== undefined && menu.list.status_code===200){
			const {data} = this.props.menu.list;
			this.setState({items:data});
    }
    if(prevProps.menu.fixMenu.isSubmit && prevProps.menu.fixMenu.isSubmit !== this.props.menu.fixMenu.isSubmit){
			const { _params } =this.state;
			const query={ params: {..._params} };
			this.props.getList(query);
			const { error } = this.props.menu.fixMenu;
			if(error){
				alert("Error: fix-brands");
			}
		}
  }

  onSelectChange(val, attributeForm){
    const { _params } =this.state;
    _params[attributeForm]=val===null?val:val.value;
    this.setState({_params});
    const query={ params: {..._params} };
    this.props.getList(query);
  }

  onSortEnd({oldIndex, newIndex, collection}) {
		const {items} = this.state;
		const rowId=items[collection].children[oldIndex].id;
		const toRowId=items[collection].children[newIndex].id;
		items[collection]={...items[collection],children:arrayMove(items[collection].children, oldIndex, newIndex)}
		this.setState({
		  items
		}, ()=>this.props.move(rowId,toRowId,newIndex>oldIndex));
  }

  render(){
    return(
      <div className="animated fadeIn">
        <Row>
          <Col xs={12} md={{size:6, offset:3}}>
            <Card>
              <CardHeader>
                <i className="fa fa-align-justify"></i>
                  Menu List
                <a style={{fontSize: '.75rem'}} href="#" className="ml-3 text-danger" onClick={e => {
                  e.preventDefault()
                  this.props.fixMenu()
                }}>
                  <i className="fa fa-cogs"></i> Fix Menu
                </a>
              </CardHeader>
              <CardBody>
              <CardText style={{margin: 0}}>
                Displaying available menu for our website:
              </CardText>
              <Row style={{paddingBottom: '1.25rem'}}>
                <Col xs={12} md={6}>
                  <Label for="SelectCategoryName">Select category</Label>
                  <Select
                    id="formCategoryName"
                    isClearable={false}
                    name="category_id"
                    value={this.state._params.category_id}
                    options={this.props.categoryOptions.options.length?this.props.categoryOptions.options:[{value:'',label:'Loading...'}]}
                    onChange={(val)=>{this.onSelectChange(val,'category_id')}}
                  />
                </Col>
              </Row>
              {
                this.props.menu.isSubmit?
                <div className="loading-wrapper">
                  <ReactLoading type="bars" color="#444" className="loading-center" width={30} height={30}/>
                  <p className="text-center">Updating...</p>
                </div>
                :''
              }
              {
                this.props.menu.isFetch || this.props.menu.list.status_code!==200?
                <Loading/>
                :
                <SortableList items={this.state.items} onSortEnd={this.onSortEnd.bind(this)} lockAxis="y"  useDragHandle={true} />
              }
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    )
  }
}

const mapStateToProps = ({ menu, auth: { isFetch, roles, isLogged, id, email }, masterCategories}) => {
  return {
    menu,
    guardData: { isFetch, roles, isLogged, id, email },
    categoryOptions : masterCategories
  }
};

const mapDispatchToProps = (dispatch) => {
  return{
    move: (id, to, after) => dispatch(moveMenu(id, to, after)),
    getList:(query) => dispatch(getMenuList(query)),
    bindCategoryOption:()=>dispatch(getCategoryOptionList()),
    fixMenu:()=>dispatch(fixMenu())
  }
};

const enchane = connect(mapStateToProps,mapDispatchToProps)
export default enchane(privateView(MenuIndex));