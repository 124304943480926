import {
    TIMER_LIST_FETCH,
    TIMER_FETCHING,
    TIMER_DETAIL_FETCH,
    TIMER_ADD,
    TIMER_UPDATE,
    TIMER_DELETE,
    TIMER_ERROR,
} from '../constants';

const initialState = {
    list: [],
    detail: {},
    isFetch: false,
    isSubmit: false,
    error: null,
    success: null
}

const onFetching = (lastState,payload) => {
  if(payload.type==='fetch')
    return { ...lastState, isFetch:payload.status, success: null, error: null }
  else if(payload.type==='submit')
    return { ...lastState, isSubmit:payload.status }

  return { ...lastState, isFetch:false,isSubmit:false }
}

const reducer=(state=initialState, action) => {
    switch(action.type){
        case TIMER_LIST_FETCH: {
            const {data, status_code, message} = action.payload;
            return {...state, list:{...data, message, status_code}, detail: {}, error: null}
        }
        case TIMER_FETCHING: return onFetching(state, action.payload);
        case TIMER_DETAIL_FETCH: {
            const {data, status_code, message}=action.payload;
            return {...state, detail: {...state.detail, ...data, message, status_code, deleted: false}, error: null}
        }
        case TIMER_ADD: {
            const {data, status_code, message} = action.payload;
            return {...state, detail:{...state.detail, ...data, message, status_code}, success: message, error: null}
        }
        case TIMER_UPDATE: {
            const {data, status_code, message} = action.payload;
            return {...state, detail:{...state.detail, ...data, message, status_code}, success: message, error: null}
        }
        case TIMER_DELETE: {
            const {data, status_code, message} = action.payload;
            return {...state, default:{...state.detail, ...data, message, status_code}, success: message, delete: true, error: null}
        }
        case TIMER_ERROR: {
            return {...state, ...action.payload, success: null}
        }
        default: return state;
    }
}

export default reducer;