import React, {Component} from 'react';
import { Row, Col, Card } from 'reactstrap';
import SizeCard from './sellRequestContainer/SizeCard';
import ImageListCard from './sellRequestContainer/ImageListCard';
import DetailCard from './sellRequestContainer/DetailCard';
import propTypes from 'prop-types';
import SellRequestLogs from './sellRequestContainer/SellRequestLogs';

export default class SellRequestContainer extends Component{
  render(){
    return(
      <Card body className="tab-content--custom__card">
        <Row>
          <Col xs={12} md={6}>
            <DetailCard
              brand={this.props.brand}
              categoryListing={this.props.categoryListing}
              isViewLogsOpen={this.props.isViewLogsModalOpen}
              onViewLogsToggle={this.props.onViewLogsToggle}
              isLoading={this.props.isLoading}
              {...this.props.sellRequest}
              user={this.props.user}
              rackDetail={this.props.rackDetail}
              userSetting={this.props.userSetting}
              attachRack={this.props.attachRack}
              detachRack={this.props.detachRack}
              dataSellRequest={this.props.dataSellRequest}
              onUpdateSellRequest={this.props.handleUpdateSellRequest}
              onForceHolidayMode={this.props.handleForceHolidayMode}
              onDeleteSellRequest={this.props.handleDeleteSellRequest}
              productVariant={this.props.dataSellRequest.detail && this.props.dataSellRequest.detail.product_variant}
            />
          </Col>
          <Col xs={12} md={6}>
            <SizeCard
              isLoading={this.props.isLoading}
              userSellId={this.props.id}
              size={this.props.size}
            />
          </Col>
          <Col xs={12}>
            <ImageListCard
              userSellId={this.props.sellRequest.id}
              images={this.props.images}
              draftImages={this.props.draftImages}
              isUploadImageLoading={this.props.isUploadImageLoading}
              isUploadImageCollapse={this.props.isUploadImageCollapse}
              onChangeImage={this.props.handleChangeImage}
              onRemoveImage={this.props.handleRemoveImage}
              onToggleUploadImageCollapse={this.props.handleToggleUploadImageCollapse}
              onDropMultipleFiles={this.props.handleDropMultipleFiles}
              onDeleteDraftImage={this.props.handleDeleteDraftImage}
              onStartUploadClick={this.props.handleStartUploadClick}
              isConsignment={this.props?.sellRequest?.consignment}
            />
          </Col>
          <Col xs={12}>
            <SellRequestLogs
              id={this.props.sellRequestId}
              type={"user_sell"}
              refresh={this.props.isLoading}
            />
          </Col>
        </Row>
      </Card>
    )
  }
}

SellRequestContainer.propTypes = {
  isLoading: propTypes.bool,
  user: propTypes.object,
  userSetting: propTypes.object,
  dataSellRequest: propTypes.shape({
    detail: propTypes.object,
    isSubmit: propTypes.bool,
    isFetch: propTypes.bool,
    error: propTypes.object
  }),
  sellRequest: propTypes.object,
  size: propTypes.object,
  images: propTypes.arrayOf(propTypes.object),
  draftImages: propTypes.arrayOf(propTypes.object),
  isUploadImageLoading: propTypes.bool,
  isUploadImageCollapse: propTypes.bool,
  handleDeleteSellRequest: propTypes.func.isRequired,
  handleUpdateSellRequest: propTypes.func.isRequired,
  handleForceHolidayMode: propTypes.func.isRequired,
  handleToggleUploadImageCollapse: propTypes.func,
  handleDropMultipleFiles: propTypes.func,
  handleDeleteDraftImage: propTypes.func,
  handleStartUploadClick: propTypes.func,
  handleChangeImage: propTypes.func,
  handleRemoveImage: propTypes.func
}

SellRequestContainer.defaultProps = {
  isLoading: false,
  size: {
    id: 0,
    US: '-'
  },
  images: []
}