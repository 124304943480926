import React from 'react';
import ItemList from '../ItemList'
import { connect } from 'react-redux';
import classNames from 'classnames';
import {
  Alert,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  FormGroup,
  Col,
  Row,
  Label,
  ListGroup,
  ListGroupItem,
} from 'reactstrap';
import { getRaffleTiers } from '../../../../actions/raffleAction';
import { FontAwesomeIcon } from '../../../../components/Icons';
import { addErrorMessage } from '../../../../utils/form';
import { ValidationMessage } from '../../../../components/Form/ValidationMessage';
import { Radio, RadioText, FormGroupRadio } from '../../../../components/Form/Radios';

const defaultParams = {
  per_page: 15,
  page: 1,
  exclude: true
}

const defaultPaginations = {
  from: 0,
  to: 0,
  total: 0,
  currentPage: 0,
  lastPage: 0,
  perPage: 0
}

class ModalRaffleTiers extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      _params: {
        ...defaultParams
      },
      paginations: {
        from: 0,
        to: 0,
        total: 0,
        currentPage: 0,
        lastPage: 0,
        perPage: 0
      },
      list: [],
      listSelected: [],
      _error: null,
      _alertErrorOpen: false,
      hasSearchKeyword: false,
      fetchWhenMount: true,
      selectType: 'one' // one or multiple
    }
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount(){
    if(this.state.fetchWhenMount){
      this._getDataList(this.state._params)
      this.setState({hasSearchKeyword: true})
    }
  }

  componentDidUpdate(prevProps){
    if(this.props.isOpen && this.props.isOpen !== prevProps.isOpen){
      this.setState({
        _params: {
          ...this.state._params,
          ...defaultParams
        },
        paginations:{
          ...defaultPaginations
        },
        list: [],
        listSelected: []
      }, () => {
        if(this.state.hasSearchKeyword){
          this._getDataList(this.state._params)
        }
      })
    }
    if(!this.props.dataRaffleTiers.isFetch && prevProps.dataRaffleTiers.isFetch !== this.props.dataRaffleTiers.isFetch){
      const { data, ...paginations } = this.props.dataRaffleTiers.data;
      if(!this.props.dataRaffleTiers.error && this.props.dataRaffleTiers.data){
        this.setState({
          list: [...data],
          paginations: {
            ...this.state.paginations,
            from: paginations.from,
            to: paginations.to,
            total: paginations.total,
            currentPage: paginations.current_page,
            lastPage: paginations.last_page,
            perPage: paginations.per_page
          }
        })
      }
    }
    if(!this.props.dataRaffleTiers.isSubmit && prevProps.dataRaffleTiers.isSubmit !== this.props.dataRaffleTiers.isSubmit){
      const { data, error } = this.props.dataRaffleTiers;
      if(!error && data && this.props.isOpen)
        this.props.toggle();
      else{
        if(error)
          this.setState({
            _alertErrorOpen: true,
            _errorMessage: this.translateMessage(error.status_code, error.message)
          })
      }
    }
  }

  _sanitizeParams = rawParams => {
    return {
      params: Object.keys(rawParams)
      .filter(key => rawParams[key]!='')
      .reduce((obj, current)=> ({...obj, [current]: rawParams[current]}), [])
    }
  }

  _getDataList = (rawParams = {}) => {
    const { pointShopId } = this.props;
    this.props.getRaffleTiers(
      pointShopId,
      this._sanitizeParams(rawParams)
    )
  }

  _handleGoto = page => {
    const _params = this.state._params;
    _params.page = page;
    this.setState({ _params },
      ()=> this._getDataList(this.state._params)
    );
  }


  translateMessage = (statusCode, message) => {
    let messageStr = ''
    switch(statusCode){
      case 200: messageStr = 'Success...'; break;
      case 422: messageStr = 'Please fullfill your form.'; break;
      case 404: messageStr = 'Resource not found.'; break;
      case 500: messageStr = "We've got something errors"; break;
      default: messageStr = message; break;
    }
    return messageStr
  }

  _validateForm = () => {
    const { listSelected } = this.state;
    let errors={};
    if(listSelected.length>1 && this.state.selectType === 'one'){
      errors['tiers'] = addErrorMessage(errors, 'tiers', 'Can attach 1 tiers only.');
    }
    const errorPoints = listSelected.reduce(function(prev ,item){
      if(!item.points){
        const next = {
          ...prev,
          [`item_${item.id}`]: 'The points field is required.'
        }
        return next
      }
      return prev;
    }, {});
    errors = {...errors, ...errorPoints}
    let errorState = null;
    if(Object.keys(errors).length){
      // const message = errors.tiers ? errors.tiers : 'Please full fill form';
      const message = 'Please full fill form';
      errorState = {
        errors: { ...errors },
        status_code: 422,
        message
      }
      this.setState({
        _error: errorState? {...errorState}: null,
        _errorMessage: errorState? errorState.message: null,
        _alertErrorOpen: true,
        _errorMessage: message
      })
    }
    return Object.keys(errors).length>0;
  }

  _handleOnSubmit = () => {
    const { onSubmit } = this.props;
    const { listSelected } = this.state;
    if(!this._validateForm()){
      if(listSelected.length){
        onSubmit(listSelected);
      }
      this._clearSelected();
      // toggle();
    }
  }

  _clearSelected = () => {
    this.setState({
      listSelected: [],
      _error:null,
      _alertErrorOpen:false,
      _errorMessage:''
    });
  }

  handleChange(e, item){
    const { listSelected } = this.state;
    const elementsIndex = listSelected.findIndex(element => element.id === item.id)
    let newArray = [...listSelected];
    newArray[elementsIndex] = {...newArray[elementsIndex], points: e.target.value}
    this.setState({listSelected:newArray});
  }

  render(){
    return(
      <Modal isOpen={this.props.isOpen} toggle={this.props.toggle} size={this.props.size}>
        <ModalBody>
          <Form
            style={{marginBottom: '.5rem'}}
            onSubmit={e => {
              e.preventDefault()
              this.setState({hasSearchKeyword: true})
              this._getDataList(this.state._params);
            }}
          >
            <InputGroup>
              <Input
                bsSize="sm"
                placeholder="Search tier name..."
                value={this.state._params.keyword||''}
                name="keyword"
                onChange={e => {
                  this.setState({
                    _params:{
                      ...this.state._params,
                      [e.target.name]: e.target.value
                    }
                  })
                }}
              />
              <InputGroupAddon addonType="append">
                <Button color="primary">Search</Button>
              </InputGroupAddon>
            </InputGroup>
          </Form>
          <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: '1rem', alignItems: 'center'}}>
            <div>
              {this.state.listSelected.length > 0?(
                <p style={{margin: 0, fontSize: '.5rem'}} className="text-muted">Selected ({this.state.listSelected.length}) item(s).</p>
              ):null}
            </div>
            <div>
              {
                this.state.listSelected.length > 0?(
                  <Button
                    outline
                    color="danger"
                    size="sm"
                    type="button"
                    className="mr-1"
                    style={{fontSize: '.5rem'}}
                    onClick={this._clearSelected}
                  >
                    Clear Selected
                  </Button>
                ): null
              }
              <Button
                outline
                title="Refresh list"
                color="primary"
                style={{fontSize: '.5rem'}}
                size="sm"
                type="button"
                onClick={() => {
                  this.setState({hasSearchKeyword: true})
                  this._handleGoto(1)
                }}
              >
                <FontAwesomeIcon iconType="refresh"/>
              </Button>
            </div>
          </div>
          <ItemList
            isLoading={this.props.dataRaffleTiers.isFetch}
            paginations={this.state.paginations}
            list={this.state.list}
            renderItems={(item, index) => {
              const { listSelected, _error } = this.state;
              const errorPoints =  _error && _error.errors;
              const checked = listSelected.length>0&&listSelected.some(list => list.id === item.id);
              const selected = listSelected.find(list => list.id === item.id);
              const errorPointField = selected && errorPoints ? errorPoints[`item_${selected.id}`]: null;
              const points = selected ? selected.points:'';
              return(
                <div key={index} style={{paddingTop: '.75rem', maxHeight: 250, overflow: 'auto'}}>
                  <ListGroup>
                    <ListGroupItem>
                      <Row
                        className={classNames({ 'has-danger has-feedback': checked && errorPointField})}
                      >
                        <Col xs={12} md={6}>
                          <FormGroupRadio
                            style={{marginLeft: '2px'}}
                            className={classNames('mb-0',{ 'has-danger has-feedback': errorPoints && errorPointField})}
                          >
                            <Radio
                              wrapperClassName="switch-primary"
                              radioType="3d"
                              size="xs"
                              value={true}
                              checked={checked}
                              usingLabelText={false}
                              onChange={({target: { checked: value }}) => {
                                const { listSelected } = this.state;
                                let myList = [];
                                if(value){
                                  const obj = {id : item.id, tier_label: item.tier_label, points:'', is_active:true}
                                  if(this.state.selectType === 'one'){
                                    myList = [obj]
                                  } else{
                                    myList = [...listSelected, obj];
                                  }
                                } else{
                                  myList = listSelected.filter(list => list.id !== item.id)
                                }
                                this.setState({
                                  listSelected: [...myList ]
                                })
                              }}
                            />
                            <RadioText text={item.tier_label.toUpperCase()}/>
                          </FormGroupRadio>
                        </Col>
                        {
                          selected ?
                          <Col xs={12} md={6}>
                            <FormGroup
                              className={classNames('mb-0',{ 'has-danger has-feedback': errorPoints && errorPointField})}
                            >
                              <Label for="formRafflePoints">
                                <Input
                                  type="number"
                                  id="formRafflePoints"
                                  name="points"
                                  placeholder="Enter a points"
                                  value={points}
                                  onChange={(e) => this.handleChange(e, item)}
                                />
                              </Label>
                              {
                                errorPointField ?
                                <ValidationMessage message={errorPointField}/>
                                :
                                null
                              }
                            </FormGroup>
                          </Col>
                          :
                          null
                        }
                      </Row>
                    </ListGroupItem>
                  </ListGroup>
              </div>
            )}}
            onGotoPage={this._handleGoto}
          />
        </ModalBody>
        <Col xs={12}>
          <Alert color="danger" isOpen={this.state._alertErrorOpen} toggle={() => this.setState({_alertErrorOpen: false})}>
            <strong>Opps. </strong> {this.state._errorMessage}
          </Alert>
        </Col>
        <ModalFooter>
          <Button color="secondary" onClick={this.props.toggle}>Cancel</Button>
          <Button color="primary" onClick={this._handleOnSubmit}>Save</Button>{' '}
        </ModalFooter>
      </Modal>
    )
  }
}

const mapStateToProps= ({ raffle: { raffleTiers } }) => ({
  dataRaffleTiers: {
    isSubmit: raffleTiers.isSubmit,
    message: raffleTiers.message,
    status_code: raffleTiers.status_code,
    isFetch: raffleTiers.isSearchFetch,
    data: raffleTiers.dataSearch,
    error: raffleTiers.error
  }
});
const mapDispatchToProps = (dispatch) => ({
  getRaffleTiers: (id, query) => dispatch(getRaffleTiers(id, query)),
});
export const ModalRaffleTier = connect(mapStateToProps,mapDispatchToProps)(ModalRaffleTiers);