import React, { PureComponent as Component } from 'react';
import { Card, CardText, Row, Col } from 'reactstrap';
import propTypes from 'prop-types';
import Loading from 'react-loading-animation';
import { Tbl, TblHead, TblBody } from '../../../components/Tbl';
import { TextRupiah } from '../../../components/Text';
import DateToHuman from '../../../components/DateToHuman';
import styled from 'styled-components';

const dataHeader=['No','Before Amount','Amount','Type','Updated'];

const HeaderDiv = styled.div`
  display: flex;
  flex-direction: column;
`;

const TopDiv = styled.div`
  display: flex;
  margin-bottom: 1rem;
  justify-content: space-between;
`;

export default class TabDisbursement extends Component{
  onRenderItems(){
    return this.props.data.map((item,index)=>
      (
        <tr key={index}>
          <th scope="row">{index+1}</th>
          <td><TextRupiah prefix="Rp. " value={item.before_disbursement}/></td>
          <td><TextRupiah prefix="Rp. " value={item.amount}/></td>
          <td>{item.type}</td>
          <td><DateToHuman value={item.updated_at} id={`tooltip_tabDisbursement_updatedAt_${index}`}/></td>
        </tr>
      )
    )
  }
  render(){
    const { balance } = this.props.totalBalance;
    return(
      <Card body className="tab-content--custom_card">
        <Card body>
          <HeaderDiv>
            <TopDiv>
              <p>About their user balance journal informations</p>
              <p>Total Balance <strong><TextRupiah prefix="Rp. " value={balance}/></strong></p>              
            </TopDiv>
          </HeaderDiv>
          <div className="table-responsive">
            <Tbl>
              <TblHead dataHeader={dataHeader}/>
              <TblBody
                isLoad={this.props.isLoading}
                hasRow={this.props.data.length>0}
                columnCount={dataHeader.length}
              >
                {this.onRenderItems()}
              </TblBody>
            </Tbl>
          </div>
        </Card>
      </Card>
    )
  }
}
TabDisbursement.propTypes={
  data: propTypes.array,
  totalBalance: propTypes.object,
  isLoading: propTypes.bool
}
TabDisbursement.defaultProps={
  data:[],
  totalBalance:{},
  isLoading: false
}