import React from 'react';
import { connect } from 'react-redux';
import { updateProductVariant } from '../../../actions/productVariantAction';
import { Link } from 'react-router-dom';
import propTypes from 'prop-types';
import { Button } from 'reactstrap';
import DateToHuman from '../../../components/DateToHuman';
import { textValue, checkChange } from '../../../utils/form';
import SummariesColumn from './rowItem/SummariesColumn';
import BrandColumn from './rowItem/BrandColumn';
import { Radio } from '../../../components/Form/Radios';
import { SimpleLineIcon } from '../../../components/Icons';

class RowItem extends React.Component{
  constructor(props){
    super(props);
    this.state={
      editorsChoice: props.editorsChoice,
      _editorsChoiceEdit: props.editorsChoice,
      editorsPosition: props.editorsPosition,
      autoSync: props.autoSync,
      _autoSyncEdit: props.autoSync,
      updatedAt: props.updatedAt
    }
    // console.log('constructor RowItem')
    this._handleCheckedChange = this._handleCheckedChange.bind(this)
  }

  componentDidUpdate(prevProps) {
    if(prevProps.productVariant.isSubmit !== this.props.productVariant.isSubmit && prevProps.productVariant.isSubmit){
      if(prevProps.productVariant.error===null){
        const { id, editors_choice, editors_position, updated_at, auto_sync } = this.props.productVariant.detail;
        if(prevProps.id===id){
          const { editorsChoice } = this.state;
          const editChoiceChanged = editorsChoice !== editors_choice
          this.setState({
            editorsChoice: editors_choice,
            _editorsChoiceEdit: editors_choice,
            editorsPosition: editors_position,
            autoSync: auto_sync,
            _autoSyncEdit: auto_sync,
            updatedAt: updated_at
          }, ()=>{
            if(!editChoiceChanged) return;
            const { category: { name } } = this.props;
            if(this.state.editorsChoice){
              this.props.onEditorsChoiceChange(name.toLowerCase(), 'INC');
            }
            else{
              this.props.onEditorsChoiceChange(name.toLowerCase(), 'DEC');
            }
          })
        }
      }
      else{
        const { id } = this.props.productVariant.error;
        if(id && this.props.id === id){
          this.setState({
            _editorsChoiceEdit: this.state.editorsChoice,
          })
        }
      }
    }
  }

  _getProductVariantPayload = () => {
    const {
      product_id,
      display_name,
      nickname,
      type,
      dimension,
      colour,
      details,
      weight,
      sex,
      SKU,
      release_date,
      expiry_date,
      active,
      price_source,
      retail_price,
      currency,
      editors_choice,
      auto_sync,
      hide_box_info,
      vintage,
      receive_sell,
      biddable,
      editors_position
    } = this.props.variantData;
    const payloads = {
      editors_choice: this.state._editorsChoiceEdit,
      auto_sync: this.state._autoSyncEdit,
      editors_position: this.state.editorsPosition
    }
    return {
      product_id,
      display_name,
      nickname,
      type,
      dimension,
      colour,
      details,
      weight,
      sex,
      SKU,
      release_date,
      expiry_date,
      active,
      price_source,
      retail_price,
      currency,
      editors_choice,
      auto_sync,
      hide_box_info,
      vintage,
      receive_sell,
      biddable,
      editors_position,
      ...payloads
    }
  }

  _handleCheckedChange(e){
    let doChange = false;
    if(e.target.name === '_editorsChoiceEdit'){
      if(e.target.checked===true){
        const { category, findEditorChoices } = this.props;
        const total = findEditorChoices(category.name, 'total');
        const max = findEditorChoices(category.name, 'threshold');
        // console.log(editorsChoiceTotal>maxEditorsChoice)
        // const { editorsChoiceTotal, maxEditorsChoice } = this.props;
        if(total + 1> max){
          this.props.onToggleModalInfo(category.name.toLowerCase());
        }
        else
          doChange = !doChange;
      }
      else{
        doChange = !doChange;
      }
    } else{
      doChange = !doChange
    }
    if(doChange){
      this.setState({
        ...checkChange(e, this.state)
      }, ()=>{
        const myPayloads = this._getProductVariantPayload()
        this.props.updateVariant(this.props.id, myPayloads)
      })
    }
  }

  shouldComponentUpdate(nextProps){
    if(nextProps.productVariant.isSubmit){
      return false;
    }
    if(!nextProps.productVariant.isSubmit&&nextProps.productVariant.isSubmit!==this.props.productVariant.isSubmit){
      if(nextProps.productVariant.error){
        const { error: { id } } = nextProps.productVariant;
        if(id && id != this.props.id){
          return false;
        }
      }
      else{
        if(nextProps.productVariant.detail.id && nextProps.productVariant.detail.id != this.props.id){
          return false;
        }
      }
    }
    return true;
  }

  render(){
    // console.log('Rerender RowItem')
    return(
      <tr>
        <th scope="row" className="v-center">{this.props.number}</th>
        <td className="v-center">
          <BrandColumn
            id={this.props.id}
            brand={this.props.brand}
          />
        </td>
        <td className="v-center">
          <SummariesColumn
            id={this.props.id}
            displayName={this.props.displayName}
            SKU={this.props.SKU}
            colour={this.props.colour}
            active={this.props.active}
            vintage={this.props.vintage}
            editorsChoice={this.state._editorsChoiceEdit}
            categoryName={this.props.category.name}
          />
        </td>
        <td className="v-center">
          <div className="d-flex align-items-center">
            {this.state.editorsPosition?
              <span className="mr-2 mb-2">#{this.state.editorsPosition}</span>
            :null}
            <Radio value={true} name="_editorsChoiceEdit" onChange={this._handleCheckedChange} checked={textValue('_editorsChoiceEdit', this.state)}/>
          </div>
          {this.state._editorsChoiceEdit? (
            <div>
              <Button
                color="link"
                onClick={() =>
                  this.props.onToggleModalPosition({
                    id: this.props.id,
                    displayName: this.props.displayName,
                    position: this.state.editorsPosition,
                    payloads: this._getProductVariantPayload()
                  })
                }
                style={{fontSize: '.65rem', textDecoration: 'underline'}}
              >
                Change Position
              </Button>
            </div>
          ):null}
          <div style={{fontSize: '.75rem'}}>
            <p className="mb-0">Auto Scrape</p>
            <Radio value={true} name="_autoSyncEdit" size="xs" onChange={this._handleCheckedChange} checked={textValue('_autoSyncEdit', this.state)}/>
          </div>
        </td>
        <td className="v-center">
          <DateToHuman value={this.state.updatedAt} id={`tooltip_productVariant_updatedAt_item_${this.props.number}`}/>
        </td>
        <td className="v-center">
          <Link to={`${this.props.defaultRoute}/${this.props.id}`} className="btn btn-secondary btn-sm btn-link"> Details</Link>
          <div className="w-100"></div>
          <Button
            size="sm"
            type="button"
            onClick={() => this.props.onToggleModalScrape({id: this.props.id, displayName: this.props.displayName, priceSource: this.props.variantData.price_source})}
          >
            <SimpleLineIcon iconType="refresh"/> Scrape Now
          </Button>
        </td>
      </tr>
    )
  }
}
const mapStateToProps = ({ productVariant }) => ({
  productVariant
})

const mapDispatchToProps = (dispatch) => ({
  updateVariant: (id, payload) => dispatch(updateProductVariant(id, payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(RowItem);

RowItem.propTypes = {
  id: propTypes.number.isRequired,
  number: propTypes.number.isRequired,
  brand: propTypes.object,
  displayName: propTypes.string,
  SKU: propTypes.string,
  colour: propTypes.string,
  active: propTypes.oneOfType([propTypes.number, propTypes.bool]),
  editorsChoice: propTypes.oneOfType([propTypes.number, propTypes.bool]),
  updatedAt: propTypes.string,
  variantData: propTypes.object.isRequired,
  defaultRoute: propTypes.string.isRequired,
  // editorsChoiceTotal: propTypes.number,
  // maxEditorsChoice: propTypes.number,
  onEditorsChoiceChange: propTypes.func,
  findEditorChoices: propTypes.func

}
RowItem.defaultProps = {
  brand:{
    flat: []
  },
  active: false,
  editorsChoice: false,
  editorsChoiceTotal: 0,
  maxEditorsChoice: 0
}