import React from 'react';
import { connect } from 'react-redux'
import { Link } from 'react-router-dom';
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  ButtonGroup,
  Alert,
  Button,
  CardText
} from 'reactstrap';
import moment from 'moment-timezone';
import classNames from 'classnames';
import { getMarketingBudgetById, saveMarketingBudget, deleteMarketingBudgetById } from '../../actions/marketingBudgetAction';
import { FontAwesomeIcon } from '../../components/Icons';
import BadgeThin from '../../components/Badges/BadgeThin';
import { ButtonLoading } from '../../components/Button';
import { addErrorMessage, errorMessage, hasError ,textValue } from '../../utils/form';
import { ModalConfirmation } from '../../components/Modals';
import InputDatetime from '../../components/Form/InputDatetime/InputDatetime';
import { isRequired, isNumeric } from '../../helpers/regex';

const MODAL_CONFIRM_ACCEPT_ACTION = 'MODAL_CONFIRM_ACCEPT_ACTION';
const MODAL_CONFIRM_REJECT_ACTION = 'MODAL_CONFIRM_REJECT_ACTION';
const MODAL_CONFIRM_DELETE_ACTION = 'MODAL_CONFIRM_DELETE_ACTION';

class MarketingBudgetForm extends React.Component{
  constructor(props){
    super(props)
    this.state = {
      _hasId: this.props.match.params.id?true:false,
      _formSubmit: false,
      _form:{
        id: 0,
        name: '',
        description: '',
        amount: 0,
        status: 'draft',
        approved_by: null
      },
      _modalConfirmAction: MODAL_CONFIRM_DELETE_ACTION,
      _isModalConfirmOpen: false,
      _modalConfirmBody: 'Are you sure?',
      _errorForm: null,
      _alertErrorOpen: false,
      _alertSuccessOpen: false,
      _errorMessage: '',
      _successMessage: '',
    }
    moment.tz('Asia/Jakarta');
  }

  componentDidMount(){
    if(this.props.match.params.id){
      const { id } = this.props.match.params;
      this.props.getMarketingBudgetById(id)
    }
  }

  componentDidUpdate(prevProps){
    if(prevProps.dataMarketingBudget.isFetch !== this.props.dataMarketingBudget.isFetch && !this.props.dataMarketingBudget.isFetch){
      const { error } = this.props.dataMarketingBudget
      if(error){
        if(error.status_code === 404)
          this.props.history.replace('/404')
        this.setState({
          _errorForm: {...error},
          _alertErrorOpen: true,
          _alertSuccessOpen: false,
          _errorMessage: this.translateMessage(error.status_code, error.message)
        })
      }
      else{
        const dataApi = this._bindDataFromApi();
        this.setState({
          _form:{
            ...this.state._form,
            ...dataApi
          },
          _errorForm: null,
          _alertErrorOpen: false,
          _formSubmit: false
        })
      }
    }

    if(prevProps.dataMarketingBudget.isSubmit !== this.props.dataMarketingBudget.isSubmit && !this.props.dataMarketingBudget.isSubmit){
      const { data, error, status_code, message } = this.props.dataMarketingBudget
      if(error){
        if(error.status_code === 404)
          this.props.history.replace('/404')
        this.setState({
          _errorForm: {...error},
          _formSubmit: false,
          _alertErrorOpen: true,
          _alertSuccessOpen: false,
          _errorMessage: this.translateMessage(error.status_code, error.message)
        })
      }
      else{
        const { deleted } = this.props.dataMarketingBudget;
        if(deleted){
          this.props.history.replace(`/marketing_budgets`)
        }
        const { id } = this.props.match.params;
        if(!id){
          this.props.history.replace(`/marketing_budgets/${data.id}`)
        }
        const dataApi = this._bindDataFromApi();
        this.setState({
          _form:{
            ...this.state._form,
            ...dataApi
          },
          _isModalConfirmOpen: false,
          _errorForm: null,
          _alertErrorOpen: false,
          _formSubmit: false,
          _alertSuccessOpen: true,
          _successMessage: this.translateMessage(status_code, message),
        })
      }
    }
  }

  _bindDataFromApi = () => {
    const { data: dataApi } = this.props.dataMarketingBudget;
    const dateParsed = moment(dataApi.scheduled_at);
    const data = {
      id: dataApi.id,
      name: dataApi.name,
      description: dataApi.description || '',
      amount: Number(dataApi.amount),
      scheduled_at: dateParsed.isValid()? dateParsed: '',
      status: dataApi.status,
      user_id: dataApi.user_id,
      user: dataApi.user,
      approved_by: dataApi.approved_by,
      created_at: dataApi.created_at,
      updated_at: dataApi.updated_at
    };
    return {...data}
  }

  translateMessage = (statusCode, message) => {
    let messageStr = ''
    switch(statusCode){
      case 200: messageStr = 'Success...'; break;
      case 422: messageStr = 'Please fullfill your form.'; break;
      case 404: messageStr = 'Resource not found.'; break;
      case 400: messageStr = 'Cannot update the data because already processed.'; break;
      case 500: messageStr = "We've got something errors"; break;
      default: messageStr = message; break;
    }
    return messageStr
  }

  _handleInputChange = ({ target: { name, value, checked, type } }) => {
    this.setState({
      _form:{
        ...this.state._form,
        [name]: type === 'checkbox'? checked: value
      }
    })
  }

  _hasInputError = name => {
    const { _errorForm } = this.state;
    return _errorForm && _errorForm.errors && hasError(name, _errorForm)
  }

  _renderErrorMessage = name => {
    const { _errorForm } = this.state;
    return errorMessage(name, _errorForm)
  }

  _validateForm = () => {
    const { _form: dataForm } = this.state;
    let errors={};
    if(!isRequired(dataForm.name)){
      errors['name'] = addErrorMessage(errors, 'name', 'The name field is required.');
    }
    if(!isRequired(dataForm.amount)){
      errors['amount'] = addErrorMessage(errors, 'amount', 'The amount field is required.');
    }
    if(!isNumeric(dataForm.amount)){
      errors['amount'] = addErrorMessage(errors, 'amount', 'The amount field must be numeric.');
    }
    if(!moment.isMoment(dataForm.scheduled_at) && dataForm.scheduled_at !== ''){
      errors['scheduled_at'] = addErrorMessage(errors, 'scheduled_at', 'Invalid date.');
    }
    let errorState = null;
    if(Object.keys(errors).length){
      errorState = {
        errors: { ...errors },
        status_code: 422,
        message: 'Please full fill form'
      }
      this.setState({
        _errorForm: errorState? {...errorState}: null,
        _errorMessage: errorState? errorState.message: null,
        _alertErrorOpen: true,
        _alertSuccessOpen: false,
        _errorMessage: 'Please full fill form'
      })
    }
    // console.log("Error: ", errors);
    return Object.keys(errors).length>0;
  }

  _submitDataForm = (status = null) => {
    const { _formSubmit, _form } = this.state;
    if(!_formSubmit){
      const payloads = {
        name: _form.name,
        description: _form.description !== ''? _form.description: null,
        amount: _form.amount,
        scheduled_at: _form.scheduled_at === ''? null:(
          moment.isMoment(_form.scheduled_at)? _form.scheduled_at.format("YYYY-MM-DD HH:mm:00"):
          _form.scheduled_at),
        active: _form.active,
      }
      if(_form.id){
        payloads.id = _form.id;
        payloads.status = status? status: _form.status;
      }
      this.setState({
        _formSubmit: true
      }, () => {
        this.props.saveMarketingBudget(payloads)
      })
    }
  }

  _handleSubmitForm = async(e) => {
    e.preventDefault();
    const invalid = await this._validateForm()
    if(invalid) return;
    this._submitDataForm();
  }

  _handleDatePickerChange = (date, name) => {
    this.setState({
      _form:{
        ...this.state._form,
        [name]: date
      }
    })
  }

  _handleAdjustedDate = (e, fieldName, type) => {
    e.preventDefault();
    const { _form } = this.state;
    const dateValue = _form[fieldName];
    if(dateValue && dateValue !== '' && moment.isMoment(dateValue)){
      this.setState({
        _form:{
          ..._form,
          [fieldName]: type === 'start'? moment(dateValue).startOf('day'): moment(dateValue).endOf('day')
        }
      })
    }
  }

  _handleModalConfirmOnSubmit = e => {
    e.preventDefault();
    const { _modalConfirmAction } = this.state;
    switch(_modalConfirmAction){
      case MODAL_CONFIRM_DELETE_ACTION : this.props.deleteMarketingBudgetById(this.state._form.id); break;
      case MODAL_CONFIRM_ACCEPT_ACTION : this._submitDataForm('approved'); break;
      case MODAL_CONFIRM_REJECT_ACTION : this._submitDataForm('rejected'); break;
      default: break;
    }
  }

  _handleDeleteBtnClick = e => {
    e.preventDefault();
    this.setState({
      _isModalConfirmOpen: true,
      _modalConfirmAction: MODAL_CONFIRM_DELETE_ACTION,
      _modalConfirmBody: `Are you sure for delete this?`
    })
  }

  _handleAcceptBtnClick = e => {
    e.preventDefault();
    this.setState({
      _isModalConfirmOpen: true,
      _modalConfirmAction: MODAL_CONFIRM_ACCEPT_ACTION,
      _modalConfirmBody: `Are you sure for approve this (${this.state._form.name})?`
    })
  }

  _handleRejectBtnClick = e => {
    e.preventDefault();
    this.setState({
      _isModalConfirmOpen: true,
      _modalConfirmAction: MODAL_CONFIRM_REJECT_ACTION,
      _modalConfirmBody: `Are you sure for reject this (${this.state._form.name})?`
    })
  }

  _handleToggleModalConfirmation = () => {
    this.setState({
      _isModalConfirmOpen: !this.state._isModalConfirmOpen
    })
  }

  render(){
    return(
      <Row>
        <ModalConfirmation
          isOpen={this.state._isModalConfirmOpen}
          toggle={this._handleToggleModalConfirmation}
          modalBody={this.state._modalConfirmBody}
          onSubmit={this._handleModalConfirmOnSubmit}
        />
        <Col xs={12} sm={6} md={6}>
          <Card>
            <CardHeader>
              <FontAwesomeIcon iconType="align-justify"/> {this.props.title}
              {this.props.edit?
                <div>
                  <Link style={{fontSize: '.65rem'}} to="/marketing_budgets/create">Create new Marketing Budget</Link>
                </div>
              :null}
            </CardHeader>
            <CardBody>
              {/* {this._renderLoading()} */}
              <Row>
                <Col xs={12}>
                  <Alert color="danger" isOpen={this.state._alertErrorOpen} toggle={() => this.setState({_alertErrorOpen: false})}>
                    <strong>Oh Snap!</strong> { this.state._errorMessage }
                  </Alert>
                </Col>
                <Col xs={12}>
                  <Alert color="success" isOpen={this.state._alertSuccessOpen} toggle={() => this.setState({_alertSuccessOpen: false})}>
                    <strong>Done!</strong> { this.state._successMessage }
                  </Alert>
                </Col>
              </Row>
              {this.state._form.id? (
                <div>
                  <BadgeThin color="primary">#{this.state._form.id}</BadgeThin>
                </div>
              ): null}
              <Form onSubmit={this._handleSubmitForm}>
                <FormGroup
                  className={classNames({ 'has-danger has-feedback': this._hasInputError('name') })}
                >
                  <Label>Name</Label>
                  <Input
                    placeholder="Enter a name"
                    name="name"
                    type="text"
                    value={textValue('name', this.state._form)}
                    onChange={this._handleInputChange}
                  />
                  { this._renderErrorMessage('name') }
                </FormGroup>
                <FormGroup
                  className={classNames({ 'has-danger has-feedback': this._hasInputError('description') })}
                >
                  <Label>Description - optional</Label>
                  <Input
                    placeholder="Enter a description"
                    name="description"
                    type="textarea"
                    value={textValue('description', this.state._form)}
                    onChange={this._handleInputChange}
                  />
                  { this._renderErrorMessage('description') }
                </FormGroup>
                <Row>
                  <Col xs={12} sm={6}>
                    <FormGroup
                      className={classNames({ 'has-danger has-feedback': this._hasInputError('amount') })}
                    >
                      <Label>Amount</Label>
                      <Input
                        className="text-right"
                        placeholder="Enter an amount"
                        name="amount"
                        type="text"
                        value={textValue('amount', this.state._form)}
                        onChange={this._handleInputChange}
                      />
                      { this._renderErrorMessage('amount') }
                    </FormGroup>
                  </Col>
                  <Col xs={12} md={6}>
                    <FormGroup
                      className={classNames({ 'has-danger has-feedback': this._hasInputError('scheduled_at') })}
                    >
                      <Label>Budget Date</Label>
                      <InputDatetime
                        inputProps={{placeholder:'Pick a Budget Date', name: "scheduled_at", autoComplete: "off", readOnly: this.state.isReadOnly}}
                        value={textValue('scheduled_at', this.state._form)}
                        onChange={ date => this._handleDatePickerChange(date, 'scheduled_at') }
                        timeFormat="HH:mm"
                      />
                      <p className="mb-0">
                        <a href="#" onClick={(e) => this._handleAdjustedDate(e, 'scheduled_at', 'start')} style={{fontSize: '.75rem', textDecoration: 'underline'}} className="mr-3">start of day</a>
                        <a href="#" onClick={(e) => this._handleAdjustedDate(e, 'scheduled_at', 'end')} style={{fontSize: '.75rem', textDecoration: 'underline'}} className="mr-3">end of day</a>
                      </p>
                      { this._renderErrorMessage('scheduled_at') }
                    </FormGroup>
                  </Col>
                </Row>
                {
                  this.state._hasId?(
                    <Row>
                      <Col xs={12} md={6}>
                        <FormGroup>
                          <Label>Created</Label>
                          <Input
                            readOnly
                            name="created_at"
                            type="text"
                            value={textValue('created_at', this.state._form)}
                          />
                        </FormGroup>
                      </Col>
                      <Col xs={12} md={6}>
                        <FormGroup>
                          <Label>Updated</Label>
                          <Input
                            readOnly
                            name="updated_at"
                            type="text"
                            value={textValue('updated_at', this.state._form)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  ):null
                }
                <FormGroup row>
                  <Col>
                    <ButtonGroup>
                      <ButtonLoading
                        disabled={this.state._formSubmit}
                        isLoading={this.state._formSubmit}
                        loadingMessage="Submitting..."
                        color="primary"
                      >
                        Submit
                      </ButtonLoading>
                      {
                        this.state._hasId?(
                          <ButtonLoading
                            type="button"
                            loadingMessage="Deleting..."
                            onClick={this._handleDeleteBtnClick}
                            color="danger"
                          >
                            Delete
                          </ButtonLoading>
                        )
                        :null
                      }
                    </ButtonGroup>
                  </Col>
                </FormGroup>
              </Form>
            {this.state._hasId? (
              <div>
                <hr/>
                <Row>
                  <Col style={{display: 'flex', alignItems: 'center'}}>
                    <CardText>
                      <strong>Approval action:</strong>
                    </CardText>
                  </Col>
                  <Col style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                    {this.props.dataMarketingBudget.data.status? (
                      <div style={{display: 'flex', flexDirection: 'column'}}>
                        <div style={{alignSelf: 'flex-end'}}>
                          <BadgeThin color={this.props.dataMarketingBudget.data.status === 'approved'? 'success': this.props.dataMarketingBudget.data.status === 'rejected'? 'danger':'secondary'} className="text-uppercase text-right">{this.props.dataMarketingBudget.data.status}</BadgeThin>
                        </div>
                        {this.props.dataMarketingBudget.data.status === 'draft'?(
                          <ButtonGroup>
                            <Button size="sm" onClick={this._handleAcceptBtnClick} color="success" type="button">Approve</Button>
                            <Button size="sm" onClick={this._handleRejectBtnClick} color="danger" type="button">Reject</Button>
                          </ButtonGroup>
                        ):null}
                      </div>
                    ): null}
                  </Col>
                </Row>
              </div>
            ): null}
            </CardBody>
          </Card>
        </Col>
      </Row>
    )
  }
}

const mapStateToProps=({ marketingBudget: { details: dataMarketingBudget } })=>({
  dataMarketingBudget
})
const mapDispatchToProps=(dispatch)=>({
  deleteMarketingBudgetById: id => dispatch(deleteMarketingBudgetById(id)),
  saveMarketingBudget: (payloads) => dispatch(saveMarketingBudget(payloads)),
  getMarketingBudgetById: id => dispatch(getMarketingBudgetById(id)),
})

export default connect(mapStateToProps, mapDispatchToProps)(MarketingBudgetForm)