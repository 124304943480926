import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import {
  Card,
  CardHeader,
  CardBody,
  CardText,
  Button,
  Col,
  Row
} from 'reactstrap';
import { getArticleVariants, saveArticleVariants, updatePosition } from '../../../actions/articleAction';
import { getOptionList as getCategoryOptionList } from '../../../actions/categoriesAction';
import { FontAwesomeIcon, SimpleLineIcon } from '../../../components/Icons';
import { ModalProductVariant, ModalProductVariantOrder } from './Modals';
import ItemList, { ProductVariantItem } from './ItemList'
import { ButtonXs } from '../../../components/Button';
import privateView from '../../../components/hocs/privateView';

const defaultParams = {
  per_page: 15,
  page: 1,
  category_id: null
}

class ProductVariantList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      _params: {
        ...defaultParams
      },
      paginations: {
        from: 0,
        to: 0,
        total: 0,
        currentPage: 0,
        lastPage: 0,
        perPage: 0
      },
      list: [],
      listSelected: [],
      error: null,
      message: '',
      statusCode: null,
      isBrowseProductsOpen: false,
      isModalOrderPosition: false,
    }
  }

  componentDidMount() {
    const { _params } = this.state;
    this._getDataList(_params)
  }

  _sanitizeParams = rawParams => {
    return {
      params: Object.keys(rawParams)
        .filter(key => rawParams[key] != '')
        .reduce((obj, current) => ({ ...obj, [current]: rawParams[current] }), [])
    }
  }

  _getDataList = (rawParams = {}) => {
    const { id } = this.props;
    this.props.getArticleVariants(
      id,
      this._sanitizeParams(rawParams)
    )
  }

  _resetDataList = () => {
    this.setState({
      _params: {
        ...defaultParams
      }
    }, () => this._getDataList(this.state._params))
  }

  _handleGoto = page => {
    const _params = this.state._params;
    _params.page = page;
    this.setState({ _params },
      () => this._getDataList(this.state._params)
    );
  }

  _handleBrowseProductToggle = () => {
    this.setState({
      isBrowseProductsOpen: !this.state.isBrowseProductsOpen
    })
  }

  componentDidUpdate(prevProps) {
    if (!this.props.dataArticleVariants.isFetch && prevProps.dataArticleVariants.isFetch !== this.props.dataArticleVariants.isFetch) {
      const { data, ...paginations } = this.props.dataArticleVariants.data;
      const { _params } = this.state;
      if (!_params.category_id) {
        let category = 1;
        if (data.length) {
          category = data[0].product.category_id;
        }
        this.setState({
          _params: {
            ..._params,
            category_id: category
          }
        }, () => {
          if (data.length) this._handleGoto(1)
        });
      }

      this.setState({
        list: [...data],
        paginations: {
          ...this.state.paginations,
          from: paginations.from,
          to: paginations.to,
          total: paginations.total,
          currentPage: paginations.current_page,
          lastPage: paginations.last_page,
          perPage: paginations.per_page
        }
      })
    }

    if (!this.props.dataArticleVariants.isSubmit && prevProps.dataArticleVariants.isSubmit !== this.props.dataArticleVariants.isSubmit) {
      const { error, message, status_code } = this.props.dataArticleVariants;
      if (error) {
        this.setState({
          error: { ...error },
        })
      }
      else {
        this.setState({
          error: null,
          message,
          statusCode: status_code,
          listSelected: []
        })
        if (status_code === 201)
          this._resetDataList();
      }
    }
  }

  _handleDetachAllItems = e => {
    e.preventDefault();
    const { listSelected } = this.state;
    const { id } = this.props;
    const payloads = {
      product_variants: listSelected.length !== 0 ?
        listSelected.map(i => i) : ['*']
    }
    this.props.saveArticleVariants('detach', id, payloads);
  }

  _handleAttachItems = itemIds => {
    const { id } = this.props;
    const payloads = {
      product_variants: itemIds.map(i => i)
    }
    this.props.saveArticleVariants('attach', id, payloads);
  }

  _handleOpenBrowseModal = e => {
    e.preventDefault();
    this._handleBrowseProductToggle()
  }

  _handleOpenModalOrder = () => {
    this._getDataList({per_page: 99999})

    setTimeout(() => {
      this.setState({ isModalOrderPosition: true })
    }, [1500])
  }
  _handleCloseModalOrder = () => {
    this.setState({ isModalOrderPosition: false }, () => {
      this._handleGoto(1)
    })
  }

  _getAllPositions = () => {
    if (!this.state?.list?.length) return []

    return this.state.list.map(l => l.pivot.position)
  }

  _updatePosition = ({ id = [], pivot, currentIdx }, reset) => {

    this.props.updatePosition(pivot?.article_id, { id, position: +currentIdx + 1 }).then(res => {
      if (reset) {
        this._handleGoto(1)
      }
    })
  }

  render() {
    return (
      <div>
        <ModalProductVariant
          size="md"
          articleId={this.props.id}
          isOpen={this.state.isBrowseProductsOpen}
          toggle={this._handleBrowseProductToggle}
          dataCategories={this.props.dataCategories}
          onSubmit={this._handleAttachItems}
        />
        <Card>
          <CardHeader>
            <Row>
              <Col><FontAwesomeIcon iconType="align-justify" /> Product Variants</Col>
              <Col className="d-flex justify-content-end">
                <a
                  style={{ color: "#2ca9d6", textDecoration: "underline", cursor: 'pointer' }}
                  onClick={this._handleOpenModalOrder}>Preview</a>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <CardText>Add / remove product variants.</CardText>
            <div className="mb-1" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <div>
                <a
                  href="#"
                  className="link text-primary mr-1"
                  onClick={this._handleOpenBrowseModal}
                >
                  <SimpleLineIcon iconType="plus" className="mr-1" />
                  Browse
                </a>
                <a
                  href="#"
                  className="link text-danger mr-2"
                  onClick={this._handleDetachAllItems}
                >
                  <SimpleLineIcon iconType="trash" className="mr-1" />
                  <span>
                    Remove {this.state.listSelected.length ? `(${this.state.listSelected.length})` : 'All'}
                  </span>
                </a>
              </div>
              <div>
                {
                  this.state.listSelected.length > 0 ? (
                    <Button
                      outline
                      color="danger"
                      size="sm"
                      type="button"
                      className="mr-1"
                      style={{ fontSize: '.5rem' }}
                      onClick={() => this.setState({ listSelected: [] })}
                    >
                      Clear Selected
                    </Button>
                  ) : null
                }
                <Button
                  outline
                  title="Refresh list"
                  color="primary"
                  style={{ fontSize: '.5rem' }}
                  size="sm"
                  type="button"
                  onClick={() => this._handleGoto(1)}
                >
                  <FontAwesomeIcon iconType="refresh" />
                </Button>
              </div>
            </div>
            <div className="mb-1">
              {this.props.dataCategories.map((item, key) => (
                <ButtonXs
                  color="gray"
                  key={key}
                  className={classNames({ "active": this.state._params.category_id == item.value })}
                  onClick={() => {
                    this.setState({
                      _params: {
                        ...this.state._params,
                        category_id: item.value,
                        page: 1
                      }
                    }, () => {
                      this._getDataList(this.state._params)
                    })
                  }}
                >
                  {item.label}
                </ButtonXs>
              ))}
            </div>
            <ItemList
              isLoading={this.props.dataArticleVariants.isFetch || this.props.dataArticleVariants.isSubmit}
              paginations={this.state.paginations}
              list={this.state.list}
              renderItems={item => (
                <ProductVariantItem
                  key={item.id}
                  data={item}
                  useSequence={true}
                  checked={this.state.listSelected.includes(item.id)}
                  list={this.state.list}
                  updatePosition={(data) => this._updatePosition(data, true)}
                  onSelectedChange={({ target: { checked: value } }) => {
                    const { listSelected } = this.state;
                    this.setState({
                      listSelected: value ? [...listSelected, item.id] : listSelected.filter(id => id !== item.id)
                    })
                  }}
                />
              )}
              onGotoPage={this._handleGoto}
              onSubmit={this._handleAttachItems}
            />
          </CardBody>
        </Card>

        {this.state.isModalOrderPosition ? <ModalProductVariantOrder
          onClose={this._handleCloseModalOrder}
          isOpen={this.state.isModalOrderPosition}
          data={this.state.list}
          updatePosition={(data) => this._updatePosition(data)}
        /> : null}
      </div>
    )
  }
}


const mapStateToProps = ({ auth: { isFetch, roles, isLogged, id, email }, article: { articleVariants }, masterCategories }) => ({
  guardData: { isFetch, roles, isLogged, id, email },
  dataCategories: masterCategories.options,
  dataArticleVariants: articleVariants
});
const mapDispatchToProps = (dispatch) => ({
  getArticleVariants: (id, query) => dispatch(getArticleVariants(id, query)),
  saveArticleVariants: (operation, id, payload) => dispatch(saveArticleVariants(operation, id, payload)),
  getCategoryOptionList: () => dispatch(getCategoryOptionList()),
  updatePosition: (id, payload) => dispatch(updatePosition(id, payload)),
});
const enhance = connect(mapStateToProps, mapDispatchToProps)

export default enhance(privateView(ProductVariantList, ['blog_admin']));