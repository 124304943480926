import React from 'react';
import { Link } from 'react-router-dom';
import propTypes from 'prop-types';
import { Button } from 'reactstrap';
import {
  SeekDiv,
  SeekHeader,
  SeekTitle,
  ButtonWrapper
} from '../styled';
import SeekItemSizeList from './SeekItemSizeList';
import SeekProductDetail from './SeekProductDetail';

const SeekItem = props =>{
  const retailPrice = !props.seek_product_variants.length? 0 : props.seek_product_variants.filter(item => item.quantity > 0)
    .map(({ price }) => price)
    .sort()[0]
  return (
    <SeekDiv>
      <SeekHeader>
        <SeekTitle>
          <div>#{props.id}</div>
          <p><span>SKU - </span><a href="#" title="More Details..." onClick={e => { e.preventDefault(); props.onOpenModal(props.id)}}>{props.name}</a></p>
        </SeekTitle>
        <ButtonWrapper>
          {
            props.product_variant_id && props.product_variant?(
              <div>
                <Button
                  size="sm"
                  disabled={props.disabledSyncBtn}
                  onClick={()=> props.onSyncClick(props.id)}
                >
                  <i className="fa fa-refresh"></i> Sync
                </Button>
                <Link className="btn btn-sm btn-primary" to={`/stock/${props.product_variant_id}`} target="_blank">
                  <i className="icon-drawer"></i> Check Stock
                </Link>
              </div>
            ):(
              <div>
                <Button
                  size="sm"
                  disabled={props.disabledSyncBtn}
                  onClick={()=> props.onSyncClick(props.id)}
                >
                  <i className="fa fa-refresh"></i> Sync
                </Button>
                <Link className="btn btn-sm btn-primary" to={`/product_variants/create?sku=${props.name}&retailPrice=${retailPrice}`} target="_blank">
                  <i className="fa fa-plus"></i> Create
                </Link>
              </div>
            )
          }
          <p>
            Last synced: {props.synced_at}
          </p>
        </ButtonWrapper>
      </SeekHeader>
      {
        props.product_variant_id && props.product_variant?(
          <SeekProductDetail
            id={props.product_variant_id}
            displayName={props.product_variant.display_name}
            SKU={props.product_variant.SKU}
            colour={props.product_variant.colour}
            images={props.product_variant.product_variant_images}
          />
        )
        :null
      }
      <SeekItemSizeList items={props.seek_product_variants}/>
    </SeekDiv>
  )
}

export default SeekItem;

SeekItem.propTypes = {
  id: propTypes.number.isRequired,
  seek_product_variants: propTypes.arrayOf(propTypes.object)
}

SeekItem.defaultProps = {
  disabledSyncBtn: false
}