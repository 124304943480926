import React from 'react';
import propTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import DateToHuman from '../../../../components/DateToHuman';
import { TextRupiah } from '../../../../components/Text';
import SmallInline from '../../../../styled/Typograph/Small/SmallInline';
import { updateSale, doMarkedSale, getSaleNotes, setReadSaleNotes, getDetailSale2, getTrackingShipmentDetail } from '../../../../actions/saleAction';
import { textValue, checkChange } from '../../../../utils/form';
import OrderSummariesColumn from '../../RowItem/OrderSummariesColumn';
import { getPaymentType, getPaymentVia } from '../../../../constants/kickavenue';
import { ModalMarkedSale, ModalTrackingShipment } from '../../../../components/Modals';
import ButtonStarred from '../../RowItem/ButtonStarred';
import { FontAwesomeIcon } from '../../../../components/Icons';
import { determineAutoCancelSwitchValue } from '../../../../helpers/misc';

class RowItem extends React.Component{
  constructor(props){
    super(props);
    this.state={
      checkedItems:{},
      autoDisburse: props.autoDisburse,
      _autoDisburseEdit: props.autoDisburse,
      autoCancel: props.autoCancel?false:true,
      _autoCancelEdit: determineAutoCancelSwitchValue(props.autoCancel, props.turnOnAutoCancelEnabled),
      updatedAt: props.updatedAt,
      _isModalMarked: false,
      marked: props.marked,
      _markedEdit: props.marked,
      // markedNotes: props.markedNotes || '',
      markedAt: props.markedAt || '',
      markedBy: props.markedBy || '',
      closeMarkedAt: props.closeMarkedAt || '',
      closeMarkedBy: props.closeMarkedBy || '',
      notes: props.notes || [],
      notesPage: 1,
      noteNextPage: null,
      unreadNoteCount: props.unreadNoteCount || 0,
      isConfirmation: false,
      purchasePrice: props.purchasePrice,
      _isModalTracking: false,
      shippingUpdate: null
    }
    this._handleAutoCancelChange = this._handleAutoCancelChange.bind(this);
    this._handleCheckedChange = this._handleCheckedChange.bind(this);
    this._handleCheckboxChange = this._handleCheckboxChange.bind(this);
    this._handleMarkedChange = this._handleMarkedChange.bind(this);
    this._handleTrackingChange = this._handleTrackingChange.bind(this);
    this._handleModalMarkedOnSubmit = this._handleModalMarkedOnSubmit.bind(this);
  }
  componentDidUpdate(prevProps, prevState) {
    if(prevProps.allSelected !== this.props.allSelected){
      if(this.props.allSelected){
        const selectedItem = this.props.selectedItems.filter(item => item.id === this.props.id).length>0;
        this.setState((prevState) => ({
          checkedItems: {
            ...prevState.checkedItems,
            [this.props.id]: selectedItem,
          },
        }));
      } else{
        this.setState({checkedItems:{}});
      }
    }
    if(prevProps.sale.isSubmit !== this.props.sale.isSubmit && prevProps.sale.isSubmit){
      if(prevProps.sale.error===null){
        const { id, autodisburse, turn_on_auto_cancel_enabled, auto_cancelled_at, updated_at, marked, open_marked_at, open_marked_by, sale_notes, unread_notes_count, close_marked_at, close_marked_by, purchase_price } = this.props.sale.detail;
        if(prevProps.id===id){
          let autoCancelEdit=determineAutoCancelSwitchValue(auto_cancelled_at, turn_on_auto_cancel_enabled);
          this.setState({
            autoDisburse: autodisburse,
            _autoDisburseEdit: autodisburse,
            autoCancel:autoCancelEdit,
            _autoCancelEdit:autoCancelEdit,
            marked: marked,
            _markedEdit: marked,
            // markedNotes: marked_notes || '',
            markedAt: open_marked_at || '',
            markedBy: open_marked_by || '',
            closeMarkedAt: close_marked_at || '',
            closeMarkedBy: close_marked_by || '',
            notes: sale_notes,
            unreadNoteCount: unread_notes_count,
            updatedAt: updated_at,
            purchasePrice: purchase_price
          })
        }
      }
      else{
        const { id } = this.props.sale.error;
        if(id && this.props.id === id){
          this.setState({
            _autoDisburseEdit: this.state.autoDisburse,
            _autoCancelEdit:this.state.autoCancel,
            _markedEdit: this.state.marked,
          })
        }
      }
    }
    if(prevProps.sale.notes.isFetch && !this.props.sale.notes.isFetch){
      const { list: { data, next_page_url, error }, saleId } = this.props.sale.notes;
      if(this.props.id === saleId){
        if(error) {
          alert('Error while fetching notes');
        } else{
          const { loginId } = this.props;
          const ids = data.filter(item => !item.read && item.user_id !== loginId).map(({ id }) => id);
          if(ids.length){
            this.props.setReadSaleNotes(this.props.id, { ids })
          }
          this.setState({
            notes: this.state.notesPage === 1? [...data]: [...data, ...this.state.notes],
            noteNextPage: next_page_url? this.state.notesPage + 1: null
          })
        }
      }
    }

    if(prevProps.sale.saleDetail.isFetch && !this.props.sale.saleDetail.isFetch){
      const { error, data } = this.props.sale.saleDetail;
      if(!error && data.id === this.props.id){
        const { unread_notes_count } = data;
        this.setState({
          unreadNoteCount: unread_notes_count
        });
      }
    }

    if(prevProps.sale.notes.isSubmit && !this.props.sale.notes.isSubmit){
      const { readNotes: { data }, saleId, error } = this.props.sale.notes;
      if(this.props.id === saleId && !error){
        if(data.total > 0) {
          this.props.getDetailSale2(this.props.id);
        }
      }
    }
    if(!prevState._isModalMarked && this.state._isModalMarked){
      this.setState({
        notesPage: 1,
        noteNextPage: null
      }, () => {
        this.props.getSaleNotes(this.props.id, {
          params: { per_page: 3, page: this.state.notesPage }
        });
      })
    }

    if (!prevState._isModalTracking && this.state._isModalTracking) {
      this.props.getTracking(this.props.id)
    }

    if (prevProps.sale.shippingUpdate.isFetch && !this.props.sale.shippingUpdate.isFetch)
    {
      const { data, error } = this.props.sale.shippingUpdate;
      if (!error) {
        this.setState({
          shippingUpdate: data
        })
      }
    }

  }

  shouldComponentUpdate(nextProps){
    if(nextProps.sale.isSubmit){
      return false;
    }
    if(!nextProps.sale.isSubmit&&nextProps.sale.isSubmit!==this.props.sale.isSubmit){
      if(nextProps.sale.error){
        const { error: { id } } = nextProps.sale;
        if(id && id != this.props.id){
          return false;
        }
      }
      else{
        if(nextProps.sale.detail.id && nextProps.sale.detail.id != this.props.id){
          return false;
        }
      }
    }
    return true;
  }

  _handleModalMarkedOnSubmit({ marked_notes, marked = null }){
    if(marked === false){
      const payload = { marked };
      if(marked_notes){
        payload['marked_notes'] = marked_notes;
      }
      this.setState({
        _markedEdit : marked,
        _isModalMarked: false,
        // isConfirmation: false
      });
      this.props.doMarkedSale(this.props.id, payload)
      return;
    }
    this.setState({
      _markedEdit : this.state.closeMarkedAt? (marked !== null? marked: this.state._markedEdit): true,
      _isModalMarked: false,
      // isConfirmation: false,
      markedNotes: marked_notes
    }, () => {
      const payload = {
        // status : param.status,
        marked : this.state._markedEdit,
        marked_notes
      }
      this.props.doMarkedSale(this.props.id, payload)
    })
  }

  _handleMarkedChange(){
    this.setState({
      // isConfirmation: false,
      _isModalMarked: !this.state._isModalMarked,
      _markedEdit : this.state.marked //cancel toggle
    });
  }

  _handleTrackingChange(){
    this.setState({
      _isModalTracking: !this.state._isModalTracking,
    });
  }

  _handleCheckedChange(e) {
    this.setState({
      ...checkChange(e, this.state)
    }, ()=>{
      const payload = {
        autodisburse : this.state._autoDisburseEdit,
      }
      this.props.update(this.props.id, payload)
    });
  }

  _handleCheckboxChange(event){
    const { name, checked } = event.target;
    this.setState((prevState) => ({
      checkedItems: {
        ...prevState.checkedItems,
        [name]: checked,
      },
    }), ()=>{
      const payload = {
        ...this.props.data,
        selected: checked
      };
      this.props.printSelectedItems(payload)
    });
  }

  _handleAutoCancelChange(e) {
    const turnOnAutoCancelEnabled = this.props?.turnOnAutoCancelEnabled;
    if (!turnOnAutoCancelEnabled) {
      return;
    }

    this.setState({
      ...checkChange(e, this.state)
    }, ()=>{
      const payload = {
        auto_cancelled_at : this.state._autoCancelEdit,
      }
      this.props.update(this.props.id, payload)
    });
  }

  _openPurchasePriceModal = e => {
    e.preventDefault();
    this.props.openPurchasePriceModal({
      id: this.props.id,
      invoiceNumber: this.props.invoiceNumber,
      purchasePrice: this.state.purchasePrice
    })
  }

  render() {
    const {
      id,
      rowId,
      data,
      bank,
      quantity,
      buyerEmail,
      invoiceNumber,
      totalAmount,
      orderDate,
      orderStatus,
      // status,
      displayName,
      SKU,
      size,
      colour,
      preOrder,
      preVerified,
      consignmentId,
      updatedAt,
      sneakersCondition,
      category,
      paymentVia,
      paymentMethod,
      rack,
      followUpStatus,
      voucherCode,
      legitCheckLogs,
      userSellId
      // markedAt,
      // markedNotes
    } = this.props;
    const via = getPaymentVia(paymentVia);
    return (
      <tr>
        <ModalMarkedSale
          isOpen={this.state._isModalMarked}
          isConfirmation={this.state.isConfirmation}
          toggle={this._handleMarkedChange}
          invoiceNumber={invoiceNumber}
          marked={this.state.marked}
          markedAt={this.state.markedAt}
          markedBy={this.state.markedBy}
          closeMarkedAt={this.state.closeMarkedAt}
          markedNotes={this.state.markedNotes}
          notes={this.state.notes}
          unreadNoteCount={this.state.unreadNoteCount}
          hasNextPage={this.state.noteNextPage}
          loginId={this.props.loginId}
          onSubmit={this._handleModalMarkedOnSubmit}
          onLoadMoreNotes={() => {
            const { notesPage, noteNextPage } = this.state;
            if(!noteNextPage) return;
            this.setState({
              noteNextPage: null,
              notesPage: notesPage + 1
            }, () => {
              this.props.getSaleNotes(this.props.id, {
                params: { per_page: 3, page: this.state.notesPage }
              });
            })
          }}
        />
        <ModalTrackingShipment
          shippingUpdate={this.state.shippingUpdate}
          isLoading={this.props.sale.shippingUpdate.isFetch}
          isOpen={this.state._isModalTracking}
          toggle={this._handleTrackingChange}
        />
        <th className="v-center" scope="row">
          { 
            !this.props.isOpenPrint?
            rowId
            :
            <input
              className="print-order-checkbox"
              name={id}
              type="checkbox"
              checked={this.state.checkedItems[id]||false}
              onChange={this._handleCheckboxChange}
            />
          }
        </th>
        <td className="v-center">
          <OrderSummariesColumn
            id={id}
            quantity={quantity}
            invoiceNumber={invoiceNumber}
            orderDate={orderDate}
            buyerEmail={buyerEmail}
            displayName={displayName}
            size={size}
            SKU={SKU}
            colour={colour}
            preOrder={preOrder}
            preVerified={preVerified}
            consignmentId={consignmentId}
            sneakersCondition={sneakersCondition}
            category={category}
            unreadNoteCount={this.state.unreadNoteCount}
            legitCheckLogs={legitCheckLogs}
            // marked={this.state._markedEdit}
            // onMarkToggle={value => {
            //   if(!value){
            //     this.setState({
            //       _markedEdit: value
            //     })
            //     this.props.doMarkedSale(this.props.id, { marked: value })

            //   }else {
            //     this.setState({
            //       _isModalMarked: true,
            //       _markedEdit: value
            //     })
            //   }
            // }}
          />
          <div>
            <p className="mb-0" style={{fontSize: '.75rem'}}>
              Purchase price: Rp. { this.state.purchasePrice? <TextRupiah tag="span" prefix="" value={this.state.purchasePrice}/>: '-' }
              <a
                href="#"
                className="ml-2"
                style={{textDecoration: 'underline', color: '#4a4a4a', fontSize: '.75rem'}}
                onClick={this._openPurchasePriceModal}
              >
                <i className="fa fa-pencil"></i> edit
              </a>
            </p>
          </div>
        </td>
        <td className="v-center">
          <span className="text-uppercase rack-span" style={{fontSize: '.75rem'}}>{userSellId}</span>
        </td>
        <td className="v-center">
          <span className="text-uppercase rack-span" style={{fontSize: '.75rem'}}>{ rack ? rack : '-' }</span>
        </td>
        <td className="v-center">
          <div>
            <TextRupiah prefix="Rp. " bold value={totalAmount}/>
          </div>
          {
            voucherCode && (
              <div className="text-uppercase voucher-wrapper" style={{fontSize: '.5rem'}}>
                <FontAwesomeIcon iconType="ticket"/> {voucherCode}
              </div>
            )
          }
          <div className="issued-wrapper" style={{minWidth: 95}}>
            <a
              href="#"
              className="mr-1"
              style={{textDecoration: 'underline', color: '#4a4a4a', fontSize: '.75rem'}}
              onClick={e => {
                e.preventDefault();
                this.setState({ _isModalMarked: true, isConfirmation: false })
              }}
            >
              <i className="fa fa-pencil"></i> {this.state.markedAt? 'view': 'add'} notes
            </a>
            <ButtonStarred
              // title={this.state._markedEdit? "Remove mark!": "Mark!"}
              title="Toggle issued"
              value={this.state._markedEdit}
              onClick={() => {
                const { _markedEdit } = this.state;
                const value = !_markedEdit;
                this.setState({
                  _isModalMarked: true,
                  isConfirmation: !value,
                  // _markedEdit: value
                })
              }}
            />
          </div>
          <div>
            <SmallInline>Auto Disburse</SmallInline><br/>
            <label className="switch switch-text switch-success-outline-alt">
              <input type="checkbox"
                className="switch-input"
                name="_autoDisburseEdit"
                id="formSaleAutoDisburse"
                value="true"
                checked={textValue('_autoDisburseEdit', this.state)}
                onChange={this._handleCheckedChange}
              />
              <span className="switch-label" data-on="YES" data-off="NO"></span>
              <span className="switch-handle"></span>
            </label>
          </div>
        </td>
        <td className="v-center">
          <div>
            {
              bank && paymentMethod === 'virtual_account'?
              <span style={{fontSize: '.75rem'}}>{bank+ '-'+ getPaymentType(paymentMethod)}</span>
              :
              getPaymentType(paymentMethod)
            }
          </div>
          { via !== null? <div><span style={{fontSize: '.75rem'}}>via <i>{via}</i></span></div>: null}
          {
            paymentVia !== null && paymentVia === 'vt' && paymentMethod=== "credit_card" ?
            <button className="btn btn-sm btn-secondary" onClick={this.props.onOpenCreditCard} target="_blank" title="Show More Info"><i className="fa fa-info-circle"></i> More Info</button>
            :
            null
          }
          {
            <div><span style={{fontSize: '.75rem'}}>{data.offer.payment_id?data.offer.payment_id:data.offer.id}</span></div>
          }
        </td>
        <td className="v-center">
          <div>{orderStatus}</div>
          <a 
            href="#"
            className="mr-1"
            style={{textDecoration: 'underline', color: '#4a4a4a', fontSize: '.75rem'}}
            onClick={e => {
              e.preventDefault()
              this.setState({
                _isModalTracking: true,
                shippingUpdate: null
              })
            }}
          >Tracking Shipment</a>
          {followUpStatus === 'PENDING'? (
            <div> -
              <Link
                style={{fontSize: '.75rem', fontStyle: 'italic', color: '#4a4a4a'}}
                to={`/sales/follow-up?activeTab=followUpApproval&keyword=${invoiceNumber}`}
                target="_blank"
                className="ml-1"
                title="Detail"
              >
                <i className="fa fa-exclamation-triangle"></i> Pending Follow Up
              </Link>
            </div>
          ):null}
        </td>
        <td className="v-center">
          <DateToHuman  value={updatedAt} id={`tooltip_${rowId}`}/>
          <div>
            <SmallInline>Auto Cancel</SmallInline><br/>
            <label className="switch switch-text switch-success-outline-alt">
              <input type="checkbox"
                className="switch-input"
                name="_autoCancelEdit"
                id="formSaleAutoCancel"
                value="true"
                checked={textValue('_autoCancelEdit', this.state)}
                onChange={this._handleAutoCancelChange}
                disabled={!this.props?.turnOnAutoCancelEnabled}
              />
              <span className="switch-label" data-on="YES" data-off="NO"></span>
              <span className="switch-handle"></span>
            </label>
          </div>
        </td>
        <td className="v-center">
          <div>
            <Link to={`/sales/${id}`} className="btn btn-sm btn-secondary" target="_blank" title="Detail"><i className="fa fa-external-link"></i> Details</Link>
          </div>
          <div>
            {/* <SmallInline>Marked Sale</SmallInline><br />
            <label className="switch switch-text switch-success-outline-alt">
              <input type="checkbox"
                className="switch-input"
                name="_markedEdit"
                id="formSaleMarked"
                value="true"
                checked={textValue('_markedEdit', this.state)}
                onChange={this._handleMarkedChange}
              />
              <span className="switch-label" data-on="YES" data-off="NO"></span>
              <span className="switch-handle"></span>
            </label> */}
          </div>
        </td>
      </tr>
    )
  }
}

const mapStateToProps = ({ sale }) => ({
  sale
})

const mapDispatchToProps = (dispatch) => ({
  getDetailSale2: (id) => dispatch(getDetailSale2(id)),
  getSaleNotes: (id, query) => dispatch(getSaleNotes(id, query)),
  setReadSaleNotes: (id, body) => dispatch(setReadSaleNotes(id, body)),
  update: (id, payload) => dispatch(updateSale(id, payload)),
  doMarkedSale: (id, payload) => dispatch(doMarkedSale(id, payload)),
  getTracking: id => dispatch(getTrackingShipmentDetail(id))
})

export default connect (mapStateToProps, mapDispatchToProps) (RowItem);

RowItem.defaultProps = {
  totalAmount: 0,
  marked: false,
}

RowItem.propTypes={
  rowId: propTypes.number.isRequired,
  id: propTypes.number.isRequired,
  buyerEmail: propTypes.string,
  autoDisburse: propTypes.number,
  marked: propTypes.bool,
  totalAmount: propTypes.oneOfType([propTypes.number,propTypes.string]),
  orderDate: propTypes.string,
  orderStatus: propTypes.string,
  status: propTypes.string,
  invoiceNumber: propTypes.string,
  size: propTypes.string,
  displayName: propTypes.string,
  SKU: propTypes.string,
  colour: propTypes.string,
  preOrder: propTypes.oneOfType([propTypes.number,propTypes.string,propTypes.bool]),
  preVerified: propTypes.oneOfType([propTypes.bool,propTypes.number]),
  consignmentId: propTypes.string,
  updatedAt: propTypes.string,
  onOpenCreditCard: propTypes.func.isRequired,
  markedNotes: propTypes.string,
  markedAt: propTypes.string,
  rack: propTypes.string,
  userSellId: propTypes.number
}