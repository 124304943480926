import {
  AUTH_TOKEN_KEY,
  USER_ROUTE_API,
  USER_BANK_ACCOUNT_LIST_FETCH,
  USER_BANK_ACCOUNT_LIST_FETCH_ERROR,
  USER_BANK_ACCOUNT_LIST_DONE,
  USER_BANK_ACCOUNT_DETAIL_FETCH,
  USER_BANK_ACCOUNT_DETAIL_FETCH_ERROR,
  USER_BANK_ACCOUNT_DETAIL_SUBMIT,
  USER_BANK_ACCOUNT_DETAIL_SUBMIT_ERROR,
  USER_BANK_ACCOUNT_DETAIL_DONE,
  USER_BANK_ACCOUNT_DETAIL_DELETED,
  USER_BANK_ACCOUNT_DETAIL_UPDATED,
} from '../constants';
import api from '../utils/Api';
import { manipulateErrors, checkAuthorized } from '../utils';
import { logout } from './authAction';

const onSuccess = (type, payload) => ({
  type,
  payload
})

const onError=(type, err)=>{    
	if(!checkAuthorized(manipulateErrors(err)))
    return logout();
  return {
    type,
    payload:manipulateErrors(err)
  }
}

export const getUserBankAccountList = (userId, query)=>(
  dispatch => (
    dispatch({
      type: USER_BANK_ACCOUNT_LIST_FETCH,
      payload: true
    }),
    api({
      Authorization:'Bearer '+localStorage.getItem(AUTH_TOKEN_KEY)
    }).get(`${USER_ROUTE_API}/${userId}/bank_accounts`, query)
    .then(res => {
      dispatch(onSuccess(USER_BANK_ACCOUNT_LIST_DONE, res.data));
      dispatch({
        type: USER_BANK_ACCOUNT_LIST_FETCH,
        payload: false
      });
    })
    .catch(err=>{
      dispatch(onError(USER_BANK_ACCOUNT_LIST_DONE, err));
      dispatch({
        type: USER_BANK_ACCOUNT_LIST_FETCH,
        payload: false
      });
    })
  )
)


export const getDetailUserBankAccount = (userId, id) => (
  dispatch => (
    dispatch({
      type: USER_BANK_ACCOUNT_DETAIL_FETCH,
      payload: true
    }),
    api({
      Authorization:'Bearer '+localStorage.getItem(AUTH_TOKEN_KEY)
    }).get(`${USER_ROUTE_API}/${userId}/bank_accounts/${id}`)
    .then(res => {
      dispatch(onSuccess(USER_BANK_ACCOUNT_DETAIL_DONE, res.data));
      dispatch({
        type: USER_BANK_ACCOUNT_DETAIL_FETCH,
        payload: false
      });
    })
    .catch(err=>{
      dispatch(onError(USER_BANK_ACCOUNT_LIST_DONE, err));
      dispatch({
        type: USER_BANK_ACCOUNT_DETAIL_FETCH,
        payload: false
      });
    })
  )
)

export const saveUserBankAccount = (userId, { id, ...payload }) => {
  return dispatch => {    
    if(id){
      return dispatch(updateUserBankAccount(userId, id, payload))
    }
    return dispatch(createUserBankAccount(userId, payload))
  }
}

export const createUserBankAccount = (userId, payload) => (
  dispatch => (
    dispatch({
      type: USER_BANK_ACCOUNT_DETAIL_SUBMIT,
      payload: true
    }),
    api({
      Authorization:'Bearer '+localStorage.getItem(AUTH_TOKEN_KEY)
    }).post(`${USER_ROUTE_API}/${userId}/bank_accounts`, payload)
    .then(res => {
      dispatch(onSuccess(USER_BANK_ACCOUNT_DETAIL_DONE, res.data));
      dispatch({
        type: USER_BANK_ACCOUNT_DETAIL_SUBMIT,
        payload: false
      });
    })
    .catch(err=>{
      dispatch(onError(USER_BANK_ACCOUNT_DETAIL_SUBMIT_ERROR, err));
      dispatch({
        type: USER_BANK_ACCOUNT_DETAIL_SUBMIT,
        payload: false
      });
    })
  )
)

export const updateUserBankAccount = (userId, id, payload) => (
  dispatch => (
    dispatch({
      type: USER_BANK_ACCOUNT_DETAIL_SUBMIT,
      payload: true
    }),
    api({
      Authorization:'Bearer '+localStorage.getItem(AUTH_TOKEN_KEY)
    }).put(`${USER_ROUTE_API}/${userId}/bank_accounts/${id}`, payload)
    .then(res => {
      dispatch(onSuccess(USER_BANK_ACCOUNT_DETAIL_DONE, res.data));
      dispatch({
        type: USER_BANK_ACCOUNT_DETAIL_SUBMIT,
        payload: false
      });
    })
    .catch(err=>{
      dispatch(onError(USER_BANK_ACCOUNT_DETAIL_SUBMIT_ERROR, err));
      dispatch({
        type: USER_BANK_ACCOUNT_DETAIL_SUBMIT,
        payload: false
      });
    })
  )
)

export const deleteUserBankAccount = (userId, id) => (
  dispatch => (
    dispatch({
      type: USER_BANK_ACCOUNT_DETAIL_SUBMIT,
      payload: true
    }),
    api({
      Authorization:'Bearer '+localStorage.getItem(AUTH_TOKEN_KEY)
    }).delete(`${USER_ROUTE_API}/${userId}/bank_accounts/${id}`)
    .then(res => {
      dispatch(onSuccess(USER_BANK_ACCOUNT_DETAIL_DELETED, {...res.data, id,  deleted: true}));
      dispatch({
        type: USER_BANK_ACCOUNT_DETAIL_SUBMIT,
        payload: false
      });
    })
    .catch(err=>{
      dispatch(onError(USER_BANK_ACCOUNT_DETAIL_SUBMIT_ERROR, err));
      dispatch({
        type: USER_BANK_ACCOUNT_DETAIL_SUBMIT,
        payload: false
      });
    })
  )
)