import styled from 'styled-components';
import { CardBody } from 'reactstrap';

export const SeekDiv = styled.div`
  padding: 0.5rem 1rem;
  border-bottom: 1px solid #ddd;
  &:last-child{
    border:none;
  }
`;

export const ButtonWrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  button.btn, a.btn{
    font-size: .65rem;
  }
`;

export const SeekHeader = styled.div`
  display: flex;
  align-items: center;
  ${ButtonWrapper}{
    flex-direction: column;
    align-items: flex-end;
    p{
      margin: 0;
      font-size: .75rem;
    }
  }
`;

export const SeekTitle = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  div {
    color: #ddd;
    font-size: .75rem;
  }
  p {
    span{
      font-weight: normal;
    }
    a{
      color: #000;
      text-decoration: underline;
    }
    font-weight: 700;
    margin-bottom: 0;
  }
`;

export const SeekContentTitle =styled.div`
  margin-right: .5rem;
  display: flex;
  justify-content: space-between;
  width: 80px;
  &:after{
    content: ":";
    margin-left: .25rem;
  }
`;


export const IndicatorContainer = styled.div`
  position: absolute;
  top: ${props => props.isOpen? 0: '-40px'};
  transition: top .2s, background .2s ease-in;
  width: 100%;
  background: ${props => props.color === 'warning'? '#f8cb00':
    props.color === 'danger'? '#f86c6b':
    props.color === 'success'? '#4dbd74': '#ddd'
  };
  z-index: 1;
  opacity: .9;
  div.indicators__content{
    padding: .5rem 0px;
    color: #000;
    display: flex;
    div.indicators__content__text{
      text-align: center;
      flex: 1;
      span, i{
        margin-right: .25rem;
      }
    }
    div.indicators__content__toggle{
      cursor: pointer;
      margin-right: 1rem;
    }
  }
`;

export const CardInputSearch = styled(CardBody)`
  z-index: 2;
  background: #FFF;
  padding: 1.25rem 1rem;
  border-bottom: 1px solid #ddd;
`;