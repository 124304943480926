import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  ButtonGroup,
  Alert,
} from 'reactstrap';
import classNames from 'classnames';
import {
  getDetailPointShop,
  savePointShop,
  newPointShop,
  deletePointShop
} from '../../actions/pointShopAction';
import { ModalDelete, ModalInfo } from '../../components/Modals';
import { FontAwesomeIcon } from '../../components/Icons';
import { Radio, RadioText, FormGroupRadio } from '../../components/Form/Radios';
import BadgeThin from '../../components/Badges/BadgeThin';
import { ButtonLoading } from '../../components/Button';
import { errorMessage, hasError ,  textValue } from '../../utils/form';
import ProductVariantList from './pointShopForm/ProductVariantList';
import VoucherList from './pointShopForm/VoucherList';

const pointTypeOptions = [{
  label: 'Product Variants',
  value: 'product_variants',
},{
  label: 'Vouchers',
  value: 'vouchers',
}]

class PointShopForm extends React.Component{
  constructor(props){
    super(props);
    this.state={
      _form:{
        id: props.match.params.id || 0,
        slug: '',
        name: '',
        active: false,
        created_at: '',
        updated_at: '',
        categories: []
      },
      _formSubmit: false,
      _deleteConfirmationOpen: false,
      _errorForm: null,
      _alertErrorOpen: false,
      _alertSuccessOpen: false,
      _errorMessage: '',
      _successMessage: '',
      _isModalErrorOpen: false,
    }
  }

  componentDidMount(){
    if(this.props.match.params.id){
      const { id } = this.props.match.params;
      this.props.find(id)
    }
    else{
      this.props.new();
    }
  }

  componentDidUpdate(prevProps){
    if(!this.props.pointShop.isFetch && prevProps.pointShop.isFetch !== this.props.pointShop.isFetch){
      const { detail, detail:{ status_code }, error } = this.props.pointShop;
      if(error){
        if(error.status_code === 404)
          this.props.history.replace('/404')
          this.setState({
            _errorForm: {...error},
            _alertErrorOpen: true,
            _alertSuccessOpen: false,
            _errorMessage: this.translateMessage(error.status_code, error.message)
          })
      }
      else if(status_code === 200){
        const { _form } = this.state;
        if(!this.props.match.params.id && _form.id){
          this.props.history.replace(`/point_shop/${_form.id}`)
        }
        else{
          this.setState({
            _form:{
              ...this.state._form,
              id: detail.id,
              slug: detail.slug,
              name: detail.name,
              point_type: detail.point_type,
              active: detail.is_active,
              created_at: detail.created_at,
              updated_at: detail.updated_at,
            },
            _errorForm: null,
            _alertErrorOpen: false,
            _formSubmit: false
          })
        }
      }
    }
    if(this.state._formSubmit && prevProps.pointShop.isSubmit !== this.props.pointShop.isSubmit && !this.props.pointShop.isSubmit){
      const { detail, detail:{ status_code, message }, error } = this.props.pointShop;
      if(error){
        if(error.status_code === 404)
          this.props.history.replace('/404')
        this.setState({
          _errorForm: {...error},
          _alertErrorOpen: true,
          _alertSuccessOpen: false,
          _errorMessage: this.translateMessage(error.status_code, error.message),
          _formSubmit: false
        })
      }
      else if(status_code === 200){
        if(detail.delete){
          this.props.history.replace(`/point_shop`)
        }
        else if(!this.props.match.params.id){
          this.props.history.replace(`/point_shop/${detail.id}`)
        }
        this.setState({
          _form:{
            ...this.state._form,
            id: detail.id,
            slug: detail.slug,
            name: detail.name,
            active: detail.is_active,
            created_at: detail.created_at,
            updated_at: detail.updated_at,
          },
          _formSubmit: false,
          _errorForm: null,
          _alertErrorOpen: false,
          _alertSuccessOpen: true,
          _successMessage: this.translateMessage(status_code, message),
        })
      }
    }
  }

  translateMessage = (statusCode, message) => {
    let messageStr = ''
    switch(statusCode){
      case 201:
      case 200: messageStr = 'Success...'; break;
      case 422: messageStr = 'Please fullfill your form.'; break;
      case 404: messageStr = 'Resource not found.'; break;
      case 500: messageStr = "We've got something errors"; break;
      default: messageStr = message; break;
    }
    return messageStr
  }

  _handleModalDeleteToggle = () => {
    const { _formSubmit } = this.state;
    const { injectedProps: {roles} } = this.props;
    if(!_formSubmit){
      if(!roles.includes('superadministrator')){
        this.setState({
         _isModalErrorOpen:true,
         _errorMessage:"Sorry, only super administrator can delete the point shop."
        })
        return;
      }
      this.setState({
        _deleteConfirmationOpen: !this.state._deleteConfirmationOpen
      })
    }
  }

  _renderErrorMessage = name => {
    const { _errorForm } = this.state;
    return errorMessage(name, _errorForm)
  }

  _hasInputError = name => {
    const { _errorForm } = this.state;
    return _errorForm && _errorForm.errors && hasError(name, _errorForm)
  }

  _renderLoading(){
    const { _formSubmit } = this.state;
    if(!_formSubmit) return null;
    return (
      <Row>
        <Col xs={12}>
          <Alert color="info">
            <strong>Heads up!</strong> Submitting your data...
          </Alert>
        </Col>
      </Row>
    )
  }

  _handleDeleteData = () => {
    this.setState({
      _formSubmit: true
    }, () => {
      const { _form: {id} } = this.state;
      this.props.delete(id);
    })
  }

  _handleSubmitForm = async (e) => {
    e.preventDefault()
    const { _form } = this.state;
    const forcedSubmit = !this.props.edit;
    const payloads = {
      name: _form.name,
      point_type: _form.point_type,
      is_active: _form.active,
    }
    if(_form.id && _form.id != 0){
      payloads.id = _form.id
    }
    if(forcedSubmit){
      this.setState({
        _form: {
          ..._form,
        },
        _formSubmit: true,
      }, () => {
        this.props.save(payloads)
      })
    } else{
      this.setState({
        _formSubmit: true,
      }, () => {
        this.props.save(payloads)
      })
    }
  }

  _handleInputChange = ({ target: { name, value, checked, type } }) => {
    this.setState({
      _form:{
        ...this.state._form,
        [name]: type === 'checkbox'? checked: value
      }
    })
  }

  render(){
    return(
      <Row>
        <ModalDelete
          isOpen={this.state._deleteConfirmationOpen}
          modalTitle="Confirmation."
          modalBody="Are you sure to delete this?"
          onDelete={this._handleDeleteData}
          toggle={this._handleModalDeleteToggle}
        />
        <ModalInfo
          modalTitle="Oh snap."
          isOpen={this.state._isModalErrorOpen}
          toggle={() => this.setState({_isModalErrorOpen: !this.state._isModalErrorOpen})}
          renderModalBody={() => (<p className="text-center">{this.state._errorMessage}</p>)}
        />
        <Col xs={12} md={6}>
          <Card>
            <CardHeader>
              <FontAwesomeIcon iconType="align-justify"/> {this.props.title}
              {this.props.edit?
                <div>
                  <Link style={{fontSize: '.75rem'}} to="/point_shop/create">Create New Point Shop</Link>
                </div>
              :null}
            </CardHeader>
            <CardBody>
              {this._renderLoading()}
              <Row>
                <Col xs={12}>
                  <Alert color="danger" isOpen={this.state._alertErrorOpen} toggle={() => this.setState({_alertErrorOpen: false})}>
                    <strong>Oh Snap!</strong> { this.state._errorMessage }
                  </Alert>
                </Col>
                <Col xs={12}>
                  <Alert color="success" isOpen={this.state._alertSuccessOpen} toggle={() => this.setState({_alertSuccessOpen: false})}>
                    <strong>Done!</strong> { this.state._successMessage }
                  </Alert>
                </Col>
              </Row>
              {this.state._form.id? (
                <div>
                  <BadgeThin color="primary">#{this.state._form.id}</BadgeThin>
                  <BadgeThin>{this.state._form.slug}</BadgeThin>
                </div>
              ): null}
              <Form onSubmit={this._handleSubmitForm}>
                <ul className="image-guides" style={{fontSize: '.75rem', paddingInlineStart: '1rem'}}>
                  <li>One point shop can store the items (vouchers/products), and showing the item based on their type (if it’s active).</li>
                  <li>Add / remove the items after created the point shop or in point shop detail page.</li>
                </ul>
                <FormGroup
                  className={classNames({ 'has-danger has-feedback': this._hasInputError('point_type') })}
                  row
                >
                  <Col xs={12} md={6}>
                    <Label>Type*</Label>
                    <Input
                      disabled={this.props.edit}
                      placeholder="Select type..."
                      name="point_type"
                      type="select"
                      value={textValue('point_type', this.state._form)}
                      onChange={this._handleInputChange}
                    >
                      <option value="">Select type...</option>
                      {
                        pointTypeOptions.map((item, key) => (
                          <option key={key} value={item.value}>{item.label}</option>
                        ))
                      }
                    </Input>
                    { this._renderErrorMessage('point_type') }
                  </Col>
                </FormGroup>
                <FormGroup
                  className={classNames({ 'has-danger has-feedback': this._hasInputError('name') })}
                >
                  <Label>Name*</Label>
                  <Input
                    placeholder="Enter a name*"
                    name="name"
                    type="text"
                    value={textValue('name', this.state._form)}
                    onChange={this._handleInputChange}
                  />
                  { this._renderErrorMessage('name') }
                </FormGroup>
                <Row>
                  <Col xs={12} sm={6} md={6}>
                    <FormGroupRadio
                      className={classNames({ 'has-danger has-feedback': this._hasInputError('active') })}
                    >
                      <Radio
                        type="checkbox"
                        name="active"
                        value={true}
                        checked={textValue('active', this.state._form)}
                        dataOnText="Yes"
                        dataOffText="No"
                        onChange={this._handleInputChange}
                      />
                      <RadioText text="Set to active"/>
                      { this._renderErrorMessage('active') }
                    </FormGroupRadio>
                  </Col>
                </Row>
                {
                  this.props.edit?(
                    <div>
                      <Row>
                        <Col xs={12} md={6}>
                          <FormGroup>
                            <Label>Created</Label>
                            <Input
                              readOnly
                              name="created_at"
                              type="text"
                              value={textValue('created_at', this.state._form)}
                            />
                          </FormGroup>
                        </Col>
                        <Col xs={12} md={6}>
                          <FormGroup>
                            <Label>Updated</Label>
                            <Input
                              readOnly
                              name="updated_at"
                              type="text"
                              value={textValue('updated_at', this.state._form)}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                  ):null
                }

                <p style={{fontSize: '.75rem', fontStyle: 'italic'}}>(*) this field is required.</p>
                <FormGroup row>
                  <Col>
                    <ButtonGroup>
                      <ButtonLoading
                        disabled={this.state._formSubmit}
                        isLoading={this.state._formSubmit}
                        loadingMessage="Submitting..."
                        color="primary"
                      >
                        Submit
                      </ButtonLoading>
                      {
                        this.props.edit?(
                          <ButtonLoading
                            type="button"
                            disabled={this.state._formSubmit}
                            loadingMessage="Deleting..."
                            onClick={this._handleModalDeleteToggle}
                            color="danger"
                          >
                            Delete
                          </ButtonLoading>
                        )
                        :null
                      }
                    </ButtonGroup>
                  </Col>
                </FormGroup>
              </Form>
            </CardBody>
          </Card>
        </Col>
        {
          this.props.edit?(
            <Col xs={12} md={6}>
              {
                this.state._form.point_type === 'product_variants'?(
                  <ProductVariantList id={this.props.match.params.id}/>
                ):(this.state._form.point_type === 'vouchers'? (
                  <VoucherList id={this.props.match.params.id}/>
                ):null)
              }
            </Col>
          ): null
        }
      </Row>
    )
  }
}
const mapStateToProps= ({ pointShop: { detail, isSubmit, isFetch, error, isImageDataSubmit }, masterCategories }) => ({
  pointShop: {
    detail, isSubmit, isImageDataSubmit, isFetch, error
  },
});
const mapDispatchToProps = (dispatch) => ({
  find:(id) => dispatch(getDetailPointShop(id)),
  delete:(id) => dispatch(deletePointShop(id)),
  save:(payload) => dispatch(savePointShop(payload)),
  new:() => dispatch(newPointShop()),
});
export default connect(mapStateToProps,mapDispatchToProps)(PointShopForm);