import React from 'react';
import propTypes from 'prop-types';
import { connect } from 'react-redux';
import qs from 'query-string';
import {
  Row,
  Col,
  Card,
  TabContent
} from 'reactstrap';
import { getDetailSale, doHijackedSale, doForceCancelledSale, getTrackingShipmentDetail } from '../../../actions/saleAction';
import { getDetailProductVariant } from '../../../actions/productVariantAction';
import privateView from '../../../components/hocs/privateView';

import Header from '../SaleDetails/Header';
import TabPaneOrderDetail from './TabPaneOrderDetail';
import TabPaneUserDetail from './TabPaneUserDetail';
import TabPaneSellerDetail from './TabPaneSellerDetail';
import TabPaneSneakerDetail from './TabPaneSneakerDetail';
import TabPaneChangeSeller from './TabPaneChangeSeller';
import Nav from './Nav';
import ModalForceCancelled from '../SaleDetails/ModalForceCancelled';
import Analytics from "../../../services/Analytics";

class SaleHijacked extends React.Component {
  constructor(props){
    super(props);
    const DEFAULT_STATE = {
      id: parseInt(props.match.params.id),
      _error: null,
      _sale: {},
      _brand: null,
      _userSellId: 0,
      _userSellImages:[],
      activeTab: 'change_seller',
      _isSubmit: false,
      _isForceCancelledSubmit: false,
      _errorForceCancelled: null,
    }
    const queries = Object.assign(DEFAULT_STATE,qs.parse(props.location.search));
    this.state = {
      ...queries
    }
    this._onActiveTabChange = this._onActiveTabChange.bind(this);
    this._handleChangeSellerSubmit = this._handleChangeSellerSubmit.bind(this);
    this._handleOnForceCancelledClick = this._handleOnForceCancelledClick.bind(this);
    this._toogleForceCancelledModal = this._toogleForceCancelledModal.bind(this);
    this. _handleOnSubmitForceCancelled = this._handleOnSubmitForceCancelled.bind(this);
  }

  componentDidMount() {
    const { email } = this.props.injectedProps;
    this.props.find(this.state.id);
    Analytics.recordPageView('change_seller_sales', email, {
      url: window.location.href,
      location: this.props.location,
      match: this.props.match
    });
    this.props.getTracking(this.state.id)
  }


  componentDidUpdate(prevProps) {
    if(prevProps.sale.isFetch && prevProps.sale.isFetch !== this.props.sale.isFetch){
      const { error } = this.props.sale;
      if(error){
        this.setState({
          _error: error
        })
      }
      else{
        const { offer, sale_product, sale_shipping, user, ...sale } = this.props.sale.detail;
        this.setState({
          _userSell: {
            id: offer.user_sell.id,
            user: offer.user_sell.user,
            asking_price: sale.price,
            purchase_price: offer.user_sell.purchase_price,
            box_condition: sale_product.box_condition,
            sneakers_condition: sale_product.sneakers_condition,
            pre_verified: sale_product.pre_verified,
            pre_order: sale_product.pre_order,
            missing_accessories: sale_product.missing_accessories,
            yellowing: sale_product.yellowing,
            display_item: sale_product.display_item,
            sneakers_defect: sale_product.sneakers_defect,
            defects: offer.user_sell.defects
          },
          _sale: sale,
          _offer: offer,
          _user: user,
          _userSellId: offer.user_sell_id,
          _seller: offer.user_sell.user,
          _saleProduct: { ...sale_product, sale_product_defects: offer.user_sell.defects },
          _saleShipping: sale_shipping,
          _userSellImages: offer.user_sell.user_sell_images
        })
        this.props.findProduct(sale_product.product_variant_id)
      }
    }
    if(prevProps.sale.isSubmit && prevProps.sale.isSubmit!==this.props.sale.isSubmit){
      const { error } = this.props.sale;
      if(error){
        if(this.state._isForceCancelledSubmit){
          this.setState({
            _errorForceCancelled: error,
            _isForceCancelledSubmit: false
          })
        }
        else{
          this.setState({
            _error: error,
            _isSubmit: false
          })
        }
      }
      else{
        const { detail } = this.props.sale;
        this.setState({
          _isSubmit: false,
          _isForceCancelledSubmit: false
        })
        this.props.history.replace(`/sales/${detail.id}`)
      }
    }
    if(prevProps.dataProductVariant.isFetch && prevProps.dataProductVariant.isFetch!==this.props.dataProductVariant.isFetch){
      const { error } = this.props.dataProductVariant;
      if(!error){
        const { detail } = this.props.dataProductVariant;
        this.setState({
          _brand: detail.product? detail.product.brand: null
        })
      }
    }
    if (this.props.sale.shippingUpdate.isFetch !== prevProps.sale.shippingUpdate.isFetch && !this.props.sale.shippingUpdate.isFetch)
    {
      const { data, error } = this.props.sale.shippingUpdate;
      if (!error) {
        this.setState({
          _shippingUpdate: data
        })
      }
    }
  }

  onRenderSaleHeader(){
    if(!this.props.sale.isFetch && this.state._sale.status_code===200) {
      return (
        <Header
          id={this.state.id}
          invoiceNumber={this.state._sale.invoice_number}
          productName={this.state._saleProduct.display_name}
          status={this.state._sale.status}
          createdAt={this.state._sale.created_at}
          isHijacked={this.state._sale.hijacked}
          hijackedAt={this.state._sale.hijacked_at}
          showButtonChangeSeller={false}
          forceCancelledAt={this.state._sale.force_cancelled_at}
          onForceCancelledClick={this._handleOnForceCancelledClick}
        />
      )
    }
    return null;
  }

  _onActiveTabChange(id){
    this.setState({activeTab:id}, ()=>{
      const { activeTab } = this.state;
      const { pathname } = this.props.location;
      const search = qs.stringify({
        activeTab
      });
      this.props.history.push({ pathname, search })
    });
  }

  _handleChangeSellerSubmit(payloads){
    this.setState({
      _isSubmit: true,
      _error: null
    },()=>{
      this.props.submitHijacked(this.state.id, payloads);
    })
  }

  _handleOnForceCancelledClick(){
    this._toogleForceCancelledModal();
  }

  _toogleForceCancelledModal(){
    if(!this.state._isForceCancelledSubmit)
      this.setState({
        _isForceCancelledModalOpen: !this.state._isForceCancelledModalOpen
      })
  }

  _handleOnSubmitForceCancelled(id, payloads) {
    this.setState({
      _isForceCancelledSubmit: true,
      _errorForceCancelled: null
    }, ()=>{
      this.props.submitForceCancelled(id, payloads)
    })
  }

  render(){
    return(
      <div className="animated fadeIn">
        <ModalForceCancelled
          id={this.state.id}
          offer={this.state._offer}
          saleStatus={this.state._sale.status}
          isOpen={this.state._isForceCancelledModalOpen}
          isSubmit={this.state._isForceCancelledSubmit}
          errorData={this.state._errorForceCancelled}
          onCancel={this._toogleForceCancelledModal}
          onSubmit={this._handleOnSubmitForceCancelled}
        />
        <Row>
          <Col xs={12}>
            <Card body>
              {this.onRenderSaleHeader()}
              <Nav
                activeTab={this.state.activeTab}
                onActiveTabChange={this._onActiveTabChange}
              />
              <TabContent className="tab-content--custom" activeTab={this.state.activeTab}>
                <TabPaneChangeSeller
                  tabId="change_seller"
                  isLoading={!(!this.props.sale.isFetch&&this.state._sale.status_code===200)}
                  isSubmit={this.state._isSubmit}
                  dataError={this.state._error}
                  dataUserSell={this.state._userSell}
                  dataSale={this.state._sale}
                  dataProduct={this.state._saleProduct}
                  dataBrand={this.state._brand}
                  dataVariant={this.props.dataProductVariant.detail}
                  onChangeSellerSubmit={this._handleChangeSellerSubmit}
                />
                <TabPaneOrderDetail
                  tabId="order"
                  isLoading={!(!this.props.sale.isFetch&&this.state._sale.status_code===200)}
                  order={this.state._sale}
                  payment={this.state._offer}
                  product={this.state._saleProduct}
                  shipping={this.state._saleShipping}
                  seller={this.state._seller}
                />
                <TabPaneSneakerDetail
                  tabId="sneaker"
                  isLoading={!(!this.props.sale.isFetch&&this.state._sale.status_code===200)}
                  isLegit={this.state._sale.ka_verified}
                  product={this.state._saleProduct}
                  userSellImages={this.state._userSellImages}
                  userSellId={this.state._userSellId}
                />
                <TabPaneUserDetail
                  tabId="user-shipping"
                  isLoading={!(!this.props.sale.isFetch&&this.state._sale.status_code===200)}
                  shipping={this.state._saleShipping}
                  shippingUpdate={this.state._shippingUpdate}
                  user={this.state._user}
                />
                <TabPaneSellerDetail
                  tabId="seller"
                  isLoading={!(!this.props.sale.isFetch&&this.state._sale.status_code===200)}
                  seller={this.state._seller}
                />
              </TabContent>
            </Card>
          </Col>
        </Row>
      </div>
    )
  }
}

const mapStateToProps= ({sale, productVariant: dataProductVariant, auth:{ isFetch, roles, isLogged, id, email } }) => ({
  guardData: { isFetch, roles, isLogged, id, email },
  dataProductVariant,
  sale
});

const mapDispatchToProps = (dispatch) => ({
  find: id => dispatch(getDetailSale(id)),
  findProduct: id => dispatch(getDetailProductVariant(id)),
  submitHijacked: (id, payloads) => dispatch(doHijackedSale(id, payloads)),
  submitForceCancelled:(id, payloads) => dispatch(doForceCancelledSale(id, payloads)),
  getTracking: id => dispatch(getTrackingShipmentDetail(id))
});

const enhance = connect(mapStateToProps,mapDispatchToProps);

export default enhance(privateView(SaleHijacked, ['supervisor','sale_supervisor']));

SaleHijacked.defaultProps = {

}

SaleHijacked.propTypes = {
  sale: propTypes.object,
  history: propTypes.object,
  location: propTypes.object,
  match: propTypes.object,
  injectedProps: propTypes.object,
  find: propTypes.func,
  submitHijacked: propTypes.func
}