import React from 'react';
import { connect } from 'react-redux';
import {
  Card,
  CardHeader,
  CardBody,
  ListGroup,
  ListGroupItem,
  ListGroupItemHeading,
  ListGroupItemText,
  Input
} from 'reactstrap';
import styled from 'styled-components';

import {
  attachCollection,
  detachCollection,
} from '../../actions/productVariantAction';

import { getCollectionList } from '../../actions/collectionAction';

import Loading from 'react-loading-animation';

import { FontAwesomeIcon, SimpleLineIcon } from '../../components/Icons';
import ModalBrowseCollection from './Modals/ModalBrowseCollection';

const CollectionItem = styled(ListGroupItem)`
  display: flex;
  align-items: center;
  .control{
    padding: .5rem;
  }
`

const Title = styled(ListGroupItemHeading)`
  margin-bottom: 0px;
`
const Desc = styled(ListGroupItemText)`
  margin-bottom: 0px;
`

class ProductVariantCollection extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      modalBrowseCollection: false,
      dataInputs: []
    }
    this._handleOpenBrowseCollectionModal = this._handleOpenBrowseCollectionModal.bind(this);
    this._handleDetachAllCollection = this._handleDetachAllCollection.bind(this);
  }

  componentDidUpdate(prevProps){
    if(prevProps.productVariantIsSubmit && prevProps.productVariantIsSubmit !== this.props.productVariantIsSubmit){
      this.setState({
        dataInputs: []
      })
    }
  }

  _openBrowseCollectionModal(){
    this.setState({
      modalBrowseCollection: !this.state.modalBrowseCollection
    });
  }

  _handleOpenBrowseCollectionModal(e) {
    e.preventDefault();
    this._openBrowseCollectionModal();
  }

  _getCollectionList(payloads){
    this.props.getCollectionList({
      params: {
        product_variant_id: this.props.productVariantId,
        active: 1,
        ...payloads
      }
    })
  }

  async _handleDetachCollection(e,id){
    e.preventDefault();
    const { productVariantId } = this.props
    await this.props.detachCollection(productVariantId,{
      collection_id: [id]
    });
  }

  _handleDetachAllCollection(e){
    e.preventDefault();
    const { dataInputs } = this.state;
    const { productVariantId, dataSource } = this.props
    if(dataInputs.length){
      this.props.detachCollection(productVariantId,{
        collection_id: [...dataInputs]
      });
    } else {
      const ids = dataSource.map(({id}) => id);
      if(ids.length)
        this.props.detachCollection(productVariantId,{
          collection_id: [...ids]
        });
    }
  }

  render(){
    return(
      <div>
        <ModalBrowseCollection
          isOpen={this.state.modalBrowseCollection}
          isLoading={this.props.masterCollection.isFetch}
          error={this.props.masterCollection.error}
          data={this.props.masterCollection.list.length !== 0? this.props.masterCollection.list: this.props.masterCollection.list}
          toggle={() => this._openBrowseCollectionModal()}
          onGetCollectionList={payloads=>this._getCollectionList(payloads)}
          onSubmitAttachCollection={payloads => this.props.attachCollection(this.props.productVariantId, payloads)}
        />
        <Card>
          <CardHeader>
            <FontAwesomeIcon iconType="align-justify"/> Collections ({this.props.dataSource.length})
          </CardHeader>
          <CardBody>
            <Loading isLoading={this.props.productIsFetch}>
              <p>
                <a
                  href="#"
                  className="link link-default mr-1"
                  onClick={this._handleOpenBrowseCollectionModal}
                >
                  <SimpleLineIcon iconType="plus"/> Browse Collections
                </a>
                <a
                  href="#"
                  className="link text-danger mr-2"
                  onClick={this._handleDetachAllCollection}
                >
                  <SimpleLineIcon iconType="trash" className="mr-1"/>
                  Remove {this.state.dataInputs.length ? `(${this.state.dataInputs.length})`: 'All'}
                </a>
                {
                  this.state.dataInputs.length? (
                    <a
                      href="#"
                      className="link text-danger"
                      onClick={e => {
                        e.preventDefault();
                        this.setState({ dataInputs: [] })
                      }}
                    >
                      <SimpleLineIcon iconType="action-undo" className="mr-1"/>
                      Undo Select
                    </a>
                  ): null
                }
              </p>
              <ListGroup style={{maxHeight: 250, overflow: 'auto'}}>
                {
                  this.props.dataSource &&
                  this.props.dataSource.map((item, index)=>
                    <CollectionItem key={index}>
                      <div className="control">
                        <Input
                          type="checkbox"
                          value="true"
                          checked={this.state.dataInputs.includes(item.id)}
                          onChange={ e => {
                            const { checked } = e.target;
                            const list  = this.state.dataInputs.filter(i => i !== item.id)
                            if(checked) list.push(item.id)
                            this.setState({
                              dataInputs: [...list]
                            })
                          }}
                        />
                      </div>
                      <div>
                        <Title>
                          (
                            <a
                              href="#"
                              className="link text-danger"
                              onClick={(e)=> this._handleDetachCollection(e,item.id)}
                            >
                              <SimpleLineIcon iconType="trash"/>
                            </a>
                          ) &nbsp; {item.name}
                        </Title>
                        <Desc>
                          <small><b>added</b>: <i> {item.pivot.created_at},</i> <b>active</b>: {item.active? <i className="fa fa-check"></i>:<i className="fa fa-times"></i> } </small>
                        </Desc>
                      </div>
                    </CollectionItem>
                  )
                }
              </ListGroup>
            </Loading>
          </CardBody>
        </Card>
      </div>
    )
  }
}

const mapStateToProps= ({ productVariant: { isSubmit }, masterCollection }) => ({ 
  masterCollection,
  productVariantIsSubmit: isSubmit
 });

const mapDispatchToProps = (dispatch) => ({
  getCollectionList:(query)=>dispatch(getCollectionList(query)),
  attachCollection:(id,payload)=>dispatch(attachCollection(id,payload)),
  detachCollection:(id,payload)=>dispatch(detachCollection(id,payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductVariantCollection);