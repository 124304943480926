import React from 'react';
import { 
  Card,
  CardHeader,
  CardImg,
  CardBody,
  Badge
} from 'reactstrap';
import Loading from 'react-loading-animation';
import propTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import noImage from  '../../assets/img/no-image.png';
import { _getValue, _getGenderString } from '../../utils/form';
import { NO_IMAGE_ALT } from '../../constants/settings';
import CardHeaderText from '../Card/CardHeaderText';
import {
  FieldRow,
  FieldColumn,
  FieldLabel,
  FieldValue
} from '../../styled/Fields';
import { getCacheImage } from '../../utils/imageHelper'

const DetailReadOnly = props => {
  return(
    <Card>
      <CardHeader>
        <CardHeaderText text={props.headerText}/>
      </CardHeader>
      <CardImg src={ getCacheImage(props.featureImage) || noImage} alt={props.featureImage?props.display_name:NO_IMAGE_ALT}/>
      <CardBody>
        { 
          props.isLoading === true?
          <Loading/>
          :
          <div>
            <div style={{marginBottom: '.5rem'}}>
              <Badge><i className={classNames('fa',{'fa-check': props.active, 'fa-times': !props.active})}></i> ACTIVE</Badge> &nbsp; <Badge><i className={classNames('fa',{'fa-check': props.editors_choice, 'fa-times': !props.editors_choice})}></i> HOT PRODUCT</Badge>
            </div>
            <div style={{marginBottom: '.5rem'}}>
              {
                props.product && props.product.brand_id?
                props.brand.flat.map((brand, index)=><Badge color="primary" key={index} style={{marginRight: 5}}>{brand}</Badge>)
                :null
              }
            </div>
            <FieldRow>
              <FieldColumn className="col-xs-12 col-md-6">
                <FieldLabel>Product ID <Link to={`/product_variants/${props.id}`} title="Go to Variant Details">See more...</Link></FieldLabel>
                <FieldValue>
                  <span>{_getValue(props.id)}</span>
                </FieldValue>
              </FieldColumn>
            </FieldRow>
            <FieldRow>
              <FieldColumn className="col-xs-12 col-md-6">
                <FieldLabel>Product</FieldLabel>
                <FieldValue>
                  <span>{_getValue(props.product.name)}</span>
                </FieldValue>
              </FieldColumn>
              <FieldColumn className="col-xs-12 col-md-6">
                <FieldLabel>Category</FieldLabel>
                <FieldValue>
                  <span>{props.product.category_id?_getValue(props.category.name):''}</span>
                </FieldValue>
              </FieldColumn>
              <FieldColumn className="col-12">
                <FieldLabel>Display Name</FieldLabel>
                <FieldValue>
                  <span>{_getValue(props.display_name)}</span>
                </FieldValue>
              </FieldColumn>
              <FieldColumn className="col-12">
                <FieldLabel>Colour</FieldLabel>
                <FieldValue>
                  <span>{_getValue(props.colour)}</span>
                </FieldValue>
              </FieldColumn>
              <FieldColumn className="col-12">
                <FieldLabel>Type</FieldLabel>
                <FieldValue>
                  <span>{_getValue(props.type)}</span>
                </FieldValue>
              </FieldColumn>
              <FieldColumn className="col-12">
                <FieldLabel>Tags</FieldLabel>
                <FieldValue style={{wordWrap: 'break-word'}}>
                  <span>{_getValue(props.nickname)}</span>
                </FieldValue>
              </FieldColumn>
              <FieldColumn className="col-xs-12 col-md-6">
                <FieldLabel>SKU</FieldLabel>
                <FieldValue>
                  <span>{_getValue(props.SKU)}</span>
                </FieldValue>
              </FieldColumn>
              <FieldColumn className="col-xs-12 col-md-6">
                <FieldLabel>Weight (gram)</FieldLabel>
                <FieldValue>
                  <span>{_getValue(props.weight)}</span>
                </FieldValue>
              </FieldColumn>
              <FieldColumn className="col-xs-12 col-md-6">
                <FieldLabel>Gender</FieldLabel>
                <FieldValue>
                  <span>{_getGenderString(props.sex)}</span>
                </FieldValue>
              </FieldColumn>
              <FieldColumn className="col-xs-12 col-md-6">
                <FieldLabel>Release Date</FieldLabel>
                <FieldValue>
                  <span>{_getValue(props.release_date)}</span>
                </FieldValue>
              </FieldColumn>
              <FieldColumn className="col-xs-12 col-md-6">
                <FieldLabel>Expiry Date</FieldLabel>
                <FieldValue>
                  <span>{_getValue(props.expiry_date)}</span>
                </FieldValue>
              </FieldColumn>
              <FieldColumn className="col-xs-12 col-md-6">
                <FieldLabel>Last Updated</FieldLabel>
                <FieldValue>
                  <span>{_getValue(props.updated_at)}</span>
                </FieldValue>
              </FieldColumn>
            </FieldRow>
          </div>
        }   
      </CardBody>
    </Card>
  )
}

export default DetailReadOnly;
DetailReadOnly.defaultProps = {
  headerText: "Sneaker Detail",
  isLoading: false,
  id: 0,
  product: {
    id: 0,
    brand_id: 0,
    category_id: 0,
    name: ''
  },
  brand: {
    id: 0,
    flat: []
  },
  category: {
    id: 0,
    name: ''
  }
}
DetailReadOnly.propTypes = {
  headerText: propTypes.oneOfType([propTypes.node, propTypes.string]),
  id: propTypes.number,
  type: propTypes.string,
  weight: propTypes.number,
  SKU: propTypes.string,
  display_name: propTypes.string,
  nickname: propTypes.string,
  featureImage: propTypes.string,
  colour: propTypes.string,
  active: propTypes.oneOfType([propTypes.bool,propTypes.number]),
  editors_choice: propTypes.oneOfType([propTypes.bool,propTypes.number]),
  release_date: propTypes.string,
  expiry_date: propTypes.string,
  category: propTypes.object,
  product: propTypes.object,
  brand: propTypes.object
}
