import { 
  AUTH_LOGIN,
  AUTH_LOGOUT,
  AUTH_PROFILES,
  AUTH_LOGIN_ERROR,
  AUTH_FETCH,
  AUTH_TOKEN_KEY,
  AUTH_USER_KEY,

	AUTH_CHANGE_PASSWORD,
	AUTH_CHANGE_PASSWORD_ERROR,
	AUTH_CHANGE_PASSWORD_SUBMIT,

	AUTH_UPDATE_PROFILE,
	AUTH_UPDATE_PROFILE_ERROR,
	AUTH_UPDATE_PROFILE_SUBMIT,
	
	AUTH_EXTEND_TKN,
	AUTH_EXTEND_TKN_ERROR,
	AUTH_EXTEND_TKN_SUBMIT,
	AUTH_ACCESS_VALIDATED
} from '../constants';

import {jwtPayloads, jwtDecode} from '../utils';
import { authorization  } from '../utils/authorization';

// login mechanism put into localStorage
const login=({token})=>{
	localStorage.setItem(AUTH_TOKEN_KEY, token);
	const userJwtPayload = jwtPayloads(token);
	localStorage.setItem(AUTH_USER_KEY, userJwtPayload);
	const roles = getRoles(userJwtPayload);
	return { ...JSON.parse(jwtDecode(userJwtPayload)), roles }
}

const getRoles = (userJwtPayload) =>{
	if(userJwtPayload){
		const { roles } = JSON.parse(jwtDecode(userJwtPayload));
		return roles? roles.split(','):[]; 
	}
	return [];
}

// logout mechanism remove data user from localStorage
const logout=()=>{
	localStorage.removeItem(AUTH_TOKEN_KEY);
	localStorage.removeItem(AUTH_USER_KEY);
}

// Initial state
const initialState={
		isLogged:localStorage.getItem(AUTH_TOKEN_KEY)!==null?true:false,
	isFetch: false,
	authRoutes: [],
    changePassword: {
      isSubmit: false,
      error: null
		},
		validateTkn:{
			isValid: false
		},
		refreshTkn:{
			isSubmit:false
		},
    updateProfile: {
      isSubmit: false,
      error: null
    },
    error:'',
    email: ''
}

const onValidateToken = ({ payload }, lastState) => {
	if(payload===true){
		return { ...lastState, validateTkn:{ isValid: payload, status_code:200 }}
	}
	const { isValid, reasonMsg, status_code } = payload
	return { ...lastState, validateTkn:{ isValid, reasonMsg, status_code }}
}

const authReducer=(state=initialState, action)=>{
	switch(action.type)
	{
		case AUTH_LOGIN:{
      // console.log('PAYLOAD LOGIN NIH: ', action.payload);
			const { roles } = login(action.payload.data);
			return {
				 ...state, isLogged:true, roles
			}
		}
		case AUTH_LOGIN_ERROR:
			return {
				 ...state, ...action.payload
			}
		case AUTH_LOGOUT:
			logout()
			return {
				 ...initialState, ...action.payload
			}
		case AUTH_PROFILES:{
			const roles = getRoles(localStorage.getItem(AUTH_USER_KEY));
			const authRoutes = authorization(roles);
			// console.log('INI ROUTES BISA ', action.payload);
			return {
				 ...state, ...action.payload, roles, authRoutes, error: null, email: action.payload.email
			}
		}
		case AUTH_FETCH:
			return {
				 ...state, isFetch:action.payload
      }
			case AUTH_CHANGE_PASSWORD_SUBMIT:{
				const error = action.payload===true ? null : state.changePassword.error;
				return { ...state, changePassword:{...state.changePassword, isSubmit: action.payload, error }}
			}
			case AUTH_CHANGE_PASSWORD_ERROR:
				return { ...state, changePassword:{...state.changePassword, ...action.payload }}
			case AUTH_CHANGE_PASSWORD:{
				const { data, ...payload } = action.payload;
				const { roles } = login(data);
				return { ...state, changePassword:{ ...state.changePassword, ...payload }, roles }
			}

			case AUTH_UPDATE_PROFILE_SUBMIT:{
				const error = action.payload===true ? null : state.updateProfile.error;
				return { ...state, updateProfile:{...state.updateProfile, isSubmit: action.payload, error }}
			}
			case AUTH_UPDATE_PROFILE_ERROR:
				return { ...state, updateProfile:{...state.updateProfile, ...action.payload }}
			case AUTH_UPDATE_PROFILE:{
				const { data, ...payload } = action.payload;
				return { ...state, updateProfile:{ ...state.updateProfile, ...payload }, ...data }
			}
			case AUTH_ACCESS_VALIDATED:
				return onValidateToken(action, state)
			case AUTH_EXTEND_TKN_SUBMIT:
				return {...state, refreshTkn:{ ...state.refreshTkn, isSubmit: action.payload } }
			case AUTH_EXTEND_TKN_ERROR:
				return {...state, refreshTkn:{ ...state.refreshTkn, ...action.payload } }
			case AUTH_EXTEND_TKN:{				
				const { roles } = login(action.payload.data);
				return {...state, validateTkn:{ isValid:true, status_code:200 }, refreshTkn:{ ...state.refreshTkn, status_code: action.payload.status_code }, isLogged: true, roles }
			}
		default: return state;

	}
};
export default authReducer;