import React from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import PropTypes from "prop-types";

const ConfirmDelete = ({ isOpen, onToggleModal, onDelete, isDeleting }) => {
  return (
    <Modal
      isOpen={isOpen}
      toggle={onToggleModal}
      className="modal-dialog modal-sm"
    >
      <ModalHeader toggle={onToggleModal}>Confirmation</ModalHeader>
      <ModalBody>
        <span>Are you sure to delete this?</span>
      </ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          onClick={onDelete}
          disabled={isDeleting}
          style={{ pointerEvents: isDeleting ? "none" : "auto" }}
        >
          {isDeleting ? "Deleting..." : "Yes"}
        </Button>{" "}
        <Button color="secondary" onClick={onToggleModal}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

ConfirmDelete.propTypes = {
  isOpen: PropTypes.bool,
  onToggleModal: PropTypes.func,
  onDelete: PropTypes.func,
};

ConfirmDelete.defaultProps = {
  isOpen: false,
  onToggleModal: () => {},
  onDelete: () => {},
};

export default ConfirmDelete;
