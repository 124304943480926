import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Container } from 'reactstrap';
import SurveyOptionForm from './SurveyOptionForm';
import privateView from '../../../components/hocs/privateView';
import Analytics from "../../../services/Analytics";

class SurveyOptionCreate extends Component{
	componentDidMount(){
    const { email } = this.props.injectedProps;
    Analytics.recordPageView('survey_option_create', email, {
      url: window.location.href,
      location: this.props.location,
      match: this.props.match
    });
  }
  render(){
    return (
      <Container fluid>
        <SurveyOptionForm 
          title="Create New Survey Option" 
          {...this.props}
        />
      </Container>
    )
  }
}

const mapStateToProps = ({auth: { isFetch, roles, isLogged, id, email } })=>({
  guardData: { isFetch, roles, isLogged, id, email }
})

const enhance = connect(mapStateToProps);
export default enhance(privateView(SurveyOptionCreate))
