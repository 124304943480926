const getErrMsg = (statusCode, errData) => {
  switch (statusCode) {
    case 422:
      let errMsg = 'Please fulfill your form!';
      if (errData?.message) {
        errMsg = Object.values(errData?.message)?.flat()?.join(', ');
      }
      return errMsg;
    case 404:
      return 'Resource not found!';
    case 403:
      return 'Forbidden, ask our staff for more details!';
    default:
      return "We've got something error!";
  }
};

export default getErrMsg;
