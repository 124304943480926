import React from 'react';
import { connect } from 'react-redux';
import {
  Alert,
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  Form,
  ModalFooter
} from 'reactstrap';
import propTypes from 'prop-types';
import Loading from 'react-loading-animation';
import {
  FieldRow,
  FieldColumn,
  FieldLabel,
  FieldValue
} from '../../styled/Fields';
import { _getValue } from '../../utils/form';
import { getDetailPaymentCreditCard } from '../../actions/saleAction';

class ModalInfoCreditCard extends React.Component{
  constructor(props){
    super(props);
    this.state={
      bank:'',
      bank_code:'',
      brand:'',
      bin_type:'',
      bin:'',
      bin_class:'',
      country_name:'',
      country_code:'',
      isLoading:false,
      error: false,
    }
  }

  componentDidUpdate(prevProps) {
    const { credit_card } = this.props.sale;
    if(prevProps.invoiceNumber!==this.props.invoiceNumber&&this.props.invoiceNumber!==null) {
      this.setState({isLoading:true}, () => this.props.getDetail(this.props.invoiceNumber));
    }
    if(prevProps.sale.credit_card.isFetch!==credit_card.isFetch&&!credit_card.isFetch) {
      if(credit_card.status_code===200&&credit_card.detail) {
        this.setState({...credit_card.detail, isLoading:false, error:false});
      }
      else{
        if(credit_card.status_code!==200&&credit_card.error){
          this.setState({error:credit_card.error&&credit_card.error,isLoading:false});          
        }
      }
    }
  }

  render(){
    return(
      <Modal isOpen={this.props.isOpen} toggle={this.props.toggle} className="modal-dialog modal-md">
        <ModalHeader tag="div" toggle={this.props.toggle}>
          <h4>{this.props.modalTitle}</h4>
          <p className="mb-0">{this.props.invoiceNumber}</p>
        </ModalHeader>
        {
          this.state.isLoading?
          <Loading />
          :
          (
            this.state.error&&!this.state.isLoading ?
            <Alert color="danger" className="text-center" style={{marginTop:'1rem'}}>
              <strong>Oh, snap!</strong> No Record Found!
            </Alert>
            :
            <ModalBody>
              <Form>
                <FieldRow>
                  <FieldColumn className="col-xs-12 col-md-6">
                    <FieldLabel>Bank Name</FieldLabel>
                    <FieldValue>
                      <span>{_getValue(this.state.bank)}</span>
                    </FieldValue>
                  </FieldColumn>
                  <FieldColumn className="col-xs-12 col-md-6">
                    <FieldLabel>Bank Code</FieldLabel>
                    <FieldValue>
                      <span>{_getValue(this.state.bank_code)}</span>
                    </FieldValue>
                  </FieldColumn>
                  <FieldColumn className="col-xs-12 col-md-6">
                    <FieldLabel>Brand</FieldLabel>
                    <FieldValue>
                      <span>{_getValue(this.state.brand)}</span>
                    </FieldValue>
                  </FieldColumn>
                  <FieldColumn className="col-xs-12 col-md-6">
                    <FieldLabel>Bin Type</FieldLabel>
                    <FieldValue>
                      <span>{_getValue(this.state.bin_type)}</span>
                    </FieldValue>
                  </FieldColumn>
                  {/* <hr/> */}
                  <FieldColumn className="col-xs-12 col-md-6">
                    <FieldLabel>Bin Number</FieldLabel>
                    <FieldValue>
                      <span>{_getValue(this.state.bin)}</span>
                    </FieldValue>
                  </FieldColumn>
                  <FieldColumn className="col-xs-12 col-md-6">
                    <FieldLabel>Bin Class</FieldLabel>
                    <FieldValue>
                      <span>{_getValue(this.state.bin_class)}</span>
                    </FieldValue>
                  </FieldColumn>
                  <FieldColumn className="col-xs-12 col-md-6">
                    <FieldLabel>Country Code</FieldLabel>
                    <FieldValue>
                      <span>{_getValue(this.state.country_code)}</span>
                    </FieldValue>
                  </FieldColumn>
                  <FieldColumn className="col-xs-12 col-md-6">
                    <FieldLabel>Country Name</FieldLabel>
                    <FieldValue>
                      <span>{_getValue(this.state.country_name)}</span>
                    </FieldValue>
                  </FieldColumn>
                </FieldRow>
              </Form>
            </ModalBody>
          )
        }
        <ModalFooter>
          <Button color="secondary" onClick={this.props.toggle}>Close</Button>
        </ModalFooter>
      </Modal>
    )
  }
}

ModalInfoCreditCard.propTypes = {
  modalTitle: propTypes.string,
  isOpen: propTypes.bool,
  toggle: propTypes.func,
  invoiceNumber: propTypes.string
}
ModalInfoCreditCard.defaultProps = {
  modalTitle: 'Details Of Credit Card',
  invoiceNumber: null,
  isOpen: false,
}

const mapStateToProps= ({sale}) => {
	return { sale }
};

const mapDispatchToProps = (dispatch) => {
	return{
		getDetail:(invoiceNumber) => dispatch(getDetailPaymentCreditCard(invoiceNumber)),
	}
};
export default connect(mapStateToProps, mapDispatchToProps)(ModalInfoCreditCard);