import React from 'react';
import propTypes from 'prop-types';
import { availableRoles } from '../../config';
import { Redirect } from 'react-router-dom';

const REDIRECT_UNAUTHORIZED = '/403';
const withAuthorized = (AuthorizedComponent) =>{
    return class extends React.Component {
        constructor(props){
            super(props);
            this.state={
              loginRoute:'/login'
            }
        }
        redirectLoginPage(){
            let next='';            
            if(!(this.props.location.pathname=='/dashboard'||this.props.location.pathname=='/'))
                next=`?next=${this.props.location.pathname}`;
            return <Redirect to={`${this.state.loginRoute}${next}`}/>
        }
        componentWillReceiveProps(nextProps){
            if(nextProps.authData.isFetch===false && nextProps.authData.isFetch!==this.props.authData.isFetch && nextProps.authData.isLogged===true){
                if(nextProps.authData.roles){
                    const isAllow = nextProps.authData.roles.filter(userRole=>{
                        return availableRoles.filter(role=>role===userRole).length
                    }).length > 0;
                    if(!isAllow)
                        this.props.history.replace(REDIRECT_UNAUTHORIZED);
                }
            }
            if(nextProps.authData.isLogged===false && nextProps.authData.isLogged!==this.props.authData.isLogged){
                this.redirectLoginPage();
            }
        }

        render(){
            if(!this.props.authData.isLogged)
                return this.redirectLoginPage();
            return (
                <AuthorizedComponent {...this.props}/>
            )
        }
    }
}
export default withAuthorized;

withAuthorized.propTypes={
    authData: propTypes.object.isRequired,
    history: propTypes.object.isRequired,
    location: propTypes.object.isRequired
}