import React from 'react';
import propTypes from 'prop-types';
import PaginationSummaries from '../../../components/Paginations/PaginationSummaries';
import { Tbl, TblHead, TblBody } from '../../../components/Tbl';
import RowItem from './RowItem';
import { generateNumber } from '../../../utils';

class List extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      _data: []
    }
  }
  componentWillReceiveProps(nextProps){
    if(!nextProps.isLoading && nextProps.isLoading != this.props.isLoading){
      this.setState({
        _data: nextProps.data
      })
    }
  }
  render (){
    return (
      <div className="table-responsive" ref={this.props.innerRefs}>
        <PaginationSummaries
          from={this.props.from}
          to={this.props.to}
          total={this.props.total}
        />
        <Tbl>
          <TblHead
            renderChildren={this.props.onRenderHeader}
          />
          <TblBody
            isLoad={this.props.isLoading}
            hasRow={this.state._data.length>0}
            columnCount={6}
            renderChildren={()=> {
              if(!this.props.isLoading&&this.props.statusCode===200){
                const { currentPage, perPage } = this.props;
                return (
                  this.state._data.map((item,index)=>{
                    const number = generateNumber(perPage,currentPage,index);
                    return (
                      <RowItem
                        key={item.id}
                        id={item.id}
                        rowId={number}
                        userEmail={item?.user?.email}
                        typedEmail={item?.user?.typed_email}
                        status={item.status}
                        updatedAt={item.updated_at}
                        approvedAt={item.approved_at}
                        defaultRoute={this.props.defaultRoute}
                      />
                    )
                  })
                )
              }
              return null;
            }}
          />
        </Tbl>
      </div>
    )
  }
}
export default List;

List.propTypes = {
  from: propTypes.number,
  to: propTypes.number,
  total: propTypes.number,
  currentPage: propTypes.number,
  perPage: propTypes.oneOfType([propTypes.number, propTypes.string]),
  statusCode: propTypes.any,
  defaultRoute: propTypes.string.isRequired,
  isLoading: propTypes.bool
}
List.defaultProps = {
  from: 0,
  to: 0,
  total: 0,
  currentPage: 0,
  perPage: 0
}