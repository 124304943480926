import React from 'react';
import propTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { getPaymentType } from '../../../constants/kickavenue';
import BadgeThin from '../../../components/Badges/BadgeThin';
import DateToHuman from '../../../components/DateToHuman';
import { TextRupiah } from '../../../components/Text';
import { getStatusStr, isExpired as checkIsExpired } from '../helpers';
import FontAwesomeIcon from '../../../components/Icons/FontAwesomeIcon';

const Div = styled.div`
  display: flex;
  flex-direction: ${props => props.column? 'column': 'row'};
  font-size: ${props => props.fontSize? props.fontSize: 'inherit' };
`
const FooterCard = styled(Div)`
  align-items: center;
  > .text-danger {
    font-size: .65rem;
    font-style: italic;
    margin-left: .2rem;
  }
`
const HeaderDiv = styled(Div)`
  color: #55676A;
  font-size: .75rem;

`
const Text = styled.span`
  margin-right: 5px;
  font-size: ${props => props.type==='small'? '.75rem': 'inherit' };
  font-style: ${props => props.fontStyle ? props.fontStyle : 'normal' };
`
const Td = styled.td`
  border-bottom: ${props => props.isDeleted? '1px solid #f86c6b': 'none'};
`


const RaffleRequestCard = props => (
  <Div column>
    <HeaderDiv>
      {props.category &&(
        <Text className="text-uppercase">
          <BadgeThin color="primary">
            <i className="fa fa-tags"></i> { props.category }
          </BadgeThin>
        </Text>
      )}
      <Text>{ `#${props.id}` }</Text>
      <Text>{ props.code }</Text>
    </HeaderDiv>
    <Div column>
      <Text>{ props.displayName }</Text>
      <Text type="small" fontStyle="italic">{ props.colour }</Text>
    </Div>
    <Div fontSize=".65rem">
      <Text><i className="fa fa-calendar"></i> { props.createdAt }</Text>
      <Text>({ props.SKU })</Text>
    </Div>
    <FooterCard>
      <Text>
        <BadgeThin color="success">SIZE: { props.size }</BadgeThin>
      </Text>
      <Text>
        <BadgeThin>
          <FontAwesomeIcon className="mr-1" iconType="user"/>
          {props.user? <Link style={{color: '#fff', textDecoration: 'underline'}} className="link-default" to={`/users/${props.user.id}`}>{props.user.email}</Link>: ' - '}
        </BadgeThin>
      </Text>
    </FooterCard>
  </Div>
)

const RowItem = props => {
  const { payment } = props;
  const isExpired = checkIsExpired(props.expiredAt)
  return (
    <tr>
      <th className="v-center" scope="row">{ props.rowNumber }</th>
      <Td className="v-center">
        <RaffleRequestCard
          id={props.id}
          rowNumber={props.rowNumber}
          code={props.code}
          category={props.category}
          createdAt={props.createdAt}
          size={props.size}
          displayName={props.displayName}
          colour={props.colour}
          SKU={props.SKU}
          amount={props.amount}
          user={props.user}
        />
      </Td>
      <Td className="v-center">
        <TextRupiah bold prefix="Rp. " value={props.amount}/>
      </Td>
      <Td className="v-center">{ getPaymentType(props.paymentMethod) }</Td>
      <Td className="v-center">{ getStatusStr(props.status, isExpired, payment) }</Td>
      <Td className="v-center"><DateToHuman  value={props.updatedAt} id={`tooltip_${props.rowNumber}`}/></Td>
      <Td className="v-center">
        <Link className="btn btn-link" title="Show details" to={`/raffle_requests/${props.id}`}>Details</Link>  
      </Td>
    </tr>
  )
}

export default RowItem;

RowItem.defaultProps = {
  amount: 0,
}

RowItem.propTypes = {
  rowNumber: propTypes.number.isRequired,
  amount: propTypes.oneOfType([propTypes.number, propTypes.string])
}