import React from "react";
import Select, { Creatable } from "react-select";
import "react-select/dist/react-select.css";
import { ButtonLoading } from "../../../components/Button";
import {
  Alert,
  Card,
  CardBody,
  CardHeader,
  Form as BSForm,
  FormGroup,
  Label,
  Input,
  Row,
  Col
} from "reactstrap";
import InputDatetime from "../../../components/Form/InputDatetime/InputDatetime";
import SelectProduct from "../../../components/Form/Select/Async/SelectProduct";
import {
  errorMessage,
  hasError,
  textValue,
  textChange,
  dateChange,
  dateValue,
  checkChange
} from "../../../utils/form";
import classNames from "classnames";
import RichTextEditor from "react-rte";
import moment from 'moment-timezone';
import BadgeThin from "../../../components/Badges/BadgeThin";
import getErrMsg from "../../../utils/errMsg";

const MyAlert = props => (
  <Alert
    color={
      props.isLoadingAlert
        ? "info"
        : props.statusCode === 200
        ? "success"
        : "danger"
    }
    isOpen={props.isOpen}
    toggle={props.toggleAlert}
  >
    <strong>
      {props.isLoadingAlert
        ? "Please wait. "
        : props.statusCode === 200
        ? "Success. "
        : "Oopps. "}{" "}
    </strong>
    {props.message}
  </Alert>
);

class Form extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      _form: {
        product: null,
        weight: 3492,
        sex: "M",
        detailsValue: RichTextEditor.createEmptyValue(),
        details: "",
        currency: 'USD',
        receive_sell:true,
        voucher_applicable:true,
        biddable: true,
        ...props.initialValueForm
      },
      _isSubmitProgress: false,
      _errorForm: [],
      _defaultRibbonTags:[
        {
          label: "collection",
          value: "collection"
        },
        {
          label: "best-selling",
          value: "best-selling"
        },
        {
          label: "search",
          value: "search"
        },
      ],
      _defaultTags: [
        {
          label: "nike",
          value: "nike"
        },
        {
          label: "adidas",
          value: "adidas"
        },
        {
          label: "vapormax",
          value: "vapormax"
        },
        {
          label: "airjordan",
          value: "airjordan"
        },
        {
          label: "ultraboost",
          value: "ultraboost"
        },
        {
          label: "yeezy",
          value: "yeezy"
        }
      ],
      _isAlertOpen: false,
      _successMsg: "Create product variant successfully.",
      _errorMsg: "Error happen...",
      defaultProductOptions: []
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeEditor = this.handleChangeEditor.bind(this);
    this.handleCheckedChange = this.handleCheckedChange.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this._handleOnSelectedProductChange = this._handleOnSelectedProductChange.bind(
      this
    );
    this._handleOnSelectProductOpen = this._handleOnSelectProductOpen.bind(
      this
    );
  }

  componentDidUpdate(lastProps) {
    if (lastProps.isSubmit && lastProps.isSubmit !== this.props.isSubmit){
      const { errorData } = this.props;
      let { _errorMsg } = this.state;
      if (errorData) {
        _errorMsg = getErrMsg(errorData.status_code, errorData);
        this.setState({
          _errorForm: { ...errorData },
          _isAlertOpen: true,
          _errorMsg
        });
      } else {
        this.setState({
          _isAlertOpen: true,
          _statusCode: this.props.statusCode,
        });
      }
    }
  }

  handleChangeEditor(value) {
    const formatHtml = this.state._form.detailsValue.toString("html");
    this.setState({
      _form: {
        ...this.state._form,
        detailsValue: value,
        details: formatHtml
      }
    });
  }

  handleChange(e) {
    const _form = textChange(e, this.state._form);
    this.setState({ _form });
  }

  handleCheckedChange(e) {
    const _form = checkChange(e, this.state._form);
    this.setState({ _form: { ..._form } });
  }

  handleOnChange(value, name) {
    const { _form } = this.state;
    _form[name] = value;
    this.setState({ _form });
  }
  handleDatePickerChange(value, name) {
    const date = name === 'ended_at' ? moment(value).endOf('day') : value;
    const _form = dateChange(date, name, this.state._form);
    this.setState({ _form });
  }

  _handleOnSelectedProductChange(option) {
    const { _form } = this.state;
    _form.product = option;
    this.setState({ _form });
  }

  handleSelectedChange(option, name) {
    const { _form } = this.state;
    _form[name] = option === null ? option : option.value;
    this.setState({ _form });
  }

  _validateForm(){
    const {product,display_name,colour,SKU,editors_choice}= this.state._form;
    let errors={};
    if(!product)
      errors['product_id']=['The product id field is required.'];
    if(!display_name)
      errors['display_name']=['The display name field is required.'];
    if(!colour)
      errors['colour']=['The colour field is required.'];
    if(!SKU)
      errors['SKU']=['The SKU field is required.'];
    if(Object.keys(errors).length){
      this.setState({
        _errorForm:{errors:{...errors}},
        _errorMsg:'Please fulfill your form.',
        _isAlertOpen: true,
        _statusCode: 422
      })
    }
    return errors;
  }

  onSubmit(e) {
    e.preventDefault();
    const _form = { ...this.state._form };
    const { product } = this.state._form
    _form.product_id = _form.product !== null ? _form.product.id : undefined;
    _form.product = undefined;
    _form.nickname =
      _form.tags && typeof _form.tags === typeof [] && _form.tags.length
        ? _form.tags
            .map(tag => tag.value)
            .reduce((concat, tag) => concat + "," + tag)
        : null;
    _form.active =
      _form.active === undefined || _form.active === null
        ? false
        : _form.active;
    _form.editors_choice =
      _form.editors_choice === undefined || _form.editors_choice === null
        ? false
        : _form.editors_choice;
    _form.hide_box_info =
      _form.hide_box_info === undefined || _form.hide_box_info === null
        ? false
        : _form.hide_box_info;
    _form.vintage =
      _form.vintage === undefined || _form.vintage === null
        ? false
        : _form.vintage;
    _form.receive_sell =
      _form.receive_sell === undefined || _form.receive_sell === null
        ? false
        : _form.receive_sell;
    _form.biddable =
      _form.biddable === undefined || _form.biddable === null
        ? false
        : _form.biddable;
    //force to vintage=false if product not apparels category
    if(product && product.category && product.category.name.toLowerCase() !== "apparels"){
      _form.vintage = false;
    }
    _form.voucher_applicable =
      _form.voucher_applicable === undefined || _form.voucher_applicable === null
        ? false
        : _form.voucher_applicable;
    const valid = this._validateForm();
    if (Object.keys(valid).length===0) {
      this.setState({
        _errorForm: null,
        _isAlertOpen: true
      });
      this.props.onSubmit({ ..._form });
    }
  }

  _handleOnSelectProductOpen() {
    if (this.state._form.product) {
      if (this.state._recentSearchProduct) {
        this.refs.selectProduct.loadSelectOptions(
          this.state._recentSearchProduct
        );
      } else {
        this.refs.selectProduct.loadSelectOptions(
          this.state._form.product.name
        );
      }
    } else {
      this.refs.selectProduct.loadSelectOptions("");
    }
  }

  render() {
    const { product: productForm } = this.state._form
    const categoryEdit = productForm && productForm.category? productForm.category.name.toLowerCase(): null;
    const brandName = productForm && productForm.brand? productForm.brand.name: null;
    
    return (
      <Card>
        <CardHeader>Create New Product Variant</CardHeader>
        <CardBody>
          <MyAlert
            isLoadingAlert={this.props.isSubmit}
            loadingText={"Submitting..."}
            toggleAlert={() =>
              this.setState({ _isAlertOpen: !this.state._isAlertOpen })
            }
            isOpen={this.state._isAlertOpen}
            statusCode={this.state._statusCode}
            message={
              this.state._statusCode === 200
                ? this.state._successMsg
                : this.state._errorMsg
            }
          />
          <BSForm onSubmit={this.onSubmit}>
            <Row>
              <Col xs={12}>
                <FormGroup
                  className={classNames({
                    "has-danger has-feedback": hasError(
                      "product_id",
                      this.state._errorForm
                    )
                  })}
                >
                  <Label for="formProductId">
                    Product{' '}{
                    categoryEdit ? 
                      <span>
                        <BadgeThin>{categoryEdit}</BadgeThin> 
                        <BadgeThin style={{backgroundColor: '#4fa1b7', marginLeft: 4}}>{brandName}</BadgeThin>
                      </span> : null
                    }
                  </Label>
                  <SelectProduct
                    id="formProductId"
                    name="product"
                    ref="selectProduct"
                    placeholder="Select a product*..."
                    noResultsText="Cannot find product."
                    cacheOptions={false}
                    value={this.state._form.product}
                    onSelectSearch={input =>
                      this.setState({ _recentSearchProduct: input })
                    }
                    onSelectChange={this._handleOnSelectedProductChange}
                    onSelectOpen={this._handleOnSelectProductOpen}
                  />
                  {errorMessage("product_id", this.state._errorForm)}
                </FormGroup>
                <FormGroup
                  className={classNames({
                    "has-danger has-feedback": hasError(
                      "display_name",
                      this.state._errorForm
                    )
                  })}
                >
                  <Label for="formProductDisplayName">Display Name</Label>
                  <Input
                    type="text"
                    id="formProductDisplayName"
                    name="display_name"
                    placeholder="Enter a Display Name*"
                    value={textValue("display_name", this.state._form)}
                    onChange={this.handleChange}
                  />
                  {errorMessage("display_name", this.state._errorForm)}
                </FormGroup>
                <FormGroup
                  className={classNames({
                    "has-danger has-feedback": hasError(
                      "type",
                      this.state._errorForm
                    )
                  })}
                >
                  <Label for="formProductType">Type / Material</Label>
                  <Input
                    type="text"
                    id="formProductType"
                    name="type"
                    placeholder="Enter a Type"
                    value={textValue("type", this.state._form)}
                    onChange={this.handleChange}
                  />
                  {errorMessage("type", this.state._errorForm)}
                </FormGroup>
                <FormGroup
                  className={classNames({
                    "has-danger has-feedback": hasError(
                      "dimension",
                      this.state._errorForm
                    )
                  })}
                >
                  <Label for="formProductDimension">Dimension</Label>
                  <Input
                    type="text"
                    id="formProductDimension"
                    name="dimension"
                    placeholder="Enter product dimension"
                    value={textValue("dimension", this.state._form)}
                    onChange={this.handleChange}
                  />
                  {errorMessage("dimension", this.state._errorForm)}
                </FormGroup>
                <FormGroup
                  className={classNames({
                    "has-danger has-feedback": hasError(
                      "nickname",
                      this.state._errorForm
                    )
                  })}
                >
                  <Label for="formProductNickName">Nickname Or Tags</Label>
                  <Creatable
                    multi
                    options={this.state._defaultTags}
                    value={this.state._form.tags}
                    onChange={val => this.handleOnChange(val, 'tags')}
                    placeholder="Enter a nickname or tags"
                  />
                  {errorMessage("nickname", this.state._errorForm)}
                </FormGroup>
                <FormGroup
                  className={classNames({
                    "has-danger has-feedback": hasError(
                      "ribbon_tag",
                      this.state._errorForm
                    )
                  })}
                >
                  <Label for="formProductRibbonTag">Ribbon Tags</Label>
                  <Creatable
                    multi
                    options={this.state._defaultRibbonTags}
                    value={this.state._form.ribbon}
                    onChange={ val => this.handleOnChange (val, 'ribbon')}
                    placeholder="Enter a ribbon tags"
                  />
                  {errorMessage("ribbon_tag", this.state._errorForm)}
                </FormGroup>
              </Col>
              <Col xs={12} md={6}>
                <FormGroup
                  className={classNames({
                    "has-danger has-feedback": hasError(
                      "colour",
                      this.state._errorForm
                    )
                  })}
                >
                  <Label for="formProductColour">Colour</Label>
                  <Input
                    type="text"
                    id="formProductColour"
                    name="colour"
                    placeholder="Enter a Colour*"
                    value={textValue("colour", this.state._form)}
                    onChange={this.handleChange}
                  />
                  {errorMessage("colour", this.state._errorForm)}
                </FormGroup>
              </Col>
              <Col xs={12} md={6}>
                <FormGroup
                  className={classNames({
                    "has-danger has-feedback": hasError(
                      "weight",
                      this.state._errorForm
                    )
                  })}
                >
                  <Label for="formProductWeight">Weight</Label>
                  <Input
                    type="text"
                    id="formProductWeight"
                    name="weight"
                    placeholder="Enter a Weight*"
                    value={textValue("weight", this.state._form)}
                    onChange={this.handleChange}
                  />
                  {errorMessage("weight", this.state._errorForm)}
                </FormGroup>
              </Col>
              <Col xs={12}>
                <FormGroup
                  className={classNames({
                    "has-danger has-feedback": hasError(
                      "SKU",
                      this.state._errorForm
                    )
                  })}
                >
                  <Label for="formProductSKU">SKU</Label>
                  <Input
                    type="text"
                    id="formProductSKU"
                    name="SKU"
                    placeholder="Enter a SKU*"
                    value={textValue("SKU", this.state._form)}
                    onChange={this.handleChange}
                  />
                  {errorMessage("SKU", this.state._errorForm)}
                </FormGroup>
              </Col>
              <Col xs={12} md={6}>
                <FormGroup
                  className={classNames({
                    "has-danger has-feedback": hasError(
                      "sex",
                      this.state._errorForm
                    )
                  })}
                >
                  <Label for="formProductSex">Sex</Label>
                  <Select
                    id="formProductSex"
                    name="sex"
                    value={textValue("sex", this.state._form)}
                    // options={[{value:'U',label:'Unisex'},{value:'F',label:'Female'},{value:'M',label:'Male'},{value:'P',label:'Primary School'},{value:'GS',label:'Grade School'},{value:'IT',label:'Infant Toddler'}]}
                    options={[
                      { value: "F", label: "Female" },
                      { value: "M", label: "Male" },
                      { value: "P", label: "Primary School" },
                      { value: "GS", label: "Grade School" },
                      { value: "IT", label: "Infant Toddler" }
                    ]}
                    onChange={val => {
                      this.handleSelectedChange(val, "sex");
                    }}
                  />
                  {errorMessage("sex", this.state._errorForm)}
                </FormGroup>
              </Col>
              <Col xs={12}>
                <FormGroup
                  className={classNames({
                    "has-danger has-feedback": hasError(
                      "active",
                      this.state._errorForm
                    )
                  })}
                >
                  <Label for="formProductActive">Set to Active &nbsp;</Label>
                  <label className="switch switch-text switch-success-outline-alt">
                    <input
                      type="checkbox"
                      className="switch-input"
                      name="active"
                      id="formProductActive"
                      value="true"
                      checked={textValue("active", this.state._form)}
                      onChange={this.handleCheckedChange}
                    />
                    <span
                      className="switch-label"
                      data-on="Yes"
                      data-off="No"
                    ></span>
                    <span className="switch-handle"></span>
                  </label>
                  {errorMessage("active", this.state._errorForm)}
                </FormGroup>
              </Col>
              <Col xs={12}>
                <FormGroup
                  className={classNames({
                    "has-danger has-feedback": hasError(
                      "auto_sync",
                      this.state._errorForm
                    )
                  })}
                >
                  <Label for="formProductAutoScrape">
                    Set to Auto Scrape &nbsp;
                  </Label>
                  <label className="switch switch-text switch-success-outline-alt">
                    <input
                      type="checkbox"
                      className="switch-input"
                      name="auto_sync"
                      id="formProductAutoScrape"
                      value="true"
                      checked={textValue("auto_sync", this.state._form)}
                      onChange={this.handleCheckedChange}
                    />
                    <span
                      className="switch-label"
                      data-on="Yes"
                      data-off="No"
                    ></span>
                    <span className="switch-handle"></span>
                  </label>
                  {errorMessage("auto_sync", this.state._errorForm)}
                </FormGroup>
              </Col>
              <Col xs={12} md={6}>
                <FormGroup
                  className={classNames({
                    "has-danger has-feedback": hasError(
                      "editors_choice",
                      this.state._errorForm
                    )
                  })}
                >
                  <Label for="formProductEditorChoice">
                    Set to Hot Product &nbsp;
                  </Label>
                  <label className="switch switch-text switch-success-outline-alt">
                    <input
                      type="checkbox"
                      className="switch-input"
                      name="editors_choice"
                      id="formProductEditorChoice"
                      value="true"
                      checked={textValue("editors_choice", this.state._form)}
                      onChange={this.handleCheckedChange}
                    />
                    <span
                      className="switch-label"
                      data-on="Yes"
                      data-off="No"
                    ></span>
                    <span className="switch-handle"></span>
                  </label>
                  {errorMessage("editors_choice", this.state._errorForm)}
                </FormGroup>
              </Col>
              <Col xs={12} md={6}>
                <FormGroup
                  className={classNames({
                    "has-danger has-feedback": hasError(
                      "editors_position",
                      this.state._errorForm
                    )
                  })}
                >
                  <Label for="formProductEditorsPosition">Position</Label>
                  <Input
                    type="number"
                    id="formProductEditorsPosition"
                    name="editors_position"
                    placeholder="Enter a hot product's position"
                    value={textValue("editors_position", this.state._form)}
                    onChange={this.handleChange}
                  />
                  {errorMessage("editors_position", this.state._errorForm)}
                </FormGroup>
              </Col>
              <Col xs={12}>
                <FormGroup
                  className={classNames({
                    "has-danger has-feedback": hasError(
                      "vintage",
                      this.state._errorForm
                    )
                  })}
                >
                  <Label for="formProductVintage">
                    Set to Vintage &nbsp;
                  </Label>
                  <label className="switch switch-text switch-success-outline-alt">
                    <input
                      type="checkbox"
                      className="switch-input"
                      name="vintage"
                      id="formProductVintage"
                      value="true"
                      checked={textValue("vintage", this.state._form)}
                      onChange={this.handleCheckedChange}
                    />
                    <span
                      className="switch-label"
                      data-on="Yes"
                      data-off="No"
                    ></span>
                    <span className="switch-handle"></span>
                  </label>
                  {errorMessage("vintage", this.state._errorForm)}
                </FormGroup>
              </Col>
              <Col xs={12}>
                <FormGroup
                  className={classNames({
                    "has-danger has-feedback": hasError(
                      "biddable",
                      this.state._errorForm
                    )
                  })}
                >
                  <Label for="formProductMakeOffer">
                    Can Make Offer &nbsp;
                  </Label>
                  <label className="switch switch-text switch-success-outline-alt">
                    <input
                      type="checkbox"
                      className="switch-input"
                      name="biddable"
                      id="formProductMakeOffer"
                      value="true"
                      checked={textValue("biddable", this.state._form)}
                      onChange={this.handleCheckedChange}
                    />
                    <span
                      className="switch-label"
                      data-on="Yes"
                      data-off="No"
                    ></span>
                    <span className="switch-handle"></span>
                  </label>
                  {errorMessage("biddable", this.state._errorForm)}
                </FormGroup>
              </Col>
              <Col xs={12}>
                <FormGroup
                className={classNames({
                  "has-danger has-feedback": hasError(
                    "hide_box_info",
                    this.state._errorForm
                  )
                })}
                >
                  <Label for="formProductHideBoxInfo">Hide Box Info &nbsp;</Label>
                  <label className="switch switch-text switch-success-outline-alt">
                    <input
                      type="checkbox"
                      className="switch-input"
                      name="hide_box_info"
                      id="formProductHideBoxInfo"
                      value="true"
                      checked={textValue("hide_box_info", this.state._form)}
                      onChange={this.handleCheckedChange}
                    />
                    <span
                      className="switch-label"
                      data-on="Yes"
                      data-off="No"
                    ></span>
                    <span className="switch-handle"></span>
                  </label>
                  {errorMessage("hide_box_info", this.state._errorForm)}
                </FormGroup>
              </Col>
              <Col xs={12}>
                <FormGroup
                className={classNames({
                  "has-danger has-feedback": hasError(
                    "receive_sell",
                    this.state._errorForm
                  )
                })}
                >
                  <Label for="formProductReceiveSell">Receive Sell Request &nbsp;</Label>
                  <label className="switch switch-text switch-success-outline-alt">
                    <input
                      type="checkbox"
                      className="switch-input"
                      name="receive_sell"
                      id="formProductReceiveSell"
                      value="true"
                      checked={textValue("receive_sell", this.state._form)}
                      onChange={this.handleCheckedChange}
                    />
                    <span
                      className="switch-label"
                      data-on="Yes"
                      data-off="No"
                    ></span>
                    <span className="switch-handle"></span>
                  </label>
                  {errorMessage("receive_sell", this.state._errorForm)}
                </FormGroup>
              </Col>
              <Col xs={12}>
                <FormGroup
                  className={classNames({
                    "has-danger has-feedback": hasError(
                      "voucher_applicable",
                      this.state._errorForm
                    )
                  })}
                >
                  <Label for="formVoucherApplicable">Voucher Applicable &nbsp;</Label>
                  <label className="switch switch-text switch-success-outline-alt">
                    <input
                      type="checkbox"
                      className="switch-input"
                      name="voucher_applicable"
                      id="formVoucherApplicable"
                      value="true"
                      checked={textValue("voucher_applicable", this.state._form)}
                      onChange={this.handleCheckedChange}
                    />
                    <span
                      className="switch-label"
                      data-on="Yes"
                      data-off="No"
                    ></span>
                    <span className="switch-handle"></span>
                  </label>
                  {errorMessage("voucher_applicable", this.state._errorForm)}
                </FormGroup>
              </Col>
              <Col xs={12} md={12}>
                <FormGroup>
                  <Label for="formProductDetail">Details</Label>
                  <RichTextEditor
                    value={this.state._form.detailsValue}
                    onChange={this.handleChangeEditor}
                    toolbarConfig={toolbarConfig}
                  />
                  {/* <div>{this.state._form.details}</div> */}
                </FormGroup>
              </Col>
              <Col xs={12} md={12}>
                <FormGroup
                  className={classNames({
                    "has-danger has-feedback": hasError(
                      "price_source",
                      this.state._errorForm
                    )
                  })}
                >
                  <Label for="formProductPriceSource">Price Source</Label>
                  <Input
                    type="text"
                    id="formProductPriceSource"
                    name="price_source"
                    placeholder="Enter a price source"
                    value={textValue("price_source", this.state._form)}
                    onChange={this.handleChange}
                  />
                  {errorMessage("price_source", this.state._errorForm)}
                </FormGroup>
              </Col>
              <Col xs={12} md={6}>
                <FormGroup
                  className={classNames({
                    "has-danger has-feedback": hasError(
                      "retail_price",
                      this.state._errorForm
                    )
                  })}
                >
                  <Label for="formProductRetailPrice">Retail Price</Label>
                  <Input
                    type="text"
                    id="formProductRetailPrice"
                    name="retail_price"
                    placeholder="Enter a retail price"
                    value={textValue("retail_price", this.state._form)}
                    onChange={this.handleChange}
                  />
                  {errorMessage("retail_price", this.state._errorForm)}
                </FormGroup>
              </Col>
              <Col xs={12} md={6}>
                <FormGroup
                  className={classNames({
                    "has-danger has-feedback": hasError(
                      "currency",
                      this.state._errorForm
                    )
                  })}
                >
                  <Label for="formProductCurrency">Currency</Label>
                  <Input
                    type="text"
                    id="formProductCurrency"
                    name="currency"
                    placeholder="Enter a retail price currency"
                    value={textValue("currency", this.state._form)}
                    onChange={this.handleChange}
                  />
                  {errorMessage("currency", this.state._errorForm)}
                </FormGroup>
              </Col>
              <Col xs={12} md={6}>
                <FormGroup
                  className={classNames({
                    "has-danger has-feedback": hasError(
                      "release_date",
                      this.state._errorForm
                    )
                  })}
                >
                  <Label for="formProductReleaseDate">Release Date</Label>
                  <InputDatetime
                    inputProps={{ placeholder: "Pick a Release Date" }}
                    value={dateValue("release_date", this.state._form)}
                    onChange={date =>
                      this.handleDatePickerChange(date, "release_date")
                    }
                    timeFormat="HH:mm"
                  />
                  {errorMessage("release_date", this.state._errorForm)}
                </FormGroup>
              </Col>
              <Col xs={12} md={6}>
                <FormGroup
                  className={classNames({
                    "has-danger has-feedback": hasError(
                      "expiry_date",
                      this.state._errorForm
                    )
                  })}
                >
                  <Label for="formProductExpiryDate">Expiry Date</Label>
                  <InputDatetime
                    inputProps={{ placeholder: "Pick a Expiry Date" }}
                    value={dateValue("expiry_date", this.state._form)}
                    onChange={date =>
                      this.handleDatePickerChange(date, "expiry_date")
                    }
                    timeFormat="HH:mm"
                  />
                  {errorMessage("expiry_date", this.state._errorForm)}
                </FormGroup>
              </Col>
            </Row>
            <FormGroup>
              <ButtonLoading isLoading={this.props.isSubmit}>
                Submit
              </ButtonLoading>
            </FormGroup>
          </BSForm>
        </CardBody>
      </Card>
    );
  }
}

const toolbarConfig = {
  // Optionally specify the groups to display (displayed in the order listed).
  display: [
    "INLINE_STYLE_BUTTONS",
    "BLOCK_TYPE_BUTTONS",
    "BLOCK_TYPE_DROPDOWN",
    "HISTORY_BUTTONS"
  ],
  INLINE_STYLE_BUTTONS: [
    { label: "Bold", style: "BOLD", className: "custom-css-class" },
    { label: "Italic", style: "ITALIC" },
    { label: "Underline", style: "UNDERLINE" }
  ],
  BLOCK_TYPE_DROPDOWN: [
    { label: "Normal", style: "unstyled" },
    { label: "Heading Large", style: "header-one" },
    { label: "Heading Medium", style: "header-two" },
    { label: "Heading Small", style: "header-three" }
  ],
  BLOCK_TYPE_BUTTONS: [
    { label: "UL", style: "unordered-list-item" },
    { label: "OL", style: "ordered-list-item" }
  ]
};

export default Form;
