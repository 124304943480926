import React from 'react';
import propTypes from 'prop-types';
import Th from './Th';

const TblHead = ({ dataHeader, renderChildren, children }) => (
  <thead>
    {
      renderChildren? renderChildren(): dataHeader.length? (
        <tr>
          {
            dataHeader.map((item, index)=><Th key={index}>{item}</Th>)
          }
        </tr>
      ): null
    }
    { children }
  </thead>
)

export default TblHead;

TblHead.propTypes = {
  dataHeader: propTypes.arrayOf(propTypes.oneOfType([propTypes.string, propTypes.node])),
  renderChildren: propTypes.func,
  children: propTypes.node
}

TblHead.defaultProps = {
  dataHeader: []
}
