import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { saveProductAddon } from '../../../actions/productAddonAction';
import { Radio } from '../../../components/Form/Radios';
import DateToHuman from '../../../components/DateToHuman';
import { TextRupiah } from '../../../components/Text';

class RowItem extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      activeEdit: props.active,
      active: props.active,
      updatedAt: props.updated_at,
      body: {
        ...this.obtainOtherData(props)
      }
    }
  }

  obtainOtherData = data => {
    return {
      name: data.name,
      display_name: data.display_name,
      description: data.description,
      img_url: data.img_url,
      price: data.price,
      order: data.order,
    }
  }
  componentDidUpdate(prevProps){
    if(prevProps.dataProductAddon.isSubmit && !this.props.dataProductAddon.isSubmit){
      const { data: {id, ...data}, error } = this.props.dataProductAddon;
      if(!error && id === this.props.id){
        this.setState({
          active: data.active,
          activeEdit: data.active,
          updatedAt: data.updated_at,
          body: {
            ...this.obtainOtherData(data)
          }
        })
      }
      else if(error){
        this.setState({
          activeEdit: this.state.active
        })
      }
    }
  }

  shouldComponentUpdate(nextProps){
    const { dataProductAddon } = this.props;
    const { dataProductAddon: nextProdAddon } = nextProps;
    if(nextProdAddon.isSubmit && !dataProductAddon.isSubmit){
      return false;
    }
    if(nextProdAddon.isSubmit !== dataProductAddon.isSubmit && nextProdAddon.data && nextProdAddon.data.id !== this.props.id){
      return false;
    }
    return true;
  }

  handleChange = async (e) => {
    await this.setState({
      [e.target.name]: e.target.type === 'checkbox'? e.target.checked: e.target.value
    })
    const { id } = this.props;
    const { activeEdit, body } = this.state;
    const payloads = {
      id,
      ...body,
      active: activeEdit
    }
    this.props.saveProductAddon(payloads)
  }

  render(){
    return(
      <tr>
        <th className="v-center" scope="row">{ this.props.rowNumber }</th>
        <td className="v-center">
          {this.props.name}
        </td>
        <td className="v-center">
          <TextRupiah prefix="Rp." block value={this.props.price}/>
        </td>
        <td className="v-center">
          <Radio
            type="checkbox"
            name="activeEdit"
            value={true}
            checked={this.state.activeEdit}
            dataOnText="Yes"
            dataOffText="No"
            onChange={this.handleChange}
          />
        </td>
        <td className="v-center">
          <DateToHuman  value={this.state.updatedAt} id={`tooltip_updated_at_${this.props.rowNumber}`}/>
        </td>
        <td className="v-center">
          <Link to={`/product_addons/${this.props.id}`} className="btn btn-link">Details</Link>
        </td>
      </tr>
    )
  }
}

const mapStateToProps=({ productAddon: { details: dataProductAddon } })=>({
  dataProductAddon,
})
const mapDispatchToProps=(dispatch)=>({
  saveProductAddon: (payloads) => dispatch(saveProductAddon(payloads))
})
export default connect(mapStateToProps, mapDispatchToProps)(RowItem);