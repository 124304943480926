import React from "react"
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Row,
  Col,
  Card,
  CardText,
  CardSubtitle,
} from "reactstrap"
import styled from "styled-components"

import {
  KA_RECEIVED_STATUS,
  VERIFICATION_FAILED_STATUS,
  VERIFICATION_PASSED_STATUS,
  CANCELLED_STATUS,
  DONE_BY_ADMIN_STATUS,
  REFUNDED_STATUS,
  SELLER_SENT_STATUS,
  DELIVERING_STATUS,
} from "../../../../constants/kickavenue"
import AlertNotFound from "../../../Card/AlertNotFound"
import moment from "moment-timezone"
import { Link } from "react-router-dom"

const CardTitleStyled = styled.p`
  font-size: 0.75rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
`

const translateType = (type) => {
  switch (type) {
    case SELLER_SENT_STATUS:
      return "Seller Sent"
    case KA_RECEIVED_STATUS:
      return "KA Received."
    case VERIFICATION_PASSED_STATUS:
      return "Verification Passed"
    case VERIFICATION_FAILED_STATUS:
      return "Verification Failed"
    case CANCELLED_STATUS:
      return "Cancelled"
    case REFUNDED_STATUS:
      return "Refunded"
    case "PENDING_DELIVERING":
      return "Pending Delivering."
    case DELIVERING_STATUS:
      return "KA Sent Buyer"
    case "KA_RETURNED_SELLER":
      return "Return to Seller"
    case DONE_BY_ADMIN_STATUS:
      return "Done by Admin."
    case "DONE_BY_BUYER":
      return "Done by Buyer."
    case "DONE_BY_CRON":
      return "Done by System."
    default:
      return "Undefined"
  }
}

const renderLogs = (item) => {
  if (item.after.hasOwnProperty("seller_responded")) {
    return (
      <>
        <CardTitleStyled style={{ fontSize: "14px" }}>
          {item.after.status === "CANCELLED" ? "Cancelled" : "Confirmed"} by
          Seller
        </CardTitleStyled>
        <CardSubtitle style={{ fontSize: "13px", marginBottom: ".5rem" }}>
          by{" "}
          <Link to={`/users/${item.user_id}`}>
            {item.after.updated_by_email || item.after.updated_by}
          </Link>
        </CardSubtitle>
      </>
    )
  } else if (item.after.hasOwnProperty("status")) {
    if (
      ["DONE_BY_ADMIN", "DONE_BY_BUYER", "DONE_BY_CRON"].includes(
        item.after.status
      )
    ) {
      return (
        <>
          <CardTitleStyled style={{ fontSize: "14px" }}>
            Sale {translateType(item.after.status)}
          </CardTitleStyled>
          <CardSubtitle style={{ fontSize: "13px", marginBottom: ".5rem" }}>
            by{" "}
            {item.user_id ? (
              <Link to={`/users/${item.user_id}`}>
                {item.after.updated_by_email}
              </Link>
            ) : (
              "System"
            )}
          </CardSubtitle>
        </>
      )
    } else {
      if(item.after.hijacked){
        return (
          <>
            <CardTitleStyled style={{ fontSize: "14px" }}>
              Hijack sale by {item.after.updated_by_email}
            </CardTitleStyled>
            <CardSubtitle style={{ fontSize: "13px", marginBottom: ".5rem" }}>
               Old Seller : {item.before.old_seller??''} <br/>
               New Seller : {item.after.new_seller??''}
            </CardSubtitle>
          </>
        )
      } else{
        return (
          <>
            <CardTitleStyled style={{ fontSize: "14px" }}>
              {item.after.rollback && item.after.rollback ? 'Rollback status to ' : 'Changed status to '}  
              {translateType(item.after.status)}
            </CardTitleStyled>
            <CardSubtitle style={{ fontSize: "13px", marginBottom: ".5rem" }}>
              by{" "}
              <Link to={`/users/${item.user_id}`}>
                {item.after.ka_received_by ||
                  item.after.ka_verified_by ||
                  item.after.updated_by_email ||
                  "Admin"}
              </Link>
            </CardSubtitle>
          </>
        )
      }
    }
  } else if (item.after.hasOwnProperty("ka_sent")) {
    return (
      <>
        <CardTitleStyled style={{ fontSize: "14px" }}>
          Sent by KA
        </CardTitleStyled>
        <CardSubtitle style={{ fontSize: "13px", marginBottom: ".5rem" }}>
          by{" "}
          <Link to={`/users/${item.user_id}`}>
            {item.after.updated_by_email}
          </Link>
        </CardSubtitle>
      </>
    )
  } else if (item.after.hasOwnProperty("autodisburse")) {
    return (
      <>
        <CardTitleStyled style={{ fontSize: "14px" }}>
          Changed autodisburse to{" "}
          {item.after.autodisburse === true ? "ON" : "OFF"}
        </CardTitleStyled>
        <CardSubtitle style={{ fontSize: "13px", marginBottom: ".5rem" }}>
          by{" "}
          <Link to={`/users/${item.user_id}`}>
            {item.after.updated_by_email}
          </Link>
        </CardSubtitle>
      </>
    )
  } else if (item.after.hasOwnProperty("marked")) {
    return (
      <>
        <CardTitleStyled style={{ fontSize: "14px" }}>
          {item.after.marked === true ? (
            <>
              Set notes:{" "}
              <span style={{ fontSize: "13px", fontWeight: "500" }}>
                "{item.after.marked_notes}"
              </span>
            </>
          ) : (
            <>Set notes as solved</>
          )}
        </CardTitleStyled>
        <CardSubtitle style={{ fontSize: "13px", marginBottom: ".5rem" }}>
          by{" "}
          <Link to={`/users/${item.user_id}`}>
            {item.after.updated_by_email}
          </Link>
        </CardSubtitle>
      </>
    )
  } else if (item.after.hasOwnProperty("follow_up_status")) {
    return (
      <>
        <CardTitleStyled style={{ fontSize: "14px" }}>
          Changed to follow up with status {item.after.follow_up_status}
        </CardTitleStyled>
        <CardSubtitle style={{ fontSize: "13px", marginBottom: ".5rem" }}>
          by{" "}
          <Link to={`/users/${item.user_id}`}>
            {item.after.updated_by_email}
          </Link>
        </CardSubtitle>
      </>
    )
  } else if (item.after.hasOwnProperty("seller_points")) {
    return (
      <>
        <CardTitleStyled style={{ fontSize: "14px" }}>
          Seller Points : {item.after.seller_points}
        </CardTitleStyled>
        <CardSubtitle style={{ fontSize: "10px", marginBottom: ".5rem" }}>
          {item.after.seller_roles}
        </CardSubtitle>
      </>
    )
  } else if (item.after.hasOwnProperty("auto_cancelled_at")) {
    return (
      <>
        <CardTitleStyled style={{ fontSize: "14px" }}>
          Auto Cancel Turned : {item.after?.auto_cancelled_at ? "On" : "Off"}
        </CardTitleStyled>
        <CardSubtitle style={{ fontSize: "13px", marginBottom: ".5rem" }}>
          by{" "}
          <Link to={`/users/${item.user_id}`}>
            {item.after.updated_by_email}
          </Link>
        </CardSubtitle>
      </>
    )
  }
}
export default class ModalSaleLogs extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      data: [],
      isFetched: false,
    }
  }

  componentDidUpdate(prevProps) {
    if (
      (this.props.data && this.props.data.length && !this.state.isFetched) ||
      (prevProps.data && prevProps.data.length !== this.props.data.length)
    ) {
      let newData = []
      this.props.data.map((item) => {
        let newRaw = {
          ...item,
          after: JSON.parse(item.after),
          before: JSON.parse(item.before),
        }
        newData.push(newRaw)
      })

      this.setState({ data: newData, isFetched: true })
    }
  }

  render() {
    return (
      <Modal isOpen={this.props.isOpen} toggle={this.props.toggle}>
        <ModalHeader toggle={this.props.toggle}>Sales Journals</ModalHeader>
        <ModalBody>
          <CardText>Showing you up your sales logs.</CardText>
          <p className="text-right">
            Showing <b>{this.state.data.length}</b> logs
          </p>
          <Row style={{ maxHeight: 340, overflow: "auto" }}>
            {this.state.data.length ? (
              this.state.data.map((item, index) => (
                <Col xs={12} key={index}>
                  <Card
                    body
                    style={{ marginBottom: ".75rem", padding: ".75rem 1rem" }}
                  >
                    {renderLogs(item)}
                    <CardText
                      style={{
                        marginBottom: 0,
                        fontSize: "12px",
                        marginTop: ".5rem",
                      }}
                    >
                      <strong>Created: </strong>{" "}
                      {moment(item.created_at).format("D MMMM YYYY - HH:mm")}{" "}
                      <br />
                    </CardText>
                  </Card>
                </Col>
              ))
            ) : (
              <Col xs={12}>
                <AlertNotFound />
              </Col>
            )}
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={this.props.toggle}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    )
  }
}
