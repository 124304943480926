import React from 'react';
import { Link } from 'react-router-dom';
import { Form, FormGroup, Label, Input, Row, Col, Badge } from 'reactstrap';

const Detail = (props) => {
	return (
		<Form>
			<Row>
				<Col xs={12} md={6}>
					<FormGroup>
						<Label for="formProductBrand">Brands</Label>
						<div style={{fontSize:18}}>
							{
								props.product.brand_id?
								<div>
									{
										props.product.brand.ancestors.map((parent)=>(<Badge key={`badge-${parent.id}`} style={{marginRight:15}} pill><Link to={`/brands/${parent.id}`} style={{color:'#FFF'}}>{parent.name}</Link></Badge>))
									}
									<Badge pill><Link to={`/brands/${props.product.brand.id}`} style={{color:'#FFF'}} >{props.product.brand.name}</Link></Badge>
								</div>
								:''
							}
						</div>
					</FormGroup>
				</Col>
				<Col xs={12} md={6}>
					<FormGroup>
						<Label for="formProductCategory">Category</Label>
						<Input
							type="text"
							id="formProductCategory"
							readOnly
							value={props.product.category_id?props.product.category.name: 'No Category'}
						/>
					</FormGroup>
				</Col>
			</Row>
			<Row>
				<Col xs={12} md={6}>
					<FormGroup>
						<Label for="formProductName">Product Name</Label>
						<Input
							type="text"
							id="formProductName"
							readOnly
							value= {props.product.name }
						/>
					</FormGroup>				
				</Col>
				<Col xs={12} md={6}>
					<FormGroup>
						<Label for="formDisplayName">Display Name</Label>
						<Input
							type="text"
							id="formProductName"
							readOnly
							value= {props.display_name }
						/>
					</FormGroup>				
				</Col>
			</Row>
			<Row>
				<Col xs={12} md={6}>
					<FormGroup>
						<Label for="formProductType">Type</Label>
						<Input
							type="text"
							id="formProductType"
							readOnly
							value={props.type}
						/>
					</FormGroup>
				</Col>
				<Col xs={12} md={6}>
					<FormGroup>
						<Label for="formProductNickname">Nickname</Label>
						<Input
							type="text"
							id="formProductNickname"
							readOnly
							value={props.nickname}
						/>
					</FormGroup>
				</Col>
			</Row>
			<Row>
				<Col xs={12} md={6}>
					<FormGroup>
						<Label for="formProductColour">Weight</Label>
						<Input
							type="text"
							id="formProductColour"
							readOnly
							value={props.weight}
						/>
					</FormGroup>
				</Col>
				<Col xs={12} md={6}>
					<FormGroup>
						<Label for="formProductColour">Colour</Label>
						<Input
							type="text"
							id="formProductColour"
							readOnly
							value={props.colour}
						/>
					</FormGroup>
				</Col>
			</Row>
			<Row>
				<Col xs={12} md={6}>
					<FormGroup>
						<Label for="formProductSku">SKU</Label>
						<Input
							type="text"
							id="formProductSku"
							readOnly
							value={props.SKU}
						/>
					</FormGroup>
				</Col>
				<Col xs={12} md={6}>
					<FormGroup>
						<Label for="formProductSku">Others</Label>
						<div className="col-md-9">
							<label className="checkbox-inline" htmlFor="inline-checkbox1">
								<input type="checkbox" id="inline-checkbox1" name="inline-checkbox1" value="true" readOnly checked={props.active}/> Active &nbsp;
							</label>
								<label className="checkbox-inline" htmlFor="inline-checkbox2">
							<input type="checkbox" id="inline-checkbox2" name="inline-checkbox2" value="true" readOnly checked={props.editor_choice}/> Editor Choises &nbsp;
							</label>
					  	</div>
					</FormGroup>					
				</Col>
			</Row>
			<Row>
				<Col xs={12} md={6}>
					<FormGroup>
						<Label for="formProductReleaseDate">Release Date</Label>
						<Input
							type="text"
							id="formProductReleaseDate"
							readOnly
							value={props.release_date}
						/>
					</FormGroup>
				</Col>
				<Col xs={12} md={6}>
					<FormGroup>
						<Label for="formProductReleaseDate">Expiry Date</Label>
						<Input
							type="text"
							id="formProductReleaseDate"
							readOnly
							value={props.expiry_date}
						/>
					</FormGroup>
				</Col>
			</Row>								
		</Form>
	)
}
export default Detail;
