import styled from 'styled-components';

const FieldRow = styled.div`
  display:flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
  > hr {
    width: 100%;
  }
`
export default FieldRow;