import React from 'react';
import propTypes from 'prop-types';
import {
  Card,
  CardText,
} from 'reactstrap';
import { getCacheImage } from '../../../../utils/imageHelper';
import noImage from  '../../../../assets/img/no-image.png';
import ConciergeDetailCard from './ConciergeDetailCard';

const getPublicImageUrl = image => {
  return image.signed_url || image.URL
}

const TabConciergeDetail = props => {
  const conciergeImage = props.details && props.details.concierge_images && props.details.concierge_images.length>0?
    getCacheImage(getPublicImageUrl(props.details.concierge_images[0])) : noImage;
  return (
    <Card body className="tab-content--custom__card">
      <CardText>About their's concierge.</CardText>
        <ConciergeDetailCard
          id={props.id}
          isLoading={props.isLoading}
          isSubmit={props.isSubmit}
          error={props.error}
          details={props.details}
          user={props.details.user}
          dataVoucher={props.dataVoucher}
          provinceId={props.provinceId}
          dataProvinces={props.dataProvinces}
          dataCouriers={props.dataCouriers}
          conciergeImage={conciergeImage||noImage}
          updateConcierge={props.updateConcierge}
          applyVoucher={props.applyVoucher}
          getShippingFee={props.getShippingFee}
        />
    </Card>
  )
}

export default TabConciergeDetail;
TabConciergeDetail.defaultProps = {
  isLoading: false,
  dataConcierge:null
}

TabConciergeDetail.propTypes = {
  isLoading: propTypes.bool,
  dataConcierge: propTypes.object,
}