import {createContext} from "react";

export const PaymentContext = createContext({
    product: [],
    setProduct: () => {},
    shippingFee: 0,
    setShippingFee: () => {},
    discount: 0,
    setDiscount: () => {},
    voucherApplied: false,
    setVoucherApplied: () => {},
    voucher: null,
    setVoucher: () => {},
    isCashback: false,
    setIsCashback: () => {},
    subsidy: 0,
    setSubsidy: () => {},
    walletAmount: 0,
    setWalletAmount: () => {},
    credit: 0,
    setCredit: () => {},
    point: 0,
    setPoint: () => {},
    offerAmount: 0,
    setOfferAmount: () => {},
    amountReceived: 0,
    setAmountReceived: () => {},
    total: 0,
    setTotal: () => {},
    totalAdjusted: 0,
    setTotalAdjusted: () => {},
})
