import React, { PureComponent as Component } from 'react';
import {
  Card,
  CardHeader,
  CardBody,
  CardImg,
  Badge
} from 'reactstrap';
import Loading from 'react-loading-animation';
import propTypes from 'prop-types';
import { _getValue, _getGenderString } from '../../../utils/form';
import noImage from  '../../../assets/img/no-image.png';
import {
  FieldRow,
  FieldColumn,
  FieldLabel,
  FieldValue
} from '../../../styled/Fields';
import { getCacheImage } from '../../../utils/imageHelper'

const NO_IMAGE_ALT ="NO IMAGE FOUND";

const getPublicImageUrl = image => {
  return image.signed_url || image.image_url
}

export default class Sneaker extends Component {
  _renderContent() {
    return(
      <div>
        <FieldRow>
          <FieldColumn className="col-xs-12 col-md-6">
            <FieldLabel>Product ID </FieldLabel>
            <FieldValue> <span>{_getValue(this.props.product.product_variant_id)}</span> </FieldValue>
          </FieldColumn>
        </FieldRow>
        <FieldRow>
          <FieldColumn className="col-xs-12 col-md-6">
            <FieldLabel>Product</FieldLabel>
            <FieldValue>
              <span>{_getValue(this.props.product.product_name)}</span>
            </FieldValue>
          </FieldColumn>
          <FieldColumn className="col-xs-12 col-md-6">
            <FieldLabel>Type</FieldLabel>
            <FieldValue>
              <span>{_getValue(this.props.product.type)}</span>
            </FieldValue>
          </FieldColumn>
          <FieldColumn className="col-12">
            <FieldLabel>Display Name</FieldLabel>
            <FieldValue>
              <span>{_getValue(this.props.product.display_name)}</span>
            </FieldValue>
          </FieldColumn>
          <FieldColumn className="col-12">
            <FieldLabel>Brand</FieldLabel>
            <FieldValue>
              <span>{_getValue(this.props.product.brand_name)}</span>
            </FieldValue>
          </FieldColumn>
          <FieldColumn className="col-xs-12 col-md-6">
            <FieldLabel>Category</FieldLabel>
            <FieldValue>
              <span>{_getValue(this.props.product.category_name)}</span>
            </FieldValue>
          </FieldColumn>
          <FieldColumn className="col-12">
            <FieldLabel>Tags</FieldLabel>
            <FieldValue>
              <span>{_getValue(this.props.product.nickname)}</span>
            </FieldValue>
          </FieldColumn>
          <FieldColumn className="col-12">
            <FieldLabel>Colour</FieldLabel>
            <FieldValue>
              <span>{_getValue(this.props.product.colour)}</span>
            </FieldValue>
          </FieldColumn>
          <FieldColumn className="col-xs-12 col-md-6">
            <FieldLabel>SKU</FieldLabel>
            <FieldValue>
              <span>{_getValue(this.props.product.SKU)}</span>
            </FieldValue>
          </FieldColumn>
          <FieldColumn className="col-xs-12 col-md-6">
            <FieldLabel>Weight (gram)</FieldLabel>
            <FieldValue>
              <span>{_getValue(this.props.product.weight)}</span>
            </FieldValue>
          </FieldColumn>
          <FieldColumn className="col-xs-12 col-md-6">
            <FieldLabel>Gender</FieldLabel>
            <FieldValue>
              <span>{_getGenderString(this.props.product.sex)}</span>
            </FieldValue>
          </FieldColumn>
        </FieldRow>
      </div>
    )
  }
  render(){
    return(
      <Card>
        <CardHeader>
          {this.props.headerText}
          &nbsp;
          <span style={{fontSize:15}}>
            <Badge>QTY <Badge color="danger">{this.props.product.quantity}</Badge></Badge>
          </span>
        </CardHeader>
        <CardImg src={ getCacheImage(getPublicImageUrl(this.props.product)) || noImage} alt={this.props.product.image_url?this.props.product.display_name:NO_IMAGE_ALT}/>
        <CardBody>
          {
            !this.props.isLoading && this._renderContent() || <Loading/>
          }
        </CardBody>        
      </Card>
    )
  }
}
Sneaker.propTypes={
  headerText: propTypes.string,
  isLoading: propTypes.bool,
  product: propTypes.shape({
    sale_id: propTypes.number.isRequired,
    display_name: propTypes.string,
    quantity: propTypes.number,
    image_url: propTypes.string,
    pre_verified: propTypes.oneOfType([propTypes.number,propTypes.bool]),
    pre_order: propTypes.oneOfType([propTypes.number,propTypes.bool])
  })
}
Sneaker.defaultProps={
  headerText:"Sneaker",
  isLoading: false,
  product: {
    sale_id: 0,
    display_name: "",
    quantity: 0,
    image_url: null,
    pre_verified: false,
    pre_order: false
  }
}