import React from 'react';
import propTypes from 'prop-types';
import { SeekContentTitle } from '../styled';
import { sortBySizeAsc } from '../selectors';
import TextRupiah from '../../../components/Text/TextRupiah';

const SeekSizeList = ({ items }) => {
  const sizeStr = !items.length ? '-': items
    .filter(({ quantity }) => quantity > 0)
    .map(({ size }) => size)
    .sort(sortBySizeAsc)
    .reduce((curr, size) => curr+ ` - ${size}`, "")
    .trim()
    .replace(/^\-/,'');
  const totalQty = items.map(({ quantity }) => quantity)
    .reduce((curr, qty) => curr + qty, 0)
  const minPrice = !items.length? '-' : items.filter(item => item.quantity > 0)
    .map(({ price }) => price)
    .sort()[0]
  return(
    <div>
      <div style={{display: 'flex', alignItems: 'center'}}>
        <SeekContentTitle>Total</SeekContentTitle>
        <div>{totalQty}</div>
      </div>
      <div style={{display: 'flex', alignItems: 'center'}}>
        <SeekContentTitle>Size</SeekContentTitle>
        <div>{sizeStr === ''? '-': sizeStr }</div>
      </div>
      <div style={{display: 'flex', alignItems: 'center'}}>
        <SeekContentTitle>Min. Price</SeekContentTitle>
        <div>{ !items.length? '-' : <TextRupiah prefix="Rp." value={minPrice}/> }</div>
      </div>
    </div>
  )
}

export default SeekSizeList;

SeekSizeList.propTypes = {
  items: propTypes.array
}

SeekSizeList.defaultProps ={
  items: []
}