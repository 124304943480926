import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import qs from 'query-string';
import { Card, Row, Col, CardBody } from 'reactstrap';
import Paginations from '../../../../components/Paginations';
import PaginationSummaries from '../../../../components/Paginations/PaginationSummaries';
import FilterContainer from '../../../../components/Container/FilterContainer/FilterContainer';
import { CardHeaderWithToolbars, Title } from '../../../../components/Card/CardHeaderWithToolbars';
import { Tbl, TblHead, TblBody } from '../../../../components/Tbl';
import { DEFAULT_SORT_BY, DEFAULT_PER_PAGE } from '../../../../constants/settings';
import  {
  NEW_STATUS,
  SELLER_SENT_STATUS,
	CANCELLED_STATUS,
	KA_RECEIVED_STATUS,
	VERIFICATION_PASSED_STATUS,
	VERIFICATION_FAILED_STATUS,
  DELIVERING_STATUS,
  REFUNDED_STATUS
} from '../../../../constants/kickavenue';
import { RowHeader, RowItem } from '../TblRow';
import { ModalInfoCreditCard } from '../../../../components/Modals';
import ModalPurchasePrice from '../../ModalPurchasePrice';
import ModalFollowUp from '../../ModalFollowUp';
import FiltersPanel from '../../Filters';
import { getOptionList as getCategoryOptionList } from '../../../../actions/categoriesAction';
import { getFollowUpSales, setFollowUpSale } from '../../../../actions/saleAction';
import { generateNumber } from '../../../../utils';

import Toolbars from '../Filters/Toolbars';

const generateDescStatus = item =>{
  if(item.status===NEW_STATUS){
    if(item.seller_responded)
      return 'Waiting Seller Delivering';
    return 'Waiting Seller Confirmation';
  }
  else if(item.status===SELLER_SENT_STATUS && item.seller_sent!=null){
    return 'Sneaker On The Way';
  }
  else if(item.status===KA_RECEIVED_STATUS && item.ka_received!=null){
    return 'Verification Progress';
  }
  else if(item.status===VERIFICATION_PASSED_STATUS && item.ka_verified!=null){
    return 'Verification Passed';
  }
  else if(item.status===VERIFICATION_FAILED_STATUS){
    return 'Verification Failed';
  }
  else if(item.status===DELIVERING_STATUS &&item.ka_sent!=null){
    return 'On Delivering';
  }
  else if(item.status===CANCELLED_STATUS){
    return item.force_cancelled_at!=null? 'Cancelled (Forced)': 'Cancelled';
  }
  else if(item.status===REFUNDED_STATUS && item.money_disbursed!=null){
    return 'Refunded';
  }
  else if( (/^DONE_\w+/).test(item.status)||item.completed)
    return 'Done';
  return 'Undefined';
}

const defaultParams = {
  sort_by : DEFAULT_SORT_BY,
  per_page : DEFAULT_PER_PAGE
}

class AllFollowUp extends Component{
  constructor(props) {
    super(props);
    const DEFAULT_STATE = {
      activeTab: null,
      keyword: null
    }
    const queries = Object.assign(DEFAULT_STATE,qs.parse(props.location.search));
    let queryParams = {};
    if(queries.activeTab === 'followUpList'){
      queryParams.keyword = queries.keyword
    }
    this.state = {
      _filterOpen: false,
      // _exportOpen: false,
      _modalCreditCard: false,
      _invoiceNumber: null,
      _dataModalPurchasePrice: null,
      _dataModalFollowUp: null,
      _isModalFollowUp: false,
      _isModalPurchasePrice: false,
      _params: {
        ...defaultParams,
        ...queryParams
      }
    }
  }

  _getList = (rawParams = {}) => {
    let p = { ...rawParams }
    if(p.voucher){
      const { voucher, ...other } = p;
      if(Number(voucher.id)){
        p = { ...other, voucher_id: voucher.id }
      }
      else{
        p = { ...other, voucher_code: voucher.code }
      }
    }
    if(p.seller && p.seller.id){
      const { seller, ...other } = p;
      p = { ...other, seller_id: seller.id }
    }
    const params = Object.keys(p)
    .filter(key => p[key]!='')
    .reduce((obj, current)=> ({...obj, [current]: p[current]}), {});
    this.props.getFollowUpSales({params});
  }

  _handleRefresh = e => {
    e.preventDefault();
    this._refreshList();
  }

  _refreshList = () => {
    // Exclude page...
    const { page, ..._params } = this.state._params;
    this.setState({
      _params:{ ..._params }
    },()=>this._getList(this.state._params));
  }

  componentDidMount(){
    this._getList(this.state._params);
    if(!this.props.categoryOptions.length){
      this.props.bindCategoryOption();
    }
  }

  _handleCategoryChanged= async (category) => {
    await this.setState({
      _params: {
        ...this.state._params,
        category: category === ''? category: category.value,
        page: 1,
      },
    });
    const { _params } = this.state;
    this._getList(_params);
  }

  onMarkedClick = async (value) => {
    await this.setState({
      _params: {
        ...this.state._params,
        marked: value? 1: 0,
        page: 1,
      },
    });
    const { _params } = this.state;
    this._getList(_params);
  }

  _handleApplyFilter = (payloadFilter) =>{
    this.setState({
      _params: {
        ...this.state._params,
        ...payloadFilter,
        page: 1
      }
    },()=>this._getList(this.state._params));
  }

  _handleGoto = page => {
    const _params=this.state._params;
    _params.page=page;
    this.setState({_params},()=>this._getList(this.state._params));
  }

  _onOpenCreditCardModal = item => {
    this.setState({_invoiceNumber: item.invoice_number},()=>this._handleToggleModalCreditCard());
  }

  _handleToggleModalCreditCard = () => {
    this.setState({_modalCreditCard: !this.state._modalCreditCard});
  }

  _togglePurchasePriceModal = (value, payloads = null) => {
    this.setState({
      _isModalPurchasePrice: value,
      _dataModalPurchasePrice: payloads? { ...payloads }: null
    })
  }

  _toggleFollowUpModal = (value, payloads = null) => {
    this.setState({
      _isModalFollowUp: value,
      _dataModalFollowUp: payloads? { ...payloads }: null
    })
  }

  _handleToggleFilterPanel = e =>{
    e.preventDefault();
    this._toggleFilterPanel();
  }

  _toggleFilterPanel = () => {
    const exportState = this.state._exportOpen?{_exportOpen:!this.state._exportOpen}:{};
    this.setState({
      ...exportState,
      _filterOpen:!this.state._filterOpen});
  }

  _handleResetFilter = e => {
    e.preventDefault();
    const newState = this.state._filterOpen?{_filterOpen:!this.state._filterOpen}:{};
    const exportState = this.state._exportOpen?{_exportOpen:!this.state._exportOpen}:{};
    this.setState({
      ...newState,
      ...exportState,
      _params:{ ...defaultParams },
      _isResetFilter: true
    },()=>this._getList(this.state._params));
  }
  
  render(){
    return(
      <div className="animated fadeIn">
        <ModalPurchasePrice
          data={this.state._dataModalPurchasePrice}
          isOpen={this.state._isModalPurchasePrice}
          toggle={() => this._togglePurchasePriceModal(false)}
        />
        <ModalFollowUp
          data={this.state._dataModalFollowUp}
          isOpen={this.state._isModalFollowUp}
          toggle={() => this._toggleFollowUpModal(false)}
        />
        <ModalInfoCreditCard
          isOpen={this.state._modalCreditCard}
          toggle={this._handleToggleModalCreditCard}
          invoiceNumber={this.state._invoiceNumber}
        />
        <Row>
          <Col xs={12}>
            <Card>
              <CardHeaderWithToolbars>
                <Title>
                  <i className="fa fa-align-justify"></i>
                  <span>All List</span>
                </Title>
                <Toolbars
                  isOpen={this.state._filterOpen}
                  onToggleFilter={this._handleToggleFilterPanel}
                  onRefreshList={this._handleRefresh}
                  onResetFilter={this._handleResetFilter}
                />
              </CardHeaderWithToolbars>
              <div style={{padding: '0rem 1rem'}}>
                <FilterContainer>
                  <FiltersPanel
                    {...this.state._params}
                    onApplyFilter={this._handleApplyFilter}
                    isReset={this.state._isResetFilter}
                    onResetFilterCallback={()=>this.setState({_isResetFilter: false, _filterOpen: false})}
                    isOpen={this.state._filterOpen}
                  />
                </FilterContainer>
              </div>
              <CardBody>
                <PaginationSummaries
                  from={this.props.dataSales.list.from}
                  to={this.props.dataSales.list.to}
                  total={this.props.dataSales.list.total}
                />
                <div className="table-responsive">
                  <Tbl>
                    <TblHead>
                      <RowHeader
                        marked={this.state._params.marked}
                        onMarkedClick={this.onMarkedClick}
                        categoryName={this.state._params.category}
                        categoryValue={this.state._params.category}
                        onCategoryChanged={this._handleCategoryChanged}
                      />
                    </TblHead>
                    <TblBody
                      isLoad={this.props.dataSales.isFetch}
                      hasRow={this.props.dataSales.status_code===200 && this.props.dataSales.list.data.length>0}
                      columnCount={7}
                      renderChildren={() => {
                        const { data, current_page, per_page } = this.props.dataSales.list;
                        return data.map((item, index) => {
                          const rowId = generateNumber(per_page,current_page,index);
                          return (
                            <RowItem
                              key={item.id}
                              rowId={rowId}
                              id={item.id}
                              quantity={item.offer.quantity}
                              buyerEmail={item.buyer_email}
                              invoiceNumber={item.invoice_number}
                              orderDate={item.created_at}
                              updatedAt={item.updated_at}
                              orderStatus={generateDescStatus(item)}
                              status={item.status}
                              totalAmount={item.total_price}
                              displayName={item.sale_product.display_name}
                              SKU={item.sale_product.SKU}
                              colour={item.sale_product.colour}
                              preOrder={item.sale_product.pre_order}
                              preVerified={item.sale_product.pre_verified}
                              size={item.sale_product.size.US}
                              autoDisburse={item.autodisburse}
                              marked={item.marked}
                              notes={item.sale_notes}
                              loginId={this.props.dataAuth.id}
                              markedAt={item.open_marked_at}
                              markedBy={item.open_marked_by}
                              closeMarkedAt={item.close_marked_at}
                              unreadNoteCount={item.unread_notes_count}
                              consignmentId={item.sale_product.consignment_id}
                              sneakersCondition={item.sale_product.sneakers_condition}
                              legitCheckLogs={item.legit_check_logs}
                              category={item.sale_product.category_name}
                              bank={item.offer.bank_code}
                              paymentVia={item.offer.payment_via}
                              paymentMethod={item.offer.payment_method}
                              onOpenCreditCard={()=>this._onOpenCreditCardModal(item)}
                              rack={item.offer.user_sell.rack}
                              purchasePrice={item.purchase_price}
                              compensation={item.compensation_amount}
                              followUpStatus={item.follow_up_status}
                              sellerEmail={item.offer.user_sell.user.email}
                              voucherCode={item.voucher_code? item.voucher_code: item.voucherable? item.voucherable.code: item.voucherable_id}
                              openPurchasePriceModal={(payloads) => this._togglePurchasePriceModal(true, payloads)}
                              openFollowUpModal={(payloads) => this._toggleFollowUpModal(true, payloads)}
                            />
                          )
                        })
                      }}
                    />
                  </Tbl>
                </div>

                { this.props.dataSales.status_code===200 && (
                  <Paginations
                    size="sm"
                    lastPage={this.props.dataSales.list.last_page}
                    perPage={this.props.dataSales.list.per_page}
                    total={this.props.dataSales.list.total}
                    maxPage={8}
                    path={this.props.dataSales.list.path}
                    currentPage={this.props.dataSales.list.current_page}
                    prevPageUrl={this.props.dataSales.list.prev_page_url}
                    nextPageUrl={this.props.dataSales.list.next_page_url}
                    handleGotoCallback={this._handleGoto}
                    onCurrentPageChanged={() => {
                      window.scrollTo({
                        top: this.tableRef.offsetTop+25,
                        behavior: 'smooth'
                      })
                    }}
                  />
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    )
  }
}

const mapStateToProps = ({ sale, masterCategories, auth: dataAuth }) => ({
  dataAuth,
  dataSales: sale.followUpSales,
  categoryOptions: masterCategories.options
})
const mapDispatchToProps = (dispatch) => ({
  getFollowUpSales: query => dispatch(getFollowUpSales(query)),
  setFollowUpSale: (id, payloads) => dispatch(setFollowUpSale(id, payloads)),
  bindCategoryOption: ()=>dispatch(getCategoryOptionList())
})
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AllFollowUp));