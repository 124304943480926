import React, { PureComponent as  Component } from 'react';
import {
  Form, 
  FormGroup,
  Label,
  Input } from 'reactstrap';
import propTypes from 'prop-types';

import { perPageOptions } from '../../../constants/kickavenue'

const sortOptions = [{
    value:"" , label:"Default"
  },
  {
    value:"updatedAt_desc" , label:"Last Updated"
  },
  {
    value:"createdAt_desc" , label:"Newest"
  },
  {
    value:"createdAt_asc" , label:"Older"
  },
  {
    value:"name_asc" , label:"A-Z"
  },
  {
    value:"name_desc" , label:"Z-A"
  },
  {
    value:"featuredAt_desc" , label:"Last Featured"
  },
  {
    value:"active_desc" , label:"Active"
  },
  {
    value:"active_asc" , label:"Inactive"
  }];


export default class SortForm extends Component{
  render(){
    return(
      <Form inline className="form-filter">
        <FormGroup className="mb-2 mr-3">
          <Label className="mr-sm-3">
            Show
          </Label>
          <Input
            type="select"
            name="per_page"
            onChange={this.props.onInputChange}
            value={this.props.per_page}
          >
            {perPageOptions.map((option,i)=><option value={option.value} key={i}>{option.label}</option>)}
          </Input>
        </FormGroup>
        <FormGroup className="mb-2 mr-3">
          <Label className="mr-sm-3">
            Sort By
          </Label>
          <Input
            type="select"
            name="sort_by"
            onChange={this.props.onInputChange}
            value={this.props.sort_by}
          >
            {sortOptions.map((option,i)=><option value={option.value} key={i}>{option.label}</option>)}
          </Input>
        </FormGroup>
      </Form>
    )
  }
}

SortForm.propTypes= {
  per_page: propTypes.oneOfType([propTypes.number, propTypes.string]),
  sort_by: propTypes.string,
  onInputChange: propTypes.func.isRequired
}

SortForm.defaultProps= {
  per_page: 15,
  sort_by: ''
}