import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import propTypes from 'prop-types';
import { TextRupiah } from '../../../components/Text';
import DateToHuman from '../../../components/DateToHuman';
import ProductColumn from './rowItem/ProductColumn';
import { updateSellRequest } from '../../../actions/sellRequestAction';
import SelectRackV2 from '../../../components/Form/Select/Async/SelectRackV2';
import { attachRack, detachRack } from '../../../actions/rackAction';

class RowItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rack: props.sellRack ? {
        id: props.sellRack.rack.id,
        name: props.sellRack.rack.name,
        max: props.sellRack.rack.max_capacity,
        count: 0
      } : null,
      updatedAt: props.updatedAt,
      error: null,
      isUpdateOptions: null
    };
    this._handleSelectRack = this._handleSelectRack.bind(this);
    this.isChecked = this.isChecked.bind(this);
  }

  _handleSelectRack(option){
    const { id, productVariantId, sellRack, qty, status, preVerified } = this.props
    this.setState({ 
      rack: option,
      error: null
    })
    if (!option && sellRack) {
      this.props.deleteRack(sellRack.rack_id, { sell_id: id })
    } else if (option) {
      this.props.saveRack(option.id, { sell_id: id })
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.sellRack !== this.props.sellRack) {
      this.setState({
        rack: this.props.sellRack ? {
          id: this.props.sellRack.rack.id,
          name: this.props.sellRack.rack.name,
          max: this.props.sellRack.rack.max_capacity,
          count: 0
        } : null
      });
    }
  }

  isChecked() {
    const isAlreadySelected = this.props.selectedData.some(el => el.id === this.props.id);
    return isAlreadySelected;
  }

  render() {
    const {
      id,
      rowId,
      category,
      displayName,
      SKU,
      colour,
      sneakersCondition,
      userEmail,
      askingPrice,
      preOrder,
      preVerified,
      consignmentId,
      sellRack,
      rack,
      qty,
      size,
      status,
      isExpired,
      isSellerHoliday,
      updatedAt,
      defectsCount,
      approvedAt // Ensure this prop is passed from the parent component
    } = this.props;
    const rackName = this.state.rack ? this.state.rack.name : null;
    return (
      <tr>
        <th className="v-center" scope="row">
          {
            !this.props.isOpenPrint ?
              rowId
              :
              <input
                className="print-order-checkbox"
                name={id}
                type="checkbox"
                checked={this.isChecked()}
                onChange={this.props.onSelectDataFromCheckbox}
              />
          }
        </th>
        <td className="v-center">
          <ProductColumn
            userSellId={id}
            displayName={displayName}
            userEmail={userEmail}
            size={size}
            preOrder={preOrder}
            preVerified={preVerified}
            SKU={SKU}
            colour={colour}
            qty={qty}
            sneakersCondition={sneakersCondition}
            isExpired={isExpired}
            isSellerHoliday={isSellerHoliday}
            category={category}
            defectsCount={defectsCount}
          />
        </td>
        <td className="v-center" width={200}>
          <SelectRackV2
            key={this.state.isUpdateOptions}
            id="warehouse_rack"
            ref="selectRack"
            value={this.state.rack}
            rackOptions={this.props.rackOptions} // Pass rack options here
            placeholder="Select a rack..."
            noResultsText="Cannot find rack."
            onSelectSearch={this.props.onSelectSearch}
            onSelectChange={this._handleSelectRack}
            onSelectOpen={this.props.handleOnSelectRackOpen}
            isClearable // Add this line to make the select clearable
          />
          {this.state.error && (
            <p className='text-danger mb-0'>{this.state.error}</p>
          )}
        </td>
        <td className="v-center">
          <TextRupiah prefix="Rp. " bold value={askingPrice} />
          {
            approvedAt && (<div style={{ fontSize: '.65rem' }}>
              Approved:<br />
              {approvedAt}
            </div>)
          }
          {
            (preVerified || rack) && (<div style={{ fontSize: '.65rem' }}>
              {preVerified ? <i className="fa fa-barcode mr-1"></i> : null}
              {consignmentId ? consignmentId : null}<br />
            </div>)
          }
        </td>
        <td className="v-center"><DateToHuman value={this.state.updatedAt} id={`tooltip_sellRequest_rowItem_updatedAt_${rowId}`} /></td>
        <td className="v-center">{status}</td>
        <td className="v-center">
          <div className="d-flex" style={{ flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <Link to={`/sell_request/${id}`} className="btn btn-sm btn-secondary"><i className="fa fa-external-link"></i> Details</Link>
          </div>
        </td>
      </tr>
    );
  }
}

const mapStateToProps = ({ sellRequest: { detail, isSubmit }, rack }) => ({
  sellRequest: { ...detail, isSubmit },
  rack: { ...rack.details }
});
const mapDispatchToProps = (dispatch) => ({
  updateSellRequest: (id, payload) => dispatch(updateSellRequest(id, payload)),
  saveRack: (id, payload) => dispatch(attachRack(id, payload)),
  deleteRack: (id, payload) => dispatch(detachRack(id, payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(RowItem));

RowItem.propTypes = {
  rowId: propTypes.number.isRequired,
  id: propTypes.number.isRequired,
  productVariantId: propTypes.number.isRequired,
  displayName: propTypes.string,
  SKU: propTypes.string,
  colour: propTypes.string,
  userEmail: propTypes.string,
  askingPrice: propTypes.string,
  status: propTypes.string,
  qty: propTypes.number,
  updatedAt: propTypes.string,
  size: propTypes.object,
  consignmentId: propTypes.string,
  rack: propTypes.string,
  preVerified: propTypes.oneOfType([propTypes.bool, propTypes.number]),
  preOrder: propTypes.oneOfType([propTypes.bool, propTypes.number]),
  isExpired: propTypes.oneOfType([propTypes.bool, propTypes.number]),
  isSellerHoliday: propTypes.bool,
  defaultRoute: propTypes.string,
  onSelectDataFromCheckbox: propTypes.func.isRequired,
  selectedData: propTypes.array.isRequired,
  isOpenPrint: propTypes.bool,
  onSelectSearch: propTypes.func,
  handleOnSelectRackOpen: propTypes.func,
  rackOptions: propTypes.array.isRequired, // Add prop types for rackOptions
  approvedAt: propTypes.string // Ensure this prop is defined
};

RowItem.defaultProps = {
  SKU: '-',
  colour: '-',
  status: 'draft',
  size: {
    US: 'Not Set'
  }
};
