import React, { useMemo } from 'react';
import Style from './RowItem.module.css';
import Badge from "../../../../../components/Badges/BadgeThin";
import FontAwesomeIcon from "../../../../../components/Icons/FontAwesomeIcon";
import { TextRupiah } from "../../../../../components/Text";
import { Link } from "react-router-dom";
import DateToHuman from "../../../../../components/DateToHuman";
import { joinClassNames } from "../../../../../helpers/formatter";
import ButtonStarred from "../../../../Sales/RowItem/ButtonStarred";
import SmallInline from "../../../../../styled/Typograph/Small/SmallInline";
import { Button, ButtonGroup } from "reactstrap";

export default function RowItem(props) {

    const {
        rowId,
        item,
        isMarked,
        onOpenMarkedModal,
        onAccept,
        onReject,
        adminRoles,
        email,
    } = props;

    const isLcRole = useMemo(() => adminRoles?.filter((role) => role.name === 'legit_check').length > 0, [adminRoles]);
    const isLcEmail = useMemo(() => item?.legit_check_logs.filter((lc) => lc.user.email === email).length === 0, [item?.legit_check_logs]);

    const sneakersCondition = useMemo(() => {
        return item.user_sell?.sneakers_condition;
    }, [item.user_sell]);

    return (
        <tr>
            <th className="v-center" scope="row">
                {rowId}
            </th>
            <td className={Style['consignment-detail']}>
                <div className={Style['consignment-detail-upper-row']}>
                    {item.unread_notes_count > 0 && <span style={{ fontSize: '.65rem' }} className="mr-1 text-danger">
                        <FontAwesomeIcon iconType="circle" />
                    </span>}
                    <p className={Style['consignment-detail-upper-row__id']}>#{item.id}</p>
                    <p className={Style['consignment-detail-upper-row__consignment-id']}>{item.consignment_number}</p>
                </div>
                <div className={Style['consignment-detail-mid-upper-row']}>
                    <p className={Style['consignment-detail-mid-upper-row__display-name']}>{item?.user_sell?.product_variant?.display_name}</p>
                    <div className={Style['consignment-detail-mid-upper-row-info']}>
                        <p className={Style['consignment-detail-mid-upper-row-info__sku']}>({item?.user_sell?.product_variant?.SKU}) Quantity: {item?.user_sell?.quantity}</p>
                        <p className={Style['consignment-detail-mid-upper-row-info__color']}>{item?.user_sell?.product_variant?.colour}</p>
                    </div>
                </div>
                <div className={Style['consignment-detail-mid-lower-row']}>
                    <Badge
                        color="primary"
                        className="text-uppercase mr-1"
                    >
                        <FontAwesomeIcon iconType="tags" /> {item?.product_variant?.product?.category?.name}
                    </Badge>
                    <Badge
                        color="success"
                        className="text-uppercase mr-1"
                    >
                        <FontAwesomeIcon iconType="tag" /> {sneakersCondition}
                    </Badge>
                    <Badge
                        color="success"
                        className="text-uppercase mr-1"
                    >
                        Size: {item?.user_sell?.size?.US}
                    </Badge>
                </div>
                <div className={Style['consignment-detail-lower-row']}>
                    <span className={joinClassNames(Style['consignment-detail-lower-row__text'], 'mr-1')}>
                        <FontAwesomeIcon iconType="calendar" className="mr-1" />{item.created_at} - {item.user?.email ?? ''}
                    </span>
                    <span className={joinClassNames(Style['consignment-detail-lower-row__text'], 'mr-1')}> (<b>Seller</b>)</span>
                </div>
            </td>
            <td className="v-center">
                <TextRupiah tag="span" prefix="Rp. " value={item.price} />
                <div className="issued-wrapper" style={{ minWidth: 95 }}>
                    <a
                        href="#"
                        className="mr-1"
                        style={{ textDecoration: 'underline', color: '#4a4a4a', fontSize: '.75rem' }}
                        onClick={e => {
                            e.preventDefault();
                            onOpenMarkedModal();
                        }}
                    >
                        <i className="fa fa-pencil"></i> {item.notes_count > 0 ? 'view' : 'add'} notes
                    </a>
                    <ButtonStarred
                        title={isMarked ? "Remove mark!" : "Mark!"}
                        value={isMarked}
                        onClick={() => {
                            onOpenMarkedModal(item.marked);
                        }}
                    />
                </div>
            </td>
            <td className={Style['shipping-method']}>
                <p className={Style['shipping-method-title']}>{item?.seller_courier ?? '-'}</p>
                <Link to={'#'} className={Style['shipping-method-info']}>Tracking Shipment</Link>
            </td>
            <td className="v-center">
                <DateToHuman value={item.updated_at} id={`tooltip_${rowId}`} />
            </td>
            <td className="v-center">
                <ButtonGroup>
                    {isLcEmail && isLcRole && (<>
                        <Button
                            color="success"
                            size="sm"
                            title="Accept"
                            onClick={onAccept}
                        >
                            <i className="fa fa-check"></i>
                        </Button>
                        <Button
                            color="danger"
                            size="sm"
                            title="Reject"
                            onClick={onReject}
                        >
                            <i className="fa fa-times"></i>
                        </Button>
                    </>)}
                    <Link
                        to={`/consignment/${item.id}`}
                        className="btn btn-sm btn-secondary"
                        target="_blank"
                        title="More Detail..."
                    >
                        <i className="fa fa-external-link"></i>
                    </Link>
                </ButtonGroup>
            </td>
        </tr>
    );
}
