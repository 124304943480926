import React from 'react';
import { connect } from 'react-redux';
import {
  Modal,
  ModalHeader,
  ModalBody,
	Button,
	Label,
  Input,
  Form,
	FormGroup,
  ModalFooter
} from 'reactstrap';
import classNames from 'classnames';
import { TextRupiah } from '../../components/Text';
import { updateSale } from '../../actions/saleAction';
import { isNumeric } from '../../helpers/regex';
import { errorMessage } from '../../utils/form';


class ModalPurchasePrice extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      purchasePrice: '',
      error: null,
      isSubmit: false
    }
  }

  componentDidUpdate(prevProps) {
    if(prevProps.isOpen !== this.props.isOpen && this.props.isOpen){
      const { data: { purchasePrice } } = this.props;
      this.setState({
        purchasePrice: purchasePrice? Number(purchasePrice): '',
        error: null,
        isSubmit: false
      })
    }
  }

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  _validateForm = async() => {
    let errors = {};
    const { purchasePrice } = this.state;
    if(!isNumeric(purchasePrice)){
      errors = {
        ...errors,
        purchasePrice: ['The purchase price field must be a number.']
      }
    }
    let p = null;
    if(Object.keys(errors).length){
      p = { errors: { ...errors }}
    }
    await this.setState({
      error: p
    })
  }

  _renderErrorMessage = name => {
    const { error } = this.state;
    return errorMessage(name, error)
  }

  onSubmit = async (e) => {
    e.preventDefault();
    await this._validateForm();
    const { error } = this.state;
    if(error) return;
    const payloads = {
      purchase_price: this.state.purchasePrice? Number(this.state.purchasePrice): null
    }
    this.props.updateSale(this.props.data.id, payloads)
    this.props.toggle();
  }

  render(){
    return(
      <Modal size="sm" className="modal-confirmation" isOpen={this.props.isOpen} toggle={this.props.toggle}>
        <Form onSubmit={this.onSubmit}>
          <ModalHeader toggle={this.props.toggle} className="modal-header--left">
            Edit
          </ModalHeader>
          <ModalBody>
            {this.props.data?(
              <p>
                <b>{this.props.data.invoiceNumber}</b><br/>
                Purchase Price: {this.props.data.purchasePrice? <TextRupiah prefix={"Rp. "} tag="span" value={this.props.data.purchasePrice}/>: '-'}.
              </p>
            ):null}
            <FormGroup className={classNames({'has-danger has-feedback': this.state.error !== null})}>
              <Label>Purchase Price</Label>
              <Input
                type="text"
                placeholder="Purchase price..."
                name="purchasePrice"
                value={this.state.purchasePrice}
                onChange={this.handleChange}
              />
              { this._renderErrorMessage('purchasePrice') }
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.props.toggle}>Cancel</Button>
            <Button color="primary" type="submit">Save</Button>
          </ModalFooter>
        </Form>
      </Modal>
    )
  }
}
const mapStateToProps = ({ sale }) => ({
  sale
})

const mapDispatchToProps = (dispatch) => ({
  updateSale: (id, payload) => dispatch(updateSale(id, payload)),
})

export default connect (mapStateToProps, mapDispatchToProps) (ModalPurchasePrice);
