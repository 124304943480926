import React, { } from 'react';
import { Form, FormGroup, Input, Label } from "reactstrap";
import { perPageOptions } from "../../../../../constants/kickavenue";

const sortOptions = [
    {
        value: "", label: "Default",
    },
    {
        value: "importants", label: "Important",
    },
    {
        value: "createdAt_desc", label: "Newest",
    },
    {
        value: "createdAt_asc", label: "Older",
    },
    {
        value: "updatedAt_desc", label: "Last Updated",
    },
    {
        value: "autodisburse_desc", label: "Autodisburse",
    },
    {
        value: "autodisburse_asc", label: "Not Autodisburse",
    },
    {
        value: "price_desc", label: "Highest Price",
    },
    {
        value: "price_asc", label: "Lowest Price",
    },
    {
        value: "product_asc", label: "Product A-Z",
    },
    {
        value: "product_desc", label: "Product Z-A",
    },
];

export default function SortForm(props) {
    const {
        setPerPage,
        setSortBy,
        perPage,
        sortBy,
    } = props;

    const onPageChange = (e) => {
        setPerPage(e.target.value);
    }

    const onSortChange = (e) => {
        setSortBy(e.target.value);
    }

    return (
        <Form inline className="form-filter">
            <FormGroup className="mb-2 mr-3">
                <Label className="mr-sm-3">
                    Show
                </Label>
                <Input
                    type="select"
                    name="perPage"
                    onChange={onPageChange}
                    value={perPage}
                >
                    {perPageOptions.map((option, i) => <option value={option.value} key={i}>{option.label}</option>)}
                </Input>
            </FormGroup>
            <FormGroup className="mb-2 mr-3">
                <Label className="mr-sm-3">
                    Sort By
                </Label>
                <Input
                    type="select"
                    name="sortBy"
                    onChange={onSortChange}
                    value={sortBy}
                >
                    {sortOptions.map((option, i) => <option value={option.value} key={i}>{option.label}</option>)}
                </Input>
            </FormGroup>
        </Form>
    );
}
