import {
    AUTH_TOKEN_KEY,
    USER_ROUTE_API,
    USER_FETCHING,
    USER_DETAIL_FETCH,
    USER_LIST_FETCH,
    USER_DELETE,
    USER_ADD,
    USER_UPDATE,
    USER_ERROR,
    USER_ROLES_FETCH,
    USER_FETCHING_ROLES,
    USER_FETCHING_SELLER_VERIFICATIONS,
    USER_SELLER_VERIFICATIONS_FETCH,
    USER_DETAIL_SIZES_LIST_FETCHING,
    USER_DETAIL_SIZES_LIST_FETCHED,
    USER_DETAIL_SIZES_LIST_ADDED,
    USER_DETAIL_SIZES_LIST_UPDATED,
    USER_DETAIL_SIZES_LIST_ERROR,
    USER_BALANCE_LOG_FETCH,
    USER_BALANCE_LOG_LIST,
		USER_BALANCE_LOG_ERROR,
    SELLER_POINT_HISTORY_FETCH,
    SELLER_POINT_HISTORY_LIST,
		SELLER_POINT_HISTORY_ERROR,
		USER_COMMISSION_RATE_SUBMIT,
		USER_COMMISSION_RATE_ERROR,
		USER_COMMISSION_RATE,
		USER_STOCK_EXPORT,
		USER_STOCK_EXPORT_SUBMIT,
		USER_STOCK_EXPORT_ERROR,
		USER_SHIPPING_FETCH,
		USER_SHIPPING_LIST,
		USER_SHIPPING_ERROR,
    USER_SHIPPING_SAVE_SUBMIT,
    USER_SHIPPING_SAVE,
    USER_SHIPPING_SAVE_ERROR,
    USER_SHIPPING_UPDATE_SUBMIT,
    USER_SHIPPING_UPDATE,
    USER_SHIPPING_UPDATE_ERROR,
} from '../constants';
import api from '../utils/Api';
import { manipulateErrors, checkAuthorized } from '../utils';
import { logout } from './authAction';
const onSuccess=(res,type)=>(
	{
		type:type,
		payload:res		
	}
)
const onError=(err)=>{    
	if(!checkAuthorized(manipulateErrors(err)))
        return logout();
    return {
        type:USER_ERROR,
        payload:manipulateErrors(err)
    }
}
const isFetch=(payload)=>(
    {
        type:USER_FETCHING,
        payload
    }
)

const isFetchUserRoles=(payload)=>(
    {
        type:USER_FETCHING_ROLES,
        payload
    }
)

const isFetchUserSellerVerification=(payload)=>(
    {
        type:USER_FETCHING_SELLER_VERIFICATIONS,
        payload
    }
)

export const getListUser=(query)=>{
	return dispatch=>(
		dispatch(isFetch({type:'fetch',status:true})),
		api({
			Authorization:'Bearer '+localStorage.getItem(AUTH_TOKEN_KEY)
		}).get(USER_ROUTE_API,query)
		.then(res => {
			dispatch(onSuccess(res.data,USER_LIST_FETCH));
			dispatch(isFetch({type:'fetch',status:false}));
		})
		.catch(err => {
			dispatch(onError(err));
			dispatch(isFetch({type:'fetch',status:false}));
		})		
	)
}

export const getListUserSellerVerification=(id)=>{
	return dispatch=>(
		dispatch(isFetchUserSellerVerification(true)),
		api({
			Authorization:'Bearer '+localStorage.getItem(AUTH_TOKEN_KEY)
		}).get(`${USER_ROUTE_API}/${id}/seller-verifications`)
		.then(res => {
			dispatch(onSuccess(res.data,USER_SELLER_VERIFICATIONS_FETCH));
			dispatch(isFetchUserSellerVerification(false));
		})
		.catch(err => {
			dispatch(onError(err));
			dispatch(isFetchUserSellerVerification(false));
		})		
	)
}

export const getListUserSizes = userId =>(
	dispatch =>{
		dispatch({type:USER_DETAIL_SIZES_LIST_FETCHING, payload:true});
		api({
			Authorization:'Bearer '+localStorage.getItem(AUTH_TOKEN_KEY)
		}).get(`${USER_ROUTE_API}/${userId}/sizes`)
		.then(res => {
			dispatch(onSuccess(res.data, USER_DETAIL_SIZES_LIST_FETCHED));
			dispatch({type:USER_DETAIL_SIZES_LIST_FETCHING, payload:false});
		})
		.catch(err => {			
			dispatch({
				type: USER_DETAIL_SIZES_LIST_ERROR,
				payload: manipulateErrors(err)
			});
			dispatch({type:USER_DETAIL_SIZES_LIST_FETCHING, payload:false});
		})

	}
)

export const saveUserSize = (userId, payload) => (
	dispatch=>{
		const { id, ...sizeReq } = payload;
		if(id)
			return dispatch(updateUserSize(userId,id,sizeReq));
		return dispatch(addUserSize(userId,sizeReq));
	}
)

export const addUserSize = (userId, payload) =>(
	dispatch =>{
		dispatch({type:USER_DETAIL_SIZES_LIST_FETCHING, payload:true});
		api({
			Authorization:'Bearer '+localStorage.getItem(AUTH_TOKEN_KEY)
		}).post(`${USER_ROUTE_API}/${userId}/sizes`, payload)
		.then(res => {
			dispatch(onSuccess(res.data, USER_DETAIL_SIZES_LIST_ADDED));
			dispatch({type:USER_DETAIL_SIZES_LIST_FETCHING, payload:false});
		})
		.catch(err => {			
			dispatch({
				type: USER_DETAIL_SIZES_LIST_ERROR,
				payload: manipulateErrors(err)
			});
			dispatch({type:USER_DETAIL_SIZES_LIST_FETCHING, payload:false});
		})

	}
)

export const updateUserSize = (userId, id, payload) =>(
	dispatch =>{
		dispatch({type:USER_DETAIL_SIZES_LIST_FETCHING, payload:true});
		api({
			Authorization:'Bearer '+localStorage.getItem(AUTH_TOKEN_KEY)
		}).put(`${USER_ROUTE_API}/${userId}/sizes/${id}`, payload)
		.then(res => {
			dispatch(onSuccess(res.data, USER_DETAIL_SIZES_LIST_UPDATED));
			dispatch({type:USER_DETAIL_SIZES_LIST_FETCHING, payload:false});
		})
		.catch(err => {			
			dispatch({
				type: USER_DETAIL_SIZES_LIST_ERROR,
				payload: manipulateErrors(err)
			});
			dispatch({type:USER_DETAIL_SIZES_LIST_FETCHING, payload:false});
		})

	}
)





export const getDetailUser=(id)=>{
	return dispatch=>(
		dispatch(isFetch({type:'fetch',status:true})),
		api({
			Authorization:'Bearer '+localStorage.getItem(AUTH_TOKEN_KEY)
		}).get(`${USER_ROUTE_API}/${id}`)
		.then(res => {
			dispatch(onSuccess(res.data,USER_DETAIL_FETCH));
			dispatch(isFetch({type:'fetch',status:false}));
		})
		.catch(err => {
			dispatch(onError(err));
			dispatch(isFetch({type:'fetch',status:false}));
		})		
	)
}
export const getUserRoles=(id)=>{
	return dispatch=>(
		dispatch(isFetchUserRoles(true)),
		api({
			Authorization:'Bearer '+localStorage.getItem(AUTH_TOKEN_KEY)
		}).get(`${USER_ROUTE_API}/${id}/roles`)
		.then(res => {
			dispatch(onSuccess(res.data,USER_ROLES_FETCH));
			dispatch(isFetchUserRoles(false));
		})
		.catch(err => {
			dispatch(onError(err));
			dispatch(isFetchUserRoles(false));
		})		
	)
}
const createUser=(payload)=>{
	return dispatch =>(
		dispatch(isFetch({type:'submit',status:true})),
				api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).post(`${USER_ROUTE_API}`,payload)
		.then(res=>{
			dispatch(onSuccess(res.data,USER_ADD))
			dispatch(isFetch({type:'submit',status:false}))
		})
		.catch(err => {
			dispatch(onError(err));
			dispatch(isFetch({type:'submit',status:false}));
		})
	)
}

const updateUser=(id, payload)=>{
	return dispatch => (
		dispatch(isFetch({type:'submit',status:true})),
		api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).put(`${USER_ROUTE_API}/${id}`,payload)
		.then(res=>{
			dispatch(onSuccess(res.data,USER_UPDATE))
			dispatch(isFetch({type:'submit',status:false}))
		})
		.catch(err => {
			dispatch(onError(err));
			dispatch(isFetch({type:'submit',status:false}));
		})
	)
}

export const saveUser = (payload) =>{
	const {id,...rest}=payload;
	return dispatch =>{
		if(id===undefined)
			dispatch(createUser({...rest}));
		else 
			dispatch(updateUser(id,{...rest}));

	}
}

export const deleteUser = (id) =>{
	return dispatch => (
		dispatch(isFetch({type:'submit',status:true})),
		api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).delete(`${USER_ROUTE_API}/${id}`)
		.then(res=>{
			dispatch(onSuccess(res.data,USER_DELETE))
			dispatch(isFetch({type:'submit',status:false}))
		})
		.catch(err => {
			dispatch(onError(err));
			dispatch(isFetch({type:'submit',status:false}));
		})
	)
}

export const getUserBalanceLog = (userId, query) => (
  dispatch => {
    dispatch({type:USER_BALANCE_LOG_FETCH, payload:true});
    api({
      Authorization: 'Bearer '+localStorage.getItem(AUTH_TOKEN_KEY)
    }).get(`${USER_ROUTE_API}/${userId}/balance/log`, query)
    .then(res => {
      dispatch(onSuccess(res.data, USER_BALANCE_LOG_LIST));
      dispatch({type:USER_BALANCE_LOG_FETCH, payload:false});
    })
    .catch(err => {
      dispatch({
        type: USER_BALANCE_LOG_ERROR,
        payload: manipulateErrors(err)
      });
      dispatch({type:USER_BALANCE_LOG_FETCH, payload:false});
    })
  }
)
export const getSellerPointHistory = (userId, query) => {
	let params = { user_id: userId };
	if(query && query.params){
		params = {
			...params,
			...query.params,
		}
	}
	return dispatch => {
		dispatch({type:SELLER_POINT_HISTORY_FETCH, payload:true});
		api({
			Authorization: 'Bearer '+localStorage.getItem(AUTH_TOKEN_KEY)
		}).get(`${USER_ROUTE_API}/seller-points/histories`, { params })
		.then(res => {
			dispatch(onSuccess(res.data, SELLER_POINT_HISTORY_LIST));
			dispatch({type:SELLER_POINT_HISTORY_FETCH, payload:false});
		})
		.catch(err => {
			dispatch({
				type: SELLER_POINT_HISTORY_ERROR,
				payload: manipulateErrors(err)
			});
			dispatch({type:SELLER_POINT_HISTORY_FETCH, payload:false});
		})
	}
}

export const updateUserCommission = (userId, payload) => (
  dispatch => {
    dispatch({ type:USER_COMMISSION_RATE_SUBMIT, payload:true} );
    api({
      Authorization: 'Bearer '+localStorage.getItem(AUTH_TOKEN_KEY)
    }).post(`${USER_ROUTE_API}/${userId}/commissions`, payload)
    .then(res => {
      dispatch(onSuccess(res.data, USER_COMMISSION_RATE));
    })
    .catch(err => {
      dispatch({
        type: USER_COMMISSION_RATE_ERROR,
        payload: manipulateErrors(err)
      });
    })
  }
)

export const userStockExport = (userId, payload) => (
	dispatch => {
		dispatch({ type:USER_STOCK_EXPORT_SUBMIT, payload:true} );
    api({
      Authorization: 'Bearer '+localStorage.getItem(AUTH_TOKEN_KEY)
    }).post(`${USER_ROUTE_API}/${userId}/stock-exports`, payload)
    .then(res => {
      dispatch(onSuccess(res.data, USER_STOCK_EXPORT));
    })
    .catch(err => {
      dispatch({
        type: USER_STOCK_EXPORT_ERROR,
        payload: manipulateErrors(err)
      });
    })
	}
)


export const getUserShippingList = (userId, query) => (
  dispatch => {
    dispatch({type:USER_SHIPPING_FETCH, payload:true});
    api({
      Authorization: 'Bearer '+localStorage.getItem(AUTH_TOKEN_KEY)
    }).get(`${USER_ROUTE_API}/${userId}/shippings`, query)
    .then(res => {
      dispatch(onSuccess(res.data, USER_SHIPPING_LIST));
      dispatch({type:USER_SHIPPING_FETCH, payload:false});
    })
    .catch(err => {
      dispatch({
        type: USER_SHIPPING_ERROR,
        payload: manipulateErrors(err)
      });
      dispatch({type:USER_SHIPPING_FETCH, payload:false});
    })
  }
)

export const saveUserAddress = (userId, payload) => (
	dispatch => {
		dispatch({ type:USER_SHIPPING_SAVE_SUBMIT, payload:true} );
    api({
      Authorization: 'Bearer '+localStorage.getItem(AUTH_TOKEN_KEY)
    }).post(`${USER_ROUTE_API}/${userId}/shippings`, payload)
    .then(res => {
      dispatch(onSuccess(res.data, USER_SHIPPING_SAVE));
    })
    .catch(err => {
      dispatch({
        type: USER_SHIPPING_SAVE_ERROR,
        payload: manipulateErrors(err)
      });
    })
	}
)

export const updateUserAddress = (userId, shipping_id, payload) => (
  dispatch => {
    dispatch({ type:USER_SHIPPING_UPDATE_SUBMIT, payload:true} );
    api({
      Authorization: 'Bearer '+localStorage.getItem(AUTH_TOKEN_KEY)
    }).put(`${USER_ROUTE_API}/${userId}/shippings/${shipping_id}`, payload)
    .then(res => {
      dispatch(onSuccess(res.data, USER_SHIPPING_UPDATE));
    })
    .catch(err => {
      dispatch({
        type: USER_SHIPPING_UPDATE_ERROR,
        payload: manipulateErrors(err)
      });
    })
  }
)
