import React, { Component } from 'react';
import moment from 'moment';
import 'moment/locale/id';
import { Tooltip } from 'reactstrap';

const getDuration = (endDate)=> moment.duration(moment().diff(moment(endDate))).asSeconds();
const toHumanString = (seconds, date)=>{
    switch(true)
    {
        case (parseInt(seconds) < 60) : return 'a while ago.';
        case (parseInt(seconds) < 3600) : return `${parseInt(seconds/60)}min ago.`;
        case (parseInt(seconds) <= 3600*24) : return `${parseInt(seconds/3600)}h ago.`;
        case (parseInt(seconds) < 3600*24*7*4) : {
            return parseInt(seconds%(3600*24)/3600) > 0 ? `${parseInt(seconds/(3600*24))}d, ${parseInt(seconds%(3600*24)/3600)}h ago.`
            : `${parseInt(seconds/(3600*24))}d ago.`;
        }
        // case (parseInt(seconds) < 3600*24*7*4) : return `${parseInt(seconds/(3600*24*7))}w ago.`;
        case (parseInt(seconds) < 3600*24*7*4*12) : return `${parseInt(seconds/(3600*24*7*4))}mo ago.`;
        default: return moment(date).format('DD/MM/YYYY');
    }
}
export default class DateToHuman extends Component{
    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.state = {
            tooltipOpen: false
        };
    }
    
    toggle() {
        this.setState({
            tooltipOpen: !this.state.tooltipOpen
        });
    }
    render(){
        return (
            <div>                
                <a href="#" onClick={e => e.preventDefault()} id={this.props.id} style={{color:'#444'}}>{ toHumanString(getDuration(this.props.value),this.props.value) }</a>
                <Tooltip placement="top" isOpen={this.state.tooltipOpen} target={this.props.id} toggle={this.toggle}>
                    {moment(this.props.value).format('DD/MM/YYYY HH:mm')}
                </Tooltip>
            </div>
        )
    }
}