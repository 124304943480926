import React from 'react';
import Loading from 'react-loading-animation';
import { Link } from 'react-router-dom';
import { TextRupiah } from '../../../../../components/Text';
import { getPaymentType, getPaymentVia } from '../../../../../constants/kickavenue';
import { _getValue, _getFloatValue } from '../../../../../utils/form';
import {
  FieldRow,
  FieldColumn,
  FieldLabel,
  FieldValue
} from '../../../../../styled/Fields';
import {
  Card,
  CardHeader,
  CardBody
} from 'reactstrap';
import AlertNotFound from '../../../../../components/Card/AlertNotFound'

const PaymentDetailCard = props => {
  return (
    <Card>
      <CardHeader>
        Payment Details
      </CardHeader>
      <CardBody>
        {
          props.isLoading?
          <Loading/>:
          (
            props.isEmptyData?
            <AlertNotFound message="Payment data not found!"/>:
            <div>
              <FieldRow>
                <FieldColumn className="col-xs-12 col-md-6">
                  <FieldLabel>Payment ID <Link to={`/payments/${props.id}`} title="Go to Payment Details">See more...</Link></FieldLabel>
                  <FieldValue>
                    <span>{_getValue(props.id)}</span>
                  </FieldValue>
                </FieldColumn>
              </FieldRow>
              <FieldRow>
                <FieldColumn className="col-12">
                  <FieldLabel>Invoice Number</FieldLabel>
                  <FieldValue>
                    <span>{ _getValue(props.invoice_number) }</span>
                  </FieldValue>
                </FieldColumn>
                <FieldColumn className="col-xs-12 col-md-6">
                  <FieldLabel>Currency</FieldLabel>
                  <FieldValue>
                    <span>{ _getValue(props.currency) }</span>
                  </FieldValue>
                </FieldColumn>
                <FieldColumn className="col-xs-12 col-md-6">
                  <FieldLabel>Qty</FieldLabel>
                  <FieldValue>
                    <span>{ _getValue(props.quantity) }</span>
                  </FieldValue>
                </FieldColumn>
                <FieldColumn className="col-xs-12 col-md-6">
                  <FieldLabel>Payment Method</FieldLabel>
                  <FieldValue>
                    <span>{ getPaymentType(props.payment_method) }</span>
                  </FieldValue>
                </FieldColumn>
                <FieldColumn className="col-xs-12 col-md-6">
                  <FieldLabel>Installment Type</FieldLabel>
                  <FieldValue>
                    <span>{ _getValue(props.installments_type, '-') }</span>
                  </FieldValue>
                </FieldColumn>
                <FieldColumn className="col-xs-12 col-md-6">
                  <FieldLabel>Payment Via</FieldLabel>
                  <FieldValue>
                    <span>{ _getValue(getPaymentVia(props.payment_via), '-') }</span>
                  </FieldValue>
                </FieldColumn>
                <FieldColumn className="col-xs-12 col-md-6">
                  <FieldLabel>{props.payment_method === 'credit_card' || props.payment_method === 'installments'? 'Acquiring Bank': 'Bank Code'}</FieldLabel>
                  <FieldValue>
                    <span>{ _getValue(props.bank_code, '-') }</span>
                  </FieldValue>
                </FieldColumn>
                <FieldColumn className="col-xs-12 col-md-6">
                  <FieldLabel>Payment Info Field</FieldLabel>
                  <FieldValue>
                    <span>{ _getValue(props.payment_info_field, '-') }</span>
                  </FieldValue>
                </FieldColumn>
                <FieldColumn className="col-12">
                  <FieldLabel>Invoice URL</FieldLabel>
                  <FieldValue>
                    <span className="break-all"> { _getValue(props.invoice_url, '-') }</span>
                  </FieldValue>
                </FieldColumn>
              </FieldRow>
              <FieldRow>
                <FieldColumn className="col-xs-12 col-md-6">
                  <FieldLabel>Courier</FieldLabel>
                  <FieldValue>
                    <span>{ _getValue(props.ka_courier) }</span>
                  </FieldValue>
                </FieldColumn>
                <FieldColumn className="col-xs-12 col-md-6">
                  <FieldLabel>Courier Services</FieldLabel>
                  <FieldValue>
                    <span>{ _getValue(props.ka_courier_option) }</span>
                  </FieldValue>
                </FieldColumn>
                <FieldColumn className="col-xs-12 col-md-6">
                  <FieldLabel>Courier Price</FieldLabel>
                  <FieldValue>
                    <TextRupiah
                      prefix="Rp. "
                      value={_getFloatValue(props.ka_courier_price)}
                    />
                  </FieldValue>
                </FieldColumn>
              </FieldRow>
              <FieldRow>
                <FieldColumn className="col-xs-12 col-md-6">
                  <FieldLabel>Amount</FieldLabel>
                  <FieldValue>
                    <TextRupiah
                      prefix="Rp. "
                      value={_getFloatValue(props.offer_amount)}
                    />
                  </FieldValue>
                </FieldColumn>
                <FieldColumn className="col-xs-12 col-md-6">
                  <FieldLabel>Kick Credit</FieldLabel>
                  <FieldValue>
                    <TextRupiah
                      prefix="Rp. "
                      value={_getFloatValue(props.wallet_amount)}
                    />
                  </FieldValue>
                </FieldColumn>
                <FieldColumn className="col-xs-12 col-md-6">
                  <FieldLabel>Unique Amount</FieldLabel>
                  <FieldValue>
                    <TextRupiah
                      prefix="Rp. "
                      value={_getFloatValue(props.unique_amount)}
                    />
                  </FieldValue>
                </FieldColumn>
                <FieldColumn className="col-xs-12 col-md-6">
                  <FieldLabel>Administration Fee</FieldLabel>
                  <FieldValue>
                    <TextRupiah
                      prefix="Rp. "
                      value={_getFloatValue(props.administration_fee)}
                    />
                  </FieldValue>
                </FieldColumn>
              </FieldRow>
              <FieldRow>
                <FieldColumn className="col-xs-12 col-md-6">
                  <FieldLabel>Disc</FieldLabel>
                  <FieldValue>
                    <TextRupiah
                      prefix="Rp. "
                      value={_getFloatValue(props.discount)}
                    />
                  </FieldValue>
                </FieldColumn>
                <FieldColumn className="col-xs-12 col-md-6">
                  <FieldLabel>Courier Disc.</FieldLabel>
                  <FieldValue>
                    <TextRupiah
                      prefix="Rp. "
                      value={_getFloatValue(props.ka_courier_discount)}
                    />
                  </FieldValue>
                </FieldColumn>
                <FieldColumn className="col-xs-12 col-md-6">
                  <FieldLabel>Admin Fee Disc.</FieldLabel>
                  <FieldValue>
                    <TextRupiah
                      prefix="Rp. "
                      value={_getFloatValue(props.admin_fee_discount)}
                    />
                  </FieldValue>
                </FieldColumn>
                <FieldColumn className="col-12">
                  <FieldLabel>Voucher</FieldLabel>
                  <FieldValue>
                    <span>{ _getValue(props.voucherable_id&&(props.voucherable?props.voucherable.code:props.voucherable_id)) }</span>
                  </FieldValue>
                </FieldColumn>
              </FieldRow>
              <FieldRow>
                <FieldColumn className="col-xs-12 col-md-6">
                  <FieldLabel>Expiry</FieldLabel>
                  <FieldValue>
                    <span>{ _getValue(props.expiry) }</span>
                  </FieldValue>
                </FieldColumn>
                <FieldColumn className="col-xs-12 col-md-6">
                  <FieldLabel>Created</FieldLabel>
                  <FieldValue>
                    <span>{ _getValue(props.created_at) }</span>
                  </FieldValue>
                </FieldColumn>
                <FieldColumn className="col-xs-12 col-md-6">
                  <FieldLabel>Updated</FieldLabel>
                  <FieldValue>
                    <span>{ _getValue(props.updated_at) }</span>
                  </FieldValue>
                </FieldColumn>
              </FieldRow>
            </div>
          )
        }
      </CardBody>
    </Card>
  )
}

export default PaymentDetailCard;
