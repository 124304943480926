import React from 'react';
import {
  Row,
  Col,
  Card,
  CardText
} from 'reactstrap';
import { Link } from 'react-router-dom';
import propTypes from 'prop-types';

import { UserDetail } from '../../../components/Users';
import SellerVerificationList from '../../../components/Order/sellerDetail/SellerVerificationList';

import AlertNotFound from '../../../components/Card/AlertNotFound';

const TabSellerDetail = props => (
  <Card body className="tab-content--custom__card">
    <CardText>About seller's detail. { !props.isEmpty? <Link to={`/users/${props.seller.id}`}>See References.</Link>: null }</CardText>
    {
      props.isEmpty ?
      <AlertNotFound message="Seller data not found!"/>:
      <div>
        <Row>
          <Col xs={12} md={6}>
            <UserDetail
              headerText="Seller Details"
              displayRole
              isLoading={props.isLoading}
              user={{...props.seller}}
            />
          </Col>
          <Col xs={12}>
            <SellerVerificationList
              data={props.seller.seller_verification_info}
            />
          </Col>
        </Row>
      </div>
    }
  </Card>
)

export default TabSellerDetail;

TabSellerDetail.propTypes={
  isLoading: propTypes.bool,
  isEmpty: propTypes.bool,
  seller:propTypes.shape({
    id:propTypes.number,
    email:propTypes.string,
    roles: propTypes.arrayOf(propTypes.object),
    seller_verification_info: propTypes.arrayOf(propTypes.object)
  })
}

TabSellerDetail.defaultProps={
  isEmpty: false,
  seller:{
    id:0,
    email:'',
    roles: [],
    seller_verification_info: []
  }
}
