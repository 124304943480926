import {
  ADD_IMAGE_SURVEY,
  ADD_IMAGE_SURVEY_OPTION,
  DELETE_IMAGE_SURVEY,
  DELETE_IMAGE_SURVEY_OPTION,
  SURVEY_ADD,
  SURVEY_DELETE,
  SURVEY_DETAIL_FETCH,
  SURVEY_ERROR,
  SURVEY_FETCHING,
  SURVEY_LIST_FETCH,
  SURVEY_OPTION_ADD,
  SURVEY_OPTION_DELETE,
  SURVEY_OPTION_DETAIL_FETCH,
  SURVEY_OPTION_ERROR,
  SURVEY_OPTION_FETCHING,
  SURVEY_OPTION_LIST_FETCH,
  SURVEY_OPTION_UPDATE,
  SURVEY_UPDATE,
} from "../constants";

const initialState = {
  list: [],
  detail: {
    delete: false,
  },
  isFetch: false,
  isSubmit: false,
  success: null,
  error: null,
  option: {
    list: [],
    detail: {},
    isFetch: false,
    isSubmit: false,
    success: null,
    error: null,
    delete: false,
    addedImages: {},
    isSubmittingImage: false,
    deletedImages: [],
    isDeletingImage: false,
  },
  addedImages: {},
  isSubmittingImage: false,
  deletedImages: [],
  isDeletingImage: false,
};

const onFetching = (lastState, payload) => {
  if (payload.type === "fetch")
    return {
      ...lastState,
      isFetch: payload.status,
      success: null,
      error: null,
    };
  else if (payload.type === "submit")
    return { ...lastState, isSubmit: payload.status };
  else if (payload.type === "submit_image")
    return { ...lastState, isSubmittingImage: payload.status };
  else if (payload.type === "delete_image")
    return { ...lastState, isDeletingImage: payload.status };

  return { ...lastState, isFetch: false, isSubmit: false };
};

const onFetchingOption = (lastState, payload) => {
  if (payload.type === "fetch")
    return {
      ...lastState,
      option: {
        ...lastState.option,
        isFetch: payload.status,
        success: null,
        error: null,
      },
    };
  else if (payload.type === "submit")
    return {
      ...lastState,
      option: { ...lastState.option, isSubmit: payload.status },
    };
  else if (payload.type === "detail")
    return {
      ...lastState,
      option: {
        ...lastState.option,
        detail: { ...lastState.option.detail, isFetch: payload.status },
      },
    };
  else if (payload.type === "submit_image")
    return {
      ...lastState,
      option: {
        ...lastState.option,
        isSubmittingImage: payload.status,
      },
    };
  else if (payload.type === "delete_image")
    return {
      ...lastState,
      option: {
        ...lastState.option,
        isDeletingImage: payload.status,
      },
    };
  return {
    ...lastState,
    option: { ...lastState.option, isFetch: false, isSubmit: false },
  };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SURVEY_LIST_FETCH: {
      const { data, status_code, message } = action.payload;
      return {
        ...state,
        list: { ...data, message, status_code },
        detail: {},
        error: null,
      };
    }
    case SURVEY_DETAIL_FETCH: {
      const { data, status_code, message } = action.payload;
      return {
        ...state,
        detail: {
          ...state.detail,
          ...data,
          message,
          delete: false,
          status_code,
        },
        error: null,
      };
    }
    case SURVEY_ADD: {
      const { data, status_code, message } = action.payload;
      return {
        ...state,
        detail: { ...state.detail, ...data, message, status_code },
        success: message,
        error: null,
      };
    }
    case SURVEY_UPDATE: {
      const { data, status_code, message } = action.payload;
      return {
        ...state,
        detail: { ...state.detail, ...data, message, status_code },
        success: message,
        error: null,
      };
    }
    case SURVEY_DELETE: {
      const { data, status_code, message } = action.payload;
      return {
        ...state,
        detail: {
          ...state.detail,
          ...data,
          message,
          delete: true,
          status_code,
        },
        success: message,
        error: null,
      };
    }
    case SURVEY_ERROR:
      return { ...state, ...action.payload, success: null };

    case SURVEY_OPTION_LIST_FETCH: {
      const { data, status_code, message } = action.payload;
      return {
        ...state,
        option: {
          ...state.option,
          list: { ...data, message, status_code },
          detail: {},
          error: null,
        },
      };
    }
    case SURVEY_OPTION_DETAIL_FETCH: {
      const { data, status_code, message } = action.payload;
      return {
        ...state,
        option: {
          ...state.option,
          detail: {
            ...state.option.detail,
            ...data,
            status_code,
          },
          message,
          error: null,
        },
      };
    }

    case SURVEY_OPTION_ADD: {
      const { data, status_code, message } = action.payload;
      return {
        ...state,
        option: {
          ...state.option,
          detail: { ...state.detail, ...data, message, status_code },
          success: message,
          error: null,
        },
      };
    }

    case SURVEY_OPTION_UPDATE: {
      const { data, status_code, message } = action.payload;
      return {
        ...state,
        option: {
          ...state.option,
          detail: { ...state.detail, ...data, message, status_code },
          success: message,
          error: null,
        },
      };
    }
    case SURVEY_OPTION_DELETE: {
      const { data, status_code, message } = action.payload;
      return {
        ...state,
        option: {
          ...state.option,
          detail: { ...state.detail, ...data, message, status_code, delete: true },
          success: message,
          error: null,
        },
      };
    }
    case SURVEY_OPTION_ERROR:
      return { ...state, option: { ...state.option, ...action.payload, success: null } };

    case ADD_IMAGE_SURVEY: {
      const { data, message } = action.payload;
      const addedImages = { ...state.addedImages };
      addedImages[action.payload.identifier] = {
        ...data,
        message,
      };
      return { ...state, addedImages };
    }
    case DELETE_IMAGE_SURVEY: {
      const { deletedImages } = { ...state };
      deletedImages.push(action.payload.identifier);
      return { ...state, deletedImages };
    }

    case ADD_IMAGE_SURVEY_OPTION: {
      const { data, message, status_code } = action.payload;
      const addedImages = { ...state.addedImages };
      addedImages[action.payload.identifier] = {
        ...data,
        message,
      };
      return {
        ...state,
        option: {
          ...state.option,
          addedImages,
        },
      };
    }
    case DELETE_IMAGE_SURVEY_OPTION: {
      const { deletedImages } = { ...state };
      deletedImages.push(action.payload.identifier);
      return {
        ...state,
        option: {
          ...state.option,
          deletedImages,
        },
      };
    }

    case SURVEY_FETCHING:
      return onFetching(state, action.payload);
    case SURVEY_OPTION_FETCHING:
      return onFetchingOption(state, action.payload);
    default:
      return state;
  }
};

export default reducer;
