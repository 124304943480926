import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { getDetailSetting } from '../../../actions/settingAction';
import FormCard from './FormCard';


class SettingForm extends React.Component {
  constructor(props){
    super(props);
    this.state={
      _id:this.props.match.params.id,
      detail: null
    }

    this._refreshDetail = this._refreshDetail.bind(this)
  }

  componentDidMount(){
    if(this.state._id)
      this.props.find(this.state._id);
  }

  _refreshDetail(){
    if(this.state._id)
      this.props.find(this.state._id);
  }

  componentDidUpdate(prevProps) {
    if (this.props.setting.isFetch !== prevProps.setting.isFetch)
    {
      const { error } = this.props.setting;
      if (!error) {
        this.setState({
          _id: this.state._id,
          detail: this.props.setting
        })
      }
    }
  }

  render(){
    return(
      <Fragment>
        {this.state.detail && (
          <>
            <FormCard
              formTitle="Setting Detail"
              edit={this.props.edit}
              setting={this.state.detail}
              {...this.props}
            />
          </>
        )}
      </Fragment>
    )
  }
}
const mapStateToProps= (state) => {
	return {
    setting: state.setting
	}
};
const mapDispatchToProps = (dispatch) => {
	return{
    find:(id) => dispatch(getDetailSetting(id)),
	}
};

export default connect(mapStateToProps,mapDispatchToProps)(SettingForm);
