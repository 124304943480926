import React from "react";
import Paginations from "../../../../../../../components/Paginations/Paginations";

const Pagination = (props) => {
    const { response, setPage, isFetching } = props;
    return (
        <Paginations
            isFetching={isFetching}
            size="sm"
            maxPage={8}
            lastPage={response?.last_page}
            perPage={response?.per_page}
            total={response?.total}
            currentPage={response?.current_page}
            prevPageUrl={response?.prev_page_url}
            nextPageUrl={response?.next_page_url}
            handleGotoCallback={setPage}
        />
    );
};

export default Pagination;
