import React, {Component} from 'react'
import {Button} from 'reactstrap'
import classNames from 'classnames'
import propTypes from 'prop-types'
import { Link } from 'react-router-dom';
import { Toolbars as ToolBarContainer } from '../../../../components/Card/CardHeaderWithToolbars'

// import connect from 'react-redux'

class Toolbars extends Component {
  render(){
    return(
      <ToolBarContainer>
        <Link
          to='/sliders/create'
          className="btn btn-sm btn-primary with-toolbars__toolbars__btn" 
          name="Create slider"
          target="_blank"
        >
          <i className="fa fa-plus"></i>
        </Link>
        <Button
          name="Refresh"
          className="with-toolbars__toolbars__btn"
          size="sm"
          color="info"
          onClick={this.props.onRefreshList}
        > 
          <i className="fa fa-refresh"></i>
        </Button>
        <Button 
          title="Reset filter"
          className="with-toolbars__toolbars__btn"
          size="sm"
          color="warning"
          onClick={this.props.onResetFilter}> 
          <i className="fa fa-ban"></i>
        </Button>
        <Button 
          name="Search and Filter Data"
          size="sm"
          className={classNames("with-toolbars__toolbars__btn",{"btn-danger":this.props.isOpen})} 
          onClick={this.props.onToggleFilter}> 
          <i className={classNames("fa",{"fa-sliders":!this.props.isOpen, "fa-minus":this.props.isOpen})}></i>
        </Button>
      </ToolBarContainer>
    )
  }
}

export default Toolbars