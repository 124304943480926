import React, { PureComponent as Component } from 'react';
import { Alert, Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';

import propTypes from 'prop-types';

export default class ModalDelete extends Component{
  render(){
    return(
      <Modal size={this.props.size} className="modal-confirmation" isOpen={this.props.isOpen} toggle={this.props.toggle}>
        <ModalHeader toggle={this.props.toggle} className="modal-header--center">
          { this.props.modalTitle }
        </ModalHeader>
        <ModalBody>
          {this.props.error ? (
            <Alert
              color='danger'
              className='text-center'
              style={{ marginTop: '1rem' }}
            >
              <strong>{this.props.error?.message}</strong>
            </Alert>
          ) : (
            <p className='text-center'>{this.props.modalBody}</p>
          )}
        </ModalBody>
        <ModalFooter>
          {this.props.error ? null : (
            <>
              <Button color='primary' onClick={this.props.onDelete}>
                Yes
              </Button>{' '}
              <Button color='secondary' onClick={this.props.toggle}>
                Cancel
              </Button>
            </>
          )}
        </ModalFooter>
      </Modal>
    )
  }
}

ModalDelete.propTypes={
  modalTitle: propTypes.string,
  modalBody: propTypes.oneOfType([propTypes.string, propTypes.node]),
  size: propTypes.oneOf(['sm','md','lg']),
  isOpen: propTypes.bool.isRequired,
  toggle: propTypes.func.isRequired,
  onDelete: propTypes.func.isRequired,
  error: propTypes.object
}
ModalDelete.defaultProps={
  modalTitle: 'Confirmation',
  modalBody: 'Are you sure to delete this?',
  size: 'sm'
}