import React, { PureComponent as Component } from 'react';
import propTypes from 'prop-types';
import {
  Card,
  CardHeader,
  CardBody,
  CardImg,
  CardSubtitle,
  Button
} from 'reactstrap'
import Loading from 'react-loading-animation';
import noImage from '../../../assets/img/no-image.png';
import classNames from 'classnames';
import {
  FieldRow,
  FieldColumn,
  FieldLabel,
  FieldValue,
} from '../../../styled/Fields';
import { _getValue } from '../../../utils/form';
import { DELIVERING_STATUS,  } from '../../../constants/kickavenue';
import { getCacheImage } from '../../../utils/imageHelper'
import ModalEditKaSent from './Modals/ModalEditKaSent';
import styled from 'styled-components';
import ModalSaleLogs from './Modals/ModalSaleLogs';
const ButtonWrapperRight = styled.div`
  display: flex;
  flex:1;
  flex-direction: column;
  justify-content: center;
  padding:0 1rem;
`

const getPublicImageUrl = image => {
  return image.signed_url || image.image_url
}

export default class GeneralInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      _modalOpen: false,
      _isViewLogsModalOpen: false
    }

    this.toggleModalKaSent = this.toggleModalKaSent.bind(this)
  }

  toggleModalViewLog() {
    this.setState({ _isViewLogsModalOpen: !this.state._isViewLogsModalOpen })
  }

  toggleModalKaSent() {
    this.setState({ _modalOpen: !this.state._modalOpen })
  }

  render(){
    const { legit_check_logs } = this.props.order;
    const content = !this.props.isLoading && (
      <FieldRow>
        <FieldColumn className="col-xs-12 col-6">
          <FieldLabel>ID</FieldLabel>
          <FieldValue>
            <span>{_getValue(this.props.order.id)}</span>
          </FieldValue>
        </FieldColumn>
        <ButtonWrapperRight>
          <div style={{display: 'flex', justifyContent: 'flex-start'}}>
            <Button onClick={() => this.toggleModalViewLog()}>View Logs</Button>
          </div>
        </ButtonWrapperRight>
        <FieldColumn className="col-12">
          <FieldLabel>Invoice Numb.</FieldLabel>
          <FieldValue>
            <span>{_getValue(this.props.order.invoice_number)}</span>
          </FieldValue>
        </FieldColumn>
        <FieldColumn className="col-12">
          <FieldLabel>Order Date</FieldLabel>
          <FieldValue>
            <span>{_getValue(this.props.order.created_at)}</span>
          </FieldValue>
        </FieldColumn>
        <FieldColumn className="col-12">
          <FieldLabel>Buyer Email</FieldLabel>
          <FieldValue>
            <span>{_getValue(this.props.order.buyer_email)}</span>
          </FieldValue>
        </FieldColumn>
        <hr/>
        <FieldColumn className="col-12">
          <FieldLabel>Seller Email</FieldLabel>
          <FieldValue>
            <span>{_getValue(this.props.seller.email)}</span>
          </FieldValue>
        </FieldColumn>
        <FieldColumn className="col-xs-12 col-md-6">
          <FieldLabel>Seller Confirmation</FieldLabel>
          <FieldValue>
            <span>{_getValue(this.props.order.seller_responded)}</span>
          </FieldValue>
        </FieldColumn>
        <FieldColumn className="col-xs-12 col-md-6">
          <FieldLabel>Seller Sent</FieldLabel>
          <FieldValue>
            <span>{_getValue(this.props.order.seller_sent)}</span>
          </FieldValue>
        </FieldColumn>
        <FieldColumn className="col-xs-12 col-md-6">
          <FieldLabel>Seller AWB</FieldLabel>
          <FieldValue>
            <span>{_getValue(this.props.order.seller_awb_number)}</span>
          </FieldValue>
        </FieldColumn>
        <FieldColumn className="col-xs-12 col-md-6">
          <FieldLabel>Seller Cancelled</FieldLabel>
          <FieldValue>
            <span>{_getValue(this.props.order.cancelled_at)}</span>
          </FieldValue>
        </FieldColumn>
        <FieldColumn className="col-xs-12 col-md-6">
          <FieldLabel>Self Drop-off Schedule</FieldLabel>
          <FieldValue>
            <span>{_getValue(this.props.order?.drop_off_schedule)}</span>
          </FieldValue>
        </FieldColumn>
        <hr/>
        <FieldColumn className="col-xs-12 col-md-6">
          <FieldLabel>On Warehouse</FieldLabel>
          <FieldValue>
            <span>{_getValue(this.props.order.ka_received)}</span>
          </FieldValue>
        </FieldColumn>
        <FieldColumn className="col-xs-12 col-md-12">
          <FieldLabel>Receiver Staff</FieldLabel>
          <FieldValue>
            <span>{_getValue(this.props.order.ka_received_by, '-')}</span>
          </FieldValue>
        </FieldColumn>
        { legit_check_logs.map((item, index) => {
          return(
            <>
              <FieldColumn className="col-xs-12 col-md-6">
                <FieldLabel>Verified By (LC) {index+1}</FieldLabel>
                <FieldValue>
                  <span>{_getValue(item.user.email, '-')}</span>
                </FieldValue>
              </FieldColumn>
              <FieldColumn className="col-xs-12 col-md-6">
                <FieldLabel>Verified At <i title={item.created_at?"Verified":"Not Verified"} className={classNames("fa",{"fa-times text-danger":!this.props.order.ka_verified,"fa-check":this.props.order.ka_verified})}></i></FieldLabel>
                <FieldValue>
                  <span>{_getValue(item.created_at)}</span>
                </FieldValue>
              </FieldColumn>
           </>
          );
        })}
        <hr/>
        <FieldColumn className="col-xs-12 col-md-6">
          <FieldLabel>KA Courier</FieldLabel>
          <FieldValue>
            <span>{_getValue(this.props.shipping.ka_courier)}</span>
          </FieldValue>
        </FieldColumn>
        <FieldColumn className="col-xs-12 col-md-6">
          <FieldLabel>KA Courier Option</FieldLabel>
          <FieldValue>
            <span>{_getValue(this.props.shipping.ka_courier_option)}</span>
          </FieldValue>
        </FieldColumn>
        <FieldColumn className="col-xs-12 col-md-6">
          <FieldLabel>KA Courier Price</FieldLabel>
          <FieldValue>
            <span>{_getValue(this.props.shipping.ka_courier_price)}</span>
          </FieldValue>
        </FieldColumn>
        <FieldColumn className="col-xs-12 col-md-6">
          <FieldLabel>
            Shipped At
            {
              this.props.order.ka_sent? (
                <a
                  href="#"
                  style={{fontSize: '.75rem', pointerEvents: this.props.order.status !== DELIVERING_STATUS? "none": "auto"}}
                  className={classNames("ml-3", {"disabled": this.props.order.status !== DELIVERING_STATUS})}
                  onClick={e => {
                    e.preventDefault();
                    this.toggleModalKaSent();
                  }}
                >
                  <i className="fa fa-edit"></i> Edit
                </a>
              ):null
            }
          </FieldLabel>
          <FieldValue>
            <span>{_getValue(this.props.order.ka_sent)}</span>
          </FieldValue>
        </FieldColumn>
        <FieldColumn className="col-xs-12 col-md-6">
          <FieldLabel>Customer Received</FieldLabel>
          <FieldValue>
            <span>{_getValue(this.props.order.customer_received)}</span>
          </FieldValue>
        </FieldColumn>
        <FieldColumn className="col-xs-12 col-md-6">
          <FieldLabel>Disbursed At</FieldLabel>
          <FieldValue>
            <span>{_getValue(this.props.order.money_disbursed)}</span>
          </FieldValue>
        </FieldColumn>
        <FieldColumn className="col-xs-1 2 col-md-12">
          <FieldLabel>Notes</FieldLabel>
          <FieldValue>
            <span>{_getValue(this.props.order.notes)}</span>
          </FieldValue>
        </FieldColumn>
        <hr/>
        <FieldColumn className="col-xs-12 col-md-6">
          <FieldLabel>Hijacked At</FieldLabel>
          <FieldValue>
            <span>{_getValue(this.props.order.hijacked_at)}</span>
          </FieldValue>
        </FieldColumn>
        <FieldColumn className="col-xs-12 col-md-6">
          <FieldLabel>Force Cancelled At</FieldLabel>
          <FieldValue>
            <span>{_getValue(this.props.order.force_cancelled_at)}</span>
          </FieldValue>
        </FieldColumn>
        <FieldColumn className="col-xs-12 col-md-6">
          <FieldLabel>Auto Cancelled At</FieldLabel>
          <FieldValue>
            <span>{_getValue(this.props.order.auto_cancelled_at)}</span>
          </FieldValue>
        </FieldColumn>
        <hr/>
        <FieldColumn className="col-xs-12 col-md-6">
          <FieldLabel>Created</FieldLabel>
          <FieldValue>
            <span>{_getValue(this.props.order.created_at)}</span>
          </FieldValue>
        </FieldColumn>
        <FieldColumn className="col-xs-12 col-md-6">
          <FieldLabel>Created Src.</FieldLabel>
          <FieldValue>
            <span>{_getValue(this.props.order.created_source, '-')}</span>
          </FieldValue>
        </FieldColumn>
        <FieldColumn className="col-xs-12 col-md-6">
          <FieldLabel>Updated</FieldLabel>
          <FieldValue>
            <span>{_getValue(this.props.order.updated_at)}</span>
          </FieldValue>
        </FieldColumn>
        <FieldColumn className="col-xs-12 col-md-6">
          <FieldLabel>Updated Src.</FieldLabel>
          <FieldValue>
            <span>{_getValue(this.props.order.updated_source, '-')}</span>
          </FieldValue>
        </FieldColumn>
      </FieldRow>
    ) || <Loading/>;
    const getImage = (!this.props.isLoading && getCacheImage(getPublicImageUrl(this.props.product))) || noImage;
    return (      
      <>
      <ModalEditKaSent
        id={this.props.order.id}
        deliveredAt={this.props.order.ka_sent}
        isOpen={this.state._modalOpen}
        toggle={this.toggleModalKaSent}
      />
      <ModalSaleLogs
        isOpen={this.state._isViewLogsModalOpen}
        toggle={() => this.setState({_isViewLogsModalOpen: !this.state._isViewLogsModalOpen})}
        isLoading={this.props.isLoading}
        data={this.props.order.logs}
        rollback={this.props.order.rollback}
        total={1}
      />
      <Card>
        <CardHeader>
          {this.props.headerText}
        </CardHeader>
        <CardImg top width="100%" src={ getImage } alt="Card image cap" />
        <CardBody>
          <CardSubtitle style={{marginBottom:15}}>{this.props.product.display_name}</CardSubtitle>
          {content}
        </CardBody>
      </Card>
      </>
    );
  }
}
GeneralInfo.propTypes = {
  headerText: propTypes.oneOfType([propTypes.string, propTypes.node]),
  isLoading: propTypes.bool,
  order: propTypes.object,
  product: propTypes.object,
  seller: propTypes.object
}
GeneralInfo.defaultProps = {
  isLoading: false,  
  headerText: "General",
  order: {},
  product: {},
  seller: {}
}