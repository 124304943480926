import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Form,
  Label,
  FormGroup,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import classnames from "classnames";
import moment from 'moment-timezone';
import { hasError, errorMessage, textValue } from "../../../../utils/form";
import InputDateTime from "../../../../components/Form/InputDatetime/InputDatetime";
import { updateSale } from "../../../../actions/saleAction";

const initialState = {
  _formSubmit: false,
  _form: {
    id: "",
    delivered_at: "",
    binding: false
  }
}

class ModalEditKaSent extends Component {

  constructor(props) {
    super(props);
    this.state = { ...initialState }
    this.onCloseModal = this.onCloseModal.bind(this);
    this.onSubmit = this.onSubmit.bind(this)
    moment.tz('Asia/Jakarta');
  }

  componentDidUpdate(prevProps) {
    const { isSubmit, error } = this.props.sale;

    if(this.props.isOpen && this.props.isOpen !== prevProps.isOpen){
      const { deliveredAt } = this.props;
      const parsed = moment(deliveredAt);
      this.setState({
        _form: {
          ...this.state._form,
          delivered_at: parsed.isValid()? parsed: deliveredAt,
        }
      })
    }

    if (this.props.id && !this.state._form.binding) {
      const { deliveredAt } = this.props;
      const parsed = moment(deliveredAt);
      this.setState({
        ...this.state,
        _form: {
          id: this.props.id,
          delivered_at: parsed.isValid()? parsed: deliveredAt,
          binding: true
        }
      })
    }

    if (this.state._formSubmit && isSubmit !== prevProps.sale.isSubmit) {
      if (!error) {
        this.setState({ ...this.state, _formSubmit: false })
        this.props.toggle()
      }
    }
  }

  onCloseModal () {
    this.props.toggle()
    this.setState({ ...initialState })
  }

  handleDatePickerChange(value, name) {
    // const _form = dateChange(value, name, this.state._form);
    const { _form } = this.state;
    this.setState({
      _form: {
        ..._form,
        [name]: value
      }
    });
  }

  onSubmit(e) {
    e.preventDefault();
    this.setState({ _formSubmit: true }, () => {
      const { id, delivered_at } = this.state._form;
      const ka_sent = delivered_at === ''? null:(
        moment.isMoment(delivered_at)? delivered_at.format("YYYY-MM-DD HH:mm"):
        delivered_at);
      if(!ka_sent){
        alert("The field is required.")
        return
      }
      this.props.update(id, { ka_sent });
    });
  }

  render() {
    return (
      <Modal
        isOpen={this.props.isOpen}
        toggle={this.onCloseModal}
        className="modal-dialog modal-sm modal-confirmation"
      >
        <ModalHeader
          className="modal-header--center"
          toggle={this.onCloseModal}
        >
          Update Delivery Time
        </ModalHeader>
        <ModalBody>
          <Form onSubmit={this.onSubmit}>
            <FormGroup
              className={classnames({
                "has-danger has-feedback": hasError(
                  "awb_number",
                  this.state.error
                ),
              })}
            >
              <Label for="formAwbNumber">Delivered at</Label>
              <InputDateTime
                inputProps={{
                  placeholder: "Pick delivered at",
                  id: "delivered_at",
                  autoComplete: "off",
                }}
                value={textValue("delivered_at", this.state._form)}
                onChange={(date) =>
                  this.handleDatePickerChange(date, "delivered_at")
                }
                timeFormat="HH:mm"
              />
              {errorMessage("awb_number", this.state.error)}
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            disabled={this.props.sale.isSubmit}
            onClick={this.onSubmit}
          >
            {this.props.isSubmit ? "Submitting..." : "Submit"}
          </Button>{" "}
          <Button
            color="secondary"
            disabled={this.props.sale.isSubmit}
            onClick={this.onCloseModal}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    sale: state.sale,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    update: (id, payload) => dispatch(updateSale(id, payload)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ModalEditKaSent);
