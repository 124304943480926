import {
  AUTH_TOKEN_KEY,
  ADD_IMAGE_SURVEY,
  ADD_IMAGE_SURVEY_OPTION,
  DELETE_IMAGE_SURVEY,
  DELETE_IMAGE_SURVEY_OPTION,
  SURVEY_ADD,
  SURVEY_DELETE,
  SURVEY_DETAIL_FETCH,
  SURVEY_ERROR,
  SURVEY_FETCHING,
  SURVEY_LIST_FETCH,
  SURVEY_OPTION_ADD,
  SURVEY_OPTION_DELETE,
  SURVEY_OPTION_DETAIL_FETCH,
  SURVEY_OPTION_ERROR,
  SURVEY_OPTION_FETCHING,
  SURVEY_OPTION_LIST_FETCH,
  SURVEY_OPTION_UPDATE,
  SURVEY_ROUTE_API,
  SURVEY_UPDATE,
} from "../constants";
import api from "../utils/Api";
import { manipulateErrors, checkAuthorized } from "../utils";
import { logout } from "./authAction";

/**
 * Indicates when start fetch api or end fetch api
 * @author haikal
 * @param {boolean} bool
 */
export const isFetch = (payload) => ({
  type: SURVEY_FETCHING,
  payload: payload,
});

  /**
   * Indicates when start fetch api or end fetch api
   * @author haikal
   * @param {boolean} bool
   */
  export const isFetchOption = (payload) => ({
    type: SURVEY_OPTION_FETCHING,
    payload: payload,
  });

/**
 * When onSuccess triggered
 * @author haikal
 * @param {object} res
 * @param {string} type
 */
export const onSuccess = (res, type) => ({
  type: type,
  payload: res,
});
/**
 * When error fetch or connect to api
 * @author haikal
 * @param {object} err
 */
// On Error fetching api
export const onError = (err) => {
  if (!checkAuthorized(manipulateErrors(err))) return logout();
  return {
    type: SURVEY_ERROR,
    payload: manipulateErrors(err),
  };
};

/**
 * When error fetch or connect to api
 * @author haikal
 * @param {object} err
 */
// On Error fetching api
export const onErrorOption = (err) => {
  if (!checkAuthorized(manipulateErrors(err))) return logout();
  return {
    type: SURVEY_OPTION_ERROR,
    payload: manipulateErrors(err),
  };
};

export const getSurveyList = (query) => {
  return (dispatch) => (
    dispatch(isFetch({ type: "fetch", status: true })),
    api({
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`,
    })
      .get(SURVEY_ROUTE_API, query)
      .then((res) => {
        dispatch(onSuccess(res.data, SURVEY_LIST_FETCH));
        dispatch(isFetch({ type: "fetch", status: false }));
      })
      .catch((err) => {
        dispatch(onError(err));
        dispatch(isFetch({ type: "fetch", status: false }));
      })
  );
};

export const getDetailSurvey = (id) => {
  return (dispatch) => (
    dispatch(isFetch({ type: "fetch", status: true })),
    api({
      Authorization: "Bearer " + localStorage.getItem(AUTH_TOKEN_KEY),
    })
      .get(`${SURVEY_ROUTE_API}/${id}`)
      .then((res) => {
        dispatch(onSuccess(res.data, SURVEY_DETAIL_FETCH));
        dispatch(isFetch({ type: "fetch", status: false }));
      })
      .catch((err) => {
        dispatch(onError(err));
        dispatch(isFetch({ type: "fetch", status: false }));
      })
  );
};

export const saveSurvey = (payload, id) => {
  return (dispatch) => {
    if (id === undefined) dispatch(createSurvey(payload));
    else dispatch(updateSurvey(id, payload));
  };
};

export const createSurvey = (payload) => {
  return (dispatch) => (
    dispatch(isFetch({ type: "submit", status: true })),
    api({
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`,
    })
      .post(`${SURVEY_ROUTE_API}`, payload)
      .then((res) => {
        dispatch(onSuccess(res.data, SURVEY_ADD));
        dispatch(isFetch({ type: "submit", status: false }));
      })
      .catch((err) => {
        dispatch(onError(err));
        dispatch(isFetch({ type: "submit", status: false }));
      })
  );
};

export const updateSurvey = (id, payload) => {
  return (dispatch) => (
    dispatch(isFetch({ type: "submit", status: true })),
    api({
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`,
    })
      .put(`${SURVEY_ROUTE_API}/${id}`, payload)
      .then((res) => {
        dispatch(onSuccess(res.data, SURVEY_UPDATE));
        dispatch(isFetch({ type: "submit", status: false }));
      })
      .catch((err) => {
        dispatch(onError(err));
        dispatch(isFetch({ type: "submit", status: false }));
      })
  );
};

export const deleteSurvey = (id) => {
  return (dispatch) => (
    dispatch(isFetch({ type: "submit", status: true })),
    api({
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`,
    })
      .delete(`${SURVEY_ROUTE_API}/${id}`)
      .then((res) => {
        dispatch(onSuccess(res.data, SURVEY_DELETE));
        dispatch(isFetch({ type: "submit", status: false }));
      })
      .catch((err) => {
        dispatch(onError(err));
        dispatch(isFetch({ type: "submit", status: false }));
      })
  );
};

export const addImageSurvey=(identifier,id,payload)=>{
	return dispatch => (
		dispatch(isFetch({type:'submit_image',status:true})),
		api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).put(`${SURVEY_ROUTE_API}/${id}`,payload)
		.then(res=>{
			dispatch(onSuccess({ ...res.data, identifier },ADD_IMAGE_SURVEY))
			dispatch(isFetch({type:'submit_image',status:false}))
		})
		.catch(err => {
			dispatch(onError(err));
			dispatch(isFetch({type:'submit_image',status:false}));
		})
	)
}

export const deleteImageSurvey=(identifier,id,payload)=>{
	return dispatch => (
		dispatch(isFetch({type:'delete_image',status:true})),
		api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).put(`${SURVEY_ROUTE_API}/${id}`, payload)
		.then(res=>{
			dispatch(onSuccess({ ...res.data, identifier },DELETE_IMAGE_SURVEY))
			dispatch(isFetch({type:'delete_image',status:false}))
		})
		.catch(err => {
			dispatch(onError(err));
			dispatch(isFetch({type:'delete_image',status:false}));
		})
	)
}

export const getSurveyOptionlList = (query) => {
  return (dispatch) => (
    dispatch(isFetchOption({ type: "fetch", status: true })),
    api({
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`,
    })
      .get(`${SURVEY_ROUTE_API}/options`, query)
      .then((res) => {
        dispatch(onSuccess(res.data, SURVEY_OPTION_LIST_FETCH));
        dispatch(isFetchOption({ type: "fetch", status: false }));
      })
      .catch((err) => {
        dispatch(onErrorOption(err));
        dispatch(isFetchOption({ type: "fetch", status: false }));
      })
  );
};

export const getDetailSurveyOption = (id) => {
  return (dispatch) => (
    dispatch(isFetchOption({ type: "fetch", status: true })),
    api({
      Authorization: "Bearer " + localStorage.getItem(AUTH_TOKEN_KEY),
    })
      .get(`${SURVEY_ROUTE_API}/options/${id}`)
      .then((res) => {
        dispatch(onSuccess(res.data, SURVEY_OPTION_DETAIL_FETCH));
        dispatch(isFetchOption({ type: "fetch", status: false }));
      })
      .catch((err) => {
        dispatch(onErrorOption(err));
        dispatch(isFetchOption({ type: "fetch", status: false }));
      })
  );
};

export const saveSurveyOption = (payload, id) => {
  return (dispatch) => {
    if (id === undefined) dispatch(createSurveyOption(payload));
    else dispatch(updateSurveyOption(id, payload));
  };
};

export const createSurveyOption = (payload) => {
  return (dispatch) => (
    dispatch(isFetchOption({ type: "submit", status: true })),
    api({
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`,
    })
      .post(`${SURVEY_ROUTE_API}/options`, payload)
      .then((res) => {
        dispatch(onSuccess(res.data, SURVEY_OPTION_ADD));
        dispatch(isFetchOption({ type: "submit", status: false }));
      })
      .catch((err) => {
        dispatch(onErrorOption(err));
        dispatch(isFetchOption({ type: "submit", status: false }));
      })
  );
};

export const updateSurveyOption = (id, payload) => {
  return (dispatch) => (
    dispatch(isFetchOption({ type: "submit", status: true })),
    api({
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`,
    })
      .put(`${SURVEY_ROUTE_API}/options/${id}`, payload)
      .then((res) => {
        dispatch(onSuccess(res.data, SURVEY_OPTION_UPDATE));
        dispatch(isFetchOption({ type: "submit", status: false }));
      })
      .catch((err) => {
        dispatch(onErrorOption(err));
        dispatch(isFetchOption({ type: "submit", status: false }));
      })
  );
};

export const deleteSurveyOption = (id) => {
  return (dispatch) => (
    dispatch(isFetchOption({ type: "submit", status: true })),
    api({
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`,
    })
      .delete(`${SURVEY_ROUTE_API}/options/${id}`)
      .then((res) => {
        dispatch(onSuccess(res.data, SURVEY_OPTION_DELETE));
        dispatch(isFetchOption({ type: "submit", status: false }));
      })
      .catch((err) => {
        dispatch(onErrorOption(err));
        dispatch(isFetchOption({ type: "submit", status: false }));
      })
  );
};

export const addImageSurveyOption=(identifier,id,payload)=>{
	return dispatch => (
		dispatch(isFetchOption({type:'submit_image',status:true})),
		api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).put(`${SURVEY_ROUTE_API}/options/${id}`,payload)
		.then(res=>{
			dispatch(onSuccess({ ...res.data, identifier },ADD_IMAGE_SURVEY_OPTION))
			dispatch(isFetchOption({type:'submit_image',status:false}))
		})
		.catch(err => {
			dispatch(onErrorOption(err));
			dispatch(isFetchOption({type:'submit_image',status:false}));
		})
	)
}

export const deleteImageSurveyOption=(identifier,id,payload)=>{
	return dispatch => (
		dispatch(isFetchOption({type:'delete_image',status:true})),
		api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).put(`${SURVEY_ROUTE_API}/options/${id}`, payload)
		.then(res=>{
			dispatch(onSuccess({ ...res.data, identifier },DELETE_IMAGE_SURVEY_OPTION))
			dispatch(isFetchOption({type:'delete_image',status:false}))
		})
		.catch(err => {
			dispatch(onErrorOption(err));
			dispatch(isFetchOption({type:'delete_image',status:false}));
		})
	)
}
