import React from 'react';
import {
  Modal,
  ModalBody,
  Card
} from 'reactstrap';
import CardLoadingPlaceholder from '../Card/CardLoadingPlaceholder';

export const ModalBlockLoading = props => (
  <Modal size="sm" className="modal-confirmation" isOpen={props.isOpen}>
    <ModalBody>
      <Card style={{height: 120, border: 'none', margin: 0}}>
        <CardLoadingPlaceholder
          isVisible={true}
          loadingColor="green"
          loadingText="Please wait..."
        />
      </Card>
    </ModalBody>
  </Modal>
)