import React, { Component } from 'react';
import { Button, Card } from 'reactstrap';
import { connect } from 'react-redux';
import { logout } from '../../../actions/authAction';
import classNames from 'classnames';

class Page403 extends Component {
  constructor(props){
    super(props);
    this.onLogoutClick = this.onLogoutClick.bind(this);
  }
  componentWillReceiveProps(nextProps){
    if(nextProps.authData.isLogged === false&&nextProps.authData.isLogged!==this.props.authData.isLogged){
      this.props.history.replace('/login');
    }
  }
  onLogoutClick(){
    this.props.doLogout();
  }
  render() {
    return (
      <div className="app flex-row align-items-center">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-6">
              <div className="clearfix">
                <Card block>
                  <div className="d-flex" style={{alignItems:'center'}}>
                    <h1 className="display-3 mr-4 text-center">403</h1>
                    <div>
                      <h4 className="pt-3">Access Denied/Forbidden!</h4>
                      <p className="text-muted">
                        The page or resource you were trying to reach is absolutely forbidden for some reason.
                        <br/>
                        <Button onClick={this.onLogoutClick} className={classNames({'d-none': this.props.authData.isLogged===false})} color="primary" style={{marginTop:15}}>Try to logout.</Button>
                      </p>
                    </div>
                  </div>
                </Card>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ auth }) =>({
  authData: auth
})

const mapDispatchToProps = dispatch => ({
  doLogout: () => dispatch(logout())
})

export default connect(mapStateToProps, mapDispatchToProps)(Page403);


