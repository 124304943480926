import {
	GET_LIST_PRODUCT_VARIANT,
	GET_DETAIL_PRODUCT_VARIANT,
	ADD_PRODUCT_VARIANT,
	UPDATE_PRODUCT_VARIANT,
    DELETE_PRODUCT_VARIANT,
    PRODUCT_VARIANT_ERROR,
    NEW_PRODUCT_VARIANT,
	FETCHING_PRODUCT_VARIANT,
	ATTACH_COLLECTION_PRODUCT_VARIANT,
	DETACH_COLLECTION_PRODUCT_VARIANT,
	ADD_IMAGE_PRODUCT_VARIANT,
	DELETE_IMAGE_PRODUCT_VARIANT,
	ATTACH_SIZE_PRODUCT_VARIANT,
	DETACH_SIZE_PRODUCT_VARIANT,
	SAVE_VARIANT_COURIER,
	SAVE_VARIANT_PAYMENT_METHOD,
	GET_DETAIL_PRODUCT_VARIANT_STOCK_SIZE,
	ATTACH_VOUCHER_PRODUCT_VARIANT,
	DETACH_VOUCHER_PRODUCT_VARIANT,
	ADD_SUBSIDY,
	UPDATE_SUBSIDY,
	GET_SUBSIDY,
	SUBSIDY_ERROR,
	DELETE_SUBSIDY,
	REORDER_VARIANT_IMG_SUBMIT,
	REORDER_VARIANT_IMG,
	UPDATE_COMMISSION,
	ADD_COMMISSION,
	COMMISSION_ERROR,
	DELETE_COMMISSION
} from '../constants';

/**
 * Initial State
 */
const initialState={
	list:[],
	detail:{},
	detailStock:{},
	addedImages:{},
	isSubmittingImage:false,
	deletedImages:[],
	isDeletingImage:false,
	isFetch:false,
	isSubmit:false,
	error:null,
	success:null,
	subsidy:{
		list:[],
		isFetch:false,
		isSubmit:false,
		isDelete:false,
		error:null,
		success:null
	},
	reorderImages: {
		data: null,
		status_code: 0,
		isSubmit: false,
		manyImages: false
	},	
	commission:{
		list:[],
		isFetch:false,
		isSubmit:false,
		isDelete:false,
		error:null,
		success:null
	},
}

/**
 * Function to manipulate payload when success get offer list
 * @author pewe
 * @param {object} lastState 
 * @param {object} payload 
 */
const onGetProductVariantList = (lastState,payload) => {
	const {data, message, status_code}=payload;
	return { ...lastState, error:null, success:null,detail:{}, list:{ ...data, message, status_code } };	
}

/**
 * Function to manipulate payload when success get offer details
 * @author pewe
 * @param {object} lastState 
 * @param {object} payload 
 */
const onGetDetailProductVariant = (lastState,payload, key = null) => {
	const {data, message, status_code}=payload;
	let body = { ...data, updatedKey: null };
	if(key){
		body = { ...lastState.detail, [key]: data[key], updatedKey: key }
	}
	return { ...lastState, error:null, success:null, detail:{ ...lastState.detail, ...body, message, status_code }, commission:{...lastState.commission, list:data.variant_seller_commissions,  message, status_code} };	
}

const onGetDetailProductVariantStock = (lastState,payload) => {
	const {data, message, status_code}=payload;
	return { ...lastState, error:null, success:null, detailStock:{ ...data, message, status_code } };	
}

/**
 * Function to handle when start or end fetching offer api
 * @author pewe
 * @param {object} lastState 
 * @param {objec} bool 
 */
const onFetchingProductVariant = (lastState,payload) => {
    if(payload.type==='fetch')
        return { ...lastState, isFetch:payload.status }
		else if(payload.type==='submit')
			return { ...lastState, isSubmit:payload.status }
		else if(payload.type==='submit_image')
			return { ...lastState, isSubmittingImage:payload.status }
		else if(payload.type==='delete_image')
			return { ...lastState, isDeletingImage:payload.status }
		else if(payload.type==='submit_subsidy')
			return { ...lastState, subsidy:{...lastState.subsidy,isSubmit:payload.status,isDelete:false}}
		else if(payload.type==='submit_commission')
			return { ...lastState, commission:{...lastState.commission,isSubmit:payload.status,isDelete:false}}
		else if(payload.type==='fetch_subsidy')
			return { ...lastState, subsidy:{...lastState.subsidy,isFetch:payload.status,isDelete:false}}
    return { ...lastState, isFetch:false,isSubmit:false,isDeletingImage:false,isSubmittingImage:false }
}

/**
 * Function to handle when fetching offer error triggered
 * @author pewe
 * @param {object} lastState 
 * @param {object} error 
 */
const onProductVariantError = (lastState, payload) => {
	const { error, id } = payload
	return { ...lastState, error: { ...error, id }, success:null };		
}

const onSubsidyError = (lastState, payload) => {
	return { ...lastState, subsidy:{ ...lastState.subsidy, error: { ...payload.error }, success:null} };		
}

const onAddProductVariant = (lastState,payload) => {
	const {data, message, status_code}=payload;
    return { ...lastState, detail:{ ...data, message, status_code }, success:'Insert has been successfully!',error:null }
}

const onUpdateProductVariant = (lastState,payload) => {
	const {data, message, status_code}=payload;
    return { ...lastState, detail:{ ...data, message, status_code }, success:'Update has been successfully!',error:null }
	
}
const onDeleteProductVariant = (lastState,payload) => {
	const {data, message, status_code}=payload;
    return { ...lastState, detail:{ ...data, message, status_code, delete:true }, success:'Delete has been successfully!',error:null }
}

const onAddImageProductVariant=(lastState,payload)=>{
	const {data, message, status_code}=payload;
	const addedImages={...lastState.addedImages};
	addedImages[payload.identifier]={...data,message,status_code};
	return { ...lastState, addedImages }	
}

const onDeleteImageProductVariant=(lastState,payload)=>{
	const { deletedImages }={ ...lastState };
	deletedImages.push(payload.identifier);
	return { ...lastState, deletedImages }	
}

const onAddSubsidy = (lastState,payload) => {
	const {data, message, status_code}=payload;
    return { ...lastState, subsidy:{ ...lastState.subsidy, detail:{data, message, status_code}, success:'Insert has been successfully!',error:null} }
}

const onUpdateSubsidy = (lastState,payload) => {
	const {data, message, status_code}=payload;
    return { ...lastState, subsidy:{ ...lastState.subsidy, detail:{data, message, status_code}, success:'Update has been successfully!',error:null }}
}

const onDeleteSubsidy=(lastState,payload)=>{
	const { data, message, status_code }=payload;
	return {...lastState, subsidy:{...lastState.subsidy, detail:{...data, message, status_code}, isSubmit:false, isDelete:true, success:'Delete has been successfully!',error:null }}
}

const onGetSubsidyList = (lastState,payload) => {
	const {data:{ data, ...paginations}, message, status_code}=payload;
	return { ...lastState, subsidy:{ list:data, ...paginations, detail:{}, message, status_code } };	
}

const onAddCommission = (lastState,payload) => {
	const {data, message, status_code}=payload;
    return { ...lastState, commission:{ ...lastState.commission, detail:{data, message, status_code}, success:'Insert has been successfully!',error:null} }
}

const onUpdateCommission = (lastState,payload) => {
	const {data, message, status_code}=payload;
    return { ...lastState, commission:{ ...lastState.commission, detail:{data, message, status_code}, success:'Update has been successfully!',error:null }}
}

const onDeleteCommission=(lastState,payload)=>{
	const { data, message, status_code }=payload;
	return {...lastState, commission:{...lastState.commission, detail:{...data, message, status_code}, isSubmit:false, isDelete:true, success:'Delete has been successfully!',error:null }}
}

const onCommissionError = (lastState, payload) => {
	return { ...lastState, commission:{ ...lastState.commission, error: { ...payload.error }, success:null} };		
}


/**
 * Offer Reducer
 * @author pewe
 * @param {object} state 
 * @param {object} action 
 */
const productReducer= (state=initialState, action) => {
	switch(action.type)
	{
		case GET_LIST_PRODUCT_VARIANT: return onGetProductVariantList(state,action.payload);
		case GET_DETAIL_PRODUCT_VARIANT: return onGetDetailProductVariant(state,action.payload);
		case FETCHING_PRODUCT_VARIANT: return onFetchingProductVariant(state,action.payload);
		case PRODUCT_VARIANT_ERROR:return onProductVariantError(state,action.payload);
		case UPDATE_PRODUCT_VARIANT:return onUpdateProductVariant(state,action.payload);
		case ADD_PRODUCT_VARIANT:return onAddProductVariant(state,action.payload);
		case DELETE_PRODUCT_VARIANT:return onDeleteProductVariant(state,action.payload);
		case ATTACH_COLLECTION_PRODUCT_VARIANT:return onGetDetailProductVariant(state,action.payload, 'collection_variants');
		case DETACH_COLLECTION_PRODUCT_VARIANT:return onGetDetailProductVariant(state,action.payload, 'collection_variants');
		case ATTACH_SIZE_PRODUCT_VARIANT:return onGetDetailProductVariant(state,action.payload, 'sizes');
		case DETACH_SIZE_PRODUCT_VARIANT:return onGetDetailProductVariant(state,action.payload, 'sizes');
		case SAVE_VARIANT_COURIER:return onGetDetailProductVariant(state,action.payload);
		case SAVE_VARIANT_PAYMENT_METHOD:return onGetDetailProductVariant(state,action.payload);
		case ADD_IMAGE_PRODUCT_VARIANT:return onAddImageProductVariant(state,action.payload);
		case DELETE_IMAGE_PRODUCT_VARIANT:return onDeleteImageProductVariant(state,action.payload);
		case GET_DETAIL_PRODUCT_VARIANT_STOCK_SIZE:return onGetDetailProductVariantStock(state,action.payload);
		case ATTACH_VOUCHER_PRODUCT_VARIANT:return onGetDetailProductVariant(state,action.payload, 'variant_vouchers');
		case DETACH_VOUCHER_PRODUCT_VARIANT:return onGetDetailProductVariant(state,action.payload, 'variant_vouchers');
		case UPDATE_SUBSIDY:return onUpdateSubsidy(state,action.payload);
		case ADD_SUBSIDY:return onAddSubsidy(state,action.payload);
		case GET_SUBSIDY: return onGetSubsidyList(state,action.payload);
		case DELETE_SUBSIDY: return onDeleteSubsidy(state, action.payload);
		case UPDATE_COMMISSION:return onUpdateCommission(state,action.payload);
		case ADD_COMMISSION:return onAddCommission(state,action.payload);
		case DELETE_COMMISSION: return onDeleteCommission(state, action.payload);
		case COMMISSION_ERROR: return onCommissionError(state,action.payload);
		case SUBSIDY_ERROR: return onSubsidyError(state,action.payload);
		case REORDER_VARIANT_IMG_SUBMIT: {
			const { reorderImages } = state;
			return {
				...state,
				reorderImages: {
					...reorderImages,
					isSubmit: action.payload.isSubmit
				}
			}
		}
		case REORDER_VARIANT_IMG: {
			const { reorderImages } = state;
			const { payload: { error, ...p } } = action;
			return {
				...state,
				reorderImages: {
					...reorderImages,
					...p,
					isSubmit: false,
					error: error? error: null
				}
			}
		}
		case NEW_PRODUCT_VARIANT:return initialState;
		default:return state;
	}
}

export default productReducer;