import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Row,
  Col,
  Card,
  CardBody,
  CardText
} from 'reactstrap';
import { getAppSectionList } from '../../actions/appSectionAction';
import privateView from '../../components/hocs/privateView';
import { CardHeaderWithToolbars, Title } from '../../components/Card/CardHeaderWithToolbars';
import { FontAwesomeIcon } from '../../components/Icons';
import Paginations from '../../components/Paginations';
import PaginationSummaries from '../../components/Paginations/PaginationSummaries';
import DateToHuman from '../../components/DateToHuman';
import CheckReadOnly from '../../components/CheckReadOnly';
import { Tbl, TblHead, TblBody } from '../../components/Tbl';
import { generateNumber } from '../../utils';
import { Toolbars, Filters } from './Filters'
import { DEFAULT_SORT_BY, DEFAULT_PER_PAGE } from '../../constants/settings';
import { TitleColumn } from './appSectionIndex/Columns';
import Analytics from "../../services/Analytics";

const dataHeader=[
	'No.', 'Title', 'Active', 'Updated', 'Action'
];

const defaultParams = {
  keyword: '',
  sort_by : DEFAULT_SORT_BY,
  // per_page : 1
  per_page : DEFAULT_PER_PAGE
}
class AppSectionIndex extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      _params:{
        ...defaultParams
      },
      _filterOpen: false
    }
  }

  componentDidMount(){
    this._getList(this.state._params);
    const { email } = this.props.injectedProps;
    Analytics.recordPageView('app_section_list', email, {
      url: window.location.href,
      location: this.props.location,
      match: this.props.match
    });
  }

  _getList(rawParams = {}){
    const params = Object.keys(rawParams)
    .filter(key => rawParams[key]!='')
    .reduce((obj, current)=> ({...obj, [current]: rawParams[current]}), []);
    this.props.getList({params});
  }

  _handleRefresh = (e) => {
    e.preventDefault();
    this._refreshList();
  }

  _refreshList = () => {
    const { _page, ..._params } = this.state._params;
    this.setState({
      _params
    },()=>this._getList(this.state._params));
  }

  _handleToggleFilterPanel = () =>{
    this.setState({_filterOpen: !this.state._filterOpen})
  }

  _handleApplyFilter = p => {
    const { _params } = this.state;
    this.setState({
      _params: {
        ..._params,
        ...p,
        page: 1
      }
    }, () => {
      this._getList(this.state._params)
    })
  }

  _handleResetFilter = (e) =>{
    e.preventDefault();
    this.setState({
      _params:{
        ...defaultParams
      },
      _filterOpen: false
    }, () => {
      this._getList(this.state._params)
    })
  }

	_handleGoto = page =>{
    this.setState({
      _params: {
        ...this.state._params,
        page
      }
    }, ()=> this._getList(this.state._params) );
  }

  render(){
    return(
      <div className="animated fadeIn">
        <Row>
          <Col xs={12}>
            <Card>

            <CardHeaderWithToolbars>
                <Title>
                  <FontAwesomeIcon iconType="align-justify"/>{' '}
                  <span>App Section List</span>
                </Title>
                <Toolbars
                  isOpen={this.state._filterOpen}
                  onToggleFilter={this._handleToggleFilterPanel}
                  onRefreshList={this._handleRefresh}
                  onResetFilter={this._handleResetFilter}
                />
              </CardHeaderWithToolbars>
              <Filters
                {...this.state._params}
                onApplyFilter={this._handleApplyFilter}
                isOpen={this.state._filterOpen}
              />
              <CardBody>
                <CardText>
                  In this section will display our app sections.
                </CardText>
                <PaginationSummaries
                  from={this.props.dataAppSection.data.from}
                  to={this.props.dataAppSection.data.to}
                  total={this.props.dataAppSection.data.total}
                />
                <div className="table-responsive" ref={tableRef => this.tableRef = tableRef}>
                  <Tbl>
                    <TblHead
                      dataHeader={dataHeader}
                    />
                    <TblBody
                      isLoad={this.props.dataAppSection.isFetch}
                      hasRow={this.props.dataAppSection.status_code===200&&this.props.dataAppSection.data.data.length>0}
                      columnCount={dataHeader.length}
                      renderChildren={() =>{
                        const { current_page, per_page, data } =  this.props.dataAppSection.data;
                        return data.map((item, key)=> {
                          const rowNumber = generateNumber(per_page,current_page,key);
                          return(
                            <tr key={item.id}>
                              <th className="v-center" scope="row">{ rowNumber }</th>
                              <td className="v-center">
                                <TitleColumn
                                  id={item.id}
                                  title={item.title}
                                  slug={item.slug}
                                  description={item.description}
                                  createdAt={item.created_at}
                                />
                              </td>
                              <td className="v-center"><CheckReadOnly check={item.active}/></td>
                              <td className="v-center">
                                <DateToHuman  value={item.updated_at} id={`tooltip_updated_at_${rowNumber}`}/>
                              </td>
                              <td className="v-center">
                                <Link to={`/app_sections/${item.id}`} className="btn btn-link">Details</Link>
                              </td>
                            </tr>
                          )
                        })
                      }}
                    />
                  </Tbl>
                </div>
                { this.props.dataAppSection.status_code===200 && (
                  <Paginations
                    size="sm"
                    lastPage={this.props.dataAppSection.data.last_page}
                    perPage={this.props.dataAppSection.data.per_page}
                    total={this.props.dataAppSection.data.total}
                    maxPage={8}
                    path={this.props.dataAppSection.data.path}
                    currentPage={this.props.dataAppSection.data.current_page}
                    prevPageUrl={this.props.dataAppSection.data.prev_page_url}
                    nextPageUrl={this.props.dataAppSection.data.next_page_url}
                    handleGotoCallback={this._handleGoto}
                    onCurrentPageChanged={() => {
                      window.scrollTo({
                        top: this.tableRef.offsetTop+25,
                        behavior: 'smooth'
                      })
                    }}
                  />
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    )
  }
}

const mapStateToProps= ({ appSection, auth: { isFetch, roles, isLogged, id, email } }) => ({
  guardData: { isFetch, roles, isLogged, id, email },
  dataAppSection: appSection.list
});

const mapDispatchToProps = (dispatch) => ({
  getList:(query) => dispatch(getAppSectionList(query))
});

const enhance = connect(mapStateToProps, mapDispatchToProps);

export default enhance(privateView(AppSectionIndex, ['supervisor', 'sale_supervisor']));