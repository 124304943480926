import React from 'react';
import propTypes from 'prop-types';
import { Badge } from 'reactstrap';

import {
  INCREASE_TYPE,
  DECREASE_TYPE,
} from '../userBalanceLogListCard/helpers';

const BadgeStatus = (props) => {
  return (
    <Badge color={props.status === DECREASE_TYPE? "danger"
    :props.status === INCREASE_TYPE? "success"
    :"default"}
    >
      {props.status}
    </Badge>
  );
}

export default BadgeStatus;

BadgeStatus.defaultProps = {
  status: INCREASE_TYPE
}

BadgeStatus.propTypes = {
  status: propTypes.string
}