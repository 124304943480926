import React from 'react';
import propTypes from 'prop-types';
import { Table, CardBody } from 'reactstrap';
import Loading from 'react-loading-animation';

import { TextRupiah } from '../../../../components/Text';
import { _getFloatValue } from '../../../../utils/form';
import { connect } from 'react-redux';
import { bidDetailOfferSelector, bidDetailSelector } from '../../../../reducers/selectors/bidSelector';

const OfferSummaryCard = props => {
  const { paymentMethod } = props;
  const kickCredit = paymentMethod === 'full_wallet' ? props.totalAmount : 0;
  return (
    <CardBody style={{padding: 0}}>
      {
        props.isLoading?
        <Loading/>:
        <Table>
          <thead>
            <tr>
              <th colSpan={2} className="text-center">
                Offer Summaries
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <p style={{marginBottom: 0}}>{props.displayName}</p>
                <p style={{marginBottom: ".2rem", fontSize: '.75rem'}}>
                  <span style={{marginRight: '.2rem'}}>{`SIZE: ${props.sizeUS} US`}</span>
                  from: <TextRupiah prefix="Rp. " tag="span" value={_getFloatValue(props.originalPrice)}/>
                </p>
              </td>
              <td><TextRupiah prefix="Rp. " value={_getFloatValue(props.amount)} align="right" block/></td>
            </tr>
            <tr>
              <td>Processing Fee</td>
              <td><TextRupiah prefix="Rp. " value={_getFloatValue(props?.bidDetail?.processing_fee_calculated)} align="right" block/></td>
            </tr>
            <tr>
              <td>{props.kaCourier} {props.kaCourierOption} (1x)</td>
              <td><TextRupiah prefix="Rp. " value={_getFloatValue(props.kaCourierPrice)} align="right" block/></td>
            </tr>
            <tr>
              <td>Biaya Tambahan</td>
              <td><TextRupiah  prefix="Rp. " value={_getFloatValue(props.administrationFee)} align="right" block/></td>
            </tr>
            <tr>
              <td><b>Sub Total</b></td>
              <td>
                <TextRupiah prefix="Rp. " value={_getFloatValue(props.totalAmount)} align="right" block bold/>
              </td>
            </tr>
            <tr>
              <td>Kick Credit</td>
              <td>
                <TextRupiah prefix="-Rp. " value={_getFloatValue(kickCredit)} align="right" block/>
              </td>
            </tr>
            <tr>
              <td><b>Total</b></td>
              <td>
                <TextRupiah prefix="Rp. " value={_getFloatValue(props.totalAmount-kickCredit)} align="right" block bold/>
              </td>
            </tr>
            <tr>
              <td>Deposit / Jaminan {(props.status==='NEW' || props.status==='ACCEPTED') && props.paymentMethod !== 'full_wallet' && Number(props.sellerCredit)? "(Use Seller Credit)":null}</td>
              <td>
                <TextRupiah prefix="Rp. " value={_getFloatValue((props.status==='NEW' || props.status==='ACCEPTED') && props.paymentMethod !== 'full_wallet'? props.depositAmount:0)} align="right" block/>
              </td>
            </tr>
          </tbody>
        </Table>
      }
    </CardBody>
  )
}

const mapStateToProps = state => ({
  bidDetailOffer: bidDetailOfferSelector(state),
  bidDetail: bidDetailSelector(state),
})

export default connect(mapStateToProps)(OfferSummaryCard);

OfferSummaryCard.propTypes = {
  id: propTypes.number,
  isLoading: propTypes.bool,
  displayName: propTypes.string,
  amount: propTypes.oneOfType([propTypes.number, propTypes.string]),
  kaCourier: propTypes.string,
  kaCourierOption: propTypes.string,
  kaCourierPrice: propTypes.oneOfType([propTypes.number, propTypes.string]),
  administrationFee: propTypes.oneOfType([propTypes.number, propTypes.string]),
  totalAmount: propTypes.oneOfType([propTypes.number, propTypes.string]),
  paymentMethod: propTypes.string,
  sizeUS: propTypes.string,
  originalPrice: propTypes.oneOfType([propTypes.number, propTypes.string])
}

OfferSummaryCard.defaultProps = {
  id: 0,
  isLoading: false,
  displayName: '-',
  amount: 0,
  kaCourierPrice: 0,
  administrationFee: 0,
  totalAmount: 0,
  paymentMethod: 'bank_tranfer',
  sizeUS: '-',
  originalPrice: 0
}