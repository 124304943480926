import React, { PureComponent as  Component } from 'react';
import {
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  Button } from 'reactstrap';
import propTypes from 'prop-types';

export default class SearchForm extends Component{
  render(){
    return(
      <Form onSubmit={this.props.onSubmit}>
        <Row>
          <Col xs={12} sm={12} md={6}>
            <FormGroup>
              <InputGroup>
                <Input type="text" placeholder={this.props.placeholder} name="keyword" value={this.props.keyword} onChange={this.props.onInputChange}/>
                <InputGroupAddon addonType="append">
                  <Button color="primary">Search</Button>
                </InputGroupAddon>
              </InputGroup>
            </FormGroup>
          </Col>
        </Row>
      </Form>
    )
  }
}

SearchForm.propTypes= {
  placeholder: propTypes.string,
  keyword: propTypes.string,
  onInputChange: propTypes.func,
  onSubmit: propTypes.func.isRequired
}

SearchForm.defaultProps= {
  placeholder: 'Search sneaker name, colourway or SKU...',
  keyword: ''
}