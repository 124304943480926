import {
  GET_LIST_SEEK_STOCK,
  GET_DETAIL_SEEK_STOCK,
  GET_DETAIL_SEEK_STOCK_VARIANTS,
  SYNC_ALL_SEEK_STOCK,
  SEEK_STOCK_ERROR,
  SEEK_STOCK_REQUEST,
  SYNC_BY_ID_SEEK_STOCK,
} from '../constants';

const initialState = {
  list: {
    data: [],
    error: null,
    isFetch: false,
    isSubmit: false
  },
  details: {
    id: {},
    error: null,
    isFetch: false,
    isSubmit: false
  }
}

const onFetched = (lastState, { requestType, value }) => {
  switch(requestType){
    case GET_LIST_SEEK_STOCK:{
      return {
        ...lastState,
        list: {
          ...lastState.list,
          isFetch: value
        }
      }
    }
    case GET_DETAIL_SEEK_STOCK_VARIANTS:
    case GET_DETAIL_SEEK_STOCK:{
      return {
        ...lastState,
        details: {
          ...lastState.details,
          isFetch: value
        }
      }
    }
    case SYNC_BY_ID_SEEK_STOCK:{
      return {
        ...lastState,
        details: {
          ...lastState.details,
          isSubmit: value
        }
      }
    }
    case SYNC_ALL_SEEK_STOCK:{
      return {
        ...lastState,
        list: {
          ...lastState.list,
          isSubmit: value
        }
      }
    }
    default: return { ...lastState }
  }
}


const onError = (lastState, { requestType, error, ...rest }) => {
  switch(requestType){
    case GET_LIST_SEEK_STOCK:{
      return {
        ...lastState,
        list: {
          ...lastState.list,
          error: { ...error, ...rest }
        }
      }
    }
    case GET_DETAIL_SEEK_STOCK_VARIANTS:
    case GET_DETAIL_SEEK_STOCK:{
      return {
        ...lastState,
        details: {
          ...lastState.details,
          error: { ...error, ...rest }
        }
      }
    }
    case SYNC_BY_ID_SEEK_STOCK:{
      return {
        ...lastState,
        details: {
          ...lastState.details,
          error: { ...error, ...rest }
        }
      }
    }
    case SYNC_ALL_SEEK_STOCK:{
      return {
        ...lastState,
        list: {
          ...lastState.list,
          error: { ...error, ...rest }
        }
      }
    }
    default: return { ...lastState }
  }
}

const reducer = (state = initialState, { type, payload }) => {
  switch(type){
    case SEEK_STOCK_REQUEST: return onFetched(state, payload);
    case GET_LIST_SEEK_STOCK:{
      return {
        ...state,
        list: {
          ...state.list,
          ...payload,
          error: null
        }
      }
    }
    case GET_DETAIL_SEEK_STOCK:{
      return {
        ...state,
        details: {
          ...state.details,
          ...payload,
          error: null
        }
      }
    }

    case GET_DETAIL_SEEK_STOCK_VARIANTS:{
      return {
        ...state,
        details: {
          ...state.details,
          seek_product_variants: [...payload],
          error: null
        }
      }
    }
    case SYNC_BY_ID_SEEK_STOCK:{
      const { data } = payload;
      const except = state.list.data.filter(i => i.id !== data.id);
      return {
        ...state,
        list: {
          ...state.list,
          data: [ { ...data }, ...except ]
        },
        details: {
          ...state.details,
          ...payload,
          error: null
        }
      }
    }
    case SYNC_ALL_SEEK_STOCK:{
      return {
        ...state,
        list: {
          ...state.list,
          ...payload,
          error: null
        }
      }
    }
    case SEEK_STOCK_ERROR: return onError(state, payload);
    default: return { ...state }
  }
}

export default reducer;