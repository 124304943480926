import React, { useContext } from 'react';
import { Card, CardBody, CardHeader, Table } from "reactstrap";
import FontAwesomeIcon from "../../../../components/Icons/FontAwesomeIcon";
import { TextRupiah } from "../../../../components/Text";
import { _getFloatValue } from "../../../../utils/form";
import { PaymentContext } from "../Context/PaymentContext";
import { connect } from 'react-redux';
import { processingFeePercentageOfflineTxSelector } from '../../../../reducers/selectors/settingSelector';
import { getProcessingFeeSetting } from '../../../../actions/settingAction';

function PaymentSummary(props) {
    const {
        product,
        shippingFee,
        subsidy,
        voucher,
        voucherApplied,
        discount,
        credit,
        point,
        amountReceived,
        isCashback,
        setTotalAdjusted,
    } = useContext(PaymentContext);

    const offerAmounts = product.length > 0 ? product.reduce((total, item) => {
        return total + _getFloatValue(item.asking_price);
    }, 0) : 0;
    const processingFeeAmount = offerAmounts * (props?.processingFeeOfflineTx / 100);
    const totalAmountBeforeDiscount = (((offerAmounts) - subsidy) + shippingFee + processingFeeAmount);
    const totalAmount = isCashback ? totalAmountBeforeDiscount : totalAmountBeforeDiscount - discount;
    const totalAdjustedAmount = totalAmount - credit - point;

    React.useEffect(() => {
        setTotalAdjusted(totalAdjustedAmount);
    }, [totalAdjustedAmount]);

    React.useEffect(() => {
        props.onGetProcessingFeeSetting();
    }, []);

    return (
        <Card>
            <CardHeader>
                <FontAwesomeIcon iconType="align-justify" /> Purchase Summaries
            </CardHeader>
            <CardBody>
                <Table>
                    <thead>
                        <tr>
                            <th colSpan={2} className="text-center">
                                Purchase Summary
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {product.length > 0 && product.map((item, _) => (
                            <tr>
                                <td>{item?.product_variant?.display_name ?? ''}</td>
                                <td><TextRupiah prefix="Rp. " value={_getFloatValue(item.asking_price)} align="right" block /></td>
                            </tr>
                        ))}
                        <tr>
                            <td>Processing Fee</td>
                            <td><TextRupiah prefix="Rp. " value={_getFloatValue(processingFeeAmount)} align="right" block /></td>
                        </tr>
                        <tr>
                            <td>{shippingFee === 0 ? 'WAREHOUSE PICKUP' : 'FLAT RATE'}</td>
                            <td><TextRupiah prefix="Rp. " value={_getFloatValue(shippingFee)} align="right" block /></td>
                        </tr>
                        {voucherApplied && <tr>
                            <td>Voucher ({voucher ? voucher.code : '-'})</td>
                            <td><TextRupiah prefix="Rp. " value={_getFloatValue(discount)} align="right" block /></td>
                        </tr>}
                        <tr>
                            <td>Subsidy Price</td>
                            <td className="v-center"><TextRupiah prefix="-Rp." value={_getFloatValue(subsidy)} align="right" block /></td>
                        </tr>
                        <tr>
                            <td><b>Sub Total</b></td>
                            <td>
                                <TextRupiah prefix="Rp. " value={_getFloatValue(totalAmount)} align="right" block bold />
                            </td>
                        </tr>
                        <tr>
                            <td>Credit</td>
                            <td>
                                <TextRupiah prefix="-Rp. " value={_getFloatValue(credit)} align="right" block />
                            </td>
                        </tr>
                        <tr>
                            <td>Kick Point</td>
                            <td>
                                <TextRupiah prefix="-Rp. " value={_getFloatValue(point)} align="right" block />
                            </td>
                        </tr>
                        <tr>
                            <td><b>Total</b></td>
                            <td>
                                <TextRupiah prefix="Rp. " value={_getFloatValue(totalAdjustedAmount)} align="right" block bold />
                            </td>
                        </tr>
                        {parseInt(amountReceived) > 0 ? <tr>
                            <td><b>Amount Received</b></td>
                            <td>
                                <TextRupiah prefix="Rp. " value={_getFloatValue(amountReceived)} align="right" block bold />
                            </td>
                        </tr> : <div />}
                    </tbody>
                </Table>
            </CardBody>
        </Card>
    );
}

const mapStateToProps = (state) => ({
    processingFeeOfflineTx: processingFeePercentageOfflineTxSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
    onGetProcessingFeeSetting: () => dispatch(getProcessingFeeSetting()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentSummary);
