import {
  AUTH_TOKEN_KEY,
  STOCK_ROUTE_API,
  GET_LIST_STOCK,
  GET_LIST_STOCK_ERROR,
  GET_LIST_STOCK_FETCHING,
  GET_LIST_STOCK_SIZES,
  GET_LIST_STOCK_SIZES_ERROR,
  GET_LIST_STOCK_SIZES_FETCHING,
  GET_LIST_STOCK_USER_SELL,
  GET_LIST_STOCK_USER_SELL_ERROR,
  GET_LIST_STOCK_USER_SELL_FETCHING,
  STOCK_KEYWORD_PARAMS,
  REPORT_STOCK,
  REPORT_STOCK_API,
  REPORT_STOCK_ERROR,
  SUBMIT_STOCK,
  REPORT_CLEAR,
  SCRAPE_PRODUCT_STOCK,
  SCRAPE_PRODUCT_STOCK_SUBMIT,
  SCRAPE_PRODUCT_STOCK_ERROR
} from '../constants';
import api from '../utils/Api';
import { manipulateErrors, checkAuthorized } from '../utils';
import { logout } from './authAction';

const onError = (type, err) => {
	if(!checkAuthorized(manipulateErrors(err)))
    return logout();
  return {
    type,
    payload:manipulateErrors(err)
  }
}

const onToggleFetching = (type, payload) => ({
  type,
  payload
})

const onSuccess = (type, payload) =>({
  type,
  payload
})

export const doGetListStock = query => {
  return dispatch => (
    dispatch(onToggleFetching(GET_LIST_STOCK_FETCHING, true)),
    api({
      Authorization:'Bearer '+localStorage.getItem(AUTH_TOKEN_KEY)
    }).get(STOCK_ROUTE_API, query)
    .then(res => {
      dispatch(onSuccess(GET_LIST_STOCK, res.data))
      dispatch(onToggleFetching(GET_LIST_STOCK_FETCHING, false))
    })
    .catch(err=>{
      dispatch(onError(GET_LIST_STOCK_ERROR, err))
      dispatch(onToggleFetching(GET_LIST_STOCK_FETCHING, false))
    })
  )
}

export const doGetListSizeOfStock = (variantId, query) => {
  return dispatch => (
    dispatch(onToggleFetching(GET_LIST_STOCK_SIZES_FETCHING, true)),
    api({
      Authorization:'Bearer '+localStorage.getItem(AUTH_TOKEN_KEY)
    }).get(`${STOCK_ROUTE_API}/${variantId}/sizes`, query)
    .then(res => {
      dispatch(onSuccess(GET_LIST_STOCK_SIZES, res.data))
      dispatch(onToggleFetching(GET_LIST_STOCK_SIZES_FETCHING, false))
    })
    .catch(err=>{
      dispatch(onError(GET_LIST_STOCK_SIZES_ERROR, err))
      dispatch(onToggleFetching(GET_LIST_STOCK_SIZES_FETCHING, false))
    })
  )
}

export const doGetListSellingOfStock = (variantId, sizeId, query) => {
  return dispatch => (
    dispatch(onToggleFetching(GET_LIST_STOCK_USER_SELL_FETCHING, true)),
    api({
      Authorization:'Bearer '+localStorage.getItem(AUTH_TOKEN_KEY)
    }).get(`${STOCK_ROUTE_API}/${variantId}/sizes/${sizeId}`, query)
    .then(res => {
      dispatch(onSuccess(GET_LIST_STOCK_USER_SELL, res.data))
      dispatch(onToggleFetching(GET_LIST_STOCK_USER_SELL_FETCHING, false))
    })
    .catch(err=>{
      dispatch(onError(GET_LIST_STOCK_USER_SELL_ERROR, err))
      dispatch(onToggleFetching(GET_LIST_STOCK_USER_SELL_FETCHING, false))
    })
  )
}

export const doChangeStockKeyword = (keyword, keywordFor) => (
  dispatch => (
    dispatch({
      type: STOCK_KEYWORD_PARAMS,
      payload: { keyword, keywordFor }
    })
  )
)

export const reportStock = (payload) =>{
	return dispatch => {
		dispatch({type:REPORT_CLEAR,payload:true});
		dispatch(onToggleFetching(SUBMIT_STOCK, true));
		api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).post(`${REPORT_STOCK_API}`,payload)
		.then(res=>{
			dispatch(onSuccess(REPORT_STOCK, res.data))
			dispatch(onToggleFetching(SUBMIT_STOCK, false))
		})
		.catch(err => {
			dispatch(onError(REPORT_STOCK_ERROR, err));
			dispatch(onToggleFetching(SUBMIT_STOCK, false));
		})
	}
}

export const doScrapeProductStock = (payloads) => {
  const { id } = payloads;
  let url = `${STOCK_ROUTE_API}/product-scrapes`;
  if(id){
    url = `${STOCK_ROUTE_API}/${id}/scrapes`
  }
  return dispatch => (
    dispatch(onToggleFetching(SCRAPE_PRODUCT_STOCK_SUBMIT, true)),
    api({
      Authorization:'Bearer '+localStorage.getItem(AUTH_TOKEN_KEY)
    }).post(url, payloads)
    .then(res => {
      dispatch(onSuccess(SCRAPE_PRODUCT_STOCK, res.data))
      dispatch(onToggleFetching(SCRAPE_PRODUCT_STOCK_SUBMIT, false))
    })
    .catch(err=>{
      dispatch(onError(SCRAPE_PRODUCT_STOCK_ERROR, err))
      dispatch(onToggleFetching(SCRAPE_PRODUCT_STOCK_SUBMIT, false))
    })
  )
}