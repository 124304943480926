import React from 'react';
import propTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  Button

} from 'reactstrap';
import classNames from 'classnames';
import { hasError, errorMessage, textChange, textValue } from '../../../utils/form';
import { ButtonLoading } from '../../../components/Button'

class ModalForceCancelled extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      id: null,
      status: null,
      isSetPenalty: false,
      seller_penalty: 50000,
      seller_points:1,
      notes: null,
      error: null
    }
    this._handleChange = this._handleChange.bind(this);
    this.handleCheckedChange = this.handleCheckedChange.bind(this);
    this._onFormSubmit = this._onFormSubmit.bind(this);
  }
  _handleChange(e){
    this.setState(textChange(e,{...this.state}));
  }

  handleCheckedChange(e) {
    const newState = { ...this.state };
    newState[e.target.name] = e.target.checked;
    this.setState({ ...newState });
  }

  componentDidUpdate(prevProps) {
    if(!prevProps.isOpen && prevProps.isOpen !== this.props.isOpen){
      this.setState({
        id: this.props.id,
        status: null,
        isSetPenalty: false,
        seller_penalty: this.props?.offer?.penalty_amount || 50000,
        notes: null,
        error: null
      })
    }
    if(prevProps.isSubmit && prevProps.isSubmit !== this.props.isSubmit){
      const { errorData } = this.props;
      if(errorData){
        this.setState({
          error: { ...errorData }
        })
      }
    }
  }

  _onFormSubmit(e){
    e.preventDefault();
    const { id, notes, status, isSetPenalty, seller_penalty, seller_points } = this.state;
    const payload = { notes, status, with_penalty: isSetPenalty, penalty_amount: seller_penalty, seller_points };
    this.props.onSubmit(id, payload);
  }

  render(){
    const statusAvailables = [{
      'value': 'CANCELLED',
      'label': 'Cancelled'
    }, {
      'value': 'VERIFICATION_FAILED',
      'label': 'Verification Failed'
    }].filter(status => status.value !== this.props.saleStatus)
    return(
      <Modal isOpen={this.props.isOpen} toggle={this.props.onCancel}>
        <ModalHeader toggle={this.props.onCancel}>
          Force Cancelled Form.
        </ModalHeader>
        <ModalBody>
          <p>
            When force cancel this order, You cannot <strong>change seller</strong> anymore. 
            And this order will be visible on <Link to="/sales/failed" title="Go to Failed Sales">Failed Sale Page</Link>.
            By confirming this Form, You cannot undo your changes.
          </p>
          <Form
            onSubmit={this._onFormSubmit}
          >
            <FormGroup className={classNames({'has-danger has-feedback':hasError('status',this.state.error)})}>
              <Label for="formForceCancelledStatus">Status*</Label>
              <Input
                type="select"
                id="formForceCancelledStatus"
                name="status"
                value={textValue('status',this.state)}
                onChange={this._handleChange}
              >
                <option>Select status...</option>
                {
                  statusAvailables.map(({ value, label }, index) => <option key={index} value={value}>{label}</option>)
                }
              </Input>
              {errorMessage('status',this.state.error)}
            </FormGroup>
            <FormGroup className={classNames({'has-danger has-feedback':hasError('seller_points',this.state.error)})}>
              <Label for="formRejectPoint">Decrease Point Seller?</Label>
              <Input
                type="number"
                id="formRejectPoint"
                name="seller_points"
                placeholder="Decrease Point Seller?"
                value={textValue('seller_points',this.state)}
                onChange={this._handleChange}
              />
              {errorMessage('seller_points',this.state.error)}
            </FormGroup>
            {this.props.saleStatus !== 'CANCELLED' && (
              <>
                <FormGroup
                className={classNames({
                  "has-danger has-feedback": hasError(
                    "isSetPenalty",
                    this.state._error
                  ),
                })}
                >
                  <Label for="formSetPenaltyActive" className="mb-0">
                    Set Penalty Seller Credit&nbsp;
                  </Label>
                  <label className="switch switch-text switch-success-outline-alt">
                    <input
                      type="checkbox"
                      className="switch-input"
                      name="isSetPenalty"
                      id="formSetPenaltyActive"
                      value="true"
                      checked={textValue("isSetPenalty", this.state)}
                      onChange={this.handleCheckedChange}
                    />
                    <span
                      className="switch-label"
                      data-on="On"
                      data-off="Off"
                    ></span>
                    <span className="switch-handle"></span>
                  </label>
                  {errorMessage('isSetPenalty',this.state.error)}
                </FormGroup>
                {this.state.isSetPenalty && (
                  <FormGroup className={classNames({'has-danger has-feedback':hasError('seller_penalty',this.state.error)})}>
                    <Label for="formRejectPoint">Penalty Amount</Label>
                    <Input
                      type="number"
                      id="formRejectPoint"
                      name="seller_penalty"
                      placeholder="Rp 0"
                      value={textValue('seller_penalty',this.state)}
                      onChange={this._handleChange}
                    />
                    {errorMessage('seller_penalty',this.state.error)}
                  </FormGroup>
                )}
              </>
            )}
            <FormGroup className={classNames({'has-danger has-feedback':hasError('notes',this.state.error)})}>
              <Label for="formForceCancelledNotes">Notes*</Label>
              <Input
                type="textarea"
                id="formForceCancelledNotes"
                name="notes"
                placeholder="Put your notes here*"
                value={textValue('notes',this.state)}
                onChange={this._handleChange}
              />
              {errorMessage('notes',this.state.error)}
            </FormGroup>
          </Form>
          <span className="text-sm">Fields marked with * are required</span>
        </ModalBody>
        <ModalFooter>
          <ButtonLoading
            color="primary"
            isLoading={this.props.isSubmit}
            onClick={this._onFormSubmit}
          >
            Confirm
          </ButtonLoading>
          <Button color="secondary" onClick={this.props.onCancel}>Cancel</Button>
        </ModalFooter>
      </Modal>
    )
  }
}

export default ModalForceCancelled;

ModalForceCancelled.defaultProps = {
  isOpen: false,
  isSubmit: false,
  errorData: null,
  saleStatus: 'NEW'
}

ModalForceCancelled.propTypes = {
  isOpen: propTypes.bool,
  isSubmit: propTypes.bool,
  id: propTypes.oneOfType([propTypes.number, propTypes.string]),
  errorData: propTypes.object,
  onCancel: propTypes.func,
  onSubmit: propTypes.func,
  saleStatus: propTypes.string
}