import React, {PureComponent as Component} from 'react';
import propTypes from 'prop-types';

export default class PaginationSummaries extends Component{
  render(){
    return(
      <div className="print-order-pagination">
        <div>
          <label className={!this.props.isOpenPrint? "d-none" : ""} style={{margin:0}}>
          <input
            className="print-order-checkbox"
            type="checkbox"
            name="_allSelected"
            checked={this.props.allSelected}
            onChange={this.props.handleCheckboxChange}
          />
            {`${this.props.isSelected}/${this.props.to} Selected`}
          </label>
          <button 
            onClick={this.props.printInvoice}
            className={!this.props.isOpenPrint||!this.props.isSelected? "d-none" : "create-invoice-button"}
          >
            Create Invoice
          </button>
        </div>
        <div>
          {`Showing ${this.props.from} to ${this.props.to} of ${this.props.total} items.`}
        </div>
      </div>
    )
  }
}
PaginationSummaries.propTypes={
  from:propTypes.number,
  to:propTypes.number,
  total:propTypes.number
}
PaginationSummaries.defaultProps={
  from:0,
  to:0,
  total:0
}