import {
  AUTH_TOKEN_KEY,
  PROVINCE_ROUTE_API,
  CITY_ROUTE_API,
  PROVINCE_LIST,
  SHIPPING_LIST,
  SHIPPING_ERROR,
	FETCHING_SHIPPING,
	FETCHING_PROVINCE,
	PROVINCE_ERROR,
	CITY_LIST,
	FETCHING_CITY,
	CITY_ERROR,
	COURIERS_ROUTE_API,
	FETCHING_COURIERS,
	COURIERS_ERROR,
	COURIERS_COST_POST,
	APPLY_VOUCHER_ROUTE_API,
	APPLY_VOUCHER,
	APPLY_VOUCHER_ERROR,
	FETCHING_APPLY_VOUCHER
} from '../constants';
import api from '../utils/Api';
import { manipulateErrors, checkAuthorized } from '../utils';
import { logout } from './authAction';
const onSuccess=(res,type)=>(
{
  type:type,
  payload:res		
}
)
const onError=(type, err)=>{    
if(!checkAuthorized(manipulateErrors(err)))
    return logout();
  return {
    type,
    payload:manipulateErrors(err)
  }
}
const isFetch=(payload)=>(
  {
    type:FETCHING_SHIPPING,
    payload
  }
)

export const getListUserShipping=(userId)=>{
	return dispatch=>(
		dispatch(isFetch({type:'fetch',status:true})),
		api({
			Authorization:'Bearer '+localStorage.getItem(AUTH_TOKEN_KEY)
		}).get(`/admins/users/${userId}/shippings`)
		.then(res => {
			dispatch(onSuccess(res.data,SHIPPING_LIST));
			dispatch(isFetch({type:'fetch',status:false}));
		})
		.catch(err => {
			dispatch(onError(SHIPPING_ERROR,err));
			dispatch(isFetch({type:'fetch',status:false}));
		})		
	)
}

export const doFetchProvinces=()=>{
	return dispatch=>(
		dispatch(isFetch({type:FETCHING_PROVINCE,status:true})),
		api().get(PROVINCE_ROUTE_API)
		.then(res => {
			dispatch(onSuccess(res.data,PROVINCE_LIST));
			dispatch(isFetch({type:FETCHING_PROVINCE,status:false}));
		})
		.catch(err => {
			dispatch(onError(PROVINCE_ERROR,err));
			dispatch(isFetch({type:FETCHING_PROVINCE,status:false}));
		})		
	)
}

export const doFetchCities=(query)=>{
	return dispatch=>(
		dispatch(isFetch({type:FETCHING_CITY,status:true})),
		api().get(CITY_ROUTE_API, query)
		.then(res => {
			dispatch(onSuccess(res.data,CITY_LIST));
			dispatch(isFetch({type:FETCHING_CITY,status:false}));
		})
		.catch(err => {
			dispatch(onError(CITY_ERROR,err));
			dispatch(isFetch({type:FETCHING_CITY,status:false}));
		})		
	)
}

export const doPostShippingCost=(payload)=>{
	return dispatch=>(
		dispatch(isFetch({type:FETCHING_COURIERS,status:true})),
		api().post(COURIERS_ROUTE_API, payload)
		.then(res => {
			dispatch(onSuccess(res.data,COURIERS_COST_POST));
			dispatch(isFetch({type:FETCHING_COURIERS,status:false}));
		})
		.catch(err => {
			dispatch(onError(COURIERS_ERROR,err));
			dispatch(isFetch({type:FETCHING_COURIERS,status:false}));
		})		
	)
}

export const doPostApplyVoucher=(payload)=>{
	return dispatch=>(
		dispatch(isFetch({type:FETCHING_APPLY_VOUCHER,status:true})),
		api({
			Authorization:'Bearer '+localStorage.getItem(AUTH_TOKEN_KEY)
		}).post(APPLY_VOUCHER_ROUTE_API, payload)
		.then(res => {
			dispatch(onSuccess(res.data,APPLY_VOUCHER));
			dispatch(isFetch({type:FETCHING_APPLY_VOUCHER,status:false}));
		})
		.catch(err => {
			dispatch(onError(APPLY_VOUCHER_ERROR,err));
			dispatch(isFetch({type:FETCHING_APPLY_VOUCHER,status:false}));
		})		
	)
}