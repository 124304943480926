import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import {
  Row,
  Col,
  Form,
  FormGroup,
  Button,
  Input,
  Label
} from 'reactstrap';

const GroupFlex = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 1rem;
  > .form-group{
    display: flex;
    align-items: center;
    justify-content: center;
    > label{
      margin-bottom: 0;
    }
    > .form-control{
      max-width: 100%;
      width: auto;
    }
  }
  @media (min-width: 320px) and (max-width: 415px) {
    margin-bottom: 0rem;
    > .form-group {
      display: block;
      width: 100%;
      > label{
        margin-bottom: .5rem;
      }
      > .form-control{
        width: 100%;
      }
    }
  }
`;

const FilterForm = props => (
  <Form className="form-filter" onSubmit={props.onSubmit}>
    <GroupFlex>
      <FormGroup className="mr-3">
        <Label className="mr-sm-3">
          Status
        </Label>
        <Input
          type="select"
          name="status"
          onChange={props.onInputChange}
          value={props.status}
        >
          <option value="">All</option>
          <option value="pending">Pending</option>
          <option value="processed">Processed</option>
          <option value="completed">Completed</option>
          <option value="failed">Failed</option>
        </Input>
      </FormGroup>
      <FormGroup className="mr-3">
        <Label className="mr-sm-3">
          Payment Method
        </Label>
        <Input
          type="select"
          name="payment_method"
          onChange={props.onInputChange}
          value={props.payment_method}
        >
          <option value="">All</option>
          <option value="bank_transfer">Transfer</option>
          <option value="credit_card">Credit Card</option>
          <option value="full_wallet">Full Wallet</option>
        </Input>
      </FormGroup>
    </GroupFlex>
    <FormGroup className="mb-2 mr-3">
      <Button color="primary" >Apply Filter</Button>
    </FormGroup>
  </Form>
)
export default FilterForm;

FilterForm.defaultProps = {
  status: "",
  payment_method: ""
}
FilterForm.propTypes = {
  onSubmit: propTypes.func.isRequired,
  onInputChange: propTypes.func.isRequired,
  status: propTypes.string,
  payment_method: propTypes.string
}