import { BULK_AWB, BULK_AWB_ERROR, BULK_AWB_SUBMIT } from '../constants';

const initialState = {
  success: {},
  isSubmit: false,
  error: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case BULK_AWB_SUBMIT:
      return { ...state, isSubmit: action.payload };
    case BULK_AWB_ERROR:
      return { ...state, ...action.payload, success: {} };
    case BULK_AWB:
      return { ...state, success: { ...action.payload }, error: null };
    default:
      return state;
  }
};

export default reducer;
