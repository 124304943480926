import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CardText,
  Collapse,
  Form,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  Button,
  ButtonGroup,
} from 'reactstrap';
import Select from 'react-select';
import classNames from 'classnames';
import CardLoadingPlaceholder from '../../../components/Card/CardLoadingPlaceholder';
import { errorMessage, hasKeyError, addErrorMessage, checkChange } from '../../../utils/form';
import {
  getBrandList
} from '../../../actions/brandAction';
import {
  getOptionList as getCategoryOptionList
} from '../../../actions/categoriesAction';
import { getSkuRequestById, saveSkuRequest, deleteSkuRequestById } from '../../../actions/skuRequestAction';
import 'react-select/dist/react-select.css';
import AlertError from './Alerts/AlertError';
import { AlertCustom } from '../../../components/Card/AlertNotFound';
import SkuRequestDetail from './SkuRequestDetail';
import { FontAwesomeIcon, SimpleLineIcon } from '../../../components/Icons';
import { ModalDelete } from '../../../components/Modals';
import { Radio, RadioText, FormGroupRadio } from '../../../components/Form/Radios';
import { getOriginFromSignedUrl } from '../../../utils/AWS';

const Alert = ({ title, color, message, toggle, isOpen, productUrl }) => {
  let iconType = "check";
  if(color === "warning") iconType = "info";
  return (
    <AlertCustom color={color} isOpen={isOpen} toggle={toggle}>
      <strong><SimpleLineIcon className="mr-1" iconType={iconType}/>{ title }</strong> { message }
      {
        color==="success" && productUrl?(
          <div className="text-left">
            <hr/>
            <p className="mb-0">
              Review your product variant, like add/attach more sizes, add product's thumbnails and more
              by clicking {' '}
              <Link target="blank" to={productUrl} title="Go to Product Variant page...">this url</Link>.
            </p>
          </div>
        ): null
      }
    </AlertCustom>
  )
}

const errorApi = {
  category_id: {
    uiErrorKey: "category",
    apiErrorLabel: "category id",
    uiErrorLabel: "category"
  },
  other_brand: {
    uiErrorKey: "newBrand",
    apiErrorLabel: "other brand",
    uiErrorLabel: "new brand"
  },
  display_name: {
    uiErrorKey: "displayName"
  },
  SKU: {
    apiErrorLabel: "s k u",
    uiErrorLabel: "SKU"
  }
}


class ModalSkuDetails extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      dataSKU:{
        id: 0,
        SKU: '',
        requestNumber: '',
        displayName: '',
        imgUrl: null,
        brandId: null,
        brand: null,
        otherBrand: null,
        categoryId: null,
        category: null,
        status: 'PENDING',
        user: null,
        deletedAt: null
      },
      form:{
        category: null,
        brand: null,
        parentBrand: null,
        newBrand: '',
        isNewBrand: false,
        displayName: '',
        SKU: '',
        remarks: '',
        isRejectForm: false,
        error: null
      },
      categoryOptions: [
        {value:'',label:'Choose category...'}
      ],
      parentBrandOptions: [],
      childBrandOptions: [],
      canSubmitForm: true,
      isSubmitDetails: false,
      isDeleted: false,
      isAlertErrorOpen: false,
      errorStatusCode: null,
      errorCustomMessage: null,
      alerts:{
        isOpen: false,
        color: "success",
        message: "Updated successfully."
      },
      productUrl: null,
      loadingText: "Please wait, loading your request...",
      modalConfirmation: {
        isOpen: false,
        type: "DELETE"
      }
    }
    this._handleCreateNewBrandOnClick = this._handleCreateNewBrandOnClick.bind(this);
    this._handleCategorySelectChange = this._handleCategorySelectChange.bind(this);
    this._handleParentBrandSelectChange = this._handleParentBrandSelectChange.bind(this);
    this._handleInputTextChange = this._handleInputTextChange.bind(this);
    this._handleIsRejectCheckedChange = this._handleIsRejectCheckedChange.bind(this);
    this._handleOnSubmit = this._handleOnSubmit.bind(this);
    this._handleToggleAlertError = this._handleToggleAlertError.bind(this);
    this._handleToggleDetailExpanded = this._handleToggleDetailExpanded.bind(this);
    this._handleToggleModalConfirmation = this._handleToggleModalConfirmation.bind(this);
    this._handleSubmitConfirmation = this._handleSubmitConfirmation.bind(this);
    this._handleToggleAlertOpen = this._handleToggleAlertOpen.bind(this);
  }

  componentDidMount(){
    if(this.props.dataCategoryOptions.length === 0){
      this.props.bindCategoryOption();
    }
  }

  async componentDidUpdate(prevProps){
    if(!prevProps.isOpen && prevProps.isOpen !== this.props.isOpen){
      const { id } = this.props.data;
      await this.setState({
        dataSKU:{
          ...this.state.dataSKU,
          ...this.props.data
        },
        isSubmitDetails: true,
        // isSuccessSubmit: false,
        loadingText: "Please wait, loading your request..."
      });
      this.props.getDetailSkuRequest(id);
      if(this.state.isAlertErrorOpen) this._handleToggleAlertError();
      if(!this.state.isDetailExpanded) this._handleToggleDetailExpanded();
    }
    if(prevProps.dataSkuRequest.isFetch && prevProps.dataSkuRequest.isFetch !== this.props.dataSkuRequest.isFetch && this.state.isSubmitDetails){
      const { error, data } = this.props.dataSkuRequest;
      if(error){
        await this.setState({
          error,
          errorStatusCode: error.status_code,
          errorCustomMessage: null,
          isSubmitDetails: false,
          canSubmitForm: false
        })
        if(!this.state.isAlertErrorOpen) this._handleToggleAlertError();
      }
      else{
        const { category, brand } = data;
        let parentBrand = null;
        let childBrand = null;
        if(brand){
          if(brand.parent_id){
            parentBrand = {
              value: brand.parent_id,
              label: brand.flat[0]
            }
            childBrand = {
              value: brand.id,
              label: brand.name
            }
          } else{
            parentBrand = {
              value: brand.id,
              label: brand.name
            }
          }
        }
        await this.setState({
          dataSKU:{
            ...this.state.dataSKU,
            requestNumber: data.request_number,
            imgUrl: data.signed_url || data.img_url,
            categoryId: data.category_id,
            category: data.category,
            brandId: data.brand_id,
            brand: data.brand,
            otherBrand: data.other_brand,
            displayName: data.display_name,
            SKU: data.SKU === '' || data.SKU === null?'': data.SKU,
            user: data.user,
            createdAt: data.created_at,
            productVariantId: data.product_variant_id,
            status: data.status,
            deletedAt: data.deleted_at
          },
          form:{
            ...this.state.form,
            displayName: data.display_name,
            SKU: data.SKU || '',
            category: {
              value: category.id,
              label: category.name
            },
            parentBrand: data.other_brand !== null && parentBrand === null? {
              value: "",
              label: "As a Root"
            }: parentBrand,
            brand: childBrand,
            isNewBrand: data.other_brand !== null,
            newBrand: data.other_brand === null? '': data.other_brand,
            isNewProduct: false,
            remarks: data.remarks || '',
            isRejectForm: false,
            error: null
          },
          isSubmitDetails: false,
          canSubmitForm: data.status === 'PENDING' && data.deleted_at === null,
          // isSuccessSubmit: data.product_variant_id !== null,
          isDeleted: data.deleted_at !== null,
          productUrl: data.product_variant_id && `/product_variants/${data.product_variant_id}`
        });
        this.props.bindBrandOption({params: { category_id: category.id }});
        const { dataSKU } = this.state;
        if(dataSKU.status === 'APPROVED'){
          this.setState({
            alerts:{
              ...this.state.alerts,
              isOpen: true,
              color: "success",
              message: "Your SKU has been approved."
            }
          });
        } else if(dataSKU.status === 'REJECTED'){
          this.setState({
            alerts:{
              ...this.state.alerts,
              isOpen: true,
              color: "warning",
              message: "Your SKU has been rejected."
            }
          });
        }
        else if(this.state.alerts.isOpen){
          this._handleToggleAlertOpen();
        }
      }
    }
    if(prevProps.dataSkuRequest.isSubmit && prevProps.dataSkuRequest.isSubmit !== this.props.dataSkuRequest.isSubmit && this.state.isSubmitDetails){
      const { error, data } = this.props.dataSkuRequest;
      if(error){
        const errorsForm = this._generateErrorsFromApi();
        await this.setState({
          form: {
            ...this.state.form,
            error: errorsForm && {
              errors: {...errorsForm}
            }
          },
          error,
          errorStatusCode: error.status_code,
          errorCustomMessage: error.status_code === 422? 'Please fulfilled your form.': null,
          isSubmitDetails: false
        });
        if(!this.state.isAlertErrorOpen) this._handleToggleAlertError();
        if(this.state.alerts.isOpen) this._handleToggleAlertOpen();
      }
      else{
        await this.setState({
          dataSKU:{
            ...this.state.dataSKU,
            requestNumber: data.request_number,
            imgUrl: data.signed_url || data.img_url,
            categoryId: data.category_id,
            category: data.category,
            brandId: data.brand_id,
            brand: data.brand,
            otherBrand: data.other_brand,
            displayName: data.display_name,
            SKU: data.SKU === '' || data.SKU === null?'-': data.SKU,
            user: data.user,
            createdAt: data.created_at,
            productVariantId: data.product_variant_id,
            status: data.status,
            remarks: data.remarks,
            deletedAt: data.deleted_at
          },
          form:{
            ...this.state.form,
            isRejectForm: false,
          },
          isSubmitDetails: false,
          isDeleted: data.deleted_at !== null,
          canSubmitForm: data.status === 'PENDING' && data.deleted_at === null,
          // isSuccessSubmit: true,
          productUrl: data.product_variant_id && `/product_variants/${data.product_variant_id}`
        });
        if(this.state.isAlertErrorOpen) this._handleToggleAlertError();
        const { dataSKU, isDeleted } = this.state;
        if(dataSKU.status === 'APPROVED'){
          this.setState({
            alerts:{
              ...this.state.alerts,
              isOpen: true,
              color: "success",
              message: "Your SKU has been approved successfully."
            }
          });
        } else if(dataSKU.status === 'REJECTED'){
          this.setState({
            alerts:{
              ...this.state.alerts,
              isOpen: true,
              color: "success",
              message: "Your SKU has been rejected successfully."
            }
          });
        } else if(isDeleted){
          this.setState({
            alerts:{
              ...this.state.alerts,
              isOpen: true,
              color: "success",
              message: "Your SKU has been deleted successfully."
            }
          });
        } else{
          this.setState({
            alerts:{
              ...this.state.alerts,
              isOpen: true,
              color: "success",
              message: "Your SKU has been updated successfully."
            }
          });
        }
      }
    }
    if(prevProps.dataCategoryOptions.length !== this.props.dataCategoryOptions.length){
      const options = this.props.dataCategoryOptions.map(item => ({...item}))
      this.setState({
        categoryOptions: [
          ...this.state.categoryOptions.filter(item => item.value === ''),
          ...options
        ]
      })
    }

    if(prevProps.dataMasterBrand.isFetch && prevProps.dataMasterBrand.isFetch !== this.props.dataMasterBrand.isFetch){
      const { error } = this.props.dataMasterBrand;
      if(!error){
        const { list: { data } } = this.props.dataMasterBrand;
        const { parentBrand } = this.state.form;
        let childBrandOptions = [];
        if(parentBrand){
          const parent = data.find(p => p.id == parentBrand.value);
          if(parent) childBrandOptions = parent.children.map(item => ({
            value: item.id,
            label: item.name
          }))
        }
        this.setState({
          parentBrandOptions: [
            { value: '', label: this.state.form.isNewBrand? 'As a Root': 'Choose Parent Brand...'},
            ...data.map(item => ({
              value: item.id,
              label: item.name
            }))
          ],
          childBrandOptions
        })

      }
    }
  }

  _generateErrorsFromApi(){
    const { error } = this.props.dataSkuRequest;
    if(error && error.errors && error.status_code === 422){
      const { errors } = error;
      return Object.keys(errors).map(k => k).reduce((obj, k) =>{
        if(errorApi[k]){
          const p = errorApi[k].uiErrorKey? errorApi[k].uiErrorKey: k;
          obj[p] = errors[k].map(str => {
            if(errorApi[k].uiErrorLabel) return str.replace(errorApi[k].apiErrorLabel, errorApi[k].uiErrorLabel)
            return str
          })
          return {...obj}
        }
        obj[k] = [...errors[k]]
        return {...obj}
      }, {})
    }
    return null;
  }

  async _handleCategorySelectChange(selected){
    await this.setState({
      form: {
        ...this.state.form,
        category: selected && {...selected},
        parentBrand: null,
        brand: null,
      },
      childBrandOptions: [],
    });
    if(selected !== null && selected.value !== ''){
      this.props.bindBrandOption({params: {category_id: selected.value}});
    }
  }

  async _handleParentBrandSelectChange(selected){
    const { list: { data } } = this.props.dataMasterBrand;
    const children = selected !== null && selected.value !== ''? data.find(p => p.id == selected.value).children: []
    await this.setState({
      form:{
        ...this.state.form,
        parentBrand: selected && { ...selected },
        brand: null
      },
      childBrandOptions: children.length > 0?[
        { value: '', label: 'Choose a Child Brand' },
        ...children.map(item=>({
          value: item.id,
          label: item.name
        }))
      ]: children
    });
  }

  async _handleCreateNewBrandOnClick(e){
    e.preventDefault();
    const { isNewBrand } = this.state.form;
    const value = !isNewBrand;
    await this.setState({
      parentBrandOptions: [
        {value: '', label: !value? 'Choose Parent Brand...': 'As a Root'},
        ...this.state.parentBrandOptions.filter(({value}) => value !== '')
      ],
      form: {
        ...this.state.form,
        isNewBrand: value,
        brand: null,
        parentBrand: null
      },
      childBrandOptions: []
    });
  }

  _handleInputTextChange(e){
    e.preventDefault();
    const { form } = this.state;
    this.setState({
      form: {
        ...form,
        [e.target.name]: e.target.value
      }
    })
  }


  _handleIsRejectCheckedChange(e){
    this.setState({
      form: {
        ...checkChange(e, this.state.form),
        error: null
      }
    });
    if(this.state.isAlertErrorOpen) this._handleToggleAlertError();
  }

  async _handleOnSubmit(e){
    e.preventDefault();
    if(this.state.form.isRejectForm){
      this._handleToggleModalConfirmation("REJECT")
      return;
    }
    //validate form here;
    await this._validateForm();
    const { form:{ error }} = this.state;
    // console.log("_validateForm: ", error);
    if(!error){
      await this.setState({
        isSubmitDetails: true,
        loadingText: "Submitting your request..."
      });
      const { form, dataSKU: { id } } = this.state;
      const payloads = {
        category_id: form.category.value,
        status: this.state.dataSKU.status,
        img_url: getOriginFromSignedUrl(this.state.dataSKU.imgUrl),
        display_name: form.displayName,
        SKU: form.SKU,
        remarks: form.remarks === ''? null: form.remarks
      }
      if(form.isNewBrand){
        payloads.other_brand = form.newBrand === ''? null: form.newBrand;
        payloads.brand_id = form.parentBrand && form.parentBrand.value !== ''? form.parentBrand.value: null
      }
      else{
        if(form.brand){
          payloads.brand_id = form.brand.value;
          // payloads.brand = {
          //   id: form.brand.value,
          //   name: form.brand.label
          // }
        } else if(form.parentBrand){
          payloads.brand_id = form.parentBrand.value;
          // payloads.brand = {
          //   id: form.parentBrand.value,
          //   name: form.parentBrand.label
          // }
        }
      }
      // console.log("MY PAYLOADS: ", payloads);
      this.props.saveSkuRequest(payloads, id);
    } else{
      this.setState({
        errorStatusCode: 422,
        errorCustomMessage: 'Please fulfilled your form.',
      });
      if(!this.state.isAlertErrorOpen) this._handleToggleAlertError();
    }
  }

  _handleToggleAlertError(){
    this.setState({
      isAlertErrorOpen: !this.state.isAlertErrorOpen
    })
  }

  _handleToggleDetailExpanded(){
    this.setState({ isDetailExpanded: !this.state.isDetailExpanded });
  }

  _handleToggleModalConfirmation(type = null){
    this.setState({
      modalConfirmation: {
        ...this.state.modalConfirmation,
        isOpen: !this.state.modalConfirmation.isOpen,
        type: type === null? this.state.modalConfirmation.type: type
      }
    })
  }

  async _handleSubmitConfirmation(){
    const { modalConfirmation, isSubmitDetails, dataSKU } = this.state;
    if(!isSubmitDetails && modalConfirmation.type !== null){
      if(modalConfirmation.type === 'DELETE'){
        await this.setState({
          isSubmitDetails: true,
          loadingText: "Submitting your request..."
        });
        this.props.deleteSkuRequest(dataSKU.id);
        if(modalConfirmation.isOpen) this._handleToggleModalConfirmation();
      } else if(modalConfirmation.type === 'REJECT'){
        await this._validateForm();
        const { form: { error } } = this.state;
        if(!error){
          await this.setState({
            isSubmitDetails: true,
            loadingText: "Submitting your request..."
          });
          const payloads = {
            // display_name: dataSKU.displayName,
            // SKU: dataSKU.SKU,
            // brand_id: dataSKU.brandId,
            // category_id: dataSKU.categoryId,
            // other_brand: dataSKU.otherBrand,
            // img_url: dataSKU.imgUrl,
            remarks: this.state.form.remarks === ''? null: this.state.form.remarks,
            status: 'REJECTED',
          }
          this.props.saveSkuRequest(payloads, dataSKU.id);
          if(modalConfirmation.isOpen) this._handleToggleModalConfirmation();
        } else{
          this.setState({
            errorStatusCode: 422,
            errorCustomMessage: 'Please fulfilled your form.',
          });
          if(!this.state.isAlertErrorOpen) this._handleToggleAlertError();
          if(modalConfirmation.isOpen) this._handleToggleModalConfirmation();
        }
      }
    }
  }

  _handleToggleAlertOpen(customColor = null, customMessage = null){
    const { isOpen, color, message } = this.state.alerts
    this.setState({
      alerts:{
        isOpen: !isOpen,
        color: customColor || color,
        message: customMessage || message,
      }
    })
  }

  async _validateForm(){
    const { form } = this.state;
    const errors = {
      category: null,
      brand: null,
      parentBrand: null,
      newBrand: null,
      displayName: null,
      SKU: null,
      remarks: null,
    }
    if(!form.isRejectForm){
      if(!form.category || (form.category !== null && form.category.value === "")){
        errors.category = addErrorMessage(errors, 'category', 'Please select a category.');
      }
      if(form.displayName === ''){
        errors.displayName = addErrorMessage(errors, 'displayName', 'The display name is required.');
      }
      if(form.SKU === ''){
        errors.SKU = addErrorMessage(errors, 'SKU', 'The SKU is required.');
      }
      if(form.isNewBrand){
        if(form.newBrand === ''){
          errors.newBrand = addErrorMessage(errors, 'newBrand', 'The new brand is required.');
        }
      } else {
        if(!form.parentBrand || (form.parentBrand !== null && form.parentBrand.value === "")){
          errors.parentBrand = addErrorMessage(errors, 'parentBrand', 'Please select a brand.');
        }
      }
    }
    else{
      if(form.remarks === ''){
        errors.remarks = addErrorMessage(errors, 'remarks', 'The remarks is required.');
      }
    }
    const filtered = Object.keys(errors)
      .filter(k => errors[k] !== null && errors[k] !== undefined)
      .reduce((prev, k) => ({ ...prev, [k]: errors[k] }), {});
    this.setState({
      form:{
        ...this.state.form,
        error: Object.keys(filtered).length > 0 ? {
          errors: filtered
        }: null
      }
    })
  }

  _hasError(propsName){
    const { form: { error } } = this.state;
    return error && error.errors && hasKeyError(error.errors, propsName);
  }

  render(){
    return(
      <div>
        <ModalDelete
          isOpen={this.state.modalConfirmation.isOpen}
          modalBody={this.state.modalConfirmation.type === 'REJECT'? 'Are you sure to reject this SKU?':'Are you sure to delete this SKU?'}
          onDelete={this._handleSubmitConfirmation}
          toggle={this._handleToggleModalConfirmation}
        />
        <Modal size="md" className="modal-confirmation" isOpen={this.props.isOpen} toggle={this.props.toggle}>
          <Form onSubmit={this._handleOnSubmit}>
            <CardLoadingPlaceholder
              isVisible={this.state.isSubmitDetails}
              loadingText={this.state.loadingText}
            />
            <ModalHeader toggle={this.props.toggle} className="modal-header--center">
              SKU Request
            </ModalHeader>
            <ModalBody style={{maxHeight: "80vh", overflow:'auto'}}>
              <AlertError
                isOpen={this.state.isAlertErrorOpen}
                message={this.state.errorCustomMessage}
                errorStatusCode={this.state.errorStatusCode}
                toggle={this._handleToggleAlertError}
              />
              <Alert
                isOpen={this.state.alerts.isOpen}
                color={this.state.alerts.color}
                message={this.state.alerts.message}
                toggle={this._handleToggleAlertOpen}
                productUrl={this.state.productUrl}
              />
              <SkuRequestDetail
                {...this.state.dataSKU}
                isExpanded={this.state.isDetailExpanded}
                toggle={this._handleToggleDetailExpanded}
              />
              <Collapse isOpen={this.state.canSubmitForm}>
                <CardText className="text-center">SKU Request Form</CardText>
                <Row>
                  <Col xs={6}>
                    <FormGroupRadio>
                      <Radio
                        type="checkbox"
                        name="isRejectForm"
                        value={true}
                        checked={this.state.form.isRejectForm}
                        dataOnText="Yes"
                        dataOffText="No"
                        onChange={this._handleIsRejectCheckedChange}
                      />
                      <RadioText text="Reject"/>
                    </FormGroupRadio>
                  </Col>
                </Row>
                <Collapse isOpen={!this.state.form.isRejectForm}>
                  <Row>
                    <Col xs={12} md={6}>
                      <FormGroup
                        className={classNames({"has-danger has-feedback": this._hasError('category')})}
                      >
                        <Label for="formCategoryName">
                          Category
                        </Label>
                        <Select
                          id="formCategoryName"
                          value={this.state.form.category}
                          options={this.state.categoryOptions}
                          onChange={this._handleCategorySelectChange}
                        />
                        {errorMessage('category', this.state.form.error)}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={6}>
                      <FormGroup
                        className={classNames({"has-danger has-feedback": this._hasError('parentBrand')})}
                      >
                        <Label
                          for="formBrandName">
                          Parent Brand {' '}
                          <a
                            href="#"
                            onClick={this._handleCreateNewBrandOnClick}
                          >
                            { !this.state.form.isNewBrand? 'New Brand':'Choose Brand' }
                          </a>
                        </Label>
                        <Select
                          id="formBrandName"
                          value={this.state.form.parentBrand}
                          options={this.state.parentBrandOptions}
                          onChange={this._handleParentBrandSelectChange}
                        />
                        {errorMessage('parentBrand', this.state.form.error)}
                      </FormGroup>
                    </Col>
                    <Col xs={12} md={6}>
                      {
                        !this.state.form.isNewBrand?(
                          <FormGroup
                            className={classNames({"has-danger has-feedback": this._hasError('childBrand')})}
                          >
                            <Label for="formChildBrandName">Child Brand</Label>
                            <Select
                              id="formChildBrandName"
                              value={this.state.form.brand}
                              options={this.state.childBrandOptions}
                              onChange={(val)=> this.setState({
                                form:{
                                  ...this.state.form,
                                  brand: val && { ...val }
                                }
                              })}
                            />
                            {errorMessage('childBrand', this.state.form.error)}
                          </FormGroup>
                        ):(
                          <FormGroup
                            className={classNames({"has-danger has-feedback": this._hasError('newBrand')})}
                          >
                            <Label for="formNewBrandName">New Brand</Label>
                            <Input
                              id="formNewBrandName"
                              name="newBrand"
                              value={this.state.form.newBrand}
                              onChange={this._handleInputTextChange}
                            />
                            {errorMessage('newBrand', this.state.form.error)}
                          </FormGroup>
                        )
                      }
                    </Col>
                    <Col xs={12}>
                      <FormGroup
                        className={classNames({"has-danger has-feedback": this._hasError('displayName')})}
                      >
                        <Label for="formDisplayName">Display Name</Label>
                        <Input
                          type="text"
                          id="formDisplayName"
                          name="displayName"
                          placeholder="Enter a Display Name*"
                          value={this.state.form.displayName}
                          onChange={this._handleInputTextChange}
                        />
                        {errorMessage('displayName', this.state.form.error)}
                      </FormGroup>
                    </Col>
                    <Col xs={12}>
                      <FormGroup
                        className={classNames({"has-danger has-feedback": this._hasError('SKU')})}
                      >
                        <Label for="formSKU">SKU</Label>
                        <Input
                          type="text"
                          id="formSKU"
                          name="SKU"
                          placeholder="Enter a SKU*"
                          value={this.state.form.SKU}
                          onChange={this._handleInputTextChange}
                        />
                        {errorMessage('SKU', this.state.form.error)}
                      </FormGroup>
                    </Col>
                  </Row>
                </Collapse>
                <Row>
                  <Col>
                    <FormGroup
                      className={classNames({"has-danger has-feedback": this._hasError('remarks')})}
                    >
                      <Label for="formRemarks">Remarks (*if any)</Label>
                      <Input
                        type="textarea"
                        id="formRemarks"
                        name="remarks"
                        placeholder="Enter remarks, if any"
                        value={this.state.form.remarks}
                        onChange={this._handleInputTextChange}
                      />
                      {errorMessage('remarks', this.state.form.error)}
                    </FormGroup>
                  </Col>
                </Row>
              </Collapse>
            </ModalBody>
            <ModalFooter>
              {
                this.state.dataSKU.status === 'PENDING' && this.state.dataSKU.deletedAt === null ?(
                  <ButtonGroup>
                    <Button
                      type="submit"
                      color="primary"
                      title="Submit"
                      size="sm"
                      onClick={this._handleOnSubmit}
                    >
                      <FontAwesomeIcon iconType="save"/> Submit
                    </Button>{' '}
                    <Button
                      type="button"
                      color="danger"
                      title="Delete"
                      size="sm"
                      onClick={() => this._handleToggleModalConfirmation("DELETE")}
                    >
                      <FontAwesomeIcon iconType="trash"/> Delete
                    </Button>
                  </ButtonGroup>
                ):(
                  <Button
                    type="button"
                    color="primary"
                    title="Delete"
                    size="sm"
                    onClick={() => this.props.toggle()}
                  >
                    Close
                  </Button>
                )
              }
            </ModalFooter>
          </Form>
        </Modal>
      </div>
    )
  }
}
const mapStateToProps= ({ masterCategories, masterBrand, skuRequest: { details } }) => ({
  dataMasterBrand: masterBrand,
  dataCategoryOptions: masterCategories.options,
  dataSkuRequest: details
});

const mapDispatchToProps = (dispatch) => ({
  getDetailSkuRequest: id => dispatch(getSkuRequestById(id)),
  deleteSkuRequest:(id) => dispatch(deleteSkuRequestById(id)),
  saveSkuRequest: (payload, id) => dispatch(saveSkuRequest(payload, id)),
  bindBrandOption:(q)=>dispatch(getBrandList(q)),
  bindCategoryOption:()=>dispatch(getCategoryOptionList())
});
export default connect(mapStateToProps, mapDispatchToProps)(ModalSkuDetails);