import React from "react";
import _ from "lodash";
import propTypes from "prop-types";
import { connect } from "react-redux";
import { Card, CardHeader, CardBody, Input, Button } from "reactstrap";
import { Th, Tbl, TblHead, TblBody } from "../../../components/Tbl";
import { generateNumber } from "../../../utils";
import Paginations from "../../../components/Paginations";
import { DEFAULT_SORT_BY } from "../../../constants/settings";
import ModalAddSeller from "./Modals/ModalAddSeller";
import { SimpleLineIcon } from "../../../components/Icons";
import { createCollectionSeller, deleteCollectionSeller, getCollectionSellerList } from "../../../actions/collectionAction";
import { ModalDelete } from "../../../components/Modals";
import { Link } from "react-router-dom";

const dataHeader = ["No.", "Email", "Action"];

const defaultParams = {
  page: 1,
  sort_by: DEFAULT_SORT_BY,
  per_page: 5,
};

class SellerList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      _params: {
        ...defaultParams,
      },
      _selectedId: null,
      _filterOpen: false,
      _modalAddSeller: false,
      _modalDelete: false
    };
    this._toggle = this._toggle.bind(this);
    this._handleSubmit = this._handleSubmit.bind(this);
    this._handleModalDeleteToggle = this._handleModalDeleteToggle.bind(this);
    this._handleDelete = this._handleDelete.bind(this);
    this._handleGoto = this._handleGoto.bind(this);
    this._throttleGetList = _.debounce(this._getList.bind(this), 500);
  }

  componentDidMount() {
    this._getList(this.state._params);
  }

  componentDidUpdate(prevProps) {
    if(prevProps.dataCollectionSeller.details.isSubmit && !this.props.dataCollectionSeller.details.isSubmit) {
      const { error, isDeleting } = this.props.dataCollectionSeller.details;
      if (error && this.state._modalDelete) {
        this.setState({_modalDelete:false, _error:{...error}});
      } else if (prevProps.dataCollectionSeller.details.isDeleting !== isDeleting) {
        this.setState({_modalDelete:false, _error:null, _selectedId: null}, () => this._handleGoto(1))
      }
    }
  }

  _toggle() {
    this.setState({ _modalAddSeller: !this.state._modalAddSeller });
  }

  _handleSubmit(payload) {
    this.props.create(this.props.collectionId, payload)
  }

  _handleDelete(){
    const { _selectedId } = this.state;
    this.props.delete(_selectedId)
  }

  _handleModalDeleteToggle(id){
    this.setState({
      _selectedId: id,
      _modalDelete: !this.state._modalDelete
    });
  }

  _getList(rawParams = {}) {
    let params = {};
    Object.keys(rawParams)
      .filter((key) => rawParams[key] != "")
      .forEach((key) => (params[key] = rawParams[key]));
    this.props.getList(this.props.collectionId, { params });
  }

  _handleGoto(page) {
    const _params = this.state._params;
    _params.page = page;
    this.setState({ _params }, () => this._getList(this.state._params));
  }

  _onRenderPagination() {
    const { data } = this.props.dataCollectionSeller;
    const hasData =
      (this.props.dataCollectionSeller.status_code === 200 && data.data.length) ||
      null;
    return (
      hasData && (
        <Paginations
          size="sm"
          lastPage={data.last_page}
          perPage={data.per_page}
          total={data.total}
          maxPage={8}
          path={data.path}
          currentPage={data.current_page}
          prevPageUrl={data.prev_page_url}
          nextPageUrl={data.next_page_url}
          handleGotoCallback={this._handleGoto}
        />
      )
    );
  }

  _renderRowItem() {
    if (
      this.props.dataCollectionSeller.isFetch === false &&
      this.props.dataCollectionSeller.status_code === 200
    ) {
      const { data, current_page, per_page } = this.props.dataCollectionSeller.data;
      return data.map((item, index) => {
        return (
          <tr key={index}>
            <Th className="v-center" scope="row">
              {generateNumber(per_page, current_page, index)}
            </Th>
            <td className="v-center">
              <Link to={`/users/${item.user_id}`} className="btn btn-link">
                {item.user.typed_email? item.user.typed_email: item.user.email}
              </Link><br/>
            </td>
            <td className="v-center">
              <Button
                color="danger"
                onClick={() => this._handleModalDeleteToggle(item.id)}
              >
                <SimpleLineIcon iconType="trash" />
              </Button>
            </td>
          </tr>
        );
      });
    }
    return null;
  }

  render() {
    const { dataCollectionSeller } = this.props;
    return (
      <Card>
        <ModalDelete
          isOpen={this.state._modalDelete}
          toggle={this._handleModalDeleteToggle}
          onDelete={this._handleDelete}
        />
        <ModalAddSeller
          isOpen={this.state._modalAddSeller}
          onCancel={this._toggle}
          dataCollectionSeller={this.props.dataCollectionSeller}
          collectionId={this.props.collectionId}
          dataAuth={this.props.dataAuth}
          onSubmit={this._handleSubmit}
          onGetCollectionSellerList={() => {
            this.setState({
              _params:{
                ...this.state._params,
                page: 1
              }
            });
            this._getList(this.state._params)
          }}
        />
        <CardHeader>
          <i className="fa fa-align-justify"></i>Seller List
        </CardHeader>
        <CardBody>
          <div className="mb-3">
            <ul className="image-guides" style={{fontSize: '.75rem', paddingInlineStart: '1rem'}}>
              <li>You can add some sellers. That means, only visible for just selected seller. Add them in "Add seller" button.</li>
            </ul>
            <Button title="Add Seller" onClick={this._toggle}>
              <i className="fa fa-plus"></i> Add Seller
            </Button>
          </div>
          <div className="d-flex mb-3">
            <Input
              type="text"
              placeholder="Search by email..."
              name="keyword"
              value={this.state._params.keyword || ""}
              onChange={(e) => {
                this.setState(
                  {
                    _params: {
                      ...this.state._params,
                      [e.target.name]: e.target.value,
                      page: 1,
                    },
                  },
                  () => this._throttleGetList(this.state._params)
                );
              }}
            />
          </div>
          <div
            className="table-responsive"
            style={{ maxHeight: 500, overflow: "auto" }}
          >
            <Tbl>
              <TblHead dataHeader={dataHeader} />
              <TblBody
                isLoad={dataCollectionSeller.isFetch}
                hasRow={
                  dataCollectionSeller.status_code === 200 &&
                  dataCollectionSeller.data.data.length
                }
                columnCount={dataHeader.length}
              >
                {this._renderRowItem()}
              </TblBody>
            </Tbl>
          </div>
          {this._onRenderPagination()}
        </CardBody>
      </Card>
    );
  }
}

const mapStateToProps = ({ masterCollection, auth: dataAuth }) => ({
  dataAuth,
  dataCollectionSeller: masterCollection.collectionSeller,
});
const mapDispatchToProps = (dispatch) => ({
  getList: (collectionId, params) => dispatch(getCollectionSellerList(collectionId, params)),
  create: (collectionId, payload) => dispatch(createCollectionSeller(collectionId, payload)),
  delete: (sellerId) => dispatch(deleteCollectionSeller(sellerId))
});
export default connect(mapStateToProps, mapDispatchToProps)(SellerList);

SellerList.propTypes = {
  collectionId: propTypes.oneOfType([propTypes.number, propTypes.string]).isRequired,
};
