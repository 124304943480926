import React from 'react';
import propTypes from 'prop-types';
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardText,
  CardTitle,
  CardBody
} from 'reactstrap';

import FontAwesomeIcon from '../../../components/Icons/FontAwesomeIcon';
import {
  FieldRow,
  FieldColumn,
  FieldLabel,
  FieldValue
} from '../../../styled/Fields';
import { _getValue } from '../../../utils/form';
import { getBalanceSource } from '../helpers';
import { TextRupiah } from '../../../components/Text';
import DisbursementSummaries from '../DisbursementSummaries';
import BadgeDisbursementType from '../Badges/BadgeDisbursementType';
import DisbursementAuditList from './DisbursementAuditList'
import BadgeStatus from '../Badges/BadgeStatus';
import BadgePayoutType from '../Badges/BadgePayoutType';
import ItemList from './ItemList';
import { DISBURSEMENT_BALANCE_OUT } from '../helpers';

const ReadOnlyForm = (props) => {
  return (
    <Row>
      <Col xs={12} md={6}>
        <Card>
          <CardHeader>
            <FontAwesomeIcon iconType="align-justify"/>Disbursement Details
          </CardHeader>
          <CardBody>
            <FieldRow>
              <FieldColumn style={{padding: "0px 15px", display:"flex", flex: 1, justifyContent: "flex-end"}}>
                <BadgeDisbursementType type={props.dataDisbursementUser.type}/>
                <BadgePayoutType type={props.dataDisbursementUser.payout_type}/>
                <BadgeStatus status={props.dataDisbursementUser.status} expiredAt={props.dataDisbursementUser.token_expired_at}/>
              </FieldColumn>
            </FieldRow>
            <FieldRow>
              <FieldColumn className="col-xs-12 col-md-3">
                <FieldLabel>ID</FieldLabel>
                <FieldValue>
                  <span>{_getValue(props.dataDisbursementUser.id)}</span>
                </FieldValue>
              </FieldColumn>
              <FieldColumn className="col-xs-12 col-md-6">
                <FieldLabel>Disbursement Numb.</FieldLabel>
                <FieldValue>
                  <span>{_getValue(props.dataDisbursementUser.disbursement_number)}</span>
                </FieldValue>
              </FieldColumn>
            </FieldRow>
            <FieldRow>
              <FieldColumn className="col-12">
                <FieldLabel>User</FieldLabel>
                <FieldValue>
                  <span>{_getValue(props.dataDisbursementUser.disburse_to&&props.dataDisbursementUser.disburse_to.email)}</span>
                </FieldValue>
              </FieldColumn>
              <FieldColumn className="col-xs-12 col-md-6">
                <FieldLabel>Balance Source</FieldLabel>
                <FieldValue>
                  <span>{getBalanceSource(props.dataDisbursementUser.balance_source)}</span>
                </FieldValue>
              </FieldColumn>
              <FieldColumn className="col-xs-12 col-md-6">
                <FieldLabel>Total Amount</FieldLabel>
                <FieldValue>
                  <TextRupiah prefix="Rp. " value={props.dataDisbursementUser.total_amount}/>
                </FieldValue>
              </FieldColumn>
              <FieldColumn className="col-xs-12 col-md-6">
                <FieldLabel>Fee</FieldLabel>
                <FieldValue>
                  <TextRupiah prefix="Rp. " value={props.dataDisbursementUser.fee}/>
                </FieldValue>
              </FieldColumn>
              <FieldColumn className="col-12">
                <FieldLabel>Note</FieldLabel>
                <FieldValue >
                  <span className="break-all">{_getValue(props.dataDisbursementUser.note)}</span>
                </FieldValue>
              </FieldColumn>
              <FieldColumn className="col-12">
                <FieldLabel>Disburse By</FieldLabel>
                <FieldValue>
                  <span>{_getValue(props.dataDisbursementUser.disburse_by&&props.dataDisbursementUser.disburse_by.email)}</span>
                </FieldValue>
              </FieldColumn>
              <FieldColumn className="col-12">
                <FieldLabel>Reference No</FieldLabel>
                <FieldValue>
                  <span>{_getValue(props.dataDisbursementUser.reference_no)}</span>
                </FieldValue>
              </FieldColumn>
              <FieldColumn className="col-xs-12 col-md-6">
                <FieldLabel>Created</FieldLabel>
                <FieldValue>
                  <span>{_getValue(props.dataDisbursementUser.created_at)}</span>
                </FieldValue>
              </FieldColumn>
              <FieldColumn className="col-xs-12 col-md-6">
                <FieldLabel>Updated</FieldLabel>
                <FieldValue>
                  <span>{_getValue(props.dataDisbursementUser.updated_at)}</span>
                </FieldValue>
              </FieldColumn>
            </FieldRow>
          </CardBody>
        </Card>
      </Col>
      <Col xs={12} md={6}>
        {
          props.dataDisbursementUser.type===DISBURSEMENT_BALANCE_OUT &&
          props.dataDisbursementUser.disbursement_bank?
          <div>
            <Card>
              <CardHeader>
                <FontAwesomeIcon iconType="bank"/> Bank Details
              </CardHeader>
              <CardBody>
                <Card body>
                  <CardTitle>From: {props.dataDisbursementUser.disbursement_bank.bank_name_sender}</CardTitle>
                  <CardText>
                    A/N <strong>{props.dataDisbursementUser.disbursement_bank.account_name_sender}</strong><br/>
                    {props.dataDisbursementUser.disbursement_bank.account_number_sender}
                    {' - '}
                    {props.dataDisbursementUser.disbursement_bank.branch_name_sender}
                  </CardText>
                </Card>
                <Card body>
                  <CardTitle>To: {props.dataDisbursementUser.disbursement_bank.bank_name_recipient}</CardTitle>
                  <CardText>
                    A/N <strong>{props.dataDisbursementUser.disbursement_bank.account_name_recipient}</strong><br/>
                    {props.dataDisbursementUser.disbursement_bank.account_number_recipient}
                    {' - '}
                    {props.dataDisbursementUser.disbursement_bank.branch_name_recipient}
                  </CardText>
                </Card>
              </CardBody>
            </Card>
          </div>
          :null
        }
        <DisbursementSummaries
          showingBalanceRemains={false}
          disbursementType={props.dataDisbursementUser.type}
          totalAmount={parseInt(props.dataDisbursementUser.total_amount)}
          fee={parseInt(props.dataDisbursementUser.fee)}
        />
        <ItemList 
          list={props.dataDisbursementUser.disbursement_items}
          onRedirect={(link)=> props.history.push(link)}
        />
      </Col>
      {props.dataDisbursementUser?.audits && (
        <Col xs={12}>
          <DisbursementAuditList 
            id={props.id}
            type="disbursement"
          />
        </Col>
      )}
    </Row>
  )
}

export default ReadOnlyForm;

ReadOnlyForm.defaultProps = {

}

ReadOnlyForm.propTypes = {
  id: propTypes.number.isRequired,
  dataDisbursementUser: propTypes.object,
  history: propTypes.object.isRequired
}