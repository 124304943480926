import React, { PureComponent as Component } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';

import propTypes from 'prop-types';
import Loading from 'react-loading-animation';

export default class InternationalShippingModal extends Component{
  render(){
    const { country, totalActive, totalCountry, size, toggle, isOpen, toggleActive } = this.props
    let isLoading = country.isSubmit
    return(
      <Modal size={size} className="modal-confirmation" isOpen={isOpen} toggle={toggle}>
        <ModalHeader toggle={toggle} className="modal-header--center">
          International Shipping
        </ModalHeader>
        <ModalBody>
          <p className="font-weight-bold mb-0">Country active: {totalActive} / {totalCountry}</p>
        </ModalBody>
        <ModalFooter>
          {totalActive === totalCountry ? (
            <Button color="warning" onClick={() => toggleActive(false)} disabled={isLoading}>Disable All</Button>
          ) : (
            <Button color="success" onClick={() => toggleActive(true)} disabled={isLoading}>Enable All</Button>
          )}
          <Button color="secondary" onClick={toggle}>Cancel</Button>
        </ModalFooter>
      </Modal>
    )
  }
}

InternationalShippingModal.propTypes={
  title: propTypes.string,
  body: propTypes.oneOfType([propTypes.string, propTypes.node]),
  size: propTypes.oneOf(['sm','md','lg']),
  isOpen: propTypes.bool.isRequired,
  toggle: propTypes.func.isRequired,
  toggleActive: propTypes.func.isRequired
}
InternationalShippingModal.defaultProps={
  title: 'Confirmation',
  body: 'Are you sure to delete this?',
  size: 'sm'
}