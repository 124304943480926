import {
  AUTH_TOKEN_KEY,
  POP_UP_BANNER_ROUTE_API,
  POP_UP_BANNER_ADD,
  POP_UP_BANNER_FETCHING,
  POP_UP_BANNER_ERROR,
  POP_UP_BANNER_LIST_FETCH,
  POP_UP_BANNER_DETAIL_FETCH,
  POP_UP_BANNER_UPDATE,
  POP_UP_BANNER_DELETE
} from '../constants';
import api from '../utils/Api';
import { manipulateErrors, checkAuthorized } from '../utils';
import { logout } from './authAction';

export const isFetch=(payload)=>(
	{
		type:POP_UP_BANNER_FETCHING,
		payload:payload		
	}
)

export const onSuccess=(res,type)=>
(
	{
		type:type,
		payload:res		
	}
)

export const onError=(err)=>{
	if(!checkAuthorized(manipulateErrors(err)))
		return logout();
	return {
		type:POP_UP_BANNER_ERROR,
		payload:manipulateErrors(err)
	}
}

export const getPopUpBannerList=(query)=>{
	return dispatch =>(
		dispatch(isFetch({type:'fetch',status:true})),
		api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).get(POP_UP_BANNER_ROUTE_API,query)
		.then(res=>{
			dispatch(onSuccess(res.data,POP_UP_BANNER_LIST_FETCH))
			dispatch(isFetch({type:'fetch',status:false}))
		})
		.catch(err => {
			dispatch(onError(err));
			dispatch(isFetch({type:'fetch',status:false}));
		})
	)
}

export const getDetailPopUpBanner=(id)=>{
	return dispatch=>(
		dispatch(isFetch({type:'fetch',status:true})),
		api({
			Authorization:'Bearer '+localStorage.getItem(AUTH_TOKEN_KEY)
		}).get(`${POP_UP_BANNER_ROUTE_API}/${id}`)
		.then(res => {
			dispatch(onSuccess(res.data,POP_UP_BANNER_DETAIL_FETCH));
			dispatch(isFetch({type:'fetch',status:false}));
		})
		.catch(err => {
			dispatch(onError(err));
			dispatch(isFetch({type:'fetch',status:false}));
		})		
	)
}

export const createPopUpBanner=(payload)=>{
	return dispatch =>(
		dispatch(isFetch({type:'submit',status:true})),
				api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).post(`${POP_UP_BANNER_ROUTE_API}`,payload)
		.then(res=>{
			dispatch(onSuccess(res.data,POP_UP_BANNER_ADD))
			dispatch(isFetch({type:'submit',status:false}))
		})
		.catch(err => {
			dispatch(onError(err));
			dispatch(isFetch({type:'submit',status:false}));
		})
	)
}

export const updatePopUpBanner=(id, payload)=>{
	return dispatch => (
		dispatch(isFetch({type:'submit',status:true})),
		api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).put(`${POP_UP_BANNER_ROUTE_API}/${id}`,payload)
		.then(res=>{
			dispatch(onSuccess(res.data,POP_UP_BANNER_UPDATE))
			dispatch(isFetch({type:'submit',status:false}))
		})
		.catch(err => {
			dispatch(onError(err));
			dispatch(isFetch({type:'submit',status:false}));
		})
	)
}

export const deletePopUpBanner=(id)=>{
	return dispatch =>(
		dispatch(isFetch({type:'submit',status:true})),
				api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).delete(`${POP_UP_BANNER_ROUTE_API}/${id}`)
		.then(res=>{
			dispatch(onSuccess(res.data,POP_UP_BANNER_DELETE))
			dispatch(isFetch({type:'submit',status:false}))
		})
		.catch(err => {
			dispatch(onError(err));
			dispatch(isFetch({type:'submit',status:false}));
		})
	)
}