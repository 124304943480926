import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  Badge,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';
import moment from 'moment';
import 'moment/locale/en-ie';
import DateToHuman from '../../../components/DateToHuman';
import { FontAwesomeIcon } from '../../../components/Icons';
import { Radio } from '../../../components/Form/Radios';
import { saveRaffle } from '../../../actions/raffleAction';

class RowItem extends React.Component{
  constructor(props){
    super(props)
    this.state = {
      name: props.name,
      groupName: props.group_name,
      startedAt: props.started_at || null,
      expiry: props.expiry || null,
      active: props.active,
      activeEdit: props.active,
      golden: props.golden,
      raffleGroup: props.raffle_group,
      updatedAt: props.updated_at,
      dropdownMenu: false,
      body: {
        ...this.obtainOtherData(props)
      }
    }
  }

  obtainOtherData = data => {
    return {
      name: data.name,
      group_name: data.group_name,
      img_url: data.img_url,
      active: data.active,
      golden: data.golden,
      promo_code: data.promo_code,
      voucher_id: data.voucher_id,
      note: data.note,
      terms: data.terms,
      instructions: data.instructions,
      description: data.description,
      started_at: data.started_at,
      expiry: data.expiry
    }
  }

  shouldComponentUpdate(nextProps){
    const { dataRaffle } = this.props;
    const { dataRaffle: nextRaffle } = nextProps;
    if(nextRaffle.isSubmit && !dataRaffle.isSubmit){
      return false;
    }
    if(nextRaffle.isSubmit !== dataRaffle.isSubmit && nextRaffle.data && nextRaffle.data.id !== this.props.id){
      return false;
    }
    return true;
  }

  componentDidUpdate(prevProps){
    if(prevProps.dataRaffle.isSubmit && !this.props.dataRaffle.isSubmit){
      const { data: {id, ...data}, error } = this.props.dataRaffle;
      if(!error && id === this.props.id){
        this.setState({
          name: data.name,
          groupName: data.group_name,
          active: data.active,
          activeEdit: data.active,
          startedAt: data.started_at,
          expiry: data.expiry,
          active: data.active,
          golden: data.golden,
          raffleGroup: data.raffle_group,
          updatedAt: data.updated_at,
          body: {
            ...this.obtainOtherData(data)
          }
        })
      }
      else if(error){
        this.setState({
          activeEdit: this.state.active
        })
      }
    }
  }

  toggleMenu = () => {
    this.setState({ dropdownMenu: !this.state.dropdownMenu });
  }

  handleChange = async (e) => {
    await this.setState({
      [e.target.name]: e.target.type === 'checkbox'? e.target.checked: e.target.value
    })
    const { id } = this.props;
    const { activeEdit, body } = this.state;
    const payloads = {
      id,
      ...body,
      expiry: !activeEdit && !body.expiry? this.state.updatedAt: body.expiry,
      active: activeEdit
    }
    this.props.saveRaffle(payloads)
  }

  render(){
    const goldenColor = '#a67c00';
    return(
      <tr>
        <th className="v-center" scope="row">{ this.props.rowNumber }</th>
        <td className="v-center">
          <div style={{fontSize: '.75rem'}}>
            {
              this.state.groupName?(
                <b className="mr-1">{this.state.groupName}</b>
              ):null
            }
            {this.state.golden? <b><Badge className="mr-1" style={{backgroundColor: goldenColor}}>GOLDEN</Badge></b>:null}
            {this.state.raffleGroup? <b><Badge className="mr-1 text-uppercase" style={{backgroundColor: this.state.raffleGroup.color}}>{this.state.raffleGroup.name}</Badge></b>:null}
          </div>
          {this.state.name}<br/>
          {this.props.raffle_tiers && this.props.raffle_tiers.length?(
            <div style={{fontSize: '.65rem'}} className="text-uppercase">
              <i className="fa fa-product-hunt"></i> {this.props.raffle_tiers[0].tier_label}
            </div>
          ): null}
          {
            this.props.voucher_id || this.props.promo_code?(
            <div style={{fontSize: '.65rem'}}>
              Code: {this.props.voucher_id?`${this.props.voucher? this.props.voucher.code: this.props.voucher_id} (voucher)`:(
                this.props.promo_code? this.props.promo_code: '-'
              )}
            </div>
            ):null
          }
          <div style={{fontSize: '.65rem'}}>
            <span className="mr-1">#{this.props.id}</span>
            <span>Entries: {this.props.raffle_entries_count}</span>
          </div>
        </td>
        <td className="v-center">
          <Radio
            type="checkbox"
            name="activeEdit"
            value={true}
            checked={this.state.activeEdit}
            dataOnText="Yes"
            dataOffText="No"
            onChange={this.handleChange}
          />
        </td>
        <td className="v-center">
          <DateToHuman  value={this.state.updatedAt} id={`tooltip_updated_at_${this.props.rowNumber}`}/>
          {this.state.started_at || this.state.expiry?
            <div style={{fontSize: '.75rem'}}>
              <div>start: {this.state.startedAt? moment(this.state.startedAt).format('DD MMM YY hh:mm a'):'always open'}</div>
              <div>end: {this.state.expiry? moment(this.state.expiry).format('DD MMM YY hh:mm a'):'no expiry'}</div>
            </div>
          :null}
        </td>
        <td className="v-center">
          <Dropdown
            isOpen={this.state.dropdownMenu}
            toggle={this.toggleMenu}
          >
            <DropdownToggle caret>
              Atur
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem tag={Link} to={`/raffles/${this.props.id}`}>
                <FontAwesomeIcon iconType="edit"/> Details
              </DropdownItem>
              <DropdownItem tag={Link} to={`/raffles/create?_ref=${this.props.id}`}>
                <FontAwesomeIcon iconType="plus"/> Duplicate Raffle
              </DropdownItem>
              <DropdownItem onClick={() => this.props.onModalUpdateOpen(this.props.id)}>
                <FontAwesomeIcon iconType="external-link"/> Quick Edit
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </td>
      </tr>
    )
  }
}

const mapStateToProps=({ raffle })=>({
  dataRaffle: raffle.details,
})
const mapDispatchToProps=(dispatch)=>({
  saveRaffle: (payloads) => dispatch(saveRaffle(payloads))
})
export default connect(mapStateToProps, mapDispatchToProps)(RowItem);
