import React from 'react';
import propTypes from 'prop-types';
import classNames from 'classnames';

const RadioText = ({ className, style, text, ...props}) => (
  <span
    className={classNames("switch-label-span", className)}
    style={style}
    {...props}
  >
    { text }
  </span>
)
export default RadioText;

RadioText.defaultProps = {
  text: 'Radio Text'
}

RadioText.propTypes = {
  text: propTypes.string,
  style: propTypes.object,
  className: propTypes.string
}