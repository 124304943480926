import React, { Component } from 'react';
import {
  Form,
  Label,
  Col, 
  Row,
  Input,
  FormGroup,
  Button,
  Card,
  Container,
  CardBody,
  CardHeader, 
  ButtonGroup,
  Alert,
  Collapse
} from 'reactstrap';
import ReactLoading from 'react-loading';
import Loading from 'react-loading-animation';
import classNames from 'classnames';
import { connect } from 'react-redux';
import {
  saveReward,
  getListReward,
  getDetailReward,
  getListRewardItem,
  clearFormReward
} from '../../../actions/rewardAction';
import SelectUserAsync from '../../../components/Form/Select/Async/SelectUser';
import SelectVoucherAsync from '../../../components/Form/Select/Async/SelectVoucher';
import { errorMessage, hasError, textChange, textValue, dateChange, dateValue, checkChange } from '../../../utils/form';
import { ButtonLoading } from '../../../components/Button';
import RewardItemList from './RewardItemList';


const LoadingBar = props => (
  <div className="loading-wrapper">
    <ReactLoading type="bars" color="green" height={20} width={30}  className="loading-center"/>
  </div>
)

class FormCard extends Component{
  constructor(props){
    super(props);
    this.state={
      id: this.props.params.id && parseInt(this.props.params.id),
      quantity:'',
      value:'',
      created_at:'',
      updated_at:'',
      user:null,
      voucher:null,
      _successMessage: null,
      _errorMessage: null,
      _alertSuccess: false,
      _alertError: false,
      _formSubmit: false,
      _error: null
    }
    this.onSubmit=this.onSubmit.bind(this);
    this.handleChange=this.handleChange.bind(this);
    this._handleOnSelectVoucherChange=this._handleOnSelectVoucherChange.bind(this);
    this._handleOnSelectUserChange = this._handleOnSelectUserChange.bind(this);
    this._handleOnSelectUserOpen = this._handleOnSelectUserOpen.bind(this);
    this._handleOnSelectVoucherOpen = this._handleOnSelectVoucherOpen.bind(this);
    this.handleCheckedChange=this.handleCheckedChange.bind(this);
  }

  componentDidMount(){        
    if(this.state.id){
      this.props.find(this.state.id);
      this.props.getListRewardItem(this.state.id);
    }
    else
      this.props.new();
  }

  componentDidUpdate(prevProps){
    const { reward } = this.props;
    if(prevProps.reward.isFetch&&prevProps.reward.isFetch!==reward.isFetch) {
      if(reward.error===null){
        const { detail } = reward;
        this.setState({
          user: detail.user && {id:detail.user.id, email:detail.user.email},
          value: detail.value && parseInt(detail.value),
          quantity: detail.quantity,
          voucher: detail.voucher && {id:detail.voucher.id, code:detail.voucher.code},
          created_at : detail.created_at,
          updated_at : detail.updated_at,
        });
      }
    }
    if(prevProps.reward.isSubmit&&prevProps.reward.isSubmit!==reward.isSubmit
      &&this.state._formSubmit===true&&reward.error===null){
      if(this.props.isNew){
        this.props.history.replace(`/rewards`);
        this.props.getListReward();
      }
      else{
        const { detail, success } = reward;
        this.setState({
          user: this.state.user,
          value: detail.value && parseInt(detail.value),
          quantity: detail.quantity,
          voucher: this.state.voucher,
          created_at : detail.created_at,
          updated_at : detail.updated_at,
          _formSubmit :!this.state._formSubmit,
          _successMessage : success,
          _alertSuccess : true
        })
      }
    }
    if(prevProps.reward.error!== this.props.reward.error){
      if(this.props.reward.error!==null) {
        if(this.props.isDelete)
          this.props.onToggleModal();
        const { error } = reward;
        this.setState({
          _error:{...error},
          _formSubmit: false,
          _errorMessage : error.status_code===422?'Please fulfill your form.' : error.message,
          _alertError : true
        })
      }
    }
  }

  _validateForm(){
    const {_error} = this.state;
    let errors={};
    if(!this.state.voucher)
      errors['voucher']=['Voucher id is required.'];
    if(!this.state.user)
      errors['user']=['User id is required.'];
    if(this.state.quantity==='')
      errors['quantity']=['Quantity is required.'];
    if(this.state.value==='')
      errors['value']=['Value is required.'];
    if(Object.keys(errors).length){
      this.setState({
        _error:{errors:{...errors}, status_code:422,message:'Please fulpill your form.'},
        _errorMessage:'Please fulpill your form.',
        _alertError:true
      })
    }
    return errors;
  }

  
  _handleOnSelectUserChange(selected){
    let { user } = this.state;
    user = selected;
    this.setState({
      user,
      _recentUserSearch: selected && this.state._recentUserSearch
    })
  }

  _handleOnSelectUserOpen(){
    if(this.state.user){
      return this.state._recentUserSearch&&this.refs.selectUser.loadSelectOptions(this.state._recentUserSearch);
    }
    return this.refs.selectUser.loadSelectOptions('');
  }

  _handleOnSelectVoucherChange(selected){
    let { voucher } = this.state;
    voucher = selected;
    this.setState({
      voucher,
      _recentVoucherSearch: selected && this.state._recentVoucherSearch
    })
  }

  _handleOnSelectVoucherOpen(){
    if(this.state.voucher){
      return this.state._recentVoucherSearch&&this.refs.selectUser.loadSelectOptions(this.state._recentVoucherSearch);
    }
    return this.refs.selectUser.loadSelectOptions('');
  }

  handleChange(e){
    const _form=textChange(e,this.state);
    this.setState({..._form});
  }
  removeAlerts(){
    this.setState({
      _error:null,
      _alertError:false,
      _alertSuccess:false
    });
  }
  onSubmit(e){
    e.preventDefault();
    this.removeAlerts();
    const { 
      id,
      user, 
      voucher,
      quantity,
      value,
      _formSubmit, 
    } = this.state;
    const valid= this._validateForm();
    if(Object.keys(valid).length===0){
      const payload = {
        id: id,
        user_id: user.id,
        voucher_id: voucher.id,
        quantity: quantity,
        value: value
      }
      this.setState({ _formSubmit:true },()=>{
        this.props.save(payload);
      })
    }
  }

  handleCheckedChange(e){
    const _form=checkChange(e,this.state);
    this.setState({..._form});
  }

  handleDatePickerChange(value, name){
    const _form=dateChange(value,name,this.state);
    this.setState({..._form});
  }

  onToggleAlert(key){
    const lastState = {...this.state};
    lastState[key] = !lastState[key];
    this.setState({...lastState});
  }

  _renderAlerts(){
    return(
      <div>
        <Alert color="success" isOpen={this.state._alertSuccess} toggle={()=>this.onToggleAlert('_alertSuccess')}>
          <strong>Well done!</strong> { this.state._successMessage }
        </Alert>
        <Alert color="danger" isOpen={this.state._alertError} toggle={()=>this.onToggleAlert('_alertError')}>
          <strong>Oh Snap!</strong> { this.state._errorMessage }
        </Alert>
      </div>
    )
  }

  render(){
    if(this.props.reward.isFetch)
      return <Loading/>
    return(
      <Row>
        <Col xs={12} md={{size:6, offset:3}}>
          <Card>
            <CardHeader>
              <i className="fa fa-align-justify"></i> {this.props.title}
            </CardHeader>
            <CardBody>
              {this._renderAlerts()}
              <Form onSubmit={this.onSubmit}>
                <Row>
                  <Col xs={12} md={6}>
                    <FormGroup className={classNames({'has-danger has-feedback':hasError('voucher',this.state._error)})}>
                      <Label for="formCode">Voucher Code</Label>
                      <SelectVoucherAsync
                        isDisabled
                        defaultOptions
                        id="_formSelectVoucher"
                        ref="selectVoucher"
                        value={this.state.voucher}
                        placeholder="Type and select a code..."
                        noResultsText="Cannot find code."
                        loadOptions={this._getVoucherOptions}
                        onSelectSearch={(input)=>this.setState({_recentVoucherSearch: input})}
                        onSelectChange={this._handleOnSelectVoucherChange}
                        onSelectOpen={this._handleOnSelectVoucherOpen}
                      />
                      {errorMessage('voucher',this.state._error)}
                    </FormGroup>
                  </Col>
                  <Col xs={12} md={6}>
                    <FormGroup className={classNames({'has-danger has-feedback':hasError('user',this.state._error)})}>
                      <Label for="formUser">User</Label>
                      <SelectUserAsync
                        isDisabled
                        defaultOptions
                        id="_formSelectUser"
                        userId={this.props.auth.id}
                        ref="selectUser"
                        value={this.state.user}
                        paramsApi={{ roles: 'user,seller,administrator', role_query: 'or', scope: 'all' }}
                        placeholder="Type and select a user..."
                        noResultsText="Cannot find user."
                        loadOptions={this._getUserOptions}
                        onSelectSearch={(input)=>this.setState({_recentUserSearch: input})}
                        onSelectChange={this._handleOnSelectUserChange}
                        onSelectOpen={this._handleOnSelectUserOpen}
                      />
                      {errorMessage('user',this.state._error)}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={6}>
                    <FormGroup className={classNames({'has-danger has-feedback':hasError('quantity',this.state._error)})}>
                      <Label for="formQuantity">Quantity</Label>
                        <Input
                          readOnly
                          type="number"
                          id="formQuantity"
                          name="quantity"
                          placeholder="Enter a quantity*"
                          value={textValue('quantity',this.state)}
                          onChange={this.handleChange}
                        />
                      {errorMessage('quantity',this.state._error)}
                    </FormGroup>
                  </Col>
                  <Col xs={12} md={6}>
                    <FormGroup className={classNames({'has-danger has-feedback':hasError('value',this.state._error)})}>
                      <Label for="formValue">Value</Label>
                        <Input
                          readOnly
                          type="text"
                          id="formValue"
                          name="value"
                          placeholder="Enter a value*"
                          value={textValue('value',this.state)}
                          onChange={this.handleChange}
                        />
                      {errorMessage('value',this.state._error)}
                    </FormGroup>
                  </Col>
                </Row>
                <FormGroup>
                  <Label>Created At</Label>
                  <Input
                    type="text"
                    readOnly
                    value={this.state.created_at}
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Updated At</Label>
                  <Input
                    type="text"
                    readOnly
                    value={this.state.updated_at}
                  />                                    
                </FormGroup>
                <FormGroup>
                  {/* <ButtonGroup>
                    <ButtonLoading isLoading={this.state._formSubmit}>Submit</ButtonLoading>
                    {
                      !this.props.isNew?
                      <Button  color="danger" type="button" onClick={this.props.onDelete}>Delete</Button>
                      :
                      ''
                    }
                  </ButtonGroup> */}
                </FormGroup>
              </Form>                        
            </CardBody>
          </Card>
        </Col>
        {
          this.props.isEdit &&
          <Col xs={12} md={12}>
            <RewardItemList
              id={this.state.id}
              onGetList={this.props.getListRewardItem}
              dataRewardItem={this.props.reward.rewardItem}
            />
          </Col>
          }
      </Row>
    )
  }
}

const mapStateToProps= ({auth, reward, user, voucher}) => {
	return {
    voucher,
    reward,
    user,
    auth
	}
};
const mapDispatchToProps = (dispatch) => {
	return{
		find:(id) => dispatch(getDetailReward(id)),
		save:(payload) => dispatch(saveReward(payload)),
    new:() => dispatch(clearFormReward()),
    getListReward: (filter) => dispatch(getListReward(filter)),
    getListRewardItem: (id, query) => dispatch(getListRewardItem(id, query))
	}
};

export default connect(mapStateToProps,mapDispatchToProps)(FormCard);

