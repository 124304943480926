import React from 'react';
import { connect } from 'react-redux';
import {
  Modal,
  ModalHeader,
  ModalBody,
  Card,
  CardText,
  Button,
  Collapse,
  Form,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  Alert
} from 'reactstrap';
import classNames from 'classnames';
import styled from 'styled-components';
import Select, { Creatable } from 'react-select';
import SelectProduct from '../../../components/Form/Select/Async/SelectProduct';
import InputDatetime from '../../../components/Form/InputDatetime/InputDatetime';
import CardLoadingPlaceholder from '../../../components/Card/CardLoadingPlaceholder';
import { errorMessage, hasKeyError, addErrorMessage, dateChange, dateValue, checkChange } from '../../../utils/form';
import {
  getBrandList
}
from '../../../actions/brandAction';
import {
  getOptionList as getCategoryOptionList
}
from '../../../actions/categoriesAction';
import { getSkuRequestById, saveSkuRequest } from '../../../actions/skuRequestAction';
import 'react-select/dist/react-select.css';
import AlertApprovedSuccess from './Alerts/AlertApprovedSuccess';
import AlertError from './Alerts/AlertError';
import SkuRequestDetail from './SkuRequestDetail';
import { Radio, RadioText, FormGroupRadio } from '../../../components/Form/Radios';
import { getOriginFromSignedUrl } from '../../../utils/AWS';

const ButtonSectionWrapper = styled.div`
  display: flex;
  border-bottom: ${props => !props.isOpen? "1px solid #ddd": "none"};
  padding: .5rem 0px;
  cursor: pointer;
  align-items: center;
  span {
    flex: 1;
    text-align: ${props => props.isOpen? 'center': 'left'};
    font-weight: ${props => props.isOpen? 700: 'normal'};
  }
  div{
    font-size: .5rem;
    text-transform: 'capitalize';
    display: ${props => props.isOpen? 'none': 'flex'};
    justify-content: center;
  }
`

const CollapseWrapper = styled.div`
  border-bottom: ${props => props.isOpen && !props.noBorder? "1px solid #ddd": "none"};
`

const StepForm = props => (
  <div>
    <ButtonSectionWrapper onClick={props.toggle} isOpen={props.isOpen}>
      <span>{props.formTitle}</span>
      <div>
        {props.isLocked? 'LOCKED': 'CHANGE'}
      </div>
    </ButtonSectionWrapper>
    <Collapse isOpen={props.isOpen && !props.isLocked}>
      <CollapseWrapper isOpen={props.isOpen} noBorder={props.noBorder}>
        {props.children}
      </CollapseWrapper>
    </Collapse>
  </div>
)
const errorApi = {
  category_id: {
    uiErrorKey: "category",
    apiErrorLabel: "category id",
    uiErrorLabel: "category"
  },
  other_brand: {
    uiErrorKey: "newBrand",
    apiErrorLabel: "other brand",
    uiErrorLabel: "new brand"
  },
  display_name: {
    uiErrorKey: "displayName"
  },
  SKU: {
    apiErrorLabel: "s k u",
    uiErrorLabel: "SKU"
  },
  "brand.parent_id": {
    uiErrorKey: "parentBrand",
    apiErrorLabel: "brand.parent id",
    uiErrorLabel: "parent brand"
  },
  "brand.id": {
    uiErrorKey: "childBrand",
    apiErrorLabel: "brand.id",
    uiErrorLabel: "brand"
  },
  "brand.name": {
    uiErrorKey: "newBrand",
    apiErrorLabel: "brand.name",
    uiErrorLabel: "new brand"
  },
  "product.id": {
    uiErrorKey: "product",
    apiErrorLabel: "product.id",
    uiErrorLabel: "product"
  },
  "product.name": {
    uiErrorKey: "newProduct",
    apiErrorLabel: "product.name",
    uiErrorLabel: "new product"
  },
  "product_variant.nickname": {
    uiErrorKey: "nickname",
    apiErrorLabel: "product variant.nickname",
    uiErrorLabel: "nickname"
  },
  "product_variant.type": {
    uiErrorKey: "type",
    apiErrorLabel: "product variant.type",
    uiErrorLabel: "type"
  },
  "product_variant.colour": {
    uiErrorKey: "colour",
    apiErrorLabel: "product variant.colour",
    uiErrorLabel: "colour"
  },
  "product_variant.weight": {
    uiErrorKey: "weight",
    apiErrorLabel: "product variant.weight",
    uiErrorLabel: "weight"

  },
  "product_variant.sex": {
    uiErrorKey: "sex",
    apiErrorLabel: "product variant.sex",
    uiErrorLabel: "sex"

  },
  "product_variant.active": {
    uiErrorKey: "active",
    apiErrorLabel: "product variant.active",
    uiErrorLabel: "active"

  },
  "product_variant.editors_choice": {
    uiErrorKey: "editorsChoice",
    apiErrorLabel: "product variant.editors choice",
    uiErrorLabel: "hot product"

  },
  "product_variant.release_date": {
    uiErrorKey: "releaseDate",
    apiErrorLabel: "product variant.release date",
    uiErrorLabel: "release date"

  },
  "product_variant.expiry_date": {
    uiErrorKey: "expiryDate",
    apiErrorLabel: "product variant.expiry date",
    uiErrorLabel: "expiry date"

  },
  "product_variant.price_source": {
    uiErrorKey: "priceSource",
    apiErrorLabel: "product variant.price source",
    uiErrorLabel: "price source"
  }
}

class ModalSkuApproved extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      dataSKU:{
        id: 0,
        SKU: '',
        requestNumber: '',
        displayName: '',
        imgUrl: null,
        brandId: null,
        brand: null,
        otherBrand: null,
        categoryId: null,
        category: null,
        status: 'PENDING',
        user: null
      },
      form:{
        category: null,
        brand: null,
        parentBrand: null,
        newBrand: '',
        isNewBrand: false,
        product: null,
        isNewProduct: false,
        newProduct: '',
        displayName: '',
        SKU: '',
        colour: '',
        type: '',
        nickname: null,
        weight: 1000,
        sex: 'M',
        active: false,
        editorsChoice: false,
        retailPrice: '',
        priceSource: '',
        releaseDate: null,
        expiryDate: null,
        remarks: '',
        error: null
      },
      categoryOptions: [
        {value:'',label:'Choose category...'}
      ],
      sections:[
        { sectionName: 'FORM_CATEGORY_SECTION', isLocked: false },
        { sectionName: 'FORM_BRAND_SECTION', isLocked: true },
        { sectionName: 'FORM_PRODUCT_SECTION', isLocked: true }
      ],
      parentBrandOptions: [],
      childBrandOptions: [],
      sectionOpen: 'FORM_CATEGORY_SECTION',
      defaultTags:[
        {
          label:'nike',
          value:'nike'
        },
        {
          label:'adidas',
          value:'adidas'
        },
        {
          label:'vapormax',
          value:'vapormax'
        },
        {
          label:'airjordan',
          value:'airjordan'
        },
        {
          label:'ultraboost',
          value:'ultraboost'
        },
        {
          label:'yeezy',
          value:'yeezy'
        }
      ],
      isSuccessSubmit: false,
      isSubmitDetails: false,
      isAlertErrorOpen: false,
      errorStatusCode: null,
      errorCustomMessage: null,
      productUrl: null,
      loadingText: "Please wait, loading your request..."
    }
    this._handleCreateNewBrandOnClick = this._handleCreateNewBrandOnClick.bind(this);
    this._handleCreateNewProductOnClick = this._handleCreateNewProductOnClick.bind(this);
    this._handleCategorySelectChange = this._handleCategorySelectChange.bind(this);
    this._handleParentBrandSelectChange = this._handleParentBrandSelectChange.bind(this);
    this._handleOnSelectedProductChange = this._handleOnSelectedProductChange.bind(this);
    this._handleOnSelectProductOpen = this._handleOnSelectProductOpen.bind(this);
    this._handleInputTextChange = this._handleInputTextChange.bind(this);
    this._handleNicknameOnChange = this._handleNicknameOnChange.bind(this);
    this._handleCheckedChange = this._handleCheckedChange.bind(this);
    this._handleSelectedChange = this._handleSelectedChange.bind(this);
    this._handleOnSubmit = this._handleOnSubmit.bind(this);
    this._handleOnSubmitBrand = this._handleOnSubmitBrand.bind(this);
    this._handleToggleAlertError = this._handleToggleAlertError.bind(this);
  }

  componentDidMount(){
    if(this.props.dataCategoryOptions.length === 0){
      this.props.bindCategoryOption();
    }
  }

  async componentDidUpdate(prevProps){
    if(!prevProps.isOpen && prevProps.isOpen !== this.props.isOpen){
      const { id } = this.props.data;
      await this.setState({
        dataSKU:{
          ...this.state.dataSKU,
          ...this.props.data
        },
        isSubmitDetails: true,
        isSuccessSubmit: false,
        loadingText: "Please wait, loading your request..."
      });
      this.props.getDetailSkuRequest(id);
      if(this.state.isAlertErrorOpen) this._handleToggleAlertError();
    }
    if(prevProps.dataSkuRequest.isFetch && prevProps.dataSkuRequest.isFetch !== this.props.dataSkuRequest.isFetch && this.state.isSubmitDetails){
      const { error, data } = this.props.dataSkuRequest;
      if(error){
        await this.setState({
          error,
          errorStatusCode: error.status_code,
          errorCustomMessage: null,
          isSubmitDetails: false
        })
        if(!this.state.isAlertErrorOpen) this._handleToggleAlertError();
      }
      else{
        const { category, brand } = data;
        let parentBrand = null;
        let childBrand = null;
        if(brand){
          if(brand.parent_id){
            parentBrand = {
              value: brand.parent_id,
              label: brand.flat[0]
            }
            childBrand = {
              value: brand.id,
              label: brand.name
            }
          } else{
            parentBrand = {
              value: brand.id,
              label: brand.name
            }
          }
        }
        const defaultVariantFields = {
          colour: '',
          type: '',
          nickname: null,
          weight: 1000,
          sex: 'M',
          active: false,
          editorsChoice: false,
          retailPrice: '',
          priceSource: '',
          releaseDate: null,
          expiryDate: null,
          error: null
        }
        await this.setState({
          dataSKU:{
            ...this.state.dataSKU,
            requestNumber: data.request_number,
            imgUrl: data.signed_url || data.img_url,
            categoryId: data.category_id,
            category: data.category,
            brandId: data.brand_id,
            brand: data.brand,
            otherBrand: data.other_brand,
            displayName: data.display_name,
            SKU: data.SKU === '' || data.SKU === null?'-': data.SKU,
            user: data.user,
            createdAt: data.created_at,
            productVariantId: data.product_variant_id,
            status: data.status
          },
          form:{
            ...this.state.form,
            displayName: data.display_name,
            SKU: data.SKU || '',
            category: {
              value: category.id,
              label: category.name
            },
            parentBrand: data.other_brand !== null && parentBrand === null? {
              value: "",
              label: "As a Root"
            }: parentBrand,
            brand: childBrand,
            isNewBrand: data.other_brand !== null,
            newBrand: data.other_brand === null? '': data.other_brand,
            isNewProduct: false,
            newProduct: '',
            product: null,
            remarks: data.remarks === null? '': data.remarks,
            ...defaultVariantFields
          },
          isSubmitDetails: false,
          isSuccessSubmit: data.product_variant_id !== null,
          productUrl: data.product_variant_id && `/product_variants/${data.product_variant_id}`
        });
        await this.settingSection();
        this.props.bindBrandOption({params: { category_id: category.id }});
        this.handleOnSectionToogle("FORM_BRAND_SECTION")
        this.refs.selectProduct && this.refs.selectProduct.purgeCache()
      }
    }
    if(prevProps.dataSkuRequest.isSubmit && prevProps.dataSkuRequest.isSubmit !== this.props.dataSkuRequest.isSubmit && this.state.isSubmitDetails){
      const { error, data } = this.props.dataSkuRequest;
      if(error){
        const errorForm = this._generateErrorsFromApi();
        let { sectionOpen } = this.state;
        if(errorForm) {
          if(errorForm.category) sectionOpen = 'FORM_CATEGORY_SECTION';
          else if(errorForm.parentBrand || errorForm.childBrand || errorForm.newBrand){
            sectionOpen = 'FORM_BRAND_SECTION';
          }
        }
        await this.setState({
          form:{
            ...this.state.form,
            error: errorForm && {
              errors: {...errorForm}
            }
          },
          sectionOpen,
          error,
          errorStatusCode: error.status_code,
          errorCustomMessage: error.status_code === 422? 'Please fulfilled your form.': null,
          isSubmitDetails: false,
          isSuccessSubmit: false
        });
        if(!this.state.isAlertErrorOpen) this._handleToggleAlertError();
      }
      else{
        this.setState({
          form:{
            ...this.state.form,
            error: null
          },
          isSubmitDetails: false,
          isSuccessSubmit: true,
          productUrl: `/product_variants/${data.product_variant_id}`
        });
        if(this.state.isAlertErrorOpen) this._handleToggleAlertError();
      }
    }
    if(prevProps.dataCategoryOptions.length !== this.props.dataCategoryOptions.length){
      const options = this.props.dataCategoryOptions.map(item => ({...item}))
      this.setState({
        categoryOptions: [
          ...this.state.categoryOptions.filter(item => item.value === ''),
          ...options
        ]
      })
    }

    if(prevProps.dataMasterBrand.isFetch && prevProps.dataMasterBrand.isFetch !== this.props.dataMasterBrand.isFetch){
      const { error } = this.props.dataMasterBrand;
      if(!error){
        const { list: { data } } = this.props.dataMasterBrand;
        const { parentBrand } = this.state.form;
        let childBrandOptions = [];
        if(parentBrand){
          const parent = data.find(p => p.id == parentBrand.value);
          if(parent) childBrandOptions = parent.children.map(item => ({
            value: item.id,
            label: item.name
          }))
        }
        this.setState({
          parentBrandOptions: [
            { value: '', label: this.state.form.isNewBrand? 'As a Root': 'Choose Parent Brand...'},
            ...data.map(item => ({
              value: item.id,
              label: item.name
            }))
          ],
          childBrandOptions
        })

      }
    }
  }

  handleOnSectionToogle(name){
    const { sections } = this.state;
    const s = sections.find(item => item.sectionName === name);
    if(s && !s.isLocked && this.state.sectionOpen !== name){
      this.setState({ sectionOpen: name })
    }
  }

  async _handleCategorySelectChange(selected){
    await this.setState({
      form: {
        ...this.state.form,
        category: selected && {...selected},
        parentBrand: null,
        brand: null,
        product: null
      },
      childBrandOptions: [],
      _recentSearchProduct: ''
    });
    await this.settingSection();
    if(selected !== null && selected.value !== ''){
      this.props.bindBrandOption({params: {category_id: selected.value}});
      this.handleOnSectionToogle("FORM_BRAND_SECTION")
      this.refs.selectProduct && this.refs.selectProduct.purgeCache()
    }
  }

  async _handleParentBrandSelectChange(selected){
    const { list: { data } } = this.props.dataMasterBrand;
    const children = selected !== null && selected.value !== ''? data.find(p => p.id == selected.value).children: []
    await this.setState({
      form:{
        ...this.state.form,
        parentBrand: selected && { ...selected },
        brand: null
      },
      childBrandOptions: children.length > 0?[
        { value: '', label: 'Choose a Child Brand' },
        ...children.map(item=>({
          value: item.id,
          label: item.name
        }))
      ]: children
    });
    this.settingSection();
  }

  _handleSectionLocked(name){
    const { sections } = this.state;
    const s = sections.find(item => item.sectionName === name);
    if(s) return s.isLocked;
    return true;
  }

  async _handleCreateNewBrandOnClick(e){
    e.preventDefault();
    const { isNewBrand } = this.state.form;
    const value = !isNewBrand;
    await this.setState({
      parentBrandOptions: [
        {value: '', label: !value? 'Choose Parent Brand...': 'As a Root'},
        ...this.state.parentBrandOptions.filter(({value}) => value !== '')
      ],
      form: {
        ...this.state.form,
        isNewBrand: value,
        brand: null,
        parentBrand: null
      },
      childBrandOptions: []
    });
    this.settingSection();
  }

  async _handleCreateNewProductOnClick(e){
    e.preventDefault();
    const { isNewProduct } = this.state.form;
    const value = !isNewProduct;
    await this.setState({
      form: {
        ...this.state.form,
        isNewProduct: value,
        newProduct: value? '': this.state.form.newProduct
      },
      childBrandOptions: []
    });
  }

  async settingSection(){
    const { sectionOpen, form, sections } = this.state;
    // console.log("TEST: ", sectionOpen, form);
    switch(sectionOpen){
      case "FORM_CATEGORY_SECTION":{
        await this.setState({
          sections: sections.map((s, i) => {
            if(form.category !== null && form.category.value !== ''){
              return {...s, isLocked: !(i <= 1)}
            }
            return {...s, isLocked: i > 0}
          })
        })
        break;
      }
      case "FORM_BRAND_SECTION":{
        await this.setState({
          sections: sections.map((s, i) => {
            if(form.isNewBrand){
              if(form.parentBrand !== null && form.newBrand !== ''){
                return {...s, isLocked: !(i <= 2)}
              }
            }
            else if(form.parentBrand !== null && form.parentBrand.value !== ''){
              return {...s, isLocked: !(i <= 2)}
            }
            return {...s, isLocked: i > 1}
          })
        })
        break;
      }
      default: break;
    }
  }


  _handleOnSelectedProductChange(option){
    this.setState({
      form: {
        ...this.state.form,
        product: option && {...option}
      },
      _recentSearchProduct: option && option.name
    });
  }

  _handleOnSelectProductOpen(){
    if(this.state.form.product){
      if(this.state._recentSearchProduct){
        this.refs.selectProduct.loadSelectOptions(this.state._recentSearchProduct)
      }
      else{
        this.refs.selectProduct.loadSelectOptions(this.state.form.product.name)
      }
    }
    else{
      this.refs.selectProduct.loadSelectOptions('')
    }
  }

  _handleInputTextChange(e){
    e.preventDefault();
    const { form } = this.state;
    this.setState({
      form: {
        ...form,
        [e.target.name]: e.target.value
      }
    })
  }

  _handleNicknameOnChange(value) {
    const { form } = this.state;
    this.setState({
      form: {
        ...form,
        nickname: value
      }
    });
  }

  _handleCheckedChange(e){
    this.setState({
      form: {
        ...checkChange(e, this.state.form)
      }
    });

  }

  _handleSelectedChange(option){
    this.setState({
      form: {
        ...this.state.form,
        sex: option === null? option: option.value
      }
    });
  }

  _handleDatePickerChange(value, name){
    const form = dateChange(value, name, this.state.form);
    this.setState({ form });
  }

  async _validateForm(){
    const { form } = this.state;
    const errors = {}
    if(!form.category || (form.category !== null && form.category.value === "")){
      errors.category = addErrorMessage(errors, 'category', 'Please select a category.');
    }
    if(form.displayName === ''){
      errors.displayName = addErrorMessage(errors, 'displayName', 'The display name is required.');
    }
    if(form.SKU === ''){
      errors.SKU = addErrorMessage(errors, 'SKU', 'The SKU is required.');
    }
    if(form.colour === ''){
      errors.colour = addErrorMessage(errors, 'colour', 'The colour is required.');
    }
    if(form.isNewBrand){
      if(form.newBrand === ''){
        errors.newBrand = addErrorMessage(errors, 'newBrand', 'The new brand is required.');
      }
    } else {
      if(!form.parentBrand || (form.parentBrand !== null && form.parentBrand.value === "")){
        errors.parentBrand = addErrorMessage(errors, 'parentBrand', 'Please select a brand.');
      }
    }
    if(form.isNewProduct){
      if(form.newProduct === ''){
        errors.newProduct = addErrorMessage(errors, 'newProduct', 'The new product is required.');
      }
    } else {
      if(!form.product || (form.product !== null && form.product.id === "")){
        errors.product = addErrorMessage(errors, 'product', 'Please select a product.');
      }
    }
    this.setState({
      form:{
        ...this.state.form,
        error: Object.keys(errors).length > 0 ? {
          errors
        }: null
      }
    })
  }

  async _validateBrandForm(){
    const { form } = this.state;
    const errors = {}
    if(form.isNewBrand){
      if(form.newBrand === ''){
        errors.newBrand = addErrorMessage(errors, 'newBrand', 'The new brand is required.');
      }
    } else {
      if(!form.parentBrand || (form.parentBrand !== null && form.parentBrand.value === "")){
        errors.parentBrand = addErrorMessage(errors, 'parentBrand', 'Please select a brand.');
      }
    }
    this.setState({
      form:{
        ...this.state.form,
        error: Object.keys(errors).length > 0 ? {
          errors
        }: null
      }
    })
  }

  _generateErrorsFromApi(){
    const { error } = this.props.dataSkuRequest;
    if(error && error.errors && error.status_code === 422){
      const { errors } = error;
      return Object.keys(errors).map(k => k).reduce((obj, k) =>{
        if(errorApi[k]){
          const p = errorApi[k].uiErrorKey? errorApi[k].uiErrorKey: k;
          obj[p] = errors[k].map(str => {
            if(errorApi[k].uiErrorLabel) return str.replace(errorApi[k].apiErrorLabel, errorApi[k].uiErrorLabel)
            return str
          })
          return {...obj}
        }
        obj[k] = [...errors[k]]
        return {...obj}
      }, {})
    }
    return null;
  }

  async _handleOnSubmitBrand(e){
    e.preventDefault();
    //validate form here;
    await this._validateBrandForm();
    const { form:{ error }} = this.state;
    // console.log("_validateBrandForm: ", error);
    if(!error){
      await this.settingSection();
      this.handleOnSectionToogle("FORM_PRODUCT_SECTION");
    } else{
      this.setState({
        errorStatusCode: 422,
        errorCustomMessage: 'Please fulfilled your form.',
      });
      if(!this.state.isAlertErrorOpen) this._handleToggleAlertError();
    }
  }

  async _handleOnSubmit(e){
    e.preventDefault();
    //validate form here;
    await this._validateForm();
    const { form:{ error }} = this.state;
    // console.log("_validateForm: ", error);
    if(!error){
      await this.setState({
        isSubmitDetails: true,
        loadingText: "Submitting your request..."
      });
      const { form, dataSKU: { id } } = this.state;
      const payloads = {
        category_id: form.category.value,
        status: 'APPROVED',
        img_url: getOriginFromSignedUrl(this.state.dataSKU.imgUrl),
        display_name: form.displayName,
        SKU: form.SKU,
        remarks: form.remarks === ''? null : form.remarks,
        product_variant: {
          SKU: form.SKU,
          display_name: form.displayName,
          colour: form.colour,
          type: form.type !== ''? form.type : null,
          weight: form.weight,
          sex: form.sex,
          active: form.active,
          editors_choice: form.editorsChoice,
          retail_price: form.retailPrice === ''? null: form.retailPrice,
          price_source: form.priceSource === ''? null: form.priceSource,
          release_date: form.releaseDate === ''? null: form.releaseDate,
          expiry_date: form.expiryDate === ''? null: form.expiryDate,
          nickname: form.nickname? form.nickname.map(item => item.value).join(',').trim() : null
        }
      }
      if(form.isNewBrand){
        payloads.other_brand = form.newBrand === ''? null: form.newBrand;
        payloads.brand = {
          parent_id: form.parentBrand && form.parentBrand.value !== ''? form.parentBrand.value: null,
          name: form.newBrand
        }
      }
      else{
        if(form.brand){
          payloads.brand_id = form.brand.value;
          payloads.brand = {
            id: form.brand.value,
            name: form.brand.label
          }
        } else if(form.parentBrand){
          payloads.brand_id = form.parentBrand.value;
          payloads.brand = {
            id: form.parentBrand.value,
            name: form.parentBrand.label
          }
        }
      }
      if(form.isNewProduct){
        payloads.product = {
          name: form.newProduct
        }
      }
      else if(form.product){
        payloads.product = {
          id: form.product.id,
          name: form.product.name
        }
      }
      // console.log("MY PAYLOADS: ", payloads);
      this.props.approveSkuRequest(payloads, id);
    } else{
      this.setState({
        errorStatusCode: 422,
        errorCustomMessage: 'Please fulfilled your form.',
      });
      if(!this.state.isAlertErrorOpen) this._handleToggleAlertError();
    }
  }

  _handleToggleAlertError(){
    this.setState({
      isAlertErrorOpen: !this.state.isAlertErrorOpen
    })
  }

  _hasError(propsName){
    const { form: { error } } = this.state;
    return error && error.errors && hasKeyError(error.errors, propsName);
  }

  render(){
    return(
      <Modal size="md" className="modal-confirmation" isOpen={this.props.isOpen} toggle={this.props.toggle}>
        <CardLoadingPlaceholder
          isVisible={this.state.isSubmitDetails}
          loadingText={this.state.loadingText}
        />
        <ModalHeader toggle={this.props.toggle} className="modal-header--center">
          Approve SKU Request
        </ModalHeader>
        <ModalBody style={{maxHeight: "80vh", overflow:'auto'}}>
          <SkuRequestDetail
            {...this.state.dataSKU}
            isExpanded={this.state.isDetailExpanded}
            toggle={()=>this.setState({ isDetailExpanded: !this.state.isDetailExpanded })}
          />
          <AlertApprovedSuccess
            isOpen={this.state.isSuccessSubmit}
            productUrl={this.state.productUrl}
          />
          <Collapse isOpen={!this.state.isSuccessSubmit}>
            <Card style={{border: "none", marginTop: '.5rem'}}>
              <CardText className="text-center">Please Complete Your Form.</CardText>
              <AlertError
                isOpen={this.state.isAlertErrorOpen}
                message={this.state.errorCustomMessage}
                errorStatusCode={this.state.errorStatusCode}
                toggle={this._handleToggleAlertError}
              />
              <StepForm
                formTitle="Choose a Category"
                isOpen={this.state.sectionOpen === 'FORM_CATEGORY_SECTION'}
                toggle={()=> this.handleOnSectionToogle('FORM_CATEGORY_SECTION')}
                isLocked={this._handleSectionLocked('FORM_CATEGORY_SECTION')}
              >
                <Form style={{padding: '.5rem 0px'}}>
                  <Row>
                    <Col xs={12} md={6}>
                      <FormGroup
                        className={classNames({"has-danger has-feedback": this._hasError('category')})}
                      >
                        <Select
                          id="formCategoryName"
                          // name="category_id"
                          value={this.state.form.category}
                          options={this.state.categoryOptions}
                          onChange={this._handleCategorySelectChange}
                        />
                        { errorMessage('category', this.state.form.error) }
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
              </StepForm>
              <StepForm
                formTitle="Choose a Brand"
                isOpen={this.state.sectionOpen === 'FORM_BRAND_SECTION'}
                toggle={()=> this.handleOnSectionToogle('FORM_BRAND_SECTION')}
                isLocked={this._handleSectionLocked('FORM_BRAND_SECTION')}
              >
                <Form onSubmit={this._handleOnSubmitBrand}>
                  <Row>
                    <Col xs={12} md={6}>
                      <FormGroup
                        className={classNames({"has-danger has-feedback": this._hasError('parentBrand')})}
                      >
                        <Label
                          for="formBrandName">
                          Parent Brand {' '}
                          <a
                            href="#"
                            onClick={this._handleCreateNewBrandOnClick}
                          >
                            { !this.state.form.isNewBrand? 'New Brand':'Choose Brand' }
                          </a>
                        </Label>
                        <Select
                          id="formBrandName"
                          // name="parent_brand_id"
                          value={this.state.form.parentBrand}
                          options={this.state.parentBrandOptions}
                          onChange={this._handleParentBrandSelectChange}
                        />
                        { errorMessage('parentBrand', this.state.form.error) }
                        {/* {this.validateError('parent_brand_id')} */}
                      </FormGroup>
                    </Col>
                    <Col xs={12} md={6}>
                      {
                        !this.state.form.isNewBrand?(
                          <FormGroup
                            className={classNames({"has-danger has-feedback": this._hasError('childBrand')})}
                          >
                            <Label for="formChildBrandName">Child Brand</Label>
                            <Select
                              id="formChildBrandName"
                              value={this.state.form.brand}
                              options={this.state.childBrandOptions}
                              onChange={(val)=> this.setState({
                                form:{
                                  ...this.state.form,
                                  brand: val && { ...val }
                                }
                              })}
                            />
                            { errorMessage('childBrand', this.state.form.error) }
                          </FormGroup>
                        ):(
                          <FormGroup
                            className={classNames({"has-danger has-feedback": this._hasError('newBrand')})}
                          >
                            <Label for="formNewBrandName">New Brand</Label>
                            <Input
                              id="formNewBrandName"
                              value={this.state.form.newBrand}
                              onChange={(e)=> this.setState({
                                form:{
                                  ...this.state.form,
                                  newBrand: e.target.value
                                }
                              })}
                            />
                            { errorMessage('newBrand', this.state.form.error) }
                          </FormGroup>
                        )
                      }
                    </Col>
                    <Col>
                      <FormGroup>
                        <Button color="primary">Save</Button>
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
              </StepForm>
              <StepForm
                noBorder
                formTitle="Create new Product Variant"
                isOpen={this.state.sectionOpen === 'FORM_PRODUCT_SECTION'}
                toggle={()=> this.handleOnSectionToogle('FORM_PRODUCT_SECTION')}
                isLocked={this._handleSectionLocked('FORM_PRODUCT_SECTION')}
              >
                <Form onSubmit={this._handleOnSubmit}>
                  <Row>
                    <Col xs={12}>
                      <FormGroup
                        className={classNames({"has-danger has-feedback": this._hasError('product')||this._hasError('newProduct')})}
                      >
                        <Label>
                          Product {' '}
                          <a
                            href="#"
                            onClick={this._handleCreateNewProductOnClick}
                          >
                            { !this.state.form.isNewProduct? 'New Product':'Choose Product' }
                          </a>
                        </Label>
                        {
                          this.state.form.isNewProduct?(
                            <Input
                              name="newProduct"
                              value={this.state.form.newProduct}
                              onChange={this._handleInputTextChange}
                            />
                          ):(
                            <SelectProduct
                              id="formProductId"
                              name="product"
                              ref="selectProduct"
                              placeholder="Select a product..."
                              noResultsText="Cannot find product."
                              cacheOptions={false}
                              paramsApi={{ category_id: this.state.form.category && this.state.form.category.value }}
                              value={this.state.form.product}
                              onSelectSearch={(input)=>this.setState({_recentSearchProduct: input})}
                              onSelectChange={this._handleOnSelectedProductChange}
                              onSelectOpen={this._handleOnSelectProductOpen}
                            />
                          )
                        }
                        { errorMessage('product', this.state.form.error) }
                        { errorMessage('newProduct', this.state.form.error) }
                      </FormGroup>
                      <Alert color="info">
                        <strong><i className="fa fa-info"></i> Info:</strong> if you select an existing product,
                        the brand that you filled before doesnt affected.
                        The brand will be set based on your selected product when submit form successfully.
                      </Alert>
                      <FormGroup
                        className={classNames({"has-danger has-feedback": this._hasError('displayName')})}
                      >
                        <Label for="formProductDisplayName">Display Name</Label>
                        <Input
                          type="text"
                          id="formProductDisplayName*"
                          name="displayName"
                          placeholder="Enter a Display Name"
                          value={this.state.form.displayName}
                          onChange={this._handleInputTextChange}
                        />
                        { errorMessage('displayName', this.state.form.error) }
                      </FormGroup>
                      <FormGroup
                        className={classNames({"has-danger has-feedback": this._hasError('SKU')})}
                      >
                        <Label for="formProductSKU">SKU</Label>
                        <Input
                          type="text"
                          id="formProductSKU"
                          name="SKU"
                          placeholder="Enter a SKU*"
                          value={this.state.form.SKU}
                          // disabled
                          onChange={this._handleInputTextChange}
                        />
                        { errorMessage('SKU', this.state.form.error) }
                      </FormGroup>
                      <FormGroup
                        className={classNames({"has-danger has-feedback": this._hasError('colour')})}
                      >
                        <Label for="formProductColour">Colour</Label>
                        <Input
                          type="text"
                          id="formProductColour"
                          name="colour"
                          placeholder="Enter a Colour*"
                          value={this.state.form.colour}
                          onChange={this._handleInputTextChange}
                        />
                        { errorMessage('colour', this.state.form.error) }
                      </FormGroup>
                      <FormGroup
                        className={classNames({"has-danger has-feedback": this._hasError('type')})}
                      >
                        <Label for="formProductType">Type</Label>
                        <Input
                          type="text"
                          id="formProductType"
                          name="type"
                          placeholder="Enter a Type"
                          value={this.state.form.type}
                          onChange={this._handleInputTextChange}
                        />
                        { errorMessage('type', this.state.form.error) }
                      </FormGroup>
                      <FormGroup
                        className={classNames({"has-danger has-feedback": this._hasError('nickname')})}
                      >
                        <Label for="formProductNickName">Nickname Or Tags</Label>
                        <Creatable
                          multi
                          options={this.state.defaultTags}
                          value={this.state.form.nickname}
                          onChange={this._handleNicknameOnChange}
                          placeholder="Enter a nickname or tags"
                        />
                        { errorMessage('nickname', this.state.form.error) }
                      </FormGroup>
                    </Col>
                    <Col xs={12} md={6}>
                      <FormGroup
                        className={classNames({"has-danger has-feedback": this._hasError('weight')})}
                      >
                        <Label for="formProductWeight">Weight</Label>
                        <Input
                          type="text"
                          id="formProductWeight"
                          name="weight"
                          placeholder="Enter a Weight*"
                          value={this.state.form.weight}
                          onChange={this._handleInputTextChange}
                        />
                        { errorMessage('weight', this.state.form.error) }
                      </FormGroup>
                    </Col>
                    <Col xs={12} md={6}>
                      <FormGroup
                        className={classNames({"has-danger has-feedback": this._hasError('sex')})}
                      >
                        <Label for="formProductSex">Sex</Label>
                        <Select
                          id="formProductSex"
                          name="sex"
                          value={this.state.form.sex}
                          // options={[{value:'U',label:'Unisex'},{value:'F',label:'Female'},{value:'M',label:'Male'},{value:'P',label:'Primary School'},{value:'GS',label:'Grade School'},{value:'IT',label:'Infant Toddler'}]}
                          options={[{value:'F',label:'Female'},{value:'M',label:'Male'},{value:'P',label:'Primary School'},{value:'GS',label:'Grade School'},{value:'IT',label:'Infant Toddler'}]}
                          onChange={this._handleSelectedChange}
                        />
                        { errorMessage('sex', this.state.form.error) }
                      </FormGroup>
                    </Col>
                    <Col xs={12} md={6}>
                      <FormGroupRadio>
                        <Radio
                          type="checkbox"
                          name="active"
                          value={true}
                          checked={this.state.form.active}
                          dataOnText="Yes"
                          dataOffText="No"
                          onChange={this._handleCheckedChange}
                        />
                        <RadioText text="Set to Active"/>
                      </FormGroupRadio>
                    </Col>
                    <Col xs={12} md={6}>
                      <FormGroupRadio>
                        <Radio
                          type="checkbox"
                          name="editorsChoice"
                          value={true}
                          checked={this.state.form.editorsChoice}
                          dataOnText="Yes"
                          dataOffText="No"
                          onChange={this._handleCheckedChange}
                        />
                        <RadioText text="Set to Editor Choice"/>
                      </FormGroupRadio>
                    </Col>
                    <Col xs={12} md={6}>
                      <FormGroup
                        className={classNames({"has-danger has-feedback": this._hasError('retailPrice')})}
                      >
                        <Label for="formProductRetailPrice">Retail Price</Label>
                        <Input
                          type="text"
                          id="formProductRetailPrice"
                          name="retailPrice"
                          placeholder="Enter a retail price"
                          value={this.state.form.retailPrice}
                          onChange={this._handleInputTextChange}
                        />
                        { errorMessage('retailPrice', this.state.form.error) }
                      </FormGroup>
                    </Col>
                    <Col xs={12} md={6}>
                      <FormGroup
                        className={classNames({"has-danger has-feedback": this._hasError('priceSource')})}
                      >
                        <Label for="formProductPriceSource">Price Source</Label>
                        <Input
                          type="text"
                          id="formProductPriceSource"
                          name="priceSource"
                          placeholder="Enter a price source"
                          value={this.state.form.priceSource}
                          onChange={this._handleInputTextChange}
                        />
                        { errorMessage('priceSource', this.state.form.error) }
                      </FormGroup>
                    </Col>
                    <Col xs={12} md={6}>
                      <FormGroup
                        className={classNames({"has-danger has-feedback": this._hasError('releaseDate')})}
                      >
                        <Label for="formProductReleaseDate">Release Date</Label>
                        <InputDatetime
                          inputProps={{placeholder:'Pick a Release Date'}}
                          value={dateValue('releaseDate', this.state.form)}
                          onChange={(date)=> this._handleDatePickerChange(date, "releaseDate")}
                          timeFormat="HH:mm"
                        />
                        { errorMessage('releaseDate', this.state.form.error) }
                      </FormGroup>
                      <FormGroup
                        className={classNames({"has-danger has-feedback": this._hasError('expiryDate')})}
                      >
                        <Label for="formProductExpiryDate">Expiry Date</Label>
                        <InputDatetime
                          inputProps={{placeholder:'Pick a Expiry Date'}}
                          value={dateValue('expiryDate', this.state.form)}
                          onChange={(date)=> this._handleDatePickerChange(date, "expiryDate")}
                          timeFormat="HH:mm"
                        />
                        { errorMessage('expiryDate', this.state.form.error) }
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup
                        className={classNames({"has-danger has-feedback": this._hasError('remarks')})}
                      >
                        <Label for="formRemarks">Remarks (*if any)</Label>
                        <Input
                          type="textarea"
                          id="formRemarks"
                          name="remarks"
                          placeholder="Enter remarks, if any"
                          value={this.state.form.remarks}
                          onChange={this._handleInputTextChange}
                        />
                        {errorMessage('remarks', this.state.form.error)}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Button color="primary">Submit</Button>
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
              </StepForm>
            </Card>
          </Collapse>
        </ModalBody>
      </Modal>
    )
  }
}
const mapStateToProps= ({ masterCategories, masterBrand, skuRequest: { details } }) => ({
  dataMasterBrand: masterBrand,
  dataCategoryOptions: masterCategories.options,
  dataSkuRequest: details
});

const mapDispatchToProps = (dispatch) => ({
  getDetailSkuRequest: id => dispatch(getSkuRequestById(id)),
  approveSkuRequest: (payload, id) => dispatch(saveSkuRequest(payload, id)),
  bindBrandOption:(q)=>dispatch(getBrandList(q)),
  bindCategoryOption:()=>dispatch(getCategoryOptionList())
});
export default connect(mapStateToProps, mapDispatchToProps)(ModalSkuApproved);