import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Form,
  Row,
  Col,
  FormGroup,
  Input,
  Label,
  Alert
} from 'reactstrap';
import classNames from 'classnames';
import { updateRaffleCoupon, redeemRaffleCoupon } from '../../../../actions/raffleAction';
import { errorMessage, hasError ,  textValue } from '../../../../utils/form';
import { _getValue } from '../../../../utils/form';
import { ButtonLoading, ButtonCopy } from '../../../../components/Button';
import { ModalConfirmation } from '../../../../components/Modals';

import {
  FieldRow,
  FieldColumn,
  FieldLabel,
  FieldValue
} from '../../../../styled/Fields';

const RaffleCodeDiv = styled.div`
  background-color: #dddddd;
  padding: 20px;
  /* margin-top: 1rem; */
  min-width: 200px;
  > p{
    font-weight: 700;
    font-size: 1rem;
    text-align: center;
    letter-spacing: 1.5px;
    margin: 0;
    text-align: center;
  }
`;
const FooterBtn = styled(ButtonLoading)`
  &.disabled{
    cursor: default;
  }
  &.btn-secondary.disabled {
    background: #ddd;
  }
  &.btn-secondary.disabled {
    background: #ddd;
  }
`;

const RaffleCodePlaceholder = ({ raffleCode, onSuccessCopied }) => (
  <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
    <div>
      <RaffleCodeDiv>
        <p>{ raffleCode }</p>
      </RaffleCodeDiv>
      <div style={{display: 'flex', justifyContent: 'flex-end'}}>
        <ButtonCopy value={raffleCode} onSuccessCopied={onSuccessCopied}>
          <i className="fa fa-clone" aria-hidden="true"></i>Copy
        </ButtonCopy>
      </div>
    </div>
  </div>
)

class ModalCoupon extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      formData: {
        note: ''
      },
      isSubmit: false,
      isSubmitRedeem: false,
      error: null,
      alertErrorOpen: false,
      errorMessage: null,
      alertSuccessOpen: false,
      successMessage: null,
      modalConfirm: false
    }
  }

  _handleInputChange = ({ target: { name, value, checked, type } }) => {
    this.setState({
      formData:{
        ...this.state.formData,
        [name]: type === 'checkbox'? checked: value
      }
    })
  }

  _renderErrorMessage = name => {
    const { error } = this.state;
    return errorMessage(name, error)
  }

  _hasInputError = name => {
    const { error } = this.state;
    return error && error.errors && hasError(name, error)
  }

  componentDidUpdate(prevProps) {
    if(!prevProps.isOpen && this.props.isOpen){
      this.setState({
        formData: {
          ...this.state.formData,
          notes: this.props.data.notes || '',
        },
        error: null,
        alertErrorOpen: false,
        alertSuccessOpen: false
      })
    }
    if((this.state.isSubmit || this.state.isSubmitRedeem) && prevProps.dataRaffleCoupon.isSubmit && !this.props.dataRaffleCoupon.isSubmit){
      const { error, status_code } = this.props.dataRaffleCoupon;
      let uiPayloads = {};
      if(this.state.isSubmit){
        uiPayloads = {
          isSubmit: false,
          isSubmitRedeem: this.state.isSubmitRedeem
        }
      } 
      else if(this.state.isSubmitRedeem){
        uiPayloads = {
          isSubmitRedeem: false,
          isSubmit: this.state.isSubmit
        }
      }
      if(error){
        // if(error.status_code !== 422){
        //   alert("Error occurred while create a new raffle code.")
        // }
        this.setState({
          ...uiPayloads,
          error: { ...error },
          alertSuccessOpen: false,
          alertErrorOpen: true,
          errorMessage: this.translateMessage(error.status_code)
        })
      }
      else{
        this.setState({
          ...uiPayloads,
          alertSuccessOpen: true,
          alertErrorOpen: false,
          successMessage: this.state.isSubmitRedeem? this.translateMessage('custom', 'Redeem successfully.'): this.translateMessage(status_code)
        });
      }
    }
  }

  translateMessage = (statusCode, message = null) => {
    let messageStr = '';
    if(statusCode === 'custom' && message){
      return message;
    }
    switch(statusCode){
      case 200: messageStr = 'Update data has been successfully.'; break;
      case 422: messageStr = 'Please fullfill your form.'; break;
      case 404: messageStr = 'Resource not found.'; break;
      case 500: messageStr = "We've got something errors"; break;
      default: messageStr = message; break;
    }
    return messageStr
  }


  _handleOnSubmit = e => {
    e.preventDefault()
    if(this.state.isSubmit || this.state.isSubmitRedeem) return;
    this.setState({
      isSubmit: true,
      alertSuccessOpen: false,
      alertErrorOpen: false
    });
    const { formData } = this.state;
    const { raffleId, data } = this.props;
    const payloads = {
      reference_number: data.external_id,
      user_id: data.user_id,
      email: data.email,
      receipt_image_url: data.img_url,
      notes: formData.notes? formData.notes: null
    }
    this.props.save(raffleId, data.id, payloads)
  }

  _handleToggle = () => {
    if(this.state.isSubmit || this.state.isSubmitRedeem) return;
    this.props.toggle && this.props.toggle();
  }

  toggleConfirm = () => {
    if(this.state.isSubmit || this.state.isSubmitRedeem) return;
    this.setState({ modalConfirm: !this.state.modalConfirm })
  }

  onConfirmClick = () => {
    this.setState({
      isSubmitRedeem: true,
      modalConfirm: false
    }, () => {
      const { raffleId } = this.props;
      const { data, data: { id } } = this.props;
      const { formData } = this.state;
      this.toggleConfirm()
      const payloads = {
        raffle_code: data.raffle_code,
        notes: formData.notes,
        user_id: data.user_id
      }
      this.props.redeemRaffleCoupon(raffleId, payloads)
    })
  }

  checkFormChanged = () => {
    if(!this.props.data) return false;
    const { notes } = this.state.formData;
    let { notes: propsNotes } = this.props.data;
    propsNotes = !propsNotes? '': propsNotes;
    return notes !== propsNotes;
  }

  render(){
    return(
      <div>
        <ModalConfirmation
          isOpen={this.state.modalConfirm}
          modalBody="Are you really sure?"
          onSubmit={this.onConfirmClick}
          toggle={this.toggleConfirm}
        />
        <Modal toggle={this._handleToggle} isOpen={this.props.isOpen} size={this.props.size}>
          <Form onSubmit={this._handleOnSubmit}>
            <ModalHeader toggle={this._handleToggle}>Details.</ModalHeader>
            {
              this.props.data?(
                <ModalBody style={{maxHeight: 450, overflow: 'auto'}}>
                  <Row>
                    <Col xs={12}>
                      <Alert color="success" isOpen={this.state.alertSuccessOpen} toggle={() => this.setState({alertSuccessOpen: false})}>
                        <strong>Success!</strong> { this.state.successMessage }
                      </Alert>
                    </Col>
                    <Col xs={12}>
                      <Alert color="danger" isOpen={this.state.alertErrorOpen} toggle={() => this.setState({alertErrorOpen: false})}>
                        <strong>Oh Snap!</strong> { this.state.errorMessage }
                      </Alert>
                    </Col>
                  </Row>
                  <div>
                    <RaffleCodePlaceholder
                      raffleCode={this.props.data.raffle_code}
                      onSuccessCopied={() => this.setState({
                        alertSuccessOpen: true,
                        successMessage: this.translateMessage('custom', 'Copied to clipboard.')
                      })}
                    />
                  </div>
                  <FieldRow>
                    <FieldColumn className="col-xs-12 col-sm-6">
                      <FieldLabel>ID </FieldLabel>
                      <FieldValue> <span>{_getValue(this.props.data.id)}</span> </FieldValue>
                    </FieldColumn>
                    <FieldColumn className="col-xs-12 col-sm-6">
                      <FieldLabel>Quantity</FieldLabel>
                      <FieldValue> <span>{_getValue(this.props.data.quantity)}</span> </FieldValue>
                    </FieldColumn>
                  </FieldRow>
                  <FieldRow>
                    <FieldColumn className="col-12">
                      <FieldLabel>Reference Numb.</FieldLabel>
                      <FieldValue> <span>{_getValue(this.props.data.external_id)}</span> </FieldValue>
                    </FieldColumn>
                    <FieldColumn className="col-12">
                      <FieldLabel>Email</FieldLabel>
                      <FieldValue> <span>{_getValue(this.props.data.email)}</span> </FieldValue>
                    </FieldColumn>
                  </FieldRow>
                  <Row>
                    <Col xs={12} sm={12}>
                      <FormGroup
                        className={classNames({ 'has-danger has-feedback': this._hasInputError('notes') })}
                      >
                        <Label>Notes - optional</Label>
                        <Input
                          placeholder="Enter a notes."
                          name="notes"
                          type="textarea"
                          value={textValue('notes', this.state.formData)}
                          onChange={this._handleInputChange}
                        />
                        { this._renderErrorMessage('notes') }
                      </FormGroup>
                    </Col>
                  </Row>
                  <FieldRow>
                    <FieldColumn className="col-xs-12 col-md-6">
                      <FieldLabel>Created At</FieldLabel>
                      <FieldValue> <span>{_getValue(this.props.data.created_at)}</span> </FieldValue>
                    </FieldColumn>
                    <FieldColumn className="col-xs-12 col-md-6">
                      <FieldLabel>Updated At</FieldLabel>
                      <FieldValue> <span>{_getValue(this.props.data.updated_at)}</span> </FieldValue>
                    </FieldColumn>
                    <FieldColumn className="col-xs-12 col-md-6">
                      <FieldLabel>Redeemed</FieldLabel>
                      <FieldValue> <span>{_getValue(this.props.data.redeemed_at, '-')}</span> </FieldValue>
                    </FieldColumn>
                  </FieldRow>
                </ModalBody>
              ): null
            }
            <ModalFooter>
              {/* {
                this.props.data?(
                  <FooterBtn
                    type="button"
                    disabled={this.state.isSubmit}
                    isLoading={this.state.isSubmit}
                    loadingMessage="Loading..."
                    color="secondary"
                    disabled={this.props.data.user_id===null || this.props.data.redeemed_at !== null}
                    onClick={this.toggleConfirm}
                  >
                    Redeem
                  </FooterBtn>
                ): null
              } */}
              <FooterBtn
                disabled={this.state.isSubmit || !this.checkFormChanged()}
                isLoading={this.state.isSubmit}
                loadingMessage="Updating..."
                color="primary"
              >
                Update
              </FooterBtn>
            </ModalFooter>
          </Form>
        </Modal>
      </div>
    )
  }
}

const mapStateToProps= ({ raffle: { coupons } }) => ({
  dataRaffleCoupon: coupons.details
});
const mapDispatchToProps = (dispatch) => ({
  save: (raffleId, id, payloads) => dispatch(updateRaffleCoupon(raffleId, id, payloads)),
  redeemRaffleCoupon: (raffleId, payloads) => dispatch(redeemRaffleCoupon(raffleId, payloads))
});
export default connect(mapStateToProps,mapDispatchToProps)(ModalCoupon);
