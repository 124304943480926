import {
  GET_DOLLAR_RATE_SETTING,
  DOLLAR_RATE_SETTING_REQUEST,
  DOLLAR_RATE_SETTING_ERROR,
  UPDATE_DOLLAR_RATE_SETTING,
  SETTING_LIST_FETCH,
  SETTING_FETCHING,
  SETTING_ERROR,
  SETTING_DETAIL_FETCH,
  SETTING_ADD,
  SETTING_UPDATE,
  SETTING_DELETE,
  PAYMENT_METHOD_FETCH,
  PAYMENT_METHOD_FETCHING,
  SETTING_GET_PROCESSING_FEE,
} from '../constants';

const initialState = {
  appStatus: "online",
  dollarSetting:{
    data: null,
    error: null,
    isFetch: false,
    isSubmit: false,
  },
  list: [],
  detail: {},
  isFetch: false,
  isSubmit: false,
  error: null,
  success: null,
  paymentMethods: {
    isFetch: false,
    data: [],
    error: null
  },
  processingFee: {
    percentage: 0,
    percentageOfflineTx: 0,
    isFetch: false,
    error: null,
  }
}

const onFetching = (lastState,payload) => {
  if(payload.type==='fetch')
    return { ...lastState, isFetch:payload.status, success: null, error: null }
  else if(payload.type==='submit')
    return { ...lastState, isSubmit:payload.status }

  return { ...lastState, isFetch:false,isSubmit:false }
}

const onFetched = (lastState, { requestType, value }) => {
  switch(requestType){
    case GET_DOLLAR_RATE_SETTING: return {...lastState, dollarSetting: { ...lastState.dollarSetting, isFetch: value }};
    case UPDATE_DOLLAR_RATE_SETTING: return {...lastState, dollarSetting: { ...lastState.dollarSetting, isSubmit: value }};
    default: return lastState;
  }
}

const onError = (lastState, { requestType, error, ...rest }) =>{
  switch(requestType){
    case GET_DOLLAR_RATE_SETTING: return {...lastState, dollarSetting: { ...lastState.dollarSetting, error: { ...error, ...rest }, isFetch: false }};
    case UPDATE_DOLLAR_RATE_SETTING: return {...lastState, dollarSetting: { ...lastState.dollarSetting, error: { ...error, ...rest }, isSubmit: false }};
    default: return lastState;
  }
}

const getProcessingFeeValue = (payload) => {
  const setting = payload?.data?.data?.[0];
  try {
    return JSON.parse(setting?.value);
  } catch (error) {
    return {};
  }
}

const handleGetProcessingFeeFulfilled = (lastState, payload) => {
  const settingValue = getProcessingFeeValue(payload);
  return {
    ...lastState,
    processingFee: {
      ...lastState.processingFee,
      isFetch: false,
      percentage: settingValue?.percentage || 0,
      percentageOfflineTx: settingValue?.percentageOfflineTx || 0,
    }
  }
}

const reducer = (state = initialState, { type, payload }) => {
  switch(type){
    case SETTING_LIST_FETCH: {
      const {data, status_code, message} = payload;
      return {...state, list:{...data, message, status_code}, detail: {}, error: null}
    }
    case SETTING_FETCHING: return onFetching(state, payload);
    case SETTING_DETAIL_FETCH: {
      const {data, status_code, message}=payload;
      return {...state, detail: {...state.detail, ...data, message, status_code, deleted: false}, error: null}
    }
    case SETTING_ADD: {
        const {data, status_code, message} = payload;
        return {...state, detail:{...state.detail, ...data, message, status_code}, success: message, error: null}
    }
    case SETTING_UPDATE: {
        const {data, status_code, message} = payload;
        return {...state, detail:{...state.detail, ...data, message, status_code}, success: message, error: null}
    }
    case SETTING_DELETE: {
        const {data, status_code, message} = payload;
        return {...state, default:{...state.detail, ...data, message, status_code}, success: message, delete: true, error: null}
    }
    case DOLLAR_RATE_SETTING_REQUEST: return onFetched(state, payload)
    case DOLLAR_RATE_SETTING_ERROR: return onError(state, payload)
    case GET_DOLLAR_RATE_SETTING: return {
      ...state,
      dollarSetting: {
        ...state.dollarSetting,
        isFetch: false,
        error: null,
        ...payload
      }
    }
    case UPDATE_DOLLAR_RATE_SETTING: return {
      ...state,
      dollarSetting: {
        ...state.dollarSetting,
        isSubmit: false,
        error: null,
        ...payload
      }
    }
    case "ERROR_MAINTENANCE_MODE": return {
      ...state,
      appStatus: "offline"
    }
    case SETTING_ERROR: {
      return {...state, ...payload, success: null}
    }
    case PAYMENT_METHOD_FETCHING: {
      return {
        ...state, 
        paymentMethods: {
          ...state.paymentMethods,
          isFetch: true,
          ...payload,
        }
      }
    }
    case PAYMENT_METHOD_FETCH:{
      return {
        ...state, 
        paymentMethods: {
          ...state.paymentMethods,
          isFetch: false,
          data: payload,
        }
      }
    }
    case `${SETTING_GET_PROCESSING_FEE}_PENDING`: {
      return {
        ...state,
        processingFee: {
          ...state.processingFee,
          isFetch: true,
        }
      }
    }
    case `${SETTING_GET_PROCESSING_FEE}_FULFILLED`:
      return handleGetProcessingFeeFulfilled(state, payload);
    case `${SETTING_GET_PROCESSING_FEE}_REJECTED`: {
      return {
        ...state,
        processingFee: {
          ...state.processingFee,
          isFetch: false,
          error: payload,
        }
      }
    }
    default: return state;
  }
}

export default reducer;