import React, { Component } from 'react';
import { connect } from 'react-redux'
import PointShopForm from './PointShopForm';
import privateView from '../../components/hocs/privateView';
import Analytics from "../../services/Analytics";

class PointShopCreate extends Component{
	componentDidMount(){
    const { email } = this.props.injectedProps;
    Analytics.recordPageView('point_shop_detail', email, {
      url: window.location.href,
      location: this.props.location,
      match: this.props.match
    });
  }
  render(){
    return (
      <div className="animate fadeIn">
        <PointShopForm
          title="Create New Point Shop"
          {...this.props}
        />
      </div>
    )
  }
}

const mapStateToProps = ({auth: { isFetch, roles, isLogged, id, email } })=>({
  guardData: { isFetch, roles, isLogged, id, email }
})

const enhance = connect(mapStateToProps);

export default enhance(privateView(PointShopCreate));

