import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import {
  Alert,
  Row,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  Col,
  Label,
  ListGroup,
  ListGroupItem,
  FormGroup,
} from 'reactstrap';
import { getPointShopItems } from '../../../../actions/pointShopAction';
import { FontAwesomeIcon } from '../../../../components/Icons';
import { ValidationMessage } from '../../../../components/Form/ValidationMessage';
import ItemList from '../ItemList';
import { Radio, RadioText, FormGroupRadio } from '../../../../components/Form/Radios';

const defaultParams = {
  per_page: 15,
  page: 1,
  sort_by: 'updatedAt_desc',
  exclude: true
}

const defaultPaginations = {
  from: 0,
  to: 0,
  total: 0,
  currentPage: 0,
  lastPage: 0,
  perPage: 0
}

class ModalVouchers extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      _params: {
        ...defaultParams
      },
      paginations: {
        ...defaultPaginations
      },
      list: [],
      listSelected: [],
      _error: null,
      _alertErrorOpen: false,
      hasSearchKeyword: false,
      view: 'NORMAL'
    }
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidUpdate(prevProps){
    if(this.props.isOpen && this.props.isOpen !== prevProps.isOpen){
      this.setState({
        _params: {
          ...this.state._params,
          ...defaultParams
        },
        paginations:{
          ...defaultPaginations
        },
        list: [],
        listSelected: []
      }, () => {
        if(this.state.hasSearchKeyword){
          this._getDataList(this.state._params)
        }
      })
    }
    if(!this.props.dataPointShopItems.isFetch && prevProps.dataPointShopItems.isFetch !== this.props.dataPointShopItems.isFetch){
      if(!this.props.dataPointShopItems.error && this.props.dataPointShopItems.data){
        const { data, ...paginations } = this.props.dataPointShopItems.data;
        this.setState({
          list: [...data],
          paginations: {
            ...this.state.paginations,
            from: paginations.from,
            to: paginations.to,
            total: paginations.total,
            currentPage: paginations.current_page,
            lastPage: paginations.last_page,
            perPage: paginations.per_page
          },
          view: 'NORMAL'
        })
      }
    }
    if(!this.props.dataPointShopItems.isSubmit && prevProps.dataPointShopItems.isSubmit !== this.props.dataPointShopItems.isSubmit){
      const { data, error } = this.props.dataPointShopItems;
      if(!error && data && this.props.isOpen)
        this.props.toggle();
      else{
        if(error)
          this.setState({
            _alertErrorOpen: true,
            _errorMessage: this.translateMessage(error.status_code, error.message)
          })
      }
    }
  }

  _sanitizeParams = rawParams => {
    return {
      params: Object.keys(rawParams)
      .filter(key => rawParams[key]!='')
      .reduce((obj, current)=> ({...obj, [current]: rawParams[current]}), [])
    }
  }

  _getDataList = (rawParams = {}) => {
    const { voucherId } = this.props;
    this.props.getPointShopItems(
      voucherId,
      this._sanitizeParams(rawParams)
    )
  }

  _handleGoto = page => {
    const _params = this.state._params;
    _params.page = page;
    this.setState({ _params },
      ()=> this._getDataList(this.state._params)
    );
  }

  translateMessage = (statusCode, message) => {
    let messageStr = ''
    switch(statusCode){
      case 200: messageStr = 'Success...'; break;
      case 422: messageStr = 'Please fullfill your form.'; break;
      case 404: messageStr = 'Resource not found.'; break;
      case 500: messageStr = "We've got something errors"; break;
      default: messageStr = message; break;
    }
    return messageStr
  }

  _clearSelected = () => {
    this.setState({
      listSelected: [],
      _error:null,
      _alertErrorOpen:false,
      _errorMessage:'',
      view: 'NORMAL'
    });
  }

  _validateForm = () => {
    // let errors={};
    const { listSelected } = this.state;
    let errors = listSelected.reduce(function(prev ,item){
      if(!item.points){
        // const err = addErrorMessage(errors, `item${item.id}`, 'The points field is required.');
        // errors = {
        //   ...
        //   [`item${item.id}`]
        // };
        const next = {
          ...prev,
          [`item_${item.id}`]: 'The points field is required.'
        }
        return next
      }
      return prev;
    }, {});
    let errorState = null;
    if(Object.keys(errors).length){
      const message = 'Please full fill form';
      errorState = {
        errors: { ...errors },
        status_code: 422,
        message: message
      }
      this.setState({
        _error: errorState? {...errorState}: null,
        _errorMessage: errorState? errorState.message: null,
        _alertErrorOpen: true,
        _errorMessage: message
      })
    }
    return Object.keys(errors).length>0;
  }


  _handleOnSubmit = () => {
    const { onSubmit } = this.props;
    const { listSelected } = this.state;
    if(!this._validateForm()){
      if(listSelected.length){
        onSubmit(listSelected);
      }
      this._clearSelected();
      // toggle();
    }
  }


  handleChange(e, item){
    const { listSelected } = this.state;
    const elementsIndex = listSelected.findIndex(element => element.id === item.id)
    let newArray = [...listSelected];
    newArray[elementsIndex] = {...newArray[elementsIndex], points: e.target.value}
    this.setState({listSelected:newArray});
  }

  render(){
    return(
      <Modal isOpen={this.props.isOpen} toggle={this.props.toggle} size={this.props.size}>
        <ModalBody>
          <Form
            style={{marginBottom: '.5rem'}}
            onSubmit={e => {
              e.preventDefault()
              this.setState({hasSearchKeyword: true})
              this._getDataList(this.state._params);
            }}
          >
            <InputGroup>
              <Input
                bsSize="sm"
                placeholder="Search vouchers..."
                value={this.state._params.keyword||''}
                name="keyword"
                onChange={e => {
                  this.setState({
                    _params:{
                      ...this.state._params,
                      [e.target.name]: e.target.value
                    }
                  })
                }}
              />
              <InputGroupAddon addonType="append">
                <Button color="primary">Search</Button>
              </InputGroupAddon>
            </InputGroup>
          </Form>
          <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: '1rem', alignItems: 'center'}}>
            <div>
              {this.state.listSelected.length > 0?(
                <p style={{margin: 0, fontSize: '.5rem'}} className="text-muted">Selected ({this.state.listSelected.length}) item(s).</p>
              ):null}
            </div>
            <div>
              {
                this.state.listSelected.length > 0?(
                  <Button
                    outline
                    color="danger"
                    size="sm"
                    type="button"
                    className="mr-1"
                    style={{fontSize: '.5rem'}}
                    onClick={() => this._clearSelected()}
                  >
                    Clear Selected
                  </Button>
                ): null
              }
              {
                this.state.listSelected.length > 0?(
                  <Button
                    outline
                    color="primary"
                    size="sm"
                    type="button"
                    className="mr-1"
                    style={{fontSize: '.5rem'}}
                    onClick={() => {
                      const {view} = this.state;
                      this.setState({ view: view ==='NORMAL'? 'SELECTED': 'NORMAL'})
                    }}
                  >
                    {this.state.view==='NORMAL'? 'Show': 'Hide'} Selected
                  </Button>
                ): null
              }
              <Button
                outline
                title="Refresh list"
                color="primary"
                style={{fontSize: '.5rem'}}
                size="sm"
                type="button"
                onClick={() => {
                  this.setState({hasSearchKeyword: true})
                  this._handleGoto(1)
                }}
              >
                <FontAwesomeIcon iconType="refresh"/>
              </Button>
            </div>
          </div>
          <ItemList
            hidePagination={this.state.view === 'SELECTED'}
            isLoading={this.props.dataPointShopItems.isFetch}
            paginations={this.state.paginations}
            list={this.state.view === 'NORMAL'?this.state.list: this.state.listSelected}
            renderItems={(item, index) => {
              const { view, _error } = this.state;
              const errorPoints =  _error && _error.errors;
              let actived = true;
              let selected = item;
              if(view === 'NORMAL'){
                const { listSelected } = this.state;
                actived = listSelected.length>0&&listSelected.some(list => list.id === item.id);
                selected = listSelected.find(list => list.id === item.id);
              }
              const errorPointField = selected && errorPoints ? errorPoints[`item_${selected.id}`]: null;
              const points = selected? selected.points: '';
              return(
                <div key={index} style={{paddingTop: '.75rem', maxHeight: 250, overflow: 'auto'}}>
                  <ListGroup>
                    <ListGroupItem>
                      <Row
                        className={classNames({ 'has-danger has-feedback': actived && errorPointField})}
                      >
                        <Col xs={12} md={6}>
                          <FormGroupRadio 
                            style={{marginLeft: '2px'}}
                            className={classNames('mb-0',{ 'has-danger has-feedback': errorPoints && errorPointField})}
                          >
                            <Radio
                              wrapperClassName="switch-primary"
                              radioType="3d"
                              size="xs"
                              value={true}
                              checked={actived}
                              usingLabelText={false}
                              onChange={({target: { checked: value }}) => {
                                const { listSelected } = this.state;
                                this.setState({
                                  listSelected: value? [...listSelected, {id : item.id, code: item.code, is_active:true}]: [...listSelected.filter(list => list.id !== item.id)]
                                }, () => {
                                  const { view, listSelected } = this.state;
                                  if(view === 'SELECTED' && !listSelected.length){
                                    this.setState({view: 'NORMAL'})
                                  }
                                })
                              }}
                            />
                            <RadioText text={item.code.toUpperCase()}/>
                          </FormGroupRadio>
                        </Col>
                        {
                          selected ?
                          <Col xs={12} md={6}>
                            <FormGroup
                              className={classNames('mb-0',{ 'has-danger has-feedback': errorPoints && errorPointField})}
                            >
                              <Label for="formPoints">
                                <Input
                                  type="number"
                                  id="formPoints"
                                  name="points"
                                  placeholder="Enter a points"
                                  value={points}
                                  onChange={(e) => this.handleChange(e, item)}
                                />
                              </Label>
                            </FormGroup>
                            {
                              errorPointField ?
                              <ValidationMessage message={errorPointField}/>
                              :
                              null
                            }
                          </Col>
                          :
                          null
                        }
                      </Row>
                    </ListGroupItem>
                  </ListGroup>
              </div>
            )}}
            onGotoPage={this._handleGoto}
          />
        </ModalBody>
        <Col xs={12}>
          <Alert color="danger" isOpen={this.state._alertErrorOpen} toggle={() => this.setState({_alertErrorOpen: false})}>
            <strong>Opps. </strong> {this.state._errorMessage}
          </Alert>
        </Col>
        <ModalFooter>
          <Button color="secondary" onClick={this.props.toggle}>Cancel</Button>
          <Button color="primary" onClick={this._handleOnSubmit}>Save</Button>{' '}
        </ModalFooter>
      </Modal>
    )
  }
}

const mapStateToProps= ({ pointShop: { pointShopItems } }) => ({
  dataPointShopItems: {
    isFetch: pointShopItems.isSearchFetch,
    data: pointShopItems.dataSearch,
    error: pointShopItems.error,
    isSubmit: pointShopItems.isSubmit
  }
});
const mapDispatchToProps = (dispatch) => ({
  getPointShopItems: (id, query) => dispatch(getPointShopItems(id, 'vouchers', query)),
});
export const ModalVoucher = connect(mapStateToProps,mapDispatchToProps)(ModalVouchers);