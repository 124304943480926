import React, { useMemo, useState } from "react";
import {
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    Alert,
    Label,
    Input,
    Form,
    Button,
    Tooltip,
    UncontrolledTooltip,
} from "reactstrap";
import { FontAwesomeIcon } from "../../../../components/Icons";
import { useFormik } from "formik";
import Select from "react-select";
import { UserSelectAsync } from "../../../Components/UserSelectAsync";
import SelectVariantAsync from "../../../../components/Form/Select/Async/SelectVariant";
import SelectRackAsync from "../../../../components/Form/Select/Async/SelectRack";
import { Radio } from "../../../../components/Form/Radios";
import SelectSizeAsync from "../../../Components/SelectSizeAsync/SelectSizeAsync";
import usePostSellRequest from "../../Hooks/usePostSellRequest";
import {
    BOX_CONDITION_OPTIONS,
    LISTING_CONDITION_OPTIONS,
    STOCK_STATUS_OPTIONS,
} from "./RequestForm.data";
import InputDatetime from "../../../../components/Form/InputDatetime/InputDatetime";
import { dateChange, dateValue } from "../../../../utils/form";
import Style from "./RequestForm.module.css";
import ImagesUploader from "../../../Components/Uploader/ImagesUploader/ImagesUploader";
import Defects from "./Components/Defects/Defects";
import moment from "moment";
import { USER_SELL_EXPIRY_DAYS } from "../../../../constants/kickavenue";
import { DATE_FORMAT_DB } from "../../../../config/date";

const TES_VALUE = {
    product: {
        id: 1,
        name: "Adidas Speedfactory AM4LDN",
        hide_box_info: false,
        category: "sneakers",
    },
    seller: {
        id: 192687,
        email: "haikalfikriluzain4@gmail.com",
    },
    rackTemp: {
        id: 1,
        name: "RACK 16",
        max: 55,
        count: "48",
    },
    size: {
        id: 5,
        US: "6",
    },
    listingCondition: {
        label: "Brand New",
        value: "BARU",
    },
    boxCondition: {
        label: "Perfect",
        value: "SEMPURNA",
    },
    stockStatus: {
        label: "KA Stock",
        value: "KA_STOCK",
    },
    images: [],
    product_variant_id: 1,
    user_id: 192687,
    rack: "RACK 16",
    size_id: null,
    asking_price: "1000000",
    purchase_price: "1000000",
    quantity: "1",
    box_condition: "SEMPURNA",
    sneakers_condition: "BARU",
    pre_verified: true,
    pre_order: false,
    defects: {
        missing_accessories: false,
        display_item: false,
        yellowing: false,
        no_tags: false,
        sneakers_defect: false,
        manufacture_defect: false,
        missing_original: false,
        discoloration: false,
        scratches: false,
        transferred_color: false,
        sign_of_wear: false,
    },
    missing_accessories_list: {
        no_tags: false,
        no_extra_laces: false,
        no_wrap_paper: false,
    },
    inclusions: {
        box: false,
        dust_bag: false,
        tag: false,
        mirror: false,
        straps: false,
        authentication_card: false,
    },
    note: "",
    expiry: "",
    consignment: false,
    request_type: "SELL_REQUEST",
    stock_status: "KA_STOCK",
    hypequarter_display: false,
};

const RequestFrom = (props) => {
    const queryParam = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop) => searchParams.get(prop),
    });
    const isConsignment = queryParam.type === "consignment";
    const [isAlertOpened, setIsAlertOpened] = useState({
        error: false,
        success: false,
    });
    const [alertMessage, setAlertMessage] = useState("");

    const { isPosting, post } = usePostSellRequest();

    const form = useFormik({
        // initialValues: TES_VALUE,
        initialValues: {
            product: null, // No need to submit this key to API
            seller: null, // No need to submit this key to API
            rackTemp: null, // No need to submit this key to API
            size: null, // No need to submit this key to API
            listingCondition: null, // No need to submit this key to API
            boxCondition: null, // No need to submit this key to API
            stockStatus: null, // No need to submit this key to API
            images: [], // No need to submit this key to API

            product_variant_id: "",
            user_id: null, // This value is from seller id
            rack: "",
            size_id: null,
            asking_price: 0,
            purchase_price: 0,
            quantity: 0,
            box_condition: "",
            sneakers_condition: "",
            pre_verified: isConsignment,
            pre_order: false,
            defects: {
                missing_accessories: false,
                display_item: false,
                yellowing: false,
                no_tags: false,
                sneakers_defect: false,
                manufacture_defect: false,
                missing_original: false,
                discoloration: false,
                scratches: false,
                transferred_color: false,
                sign_of_wear: false,
            },
            missing_accessories_list: {
                no_tags: false,
                no_extra_laces: false,
                no_wrap_paper: false,
            },
            inclusions: {
                box: false,
                dust_bag: false,
                tag: false,
                mirror: false,
                straps: false,
                authentication_card: false,
            },
            note: "",
            expiry: "",
            consignment: isConsignment,
            request_type: isConsignment
                ? "CONSIGNMENT_REQUEST"
                : "SELL_REQUEST",
            stock_status: "",
            hypequarter_display: false,
        },
        onSubmit: async (value) => {
            const {
                product,
                seller,
                rackTemp,
                size,
                images,
                listingCondition,
                boxCondition,
                stockStatus,
                ...payload
            } = value;

            payload["size_id"] = size.id;

            const response = await post(payload, images);
            setAlertMessage(response.message);
            window.scrollTo(0, 0);

            if (!response.isSuccess) {
                setIsAlertOpened({
                    error: true,
                    success: false,
                });
                return;
            }

            setIsAlertOpened({
                error: false,
                success: true,
            });

            form.resetForm();
        },
    });

    const formValue = form.values;
    const errorMessage = alertMessage;
    const successMessage = alertMessage;

    const isFormInvalid = useMemo(() => {
        const consignmentValue = isConsignment && !formValue.stock_status;
        const askingPrice = parseInt(formValue.asking_price);
        const quantity = parseInt(formValue.quantity);
        const isBelowMinAskingPrice = askingPrice < 20000;
        const isValidQuantity = isConsignment ? quantity <= 1 : quantity >= 0;

        return (
            !formValue.product ||
            !formValue.seller ||
            !formValue.size ||
            !formValue.box_condition ||
            !formValue.sneakers_condition ||
            !isValidQuantity ||
            isBelowMinAskingPrice ||
            consignmentValue
        );
    }, [formValue]);

    const isDisabledSubmit = useMemo(() => {
        return isFormInvalid || isPosting;
    }, [isFormInvalid, isPosting]);

    const resetDefectDetail = (type) => {
        if (!type) {
            return;
        }

        if (type === "accessories") {
            form.setFieldValue(`missing_accessories_list.no_tags`, false);
            form.setFieldValue(
                `missing_accessories_list.no_extra_laces`,
                false
            );
            form.setFieldValue(`missing_accessories_list.no_wrap_paper`, false);
            return;
        }

        if (type === "inclusions") {
            form.setFieldValue(`${type}.box`, false);
            form.setFieldValue(`${type}.dust_bag`, false);
            form.setFieldValue(`${type}.tag`, false);
            form.setFieldValue(`${type}.mirror`, false);
            form.setFieldValue(`${type}.straps`, false);
            form.setFieldValue(`${type}.authentication_card`, false);
        }
    };

    const onChangeInputValue = (event) => {
        const {
            target: { name, value, type, checked },
        } = event;

        if (isConsignment) {
            const isQuantity = name === "quantity";
            if (isQuantity) {
                const isMoreThanOne = parseInt(value) > 1;
                if (isMoreThanOne) {
                    form.setFieldValue(name, 1);
                    alert("You cannot input quantity more than 1");
                    return;
                }
            }
        }

        if (type === "checkbox") {
            form.setFieldValue(name, checked);

            if (name === "pre_verified") {
                if (!checked && !formValue.consignment) {
                    form.setFieldValue("hypequarter_display", false);
                }
            }

            if (name === "consignment") {
                if (!checked && !formValue.pre_verified) {
                    form.setFieldValue("hypequarter_display", false);
                }
            }

            return;
        }

        form.setFieldValue(name, value);
    };

    const onChangeSelectValue = (option, field) => {
        if (field === "seller") {
            form.setFieldValue("seller", option);
            form.setFieldValue("user_id", option?.id ?? null);
            return;
        }

        if (field === "product") {
            form.setFieldValue("product", option);
            form.setFieldValue("product_variant_id", option?.id ?? null);
            form.setFieldValue("size", null);
            form.setFieldValue("size_id", null);
            return;
        }

        if (field === "rack") {
            form.setFieldValue("rackTemp", option);
            form.setFieldValue("rack", option?.name ?? "");
            return;
        }

        if (field === "size") {
            form.setFieldValue("size", option);
            form.setFieldValue("size_id", option?.name ?? null);
            return;
        }

        if (field === "sneakers_condition") {
            form.setFieldValue("listingCondition", option);
            form.setFieldValue("sneakers_condition", option?.value ?? "");
            return;
        }

        if (field === "box_condition") {
            form.setFieldValue("boxCondition", option);
            form.setFieldValue("box_condition", option?.value ?? "");
            return;
        }

        if (field === "stock_status") {
            form.setFieldValue("stockStatus", option);
            form.setFieldValue("stock_status", option?.value ?? "");
        }
    };

    const onChangeExpiry = (value) => {
        const obj = { expiry: formValue.expiry };
        const { expiry } = dateChange(value, "expiry", obj);
        form.setFieldValue("expiry", expiry);
    };

    const onClickGenerateExpiry = () => {
        form.setFieldValue(
            "expiry",
            moment()
                .add(USER_SELL_EXPIRY_DAYS, "days")
                .endOf("day")
                .format(DATE_FORMAT_DB)
        );
    };

    return (
        <div className={Style["request-form"]}>
            <Row className="animated fadeIn">
                <Col xs={12} md={6}>
                    <Card>
                        <CardHeader>
                            <FontAwesomeIcon iconType="align-justify" />
                            CREATE {isConsignment ? "CONSIGNMENT" : "SELL"}{" "}
                            REQUEST
                        </CardHeader>
                        <CardBody>
                            <Alert
                                color="danger"
                                isOpen={isAlertOpened.error}
                                toggle={() => {
                                    setIsAlertOpened({
                                        ...isAlertOpened,
                                        error: !isAlertOpened.error,
                                    });
                                }}
                            >
                                <strong>Oppps... </strong>
                                {errorMessage}
                            </Alert>
                            <Alert
                                color="success"
                                isOpen={isAlertOpened.success}
                                toggle={() => {
                                    setIsAlertOpened({
                                        ...isAlertOpened,
                                        success: !isAlertOpened.success,
                                    });
                                }}
                            >
                                <strong>Well done! </strong>
                                {successMessage}
                            </Alert>
                            <div style={{ hegiht: 16 }} />
                            <div>
                                <Form
                                    onSubmit={(e) => {
                                        e.preventDefault();
                                        form.submitForm();
                                    }}
                                >
                                    <Row>
                                        <Col xs={12}>
                                            <Label htmlFor="product">
                                                Product
                                            </Label>
                                            <SelectVariantAsync
                                                id="product"
                                                name="product"
                                                noResultsText="Cannot find product."
                                                placeholder="Type and select a product..."
                                                cacheOptions={false}
                                                value={formValue.product}
                                                onSelectChange={(value) =>
                                                    onChangeSelectValue(
                                                        value,
                                                        "product"
                                                    )
                                                }
                                                onSelectOpen={() => {}}
                                            />
                                        </Col>
                                        <Col xs={12} style={{ marginTop: 16 }}>
                                            <Label htmlFor="seller">
                                                Email
                                            </Label>
                                            <UserSelectAsync
                                                id="seller"
                                                name="seller"
                                                onChange={(value) =>
                                                    onChangeSelectValue(
                                                        value,
                                                        "seller"
                                                    )
                                                }
                                                value={formValue.seller}
                                                roles="seller,unsuspended_seller"
                                                placeholder="Type and select a user..."
                                            />
                                        </Col>
                                        <Col xs={6} style={{ marginTop: 16 }}>
                                            <Label htmlFor="size">
                                                Choose Size
                                            </Label>
                                            <SelectSizeAsync
                                                id="size"
                                                name="size"
                                                onChange={(value) =>
                                                    onChangeSelectValue(
                                                        value,
                                                        "size"
                                                    )
                                                }
                                                value={formValue.size}
                                                productId={
                                                    formValue.product_variant_id
                                                }
                                            />
                                        </Col>
                                        <Col xs={6} style={{ marginTop: 16 }}>
                                            <Label htmlFor="rack">Rack</Label>
                                            <SelectRackAsync
                                                id="rack"
                                                name="rack"
                                                noResultsText="Cannot find rack."
                                                placeholder="Rack example: RACK01"
                                                cacheOptions={false}
                                                value={formValue.rackTemp}
                                                onSelectChange={(value) =>
                                                    onChangeSelectValue(
                                                        value,
                                                        "rack"
                                                    )
                                                }
                                                onSelectOpen={() => {}}
                                            />
                                        </Col>

                                        <Col xs={6} style={{ marginTop: 16 }}>
                                            <Label htmlFor="asking_price">
                                                Asking Price
                                            </Label>
                                            <Input
                                                id="asking_price"
                                                name="asking_price"
                                                type="number"
                                                placeholder="0"
                                                value={
                                                    formValue.asking_price || ""
                                                }
                                                onChange={onChangeInputValue}
                                                onKeyDown={(event) =>
                                                    [
                                                        "e",
                                                        "E",
                                                        "+",
                                                        "-",
                                                    ].includes(event.key) &&
                                                    event.preventDefault()
                                                }
                                                onWheel={(event) =>
                                                    event.target.blur()
                                                }
                                            />
                                        </Col>
                                        <Col xs={6} style={{ marginTop: 16 }}>
                                            <Label htmlFor="purchase_price">
                                                Purchase Price
                                            </Label>
                                            <Input
                                                id="purchase_price"
                                                name="purchase_price"
                                                type="number"
                                                placeholder="0"
                                                value={
                                                    formValue.purchase_price ||
                                                    ""
                                                }
                                                onChange={onChangeInputValue}
                                                onKeyDown={(event) =>
                                                    [
                                                        "e",
                                                        "E",
                                                        "+",
                                                        "-",
                                                    ].includes(event.key) &&
                                                    event.preventDefault()
                                                }
                                                onWheel={(event) =>
                                                    event.target.blur()
                                                }
                                            />
                                        </Col>
                                        <Col xs={6} style={{ marginTop: 16 }}>
                                            <Label htmlFor="quantity">
                                                Quantity
                                            </Label>
                                            <Input
                                                id="quantity"
                                                name="quantity"
                                                type="number"
                                                placeholder="0"
                                                value={formValue.quantity || ""}
                                                onChange={onChangeInputValue}
                                                onKeyDown={(event) =>
                                                    [
                                                        "e",
                                                        "E",
                                                        "+",
                                                        "-",
                                                    ].includes(event.key) &&
                                                    event.preventDefault()
                                                }
                                                onWheel={(event) =>
                                                    event.target.blur()
                                                }
                                            />
                                        </Col>
                                        <Col xs={6} style={{ marginTop: 16 }}>
                                            <Label for="expiry">
                                                Expiry Date
                                                <FontAwesomeIcon
                                                    style={{
                                                        margin: "0 .75rem 0 .5rem",
                                                        cursor: "pointer",
                                                    }}
                                                    iconType="question-circle"
                                                    id="expirySellRequestToolTip"
                                                />
                                                <UncontrolledTooltip
                                                    placement="right"
                                                    target="expirySellRequestToolTip"
                                                >
                                                    Kosongkan <i>Expiry</i>{" "}
                                                    khusus sell request yang
                                                    diinput melalui CMS atau
                                                    khusus seller seller yang
                                                    telah bekerja sama dengan{" "}
                                                    <strong>KickAvenue</strong>.
                                                </UncontrolledTooltip>
                                            </Label>
                                            <InputDatetime
                                                inputProps={{
                                                    placeholder:
                                                        "Pick a Expiry Date",
                                                    id: "expiry",
                                                    disabled: isConsignment,
                                                }}
                                                value={dateValue(
                                                    "expiry",
                                                    formValue
                                                )}
                                                onChange={onChangeExpiry}
                                                timeFormat="HH:mm"
                                            />
                                            {!isConsignment && (
                                                <div>
                                                    <Button
                                                        onClick={() =>
                                                            form.setFieldValue(
                                                                "expiry",
                                                                null
                                                            )
                                                        }
                                                        type="button"
                                                        style={{
                                                            padding: 0,
                                                            fontSize: ".75rem",
                                                            textDecoration:
                                                                "underline",
                                                        }}
                                                        className="btn-link"
                                                        size="sm"
                                                    >
                                                        Kosongkan
                                                    </Button>
                                                    <Button
                                                        onClick={
                                                            onClickGenerateExpiry
                                                        }
                                                        type="button"
                                                        style={{
                                                            padding: 0,
                                                            fontSize: ".75rem",
                                                            textDecoration:
                                                                "underline",
                                                            marginLeft: ".5rem",
                                                        }}
                                                        className="btn-link"
                                                        size="sm"
                                                    >
                                                        Generate Expiry
                                                    </Button>
                                                </div>
                                            )}
                                        </Col>
                                        <Col xs={6} style={{ marginTop: 16 }}>
                                            <Label htmlFor="sneakers_condition">
                                                Listing Condition
                                            </Label>
                                            <Select
                                                id="sneakers_condition"
                                                name="sneakers_condition"
                                                placeholder={
                                                    "Select listing condition..."
                                                }
                                                value={
                                                    formValue.listingCondition
                                                }
                                                options={
                                                    LISTING_CONDITION_OPTIONS
                                                }
                                                onChange={(val) => {
                                                    onChangeSelectValue(
                                                        val,
                                                        "sneakers_condition"
                                                    );
                                                }}
                                            />
                                        </Col>
                                        <Col xs={6} style={{ marginTop: 16 }}>
                                            <Label htmlFor="box_condition">
                                                Box Condition
                                            </Label>
                                            <Select
                                                id="box_condition"
                                                name="box_condition"
                                                placeholder={
                                                    "Select box condition..."
                                                }
                                                value={formValue.boxCondition}
                                                options={BOX_CONDITION_OPTIONS}
                                                onChange={(val) => {
                                                    onChangeSelectValue(
                                                        val,
                                                        "box_condition"
                                                    );
                                                }}
                                            />
                                        </Col>
                                        {isConsignment && (
                                            <>
                                                <Col
                                                    xs={6}
                                                    style={{ marginTop: 16 }}
                                                >
                                                    <Label htmlFor="stock_status">
                                                        Stock Label
                                                    </Label>
                                                    <Select
                                                        id="stock_status"
                                                        name="stock_status"
                                                        placeholder={
                                                            "Select stock label"
                                                        }
                                                        value={
                                                            formValue.stockStatus
                                                        }
                                                        options={
                                                            STOCK_STATUS_OPTIONS
                                                        }
                                                        onChange={(val) => {
                                                            onChangeSelectValue(
                                                                val,
                                                                "stock_status"
                                                            );
                                                        }}
                                                    />
                                                </Col>
                                                <Col
                                                    xs={6}
                                                    style={{ marginTop: 16 }}
                                                />
                                            </>
                                        )}

                                        <Col xs={6} style={{ marginTop: 32 }}>
                                            <Radio
                                                name="pre_verified"
                                                id="pre_verified"
                                                value={formValue.pre_verified}
                                                checked={formValue.pre_verified}
                                                onChange={onChangeInputValue}
                                                disabled={isConsignment}
                                            />
                                            <Label for="pre_verified">
                                                &nbsp; Pre-verified
                                            </Label>
                                        </Col>
                                        <Col xs={6} style={{ marginTop: 32 }}>
                                            <Radio
                                                name="pre_order"
                                                id="pre_order"
                                                value={formValue.pre_order}
                                                checked={formValue.pre_order}
                                                onChange={onChangeInputValue}
                                                disabled={isConsignment}
                                            />
                                            <Label for="pre_order">
                                                &nbsp; Pre-order
                                            </Label>
                                        </Col>
                                        <Col xs={6} style={{ marginTop: 16 }}>
                                            <Radio
                                                name="consignment"
                                                id="consignment"
                                                value={formValue.consignment}
                                                checked={formValue.consignment}
                                                onChange={onChangeInputValue}
                                                disabled={true}
                                            />
                                            <Label for="consignment">
                                                &nbsp; Consignment
                                            </Label>
                                        </Col>
                                        <Col xs={6} style={{ marginTop: 16 }}>
                                            <Radio
                                                name="hypequarter_display"
                                                id="hypequarter_display"
                                                value={
                                                    formValue.hypequarter_display
                                                }
                                                checked={
                                                    formValue.hypequarter_display
                                                }
                                                onChange={onChangeInputValue}
                                                disabled={
                                                    !formValue.consignment &&
                                                    !formValue.pre_verified
                                                }
                                            />
                                            <Label for="hypequarter_display">
                                                &nbsp; Hypequarter Display
                                            </Label>
                                        </Col>
                                        <Col>
                                            <Defects
                                                formValue={formValue}
                                                onChangeValue={
                                                    onChangeInputValue
                                                }
                                                resetDefectDetail={
                                                    resetDefectDetail
                                                }
                                            />
                                        </Col>
                                        <Col xs={12} style={{ marginTop: 24 }}>
                                            <Label for="note">Note</Label>
                                            <Input
                                                id="note"
                                                name="note"
                                                placeholder="Enter your note..."
                                                type="textarea"
                                                value={formValue.note}
                                                onChange={onChangeInputValue}
                                            />
                                        </Col>
                                        <Col xs={12} style={{ marginTop: 24 }}>
                                            <Button
                                                type="submit"
                                                disabled={isDisabledSubmit}
                                                color={
                                                    isDisabledSubmit
                                                        ? undefined
                                                        : "primary"
                                                }
                                            >
                                                {isPosting
                                                    ? "Submitting..."
                                                    : "Submit"}
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
                <Col xs={12} md={6}>
                    <ImagesUploader
                        images={formValue.images}
                        setImages={(images) => {
                            form.setFieldValue("images", images);
                        }}
                    />
                </Col>
            </Row>
        </div>
    );
};

export default RequestFrom;
