import React from 'react';
import propTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Alert,
} from 'reactstrap';
import classNames from 'classnames';
import { hasError, errorMessage, textChange, textValue } from '../../../utils/form';
import { ButtonLoading } from '../../../components/Button'

class ModalForceCancelled extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      id: null,
      status: null,
      isSetPenalty: false,
      notes: "Take Out Request",
      error: null
    }
    this._handleChange = this._handleChange.bind(this);
    this.handleCheckedChange = this.handleCheckedChange.bind(this);
    this._onFormSubmit = this._onFormSubmit.bind(this);
  }
  _handleChange(e){
    this.setState(textChange(e,{...this.state}));
  }

  handleCheckedChange(e) {
    const newState = { ...this.state };
    newState[e.target.name] = e.target.checked;
    this.setState({ ...newState });
  }

  componentDidUpdate(prevProps) {
    if(!prevProps.isOpen && prevProps.isOpen !== this.props.isOpen){
      const { status, notes, error } = this.state;
      this.setState({
        id: this.props.id,
        status: status,
        notes: notes,
        error: error
      })
    }
    if(prevProps.isSubmit && prevProps.isSubmit !== this.props.isSubmit){
      const { errorData } = this.props;
      if(errorData){
        this.setState({
          error: { ...errorData }
        })
      }
    }
  }

  _onFormSubmit(e){
    e.preventDefault();
    const { id, notes, status } = this.state;
    const payload = { 
      notes, 
      status 
    };
    this.props.onSubmit(id, payload);
  }

  render(){
    const statusAvailables = [{
      'value': 'CANCELLED',
      'label': 'Cancelled'
    },
  ].filter(status => status.value !== this.props.consignmentStatus)
    return(
      <Modal isOpen={this.props.isOpen} toggle={this.props.onCancel}>
        <ModalHeader toggle={this.props.onCancel}>
          Force Cancelled Form.
        </ModalHeader>
        <ModalBody>
          {
            this.state.error && 
            <Alert color="danger" className="text-center" style={{marginTop:'1rem'}}>
              <strong>{this.state.error?.message}</strong>
            </Alert>
          }
          <p>
            When force cancel this order, You cannot <strong>change seller</strong> anymore. 
            And this order will be visible on <Link to="/consignment/failed" title="Go to Failed Consignment">Failed Consignment Page</Link>.
            By confirming this Form, You cannot undo your changes.
          </p>
          <Form
            onSubmit={this._onFormSubmit}
          >
            <FormGroup className={classNames({'has-danger has-feedback':hasError('status',this.state.error)})}>
              <Label for="formForceCancelledStatus">Status*</Label>
              <Input
                type="select"
                id="formForceCancelledStatus"
                name="status"
                value={textValue('status',this.state)}
                onChange={this._handleChange}
              >
                <option>Select status...</option>
                {
                  statusAvailables.map(({ value, label }, index) => <option key={index} value={value}>{label}</option>)
                }
              </Input>
              {errorMessage('status',this.state.error)}
            </FormGroup>
            <FormGroup className={classNames({'has-danger has-feedback':hasError('notes',this.state.error)})}>
              <Label for="formForceCancelledNotes">Notes*</Label>
              <Input
                type="textarea"
                id="formForceCancelledNotes"
                name="notes"
                placeholder="Put your notes here*"
                value={textValue('notes',this.state)}
                onChange={this._handleChange}
              />
              {errorMessage('notes',this.state.error)}
            </FormGroup>
          </Form>
          <span className="text-sm">Fields marked with * are required</span>
        </ModalBody>
        <ModalFooter>
          <ButtonLoading
            color="primary"
            isLoading={this.props.isSubmit}
            onClick={this._onFormSubmit}
          >
            Confirm
          </ButtonLoading>
          <Button color="secondary" onClick={this.props.onCancel}>Cancel</Button>
        </ModalFooter>
      </Modal>
    )
  }
}

export default ModalForceCancelled;

ModalForceCancelled.defaultProps = {
  isOpen: false,
  isSubmit: false,
  errorData: null,
  consignmentStatus: 'NEW'
}

ModalForceCancelled.propTypes = {
  isOpen: propTypes.bool,
  isSubmit: propTypes.bool,
  id: propTypes.oneOfType([propTypes.number, propTypes.string]),
  errorData: propTypes.object,
  onCancel: propTypes.func,
  onSubmit: propTypes.func,
  consignmentStatus: propTypes.string
}