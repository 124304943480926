import React from 'react';
import propTypes from 'prop-types';
import Select from 'react-select';
// import 'react-select/dist/react-select.css';

import api from '../../../../utils/Api';
import { AUTH_TOKEN_KEY } from '../../../../constants';
import moment from 'moment-timezone';
import { getCategoryName, capitalizeFirstLetter } from '../../../../helpers/formatter';

class SelectProductAsync extends React.Component{
  state = {
    selectValue: null
  }

  constructor(props){
    super(props);
    this.loadSelectOptions = this.loadSelectOptions.bind(this);
    this._getProductOptions = this._getProductOptions.bind(this);
  }

  cache = {}

  loadSelectOptions(input){
    return this.selectRef.loadOptions(input);
  }

  purgeCache = () => {
    Object.keys(this.cache).forEach(entry => {
      delete this.cache[entry]
    })
  }

  constructOptionsData = (item) => {
    const categoryName = getCategoryName(item.category.name);
    const formattedCategory = capitalizeFirstLetter(categoryName);
    const brandName = item?.brand?.name || "**BRAND IS STILL UNFILLED**";
    const label = `${item.name} (${formattedCategory} - ${brandName})`;
    
    return {
      id: item.id,
      name: item.name,
      label,
      category: item.category && {
        id: item.category.id,
        name: item.category.name
      },
      brand: item.brand && {
        id: item.brand.id,
        name: item.brand.name
      }
    }
  }

  _getProductOptions(input){
    if(!input || input === '') return Promise.resolve([]);
    const myToken = localStorage.getItem(AUTH_TOKEN_KEY);
    const headers = {
      'Authorization': `Bearer ${myToken}`
    };
    const params = {
      keyword: input,
      ...this.props.paramsApi
    }
    return new Promise(r =>{
      api(headers).get('/admins/products', { params })
      .then(({ data }) => {
        const products = data.data.data.map(item=>{
          return this.constructOptionsData(item);
        })
        if(this.props.onSelectSearch){
          this.props.onSelectSearch(input)
        }
        r({options: products});
      });
    })
  }

  updateSelectValueState = () => {
    const selectHasValue = !!this.props.value;
    
    if(!selectHasValue){
      this.setState({
        selectValue: null
      });
      return;
    }

    if(selectHasValue) {
      const selectValue = this.constructOptionsData(this.props.value);
      this.setState({
        selectValue
      })
    }
  }

  componentDidMount(){
    this.updateSelectValueState()
  }
  
  componentDidUpdate(prevProps){
    const selectStringValue = {
      prev: JSON.stringify(prevProps.value),
      now: JSON.stringify(this.props.value)
    }

    if(selectStringValue.prev !== selectStringValue.now){
      this.updateSelectValueState();
    }
  }

  render(){
    return(
      <Select.Async
        valueKey="id"
        labelKey="label"
        ref={input => this.selectRef = input}
        loadOptions={this._getProductOptions}
        // cacheOptions={this.state.cache}
        cache={this.cache}
        id={this.props.id}
        name={this.props.name}
        value={this.state.selectValue}
        defaultInputValue={this.props.value?.id}
        placeholder={this.props.placeholder}
        noResultsText={this.props.noResultsText}
        onChange={this.props.onSelectChange}
        onOpen={this.props.onSelectOpen}
      />
    )
  }
}

export default SelectProductAsync;

SelectProductAsync.defaultProps = {
  cacheOptions: false,
  value: null,
  placeholder: 'Select a product...',
  noResultsText: 'No Result!',
  paramsApi: {}
}

SelectProductAsync.propTypes = {
  id: propTypes.string.isRequired,
  name: propTypes.string,
  value: propTypes.object,
  placeholder: propTypes.string,
  noResultsText: propTypes.string,
  cacheOptions: propTypes.bool,
  paramsApi: propTypes.object,
  onSelectSearch: propTypes.func,
  onSelectChange: propTypes.func,
  onSelectOpen: propTypes.func
}