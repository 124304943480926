import {
    AUTH_TOKEN_KEY,
    PUSH_NOTIFICATION_ROUTE_API,
    PUSH_NOTIFICATION_ADD,
    PUSH_NOTIFICATION_DELETE,
    PUSH_NOTIFICATION_DETAIL_FETCH,
    PUSH_NOTIFICATION_ERROR,
    PUSH_NOTIFICATION_FETCHING,
    PUSH_NOTIFICATION_LIST_FETCH,
    PUSH_NOTIFICATION_UPDATE,
    ADD_IMAGE_PUSH_NOTIFICATION,
    DELETE_IMAGE_PUSH_NOTIFICATION,
} from "../constants";
import api from "../utils/Api";
import { manipulateErrors, checkAuthorized } from "../utils";
import { logout } from "./authAction";

export const isFetch = (payload) => ({
    type: PUSH_NOTIFICATION_FETCHING,
    payload: payload,
});

export const onSuccess = (res, type) => ({
    type: type,
    payload: res,
});

export const onError = (err) => {
    if (!checkAuthorized(manipulateErrors(err))) return logout();
    return {
        type: PUSH_NOTIFICATION_ERROR,
        payload: manipulateErrors(err),
    };
};

export const getPushNotificationList = (query) => {
    return (dispatch) => (
        dispatch(isFetch({ type: "fetch", status: true })),
        api({
            Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`,
        })
            .get(PUSH_NOTIFICATION_ROUTE_API, query)
            .then((res) => {
                dispatch(onSuccess(res.data, PUSH_NOTIFICATION_LIST_FETCH));
                dispatch(isFetch({ type: "fetch", status: false }));
            })
            .catch((err) => {
                dispatch(onError(err));
                dispatch(isFetch({ type: "fetch", status: false }));
            })
    );
};

export const getDetailPushNotification = (id) => {
    return (dispatch) => (
        dispatch(isFetch({ type: "fetch", status: true })),
        api({
            Authorization: "Bearer " + localStorage.getItem(AUTH_TOKEN_KEY),
        })
            .get(`${PUSH_NOTIFICATION_ROUTE_API}/${id}`)
            .then((res) => {
                dispatch(onSuccess(res.data, PUSH_NOTIFICATION_DETAIL_FETCH));
                dispatch(isFetch({ type: "fetch", status: false }));
            })
            .catch((err) => {
                dispatch(onError(err));
                dispatch(isFetch({ type: "fetch", status: false }));
            })
    );
};

export const savePushNotification = (payload, id) => {
    return (dispatch) => {
        if (id === undefined) dispatch(createPushNotification(payload));
        else dispatch(updatePushNotification(id, payload));
    };
};

export const createPushNotification = (payload) => {
    return (dispatch) => (
        dispatch(isFetch({ type: "submit", status: true })),
        api({
            Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`,
        })
            .post(`${PUSH_NOTIFICATION_ROUTE_API}`, payload)
            .then((res) => {
                dispatch(onSuccess(res.data, PUSH_NOTIFICATION_ADD));
                dispatch(isFetch({ type: "submit", status: false }));
            })
            .catch((err) => {
                dispatch(onError(err));
                dispatch(isFetch({ type: "submit", status: false }));
            })
    );
};

export const updatePushNotification = (id, payload) => {
    return (dispatch) => (
        dispatch(isFetch({ type: "submit", status: true})),
        api({
            Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`,
        })
            .put(`${PUSH_NOTIFICATION_ROUTE_API}/${id}`, payload)
            .then((res) => {
                dispatch(onSuccess(res.data, PUSH_NOTIFICATION_UPDATE));
                dispatch(
                    isFetch({ type: "submit", status: false })
                );
            })
            .catch((err) => {
                dispatch(onError(err));
                dispatch(
                    isFetch({ type: "submit", status: false })
                );
            })
    );
};

export const deletePushNotification = (id) => {
    return (dispatch) => (
        dispatch(isFetch({ type: "submit", status: true })),
        api({
            Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`,
        })
            .delete(`${PUSH_NOTIFICATION_ROUTE_API}/${id}`)
            .then((res) => {
                dispatch(onSuccess(res.data, PUSH_NOTIFICATION_DELETE));
                dispatch(isFetch({ type: "submit", status: false }));
            })
            .catch((err) => {
                dispatch(onError(err));
                dispatch(isFetch({ type: "submit", status: false }));
            })
    );
};

export const addImage = (identifier, id, payload) => {
    return (dispatch) => (
        dispatch(isFetch({ type: "submit_image", status: true })),
        api({
            Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`,
        })
            .put(`${PUSH_NOTIFICATION_ROUTE_API}/${id}`, payload)
            .then((res) => {
                dispatch(
                    onSuccess(
                        { ...res.data, identifier },
                        ADD_IMAGE_PUSH_NOTIFICATION
                    )
                );
                dispatch(isFetch({ type: "submit_image", status: false }));
            })
            .catch((err) => {
                dispatch(onError(err));
                dispatch(isFetch({ type: "submit_image", status: false }));
            })
    );
};

export const deleteImage = (identifier, id, payload) => {
    return (dispatch) => (
        dispatch(isFetch({ type: "delete_image", status: true })),
        api({
            Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`,
        })
            .put(`${PUSH_NOTIFICATION_ROUTE_API}/${id}`, {
                ...payload,
                URL: null,
            })
            .then((res) => {
                dispatch(
                    onSuccess({ identifier }, DELETE_IMAGE_PUSH_NOTIFICATION)
                );
                dispatch(isFetch({ type: "delete_image", status: false }));
            })
            .catch((err) => {
                dispatch(onError(err));
                dispatch(isFetch({ type: "delete_image", status: false }));
            })
    );
};
