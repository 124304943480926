import React, { PureComponent as Component } from 'react';
import { Link } from 'react-router-dom';
import propTypes from 'prop-types';
import {
  Card,
  CardHeader,
  CardBody,
  Table
} from 'reactstrap'
import Loading from 'react-loading-animation';
import { getPaymentStatus, getPaymentType, getPaymentVia } from '../../../constants/kickavenue';
import { TextRupiah } from '../../Text';
import { _getValue, _getFloatValue } from '../../../utils/form';
import {
  FieldRow,
  FieldColumn,
  FieldLabel,
  FieldValue
} from '../../../styled/Fields';
import { FontAwesomeIcon } from '../../../components/Icons';

import classes from './PaymentSummaries.module.css';

export default class PaymentSummaries extends Component {
  render(){
    const estimatedProfit = !this.props.isLoading &&
    _getFloatValue(this.props.order.price)-_getFloatValue(this.props.order.seller_price)
    const commissionFee = parseFloat(estimatedProfit / this.props.order.price * 100).toFixed(1)

    const cashOutFee = !this.props.isLoading && parseInt(this.props.order.seller_price * 3 / 100)
    const content = !this.props.isLoading && (
      <div>
        <FieldRow>
          <FieldColumn className="col-xs-12 col-md-6">
            <FieldLabel>ID <Link to={`/payments/${this.props.payment.id}`} title="To payment details.">See more details...</Link></FieldLabel>
            <FieldValue>
              <span>{_getValue(this.props.payment.id)}</span>
            </FieldValue>
          </FieldColumn>
          <FieldColumn className="col-12">
            <FieldLabel>Invoice Link</FieldLabel>
            <FieldValue>
              <span className="break-all">{_getValue(this.props.payment.invoice_url)}</span>
            </FieldValue>
          </FieldColumn>
          <FieldColumn className="col-xs-12 col-md-6">
            <FieldLabel>
              Payment Method
              {
                this.props.payment.payment_via !== null && this.props.payment.payment_via === 'vt' && this.props.payment.payment_method === "credit_card" ? 
                <button type="button" className="ml-3 btn btn-sm btn-secondary" onClick={this.props.onOpenCreditCard} target="_blank" title="Show More Info"><i className="fa fa-info-circle"></i> More Info</button>
                :
                null
              }
            </FieldLabel>
            <FieldValue>
              <span>{getPaymentType(this.props.payment.payment_method)}</span>
            </FieldValue>
          </FieldColumn>
          <FieldColumn style={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-end'}} className="col-xs-12 col-md-6">
            <FieldLabel>Payment Via</FieldLabel>
            <FieldValue>
              <span>{_getValue(getPaymentVia(this.props.payment.payment_via), '-')}</span>
            </FieldValue>
          </FieldColumn>
          <FieldColumn className="col-xs-12 col-md-6">
            <FieldLabel>Payment Type</FieldLabel>
            <FieldValue>
              <span>{_getValue(this.props.payment.payment_type, '-')}</span>
            </FieldValue>
          </FieldColumn>
          <FieldColumn className="col-xs-12 col-md-6">
            <FieldLabel>Points</FieldLabel>
            <FieldValue>
              <span>{ _getValue(this.props.payment.points, '-') }</span>
            </FieldValue>
          </FieldColumn>
          {
            this.props.payment.payment_method=== 'kredivo'?
            <FieldColumn className="col-xs-12 col-md-6">
              <FieldLabel>Installment Type</FieldLabel>
              <FieldValue>
                <span>{this.props.payment.installments_type}</span>
              </FieldValue>
            </FieldColumn>
            : null
          }
          <FieldColumn className="col-xs-12 col-md-6">
        <FieldLabel>{this.props.payment.payment_method === 'credit_card' || this.props.payment.payment_method === 'installments'? 'Acquiring Bank': 'Bank Code'}</FieldLabel>
            <FieldValue>
              <span style={{ textTransform: 'uppercase' }}>
              {
                this.props.payment.bank_code ? 
                this.props.payment.bank_code:'-'
              }
              </span>
            </FieldValue>
          </FieldColumn>
          <FieldColumn className="col-xs-12 col-md-6">
            <FieldLabel>Status</FieldLabel>
            <FieldValue>
              <span>{getPaymentStatus(this.props.payment.status)}</span>
            </FieldValue>
          </FieldColumn>
          <FieldColumn className="col-xs-12 col-md-6">
            <FieldLabel>Expiry Date</FieldLabel>
            <FieldValue>
              <span>{_getValue(this.props.payment.expiry)}</span>
            </FieldValue>
          </FieldColumn>
          <FieldColumn className="col-xs-12 col-md-6">
            <FieldLabel>Paid Date</FieldLabel>
            <FieldValue>
              <span>{_getValue(this.props.payment.customer_paid)}</span>
            </FieldValue>
          </FieldColumn>
        </FieldRow>
        <Table style={styles.table} className={classes['purchase-summary-table']}>
          <thead>
            <tr>
              <th colSpan={2} className="text-center">
                Purchase Summary
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{this.props.product.display_name} ({this.props.payment.quantity}x)</td>
              <td className="v-center" style={{width: '50%'}}><TextRupiah prefix="Rp. " value={_getFloatValue(this.props.order.price)} align="right" block/></td>
            </tr>
            <tr>
              <td>{this.props.payment.ka_courier} {this.props.payment.ka_courier_option} (1x)</td>
              <td className="v-center"><TextRupiah prefix="Rp. " value={_getFloatValue(this.props.payment.ka_courier_price)} align="right" block/></td>
            </tr>
            <tr>
              <td>Processing Fee</td>
              <td className="v-center"><TextRupiah prefix="Rp. " value={(_getFloatValue(this.props.payment?.processing_fee_calculated))} align="right" block/></td>
            </tr>
            <tr>
              <td>Biaya Tambahan</td>
              <td className="v-center"><TextRupiah prefix="Rp. " value={(_getFloatValue(this.props.order.unique_amount)+_getFloatValue(this.props.order.administration_fee))} align="right" block/></td>
            </tr>
            <tr>
              <td>Voucher ({this.props.order.voucherable_id && this.props.order.voucherable? this.props.order.voucherable.code: this.props.order.voucherable_id? `REF: ${this.props.order.voucherable_id}` : this.props.order.voucher_code? `REF: ${this.props.order.voucher_code}` : '-'})</td>
              <td className="v-center"><TextRupiah prefix="Rp." value={_getFloatValue(this.props.order.discount)+_getFloatValue(this.props.order.admin_fee_discount)+_getFloatValue(this.props.shipping.ka_courier_discount)} align="right" block/></td>
            </tr>
            <tr>
              <td>Subsidy Price</td>
              <td className="v-center"><TextRupiah prefix="-Rp." value={_getFloatValue(this.props.order.subsidy_price)} align="right" block/></td>
            </tr>
            <tr>
              <td><b>Sub Total</b></td>
              <td className="v-center">
                <TextRupiah prefix="Rp." value={_getFloatValue(this.props.order.total_price)} align="right" block bold/>
              </td>
            </tr>
            {Number(this.props.payment.wallet_amount)? (
              <tr>
                <td>Kick Credit</td>
                <td className="v-center">
                  <TextRupiah prefix="Rp. " value={_getFloatValue(this.props.payment.wallet_amount)} align="right" block/>
                </td>
              </tr>
              ):null}
            {Number(this.props.payment.lbalance_amount)? (
              <tr>
                <td>Kick Point</td>
                <td className="v-center">
                  <TextRupiah prefix="Rp. " value={_getFloatValue(this.props.payment.lbalance_amount)} align="right" block/>
                </td>
              </tr>
            ):null}
            {Number(this.props.payment.scredit_amount)?(
            <tr>
              <td className="v-center">Seller Credit</td>
              <td>
                <TextRupiah prefix="Rp. " value={_getFloatValue(this.props.payment.scredit_amount)} align="right" block/>
              </td>
            </tr>
            ):null}
            {Number(this.props.payment.wallet_usage)? (
              <tr>
                <td className="v-center"><b>Sub Total Credit Usage</b></td>
                <td>
                  <TextRupiah prefix="-Rp. " value={_getFloatValue(this.props.payment.wallet_usage)} align="right" block bold/>
                </td>
              </tr>
            ):null}
            <tr>
              <td><b>Total Payment</b></td>
              <td className="v-center">
                <TextRupiah prefix="Rp. " bold value={_getFloatValue(this.props.payment.total_adjusted_amount)} align="right" block/>
              </td>
            </tr>
            {
              this.props.payment.points?(
                <tr>
                  <td>
                    <FontAwesomeIcon className="mr-1" iconType="product-hunt"/>
                    Kick Point*<br/>
                    <span style={{fontStyle: 'italic', fontSize: '.65rem'}}>*not included from <b>Total Payment</b>.</span>
                  </td>
                  <td>
                    <p style={{margin: 0}} className="text-right">{Number(this.props.payment.points)}</p>
                  </td>
                </tr>
              ): null
            }
          </tbody>
        </Table>
        <Table style={styles.table} className={classes['estimated-profit-table']}>
          <thead>
            <tr>
              <th colSpan={2} className="text-center">
                Estimated Profit
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td><p>Total Purchase</p></td>
              <td className="v-center"><TextRupiah prefix="Rp. " value={_getFloatValue(this.props.order.total_price)} align="right" block /></td>
            </tr>
            <tr>
              <td>
                <p>Seller Price</p>
                <p style={styles.description}>(Product Price - {commissionFee}% Commission Fee x Product Price)</p>
              </td>
              <td className="v-center"><TextRupiah prefix="Rp. " value={_getFloatValue(this.props.order.seller_price)} align="right" block /></td>
            </tr>
            {this.props.order?.shipper && (
              <>
                <tr>
                  <td>
                    <p>Shipper Courier Fee</p>
                  </td>
                  <td className="v-center"><TextRupiah prefix="Rp. " value={_getFloatValue(this.props.order.shipper.courier_price)} align="right" block /></td>
                </tr>
                <tr>
                  <td>
                    <p>Insurance Fee</p>
                  </td>
                  <td className="v-center"><TextRupiah prefix="Rp. " value={_getFloatValue(this.props.order.shipper.insurance_fee)} align="right" block /></td>
                </tr>
              </>
            )}
            <tr>
              <td>
                <p>Seller Courier Price</p>
                {
                  this.props.order.seller_courier && this.props.order.seller_courier_option?
                  <p style={styles.description}>{this.props.order.seller_courier} - {this.props.order.seller_courier_option}</p>
                  :null
                }
                {
                  this.props.order.seller_courier && this.props.order?.shipper?
                  <p style={styles.description}>Shipper Courier Fee + Insurance Fee</p>
                  :null
                }
              </td>
              <td className="v-center"><TextRupiah prefix="Rp. " value={_getFloatValue(this.props.order.seller_courier_price)} align="right" block /></td>
            </tr>
            <tr>
              <td><p>KA Courier Price</p></td>
              <td className="v-center"><TextRupiah prefix="Rp. " value={_getFloatValue(this.props.payment.ka_courier_price)} align="right" block /></td>
            </tr>
            <tr>
              <td>
                <p className={classes['bold']}>Sub Total</p>
                <p style={styles.description}>(Seller Price + Seller Courier Price)</p>
              </td>
              <td className="v-center"><TextRupiah prefix="Rp. " value={_getFloatValue(this.props.order.total_price_seller)} align="right" block bold/></td>
            </tr>
            <tr>
              <td>
                <p className={classes['bold']}>Total Estimated Profit</p>
                <p className={classes['bold']}>({ commissionFee }% Commission Fee + 3% Cash Out Fee + Processing Fee)</p>
                <p style={styles.description}>(Product Price - Seller Price + Cash Out Fee + Processing Fee)</p>
              </td>
              <td className="v-center"><TextRupiah prefix="Rp. " value={estimatedProfit + cashOutFee + (this.props.payment?.processing_fee_calculated || 0)} align="right" block bold/></td>
            </tr>
            <tr>
              <td>
                <p className={classes['bold']}>Seller Revenue</p>
                <p style={styles.description}>(Seller Price - Seller Courier Price)</p>
              </td>
              <td className="v-center"><TextRupiah prefix="Rp. " value={_getFloatValue(this.props.order.seller_price - this.props.order.seller_courier_price)} align="right" block bold/></td>
            </tr>
            <tr>
              <td>
                <p>Our Purchase Price</p>
                <p style={styles.description}>Hanya informasi.</p>
              </td>
              <td className="v-center">
                <TextRupiah prefix="Rp. " value={_getFloatValue(this.props.order.purchase_price)} align="right" block/>
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
    ) || <Loading />;
    return (
      <Card>
        <CardHeader>
          {this.props.headerText}
        </CardHeader>
        <CardBody>
          {content}
        </CardBody>
      </Card>
    )
  }
}
const styles = {
  table:{
    marginTop:25
  },
  description:{
    fontSize:9,
    marginBottom:0
  },
  boldedField: {
    fontSize: 0.75 + 'rem',
  }
}
PaymentSummaries.propTypes = {
  headerText: propTypes.oneOfType([propTypes.string, propTypes.node]),
  isLoading: propTypes.bool,
  payment: propTypes.object,
  order: propTypes.object,
  product: propTypes.object,
  shipping: propTypes.object
}
PaymentSummaries.defaultProps = {
  isLoading: false,
  headerText: "Payment Summary & Total"
}