import React, { Component } from 'react';
import propTypes from 'prop-types';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Input,
  Label
} from 'reactstrap';
import classnames from 'classnames';
import { TextRupiah } from '../../../../components/Text';
import { hasError, errorMessage, textChange, textValue } from '../../../../utils/form';
import Button from '../../../../components/Button/ButtonLoading';
import Radio from '../../../../components/Form/Radios/Radio';
import RadioText from '../../../../components/Form/Radios/RadioText';
import Analytics from "../../../../services/Analytics";

class ModalDisbursement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      offerId: null,
      error: null,
      email: '',
      remarks: '',
      invoiceNumber: '',
      with_balance_out: false,
      totalAmount: 0,
      _isSubmit: false
    }
    this._handleSubmit = this._handleSubmit.bind(this);
    this._handleChange = this._handleChange.bind(this);
  }

  componentDidUpdate(prevProps) {
    if(!prevProps.isOpen && prevProps.isOpen!==this.props.isOpen){
      const { offer } = this.props;
      // const amount = (parseInt(offer.offer_amount)+parseInt(offer.ka_courier_price))-(parseInt(offer.discount)+parseInt(offer.ka_courier_discount));
      const amount = parseInt(offer.total_adjusted_amount);
      this.setState({
        offerId: offer.id,
        error: null,
        email: offer.shipping.user.email,
        invoiceNumber: offer.invoice_number,
        totalAmount: amount,
        amount,
        with_balance_out: false,
        remarks: this.state.offerId==offer.id?this.state.remarks:''
      })
    }
    if(this.state._isSubmit){
      if(prevProps.isSubmit && prevProps.isSubmit!==this.props.isSubmit){
        const { dataError, dataUpdated } = this.props;
        if(dataError){
          this.setState({
            _isSubmit: !this.state._isSubmit,
            error: dataError
          })
        }
        else if(dataUpdated.status_code===200){
          this.setState({
            _isSubmit: !this.state._isSubmit
          })
        }
      }
    }
  }

  _handleChange(e){
    this.setState(textChange(e,{...this.state}));
  }

  _handleSubmit(e){
    e.preventDefault();
    const { offerId, amount, remarks, with_balance_out, invoiceNumber } = this.state;
    const email = this.props;
    Analytics.recordRefundToBalance(
      invoiceNumber,
      offerId, 
      amount, 
      remarks, 
      with_balance_out,
      email
    )
    this.setState({
      _isSubmit: !this.state._isSubmit
    }, ()=> this.props.handleUpdateOffer(offerId, {
      status: 'REFUNDED',
      amount,
      remarks,
      with_balance_out
    }))
  }

  render() {
    return (
      <Modal
        size="md"
        isOpen={this.props.isOpen}
        toggle={this.props.toggle}
      >
        <ModalHeader
          toggle={this.props.toggle}
        >
          Confirmation
        </ModalHeader>
        <ModalBody>
          <p>
            Invoice: <i>({this.state.invoiceNumber})</i> <br/>
            Email: <i>({this.state.email})</i> <br/>
            Total: <TextRupiah prefix="Rp. " bold value={this.state.totalAmount}/> <br/>
            Moving their amounts payment into their balance?
          </p>
          <Form onSubmit={this._handleSubmit}>
            <FormGroup className={classnames({'has-danger has-feedback':hasError('amount',this.state.error)})}>
              <Label>Amount*</Label>
              <Input
                type="number"
                name="amount"
                value={textValue('amount',this.state)}
                onChange={this._handleChange}
              />
              {errorMessage('amount',this.state.error)}
            </FormGroup>
            <FormGroup className={classnames({'has-danger has-feedback':hasError('remarks',this.state.error)||hasError('status',this.state.error)})}>
              <Label>Remarks</Label>
              <Input
                type="textarea"
                name="remarks"
                placeholder="Put your remarks here"
                value={textValue('remarks',this.state)}
                onChange={this._handleChange}
              />
              {errorMessage('remarks',this.state.error)}
              {errorMessage('status',this.state.error)}
            </FormGroup>
            <FormGroup className={classnames({'has-danger has-feedback':hasError('with_balance_out',this.state.error)})}>
              <Radio value="true" checked={this.state.with_balance_out} onChange={e=>this.setState({ with_balance_out: e.target.checked })}/>
              <RadioText text="Also create a balance out disbursement?"/>
              {errorMessage('with_balance_out',this.state.error)}
            </FormGroup>
          </Form>
          <span className="text-sm">Fields marked with * are required</span>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            isLoading={this.state._isSubmit}
            onClick={this._handleSubmit}
          >
            Confirm
          </Button>{' '}
          <Button
            color="secondary"
            disabled={this.state._isSubmit}
            onClick={this.props.toggle}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default ModalDisbursement;
ModalDisbursement.propTypes = {
  offer: propTypes.object,
  isSubmit: propTypes.bool,
  isOpen: propTypes.bool.isRequired,
  toggle: propTypes.func,
  handleUpdateOffer: propTypes.func.isRequired,
  dataError: propTypes.object, //Props from redux
  dataUpdated: propTypes.object //Props from redux
}
ModalDisbursement.defaultProps = {
  isSubmit: false,
  isOpen: false
}
