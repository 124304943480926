import React, {Component} from 'react';
import ReactDOMServer from 'react-dom/server';
import { connect } from 'react-redux';
import{
  Row,
  Col,
  Card,
  CardBody,
  CardText,
} from 'reactstrap';

import { Tbl, TblHead, TblBody } from '../../../components/Tbl';
import Paginations from '../../../components/Paginations';
import PaginationSummaries from './Paginations/PaginationSummaries';
import { CardHeaderWithToolbars,Title } from '../../../components/Card/CardHeaderWithToolbars';
import Toolbars from './Filters/Toolbars';
import FiltersPanel from '../Filters';
import ExportsPanel from './Exports';
import FilterContainer from '../../../components/Container/FilterContainer/FilterContainer';
import { ModalInfoCreditCard } from '../../../components/Modals';
import { generateNumber } from '../../../utils';
import { DEFAULT_SORT_BY, DEFAULT_PER_PAGE } from '../../../constants/settings';
import  {
  NEW_STATUS,
  SELLER_SENT_STATUS,
	CANCELLED_STATUS,
	KA_RECEIVED_STATUS,
	VERIFICATION_PASSED_STATUS,
	VERIFICATION_FAILED_STATUS,
  DELIVERING_STATUS,
  REFUNDED_STATUS
} from '../../../constants/kickavenue';
import { getSaleList, reportSale } from '../../../actions/saleAction';
import RowItem from './rowItem';
import RowHeader from './rowItem/RowHeader';
import privateView from '../../../components/hocs/privateView';
import qs from 'query-string';
import ModalPurchasePrice from '../ModalPurchasePrice';
import Analytics from "../../../services/Analytics";
import Invoice from './Invoice/Invoice';

const generateDescStatus = item =>{
  if(item.status===NEW_STATUS){
    if(item.seller_responded)
      return 'Waiting Seller Delivering';
    return 'Waiting Seller Confirmation';
  }
  // else if(item.status===SELLER_SENT_STATUS && item.seller_sent!=null){
  else if(item.status===SELLER_SENT_STATUS){
    return 'Sneaker On The Way';
  }
  else if(item.status===KA_RECEIVED_STATUS && item.ka_received!=null){
    return 'Verification Progress';
  }
  else if(item.status===VERIFICATION_PASSED_STATUS && item.ka_verified!=null){
    return 'Verification Passed';
  }
  else if(item.status===VERIFICATION_FAILED_STATUS){
    return 'Verification Failed';
  }
  else if(item.status===DELIVERING_STATUS &&item.ka_sent === null){
    return 'Awaiting AWB Number';
  }
  else if(item.status===DELIVERING_STATUS &&item.ka_sent!=null){
    return 'On Delivering';
  }
  else if(item.status===CANCELLED_STATUS){
    return item.force_cancelled_at!=null? 'Cancelled (Forced)': 'Cancelled';
  }
  else if(item.status===REFUNDED_STATUS && item.money_disbursed!=null){
    return 'Refunded';
  }
  else if( (/^DONE_\w+/).test(item.status)||item.completed)
    return 'Done';
  return 'Undefined';
}

const defaultParams = {
  sort_by : DEFAULT_SORT_BY,
  per_page : DEFAULT_PER_PAGE
}

class Monitoring extends Component{
  constructor(props){
    super(props);
    const params = qs.parse(props.location.search);
    this.state={
      _filterOpen: false,
      _exportOpen: false,
      _printOpen: false,
      _allSelected:false,
      _modalCreditCard: false,
      _invoiceNumber: null,
      _dataModalPurchasePrice: null,
      _isModalPurchasePrice: false,
      _selectedItems:[],
      _params:{
        ...defaultParams,
        keyword: params.keyword || ''
      },
      isDataLoaded: false
    }
    this._handleApplyFilter = this._handleApplyFilter.bind(this);
    this._handleToggleFilterPanel = this._handleToggleFilterPanel.bind(this);
    this._handleToggleExportPanel = this._handleToggleExportPanel.bind(this);
    this._handleTogglePrintPanel = this._handleTogglePrintPanel.bind(this);
    this._handleGoto = this._handleGoto.bind(this);
    this._refreshList = this._refreshList.bind(this);
    this._handleRefresh = this._handleRefresh.bind(this);
    this._handleResetFilter = this._handleResetFilter.bind(this);
    this._handleExport = this._handleExport.bind(this);
    this._handlePrintInvoice = this._handlePrintInvoice.bind(this);
    this._handleCheckboxChange = this._handleCheckboxChange.bind(this);
    this._handleCategoryChanged = this._handleCategoryChanged.bind(this);
    this._handleToggleModalCreditCard = this._handleToggleModalCreditCard.bind(this);
    this._handlePrintSelectedItem = this._handlePrintSelectedItem.bind(this);
  }

  componentDidMount(){
    const { email } = this.props.injectedProps;
    this._getList(this.state._params).then(() => {
      this.setState({
        isDataLoaded: true
      })
    });
    Analytics.recordPageView('monitoring_sales', email, {
      url: window.location.href,
      location: this.props.location,
      match: this.props.match
    });
  }

  _getList(rawParams = {}){
    let p = { ...rawParams }
    if(p.voucher){
      const { voucher, ...other } = p;
      if(Number(voucher.id)){
        p = { ...other, voucher_id: voucher.id }
      }
      else{
        p = { ...other, voucher_code: voucher.code }
      }
    }
    if(p.seller && p.seller.id){
      const { seller, ...other } = p;
      p = { ...other, seller_id: seller.id }
    }
    const params = Object.keys(p)
    .filter(key => p[key]!='')
    .reduce((obj, current)=> ({...obj, [current]: p[current]}), {});
    return this.props.getList({params});
  }

  _toggleFilterPanel(){
    const exportState = this.state._exportOpen?{_exportOpen:!this.state._exportOpen}:{};
    this.setState({
      ...exportState,
      _filterOpen:!this.state._filterOpen});
  }

  _toggleExportPanel(){
    const filterState = this.state._filterOpen?{_filterOpen:!this.state._filterOpen}:{};
    this.setState({
      ...filterState,
      _exportOpen:!this.state._exportOpen});
  }

  _handleRefresh(e){
    e.preventDefault();
    this._refreshList();
  }

  _handleExport(payload){
    this.props.reportSale(payload);
  }

  _handlePrintInvoice(){
    const { _selectedItems } = this.state;
    const newTab = window.open('', '_blank');
    const invoiceContent = ReactDOMServer.renderToString(
      <Invoice selectedItems={_selectedItems} />
    );
    newTab.document.write(`
      <!DOCTYPE html>
      <html lang="en">
      <head>
        <meta charset="UTF-8">
        <title>Print Invoice</title>
      </head>
      <body>
        ${invoiceContent}
        <script>
          window.onload = function() {
            window.print();
          };
        </script>
      </body>
      </html>
    `);
    newTab.document.close();
  }

  _handleCheckboxChange(event){
    const { name, checked } = event.target;
    const { _selectedItems } = this.state;
    const selectedArray = this.props.sale.list.data?this.props.sale.list.data:[];
    const selectedItems = [..._selectedItems, ...selectedArray];
    const uniqueItems = selectedItems.reduce((unique, item) => {
      const exists = unique.find(existingItem => existingItem.id === item.id);
      if (!exists) {
        unique.push(item);
      }
  
      return unique;
    }, []);
    let updatedSelectedItems=uniqueItems;
    if(!checked) 
      updatedSelectedItems = [];
    this.setState({
      [name]:checked,
      _selectedItems:updatedSelectedItems
    })
  }

  _refreshList(){
    // Exclude page...
    const { page, ..._params } = this.state._params;
    this.setState({
      _params:{ ..._params },
      _selectedItems:[],
      _allSelected:false,
    },()=>this._getList(this.state._params));
  }

  _handleResetFilter(e){
    e.preventDefault();
    const newState = this.state._filterOpen?{_filterOpen:!this.state._filterOpen}:{};
    const exportState = this.state._exportOpen?{_exportOpen:!this.state._exportOpen}:{};
    this.setState({
      ...newState,
      ...exportState,
      _selectedItems:[],
      _allSelected:false,
      _params:{ ...defaultParams },
      _isResetFilter: true
    },()=>this._getList(this.state._params));
  }

  _handleToggleFilterPanel(e){
    e.preventDefault();
    this._toggleFilterPanel();
  }

  _handleToggleExportPanel(e){
    e.preventDefault();
    this._toggleExportPanel();
  }

  _handleTogglePrintPanel(e){
    const filterState = this.state._filterOpen?{_filterOpen:!this.state._filterOpen}:{};
    this.setState({
      ...filterState,
      _printOpen:!this.state._printOpen});
  }

  _handleApplyFilter(payloadFilter){
    this.setState({
      _params: {
        ...this.state._params,
        ...payloadFilter,
        page: 1
      }
    },()=>this._getList(this.state._params));
  }

  _handleGoto(page){
    const _params=this.state._params;
    _params.page=page;
    this.setState({_params},()=>this._getList(this.state._params));
  }

  _onOpenCreditCardModal(item){
    this.setState({_invoiceNumber: item.invoice_number},()=>this._handleToggleModalCreditCard());
  }

  _handleToggleModalCreditCard() {
    this.setState({_modalCreditCard: !this.state._modalCreditCard});
  }

  _handlePrintSelectedItem(payload){
    const { _selectedItems } = this.state;
    const selectedArray = [payload];
    const selectedItems = [..._selectedItems, ...selectedArray];
    const uniqueItems = selectedItems.reduce((unique, item) => {
      const exists = unique.find(existingItem => existingItem.id === item.id);
      if (!exists) {
        unique.push(item);
      }
  
      return unique;
    }, []);
    let updatedSelectedItems=uniqueItems;
    if(!payload.selected) 
      updatedSelectedItems = uniqueItems.filter(item => item.id!==payload.id);
    this.setState({
      _selectedItems:updatedSelectedItems
    })
  }

  _togglePurchasePriceModal(value, payloads = null){
    this.setState({
      _isModalPurchasePrice: value,
      _dataModalPurchasePrice: payloads? { ...payloads }: null
    })
  }

  _onRenderRowItem(){
    if(this.props.sale.list.status_code===200&&this.props.sale.list.data.length) {
        const { current_page,per_page } =  this.props.sale.list;
        return this.props.sale.list.data.map((item,index)=>{
          const rowId = generateNumber(per_page,current_page,index);
          return (
            <RowItem
              key={rowId}
              rowId={rowId}
              data={item}
              id={item.id}
              quantity={item.offer.quantity}
              buyerEmail={item.buyer_email}
              invoiceNumber={item.invoice_number}
              orderDate={item.created_at}
              updatedAt={item.updated_at}
              orderStatus={generateDescStatus(item)}
              status={item.status}
              totalAmount={item.total_price}
              displayName={item.sale_product.display_name}
              SKU={item.sale_product.SKU}
              colour={item.sale_product.colour}
              preOrder={item.sale_product.pre_order}
              preVerified={item.sale_product.pre_verified}
              size={item.sale_product.size.US}
              autoDisburse={item.autodisburse}
              autoCancel={item.auto_cancelled_at}
              marked={item.marked}
              notes={item.sale_notes}
              loginId={this.props.injectedProps.id}
              legitCheckLogs={item.legit_check_logs}
              // markedNotes={item.marked_notes}
              markedAt={item.open_marked_at}
              markedBy={item.open_marked_by}
              closeMarkedAt={item.close_marked_at}
              unreadNoteCount={item.unread_notes_count}
              consignmentId={item.sale_product.consignment_id}
              sneakersCondition={item.sale_product.sneakers_condition}
              category={item.sale_product.category_name}
              bank={item.offer.bank_code}
              paymentVia={item.offer.payment_via}
              paymentMethod={item.offer.payment_method}
              onOpenCreditCard={()=>this._onOpenCreditCardModal(item)}
              rack={item.offer.user_sell.rack}
              purchasePrice={item.purchase_price}
              followUpStatus={item.follow_up_status}
              isOpenPrint={this.state._printOpen}
              allSelected={this.state._allSelected}
              selectedItems={this.state._selectedItems}
              voucherCode={item.voucher_code? item.voucher_code: item.voucherable? item.voucherable.code: item.voucherable_id}
              openPurchasePriceModal={(payloads) => this._togglePurchasePriceModal(true, payloads)}
              printSelectedItems={(selected) => this._handlePrintSelectedItem(selected)}
              userSellId={item?.offer?.user_sell?.id}
              turnOnAutoCancelEnabled={item?.turn_on_auto_cancel_enabled}
            />
          )}
        )
    }
    return null;
  }

  _onRenderPagination(){
    const { status_code,data } = this.props.sale.list;
    const hasData = (status_code===200 && data.length) || null;
    return hasData && (
      <Paginations
        size="sm"
        lastPage={this.props.sale.list.last_page}
        perPage={this.props.sale.list.per_page}
        total={this.props.sale.list.total}
        maxPage={8}
        path={this.props.sale.list.path}
        currentPage={this.props.sale.list.current_page}
        prevPageUrl={this.props.sale.list.prev_page_url}
        nextPageUrl={this.props.sale.list.next_page_url}
        handleGotoCallback={this._handleGoto}
      />
    );
  }

  async _handleCategoryChanged(category){
    await this.setState({
      _params: {
        ...this.state._params,
        category: category === ''? category: category.value,
        page: 1,
      },
    });
    const { _params } = this.state;
    this._getList(_params);
  }

  onMarkedClick = async (value) => {
    await this.setState({
      _params: {
        ...this.state._params,
        marked: value? 1: 0,
        page: 1,
      },
    });
    const { _params } = this.state;
    this._getList(_params);
  }

  render(){
    return(
      <div className="animated fadeIn">
        <Row>
          <ModalPurchasePrice
            data={this.state._dataModalPurchasePrice}
            isOpen={this.state._isModalPurchasePrice}
            toggle={() => this._togglePurchasePriceModal(false)}
          />
          <ModalInfoCreditCard
            isOpen={this.state._modalCreditCard}
            toggle={this._handleToggleModalCreditCard}
            invoiceNumber={this.state._invoiceNumber}
          />
          <Col xs={12} md={12}>
            <Card>
              <CardHeaderWithToolbars>
                <Title>
                  <i className="fa fa-align-justify"></i>
                  <span>All Sale List</span>
                </Title>
                {this.state.isDataLoaded && <Toolbars
                  isOpen={this.state._filterOpen}
                  isOpenExport={this.state._exportOpen}
                  isOpenPrint={this.state._printOpen}
                  onToggleFilter={this._handleToggleFilterPanel}
                  onToggleExport={this._handleToggleExportPanel}
                  onTogglePrint={this._handleTogglePrintPanel}
                  onRefreshList={this._handleRefresh}
                  onResetFilter={this._handleResetFilter}
                />}
              </CardHeaderWithToolbars>
              <FilterContainer>
                <FiltersPanel
                  {...this.state._params}
                  onApplyFilter={this._handleApplyFilter}
                  isReset={this.state._isResetFilter}
                  onResetFilterCallback={()=>this.setState({_isResetFilter: false, _filterOpen: false})}
                  isOpen={this.state._filterOpen}
                />
              </FilterContainer>
              <FilterContainer>
                <ExportsPanel
                  onHandleExport={this._handleExport}
                  isOpenExport={this.state._exportOpen}
                  data={this.props.sale.report_sale}
                  categoryOptions={this.props.categoryOptions}
                />
              </FilterContainer>
              <CardBody>
                <CardText>
                  In this section we can monitoring our sales.
                </CardText>
                <div className="table-responsive">
                  <PaginationSummaries
                    isOpenPrint={this.state._printOpen}
                    allSelected={this.props._allSelected}
                    isSelected={this.state._selectedItems.length}
                    printInvoice={this._handlePrintInvoice}
                    handleCheckboxChange={this._handleCheckboxChange}
                    from={this.props.sale.list.from}
                    to={this.props.sale.list.to}
                    total={this.props.sale.list.total}
                  />
                  <Tbl>
                    <TblHead>
                      <RowHeader
                        marked={this.state._params.marked}
                        onMarkedClick={this.onMarkedClick}
                        categoryName={this.state._params.category}
                        categoryValue={this.state._params.category}
                        onCategoryChanged={this._handleCategoryChanged}
                      />
                    </TblHead>
                    <TblBody
                      isLoad={this.props.sale.isFetch}
                      hasRow={this.props.sale.list.status_code===200&&this.props.sale.list.data.length>0}
                      columnCount={9}
                    >
                    { this._onRenderRowItem() }
                    </TblBody>
                  </Tbl>
                </div>
                {this._onRenderPagination()}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    )
  }
}
const mapStateToProps= ({sale, masterCategories, auth: {id: authId, email, isFetch, isLogged, roles}}) => {
	return {
    sale,
    guardData: {isFetch, isLogged, roles, id: authId, email},
    categoryOptions:masterCategories
	}
};

const mapDispatchToProps = (dispatch) => {
	return{
    getList:(query) => dispatch(getSaleList(query)),
    reportSale:(payload) => dispatch(reportSale(payload)),
	}

};

const enhance = connect(mapStateToProps,mapDispatchToProps);
export default enhance(privateView(Monitoring,['supervisor','sale_supervisor', 'cashier']));
