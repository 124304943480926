import React from "react";
import Select from "react-select";

const SelectBankPromoTag = ({
  value,
  placeholder,
  onSelectChange,
  options,
}) => {
  return (
    <Select.Creatable
      name="bankPromoTags"
      valueKey="id"
      labelKey="name"
      options={options}
      value={value}
      placeholder={placeholder}
      onChange={onSelectChange}
      multi
    />
  );
};

export default SelectBankPromoTag;
