import React from 'react';
import propTypes from 'prop-types';
import { TextRupiah } from '../../../components/Text';
import DateToHuman from '../../../components/DateToHuman';
import { Link } from 'react-router-dom';
import { transPaymentType, transStatus } from '../helpers';  

const RowItem = props => (
  <tr>
    <th className="v-center" scope="row">{ props.rowNumber }</th>
    <td className="v-center">
      <p style={{marginBottom: 0}}>
        #{ props.id }/<strong>{ props.externalId }</strong>
      </p>
      <p style={{marginBottom: 0, fontSize: ".75rem"}}>
        { props.transactionId }
      </p>
      <p style={{marginBottom: 0, fontSize: ".75rem"}}>
        { props.createdAt }
      </p>
    </td>
    <td className="v-center">
      <TextRupiah bold prefix="Rp. " value={props.grossAmount}/>
    </td>
    <td className="v-center">
      <p style={{marginBottom: 0}}>
        { transPaymentType(props.paymentType) }{props.paymentType === 'bank_transfer'? <span> / { props.vaType }</span>: null}
      </p>
      <p style={{marginBottom: 0}}>
        Status: { transStatus(props.status, props.transactionId, props.isExpired) }<br/>
        {
          props.status === 'pending' && !props.isExpired?
          <span style={{fontSize: '.75rem'}}>Expire in {props.expiredAt}</span>
          :null
        }
      </p>
    </td>
    <td className="v-center">
      <DateToHuman
        value={props.updatedAt}
        id={`tooltip_${props.rowNumber}_${props.id}`}
      />
    </td>
    <td className="v-center">
      <Link to={`${props.defaultRoute}/${props.id}`} className="btn btn-secondary btn-sm btn-link"> Details</Link>
    </td>
  </tr>
)

export default RowItem;