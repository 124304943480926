import React from 'react';
import { Link } from 'react-router-dom';
import propTypes from 'prop-types';
import {
  Card,
  CardHeader,
  CardBody,
  CardText
} from 'reactstrap';
import Loading from 'react-loading-animation';

import { Tbl, TblHead, TblBody } from  '../../Tbl';
import Paginations from '../../Paginations';
import PaginationSummaries from '../../Paginations/PaginationSummaries';
import { generateNumber } from  '../../../utils';

const dataHeader = ['No.', 'Bank', 'Account Number', 'Account Name', 'Updated'];
const _renderRowItem = (dataBankAccount) => {
  if(dataBankAccount.isFetch === false && dataBankAccount.status_code===200){
    const { data, current_page, per_page } = dataBankAccount;
    return data.map((item, index)=>{
      const rowNumber = generateNumber(per_page,current_page,index);
      return (
        <tr key={index}>
          <th scope="row">{rowNumber}</th>
          <td>{item.bank_name?item.bank_name: '-'}</td>
          <td>{item.account_number}</td>
          <td>{item.account_name}</td>
          <td>{item.updated_at}</td>
        </tr>
      )
    })
  }
  return null;
}

const UserBankAccountList = props =>{
  const {
    isUserFetching,
    dataBankAccount,
    onGotoPage
  } = props;
  const showPagination = (dataBankAccount.status_code === 200 && dataBankAccount.data.length)||null;
  return(
    <Card>
      <CardHeader>Bank Accounts</CardHeader>
      <CardBody>
        {
          isUserFetching?
          <Loading/>
          :
          <div>
            <CardText>
              You can add or modify or remove their bank accounts in <Link to={`/users/${props.userId}`} title="Go to User Details.">User Details Page</Link>.
            </CardText>
            <div className="table-responsive">
              <PaginationSummaries
                from={dataBankAccount.from}
                to={dataBankAccount.to}
                total={dataBankAccount.total}
              />
              <Tbl>
                <TblHead dataHeader={dataHeader}/>
                <TblBody
                  isLoad={dataBankAccount.isFetch}
                  hasRow={dataBankAccount.status_code===200 && dataBankAccount.data.length}
                  columnCount={dataHeader.length}
                >
                  { _renderRowItem(dataBankAccount) }
                </TblBody>
              </Tbl>
            </div>
            {
              showPagination && (
                <Paginations 
                  size="sm" 
                  lastPage={dataBankAccount.last_page} 
                  perPage={dataBankAccount.per_page}
                  total={dataBankAccount.total}
                  maxPage={8}
                  path={dataBankAccount.path}
                  currentPage={dataBankAccount.current_page} 
                  prevPageUrl={dataBankAccount.prev_page_url} 
                  nextPageUrl={dataBankAccount.next_page_url} 
                  handleGotoCallback={onGotoPage}
                />
              )
            }
          </div>
        }
      </CardBody>
    </Card>
  )
}

export default UserBankAccountList;

UserBankAccountList.defaultProps = {
  userId: null
}

UserBankAccountList.propTypes={
  userId: propTypes.number,
  isUserFetching: propTypes.bool,
  dataBankAccount: propTypes.object,
  onGotoPage: propTypes.func
}