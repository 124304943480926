import React, { useReducer, useCallback, useEffect } from 'react';
import {
  CardBody,
  Col,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  Button,
} from 'reactstrap';
import propTypes from 'prop-types';
import FilterContainer from '../../../../components/Container/FilterContainer/FilterContainer';
import { SortForm, SearchForm } from '../Filters/FilterPanel';

const reducer = (state, action) => {
  switch(action.type){
    case 'INPUT_CHANGED': return {...state, ...action.payload}
    default: return state
  }
}


const Filters = props => {
  const [state, dispatch] = useReducer(reducer, {
    keyword: props.keyword,
    status: props.status,
    perPage: props.per_page,
    sortBy: props.sort_by
  })

  useEffect(()=>{
    if(props.isResetFields){
      dispatch({
        type: 'INPUT_CHANGED',
        payload: {
          keyword: props.keyword,
          status: props.status,
          perPage: props.per_page,
          sortBy: props.sort_by
        }
      })
    }
  }, [props.isResetFields])

  const setInputChange = useCallback(payloads => {
    dispatch({
      type: 'INPUT_CHANGED',
      payload: payloads
    })
  }, [])

  const onApplyFilter = useCallback((payloads = {}) => {
    props.onApplyFilter({
      keyword: state.keyword,
      status: props.status,
      per_page: state.perPage,
      sort_by: state.sortBy,
      ...payloads
    })
  }, [state])

  return (
    <FilterContainer>
      <CardBody className="filter-container__card-body">
        <SearchForm
          block
          title="Search raffle's entries"
          keyword = {state.keyword}
          onInputChange = {setInputChange}
          onSubmit = {e => {
            e.preventDefault()
            onApplyFilter()
          }}
        />
        <hr/>
        <SortForm
          sortOptions={props.sortOptions}
          perPage={state.perPage}
          sortBy={state.sortBy}
          status={state.status}
          onInputChange={ (name, value, payloads) =>{
            setInputChange(payloads)
            onApplyFilter({[name]: value})
          }}
        />
      </CardBody>
    </FilterContainer>
  )
}

Filters.propTypes= {
  keyword: propTypes.string,
  per_page: propTypes.oneOfType([propTypes.number, propTypes.string]),
  sort_by: propTypes.string,
  isResetFields: propTypes.bool.isRequired,
  onHandleChange: propTypes.func,
  onHandleSearch: propTypes.func,
  onHandleChangeAndSubmit: propTypes.func
}

Filters.defaultProps= {
  isResetFields: false,
  keyword: '',
  per_page: 15,
  sort_by: ''
}
export default Filters;