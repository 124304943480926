import React, { Component } from 'react';
import classNames from 'classnames';
import propTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import {
  Card,
  CardHeader,
  CardBody,
  Col,
  Alert,
  Form,
  FormGroup,
  Input,
  Label
} from 'reactstrap';
import moment from 'moment-timezone';
import Loading from 'react-loading-animation';
import { 
  errorMessage, 
  hasError, 
  _getValue, 
  textChange 
} from '../../../../utils/form';
import { 
  sellerVerificationStatusOptions, 
} from '../../../../constants/kickavenue';
import {
  FieldRow,
  FieldColumn,
  FieldLabel,
  FieldValue
} from '../../../../styled/Fields';
import { DATE_TIMEZONE } from '../../../../config/date';
import ButtonLoading from '../../../../components/Button/ButtonLoading';
const ALERT_ERROR_COLOR = 'danger';
const ALERT_SUCCESS_COLOR = 'success';
const ALERT_ERROR_BOLD_MESSAGE = 'Ooops.';
const ALERT_SUCCESS_BOLD_MESSAGE = 'Success.';
const ALERT_SUCCESS_UPDATE_MESSAGE = 'Your seller verification updated successfully';
class DetailCard extends Component {
  constructor(props){
    super(props);
    this.state = {
      id: 0,
      email: "",
      mobileNumber: 0,
      type: "",
      name: "",
      idCard: 0,
      address: "",
      status: "",
      notes: "",
      updated_at: "",
      _error: null,
      isAlertOpen: false,
      alert: null,
      isSubmit: false
    }
		moment.tz(DATE_TIMEZONE);
    this._handleTextChange = this._handleTextChange.bind(this);
    this._onSubmit = this._onSubmit.bind(this);
    this._toggleAlert = this._toggleAlert.bind(this);
  }

  shouldComponentUpdate(nextProps){
    if(nextProps.userSetting.isSubmit===true){
      return false;
    }
    return true;
  }

  componentDidUpdate(prevProps) {
    if(prevProps.isLoading !== this.props.isLoading && !this.props.isLoading){
      const {
        id,
        user: { email },
        mobileNumber,
        type,
        name,
        idCard,
        address,
        status,
        notes,
        updated_at
      } = this.props;
      this.setState({
        id,
        email,
        mobileNumber,
        type,
        name,
        idCard,
        address,
        status,
        notes,
        updated_at
      })
    }
    if(prevProps.dataSellerVerification.isSubmit!==this.props.dataSellerVerification.isSubmit && !this.props.dataSellerVerification.isSubmit){
      let { _error } = this.state;
      let payload = {};
      let color = ALERT_SUCCESS_COLOR, header = ALERT_SUCCESS_BOLD_MESSAGE, message = ALERT_SUCCESS_UPDATE_MESSAGE;
      this.setState({
        ...payload,
        isSubmit: false,
        _error,
        isAlertOpen: true,
        alert:{
          color,
          header,
          message
        }
      })
    }
  }

  _toggleAlert(){
    this.setState({
      isAlertOpen: false
    })
  }

  _renderAlert(){
    return this.state.alert !== null?
      <Alert
        color={this.state.alert.color}
        toggle={this._toggleAlert}
        isOpen={this.state.isAlertOpen}
      >
        <strong>{this.state.alert.header}</strong> &nbsp;
        {this.state.alert.message}
      </Alert>
      :null
  }

  _handleTextChange(e){
    const lastState = {...this.state};
    this.setState({
      ...textChange(e, lastState)
    })
  }

  _onSubmit(e){
    e.preventDefault();
    this.setState({
      isSubmit: true,
      _error: null,
      isAlertOpen: false,
      alert: null
    },()=>{
      const { status, notes } = this.state;
      const payload = {
        status,
        notes
      }
      this.props.onUpdateSellerVerification(payload);
    })
  }

  render(){
    return (
      <Card>
        <CardHeader><i className="fa fa-align-justify"></i> Seller Verification Detail</CardHeader>
        <CardBody>
          {
            this.props.isLoading?
            <Loading/>
            :
            <Form onSubmit={this._onSubmit}>
              {this._renderAlert()}
              <FieldRow>
                <FieldColumn className="col-xs-12 col-12">
                  <FieldLabel>Email</FieldLabel>
                  <FieldValue>
                    <span>{_getValue(this.state.email)}</span>
                  </FieldValue>
                </FieldColumn>
              </FieldRow>
              <FieldRow>
                <FieldColumn className="col-xs-12 col-12">
                  <FieldLabel>Mobile Number</FieldLabel>
                  <FieldValue>
                    <span>{_getValue(this.state.mobileNumber)}</span>
                  </FieldValue>
                </FieldColumn>
              </FieldRow>
              <FieldRow>
                <FieldColumn className="col-xs-12 col-12">
                  <FieldLabel>Type</FieldLabel>
                  <FieldValue>
                    <span>{_getValue(this.state.type)}</span>
                  </FieldValue>
                </FieldColumn>
              </FieldRow>
              <FieldRow>
                <FieldColumn className="col-xs-12 col-12">
                  <FieldLabel>Name (according to NPWP/KTP)</FieldLabel>
                  <FieldValue>
                    <span>{_getValue(this.state.name)}</span>
                  </FieldValue>
                </FieldColumn>
              </FieldRow>
              <FieldRow>
                <FieldColumn className="col-xs-12 col-12">
                  <FieldLabel>NPWP/KTP Number</FieldLabel>
                  <FieldValue>
                    <span>{_getValue(this.state.idCard)}</span>
                  </FieldValue>
                </FieldColumn>
              </FieldRow>
              <FieldRow>
                <FieldColumn className="col-xs-12 col-12">
                  <FieldLabel>Address</FieldLabel>
                  <FieldValue>
                    <span>{_getValue(this.state.address)}</span>
                  </FieldValue>
                </FieldColumn>
              </FieldRow>
              <FieldRow>
                <Col xs={12} md={6}>
                  <FormGroup className={classNames({'has-danger has-feedback':hasError('status',this.state._error)})}>
                    <Label for="formSellStatus">Status</Label>
                    <Input
                      id="formSellStatus"
                      type="select"
                      name="status"
                      value={_getValue(this.state.status)}
                      onChange={this._handleTextChange}
                    >
                      {
                        sellerVerificationStatusOptions.map((status, index)=><option value={status.value} key={index}>{status.label}</option>)
                      }
                    </Input>
                    {errorMessage('status',this.state._error)}
                  </FormGroup>
                </Col>
                <Col xs={12} md={6}>
                  <FormGroup className={classNames({'has-danger has-feedback':hasError('notes',this.state._error)})}>
                    <Label for="formSellNote">Notes</Label>
                    <Input
                      id="formSellNote"
                      type="textarea"
                      name="notes"
                      placeholder="Enter your note"
                      value={_getValue(this.state.notes)}
                      onChange={this._handleTextChange}
                    />
                    {errorMessage('notes',this.state._error)}
                  </FormGroup>
                </Col>
              </FieldRow>
              <FieldRow>
                
              </FieldRow>
              <FieldRow>
                <FieldColumn className="col-xs-12 col-md-6">
                  <FieldLabel>Last Updated</FieldLabel>
                  <FieldValue>
                    <span>{_getValue(this.state.updated_at)}</span>
                  </FieldValue>
                </FieldColumn>
              </FieldRow>
              <FieldRow>
                <FieldColumn className="col-xs-12 col-md-6">
                  <ButtonLoading
                    isLoading={this.state.isSubmit}
                    loadingMessage="Updating..."
                    color="primary"
                  >
                    Update
                  </ButtonLoading>
                </FieldColumn>
              </FieldRow>
            </Form>
          }
        </CardBody>
      </Card>
    )
  }
}

export default withRouter(DetailCard);

DetailCard.propTypes = {
  isLoading: propTypes.bool,
  id: propTypes.number.isRequired,
  user: propTypes.object,
  userSetting: propTypes.object,
  dataSellerVerification: propTypes.shape({
    detail: propTypes.object,
    isSubmit: propTypes.bool,
    isFetch: propTypes.bool,
    error: propTypes.object,
  }),
  onUpdateSellerVerification: propTypes.func.isRequired,
}

DetailCard.defaultProps = {
  id: 0,
  isLoading: false
}
