import React from "react";
import propTypes from "prop-types";
import {
  Card,
  CardImg,
  CardBody,
  CardText,
  CardLink,
  Alert,
  Button,
} from "reactstrap";
import { FontAwesomeIcon } from "../../../../components/Icons";
import BadgeStorage from "./cardThumbnail/BadgeStorage";
import LoadingPlaceholder from "./cardThumbnail/LoadingPlaceholder";
import { getCacheImage } from "../../../../utils/imageHelper";

const CardThumbnail = ({
  id,
  url,
  updatedAt,
  storageName,
  fileInput,
  isUploading,
  isDeleting,
  onRemoveImage,
  isCopied,
  copiedId,
  copyImageUrl,
}) => {
  return(
  <Card style={{ marginBottom: "1rem" }}>
    <LoadingPlaceholder
      isVisible={isUploading || isDeleting}
      loadingColor={isDeleting ? "#f86c6b" : "green"}
      loadingText={isDeleting ? "Deleting..." : "Uploading..."}
    />
    <BadgeStorage storageName={storageName} />
    <CardImg
      top
      width="100%"
      src={id ? getCacheImage(url) : fileInput.preview}
      alt={id ? url : fileInput.preview}
    />
    <CardBody>
      <CardText>
        <small className="text-muted">
          {id ? `Last updated ${updatedAt}` : "Not uploaded yet..."}
        </small>
      </CardText>
      {id ? (
        <CardLink
          href={url}
          className="text-default"
          target="_blank"
        >
          <FontAwesomeIcon iconType="external-link" /> Open
        </CardLink>
      ) : null}
      <button
        type="button"
        className="btn btn-link text-danger card-link"
        style={{ padding: 0 }}
        onClick={onRemoveImage}
      >
        <FontAwesomeIcon iconType="remove" /> Delete
      </button>
      {id && (
        <div className="mt-2">
          {isCopied && copiedId === id && (
            <Alert color="success" className="mb-1 p-2">
              <FontAwesomeIcon iconType="check" /> Copied!
            </Alert>
          )}
          <Button color="info" onClick={() => copyImageUrl(id, url)}>
            Copy Image Link
          </Button>
        </div>
      )}
    </CardBody>
  </Card>
)};

export default CardThumbnail;

CardThumbnail.propTypes = {
  id: propTypes.number,
  url: propTypes.string,
  updatedAt: propTypes.string,
  storageName: propTypes.string,
  fileInput: propTypes.object,
  isUploading: propTypes.bool,
  isDeleting: propTypes.bool,
  onRemoveImage: propTypes.func,
  isCopied: propTypes.bool,
  copiedId: propTypes.number,
  copyImageUrl: propTypes.func,
};
CardThumbnail.defaultProps = {
  isUploading: false,
  isDeleting: false,
  fileInput: {
    preview: "",
  },
};
