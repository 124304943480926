import React from 'react';
import propTypes from 'prop-types';
import {
  Card,
  CardHeader,
  CardBody,
  Table
} from 'reactstrap';
import FontAwesomeIcon from '../../components/Icons/FontAwesomeIcon';
import { TextRupiah } from '../../components/Text';
import { DISBURSEMENT_BALANCE_IN } from './helpers';

const calculateBalanceRemains = (type, balance, amount) =>{
  if(type===DISBURSEMENT_BALANCE_IN){
    return balance + amount;
  }
  return balance - amount;
}

const DisbursementSummaries = props => (
  <Card>
    <CardHeader>
      <FontAwesomeIcon iconType="align-justify"/> Summaries
    </CardHeader>
    <CardBody>
      <div className="table-responsive">
        <Table>
          <tbody>
            {
              props.showingBalanceRemains === true?
              <tr>
                <td>{props.type === 'BALANCE_WITH_FEE'? 'Seller Credit':  props.type === 'LOCKED_BALANCE'? 'Kick Point': 'Kick Credit'}</td>
                <td className="text-right">
                  <TextRupiah prefix="Rp. " value={props.balance}/>
                </td>
              </tr>
              :null
            }
            <tr>
              <td>Total Amount (incl Fee)</td>
              <td className="text-right">
                <TextRupiah prefix="Rp. " value={props.totalAmount}/>
              </td>
            </tr>
            <tr>
              <td>Fee</td>
              <td className="text-right">
                <TextRupiah prefix="Rp. " value={props.fee}/>
              </td>
            </tr>
            <tr>
              <th scope="row">Total Amount to Transfer</th>
              <td className="text-right">
                <TextRupiah prefix="Rp. " value={props.totalAmount - props.fee}/>
              </td>
            </tr>
            {
              props.showingBalanceRemains === true?
              <tr>
                <th scope="row">Balance Remains</th>
                <td className="text-right">
                  <TextRupiah prefix="Rp. " value={calculateBalanceRemains(props.disbursementType, props.balance, props.totalAmount)}/>
                </td>
              </tr>
              :null
            }
          </tbody>
        </Table>
      </div>
    </CardBody>
  </Card>
)

DisbursementSummaries.propTypes = {
  showingBalanceRemains: propTypes.bool,
  disbursementType: propTypes.string,
  fee: propTypes.number,
  totalAmount: propTypes.number,
  balance: propTypes.number
}

DisbursementSummaries.defaultProps = {
  showingBalanceRemains: true,
  disbursementType: DISBURSEMENT_BALANCE_IN,
  fee: 0,
  totalAmount: 0,
  balance: 0
}

export default DisbursementSummaries;