import date, { compareDateNow } from '../../helpers/date';
import { _getOfferStatusStr } from '../../constants/kickavenue';


export const getStatusStr = (status, isExpired, payment) =>
  payment === null? _getOfferStatusStr(status, isExpired)
  : _getOfferStatusStr(status, isExpired, payment.status, payment.expired)

export const isExpired = expiredAt =>
  expiredAt && compareDateNow(date(expiredAt), 'seconds') < 0
