import React from 'react';
import propTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Button,
  ButtonGroup
} from 'reactstrap';
import { Link } from 'react-router-dom';
import DateToHuman from '../../../components/DateToHuman';
import { TextRupiah } from '../../../components/Text';
import { checkChange } from '../../../utils/form';
import { updateSale, doMarkedSale, getSaleNotes, setReadSaleNotes, getDetailSale2, getTrackingShipmentDetail } from '../../../actions/saleAction';
import OrderSummariesColumn from '../RowItem/OrderSummariesColumn';
import ReactLoading from 'react-loading';
import { getPaymentType, getPaymentVia } from '../../../constants/kickavenue';
import { ModalMarkedConsignment, ModalTrackingShipment } from '../../../components/Modals';
import ButtonStarred from '../RowItem/ButtonStarred';

class CourierRowItem extends React.Component{
  constructor(props){
    super(props);
    this.state={
      autoDisburse: props.autoDisburse,
      _autoDisburseEdit: props.autoDisburse,
      updatedAt: props.updatedAt,
      marked: props.marked,
      _markedEdit: props.marked,
      _isModalMarked: false,
      markedAt: props.markedAt || '',
      markedBy: props.markedBy || '',
      closeMarkedAt: props.closeMarkedAt || '',
      closeMarkedBy: props.closeMarkedBy || '',
      notes: props.notes || [],
      notesPage: 1,
      noteNextPage: null,
      unreadNoteCount: props.unreadNoteCount || 0,
      isConfirmation: false,
      purchasePrice: props.purchasePrice,
      compensation: props.compensation,
      followUpStatus: props.followUpStatus,
      _isModalTracking: false,
      shippingUpdate: null
    }
    this._handleCheckedChange = this._handleCheckedChange.bind(this);
    this._handleMarkedChange = this._handleMarkedChange.bind(this);
    this._handleTrackingChange = this._handleTrackingChange.bind(this);
    this._handleModalMarkedOnSubmit = this._handleModalMarkedOnSubmit.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevProps.sale.followUpSales.isSubmit !== this.props.sale.followUpSales.isSubmit && !this.props.sale.followUpSales.isSubmit){
      const { error, followUp } = this.props.sale.followUpSales;
      if(!error && prevProps.id === followUp.id){
        this.setState({
          updatedAt: followUp.updated_at,
          compensation: followUp.compensation_amount,
          followUpStatus: followUp.follow_up_status
        })
      } else if(error && prevProps.id === followUp.id){
        alert("Error when processing follow-up sales.")
      }
    }
    if(prevProps.sale.isSubmit !== this.props.sale.isSubmit && prevProps.sale.isSubmit){
      if(prevProps.sale.error===null){
        const { id, autodisburse, updated_at, marked, open_marked_at, open_marked_by, close_marked_at, close_marked_by, purchase_price } = this.props.sale.detail;
        if(prevProps.id===id){
          this.setState({
            autoDisburse: autodisburse,
            _autoDisburseEdit: autodisburse,
            marked: marked,
            _markedEdit: marked,
            // markedNotes: marked_notes || '',
            markedAt: open_marked_at || '',
            markedBy: open_marked_by || '',
            closeMarkedAt: close_marked_at || '',
            closeMarkedBy: close_marked_by || '',
            // notes: sale_notes,
            // unreadNoteCount: unread_notes_count,
            updatedAt: updated_at,
            purchasePrice: purchase_price
          })
        }
      }
      else{
        const { id, message } = this.props.sale.error;
        if(id && this.props.id === id){
          alert(message)
          this.setState({
            _autoDisburseEdit: this.state.autoDisburse,
            _markedEdit: this.state.marked,
          })
        }
      }
    }

    if(prevProps.sale.notes.isFetch && !this.props.sale.notes.isFetch){
      const { list: { data, next_page_url, error }, saleId } = this.props.sale.notes;
      if(this.props.id === saleId){
        if(error) {
          alert('Error while fetching notes');
        } else{
          const { loginId } = this.props;
          const ids = data.filter(item => !item.read && item.user_id !== loginId).map(({ id }) => id);
          if(ids.length){
            this.props.setReadSaleNotes(this.props.id, { ids })
          }
          this.setState({
            notes: this.state.notesPage === 1? [...data]: [...data, ...this.state.notes],
            noteNextPage: next_page_url? this.state.notesPage + 1: null
          })
        }
      }
    }

    if(prevProps.sale.saleDetail.isFetch && !this.props.sale.saleDetail.isFetch){
      const { error, data } = this.props.sale.saleDetail;
      if(!error && data.id === this.props.id){
        const { unread_notes_count } = data;
        this.setState({
          unreadNoteCount: unread_notes_count
        });
      }
    }

    if(prevProps.sale.notes.isSubmit && !this.props.sale.notes.isSubmit){
      const { readNotes: { data }, saleId, readNotes: { error } } = this.props.sale.notes;
      if(this.props.id === saleId && !error){
        if(data.total > 0) {
          this.props.getDetailSale2(this.props.id);
        }
      }
    }
    if(!prevState._isModalMarked && this.state._isModalMarked){
      this.setState({
        notesPage: 1,
        noteNextPage: null
      }, () => {
        this.props.getSaleNotes(this.props.id, {
          params: { per_page: 3, page: this.state.notesPage }
        });
      })
    }
    if (!prevState._isModalTracking && this.state._isModalTracking) {
      this.props.getTracking(this.props.id)
    }
    if (prevProps.sale.shippingUpdate.isFetch && !this.props.sale.shippingUpdate.isFetch)
    {
      const { data, error } = this.props.sale.shippingUpdate;
      if (!error) {
        this.setState({
          shippingUpdate: data
        })
      }
    }
  }

  shouldComponentUpdate(nextProps){
    if(nextProps.sale.isSubmit){
      return false;
    }
    if(!nextProps.sale.isSubmit&&nextProps.sale.isSubmit!==this.props.sale.isSubmit){
      if(nextProps.sale.error){
        const { error: { id } } = nextProps.sale;
        if(id && id != this.props.id){
          return false;
        }
      }
      else{
        if(nextProps.sale.detail.id && nextProps.sale.detail.id != this.props.id){
          return false;
        }
      }
    }
    return true;
  }

  _handleTrackingChange(){
    this.setState({
      _isModalTracking: !this.state._isModalTracking,
    });
  }

  _handleCheckedChange(e) {
    this.setState({
      ...checkChange(e, this.state)
    }, ()=>{
      const payload = {
      autodisburse : this.state._autoDisburseEdit
      }
      this.props.update(this.props.id, payload)
    });
  }

  _handleModalMarkedOnSubmit({ marked_notes, marked = null }){
    if(marked === false){
      const payload = { marked };
      if(marked_notes){
        payload['marked_notes'] = marked_notes;
      }
      this.setState({
        _markedEdit : marked,
        _isModalMarked: false,
        // isConfirmation: false
      });
      this.props.doMarkedSale(this.props.id, payload)
      return;
    }
    this.setState({
      _markedEdit : this.state.closeMarkedAt? (marked !== null? marked: this.state._markedEdit): true,
      _isModalMarked: false,
      // isConfirmation: false,
      markedNotes: marked_notes
    }, () => {
      const payload = {
        // status : param.status,
        marked : this.state._markedEdit,
        marked_notes
      }
      this.props.doMarkedSale(this.props.id, payload)
    })
  }

  _handleMarkedChange(){
    this.setState({
      // isConfirmation: false,
      _isModalMarked: !this.state._isModalMarked,
      _markedEdit : this.state.marked //cancel toggle
    });
  }

  _openPurchasePriceModal = e => {
    e.preventDefault();
    this.props.openPurchasePriceModal({
      id: this.props.id,
      invoiceNumber: this.props.invoiceNumber,
      purchasePrice: this.state.purchasePrice
    })
  }

  _openFollowUpModal = e => {
    e.preventDefault();
    const status = this.state.followUpStatus? this.state.followUpStatus: 'SET_FOLLOW'
    this.props.openFollowUpModal({
      id: this.props.id,
      invoiceNumber: this.props.invoiceNumber,
      compensation: this.state.compensation,
      status,
      user: this.props.buyerEmail,
      seller: this.props.sellerEmail
    })
  }

  render() {
    const {
      id,
      data,
      rowId,
      quantity,
      buyerEmail,
      invoiceNumber,
      totalAmount,
      orderDate,
      displayName,
      SKU,
      size,
      colour,
      preOrder,
      preVerified,
      updatedAt,
      isSelected,
      onOpenDeliverModal,
      consignmentId,
      category,
      sneakersCondition,
      bank,
      paymentVia,
      paymentMethod,
      rack,
      legitCheckLogs
    } = this.props;
    const via = getPaymentVia(paymentVia);
    const actionButtons = (isSelected===true?
      <ReactLoading
        type="bars"
        color="green"
        height={20}
        width={30}
      />:
      <div>
        <ButtonGroup>
          {!this.props.kaSent?
            <Button
              color="success"
              size="sm"
              title="Deliver Now!"
              onClick={onOpenDeliverModal}
            >
              <i className="fa fa-truck"></i>
            </Button>
          : null}
          <Link
            to={`/sales/${id}`}
            className="btn btn-sm btn-secondary"
            target="_blank"
            title="More Detail..."
          >
            <i className="fa fa-external-link"></i>
          </Link>
        </ButtonGroup>
      </div>);
    return (
      <tr>
        <ModalMarkedConsignment
          isOpen={this.state._isModalMarked}
          isConfirmation={this.state.isConfirmation}
          toggle={this._handleMarkedChange}
          invoiceNumber={invoiceNumber}
          marked={this.state.marked}
          markedAt={this.state.markedAt}
          markedBy={this.state.markedBy}
          closeMarkedAt={this.state.closeMarkedAt}
          markedNotes={this.state.markedNotes}
          notes={this.state.notes}
          unreadNoteCount={this.state.unreadNoteCount}
          hasNextPage={this.state.noteNextPage}
          loginId={this.props.loginId}
          onSubmit={this._handleModalMarkedOnSubmit}
          onLoadMoreNotes={() => {
            const { notesPage, noteNextPage } = this.state;
            if(!noteNextPage) return;
            this.setState({
              noteNextPage: null,
              notesPage: notesPage + 1
            }, () => {
              this.props.getSaleNotes(this.props.id, {
                params: { per_page: 3, page: this.state.notesPage }
              });
            })
          }}
        />
        <ModalTrackingShipment
          shippingUpdate={this.state.shippingUpdate}
          isLoading={this.props.sale.shippingUpdate.isFetch}
          isOpen={this.state._isModalTracking}
          toggle={this._handleTrackingChange}
        />
        <th className="v-center" scope="row">{ rowId }</th>
        <td className="v-center">
          <OrderSummariesColumn
            id={id}
            quantity={quantity}
            invoiceNumber={invoiceNumber}
            orderDate={orderDate}
            buyerEmail={buyerEmail}
            displayName={displayName}
            size={size}
            SKU={SKU}
            colour={colour}
            preOrder={preOrder}
            preVerified={preVerified}
            consignmentId={consignmentId}
            category={category}
            sneakersCondition={sneakersCondition}
            unreadNoteCount={this.state.unreadNoteCount}
            legitCheckLogs={legitCheckLogs}
          />
        </td>
        <td className="v-center">
          <span className="text-uppercase rack-span" style={{fontSize: '.75rem'}}>{ rack ? rack : '-' }</span>
        </td>
        <td className="v-center">
          <div>
            <TextRupiah
              prefix="Rp. "
              value={totalAmount}
            />
          </div>
          <div>
            <a
              href="#"
              className="mr-1"
              style={{textDecoration: 'underline', color: '#4a4a4a', fontSize: '.75rem'}}
              onClick={e => {
                e.preventDefault();
                this.setState({ _isModalMarked: true, isConfirmation: false })
              }}
            >
              <i className="fa fa-pencil"></i> {this.state.markedAt? 'view': 'add'} notes
              {/* <i className="fa fa-pencil"></i> add notes */}
            </a>
            <ButtonStarred
              title={this.state._markedEdit? "Remove mark!": "Mark!"}
              value={this.state._markedEdit}
              onClick={() => {
                const value = !this.state._markedEdit;
                this.setState({
                  _isModalMarked: true,
                  isConfirmation: !value,
                  _markedEdit: value
                })
              }}
            />
          </div>
          <div style={{fontSize: '.75rem'}}>
            <strong>COURIER:</strong> <span>{this.props.courier}</span>
          </div>
        </td>
        <td className="v-center">
          <a 
            href="#"
            className="mr-1"
            style={{textDecoration: 'underline', color: '#4a4a4a', fontSize: '.75rem'}}
            onClick={e => {
              e.preventDefault()
              this.setState({
                _isModalTracking: true,
                shippingUpdate: null
              })
            }}
          >Tracking Shipment</a><br />
          <div>
            {
              bank && paymentMethod === 'virtual_account'?
              <span style={{fontSize: '.75rem'}}>{bank+ '-'+ getPaymentType(paymentMethod)}</span>
              :
              getPaymentType(paymentMethod)
            }
          </div>
          { via !== null? <div><span style={{fontSize: '.75rem'}}>via <i>{via}</i></span></div>: null}
          {
            paymentVia !== null && paymentVia === 'vt' && paymentMethod=== "credit_card" ?
            <button className="btn btn-sm btn-secondary" onClick={this.props.onOpenCreditCard} target="_blank" title="Show More Info"><i className="fa fa-info-circle"></i> More Info</button>
            :
            null
          }
          {
            <div><span style={{fontSize: '.75rem'}}>{data.offer.payment_id?data.offer.payment_id:data.offer.id}</span></div>
          }
        </td>
        <td className="v-center"><DateToHuman  value={updatedAt} id={`tooltip_${rowId}`}/></td>
        <td className="v-center">
          { actionButtons }
        </td>
      </tr>
    )
  }
}
const mapStateToProps = ({ sale }) => ({
  sale
})
const mapDispatchToProps = (dispatch) => ({
  getDetailSale2: (id) => dispatch(getDetailSale2(id)),
  getSaleNotes: (id, query) => dispatch(getSaleNotes(id, query)),
  setReadSaleNotes: (id, body) => dispatch(setReadSaleNotes(id, body)),
  update: (id, payload) => dispatch(updateSale(id, payload)),
  doMarkedSale: (id, payload) => dispatch(doMarkedSale(id, payload)),
  getTracking: id => dispatch(getTrackingShipmentDetail(id))
})

export default connect (mapStateToProps, mapDispatchToProps) (CourierRowItem);

CourierRowItem.defaultProps = {
  totalAmount: 0,
  isSelected: false,
  hijacked: false,
  status: 'NEW'
}

CourierRowItem.propTypes={
  rowId: propTypes.number.isRequired,
  id: propTypes.number.isRequired,
  buyerEmail: propTypes.string,
  autoDisburse: propTypes.number,
  totalAmount: propTypes.oneOfType([propTypes.number,propTypes.string]),
  orderDate: propTypes.string,
  invoiceNumber: propTypes.string.isRequired,
  size: propTypes.string,
  displayName: propTypes.string,
  SKU: propTypes.string,
  colour: propTypes.string,
  preOrder: propTypes.oneOfType([propTypes.number,propTypes.string]),
  preVerified: propTypes.oneOfType([propTypes.bool,propTypes.string]),
  updatedAt: propTypes.string,
  hijacked: propTypes.bool,
  status: propTypes.string,
  onOpenDeliverModal: propTypes.func.isRequired,
  consignmentId: propTypes.string,
  onOpenCreditCard: propTypes.func.isRequired,
  marked: propTypes.bool,
  status: propTypes.string,
  markedNotes: propTypes.string,
  markedAt: propTypes.string,
  rack: propTypes.string,
}
