import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import FontAwesomeIcon from '../../../../components/Icons/FontAwesomeIcon';
import { TextRupiah } from '../../../../components/Text';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  line-height: 1.5;
`;

const IconWrapper = styled.div`
  margin-right: 1rem;
  font-size: 1.5rem;
`
const Price = styled.div`
  font-size: .75rem;
`

const ProductValue = props => (
  <div className="Select-value" title={props.value.email}>
    <span className="Select-value-label">
      <Wrapper>
        <IconWrapper>
          <FontAwesomeIcon iconType="user"/>
        </IconWrapper>
        <div>
          <div>
            { props.value.email }
          </div>
          <div>
            { props.value.product } ({ props.value.size })
          </div>
          <Price>
            <TextRupiah prefix="Rp. " value={props.value.price}/>
          </Price>
        </div>
      </Wrapper>
    </span>
  </div>
)

ProductValue.propTypes = {
    value: propTypes.shape({
    id: propTypes.number,
    email: propTypes.string,
    price: propTypes.string
  })
}
ProductValue.defaultProps = {

}

export default ProductValue;
