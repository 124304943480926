import React from 'react';
import propTypes from 'prop-types';
import { Table, Card, CardHeader, CardBody } from 'reactstrap';
import Loading from 'react-loading-animation';
import { TextRupiah } from '../../../../components/Text';
import { _getFloatValue } from '../../../../utils/form';
import AlertNotFound from '../../../../components/Card/AlertNotFound';

class PurchaseSummaries extends React.Component{
  render(){
  const offerAmount=this.props.offerAmount*this.props.quantity;
  const ongkir=this.props.kaCourierPrice*this.props.quantity;
  const discount=this.props.discount+ this.props.adminFeeDiscount+this.props.kaCourierDiscount;
  const totalAmount = (((offerAmount)-discount-this.props.subsidyPrice)+this.props.uniqueAmount+ongkir+this.props.administrationFee);
  const totalAdjustedAmount = totalAmount-this.props.walletAmount;
    return(
      <Card>
        <CardHeader>Purchase Summaries</CardHeader>
        <CardBody>
          {
            this.props.isAlert?
            <AlertNotFound message="Showing data not found!"/>
            :
            <Table>
              <thead>
                <tr>
                  <th colSpan={2} className="text-center">
                    Purchase Summary
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{this.props.displayName} ({this.props.quantity}x)</td>
                  <td><TextRupiah prefix="Rp. " value={_getFloatValue(offerAmount)} align="right" block/></td>
                </tr>
                <tr>
                  <td>{this.props.kaCourierOption} ({this.props.quantity}x)</td>
                  <td><TextRupiah prefix="Rp. " value={_getFloatValue(ongkir)} align="right" block/></td>
                </tr>
                <tr>
                  <td>Biaya Tambahan</td>
                  <td><TextRupiah  prefix="Rp. " value={(_getFloatValue(this.props.uniqueAmount)+_getFloatValue(this.props.administrationFee))} align="right" block/></td>
                </tr>
                <tr>
                  <td>Voucher ({this.props.voucher?this.props.voucher.code:'-'})</td>
                  <td><TextRupiah prefix="Rp. " value={_getFloatValue(discount)} align="right" block/></td>
                </tr>
                <tr>
                  <td>Subsidy Price</td>
                  <td className="v-center"><TextRupiah prefix="-Rp." value={_getFloatValue(this.props.subsidyPrice)} align="right" block/></td>
                </tr>
                <tr>
                  <td><b>Sub Total</b></td>
                  <td>
                    <TextRupiah prefix="Rp. " value={_getFloatValue(totalAmount)} align="right" block bold/>
                  </td>
                </tr>
                <tr>
                  <td>Kick Credit</td>
                  <td>
                    <TextRupiah prefix="-Rp. " value={_getFloatValue(this.props.walletAmount)} align="right" block/>
                  </td>
                </tr>
                <tr>
                  <td><b>Total</b></td>
                  <td>
                    <TextRupiah prefix="Rp. " value={_getFloatValue(totalAdjustedAmount)} align="right" block bold/>
                  </td>
                </tr>
              </tbody>
            </Table>
          }
        </CardBody>
      </Card>
    )
  }
}
export default PurchaseSummaries;