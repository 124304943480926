import React from 'react';
import { Link } from 'react-router-dom';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  FormGroup,
  Input,
  Label
} from 'reactstrap';


class ModalEditorsPosition extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      position: 0
    }
  }

  componentDidUpdate(prevProps){
    if(!prevProps.isOpen && prevProps.isOpen !== this.props.isOpen){
      this.setState({
        position: this.props.position
      })
    }
  }

  onSubmit = async (e) =>{
    e.preventDefault();
    const { productPayloads } = this.props;
    let body = productPayloads? {...productPayloads}: {}
    this.props.onSubmit({ ...body, editors_position: this.state.position })
    this.props.toggle && this.props.toggle()
  }

  render() {
    return(
      <Modal isOpen={this.props.isOpen} toggle={this.props.toggle} size={this.props.size}>
        <ModalHeader toggle={this.props.toggle}>Set Position</ModalHeader>
        <ModalBody>
          <p>
            ID #{this.props.id} <br/>
            {this.props.displayName}.
            <Link style={{fontSize: '.75rem', fontStyle: 'italic'}} to={`/product_variants/${this.props.id || 0}`}>Click for details</Link>
          </p>
          <Form onSubmit={this.onSubmit}>
            <FormGroup>
              <Label for="formProductEditorsPosition">Hot Product Position</Label>
              <Input
                type="number"
                id="formProductEditorsPosition"
                name="position"
                placeholder="Enter a hot product's position"
                value={this.state.position}
                onChange={(e) => this.setState({ [e.target.name]: e.target.value })}
              />
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={this.onSubmit}>Submit</Button>{' '}
          <Button color="secondary" onClick={this.props.toggle}>Cancel</Button>
        </ModalFooter>
      </Modal>
    )
  }
}

export default ModalEditorsPosition;