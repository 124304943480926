import React from 'react';
import { connect } from 'react-redux';
import {
  Row,
  Col,
  Card,
  CardBody,
  CardText
} from 'reactstrap';
import PaginationList from '../../components/Paginations/PaginationList';
import { CardHeaderWithToolbars, Title } from '../../components/Card/CardHeaderWithToolbars';
import FilterContainer from '../../components/Container/FilterContainer/FilterContainer';
import privateView from '../../components/hocs/privateView';
import { doGetPendingDisbursementList, doReportDisbursement } from '../../actions/disbursementUserAction';
import { generateNumber } from '../../utils';
import { DEFAULT_SORT_BY, DEFAULT_PER_PAGE } from '../../constants/settings';
import ExportsPanel from './Exports';
import Toolbars from './Filters/Toolbars';
import FilterPanel from './pendingDisbursementList/FilterPanel';
import RowItem from './pendingDisbursementList/RowItem';
import Analytics from "../../services/Analytics";
import StatusFilter from './disbursementList/StatusFilter';

const dataHeader = [
  'No', 'Disbursement', 'Total Amount', 'Updated', 'Actions'
]

const defaultParams = {
  sort_by : DEFAULT_SORT_BY,
  per_page : DEFAULT_PER_PAGE
}

class PendingDisbursementList extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      _params: {
        ...defaultParams
      },
      _isResetFilter: false,
      _filterOpen: false,
      isOpenExport: false,
      _exportOpen: false
    }
    this._handleGoto = this._handleGoto.bind(this);
    this._handleExport = this._handleExport.bind(this);
    this._handleApplyFilter = this._handleApplyFilter.bind(this);
    this._handleToggleFilterPanel = this._handleToggleFilterPanel.bind(this);
    this._handleToggleExportPanel = this._handleToggleExportPanel.bind(this);
    this._handleResetFilter = this._handleResetFilter.bind(this);
    this._handleRefresh = this._handleRefresh.bind(this);
    this._handleRowItem = this._handleRowItem.bind(this);
  }

  componentDidMount(){
    const { email } = this.props.injectedProps;
    this._refreshList();
    Analytics.recordPageView('pending_disbursement_list', email, {
      url: window.location.href,
      location: this.props.location,
      match: this.props.match
    });
  }

  _getList(rawParams = {}){
    let params = {};
    Object.keys(rawParams)
    .filter(key => rawParams[key]!='')
    .forEach(key=> params[key] = rawParams[key]);
    this.props.getList({params});
  }

  _refreshList(){
    const {page, ..._params} = this.state._params;
    this.setState({
      _params
    },()=>this._getList(this.state._params));
  }

  _handleGoto(page){
    const _params=this.state._params;
    _params.page=page;
    this.setState({_params}, ()=>this._getList(this.state._params));
  }

  
  _toggleExportPanel(){
    const filterState = this.state._filterOpen?{_filterOpen:!this.state._filterOpen}:{};
    this.setState({
      ...filterState,
      _exportOpen:!this.state._exportOpen});
  }

  _handleToggleExportPanel(e){
    e.preventDefault();
    this._toggleExportPanel();
  }


  _handleExport(payload){
    this.props.doReport(payload);
  }

  _handleRefresh(e){
    e.preventDefault();
    this._refreshList();
  }

  _handleToggleFilterPanel(e){
    e.preventDefault();
    this.setState({
      _filterOpen:!this.state._filterOpen
    });
  }

  _handleResetFilter(e){
    e.preventDefault();
    const newState = this.state._filterOpen?{_filterOpen:!this.state._filterOpen}:{};
    this.setState({
      ...newState,
      _params:{...defaultParams},
      _isResetFilter: true
    },()=>this._getList(this.state._params));
  }

  _handleApplyFilter(payloadFilter){
    this.setState({
      _params: { status: 'NEW', ...payloadFilter }
    },()=>this._getList(this.state._params));
  }

  _handleRowItem(data, paginations){
    const { currentPage, perPage } = paginations;
    return data.map((item, index) => {
      return(
        <RowItem
          key={item.id}
          id={item.id}
          number={generateNumber(perPage, currentPage, index)}
          disbursementNumber={item.disbursement_number}
          disbursementType={item.type}
          payoutType={item.payout_type}
          referenceNo={item.reference_no}
          disbursementItemsCount={item.disbursement_items_count}
          disbursementBank={item.disbursement_bank}
          userId={item.disburse_to&&item.disburse_to.id}
          userEmail={item.disburse_to&&item.disburse_to.email}
          totalAmount={item.total_amount}
          balanceSource={item.balance_source}
          updatedAt={item.updated_at}
          createdAt={item.created_at}
        />
      )
    })
  }

  render(){
    return(
      <div className="animated fadeIn">
        <Row>
          <Col xs={12} style={{padding:0}}>
            <Card>
              <CardHeaderWithToolbars>
                <Title>
                  <i className="fa fa-align-justify"></i>
                  <span>Pending Disbursement List</span>
                </Title>
                <Toolbars
                  isOpen={this.state._filterOpen}
                  onToggleFilter={this._handleToggleFilterPanel}
                  onRefreshList={this._handleRefresh}
                  onToggleExport={this._handleToggleExportPanel}
                  onResetFilter={this._handleResetFilter}
                />
              </CardHeaderWithToolbars>
              <FilterContainer>
                <FilterPanel
                  {...this.state._params}
                  isOpen={this.state._filterOpen}
                  onApplyFilter={this._handleApplyFilter}
                  isReset={this.state._isResetFilter}
                  onResetFilterCallback={()=>this.setState({_isResetFilter: false, _filterOpen: false})}
                />
              </FilterContainer>
              <FilterContainer>
                <ExportsPanel
                  onHandleExport={this._handleExport}
                  isOpenExport={this.state._exportOpen}
                  data={this.props.disbursementReport}
                />
              </FilterContainer>
              <CardBody>
                <CardText>
                  Your pending disbursements in this section. Please validate correctly.
                </CardText>
                <PaginationList
                  headerRow={dataHeader}
                  statusCode={this.props.pendingList.status_code}
                  data={this.props.pendingList.data}
                  from={this.props.pendingList.from}
                  to={this.props.pendingList.to}
                  total={this.props.pendingList.total}
                  currentPage={this.props.pendingList.current_page}
                  lastPage={this.props.pendingList.last_page}
                  perPage={this.props.pendingList.per_page}
                  isLoading={this.props.pendingList.isFetch}
                  renderRowItem={this._handleRowItem}
                  handleGotoList={this._handleGoto}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    )
  }
}

const mapStateToProps= ({ disbursementUser: { pendingList, disbursementReport }, auth:{isFetch, isLogged, roles, id, email}}) => ({
  pendingList,
  guardData: {isFetch, isLogged, roles, id, email},
  disbursementReport
});

const mapDispatchToProps = (dispatch) => ({
	getList:(query) => dispatch(doGetPendingDisbursementList(query)),
  doReport:(query) => dispatch(doReportDisbursement(query))
});
const enhance = connect(mapStateToProps,mapDispatchToProps);
export default enhance(privateView(PendingDisbursementList));
