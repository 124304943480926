import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Row,
  Col,
  Card,
  CardBody,
  CardText
} from 'reactstrap';
import { doGetCompletedProcessedList, doDisburseSellerSale, doUpdateAwbNumberSale, manualDisburse, reportSale } from '../../../actions/saleAction';
import { DEFAULT_SORT_BY, DEFAULT_PER_PAGE } from '../../../constants/settings';
import privateView from '../../../components/hocs/privateView';
import { CardHeaderWithToolbars,Title } from '../../../components/Card/CardHeaderWithToolbars';
import { ModalConfirmation } from '../../../components/Modals';
import FilterContainer from '../../../components/Container/FilterContainer/FilterContainer';
import ExportsPanel from './Exports';

import ProcessedList from './ProcessedList';
import FiltersPanel from '../Filters';
import Toolbars from './processedList/filterPanel/Toolbars';
import Analytics from "../../../services/Analytics";

const defaultParams = {
  type:'processed',
  sort_by : DEFAULT_SORT_BY,
  per_page : DEFAULT_PER_PAGE
}

class Processed extends Component{
  constructor(props){
    super(props);
    this.state = {
      _pagination:{
        perPage: 0,
        from: 0,
        to: 0,
        total: 0,
        lastPage: 0,
        nextPageUrl: null,
        path: null,
        prevPageUrl: null
      },
      _params: { ...defaultParams },
      _filterOpen: false,
      _exportOpen: false,
      _isResetFilter: false
    }
    this._handleGoto = this._handleGoto.bind(this);
    this._handleToggleFilterPanel = this._handleToggleFilterPanel.bind(this);
    this._handleToggleExportPanel = this._handleToggleExportPanel.bind(this);
    this._handleRefresh = this._handleRefresh.bind(this);
    this._handleResetFilter = this._handleResetFilter.bind(this);
    this._handleExport = this._handleExport.bind(this);
    this._handleApplyFilter = this._handleApplyFilter.bind(this);
    this._handleCategoryChanged = this._handleCategoryChanged.bind(this);
    this._handleDisburseJob = this._handleDisburseJob.bind(this);
  }

  componentDidMount(){
    const { email } = this.props.injectedProps;
    this._refreshList();
    Analytics.recordPageView('processed_sales', email, {
      url: window.location.href,
      location: this.props.location,
      match: this.props.match
    });
  }

  _handleGoto(page){
    const _params=this.state._params;
    _params.page=page;
    this.setState({_params}, ()=>this._getList(this.state._params));
  }

  _getList(rawParams = {}){
    let p = { ...rawParams }
    if(p.voucher){
      const { voucher, ...other } = p;
      if(Number(voucher.id)){
        p = { ...other, voucher_id: voucher.id }
      }
      else{
        p = { ...other, voucher_code: voucher.code }
      }
    }
    if(p.seller && p.seller.id){
      const { seller, ...other } = p;
      p = { ...other, seller_id: seller.id }
    }
    const params = Object.keys(p)
    .filter(key => p[key]!='')
    .reduce((obj, current)=> ({...obj, [current]: p[current]}), {});
    this.props.getList({params});
  }

  _refreshList(){
    // Exclude page...
    const { page, ..._params } = this.state._params;
    this.setState({
      _params:{ ..._params }
    },()=>this._getList(this.state._params));
  }

  componentDidUpdate(lastProps){
    if(lastProps.sale.isFetch && lastProps.sale.isFetch !== this.props.sale.isFetch){
      const { error } = this.props.sale;
      if(!error){
        const { list } = this.props.sale;
        this.setState({
          // _list: data,
          _pagination:{
            perPage: list.per_page,
            from: list.from,
            to: list.to,
            total: list.total,
            lastPage: list.last_page,
            currentPage: list.current_page,
            nextPageUrl: list.next_page_url,
            path: list.path,
            prevPageUrl: list.prev_page_url,
            statusCode: list.status_code
          }
        })
      }
    }
  }

  _handleToggleFilterPanel(e){
    e.preventDefault();
    this._toggleFilterPanel();
  }

  _handleToggleExportPanel(e){
    e.preventDefault();
    this._toggleExportPanel();
  }

  _toggleFilterPanel(){
    const exportState = this.state._exportOpen?{_exportOpen:!this.state._exportOpen}:{};
    this.setState({
      ...exportState,
      _filterOpen:!this.state._filterOpen
    })
  }

  _toggleExportPanel(){
    const filterState = this.state._filterOpen?{_filterOpen:!this.state._filterOpen}:{};
    this.setState({
      ...filterState,
      _exportOpen:!this.state._exportOpen});
  }

  _handleRefresh(e){
    e.preventDefault();
    this._refreshList();
  }

  _handleExport(payload){
    payload.date_field = 'ka_sent'
    this.props.reportSale(payload);
  }

  _handleDisburseJob(e) {
    e.preventDefault()
    this.confirmationToggle();
  }

  _handleResetFilter(e){
    e.preventDefault();
    const exportState = this.state._exportOpen?{_exportOpen:!this.state._exportOpen}:{};
    this.setState({
      ...exportState,
      _params:{
        ...defaultParams
      },
      _isResetFilter: true
    },()=>this._getList(this.state._params));
  }

  _handleApplyFilter(payloadFilter){
    this.setState({
      _params: {
        ...this.state._params,
        ...payloadFilter,
        type: 'processed',
        page: 1
      }
    },()=>this._getList(this.state._params));
  }

  async _handleCategoryChanged(category){
    await this.setState({
      _params: {
        ...this.state._params,
        category: category === ''? category: category.value,
        page: 1,
      }
    });
    const { _params } = this.state;
    this._getList(_params);
  }

  confirmationToggle = () => {
    this.setState({ isConfirmationOpen: !this.state.isConfirmationOpen })
  }

  render(){
    return (
      <div className="animated fadeIn">
        <ModalConfirmation
          isOpen={this.state.isConfirmationOpen}
          onSubmit={() => {
            this.props.manualDisburse()
            this._refreshList();
            this.confirmationToggle();
          }}
          toggle={this.confirmationToggle}
        />
        <Row>
          <Col xs={12}>
            <Card>
              <CardHeaderWithToolbars>
                <Title>
                  <i className="fa fa-align-justify"></i>
                  <span>Processed List</span>
                </Title>
                <Toolbars
                  isOpen={this.state._filterOpen}
                  isOpenExport={this.state._exportOpen}
                  onToggleFilter={this._handleToggleFilterPanel}
                  onToggleExport={this._handleToggleExportPanel}
                  onRefreshList={this._handleRefresh}
                  onResetFilter={this._handleResetFilter}
                  onDisburse={this._handleDisburseJob}
                />
              </CardHeaderWithToolbars>
              <FilterContainer>
                <FiltersPanel
                  {...this.state._params}
                  isOpen={this.state._filterOpen}
                  onApplyFilter={this._handleApplyFilter}
                  isReset={this.state._isResetFilter}
                  onResetFilterCallback={()=>this.setState({_isResetFilter: false, _filterOpen: false})}
                />
              </FilterContainer>
              <FilterContainer>
                <ExportsPanel
                  onHandleExport={this._handleExport}
                  isOpenExport={this.state._exportOpen}
                  data={this.props.saleReport}
                  categoryOptions={this.props.categoryOptions}
                />
              </FilterContainer>
              <CardBody>
                <CardText>
                  All orders after delivering process. Increase seller's points and balance, when you click Processed button.
                </CardText>
                <ProcessedList
                  {...this.state._pagination}
                  loginId={this.props.injectedProps.id}
                  dataUpdatedSale={this.props.updatedSale}
                  list={this.props.sale.list.data}
                  isLoading={this.props.sale.isFetch}
                  handleGotoList={this._handleGoto}
                  handleCompletionSale={(id, payload) => this.props.completingSale(id, payload)}
                  handleUpdateAwbNumber={(id, payload)=> this.props.updateAwbNumber(id, payload)}
                  categoryValue={this.state._params.category}
                  onCategoryChanged={this._handleCategoryChanged}
                  email={this.props.email}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    )
  }
}

const mapStateToProps= ({ sale:{ processed, report_sale, ...other }, auth:{id: authId, isFetch, isLogged, roles, email}, masterCategories}) => {
	return {
    sale: processed.completed,
    saleReport: report_sale,
    updatedSale: other,
    email,
    guardData: {isFetch, isLogged, roles, id: authId, email},
    categoryOptions: masterCategories
	}
};

const mapDispatchToProps = (dispatch) => ({
  getList:(query) => dispatch(doGetCompletedProcessedList(query)),
  completingSale:(id,payload) => dispatch( doDisburseSellerSale(id,payload)),
  updateAwbNumber:(id,payload) => dispatch( doUpdateAwbNumberSale(id,payload)),
  manualDisburse: () => dispatch( manualDisburse() ),
  reportSale:(payload) => dispatch(reportSale(payload))
})
const enhance = connect(mapStateToProps, mapDispatchToProps);

export default enhance(privateView(Processed, ['supervisor','sale_supervisor']));