import React from 'react';
import propTypes from 'prop-types';
import Select from 'react-select';
import api from '../../../../utils/Api';
import { AUTH_TOKEN_KEY } from '../../../../constants';

class SelectBrand extends React.Component{
  constructor(props){
    super(props);
    this.loadSelectOptions = this.loadSelectOptions.bind(this);
    this._getBrandOptions = this._getBrandOptions.bind(this);
  }

  cache = {}

  loadSelectOptions(input){
    return this.refs[this.props.id].loadOptions(input);
  }

  purgeCache = () => {
    Object.keys(this.cache).forEach(entry => {
      delete this.cache[entry]
    })
  }

  _getBrandOptions(input){
    const myToken = localStorage.getItem(AUTH_TOKEN_KEY);
    const headers = {
      'Authorization': `Bearer ${myToken}`
    };
    const params = {
      keyword: input,
      active: 1,
      no_limit: 1,
      ...this.props.paramsApi
    }
    return api(headers).get('/admins/brands', { params })
    .then(({ data }) => {
      const brands = data.data.data.map(item=>{
        return {
          id: item.id,
          name: this.props.searchByName?item.name:item.name,
        }
      })
      if(this.props.onSelectSearch){
        this.props.onSelectSearch(input)
      }
      return{ options: brands };
    });
  }

  render(){
    return(
      <Select.Async
        valueKey="id"
        labelKey="name"
        ref={this.props.id}
        loadOptions={this._getBrandOptions}
        cache={this.cache}
        id={this.props.id}
        name={this.props.name}
        value={this.props.value}
        placeholder={this.props.placeholder}
        noResultsText={this.props.noResultsText}
        onChange={this.props.onSelectChange}
        onOpen={this.props.onSelectOpen}
        multi={this.props.isMulti}
      />
    )
  }
}

export default SelectBrand;

SelectBrand.defaultProps = {
  cacheOptions: false,
  searchByName: false,
  value: null,
  placeholder: 'Select a product...',
  noResultsText: 'No Result!',
  paramsApi: {}
}

SelectBrand.propTypes = {
  id: propTypes.string.isRequired,
  name: propTypes.string,
  value: propTypes.object,
  placeholder: propTypes.string,
  noResultsText: propTypes.string,
  cacheOptions: propTypes.bool,
  searchByName: propTypes.bool,
  paramsApi: propTypes.object,
  onSelectSearch: propTypes.func,
  onSelectChange: propTypes.func,
  onSelectOpen: propTypes.func
}