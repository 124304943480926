import React from 'react';
import Loading from 'react-loading-animation';
import AlertNotFound from '../Card/AlertNotFound';

const TblBody = ({children,emptyRowText="No Record Found!",isLoad=false,hasRow=false,columnCount, renderChildren})=>{
  return (
    <tbody>
      {
        isLoad?
        <tr>
          <td colSpan={columnCount}>
            <Loading/>
          </td>
        </tr>
        :
        ( 
          !hasRow?
          <tr>
            <td colSpan={columnCount}>
              <AlertNotFound message={emptyRowText}/>
            </td>
          </tr>
          :
          (renderChildren? renderChildren() : children)
        )
      }
    </tbody>
  )
}
export default TblBody;