import React from 'react';
import {
  Form,
  FormGroup,
  Label,
  Input } from 'reactstrap';
import propTypes from 'prop-types';

import { perPageOptions } from '../../../constants/kickavenue';
import { DISBURSEMENT_BALANCE_IN, DISBURSEMENT_BALANCE_OUT } from '../helpers';

const sortOptions = [{
    value:"" , label:"Default"
  },
  {
    value:"updatedAt_desc" , label:"Last Updated"
  },
  {
    value:"createdAt_desc" , label:"Newest"
  },
  {
    value:"createdAt_asc" , label:"Older"
  },
  {
    value:"email_asc" , label:"A-Z"
  },
  {
    value:"email_desc" , label:"Z-A"
  },
  {
    value:"totalAmount_desc" , label:"Highest Amount"
  },
  {
    value:"totalAmount_asc" , label:"Lowest Amount"
  }];

const SortForm = props => (
  <Form inline className="form-filter">
    <FormGroup className="mb-2 mr-3">
      <Label className="mr-sm-3">
        Type
      </Label>
      <Input
        type="select"
        name="disbursementType"
        onChange={props.onInputChange}
        value={props.disbursementType}
      >
        <option value="">All</option>
        <option value={DISBURSEMENT_BALANCE_IN}>Balance In</option>
        <option value={DISBURSEMENT_BALANCE_OUT}>Balance Out</option>
      </Input>
    </FormGroup>
    <FormGroup className="mb-2 mr-3">
      <Label className="mr-sm-3">
        Show
      </Label>
      <Input
        type="select"
        name="perPage"
        onChange={props.onInputChange}
        value={props.perPage}
      >
        {perPageOptions.map((option,i)=><option value={option.value} key={i}>{option.label}</option>)}
      </Input>
    </FormGroup>
    <FormGroup className="mb-2 mr-3">
      <Label className="mr-sm-3">
        Sort By
      </Label>
      <Input
        type="select"
        name="sortBy"
        onChange={props.onInputChange}
        value={props.sortBy}
      >
        {sortOptions.map((option,i)=><option value={option.value} key={i}>{option.label}</option>)}
      </Input>
    </FormGroup>
  </Form>
)
export default SortForm;

SortForm.propTypes= {
  perPage: propTypes.oneOfType([propTypes.number, propTypes.string]),
  sortBy: propTypes.string,
  onInputChange: propTypes.func.isRequired
}

SortForm.defaultProps= {
  perPage: 15,
  sortBy: '',
  disbursementType: ''
}