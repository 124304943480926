import {
  POP_UP_BANNER_ADD,
  POP_UP_BANNER_FETCHING,
  POP_UP_BANNER_ERROR,
  POP_UP_BANNER_LIST_FETCH,
  POP_UP_BANNER_DETAIL_FETCH,
  POP_UP_BANNER_UPDATE,
  POP_UP_BANNER_DELETE
} from '../constants';

const initialState={
	list:[],
	detail: {},
  isFetch:false,
  isSubmit:false,
  success:null,
  error:null,
  delete: false
}


const onFetching = (lastState,payload) => {
  if(payload.type==='fetch')
    return { ...lastState, isFetch:payload.status, success: null, error: null }
  else if(payload.type==='submit')
    return { ...lastState, isSubmit:payload.status }

  return { ...lastState, isFetch:false,isSubmit:false }
}

const reducer=(state=initialState, action)=>{
  switch(action.type){
    case POP_UP_BANNER_LIST_FETCH: {
      const {data, status_code, message}=action.payload;
      return {...state, list:{...data, message, status_code}, detail: {}, error:null}
    }
    case POP_UP_BANNER_DETAIL_FETCH:{
      const {data, status_code, message}=action.payload;
      return { ...state, detail:{...state.detail, ...data, message, status_code,deleted:false}, error:null }
    }
    case POP_UP_BANNER_ADD : {
      const {data, status_code, message}=action.payload;
      return {...state, detail:{...state.detail,...data, message, status_code}, success: message, error:null}
    }
    case POP_UP_BANNER_ERROR:
      return { ...state, ...action.payload, success: null }
    case POP_UP_BANNER_UPDATE : {
        const {data, status_code, message}=action.payload;
        return {...state, detail:{...state.detail,...data, message, status_code}, success: message, error:null}
    }
    case POP_UP_BANNER_DELETE : {
      const {data, status_code, message}=action.payload;
      return {...state, detail:{...state.detail,...data, message, status_code}, success: message, delete: true, error:null}
  }

    case POP_UP_BANNER_FETCHING:  return onFetching(state, action.payload);
    default:return state;
  }

}

export default reducer;