import React from 'react';
import moment from 'moment-timezone';
import { ValidationMessage } from '../components/Form/ValidationMessage';

moment.tz.setDefault("Asia/Jakarta");

export const textValue = (name, obj={})=> {
  return (
    (obj[name]===undefined
      ||obj[name]===null)?'':
      obj[name]
  )
}

export const textChange = (input,obj)=>{
  obj[input.target.name]=input.target.value;
  return obj;
}

export const checkChange = (input,obj)=>{
  obj[input.target.name]=input.target.checked;
  return obj;
}

export const dateValue = (name,obj)=>(
  (obj[name]===undefined
    ||obj[name]===null)?'':
    moment(obj[name])
)

export const dateChange = (momentValue, name, obj, format='YYYY-MM-DD HH:mm:00')=>{
  const dateParsed = moment.isMoment(momentValue)? momentValue: null;
  obj[name] = dateParsed!==null?
    dateParsed.format(format):dateParsed
  return {...obj};
}

export const parsedDate = (momentValue, format='YYYY-MM-DD HH:mm:00')=>{
  const dateParsed = moment.isMoment(momentValue)? momentValue: null;
  return dateParsed!==null?
      dateParsed.format(format):dateParsed;
}

export const hasError = (name, objHasError)=>{
  if(objHasError!==undefined && objHasError!==null){
    if(objHasError.errors!==undefined && objHasError.errors!==null){
      const errList=Object.keys(objHasError.errors).filter(key=> key==name);
      if(errList.length)
        return true
    }
  }
  return false
}

export const hasKeyError = (errors, propsName) => (
  errors && Object.keys(errors).find(key => key===propsName)
)

export const addErrorMessage = (errors, propsName, value) => (
  hasKeyError(errors, propsName) && errors[propsName]? [ ...errors[propsName], value ] : [ value ]
)

export const hasErrorArray = (array, objHasError) => {
  for(let i=0;i<array.length;i++){
    const isError = hasError(array[i], objHasError);
    if(isError){
      return isError;
    }
  }
  return false;
}

export const errorMessage = (name, objHasError) =>{
  if(hasError(name,objHasError)){
    const errorList=objHasError.errors;
    return <ValidationMessage message={errorList[name][0]}/>
  }
  return '';
}

export const errorMessageArray = (array, objHasError) =>{
  const errorMsgList = [];
  if(objHasError){
    const errorList=objHasError.errors;
    for(let i=0;i<array.length;i++){
      if(hasError(array[i], objHasError)){
        errorMsgList.push(<ValidationMessage key={`validation-message-${array[i]}-${i}`} message={errorList[array[i]][0]}/>);
      }
    }
  }
  return errorMsgList.length? errorMsgList: null;
}

export const _getValue = (value, defaultValue='') => value===null||value===undefined? defaultValue : value;

export const _getFloatValue = (value) => value===null||value===undefined? 0 : parseFloat(value);

export const _getGenderString = value => {
  switch (value) {
    case null:
    case 'M':
      return 'Men';
    case 'F':
      return 'Women';
    case 'U':
      return 'Unisex';
    case 'K':
      return 'Kids';    
    default:
      return value;
  }
}

type _getAdmFees = (pay:string, amount:number, shippingFee:number, discount:number) => number
export const getAdmFees:_getAdmFees = (paymentType, amount, shippingFee, discount)=>{
  return 0;
}
export const parsePhone = (phone, isRemoved = true, prefix='62') =>  {
  const regex = new RegExp(`(^0)|(^${prefix})|(^\\+${prefix})`);
  const parsed = phone.replace(regex, '');
  return isRemoved? parsed: `+${prefix}${parsed}`;
}

