import React from 'react';
import { connect } from 'react-redux';
import UserForm from './UserForm';
import { Container } from 'reactstrap';
import privateView from '../../components/hocs/privateView';
import Analytics from "../../services/Analytics";

const userEmailGranted = [
  'eko@kickavenue.com',
  'alwin@kickavenue.com',
  'rei@kickavenue.com',
  'sopyan@kickavenue.com',
  'nabila@kickavenue.com',
  'francoiseguinea@yahoo.com',
  'raynadya@kickavenue.com',
  'keefekh@yahoo.com'
  // 'administrator@kickavenue.com'
]
class UserDetail extends React.Component{
	componentDidMount(){
    const { email } = this.props.injectedProps;
    Analytics.recordPageView('user_detail', email, {
      url: window.location.href,
      location: this.props.location,
      match: this.props.match
    });
  }
  render(){
    return(
      <Container>
        <UserForm
          title="Detail User"
          {...this.props}
          _edit
          formScopes={{ update: userEmailGranted, delete: userEmailGranted }}
        />
      </Container>
    )
  }
}

const mapStateToProps=({auth:{ isFetch, isLogged, roles, id, email }})=>({
  guardData: { isFetch, isLogged, roles, id, email }
})
const enhance = connect(mapStateToProps);
export default enhance(privateView(UserDetail, ['customer_service']));