import React from 'react';
import propTypes from 'prop-types';
import { Button } from 'reactstrap';
import classNames from 'classnames';
import ImageDropzone from './ImageDropzone';
import { FontAwesomeIcon } from '../../../components/Icons';

const DropzoneWithButton = props => (  
  <div className="dropzone d-flex flex-column" style={{marginBottom:'1rem'}}>
    <ImageDropzone onDrop={props.onDrop}/>
    <Button 
      color="primary"
      onClick={props.onUploadImages} 
      className={classNames("mt-2 mb-2", {'d-none':props.totalUpload===0})}
    >
      <FontAwesomeIcon iconType="upload"/> Upload ({props.totalUpload})
    </Button>
  </div>
)

DropzoneWithButton.propTypes = {
  onDrop: propTypes.func,
  onUploadImages: propTypes.func,
  totalUpload: propTypes.number
}

DropzoneWithButton.defaultProps = {
  totalUpload: 0
}

export default DropzoneWithButton;