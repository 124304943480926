import React from 'react';
import { Button, Col, Container, Form, FormGroup, Input, Label, Row } from "reactstrap";
import {
    consignmentShippingMethodList,
    consignmentFailedStatusOptions,
} from "../../../../../constants/kickavenue";
import SelectRack from "../../../../../components/Form/Select/Async/SelectRack";
import { UserSelectAsync } from "../../../../Components/UserSelectAsync";

const filterShippingMethodOptions = [{ name: 'All', value: '' }, ...consignmentShippingMethodList];

export default function SearchForm(props) {
    const {
        keyword,
        onInputChange,
        seller,
        onSellerChange,
        shippingMethod,
        rack,
        onRackChange,
        isCheckedStatus,
        onStatusesInputChange,
        onSubmit,
        onSearch,
    } = props;

    const onApplyFilter = (e) => {
        e.preventDefault();
        onSubmit();
    };

    const onPressedSearch = (e) => {
        e.preventDefault();
        onSearch();
    };

    const onKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            onSearch();
        }
    };

    return (
        <Form inline>
            <Container style={{ padding: 0 }}>
                <Row className="mb-1">
                    <Col xs={10} sm={6}>
                        <FormGroup row className="mb-3">
                            <Col xs={12} sm={12}>
                                <Input
                                    className="w-100"
                                    type="text"
                                    placeholder="Search CG ID, SKU, or Product Name..."
                                    name="keyword"
                                    value={keyword}
                                    onChange={(e) => onInputChange(e, 'keyword')}
                                    onKeyDown={onKeyDown}
                                />
                            </Col>
                        </FormGroup>
                    </Col>
                    <Col xs={2} sm={6}>
                        <FormGroup row className="mb-3">
                            <Col xs={12}>
                                <Button color="primary" onClick={onPressedSearch}>Search</Button>
                            </Col>
                        </FormGroup>
                    </Col>
                </Row>
                <Row className="mb-1">
                    <Col xs={12} sm={6}>
                        <FormGroup className="mb-3">
                            <Col xs={12} sm={12}>
                                <Label className="justify-content-start mb-1">
                                    Seller
                                </Label>
                            </Col>
                            <Col xs={12} sm={12}>
                                <UserSelectAsync
                                    onChange={onSellerChange}
                                    value={seller}
                                />
                            </Col>
                        </FormGroup>
                    </Col>
                </Row>
                <Row className="mb-1">
                    <Col xs={12} sm={6}>
                        <FormGroup className="mb-3">
                            <Col xs={12}>
                                <Label className="justify-content-start mb-1">
                                    Shipping Method
                                </Label>
                            </Col>
                            <Col xs={12}>
                                <Input
                                    className="w-100"
                                    type="select"
                                    name="shippingMethod"
                                    onChange={(e) => onInputChange(e, 'shippingMethod')}
                                    value={shippingMethod}
                                >
                                    {filterShippingMethodOptions.map((option, i) => <option value={option.value} key={i}>{option.name}</option>)}
                                </Input>
                            </Col>
                        </FormGroup>
                    </Col>
                </Row>
                <Row className="mb-1">
                    <Col xs={12} sm={6}>
                        <FormGroup className="mb-3">
                            <Col xs={12}>
                                <Label className="justify-content-start mb-1">
                                    Rack
                                </Label>
                            </Col>
                            <Col xs={12}>
                                <SelectRack
                                    defaultOptions
                                    // id={filterRackId}
                                    // ref={filterRackRef}
                                    isMulti
                                    value={rack}
                                    paramsApi={{ limit: 50 }}
                                    placeholder="Type and select a rack..."
                                    noResultsText="Cannot find rack."
                                    onSelectChange={onRackChange}
                                />
                            </Col>
                        </FormGroup>
                    </Col>
                </Row>
                <Row className="mb-1">
                    <Col xs={12} sm={6}>
                        <FormGroup className="mb-3">
                            <Col xs={12}>
                                <Label className="justify-content-start mb-1">
                                    Status
                                </Label>
                                <Row className="ml-2 mr-2">
                                    <Label check className="mr-3">
                                        <Input
                                            type="checkbox"
                                            name="status"
                                            value="default"
                                            checked={isCheckedStatus('default')}
                                            onChange={(e) => onStatusesInputChange(e)}
                                        /> Default
                                    </Label>
                                    <Label check className="mr-3">
                                        <Input
                                            type="checkbox"
                                            name="status"
                                            value="all"
                                            checked={isCheckedStatus('all')}
                                            onChange={(e) => onStatusesInputChange(e)}
                                        /> All
                                    </Label>
                                    <div className="w-100"></div>
                                    {consignmentFailedStatusOptions.map((option, i) => (
                                        <Label check className="mr-3" key={i}>
                                            <Input
                                                type="checkbox"
                                                name="status"
                                                value={option.value}
                                                checked={isCheckedStatus(option.value)}
                                                onChange={(e) => onStatusesInputChange(e)}
                                            /> {option.label}
                                        </Label>
                                    ))}
                                </Row>
                            </Col>
                        </FormGroup>
                    </Col>
                </Row>
                <Row className="mb-1">
                    <Col xs={12}>
                        <FormGroup>
                            <Col xs={12}>
                                <Button color="primary" onClick={onApplyFilter}>Apply Filter</Button>
                            </Col>
                        </FormGroup>
                    </Col>
                </Row>
            </Container>
        </Form>
    )
}
