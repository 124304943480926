import React from 'react';
import propTypes from 'prop-types';
import classNames from 'classnames';
import styled from 'styled-components';
import Icon from '../../Icons/SimpleLineIcon';
const LoadingContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(255,255,255,0.7)
`
const CardSuccessUploadPlaceholder = ({ isVisible}) => (
  <LoadingContainer className={classNames({'d-none': !isVisible})}>
    <div className="loading-wrapper loading-wrapper--flex">
      <Icon iconType="cloud-upload" style={{fontSize: '4rem', color: 'green'}}/>
    </div>
  </LoadingContainer>
)

CardSuccessUploadPlaceholder.propTypes = {
  isVisible: propTypes.bool,
  loadingText: propTypes.string,
  loadingHeight: propTypes.number,
  loadingWidth: propTypes.number,
  loadingType: propTypes.string,
  loadingColor: propTypes.string
}

CardSuccessUploadPlaceholder.defaultProps = {
  isVisible: false,
  loadingColor: 'green',
  loadingHeight: 60,
  loadingWidth: 60,
  loadingType: 'spin'
}

export default CardSuccessUploadPlaceholder;
