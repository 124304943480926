import {
  RACK_REQUEST,
  RACK_ERROR,
  GET_LIST_RACK,
  GET_DETAIL_RACK,
  CREATE_RACK,
  UPDATE_RACK
} from '../constants';

const initialState = {
  list: {
    isFetch : false,
    data: [],
    error: null,
  },
  details: {
    isFetch: false,
    isSubmit: false,
    user_sells_count:0,
    error:null
  }
}

const onFetched = (lastState, { requestType, value }) => {
  switch(requestType){
    case GET_LIST_RACK: return {...lastState, list: {...lastState.list, isFetch:value}}
    case CREATE_RACK: 
    case GET_DETAIL_RACK: 
    case UPDATE_RACK: {
      return {...lastState, details: { ...lastState.details, isSubmit: value }}
    }
    default: return lastState;
  }
}

const onError = (lastState, { requestType, error, ...rest }) => {
  switch(requestType){
    case GET_LIST_RACK: return {...lastState, list: {...lastState.list, error:{...error, ...rest}}};
    case CREATE_RACK: 
    case GET_DETAIL_RACK: 
    case UPDATE_RACK: {
      return {...lastState, details: { ...lastState.details, error:{...error, ...rest}}}
    }
    default: return lastState;
  }
}

const reducer = (state = initialState, { type, payload }) => {
  switch(type){
    case RACK_REQUEST : return onFetched(state, payload);
    case RACK_ERROR : return onError(state, payload);
    case GET_LIST_RACK : {
      return {
        ...state,
        list: {
          ...state.list,
          ...payload,
          error: null,
        }
      }
    }
    case UPDATE_RACK:
    case GET_DETAIL_RACK:
    case CREATE_RACK:{
      const user_sells_count=state.details.data&&state.details.data.user_sells_count?state.details.data.user_sells_count:state.details.user_sells_count;
      return {
        ...state,
        details: {
          ...state.details,
          ...payload,
          user_sells_count:user_sells_count,
          error: null
        }
      }
    }
    default: return state;
  }
}

export default reducer;