import React from 'react';
import {
  ButtonGroup, 
  Button
} from 'reactstrap';
import propTypes from 'prop-types';

const RowItem = props => {
  return (
    <tr>
      <th className="v-center" scope="row">
        { props.rowId }
      </th>
      <td className="v-center">
        { props.bankName }
      </td>
      <td className="v-center">
        { props.accountNumber }
      </td>
      <td className="v-center">
        { props.accountName }
      </td>
      <td className="v-center">
        { props.updatedAt }
      </td>
      <td className="v-center">
      {"-"}
        {/* <ButtonGroup>
          <Button color="primary" onClick={props.onUpdateBtnClick} title="Update Bank Account">Update</Button>
          <Button color="danger" onClick={props.onDeleteBtnClick} title="Delete Bank Account">Delete</Button>
        </ButtonGroup> */}
      </td>
    </tr>  
  )
}
export default RowItem;
RowItem.defaultProps = {
  rowId: 0
}
RowItem.propTypes = {
  rowId: propTypes.number,
  id: propTypes.number.isRequired,
  bankName: propTypes.string,
  accountName: propTypes.string,
  accountNumber: propTypes.string,
  updatedAt: propTypes.string,
  onUpdateBtnClick: propTypes.func.isRequired,
  onDeleteBtnClick: propTypes.func.isRequired
}