import React from 'react';
import propTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Badge } from 'reactstrap';
import { TextRupiah } from '../../../components/Text';
import DateToHuman from '../../../components/DateToHuman';
import { Th } from '../../../components/Tbl';
import { FontAwesomeIcon } from '../../../components/Icons';
import DisbursementColumn from '../RowItem/DisbursementColumn';
import { DISBURSEMENT_BALANCE_IN, DISBURSEMENT_BALANCE_OUT, getBalanceSource } from '../helpers';

const RowItem = (props) => (
  <tr>
    <Th className="v-center" scope="row">{ props.number }</Th>
    <td className="v-center">
      <DisbursementColumn
        id={props.id}
        itemCount={props.disbursementItemsCount}
        bank={props.disbursementBank}
        items={props.disbursementItems}
        disbursementNumber={props.disbursementNumber}
        disbursementType={props.disbursementType}
        payoutType={props.payoutType}
        referenceNo={props.referenceNo}
        userId={props.userId}
        userEmail={props.userEmail}
        createdAt={props.createdAt}
      />
    </td>
    <td className="v-center">
      <TextRupiah prefix="Rp. " value={props.totalAmount}/>
      <Badge className="text-uppercase">{getBalanceSource(props.balanceSource)}</Badge>
    </td>
    <td className="v-center">{ <DateToHuman id={`pendingList_disbursements_updatedAt_${props.id}`} value={props.updatedAt}/> }</td>
    <td className="v-center">
      <Link className="btn btn-secondary" to={`/disbursements/${props.id}`} title="Details"><FontAwesomeIcon iconType="external-link"/> Details</Link>
    </td>
  </tr>
);

export default RowItem;

RowItem.defaultProps = {
  disbursementItemsCount: 0,
  disbursementType: DISBURSEMENT_BALANCE_IN
}

RowItem.propTypes = {
  number: propTypes.number.isRequired,
  id: propTypes.number.isRequired,
  disbursementNumber: propTypes.string,
  disbursementItemsCount: propTypes.number,
  disbursementType: propTypes.oneOf([DISBURSEMENT_BALANCE_IN, DISBURSEMENT_BALANCE_OUT]),
  userId: propTypes.number,
  userEmail: propTypes.string,
  createdAt: propTypes.string,
  updatedAt: propTypes.string,
  totalAmount: propTypes.oneOfType([propTypes.number, propTypes.string])
}
