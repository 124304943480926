import React from 'react';
import propTypes from 'prop-types';
import Select from 'react-select';

class SelectRackV2 extends React.Component {
  constructor(props) {
    super(props);
    this.loadSelectOptions = this.loadSelectOptions.bind(this);
  }

  loadSelectOptions(input) {
    const filteredOptions = this.props.rackOptions?.filter(option =>
      option.name.toLowerCase().includes(input.toLowerCase())
    );
    return Promise.resolve({ options: filteredOptions });
  }

  render() {
    return (
      <Select.Async
        key={this.props.key}
        valueKey="id"
        labelKey="name"
        defaultOptions={this.props.rackOptions}
        ref={this.props.id}
        loadOptions={this.loadSelectOptions}
        cacheOptions={this.props.cacheOptions}
        name={this.props.name}
        value={this.props.value}
        placeholder={this.props.placeholder}
        noResultsText={this.props.noResultsText}
        onChange={this.props.onSelectChange}
        onOpen={this.props.onSelectOpen}
        isClearable
        isMulti={this.props.isMulti}
      />
    );
  }
}

export default SelectRackV2;

SelectRackV2.defaultProps = {
  cacheOptions: false,
  isAvailable: false,
  value: null,
  placeholder: 'Select a rack...',
  noResultsText: 'No Result!',
  isMulti: false,
};

SelectRackV2.propTypes = {
  name: propTypes.string,
  value: propTypes.oneOfType([propTypes.object, propTypes.array, propTypes.string]),
  placeholder: propTypes.string,
  noResultsText: propTypes.string,
  cacheOptions: propTypes.bool,
  isMulti: propTypes.bool,
  onSelectSearch: propTypes.func,
  onSelectChange: propTypes.func.isRequired, 
  onSelectOpen: propTypes.func,
  rackOptions: propTypes.array.isRequired
}
