import React from 'react';
import moment from 'moment-timezone';
import {
  Col,
  Card,
  CardText,
  Alert,
  Button,
  CardBody,
  ButtonGroup,
  CardHeader,
} from 'reactstrap';
import { Tbl, TblHead, TblBody } from '../../components/Tbl';
import { generateNumber } from '../../utils';
import { ModalCommission }  from './Modals/ModalCommission';
import { TextRupiah } from '../../components/Text';
import { ModalDelete } from '../../components/Modals';

const dataHeader = ['No. ', 'Commission', 'Max Amount', 'Action'];
moment.tz('Asia/Jakarta');
export default class Commission extends React.Component{
  constructor(props){
    super(props);
    this.state={
      _params:{
        variant_id: props.productVariantId,
        per_page: 5
      },
      _error:null,
      _selectedId:null,
      _modalDelete:false,
      _modalCommission:false,
      _commissionSelected:null,
      _selectedRow:null,
      _isAlertErrorOpen:false
    }
    this._toggle = this._toggle.bind(this);
    this._onRemoveSelected=this._onRemoveSelected.bind(this);
    this._handleModalDeleteToggle = this._handleModalDeleteToggle.bind(this);
    this._handleDeleteCommission = this._handleDeleteCommission.bind(this);
    this._handleAlertErrorToggle = this._handleAlertErrorToggle.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { dataCommission, productVariantId } = this.props;
    if(this.state._modalDelete&&dataCommission.isSubmit!==prevProps.dataCommission.isSubmit
      &&dataCommission.isSubmit===false){
        if(dataCommission.isDelete){
          this.setState({
            _modalDelete:false,
            _selectedId:null,
            _params:{
              ...this.state._params,
              page: 1
          }}, ()=>{
            this._onRemoveSelected();
            this.props.getCommissionList(productVariantId);
          });
        }
    }
    if(dataCommission.isSubmit!==prevProps.dataCommission.isSubmit
      &&dataCommission.isSubmit===false){
        const { error } = dataCommission;
        if(error)
          this.setState({_modalDelete:false, _error:{...dataCommission.error}}, ()=>this._handleAlertErrorToggle());
    }
  }

  _onRemoveSelected(){
    this.setState({
      _isAlertErrorOpen:false,
      _commissionSelected:null,
      _selectedRow:null,
      _error:null
    });
  }

  _toggle() {
    this.setState({
      _modalCommission: !this.state._modalCommission
    });
  }

  _handleAlertErrorToggle(){
    this.setState({
      _isAlertErrorOpen: !this.state._isAlertErrorOpen
    })
  }

  _handleDeleteCommission(){
    const { _selectedId } = this.state;
    this.props.onDelete(_selectedId);
  }

  _handleModalDeleteToggle(item){
    this.setState({
      _isAlertErrorOpen:false,
      _commissionSelected: item? item: null,
      _selectedId:item? item.id: null,
      _modalDelete: !this.state._modalDelete
    });
  }

  _onOpenModal(item){
    this.setState({_isAlertErrorOpen:false, _commissionSelected: item, _selectedRow: item.id},()=>this._toggle());
  }

  _renderButtonAction(item){
    return(
      <ButtonGroup>
        <Button color="success" onClick={()=>this._onOpenModal(item)}><i className="icon-pencil"></i></Button>
        <Button color="danger" onClick={()=>this._handleModalDeleteToggle(item)}><i className="icon-trash"></i></Button>
      </ButtonGroup>
    )
  }

  _onRenderRowItem(){
    if(this.props.dataCommission.status_code===200&&this.props.dataCommission.list.length>0)
    {
      const { list } =  this.props.dataCommission;
      return list.map((item,index)=>(
          <tr key={generateNumber(1,1,index)}>
            <th scope="row" className="v-center">{ generateNumber(1,1,index) }</th>
            <td className="v-center">
              {item.commission_amount}
            </td>
            <td className="v-center">
              <p className="mb-0" style={{fontSize: '.75rem'}}>
                <b>{item.max_amount ? <TextRupiah prefix="Rp. " value={item.max_amount}/>:'-'}</b>
                start: {item.started_at? moment(item.started_at).format('DD MMM YY hh:mm a'):'always open'} <br/>
                end: {item.ended_at? moment(item.ended_at).format('DD MMM YY hh:mm a'):'no expiry'}
              </p>
              <small>#{item.id} <i className="fa fa-calendar"></i> {item.updated_at}</small>
            </td>
            <td className="v-center">{ this._renderButtonAction(item) }</td>
          </tr>
        )
      )
    }
    return null;
  }

  _renderErrorMsg(){
    const { _isAlertErrorOpen, _error } = this.state;
    if(_isAlertErrorOpen && _error!==null){
      switch(_error.status_code){
        case 422:
          return <Alert color="danger" isOpen={_isAlertErrorOpen} toggle={this._handleAlertErrorToggle}>Please fulfill your form</Alert>
        default:
          return <Alert color="danger" isOpen={_isAlertErrorOpen} toggle={this._handleAlertErrorToggle}>Houston, we have a problem!. Please inform our friendly staff via LINE @kickavenue</Alert>
      }
    }
    return null;
  }

  render(){
    return(
      <Card>
      <Col xs={12}>
        <ModalDelete
          isOpen={this.state._modalDelete}
          toggle={this._handleModalDeleteToggle}
          onDelete={this._handleDeleteCommission}
        />
        <ModalCommission
          isOpen={this.state._modalCommission}
          onCancel={this._toggle}
          onRemoveSelected={this._onRemoveSelected}
          dataCommission={this.props.dataCommission}
          variantId={this.props.productVariantId}
          onSubmit={this.props.saveCommission}
          commissionSelected={this.state._commissionSelected}
          onGetCommissionList={async () => {
            this.props.getCommissionList(this.props.productVariantId)
          }}
        />
      </Col>
      <CardHeader>
        <i className="fa fa-align-justify"></i> Selling Commission
      </CardHeader>
      <CardBody>
        {
          this.props.dataCommission.list.length==0?
          <div className="mb-3">
           <Button title="New Commission" onClick={this._toggle}><i className="fa fa-plus"></i> New Commission</Button>
          </div>
          :
          null
        }
        { this._renderErrorMsg() }
        <div className="table-responsive">
          <Tbl>
            <TblHead
                dataHeader={dataHeader}
            />
            <TblBody
              isLoad={this.props.dataCommission.isFetch}
              hasRow={this.props.dataCommission.status_code===200&&this.props.dataCommission.list.length>0}
              columnCount={dataHeader.length}
            >
              { this._onRenderRowItem() }
            </TblBody>
          </Tbl>
        </div>
      </CardBody>
    </Card>
    )
  }
}