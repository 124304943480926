/* eslint-disable no-sequences */
import {
  ADD_BANK_PROMO,
  AUTH_TOKEN_KEY,
  BANK_PROMO_ERROR,
  BANK_PROMO_ROUTE_API,
  BANK_PROMO_SET_DELETE_MODAL_OPEN,
  DELETE_BANK_PROMO,
  DELETTING_BANK_PROMO,
  FETCHING_BANK_PROMOTION,
  GET_BANK_PROMO_BY_ID,
  GET_LIST_BANK_PROMO,
  SUBMITTING_BANK_PROMOTION,
  UPDATE_BANK_PROMO,
} from "../constants";

import { logout } from "./authAction";
import { manipulateErrors, checkAuthorized } from "../utils";
import api from "../utils/Api";

export const getBankPromoList = (params) => {
  return (dispatch) => (
    dispatch(isFetch({ status: true })),
    api({
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`,
    }).get(BANK_PROMO_ROUTE_API, { params })
      .then((res) => dispatch(onSuccess(res.data, GET_LIST_BANK_PROMO)))
      .catch((err) => dispatch(onError(err)))
      .finally(() => dispatch(isFetch({ status: false })))
  );
};

export const addBankPromo = (payload) => {
  return (dispatch) => (
    dispatch(isSubmit({ status: true })),
    api({
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`,
      "Content-Type": "multipart/form-data"
    }).post(BANK_PROMO_ROUTE_API, payload)
      .then((res) => dispatch(onSuccess(res, ADD_BANK_PROMO)))
      .catch((err) => dispatch(onError(err)))
      .finally(() => dispatch(isSubmit({ status: false })))
  );
};

export const getBankPromoById = (id) => {
  return (dispatch) => (
    dispatch(isFetch({ status: true })),
    api({
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
    }).get(`${BANK_PROMO_ROUTE_API}/${id}`)
      .then((res) => {
        dispatch(onSuccess(res.data, GET_BANK_PROMO_BY_ID));
      })
      .catch((err) => {
        dispatch(onError(err));
      })
      .finally(() => {
        dispatch(isFetch({ status: false }));
      })
  );
};

export const updateBankPromo = (payload, id) => {
  return (dispatch) => (
    dispatch(isSubmit({ status: true })),
    api({
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
    }).post(`${BANK_PROMO_ROUTE_API}/${id}`, payload)
      .then((res) => dispatch(onSuccess(res.data, UPDATE_BANK_PROMO)))
      .catch((err) => dispatch(onError(err)))
      .finally(() => dispatch(isSubmit({ status: false })))
  );
};

export const deleteBankPromo = (id) => {
  return (dispatch) => (
    dispatch(isDelete({ status: true })),
    api({
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
    }).delete(`${BANK_PROMO_ROUTE_API}/${id}`)
      .then((res) => dispatch(onSuccess(res, DELETE_BANK_PROMO)))
      .catch((err) => dispatch(onError(err)))
      .finally(() => dispatch(isDelete({ status: false })))
  );
};

export const setBankPromoDeleteModalOpen = (value) => (dispatch) =>
  dispatch({ type: BANK_PROMO_SET_DELETE_MODAL_OPEN, value });

export const isFetch = (payload) => ({
  type: FETCHING_BANK_PROMOTION,
  payload: payload,
});

export const isSubmit = (payload) => ({
  type: SUBMITTING_BANK_PROMOTION,
  payload: payload,
});

export const onSuccess = (res, type) => ({
  type: type,
  payload: res,
});

export const isDelete = (payload) => ({
  type: DELETTING_BANK_PROMO,
  payload,
});

export const onError = (err) => {
  if (!checkAuthorized(manipulateErrors(err))) {
    return logout();
  }

  return {
    type: BANK_PROMO_ERROR,
    payload: manipulateErrors(err),
  };
};
