import React from 'react';
import {
  Alert,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input, 
  Button,
  Row,
  Col
} from 'reactstrap';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import SelectUserAsync from '../../../../components/Form/Select/Async/SelectUser';
import Radio from '../../../../components/Form/Radios/Radio';
import InputDatetime from '../../../../components/Form/InputDatetime/InputDatetime';
import { hasError, errorMessage, textChange, textValue, dateValue, dateChange, checkChange } from '../../../../utils/form';

export default class ModalCreateRaffleEntry extends React.Component{
  constructor(props){
    super(props);
    moment.tz.setDefault("Asia/Jakarta");
    this.state={
      id:null,
      user:[],
      variant_id: null,
      raffle_group_id: this.props.raffleGroupId || null,
      raffle_id: null,
      raffle_request_id: null,
      size_id: null,
      short_note: '',
      golden: false,
      expiry: null,
      quantity:1,
      isSubmit:false,
      isAletError:false,
      error:null,
      _recentUserSearch: null
    }
    this.handleCheckedChange=this.handleCheckedChange.bind(this);
    this._handleOnSelectUserChange = this._handleOnSelectUserChange.bind(this);
    this._handleOnSelectUserOpen = this._handleOnSelectUserOpen.bind(this);
    this.handleChange=this.handleChange.bind(this);
    this.onSubmit=this.onSubmit.bind(this);
    this.onDismiss = this.onDismiss.bind(this);
  }

  _handleOnSelectUserChange(selected){
    console.log(selected)
    let { user } = this.state;
    user = selected;
    this.setState({
      user,
      _recentUserSearch: selected && this.state._recentUserSearch
    })
  }

  _handleOnSelectUserOpen(){
    if(this.state.user){
      return this.state._recentUserSearch&&this.refs.selectUser.loadSelectOptions(this.state._recentUserSearch);
    }
    return this.refs.selectUser.loadSelectOptions('');
  }

  handleChange(e){
    this.setState(textChange(e,{...this.state}));
  }

  handleDatePickerChange(date, name){
    this.setState(dateChange(date,name, {...this.state}));
  }

  handleCheckedChange(e){
    const _form=checkChange(e,this.state);
    this.setState({..._form});
  }
  componentWillReceiveProps(nextProps){
    const { dataRaffleEntry } = nextProps;
    if(nextProps.isOpen && nextProps.isOpen !== this.props.isOpen) {
      if (this.props.selectedRaffleEntry !== null) {
        const { id, user, shortNote, golden, expiry } = this.props.selectedRaffleEntry
        this.setState({
          id,
          user: [user],
          short_note: shortNote,
          golden,
          expiry,
          quantity:1,
          isSubmit:false,
          isAletError:false,
          error:null,
          _recentUserSearch: null
        });
      }
    } else if (!nextProps.isOpen && nextProps.isOpen !== this.props.isOpen) {
      this.setState({
        id:null,
        user: [],
        variant_id: null,
        raffle_id: null,
        raffle_request_id: null,
        size_id: null,
        short_note: '',
        golden: false,
        expiry: null,
        quantity:1,
        isSubmit:false,
        isAletError:false,
        error:null,
        _recentUserSearch: null
      }, () => this.props.onRemoveSelected());
    }

    if(dataRaffleEntry.details.isSubmit!==this.props.dataRaffleEntry.details.isSubmit
      &&dataRaffleEntry.details.isSubmit===false){
        const { error, isDeleting } = dataRaffleEntry.details;
        if(error) {
          this.setState({
            error:{...dataRaffleEntry.error},
            isSubmit:false, isAlertError:true},
          );
        } else if (!(this.props.dataRaffleEntry.details.isDeleting !== isDeleting)) {
          this.setState({
            isSubmit:false
          },()=>{this.props.onCancel()});
          this.props.onGetRaffleEntryList();
        }
    }
  }

  onDismiss() {
    this.setState({ 
      isAlertError: false, 
      error: null 
    });
  }

  _validateForm(){
    let errors={};
    if(this.state.user.length==0)
      errors['user']=['The user field is required..'];
    if(Object.keys(errors).length){
      this.setState({
        error:{errors:{...errors}, status_code:422,message:'Please fulfill your form *'}
      })
    }
    return errors;
  }

  onSubmit(e){
    e.preventDefault();
    const valid = this._validateForm();
    const { id, user, raffle_group_id, golden, short_note, expiry, quantity } = this.state
    let getUser = this.state.user;
    if(id==null)
      getUser = user.map(user => user.id);
    if(Object.keys(valid).length===0){
      let payload = {
        id,
        user_id: id ? user[0].id: getUser,
        raffle_group_id: raffle_group_id,
        golden,
        short_note,
        expiry,
        quantity
      }
      this.setState({
        isSubmit:true,
        error:null,
      },()=>{
        this.props.onSubmit(payload, this.props.update);
      });
    }
  }

  _renderError(){
    const { error, isAlertError } = this.state;
    if(error!==null){
      switch(error.status_code){
        case 422:
          return <Alert color="danger" isOpen={isAlertError} toggle={this.onDismiss}>Please fulfill your form</Alert>
        default:
          return <Alert color="danger" isOpen={isAlertError} toggle={this.onDismiss}>Houston, we have a problem!. Please inform our friendly staff via LINE @kickavenue</Alert>
      }
    }
    return null;
  }

  render(){
    return(        
      <Modal isOpen={this.props.isOpen} toggle={this.props.onCancel} className="modal-dialog modal-md">
        <ModalHeader toggle={this.props.onCancel}>{this.props.update ? 'Update ': 'Add '}Raffle Entry</ModalHeader>
        <ModalBody>
        <Form>
          <Row>
            <Col  xs={12} md={8}>
              <FormGroup  className={classnames({'has-danger has-feedback':hasError('user',this.state.error)})}>
                <Label for="formUser">User*</Label>
                <SelectUserAsync
                  isMulti
                  defaultOptions
                  id="_formSelectUser"
                  userId={this.props.dataAuth.id}
                  ref="selectUser"
                  value={this.state.user}
                  paramsApi={{ roles: 'user,seller,administrator', role_query: 'or', scope: 'all' }}
                  placeholder="Type and select a user..."
                  noResultsText="Cannot find user."
                  loadOptions={this._getUserOptions}
                  onSelectSearch={(input)=>this.setState({_recentUserSearch: input})}
                  onSelectChange={this._handleOnSelectUserChange}
                  onSelectOpen={this._handleOnSelectUserOpen}
                  isDisabled={this.state.id?true:false}
                />
                {errorMessage('user',this.state.error)}
              </FormGroup>
            </Col>
            <Col xs={4}>
              <FormGroup className={classnames({'has-danger has-feedback':hasError('quantity',this.state.error)})}>
                <Label for="formQuantity">Quantity</Label>
                <Input
                  type="number"
                  id="formQuantiy"
                  name="quantity"
                  placeholder="Enter a quantity usage*"
                  value={textValue('quantity',this.state)}
                  onChange={this.handleChange}
                  disabled={this.state.id?true:false}
                />
                {errorMessage('quantity',this.state.error)}
              </FormGroup>
            </Col>
            <Col xs={12} md={6}>
              <FormGroup className={classnames({'has-danger has-feedback':hasError('golden',this.state.error)})}>
                <Label for="formGolden">Golden &nbsp;</Label>
                <Radio
                  name="golden"
                  id="formGolden"
                  value="true" 
                  checked={textValue('golden', this.state)}
                  onChange={this.handleCheckedChange}
                />
                {errorMessage('golden',this.state.error)}
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6}>
              <FormGroup  className={classnames({'has-danger has-feedback':hasError('short_note',this.state.error)})}>
                <Label for="formUsedCount">Short Note</Label>
                <Input
                  type="text"
                  id="formUsedCount"
                  name="short_note"
                  placeholder="Short Note"
                  value={textValue('short_note',this.state)}
                  onChange={this.handleChange}
                />
                {errorMessage('short_note',this.state.error)}
              </FormGroup>
            </Col>
            <Col xs={12} md={6}>
              <FormGroup  className={classnames({'has-danger has-feedback':hasError('expiry',this.state.error)})}>
                <Label for="formDeliverSentDate">Expiry</Label>
                <InputDatetime 
                  name="expiry"
                  id="formExpiryDate"
                  timeFormat="HH:mm"
                  value={dateValue('expiry',this.state)}
                  onChange={(date)=>this.handleDatePickerChange(date,'expiry')}
                  inputProps={{placeholder:'Expired At', id:'formExpiryDate'}}
                />
                {errorMessage('expiry',this.state.error)}
              </FormGroup>
            </Col>
          </Row>     
        </Form>
        <span className="text-sm">Fields marked with * are required</span><br/>
        <span>{this._renderError()}</span>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" disabled={this.state.isSubmit} onClick={this.onSubmit}>{this.state.isSubmit?'Submitting...':'Submit'}</Button>{' '}
          <Button color="secondary" onClick={this.props.onCancel}>Cancel</Button>
        </ModalFooter>
      </Modal>
    )
  }
}
ModalCreateRaffleEntry.propTypes={
  dataAuth: PropTypes.object,
  selectedRaffleEntry: PropTypes.object,
  onRemoveSelected: PropTypes.func.isRequired,
  raffleGroupId:PropTypes.number.isRequired,
  isOpen:PropTypes.bool,
  onSubmit:PropTypes.func,
  onCancel:PropTypes.func
}