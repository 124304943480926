import moment from 'moment-timezone';
const TIMEZONE_DEFAULT = 'Asia/Jakarta';

const date = (value, tz = TIMEZONE_DEFAULT) => {
  moment.tz(tz);
  return moment(value);
}

export const compareDate = (from, to, type = null) =>
  from.diff(to, type);

export const compareDateNow = (from, type = null) =>
  from.diff(new Date(), type);

export default date;