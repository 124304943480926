import React from 'react';
import propTypes from 'prop-types';
import { TabPane } from 'reactstrap';
import UserShippingDetail from '../SaleDetails/UserShippingDetail';

const TabPaneUserDetail = props => (
  <TabPane className="tab-content--custom__tab-pane" tabId={props.tabId}>
    <UserShippingDetail
      isLoading={props.isLoading}
      shipping={props.shipping}
      shippingUpdate={props.shippingUpdate}
      user={props.user}
    />
  </TabPane>
)

export default TabPaneUserDetail;

TabPaneUserDetail.defaultProps = {
  isLoading: false
}

TabPaneUserDetail.propTypes = {
  tabId: propTypes.string.isRequired,
  isLoading: propTypes.bool,
  shipping: propTypes.object,
  shippingUpdate: propTypes.object,
  user: propTypes.object
}