import {
    BULK_STOCK_SUBMIT,
    BULK_STOCK_ERROR,
    BULK_STOCK,
    BULK_STOCK_UPLOAD_SUBMIT,
	BULK_STOCK_UPLOAD,
} from '../constants';
const initialState={
    success:{},
    isUpload:false,
	isSubmit:false,
	error:null
}

const reducer=(state=initialState, action)=>{
    switch(action.type){
        case BULK_STOCK_SUBMIT: return {...state, isSubmit:action.payload};
        case BULK_STOCK_UPLOAD_SUBMIT: return {...state, isUpload:action.payload};
        case BULK_STOCK_ERROR: return { ...state, ...action.payload,success:{} };
        case BULK_STOCK: return {...state, success:{...action.payload},error:null};
        case BULK_STOCK_UPLOAD: return {...state, success:{...action.payload},error:null};
        case 'CLEAR_BULK_STOCK': return initialState
        default:return state;
    }
}
export default reducer;