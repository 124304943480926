import React from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
	Button,
	Label,
	Input,
	Badge,
	FormGroup,
  ModalFooter
} from 'reactstrap';
import propTypes from 'prop-types';
import { textValue } from '../../utils/form';
import { SimpleLineIcon, FontAwesomeIcon } from '../../components/Icons';
import moment from 'moment-timezone';
import styled from 'styled-components';
import classNames from 'classnames';
import { ValidationMessage } from '../../components/Form/ValidationMessage';
import { Radio, FormGroupRadio, RadioText } from '../../components/Form/Radios';

const NotesListWrapper = styled.div`
	max-height: 200px;
	overflow: auto;
	margin: 1rem 0px;
	position: relative;
`;

const LoadMoreButton = styled.div`
	/* style={{width: '100%', display: 'flex', justifyContent: 'center'}} */
	width: 100%;
	display: flex;
	justify-content: center;
	a {
		text-decoration: underline;
		margin-bottom: .75rem;
	}
`;

const NoteItemWrapper = styled.div`
	width: 100%;
	display: flex;
	justify-content: ${props => props.direction === 'left'? 'flex-end': 'flex-start'};
	.note-container {
		/* style={{width: '85%', display: 'flex', justifyContent: item.user_id === this.props.loginId? 'flex-end': 'flex-start'}} */
		display: flex;
		width: 85%;
		justify-content: ${props => props.direction === 'left'? 'flex-end': 'flex-start'};
	}
	.note-content{
		/* style={{padding: '6px 12px', marginBottom: '.5rem', color: item.user_id === this.props.loginId? '#fff': '#4a4a4a', background: item.user_id === this.props.loginId?'#34b7f1': '#ddd', borderRadius: '12px'}} */
		padding: 6px 12px;
		margin-bottom: .5rem;
		color: ${props => props.direction === 'left'? '#fff': '#4a4a4a'};
		background-color: ${props => props.direction === 'left'? '#34b7f1': '#ddd'};
		border-radius: 12px;
	}
`;

const Shadow = styled.div`
	position: absolute;
	top: -1rem;
	z-index: 10;
	background: rgb(255, 255, 255);
	box-shadow: rgba(0, 0, 0, 0.5) 0px -5px 5px -3px;
	height: 8px;
	width: 100%;
`;



export default class ModalMarkedSale extends React.Component{
	constructor(props){
		super(props);
    this.state = {
      _form:{
        body: ''
			},
			// notes: [],
			_isOpen: false,
			reOpen: false,
			error: null
    }
    this._handleChange = this._handleChange.bind(this);
		this._onSubmit = this._onSubmit.bind(this);
		this._handleCollapse = this._handleCollapse.bind(this);
		// this.myRef = React.createRef()
	}

	componentDidUpdate(prevProps){
		if(prevProps.isOpen !== this.props.isOpen){
			this.setState({
				_form:{
					body: ''
				},
				reOpen: false,
				error: null
				// notes: this.props.notes.map(item => item).reverse()
			})
			if(this.props.isOpen && !this.props.isConfirmation){
				setTimeout(() => {
					this.scrollToMyRef()
				}, 100)
			}
		}
	}

	scrollToMyRef = () => {
		if(!this.myRef) return;
		const el = this.myRef;
		el.scrollTop = el.scrollHeight;
		// window.scrollTo(0, this.myRef.offsetTop)
	}

	_handleChange(e){
    const { _form } = this.state;
    _form[e.target.name] = e.target.value;
    this.setState({ _form, error: null });
	}

	_handleCollapse(){
		this.setState({_isOpen:!this.state._isOpen});
	}

	_onSubmit(e){
		e.preventDefault();
		const { isConfirmation, marked, closeMarkedAt } = this.props;
		const { _form: { body }, reOpen } = this.state;
		// console.log("reopen: ", reOpen)
		if(!isConfirmation){
			if(body.length === 0){
				this.setState({
					error: 'Cannot submit empty note.'
				})
				return;
			}
			if(body.length > 250){
				this.setState({
					error: 'Too long. Exceeded 250 characters.'
				})
				return;
			}
		}
		const payload = isConfirmation? { marked: false }: { marked: !marked && closeMarkedAt? reOpen: null, marked_notes: body };
		// console.log("payload: ", payload)
		this.setState({_isOpen:false},()=>{
			this.props.onSubmit(payload);
		})
	}

	handleLoadMoreClick = e =>{
		e.preventDefault();
		this.props.onLoadMoreNotes()
	}

  render(){
    return(
      <Modal size="md" className="modal-confirmation" isOpen={this.props.isOpen} toggle={this.props.toggle}>
        <ModalHeader toggle={this.props.toggle} className="modal-header--left">
          Notes. {this.props.unreadNoteCount > 0? (
						<span className="badge badge-pill badge-danger">
							{this.props.unreadNoteCount > 10? '9+': this.props.unreadNoteCount}
						</span>
					):null}
        </ModalHeader>
				{/* <Button onClick={this._handleCollapse} style={{ border:'none', backgroundColor:'#FFF' }}>
					{this.state._isOpen ? 'Close Notes' : 'Show Notes'}
				</Button> */}
				<ModalBody>
					<p className="mb-0">
						Order ID: {this.props.invoiceNumber}<br/>
						<span style={{fontSize: '.75rem'}}>Issued at: {this.props.markedAt? this.props.markedAt: '-'}</span><br/>
						<span style={{fontSize: '.75rem'}}>Issued by: {this.props.markedBy? this.props.markedBy: '-'}</span>
					</p>
					{
						this.props.markedAt?(
							<p>
								{
									this.props.marked?(
										<Badge color="primary">open-issued</Badge>
									):
									<Badge color="success">solved</Badge>
								}
							</p>
						):null
					}
					{
						this.props.isConfirmation?(
							<p className="mb-0">Set to solved the issue for this order?</p>
						):(
							<div>
								<div className={classNames({'d-none': this.props.notes.length === 0})}>
									<NotesListWrapper ref={myRef => this.myRef = myRef}>
										{this.props.hasNextPage?(
											<LoadMoreButton>
												<a href="#" onClick={this.handleLoadMoreClick} className="btn btn-link">
													Load More
												</a>
											</LoadMoreButton>
										):null}
										{this.props.notes.map(item => (
											<NoteItemWrapper key={item.id} direction={item.user_id === this.props.loginId?'left': 'right'}>
												<div className="note-container">
													<div className="note-content">
														<p className="mb-1" style={{fontSize: '.65rem'}}>
															<SimpleLineIcon iconType="user"/> {item.user_id === this.props.loginId? 'You': item.user.email.substring(0, item.user.email.indexOf('@'))}
															{!item.read && item.user_id != this.props.loginId?(
																<span style={{fontSize: '.5rem'}}>
																	<FontAwesomeIcon iconType="circle" className="ml-2 text-danger"/>
																</span>
															)
															:null}
														</p>
														<p className="mb-0">
															{item.body}<br/>
															<span style={{fontSize: '.5rem', fontWeight: 'normal'}}>
																<SimpleLineIcon iconType="calendar"/> {moment(item.created_at).format('DD/MM/YYYY')}
															</span>
															<span className="ml-2" style={{fontSize: '.5rem', fontWeight: 'normal'}}>
																<SimpleLineIcon iconType="clock"/> {moment(item.created_at).format('HH:mm')}
															</span>
														</p>
													</div>
												</div>
											</NoteItemWrapper>
										))}
									</NotesListWrapper>
									<div style={{position: 'relative'}}>
										<Shadow/>
									</div>
								</div>
								<FormGroup className={classNames({'has-danger has-feedback': this.state.error !== null})}>
									<Label for="markedNotes">
										Notes
									</Label>
									<Input
										type="textarea"
										placeholder="Put your notes here"
										id="markedNotes"
										name="body"
										value={textValue('body',this.state._form)}
										onChange={this._handleChange}
									/>
									{this.state.error?(
										<ValidationMessage message={this.state.error}/>
									): null}
								</FormGroup>
								{!this.props.marked && this.props.closeMarkedAt?(
									<FormGroupRadio style={{marginLeft: '2px', marginBottom: 0, marginTop: '.25rem'}}>
										<Radio
											wrapperClassName="switch-primary"
											radioType="3d"
											size="xs"
											value={true}
											checked={this.state.reOpen}
											usingLabelText={false}
											onChange={e => this.setState({reOpen: e.target.checked})}
										/>
										<RadioText style={{fontSize: '.75rem'}} className="text-muted" text="Re-open issue"/>
									</FormGroupRadio>
								): null}

								{/* <FormGroup>
									<Label for="markedAt">Marked At</Label>
									<Input
										type="text"
										disabled={true}
										id="markedAt"
										name="marked_at"
										value={textValue('marked_at',this.state._form)}
										onChange={this._handleChange}
									/>
								</FormGroup> */}
								{/* <span className="text-sm">Fields marked with * are required</span> */}
							</div>
						)
					}
				</ModalBody>
				{/* <Collapse isOpen={this.state._isOpen}>
				</Collapse> */}
        <ModalFooter>
          <Button color="secondary" onClick={this.props.toggle}>Cancel</Button>
          <Button color="primary" onClick={this._onSubmit}>{this.props.isConfirmation? 'Yes': 'Submit'}</Button>
        </ModalFooter>
      </Modal>
    )
  }
}
ModalMarkedSale.propTypes = {
  status: propTypes.string,
  markedNotes: propTypes.string,
  markedAt: propTypes.string,
  isOpen: propTypes.bool,
  toggle: propTypes.func,
  onSubmit: propTypes.func.isRequired
}
ModalMarkedSale.defaultProps = {
  modalBody: 'Are you sure?',
	isOpen: false,
	notes: []
}