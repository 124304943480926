import React from 'react';
import propTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Badge } from 'reactstrap';
import Paragraph from '../../../../styled/Typograph/Paragraph';
import SmallInline from '../../../../styled/Typograph/Small/SmallInline';
import FontAwesomeIcon from '../../../../components/Icons/FontAwesomeIcon';
import { DISBURSEMENT_BALANCE_IN, DISBURSEMENT_BALANCE_OUT } from './helpers';

const DisbursementColumn = props => {
  const items = props.items.filter(item => ["SALE", "OFFER", "TOPUP", "BID"].includes(item.item_type))
  .map(item => ({ invoice_number: item.disbursementable.invoice_number || item.disbursementable.ref_number, item_type: item.item_type==='OFFER'? 'PAYMENT' : item.item_type }));
  let linkType = 'sales';
  if(items.length){
    linkType = items[0].item_type === 'PAYMENT'? 'payments': items[0].item_type === 'TOPUP'? 'top_up': items[0].item_type === 'BID'? 'offers':linkType;
  }
  return(
    <Paragraph>
      <span style={{marginRight: 5}} className="text-muted">#{props.id}</span>
      <Link className="link-secondary" to={`/disbursements/${props.id}`}>{props.disbursementNumber}</Link><br/>
      {items.length?(
        <div>
          <Link className="link-secondary" to={`/${linkType}/${props.items[0].disbursementable_id}`}>({items[0].invoice_number})</Link>
        </div>
      ): null}
      <Badge color={props.disbursementType==='BALANCE_OUT'?"success": "primary"}>
        <FontAwesomeIcon style={{marginRight: 5}} iconType="exchange"/>
        {props.disbursementType==='BALANCE_OUT'?'BALANCE OUT':'BALANCE IN'}
      </Badge><br/>
      <SmallInline>
        <FontAwesomeIcon style={{marginRight: 5}} iconType="calendar"/>
        {props.createdAt}
      </SmallInline>{' '}
      <span className={props.itemCount?"":"d-none"} style={{fontSize: ".75rem"}}>{props.itemCount} items</span>
    </Paragraph>
  )
}
export default DisbursementColumn;

DisbursementColumn.defaultProps = {
  itemCount: 0,
  items: [],
  disbursementType: DISBURSEMENT_BALANCE_IN
}

DisbursementColumn.propTypes = {
  id: propTypes.number.isRequired,
  disbursementNumber: propTypes.string,
  userId: propTypes.number,
  userEmail: propTypes.string,
  itemCount: propTypes.number,
  items: propTypes.arrayOf(propTypes.object),
  createdAt: propTypes.string,
  disbursementType: propTypes.oneOf([DISBURSEMENT_BALANCE_IN, DISBURSEMENT_BALANCE_OUT])
}