import React from 'react';
import { StyleSheet } from '@react-pdf/renderer';
import { getPaymentType, getBoxConditionStatus, getSneakersConditionStatus } from '../../../../constants/kickavenue';
import { TextRupiah } from '../../../../components/Text';
import { isAllLuxuryItem } from './Invoice.utils';

const styles = StyleSheet.create({
  container:{
    display:'flex', 
    justifyContent:'center', 
    fontFamily: 'arial, sans-serif', 
    fontSize:'12px'
  },
  page: {
    width: '15cm',
    minHeight: '21cm',
    padding: '2cm',
    margin: '1cm auto',
    border: '1px #D3D3D3 solid',
    borderRadius: '5px',
    background: 'white',
    boxShadow: '0 0 5px rgba(0, 0, 0, 0.1)',
    position: 'relative'
  },
  section: {
    padding: '15px 0 5px 0',
    borderBottom:'1px solid #dddddd'
  },
  content:{
    display:'flex', 
    justifyContent:'space-between',
    marginBottom:'10px'
  },
  table: {
    borderCollapse: 'collapse',
    width: '100%',
  },
  tableHeader:{
    textAlign: 'left',
    padding: '8px',
    backgroundColor:'#EEF2F6'
  },
  tableCell: {
    borderBottom: '1px solid #dddddd',
    textAlign: 'left',
    padding: '8px',
  },
  fontBold:{
    fontWeight:'bold'
  },
  footer:{
    position: 'absolute',
    bottom: '10px',
    width: '547px',
    fontSize:'10px'
  }
});

const Invoice = ({selectedItems}) => {
  var today = new Date();
  var hours = today.getHours();
  var minutes = String(today.getMinutes()).padStart(2, '0');
  var formattedTime = `${hours}:${minutes}`;
  var dd = String(today.getDate()).padStart(2, '0');
  var monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  var mm = monthNames[today.getMonth()];
  var yyyy = today.getFullYear();
  today = mm + ' ' + dd + ', ' + yyyy;
  let subTotal = 0;
  let voucherCode = '-';
  let totalDiscount = 0;
  let totalProcessingFee = 0;
  const orderId = selectedItems && selectedItems[0].id;
  const buyer = selectedItems && selectedItems[0].sale_shipping.full_name;
  const dataOrder = selectedItems && selectedItems[0].offer;
  const bankCode = dataOrder ? dataOrder.bank_code:'BCA';
  const paymentMethod = bankCode && dataOrder.payment_method === 'virtual_account'?bankCode + '-'+ getPaymentType(dataOrder.payment_method):getPaymentType(dataOrder.payment_method);
  const logoImgUrl = isAllLuxuryItem(selectedItems.map(i => i?.sale_product?.category_name))
    ? "https://s3.ap-southeast-1.amazonaws.com/kickavenue.com/dapur/luxavenue_logo.png"
    : "https://s3.ap-southeast-1.amazonaws.com/kickavenue.com/dapur/black_logo_kick.png";
  return(
    <div style={styles.container}>
      <div style={styles.page}>
        <div style={{...styles.section, paddingBottom:0}}>
          <div style={{...styles.content, marginBottom:'0'}}>
            <div style={{ width: '150px', height: 'auto' }}>
              <img 
                src={logoImgUrl}
                style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                alt="Logo"
              />
            </div>
            <div style={{display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column'}}>
              <span style={{paddingBottom:'10px'}}>Invoice Number</span>
              <span style={{...styles.fontBold, marginLeft: '50px'}}>{orderId}</span>
            </div>
          </div>
        </div>
        <div style={styles.section}>
          <div style={styles.content}>
            <span>Bill From</span>
            <span>Bill To</span>
          </div>
          <div style={styles.content}>
            <span style={styles.fontBold}>Kick Avenue</span>
            <span style={styles.fontBold}>{buyer}</span>
          </div>
        </div>
        <div style={styles.section}>
          <div style={styles.content}>
            <span>Payment Date</span>
            <span>Payment Method</span>
          </div>
          <div style={styles.content}>
            <span style={styles.fontBold}>{today}</span>
            <span style={styles.fontBold}>{paymentMethod}</span>
          </div>
        </div>
        <div style={{...styles.section, marginTop:'10px', borderBottom:'none'}}>
          <table style={styles.table}>
            <thead>
              <tr>
                <th style={styles.tableHeader}>Order ID</th>
                <th style={styles.tableHeader}>Product</th>
                <th style={styles.tableHeader}>Qty</th>
                <th style={styles.tableHeader}>Price</th>
                <th style={styles.tableHeader}>Total</th>
              </tr>
            </thead>
            <tbody>
              {selectedItems&&selectedItems.map((item, index) => {
                const sneakerDefectCount = [
                  item.sale_product.yellowing,
                  item.sale_product.display_item,
                  item.sale_product.missing_accessories,
                  item.sale_product.sneakers_defect,
                ].filter(item => item === true).length;
                subTotal+=parseInt(item.actual_total_price);
                totalDiscount+=parseInt(item.offer.discount)?parseInt(item.offer.discount):parseInt(item.offer.ka_courier_discount);
                totalProcessingFee+=parseInt(item?.offer?.processing_fee_calculated || 0);
                if(item.voucher_code) voucherCode = item.voucher_code;
                return(
                  <tr key={index}>
                    <td style={styles.tableCell}>{item.invoice_number}</td>
                    <td style={styles.tableCell}>
                      <span>{item.sale_product.display_name}</span>
                      <br/>
                      <span>US {item.sale_product.size.US} / {getSneakersConditionStatus(item.sale_product.sneakers_condition)}, {getBoxConditionStatus(item.sale_product.box_condition)}, {sneakerDefectCount?'Defect':'Complete Accessories'}</span>
                    </td>
                    <td style={styles.tableCell}>1</td>
                    <td style={styles.tableCell}>
                      <TextRupiah prefix="IDR. " bold value={item.actual_total_price}/>
                    </td>
                    <td style={styles.tableCell}>
                      <TextRupiah prefix="IDR. " bold value={item.actual_total_price}/>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
        <div style={styles.section}>
          <div style={styles.content}>
            <span style={styles.fontBold}>Sub Total</span>
            <TextRupiah prefix="IDR. " bold value={subTotal} style={styles.fontBold}/>
          </div>
          <div style={styles.content}>
            <span>Processing Fee</span>
            <TextRupiah prefix="IDR. " bold value={totalProcessingFee}/>
          </div>
          <div style={styles.content}>
            <span>Voucher ({voucherCode})</span>
            <TextRupiah prefix="-IDR. " bold value={totalDiscount}/>
          </div>
        </div>
        <div style={styles.section}>
          <div style={styles.content}>
            <span style={styles.fontBold}>Total Payment</span>
            <TextRupiah prefix="IDR. " bold value={(subTotal+totalProcessingFee)-totalDiscount} style={styles.fontBold}/>
          </div>
        </div>
        <div style={styles.footer}>
          <p style={{margin:0, padding:0}}>This is computer generated invoice no signature required.</p>
          <p style={{marginTop:'5px', padding:0}}>Please contact our Customer Service should you have any inquries</p>
          <p>Last updated: {today} {formattedTime}</p>
        </div>
      </div>
    </div>
  )};

export default Invoice;
