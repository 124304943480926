import React from 'react';
import propTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Loading from 'react-loading-animation';
import classNames from 'classnames';
import {
  Card,
  CardHeader,
  CardBody,
  CardImg,
  CardSubtitle,
  Badge
} from 'reactstrap';
import {
  FieldRow,
  FieldColumn,
  FieldLabel,
  FieldValue
} from '../../../../styled/Fields';
import { BadgeFlexContainer, BadgeMedium } from '../../../../styled/Badge';
import { _getValue, _getFloatValue } from '../../../../utils/form';
import { NO_IMAGE_ALT } from '../../../../constants/settings';
import OfferSummaryCard from './OfferSummaryCard';

const SneakerSummaryCard = props => (
  <Card>
    <CardHeader>Offer Summaries</CardHeader>
    <CardImg
      src={props.featuredImage}
      alt={props.isLoading? (props.productVariant? props.productVariant.display_name: NO_IMAGE_ALT): NO_IMAGE_ALT}
    />
    {
      props.isLoading?
      <CardBody>
        <Loading/>
      </CardBody>
      :
      <CardBody>
        <div style={{padding: "1rem 0px"}}>
          <CardSubtitle>{props.productVariant&&props.productVariant.display_name}</CardSubtitle>
          <Link to={`/product_variants/${props.productVariantId}`}>See more...</Link>
        </div>
        <FieldRow>
          <FieldColumn className="col-xs-12 col-md-6">
            <FieldLabel>
              Box Condition
            </FieldLabel>
            <FieldValue>
              <span>{_getValue(props.boxCondition)}</span>
            </FieldValue>
          </FieldColumn>
          <FieldColumn className="col-xs-12 col-md-6">
            <FieldLabel>
              Sneaker Condition
            </FieldLabel>
            <FieldValue>
              <span>{_getValue(props.sneakersCondition)}</span>
            </FieldValue>
          </FieldColumn>
          <FieldColumn className="col-12">
            <BadgeFlexContainer>
              <BadgeMedium>
                <Badge color={(props.preOrder?"success":"default")}>
                  <i
                    className={
                      classNames("fa",{
                      "fa-times":!props.preOrder,
                      "fa-check":props.preOrder})
                      }
                  >
                  </i>
                  &nbsp; PRE-ORDER
                </Badge>
              </BadgeMedium>
              {/* <BadgeMedium>
                <Badge color={(props.preVerified?"success":"default")}>
                  <i
                    className={
                      classNames("fa",{
                        "fa-times":!props.preVerified,
                        "fa-check":props.preVerified})
                      }
                  >
                  </i>
                  &nbsp; PRE-VERIFIED
                </Badge>
              </BadgeMedium> */}
              <BadgeMedium>
                <Badge color={(props.missingAccessories?"success":"default")}>
                  <i
                    className={
                      classNames("fa",{
                        "fa-times":!props.missingAccessories,
                        "fa-check":props.missingAccessories})
                      }
                  >
                  </i>
                  &nbsp; MISS ACCESSORIES
                </Badge>
              </BadgeMedium>
              <BadgeMedium>
                <Badge color={(props.yellowing?"success":"default")}>
                  <i
                    className={
                      classNames("fa",{
                        "fa-times":!props.yellowing,
                        "fa-check":props.yellowing})
                      }
                  >
                  </i>
                  &nbsp; YELLOWING
                </Badge>
              </BadgeMedium>
              <BadgeMedium>
                <Badge color={(props.displayItem?"success":"default")}>
                  <i
                    className={
                      classNames("fa",{
                        "fa-times":!props.displayItem,
                        "fa-check":props.displayItem})
                      }
                  >
                  </i>
                  &nbsp; DISPLAY ITEM
                </Badge>
              </BadgeMedium>
              <BadgeMedium>
                <Badge color={(props.sneakersDefect?"success":"default")}>
                  <i
                    className={
                      classNames("fa",{
                        "fa-times":!props.sneakersDefect,
                        "fa-check":props.sneakersDefect})
                      }
                  >
                  </i>
                  &nbsp; SNEAKER DEFECT
                </Badge>
              </BadgeMedium>
            </BadgeFlexContainer>
          </FieldColumn>
        </FieldRow>
        <FieldRow>
          <FieldColumn className="col-12">
            <OfferSummaryCard
              id={props.id}
              isLoading={props.isLoading}
              displayName={props.productVariant===null? '-': props.productVariant.display_name}
              amount={props.amount}
              originalPrice={props.originalPrice}
              kaCourier={props.kaCourier}
              kaCourierOption={props.kaCourierOption}
              kaCourierPrice={props.kaCourierPrice}
              administrationFee={props.administrationFee}
              totalAmount={props.totalAmount}
              paymentMethod={props.paymentMethod}
              sizeUS={props.size.US}
              status={props.status}
              sellerCredit={props.sellerCredit}
              depositAmount={props.depositAmount}
            />
          </FieldColumn>
        </FieldRow>
      </CardBody>
    }
  </Card>
)

export default SneakerSummaryCard;

SneakerSummaryCard.defaultProps = {
  id: 0,
  productVariantId: 0,
  isLoading: false,
  preOrder: false,
  displayItem: false,
  missingAccessories: false,
  yellowing: false,
  sneakersDefect: false,
  paymentMethod: 'bank_tranfer',
  amount: 0,
  kaCourierPrice: 0,
  administrationFee: 0,
  totalAmount: 0,
  discount: 0,
  kaCourierDiscount: 0,
  adminFeeDiscount: 0,
  originalPrice: 0
}

SneakerSummaryCard.propTypes = {
  isLoading: propTypes.bool,
  id: propTypes.number,
  productVariantId: propTypes.number,
  featuredImage: propTypes.string,
  productVariant: propTypes.object,
  size: propTypes.object,
  boxCondition: propTypes.string,
  sneakersCondition: propTypes.string,
  preOrder: propTypes.bool,
  displayItem: propTypes.bool,
  missingAccessories: propTypes.bool,
  yellowing: propTypes.bool,
  sneakersDefect: propTypes.bool,
  paymentMethod: propTypes.string,
  amount: propTypes.oneOfType([propTypes.number, propTypes.string]),
  kaCourierPrice: propTypes.oneOfType([propTypes.number, propTypes.string]),
  kaCourier: propTypes.string,
  kaCourierOption: propTypes.string,
  administrationFee: propTypes.oneOfType([propTypes.number, propTypes.string]),
  totalAmount: propTypes.oneOfType([propTypes.number, propTypes.string]),
  originalPrice: propTypes.oneOfType([propTypes.number, propTypes.string])
}