import React from 'react';
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardText,
  CardBody,
  Input,
  Button
} from 'reactstrap';
import { connect } from 'react-redux';
import { getSeekStockList, syncAllSeekStock, syncSeekStockById, getSeekStockById } from '../../actions/seekStockAction';

import privateView from '../../components/hocs/privateView';
import Indicators from './Indicators';
import SeekList from './SeekList';
import {
  CardInputSearch
} from './styled';
import ModalSeekDetail from './seekList/ModalSeekDetail';
import { sortBySizeAsc } from './selectors';
import Analytics from "../../services/Analytics";

class SeekStockIndex extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      _isRequestPending: true,
      _requestType: 'FETCH_PENDING',
      _isIndicatorShowns: {
        notSynchedList: false,
        synchedList: false,
      },
      _isIndicatorShown: true,
      _indicatorFor: null,
      _syncList:[],
      _notSyncList:[],
      _notSyncListKeyword: '',
      _syncListKeyword: '',
      _modalDetailData: {
        id: null,
        items: []
      }
    }
    this._openModalDetail = this._openModalDetail.bind(this);
  }

  componentDidMount(){
    const { email } = this.props.injectedProps;
    this.props.getList();
    Analytics.recordPageView('3rd_party_stock', email, {
      url: window.location.href,
      location: this.props.location,
      match: this.props.match
    });
  }

  componentDidUpdate(prevProps){
    if(prevProps.dataSeekStock.list.isFetch !== this.props.dataSeekStock.list.isFetch && !this.props.dataSeekStock.list.isFetch){
      const { error, data } = this.props.dataSeekStock.list;
      if(error){
        this.setState({
          _isRequestPending: false,
          _requestType: 'ERROR'
        })
      }
      else{
        this.setState({
          _isRequestPending: false,
          _requestType: 'SUCCEEDED',
          _syncList: data.filter(item => item.product_variant_id !== null),
          _notSyncList: data.filter(item => item.product_variant_id === null)
        }, () => {
          this._forceIndicatorToggle(false);
        })
      }
    }
    if(prevProps.dataSeekStock.list.isSubmit !== this.props.dataSeekStock.list.isSubmit && !this.props.dataSeekStock.list.isSubmit){
      const { error, data } = this.props.dataSeekStock.list;
      if(error){
        this.setState({
          _isRequestPending: false,
          _requestType: 'ERROR'
        })
      }
      else{
        this.setState({
          _isRequestPending: false,
          _requestType: 'SUCCEEDED',
          _syncList: data.filter(item => item.product_variant_id !== null),
          _notSyncList: data.filter(item => item.product_variant_id === null)
        })
      }
    }
    if(prevProps.dataSeekStock.details.isSubmit !== this.props.dataSeekStock.details.isSubmit && !this.props.dataSeekStock.details.isSubmit){
      const { error, data } = this.props.dataSeekStock.details;
      if(error){
        this.setState({
          _isRequestPending: false,
          _requestType: 'ERROR'
        })
      }
      else{
        const list = this.props.dataSeekStock.list.data.map(item => {
          if(item.id === data.id) return {...data}
          return {...item}
        })
        this.setState({
          _isRequestPending: false,
          _requestType: 'SUCCEEDED',
          _syncList: list.filter(item => item.product_variant_id !== null),
          _notSyncList: list.filter(item => item.product_variant_id === null)
        })
      }
    }
    if(prevProps.dataSeekStock.details.isFetch !== this.props.dataSeekStock.details.isFetch && !this.props.dataSeekStock.details.isFetch){
      const {error, data} = this.props.dataSeekStock.details;
      if(!error){
        this.setState({
          _modalDetailData:{
            id: data.id,
            name: data.name,
            items: data.seek_product_variants.map(i => ({...i}))
              .sort((a, b) => sortBySizeAsc(a.size, b.size))
          }
        })
      }
    }
  }

  async _syncAllList(){
    this._forceIndicatorToggle(true);
    await this.setState({
      _isRequestPending: true,
      _requestType: 'SUBMIT_PENDING',
    })
    this.props.postSyncAll();
  }

  _forceIndicatorToggle(bool){
    this.setState({
      _isIndicatorShown: bool
    })
  }

  _indicatorToggle(bool, indicatorFor){
    this.setState({
      _isIndicatorShown: !bool && bool !== this.state._isIndicatorShown ? bool: this.state._isIndicatorShown,
      _isIndicatorShowns: {
        ...this.state._isIndicatorShowns,
        [indicatorFor]: bool
      }
    })
  }

  async _refreshList(){
    this._forceIndicatorToggle(true);
    await this.setState({
      _isRequestPending: true,
      _requestType: 'FETCH_PENDING',
    })
    this.props.getList();
  }

  async _syncById(id, indicatorFor){
    await this.setState({
      _isRequestPending: true,
      _requestType: 'SUBMIT_PENDING',
    })
    this._indicatorToggle(true, indicatorFor);
    this.props.postSyncById(id);
  }

  async _openModalDetail(id){
    await this.setState({
      _isModalDetailOpen: true,
      _modalDetailData: {
        id,
        items: []
      }
    });
    this.props.getDetail(id);
  }

  render(){
    return(
      <div className="animated fadeIn">
        <ModalSeekDetail
          data={this.state._modalDetailData}
          isOpen={this.state._isModalDetailOpen}
          toggle={()=> this.setState({ _isModalDetailOpen: !this.state._isModalDetailOpen })}
        />
        <Row>
          <Col xs={12}>
            <Card>
              <CardHeader>3rd Party's Stock</CardHeader>
              <CardBody>
                <CardText>Showing all 3rd party's stock.</CardText>
                <Button 
                  color="primary" 
                  onClick={()=> this._refreshList()}
                  disabled={this.state._isRequestPending}
                >
                  <i className="fa fa-refresh"></i> Refresh List
                </Button>
                <Button
                  disabled={this.state._isRequestPending}
                  onClick={() => this._syncAllList()}
                >
                  <i className="fa fa-refresh"></i> Sync All
                </Button>
                <Row style={{marginTop: '.5rem'}}>
                  <Col xs={12} md={6}>
                    <Card>
                      <CardHeader>Not Synced. Total: {this.state._notSyncList.length}</CardHeader>
                      <CardInputSearch>
                        <Input
                          size="sm"
                          name="_notSyncListKeyword"
                          placeholder="Search by SKU, min 3 characters..."
                          value={this.state._notSyncListKeyword}
                          onChange={e => this.setState({ [e.target.name]: e.target.value })}
                        />
                      </CardInputSearch>
                      <Indicators
                        isOpen={this.state._isIndicatorShown || this.state._isIndicatorShowns.notSynchedList}
                        type={this.state._requestType}
                        toggle={(bool)=> this._indicatorToggle(bool, 'notSynchedList')}
                      />
                      <SeekList
                        data={this.state._notSyncList}
                        searchKeyword={this.state._notSyncListKeyword}
                        isRequestPending={this.state._isRequestPending}
                        onSyncItemClick={id => this._syncById(id, "notSynchedList")}
                        onOpenModal={this._openModalDetail}
                      />
                    </Card>
                  </Col>
                  <Col xs={12} md={6}>
                    <Card>
                      <CardHeader style={{zIndex: 2}}>Synced. Total: {this.state._syncList.length}</CardHeader>
                      <CardInputSearch>
                        <Input
                          size="sm"
                          name="_syncListKeyword"
                          placeholder="Search by SKU, min 3 characters..."
                          value={this.state._syncListKeyword}
                          onChange={e => this.setState({ [e.target.name]: e.target.value })}
                        />
                      </CardInputSearch>
                      <Indicators
                        isOpen={this.state._isIndicatorShown || this.state._isIndicatorShowns.synchedList}
                        type={this.state._requestType}
                        toggle={(bool)=> this._indicatorToggle(bool, 'synchedList')}
                      />
                      <SeekList
                        data={this.state._syncList}
                        searchKeyword={this.state._syncListKeyword}
                        isRequestPending={this.state._isRequestPending}
                        onSyncItemClick={id => this._syncById(id, "synchedList")}
                        onOpenModal={this._openModalDetail}
                      />
                    </Card>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    )
  }
}

const mapStateToProps= ({seekStock, auth: { isFetch, roles, isLogged, id, email }}) => {
	return {
    dataSeekStock: seekStock,
    guardData: { isFetch, roles, isLogged, id, email }
	}
};
const mapDispatchToProps = (dispatch) => {
	return{
    postSyncAll: () => dispatch(syncAllSeekStock()),
    postSyncById: id => dispatch(syncSeekStockById(id)),
    getList:() => dispatch(getSeekStockList()),
    getDetail: id => dispatch(getSeekStockById(id))
	}
};
const enhance = connect(mapStateToProps,mapDispatchToProps);
export default enhance(privateView(SeekStockIndex));