import React from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  ModalFooter
} from 'reactstrap';
import propTypes from 'prop-types'
export default class ModalConfirmation extends React.PureComponent{
  render(){
    return(
      <Modal size="sm" className="modal-confirmation" isOpen={this.props.isOpen} toggle={this.props.toggle}>
        <ModalHeader toggle={this.props.toggle} className="modal-header--center">
          Attention.
        </ModalHeader>
        <ModalBody>
          <p className="text-center">{ this.props.modalBody }</p>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={this.props.toggle}>{this.props.secondaryText}</Button>
          <Button color="primary" onClick={this.props.onSubmit}>{this.props.primaryText}</Button>{' '}
        </ModalFooter>
      </Modal>
    )
  }
}
ModalConfirmation.propTypes = {
  modalBody: propTypes.string,
  isOpen: propTypes.bool,
  toggle: propTypes.func,
  onSubmit: propTypes.func.isRequired
}
ModalConfirmation.defaultProps = {
  modalBody: 'Are you sure?',
  isOpen: false,
  secondaryText: 'No',
  primaryText: 'Yes'
}