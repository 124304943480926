import React from 'react';
import { connect } from 'react-redux';
import {
  Alert,
  Row,
  Col,
  Card,
	TabContent,
	TabPane,
	Nav,
  NavItem,
	NavLink
} from 'reactstrap';
import propTypes from 'prop-types';
import classnames from 'classnames';
import qs from 'query-string';

import { getBidDetail } from '../../actions/bidAction';

import privateView from '../../components/hocs/privateView';
import Header from './bidDetail/Header';
import TabBidDetail from './bidDetail/TabBidDetail';
import TabPaymentDetail from './bidDetail/TabPaymentDetail';
import TabSneakerDetail from './bidDetail/TabSneakerDetail';
import TabListingDetail from './bidDetail/TabListingDetail';
import TabUserDetail from './bidDetail/TabUserDetail';
import TabSellerDetail from './bidDetail/TabSellerDetail';
import Analytics from "../../services/Analytics";
import Audit from '../../components/Audit';

const DEFAULT_ERROR_500_MSG = 'We got something errors. Please ask our staff!';
const DEFAULT_ERROR_404_MSG = 'Sorry, cannot find what you are looking for.';

class BidDetails extends React.Component {
  constructor(props){
    super(props)
    const queries = Object.assign({}, qs.parse(props.location.search));
    this.state = {
      _id: parseInt(this.props.match.params.id),
      activeTab: 'offerDetail',
      _error: null,
      _errorLoadData: false,
      _errorMsg: '',
      ...queries,
      _isLoading: true,
      data:{
        id: null,
        user_sell_id: null,
        shipping_id: null,
        product_variant_id: null,
        size_id: null,
        offer_id: null,
      }
    }
  }

  componentDidMount(){
    const { email } = this.props.injectedProps;
    this.props.getDetail(this.state._id)
    Analytics.recordPageView('offers_detail', email, {
      url: window.location.href,
      location: this.props.location,
      match: this.props.match
    });
  }

  componentDidUpdate(prevProps){
    if(prevProps.bidDetails.isFetch && prevProps.bidDetails.isFetch !== this.props.bidDetails.isFetch){
      const { error } = prevProps.bidDetails;
      if(error){
        let msg = null;
        switch(error.status_code){
          case 500:
            msg = DEFAULT_ERROR_500_MSG; break;
          case 404:
            msg = DEFAULT_ERROR_404_MSG; break;
          default:
            msg = error.message;
        }
        this.setState({
          _error: error,
          _errorLoadData: true,
          _errorMsg: msg
        })
      }
      else{
        let { user, shipping, ...data } = this.props.bidDetails.data;
        this.setState({
          _error: null,
          _errorLoadData: null,
          _isLoading: false,
          data:{
            ...data,
            user,
            shipping:{
              user,
              ...shipping
            }
          }
        })
      }
    }
  }

  _renderHeaderContent(){
    let { user, product_variant, size, ...data } = this.state.data;
    user = user || {};
    product_variant = product_variant || {};
    size = size || {};
    return(
      <Header
        isLoading={this.state._isLoading}
        email={user.email}
        createdAt={data.created_at}
        displayName={product_variant.display_name}
        SKU={product_variant.SKU}
        colour={product_variant.colour}
        sizeUS={size.US}
        payment={data.offer}
        paymentMethod={data.payment_method}
        status={data.status}
        sneakerCondition={data.sneakers_condition}
        boxCondition={data.box_condition}
        preOrder={data.pre_order}
        expiredAt={data.expired_at}
        totalAmount={data.total_amount}
        category={product_variant&&product_variant.product&&product_variant.product.category.name}
      />
    )
  }

  toggleTab(tab) {
		if (this.state.activeTab !== tab) {
			this.setState({
        activeTab: tab
      });
      const { pathname } = this.props.location;
      const search = qs.stringify({
        activeTab: tab
      });
      this.props.history.replace({ pathname, search })
		}
  }

  render(){
    return(
      <div className="animated fadeIn">
        <Row>
          <Col xs={12} style={{padding:0}}>
          {
            this.state._errorLoadData?
            <Card body>
              <Alert
                color="danger"
                className="text-center"
                isOpen={this.state._errorAfterFetched}
              >
                <strong>Ooops. </strong>{this.state._errorMsg}
              </Alert>
            </Card>
            :
            <Card body>
              { this._renderHeaderContent() }
              <Nav className="nav-tabs--custom" tabs>
                <NavItem>
                  <NavLink
                    className={classnames({ active: this.state.activeTab === 'offerDetail' })}
                    onClick={() => this.toggleTab('offerDetail')}
                  >
                    Offer Details
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: this.state.activeTab === 'paymentDetail' })}
                    onClick={() => this.toggleTab('paymentDetail')}
                  >
                    Payment Details
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: this.state.activeTab === 'productDetail' })}
                    onClick={() => this.toggleTab('productDetail')}
                  >
                    Product Details
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: this.state.activeTab === 'userDetail' })}
                    onClick={() => this.toggleTab('userDetail')}
                  >
                    User & Shipping
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: this.state.activeTab === 'sellerDetail' })}
                    onClick={() => this.toggleTab('sellerDetail')}
                  >
                    Seller
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent className="tab-content--custom" activeTab={this.state.activeTab}>
                <TabPane className="tab-content--custom__tab-pane" tabId="offerDetail">
                  <TabBidDetail
                    isLoading={this.state._isLoading}
                    dataBid={this.state.data}
                  />
                </TabPane>
                <TabPane className="tab-content--custom__tab-pane" tabId="paymentDetail">
                  <TabPaymentDetail
                    isLoading={this.state._isLoading}
                    paymentId={this.state.data.offer_id}
                    payment={this.state.data.offer}
                    productVariant={this.state.data.product_variant}
                  />
                </TabPane>
                <TabPane className="tab-content--custom__tab-pane" tabId="productDetail">
                  <TabSneakerDetail
                    isLoading={this.state._isLoading}
                    userSellId={this.state.data.user_sell_id}
                    userSell={this.state.data.user_sell}
                    payment={this.state.data.offer}
                    productVariant={this.state.data.product_variant_id !== null? this.state.data.product_variant: undefined}
                    size={this.state.data.size_id !== null? this.state.data.size: undefined}
                  />
                </TabPane>
                <TabPane className="tab-content--custom__tab-pane" tabId="userDetail">
                  <TabUserDetail
                    isLoading={this.state._isLoading}
                    shipping={this.state.data.shipping_id !== null ? this.state.data.shipping: undefined}
                  />
                </TabPane>
                <TabPane className="tab-content--custom__tab-pane" tabId="sellerDetail">
                  <TabSellerDetail
                    isEmpty={this.state.data.user_sell_id === null}
                    isLoading={this.state._isLoading}
                    seller={this.state.data.user_sell_id !== null? this.state.data.user_sell.user : undefined}
                  />
                </TabPane>
              </TabContent>
            </Card>
          }
          </Col>
        </Row>
        <Row>
          <Col xs={12} style={{ padding: 0 }}>
            <Audit
              id={this.state._id}
              type='bid'
              per_page={10}
              sort_by={'createdAt_desc'}
              refresh={this.state._isLoading}
            />
          </Col>
        </Row>
      </div>
    )
  }
}

const mapStateToProps =  ({ bid: { detail }, auth: { isFetch, isLogged, roles, id, email } }) => ({
  bidDetails: detail,
  guardData: { isFetch, isLogged, roles, id, email }
})

const mapDispatchToProps = dispatch => ({
  getDetail: id => dispatch(getBidDetail(id))
})

const enhance = connect(mapStateToProps, mapDispatchToProps);

export default enhance(privateView(BidDetails));

BidDetails.defaultProps = {

}

BidDetails.propTypes = {

}