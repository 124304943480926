import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {convertToRupiah} from '../../utils';
import styled from 'styled-components';

const Span = styled.span`
  text-decoration: ${props => props.isStroke? "line-through": "none"};
  color: ${props => props.isStroke? "#4a4a4a": "inherit"};
`

const Div = styled.div`
  text-decoration: ${props => props.isStroke? "line-through": "none"};
  color: ${props => props.isStroke? "#4a4a4a": "inherit"};
`

const TextRupiah = props => (
  props.tag === 'span'?
  <Span
    style={{...props.style}}
    isStroke={props.isStroke}
    className={classnames(`text-${props.align}`,{'text-bold':props.bold,'text-block':props.block})}>
    {`${props.prefix}${convertToRupiah(parseInt(parseFloat(props.value)))}`}
  </Span>
  :
  <Div
    style={{...props.style}}
    isStroke={props.isStroke}
    className={classnames(`text-${props.align}`,{'text-bold':props.bold,'text-block':props.block})}>
    {`${props.prefix}${convertToRupiah(parseInt(parseFloat(props.value)))}`}
  </Div>
)
export default TextRupiah;

TextRupiah.propTypes={
  bold: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.number,PropTypes.string]),
  prefix: PropTypes.string,
  align: PropTypes.oneOf(['left', 'right','center']),
  block: PropTypes.bool,
  isStroke: PropTypes.bool,
  tag: PropTypes.oneOf(['div', 'span'])
}

TextRupiah.defaultProps = {
  style: {},
  prefix: 'IDR',
  align:'left',
  value: 0,
  isStroke: false,
  tag: 'div'
};