import React, { PureComponent as Component } from "react";
import propTypes from "prop-types";
import ZebraBrowserPrintWrapper from "zebra-browser-print-wrapper";
import { getPrintData, getZplScript } from "./Helper/PrintHelper";
import PrintTypeOptions from "./PrintTypeOptions";
import { PRINT_TYPE_OPTIONS } from "./PrintTypeOptions.constants";
import { processingFeeIsFetchSelector, processingFeePercentageOfflineTxSelector } from "../../../../reducers/selectors/settingSelector";
import { connect } from "react-redux";
import { getProcessingFeeSetting } from "../../../../actions/settingAction";

class PaginationSummary extends Component {
    constructor() {
        super();
        this.state = {
            qty: 1,
            isPrinting: false,
            printType: PRINT_TYPE_OPTIONS.NO_PRICE,
        };
        this.checkAll = this.checkAll.bind(this);
        this.isCheckedAll = this.isCheckedAll.bind(this);
        this.print = this.print.bind(this);
    }

    componentDidMount() {
        this.props.onGetProcessingFeeSetting();
    }

    isCheckedAll() {
        const selectedIds = this.props.selectedData.map((el) => el.id);
        const isTrue = this.props.data.every((el) =>
            selectedIds.includes(el.id)
        );
        return isTrue;
    }

    checkAll() {
        const isCheckedAll = this.isCheckedAll();
        if (isCheckedAll) {
            const selectedIds = this.props.data.map((el) => el.id);
            const newData = this.props.selectedData.filter(
                (el) => !selectedIds.includes(el.id)
            );
            this.props.setSelectedData(newData);
            return;
        }

        const selectedIds = this.props.selectedData.map((el) => el.id);
        const temp = [...this.props.selectedData];
        const excludeDataFromThisPage = temp.filter((el) =>
            selectedIds.includes(el.id)
        );
        const newData = [...excludeDataFromThisPage, ...this.props.data];
        this.props.setSelectedData(newData);
    }

    async print() {
        if (!this.props.selectedData.length) {
            return;
        }
        this.setState({ isPrinting: true });
        
        try {
            const browserPrintInstance = new ZebraBrowserPrintWrapper();
            const defaultPrinter =
                await browserPrintInstance.getDefaultPrinter();
            browserPrintInstance.setPrinter(defaultPrinter);
            const printerStatus =
                await browserPrintInstance.checkPrinterStatus();

            if (!printerStatus.isReadyToPrint) {
                alert(`Printer Error: ${printerStatus.errors}`);
                return;
            }

            this.props.selectedData.forEach(async (data) => {
                const printData = getPrintData({ 
                    ...data, 
                    processingFeeOfflineTx: this.props?.processingFeeOfflineTx 
                });
                const script = await getZplScript({
                    ...printData,
                    printType: this.state.printType,
                });
                console.log("xxx script", script);

                for (let i = 0; i < this.state.qty; i++) {
                    browserPrintInstance.print(script);
                }
            });

            this.setState({ isPrinting: false });
        } catch (error) {
            alert(`Printer Error: not found`);
            console.log(error.message);
            this.setState({ isPrinting: false });
        }
    }

    render() {
        return (
            <div className="print-order-pagination">
                <div className="print-wrapper">
                    <label
                        className={!this.props.isOpenPrint ? "d-none" : ""}
                        style={{ margin: 0 }}
                    >
                        <input
                            className="print-order-checkbox"
                            type="checkbox"
                            name="_allSelected"
                            checked={this.isCheckedAll()}
                            onChange={this.checkAll}
                        />
                        {`${this.props.selectedData.length}/${this.props.total} Selected`}
                    </label>

                    <PrintTypeOptions 
                        selectedData={this.props.selectedData}
                        onSetPrintTypeOptions={(printType) => this.setState({ printType })}
                        selectedPrintType={this.state.printType}
                    />

                    <label
                        style={{ marginBottom: 0 }}
                        className={
                            this.props.isOpenPrint &&
                            this.props.selectedData.length
                                ? ""
                                : "d-none"
                        }
                    >
                        <input
                            type="number"
                            name="qty"
                            value={this.state.qty}
                            onChange={(e) => {
                                const value = parseInt(e.target.value);
                                if (value < 1) {
                                    this.setState({
                                        qty: 1,
                                    });
                                    return;
                                }

                                this.setState({
                                    qty: e.target.value,
                                });
                            }}
                            style={{
                                marginLeft: 10,
                                marginRight: 6,
                                width: 40,
                            }}
                        />
                        Qty/selected
                    </label>
                    <button
                        onClick={this.print}
                        disabled={this.state.isPrinting || this.props?.processingFeeIsFetch}
                        className={
                            this.props.isOpenPrint &&
                            this.props.selectedData.length
                                ? "create-invoice-button"
                                : "d-none"
                        }
                        {...(this.state.isPrinting && {
                            style: {
                                backgroundColor: "#e0e0e0",
                                cursor: "not-allowed",
                            },
                        })}
                    >
                        {this.state.isPrinting ? "Printing..." : "Print"}
                    </button>
                </div>
                <div>
                    {`Showing ${this.props.from} to ${this.props.to} of ${this.props.total} items.`}
                </div>
            </div>
        );
    }
}
PaginationSummary.propTypes = {
    from: propTypes.number,
    to: propTypes.number,
    total: propTypes.number,
};
PaginationSummary.defaultProps = {
    from: 0,
    to: 0,
    total: 0,
};

const mapStateToProps = (state) => ({
    processingFeeOfflineTx: processingFeePercentageOfflineTxSelector(state),
    processingFeeIsFetch: processingFeeIsFetchSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
    onGetProcessingFeeSetting: () => dispatch(getProcessingFeeSetting()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PaginationSummary);
