import React, { Component } from 'react';
import { 
  Row,
  Col,
  Card
} from 'reactstrap';
import PaymentDetailCard from './tabPaymentDetail/PaymentDetailCard';
import PurchaseSummaryCard from './tabPaymentDetail/PurchaseSummaryCard';

export default class TabPaymentDetail extends Component{
  constructor(props){
    super(props);
    this.state={
      _id: props.id,
      paymentDetail:{
      }
    }
  }

  componentWillReceiveProps(nextProps){
    if(nextProps.isLoading!==this.props.isLoading && !nextProps.isLoading){
      if(nextProps.dataPayment.error===null){
        const { detail }=nextProps.dataPayment;
        this.setState({ error: null, paymentDetail:{ ...detail }, _id: detail.id })
      }
      else{
        const { error }=nextProps.dataPayment;
        this.setState({ error });
      }
    }
    if(nextProps.isSubmit===false
    &&nextProps.isSubmit!==this.props.isSubmit&& nextProps.dataPayment.detail.status_code===200){
      const { detail, error } = nextProps.dataPayment;
      if(error){
        this.setState({ error });
      }
      else{
        this.setState({ error: null,paymentDetail:{ ...detail }, _id: detail.id })
      }
    }
  }

  _renderPurchaseSummaryCard(){
    const { user_sell, voucherable, ...others } = this.state.paymentDetail;
    return(
      <PurchaseSummaryCard
        isLoading={this.props.isLoading}
        displayName={user_sell&&user_sell.product_variant.display_name}
        id={this.state._id}
        totalAmount={others.total_amount}
        totalAdjustedAmount={others.total_adjusted_amount}
        offerAmount={others.offer_amount}
        walletUsage={others.wallet_usage}
        kickCredit={others.wallet_amount}
        kickPoint={others.lbalance_amount}
        sellerCredit={others.scredit_amount}
        kaCourier={others.ka_courier}
        kaCourierOption={others.ka_courier_option}
        kaCourierPrice={others.ka_courier_price}
        uniqueAmount={others.unique_amount}
        administrationFee={others.administration_fee}
        voucherId={others.voucherable_id || others.voucher_code}
        voucher={voucherable}
        quantity={others.quantity}
        discount={others.discount}
        adminFeeDiscount={others.admin_fee_discount}
        kaCourierDiscount={others.ka_courier_discount}
        subsidyPrice={others.subsidy_price}
        points={others.points}
        processingFeeCalculated={others.processing_fee_calculated}
      />
    )
  }

  render(){
    return(
      <Card body className="tab-content--custom__card">
        <Row>
          <Col xs={12} md={6}>
            <PaymentDetailCard
              id={this.state._id}
              {...this.state.paymentDetail}
              isLoading={this.props.isLoading}
              isSubmit={this.props.isSubmit}
              dataError={this.state.error}
              onUpdatePayment={this.props.onUpdatePayment}
              email={this.props.email}
              onOpenCreditCard={this.props.onOpenCreditCard}
            />
          </Col>
          <Col xs={12} md={6}>
            { this._renderPurchaseSummaryCard() }
          </Col>
        </Row>
      </Card>
    )
  }
}
