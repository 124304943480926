import React from 'react';
import {
  Col,
  Card,
  CardHeader,
  CardBody,
  CardText,
  Row,
  Label,
  FormGroup,
  UncontrolledTooltip
} from 'reactstrap';
import { ButtonLoading } from '../../components/Button';
import _ from 'lodash';
import Loading from 'react-loading-animation';
import { Radio, FormGroupRadio } from '../../components/Form/Radios';
import { FontAwesomeIcon } from '../../components/Icons';

const paymentGroupText = name => {
  switch(name){
    case 'virtual_accounts': return "Virtual Accounts";
    case 'credit_cards': return "Credit Card / Debit Card / Installments";
    case 'online_installments': return "Online Installments";
    case 'e_payments': return "E-Payments";
    case 'our_payments': return "Basic";
    default:  return "Undefined Payments";
  }
}

const visibility = paymentMethods => {
  const keys = paymentMethods.map(item => item.value)
  const hasId = paymentMethods.filter(item => item.id).length > 0;
  const all = paymentMethods.every(item => item.active && keys.includes(item.value))
  // console.log("hasId: ", hasId, "all: ", all)
  return hasId || !all;
}

export default class ProductVariantPaymentMethods extends React.Component{
  render(){
    const paymentGroups = _.chain(this.props.paymentMethods)
    .groupBy('group').map((value, key) => ({ title: paymentGroupText(key), items: value }))
    .value()
    return(
      <Card>
        <CardHeader>
          <i className="fa fa-align-justify"></i> Payment Methods
        </CardHeader>
        <CardBody>
          <CardText>
            At least one active payment method.
          </CardText>
          <Loading isLoading={this.props.isLoading}>
            {paymentGroups.map((g, gKey) => (
              <div key={gKey}>
                <Row style={{alignItems:'flex-end'}}>
                  <Col xs={12} md={12}>
                    <CardText className="mb-3">
                      <FontAwesomeIcon iconType="circle" style={{fontSize: '.65rem'}}/> {g.title}:
                    </CardText>
                  </Col>
                  {g.items.map((payment, keyId) => (
                    <Col xs={6} key={keyId}>
                      <FormGroup>
                        <FormGroupRadio>
                          <Col xs={6} style={{paddingLeft: 0}}>
                            <Label className="text-uppercase" style={{fontWeight: 700, fontSize: '.75rem'}}>
                              {payment.label}{payment.info? <FontAwesomeIcon className="ml-1" id={`payment-group${gKey}-id${keyId}`} style={{cursor: 'pointer'}} iconType="question-circle"/>:null}
                            </Label>
                          </Col>
                          <Radio
                            type="checkbox"
                            value={true}
                            checked={payment.active}
                            dataOnText="Yes"
                            dataOffText="No"
                            onChange={(e) => {
                              const valid = !e.target.checked? this.props.paymentMethods
                                .filter(item => item.value !== payment.value && item.active).length > 0: true;
                              if(!valid){
                                alert("Oops. At least one active payment method!");
                                return;
                              }
                              const payloads = this.props.paymentMethods.map(item => {
                                if(item.value === payment.value){
                                  return { ...item, active: e.target.checked }
                                }
                                return item;
                              })
                              this.props.onTogglePaymentMethod(payloads);
                            }}
                          />
                        </FormGroupRadio>
                        { payment.info? (
                          <UncontrolledTooltip placement="right" target={`payment-group${gKey}-id${keyId}`}>
                            {payment.info}
                          </UncontrolledTooltip>
                        ):null }
                      </FormGroup>
                    </Col>
                  ))}
                </Row>
                {gKey < paymentGroups.length - 1? <hr className="mt-0"/>: null}
              </div>
            ))}
            <ButtonLoading
              disabled={!visibility(this.props.paymentMethods)}
              isLoading={this.props.isSubmit}
              loadingMessage="Submitting..."
              color="primary"
              onClick={() => {
                const { paymentMethods, productVariantId } = this.props;
                const keys = paymentMethods.map(item => item.value)
                const all = paymentMethods.every(item => item.active && keys.includes(item.value))
                const datas = paymentMethods.filter(item => item.active || item.id)
                const payloads = [];
                for (let i = 0; i < datas.length; i++) {
                  const data={};
                  if(datas[i].id && datas[i].value){
                    data.id=datas[i].id;
                    data.active= datas[i].active? !all: datas[i].active;
                    data.payment_method=datas[i].value;
                  }
                  else if(!all){
                    data.payment_method=datas[i].value;
                  }
                  if(Object.keys(data).length)
                    payloads.push(data)
                }
                if(payloads.length)
                  this.props.saveVariantPaymentMethod(productVariantId, {variants:payloads})
              }}
            >
              Save
            </ButtonLoading>
          </Loading>
        </CardBody>
      </Card>
    )
  }
}