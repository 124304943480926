import React from 'react';
import { Card, CardImg, CardText, CardLink } from 'reactstrap';
import PropTypes from 'prop-types';
import { getCacheImage } from '../../../utils/imageHelper'

const Image = props => (
  <Card body>
    <CardImg src={getCacheImage(props.url)} alt={props.alt}/>
    <CardText>
      <small className="text-muted">Last updated {props.updatedAt}</small>
    </CardText>
    <CardLink href={getCacheImage(props.url)} className="text-default text-center" target="_blank"><i className="fa fa-external-link"></i> Open</CardLink>
  </Card>
)

export default Image;

Image.propTypes={
  url:PropTypes.string,
  updatedAt:PropTypes.string,
  alt:PropTypes.string,
}
