import React, { useState, useCallback } from 'react';
import { DropdownMenu } from 'reactstrap';
import { ButtonDropdown, DropdownItem } from '../../../components/Dropdowns/ButtonDropdown';
import { SimpleLineIcon } from '../../../components/Icons';

const PointShopTypeButton = props => {
  const [isOpen, setToggle] = useState(false)

  const validateIsActive = useCallback((value) => {
    return props.value?(props.value === value): (value === '')
  }, [props.value])

  return(
    <ButtonDropdown
      isOpen={isOpen}
      toggle={() => setToggle(!isOpen)}
      renderChildren={() => {
        return (
          <DropdownMenu>
            <DropdownItem header>Show Only: </DropdownItem>
            <DropdownItem
              onClick={() => props.onClick('product_variants')}
              active={validateIsActive('product_variants')}
            >
              { validateIsActive('product_variants')? <SimpleLineIcon iconType="check"/>: null } Variants
            </DropdownItem>
            <DropdownItem
              onClick={() => props.onClick('vouchers')}
              active={validateIsActive('vouchers')}
            >
              { validateIsActive('vouchers')? <SimpleLineIcon iconType="check"/>: null } Vouchers
            </DropdownItem>
          </DropdownMenu>
        )
      }}
    />
  )
}
export default PointShopTypeButton;