import React from 'react';
import 'react-datetime/css/react-datetime.css';
import Datetime from 'react-datetime';
const InputDatetime=({value,dateFormat,timeFormat, onChange,timeConstraints, inputProps})=>{
    return (
        <Datetime
            value={value}
            inputProps={inputProps}
            dateFormat={dateFormat}
            timeFormat={timeFormat}
            onChange={onChange}
            timeConstraints={timeConstraints}
        />
    )
}
export default InputDatetime;