import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  ButtonGroup,
  Alert,
  // CardText,
  UncontrolledTooltip
} from 'reactstrap';
import classNames from 'classnames';
import {
  getDetailCollection,
  saveCollection,
  deleteCollection,
  newCollection,
  saveCollectionCategories,
  saveCollectionImage,
  deleteCollectionImageById,
  uploadCollectionImage,
  S3_FOLDER_PATH
} from '../../actions/collectionAction';
import uuidv4 from 'uuid/v4';
import { getOptionList as getCategoryOptionList } from '../../actions/categoriesAction';
import { ModalDelete, ModalEditImage, ModalBlockLoading as ModalLoading, ModalInfo, ModalConfirmation } from '../../components/Modals';
import { FontAwesomeIcon, SimpleLineIcon } from '../../components/Icons';
import { Radio, RadioText, FormGroupRadio } from '../../components/Form/Radios';
import BadgeThin from '../../components/Badges/BadgeThin';
import { ButtonLoading } from '../../components/Button';
import { errorMessage, hasError ,  textValue } from '../../utils/form';
import { ImagesCompressor, createUploadFileForm } from '../../utils/imageHelper'
import ProductVariantList from './collectionForm/ProductVariantList';
import BrandList from './collectionForm/BrandList';
import CollectionList from './collectionForm/CollectionList';
import { CategoryForm } from './collectionForm/CategoryForm';
import { uploadImage, deleteImage } from '../../actions/imageUploadAction';
import RichTextEditor from 'react-rte';
import { toolbarConfig } from '../../config/rte';
import InputDatetime from '../../components/Form/InputDatetime/InputDatetime';
import moment from 'moment-timezone';
import { ImageFile } from '../../components/Images';
import { DropzonePlusButton } from '../../components/Form/DropzoneFile';
import { getPathS3 } from '../../utils/AWS'
import { UI_TYPE_DEFAULT, UI_TYPE_HERO, UI_TYPE_LOOK, UI_TYPE_PARENT, COLLECTION_TYPE_VARIANTS, COLLECTION_TYPE_BRANDS } from './helpers';
import SellerList from './collectionForm/SellerList';

const parseRteValue = valueStr => {
  return valueStr !== null && valueStr !== ''? RichTextEditor.createValueFromString(valueStr, 'html'):
    RichTextEditor.createEmptyValue()
}

const isEmptyRteValue = rteHtml => {
  return rteHtml === '<p><br></p>'
}

const collectionTypeOptions = [{
  label: 'Product Variants',
  value: COLLECTION_TYPE_VARIANTS,
},{
  label: 'Brands',
  value: COLLECTION_TYPE_BRANDS,
}]

const collectionUITypeOptions = [{
  label: 'Default',
  value: UI_TYPE_DEFAULT,
},{
  label: 'Parent Collection',
  value: UI_TYPE_PARENT,
},{
  label: 'Hero Collection',
  value: UI_TYPE_HERO,
},{
  label: 'Look Collection',
  value: UI_TYPE_LOOK,
}]

const collectionSortOptions = [{
  label: 'Most Popular',
  value: 'most_popular',
},{
  label: 'Newly Added',
  value: 'product_desc',
},{
  label: 'Highest to Lowest',
  value: 'price_desc',
},{
  label: 'Lowest to Highest',
  value: 'price_asc',
},{
  label: 'Size Availability',
  value: 'available_size',
}]

// const DEFAULT_IMAGE_ASPECT_LANDSCAPE = 16/5;
const DEFAULT_IMAGE_ASPECT_PORTRAIT = 1/1;

const addImageRawObject = file => {
  return {
    identifier: uuidv4(),
    fileInputOrigin: file, //for rollback.
    fileInput: file,
    URL: file.preview,
    status: false,
    isUploading: false,
    compressed: false
  }
}

const NEXT_SUBMIT_TYPES = ['SUBMIT_FORM_DATA', 'SUBMIT_CATEGORIES', 'UPLOAD_IMGS', 'SUBMIT_IMG_DATA', 'SYNC_IMAGES']

class CollectionForm extends React.Component{
  constructor(props){
    super(props);
    this.state={
      _form:{
        id: props.match.params.id || 0,
        slug: '',
        name: '',
        parent_id: null,
        ui_template: UI_TYPE_DEFAULT,
        active: false,
        featured: false,
        peoples: false,
        description: '',
        created_at: '',
        updated_at: '',
        featured_at:'',
        expiry: '',
        starts_at: '',
        termsRte: parseRteValue(null),
        terms: '',
        order: null,
        sort_by: null,
        categories: [],
        defaultImage: null,
        defaultImageUrl: null
      },
      defaultImageFile: null,
      _rawImages: [],
      _formSubmit: false,
      _deleteConfirmationOpen: false,
      _errorForm: null,
      _alertErrorOpen: false,
      _alertSuccessOpen: false,
      _errorMessage: '',
      _successMessage: '',

      _isModalLoadingOpen: false,
      _isModalErrorOpen: false,
      _errorCategoriesForm: null,
      _alertErrorCategoriesOpen: false,
      _alertSuccessCategoriesOpen: false,
      _errorCategoriesMessage: '',
      _successCategoriesMessage: '',
      _nextSubmit: null,
      _categoryFormSubmit: false,
      _imagesReady: [],
      _uploadImagesConfirmationOpen: false,
      modalEditImage: {
        isOpen: false,
        defaultImageRatio: DEFAULT_IMAGE_ASPECT_PORTRAIT,
        imageSourceEdits: [],
        purpose: "default"
      },
      uiTemplateOptions: collectionUITypeOptions
    }
  }

  componentDidMount(){
    if(this.props.dataCategories.length === 0)
      this.props.getCategoryOptionList()
    else{
      const { categories } = this.state._form;
      this.setState({
        _form:{
          ...this.state._form,
          categories: this._syncFormCategories(categories)
        }
      })
    }
    if(this.props.match.params.id){
      const { id } = this.props.match.params;
      this.props.find(id)
    }
    else{
      this.props.new();
    }
  }

  componentDidUpdate(prevProps, prevState){
    if(prevProps.dataCategories.length!==this.props.dataCategories.length){
      const { categories } = this.state._form;
      this.setState({
        _form:{
          ...this.state._form,
          categories: this._syncFormCategories(categories)
        }
      })
    }
    if(prevProps.dataUploaded.isUpload && prevProps.dataUploaded.isUpload !== this.props.dataUploaded.isUpload) {
      const { success, error } = this.props.dataUploaded;
      if(error){
        const { id } = this.state._form;
        if(!this.props.match.params.id){
          this.props.history.replace(`/collections/${id}`)
          return;
        }
        this.setState({
          _isModalLoadingOpen: false,
          _categoryFormSubmit: false,
          _errorCategoriesForm: {...error},
          _alertErrorCategoriesOpen: true,
          _alertSuccessCategoriesOpen: false,
          _errorCategoriesMessage: this.translateMessage(error.status_code, error.message)
        })
      }
      if(!error){
        const { data } = success.data;
        const { defaultImageFile, _rawImages, _form } = this.state;
        let payloads = [];
        if((defaultImageFile || _rawImages.length>0 )){
          if(data){
            // _nextSubmit === NEXT_SUBMIT_TYPES[3]
            _rawImages.map(raw => {
              data.map(item => {
                if(raw.identifier===item.identifier)
                payloads.push({url:item.url, orientation:raw.orientation, category_id:raw.category_id}); 
              })
            })
            this.setState({
              _rawImages: payloads.length? []: _rawImages,
              _imagesReady: []
            })
            if(payloads.length>0){
              this.props.saveCollectionImage(_form.id, { images: [...payloads] })
            }
          }
          else if(success.data.url && defaultImageFile){
            // _nextSubmit === NEXT_SUBMIT_TYPES[0]
            let imageId = 0;
            if(this.state._form.defaultImage){
              imageId = this.state._form.defaultImage.id;
            }
            const createPayload = {
              url: success.data.url,
              orientation: defaultImageFile.orientation,
              category_id: defaultImageFile.category_id
            }
            if(imageId) createPayload.id = imageId;
            this.props.saveCollectionImage(_form.id, createPayload)
            this.setState({ defaultImageFile: null })
          }
        }
      }
    }
    if(!this.props.masterCollection.isFetch && prevProps.masterCollection.isFetch !== this.props.masterCollection.isFetch){
      const { detail, detail:{ status_code }, error } = this.props.masterCollection;
      if(error){
        if(error.status_code === 404)
          this.props.history.replace('/404')
          this.setState({
            _errorForm: {...error},
            _alertErrorOpen: true,
            _alertSuccessOpen: false,
            _errorMessage: this.translateMessage(error.status_code, error.message)
          })
      }
      else if(status_code === 200){
        const { _nextSubmit, _form } = this.state;
        if(!this.props.match.params.id && _form.id && _nextSubmit !== null){
          this.props.history.replace(`/collections/${_form.id}`)
        }
        else{
          if(_nextSubmit === NEXT_SUBMIT_TYPES[4]){
            this.setState({
              _form:{
                ..._form,
                id: detail.id,
                categories: this._syncFormCategories(detail.categories),
              },
              _imagesReady: [],
              _nextSubmit: null,
              _isModalLoadingOpen: false,
              _categoryFormSubmit: false,
              _formSubmit: false
            })
          }
          else{
            const startsAtParsed = moment(detail.starts_at);
            const expiryParsed = moment(detail.expiry);
            const termsRte = parseRteValue(detail.terms);
            const defaultImage = detail.default_images.length? detail.default_images[0]: null;
            this.setState({
              _form:{
                ...this.state._form,
                id: detail.id,
                slug: detail.slug,
                name: detail.name,
                parent_id: detail.parent_id,
                ui_template: detail.ui_template,
                order: detail.order,
                collection_type: detail.collection_type,
                description: detail.description || '',
                active: detail.active,
                sort_by: detail.sort_by,
                created_at: detail.created_at,
                updated_at: detail.updated_at,
                featured: detail.featured,
                termsRte,
                terms: detail.terms,
                starts_at: startsAtParsed.isValid()? startsAtParsed: '',
                expiry: expiryParsed.isValid()? expiryParsed: '',
                peoples: detail.peoples,
                featured_at: detail.featured_at,
                categories: this._syncFormCategories(detail.categories),
                defaultImage,
                defaultImageUrl: defaultImage? (defaultImage.signed_url || defaultImage.URL): null
              },
              uiTemplateOptions: detail.ui_template !== UI_TYPE_PARENT? collectionUITypeOptions.filter(item => item.value !== UI_TYPE_PARENT): collectionUITypeOptions,
              defaultImageFile: null,
              _errorForm: null,
              _alertErrorOpen: false,
              _rawImages: [],
              _imagesReady: [],
              _isModalLoadingOpen: false,
              _formSubmit: false
            })
          }
        }
      }
    }
    if(prevProps.masterCollection.isImageDataSubmit && !this.props.masterCollection.isImageDataSubmit){
      const { error } = this.props.masterCollection;
      if(error){
        this.setState({
          _categoryFormSubmit: false,
          _isModalLoadingOpen: false,
          _isErrorModalOpen: true,
          _errorMessage: 'An error occured!'
        })
      } else{
        const { _form: { id }, _rawImages, _uploadImageFromMainForm } = this.state;
        if(_uploadImageFromMainForm && _rawImages.length > 0){
          this._uploadManyImagesProgress(id)
        }
        else{
          this.setState({
            _nextSubmit: NEXT_SUBMIT_TYPES[4]
          })
          this.props.find(id)
        }
      }
    }
    if(this.state._formSubmit && prevProps.masterCollection.isSubmit !== this.props.masterCollection.isSubmit && !this.props.masterCollection.isSubmit){
      const { detail, detail:{ status_code, message }, error } = this.props.masterCollection;
      if(error){
        if(error.status_code === 404)
          this.props.history.replace('/404')
        this.setState({
          _errorForm: {...error},
          _alertErrorOpen: true,
          _alertSuccessOpen: false,
          _isModalLoadingOpen: false,
          _errorMessage: this.translateMessage(error.status_code, error.message),
          _formSubmit: false
        })
      }
      else if(status_code === 200){
        const { _nextSubmit } = this.state;
        if(detail.delete){
          this.props.history.replace(`/collections`)
        }
        if(_nextSubmit === NEXT_SUBMIT_TYPES[0]){
          this.uploadDefaultImage(detail.id);
        }
        else if(_nextSubmit === NEXT_SUBMIT_TYPES[1]){
          const { _form: { categories }, _rawImages } = this.state;
          const payloads = categories.map(item => ({
            id: item.id,
            active: item.active
          }))
          this.setState({
            _form: {
              ...this.state._form,
              id: detail.id
            },
            _categoryFormSubmit: true,
            _nextSubmit: _rawImages.length>0? NEXT_SUBMIT_TYPES[2]: null
          })
          this.props.saveCollectionCategories(detail.id, { categories: payloads });
        }
        else if(_nextSubmit === NEXT_SUBMIT_TYPES[2]){
          this._uploadManyImagesProgress(detail.id);
        } else if(!this.props.match.params.id){
          this.props.history.replace(`/collections/${detail.id}`)
        }
        const expiryParsed = moment(detail.expiry);
        const startsAtParsed = moment(detail.starts_at);
        const termsRte = parseRteValue(detail.terms);
        this.setState({
          _form:{
            ...this.state._form,
            id: detail.id,
            slug: detail.slug,
            name: detail.name,
            parent_id: detail.parent_id,
            ui_template: detail.ui_template,
            order: detail.order,
            sort_by: detail.sort_by,
            description: detail.description || '',
            active: detail.active,
            created_at: detail.created_at,
            updated_at: detail.updated_at,
            featured: detail.featured,
            termsRte,
            terms: detail.terms,
            expiry: expiryParsed.isValid()? expiryParsed: '',
            starts_at: startsAtParsed.isValid()? startsAtParsed: '',
            peoples: detail.peoples,
            featured_at: detail.featured_at,
          },
          uiTemplateOptions: detail.ui_template !== UI_TYPE_PARENT? collectionUITypeOptions.filter(item => item.value !== UI_TYPE_PARENT): collectionUITypeOptions,
          _formSubmit: false,
          _errorForm: null,
          _alertErrorOpen: false,
          _alertSuccessOpen: true,
          _successMessage: this.translateMessage(status_code, message),
        })
      }
    }

    if(!this.props.dataCollectionCategories.isSubmit && this.props.dataCollectionCategories.isSubmit !== prevProps.dataCollectionCategories.isSubmit){
      const { error } = this.props.dataCollectionCategories;
      if(error){
        this.setState({
          _errorCategoriesForm: {...error},
          _alertErrorCategoriesOpen: true,
          _alertSuccessCategoriesOpen: false,
          _errorCategoriesMessage: this.translateMessage(error.status_code, error.message)
        })
      } else{
        const { status_code, message } = this.props.dataCollectionCategories;
        const { _form: { id }, _nextSubmit, _uploadImageFromMainForm } = this.state;
        if(_nextSubmit === NEXT_SUBMIT_TYPES[2]){
          if(_uploadImageFromMainForm && this.state.defaultImageFile){
            this.uploadDefaultImage(id);
            this.setState({
              _nextSubmit: NEXT_SUBMIT_TYPES[0]
            });
          }else{
            this._uploadManyImagesProgress(id);
          }
        }
        this.setState({
          _alertErrorCategoriesOpen: false,
          _alertSuccessCategoriesOpen: true,
          _successCategoriesMessage: this.translateMessage(status_code, message)
        })
      }
    }
    if(prevState._form.ui_template !== this.state._form.ui_template && this.state._form.ui_template === UI_TYPE_PARENT){
      this.setState({
        _form: {
          ...this.state._form,
          collection_type: COLLECTION_TYPE_VARIANTS
        }
      })
    }
  }

  uploadDefaultImage = id => {
    const { defaultImageFile, _form: { defaultImage } } = this.state;
    const original = defaultImage? defaultImage.URL: '';
    let name = getPathS3(original)
    if(!name){
      name = `collections/${id}`;
    }
    const tempPayload = createUploadFileForm(
      defaultImageFile.fileInput,
      false,
      { name, bucket_type: "assets" }
    );
    this.props.uploadImage(tempPayload);
  }

  _uploadManyImagesProgress = (id) => {
    const { _rawImages } = this.state;
    if(_rawImages.length){
      this.setState({
        _categoryFormSubmit: true,
        _isModalLoadingOpen: true,
        _nextSubmit: NEXT_SUBMIT_TYPES[3]
      },()=>{
        const tempPayload = createUploadFileForm(
          _rawImages.map(img => img.fileInput),
          true,
          { name: "collections/"+id, bucket_type: "assets" }
        );
        this.props.uploadImage(tempPayload, _rawImages);
      })
    }
  }

  _compressImage = async (image) => {
    let fileInput = image
    try{
      //compress file
      fileInput = await ImagesCompressor(image);
    }
    catch(e){
      alert('error while compress the images')
      fileInput = image
    }
    return fileInput;
  }

  _compressMultipleImages = async (images) => {
    const imgs = images.filter(img => !img.commpressed)
    if(imgs.length){
      const compressedPromises = images.map(async(img) =>{
        const compressed = await this._compressImage(img.fileInput)
        return {
          ...img,
          fileInput: compressed,
          compressed: true
        }
      })
      const results = await Promise.all(compressedPromises)
      let temp = [];
      if(results.length){
        temp = images.map(draft => {
          const data = results.find(r => r.identifier === draft.identifier);
          if(data){
            return data
          }
          return draft
        })
      }
      return temp;
    }
    return images;
  }

  _syncFormCategories(categoriesState){
    const { dataCategories } = this.props;
    let categories = [];
    if(dataCategories.length){
      categories = dataCategories.map(item => {
        const d = categoriesState.find(c => c.id == item.value);
        if(d){
          const { pivot } = d
          return {
            id: d.id,
            name: d.name,
            active: pivot? pivot.active: d.active,
            images: pivot? [...pivot.images]: [...d.images]
          }
        }
        return {
          id: item.value,
          name: item.label,
          active: false,
          images: []
        }
      })
    } else{
        categories = categoriesState.map(item =>{
          const { pivot } = item
          return {
            id: item.id,
            name: item.name,
            active: pivot? pivot.active: false,
            images: pivot? [...pivot.images]: [...item.images]
          }
        })
    }
    return categories;
  }

  translateMessage = (statusCode, message) => {
    let messageStr = ''
    switch(statusCode){
      case 201:
      case 200: messageStr = 'Success...'; break;
      case 422: messageStr = 'Please fullfill your form.'; break;
      case 404: messageStr = 'Resource not found.'; break;
      case 500: messageStr = "We've got something errors"; break;
      default: messageStr = message; break;
    }
    return messageStr
  }

  handleChangeEditor(value, name) {
    let newState = {...this.state._form};
    switch (name) {
      case "terms" :
        newState = {termsRte: value, terms:value.toString("html")}
        break;
      default:
        break;
    }
    this.setState({_form:{...this.state._form, ...newState}})
  }

  _handleModalDeleteToggle = () =>{
    const { _formSubmit } = this.state;
    if(!_formSubmit){
      this.setState({
        _deleteConfirmationOpen: !this.state._deleteConfirmationOpen
      })
    }
  }

  _renderErrorMessage = name => {
    const { _errorForm } = this.state;
    return errorMessage(name, _errorForm)
  }

  _hasInputError = name => {
    const { _errorForm } = this.state;
    return _errorForm && _errorForm.errors && hasError(name, _errorForm)
  }

  _renderLoading(){
    const { _formSubmit } = this.state;
    if(!_formSubmit) return null;
    return (
      <Row>
        <Col xs={12}>
          <Alert color="info">
            <strong>Heads up!</strong> Submitting your data...
          </Alert>
        </Col>
      </Row>
    )
  }

  _handleDeleteData = () => {
    this.setState({
      _formSubmit: true
    }, () => {
      const { _form: {id} } = this.state;
      this.props.delete(id);
    })
  }

  _submitMainForm = async () =>{
    const { _form, defaultImageFile, _rawImages, _uploadImageFromMainForm } = this.state;
    const forcedSubmit = _uploadImageFromMainForm || !this.props.edit;
    const expiry = _form.expiry === ''? null:(
      moment.isMoment(_form.expiry)? _form.expiry.format("YYYY-MM-DD HH:mm:00"):
      _form.expiry);
    const starts_at = _form.starts_at === ''? null:(
      moment.isMoment(_form.starts_at)? _form.starts_at.format("YYYY-MM-DD HH:mm:00"):
      _form.starts_at);
    const payloads = {
      name: _form.name,
      ui_template: _form.ui_template === ""? UI_TYPE_DEFAULT: _form.ui_template,
      collection_type: _form.collection_type,
      description: _form.description,
      active: _form.active,
      featured: _form.featured,
      peoples: _form.collection_type !== 'brands'? _form.peoples: false,
      terms: isEmptyRteValue(_form.terms)?null: _form.terms,
      order: _form.order,
      sort_by: _form.sort_by,
      expiry,
      starts_at
    }
    let categories = [..._form.categories]
    if(_form.id && _form.id != 0){
      payloads.id = _form.id
    }
    else{
      payloads.categories = categories.map(item => Number(item.id))
      categories = _form.categories.map(c => ({
        ...c,
        active: _rawImages.filter(r => r.category_id === c.id).length>0
      }))
    }

    //get default image...
    let _defaultImageFile = defaultImageFile;
    //dont compressed again, if already compressed.
    if(defaultImageFile && !defaultImageFile.commpressed){
      const res = await this._compressImage(defaultImageFile.fileInput)
      _defaultImageFile = {
        ...defaultImageFile,
        fileInput: res,
        compressed: true
      }
    }
    if(forcedSubmit){
      const compressed = await this._compressMultipleImages(_rawImages)
      const hasImageUploaded = (_defaultImageFile || _rawImages.length > 0);
      this.setState({
        _form: {
          ..._form,
          categories
        },
        defaultImageFile: _defaultImageFile,
        _formSubmit: true,
        _rawImages: [ ...compressed ],
        _uploadImageFromMainForm: forcedSubmit,
        _categoryFormSubmit: _rawImages.length > 0,
        _isModalLoadingOpen: hasImageUploaded,
        _nextSubmit:hasImageUploaded? (_rawImages.length > 0? NEXT_SUBMIT_TYPES[1]: NEXT_SUBMIT_TYPES[0]): null
      }, () => {
        this.props.save(payloads)
      })
    } else{
      this.setState({
        defaultImageFile: _defaultImageFile,
        _formSubmit: true,
        _isModalLoadingOpen: _defaultImageFile !== null,
        _nextSubmit: _defaultImageFile? NEXT_SUBMIT_TYPES[0]: null
      }, () => {
        this.props.save(payloads)
      })
    }
  }

  _handleSubmitForm = async (e) => {
    e.preventDefault()
    if(this.state._rawImages.length>0 && this.props.edit){
      this.setState({
        _uploadImageFromMainForm: false, //reset first.
        _uploadImagesConfirmationOpen: true
      })
      return;
    }
    this._submitMainForm();
  }

  _handleInputChange = ({ target: { name, value, checked, type } }) => {
    this.setState({
      _form:{
        ...this.state._form,
        [name]: type === 'checkbox'? checked: value
      }
    })
  }

  _handleSubmitCollectionCategories = async () => {
    const compressed = await this._compressMultipleImages(this.state._rawImages)
    await this.setState({
      _alertErrorCategoriesOpen: false,
      _alertSuccessCategoriesOpen: false,
      _rawImages: [...compressed],
      _categoryFormSubmit: true,
      _isModalLoadingOpen: compressed.length>0,
      _nextSubmit: compressed.length>0? NEXT_SUBMIT_TYPES[2]: null
    })
    const { id, categories } = this.state._form
    const payloads = categories.map(item => ({
      id: item.id,
      active: item.active
    }))
    this.props.saveCollectionCategories(id, { categories: payloads })
  }

  _handleDropzoneItemChange = (files, categoryId) =>{
    if(files.length){
      const fileInputs = files.map(c => ({
        ...addImageRawObject(c),
        category_id: categoryId !== 'default'? categoryId: null
      }));
      if(categoryId === 'default'){
        const { _form } = this.state;
        this.setState({
          _form: {
            ..._form,
            defaultImageUrl: fileInputs[0].URL
          },
          defaultImageFile: fileInputs[0],
          modalEditImage: {
            ...this.state.modalEditImage,
            isOpen: true,
            imageSourceEdits: [
              ...this.state.modalEditImage.imageSourceEdits,
              ...fileInputs.map(f => ({
                imgId: f.identifier,
                src: f.URL
              }))
            ],
            purpose: 'defaultImage',
          }
        });
      }
      else{
        this.setState({
          _rawImages: [
            ...this.state._rawImages,
            ...fileInputs
          ],
          modalEditImage: {
            ...this.state.modalEditImage,
            isOpen: true,
            imageSourceEdits: [
              ...this.state.modalEditImage.imageSourceEdits,
              ...fileInputs.map(f => ({
                imgId: f.identifier,
                src: f.URL
              }))
            ],
            purpose: 'list',
            // defaultImageRatio: DEFAULT_IMAGE_ASPECT_PORTRAIT
          }
        });
      }
    }
  }


  onCropSucceeded = async (blob, ratio) => {
    const blobUrl = URL.createObjectURL(blob);
    let fileName = blobUrl.substr(blobUrl.lastIndexOf('/')+1)
    if(fileName === '') fileName = 'local'
    const { modalEditImage, _rawImages } = this.state;
    let identifier = 0;
    if(modalEditImage.imageSourceEdits.length)
      identifier = modalEditImage.imageSourceEdits[0].imgId;
    let newState = {};
    if(modalEditImage.purpose === 'defaultImage'){
      const { _form, defaultImageFile } = this.state;
      const fileType = defaultImageFile.fileInputOrigin.type? defaultImageFile.fileInputOrigin.type: 'image/jpeg';
      const extension = defaultImageFile.fileInputOrigin.name.substring(
        defaultImageFile.fileInputOrigin.name.lastIndexOf('.') + 1,
        defaultImageFile.fileInputOrigin.name.length
      ) || '.jpeg';

      const result = {
        fileInput: new File([blob], `${fileName}${extension}`, {
          type: fileType
        }),
        URL: blobUrl,
        orientation: ratio.ratio <=1? 'portrait': 'landscape'
      }
      newState = { _form: { ..._form, defaultImageUrl: blobUrl }, defaultImageFile: { ...defaultImageFile, ...result }}
    }else{
      const images =  _rawImages.map(item => {
        if(item.identifier === identifier){
          const fileType = item.fileInputOrigin.type? item.fileInputOrigin.type: 'image/jpeg';
          const extension = item.fileInputOrigin.name.substring(item.fileInputOrigin.name.lastIndexOf('.') + 1, item.fileInputOrigin.name.length) || '.jpeg';
          return {
            ...item,
            fileInput: new File([blob], `${fileName}${extension}`, {
              type: fileType
            }),
            URL: blobUrl,
            orientation: ratio.ratio <=1? 'portrait': 'landscape'
          }
        }
        return item;
      })
      newState = { _rawImages: images }
    }
    this.setState({
      ...newState,
      modalEditImage: {
        ...modalEditImage,
        isOpen: false,
        imageSourceEdits: modalEditImage.imageSourceEdits
          .filter(item => item.imgId !== identifier)
      }
    }, () => {
      const { modalEditImage, modalEditImage: {imageSourceEdits} } = this.state;
      if(imageSourceEdits.length){
        setTimeout(() => {
          this.setState({
            modalEditImage: {
              ...modalEditImage,
              isOpen: true
            }
          })
        }, 1500)
      }
    })
  }

  _handleDatePickerChange = (date, name) => {
    this.setState({
      _form:{
        ...this.state._form,
        [name]: date
      }
    })
  }

  _handleAdjustedDate = (e, fieldName, type) => {
    e.preventDefault();
    const { _form } = this.state;
    const dateValue = _form[fieldName];
    if(dateValue && dateValue !== '' && moment.isMoment(dateValue)){
      this.setState({
        _form:{
          ..._form,
          [fieldName]: type === 'start'? moment(dateValue).startOf('day'): moment(dateValue).endOf('day')
        }
      })
    }
  }

  toggleModalEditImage = (_, r) => {
    const { modalEditImage, _rawImages, defaultImageFile } = this.state;
    let identifier = 0;
    if(modalEditImage.imageSourceEdits.length)
      identifier = modalEditImage.imageSourceEdits[0].imgId;
    let newState = {};
    if(modalEditImage.purpose === 'defaultImage'){
      newState = { defaultImageFile: { ...defaultImageFile, orientation: r.orientation }}
    } else{
      const images =  _rawImages.map(item => {
        if(item.identifier === identifier){
          return {
            ...item,
            orientation: r.orientation
          }
        }
        return item;
      })
      newState = { _rawImages: images }
    }
    this.setState({
      ...newState,
      modalEditImage: {
        ...modalEditImage,
        isOpen: false,
        imageSourceEdits: modalEditImage.imageSourceEdits
          .filter(item => item.imgId !== identifier)
      }
    }, () => {
      const { modalEditImage, modalEditImage: {imageSourceEdits} } = this.state;
      if(imageSourceEdits.length){
        setTimeout(() => {
          this.setState({
            modalEditImage: {
              ...modalEditImage,
              isOpen: true
            }
          })
        }, 1500)
      }
    })
  }

  render(){
    return(
      <Row>
        <ModalEditImage
          isOpen={this.state.modalEditImage.isOpen}
          imageSource={this.state.modalEditImage.imageSourceEdits.length? this.state.modalEditImage.imageSourceEdits[0].src: null}
          showGrid
          ratio={this.state.modalEditImage.defaultImageRatio}
          onCropSucceeded={this.onCropSucceeded}
          toggle={this.toggleModalEditImage}
        />
        <ModalLoading isOpen={this.state._isModalLoadingOpen}/>
        <ModalDelete
          isOpen={this.state._deleteConfirmationOpen}
          modalTitle="Confirmation."
          modalBody="Are you sure to delete this?"
          onDelete={this._handleDeleteData}
          toggle={this._handleModalDeleteToggle}
        />
        <ModalInfo
          modalTitle="Oh snap."
          isOpen={this.state._isModalErrorOpen}
          toggle={() => this.setState({_isModalErrorOpen: !this.state._isModalErrorOpen})}
          renderModalBody={() => (<p className="text-center">{this.state._errorMessage}</p>)}
        />
        <ModalConfirmation
          isOpen={this.state._uploadImagesConfirmationOpen}
          modalBody="You've not uploaded images (in Categories Form), press `Yes` to also upload your images."
          secondaryText="Later"
          onSubmit={() => {
            this.setState({
              _uploadImageFromMainForm: true,
              _uploadImagesConfirmationOpen: false
            }, () => {
              this._submitMainForm();
            });
          }}
          toggle={() => {
            this.setState({
              _uploadImageFromMainForm: false,
              _uploadImagesConfirmationOpen: false
            }, () => {
              this._submitMainForm();
            });
          }}
        />
        <Col xs={12} md={6}>
          <Card>
            <CardHeader>
              <FontAwesomeIcon iconType="align-justify"/> {this.props.title}
              {this.props.edit?
                <div>
                  <Link style={{fontSize: '.75rem'}} to="/collections/create">Create new Collection</Link>
                </div>
              :null}
            </CardHeader>
            <CardBody>
              {this._renderLoading()}
              <Row>
                <Col xs={12}>
                  <Alert color="danger" isOpen={this.state._alertErrorOpen} toggle={() => this.setState({_alertErrorOpen: false})}>
                    <strong>Oh Snap!</strong> { this.state._errorMessage }
                  </Alert>
                </Col>
                <Col xs={12}>
                  <Alert color="success" isOpen={this.state._alertSuccessOpen} toggle={() => this.setState({_alertSuccessOpen: false})}>
                    <strong>Done!</strong> { this.state._successMessage }
                  </Alert>
                </Col>
              </Row>
              {this.state._form.id? (
                <div>
                  <BadgeThin color="primary">#{this.state._form.id}</BadgeThin>
                  <BadgeThin>{this.state._form.slug}</BadgeThin>
                </div>
              ): null}
              <Form onSubmit={this._handleSubmitForm}>
                <ul className="image-guides" style={{fontSize: '.75rem', paddingInlineStart: '1rem'}}>
                  <li>You can set visibility (active/inactive) every categories. That means, only visible for just selected category. Set them in "Categories Form".</li>
                  <li>One collection can store the items (brands/products) in every categories, and showing the item based on their category (if it’s active).</li>
                  <li>Add / remove the items after created the collection or in Collection Detail Page.</li>
                </ul>
                <Row>
                  <Col xs={12} md={6}>
                    <FormGroup
                      className={classNames({ 'has-danger has-feedback': this._hasInputError('collection_type') })}
                    >
                      <Label>Type*</Label>
                      <Input
                        disabled={this.props.edit || this.state._form.ui_template === UI_TYPE_PARENT}
                        placeholder="Select type..."
                        name="collection_type"
                        type="select"
                        value={textValue('collection_type', this.state._form)}
                        onChange={this._handleInputChange}
                      >
                        <option value="">Select type...</option>
                        {
                          collectionTypeOptions.map((item, key) => (
                            <option key={key} value={item.value}>{item.label}</option>
                          ))
                        }
                      </Input>
                      { this._renderErrorMessage('collection_type') }
                    </FormGroup>
                  </Col>
                  <Col xs={12} md={6}>
                    <FormGroup
                      className={classNames({ 'has-danger has-feedback': this._hasInputError('ui_template') })}
                    >
                      <Label>UI Template*</Label>
                      <Input
                        disabled={this.props.masterCollection.detail.ui_template === UI_TYPE_PARENT}
                        placeholder="Select UI template..."
                        name="ui_template"
                        type="select"
                        value={textValue('ui_template', this.state._form)}
                        onChange={this._handleInputChange}
                      >
                        <option value="">Select UI template...</option>
                        {
                          this.state.uiTemplateOptions.map((item, key) => (
                            <option key={key} value={item.value}>{item.label}</option>
                          ))
                        }
                      </Input>
                      { this._renderErrorMessage('ui_template') }
                    </FormGroup>
                  </Col>
                </Row>
                <FormGroup
                    row
                    className={classNames({ 'has-danger has-feedback': this._hasInputError('img_url') })}
                  >
                    <Col sm={12}>
                      <Label>Default Image/Logo</Label>
                    </Col>
                    <Col className="d-flex">
                      <ImageFile
                        style={{padding: 0}}
                        alt="Default collection image."
                        src={this.state._form.defaultImageUrl? this.state._form.defaultImageUrl : null}
                        showDelete={this.state.defaultImageFile !== null}
                        renderEmptyImageText={() => (
                          <p className="mb-0 text-center">
                            No Image <br/>
                            Size: 700 x 700 in pixels
                          </p>
                        )}
                        onDelete={() => {
                          const { _form } = this.state;
                          this.setState({
                            _form: {
                              ..._form,
                              defaultImageUrl: this.state._form.defaultImage
                            },
                            defaultImageFile: null
                          })
                        }}
                      />
                    <div className={classNames('ml-3', {'d-none': this.state.isReadOnly})}>
                      <DropzonePlusButton mulitple={false} onDrop={(files) => this._handleDropzoneItemChange(files, 'default')}/>
                    </div>
                  </Col>
                  <Col xs={12}>
                    { this._renderErrorMessage('img_url') }
                  </Col>
                </FormGroup>
                <FormGroup
                  className={classNames({ 'has-danger has-feedback': this._hasInputError('name') })}
                >
                  <Label>Name*</Label>
                  <Input
                    placeholder="Enter a name*"
                    name="name"
                    type="text"
                    value={textValue('name', this.state._form)}
                    onChange={this._handleInputChange}
                  />
                  { this._renderErrorMessage('name') }
                </FormGroup>
                <FormGroup
                  className={classNames({ 'has-danger has-feedback': this._hasInputError('description') })}
                >
                  <Label>Description</Label>
                  <Input
                    placeholder="Enter a description"
                    name="description"
                    type="textarea"
                    value={textValue('description', this.state._form)}
                    onChange={this._handleInputChange}
                  />
                  { this._renderErrorMessage('description') }
                </FormGroup>
                <FormGroup
                  className={classNames({ 'has-danger has-feedback': this._hasInputError('description') })}
                >
                  <Label>Terms - optional</Label>
                  <RichTextEditor
                    value={this.state._form.termsRte}
                    onChange={(value) => this.handleChangeEditor(value, "terms")}
                    toolbarConfig={toolbarConfig}
                  />
                  { this._renderErrorMessage('terms') }
                </FormGroup>
                <Row>
                  <Col xs={12} sm={6} md={6}>
                    <FormGroupRadio
                      className={classNames({ 'has-danger has-feedback': this._hasInputError('active') })}
                    >
                      <Radio
                        type="checkbox"
                        name="active"
                        value={true}
                        checked={textValue('active', this.state._form)}
                        dataOnText="Yes"
                        dataOffText="No"
                        onChange={this._handleInputChange}
                      />
                      <RadioText text="Set to active"/>
                      { this._renderErrorMessage('active') }
                    </FormGroupRadio>
                  </Col>
                  <Col xs={12} sm={6} md={6} className={classNames({"d-none": this.state._form.collection_type === 'brands'})}>
                    <FormGroupRadio
                      className={classNames({ 'has-danger has-feedback': this._hasInputError('peoples') })}
                    >
                      <Radio
                        type="checkbox"
                        name="peoples"
                        value={true}
                        checked={textValue('peoples', this.state._form)}
                        dataOnText="Yes"
                        dataOffText="No"
                        onChange={this._handleInputChange}
                      />
                      <RadioText text="Set to people's collection"/>
                      <span style={{fontSize: '.65rem'}}>
                        For designers, influencers, celebs,
                        fashion-models or a person purposes (e.g. `Virgil Abloh Collection`).
                      </span>
                      { this._renderErrorMessage('peoples') }
                    </FormGroupRadio>
                  </Col>
                </Row>
                <p>Visibility by Date</p>
                <Row>
                  <Col xs={12} md={6}>
                    <FormGroup
                      className={classNames({ 'has-danger has-feedback': this._hasInputError('starts_at') })}
                    >
                      <Label>Start - optional</Label>
                      <InputDatetime
                        inputProps={{placeholder:'Pick a Start Date', name: "starts_at", autoComplete: "off"}}
                        value={textValue('starts_at', this.state._form)}
                        onChange={ date => this._handleDatePickerChange(date, 'starts_at') }
                        timeFormat="HH:mm"
                      />
                      <p className="mb-0">
                        <a href="#" onClick={(e) => this._handleAdjustedDate(e, 'starts_at', 'start')} style={{fontSize: '.75rem', textDecoration: 'underline'}} className="mr-3">start of day</a>
                        <a href="#" onClick={(e) => this._handleAdjustedDate(e, 'starts_at', 'end')} style={{fontSize: '.75rem', textDecoration: 'underline'}} className="mr-3">end of day</a>
                      </p>
                      { this._renderErrorMessage('starts_at') }
                    </FormGroup>
                  </Col>
                  <Col xs={12} md={6}>
                    <FormGroup
                      className={classNames({ 'has-danger has-feedback': this._hasInputError('expiry') })}
                    >
                      <Label>Expiry - optional</Label>
                      <InputDatetime
                        inputProps={{placeholder:'Pick a Expiry Date', name: "expiry", autoComplete: "off"}}
                        value={textValue('expiry', this.state._form)}
                        onChange={ date => this._handleDatePickerChange(date, 'expiry') }
                        timeFormat="HH:mm"
                      />
                      <p className="mb-0">
                        <a href="#" onClick={(e) => this._handleAdjustedDate(e, 'expiry', 'start')} style={{fontSize: '.75rem', textDecoration: 'underline'}} className="mr-3">start of day</a>
                        <a href="#" onClick={(e) => this._handleAdjustedDate(e, 'expiry', 'end')} style={{fontSize: '.75rem', textDecoration: 'underline'}} className="mr-3">end of day</a>
                      </p>
                      { this._renderErrorMessage('expiry') }
                    </FormGroup>
                  </Col>
                  <Col xs={12} md={6}>
                    <FormGroup
                      className={classNames({ 'has-danger has-feedback': this._hasInputError('order') })}
                    >
                      <Label>Order</Label>
                      <Input
                        placeholder="Numeric value"
                        name="order"
                        type="number"
                        value={textValue('order', this.state._form)}
                        onChange={this._handleInputChange}
                      />
                      { this._renderErrorMessage('order') }
                    </FormGroup>
                  </Col>
                  <Col xs={12} md={6}>
                    <FormGroup
                      className={classNames({ 'has-danger has-feedback': this._hasInputError('sort_by') })}
                    >
                      <Label>Default Sort by</Label>
                      <Input
                        placeholder="Select UI template..."
                        name="sort_by"
                        type="select"
                        value={textValue('sort_by', this.state._form)}
                        onChange={this._handleInputChange}
                      >
                        <option value="">Select Sort by...</option>
                        {
                          collectionSortOptions.map((item, key) => (
                            <option key={key} value={item.value}>{item.label}</option>
                          ))
                        }
                      </Input>
                      { this._renderErrorMessage('sort_by') }
                    </FormGroup>
                  </Col>
                </Row>
                {
                  this.props.edit?(
                    <div>
                      <Row>
                        <Col xs={12} sm={6} md={6}>
                          <FormGroupRadio
                            className={classNames({ 'has-danger has-feedback': this._hasInputError('active') })}
                          >
                            <Radio
                              type="checkbox"
                              name="featured"
                              value={true}
                              checked={textValue('featured', this.state._form)}
                              dataOnText="Yes"
                              dataOffText="No"
                              onChange={this._handleInputChange}
                            />
                            <RadioText text="Set to featured"/>
                            <span style={{fontSize: '.65rem'}}>
                              Show in featured collection list (homepage our app).
                            </span>
                            { this._renderErrorMessage('featured') }
                          </FormGroupRadio>
                        </Col>
                        <Col xs={12} md={6}>
                          <FormGroup>
                            <Label>Featured At</Label>
                            <Input
                              readOnly
                              name="featured_at"
                              type="text"
                              value={textValue('featured_at', this.state._form)}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} md={6}>
                          <FormGroup>
                            <Label>Created</Label>
                            <Input
                              readOnly
                              name="created_at"
                              type="text"
                              value={textValue('created_at', this.state._form)}
                            />
                          </FormGroup>
                        </Col>
                        <Col xs={12} md={6}>
                          <FormGroup>
                            <Label>Updated</Label>
                            <Input
                              readOnly
                              name="updated_at"
                              type="text"
                              value={textValue('updated_at', this.state._form)}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                  ):null
                }

                <p style={{fontSize: '.75rem', fontStyle: 'italic'}}>(*) this field is required.</p>
                <FormGroup row>
                  <Col>
                    <ButtonGroup>
                      <ButtonLoading
                        disabled={this.state._formSubmit}
                        isLoading={this.state._formSubmit}
                        loadingMessage="Submitting..."
                        color="primary"
                      >
                        Submit
                      </ButtonLoading>
                      {
                        this.props.edit?(
                          <ButtonLoading
                            type="button"
                            disabled={this.state._formSubmit}
                            // isLoading={this.state._formSubmit}
                            loadingMessage="Deleting..."
                            onClick={this._handleModalDeleteToggle}
                            color="danger"
                          >
                            Delete
                          </ButtonLoading>
                        )
                        :null
                      }
                    </ButtonGroup>
                  </Col>
                </FormGroup>
              </Form>
            </CardBody>
          </Card>
        </Col>
        {
          this.props.edit?(
            <Col xs={12} md={6}>
              {
                this.props.masterCollection.detail.ui_template === "PARENT" ? (
                  <CollectionList id={this.props.match.params.id}/>
                ) :this.state._form.collection_type === COLLECTION_TYPE_VARIANTS?(
                  <>
                    <ProductVariantList id={this.props.match.params.id}/>
                    <SellerList collectionId={this.props.match.params.id} />
                  </>
                ):(this.state._form.collection_type === COLLECTION_TYPE_BRANDS? (
                  <>
                    <BrandList id={this.props.match.params.id}/>
                    <SellerList collectionId={this.props.match.params.id} />
                  </>
                ):null)
              }
            </Col>
          ): null
        }
        <Col xs={12} md={6}>
          <Card>
            <CardHeader>
              <FontAwesomeIcon className="mr-2" iconType="align-justify"/>
              <span className="mr-1">Categories</span>
              <SimpleLineIcon id="categoryImageTitleTip" iconType="question"/>
              <UncontrolledTooltip innerClassName="text-left" placement="right" target="categoryImageTitleTip">
                Please provide at least one image every active category!
              </UncontrolledTooltip>
            </CardHeader>
            <CardBody>
              <ul className="image-guides" style={{fontSize: '.75rem', paddingInlineStart: '1rem'}}>
                <li className="d-none">You can click the image for preview the image.</li>
                <li>It will be display on Web and App.</li>
                <li>Every category can display different images.</li>
                <li>Use 1280x400 (16:5),  1280x720 (16:9) or  1280x465,45 (11:4) resolution of your image as our recommended for our web. (In pixels). Or any resolution that you want, but make sure its <strong>landscape</strong> orientation.</li>
                <li>Use 700x700 (1:1),  700x933,33 (3:4) or  700x1050 (4:6) resolution of your image as our recommended for our app display. (In pixels). Or any resolution that you want, but make sure its <strong>portrait</strong> orientation.</li>
                <li>You can upload multiple images, it will be sort by latest uploaded. (By default).</li>
                <li>By changing the images, click "Update Changes" button for save your changes below this form.</li>
              </ul>
              {this.props.dataCollectionCategories.isSubmit?(
                <Row>
                  <Col xs={12}>
                    <Alert color="info">
                      <strong>Heads up!</strong> Submitting your data...
                    </Alert>
                  </Col>
                </Row>
              ): null}
              <Row>
                <Col xs={12}>
                  <Alert color="danger" isOpen={this.state._alertErrorCategoriesOpen} toggle={() => this.setState({_alertErrorCategoriesOpen: false})}>
                    <strong>Oh Snap!</strong> { this.state._errorCategoriesMessage }
                  </Alert>
                </Col>
                <Col xs={12}>
                  <Alert color="success" isOpen={this.state._alertSuccessCategoriesOpen} toggle={() => this.setState({_alertSuccessCategoriesOpen: false})}>
                    <strong>Done!</strong> { this.state._successCategoriesMessage }
                  </Alert>
                </Col>
              </Row>
              <div>
              {this.state._form.categories.map((category, key) => (
                <CategoryForm
                  key={key}
                  create={!this.props.edit}
                  category={category}
                  isLoading={this.state._categoryFormSubmit}
                  onActiveChange={e => {
                    const { _form, _form: { categories } } = this.state;
                    this.setState({
                      _form:{
                        ..._form,
                        categories: categories.map((cat, catKey) => ({
                          ...cat,
                          active: catKey === key? e.target.checked: cat.active
                        }))
                      }
                    })
                  }}
                  onClearImageFiles={() => {
                    const { _rawImages } = this.state;
                    this.setState({
                      _rawImages: _rawImages.filter(img => img.category_id !== category.id)
                    })
                  }}
                  onDeleteFile={index => {
                    const { _rawImages } = this.state;
                    this.setState({
                      _rawImages: _rawImages.filter((_img, idx) => idx !== index)
                    })
                  }}
                  onSaveImage={(data) => {
                    this.setState({_categoryFormSubmit: true}, () => {
                      if(data.deleted)
                        this.props.deleteCollectionImageById(this.state._form.id, data.id)
                      else{
                        const { id, URL: url, orientation, category_id } = data;
                        this.props.saveCollectionImage(this.state._form.id, {id, category_id, url, orientation})
                      }
                    })
                  }}
                  onDropFiles={files => this._handleDropzoneItemChange(files, category.id)}
                  imageFiles={this.state._rawImages.filter(img => img.category_id === category.id)}
                  images={category.images}
                />
                ))
              }
              </div>
              <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                {this.props.edit && this.state._form.id && this.state._form.categories.length? (
                  <ButtonLoading
                  type="button"
                  onClick={this._handleSubmitCollectionCategories}
                  >
                    Update Changes
                  </ButtonLoading>
                ): null}
              </div>
            </CardBody>
          </Card>
          {/* {
            this.props.edit?(
              )
            ):null
          } */}
        </Col>
      </Row>
    )
  }
}
const mapStateToProps= ({ masterCollection, masterCollection: { detail, isSubmit, isFetch, error, isImageDataSubmit }, masterCategories, imageUpload }) => ({
  masterCollection: {
    detail, isSubmit, isImageDataSubmit, isFetch, error
  },
  dataCategories: masterCategories.options,
  dataCollectionCategories: masterCollection.collectionCategories,
  dataUploaded: imageUpload
});
const mapDispatchToProps = (dispatch) => ({
  find:(id) => dispatch(getDetailCollection(id)),
  delete:(id) => dispatch(deleteCollection(id)),
  save:(payload) => dispatch(saveCollection(payload)),
  new:() => dispatch(newCollection()),
  saveCollectionCategories: (id, payload) => dispatch(saveCollectionCategories(id, payload)),
  getCategoryOptionList: ()=> dispatch(getCategoryOptionList()),
  saveCollectionImage: (collectionId, payload) => dispatch(saveCollectionImage(collectionId, payload)),
  deleteCollectionImageById: (collectionId, id) => dispatch(deleteCollectionImageById(collectionId, id)),
  uploadCollectionImage: (collectionId, file) => dispatch(uploadCollectionImage(file, `${S3_FOLDER_PATH}${collectionId}/`)),
  uploadImage: (payload, rawImages) => dispatch(uploadImage(payload, rawImages)),
  deleteImage: (payload) => dispatch(deleteImage(payload)),
});
export default connect(mapStateToProps,mapDispatchToProps)(CollectionForm);