import {
  AUTH_TOKEN_KEY,
  SELLER_VERIFICATION_API,
  GET_LIST_SELLER_VERIFICATION,
  FETCHING_SELLER_VERIFICATION,
  SELLER_VERIFICATION_ERROR,
  GET_DETAIL_SELLER_VERIFICATION,
  UPDATE_STATUS_SELLER_VERIFICATION,
  UPDATE_STATUS_SELLER_VERIFICATION_ERROR
} from '../constants';
import api from '../utils/Api';
import { manipulateErrors, checkAuthorized } from '../utils';
import { logout } from './authAction';

const onSuccess=(res,type)=>(
	{
		type:type,
		payload:res		
	}
)
const onError=(err)=>{    
	if(!checkAuthorized(manipulateErrors(err)))
        return logout();
    return {
        type:SELLER_VERIFICATION_ERROR,
        payload:manipulateErrors(err)
    }
}
const isFetch=(payload)=>(
    {
        type:FETCHING_SELLER_VERIFICATION,
        payload
    }
)

const onUpdateStatusError = (id, err) => {
	if(!checkAuthorized(manipulateErrors(err)))
		return logout();
	return {
		type: UPDATE_STATUS_SELLER_VERIFICATION_ERROR,
		payload: { id, ...manipulateErrors(err) }
	}
}

export const getListSellerVerification=(payload)=>{
	return dispatch=>(
		dispatch(isFetch({type:'fetch',status:true})),
		api({
			Authorization:'Bearer '+localStorage.getItem(AUTH_TOKEN_KEY)
		}).get(SELLER_VERIFICATION_API, payload)
		.then(res => {
			dispatch(onSuccess(res.data,GET_LIST_SELLER_VERIFICATION));
			dispatch(isFetch({type:'fetch',status:false}));
		})
		.catch(err => {
			dispatch(onError(err));
			dispatch(isFetch({type:'fetch',status:false}));
		})		
	)
}

export const updateStatusSellerVerification=(id, payload)=>{
	return dispatch=>(
		dispatch(isFetch({type:'submit',status:true})),
		api({
			Authorization:'Bearer '+localStorage.getItem(AUTH_TOKEN_KEY)
		}).put(`/admins/seller-verifications/${id}`,payload)
		.then(res => {
				dispatch(onSuccess(res.data,UPDATE_STATUS_SELLER_VERIFICATION));
				dispatch(isFetch({type:'submit',status:false}));
		})
		.catch(err => {
			dispatch(onUpdateStatusError(id, err));
			dispatch(isFetch({type:'submit',status:false}));
		})
	)
}

export const getDetailSellerVerification=(id)=>{
	return dispatch=>(
		dispatch(isFetch({type:'fetch',status:true})),
		api({
			Authorization:'Bearer '+localStorage.getItem(AUTH_TOKEN_KEY)
		}).get(`/admins/seller-verifications/${id}`)
		.then(res => {
			dispatch(onSuccess(res.data,GET_DETAIL_SELLER_VERIFICATION));
			dispatch(isFetch({type:'fetch',status:false}));
		})
		.catch(err => {
			dispatch(onError(err));
			dispatch(isFetch({type:'fetch',status:false}));
		})
	)
}