import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Row,
  Col,
  Card,
  CardBody,
  CardText,
  Collapse
} from 'reactstrap';
import Paginations from '../../../components/Paginations';
import PaginationSummaries from '../../../components/Paginations/PaginationSummaries';
import { CardHeaderWithToolbars,Title } from '../../../components/Card/CardHeaderWithToolbars';
import FiltersPanel from '../Filters';
import Toolbars from './Filters/Toolbars';
import FilterContainer from '../../../components/Container/FilterContainer/FilterContainer';
import {Tbl, TblHead, TblBody} from '../../../components/Tbl';
import { generateNumber } from '../../../utils';
import { DEFAULT_SORT_BY, DEFAULT_PER_PAGE } from '../../../constants/settings';
import { ModalRejectOrder, ModalAcceptOrderConfirmation } from './Modals';
import { ModalInfoCreditCard } from '../../../components/Modals';
import { getOutstandingSaleConfirmation, doAcceptSale, doDeclineSale } from '../../../actions/saleAction';
import RowItem from './RowItem';
import RowHeader from './RowHeader';
import Analytics from "../../../services/Analytics";

const defaultParams = {
  type:'outstanding_confirmation',
  sort_by : DEFAULT_SORT_BY,
  per_page : DEFAULT_PER_PAGE
}

class OutstandingSaleConfirmation extends Component{
  constructor(props){
    super(props);
    this.state={
      _params:{
        ...defaultParams
      },
      _selectedRow: null,
      _modalReject:false,
      _modalAccept:false,
      _formRejectSelected:null,
      _formAcceptSelected:null,
      _formReceivedSelected:null,
      _rejectProcess:false,
      _acceptProcess:false,
      _filterOpen: false,
      _collapse: false,
      _modalCreditCard: false,
      _invoiceNumber: null,
    }
    this._handleApplyFilter = this._handleApplyFilter.bind(this);
    this._handleToggleFilterPanel = this._handleToggleFilterPanel.bind(this);
    this._handleGoto = this._handleGoto.bind(this);
    this._refreshList = this._refreshList.bind(this);
    this._handleRefresh = this._handleRefresh.bind(this);
    this._handleResetFilter = this._handleResetFilter.bind(this);
    this._onCloseRejectModal = this._onCloseRejectModal.bind(this);
    this._onCloseAcceptModal = this._onCloseAcceptModal.bind(this);
    this._handleCategoryChanged = this._handleCategoryChanged.bind(this);
    this._handleToggleCollapse = this._handleToggleCollapse.bind(this);
    this._handleToggleModalCreditCard = this._handleToggleModalCreditCard.bind(this);
  }

  componentDidMount(){
    this._getList(this.state._params);
  }

  _getList(rawParams = {}){
    let p = { ...rawParams }
    if(p.voucher){
      const { voucher, ...other } = p;
      if(Number(voucher.id)){
        p = { ...other, voucher_id: voucher.id }
      }
      else{
        p = { ...other, voucher_code: voucher.code }
      }
    }
    if(p.seller && p.seller.id){
      const { seller, ...other } = p;
      p = { ...other, seller_id: seller.id }
    }
    const params = Object.keys(p)
    .filter(key => p[key]!='')
    .reduce((obj, current)=> ({...obj, [current]: p[current]}), {});
    this.props.getList({params});
  }

  componentDidUpdate(prevProps) {
    if(prevProps.sectionName !== this.props.sectionName){
      const { _collapse } = this.state;
      if(_collapse && this.props.sectionName !== 'outstanding_confirmation'){
        this.setState({ _collapse: !_collapse });
      }
    }
    if(this.state._rejectProcess
      &&!this.props.sale.isSubmit
      &&prevProps.sale.isSubmit!==this.props.sale.isSubmit) {
      if(this.props.sale.detail.status_code===200){
        this._getList(this.state._params);
        this.setState({
          _rejectProcess:!this.state._rejectProcess,
          _modalReject:false,
          _selectedRow: null,
          _formRejectSelected: null
        });
      }
      else{
        this.setState({_rejectProcess:!this.state._rejectProcess});
      }
    }
    else if(this.state._acceptProcess
      &&!this.props.sale.isSubmit
      &&prevProps.sale.isSubmit!==this.props.sale.isSubmit){
      if(this.props.sale.detail.status_code===200){
        this._getList(this.state._params);
        this.setState({
          _acceptProcess:!this.state._acceptProcess,
          _modalAccept:false,
          _selectedRow: null,
          _formAcceptSelected: null
        });
      }
      else{
        this.setState({_acceptProcess:!this.state._acceptProcess});
      }
    }
  }

  _refreshList(){
    // Exclude page...
    const { page, ..._params } = this.state._params;
    this.setState({
      _params:{ ..._params }
    },()=>this._getList(this.state._params));
  }

  _toggleFilterPanel(){
    this.setState({_filterOpen:!this.state._filterOpen})
  }

  _handleToggleFilterPanel(e){
    e.preventDefault();
    this._toggleFilterPanel();
    if(!this.state._collapse)
      this._handleToggleCollapse(e);
  }

  _handleRefresh(e){
    e.preventDefault();
    this._refreshList();
    if(!this.state._collapse)
      this._handleToggleCollapse(e);
  }

  _handleResetFilter(e){
    e.preventDefault();
    const newState = this.state._filterOpen?{_filterOpen:!this.state._filterOpen}:{};
    this.setState({
      ...newState,
      _params:{ ...defaultParams },
      _isResetFilter: true
    },()=>this._getList(this.state._params));
  }

  _handleApplyFilter(payloadFilter){
    this.setState({
      _params: {
        ...this.state._params,
        ...payloadFilter,
        page: 1
      }
    },()=>this._getList(this.state._params));
  }

  _handleToggleCollapse(e) {
    e.preventDefault();
    this.setState({_collapse: !this.state._collapse});
    if(!this.state._collapse === true)
      this.props.onChangeSection();
  }

  _onRenderRowItem(){
    if(this.props.sale.outstanding_confirmation.list.status_code===200&&this.props.sale.outstanding_confirmation.list.data.length>0) {
      const { current_page,per_page } =  this.props.sale.outstanding_confirmation.list;
      return this.props.sale.outstanding_confirmation.list.data.map((item,index)=>{
        const rowId = generateNumber(per_page,current_page,index);
        return(
          <RowItem
            key={rowId}
            timer={true}
            rowId={rowId}
            data={item}
            id={item.id}
            quantity={item.offer.quantity}
            buyerEmail={item.buyer_email}
            invoiceNumber={item.invoice_number}
            orderDate={item.created_at}
            updatedAt={item.updated_at}
            orderStatus={item.status}
            totalAmount={item.total_price}
            displayName={item.sale_product.display_name}
            SKU={item.sale_product.SKU}
            colour={item.sale_product.colour}
            preOrder={item.sale_product.pre_order}
            preVerified={item.sale_product.pre_verified}
            consignmentId={item.sale_product.consignment_id}
            sneakersCondition={item.sale_product.sneakers_condition}
            category={item.sale_product.category_name}
            size={item.sale_product.size.US}
            sellerResponded={item.seller_responded}
            hijacked={item.hijacked}
            autoDisburse={item.autodisburse}
            autoCancel={item.auto_cancelled_at}
            isSelected={this.state._selectedRow===item.id}
            onOpenReceivedModal={()=>this._onOpenReceivedModal(item)}
            onOpenAcceptModal={()=>this._onOpenAcceptModal(item)}
            onOpenRejectModal={()=>this._onOpenRejectModal(item)}
            bank={item.offer.bank_code}
            paymentVia={item.offer.payment_via}
            paymentMethod={item.offer.payment_method}
            onOpenCreditCard={()=>this._onOpenCreditCardModal(item)}
            status={item.status}
            marked={item.marked}
            notes={item.sale_notes}
            loginId={this.props.injectedProps.id}
            // markedNotes={item.marked_notes}
            markedAt={item.open_marked_at}
            markedBy={item.open_marked_by}
            closeMarkedAt={item.close_marked_at}
            unreadNoteCount={item.unread_notes_count}
            rack={item.offer.user_sell.rack}
            voucherCode={item.voucher_code? item.voucher_code: item.voucherable? item.voucherable.code: item.voucherable_id}
          />
        )
      })
    }
    return null;
  }

  _onReject(id, data){
    const { email } = this.props;
    const { _formRejectSelected } = this.state;
  
    this.setState({_rejectProcess:true},()=>{
      this.props.declineSale(id, data);
      Analytics.recordRejectOutstandingSale(
        email,
        data.notes,
        _formRejectSelected.invoice_number,
        _formRejectSelected.buyer_email,
        _formRejectSelected.price
      );
    })
  }

  _onSellerResponded(id, data){
    const { email } = this.props;
    const { _formAcceptSelected } = this.state;

    this.setState({_acceptProcess:true},()=>{
      this.props.acceptSale(id, {...data});
      Analytics.recordAcceptOutstandingConfirmation(
        email,
        data.notes,
        _formAcceptSelected.invoice_number,
        _formAcceptSelected.buyer_email,
        _formAcceptSelected.price
      );
    })
  }

  _onOpenAcceptModal(item){
    this.setState({_formAcceptSelected: item, _selectedRow: item.id},()=>this._toggle('_modalAccept'));
  }

  _onOpenRejectModal(item){
    this.setState({_formRejectSelected: item, _selectedRow: item.id},()=>this._toggle('_modalReject'));
  }

  _onCloseAcceptModal(){
    this.setState({_formAcceptSelected: null, _selectedRow: null},()=>this._toggle('_modalAccept'));
  }

  _onCloseRejectModal(){
    this.setState({_formRejectSelected: null, _selectedRow: null},()=>this._toggle('_modalReject'));
  }

  _onOpenCreditCardModal(item){
    this.setState({_invoiceNumber: item.invoice_number},()=>this._handleToggleModalCreditCard());
  }

  _handleToggleModalCreditCard() {
    this._toggle('_modalCreditCard');
  }


  _toggle(_modalState){
    const lastState={...this.state};
    lastState[_modalState]=!lastState[_modalState];
    this.setState(lastState);
  }

  _handleGoto(page){
    const _params=this.state._params;
    _params.page=page;
    this.setState({_params}, ()=>this._getList(this.state._params));
  }

  _onRenderPagination(){
    const { status_code,data } = this.props.sale.outstanding_confirmation.list;
    const hasData = (status_code===200 && data.length) || null;
    return hasData && (
      <Paginations
        size="sm"
        lastPage={this.props.sale.outstanding_confirmation.list.last_page}
        perPage={this.props.sale.outstanding_confirmation.list.per_page}
        total={this.props.sale.outstanding_confirmation.list.total}
        maxPage={8}
        path={this.props.sale.outstanding_confirmation.list.path}
        currentPage={this.props.sale.outstanding_confirmation.list.current_page}
        prevPageUrl={this.props.sale.outstanding_confirmation.list.prev_page_url}
        nextPageUrl={this.props.sale.outstanding_confirmation.list.next_page_url}
        handleGotoCallback={this._handleGoto}
      />
    );
  }

  async _handleCategoryChanged(category){
    await this.setState({
      _params: {
        ...this.state._params,
        category: category === ''? category: category.value,
        page: 1,
      },
    });
    const { _params } = this.state;
    this._getList(_params);
  }

  render(){
    return (
      <div className="animated fadeIn" ref={this.props.childRef}>
        <Row>
          {/* Reject Modals */}
          <ModalRejectOrder
            isOpen={this.state._modalReject}
            onCancel={this._onCloseRejectModal}
            onSubmit={(id, form)=>this._onReject(id, form)}
            sale={this.state._formRejectSelected}
            error={this.props.sale.error}
            isSubmit={this.state._rejectProcess}
          />
          {/* Accept Modals */}
          <ModalAcceptOrderConfirmation
            receivedBy={this.props.email}
            isOpen={this.state._modalAccept}
            onCancel={this._onCloseAcceptModal}
            onSubmit={(id, form)=>this._onSellerResponded(id, form)}
            sale={this.state._formAcceptSelected}
            error={this.props.sale.error}
            isSubmit={this.state._acceptProcess}
          />
          <ModalInfoCreditCard
            isOpen={this.state._modalCreditCard}
            toggle={this._handleToggleModalCreditCard}
            invoiceNumber={this.state._invoiceNumber}
          />
          <Col xs={12}>
            <Card>
              <CardHeaderWithToolbars>
                <Title>
                  <i className="fa fa-align-justify"></i>
                  <span>Outstanding Sale Confirmations</span>
                </Title>
                <Toolbars
                  isOpen={this.state._filterOpen}
                  isCollapse={this.state._collapse}
                  onToggleFilter={this._handleToggleFilterPanel}
                  onRefreshList={this._handleRefresh}
                  onResetFilter={this._handleResetFilter}
                  onToggleCollapse={this._handleToggleCollapse}
                  total={this.props.sale.outstanding_confirmation.list.total}
                />
              </CardHeaderWithToolbars>
              <FilterContainer>
                <FiltersPanel
                  {...this.state._params}
                  onApplyFilter={this._handleApplyFilter}
                  isReset={this.state._isResetFilter}
                  onResetFilterCallback={()=>this.setState({_isResetFilter: false, _filterOpen: false})}
                  isOpen={this.state._filterOpen}
                />
              </FilterContainer>
              <CardBody>
                <CardText>
                    Outstanding sale confirmations means we waiting for seller confirm his sale. Please contact seller as soon as possible. Auto Cancel by cron if reach time total of 72 hours unconfirmed sale & in here after more than 24 hours.<br />
                </CardText>
                <Collapse isOpen={this.state._collapse && this.props.sectionName === 'outstanding_confirmation'}>
                  <div className="table-responsive" >
                    <PaginationSummaries
                      from={this.props.sale.outstanding_confirmation.list.from}
                      to={this.props.sale.outstanding_confirmation.list.to}
                      total={this.props.sale.outstanding_confirmation.list.total}
                    />
                    <Tbl>
                      <TblHead>
                        <RowHeader
                          categoryName={this.state._params.category}
                          categoryValue={this.state._params.category}
                          onCategoryChanged={this._handleCategoryChanged}
                          timer={true}
                        />
                      </TblHead>
                      <TblBody
                        isLoad={this.props.sale.outstanding_confirmation.isFetch}
                        hasRow={this.props.sale.outstanding_confirmation.list.status_code===200&&this.props.sale.outstanding_confirmation.list.data.length>0}
                        columnCount={8}
                      >
                      { this._onRenderRowItem() }
                      </TblBody>
                    </Tbl>
                  </div>
                  {this._onRenderPagination()}
                </Collapse>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    )
  }
}

const mapStateToProps= ({sale, auth:{ email }}) => {
	return {
    sale,
    email
	}
};

const mapDispatchToProps = (dispatch) => {
	return{
		acceptSale:(id,payload) => dispatch(doAcceptSale(id,payload)),
		declineSale:(id,payload) => dispatch(doDeclineSale(id,payload)),
		getList:(query) => dispatch(getOutstandingSaleConfirmation(query))
	}

};
const enhance = connect(mapStateToProps,mapDispatchToProps);
export default enhance(OutstandingSaleConfirmation);
