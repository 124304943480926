import React from 'react';
import {
  Col,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  Button
} from 'reactstrap';
import propTypes from 'prop-types';

const SearchForm =  props => {
  return(
    <Form onSubmit={props.onSubmit}>
      <FormGroup row>
          <Col xs={12} sm={12} md={6}>
              <InputGroup>
                  <Input type="text" placeholder="Search Product name, SKU, CG ID, Sell ID..." name="keyword" value={props.keyword} onChange={props.onInputChange}/>
                  <InputGroupAddon addonType="append">
                    <Button color="primary">Search</Button>
                  </InputGroupAddon>
              </InputGroup>
          </Col>
      </FormGroup>
    </Form>
  )
}
export default SearchForm;
SearchForm.propTypes= {
  keyword: propTypes.string,
  onInputChange: propTypes.func,
  onSubmit: propTypes.func.isRequired
}

SearchForm.defaultProps= {
  keyword: ''
}