import {
    AUTH_TOKEN_KEY,
    ACCOUNT_BANK_ROUTE_API,
    ACCOUNT_BANK_FETCHING,
    ACCOUNT_BANK_DETAIL_FETCH,
    ACCOUNT_BANK_LIST_FETCH,
	ACCOUNT_BANK_ERROR,
	ACCOUNT_BANK_ADD,
	ACCOUNT_BANK_UPDATE,
	ACCOUNT_BANK_DELETE,
	ACCOUNT_BANK_CLEAR_FORM
} from '../constants';
import api from '../utils/Api';
import { manipulateErrors, checkAuthorized } from '../utils';
import { logout } from './authAction';
const onSuccess=(res,type)=>(
	{
		type:type,
		payload:res		
	}
)
const onError=(err)=>{    
	if(!checkAuthorized(manipulateErrors(err)))
        return logout();
    return {
        type:ACCOUNT_BANK_ERROR,
        payload:manipulateErrors(err)
    }
}
const isFetch=(payload)=>(
    {
        type:ACCOUNT_BANK_FETCHING,
        payload
    }
)

export const getListAccountBank=(query)=>{
	return dispatch=>(
		dispatch(isFetch({type:'fetch',status:true})),
		api({
			Authorization:'Bearer '+localStorage.getItem(AUTH_TOKEN_KEY)
		}).get(ACCOUNT_BANK_ROUTE_API,query)
		.then(res => {
			dispatch(onSuccess(res.data,ACCOUNT_BANK_LIST_FETCH));
			dispatch(isFetch({type:'fetch',status:false}));
		})
		.catch(err => {
			dispatch(onError(err));
			dispatch(isFetch({type:'fetch',status:false}));
		})		
	)
}

export const getDetailAccountBank=(id)=>{
	return dispatch=>(
		dispatch(isFetch({type:'fetch',status:true})),
		api({
			Authorization:'Bearer '+localStorage.getItem(AUTH_TOKEN_KEY)
		}).get(`${ACCOUNT_BANK_ROUTE_API}/${id}`)
		.then(res => {
			dispatch(onSuccess(res.data,ACCOUNT_BANK_DETAIL_FETCH));
			dispatch(isFetch({type:'fetch',status:false}));
		})
		.catch(err => {
			dispatch(onError(err));
			dispatch(isFetch({type:'fetch',status:false}));
		})		
	)
}

const createAccountBank=(payload)=>{
	return dispatch => (
		dispatch(isFetch({type:'submit',status:true})),
        api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).post(`${ACCOUNT_BANK_ROUTE_API}`,payload)
		.then(res=>{
			dispatch(onSuccess(res.data,ACCOUNT_BANK_ADD))
			dispatch(isFetch({type:'submit',status:false}))
		})
		.catch(err => {
			dispatch(onError(err));
			dispatch(isFetch({type:'submit',status:false}));
		})
	)
}

const updateAccountBank=(id, payload)=>{
	return dispatch => (
		dispatch(isFetch({type:'submit',status:true})),
		api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).put(`${ACCOUNT_BANK_ROUTE_API}/${id}`,payload)
		.then(res=>{
			dispatch(onSuccess(res.data,ACCOUNT_BANK_UPDATE))
			dispatch(isFetch({type:'submit',status:false}))
		})
		.catch(err => {
			dispatch(onError(err));
			dispatch(isFetch({type:'submit',status:false}));
		})
	)
}

export const saveAccountBank = (payload) =>{
	const {id,account_name, account_number, bank_name, branch}=payload;
	return dispatch =>{
		if(payload.id===undefined)
			dispatch(createAccountBank({account_name, account_number, bank_name, branch}));
		else 
			dispatch(updateAccountBank(id,{account_name, account_number, bank_name, branch}));

	}
}

export const deleteAccountBank = (id) =>{
	return dispatch => (
		dispatch(isFetch({type:'submit',status:true})),
		api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).delete(`${ACCOUNT_BANK_ROUTE_API}/${id}`)
		.then(res=>{
			dispatch(onSuccess(res.data,ACCOUNT_BANK_DELETE))
			dispatch(isFetch({type:'submit',status:false}))
		})
		.catch(err => {
			dispatch(onError(err));
			dispatch(isFetch({type:'submit',status:false}));
		})
	)
}
export const clearFormAccountBank =()=>dispatch=>dispatch({type:ACCOUNT_BANK_CLEAR_FORM})
