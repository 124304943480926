import React from 'react';
import { Link } from 'react-router-dom';
import { Button, ButtonGroup } from 'reactstrap';
import ReactLoading from 'react-loading';
import propTypes from 'prop-types';

const ActionButtonsColumn = props => {
  return(
    props.isSelected?
      <ReactLoading type="bars" color="green" height={20} width={30}  className="loading-center"/>
    :
      <ButtonGroup>
        <Link to={`/payments/${props.id}`} className="btn btn-secondary btn-sm"><i className="fa fa-external-link"></i></Link>
        <Button color="success" size="sm" title="Deliver Now!" onClick={props.onOpenModalClick}><i className="fa fa-check"></i></Button>
      </ButtonGroup>
  )
}

export default ActionButtonsColumn;

ActionButtonsColumn.defaultProps={
  isSelected: false
}

ActionButtonsColumn.propTypes = {
  isSelected: propTypes.bool,
  id: propTypes.number.isRequired,
  onOpenModalClick: propTypes.func
}