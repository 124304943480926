import React from 'react';
import { connect } from 'react-redux';
import {
  Alert,
  Row,
  Col,
  Card,
	TabContent,
	TabPane,
	Nav,
  NavItem,
	NavLink
} from 'reactstrap';
import propTypes from 'prop-types';
import classnames from 'classnames';
import qs from 'query-string';
import { getRaffleRequestDetail, updateRaffleRequest } from '../../actions/raffleAction';
import { textChange } from '../../utils/form';
import privateView from '../../components/hocs/privateView';
import Header from './RaffleRequest/Detail/Header';
import TabRaffleRequestDetail from './RaffleRequest/Detail/TabRaffleRequestDetail';
import TabPaymentDetail from './RaffleRequest/Detail/TabPaymentDetail';
import TabSneakerDetail from './RaffleRequest/Detail/TabSneakerDetail';
import TabUserDetail from './RaffleRequest/Detail/TabUserDetail';
import TabSellerDetail from './RaffleRequest/Detail/TabSellerDetail';
import Analytics from "../../services/Analytics";

const DEFAULT_ERROR_500_MSG = 'We got something errors. Please ask our staff!';
const DEFAULT_ERROR_404_MSG = 'Sorry, cannot find what you are looking for.';
const DEFAULT_SUCCESS_MSG = 'Updating payment successfully.';
const DEFAULT_ERROR_422_MSG = 'Please fulfill your form.';
class RaffleRequestDetail extends React.Component {
  constructor(props){
    super(props)
    const queries = Object.assign({}, qs.parse(props.location.search));
    this.state = {
      _id: parseInt(this.props.match.params.id),
      activeTab: 'raffleRequestDetail',
      _error: null,
      _errorLoadData: false,
      _errorMsg: '',
      ...queries,
      _isLoading: true,
      _isSubmit:false,
      _isSuccessAlertDisplayed: false,
      _isErrorAlertDisplayed: false,
      _disabledDropdownStatus: false,
      _successMsg: DEFAULT_SUCCESS_MSG,
      data:{
        id: null,
        user_sell_id: null,
        shipping_id: null,
        product_variant_id: null,
        size_id: null,
        offer_id: null,
      }
    }
    this._onSubmit = this._onSubmit.bind(this);
    this._handleTextChange = this._handleTextChange.bind(this);
    this._alertSuccessToggle = this._alertSuccessToggle.bind(this);
    this._alertErrorToggle = this._alertErrorToggle.bind(this);
  }

  componentDidMount(){
    const { email } = this.props.injectedProps;
    this.props.getDetail(this.state._id)
    Analytics.recordPageView('raffle_req_detail', email, {
      url: window.location.href,
      location: this.props.location,
      match: this.props.match
    });
  }

  componentDidUpdate(prevProps){
    if(prevProps.raffleRequest.isFetch && prevProps.raffleRequest.isFetch !== this.props.raffleRequest.isFetch){
      const { error } = this.props.raffleRequest.details;
      if(error){
        let msg = null;
        switch(error.status_code){
          case 500:
            msg = DEFAULT_ERROR_500_MSG; break;
          case 404:
            msg = DEFAULT_ERROR_404_MSG; break;
          default:
            msg = error.message;
        }
        this.setState({
          _error: error,
          _errorLoadData: true,
          _errorMsg: msg
        })
      }
      else{
        let { _disabledDropdownStatus } = this.state;
        let { user, shipping, raffle_entries, ...data } = this.props.raffleRequest.details.data;
        if(data.status == 'ACCEPTED')
        _disabledDropdownStatus = true;
        this.setState({
          _error: null,
          _errorLoadData: null,
          _isLoading: false,
          _disabledDropdownStatus,
          data:{
            ...data,
            user,
            shipping:{
              user,
              ...shipping
            },
            raffle_entries,
            raffle_entry_id:raffle_entries.length > 0 ? raffle_entries[0].id : null
          }
        })
      }
    }
    if(this.state._isSubmit && prevProps.raffleRequest.details.isSubmit 
      && !this.props.raffleRequest.details.isSubmit){
      const { error } = this.props.raffleRequest.details;
      if(error){
        this.setState({
          _isSubmit: false,
          _error: error,
          _isErrorAlertDisplayed: true,
          _errorMsg: error.status_code === 422? DEFAULT_ERROR_422_MSG: error.message
        })
      }
      else{
        let { _disabledDropdownStatus } = this.state;
        let { user, shipping, raffle_entries, ...data } = this.props.raffleRequest.details.data;
        if(data.status == 'ACCEPTED')
          _disabledDropdownStatus = true;
        this.setState({
          _error: null,
          _isSubmit: false,
          _isSuccessAlertDisplayed: true,
          _disabledDropdownStatus,
          data:{
            ...data,
            user,
            shipping:{
              user,
              ...shipping
            },
            raffle_entries,
            raffle_entry_id:raffle_entries.length > 0 ? raffle_entries[0].id : null
          }
        })
      }
    }
  }

  _renderHeaderContent(){
    let { user, product_variant, size, ...data } = this.state.data;
    user = user || {};
    product_variant = product_variant || {};
    size = size || {};
    return(
      <Header
        isLoading={this.state._isLoading}
        email={user.email}
        createdAt={data.created_at}
        displayName={product_variant.display_name}
        SKU={product_variant.SKU}
        colour={product_variant.colour}
        sizeUS={size.US}
        payment={data.offer}
        paymentMethod={data.payment_method}
        status={data.status}
        sneakerCondition={data.sneakers_condition}
        boxCondition={data.box_condition}
        preOrder={data.pre_order}
        expiredAt={data.expired_at}
        totalAmount={data.total_amount}
        category={product_variant&&product_variant.product&&product_variant.product.category.name}
      />
    )
  }

  toggleTab(tab) {
		if (this.state.activeTab !== tab) {
			this.setState({
        activeTab: tab
      });
      const { pathname } = this.props.location;
      const search = qs.stringify({
        activeTab: tab
      });
      this.props.history.replace({ pathname, search })
		}
  }

  _handleTextChange(e){
    const lastState = this.state.data;
    this.setState({
      data:{
        ...textChange(e, lastState)
      }
    });
  }

  _onSubmit(e){
    e.preventDefault();
    this.setState({
      _isSubmit: true,
      _error: null
    },()=>{
      const { status, raffle_entry_id,  payment_method, administration_fee } = this.state.data;
      this.props.updateDetail(this.state._id, {status, raffle_entry_id, payment_method, administration_fee })
      if(this.state._isErrorAlertDisplayed)
        this._alertErrorToggle();
      if(this.state._isSuccessAlertDisplayed)
        this._alertSuccessToggle()
    })
  }

  _alertSuccessToggle(){
    this.setState({
      _isSuccessAlertDisplayed: !this.state._isSuccessAlertDisplayed
    })
  }

  _alertErrorToggle(){
    this.setState({
      _isErrorAlertDisplayed: !this.state._isErrorAlertDisplayed
    })
  }

  render(){
    return(
      <div className="animated fadeIn">
        <Row>
          <Col xs={12} style={{padding:0}}>
          {
            this.state._errorLoadData?
            <Card body>
              <Alert
                color="danger"
                className="text-center"
                isOpen={this.state._errorAfterFetched}
              >
                <strong>Ooops. </strong>{this.state._errorMsg}
              </Alert>
            </Card>
            :
            <Card body>
              { this._renderHeaderContent() }
              <Nav className="nav-tabs--custom" tabs>
                <NavItem>
                  <NavLink
                    className={classnames({ active: this.state.activeTab === 'raffleRequestDetail' })}
                    onClick={() => this.toggleTab('raffleRequestDetail')}
                  >
                    Raffle Request Details
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: this.state.activeTab === 'paymentDetail' })}
                    onClick={() => this.toggleTab('paymentDetail')}
                  >
                    Payment Details
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: this.state.activeTab === 'productDetail' })}
                    onClick={() => this.toggleTab('productDetail')}
                  >
                    Product Details
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: this.state.activeTab === 'userDetail' })}
                    onClick={() => this.toggleTab('userDetail')}
                  >
                    User & Shipping
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: this.state.activeTab === 'sellerDetail' })}
                    onClick={() => this.toggleTab('sellerDetail')}
                  >
                    Seller
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent className="tab-content--custom" activeTab={this.state.activeTab}>
                <TabPane className="tab-content--custom__tab-pane" tabId="raffleRequestDetail">
                  <TabRaffleRequestDetail
                    isLoading={this.state._isLoading}
                    dataRaffle={this.state.data}
                    handleTextChange={this._handleTextChange}
                    onSubmit={this._onSubmit}
                    error={this.state._error}
                    isSubmit={this.state._isSubmit}
                    errorMsg={this.state._errorMsg}
                    successMsg={this.state._successMsg}
                    disabledDropdownStatus={this.state._disabledDropdownStatus}
                    isErrorAlertDisplayed={this.state._isErrorAlertDisplayed}
                    isSuccessAlertDisplayed={this.state._isSuccessAlertDisplayed}
                  />
                </TabPane>
                <TabPane className="tab-content--custom__tab-pane" tabId="paymentDetail">
                  <TabPaymentDetail
                    isLoading={this.state._isLoading}
                    paymentId={this.state.data.offer_id}
                    payment={this.state.data.offer}
                    productVariant={this.state.data.product_variant}
                  />
                </TabPane>
                <TabPane className="tab-content--custom__tab-pane" tabId="productDetail">
                  <TabSneakerDetail
                    isLoading={this.state._isLoading}
                    userSellId={this.state.data.user_sell_id}
                    userSell={this.state.data.user_sell}
                    payment={this.state.data.offer}
                    productVariant={this.state.data.product_variant_id !== null? this.state.data.product_variant: undefined}
                    size={this.state.data.size_id !== null? this.state.data.size: undefined}
                  />
                </TabPane>
                <TabPane className="tab-content--custom__tab-pane" tabId="userDetail">
                  <TabUserDetail
                    isLoading={this.state._isLoading}
                    shipping={this.state.data.shipping_id !== null ? this.state.data.shipping: undefined}
                  />
                </TabPane>
                <TabPane className="tab-content--custom__tab-pane" tabId="sellerDetail">
                  <TabSellerDetail
                    isEmpty={this.state.data.offer_id === null}
                    isLoading={this.state._isLoading}
                    seller={this.state.data.offer_id !== null? this.state.data.offer.user_sell.user : undefined}
                  />
                </TabPane>
              </TabContent>
            </Card>
          }
          </Col>
        </Row>
      </div>
    )
  }
}

const mapStateToProps =  ({ raffle: { requests }, auth: { isFetch, isLogged, roles, id, email } }) => ({
  raffleRequest: requests,
  guardData: { isFetch, isLogged, roles, id, email }
})

const mapDispatchToProps = dispatch => ({
  getDetail: id => dispatch(getRaffleRequestDetail(id)),
  updateDetail: (id, payload) => dispatch(updateRaffleRequest(id, payload))
})

const enhance = connect(mapStateToProps, mapDispatchToProps);

export default enhance(privateView(RaffleRequestDetail));