import React from 'react';
import propTypes from 'prop-types';
import Loading from 'react-loading-animation';
import {
  Card,
  CardHeader,
  CardBody,
} from 'reactstrap';
import {
  FieldRow,
  FieldColumn,
  FieldLabel,
  FieldValue
} from '../../../../styled/Fields';
import { _getValue, _getFloatValue } from '../../../../utils/form';
import { getPaymentType } from '../../../../constants/kickavenue';
import { TextRupiah } from '../../../../components/Text';
import { connect } from 'react-redux';
import { bidDetailSelector } from '../../../../reducers/selectors/bidSelector';

const OfferDetailCard = props => (
  <Card>
    <CardHeader>
      Offer Details
    </CardHeader>
    {
      props.isLoading?
      <CardBody>
        <Loading/>
      </CardBody>
      :
      <CardBody>
        <FieldRow>
          <FieldColumn className="col-xs-12 col-md-6">
            <FieldLabel>Offer ID</FieldLabel>
            <FieldValue>
              <span>{_getValue(props.id)}</span>
            </FieldValue>
          </FieldColumn>
        </FieldRow>
        <FieldRow>
          <FieldColumn className="col-12">
            <FieldLabel>Offer Code</FieldLabel>
            <FieldValue>
              <span>{ _getValue(props.code) }</span>
            </FieldValue>
          </FieldColumn>
        </FieldRow>
        <FieldRow>
            <FieldColumn className="col-12">
              <FieldLabel>Invoice Numb.</FieldLabel>
              <FieldValue>
                <span>{ _getValue(props.payment? props.payment.invoice_number: 'Not Set') }</span>
              </FieldValue>
            </FieldColumn>
          </FieldRow>
        <FieldRow>
          <FieldColumn className="col-xs-12 col-md-6">
            <FieldLabel>Currency</FieldLabel>
            <FieldValue>
              <span>{ _getValue(props.currency) }</span>
            </FieldValue>
          </FieldColumn>
          <FieldColumn className="col-xs-12 col-md-6">
            <FieldLabel>Payment Method</FieldLabel>
            <FieldValue>
              <span>{ getPaymentType(props.paymentMethod) }</span>
            </FieldValue>
          </FieldColumn>
          <FieldColumn className="col-xs-12 col-md-6">
            <FieldLabel>Amount</FieldLabel>
            <FieldValue>
              <TextRupiah
                prefix="Rp. "
                value={_getFloatValue(props.amount)}
              />
            </FieldValue>
          </FieldColumn>
          <FieldColumn className="col-xs-12 col-md-6">
            <FieldLabel>Original Price</FieldLabel>
            <FieldValue>
              <TextRupiah
                prefix="Rp. "
                value={_getFloatValue(props.originalPrice)}
              />
            </FieldValue>
          </FieldColumn>
          <FieldColumn className="col-xs-12 col-md-6">
            <FieldLabel>Deposit Amount</FieldLabel>
            <FieldValue>
              <TextRupiah
                prefix="Rp. "
                value={_getFloatValue(props.depositAmount)}
              />
            </FieldValue>
          </FieldColumn>
          <FieldColumn className="col-xs-12 col-md-6">
            <FieldLabel>Adm. Fee</FieldLabel>
            <FieldValue>
              <TextRupiah
                prefix="Rp. "
                value={_getFloatValue(props.administrationFee)}
              />
            </FieldValue>
          </FieldColumn>
          <FieldColumn className="col-xs-12 col-md-6">
            <FieldLabel>Courier</FieldLabel>
            <FieldValue>
              <span>{ _getValue(props.kaCourier) }</span>
            </FieldValue>
          </FieldColumn>
          <FieldColumn className="col-xs-12 col-md-6">
            <FieldLabel>Courier Services</FieldLabel>
            <FieldValue>
              <span>{ _getValue(props.kaCourierOption) }</span>
            </FieldValue>
          </FieldColumn>
          <FieldColumn className="col-xs-12 col-md-6">
            <FieldLabel>Courier Price</FieldLabel>
            <FieldValue>
              <TextRupiah
                prefix="Rp. "
                value={_getFloatValue(props.kaCourierPrice)}
              />
            </FieldValue>
          </FieldColumn>
          <FieldColumn className="col-xs-12 col-md-6">
            <FieldLabel>Processing Fee</FieldLabel>
            <FieldValue>
              <TextRupiah
                prefix="Rp. "
                value={_getFloatValue(props?.bidDetail?.processing_fee_calculated || 0)}
              />
            </FieldValue>
          </FieldColumn>
        </FieldRow>
        <FieldRow>
          <FieldColumn className="col-xs-12 col-md-6">
            <FieldLabel>Expired At</FieldLabel>
            <FieldValue>
              <span>{ _getValue(props.expiredAt) }</span>
            </FieldValue>
          </FieldColumn>
          <FieldColumn className="col-xs-12 col-md-6">
            <FieldLabel>Updated At</FieldLabel>
            <FieldValue>
              <span>{ _getValue(props.updatedAt) }</span>
            </FieldValue>
          </FieldColumn>
        </FieldRow>
      </CardBody>
    }
  </Card>
)

const mapStateToProps = state => ({
  bidDetail: bidDetailSelector(state)
})

export default connect(mapStateToProps)(OfferDetailCard);

OfferDetailCard.defaultProps = {
  id: 0,
  isLoading: false,
  payment: null,
  paymentMethod: 'bank_transfer',
  amount: 0,
  originalPrice: 0,
  depositAmount: 0,
  administrationFee: 0,
  kaCourierPrice: 0
}


OfferDetailCard.propTypes = {
  isLoading: propTypes.bool,
  id: propTypes.number,
  code: propTypes.string,
  payment: propTypes.object,
  paymentMethod: propTypes.string,
  currency: propTypes.string,
  amount: propTypes.oneOfType([propTypes.number, propTypes.string]),
  originalPrice: propTypes.oneOfType([propTypes.number, propTypes.string]),
  depositAmount: propTypes.oneOfType([propTypes.number, propTypes.string]),
  administrationFee: propTypes.oneOfType([propTypes.number, propTypes.string]),
  kaCourier: propTypes.string,
  kaCourierOption: propTypes.string,
  kaCourierPrice: propTypes.oneOfType([propTypes.number, propTypes.string]),
  expiredAt: propTypes.string,
  updatedAt: propTypes.string
}