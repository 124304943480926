import {
  GET_LIST_RAFFLE,
  GET_DETAIL_RAFFLE,
  CREATE_RAFFLE,
  UPDATE_RAFFLE,
  DELETE_RAFFLE,
  RAFFLE_ERROR,
  RAFFLE_REQUEST,
  GET_LIST_RAFFLE_ENTRIES,
  DELETE_RAFFLE_ENTRIES,
  GET_LIST_RAFFLE_COUPONS,
  CREATE_RAFFLE_COUPONS,
  UPDATE_RAFFLE_COUPONS,
  REDEEM_RAFFLE_COUPONS,
  GET_LIST_RAFFLE_REQUEST,
  GET_DETAIL_RAFFLE_REQUEST,
  UPDATE_RAFFLE_REQUEST,
  SUBMITTING_RAFFLE_ITEMS,
  SEARCH_RAFFLE_ITEMS,
  GET_LIST_RAFFLE_ITEMS,
  GET_LIST_RAFFLE_TIERS,
  SEARCH_RAFFLE_TIERS,
  SUBMITTING_RAFFLE_TIERS,
  RAFFLE_S3,
  GET_LIST_RAFFLE_GROUP,
  FETCHING_RAFFLE_GROUP,
  CREATE_RAFFLE_GROUP,
  UPDATE_RAFFLE_GROUP,
  DELETE_RAFFLE_GROUP,
  GET_DETAIL_RAFFLE_GROUP,
  CREATE_RAFFLE_ENTRIES,
  UPDATE_RAFFLE_ENTRIES
} from '../constants';


const initialState = {
  list: {
    isFetch: false,
    data: [],
    error: null
  },
  entries: {
    isFetch: false,
    data: [],
    error: null,
    details: {
      isDeleting: false,
      isSubmit: false,
      data: null,
      error: null,
    }
  },
  coupons: {
    isFetch: false,
    data: [],
    error: null,
    details: {
      isSubmit: false,
      data: null,
      error: null,
    }
  },
  details: {
    isFetch: false,
    // isImageDataFetch: false,
    isSubmit: false,
    // isImageDataSubmit: false,
    data: {
      id: null,
      deleted: false,
      images: []
    },
    error: null
  },
  uploadedImage: {
    isPending: false,
    results: null,
    error: null
  },
  requests: {
    isFetch: false,
    data: [],
    error: null,
    details: {
      isSubmit: false,
      data: null,
      error: null,
    }
  },
  raffleGroups: {
    data: [],
    error: null,
    isFetch: false
  },
  raffleItems: {
		dataSearch: [],
		data: [],
		isSearchFetch: false,
		isFetch: false,
		isSubmit: false,
		error: null
  },
  raffleTiers: {
		dataSearch: [],
		data: [],
		isSearchFetch: false,
		isFetch: false,
		isSubmit: false,
		error: null
  },
  detailsGroup: {
    isFetch: false,
    isSubmit: false,
    data: {
      id: null,
      deleted: false,
      images: []
    },
    error: null
  },
}

const onFetched = (lastState, { requestType, value }) => {
  switch(requestType){
    case GET_LIST_RAFFLE: return {...lastState, list: { ...lastState.list, isFetch: value }}
    case GET_DETAIL_RAFFLE: return {...lastState, details: { ...lastState.details, isFetch: value }}
    case GET_LIST_RAFFLE_ENTRIES: return {...lastState, entries: { ...lastState.entries, isFetch: value }}
    case CREATE_RAFFLE_ENTRIES:
    case UPDATE_RAFFLE_ENTRIES: return {...lastState, entries: { ...lastState.entries, details: { ...lastState.entries.details, isSubmit: value } }}
    case DELETE_RAFFLE_ENTRIES: return {...lastState, entries: { ...lastState.entries, details: { ...lastState.entries.details, isSubmit: value, isDeleting: value } }}
    case GET_LIST_RAFFLE_COUPONS: return {...lastState, coupons: { ...lastState.coupons, isFetch: value }}
    case REDEEM_RAFFLE_COUPONS:
    case UPDATE_RAFFLE_COUPONS:
    case CREATE_RAFFLE_COUPONS: return {...lastState, coupons: { ...lastState.coupons, details: { ...lastState.coupons.details, isSubmit: value } }}
    case CREATE_RAFFLE:
    case UPDATE_RAFFLE:
    case DELETE_RAFFLE: return {...lastState, details: { ...lastState.details, isSubmit: value }}
    case GET_LIST_RAFFLE_REQUEST: return {...lastState, requests: { ...lastState.requests, isFetch: value }}
    case GET_DETAIL_RAFFLE_REQUEST: return {...lastState, requests: { ...lastState.requests, isFetch: value }}
    case UPDATE_RAFFLE_REQUEST: return {...lastState, requests: { ...lastState.requests, details:{...lastState.requests.details, isSubmit:value}}}
    case GET_LIST_RAFFLE_ITEMS: return {...lastState, raffleItems: {...lastState.raffleItems, isFetch:value}};
    case SEARCH_RAFFLE_ITEMS: return {...lastState, raffleItems: {...lastState.raffleItems, isSearchFetch:value}};
    case SUBMITTING_RAFFLE_ITEMS: return {...lastState, raffleItems: {...lastState.raffleItems, isSubmit:value}};
    case SUBMITTING_RAFFLE_TIERS: return {...lastState, raffleTiers: {...lastState.raffleTiers, isSubmit:value}};
    case GET_LIST_RAFFLE_TIERS: return {...lastState, raffleTiers: {...lastState.raffleTiers, isFetch:value}};
    case SEARCH_RAFFLE_TIERS: return {...lastState, raffleTiers: {...lastState.raffleTiers, isSearchFetch:value}};
    case CREATE_RAFFLE_GROUP:
    case UPDATE_RAFFLE_GROUP:
    case DELETE_RAFFLE_GROUP: return {...lastState, detailsGroup: { ...lastState.detailsGroup, isSubmit: value }}
    case GET_LIST_RAFFLE_GROUP: return {...lastState, raffleGroups: { ...lastState.raffleGroups, isFetch: value }}
    case GET_DETAIL_RAFFLE_GROUP: return {...lastState, detailsGroup: { ...lastState.detailsGroup, isFetch: value }}
    default: return lastState;
  }
}

const onError = (lastState, { requestType, error, ...rest }) => {
  switch(requestType){
    case GET_LIST_RAFFLE: return {...lastState, list: { ...lastState.list, error: { ...error, ...rest } }}
    case GET_LIST_RAFFLE_ENTRIES: return {...lastState, entries: { ...lastState.entries, error: { ...error, ...rest } }}
    case CREATE_RAFFLE_ENTRIES:
    case UPDATE_RAFFLE_ENTRIES:
    case DELETE_RAFFLE_ENTRIES: return {...lastState, entries: { ...lastState.entries, details: { ...lastState.entries.details, error: { ...error, ...rest } } }}
    case GET_LIST_RAFFLE_COUPONS: return {...lastState, coupons: { ...lastState.coupons, error: { ...error, ...rest } }}
    case REDEEM_RAFFLE_COUPONS:
    case UPDATE_RAFFLE_COUPONS:
    case CREATE_RAFFLE_COUPONS: return {...lastState, coupons: { ...lastState.coupons, details: { ...lastState.coupons.details, error: { ...error, ...rest } } }}
    case GET_DETAIL_RAFFLE:
    case CREATE_RAFFLE:
    case UPDATE_RAFFLE:
    case DELETE_RAFFLE:return {...lastState, details: { ...lastState.details, error: { ...error, ...rest } }}
    case RAFFLE_S3: return { ...lastState, uploadedImage: { ...lastState.uploadedImage, isPending: false, error }}
    case GET_LIST_RAFFLE_REQUEST: return { ...lastState, requests: { ...lastState.requests, error: { ...error, ...rest } }}
    case GET_DETAIL_RAFFLE_REQUEST: return { ...lastState, requests: { ...lastState.requests, details: {...lastState.requests.details, error: { ...error, ...rest }}}}
    case UPDATE_RAFFLE_REQUEST: return { ...lastState, requests: { ...lastState.requests, details: {...lastState.requests.details, error: { ...error, ...rest }}}}
    case SEARCH_RAFFLE_ITEMS: return { ...lastState, raffleItems: { ...lastState.raffleItems, error: { ...error, ...rest } }}
    case GET_LIST_RAFFLE_ITEMS: return { ...lastState, raffleItems: { ...lastState.raffleItems, error: { ...error, ...rest } }}
    case GET_LIST_RAFFLE_TIERS: return { ...lastState, raffleTiers: { ...lastState.raffleTiers, error: { ...error, ...rest } }}
    case SEARCH_RAFFLE_TIERS: return { ...lastState, raffleTiers: { ...lastState.raffleTiers, error: { ...error, ...rest } }}
    case SUBMITTING_RAFFLE_TIERS: return { ...lastState, raffleTiers: { ...lastState.raffleTiers, error: { ...error, ...rest } }}
    case SUBMITTING_RAFFLE_ITEMS: return { ...lastState, raffleItems: { ...lastState.raffleItems, error: { ...error, ...rest } }}
    case CREATE_RAFFLE_GROUP:
    case UPDATE_RAFFLE_GROUP:
    case DELETE_RAFFLE_GROUP: return {...lastState, detailsGroup: { ...lastState.detailsGroup, error: { ...error, ...rest } }}
    case GET_LIST_RAFFLE_GROUP: return {...lastState, raffleGroups: { ...lastState.raffleGroups, error: { ...error, ...rest } }}
    default: return lastState;

  }
}
const reducer = (state = initialState, { type, payload }) => {
  switch(type){
    case RAFFLE_REQUEST: return onFetched(state, payload)
    case RAFFLE_ERROR: return onError(state, payload)
    case GET_LIST_RAFFLE: {
      return {
        ...state,
        list: {
          ...state.list,
          ...payload,
          error: null
        }
      }
    }
    case CREATE_RAFFLE:
    case UPDATE_RAFFLE:
    case DELETE_RAFFLE:
    case GET_DETAIL_RAFFLE: {
      const { data, ...restData } = payload;
      return {
        ...state,
        details: {
          ...state.details,
          ...restData,
          data: {
            ...state.details.data,
            ...data,
            deleted: type === DELETE_RAFFLE,
          },
          error: null
        }
      }
    }
    case GET_LIST_RAFFLE_ENTRIES: {
      return {
        ...state,
        entries: {
          ...state.entries,
          ...payload,
          error: null
        }
      }
    }
    case UPDATE_RAFFLE_ENTRIES:
    case CREATE_RAFFLE_ENTRIES:{
      const { data, ...restData } = payload;
      return {
        ...state,
        entries: {
          ...state.entries,
          details:{
            ...restData,
            data: {
              ...data
            },
            error: null,
          }
        }
      }
    }
    case DELETE_RAFFLE_ENTRIES:{
      const { data, ...restData } = payload;
      return {
        ...state,
        entries: {
          ...state.entries,
          details:{
            ...restData,
            data: {
              ...data,
              deleted: true
            },
            error: null,
          }
        }
      }
    }
    case GET_LIST_RAFFLE_COUPONS: {
      return {
        ...state,
        coupons: {
          ...state.coupons,
          ...payload,
          error: null
        }
      }
    }
    case REDEEM_RAFFLE_COUPONS:
    case UPDATE_RAFFLE_COUPONS:
    case CREATE_RAFFLE_COUPONS:{
      const { data, ...restData } = payload;
      return {
        ...state,
        coupons: {
          ...state.coupons,
          details:{
            ...restData,
            data: {
              ...data,
              deleted: true
            },
            error: null,
          }
        }
      }
    }
    case RAFFLE_S3: {
      return {
        ...state,
        uploadedImage: {
          ...state.uploadedImage,
          error: null,
          ...payload,
        }
      }
    }
    case GET_LIST_RAFFLE_REQUEST: {
      return {
        ...state,
        requests: {
          ...state.requests,
          ...payload,
          error: null
        }
      }
    }
    case GET_DETAIL_RAFFLE_REQUEST: {
      const { data, ...restData } = payload;
      return {
        ...state,
        requests: {
          ...state.requests,
          ...restData,
          details: {
            ...state.requests.details,
            data:data,
            error: null
          },
        }
      }
    }
    case UPDATE_RAFFLE_REQUEST: {
      const { data, ...restData } = payload;
      return {
        ...state,
        requests: {
          ...state.requests,
          ...restData,
          details: {
            ...state.requests.details,
            data:data,
            error: null
          },
        }
      }
    }
    case SUBMITTING_RAFFLE_ITEMS:{
      const { data, message, status_code } = payload;
      return {
        ...state,
        raffleItems: {
          ...state.raffleItems,
          error: null,
          dataSearch: data,
          message,
          status_code
        }
      }
    }
    case SEARCH_RAFFLE_ITEMS:{
      const { data, message, status_code } = payload;
      return {
        ...state,
        raffleItems: {
          ...state.raffleItems,
          error: null,
          dataSearch: data,
          message,
          status_code
        }
      }
    }
    case GET_LIST_RAFFLE_ITEMS:{
      const { data, message, status_code } = payload;
      return {
        ...state,
        raffleItems: {
          ...state.raffleItems,
          error: null,
          data:data,
          message,
          status_code
        }
      };
    }
    case SEARCH_RAFFLE_TIERS:{
      const { data, message, status_code } = payload;
      return {
        ...state,
        raffleTiers: {
          ...state.raffleTiers,
          error: null,
          dataSearch: data,
          message,
          status_code
        }
      }
    }
    case GET_LIST_RAFFLE_TIERS:{
      const { data, message, status_code } = payload;
      return {
        ...state,
        raffleTiers: {
          ...state.raffleTiers,
          error: null,
          data,
          message,
          status_code
        }
      };
    }
    case SUBMITTING_RAFFLE_TIERS:{
      const { data, message, status_code } = payload;
      return {
        ...state,
        raffleTiers: {
          ...state.raffleTiers,
          error: null,
          data,
          message,
          status_code
        }
      };
    }
    case GET_LIST_RAFFLE_GROUP: {
      const { data, message, status_code } = payload;
      const { raffleGroups } = state;
      return{
        ...state,
        raffleGroups: {
          ...raffleGroups,
          data: { ...raffleGroups.data, ...data },
          isFetch: false,
          error: null,
          message,
          status_code
        }
      }
    }
    case FETCHING_RAFFLE_GROUP: {
      const { value, error } = payload;
      return{
        ...state,
        raffleGroups: {
          ...state.raffleGroups,
          isFetch: value,
          error: error? error: state.raffleGroups.error
        }
      }
    }
    case CREATE_RAFFLE_GROUP:
    case UPDATE_RAFFLE_GROUP:
    case DELETE_RAFFLE_GROUP:
    case GET_DETAIL_RAFFLE_GROUP: {
      const { data, ...restData } = payload;
      return {
        ...state,
        detailsGroup: {
          ...state.detailsGroup,
          ...restData,
          data: {
            ...state.detailsGroup.data,
            ...data,
            deleted: type === DELETE_RAFFLE_GROUP,
          },
          error: null
        }
      }
    }

    default: return { ...state }
  }
}

export default reducer;