import React, { useState, useRef, useEffect } from "react";
import Picker from "emoji-picker-react";
import { FontAwesomeIcon } from "../../Icons";
import { Col, Input, Row } from "reactstrap";
import { useToggleValue } from "../../../reusables/hooks/useToggleValue";

const InputWithEmojiPicker = (props) => {
    const [isOpen, toggleIsOpen] = useToggleValue();
    const {
        inputProps: { id, name, type, placeholder, value, onChange },
        emojiPickerProps: { onEmojiClick, pickerStyle },
        wrapperStyle,
    } = props;

    const onBlurPicker = (e) => {
        if (!e.currentTarget.contains(e.relatedTarget)) {
            setTimeout(() => {
                if (isOpen) {
                    toggleIsOpen(false);
                }
            }, 500);
        }
    };

    const onClickIcon = () => {
        toggleIsOpen();
    };

    return (
        <>
            <Row
                noGutters={false}
                style={{
                    alignItems: type === "textarea" ? "flex-start" : "center",
                    ...wrapperStyle,
                }}
            >
                <Col md={11} style={{ paddingRight: 0 }}>
                    <Input
                        {...props.inputProps}
                        type={type}
                        id={id}
                        name={name}
                        placeholder={placeholder}
                        value={value}
                        onChange={onChange}
                    />
                </Col>
                <Col md={1}>
                    <FontAwesomeIcon
                        iconType="smile-o"
                        onClick={onClickIcon}
                        style={{
                            cursor: "pointer",
                            fontSize: "1.5rem",
                            color: isOpen ? "green" : "gray",
                        }}
                    />

                    {isOpen && (
                        <div onBlur={onBlurPicker}>
                            <Picker
                                {...props.emojiPickerProps}
                                onEmojiClick={(_, obj) =>
                                    onEmojiClick(obj.emoji)
                                }
                                pickerStyle={{
                                    position: "absolute",
                                    right: 0,
                                    marginTop: 10,
                                    marginRight: 10,
                                    zIndex: 99,
                                    ...pickerStyle,
                                }}
                            />
                        </div>
                    )}
                </Col>
            </Row>
        </>
    );
};

export default InputWithEmojiPicker;
