import React, { Component } from 'react';
import { CardBody, Collapse } from 'reactstrap';
import propTypes from 'prop-types';

import SearchForm from './SearchForm';
import SortForm from './SortForm';
import FilterForm from './FillterForm';
import StockFilterForm from './StockFillterForm';

const warehouseStockStatusOptions = [
  { value: 'verification_passed', label: 'Verification Passed' },
  { value: 'verification_failed', label: 'Verification Failed' },
]
export default class Filters extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rack: props.rack,
      seller: props.seller,
      keyword: props.keyword,
      perPage: props.per_page,
      sortBy: props.sort_by,
      seller: props.seller,
      stock_label: props.stock_label,
      sneakers_condition: props.sneakers_condition,
      status: props.status,
    }
    this._handleOnChangeInput = this._handleOnChangeInput.bind(this);
    this._handleOnChangeInputLabel = this._handleOnChangeInputLabel.bind(this);
    this._handleApplyFilterButtonClick = this._handleApplyFilterButtonClick.bind(this);
    this._handleOnChangeInputAndApply = this._handleOnChangeInputAndApply.bind(this);
    this._handleSearchButtonClick = this._handleApplyFilterButtonClick.bind(this);
    this._handleOnSellerChange = this._handleOnSellerChange.bind(this);
    this._handleSelectRack = this._handleSelectRack.bind(this);
    this._isCheckedStatus = this._isCheckedStatus.bind(this);
    this._handleStatusesChangeInput = this._handleStatusesChangeInput.bind(this);
  }

  _handleSelectRack(option){
    this.setState({ rack: option });
  }

  _handleOnSellerChange(seller){
    this.setState({
      seller
    })
  }
  _handleOnChangeInput(e){
    const lastState = {...this.state};
    lastState[e.target.name] = e.target.value;
    this.setState({...lastState});
  }
  
  _handleOnChangeInputLabel(e){
    const lastState = {...this.state};
    lastState[e.target.name] = e.target.value;
    this.setState({...lastState});
  }

  _handleOnChangeInputAndApply(e){
    const lastState = {...this.state};
    lastState[e.target.name] = e.target.value;
    this.setState({...lastState}, ()=> this._handleSubmitFilter());
  }

  _handleApplyFilterButtonClick(e){
    e.preventDefault();
    this._handleSubmitFilter();
  }

  _handleSubmitFilter(){
    const {
      keyword,
      seller,
      perPage,
      sortBy,
      rack,
      stock_label,
      sneakers_condition,
      status,
    } = this.state;
    let rack_names = ''
    if (rack && rack.length) {
      const names = rack && rack.map(obj => obj.name)
      rack_names = names.join(', ')
    }
    this.props.onApplyFilter({
      rack: rack_names,
      keyword,
      seller: seller,
      per_page: perPage,
      sort_by: sortBy,
      stock_label: stock_label,
      sneakers_condition: sneakers_condition,
      status: status
    });
  }

  _isCheckedStatus(val) { 
    if (val === 'all') {
      return this.state.status.split(',').length === warehouseStockStatusOptions.length
    } else {
      return val === 'default' ? this.state.status === '' : this.state.status.includes(val)
    }
  }

  _handleStatusesChangeInput(e) {
    const lastState = {...this.state};
    let status = e.target.value
    let statuses = this.state.status.split(',')
    let arrStatus = [];
    if (this._isCheckedStatus(status)) {
      lastState['status'] = statuses.filter(itm => itm !== status).join(',')
    } else if (status === 'default' || status === 'all') {
      if (status === 'default') {
        lastState['status'] = ''
      } else {
        arrStatus = [...warehouseStockStatusOptions.map(v => v.value)]
        lastState['status'] = arrStatus.join(',')
      } 
    } else {
      if (this._isCheckedStatus('default')) {
        lastState['status'] = status
      } else if (status !== 'all') {
        arrStatus = [status, ...statuses]
        lastState['status'] = arrStatus.join(',')
      }
    }
    this.setState({...lastState});
  }

  componentDidUpdate(prevProps) {
    if(!prevProps.isReset && prevProps.isReset !== this.props.isReset){
      this.setState({
        keyword: '',
        seller: '',
        rack: '',
        perPage: '',
        sortBy: '',
      })
      this.props.onResetFilterCallback();
    }
  }

  render(){
    return(
      <Collapse isOpen={this.props.isOpen}>
        <CardBody className="filter-container__card-body">
          <SearchForm
            keyword = {this.state.keyword}
            onInputChange = {this._handleOnChangeInput}
            onSubmit = {this._handleSearchButtonClick}
          />
          
          {
            this.props.isStockFilterForm ? (
            <StockFilterForm
              onSubmit={this._handleApplyFilterButtonClick}
              //stock label
              stock_label={this.state.stock_label}
              onCheckedChangeLabel={this._handleOnChangeInputLabel}
              //stock condition
              sneakers_condition={this.state.sneakers_condition}
              onCheckedChange={this._handleOnChangeInput}
              //stock status
              status={this.state.status}
              isCheckedStatus={this._isCheckedStatus}
              onStatusesInputChange={this._handleStatusesChangeInput}

              filterRackRef="selectRack"
              filterRackId="_formSelectRack"
              rack={this.state.rack}
              seller={this.state.seller}
              onSellerChange={this._handleOnSellerChange}
              getRackOptions={this._getRackOptions}
              onSelectSearch={(input)=>this.setState({_recentSearchRack: input})}
              handleSelectRack={this._handleSelectRack}
              />
            ) : (
              <FilterForm
                filterRackRef="selectRack"
                filterRackId="_formSelectRack"
                rack={this.state.rack}
                seller={this.state.seller}
                onSellerChange={this._handleOnSellerChange}
                getRackOptions={this._getRackOptions}
                onSelectSearch={(input)=>this.setState({_recentSearchRack: input})}
                handleSelectRack={this._handleSelectRack}
                onSubmit={this._handleApplyFilterButtonClick}
              />
            )
          }
          <hr/>
          <SortForm
            perPage={this.state.perPage}
            sortBy={this.state.sortBy}
            onInputChange={this._handleOnChangeInputAndApply}
          />
        </CardBody>
      </Collapse>
    )
  }
}

Filters.propTypes= {
  type: propTypes.string,
  keyword: propTypes.string,
  per_page: propTypes.oneOfType([propTypes.number, propTypes.string]),
  seller: propTypes.object,
  sort_by: propTypes.string,
  isOpen: propTypes.bool.isRequired,
  onApplyFilter: propTypes.func,
  onResetFilterCallback: propTypes.func.isRequired,
  isReset: propTypes.bool,
  isStockFilterForm: propTypes.bool,
  status: propTypes.string,
}

Filters.defaultProps= {
  isReset: false,
  seller: null,
  keyword: '',
  per_page: 15,
  sort_by: '',
  isStockFilterForm: false,
  status: '',
}