export const LISTING_CONDITION_OPTIONS = [
    {
        label: "Brand New",
        value: "BARU",
    },
    {
        label: "Used",
        value: "BEKAS",
    },
    {
        label: "Pristine",
        value: "PRISTINE",
    },
    {
        label: "Good",
        value: "GOOD",
    },
    {
        label: "Well Used",
        value: "WELL_USED",
    },
    {
        label: "Like New",
        value: "LIKE_NEW",
    },
    {
        label: "Vintage",
        value: "VINTAGE",
    },
];

export const BOX_CONDITION_OPTIONS = [
    {
        label: "Perfect",
        value: "SEMPURNA",
    },
    {
        label: "Damaged",
        value: "CACAT",
    },
    {
        label: "No Box",
        value: "NO_BOX",
    },
];

export const STOCK_STATUS_OPTIONS = [
    {
        label: "KA Stock",
        value: "KA_STOCK",
    },
    {
        label: "Seller Stock",
        value: "SELLER_STOCK",
    },
];
