import {
  getPathS3,
  clientDeleteObject,
  clientPutObject
} from '../utils/AWS';
import mime from 'mime-types';
import {
  SLIDER_ROUTE_API,
  GET_LIST_SLIDER,
  FETCHING_SLIDER,
  GET_DETAIL_SLIDER,
  UPDATE_SLIDER,
  UPLOAD_SLIDER_S3,
  SLIDER_ERROR,
  SLIDER_S3_ERROR,
  ADD_SLIDER,
  NEW_SLIDER,
  DELETE_SLIDER,
  DELETE_SLIDER_S3,
  // GET_OPTION_LIST_SLIDER,
  AUTH_TOKEN_KEY,
  // GET_LIST_SLIDER_ROOT_SLIDER,

  SLIDER_IMGS_FETCH,
  SLIDER_IMGS_SUBMIT,
  SLIDER_IMGS_ERROR,
  ADD_SLIDER_IMGS,
  GET_LIST_SLIDER_IMGS,
  DELETE_SLIDER_IMGS
} from '../constants';
import {
  AWS_BUCKET,
  AWS_BUCKET_URL
} from '../config/storageBucket';
import { logout } from './authAction';

import api from '../utils/Api';
import { manipulateErrors, checkAuthorized } from '../utils';
// let regexAWS = /^(https:\/\/kickavenue-develop.s3.amazonaws.com\/)/g
// let regexAWS2 = /(https:\/\/s3-ap-southeast-1.amazonaws.com\/kickavenue-develop\/)/g
// const sliderFolderRegex = new RegExp(/^\/slider-images\//)
/**
 * All action of sliders
 * @author sopyan
 * @guards jwt-token
 *
 */
export const getDetailSlider = (id) => {
  return dispatch => {
    dispatch(isFetch({ type: 'fetch', status: true }));
    api({
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
    }).get(`${SLIDER_ROUTE_API}/${id}`)
    .then(res => {
      dispatch(onSuccess(res.data, GET_DETAIL_SLIDER))
      dispatch(isFetch({ type: 'fetch', status: false }))
    })
    .catch(err => {
      dispatch(onError(err));
      dispatch(isFetch({ type: 'fetch', status: false }));
    })
  }
}

export const saveSlider = (payload) => {
  return dispatch => {
    const { id, ...createPayloads } = payload;
    if (payload.id === undefined) {
      dispatch(createSlider(createPayloads));
    }
    else {
      dispatch(updateSlider(payload));
    }
  }
}

export const createSlider = (payload) => {
  return dispatch => {
    dispatch(isFetch({ type: 'submit', status: true }));
    api({
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
    }).post(`${SLIDER_ROUTE_API}`, payload)
    .then(res => {
      dispatch(onSuccess(res.data, ADD_SLIDER))
      dispatch(isFetch({ type: 'submit', status: false }))
    })
    .catch(err => {
      dispatch(onError(err));
      dispatch(isFetch({ type: 'submit', status: false }))
    })
  }
}

export const updateSlider = (payload) => dispatch => {
  dispatch(isFetch({type:'submit', status: true}))
  api({
    Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
  }).put(`${SLIDER_ROUTE_API}/${payload.id}`, payload)
  .then(res => {
    dispatch(onSuccess(res.data, UPDATE_SLIDER))
    dispatch(isFetch({type:'submit', status: false}))
  }).catch(err => {
    dispatch(onError(err));
    dispatch(isFetch({type:'submit', status: false}))
  })
}

export const deleteSlider = (id, img_url=false) => {
  return dispatch => {
    dispatch(isFetch({ type: 'submit', status: true }));
    api({
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
    }).delete(`${SLIDER_ROUTE_API}/${id}`)
    .then(res => {
      dispatch(onSuccess(res.data, DELETE_SLIDER));
      if(img_url){
        const myFile = getPathS3(img_url)
        if(myFile !== null){
          // const url = myFile.replace(sliderFolderRegex, '');
          dispatch(deletePhotoS3(myFile));
        }
      }
      dispatch(isFetch({ type: 'submit', status: false }));
    })
    .catch(err => {
      dispatch(onError(err));
      dispatch(isFetch({ type: 'submit', status: false }));
    });
  }
}

export const upload = (file, folder = '', keys = null, _isUserAsset = false) => {
  const key = `${folder}${file.identifier}.${mime.extension(file.fileInput.type)}`;
  const _pars_key = keys ? `${folder}${keys}` : key;
  return dispatch => {
    dispatch(s3Process(UPLOAD_SLIDER_S3, true));
    clientPutObject({
      Bucket: AWS_BUCKET,
      Key: _pars_key,
      Body: file.fileInput,
      ContentType: 'image/jpeg',
      ACL: 'public-read', // your permisions
    }).then(res => {
      const result = {
        identifier: file.identifier,
        prefix: AWS_BUCKET_URL,
        folder,
        url: `${AWS_BUCKET_URL}${res.key}`
      }
      dispatch(onSuccess(result, UPLOAD_SLIDER_S3));
      dispatch(s3Process(UPLOAD_SLIDER_S3, false));
    })
    .catch(err => {
      dispatch(onErrorS3(err));
      dispatch(s3Process(UPLOAD_SLIDER_S3, false));
    })
  }
}

export const deletePhotoS3 = (url) => {
  let key = url;
  if(url.indexOf('/') === 0)
    key = url.substring(1)
  return dispatch => {
    dispatch(s3Process(DELETE_SLIDER_S3, true));
    clientDeleteObject(AWS_BUCKET, key).then(res => {
      dispatch(onSuccess(res.key, DELETE_SLIDER_S3));
      dispatch(s3Process(DELETE_SLIDER_S3, false));
    }).catch(err => {
      dispatch(onErrorS3(err));
      dispatch(s3Process(DELETE_SLIDER_S3, false));
    })
  }
}

export const getListSlider = (query) => dispatch => {
  dispatch(isFetch({ type: 'fetch', status: true }))
  api({
    Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
  }).get(`${SLIDER_ROUTE_API}`, query)
    .then(res => {
      dispatch(onSuccess(res.data, GET_LIST_SLIDER))
      dispatch(isFetch({ type: 'fetch', status: false }))
    })
    .catch(err => {
      dispatch(onError(err));
      dispatch(isFetch({ type: 'fetch', status: false }));
    })
}

export const getSliderImages = (sliderId) => dispatch =>{
  dispatch({
    type: SLIDER_IMGS_FETCH,
    payload: true
  })
  api({
    Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
  }).get(`${SLIDER_ROUTE_API}/${sliderId}`)
  .then(res => {
    dispatch(onSuccess(res.data, GET_LIST_SLIDER_IMGS))
    dispatch({
      type: SLIDER_IMGS_FETCH,
      payload: false
    })
  })
  .catch(err => {
    dispatch({
      type: SLIDER_IMGS_ERROR,
      payload: err
    });
    dispatch({
      type: SLIDER_IMGS_FETCH,
      payload: false
    })
  })
}

export const deleteSliderImages = (sliderId, data) => dispatch =>{
  dispatch({
    type: SLIDER_IMGS_SUBMIT,
    payload: true
  })
  api({
    Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
  }).delete(`${SLIDER_ROUTE_API}/${sliderId}/images/${data.id}`)
  .then(res => {
    dispatch(onSuccess(res.data, DELETE_SLIDER_IMGS))
    const myFile = getPathS3(data.URL)
    if(myFile !== null){
      // const url = myFile.replace(sliderFolderRegex, '');
      dispatch(deletePhotoS3(myFile));
    }
    dispatch({
      type: SLIDER_IMGS_SUBMIT,
      payload: false
    })
  })
  .catch(err => {
    dispatch({
      type: SLIDER_IMGS_ERROR,
      payload: err
    });
    dispatch({
      type: SLIDER_IMGS_SUBMIT,
      payload: false
    })
  })
}

export const storeSliderImages = (sliderId, payloads) => dispatch =>{
  dispatch({
    type: SLIDER_IMGS_SUBMIT,
    payload: true
  })
  let initReq = api({
    Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
  });
  const {id, ...body} = payloads;
  let req = null;
  if(id){
    req = initReq.put(`${SLIDER_ROUTE_API}/${sliderId}/images/${id}`, body);
  } else{
    req = initReq.post(`${SLIDER_ROUTE_API}/${sliderId}/images`, body);
  }
  req.then(res => {
    dispatch(onSuccess(res.data, ADD_SLIDER_IMGS))
    dispatch({
      type: SLIDER_IMGS_SUBMIT,
      payload: false
    })
  })
  .catch(err => {
    dispatch({
      type: SLIDER_IMGS_ERROR,
      payload: err
    });
    dispatch({
      type: SLIDER_IMGS_SUBMIT,
      payload: false
    })
  })
}

/**
 * Indicates when start fetch api or end fetch api
 * @author sopyan
 * @param {boolean} bool
 */
export const isFetch = (payload) => (
  {
    type: FETCHING_SLIDER,
    payload: payload
  }
)

/**
 * When onSuccess triggered
 * @author sopyan
 * @param {object} res
 * @param {string} type
 */
export const onSuccess = (res, type) =>
  (
    {
      type: type,
      payload: res
    }
  )

/**
 * When error fetch or connect to api
 * @author zani
 * @param {object} err
 */
// On Error fetching api
export const onError = (err) => {
  if (!checkAuthorized(manipulateErrors(err)))
    return logout();
  return {
    type: SLIDER_ERROR,
    payload: manipulateErrors(err)
  }
}

export const newSlider = () => {
  return {
    type: NEW_SLIDER
  }
}

export const onErrorS3 = (error) => {
  return {
    type: SLIDER_S3_ERROR,
    payload: error
  }
}

export const s3Process = (type, bool) => {
  return {
    type: type,
    payload: bool
  }
}
