import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
	Alert,
	Col,
  Row,
	Card,
	TabContent,
	TabPane,
	Nav,
  NavItem,
	NavLink } from 'reactstrap';

import classnames from 'classnames';
import qs from 'query-string';

import { getDetailTopUp, updateTopUp } from '../../../actions/topUpAction';
import Header from './paymentDetail/Header';
import TabPaymentContent from './TabPaymentDetail';
import TabUserContent from './TabUserDetail';
import TabPaymentConfirmationContent from './TabPaymentConfirmation';
import TabDisbursementContent from './TabDisbursement';
import privateView from '../../../components/hocs/privateView';
import Analytics from "../../../services/Analytics";

const DEFAULT_STATUS_COLOR = 'default';
const DEFAULT_ERROR_500_MSG = 'We got something errors. Please ask our staff!';
const DEFAULT_ERROR_404_MSG = 'Sorry, cannot find what you are looking for.';

/**
 * Component for when user navigate to /topUps/:id
 * @author sopyan
 */
class TopUpDetail extends Component {
	constructor(props) {
		super(props);
    const DEFAULT_STATE = {
      _id: parseInt(this.props.match.params.id),
      activeTab:'paymentDetail'
    }
    const queries = Object.assign(DEFAULT_STATE,qs.parse(props.location.search));
		this.state={
      ...queries,
      _statusColor: DEFAULT_STATUS_COLOR,
      _errorAfterFetched: false,
      _errorMsg: '',
      _error: null
		}
		this.toggleTab = this.toggleTab.bind(this);
	}

	componentDidMount(){
    const { email } = this.props.injectedProps;
    this.props.getDetail(this.state._id)
    Analytics.recordPageView('topup_detail', email, {
      url: window.location.href,
      location: this.props.location,
      match: this.props.match
    });
  }

	toggleTab(tab) {
		if (this.state.activeTab !== tab) {
			this.setState({
			  activeTab: tab
      });
      const { pathname } = this.props.location;
      const search = qs.stringify({
        activeTab: tab
      });
      this.props.history.replace({ pathname, search })
		}
  }

  componentWillReceiveProps(nextProps){
    if(!nextProps.topUp.isFetch && nextProps.topUp.isFetch !== this.props.topUp.isFetch){
      if(nextProps.topUp.error){
        let msg = DEFAULT_ERROR_404_MSG;
        switch(nextProps.topUp.error.status_code){
          case 500:
            msg = DEFAULT_ERROR_500_MSG; break;
          case 404:
            msg = DEFAULT_ERROR_404_MSG; break;
          default:
            msg = nextProps.topUp.error.message;
        }
        this.setState({
          _error: nextProps.topUp.error,
          _errorAfterFetched: true,
          _errorMsg: msg
        })
      }
      else{
        const { status, expired } = nextProps.topUp.detail;
        let _statusColor = DEFAULT_STATUS_COLOR;
        switch(status){
          case 'PENDING':{
            if(expired)
              _statusColor = 'danger';
            break;
          }
          case 'VERIFYING_PAYMENT':
          case 'PENDING_REFUND':
            _statusColor = 'warning'; break;
          case 'COMPLETED':
            _statusColor = 'success'; break;
          case 'REJECTED':
          case 'EXPIRED':
          case 'CANCELLED':
          case 'REFUNDED':
            _statusColor = 'danger'; break;
        }
        this.setState({_statusColor});
      }
    }
  }

// componentDidUpdate(prevProps) {
//     if(!prevProps.topUp.isFetch && prevProps.topUp.isFetch !== this.props.topUp.isFetch){
//       if(prevProps.topUp.error){
//         let msg = DEFAULT_ERROR_404_MSG;
//         switch(prevProps.topUp.error.status_code){
//           case 500:
//             msg = DEFAULT_ERROR_500_MSG; break;
//           case 404:
//             msg = DEFAULT_ERROR_404_MSG; break;
//           default:
//             msg = prevProps.topUp.error.message;
//         }
//         this.setState({
//           _error: prevProps.topUp.error,
//           _errorAfterFetched: true,
//           _errorMsg: msg
//         })
//       }
//       else{
//         const { status, expired } = prevProps.topUp.detail;
//         let _statusColor = DEFAULT_STATUS_COLOR;
//         switch(status){
//           case 'PENDING':{
//             if(expired)
//               _statusColor = 'danger';
//             break;
//           }
//           case 'VERIFYING_PAYMENT':
//           case 'PENDING_REFUND':
//             _statusColor = 'warning'; break;
//           case 'COMPLETED':
//             _statusColor = 'success'; break;
//           case 'REJECTED':
//           case 'EXPIRED':
//           case 'CANCELLED':
//           case 'REFUNDED':
//             _statusColor = 'danger'; break;
//         }
//         this.setState({_statusColor});
//       }
//     }
//   }

  _renderPaymentDetailTab(){
    const { isFetch, isSubmit, list, ...topUpDetails } = this.props.topUp;
    const email = this.props.guardData;
    return (
      <TabPaymentContent
        id={this.state._id}
        isLoading={isFetch}
        isSubmit={isSubmit}
        dataPayment={topUpDetails}
        onUpdatePayment={(id, dataForm)=>this.props.updateDetail(id, dataForm)}
        email={email}
      />
    )

  }
	/**
	 * Render views
	 * @author sopyan
	 */
	render(){
    const { user, ...topUpDetails } = this.props.topUp.detail;
    return (
      <Row className="animated fadeIn">
        <Col xs={12} style={{padding:0}}>
        {
          this.state._errorAfterFetched?
          <Card body>
            <Alert
              color="danger"
              className="text-center"
              isOpen={this.state._errorAfterFetched}
            >
              <strong>Oppps. </strong>{this.state._errorMsg}
            </Alert>
          </Card>
          :

          <Card body>
            <Header
              paymentId={topUpDetails.id}
              isLoading={this.props.topUp.isFetch}
              invoiceNumber={topUpDetails.invoice_number}
              email={user === null || user === undefined ? undefined : user.email}
              totalAmount={parseInt(topUpDetails.total_amount)}
              updatedAt={topUpDetails.updated_at}
              createdAt={topUpDetails.created_at}
              statusColor={this.state._statusColor}
              status={topUpDetails.status}
              expired={topUpDetails.expired}
              paymentType={topUpDetails.payment_method}
            />
            <Nav className="nav-tabs--custom" tabs>
              <NavItem>
                <NavLink
                  className={classnames({ active: this.state.activeTab === 'paymentDetail' })}
                  onClick={() => { this.toggleTab('paymentDetail'); }}
                >
                  Top-up Details
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: this.state.activeTab === 'paymentConfirmations' })}
                  onClick={() => { this.toggleTab('paymentConfirmations'); }}
                >
                  Payment Confirmations
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: this.state.activeTab === 'userDetail' })}
                  onClick={() => { this.toggleTab('userDetail'); }}
                >
                  User
                </NavLink>
              </NavItem>
              {/* <NavItem>
                <NavLink
                  className={classnames({ active: this.state.activeTab === 'disburment' })}
                  onClick={() => { this.toggleTab('disburment'); }}
                >
                  Disburment
                </NavLink>
              </NavItem> */}
            </Nav>
            <TabContent className="tab-content--custom" activeTab={this.state.activeTab}>
              <TabPane className="tab-content--custom__tab-pane" tabId="paymentDetail">
                { this._renderPaymentDetailTab() }
              </TabPane>
              <TabPane className="tab-content--custom__tab-pane" tabId="paymentConfirmations">
                <TabPaymentConfirmationContent
                  isLoading={this.props.topUp.isFetch}
                  data={topUpDetails&&topUpDetails.payment_bank_transfer}
                />
              </TabPane>
              <TabPane className="tab-content--custom__tab-pane" tabId="userDetail">
                <TabUserContent
                  isLoading={this.props.topUp.isFetch}
                  user={user}
                />
              </TabPane>
              <TabPane className="tab-content--custom__tab-pane" tabId="disburment">
                <TabDisbursementContent
                  isLoading={this.props.topUp.isFetch}
                  data={user&&user.user_balance_journal}
                  totalBalance={user}
                />
              </TabPane>
            </TabContent>
          </Card>
        }
        </Col>
      </Row>
    )
	}
}

const mapStateToProps = ({topUp, auth: { isFetch, isLogged, roles, id, email }}) =>{
	return {
		topUp,
		guardData: { isFetch, isLogged, roles, id, email }
	}
}

const mapDispatchToProps = (dispatch) =>{
	return {
		getDetail : (id) => dispatch(getDetailTopUp(id)),
		updateDetail : (id,payload) => dispatch(updateTopUp(id,payload)),
	}
}

const enhance = connect(mapStateToProps,mapDispatchToProps);
export default enhance(privateView(TopUpDetail));
