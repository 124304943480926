import React from 'react';
import { Button, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Alert } from "reactstrap";

export default function (props) {
    const {
        isOpen,
        onCancel,
        isSubmit,
        sale,
        onSubmit,
    } = props;

    const [notes, setNotes] = React.useState('');
    const [invalid, setInvalid] = React.useState(false);

    React.useEffect(() => {
        if (!isOpen) {
            setNotes('');
            setInvalid(false);
        }
    }, [isOpen]);

    React.useEffect(() => {
        if (invalid) {
            setTimeout(() => setInvalid(false), 5000);
        }
    }, [invalid]);

    const submit = (e) => {
        e.preventDefault();
        if (notes === '') {
            setInvalid(true);
            return;
        }
        const payload = { notes };
        onSubmit(sale.id, payload);
    };

    return (
        <Modal isOpen={isOpen} toggle={onCancel} className="modal-dialog modal-md">
            <ModalHeader toggle={onCancel}>Confirmation</ModalHeader>
            <ModalBody>
                {sale !== null ?
                    <p>
                        (<b>{sale.consignment_number}</b>)<br />
                        Reject Consignment <i>{sale.user_sell.product_variant.display_name}</i>.
                    </p>
                    : ''
                }
                <p>
                    You cannot undo this action, are you sure?
                </p>
                <Form>
                    {invalid && <Alert color="danger">
                        Notes is required.
                    </Alert>}
                    <FormGroup>
                        <Label for="formRejectNotes">Notes*</Label>
                        <Input
                            type="textarea"
                            id="formRejectNotes"
                            name="notes"
                            placeholder="Put your notes here*"
                            value={notes}
                            onChange={(e) => setNotes(e.target.value)}
                        />
                    </FormGroup>
                </Form>
                <span className="text-sm">Fields marked with * are required</span>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" disabled={isSubmit} onClick={submit}>{isSubmit ? 'Rejecting Consignment...' : 'Reject Consignment'}</Button>{' '}
                <Button color="secondary" onClick={onCancel}>Cancel</Button>
            </ModalFooter>
        </Modal>
    )
}
