import React from 'react';
import propTypes from 'prop-types';
import classNames from 'classnames';

const CardHeaderText = props => (
  <div>
    <i className={classNames('mr-2',props.iconClassName)}></i>{props.text}
  </div>
)

export default CardHeaderText;

CardHeaderText.propTypes = {
  iconClassName: propTypes.string,
  text: propTypes.string
}

CardHeaderText.defaultProps = {
  iconClassName: 'fa fa-align-justify',
  text: 'My Title'
}