import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import {Card, CardBody, CardImg, CardText,CardSubtitle,CardTitle, Badge} from 'reactstrap';
import { getCacheImage } from '../../../utils/imageHelper'

const getPublicImageUrl = image => {
    return image.signed_url || image.URL
}

class ProductCard extends Component{
    constructor(props){
        super(props)
    }
    render(){
        return (
            <Card style={{border:'none', marginBottom:0, paddingTop:15, paddingBottom:15, paddingLeft:0, paddingRight:0}}>
                <div className="row">
                    <div className="col-md-3">
                        <CardImg src={(this.props.product_variant_images&&this.props.product_variant_images.length>0)? getCacheImage(getPublicImageUrl(this.props.product_variant_images[0])) : "/img/no-image.png"} alt="Featured image products" />
                    </div>
                    <div className="col-md-9">
                        <div>
                            {this.props.product!==undefined&&this.props.product.brand_id!==null?`${this.props.product.brand.flat.reduce((sum,value)=>sum+' - '+value)}`:<i>(No Brand)</i>}
                            ,&nbsp;{this.props.product!==undefined&&this.props.product.category_id!==null?`${this.props.product.category.name}`:<i>(No categories)</i>}
                        </div>
                        <CardTitle>{this.props.display_name}</CardTitle>
                        <CardSubtitle>{this.props.colour}</CardSubtitle>
                        <br/>
                        <div>
                            <Badge>{this.props.SKU?this.props.SKU:'-'}</Badge>
                            <br/>
                            <Link style={{marginTop:15}} title="Add Stock Now" to={`/stock/${this.props.id}/add`} className="btn btn-sm btn-primary" target="blank">
                                <i className="fa fa-plus"></i> Add Stock
                            </Link>
                            <Link style={{marginTop:15}} title="Add Stock Now" to={`/product_variants/${this.props.id}`} className="btn btn-sm btn-secondary" target="blank">
                                <i className="fa fa-pencil"></i> Edit Variant
                            </Link>
                            {this.props.size?
                            <div>
                                <span>US: {this.props.size.US?this.props.size.US:'-'}&nbsp;&nbsp;&nbsp;</span>
                                <span>UK: {this.props.size.UK?this.props.size.UK:'-'}&nbsp;&nbsp;&nbsp;</span>
                                <span>EUR: {this.props.size.EUR?this.props.size.EUR:'-'}&nbsp;&nbsp;&nbsp;</span>
                                <span>CM: {this.props.size.cm?this.props.size.cm:'-'}&nbsp;&nbsp;&nbsp;</span>
                                <span>Gender: {this.props.size.sex=='F'?'Womens':(this.props.size.sex=='K'?'Kids':'Mens')}&nbsp;</span>
                            </div>
                            :''}
                        </div>
                        
                    </div>
                </div>
            </Card>            
        )
    }
}
export default ProductCard;