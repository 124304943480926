import React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import moment from "moment-timezone";

export default function (props) {
    const {
        isOpen,
        onCancel,
        isSubmit,
        sale,
        onSubmit,
    } = props;

    const submit = (e) => {
        e.preventDefault();
        onSubmit(sale.id, { seller_responded: moment().format('YYYY-MM-DD HH:mm:00') });
    }

    return (
        <Modal isOpen={isOpen} toggle={onCancel} className="modal-dialog modal-md">
            <ModalHeader toggle={onCancel}>Confirmation</ModalHeader>
            <ModalBody>
                {sale !== null ?
                    <p>
                        (<b>{sale.consignment_number}</b>)<br />
                        Accept consignment <i>{sale.user_sell.product_variant.display_name}</i>.
                    </p>
                    : ''
                }
                <p>
                    Are you sure confirm this consignment?
                </p>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" disabled={isSubmit} onClick={submit}>{isSubmit ? 'Submitting...' : 'Accept'}</Button>{' '}
                <Button color="secondary" onClick={onCancel}>Cancel</Button>
            </ModalFooter>
        </Modal>
    )
}
