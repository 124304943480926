import React from 'react'
import PropTypes from 'prop-types';

// Just dumb component to show error message after validation
export const ValidationMessage = props => (
  <div className="validation-error-message">
    <span className="help-block text-danger">{props.message}</span>
  </div>
)

// Need props message with string type
ValidationMessage.propTypes = {
  message: PropTypes.string
};