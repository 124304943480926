import React from 'react';
import propTypes from 'prop-types';
import {
  Form,
  FormGroup,
  Button,
  Row,
  Col,
  Input,
  Label
} from 'reactstrap';

import { sellerVerificationStatusOptions } from '../../../../constants/kickavenue';

const FilterForm = props => {

  return (
    <Form className="form-filter" onSubmit={props.onSubmit}>
      <Row>
        <Col xs={12} md={6}>
          <p>Filter By :</p>
          <div className="d-flex" style={{flexDirection: 'column'}}>
            <div className="d-flex" style={{padding: "0px 1rem"}}>
              <FormGroup className="d-flex" style={{flexDirection: 'column', alignItems: 'flex-start'}} check>
                <Label className="mr-sm-3" style={{fontWeight: 700}}>
                  Status
                </Label>
                <div className="ml-2 mr-2">
                  <Label check className="mr-3">
                    <Input
                      type="checkbox"
                      name="status"
                      value="default"
                      checked={props.isCheckedStatus('default')}
                      onChange={(e) => props.onStatusesInputChange(e)}
                    /> Default
                  </Label>
                  <Label check className="mr-3">
                    <Input
                      type="checkbox"
                      name="status"
                      value="all"
                      checked={props.isCheckedStatus('all')}
                      onChange={(e) => props.onStatusesInputChange(e)}
                    /> All
                  </Label>
                  <div className="w-100"></div>
                  {sellerVerificationStatusOptions.map((option, i) => (
                    <Label check className="mr-3" key={i}>
                      <Input
                        type="checkbox"
                        name="status"
                        value={option.value}
                        checked={props.isCheckedStatus(option.value)}
                        onChange={(e) => props.onStatusesInputChange(e)}
                      /> {option.label}
                    </Label>
                  ))}
                </div>
              </FormGroup>
            </div>
            <FormGroup className="mb-2" style={{alignSelf: 'flex-end'}}>
              <Button color="primary" >Apply Filter</Button>
            </FormGroup>
          </div>
        </Col>
      </Row>
    </Form>
  )
}
export default FilterForm;

FilterForm.propTypes= {
  status: propTypes.string,
  onInputChange: propTypes.func.isRequired,
  onStatusesInputChange: propTypes.func.isRequired,
  isCheckedStatus: propTypes.func.isRequired,
  onSubmit: propTypes.func.isRequired
}

FilterForm.defaultProps= {
  status: '',
}