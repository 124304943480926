import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Row,
  Col,
  Card,
  CardBody,
  CardText } from 'reactstrap';

import {Tbl, TblHead, TblBody} from '../../../components/Tbl';
import Paginations from '../../../components/Paginations';
import PaginationSummaries from '../../../components/Paginations/PaginationSummaries';
import { CardHeaderWithToolbars,Title } from '../../../components/Card/CardHeaderWithToolbars';
import Toolbars from '../Filters/Toolbars';
import FilterPanel from '../Filters';
import ExportsPanel from '../Exports';
import FilterContainer from '../../../components/Container/FilterContainer/FilterContainer';

import { getTopUpList } from '../../../actions/topUpAction';
import { generateNumber } from '../../../utils';
import { DEFAULT_SORT_BY, DEFAULT_PER_PAGE } from '../../../constants/settings';
import RowItem from './rowItem';
import privateView from '../../../components/hocs/privateView';
import Analytics from "../../../services/Analytics";

/**
 * Component for when user navigate to /top_up
 * @author sopyan
 */

const dataHeader=[
    'No.','Order','Total','Payment','Updated','Status', 'Action'
];

const defaultParams={
  sort_by : DEFAULT_SORT_BY,
  per_page : DEFAULT_PER_PAGE
}

class TopUpList extends Component{
	constructor(){
		super();
		this.state={
      _exportOpen: false,
      _filterOpen: false,
			_params:{
        ...defaultParams
			}
    }
    this._handleToggleFilterPanel = this._handleToggleFilterPanel.bind(this);
    this._handleToggleExportPanel = this._handleToggleExportPanel.bind(this);
    this._handleResetFilter = this._handleResetFilter.bind(this);
    this._handleGoto = this._handleGoto.bind(this);
    this._handleRefresh = this._handleRefresh.bind(this);
    this._handleExport = this._handleExport.bind(this);
    this._handleApplyFilter = this._handleApplyFilter.bind(this);
	}

	componentDidMount(){
    const { email } = this.props.injectedProps;
		this._getList(this.state._params);
    Analytics.recordPageView('topup_list', email, {
      url: window.location.href,
      location: this.props.location,
      match: this.props.match
    });
  }

  _getList(rawParams = {}){
    let params = {};
    Object.keys(rawParams)
    .filter(key => rawParams[key]!='')
    .forEach(key=> params[key] = rawParams[key]);
    this.props.getList({params});
  }

  _refreshList(){
    const {page, ..._params} = this.state._params;
    this.setState({
      _params
    },()=>this._getList(this.state._params));
  }


	/**
	 * Function to handle rendering table's items or rows
	 * @author sopyan
	 */
	_onRenderRowItem(){
		if(this.props.topUp.list.status_code===200 && this.props.topUp.list.data.length>0){
			const { current_page,per_page } =  this.props.topUp.list;
			return this.props.topUp.list.data.map((item,index)=>{
        const rowNumber = generateNumber(per_page,current_page,index);
        return (
          <RowItem
            key={rowNumber}
            id={item.id}
            rowId={rowNumber}
            invoiceNumber={item.invoice_number}
            email={item.user.email}
            updatedAt={item.updated_at}
            paymentMethod={item.payment_method}
            paymentStatus={item.status}
            amount={item.total_amount}
            expired={item.expired}
            paymentVia={item.payment_via}
            bank={item.bank_code}
          />
        )
      })
		}
    return null;
	}

	/**
	 * Function to handle rendering paginations
	 * @author sopyan
	 */
	_onRenderPagination(){
    const {status_code, data} = this.props.topUp.list;
    const hasData = (status_code===200 && data.length)||null;
    return hasData && (
      <Paginations
        size="sm"
        lastPage={this.props.topUp.list.last_page}
        perPage={this.props.topUp.list.per_page}
        total={this.props.topUp.list.total}
        maxPage={8}
        path={this.props.topUp.list.path}
        currentPage={this.props.topUp.list.current_page}
        prevPageUrl={this.props.topUp.list.prev_page_url}
        nextPageUrl={this.props.topUp.list.next_page_url}
        handleGotoCallback={this._handleGoto}
      />
    )
	}

	/**
	 * Handle callback when user click pagination items
	 * @author sopyan
	 * @param {integer} page
	 */
	_handleGoto(page){
    const _params=this.state._params;
    _params.page=page;
    this.setState({_params},()=>this._getList(this.state._params));
  }

  _handleRefresh(e){
    e.preventDefault();
    this._refreshList();
  }

  _handleExport(payload){
    // this.props.reporttopUp(payload);
  }

  _handleResetFilter(e){
    e.preventDefault();
    const newState = this.state._filterOpen?{_filterOpen:!this.state._filterOpen}:{};
    const exportState = this.state._exportOpen?{_exportOpen:!this.state._exportOpen}:{};
    this.setState({
      ...exportState,
      ...newState,
      _params:{...defaultParams},
      _isResetFilter: true
    },()=>this._getList(this.state._params));
  }

  _toggleFilterPanel(){
    const exportState = this.state._exportOpen?{_exportOpen:!this.state._exportOpen}:{};
    this.setState({
      ...exportState,
      _filterOpen:!this.state._filterOpen});
  }

  _toggleExportPanel(){
    const filterState = this.state._filterOpen?{_filterOpen:!this.state._filterOpen}:{};
    this.setState({
      ...filterState,
      _exportOpen:!this.state._exportOpen});
  }

  _handleToggleExportPanel(e){
    e.preventDefault();
    this._toggleExportPanel();
  }

  _handleToggleFilterPanel(e){
    e.preventDefault();
    this._toggleFilterPanel();
  }

  _handleApplyFilter(payloadFilter){
    this.setState({
      _params: {...payloadFilter}
    },()=>this._getList(this.state._params));
  }

	render(){
		return (
			<div className="animated fadeIn">
        <Row>
          <Col xs={12}>
            <Card>
              <CardHeaderWithToolbars>
                <Title>
                  <i className="fa fa-align-justify"></i>
                  <span>User TopUp List</span>
                </Title>
                <Toolbars
                  isOpen={this.state._filterOpen}
                  onToggleFilter={this._handleToggleFilterPanel}
                  onToggleExport={this._handleToggleExportPanel}
                  onRefreshList={this._handleRefresh}
                  onResetFilter={this._handleResetFilter}
                />
              </CardHeaderWithToolbars>
              <FilterContainer>
                <FilterPanel
                  {...this.state._params}
                  isOpen={this.state._filterOpen}
                  onApplyFilter={this._handleApplyFilter}
                  isReset={this.state._isResetFilter}
                  onResetFilterCallback={()=>this.setState({_isResetFilter: false, _filterOpen: false})}
                />
              </FilterContainer>
              <FilterContainer>
                {/* <ExportsPanel
                  onHandleExport={this._handleExport}
                  isOpenExport={this.state._exportOpen}
                  data={this.props.offer.report_offer}
                /> */}
              </FilterContainer>
              <CardBody>
                <CardText>
                  In this section will display our user's top-up.
                </CardText>
                <div className="table-responsive">
                  <PaginationSummaries
                    from={this.props.topUp.list.from}
                    to={this.props.topUp.list.to}
                    total={this.props.topUp.list.total}
                  />
                  <Tbl>
                    <TblHead
                      dataHeader={dataHeader}
                    />
                    <TblBody
                      isLoad={this.props.topUp.isFetch}
                      hasRow={this.props.topUp.list.status_code===200&&this.props.topUp.list.data.length>0}
                      columnCount={dataHeader.length}
                    >
                    { this._onRenderRowItem() }
                    </TblBody>
                  </Tbl>
                </div>
                { this._onRenderPagination() }

              </CardBody>
            </Card>
          </Col>
        </Row>
			</div>
		)
	}
}
const mapStateToProps = ({topUp, auth: { isFetch, isLogged, id, email, roles }}) =>{
	return {
		topUp,
		guardData: { isFetch, isLogged, id, email, roles }
	}
}
const mapDispatchToProps = (dispatch) => {
	return{
    getList:(query) => dispatch(getTopUpList(query)),
	}

};
const enhance = connect(mapStateToProps,mapDispatchToProps);

export default enhance(privateView(TopUpList));
