import React from 'react';
import { connect } from 'react-redux';
import {
    Row, Col
} from 'reactstrap';
import FormCard from './FormCard';
import SellerVerificationListCard from './SellerVerificationListCard';
import UserBankAccountListCard from './UserBankAccountListCard';
import UserSizes from './UserSizes';
import UserLogCard from './UserLogCard';
import UserShippingListCard from './UserShippingListCard';
import { ModalDelete } from '../../../components/Modals';
import { deleteUser } from '../../../actions/userAction';


class UserForm extends React.Component {
  constructor(props){
    super(props);
    this.state={
      _edit : this.props._edit,
      _hasDeleted: false,
      _deleteProcess: false,
      _modalDelete: false
    }
    this.toggle = this.toggle.bind(this);
    this.onDelete = this.onDelete.bind(this);
  }

  toggle(){
    this.setState({_modalDelete:!this.state._modalDelete});
  }

  onDelete(){
    this.setState({_deleteProcess:true}, ()=>this.props.delete(this.props.match.params.id));
  }

  render(){
    return(
      <Row>
        <ModalDelete
          isOpen={this.state._modalDelete} 
          toggle={this.toggle}
          onDelete={this.onDelete}
        />
        <Col className="animated fadeIn" xs={12} md={{size:6,offset:(!this.state._edit?3:0)}}>
            <FormCard
              userData={this.props.injectedProps}
              scopes={this.props.formScopes}
              validateScopeOnSubmit={this.props.validateScopeOnSubmit}
              params = {this.props.match.params}
              history = {this.props.history}
              formTitle = {this.props.title}
              isEdit = {this.state._edit}
              onDelete = {this.toggle}
            />
        </Col>
        {
          this.state._edit&&
          <Col className="animated fadeIn" xs={12} md={6}>
            <UserSizes userId={this.props.match.params.id}/>
            <UserLogCard userId={this.props.match.params.id}/>
            <UserShippingListCard userId={this.props.match.params.id}/>
          </Col>
        }
        {
          this.state._edit&&
          <Col>
            <UserBankAccountListCard
              userId={this.props.match.params.id}
            />
          </Col>
        }
        {
          this.state._edit&&
          <Col className="animated fadeIn" xs={12}>
            <SellerVerificationListCard
              userId={this.props.match.params.id}
            />
          </Col>
        }
      </Row>
    )
  }
}
const mapStateToProps= ({user}) => {
	return {
    user
	}
};
const mapDispatchToProps = (dispatch) => {
	return{
    delete:(id) => dispatch(deleteUser(id)),
	}
};

export default connect(mapStateToProps,mapDispatchToProps)(UserForm);
