import React from 'react';
import propTypes from 'prop-types';
import { Button } from 'reactstrap';
import FontAwesomeIcon from '../../../components/Icons/FontAwesomeIcon';


const StatusFilter = props => (
  <div className="d-flex mb-3" style={{justifyContent: 'flex-end'}}>
    <Button
      size="sm"
      onClick={()=> props.onClick("")}
      color={props.status===""?"primary": "link"}
    >
      { props.status===""&&<FontAwesomeIcon className="mr-1" iconType="check"/> }
      All
    </Button>
    <Button
      size="sm"
      onClick={()=> props.onClick("PENDING")}
      color={props.status === "PENDING"? "primary": "link"}
    >
      { props.status === "PENDING" && <FontAwesomeIcon className="mr-1" iconType="check"/> }
      Pending
    </Button>
    <Button
      size="sm"
      onClick={()=> props.onClick("APPROVED")}
      color={props.status === "APPROVED"? "primary": "link"}
    >
      { props.status === "APPROVED" && <FontAwesomeIcon className="mr-1" iconType="check"/> }
      Approved
    </Button>
    <Button
      size="sm"
      onClick={()=> props.onClick("REJECTED")}
      color={props.status === "REJECTED"? "primary": "link"}
    >
      { props.status === "REJECTED" && <FontAwesomeIcon className="mr-1" iconType="check"/> }
      Rejected
    </Button>
  </div>
)
export default StatusFilter;
StatusFilter.defaultProps = {
  status: ""
}

StatusFilter.propTypes = {
  status: propTypes.string,
  onClick: propTypes.func
}