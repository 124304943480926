import React from 'react';
import { connect } from 'react-redux'
import { Link } from 'react-router-dom';
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  CardText,
  Form,
  FormGroup,
  Label,
  Input,
  ButtonGroup,
  Alert
} from 'reactstrap';
import classNames from 'classnames';
import RichTextEditor from 'react-rte';
import { getProductAddonById, saveProductAddon, deleteProductAddonById, saveProductAddonItems } from '../../actions/productAddonAction';
import { getOptionList } from '../../actions/categoriesAction';
import { FontAwesomeIcon } from '../../components/Icons';
import { Radio, RadioText, FormGroupRadio } from '../../components/Form/Radios';
import BadgeThin from '../../components/Badges/BadgeThin';
import { ButtonLoading } from '../../components/Button';
import { addErrorMessage, errorMessage, hasError ,textValue } from '../../utils/form';
import { ModalDelete, ModalEditImage, ModalBlockLoading } from '../../components/Modals';
import { isRequired, isNumeric } from '../../helpers/regex';
import { toolbarConfig, parseRteValue } from '../../config/rte';
import { DropzonePlusButton } from '../../components/Form/DropzoneFile';
import { ImagesCompressor, createUploadFileForm } from '../../utils/imageHelper';
import { ImageFile } from '../../components/Images';
import uuidv4 from 'uuid/v4';
import { uploadImage } from '../../actions/imageUploadAction';
import { getPathS3, getOriginFromSignedUrl } from '../../utils/AWS';
// import CategoryList from './productAddonForm/CategoryList';

const isEmptyRteValue = rteHtml => {
  return rteHtml === '<p><br></p>'
}

const addImageRawObject = (src, file) => {
  return {
    status: false,
    identifier: uuidv4(),
    fileInput: file,
    URL: src,
    isUploading: false
  }
}

class ProductAddonForm extends React.Component{
  constructor(props){
    super(props)
    this.state = {
      _hasId: this.props.match.params.id?true:false,
      _formSubmit: false,
      _form:{
        id: 0,
        name: '',
        display_name: '',
        descriptionRte: parseRteValue(null),
        description: '',
        img_url: null,
        oldImageUrl: null,
        price: 0,
        order: 0,
        quantity: 0,
        categories: []
      },
      _rawImage: null,
      _deleteConfirmationOpen:false,
      _nameFieldReadOnly: false,
      _errorForm: null,
      _alertErrorOpen: false,
      _alertSuccessOpen: false,
      _errorMessage: '',
      _successMessage: '',
      _eventNextId: null, //possible values: null, 1, 2
      _modalEditImage: {
        isOpen: false,
        defaultImageRatio: 1,
        imageSourceEdits: [],
        purpose: "default"
      }
    }
  }

  componentDidMount(){
    if(this.props.match.params.id){
      const { id } = this.props.match.params;
      this.props.getProductAddonById(id)
    }
    if(!this.props.dataCategories.length){
      this.props.getCategories()
    } else{
      const data = this._bindCategoryData()
      this.setState({
        _form:{
          ...this.state._form,
          categories: [...data]
        }
      })
    }
  }

  componentDidUpdate(prevProps){
    if(prevProps.dataCategories.length !== this.props.dataCategories.length){
      this.setState({
        _form:{
          ...this.state._form,
          categories: this._bindCategoryData()
        }
      })
    }
    if(prevProps.dataProductAddon.isFetch !== this.props.dataProductAddon.isFetch && !this.props.dataProductAddon.isFetch){
      const { data, error } = this.props.dataProductAddon
      if(error){
        if(error.status_code === 404)
          this.props.history.replace('/404')
        this.setState({
          _errorForm: {...error},
          _alertErrorOpen: true,
          _alertSuccessOpen: false,
          _eventNextId: null,
          _errorMessage: this.translateMessage(error.status_code, error.message)
        })
      }
      else if(this.state._eventNextId === 2){
        const categories = data.categories? data.categories.map(item => ({
          id: item.id,
          active: true
        })): []
        this.setState({
          _form: {
            ...this.state._form,
            id: data.id,
            categories: this._bindCategoryData(categories)
          },
          _eventNextId: null,
          _errorForm: null,
          _alertErrorOpen: false,
        })
      }
      else{
        const dataApi = this._bindDataFromApi();
        let _nameFieldReadOnly = false;
        const { injectedProps: { roles } } = this.props;
        if(!roles.includes('superadministrator')) _nameFieldReadOnly = true;
        const categories = data.categories? data.categories.map(item => ({
          id: item.id,
          active: true
        })): []
        this.setState({
          _form:{
            ...this.state._form,
            ...dataApi,
            categories: this._bindCategoryData(categories)
          },
          _nameFieldReadOnly,
          _eventNextId: null,
          _errorForm: null,
          _alertErrorOpen: false,
          _formSubmit: false
        })
      }
    }

    if(prevProps.dataUploaded.isUpload && prevProps.dataUploaded.isUpload !== this.props.dataUploaded.isUpload){
      const { success, error } = this.props.dataUploaded;
      if(error){
        this.setState({
          _formSubmit: false,
          _alertErrorOpen: true,
          _alertSuccessOpen: false,
          _errorMessage: "Error while uploading your image."
        })
      }
      else{
        this.setState({
          _form: {
            ...this.state._form,
            img_url: success.data.url
          },
          _eventNextId: 2,
          _formSubmit: true,
          _rawImage: null
        })
        const payloads = {
          ...this._getPayloadsForApi(),
          img_url: success.data.url
        }
        this.props.saveProductAddon(payloads)
      }
    }

    if(prevProps.dataProductAddon.isSubmit !== this.props.dataProductAddon.isSubmit && !this.props.dataProductAddon.isSubmit){
      const { data, error, status_code, message } = this.props.dataProductAddon
      if(error){
        if(error.status_code === 404)
          this.props.history.replace('/404')
        this.setState({
          _errorForm: {...error},
          _formSubmit: false,
          _alertErrorOpen: true,
          _alertSuccessOpen: false,
          _errorMessage: this.translateMessage(error.status_code, error.message)
        })
      }
      else{
        const { deleted } = this.props.dataProductAddon;
        if(deleted){
          this.props.history.replace(`/product_addons`)
        }
        const { id } = this.props.match.params;
        if(!id && (!this.state._eventNextId || this.state._eventNextId === 2)){
          this.props.history.replace(`/product_addons/${data.id}`)
        }
        let payloads = {};
        if(this.state._eventNextId === 1){
          payloads = { img_url: this.state._form.img_url }
        }
        const dataApi = this._bindDataFromApi(payloads);
        let _nameFieldReadOnly = false;
        const { injectedProps: { roles } } = this.props;
        if(!roles.includes('superadministrator')) _nameFieldReadOnly = true;
        this.setState({
          _form:{
            ...this.state._form,
            id: data.id,
            ...dataApi
          },
          _nameFieldReadOnly,
          _errorForm: null,
          _alertErrorOpen: false,
          _formSubmit: false,
          _eventNextId: this.state._eventNextId === 2? null: this.state._eventNextId,
          _alertSuccessOpen: true,
          _successMessage: this.translateMessage(status_code, message),
        }, () => {
          const { _eventNextId } = this.state;
          if(_eventNextId === 1){
            const { _rawImage, _form } = this.state;
            let key = getPathS3(getOriginFromSignedUrl(_form.oldImageUrl))
            if(!key){
              key = `product-addons/${id}`;
            }
            const tempPayload = createUploadFileForm(
              _rawImage.fileInput, false, {name: key, bucket_type: "assets"}
            );
            this.props.uploadImage(tempPayload, _rawImage);
          }
        })
      }
    }
    if(!this.props.dataProductAddonItems.isSubmit && prevProps.dataProductAddonItems.isSubmit !== this.props.dataProductAddonItems.isSubmit){
      const { error, message, status_code } = this.props.dataProductAddonItems;
      if(error){
        this.setState({
          _formSubmit: false,
          _alertErrorOpen: true,
          _eventNextId: null,
          _alertSuccessOpen: false,
          _errorMessage: "Error while submitting product-addon categories."
        })
      }
      else{
        const { _form } = this.state;
        this.props.getProductAddonById(_form.id)
      }
    }
  }

  _bindCategoryData = (productAddonCategories = null) => {
    const { dataCategories } = this.props;
    const { _form: { categories } } = this.state;
    const data = productAddonCategories? productAddonCategories: categories;
    const ids = data.filter(item => item.active).map(item => Number(item.id));
    return dataCategories.map(item => {
      return {
        id: item.value,
        name: item.label.toLowerCase(),
        active: data.length === 0 || ids.includes(item.value)
      }
    })
  }

  _bindDataFromApi = (customFields = {}) => {
    const { data: dataApi } = this.props.dataProductAddon;
    const descriptionRte = parseRteValue(dataApi.description);
    const data = {
      id: dataApi.id,
      name: dataApi.name,
      img_url: dataApi.signed_url || dataApi.img_url,
      oldImageUrl: dataApi.signed_url || dataApi.img_url,
      display_name: dataApi.display_name,
      descriptionRte,
      description: dataApi.description || '',
      active: dataApi.active,
      price: Number(dataApi.price),
      order: Number(dataApi.order),
      quantity: Number(dataApi.quantity),
      created_at: dataApi.created_at,
      updated_at: dataApi.updated_at,
      ...customFields
    };
    return {...data}
  }

  translateMessage = (statusCode, message) => {
    let messageStr = ''
    switch(statusCode){
      case 200: messageStr = 'Success...'; break;
      case 422: messageStr = 'Please fullfill your form.'; break;
      case 404: messageStr = 'Resource not found.'; break;
      case 400: messageStr = 'Cannot update the data because already processed.'; break;
      case 500: messageStr = "We've got something errors"; break;
      default: messageStr = message; break;
    }
    return messageStr
  }

  _handleInputChange = ({ target: { name, value, checked, type } }) => {
    this.setState({
      _form:{
        ...this.state._form,
        [name]: type === 'checkbox'? checked: value
      }
    })
  }

  _handleChangeEditor = (value, name) =>  {
    let newState = {
      [`${name}Rte`]: value,
      [name]: value.toString("html")
    };
    this.setState({_form: { ...this.state._form, ...newState }})
  }

  _compressImage = async (image) => {
    let fileInput = image
    try{
      //compress file
      fileInput = await ImagesCompressor(image);
    }
    catch(e){
      alert('error while compress the images')
      fileInput = image
    }
    return fileInput;
  }

  _handleDropzoneChange = (files) => {
    if(files.length){
      const [file] = files;
      const { _form, _modalEditImage } = this.state;
      const fileObj = addImageRawObject(file.preview, file)
      let modalEditImage = _modalEditImage;
      if(this.props.useCrop){
        modalEditImage = {
          ..._modalEditImage,
          isOpen: true,
          imageSourceEdits: [{
            imgId: fileObj.identifier,
            src: fileObj.URL
          }]
        }
      }
      this.setState({
        _form: {
          ..._form,
          img_url: file.preview
        },
        _rawImage: fileObj,
        _modalEditImage: {
          ...modalEditImage,
        }
      });
    }
  }

  _hasInputError = name => {
    const { _errorForm } = this.state;
    return _errorForm && _errorForm.errors && hasError(name, _errorForm)
  }

  _renderErrorMessage = name => {
    const { _errorForm } = this.state;
    return errorMessage(name, _errorForm)
  }

  _handleModalDeleteToggle = () => {
    const { _formSubmit } = this.state;
    if(!_formSubmit){
      this.setState({
        _deleteConfirmationOpen: !this.state._deleteConfirmationOpen
      })
    }
  }

  _handleDeleteData = () => {
    const { id } = this.state._form;
    this.setState({
      _formSubmit: true
    })
    this.props.deleteProductAddonById(id);
  }

  _validateForm = () => {
    const { _form: dataForm } = this.state;
    let errors={};
    if(!isRequired(dataForm.name)){
      errors['name'] = addErrorMessage(errors, 'name', 'The name field is required.');
    }
    if(!isRequired(dataForm.display_name)){
      errors['display_name'] = addErrorMessage(errors, 'display_name', 'The display name field is required.');
    }
    if(!isRequired(dataForm.price)){
      errors['price'] = addErrorMessage(errors, 'price', 'The price field is required.');
    }
    if(!isNumeric(dataForm.price)){
      errors['price'] = addErrorMessage(errors, 'price', 'The price field must be numeric.');
    }
    let errorState = null;
    if(Object.keys(errors).length){
      errorState = {
        errors: { ...errors },
        status_code: 422,
        message: 'Please full fill form'
      }
      this.setState({
        _errorForm: errorState? {...errorState}: null,
        _errorMessage: errorState? errorState.message: null,
        _alertErrorOpen: true,
        _alertSuccessOpen: false,
        _errorMessage: 'Please full fill form'
      })
    }
    // console.log("Error: ", errors);
    return Object.keys(errors).length>0;
  }

  _getPayloadsForApi = () => {
    const { _form } = this.state;
    const payloads = {
      name: _form.name,
      display_name: _form.display_name,
      description: isEmptyRteValue(_form.description)? null: _form.description,
      img_url: getOriginFromSignedUrl(_form.oldImageUrl),
      price: _form.price,
      active: _form.active,
      order: _form.order,
      quantity: _form.quantity
    }
    if(_form.id){
      payloads.id = _form.id;
    }
    return payloads;
  }

  _handleSubmitForm = async (e) => {
    e.preventDefault();
    const invalid = await this._validateForm();
    if(invalid) return;
    const { _formSubmit, _form, _rawImage } = this.state;
    if(!_formSubmit){
      let rawImage = _rawImage? { ..._rawImage } : _rawImage;
      let eventNextId = null;
      if(_rawImage){
        if(!_rawImage.compressed){
          const compFileInput = await this._compressImage(rawImage.fileInput);
          rawImage = {
            ...rawImage,
            fileInput: compFileInput,
            compressed: true
          }
        }
        eventNextId = 1;
      }
      this.setState({
        _formSubmit: true,
        _rawImage: rawImage,
        _eventNextId: eventNextId
      }, () => {
        const payloads = this._getPayloadsForApi()
        this.props.saveProductAddon(payloads)
      })
    }
  }

  _renderLoading(){
    const { _formSubmit } = this.state;
    if(!_formSubmit) return null;
    return (
      <Row>
        <Col xs={12}>
          <Alert color="info">
            <strong>Heads up!</strong> Submitting your data...
          </Alert>
        </Col>
      </Row>
    )
  }

  onCropSucceeded = async (blob, ratio) => {
    const blobUrl = URL.createObjectURL(blob);
    let fileName = blobUrl.substr(blobUrl.lastIndexOf('/')+1)
    if(fileName == '') fileName = 'local'
    const { _modalEditImage, _form, _rawImage } = this.state;
    if(_modalEditImage.purpose === 'default'){
      this.setState({
        _form: {
          ..._form,
          img_url: blobUrl
        },
        _rawImage: {
          ..._rawImage,
          fileInput: new File([blob], `${fileName}.jpeg`, {
            type: 'image/jpeg'
          }),
          URL: blobUrl,
          orientation: ratio.ratio <=1? 'portrait': 'landscape'
        },
        _modalEditImage: {
          ..._modalEditImage,
          isOpen: false,
          imageSourceEdits: []
        }
      })
    }
  }

  render(){
    return(
      <Row>
        <ModalBlockLoading isOpen={this.state._eventNextId!==null}/>
        <ModalEditImage
          isOpen={this.state._modalEditImage.isOpen}
          imageSource={this.state._modalEditImage.imageSourceEdits.length? this.state._modalEditImage.imageSourceEdits[0].src: null}
          showGrid
          ratio={this.state._modalEditImage.defaultImageRatio}
          onCropSucceeded={this.onCropSucceeded}
        />
        <ModalDelete
          isOpen={this.state._deleteConfirmationOpen}
          modalTitle="Confirmation."
          modalBody="Are you sure to delete this?"
          onDelete={this._handleDeleteData}
          toggle={this._handleModalDeleteToggle}
        />
        <Col xs={12} sm={6} md={6}>
          <Card>
            <CardHeader>
              <FontAwesomeIcon iconType="align-justify"/> {this.props.title}
              {this.props.edit?
                <div>
                  <Link style={{fontSize: '.65rem'}} to="/product_addons/create">Create new Product Addon</Link>
                </div>
              :null}
            </CardHeader>
            <CardBody>
              {this._renderLoading()}
              <Row>
                <Col xs={12}>
                  <Alert color="danger" isOpen={this.state._alertErrorOpen} toggle={() => this.setState({_alertErrorOpen: false})}>
                    <strong>Oh Snap!</strong> { this.state._errorMessage }
                  </Alert>
                </Col>
                <Col xs={12}>
                  <Alert color="success" isOpen={this.state._alertSuccessOpen} toggle={() => this.setState({_alertSuccessOpen: false})}>
                    <strong>Done!</strong> { this.state._successMessage }
                  </Alert>
                </Col>
              </Row>
              {this.state._form.id? (
                <div>
                  <BadgeThin color="primary">#{this.state._form.id}</BadgeThin>
                </div>
              ): null}
              <Form onSubmit={this._handleSubmitForm}>
                <FormGroup
                  row
                  className={classNames({ 'has-danger has-feedback': this._hasInputError('img_url') })}
                >
                  <Col sm={12}>
                    <Label>Image</Label>
                  </Col>
                  <Col className="d-flex">
                    <ImageFile
                      style={{padding: 0}}
                      alt="Product addon's image banner"
                      src={this.state._form.img_url? this.state._form.img_url : null}
                      showDelete={this.state._rawImage !== null}
                      renderEmptyImageText={() => (
                        <p className="mb-0 text-center">
                          No Image <br/>
                          Size: 700 x 700 in pixels
                        </p>
                      )}
                      onDelete={() => {
                        const { _form } = this.state;
                        this.setState({
                          _form: {
                            ..._form,
                            img_url: this.state._form.oldImageUrl
                          },
                          _rawImage: null
                        })
                      }}
                    />
                  <div className="ml-3">
                    <DropzonePlusButton mulitple={false} onDrop={this._handleDropzoneChange}/>
                  </div>
                </Col>
                  <Col xs={12}>
                    { this._renderErrorMessage('img_url') }
                  </Col>
                </FormGroup>
                <FormGroup
                  className={classNames({ 'has-danger has-feedback': this._hasInputError('name') })}
                >
                  <Label>Name</Label>
                  <Input
                    readOnly={this.state._nameFieldReadOnly}
                    placeholder="Enter a name"
                    name="name"
                    type="text"
                    value={textValue('name', this.state._form)}
                    onChange={this._handleInputChange}
                  />
                  { this._renderErrorMessage('name') }
                </FormGroup>
                <FormGroup
                  className={classNames({ 'has-danger has-feedback': this._hasInputError('display_name') })}
                >
                  <Label>Display Name</Label>
                  <Input
                    placeholder="Enter a display name"
                    name="display_name"
                    type="text"
                    value={textValue('display_name', this.state._form)}
                    onChange={this._handleInputChange}
                  />
                  { this._renderErrorMessage('display_name') }
                </FormGroup>
                <FormGroup
                  className={classNames({ 'has-danger has-feedback': this._hasInputError('description') })}
                >
                  <Label>Description - optional</Label>
                  <RichTextEditor
                    value={this.state._form.descriptionRte}
                    onChange={(value) => this._handleChangeEditor(value, "description")}
                    toolbarConfig={toolbarConfig}
                  />
                  { this._renderErrorMessage('description') }
                </FormGroup>
                <Row>
                  <Col xs={12} sm={6}>
                    <FormGroup
                      className={classNames({ 'has-danger has-feedback': this._hasInputError('price') })}
                    >
                      <Label>Price</Label>
                      <Input
                        className="text-right"
                        placeholder="Enter a price"
                        name="price"
                        type="text"
                        value={textValue('price', this.state._form)}
                        onChange={this._handleInputChange}
                      />
                      { this._renderErrorMessage('price') }
                    </FormGroup>
                  </Col>
                  <Col xs={12} md={6}>
                    <Label>Visibility</Label>
                    <FormGroupRadio
                      className={classNames({ 'has-danger has-feedback': this._hasInputError('active') })}
                    >
                      <Radio
                        type="checkbox"
                        name="active"
                        value={true}
                        checked={textValue('active', this.state._form)}
                        dataOnText="Yes"
                        dataOffText="No"
                        onChange={this._handleInputChange}
                      />
                      <RadioText text="Set to Active"/>
                      { this._renderErrorMessage('active') }
                    </FormGroupRadio>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} sm={6}>
                    <FormGroup
                      className={classNames({ 'has-danger has-feedback': this._hasInputError('order') })}
                    >
                      
                        <Label>Order Position - optional</Label>
                        <Input
                          placeholder="Enter a order position"
                          name="order"
                          type="number"
                          value={textValue('order', this.state._form)}
                          onChange={this._handleInputChange}
                        />
                        { this._renderErrorMessage('order') }
                      
                    </FormGroup>
                  </Col>
                  <Col xs={12} sm={6}>
                    <FormGroup
                      className={classNames({ 'has-danger has-feedback': this._hasInputError('quantity') })}
                    >
                      
                        <Label>Quantity</Label>
                        <Input
                          placeholder="Enter a quantity"
                          name="quantity"
                          type="number"
                          value={textValue('quantity', this.state._form)}
                          onChange={this._handleInputChange}
                        />
                        { this._renderErrorMessage('quantity') }
                      
                    </FormGroup>
                  </Col>
                </Row>
                {
                  this.state._hasId?(
                    <Row>
                      <Col xs={12} md={6}>
                        <FormGroup>
                          <Label>Created</Label>
                          <Input
                            readOnly
                            name="created_at"
                            type="text"
                            value={textValue('created_at', this.state._form)}
                          />
                        </FormGroup>
                      </Col>
                      <Col xs={12} md={6}>
                        <FormGroup>
                          <Label>Updated</Label>
                          <Input
                            readOnly
                            name="updated_at"
                            type="text"
                            value={textValue('updated_at', this.state._form)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  ):null
                }
                <FormGroup row>
                  <Col>
                    <ButtonGroup>
                      <ButtonLoading
                        disabled={this.state._formSubmit}
                        isLoading={this.state._formSubmit}
                        loadingMessage="Submitting..."
                        color="primary"
                      >
                        Submit
                      </ButtonLoading>
                      {
                        this.state._hasId?(
                          <ButtonLoading
                            type="button"
                            disabled={this.state._formSubmit}
                            loadingMessage="Deleting..."
                            onClick={this._handleModalDeleteToggle}
                            color="danger"
                          >
                            Delete
                          </ButtonLoading>
                        )
                        :null
                      }
                    </ButtonGroup>
                  </Col>
                </FormGroup>
              </Form>
            </CardBody>
          </Card>
        </Col>
        {/* {
          this.props.edit?(
            <Col xs={12} md={6}>
              <CategoryList id={this.props.match.params.id}/>
            </Col>
          ): null
        } */}
        {this.state._hasId? (
          <Col xs={12} sm={6} md={6}>
            <Card>
              <CardHeader>
                <FontAwesomeIcon iconType="align-justify"/> Visibility per Category
              </CardHeader>
              <CardBody>
                <CardText>
                  At least one active category.
                </CardText>
                {this.state._form.categories.map(category => (
                  <FormGroup key={category.id} row sm={3}>
                    <Label sm={3} className="text-uppercase" style={{fontWeight: 700, fontSize: '.75rem'}}>
                      {category.name}
                    </Label>
                    <Col sm={9} style={{display: "flex", alignItems: "center"}}>
                      <FormGroupRadio
                      >
                        <Radio
                          type="checkbox"
                          value={true}
                          checked={category.active}
                          dataOnText="Yes"
                          dataOffText="No"
                          onChange={(e) => {
                            const valid = !e.target.checked? this.state._form.categories
                              .filter(item => item.id !== category.id && item.active).length > 0: true;
                            if(!valid){
                              alert("Oops. At least one active category!");
                              return;
                            }
                            this.setState({
                              _form: {
                                ...this.state._form,
                                categories: this.state._form.categories.map(item => {
                                  if(item.id === category.id){
                                    return { ...item, active: e.target.checked }
                                  }
                                  return item;
                                })
                              }
                            })
                          }}
                        />
                        <RadioText text="Set to Active"/>
                      </FormGroupRadio>
                    </Col>
                  </FormGroup>
                ))}
                <FormGroup>
                  <ButtonLoading
                    isLoading={this.state._eventNextId === 2}
                    loadingMessage="Submitting..."
                    color="primary"
                    onClick={async () => {
                      await this.setState({
                        _eventNextId: 2
                      })
                      const { _form } = this.state;
                      const ids = _form.categories.filter(item => item.active)
                      .map(item => Number(item.id))
                      const payloads = {
                        sync: true,
                        categories: ids
                      }
                      this.props.saveProductAddonItems('attach', _form.id, payloads)
                    }}
                  >
                    Save
                  </ButtonLoading>
                </FormGroup>
              </CardBody>
            </Card>
          </Col>
        ):null}
      </Row>
    )
  }
}

const mapStateToProps=({ productAddon: { details: dataProductAddon, productAddonItems }, imageUpload, masterCategories })=>({
  dataProductAddon,
  dataProductAddonItems: productAddonItems,
  dataUploaded: imageUpload,
  dataCategories: masterCategories.options
})
const mapDispatchToProps=(dispatch)=>({
  uploadImage: (payload, rawImages) => dispatch(uploadImage(payload, rawImages)),
  getProductAddonById: id => dispatch(getProductAddonById(id)),
  saveProductAddon: payloads => dispatch(saveProductAddon(payloads)),
  deleteProductAddonById: id => dispatch(deleteProductAddonById(id)),
  getCategories: () => dispatch(getOptionList()),
  saveProductAddonItems: (operation, id, payload) => dispatch(saveProductAddonItems(operation, id, payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(ProductAddonForm)