import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import propTypes from 'prop-types';
import {
  Row,
  Col,
  Card,
  CardImg,
  CardSubtitle,
  CardText,
  CardHeader,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  InputGroup,
  InputGroupAddon,
  Button,
  Alert
} from 'reactstrap';
import {
  sellRequestBoxConditionOptions,
  sellRequestSneakerConditionOptions
} from '../../../../constants/kickavenue';
import {
  textValue,
  textChange,
  checkChange,
  hasError,
  errorMessage,
  hasErrorArray,
  errorMessageArray
} from '../../../../utils/form';
import getErrMsg from '../../../../utils/errMsg';
import noImage from '../../../../assets/img/no-image.png';
import SelectUserAsync from '../../../../components/Form/Select/Async/SelectUser';
import ModalConfirmation from '../../../../components/Modals/ModalConfirmation';
import ButtonSubmit from '../../../../components/Button/ButtonLoading';
import { getCacheImage } from '../../../../utils/imageHelper';
import { Radio, RadioText, FormGroupRadio } from '../../../../components/Form/Radios';
import { userSellDefaultConditions, userSellApparelConditions, userSellHandbagConditions, sneakersConditionOption, userSellLifestyleConditions, brandConditions } from '../../../../utils/userSell';

const getPublicImageUrl = image => {
  return image.signed_url || image.image_url
}

class ChangeSellerForm extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      _id: 0,
      _oldSeller: {},
      _categoryListing: 'SNEAKERS',
      _form: {
        listingConditions: [],
      },
      _error: null,
      _errorMessage: null,
      _errorAlertOpen: false,
      _isConfirmationModalOpen: false,
      sneakersConditionOpt: this._getSneakerConditionsOpt('SNEAKERS'),
      boxConditionOpt: this._getBoxConditionsOpt('SNEAKERS'),
      disabled_box: false
    };
    this._handleOnChange = this._handleOnChange.bind(this);
    this._handleCheckChange = this._handleCheckChange.bind(this);
    this._handleOnSelectUserChange = this._handleOnSelectUserChange.bind(this);
    this._handleOnSelectUserOpen = this._handleOnSelectUserOpen.bind(this);
    this._handleOnToggle = this._handleOnToggle.bind(this);
    this._handleFormOnSubmit = this._handleFormOnSubmit.bind(this);
    this._handleOnSubmitConfirm = this._handleOnSubmitConfirm.bind(this);
    this._handleAlertErrorToggle = this._handleAlertErrorToggle.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { hide_box_info } = this.props.dataVariant;
    if(prevProps.isFetching && prevProps.isFetching!==this.props.isFetching){
      const category = this.props.dataProduct.category_name;
      const { id, user, ...userSell } = this.props.dataUserSell;
      this.setState({
        _id: id,
        _categoryListing: category.toUpperCase(),
        _form: { ...userSell, 
          listingConditions: this._getSellConditions(category.toUpperCase(), this.props.dataBrand)
        },
        boxConditionOpt: this._getBoxConditionsOpt(category.toUpperCase(), this.props.dataBrand),
        sneakersConditionOpt: this._getSneakerConditionsOpt(category.toUpperCase(), this.props.dataBrand),
        _oldSeller: user,
        disabled_box: hide_box_info
      });
    }
    if(prevProps.isSubmit && prevProps.isSubmit!==this.props.isSubmit){
      const { dataError } = this.props;
      if(dataError){
        const { status_code, errors } = dataError;
        const _errorMessage = getErrMsg(dataError.status_code);
        this.setState({
          _error: { errors, statusCode: status_code },
          _errorMessage,
          _errorAlertOpen: true
        })
      }
    }
    if(prevProps.dataBrand !== this.props.dataBrand && this.props.dataBrand){
      const category = this.props.dataProduct.category_name;
      this.setState({
        _form: {
          ...this.state._form,
          listingConditions: this._getSellConditions(category.toUpperCase(), this.props.dataBrand),
        },
        boxConditionOpt: this._getBoxConditionsOpt(category.toUpperCase(), this.props.dataBrand),
        sneakersConditionOpt: this._getSneakerConditionsOpt(category.toUpperCase(), this.props.dataBrand),
        // disabled_box: hide_box_info
      })
    }
  }

  _getBoxConditionsOpt = (category, brand = null) => {
    const parentBrand = brand && brand.ancestors.length? brand.ancestors[0].slug:  null;
    if(category === 'LIFESTYLES'){
      switch (parentBrand) {
        case 'bike':
          return [{value:'NO_BOX',label:'Missing Box'}];
        case 'games-consoles':
          return [{value:'SEMPURNA',label:'Sempurna'},{value:'NO_BOX',label:'Missing Box'}];
        default:
          break;
      }
    }
    return sellRequestBoxConditionOptions
  }

  _getSneakerConditionsOpt = (category, brand = null) => {
    const slug = brand && brand.ancestors.length? brand.ancestors[0].slug:  null;
    if(category === 'LIFESTYLES'){
      switch (slug) {
        case 'bike':
          return [{value:'BARU', label:'Brand New'}];
        default:
          return [{value:'BARU', label:'Brand New'},{value:'BEKAS', label:'Preowned'}];
      }
    } else{
      return sneakersConditionOption
      .filter(item => item.categories.includes(category.toLowerCase()))
      .map(item => {
        let label = item.label;
        if(item.en && item .en[category]){
          label = item .en[category];
        }
        return { value: item.value, label }
      })
    }
    return []
  }

  _getSellConditions(category, brand = null){
    let listingConditions = [];
    const {
      missing_accessories,
      yellowing,
      display_item,
      sneakers_defect,
      defects
    } = this.props.dataUserSell;
    // console.log("category user sell: ",category, this.props.dataUserSell)
    switch(category){
      case 'APPARELS': {
        listingConditions = userSellApparelConditions.map(item => {
          const getValue = defects.find(i => i.name === item.name.toUpperCase());
          return {
            ...item,
            value: getValue ? getValue.value: false
          }
        })
        break;
      }
      case 'HANDBAGS': {
        const tambahan = [{
          name: 'missing_accessories',
          value: missing_accessories === null? false: missing_accessories
        }];
        const allDefects = [
          ...tambahan,
          ...defects.map(item=>({...item, name: item.name.toLowerCase()}))
        ];
        listingConditions = userSellHandbagConditions.map(item => {
          const getValue = allDefects.find(i => i.name === item.name);
          return {
            ...item,
            value: getValue ? getValue.value: false
          }
        })
        break;
      }
      case 'LIFESTYLES': {
        const slug = brand && brand.ancestors.length? brand.ancestors[0].slug:  null;
        // const slug = null
        const selected = brandConditions.find(item => item.slug === slug);
        const tambahan = [{
          name: 'missing_accessories',
          value: missing_accessories === null? false: missing_accessories
        }, {
          name: 'display_item',
          value: display_item === null? false: display_item
        }];
        const allDefects = [
          ...tambahan,
          ...defects.map(item=>({...item, name: item.name.toLowerCase()}))
        ];
        listingConditions = userSellLifestyleConditions
        .filter(item => !selected || (selected.conditions.includes(item.name)))
        .map(item => {
          const getValue = allDefects.find(i => i.name === item.name);
          return {
            ...item,
            value: getValue ? getValue.value: false
          }
        })
        break;
      }
      default: {
        const myConditions = [{
          name: 'missing_accessories',
          value: missing_accessories === null? false: missing_accessories
        },{
          name: 'yellowing',
          value: yellowing === null? false: yellowing
        },{
          name: 'display_item',
          value: display_item === null? false: display_item
        },{
          name: 'sneakers_defect',
          value: sneakers_defect === null? false: sneakers_defect
        }];
        listingConditions = userSellDefaultConditions.map(item => {
          const getValue = myConditions.find(i => i.name === item.name);
          return {
            ...item,
            value: getValue ? getValue.value: false
          }
        })
        break;
      }
    }
    // console.log("category - listingConditions: ", category, listingConditions);
    return listingConditions;
    // switch(category){
    //   case 'APPARELS': {
    //     listingConditions = userSellApparelConditions.map(item => {
    //       return {
    //         ...item,
    //         value: false
    //       }
    //     })
    //     break;
    //   }
    //   default: {
    //     listingConditions = userSellDefaultConditions.map(item => {
    //       return {
    //         ...item,
    //         value: false
    //       }
    //     })
    //     break;
    //   }
    // }
    // return listingConditions;
  }

  _handleOnChange(e){
    const lastState = { ...this.state._form };
    this.setState({
      _form: { ...textChange(e, lastState) }
    });
  }

  _handleCheckChange(e){
    const lastState = { ...this.state._form };
    this.setState({
      _form: { ...checkChange(e, lastState) }
    });
  }

  _handleOnSelectUserChange(selected){
    const { _form } = this.state;
    _form.user = selected;
    this.setState({
      _form,
      _recentUserSearch: selected && this.state._recentUserSearch
    })
  }

  _handleOnSelectUserOpen(){
    if(this.state._form.user){
      return this.state._recentUserSearch&&this.refs.selectSeller.loadSelectOptions(this.state._recentUserSearch);
    }
    return this.refs.selectSeller.loadSelectOptions('');
  }

  _handleOnToggle(){
    this.setState({
      _isConfirmationModalOpen: !this.state._isConfirmationModalOpen
    });
  }

  _handleFormOnSubmit(e){
    e.preventDefault();
    this._handleOnToggle();
  }

  _handleClearError(){
    return  { _error: null, _errorMessage: null, _errorAlertOpen: false }
  }

  _handleOnSubmitConfirm(){
    const { _categoryListing, disabled_box, otp_code } = this.state;
    const { user, listingConditions, ...payloads } = this.state._form;
    payloads.user_id = user && user.id;
    let payloadConditions = {};
    if(_categoryListing === 'SNEAKERS'){
      payloadConditions = listingConditions.reduce((obj, item)=>{
        obj[item.name] = item.value
        return { ...obj }
      }, Object.assign({}))
    }
    // else if(_categoryListing === 'APPARELS'){
    else{
      const _listConditions = listingConditions.filter(item => item.name !== 'missing_accessories');
      const missing_accessories = listingConditions.find(item => item.name === 'missing_accessories');
      payloadConditions = {
        display_item: false,
        yellowing: false,
        missing_accessories: missing_accessories? missing_accessories.value: false,
        sneakers_defect: _listConditions.filter(item => item.value === true && !item.inclusions).length > 0,
        defects: _listConditions.map(item => ({
          name: item.name.toUpperCase(),
          value: item.value
        }))
      }
    }
    this.props.onChangeSellerSubmit({
      ...payloads,
      verify_type:'google',
      box_condition: _categoryListing==='HANDBAGS' || disabled_box? 'SEMPURNA': payloads.box_condition,
      ...payloadConditions
    });
    this.setState({
      ...this._handleClearError(),
      _isConfirmationModalOpen: false
    })
  }

  _handleAlertErrorToggle(){
    this.setState({
      _errorAlertOpen: !this.state._errorAlertOpen
    })
  }

  _handleConditionsCheckedChange(e, index){
    const { listingConditions } = this.state._form;
    const item = listingConditions[index];
    const arr = listingConditions.filter(item => item.name !== e.target.name);
    arr.splice(index, 0, {
      ...item,
      value: e.target.checked
    })
    this.setState({
      _form:{
        ...this.state._form,
        listingConditions: arr
      }
    });
  }

  render(){
    const getImage = (!this.props.isFetching && getCacheImage(getPublicImageUrl(this.props.dataProduct))) || noImage;
    const defectCount = this.state._form.listingConditions.filter(condition => !condition.inclusions).length;
    return(
      <Card className={classNames({'card__has-error': this.state._errorAlertOpen})}>
        <CardHeader><i className="fa fa-align-justify"></i> Change Seller Form</CardHeader>
        <CardImg top width="100%" src={ getImage }/>
        <CardBody>
          <CardSubtitle style={{marginBottom: '.75rem'}}>
            { this.props.dataProduct.display_name } { this.props.dataProduct.size? <span>(Size: {this.props.dataProduct.size.US} US)</span>:null}
          </CardSubtitle>
          <CardText style={{marginBottom: '.5rem'}}>
            <Link title="See more..." to={`/sell_request/${this.state._id}`}>See Sell Request.</Link>
          </CardText>
          <Form onSubmit={this._handleFormOnSubmit}>
            <Alert
              color="danger"
              isOpen={this.state._errorAlertOpen}
              toggle={this._handleAlertErrorToggle}
            >
              <strong>Oh Snap!</strong> {this.state._errorMessage}
            </Alert>
            <ModalConfirmation
              isOpen={this.state._isConfirmationModalOpen}
              toggle={this._handleOnToggle}
              onSubmit={this._handleOnSubmitConfirm}
            />
            <FormGroup>
              <Label for="formOriginalSeller">Original Seller <Link title="See more..." to={`/users/${this.state._oldSeller.id}`}>See more...</Link></Label>
              <Input
                id="formOriginalSeller"
                value={textValue('email', this.state._oldSeller)}
                readOnly
              />
            </FormGroup>
            <FormGroup className={classNames({'has-danger has-feedback': hasErrorArray(['id', 'user_id', 'status'], this.state._error)})}>
              <Label for="formSelectSeller">New Seller*</Label>
              <SelectUserAsync
                defaultOptions
                id="formSelectSeller"
                ref="selectSeller"
                value={this.state._form.user}
                paramsApi={{ roles: 'seller,unsuspended_seller,administrator', role_query: 'or', scope: 'all' }}
                placeholder="Type and select a seller..."
                noResultsText="Cannot find seller."
                onSelectSearch={(input)=>this.setState({_recentUserSearch: input})}
                onSelectChange={this._handleOnSelectUserChange}
                onSelectOpen={this._handleOnSelectUserOpen}
              />
              { errorMessageArray(['id', 'user_id', 'status'], this.state._error) }
            </FormGroup>
            <Row>
              <Col xs={12} md={6}>
                <FormGroup className={classNames({'has-danger has-feedback':hasError('asking_price',this.state._error)})}>
                  <Label for="formAskingPrice">Asking Price*</Label>
                  <Input
                    id="formAskingPrice"
                    name="asking_price"
                    value={textValue('asking_price', this.state._form)}
                    placeholder="Asking price in Rupiah Currency"
                    className="text-right"
                    onChange={this._handleOnChange}
                  />
                  { errorMessage('asking_price',this.state._error) }
                </FormGroup>
              </Col>
              <Col xs={12} md={6}>
                <FormGroup className={classNames({'has-danger has-feedback':hasError('purchase_price',this.state._error)})}>
                  <Label for="formPurchasePrice">Purchase Price</Label>
                  <Input
                    id="formPurchasePrice"
                    name="purchase_price"
                    value={textValue('purchase_price', this.state._form)}
                    placeholder="Purchase price in Rupiah Currency"
                    className="text-right"
                    onChange={this._handleOnChange}
                  />
                  { errorMessage('purchase_price',this.state._error) }
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={6}>
                <FormGroup className={classNames({'has-danger has-feedback':hasError('sneakers_condition',this.state._error)})}>
                  <Label for="formSneakersCondition">Listing Condition</Label>
                  <Input
                    id="formSneakersCondition"
                    name="sneakers_condition"
                    value={textValue('sneakers_condition', this.state._form)}
                    type="select"
                    placeholder="Sneaker condition..."
                    onChange={this._handleOnChange}
                  >
                  { this.state.sneakersConditionOpt.map((item, index) => <option key={index} value={item.value}>{ item.label }</option>) }
                  </Input>
                  { errorMessage('sneakers_condition',this.state._error) }
                </FormGroup>
              </Col>
              <Col xs={12} md={6}>
                <FormGroup className={classNames({'has-danger has-feedback':hasError('box_condition',this.state._error)})}>
                  <Label for="formBoxCondition">
                    Box Condition
                    {
                      this.state._categoryListing === 'HANDBAGS'|| this.state.disabled_box?
                        <span style={{fontSize: '.65rem', marginLeft: '5px'}}>(disabled)</span>: null
                    }
                  </Label>
                  {
                    this.state._categoryListing !== 'HANDBAGS' && !this.state.disabled_box?(
                      <Input
                        id="formBoxCondition"
                        name="box_condition"
                        value={textValue('box_condition', this.state._form)}
                        type="select"
                        disabled={this.state.disabled_box}
                        placeholder="Box condition..."
                        onChange={this._handleOnChange}
                      >
                      { this.state.boxConditionOpt.map((item, index) => <option key={index} value={item.value}>{ item.label }</option>) }
                      </Input>
                    ): <p>-</p>
                  }
                  { errorMessage('box_condition',this.state._error) }
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={6}>
                <FormGroup className={classNames({'has-danger has-feedback':hasError('pre_verified',this.state._error)})}>
                  <Radio
                    name="pre_verified"
                    value="true"
                    checked={textValue('pre_verified', this.state._form)}
                    dataOnText="Yes"
                    dataOffText="No"
                    onChange={this._handleCheckChange}
                  />
                  <RadioText text="Pre-verified"/>
                  { errorMessage('pre_verified',this.state._error) }
                </FormGroup>
              </Col>
              <Col xs={12} md={6}>
                <FormGroup className={classNames({'has-danger has-feedback':hasError('pre_order',this.state._error)})}>
                  <Radio
                    name="pre_order"
                    value="true"
                    checked={textValue('pre_order', this.state._form)}
                    dataOnText="Yes"
                    dataOffText="No"
                    onChange={this._handleCheckChange}
                  />
                  <RadioText text="Pre-order"/>
                  { errorMessage('pre_order',this.state._error) }
                </FormGroup>
              </Col>
              {/* <Col xs={12} md={6}>
                <FormGroup className={classNames({'has-danger has-feedback':hasError('missing_accessories',this.state._error)})}>
                  <Radio
                    name="missing_accessories"
                    value="true"
                    checked={textValue('missing_accessories', this.state._form)}
                    dataOnText="Yes"
                    dataOffText="No"
                    onChange={this._handleCheckChange}
                  />
                  <RadioText text="Missing Accessories"/>
                  { errorMessage('missing_accessories',this.state._error) }
                </FormGroup>
              </Col>
              <Col xs={12} md={6}>
                <FormGroup className={classNames({'has-danger has-feedback':hasError('yellowing',this.state._error)})}>
                  <Radio
                    name="yellowing"
                    value="true"
                    checked={textValue('yellowing', this.state._form)}
                    dataOnText="Yes"
                    dataOffText="No"
                    onChange={this._handleCheckChange}
                  />
                  <RadioText text="Yellowing"/>
                  { errorMessage('yellowing',this.state._error) }
                </FormGroup>
              </Col>
              <Col xs={12} md={6}>
                <FormGroup className={classNames({'has-danger has-feedback':hasError('display_item',this.state._error)})}>
                  <Radio
                    name="display_item"
                    value="true"
                    checked={textValue('display_item', this.state._form)}
                    dataOnText="Yes"
                    dataOffText="No"
                    onChange={this._handleCheckChange}
                  />
                  <RadioText text="Display Item"/>
                  { errorMessage('display_item',this.state._error) }
                </FormGroup>
              </Col>
              <Col xs={12} md={6}>
                <FormGroup className={classNames({'has-danger has-feedback':hasError('sneakers_defect',this.state._error)})}>
                  <Radio
                    name="sneakers_defect"
                    value="true"
                    checked={textValue('sneakers_defect', this.state._form)}
                    dataOnText="Yes"
                    dataOffText="No"
                    onChange={this._handleCheckChange}
                  />
                  <RadioText text="Defect"/>
                  { errorMessage('sneakers_defect',this.state._error) }
                </FormGroup>
              </Col> */}
            </Row>
            {
              defectCount?(
                <Row>
                  <Col xs={12}><p>Defects:</p></Col>
                  {
                    this.state._form.listingConditions.filter(condition => !condition.inclusions).map((condition, index) => (
                      <Col xs={12} md={6} key={index} className="d-flex align-items-center">
                        <FormGroupRadio>
                          <Radio
                            type="checkbox"
                            name={condition.name}
                            value={true}
                            checked={condition.value}
                            dataOnText="Yes"
                            dataOffText="No"
                            onChange={e => this._handleConditionsCheckedChange(e, index)}
                          />
                          <RadioText text={condition.en}/>
                        </FormGroupRadio>
                      </Col>
                    ))
                  }
                </Row>
              ): null
            }
            {
              this.state._form.listingConditions.filter(condition => condition.inclusions).length?(
                <Row>
                  <Col xs={12}><p>Inclusions:</p></Col>
                  {
                    this.state._form.listingConditions.filter(condition => condition.inclusions).map((condition, index) => (
                      <Col xs={12} md={6} key={index} className="d-flex align-items-center">
                        <FormGroupRadio>
                          <Radio
                            type="checkbox"
                            name={condition.name}
                            value={true}
                            checked={condition.value}
                            dataOnText="Yes"
                            dataOffText="No"
                            onChange={e => this._handleConditionsCheckedChange(e, defectCount + index)}
                          />
                          <RadioText text={condition.en}/>
                        </FormGroupRadio>
                      </Col>
                    ))
                  }
                </Row>
              ): null
            }
            <Row>
              <Col xs={12} md={6}>
                <FormGroup className={classNames({'has-danger has-feedback':hasError('otp_code',this.state._error)})}>
                  <Label for="formOtpCOde">OTP*</Label>
                  <Input
                    id="formOtpCOde"
                    name="otp_code"
                    value={textValue('otp_code', this.state._form)}
                    placeholder="Enter a otp code"
                    className="text-right"
                    onChange={this._handleOnChange}
                  />
                  { errorMessage('otp_code',this.state._error) }
                </FormGroup>
              </Col>
            </Row>
            <FormGroup>
              <ButtonSubmit
                block
                color="primary"
                loadingMessage="Submitting..."
                isLoading={this.props.isSubmit}
              >
                Submit
              </ButtonSubmit>
            </FormGroup>
            <small>Fields marked with * are required</small>
          </Form>
        </CardBody>
      </Card>
    )
  }
}

export default ChangeSellerForm;

ChangeSellerForm.propTypes = {
  dataProduct: propTypes.object,
  dataUserSell: propTypes.object,
  dataSale: propTypes.object,
  dataError: propTypes.object,
  isFetching: propTypes.bool,
  isSubmit: propTypes.bool,
  onChangeSellerSubmit: propTypes.func.isRequired
}

ChangeSellerForm.defaultProps ={
  isFetching: false,
  isSubmit: false
}