import {
  AUTH_TOKEN_KEY,
	REWARD_ROUTE_API,
	REWARD_FETCHING,
	REWARD_DETAIL_FETCH,
	REWARD_LIST_FETCH,
	REWARD_ERROR,
	REWARD_ADD,
	REWARD_UPDATE,
	REWARD_DELETE,
	REWARD_CLEAR_FORM,
	REWARD_ITEM_LIST,
	REWARD_ITEM_FETCH,
	REWARD_ITEM_ERROR,
} from '../constants';
const initialState={
	list:[],
	detail:{},
	rewardItem:{
		list:[],
		isFetch:false,
		isSubmit:false,
		error:null
	},
	isFetch:false,
	isSubmit:false,
	error:null
}

const onFetching = (lastState,payload) => {
	if(payload.type==='fetch')
		return { ...lastState, isFetch:payload.status }
	else if(payload.type==='submit')
		return { ...lastState, isSubmit:payload.status }

	return { ...lastState, isFetch:false,isSubmit:false }
}

const onFetchingReward = (lastState,payload) => {
	const { rewardItem }=lastState;
	if(payload.type==='fetch')
		return { ...lastState, rewardItem:{...rewardItem, isFetch:payload.status} }
	else if(payload.type==='submit')
		return { ...lastState, rewardItem:{...rewardItem, isSubmit:payload.status} }
	return { ...lastState, rewardItem:{...rewardItem, isFetch:false, isSubmit:false } }
}

const onAddReward=(lastState,payload)=>{
	const {data, message, status_code}=payload;
    return { ...lastState, detail:{ ...data, message, status_code }, success:'Insert has been successfully!',error:null }
}

const onUpdateReward=(lastState,payload)=>{
	const {data, message, status_code}=payload;
    return { ...lastState, detail:{ ...data, message, status_code }, success:'Update has been successfully!',error:null }
}

const onDeleteReward=(lastState,payload)=>{
	const {data, message, status_code}=payload;
    return { ...lastState, detail:{ ...data, message, status_code, delete:true }, success:'Delete has been successfully!',error:null }
}

const reducer=(state=initialState, action)=>{
	switch(action.type){
		case REWARD_LIST_FETCH:{
			const {data, status_code, message}=action.payload;
			return {...state, list:{...data, message, status_code}, error:null, success:null }
		}
		case REWARD_DETAIL_FETCH:{
			const {data, status_code, message}=action.payload;
			return { ...state, detail:{...data, message, status_code}, error:null, success:null }
		}
		case REWARD_FETCHING:  return onFetching(state, action.payload); 
		case REWARD_CLEAR_FORM:return {...initialState};     
		case REWARD_ERROR:
			return { ...state, ...action.payload }
		case REWARD_ADD: return onAddReward(state, action.payload);
		case REWARD_UPDATE: return onUpdateReward(state, action.payload);
		case REWARD_DELETE: return onDeleteReward(state, action.payload);
		case REWARD_ITEM_FETCH: return onFetchingReward(state, action.payload);
		case REWARD_ITEM_LIST:{
			const {data, status_code, message}=action.payload;
			return {...state, rewardItem:{ list: {...data, message, status_code}, error:null, success:null }}
		}
		case REWARD_ITEM_ERROR: {
			const { rewardItem } = state;
			return { ...state, rewardItem: {...rewardItem, ...action.payload } }
		}
		default:return state;
	}
}
export default reducer;