import ImageCompressor from 'image-compressor.js';
import { getBucketAndKey, getOriginFromSignedUrl } from '../AWS';
import uuidv4 from "uuid/v4";
// Images Compressor function
export const ImagesCompressor = (file) => {
  const imageCompressing = new ImageCompressor();
  const maxSize = 1000000; // byte
  const originSize = file.size; // origin size
  let isQuality = maxSize < originSize ? 0.6 : 1;
  // console.log('KUALITAS ', isQuality.toFixed(1));

  return imageCompressing.compress(file, {
    quality: isQuality
  })
  .then(result => result)
  .catch(error => file)
}

// Get Image Caching URL function
export const getCacheImage = (url, width = 500) => {
  if(!url || url === "" || typeof url !== "string")
    return url;
  const origin = getOriginFromSignedUrl(url);
  const data = getBucketAndKey(origin);
  if(data && data.length > 0 && !data.includes('ka-faq-assets')){
    const [bucket, key] = data;
    const cloudImageUrl = JSON.stringify({
      bucket,
      key: decodeURIComponent(key),
      edits: {
        resize: {
          width
        }
      }
    });
    const splittedKey = key.split('.');
    const fileType = splittedKey[splittedKey.length - 1];
    if(fileType == "gif"){
      return url;
    }
    const newImagePath = Buffer.from(cloudImageUrl).toString('base64');
    return "https://d5ibtax54de3q.cloudfront.net/" + newImagePath;
  }
  return url
    .replace(/kickavenue-user-uploads.s3([\.|-][^\.]+)?.amazonaws.com/, "d1i34sodava61.cloudfront.net")
    .replace(/kickavenue-assets.s3([\.|-][^\.]+)?.amazonaws.com/, "d3jneea6mhvdzo.cloudfront.net")
    .replace(/ka-faq-assets([\.|-][^\.]+)?.amazonaws.com/, "d2wvligaol3ruc.cloudfront.net")
}


export const readFile = file => {
  return new Promise(resolve => {
    const reader = new FileReader()
    reader.addEventListener('load', () => resolve(reader.result), false)
    reader.readAsDataURL(file)
  })
}

//files Array | File
//multiples boolean
//payloads Object examples: {name: "folder/123", bucket_type: "assets"}
export const createUploadFileForm = (files, multiples, payloads = null) => {
  const tempPayload = new FormData();
  if(payloads)
    Object.keys(payloads).map(k => tempPayload.append(k, payloads[k]))
  if(multiples){
    files.map(file => tempPayload.append('file[]', file))
  } else{
    tempPayload.append('file', files)
  }
  return tempPayload;
}

export const addImageRawObject = file => {
  return {
    identifier: uuidv4(),
    fileInputOrigin: file, //for rollback.
    fileInput: file,
    URL: file.preview,
    status: false,
    isUploading: false,
    compressed: false
  }
}
