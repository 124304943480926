import React from 'react';
import propTypes from 'prop-types';
import { Link } from 'react-router-dom';

import DateToHuman from '../../../../components/DateToHuman';
import { TextRupiah } from '../../../../components/Text';
import { getPaymentType, getPaymentStatus, getPaymentVia } from '../../../../constants/kickavenue';
import SmallInline from '../../../../styled/Typograph/Small/SmallInline';
import Paragraph from '../../../../styled/Typograph/Paragraph';

const RowItem = props => {
  const {
    id,
    rowId,
    invoiceNumber,
    email,
    amount,
    paymentMethod,
    paymentStatus,
    updatedAt,
    expired,
    paymentVia,
    bank
  } = props;
  const via = getPaymentVia(paymentVia);
  return (
    <tr>
      <th className="v-center" scope="row">{ rowId }</th>
      <td className="v-center">
        <Paragraph>
          { invoiceNumber } <br />
          <SmallInline className="text-muted">{ email }</SmallInline>
        </Paragraph>
      </td>
      <td className="v-center"><TextRupiah bold prefix="Rp. " value={amount}/></td>
      <td className="v-center">
        {
          bank && paymentMethod === 'virtual_account'?
          <span style={{fontSize: '.75rem'}}>{bank+ '-'+ getPaymentType(paymentMethod)}</span>
          : getPaymentType(paymentMethod)}
        <br/>
        { via !== null? <span style={{fontSize: '.75rem'}}>via <i>{via}</i></span>: null}
      </td>
      <td className="v-center"><DateToHuman  value={updatedAt} id={`tooltip_${rowId}`}/></td>
      <td className="v-center">
        { expired && paymentStatus==='PENDING' ?'Reach Limit Time':getPaymentStatus(paymentStatus) }
      </td>
      <td className="v-center">
        <Link to={`/top_up/${id}`} className="btn btn-link">Details</Link>
      </td>
    </tr>
  )
}

export default RowItem;

RowItem.defaultProps = {
  amount: 0,
  paymentMethod: 'bank_transfer',
  paymentStatus: 'PENDING',
  expired: false
}

RowItem.propTypes={
  id: propTypes.number.isRequired,
  amount: propTypes.oneOfType([propTypes.number,propTypes.string]),
  rowId: propTypes.number.isRequired,
  expired: propTypes.bool,
  updatedAt: propTypes.string,
  email: propTypes.string,
  paymentMethod: propTypes.string,
  paymentStatus: propTypes.string,
  invoiceNumber: propTypes.string,
  paymentVia: propTypes.string,
  bank: propTypes.string,
}