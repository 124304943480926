import React from 'react';
import { Card, Collapse } from 'reactstrap';
import propTypes from 'prop-types';
import SearchForm from './filterPanel/SearchForm';
import SortForm from './filterPanel/SortForm';
import FilterForm from './filterPanel/FilterForm';
import { sellRequestStatusOptions } from '../../../constants/kickavenue';

class FilterPanel extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      keyword: props.keyword,
      status: props.status,
      pre_order: props.pre_order,
      pre_verified: props.pre_verified,
      consignment: props.consignment,
      sneakers_condition: props.sneakers_condition,
      per_page: props.per_page,
      sort_by: props.sort_by,
    }
    this._handleOnChangeInput = this._handleOnChangeInput.bind(this);
    this._handleApplyFilterButtonClick = this._handleApplyFilterButtonClick.bind(this);
    this._handleOnChangeInputAndApply = this._handleOnChangeInputAndApply.bind(this);
    this._handleStatusesChangeInput = this._handleStatusesChangeInput.bind(this);
    this._handleSearchButtonClick = this._handleApplyFilterButtonClick.bind(this);
    this._isCheckedStatus = this._isCheckedStatus.bind(this);
    this._isCheckedCondition = this._isCheckedCondition.bind(this);
    this._handleConditionsChangeInput = this._handleConditionsChangeInput.bind(this);
  }

  _handleOnChangeInput(e){
    const lastState = {...this.state};
    lastState[e.target.name] = e.target.value;
    this.setState({...lastState});
  }

  _isCheckedStatus(val) {
    if (val === 'all') {
      return this.state.status.split(',').length === sellRequestStatusOptions.length
    } else {
      if(val === "default"){
        return this.state.status === '';
      }

      const asArray = this.state.status.split(",");
      const isFound = asArray.some(status => status === val);
      
      return isFound;
    }
  }

  _isCheckedCondition(val){
    if (val === 'all') {
      return this.state.sneakers_condition.split(',').length === 3
    } else {
      return val === 'default' ? this.state.sneakers_condition === '' : this.state.sneakers_condition.includes(val)
    }
  }

  _handleStatusesChangeInput(e) {
    const lastState = {...this.state};
    let status = e.target.value
    let statuses = this.state.status.split(',')
    let arrStatus = [];
    if (this._isCheckedStatus(status)) {
      lastState['status'] = statuses.filter(itm => itm !== status).join(',')
    } else if (status === 'default' || status === 'all') {
      if (status === 'default') {
        lastState['status'] = ''
      } else {
        arrStatus = [...sellRequestStatusOptions.map(v => v.value)]
        lastState['status'] = arrStatus.join(',')
      } 
    } else {
      if (this._isCheckedStatus('default')) {
        lastState['status'] = status
      } else if (status !== 'all') {
        arrStatus = [status, ...statuses]
        lastState['status'] = arrStatus.join(',')
      }
    }
    this.setState({...lastState});
  }

  _handleConditionsChangeInput(e) {
    const lastState = {...this.state};
    let condition = e.target.value;
    let conditions = this.state.sneakers_condition.split(',');
    let newValue = [];
    if (this._isCheckedCondition(condition)) {
      lastState['sneakers_condition'] = conditions.filter(itm => itm !== condition).join(',');
    } else if (condition === 'default' || condition === 'all') {
      if (condition === 'default') {
        lastState['sneakers_condition'] = '';
      } else {
        newValue = ["BARU", "BEKAS", "STANDARD_99"];
        lastState['sneakers_condition'] = newValue.join(',')
      } 
    } else {
      if (this._isCheckedCondition('default')) {
        lastState['sneakers_condition'] = condition
      } else if (condition !== 'all') {
        newValue = [condition, ...conditions]
        lastState['sneakers_condition'] = newValue.join(',')
      }
    }
    this.setState({...lastState});
  }
  
  _handleOnChangeInputAndApply(e){
    const lastState = {...this.state};
    lastState[e.target.name] = e.target.value;
    this.setState({...lastState}, ()=> this._handleSubmitFilter());
  }

  _handleApplyFilterButtonClick(e){
    e.preventDefault();
    this._handleSubmitFilter();
  }

  _handleSubmitFilter(){
    const {
      keyword,
      status,
      pre_order,
      pre_verified,
      consignment,
      sneakers_condition,
      per_page,
      sort_by
    } = this.state;
    this.props.onApplyFilter({
      keyword,
      status,
      pre_order,
      pre_verified,
      consignment,
      sneakers_condition,
      per_page,
      sort_by
    });
  }

  componentWillReceiveProps(nextProps){
    if(nextProps.isReset && this.props.isReset!==nextProps.isReset){
      const {
        keyword,
        status,
        pre_order,
        pre_verified,
        consignment,
        sneakers_condition,
        per_page,
        sort_by
      } = nextProps;
      this.setState({
        keyword,
        status,
        pre_order,
        pre_verified,
        consignment,
        sneakers_condition,
        per_page,
        sort_by
      })
      this.props.onResetFilterCallback();
    }
  }

  render(){
    return(
      <Collapse isOpen={this.props.isOpen}>
        <Card body className="filter-container__card-body">
          <SearchForm
            keyword = {this.state.keyword}
            onInputChange = {this._handleOnChangeInput}
            onSubmit = {this._handleSearchButtonClick}
          />
          <FilterForm
            status={this.state.status}
            pre_verified={this.state.pre_verified}
            pre_order={this.state.pre_order}
            consignment={this.state.consignment}
            sneakers_condition={this.state.sneakers_condition}
            onInputChange={this._handleOnChangeInput}
            onCheckedChange={this._handleOnChangeInput}
            onStatusesInputChange={this._handleStatusesChangeInput}
            onConditionsInputChange={this._handleConditionsChangeInput}
            isCheckedStatus={this._isCheckedStatus}
            isCheckedCondition={this._isCheckedCondition}
            onSubmit={this._handleApplyFilterButtonClick}
          />
          <hr/>
          <SortForm
            per_page={this.state.per_page}
            sort_by={this.state.sort_by}
            onInputChange={this._handleOnChangeInputAndApply}
          />
        </Card>
      </Collapse>
    )
  }
}


export default FilterPanel;

FilterPanel.propTypes= {
  keyword: propTypes.string,
  status: propTypes.string,
  pre_order: propTypes.string,
  per_page: propTypes.oneOfType([propTypes.number, propTypes.string]),
  sort_by: propTypes.string,
  isOpen: propTypes.bool.isRequired,
  pre_verified: propTypes.string,
  onResetFilterCallback: propTypes.func,
  isReset: propTypes.bool
}

FilterPanel.defaultProps= {
  keyword: '',
  per_page: 15,
  sort_by: '',
  status: '',
  pre_order: '',
  pre_verified: '',
  sneakers_condition: ''
}