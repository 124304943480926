import React from 'react';
import propTypes from 'prop-types';
import {
  FieldRow,
  FieldColumn,
  FieldLabel,
  FieldValue
} from '../../../styled/Fields';
import { _getGenderString } from '../../../utils/form';

const SneakerSize = (props)=>{
  return(
    <FieldRow>
      <FieldColumn className="col-xs-12 col-sm-6 col-md-3">
        <FieldLabel>US</FieldLabel>
        <FieldValue>
          <span>{ props.US }</span>
        </FieldValue>
      </FieldColumn>
      <FieldColumn className="col-xs-12 col-sm-6 col-md-3">
        <FieldLabel>UK</FieldLabel>
        <FieldValue>
          <span>{ props.UK }</span>
        </FieldValue>
      </FieldColumn>
      <FieldColumn className="col-xs-12 col-sm-6 col-md-3">
        <FieldLabel>EUR</FieldLabel>
        <FieldValue>
          <span>{ props.EUR }</span>
        </FieldValue>
      </FieldColumn>
      <FieldColumn className="col-xs-12 col-sm-6 col-md-3">
        <FieldLabel>CM</FieldLabel>
        <FieldValue>
          <span>{ props.cm }</span>
        </FieldValue>
      </FieldColumn>
      <FieldColumn className="col-xs-12 col-sm-6 col-md-3">
        <FieldLabel>Size For</FieldLabel>
        <FieldValue>
          <span>{ _getGenderString(props.sex) }</span>
        </FieldValue>
      </FieldColumn>
    </FieldRow>
  )
}
SneakerSize.propTypes={
  UK: propTypes.string,
  US: propTypes.string,
  EUR: propTypes.string,
  cm: propTypes.string,
  sex: propTypes.oneOf(['M','F','U','GS'])
}
SneakerSize.defaultProps={
  UK: '0',
  US: '0',
  EUR: '0',
  cm: '0',
  sex: 'M'
}
export default SneakerSize;