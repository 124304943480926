import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import propTypes from 'prop-types';
import {
  Link
} from 'react-router-dom';
import {
  ButtonGroup,
  Button
} from 'reactstrap';
import ReactLoading from 'react-loading';
import { getStatusSales } from '../../../../constants/kickavenue';
import { Th } from '../../../../components/Tbl';
import { TextRupiah } from '../../../../components/Text';
import DateToHuman from '../../../../components/DateToHuman';
import { textValue, checkChange } from '../../../../utils/form';
import { updateSale, doMarkedSale, getSaleNotes, setReadSaleNotes, getDetailSale2, getTrackingShipmentDetail } from '../../../../actions/saleAction';
import SmallInline from '../../../../styled/Typograph/Small/SmallInline';
import OrderSummariesColumn from '../../RowItem/OrderSummariesColumn';
import ButtonChangeSeller from '../../RowItem/ButtonChangeSeller';
import { getPaymentType, getPaymentVia } from '../../../../constants/kickavenue';
import { ModalMarkedSale, ModalTrackingShipment } from '../../../../components/Modals';
import ButtonStarred from '../../RowItem/ButtonStarred';
import moment from 'moment-timezone';
import { FontAwesomeIcon } from '../../../../components/Icons';

class RowItem extends React.Component{
  constructor(props){
    super(props);
    this.state={
      autoDisburse: props.autoDisburse,
      _autoDisburseEdit: props.autoDisburse,
      updatedAt: props.updatedAt,
      marked: props.marked,
      _markedEdit: props.marked,
      _isModalMarked: false,
      markedAt: props.markedAt || '',
      markedBy: props.markedBy || '',
      closeMarkedAt: props.closeMarkedAt || '',
      closeMarkedBy: props.closeMarkedBy || '',
      notes: props.notes || [],
      notesPage: 1,
      noteNextPage: null,
      unreadNoteCount: props.unreadNoteCount || 0,
      isConfirmation: false,
      purchasePrice: props.purchasePrice,
      _isModalTracking: false,
      shippingUpdate: null
    }
    this._handleCheckedChange = this._handleCheckedChange.bind(this);
    this._handleMarkedChange = this._handleMarkedChange.bind(this);
    this._handleTrackingChange = this._handleTrackingChange.bind(this);
    this._handleModalMarkedOnSubmit = this._handleModalMarkedOnSubmit.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if(!_.isEqual(prevProps.dataItem, this.props.dataItem)){
      this.setState({
        autoDisburse: this.props.autoDisburse,
        _autoDisburseEdit: this.props.autoDisburse,
        updatedAt: this.props.updatedAt,
        marked: this.props.marked,
        _markedEdit: this.props.marked,
        // markedNotes: this.props.markedNotes || '',
        markedAt: this.props.markedAt || '',
      })
    }
    if(prevProps.sale.isSubmit !== this.props.sale.isSubmit && prevProps.sale.isSubmit){
      if(prevProps.sale.error===null){
        const { id, autodisburse, updated_at, marked, open_marked_at, open_marked_by, sale_notes, unread_notes_count, close_marked_at, close_marked_by, purchase_price } = this.props.sale.detail;
        if(prevProps.id===id){
          this.setState({
            autoDisburse: autodisburse,
            _autoDisburseEdit: autodisburse,
            marked: marked,
            _markedEdit: marked,
            // markedNotes: marked_notes || '',
            markedAt: open_marked_at || '',
            markedBy: open_marked_by || '',
            closeMarkedAt: close_marked_at || '',
            closeMarkedBy: close_marked_by || '',
            // notes: sale_notes,
            // unreadNoteCount: unread_notes_count,
            updatedAt: updated_at,
            purchasePrice: purchase_price
          })
        }
      }
      else{
        const { id } = this.props.sale.error;
        if(id && this.props.id === id){
          this.setState({
            _autoDisburseEdit: this.state.autoDisburse,
            _markedEdit: this.state.marked,
          })
        }
      }
    }

    if(prevProps.sale.notes.isFetch && !this.props.sale.notes.isFetch){
      const { list: { data, next_page_url, error }, saleId } = this.props.sale.notes;
      if(this.props.id === saleId){
        if(error) {
          alert('Error while fetching notes');
        } else{
          const { loginId } = this.props;
          const ids = data.filter(item => !item.read && item.user_id !== loginId).map(({ id }) => id);
          if(ids.length){
            this.props.setReadSaleNotes(this.props.id, { ids })
          }
          this.setState({
            notes: this.state.notesPage === 1? [...data]: [...data, ...this.state.notes],
            noteNextPage: next_page_url? this.state.notesPage + 1: null
          })
        }
      }
    }

    if(prevProps.sale.saleDetail.isFetch && !this.props.sale.saleDetail.isFetch){
      const { error, data } = this.props.sale.saleDetail;
      if(!error && data.id === this.props.id){
        const { unread_notes_count } = data;
        this.setState({
          unreadNoteCount: unread_notes_count
        });
      }
    }

    if(prevProps.sale.notes.isSubmit && !this.props.sale.notes.isSubmit){
      const { readNotes: { data }, saleId, error } = this.props.sale.notes;
      if(this.props.id === saleId && !error){
        if(data.total > 0) {
          this.props.getDetailSale2(this.props.id);
        }
      }
    }
    if(!prevState._isModalMarked && this.state._isModalMarked){
      this.setState({
        notesPage: 1,
        noteNextPage: null
      }, () => {
        this.props.getSaleNotes(this.props.id, {
          params: { per_page: 3, page: this.state.notesPage }
        });
      })
    }
    if (!prevState._isModalTracking && this.state._isModalTracking) {
      this.props.getTracking(this.props.id)
    }
    if (prevProps.sale.shippingUpdate.isFetch && !this.props.sale.shippingUpdate.isFetch)
    {
      const { data, error } = this.props.sale.shippingUpdate;
      if (!error) {
        this.setState({
          shippingUpdate: data
        })
      }
    }
  }

  shouldComponentUpdate(nextProps){
    if(nextProps.sale.isSubmit){
      return false;
    }
    if(!nextProps.sale.isSubmit&&nextProps.sale.isSubmit!==this.props.sale.isSubmit){
      if(nextProps.sale.error){
        const { error: { id } } = nextProps.sale;
        if(id && id != this.props.id){
          return false;
        }
      }
      else{
        if(nextProps.sale.detail.id && nextProps.sale.detail.id != this.props.id){
          return false;
        }
      }
    }
    // else{
    //   if(nextProps.isSelected!=this.props.isSelected || !_.isEqual(nextProps, this.props)){
    //     //commented because defaultnya true
    //     return true;
    //   }
    // }
    return (nextProps.selectedId === null && nextProps.selectedId === this.props.selectedId)
    || (nextProps.selectedId === null && this.props.selectedId === this.props.id)
    || nextProps.selectedId === this.props.id;
  }

  _handleTrackingChange(){
    this.setState({
      _isModalTracking: !this.state._isModalTracking,
    });
  }

  _handleCheckedChange(e) {
    this.setState({
      ...checkChange(e, this.state)
    }, ()=>{
      const payload = {
      autodisburse : this.state._autoDisburseEdit
      }
      this.props.update(this.props.id, payload)
    });
  }

  _handleModalMarkedOnSubmit({ marked_notes, marked = null }){
    if(marked === false){
      const payload = { marked };
      if(marked_notes){
        payload['marked_notes'] = marked_notes;
      }
      this.setState({
        _markedEdit : marked,
        _isModalMarked: false,
        // isConfirmation: false
      });
      this.props.doMarkedSale(this.props.id, payload)
      return;
    }
    this.setState({
      _markedEdit : this.state.closeMarkedAt? (marked !== null? marked: this.state._markedEdit): true,
      _isModalMarked: false,
      // isConfirmation: false,
      markedNotes: marked_notes
    }, () => {
      const payload = {
        // status : param.status,
        marked : this.state._markedEdit,
        marked_notes
      }
      this.props.doMarkedSale(this.props.id, payload)
    })
  }

  _handleMarkedChange(){
    this.setState({
      isConfirmation: false,
      _isModalMarked: !this.state._isModalMarked,
      _markedEdit : this.state.marked //cancel toggle
    });
  }

  _openPurchasePriceModal = e => {
    e.preventDefault();
    this.props.openPurchasePriceModal({
      id: this.props.id,
      invoiceNumber: this.props.invoiceNumber,
      purchasePrice: this.state.purchasePrice
    })
  }

  render(){
    const via = getPaymentVia(this.props.paymentVia);
    const forceAt = this.props.forceCancelledAt? moment(this.props.forceCancelledAt).format('DD/MM/YYYY'):this.props.forceCancelledAt
    const { voucherCode } = this.props;
    return(
      <tr>
        <ModalMarkedSale
          isOpen={this.state._isModalMarked}
          isConfirmation={this.state.isConfirmation}
          toggle={this._handleMarkedChange}
          invoiceNumber={this.props.invoiceNumber}
          marked={this.state.marked}
          markedAt={this.state.markedAt}
          markedBy={this.state.markedBy}
          closeMarkedAt={this.state.closeMarkedAt}
          markedNotes={this.state.markedNotes}
          notes={this.state.notes}
          unreadNoteCount={this.state.unreadNoteCount}
          hasNextPage={this.state.noteNextPage}
          loginId={this.props.loginId}
          onSubmit={this._handleModalMarkedOnSubmit}
          onLoadMoreNotes={() => {
            const { notesPage, noteNextPage } = this.state;
            if(!noteNextPage) return;
            this.setState({
              noteNextPage: null,
              notesPage: notesPage + 1
            }, () => {
              this.props.getSaleNotes(this.props.id, {
                params: { per_page: 3, page: this.state.notesPage }
              });
            })
          }}
        />
        <ModalTrackingShipment
          shippingUpdate={this.state.shippingUpdate}
          isLoading={this.props.sale.shippingUpdate.isFetch}
          isOpen={this.state._isModalTracking}
          toggle={this._handleTrackingChange}
        />
        <Th className="v-center" scope="row"> {this.props.number} </Th>
        <td className="v-center">
          <OrderSummariesColumn
            id={this.props.id}
            quantity={this.props.quantity}
            invoiceNumber={this.props.invoiceNumber}
            orderDate={this.props.orderDate}
            buyerEmail={this.props.buyerEmail}
            displayName={this.props.displayName}
            size={this.props.size}
            SKU={this.props.SKU}
            colour={this.props.colour}
            preOrder={this.props.preOrder}
            preVerified={this.props.preVerified}
            consignmentId={this.props.consignmentId}
            category={this.props.category}
            sneakersCondition={this.props.sneakersCondition}
            unreadNoteCount={this.state.unreadNoteCount}
            legitCheckLogs={this.props.legitCheckLogs}
            // marked={this.state._markedEdit}
            // onMarkToggle={value => {
            //   if(!value){
            //     this.setState({
            //       _markedEdit: value
            //     })
            //     this.props.doMarkedSale(this.props.id, { marked: value })

            //   }else {
            //     this.setState({
            //       _isModalMarked: true,
            //       _markedEdit: value
            //     })
            //   }
            // }}
          />
          <div>
            <p className="mb-0" style={{fontSize: '.75rem'}}>
              Purchase price: Rp. { this.state.purchasePrice? <TextRupiah tag="span" prefix="" value={this.state.purchasePrice}/>: '-' }
              <a
                href="#"
                className="ml-2"
                style={{textDecoration: 'underline', color: '#4a4a4a', fontSize: '.75rem'}}
                onClick={this._openPurchasePriceModal}
              >
                <i className="fa fa-pencil"></i> edit
              </a>
            </p>
          </div>
        </td>
        <td className="v-center">{ this.props.rack ? this.props.rack : '-' }</td>
        <td className="v-center">
          <div>
            <TextRupiah
              prefix="Rp. "
              value={this.props.totalAmount}
            />
          </div>
          {
            voucherCode && (
              <div className="text-uppercase voucher-wrapper" style={{fontSize: '.5rem'}}>
                <FontAwesomeIcon iconType="ticket"/> {voucherCode}
              </div>
            )
          }
          <div className="issued-wrapper" style={{minWidth: 95}}>
            <a
              href="#"
              className="mr-1"
              style={{textDecoration: 'underline', color: '#4a4a4a', fontSize: '.75rem'}}
              onClick={e => {
                e.preventDefault();
                this.setState({ _isModalMarked: true })
              }}
            >
              <i className="fa fa-pencil"></i> {this.state.markedAt? 'view': 'add'} notes
              {/* <i className="fa fa-pencil"></i> add notes */}
            </a>
            <ButtonStarred
              title={this.state._markedEdit? "Remove mark!": "Mark!"}
              value={this.state._markedEdit}
              onClick={() => {
                const value = !this.state._markedEdit;
                this.setState({
                  _isModalMarked: true,
                  isConfirmation: !value,
                  _markedEdit: value
                })
              }}
            />
          </div>
          <ButtonChangeSeller
            id={this.props.id}
            isDisplayed={!this.props.hijacked}
            status={this.props.status}
          />
          <div>
            <SmallInline>Auto Disburse</SmallInline><br/>
            <label className="switch switch-text switch-success-outline-alt">
              <input type="checkbox"
                className="switch-input"
                name="_autoDisburseEdit"
                id="formSaleAutoDisburse"
                value="true"
                checked={textValue('_autoDisburseEdit', this.state)}
                onChange={this._handleCheckedChange}
              />
              <span className="switch-label" data-on="YES" data-off="NO"></span>
              <span className="switch-handle"></span>
            </label>
          </div>
        </td>
        <td className="v-center">
          <a 
            href="#"
            className="mr-1"
            style={{textDecoration: 'underline', color: '#4a4a4a', fontSize: '.75rem'}}
            onClick={e => {
              e.preventDefault()
              this.setState({
                _isModalTracking: true,
                shippingUpdate: null
              })
            }}
          >Tracking Shipment</a><br />
          <div>
            {
              this.props.bank && this.props.paymentMethod === 'virtual_account'?
              <span style={{fontSize: '.75rem'}}>{this.props.bank+ '-'+ getPaymentType(this.props.paymentMethod)}</span>
              :
              getPaymentType(this.props.paymentMethod)
            }
          </div>
          { via !== null? <div><span style={{fontSize: '.75rem'}}>via <i>{via}</i></span></div>: null}
          {
            this.props.paymentVia !== null && this.props.paymentVia === 'vt' && this.props.paymentMethod=== "credit_card" ? 
            <button className="btn btn-sm btn-secondary" onClick={this.props.onOpenCreditCard} target="_blank" title="Show More Info"><i className="fa fa-info-circle"></i> More Info</button>
            :
            null
          }
          {
            <div><span style={{fontSize: '.75rem'}}>{this.props.dataItem.offer.payment_id?this.props.dataItem.offer.payment_id:this.props.dataItem.offer.id}</span></div>
          }
        </td>
        <td className="v-center">
          { getStatusSales(this.props.status) }
          {this.props.forceCancelledAt?
            <p className="text-danger mb-0" style={{fontSize: '.65rem'}}>
              forced: {forceAt}
            </p>
          :null}
        </td>
        <td className="v-center">
          <DateToHuman
            id={`tooltip_${this.props.number}`}
            value={this.props.updatedAt}
          />
        </td>
        <td className="v-center">
          {
            this.props.isSelected?
            <ReactLoading type="bars" color="green" height={20} width={30}/>
            :
            <ButtonGroup>
              <Link to={`/sales/${this.props.id}`} className="btn btn-sm btn-secondary" title="Detail"><i className="fa fa-external-link"></i></Link>
              {
                this.props.canDisbursed?
                <Button
                  color="success"
                  size="sm"
                  title="Disbursh Now!"
                  onClick={this.props.handleOpenModalDisburse}
                  >
                    <i className="fa fa-cc"></i>
                </Button>
                :null
              }
            </ButtonGroup>
          }
          <div>
            {/* <SmallInline>Marked Sale</SmallInline><br />
            <label className="switch switch-text switch-success-outline-alt">
              <input type="checkbox"
                className="switch-input"
                name="_markedEdit"
                id="formSaleMarked"
                value="true"
                checked={textValue('_markedEdit', this.state)}
                onChange={this._handleMarkedChange}
              />
              <span className="switch-label" data-on="YES" data-off="NO"></span>
              <span className="switch-handle"></span>
            </label> */}
          </div>
        </td>
      </tr>
    )
  }
}

const mapStateToProps = ({ sale }) => ({
  sale
})
const mapDispatchToProps = (dispatch) => ({
  getDetailSale2: (id) => dispatch(getDetailSale2(id)),
  getSaleNotes: (id, query) => dispatch(getSaleNotes(id, query)),
  setReadSaleNotes: (id, body) => dispatch(setReadSaleNotes(id, body)),
  update: (id, payload) => dispatch(updateSale(id, payload)),
  doMarkedSale: (id, payload) => dispatch(doMarkedSale(id, payload)),
  getTracking: id => dispatch(getTrackingShipmentDetail(id))
})

export default connect (mapStateToProps, mapDispatchToProps) (RowItem);

RowItem.defaultProps = {
  isSelected: false,
  canDisbursed: false,
  hijacked: false
}

RowItem.propTypes = {
  id: propTypes.number.isRequired,
  dataItem: propTypes.object.isRequired,
  number: propTypes.number.isRequired,
  buyerEmail: propTypes.string,
  invoiceNumber: propTypes.string,
  autoDisburse: propTypes.number,
  orderDate: propTypes.string,
  updatedAt: propTypes.string,
  totalAmount: propTypes.oneOfType([propTypes.number, propTypes.string]),
  displayName: propTypes.string,
  SKU: propTypes.string,
  colour: propTypes.string,
  preOrder: propTypes.oneOfType([propTypes.number, propTypes.bool]),
  preVerified: propTypes.oneOfType([propTypes.bool, propTypes.bool]),
  size: propTypes.string,
  status: propTypes.string,
  hijacked: propTypes.bool,
  canDisbursed: propTypes.oneOfType([propTypes.number, propTypes.bool]),
  isSelected: propTypes.oneOfType([propTypes.number, propTypes.bool]),
  handleOpenModalDisburse: propTypes.func,
  consignmentId: propTypes.string,
  onOpenCreditCard: propTypes.func.isRequired,
  marked: propTypes.bool,
  markedNotes: propTypes.string,
  markedAt: propTypes.string,
  rack: propTypes.string,
}