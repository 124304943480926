import React from "react";
import { useSelector } from "react-redux";
import {
  Col,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  Button,
} from "reactstrap";
import { bankPromoIsFetchSelector } from "../../../store/selectors/bankPromoSelector";

const SearchForm = ({ onSubmitSearch, keyword, onChangeKeyword }) => {
  const bankPromoIsFetch = useSelector(bankPromoIsFetchSelector)
  const disableSearch = bankPromoIsFetch;
  return (
    <Form onSubmit={onSubmitSearch}>
      <FormGroup>
        <Col xs={12} sm={12} md={6} style={{ padding: 0 }}>
          <InputGroup>
            <Input
              type="text"
              placeholder="Search title, voucher code..."
              value={keyword}
              onChange={(e) => onChangeKeyword(e.target.value)}
            />
            <InputGroupAddon addonType="append">
              <Button
                color="primary"
                disabled={disableSearch}
                style={{ pointerEvents: disableSearch ? "none" : "auto" }}
              >
                Search
              </Button>
            </InputGroupAddon>
          </InputGroup>
        </Col>
      </FormGroup>
    </Form>
  );
};

export default SearchForm;
