import {
  GET_LIST_SELLER_VERIFICATION,
  FETCHING_SELLER_VERIFICATION,
  SELLER_VERIFICATION_ERROR,
  GET_DETAIL_SELLER_VERIFICATION,
  UPDATE_STATUS_SELLER_VERIFICATION,
  UPDATE_STATUS_SELLER_VERIFICATION_ERROR
} from '../constants';

const initialState={
  list:[],
  updateStatus:{
    status_code: null
	},
	detail:{},
	isFetch:false,
	isSubmit:false,
	isDelete:false,
	isImageProcessLoading: false,
	error:null
}

const onFetchingUserSell = (lastState,payload) => {
  if(payload.type==='fetch')
      return { ...lastState, isFetch:payload.status }
  else if(payload.type==='submit')
      return { ...lastState, isSubmit:payload.status }

  return { ...lastState, isFetch:false,isSubmit:false }
}

const sellerVerificationReducer=(state=initialState, action)=>
{
  switch(action.type) 
  {
    case GET_LIST_SELLER_VERIFICATION: {
			const {message,status_code,data}=action.payload;
			return { ...state, list:{...data,message:message,status_code:status_code},error:null }
		}
    case FETCHING_SELLER_VERIFICATION:
      return onFetchingUserSell(state, action.payload)
    case SELLER_VERIFICATION_ERROR:
      return { ...state, ...action.payload }
    case GET_DETAIL_SELLER_VERIFICATION: {
      const {message,status_code,data}=action.payload;
      return { ...state,detail:{ message, status_code, ...data },error:null }
    }
    case UPDATE_STATUS_SELLER_VERIFICATION: {
			const {message,status_code,data}=action.payload;
			return { ...state, updateStatus:{ message, status_code, id: data.id, status: data.status, updated_at: data.updated_at, approved_at: data.approved_at }}
    }
    case UPDATE_STATUS_SELLER_VERIFICATION_ERROR:{
      const { id, error } = action.payload;
      return { ...state, updateStatus:{ ...state.updateStatus, error: { id, ...error } }}
    }
    default:return state;
  }
}

export default sellerVerificationReducer;