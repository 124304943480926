import {
  GET_LIST_STOCK,
  GET_LIST_STOCK_ERROR,
  GET_LIST_STOCK_FETCHING,
  GET_LIST_STOCK_SIZES,
  GET_LIST_STOCK_SIZES_ERROR,
  GET_LIST_STOCK_SIZES_FETCHING,
  GET_LIST_STOCK_USER_SELL,
  GET_LIST_STOCK_USER_SELL_ERROR,
  GET_LIST_STOCK_USER_SELL_FETCHING,
  STOCK_KEYWORD_PARAMS,
  REPORT_STOCK_ERROR,
  REPORT_CLEAR,
  REPORT_STOCK,
  SUBMIT_STOCK,
  SCRAPE_PRODUCT_STOCK,
  SCRAPE_PRODUCT_STOCK_SUBMIT,
  SCRAPE_PRODUCT_STOCK_ERROR
} from '../constants';

const initialState = {
  keyword: '',
  keywordFor: 'variants',
  stockList:{
    data:[],
    isFetch: false,
    error: null
  },
  stockSizeList:{
    data:[],
    isFetch: false,
    error: null
  },
  stockUserSellList:{
    data:[],
    isFetch: false,
    error: null
  },
  reportStock: {
    isSubmit:false,
		status_code: 0,
		message: '',
		error: null
  },
  rack: {
    data: [],
    isFetch:false,
    status_code:0,
    error: null
  },
  productScrape: {
    isSubmit: false,
    status_code: 0,
    error: null
  }
}

const onReportStock = (lastState,payload) => {
	const {message, status_code}=payload;
	return { ...lastState, reportStock:{ message, status_code, error: null } };
}

const onReportStockError = (lastState,error) => {
	const {message, status_code}=error.error;
	return { ...lastState, reportStock:{ message, status_code, ...error } };
}


const reducer = (state=initialState, { type, payload })=> {
  switch(type){
    case STOCK_KEYWORD_PARAMS: return { ...state, ...payload };
    case GET_LIST_STOCK: return { ...state, stockList: { ...state.stockList, ...payload.data, status_code: payload.status_code, message: payload.message, error: null }}
    case GET_LIST_STOCK_FETCHING: return { ...state, stockList: { ...state.stockList, isFetch: payload }}
    case GET_LIST_STOCK_ERROR: return { ...state, stockList: { ...state.stockList, error: { ...payload } }}

    case GET_LIST_STOCK_SIZES: return { ...state, stockSizeList: { ...state.stockSizeList, ...payload.data, status_code: payload.status_code, message: payload.message, error: null }}
    case GET_LIST_STOCK_SIZES_FETCHING: return { ...state, stockSizeList: { ...state.stockSizeList, isFetch: payload }}
    case GET_LIST_STOCK_SIZES_ERROR: return { ...state, stockSizeList: { ...state.stockSizeList, error: { ...payload } }}

    case GET_LIST_STOCK_USER_SELL: return { ...state, stockUserSellList: { ...state.stockUserSellList, ...payload.data, status_code: payload.status_code, message: payload.message, error: null }}
    case GET_LIST_STOCK_USER_SELL_FETCHING: return { ...state, stockUserSellList: { ...state.stockUserSellList, isFetch: payload }}
    case GET_LIST_STOCK_USER_SELL_ERROR: return { ...state, stockUserSellList: { ...state.stockUserSellList, error: { ...payload } }}
    case REPORT_CLEAR : return {
			...state,reportStock:{
				...state.reportStock,status_code:0,message:'',error: null
			}
    }
    case SUBMIT_STOCK: return { ...state, reportStock: { ...state.reportStock, isSubmit: payload }}
		case REPORT_STOCK : return onReportStock(state,payload);
    case REPORT_STOCK_ERROR: return onReportStockError(state,payload);

    case SCRAPE_PRODUCT_STOCK_SUBMIT: return { ...state, productScrape: { ...state.productScrape, isSubmit: payload }}
		case SCRAPE_PRODUCT_STOCK : return { ...state, productScrape: { ...state.productScrape, ...payload, error: null }};
    case SCRAPE_PRODUCT_STOCK_ERROR: return { ...state, productScrape: { ...state.productScrape, error: { ...payload.error } }};
    default: return state;
  }
}

export default reducer;