import React, { Component } from "react";
import { connect } from "react-redux";
import uuidv4 from "uuid/v4";
import {
  Container,
  Row,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Button
} from "reactstrap";
import classNames from "classnames";
import qs from "query-string";
import {
  saveProductVariant,
  addImage,
} from "../../actions/productVariantAction";
// import { getOptionList as getProductOptionList } from '../../actions/productAction';
import { clearImageUpload, upload, uploadImage } from "../../actions/imageUploadAction";

import Form from "./productVariantCreate/Form";
import UploadImages from "./productVariantCreate/UploadImages";
import privateView from "../../components/hocs/privateView";
import { ImagesCompressor, createUploadFileForm } from "../../utils/imageHelper";
import { isNumeric } from "../../utils/regex";
import { AWS_BUCKET, AWS_BUCKET_REGION } from "../../config/storageBucket";
import Analytics from "../../services/Analytics";

const AWS_ENDPOINT = "amazonaws.com";

const getStorageName = imgUrl => {
  if (new RegExp(AWS_BUCKET).test(imgUrl)) return AWS_BUCKET;
  else if (/kickavenue-assets/.test(imgUrl)) return "kickavenue-assets";
  return "external-link";
};

const getUrlKey = imgUrl => {
  const regexStorage = new RegExp(AWS_BUCKET);
  if (regexStorage.test(imgUrl)) {
    const firstPattern = new RegExp(
      `s3.${AWS_BUCKET_REGION}.${AWS_ENDPOINT}/${AWS_BUCKET}/`
    );
    const secondPattern = new RegExp(
      `${AWS_BUCKET}.s3.${AWS_BUCKET_REGION}.${AWS_ENDPOINT}/`
    );
    const thirdPattern = new RegExp(`${AWS_BUCKET}.s3.${AWS_ENDPOINT}/`);
    let parsedUrl = imgUrl.trim().replace(/^(https|http):\/\//, "");
    // console.log('Parse URL', parsedUrl);
    if (firstPattern.test(parsedUrl)) {
      return parsedUrl.replace(firstPattern, "");
    } else if (secondPattern.test(parsedUrl)) {
      return parsedUrl.replace(secondPattern, "");
    } else if (thirdPattern.test(parsedUrl)) {
      return parsedUrl.replace(thirdPattern, "");
    }
  }
  return "";
};

const styles = {
  paddingNol: { padding: 0 }
};

const ModalProgress = props => (
  <Modal size="sm" className="modal-confirmation" isOpen={props.isOpen}>
    <ModalHeader className="modal-header--center">
      Submitting Progress.
    </ModalHeader>
    <ModalBody>
      {props.renderModalBody ? props.renderModalBody() : props.modalBody}
    </ModalBody>
    <ModalFooter>
      <Button
        className={classNames({ "d-none": props.isPrimaryButtonHide })}
        color="primary"
        onClick={props.onPrimaryClick}
      >
        Gotchas
      </Button>
    </ModalFooter>
  </Modal>
);

class ProductVariantCreate extends Component {
  constructor(props) {
    super(props);
    const { sku, retailPrice } = Object.assign(
      { sku: null, retail_price: 0 },
      qs.parse(props.location.search)
    );
    this.state = {
      _productOptions: [],
      _params: {
        SKU: sku ? sku.replace("-", " ").toUpperCase() : "",
        retail_price: isNumeric(retailPrice) ? retailPrice : 0
      },
      _productVariantId: null,
      _images: [],
      _imageUploadList: [],
      _isModalProgress: false,
      _taskAvailable: 0,
      _taskDone: 0,
    };
    this._handleDropzoneOnDrop = this._handleDropzoneOnDrop.bind(this);
    this._handleSubmitVariant = this._handleSubmitVariant.bind(this);
    this._handleRemoveImage = this._handleRemoveImage.bind(this);
  }
  componentDidMount() {
    // this.props.getProductList();
    const { email } = this.props.injectedProps;
    Analytics.recordPageView('product_variant_detail', email, {
      url: window.location.href,
      location: this.props.location,
      match: this.props.match
    });
  }

  componentWillUnmount() {
    this.props.clearImageUpload();
  }

  componentDidUpdate = async lastProps => {
    const { detail, error } = this.props.productVariant;
    if (
      lastProps.productVariant.isSubmit &&
      lastProps.productVariant.isSubmit != this.props.productVariant.isSubmit
    ) {
      if (error === null && detail.status_code === 200) {
        if (!this.state._imageUploadList.length && !detail.editors_choice) {          
          this.props.history.replace(`/product_variants/${detail.id}`);
        } else {
          const { _imageUploadList } = this.state;
          if(_imageUploadList.length) {
            let { identifier, fileInput } = _imageUploadList[0];
            //compress file
            try {
              fileInput = await ImagesCompressor(fileInput);
            } catch (e) {}

            const _images = this.state._images.map(item => {
              if (item.identifier === identifier)
                return { ...item, isUploading: true };
              return item;
            });
            this.setState(
              {
                _images,
                _productVariantId: detail.id,
                _taskDone: this.state._taskDone + 1
              },
              () => {
                const tempPayload = createUploadFileForm(fileInput, false, {name: "products/"+this.state._productVariantId, bucket_type: "assets"});
                this.props.uploadImage(tempPayload, _imageUploadList[0]);
              }
            );
          }
        }
      } else {
        this.setState({
          _isModalProgress: false,
          _taskAvailable: 0,
          _taskDone: false
        });
      }
    }
    if (
      lastProps.product.isFetch &&
      lastProps.product.isFetch != this.props.product.isFetch
    ) {
      const { options, error } = this.props.product;
      if (error === null) {
        this.setState({
          _productOptions: options.map(item => item)
        });
      }
    }
    if(lastProps.imageUpload.isUpload && lastProps.imageUpload.isUpload !== this.props.imageUpload.isUpload){
      const { success, error } = this.props.imageUpload;
      if(!error){
        const { data } = success.data;
        const { _images } = this.state;
        let myIdentifier = null;
          const filtered = _images.filter(
            img =>
              img.identifier === success.data.identifier &&
              !img.status &&
              success.data.url !== null
          );
          if (filtered.length) {
            myIdentifier = filtered[0].identifier;
            this.setState(
              {
                _images: _images.map(item => {
                  if (item.identifier == myIdentifier) {
                    return {
                      ...item,
                      storageName: getStorageName(success.data.url),
                      urlKey: getUrlKey(success.data.url),
                      url: success.data.url
                    };
                  }
                  return item;
                })
              },
              () => {
                this.props.saveImage(myIdentifier, {
                  product_variant_id: this.state._productVariantId,
                  URL: success.data.url
                });
              }
            );
          }
      }
    }
    if (
      lastProps.productVariant.isSubmittingImage &&
      !this.props.productVariant.isSubmittingImage
    ) {
      const _images = [...this.state._images];
      const { addedImages } = this.props.productVariant;
      let getIdentifier = null;
      let count = this.state._taskDone;
      Object.keys(addedImages).map(index => {
        const filtered = _images.filter(
          item => item.identifier === index && item.isUploading
        );
        if (filtered.length) {
          getIdentifier = index;
          filtered[0].id = addedImages[index].id;
          filtered[0].updatedAt = addedImages[index].updated_at;
          filtered[0].status = true;
          filtered[0].isUploading = false;
          count++;
        }
      });
      const nextImageUpload = this.state._imageUploadList.filter(
        item => item.identifier !== getIdentifier
      );
      if (nextImageUpload.length) {
        let { identifier, fileInput } = nextImageUpload[0];

        //compress file
        try {
          fileInput = await ImagesCompressor(fileInput);
        } catch (e) {}

        _images.map(item => {
          if (item.identifier === identifier) {
            item.isUploading = true;
          }
        });
        const tempPayload = createUploadFileForm(fileInput, false, {name: "products/"+this.state._productVariantId, bucket_type: "assets"});
        this.props.uploadImage(tempPayload, nextImageUpload[0]);
      }
      this.setState({
        _images,
        _imageUploadList: this.state._imageUploadList.filter(
          ({ identifier }) => identifier !== getIdentifier
        ),
        _taskDone: count
      });
    }
  };

  _handleSubmitVariant(payload) {
    if (
      this.state._images.filter(item => item.storageName === "in-local").length
    ) {
      const _imageUploadList = this.state._images
        .filter(item => item.storageName === "in-local")
        .map(({ identifier, fileInput }) => ({ identifier, fileInput }));
      this.setState(
        {
          _imageUploadList,
          _isModalProgress: true,
          _taskAvailable: 1 + _imageUploadList.length,
          _taskDone: 0
        },
        () =>
          this.props.saveProductVariant(payload)
      );
    } else this.props.saveProductVariant(payload);
  }

  _handleDropzoneOnDrop(files) {
    if (files.length) {
      const { _images } = this.state;
      const droppedImages = files.map(file => {
        const identifier = uuidv4();
        return {
          id: null,
          url: null,
          storageName: "in-local",
          status: false,
          isUploading: false,
          fileInput: file,
          updatedAt: null,
          identifier
        };
      });
      const clonedImages = [..._images, ...droppedImages];
      this.setState({ _images: clonedImages });
    }
  }

  _handleRemoveImage(e, imageIdentifier) {
    e.preventDefault();
    const { _images } = this.state;
    this.setState({
      _images: _images.filter(
        ({ identifier }) => identifier !== imageIdentifier
      )
    });
  }

  render() {
    return (
      <Container style={styles.paddingNol}>
        <ModalProgress
          isOpen={this.state._isModalProgress}
          renderModalBody={() => (
            <p className="text-center">
              {this.state._taskAvailable > this.state._taskDone
                ? "Please waiting..."
                : this.state._taskAvailable > 0
                ? "Submit successfully..."
                : "Not available..."}{" "}
              ({this.state._taskDone}/{this.state._taskAvailable})
            </p>
          )}
          isPrimaryButtonHide={this.state._taskAvailable > this.state._taskDone}
          onPrimaryClick={() =>
            this.props.history.replace(
              `/product_variants/${this.state._productVariantId}`
            )
          }
        />
        <Row>
          <Col xs={12} md={6}>
            <Form
              initialValueForm={this.state._params}
              productOptions={this.state._productOptions}
              isSubmit={this.props.productVariant.isSubmit}
              statusCode={this.props.productVariant.detail.status_code}
              variantId={this.props.productVariant.detail.id}
              onSubmit={this._handleSubmitVariant}
              errorData={this.props.productVariant.error}
            />
          </Col>
          <Col xs={12} md={6}>
            <UploadImages
              onDrop={this._handleDropzoneOnDrop}
              images={this.state._images}
              onRemoveImage={this._handleRemoveImage}
            />
          </Col>
        </Row>
        {/* <ProductVariantForm
          title="Create New Product Variant"
          {...this.props}
        /> */}
      </Container>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  upload: (file, folder) => dispatch(upload(file, folder)),
  saveProductVariant: payload => dispatch(saveProductVariant(payload)),
  saveImage: (identifier, payload) => dispatch(addImage(identifier, payload)),
  clearImageUpload: () => dispatch(clearImageUpload()),
  uploadImage: (payload, rawImages) => dispatch(uploadImage(payload, rawImages)),
});

const mapStateToProps = ({
  auth: { isFetch, roles, isLogged, id, email },
  productVariant,
  product,
  imageUpload
}) => ({
  guardData: { isFetch, roles, isLogged, id, email },
  productVariant,
  product,
  imageUpload
});

const enhance = connect(mapStateToProps, mapDispatchToProps);
export default enhance(
  privateView(ProductVariantCreate, ["supervisor",'sale_supervisor',"inventory_admin"])
);
