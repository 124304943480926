import React from 'react';
import _ from 'lodash';
import propTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Card, CardHeader, CardBody, Input
} from 'reactstrap';

import { getUserBalanceLog } from '../../../actions/userAction';
import { Tbl, TblHead, TblBody } from '../../../components/Tbl';
import { TextRupiah } from '../../../components/Text';
import { generateNumber } from '../../../utils';
import RowItem from '../UserForm/userBalanceLogListCard/RowItem';
import Paginations from '../../../components/Paginations';
import { DEFAULT_SORT_BY } from '../../../constants/settings';
import classnames from 'classnames';

const dataHeader = ['No.', 'Disbursement', 'Total Amount', 'Updated'];

const defaultParams = {
  sort_by : DEFAULT_SORT_BY,
  per_page : 3
}

class UserBalanceLogListCard extends React.Component{
  constructor(props){
    super(props);
    this.state={
      _params:{
        ...defaultParams,
        balance_source: "balance"
      },
      _filterOpen:false
    }
    this._handleGoto = this._handleGoto.bind(this);
    this._throttleGetList = _.debounce(this._getList.bind(this), 500)
  }

  componentDidMount(){
    this._getList(this.state._params);
  }

  _getList(rawParams = {} ){
    let params = {};
    Object.keys(rawParams)
    .filter(key => rawParams[key]!='')
    .forEach(key=> params[key] = rawParams[key]);
    this.props.getList(this.props.userId, {params});
  }

  _handleGoto(page){
		const _params=this.state._params;
		_params.page=page;
		this.setState({_params},()=>this._getList(this.state._params));
	}

  _onRenderPagination(){
    const { list } = this.props.dataUserBalance;
    const hasData = (this.props.dataUserBalance.status_code===200 && list.data.length) || null;
    return hasData && (
      <Paginations 
        size="sm" 
        lastPage={list.last_page} 
        perPage={list.per_page}
        total={list.total}
        maxPage={8}
        path={list.path}
        currentPage={list.current_page} 
        prevPageUrl={list.prev_page_url} 
        nextPageUrl={list.next_page_url} 
        handleGotoCallback={this._handleGoto}
      />
    )
  }

  _renderRowItem(){
    if(this.props.dataUserBalance.isFetch === false && this.props.dataUserBalance.status_code===200){
      const { data, current_page, per_page } = this.props.dataUserBalance.list;
      return data.map((item, index)=>{
        return (
          <RowItem
            key={item.id}
            id={item.id}
            number={generateNumber(per_page, current_page, index)}
            disbursementNumber={item.disbursement_number}
            disbursementType={item.type}
            disbursementItemsCount={item.disbursement_items_count}
            disbursementItems={item.disbursement_items}
            userId={item.disburse_to&&item.disburse_to.id}
            userEmail={item.disburse_to&&item.disburse_to.email}
            adminId={item.disburse_by&&item.disburse_by.id}
            adminEmail={item.disburse_by&&item.disburse_by.email}
            totalAmount={item.total_amount}
            status={item.status}
            updatedAt={item.updated_at}
            createdAt={item.created_at}
          />
        )
      })
    }
    return null;
  }

  render(){
    const { _params: { balance_source } } = this.state;
    let balance = 0, currentBalance = 0;
    const { dataUser, dataUserBalance } = this.props;
    switch (balance_source){
      case "balance_with_fee": {
        balance = dataUser.balance_with_fee;
        currentBalance = dataUser.current_balance_with_fee;
        break;
      }
      case "locked_balance": {
        balance = dataUser.locked_balance;
        currentBalance = dataUser.current_locked_balance;
        break;
      }
      default: {
        balance = dataUser.balance;
        currentBalance = dataUser.current_balance;
        break;
      }
    }
    return(
      <Card className={classnames(this.props.cardClassname)}>
        { this.props.showHeader?
          <CardHeader>
            <i className="fa fa-align-justify mr-2"></i>
            {this.props.headerText? this.props.headerText: "Balance Log"}
          </CardHeader>
        :null }
        <CardBody className={classnames("pb-0", this.props.filterBodyClassname)}>
          <div className="d-flex mb-3">
            <Input
              type="text"
              placeholder="Search disbursement numb..."
              name="keyword"
              value={this.state._params.keyword || ""}
              onChange={async(e) => {
                await this.setState({_params: { ...this.state._params, [e.target.name]: e.target.value, page: 1 }})
                this._throttleGetList(this.state._params)
              }}
            />
          </div>
          <div style={{fontSize: '.75rem', justifyContent: 'flex-end'}} className="d-flex mb-1 align-items-center">
            <p className="mb-0 mr-3">Per Page</p>
            <Input
              style={{width: '80px'}}
              size="sm"
              type="select"
              name="per_page"
              value={this.state._params.per_page}
              onChange={async (e) => {
                await this.setState({_params: { ...this.state._params, [e.target.name]: e.target.value, page: 1 }})
                this._getList(this.state._params)
              }}
            >
              <option value="3">Default</option>
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="15">15</option>
            </Input>
          </div>
          <div className="d-flex mb-3 align-items-center">
            <Input
              style={{width: 150}}
              type="select"
              name="balance_source"
              value={this.state._params.balance_source}
              onChange={async (e) => {
                await this.setState({_params: { ...this.state._params, [e.target.name]: e.target.value, page: 1 }})
                this._getList(this.state._params)
              }}
            >
              <option value="">All</option>
              <option value="balance">Kick Credit</option>
              <option value="balance_with_fee">Seller Credit</option>
              <option value="locked_balance">Kick Point</option>
            </Input>
            <div style={{flex: 1}}>
              <TextRupiah bold={true} align="right" prefix="Rp. " block value={balance}/>
              {currentBalance - balance > 0?
                <div style={{fontSize: '.65rem', textAlign: 'right', display: 'block', fontStyle: 'italic'}}>
                  On Hold: <TextRupiah tag="span" prefix="Rp. " value={currentBalance - balance}/>
                </div>
              : null}
            </div>
          </div>
        </CardBody>
        <CardBody className={classnames(this.props.contentBodyClassname)}>
          <div className="table-responsive" style={{maxHeight: 500, overflow: 'auto'}}>
            <Tbl>
              <TblHead dataHeader={dataHeader}/>
              <TblBody
                isLoad={dataUserBalance.isFetch}
                hasRow={dataUserBalance.status_code===200 && dataUserBalance.list.data.length}
                columnCount={dataHeader.length}
              >
              { this._renderRowItem() }
              </TblBody>
            </Tbl>
          </div>
        </CardBody>
        <CardBody className={classnames("py-0", this.props.footerBodyClassname)}>
          { this._onRenderPagination() }
        </CardBody>
      </Card>
    )
  }
}

const mapStateToProps = ({user:{ detail }}) => ({
  dataUser: detail,
  dataUserBalance: detail.user_balance_log
})
const mapDispatchToProps = dispatch => ({
  getList: (userId, params) => dispatch(getUserBalanceLog(userId, params)),
})
export default connect(mapStateToProps,mapDispatchToProps)(UserBalanceLogListCard);
UserBalanceLogListCard.propTypes={
  userId: propTypes.oneOfType([propTypes.number, propTypes.string]).isRequired
}