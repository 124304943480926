import React, { Component } from "react";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { 
    Row,
    Col,
    Card,
    CardBody,
    CardText,
    Badge
} from 'reactstrap';

import {Tbl, TblHead, TblBody} from '../../components/Tbl';
import Paginations from '../../components/Paginations/Paginations';
import PaginationSummaries from '../../components/Paginations/PaginationSummaries';
import FilterPanel from './Filters';
import FilterContainer from '../../components/Container/FilterContainer/FilterContainer';
import { CardHeaderWithToolbars,Title } from '../../components/Card/CardHeaderWithToolbars';
import Toolbars from './Filters/Toolbars'
import DateToHuman from '../../components/DateToHuman';

import { generateNumber } from '../../utils';
import { DEFAULT_SORT_BY } from '../../constants/settings';
import privateView from '../../components/hocs/privateView';
import Analytics from "../../services/Analytics";
import { getArticlelList } from "../../actions/articleAction";
import CheckReadOnly from "../../components/CheckReadOnly";

const dataHeader=[
  'No.', 'Title','Active','Updated', 'Action'
];

const defaultParams = {
  category: null,
  sort_by : DEFAULT_SORT_BY,
  per_page : 10
}

class ArticleIndex extends Component {
  constructor() {
    super();
    this.state = {
      _params: {
        ...defaultParams,
      },
      _filterOpen: false,
    };
    this._handleToggleFilterPanel = this._handleToggleFilterPanel.bind(this);
    this._handleParamsChange = this._handleParamsChange.bind(this);
    this._handleParamsChangeAndSubmit = this._handleParamsChangeAndSubmit.bind(
      this
    );
    this._handleGoto = this._handleGoto.bind(this);
    this._handleRefresh = this._handleRefresh.bind(this);
    this._handleResetFilter = this._handleResetFilter.bind(this);
    this._handleApplyFilter = this._handleApplyFilter.bind(this);
  }

  componentDidMount() {
    const { email } = this.props.injectedProps;
    this._getList(this.state._params);
    Analytics.recordPageView("article_list", email, {
      url: window.location.href,
      location: this.props.location,
      match: this.props.match,
    });
  }

  _toggleFilterPanel() {
    this.setState({ _filterOpen: !this.state._filterOpen });
  }

  _handleToggleFilterPanel(e) {
    e.preventDefault();
    this._toggleFilterPanel();
  }

  _handleApplyFilter(payloadFilter){
    this.setState({
      _params: {
        ...this.state._params,
        ...payloadFilter,
        page: 1
      }
    },()=>this._getList(this.state._params));
  }

  _onRenderRowItem(){
    if(this.props.article.list.status_code===200 && this.props.article.list.data.length>0){
      const { current_page,per_page } =  this.props.article.list;
      return this.props.article.list.data.map((item,index)=>
        (
          <tr key={generateNumber(per_page,current_page,index)}>
            <th scope="row">{ generateNumber(per_page,current_page,index) }</th>	
            <td>{ item.title }</td>
            <td className="v-center"><CheckReadOnly check={item.active}/></td>
            <td>{ <DateToHuman value={item.updated_at} id={`tooltip_updatedAt_${generateNumber(per_page,current_page,index)}`}/> }</td>
            <td>
              <Link to={`/articles/${item.id}`} className="btn btn-link">Details</Link>
            </td>
          </tr>
        )
      )	
    }
    return null;
  }

   /**
	 * Function to handle rendering paginations
	 * @author haikal
	 */
  _onRenderPagination(){
    const { list } = this.props.article;
    const hasData = (list.status_code===200&&list.data.length)||null;
    return hasData && (
      <Paginations 
        size="sm" 
        lastPage={this.props.article.list.last_page} 
        perPage={this.props.article.list.per_page}
        total={this.props.article.list.total}
        maxPage={8}
        path={this.props.article.list.path}
        currentPage={this.props.article.list.current_page} 
        prevPageUrl={this.props.article.list.prev_page_url} 
        nextPageUrl={this.props.article.list.next_page_url} 
        handleGotoCallback={this._handleGoto}
      />
    )
  }

	/**
	 * Handle callback when user click pagination items
	 * @author haikal
	 * @param {integer} page 
	 */
	_handleGoto(page){
    const _params=this.state._params;
    _params.page=page;
    this.setState({_params},()=>this._getList(this.state._params));
  }

  _getList(rawParams = {}){
    let params = {};
    Object.keys(rawParams)
    .filter(key => rawParams[key]!='')
    .forEach(key=> params[key] = rawParams[key]);
    this.props.getList({params});
  }

  _refreshList(){
    const {page, ..._params} = this.state._params;
    this.setState({
      _params
    },()=>this._getList(this.state._params));
  }

  _handleParamsChange(e){
    const { _params } = this.state;
    _params[e.target.name] = e.target.value;
    this.setState({_params});
  }

  _handleParamsChangeAndSubmit(e){
    // Exclude page...
    const { page , ..._params } = this.state._params;
    _params[e.target.name] = e.target.value;
    this.setState({_params},()=>{
      this._getList(this.state._params);
    });    
  }

  _handleDisplayChange(e){
    const { _params } = this.state;
    _params[e.target.name] = e.target.value;
    _params.page = 1;
    this.setState({_params},()=>{
      this._getList(this.state._params);
    });    
  }

  _handleRefresh(e){
    e.preventDefault();
    this._refreshList();
  }

  _handleResetFilter(e){
    e.preventDefault();
    const newState = this.state._filterOpen?{_filterOpen:!this.state._filterOpen, _isResetFilter: true}:{};
    this.setState({
      ...newState,
      _params:{
        ...defaultParams
      }
    },()=>this._getList(this.state._params));
  }
    
  render(){
    return(
      <div className="animated fadeIn">
        <Row>
          <Col xs={12}>
            <Card>
              <CardHeaderWithToolbars>
                <Title>
                  <i className="fa fa-align-justify"></i>
                  <span>Article List</span>
                </Title>
                <Toolbars
                  isOpen={this.state._filterOpen}
                  onToggleFilter={this._handleToggleFilterPanel}
                  onRefreshList={this._handleRefresh}
                  onResetFilter={this._handleResetFilter}
                />
              </CardHeaderWithToolbars>
              <FilterContainer>
                <FilterPanel 
                  {...this.state._params}
                  onApplyFilter={this._handleApplyFilter} 
                  onHandleChange={this._handleParamsChange}
                  onHandleChangeAndSubmit={this._handleParamsChangeAndSubmit}
                  onHandleSearch={this._handleRefresh}
                  isReset={this.state._isResetFilter}
                  isOpen={this.state._filterOpen}
                />
              </FilterContainer>
              <CardBody>
                <CardText>
                  In this section will display Article list.
                </CardText>
                <div className="table-responsive">
                  <PaginationSummaries
                    from={this.props.article.list.from}
                    to={this.props.article.list.to}
                    total={this.props.article.list.total}
                  />
                  <Tbl>
                    <TblHead
                      dataHeader={dataHeader}
                    />
                    <TblBody
                      isLoad={this.props.article.isFetch}
                      hasRow={this.props.article.list.status_code===200 && this.props.article.list.data.length>0}
                      columnCount={dataHeader.length}
                    >
                    { this._onRenderRowItem() }
                    </TblBody>
                  </Tbl>
                </div>
                { this._onRenderPagination() }
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    )
  }
}

const mapStateToProps= ({ article, auth: { isFetch, roles, isLogged, id, email } }) => {
	return {
    article,
    guardData: { isFetch, roles, isLogged, id, email }
	}
};
const mapDispatchToProps = (dispatch) => {
	return{
		getList:(query) => dispatch(getArticlelList(query))
	}

};
const enhance = connect(mapStateToProps,mapDispatchToProps);

export default enhance(privateView(ArticleIndex, ['blog_admin']));
