import {
    UPLOAD_IMAGES,
    UPLOADING_IMAGES,
    UPLOADING_IMAGES_ERROR,
    DELETING_OBJECT_S3_PROCESS,
    DELETING_OBJECT_S3_DONE,
    DELETING_OBJECT_S3_ERROR,
    UPLOAD_IMAGES_API,
    CLEAR_IMAGE_UPLOAD,
    DELETING_IMAGE_ERROR,
    DELETING_IMAGE_API,
    DELETING_IMAGE_REQUEST
} from '../constants';
const initialState={
  success:{
    images:{},
    data:null,
  },
  error:null,
  isUpload:false,
  isDeleteProgress: false,
  deleted: null
}
const imageUploadReducer= (state=initialState, action) => {
	switch(action.type)
	{
		case UPLOAD_IMAGES: 
			const images={...state.success.images};
			images[action.payload.identifier]=action.payload;
		return { ...state, success:{images}, error: null };
		case UPLOADING_IMAGES: return { ...state,isUpload:action.payload };
    case UPLOADING_IMAGES_ERROR: return { ...state,error:action.payload };
    
		case DELETING_OBJECT_S3_PROCESS: return { ...state, isDeleteProgress: action.payload };
		case DELETING_OBJECT_S3_DONE: return { ...state, error:null, deleted: action.payload };
    case DELETING_OBJECT_S3_ERROR: return { ...state, error:action.payload };
    case UPLOAD_IMAGES_API : return { ...state, error:null, success:{...state.success, data:action.payload.data, error:null}}
    case DELETING_IMAGE_REQUEST: return { ...state, isDeleteProgress: action.payload };
    case DELETING_IMAGE_API: return { ...state, error:null, deleted: action.payload };
    case DELETING_IMAGE_ERROR: return { ...state, error:action.payload };
    case CLEAR_IMAGE_UPLOAD: return { ...initialState };
		default:return state;
	}
}

export default imageUploadReducer;