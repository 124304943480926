import React from 'react';
import propTypes from 'prop-types';
import { Badge } from 'reactstrap';

import {
  PENDING_STATUS,
  COMPLETED_STATUS,
  REJECTED_STATUS,
  IN_PROGRESS_STATUS,
  getDisbursementStatusStr
} from '../helpers';

const BadgeDisbursementType = (props) => {
  return (
    <Badge color={props.status === REJECTED_STATUS? "danger"
    :props.status === COMPLETED_STATUS? "success"
    :props.status === IN_PROGRESS_STATUS? "warning"
    :"default"}
    >
      {getDisbursementStatusStr(props.status)}
    </Badge>
  );
}

export default BadgeDisbursementType;

BadgeDisbursementType.defaultProps = {
  status: PENDING_STATUS
}

BadgeDisbursementType.propTypes = {
  status: propTypes.string
}