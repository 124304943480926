import React from 'react';
import propTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';


import { getPaymentType } from '../../../constants/kickavenue';

import BadgeThin from '../../../components/Badges/BadgeThin';
import DateToHuman from '../../../components/DateToHuman';
import { TextRupiah } from '../../../components/Text';
import { FontAwesomeIcon } from '../../../components/Icons';
import { getStatusStr, isExpired as checkIsExpired } from '../helpers';
import date from '../../../helpers/date';
import { DATE_FORMAT_DB } from '../../../config/date';

const UserCard = styled.div`
  > p {
    margin: 0;
    &:first-child{
      font-size: .75rem;
    }
  }
`

const UserWrapper = styled.div`
  display: flex;
  flex-direction: column;
  > ${UserCard}:first-child{
    margin-bottom: .5rem;
  }
`


const Div = styled.div`
  display: flex;
  flex-direction: ${props => props.column? 'column': 'row'};
  font-size: ${props => props.fontSize? props.fontSize: 'inherit' };
`

const FooterCard = styled(Div)`
  align-items: center;
  > .text-danger {
    font-size: .65rem;
    font-style: italic;
    margin-left: .2rem;
  }
`

const HeaderDiv = styled(Div)`
  color: #55676A;
  font-size: .75rem;

`
const Text = styled.span`
  margin-right: 5px;
  font-size: ${props => props.type==='small'? '.75rem': 'inherit' };
  font-style: ${props => props.fontStyle ? props.fontStyle : 'normal' };
`
const Menu = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
const MenuDivider = styled.div`
  border-bottom: 1px solid #ddd;
  margin: .5rem 0px;
  width: 100%;
`

const Td = styled.td`
  border-bottom: ${props => props.isDeleted? '1px solid #f86c6b': 'none'};
`


const BidCard = props => (
  <Div column>
    <HeaderDiv>
      {props.category &&(
        <Text className="text-uppercase">
          <BadgeThin color="primary">
            <i className="fa fa-tags"></i> { props.category }
          </BadgeThin>
        </Text>
      )}
      <Text>{ `#${props.id}` }</Text>
      <Text>{ props.code }</Text>
    </HeaderDiv>
    <Div column>
      <Text>{ props.displayName }</Text>
      <Text type="small" fontStyle="italic">{ props.colour }</Text>
    </Div>
    <Div fontSize=".65rem">
      <Text><i className="fa fa-calendar"></i> { props.createdAt }</Text>
      <Text>({ props.SKU })</Text>
    </Div>
    <FooterCard>
      <Text>
        <BadgeThin color="success">SIZE: { props.size }</BadgeThin>
      </Text>
      <TextRupiah bold prefix="Rp. " value={props.amount}/>
      { props.isDeleting? <div className="text-danger">(Deleting.)</div> : (props.deletedAt? <div className="text-danger">(Deleted.)</div> : null) }
    </FooterCard>
  </Div>
)

class RowItem extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      _dropdownMenu: false,
      _deletedAt: props.deletedAt,
      _updatedAt: props.updatedAt,
      _isDeleting: false
    }
    this._toggleMenu = this._toggleMenu.bind(this);
    this._onDeleteBidClick = this._onDeleteBidClick.bind(this);
  }

  componentDidUpdate(prevProps){
    if(prevProps.bid.isSubmit && prevProps.bid.isSubmit !== this.props.bid.isSubmit) {
      const { error } = this.props.bid;
      if(error){
        const { id } = error;
        if(this.state._isDeleting && id === this.props.id)
          this.setState({
            _isDeleting: false,
            ...this.state._before,
            _before: null
          })
      }
      else{
        const { data } = this.props.bid;
        if(this.state._isDeleting && data.id === this.props.id)
          this.setState({
            _isDeleting: false,
            _deletedAt: data.deleted_at,
            _updatedAt: data.updated_at
          })
      }
    }
  }

  _toggleMenu() {
    this.setState({ _dropdownMenu: !this.state._dropdownMenu });
  }

  _onDeleteBidClick(){
    const { _updatedAt, _deletedAt } = this.state;
    this.setState({
      _isDeleting: true,
      _deletedAt: date(new Date()).format(DATE_FORMAT_DB),
      _updatedAt: date(new Date()).format(DATE_FORMAT_DB),
      _before: { _updatedAt, _deletedAt }
    })
    this.props.onDeleteBid(this.props.id)
  }


  render(){
    const { payment } = this.props;
    const isExpired = checkIsExpired(this.props.expiredAt)
    return (
      <tr>
        <th className="v-center" scope="row">{ this.props.rowNumber }</th>
        <Td className="v-center" isDeleted={this.state._deletedAt!==null}>
          <BidCard
            id={this.props.id}
            rowNumber={this.props.rowNumber}
            code={this.props.code}
            category={this.props.category}
            createdAt={this.props.createdAt}
            size={this.props.size}
            displayName={this.props.displayName}
            colour={this.props.colour}
            SKU={this.props.SKU}
            amount={this.props.amount}
            deletedAt={this.state._deletedAt}
            isDeleting={this.state._isDeleting}
          />
        </Td>
        <Td className="v-center" isDeleted={this.state._deletedAt!==null}>
          <UserWrapper>
            <UserCard>
              <p>User: </p>
              <p>{this.props.user && this.props.user.email}</p>
            </UserCard>
            <UserCard>
              <p>Seller: </p>
              <p>{this.props.seller? this.props.seller.email : '-'}</p>
            </UserCard>
          </UserWrapper>
        </Td>
        <Td className="v-center" isDeleted={this.state._deletedAt!==null}>{ getPaymentType(this.props.paymentMethod) }</Td>
        <Td className="v-center" isDeleted={this.state._deletedAt!==null}>{ getStatusStr(this.props.status, isExpired, payment) }</Td>
        <Td className="v-center" isDeleted={this.state._deletedAt!==null}><DateToHuman  value={this.state._updatedAt} id={`tooltip_${this.props.rowNumber}`}/></Td>
        <Td className="v-center" isDeleted={this.state._deletedAt!==null}>
          <Menu>
            <Link className="btn btn-link" title="Show details" to={`/offers/${this.props.id}`}>Details</Link>
            <MenuDivider/>
            <Dropdown
              isOpen={this.state._dropdownMenu}
              toggle={this._toggleMenu}
            >
              <DropdownToggle caret>
                Action...
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem>
                  <FontAwesomeIcon iconType="edit"/> Update
                </DropdownItem>
                <DropdownItem onClick={this._onDeleteBidClick}>
                  <FontAwesomeIcon iconType="trash"/> Delete
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </Menu>
        </Td>
      </tr>
    )
  }
}

const mapStateToProps = state => ({
  bid: state.bid.detail
})

export default connect(mapStateToProps)(RowItem);

RowItem.defaultProps = {
  amount: 0,
  deletedAt: null
}

RowItem.propTypes = {
  rowNumber: propTypes.number.isRequired,
  deletedAt: propTypes.string,
  amount: propTypes.oneOfType([propTypes.number, propTypes.string])
}