import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import { getCacheImage } from '../../utils/imageHelper';

const ImageWrapper = styled.div`
  min-height: ${props => props.height? `${props.height}px`: (props.size? `${props.size}px`: "150px") || "150px"};
  min-width: ${props => props.width? `${props.width}px`: (props.size? `${props.size}px`: "150px") || "150px"};
  height: ${props => props.height? `${props.height}px`: (props.size? `${props.size}px`: "150px") || "150px"};
  width: ${props => props.width? `${props.width}px`: (props.size? `${props.size}px`: "150px") || "150px"};
  position: relative;
  background: #ddd;
  border: 1px solid #4a4a4a;
  display: flex;
  justify-content: center;
  > img {
    position: absolute;
    top: 0;
    bottom: 0;
    max-width: 100%;
    max-height: 100%;
    margin: auto;
  }
`;

export const ImageCentered = ({src, size = 150, alt = 'Image\'s alt', ...props}) => (
  <ImageWrapper size={size} {...props}>
    <img alt={alt} title={src} src={getCacheImage(src)}/>
  </ImageWrapper>
)

export const Image = ({ url, className = "img-thumbnail img-responsive" }) => {
  const [state, setState] = useState({
    width: 0,
    height: 0
  })

  const onImageLoad = useCallback(({target: img})=>{
    setState(prev => ({
      ...prev,
      width: img.naturalWidth,
      height: img.naturalHeight
    }))
  })

  return (
    <div style={{display: 'flex', width: '100%', alignItems: 'center', flexDirection: 'column'}}>
      <img className={className} onLoad={t => onImageLoad(t)} src={url}/>
      <p style={{fontSize: '.5rem', margin: 0, marginTop: '.25rem'}}>
        {url} - {state.width}px,{state.height}px
      </p>
    </div>
  )
}

