import React, {PureComponent as Component} from 'react';
import propTypes from 'prop-types';
import OrderShipping from '../../../components/Order/OrderShipping';
export default class UserShippingDetail extends Component{
  render(){
    return (
      <OrderShipping
        className="tab-content--custom__card"
        {...this.props}
      />
    )
  }
}

UserShippingDetail.propTypes={
  isLoading: propTypes.bool,
  user: propTypes.object,
  shipping: propTypes.object
}
UserShippingDetail.defaultProps = {
  isLoading: false
}