import React, {PureComponent as Component} from 'react';
import {Row,Col,Card, CardText} from 'reactstrap';
import { Link } from 'react-router-dom';
import propTypes from 'prop-types';

import { UserDetail } from '../../../components/Users';
import SellerVerificationList from '../../../components/Order/sellerDetail/SellerVerificationList';

export default class TabSellerDetail extends Component{
  render(){
    return(
      <Card body className="tab-content--custom__card">
        <CardText>About seller's detail. <Link to={`/users/${this.props.seller.id}`}>See References.</Link></CardText>
        <Row>
          <Col xs={12} md={6}>
            <UserDetail
              headerText="Seller Details"
              displayRole
              isLoading={this.props.isLoading} 
              user={{...this.props.seller}}
            />
          </Col>
          <Col xs={12}>
            <SellerVerificationList
              data={this.props.seller.seller_verification_info}
            />
          </Col>
        </Row>                    
      </Card>
    );
  }
}
TabSellerDetail.propTypes={
  seller:propTypes.shape({
    id:propTypes.number,
    email:propTypes.string,
    roles: propTypes.arrayOf(propTypes.object),
    seller_verification_info: propTypes.arrayOf(propTypes.object)
  })
}
TabSellerDetail.defaultProps={
  seller:{
    id:0,
    email:'',
    roles: [],
    seller_verification_info: []
  }
}
