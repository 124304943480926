import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import styled from 'styled-components';
import {
  Row,
  Col,
  Label,
  Input,
  Card,
  CardHeader,
  CardBody,
  CardText,
  Button,
  ListGroup,
  ListGroupItem,
  ListGroupItemText,
  ListGroupItemHeading
} from 'reactstrap';
import { getRaffleTiers, saveRaffleItems, saveRaffleTiers } from '../../../actions/raffleAction';
import { getOptionList as getCategoryOptionList } from '../../../actions/categoriesAction';
import { FontAwesomeIcon, SimpleLineIcon } from '../../../components/Icons';
import { ModalRaffleTier } from './Modals';
import ItemList from './ItemList';

const RaffleTiersItem = styled(ListGroupItem)`
  display: flex;
  align-items: center;
  .control{
    padding: .5rem;
  }
`

const Title = styled(ListGroupItemHeading)`
  margin-bottom: 0px;
`

const Desc = styled(ListGroupItemText)`
  margin-bottom: 0px;
`

const defaultParams = {
  per_page: 15,
  page: 1,
}

class RaffleTierList extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      _params:{
        ...defaultParams
      },
      paginations: {
        from: 0,
        to: 0,
        total: 0,
        currentPage: 0,
        lastPage: 0,
        perPage: 0
      },
      list: [],
      listSelected: [],
      error: null,
      message: '',
      statusCode: null,
      isBrowseProductsOpen: false
    }
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount(){
    const { _params } = this.state;
    this._getDataList(_params)
  }

  _sanitizeParams = rawParams => {
    return {
      params: Object.keys(rawParams)
      .filter(key => rawParams[key]!='')
      .reduce((obj, current)=> ({...obj, [current]: rawParams[current]}), [])
    }
  }

  _getDataList = (rawParams = {}) => {
    const { id } = this.props;
    this.props.getRaffleTiers(
      id,
      this._sanitizeParams(rawParams)
    )
  }

  _resetDataList = () => {
    this.setState({
      _params: {
        ...defaultParams
      }
    }, () => this._getDataList(this.state._params))
  }

  _handleGoto = page => {
    const _params = this.state._params;
    _params.page = page;
    this.setState({ _params },
      ()=> this._getDataList(this.state._params)
    );
  }

  _handleBrowseProductToggle = () => {
    this.setState({
      isBrowseProductsOpen: !this.state.isBrowseProductsOpen
    })
  }

  componentDidUpdate(prevProps){
    if(!this.props.dataRaffleTiers.isFetch && prevProps.dataRaffleTiers.isFetch !== this.props.dataRaffleTiers.isFetch){
      if(!this.props.dataRaffleTiers.error&&this.props.dataRaffleTiers.data){
        const { data, ...paginations } = this.props.dataRaffleTiers.data;
        this.setState({
          list: [...data],
          paginations: {
            ...this.state.paginations,
            from: paginations.from,
            to: paginations.to,
            total: paginations.total,
            currentPage: paginations.current_page,
            lastPage: paginations.last_page,
            perPage: paginations.per_page
          }
        })
      }
    }
    if(!this.props.dataRaffleTiers.isSubmit && prevProps.dataRaffleTiers.isSubmit !== this.props.dataRaffleTiers.isSubmit){
      const { error, message, status_code } = this.props.dataRaffleTiers;
      if(error){
        this.setState({
          error: {...error},
        })
      }
      else{
        this.setState({
          error: null,
          message,
          statusCode: status_code,
          listSelected: []
        })
        if(status_code === 201)
          this._resetDataList();
      }
    }
  }

  _handleDetachAllItems = e => {
    e.preventDefault();
    const { listSelected } = this.state;
    const { id } = this.props;
    const payloads = {
      raffles : listSelected.length !== 0?
        listSelected.map(i => i.id): ['*']
    }
    this.props.saveRaffleTiers('detach', id, payloads);
  }

  async _handleDetachRaffleTiers(e,item){
    e.preventDefault();
    const { id } = this.props;
    const payload = {raffles:[item]};
    this.props.saveRaffleTiers('detach', id, payload);
  }

  _handleAttachItems = item => {
    const { id } = this.props;
    const payloads = {raffles:item};
    this.props.saveRaffleTiers('attach', id, payloads);
  }

  _handleOpenBrowseModal = e => {
    e.preventDefault();
    this._handleBrowseProductToggle()
  }

  handleChange(e, item){
    const { listSelected } = this.state;
    const elementsIndex = listSelected.findIndex(element => element.id === item.id)
    let newArray = [...listSelected];
    newArray[elementsIndex] = {...newArray[elementsIndex], points: e.target.value}
    this.setState({listSelected:newArray});
  }

  render(){
    return(
      <div>
        <ModalRaffleTier
          size="md"
          pointShopId={this.props.id}
          isOpen={this.state.isBrowseProductsOpen}
          toggle={this._handleBrowseProductToggle}
          onSubmit={this._handleAttachItems}
        />
        <Card>
          <CardHeader>
            <FontAwesomeIcon iconType="align-justify"/> Raffle Tiers
          </CardHeader>
          <CardBody>
            <CardText>Add / remove raffle tiers (Only have 1 tiers).</CardText>
            <div className="mb-1" style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
              <div>
                {
                  this.state.list.length==0?
                  <a
                    href="#"
                    className="link text-primary mr-1"
                    onClick={this._handleOpenBrowseModal}
                  >
                    <SimpleLineIcon iconType="plus" className="mr-1"/>
                    Browse
                  </a>
                  :
                  null
                }
                <a
                  href="#"
                  className="link text-danger mr-2"
                  onClick={this._handleDetachAllItems}
                >
                  <SimpleLineIcon iconType="trash" className="mr-1"/>
                  <span>
                    Remove {this.state.listSelected.length?`(${this.state.listSelected.length})`: 'All'}
                  </span>
                </a>
              </div>
              <div>
                {
                  this.state.listSelected.length > 0?(
                    <Button
                      outline
                      color="danger"
                      size="sm"
                      type="button"
                      className="mr-1"
                      style={{fontSize: '.5rem'}}
                      onClick={() => this.setState({listSelected: []})}
                    >
                      Clear Selected
                    </Button>
                  ): null
                }
                <Button
                  outline
                  title="Refresh list"
                  color="primary"
                  style={{fontSize: '.5rem'}}
                  size="sm"
                  type="button"
                  onClick={() => this._handleGoto(1)}
                >
                  <FontAwesomeIcon iconType="refresh"/>
                </Button>
              </div>
            </div>
            <ItemList
              isLoading={this.props.dataRaffleTiers.isFetch||this.props.dataRaffleTiers.isSubmit}
              paginations={this.state.paginations}
              list={this.state.list}
              renderItems={(item, index) => {
                const { listSelected } = this.state;
                return(
                  <ListGroup key={index} style={{maxHeight: 250, overflow: 'auto'}}>
                  {
                    <RaffleTiersItem key={index}>
                      <div className="control">
                        <Input
                          type="checkbox"
                          value="true"
                          checked={listSelected.length>0&&listSelected.some(list => list.id === item.id)}
                          onChange={({target: { checked: value }}) => {
                            const { listSelected } = this.state;
                            this.setState({
                              listSelected: value? [...listSelected, {id : item.id}]: [...listSelected.filter(list => list.id !== item.id)]
                            })
                          }}
                        />
                      </div>
                      <div>
                        <Title>
                          (
                            <a
                              href="#"
                              className="link text-danger"
                              onClick={(e)=> this._handleDetachRaffleTiers(e,item.id)}
                            >
                              <SimpleLineIcon iconType="trash"/>
                            </a>
                          ) &nbsp; {item.tier_label.toUpperCase()}
                        </Title>
                        <Desc>
                          <small><b>points</b>: <i>{parseInt(item.pivot.points)}</i> <b>added</b>: <i>{item.pivot.created_at},</i> <b>active</b>: {item.pivot.is_active? <i className="fa fa-check"></i>:<i className="fa fa-times"></i> } </small>
                        </Desc>
                      </div>
                    </RaffleTiersItem>
                  }
                </ListGroup>
              )}}
              onGotoPage={this._handleGoto}
              onSubmit={this._handleAttachItems}
            />
          </CardBody>
        </Card>
      </div>
    )
  }
}


const mapStateToProps= ({ raffle: { raffleTiers } }) => ({
  dataRaffleTiers: raffleTiers
});
const mapDispatchToProps = (dispatch) => ({
  getRaffleTiers: (id, query) => dispatch(getRaffleTiers(id, query)),
  saveRaffleItems: (operation, id, payload) => dispatch(saveRaffleItems(operation, id, payload)),
  saveRaffleTiers: (operation, id, payload) => dispatch(saveRaffleTiers(operation, id, payload)),
  getCategoryOptionList: ()=> dispatch(getCategoryOptionList()),
});
export default connect(mapStateToProps,mapDispatchToProps)(RaffleTierList);
