import React from 'react';
import propTypes from 'prop-types';
import {
  Row,
  Col,
  Form,
  FormGroup,
  Button,
  Input,
  Label
} from 'reactstrap';

const FilterForm = props => (
  <Form className="form-filter" onSubmit={props.onSubmit}>
    <Row>
      <Col xs={12} md={6}>
        <p>Filter By :</p>
        <div className="d-flex" style={{flexDirection: 'column'}}>
          <div className="d-flex" style={{padding: "0px 1rem"}}>
            <FormGroup className="d-flex" style={{flexDirection: 'column', alignItems: 'flex-start'}}>
                <Label className="mb-2" style={{fontWeight: 700}}>
                  Nominal Type
                </Label>
                <div className="d-flex ml-2 mr-2">
                  <FormGroup check className="mr-2">
                    <Label check>
                      <Input type="radio" name="type" value="" checked={props.type == ''} onChange={props.onCheckedChange} /> All
                    </Label>
                  </FormGroup>
                  <FormGroup check className="mr-2">
                    <Label check>
                      <Input type="radio" name="type" value="percentage" checked={props.type == 'percentage'} onChange={props.onCheckedChange} /> Percentage
                    </Label>
                  </FormGroup>
                  <FormGroup check className="mr-2">
                    <Label check>
                      <Input type="radio" name="type" value="nominal" checked={props.type == 'nominal'} onChange={props.onCheckedChange} /> Fixed
                    </Label>
                  </FormGroup>
                </div>
            </FormGroup>
          </div>
          <div className="d-flex" style={{padding: "0px 1rem"}}>
            <FormGroup className="d-flex" style={{flexDirection: 'column', alignItems: 'flex-start'}}>
                <Label className="mb-2" style={{fontWeight: 700}}>
                  Voucher Type
                </Label>
                <div className="d-flex ml-2 mr-2">
                  <FormGroup check className="mr-2">
                    <Label check>
                      <Input type="radio" name="voucher_type" value="" checked={props.voucher_type == ''} onChange={props.onCheckedChange} /> All
                    </Label>
                  </FormGroup>
                  <FormGroup check className="mr-2">
                    <Label check>
                      <Input type="radio" name="voucher_type" value="public" checked={props.voucher_type == 'public'} onChange={props.onCheckedChange} /> Public
                    </Label>
                  </FormGroup>
                  <FormGroup check className="mr-2">
                    <Label check>
                      <Input type="radio" name="voucher_type" value="private" checked={props.voucher_type == 'private'} onChange={props.onCheckedChange} /> Private
                    </Label>
                  </FormGroup>
                  <FormGroup check className="mr-2">
                    <Label check>
                      <Input type="radio" name="voucher_type" value="public_open" checked={props.voucher_type == 'public_open'} onChange={props.onCheckedChange} /> Public Open
                    </Label>
                  </FormGroup>
                </div>
            </FormGroup>
          </div>
          <div className="d-flex" style={{padding: "0px 1rem"}}>
            <FormGroup className="d-flex" style={{flexDirection: 'column', alignItems: 'flex-start'}}>
                <Label className="mb-2" style={{fontWeight: 700}}>
                  Deduct Type
                </Label>
                <div className="d-flex ml-2 mr-2">
                  <FormGroup check className="mr-2">
                    <Label check>
                      <Input type="radio" name="deduct_type" value="" checked={props.deduct_type == ''} onChange={props.onCheckedChange} /> All
                    </Label>
                  </FormGroup>
                  <FormGroup check className="mr-2">
                    <Label check>
                      <Input type="radio" name="deduct_type" value="product_price" checked={props.deduct_type == 'product_price'} onChange={props.onCheckedChange} /> Product Price
                    </Label>
                  </FormGroup>
                  <FormGroup check className="mr-2">
                    <Label check>
                      <Input type="radio" name="deduct_type" value="courier_price" checked={props.deduct_type == 'courier_price'} onChange={props.onCheckedChange} /> Courier Price
                    </Label>
                  </FormGroup>
                </div>
            </FormGroup>
          </div>
          <FormGroup className="mb-2" style={{alignSelf: 'flex-end'}}>
            <Button color="primary" >Apply Filter</Button>
          </FormGroup>
        </div>
      </Col>
    </Row>
  </Form>
)
export default FilterForm;
FilterForm.defaultProps = {
  deduct_type: "",
  type: "",
  voucher_type: ""
}
FilterForm.propTypes = {
  onSubmit: propTypes.func.isRequired,
  onCheckedChange: propTypes.func.isRequired,
  voucher_type: propTypes.string,
  type: propTypes.string,
  deduct_type: propTypes.string,
}