import {
    PUSH_NOTIFICATION_ADD,
    PUSH_NOTIFICATION_DELETE,
    PUSH_NOTIFICATION_DETAIL_FETCH,
    PUSH_NOTIFICATION_ERROR,
    PUSH_NOTIFICATION_FETCHING,
    PUSH_NOTIFICATION_LIST_FETCH,
    PUSH_NOTIFICATION_UPDATE,
    ADD_IMAGE_PUSH_NOTIFICATION,
    DELETE_IMAGE_PUSH_NOTIFICATION,
} from "../constants";

const initialState = {
    list: [],
    detail: {
        delete: false,
    },
    isFetch: false,
    isSubmit: false,
    success: null,
    error: null,
    addedImages: {},
    isSubmittingImage: false,
    deletedImages: [],
    isDeletingImage: false,
};

const onFetching = (lastState, payload) => {
    if (payload.type === "fetch")
        return {
            ...lastState,
            isFetch: payload.status,
            success: null,
            error: null,
        };
    else if (payload.type === "submit")
        return { ...lastState, isSubmit: payload.status };
    else if (payload.type === "submit_image")
        return { ...lastState, isSubmittingImage: payload.status };
    else if (payload.type === "delete_image")
        return { ...lastState, isDeletingImage: payload.status };

    return { ...lastState, isFetch: false, isSubmit: false };
};

const pushNotificationReducer = (state = initialState, action) => {
    switch (action.type) {
        case PUSH_NOTIFICATION_LIST_FETCH: {
            const { data, status_code, message } = action.payload;
            return {
                ...state,
                list: { ...data, message, status_code },
                detail: {},
                error: null,
            };
        }
        case PUSH_NOTIFICATION_DETAIL_FETCH: {
            const { data, status_code, message } = action.payload;
            return {
                ...state,
                detail: {
                    ...state.detail,
                    ...data,
                    message,
                    delete: false,
                    status_code,
                },
                error: null,
            };
        }
        case PUSH_NOTIFICATION_ADD:
        case PUSH_NOTIFICATION_UPDATE: {
            const { data, status_code, message } = action.payload;
            return {
                ...state,
                detail: { ...state.detail, ...data, message, status_code },
                success: message,
                error: null,
            };
        }
        case PUSH_NOTIFICATION_DELETE: {
            const { data, status_code, message } = action.payload;
            return {
                ...state,
                detail: {
                    ...state.detail,
                    ...data,
                    message,
                    delete: true,
                    status_code,
                },
                success: message,
                error: null,
            };
        }
        case PUSH_NOTIFICATION_ERROR: {
            return { ...state, ...action.payload, success: null };
        }
        case ADD_IMAGE_PUSH_NOTIFICATION: {
            const { data, message, status_code } = action.payload;
            const addedImages = { ...state.addedImages };
            addedImages[action.payload.identifier] = {
                ...data,
                message,
                status_code,
            };
            return { ...state, addedImages };
        }
        case DELETE_IMAGE_PUSH_NOTIFICATION: {
            const { deletedImages } = { ...state };
            deletedImages.push(action.payload.identifier);
            return { ...state, deletedImages };
        }
        case PUSH_NOTIFICATION_FETCHING: {
            return onFetching(state, action.payload);
        }
        default: {
            return state;
        }
    }
};

export default pushNotificationReducer;
