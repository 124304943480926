import { LEGIT_CHECK_STATUS } from "../../../constants/kickavenue";

const legitCheckLogQC = (legitCheckLogIndex) => legitCheckLogIndex === 0;
const legitCheckLogLC = (legitCheckLogIndex) => legitCheckLogIndex === 1;

const isQCApproved = (legitCheckLogs, legitCheckStatus) => {
  if (legitCheckLogs?.length > 1) {
    return true;
  }

  if (legitCheckLogs?.length === 1 && legitCheckStatus === null) {
    return true;
  }

  if (
    legitCheckLogs?.length === 1 &&
    legitCheckStatus !== LEGIT_CHECK_STATUS.VERIFIED
  ) {
    return false;
  }

  // edge case (should never heppened)
  return false;
};

const isLCApproved = (legitCheckLogs, legitCheckStatus) =>
  legitCheckStatus === LEGIT_CHECK_STATUS.VERIFIED &&
  legitCheckLogs?.length === 2;

export const isLegitApproved = (
  legitCheckLogs,
  legitCheckStatus,
  legitCheckLogIndex
) => {
  if (legitCheckLogQC(legitCheckLogIndex)) {
    return isQCApproved(legitCheckLogs, legitCheckStatus);
  }

  if (legitCheckLogLC(legitCheckLogIndex)) {
    return isLCApproved(legitCheckLogs, legitCheckStatus);
  }

  return false;
};
