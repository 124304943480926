import {
  ARTICLE_ADD,
  ARTICLE_DELETE,
  ARTICLE_DETAIL_FETCH,
  ARTICLE_ERROR,
  ARTICLE_FETCHING,
  ARTICLE_LIST_FETCH,
  ARTICLE_UPDATE,
  ARTICLE_CATEGORY_ADD,
  ARTICLE_CATEGORY_DELETE,
  ARTICLE_CATEGORY_ERROR,
  ARTICLE_CATEGORY_FETCHING,
  ARTICLE_CATEGORY_LIST_FETCH,
  ARTICLE_CATEGORY_UPDATE,
  ARTICLE_CATEGORY_DETAIL_FETCH,
  ADD_IMAGE_ARTICLE,
  DELETE_IMAGE_ARTICLE,
  SUBMITING_ARTICLE_VARIANTS,
  SEARCH_ARTICLE_VARIANTS,
  GET_LIST_ARTICLE_VARIANTS,
  ARTICLE_CHILDS_REQUEST,
  ARTICLE_CHILDS_ERROR,
  UPDATE_IMAGE_ARTICLE,
  ARTICLE_META_LIST_FETCH,
  ARTICLE_META_DETAIL_FETCH,
  ARTICLE_META_ADD,
  ARTICLE_META_UPDATE,
  ARTICLE_META_DELETE,
  ARTICLE_META_ERROR,
  ARTICLE_META_FETCHING,
} from "../constants";

const initialState = {
  list: [],
  detail: {
    delete: false,
  },
  isFetch: false,
  isSubmit: false,
  success: null,
  error: null,
  category: {
    list: [],
    detail: {},
    isFetch: false,
    isSubmit: false,
    success: null,
    error: null,
    delete: false,
  },
  meta: {
    list: [],
    detail: {},
    isFetch: false,
    isSubmit: false,
    success: null,
    error: null,
    delete: false,
  },
  addedImages: {},
  updatedImages: {},
  isSubmittingImage: false,
  deletedImages: [],
  isDeletingImage: false,
  articleVariants: {
		dataSearch: [],
		data: [],
		isSearchFetch: false,
		isFetch: false,
		isSubmit: false,
		error: null
	}
};

const onFetching = (lastState, payload) => {
  if (payload.type === "fetch")
    return {
      ...lastState,
      isFetch: payload.status,
      success: null,
      error: null,
    };
  else if (payload.type === "submit")
    return { ...lastState, isSubmit: payload.status };
  else if (payload.type === "submit_image")
    return { ...lastState, isSubmittingImage: payload.status };
  else if (payload.type === "delete_image")
    return { ...lastState, isDeletingImage: payload.status };

  return { ...lastState, isFetch: false, isSubmit: false };
};

const onFetchingCategory = (lastState, payload) => {
  if (payload.type === "fetch")
    return {
      ...lastState,
      category: {
        ...lastState.category,
        isFetch: payload.status,
        success: null,
        error: null,
      },
    };
  else if (payload.type === "submit")
    return {
      ...lastState,
      category: { ...lastState.category, isSubmit: payload.status },
    };
  else if (payload.type === "detail")
    return {
      ...lastState,
      category: { ...lastState.category, detail: { ...lastState.category.detail, isFetch: payload.status } },
    };
  return {
    ...lastState,
    category: { ...lastState.category, isFetch: false, isSubmit: false },
  };
};

const onFetchingMeta = (lastState, payload) => {
  if (payload.type === "fetch")
    return {
      ...lastState,
      meta: {
        ...lastState.meta,
        isFetch: payload.status,
        success: null,
        error: null,
      },
    };
  else if (payload.type === "submit")
    return {
      ...lastState,
      meta: { ...lastState.meta, isSubmit: payload.status },
    };
  else if (payload.type === "detail")
    return {
      ...lastState,
      meta: { ...lastState.meta, detail: { ...lastState.meta.detail, isFetch: payload.status } },
    };
  return {
    ...lastState,
    meta: { ...lastState.meta, isFetch: false, isSubmit: false },
  };
};

const onValidateChildRequest = (lastState, { requestType, value }) => {
	switch(requestType){
		case GET_LIST_ARTICLE_VARIANTS: return {
			...lastState,
			articleVariants: {
				...lastState.articleVariants,
				isFetch: value
			}
		}
		case SEARCH_ARTICLE_VARIANTS: return {
			...lastState,
			articleVariants: {
				...lastState.articleVariants,
				isSearchFetch: value
			}
		}
		case SUBMITING_ARTICLE_VARIANTS: return {
			...lastState,
			articleVariants: {
				...lastState.articleVariants,
				isSubmit: value
			}
		}
		default: return lastState;
	}
}

const onErrorChildRequest = (lastState, { requestType, error, ...rest }) => {
	switch(requestType){
		case SUBMITING_ARTICLE_VARIANTS: return {
			...lastState,
			articleVariants: {
				...lastState.articleVariants,
				error: { ...error, ...rest }
			}
		}
		default: return lastState;
	}
}

const onArticleVariantsSucceeded = (lastState, { type, payload }) => {
	const { data, message, status_code } = payload
	switch(type){
		case SUBMITING_ARTICLE_VARIANTS: return {
			...lastState,
			articleVariants: {
				...lastState.articleVariants,
				error: null,
				message,
				status_code
			}
		};
		case SEARCH_ARTICLE_VARIANTS: return {
			...lastState,
			articleVariants: {
				...lastState.articleVariants,
				error: null,
				dataSearch: data,
				message,
				status_code
			}
		}
		case GET_LIST_ARTICLE_VARIANTS: return {
			...lastState,
			articleVariants: {
				...lastState.articleVariants,
				error: null,
				data,
				message,
				status_code
			}
		};
		default: return lastState;
	}
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ARTICLE_CHILDS_REQUEST: return onValidateChildRequest(state, action.payload);
		case ARTICLE_CHILDS_ERROR: return onErrorChildRequest(state, action.payload);
    case SUBMITING_ARTICLE_VARIANTS:
		case SEARCH_ARTICLE_VARIANTS:
    case GET_LIST_ARTICLE_VARIANTS: return onArticleVariantsSucceeded(state, action);
    case ARTICLE_LIST_FETCH: {
      const { data, status_code, message } = action.payload;
      return {
        ...state,
        list: { ...data, message, status_code },
        detail: {},
        error: null,
      };
    }
    case ARTICLE_DETAIL_FETCH: {
      const { data, status_code, message } = action.payload;
      return {
        ...state,
        detail: {
          ...state.detail,
          ...data,
          message,
          delete: false,
          status_code
        },
        error: null,
      };
    }
    case ARTICLE_ADD: {
      const { data, status_code, message } = action.payload;
      return {
        ...state,
        detail: { ...state.detail, ...data, message, status_code },
        success: message,
        error: null,
      };
    }
    case ARTICLE_UPDATE: {
      const { data, status_code, message } = action.payload;
      return {
        ...state,
        detail: { ...state.detail, ...data, message, status_code },
        success: message,
        error: null,
      };
    }
    case ARTICLE_DELETE: {
      const { data, status_code, message } = action.payload;
      return {
        ...state,
        detail: { ...state.detail, ...data, message, delete: true, status_code },
        success: message,
        error: null,
      };
    }
    case ARTICLE_ERROR:
      return { ...state, ...action.payload, success: null };

    case ARTICLE_CATEGORY_LIST_FETCH: {
      const { data, status_code, message } = action.payload;
      return {
        ...state,
        category: {
          ...state.category,
          list: { ...data, message, status_code },
          detail: {},
          error: null,
        },
      };
    }
    case ARTICLE_CATEGORY_DETAIL_FETCH: {
      const { data, status_code, message } = action.payload;
      return {
        ...state,
        category: {
          ...state.category,
          detail: {
            ...state.category.detail,
            ...data,
            status_code
          },
          message,
          error: null,
        },
      };
    }

    case ARTICLE_CATEGORY_ADD: {
      const { data, status_code, message } = action.payload;
      return {
        ...state,
        category: {
          ...state.category,
          detail: { ...state.detail, ...data, message, status_code },
          success: message,
          error: null,
        },
      };
    }

    case ARTICLE_CATEGORY_UPDATE: {
      const { data, status_code, message } = action.payload;
      return {
        ...state,
        category: {
          ...state.category,
          detail: { ...state.detail, ...data, message, status_code },
          success: message,
          error: null,
        },
      };
    }
    case ARTICLE_CATEGORY_DELETE: {
      const { data, status_code, message } = action.payload;
      return {
        ...state,
        category: {
          ...state.category,
          detail: { ...state.detail, ...data, message, status_code },
          success: message,
          delete: true,
          error: null,
        },
      };
    }
    case ARTICLE_META_LIST_FETCH: {
      const { data, status_code, message } = action.payload;
      return {
        ...state,
        meta: {
          ...state.meta,
          list: { ...data, message, status_code },
          detail: {},
          error: null,
        },
      };
    }
    case ARTICLE_META_DETAIL_FETCH: {
      const { data, status_code, message } = action.payload;
      return {
        ...state,
        meta: {
          ...state.meta,
          detail: {
            ...state.meta.detail,
            ...data,
            status_code
          },
          message,
          error: null,
        },
      };
    }

    case ARTICLE_META_ADD: {
      const { data, status_code, message } = action.payload;
      return {
        ...state,
        meta: {
          ...state.meta,
          detail: { ...state.detail, ...data, message, status_code },
          success: message,
          error: null,
        },
      };
    }

    case ARTICLE_META_UPDATE: {
      const { data, status_code, message } = action.payload;
      return {
        ...state,
        meta: {
          ...state.meta,
          detail: { ...state.detail, ...data, message, status_code },
          success: message,
          error: null,
        },
      };
    }
    case ARTICLE_META_DELETE: {
      const { data, status_code, message } = action.payload;
      return {
        ...state,
        meta: {
          ...state.meta,
          detail: { ...state.detail, ...data, message, status_code },
          success: message,
          delete: true,
          error: null,
        },
      };
    }
    case ADD_IMAGE_ARTICLE: {
      const { data, message, status_code } = action.payload;
      const addedImages = { ...state.addedImages };
      addedImages[action.payload.identifier] = {
        ...data,
        message,

      };
      return { ...state, addedImages };
    }
    case UPDATE_IMAGE_ARTICLE: {
      const { data, message, status_code } = action.payload;
      const updatedImages = { ...state.updatedImages };
      updatedImages[action.payload.identifier] = {
        ...data,
        message,

      };
      return { ...state, updatedImages };
    }
    case DELETE_IMAGE_ARTICLE: {
      const { deletedImages } = { ...state };
      deletedImages.push(action.payload.identifier);
      return { ...state, deletedImages };
    }
    case ARTICLE_CATEGORY_ERROR:
      return { ...state, category: { ...state.category, ...action.payload } };

    case ARTICLE_META_ERROR:
      return { ...state, meta: { ...state.meta, ...action.payload } };

    case ARTICLE_FETCHING:
      return onFetching(state, action.payload);
    case ARTICLE_CATEGORY_FETCHING:
      return onFetchingCategory(state, action.payload);
    case ARTICLE_META_FETCHING:
      return onFetchingMeta(state, action.payload);
    default:
      return state;
  }
};

export default reducer;
