import AWS from 'aws-sdk';
import {
  AWS_ACCESS_KEY_ID,
  AWS_SECRET_ACCESS_KEY,
  AWS_BUCKET_REGION,
  // AWS_BUCKET_USER,
  AWS_BUCKET,
  AWS_BUCKET_FAQ,
} from '../config/storageBucket'
// import {} from 'constants'

export const s3 = () => {
  const instance = new AWS.S3()
  instance.config.update({
    accessKeyId: AWS_ACCESS_KEY_ID,
    secretAccessKey: AWS_SECRET_ACCESS_KEY,
    region: AWS_BUCKET_REGION,
  });
  return instance
}

export const clientPutObject = params => {
  return new Promise((resolve, reject) => {
    s3().putObject(params, (error, data)=>{
      if(error) return reject(error);
      return resolve({ data, key: params.Key })
    })
  })
}

export const clientDeleteObject = (bucket, key) => {
  return new Promise((resolve, reject) => {
    s3().deleteObject({
      Bucket: bucket,
      Key: key
    }, (error, data)=>{
      if(error) return reject(error);
      return resolve({ data, key })
    })
  })
}

const S3Regex = new RegExp('https?:\/\/([^\.]+)?\.?(s3|s3-?.?[^\.]+)\.amazonaws\.com\/([^\/]+)(\/.+)?');

export const getBucketAndKey = u => {
  const regex = S3Regex;
  const matched = u.match(regex)
  const result = []
  if(matched){
    const [_, bucket, _a, key, key2] = matched;
    if(bucket){
      result[0] = bucket.trim()
      let k = '';
      if(key){
        k += key
        if(key2){
          k += key2?key2:''
        }
      }
      result[1] = k.trim();
    } else if(key){
      result[0] = key.trim();
      result[1] = key2?(
        ~key2.indexOf('/')? key2.trim().substring(key2.indexOf('/')+1): key2.trim()
      ): ''
    }
  }
  return result.length? result: matched
}

export const getOriginFromSignedUrl = signedUrl =>{
  if(!signedUrl) return signedUrl;
  if(S3Regex.test(signedUrl) && ~signedUrl.indexOf('?')){
    return signedUrl.substring(0, signedUrl.indexOf('?'))
      .replace('.ap-southeast-1', ''); //also remove region in signed url.
  }
  return signedUrl;
}

export const getPathS3 = (s3Link) => {
  if(!s3Link) return null;
  // const urlParsed = s3Link.replace(/^https:\/\/|http:\/\//, '')
  // const s3regex = new RegExp(`(${AWS_BUCKET}.${AWS_BUCKET_REGION}.s3.amazonaws.com)|(${AWS_BUCKET}.s3.amazonaws.com)|(s3-${AWS_BUCKET_REGION}.amazonaws.com\/${AWS_BUCKET})`);
  // if(s3regex.test(urlParsed)) return urlParsed.replace(s3regex, '');
  const results = getBucketAndKey(s3Link);
  if(results && results[0] === AWS_BUCKET || results && results[0] === AWS_BUCKET_FAQ){
    return results[1];
  }
  return null;
}