import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {
  Card,CardText,CardImg, CardLink
} from 'reactstrap';
import { getCacheImage } from '../../../utils/imageHelper'

const getPublicImageUrl = image => {
  return image.signed_url || image.URL
}

export default class ProductVariantImage extends PureComponent{
  render(){
    return(
      <Card body>
        <CardImg src={getCacheImage(getPublicImageUrl(this.props))}/>
        <CardText>
          <small className="text-muted">Last updated {this.props.updated_at}</small>
        </CardText>
        <CardLink href={getCacheImage(getPublicImageUrl(this.props))} className="text-default text-center" target="_blank"><i className="fa fa-external-link"></i> Open</CardLink>
      </Card>
    )
  }
}
ProductVariantImage.propTypes={
  image:PropTypes.object,
}
