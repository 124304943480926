import {
  AUTH_TOKEN_KEY,
  BULK_AWB,
  BULK_AWB_ERROR,
  BULK_AWB_ROUTE_API,
  BULK_AWB_SUBMIT,
} from '../constants';
import { checkAuthorized, manipulateErrors } from '../utils';
import api from '../utils/Api';
import { logout } from './authAction';

export const doBulkAWB = (payload) => {
  return (dispatch) => {
    dispatch({ type: BULK_AWB_SUBMIT, payload: true });
    api({
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`,
    })
      .post(`${BULK_AWB_ROUTE_API}`, payload)
      .then((res) => {
        dispatch(onSuccess(res.data, BULK_AWB));
        dispatch({ type: BULK_AWB_SUBMIT, payload: false });
      })
      .catch((err) => {
        dispatch(onError(err));
        dispatch({ type: BULK_AWB_SUBMIT, payload: false });
      });
  };
};

const onSuccess = (res, type) => ({
  type: type,
  payload: res,
});

const onError = (err) => {
  if (!checkAuthorized(manipulateErrors(err))) {
    return logout();
  }

  return {
    type: BULK_AWB_ERROR,
    payload: manipulateErrors(err),
  };
};
