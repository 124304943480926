import React from 'react';
import propTypes from 'prop-types';
import { Badge } from 'reactstrap';
import { Link } from 'react-router-dom';
import BadgeThin from '../../../components/Badges/BadgeThin';
import { FontAwesomeIcon, SimpleLineIcon } from '../../../components/Icons';
import Paragraph from '../../../styled/Typograph/Paragraph';
import SmallInline from '../../../styled/Typograph/Small/SmallInline';

const OrderSummariesColumn = props => {
  const { id, category, invoiceNumber, userId, userEmail, size, displayName, SKU, quantity, colour, preOrder, paymentBankTransfer, paymentMethod, createdSource } = props;
  return (
    <Paragraph>
      { createdSource && (
        <SimpleLineIcon className="mr-1" style={{fontSize: '.75rem'}} iconType={['APP', 'ANDROID', 'IOS'].includes(createdSource) ? 'screen-smartphone': 'screen-desktop'}/>
      )}
      { invoiceNumber } &nbsp;{preOrder?<Badge color="primary">PREORDER</Badge>:''}<br/>
      { displayName }<br/>
      <SmallInline>({ SKU })</SmallInline>
      <SmallInline>(Quantity: { quantity })</SmallInline>
      <SmallInline className="text-muted">{ colour }</SmallInline>
      <br/>
      {category && (
        <BadgeThin
          className="text-uppercase mr-1"
          color="primary"
        >
          <FontAwesomeIcon className="mr-1" iconType="tags"/>
          {category}
        </BadgeThin>
      )}
      <BadgeThin color="success" className="text-uppercase mr-1">SIZE: {size}</BadgeThin>
      {
        paymentMethod==='bank_transfer'&&paymentBankTransfer.length>0 && (
          <BadgeThin className="text-uppercase mr-1" color="success">
            <FontAwesomeIcon className="mr-1" iconType="exchange"/>
            {paymentBankTransfer[0].destination_bank? paymentBankTransfer[0].destination_bank.bank_name: 'undefined'}
          </BadgeThin>
        )
      }
      <BadgeThin>
        <FontAwesomeIcon className="mr-1" iconType="user"/>
        {userId? <Link style={{color: '#fff', textDecoration: 'underline'}} className="link-default" to={`/users/${userId}`}>{userEmail}</Link>: ' - '}
      </BadgeThin>
    </Paragraph>
  )
}

export default OrderSummariesColumn;

OrderSummariesColumn.defaultProps = {
  size: '-',
  SKU: '-',
  preOrder: false
}

OrderSummariesColumn.propTypes = {
  invoiceNumber: propTypes.string.isRequired,
  displayName: propTypes.string,
  SKU: propTypes.string,
  colour: propTypes.string,
  size: propTypes.string,
  userId: propTypes.number,
  userEmail: propTypes.string,
  paymentMethod: propTypes.string,
  paymentBankTransfer: propTypes.array,
  preOrder: propTypes.oneOfType([propTypes.number, propTypes.bool])
}