import React from 'react';
import styled from 'styled-components';
import {
  Row,
  Col,
  Form,
  FormGroup,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  ListGroup,
  ListGroupItem,
  Button,
  Input
} from 'reactstrap';
import Loading from 'react-loading-animation';
import AlertNotFound from '../../../components/Card/AlertNotFound';
import Paginations from '../../../components/Paginations/Paginations';
import PaginationSummaries from '../../../components/Paginations/PaginationSummaries';


const SelectButton = styled.button`
  color: #4a4a4a;
  padding: 3px 6px;
  border: 1px solid #4a4a4a;
  font-size: .65rem;
  background: none;
`

class ModalBrowseCollection extends React.Component{
  constructor(props){
    super(props);
    this.state={
      keyword: '',
      page: 1,
      perPage: 15,
      collectionType: 'product_variants',
      dataInputs: [],
      isInitGetList: false,
      total: null
    }
    this._handleSubmitCollection = this._handleSubmitCollection.bind(this);
    this._handleChange = this._handleChange.bind(this);
    this._handleOnCheckedChange = this._handleOnCheckedChange.bind(this);
    this._handleGotoPage = this._handleGotoPage.bind(this);
    this._handleOnSubmitSearch = this._handleOnSubmitSearch.bind(this);
  }

  componentDidUpdate(prevProps){
    if(!prevProps.isOpen && prevProps.isOpen !== this.props.isOpen){
      this.setState({
        page: 1,
        keyword: '',
        isInitGetList: true,
        hasNextPage: false,
        dataInputs: []
      }, () => this._getCollectionList())
    }
    if(prevProps.isLoading && prevProps.isLoading !== this.props.isLoading){
      const { error, data } = this.props;
      const { isInitGetList, total, hasNextPage } = this.state;
      if(!error){
        this.setState({
          // data: data.map(item => ({
          //   id: item.id,
          //   name: item.name,
          //   slug: item.slug,
          //   selected: false
          // })),
          isInitGetList: false,
          total: isInitGetList? data.total: total,
          hasNextPage: isInitGetList? data.next_page_url !== null: hasNextPage
        });
      }
    }
  }

  async _handleSubmitCollection(){
    const { dataInputs } = this.state;
    if(dataInputs.length){
      await this.props.onSubmitAttachCollection({ collection_id: dataInputs });
    }
    this.props.toggle();
  }

  _handleChange(e){
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  _itemChecked(id){
    const { dataInputs } = this.state;
    if(dataInputs.length > 0 && dataInputs[0] === 'all') return true;
    const d = dataInputs.find(item => item === id );
    return d != null
  }

  _handleOnCheckedChange(e, itemId){
    const { dataInputs } = this.state;
    const { data:{ data} } = this.props;
    let list = []
    if(dataInputs.includes('all') && !e.target.checked){
      list = data.filter(({id}) => id!==itemId)
        .map(({id}) => id)
    }else{
      list = [...dataInputs.filter(item => item !== itemId), ...e.target.checked? [itemId]: []]
    }
    this.setState({
      dataInputs: [ ...list ]
    })
    // const data = [...this.state.data];
    // data[index].selected = e.target.checked;
    // this.setState({
    //   data
    // })
  }

  _getCollectionList(){
    this.props.onGetCollectionList({
      per_page: this.state.perPage,
      page: this.state.page,
      keyword: this.state.keyword,
      collection_type: this.state.collectionType
    });
  }

  async _handleGotoPage(page){
    await this.setState({ page })
    this._getCollectionList();
  }

  _handlSelectAllButton(type){
    if(type==='all'){
      this.setState({ dataInputs: [type] })
    }else{
      const { data:{ data } } = this.props;
      const { dataInputs } = this.state;
      const diff = data.filter(({ id })=> !dataInputs.includes(id))
        .map(({id}) => id)
      this.setState({
        dataInputs: [...dataInputs, ...diff]
      })
    }
  }

  _handleOnSubmitSearch(e){
    e.preventDefault();
    this._handleGotoPage(1);
  }

  render(){
    return(
      <Modal isOpen={this.props.isOpen} toggle={this.props.toggle} size="md">
        <ModalHeader toggle={this.props.toggle}>
          Attach Collections
        </ModalHeader>
        <ModalBody>
          <Form style={{marginBottom: '.5rem'}} onSubmit={this._handleOnSubmitSearch}>
            <Row>
              <Col xs={12} md={12}>
                <FormGroup style={{marginBottom: '.5rem'}}>
                  <Input
                    bsSize="sm"
                    name="keyword"
                    placeholder="Search any keyword..."
                    value={this.state.keyword}
                    onChange={this._handleChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={12}>
                <div style={{display: 'flex', alignItems: 'flex-end', justifyContent: 'space-between'}}>
                  <div>
                    {
                      this.props.data.data && this.props.data.data.length > 1 &&(
                        <SelectButton type="button" className="mr-1" onClick={() => this._handlSelectAllButton('allPage')}>
                          Select ({this.props.data.data.length})
                        </SelectButton>
                      )
                    }
                    {
                      this.state.hasNextPage && this.state.total > 0 &&(
                        <SelectButton  type="button" className="mr-1" onClick={() => this._handlSelectAllButton('all')}>
                          Select All ({this.state.total})
                        </SelectButton>
                      )
                    }
                  </div>
                  <Button size="sm" color="primary">
                    Search
                  </Button>
                </div>
                {
                  this.state.dataInputs.length > 0 &&(
                    <p style={{marginBottom: 0, marginTop: '.65rem', fontSize: '.65rem', fontStyle: 'italic'}}>
                      You've selected {this.state.dataInputs[0]==='all'?this.state.total: this.state.dataInputs.length} {this.state.dataInputs.length === 1 && this.state.dataInputs[0] !== 'all'?'item': 'items'}.
                      <a
                        className="ml-1 text-danger"
                        href="#"
                        style={{textDecoration: 'underline'}}
                        onClick={e => {
                          e.preventDefault();
                          this.setState({
                            dataInputs: []
                          })
                        }}
                      >
                        clear
                      </a>
                    </p>
                  )
                }
              </Col>
            </Row>
          </Form>
          {
            this.props.data.status_code ===200 &&(
              <PaginationSummaries
                from={this.props.data.from}
                to={this.props.data.to}
                total={this.props.data.total}
              />
            )
          }
          <div style={{paddingTop: '.75rem', maxHeight: 250, overflow: 'auto'}}>
            <Loading isLoading={this.props.isLoading}>
              {
                this.props.data.data&&(
                  this.props.data.data.length>0?(
                    <ListGroup>
                      {
                        this.props.data.data.map((item, index)=>
                          <ListGroupItem key={index}>
                            <Label check>
                              <Input type="checkbox" checked={this._itemChecked(item.id)} value="true" onChange={e => this._handleOnCheckedChange(e, item.id)}/>
                              <span style={{top:1, position:'relative'}}> {item.name}</span>
                            </Label>
                          </ListGroupItem>
                        )
                      }
                    </ListGroup>
                  ): <AlertNotFound/>
                )
              }
            </Loading>
          </div>
          {this.props.data.total > 0?(
            <div style={{overflow: 'auto', width: '100%', paddingTop: '.75rem', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
              <Paginations
                isLoading={this.props.isLoading}
                size="sm"
                maxPage={4}
                total={this.props.data.total}
                currentPage={this.props.data.current_page}
                lastPage={this.props.data.last_page}
                perPage={this.props.data.per_page}
                handleGotoCallback={this._handleGotoPage}
              />
            </div>
          ): null}
        </ModalBody>
        <ModalFooter>
          <Button
            size="sm"
            color="primary"
            onClick={this._handleSubmitCollection}
          >
            Save
          </Button>{' '}
          <Button
            size="sm"
            color="secondary"
            onClick={this.props.toggle}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    )
  }
}
export default ModalBrowseCollection;