import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import BadgeThin from '../../../../../components/Badges/BadgeThin';

const Div = styled.div`
  position: absolute;
  line-height: 0;
`

const BadgeStorage = ({storageName}) => (
  <Div>
    <BadgeThin>{storageName}</BadgeThin>
  </Div>
)

BadgeStorage.propTypes = {
  storageName: propTypes.string.isRequired
}

BadgeStorage.defaultProps = {

}

export default BadgeStorage;