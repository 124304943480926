import React,{Component} from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import {
  Badge,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
  Card
} from 'reactstrap';

import classnames from 'classnames';

import qs from 'query-string';

import {
  getDetailUserSell,
  updateSellRequest,
  deleteSellRequest,
  addSellImageApi,
  addAndUploadSellImage,
  updateSellImage,
  modifyAndUploadSellImage,
  deleteSellImageApi,
  getUserSellJournal
} from '../../actions/sellRequestAction';
import { uploadImage, deleteImage } from "../../actions/imageUploadAction";
import { attachRack, detachRack } from "../../actions/rackAction";
import { forceHolidayMode } from '../../actions/userSettingAction';
import SellRequestContainer from './sellRequestDetail/SellRequestContainer';
import ModalViewLogs from './sellRequestDetail/ModalViewLogs';
import UserContainer from './sellRequestDetail/UserContainer';
import ProductContainer from './sellRequestDetail/ProductContainer';
import privateView from '../../components/hocs/privateView';
import Icon from '../../components/Icons/FontAwesomeIcon';
import { ImagesCompressor, createUploadFileForm } from '../../utils/imageHelper';
import { getPathS3 } from '../../utils/AWS';
import {
	PROCESS_IMAGE_SELL_MODIFIED_STATE,
  PROCESS_IMAGE_SELL_DELETED_STATE
} from '../../constants';
import Analytics from "../../services/Analytics";

const HeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  /* justify-content: center; */
  flex-wrap: wrap;
`;

const HeaderItem = styled.div`
  margin-bottom: .5rem;
  margin-right: ${props => props.special? "0px":".5rem"};
  font-style: ${props => props.special? "italic":"normal"};
  display: ${props => props.hidden? "none":"block"};
  padding: ${props => props.special? "0rem 0.5rem": "0rem"};
`;

const Header = props => (
  <div>
    <div style={{display: 'flex', alignItems: 'center'}}>
      <h5>{props.displayName}</h5>
      {
        props.category && (
          <Badge color="primary" className="text-uppercase ml-1 mb-2">
            <Icon iconType="tags"/> {props.category}
          </Badge>
        )
      }
    </div>
    <HeaderWrapper>
      <HeaderItem>
        <Icon iconType="calendar"/> {props.createdAt}
      </HeaderItem>
      <HeaderItem>
        <Icon iconType="user"/> {props.userEmail}
      </HeaderItem>
      <HeaderItem
        special
        hidden={!props.roles.includes('trusted_seller')}
      >
        <Icon iconType="check-circle"/> Trusted.
      </HeaderItem>
    </HeaderWrapper>
  </div>
)

class SellRequestDetail extends Component {
	constructor(props) {
		super(props);
    const DEFAULT_STATE = {
      activeTab:'sellRequestDetail',
      id: parseInt(this.props.match.params.id)
    }
    const { id, activeTab } = Object.assign(DEFAULT_STATE,qs.parse(props.location.search));
		this.state={
      id,
      activeTab,
      fetchWhenLoad: true,
      _imageChanged:false,
      _newImagesQueue: [],
      _existingImageQueue:[],
      _images:[],
      _indexImage: null,
      _imageIdProcessed: null,
      _isUploadImageCollapse: false,
      _isViewLogsModalOpen: false
		}
    this.toggleTab = this.toggleTab.bind(this);
    this._handleUpdate = this._handleUpdate.bind(this);
    this._handleForceHolidayMode = this._handleForceHolidayMode.bind(this);
    this._handleDelete = this._handleDelete.bind(this);
    this._handleToggleUploadImageCollapse = this._handleToggleUploadImageCollapse.bind(this);
    this._handleDropMultipleFiles = this._handleDropMultipleFiles.bind(this);
    this._handleDeleteDraftImage = this._handleDeleteDraftImage.bind(this);
    this._handleStartUploadClick = this._handleStartUploadClick.bind(this);
    this._handleRemoveImage = this._handleRemoveImage.bind(this);
  }

	componentDidMount() {
    const { email } = this.props.injectedProps;
		this.props.getDetail(this.state.id);
    Analytics.recordPageView('sell_request_detail', email, {
      url: window.location.href,
      location: this.props.location,
      match: this.props.match
    });
  }

  componentDidUpdate(prevProps, prevState){
    if(!this.props.sellRequest.isFetch && prevProps.sellRequest.isFetch!==this.props.sellRequest.isFetch){
      const { detail, error } = this.props.sellRequest;
      if(error){
        this.props.history.replace(`/${error.status_code}`);
      }
      else{
        this.setState({
          fetchWhenLoad: false,
          _images: detail.user_sell_images.map(img =>({ ...img, previewURL: img.signed_url || img.URL })),
        })
      }
    }
    if(prevProps.imageUpload.isUpload && prevProps.imageUpload.isUpload !== this.props.imageUpload.isUpload){
      const { success, error } = this.props.imageUpload;
      const { imageProcessedResponse, detail } = this.props.sellRequest;
      if(!error){
        const { data } = success.data;
        const { _newImagesQueue, _imageIdProcessed, _images, _indexImage, _imageChanged, id } = this.state;
        const filtered = _images.filter(img => img.id=== _imageIdProcessed);
        const payload = {
          user_sell_id:id,
          URL:data[0].url,
        }
        if(_imageChanged && filtered.length>0){
          this.props.modifyImage(_imageIdProcessed, filtered[0], payload, null)
        }
        else{
          this.props.newImage(_newImagesQueue[_indexImage], payload, null);
        }
      }
      else{
        this.setState({
          _isUploadMultipleFiles: false,
        });
      }
    }
    if(prevProps.imageUpload.isDeleteProgress && prevProps.imageUpload.isDeleteProgress !== this.props.imageUpload.isDeleteProgress) {
      const { _imageIdProcessed, _images } = this.state;
      const filtered = _images.filter(img => img.id=== _imageIdProcessed);
      if(filtered.length>0)
        this.props.deleteSellImage(filtered[0]);
    }
    if(this.state._imageIdProcessed !== null
      && !this.props.sellRequest.isImageProcessLoading
      && prevProps.sellRequest.isImageProcessLoading !== this.props.sellRequest.isImageProcessLoading){
      const processType = prevProps.sellRequest.imageProcessedState;
      if(processType === PROCESS_IMAGE_SELL_MODIFIED_STATE){
        if(this.state._isUploadMultipleFiles){
          const { _newImagesQueue, _indexImage } = this.state;
          const { imageProcessedResponse, detail } = this.props.sellRequest;
          if(imageProcessedResponse && !imageProcessedResponse.error){
            const hasQueue = _indexImage + 1 < _newImagesQueue.length;
            const newImagesQueue = _newImagesQueue.map((item, index) =>
              index === _indexImage? { ...imageProcessedResponse }: item
            )
            if(hasQueue)
              newImagesQueue[_indexImage + 1].isProcessed = true;
            this.setState({
              _indexImage: hasQueue? _indexImage + 1 : null,
              _imageIdProcessed: hasQueue? newImagesQueue[_indexImage + 1] : null,
              _newImagesQueue: hasQueue? newImagesQueue : [],
              _images: hasQueue?this.state._images : [ ...this.state._images, ...newImagesQueue ],
              _isUploadMultipleFiles: hasQueue,
              _isUploadImageCollapse: hasQueue
            }, ()=>{
              if(hasQueue){
                const { _indexImage, _newImagesQueue, id } = this.state;
                const tempPayload = createUploadFileForm(_newImagesQueue[_indexImage].fileInput, false, {name: "seller-upload-products/"+id, bucket_type: "assets"});
                this.props.uploadImage(tempPayload, _newImagesQueue[_indexImage]);
              }
            })
          }
          else{
            const images = _newImagesQueue.map((item, index) =>
              index === _indexImage? { ...imageProcessedResponse }: item
            );
            const completedImages = images.filter( item => item.statusCode===200);
            const failedAndQueueImages = images.filter( item => item.statusCode!==200);
            this.setState({
              _indexImage: null,
              _imageIdProcessed: null,
              _newImagesQueue: failedAndQueueImages,
              _images: [ ...this.state._images, ...completedImages ],
              _isUploadMultipleFiles: false
            })

          }
        }
        else{
          const { imageProcessedResponse, detail } = this.props.sellRequest;
          const { _existingImageQueue } = this.state;
          if(imageProcessedResponse && !imageProcessedResponse.error){
            if(_existingImageQueue.length>0){
              const nextId = _existingImageQueue[0].id;
              this.setState({
                _imageIdProcessed: nextId,
                _existingImageQueue: _existingImageQueue.filter(item => item.id!==nextId),
                _images: this.state._images
                  .map(img =>img.id===imageProcessedResponse.id?{ ...imageProcessedResponse } : img)
                  .map(img => img.id === nextId? { ...img, isProcessed: true } : img)
              }, ()=>{
                const img = this.state._images.find(img=>img.id === nextId);
                if(img.isDeleted){
                  const payload = {
                    bucket_type : 'assets',
                    name : getPathS3(img.URL)
                  };
                  this.props.deleteImage(payload);
                }
                else{
                  const payload = {
                    user_sell_id: this.state.id,
                    URL:img.URL,
                  }
                  this.props.modifyImage(img.id, img, payload, null)
                }
              });
            }
            else{
              this.setState({
                _imageIdProcessed: null,
                _images: this.state._images.map(img => img.id === imageProcessedResponse.id ? {...imageProcessedResponse}: img)
              });
            }
          }
          else if(imageProcessedResponse && imageProcessedResponse.error) {
            this.setState({
              _imageIdProcessed: null,
              _existingImageQueue: [],
              _images: this.state._images.map(img => {
                if(img.id === imageProcessedResponse.id)
                  return { ...imageProcessedResponse }
                return { ...img, isProcessed: false }
              })
            });
          }
        }
      }
      else if(processType === PROCESS_IMAGE_SELL_DELETED_STATE){
        const { imageProcessedResponse, detail } = this.props.sellRequest;
        const { _existingImageQueue } = this.state;
        if(imageProcessedResponse && !imageProcessedResponse.error){
          if(_existingImageQueue.length>0){
            const nextId = _existingImageQueue[0].id;
            this.setState({
              _imageIdProcessed: nextId,
              _existingImageQueue: _existingImageQueue.filter(item => item.id!==nextId),
              _images: this.state._images
                .filter(img => img.id !== imageProcessedResponse.id)
                .map(img =>img.id===nextId?{ ...img, isProcessed: true } : img)
            }, ()=>{
              const img = this.state._images.find(img=>img.id === nextId);
              if(img.isDeleted){
                const payload = {
                  bucket_type : 'assets',
                  name : getPathS3(img.URL)
                };
                this.props.deleteImage(payload);
              }
              else{
                const payload = {
                  user_sell_id: this.state.id,
                  URL:img.URL,
                }
                this.props.modifyImage(img.id, img, payload, null)
              }
            });
          }
          else{
            this.setState({
              _imageIdProcessed: null,
              _images: this.state._images.filter(img => img.id !== imageProcessedResponse.id)
            });
          }
        }
        else if(imageProcessedResponse && imageProcessedResponse.error) {
          this.setState({
            _imageIdProcessed: null,
            _existingImageQueue: [],
            _images: this.state._images.map(img => {
              if(img.id === imageProcessedResponse.id){
                return { ...imageProcessedResponse }
              }
              return { ...img, isProcessed: false, isDeleted: false }
            })
          });

        }
      }
    }
    if(!prevState._isViewLogsModalOpen && prevState._isViewLogsModalOpen !== this.state._isViewLogsModalOpen){
      this.props.getUserSellJournal(this.state.id);
    }
  }

	toggleTab(tab) {
		if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
      const { pathname } = this.props.location;
      const search = qs.stringify({
        activeTab: tab
      });
      this.props.history.push({ pathname, search })
		}
  }

  _getActiveTab(){
    const { activeTab }  = this.state;
    switch(activeTab){
      case "sellerDetail": return "sellerDetail";
      case "productDetail": return "productDetail";
      default: return "sellRequestDetail";
    }
  }

  _isLoading(){
    return this.state.fetchWhenLoad
  }

  _handleUpdate(payload){
    this.props.updateSellRequest(this.state.id, payload);
  }

  _handleForceHolidayMode(payload){
    this.props.forceHolidayMode(payload)
  }

  _handleDelete(id){
    this.props.deleteSellRequest(id);
  }

  _handleUploadImage(files, evt) {
    const { user_id } = this.props.sellRequest.detail;
    const { _imageIdProcessed } = this.state;
    const inOtherProcess = _imageIdProcessed !== null;
    const _images = [ ...this.state._images ];
    const imageId = Number(evt.target.id.split('-')[1]);
    const image = _images.find(item => item.id === imageId);
    image.fileInput = files[0];
    image.previewURL = files[0].preview;
    if(!inOtherProcess){
      image.isProcessed = true;
      this.setState({
        _imageChanged:true,
        _image: _images.map(item => item.id === imageId? { ...image, error: null }: {...item}),
        _imageIdProcessed: image.id
      },()=>{
        const tempPayload = createUploadFileForm(image.fileInput, false, {name: "seller-upload-products/"+this.state.id, bucket_type: "assets"});
        this.props.uploadImage(tempPayload, image);
      });
    }
    else{
      this.setState({
        _image: _images.map(item => item.id === imageId? { ...image, error: null }: {...item}),
        _existingImageQueue: [...this.state._existingImageQueue, { ...image }]
      });
    }
  }

  _handleRemoveImage(evt, indexImg){
    evt.preventDefault();
    const { _imageIdProcessed } = this.state;
    const inOtherProcess = _imageIdProcessed !== null;
    const _images = [ ...this.state._images ];
    if(!inOtherProcess){
      _images[indexImg] ={ ..._images[indexImg], isDeleted: true, isProcessed: true, error: null }
      this.setState({
        _imageIdProcessed: _images[indexImg].id,
        _images
      }, ()=>{
        const _images = [ ...this.state._images ]
        const img = { ..._images[indexImg] };
        const payload = {
          bucket_type : 'assets',
          name : getPathS3(img.URL)
        };
        this.props.deleteImage(payload);
      })
    }
    else{
      _images[indexImg] ={ ..._images[indexImg], isDeleted: true, isProcessed: false, error: null }
      this.setState({
        _images,
        _existingImageQueue: [ ...this.state._existingImageQueue, { ..._images[indexImg ]}]
      });
    }
  }

  _handleToggleUploadImageCollapse(){
    this.setState({
      _isUploadImageCollapse: !this.state._isUploadImageCollapse
    })
  }

  _handleDropMultipleFiles(dataAccepted, dataRejected, evt){
    const { _newImagesQueue } = this.state;
    const droppedFiles = dataAccepted.map((file, index) => ({
      id: `new-${_newImagesQueue.length + index}`,
      fileInput: file,
      previewURL: file.preview,
      isProcessed: false,
      isUploaded: false
    }));
    this.setState({
      _newImagesQueue: [ ..._newImagesQueue, ...droppedFiles  ]
    })
  }

  _handleStartUploadClick(){
    if(this.state._newImagesQueue.length>0){
      const { id, user_id } = this.props.sellRequest.detail;
      const draftImages = [ ...this.state._newImagesQueue ];
      draftImages[0].isProcessed = true;
      this.setState({
        _indexImage: 0,
        _imageIdProcessed:draftImages[0].id,
        _isUploadMultipleFiles: true,
        _newImagesQueue: [...draftImages]
      }, ()=>{
        const { _newImagesQueue } = this.state;
        const tempPayload = createUploadFileForm(_newImagesQueue[0].fileInput, false, {name: "seller-upload-products/"+id, bucket_type: "assets"});
        this.props.uploadImage(tempPayload, _newImagesQueue[0]);
      });
    }
  }

  _handleDeleteDraftImage(evt, id){
    evt.preventDefault();
    const { _newImagesQueue } = this.state;
    this.setState({
      _newImagesQueue: _newImagesQueue.filter(img => img.id !== id).map((img, index) => ({...img, id: `new-${index}`}))
    });
  }

  _renderContent(){
    const { user, product_variant, size, ...sellRequest } = this.props.sellRequest.detail;
    return(
      <Row>
        <ModalViewLogs
          isOpen={this.state._isViewLogsModalOpen}
          toggle={() => this.setState({_isViewLogsModalOpen: !this.state._isViewLogsModalOpen})}
          isLoading={this.props.sellRequest.journalList.isFetch}
          data={this.props.sellRequest.journalList.data}
          statusCode={this.props.sellRequest.journalList.status_code}
          from={this.props.sellRequest.journalList.from}
          to={this.props.sellRequest.journalList.to}
          currentPage={this.props.sellRequest.journalList.current_page}
          perPage={this.props.sellRequest.journalList.per_page}
          lastPage={this.props.sellRequest.journalList.last_page}
          total={this.props.sellRequest.journalList.total}
          onNextPage={(query) => this.props.getUserSellJournal(this.state.id, query)}
        />
        <Col xs="12">
          {
            !this._isLoading()?
            <Header
              category={product_variant.product.category && product_variant.product.category.name}
              displayName={product_variant.display_name}
              createdAt={sellRequest.created_at}
              userEmail={user.email}
              roles={user.roles.map(item=> item.name)}
            />
            :null
          }
        </Col>
        <Col xs={12}>
          <Nav className="nav-tabs--custom" tabs>
            <NavItem>
              <NavLink
                className={classnames({ active: this._getActiveTab() === 'sellRequestDetail' })}
                onClick={() => { this.toggleTab('sellRequestDetail'); }}
              >
                {sellRequest?.consignment ? "Consignment" : "Sell"} Request
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: this._getActiveTab() === 'productDetail' })}
                onClick={() => { this.toggleTab('productDetail'); }}
              >
                Product Detail
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: this._getActiveTab() === 'sellerDetail' })}
                onClick={() => { this.toggleTab('sellerDetail'); }}
              >
                Seller Detail
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent className="tab-content--custom" activeTab={this._getActiveTab()} style={{marginBottom:25}}>
            <TabPane className="tab-content--custom__tab-pane" tabId="sellRequestDetail">
              <SellRequestContainer
                sellRequestId={this.state.id}
                brand={product_variant && product_variant.product.brand? product_variant.product.brand: null}
                categoryListing={product_variant && product_variant.product.category? product_variant.product.category.name.toUpperCase(): 'SNEAKERS'}
                isViewLogsOpen={this.state._isViewLogsModalOpen}
                onViewLogsToggle={() => this.setState({_isViewLogsModalOpen: !this.state._isViewLogsModalOpen})}
                isLoading={this._isLoading()}
                user={user}
                userSetting={this.props.userSetting}
                sellRequest={{...sellRequest}}
                rackDetail={this.props.rackDetail}
                size={size}
                images={this.state._images}
                draftImages={this.state._newImagesQueue}
                dataSellRequest={this.props.sellRequest}
                isUploadImageLoading={this.state._isUploadMultipleFiles}
                isUploadImageCollapse={this.state._isUploadImageCollapse}
                attachRack={this.props.attachRack}
                detachRack={this.props.detachRack}
                handleUpdateSellRequest={this._handleUpdate}
                handleForceHolidayMode={this._handleForceHolidayMode}
                handleDeleteSellRequest={this._handleDelete}
                handleRemoveImage={this._handleRemoveImage}
                handleChangeImage={this._handleUploadImage.bind(this)}
                handleToggleUploadImageCollapse={this._handleToggleUploadImageCollapse}
                handleDropMultipleFiles={this._handleDropMultipleFiles}
                handleDeleteDraftImage={this._handleDeleteDraftImage}
                handleStartUploadClick={this._handleStartUploadClick}
              />
            </TabPane>
            <TabPane className="tab-content--custom__tab-pane" tabId="productDetail">
              <ProductContainer
                isLoading={this._isLoading()}
                productVariant={product_variant}/>
            </TabPane>
            <TabPane className="tab-content--custom__tab-pane" tabId="sellerDetail">
              <UserContainer
                isLoading={this._isLoading()}
                user={user}
                sellerVerificationList={user&&user.seller_verification_info}
              />
            </TabPane>
          </TabContent>
        </Col>
      </Row>
    )
  }
	render(){
    return(
      <div className="animated fadeIn">
        <Row>
          <Card body>
            {this._renderContent()}
          </Card>
        </Row>
      </div>
    )
  }

}

const mapStateToProps=({sellRequest, rack, auth: { isFetch, isLogged, roles, id, email }, user, imageUpload})=> {
	return {
    sellRequest,
    rackDetail: rack.details,
    userSetting: user.user_setting,
    guardData: { isFetch, isLogged, roles, id, email },
    imageUpload
	}
}

const mapDispatchToProps=(dispatch)=>{
	return {
		deleteSellRequest: id => dispatch(deleteSellRequest(id)),
		forceHolidayMode: payload => dispatch(forceHolidayMode(payload)),
		updateSellRequest: (id, payload) => dispatch(updateSellRequest(id, payload)),
    getDetail:(id)=>dispatch(getDetailUserSell(id)),
    deleteSellImage: (image) => dispatch(deleteSellImageApi(image)),
    newImage: (image, payload, result) => dispatch(addSellImageApi(image, payload, result)),
    modifyImage: (id, image, payload, result) => dispatch(updateSellImage(id, image, payload, result)),
    uploadImage: (payload, rawImages) => dispatch(uploadImage(payload, rawImages)),
    deleteImage: (payload) => dispatch(deleteImage(payload)),
    getUserSellJournal: (id, q) => dispatch(getUserSellJournal(id, q)),
    attachRack: (id, p) => dispatch(attachRack(id, p)),
    detachRack: (id, p) => dispatch(detachRack(id, p))
	}
}

const enhance = connect(mapStateToProps,mapDispatchToProps);

export default enhance(privateView(SellRequestDetail, ['supervisor','sale_supervisor','cashier']));
