import React from 'react';
import { connect } from 'react-redux';
import {
  Row,
  Col,
  Card,
  CardBody,
  CardText,
} from 'reactstrap';
import { getRaffleList, getRaffleGroups } from '../../actions/raffleAction';
import privateView from '../../components/hocs/privateView';
import { CardHeaderWithToolbars, Title } from '../../components/Card/CardHeaderWithToolbars';
import { FontAwesomeIcon } from '../../components/Icons';
import Paginations from '../../components/Paginations';
import PaginationSummaries from '../../components/Paginations/PaginationSummaries';
import { Tbl, TblHead, TblBody } from '../../components/Tbl';
import { generateNumber } from '../../utils';
import { Toolbars, Filters } from '../Raffles/Filters'
import { DEFAULT_SORT_BY, DEFAULT_PER_PAGE } from '../../constants/settings';
import RowItem from '../RaffleGroup/raffleGroupIndex/RowItem';
import Analytics from "../../services/Analytics";

const sortOptions = [{
    value:"" , label:"Default"
  },
  {
    value:"updatedAt_desc" , label:"Last Updated"
  },
  {
    value:"createdAt_desc" , label:"Newest"
  },
  {
    value:"createdAt_asc" , label:"Older"
  },
  {
    value:"title_asc" , label:"A-Z"
  },
  {
    value:"title_desc" , label:"Z-A"
  },
  {
    value:"active_desc" , label:"Active"
  },
  {
    value:"active_asc" , label:"Inactive"
}];

const dataHeader=[
	'No.', 'Name', 'Active', 'Updated', 'Action'
];

const defaultParams = {
  keyword: '',
  sort_by : DEFAULT_SORT_BY,
  per_page : DEFAULT_PER_PAGE
}
class RaffleGroupIndex extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      _params:{
        ...defaultParams
      },
      _filterOpen: false,
      _isModalUpdateOpen: false,
      _id: null
    }
  }

  componentDidMount(){
    const { email } = this.props.injectedProps;
    this._getList(this.state._params);
    Analytics.recordPageView('raffle_group_list', email, {
      url: window.location.href,
      location: this.props.location,
      match: this.props.match
    });
  }

  _getList(rawParams = {}){
    const params = Object.keys(rawParams)
    .filter(key => rawParams[key]!='')
    .reduce((obj, current)=> ({...obj, [current]: rawParams[current]}), []);
    this.props.getList({params});
  }

  _handleRefresh = (e) => {
    e.preventDefault();
    this._refreshList();
  }

  _refreshList = () => {
    const { _page, ..._params } = this.state._params;
    this.setState({
      _params
    },()=>this._getList(this.state._params));
  }

  _handleToggleFilterPanel = () =>{
    this.setState({_filterOpen: !this.state._filterOpen})
  }

  _handleApplyFilter = p => {
    const { _params } = this.state;
    this.setState({
      _params: {
        ..._params,
        ...p,
        page: 1
      }
    }, () => {
      this._getList(this.state._params)
    })
  }

  _handleResetFilter = (e) =>{
    e.preventDefault();
    this.setState({
      _params:{
        ...defaultParams
      },
      _filterOpen: false
    }, () => {
      this._getList(this.state._params)
    })
  }

	_handleGoto = page =>{
    this.setState({
      _params: {
        ...this.state._params,
        page
      }
    }, ()=> this._getList(this.state._params) );
  }

  render(){
    return(
      <div className="animated fadeIn">
        <Row>
          <Col xs={12}>
            <Card>
              <CardHeaderWithToolbars>
                <Title>
                  <FontAwesomeIcon iconType="align-justify"/>{' '}
                  <span>Raffle Group List</span>
                </Title>
                <Toolbars
                  isOpen={this.state._filterOpen}
                  onToggleFilter={this._handleToggleFilterPanel}
                  onRefreshList={this._handleRefresh}
                  onResetFilter={this._handleResetFilter}
                  redirect='/raffle_group/create'
                />
              </CardHeaderWithToolbars>
              <Filters
                {...this.state._params}
                title="Search raffle groups name"
                sortOptions={sortOptions}
                onApplyFilter={this._handleApplyFilter}
                isOpen={this.state._filterOpen}
              />
              <CardBody>
                <CardText>
                  In this section will display our raffle groups.
                </CardText>
                <PaginationSummaries
                  from={this.props.dataRaffleGroup.data.from}
                  to={this.props.dataRaffleGroup.data.to}
                  total={this.props.dataRaffleGroup.data.total}
                />
                <div className="table-responsive" ref={tableRef => this.tableRef = tableRef}>
                  <Tbl>
                    <TblHead
                      dataHeader={dataHeader}
                    />
                    <TblBody
                      isLoad={this.props.dataRaffleGroup.isFetch}
                      hasRow={this.props.dataRaffleGroup.status_code===200&&this.props.dataRaffleGroup.data.data.length>0}
                      columnCount={dataHeader.length}
                      renderChildren={() =>{
                        const { current_page, per_page, data } =  this.props.dataRaffleGroup.data;
                        return data.map((item, key)=> {
                          const rowNumber = generateNumber(per_page,current_page,key);
                          return(
                            <RowItem
                              key={item.id}
                              rowNumber={rowNumber}
                              {...item}
                            />
                          )
                        })
                      }}
                    />
                  </Tbl>
                </div>
                { this.props.dataRaffleGroup.status_code===200 && (
                  <Paginations
                    size="sm"
                    lastPage={this.props.dataRaffleGroup.data.last_page}
                    perPage={this.props.dataRaffleGroup.data.per_page}
                    total={this.props.dataRaffleGroup.data.total}
                    maxPage={8}
                    path={this.props.dataRaffleGroup.data.path}
                    currentPage={this.props.dataRaffleGroup.data.current_page}
                    prevPageUrl={this.props.dataRaffleGroup.data.prev_page_url}
                    nextPageUrl={this.props.dataRaffleGroup.data.next_page_url}
                    handleGotoCallback={this._handleGoto}
                    onCurrentPageChanged={() => {
                      window.scrollTo({
                        top: this.tableRef.offsetTop+25,
                        behavior: 'smooth'
                      })
                    }}
                  />
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    )
  }
}

const mapStateToProps= ({ raffle, auth: { isFetch, roles, isLogged, id, email } }) => ({
  guardData: { isFetch, roles, isLogged, id, email },
  dataRaffleGroup: raffle.raffleGroups
});

const mapDispatchToProps = (dispatch) => ({
  getList:(query) => dispatch(getRaffleGroups(query)),
});

const enhance = connect(mapStateToProps, mapDispatchToProps);

export default enhance(privateView(RaffleGroupIndex, ['supervisor', 'sale_supervisor', 'customer_service']));