import React from 'react';
import { Card, Collapse } from 'reactstrap';
import propTypes from 'prop-types';
import SearchForm from './filterPanel/SearchForm';
import SortForm from './filterPanel/SortForm';
import FilterForm from './filterPanel/FilterForm';
import { sellerVerificationStatusOptions } from '../../../constants/kickavenue';

class FilterPanel extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      keyword: props.keyword,
      status: props.status,
      per_page: props.per_page,
      sort_by: props.sort_by,
    }
    this._handleOnChangeInput = this._handleOnChangeInput.bind(this);
    this._handleApplyFilterButtonClick = this._handleApplyFilterButtonClick.bind(this);
    this._handleOnChangeInputAndApply = this._handleOnChangeInputAndApply.bind(this);
    this._handleStatusesChangeInput = this._handleStatusesChangeInput.bind(this);
    this._handleSearchButtonClick = this._handleApplyFilterButtonClick.bind(this);
    this._isCheckedStatus = this._isCheckedStatus.bind(this);
  }

  _handleOnChangeInput(e){
    const lastState = {...this.state};
    lastState[e.target.name] = e.target.value;
    this.setState({...lastState});
  }

  _isCheckedStatus(val) { 
    if (val === 'all') {
      return this.state.status.split(',').length === sellerVerificationStatusOptions.length
    } else {
      let arrStatus = this.state.status.split(',');
      let selectedStatus = arrStatus.find((item) => item === val);
      return val === 'default' ? this.state.status === '' : selectedStatus
    }
  }

  _handleStatusesChangeInput(e) {
    const lastState = {...this.state};
    let status = e.target.value
    let statuses = this.state.status.split(',')
    let arrStatus = [];
    if (this._isCheckedStatus(status)) {
      lastState['status'] = statuses.filter(itm => itm !== status).join(',')
    } else if (status === 'default' || status === 'all') {
      if (status === 'default') {
        lastState['status'] = ''
      } else {
        arrStatus = [...sellerVerificationStatusOptions.map(v => v.value)]
        lastState['status'] = arrStatus.join(',')
      } 
    } else {
      if (this._isCheckedStatus('default')) {
        lastState['status'] = status
      } else if (status !== 'all') {
        arrStatus = [status, ...statuses]
        lastState['status'] = arrStatus.join(',')
      }
    }
    this.setState({...lastState});
  }

  _handleOnChangeInputAndApply(e){
    const lastState = {...this.state};
    lastState[e.target.name] = e.target.value;
    this.setState({...lastState}, ()=> this._handleSubmitFilter());
  }

  _handleApplyFilterButtonClick(e){
    e.preventDefault();
    this._handleSubmitFilter();
  }

  _handleSubmitFilter(){
    const {
      keyword,
      status,
      per_page,
      sort_by
    } = this.state;
    this.props.onApplyFilter({
      keyword,
      status,
      per_page,
      sort_by
    });
  }

  componentWillReceiveProps(nextProps){
    if(nextProps.isReset && this.props.isReset!==nextProps.isReset){
      const {
        keyword,
        status,
        per_page,
        sort_by
      } = nextProps;
      this.setState({
        keyword,
        status,
        per_page,
        sort_by
      })
      this.props.onResetFilterCallback();
    }
  }

  render(){

    console.log('props', this.state.status)
    return(
      <Collapse isOpen={this.props.isOpen}>
        <Card body className="filter-container__card-body">
          <SearchForm
            keyword = {this.state.keyword}
            onInputChange = {this._handleOnChangeInput}
            onSubmit = {this._handleSearchButtonClick}
          />
          <FilterForm
            status={this.state.status}
            onInputChange = {this._handleOnChangeInput}
            onCheckedChange = {this._handleOnChangeInput}
            onStatusesInputChange = {this._handleStatusesChangeInput}
            isCheckedStatus = {this._isCheckedStatus}
            onSubmit={this._handleApplyFilterButtonClick}
          />
          <hr/>
          <SortForm
            per_page={this.state.per_page}
            sort_by={this.state.sort_by}
            onInputChange={this._handleOnChangeInputAndApply}
          />
        </Card>
      </Collapse>
    )
  }
}


export default FilterPanel;

FilterPanel.propTypes= {
  keyword: propTypes.string,
  status: propTypes.string,
  per_page: propTypes.oneOfType([propTypes.number, propTypes.string]),
  sort_by: propTypes.string,
  isOpen: propTypes.bool.isRequired,
  onResetFilterCallback: propTypes.func,
  isReset: propTypes.bool
}

FilterPanel.defaultProps= {
  keyword: '',
  per_page: 15,
  sort_by: '',
  status: '',
}