import React from 'react';
import propTypes from 'prop-types';
import { SimpleLineIcon } from '../Icons';
import { Alert } from 'reactstrap';
import styled from 'styled-components';

export const AlertCustom = styled(Alert)`
  text-align: center;
  position: relative;
  &.alert-dismissible .close{
    position: absolute;
    top: 0rem;
    right: 0rem;
  }
`

const AlertNotFound = ({ title, message, renderMessage, toggle, isOpen }) =>(
  <AlertCustom color="danger" isOpen={isOpen} toggle={toggle}>
    <strong><SimpleLineIcon iconType="ghost"/> { title }</strong> { renderMessage? renderMessage(): message }
  </AlertCustom>
)

export default AlertNotFound;

AlertNotFound.propTypes = {
  isOpen: propTypes.bool,
  toggle: propTypes.func,
  title: propTypes.string,
  message: propTypes.string,
  renderMessage: propTypes.func
}

AlertNotFound.defaultProps = {
  isOpen: true,
  title: 'Oh snap.',
  message: 'Data not found!'
}