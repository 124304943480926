import React from 'react';
import propTypes from 'prop-types';

import Badge from '../../../components/Badges/BadgeThin';
import FontAwesomeIcon from '../../../components/Icons/FontAwesomeIcon';
import Paragraph from '../../../styled/Typograph/Paragraph';
import SmallInline from '../../../styled/Typograph/Small/SmallInline';
// import ButtonStarred from './ButtonStarred';

const OrderSummariesColumn = props => {
  const {
    id,
    invoiceNumber,
    orderDate,
    buyerEmail,
    size,
    displayName,
    SKU,
    quantity,
    colour,
    preOrder,
    preVerified,
    consignmentId,
    sneakersCondition,
    category,
    unreadNoteCount,
    legitCheckLogs = [],
    isBuyerFirstOrder,
  } = props;
  return (
    <Paragraph>
      {unreadNoteCount? (
        <span style={{fontSize: '.65rem'}} className="mr-1 text-danger">
          <FontAwesomeIcon iconType="circle"/>
        </span>
      ):null}
      <span className="text-muted">{ `#${id}` }</span> &nbsp;{ invoiceNumber } {preOrder?<>&nbsp;<Badge color="primary">PREORDER</Badge></>:''}{preVerified?<>&nbsp;<Badge color="warning" className="text-uppercase"><FontAwesomeIcon iconType='bolt' /> Express Shipping</Badge></> : null}<br/>
      { displayName }<br/>
      <SmallInline>({ SKU })</SmallInline>
      <SmallInline>(Quantity: { quantity })</SmallInline>
      <SmallInline className="text-muted">{ colour }</SmallInline><br/>
      <Badge
        color="primary"
        className="text-uppercase mr-1"
      >
        <FontAwesomeIcon iconType="tags"/> {category}
      </Badge>
      <Badge
        color="success"
        className="text-uppercase mr-1"
      >
        <FontAwesomeIcon iconType="tag"/> {sneakersCondition}
      </Badge>
      <Badge
        color="success"
        className="text-uppercase mr-1"
      >
        SIZE: {size}
      </Badge>
      {
        consignmentId && (
          <Badge color="warning" className="mr-1">
            CG-ID: {consignmentId}
          </Badge>
        )
      }
      {isBuyerFirstOrder ? (
        <Badge color="danger" className="mr-1" style={{ backgroundColor: 'orange' }}>
          FIRST ORDER
        </Badge>
      ) : null}
      <br/>
      <SmallInline>
        {/* <ButtonStarred title={marked? "Remove mark!": "Mark!"} value={marked} onClick={() => props.onMarkToggle(!marked)}/> */}
        <span className="mr-1">
          <FontAwesomeIcon iconType="calendar" className="mr-1"/>{ orderDate }
        </span>
        - {buyerEmail} <b>(Buyer)</b>
      </SmallInline>
      <br/>
      {
        legitCheckLogs.map((item, index) => {
          return(
            <>
              <SmallInline key={index}>
                <span className="mr-1">
                  <FontAwesomeIcon iconType="calendar" className="mr-1"/>{ item.created_at }
                </span>
                - {item.user.email} <b>(LC Approval)</b>
                
              </SmallInline>
              <br />
            </>
          )
        })
      }
    </Paragraph>
  )
}

export default OrderSummariesColumn;

OrderSummariesColumn.defaultProps = {
  size: '-',
  SKU: '-',
  orderDate: '-',
  preOrder: false
}

OrderSummariesColumn.propTypes = {
  id: propTypes.number.isRequired,
  invoiceNumber: propTypes.string.isRequired,
  buyerEmail: propTypes.string,
  displayName: propTypes.string,
  SKU: propTypes.string,
  colour: propTypes.string,
  size: propTypes.string,
  orderDate: propTypes.string,
  preOrder: propTypes.oneOfType([propTypes.number, propTypes.bool]),
  consignmentId: propTypes.string,
}