import React, { PureComponent as Component } from 'react';
import {
  Card,
  CardText,
  Row,
  Col
} from 'reactstrap';
import propTypes from 'prop-types';
import { UserDetail, ShippingDetail } from '../Users';
import CourierDetail from './orderShipping/CourierDetail';
import TrackingDetail from './orderShipping/TrackingDetail';
export default class OrderShipping extends Component{
  render(){
    const { isLoading, user, shipping, shippingUpdate } = this.props;
    return(
      <Card body className={this.props.className}>
        <CardText>Your order shipping details.</CardText>
        <Row>
          <Col xs={12} md={6}>
            <ShippingDetail
              isLoading={isLoading}
              shipping={shipping}
            />
            <TrackingDetail
              isLoading={isLoading}
              shippingUpdate={shippingUpdate}
            />
          </Col>
          <Col xs={12} md={6}>
            <CourierDetail
              isLoading={isLoading}
              shipping={shipping}
            />
            <UserDetail
              isLoading={isLoading}
              user={user}
              displayRole
            />
          </Col>
        </Row>
      </Card>
    )

  }
}
OrderShipping.propTypes= {
  isLoading: propTypes.bool,
  user: propTypes.object,
  shipping: propTypes.object,
}
OrderShipping.defaultProps = {
  isLoading: false
}