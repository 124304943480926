import React, { Component } from 'react';
import { connect } from 'react-redux';
import RackForm from './RackForm'
import privateView from '../../components/hocs/privateView';
import Analytics from "../../services/Analytics";

class RackDetail extends Component{
	componentDidMount(){
    const { email } = this.props.injectedProps;
    Analytics.recordPageView('rack_detail', email, {
      url: window.location.href,
      location: this.props.location,
      match: this.props.match
    });
  }
  render(){
    return(
      <RackForm
        edit
        title="Rack Detail"
        {...this.props}
      />
    )
  }
}

const mapStateToProps = ({auth: { isFetch, roles, isLogged, id, email } })=>({
    guardData: { isFetch, roles, isLogged, id, email }
  })

const enhance = connect(mapStateToProps);
export default enhance(privateView(RackDetail))