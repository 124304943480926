import {
  AUTH_TOKEN_KEY,
  AUDIT_ROUTE_API,
  AUDIT_ADD,
  AUDIT_FETCHING,
  AUDIT_ERROR,
  AUDIT_LIST_FETCH,
  AUDIT_DETAIL_FETCH,
  AUDIT_UPDATE,
  AUDIT_DELETE
} from '../constants';
import api from '../utils/Api';
import { manipulateErrors, checkAuthorized } from '../utils';
import { logout } from './authAction';

export const isFetch=(payload)=>(
	{
		type: AUDIT_FETCHING,
		payload: payload		
	}
)

export const onSuccess=(res,type)=>
(
	{
		type:type,
		payload:res		
	}
)

export const onError=(err)=>{
	if(!checkAuthorized(manipulateErrors(err)))
		return logout();
	return {
		type: AUDIT_ERROR,
		payload: manipulateErrors(err)
	}
}

export const getAuditList=(query)=>{
	return dispatch =>(
		dispatch(isFetch({type:'fetch',status:true})),
		api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).get(AUDIT_ROUTE_API,query)
		.then(res=>{
			dispatch(onSuccess(res.data,AUDIT_LIST_FETCH))
			dispatch(isFetch({type:'fetch',status:false}))
		})
		.catch(err => {
			dispatch(onError(err));
			dispatch(isFetch({type:'fetch',status:false}));
		})
	)
}