import React from 'react';
import {
  CardBody,
  CardText,
  Badge
} from 'reactstrap';

const SizeInfo = props =>(
  <CardBody style={{paddingBottom: 0}}>
    <CardText>
      For size <Badge>US - {props.US}</Badge> <Badge>UK - {props.UK}</Badge> <Badge>EUR - {props.EUR}</Badge> <Badge>CM - {props.cm}</Badge>
    </CardText>
  </CardBody>
)

export default SizeInfo;