import React from 'react';
import {
  Col,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  Button } from 'reactstrap';
import { Creatable } from 'react-select';
import propTypes from 'prop-types';
import { bankOptions } from '../helpers';
import { PAYOUT_MANUAL, PAYOUT_MIDTRANS, KICK_CREDIT, SELLER_CREDIT, KICK_POINT } from '../helpers';

const SearchForm = props => (
  <Form onSubmit={props.onSubmit}>
    <FormGroup>
      <Col xs={12} sm={12} md={6} style={{padding:0}}>
        <InputGroup>
          <Input type="text" placeholder="Search disbursement numb or user's email..." name="keyword" value={props.keyword} onChange={props.onInputChange}/>
          <InputGroupAddon addonType="append">
          </InputGroupAddon>
        </InputGroup>
      </Col>
    </FormGroup>
    <FormGroup row>
      <Col xs={12} sm={12} md={3}>
        <Creatable
          className="kickAveReactSelect"
          options={bankOptions}
          value={props.bankRecipient}
          pageSize={6}
          simpleValue
          onChange={props.onSelectChange}
          placeholder="Filter by bank..."
        />
      </Col>
      <Col xs={12} sm={12} md={3} style={{paddingRight:0}}>
        <Input
          type="select"
          name="payoutType"
          onChange={props.onInputChange}
          value={props.payoutType}
        >
          <option value="">All Payout</option>
          <option value={PAYOUT_MANUAL}>Manual</option>
          <option value={PAYOUT_MIDTRANS}>Midtrans</option>
        </Input>
      </Col>
    </FormGroup>
    <FormGroup row>
      <Col xs={12} sm={12} md={3}>
        <Input
          type="select"
          name="balanceSource"
          onChange={props.onInputChange}
          value={props.balanceSource}
        >
          <option value="">All Credit</option>
          <option value={KICK_CREDIT}>Kick Credit</option>
          <option value={SELLER_CREDIT}>Seller Credit</option>
          <option value={KICK_POINT}>Kick Point</option>
        </Input>
      </Col>
    </FormGroup>
    <Button color="primary">Search</Button>
  </Form>
)
export default SearchForm;

SearchForm.propTypes= {
  keyword: propTypes.string,
  onInputChange: propTypes.func,
  onSubmit: propTypes.func.isRequired
}

SearchForm.defaultProps= {
  keyword: ''
}