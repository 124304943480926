import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import cx from "classnames";
import { getExpressLowestAskBySellId } from "../../../actions/sellRequestAction";
import Styles from "./ExpressLowestAsk.module.css";
import {
  SELL_REQUEST_STATUS,
} from "../../../constants/kickavenue";

const ExpressLowestAsk = ({
  sellId,
  isConsignment,
  preVerified,
  status,
  isNewNoDefect,
  preOrder,
  qty,
  expressLowestAskList,
  getExpressLowestAsk,
}) => {
  const getLowestAsk = () => {
    if (!expressLowestAskList.hasOwnProperty(sellId)) {
      return {
        data: "",
        error: "",
        isFetching: false,
      };
    }
    return expressLowestAskList[sellId];
  };

  if (
    (!isConsignment && !preVerified) ||
    !isNewNoDefect ||
    preOrder ||
    qty <= 0
  ) {
    return "-";
  }

  const { data: lowestAsk, error, isFetching } = getLowestAsk();

  if (lowestAsk) {
    return (
      <span>
        {lowestAsk}{" "}
        <i
          onClick={() => getExpressLowestAsk(sellId)}
          className={cx("fa fa-refresh", Styles["refresh-btn"])}
          title="Recheck"
        ></i>
      </span>
    );
  }

  if (error) {
    return <span>{error}</span>;
  }

  if (isFetching) {
    return <span>Checking...</span>;
  }

  return (
    <span
      className={Styles["span-as-button"]}
      onClick={() => getExpressLowestAsk(sellId)}
    >
      Check!
    </span>
  );
};


ExpressLowestAsk.propTypes = {
  sellId: PropTypes.number.isRequired,
  isConsignment: PropTypes.bool,
  preVerified: PropTypes.bool,
  status: PropTypes.string,
  isNewNoDefect: PropTypes.bool,
  preOrder: PropTypes.bool,
  qty: PropTypes.number,
  expressLowestAskList: PropTypes.shape({
    [PropTypes.number]: PropTypes.shape({
      data: PropTypes.string,
      isFetching: PropTypes.bool,
    }),
  }),
  getExpressLowestAsk: PropTypes.func.isRequired,
};

ExpressLowestAsk.defaultProps = {
  sellId: 0,
  isConsignment: false,
  preVerified: false,
  status: "",
  isNewNoDefect: false,
  preOrder: false,
  qty: 0,
  expressLowestAskList: {
    0: {
      data: "",
      isFetching: false,
    },
  },
};

const mapDispatchToProps = (dispatch) => {
  return {
    getExpressLowestAsk: (id) => dispatch(getExpressLowestAskBySellId(id)),
  };
};

const mapStateToProps = (state) => {
  return {
    expressLowestAskList: state.sellRequest.expressLowestAskList,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ExpressLowestAsk);
