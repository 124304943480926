import {
	REPORT_ROUTE_API,
	SALE_ROUTE_API,
	GET_LIST_SALE,
	GET_LIST_PROCESSED_SALE,
	FETCHING_SALE,
	GET_DETAIL_SALE,
	UPDATE_SALE,
	ROLLBACK_SALE,
	SALE_ERROR,
	REPORT_CLEAR,
	REPORT_SALE,
	REPORT_SALE_ERROR,
	AUTH_TOKEN_KEY,
	GET_LIST_PENDING_PROCESSED_SALE,
	FETCHING_PENDING_PROCESSED_SALE,
	PENDING_PROCESSED_SALE_ERROR,
	GET_LIST_COMPLETED_SALE,
	FETCHING_COMPLETED_SALE,
	COMPLETED_SALE_ERROR,
	SELLER_ACCEPT_SALE,
	SELLER_DECLINE_SALE,
	ADMIN_RECEIVED_SNEAKER_SALE,
	LEGIT_PASSED_SALE,
	LEGIT_FAILED_SALE,
	ADMIN_DELIVERING_SNEAKER_SALE,
	COMPLETION_BY_ADMIN_SALE,
	DISBURSEMENT_TO_SELLER_SALE,
	AUTODISBURSEMENT_TO_SELLER_SALE,
	DISBURSEMENT_TO_BUYER_SALE,
	UPDATE_AWB_NUMBER_SALE,
	UPDATE_HIJACKED_SALE,
  UPDATE_FORCE_CANCELLED_SALE,
  GET_LIST_OUTSTANDING_CONFIRMATION,
  FETCHING_OUTSTANDING_CONFIRMATION,
  OUTSTANDING_CONFIRMATION_ERROR,
  GET_LIST_OUTSTANDING_DELIVERY,
  FETCHING_OUTSTANDING_DELIVERY,
  OUTSTANDING_DELIVERY_ERROR,
  FETCHING_DETAILS_BIN,
  GET_DETAILS_BIN,
  BIN_ROUTE_API,
	BIN_ERROR,

	SALE_IMAGE_S3,
	SALE_IMAGES_REQUEST,
	GET_SALE_IMAGES,
	SALE_IMAGES_ERROR,
	CREATE_SALE_IMAGES,
	DELETE_SALE_IMAGES,

	FETCHING_SALE_NOTES,
	SALE_NOTES_SUBMIT,
	GET_SALE_NOTES,
	READ_SALE_NOTES,

	FETCHING_DETAILS_SALE,
	GET_DETAIL_SALE_2,

	GET_FOLLOWUP_SALES,
	UPDATE_FOLLOWUP_SALES,
	FETCH_FOLLOWUP_SALES,
	GET_FOLLOWUP_APPROVAL_SALES,
	FETCH_FOLLOWUP_APPROVAL_SALES,

	GET_TRACKING_SHIPMENT,
	TRACKING_SHIPMENT_ERROR,
} from '../constants';

import { logout } from './authAction';

import api from '../utils/Api';
import { manipulateErrors, checkAuthorized } from '../utils';

import {
	NEW_STATUS,
	CANCELLED_STATUS,
	KA_RECEIVED_STATUS,
	VERIFICATION_PASSED_STATUS,
	VERIFICATION_FAILED_STATUS,
  DELIVERING_STATUS,
  DONE_BY_ADMIN_STATUS,
} from '../constants/kickavenue';
import {
  onRequest,
  onSuccessRequest,
  onFailedRequest
} from './helpers';
import { clientPutObject } from '../utils/AWS';
import {
  AWS_BUCKET,
  AWS_BUCKET_URL
} from '../config/storageBucket';
import mime from 'mime-types';

/**
 * Get list of offers
 * @author pewe
 * @guards jwt-token
 * @param {object?} query
 */
export const getSaleList=(query)=>{
	return dispatch =>(
		dispatch(isFetch({type:'fetch',status:true})),
		api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).get(`${SALE_ROUTE_API}`,query)
		.then(res=>{
			dispatch(onSuccess(res.data,GET_LIST_SALE))
			dispatch(isFetch({type:'fetch',status:false}))
		})
		.catch(err => {
			dispatch(onError(err, SALE_ERROR));
			dispatch(isFetch({type:'fetch',status:false}));
		})
	)
}
export const getPendingDeliveringSaleList=(query)=>{
	return dispatch =>(
		dispatch(isFetch({type:'fetch',status:true})),
		api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).get(`${SALE_ROUTE_API}/pending-delivering`,query)
		.then(res=>{
			dispatch(onSuccess(res.data,GET_LIST_SALE))
			dispatch(isFetch({type:'fetch',status:false}))
		})
		.catch(err => {
			dispatch(onError(err, SALE_ERROR));
			dispatch(isFetch({type:'fetch',status:false}));
		})
	)
}

export const getDetailPaymentCreditCard=(invoiceNumber) => {
  return dispatch => (
    dispatch({type: FETCHING_DETAILS_BIN, status:true}),
    api({
      Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
    }).get(`${BIN_ROUTE_API}/${invoiceNumber}`)
    .then(res => {
      dispatch(onSuccess(res.data, GET_DETAILS_BIN));
      dispatch({type: FETCHING_DETAILS_BIN, status:false});
    })
    .catch(err => {
      dispatch(onError(err, BIN_ERROR));
      dispatch({type: FETCHING_DETAILS_BIN, status:false});
    })
	)
}

export const getOutstandingSaleConfirmation=(query)=>{
  return dispatch => (
    dispatch({type:FETCHING_OUTSTANDING_CONFIRMATION,status:true}),
    api({
      Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
    }).get(`${SALE_ROUTE_API}`, query)
    .then(res=>{
      dispatch(onSuccess(res.data, GET_LIST_OUTSTANDING_CONFIRMATION))
      dispatch({type:FETCHING_OUTSTANDING_CONFIRMATION,status:false})
    })
    .catch(err=> {
      dispatch(onError(err, OUTSTANDING_CONFIRMATION_ERROR));
      dispatch({type:FETCHING_OUTSTANDING_CONFIRMATION, status:false})
    })
	)
}

export const getOutstandingSaleDelivery=(query)=>{
  return dispatch => (
    dispatch({type:FETCHING_OUTSTANDING_DELIVERY,status:true}),
    api({
      Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
    }).get(`${SALE_ROUTE_API}`, query)
    .then(res=>{
      dispatch(onSuccess(res.data, GET_LIST_OUTSTANDING_DELIVERY))
      dispatch({type:FETCHING_OUTSTANDING_DELIVERY,status:false})
    })
    .catch(err=> {
      dispatch(onError(err, OUTSTANDING_DELIVERY_ERROR));
      dispatch({type:FETCHING_OUTSTANDING_DELIVERY, status:false})
    })
	)
}

export const doGetPendingProcessedList = query =>{
	return dispatch =>(
		dispatch({type: FETCHING_PENDING_PROCESSED_SALE, payload:true}),
		api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).get(`${SALE_ROUTE_API}`,query)
		.then(res=>{
			dispatch(onSuccess(res.data,GET_LIST_PENDING_PROCESSED_SALE))
			dispatch({type:FETCHING_PENDING_PROCESSED_SALE, payload:false})
		})
		.catch(err => {
			dispatch(onError(err, PENDING_PROCESSED_SALE_ERROR));
			dispatch({type:FETCHING_PENDING_PROCESSED_SALE, payload:false})
		})
	)
}

export const doGetCompletedProcessedList = query =>{
	return dispatch => (
		dispatch({type: FETCHING_COMPLETED_SALE, payload:true}),
		api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).get(`${SALE_ROUTE_API}`,query)
		.then(res=>{
			dispatch(onSuccess(res.data,GET_LIST_COMPLETED_SALE))
			dispatch({type:FETCHING_COMPLETED_SALE, payload:false})
		})
		.catch(err => {
			dispatch(onError(err, COMPLETED_SALE_ERROR));
			dispatch({type:FETCHING_COMPLETED_SALE, payload:false})
		})
	)
}

export const getSaleProcessedList=(query)=>{
	return dispatch => (
		dispatch(isFetch({type:'fetch',status:true})),
		api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).get(`${SALE_ROUTE_API}`,query)
		.then(res=>{
			dispatch(onSuccess(res.data,GET_LIST_PROCESSED_SALE))
			dispatch(isFetch({type:'fetch',status:false}))
		})
		.catch(err => {
			dispatch(onError(err, SALE_ERROR));
			dispatch(isFetch({type:'fetch',status:false}));
		})
	)
}

/**
 * Get detail of Offer
 * @guards jwt-token
 * @param {integer} id
 */
export const getDetailSale=(id)=>{
	return dispatch => (
		dispatch(isFetch({type:'fetch',status:true})),
		api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).get(`${SALE_ROUTE_API}/${id}`)
		.then(res=>{
			dispatch(onSuccess(res.data,GET_DETAIL_SALE))
			dispatch(isFetch({type:'fetch',status:false}))
		})
		.catch(err => {
			dispatch(onError(err, SALE_ERROR));
			dispatch(isFetch({type:'fetch',status:false}));
		})
	)
}

export const getTrackingShipmentDetail = (id) => {
	return dispatch => (
		dispatch(isFetch({type:'fetch_tracking',status:true})),
		api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).get(`${SALE_ROUTE_API}/${id}/tracking-shipment`)
		.then(res=>{
			dispatch(onSuccess(res.data, GET_TRACKING_SHIPMENT))
			dispatch(isFetch({type:'fetch_tracking',status:false}))
		})
		.catch(err => {
			dispatch(onError(err, TRACKING_SHIPMENT_ERROR));
			dispatch(isFetch({type:'fetch_tracking',status:false}));
		})
	)
}

export const doAcceptSale = (id, payload)=>(
	dispatch => (
		dispatch(isFetch({type:'submit',status:true})),
		api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).put(`${SALE_ROUTE_API}/${id}`,{...payload, status: NEW_STATUS})
		.then(res=>{
			dispatch(onSuccess(res.data,SELLER_ACCEPT_SALE))
			dispatch(isFetch({type:'submit',status:false}))
		})
		.catch(err => {
			dispatch(onError(err, SALE_ERROR));
			dispatch(isFetch({type:'submit',status:false}));
		})
	)
)

export const doDeclineSale = (id, payload)=>(
	dispatch => (
		dispatch(isFetch({type:'submit',status:true})),
		api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).put(`${SALE_ROUTE_API}/${id}`,{...payload, status: CANCELLED_STATUS})
		.then(res=>{
			dispatch(onSuccess(res.data,SELLER_DECLINE_SALE))
			dispatch(isFetch({type:'submit',status:false}))
		})
		.catch(err => {
			dispatch(onError(err, SALE_ERROR));
			dispatch(isFetch({type:'submit',status:false}));
		})
	)
)

export const doReceiveSneakerSale = (id, payload)=>(
	dispatch => (
		dispatch(isFetch({type:'submit',status:true})),
		api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).put(`${SALE_ROUTE_API}/${id}`,{...payload, status: KA_RECEIVED_STATUS})
		.then(res=>{
			dispatch(onSuccess(res.data, ADMIN_RECEIVED_SNEAKER_SALE))
			dispatch(isFetch({type:'submit',status:false}))
		})
		.catch(err => {
			dispatch(onError(err, SALE_ERROR));
			dispatch(isFetch({type:'submit',status:false}));
		})
	)
)

export const doLegitPassedSneakerSale = (id, payload)=>(
	dispatch => (
		dispatch(isFetch({type:'submit',status:true})),
		api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).put(`${SALE_ROUTE_API}/${id}`,{...payload, status: VERIFICATION_PASSED_STATUS})
		.then(res=>{
			dispatch(onSuccess(res.data, LEGIT_PASSED_SALE))
			dispatch(isFetch({type:'submit',status:false}))
		})
		.catch(err => {
			dispatch(onError(err, SALE_ERROR));
			dispatch(isFetch({type:'submit',status:false}));
		})
	)
)

export const doLegitFailedSneakerSale = (id, payload)=>(
	dispatch => (
		dispatch(isFetch({type:'submit',status:true})),
		api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).put(`${SALE_ROUTE_API}/${id}`,{...payload, status: VERIFICATION_FAILED_STATUS})
		.then(res=>{
			dispatch(onSuccess(res.data, LEGIT_FAILED_SALE))
			dispatch(isFetch({type:'submit',status:false}))
		})
		.catch(err => {
			dispatch(onError(err, SALE_ERROR));
			dispatch(isFetch({type:'submit',status:false}));
		})
	)
)

export const doDeliverSneakerSale = (id, payload)=>(
	dispatch => (
		dispatch(isFetch({type:'submit',status:true})),
		api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).put(`${SALE_ROUTE_API}/${id}`,{...payload, status: 'PENDING_DELIVERING'})
		.then(res=>{
			dispatch(onSuccess(res.data, ADMIN_DELIVERING_SNEAKER_SALE))
			dispatch(isFetch({type:'submit',status:false}))
		})
		.catch(err => {
			dispatch(onError(err, SALE_ERROR));
			dispatch(isFetch({type:'submit',status:false}));
		})
	)
)

export const doUpdateAwbNumber = (id, payload)=>(
	dispatch => (
		dispatch(isFetch({type:'submit',status:true})),
		api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).put(`${SALE_ROUTE_API}/${id}/awb_number`,{...payload})
		.then(res=>{
			dispatch(onSuccess(res.data, ADMIN_DELIVERING_SNEAKER_SALE))
			dispatch(isFetch({type:'submit',status:false}))
		})
		.catch(err => {
			dispatch(onError(err, SALE_ERROR));
			dispatch(isFetch({type:'submit',status:false}));
		})
	)
)


export const doCompletionSale = (id, payload)=>(
	dispatch => (
		dispatch(isFetch({type:'submit',status:true})),
		api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).put(`${SALE_ROUTE_API}/${id}`,{...payload, status: DONE_BY_ADMIN_STATUS})
		.then(res=>{
			dispatch(onSuccess(res.data, COMPLETION_BY_ADMIN_SALE))
			dispatch(isFetch({type:'submit',status:false}))
		})
		.catch(err => {
			dispatch(onError(err, SALE_ERROR));
			dispatch(isFetch({type:'submit',status:false}));
		})
	)
)

export const doDisburseSellerSale = (id, payload)=>(
	dispatch => (
		dispatch(isFetch({type:'submit',status:true})),
		api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).post(`${SALE_ROUTE_API}/${id}/disbursement`,{...payload, type:'seller_disbursement'})
		.then(res=>{
			dispatch(onSuccess(res.data, DISBURSEMENT_TO_SELLER_SALE))
			dispatch(isFetch({type:'submit',status:false}))
		})
		.catch(err => {
			dispatch(onError(err, SALE_ERROR));
			dispatch(isFetch({type:'submit',status:false}));
		})
	)
)

export const manualDisburse = () => (
	dispatch => (
		dispatch(isFetch({type:'submit',status:true})),
		api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).post(`${SALE_ROUTE_API}/manual-disbursement`,{type:'manual_disbursement'})
		.then(res=>{
			dispatch(onSuccess(res.data, AUTODISBURSEMENT_TO_SELLER_SALE))
			dispatch(isFetch({type:'submit',status:false}))
		})
		.catch(err => {
			dispatch(onError(err, SALE_ERROR));
			dispatch(isFetch({type:'submit',status:false}));
		})
	)
)


/**
 * Update an offer
 * @author pewe
 * @guards jwt-token
 * @param {integer} id
 * @param {object} payload
 */
export const updateSale = (id,payload) =>{
	return dispatch => (
		dispatch(isFetch({type:'submit',status:true})),
		api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).put(`${SALE_ROUTE_API}/${id}`,payload)
		.then(res=>{
			dispatch(onSuccess(res.data,UPDATE_SALE))
			dispatch(isFetch({type:'submit',status:false}))
		})
		.catch(err => {
			dispatch(onError(err, SALE_ERROR, id));
			dispatch(isFetch({type:'submit',status:false}));
		})
	)
}

export const disburseSale = (id,payload) =>{
	return dispatch => (
		dispatch(isFetch({type:'submit',status:true})),
		api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).post(`${SALE_ROUTE_API}/${id}/disbursement`,{...payload, type:'buyer_disbursement'})
		.then(res=>{
			dispatch(onSuccess(res.data,DISBURSEMENT_TO_BUYER_SALE))
			dispatch(isFetch({type:'submit',status:false}))
		})
		.catch(err => {
			dispatch(onError(err, SALE_ERROR));
			dispatch(isFetch({type:'submit',status:false}));
		})
	)
}
export const doUpdateAwbNumberSale = (id,payload) =>{
	return dispatch => (
		dispatch(isFetch({type:'submit',status:true})),
		api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).put(`${SALE_ROUTE_API}/${id}/awb_number`,{...payload, type:'buyer_disbursement'})
		.then(res=>{
			dispatch(onSuccess(res.data,UPDATE_AWB_NUMBER_SALE))
			dispatch(isFetch({type:'submit',status:false}))
		})
		.catch(err => {
			dispatch(onError(err, SALE_ERROR));
			dispatch(isFetch({type:'submit',status:false}));
		})
	)
}

/**
 * Post an report sale
 * @author sopyan
 * @guards jwt-token
 * @param {object} payload
 */
export const reportSale = (payload) =>{
	return dispatch => (
		dispatch({type:REPORT_CLEAR,payload:true}),
		dispatch(isFetch({type:'submit',status:true})),
		api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).post(`${REPORT_ROUTE_API}`,payload)
		.then(res=>{
			dispatch(onSuccess(res.data,REPORT_SALE))
			dispatch(isFetch({type:'submit',status:false}))
		})
		.catch(err => {
			dispatch(onError(err,REPORT_SALE_ERROR));
			dispatch(isFetch({type:'submit',status:false}));
		})
	)
}


export const doHijackedSale = (id,payload) =>{
	return dispatch => (
		dispatch(isFetch({type:'submit',status:true})),
		api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).put(`${SALE_ROUTE_API}/${id}/hijacked`,{...payload, type:'buyer_disbursement'})
		.then(res=>{
			dispatch(onSuccess(res.data,UPDATE_HIJACKED_SALE))
			dispatch(isFetch({type:'submit',status:false}))
		})
		.catch(err => {
			dispatch(onError(err, SALE_ERROR));
			dispatch(isFetch({type:'submit',status:false}));
		})
	)
}

export const doForceCancelledSale = (id,payload) =>{
	return dispatch => (
		dispatch(isFetch({type:'submit',status:true})),
		api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).put(`${SALE_ROUTE_API}/${id}/force_cancelled`,{...payload})
		.then(res=>{
			dispatch(onSuccess(res.data,UPDATE_FORCE_CANCELLED_SALE))
			dispatch(isFetch({type:'submit',status:false}))
		})
		.catch(err => {
			dispatch(onError(err, SALE_ERROR));
			dispatch(isFetch({type:'submit',status:false}));
		})
	)
}

export const doRollbackSale = (id,payload) =>{
	return dispatch => (
		dispatch(isFetch({type:'submit',status:true})),
		api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).put(`${SALE_ROUTE_API}/${id}/rollback`,{...payload})
		.then(res=>{
			dispatch(onSuccess(res.data,ROLLBACK_SALE))
			dispatch(isFetch({type:'submit',status:false}))
		})
		.catch(err => {
			dispatch(onError(err, SALE_ERROR));
			dispatch(isFetch({type:'submit',status:false}));
		})
	)
}

export const doMarkedSale = (id,payload) =>{
	return dispatch => {
		dispatch(isFetch({type:'submit',status:true}));
		api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).put(`${SALE_ROUTE_API}/${id}/marked`,payload)
		.then(res=>{
			dispatch(onSuccess(res.data,UPDATE_SALE));
			dispatch(isFetch({type:'submit',status:false}));
		})
		.catch(err => {
			dispatch(onError(err, SALE_ERROR, id));
			dispatch(isFetch({type:'submit',status:false}));
		})
	}
}

export const uploadSaleImageS3 = (folder, file, customFileName = null) => (
  dispatch => {
    let key = `${folder}${file.identifier}.${mime.extension(file.fileInput.type)}`;
    if(customFileName){
      key = `${folder}${customFileName}`;
    }
    dispatch({
      type: SALE_IMAGE_S3,
      payload: { isPending: true }
    })
    clientPutObject({
      Bucket: AWS_BUCKET,
      Key: key,
      Body: file.fileInput,
      ContentType:'image/jpeg',
      ACL: 'public-read', // your permisions
    }).then(res => {
      const results={
        identifier:file.identifier,
        prefix:AWS_BUCKET_URL,
        folder,
        url:`${AWS_BUCKET_URL}${res.key}`
      }
      dispatch({
        type: SALE_IMAGE_S3,
        payload: { results, isPending: false }
      });
    }).catch(err => {
      dispatch(onFailedRequest(SALE_IMAGE_S3,
				SALE_IMAGE_S3,
				err
			));
    })
  }
)

export const getSaleImages = (saleId) => dispatch => {
	dispatch(onRequest(SALE_IMAGES_REQUEST, GET_SALE_IMAGES, true));
	api({
		Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
	}).get(`${SALE_ROUTE_API}/${saleId}/images`)
	.then(res => {
		dispatch(onSuccessRequest(GET_SALE_IMAGES, res.data));
		dispatch(onRequest(SALE_IMAGES_REQUEST, GET_SALE_IMAGES, false));
	})
	.catch(err => {
		dispatch(onFailedRequest(SALE_IMAGES_ERROR, GET_SALE_IMAGES, err, saleId));
		dispatch(onRequest(SALE_IMAGES_REQUEST, GET_SALE_IMAGES, false));
	});
}

export const createSaleImages = (saleId, payload) => (
  dispatch => {
    dispatch(onRequest(SALE_IMAGES_REQUEST, CREATE_SALE_IMAGES, true));
    api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).post(`${SALE_ROUTE_API}/${saleId}/images`, payload)
    .then(res => {
      dispatch(onSuccessRequest(CREATE_SALE_IMAGES, res.data));
      dispatch(onRequest(SALE_IMAGES_REQUEST, CREATE_SALE_IMAGES, false));
    })
    .catch(err => {
      dispatch(onFailedRequest(SALE_IMAGES_ERROR, CREATE_SALE_IMAGES, err));
      dispatch(onRequest(SALE_IMAGES_REQUEST, CREATE_SALE_IMAGES, false));
    });
  }
)

export const deleteSaleImagesById = (saleId, id) => (
  dispatch => {
    dispatch(onRequest(SALE_IMAGES_REQUEST, DELETE_SALE_IMAGES, true));
    api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).delete(`${SALE_ROUTE_API}/${saleId}/images/${id}`)
    .then(res => {
      dispatch(onSuccessRequest(DELETE_SALE_IMAGES, res.data));
      dispatch(onRequest(SALE_IMAGES_REQUEST, DELETE_SALE_IMAGES, false));
    })
    .catch(err => {
      dispatch(onFailedRequest(SALE_IMAGES_ERROR, DELETE_SALE_IMAGES, err, id));
      dispatch(onRequest(SALE_IMAGES_REQUEST, DELETE_SALE_IMAGES, false));
    });
  }
)

export const getSaleNotes = (saleId, query) => (
	dispatch => {
		dispatch({
			type: FETCHING_SALE_NOTES,
			payload: true
		});
		api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).get(`${SALE_ROUTE_API}/${saleId}/notes`, query)
    .then(res => {
			dispatch({
				type: GET_SALE_NOTES,
				payload: { saleId, ...res.data }
			});
    })
    .catch(err => {
			dispatch({
				type: FETCHING_SALE_NOTES,
				payload: {
					id: saleId, value: false, error: err, errorKey: 'list'
				}
			});
    });
	}
)

export const setReadSaleNotes = (saleId, body) => (
	dispatch => {
		dispatch({
			type: SALE_NOTES_SUBMIT,
			payload: true
		});
		api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).put(`${SALE_ROUTE_API}/${saleId}/notes/reads`, body)
    .then(res => {
      dispatch({
				type: READ_SALE_NOTES,
				payload: { saleId, ...res.data }
			});
    })
    .catch(err => {
			dispatch({
				type: SALE_NOTES_SUBMIT,
				payload: {
					id: saleId, value: false, error: err, errorKey: 'readNotes'
				}
			});
    });
	}
)

export const getDetailSale2= (id) => (
	dispatch => {
		dispatch({
			type: FETCHING_DETAILS_SALE,
			payload: true
		});
		api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).get(`${SALE_ROUTE_API}/${id}`)
    .then(res => {
      dispatch({
				type: GET_DETAIL_SALE_2,
				payload: res.data
			});
    })
    .catch(err => {
			dispatch({
				type: FETCHING_DETAILS_SALE,
				payload: {
					id, value: false, error: err
				}
			});
    });
	}
)

export const getFollowUpSales = (query = {}) => (
	dispatch => {
		let params = query.params? {...query.params}: {...query}
		params = { ...params,  type: 'follow_up' };
		dispatch({
			type: FETCH_FOLLOWUP_SALES,
			payload: { type: "GET", value: true }
		});
		api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).get(`${SALE_ROUTE_API}`, { params })
    .then(res => {
      dispatch({
				type: GET_FOLLOWUP_SALES,
				payload: res.data
			});
    })
    .catch(err => {
			dispatch({
				type: FETCH_FOLLOWUP_SALES,
				payload: {
					type: "GET", value: false, error: err
				}
			});
    });
	}
)

export const getFollowUpApprovalSales = (query = {}) => (
	dispatch => {
		let params = query.params? {...query.params}: {...query}
		params = { ...params,  type: 'follow_up_approval' };
		dispatch({
			type: FETCH_FOLLOWUP_APPROVAL_SALES,
			payload: { type: "GET", value: true }
		});
		api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).get(`${SALE_ROUTE_API}`, { params })
    .then(res => {
      dispatch({
				type: GET_FOLLOWUP_APPROVAL_SALES,
				payload: res.data
			});
    })
    .catch(err => {
			dispatch({
				type: FETCH_FOLLOWUP_APPROVAL_SALES,
				payload: {
					type: "GET", value: false, error: err
				}
			});
    });
	}
)

export const setFollowUpSale = (id, payloads, isUpdate = false) => (
	dispatch => {
		const instance = api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		})
		const req = isUpdate? instance.put(`${SALE_ROUTE_API}/${id}/follow-up`, payloads):
			instance.post(`${SALE_ROUTE_API}/${id}/follow-up`, payloads)
		const type = isUpdate? "PUT": "POST";
		dispatch({
			type: FETCH_FOLLOWUP_SALES,
			payload: { type, value: true }
		});
		req.then(res => {
      dispatch({
				type: UPDATE_FOLLOWUP_SALES,
				payload: res.data
			});
    })
    .catch(err => {
			dispatch({
				type: FETCH_FOLLOWUP_SALES,
				payload: {
					type, value: false, id, error: err
				}
			});
    });
	}
)

/**
 * Indicates when start fetch api or end fetch api
 * @author pewe
 * @param {boolean} bool
 */
export const isFetch=(payload)=>(
	{
		type:FETCHING_SALE,
		payload:payload
	}
)

/**
 * When onSuccess triggered
 * @author pewe
 * @param {object} res
 * @param {string} type
 */
export const onSuccess=(res,type)=>
(
	{
		type:type,
		payload:res
	}
)
/**
 * When error fetch or connect to api
 * @author pewe
 * @param {object} err
 */
// On Error fetching api
export const onError=(err, type = SALE_ERROR, id)=>{
	if(!checkAuthorized(manipulateErrors(err)))
		return logout();
	return {
		type,
		payload: { ...manipulateErrors(err), id}
	}
}
