import React from 'react';
import Style from './Processed.module.css';

function ProcessedConsignment({}) {
    return (
        <div className={Style['container']}>
            Processed Consignment
        </div>
    );
}

export default ProcessedConsignment;