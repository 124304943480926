import React from 'react';
import { FontAwesomeIcon } from '../../../components/Icons';
import Paragraph from '../../../styled/Typograph/Paragraph';
import SmallInline from '../../../styled/Typograph/Small/SmallInline';

export const TitleColumn = props => {
  const { id, slug, title, description, createdAt } = props;
  return(
    <div>
      <SmallInline className="text-muted mr-1">#{id} {slug}</SmallInline>
      <Paragraph>
        {title}
      </Paragraph>
      <div>
        {description?(
          <SmallInline style={{margin: 0, fontSize: '.5rem'}} className="text-muted">
            {''.concat(description.substring(0, 80), description.length>80? '\[...\]': '')}
          </SmallInline>
        ):null}
      </div>
      <SmallInline>
        <span className="mr-1">
          <FontAwesomeIcon iconType="calendar" className="mr-1"/>{ createdAt }
        </span>
      </SmallInline>
    </div>
  )
}
