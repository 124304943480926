import React from 'react';
import propTypes from 'prop-types';

const FilterContainer = props => (
  <div className="filter-container">
    { props.children }
  </div>
)
export default FilterContainer;

FilterContainer.propTypes={
  children: propTypes.node
}