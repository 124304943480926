import {
	GET_LIST_CONCIERGE,
	FETCHING_CONCIERGE,
	GET_DETAIL_CONCIERGE,
	UPDATE_CONCIERGE,
	CREATE_CONCIERGE,
	CONCIERGE_ERROR,
} from '../constants';

/**
 * Initial State
 */
const initialState={
	list:[],
	concierge:{},
  detail:{},
	isFetch:false,
	isSubmit:false,
	error:null
}

const onGetConciergeList = (lastState,payload) => {
	const {data, message, status_code}=payload;
	return { ...lastState, error:null, list:{ ...data, message, status_code } };	
}

const onGetDetailConcierge = (lastState,payload) => {
	const {data, message, status_code}=payload;
	return { ...lastState, error:null, detail:{ ...data, message, status_code } };	
}

const onFetchingConcierge = (lastState,payload) => {
    if(payload.type==='fetch')
      return { ...lastState, isFetch:payload.status }
    else if(payload.type==='submit')
			return { ...lastState, isSubmit:payload.status }
    return { ...lastState, isFetch:false,isSubmit:false }
}

const onConciergeError = (lastState,error) => {
	return { ...lastState, ...error };		
}

const conciergeReducer= (state=initialState, action) => {
	switch(action.type)
	{
		case GET_LIST_CONCIERGE: return onGetConciergeList(state,action.payload);
		case GET_DETAIL_CONCIERGE: return onGetDetailConcierge(state,action.payload);
		case FETCHING_CONCIERGE: return onFetchingConcierge(state,action.payload);
		case CONCIERGE_ERROR:return onConciergeError(state,action.payload);
		case UPDATE_CONCIERGE:return onGetDetailConcierge(state,action.payload);
		case CREATE_CONCIERGE:{
			const {data, message, status_code}=action.payload;
			return { ...state, error:null, concierge:{ ...data, message, status_code } };
		};
		default:return state;
	}
}

export default conciergeReducer;