export function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export const determineAutoCancelSwitchValue = (autoCancelledAt, turnOnAutoCancelEnabled = true) => {
  if (!turnOnAutoCancelEnabled) {
    return false;
  }

  return !autoCancelledAt ? true : false;
}

export const getAskingPriceWithProcessingFee = (askingPrice, processingFeeOfflineTx) => {
  const price = parseInt(askingPrice || "0");
  const processingFee = parseInt(processingFeeOfflineTx || "0");
  return price + (price * (processingFee / 100));
}

export function getProcessingFeeFromTotal(total, processingFeeOfflineTx) {
  return total * (parseInt(processingFeeOfflineTx) / 100);
}