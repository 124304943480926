// AWS Configs
// export const AWS_ACCESS_KEY_ID='AKIAI67IVUGFRCLGYS7A';
// export const AWS_SECRET_ACCESS_KEY='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';
// export const BUCKET_REGION='ap-southeast-1';
// export const BUCKET_NAME='kickavenue-assets';
// export const BUCKET_NAME_DEV='kickavenue-develop';
// export const BUCKET_NAME_USER='kickavenue-user-uploads';
// export const ENV_BUCKET_NAME=process.env.NODE_ENV==='development' ? BUCKET_NAME_DEV : BUCKET_NAME;
// export const RESOURCE_URL='https://kickavenue-assets.s3.amazonaws.com/';
// export const RESOURCE_URL_DEV='https://kickavenue-develop.s3.amazonaws.com/';
// export const RESOURCE_URL_USER='https://kickavenue-user-uploads.s3.amazonaws.com/';
// export const ENV_RESOURCE_USER=process.env.NODE_ENV==='development' ? RESOURCE_URL_DEV : RESOURCE_URL;
export const availableRoles=[
	'administrator','superadministrator','supervisor','sale_supervisor','inventory_admin', 'customer_service', 'courier', 'blog_admin', 'cashier'
];
export const bulkProductFormats = [
'Brand',
'Child Brand',
'Category',
'Product ID',
'Product Name',
'Active',
'New Product'
];
export const bulkVariantFormats=[
	'Category',
	'Brand',
	'Child Brand',
	'Product',
	'Display Name',
	'Type',
	'Dimension',
	'Tags',
	'Ribbon Tags',
	'Colour',
	'Weight',
	'SKU',
	'Sex',
	'Auto Scrape',
	'Editors Choice',
	'Position',
	'Vintage',
	'Biddable',
	'Hide Box Info',
	'Receive Sell Request',
	'Voucher Applicable',
	'Details',
	'Price Source',
	'Currency',
	'Retail Price',
	'Release Date',
	'Expiry Date',
	'Active',
	'New SKU',
	'Image1',
	'Image2',
	'Image3',
	'Image4',
	'Image5',
	'Image6',
	'Payment Method',
	'Size Chart',
	'Size Chart Alt'
];

export const bulkStockFormats=[
	'Email',
	'SKU',
	'Size (US)',
	'Price',
	'Currency',
	'Pre-Verified',
	'Pre-Order',
	'Status',
	'Sneakers Condition',
	'Missing Accessories',
	'Yellowing',
	'Display Item',
	'Sneakers Defect',
	'No Tags',
	'Missing Original',
	'Manufacture Defect',
	'Box Condition',
	'Rack',
	'New Stock',
	'Quantity',
	'Note',
];

export const bulkRackFormats=[
	'Id',
	'Rack Name',
	'Maximum Capacity',
]

export const bulkAWBFormats = [
	'Order Number',
	'AWB'
]