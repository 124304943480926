import { GET_LIST_USER_SELL,
		GET_DETAIL_USER_SELL,
		ADD_USER_SELL,
		UPDATE_STATUS_USER_SELL,
		FETCHING_USER_SELL,
		GET_LIST_USER_SELL_WITH_SIZE,
    USER_SELL_ERROR,
    UPDATE_SELL_REQUEST_PRICE,
		UPDATE_STATUS_USER_SELL_ERROR,
		DELETE_USER_SELL,
		UPLOADING_IMAGE_SELL,
		CHANGING_IMAGE_SELL,
		DELETE_IMAGES_SELL,
		PROCESS_CHANGED_IMAGE_SELL,
		IMAGE_SELL_S3_ERROR,
		IMAGE_SELL_ERROR,
		GET_LIST_USER_SELL_JOURNAL,
		USER_SELL_JOURNAL_ERROR,
		FETCHING_USER_SELL_JOURNAL,
		GET_EXPRESS_LOWEST_ASK_ERROR,
		GET_EXPRESS_LOWEST_ASK_LOADING,
		GET_EXPRESS_LOWEST_ASK_SUCCESS
} from '../constants';

const initialState={
  list:[],
  updateStatus:{
    status_code: null
	},
	journalList: {
		data: [],
		isFetch: false,
		error: null
	},
	detail:{},
	isFetch:false,
	isSubmit:false,
	isDelete:false,
	imageProcessedResponse: null,
	imageProcessedState: null,
	isImageProcessLoading: false,
	error:null,
	expressLowestAskList: {}
}
const onFetchingUserSell = (lastState,payload) => {
    if(payload.type==='fetch')
        return { ...lastState, isFetch:payload.status }
    else if(payload.type==='submit')
				return { ...lastState, isSubmit:payload.status }

    return { ...lastState, isFetch:false,isSubmit:false, isUpload: false }
}

const onUploadSellImageSuccess = (lastState, payload) => {
	// const { imageArray, imageIndex, isNew, dataApi } = payload
	const { image, isNew, dataApi } = payload
	// console.log('individu', hasImage, numImage, bool)
	const { detail } = lastState;
	if(!isNew){
		const userSellImages = detail.user_sell_images.map(imageData => {
			if(imageData.id === dataApi.data.id) return  { ...dataApi.data }
			return imageData;
		})
		detail.user_sell_images = userSellImages;
	}
	else{
		detail.user_sell_images.push(dataApi.data);
	}

	return {
		...lastState,
		detail: { ...detail },
		imageProcessedState: null,
		imageProcessedResponse: {
			...image,
			previewURL: isNew? dataApi.data.URL : image.previewURL,
			...dataApi.data,
			fileInput: null,
			statusUpload: true,
			statusCode: dataApi.status_code,
			isProcessed: false,
			error: null
		},
		isImageProcessLoading: false,
	}
}

const onDeleteSellImageSuccess = (lastState, payload) => {
	const { image, dataApi, deleted } = payload;
	const { detail } = lastState;
	detail.user_sell_images = detail.user_sell_images
		.filter((value)=> value.id !== dataApi.id);
	return {
		...lastState,
		detail: { ...detail },
		imageProcessedState: null,
		imageProcessedResponse: {
			...image,
			...dataApi,
			isDeleted: deleted,
			statusCode: dataApi.status_code,
			isProcessed: false,
			error: null
		},
		isImageProcessLoading: false,
	}
}

const onSellImageError = (lastState, payload) => {
	const { image, error } = payload;
	// console.log("MY ERROR:  ",  error);
	return {
		...lastState,
		imageProcessedState: null,
		imageProcessedResponse: {
			...image,
			isDeleted: false,
			statusCode: null,
			isProcessed: false,
			error
		},
		isImageProcessLoading: false,
	}
}

const handleGetExpressLowestAskLoading = (lastState, payload) => {
	return {
		...lastState,
		expressLowestAskList: {
			...lastState.expressLowestAskList,
			[payload.id]: {
				data: "",
				isFetching: true,
			}
		},
	};
}

const handleGetExpressLowestAskError = (lastState, payload) => {
	return {
		...lastState,
		expressLowestAskList: {
			...lastState.expressLowestAskList,
			[payload.id]: {
				data: "",
				error: payload?.error?.message || "",
				isFetching: false,
			}
		},
	};
}

const handleGetExpressLowestAskSuccess = (lastState, payload) => {
	const sellId = payload?.data?.id || "";
	return {
		...lastState,
		expressLowestAskList: {
			...lastState.expressLowestAskList,
			[sellId]: {
				data: payload?.data?.lowest_ask_express || '',
				isFetching: false,
			}
		},
	};
}

const sellRequestReducer=(state=initialState, action)=>
{
	switch(action.type)
	{
		case GET_LIST_USER_SELL: {
			const {message,status_code,data}=action.payload;
			return { ...state, list:{...data,message:message,status_code:status_code},error:null }
		}
		case GET_LIST_USER_SELL_WITH_SIZE: {
			const {message,status_code,data}=action.payload;
			return { ...state, list:{...data,message:message,status_code:status_code},error:null }
		}
		case GET_DETAIL_USER_SELL: {
			const {message,status_code,data}=action.payload;
			return { ...state,detail:{ message, status_code, ...data },error:null }
		}
		case UPDATE_STATUS_USER_SELL: {
			const {message,status_code,data}=action.payload;
			return { ...state, updateStatus:{ message, status_code, id: data.id, status: data.status, updated_at: data.updated_at, approved_at: data.approved_at }}
    }
    case UPDATE_STATUS_USER_SELL_ERROR:{
      const { id, error } = action.payload;
      return { ...state, updateStatus:{ ...state.updateStatus, error: { id, ...error } }}
    }
		case ADD_USER_SELL:{
			const {message,status_code,data}=action.payload;
			return { ...state,detail:{ message, status_code, ...data },error:null }
		}
		case FETCHING_USER_SELL:
			return onFetchingUserSell(state, action.payload)
		case USER_SELL_ERROR:
			return { ...state, ...action.payload }
		case UPDATE_SELL_REQUEST_PRICE: {
			const { message, status_code, data } = action.payload;
			return {...state, detail: { message, status_code, ...data }, error: null}
		}
		case DELETE_USER_SELL: {
			const { message, status_code, id } = action.payload;
			return {...state, detail: {...state.detail, message, status_code, id, deleted: true }, error: null}
		}
		case CHANGING_IMAGE_SELL:
		case UPLOADING_IMAGE_SELL: {
			return onUploadSellImageSuccess(state, action.payload)
		}
		// case CHANGING_IMAGE_SELL: {
		// 	return changeImageSell(state, action.payload)
		// }
		case DELETE_IMAGES_SELL: {
			return onDeleteSellImageSuccess(state, action.payload)
		}
		case IMAGE_SELL_ERROR:
		case IMAGE_SELL_S3_ERROR: return onSellImageError(state, action.payload)
		case PROCESS_CHANGED_IMAGE_SELL: {
			return { ...state, ...action.payload }
		}

		case GET_LIST_USER_SELL_JOURNAL:{
			const { data, ...rest }=action.payload;
			return { ...state, journalList: { ...state.journalList, ...data, ...rest } }
		}
		case FETCHING_USER_SELL_JOURNAL: {
			return { ...state, journalList: { ...state.journalList, error: action.status? null: state.journalList.error, isFetch: action.status } }
		}
		case USER_SELL_JOURNAL_ERROR: {
			const  { error } = action.payload;
			return { ...state, journalList: { ...state.journalList, error } }
		}
		case GET_EXPRESS_LOWEST_ASK_LOADING: {
			return handleGetExpressLowestAskLoading(state, action.payload);
		}
		case GET_EXPRESS_LOWEST_ASK_ERROR: {
			return handleGetExpressLowestAskError(state, action.payload);
		}
		case GET_EXPRESS_LOWEST_ASK_SUCCESS: {
			return handleGetExpressLowestAskSuccess(state, action.payload);
		}
		default:return state;
	}
}
export default sellRequestReducer;