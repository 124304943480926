import React from 'react';
import propTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import DateToHuman from '../../../../components/DateToHuman';
import { TextRupiah } from '../../../../components/Text';
import { getPaymentType, getConciergeStatus, getPaymentVia } from '../../../../constants/kickavenue';
import FontAwesomeIcon from '../../../../components/Icons/FontAwesomeIcon';
import OrderSummariesColumn from '../../RowItem/OrderSummariesColumn';

const TotalAmount = styled.div`
  font-size: .75rem;
`

const RowItem = props => {
  const {
    id,
    rowId,
    userId,
    userEmail,
    condition,
    condition2,
    amount,
    adjustedAmount,
    paymentMethod,
    paymentBankTransfer,
    status,
    bank,
    paymentVia,
    onOpenCreditCard,
    name,
    productName,
    phoneNumber,
    createdAt,
    updatedAt,
  } = props;
  const via = getPaymentVia(paymentVia);
  return (
    <tr>
      <th className="v-center" scope="row">{ rowId }</th>
      <td className="v-center">
        <OrderSummariesColumn
          id={id}
          name={name}
          productName={productName}
          phoneNumber={phoneNumber}
          condition={condition}
          condition2={condition2}
          userId={userId}
          userEmail={userEmail}
          paymentMethod={paymentMethod}
          paymentBankTransfer={paymentBankTransfer}
          createdAt={createdAt}
        />
      </td>
      <td className="v-center">
        <div>
          {
            bank && paymentMethod === 'virtual_account'?
            <span style={{fontSize: '.75rem'}}>{bank+ '-'+ getPaymentType(paymentMethod)}</span>
            : 
            (paymentMethod === null? 'Not Set' : getPaymentType(paymentMethod))
          }
        </div>
        { via !== null? <div><span style={{fontSize: '.75rem'}}>via <i>{via}</i></span></div>: null}
        {
          paymentVia !== null && paymentVia === 'vt' && paymentMethod=== "credit_card" ? 
          <button className="btn btn-sm btn-secondary" onClick={onOpenCreditCard} target="_blank" title="Show More Info"><i className="fa fa-info-circle"></i> More Info</button>
          :
          null
        }
      </td>
      <td className="v-center"><DateToHuman  value={updatedAt} id={`tooltip_${rowId}`}/></td>
      <td className="v-center">
        { status && getConciergeStatus(status) }
      </td>
      <td className="v-center">
        <Link to={`/concierge/${id}`} className="btn btn-link">Details</Link>
      </td>
    </tr>
  )
}

export default RowItem;

RowItem.defaultProps = {
  amount: 0,
  paymentMethod: 'bank_transfer',
  status: 'NEW',
}

RowItem.propTypes={
  rowId: propTypes.number.isRequired,
  expired: propTypes.bool,
  id: propTypes.number.isRequired,
  amount: propTypes.oneOfType([propTypes.number,propTypes.string]),
  adjustedAmount: propTypes.oneOfType([propTypes.number,propTypes.string]),
  updatedAt: propTypes.string,
  paymentMethod: propTypes.string,
  paymentVia: propTypes.string,
  bank: propTypes.string,
  status: propTypes.string,
  name: propTypes.string,
  productName: propTypes.string,
  phoneNumber: propTypes.string,
  size: propTypes.string,
  displayName: propTypes.string,
  SKU: propTypes.string,
  colour: propTypes.string,
  preOrder: propTypes.oneOfType([propTypes.number,propTypes.bool]),
  onOpenCreditCard: propTypes.func.isRequired,
  rack: propTypes.string
}
