import React, { Component } from 'react';
import {connect} from 'react-redux';
import { Redirect } from 'react-router-dom';
import Analytics from "../../../services/Analytics";
import { ValidationMessage } from '../../../components/Form/ValidationMessage';

// Action
import { loginUser, isFetch } from '../../../actions/authAction';

class Login extends Component {
  constructor(props){
    super(props);
    this.state= {
      'email':'',
      'password':'',
      'defaultRoute':'/dashboard',
      'redirect':false,
      'google_2fa': ''
    };
  }

  componentDidMount(){
    if(this.props.login.isLogged===true)
      this._redirectToHome();
  }

  handleChange(e)
  {
    let newState={};
    newState[e.target.name]=e.target.value;
    this.setState(newState);
  }

  onSubmit(e) {
    e.preventDefault();

    let payloadLogin = {
      email: this.state.email,
      password: this.state.password
    }

    if (this.state.google_2fa) {
      payloadLogin = {
        ...payloadLogin,
        google_2fa: this.state.google_2fa
      }
    }

    this.props.postLogin(payloadLogin);
  }

  validateError(name)
  {
    if(this.hasError(name))
    {
      let errorList=this.props.login.error.errors;
      return <ValidationMessage message={errorList[name][0]}/>
    }
    return '';
  }

  hasError(name)
  {
    if(this.props.login.error)
    {
      if(this.props.login.error.errors)
      {
          let errorList=this.props.login.error.errors;
          let errList=Object.keys(errorList).filter(key=> key==name);
          if(errList.length)
            return true
      }
    }
    return false
  }

  _redirectToHome (){
    const { defaultRoute }=this.state;
    const params = new URLSearchParams(this.props.location.search);
    const next = params.get('next')
    if(next && next!= defaultRoute)
      this.setState({defaultRoute:next,'redirect':true}, ()=>this.props.history.replace(this.state.defaultRoute));
    else
      this.setState({'redirect':true},()=>this.props.history.replace(this.state.defaultRoute));
  }

  componentDidUpdate (prevProps) {
    if(!this.props.login.isFetch && prevProps.login.isFetch!==this.props.login.isFetch && this.props.login.isLogged && !this.state.redirect){
      this._redirectToHome();
      Analytics.recordSignIn(
        'email_and_password',
        this.state.email
      );
    }
  }

  render() {
    let messageError='';
    if(this.props.login.error)
    {
      switch(this.props.login.error.status_code)
      {
        case 500:
        case 401:
        case 503:
        case 400:
        case 404:
          messageError=(<div className="alert alert-danger">
                      <a href="#" className="close" data-dismiss="alert" aria-label="close">&times;</a>
                      <strong>Error!</strong> {this.props.login.error.message}
                    </div>);
          break;
        default:messageError='';
      }
    }
    return (
      <div className="app flex-row align-items-center">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-8">
              <div className="card-group mb-0">
                <div className="card p-4">
                  <div className="card-body">
                    <form>
                      <h1>Login</h1>
                      <p className="text-muted">Sign In to your account</p>
                      {(messageError)}
                      <div className={`form-group${this.hasError('email')?' has-danger has-feedback':''}`}>
                        <div className="input-group mb-3">
                          <span className="input-group-addon"><i className="icon-user"></i></span>
                          <input type="text" name="email" className="form-control" placeholder="Email" onChange={(e)=>this.handleChange(e)}/>
                        </div>
                        {this.validateError('email')}
                      </div>
                      <div className={`form-group${this.hasError('password')?' has-danger has-feedback':''}`}>
                        <div className="input-group mb-3">
                          <span className="input-group-addon"><i className="icon-lock"></i></span>
                          <input type="password" name="password" className="form-control" placeholder="Password" onChange={(e)=>this.handleChange(e)}/>
                        </div>
                        {this.validateError('password')}
                      </div>
                      <div className={`form-group${this.hasError('google_2fa')?' has-danger has-feedback':''}`}>
                        <div className="input-group mb-4">
                          <span className="input-group-addon"><i className="icon-key"></i></span>
                          <input type="text" name="google_2fa" className="form-control" placeholder="Verification code" onChange={(e)=>this.handleChange(e)}/>
                        </div>
                        {this.validateError('google_2fa')}
                      </div>
                      <div className="row">
                        <div className="col-6">
                          <button 
                            type="submit" 
                            className={`btn btn-primary px-4${this.props.login.isFetch?' disabled':''}`} 
                            onClick={this.onSubmit.bind(this)}
                            disabled={this.props.login.isFetch}>
                              {this.props.login.isFetch?'Loading...':'Login'}
                            </button>
                        </div>
                        <div className="col-6 text-right">
                          <button type="button" className="btn btn-link px-0">Forgot password?</button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps=(state)=>{
  return {
    login:state.auth
  }
}
const mapDispatchToProps= (dispatch)=>{
  return{
    postLogin: (payload) => dispatch(loginUser(payload))
  }
}
export default connect(mapStateToProps,mapDispatchToProps)(Login);
