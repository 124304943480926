import React from 'react';
import propTypes from 'prop-types';
import { Card, CardImg, CardBody } from 'reactstrap';
import FontAwesomeIcon from '../../Icons/FontAwesomeIcon';
import CardLoadingPlaceholder from '../CardLoadingPlaceholder';
import CardSuccessUploadPlaceholder from './CardSuccessUploadPlaceholder';
import classNames from 'classnames';
import { getCacheImage } from '../../../utils/imageHelper'

const CardNewImageThumbnail = ({id, url, fileInput, isUploading, statusCodeUpload, onRemoveImage}) => (
  <Card style={{marginBottom:'1rem'}}>
    <CardSuccessUploadPlaceholder
      isVisible={statusCodeUpload===200}
    />
    <CardLoadingPlaceholder
      isVisible={isUploading}
      loadingColor="green"
      loadingText="Uploading..."
    />
    <CardImg top width="100%" src={id ? getCacheImage(url) : fileInput.preview} alt={id?url:fileInput.preview}/>
    <CardBody>
      <button type="button"
        className={classNames("btn btn-link text-danger card-link", {"d-none": isUploading || statusCodeUpload===200})}
        style={{padding: 0}}
        onClick={onRemoveImage}
      >
        <FontAwesomeIcon iconType="remove" /> Delete
      </button>
    </CardBody>
  </Card>

)

export default CardNewImageThumbnail;

CardNewImageThumbnail.propTypes = {
  id: propTypes.oneOfType([ propTypes.number, propTypes.string ]),
  url: propTypes.string,
  updatedAt: propTypes.string,
  storageName: propTypes.string,
  fileInput: propTypes.object,
  isUploading: propTypes.bool,
  statusCodeUpload: propTypes.number,
  onRemoveImage: propTypes.func
}
CardNewImageThumbnail.defaultProps = {
  isUploading: false,
  fileInput: {
    preview: ''
  },
  statusCodeUpload: null
}