import React from 'react';
import { CardText } from 'reactstrap';

const Description = () => (
  <CardText>
    <strong>Change Seller</strong> (<i>a.k.a Hijacking Sale</i>) means if your seller cannot fulfilled their order, you can change with other seller.
    It will be make new sell request with new seller.
    Copying all old sell request's attributes into new sell request.
    And old sell request will be set into <strong>hijacked</strong> status.
  </CardText>
)

export default Description;