import React, { PureComponent as Component } from 'react';
import{
  Card,
  CardHeader,
  CardBody
} from 'reactstrap';
import propTypes from 'prop-types';
import Loading from 'react-loading-animation';

import { _getValue } from '../../../utils/form';
import { TextRupiah } from '../../Text';
import {
  FieldRow,
  FieldColumn,
  FieldLabel,
  FieldValue
} from '../../../styled/Fields';

const getYesNo = (value=false) => value? 'Ya':'No' 

export default class CourierDetail extends Component{
  render(){    
    return(
      <Card>
        <CardHeader>
          {this.props.headerText}
        </CardHeader>
        <CardBody>
          <Loading isLoading={this.props.isLoading}>
            <FieldRow>
              <FieldColumn className="col-xs-12 col-md-6">
                <FieldLabel>Courier</FieldLabel>
                <FieldValue>
                  <span>{_getValue(this.props.shipping.ka_courier)}</span>
                </FieldValue>
              </FieldColumn>
              <FieldColumn className="col-xs-12 col-md-6">
                <FieldLabel>Courier Option</FieldLabel>
                <FieldValue>
                  <span>{_getValue(this.props.shipping.ka_courier_option)}</span>
                </FieldValue>
              </FieldColumn>
              <FieldColumn className="col-xs-12 col-md-6">
                <FieldLabel>Using Insurance</FieldLabel>
                <FieldValue>
                  <span>{getYesNo(this.props.shipping.ka_shipping_insurance)}</span>
                </FieldValue>
              </FieldColumn>
              <FieldColumn className="col-xs-12 col-md-6">
                <FieldLabel>Insurance Price</FieldLabel>
                <FieldValue>
                  <TextRupiah value={this.props.shipping.ka_shipping_insurance_price} align="right" block/>
                </FieldValue>
              </FieldColumn>
              <FieldColumn className="col-12">
                <FieldLabel>AWB Numb.</FieldLabel>
                <FieldValue>
                  <span>{_getValue(this.props.shipping.awb_number)}</span>
                </FieldValue>
              </FieldColumn>
              <FieldColumn className="col-xs-12 col-md-6">
                <FieldLabel>Courier Price</FieldLabel>
                <FieldValue>
                  <TextRupiah value={this.props.shipping.ka_courier_price} align="right" block/>
                </FieldValue>
              </FieldColumn>
              <FieldColumn className="col-xs-12 col-md-6">
                <FieldLabel>Discount</FieldLabel>
                <FieldValue>
                  <TextRupiah value={this.props.shipping.ka_courier_discount} align="right" block/>
                </FieldValue>
              </FieldColumn>
            </FieldRow>
          </Loading>
        </CardBody>
      </Card>
    )
  }
}
CourierDetail.propTypes= {
  headerText: propTypes.string,
  isLoading: propTypes.bool,
  shipping: propTypes.object
}
CourierDetail.defaultProps = {
  headerText: "Courier Details",
  isLoading: false,
  shipping:{
    ka_courier_price: 0,
    ka_courier_discount: 0,
    ka_shipping_insurance_price: 0
  }
}