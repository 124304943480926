import React, { Component } from 'react';
import {connect} from 'react-redux';
import {
  Row,
  Col,
  Card,
  CardBody,
  CardText,
  ButtonDropdown,
  DropdownToggle as DropdownToggleBs,
  DropdownMenu,
  DropdownItem as DropdownItemBs
} from 'reactstrap';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { Tbl, TblHead, TblBody, Th } from '../../components/Tbl';
import Paginations from '../../components/Paginations';
import PaginationSummaries from '../../components/Paginations/PaginationSummaries';
import { getListVoucher } from '../../actions/voucherAction';
import { generateNumber } from '../../utils';
import { DEFAULT_SORT_BY, DEFAULT_PER_PAGE } from '../../constants/settings';
import DateToHuman from '../../components/DateToHuman';
import { TextRupiah } from '../../components/Text';
import CheckReadOnly from '../../components/CheckReadOnly';
import FilterContainer from '../../components/Container/FilterContainer/FilterContainer';
import FilterPanel from './Filters';
import { CardHeaderWithToolbars, Title } from '../../components/Card/CardHeaderWithToolbars';
import Toolbars from './Filters/Toolbars'
import privateView from '../../components/hocs/privateView';
import { SimpleLineIcon } from '../../components/Icons';
import Analytics from "../../services/Analytics";

const DropdownToggle = styled(DropdownToggleBs)`
  border: none;
  padding: 0px;
  &:active,&:focus,&:hover{
    border: none;
    padding: 0px;
    background: transparent;
    outline: none;
    box-shadow: none;
  }
  ~ .dropdown-menu{
    background-color: rgba(255,255,255,.8);
  }
`
const DropdownItem = styled(DropdownItemBs)`
  text-transform: capitalize;
`

const defaultParams = {
  sort_by : DEFAULT_SORT_BY,
  per_page : DEFAULT_PER_PAGE
}
const options = [{
  label: 'Voucher for App',
  value: 'APP'
}, {
  label: 'Voucher for Web',
  value: 'WEB'
}];

const RowHeader = (props) => {
  const isActive = (value) => props.platformSpecification?(props.platformSpecification === value): (value === '')
  return (
    <tr>
      <Th>No.</Th>
      <Th>
        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'flex-end'}}>
          <p style={{marginRight: 10, marginBottom: 0}}>
            Code
            {
              props.platformSpecification?
              <span className="text-capitalize ml-1" style={{fontSize: '.5rem'}}>({props.platformSpecification})</span>
              : null
            }
          </p>
          <ButtonDropdown isOpen={props.dropdownOpen} toggle={props.onDropdownOpenChange}>
            <DropdownToggle size="sm">
              <SimpleLineIcon iconType="options-vertical"/>
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem header>Show Only: </DropdownItem>
              <DropdownItem
                onClick={() => props.onPlatformSpecificationChanged('')}
                active={isActive('')}
              >
                { isActive('')? <SimpleLineIcon iconType="check"/>: null }All
              </DropdownItem>
              {
                options.map((item, idx) => (
                  <DropdownItem
                    onClick={() => props.onPlatformSpecificationChanged(item.value)}
                    active={isActive(item.value)}
                    key={idx}
                  >
                    { isActive(item.value)? <SimpleLineIcon iconType="check"/>: null }{item.label}
                  </DropdownItem>
                ))
              }
            </DropdownMenu>
          </ButtonDropdown>
        </div>
      </Th>
      <Th>Active</Th>
      <Th>Max. Amount</Th>
      <Th>Duration</Th>
      <Th>Updated</Th>
      <Th>Action</Th>
    </tr>
  )
}

class VoucherIndex extends Component{
  constructor(props){
    super(props);
    this.state={
      _params:{
        ...defaultParams
      },
      _filterOpen:false
    }
    this._handleGoto=this._handleGoto.bind(this);
    this._handleRefresh = this._handleRefresh.bind(this);
    this._handleToggleFilterPanel = this._handleToggleFilterPanel.bind(this);
    this._handleParamsChange = this._handleParamsChange.bind(this);
    this._handleParamsChangeAndSubmit = this._handleParamsChangeAndSubmit.bind(this);
    this._handleResetFilter = this._handleResetFilter.bind(this);
  }

  componentWillMount(){
    const { email } = this.props.injectedProps;
    this._getList(this.state._params);
    Analytics.recordPageView('voucher_list', email, {
      url: window.location.href,
      location: this.props.location,
      match: this.props.match
    });
  }

  _onRenderRowItem(){
    if(this.props.voucher.list.status_code===200 && this.props.voucher.list.data.length>0)
    {
      const { current_page,per_page } =  this.props.voucher.list;
      return this.props.voucher.list.data.map((item,index)=>
        (
          <tr key={generateNumber(per_page,current_page,index)}>
            <th className="v-center" scope="row">{ generateNumber(per_page,current_page,index) }</th>
            <td className="v-center">
              <div>
                { item.code }
              </div>
              <small>Minimum Purchase <TextRupiah prefix="Rp. " value={item.minimum_purchase}/></small>
            </td>
            <td className="v-center"><CheckReadOnly check={item.active}/></td>
            <td className="v-center">
              {item.max_amount && (
                <TextRupiah prefix="Rp. " value={item.max_amount}/>
              )}
            </td>
            <td className="v-center">
              {
                item.started_at === null && item.ended_at === null ?(
                  <div style={{fontStyle: 'italic'}}>Not set yet.</div>
                ):(
                  <div>
                    <div>Start: <span>{ item.started_at||'(Not Set)' }</span></div>
                    <div>End: <span>{ item.ended_at||'(Not Set)' }</span></div>
                  </div>
                )
              }
            </td>
            <td className="v-center">{ <DateToHuman value={item.updated_at} id={`tooltip_${generateNumber(per_page,current_page,index)}`}/> }</td>
            <td className="v-center">
                <Link to={`/vouchers/${item.id}`} className="btn btn-link">Details</Link>
            </td>
          </tr>
        )
      )
    }
    return null;
  }

  _onRenderPagination(){
    const { status_code,data } = this.props.voucher.list;
    const hasData = (status_code===200 && data.length) || null;
    return hasData && (
      <Paginations
        size="sm"
        lastPage={this.props.voucher.list.last_page}
        perPage={this.props.voucher.list.per_page}
        total={this.props.voucher.list.total}
        maxPage={8}
        path={this.props.voucher.list.path}
        currentPage={this.props.voucher.list.current_page}
        prevPageUrl={this.props.voucher.list.prev_page_url}
        nextPageUrl={this.props.voucher.list.next_page_url}
        handleGotoCallback={this._handleGoto}
      />
    )
  }

  /**
   * Handle callback when user click pagination items
   * @author pewe
   * @param {integer} page
   */
  _handleGoto(page){
    const _params=this.state._params;
    _params.page=page;
    this.setState({_params},()=>this._getList(this.state._params));
  }

  _toggleFilterPanel(){
    this.setState({_filterOpen:!this.state._filterOpen})
  }

  _handleToggleFilterPanel(e){
    e.preventDefault();
    this._toggleFilterPanel();
  }

  _handleRefresh(e){
    e.preventDefault();
    this._refreshList();
  }

  _getList(rawParams = {} ){
    let params = {};
    Object.keys(rawParams)
    .filter(key => rawParams[key]!='')
    .forEach(key=> params[key] = rawParams[key]);
    this.props.getList({params});
  }

  _refreshList(){
    const {page, ..._params} = this.state._params;
    this.setState({
      _params
    },()=>this._getList(this.state._params));
  }

  _handleParamsChange(e){
    const { _params } = this.state;
    _params[e.target.name] = e.target.value;
    this.setState({_params});
  }

  _handleParamsChangeAndSubmit(e){
    // Exclude page...
    const { page , ..._params } = this.state._params;
    _params[e.target.name] = e.target.value;
    this.setState({_params},()=>{
      this._getList(this.state._params);
    });
  }

  _handleResetFilter(e){
    e.preventDefault();
    const newState = this.state._filterOpen?{_filterOpen:!this.state._filterOpen}:{};
    this.setState({
      ...newState,
      _params:{
        ...defaultParams
      }
    },()=>this._getList(this.state._params));
  }

  render(){
    return(
      <div className="animated fadeIn">
        <Row>
          <Col xs={12}>
            <Card>
              <CardHeaderWithToolbars>
                <Title>
                  <i className="fa fa-align-justify"></i>
                  <span>Voucher List</span>
                </Title>
                <Toolbars
                  isOpen={this.state._filterOpen}
                  onToggleFilter={this._handleToggleFilterPanel}
                  onRefreshList={this._handleRefresh}
                  onResetFilter={this._handleResetFilter}
                />
              </CardHeaderWithToolbars>
              <FilterContainer>
                <FilterPanel
                  {...this.state._params}
                  onHandleChange={this._handleParamsChange}
                  onHandleSearch={this._handleRefresh}
                  onHandleChangeAndSubmit={this._handleParamsChangeAndSubmit}
                  isOpen={this.state._filterOpen}
                />
              </FilterContainer>
              <CardBody>
                <CardText>
                  In this section will display our vouchers.
                </CardText>
                <div className="table-responsive" ref={tableRef => this.tableRef = tableRef}>
                  <PaginationSummaries
                    from={this.props.voucher.list.from}
                    to={this.props.voucher.list.to}
                    total={this.props.voucher.list.total}
                  />
                  <Tbl>
                    <TblHead>
                      <RowHeader
                        dropdownOpen={this.state._dropdownOpen}
                        onDropdownOpenChange={()=>this.setState({_dropdownOpen: !this.state._dropdownOpen})}
                        platformSpecification={this.state._params.platform_specifications}
                        onPlatformSpecificationChanged={async (value) => {
                          await this.setState({
                            _params: {
                              ...this.state._params,
                              platform_specifications: value === ''? null: value,
                              page: 1
                            }
                          });
                          this._getList(this.state._params);
                        }}
                      />
                    </TblHead>
                    <TblBody
                      isLoad={this.props.voucher.isFetch}
                      hasRow={this.props.voucher.list.status_code===200&&this.props.voucher.list.data.length>0}
                      columnCount={7}
                    >
                      { this._onRenderRowItem() }
                    </TblBody>
                  </Tbl>
                </div>
                { this._onRenderPagination() }
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    )
  }
}
const mapStateToProps=({voucher, auth: { isFetch, roles, isLogged, id, email }})=>{
	return {
    voucher,
    guardData: { isFetch, roles, isLogged, id, email }
	}
}
const mapDispatchToProps=(dispatch)=>
{
	return {
		getList:(filter)=>dispatch(getListVoucher(filter)),
	}
}
const enhance = connect(mapStateToProps,mapDispatchToProps);
export default enhance(privateView(VoucherIndex))