import React from "react";
import { Button } from "reactstrap";
import classNames from "classnames";
import propTypes from "prop-types";
import { Link } from "react-router-dom";
import { Toolbars as ToolBarContainer } from "../../../../components/Card/CardHeaderWithToolbars";

const Toolbars = ({ onRefreshList, onResetFilter, onToggleFilter, isOpen }) => (
  <ToolBarContainer>
    <Link
      to={"/bank_promo/create"}
      className="btn btn-sm btn-primary with-toolbars__toolbars__btn"
      title="Create new bank promo"
      target="_blank"
    >
      <i className="fa fa-plus"></i>
    </Link>
    <Button
      title="Refresh"
      className="with-toolbars__toolbars__btn"
      size="sm"
      color="info"
      onClick={onRefreshList}
    >
      <i className="fa fa-refresh"></i>
    </Button>
    <Button
      title="Reset filter"
      className="with-toolbars__toolbars__btn"
      size="sm"
      color="warning"
      onClick={onResetFilter}
    >
      <i className="fa fa-ban"></i>
    </Button>
    <Button
      title="Search and Filter Data"
      size="sm"
      className={classNames("with-toolbars__toolbars__btn", {
        "btn-danger": isOpen,
      })}
      onClick={onToggleFilter}
    >
      <i
        className={classNames("fa", {
          "fa-sliders": !isOpen,
          "fa-minus": isOpen,
        })}
      ></i>
    </Button>
  </ToolBarContainer>
);

Toolbars.propTypes = {
  isOpen: propTypes.bool,
  onToggleFilter: propTypes.func,
  onResetFilter: propTypes.func,
  onRefreshList: propTypes.func,
};
Toolbars.defaultProps = {
  isOpen: false,
};

export default Toolbars;
