import React, {PureComponent} from 'react';
import { Row, Col, Card } from 'reactstrap';
import PropTypes from 'prop-types';
import { ProductVariantImages } from '../../../components/Products';
import DetailReadOnly from '../../../components/Products/DetailReadOnly';

export default class ProductContainer extends PureComponent{
  render(){
    const { product:{ brand, category, ...product }, product_variant_images, ...productVariant  } = this.props.productVariant;
    const featuredImage = product_variant_images.length? (
      product_variant_images[0].signed_url || product_variant_images[0].URL
    ): undefined;
    return(
      <Card body className="tab-content--custom__card">
        <Row>
          <Col xs={12} md={6}>
            <DetailReadOnly
              headerText="Product Detail"
              {...productVariant}
              product={product}
              brand={brand}
              category={category}
              featureImage={featuredImage}
              isLoading={this.props.isLoading}
            />
          </Col>
          <Col xs={12}>
            <ProductVariantImages
              headerText="Product Images"
              data={product_variant_images}
            />
          </Col>
        </Row>
      </Card>
    )
  }
}
ProductContainer.defaultProps = {
  productVariant:{
    id: 0,
    display_name: '',
    SKU: '',
    product:{
      id: 0,
      brand: null,
      category: null
    },
    product_variant_images:[]
  }
}
ProductContainer.propTypes={
  isLoading: PropTypes.bool,
  productVariant:PropTypes.object,
}