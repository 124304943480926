import React from 'react';
import propTypes from 'prop-types';
import Loading from 'react-loading-animation';
import classNames from 'classnames';
import {
  Row,
  Col,
  Card,
  CardHeader,
  Form,
  FormGroup,
  Input,
  Alert,
  Label,
  CardBody
} from 'reactstrap';
import { getPaymentType, paymentStatusOptions, getPaymentVia } from '../../../../constants/kickavenue';
import { ButtonLoading } from '../../../../components/Button';
import { TextRupiah } from '../../../../components/Text';
import { errorMessage, hasError, _getValue, _getFloatValue, textChange } from '../../../../utils/form';
import {
  FieldRow,
  FieldColumn,
  FieldLabel,
  FieldValue
} from '../../../../styled/Fields';
import Analytics from "../../../../services/Analytics";

const DEFAULT_SUCCESS_MSG = 'Updating payment successfully.';
const DEFAULT_ERROR_422_MSG = 'Please fulfill your form.';
export default class PaymentDetailCard extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      id: props.id,
      _isSubmit: false,
      _error: null,
      _isSuccessAlertDisplayed: false,
      _isErrorAlertDisplayed: false,
      _successMsg: DEFAULT_SUCCESS_MSG,
      _errorMsg: DEFAULT_ERROR_422_MSG,
      _disabledDropdownStatus: false
    }
    this._onSubmit = this._onSubmit.bind(this);
    this._handleTextChange = this._handleTextChange.bind(this);
    this._alertSuccessToggle = this._alertSuccessToggle.bind(this);
    this._alertErrorToggle = this._alertErrorToggle.bind(this);
  }

  componentDidUpdate(prevProps){
    if(prevProps.isLoading && !this.props.isLoading){
      const { dataError, id, status, remarks, updated_at, payment_id } = this.props;
      let { _disabledDropdownStatus } = this.state;
      if(status == 'COMPLETED' || status == 'REJECTED' || status =='REFUNDED')
        _disabledDropdownStatus = true;
      this.setState({
        _error: dataError,
        updated_at,
        id:id,
        payment_id:payment_id?payment_id:id,
        status,
        remarks,
        _disabledDropdownStatus
      })
    }
    if(this.state._isSubmit && prevProps.isSubmit && !this.props.isSubmit){
      const { dataError, id, remarks, status, updated_at, payment_id } = this.props;
      if(dataError){
        this.setState({
          _isSubmit: false,
          _error: dataError,
          _isErrorAlertDisplayed: true,
          _errorMsg: dataError.status_code === 422? DEFAULT_ERROR_422_MSG: dataError.message
        })
      }
      else{
        let { _disabledDropdownStatus } = this.state;
        if(status == 'COMPLETED' || status == 'REJECTED' || status =='REFUNDED')
          _disabledDropdownStatus = true;
        this.setState({
          _error: null,
          _isSubmit: false,
          _isSuccessAlertDisplayed: true,
          _disabledDropdownStatus,
          id:id,
          payment_id:payment_id?payment_id:id,
          status,
          remarks,
          updated_at
        })
      }
    }
  }

  _handleTextChange(e){
    const lastState = this.state;
    // lastState[e.target.name] = e.target.value;
    this.setState({
      // ...lastState
      ...textChange(e, lastState)
    });
  }

  _onSubmit(e){
    e.preventDefault();
    this.setState({
      _isSubmit: true,
      _error: null
    },()=>{
      const { id, status, remarks, _isErrorAlertDisplayed, _isSuccessAlertDisplayed } = this.state;
      const { invoice_number, email } = this.props;
      Analytics.recordChangeStatusPayment(
        status, 
        remarks,
        invoice_number,
        email
			)
      this.props.onUpdatePayment(id, { status, remarks })
      if(_isErrorAlertDisplayed)
        this._alertErrorToggle();
      if(_isSuccessAlertDisplayed)
        this._alertSuccessToggle()
    })
  }

  _alertSuccessToggle(){
    this.setState({
      _isSuccessAlertDisplayed: !this.state._isSuccessAlertDisplayed
    })
  }

  _alertErrorToggle(){
    this.setState({
      _isErrorAlertDisplayed: !this.state._isErrorAlertDisplayed
    })
  }

  render(){
    return(
      <Card>
        <CardHeader>
          Payment Details
        </CardHeader>
        <CardBody>
        { this.props.isLoading?
          <Loading/>:
          <Form
            onSubmit={this._onSubmit}
          >
            <FieldRow>
              <FieldColumn className="col-xs-12 col-md-6">
                <FieldLabel>Payment ID</FieldLabel>
                <FieldValue>
                  <span>{_getValue(this.state.payment_id)}</span>
                </FieldValue>
              </FieldColumn>
            </FieldRow>
            <FieldRow>
              <FieldColumn className="col-12">
                <FieldLabel>
                  Invoice Number
                </FieldLabel>
                <FieldValue>
                  <span>{ _getValue(this.props.invoice_number) }</span>
                </FieldValue>
              </FieldColumn>
              <FieldColumn className="col-xs-12 col-md-6">
                <FieldLabel>Currency</FieldLabel>
                <FieldValue>
                  <span>{ _getValue(this.props.currency) }</span>
                </FieldValue>
              </FieldColumn>
              <FieldColumn className="col-xs-12 col-md-6">
                <FieldLabel>Qty</FieldLabel>
                <FieldValue>
                  <span>{ _getValue(this.props.quantity) }</span>
                </FieldValue>
              </FieldColumn>
              <FieldColumn className="col-xs-12 col-md-6">
                <FieldLabel>
                  Payment Method
                  {
                    this.props.payment_via !== null && this.props.payment_via === 'vt' && this.props.payment_method === "credit_card" ? 
                    <button type="button" className="ml-3 btn btn-sm btn-secondary" onClick={this.props.onOpenCreditCard} target="_blank" title="Show More Info"><i className="fa fa-info-circle"></i> More Info</button>
                    :
                    null
                  }
                </FieldLabel>
                <FieldValue>
                  <span>{ getPaymentType(this.props.payment_method) }</span>
                </FieldValue>
              </FieldColumn>
              <FieldColumn style={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-end'}} className="col-xs-12 col-md-6">
                <FieldLabel>Installment Type</FieldLabel>
                <FieldValue>
                  <span>{ _getValue(this.props.installments_type, '-') }</span>
                </FieldValue>
              </FieldColumn>
              <FieldColumn className="col-xs-12 col-md-6">
                <FieldLabel>Payment Via</FieldLabel>
                <FieldValue>
                  <span>{ _getValue(getPaymentVia(this.props.payment_via), '-') }</span>
                </FieldValue>
              </FieldColumn>
              <FieldColumn className="col-xs-12 col-md-6">
                <FieldLabel>{this.props.payment_method === 'credit_card' || this.props.payment_method === 'installments'? 'Acquiring Bank': 'Bank Code'}</FieldLabel>
                <FieldValue>
                  <span style={{ textTransform: 'uppercase' }}>
                  {
                    this.props.bank_code ? 
                    this.props.bank_code : '-'
                  }
                  </span>
                </FieldValue>
              </FieldColumn>
              <FieldColumn className="col-xs-12 col-md-6">
                <FieldLabel>Payment Info Field</FieldLabel>
                <FieldValue>
                  <span>{ _getValue(this.props.payment_info_field, '-') }</span>
                </FieldValue>
              </FieldColumn>
              <FieldColumn className="col-xs-12 col-md-6">
                <FieldLabel>Facebook Campaign</FieldLabel>
                <FieldValue>
                  <span>{ _getValue(this.props.facebook_ad_campaign, '-') }</span>
                </FieldValue>
              </FieldColumn>
              <FieldColumn className="col-12">
                <FieldLabel>Invoice URL</FieldLabel>
                <FieldValue>
                  <span className="break-all"> { _getValue(this.props.invoice_url, '-') }</span>
                </FieldValue>
              </FieldColumn>
            </FieldRow>
            <FieldRow>
              <FieldColumn className="col-xs-12 col-md-6">
                <FieldLabel>Payment Type</FieldLabel>
                <FieldValue>
                  <span>{ _getValue(this.props.payment_type, '-') }</span>
                </FieldValue>
              </FieldColumn>
              <FieldColumn className="col-xs-12 col-md-6">
                <FieldLabel>Points</FieldLabel>
                <FieldValue>
                  <span>{ _getValue(this.props.points, '-') }</span>
                </FieldValue>
              </FieldColumn>
            </FieldRow>
            <FieldRow>
              <FieldColumn className="col-xs-12 col-md-6">
                <FieldLabel>Courier</FieldLabel>
                <FieldValue>
                  <span>{ _getValue(this.props.ka_courier) }</span>
                </FieldValue>
              </FieldColumn>
              <FieldColumn className="col-xs-12 col-md-6">
                <FieldLabel>Courier Services</FieldLabel>
                <FieldValue>
                  <span>{ _getValue(this.props.ka_courier_option) }</span>
                </FieldValue>
              </FieldColumn>
              <FieldColumn className="col-xs-12 col-md-6">
                <FieldLabel>Courier Price</FieldLabel>
                <FieldValue>
                  <TextRupiah
                    prefix="Rp. "
                    value={_getFloatValue(this.props.ka_courier_price)}
                  />
                </FieldValue>
              </FieldColumn>
              <FieldColumn className="col-xs-12 col-md-6">
                <FieldLabel>Processing Fee</FieldLabel>
                <FieldValue>
                  <TextRupiah
                    prefix="Rp. "
                    value={_getFloatValue(this.props.processing_fee_calculated)}
                  />
                </FieldValue>
              </FieldColumn>
            </FieldRow>
            <FieldRow>
              <FieldColumn className="col-xs-12 col-md-6">
                <FieldLabel>Amount</FieldLabel>
                <FieldValue>
                  <TextRupiah
                    prefix="Rp. "
                    value={_getFloatValue(this.props.offer_amount)}
                  />
                </FieldValue>
              </FieldColumn>
              <FieldColumn className="col-xs-12 col-md-6">
                <FieldLabel>Unique Amount</FieldLabel>
                <FieldValue>
                  <TextRupiah
                    prefix="Rp. "
                    value={_getFloatValue(this.props.unique_amount)}
                  />
                </FieldValue>
              </FieldColumn>
              <FieldColumn className="col-xs-12 col-md-6">
                <FieldLabel>Administration Fee</FieldLabel>
                <FieldValue>
                  <TextRupiah
                    prefix="Rp. "
                    value={_getFloatValue(this.props.administration_fee)}
                  />
                </FieldValue>
              </FieldColumn>
            </FieldRow>
            <hr/>
            <FieldRow>
              <FieldColumn className="col-xs-12 col-md-6">
                <FieldLabel>Kick Credit</FieldLabel>
                <FieldValue>
                  <TextRupiah
                    prefix="Rp. "
                    value={_getFloatValue(this.props.wallet_amount)}
                  />
                </FieldValue>
              </FieldColumn>
              <FieldColumn className="col-xs-12 col-md-6">
                <FieldLabel>Kick Point</FieldLabel>
                <FieldValue>
                  <TextRupiah
                    prefix="Rp. "
                    value={_getFloatValue(this.props.lbalance_amount)}
                  />
                </FieldValue>
              </FieldColumn>
              <FieldColumn className="col-xs-12 col-md-6">
                <FieldLabel>Seller Credit</FieldLabel>
                <FieldValue>
                  <TextRupiah
                    prefix="Rp. "
                    value={_getFloatValue(this.props.scredit_amount)}
                  />
                </FieldValue>
              </FieldColumn>
            </FieldRow>
            <hr/>
            <FieldRow>
              <FieldColumn className="col-xs-12 col-md-6">
                <FieldLabel>Disc</FieldLabel>
                <FieldValue>
                  <TextRupiah
                    prefix="Rp. "
                    value={_getFloatValue(this.props.discount)}
                  />
                </FieldValue>
              </FieldColumn>
              <FieldColumn className="col-xs-12 col-md-6">
                <FieldLabel>Courier Disc.</FieldLabel>
                <FieldValue>
                  <TextRupiah
                    prefix="Rp. "
                    value={_getFloatValue(this.props.ka_courier_discount)}
                  />
                </FieldValue>
              </FieldColumn>
              <FieldColumn className="col-xs-12 col-md-6">
                <FieldLabel>Admin Fee Disc.</FieldLabel>
                <FieldValue>
                  <TextRupiah
                    prefix="Rp. "
                    value={_getFloatValue(this.props.admin_fee_discount)}
                  />
                </FieldValue>
              </FieldColumn>
              <FieldColumn className="col-12">
                <FieldLabel>Voucher</FieldLabel>
                <FieldValue>
                  <span>{ _getValue(this.props.voucherable_id && this.props.voucherable? this.props.voucherable.code: this.props.voucher_code) }</span>
                </FieldValue>
              </FieldColumn>
            </FieldRow>
            <FieldRow>
              <FieldColumn className="col-xs-12 col-md-6">
                <FieldLabel>Expiry</FieldLabel>
                <FieldValue>
                  <span>{ _getValue(this.props.expiry) }</span>
                </FieldValue>
              </FieldColumn>
            </FieldRow>
            <FieldRow>
              <FieldColumn className="col-xs-12 col-md-6">
                <FieldLabel>Created</FieldLabel>
                <FieldValue>
                  <span>{ _getValue(this.props.created_at) }</span>
                </FieldValue>
              </FieldColumn>
              <FieldColumn className="col-xs-12 col-md-6">
                <FieldLabel>Created Src.</FieldLabel>
                <FieldValue>
                  <span>{ _getValue(this.props.created_source, '-') }</span>
                </FieldValue>
              </FieldColumn>
              <FieldColumn className="col-xs-12 col-md-6">
                <FieldLabel>Updated</FieldLabel>
                <FieldValue>
                  <span>{ _getValue(this.state.updated_at) }</span>
                </FieldValue>
              </FieldColumn>
              <FieldColumn className="col-xs-12 col-md-6">
                <FieldLabel>Updated Src.</FieldLabel>
                <FieldValue>
                  <span>{ _getValue(this.props.updated_source, '-') }</span>
                </FieldValue>
              </FieldColumn>
            </FieldRow>
            <Row>
              <Col xs={12}>
                <Alert
                  color="success"
                  isOpen={this.state._isSuccessAlertDisplayed}
                  toggle={this._alertSuccessToggle}
                >
                  <strong>Success. </strong> {this.state._successMsg}
                </Alert>
                <Alert
                  color="danger"
                  isOpen={this.state._isErrorAlertDisplayed}
                  toggle={this._alertErrorToggle}
                >
                  <strong>Opps. </strong> {this.state._errorMsg}
                </Alert>
              </Col>
              <Col xs={12} md={6}>
                <FormGroup
                  className={classNames({'has-danger has-feedback':hasError('status',this.state._error)})}
                >
                  <Label for="_formPaymentDetailStatus">
                    Status
                  </Label>
                  <Input
                    id="_formPaymentDetailStatus"
                    type="select"
                    name="status"
                    disabled={this.state._disabledDropdownStatus}
                    value={_getValue(this.state.status)}
                    onChange={this._handleTextChange}
                  >
                    {
                      paymentStatusOptions.map((item, index)=><option value={item.value} key={index}>{item.name}</option>)
                    }
                  </Input>
                  { errorMessage('status',this.state._error) }
                </FormGroup>
              </Col>
              <Col xs={12}>
                <FormGroup
                   className={classNames({'has-danger has-feedback':hasError('remarks',this.state._error)})}
                >
                  <Label for="_formPaymentDetailRemarks">
                    Remarks
                  </Label>
                  <Input
                    id="_formPaymentDetailRemarks"
                    type="textarea"
                    name="remarks"
                    value={_getValue(this.state.remarks)}
                    onChange={this._handleTextChange}
                  />
                  { errorMessage('remarks',this.state._error) }
                </FormGroup>
              </Col>
            </Row>
            <FormGroup>
              <ButtonLoading
               isLoading={this.state._isSubmit}
              >
                Update
              </ButtonLoading>
            </FormGroup>
          </Form>
        }
        </CardBody>
    </Card>
    )
  }
}

PaymentDetailCard.propTypes = {
  id: propTypes.number.isRequired,
  dataError: propTypes.object,
  onUpdatePayment: propTypes.func.isRequired,
  isLoading: propTypes.bool,
  isSubmit: propTypes.bool,
}

PaymentDetailCard.defaultProps = {
  isLoading: false,
  isSubmit: false
}