import React from 'react';
import {
  Col,
  Card,
  CardHeader,
  CardBody,
  Row,
  Label,
  FormGroup
} from 'reactstrap';
import Loading from 'react-loading-animation';
import { TextRupiah } from '../../components/Text';

export default class ProductVariantCouriers extends React.Component{
  _openShippingRateModal = (e, item) => {
    e.preventDefault();
    this.props.openShippingRateModal({
      id: item.id,
      location_group:item.location_group,
      name: item.name,
      price:item.price,
    })
  }

  render(){
    return(
      <Card>
        <CardHeader>
          <i className="fa fa-align-justify"></i> Shipping Rates
        </CardHeader>
        <CardBody>
          <Loading isLoading={this.props.isLoading}>
            <Row style={{alignItems:'flex-end'}}>
              {this.props.shippingRates.map((item, keyId) => (
                <Col xs={6} key={keyId}>
                  <FormGroup>
                    <Label>
                      <strong>
                        {item.name}
                        <a
                          href="#"
                          className="ml-2"
                          style={{textDecoration: 'underline', color: '#4a4a4a', fontSize: '.75rem'}}
                          onClick={e => this._openShippingRateModal(e, item)}
                        >
                          <i className="fa fa-pencil"></i> edit
                        </a>
                        <br/>
                      </strong>
                      <TextRupiah prefix="Rp. " tag="span" value={item.price}/>
                    </Label>
                  </FormGroup>
                </Col>
              ))}
            </Row>
          </Loading>
        </CardBody>
      </Card>
    )
  }
}