
import { availableRoles } from '../config';
import { AUTH_TOKEN_KEY, AUTH_USER_KEY } from '../constants';
export const saleMenus = {
  'sales': 'Sales',
  'sales/all': 'All Sales',
  'sales/outstanding': 'Outstanding Sales',
  'sales/quality_control': 'Quality Control',
  'sales/legit_check': 'Legit Check',
  'sales/follow-up': 'Follow Up',
  'sales/pre-delivering': 'Pre-Delivering',
  'sales/delivering': 'Delivering',
  'sales/processed': 'Processed Sales',
  'sales/failed': 'Failed Sales',
}
export const saleMenusDelivering = {
  'sales/jnt-delivering': 'JNT',
  'sales/instant-delivering': 'Gosend/Grab',
  'sales/warehouse-delivering': 'Warehouse Delivering',
  'sales/other-delivering': 'Others',
}
export const consignmentMenus = {
  'consignment': 'Consignment',
  'consignment/all': 'All Consignment',
  'consignment/outstanding': 'Outstanding Consignment',
  'consignment/quality_control': 'Quality Control',
  'consignment/legit_check': 'Legit Check',
  // 'consignment/follow-up': 'Follow Up',
  'consignment/rack-assignment': 'Rack Assignment',
  'consignment/pre-delivering': 'Seller Take Out Pre-Delivering',
  'consignment/delivering': 'Seller Take Out Delivering',
  // 'consignment/processed': 'Processed Consignment',
  'consignment/failed': 'Failed Consignment'
}
export const routesList = [
  ...Object.keys(saleMenus),
  ...Object.keys(saleMenusDelivering),
  'stock',
  'sliders',
  'product_variants',
  'products',
  'categories',
  'brands',
  'bank_accounts',
  'vouchers',
  'users',
  'roles',
  'collections',
  'concierge',
  'concierge/create',
  'concierge/pending',
  'concierge/processed',
  'concierge/completed',
  'concierge/failed',
  'payments',
  'payments/create',
  'payments/disbursements',
  'sell_request',
  'seller_verification',
  'top_up',
  'payments/refund-to-balance',
  'midtrans',
  'warehouse',
  'disbursements',
  'rewards',
  'offers',
  'menus',
  '3rdparty_stock',
  'app_sections',
  'racks',
  'raffles',
  'point_shop',
  'raffle_requests',
  'marketing_budgets',
  'spinning_wheel',
  'pop_up_banner',
  'faqs',
  'product_addons',
  'timer',
  'videos',
  'survey',
  'option',
  'survey_option',
  'setting',
  'country',
  'push_notification',
  'articles',
  'metas',
  'bank_promo',
];

const additionalRoutes = [
  "components",
  "icons",
  "widgets",
  "charts",
]

export const rolesList = [
  {
    role: 'superadministrator',
    authRoute: [...routesList, ...additionalRoutes]
  },
  {
    role: 'administrator',
    authRoute: routesList
  },
  {
    role: 'user',
    authRoute: []
  },
  {
    role: 'unverified_user',
    authRoute: []
  },
  {
    role: 'seller',
    authRoute: []
  },
  {
    role: 'customer_service',
    authRoute: ['users', 'payments', 'raffles']
  },
  {
    role: 'supervisor',
    authRoute: [...Object.keys(saleMenus), ...Object.keys(saleMenusDelivering), 'stock', 'product_variants', 'sell_request', 'seller_verification', 'payments', 'warehouse', 'raffles', 'raffle_requests', 'marketing_budgets', 'product_addons', 'collections']
  },
  {
    role: 'sale_supervisor',
    authRoute: [...Object.keys(saleMenus), ...Object.keys(saleMenusDelivering), 'stock', 'product_variants', 'sell_request', 'warehouse', 'product_addons', 'collections']
  },
  {
    role: 'inventory_admin',
    authRoute: [
      'stock',
      'product_variants',
      'product_addons',
      'collections'
    ]
  }, {
    role: 'courier',
    authRoute: [
      ...Object.keys(saleMenus).filter(i => i === 'sales' || i === 'sales/delivering'),
      ...Object.keys(saleMenusDelivering),
    ]
  },
  {
    role: 'blog_admin',
    authRoute: ['articles', 'metas']
  },
  {
    role: 'cashier',
    authRoute: [
      'stock',
      'sales',
      'warehouse',
      'sales/all',
      'payments',
      'payments/create',
      'sales/delivering',
      'sales/warehouse-delivering',
      'sales/pre-delivering'
    ]
  }
];

export const authorization = (roles) => {
  const routes = [];
  for (let i = 0; i < rolesList.length; i++) {
    /**
     * cek roles
     */
    const myRoles = roles.filter(myRole => availableRoles.includes(myRole) && rolesList[i].role === myRole);
    const role = myRoles.length && myRoles[0];
    if (role) {
      if (rolesList[i].role === role) {
        const { authRoute } = rolesList[i];
        authRoute.forEach(aRoute => {
          if (!routes.includes(aRoute)) {
            routes.push(aRoute)
          }
        })
      }
    }
  }
  return routes.sort();
}

export const logout = (pathname, history) => {
  localStorage.removeItem(AUTH_TOKEN_KEY);
  localStorage.removeItem(AUTH_USER_KEY);
  history.push(`/login?next=${pathname}`);
};
