import React, { useMemo } from 'react';
import Style from './RowItem.module.css';
import Badge from "../../../../../components/Badges/BadgeThin";
import FontAwesomeIcon from "../../../../../components/Icons/FontAwesomeIcon";
import { TextRupiah } from "../../../../../components/Text";
import { Link } from "react-router-dom";
import {
    CANCELLED_STATUS,
    DELIVERING_STATUS,
    KA_RECEIVED_STATUS,
    NEW_STATUS, REFUNDED_STATUS,
    SELLER_SENT_STATUS, VERIFICATION_FAILED_STATUS,
    VERIFICATION_PASSED_STATUS,
    KA_RETURNED_SELLER_STATUS,
    ACTIVE_CONSIGNMENT_STATUS,
    CONSIGNMENT_REJECTED_STATUS,
    DONE_STATUS,
} from "../../../../../constants/kickavenue";
import DateToHuman from "../../../../../components/DateToHuman";
import ButtonStarred from "../../../../Sales/RowItem/ButtonStarred";

const getStatus = item => {
    if (item.force_cancelled_at !== null && item.status !== KA_RETURNED_SELLER_STATUS) {
        return 'Take Out Request';
    }
    if (item.status === NEW_STATUS) {
        return 'Waiting Seller Delivering';
    }
    if (item.status === SELLER_SENT_STATUS) {
        return 'Sneaker On The Way';
    }
    if (item.status === KA_RECEIVED_STATUS && item.ka_received != null) {
        return 'Verification Progress';
    }
    if (item.status === VERIFICATION_PASSED_STATUS && item.ka_verified != null) {
        return 'Verification Passed';
    }
    if (item.status === VERIFICATION_FAILED_STATUS) {
        return 'Verification Failed';
    }
    if (item.status === KA_RETURNED_SELLER_STATUS && item.ka_sent === null) {
        return 'Awaiting AWB Number';
    }
    if (item.status === DONE_STATUS && item.ka_sent != null) {
        return 'Returned to Seller';
    }
    if (item.status === CANCELLED_STATUS) {
        return item.force_cancelled_at != null ? 'Cancelled (Forced)' : 'Cancelled';
    }
    if (item.status === REFUNDED_STATUS && item.money_disbursed != null) {
        return 'Refunded';
    }
    if ((/^DONE_\w+/).test(item.status) || item.completed) {
        return 'Done';
    }
    if (item.status === KA_RETURNED_SELLER_STATUS) {
        return 'Returned to Seller';
    }
    if (item.status === ACTIVE_CONSIGNMENT_STATUS) {
        return 'Active Consignment';
    }
    if (item.status === CONSIGNMENT_REJECTED_STATUS) {
        return 'Rejected Consignment';
    }
    return 'Undefined';
};

export default function RowItem({ rowId, item, onOpenMarkedModal, isMarked }) {
    const sneakersCondition = useMemo(() => {
        if (item.user_sell?.sneakers_condition === 'BARU') {
            if (
                item.user_sell?.missing_accessories ||
                item.user_sell?.yellowing ||
                item.user_sell?.display_item ||
                item.user_sell?.sneakers_defect
            ) {
                return 'Standard 99%';
            }
            return 'Brand New';
        }

        return item.user_sell?.sneakers_condition;
    }, [item.user_sell]);

    const status = useMemo(() => getStatus(item), [item]);

    return (
        <tr>
            <th className="v-center" scope="row">
                {rowId}
            </th>
            <td className={Style['consignment-detail']}>
                <div className={Style['consignment-detail-upper-row']}>
                    {item.unread_notes_count > 0 && <span style={{ fontSize: '.65rem' }} className="mr-1 text-danger">
                        <FontAwesomeIcon iconType="circle" />
                    </span>}
                    <p className={Style['consignment-detail-upper-row__id']}>#{item.id}</p>
                    <p className={Style['consignment-detail-upper-row__consignment-id']}>{item.consignment_number}</p>
                </div>
                <div className={Style['consignment-detail-mid-upper-row']}>
                    <p className={Style['consignment-detail-mid-upper-row__display-name']}>{item?.user_sell?.product_variant?.display_name}</p>
                    <div className={Style['consignment-detail-mid-upper-row-info']}>
                        <p className={Style['consignment-detail-mid-upper-row-info__sku']}>({item?.user_sell?.product_variant?.SKU})</p>
                        <p className={Style['consignment-detail-mid-upper-row-info__color']}>{item?.user_sell?.product_variant?.colour}</p>
                    </div>
                </div>
                <div className={Style['consignment-detail-mid-lower-row']}>
                    <Badge
                        color="primary"
                        className="text-uppercase mr-1"
                    >
                        <FontAwesomeIcon iconType="tags" /> {item?.product_variant?.product?.category?.name}
                    </Badge>
                    <Badge
                        color="success"
                        className="text-uppercase mr-1"
                    >
                        <FontAwesomeIcon iconType="tag" /> {sneakersCondition}
                    </Badge>
                    <Badge
                        color="success"
                        className="text-uppercase mr-1"
                    >
                        <FontAwesomeIcon iconType="tag" /> {item?.user_sell?.stock_status ?? '-'}
                    </Badge>
                </div>
                <div className={Style['consignment-detail-lowerRow']}>
                    <Badge
                        color="primary"
                        className="mr-1"
                    >
                        {item.user.email}
                    </Badge>
                    <Badge
                        color="success"
                        className="text-uppercase mr-1"
                    >
                        Qty: {item?.user_sell?.quantity}
                    </Badge>
                    <Badge
                        color="success"
                        className="text-uppercase mr-1"
                    >
                        Size: {item?.user_sell?.size?.US}
                    </Badge>
                </div>
            </td>
            <td className="v-center">
                {item?.user_sell?.rack ?? '-'}
            </td>
            <td className="v-center">
                <TextRupiah tag="span" prefix="Rp. " value={item.price} />
                <div className="issued-wrapper" style={{ minWidth: 95 }}>
                    <a
                        href="#"
                        className="mr-1"
                        style={{ textDecoration: 'underline', color: '#4a4a4a', fontSize: '.75rem' }}
                        onClick={e => {
                            e.preventDefault();
                            onOpenMarkedModal();
                        }}
                    >
                        <i className="fa fa-pencil"></i> {item.notes_count > 0 ? 'view' : 'add'} notes
                    </a>
                    <ButtonStarred
                        title={isMarked ? "Remove mark!" : "Mark!"}
                        value={isMarked}
                        onClick={() => {
                            onOpenMarkedModal(item.marked);
                        }}
                    />
                </div>
            </td>
            <td className={Style['shipping-method']}>
                <p className={Style['shipping-method-title']}>{item?.seller_courier ?? '-'}</p>
                {/*<Link to={'#'} className={Style['shipping-method-info']}>Tracking Shipment</Link>*/}
            </td>
            <td className={Style['shipping-method']}>
                <p className={Style['shipping-method-title']}>{item?.ka_courier ?? '-'}</p>
                {/*{item?.seller_courier && <Link to={'#'} className={Style['shipping-method-info']}>Tracking Shipment</Link>}*/}
            </td>
            <td className="v-center">
                {status}
            </td>
            <td className="v-center">
                <DateToHuman value={item.updated_at} id={`tooltip_${rowId}`} />
            </td>
            <td className="v-center">
                <Link to={`/consignment/${item.id}`} className="btn btn-sm btn-secondary" target="_blank" title="Detail"><i className="fa fa-external-link"></i> Details</Link>
            </td>
        </tr>
    );
}
