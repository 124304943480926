import React from 'react';
import classNames from 'classnames';
import styled from 'styled-components';
import propTypes from 'prop-types';

const CardWrapperDiv = styled.div`
  > .row > .col-12 {
    padding: 0px;
  }
`

const CardWrapper = ({ className, children, animatedType, ...otherProps }) => (
  <CardWrapperDiv
    className={classNames("animated", className, animatedType)}
    {...otherProps}
  >
    { children }
  </CardWrapperDiv>
)

export default CardWrapper;

CardWrapper.defaultProps = {
  animatedType: "fadeIn"
}

CardWrapper.propTypes = {
  className: propTypes.string,
  animatedType: propTypes.string,
  children: propTypes.node
}