import React from 'react';
import Style from './ModalDeleteProductConfirmation.module.css';
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {SimpleLineIcon} from "../../../../../components/Icons";
import Pagination from "../ModalSelectProduct/Components/Pagination";

function ModalDeleteProductConfirmation(props) {
    const { onClose, onConfirm, productName, ...modalProps } = props;

    return <Modal {...modalProps} className={Style["modal-delete-product-confirmation"]}>
        <ModalHeader
            className={Style["modal-header"]}
            cssModule={{ "modal-title": "w-100" }}
        >
            <div className={Style["header-wrapper"]}>
                <p className={Style['header-text']}>Delete Product</p>
                <div className={Style["close-button"]} onClick={onClose}>
                    <SimpleLineIcon iconType="close" />
                </div>
            </div>
        </ModalHeader>
        <ModalBody>
            <div className={Style['body-wrapper']}>
                <p className={Style['body-text']}>Are you sure want to delete "{productName}" ?</p>
            </div>
        </ModalBody>
        <ModalFooter>
            <div className={Style["footer-wrapper"]}>
                <Button color="primary" onClick={onConfirm}>
                    Yes
                </Button>
                <Button color="secondary" onClick={onClose}>
                    No
                </Button>
            </div>
        </ModalFooter>
    </Modal>
}

export default ModalDeleteProductConfirmation;
