import React, {Component} from 'react';
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  CardBody,
  Row,
  Col
} from 'reactstrap';
import {connect} from 'react-redux';
import classnames from 'classnames';
import qs from 'query-string';

import { getDetailConsignment, doForceCancelledConsignment, doRollbackConsignment } from '../../../actions/consignmentAction';
import { getUserBankAccountList } from '../../../actions/userBankAccountAction';

import ConsignmentOrderDetail from './ConsignmentOrderDetail';
import ConsignmentAuditCard from './ConsignmentAuditCard';
import SellerDetail from './SellerDetail';
import SneakerDetail from './SneakerDetail';
import Header from './Header';
import privateView from '../../../components/hocs/privateView';
import ModalForceCancelled from './ModalForceCancelled';

import Analytics from "../../../services/Analytics";
import { ModalDelete as ModalRollback } from '../../../components/Modals';
import { KA_RECEIVED_STATUS, ACTIVE_CONSIGNMENT_STATUS } from '../../../constants/kickavenue';

class ConsignmentDetails extends Component{
  constructor(props){
    super(props)
    const DEFAULT_STATE = {
      activeTab:'order',
      id: parseInt(this.props.match.params.id),
      consignment: {},
      userSellConsignment: {},
      sellerConsignment: {},
      userSellImagesConsignment: [],
      legitCheckImagesConsignment:[],
   
      // userSell: {},
      // userSellImages:[],
      images: [],
      listingCategory: 'SNEAKERS',
      _modalCreditCard: false,
      _modalRollbackStatus:false,
      _isForceCancelledSubmit:false,
      shippingUpdate: null
    }
    const queries = Object.assign(DEFAULT_STATE,qs.parse(props.location.search));
    this.state={
      ...queries,
      _sellerBankAccountParams:{}
    };
    this._handleGotoPageSellerBankAccount = this._handleGotoPageSellerBankAccount.bind(this);
    this._handleOnForceCancelledClick = this._handleOnForceCancelledClick.bind(this);
    this._handleOnRollbackStatusClick = this._handleOnRollbackStatusClick.bind(this);
    this._toogleForceCancelledModal = this._toogleForceCancelledModal.bind(this);
    this. _handleOnRollbackSubmit = this._handleOnRollbackSubmit.bind(this);
    this. _handleOnSubmitForceCancelled = this._handleOnSubmitForceCancelled.bind(this);
  }

  isAfterLoaded(){
    return (!this.props.consignment.isFetch&&this.state.consignment.status_code===200);
  }

  componentDidUpdate(prevProps) {
    if(prevProps.consignment.isFetch&&prevProps.consignment.isFetch!==this.props.consignment.isFetch){
      const { error } = this.props.consignment;
      if(error)
        this.setState({
          _errorConsignment: error
        })
      else {
        const { user, user_sell, product_variant, sell_consignment_images, ...consignment } = this.props.consignment.detail;
        this.setState({
          userSellConsignment: user_sell,
          sellerConsignment: user,
          productConsignment: product_variant,
          legitCheckImagesConsignment: sell_consignment_images,
          userSellImagesConsignment: user_sell.user_sell_images,
          consignment,
        })
        this.props.getUserBankAccountList(user.id)
      }
    }
    if(prevProps.consignment.isSubmit && prevProps.consignment.isSubmit !== this.props.consignment.isSubmit){
      const { error } = this.props.consignment;
      if(error)
        this.setState({
          _errorConsignment: error,
          _isForceCancelledSubmit: false,
          _modalRollbackStatus:false,
        })
      else {
        const { user, user_sell, product_variant, sell_consignment_images, ...consignment } = this.props.consignment.detail;
        this.setState({
          userSellConsignment: user_sell,
          sellerConsignment: user,
          productConsignment: product_variant,
          legitCheckImagesConsignment: sell_consignment_images,
          userSellImagesConsignment: user_sell.user_sell_images,
          consignment,
          _modalRollbackStatus:false,
          _isForceCancelledModalOpen: false,
          _isForceCancelledSubmit: false
        })
      }
    }
  }

  componentDidMount(){
    const { email } = this.props.injectedProps;
    this.props.find(this.state.id);
    Analytics.recordPageView('consignment_detail', email, {
      url: window.location.href,
      location: this.props.location,
      match: this.props.match
    });
  }

  _handleOnForceCancelledClick(){
    this._toogleForceCancelledModal();
  }

  _handleOnRollbackStatusClick(){
    this.setState({_modalRollbackStatus: !this.state._modalRollbackStatus})
  }

  _toogleForceCancelledModal(){
    if(!this.state._isForceCancelledSubmit)
      this.setState({
        _isForceCancelledModalOpen: !this.state._isForceCancelledModalOpen
      })
  }

  _handleOnRollbackSubmit() {
    const { status } = this.props.consignment.detail;
    let statusValue = '';
    switch (status) {
      case KA_RECEIVED_STATUS:
        statusValue = 'sneakers_on_the_way';
        break;
      case ACTIVE_CONSIGNMENT_STATUS:
        statusValue = 'rack_assignment';
        break;
      default:
        statusValue = 'verification_progress';
        break;
    }
    const payload = {
      status: statusValue,
    };
    this.props.submitRollback(this.state.id, payload).then(() => {
      if (this.props?.consignment?.error) {
        this.setState({
          _errorConsignment: this.props?.consignment?.error,
          _modalRollbackStatus: true
        });
        return;
      };
      this.props.find(this.state.id);
    });
  }

  _handleGotoPageSellerBankAccount(page){
    const { user } = this.props.consignment.detail;
    const { _sellerBankAccountParams }=this.state;
    _sellerBankAccountParams.page=page;
    this.setState({
      _sellerBankAccountParams
    }, ()=>
      this.props.getUserBankAccountList(user.id, { params: this.state._sellerBankAccountParams })
    );
  }

  _onActiveTabChange(id){
    this.setState({activeTab:id}, ()=>{
      const { activeTab } = this.state;
      const { pathname } = this.props.location;
      const search = qs.stringify({
        activeTab
      });
      this.props.history.push({ pathname, search })
    });
  }

  _handleOnSubmitForceCancelled(id, payloads) {
    this.setState({
      _isForceCancelledSubmit: true,
      _errorConsignment: null
    }, ()=>{
      this.props.submitForceCancelled(id, payloads)
    })
  }

  onRenderConsignmentHeader(){
    if(!this.props.consignment.isFetch && this.state.consignment.status_code===200) {
      return (
        <Header
          id={this.state.id}
          consignment={this.state.consignment}
          roles={this.props.injectedProps.roles}
          status={this.state.consignment.status}
          consignmentNumber={this.state.consignment.consignment_number}
          productName={this.state.userSellConsignment.product_variant.display_name}
          category={this.state.productConsignment.product.category.name}
          createdAt={this.state.consignment.created_at}
          forceCancelledAt={this.state.consignment.force_cancelled_at}
          kaSent={this.state.consignment.ka_sent}
          onForceCancelledClick={this._handleOnForceCancelledClick}
          onRollbackStatusClick={this._handleOnRollbackStatusClick}
        />
      )
    }
    return null;
  }

  _handleToggleModalCreditCard = () => {
    this.setState({ _modalCreditCard: !this.state._modalCreditCard });
  }

  render(){
    return (
      <div className="animated fadeIn">
        <ModalForceCancelled
          id={this.state.id}
          consignmentStatus={this.state.consignment.status}
          isOpen={this.state._isForceCancelledModalOpen}
          isSubmit={this.state._isForceCancelledSubmit}
          errorData={this.state._errorConsignment}
          onCancel={this._toogleForceCancelledModal}
          onSubmit={this._handleOnSubmitForceCancelled}
        />
        <ModalRollback
          isOpen={this.state._modalRollbackStatus}
          modalTitle="Confirmation."
          modalBody="Are you sure to rollback this?"
          onDelete={this._handleOnRollbackSubmit}
          toggle={this._handleOnRollbackStatusClick}
          error={this.state._errorConsignment}
        />
        <Row>
          <Col xs={12}>
            <Card>
              <CardBody>
                {this.onRenderConsignmentHeader()}
                <Nav className="nav-tabs--custom" tabs>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: this.state.activeTab === 'order' })}
                      onClick={() => this._onActiveTabChange('order')}
                    >
                      Consignment Order Detail
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: this.state.activeTab === 'product' })}
                      onClick={() => this._onActiveTabChange('product') }
                    >
                      Product Detail
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: this.state.activeTab === 'seller' })}
                      onClick={() => this._onActiveTabChange('seller') }
                    >
                      Seller Detail
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent className="tab-content--custom" activeTab={this.state.activeTab}>
                    <TabPane className="tab-content--custom__tab-pane" tabId="order">
                      <ConsignmentOrderDetail
                        isLoading={!this.isAfterLoaded()}
                        order={this.state.consignment}
                        seller={this.state.sellerConsignment}
                        productConsignment={this.state.productConsignment}
                      />
                    </TabPane>
                    <TabPane className="tab-content--custom__tab-pane" tabId="product">
                      <SneakerDetail
                        consignment={this.state.consignment}
                        userSell={this.state.userSellConsignment}
                        isLegit={this.state.consignment.ka_verified}
                        productConsignment={this.state.productConsignment}
                        legitCheckImagesConsignment={this.state.legitCheckImagesConsignment}
                        userSellImagesConsignment={this.state.userSellImagesConsignment}
                        isLoading={!this.isAfterLoaded()}
                        listingCategory={this.state.listingCategory}
                      />
                    </TabPane>
                    <TabPane className="tab-content--custom__tab-pane" tabId="seller">
                      <SellerDetail
                        isLoading={!this.isAfterLoaded()}
                        sellerConsignment={this.state.sellerConsignment}
                        dataBankAccount={this.props.userBankAccount}
                        handleGotoPageBankAccountList={this._handleGotoPageSellerBankAccount}
                      />
                    </TabPane>
                </TabContent>

              </CardBody>
            </Card>
          </Col>
          {this.state.userSellConsignment.id && (
              <Col xs={12}>
                <div>
                  <ConsignmentAuditCard 
                    id={this.state.userSellConsignment.id}
                    type="user_sell"
                    refresh={!this.props.consignment.isFetch}
                  />
                </div>
              </Col>
            )}
        </Row>
      </div>
    )
  }
}

const mapStateToProps= ({consignment, userBankAccount, auth:{ isFetch, roles, isLogged, id, email } }) => {
	return {
    guardData: { isFetch, roles, isLogged, id, email },
    consignment, 
    userBankAccount
	}
};

const mapDispatchToProps = (dispatch) => {
	return{
    find:(id) => dispatch(getDetailConsignment(id)),
    getUserBankAccountList: (userId, queries) => dispatch(getUserBankAccountList(userId, queries)),
    submitForceCancelled: (id, payloads) => dispatch(doForceCancelledConsignment(id, payloads)),
    submitRollback: (id, payloads) => dispatch(doRollbackConsignment(id, payloads)),
	}
};

const enhance = connect(mapStateToProps,mapDispatchToProps);

export default enhance(privateView(ConsignmentDetails, ['supervisor', 'sale_supervisor', 'courier']));