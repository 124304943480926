import React from 'react';
import { connect } from 'react-redux';
import UserForm from './UserForm';
import { Container } from 'reactstrap';
import privateView from '../../components/hocs/privateView';
import Analytics from "../../services/Analytics";

class UserCreate extends React.Component{
	componentDidMount(){
    const { email } = this.props.injectedProps;
    Analytics.recordPageView('user_detail', email, {
      url: window.location.href,
      location: this.props.location,
      match: this.props.match
    });
  }
  render(){
    return(
      <Container>
        <UserForm
          title="Create New User"
          {...this.props}
        />
      </Container>
    )
  }
}

const mapStateToProps=({auth:{ isFetch, isLogged, roles, id, email }})=>({
  guardData: { isFetch, isLogged, roles, id, email }
})

const enhance = connect(mapStateToProps);
export default enhance(privateView(UserCreate, ['customer_service']));