import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Row,
  Col,
  Card,
  CardBody,
  CardText,
} from 'reactstrap';
import Paginations from '../../../components/Paginations';
import PaginationSummaries from '../../../components/Paginations/PaginationSummaries';
import { CardHeaderWithToolbars, Title } from '../../../components/Card/CardHeaderWithToolbars';
import Toolbars from './Filters/Toolbars';
import FiltersPanel from '../Filters';
import FilterContainer from '../../../components/Container/FilterContainer/FilterContainer';
import { Tbl, TblHead, TblBody } from '../../../components/Tbl';
import { ModalDeliver } from './Modals';
import { ModalBulkUpload } from '../../../components/Modals';
import ModalAddress from './ModalAddress';
import { generateNumber } from '../../../utils';
import { DEFAULT_SORT_BY, DEFAULT_PER_PAGE } from '../../../constants/settings';
import { getConsignmentList, doUpdateAwbNumber } from '../../../actions/consignmentAction';
import { doFetchProvinces, doFetchCities } from '../../../actions/userShippingAction';
import { saveUserAddress, updateUserAddress, getUserShippingList } from '../../../actions/userAction';
import { getCountryList } from "../../../actions/countryAction";
import RowHeader from './RowHeader';
import Analytics from "../../../services/Analytics";
import CourierRowItem from './CourierRowItem';
import { bulkAWBFormats } from '../../../config';
import { doBulkAWB } from '../../../actions/bulkUploadAwbAction';
import { bulkUploadAWBErrorMessageSelector, bulkUploadAWBIsSubmitSelector, bulkUploadAWBSuccessSelector } from '../../../reducers/selectors/bulkUploadAWBSelector';

const defaultParams = {
  sort_by: DEFAULT_SORT_BY,
  per_page: DEFAULT_PER_PAGE,
  type: "pending_delivering",
}

class CourierDelivering extends Component {
  constructor(props) {
    super(props);
    this.state = {
      _filterOpen: false,
      _params: {
        ...defaultParams,
        ka_courier: this.props.courier
      },
      _modalAddress: false,
      _formAddressSelected: null,
      _updateAddressProcess: false,
      _selectedRowAddress: null,
      user_id: 0,
      shipping_id: 0,

      _autodisburse: true,
      _selectedRow: null,
      _modalDeliver: false,
      _formDeliverSelected: null,
      _deliverProcess: false,
      _invoiceNumber: null,
      _dataModalPurchasePrice: null,
      _isModalPurchasePrice: false,
      _modalBulkUpload: false,
    }
    this._handleApplyFilter = this._handleApplyFilter.bind(this);
    this._handleToggleFilterPanel = this._handleToggleFilterPanel.bind(this);
    this._handleGoto = this._handleGoto.bind(this);
    this._refreshList = this._refreshList.bind(this);
    this._handleRefresh = this._handleRefresh.bind(this);
    this._handleResetFilter = this._handleResetFilter.bind(this);
    this._onCloseDeliverModal = this._onCloseDeliverModal.bind(this);
    this._onCloseAddressModal = this._onCloseAddressModal.bind(this);
    this._handleCategoryChanged = this._handleCategoryChanged.bind(this);
    this._handleToggleModalBulkUpload = this._handleToggleModalBulkUpload.bind(this);
    this._handleOnSubmitBulkUpload = this._handleOnSubmitBulkUpload.bind(this);
  }

  componentDidMount() {
    this._getList(this.state._params);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.consignment.isSubmit !== this.props.consignment.isSubmit) {
      if (!this.props.consignment.isSubmit && !this.props.consignment.error) {
        this._getList(this.state._params);
        this.setState({
          _deliverProcess: !this.state._deliverProcess,
          _modalDeliver: false,
          _selectedRow: null
        });
      }
    }

    if (prevProps.user.isSubmit && prevProps.user.isSubmit !== this.props.user.isSubmit) {
      if (this.props.user.status_code === 200) {
        this.setState({
          _updateAddressProcess: false,
          _modalAddress: !this.state._modalAddress,
          _selectedRowAddress: null,
        })
        this.props.getUserAddress(this.state.user_id)
      } else {
        this.setState({
          _updateAddressProcess: false,
          _selectedRowAddress: null,
        })
      }
    }

    if (prevProps.user.detail.user_shipping.isFetch && prevProps.user.detail.user_shipping.isFetch !== this.props.user.detail.user_shipping.isFetch) {
      if (this.props.user.detail.user_shipping.status_code === 200) {
        const listAddress = this.props.user?.detail?.user_shipping?.list?.data;
        const addressSelected = listAddress.find((a) => a.id === this.state.shipping_id);
        this.setState({
          _formDeliverSelected: {
            ...this.state._formDeliverSelected,
            shipping: addressSelected
          }
        })
      }
    }
  }

  _getList(rawParams = {}) {
    let p = { ...rawParams }
    if (p.voucher) {
      const { voucher, ...other } = p;
      if (Number(voucher.id)) {
        p = { ...other, voucher_id: voucher.id }
      }
      else {
        p = { ...other, voucher_code: voucher.code }
      }
    }
    if (p.seller && p.seller.id) {
      const { seller, ...other } = p;
      p = { ...other, seller_id: seller.id }
    }
    const params = Object.keys(p)
      .filter(key => p[key] != '')
      .reduce((obj, current) => ({ ...obj, [current]: p[current] }), {});
    this.props.getList({ params });
  }

  _refreshList() {
    // Exclude page...
    const _params = { ...this.state._params, page: 1 };
    this.setState({
      _params: { ..._params }
    }, () => this._getList(this.state._params));
  }

  _toggleFilterPanel() {
    this.setState({ _filterOpen: !this.state._filterOpen })
  }

  _handleToggleFilterPanel(e) {
    e.preventDefault();
    this._toggleFilterPanel();
  }

  _handleRefresh(e) {
    e.preventDefault();
    this._refreshList();
  }

  _handleResetFilter(e) {
    e.preventDefault();
    const newState = this.state._filterOpen ? { _filterOpen: !this.state._filterOpen } : {};
    const newParams = {
      ...this.state._params,
      keyword: "",
      rack: "",
      seller: "",
      sneakers_condition: "",
      status: "",
      stock_label: ""
    }
    this.setState({
      ...newState,
      _isResetFilter: true
    }, () => this._getList(newParams));
  }

  _handleApplyFilter(payloadFilter) {
    this.setState({
      _params: {
        ...this.state._params,
        ...payloadFilter,
        page: 1
      }
    }, () => this._getList(this.state._params));
  }

  _handleGoto(page) {
    const _params = this.state._params;
    _params.page = page;
    this.setState({ _params }, () => this._getList(this.state._params));
  }

  _togglePurchasePriceModal(value, payloads = null) {
    this.setState({
      _isModalPurchasePrice: value,
      _dataModalPurchasePrice: payloads ? { ...payloads } : null
    })
  }

  _handleToggleModalBulkUpload = () => {
    this.setState({
      _modalBulkUpload: !this.state._modalBulkUpload
    });
  }

  _handleOnSubmitBulkUpload = (payload) => {
    this.props.doBulkAWB(payload);
  }

  _onRenderRowItem() {
    const consignment = this.props.consignment.list.data;
    if (this.props.consignment.list.status_code === 200 && this.props.consignment.list.data.length > 0) {
      const { current_page, per_page } = this.props.consignment.list;
      return this.props.consignment.list.data.map((item, index) => {
        const rowId = generateNumber(per_page, current_page, index);
        return (
          <CourierRowItem
            key={rowId}
            rowId={rowId}
            consignment={consignment}
            id={item.id}
            quantity={item.user_sell.quantity}
            sellerEmail={item.seller_email}
            consignmentId={item.consignment_number}
            orderDate={item.created_at}
            updatedAt={item.updated_at}
            SKU={item.user_sell.product_variant?.SKU}
            displayName={item.user_sell.product_variant?.display_name}
            size={item.user_sell.size.US}
            colour={item.user_sell.product_variant?.colour}
            preOrder={item.user_sell.pre_order}
            preVerified={item.user_sell.pre_verified}
            category={item.product_variant.product.category.name}
            sneakersCondition={item.user_sell.sneakers_condition}
            legitCheckLogs={item.legit_check_logs}
            // notes={item.notes}
            status={item.status}
            marked={item.marked}
            markedNotes={item.marked_notes}
            markedAt={item.open_marked_at}
            markedBy={item.open_marked_by}
            closeMarkedAt={item.close_marked_at}
            unreadNoteCount={item.unread_notes_count}
            courier={item.ka_courier}
            kaSent={item.ka_sent}
            rack={item.rack_name}
            stockStatus={item.user_sell.stock_status}
            onOpenDeliverModal={() => this._onOpenDeliverModal(item)}
            openPurchasePriceModal={(payloads) => this._togglePurchasePriceModal(true, payloads)}
            createdAt={item.created_at}
            price={item.price}

            invoiceNumber={item.invoice_number}
            totalAmount={item.total_price}
            isSelected={this.state._selectedRow === item.id}
            autoDisburse={item.autodisburse}
            loginId={this.props.injectedProps.id}
            purchasePrice={item.purchase_price}
            compensation={item.compensation_amount}
          />
        )
      })
    }
    return null;
  }

  _onDelivery(id, data) {
    const { email } = this.props;
    const { _formDeliverSelected } = this.state;

    this.setState({ _deliverProcess: true }, () => {
      this.props.deliverSneaker(id, data);
      Analytics.recordDeliverOrder(
        email,
        data.notes,
        _formDeliverSelected.invoice_number,
        _formDeliverSelected.buyer_email,
        _formDeliverSelected.price
      );
    })
  }

  _onUpdateAddress(userId, shippingId, data) {
    this.setState({
      _updateAddressProcess: true,
      shipping_id: shippingId,
    })

    if (data.id) {
      this.props.updateAddress(this.state.user_id, shippingId, data)
    } else {
      this.props.saveAddress(this.state.user_id, data)
    }
  }

  _onOpenDeliverModal(item) {
    this.setState({
      _formDeliverSelected: item,
      _selectedRow: item.id,
      user_id: item.user_id
    }, () => this._toggle('_modalDeliver'));

    if (item.shipping && Object.keys(item.shipping).length === 0 || item.shipping === null) {
      this.props.getUserAddress(item.user_id);
    }
  }

  _onCloseDeliverModal() {
    this.setState({
      _formDeliverSelected: null,
      _selectedRow: null
    }, () => this._toggle('_modalDeliver'));
  }

  _onOpenAddressModal = async (data) => {
    if (!data) {
      await this.setState({
        _formAddressSelected: null,
        _selectedRowAddress: null,
      }, () => this._toggle('_modalAddress'));
    } else {
      await this.setState({
        _formAddressSelected: data,
        _selectedRowAddress: data.id,
      }, () => this._toggle('_modalAddress'));
    }
  }

  _onCloseAddressModal() {
    this.setState({
      _formAddressSelected: null,
      _selectedRowAddress: null
    }, () => this._toggle('_modalAddress'));
  }

  _toggle(_modalState) {
    const lastState = { ...this.state };
    lastState[_modalState] = !lastState[_modalState];
    this.setState(lastState);
  }

  _onRenderPagination() {
    const { status_code, data } = this.props.consignment.list;
    const hasData = (status_code === 200 && data.length) || null;
    return hasData && (
      <Paginations
        size="sm"
        lastPage={this.props.consignment.list.last_page}
        perPage={this.props.consignment.list.per_page}
        total={this.props.consignment.list.total}
        maxPage={8}
        path={this.props.consignment.list.path}
        currentPage={this.props.consignment.list.current_page}
        prevPageUrl={this.props.consignment.list.prev_page_url}
        nextPageUrl={this.props.consignment.list.next_page_url}
        handleGotoCallback={this._handleGoto}
      />
    );
  }

  async _handleCategoryChanged(category) {
    await this.setState({
      _params: {
        ...this.state._params,
        category: category === '' ? category : category.value,
        page: 1,
      },
    });
    const { _params } = this.state;
    this._getList(_params);
  }

  render() {
    return (
      <div className="animated fadeIn">
        <Row>
          <ModalDeliver
            isOpen={this.state._modalDeliver}
            onCancel={this._onCloseDeliverModal}
            onSubmit={(id, form) => this._onDelivery(id, form)}
            consignment={this.state._formDeliverSelected}
            error={this.props.consignment.error}
            isSubmit={this.props.consignment.isSubmit}
            onOpenAddressModal={(data) => this._onOpenAddressModal(data)}
            dataShipping={this.props.dataShipping}
          />
          <ModalAddress
            isOpen={this.state._modalAddress}
            onCancel={this._onCloseAddressModal}
            data={this.state._formAddressSelected}
            getCountries={this.props.getCountries}
            dataCountries={this.props.country}
            getProvinces={this.props.getProvinces}
            getCities={this.props.getCities}
            dataProvinces={this.props.dataProvinces}
            dataCities={this.props.dataCities}
            onSubmit={(userId, shippingId, data) => this._onUpdateAddress(userId, shippingId, data)}
            isSubmit={this.state._updateAddressProcess}
          />
          <ModalBulkUpload
            isOpen={this.state._modalBulkUpload}
            toggle={this._handleToggleModalBulkUpload}
            bulkFormat={bulkAWBFormats.reduce((prev,current)=>`${prev} & ${current}`)}
            onSubmit={this._handleOnSubmitBulkUpload}
            isLoading={this.props.bulkUploadAWBIsSubmit}
            errorMessage={this.props.bulkUploadAWBErrorMessage}
            successMessage={this.props.bulkUploadAWBSuccess}
          />
          <Col xs={12}>
            <Card>
              <CardHeaderWithToolbars>
                <Title>
                  <i className="fa fa-align-justify"></i>
                  <span>{this.props.title}</span>
                </Title>
                <Toolbars
                  isOpen={this.state._filterOpen}
                  onToggleFilter={this._handleToggleFilterPanel}
                  onRefreshList={this._handleRefresh}
                  onResetFilter={this._handleResetFilter}
                  onToggleModalBulkUpload={this._handleToggleModalBulkUpload}
                />
              </CardHeaderWithToolbars>
              <FilterContainer>
                <FiltersPanel
                  {...this.state._params}
                  onApplyFilter={this._handleApplyFilter}
                  isReset={this.state._isResetFilter}
                  onResetFilterCallback={() => this.setState({ _isResetFilter: false, _filterOpen: false })}
                  isOpen={this.state._filterOpen}
                />
              </FilterContainer>
              <CardBody>
                <CardText>
                  In this section we have to set awb number of the order and notify user after submit successfully.
                </CardText>
                <div className="table-responsive">
                  <PaginationSummaries
                    from={this.props.consignment.list.from}
                    to={this.props.consignment.list.to}
                    total={this.props.consignment.list.total}
                  />
                  <Tbl>
                    <TblHead>
                      <RowHeader
                        categoryName={this.state._params.category}
                        categoryValue={this.state._params.category}
                        onCategoryChanged={this._handleCategoryChanged}
                      />
                    </TblHead>
                    <TblBody
                      isLoad={this.props.consignment.isFetch}
                      hasRow={this.props.consignment.list.status_code === 200 && this.props.consignment.list.data.length > 0}
                      columnCount={7}
                    >
                      {this._onRenderRowItem()}
                    </TblBody>
                  </Tbl>
                </div>
                {this._onRenderPagination()}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const { consignment, sale, auth: { id: authId, isFetch, isLogged, roles, id, email }, userShipping, country, user } = state;
  return {
    consignment,
    sale,
    email,
    guardData: { isFetch, isLogged, roles, id: authId, id, email },
    dataCities: userShipping.cities,
    dataProvinces: userShipping.provinces,
    country,
    user,
    dataShipping: user.detail.user_shipping,
    bulkUploadAWBIsSubmit: bulkUploadAWBIsSubmitSelector(state),
    bulkUploadAWBErrorMessage: bulkUploadAWBErrorMessageSelector(state),
    bulkUploadAWBSuccess: bulkUploadAWBSuccessSelector(state),
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    getList: (query) => dispatch(getConsignmentList(query)),
    deliverSneaker: (id, payload) => dispatch(doUpdateAwbNumber(id, payload)),
    getCountries: (query) => dispatch(getCountryList(query)),
    getProvinces: () => dispatch(doFetchProvinces()),
    getCities: (payload) => dispatch(doFetchCities(payload)),
    saveAddress: (shippingId, payload) => dispatch(saveUserAddress(shippingId, payload)),
    updateAddress: (userId, shippingId, payload) => dispatch(updateUserAddress(userId, shippingId, payload)),
    getUserAddress: (userId) => dispatch(getUserShippingList(userId)),
    doBulkAWB: (payload) => dispatch(doBulkAWB(payload)),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(CourierDelivering);