import React, { Component } from 'react';
export default class Timer extends Component {
  render() {
    const { hours,  minutes, seconds } = this.props;
    return (
      <td>
        <div className="countdown-wrapper">
          {hours!==null&&(
            <div className="countdown-item">
              {hours}
              <span>hours</span>
            </div>
          )}
          {minutes!==null&&(
            <div className="countdown-item">
              {minutes}
              <span>minutes</span>
            </div>
          )}
          {seconds!==null&&(
            <div className="countdown-item">
              {seconds}
              <span>seconds</span>
            </div>
          )}
        </div>
      </td>
    );
  }
}

