import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Card,
  CardBody,
  CardText,
  Button,
  Alert
} from 'reactstrap';
import { deleteRaffleEntryByGroup, createRaffleEntry, getRaffleEntryListByGroup, updateRaffleEntry } from '../../../actions/raffleAction';
import { CardHeaderWithToolbars, Title } from '../../../components/Card/CardHeaderWithToolbars';
import { FontAwesomeIcon, SimpleLineIcon } from '../../../components/Icons';
import { Toolbars as ToolBarContainer } from '../../../components/Card/CardHeaderWithToolbars';
import { Tbl, TblHead, TblBody } from '../../../components/Tbl';
import Paginations from '../../../components/Paginations';
import PaginationSummaries from '../../../components/Paginations/PaginationSummaries';
import { generateNumber } from '../../../utils';
import DateToHuman from '../../../components/DateToHuman';
import Filters from './raffleEntry/Filters';
import { DEFAULT_PER_PAGE, DEFAULT_SORT_BY } from '../../../constants/settings';
import CheckReadOnly from '../../../components/CheckReadOnly';
import ModalCreateRaffleEntry from './Modals/ModalRaffleEntry';
import { ModalDelete } from '../../../components/Modals';

const sortOptions = [{
  value:"" , label:"Default"
},
{
  value:"updatedAt_desc" , label:"Last Updated"
},
{
  value:"createdAt_desc" , label:"Newest"
},
{
  value:"createdAt_asc" , label:"Older"
}];

const dataHeader=[
	'No.', 'User', 'Redeemed', 'Expired','Updated', 'Action'
];

const Toolbars = props => (
  <ToolBarContainer>
    <Button
      title="Refresh"
      className="with-toolbars__toolbars__btn"
      size="sm"
      color="info"
      onClick={props.onRefreshList}>
      <FontAwesomeIcon iconType="refresh"/>
    </Button>
    <Button
      title="Reset filter"
      className="with-toolbars__toolbars__btn"
      size="sm"
      color="warning"
      onClick={props.onResetFilter}>
      <FontAwesomeIcon iconType="ban"/>
    </Button>
  </ToolBarContainer>
)

const defaultParams = {
  keyword: '',
  status: '',
  sort_by : DEFAULT_SORT_BY,
  per_page : DEFAULT_PER_PAGE
}

class RaffleEntryList extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      _params: {...defaultParams},
      _isResetParams: false,
      _modalRaffleEntry: false,
      _selectedRaffleEntry: {
        id: null,
        user: null,
        raffleGroupId: null,
        golden: false,
        shortNote: "",
        expiry: null
      },
      _selectedId:null,
      _modalDelete:false,
      _isAlertErrorOpen:false,
      _isUpdate: false
    }

    this._toggle = this._toggle.bind(this);
    this._handleSubmit=this._handleSubmit.bind(this);
    this._removeSelected=this._removeSelected.bind(this);
    this._handleModalDeleteToggle = this._handleModalDeleteToggle.bind(this);
    this._handleDeleteUserVoucher = this._handleDeleteUserVoucher.bind(this);
    this._handleAlertErrorToggle = this._handleAlertErrorToggle.bind(this);
  }

  componentDidMount(){
    if(this.props.raffleGroupId){
      this._getList(this.state._params);
    }
  }

  componentDidUpdate(prevProps){
    if(prevProps.dataRaffle.details.isSubmit && !this.props.dataRaffle.details.isSubmit){
      const { error } = this.props.dataRaffle.details;
      if(error && this.state._modalDelete){
        this.setState({_modalDelete:false, _error:{...error}}, ()=>this._handleAlertErrorToggle());
      } else{
        this.setState({_modalDelete:false, _error:null, _isAlertErrorOpen:false}, ()=> this._handleGoto(1))
      }
    }
  }

  _toggle() {
    this.setState({ _isAlertErrorOpen:false, _modalRaffleEntry: !this.state._modalRaffleEntry })
  }

  _handleSubmit(payload, update = false) {
    if (update) {
      this.props.updateRaffleEntry(payload.id, payload)
    } else {
      this.props.createRaffleEntry(payload);
    }
  }

  _getList(rawParams = {}){
    const params = Object.keys(rawParams)
    .filter(key => rawParams[key]!='')
    .reduce((obj, current)=> ({...obj, [current]: rawParams[current]}), []);
    const { raffleGroupId } = this.props;
    this.props.getRaffleEntryList(raffleGroupId, {params});
  }

  _handleRefresh = (e) => {
    e.preventDefault();
    this._refreshList();
  }

  _refreshList = () => {
    const { _page, ..._params } = this.state._params;
    this.setState({
      _params
    },()=>this._getList(this.state._params));
  }

  _removeSelected() {
    this.setState({
      _error:null,
      _isUpdate: false,
      _isAlertErrorOpen:false,
      _selectedRaffleEntry: {
        id: null,
        user: null,
        raffleGroupId: null,
        golden: false,
        shortNote: "",
        expiry: null
      }
    })
  }

  _handleApplyFilter = p => {
    const { _params } = this.state;
    this.setState({
      _params: {
        ..._params,
        ...p,
        page: 1
      }
    }, () => {
      this._getList(this.state._params)
    })
  }

  _handleResetFilter = (e) =>{
    e.preventDefault();
    this.setState({
      _params:{
        ...defaultParams
      },
      _isResetParams: true
    }, () => {
      this.setState({ _isResetParams: false })
      this._getList(this.state._params)
    })
  }

	_handleGoto = page =>{
    this.setState({
      _params: {
        ...this.state._params,
        page
      }
    }, ()=> this._getList(this.state._params) );
  }

  _handleAlertErrorToggle(){
    this.setState({
      _isAlertErrorOpen: !this.state._isAlertErrorOpen
    })
  }

  _handleDeleteUserVoucher(){
    const { _selectedId } = this.state;
    this.props.deleteRaffleEntry(this.props.raffleGroupId, _selectedId)
  }

  _handleModalDeleteToggle(item){
    this.setState({
      _isAlertErrorOpen:false,
      _selectedId:item? item.id: null,
      _modalDelete: !this.state._modalDelete
    });
  }

  _renderErrorMsg(){
    const { _isAlertErrorOpen, _error } = this.state;
    if(_isAlertErrorOpen && _error!==null){
      switch(_error.status_code){
        case 422:
          return <Alert color="danger" isOpen={_isAlertErrorOpen} toggle={this._handleAlertErrorToggle}>Please fulfill your form</Alert>
        default:
          return <Alert color="danger" isOpen={_isAlertErrorOpen} toggle={this._handleAlertErrorToggle}>Houston, we have a problem!. Please inform our friendly staff via LINE @kickavenue</Alert>
      }
    }
    return null;
  }

  render(){
    return (
      <Card>
        <ModalDelete
          isOpen={this.state._modalDelete}
          toggle={this._handleModalDeleteToggle}
          onDelete={this._handleDeleteUserVoucher}
        />
        <ModalCreateRaffleEntry 
          isOpen={this.state._modalRaffleEntry}
          onCancel={this._toggle}
          update={this.state._isUpdate}
          selectedRaffleEntry={this.state._selectedRaffleEntry.id ? this.state._selectedRaffleEntry : null}
          onRemoveSelected={this._removeSelected}
          dataRaffleEntry={this.props.dataRaffle}
          raffleGroupId={this.props.raffleGroupId}
          dataAuth={this.props.dataAuth}
          onSubmit={this._handleSubmit}
          onGetRaffleEntryList={() => {
            this.setState({
              _params:{
                ...this.state._params,
                page: 1
              }
            });
            this._getList(this.state._params)
          }}
        />
        <CardHeaderWithToolbars>
          <Title>
            <FontAwesomeIcon iconType="align-justify"/>{' '}
            <span>Raffle Entry List</span>
          </Title>
          <Toolbars
            onRefreshList={this._handleRefresh}
            onResetFilter={this._handleResetFilter}
          />
        </CardHeaderWithToolbars>
        <CardBody>
          <div className="mb-3">
            <Button title="Create Raffle Entry" onClick={this._toggle}><i className="fa fa-plus"></i> Create Raffle Entry</Button>
          </div>
          <Filters
            {...this.state._params}
            isResetFields={this.state._isResetParams}
            sortOptions={sortOptions}
            onApplyFilter={this._handleApplyFilter}
          />
          <CardText>
            Raffle entries data.
          </CardText>
          <PaginationSummaries
            from={this.props.dataRaffle.data.from}
            to={this.props.dataRaffle.data.to}
            total={this.props.dataRaffle.data.total}
          />
          { this._renderErrorMsg() }
          <div className="table-responsive" ref={tableRef => this.tableRef = tableRef}>
            <Tbl>
              <TblHead
                dataHeader={dataHeader}
              />
              <TblBody
                isLoad={this.props.dataRaffle.isFetch}
                hasRow={this.props.dataRaffle.status_code===200&&this.props.dataRaffle.data.data.length>0}
                columnCount={dataHeader.length}
                renderChildren={() =>{
                  const { current_page, per_page, data } =  this.props.dataRaffle.data;
                  return data.map((item, key)=> {
                    const rowNumber = generateNumber(per_page,current_page,key);
                    return(
                      <tr key={item.id}>
                        <th className="v-center" scope="row">{ rowNumber }</th>
                        <td className="v-center">
                          <div>
                            {
                              item.variant_id?(
                                <div>
                                  {item.product_variant.display_name}
                                </div>
                              ): null
                            }
                            {item.size_id?(
                              <div className="mb-2">Size (US): {item.size.US}</div>
                            ): null}
                          </div>
                          <p className="mb-0">
                            <span className="text-muted mr-1">#{item.user_id}</span>
                            <Link to={`/users/${item.user_id}`} className="btn btn-link">
                              {item.user.typed_email? item.user.typed_email: item.user.email}
                            </Link><br/>
                            <span style={{fontSize: '.65rem'}}>
                              Note: {item.short_note?item.short_note: '-'}
                            </span>
                          </p>
                        </td>
                        <td className="v-center"><CheckReadOnly check={item.raffle_id}/></td>
                        <td className="v-center">{item.expiry || "-"}</td>
                        <td className="v-center">
                          <DateToHuman  value={item.updated_at} id={`tooltip_updated_at_${rowNumber}`}/>
                        </td>
                        <td className="v-center">
                          <Button color="danger" onClick={()=>this._handleModalDeleteToggle(item)}>
                            <SimpleLineIcon iconType="trash"/>
                          </Button>
                          <Button color="success" onClick={() => this.setState({
                            _isUpdate: true,
                            _selectedRaffleEntry: {
                              id: item.id,
                              user: {
                                id: item.user.id,
                                email: item.user.email
                              },
                              raffleGroupId: item.raffle_group_id,
                              golden: item.golden,
                              shortNote: item.short_note,
                              expiry: item.expiry
                            }
                          }, () => this._toggle())}>
                            <SimpleLineIcon iconType="pencil"/>
                          </Button>
                        </td>
                      </tr>
                    )
                  })
                }}
              />
            </Tbl>
          </div>
          { this.props.dataRaffle.status_code===200 && (
            <Paginations
              size="sm"
              lastPage={this.props.dataRaffle.data.last_page}
              perPage={this.props.dataRaffle.data.per_page}
              total={this.props.dataRaffle.data.total}
              maxPage={8}
              path={this.props.dataRaffle.data.path}
              currentPage={this.props.dataRaffle.data.current_page}
              prevPageUrl={this.props.dataRaffle.data.prev_page_url}
              nextPageUrl={this.props.dataRaffle.data.next_page_url}
              handleGotoCallback={this._handleGoto}
              onCurrentPageChanged={() => {
                window.scrollTo({
                  top: this.tableRef.offsetTop+25,
                  behavior: 'smooth'
                })
              }}
            />
          )}
        </CardBody>
      </Card>
    )
  }
}


const mapStateToProps=({ raffle })=>({
  dataRaffle: raffle.entries,
})
const mapDispatchToProps=(dispatch)=>({
  getRaffleEntryList: (raffleGroupId, queries) => dispatch(getRaffleEntryListByGroup(raffleGroupId, queries)),
  createRaffleEntry: (payload) => dispatch(createRaffleEntry(payload)),
  updateRaffleEntry: (id, payload) => dispatch(updateRaffleEntry(id, payload)),
  deleteRaffleEntry: (raffleGroupId, id) => dispatch(deleteRaffleEntryByGroup(raffleGroupId, id))
})

export default connect(mapStateToProps, mapDispatchToProps)(RaffleEntryList)