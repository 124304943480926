import React, { useCallback, useEffect, useState } from "react";
import { Card, CardBody, CardText, Col, Row } from "reactstrap";
import { Link } from "react-router-dom";
import get from "lodash/get";
import Paginations from "../../components/Paginations";
import PaginationSummaries from "../../components/Paginations/PaginationSummaries";
import {
  CardHeaderWithToolbars,
  Title,
} from "../../components/Card/CardHeaderWithToolbars";
import { Tbl, TblBody, TblHead } from "../../components/Tbl";
import { HTTP_STATUS } from "../../constants/kickavenue";
import DateToHuman from "../../components/DateToHuman";
import FilterContainer from "../../components/Container/FilterContainer/FilterContainer";
import Toolbars from "./Filters/Toolbars";
import FilterPanel from "./Filters";
import { useDispatch, useSelector } from "react-redux";
import { getBankPromoList } from "../../actions/bankPromoAction";
import {
  bankPromoIsFetchSelector,
  bankPromoListSelector,
} from "../../store/selectors/bankPromoSelector";
import { DEFAULT_PER_PAGE } from "../../constants/settings";

const dataHeader = [
  "Voucher Code",
  "Title",
  "Duration",
  "Status",
  "Last Updated",
  "Action",
];

const defaultParams = {
  per_page: DEFAULT_PER_PAGE,
};

const BankPromoIndex = () => {
  const dispatch = useDispatch();
  const bankPromoList = useSelector(bankPromoListSelector);
  const bankPromoIsFetch = useSelector(bankPromoIsFetchSelector);
  const [isOpen, setIsOpen] = useState(false);
  const [keyword, setKeyword] = useState("");

  const handleSubmitSearch = useCallback(
    (e) => {
      e.preventDefault();
      dispatch(getBankPromoList({ query: `keyword:${keyword}` }));
    },
    [dispatch, keyword]
  );

  const handleOnRefreshList = () => {
    const params = {
      ...defaultParams,
    };
    if (keyword) {
      params.query = `keyword:${keyword}`;
    }
    dispatch(getBankPromoList({ ...params }));
  };

  const onRenderRowItem = () => {
    return (get(bankPromoList, "data") || []).map((item, index) => (
      <tr key={item.id}>
        <td className="align-middle">
          {get(item, "voucher.code") && (
            <span className="ml-2">{get(item, "voucher.code") || ""}</span>
          )}
        </td>
        <td className="align-middle">{item.title}</td>
        <td className="align-middle">
          {item.started_at && <p className="mb-0">Start: {item.started_at}</p>}
          {item.ended_at && <p>End: {item.ended_at}</p>}
        </td>
        <td className="align-middle">
          {item.active ? (
            <i className="fa fa-check"></i>
          ) : (
            <i className="fa fa-remove"></i>
          )}
        </td>
        <td className="align-middle">
          <DateToHuman
            value={item.updated_at}
            id={`tooltip_updatedAt_${item.id}`}
          />
        </td>
        <td className="align-middle">
          <Link
            to={`/bank_promo/${item.id}`}
            target="_blank"
            className="btn btn-link"
          >
            <i className="fa fa-pencil"></i>
            <span className="ml-2">Details</span>
          </Link>
        </td>
      </tr>
    ));
  };

  useEffect(() => {
    dispatch(getBankPromoList({ ...defaultParams }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="animated fadeIn">
      <Row>
        <Col xs={12}>
          <Card>
            <CardHeaderWithToolbars>
              <Title>
                <i className="fa fa-align-justify"></i>
                <span>Bank Promo List</span>
              </Title>
              <Toolbars
                isOpen={isOpen}
                onToggleFilter={() => setIsOpen(!isOpen)}
                onRefreshList={handleOnRefreshList}
                onResetFilter={() => {
                  setKeyword("");
                  setIsOpen(false);
                  dispatch(getBankPromoList({ ...defaultParams }));
                }}
              />
            </CardHeaderWithToolbars>
            <FilterContainer>
              <FilterPanel
                isOpen={isOpen}
                keyword={keyword}
                onSubmitSearch={handleSubmitSearch}
                onChangeKeyword={setKeyword}
              />
            </FilterContainer>
            <CardBody>
              <CardText>In this section will display bank promo list.</CardText>
              <div className="table-responsive">
                <PaginationSummaries
                  from={get(bankPromoList, "from")}
                  to={get(bankPromoList, "to")}
                  total={get(bankPromoList, "total")}
                />
                <Tbl>
                  <TblHead dataHeader={dataHeader} />
                  <TblBody
                    hasRow={
                      get(bankPromoList, "status_code") === HTTP_STATUS.OK &&
                      get(bankPromoList, "data.length")
                    }
                    isLoad={bankPromoIsFetch}
                    columnCount={dataHeader.length}
                  >
                    {onRenderRowItem()}
                  </TblBody>
                </Tbl>
              </div>
              <Paginations
                size="sm"
                lastPage={get(bankPromoList, "last_page")}
                perPage={get(bankPromoList, "per_page")}
                total={get(bankPromoList, "total")}
                maxPage={8}
                path={get(bankPromoList, "path")}
                currentPage={get(bankPromoList, "current_page")}
                prevPageUrl={get(bankPromoList, "prev_page_url")}
                nextPageUrl={get(bankPromoList, "next_page_url")}
                handleGotoCallback={(page) =>
                  dispatch(getBankPromoList({ ...defaultParams, page }))
                }
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default BankPromoIndex;
