import React from 'react';
import propTypes from 'prop-types';
import { Tbl, TblHead, TblBody } from '../../../components/Tbl';
import Paginations from '../../../components/Paginations';
import { generateNumber } from '../../../utils';
import PaginationSummaries from '../../../components/Paginations/PaginationSummaries';
import RowItem from './failedSaleList/RowItem';
import RowHeader from './failedSaleList/RowHeader';
import { ModalDisburseCustomer } from './Modals';
import { ModalInfoCreditCard } from '../../../components/Modals';
import ModalPurchasePrice from '../ModalPurchasePrice';

const dataHeader=[
  'No.', 'Order', 'Price', 'Status', 'Updated', 'Action'
];

const onRenderItem = (data, perPage, currentPage, selectedRow, openModal, list, onOpenCreditCardModal, loginId, togglePurchasePriceModal) => {
  return data.map((item, index)=>{
    const rowNumber = generateNumber(perPage, currentPage, index);
    return (
      <RowItem
        key={`${rowNumber}__${item.id}`}
        dataItem={item}
        id={item.id}
        number={rowNumber}
        quantity={item.offer.quantity}
        buyerEmail={item.buyer_email}
        invoiceNumber={item.invoice_number}
        orderDate={item.created_at}
        updatedAt={item.updated_at}
        totalAmount={item.total_price}
        displayName={item.sale_product.display_name}
        SKU={item.sale_product.SKU}
        colour={item.sale_product.colour}
        preOrder={item.sale_product.pre_order}
        preVerified={item.sale_product.pre_verified}
        consignmentId={item.sale_product.consignment_id}
        sneakersCondition={item.sale_product.sneakers_condition}
        category={item.sale_product.category_name}
        size={item.sale_product.size.US}
        status={item.status}
        hijacked={item.hijacked}
        autoDisburse={item.autodisburse}
        forceCancelledAt={item.force_cancelled_at}
        canDisbursed={item.money_disbursed === null}
        selectedId={selectedRow && selectedRow.id}
        isSelected={selectedRow!==null && selectedRow.id === item.id}
        handleOpenModalDisburse={() => openModal(item)}
        list={list}
        bank={item.offer.bank_code}
        paymentVia={item.offer.payment_via}
        paymentMethod={item.offer.payment_method}
        onOpenCreditCard={()=> onOpenCreditCardModal(item)}
        marked={item.marked}
        notes={item.sale_notes}
        loginId={loginId}
        legitCheckLogs={item.legit_check_logs}
        // markedNotes={item.marked_notes}
        markedAt={item.open_marked_at}
        markedBy={item.open_marked_by}
        closeMarkedAt={item.close_marked_at}
        unreadNoteCount={item.unread_notes_count}
        rack={item.offer.user_sell.rack}
        purchasePrice={item.purchase_price}
        voucherCode={item.voucher_code? item.voucher_code: item.voucherable? item.voucherable.code: item.voucherable_id}
        openPurchasePriceModal={(payloads) => togglePurchasePriceModal(true, payloads)}
      />
    )
  })
}

export default class FailedSaleList extends React.Component {
  constructor(props){
    super(props);
    this.state={
      _list: [],
      _selectedItem: null,
      _isModalDisburseOpen: false,
      _disburseProcess: false,
      _modalCreditCard: false,
      _invoiceNumber: null,
      _dataModalPurchasePrice: null,
      _isModalPurchasePrice: false,
    }
    this._handleCloseModalDisburse = this._handleCloseModalDisburse.bind(this);
    this._handleToggleModalCreditCard = this._handleToggleModalCreditCard.bind(this);
  }

  // componentWillReceiveProps(nextProps) {
  //   if(!nextProps.isLoading && nextProps.isLoading !== this.props.isLoading){
  //     if(!this.props.dataErrorSale){
  //       this.setState({
  //         _list: this.props.list
  //       });
  //     }
  //   }
  // }

  componentDidUpdate(lastProps){
    if(lastProps.isLoading !== this.props.isLoading && !this.props.isLoading){
      this.setState({
        _list: this.props.list
      })
    }
    if(this.state._disburseProcess){
      if(lastProps.isSubmit && lastProps.isSubmit !== this.props.isSubmit){
        const { dataErrorSale, dataUpdatedSale } = this.props;
        if(!dataErrorSale){
          this.setState({
            _isModalDisburseOpen: false,
            _list: this.state._list.map(item=>{
              if(item.id===dataUpdatedSale.id){
                item.money_disbursed = dataUpdatedSale.money_disbursed;
                item.updated_at = dataUpdatedSale.updated_at;
                item.status = dataUpdatedSale.status;
              }
              return {...item}
            }),
            _disburseProcess: false,
            _selectedItem: null
          })
        }
        else{
          this.setState({
            _disburseProcess: false
          })
        }
      }
    }
  }

  _handleOpenModalDisburse(item){
    this.setState({
      _selectedItem: item,
      _isModalDisburseOpen: !this.state._isModalDisburseOpen
    })
  }

  _handleCloseModalDisburse(){
    //prevents close modal...
    if(this.state._disburseProcess) return;

    this.setState({
      _selectedItem: null,
      _isModalDisburseOpen: !this.state._isModalDisburseOpen
    })
  }

  _handleDisbursement(id, form){
    this.setState({
      _disburseProcess: true,
    }, ()=> this.props.handleSubmit(id, form))
  }

  _onOpenCreditCardModal(item){
    this.setState({_invoiceNumber: item.invoice_number},()=>this._handleToggleModalCreditCard());
  }

  _handleToggleModalCreditCard() {
    this.setState({_modalCreditCard: !this.state._modalCreditCard});
  }

  _togglePurchasePriceModal(value, payloads = null){
    this.setState({
      _isModalPurchasePrice: value,
      _dataModalPurchasePrice: payloads? { ...payloads }: null
    })
  }

  render(){
    return(
      <div className="animated fadeIn">
        <ModalPurchasePrice
          data={this.state._dataModalPurchasePrice}
          isOpen={this.state._isModalPurchasePrice}
          toggle={() => this._togglePurchasePriceModal(false)}
        />
        <ModalDisburseCustomer
          isOpen={this.state._isModalDisburseOpen}
          onCancel={this._handleCloseModalDisburse}
          onSubmit={(id, form)=>this._handleDisbursement(id, form)}
          sale={this.state._selectedItem}
          error={this.props.dataErrorSale}
          isSubmit={this.state._disburseProcess}
        />
        <ModalInfoCreditCard
          isOpen={this.state._modalCreditCard}
          toggle={this._handleToggleModalCreditCard}
          invoiceNumber={this.state._invoiceNumber}
        />
        <div className="table-responsive">
          <PaginationSummaries
            from={this.props.from}
            to={this.props.to}
            total={this.props.total}
          />
          <Tbl>
            <TblHead>
              <RowHeader
                categoryName={this.props.categoryValue}
                categoryValue={this.props.categoryValue}
                onCategoryChanged={this.props.onCategoryChanged}
              />
            </TblHead>
            <TblBody
              isLoad={this.props.isLoading}
              hasRow={this.props.statusCode===200 && this.props.list.length>0}
              columnCount={8}
              renderChildren={()=>
                onRenderItem(
                  this.state._list,
                  this.props.perPage,
                  this.props.currentPage,
                  this.state._selectedItem,
                  this._handleOpenModalDisburse.bind(this),
                  this.props.list,
                  this._onOpenCreditCardModal.bind(this),
                  this.props.loginId,
                  this._togglePurchasePriceModal.bind(this)
                )
              }
            />
          </Tbl>
        </div>
        <Paginations
          size="sm"
          lastPage={this.props.lastPage}
          perPage={this.props.perPage}
          total={this.props.total}
          maxPage={8}
          currentPage={this.props.currentPage}
          handleGotoCallback={this.props.handleGotoList}
        />
      </div>
    )
  }
}

FailedSaleList.defaultProps = {
  list: [],
  currentPage: 0,
  from: 0,
  to: 0,
  total: 0,
  statusCode: null,
  prevPageUrl: null,
  nextPageUrl: null,
  lastPage: 0,
  dataErrorSale: null,
  isLoading: false,
  isSubmit: false,
}

FailedSaleList.propTypes = {
  list: propTypes.arrayOf(propTypes.object),
  currentPage: propTypes.number,
  from: propTypes.number,
  to: propTypes.number,
  total: propTypes.number,
  statusCode: propTypes.oneOfType([propTypes.string, propTypes.number]),
  prevPageUrl: propTypes.string,
  nextPageUrl: propTypes.string,
  lastPage: propTypes.number,
  path: propTypes.string,
  list: propTypes.arrayOf(propTypes.object),
  isLoading: propTypes.bool,
  isSubmit: propTypes.bool,
  dataUpdatedSale: propTypes.object, //Props from redux
  dataErrorSale: propTypes.object, //Props from redux
  handleGotoList: propTypes.func.isRequired,
  handleSubmit: propTypes.func.isRequired
}

