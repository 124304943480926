import React, { PureComponent as Component } from 'react';
import {
  Card,
  CardText,
  Row,
  Col
} from 'reactstrap';
import propTypes from 'prop-types';
import PaymentSummaries from './details/PaymentSummaries';
import GeneralInfo from './details/GeneralInfo';

export default class Details extends Component {
  render(){
    const { isLoading, order, product, seller, payment, shipping } = this.props;
    return(
      <Card body className={this.props.className}>
        <CardText>Your order detail.</CardText>
        <Row>
          <Col xs={12} md={6}>
            <GeneralInfo
              isLoading={isLoading}
              order={order}
              product={product}
              seller={seller}
              shipping={shipping}
            />
          </Col>
          <Col xs={12} md={6}>
            <PaymentSummaries
              isLoading={isLoading}
              order={order}
              product={product}
              payment={payment}
              shipping={shipping}
              onOpenCreditCard={this.props.onOpenCreditCard}
            />
          </Col>
        </Row>
      </Card>
    )
  }
}
Details.propTypes= {
  isLoading: propTypes.bool,
  payment: propTypes.object,
  order: propTypes.object,
  product: propTypes.object,
  shipping: propTypes.object,
  seller: propTypes.object
}
Details.defaultProps = {
  isLoading: false
}