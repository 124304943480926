import React from 'react';
import propTypes from 'prop-types';
import {
  Form,
  FormGroup,
  Button,
  Row,
  Col,
  Input,
  Label
} from 'reactstrap';
import SelectUserAsync from '../../../components/Form/Select/Async/SelectUser';
import SelectRack from '../../../components/Form/Select/Async/SelectRack';

const warehouseStockStatusOptions = [
  { value: 'verification_passed', label: 'Verification Passed' },
  { value: 'verification_failed', label: 'Verification Failed' },
]


class StockFilterForm extends React.Component {
  constructor(props){
    super(props);
    this._handleOnSelectUserChange = this._handleOnSelectUserChange.bind(this);
    this._handleOnSelectUserOpen = this._handleOnSelectUserOpen.bind(this);
  }
  _handleOnSelectUserChange(seller){
    const { _recentUserSearch } = this.state;
    this.setState({
      _recentUserSearch: seller && _recentUserSearch
    })
    this.props.onSellerChange(seller);
  }
  _handleOnSelectUserOpen(){
    if(this.props.seller){
      return this.state._recentUserSearch&&this.refs.selectSeller.loadSelectOptions(this.state._recentUserSearch);
    }
    return this.refs.selectSeller.loadSelectOptions('');
  }

  render(){
    return (
      <Form className="form-filter" onSubmit={this.props.onSubmit}>
        <Row>
          <Col xs={12}>
            <div className="d-flex" style={{flexDirection: 'column'}}>
              <div style={{padding: "0px 1rem"}}>
                <FormGroup row>
                  <Col xs={12} sm={6}>
                    <Label className="justify-content-start">
                      Seller
                    </Label>
                    <SelectUserAsync
                      defaultOptions
                      id="formSelectSeller"
                      ref="selectSeller"
                      value={this.props.seller}
                      paramsApi={{ roles: 'user,seller,unsuspended_seller,administrator', role_query: 'or', scope: 'all' }}
                      placeholder="Type and select a seller"
                      noResultsText="Cannot find seller."
                      onSelectSearch={(input)=>this.setState({_recentUserSearch: input})}
                      onSelectChange={this._handleOnSelectUserChange}
                      onSelectOpen={this._handleOnSelectUserOpen}
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col xs={12} sm={6}>
                    <Label className="justify-content-start">
                      Rack
                    </Label>
                    <SelectRack
                      defaultOptions
                      id={this.props.filterRackId}
                      ref={this.props.filterRackRef}
                      value={this.props.rack}
                      paramsApi={{ limit: 50 }}
                      placeholder="Type and select a rack"
                      noResultsText="Cannot find rack."
                      loadOptions={this.props.getRackOptions}
                      onSelectSearch={this.props.onSelectSearch}
                      onSelectChange={this.props.handleSelectRack}
                      onSelectOpen={this.props.handleOnSelectRackOpen}
                    />
                  </Col>
                </FormGroup>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <div className="d-flex" style={{flexDirection: 'column'}}>
              <div className="d-flex" style={{padding: "0px 1rem"}}>
                <FormGroup className="d-flex" style={{flexDirection: 'column', alignItems: 'flex-start'}}>
                  <Label className="mb-2" style={{fontWeight: 700}}>
                    Stock Label
                  </Label>
                  <div className="d-flex ml-2 mr-2">
                    <FormGroup check className="mr-2">
                      <Label check>
                        <Input type="radio" name="stock_label" value="" checked={this.props.stock_label === ''} onChange={this.props.onCheckedChangeLabel} /> All
                      </Label>
                    </FormGroup>
                    <FormGroup check className="mr-2">
                      <Label check>
                        <Input type="radio" name="stock_label" value="ka_stock" checked={this.props.stock_label === 'ka_stock'} onChange={this.props.onCheckedChangeLabel} /> KA Stock
                      </Label>
                    </FormGroup>
                    <FormGroup check className="mr-2">
                      <Label check>
                        <Input type="radio" name="stock_label" value="seller_stock" checked={this.props.stock_label === 'seller_stock'} onChange={this.props.onCheckedChangeLabel} /> Seller Stock
                      </Label>
                    </FormGroup>
                  </div>
                </FormGroup>
              </div>
              <div className="d-flex" style={{padding: "0px 1rem"}}>
                <FormGroup className="d-flex" style={{flexDirection: 'column', alignItems: 'flex-start'}}>
                  <Label className="mb-2" style={{fontWeight: 700}}>
                    Stock Condition
                  </Label>
                  <div className="d-flex ml-2 mr-2">
                    <FormGroup check className="mr-2">
                      <Label check>
                        <Input type="radio" name="sneakers_condition" value="" checked={this.props.sneakers_condition === ''} onChange={this.props.onCheckedChange} /> All
                      </Label>
                    </FormGroup>
                    <FormGroup check className="mr-2">
                      <Label check>
                        <Input type="radio" name="sneakers_condition" value="BARU" checked={this.props.sneakers_condition === 'BARU'} onChange={this.props.onCheckedChange} /> Brand New
                      </Label>
                    </FormGroup>
                    <FormGroup check className="mr-2">
                      <Label check>
                        <Input type="radio" name="sneakers_condition" value="STANDARD_99" checked={this.props.sneakers_condition === 'STANDARD_99'} onChange={this.props.onCheckedChange} /> Standard 99%
                      </Label>
                    </FormGroup>
                    <FormGroup check className="mr-2">
                      <Label check>
                        <Input type="radio" name="sneakers_condition" value="BEKAS" checked={this.props.sneakers_condition === 'BEKAS'} onChange={this.props.onCheckedChange} /> Used
                      </Label>
                    </FormGroup>
                  </div>
                </FormGroup>
              </div>
              <div className="d-flex" style={{padding: "0px 1rem"}}>
                <FormGroup className="d-flex" style={{flexDirection: 'column', alignItems: 'flex-start'}}>
                  <Label className="mb-2" style={{fontWeight: 700}}>
                    Stock Status
                  </Label>
                  <div className="d-flex ml-2 mr-2">
                    <FormGroup check className="mr-2">
                      <Label check className="mr-3">
                        <Input
                          type="checkbox"
                          name="status"
                          value="default"
                          checked={this.props.isCheckedStatus('default')}
                          onChange={(e) => this.props.onStatusesInputChange(e)}
                        /> Default
                      </Label>
                    </FormGroup>
                    <FormGroup check className="mr-2">
                      <Label check className="mr-3">
                        <Input
                          type="checkbox"
                          name="status"
                          value="all"
                          checked={this.props.isCheckedStatus('all')}
                          onChange={(e) => this.props.onStatusesInputChange(e)}
                        /> All
                      </Label>
                    </FormGroup>
                    {warehouseStockStatusOptions.map((option, i) => (
                    <Label check className="mr-3" key={i}>
                      <Input
                        type="checkbox"
                        name="status"
                        value={option.value}
                        checked={this.props.isCheckedStatus(option.value)}
                        onChange={(e) => this.props.onStatusesInputChange(e)}
                      /> {option.label}
                    </Label>
                    ))}
                  </div>
                </FormGroup>
              </div>
              <FormGroup style={{alignSelf: 'flex-start', padding: "0px 1rem"}}>
                <Button color="primary">Apply Filter</Button>
              </FormGroup>
            </div>
          </Col>
        </Row>
      </Form>
    )
  }
}
export default StockFilterForm;

StockFilterForm.propTypes= {
  sneakers_condition: propTypes.oneOf(['','BARU','STANDARD_99','BEKAS']),
  sneakers_condition: propTypes.oneOf(['','ka_stock','seller_stock']),
  pre_order: propTypes.string,
  onSubmit: propTypes.func.isRequired
}

StockFilterForm.defaultProps= {
  sneakers_condition: '',
  stock_label: '',
  pre_order: ''
}