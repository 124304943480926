import React from 'react';
import { Button } from 'reactstrap';
import classNames from 'classnames';
import propTypes from 'prop-types';
import { Toolbars as ToolBarContainer } from '../../../../components/Card/CardHeaderWithToolbars';

const Toolbars = props => (
  <ToolBarContainer>
    <Button 
      type="button"
      title="Refresh"
      color="info"
      size="sm"
      className="with-toolbars__toolbars__btn"
      onClick={props.onRefreshList}
    >            
      <i className="fa fa-refresh"></i>
    </Button>
    <Button 
      type="button"
      title="Reset filter"
      color="warning"
      size="sm"
      className="with-toolbars__toolbars__btn"
      onClick={props.onResetFilter}
    >            
      <i className="fa fa-ban"></i>
    </Button>
    <Button 
      type="button"
      title="Search and Filter Data"
      size="sm"
      className={classNames('with-toolbars__toolbars__btn',{'btn-danger':props.isOpen})}
      onClick={props.onToggleFilter}
    >            
      <i className={classNames('fa', {'fa-sliders':!props.isOpen,'fa-minus':props.isOpen})}></i>
    </Button>
    <Button 
      type="button"
      color="success"
      title="Export Data"
      size="sm"
      className={classNames('with-toolbars__toolbars__btn',{'btn-success':props.isOpenExport})}
      onClick={props.onToggleExport}
    >            
      <i className={classNames('fa', {'fa-envelope-o':!props.isOpenExport,'fa-minus':props.isOpenExport})}></i>
    </Button>
  </ToolBarContainer>  
)

export default Toolbars;

Toolbars.propTypes = {
  isOpen: propTypes.bool,
  onToggleFilter: propTypes.func,
  onResetFilter: propTypes.func,
  onRefreshList: propTypes.func
}
Toolbars.defaultProps = {
  isOpen: false
}