import React, { Component } from "react"
import { connect } from "react-redux"
import {
  Alert,
  Form,
  Label,
  Row,
  Col,
  Input,
  FormGroup,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap"
import classNames from "classnames"
import { hasError, textValue } from "../../../utils/form"

import {
  createArticleMeta,
  deleteArticleMeta,
  getDetailArticleMeta,
  updateArticleMeta,
} from "../../../actions/articleAction"

import { ValidationMessage } from "../../../components/Form/ValidationMessage"
import privateView from "../../../components/hocs/privateView"
import SelectMetaAsync from "../../../components/Form/Select/Async/SelectMeta"

const initialState = {
  _edit: false,
  _hasId: false,
  _form: {
    binding: false,
    meta: null,
    meta_id: null,
    content: "",
    created_at: "",
    updated_at: "",
  },
  _exludeIds: "",
  _getExlcudeIds: false,
  _formSubmit: false,
  _modalDelete: false,
}

class ModalArticleMetaForm extends Component {
  constructor(props) {
    super(props)
    console.log(this.props.edit)
    this.state = { ...initialState }

    this.handleCheckedChange = this.handleCheckedChange.bind(this)
    this.onCloseModal = this.onCloseModal.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
    this._handleToggleModal = this._handleToggleModal.bind(this)
    this._handleSelectChange = this._handleSelectChange.bind(this)
    this._handleOnSelectMetaOpen = this._handleOnSelectMetaOpen.bind(this)
  }

  componentDidUpdate(prevProps) {
    const { detail, isSubmit, error } = this.props.article.meta

    if (this.props.metaId && this.props.edit && !this.state._hasId) {
      this.setState(
        {
          _edit: true,
          _hasId: true
        },
        () => {
          this.props.find(this.props.articleId, this.props.metaId)
        }
      )
    }

    if(this.props.article.meta.list.status_code === 200 && this.props.article.meta.list.data.length > 0 && !this.state._getExlcudeIds){
      this.setState({
        _exludeIds: this.props.article.meta.list.data.map((e) => e.id).join(','),
        _getExlcudeIds: true
      })
    }

    // Edit Form
    if (this.state._hasId) {
      // Checking if already ok after get detail from api
      if (
        prevProps.article.meta.detail.isFetch !== detail.isFetch &&
        !detail.isFetch &&
        detail.status_code === 200 &&
        error === null
      ) {
        // Binding data when load page and after get detail from api
        if (!detail.isFetch && !this.state._form.binding) {
          const _form = { ...this.state._form }
          _form.binding = true
          this.setState({ _form }, () => {
            const _newForm = { ...this.state._form, ...detail }
            _newForm.meta = {
              id: detail.id,
              name: detail.name
            }
            _newForm.meta_id = detail.id
            _newForm.content = detail.pivot.content
            this.setState({ _form: _newForm })
          })
        }
        // Binding data again when after success update in api
        else if (!isSubmit && this.state._formSubmit) {
          if (this.state._modalDelete) {
            this.toggle()
          }
          const _form = { ...this.state._form }
          _form.binding = true
          const _newForm = { ...this.state._form, ...detail }
          this.setState(
            { _formSubmit: !this.state._formSubmit, _form: _newForm },
            () => {
              this._handleToggleModal(true)
            }
          )
        }
      }
    }
    // Create Form
    else {
      // Checking if already ok after submit
      if (
        detail.status_code === 200 &&
        error === null &&
        this.state._formSubmit &&
        !isSubmit
      ) {
        this.setState({
          _form: { name: "", created_at: "", updated_at: "" },
          _formSubmit: !this.state._formSubmit,
        })

        this._handleToggleModal(true)
      }
    }
  }

  /**
   * Handle to change local state from input uset like textfield
   * @author haikal
   * @param {event} e
   */
  handleChange(e) {
    const newState = { ...this.state._form }
    newState[e.target.name] = e.target.value
    this.setState({ _form: newState })
  }
  handleCheckedChange(e) {
    const newState = { ...this.state._form }
    newState[e.target.name] = e.target.checked
    this.setState({ _form: newState })
  }
  onSubmit(e) {
    e.preventDefault()
    this.setState({ _formSubmit: true }, () => {
      const { meta_id, content } = this.state._form
      if (this.props.edit && this.state._hasId) {
        this.props.update(this.props.articleId, { meta_id, content })
      } else {
        this.props.create(this.props.articleId, { meta_id, content })
      }
    })
  }
  renderLoading() {
    return (
      <div className="row">
        <div className="col-sm-12">
          <Alert color="info" className="text-center">
            Submitting...
          </Alert>
        </div>
      </div>
    )
  }
  renderInfo() {
    let infoMessage = []
    if (this.props.article.meta.isSubmit)
      infoMessage.push(
        <Alert color="info" key="info">
          <strong>Heads up!</strong> Submitting...
        </Alert>
      )
    if (this.props.article.meta.success)
      infoMessage.push(
        <Alert color="success" key="success">
          <strong>Well done!</strong> {this.props.article.meta.success}
        </Alert>
      )
    if (this.props.article.meta.error !== null) {
      const { status_code } = this.props.article.meta.error
      switch (status_code) {
        case 422:
          infoMessage.push(
            <Alert color="danger" key="error">
              <strong>Oh Snap!</strong> Please fullfill your form.{" "}
            </Alert>
          )
          break
        case 404:
          infoMessage.push(
            <Alert color="danger" key="error">
              <strong>Oh Snap!</strong> Resource not found.{" "}
            </Alert>
          )
          break
        default:
          infoMessage.push(
            <Alert color="danger" key="error">
              <strong>Oh Snap!</strong> We've got something errors{" "}
            </Alert>
          )
          break
      }
    }
    return (
      <div className="row">
        <div className="col-sm-12">{infoMessage}</div>
      </div>
    )
  }

  validateError(name) {
    if (this.hasError(name)) {
      let errorList = this.props.article.meta.error.errors
      return <ValidationMessage message={errorList[name][0]} />
    }
    return ""
  }

  hasError(name) {
    if (this.props.article.meta.error) {
      if (this.props.article.meta.error.errors) {
        let errorList = this.props.article.meta.error.errors
        let errList = Object.keys(errorList).filter((key) => key == name)
        if (errList.length) return true
      }
    }
    return false
  }

  toggle() {
    this.setState({ _modalDelete: !this.state._modalDelete })
  }

  onCloseModal() {
    this.setState({ ...initialState })
  }

  onDelete() {
    this.setState({ _formSubmit: true, _modalDelete: false }, function () {
      this.props.delete(this.props.articleId, this.state._form.id)
    })
  }

  _handleToggleModal(update = false) {
    this.props.toggle(update)
  }

  _handleOnSelectMetaOpen() {
    if (this.state.roles) {
      return (
        this.state._recentMetaSearch &&
        this.refs.selectMeta.loadSelectOptions(
          this.state._recentMetaSearch
        )
      )
    }
    return this.refs.selectMeta.loadSelectOptions("")
  }

  _handleSelectChange(val) {
    this.setState({
      _form: {
        ...this.state._form,
        meta: val,
        meta_id: val.id
      },
      _recentMetaSearch: val && this.state._recentMetaSearch,
    })
  }

  render() {
    return (
      <Modal
        isOpen={this.props.isOpen}
        toggle={() => this._handleToggleModal(false)}
        onClosed={this.onCloseModal}
      >
        <ModalHeader toggle={() => this._handleToggleModal(false)}>
          {this.props.edit ? "Edit Article Meta" : "Create Article Meta"}
        </ModalHeader>
        <Modal
          isOpen={this.state._modalDelete}
          toggle={this.toggle.bind(this)}
          className="modal-dialog modal-sm"
        >
          <ModalHeader toggle={this.toggle.bind(this)}>
            Confirmation
          </ModalHeader>
          <ModalBody>
            {this.state._modalDelete && this.props.article.meta.isSubmit ? (
              <div className="row">
                <div className="col-sm-12">
                  <Alert color="info" className="text-center">
                    Deleting...
                  </Alert>
                </div>
              </div>
            ) : (
              <span>Are you sure to delete this?</span>
            )}
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.onDelete.bind(this)}>
              Yes
            </Button>{" "}
            <Button color="secondary" onClick={this.toggle.bind(this)}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
        <Form onSubmit={this.onSubmit.bind(this)}>
          <ModalBody>
            {this.renderInfo()}
            {this.props.article.meta.detail.isFetch ? (
              this.renderLoading()
            ) : (
              <Row>
                <Col xs={12}>
                  <FormGroup
                    className={
                      this.hasError("meta") ? " has-danger has-feedback" : ""
                    }
                  >
                    <Label for="formMeta">Meta Name</Label>
                    <SelectMetaAsync
                      id="formMeta"
                      isDisabled={this.state._edit}
                      // name="meta"
                      defaultOptions
                      ref="selectMeta"
                      paramsApi={{ sort_by: "createdAt_desc", exclude: this.state._exludeIds }}
                      placeholder="Select a meta..."
                      noResultsText="Cannot find meta."
                      value={this.state._form.meta}
                      onSelectSearch={(input) =>
                        this.setState({ _recentMetaSearch: input })
                      }
                      onSelectChange={this._handleSelectChange}
                      onSelectOpen={this._handleOnSelectMetaOpen}
                    />
                    {this.validateError("meta")}
                  </FormGroup>
                  <FormGroup
                    className={
                      this.hasError("content") ? " has-danger has-feedback" : ""
                    }
                  >
                    <Label for="formMetaContent">Content</Label>
                    <Input
                      type="text"
                      id="formMetaContent"
                      name="content"
                      placeholder="Enter a content*"
                      value={this.state._form.content}
                      onChange={this.handleChange.bind(this)}
                    />
                    {this.validateError("content")}
                  </FormGroup>
                </Col>
              </Row>
            )}
          </ModalBody>
          <ModalFooter>
            {this.props.edit && this.state._form.id && (
              <Button
                color="danger"
                onClick={this.toggle.bind(this)}
                disabled={this.props.article.meta.detail.isFetch}
              >
                Delete
              </Button>
            )}
            <Button
              type="submit"
              color="primary"
              disabled={this.props.article.meta.detail.isFetch}
              onClick={this.onSubmit}
            >
              {this.state.isSubmit ? "Submitting..." : "Submit"}
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    )
  }
}
const mapStateToProps = ({
  auth: { isFetch, roles, isLogged, id, email },
  article,
}) => {
  return {
    article: article,
    guardData: { isFetch, roles, isLogged, id, email },
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    find: (article_id, id) => dispatch(getDetailArticleMeta(article_id, id)),
    delete: (article_id, id) => dispatch(deleteArticleMeta(article_id, id)),
    update: (article_id, payload) => dispatch(updateArticleMeta(article_id, payload)),
    create: (article_id, payload) => dispatch(createArticleMeta(article_id, payload)),
  }
}

const enhance = connect(mapStateToProps, mapDispatchToProps)

export default enhance(privateView(ModalArticleMetaForm, ["blog_admin"]))
