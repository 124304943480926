import React from 'react';
import propTypes from 'prop-types';
import {
  Collapse,
  CardBody
} from 'reactstrap';
import SearchForm from './filterPanel/SearchForm';
import SortForm from './filterPanel/SortForm';
import FilterForm from './filterPanel/FilterForm';
import FilterContainer from '../../../components/Container/FilterContainer/FilterContainer';

class FilterPanel extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      keyword: props.keyword,
      status: props.status,
      payment_method: props.payment_method,
      per_page: props.per_page,
      sort_by: props.sort_by
    }
    this._handleOnChangeInput = this._handleOnChangeInput.bind(this);
    this._handleApplyFilterButtonClick = this._handleApplyFilterButtonClick.bind(this);
    this._handleOnChangeInputAndApply = this._handleOnChangeInputAndApply.bind(this);
    this._handleSearchButtonClick = this._handleApplyFilterButtonClick.bind(this);
  }

  _handleOnChangeInput(e){
    this.setState({ [e.target.name]: e.target.value });
  }

  _handleOnChangeInputAndApply(e){
    this.setState({ [e.target.name]: e.target.value }, ()=> this._handleSubmitFilter());
  }

  _handleApplyFilterButtonClick(e){
    e.preventDefault();
    this._handleSubmitFilter();
  }

  _handleSubmitFilter(){
    const { keyword, per_page, payment_method, status, sort_by } = this.state;
    this.props.onApplyFilter({ keyword, per_page, keyword, payment_method, status, sort_by });
  }

  componentDidUpdate(prevProps){
    if(!prevProps.isReset && this.props.isReset!==prevProps.isReset){
      const { keyword, payment_method, status, per_page, sort_by } = this.props;
      this.setState({
        keyword,
        per_page,
        sort_by,
        keyword,
        payment_method,
        status
      })
      this.props.onResetFilterCallback();
    }
  }

  render(){
    return (
      <FilterContainer>
        <Collapse isOpen={this.props.isOpen}>
          <CardBody className="filter-container__card-body">
            <SearchForm
              keyword = {this.state.keyword}
              onInputChange = {this._handleOnChangeInput}
              onSubmit = {this._handleSearchButtonClick}
            />
            <FilterForm
              status = {this.state.status}
              payment_method = {this.state.payment_method}
              onInputChange= {this._handleOnChangeInput}
              onSubmit = {this._handleApplyFilterButtonClick}
            />
            <hr/>
            <SortForm
              per_page={this.state.per_page}
              sort_by={this.state.sort_by}
              onInputChange={this._handleOnChangeInputAndApply}
            />
          </CardBody>
        </Collapse>
      </FilterContainer>
    )
  }
}

export default FilterPanel;

FilterPanel.defaultProps = {
  per_page: 15,
  keyword: '',
  isOpen: false,
  isReset: false
}

FilterPanel.propTypes = {
  keyword: propTypes.string,
  per_page: propTypes.oneOfType([propTypes.number, propTypes.string]),
  sort_by: propTypes.string,
  isOpen: propTypes.bool.isRequired,
  onApplyFilter: propTypes.func,
  onResetFilterCallback: propTypes.func,
}