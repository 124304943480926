import React from 'react';
import { connect } from 'react-redux';
import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  ModalFooter, Alert
} from 'reactstrap';
import Loading from 'react-loading-animation';

import { userStockExport } from '../../../actions/userAction';

class ModalUserStockExport extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      isSuccess: false,
      errorMessage: 'Something when wrong, please contact our developer.'
    }
  }

  componentDidUpdate(prevProps){
    if(!prevProps.isOpen && prevProps.isOpen !== this.props.isOpen){
      this.setState({
        errorMessage: null,
        isSuccess: false
      })
    }
    if(prevProps.userStockExport.isSubmit && prevProps.userStockExport.isSubmit !== this.props.userStockExport.isSubmit){
      const { error } = this.props.userStockExport;
      if(error){
        const { message, status_code } = error;
        let msg = 'Something when wrong, please contact our developer.';
        if(status_code === 404 && message === 'Data Not Found.'){
          msg = 'Active stock not found.';
        } else if(status_code === 404){
          msg = 'User not found.';
        }
        this.setState({
          errorMessage: msg,
          isSuccess: false
        })
      } else{
        this.setState({
          errorMessage: null,
          isSuccess: true
        })
      }
    }
  }

  onSubmit = () => {
    this.props.submitStockExport(this.props.userId)
  }

  render(){
    return(
      <Modal size="sm" className="modal-confirmation" isOpen={this.props.isOpen} toggle={this.props.toggle}>
        <ModalHeader toggle={this.props.toggle} className="modal-header--center">
          Export User Stock
        </ModalHeader>
        <ModalBody>
          <Loading isLoading={this.props.userStockExport.isSubmit}>
            {this.state.isSuccess?(
              <Alert color="success">
                <strong>Well done!</strong> Your requested data will be sent to your email, if you still haven't received your data in 30 minutes, please retry or contact our developer.
              </Alert>
            ): this.state.errorMessage?(
              <Alert color="danger">
                <strong>Oh Snap!</strong> {this.state.errorMessage}
              </Alert>
            ):(
              <p>
                Export {this.props.email}'s stock?<br/>
                By click submit, it will send the data as excel to your email.
              </p>
            )}
          </Loading>
        </ModalBody>
        {
          !this.state.isSuccess && !this.state.errorMessage? (
            <ModalFooter>
              <Button color="secondary" onClick={this.props.toggle}>Cancel</Button>
              <Button color="primary" onClick={this.onSubmit}>Submit</Button>{' '}
            </ModalFooter>
          ): null
        }
      </Modal>
    )
  }
}

const mapStateToProps = ({ auth, user: { userStockExport } }) => ({
  auth,
  userStockExport
});

const mapDispatchToProps = dispatch => ({
  submitStockExport: (userId)=>dispatch(userStockExport(userId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalUserStockExport);