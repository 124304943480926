import {
  TOP_UP_ERROR,  
  UPDATE_TOP_UP,
  FETCHING_TOP_UP, 
  GET_LIST_TOP_UP,
  TOP_UP_ROUTE_API,   
  GET_DETAIL_TOP_UP,  
  FETCH_TOP_UP_ERROR,
  AUTH_TOKEN_KEY
} from '../constants';

import { logout } from './authAction';

import api from '../utils/Api';
import { manipulateErrors, checkAuthorized } from '../utils';

/**
 * Get list of top-up
 * @author sopyan
 * @guards jwt-token 
 * @param {object?} query 
 */
export const getTopUpList=(query)=>{
  return dispatch =>(
    dispatch(isFetch({type:'fetch',status:true})),
    api({
      Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
    }).get(TOP_UP_ROUTE_API,query)
    .then(res=>{
      dispatch(onSuccess(res.data, GET_LIST_TOP_UP))
      dispatch(isFetch({ type: 'fetch', status: false }))
    })
    .catch(err=> {
      dispatch(onError(err));
      dispatch(isFetch({ type:'fetch', status: false}));
    })
  )
}

/**
 * Get Details of top-up
 * @author sopyan
 * @guards jwt-token 
 * @param {integer} id 
 */
export const getDetailTopUp=(id)=>{
  return dispatch => (
    dispatch(isFetch({type:'fetch',status:true})),
    api({
      Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
    }).get(`${TOP_UP_ROUTE_API}/${id}`)
    .then(res=>{
      dispatch(onSuccess(res.data, GET_DETAIL_TOP_UP));
      dispatch(isFetch({type:'fetch',status:false}));
    })
    .catch(err => {
      dispatch(onError(err));
      dispatch(isFetch({type:'fetch',status:false}))
    })
  )
}

/**
 * Update an top-up
 * @author sopyan
 * @guards jwt-token 
 * @param {integer} id 
 * @param {object} payload
 */
export const updateTopUp = (id, payload) =>{
  return dispatch => (
    dispatch(isFetch({type:'submit',status:true})),
    api({
      Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
    }).put(`${TOP_UP_ROUTE_API}/${id}`, payload)
    .then(res=>{
      dispatch(onSuccess(res.data,UPDATE_TOP_UP))
      dispatch(isFetch({type:'submit',status:false}))
    })
    .catch(err=>{
      dispatch(onError(err));
      dispatch(isFetch({type:'submit',status:false}));
    })
  )
}

/**
 * Indicates when start fetch api or end fetch api
 * @author sopyan
 * @guards jwt-token 
 * @param {boolean} bool 
 */
export const isFetch=(bool)=>(
  {
    type:FETCHING_TOP_UP,
    payload:bool
  }
)
/**
 * When onSuccess triggered
 * @author sopyan
 * @guards jwt-token 
 * @param {object} res 
 * @param {string} type 
 */
export const onSuccess=(res,type)=>(
  {
    type:type,
    payload:res
  }  
)
/**
 * When error fetch or connect to api
 * @author pewe
 * @param {object} err 
 */
export const onError=(err, type=TOP_UP_ERROR)=>{
  if(!checkAuthorized(manipulateErrors(err)))
    return logout();
  return {
    type,
    payload:manipulateErrors(err)
  }
}