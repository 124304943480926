import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Row,
  Col,
  Card,
  CardBody,
  CardText,
} from 'reactstrap';

import { CardHeaderWithToolbars,Title } from '../../../components/Card/CardHeaderWithToolbars';
// import Toolbars from './Filters/Toolbars';
// import FiltersPanel from './Filters';
import FiltersPanel from '../Filters';
import Toolbars from '../Processed/processedList/filterPanel/Toolbars';
import FilterContainer from '../../../components/Container/FilterContainer/FilterContainer';

import { DEFAULT_SORT_BY, DEFAULT_PER_PAGE } from '../../../constants/settings';
import { getSaleList,disburseSale } from '../../../actions/saleAction';
import privateView from '../../../components/hocs/privateView';
import FaildSaleList from './FailedSaleList';
import Analytics from "../../../services/Analytics";

const defaultParams = {
  sort_by : DEFAULT_SORT_BY,
  per_page : DEFAULT_PER_PAGE,
  type:'failed'
}

class FailedSales extends Component{
  constructor(props){
    super(props);
    this.state={
      _filterOpen: false,
      _params:{
        ...defaultParams
      },
      _paginations: {
        perPage: 0,
        from: 0,
        to: 0,
        total: 0,
        lastPage: 0,
        nextPageUrl: null,
        path: null,
        prevPageUrl: null
      }
    }
    this._handleGoto = this._handleGoto.bind(this);
    this._handleToggleFilterPanel = this._handleToggleFilterPanel.bind(this);
    this._handleRefresh = this._handleRefresh.bind(this);
    this._handleResetFilter = this._handleResetFilter.bind(this);
    this._handleApplyFilter = this._handleApplyFilter.bind(this);
    this._handleCategoryChanged = this._handleCategoryChanged.bind(this);
  }

  componentDidMount(){
    const { email } = this.props.injectedProps;
    this._refreshList();
    Analytics.recordPageView('failed_sales', email, {
      url: window.location.href,
      location: this.props.location,
      match: this.props.match
    });
  }

  componentDidUpdate(lastProps){
    if(lastProps.sale.isFetch !== this.props.sale.isFetch && !this.props.sale.isFetch){
      const { error } = this.props.sale;
      if(!error){
        const { list } = this.props.sale;
        this.setState({
          _paginations: {
            perPage: list.per_page,
            from: list.from,
            to: list.to,
            total: list.total,
            lastPage: list.last_page,
            currentPage: list.current_page,
            nextPageUrl: list.next_page_url,
            path: list.path,
            prevPageUrl: list.prev_page_url,
            statusCode: list.status_code
          }
        })
      }
    }
  }

  _getList(rawParams = {}){
    let p = { ...rawParams }
    if(p.voucher){
      const { voucher, ...other } = p;
      if(Number(voucher.id)){
        p = { ...other, voucher_id: voucher.id }
      }
      else{
        p = { ...other, voucher_code: voucher.code }
      }
    }
    if(p.seller && p.seller.id){
      const { seller, ...other } = p;
      p = { ...other, seller_id: seller.id }
    }
    const params = Object.keys(p)
    .filter(key => p[key]!='')
    .reduce((obj, current)=> ({...obj, [current]: p[current]}), {});
    this.props.getList({params});
  }

  _refreshList(){
    // Exclude page...
    const { page, ..._params } = this.state._params;
    this.setState({
      _params:{ ..._params }
    },()=>this._getList(this.state._params));
  }

  _toggleFilterPanel(){
    this.setState({_filterOpen:!this.state._filterOpen})
  }

  _handleApplyFilter(payloadFilter){
    this.setState({
      _params: {
        ...this.state._params,
        ...payloadFilter,
        page: 1
      }
    },()=>this._getList(this.state._params));
  }
  _handleToggleFilterPanel(e){
    e.preventDefault();
    this._toggleFilterPanel();
  }


  _handleRefresh(e){
    e.preventDefault();
    this._refreshList();
  }

  _handleResetFilter(e){
    e.preventDefault();
    const newState = this.state._filterOpen?{_filterOpen:!this.state._filterOpen}:{};
    this.setState({
      ...newState,
      _params:{ ...defaultParams }
    },()=>this._getList(this.state._params));
  }

  _handleGoto(page){
    const _params=this.state._params;
    _params.page=page;
    this.setState({_params}, ()=>this._getList(this.state._params));
  }

  async _handleCategoryChanged(category){
    await this.setState({
      _params: {
        ...this.state._params,
        category: category === ''? category: category.value,
        page: 1,
      },
    });
    const { _params } = this.state;
    this._getList(_params);
  }

  render(){
    return (
      <div className="animated fadeIn">
        <Row>
          <Col xs={12}>
            <Card>
              <CardHeaderWithToolbars>
                <Title>
                  <i className="fa fa-align-justify"></i>
                  <span>Failed Sale List</span>
                </Title>
                <Toolbars
                  isOpen={this.state._filterOpen}
                  onToggleFilter={this._handleToggleFilterPanel}
                  onRefreshList={this._handleRefresh}
                  onResetFilter={this._handleResetFilter}
                />
              </CardHeaderWithToolbars>
              <FilterContainer>
                <FiltersPanel
                  {...this.state._params}
                  isOpen={this.state._filterOpen}
                  onApplyFilter={this._handleApplyFilter}
                  isReset={this.state._isResetFilter}
                  onResetFilterCallback={()=>this.setState({_isResetFilter: false, _filterOpen: false})}
                />
              </FilterContainer>
              <CardBody>
                <CardText>
                  In this section all our failed sales. Lets disburse your funds into their balance.
                </CardText>
                <FaildSaleList
                  {...this.state._paginations}
                  loginId={this.props.injectedProps.id}
                  isLoading={this.props.sale.isFetch}
                  list={this.props.sale.list.data}
                  handleGotoList={this._handleGoto}
                  handleSubmit={(id, payloads) => this.props.disburse(id, payloads)}
                  isSubmit={this.props.sale.isSubmit}
                  dataUpdatedSale={this.props.sale.detail}
                  dataErrorSale={this.props.sale.error}
                  categoryValue={this.state._params.category}
                  onCategoryChanged={this._handleCategoryChanged}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    )
  }
}

const mapStateToProps= ({sale, auth: {id: authId, email, isFetch, isLogged, roles}}) => {
	return {
    sale,
    guardData: {isFetch, isLogged, roles, id: authId, email}
	}
};

const mapDispatchToProps = (dispatch) => {
	return {
      getList:(query) => dispatch(getSaleList(query)),
      disburse:(id,payload) => dispatch(disburseSale(id,payload))
	}
};
const enhance = connect(mapStateToProps,mapDispatchToProps);
export default enhance(privateView(FailedSales, ['supervisor','sale_supervisor']));