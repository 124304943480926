import {
  AUTH_TOKEN_KEY,
  METADATA_ROUTE_API,
  METADATA_ADD,
  METADATA_FETCHING,
  METADATA_ERROR,
  METADATA_LIST_FETCH,
  METADATA_DETAIL_FETCH,
  METADATA_UPDATE,
  METADATA_DELETE
} from '../constants';
import api from '../utils/Api';
import { manipulateErrors, checkAuthorized } from '../utils';
import { logout } from './authAction';

export const isFetch=(payload)=>(
	{
		type:METADATA_FETCHING,
		payload:payload		
	}
)

export const onSuccess=(res,type)=>
(
	{
		type:type,
		payload:res		
	}
)

export const onError=(err)=>{
	if(!checkAuthorized(manipulateErrors(err)))
		return logout();
	return {
		type:METADATA_ERROR,
		payload:manipulateErrors(err)
	}
}

export const getMetaDataList=(query)=>{
	return dispatch =>(
		dispatch(isFetch({type:'fetch',status:true})),
		api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).get(METADATA_ROUTE_API,query)
		.then(res=>{
			dispatch(onSuccess(res.data,METADATA_LIST_FETCH))
			dispatch(isFetch({type:'fetch',status:false}))
		})
		.catch(err => {
			dispatch(onError(err));
			dispatch(isFetch({type:'fetch',status:false}));
		})
	)
}

export const getDetailMetaData=(id)=>{
	return dispatch=>(
		dispatch(isFetch({type:'fetch',status:true})),
		api({
			Authorization:'Bearer '+localStorage.getItem(AUTH_TOKEN_KEY)
		}).get(`${METADATA_ROUTE_API}/${id}`)
		.then(res => {
			dispatch(onSuccess(res.data,METADATA_DETAIL_FETCH));
			dispatch(isFetch({type:'fetch',status:false}));
		})
		.catch(err => {
			dispatch(onError(err));
			dispatch(isFetch({type:'fetch',status:false}));
		})		
	)
}

export const createMetaData=(payload)=>{
	return dispatch =>(
		dispatch(isFetch({type:'submit',status:true})),
				api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).post(`${METADATA_ROUTE_API}`,payload)
		.then(res=>{
			dispatch(onSuccess(res.data,METADATA_ADD))
			dispatch(isFetch({type:'submit',status:false}))
		})
		.catch(err => {
			dispatch(onError(err));
			dispatch(isFetch({type:'submit',status:false}));
		})
	)
}

export const updateMetaData=(id, payload)=>{
	return dispatch => (
		dispatch(isFetch({type:'submit',status:true})),
		api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).put(`${METADATA_ROUTE_API}/${id}`,payload)
		.then(res=>{
			dispatch(onSuccess(res.data,METADATA_UPDATE))
			dispatch(isFetch({type:'submit',status:false}))
		})
		.catch(err => {
			dispatch(onError(err));
			dispatch(isFetch({type:'submit',status:false}));
		})
	)
}

export const deleteMetaData=(id)=>{
	return dispatch =>(
		dispatch(isFetch({type:'submit',status:true})),
				api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).delete(`${METADATA_ROUTE_API}/${id}`)
		.then(res=>{
			dispatch(onSuccess(res.data,METADATA_DELETE))
			dispatch(isFetch({type:'submit',status:false}))
		})
		.catch(err => {
			dispatch(onError(err));
			dispatch(isFetch({type:'submit',status:false}));
		})
	)
}