import React, { Component } from "react";
import { connect } from "react-redux";
import "react-select/dist/react-select.css";
import classNames from "classnames";
import InputDatetime from "../../../components/Form/InputDatetime/InputDatetime";

import {
  Row,
  Card,
  CardHeader,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  Alert,
  Button,
  ButtonGroup,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "reactstrap";
import {
  hasError,
  textValue,
  dateChange,
  dateValue,
  checkChange,
} from "../../../utils/form";
import { Link } from "react-router-dom";
import privateView from "../../../components/hocs/privateView";
import {
  createTimer,
  deleteTimer,
  updateTimer,
} from "../../../actions/timerAction";
import { ValidationMessage } from "../../../components/Form/ValidationMessage";

class FormCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      _form: {
        _id: this.props.edit ? this.props.match.params.id : null,
        name: "",
        content: "",
        started_at: "",
        ended_at: "",
        active: false,
        binding: false,
      },
      _formSubmit: false,
      _modalDelete: false,
      isDeleting: false
    };

    this.handleCheckedChange = this.handleCheckedChange.bind(this);
    this.toggleModalDelete = this.toggleModalDelete.bind(this);
    this.onDelete = this.onDelete.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { detail, isFetch, isSubmit, error } = this.props.timer;

    if (this.props.edit && detail.status_code === 200) {
      if (prevProps.timer.isFetch && !isFetch && !this.state._form.binding) {
        const _form = { ...this.state._form };
        _form.binding = true;
        this.setState({ _form }, () => {
          const _newForm = { ...this.state._form, ...detail };
          this.setState({ _form: _newForm });
        });
      }
    } 

    if (this.state._formSubmit &&
      prevProps.timer.isSubmit && !isSubmit
    ) {
      if (this.state.isDeleting) {
        this.setState({ isDeleting: false });
        this.toggleModalDelete();
        this.props.history.replace('/timer')
        return
      }
      if (error === null) {
        if(!this.state._form._id && detail.id){
          this.props.history.push(`/timer/${detail.id}`)
        }
        this.setState({
          _formSubmit: !this.state._formSubmit
        });
      }
    }
  }

  renderLoading() {
    return (
      <div className="row">
        <div className="col-sm-12">
          <Alert color="info" className="text-center">
            Getting all data...
          </Alert>
        </div>
      </div>
    );
  }

  renderInfo() {
    let infoMessage = [];
    if (this.props.timer.isSubmit)
      infoMessage.push(
        <Alert color="info" key="info">
          <strong>Heads up!</strong> Submitting...
        </Alert>
      );
    if (this.props.timer.success)
      infoMessage.push(
        <Alert color="success" key="success">
          <strong>Well done!</strong> {this.props.timer.success}
        </Alert>
      );
    if (this.props.timer.error !== null) {
      const { status_code, message } = this.props.timer.error;
      switch (status_code) {
        case 422:
          infoMessage.push(
            <Alert color="danger" key="error">
              <strong>Oh Snap!</strong> Please fullfill your form.{" "}
            </Alert>
          );
          break;
        case 404:
          infoMessage.push(
            <Alert color="danger" key="error">
              <strong>Oh Snap!</strong> Resource not found.{" "}
            </Alert>
          );
          break;
        default:
          infoMessage.push(
            <Alert color="danger" key="error">
              <strong>Oh Snap!</strong> {message}
            </Alert>
          );
          break;
      }
    }
    return (
      <div className="row">
        <div className="col-sm-12">{infoMessage}</div>
      </div>
    );
  }

  handleChange(e) {
    const newState = { ...this.state._form };
    newState[e.target.name] = e.target.value;
    this.setState({ _form: newState });
  }

  handleCheckedChange(e) {
    const _form = checkChange(e, this.state._form);
    this.setState({ _form: { ..._form } });
  }

  validateError(name) {
    if (this.hasError(name)) {
      let errorList = this.props.timer.error.errors;
      return <ValidationMessage message={errorList[name][0]} />;
    }
    return "";
  }

  handleDatePickerChange(value, name) {
    const _form = dateChange(value, name, this.state._form);
    this.setState({ ..._form });
  }

  hasError(name) {
    if (this.props.timer.error) {
      if (this.props.timer.error.errors) {
        let errorList = this.props.timer.error.errors;
        let errList = Object.keys(errorList).filter((key) => key == name);
        if (errList.length) return true;
      }
    }
    return false;
  }

  toggleModalDelete() {
    const { _modalDelete } = this.state
    this.setState({ _modalDelete: !_modalDelete })
  }

  onSubmit(e) {
    e.preventDefault();
    this.setState({ _formSubmit: true }, () => {
      const {
        _id,
        name,
        content,
        image_url,
        colour,
        terms_url,
        started_at,
        ended_at,
        active,
      } = this.state._form;
      if (this.props.edit) {
        this.props.update(_id, {
          name,
          content,
          started_at,
          image_url,
          terms_url,
          colour,
          ended_at,
          active,
        });
      } else {
        this.props.new({ name, content, started_at, ended_at, colour, image_url, terms_url});
      }
    });
  }

  onDelete(e) {
    e.preventDefault()
    const { _id } = this.state._form
    if (this.props.edit && _id) {
      this.setState({ _formSubmit: true, isDeleting: true }, () => {
        this.props.delete(_id)
      })
    }
  }

  render() {
    return (
      <Row>
        <Modal
          isOpen={this.state._modalDelete}
          toggle={this.toggleModalDelete}
          className="modal-dialog modal-sm"
        >
          <ModalHeader toggle={this.toggleModalDelete}>
            Confirmation
          </ModalHeader>
          <ModalBody>
            {this.state.isDeleting &&
            this.props.timer.isSubmit ? (
              <div className="row">
                <div className="col-sm-12">
                  <Alert color="info" className="text-center">
                    Deleting...
                  </Alert>
                </div>
              </div>
            ) : (
              <span>Are you sure to delete this?</span>
            )}
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.onDelete}>
              Yes
            </Button>{" "}
            <Button color="secondary" onClick={this.toggleModalDelete}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>

        <div className="col-md-6 offset-md-3">
          <Card>
            <CardHeader>
              <i className="fa fa-align-justify"></i>{" "}
              <span className="mr-2">{this.props.formTitle}</span>
              {this.props.edit ? (
                <Link
                  to="/timer/create"
                  title="Create New Timer"
                  className="text-right"
                >
                  Create New
                </Link>
              ) : null}
            </CardHeader>
            <CardBody>
              {this.renderInfo()}
              {this.props.edit && this.props.timer.isFetch ? (
                this.renderLoading()
              ) : (
                <Form onSubmit={this.onSubmit.bind(this)}>
                  <FormGroup
                    className={
                      this.hasError("name") ? " has-danger has-feedback" : ""
                    }
                  >
                    <Label for="name">Title</Label>
                    <Input
                      type="text"
                      id="name"
                      name="name"
                      autoComplete="off"
                      placeholder="Enter a Title*"
                      value={this.state._form.name}
                      onChange={this.handleChange.bind(this)}
                    />
                    {this.validateError("name")}
                  </FormGroup>
                  <FormGroup
                    className={
                      this.hasError("description")
                        ? " has-danger has-feedback"
                        : ""
                    }
                  >
                    <Label for="content">Description</Label>
                    <Input
                      type="textarea"
                      id="content"
                      name="content"
                      autoComplete="off"
                      placeholder="Enter a Description*"
                      value={this.state._form.content}
                      onChange={this.handleChange.bind(this)}
                    />
                    {this.validateError("content")}
                  </FormGroup>
                  <FormGroup
                    className={classNames({
                      "has-danger has-feedback": hasError(
                        "started_at",
                        this.state._error
                      ),
                    })}
                  >
                    <Label for="formStartedAt">Started At</Label>
                    <InputDatetime
                      inputProps={{
                        placeholder: "Pick a Start At",
                        id: "formStartedAt",
                        autoComplete: "off",
                      }}
                      value={dateValue("started_at", this.state._form)}
                      onChange={(date) =>
                        this.handleDatePickerChange(date, "started_at")
                      }
                      timeFormat="HH:mm"
                    />
                    {this.validateError("started_at")}
                  </FormGroup>
                  <FormGroup
                    className={classNames({
                      "has-danger has-feedback": hasError(
                        "ended_at",
                        this.state._error
                      ),
                    })}
                  >
                    <Label for="formEndedAt">Ended At</Label>
                    <InputDatetime
                      inputProps={{
                        placeholder: "Pick a Ended At",
                        id: "formEndedAt",
                        autoComplete: "off",
                      }}
                      value={dateValue("ended_at", this.state._form)}
                      onChange={(date) =>
                        this.handleDatePickerChange(date, "ended_at")
                      }
                      timeFormat="HH:mm"
                    />
                    {this.validateError("ended_at")}
                  </FormGroup>
                  <FormGroup
                    className={
                      this.hasError("colour") ? " has-danger has-feedback" : ""
                    }
                  >
                    <Label for="colour">Colour</Label>
                    <Input
                      type="text"
                      id="colour"
                      name="colour"
                      autoComplete="off"
                      placeholder="Enter a Colour"
                      value={this.state._form.colour}
                      onChange={this.handleChange.bind(this)}
                    />
                    {this.validateError("colour")}
                  </FormGroup>
                  <FormGroup
                    className={
                      this.hasError("terms_url") ? " has-danger has-feedback" : ""
                    }
                  >
                    <Label for="terms_url">Terms URL</Label>
                    <Input
                      type="text"
                      id="terms_url"
                      name="terms_url"
                      autoComplete="off"
                      placeholder="Enter the Terms URL"
                      value={this.state._form.terms_url}
                      onChange={this.handleChange.bind(this)}
                    />
                    {this.validateError("terms_url")}
                  </FormGroup>
                  <FormGroup
                    className={
                      this.hasError("image_url") ? " has-danger has-feedback" : ""
                    }
                  >
                    <Label for="image_url">Image URL</Label>
                    <Input
                      type="text"
                      id="image_url"
                      name="image_url"
                      autoComplete="off"
                      placeholder="Enter the Image URL"
                      value={this.state._form.image_url}
                      onChange={this.handleChange.bind(this)}
                    />
                    {this.validateError("image_url")}
                  </FormGroup>
                  {this.props.edit && (
                    <FormGroup
                      className={classNames({
                        "has-danger has-feedback": hasError(
                          "active",
                          this.state._error
                        ),
                      })}
                    >
                      <Label for="formTimer">
                        Set to Active &nbsp;
                      </Label>
                      <label className="switch switch-text switch-success-outline-alt">
                        <input
                          type="checkbox"
                          className="switch-input"
                          name="active"
                          id="formTimer"
                          value="true"
                          checked={textValue("active", this.state._form)}
                          onChange={this.handleCheckedChange}
                        />
                        <span
                          className="switch-label"
                          data-on="On"
                          data-off="Off"
                        ></span>
                        <span className="switch-handle"></span>
                      </label>
                      {this.validateError("active")}
                    </FormGroup>
                  )}
                  <FormGroup>
                    <ButtonGroup>
                      {this.props.edit && this.state._form._id && (
                        <Button
                          className="mr-2"
                          color="danger"
                          onClick={this.toggleModalDelete}
                          disabled={this.state.formSubmit}
                        >
                          Delete
                        </Button>
                      )}
                      <Button color="primary">Submit</Button>
                    </ButtonGroup>
                  </FormGroup>
                </Form>
              )}
            </CardBody>
          </Card>
        </div>
      </Row>
    );
  }
}

const mapStateToProps = ({
  user,
  auth: { isFetch, isLogged, roles, id, email },
  timer,
}) => {
  return {
    user,
    email,
    guardData: { isFetch, isLogged, roles, id, email },
    timer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    update: (id, payload) => dispatch(updateTimer(id, payload)),
    new: (payload) => dispatch(createTimer(payload)),
    delete: (id) => dispatch(deleteTimer(id))
  };
};

const enhance = connect(mapStateToProps, mapDispatchToProps);
export default enhance(privateView(FormCard));
