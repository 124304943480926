import React from 'react';
import propTypes from 'prop-types';
import { Table, Card, CardHeader, CardBody } from 'reactstrap';
import Loading from 'react-loading-animation';

import { TextRupiah } from '../../../../components/Text';
import { _getFloatValue } from '../../../../utils/form';

const PurchaseSummaryCard = props =>(
  <Card>
    <CardHeader>
      Purchase Summaries
    </CardHeader>
    <CardBody>
      {
        props.isLoading?
        <Loading/>:
        <Table>
          <thead>
            <tr>
              <th colSpan={2} className="text-center">
                Purchase Summary
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Top-up</td>
              <td><TextRupiah prefix="Rp. " value={_getFloatValue(props.amount)} align="right" block/></td>
            </tr>
            <tr>
              <td>Biaya Tambahan</td>
              <td><TextRupiah  prefix="Rp. " value={(_getFloatValue(props.uniqueAmount)+_getFloatValue(props.administrationFee))} align="right" block/></td>
            </tr>
            <tr>
              <td><b>Total</b></td>
              <td>
                <TextRupiah prefix="Rp. " value={_getFloatValue(props.totalAmount)} align="right" block bold/>
              </td>
            </tr>
          </tbody>
        </Table>
      }
    </CardBody>
  </Card>
)

export default PurchaseSummaryCard;
PurchaseSummaryCard.propTypes = {
  id: propTypes.number.isRequired,
  isLoading: propTypes.bool,
  amount: propTypes.oneOfType([propTypes.number, propTypes.string]),
  administrationFee: propTypes.oneOfType([propTypes.number, propTypes.string]),
  uniqueAmount: propTypes.oneOfType([propTypes.number, propTypes.string]),
  totalAmount: propTypes.oneOfType([propTypes.number, propTypes.string])
}
PurchaseSummaryCard.defaultProps = {
  isLoading: false,
  displayName: '-',
  amount: 0,
  administrationFee: 0,
  uniqueAmount: 0,
  totalAmount: 0
}