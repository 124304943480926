import React from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import {
  Card,
  CardHeader,
  CardBody,
  CardText,
  Button
} from 'reactstrap';
import { getCollectionItems, saveCollectionItems } from '../../../actions/collectionAction';
import { getOptionList as getCategoryOptionList } from '../../../actions/categoriesAction';
import { FontAwesomeIcon, SimpleLineIcon } from '../../../components/Icons';
import { ModalBrand } from './Modals';
import ItemList, { BrandItem } from './ItemList';
import { ButtonXs } from '../../../components/Button';

const defaultParams = {
  per_page: 15,
  page: 1,
  category_id: null
}

class BrandList extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      _params:{
        ...defaultParams
      },
      paginations: {
        from: 0,
        to: 0,
        total: 0,
        currentPage: 0,
        lastPage: 0,
        perPage: 0
      },
      list: [],
      listSelected: [],
      error: null,
      message: '',
      statusCode: null,
      isBrowseProductsOpen: false
    }
  }

  componentDidMount(){
    const { _params } = this.state;
    this._getDataList(_params)
  }

  _sanitizeParams = rawParams => {
    return {
      params: Object.keys(rawParams)
      .filter(key => rawParams[key]!='')
      .reduce((obj, current)=> ({...obj, [current]: rawParams[current]}), [])
    }
  }

  _getDataList = (rawParams = {}) => {
    const { id } = this.props;
    this.props.getCollectionItems(
      id,
      this._sanitizeParams(rawParams)
    )
  }

  _resetDataList = () => {
    this.setState({
      _params: {
        ...defaultParams
      }
    }, () => this._getDataList(this.state._params))
  }

  _handleGoto = page => {
    const _params = this.state._params;
    _params.page = page;
    this.setState({ _params },
      ()=> this._getDataList(this.state._params)
    );
  }

  _handleBrowseProductToggle = () => {
    this.setState({
      isBrowseProductsOpen: !this.state.isBrowseProductsOpen
    })
  }

  componentDidUpdate(prevProps){
    if(!this.props.dataCollectionItems.isFetch && prevProps.dataCollectionItems.isFetch !== this.props.dataCollectionItems.isFetch){
      const { data, ...paginations } = this.props.dataCollectionItems.data;
      const { _params } = this.state;
      if(!_params.category_id){
        let category = 1;
        if(data.length){
          category = data[0].category_id? data[0].category_id: (
            data[0].ancestors.length? data[0].ancestors[0].category_id: 1);
          if(!category) category = 1;
        }
        this.setState({
          _params: {
            ..._params,
            category_id: category
          }
        }, () => {
          if(data.length) this._handleGoto(1)
        });
      }
      this.setState({
        list: [...data],
        paginations: {
          ...this.state.paginations,
          from: paginations.from,
          to: paginations.to,
          total: paginations.total,
          currentPage: paginations.current_page,
          lastPage: paginations.last_page,
          perPage: paginations.per_page
        }
      })
    }

    if(!this.props.dataCollectionItems.isSubmit && prevProps.dataCollectionItems.isSubmit !== this.props.dataCollectionItems.isSubmit){
      const { error, message, status_code } = this.props.dataCollectionItems;
      if(error){
        this.setState({
          error: {...error},
        })
      }
      else{
        this.setState({
          error: null,
          message,
          statusCode: status_code,
          listSelected: []
        })
        if(status_code === 201)
          this._resetDataList();
      }
    }
  }

  _handleDetachAllItems = e => {
    e.preventDefault();
    const { listSelected } = this.state;
    const { id } = this.props;
    const payloads = {
      brands : listSelected.length !== 0?
        listSelected.map(i => i): ['*']
    }
    this.props.saveCollectionItems('detach', id, payloads);
  }

  _handleAttachItems = itemIds => {
    const { id } = this.props;
    const payloads = {
      brands : itemIds.map(i => i)
    }
    this.props.saveCollectionItems('attach', id, payloads);
  }

  _handleOpenBrowseModal = e => {
    e.preventDefault();
    this._handleBrowseProductToggle()
  }

  render(){
    return(
      <div>
        <ModalBrand
          size="md"
          collectionId={this.props.id}
          isOpen={this.state.isBrowseProductsOpen}
          toggle={this._handleBrowseProductToggle}
          dataCategories={this.props.dataCategories}
          onSubmit={this._handleAttachItems}
        />
        <Card>
          <CardHeader>
            <FontAwesomeIcon iconType="align-justify"/> Brands
          </CardHeader>
          <CardBody>
            <CardText>Add / remove brands. Dont forget to upload the icon and the background image of your brand in Brand Page.</CardText>
            <div className="mb-1" style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
              <div>
                <a
                  href="#"
                  className="link text-primary mr-1"
                  onClick={this._handleOpenBrowseModal}
                >
                  <SimpleLineIcon iconType="plus" className="mr-1"/>
                  Browse
                </a>
                <a
                  href="#"
                  className="link text-danger mr-2"
                  onClick={this._handleDetachAllItems}
                >
                  <SimpleLineIcon iconType="trash" className="mr-1"/>
                  <span>
                    Remove {this.state.listSelected.length?`(${this.state.listSelected.length})`: 'All'}
                  </span>
                </a>
              </div>
              <div>
                {
                  this.state.listSelected.length > 0?(
                    <Button
                      outline
                      color="danger"
                      size="sm"
                      type="button"
                      className="mr-1"
                      style={{fontSize: '.5rem'}}
                      onClick={() => this.setState({listSelected: []})}
                    >
                      Clear Selected
                    </Button>
                  ): null
                }
                <Button
                  outline
                  title="Refresh list"
                  color="primary"
                  style={{fontSize: '.5rem'}}
                  size="sm"
                  type="button"
                  onClick={() => this._handleGoto(1)}
                >
                  <FontAwesomeIcon iconType="refresh"/>
                </Button>
              </div>
            </div>
            <div className="mb-1">
              {this.props.dataCategories.map((item, key) => (
                <ButtonXs
                  color="gray"
                  key={key}
                  className={classNames({"active": this.state._params.category_id == item.value})}
                  onClick={() => {
                    this.setState({
                      _params: {
                        ...this.state._params,
                        category_id: item.value,
                        page: 1
                      }
                    }, () => {
                      this._getDataList(this.state._params)
                    })
                  }}
                >
                  {item.label}
                </ButtonXs>
              ))}
            </div>
            <ItemList
              isLoading={this.props.dataCollectionItems.isFetch||this.props.dataCollectionItems.isSubmit}
              paginations={this.state.paginations}
              list={this.state.list}
              renderItems={item => (
                <BrandItem
                  key={item.id}
                  data={item}
                  checked={this.state.listSelected.includes(item.id)}
                  onSelectedChange={({target: { checked: value }}) => {
                    const { listSelected } = this.state;
                    this.setState({
                      listSelected: value? [...listSelected, item.id]: listSelected.filter(id => id !== item.id)
                    })
                  }}
                />
              )}
              onGotoPage={this._handleGoto}
              onSubmit={this._handleAttachItems}
            />
          </CardBody>
        </Card>
      </div>
    )
  }
}


const mapStateToProps= ({ masterCollection: { collectionItems }, masterCategories }) => ({
  dataCategories: masterCategories.options,
  dataCollectionItems: collectionItems
});
const mapDispatchToProps = (dispatch) => ({
  getCollectionItems: (id, query) => dispatch(getCollectionItems(id, 'brands', query)),
  saveCollectionItems: (operation, id, payload) => dispatch(saveCollectionItems(operation, id, 'brands', payload)),
  getCategoryOptionList: ()=> dispatch(getCategoryOptionList()),
});
export default connect(mapStateToProps,mapDispatchToProps)(BrandList);