import React from 'react';
import _ from 'lodash';
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Alert,
  Collapse
} from 'reactstrap';
import classNames from 'classnames';
import { connect } from 'react-redux';
import Select from 'react-select';
import 'react-select/dist/react-select.css';
import api from '../../utils/Api';
import { AUTH_TOKEN_KEY } from '../../constants';
import { doCreateDisbursement, doGetBankAccountList, doGetCashoutFee, doGetCashoutSetting } from '../../actions/disbursementUserAction';
import privateView from '../../components/hocs/privateView';
import { errorMessage, hasError, textValue, textChange, checkChange } from '../../utils/form';
import FontAwesomeIcon from '../../components/Icons/FontAwesomeIcon';
import ButtonLoading from '../../components/Button/ButtonLoading';
import ModalAccountBankOffice from './Modals/ModalAccountBankOffice';
import UserValue from './disbursementCreate/UserValue';
import UserOption from './disbursementCreate/UserOption';
import DisbursementSummaries from './DisbursementSummaries';
import Analytics from "../../services/Analytics";
import SelectBeneficiaryBank from '../../components/Form/Select/Async/SelectBeneficiaryBank';
import { SELLER_CREDIT } from './helpers';
import { TextRupiah } from '../../components/Text';
import { determineDisbursementStatus, isDisableImmediateApproveCheckbox, isDisableImmediatePendingCheckbox } from './Disbursement.utils';
class DisbursementCreate extends React.Component{
  constructor(props){
    super(props);
    this.state={
      _user: null,
      _accountNumberSender: '',
      _accountNameSender: '',
      _bankNameSender: '',
      _branchSender: '',
      _accountBankIdRecipient: '',
      _accountNumberRecipient: '',
      _accountNameRecipient: '',
      _bankNameRecipient: '',
      _branchRecipient: '',
      _totalAmount: 0,
      _fee: 0,
      _note: '',
      _disbursementType: 'BALANCE_OUT',
      _balanceSource: 'BALANCE',
      _balanceSourceOpt: [{
        value: "BALANCE",
        label: "Kick Credit"
      }, {
        value: "BALANCE_WITH_FEE",
        label: "Seller Credit"
      }, {
        value: "LOCKED_BALANCE",
        label: "Kick Point"
      }],
      _payoutType: 'MANUAL',
      _bank_option:"CUSTOM",
      _modalAccountBankOfficeOpen: false,
      _isAccountBankUser: false,
      _isSubmit: false,
      _errorMessage: '',
      _isAlertErrorOpen: false,
      _isAccountBankCollapse: true,
      _isValidateBankAccount: true
    }
    this._handleAccountBankOfficeChanged = this._handleAccountBankOfficeChanged.bind(this);
    this._getUserOptions = this._getUserOptions.bind(this);
    this.setValue = this.setValue.bind(this);
    this._handleOpenModalAccountBankForOffice = this._handleOpenModalAccountBankForOffice.bind(this);
    this._handleOpenModalAccountBankForUser = this._handleOpenModalAccountBankForUser.bind(this);
    this._handleTextChange = this._handleTextChange.bind(this);
    this._handleNumericTextChange = this._handleNumericTextChange.bind(this);
    this._handleSubmitForm = this._handleSubmitForm.bind(this);
    this._handleCheckedChange = this._handleCheckedChange.bind(this);
    this._handleDisbursementTypeChange = this._handleDisbursementTypeChange.bind(this);
    this._handleSelectBank = this._handleSelectBank.bind(this);
    this._handleOnSelectBankOpen = this._handleOnSelectBankOpen.bind(this);
    this._throttleGetDisbursementFee = _.debounce(this._getDisbursementFee.bind(this), 500)
  }

	componentDidMount(){
    const { email } = this.props.injectedProps;
    Analytics.recordPageView('create_disbursement', email, {
      url: window.location.href,
      location: this.props.location,
      match: this.props.match
    });
    this.props.getList();
    this.props.getFeeSetting();
  }

  setValue (value) {
		this.setState({ _user: value });
  }

  _handleNumericTextChange(e){
    let { value } = e.target;
    const lastState = {...this.state};
    const numb = value.trim().replace(/([a-zA-Z])+/, '');
    lastState[e.target.name] = numb===''? '0': parseInt(numb);
    if(e.target.name && e.target.name === '_totalAmount'
      && this.state._balanceSource === 'BALANCE_WITH_FEE' && this.state._disbursementType === 'BALANCE_OUT')
      this._throttleGetDisbursementFee(numb)
    this.setState({...lastState})
  }

  _getDisbursementFee = (totalAmount) => {
    if(totalAmount > 0){
      this.props.getFee({params: { total_amount: totalAmount }});
    }
  }

  _getUserOptions(input){
    const myToken = localStorage.getItem(AUTH_TOKEN_KEY);
    const headers = {
      'Authorization': `Bearer ${myToken}`
    };
    const params = {
      keyword: input,
      sort_by: 'balanceUpdatedAt_desc'
    }
    return api(headers).get('/admins/users', { params })
      .then(({ data }) => {
      return { options: data.data.data.map(item=>({
        id: item.id,
        email: item.email,
        balance: item.balance,
        seller_balance: item.balance_with_fee,
        kick_point: item.locked_balance
      })) };
    });
  }

  _handleCheckedChange(e){
    this.setState({
      ...checkChange(e, this.state)
    })
  }

  _handleDisbursementTypeChange(e){
    const value = e.target.value;
    const newState = {};
    if(value === 'BALANCE_IN'){
      newState._fee = 0;
    }
    newState._disbursementType = value;
    newState._isAccountBankCollapse = value === 'BALANCE_OUT';
    newState._status = false;
    newState._statusPending = false;
    this.setState({
      ...newState
    })
  }

  _handleSelectBank(option){
    let { _bankNameRecipient } =this.state;
    _bankNameRecipient = option? option.value: option;
    this.setState({_bankNameRecipient, _isValidateBankAccount:true});
  }

  _handleOnSelectBankOpen(){
    if(this.state._bankNameRecipient){
      if(this.state._recentSearchBank){
        this.refs.selectBeneficiaryBank.loadSelectOptions(this.state._recentSearchBank)
      }
      else{
        this.refs.selectBeneficiaryBank.loadSelectOptions(this.state._bankNameRecipient)
      }
    }
    else{
      this.refs.selectBeneficiaryBank.loadSelectOptions('')
    }
  }

  componentDidUpdate(lastProps, lastStates){
    const { cashoutFee } = this.props;
    if(lastStates._balanceSource!==this.state._balanceSource){
      if(this.state._balanceSource==='BALANCE_WITH_FEE' && this.state._disbursementType === 'BALANCE_OUT'){
        const totalAmount = Number(this.state._totalAmount)
        this._throttleGetDisbursementFee(totalAmount);
      }
      else{
        this.setState({_fee:0})
      }
    }
    if(lastProps.cashoutFee.isFetch!==cashoutFee.isFetch&&!cashoutFee.isFetch){
      if(cashoutFee.data){
        this.setState({
          _fee:cashoutFee.data.fee
        })
      }
    }
    if(lastProps.detail.isSubmit&&lastProps.detail.isSubmit!==this.props.detail.isSubmit){
      const { error } = this.props.detail;
      if(error){
        const { errors, status_code, message } = error;
        if(status_code===422){
          const getErrors = {};
          Object.keys(errors).map(index=>{
            if(index==='total_amount'){
              const lastMsgs = getErrors['_totalAmount']||[];
              getErrors['_totalAmount'] = [ ...lastMsgs, errors[index]];
            }
            else if(index==='disburse_to'){
              const lastMsgs = getErrors['_userId']||[];
              getErrors['_userId'] = [...lastMsgs, errors[index][0].replace('disburse to', 'user')];
            }
            else if(index==='disbursement_type'){
              const lastMsgs = getErrors['_disbursementType']||[];
              getErrors['_disbursementType'] = [...lastMsgs, errors[index][0]];
            }
            else if(index==='payout_type'){
              const lastMsgs = getErrors['_payoutType']||[];
              getErrors['_payoutType'] = [...lastMsgs, errors[index][0]];
            }
            else if(index==='fee'){
              const lastMsgs = getErrors['_fee']||[];
              getErrors['_fee'] = [...lastMsgs, errors[index]];
            }
            else if(index==='status'){
              const lastMsgs = getErrors['_status']||[];
              getErrors['_status'] = [...lastMsgs, errors[index]];
            }
            else if(index==='disbursement_bank.account_number_sender'){
              const lastMsgs = getErrors['_accountNumberSender']||[];
              getErrors['_accountNumberSender'] = [...lastMsgs, errors[index][0].replace('disbursement bank.account number', 'account number')];
            }
            else if(index==='disbursement_bank.account_name_sender'){
              const lastMsgs = getErrors['_accountNameSender']||[];
              getErrors['_accountNameSender'] = [...lastMsgs, errors[index][0].replace('disbursement bank.account name', 'account name')];
            }
            else if(index==='disbursement_bank.bank_name_sender'){
              const lastMsgs = getErrors['_bankNameSender']||[];
              getErrors['_bankNameSender'] = [...lastMsgs, errors[index][0].replace('disbursement bank.bank name', 'bank name')];
            }
            else if(index==='disbursement_bank.branch_sender'){
              const lastMsgs = getErrors['_branchSender']||[];
              getErrors['_branchSender'] = [...lastMsgs, errors[index][0].replace('disbursement bank.branch', 'branch')];
            }
            else if(index==='disbursement_bank.account_number_recipient'){
              const lastMsgs = getErrors['_accountNumberRecipient']||[];
              getErrors['_accountNumberRecipient'] = [...lastMsgs, errors[index][0].replace('disbursement bank.account number', 'account number')];
            }
            else if(index==='disbursement_bank.account_name_recipient'){
              const lastMsgs = getErrors['_accountNameRecipient']||[];
              getErrors['_accountNameRecipient'] = [...lastMsgs, errors[index][0].replace('disbursement bank.account name', 'account name')];
            }
            else if(index==='disbursement_bank.bank_name_recipient'){
              const lastMsgs = getErrors['_bankNameRecipient']||[];
              getErrors['_bankNameRecipient'] = [...lastMsgs, errors[index][0].replace('disbursement bank.bank name', 'bank name')];
            }
            else if(index==='disbursement_bank.branch_recipient'){
              const lastMsgs = getErrors['_branchRecipient']||[];
              getErrors['_branchRecipient'] = [...lastMsgs, errors[index][0].replace('disbursement bank.branch', 'branch')];
            }
          })
          this.setState({
            _errorForm:{
              errors: getErrors,
              statusCode: status_code,
              message
            },
            _isSubmit: false,
            _errorMessage: 'Please fulfill your form!',
            _isAlertErrorOpen: true
          })
        }
        else{
          this.setState({
            _errorForm:{
              statusCode: status_code,
              message
            },
            _isSubmit: false,
            _errorMessage: message,
            _isAlertErrorOpen: true
          })
        }
      }
      else if(this.props.detail.status_code === 200){
        this.setState({
          _isSubmit: false
        })
        const  { status, id } = this.props.detail.data;
        if(status==='COMPLETED')
          this.props.history.replace(`/disbursements/completed`)
        else
          this.props.history.replace(`/disbursements/${id}`)
      }
    }
  }


  _handleAccountBankOfficeChanged(accountBank){
    if(accountBank.user_id){
      this.setState({
        _accountBankIdRecipient: accountBank.id,
        _accountBankUserIdRecipient: accountBank.user_id,
        _accountNumberRecipient: accountBank.account_number,
        _accountNameRecipient: accountBank.account_name,
        _bankNameRecipient: accountBank.bank_name,
        _branchRecipient: accountBank.branch,
        _modalAccountBankOfficeOpen: false,
        _isValidateBankAccount:false
      }, ()=> {
        if(accountBank.account_number.toString().length >= 16){
          alert('Please re-check account number recipient');
        }
      });
    }
    else{
      this.setState({
        _accountBankIdSender: accountBank.id,
        _accountNumberSender: accountBank.account_number,
        _accountNameSender: accountBank.account_name,
        _bankNameSender: accountBank.bank_name,
        _branchSender: accountBank.branch,
        _modalAccountBankOfficeOpen: false,
        _isValidateBankAccount:false
      });
    }
  }

  _handleOpenModalAccountBankForOffice(){
    this.setState({
      _modalAccountBankOfficeOpen: !this.state._modalAccountBankOfficeOpen,
      _isAccountBankUser: false
    })
  }

  _validatePayloads(){
    let objErrors = {};
    const { _totalAmount, _disbursementType, _payoutType, _accountNumberRecipient, _accountNameRecipient, _bankNameRecipient } = this.state;
    if(this.state._user === null){
      objErrors['_userId'] = ["Please select a user to disburse."];
    }
    else{
      let selectedBalance = 0;
      if(this.state._user){
        const { _balanceSource } = this.state;
        const { balance, seller_balance, kick_point } = this.state._user;
        selectedBalance = _balanceSource === 'BALANCE_WITH_FEE'? seller_balance: (_balanceSource === 'LOCKED_BALANCE'? kick_point:balance)
      }
      if(parseInt(selectedBalance) - _totalAmount < 0 && _disbursementType === 'BALANCE_OUT'){
        if(this.state._balanceSource === SELLER_CREDIT){
          objErrors['_totalAmount'] = ["The total amount field must be less than seller balance."];
        } else{
          objErrors['_totalAmount'] = ["The total amount field must be less than user balance."];
        }
      }
    }
    if(this.state._totalAmount <= 0){
      objErrors['_totalAmount'] = ["The total amount field must be greater than 0."];
    }
    if(_payoutType==='MIDTRANS'){
      // if(!_bankNameRecipient)
      //   objErrors['_bankNameRecipient'] = ["The bank name recipient field must is required."];
      // if(!_accountNameRecipient)
      //   objErrors['_accountNameRecipient'] = ["The account name recipient field must is required."];
      // if(!_accountNumberRecipient)
      //   objErrors['_accountNumberRecipient'] = ["The account number recipient field must is required."];
      if(_bankNameRecipient){
        const availableBankName = this.props.bankAccount.filter(item => item.code.toLocaleLowerCase() === _bankNameRecipient.toLocaleLowerCase()
        || !!~item.name.replace(/\./g, '').toLocaleLowerCase().indexOf(_bankNameRecipient.replace(/\./g, '').toLocaleLowerCase())).length
        if(availableBankName <= 0){
          objErrors['_bankNameRecipient'] = ["The bank name recipient field is not included in the midtrans bank list."];
        }
      }
    }
    return objErrors;
  }

  _handleSubmitForm(e){
    const { email } = this.props;

    e.preventDefault();
    const getLocalErrors = this._validatePayloads();
    if(Object.keys(getLocalErrors).length===0){
      let payout_type = this.state._disbursementType === 'BALANCE_IN'? "MANUAL": this.state._payoutType
      const payloads = {
        disburse_to: this.state._user.id,
        balance_source: this.state._balanceSource,
        payout_type, //forced to manual if selected BALANCE_IN
        disbursement_type: this.state._disbursementType,
        total_amount: this.state._totalAmount,
        validate_bank_account : this.state._isValidateBankAccount,
        fee: this.state._fee,
        note: this.state._note
      }
      if(this.state._disbursementType==='BALANCE_OUT'){
        payloads.disbursement_bank = {
          account_number_sender: this.state._accountNumberSender,
          account_name_sender: this.state._accountNameSender,
          bank_name_sender: this.state._bankNameSender,
          branch_sender: this.state._branchSender,
          bank_account_id_recipient: this.state._accountBankIdRecipient
          // account_number_recipient: this.state._accountNumberRecipient,
          // account_name_recipient: this.state._accountNameRecipient,
          // bank_name_recipient: this.state._bankNameRecipient,
          // branch_recipient: this.state._branchRecipient
        }
      }
      payloads.status = determineDisbursementStatus({
        disbursement_type: this.state._disbursementType,
        status: this.state._status,
        statusPending: this.state._statusPending
      });
      this.setState({
        _isSubmit: true,
        _isAlertErrorOpen: false
      }, ()=>this.props.save(payloads))

      const dirsbursementBank = this.state._disbursementType==='BALANCE_OUT'? payloads.disbursement_bank : null;
      Analytics.recordCreateDisbursement(
        payloads,
        dirsbursementBank,
        email
      );
    }
    else{
      this.setState({
        _errorForm:{
          errors: getLocalErrors,
          statusCode: 422
        },
        _errorMessage: 'Please fulfill your form!',
        _isAlertErrorOpen: true
      })
    }
  }

  _handleOpenModalAccountBankForUser(){
    if(!this.state._user){
      alert('Please select user!');
    }
    else{
      this.setState({
        _modalAccountBankOfficeOpen: !this.state._modalAccountBankOfficeOpen,
        _isAccountBankUser: true
      })
    }
  }

  _handleTextChange(e){
    const {_accountNameRecipient, _bankNameRecipient, _isValidateBankAccount} = this.state;
    let isValidateBankAccount = _isValidateBankAccount;
    if(e.target.name == '_accountNumberRecipient' && e.target.value !== _accountNameRecipient) isValidateBankAccount = true;
    else if(e.target.name == '_bankNameRecipient' && e.target.value !== _bankNameRecipient) isValidateBankAccount = true;
    this.setState({
      ...textChange(e, this.state),
      _isValidateBankAccount : isValidateBankAccount
    })
  }

  render(){
    let selectedBalance = 0;
    if(this.state._user){
      const { _balanceSource } = this.state;
      const { balance, seller_balance, kick_point } = this.state._user;
      selectedBalance = _balanceSource === 'BALANCE_WITH_FEE'? seller_balance: (_balanceSource === 'LOCKED_BALANCE'? kick_point:balance)
    }
    return(
      <Row className="animated fadeIn">
        <ModalAccountBankOffice
          isOpen={this.state._modalAccountBankOfficeOpen}
          userId={this.state._user?this.state._user.id: null}
          isAccountBankUser={this.state._isAccountBankUser}
          onCancel={()=> this.setState({_modalAccountBankOfficeOpen: !this.state._modalAccountBankOfficeOpen})}
          onSelectChanged={this._handleAccountBankOfficeChanged}
        />
        <Col xs={12} md={6}>
          <Card>
            <CardHeader>
              <FontAwesomeIcon iconType="align-justify"/>Disbursement Form
            </CardHeader>
            <CardBody>
              <Alert
                color="danger"
                isOpen={this.state._isAlertErrorOpen}
                toggle={()=> this.setState({_isAlertErrorOpen: !this.state._isAlertErrorOpen})}
              >
                <strong>Oppps... </strong>{this.state._errorMessage}
              </Alert>
              <Form onSubmit={this._handleSubmitForm}>
                <Row>
                  <Col xs={12}>
                    <FormGroup className={classNames({'has-danger has-feedback':hasError('_userId', this.state._errorForm)})}>
                      <Label>User</Label>
                      <Select.Async
                        style={{height: 45}}
                        className="customSelect"
                        valueKey="id"
                        labelKey="email"
                        noResultsText="Cannot find user."
                        loadOptions={this._getUserOptions}
                        optionComponent={UserOption}
                        valueComponent={UserValue}
                        onChange={this.setValue}
                        value={this.state._user}
                        placeholder="Type and select a user..."
                      />
                      {errorMessage('_userId', this.state._errorForm)}
                    </FormGroup>
                  </Col>
                  <Col xs={12} md={6}>
                    <FormGroup className={classNames({'has-danger has-feedback':hasError('_disbursementType', this.state._errorForm)})}>
                      <Label>Disbursement Type</Label>
                      <Input
                        type="select"
                        name="_disbursementType"
                        onChange={this._handleDisbursementTypeChange}
                        value={this.state._disbursementType}
                        placeholder="Disbursment type..."
                      >
                        <option value="BALANCE_IN">Balance In</option>
                        <option value="BALANCE_OUT">Balance Out</option>
                      </Input>
                      {errorMessage('_disbursementType', this.state._errorForm)}
                    </FormGroup>
                  </Col>
                  <Col xs={12} md={6}>
                    <FormGroup className={classNames({'has-danger has-feedback':hasError('_disbursementType', this.state._errorForm)})}>
                      <Label>Balance Source</Label>
                      <Input
                        type="select"
                        name="_balanceSource"
                        onChange={(e) => { this.setState({ [e.target.name]: e.target.value }) }}
                        value={this.state._balanceSource}
                        placeholder="Balance source..."
                      >
                        {this.state._balanceSourceOpt.map((opt, i) => <option key={i} value={opt.value}>{opt.label}</option>)}
                      </Input>
                      {errorMessage('_balanceSource', this.state._errorForm)}
                    </FormGroup>
                  </Col>
                  {
                    this.state._disbursementType === "BALANCE_OUT" ?
                    <Col xs={12} md={6}>
                      <FormGroup className={classNames({'has-danger has-feedback':hasError('_payoutType', this.state._errorForm)})}>
                        <Label>Payout Type</Label>
                        <Input
                          type="select"
                          name="_payoutType"
                          onChange={this._handleTextChange}
                          value={this.state._payoutType}
                          placeholder="Payout type..."
                        >
                          <option value="MANUAL">Manual</option>
                          <option value="MIDTRANS">Midtrans</option>
                        </Input>
                        {errorMessage('_payoutType', this.state._errorForm)}
                      </FormGroup>
                    </Col>
                    :
                    null
                  }
                  <Col xs={12}>
                    <Collapse isOpen={this.state._isAccountBankCollapse}>
                      <Row>
                        <Col xs={12}>
                          <hr/>
                        </Col>
                        <Col xs={12}>
                          <FormGroup>
                            <Label>Transfer From (KickAvenue)</Label>
                            <br/>
                            <Button
                              type="button"
                              onClick={this._handleOpenModalAccountBankForOffice}
                            >
                              Pilih Account Bank
                            </Button>
                          </FormGroup>
                        </Col>
                        <Col xs={12} md={12}>
                          <FormGroup className={classNames({'has-danger has-feedback':hasError('_bankNameSender', this.state._errorForm)})}>
                            <Label>Bank Name</Label>
                            <Input
                              name="_bankNameSender"
                              onChange={this._handleTextChange}
                              value={textValue("_bankNameSender", this.state)}
                              placeholder="Bank name..."
                            />
                            {errorMessage('_bankNameSender', this.state._errorForm)}
                          </FormGroup>
                        </Col>
                        <Col xs={12} md={6}>
                          <FormGroup className={classNames({'has-danger has-feedback':hasError('_accountNumberSender', this.state._errorForm)})}>
                            <Label>Account Number</Label>
                            <Input
                              name="_accountNumberSender"
                              onChange={this._handleTextChange}
                              value={this.state._accountNumberSender}
                              placeholder="Account number..."
                            />
                            {errorMessage('_accountNumberSender', this.state._errorForm)}
                          </FormGroup>
                        </Col>
                        <Col xs={12} md={6}>
                          <FormGroup className={classNames({'has-danger has-feedback':hasError('_branchSender', this.state._errorForm)})}>
                            <Label>Branch</Label>
                            <Input
                              name="_branchSender"
                              onChange={this._handleTextChange}
                              value={textValue("_branchSender", this.state)}
                              placeholder="Branch..."
                            />
                            {errorMessage('_branchSender', this.state._errorForm)}
                          </FormGroup>
                        </Col>
                        <Col xs={12} md={12}>
                          <FormGroup className={classNames({'has-danger has-feedback':hasError('_accountNameSender', this.state._errorForm)})}>
                            <Label>Account Name</Label>
                            <Input
                              name="_accountNameSender"
                              onChange={this._handleTextChange}
                              value={textValue("_accountNameSender", this.state)}
                              placeholder="Account name..."
                            />
                            {errorMessage('_accountNameSender', this.state._errorForm)}
                          </FormGroup>
                        </Col>
                        <Col xs={12}>
                          <hr/>
                        </Col>
                        <Col xs={12}>
                          <FormGroup>
                            <Label>Transfer To</Label>
                            <br/>
                            <Button
                              type="button"
                              onClick={this._handleOpenModalAccountBankForUser}
                            >
                              Pilih Account Bank
                            </Button>
                          </FormGroup>
                        </Col>
                        <Col xs={12} md={12}>
                          <FormGroup className={classNames({'has-danger has-feedback':hasError('_bankNameRecipient', this.state._errorForm)})}>
                            <Label for="formRack" className="d-block">
                              <div className="d-flex" style={{justifyContent: 'space-between'}}>
                                <div>Bank Name</div>
                                {
                                  this.state._bank_option !== "CUSTOM" ?
                                  <div style={{marginLeft: "0.5rem"}}>
                                    <span style={{marginLeft: "0.5rem", textDecoration: 'underline', cursor: "pointer", color: 'red', fontSize: '.75rem'}} onClick={() => this.setState({_bank_option: "CUSTOM"})}>Make a new bank</span>
                                  </div>
                                  :
                                  <div style={{marginLeft: "0.5rem"}}>
                                    <span style={{marginLeft: "0.5rem", textDecoration: 'underline', cursor: "pointer", color: 'red', fontSize: '.75rem'}} onClick={() => this.setState({_bank_option: "SELECT"})}>Select existing bank</span>
                                  </div>
                                }
                              </div>
                            </Label>
                            {
                              this.state._bank_option !== "CUSTOM"?
                              <SelectBeneficiaryBank
                                readOnly={true}
                                defaultOptions
                                id="_formSelectBank"
                                ref="selectBeneficiaryBank"
                                value={this.state._bankNameRecipient? this.state._bankNameRecipient.toUpperCase(): null}
                                placeholder="Type and select a bank..."
                                noResultsText="Cannot find rack."
                                loadOptions={this._getBankOptions}
                                onSelectSearch={(input)=>this.setState({_recentSearchBank: input})}
                                onSelectChange={this._handleSelectBank}
                                onSelectOpen={this._handleOnSelectBankOpen}
                              />
                              :
                              <Input
                                name="_bankNameRecipient"
                                onChange={this._handleTextChange}
                                readOnly={true}
                                value={textValue("_bankNameRecipient", this.state).toUpperCase()}
                                placeholder="Bank name..."
                              />
                            }
                            {errorMessage('_bankNameRecipient', this.state._errorForm)}
                          </FormGroup>
                        </Col>
                        <Col xs={12} md={6}>
                          <FormGroup className={classNames({'has-danger has-feedback':hasError('_accountNumberRecipient', this.state._errorForm)})}>
                            <Label>Account Number</Label>
                            <Input
                              name="_accountNumberRecipient"
                              readOnly={true}
                              onChange={this._handleTextChange}
                              value={textValue("_accountNumberRecipient", this.state)}
                              placeholder="Account number..."
                            />
                            {errorMessage('_accountNumberRecipient', this.state._errorForm)}
                          </FormGroup>
                        </Col>
                        <Col xs={12} md={6}>
                          <FormGroup className={classNames({'has-danger has-feedback':hasError('_branchRecipient', this.state._errorForm)})}>
                            <Label>Branch</Label>
                            <Input
                              name="_branchRecipient"
                              readOnly={true}
                              onChange={this._handleTextChange}
                              placeholder="Branch..."
                              value={textValue("_branchRecipient", this.state)}
                            />
                            {errorMessage('_branchRecipient', this.state._errorForm)}
                          </FormGroup>
                        </Col>
                        <Col xs={12} md={12}>
                          <FormGroup className={classNames({'has-danger has-feedback':hasError('_accountNameRecipient', this.state._errorForm)})}>
                            <Label>Account Name</Label>
                            <Input
                              name="_accountNameRecipient"
                              readOnly={true}
                              onChange={this._handleTextChange}
                              value={textValue("_accountNameRecipient", this.state)}
                              placeholder="Account name..."
                            />
                            {errorMessage('_accountNameRecipient', this.state._errorForm)}
                          </FormGroup>
                        </Col>
                        <Col xs={12}>
                          <hr/>
                        </Col>
                      </Row>
                    </Collapse>
                  </Col>
                  <Col xs={12} md={6}>
                    <FormGroup className={classNames({'has-danger has-feedback':hasError('_totalAmount', this.state._errorForm)})}>
                      <Label>Total Amount</Label>
                      <Input
                        name="_totalAmount"
                        onChange={this._handleNumericTextChange}
                        value={textValue("_totalAmount", this.state)}
                        placeholder="Total Amount"
                      />
                      {errorMessage('_totalAmount', this.state._errorForm)}
                    </FormGroup>
                  </Col>
                  <Col xs={12} md={6}>
                    <FormGroup className={classNames({'has-danger has-feedback':hasError('_fee', this.state._errorForm)})}>
                      <Label>Fee</Label>
                      <Input
                        name="_fee"
                        onChange={this._handleNumericTextChange}
                        value={textValue("_fee", this.state)}
                        placeholder="Fee"
                        disabled={this.state._disbursementType === 'BALANCE_IN'}
                      />
                      {this.props.cashoutFee.settings && this.props.cashoutFee.settings[this.state._balanceSource]?(
                        <div style={{fontSize: '.65rem'}}>
                          <span>fee: {this.props.cashoutFee.settings[this.state._balanceSource].cashout_fee}%</span>
                          {this.props.cashoutFee.settings[this.state._balanceSource].admin_fee? (
                            <>
                              <span className="ml-1 mr-1">+</span>
                              <TextRupiah tag="span" prefix="" value={this.props.cashoutFee.settings[this.state._balanceSource].admin_fee}/>
                              {' '}
                            </>
                          ):null}
                          {this.props.cashoutFee.settings[this.state._balanceSource].max_fee? (
                            <> 
                            (
                              <span>max fee: </span>
                              <TextRupiah tag="span" prefix="" value={this.props.cashoutFee.settings[this.state._balanceSource].max_fee}/>
                            )
                            </>
                          ):null}
                        </div>
                      ):null}
                      {errorMessage('_fee', this.state._errorForm)}
                    </FormGroup>
                  </Col>
                  <Col xs={12}>
                    <FormGroup className={classNames({'has-danger has-feedback':hasError('_note', this.state._errorForm)})}>
                      <Label>Note</Label>
                      <Input
                        name="_note"
                        onChange={this._handleTextChange}
                        value={textValue("_note", this.state)}
                        type="textarea"
                        placeholder="Notes..."
                      />
                      {errorMessage('_note', this.state._errorForm)}
                    </FormGroup>
                  </Col>
                  {
                    this.state._payoutType!=='MIDTRANS'?
                    <Col xs={12}>
                      <FormGroup 
                        className={classNames({'has-danger has-feedback':hasError('_status', this.state._errorForm)})}
                        style={{ marginBottom: '4px' }}>
                        <Label check>
                          <Input
                            value="true"
                            checked={this.state._status===true}
                            onChange={this._handleCheckedChange}
                            disabled={isDisableImmediateApproveCheckbox({ disbursement_type: this.state._disbursementType })}
                            name="_status"
                            type="checkbox"/>{' '} Langsung approve?
                        </Label>
                        {errorMessage('_status', this.state._errorForm)}
                      </FormGroup>
                    </Col>
                    :
                    null
                  }
                </Row>
                <Row>
                <Col xs={12}>
                  <FormGroup className={classNames({'has-danger has-feedback':hasError('_status', this.state._errorForm)})}>
                    <Label check>
                      <Input
                        value="true"
                        checked={this.state._statusPending===true}
                        onChange={this._handleCheckedChange}
                        disabled={isDisableImmediatePendingCheckbox({ disbursement_type: this.state._disbursementType })}
                        name="_statusPending"
                        type="checkbox"/>{' '} Langsung pending?
                    </Label>
                    {errorMessage('_statusPending', this.state._errorForm)}
                  </FormGroup>
                </Col>
                </Row>
                <ButtonLoading
                  isLoading={this.state._isSubmit}
                  disabled={this.state._isSubmit}
                  loadingMessage="Submitting..."
                  color="primary"
                >
                  Create
                </ButtonLoading>
              </Form>
            </CardBody>
          </Card>
        </Col>
        <Col xs={12} md={6}>
          <DisbursementSummaries
            disbursementType={this.state._disbursementType}
            type={this.state._balanceSource}
            balance={this.state._user?parseInt(selectedBalance):undefined}
            totalAmount={parseInt(this.state._totalAmount)}
            fee={parseInt(this.state._fee)}
          />
        </Col>
      </Row>
    )
  }
}

const mapStateToProps= ({ disbursementUser: { detail, midtrans, cashoutFee }, auth:{isFetch, isLogged, roles, id, email}}) => ({
  detail,
  guardData: {isFetch, isLogged, roles, id},
  email,
  cashoutFee,
  bankAccount:midtrans.bankAccount
});

const mapDispatchToProps = (dispatch) => ({
	save: payload => dispatch(doCreateDisbursement(payload)),
	getFee: params => dispatch(doGetCashoutFee(params)),
	getFeeSetting: () => dispatch(doGetCashoutSetting()),
	getList: () => dispatch(doGetBankAccountList())
});

// const enhance = connect(mapStateToProps);
const enhance = connect(mapStateToProps, mapDispatchToProps);
export default enhance(privateView(DisbursementCreate));