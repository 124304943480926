import {
    AUTH_TOKEN_KEY,
    SPIN_WHEEL_ROUTE_API,
    SPIN_WHEEL_FETCHING,
	SPIN_WHEEL_LIST_FETCH,
	SPIN_WHEEL_DETAIL_FETCH,
	SPIN_WHEEL_ADD,
	SPIN_WHEEL_UPDATE,
	SPIN_WHEEL_DELETE,
	SPIN_WHEEL_PRIZE_FETCHING,
	SPIN_WHEEL_PRIZE_ADD,
	SPIN_WHEEL_PRIZE_UPDATE,
	SPIN_WHEEL_PRIZE_DELETE,
	SPIN_WHEEL_PRIZE_DETAIL_FETCH,
    SPIN_WHEEL_ERROR,
    SPIN_WHEEL_PRIZE_ERROR
} from '../constants';
import api from '../utils/Api';
import { manipulateErrors, checkAuthorized } from '../utils';
import { logout } from './authAction';

/**
 * Indicates when start fetch api or end fetch api
 * @author haikal
 * @param {boolean} bool 
 */
export const isFetch=(payload)=>(
	{
		type:SPIN_WHEEL_FETCHING,
		payload:payload		
	}
)

/**
 * Indicates when start fetch api or end fetch api
 * @author haikal
 * @param {boolean} bool 
 */
export const isFetchPrize=(payload)=>(
	{
		type:SPIN_WHEEL_PRIZE_FETCHING,
		payload: payload
	}
)

/**
 * When onSuccess triggered
 * @author haikal
 * @param {object} res 
 * @param {string} type 
 */
export const onSuccess=(res,type)=>
(
	{
		type:type,
		payload:res		
	}
)
/**
 * When error fetch or connect to api
 * @author haikal
 * @param {object} err 
 */
// On Error fetching api
export const onError=(err)=>{
	if(!checkAuthorized(manipulateErrors(err)))
		return logout();
	return {
		type:SPIN_WHEEL_ERROR,
		payload:manipulateErrors(err)
	}
}

/**
 * When error fetch or connect to api
 * @author haikal
 * @param {object} err 
 */
// On Error fetching api
export const onErrorPrize=(err)=>{
	if(!checkAuthorized(manipulateErrors(err)))
		return logout();
	return {
		type:SPIN_WHEEL_PRIZE_ERROR,
		payload:manipulateErrors(err)
	}
}

/**
 * Get list of Spinning Wheel
 * @author pewe
 * @guards jwt-token 
 * @param {object?} query 
 */
export const getSpinWheelList=(query)=>{
	return dispatch =>(
		dispatch(isFetch({type:'fetch',status:true})),
		api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).get(SPIN_WHEEL_ROUTE_API,query)
		.then(res=>{
			dispatch(onSuccess(res.data,SPIN_WHEEL_LIST_FETCH))
			dispatch(isFetch({type:'fetch',status:false}))
		})
		.catch(err => {
			dispatch(onError(err));
			dispatch(isFetch({type:'fetch',status:false}));
		})
	)
}

export const getDetailSpinWheel=(id)=>{
	return dispatch=>(
		dispatch(isFetch({type:'fetch',status:true})),
		api({
			Authorization:'Bearer '+localStorage.getItem(AUTH_TOKEN_KEY)
		}).get(`${SPIN_WHEEL_ROUTE_API}/${id}`)
		.then(res => {
			dispatch(onSuccess(res.data,SPIN_WHEEL_DETAIL_FETCH));
			dispatch(isFetch({type:'fetch',status:false}));
		})
		.catch(err => {
			dispatch(onError(err));
			dispatch(isFetch({type:'fetch',status:false}));
		})		
	)
}

export const createSpinWheel=(payload)=>{
	return dispatch =>(
		dispatch(isFetch({type:'submit',status:true})),
				api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).post(`${SPIN_WHEEL_ROUTE_API}`,payload)
		.then(res=>{
			dispatch(onSuccess(res.data,SPIN_WHEEL_ADD))
			dispatch(isFetch({type:'submit',status:false}))
		})
		.catch(err => {
			dispatch(onError(err));
			dispatch(isFetch({type:'submit',status:false}));
		})
	)
}

export const updateSpinWheel=(id, payload)=>{
	return dispatch => (
		dispatch(isFetch({type:'submit',status:true})),
		api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).put(`${SPIN_WHEEL_ROUTE_API}/${id}`,payload)
		.then(res=>{
			dispatch(onSuccess(res.data,SPIN_WHEEL_UPDATE))
			dispatch(isFetch({type:'submit',status:false}))
		})
		.catch(err => {
			dispatch(onError(err));
			dispatch(isFetch({type:'submit',status:false}));
		})
	)
}

export const deleteSpinWheel=(id)=>{
	return dispatch =>(
		dispatch(isFetch({type:'submit',status:true})),
				api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).delete(`${SPIN_WHEEL_ROUTE_API}/${id}`)
		.then(res=>{
			dispatch(onSuccess(res.data,SPIN_WHEEL_DELETE))
			dispatch(isFetch({type:'submit',status:false}))
		})
		.catch(err => {
			dispatch(onError(err));
			dispatch(isFetch({type:'submit',status:false}));
		})
	)
}

export const createSpinWheelPrize=(payload)=>{
	return dispatch =>(
		dispatch(isFetchPrize({type:'submit',status:true})),
				api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).post(`${SPIN_WHEEL_ROUTE_API}/prize`,payload)
		.then(res=>{
			dispatch(onSuccess(res.data,SPIN_WHEEL_PRIZE_ADD))
			dispatch(isFetchPrize({type:'submit',status:false}))
		})
		.catch(err => {
			dispatch(onErrorPrize(err));
			dispatch(isFetchPrize({type:'submit',status:false}));
		})
	)
}

export const updateSpinWheelPrize=(spin_id, id, payload)=>{
	return dispatch =>(
		dispatch(isFetchPrize({type:'submit',status:true})),
				api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).put(`${SPIN_WHEEL_ROUTE_API}/${spin_id}/prize/${id}`,payload)
		.then(res=>{
			dispatch(onSuccess(res.data,SPIN_WHEEL_PRIZE_UPDATE))
			dispatch(isFetchPrize({type:'submit',status:false}))
		})
		.catch(err => {
			dispatch(onErrorPrize(err));
			dispatch(isFetchPrize({type:'submit',status:false}));
		})
	)
}

export const deleteSpinWheelPrize=(spin_id, id)=>{
	return dispatch =>(
		dispatch(isFetchPrize({type:'submit',status:true})),
				api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).delete(`${SPIN_WHEEL_ROUTE_API}/${spin_id}/prize/${id}`)
		.then(res=>{
			dispatch(onSuccess(res.data,SPIN_WHEEL_PRIZE_DELETE))
			dispatch(isFetchPrize({type:'submit',status:false}))
		})
		.catch(err => {
			dispatch(onErrorPrize(err));
			dispatch(isFetchPrize({type:'submit',status:false}));
		})
	)
}

export const getDetailSpinWheelPrize=(spin_id, id)=>{
	return dispatch=>(
		dispatch(isFetchPrize({type:'fetch',status:true})),
		api({
			Authorization:'Bearer '+localStorage.getItem(AUTH_TOKEN_KEY)
		}).get(`${SPIN_WHEEL_ROUTE_API}/${spin_id}/prize/${id}`)
		.then(res => {
			dispatch(onSuccess(res.data,SPIN_WHEEL_PRIZE_DETAIL_FETCH));
			dispatch(isFetchPrize({type:'fetch',status:false}));
		})
		.catch(err => {
			dispatch(onErrorPrize(err));
			dispatch(isFetchPrize({type:'fetch',status:false}));
		})		
	)
}