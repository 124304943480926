import {
  SLIDER_ROUTE_API,
  GET_LIST_SLIDER,
  FETCHING_SLIDER,
  GET_DETAIL_SLIDER,
  UPDATE_SLIDER,
  UPLOAD_SLIDER_S3,
  SLIDER_ERROR,
  SLIDER_S3_ERROR,
  ADD_SLIDER,
  NEW_SLIDER,
  DELETE_SLIDER,
  // GET_OPTION_LIST_SLIDER,
  // AUTH_TOKEN_KEY,
  // GET_LIST_SLIDER_ROOT_SLIDER
  SLIDER_IMGS_FETCH,
  SLIDER_IMGS_SUBMIT,
  SLIDER_IMGS_ERROR,
  ADD_SLIDER_IMGS,
  DELETE_SLIDER_IMGS,
  GET_LIST_SLIDER_IMGS
} from '../constants';

/**
 * Initial State
 */
const initialState = {
    data:{},
    detail:{},
    images:{
      data: [],
      isFetch: false,
      isSubmit: false
    },
    successS3:{
      images:{}
    },
    isS3Pending: false,
    errorS3: false,
    isUpload:false,
    isFetch: false,
    isSubmit:false,
    error: null,
    success:null,
}
/**
 * Function to handle when start or end fetching offer api
 * @author sopyan
 * @param {object} lastState 
 * @param {objec} bool 
 */
const onFetchingSlider = (lastState,payload) => {
  if(payload.type==='fetch')
      return { ...lastState, isFetch:payload.status}
  else if(payload.type==='submit')
      return { ...lastState, isSubmit:payload.status}

  return { ...lastState, isFetch:false,isSubmit:false }
}
/**
 * Function to handle when fetching slider error triggered
 * @author sopyan
 * @param {object} lastState 
 * @param {object} error 
 */
const onSliderError = (lastState,error) => {
	return { ...lastState, ...error, success:null };		
}

const onAddSlider = (lastState,payload) => {
	const {data, message, status_code}=payload;
    return { ...lastState, detail:{ ...data, message, status_code }, success:'Insert has been successfully!',error:null }
}

const onGetDetailSlider = (lastState, payload) => {
  const {data, message, status_code}=payload;
  return {...lastState, error:null, success:null, detail:{ ...data, message, status_code } };
}

const onUpdateSlider = (lastState, payload) => {
  const {data, message, status_code}=payload;
  if(lastState.data.data){
    const newState = {...lastState, detail:{ ...data, message, status_code, update:true }, success:'Update has been successfully!',error:null }
    const newDataState = {...newState.data}
    newDataState.data = [...newDataState.data].map((item, index)=>{
      if(item.id === payload.data.id){
        let oldItem = {...item, ...payload.data}
        return oldItem
      } else {
        return item
      }
    })
    newState.data = newDataState
    return newState
  } else {
    return {...lastState, detail:{ ...data, message, status_code, update:true }, success:'Update has been successfully!',error:null }
  }
}

const onDeleteSlider = (lastState,payload) => {
	const {data, message, status_code}=payload;
    return { ...lastState, detail:{ ...data, message, status_code, delete:true }, success:'Delete has been successfully!',error:null }
}

const onGetSlider = (lastState, payload) => {
  return {...lastState, ...payload, error: null}
}

const sliderReducer = (state=initialState, action) => {
    switch(action.type)
    {
      case UPLOAD_SLIDER_S3:{
        if(action.payload === true || action.payload === false){
          return { ...state, isS3Pending: action.payload }
        }
        else{
          const images={ ...state.successS3.images };
          images[action.payload.identifier]=action.payload;
          return { ...state, successS3:{images}, errorS3: null };
        }
      }
      case SLIDER_S3_ERROR: return { ...state, errorS3: action.payload };
      case ADD_SLIDER:return onAddSlider(state,action.payload);
      case SLIDER_ERROR:return onSliderError(state,action.payload);
      case FETCHING_SLIDER: return onFetchingSlider(state,action.payload);
      case GET_LIST_SLIDER: return onGetSlider(state,action.payload);
      case GET_DETAIL_SLIDER: return onGetDetailSlider(state,action.payload);
      case UPDATE_SLIDER: return onUpdateSlider(state, action.payload)
      case DELETE_SLIDER:return onDeleteSlider(state,action.payload);
      case NEW_SLIDER:return initialState;
      case DELETE_SLIDER_IMGS: return {
        ...state,
        images: {
          ...state.images,
          ...action.payload
        }
      }
      case SLIDER_IMGS_SUBMIT: return {
        ...state,
        images: {
          ...state.images,
          isSubmit: action.payload
        }
      }
      case ADD_SLIDER_IMGS: return {
        ...state,
        images: {
          ...state.images,
          ...action.payload,
          error: null
        }
      }
      case GET_LIST_SLIDER_IMGS: return {
        ...state,
        detail:{
          ...state.detail,
          images: [...action.payload.data.images]
        },
        images: {
          ...state.images,
          data: [...action.payload.data.images],
          error: null
        }
      }
      case SLIDER_IMGS_ERROR: return {
        ...state,
        images: {
          ...state.images,
          error: true
        }
      }
      default: return state
    }
}

export default sliderReducer