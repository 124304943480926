import React from 'react';
import propTypes from 'prop-types';
import Loading from 'react-loading-animation';
import { Card, CardHeader, CardBody } from 'reactstrap';
import {
  FieldRow,
  FieldColumn,
  FieldLabel,
  FieldValue
} from '../../styled/Fields';
import { _getGenderString } from '../../utils/form';

const SizeDetail = props => (
  <Card>
    <CardHeader>
      { props.renderHeaderText? props.renderHeaderText() : props.headerText }
    </CardHeader>
    <CardBody>
      {
        props.isLoading===true?
        <Loading/> :
        <div>
          <FieldRow>
            <FieldColumn className="col-xs-12 col-sm-6 col-md-3">
              <FieldLabel>Size Id</FieldLabel>
              <FieldValue>
                <span>{ props.id }</span>
              </FieldValue>
            </FieldColumn>
            <FieldColumn className="col-xs-12 col-sm-6 col-md-3">
              <FieldLabel>Size For</FieldLabel>
              <FieldValue>
                <span>{ _getGenderString(props.sex) }</span>
              </FieldValue>
            </FieldColumn>
          </FieldRow>
          <FieldRow>
            <FieldColumn className="col-xs-12 col-sm-6 col-md-3">
              <FieldLabel>US</FieldLabel>
              <FieldValue>
                <span>{ props.US }</span>
              </FieldValue>
            </FieldColumn>
            <FieldColumn className="col-xs-12 col-sm-6 col-md-3">
              <FieldLabel>UK</FieldLabel>
              <FieldValue>
                <span>{ props.UK }</span>
              </FieldValue>
            </FieldColumn>
            <FieldColumn className="col-xs-12 col-sm-6 col-md-3">
              <FieldLabel>EUR</FieldLabel>
              <FieldValue>
                <span>{ props.EUR }</span>
              </FieldValue>
            </FieldColumn>
            <FieldColumn className="col-xs-12 col-sm-6 col-md-3">
              <FieldLabel>CM</FieldLabel>
              <FieldValue>
                <span>{ props.cm }</span>
              </FieldValue>
            </FieldColumn>
          </FieldRow>
        </div>
      }
    </CardBody>
  </Card>
)

export default SizeDetail;

SizeDetail.propTypes={
  isLoading: propTypes.bool,
  renderHeaderText: propTypes.func,
  headerText: propTypes.oneOfType([propTypes.node, propTypes.string]),
  id:propTypes.number,
  US:propTypes.any,
  EUR:propTypes.any,
  UK:propTypes.any,
  cm:propTypes.any
}
SizeDetail.defaultProps={
  isLoading: false,
  headerText: 'Size Detail',
  id: null,
  US:'-',
  EUR:'-',
  UK:'-',
  cm:'-'
}