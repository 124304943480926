import React from "react";
import {
  Alert,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Row,
  Col,
} from "reactstrap";
import moment from "moment-timezone";
import PropTypes from "prop-types";
import classnames from "classnames";
import SelectUserAsync from "../../../../components/Form/Select/Async/SelectUser";
import Radio from "../../../../components/Form/Radios/Radio";
import InputDatetime from "../../../../components/Form/InputDatetime/InputDatetime";
import {
  hasError,
  errorMessage,
  textChange,
  textValue,
  dateValue,
  dateChange,
  checkChange,
} from "../../../../utils/form";

export default class ModalAddSeller extends React.Component {
  constructor(props) {
    super(props);
    moment.tz.setDefault("Asia/Jakarta");
    this.state = {
      collection_id: null,
      user: [],
      _recentUserSearch: null,
      isSubmit: false,
      isAletError: false,
      error: null,
    };

    this._handleOnSelectUserChange = this._handleOnSelectUserChange.bind(this);
    this._handleOnSelectUserOpen = this._handleOnSelectUserOpen.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onDismiss = this.onDismiss.bind(this);
  }

  componentDidUpdate(prevProps){
    const { isOpen, dataCollectionSeller } = this.props

    if (isOpen && isOpen !== prevProps.isOpen) {
      this.setState({
        collection_id: this.props.collectionId,
        user: [],
        _recentUserSearch: null,
        isSubmit: false,
        error: null,
        isAlertError: null,
      })
    }

    if(dataCollectionSeller.details.isSubmit!==prevProps.dataCollectionSeller.details.isSubmit && dataCollectionSeller.details.isSubmit===false){
      const { error, isDeleting } = dataCollectionSeller.details;
      if(error) {
        this.setState({
          error:{...dataCollectionSeller.details.error},
          isSubmit:false, isAlertError:true},
        );
      } else if (!(isDeleting !== prevProps.dataCollectionSeller.details.isDeleting)) {
        this.setState({
          isSubmit:false
        },()=>{this.props.onCancel()});
        this.props.onGetCollectionSellerList();
      }
    }
  }

  _handleOnSelectUserChange(selected) {
    let { user } = this.state;
    user = selected;
    this.setState({
      user,
      _recentUserSearch: selected && this.state._recentUserSearch,
    });
  }

  _handleOnSelectUserOpen() {
    if (this.state.user) {
      return (
        this.state._recentUserSearch &&
        this.refs.selectUser.loadSelectOptions(this.state._recentUserSearch)
      );
    }
    return this.refs.selectUser.loadSelectOptions("");
  }

  _validateForm(){
    let errors={};
    if(this.state.user.length==0)
      errors['user']=['The user field is required..'];
    if(Object.keys(errors).length){
      this.setState({
        error:{errors:{...errors}, status_code:422,message:'Please fulfill your form *'}
      })
    }
    return errors;
  }

  onSubmit(e) {
    e.preventDefault();
    const valid = this._validateForm();
    if (Object.keys(valid).length===0) {
      const { collection_id, user } = this.state;
      let getUser = user.map((user) => user.id);
      const payload = {
        collection_id,
        user_id: getUser,
      };
      this.setState({
        isSubmit:true,
        error:null,
      },()=>{
        this.props.onSubmit(payload);
      });
    }
  }

  onDismiss() {
    this.setState({
      isAlertError: false,
      error: null,
    });
  }

  _renderError() {
    const { error, isAlertError } = this.state;
    if (error !== null) {
      switch (error.status_code) {
        case 422:
          return (
            <Alert color="danger" isOpen={isAlertError} toggle={this.onDismiss}>
              Please fulfill your form
            </Alert>
          );
        default:
          return (
            <Alert color="danger" isOpen={isAlertError} toggle={this.onDismiss}>
              {error.message}
            </Alert>
          );
      }
    }
    return null;
  }

  render() {
    return (
      <Modal
        isOpen={this.props.isOpen}
        toggle={this.props.onCancel}
        className="modal-dialog modal-md"
      >
        <ModalHeader toggle={this.props.onCancel}>
          {this.props.update ? "Update " : "Add "}Seller
        </ModalHeader>
        <ModalBody>
          <Form>
            <Row>
              <Col xs={12} md={8}>
                <FormGroup
                  className={classnames({
                    "has-danger has-feedback": hasError(
                      "user",
                      this.state.error
                    ),
                  })}
                >
                  <Label for="formUser">User*</Label>
                  <SelectUserAsync
                    isMulti
                    defaultOptions
                    id="_formSelectUser"
                    userId={this.props.dataAuth.id}
                    ref="selectUser"
                    value={this.state.user}
                    paramsApi={{
                      roles: "seller",
                      role_query: "or",
                      scope: "all",
                    }}
                    placeholder="Type and select a user..."
                    noResultsText="Cannot find user."
                    loadOptions={this._getUserOptions}
                    onSelectSearch={(input) =>
                      this.setState({ _recentUserSearch: input })
                    }
                    onSelectChange={this._handleOnSelectUserChange}
                    onSelectOpen={this._handleOnSelectUserOpen}
                    // isDisabled={this.state.id ? true : false}
                  />
                  {errorMessage("user", this.state.error)}
                </FormGroup>
              </Col>
            </Row>
          </Form>
          <span className="text-sm">Fields marked with * are required</span>
          <br />
          <span>{this._renderError()}</span>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            disabled={this.state.isSubmit}
            onClick={this.onSubmit}
          >
            {this.state.isSubmit ? "Submitting..." : "Submit"}
          </Button>{" "}
          <Button color="secondary" onClick={this.props.onCancel}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}
