import {
  AUTH_TOKEN_KEY,
  SKU_REQUEST_ROUTE_API,
  GET_LIST_SKU_REQUEST,
  GET_DETAIL_SKU_REQUEST,
  DELETE_SKU_REQUEST,
  UPDATE_SKU_REQUEST,
  CREATE_SKU_REQUEST,
  SKU_REQUEST_ERROR,
  SKU_REQUEST_REQUEST
} from '../constants';
import api, { addHeaderAuthorization } from '../utils/Api';
import { manipulateErrors, checkAuthorized } from '../utils';
import { logout } from './authAction';

const onRequest = (requestType, value) => ({
  type: SKU_REQUEST_REQUEST,
  payload: {
    requestType,
    value
  }
})

const onSuccessRequest = (type, payload) => ({
  type, payload
})

const onFailedRequest = (requestType, error, id) => {
  const translateError = manipulateErrors(error);
  if(!checkAuthorized(translateError)) return logout();
  return {
    type: SKU_REQUEST_ERROR,
    payload: {
      id,
      requestType,
      ...translateError
    }
  }
}

export const getSkuRequestList = q => (
  dispatch => {
    dispatch(onRequest(GET_LIST_SKU_REQUEST, true));
    api({
      ...addHeaderAuthorization(localStorage.getItem(AUTH_TOKEN_KEY))
    }).get(SKU_REQUEST_ROUTE_API, q)
    .then(res => {
      dispatch(onSuccessRequest(GET_LIST_SKU_REQUEST, res.data));
      dispatch(onRequest(GET_LIST_SKU_REQUEST, false));
    })
    .catch(err => {
      dispatch(onFailedRequest(GET_LIST_SKU_REQUEST, err));
      dispatch(onRequest(GET_LIST_SKU_REQUEST, false));
    })
  }
)

export const getSkuRequestById = id => (
  dispatch => {
    dispatch(onRequest(GET_DETAIL_SKU_REQUEST, true));
    api({
      ...addHeaderAuthorization(localStorage.getItem(AUTH_TOKEN_KEY))
    }).get(`${SKU_REQUEST_ROUTE_API}/${id}`)
    .then(res => {
      dispatch(onSuccessRequest(GET_DETAIL_SKU_REQUEST, res.data));
      dispatch(onRequest(GET_DETAIL_SKU_REQUEST, false));
    })
    .catch(err => {
      dispatch(onFailedRequest(GET_DETAIL_SKU_REQUEST, err));
      dispatch(onRequest(GET_DETAIL_SKU_REQUEST, false));
    })
  }
)

export const deleteSkuRequestById = id => (
  dispatch => {
    dispatch(onRequest(DELETE_SKU_REQUEST, true));
    api({
      ...addHeaderAuthorization(localStorage.getItem(AUTH_TOKEN_KEY))
    }).delete(`${SKU_REQUEST_ROUTE_API}/${id}`)
    .then(res => {
      dispatch(onSuccessRequest(DELETE_SKU_REQUEST, res.data));
      dispatch(onRequest(DELETE_SKU_REQUEST, false));
    })
    .catch(err => {
      dispatch(onFailedRequest(DELETE_SKU_REQUEST, err));
      dispatch(onRequest(DELETE_SKU_REQUEST, false));
    })
  }
)

export const saveSkuRequest = (payload, id) => (
  id? updateSkuRequest(id, payload): createSkuRequest(payload)
)

export const updateSkuRequest = (id, payload) => (
  dispatch => {
    dispatch(onRequest(UPDATE_SKU_REQUEST, true));
    api({
      ...addHeaderAuthorization(localStorage.getItem(AUTH_TOKEN_KEY))
    }).put(`${SKU_REQUEST_ROUTE_API}/${id}`, payload)
    .then(res => {
      dispatch(onSuccessRequest(UPDATE_SKU_REQUEST, res.data));
      dispatch(onRequest(UPDATE_SKU_REQUEST, false));
    })
    .catch(err => {
      dispatch(onFailedRequest(UPDATE_SKU_REQUEST, err));
      dispatch(onRequest(UPDATE_SKU_REQUEST, false));
    })
  }
)


export const createSkuRequest = (payload) => (
  dispatch => {
    dispatch(onRequest(CREATE_SKU_REQUEST, true));
    api({
      ...addHeaderAuthorization(localStorage.getItem(AUTH_TOKEN_KEY))
    }).put(SKU_REQUEST_ROUTE_API, payload)
    .then(res => {
      dispatch(onSuccessRequest(CREATE_SKU_REQUEST, res.data));
      dispatch(onRequest(CREATE_SKU_REQUEST, false));
    })
    .catch(err => {
      dispatch(onFailedRequest(CREATE_SKU_REQUEST, err));
      dispatch(onRequest(CREATE_SKU_REQUEST, false));
    })
  }
)