import {
  COUNTRY_LIST_FETCH,
  COUNTRY_FETCHING,
  COUNTRY_ERROR,
  COUNTRY_ALL_FETCH,
  INTERNATIONAL_SHIPPING_UPDATE,
  COUNTRY_BATCH_UPDATE,
} from '../constants';

const initialState = {
  appStatus: "online",
  list: [],
  detail: {},
  message: '',
  status_code: null,
  isFetch: false,
  isSubmit: false,
  error: null,
  success: null
}

const onFetching = (lastState,payload) => {
  if(payload.type==='fetch')
    return { ...lastState, isFetch:payload.status, success: null, error: null }
  else if(payload.type==='submit')
    return { ...lastState, isSubmit:payload.status }

  return { ...lastState, isFetch:false,isSubmit:false }
}

const reducer = (state = initialState, { type, payload }) => {
  switch(type){
    case COUNTRY_LIST_FETCH: {
      const {data, status_code, message} = payload;
      return {...state, list:{...data, message, status_code}, detail: {}, error: null}
    }
    case COUNTRY_ALL_FETCH: {
      const {data, status_code, message} = payload;
      return {...state, list: [...data], message, status_code, detail: {}, error: null}
    }
    case COUNTRY_FETCHING: return onFetching(state, payload);
    case INTERNATIONAL_SHIPPING_UPDATE: {
      const {status_code, message} = payload;
      return {...state, detail:{message, status_code}, success: true, error: null}
    }
    case COUNTRY_BATCH_UPDATE: {
      const {status_code, message} = payload;
      return {...state, detail:{message, status_code}, success: true, error: null}
    }
    case "ERROR_MAINTENANCE_MODE": return {
      ...state,
      appStatus: "offline"
    }
    case COUNTRY_ERROR: {
      return {...state, ...payload, success: null}
    }
    default: return state;
  }
}

export default reducer;