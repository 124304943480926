import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Row,
  Col,
  Card,
  CardBody,
  CardText } from 'reactstrap';

import {Tbl, TblHead, TblBody} from '../../../components/Tbl';
import Paginations from '../../../components/Paginations';
import PaginationSummaries from '../../../components/Paginations/PaginationSummaries';
import { CardHeaderWithToolbars,Title } from '../../../components/Card/CardHeaderWithToolbars';
import Toolbars from '../ConciergeList/Toolbars';
import FilterPanel from '../ConciergeList/FilterPanel';
import FilterContainer from '../../../components/Container/FilterContainer/FilterContainer';
import { ModalInfoCreditCard } from '../../../components/Modals';
import { getConciergeList } from '../../../actions/conciergeAction';
import { generateNumber } from '../../../utils';
import { DEFAULT_SORT_BY, DEFAULT_PER_PAGE } from '../../../constants/settings';
import RowItem from './rowItem';
import privateView from '../../../components/hocs/privateView';
import { Th } from '../../../components/Tbl';
import Analytics from "../../../services/Analytics";

/**
 * Component for when user navigate to /concierge
 * @author sopyan
 */

const defaultParams={
  status : 'completed',
  sort_by : DEFAULT_SORT_BY,
  per_page : DEFAULT_PER_PAGE
}

class ConciergeCompleted extends Component{
	constructor(){
		super();
		this.state={
      _filterOpen: false,
			_params:{
        ...defaultParams
      },
      _modalCreditCard: false,
      _invoiceNumber: null,
    }
    this._handleToggleFilterPanel = this._handleToggleFilterPanel.bind(this);
    this._handleResetFilter = this._handleResetFilter.bind(this);
    this._handleGoto = this._handleGoto.bind(this);
    this._handleRefresh = this._handleRefresh.bind(this);
    this._handleApplyFilter = this._handleApplyFilter.bind(this);
    this._handleToggleModalCreditCard = this._handleToggleModalCreditCard.bind(this);
	}

	componentDidMount(){
    const { email } = this.props.injectedProps;
    this._getList(this.state._params);
    Analytics.recordPageView('completed_concierge_list', email, {
      url: window.location.href,
      location: this.props.location,
      match: this.props.match
    });
  }

  _getList(rawParams = {}){
    let p = { ...rawParams }
    const params = Object.keys(p)
    .filter(key => p[key]!='')
    .reduce((obj, current)=> ({...obj, [current]: p[current]}), {});
    this.props.getList({params});
  }

  _refreshList(){
    const {page, ..._params} = this.state._params;
    this.setState({
      _params
    },()=>this._getList(this.state._params));
  }


	/**
	 * Function to handle rendering table's items or rows
	 * @author sopyan
	 */
	_onRenderRowItem(){
		if(this.props.concierge.list.status_code===200 && this.props.concierge.list.data.length>0){
			const { current_page,per_page } =  this.props.concierge.list;
			return this.props.concierge.list.data.map((item,index)=>{
        const rowNumber = generateNumber(per_page,current_page,index);
        const offer = item.offer;
        return (
          <RowItem
            key={rowNumber}
            id={item.id}
            userId={item.shipping.user_id}
            userEmail={item.user.email}
            rowId={rowNumber}
            name={item.name}
            productName={item.product_name}
            phoneNumber={item.phone_number}
            status={item.status}
            condition={item.condition}
            condition2={item.condition2}
            paymentMethod={offer && offer.payment_method}
            paymentBankTransfer={offer && offer.payment_bank_transfer}
            amount={offer && offer.total_amount}
            adjustedAmount={offer && offer.total_adjusted_amount}
            displayName={offer && offer.user_sell.product_variant.display_name}
            size={offer && offer.user_sell.size.US}
            colour={offer && offer.user_sell.product_variant.colour}
            SKU={offer && offer.user_sell.product_variant.SKU}
            preOrder={offer && offer.user_sell.pre_order}
            expired={offer && offer.expired}
            consignmentId={offer && offer.user_sell.consignment_id}
            bank={offer && offer.bank_code}
            paymentVia={offer && offer.payment_via}
            paymentMethod={offer && offer.payment_method}
            onOpenCreditCard={()=>this._onOpenCreditCardModal(item)}
            rack={offer && offer.user_sell.rack}
            voucherCode={offer && offer.voucherable_id? (offer && offer.voucherable? offer && offer.voucherable.code: `ID: ${offer && offer.voucherable_id}`): null}
            createdAt={item.created_at}
            updatedAt={item.updated_at}
          />
        )
      })
		}
    return null;
	}

	/**
	 * Function to handle rendering paginations
	 * @author sopyan
	 */
	_onRenderPagination(){
    const {status_code, data} = this.props.concierge.list;
    const hasData = (status_code===200 && data.length)||null;
    return hasData && (
      <Paginations
        isLoading={this.props.concierge.isFetch}
        size="sm"
        lastPage={this.props.concierge.list.last_page}
        perPage={this.props.concierge.list.per_page}
        total={this.props.concierge.list.total}
        maxPage={8}
        path={this.props.concierge.list.path}
        currentPage={this.props.concierge.list.current_page}
        prevPageUrl={this.props.concierge.list.prev_page_url}
        nextPageUrl={this.props.concierge.list.next_page_url}
        handleGotoCallback={this._handleGoto}
        onCurrentPageChanged={() => {
          window.scrollTo({
            top: this.tableRef.offsetTop+25,
            behavior: 'smooth'
          })
        }}
      />
    )
	}

	/**
	 * Handle callback when user click pagination items
	 * @author sopyan
	 * @param {integer} page
	 */
	_handleGoto(page){
    const _params=this.state._params;
    _params.page=page;
    this.setState({_params},()=>this._getList(this.state._params));
  }

  _handleRefresh(e){
    e.preventDefault();
    this._refreshList();
  }

  _handleResetFilter(e){
    e.preventDefault();
    const newState = this.state._filterOpen?{_filterOpen:!this.state._filterOpen}:{};
    this.setState({
      ...newState,
      _params:{...defaultParams},
      _isResetFilter: true
    },()=>this._getList(this.state._params));
  }

  _handleApplyFilter(payloadFilter){
    this.setState({
      _params: {
        ...this.state._params,
        ...payloadFilter,
        page: 1
      }
    },()=>this._getList(this.state._params));
  }

  _toggleFilterPanel(){
    this.setState({
      _filterOpen:!this.state._filterOpen});
  }

  _handleToggleFilterPanel(e){
    e.preventDefault();
    this._toggleFilterPanel();
  }

  _onOpenCreditCardModal(item){
    this.setState({_invoiceNumber: item.invoice_number},()=>this._handleToggleModalCreditCard());
  }

  _handleToggleModalCreditCard() {
    this.setState({_modalCreditCard: !this.state._modalCreditCard});
  }

	render(){
		return (
			<div className="animated fadeIn">
        <Row>
          <ModalInfoCreditCard
            isOpen={this.state._modalCreditCard}
            toggle={this._handleToggleModalCreditCard}
            invoiceNumber={this.state._invoiceNumber}
          />
          <Col xs={12}>
            <Card>
              <CardHeaderWithToolbars>
                <Title>
                  <i className="fa fa-align-justify"></i>
                  <span>Completed Concierge List</span>
                </Title>
                <Toolbars
                  isOpen={this.state._filterOpen}
                  onToggleFilter={this._handleToggleFilterPanel}
                  onRefreshList={this._handleRefresh}
                  onResetFilter={this._handleResetFilter}
                />
              </CardHeaderWithToolbars>
              <FilterContainer>
                <FilterPanel
                  {...this.state._params}
                  isOpen={this.state._filterOpen}
                  onApplyFilter={this._handleApplyFilter}
                  isReset={this.state._isResetFilter}
                  onResetFilterCallback={()=>this.setState({_isResetFilter: false, _filterOpen: false})}
                />
              </FilterContainer>
              <CardBody>
                <CardText>
                  In this section will display our completed concierge.
                </CardText>
                <div className="table-responsive" ref={tableRef => this.tableRef = tableRef}>
                  <PaginationSummaries
                    from={this.props.concierge.list.from}
                    to={this.props.concierge.list.to}
                    total={this.props.concierge.list.total}
                  />
                  <Tbl>
                    <TblHead
                      renderChildren={()=>(
                        <tr>
                          <Th>No.</Th>
                          <Th>Concierge</Th>
                          <Th>Payment</Th>
                          <Th>Updated</Th>
                          <Th>Status</Th>
                          <Th>Action</Th>
                        </tr>
                      )}
                    />
                    <TblBody
                      isLoad={this.props.concierge.isFetch}
                      hasRow={this.props.concierge.list.status_code===200&&this.props.concierge.list.data.length>0}
                      columnCount={7}
                    >
                    { this._onRenderRowItem() }
                    </TblBody>
                  </Tbl>
                </div>
                { this._onRenderPagination() }

              </CardBody>
            </Card>
          </Col>
        </Row>
			</div>
		)
	}
}
const mapStateToProps = ({concierge, auth: { isFetch, isLogged, roles, id, email }}) =>{
	return {
		concierge,
    guardData: { isFetch, isLogged, roles, id, email },
	}
}
const mapDispatchToProps = (dispatch) => {
	return{
    getList:(query) => dispatch(getConciergeList(query)),
	}

};
const enhance = connect(mapStateToProps,mapDispatchToProps);

export default enhance(privateView(ConciergeCompleted));
