import React from 'react';
import AlertNotFound from '../../../../components/Card/AlertNotFound'
const AlertError = props => {
  let message = props.message;
  if(!message){
    switch(props.errorStatusCode){
      case "403":
        message = "Forbidden Access.";
        break;
      case 401:
        message ="Unauthorized resource.";
        break;
      case 404:
        message = "Resource not found!";
        break;
      default:
        message = "Error occurred while processing your data.";
        break;
    }
  }
  return(
    <AlertNotFound
      isOpen={props.isOpen}
      toggle={props.toggle}
      message={message}
    />
  )
}

export default AlertError;