import React from 'react';
import propTypes from 'prop-types';
import Paginations from '../../../components/Paginations';
import PaginationSummaries from '../../../components/Paginations/PaginationSummaries';
import { Tbl, TblHead, TblBody } from '../../../components/Tbl';

// const dataHeader=[
//   'No.', 'Brand','Title','Qty','Updated', 'Action'
// ];

class StockList extends React.Component {
  constructor(props){
    super(props);
    this._handleRenderRowItem = this._handleRenderRowItem.bind(this);
  }

  shouldComponentUpdate(nextProps){
    return nextProps.isLoading!==this.props.isLoading;
  }

  _handleRenderRowItem(){
    const { data, perPage, currentPage } = this.props;
    return this.props.onRenderRowItem(data, { perPage, currentPage })
  }

  render(){
    return (
      <div className="animated fadeIn">
        <PaginationSummaries
          from={this.props.from}
          to={this.props.to}
          total={this.props.total}
        />
        <div className="table-responsive" ref={tableRef => this.tableRef = tableRef}>
          <Tbl>
            <TblHead renderChildren={this.props.onRenderHeader}/>
            <TblBody
              isLoad={this.props.isLoading}
              hasRow={this.props.statusCode===200 && this.props.data.length>0}
              columnCount={this.props.columnCount}
              renderChildren={this._handleRenderRowItem}
            />
          </Tbl>
          <Paginations
            isLoading={this.props.isLoading}
            size="sm"
            maxPage={8}
            total={this.props.total}
            currentPage={this.props.currentPage}
            lastPage={this.props.lastPage}
            perPage={this.props.perPage}
            handleGotoCallback={this.props.onGotoPageList}
            onCurrentPageChanged={currentPage => {
              window.scrollTo({
                top: this.tableRef.offsetTop+25,
                behavior: 'smooth'
              })
            }}
          />
        </div>
      </div>
    )
  }
}

export default StockList;

StockList.defaultProps = {
  isLoading: false,
  data: [],
  from: 0,
  to: 0,
  total: 0,
  perPage: 0,
  currentPage: 1,
  lastPage: 0,
}

StockList.propTypes = {
  isLoading: propTypes.bool,
  statusCode: propTypes.oneOfType([propTypes.number, propTypes.string]),
  data: propTypes.arrayOf(propTypes.object),
  from: propTypes.number,
  to: propTypes.number,
  total: propTypes.number,
  perPage: propTypes.oneOfType([propTypes.number, propTypes.string]),
  currentPage: propTypes.number,
  lastPage: propTypes.number,
}
