import React, {Component} from 'react';
import { connect } from 'react-redux';
import {
  Row,
  Col,
  Card,
  CardBody,
  CardText
} from 'reactstrap';

import { CardHeaderWithToolbars,Title } from '../../../components/Card/CardHeaderWithToolbars';
import Toolbars from './Toolbars';
import FilterPanel from './FilterPanel';
import FilterContainer from '../../../components/Container/FilterContainer/FilterContainer';
import DropdownProductCategories from '../../../components/Dropdowns/DropdownProductCategories';
import { Th } from '../../../components/Tbl';

import { getOfferList,disburshOffer } from '../../../actions/offerAction';
import { DEFAULT_SORT_BY, DEFAULT_PER_PAGE } from '../../../constants/settings';
import privateView from '../../../components/hocs/privateView';
import PaymentDisbursementList from './PaymentDisbursementList'
import Analytics from "../../../services/Analytics";

const defaultParams={
  sort_by : DEFAULT_SORT_BY,
  per_page : DEFAULT_PER_PAGE,
  status:'PENDING_REFUND'
}

class PaymentDisbursement extends Component{
  constructor(props)
  {
    super(props);
    this.state={
      _formDisbursh:{
        modalOpen:false,
        selected:null
      },
      _filterOpen: false,
      _params:{
        ...defaultParams
      },
      _pagination:{
        isLoading: true,
        perPage: 0,
        from: 0,
        to: 0,
        total: 0,
        currentPage: 1,
        lastPage: 0,
        nextPageUrl: null,
        path: null,
        prevPageUrl: null
      },
      _selectedCategory: null
    }
    this._handleToggleFilterPanel = this._handleToggleFilterPanel.bind(this);
    this._handleResetFilter = this._handleResetFilter.bind(this);
    this._handleGoto = this._handleGoto.bind(this);
    this._handleRefresh = this._handleRefresh.bind(this);
    this._handleApplyFilter = this._handleApplyFilter.bind(this);
    this._handleUpdateOffer = this._handleUpdateOffer.bind(this);
    this._handleCategoryChanged = this._handleCategoryChanged.bind(this);
  }

  componentDidMount(){
    const { email } = this.props.injectedProps;
    this._getList(this.state._params);
    Analytics.recordPageView('payment_refunds', email, {
      url: window.location.href,
      location: this.props.location,
      match: this.props.match
    });
  }

  _getList(rawParams = {}){
    let p = { ...rawParams }
    if(p.voucher){
      const { voucher, ...other } = p;
      if(Number(voucher.id)){
        p = { ...other, voucher_id: voucher.id }
      }
      else{
        p = { ...other, voucher_code: voucher.code }
      }
    }
    const params = Object.keys(p)
    .filter(key => p[key]!='')
    .reduce((obj, current)=> ({...obj, [current]: p[current]}), {});
    this.props.getList({params});
  }

  componentDidUpdate(prevProps) {
    if(prevProps.offer.isFetch && prevProps.offer.isFetch !== this.props.offer.isFetch){
      const { error } = this.props.offer;
      if(!error){
        const { list } = this.props.offer;
        this.setState({
          _pagination:{
            isLoading: this.props.offer.isFetch,
            perPage: list.per_page,
            from: list.from,
            to: list.to,
            total: list.total,
            lastPage: list.last_page,
            currentPage: list.current_page,
            nextPageUrl: list.next_page_url,
            path: list.path,
            prevPageUrl: list.prev_page_url,
            statusCode: list.status_code
          }
        })
      }
      else{
        const { _pagination, _params } = this.state;
        this.setState({
          _params:{
            ..._params,
            page: _params.page > 1? _params.page -1 : _params.page
          },
          _pagination:{
            ..._pagination,
            isLoading: this.props.offer.isFetch,
            currentPage: _pagination.current_page> 1? _pagination.currentPage -1 : _pagination.currentPage,
          }
        })
      }
    }
  }

  _refreshList(){
    const { _params, _pagination} = this.state;
    this.setState({
      _params: {
        ..._params,
        page: 1
      },
      _pagination: {
        ..._pagination,
        isLoading: true
      }
    },()=>this._getList(this.state._params));
  }

  _handleGoto(page){
    const { _params, _pagination } = { ...this.state };
    _params.page=page;
    this.setState({
      _params,
      _pagination: {
        ..._pagination,
        isLoading: true
      }
    },()=>this._getList(this.state._params));
  }

  _handleRefresh(e){
    e.preventDefault();
    this._refreshList();
  }

  _handleResetFilter(e){
    e.preventDefault();
    const newState = this.state._filterOpen?{_filterOpen:!this.state._filterOpen}:{};
    this.setState({
      ...newState,
      _params:{...defaultParams},
      _pagination: {
        ...this.state._pagination,
        isLoading: true
      },
      _isResetFilter: true,
      _selectedCategory: null
    },()=>this._getList(this.state._params));
  }

  _handleApplyFilter(payloadFilter){
    this.setState({
      _params: {
        ...this.state._params,
        ...payloadFilter,
        status: 'PENDING_REFUND',
        page: 1
      },
      _pagination:{
        ...this.state._pagination,
        isLoading: true
      }
    },()=>this._getList(this.state._params));
  }

  _toggleFilterPanel(){
    this.setState({_filterOpen:!this.state._filterOpen})
  }

  _handleToggleFilterPanel(e){
    e.preventDefault();
    this._toggleFilterPanel();
  }

  _handleUpdateOffer(id, payloads){
    this.props.disburse(id, payloads);
  }

  async _handleCategoryChanged(category){
    await this.setState({
      _selectedCategory: category === ''? null: category.label,
      _params: {
        ...this.state._params,
        category_id: category === ''? category: category.value,
        page: 1,
      },
      _pagination:{
        ...this.state._pagination,
        isLoading: true
      }
    });
    const { _params } = this.state;
    this._getList(_params);
  }

  render(){
    return (
      <div className="animated fadeIn">
        <Row>
          <Col xs={12}>
            <Card>
              <CardHeaderWithToolbars>
                <Title>
                  <i className="fa fa-align-justify"></i>
                  <span>REFUND TO BALANCE</span>
                </Title>
                <Toolbars
                  isOpen={this.state._filterOpen}
                  onToggleFilter={this._handleToggleFilterPanel}
                  onRefreshList={this._handleRefresh}
                  onResetFilter={this._handleResetFilter}
                />
              </CardHeaderWithToolbars>
              <FilterContainer>
                <FilterPanel
                  {...this.state._params}
                  isOpen={this.state._filterOpen}
                  onApplyFilter={this._handleApplyFilter}
                  isReset={this.state._isResetFilter}
                  onResetFilterCallback={()=>this.setState({_isResetFilter: false, _filterOpen: false})}
                />
              </FilterContainer>
              <CardBody>
                <CardText>
                  In this section will display our user's payments in <strong>PENDING REFUND</strong>.
                  Process the payment will be move amounts into user's balance.
                  {/* Amounts will calculate product price and courier price only. */}
                  Please validate carefully, you cannot undoing your changed.
                </CardText>
                <PaymentDisbursementList
                  {...this.state._pagination}
                  dataErrorOffer={this.props.offer.error}
                  dataUpdatedOffer={this.props.offer.detail}
                  list={this.props.offer.list.data}
                  isSubmit={this.props.offer.isSubmit}
                  handleGotoList={this._handleGoto}
                  handleUpdateOffer={this._handleUpdateOffer}
                  onRenderHeader={()=>(
                    <tr>
                      <Th>No.</Th>
                      <Th>
                        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'flex-end'}}>
                          <p style={{marginRight: 10, marginBottom: 0}}>
                            Order Detail
                            {
                              this.state._selectedCategory !== null?
                              <span style={{display: 'block', fontSize: '.5rem'}}>{this.state._selectedCategory}</span>
                              : null
                            }
                          </p>
                          <DropdownProductCategories
                            value={this.state._params.category_id}
                            onCategoryChanged={this._handleCategoryChanged}
                          />
                        </div>
                      </Th>
                      <Th>Total</Th>
                      <Th>Payment</Th>
                      <Th>Updated</Th>
                      <Th>Action</Th>
                    </tr>
                  )}
                  email={this.props.injectedProps.email}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    )
  }
}
const mapStateToProps= ({offer, auth: { isFetch, isLogged, roles, id, email } }) => {
	return {
    offer,
    guardData: { isFetch, isLogged, roles, id, email }
	}
};
const mapDispatchToProps = (dispatch) => {
	return{
    getList:(query) => dispatch(getOfferList(query)),
    disburse:(id,payload)=>dispatch(disburshOffer(id,payload))
	}
};
const enhance = connect(mapStateToProps,mapDispatchToProps);
export default enhance(privateView(PaymentDisbursement));