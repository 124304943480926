import { Link } from 'react-router-dom';
import styled from 'styled-components';

// Link with black color
export const SecondaryLink = styled(Link)`
  text-decoration: none;
  color: black;
  display: block;
  &:hover, &:focus, &:active{
    color: black;
    text-decoration: none;
  }
`;
