import React from 'react';

export default function (props) {
    const {
        isOpenPrint,
        allSelected,
        handleCheckboxChange,
        isSelected,
        printInvoice,
        from,
        to,
        total,
    } = props;
    return(
        <div className="print-order-pagination">
            <div>
                <label className={!isOpenPrint? "d-none" : ""} style={{margin:0}}>
                    <input
                        className="print-order-checkbox"
                        type="checkbox"
                        name="_allSelected"
                        checked={allSelected}
                        onChange={handleCheckboxChange}
                    />
                    {`${isSelected}/${to} Selected`}
                </label>
                <button
                    onClick={printInvoice}
                    className={!isOpenPrint||!isSelected? "d-none" : "create-invoice-button"}
                >
                    Create Invoice
                </button>
            </div>
            <div>
                {`Showing ${from} to ${to} of ${total} items.`}
            </div>
        </div>
    )
}
