import {
  RAFFLE_ROUTE_API,
  GET_LIST_RAFFLE,
  GET_DETAIL_RAFFLE,
  CREATE_RAFFLE,
  UPDATE_RAFFLE,
  DELETE_RAFFLE,
  RAFFLE_ERROR,
  RAFFLE_REQUEST,
  GET_LIST_RAFFLE_ENTRIES,
  CREATE_RAFFLE_ENTRIES,
  DELETE_RAFFLE_ENTRIES,
  GET_LIST_RAFFLE_COUPONS,
  CREATE_RAFFLE_COUPONS,
  UPDATE_RAFFLE_COUPONS,
  REDEEM_RAFFLE_COUPONS,
  RAFFLE_S3,
  RAFFLE_REQUEST_ROUTE_API,
  GET_LIST_RAFFLE_REQUEST,
  GET_DETAIL_RAFFLE_REQUEST,
  UPDATE_RAFFLE_REQUEST,
  SUBMITTING_RAFFLE_ITEMS,
  SEARCH_RAFFLE_ITEMS,
  GET_LIST_RAFFLE_ITEMS,
  GET_LIST_RAFFLE_TIERS,
  SEARCH_RAFFLE_TIERS,
  SUBMITTING_RAFFLE_TIERS,
  GET_LIST_RAFFLE_GROUP,
  CREATE_RAFFLE_GROUP,
  UPDATE_RAFFLE_GROUP,
  DELETE_RAFFLE_GROUP,
  GET_DETAIL_RAFFLE_GROUP,
  UPDATE_RAFFLE_ENTRIES
} from '../constants';
import {
  onRequest,
  onSuccessRequest,
  onFailedRequest
} from './helpers';
import { apiClient } from '../utils/Api';
import { AWS_BUCKET_URL } from '../config/storageBucket';

export const getRaffleList = q => (
  dispatch => {
    dispatch(onRequest(RAFFLE_REQUEST, GET_LIST_RAFFLE, true));
    apiClient()
    .get(RAFFLE_ROUTE_API, q)
    .then(res => {
      dispatch(onSuccessRequest(GET_LIST_RAFFLE, res.data));
      dispatch(onRequest(RAFFLE_REQUEST, GET_LIST_RAFFLE, false));
    })
    .catch(err => {
      dispatch(onFailedRequest(RAFFLE_ERROR, GET_LIST_RAFFLE, err));
      dispatch(onRequest(RAFFLE_REQUEST, GET_LIST_RAFFLE, false));
    });
  }
)

export const getRaffleById = id => (
  dispatch => {
    dispatch(onRequest(RAFFLE_REQUEST, GET_DETAIL_RAFFLE, true));
    apiClient()
    .get(`${RAFFLE_ROUTE_API}/${id}`)
    .then(res => {
      dispatch(onSuccessRequest(GET_DETAIL_RAFFLE, res.data));
      dispatch(onRequest(RAFFLE_REQUEST, GET_DETAIL_RAFFLE, false));
    })
    .catch(err => {
      dispatch(onFailedRequest(RAFFLE_ERROR, GET_DETAIL_RAFFLE, err, id));
      dispatch(onRequest(RAFFLE_REQUEST, GET_DETAIL_RAFFLE, false));
    });
  }
)

export const saveRaffle = payload => (
  dispatch => {
    const { id, ...body } = payload;
    return id? dispatch(updateRaffle(id, body)): dispatch(createRaffle(body))
  }
)

export const updateRaffle = (id, payload) => (
  dispatch => {
    dispatch(onRequest(RAFFLE_REQUEST, UPDATE_RAFFLE, true));
    apiClient()
    .put(`${RAFFLE_ROUTE_API}/${id}`, payload)
    .then(res => {
      dispatch(onSuccessRequest(UPDATE_RAFFLE, res.data));
      dispatch(onRequest(RAFFLE_REQUEST, UPDATE_RAFFLE, false));
    })
    .catch(err => {
      dispatch(onFailedRequest(RAFFLE_ERROR, UPDATE_RAFFLE, err, id));
      dispatch(onRequest(RAFFLE_REQUEST, UPDATE_RAFFLE, false));
    });
  }
)

export const createRaffle = payload => (
  dispatch => {
    dispatch(onRequest(RAFFLE_REQUEST, CREATE_RAFFLE, true));
    apiClient()
    .post(RAFFLE_ROUTE_API, payload)
    .then(res => {
      dispatch(onSuccessRequest(CREATE_RAFFLE, res.data));
      dispatch(onRequest(RAFFLE_REQUEST, CREATE_RAFFLE, false));
    })
    .catch(err => {
      dispatch(onFailedRequest(RAFFLE_ERROR, CREATE_RAFFLE, err));
      dispatch(onRequest(RAFFLE_REQUEST, CREATE_RAFFLE, false));
    });
  }
)

export const deleteRaffleById = id => (
  dispatch => {
    dispatch(onRequest(RAFFLE_REQUEST, DELETE_RAFFLE, true));
    apiClient()
    .delete(`${RAFFLE_ROUTE_API}/${id}`)
    .then(res => {
      dispatch(onSuccessRequest(DELETE_RAFFLE, res.data));
      dispatch(onRequest(RAFFLE_REQUEST, DELETE_RAFFLE, false));
    })
    .catch(err => {
      dispatch(onFailedRequest(RAFFLE_ERROR, DELETE_RAFFLE, err, id));
      dispatch(onRequest(RAFFLE_REQUEST, DELETE_RAFFLE, false));
    });
  }
)

export const getRaffleEntryList = (raffleId, q) => (
  dispatch => {
    dispatch(onRequest(RAFFLE_REQUEST, GET_LIST_RAFFLE_ENTRIES, true));
    apiClient()
    .get(`${RAFFLE_ROUTE_API}/${raffleId}/entries`, q)
    .then(res => {
      dispatch(onSuccessRequest(GET_LIST_RAFFLE_ENTRIES, res.data));
      dispatch(onRequest(RAFFLE_REQUEST, GET_LIST_RAFFLE_ENTRIES, false));
    })
    .catch(err => {
      dispatch(onFailedRequest(RAFFLE_ERROR, GET_LIST_RAFFLE_ENTRIES, err));
      dispatch(onRequest(RAFFLE_REQUEST, GET_LIST_RAFFLE_ENTRIES, false));
    });
  }
)

export const getRaffleEntryListByGroup = (raffleGroupId, q) => (
  dispatch => {
    dispatch(onRequest(RAFFLE_REQUEST, GET_LIST_RAFFLE_ENTRIES, true));
    apiClient()
    .get(`${RAFFLE_ROUTE_API}/${raffleGroupId}/entries2`, q)
    .then(res => {
      dispatch(onSuccessRequest(GET_LIST_RAFFLE_ENTRIES, res.data));
      dispatch(onRequest(RAFFLE_REQUEST, GET_LIST_RAFFLE_ENTRIES, false));
    })
    .catch(err => {
      dispatch(onFailedRequest(RAFFLE_ERROR, GET_LIST_RAFFLE_ENTRIES, err));
      dispatch(onRequest(RAFFLE_REQUEST, GET_LIST_RAFFLE_ENTRIES, false));
    });
  }
)

export const createRaffleEntry = payload => (
  dispatch => {
    dispatch(onRequest(RAFFLE_REQUEST, CREATE_RAFFLE_ENTRIES, true));
    apiClient()
    .post(`${RAFFLE_ROUTE_API}/entries`, payload)
    .then(res => {
      dispatch(onSuccessRequest(CREATE_RAFFLE_ENTRIES, res.data));
      dispatch(onRequest(RAFFLE_REQUEST, CREATE_RAFFLE_ENTRIES, false));
    })
    .catch(err => {
      dispatch(onFailedRequest(RAFFLE_ERROR, CREATE_RAFFLE_ENTRIES, err));
      dispatch(onRequest(RAFFLE_REQUEST, CREATE_RAFFLE_ENTRIES, false));
    });
  }
)

export const updateRaffleEntry = (id, payload) => (
  dispatch => {
    dispatch(onRequest(RAFFLE_REQUEST, UPDATE_RAFFLE_ENTRIES, true));
    apiClient()
    .put(`${RAFFLE_ROUTE_API}/entries/${id}`, payload)
    .then(res => {
      dispatch(onSuccessRequest(UPDATE_RAFFLE_ENTRIES, res.data));
      dispatch(onRequest(RAFFLE_REQUEST, UPDATE_RAFFLE_ENTRIES, false));
    })
    .catch(err => {
      dispatch(onFailedRequest(RAFFLE_ERROR, UPDATE_RAFFLE_ENTRIES, err));
      dispatch(onRequest(RAFFLE_REQUEST, UPDATE_RAFFLE_ENTRIES, false));
    });
  }
)

export const deleteRaffleEntry = (raffleId, id) => (
  dispatch => {
    dispatch(onRequest(RAFFLE_REQUEST, DELETE_RAFFLE_ENTRIES, true));
    apiClient()
    .delete(`${RAFFLE_ROUTE_API}/${raffleId}/entries/${id}`)
    .then(res => {
      dispatch(onSuccessRequest(DELETE_RAFFLE_ENTRIES, res.data));
      dispatch(onRequest(RAFFLE_REQUEST, DELETE_RAFFLE_ENTRIES, false));
    })
    .catch(err => {
      dispatch(onFailedRequest(RAFFLE_ERROR, DELETE_RAFFLE_ENTRIES, err, id));
      dispatch(onRequest(RAFFLE_REQUEST, DELETE_RAFFLE_ENTRIES, false));
    });
  }
)

export const deleteRaffleEntryByGroup = (raffleGroupId, id) => (
  dispatch => {
    dispatch(onRequest(RAFFLE_REQUEST, DELETE_RAFFLE_ENTRIES, true));
    apiClient()
    .delete(`${RAFFLE_ROUTE_API}/${raffleGroupId}/entries2/${id}`)
    .then(res => {
      dispatch(onSuccessRequest(DELETE_RAFFLE_ENTRIES, res.data));
      dispatch(onRequest(RAFFLE_REQUEST, DELETE_RAFFLE_ENTRIES, false));
    })
    .catch(err => {
      dispatch(onFailedRequest(RAFFLE_ERROR, DELETE_RAFFLE_ENTRIES, err, id));
      dispatch(onRequest(RAFFLE_REQUEST, DELETE_RAFFLE_ENTRIES, false));
    });
  }
)

export const getRaffleCouponList = (raffleId, q) => (
  dispatch => {
    dispatch(onRequest(RAFFLE_REQUEST, GET_LIST_RAFFLE_COUPONS, true));
    apiClient()
    .get(`${RAFFLE_ROUTE_API}/${raffleId}/coupons`, q)
    .then(res => {
      dispatch(onSuccessRequest(GET_LIST_RAFFLE_COUPONS, res.data));
      dispatch(onRequest(RAFFLE_REQUEST, GET_LIST_RAFFLE_COUPONS, false));
    })
    .catch(err => {
      dispatch(onFailedRequest(RAFFLE_ERROR, GET_LIST_RAFFLE_COUPONS, err));
      dispatch(onRequest(RAFFLE_REQUEST, GET_LIST_RAFFLE_COUPONS, false));
    });
  }
)
export const createRaffleCoupon = (raffleId, payload) => (
  dispatch => {
    dispatch(onRequest(RAFFLE_REQUEST, CREATE_RAFFLE_COUPONS, true));
    apiClient()
    .post(`${RAFFLE_ROUTE_API}/${raffleId}/coupons`, payload)
    .then(res => {
      dispatch(onSuccessRequest(CREATE_RAFFLE_COUPONS, res.data));
      dispatch(onRequest(RAFFLE_REQUEST, CREATE_RAFFLE_COUPONS, false));
    })
    .catch(err => {
      dispatch(onFailedRequest(RAFFLE_ERROR, CREATE_RAFFLE_COUPONS, err));
      dispatch(onRequest(RAFFLE_REQUEST, CREATE_RAFFLE_COUPONS, false));
    });
  }
)

export const updateRaffleCoupon = (raffleId, id, payload) => (
  dispatch => {
    dispatch(onRequest(RAFFLE_REQUEST, UPDATE_RAFFLE_COUPONS, true));
    apiClient()
    .put(`${RAFFLE_ROUTE_API}/${raffleId}/coupons/${id}`, payload)
    .then(res => {
      dispatch(onSuccessRequest(UPDATE_RAFFLE_COUPONS, res.data));
      dispatch(onRequest(RAFFLE_REQUEST, UPDATE_RAFFLE_COUPONS, false));
    })
    .catch(err => {
      dispatch(onFailedRequest(RAFFLE_ERROR, UPDATE_RAFFLE_COUPONS, err));
      dispatch(onRequest(RAFFLE_REQUEST, UPDATE_RAFFLE_COUPONS, false));
    });
  }
)


export const redeemRaffleCoupon = (raffleId, payload) => (
  dispatch => {
    dispatch(onRequest(RAFFLE_REQUEST, REDEEM_RAFFLE_COUPONS, true));
    apiClient()
    .post(`${RAFFLE_ROUTE_API}/${raffleId}/coupon_redeem`, payload)
    .then(res => {
      dispatch(onSuccessRequest(REDEEM_RAFFLE_COUPONS, res.data));
      dispatch(onRequest(RAFFLE_REQUEST, REDEEM_RAFFLE_COUPONS, false));
    })
    .catch(err => {
      dispatch(onFailedRequest(RAFFLE_ERROR, REDEEM_RAFFLE_COUPONS, err));
      dispatch(onRequest(RAFFLE_REQUEST, REDEEM_RAFFLE_COUPONS, false));
    });
  }
)

export const uploadRaffleImage = (payload) => (
  dispatch => {
    dispatch({
      type: RAFFLE_S3,
      payload: { isPending: true }
    })
    apiClient()
    .post('admins/assets', payload)
    .then(({ data: { data } }) => {
      const results={
        prefix: AWS_BUCKET_URL,
        ...data
      }
      dispatch({
        type: RAFFLE_S3,
        payload: { results, isPending: false }
      });
    })
    .catch(err => {
      dispatch(onFailedRequest(RAFFLE_ERROR,
        RAFFLE_S3, {
          type: RAFFLE_S3,
          payload: { error: err }
        })
      );
    })
  }
)

export const getRaffleRequestList = q => (
  dispatch => {
    dispatch(onRequest(RAFFLE_REQUEST, GET_LIST_RAFFLE_REQUEST, true));
    apiClient()
    .get(RAFFLE_REQUEST_ROUTE_API, q)
    .then(res => {
      dispatch(onSuccessRequest(GET_LIST_RAFFLE_REQUEST, res.data));
      dispatch(onRequest(RAFFLE_REQUEST, GET_LIST_RAFFLE_REQUEST, false));
    })
    .catch(err => {
      dispatch(onFailedRequest(RAFFLE_ERROR, GET_LIST_RAFFLE_REQUEST, err));
      dispatch(onRequest(RAFFLE_REQUEST, GET_LIST_RAFFLE_REQUEST, false));
    });
  }
)

export const getRaffleRequestDetail = id => {
  return dispatch => {
    dispatch(onRequest(RAFFLE_REQUEST, GET_DETAIL_RAFFLE_REQUEST, true));
    apiClient()
    .get(`${RAFFLE_REQUEST_ROUTE_API}/${id}`)
    .then(res => {
			dispatch(onSuccessRequest(GET_DETAIL_RAFFLE_REQUEST, res.data));
        dispatch(onRequest(RAFFLE_REQUEST, GET_DETAIL_RAFFLE_REQUEST, false));
    })
    .catch(err => {
      dispatch(onFailedRequest(RAFFLE_ERROR, GET_DETAIL_RAFFLE_REQUEST, err));
      dispatch(onRequest(RAFFLE_REQUEST, GET_DETAIL_RAFFLE_REQUEST, false));
    })
  }
}

export const updateRaffleRequest = (id, payload) => (
  dispatch => {
    dispatch(onRequest(RAFFLE_REQUEST, UPDATE_RAFFLE_REQUEST, true));
    apiClient()
    .put(`${RAFFLE_REQUEST_ROUTE_API}/${id}`, payload)
    .then(res => {
      dispatch(onSuccessRequest(UPDATE_RAFFLE_REQUEST, res.data));
      dispatch(onRequest(RAFFLE_REQUEST, UPDATE_RAFFLE_REQUEST, false));
    })
    .catch(err => {
      dispatch(onFailedRequest(RAFFLE_ERROR, UPDATE_RAFFLE_REQUEST, err, id));
      dispatch(onRequest(RAFFLE_REQUEST, UPDATE_RAFFLE_REQUEST, false));
    });
  }
)

export const getRaffleItems = (id, query) => (
	dispatch => {
		let actionType = GET_LIST_RAFFLE_ITEMS;
		if(query.params.exclude === true){
			actionType = 	SEARCH_RAFFLE_ITEMS;
		}
		dispatch(onRequest(
			RAFFLE_REQUEST,
			actionType,
			true
		))
    apiClient()
    .get(`admins/raffles/${id}/variants`, query)
		.then(res=>{
			dispatch(onSuccessRequest(actionType, res.data))
			dispatch(onRequest(
				RAFFLE_REQUEST,
				actionType,
				false
			))
		})
		.catch(err => {
			dispatch(onFailedRequest(RAFFLE_ERROR, actionType, err))
			dispatch(onRequest(
				RAFFLE_REQUEST,
				actionType,
				false
			))
		})
	}
)


export const saveRaffleItems = (operation, id, payload) => (
	dispatch => {
		dispatch(onRequest(
			RAFFLE_REQUEST,
			SUBMITTING_RAFFLE_ITEMS,
			true
		))
		let request = null;
		if(operation === 'attach'){
      request = apiClient()
      .post(`admins/attach/raffles/${id}/variants`, payload)
		} else{ //detach
      request = apiClient()
      .post(`admins/detach/raffles/${id}/variants`, payload)
		}
		request
		.then(res=>{
			dispatch(onSuccessRequest(SUBMITTING_RAFFLE_ITEMS, res.data))
			dispatch(onRequest(
				RAFFLE_REQUEST,
				SUBMITTING_RAFFLE_ITEMS,
				false
			))
		})
		.catch(err => {
			dispatch(onFailedRequest(RAFFLE_ERROR, SUBMITTING_RAFFLE_ITEMS, err));
			dispatch(onRequest(
				RAFFLE_REQUEST,
				SUBMITTING_RAFFLE_ITEMS,
				false
			))
		})
	}
)


export const saveRaffleTiers = (operation, id, payload) => (
	dispatch => {
		dispatch(onRequest(
			RAFFLE_REQUEST,
			SUBMITTING_RAFFLE_TIERS,
			true
		))
		let request = null;
		if(operation === 'attach'){
      request = apiClient()
      .post(`admins/attach/raffles/${id}/raffle_tiers`, payload)
		} else{ //detach
      request = apiClient()
      .post(`admins/detach/raffles/${id}/raffle_tiers`, payload)
		}
		request
		.then(res=>{
			dispatch(onSuccessRequest(SUBMITTING_RAFFLE_TIERS, res.data))
			dispatch(onRequest(
				RAFFLE_REQUEST,
				SUBMITTING_RAFFLE_TIERS,
				false
			))
		})
		.catch(err => {
			dispatch(onFailedRequest(RAFFLE_ERROR, SUBMITTING_RAFFLE_TIERS, err, id));
			dispatch(onRequest(
				RAFFLE_REQUEST,
				SUBMITTING_RAFFLE_TIERS,
				false
			))
		})
	}
)

export const getRaffleTiers = (id, query) => (
	dispatch => {
		let actionType = GET_LIST_RAFFLE_TIERS;
		if(query.params.exclude === true){
			actionType = 	SEARCH_RAFFLE_TIERS;
		}
		dispatch(onRequest(
			RAFFLE_REQUEST,
			actionType,
			true
		))
    apiClient()
    .get(`admins/raffles/${id}/tiers`, query)
		.then(res=>{
			dispatch(onSuccessRequest(actionType, res.data))
			dispatch(onRequest(
				RAFFLE_REQUEST,
				actionType,
				false
			))
		})
		.catch(err => {
			dispatch(onFailedRequest(RAFFLE_ERROR, actionType, err, { id, query }))
			dispatch(onRequest(
				RAFFLE_REQUEST,
				actionType,
				false
			))
		})
	}
)

export const getRaffleGroups = (query) => (
  dispatch => {
    dispatch(onRequest(RAFFLE_REQUEST, GET_LIST_RAFFLE_GROUP, true));
    apiClient()
    .get(`admins/raffle-groups`, query)
		.then(res=>{
			dispatch(onSuccessRequest(GET_LIST_RAFFLE_GROUP, res.data))
      dispatch(onRequest(RAFFLE_REQUEST, GET_LIST_RAFFLE_GROUP, false));
		})
		.catch(err => {
      dispatch(onFailedRequest(RAFFLE_ERROR, GET_LIST_RAFFLE_GROUP, err));
      dispatch(onRequest(RAFFLE_REQUEST, GET_LIST_RAFFLE_GROUP, false));
		})
  }
)

export const createRaffleGroups = payload => (
  dispatch => {
    dispatch(onRequest(RAFFLE_REQUEST, CREATE_RAFFLE_GROUP, true));
    apiClient()
    .post(`admins/raffle-groups`, payload)
		.then(res=>{
      dispatch(onSuccessRequest(CREATE_RAFFLE_GROUP, res.data));
      dispatch(onRequest(RAFFLE_REQUEST, CREATE_RAFFLE_GROUP, false));
		})
		.catch(err => {
      dispatch(onFailedRequest(RAFFLE_ERROR, CREATE_RAFFLE_GROUP, err));
      dispatch(onRequest(RAFFLE_REQUEST, CREATE_RAFFLE_GROUP, false));
		})
  }
)

export const getRaffleGroupById = id => (
  dispatch => {
    dispatch(onRequest(RAFFLE_REQUEST, GET_DETAIL_RAFFLE_GROUP, true));
    apiClient()
    .get(`admins/raffle-groups/${id}`)
    .then(res => {
      dispatch(onSuccessRequest(GET_DETAIL_RAFFLE_GROUP, res.data));
      dispatch(onRequest(RAFFLE_REQUEST, GET_DETAIL_RAFFLE_GROUP, false));
    })
    .catch(err => {
      dispatch(onFailedRequest(RAFFLE_ERROR, GET_DETAIL_RAFFLE_GROUP, err, id));
      dispatch(onRequest(RAFFLE_REQUEST, GET_DETAIL_RAFFLE_GROUP, false));
    });
  }
)

export const saveRaffleGroup = payload => (
  dispatch => {
    const { id, ...body } = payload;
    return id? dispatch(updateRaffleGroups(id, body)): dispatch(createRaffleGroups(body))
  }
)

export const updateRaffleGroups = (id, body) => (
  dispatch => {
    dispatch(onRequest(RAFFLE_REQUEST, UPDATE_RAFFLE_GROUP, true));
    apiClient()
    .put(`admins/raffle-groups/${id}`, body)
		.then(res=>{
      dispatch(onSuccessRequest(UPDATE_RAFFLE_GROUP, res.data));
      dispatch(onRequest(RAFFLE_REQUEST, UPDATE_RAFFLE_GROUP, false));
		})
		.catch(err => {
      dispatch(onFailedRequest(RAFFLE_ERROR, UPDATE_RAFFLE_GROUP, err, id));
      dispatch(onRequest(RAFFLE_REQUEST, UPDATE_RAFFLE_GROUP, false));
		})
  }
)

export const deleteRaffleGroupById = id => (
  dispatch => {
    dispatch(onRequest(RAFFLE_REQUEST, DELETE_RAFFLE_GROUP, true));
    apiClient()
    .delete(`admins/raffle-groups/${id}`)
    .then(res => {
      dispatch(onSuccessRequest(DELETE_RAFFLE_GROUP, res.data));
      dispatch(onRequest(RAFFLE_REQUEST, DELETE_RAFFLE_GROUP, false));
    })
    .catch(err => {
      dispatch(onFailedRequest(RAFFLE_ERROR, DELETE_RAFFLE_GROUP, err, id));
      dispatch(onRequest(RAFFLE_REQUEST, DELETE_RAFFLE_GROUP, false));
    });
  }
)