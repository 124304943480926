import React from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  FormGroup,
  Col
} from 'reactstrap';
import { getCollectionItems } from '../../../../actions/collectionAction';
import { FontAwesomeIcon } from '../../../../components/Icons';
import ItemList, { ProductVariantItem } from '../ItemList'

const defaultParams = {
  per_page: 15,
  page: 1,
  sort_by: 'updatedAt_desc',
  exclude: true
}

const defaultPaginations = {
  from: 0,
  to: 0,
  total: 0,
  currentPage: 0,
  lastPage: 0,
  perPage: 0
}

class ModalProductVariants extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      _params: {
        ...defaultParams
      },
      paginations: {
        from: 0,
        to: 0,
        total: 0,
        currentPage: 0,
        lastPage: 0,
        perPage: 0
      },
      list: [],
      listSelected: [],
    }
  }

  componentDidUpdate(prevProps){
    if(this.props.isOpen && this.props.isOpen !== prevProps.isOpen){
      this.setState({
        _params: {
          ...this.state._params,
          ...defaultParams,
          category_id: this.props.dataCategories.length>0 && this.state._params.category_id === ""?
            this.props.dataCategories[0].value:
            (this.state._params.category_id? this.state._params.category_id: "")
        },
        paginations:{
          ...defaultPaginations
        },
        list: [],
        listSelected: []
      })
    }
    if(!this.props.dataCollectionItems.isFetch && prevProps.dataCollectionItems.isFetch !== this.props.dataCollectionItems.isFetch){
      const { data, ...paginations } = this.props.dataCollectionItems.data;
      this.setState({
        list: [...data],
        paginations: {
          ...this.state.paginations,
          from: paginations.from,
          to: paginations.to,
          total: paginations.total,
          currentPage: paginations.current_page,
          lastPage: paginations.last_page,
          perPage: paginations.per_page
        }
      })
    }
  }

  _sanitizeParams = rawParams => {
    return {
      params: Object.keys(rawParams)
      .filter(key => rawParams[key]!='')
      .reduce((obj, current)=> ({...obj, [current]: rawParams[current]}), [])
    }
  }

  _getDataList = (rawParams = {}) => {
    const { collectionId } = this.props;
    this.props.getCollectionItems(
      collectionId,
      this._sanitizeParams(rawParams)
    )
  }

  _handleGoto = page => {
    const _params = this.state._params;
    _params.page = page;
    this.setState({ _params },
      ()=> this._getDataList(this.state._params)
    );
  }

  _handleOnSubmit = () => {
    const { onSubmit, toggle } = this.props;
    const { listSelected } = this.state;
    if(listSelected.length){
      onSubmit(listSelected);
    }
    toggle();
  }

  render(){
    return(
      <Modal isOpen={this.props.isOpen} toggle={this.props.toggle} size={this.props.size}>
        <ModalBody>
          <Form
            style={{marginBottom: '.5rem'}}
            onSubmit={e => {
              e.preventDefault()
              this._getDataList(this.state._params);
            }}
          >
            <FormGroup row style={{marginBottom: '.5rem'}}>
              <Col sm={4}>
                <Input
                  bsSize="sm"
                  type="select"
                  name="category_id"
                  value={this.state._params.category_id||""}
                  onChange={e => {
                    this.setState({
                      _params:{
                        ...this.state._params,
                        [e.target.name]: e.target.value
                      }
                    })
                  }}
                >
                  <option value="">All Category</option>
                  {this.props.dataCategories.map(category => (
                    <option key={category.value} value={category.value}>{category.label}</option>
                  ))}
                </Input>
              </Col>
            </FormGroup>
            <InputGroup>
              <Input
                bsSize="sm"
                placeholder="Search products..."
                value={this.state._params.keyword||''}
                name="keyword"
                onChange={e => {
                  this.setState({
                    _params:{
                      ...this.state._params,
                      [e.target.name]: e.target.value
                    }
                  })
                }}
              />
              <InputGroupAddon addonType="append">
                <Button color="primary">Search</Button>
              </InputGroupAddon>
            </InputGroup>
          </Form>
          <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: '1rem', alignItems: 'center'}}>
            <div>
              {this.state.listSelected.length > 0?(
                <p style={{margin: 0, fontSize: '.5rem'}} className="text-muted">Selected ({this.state.listSelected.length}) item(s).</p>
              ):null}
            </div>
            <div>
              {
                this.state.listSelected.length > 0?(
                  <Button
                    outline
                    color="danger"
                    size="sm"
                    type="button"
                    className="mr-1"
                    style={{fontSize: '.5rem'}}
                    onClick={() => this.setState({listSelected: []})}
                  >
                    Clear Selected
                  </Button>
                ): null
              }
              <Button
                outline
                title="Refresh list"
                color="primary"
                style={{fontSize: '.5rem'}}
                size="sm"
                type="button"
                onClick={() => this._handleGoto(1)}
              >
                <FontAwesomeIcon iconType="refresh"/>
              </Button>
            </div>
          </div>
          <ItemList
            isLoading={this.props.dataCollectionItems.isFetch}
            paginations={this.state.paginations}
            list={this.state.list}
            renderItems={item => (
              <ProductVariantItem
                key={item.id}
                data={item}
                checked={this.state.listSelected.includes(item.id)}
                onSelectedChange={({target: { checked: value }}) => {
                  const { listSelected } = this.state;
                  this.setState({
                    listSelected: value? [...listSelected, item.id]: listSelected.filter(id => id !== item.id)
                  })
                }}
              />
            )}
            onGotoPage={this._handleGoto}
          />
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={this.props.toggle}>Cancel</Button>
          <Button color="primary" onClick={this._handleOnSubmit}>Save</Button>{' '}
        </ModalFooter>
      </Modal>
    )
  }
}

const mapStateToProps= ({ masterCollection: { collectionItems } }) => ({
  dataCollectionItems: {
    isFetch: collectionItems.isSearchFetch,
    data: collectionItems.dataSearch,
    error: collectionItems.error
  }
});
const mapDispatchToProps = (dispatch) => ({
  getCollectionItems: (id, query) => dispatch(getCollectionItems(id, 'product_variants', query)),
});
export const ModalProductVariant = connect(mapStateToProps,mapDispatchToProps)(ModalProductVariants);