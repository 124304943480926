import React from 'react';
import _ from 'lodash'
import { Link } from 'react-router-dom';
import propTypes from 'prop-types';
import DateToHuman from '../../../../../components/DateToHuman';
import { TextRupiah } from '../../../../../components/Text';
import { getPaymentType, getPaymentVia } from '../../../../../constants/kickavenue';
import OrderSummariesColumn from '../../../RowItem/OrderSummariesColumn';
import ActionButtonsColumn from './ActionButtonsColumn';
import FontAwesomeIcon from '../../../../../components/Icons/FontAwesomeIcon';

class RowItem extends React.Component{
  shouldComponentUpdate(nextProps) {
    return nextProps.isSelected!=this.props.isSelected || nextProps.dataItem !== this.props.dataItem;
  }
  render(){
    const {
      id,
      rowId,
      quantity,
      category,
      userId,
      userEmail,
      invoiceNumber,
      amount,
      adjustedAmount,
      paymentMethod,
      displayName,
      SKU,
      size,
      colour,
      updatedAt,
      isSelected,
      onOpenModalClick,
      preOrder,
      consignmentId,
      bank,
      paymentVia,
      rack,
      points
    } = this.props;
    const via = getPaymentVia(paymentVia);
    return(
      <tr>
        <th className="v-center" scope="row">{ rowId }</th>
        <td className="v-center">
          <OrderSummariesColumn
            id={id}
            quantity={quantity}
            category={category}
            invoiceNumber={invoiceNumber}
            displayName={displayName}
            size={size}
            SKU={SKU}
            colour={colour}
            preOrder={preOrder}
            userId={userId}
            userEmail={userEmail}
          />
        </td>
        <td className="v-center">
          {points ? (
            <div style={{fontSize: '.75rem'}}>
              <FontAwesomeIcon className="mr-1" iconType="product-hunt"/>
              {Number(points)}
            </div>
          ): null}
          <TextRupiah bold prefix="Rp. " value={amount}/>
          {
            amount !== adjustedAmount?
            <div style={{fontSize: '.75rem'}}>
              <div>Pembayaran: </div>
              <TextRupiah prefix="Rp. " value={adjustedAmount}/>
            </div>
            :null
          }
          {
            consignmentId && (
              <div style={{fontSize: '.65rem'}}>
                <i className="fa fa-barcode mr-1"></i>
                {consignmentId}
              </div>
            )
          }
          {
            rack && (
              <div className="text-uppercase" style={{fontSize: '.65rem'}}>
                RACK: {rack}
              </div>
            )
          }
        </td>
        <td className="v-center">
          <div>
            {
              bank && paymentMethod === 'virtual_account'?
              <span style={{fontSize: '.75rem'}}>{bank+ '-'+ getPaymentType(paymentMethod)}</span>
              : 
              getPaymentType(paymentMethod)
            }
          </div>
          { via !== null? <div><span style={{fontSize: '.75rem'}}>via <i>{via}</i></span></div>: null}
          {
            paymentVia !== null && paymentVia === 'vt' && paymentMethod=== "credit_card" ? 
            <button className="btn btn-sm btn-secondary" onClick={this.props.onOpenCreditCard} target="_blank" title="Show More Info"><i className="fa fa-info-circle"></i> More Info</button>
            :
            null
          }
        </td>
        <td className="v-center"><DateToHuman  value={updatedAt} id={`tooltip_${rowId}`}/></td>
        <td className="v-center">
          {
            this.props.paymentStatus==='REFUNDED'?
            <Link to={`/payments/${id}`} className="btn btn-secondary btn-sm"><i className="fa fa-external-link"></i></Link>
            :
            <ActionButtonsColumn
              id={id}
              isSelected={isSelected}
              onOpenModalClick={onOpenModalClick}
            />
          }
        </td>
      </tr>

    )
  }
}

export default RowItem;

RowItem.defaultProps = {
  amount: 0,
  paymentMethod: 'bank_transfer',
  paymentStatus: 'PENDING',
  isSelected: false
}

RowItem.propTypes={
  rowId: propTypes.number.isRequired,
  id: propTypes.number.isRequired,
  dataItem: propTypes.object.isRequired,
  amount: propTypes.oneOfType([propTypes.number,propTypes.string]),
  adjustedAmount: propTypes.oneOfType([propTypes.number,propTypes.string]),
  updatedAt: propTypes.string,
  paymentMethod: propTypes.string,
  paymentStatus: propTypes.string,
  invoiceNumber: propTypes.string,
  size: propTypes.string,
  displayName: propTypes.string,
  SKU: propTypes.string,
  colour: propTypes.string,
  isSelected: propTypes.bool,
  onOpenModalClick: propTypes.func,
  preOrder: propTypes.oneOfType([propTypes.number,propTypes.bool]),
}
