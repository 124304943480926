import { createStore, combineReducers, applyMiddleware } from "redux";
import authReducer from "../reducers/authReducer";
import sellRequestReducer from "../reducers/sellRequestReducer";
import sellerVerificationReducer from "../reducers/sellerVerificationReducer";
import offerReducer from "../reducers/offerReducer";
import collectionReducer from "../reducers/collectionReducer";
import brandReducer from "../reducers/brandReducer";
import categoriesReducer from "../reducers/categoriesReducer";
import imageUploadReducer from "../reducers/imageUploadReducer";
import productReducer from "../reducers/productReducer";
import productVariantReducer from "../reducers/productVariantReducer";
import saleReducer from "../reducers/saleReducer";
import consignmentReducer from "../reducers/consignmentReducer";
import sizeReducer from "../reducers/sizeReducer";
import userReducer from "../reducers/userReducer";
import accountBankReducer from "../reducers/accountBankReducer";
import roleReducer from "../reducers/roleReducer";
import bulkUploadReducer from "../reducers/bulkUploadReducer";
import bulkUploadStockReducer from "../reducers/bulkUploadStockReducer";
import voucherReducer from "../reducers/voucherReducer";
import userBankAccountReducer from "../reducers/userBankAccountReducer";
import sliderReducer from "../reducers/sliderReducer";
import topUpReducer from "../reducers/topUpReducer";
import rewardReducer from "../reducers/rewardReducer";
import disbursementUserReducer from "../reducers/disbursementUserReducer";
import productStockReducer from "../reducers/productStockReducer";
import bidReducer from "../reducers/bidReducer";
import menuReducer from "../reducers/menuReducer";
import midtransReducer from "../reducers/midtransReducer";
import seekStockReducer from "../reducers/seekStockReducer";
import skuRequestReducer from "../reducers/skuRequestReducer";
import appSectionReducer from "../reducers/appSectionReducer";
import rackReducer from "../reducers/rackReducer";
import raffleReducer from "../reducers/raffleReducer";
import userShippingReducer from "../reducers/userShippingReducer";
import settingReducer from "../reducers/settingReducer";
import pointShopReducer from "../reducers/pointShopReducer";
import pointiersReducer from "../reducers/pointiersReducer";
import marketingBudgetReducer from "../reducers/marketingBudgetReducer";
import productAddonReducer from "../reducers/productAddonReducer";
import conciergeReducer from "../reducers/conciergeReducer";
import spinningWheelReducer from "../reducers/spinningWheelReducer";
import popUpBannerReducer from "../reducers/popUpBannerReducer";
import timerReducer from "../reducers/timerReducer";
import faqReducer from "../reducers/faqReducer";
import surveyReducer from "../reducers/surveyReducer";
import optionReducer from "../reducers/optionReducer";
import countryReducer from "../reducers/countryReducer";
import pushNotificationReducer from "../reducers/pushNotificationReducer";
import articleReducer from "../reducers/articleReducer";
import metaDataReducer from "../reducers/metaDataReducer";
import auditReducer from "../reducers/auditReducer";
import bankPromoReducer from "../reducers/bankPromoReducer";
import bulkUploadAWBReducer from "../reducers/bulkUploadAWBReducer";
import logger from "redux-logger";
import thunk from "redux-thunk";

let middlewares = [thunk];

if (process.env.REACT_APP_REDUX_LOGGER_ENABLED == "true") {
    middlewares = [...middlewares, logger];
}

const store = createStore(
    combineReducers({
        auth: authReducer,
        sellRequest: sellRequestReducer,
        sellerVerification: sellerVerificationReducer,
        offer: offerReducer,
        masterCollection: collectionReducer,
        masterBrand: brandReducer,
        masterCategories: categoriesReducer,
        imageUpload: imageUploadReducer,
        product: productReducer,
        productVariant: productVariantReducer,
        sale: saleReducer,
        consignment: consignmentReducer,
        size: sizeReducer,
        user: userReducer,
        userBankAccount: userBankAccountReducer,
        accountBank: accountBankReducer,
        role: roleReducer,
        bulkUpload: bulkUploadReducer,
        bulkUploadStock: bulkUploadStockReducer,
        bulkUploadAWB: bulkUploadAWBReducer,
        voucher: voucherReducer,
        reward: rewardReducer,
        slider: sliderReducer,
        topUp: topUpReducer,
        disbursementUser: disbursementUserReducer,
        productStock: productStockReducer,
        bid: bidReducer,
        menu: menuReducer,
        midtrans: midtransReducer,
        seekStock: seekStockReducer,
        skuRequest: skuRequestReducer,
        appSection: appSectionReducer,
        rack: rackReducer,
        raffle: raffleReducer,
        userShipping: userShippingReducer,
        setting: settingReducer,
        pointShop: pointShopReducer,
        pointiers: pointiersReducer,
        marketingBudget: marketingBudgetReducer,
        productAddon: productAddonReducer,
        concierge: conciergeReducer,
        spinningWheel: spinningWheelReducer,
        popUpBanner: popUpBannerReducer,
        faq: faqReducer,
        timer: timerReducer,
        survey: surveyReducer,
        option: optionReducer,
        country: countryReducer,
		pushNotification: pushNotificationReducer,
        article: articleReducer,
        metaData: metaDataReducer,
        audit: auditReducer,
        bankPromo: bankPromoReducer,
    }),
    applyMiddleware(...middlewares)
);
export default store;
