import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Row, Col, Card, CardBody, CardText } from "reactstrap";
import { getPushNotificationList } from "../../actions/pushNotificationAction";
import {
    CardHeaderWithToolbars,
    Title,
} from "../../components/Card/CardHeaderWithToolbars";
import privateView from "../../components/hocs/privateView";
import { Tbl, TblHead, TblBody } from "../../components/Tbl";
import Paginations from "../../components/Paginations";
import PaginationSummaries from "../../components/Paginations/PaginationSummaries";
import { generateNumber } from "../../utils";
import CheckReadOnly from "../../components/CheckReadOnly";
import { DEFAULT_PER_PAGE, DEFAULT_SORT_BY } from "../../constants/settings";
import moment from "moment";
import FilterPanel from "./Filters";
import FilterContainer from "../../components/Container/FilterContainer/FilterContainer";
import Toolbars from "./Filters/Toolbars";
import DateToHuman from "../../components/DateToHuman";

const dataHeader = [
    "No.",
    "Title",
    "Active",
    "Status",
    "Started",
    "Updated",
    "Action",
];
const defaultParams = {
    category: null,
    sort_by: DEFAULT_SORT_BY,
    per_page: DEFAULT_PER_PAGE,
};

class PushNotification extends Component {
    constructor() {
        super();
        this.state = {
            _params: {
                ...defaultParams,
            },
            _filterOpen: false,
        };

        this._handleToggleFilterPanel =
            this._handleToggleFilterPanel.bind(this);
        this._handleParamsChange = this._handleParamsChange.bind(this);
        this._handleParamsChangeAndSubmit =
            this._handleParamsChangeAndSubmit.bind(this);
        this._handleGoto = this._handleGoto.bind(this);
        this._handleRefresh = this._handleRefresh.bind(this);
        this._handleResetFilter = this._handleResetFilter.bind(this);
    }

    componentDidMount() {
        this._getList(this.state._params);
    }

    _toggleFilterPanel() {
        this.setState({ _filterOpen: !this.state._filterOpen });
    }

    _handleToggleFilterPanel(e) {
        e.preventDefault();
        this._toggleFilterPanel();
    }

    _getList(rawParams = {}) {
        let params = {};
        Object.keys(rawParams)
            .filter((key) => rawParams[key] !== "")
            .forEach((key) => (params[key] = rawParams[key]));
        this.props.getList({ params });
    }

    _handleGoto(page) {
        const _params = this.state._params;
        _params.page = page;
        this.setState({ _params }, () => this._getList(this.state._params));
    }

    _refreshList() {
        const { page, ..._params } = this.state._params;
        this.setState(
            {
                _params,
            },
            () => this._getList(this.state._params)
        );
    }

    _handleParamsChange(e) {
        const { _params } = this.state;
        _params[e.target.name] = e.target.value;
        this.setState({ _params });
    }

    _handleParamsChangeAndSubmit(e) {
        // Exclude page...
        const { page, ..._params } = this.state._params;
        _params[e.target.name] = e.target.value;
        this.setState({ _params }, () => {
            this._getList(this.state._params);
        });
    }

    _handleDisplayChange(e) {
        const { _params } = this.state;
        _params[e.target.name] = e.target.value;
        _params.page = 1;
        this.setState({ _params }, () => {
            this._getList(this.state._params);
        });
    }

    _handleRefresh(e) {
        e.preventDefault();
        this._refreshList();
    }

    _handleResetFilter(e) {
        e.preventDefault();
        const newState = this.state._filterOpen
            ? { _filterOpen: !this.state._filterOpen }
            : {};
        this.setState(
            {
                ...newState,
                _params: {
                    ...defaultParams,
                },
            },
            () => this._getList(this.state._params)
        );
    }

    _onRenderRowItem() {
        if (
            this.props.pushNotification.list.status_code === 200 &&
            this.props.pushNotification.list.data.length > 0
        ) {
            const { current_page, per_page } = this.props.pushNotification.list;
            return this.props.pushNotification.list.data.map((item, index) => (
                <tr key={generateNumber(per_page, current_page, index)}>
                    <th scope="row">
                        {generateNumber(per_page, current_page, index)}
                    </th>
                    <td>{item.title}</td>
                    <td>
                        <CheckReadOnly check={item.active} />
                    </td>
                    <td>{item.status}</td>
                    <td>
                        {moment(item.started_at || item.created_at).format("DD-MM-YYYY HH:mm:ss")}
                    </td>
                    <td>
                        <DateToHuman
                            value={item.updated_at}
                            id={`tooltip_updatedAt_${generateNumber(
                                per_page,
                                current_page,
                                index
                            )}`}
                        />
                    </td>
                    <td>
                        <Link
                            to={`/push_notification/${item.id}`}
                            className="btn btn-link"
                        >
                            Details
                        </Link>
                    </td>
                </tr>
            ));
        }
        return null;
    }

    _onRenderPagination() {
        const { status_code, list } = this.props.pushNotification;
        const hasData = (status_code === 200 && list.data.length) || null;
        return (
            hasData && (
                <Paginations
                    size="sm"
                    lastPage={this.props.pushNotification.list.last_page}
                    perPage={this.props.pushNotification.list.per_page}
                    total={this.props.pushNotification.list.total}
                    maxPage={8}
                    path={this.props.pushNotification.list.path}
                    currentPage={this.props.pushNotification.list.current_page}
                    prevPageUrl={this.props.pushNotification.list.prev_page_url}
                    nextPageUrl={this.props.pushNotification.list.next_page_url}
                    handleGotoCallback={this._handleGoto}
                />
            )
        );
    }

    render() {
        return (
            <div className="animated fadeIn">
                <Row>
                    <Col xs={12}>
                        <Card>
                            <CardHeaderWithToolbars>
                                <Title>
                                    <i className="fa fa-align-justify"></i>
                                    <span>Push Notification List</span>
                                </Title>
                                <Toolbars
                                    isOpen={this.state._filterOpen}
                                    onToggleFilter={
                                        this._handleToggleFilterPanel
                                    }
                                    onRefreshList={this._handleRefresh}
                                    onResetFilter={this._handleResetFilter}
                                />
                            </CardHeaderWithToolbars>
                            <FilterContainer>
                                <FilterPanel
                                    {...this.state._params}
                                    onHandleChange={this._handleParamsChange}
                                    onHandleChangeAndSubmit={
                                        this._handleParamsChangeAndSubmit
                                    }
                                    onHandleSearch={this._handleRefresh}
                                    isOpen={this.state._filterOpen}
                                />
                            </FilterContainer>
                            <CardBody>
                                <CardText>
                                    In this section will display Push
                                    Notification list.
                                </CardText>
                                <div className="table-responsive">
                                    <PaginationSummaries
                                        from={
                                            this.props.pushNotification.list
                                                .from
                                        }
                                        to={this.props.pushNotification.list.to}
                                        total={
                                            this.props.pushNotification.list
                                                .total
                                        }
                                    />
                                    <Tbl>
                                        <TblHead dataHeader={dataHeader} />
                                        <TblBody
                                            isLoad={
                                                this.props.pushNotification
                                                    .isFetch
                                            }
                                            hasRow={
                                                this.props.pushNotification.list
                                                    .status_code === 200 &&
                                                this.props.pushNotification.list
                                                    .data.length > 0
                                            }
                                            columnCount={dataHeader.length}
                                        >
                                            {this._onRenderRowItem()}
                                        </TblBody>
                                    </Tbl>
                                </div>
                                {this._onRenderPagination()}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        );
    }
}

const mapStateToProps = ({
    pushNotification,
    auth: { isFetch, roles, isLogged, id, email },
}) => {
    return {
        pushNotification,
        guardData: { isFetch, roles, isLogged, id, email },
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getList: (query) => dispatch(getPushNotificationList(query)),
    };
};
const enhance = connect(mapStateToProps, mapDispatchToProps);

export default enhance(privateView(PushNotification));
