import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { 
    Alert,
    Form,
    Label,
    Col, 
    Row,
    Input,
    FormGroup,
    ButtonGroup,
    Button,
    Card,
    CardBody,
    CardHeader,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter
} from 'reactstrap';

import { 
    getDetailCategories,
    saveCategories,
    deleteCategories,
    newCategories 
} from '../../actions/categoriesAction'

import { ValidationMessage } from '../../components/Form/ValidationMessage';


class CategoriesForm extends Component{
    constructor(props){
        super(props);
        console.log(this.props.edit);
        this.state={
            _edit:this.props.edit,
            _readOnly:this.props.readOnly,
            _hasId:this.props.match.params.id?true:false,
            _form:{
                binding:false,
                id:this.props.match.params.id,
                name:'',
                created_at:'',
                updated_at:''
            },
            _formSubmit:false,
            _modalDelete:false
        }
    }

    componentWillMount()
    {
        if(this.state._hasId)
            this.props.find(this.state._form.id);
        else
            this.props.new();
    }

    componentWillUpdate(nextProps)
    {
        const {detail,isFetch,isSubmit,error} = nextProps.masterCategories;
        // Edit Form
        if(this.state._hasId)
        {
            // Checking if already ok after get detail from api
            if(detail.status_code===200&&error===null)
            {
                // Binding data when load page and after get detail from api
                if(!isFetch&&!this.state._form.binding)
                {                    
                    const _form  ={ ...this.state._form };
                    _form.binding=true;
                    this.setState({_form},()=>{
                        const _newForm  ={ ...this.state._form,...detail };
                        this.setState({_form:_newForm});
                    })
                }
                // Binding data again when after success update in api
                else if(!isSubmit&&this.state._formSubmit)
                {
                    const _form  ={ ...this.state._form };
                    _form.binding=true;
                    this.setState({_formSubmit:!this.state._formSubmit},()=>{
                        const _newForm  ={ ...this.state._form,...detail };
                        this.setState({_form:_newForm});
                    })
                }
            }
        }
        // Create Form
        else{
            // Checking if already ok after submit 
            if(detail.status_code===200 
                &&error===null
                &&this.state._formSubmit
                && !isSubmit)
            {
                this.setState({_form:{name:'', created_at:'', updated_at:''},_formSubmit:!this.state._formSubmit});
            }
        }
    }

    /**
	 * Handle to change local state from input uset like textfield
	 * @author pewe
	 * @param {event} e 
	 */
	handleChange(e){
		const newState={...this.state._form};
        newState[e.target.name]=e.target.value;
		this.setState({_form:newState});
    }
	handleCheckedChange(e){
		const newState={...this.state._form};
        newState[e.target.name]=e.target.checked;
		this.setState({_form:newState});
    }
    onSubmit(e){
        e.preventDefault();
        this.setState({_formSubmit:true},()=>{
            const {id,name,active}= this.state._form;
            this.props.save({id,name,active});
        })
    }
    renderLoading(){
        return (
			<div className="row">
				<div className="col-sm-12">
                    <Alert color='info' className="text-center">Getting all data...</Alert>
				</div>
			</div>
		)
    }
    renderInfo(){
        let infoMessage=[];
        if(this.props.masterCategories.isSubmit)
            infoMessage.push(
                <Alert color='info' key="info"><strong>Heads up!</strong> Submitting...</Alert>
            );
        if(this.props.masterCategories.success)
            infoMessage.push(
                <Alert color='success' key="success"><strong>Well done!</strong> { this.props.masterCategories.success }</Alert>
            );
        if(this.props.masterCategories.error!==null)
        {
            const {status_code,message} = this.props.masterCategories.error;
            switch(status_code)
            {
                case 422:
                    infoMessage.push(
                        <Alert color='danger' key="error"><strong>Oh Snap!</strong> Please fullfill your form. </Alert>
                    );
                    break;
                case 404:
                    infoMessage.push(
                        <Alert color='danger' key="error"><strong>Oh Snap!</strong> Resource not found. </Alert>
                    );
                    break;
                default:
                    infoMessage.push(
                        <Alert color='danger' key="error"><strong>Oh Snap!</strong> We've got something errors </Alert>
                    );
                    break;
            }
        }
        return(
            <div className="row">
                <div className="col-sm-12">
                    {infoMessage}   
                </div>
            </div>
        )
    }

    validateError(name){
      if(this.hasError(name))
      {
        let errorList=this.props.masterCategories.error.errors;
        return <ValidationMessage message={errorList[name][0]}/>
      }
      return '';
    }
  
    hasError(name){
      if(this.props.masterCategories.error)
      {
        if(this.props.masterCategories.error.errors)
        {
            let errorList=this.props.masterCategories.error.errors;
            let errList=Object.keys(errorList).filter(key=> key==name);
            if(errList.length)
              return true
        }
      }
      return false
    }
    
    toggle(){
        this.setState({_modalDelete:!this.state._modalDelete});
    }

    onDelete(){
        this.props.delete(this.state._form.id);
        this.toggle();
    }

    render(){
        if(this.props.masterCategories.detail.delete===true)
            return <Redirect to='/categories'/>
        return(
            <Row>
                <Modal isOpen={this.state._modalDelete} toggle={this.toggle.bind(this)} className="modal-dialog modal-sm">
                    <ModalHeader toggle={this.toggle.bind(this)}>Confirmation</ModalHeader>
                    <ModalBody>
                        Are you sure to delete this?
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.onDelete.bind(this)}>Yes</Button>{' '}
                        <Button color="secondary" onClick={this.toggle.bind(this)}>Cancel</Button>
                    </ModalFooter>
                </Modal>
                <div className="col-md-6 offset-md-3">
                    <Card>
                        <CardHeader>
                            <i className="fa fa-align-justify"></i> {this.props.title}
                        </CardHeader>
                        <CardBody>
                            { this.renderInfo() }
                            {
                                this.props.masterCategories.isFetch?
                                    this.renderLoading()
                                :
                                <Form onSubmit={this.onSubmit.bind(this)}>
                                    <FormGroup className={this.hasError('name')?' has-danger has-feedback':''}>
                                        <Label for="formCategoriesName">Categories Name</Label>
                                        <Input
                                            type="text"
                                            id="formCategoriesName"
                                            name="name"
                                            placeholder="Enter a name*"
                                            value={this.state._form.name}
                                            onChange={this.handleChange.bind(this)}
                                        />
                                        {this.validateError('name')}
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>Created At</Label>
                                        <Input
                                            type="text"
                                            readOnly
                                            value={this.state._form.created_at}
                                        />                                    
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>Updated At</Label>
                                        <Input
                                            type="text"
                                            readOnly
                                            value={this.state._form.updated_at}
                                        />                                    
                                    </FormGroup>
                                    <FormGroup>
                                        <ButtonGroup>
                                            <Button  color="primary">Submit</Button>
                                            {/* {
                                                this.state._hasId?
                                                <Button  color="danger" onClick={this.toggle.bind(this)}>Delete</Button>
                                                :
                                                ''
                                            } */}
                                        </ButtonGroup>
                                    </FormGroup>
                                </Form>
                            }
                        </CardBody>
                    </Card>
                </div>
            </Row>
        )
    }
}
const mapStateToProps= (state) => {
	return {
		masterCategories:state.masterCategories
	}
};
const mapDispatchToProps = (dispatch) => {
	return{
		find:(id) => dispatch(getDetailCategories(id)),
		delete:(id) => dispatch(deleteCategories(id)),
		save:(payload) => dispatch(saveCategories(payload)),
		new:() => dispatch(newCategories())
	}

};
export default connect(mapStateToProps,mapDispatchToProps)(CategoriesForm);