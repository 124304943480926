import {
  AUTH_TOKEN_KEY,
  ARTICLE_ROUTE_API,
  ARTICLE_ADD,
  ARTICLE_DELETE,
  ARTICLE_DETAIL_FETCH,
  ARTICLE_ERROR,
  ARTICLE_FETCHING,
  ARTICLE_LIST_FETCH,
  ARTICLE_UPDATE,
  ARTICLE_CATEGORY_ADD,
  ARTICLE_CATEGORY_DELETE,
  ARTICLE_CATEGORY_ERROR,
  ARTICLE_CATEGORY_FETCHING,
  ARTICLE_CATEGORY_LIST_FETCH,
  ARTICLE_CATEGORY_UPDATE,
  ARTICLE_CATEGORY_DETAIL_FETCH,
  ADD_IMAGE_ARTICLE,
  DELETE_IMAGE_ARTICLE,
  GET_LIST_ARTICLE_VARIANTS,
  SEARCH_ARTICLE_VARIANTS,
  ARTICLE_CHILDS_REQUEST,
  ARTICLE_CHILDS_ERROR,
  SUBMITING_ARTICLE_VARIANTS,
  UPDATE_IMAGE_ARTICLE,
  ARTICLE_META_LIST_FETCH,
  ARTICLE_META_FETCHING,
  ARTICLE_META_ERROR,
  ARTICLE_META_DETAIL_FETCH,
  ARTICLE_META_ADD,
  ARTICLE_META_UPDATE,
  ARTICLE_META_DELETE,
} from "../constants"
import api from "../utils/Api"
import { manipulateErrors, checkAuthorized } from "../utils"
import { logout } from "./authAction"
import { onFailedRequest, onRequest } from "./helpers"

/**
 * Indicates when start fetch api or end fetch api
 * @author haikal
 * @param {boolean} bool
 */
export const isFetch = (payload) => ({
  type: ARTICLE_FETCHING,
  payload: payload,
})

/**
 * Indicates when start fetch api or end fetch api
 * @author haikal
 * @param {boolean} bool
 */
export const isFetchCategory = (payload) => ({
  type: ARTICLE_CATEGORY_FETCHING,
  payload: payload,
})

/**
 * Indicates when start fetch api or end fetch api
 * @author haikal
 * @param {boolean} bool
 */
export const isFetchMeta = (payload) => ({
  type: ARTICLE_META_FETCHING,
  payload: payload,
})

/**
 * When onSuccess triggered
 * @author haikal
 * @param {object} res
 * @param {string} type
 */
export const onSuccess = (res, type) => ({
  type: type,
  payload: res,
})
/**
 * When error fetch or connect to api
 * @author haikal
 * @param {object} err
 */
// On Error fetching api
export const onError = (err) => {
  if (!checkAuthorized(manipulateErrors(err))) return logout()
  return {
    type: ARTICLE_ERROR,
    payload: manipulateErrors(err),
  }
}

/**
 * When error fetch or connect to api
 * @author haikal
 * @param {object} err
 */
// On Error fetching api
export const onErrorCategory = (err) => {
  if (!checkAuthorized(manipulateErrors(err))) return logout()
  return {
    type: ARTICLE_CATEGORY_ERROR,
    payload: manipulateErrors(err),
  }
}

/**
 * When error fetch or connect to api
 * @author haikal
 * @param {object} err
 */
// On Error fetching api
export const onErrorMeta = (err) => {
  if (!checkAuthorized(manipulateErrors(err))) return logout()
  return {
    type: ARTICLE_META_ERROR,
    payload: manipulateErrors(err),
  }
}

export const getArticlelList = (query) => {
  return (dispatch) => (
    dispatch(isFetch({ type: "fetch", status: true })),
    api({
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`,
    })
      .get(ARTICLE_ROUTE_API, query)
      .then((res) => {
        dispatch(onSuccess(res.data, ARTICLE_LIST_FETCH))
        dispatch(isFetch({ type: "fetch", status: false }))
      })
      .catch((err) => {
        dispatch(onError(err))
        dispatch(isFetch({ type: "fetch", status: false }))
      })
  )
}

export const getDetailArticle = (id) => {
  return (dispatch) => (
    dispatch(isFetch({ type: "fetch", status: true })),
    api({
      Authorization: "Bearer " + localStorage.getItem(AUTH_TOKEN_KEY),
    })
      .get(`${ARTICLE_ROUTE_API}/${id}`)
      .then((res) => {
        dispatch(onSuccess(res.data, ARTICLE_DETAIL_FETCH))
        dispatch(isFetch({ type: "fetch", status: false }))
      })
      .catch((err) => {
        dispatch(onError(err))
        dispatch(isFetch({ type: "fetch", status: false }))
      })
  )
}

export const saveArticle = (payload, id) => {
  return (dispatch) => {
    if (id === undefined) dispatch(createArticle(payload))
    else dispatch(updateArticle(id, payload))
  }
}

export const addImage = (identifier, payload) => {
  return (dispatch) => (
    dispatch(isFetch({ type: "submit_image", status: true })),
    api({
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`,
    })
      .post(`${ARTICLE_ROUTE_API}/images`, payload)
      .then((res) => {
        dispatch(onSuccess({ ...res.data, identifier }, ADD_IMAGE_ARTICLE))
        dispatch(isFetch({ type: "submit_image", status: false }))
      })
      .catch((err) => {
        dispatch(onError(err))
        dispatch(isFetch({ type: "submit_image", status: false }))
      })
  )
}

export const deleteImage = (identifier, id) => {
  return (dispatch) => (
    dispatch(isFetch({ type: "delete_image", status: true })),
    api({
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`,
    })
      .delete(`${ARTICLE_ROUTE_API}/images/${id}`)
      .then((res) => {
        dispatch(onSuccess({ identifier }, DELETE_IMAGE_ARTICLE))
        dispatch(isFetch({ type: "delete_image", status: false }))
      })
      .catch((err) => {
        dispatch(onError(err))
        dispatch(isFetch({ type: "delete_image", status: false }))
      })
  )
}

export const updateImage = (identifier, id, payload) => {
  return (dispatch) => (
    dispatch(isFetch({ type: "submit_image", status: true })),
    api({
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`,
    })
      .put(`${ARTICLE_ROUTE_API}/images/${id}`, payload)
      .then((res) => {
        dispatch(onSuccess({ identifier }, UPDATE_IMAGE_ARTICLE))
        dispatch(isFetch({ type: "submit_image", status: false }))
      })
      .catch((err) => {
        dispatch(onError(err))
        dispatch(isFetch({ type: "submit_image", status: false }))
      })
  )
}

export const createArticle = (payload) => {
  return (dispatch) => (
    dispatch(isFetch({ type: "submit", status: true })),
    api({
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`,
    })
      .post(`${ARTICLE_ROUTE_API}`, payload)
      .then((res) => {
        dispatch(onSuccess(res.data, ARTICLE_ADD))
        dispatch(isFetch({ type: "submit", status: false }))
      })
      .catch((err) => {
        dispatch(onError(err))
        dispatch(isFetch({ type: "submit", status: false }))
      })
  )
}

export const updateArticle = (id, payload) => {
  return (dispatch) => (
    dispatch(isFetch({ type: "submit", status: true })),
    api({
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`,
    })
      .put(`${ARTICLE_ROUTE_API}/${id}`, payload)
      .then((res) => {
        dispatch(onSuccess(res.data, ARTICLE_UPDATE))
        dispatch(isFetch({ type: "submit", status: false }))
      })
      .catch((err) => {
        dispatch(onError(err))
        dispatch(isFetch({ type: "submit", status: false }))
      })
  )
}

export const deleteArticle = (id) => {
  return (dispatch) => (
    dispatch(isFetch({ type: "submit", status: true })),
    api({
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`,
    })
      .delete(`${ARTICLE_ROUTE_API}/${id}`)
      .then((res) => {
        dispatch(onSuccess(res.data, ARTICLE_DELETE))
        dispatch(isFetch({ type: "submit", status: false }))
      })
      .catch((err) => {
        dispatch(onError(err))
        dispatch(isFetch({ type: "submit", status: false }))
      })
  )
}

export const getArticleCategorylList = (query) => {
  return (dispatch) => (
    dispatch(isFetchCategory({ type: "fetch", status: true })),
    api({
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`,
    })
      .get(`${ARTICLE_ROUTE_API}/categories`, query)
      .then((res) => {
        dispatch(onSuccess(res.data, ARTICLE_CATEGORY_LIST_FETCH))
        dispatch(isFetchCategory({ type: "fetch", status: false }))
      })
      .catch((err) => {
        dispatch(onErrorCategory(err))
        dispatch(isFetchCategory({ type: "fetch", status: false }))
      })
  )
}

export const getDetailArticleCategory = (id) => {
  return (dispatch) => (
    dispatch(isFetchCategory({ type: "detail", status: true })),
    api({
      Authorization: "Bearer " + localStorage.getItem(AUTH_TOKEN_KEY),
    })
      .get(`${ARTICLE_ROUTE_API}/categories/${id}`)
      .then((res) => {
        dispatch(onSuccess(res.data, ARTICLE_CATEGORY_DETAIL_FETCH))
        dispatch(isFetchCategory({ type: "detail", status: false }))
      })
      .catch((err) => {
        dispatch(onErrorCategory(err))
        dispatch(isFetchCategory({ type: "detail", status: false }))
      })
  )
}

export const createArticleCategory = (payload) => {
  return (dispatch) => (
    dispatch(isFetchCategory({ type: "submit", status: true })),
    api({
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`,
    })
      .post(`${ARTICLE_ROUTE_API}/categories`, payload)
      .then((res) => {
        dispatch(onSuccess(res.data, ARTICLE_CATEGORY_ADD))
        dispatch(isFetchCategory({ type: "submit", status: false }))
      })
      .catch((err) => {
        dispatch(onErrorCategory(err))
        dispatch(isFetchCategory({ type: "submit", status: false }))
      })
  )
}

export const updateArticleCategory = (id, payload) => {
  return (dispatch) => (
    dispatch(isFetchCategory({ type: "detail", status: true })),
    api({
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`,
    })
      .put(`${ARTICLE_ROUTE_API}/categories/${id}`, payload)
      .then((res) => {
        dispatch(onSuccess(res.data, ARTICLE_CATEGORY_UPDATE))
        dispatch(isFetchCategory({ type: "detail", status: false }))
      })
      .catch((err) => {
        dispatch(onErrorCategory(err))
        dispatch(isFetchCategory({ type: "detail", status: false }))
      })
  )
}

export const deleteArticleCategory = (id) => {
  return (dispatch) => (
    dispatch(isFetchCategory({ type: "detail", status: true })),
    api({
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`,
    })
      .delete(`${ARTICLE_ROUTE_API}/categories/${id}`)
      .then((res) => {
        dispatch(onSuccess(res.data, ARTICLE_CATEGORY_DELETE))
        dispatch(isFetchCategory({ type: "detail", status: false }))
      })
      .catch((err) => {
        dispatch(onErrorCategory(err))
        dispatch(isFetchCategory({ type: "detail", status: false }))
      })
  )
}

export const getArticleVariants = (id, query) => (dispatch) => {
  let actionType = GET_LIST_ARTICLE_VARIANTS
  if (query.params.exclude === true) {
    actionType = SEARCH_ARTICLE_VARIANTS
  }
  dispatch(onRequest(ARTICLE_CHILDS_REQUEST, actionType, true))
  api({
    Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`,
  })
    .get(`${ARTICLE_ROUTE_API}/${id}/product_variants`, query)
    .then((res) => {
      dispatch(onSuccess(res.data, actionType))
      dispatch(onRequest(ARTICLE_CHILDS_REQUEST, actionType, false))
    })
    .catch((err) => {
      dispatch(
        onFailedRequest(ARTICLE_CHILDS_ERROR, actionType, err, { id, query })
      )
      dispatch(onRequest(ARTICLE_CHILDS_REQUEST, actionType, false))
    })
}

export const saveArticleVariants =
  (operation, id, payload) => (dispatch) => {
    dispatch(
      onRequest(ARTICLE_CHILDS_REQUEST, SUBMITING_ARTICLE_VARIANTS, true)
    )
    let request = null
    if (operation === "attach") {
      request = api({
        Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`,
      }).post(`${ARTICLE_ROUTE_API}/${id}/product_variants`, payload)
    } else {
      //detach
      request = api({
        Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`,
      }).put(`${ARTICLE_ROUTE_API}/${id}/product_variants`, payload)
    }
    request
      .then((res) => {
        dispatch(onSuccess(res.data, SUBMITING_ARTICLE_VARIANTS))
        dispatch(
          onRequest(
            ARTICLE_CHILDS_REQUEST,
            SUBMITING_ARTICLE_VARIANTS,
            false
          )
        )
      })
      .catch((err) => {
        dispatch(
          onFailedRequest(
            ARTICLE_CHILDS_ERROR,
            SUBMITING_ARTICLE_VARIANTS,
            err,
            id
          )
        )
        dispatch(
          onRequest(
            ARTICLE_CHILDS_REQUEST,
            SUBMITING_ARTICLE_VARIANTS,
            false
          )
        )
      })
  }

export const updatePosition = (id, payload) => {
  return () => (
    api({
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`,
    })
      .put(`${ARTICLE_ROUTE_API}/${id}/product_variants/position`, payload)
      .then((res) => {
        console.log(res)
      })
      .catch((err) => {
        console.log(err)
      })
  )
}

export const getArticleMetalList = (article_id, query) => {
  return (dispatch) => (
    dispatch(isFetchMeta({ type: "fetch", status: true })),
    api({
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`,
    })
      .get(`${ARTICLE_ROUTE_API}/${article_id}/meta`, query)
      .then((res) => {
        dispatch(onSuccess(res.data, ARTICLE_META_LIST_FETCH))
        dispatch(isFetchMeta({ type: "fetch", status: false }))
      })
      .catch((err) => {
        dispatch(onErrorMeta(err))
        dispatch(isFetchMeta({ type: "fetch", status: false }))
      })
  )
}

export const getDetailArticleMeta = (article_id, meta_id) => {
  return (dispatch) => (
    dispatch(isFetchMeta({ type: "detail", status: true })),
    api({
      Authorization: "Bearer " + localStorage.getItem(AUTH_TOKEN_KEY),
    })
      .get(`${ARTICLE_ROUTE_API}/${article_id}/meta/${meta_id}`)
      .then((res) => {
        dispatch(onSuccess(res.data, ARTICLE_META_DETAIL_FETCH))
        dispatch(isFetchMeta({ type: "detail", status: false }))
      })
      .catch((err) => {
        dispatch(onErrorMeta(err))
        dispatch(isFetchMeta({ type: "detail", status: false }))
      })
  )
}

export const createArticleMeta = (article_id, payload) => {
  return (dispatch) => (
    dispatch(isFetchMeta({ type: "submit", status: true })),
    api({
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`,
    })
      .post(`${ARTICLE_ROUTE_API}/${article_id}/meta`, payload)
      .then((res) => {
        dispatch(onSuccess(res.data, ARTICLE_META_ADD))
        dispatch(isFetchMeta({ type: "submit", status: false }))
      })
      .catch((err) => {
        dispatch(onErrorMeta(err))
        dispatch(isFetchMeta({ type: "submit", status: false }))
      })
  )
}

export const updateArticleMeta = (article_id, payload) => {
  return (dispatch) => (
    dispatch(isFetchMeta({ type: "detail", status: true })),
    api({
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`,
    })
      .put(`${ARTICLE_ROUTE_API}/${article_id}/meta`, payload)
      .then((res) => {
        dispatch(onSuccess(res.data, ARTICLE_META_UPDATE))
        dispatch(isFetchMeta({ type: "detail", status: false }))
      })
      .catch((err) => {
        dispatch(onErrorMeta(err))
        dispatch(isFetchMeta({ type: "detail", status: false }))
      })
  )
}

export const deleteArticleMeta = (article_id, meta_id) => {
  return (dispatch) => (
    dispatch(isFetchMeta({ type: "detail", status: true })),
    api({
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`,
    })
      .delete(`${ARTICLE_ROUTE_API}/${article_id}/meta/${meta_id}`)
      .then((res) => {
        dispatch(onSuccess(res.data, ARTICLE_META_DELETE))
        dispatch(isFetchMeta({ type: "detail", status: false }))
      })
      .catch((err) => {
        dispatch(onErrorMeta(err))
        dispatch(isFetchMeta({ type: "detail", status: false }))
      })
  )
}
