import React, { Component } from 'react';
import { connect } from "react-redux";
import { Container } from 'reactstrap';
import MenuForm from './MenuForm';
import privateView from '../../components/hocs/privateView';
import Analytics from "../../services/Analytics";

class MenuCreate extends Component{
	componentDidMount(){
    const { email } = this.props.injectedProps;
    Analytics.recordPageView('menu_detail', email, {
      url: window.location.href,
      location: this.props.location,
      match: this.props.match
    });
  }
  render(){
    return(
      <Container>
        <MenuForm
          title="Create New Menu"
          {...this.props}
        />
      </Container>
    )
  }
}

const mapStateToProps = ({auth: { isFetch, roles, isLogged, id, email }}) =>({
  guardData: { isFetch, roles, isLogged, id, email }
});

const enhance = connect(mapStateToProps);
export default enhance(privateView(MenuCreate));