import {
  USER_BANK_ACCOUNT_LIST_FETCH,
  USER_BANK_ACCOUNT_LIST_FETCH_ERROR,
  USER_BANK_ACCOUNT_LIST_DONE,
  USER_BANK_ACCOUNT_DETAIL_FETCH,
  USER_BANK_ACCOUNT_DETAIL_FETCH_ERROR,
  USER_BANK_ACCOUNT_DETAIL_SUBMIT,
  USER_BANK_ACCOUNT_DETAIL_SUBMIT_ERROR,
  USER_BANK_ACCOUNT_DETAIL_DONE,
  USER_BANK_ACCOUNT_DETAIL_DELETED,
  USER_BANK_ACCOUNT_DETAIL_UPDATED,
} from '../constants';

const initialState = {
  list:{
    data: [],
    error: null,
    isFetch: false
  },
  bankAccount: {
    data: {},
    deleted: false,
    error: null,
    isFetch: false,
    isSubmit: false
  }
}

const reducer = (state = initialState, { type, payload })=>{
  switch(type){
    case USER_BANK_ACCOUNT_LIST_FETCH:{
      const { list } = state;
      return { ...state, list:{ ...list, isFetch: payload, error: payload? null : list.error  }}
    }
    case USER_BANK_ACCOUNT_LIST_DONE:{
      const { list } = state;
      const { data, ...otherPayload } = payload;
      return { ...state, list:{ ...list, ...data, ...otherPayload }}
    }
    case USER_BANK_ACCOUNT_LIST_FETCH_ERROR:{
      const { list } = state;
      return { ...state, list:{ ...list, ...payload }}
    }
    case USER_BANK_ACCOUNT_DETAIL_FETCH:{
      const { bankAccount } = state;
      return { ...state, bankAccount:{ ...bankAccount, isFetch: payload, error: payload? null : bankAccount.error  }}
    }
    case USER_BANK_ACCOUNT_DETAIL_FETCH_ERROR:{
      const { bankAccount } = state;
      return { ...state, bankAccount:{ ...bankAccount, ...payload }}
    }
    case USER_BANK_ACCOUNT_DETAIL_SUBMIT:{
      const { bankAccount } = state;
      return { ...state, bankAccount:{ ...bankAccount, isSubmit: payload, error: payload? null : bankAccount.error  }}
    }
    case USER_BANK_ACCOUNT_DETAIL_SUBMIT_ERROR:{
      const { bankAccount } = state;
      return { ...state, bankAccount:{ ...bankAccount, ...payload }}
    }
    case USER_BANK_ACCOUNT_DETAIL_DONE:
    case USER_BANK_ACCOUNT_DETAIL_UPDATED:{
      const { bankAccount } = state;
      const { data, ...otherPayload } = payload;
      return { ...state, bankAccount:{ ...bankAccount, data:{ ...data, ...otherPayload} }}
    }
    case USER_BANK_ACCOUNT_DETAIL_DELETED:{
      const { bankAccount } = state;
      return { ...state, bankAccount:{ ...bankAccount, data:{ ...bankAccount.data, ...payload } }}
    }
    default: return state;
  }
}
export default reducer;