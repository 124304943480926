import React, {
    useReducer,
    useCallback,
    useRef,
    useEffect,
    useState,
} from "react";
import Cropper from "react-easy-crop";
import { Modal, ModalFooter, Button, ButtonGroup } from "reactstrap";
import styled from "styled-components";
import { SimpleLineIcon } from "../../components/Icons";
import { getCroppedImg } from "../../utils/imageHelper";

const Close = styled.button`
    position: absolute;
    right: 0.5rem;
    color: #fff;
    background: none;
    border: none;
    font-size: 1.75rem;
    z-index: 11;
    top: 0.5rem;
`;

const CropContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 55px;
    z-index: 10;
`;

const Container = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    cursor: move;
`;

const Controls = styled.div`
    padding: 15px;
    flex: 1;
    display: flex;
    flex-direction: row;
    overflow: auto;
`;

const ControlButton = styled.button`
    padding: 6px 12px;
    background: ${(props) =>
        props.active === true ? "#4a4a4a" : "none" || "none"};
    color: ${(props) =>
        props.active === true ? "#fff" : "#4a4a4a" || "#4a4a4a"};
    border: 1px solid #4a4a4a;
    font-size: 0.75rem;
    margin: 0px 0.5rem;
    box-shadow: none;
`;

const ModalWrapper = styled(Modal)`
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    > .modal-content {
        position: absolute;
        top: 0px;
        bottom: 0px;
        left: 0px;
        right: 0px;
        background: transparent;
        > .modal-footer {
            position: absolute;
            bottom: 0px;
            left: 0px;
            right: 0px;
            padding: 0px;
            height: 55px;
            background: #fff;
        }
    }
`;

const initialState = {
    showGrid: true,
    aspect: 16 / 9,
    zoom: 1,
    crop: { x: 0, y: 0 },
    croppedAreaPixels: null,
    progress: false,
};

const reducer = (state, action) => {
    switch (action.type) {
        case "EASY_CROP_SET_PROGRESS":
            return { ...state, progress: action.payload };
        case "EASY_CROP_RESET":
            return {
                ...state,
                zoom: 1,
                crop: { x: 0, y: 0 },
                ...action.payload,
            };
        case "EASY_CROP_SET_RATIO":
            return {
                ...state,
                zoom: 1,
                crop: { x: 0, y: 0 },
                aspect: action.payload,
            };
        case "EASY_CROP_SET_GRID":
            return {
                ...state,
                zoom: 1,
                crop: { x: 0, y: 0 },
                showGrid: !state.showGrid,
            };
        case "EASY_CROP_SET_ZOOM":
            return { ...state, zoom: action.payload };
        case "EASY_CROP_SET_CROP":
            return { ...state, crop: { ...action.payload } };
        case "EASY_CROP_IMG_LOADED":
            return { ...state, ...action.payload };
        case "EASY_CROP_SET_CROPPED_AREA":
            return { ...state, croppedAreaPixels: { ...action.payload } };
        default:
            return { ...state };
    }
};

const RatioButtons = [
    {
        name: "16:5",
        value: 16 / 5,
    },
    {
        name: "16:9",
        value: 16 / 9,
    },
    {
        name: "128:9",
        value: 1280 / 90,
    },
    {
        name: "11:4",
        value: 11 / 4,
    },
    {
        name: "3:4",
        value: 3 / 4,
    },
    {
        name: "9:16",
        value: 9 / 16,
    },
    {
        name: "9:6",
        value: 9 / 6,
    },
    {
        name: "1:1",
        value: 1 / 1,
    },
    {
        name: "4:3",
        value: 4 / 3,
    },
    {
        name: "375:72",
        value: 375 / 72,
    },
];

const getRatioStr = (value) => {
    let ratioStr = "custom";
    switch (value) {
        case 1 / 1:
            ratioStr = "1:1";
            break;
        case 4 / 3:
            ratioStr = "4:3";
            break;
        case 3 / 4:
            ratioStr = "3:4";
            break;
        case 11 / 4:
            ratioStr = "11:4";
            break;
        case 16 / 9:
            ratioStr = "16:9";
            break;
        case 16 / 5:
            ratioStr = "16:5";
            break;
        case 9 / 6:
            ratioStr = "9:6";
            break;
        case 128 / 9:
            ratioStr = "128:9";
            break;
        case 375 / 72:
            ratioStr = "375:72";
            break;
        default:
            ratioStr = "1:1";
            break;
    }
    return ratioStr;
};

export const ModalEditImage = ({
    isOpen = false,
    showGrid = true,
    imageSource = null,
    toggle,
    ratio = 1 / 1,
    onCropSucceeded = null,
    activeRatio = [],
    isSkipVisible = true,
}) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const [ratioList, setRatioList] = useState(RatioButtons);
    const prevProps = useRef({ isOpen, imageSource });

    const onCropChange = useCallback(
        (crop) =>
            dispatch({
                type: "EASY_CROP_SET_CROP",
                payload: crop,
            }),
        []
    );

    const onZoomChange = useCallback(
        (zoom) =>
            dispatch({
                type: "EASY_CROP_SET_ZOOM",
                payload: zoom,
            }),
        []
    );

    const onCropComplete = useCallback((_croppedArea, croppedAreaPixels) => {
        dispatch({
            type: "EASY_CROP_SET_CROPPED_AREA",
            payload: croppedAreaPixels,
        });
    }, []);

    const onToggleModal = useCallback(() => {
        toggle &&
            toggle(imageSource, {
                name: getRatioStr(state.aspect),
                orientation: state.imageOrientation,
                ratio: state.aspect,
            });
    }, [imageSource, state.aspect, state.imageOrientation, toggle]);

    const setRatio = useCallback(
        (ratio) =>
            dispatch({
                type: "EASY_CROP_SET_RATIO",
                payload: ratio,
            }),
        []
    );

    const toggleGrid = useCallback(
        () => dispatch({ type: "EASY_CROP_SET_GRID" }),
        []
    );

    const handleCropImage = useCallback(async () => {
        try {
            const croppedImage = await getCroppedImg(
                imageSource,
                state.croppedAreaPixels
            );
            // console.log('donee', { croppedImage })
            return croppedImage;
        } catch (e) {
            console.error(e);
        }
    }, [imageSource, state.croppedAreaPixels]);

    useEffect(() => {
        if (prevProps.current.isOpen !== isOpen && isOpen) {
            dispatch({
                type: "EASY_CROP_RESET",
                payload: {
                    showGrid: showGrid,
                    aspect: ratio,
                    imageOrientation: null,
                },
            });
        }
        if (prevProps.current.imageSource !== imageSource && imageSource) {
            const image = new Image();
            image.onload = function () {
                let orientation = null;
                if (image.naturalWidth > image.naturalHeight) {
                    orientation = "landscape";
                } else if (image.naturalWidth <= image.naturalHeight) {
                    orientation = "portrait";
                }
                const testRatio = image.naturalWidth / image.naturalHeight;
                const arr = ratioList
                    .map((item) => item.value)
                    .sort()
                    .filter((item) => item > testRatio);
                // console.log("arr: ", arr, arr.length ? arr[0]: ratio)
                dispatch({
                    type: "EASY_CROP_IMG_LOADED",
                    payload: {
                        aspect: arr.length ? arr[0] : ratio,
                        imageOrientation: orientation,
                    },
                });
                // console.log("arr: ", arr)
            };
            image.src = imageSource;
        }
        prevProps.current = { isOpen, imageSource };
    }, [isOpen, imageSource, showGrid, ratio]);

    useEffect(() => {
        if (activeRatio.length > 0) {
            const newRatioList = RatioButtons.filter((item) =>
                activeRatio.includes(item.value || item.name)
            );
            setRatioList(newRatioList);
        }
    }, [activeRatio]);

    return (
        <ModalWrapper isOpen={isOpen} size="lg">
            {toggle && (
                <Close onClick={onToggleModal}>
                    <SimpleLineIcon iconType="close" />
                </Close>
            )}
            <CropContainer>
                <Container>
                    <Cropper
                        image={imageSource}
                        crop={state.crop}
                        zoom={state.zoom}
                        aspect={state.aspect}
                        showGrid={state.showGrid}
                        onCropChange={onCropChange}
                        onCropComplete={onCropComplete}
                        onZoomChange={onZoomChange}
                    />
                </Container>
            </CropContainer>
            <ModalFooter>
                {state.progress ? (
                    <div style={{ flex: 1, padding: "0px 15px" }}>
                        <p style={{ margin: 0 }}>Loading...</p>
                    </div>
                ) : (
                    <Controls>
                        <ControlButton
                            active={state.showGrid}
                            onClick={toggleGrid}
                        >
                            <SimpleLineIcon iconType="grid" />
                        </ControlButton>
                        {ratioList.map((item, key) => (
                            <ControlButton
                                key={key}
                                active={state.aspect === item.value}
                                onClick={() => setRatio(item.value)}
                            >
                                {item.name}
                            </ControlButton>
                        ))}
                    </Controls>
                )}
                <ButtonGroup style={{ height: "100%" }}>
                    {isSkipVisible && (
                        <Button
                            style={{
                                height: "100%",
                                fontSize: "1rem",
                                minWidth: 80,
                            }}
                            onClick={onToggleModal}
                        >
                            Skip
                        </Button>
                    )}

                    <Button
                        disabled={state.progress}
                        color="primary"
                        title="Crop"
                        style={{
                            height: "100%",
                            fontSize: "1rem",
                            minWidth: 80,
                        }}
                        onClick={async () => {
                            if (imageSource) {
                                try {
                                    dispatch({
                                        type: "EASY_CROP_SET_PROGRESS",
                                        payload: true,
                                    });
                                    const blob = await handleCropImage();
                                    
                                    dispatch({
                                        type: "EASY_CROP_SET_PROGRESS",
                                        payload: false,
                                    });
                                    if (onCropSucceeded) {
                                        onCropSucceeded(blob, {
                                            name: getRatioStr(state.aspect),
                                            orientation: state.imageOrientation,
                                            ratio: state.aspect,
                                        });
                                    }
                                } catch {
                                    alert("Error while cropping your image.");
                                    dispatch({
                                        type: "EASY_CROP_SET_PROGRESS",
                                        payload: false,
                                    });
                                }
                            }
                        }}
                    >
                        <SimpleLineIcon iconType="crop" /> Crop
                    </Button>
                </ButtonGroup>
            </ModalFooter>
        </ModalWrapper>
    );
};
