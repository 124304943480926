import React, {Component} from 'react';
import {connect} from 'react-redux';
import {
    Row,
    Col,
    Card,
    CardBody,
    CardText,
    Badge
} from 'reactstrap';
import moment from 'moment-timezone';
import { Link } from 'react-router-dom';
import SmallInline from '../../styled/Typograph/Small/SmallInline';
import {Tbl, TblHead, TblBody} from '../../components/Tbl';
import { FontAwesomeIcon } from '../../components/Icons';
import Paginations from '../../components/Paginations';
import PaginationSummaries from '../../components/Paginations/PaginationSummaries';
import DateToHuman from '../../components/DateToHuman';
import { TextRupiah } from '../../components/Text';
import { ButtonXs } from '../../components/Button/Buttons';
import FilterPanel from './Filters';
import FilterContainer from '../../components/Container/FilterContainer/FilterContainer';
import { CardHeaderWithToolbars,Title } from '../../components/Card/CardHeaderWithToolbars';
import Toolbars from './Filters/Toolbars';

import {getListUser} from '../../actions/userAction';
import { generateNumber } from '../../utils';
import { DEFAULT_SORT_BY, DEFAULT_PER_PAGE } from '../../constants/settings';
import privateView from '../../components/hocs/privateView';
import Analytics from "../../services/Analytics";
import ModalUserStockExport from './UserForm/ModalUserStockExport';

const dataHeader=[
  'No.','Email', 'Kick Credit','Seller Credit', 'Kick Point','Updated', 'Action'
];

const defaultParams = {
  sort_by : DEFAULT_SORT_BY,
  per_page : DEFAULT_PER_PAGE,
}

class UserIndex extends Component{
  constructor(props){
    super(props);
    this.state={
      _params:{
        ...defaultParams
      },
      _filterOpen:false,
      modalStockExport: {
        userId: null,
        email: null,
        isOpen: false
      }
    }
    this._handleGoto = this._handleGoto.bind(this);
    this._handleToggleFilterPanel = this._handleToggleFilterPanel.bind(this);
    this._handleChange = this._handleChange.bind(this);
    this._handleParamsChangeAndSubmit = this._handleParamsChangeAndSubmit.bind(this);
    this._handleRefresh = this._handleRefresh.bind(this);
    this._handleResetFilter = this._handleResetFilter.bind(this);
    this._handleRegisteredAtChange = this._handleRegisteredAtChange.bind(this);
  }

  componentWillMount(){
    const { email } = this.props.injectedProps;
    this._getList(this.state._params);
    Analytics.recordPageView('user_list', email, {
      url: window.location.href,
      location: this.props.location,
      match: this.props.match
    });
  }

  _handleModalStockExportToggle = (payload = {}) =>{
    this.setState({
      modalStockExport: {
        ...this.state.modalStockExport,
        ...payload,
        isOpen: !this.state.modalStockExport.isOpen
      }
    })
  }

  _onRenderRowItem(){
    if(this.props.user.list.status_code===200 && this.props.user.list.data.length>0) {
      const { current_page,per_page } =  this.props.user.list;
      return this.props.user.list.data.map((item,index)=>
        (
          <tr key={generateNumber(per_page,current_page,index)}>
            <th className="v-center" scope="row">{ generateNumber(per_page,current_page,index) }</th>	
            <td className="v-center" style={{maxWidth: 250}}>
              { item.email } <SmallInline className="text-muted">  { item.is_holiday?  <Badge color="warning">HOLIDAY!</Badge>:'' }</SmallInline>
              <br/>
              <p className="mb-0" style={{fontSize: '.65rem'}}>
                typed email: <br/>
                { item.typed_email }
              </p>
              { item.roles.sort((a,b)=> a.name>b.name).map(role => <Link style={{marginRight:5}} to={`/roles/${role.id}`} key={`badge-link-${role.id}`}><Badge color="primary">{role.name}</Badge></Link>) }
              <SmallInline>
                <span className="mr-1">
                  <FontAwesomeIcon iconType="calendar" className="mr-1"/>{ item.created_at }
                </span>
              </SmallInline>
            </td>
            <td className="v-center">
              {item.buyer_points_status && item.buyer_points_status.points?(
                <p className="mb-0" style={{fontSize: '.65rem'}}>
                  <strong className="text-uppercase">{item.buyer_points_status.tier_label}</strong>
                  <span className="ml-1">({item.buyer_points_status.points}/{item.buyer_points_status.max_point? Number(item.buyer_points_status.max_point): '-'})</span>
                </p>
              ):null}
              <TextRupiah prefix="Rp. " block value={item.balance}/>
              {item.current_balance - item.balance > 0?
                <div style={{fontSize: '.75rem', fontStyle: 'italic'}}>
                  On Hold: <TextRupiah tag="span" prefix="Rp. " value={item.current_balance - item.balance}/>
                </div>
              : null}
            </td>
            <td className='v-center'>
              <TextRupiah prefix="Rp. " block value={item.balance_with_fee} />
              {item.current_balance_with_fee - item.balance_with_fee > 0?
                <div style={{fontSize: '.75rem', fontStyle: 'italic'}}>
                  On Hold: <TextRupiah tag="span" prefix="Rp. " value={item.current_balance_with_fee - item.balance_with_fee}/>
                </div>
              : null}
            </td>
            <td className='v-center'>
              <TextRupiah prefix="Rp. " block value={item.locked_balance} />
              {item.current_locked_balance - item.locked_balance > 0?
                <div style={{fontSize: '.75rem', fontStyle: 'italic'}}>
                  On Hold: <TextRupiah tag="span" prefix="Rp. " value={item.current_locked_balance - item.locked_balance}/>
                </div>
              : null}
            </td>
            <td className="v-center">{ <DateToHuman value={item.updated_at} id={`tooltip_updatedAt_${generateNumber(per_page,current_page,index)}`}/> }</td>
            <td>
              <Link to={`/users/${item.id}`} className="btn btn-link">Details</Link>
                <div>
                  <ButtonXs onClick={() => this._handleModalStockExportToggle({userId: item.id, email: item.email})}>
                    <i className="icon-share-alt"></i> Export Stock
                  </ButtonXs>
                </div>
            </td>
          </tr>
        )
      )	
    }
    return null;
  }

  _onRenderPagination(){
    const { status_code,data } = this.props.user.list;
    const hasData = (status_code===200 && data.length) || null;
    return hasData && (
      <Paginations 
        size="sm" 
        lastPage={this.props.user.list.last_page} 
        perPage={this.props.user.list.per_page}
        total={this.props.user.list.total}
        maxPage={8}
        path={this.props.user.list.path}
        currentPage={this.props.user.list.current_page} 
        prevPageUrl={this.props.user.list.prev_page_url} 
        nextPageUrl={this.props.user.list.next_page_url} 
        handleGotoCallback={this._handleGoto}
      />
    )
  }

  /**
 * Handle callback when user click pagination items
 * @author pewe
 * @param {integer} page 
 */
	_handleGoto(page){
		const _params=this.state._params;
		_params.page=page;
		this.setState({_params},()=>this._getList(this.state._params));
	}

  _toggleFilterPanel(){
    this.setState({_filterOpen:!this.state._filterOpen})
  }

  _handleToggleFilterPanel(e){
    e.preventDefault();
    this._toggleFilterPanel();
  }

  _getList(rawParams = {} ){
    let params = {};
    Object.keys(rawParams)
    .filter(key => rawParams[key]!='')
    .forEach(key=> params[key] = rawParams[key]);
    this.props.getList({params});
  }

  _refreshList(){
    const {page, ..._params} = this.state._params;
    this.setState({
      _params
    },()=>this._getList(this.state._params));
  }

  _handleChange(e){
    const { _params } = this.state;
    _params[e.target.name] = e.target.value;
    this.setState({_params});
  }

  _handleParamsChangeAndSubmit(e){
    // Exclude page...
    const { page , ..._params } = this.state._params;
    _params[e.target.name] = e.target.value;
    this.setState({_params},()=>{
      this._getList(this.state._params);
    });    
  }

  _handleResetFilter(e){
    e.preventDefault();
    const newState = this.state._filterOpen?{_filterOpen:!this.state._filterOpen}:{};
    this.setState({
      ...newState,
      _params:{
        ...defaultParams
      }
    },()=>this._getList(this.state._params));
  }

  _handleRefresh(e){
    e.preventDefault();
    this._refreshList();
  }

  _handleRegisteredAtChange(rangeDates){
    this.setState({
      _params:{
        ...this.state._params,
        start_at: rangeDates.start_at != null && moment.isMoment(rangeDates.start_at)? rangeDates.start_at.format("YYYY-MM-DD"):rangeDates.start_at,
        end_at: rangeDates.end_at != null && moment.isMoment(rangeDates.end_at)? rangeDates.end_at.format("YYYY-MM-DD"):rangeDates.end_at,
      }
    })
  }

  render(){
    return(
      <div className="animated fadeIn">
        <ModalUserStockExport
          userId={this.state.modalStockExport.userId}
          email={this.state.modalStockExport.email}
          isOpen={this.state.modalStockExport.isOpen}
          toggle={this._handleModalStockExportToggle}
        />
        <Row>
          <Col xs={12}>
            <Card>                            
              <CardHeaderWithToolbars>
                <Title>
                  <i className="fa fa-align-justify"></i>
                  <span>User List</span>
                </Title>
                <Toolbars
                  isOpen={this.state._filterOpen}
                  onToggleFilter={this._handleToggleFilterPanel}
                  onRefreshList={this._handleRefresh}
                  onResetFilter={this._handleResetFilter}
                />
              </CardHeaderWithToolbars>
              <FilterContainer>
                <FilterPanel                  
                  {...this.state._params}
                  onHandleChange={this._handleChange}
                  onHandleChangeAndSubmit={this._handleParamsChangeAndSubmit}
                  onHandleSearch={this._handleRefresh}
                  onHandleRegisteredAtChange={this._handleRegisteredAtChange}
                  isOpen={this.state._filterOpen}
                />
              </FilterContainer>
              <CardBody>
                <CardText>
                  In this section will display our users.
                </CardText>
                <div className="table-responsive">
                  <PaginationSummaries
                    from={this.props.user.list.from}
                    to={this.props.user.list.to}
                    total={this.props.user.list.total}
                  />
                  <Tbl>
                      <TblHead
                          dataHeader={dataHeader}
                      />
                      <TblBody
                          isLoad={this.props.user.isFetch}
                          hasRow={this.props.user.list.status_code===200&&this.props.user.list.data.length>0}
                          columnCount={dataHeader.length}
                      >
                      { this._onRenderRowItem() }
                      </TblBody>
                  </Tbl>
                </div>
                { this._onRenderPagination() }
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    )
  }
}
const mapStateToProps=({user, auth: { isFetch, isLogged, roles, id, email }})=>
{
	return {
    user,
    email,
    guardData: { isFetch, isLogged, roles, id, email }
	}
}
const mapDispatchToProps=(dispatch)=>
{
	return {
		getList:(filter)=>dispatch(getListUser(filter)),
	}
}

const enhance = connect(mapStateToProps, mapDispatchToProps);
export default enhance(privateView(UserIndex, ['customer_service']))