import React from 'react';
import propTypes from 'prop-types';
import { Button } from 'reactstrap';
import FontAwesomeIcon from '../../../components/Icons/FontAwesomeIcon';
import {
  PENDING_STATUS,
  COMPLETED_STATUS,
  REJECTED_STATUS,
} from '../helpers';

const StatusFilter = props => (
  <div className="d-flex mb-3" style={{justifyContent: 'flex-end'}}>
    <Button
      size="sm"
      onClick={()=> props.onClick("")}
      color={props.status===""?"primary": "link"}
    >
      { props.status===""&&<FontAwesomeIcon className="mr-1" iconType="check"/> }
      All
    </Button>
    <Button
      size="sm"
      onClick={()=> props.onClick(PENDING_STATUS)}
      color={props.status===PENDING_STATUS?"primary": "link"}
    >
      { props.status===PENDING_STATUS&&<FontAwesomeIcon className="mr-1" iconType="check"/> }
      Pending
    </Button>
    <Button
      size="sm"
      onClick={()=> props.onClick(REJECTED_STATUS)}
      color={props.status===REJECTED_STATUS?"primary": "link"}
    >
      { props.status===REJECTED_STATUS&&<FontAwesomeIcon className="mr-1" iconType="check"/> }
      Rejected
    </Button>
    <Button
      size="sm"
      onClick={()=> props.onClick(COMPLETED_STATUS)}
      color={props.status===COMPLETED_STATUS?"primary": "link"}
    >
      { props.status===COMPLETED_STATUS&&<FontAwesomeIcon className="mr-1" iconType="check"/> }
      Completed
    </Button>
  </div>
)
export default StatusFilter;
StatusFilter.defaultProps = {
  status: ""
}

StatusFilter.propTypes = {
  status: propTypes.string,
  onClick: propTypes.func
}