import React from 'react';
import {Alert, Button, Col, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";
import {ImageFile, ImageFileListContainer, ImageFrame} from "../../../../../components/Images";
import {DropzonePlusButton} from "../../../../../components/Form/DropzoneFile";
import {useUploadImageS3} from "../../../Hooks/images/useUploadImageS3";
import {usePostImages} from "../../../Hooks/post/usePostImages";
import {addImageRawObject} from "../../../../../utils/imageHelper";

export default function (props) {
    const {
        isOpen,
        onToggle,
        consignment,
        onSubmit,
        isSubmit,
        email,
        errorMessage,
    } = props;

    const {upload} = useUploadImageS3();
    const {postImages} = usePostImages();

    const [rawImages, setRawImages] = React.useState([]);
    const [imageStored, setImageStored] = React.useState([]);
    const [isPendingSubmit, setIsPendingSubmit] = React.useState(false);

    const onDropFiles = files => {
        if(isPendingSubmit) return;
        if(files.length){
            const fileInputs = files.map(c => ({
                ...addImageRawObject(c),
            }));
            setRawImages([
                ...rawImages,
                ...fileInputs
            ]);
        }
    };

    const handleUploadImage = async () => {
        let images = [];
        const continueSubmit = async () => {
            const resp = await postImages(consignment.id, {
                urls: images.map(c => c.url),
            });
            setIsPendingSubmit(false);
            return resp;
        };

        for(let i = 0; i < rawImages.length; i++){
            const file = rawImages[i];
            images.push(await upload(`consignment/${consignment.id}/${i}`, file));
            if (i === rawImages.length - 1) {
                return continueSubmit();
            }
        }
    };

    const submit = async (e) => {
        e.preventDefault();
        if(isPendingSubmit) return;
        if(rawImages.length){
            setIsPendingSubmit(true);
            await handleUploadImage();
        }
        onSubmit();
    };

    return (
        <Modal isOpen={isOpen} toggle={onToggle} size="md">
            <ModalHeader>
                Confirmation
            </ModalHeader>
            <ModalBody>
                {errorMessage !== '' && <Alert color="danger">{errorMessage}</Alert>}
                <p>
                    Crew: {email} <br/>
                    <b>({consignment?.consignment_number})</b> <br/>
                    Legit consignment <i>{consignment?.user_sell?.product_variant?.display_name}</i>
                </p>
                <p>
                    You cannot undo this action, this order will be accepted because its legit.
                    <br/>
                    Are you really sure?
                </p>
                <FormGroup>
                    <Label>Upload Images - optional</Label>
                    <p className="mb-0" style={{fontStyle: 'italic', fontSize: '.65rem'}}>Try drop any images or click to select any images.</p>
                    <ImageFileListContainer>
                        <ImageFrame>
                            <DropzonePlusButton onDrop={onDropFiles}/>
                        </ImageFrame>
                        {rawImages.map((img, key)=>(
                            <ImageFile
                                showDelete
                                key={key}
                                size={60}
                                src={img.URL}
                                onDelete={() => {
                                    if(isPendingSubmit) return;
                                    setRawImages(rawImages.filter((_img, idx) => idx !== key));
                                }}
                            />
                        ))}
                    </ImageFileListContainer>
                </FormGroup>
                {imageStored.length? (
                    <div>
                        <p>Already uploaded.</p>
                        <Row style={{maxHeight: 150, overflow: 'auto'}}>
                            {imageStored.map(item => (
                                <Col xs={6} sm={4} key={item.id}>
                                    <img width="100%" src={item.URL} alt={item.URL}/>
                                </Col>
                            ))}
                        </Row>
                    </div>
                ): null}
            </ModalBody>
            {
                !isSubmit ? (
                    <ModalFooter>
                        <Button color="secondary" onClick={onToggle}>Cancel</Button>
                        <Button color="primary" onClick={submit}>Submit</Button>
                    </ModalFooter>
                ):null
            }
        </Modal>
    );
}
