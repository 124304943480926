import React from "react"
import { connect } from "react-redux"
import {
  Button,
  Modal,
  ModalBody,
  Form,
  Input,
  InputGroup,
  InputGroupAddon
} from "reactstrap"
import { FontAwesomeIcon } from "../../../components/Icons"
import Paginations from "../../../components/Paginations/Paginations"
import PaginationSummaries from "../../../components/Paginations/PaginationSummaries"
import AlertNotFound from "../../../components/Card/AlertNotFound"
import { getRaffleList } from "../../../actions/raffleAction"
import Badge from "reactstrap/lib/Badge"

const ItemList = (props) => (
  <div>
    <PaginationSummaries
      from={props.paginations.from}
      to={props.paginations.to}
      total={props.paginations.total}
    />
    <div
      style={{
        paddingTop: ".5rem",
        borderTop: "1px solid #ddd",
        position: "relative",
      }}
    >
      {props.isLoading ? (
        <div style={{ position: "absolute", width: "100%", zIndex: 5 }}>
          <div
            style={{
              padding: "3px 6px",
              background: "rgba(255,255,255,.85)",
              width: 150,
              textAlign: "center",
              margin: "auto",
            }}
          >
            Loading...
          </div>
        </div>
      ) : null}
      <div style={{ maxHeight: 400, overflow: "auto" }}>
        {props.list.length === 0 ? (
          <div style={{ padding: "0px .5rem" }}>
            <AlertNotFound message="No Record Found!" />
          </div>
        ) : null}
        {props.list.map(props.renderItems)}
      </div>
    </div>
    <div style={{ padding: "6px 0px", position: "relative" }}>
      <div
        style={{
          position: "absolute",
          top: "-2px",
          zIndex: 10,
          background: "#FFF",
          boxShadow: "0px -5px 5px -3px rgba(0,0,0,0.5)",
          height: 8,
          width: "100%",
        }}
      ></div>
      <Paginations
        size="sm"
        lastPage={props.paginations.lastPage}
        perPage={props.paginations.perPage}
        total={props.paginations.total}
        maxPage={8}
        currentPage={props.paginations.currentPage}
        handleGotoCallback={props.onGotoPage}
      />
    </div>
  </div>
)

const defaultParams = {
  per_page: 15,
  page: 1,
  sort_by: "updatedAt_desc",
}

const defaultPaginations = {
  from: 0,
  to: 0,
  total: 0,
  currentPage: 0,
  lastPage: 0,
  perPage: 0,
}

class ModalRaffles extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      _params: {
        ...defaultParams,
      },
      paginations: {
        ...defaultPaginations,
      },
      list: [],
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.isOpen && this.props.isOpen) {
      this._getDataList(this.state._params)
    }
    if (
      prevProps.dataRaffle.isFetch &&
      !this.props.dataRaffle.isFetch &&
      this.props.isOpen
    ) {
      const { error } = this.props.dataRaffle
      if (!error) {
        const { data, ...paginations } = this.props.dataRaffle.data
        if (data.length === 0 && paginations.current_page > 1) {
          this._handleGoto(paginations.last_page ? paginations.last_page : 1)
        } else {
          this.setState({
            list: [...data],
            paginations: {
              ...this.state.paginations,
              from: paginations.from,
              to: paginations.to,
              total: paginations.total,
              currentPage: paginations.current_page,
              lastPage: paginations.last_page,
              perPage: paginations.per_page,
            },
          })
        }
      }
    }
  }

  _sanitizeParams = (rawParams) => {
    return {
      params: Object.keys(rawParams)
        .filter((key) => rawParams[key] != "")
        .reduce(
          (obj, current) => ({ ...obj, [current]: rawParams[current] }),
          []
        ),
    }
  }

  _getDataList = (rawParams = {}) => {
    this.props.getRaffleList(this._sanitizeParams(rawParams))
  }

  _handleGoto = (page) => {
    const _params = this.state._params
    _params.page = page
    this.setState({ _params }, () => this._getDataList(this.state._params))
  }

  render() {
    return (
      <Modal
        isOpen={this.props.isOpen}
        toggle={this.props.toggle}
        size={this.props.size}
      >
        <ModalBody>
          <Form
            style={{ marginBottom: ".5rem" }}
            onSubmit={(e) => {
              e.preventDefault()
              this._getDataList(this.state._params)
            }}
          >
            <InputGroup>
              <Input
                bsSize="sm"
                placeholder="Search raffles..."
                value={this.state._params.keyword || ""}
                name="keyword"
                onChange={(e) => {
                  this.setState({
                    _params: {
                      ...this.state._params,
                      [e.target.name]: e.target.value,
                    },
                  })
                }}
              />
              <InputGroupAddon addonType="append">
                <Button color="primary">Search</Button>
              </InputGroupAddon>
            </InputGroup>
          </Form>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginBottom: "1rem",
              alignItems: "center",
            }}
          >
            <Button
              outline
              title="Refresh list"
              color="primary"
              style={{ fontSize: ".5rem" }}
              size="sm"
              type="button"
              onClick={() => this._handleGoto(1)}
            >
              <FontAwesomeIcon iconType="refresh" />
            </Button>
          </div>
          <ItemList
            isLoading={this.props.dataRaffle.isFetch}
            paginations={this.state.paginations}
            list={this.state.list}
            renderItems={(item) => (
              <button
                title="Click to select..."
                key={item.id}
                style={{
                  background: "none",
                  width: "100%",
                  border: "none",
                  padding: "6px 12px",
                  textAlign: "left",
                  borderBottom: "1px solid #ddd",
                }}
                className="mb-1"
                onClick={() => this.props.onSelectedItem(item)}
              >
                <div style={{ fontSize: ".75rem" }}>
                  {item.group_name ? (
                    <b className="mr-1">{item.group_name}</b>
                  ) : null}
                  {item.golden ? (
                    <b>
                      <Badge
                        className="mr-1"
                        // style={{ backgroundColor: goldenColor }}
                      >
                        GOLDEN
                      </Badge>
                    </b>
                  ) : null}
                  {item.raffle_group ? (
                    <b>
                      <Badge
                        className="mr-1 text-uppercase"
                        style={{
                          backgroundColor: item.raffle_group.color,
                        }}
                      >
                        {item.raffle_group.name}
                      </Badge>
                    </b>
                  ) : null}
                </div>
                {item.name}
                <br />
                {item.raffle_tiers && item.raffle_tiers.length ? (
                  <div
                    style={{ fontSize: ".65rem" }}
                    className="text-uppercase"
                  >
                    <i className="fa fa-product-hunt"></i>{" "}
                    {item.raffle_tiers[0].tier_label}
                  </div>
                ) : null}
                {item.voucher_id || item.promo_code ? (
                  <div style={{ fontSize: ".65rem" }}>
                    Code:{" "}
                    {item.voucher_id
                      ? `${
                          item.voucher
                            ? item.voucher.code
                            : item.voucher_id
                        } (voucher)`
                      : item.promo_code
                      ? item.promo_code
                      : "-"}
                  </div>
                ) : null}
                <div style={{ fontSize: ".65rem" }}>
                  <span className="mr-1">#{item.id}</span>
                  <span>Entries: {item.raffle_entries_count}</span>
                </div>
              </button>
            )}
            onGotoPage={this._handleGoto}
          />
        </ModalBody>
      </Modal>
    )
  }
}

const mapStateToProps = ({ raffle }) => ({
  dataRaffle: {
    isFetch: raffle.list.isFetch,
    data: raffle.list.data,
    error: raffle.list.error,
  },
})
const mapDispatchToProps = (dispatch) => ({
  getRaffleList: (query) => dispatch(getRaffleList(query)),
})
export default connect(mapStateToProps, mapDispatchToProps)(ModalRaffles)
