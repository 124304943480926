import React from 'react';
import Select from 'react-select';

import styles from './PrintTypeOptions.module.css';
import { PRINT_TYPE_OPTIONS } from './PrintTypeOptions.constants';

export const PrintTypeOptions = (props) => {
  const { 
    selectedData = [], 
    onSetPrintTypeOptions = () => {},
    selectedPrintType = PRINT_TYPE_OPTIONS.NO_PRICE
  } = props;

  if (!selectedData.length) {
    return null;
  }

  return (
    <label 
      className={styles.printTypeOptLabel}
    >
      <Select
        className={styles.printTypeOptSelect}
        style={{ 
          width: '100px',
          height: '30px',
        }}
        options={[
          { value: PRINT_TYPE_OPTIONS.NO_PRICE, label: 'No Price' },
          { value: PRINT_TYPE_OPTIONS.SHOW_PRICE, label: 'Show Price' }
        ]}
        clearable={false}
        value={selectedPrintType}
        onChange={(opt) => onSetPrintTypeOptions(opt?.value)}
      />
    </label>
  )
}

export default PrintTypeOptions;