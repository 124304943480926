import React from 'react';
import propTypes from 'prop-types';
import { Tbl, TblHead, TblBody } from '../../../components/Tbl';
import Paginations from '../../../components/Paginations';
import PaginationSummaries from '../../../components/Paginations/PaginationSummaries';
import { generateNumber } from '../../../utils';
import { ModalInfoCreditCard } from '../../../components/Modals';
import RowItem from './paymentDisbursementList/rowItem';
import ModalDisbursement from './paymentDisbursementList/ModalDisbursement';

class PaymentDisbursementList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      _list:[],
      _selectedItem: null,
      _isModalDisburseOpen: false,
      _modalCreditCard: false,
      _invoiceNumber: null,
    }
    this._handleHideModal = this._handleHideModal.bind(this);
    this._handleToggleModalCreditCard = this._handleToggleModalCreditCard.bind(this);
  }

  componentDidUpdate(prevProps) {
    if(prevProps.isLoading && prevProps.isLoading!==this.props.isLoading){
      this.setState({
        _list: this.props.list
      })
    }
    if(prevProps.isSubmit && prevProps.isSubmit!==this.props.isSubmit){
      const { dataErrorOffer, dataUpdatedOffer } = this.props;
      if(!dataErrorOffer){
        this.setState({
          _selectedItem: null,
          _isModalDisburseOpen: false,
          _list: this.state._list.map(item=>{
            if(item.id===dataUpdatedOffer.id){
              item.updated_at=dataUpdatedOffer.updated_at;
              item.status=dataUpdatedOffer.status;
            }
            return { ...item }
          })
        })
      }
    }
  }

  _handleOpenModal(item){
    this.setState({
      _selectedItem: item,
      _isModalDisburseOpen: !this.state._isModalDisburseOpen
    })
  }

  _handleHideModal(){
    if(!this.props.isSubmit){
      this.setState({
        _selectedItem: null,
        _isModalDisburseOpen: !this.state._isModalDisburseOpen
      })
    }
  }

  
  _onOpenCreditCardModal(item){
    this.setState({_invoiceNumber: item.invoice_number},()=>this._handleToggleModalCreditCard());
  }

  _handleToggleModalCreditCard() {
    this.setState({_modalCreditCard: !this.state._modalCreditCard});
  }


  render() {
    return (
      <div className="animated fadeIn">
        <ModalDisbursement
          offer={this.state._selectedItem}
          isSubmit={this.props.isSubmit}
          dataUpdated={this.props.dataUpdatedOffer}
          dataError={this.props.dataErrorOffer}
          isOpen={this.state._isModalDisburseOpen}
          toggle={this._handleHideModal}
          handleUpdateOffer={this.props.handleUpdateOffer}
          email={this.props.email}
        />
        <ModalInfoCreditCard
          isOpen={this.state._modalCreditCard}
          toggle={this._handleToggleModalCreditCard}
          invoiceNumber={this.state._invoiceNumber}
        />
        <div className="table-responsive" ref={tableRef => this.tableRef = tableRef}>
          <PaginationSummaries
            from={this.props.from}
            to={this.props.to}
            total={this.props.total}
          />
          <Tbl>
            <TblHead
              renderChildren={this.props.onRenderHeader}
            />
            <TblBody
              isLoad={this.props.isLoading}
              hasRow={this.props.statusCode===200&&this.state._list.length>0}
              columnCount={6}
              renderChildren={()=>{
                const { currentPage, perPage } =  this.props;
                return this.state._list.map((item,index) => {
                  const rowNumber = generateNumber(perPage,currentPage,index);
                  return (
                    <RowItem
                      key={rowNumber}
                      dataItem={item}
                      id={item.id}
                      rowId={rowNumber}
                      quantity={item.quantity}
                      userId={item.shipping.user_id}
                      userEmail={item.shipping.user.email}
                      category={item.user_sell.product_variant.product.category&&item.user_sell.product_variant.product.category.name}
                      invoiceNumber={item.invoice_number}
                      updatedAt={item.updated_at}
                      paymentMethod={item.payment_method}
                      paymentStatus={item.status}
                      amount={item.total_amount}
                      adjustedAmount={item.total_adjusted_amount}
                      displayName={item.user_sell.product_variant.display_name}
                      size={item.user_sell.size.US}
                      colour={item.user_sell.product_variant.colour}
                      SKU={item.user_sell.product_variant.SKU}
                      isSelected={this.state._selectedItem!==null&&this.state._selectedItem.id===item.id}
                      onOpenModalClick={()=>this._handleOpenModal(item)}
                      preOrder={item.user_sell.pre_order}
                      consignmentId={item.user_sell.consignment_id}
                      bank={item.bank_code}
                      paymentVia={item.payment_via}
                      rack={item.user_sell.rack}
                      points={item.points}
                      onOpenCreditCard={()=>this._onOpenCreditCardModal(item)}
                    />
                  )
                })
              }}
            />
          </Tbl>
        </div>
        <Paginations
          isLoading={this.props.isLoading}
          size="sm"
          lastPage={this.props.lastPage}
          perPage={this.props.perPage}
          total={this.props.total}
          maxPage={8}
          currentPage={this.props.currentPage}
          handleGotoCallback={this.props.handleGotoList}
          onCurrentPageChanged={() => {
            window.scrollTo({
              top: this.tableRef.offsetTop+25,
              behavior: 'smooth'
            })
          }}
        />
      </div>
    )
  }
}

export default PaymentDisbursementList;

PaymentDisbursementList.propTypes = {
  currentPage: propTypes.number,
  from: propTypes.number,
  to: propTypes.number,
  total: propTypes.number,
  statusCode: propTypes.oneOfType([propTypes.string, propTypes.number]),
  prevPageUrl: propTypes.string,
  nextPageUrl: propTypes.string,
  lastPage: propTypes.number,
  path: propTypes.string,
  list: propTypes.arrayOf(propTypes.object),
  isLoading: propTypes.bool,
  dataUpdatedOffer: propTypes.object, //Props from redux
  dataErrorOffer: propTypes.object, //Props from redux
  handleGotoList: propTypes.func.isRequired,
  handleUpdateOffer: propTypes.func.isRequired
}

PaymentDisbursementList.defaultProps = {
  currentPage: 0,
  from: 0,
  to: 0,
  total: 0,
  statusCode: null,
  prevPageUrl: null,
  nextPageUrl: null,
  lastPage: 0,
  list: [],
  isLoading: false,
}