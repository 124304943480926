import React from 'react';
import {
  Alert,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input, 
  Button,
  Row,
  Col
} from 'reactstrap';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Select from '../../../components/Form/Select';
import SelectUserAsync from '../../../components/Form/Select/Async/SelectUser';
import Radio from '../../../components/Form/Radios/Radio';
import InputDatetime from '../../../components/Form/InputDatetime/InputDatetime';
import { hasError, errorMessage, textChange, textValue, dateValue, dateChange, checkChange } from '../../../utils/form';

export class ModalUserVoucher extends React.Component{
  constructor(props){
    super(props);
    moment.tz.setDefault("Asia/Jakarta");
    this.state={
      id:null,
      user:[],
      voucher_id:null,
      used:false,
      used_count:0,
      used_at:null,
      started_at:null,
      ended_at:null,
      quantity:1,
      isSubmit:false,
      isAletError:false,
      error:null
    }
    this.handleCheckedChange=this.handleCheckedChange.bind(this);
    this._handleOnSelectUserChange = this._handleOnSelectUserChange.bind(this);
    this._handleOnSelectUserOpen = this._handleOnSelectUserOpen.bind(this);
    this.handleChange=this.handleChange.bind(this);
    this.onSubmit=this.onSubmit.bind(this);
    this.onDismiss = this.onDismiss.bind(this);
  }

  _handleOnSelectUserChange(selected){
    let { user } = this.state;
    user = selected;
    this.setState({
      user,
      _recentUserSearch: selected && this.state._recentUserSearch
    })
  }

  _handleOnSelectUserOpen(){
    if(this.state.user){
      return this.state._recentUserSearch&&this.refs.selectUser.loadSelectOptions(this.state._recentUserSearch);
    }
    return this.refs.selectUser.loadSelectOptions('');
  }

  handleChange(e){
    this.setState(textChange(e,{...this.state}));
  }

  handleDatePickerChange(date, name){
    this.setState(dateChange(date,name, {...this.state}));
  }

  handleCheckedChange(e){
    const _form=checkChange(e,this.state);
    this.setState({..._form});
  }
  componentWillReceiveProps(nextProps){
    const { dataUserVouchers, userVoucherSelected } = nextProps;
    if(nextProps.isOpen===true&&nextProps.isOpen!==this.props.isOpen){
      if(userVoucherSelected==null) {
        this.setState({
          voucher_id:nextProps.voucherId,
          error: null
        });
      }
      else{
        this.setState({
          id:userVoucherSelected.id,
          used:userVoucherSelected.used,
          used_count:userVoucherSelected.used_count,
          used_at:userVoucherSelected.used_at,
          voucher_id:nextProps.voucherId,
          started_at:userVoucherSelected.started_at,
          ended_at:userVoucherSelected.ended_at,
          user:userVoucherSelected.user && { id: userVoucherSelected.user.id, email: userVoucherSelected.user.email },
          error: null
        });
      }
    }
    else if(nextProps.isOpen===false&&nextProps.isOpen!==this.props.isOpen) {
      this.setState({
        id:null,
        user:[],
        isSubmit:false,
        used:false,
        used_count:0,
        used_at:null,
        started_at:null,
        ended_at:null,
        quantity:1,
      },()=>this.props.onRemoveSelected());
    }
    if(dataUserVouchers.isSubmit!==this.props.dataUserVouchers.isSubmit
      &&dataUserVouchers.isSubmit===false){
        const { error, isDelete } = dataUserVouchers;
        if(error)
          this.setState({
            error:{...dataUserVouchers.error},
            isSubmit:false, isAlertError:true},
          );
        else if(!isDelete){
          this.setState({
            isSubmit:false,
            started_at:null,
            ended_at:null,
            quantity:1,
          },()=>{this.props.onCancel()});
          this.props.onGetUserVoucherList();
        }
    }
  }

  onDismiss() {
    this.setState({ 
      isAlertError: false, 
      error: null 
    }, ()=>{this.props.onRemoveSelected()});
  }

  _validateForm(){
    let errors={};
    if(this.state.user.length==0)
      errors['user']=['The user field is required..'];
    if(Object.keys(errors).length){
      this.setState({
        error:{errors:{...errors}, status_code:422,message:'Please fulfill your form *'}
      })
    }
    return errors;
  }

  onSubmit(e){
    e.preventDefault();
    const valid= this._validateForm();
    let getUser = this.state.user;
    if(this.state.id==null)
      getUser = this.state.user.map(user =>user.id);
    if(Object.keys(valid).length===0){
      let payload = {
        id:this.state.id,
        used:this.state.used,
        used_count:this.state.used_count,
        used_at:this.state.used_at,
        voucher_id: this.state.voucher_id,
        user_id:this.state.id?this.state.user.id:getUser,
        quantity:this.state.quantity,
        started_at: this.state.started_at,
        ended_at: this.state.ended_at
      }
      this.setState({
        isSubmit:true,
        error:null,
      },()=>{
        this.props.onSubmit(payload);
      });
    }
  }

  _renderError(){
    const { error, isAlertError } = this.state;
    if(error!==null){
      switch(error.status_code){
        case 422:
          return <Alert color="danger" isOpen={isAlertError} toggle={this.onDismiss}>Please fulfill your form</Alert>
        default:
          return <Alert color="danger" isOpen={isAlertError} toggle={this.onDismiss}>Houston, we have a problem!. Please inform our friendly staff via LINE @kickavenue</Alert>
      }
    }
    return null;
  }

  render(){
    return(        
      <Modal isOpen={this.props.isOpen} toggle={this.props.onCancel} className="modal-dialog modal-md">
        <ModalHeader toggle={this.props.onCancel}>{this.state.id === null? 'Add ': ''}User Voucher</ModalHeader>
        <ModalBody>
        <Form>
          <Row>
            <Col  xs={12} md={8}>
              <FormGroup  className={classnames({'has-danger has-feedback':hasError('user',this.state.error)})}>
                <Label for="formUser">User*</Label>
                <SelectUserAsync
                  isMulti
                  defaultOptions
                  id="_formSelectUser"
                  userId={this.props.dataAuth.id}
                  ref="selectUser"
                  value={this.state.user}
                  paramsApi={{ roles: 'user,seller,administrator', role_query: 'or', scope: 'all' }}
                  placeholder="Type and select a user..."
                  noResultsText="Cannot find user."
                  loadOptions={this._getUserOptions}
                  onSelectSearch={(input)=>this.setState({_recentUserSearch: input})}
                  onSelectChange={this._handleOnSelectUserChange}
                  onSelectOpen={this._handleOnSelectUserOpen}
                  isDisabled={this.state.id?true:false}
                />
                {errorMessage('user',this.state.error)}
              </FormGroup>
            </Col>
            <Col xs={4}>
              <FormGroup className={classnames({'has-danger has-feedback':hasError('quantity',this.state.error)})}>
                <Label for="formQuantity">Quantity</Label>
                <Input
                  type="number"
                  id="formQuantiy"
                  name="quantity"
                  placeholder="Enter a quantity usage*"
                  value={textValue('quantity',this.state)}
                  onChange={this.handleChange}
                  disabled={this.state.id?true:false}
                />
                {errorMessage('quantity',this.state.error)}
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6}>
              <FormGroup  className={classnames({'has-danger has-feedback':hasError('started_at',this.state.error)})}>
                <Label for="formDeliverSentDate">Start Date</Label>
                <InputDatetime 
                  name="started_at"
                  id="formStartedDate"
                  timeFormat="HH:mm"
                  value={dateValue('started_at',this.state)}
                  onChange={(date)=>this.handleDatePickerChange(date,'started_at')}
                  inputProps={{placeholder:'Pick a Started At', id:'formStartedDate'}}
                />
                {errorMessage('started_at',this.state.error)}
              </FormGroup>
            </Col>
            <Col xs={12} md={6}>
              <FormGroup  className={classnames({'has-danger has-feedback':hasError('ended_at',this.state.error)})}>
                <Label for="formDeliverSentDate">End Date</Label>
                <InputDatetime 
                  name="ended_at"
                  id="formEndedDate"
                  timeFormat="HH:mm"
                  value={dateValue('ended_at',this.state)}
                  onChange={(date)=>this.handleDatePickerChange(date,'ended_at')}
                  inputProps={{placeholder:'Pick a Ended At', id:'formEndedDate'}}
                />
                {errorMessage('ended_at',this.state.error)}
              </FormGroup>
            </Col>
          </Row>
          {
            this.state.id!==null?
            <Row>
              <Col xs={12} md={6}>
                <FormGroup  className={classnames({'has-danger has-feedback':hasError('used_count',this.state.error)})}>
                  <Label for="formUsedCount">Used Count</Label>
                  <Input
                    type="number"
                    id="formUsedCount"
                    name="used_count"
                    placeholder="Used Count"
                    value={textValue('used_count',this.state)}
                    onChange={this.handleChange}
                  />
                  {errorMessage('used_count',this.state.error)}
                </FormGroup>
              </Col>
              <Col xs={12} md={6}>
                <FormGroup  className={classnames({'has-danger has-feedback':hasError('used_at',this.state.error)})}>
                  <Label for="formDeliverSentDate">Used Date</Label>
                  <InputDatetime 
                    name="used_at"
                    id="formUsedDate"
                    timeFormat="HH:mm"
                    value={dateValue('used_at',this.state)}
                    onChange={(date)=>this.handleDatePickerChange(date,'used_at')}
                    inputProps={{placeholder:'Pick a Used At', id:'formUsedDate'}}
                  />
                  {errorMessage('used_at',this.state.error)}
                </FormGroup>
              </Col>
              <Col xs={12} md={6}>
                <FormGroup className={classnames({'has-danger has-feedback':hasError('Used',this.state.error)})}>
                  <Label for="formUsed">Used &nbsp;</Label>
                  <Radio
                    name="used"
                    id="formUsed"
                    value="true" checked={textValue('used', this.state)}
                    onChange={this.handleCheckedChange}
                  />
                  {errorMessage('used',this.state.error)}
                </FormGroup>
              </Col>
            </Row>
            :
            null
          }            
        </Form>
        <span className="text-sm">Fields marked with * are required</span><br/>
        <span className="text-sm">Notes when you not pass Start Date & End Date by default 3 month</span>
        <span>{this._renderError()}</span>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" disabled={this.state.isSubmit} onClick={this.onSubmit}>{this.state.isSubmit?'Submitting...':'Submit'}</Button>{' '}
          <Button color="secondary" onClick={this.props.onCancel}>Cancel</Button>
        </ModalFooter>
      </Modal>
    )
  }
}
ModalUserVoucher.propTypes={
  dataUserVouchers: PropTypes.object,
  dataAuth: PropTypes.object,
  userVoucherSelected: PropTypes.object,
  onGetUserVoucherList: PropTypes.func.isRequired,
  onRemoveSelected: PropTypes.func.isRequired,
  voucherId:PropTypes.number.isRequired,
  isOpen:PropTypes.bool,
  onSubmit:PropTypes.func,
  onCancel:PropTypes.func
}