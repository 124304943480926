import React from 'react';
import { Link } from 'react-router-dom';
import { Card, CardHeader, CardBody } from 'reactstrap';
import { _getValue, _getGenderString } from '../../utils/form';
import propTypes from 'prop-types';
import Loading from 'react-loading-animation';
import {
	FieldRow,
	FieldColumn,
	FieldValue,
	FieldLabel
} from '../../styled/Fields';
import {
	BadgeFlexContainer
} from '../../styled/Badge';

import Badge from '../Badges/BadgeThin';

const Detail = (props) => {
	return (
		<Card>
			<CardHeader>
				{props.headerText}
			</CardHeader>
			<CardBody>
				<Loading isLoading={props.isLoading}>
					{
						props.displayRole?
						<div style={{width:'100%',marginBottom: '1rem', fontSize:20}}>
							<BadgeFlexContainer>
								{
									props.user && props.user.roles && props.user.roles.sort((a, b)=> a.name > b.name).map(role => <Link style={{marginRight:5, fontSize: '1rem'}} to={`/roles/${role.id}`} key={`badge-user-link-${role.id}`}><Badge color="primary">{role.name}</Badge></Link>)
								}
							</BadgeFlexContainer>
						</div>
						:null
					}
					<FieldRow>
						<FieldColumn className="col-xs-12 col-md-6">
							<FieldLabel>ID</FieldLabel>
							<FieldValue>
								<span>{_getValue(props.user.id)}</span>
							</FieldValue>
						</FieldColumn>
						<FieldColumn className="col-xs-12 col-md-6">
							<FieldLabel>Balance</FieldLabel>
							<FieldValue>
								<span>{_getValue(props.user.balance)}</span>
							</FieldValue>
						</FieldColumn>
						<FieldColumn className="col-12">
							<FieldLabel>Email</FieldLabel>
							<FieldValue>
								<span>{_getValue(props.user.email)}</span>
							</FieldValue>
						</FieldColumn>
						<FieldColumn className="col-xs-12 col-md-6">
							<FieldLabel>Firstname</FieldLabel>
							<FieldValue>
								<span>{_getValue(props.user.first_name)}</span>
							</FieldValue>
						</FieldColumn>
						<FieldColumn className="col-xs-12 col-md-6">
							<FieldLabel>Lastname</FieldLabel>
							<FieldValue>
								<span>{_getValue(props.user.family_name)}</span>
							</FieldValue>
						</FieldColumn>
						<FieldColumn className="col-xs-12 col-md-6">
							<FieldLabel>Username</FieldLabel>
							<FieldValue>
								<span>{_getValue(props.user.username)}</span>
							</FieldValue>
						</FieldColumn>
						<FieldColumn className="col-xs-12 col-md-6">
							<FieldLabel>Gender</FieldLabel>
							<FieldValue>
								<span>{_getGenderString(props.user.sex)}</span>
							</FieldValue>
						</FieldColumn>
						<FieldColumn className="col-12">
							<FieldLabel>Updated At</FieldLabel>
							<FieldValue>
								<span >{_getValue(props.user.updated_at)}</span>
							</FieldValue>
						</FieldColumn>
					</FieldRow>
				</Loading>
			</CardBody>
		</Card>
	)
}
Detail.propTypes={
  isLoading: propTypes.bool,
  headerText: propTypes.oneOfType([propTypes.string, propTypes.node]),
	user:propTypes.object,
	displayRole: propTypes.bool
}
Detail.defaultProps={
  isLoading: false,  
	headerText: "User Details",
	displayRole: false,
	user:{
		id:null,
		email: null,
		roles: []
	}
}
export default Detail;