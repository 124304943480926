import React from 'react';
import { connect } from 'react-redux'
import reactCSS from 'reactcss'
import { Link } from 'react-router-dom'
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  ButtonGroup,
  Alert,
} from 'reactstrap';
import classNames from 'classnames';
import uuidv4 from 'uuid/v4';
import moment from 'moment-timezone';
import qs from 'query-string';
import { getRaffleGroupById, saveRaffleGroup, deleteRaffleGroupById } from '../../../actions/raffleAction';
import { FontAwesomeIcon } from '../../../components/Icons';
import { Radio, RadioText, FormGroupRadio } from '../../../components/Form/Radios';
import { ButtonLoading } from '../../../components/Button';
import { addErrorMessage, errorMessage, hasError ,  textValue } from '../../../utils/form';
import { ModalDelete, ModalBlockLoading as ModalLoading, ModalInfo, ModalEditImage } from '../../../components/Modals';
import { ImageFile } from '../../../components/Images';
import { parseRteValue, toolbarConfig } from '../../../config/rte';
import { DropzonePlusButton } from '../../../components/Form/DropzoneFile';
import { ImagesCompressor, createUploadFileForm } from '../../../utils/imageHelper';
import { isRequired } from '../../../helpers/regex';
import { SketchPicker, TwitterPicker } from 'react-color';
import { uploadImage } from '../../../actions/imageUploadAction';
import { isHexCode } from '../../../utils/regex';
import RaffleEntryList from './RaffleEntryList';
import RichTextEditor from 'react-rte';

const DEFAULT_IMAGE_ASPECT_LANDSCAPE = 16/5;
const addImageRawObject = (src, file, type) => {
  return {
    status: false,
    identifier: uuidv4(),
    fileInput: file,
    URL: src,
    isUploading: false,
    imageType: type
  }
}

class RaffleGroupForm extends React.Component{
  constructor(props){
    super(props)
    const DEFAULT_STATE = {
      activeTab:'raffle_groups',
    }
    const queries = Object.assign(DEFAULT_STATE,
      qs.parse(props.location.search)
    );
    this.state = {
      ...queries,
      _hasId: this.props.match.params.id?true:false,
      _form:{
        id: this.props.match.params.id||0,
        name: '',
        ticket_name: '',
        description: '',
        descriptionRte: parseRteValue(null),
        color: '',
        is_default: '',
        active: '',
        btn_img_url: '',
        oldBtnImgUrl: '',
        badge_img_url: '',
        oldBadgeImgUrl: '',
        ticket_img_url: '',
        oldTicketImgUrl: '',
      },
      _errorForm: null,
      _formSubmit: false,
      _alertErrorOpen: false,
      _alertSuccessOpen: false,
      _deleteConfirmationOpen: false,
      _rawImages: [],
      _modalInfo:{
        isOpen: false,
        modalBody: null
      },
      _modalEditImage: {
        isOpen: false,
        defaultImageRatio: DEFAULT_IMAGE_ASPECT_LANDSCAPE,
        imageSourceEdits: [],
        purpose: "default"
      },
      events: null,
      isReadOnly: false,
      background: "#fff",
      colors: ['#a67c00', '#FCB900', '#7BDCB5', '#00D084', '#8ED1FC', '#0693E3', '#ABB8C3', '#EB144C', '#F78DA7', '#FF6900'],
      displayColorPicker: false,
      color: {
        r: '241',
        g: '112',
        b: '19',
        a: '1',
      },
    }
    moment.tz('Asia/Jakarta');
    this.handleChangeEditor=this.handleChangeEditor.bind(this);
  }

  isEmptyRteValue = rteHtml => {
    return rteHtml === '<p><br></p>'
  }

  componentDidMount(){
    let raffleGroupId = null;
    if(this.props.match.params.id){
      raffleGroupId = this.props.match.params.id;
    }
    if(raffleGroupId){
      this.props.getRaffleGroupById(raffleGroupId)
      const { roles } = this.props.injectedProps;
      const isReadOnly = !['superadministrator', 'administrator'].map(item => roles.includes(item))
        .some(value => value);
      this.setState({
        isReadOnly
      })
    }
  }

  componentDidUpdate(prevProps){
    if(prevProps.dataRaffleGroup.isFetch&&!this.props.dataRaffleGroup.isFetch){
      const { data, error, status_code } = this.props.dataRaffleGroup;
      if(error){
        if(error.status_code === 404)
          this.props.history.replace('/404')
      } else if(status_code === 200){
        if(!this.props.match.params.id){
          this.props.history.replace(`/raffle_group/${data.id}`)
        }
        this.setState({
          ...this._bindRafflePropsToState(data)
        });
      }
    }

    if(prevProps.dataUploaded.isUpload !== this.props.dataUploaded.isUpload
      && !this.props.dataUploaded.isUpload){
      const { success, error } = this.props.dataUploaded;
      if(error){
        const { id } = this.state._form;
        if(!this.props.match.params.id){
          this.props.history.replace(`/raffle_group/${id}`)
          return;
        }
        this.setState({
          events: null,
          _formSubmit: false,
          _alertSuccessOpen: false,
          _alertErrorOpen: true,
          _errorMessage: this.translateMessage(500, 'Error occurred when uploading your image.')
        })
      } else{
        if(success.data){
          const { _rawImages, _form } = this.state;
          let btnImgUrl= _form.btn_img_url;
          let badgeImgUrl=_form.badge_img_url;
          let ticketImgUrl=_form.ticket_img_url;
            _rawImages.map((item, index)=> {
              const imgUpload=success.data.data[index];
              if(item.identifier === imgUpload.identifier){
                switch (item.imageType) {
                  case 'btn_img_url':
                    btnImgUrl=imgUpload.url
                    break;
                  case 'badge_img_url':
                    badgeImgUrl=imgUpload.url
                    break;
                  case 'ticket_img_url':
                    ticketImgUrl=imgUpload.url
                    break;
                  default:
                    break;
                }
              }
            })
            this.setState({
              _form:{
                ...this.state._form,
                btn_img_url:btnImgUrl,
                badge_img_url:badgeImgUrl,
                ticket_img_url:ticketImgUrl
              }
            },()=>{
              this._submitData();
            })
        }
      }
    }

    if(prevProps.dataRaffleGroup.isSubmit && !this.props.dataRaffleGroup.isSubmit){
      const { data, error, status_code, message } = this.props.dataRaffleGroup;
      if(error){
        this.setState({
          _formSubmit: false,
          _errorForm: {
            ...error
          },
          events: null,
          _alertSuccessOpen: false,
          _alertErrorOpen: true,
          _errorMessage: this.translateMessage(error.status_code, error.message)
        })
      }
      else if(status_code === 200){
        const { events } = this.state;
        if(data.deleted)
          this.props.history.replace(`/raffle_group`)
        if(events === null && !this.props.match.params.id){
          this.props.history.replace(`/raffle_group/${data.id}`)
        }
        const { _form: dataForm } = this._bindRafflePropsToState(data);
        this.setState({
          _form: {
            ...dataForm,
            id: data.id,
            img_url: this.state._rawImage? this.state._rawImage.URL: dataForm.img_url
          },
          _formSubmit: false,
          _errorForm: null,
          _alertErrorOpen: false,
          _alertSuccessOpen: events === null,
          _rawImages:[],
          _successMessage: this.translateMessage(status_code, message),
        });
      }
    }
  }

  _bindRafflePropsToState = (data = null) => {
    const { data: dataProps } = this.props.dataRaffleGroup;
    const dataApi = data? data: dataProps;
    const descriptionRte = parseRteValue(dataApi.description);
    const body = {
      id:dataApi.id,
      name: dataApi.name,
      ticket_name: dataApi.name,
      descriptionRte,
      description: dataApi.description,
      color: dataApi.color,
      is_default: dataApi.is_default,
      active: dataApi.active,
      btn_img_url: dataApi.id? dataApi.btn_img_url : dataApi.btn_signed_url,
      badge_img_url: dataApi.id? dataApi.badge_img_url: dataApi.badge_signed_url,
      ticket_img_url: dataApi.id? dataApi.ticket_img_url : dataApi.ticket_signed_url,
      created_at: dataApi.created_at,
      updated_at: dataApi.updated_at
    }
    const rgba = this.hexToRgbA(dataApi.color);
    return {
      _form:{
        ...this.state._form,
        ...body
      },
      color: rgba || { r: 255, g: 255, g: 255, a: 1} //set default to white color if null
    }
  }

  _hasInputError = name => {
    const { _errorForm } = this.state;
    return _errorForm && _errorForm.errors && hasError(name, _errorForm)
  }

  _handleInputChange = ({ target: { name, value, checked, type } }) => {
    this.setState({
      _form:{
        ...this.state._form,
        [name]: type === 'checkbox'? checked: value
      }
    })
  }

  handleChangeEditor(value, name) {
    let newState = {...this.state._form};
    switch (name) {
      case "description":
        newState = {descriptionRte: value, description:value.toString("html")}
        break;
      default:
        break;
    }
    this.setState({_form:{...this.state._form, ...newState}})
  }

  _renderErrorMessage = name => {
    const { _errorForm } = this.state;
    return errorMessage(name, _errorForm)
  }

  _handleModalDeleteToggle = () => {
    const { _formSubmit } = this.state;
    const { injectedProps: {roles} } = this.props;
    if(!_formSubmit){
      if(!roles.includes('superadministrator')){
        const modalBody =  "Sorry, only super administrator can delete the raffle.";
        this.setState({
          _modalInfo: {
            ...this.state._modalInfo,
            modalBody,
            isOpen: true
          }
        })
        return;
      }
      this.setState({
        _deleteConfirmationOpen: !this.state._deleteConfirmationOpen
      })
    }
  }

  _handleDropzoneChange = (files, type) => {
    if(files.length){
      let rawFiles = [];
      const [file] = files;
      let { _form, _modalEditImage, _rawImages } = this.state;
      rawFiles.push({
        ...addImageRawObject(file.preview, file, type)
      })
      if(_rawImages.length>0){
        if(_rawImages.filter(item => item.imageType === type).length)
          _rawImages=[];
      }
      let modalEditImage = _modalEditImage;
      if(this.props.useCrop){
        modalEditImage = {
          ..._modalEditImage,
          isOpen: true,
          imageSourceEdits: [{
            imgId: rawFiles[0].identifier,
            src: rawFiles[0].URL
          }]
        }
      }
      this.setState({
        _form: {
          ..._form,
          [type]: file.preview
        },
        _rawImages: [..._rawImages, ...rawFiles],
        _modalEditImage: {
          ...modalEditImage,
        }
      });
    }
  }

  translateMessage = (statusCode, message) => {
    let messageStr = ''
    switch(statusCode){
      case 200: messageStr = 'Success...'; break;
      case 422: messageStr = 'Please fullfill your form.'; break;
      case 404: messageStr = 'Resource not found.'; break;
      case 500: messageStr = "We've got something errors"; break;
      default: messageStr = message; break;
    }
    return messageStr
  }

  _validateForm = () => {
    const { _form: dataForm } = this.state;
    let errors={};
    if(!isRequired(dataForm.name)){
      errors['name'] = addErrorMessage(errors, 'name', 'The name field is required.');
    }
    let errorState = null;
    if(Object.keys(errors).length){
      errorState = {
        errors: { ...errors },
        status_code: 422,
        message: 'Please full fill form'
      }
      this.setState({
        _formSubmit:false,
        _errorForm: errorState? {...errorState}: null,
        _errorMessage: errorState? errorState.message: null,
        _alertErrorOpen: true,
        _alertSuccessOpen: false,
      })
    }
    return Object.keys(errors).length>0;
  }

  _compressImage = async (image) => {
    let fileInput = image
    try{
      //compress file
      fileInput = await ImagesCompressor(image);
    }
    catch(e){
      alert('error while compress the images')
      fileInput = image
    }
    return fileInput;
  }

  _submitData = async() => {
    const { _form: { voucher_id, ...dataForm }} = this.state;
    let payloads = {
      id:dataForm.id,
      name: dataForm.name,
      ticket_name: dataForm.ticket_name,
      description: this.isEmptyRteValue(dataForm.description) ? null: dataForm.description,
      color: dataForm.color,
      is_default: dataForm.is_default,
      active: dataForm.active,
      btn_img_url: dataForm.btn_img_url,
      badge_img_url: dataForm.badge_img_url,
      ticket_img_url: dataForm.ticket_img_url
    }
    this.props.saveRaffleGroup(payloads)
  }

  _handleSubmitForm = (e) => {
    e.preventDefault();
    this.setState({_formSubmit:true, _errorForm:null,
      _alertErrorOpen:false,_errorMessage:null}, ()=>{
      if(!this._validateForm()){
        if(this.state._rawImages.length)
          this._handleUploadImages()
        else
          this._submitData()
      }
    })
  }

  _compressImgsProgress = async () => {
    const { _rawImages } = this.state;
    const rawImagesPromises = _rawImages.map(async(r, key) => {
      const fileInput =  await this._compressImage(r.fileInput)
      return {
        ...r,
        is_uploading: key === 0,
        fileInput
      }
    });
    return await Promise.all(rawImagesPromises)
  }

  _handleUploadImages = async e => {
    const compressImages= await this._compressImgsProgress()
    const tempPayload = createUploadFileForm(compressImages.map(r => r.fileInput), true, {name: "raffle-groups-images/", bucket_type: "assets"});
    this.props.uploadImage(tempPayload, compressImages);
  };

  _renderLoading(){
    const { _formSubmit } = this.state;
    if(!_formSubmit) return null;
    return (
      <Row>
        <Col xs={12}>
          <Alert color="info">
            <strong>Heads up!</strong> Submitting your data...
          </Alert>
        </Col>
      </Row>
    )
  }


  onCropSucceeded = async (blob, ratio) => {
    const blobUrl = URL.createObjectURL(blob);
    let fileName = blobUrl.substr(blobUrl.lastIndexOf('/')+1)
    if(fileName == '') fileName = 'local'
    const { _modalEditImage, _form, _rawImages } = this.state;
    if(_modalEditImage.purpose === 'default'){
      let identifier = 0;
      if(_modalEditImage.imageSourceEdits.length)
        identifier = _modalEditImage.imageSourceEdits[0].imgId;
      const newRawImages = _rawImages.map(item => {
        if(item.identifier === identifier){
          return {
            ...item,
            fileInput: new File([blob], `${fileName}.jpeg`, {
              type: 'image/jpeg'
            }),
            URL: blobUrl,
            orientation: ratio.ratio <=1? 'portrait': 'landscape'
          }
        }
        return item;
      });
      this.setState({
        _form: {
          ..._form,
          img_url: blobUrl
        },
        _rawImages: [
          ...newRawImages,
        ],
        _modalEditImage: {
          ..._modalEditImage,
          isOpen: false,
          imageSourceEdits: []
        }
      })
    }
  }

  _onActiveTabChange = id => {
    this.setState({activeTab:id}, ()=>{
      const { activeTab } = this.state;
      const { pathname } = this.props.location;
      const search = qs.stringify({
        activeTab
      });
      this.props.history.replace({ pathname, search })
    });
  }

  onChangeComplete = (color) => {
    this.setState({
      background: color.hex
    })
  }

  handleClick = (e, selectedPicker) => {
    e.preventDefault();
    this.setState({ displayColorPicker: selectedPicker })
  };

  handleClose = () => {
    this.setState({ displayColorPicker: false })
  };

  handleColorChange = (color, e) => {
    this.setState({
      color:color.rgb,
      _form: {
        ...this.state._form,
        color: color.hex
      }
    })
  };

  _handleDeleteRaffleGroup = () =>{
    const { _form: { id, _formSubmit } } = this.state;
    if(!_formSubmit){
      this.setState({
        _formSubmit: !_formSubmit,
      })
      this.props.deleteRaffleGroupById(id);
    }
    this._handleModalDeleteToggle();
  }

  hexToRgbA = (hex) => {
    // let c;
    // if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)){
    //   c= hex.substring(1).split('');
    //   if(c.length== 3){
    //     c= [c[0], c[0], c[1], c[1], c[2], c[2]];
    //   }
    //   c= '0x'+c.join('');
    //   return 'rgba('+[(c>>16)&255, (c>>8)&255, c&255].join(',')+',1)';
    // }
    // throw new Error('Bad Hex');
    if(isHexCode(hex)){
      hex = hex.substring(1) //remove '#' char
      hex = '0x' + hex.trim();
      const r = hex >> 16 & 0xFF
      const g = hex >> 8 & 0xFF
      const b = hex & 0xFF
      return {
        r, g, b, a: 1
      }
    }
    return null
  }

  handleColorTextChange = e => {
    const str = e.target.value.trim()
    const colorRgb = this.hexToRgbA(str)
    this.setState({
      _form:{
        ...this.state._form,
        color: str
      },
      color: colorRgb || this.state.color
    })
  }

  render(){
    const styles = reactCSS({
      'default': {
        color: {
          width: '1rem',
          height: '14px',
          borderRadius: '2px',
          background: `rgba(${ this.state.color.r }, ${ this.state.color.g }, ${ this.state.color.b }, ${ this.state.color.a })`,
        },
        swatch: {
          margin: "auto .5rem",
          padding: '5px',
          background: '#fff',
          borderRadius: '1px',
          boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
          display: 'inline-block',
          cursor: 'pointer',
        },
        popover: {
          position: 'absolute',
          zIndex: '2',
          top: '2px',
        },
        cover: {
          position: 'fixed',
          top: '0px',
          right: '0px',
          bottom: '0px',
          left: '0px',
        },
      },
    });
    return(
      <div className="animated fadeIn">
        <ModalDelete
          isOpen={this.state._deleteConfirmationOpen}
          modalTitle="Confirmation."
          modalBody="Are you sure to delete this?"
          onDelete={this._handleDeleteRaffleGroup}
          toggle={this._handleModalDeleteToggle}
        />
        <ModalInfo
          modalTitle="Info"
          {...this.state._modalInfo}
          toggle={() => {
            const { _modalInfo } = this.state;
            this.setState({ _modalInfo: { ..._modalInfo, isOpen: !_modalInfo.isOpen } })
          }}
        />
        <ModalLoading isOpen={this.state.events!==null}/>
        <ModalEditImage
          isOpen={this.state._modalEditImage.isOpen}
          imageSource={this.state._modalEditImage.imageSourceEdits.length? this.state._modalEditImage.imageSourceEdits[0].src: null}
          showGrid
          ratio={this.state._modalEditImage.defaultImageRatio}
          onCropSucceeded={this.onCropSucceeded}
        />
        <Row>
          <Col xs={12} md={{size:6, offset:3}}>
            <Card>
              <CardHeader>
                <FontAwesomeIcon iconType="align-justify"/> {this.props.title}
                {this.props.edit?
                  <div>
                    <Link style={{fontSize: '.75rem'}} to="/raffle_group/create">Create new Raffle Group</Link>
                  </div>
                :null}
              </CardHeader>
              <CardBody>
                <Form onSubmit={this._handleSubmitForm}>
                  {this._renderLoading()}
                  <Row>
                    <Col xs={12}>
                      <Alert color="danger" isOpen={this.state._alertErrorOpen} toggle={() => this.setState({_alertErrorOpen: false})}>
                        <strong>Oh Snap!</strong> { this.state._errorMessage }
                      </Alert>
                    </Col>
                    <Col xs={12}>
                      <Alert color="success" isOpen={this.state._alertSuccessOpen} toggle={() => this.setState({_alertSuccessOpen: false})}>
                        <strong>Done!</strong> { this.state._successMessage }
                      </Alert>
                    </Col>
                  </Row>
                <Row>
                  <Col xs={12} sm={12}>
                    <FormGroup
                      className={classNames({ 'has-danger has-feedback': this._hasInputError('name') })}
                    >
                      <Label>Raffle Group Name</Label>
                      <Input
                        readOnly={this.state.isReadOnly}
                        placeholder="Enter a name"
                        name="name"
                        type="text"
                        value={textValue('name', this.state._form)}
                        onChange={this._handleInputChange}
                      />
                      { this._renderErrorMessage('name') }
                    </FormGroup>
                  </Col>
                  <Col xs={12} sm={12}>
                    <FormGroup
                      className={classNames({ 'has-danger has-feedback': this._hasInputError('name') })}
                    >
                      <Label>Raffle Color</Label>
                      <Col className="d-flex" style={{padding:0}}>
                        <Input
                          placeholder="Hex value..."
                          style={{width:'20%'}}
                          name="color"
                          type="text"
                          value={textValue('color', this.state._form)}
                          onChange={this.handleColorTextChange}
                        />
                        <div style={ styles.swatch } onClick={ e => this.handleClick(e, "sketch") }>
                          <div style={ styles.color } />
                        </div>
                        <button type="button" onClick={ e => this.handleClick(e, "twitter") }>Pick Color</button>
                        { this._renderErrorMessage('color') }
                      </Col>
                      <div style={{position: 'relative'}}>
                        { this.state.displayColorPicker ?
                        <div style={ styles.popover }>
                          <div style={ styles.cover } onClick={ this.handleClose }/>
                            {this.state.displayColorPicker === 'sketch'?(
                              <SketchPicker
                                name="color"
                                color={ this.state.color }
                                onChange={ this.handleColorChange }
                                value={this.state._form.color}
                              />
                            ): (
                              <TwitterPicker
                                name="color"
                                color={ this.state.color }
                                onChange={ this.handleColorChange }
                                value={this.state._form.color}
                              />
                            )}
                          </div> : null }
                      </div>
                    </FormGroup>
                  </Col>
                  <Col xs={12} sm={12}>
                    <FormGroup
                      className={classNames({ 'has-danger has-feedback': this._hasInputError('ticket_name') })}
                    >
                      <Label>Raffle Ticket Name</Label>
                      <Input
                        readOnly={this.state.isReadOnly}
                        placeholder="Enter a name"
                        name="ticket_name"
                        type="text"
                        value={textValue('ticket_name', this.state._form)}
                        onChange={this._handleInputChange}
                      />
                      { this._renderErrorMessage('ticket_name') }
                    </FormGroup>
                  </Col>
                  <div className="w-100"></div>
                  <Col xs={12} sm={6}>
                    <FormGroupRadio
                      className={classNames({ 'has-danger has-feedback': this._hasInputError('is_default') })}
                    >
                      <Radio
                        disabled={this.state.isReadOnly}
                        type="checkbox"
                        name="is_default"
                        value={true}
                        checked={textValue('is_default', this.state._form)}
                        dataOnText="Yes"
                        dataOffText="No"
                        onChange={this._handleInputChange}
                      />
                      <RadioText text="Default"/>
                      { this._renderErrorMessage('is_default') }
                    </FormGroupRadio>
                  </Col>
                  <Col xs={12} sm={6}>
                    <FormGroupRadio
                      className={classNames({ 'has-danger has-feedback': this._hasInputError('active') })}
                    >
                      <Radio
                        disabled={this.state.isReadOnly}
                        type="checkbox"
                        name="active"
                        value={true}
                        checked={textValue('active', this.state._form)}
                        dataOnText="Yes"
                        dataOffText="No"
                        onChange={this._handleInputChange}
                      />
                      <RadioText text="Set to Active"/>
                      { this._renderErrorMessage('active') }
                    </FormGroupRadio>
                  </Col>
                  <Col xs={12} sm={12}>
                    <FormGroup
                      className={classNames({ 'has-danger has-feedback': this._hasInputError('description') })}
                    >
                      <Label>Description</Label>
                      <RichTextEditor
                        readOnly={this.state.isReadOnly}
                        value={this.state._form.descriptionRte}
                        onChange={(value) => this.handleChangeEditor(value, "description")}
                        toolbarConfig={toolbarConfig}
                      />
                      { this._renderErrorMessage('description') }
                    </FormGroup>
                  </Col>
                </Row>
                <FormGroup
                  row
                  className={classNames({ 'has-danger has-feedback': this._hasInputError('btn_img_url') })}
                >
                  <Col sm={12}>
                    <Label>Image Button</Label>
                  </Col>
                  <Col className="d-flex">
                    <ImageFile
                      style={{padding: 0}}
                      alt="Raffle's image banner"
                      src={this.state._form.btn_img_url? this.state._form.btn_img_url : null}
                      showDelete={this.state._rawImages.filter(item => item.imageType==='btn_img_url').length}
                      renderEmptyImageText={() => (
                        <p className="mb-0 text-center">
                          No Image <br/>
                          Size: 1280 x 400 in pixels
                        </p>
                      )}
                      onDelete={() => {
                        const { _form, _rawImages } = this.state;
                        this.setState({
                          _form: {
                            ..._form,
                            btn_img_url: this.state._form.oldBtnImgUrl
                          },
                          _rawImages: _rawImages.filter(item => item.imageType!=='btn_img_url')
                        })
                      }}
                    />
                    <div className={classNames('ml-3', {'d-none': this.state.isReadOnly})}>
                      <DropzonePlusButton mulitple={false} onDrop={files => this._handleDropzoneChange(files, 'btn_img_url')}/>
                    </div>
                  </Col>
                  <Col xs={12}>
                    { this._renderErrorMessage('btn_img_url') }
                  </Col>
                </FormGroup>
                <FormGroup
                  row
                  className={classNames({ 'has-danger has-feedback': this._hasInputError('badge_img_url') })}
                >
                  <Col sm={12}>
                    <Label>Image Badge</Label>
                  </Col>
                  <Col className="d-flex">
                    <ImageFile
                      style={{padding: 0}}
                      alt="Raffle's image banner"
                      src={this.state._form.badge_img_url? this.state._form.badge_img_url : null}
                      showDelete={this.state._rawImages.filter(item => item.imageType==='badge_img_url').length}
                      renderEmptyImageText={() => (
                        <p className="mb-0 text-center">
                          No Image <br/>
                          Size: 1280 x 400 in pixels
                        </p>
                      )}
                      onDelete={() => {
                        const { _form, _rawImages } = this.state;
                        this.setState({
                          _form: {
                            ..._form,
                            badge_img_url: this.state._form.oldBadgeImgUrl
                          },
                          _rawImages: _rawImages.filter(item => item.imageType!=='badge_img_url')
                        })
                      }}
                    />
                    <div className={classNames('ml-3', {'d-none': this.state.isReadOnly})}>
                      <DropzonePlusButton mulitple={false} onDrop={files =>this._handleDropzoneChange(files, 'badge_img_url')}/>
                    </div>
                  </Col>
                  <Col xs={12}>
                    { this._renderErrorMessage('badge_img_url') }
                  </Col>
                </FormGroup>
                <FormGroup
                  row
                  className={classNames({ 'has-danger has-feedback': this._hasInputError('ticket_img_url') })}
                >
                  <Col sm={12}>
                    <Label>Image Ticket</Label>
                  </Col>
                  <Col className="d-flex">
                    <ImageFile
                      style={{padding: 0}}
                      alt="Raffle's image banner"
                      src={this.state._form.ticket_img_url? this.state._form.ticket_img_url : null}
                      showDelete={this.state._rawImages.filter(item => item.imageType==='ticket_img_url').length}
                      renderEmptyImageText={() => (
                        <p className="mb-0 text-center">
                          No Image <br/>
                          Size: 1280 x 400 in pixels
                        </p>
                      )}
                      onDelete={() => {
                        const { _form, _rawImages } = this.state;
                        this.setState({
                          _form: {
                            ..._form,
                            ticket_img_url: this.state._form.oldTicketImgUrl
                          },
                          _rawImages:_rawImages.filter(item => item.imageType!=='ticket_img_url')
                        })
                      }}
                    />
                    <div className={classNames('ml-3', {'d-none': this.state.isReadOnly})}>
                      <DropzonePlusButton mulitple={false} onDrop={files=>this._handleDropzoneChange(files, 'ticket_img_url')}/>
                    </div>
                  </Col>
                  <Col xs={12}>
                    { this._renderErrorMessage('ticket_img_url') }
                  </Col>
                </FormGroup>
                {
                  this.state._hasId?(
                    <Row>
                      <Col xs={12} md={6}>
                        <FormGroup>
                          <Label>Created</Label>
                          <Input
                            readOnly
                            name="created_at"
                            type="text"
                            value={textValue('created_at', this.state._form)}
                          />
                        </FormGroup>
                      </Col>
                      <Col xs={12} md={6}>
                        <FormGroup>
                          <Label>Updated</Label>
                          <Input
                            readOnly
                            name="updated_at"
                            type="text"
                            value={textValue('updated_at', this.state._form)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  ):null
                }
                {
                  this.state._errorForm?(
                    <p className="text-danger">Opps. Please check your form!</p>
                  ): null
                }
                <FormGroup className={classNames({'d-none': this.state.isReadOnly})} row>
                  <Col>
                    <ButtonGroup>
                      <ButtonLoading
                        disabled={this.state._formSubmit}
                        isLoading={this.state._formSubmit}
                        loadingMessage="Submitting..."
                        color="primary"
                      >
                        Submit
                      </ButtonLoading>
                      {
                        this.state._hasId?(
                          <ButtonLoading
                            disabled={this.state._formSubmit}
                            isLoading={this.state._formSubmit}
                            loadingMessage="Deleting..."
                            onClick={this._handleModalDeleteToggle}
                            color="danger"
                          >
                            Delete
                          </ButtonLoading>
                        )
                        :null
                      }
                    </ButtonGroup>
                  </Col>
                </FormGroup>
              </Form>
              </CardBody>
            </Card>
          </Col>
          {this.state._hasId && 
            <Col>
              <RaffleEntryList raffleGroupId={this.state._form.id} {...this.props} />
            </Col>
          }
        </Row>
      </div>
    )
  }
}

const mapStateToProps=({ raffle, imageUpload, auth: dataAuth })=>({
  dataAuth,
  dataRaffle: raffle.details,
  dataRaffleGroup: raffle.detailsGroup,
  dataUploaded: imageUpload
})
const mapDispatchToProps=(dispatch)=>({
  deleteRaffleGroupById: id => dispatch(deleteRaffleGroupById(id)),
  saveRaffleGroup: (payloads) => dispatch(saveRaffleGroup(payloads)),
  getRaffleGroupById: id => dispatch(getRaffleGroupById(id)),
  uploadImage: (payload, rawImages) => dispatch(uploadImage(payload, rawImages)),
})

export default connect(mapStateToProps, mapDispatchToProps)(RaffleGroupForm)