import React from 'react';
import propTypes from 'prop-types';
import { Badge } from 'reactstrap';
import { Link } from 'react-router-dom';
import BadgeThin from '../../../components/Badges/BadgeThin';
import { FontAwesomeIcon } from '../../../components/Icons';
import Paragraph from '../../../styled/Typograph/Paragraph';
import SmallInline from '../../../styled/Typograph/Small/SmallInline';

const OrderSummariesColumn = props => {
  const { id, name, userId, userEmail, phoneNumber, productName, condition, condition2, createdAt } = props;
  return (
    <Paragraph>
      <SmallInline>#{`${id} `}</SmallInline>
      <SmallInline><i className="fa fa-calendar"></i> { createdAt }</SmallInline> 
      &nbsp;{<Badge color="primary">{name}</Badge>}<br/>
      { productName } ({ condition && condition2 ? condition + ' | ' + condition2 : condition })<br/>
      <SmallInline>({ phoneNumber })</SmallInline>
      <BadgeThin>
        <FontAwesomeIcon className="mr-1" iconType="user"/>
        {userId? <Link style={{color: '#fff', textDecoration: 'underline'}} className="link-default" to={`/users/${userId}`}>{userEmail}</Link>: ' - '}
      </BadgeThin>
    </Paragraph>
  )
}

export default OrderSummariesColumn;

OrderSummariesColumn.defaultProps= {
  name: '',
  userEmail: '',
  productName: '',
}

OrderSummariesColumn.propTypes = {
  id: propTypes.number,
  name: propTypes.string,
  userId: propTypes.number,
  userEmail: propTypes.string,
  phoneNumber: propTypes.string,
  productName: propTypes.string,
  condition: propTypes.string,
  condition2: propTypes.string,
  createdAt: propTypes.string
}