import React from 'react';
import {
  Card,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane
} from 'reactstrap';
import classNames from 'classnames';

import RaffleEntryList from './RaffleEntryList';
import RaffleCouponList from './RaffleCouponList';

class TabEntryCoupon extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 'entries'
    }
  }

  _onActiveTabChange = activeTab => {
    this.setState({
      activeTab
    })
  }

  render(){
    return(
      <div>
        <Nav className="nav-tabs--custom" tabs>
          <NavItem>
            <NavLink
              className={classNames({ active: this.state.activeTab === 'entries' })}
              onClick={() => this._onActiveTabChange('entries') }
            >
              Raffle Entries
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classNames({ active: this.state.activeTab === 'coupons' })}
              onClick={() => this._onActiveTabChange('coupons') }
            >
              Raffle Coupons
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent className="tab-content--custom" activeTab={this.state.activeTab}>
          <TabPane className="tab-content--custom__tab-pane" tabId="entries">
            <Card body className="tab-content--custom__card">
              <RaffleEntryList raffleId={this.props.raffleId}/>
            </Card>
          </TabPane>
          <TabPane className="tab-content--custom__tab-pane" tabId="coupons">
            <Card body className="tab-content--custom__card">
              <RaffleCouponList raffleId={this.props.raffleId}/>
            </Card>
          </TabPane>
        </TabContent>
      </div>
    )
  }
}
export default TabEntryCoupon;