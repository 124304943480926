import React from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
	Button,
	Label,
	Input,
	// Badge,
	FormGroup,
  ModalFooter
} from 'reactstrap';
import styled from 'styled-components';
import propTypes from 'prop-types';
import { textValue, errorMessage } from '../../utils/form';
// import { SimpleLineIcon, FontAwesomeIcon } from '../../components/Icons';
// import moment from 'moment-timezone';
import classNames from 'classnames';
import { isNumeric, isRequired } from '../../helpers/regex';
import { convertToRupiah } from '../../utils';
import { ModalConfirmation } from '../../components/Modals';
import { ButtonLoading } from '../../components/Button';
import moment from 'moment-timezone';

const FooterBtn = styled(ButtonLoading)`
  &.disabled{
    cursor: default;
  }
  &.btn-secondary.disabled {
    background: #ddd;
  }
  &.btn-secondary.disabled {
    background: #ddd;
  }
`;

export default class ModalDollarRate extends React.Component{
	constructor(props){
		super(props);
    this.state = {
      _form: {
        conversionRate: 16500
      },
      error: null,
      isSubmit: false,
      isConfirmationOpen: false
    }
    this._handleChange = this._handleChange.bind(this);
		this._onSubmit = this._onSubmit.bind(this);
	}

	componentDidUpdate(prevProps){
		if(!prevProps.isOpen && this.props.isOpen){
      const { data } = this.props.data;
			this.setState({
				_form:{
					conversionRate: data? data.value: ''
				},
				error: null
			})
    }
    if(prevProps.data.isSubmit && !this.props.data.isSubmit){
      const { error } = this.props.data;
      if(error){
        let errorLocal = error;
        if(error.status_code === 422){
          if(errorLocal.errors.conversion_rate){
            errorLocal = {
              ...error,
              errors: Object.keys(errorLocal.errors).filter(key => key !== 'conversion_rate')
              .reduce((prev, curr)=>{
                return {
                  ...prev,
                  [curr]: error.errors[curr]
                }
              }, {})
            }
            errorLocal.errors.conversionRate = [...error.errors.conversion_rate]
          }
        }
        this.setState({
          isSubmit: false,
          error: errorLocal
        })
      } else{
        const { data } = this.props.data;
        this.setState({
          isSubmit: false,
          _form:{
            conversionRate: data? data.value: ''
          },
          error: null
        })
        if(this.props.toggle && this.props.isOpen)
          this.props.toggle()
      }
    }
  }

	_handleChange(e){
    const { _form } = this.state;
    _form[e.target.name] = e.target.value;
    this.setState({ _form, error: null });
  }

  _validateForm = async() => {
    let errors = {};
    const { _form: { conversionRate } } = this.state;
    if(!isRequired(conversionRate) || conversionRate <= 0){
      errors = {
        ...errors,
        conversionRate: ['The conversion rate field is required.']
      }
    } else if(!isNumeric(conversionRate)){
      errors = {
        ...errors,
        conversionRate: ['The conversion rate field must be a number.']
      }
    }
    let p = null;
    if(Object.keys(errors).length){
      p = { errors: { ...errors }}
    }
    await this.setState({
      error: p
    })
  }

  _renderErrorMessage = name => {
    const { error } = this.state;
    return errorMessage(name, error)
  }

	async _onSubmit(e){
    e.preventDefault();
    // await this._validateForm();
    const { error } = this.state;
    if(error) return;
    this.confirmationToggle();
  }

  onConfirmClick = () => {
    this.confirmationToggle();
    let { conversionRate } = this.state._form;
    conversionRate = Number(conversionRate.trim());
    this.setState({
      isSubmit: true,
      _form: {
        ...this.state._form,
        conversionRate
      }
    });
    this.props.onSubmit({
      conversion_rate: conversionRate
    })
  }

  checkFormChanged = () => {
    if(!this.props.data.data) return false;
    const { conversionRate } = this.state._form;
    let { value: propsConversionRate } = this.props.data.data;
    propsConversionRate = !propsConversionRate? '': propsConversionRate;
    return conversionRate !== propsConversionRate;
  }

  confirmationToggle = () => {
    if(this.state.isSubmit) return;
    this.setState({
      isConfirmationOpen: !this.state.isConfirmationOpen
    });
  }

  render(){
    return(
      <div>
        <ModalConfirmation
          isOpen={this.state.isConfirmationOpen}
          onSubmit={this.onConfirmClick}
          toggle={this.confirmationToggle}
        />
        <Modal size="sm" className="modal-confirmation" isOpen={this.props.isOpen} toggle={this.props.toggle}>
          <ModalHeader tag="p" toggle={this.props.toggle} className="modal-header--left">
            Dollar Conversion Rate
          </ModalHeader>
          <ModalBody>
            {
              this.props.data && this.props.data.data?(
                <p>
                  IDR {this.props.data.data? convertToRupiah(this.props.data.data.value): '-'}<br/>
                  Last Updated: {moment(this.props.data.data.updated_at).format('DD/MM/YYYY HH:mm')}
                </p>
              ): null
            }
            <FormGroup className={classNames({'has-danger has-feedback': this.state.error !== null})}>
              <Label for="conversionRate">
                Conversion Rate
              </Label>
              <Input
                id="conversionRate"
                name="conversionRate"
                value={textValue('conversionRate',this.state._form)}
                onChange={this._handleChange}
              />
              { this._renderErrorMessage('conversionRate') }
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <FooterBtn
              disabled={this.state.isSubmit || !this.checkFormChanged()}
              isLoading={this.state.isSubmit}
              loadingMessage="Updating..."
              color="primary"
              onClick={this._onSubmit}
            >
              Update
            </FooterBtn>
            {/* <Button color="primary" onClick={this._onSubmit}>Update</Button> */}
          </ModalFooter>
        </Modal>
      </div>
    )
  }
}
ModalDollarRate.propTypes = {
  // status: propTypes.string,
  // markedNotes: propTypes.string,
  // markedAt: propTypes.string,
  isOpen: propTypes.bool,
  toggle: propTypes.func,
  onSubmit: propTypes.func.isRequired
}
ModalDollarRate.defaultProps = {
  // modalBody: 'Are you sure?',
  isOpen: false
}