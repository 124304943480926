import React from 'react';
import propTypes from 'prop-types';
import { Link } from 'react-router-dom'
import styled from 'styled-components';
import { Badge } from 'reactstrap';
import classNames from 'classnames';
import FontAwesomeIcon from '../../../components/Icons/FontAwesomeIcon';
import ButtonChangeSeller from '../RowItem/ButtonChangeSeller';
import {
  CANCELLED_STATUS,
	KA_RECEIVED_STATUS,
	VERIFICATION_PASSED_STATUS,
	VERIFICATION_FAILED_STATUS,
  DELIVERING_STATUS,
  REFUNDED_STATUS,
  getStatusSales
} from '../../../constants/kickavenue';

import { isCompletedSale } from '../../../helpers/regex';
import { saleMenus } from '../../../utils/authorization';

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  padding-bottom: 1rem;
`;

const ContentInvoice = styled.div`
  flex: 1;
  > p {
    font-size: 18px;
    margin-bottom: 0px;
    ~ p {
      font-size: 10px;
    }
  }
`;

const ContentStatus = styled.div`
  font-size: 18px;
  flex: 1;
  text-align: right;
`;

const getColorStatus = status => {
  let color = 'warning';
  switch (status) {
    case 'KA_RETURNED_SELLER':
    case CANCELLED_STATUS:
    case REFUNDED_STATUS:
    case VERIFICATION_FAILED_STATUS:
      color='danger';break;
    case KA_RECEIVED_STATUS:
    case VERIFICATION_PASSED_STATUS:
    case DELIVERING_STATUS:
      color='success';break;
    default: color='warning'; break;
  }
  if(isCompletedSale(status))
    color = 'success';
  return color;
}

const isVisibleForceCancelled = (status, forceCancelledAt) => {
  if(forceCancelledAt){
    if(status === VERIFICATION_FAILED_STATUS || status === CANCELLED_STATUS)
      return true;
    return false;
  }
  else if(isCompletedSale(status))
    return false;
  switch(status){
    case DELIVERING_STATUS:
    case REFUNDED_STATUS:
      return false;
    default: return true;
  }
}

const isVisibleButtonRollback = (sale, roles=[]) => {
  const validRoles = roles.length && (roles.includes('superadministrator') || roles.includes('administrator')); 
  const isCanAccess = roles.length > 0 ? validRoles && !sale.money_disbursed : false;
  if(isCanAccess && (sale.status===KA_RECEIVED_STATUS || sale.status === CANCELLED_STATUS || sale.status === VERIFICATION_FAILED_STATUS)){
    return true;
  }
  else if(isCanAccess && (sale.status===VERIFICATION_PASSED_STATUS && sale.ka_verified!=null))
    return true;
  return false;
}

const rollbackStatus = (status) => {
  if(status === KA_RECEIVED_STATUS) return  'Rollback to Sneaker On The way';
  else if(status === VERIFICATION_PASSED_STATUS || status === VERIFICATION_FAILED_STATUS || status === CANCELLED_STATUS) return 'Rollback to Verification Progress';
  return '';
}

const ButtonStyle = styled.button`
  cursor: pointer;
  color: #f86c6b;
  font-size: .75rem;
  background: none;
  text-decoration: underline;
  border: none;
  padding: 0;
`;

const Header = props => (
  <HeaderWrapper>
    <ContentInvoice>
      <p>
        <FontAwesomeIcon iconType="check-square-o"/> { props.invoiceNumber }
        <ButtonChangeSeller
          style={{marginLeft: '.5rem'}}
          isDisplayed={props.showButtonChangeSeller}
          id={props.id}
          status={props.status}
        />
      </p>
      <p style={{display: 'flex', alignItems: 'center'}}>
        {props.category&&(
          <Badge color="primary" className="text-uppercase mr-1">
            <FontAwesomeIcon iconType="tags" className="mr-1"/>{ props.category }
          </Badge>
        )}
        { props.productName } ({ props.createdAt })
      </p>
      <p className="text-warning">{ props.isHijacked? `Has seller changed at ${props.hijackedAt}`:null  }</p>
    </ContentInvoice>
    <ContentStatus>
      <Badge tag="div" color={props.status === 'KA_SENT_BUYER' && !props.kaSent? 'warning': getColorStatus(props.status)}>
        { props.status === 'KA_SENT_BUYER' && !props.kaSent? 'Awaiting AWB Number': getStatusSales(props.status) } { props.status === CANCELLED_STATUS && props.forceCancelledAt!==null? '(F)': null }
      </Badge>
      {props.followUpStatus === 'PENDING'?(
        <div>
          <Link
            style={{fontSize: '.75rem', fontStyle: 'italic', color: '#4a4a4a'}}
            to={`/sales/follow-up?activeTab=followUpApproval&keyword=${props.invoiceNumber}`}
            target="_blank"
            title="Detail"
          >
            <i className="fa fa-exclamation-triangle"></i> Pending Follow Up
          </Link>
        </div>
      ):null}
      <div>
        <ButtonStyle
          onClick={props.onForceCancelledClick}
          className={classNames({"d-none": !isVisibleForceCancelled(props.status, props.forceCancelledAt)})}
          disabled={!isVisibleForceCancelled(props.status, props.forceCancelledAt)}
        >
          Force Cancelled
        </ButtonStyle>
      </div>
      <div>
        <ButtonStyle
          onClick={props.onRollbackStatusClick}
          className={classNames({"d-none": !isVisibleButtonRollback(props.sale, props.roles)})}
        >
          {rollbackStatus(props.status)}
        </ButtonStyle>
      </div>
    </ContentStatus>
  </HeaderWrapper>
)

export default Header;

Header.propTypes = {
  sale: propTypes.object,
  invoiceNumber: propTypes.string,
  productName: propTypes.string,
  createdAt: propTypes.string,
  status: propTypes.string,
  isHijacked: propTypes.bool,
  hijackedAt: propTypes.string,
  showButtonChangeSeller: propTypes.bool,
  forceCancelledAt: propTypes.string,
  onForceCancelledClick: propTypes.func
}

Header.defaultProps = {
  invoiceNumber: '-',
  productName: '-',
  createdAt: '-',
  status: 'NEW',
  isHijacked: false,
  hijackedAt: '-',
  showButtonChangeSeller: true,
  forceCancelledAt: null
}