import React from 'react';
import {
  ListGroup,
  Input,
  ListGroupItem,
  ListGroupItemHeading,
  ListGroupItemText,
} from 'reactstrap';
import styled from 'styled-components';
import { SimpleLineIcon } from '../Icons';

const VoucherItem = styled(ListGroupItem)`
  display: flex;
  align-items: center;
  .control{
    padding: .5rem;
  }
`;

const Title = styled(ListGroupItemHeading)`
  font-size: 1rem;
  margin-bottom: 0px;
`;

const Desc = styled(ListGroupItemText)`
  margin-bottom: 0px;
`;

const CardVoucherList = props => (
  <ListGroup style={{maxHeight: 250, overflow: 'auto'}}>
    {props.dataSource && props.dataSource.map((item, index) =>
      <VoucherItem key={index}>
        <div className="control">
          <Input
            type="checkbox"
            value="true"
            checked={props.dataInputs.includes(item.id)}
            onChange={e => props.onCheckedChange(e, item)}
          />
        </div>
        <div>
          <Title>
            (
              <a
                href="#"
                className="link text-danger"
                onClick={(e)=> {
                  props.onDetachVoucher(e, item.id)
                }}
              >
                <SimpleLineIcon iconType="trash"/>
              </a>
            ) &nbsp; {item.code}
          </Title>
          <Desc>
            <small>
              <b>pre-order</b>: <i> {item.listing_pre_order? <i className="fa fa-check"></i>:<i className="fa fa-times"></i>}, </i> 
              <b>pre-verified</b>: <i> {item.listing_pre_verified? <i className="fa fa-check"></i>:<i className="fa fa-times"></i> }, </i>
              <b>new-user-only</b>: {item.new_user_only? <i className="fa fa-check"></i>:<i className="fa fa-times"></i> } 
            </small>
            <br/>
            <small><b>added</b>: <i> {item.pivot.created_at},</i> <b>active</b>: {item.active? <i className="fa fa-check"></i>:<i className="fa fa-times"></i> } </small>
          </Desc>
        </div>
      </VoucherItem>
    )}
  </ListGroup>
)

export default CardVoucherList