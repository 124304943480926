import React from 'react';
import {
  Card,
  CardText,
  CardHeader,
  CardBody,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col
} from 'reactstrap';
import { SizeDetail } from '../../../components/Products';
import DetailReadOnly from '../../../components/Products/DetailReadOnly';
import ImageList from '../../../components/Card/ImageList';
import Image from '../../../components/Card/ImageList/Image';
import classNames from 'classnames';
import SellDetailCard from './tabSneakerDetail/SellDetailCard';

const getPublicImageUrl = image => {
  return image.signed_url || image.URL
}

class TabSneakerDetail extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      activeTab: 'variantImagesTab',
      dropdownSellOpen: false
    }
  }

  toggle = activeTab => this.setState({ activeTab })

  renderSellerSellingImages = () => {
    const { userSell, isLoading, productVariant: { slug } } = this.props;
    const doesntHaveImages = userSell === null || userSell.user_sell_images.length === 0;
    return(
      <ImageList
        data={doesntHaveImages ? []: userSell.user_sell_images}
        isEmpty={doesntHaveImages}
        isLoading={isLoading}
        headerText="Seller Selling Images"
        onRenderItems={(data)=>(
          <Row>
            {
              data.map((image, index)=>
                <Col key={index} xs={6} md={3}>
                  <Image
                    url={getPublicImageUrl(image)}
                    alt={`${slug}-${image.id}`}
                    updatedAt={image.updated_at}
                  />
                </Col>
              )
            }
          </Row>
        )}
      />
    )
  }

  render(){
    const {
      isLoading,
      productVariant: {
        product: { brand, category, ...product },
        product_variant_images,
        ...variant
      },
      size,
      userSell,
      userSellId
    } = this.props;
    const featureImage = product_variant_images && product_variant_images.length? getPublicImageUrl(product_variant_images[0]): null
    return(
      <Card body className="tab-content--custom__card">
        <CardText>About their's offer to a product.</CardText>
        <Row>
          <Col xs={12} md={6}>
            <DetailReadOnly
              {...variant}
              category={category}
              brand={brand}
              product={product}
              headerText="Variant Details"
              featureImage={featureImage}
              isLoading={isLoading}
            />
          </Col>
          <Col xs={12} md={6}>
            <Row>
              <Col xs={12}>
                <SizeDetail
                  renderHeaderText={()=> <span>Selected Size</span>}
                  {...size}
                  isLoading={isLoading}
                />
              </Col>
              <Col xs={12}>
                <SellDetailCard
                  dropdownSellOpen={this.state.dropdownSellOpen}
                  toggleDropdownSell={() => this.setState({ dropdownSellOpen: !this.state.dropdownSellOpen })}
                  renderHeaderText={()=> <span>Seller Listing Details</span>}
                  isLoading={isLoading}
                  isEmptyData={userSellId === null}
                  {...userSell}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={classNames({ active: this.state.activeTab === 'variantImagesTab' })}
                  onClick={() => { this.toggle('variantImagesTab'); }}
                >
                  Variant Images
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classNames({ active: this.state.activeTab === 'sellerImagesTab' })}
                  onClick={() => { this.toggle('sellerImagesTab'); }}
                >
                  Seller Images
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={this.state.activeTab}>
              <TabPane tabId="variantImagesTab">
                <ImageList
                  data={product_variant_images}
                  isEmpty={product_variant_images.length==0}
                  isLoading={isLoading}
                  headerText="Variant Images"
                  onRenderItems={(data)=>(
                    <Row>
                      {
                        data.map((image, index)=>
                          <Col key={index} xs={6} md={3}>
                            <Image
                              url={getPublicImageUrl(image)}
                              alt={`${variant.slug}-${image.id}`}
                              updatedAt={image.updated_at}
                            />
                          </Col>
                        )
                      }
                    </Row>
                  )}
                />
              </TabPane>
              <TabPane tabId="sellerImagesTab">
                { this.renderSellerSellingImages() }
              </TabPane>
            </TabContent>
          </Col>
        </Row>
      </Card>
    )
  }
}

export default TabSneakerDetail;

TabSneakerDetail.defaultProps = {
  isLoading: false,
  productVariant: {
    id: null,
    display_name: '',
    product:{
      brand_id: null,
      brand: {
        id: null
      },
      category_id: null,
      category: {
        id: null
      }
    },
    product_variant_images: []
  },
  size: {
    id: null,
    US: '-',
    EUR: '-',
    UK: '-',
    cm: '-'
  },
  userSellId: null,
  userSell: {
    id: null,
    size_id: null,
    product_variant_id: null,
    user_sell_images: []
  }
}