import React, { PureComponent as Component } from 'react';
import { CardBody, Collapse, Alert } from 'reactstrap';
import propTypes from 'prop-types';

import ExportForm from '../../Exports/ExportForm';

export default class Exports extends Component {
  render(){
    return(
      <Collapse isOpen={this.props.isOpenExport}>
        <CardBody className="filter-container__card-body">
          <ExportForm
            onSubmit = {this.props.onHandleExport}
            data = {this.props.data}
            categoryOptions = {this.props.categoryOptions}
          />
          <hr/>
        </CardBody>
      </Collapse>
    )
  }
}

Exports.propTypes= {
  isOpenExport: propTypes.bool.isRequired,
  onHandleExport: propTypes.func, 
  data: propTypes.object,
  categoryOptions: propTypes.object
}
