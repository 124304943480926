import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Row,
  Col,
  Card,
  CardBody,
  CardText } from 'reactstrap';

import {Tbl, TblHead, TblBody} from '../../../components/Tbl';
import Paginations from '../../../components/Paginations';
import PaginationSummaries from '../../../components/Paginations/PaginationSummaries';
import { CardHeaderWithToolbars,Title } from '../../../components/Card/CardHeaderWithToolbars';
import Toolbars from './Toolbars';
import FilterPanel from './FilterPanel';
import ExportsPanel from '../Exports';
import FilterContainer from '../../../components/Container/FilterContainer/FilterContainer';
import { ModalInfoCreditCard } from '../../../components/Modals';
import { getOfferList, reportOffer } from '../../../actions/offerAction';
import { getOptionList as getCategoryOptionList } from '../../../actions/categoriesAction';
import { generateNumber } from '../../../utils';
import { DEFAULT_SORT_BY, DEFAULT_PER_PAGE } from '../../../constants/settings';
import RowItem from './rowItem';
import privateView from '../../../components/hocs/privateView';
import DropdownProductCategories from '../../../components/Dropdowns/DropdownProductCategories';
import { Th } from '../../../components/Tbl';
import Analytics from "../../../services/Analytics";

/**
 * Component for when user navigate to /offers
 * @author pewe
 */

const dataHeader=[
    'No.','Order','Total','Payment','Updated','Status', 'Action'
];
// const getTotal=(item)=>(parseFloat(item.offer_amount)+parseFloat(item.ka_courier_price)+parseFloat(item.unique_amount)+parseFloat(item.administration_fee))

const defaultParams={
  sort_by : DEFAULT_SORT_BY,
  per_page : DEFAULT_PER_PAGE
}

class PaymentList extends Component{
	constructor(){
		super();
		this.state={
      _exportOpen: false,
      _filterOpen: false,
			_params:{
        ...defaultParams
      },
      _selectedCategory: null,
      _modalCreditCard: false,
      _invoiceNumber: null,
      isDataLoaded: false
    }
    this._handleToggleFilterPanel = this._handleToggleFilterPanel.bind(this);
    this._handleToggleExportPanel = this._handleToggleExportPanel.bind(this);
    this._handleResetFilter = this._handleResetFilter.bind(this);
    this._handleGoto = this._handleGoto.bind(this);
    this._handleRefresh = this._handleRefresh.bind(this);
    this._handleExport = this._handleExport.bind(this);
    this._handleApplyFilter = this._handleApplyFilter.bind(this);
    this._handleCategoryChanged = this._handleCategoryChanged.bind(this);
    this._handleToggleModalCreditCard = this._handleToggleModalCreditCard.bind(this);
	}

	componentDidMount(){
    const { email } = this.props.injectedProps;
    this._getList(this.state._params).then(() => {
      this.setState({
        isDataLoaded: true
      })
    });
    this.props.bindCategoryOption();
    Analytics.recordPageView('payment_list', email, {
      url: window.location.href,
      location: this.props.location,
      match: this.props.match
    });
  }

  _getList(rawParams = {}){
    let p = { ...rawParams }
    // console.log("params filter: ", p)
    if(p.voucher){
      const { voucher, ...other } = p;
      if(Number(voucher.id)){
        p = { ...other, voucher_id: voucher.id }
      }
      else{
        p = { ...other, voucher_code: voucher.code }
      }
    }
    const params = Object.keys(p)
    .filter(key => p[key]!='')
    .reduce((obj, current)=> ({...obj, [current]: p[current]}), {});
    return this.props.getList({params});
  }

  _refreshList(){
    const {page, ..._params} = this.state._params;
    this.setState({
      _params
    },()=>this._getList(this.state._params));
  }


	/**
	 * Function to handle rendering table's items or rows
	 * @author pewe
	 */
	_onRenderRowItem(){
		if(this.props.offer.list.status_code===200 && this.props.offer.list.data.length>0){
			const { current_page,per_page } =  this.props.offer.list;
			return this.props.offer.list.data.map((item,index)=>{
        const rowNumber = generateNumber(per_page,current_page,index);
        return (
          <RowItem
            key={rowNumber}
            id={item.id}
            paymentId={item.payment_id}
            quantity={item.quantity}
            category={item.user_sell.product_variant.product.category&&item.user_sell.product_variant.product.category.name}
            userId={item.shipping.user_id}
            userEmail={item.shipping.user.email}
            rowId={rowNumber}
            invoiceNumber={item.invoice_number}
            updatedAt={item.updated_at}
            paymentMethod={item.payment_method}
            paymentBankTransfer={item.payment_bank_transfer}
            paymentStatus={item.status}
            amount={item.total_amount}
            adjustedAmount={item.total_adjusted_amount}
            displayName={item.user_sell.product_variant.display_name}
            size={item.user_sell.size.US}
            colour={item.user_sell.product_variant.colour}
            SKU={item.user_sell.product_variant.SKU}
            preOrder={item.user_sell.pre_order}
            expired={item.expired}
            consignmentId={item.user_sell.consignment_id}
            bank={item.bank_code}
            paymentVia={item.payment_via}
            onOpenCreditCard={()=>this._onOpenCreditCardModal(item)}
            rack={item.user_sell.rack}
            points={item.points}
            transactionNumber={item.transaction?.transaction_number}
            referenceNumber={item.transaction?.reference_number}
            voucherCode={item.voucherable_id? (item.voucherable? item.voucherable.code: `ID: ${item.voucherable_id}`): (item.voucher_code? "REF: "+item.voucher_code: null)}
            createdSource={item.created_source}
          />
        )
      })
		}
    return null;
	}

	/**
	 * Function to handle rendering paginations
	 * @author pewe
	 */
	_onRenderPagination(){
    const {status_code, data} = this.props.offer.list;
    const hasData = (status_code===200 && data.length)||null;
    return hasData && (
      <Paginations
        isLoading={this.props.offer.isFetch}
        size="sm"
        lastPage={this.props.offer.list.last_page}
        perPage={this.props.offer.list.per_page}
        total={this.props.offer.list.total}
        maxPage={8}
        path={this.props.offer.list.path}
        currentPage={this.props.offer.list.current_page}
        prevPageUrl={this.props.offer.list.prev_page_url}
        nextPageUrl={this.props.offer.list.next_page_url}
        handleGotoCallback={this._handleGoto}
        onCurrentPageChanged={() => {
          window.scrollTo({
            top: this.tableRef.offsetTop+25,
            behavior: 'smooth'
          })
        }}
      />
    )
	}

	/**
	 * Handle callback when user click pagination items
	 * @author pewe
	 * @param {integer} page
	 */
	_handleGoto(page){
    const _params=this.state._params;
    _params.page=page;
    this.setState({_params},()=>this._getList(this.state._params));
  }

  _handleRefresh(e){
    e.preventDefault();
    this._refreshList();
  }

  _handleExport(payload){
    this.props.reportOffer(payload);
  }

  _handleResetFilter(e){
    e.preventDefault();
    const newState = this.state._filterOpen?{_filterOpen:!this.state._filterOpen}:{};
    const exportState = this.state._exportOpen?{_exportOpen:!this.state._exportOpen}:{};
    this.setState({
      ...exportState,
      ...newState,
      _params:{...defaultParams},
      _selectedCategory: null,
      _isResetFilter: true
    },()=>this._getList(this.state._params));
  }

  _handleApplyFilter(payloadFilter){
    this.setState({
      _params: {
        ...this.state._params,
        ...payloadFilter,
        page: 1
      }
    },()=>this._getList(this.state._params));
  }

  _toggleFilterPanel(){
    const exportState = this.state._exportOpen?{_exportOpen:!this.state._exportOpen}:{};
    this.setState({
      ...exportState,
      _filterOpen:!this.state._filterOpen});
  }

  _toggleExportPanel(){
    const filterState = this.state._filterOpen?{_filterOpen:!this.state._filterOpen}:{};
    this.setState({
      ...filterState,
      _exportOpen:!this.state._exportOpen});
  }

  _handleToggleExportPanel(e){
    e.preventDefault();
    this._toggleExportPanel();
  }

  _handleToggleFilterPanel(e){
    e.preventDefault();
    this._toggleFilterPanel();
  }

  async _handleCategoryChanged(category){
    await this.setState({
      _selectedCategory: category === ''? null: category.label,
      _params: {
        ...this.state._params,
        category_id: category === ''? category: category.value,
        page: 1,
      }
    });
    const { _params } = this.state;
    this._getList(_params);
  }

  _onOpenCreditCardModal(item){
    this.setState({_invoiceNumber: item.invoice_number},()=>this._handleToggleModalCreditCard());
  }

  _handleToggleModalCreditCard() {
    this.setState({_modalCreditCard: !this.state._modalCreditCard});
  }

	render(){
		return (
			<div className="animated fadeIn">
        <Row>
          <ModalInfoCreditCard
            isOpen={this.state._modalCreditCard}
            toggle={this._handleToggleModalCreditCard}
            invoiceNumber={this.state._invoiceNumber}
          />
          <Col xs={12}>
            <Card>
              <CardHeaderWithToolbars>
                <Title>
                  <i className="fa fa-align-justify"></i>
                  <span>User Payment List</span>
                </Title>
                {this.state.isDataLoaded && <Toolbars
                  isOpen={this.state._filterOpen}
                  onToggleFilter={this._handleToggleFilterPanel}
                  onToggleExport={this._handleToggleExportPanel}
                  onRefreshList={this._handleRefresh}
                  onResetFilter={this._handleResetFilter}
                />}
              </CardHeaderWithToolbars>
              <FilterContainer>
                <FilterPanel
                  {...this.state._params}
                  isOpen={this.state._filterOpen}
                  onApplyFilter={this._handleApplyFilter}
                  isReset={this.state._isResetFilter}
                  onResetFilterCallback={()=>this.setState({_isResetFilter: false, _filterOpen: false})}
                />
              </FilterContainer>
              <FilterContainer>
                <ExportsPanel
                  onHandleExport={this._handleExport}
                  isOpenExport={this.state._exportOpen}
                  data={this.props.offer.report_offer}
                  categoryOptions={this.props.categoryOptions}
                />
              </FilterContainer>
              <CardBody>
                <CardText>
                  In this section will display our user's payments.
                </CardText>
                <div className="table-responsive" ref={tableRef => this.tableRef = tableRef}>
                  <PaginationSummaries
                    from={this.props.offer.list.from}
                    to={this.props.offer.list.to}
                    total={this.props.offer.list.total}
                  />
                  <Tbl>
                    <TblHead
                      renderChildren={()=>(
                        <tr>
                          <Th>No.</Th>
                          <Th>Payment ID</Th>
                          <Th>
                            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'flex-end'}}>
                              <p style={{marginRight: 10, marginBottom: 0}}>
                                Order Detail
                                {
                                  this.state._selectedCategory !== null?
                                  <span style={{display: 'block', fontSize: '.5rem'}}>{this.state._selectedCategory}</span>
                                  : null
                                }
                              </p>
                              <DropdownProductCategories
                                value={this.state._params.category_id}
                                onCategoryChanged={this._handleCategoryChanged}
                              />
                            </div>
                          </Th>
                          <Th>Total</Th>
                          <Th>Payment</Th>
                          <Th>Updated</Th>
                          <Th>Status</Th>
                          <Th>Action</Th>
                        </tr>
                      )}
                    />
                    <TblBody
                      isLoad={this.props.offer.isFetch}
                      hasRow={this.props.offer.list.status_code===200&&this.props.offer.list.data.length>0}
                      columnCount={8}
                    >
                    { this._onRenderRowItem() }
                    </TblBody>
                  </Tbl>
                </div>
                { this._onRenderPagination() }

              </CardBody>
            </Card>
          </Col>
        </Row>
			</div>
		)
	}
}
const mapStateToProps = ({offer, masterCategories, auth: { isFetch, isLogged, roles, id, email }}) =>{
	return {
		offer,
    guardData: { isFetch, isLogged, roles, id, email },
    categoryOptions:masterCategories
	}
}
const mapDispatchToProps = (dispatch) => {
	return{
    getList:(query) => dispatch(getOfferList(query)),
    reportOffer:(payload) => dispatch(reportOffer(payload)),
    bindCategoryOption:()=>dispatch(getCategoryOptionList())
	}

};
const enhance = connect(mapStateToProps,mapDispatchToProps);

export default enhance(privateView(PaymentList, ['supervisor', 'customer_service', 'cashier']));
