import React, { Component } from 'react';
import { connect } from 'react-redux';
import { deleteVoucher } from '../../../actions/voucherAction';
import { ModalDelete } from '../../../components/Modals';
import FormCard from './FormCard';

class RaffleTicketForm extends Component{
  constructor(props){
    super(props);
    this.state={
      _edit:this.props.edit,
      _readOnly:this.props.readOnly,
      _hasId:this.props.match.params.id?true:false,
      _redirect:'/raffle_tickets',
      _hasDeleted:false,
      _deleteProcess:false,
      _errors:[],
      _success:[],
      _formSubmit:false,
      _modalDelete:false
    }
    this.onDelete=this.onDelete.bind(this);
    this.toggle=this.toggle.bind(this);
  }

  componentWillReceiveProps(nextProps){
    if(nextProps.voucher.isSubmit === false
      && nextProps.voucher.isSubmit !== this.props.voucher.isSubmit
      && this.state._deleteProcess===true
    ){
      if(nextProps.voucher.detail.delete===true){
        this.props.history.replace(this.state._redirect);
      }
    }
  }

  toggle(){
    this.setState({_modalDelete:!this.state._modalDelete});
  }

  onDelete(){
    this.setState({_deleteProcess:true}, ()=>this.props.delete(this.props.match.params.id));
  }

  render(){
    const { match : { params }, history, title } = this.props;
    return(
      <div>
        <ModalDelete
          isOpen={this.state._modalDelete}
          toggle={this.toggle}
          onDelete={this.onDelete}
        />
        <FormCard
          dataUser={this.props.dataUser}
          params={params}
          history={history}
          title={title}
          isEdit={this.state._edit}
          isNew={!this.state._hasId}
          onDelete={this.toggle}
        />
      </div>
    )
  }
}
const mapStateToProps= ({ voucher, auth }) => {
	return {
    dataUser: auth,
    voucher
	}
};
const mapDispatchToProps = (dispatch) => {
	return{
		delete:(id) => dispatch(deleteVoucher(id)),
	}
};

export default connect(mapStateToProps,mapDispatchToProps)(RaffleTicketForm);