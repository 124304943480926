import React, { useMemo } from "react";
import Style from "./ProductCard.module.css";
import { Button } from "reactstrap";
import { SimpleLineIcon } from "../../../../../../../components/Icons";
import { formatRupiah } from "../../../../../../../helpers/formatter";
import Tooltip from "../Tooltip/Tooltip";
import { getProductCondition, getProductStatus } from "../../../../Helper";

const ProductCard = (props) => {
    const {
        data,
        onClickButton,
        isSelected,
        formMode,
        onClickDelete,
        selectedStatus,
    } = props;
    const categoryName = data?.product_variant?.product?.category?.name || "";
    const quantity = data?.quantity
    const isShowingSizeText = ["sneakers", "apparels", "handbags"].includes(
        categoryName
    );

    const status = useMemo(() => {
        return getProductStatus(data);
    }, [data]);

    const condition = useMemo(() => {
        return getProductCondition(data);
    }, [data]);

    const isDisableAdd = selectedStatus !== "" && selectedStatus !== status;

    return (
        <div
            className={
                formMode ? Style["product-card-form"] : Style["product-card"]
            }
        >
            <div
                className={
                    formMode
                        ? Style["image-wrapper-form"]
                        : Style["image-wrapper"]
                }
            >
                <img
                    alt="product"
                    src={
                        data?.product_variant?.product_variant_images[0]
                            ?.signed_url ||
                        data?.product_variant?.product_variant_images[0]?.URL
                    }
                />
            </div>
            <div className={Style["desc-wrapper"]}>
                <div className={Style["cg-id"]}>
                    CG ID: {data?.consignment_id || "-"}
                </div>
                <div className={Style["product-name"]}>
                    {data?.product_variant?.display_name || "-"}
                </div>
                <div className={Style["seller"]}>
                    {data?.product_variant?.SKU || "-"}
                </div>
                <div className={Style["product-price"]}>
                    {data?.asking_price
                        ? formatRupiah(parseInt(data.asking_price))
                        : "-"}
                </div>
                <div className={Style["seller"]}>
                    Seller: {data?.user?.email}
                </div>
                <div className={Style["tags"]}>
                    <span id={`tag-1-${data.id}`} className={Style["tag-1"]}>
                        <span className={Style["tag-text"]}>{status}</span>
                    </span>
                    <span id={`tag-2-${data.id}`} className={Style["tag-2"]}>
                        <span className={Style["tag-text"]}>{condition}</span>
                    </span>
                    <span id={`tag-3-${data.id}`} className={Style["tag-3"]}>
                        <span className={Style["tag-text"]}>
                            {isShowingSizeText && "Size"}{" "}
                            {data?.size?.US || "-"}
                        </span>
                    </span>
                </div>
            </div>
            <div className={Style["add-wrapper"]}>
                {formMode ? (
                    <Button
                        outline
                        color={"secondary"}
                        onClick={() => {
                            onClickDelete(data);
                        }}
                        className={Style["button-form"]}
                    >
                        <SimpleLineIcon iconType="trash" />
                    </Button>
                ) : (
                    quantity > 0 ?
                        <Button
                            color={"secondary"}
                            disabled={isDisableAdd}
                            onClick={() => {
                                onClickButton(data);
                            }}
                            style={{cursor: isDisableAdd ? "default" : "pointer"}}
                            className={
                                Style[isSelected ? "button-selected" : "button"]
                            }
                        >
                            {isSelected && <SimpleLineIcon iconType="check" />}
                            {!isSelected && "Add"}
                        </Button> : null
                )}
            </div>
            <Tooltip target={`tag-1-${data.id}`}>{status}</Tooltip>
            <Tooltip target={`tag-2-${data.id}`}>{condition}</Tooltip>
            <Tooltip target={`tag-3-${data.id}`}>
                {data?.size?.US || "-"}
            </Tooltip>
        </div>
    );
};

export default ProductCard;
