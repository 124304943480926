import api from "../../../utils/Api";
import {AUTH_TOKEN_KEY} from "../../../constants";
import {useState} from "react";
import {manipulateErrors} from "../../../utils";

export const useFetchCity = () => {
    const [isFetch, setIsFetch] = useState(false);
    const [error, setError] = useState({error:{message:'I got something undefined errors',status_code:500}});
    const [data, setData] = useState([]);

    const fetchCity = (provinceName) => {
        setIsFetch(true);
        return api({
            Authorization:'Bearer '+localStorage.getItem(AUTH_TOKEN_KEY)
        })
            .get(`/postalcode/city?province=${provinceName}`)
            .then((res) => {
                setIsFetch(false);
                setData(res.data.data);
                return res.data.data;
            })
            .catch((err) => {
                setIsFetch(false);
                setError(manipulateErrors(err));
            })
    };

    return {isFetch, error, fetchCity, data};
}
