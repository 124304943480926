import {
  VOUCHER_ADD,
  VOUCHER_DETAIL_FETCH,
  VOUCHER_LIST_FETCH,
  VOUCHER_ERROR,
  VOUCHER_DELETE,
  VOUCHER_UPDATE,
  VOUCHER_CLEAR_FORM,
  VOUCHER_FETCHING,
  TOTAL_USAGE_VOUCHER_FETCH,
  TOTAL_USAGE_VOUCHER_DONE,
  TOTAL_USAGE_VOUCHER_FETCH_ERROR,
  VOUCHER_USERS_FETCH,
  VOUCHER_USERS_DONE,
  VOUCHER_USERS_POST,
	VOUCHER_USERS_UPDATE,
	VOUCHER_USERS_DELETE,
	VOUCHER_USERS_ERROR,
  VOUCHER_USERS_FETCH_ERROR,
  VOUCHER_S3,
  VOUCHER_S3_ERROR,
  VOUCHER_IMAGE_REQUEST,
	UPDATE_VOUCHER_IMAGE,
	CREATE_VOUCHER_IMAGE,
	VOUCHER_IMAGE_ERROR,
  DELETE_VOUCHER_IMAGE,
	VOUCHER_CATEGORY_REQUEST,
	VOUCHER_CATEGORY_LIST,
	CREATE_VOUCHER_CATEGORY,
	VOUCHER_CATEGORY_ERROR,
  SEARCH_VOUCHER_ITEMS,
  GET_LIST_VOUCHER_ITEMS,
  GET_LIST_VOUCHER_BRANDS,
  VOUCHER_CHILDS_REQUEST,
  SUBMITING_VOUCHER_ITEMS,
  SUBMITING_VOUCHER_BRANDS,
  VOUCHER_CHILDS_ERROR,
  SAVE_VOUCHER_PAYMENT_METHOD,
} from '../constants';
const initialState={
  totalUsages:{
    data: {},
    error: null,
    isFetch: false
  },
  userVouchers:{
    list:[],
    detail:{},
    error: null,
    isFetch: false,
		isSubmit:false,
		isDelete:false,
  },
  uploadedImage: {
    isPending: false,
    results: null,
    error: null
  },
	list:[],
  detail:{},
  voucherCategories: {
    id: null,
    data: [],
    status_code: 0,
    isFetch: false,
    isSubmit:false
  },
  voucherItems: {
		dataSearch: [],
		data: [],
    brand:{
      dataSearch: [],
      data: [],
      isSearchFetch: false,
      isFetch: false,
      isSubmit: false,
      error: null
    },
		isSearchFetch: false,
		isFetch: false,
		isSubmit: false,
		error: null
	},
	isFetch:false,
	isSubmit:false,
	error:null,
}

const onFetching = (lastState,payload) => {
    if(payload.type==='fetch')
        return { ...lastState, isFetch:payload.status }
    else if(payload.type==='submit')
        return { ...lastState, isSubmit:payload.status }

    return { ...lastState, isFetch:false,isSubmit:false }
}

const onFetched = (lastState, { requestType, value }) => {
  switch(requestType){
    case CREATE_VOUCHER_IMAGE:
    case DELETE_VOUCHER_IMAGE:
    case UPDATE_VOUCHER_IMAGE: return {...lastState, detail: { ...lastState.detail, isImageDataSubmit: value }}
    default: return lastState;
  }
}

const onVoucherCategoryFetched = (lastState, { requestType, value }) => {
  switch(requestType){
    case VOUCHER_CATEGORY_LIST:
      return {...lastState, voucherCategories: { ...lastState.voucherCategories, isFetch: value }}
    case CREATE_VOUCHER_CATEGORY:
      return {...lastState, voucherCategories: { ...lastState.voucherCategories, isSubmit: value }}
    default: return lastState;
  }
}

const onError = (lastState, { requestType, error, ...rest }) => {
  switch(requestType){
    case CREATE_VOUCHER_IMAGE:
    case DELETE_VOUCHER_IMAGE:
    case UPDATE_VOUCHER_IMAGE: return {...lastState, detail: { ...lastState.detail, error: { ...error, ...rest } }}
    case VOUCHER_CATEGORY_LIST:
    case CREATE_VOUCHER_CATEGORY:
      return {...lastState, voucherCategories: { ...lastState.voucherCategories, error: { ...error, ...rest } }}
    default: return lastState;
  }
}

const onFetchingVoucherUser = (lastState,payload) => {
	const { userVouchers }=lastState;
	if(payload.type==='fetch')
		return { ...lastState, userVouchers:{...userVouchers, isFetch:payload.status, isDelete:false} }
	else if(payload.type==='submit')
		return { ...lastState, userVouchers:{...userVouchers, isSubmit:payload.status, isDelete:false} }
	return { ...lastState, userVouchers:{...userVouchers, isFetch:false, isSubmit:false, isDelete:false } }
}

const onValidateChildRequest = (lastState, { requestType, value }) => {
	switch(requestType){
		case GET_LIST_VOUCHER_ITEMS: return {
			...lastState,
			voucherItems: {
				...lastState.voucherItems,
				isFetch: value
			}
		}
    case GET_LIST_VOUCHER_BRANDS: return {
			...lastState,
			voucherItems: {
        ...lastState.voucherItems,
        brand:{
          ...lastState.voucherItems.brand,
          isFetch: value
        }
			}
		}
		case SEARCH_VOUCHER_ITEMS: return {
			...lastState,
			voucherItems: {
				...lastState.voucherItems,
				isSearchFetch: value
			}
		}
		case SUBMITING_VOUCHER_ITEMS: return {
			...lastState,
			voucherItems: {
				...lastState.voucherItems,
				isSubmit: value
			}
		}
    case SUBMITING_VOUCHER_BRANDS: return {
			...lastState,
			voucherItems: {
				...lastState.voucherItems,
        brand:{
          ...lastState.voucherItems.brand,
				  isSubmit: value
        }
			}
		}
		default: return lastState;
	}
}

const onAddVoucher=(lastState,payload)=>{
	const {data, message, status_code}=payload;
    return { ...lastState, detail:{ ...data, message, status_code }, success:'Insert has been successfully!',error:null }
}

const onUpdateVoucher=(lastState,payload)=>{
	const {data, message, status_code}=payload;
    return { ...lastState, detail:{ ...data, message, status_code }, success:'Update has been successfully!',error:null }
}

const onDeleteVoucher=(lastState,payload)=>{
	const {data, message, status_code}=payload;
    return { ...lastState, detail:{ ...data, message, status_code, delete:true }, success:'Delete has been successfully!',error:null }
}

const onAddVoucherUser=(lastState,payload)=>{
	const {data, message, status_code}=payload;
	const { userVouchers }=lastState;
  return {...lastState, userVouchers:{...userVouchers, detail:{...data, message, status_code}, isSubmit:false, success:'Insert has been successfully!',error:null }}
}

const onUpdateVoucherUser=(lastState,payload)=>{
	const { data, message, status_code }=payload;
	const { userVouchers }=lastState;
	return {...lastState, userVouchers:{...userVouchers, detail:{...data, message, status_code}, isSubmit:false, success:'Update has been successfully!',error:null }}
}

const onDeleteVoucherUser=(lastState,payload)=>{
	const { data, message, status_code }=payload;
	const { userVouchers }=lastState;
	return {...lastState, userVouchers:{...userVouchers, detail:{...data, message, status_code}, isSubmit:false, isDelete:true, success:'Delete has been successfully!',error:null }}
}

const onVoucherItemsSucceeded = (lastState, { type, payload }) => {
	const { data, message, status_code } = payload
	switch(type){
		case SUBMITING_VOUCHER_ITEMS: return {
			...lastState,
			voucherItems: {
				...lastState.voucherItems,
				error: null,
				message,
				status_code
			}
		};
    case SUBMITING_VOUCHER_BRANDS: return {
			...lastState,
			voucherItems: {
				...lastState.voucherItems,
        brand:{
          ...lastState.voucherItems.brand,
          error: null,
          message,
          status_code
        }
			}
		};
		case SEARCH_VOUCHER_ITEMS: return {
			...lastState,
			voucherItems: {
				...lastState.voucherItems,
				error: null,
				dataSearch: data,
				message,
				status_code
			}
		}
		case GET_LIST_VOUCHER_ITEMS: return {
			...lastState,
			voucherItems: {
				...lastState.voucherItems,
				error: null,
				data,
				message,
				status_code
			}
		};
    case GET_LIST_VOUCHER_BRANDS: return {
			...lastState,
			voucherItems: {
        ...lastState.voucherItems,
        brand:{
				  ...lastState.voucherItems.brand,
          error: null,
          data,
          message,
          status_code
        }
			}
		};
		default: return lastState;
	}
}


const onErrorChildRequest = (lastState, { requestType, error, ...rest }) => {
	switch(requestType){
    case SEARCH_VOUCHER_ITEMS:
    case GET_LIST_VOUCHER_ITEMS:
		case SUBMITING_VOUCHER_ITEMS: return {
			...lastState,
			voucherItems: {
				...lastState.voucherItems,
				error: { ...error, ...rest }
			}
		}
    case GET_LIST_VOUCHER_BRANDS:
    case SUBMITING_VOUCHER_BRANDS: return {
			...lastState,
			voucherItems: {
				...lastState.voucherItems,
        brand:{
          ...lastState.voucherItems.brand,
				error: { ...error, ...rest }
        }
			}
		}
		default: return lastState;
	}
}

const reducer=(state=initialState, action)=>{
    switch(action.type){
        case VOUCHER_LIST_FETCH:{
            const {data, status_code, message}=action.payload;
            return {...state, list:{...data, message, status_code}, error:null, success:null }
        }
        case VOUCHER_DETAIL_FETCH:{
            const {data, status_code, message}=action.payload;
            return { ...state, detail:{...data, message, status_code}, error:null, success:null }
        }
        case VOUCHER_FETCHING:  return onFetching(state, action.payload);      
        case VOUCHER_ERROR:
            return { ...state, ...action.payload }
        case VOUCHER_ADD: return onAddVoucher(state, action.payload);
        case VOUCHER_UPDATE: return onUpdateVoucher(state, action.payload);
        case VOUCHER_DELETE: return onDeleteVoucher(state, action.payload);
        case VOUCHER_CLEAR_FORM:return {...initialState};
        case TOTAL_USAGE_VOUCHER_FETCH: {
          const { totalUsages, error } = state;
          return { ...state, totalUsages: {...totalUsages, isFetch: action.payload, error: action.payload?null:{...error} } }
        }
        case TOTAL_USAGE_VOUCHER_DONE: 
        case TOTAL_USAGE_VOUCHER_FETCH_ERROR: {
          const { totalUsages } = state;
          return { ...state, totalUsages: {...totalUsages, ...action.payload } }
        }
        case VOUCHER_USERS_FETCH: return onFetchingVoucherUser(state, action.payload);
        case VOUCHER_USERS_DONE: {
          const { userVouchers } = state;
          const { data:{ data, ...paginations}, status_code, message } = action.payload
          return { ...state, userVouchers: {...userVouchers, isFetch: action.payload, list: data, ...paginations, status_code, message } }
        }
        case VOUCHER_USERS_FETCH_ERROR: {
          const { userVouchers } = state;
          return { ...state, userVouchers: {...userVouchers, ...action.payload } }
				}
				case VOUCHER_USERS_POST: return onAddVoucherUser(state, action.payload);
				case VOUCHER_USERS_UPDATE: return onUpdateVoucherUser(state, action.payload);
				case VOUCHER_USERS_DELETE: return onDeleteVoucherUser(state, action.payload);
				case VOUCHER_USERS_ERROR: {
					const { userVouchers } = state;
					return { ...state, userVouchers: {...userVouchers, ...action.payload } }
        }
        case VOUCHER_S3: {
          return {
            ...state,
            uploadedImage: {
              ...state.uploadedImage,
              error: null,
              ...action.payload,
            }
          }
        }
        case VOUCHER_S3_ERROR:{
          return {
            ...state, uploadedImage: { ...state.uploadedImage, isPending: false, ...action.payload }
          }
        }
        case VOUCHER_IMAGE_REQUEST : return onFetched(state, action.payload)
        case VOUCHER_IMAGE_ERROR : return onError(state, action.payload)
        case CREATE_VOUCHER_IMAGE:
        case DELETE_VOUCHER_IMAGE:
        case UPDATE_VOUCHER_IMAGE: {
          const { status_code, message } = action.payload;
          return {
            ...state,
            detail: {
              ...state.detail,
              status_code,
              message,
              error: null
            }
          }
        }
        case VOUCHER_CATEGORY_REQUEST : return onVoucherCategoryFetched(state, action.payload)
        case VOUCHER_CATEGORY_ERROR : return onError(state, action.payload)
        case VOUCHER_CATEGORY_LIST:
        case CREATE_VOUCHER_CATEGORY: {
          return {
            ...state,
            voucherCategories: {
              ...state.voucherCategories,
              ...action.payload,
              error: null
            }
          }
        }
        case VOUCHER_CHILDS_REQUEST: return onValidateChildRequest(state, action.payload);
        case SUBMITING_VOUCHER_ITEMS:
        case SUBMITING_VOUCHER_BRANDS:
        case SEARCH_VOUCHER_ITEMS:
        case GET_LIST_VOUCHER_BRANDS:
        case GET_LIST_VOUCHER_ITEMS: return onVoucherItemsSucceeded(state, action);
        case VOUCHER_CHILDS_ERROR: return onErrorChildRequest(state, action.payload);
        case SAVE_VOUCHER_PAYMENT_METHOD:{
          const {data, status_code, message}=action.payload;
          return { ...state, detail:{...data, message, status_code}, error:null, success:null }
        }

        default:return state;
    }
}
export default reducer;