import React, { useEffect, useRef, useState, useLayoutEffect } from "react";
import { Tooltip } from "reactstrap";
import Styles from "./OverflownText.module.css";

function OverflownText({ children, id }) {
  const ref = useRef(null);
  const [isOverflown, setIsOverflown] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const targetId = `overflown-text-${id}`

  const toggle = () => setTooltipOpen(!tooltipOpen);
  
  useEffect(() => {
    const element = ref?.current;
    setIsOverflown(element.scrollWidth > element.clientWidth);
  }, []);
  
  useLayoutEffect(() => {
    function updateSize() {
      const element = ref?.current;
      setIsOverflown(element.scrollWidth > element.clientWidth);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  return (
    <>
      <div className={Styles["truncate-elipsis"]} id={targetId} ref={ref}>
        {children}
      </div>
      <Tooltip
        placement="top"
        isOpen={tooltipOpen && isOverflown}
        target={targetId}
        toggle={toggle}
      >
        {children}
      </Tooltip>
    </>
  );
}
export default OverflownText;
