import React, { PureComponent as Component } from 'react';
import propTypes from 'prop-types';

export default class Toolbars extends Component{
  render(){
    return(
      <div className="with-toolbars__toolbars">
        { this.props.children }
      </div>
    )
  }
}

Toolbars.propTypes={
  children: propTypes.node
}