import { useEffect, useMemo, useState } from "react";
import api from "../../../../utils/Api";
import { AUTH_TOKEN_KEY } from "../../../../constants";

const useFetchCategoryLists = () => {
    const [isError, setIsError] = useState(false);
    const [isFetching, setIsFetching] = useState(false);
    const [categories, setCategories] = useState([]);

    const usSizes = useMemo(() => {
        if (categories && categories.length > 0) {
            return categories.map((size) => size.US);
        }

        return [];
    }, [categories]);

    const fetch = async () => {
        setIsFetching(true);
        const myToken = localStorage.getItem(AUTH_TOKEN_KEY);
        const headers = {
            Authorization: `Bearer ${myToken}`,
        };

        const data = await api(headers)
            .get("admins/categories?no_limit=true")
            .then((response) => {
                setIsFetching(false);
                let res = response.data.data
                let accepted = ['sneakers', 'apparels', 'handbags', 'lifestyles']
                return res.filter(item => accepted.includes(item.name));
            })
            .catch((_err) => {
                setIsError(true);
                setIsFetching(false);
                return [];
            });

        setCategories(data);
    };

    useEffect(() => {
        fetch();

        return () => {
            setIsError(false);
            setIsFetching(false);
            setCategories([]);
        };
    }, []);

    return {
        isError,
        isFetching,
        categories,
        usSizes,
    };
};

export default useFetchCategoryLists;
