import React, { useState } from 'react';
import {
  Card,
  CardHeader,
  CardBody,
  TabPane,
  TabContent,
  Nav,
  NavItem,
  NavLink
} from "reactstrap";
import classnames from 'classnames';
import UserBalanceLogListCard from './UserBalanceLogListCard';
import SellerPointLogListCard from './SellerPointLogListCard';

const UserHistoryCard = (props) => {
  const [activeTab, setActiveTab] = useState("userBalance")
  return (
    <Card>
      <CardHeader><i className="fa fa-align-justify"></i>Log</CardHeader>
      <CardBody>
        <Nav className="nav-tabs--custom" tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === 'userBalance' })}
              onClick={() => setActiveTab('userBalance')}
            >
              User
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === 'sellerPoint' })}
              onClick={() => setActiveTab('sellerPoint')}
            >
              Seller
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent className="tab-content--custom" activeTab={activeTab}>
          <TabPane className="tab-content--custom__tab-pane" tabId="userBalance">
            <UserBalanceLogListCard
              userId={props.userId}
              cardClassname="tab-content--custom__card"
              contentBodyClassname="p-0"
              />
          </TabPane>
          <TabPane className="tab-content--custom__tab-pane" tabId="sellerPoint">
            <SellerPointLogListCard
              userId={props.userId}
              cardClassname="tab-content--custom__card"
              contentBodyClassname="p-0"
            />
          </TabPane>
        </TabContent>
      </CardBody>
    </Card>
  )
}

export default UserHistoryCard;