import React from 'react';
import { connect } from 'react-redux';
import ProductAddonForm from './ProductAddonForm';
import privateView from '../../components/hocs/privateView';
import Analytics from "../../services/Analytics";

class ProductAddonCreate extends React.Component{
	componentDidMount(){
    const { email } = this.props.injectedProps;
    Analytics.recordPageView('product_addon_detail', email, {
      url: window.location.href,
      location: this.props.location,
      match: this.props.match
    });
  }
  render(){
    return(
      <div className="animate fadeIn">
        <ProductAddonForm
          title="Create New Product Addon"
          useCrop
          {...this.props}
        />
      </div>
    )
  }
}

const mapStateToProps = ({auth: { isFetch, roles, isLogged, id, email } })=>({
  guardData: { isFetch, roles, isLogged, id, email }
})

const enhance = connect(mapStateToProps);

export default enhance(privateView(ProductAddonCreate, ['supervisor', 'sale_supervisor']));