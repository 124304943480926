import React, { PureComponent } from 'react';
import { Card, CardHeader, CardBody } from 'reactstrap';
import { _getValue } from '../../../../utils/form';
import {
  FieldRow,
  FieldColumn,
  FieldLabel,
  FieldValue
} from '../../../../styled/Fields';
import propTypes from 'prop-types';
import Loading from 'react-loading-animation';

const SizeCard = props => (
  <Card>
    <CardHeader><i className="fa fa-align-justify"></i> Size Details</CardHeader>
    <CardBody>
      {
        props.isLoading?
        <Loading/>
        :
        <div>
          <FieldRow>
            <FieldColumn className="col-xs-12 col-md-6">
              <FieldLabel>Size ID</FieldLabel>
              <FieldValue>
                <span>{_getValue(props.size.id)}</span>
              </FieldValue>
            </FieldColumn>
            <FieldColumn className="col-xs-12 col-md-6">
              <FieldLabel>Size For</FieldLabel>
              <FieldValue>
                <span>{_getValue(props.size.sex)}</span>
              </FieldValue>
            </FieldColumn>
          </FieldRow>
          <FieldRow>
            <FieldColumn className="col-xs-6 col-md-3">
              <FieldLabel>US</FieldLabel>
              <FieldValue>
                <span>{_getValue(props.size.US)}</span>
              </FieldValue>
            </FieldColumn>
            <FieldColumn className="col-xs-6 col-md-3">
              <FieldLabel>UK</FieldLabel>
              <FieldValue>
                <span>{_getValue(props.size.UK)}</span>
              </FieldValue>
            </FieldColumn>
            <FieldColumn className="col-xs-6 col-md-3">
              <FieldLabel>EUR</FieldLabel>
              <FieldValue>
                <span>{_getValue(props.size.EUR)}</span>
              </FieldValue>
            </FieldColumn>
            <FieldColumn className="col-xs-6 col-md-3">
              <FieldLabel>CM</FieldLabel>
              <FieldValue>
                <span>{_getValue(props.size.cm)}</span>
              </FieldValue>
            </FieldColumn>
          </FieldRow>
        </div>
      }
    </CardBody>
  </Card>
)
export default SizeCard;

SizeCard.propTypes={
  isLoading: propTypes.bool,
  userSellId: propTypes.number,
  size: propTypes.object,
}

SizeCard.defaultProps={
  userSellId: 0,
  isLoading: false
}