import React from 'react';
import propTypes from 'prop-types';
import { Card, CardHeader, CardBody, Button, Alert } from 'reactstrap';
import { connect } from 'react-redux';

import { getUserBankAccountList, getDetailUserBankAccount, saveUserBankAccount, deleteUserBankAccount  } from '../../../actions/userBankAccountAction';

import { Tbl, TblHead, TblBody } from '../../../components/Tbl';
import Paginations from '../../../components/Paginations';
import { ModalDelete } from '../../../components/Modals';
import PaginationSummaries from '../../../components/Paginations/PaginationSummaries';
import RowItem from './userBankAccountListCard/RowItem';
import { DEFAULT_SORT_BY } from '../../../constants/settings';
import { generateNumber } from '../../../utils';
import Analytics from "../../../services/Analytics";

import ModalUserBankAccount from './userBankAccountListCard/ModalUserBankAccount';

const DEFAULT_CREATED_SUCCESS_MSG = 'Creating new account bank successfully.';
const DEFAULT_UPDATED_SUCCESS_MSG = 'Updating a account bank successfully.';
const DEFAULT_DELETED_SUCCESS_MSG = 'Deleting a account bank successfully.';

const dataHeader = ['No.', 'Bank', 'Account Number', 'Account Name', 'Updated', 'Action'];
class UserBankAccountListCard extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      _userId: parseInt(this.props.userId),
      _params:{
        sort_by: DEFAULT_SORT_BY
      },
      _userBankAccountForm:{ 
        mode: 'NEW',
        userId: parseInt(this.props.userId)
      },
      _selectedId: null,
      _modalAccountBankFormOpen:false,
      _modalDeleteAccountBankOpen: false,
      _deleteProgress: false,
      _saveProgress: false,
      _isAlertSuccessOpen: false,
      _successMsg: ''
    }
    this._handleModalDeleteToggle = this._handleModalDeleteToggle.bind(this);
    this._handleDeleteBankAccountSubmit = this._handleDeleteBankAccountSubmit.bind(this);
    this._handleModalUserBankAccountFormToggle = this._handleModalUserBankAccountFormToggle.bind(this);
    this._handleNewUserBankAccountForm = this._handleNewUserBankAccountForm.bind(this);
    this._handleUserBankAccountSubmit = this._handleUserBankAccountSubmit.bind(this);
    this._handleAlertSuccessToggle = this._handleAlertSuccessToggle.bind(this);
    this._handleGoto = this._handleGoto.bind(this);
  }

  componentDidMount(){
    this._getList({...this.state._params});
  }

  componentWillReceiveProps(nextProps){
    const { bankAccount } = nextProps.dataBankAccount;
    if(bankAccount.isSubmit !== this.props.dataBankAccount.bankAccount.isSubmit && !bankAccount.isSubmit){
      if(bankAccount.error === null){
        if(bankAccount.data.deleted && this.state._deleteProgress){
          this.setState({
            _deleteProgress: false,
            _modalDeleteAccountBankOpen: false,
            _selectedId: null,
            _successMsg: DEFAULT_DELETED_SUCCESS_MSG
          },()=>{
            this._handleAlertSuccessToggle()
            this._getList({...this.state._params})
          })
        }
        if(this.state._saveProgress){
          this.setState({
            _saveProgress: false,
            _modalAccountBankFormOpen: false,
            _successMsg: this.state._userBankAccountForm.mode==='NEW'? DEFAULT_CREATED_SUCCESS_MSG:DEFAULT_UPDATED_SUCCESS_MSG,
            _params: {
              ...this.state._params,
              page: 1
            }
          },()=>{
            this._handleAlertSuccessToggle()
            this._getList({...this.state._params})
          })
        }
      }
      else{
        this.setState({
          _saveProgress: false
        })
      }
    }
  }

  _getList(params){
    const { _userId } = this.state;
    this.props.getList(_userId, { params })
  }

  _handleGoto(page){
    const _params=this.state._params;
    _params.page=page;
    this.setState({_params}, ()=>this._getList(this.state._params));
  }
  
  _renderRowItem(){
    if(this.props.dataBankAccount.list.isFetch === false && this.props.dataBankAccount.list.status_code===200){
      const { data, current_page, per_page } = this.props.dataBankAccount.list;
      return data.map((item, index)=>{
        const rowNumber = generateNumber(per_page,current_page,index);
        return (
          <RowItem
            key={rowNumber}
            id={item.id}
            bankName={item.bank_name}
            accountName={item.account_name}
            accountNumber={item.account_number}
            updatedAt={item.updated_at}
            rowId={rowNumber}
            onUpdateBtnClick={ev => this._handleUpdateUserBankAccountForm(item) }
            onDeleteBtnClick={ev => this._handleDeleteButtonClick(item.id)}
          />
        )
      })
    }
    return null;
  }

  _handleDeleteButtonClick(id){
    this.setState({
      _selectedId: id,
      _modalDeleteAccountBankOpen: !this.state._modalDeleteAccountBankOpen
    })
  }

  _handleDeleteBankAccountSubmit(){
    const email = this.props.guardData;
    this.setState({
      _deleteProgress: true,
      _isAlertSuccessOpen: false
    },()=>{
      const { _userId, _selectedId } = this.state;
      this.props.delete(_userId, _selectedId);
      Analytics.recordDeleteBackAccount(
        _userId, 
        _selectedId,
        email
      )
    })
  }

  _handleModalDeleteToggle(){
    if(!this.state._deleteProgress)
      this.setState({
        _modalDeleteAccountBankOpen: !this.state._modalDeleteAccountBankOpen
      })
  }

  _handleModalUserBankAccountFormToggle(){
    this.setState({
      _modalAccountBankFormOpen: !this.state._modalAccountBankFormOpen
    })
  }

  _handleNewUserBankAccountForm(){
    this.setState({
      _userBankAccountForm:{
        userId: this.state._userId,
        mode: 'NEW',
      },
      _modalAccountBankFormOpen: true
    })
  }

  _handleUpdateUserBankAccountForm(item){
    this.setState({
      _userBankAccountForm:{
        userId: this.state._userId,
        id: item.id,
        bankName: item.bank_name,
        accountName: item.account_name,
        accountNumber: item.account_number,
        mode: 'EDIT',
      },
      _modalAccountBankFormOpen: true
    })
  }

  _handleUserBankAccountSubmit(userId, payloads){
    const email = this.props.guardData;
    this.setState({
      _saveProgress: true,
      _isAlertSuccessOpen: false
    }, ()=>{
      this.props.save(userId, payloads);
      Analytics.recordAddBackAccount(
        userId, 
        payloads.account_name, 
        payloads.account_number, 
        payloads.bank_name, 
        email
      )
    })
  }

  _handleAlertSuccessToggle(){
    this.setState({
      _isAlertSuccessOpen: !this.state._isAlertSuccessOpen
    })
  }

  _renderSuccessMsg(){
    if(this.state._isAlertSuccessOpen){
      return(
        <Alert
          isOpen={this.state._isAlertSuccessOpen}
          toggle={this._handleAlertSuccessToggle}
        >
          <strong>Success! </strong> { this.state._successMsg }
        </Alert>
      )
    }
    return null;
  }
  render(){
    const { dataBankAccount } = this.props;
    const showPagination = (dataBankAccount.list.status_code === 200 && dataBankAccount.list.data.length)||null;
    return(
      <Card>
        <CardHeader>
          <i className="fa fa-align-justify"></i>
          Bank Account List
        </CardHeader>
        <CardBody>
          <ModalDelete
            isOpen={this.state._modalDeleteAccountBankOpen}
            toggle={this._handleModalDeleteToggle}
            onDelete={this._handleDeleteBankAccountSubmit}
          />
          <ModalUserBankAccount
            isOpen={this.state._modalAccountBankFormOpen}
            {...this.state._userBankAccountForm}
            dataBankAccount={this.props.dataBankAccount.bankAccount}
            onCancel={this._handleModalUserBankAccountFormToggle}
            onSubmit={this._handleUserBankAccountSubmit}
            isSubmit={this.state._saveProgress}
          />
          {/* <div className="mb-3">
            <Button title="New Bank Account" onClick={this._handleNewUserBankAccountForm}><i className="fa fa-plus"></i> New Bank Account</Button>
          </div> */}
          { this._renderSuccessMsg() }
          <div className="table-responsive">
            <PaginationSummaries
              from={dataBankAccount.list.from}
              to={dataBankAccount.list.to}
              total={dataBankAccount.list.total}
            />
            <Tbl>
              <TblHead dataHeader={dataHeader}/>
              <TblBody
                isLoad={dataBankAccount.list.isFetch}
                hasRow={dataBankAccount.list.status_code===200 && dataBankAccount.list.data.length}
                columnCount={dataHeader.length}
              >
              { this._renderRowItem() }
              </TblBody>
            </Tbl>
          </div>
          {
            showPagination&&
            <Paginations 
              size="sm" 
              lastPage={dataBankAccount.list.last_page} 
              perPage={dataBankAccount.list.per_page}
              total={dataBankAccount.list.total}
              maxPage={8}
              path={dataBankAccount.list.path}
              currentPage={dataBankAccount.list.current_page} 
              prevPageUrl={dataBankAccount.list.prev_page_url} 
              nextPageUrl={dataBankAccount.list.next_page_url} 
              handleGotoCallback={this._handleGoto}
            />
          }
        </CardBody>
      </Card>
    )
  }
}
const mapStateToProps = ( { userBankAccount, auth: { email } })=>({
  dataBankAccount: userBankAccount,
  guardData: {email}
})

const mapDispatchToProps = (dispatch)=>({
  getList: ( userId, queries ) => dispatch(getUserBankAccountList(userId, queries)),
  save: ( userId, payload ) => dispatch(saveUserBankAccount(userId, payload)),
  find: ( userId, id ) => dispatch(getDetailUserBankAccount(userId, id)),
  delete: ( userId, id ) => dispatch(deleteUserBankAccount(userId, id))
})

export default connect(mapStateToProps, mapDispatchToProps)(UserBankAccountListCard)