import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Row,
  Col,
  Card,
  CardBody,
  CardText,
} from 'reactstrap';

import Paginations from '../../../components/Paginations';
import PaginationSummaries from '../../../components/Paginations/PaginationSummaries';
import { CardHeaderWithToolbars,Title } from '../../../components/Card/CardHeaderWithToolbars';
import Toolbars from './Filters/Toolbars';
import FiltersPanel from '../Filters';
import FilterContainer from '../../../components/Container/FilterContainer/FilterContainer';
import { Tbl, TblHead, TblBody } from '../../../components/Tbl';
import { ModalDeliver } from './Modals';
import { ModalBulkUpload, ModalInfoCreditCard } from '../../../components/Modals';
import { generateNumber } from '../../../utils';
import { DEFAULT_SORT_BY, DEFAULT_PER_PAGE } from '../../../constants/settings';
import { getPendingDeliveringSaleList, doUpdateAwbNumber } from '../../../actions/saleAction';
import RowHeader from './RowHeader';
import ModalPurchasePrice from '../ModalPurchasePrice';
import ModalFollowUp from '../ModalFollowUp';
import Analytics from "../../../services/Analytics";
import CourierRowItem from './CourierRowItem';
import { bulkAWBFormats } from '../../../config';
import { doBulkAWB } from '../../../actions/bulkUploadAwbAction';
import { bulkUploadAWBErrorMessageSelector, bulkUploadAWBIsSubmitSelector, bulkUploadAWBSuccessSelector } from '../../../reducers/selectors/bulkUploadAWBSelector';


const defaultParams = {
  sort_by : DEFAULT_SORT_BY,
  per_page : DEFAULT_PER_PAGE
}

class CourierDelivering extends Component{
  constructor(props){
    super(props);
    this.state={
      _filterOpen: false,
      _params:{
        ...defaultParams,
        courier: this.props.courier
      },
      _autodisburse: true,
      _selectedRow: null,
      _modalDeliver:false,
      _formDeliverSelected:null,
      _deliverProcess:false,
      _modalCreditCard: false,
      _invoiceNumber: null,
      _dataModalPurchasePrice: null,
      _isModalPurchasePrice: false,
      _dataModalFollowUp: null,
      _isModalFollowUp: false,
      _modalBulkUpload: false,
    }
    this._handleApplyFilter = this._handleApplyFilter.bind(this);
    this._handleToggleFilterPanel = this._handleToggleFilterPanel.bind(this);
    this._handleGoto = this._handleGoto.bind(this);
    this._refreshList = this._refreshList.bind(this);
    this._handleRefresh = this._handleRefresh.bind(this);
    this._handleResetFilter = this._handleResetFilter.bind(this);
    this._onCloseDeliverModal = this._onCloseDeliverModal.bind(this);
    this._handleCategoryChanged = this._handleCategoryChanged.bind(this);
    this._handleToggleModalCreditCard = this._handleToggleModalCreditCard.bind(this);
    this._handleToggleModalBulkUpload = this._handleToggleModalBulkUpload.bind(this);
    this._handleOnSubmitBulkUpload = this._handleOnSubmitBulkUpload.bind(this);
  }

  componentDidMount(){
    this._getList(this.state._params);
  }

  componentWillReceiveProps(nextProps){
    if(this.state._deliverProcess
      &&this.props.sale.isSubmit
      &&nextProps.sale.isSubmit!==this.props.sale.isSubmit) {
        if(nextProps.sale.detail.status_code===200){
          this._getList(this.state._params);
          this.setState({
            _deliverProcess:!this.state._deliverProcess,
            _modalDeliver:!this.state._modalDeliver,
            _selectedRow: null
          });
        }
        else{
          this.setState({_deliverProcess:!this.state._deliverProcess});
        }
    }
  }

  _getList(rawParams = {}){
    let p = { ...rawParams }
    if(p.voucher){
      const { voucher, ...other } = p;
      if(Number(voucher.id)){
        p = { ...other, voucher_id: voucher.id }
      }
      else{
        p = { ...other, voucher_code: voucher.code }
      }
    }
    if(p.seller && p.seller.id){
      const { seller, ...other } = p;
      p = { ...other, seller_id: seller.id }
    }
    const params = Object.keys(p)
    .filter(key => p[key]!='')
    .reduce((obj, current)=> ({...obj, [current]: p[current]}), {});
    this.props.getList({params});
  }

  _refreshList(){
    // Exclude page...
    const _params = { ...this.state._params, page: 1 };
    this.setState({
      _params:{ ..._params }
    },()=>this._getList(this.state._params));
  }

  _toggleFilterPanel(){
    this.setState({_filterOpen:!this.state._filterOpen})
  }

  _handleToggleFilterPanel(e){
    e.preventDefault();
    this._toggleFilterPanel();
  }

  _handleRefresh(e){
    e.preventDefault();
    this._refreshList();
  }

  _handleResetFilter(e){
    e.preventDefault();
    const newState = this.state._filterOpen?{_filterOpen:!this.state._filterOpen}:{};
    this.setState({
      ...newState,
      _params:{ ...defaultParams },
      _isResetFilter: true
    },()=>this._getList(this.state._params));
  }

  _handleApplyFilter(payloadFilter){
    this.setState({
      _params: {
        ...this.state._params,
        ...payloadFilter,
        page: 1
      }
    },()=>this._getList(this.state._params));
  }

  _handleGoto(page){
    const _params=this.state._params;
    _params.page=page;
    this.setState({_params}, ()=>this._getList(this.state._params));
  }

  _onOpenCreditCardModal(item){
    this.setState({_invoiceNumber: item.invoice_number},()=>this._handleToggleModalCreditCard());
  }

  _handleToggleModalCreditCard() {
    this.setState({ _modalCreditCard: !this.state._modalCreditCard});
  }

  _handleToggleModalBulkUpload = () => {
    this.setState({ _modalBulkUpload: !this.state._modalBulkUpload });
  }

  _handleOnSubmitBulkUpload = (payload) => {
    this.props.doBulkAWB(payload);
  }

  _togglePurchasePriceModal(value, payloads = null){
    this.setState({
      _isModalPurchasePrice: value,
      _dataModalPurchasePrice: payloads? { ...payloads }: null
    })
  }

  _toggleFollowUpModal = (value, payloads = null) => {
    this.setState({
      _isModalFollowUp: value,
      _dataModalFollowUp: payloads? { ...payloads }: null
    })
  }

  _onRenderRowItem(){
    if(this.props.sale.list.status_code===200&&this.props.sale.list.data.length>0) {
      const { current_page,per_page } =  this.props.sale.list;
      return this.props.sale.list.data.map((item,index)=>{
        const rowId = generateNumber(per_page,current_page,index);
        return(
          <CourierRowItem
            key={rowId}
            data={item}
            rowId={rowId}
            id={item.id}
            quantity={item.offer.quantity}
            buyerEmail={item.buyer_email}
            invoiceNumber={item.invoice_number}
            orderDate={item.created_at}
            updatedAt={item.updated_at}
            totalAmount={item.total_price}
            displayName={item.sale_product.display_name}
            SKU={item.sale_product.SKU}
            colour={item.sale_product.colour}
            preOrder={item.sale_product.pre_order}
            preVerified={item.sale_product.pre_verified}
            size={item.sale_product.size.US}
            isSelected={this.state._selectedRow === item.id}
            hijacked={item.hijacked}
            status={item.status}
            autoDisburse={item.autodisburse}
            onOpenDeliverModal={()=>this._onOpenDeliverModal(item)}
            consignmentId={item.sale_product.consignment_id}
            sneakersCondition={item.sale_product.sneakers_condition}
            category={item.sale_product.category_name}
            bank={item.offer.bank_code}
            paymentVia={item.offer.payment_via}
            paymentMethod={item.offer.payment_method}
            onOpenCreditCard={()=>this._onOpenCreditCardModal(item)}
            marked={item.marked}
            notes={item.sale_notes}
            loginId={this.props.injectedProps.id}
            legitCheckLogs={item.legit_check_logs}
            // markedNotes={item.marked_notes}
            markedAt={item.open_marked_at}
            markedBy={item.open_marked_by}
            closeMarkedAt={item.close_marked_at}
            unreadNoteCount={item.unread_notes_count}
            rack={item.offer.user_sell.rack}
            purchasePrice={item.purchase_price}
            compensation={item.compensation_amount}
            followUpStatus={item.follow_up_status}
            sellerEmail={item.offer.user_sell.user.email}
            courier={item.sale_shipping.ka_courier}
            kaSent={item.ka_sent}
            openPurchasePriceModal={(payloads) => this._togglePurchasePriceModal(true, payloads)}
            openFollowUpModal={(payloads) => this._toggleFollowUpModal(true, payloads)}
          />
        )
      })
    }
    return null;
  }

  _onDelivery(id, data){
    const { email } = this.props;
    const { _formDeliverSelected } = this.state;

    this.setState({_deliverProcess:true},()=>{
      this.props.deliverSneaker(id, data);
      Analytics.recordDeliverOrder(
        email,
        data.notes,
        _formDeliverSelected.invoice_number,
        _formDeliverSelected.buyer_email,
        _formDeliverSelected.price
      );
    })
  }

  _onOpenDeliverModal(item){
    this.setState({_formDeliverSelected: item, _selectedRow: item.id},()=>this._toggle('_modalDeliver'));
  }

  _onCloseDeliverModal(){
    this.setState({_formDeliverSelected: null, _selectedRow: null},()=>this._toggle('_modalDeliver'));
  }

  _toggle(_modalState){
    const lastState={...this.state};
    lastState[_modalState]=!lastState[_modalState];
    this.setState(lastState);
  }

  _onRenderPagination(){
    const { status_code,data } = this.props.sale.list;
    const hasData = (status_code===200 && data.length) || null;
    return hasData && (
      <Paginations
        size="sm"
        lastPage={this.props.sale.list.last_page}
        perPage={this.props.sale.list.per_page}
        total={this.props.sale.list.total}
        maxPage={8}
        path={this.props.sale.list.path}
        currentPage={this.props.sale.list.current_page}
        prevPageUrl={this.props.sale.list.prev_page_url}
        nextPageUrl={this.props.sale.list.next_page_url}
        handleGotoCallback={this._handleGoto}
      />
    );
  }

  async _handleCategoryChanged(category){
    await this.setState({
      _params: {
        ...this.state._params,
        category: category === ''? category: category.value,
        page: 1,
      },
    });
    const { _params } = this.state;
    this._getList(_params);
  }

  render(){
    return (
      <div className="animated fadeIn">
        <Row>
          <ModalFollowUp
            data={this.state._dataModalFollowUp}
            isOpen={this.state._isModalFollowUp}
            toggle={() => this._toggleFollowUpModal(false)}
          />
          <ModalPurchasePrice
            data={this.state._dataModalPurchasePrice}
            isOpen={this.state._isModalPurchasePrice}
            toggle={() => this._togglePurchasePriceModal(false)}
          />
          <ModalDeliver
            isOpen={this.state._modalDeliver}
            onCancel={this._onCloseDeliverModal}
            onSubmit={(id,form)=>this._onDelivery(id,form)}
            sale={this.state._formDeliverSelected}
            error={this.props.sale.error}
            isSubmit={this.state._deliverProcess}
          />
          <ModalInfoCreditCard
            isOpen={this.state._modalCreditCard}
            toggle={this._handleToggleModalCreditCard}
            invoiceNumber={this.state._invoiceNumber}
          />
          <ModalBulkUpload
            isOpen={this.state._modalBulkUpload}
            toggle={this._handleToggleModalBulkUpload}
            bulkFormat={bulkAWBFormats.reduce((prev,current)=>`${prev} & ${current}`)}
            onSubmit = {this._handleOnSubmitBulkUpload}
            isLoading={this.props.bulkUploadAWBIsSubmit}
            errorMessage={this.props.bulkUploadAWBErrorMessage}
            successMessage={this.props.bulkUploadAWBSuccess}
          />
          <Col xs={12}>
            <Card>
              <CardHeaderWithToolbars>
                <Title>
                  <i className="fa fa-align-justify"></i>
                  <span>{this.props.title}</span>
                </Title>
                <Toolbars
                  isOpen={this.state._filterOpen}
                  onToggleFilter={this._handleToggleFilterPanel}
                  onRefreshList={this._handleRefresh}
                  onResetFilter={this._handleResetFilter}
                  onToggleModalBulkUpload={this._handleToggleModalBulkUpload}
                />
              </CardHeaderWithToolbars>
              <FilterContainer>
                <FiltersPanel
                  {...this.state._params}
                  onApplyFilter={this._handleApplyFilter}
                  isReset={this.state._isResetFilter}
                  onResetFilterCallback={()=>this.setState({_isResetFilter: false, _filterOpen: false})}
                  isOpen={this.state._filterOpen}
                />
              </FilterContainer>
              <CardBody>
                <CardText>
                  In this section we have to set awb number of the order and notify user after submit successfully.
                </CardText>
                <div className="table-responsive">
                  <PaginationSummaries
                    from={this.props.sale.list.from}
                    to={this.props.sale.list.to}
                    total={this.props.sale.list.total}
                  />
                  <Tbl>
                    <TblHead>
                      <RowHeader
                        categoryName={this.state._params.category}
                        categoryValue={this.state._params.category}
                        onCategoryChanged={this._handleCategoryChanged}
                      />
                    </TblHead>
                    <TblBody
                      isLoad={this.props.sale.isFetch}
                      hasRow={this.props.sale.list.status_code===200&&this.props.sale.list.data.length>0}
                      columnCount={7}
                    >
                    { this._onRenderRowItem() }
                    </TblBody>
                  </Tbl>
                </div>
                {this._onRenderPagination()}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    )
  }
}

const mapStateToProps= (state) => {
  const {sale, auth: {id: authId, isFetch, isLogged, roles, id, email}} = state;
	return {
    sale,
    email,
    guardData: {isFetch, isLogged, roles, id: authId, id, email},
    bulkUploadAWBIsSubmit: bulkUploadAWBIsSubmitSelector(state),
    bulkUploadAWBErrorMessage: bulkUploadAWBErrorMessageSelector(state),
    bulkUploadAWBSuccess: bulkUploadAWBSuccessSelector(state),
	}
};

const mapDispatchToProps = (dispatch) => {
	return {
    getList:(query) => dispatch(getPendingDeliveringSaleList(query)),
    deliverSneaker:(id,payload)=>dispatch(doUpdateAwbNumber(id,payload)),
    doBulkAWB: (payload) => dispatch(doBulkAWB(payload)),
	}
};

export default connect(mapStateToProps,mapDispatchToProps)(CourierDelivering);