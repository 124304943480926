export const USER_SELL_EXPIRY_DAYS = 30;

export const NEW_STATUS = 'NEW';
export const SELLER_SENT_STATUS = 'SELLER_SENT';
export const CANCELLED_STATUS = 'CANCELLED';
export const KA_RECEIVED_STATUS = 'KA_RECEIVED';
export const VERIFICATION_PASSED_STATUS = 'VERIFICATION_PASSED';
export const VERIFICATION_FAILED_STATUS = 'VERIFICATION_FAILED';
export const DELIVERING_STATUS = 'KA_SENT_BUYER';
export const REFUNDED_STATUS = 'REFUNDED';
export const DONE_BY_ADMIN_STATUS = 'DONE_BY_ADMIN';
export const DONE_STATUS = 'DONE';
export const KA_RETURNED_SELLER_STATUS = 'KA_RETURNED_SELLER';
export const ACTIVE_CONSIGNMENT_STATUS = 'ACTIVE_CONSIGNMENT';
export const CONSIGNMENT_REJECTED_STATUS = 'CONSIGNMENT_REJECTED';

const kaSalesEnum = {
  'NEW': 'New',
  'SELLER_SENT': 'Seller has been sent',
  'KA_RECEIVED': 'On Warehouse Now',
  'VERIFICATION_PASSED': 'Verification Passed',
  'VERIFICATION_FAILED': 'Verification Failed',
  'KA_SENT_BUYER': 'On Delivering',
  'KA_RETURNED_SELLER': 'Return to Seller',
  'DONE_BY_ADMIN': 'Done by Admin',
  'DONE_BY_CRON': 'Done by System',
  'DONE_BY_BUYER': 'Done',
  'CANCELLED': 'Cancelled',
  'REFUNDED': 'Refunded',
}

const kaConsignmentEnum = {
  'NEW': 'New',
  'VERIFICATION_PASSED': 'Verification Passed',
  'VERIFICATION_FAILED': 'Verification Failed',
  'KA_SENT_BUYER': 'On Delivering',
  'KA_RETURNED_SELLER': 'Return to Seller',
  'DONE_BY_ADMIN': 'Done by Admin',
  'DONE_BY_CRON': 'Done by System',
  'DONE_BY_BUYER': 'Done',
  'CANCELLED': 'Cancelled',
  'REFUNDED': 'Refunded',
  'ACTIVE_CONSIGNMENT': 'Active Consignment',
  'KA_RECEIVED': 'Verification Progress',
  'CONSIGNMENT_REJECTED': 'Rejected Consignment',
}

const kaPaymentType = {
  'bank_transfer': 'Transfer',
  'bca_va': 'BCA VA',
  'bni_va': 'BNI VA',
  'permata_va': 'PERMATA VA',
  'mandiri_va': 'MANDIRI VA',
  'virtual_account': 'VA',
  'credit_card': 'Credit Card',
  'akulaku': 'Akulaku',
  'atome': 'AtomeID',
  'kredivo': 'Kredivo',
  'full_wallet': 'Full Wallet',
  'installments': 'Installments',
  'gopay': 'Gopay',
  'slash': 'Slash',
  'kick_point': 'Kick Point',
  'cash': 'Cash',
  'cc_bca_edc': 'CC BCA EDC',
  'qris_bca_edc': 'QRIS BCA EDC',
  'qris_bni_edc': 'QRIS BNI EDC',
  'cc_bni_edc': 'CC BNI EDC',
  'qris_mandiri_edc': 'QRIS MANDIRI EDC',
  'cc_mandiri_edc': 'CC MANDIRI EDC',
  'cc_bri_edc': 'CC BRI EDC',
  'qris_bri_edc': 'QRIS BRI EDC',
  'cc_bca_edc_ev': 'CC BCA EDC EVENT',
  'qris_bca_edc_ev': 'QRIS BCA EDC EVENT',
  'qris_bni_edc_ev': 'QRIS BNI EDC EVENT',
  'cc_bni_edc_ev': 'CC BNI EDC EVENT',
  'qris_mandiri_edc_ev': 'QRIS MANDIRI EDC EVENT',
  'cc_mandiri_edc_ev': 'CC MANDIRI EDC EVENT',
  'cc_bri_edc_ev': 'CC BRI EDC EVENT',
  'qris_bri_edc_ev': 'QRIS BRI EDC EVENT',
  'kredivo_in_store': 'Kredivo in Store'
}

const kaPaymentTypeOptions = [
  { name: 'Transfer', value: 'bank_transfer' },
  { name: 'BCA VA', value: 'bca_va' },
  { name: 'BRI VA', value: 'bri_va' },
  { name: 'BNI VA', value: 'bni_va' },
  { name: 'PERMATA VA', value: 'permata_va' },
  { name: 'MANDIRI VA', value: 'mandiri_va' },
  { name: 'Virtual Account', value: 'virtual_account' },
  { name: 'Credit Card', value: 'credit_card' },
  { name: 'Akulaku', value: 'akulaku' },
  { name: 'AtomeID', value: 'atome' },
  { name: 'Kredivo', value: 'kredivo' },
  { name: 'Full Wallet', value: 'full_wallet' },
  { name: 'Installments', value: 'installments' },
  { name: 'Gopay', value: 'gopay' },
  { name: 'Slash', value: 'slash' },
  { name: 'Kick Point', value: 'kick_point' },
  { name: 'Cash', value: 'cash' },
  { name: 'CC BCA EDC', value: 'cc_bca_edc' },
  { name: 'QRIS BCA EDC', value: 'qris_bca_edc' },
  { name: 'CC MANDIRI EDC', value: 'cc_mandiri_edc' },
  { name: 'QRIS MANDIRI EDC', value: 'qris_mandiri_edc' },
  { name: 'CC BNI EDC', value: 'cc_bni_edc' },
  { name: 'QRIS BNI EDC', value: 'qris_bni_edc' },
  { name: 'CC BRI EDC', value: 'cc_bri_edc' },
  { name: 'QRIS BRI EDC', value: 'qris_bri_edc' },
  { name: 'CC BCA EDC EVENT', value: 'cc_bca_edc_ev' },
  { name: 'QRIS BCA EDC EVENT', value: 'qris_bca_edc_ev' },
  { name: 'CC MANDIRI EDC EVENT', value: 'cc_mandiri_edc_ev' },
  { name: 'QRIS MANDIRI EDC EVENT', value: 'qris_mandiri_edc_ev' },
  { name: 'CC BNI EDC EVENT', value: 'cc_bni_edc_ev' },
  { name: 'QRIS BNI EDC EVENT', value: 'qris_bni_edc_ev' },
  { name: 'CC BRI EDC EVENT', value: 'cc_bri_edc_ev' },
  { name: 'QRIS BRI EDC EVENT', value: 'qris_bri_edc_ev' },
]

export const createPaymentTypeOptions = [
  { name: 'Cash', value: 'cash' },
  { name: 'BCA VA', value: 'bca_va' },
  { name: 'BRI VA', value: 'bri_va' },
  { name: 'BNI VA', value: 'bni_va' },
  { name: 'PERMATA VA', value: 'permata_va' },
  { name: 'MANDIRI VA', value: 'mandiri_va' },
  { name: 'CC BCA EDC', value: 'cc_bca_edc' },
  { name: 'QRIS BCA EDC', value: 'qris_bca_edc' },
  { name: 'CC MANDIRI EDC', value: 'cc_mandiri_edc' },
  { name: 'QRIS MANDIRI EDC', value: 'qris_mandiri_edc' },
  { name: 'CC BNI EDC', value: 'cc_bni_edc' },
  { name: 'QRIS BNI EDC', value: 'qris_bni_edc' },
  { name: 'CC BRI EDC', value: 'cc_bri_edc' },
  { name: 'QRIS BRI EDC', value: 'qris_bri_edc' },
  { name: 'KREDIVO IN STORE', value: 'kredivo_in_store' },
  { name: 'CC BCA EDC EVENT', value: 'cc_bca_edc_ev' },
  { name: 'QRIS BCA EDC EVENT', value: 'qris_bca_edc_ev' },
  { name: 'CC MANDIRI EDC EVENT', value: 'cc_mandiri_edc_ev' },
  { name: 'QRIS MANDIRI EDC EVENT', value: 'qris_mandiri_edc_ev' },
  { name: 'CC BNI EDC EVENT', value: 'cc_bni_edc_ev' },
  { name: 'QRIS BNI EDC EVENT', value: 'qris_bni_edc_ev' },
  { name: 'CC BRI EDC EVENT', value: 'cc_bri_edc_ev' },
  { name: 'QRIS BRI EDC EVENT', value: 'qris_bri_edc_ev' },
];

const kaPaymentStatusOptions = [
  { name: 'Pending', value: 'PENDING' },
  { name: 'Verify Payment', value: 'VERIFYING_PAYMENT' },
  { name: 'Verify Bid', value: 'VERIFYING_BID' },
  { name: 'Paid', value: 'COMPLETED' },
  { name: 'Rejected', value: 'REJECTED' },
  { name: 'Expired', value: 'EXPIRED' },
  { name: 'Cancelled', value: 'CANCELLED' },
  { name: 'Pending Refund', value: 'PENDING_REFUND' },
  { name: 'Refunded', value: 'REFUNDED' },
]

const kaPaymentViaOptions = [
  { name: 'Sprintasia', value: 'spa' },
  { name: 'Xendit', value: 'xnd' },
  { name: 'Midtrans', value: 'vt' }
]

export const paymentViaOptions = kaPaymentViaOptions;

export const getPaymentStatus = payment_status => {
  var status = 'Undefined';
  kaPaymentStatusOptions.forEach(item => {
    if (item.value == payment_status) {
      status = item.name;
      return;
    }
  })
  return status;
}
export const paymentStatusOptions = kaPaymentStatusOptions;
export const paymentTypeOptions = kaPaymentTypeOptions;
export const getPaymentType = payment_status => {
  if (payment_status && Object.keys(kaPaymentType).filter(s => s === payment_status).length)
    return kaPaymentType[payment_status];
  return 'Undefined';
}

export const getStatusSales = status => {
  if (status && Object.keys(kaSalesEnum).filter(s => s === status).length)
    return kaSalesEnum[status];
  return 'Undefined';
}

export const getStatusConsignment = status => {
  if (status && Object.keys(kaConsignmentEnum).filter(s => s === status).length)
    return kaConsignmentEnum[status];
  return 'Undefined';
}

export const perPageOptions = [{
  value: 15, label: "Default"
},
{
  value: 25, label: "25"
},
{
  value: 50, label: "50"
},
{
  value: 100, label: "100"
}];

export const settingTypeOptions = [
  {
    value: "text",
    label: "Text",
  },
  {
    value: "numeric",
    label: "Numeric",
  },
  {
    value: "bool",
    label: "Boolean",
  },
  {
    value: "date",
    label: "Date",
  },
  {
    value: "json",
    label: "Json",
  },
];

export const consignmentStatusOptions = [
  { value: 'awaiting_awb_number', label: 'Awaiting AWB Number' },
  { value: 'active_consignment', label: 'Active Consignment' },
  { value: 'on_loan', label: 'On Loan' },
  { value: 'verification_passed', label: 'Verification Passed' },
  { value: 'verification_failed', label: 'Verification Failed' },
  { value: 'verification_progress', label: 'Verification Progress' },
  { value: 'sneakers_on_the_way', label: 'Sneakers On The Way' },
  { value: 'cancelled', label: 'Cancelled' },
  { value: 'returned', label: 'Returned' },
];

export const consignmentFailedStatusOptions = [
  { value: 'verification_failed', label: 'Verification Failed' },
  { value: 'cancelled', label: 'Cancelled' },
  { value: 'returned', label: 'Returned' },
];

export const sellRequestStatusOptions = [
  { value: 'draft', label: 'Draft' },
  { value: 'awaiting_review', label: 'Awaiting Review' },
  { value: 'awaiting_consignment', label: 'Awaiting Consignment' },
  { value: 'under_review', label: 'Under Review' },
  { value: 'more_info', label: 'More Info' },
  { value: 'approved', label: 'Listing Approved' },
  { value: 'rejected', label: 'Listing Rejected' },
  { value: 'consignment_approved', label: 'Consignment Approved' },
  { value: 'consignment_rejected', label: 'Consignment Rejected' },
  { value: 'approved_as_online_listing', label: 'Approved as Online Listing' },
  { value: 'rescinded', label: 'Rescinded' },
  { value: 'cancelled', label: 'Cancelled' },
  { value: 'hijacked', label: 'Hijacked' },
];

export const sellRequestConstantStatus = [
  'approved', 'consignment_approved', 'approved_as_online_listing', 'rejected', 'consignment_rejected'
];

export const sellRequestBoxConditionOptions = [
  { value: 'SEMPURNA', label: 'Sempurna' },
  { value: 'CACAT', label: 'Cacat' },
  { value: 'NO_BOX', label: 'Missing Box' }
];

export const sellRequestSneakerConditionOptions = [
  { value: 'BARU', label: 'Baru' },
  { value: 'BEKAS', label: 'Bekas' }
];

export const sellerVerificationStatusOptions = [
  { value: 'unverified', label: 'Unverified' },
  { value: 'in_progress', label: 'Pending' },
  { value: 'verified', label: 'Verified' }
];

export const sellerVerificationTypeOptions = [
  { value: 'KTP', label: 'KTP' },
  { value: 'NPWP', label: 'NPWP' }
];

export const genderUsers = [
  { value: 'M', label: 'Male' },
  { value: 'F', label: 'Female' }
]

export const genderSizes = [
  { value: 'M', label: 'Male' },
  { value: 'F', label: 'Female' },
  { value: 'U', label: 'Unisex' },
  { value: 'P', label: 'Primary School' },
  { value: 'GS', label: 'Grade School' },
  { value: 'IT', label: 'Infant Toddler' }
]


const kaOfferEnum = {
  'NEW': 'Pending',
  'ACCEPTED': 'Seller Accepted',
  'REJECTED': 'Rejected',
  'CANCELLED': 'Cancelled',
  'EXPIRED': 'Expired',
  'STOCK_BOUGHT': 'Out Of Stock',
  'STOCK_REMOVED': 'Stock Removed'
}

export const kaRaffleRequestStatus = [
  { name: 'NEW', value: 'PENDING' },
  { name: 'ACCEPTED', value: 'ACCEPTED' },
  { name: 'REJECTED', value: 'REJECTED' },
  { name: 'EXPIRED', value: 'EXPIRED' },
  { name: 'CANCELLED', value: 'CANCELLED' },
]

const kaRaffleRequestStatusOptions = [
  { name: 'PENDING', value: 'PENDING' },
  { name: 'COMPLETED', value: 'COMPLETED' },
  { name: 'FAILED', value: 'FAILED' },
]

export const createdSourceList = [
  {
    name: 'Online (Web & App)',
    value: 'marketplace'
  },
  {
    name: 'In Store/CMS',
    value: 'cms'
  },
]

export const shippingMethodList = [
  {
    name: 'Express',
    value: 'express'
  },
  {
    name: 'Pre-Order',
    value: 'pre_order'
  },
  {
    name: 'Standard',
    value: 'standard'
  }
]

export const consignmentShippingMethodList = [
  {
    name: 'GoSend',
    value: 'gosend',
  },
  {
    name: 'Grab',
    value: 'grab',
  },
  {
    name: 'J&T',
    value: 'jnt',
  },
  {
    name: 'JNE',
    value: 'jne',
  },
  {
    name: 'POS Indonesia',
    value: 'pos_indonesia',
  },
  {
    name: 'SiCepat',
    value: 'sicepat',
  },
  {
    name: 'Tiki',
    value: 'tiki',
  },
  {
    name: 'Self Drop-off',
    value: 'self_drop_off',
  },
]

const kaBoxConditionStatusOptions = [
  { name: 'Perfect', value: 'SEMPURNA' },
  { name: 'Damage', value: 'CACAT' },
  { name: 'No Box', value: 'NO BOX' },
]

export const getBoxConditionStatus = boxCondition => {
  var status = boxCondition;
  kaBoxConditionStatusOptions.forEach(item => {
    if (item.value == boxCondition) {
      status = item.name;
      return;
    }
  })
  return status;
}

const kaSneakersConditionStatusOptions = [
  { name: 'Brand New', value: 'BARU' },
  { name: 'Used', value: 'BEKAS' },
]

export const getSneakersConditionStatus = sneakersCondition => {
  var status = sneakersCondition;
  kaSneakersConditionStatusOptions.forEach(item => {
    if (item.value == sneakersCondition) {
      status = item.name;
      return;
    }
  })
  return status;
}

export const getRaffleRequestStatus = raffle_status => {
  var status = 'Undefined';
  kaRaffleRequestStatusOptions.forEach(item => {
    if (item.value == raffle_status) {
      status = item.name;
      return;
    }
  })
  return status;
}
export const raffleRequestStatusOptions = kaRaffleRequestStatusOptions;

export const _getOfferStatusStr = (status, isExpired, paymentStatus, paymentIsExpired = false) => {
  const getStatusKey = Object.keys(kaOfferEnum).find(key => key === status);
  if (!getStatusKey) return 'Undefined Status';
  switch (getStatusKey) {
    case 'NEW': return isExpired ? 'Reach Limit Time' : kaOfferEnum[getStatusKey];
    case 'ACCEPTED': {
      const pendings = kaPaymentStatusOptions.filter(({ value }) => value === 'VERIFYING_PAYMENT' || value === 'VERIFYING_BID' || value === 'BID')
        .map(item => item.value)
      if (pendings.includes(paymentStatus)) {
        if (paymentStatus !== 'VERIFYING_PAYMENT' && paymentIsExpired) return 'Payment Expired';
        return 'Waiting for Payment'
      }
      else if (paymentStatus === 'COMPLETED') return 'Completed';
      else if (paymentStatus === 'PENDING') return 'Payment Pending';
      else return 'Payment Expired';
    }
    default: return kaOfferEnum[getStatusKey];
  }
}

export const getPaymentVia = via => {
  switch (via) {
    case 'vt': return 'Midtrans';
    case 'xnd': return 'Xendit';
    case 'spa': return 'Sprintasia';
    default: return via;
  }
}

export const courierOptions = [{
  name: 'JNT',
  value: 'jnt'
}, {
  name: 'Gosend/Grab',
  value: 'instant_courier'
}, {
  name: 'Warehouse',
  value: 'warehouse'
}, {
  name: 'Others',
  value: 'others'
}]


const kaConciergeStatusOptions = [
  { name: 'New', value: 'NEW' },
  { name: 'Assigned', value: 'ASSIGNED' },
  { name: 'Awaiting DP', value: 'AWAITING_DP' },
  { name: 'Progress', value: 'IN_PROGRESS' },
  { name: 'Awaiting FP', value: 'AWAITING_FP' },
  { name: 'Sale Created', value: 'SALE_CREATED' },
  { name: 'Not Found', value: 'NOT_FOUND' },
  { name: 'DP Fine', value: 'DP_FINE' },
]
export const conciergeStatusOptions = kaConciergeStatusOptions;
export const getConciergeStatus = conciergeStatus => {
  var status = 'Undefined';
  kaConciergeStatusOptions.forEach(item => {
    if (item.value == conciergeStatus) {
      status = item.name;
      return;
    }
  })
  return status;
}

export const balanceSourceEnum = {
  'BALANCE': 'Kick Credit',
  'BALANCE_WITH_FEE': 'Seller Credit',
  'LOCKED_BALANCE': 'Kick Point'
}

export const paymentMethods = [
  {
    label: 'Bank Transfer',
    value: 'bank_transfer',
    group: 'our_payments',
    active: true
  },
  {
    label: 'Full Wallet',
    value: 'full_wallet',
    group: 'our_payments',
    active: true
  },
  {
    label: 'Kick Point',
    value: 'kick_point',
    group: 'our_payments',
    active: true
  },
  {
    label: 'BCA VA',
    value: 'bca_va',
    group: 'virtual_accounts',
    active: true
  },
  {
    label: 'Mandiri VA',
    value: 'mandiri_va',
    group: 'virtual_accounts',
    active: true
  },
  {
    label: 'Permata VA',
    value: 'permata_va',
    group: 'virtual_accounts',
    active: true
  },
  {
    label: 'BNI VA',
    value: 'bni_va',
    group: 'virtual_accounts',
    active: true
  },
  {
    label: 'BRI VA',
    value: 'bri_va',
    group: 'virtual_accounts',
    active: true
  },
  {
    label: 'Gopay',
    value: 'gopay',
    group: 'e_payments',
    active: true
  },
  {
    label: 'Other VA',
    value: 'virtual_account',
    group: 'virtual_accounts',
    active: true
  },
  {
    label: 'Mandiri CC',
    value: 'mandiri_credit_card',
    group: 'credit_cards',
    active: true,
    info: "Acquired by: Bank Mandiri (installments included). Support Visa and Master cards Only"
  },
  {
    label: 'BCA CC',
    value: 'bca_credit_card',
    group: 'credit_cards',
    active: true,
    info: "Acquired by: Bank BCA (installments included). Support Visa, Master and JCB cards."
  },
  {
    label: 'BCA Installments',
    value: 'bca_installments',
    group: 'credit_cards',
    active: true,
    info: "Acquired by: Bank BCA (installments only). Support Visa, Master and JCB cards."
  },
  {
    label: 'OTHER CC',
    value: 'credit_card',
    group: 'credit_cards',
    active: true,
    info: "Acquired by: Bank BCA. Support Visa, Master and JCB cards."
  },
  {
    label: 'Akulaku Installments',
    value: 'akulaku',
    group: 'online_installments',
    active: true
  },
  {
    label: 'AtomeID Installments',
    value: 'atome',
    group: 'online_installments',
    active: true
  },
  {
    label: 'Kredivo Installments',
    value: 'kredivo',
    group: 'online_installments',
    active: true
  },
  {
    label: 'Slash Installments',
    value: 'slash',
    group: 'online_installments',
    active: true
  },
];

export const statusTypeOption = [
  {
    value: "FAKE",
    label: "Fake (-2)",
  },
  {
    value: "SKU_NOT_MATCH",
    label: "Produk yang dikirim tidak sesuai SKU (-1)",
  },
  {
    value: "NOT_SUITABLE",
    label: "Produk tidak sesuai kondisi listing (-1)",
  },
  {
    value: "MINOR_DEFECT",
    label: "Minor Defect (0)"
  }
];

export const LEGIT_CHECK_STATUS = {
  VERIFIED: 'VERIFIED'
}

export const bankPromoVoucherStatusEnum = {
  OUT_OF_STOCK: 'OUT_OF_STOCK',
  AVAILABLE: 'AVAILABLE',
}

export const bankPromoFormField = {
  title: 'title',
  subtitle: 'subtitle',
  ribbon_tag: 'ribbon_tag',
  description: 'description',
  voucher: 'voucher',
  started_at: 'started_at',
  ended_at: 'ended_at',
  active: 'active',
  image_url: 'image_url',
  image_file: 'image_file',
  bank_promotion: 'bankPromotion',
  image: 'image'
}

export const HTTP_STATUS = {
  OK: 200,
  INTERNAL_SERVER_ERROR: 500,
  UNAUTHORIZED: 401,
  INVALID_INPUT: 422,
  NOT_FOUND: 404
}

export const BANK_PROMO_SUBTITLE_MAX_CHAR = 150;
export const HANDBAGS_CATEGORY = 'handbags';
export const SNEAKERS_CONDITIONS = {
  NEW: 'BARU',
  USED: 'BEKAS'
}
export const BOX_CONDITIONS = {
  DAMAGE_BOX: 'DAMAGED BOX',
}
export const SELL_REQUEST_STATUS = {
  APPROVED: 'approved',
}
