import React from 'react';
import { 
  Row,
  Col, 
  Form, 
  FormGroup, 
  Input, 
  InputGroup, 
  InputGroupAddon, 
  Button } from 'reactstrap';
import propTypes from 'prop-types';

const SearchForm = props => (
  <Form onSubmit={props.onSubmit}>
    <Row>
      <Col xs={12} sm={12} md={6}>
        <FormGroup>
          <InputGroup>
            <Input
              type="text"
              placeholder="Search invoice number or transaction id..."
              name="keyword"
              value={props.keyword}
              onChange={props.onInputChange}
            />
            <InputGroupAddon addonType="append">
              <Button color="primary">Search</Button>
            </InputGroupAddon>
          </InputGroup>
        </FormGroup>
      </Col>
    </Row>
  </Form>
)

export default SearchForm;

SearchForm.propTypes= {
  keyword: propTypes.string,
  onInputChange: propTypes.func,
  onSubmit: propTypes.func.isRequired
}

SearchForm.defaultProps= {
  keyword: ''
}