import React, { useEffect, useState } from 'react';
import { useFetchConsignment } from "../Hooks/fetch/useFetchConsignment";
import { useFetchConsignmentNotes } from "../Hooks/fetch/useFetchConsignmentNotes";
import { generateNumber } from "../../../utils";
import {
    Filters,
    ModalAcceptConsignment,
    ModalReceiveConsignment,
    ModalRejectConsignment,
    RowHeader,
    RowItem
} from "./Components";
import { Card, CardBody, CardText, Col, Collapse, Row } from "reactstrap";
import { CardHeaderWithToolbars, Title } from "../../../components/Card/CardHeaderWithToolbars";
import { Toolbars } from "../Components";
import FilterContainer from "../../../components/Container/FilterContainer/FilterContainer";
import PaginationSummaries from "../../Sales/Monitoring/Paginations/PaginationSummaries";
import { Tbl, TblBody, TblHead } from "../../../components/Tbl";
import Paginations from "../../../components/Paginations";
import { usePutConsignment } from "../Hooks/put/usePutConsignment";
import { CONSIGNMENT_REJECTED_STATUS, KA_RECEIVED_STATUS, NEW_STATUS } from "../../../constants/kickavenue";
import { ModalMarked } from "../QualityControl/Components/ModalMarked";
import { usePutConsignmentMarked } from "../Hooks/put/usePutConsignmentMarked";
import { useFetchProfile } from "../../../reusables/hooks/fetch/useFetchProfile";
import { ModalMarkedConsignment } from "../../../components/Modals";
import { useReadNotes } from '../Hooks/put/useReadNotes';

function OutstandingConsignment({ }) {
    const { fetchConsignment } = useFetchConsignment();
    const { fetchConsignmentNotes } = useFetchConsignmentNotes();
    const { putConsignment } = usePutConsignment();
    const { putConsignmentMarked } = usePutConsignmentMarked();
    const { readNotes } = useReadNotes();
    const { email, id } = useFetchProfile();

    const [loadingAll, setLoadingAll] = useState(false);
    const [loadingHours, setLoadingHours] = useState(false);

    const [selectedModal, setSelectedModal] = useState('');

    const [modalAccept, setModalAccept] = useState(false);
    const [modalAcceptLoading, setModalAcceptLoading] = useState(false);
    const [acceptModalData, setAcceptModalData] = useState(null);
    const [modalReject, setModalReject] = useState(false);
    const [modalRejectLoading, setModalRejectLoading] = useState(false);
    const [rejectModalData, setRejectModalData] = useState(null);
    const [modalReceive, setModalReceive] = useState(false);
    const [modalReceiveLoading, setModalReceiveLoading] = useState(false);
    const [receiveModalData, setReceiveModalData] = useState(null);
    const [modalMarked, setModalMarked] = useState(false);
    const [consignment, setConsignment] = useState(null);

    const [filterOpenAll, setFilterOpenAll] = useState(false);
    const [filterOpenHours, setFilterOpenHours] = useState(false);
    const [allOpen, setAllOpen] = useState(false);
    const [hoursOpen, setHoursOpen] = useState(false);
    const [isResetFilterAll, setIsResetFilterAll] = useState(false);
    const [isResetFilterHours, setIsResetFilterHours] = useState(false);

    const [dataAll, setDataAll] = useState({});
    const [dataHours, setDataHours] = useState({});

    const [keywordAll, setKeywordAll] = useState('');
    const [sellerAll, setSellerAll] = useState(null);
    const [shippingMethodAll, setShippingMethodAll] = useState('');

    const [perPageAll, setPerPageAll] = useState(50);
    const [sortByAll, setSortByAll] = useState('updatedAt_desc');

    const [keywordHours, setKeywordHours] = useState('');
    const [sellerHours, setSellerHours] = useState(null);
    const [shippingMethodHours, setShippingMethodHours] = useState('');

    const [perPageHours, setPerPageHours] = useState(50);
    const [sortByHours, setSortByHours] = useState('updatedAt_desc');

    const [getParamsAll, setGetParamsAll] = useState({
        page: 1,
    });
    const [getParamsHours, setGetParamsHours] = useState({
        page: 1,
    });

    // marked consignment
    const [isConfirmation, setIsConfirmation] = useState(false);
    const [marked, setMarked] = useState('');
    const [markedAt, setMarkedAt] = useState('');
    const [markedBy, setMarkedBy] = useState('');
    const [closeMarkedAt, setCloseMarkedAt] = useState('');
    const [markedNotes, setMarkedNotes] = useState('');
    const [notes, setNotes] = useState([]);
    const [unreadNoteCount, setUnreadNoteCount] = useState('');
    const [noteNextPage, setNoteNextPage] = useState(null);
    const [notesPage, setNotesPage] = useState(1);

    useEffect(() => {
        setLoadingAll(true);
        const params = {
            sort_by: sortByAll,
            per_page: perPageAll,
            type: 'outstanding',
            ...getParamsAll,
        };
        fetchConsignment(params).then((res) => {
            setLoadingAll(false);
            setDataAll(res);
        });
    }, [getParamsAll, perPageAll, sortByAll]);

    useEffect(() => {
        setLoadingHours(true);
        const params = {
            sort_by: sortByHours,
            per_page: perPageHours,
            type: 'outstanding_delivery',
            ...getParamsHours,
        };
        fetchConsignment(params).then((res) => {
            setLoadingHours(false);
            setDataHours(res);
        });
    }, [getParamsHours, perPageHours, sortByHours]);

    useEffect(() => {
        if (keywordAll === '') {
            setGetParamsAll({
                ...getParamsAll,
                keyword: keywordAll,
            });
        }
    }, [keywordAll]);

    useEffect(() => {
        if (keywordHours === '') {
            setGetParamsHours({
                ...getParamsHours,
                keyword: keywordHours,
            });
        }
    }, [keywordHours]);

    useEffect(() => {
        // when opening the modal marked
        if (modalMarked) {
            // if there are notes list available
            if (notes.length) {
                // if there are unread notes
                const ids = notes.filter((item) => !item.read).map((item) => item.id);
                if (ids.length) {
                    // read the notes
                    readNotes(consignment.id, { ids }).then(() => {
                        // then update the unread notes count
                        const newData = selectedModal === 'all' ? [...dataAll.data] : [...dataHours.data];
                        const index = newData.findIndex((item) => item.id === consignment.id);
                        newData[index].unread_notes_count = 0;
                        if (selectedModal === 'all') {
                            setDataAll({
                                ...dataAll,
                                data: newData,
                            });
                        } else {
                            setDataHours({
                                ...dataHours,
                                data: newData,
                            });
                        }
                    });
                }
            }
        }
    }, [modalMarked, notes]);

    const handleToggleFilterPanelAll = () => {
        setFilterOpenAll(!filterOpenAll);
    };

    const handleToggleFilterPanelHours = () => {
        setFilterOpenHours(!filterOpenHours);
    };

    const handleToggleCollapseAll = () => {
        setAllOpen(!allOpen);
        if (!allOpen) {
            setFilterOpenAll(true);
            return;
        }
        setFilterOpenAll(false);
    };

    const handleToggleCollapseHours = () => {
        setHoursOpen(!hoursOpen);
        if (!hoursOpen) {
            setFilterOpenHours(true);
            return;
        }
        setFilterOpenHours(false);
    };

    const handleRefreshAll = async () => {
        setLoadingAll(true);
        const params = {
            type: 'outstanding',
            sort_by: sortByAll,
            per_page: perPageAll,
            ...getParamsAll,
        };
        await fetchConsignment(params).then((res) => {
            setDataAll(res);
            setLoadingAll(false);
        });
    };

    const handleRefreshHours = async () => {
        setLoadingHours(true);
        const params = {
            type: 'outstanding_delivery',
            sort_by: sortByHours,
            per_page: perPageHours,
            ...getParamsHours,
        };
        await fetchConsignment(params).then((res) => {
            setDataHours(res);
            setLoadingHours(false);
        });
    };

    const handleResetFilterAll = () => {
        setKeywordAll('');
        setSellerAll(null);
        setShippingMethodAll('');
        setFilterOpenAll(false);
        setGetParamsAll({
            page: 1,
            type: 'outstanding',
        });
    };

    const handleResetFilterHours = () => {
        setKeywordHours('');
        setSellerHours(null);
        setShippingMethodHours('');
        setFilterOpenHours(false);
        setGetParamsHours({
            page: 1,
            type: 'outstanding_delivery',
        });
    };

    const handleApplyFilterAll = () => {
        const newParams = {};

        if (sellerAll !== null) {
            newParams['seller_id'] = sellerAll.id;
        } else {
            delete newParams['seller_id'];
            delete getParamsAll['seller_id'];
        }

        if (shippingMethodAll !== '') {
            newParams['seller_courier'] = shippingMethodAll;
        } else {
            delete newParams['seller_courier'];
            delete getParamsAll['seller_courier'];
        }

        setGetParamsAll({
            ...getParamsAll,
            ...newParams,
        });
    };

    const handleApplyFilterHours = () => {
        const newParams = {};

        if (sellerHours !== null) {
            newParams['seller_id'] = sellerHours.id;
        } else {
            delete newParams['seller_id'];
            delete getParamsHours['seller_id'];
        }

        if (shippingMethodHours !== '') {
            newParams['seller_courier'] = shippingMethodHours;
        } else {
            delete newParams['seller_courier'];
            delete getParamsHours['seller_courier'];
        }

        setGetParamsHours({
            ...getParamsHours,
            ...newParams,
        });
    };

    const handleSearchAll = () => {
        setGetParamsAll({
            ...getParamsAll,
            keyword: keywordAll,
        });
    };

    const handleSearchHours = () => {
        setGetParamsHours({
            ...getParamsHours,
            keyword: keywordHours,
        });
    };

    const handleCategoryChangedAll = (category) => {
        if (category === '') {
            setGetParamsAll({
                ...getParamsAll,
                category,
            });
        } else {
            setGetParamsAll({
                ...getParamsAll,
                category: category.value,
            });
        }
    };

    const handleCategoryChangedHours = (category) => {
        if (category === '') {
            setGetParamsHours({
                ...getParamsHours,
                category,
            });
        } else {
            setGetParamsHours({
                ...getParamsHours,
                category: category.value,
            });
        }
    };

    const handleGotoAll = (page) => {
        setGetParamsAll({
            ...getParamsAll,
            page,
        });
    };

    const handleGotoHours = (page) => {
        setGetParamsHours({
            ...getParamsHours,
            page,
        });
    };

    const onOpenReceiveModal = (item, type) => {
        setModalReceive(true);
        setReceiveModalData(item);
        setSelectedModal(type);
    };

    const onOpenAcceptModal = (item, type) => {
        setModalAccept(true);
        setAcceptModalData(item);
        setSelectedModal(type);
    };

    const onOpenRejectModal = (item, type) => {
        setModalReject(true);
        setRejectModalData(item);
        setSelectedModal(type);
    };

    const onAccept = (id, payload) => {
        setModalAcceptLoading(true);
        const data = {
            ...payload,
            status: NEW_STATUS,
        }
        putConsignment(id, data).then(() => {
            if (selectedModal === 'all') {
                handleRefreshAll().then(() => { });
            } else {
                handleRefreshHours().then(() => { });
            }
            setModalAcceptLoading(false);
            setModalAccept(false);
            setSelectedModal('');
        });
    };

    const onReject = (id, payload) => {
        setModalRejectLoading(true);
        const data = {
            ...payload,
            status: CONSIGNMENT_REJECTED_STATUS,
            seller_points: 1,
        }
        putConsignment(id, data).then(() => {
            if (selectedModal === 'all') {
                handleRefreshAll().then(() => { });
            } else {
                handleRefreshHours().then(() => { });
            }
            setModalRejectLoading(false);
            setModalReject(false);
            setSelectedModal('');
        });
    };

    const onReceive = (id, payload) => {
        setModalReceiveLoading(true);
        const data = {
            ...payload,
            status: KA_RECEIVED_STATUS,
        }
        putConsignment(id, data).then(() => {
            if (selectedModal === 'all') {
                handleRefreshAll().then(() => { });
            } else {
                handleRefreshHours().then(() => { });
            }
            setModalReceiveLoading(false);
            setModalReceive(false);
            setSelectedModal('');
        });
    };

    const onSubmitMarked = ({ marked_notes, marked = null }) => {
        const newAllData = [...dataAll.data];
        const newHoursData = [...dataHours.data];

        if (marked) {
            const payload = { marked };
            if (marked_notes) {
                payload['marked_notes'] = marked_notes;
            }
            putConsignmentMarked(consignment.id, payload).then(() => {
                if (selectedModal === 'all') {
                    // manually update the data on the list instead of fetching again
                    const index = newAllData.findIndex((item) => item.id === consignment.id);
                    newAllData[index].marked = marked;
                    if (marked_notes) {
                        newAllData[index].marked_notes = marked_notes;
                        newAllData[index].notes_count += 1;
                    }
                    setDataAll({
                        ...dataAll,
                        data: newAllData,
                    });
                } else {
                    // manually update the data on the list instead of fetching again
                    const index = newHoursData.findIndex((item) => item.id === consignment.id);
                    newHoursData[index].marked = marked;
                    if (marked_notes) {
                        newHoursData[index].marked_notes = marked_notes;
                        newHoursData[index].notes_count += 1;
                    }
                    setDataHours({
                        ...dataHours,
                        data: newHoursData,
                    });
                }
                setConsignment(null);
                setModalMarked(false);
            });
            return;
        }

        const payload = {
            marked: isConfirmation ? false : marked || consignment.marked,
            marked_notes
        }

        putConsignmentMarked(consignment.id, payload).then(() => {
            if (selectedModal === 'all') {
                // manually update the data on the list instead of fetching again
                const index = newAllData.findIndex((item) => item.id === consignment.id);
                newAllData[index].marked = isConfirmation ? false : marked || consignment.marked;
                if (marked_notes) {
                    newAllData[index].marked_notes = marked_notes;
                    newAllData[index].notes_count += 1;
                }
                setDataAll({
                    ...dataAll,
                    data: newAllData,
                });
            } else {
                // manually update the data on the list instead of fetching again
                const index = newHoursData.findIndex((item) => item.id === consignment.id);
                newHoursData[index].marked = isConfirmation ? false : marked || consignment.marked;
                if (marked_notes) {
                    newHoursData[index].marked_notes = marked_notes;
                    newHoursData[index].notes_count += 1;
                }
                setDataHours({
                    ...dataHours,
                    data: newHoursData,
                });
            }
            setConsignment(null);
            setModalMarked(false);
        });
    };

    const onOpenMarkedModal = (item, type, confirmation = false) => {
        setConsignment(item);
        setModalMarked(true);
        setSelectedModal(type);
        setMarked(item.marked);
        setMarkedAt(item.open_marked_at);
        setMarkedBy(item.open_marked_by)
        setCloseMarkedAt(item.close_marked_at)
        setMarkedNotes(item.marked_notes)
        setUnreadNoteCount(item.unread_notes_count)
        setNoteNextPage(item.notes?.next_page_url ?? '')
        setIsConfirmation(confirmation);
        const params = {
            per_page: perPageAll,
            ...getParamsAll,
        }
        fetchConsignmentNotes(item.id, params)
            .then((res) => {
                setNotes(res.data)
            })
    };

    const renderAllTableRow = () => {
        return dataAll?.data?.map((item, index) => {
            const rowId = generateNumber(dataAll?.per_page, dataAll?.current_page, index);
            return (
                <RowItem
                    item={item}
                    key={rowId}
                    rowId={rowId}
                    onOpenReceivedModal={() => onOpenReceiveModal(item, 'all')}
                    onOpenAcceptModal={() => onOpenAcceptModal(item, 'all')}
                    onOpenRejectModal={() => onOpenRejectModal(item, 'all')}
                    onOpenMarkedModal={(confirmation) => onOpenMarkedModal(item, 'all', confirmation)}
                />
            )
        })
    };

    const renderHoursTableRow = () => {
        return dataHours?.data?.map((item, index) => {
            const rowId = generateNumber(dataHours?.per_page, dataHours?.current_page, index);
            return (
                <RowItem
                    item={item}
                    key={rowId}
                    rowId={rowId}
                    timer
                    onOpenReceivedModal={() => onOpenReceiveModal(item, 'hours')}
                    onOpenAcceptModal={() => onOpenAcceptModal(item, 'hours')}
                    onOpenRejectModal={() => onOpenRejectModal(item, 'hours')}
                    onOpenMarkedModal={(confirmation) => onOpenMarkedModal(item, 'hours', confirmation)}
                />
            )
        })
    };

    return (
        <>
            <ModalAcceptConsignment
                isOpen={modalAccept}
                onCancel={() => setModalAccept(false)}
                isSubmit={modalAcceptLoading}
                sale={acceptModalData}
                onSubmit={onAccept}
            />
            <ModalRejectConsignment
                isOpen={modalReject}
                onCancel={() => setModalReject(false)}
                isSubmit={modalRejectLoading}
                sale={rejectModalData}
                onSubmit={onReject}
            />
            <ModalReceiveConsignment
                isOpen={modalReceive}
                onCancel={() => setModalReceive(false)}
                isSubmit={modalReceiveLoading}
                sale={receiveModalData}
                onSubmit={onReceive}
                receiveBy={email}
            />
            <ModalMarkedConsignment
                isOpen={modalMarked}
                isConfirmation={isConfirmation}
                toggle={() => setModalMarked(!modalMarked)}
                invoiceNumber={consignment && consignment.consignment_number}
                marked={marked}
                markedAt={markedAt}
                markedBy={markedBy}
                closeMarkedAt={closeMarkedAt}
                markedNotes={markedNotes}
                notes={notes}
                unreadNoteCount={unreadNoteCount}
                hasNextPage={noteNextPage}
                loginId={id}
                onSubmit={onSubmitMarked}
                onLoadMoreNotes={onSubmitMarked}
            />
            <Row className="animated fadeIn">
                <Col xs={12}>
                    <Card>
                        <CardHeaderWithToolbars>
                            <Title>
                                <i className="fa fa-align-justify"></i>
                                <span>Outstanding Consignment Deliveries to Kick Avenue (Above 96 Hours)</span>
                            </Title>
                            <Toolbars
                                isOpen={filterOpenHours}
                                onToggleFilter={handleToggleFilterPanelHours}
                                collapsible
                                isCollapse={hoursOpen}
                                onToggleCollapse={handleToggleCollapseHours}
                                onRefreshList={handleRefreshHours}
                                onResetFilter={handleResetFilterHours}
                                total={dataHours?.total}
                            />
                        </CardHeaderWithToolbars>
                        <FilterContainer>
                            <Filters
                                onApplyFilter={handleApplyFilterHours}
                                onHandleSearch={handleSearchHours}
                                isReset={isResetFilterHours}
                                onResetFilterCallback={() => {
                                    setIsResetFilterHours(false);
                                    setFilterOpenHours(false);
                                }}
                                isOpen={filterOpenHours}
                                keyword={keywordHours}
                                seller={sellerHours}
                                shippingMethod={shippingMethodHours}
                                setKeyword={setKeywordHours}
                                setSeller={setSellerHours}
                                setShippingMethod={setShippingMethodHours}
                                perPage={perPageHours}
                                sortBy={sortByHours}
                                setPerPage={setPerPageHours}
                                setSortBy={setSortByHours}
                            />
                        </FilterContainer>
                        <CardBody>
                            <CardText>
                                Outstanding Consignment deliveries to Kick Avenue means we waiting for seller delivery his consignment. Please contact seller as soon as possible. Show button cancel after if reach time total of 96 hour since sales order is confirmed.
                            </CardText>
                            <Collapse isOpen={hoursOpen}>
                                <div className="table-responsive">
                                    <PaginationSummaries
                                        isOpenPrint={false}
                                        from={dataHours?.from ?? 0}
                                        to={dataHours?.to ?? 0}
                                        total={dataHours?.total ?? 0}
                                    />
                                    <Tbl>
                                        <TblHead>
                                            <RowHeader
                                                categoryName={getParamsHours.category}
                                                categoryValue={getParamsHours.category}
                                                onCategoryChanged={handleCategoryChangedHours}
                                                timer
                                            />
                                        </TblHead>
                                        <TblBody
                                            isLoad={loadingHours}
                                            hasRow={dataHours?.data?.length > 0}
                                            columnCount={6}
                                        >
                                            {renderHoursTableRow()}
                                        </TblBody>
                                    </Tbl>
                                </div>
                                <Paginations
                                    size="sm"
                                    lastPage={dataHours.last_page}
                                    perPage={dataHours.per_page}
                                    total={dataHours.total}
                                    maxPage={8}
                                    path={dataHours.path}
                                    currentPage={dataHours.current_page}
                                    prevPageUrl={dataHours.prev_page_url}
                                    nextPageUrl={dataHours.next_page_url}
                                    handleGotoCallback={handleGotoHours}
                                />
                            </Collapse>
                        </CardBody>
                    </Card>
                </Col>
                <Col xs={12}>
                    <Card>
                        <CardHeaderWithToolbars>
                            <Title>
                                <i className="fa fa-align-justify"></i>
                                <span>Outstanding Consignment All List</span>
                            </Title>
                            <Toolbars
                                isOpen={filterOpenAll}
                                onToggleFilter={handleToggleFilterPanelAll}
                                collapsible
                                isCollapse={allOpen}
                                onToggleCollapse={handleToggleCollapseAll}
                                onRefreshList={handleRefreshAll}
                                onResetFilter={handleResetFilterAll}
                                total={dataAll?.total}
                            />
                        </CardHeaderWithToolbars>
                        <FilterContainer>
                            <Filters
                                onApplyFilter={handleApplyFilterAll}
                                onHandleSearch={handleSearchAll}
                                isReset={isResetFilterAll}
                                onResetFilterCallback={() => {
                                    setIsResetFilterAll(false);
                                    setFilterOpenAll(false);
                                }}
                                isOpen={filterOpenAll}
                                keyword={keywordAll}
                                seller={sellerAll}
                                shippingMethod={shippingMethodAll}
                                setKeyword={setKeywordAll}
                                setSeller={setSellerAll}
                                setShippingMethod={setShippingMethodAll}
                                perPage={perPageAll}
                                sortBy={sortByAll}
                                setPerPage={setPerPageAll}
                                setSortBy={setSortByAll}
                            />
                        </FilterContainer>
                        <CardBody>
                            <CardText>
                                Outstanding Consignment means we we are waiting for seller to deliver to Kick Avenue.
                            </CardText>
                            <Collapse isOpen={allOpen}>
                                <div className="table-responsive">
                                    <PaginationSummaries
                                        isOpenPrint={false}
                                        from={dataAll?.from ?? 0}
                                        to={dataAll?.to ?? 0}
                                        total={dataAll?.total ?? 0}
                                    />
                                    <Tbl>
                                        <TblHead>
                                            <RowHeader
                                                categoryName={getParamsAll.category}
                                                categoryValue={getParamsAll.category}
                                                onCategoryChanged={handleCategoryChangedAll}
                                            />
                                        </TblHead>
                                        <TblBody
                                            isLoad={loadingAll}
                                            hasRow={dataAll?.data?.length > 0}
                                            columnCount={6}
                                        >
                                            {renderAllTableRow()}
                                        </TblBody>
                                    </Tbl>
                                </div>
                                <Paginations
                                    size="sm"
                                    lastPage={dataAll.last_page}
                                    perPage={dataAll.per_page}
                                    total={dataAll.total}
                                    maxPage={8}
                                    path={dataAll.path}
                                    currentPage={dataAll.current_page}
                                    prevPageUrl={dataAll.prev_page_url}
                                    nextPageUrl={dataAll.next_page_url}
                                    handleGotoCallback={handleGotoAll}
                                />
                            </Collapse>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    );
}

export default OutstandingConsignment;
