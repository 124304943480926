import React from 'react';
import { CardBody, Collapse } from 'reactstrap';
import propTypes from 'prop-types';

import SearchForm from './SearchForm';
import FilterForm from './FilterForm';
import SortForm from '../../../ProductVariants/Filters/SortForm';

const FilterPanel = props =>(
  <Collapse isOpen={props.isOpen}>
    <CardBody className="filter-container__card-body">
      <SearchForm
        keyword = {props.keyword}
        keywordFor={props.keyword_for}
        onInputChange = {props.onHandleChange}
        onSubmit = {props.onHandleSearch}
        isOpen={props.isSearchOptionOpen}
        onHandleSearchForClick={props.onHandleSearchForClick}
        onSearchOptionToggle={props.onSearchOptionToggle}
      />
      <FilterForm
        active = {props.active}
        editors_choice = {props.editors_choice}
        availables = {props.availables}
        onCheckedChange= {props.onHandleChange}
        onSubmit = {props.onHandleSearch}
      />
      <hr/>
      <SortForm
        sortOptions={props.sortOptions}
        per_page={props.per_page}
        sort_by={props.sort_by}
        onInputChange={props.onHandleChangeAndSubmit}
      />
    </CardBody>
  </Collapse>
)
export default FilterPanel;

FilterPanel.propTypes= {
  keyword: propTypes.string,
  per_page: propTypes.oneOfType([propTypes.number, propTypes.string]),
  sort_by: propTypes.string,
  isOpen: propTypes.bool.isRequired,
  isSearchOptionOpen: propTypes.bool.isRequired,
  onHandleChange: propTypes.func,
  onHandleChangeAndSubmit: propTypes.func,
  onSearchOptionToggle: propTypes.func
}

FilterPanel.defaultProps= {
  keyword: '',
  per_page: 15,
  sort_by: '',
  isSearchOptionOpen: false
}