import React from 'react';

const MenuPlaceholder = props =>(
  <li className="nav-item nav-placeholder">
    <div className="nav-placeholder__wrapper">
      <div className="nav-placeholder__icon nav-placeholder__animated"></div>
      <div className="nav-placeholder__label">
        <div className="nav-placeholder__label__top nav-placeholder__animated"></div> 
        <div className="nav-placeholder__label__bottom nav-placeholder__animated"></div>   
      </div>
    </div>
  </li>
)

export default MenuPlaceholder;