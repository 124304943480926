import api from "../../../../utils/Api";
import { AUTH_TOKEN_KEY, CONSIGNMENT_ROUTE_API } from "../../../../constants";
import { useState } from "react";

export function useFetchConsignmentNotes() {
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const fetchConsignmentNotes = async (id, payload) => {
        setLoading(true);

        return api({
            Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
        }).get(`${CONSIGNMENT_ROUTE_API}/${id}/notes`, payload)
            .then(res => {
                const notesData = {
                    ...res.data.data,
                    data: res.data.data.data.reverse(),
                }
                setLoading(false);
                setData(notesData);
                return notesData;
            })
            .catch(err => {
                setLoading(false);
                setError(err);
                throw err;
            })
    };

    return {
        data,
        loading,
        error,
        fetchConsignmentNotes,
    }
}
