import get from "lodash/get";

export function getBeErrorMessages(error) {
  const errors = get(error, "errors") || {};
  const errorMsg = [];
  for (const key in errors) {
    if (Object.hasOwnProperty.call(errors, key)) {
      const errorField = errors[key];
      if (Array.isArray(errorField)) {
        errorField.forEach((e) => errorMsg.push(e));
      }
    }
  }
  return errorMsg;
}
