import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Alert,
  Form,
  Label,
  Col,
  Row,
  Input,
  FormGroup,
  ButtonGroup,
  Card,
  CardBody,
  CardHeader,
} from 'reactstrap';
import 'react-select/dist/react-select.css';
import Loading from 'react-loading-animation';
import { textValue, hasError } from '../../utils/form';
import { ButtonLoading } from '../../components/Button';
import { saveRack, getRackById } from '../../actions/rackAction';
import { ValidationMessage } from '../../components/Form/ValidationMessage';

class RackForm extends Component{
  constructor(props){
    super(props);
    this.state={
      _edit:this.props.edit,
      _hasId: this.props.match.params.id?true:false,
      _form:{
        id: this.props.match.params.id||0,
        name:'',
      },
      _errors:[],
      _success:[],
      _formSubmit:false
    }
    this.onSubmit=this.onSubmit.bind(this);
    this.handleChange=this.handleChange.bind(this);
  }

  componentDidMount(){
    if(this.state._hasId){
      this.props.detail(this.state._form.id)
    }
  }


  componentDidUpdate(prevProps){
    const { details } = this.props.rack;
    const { data, user_sells_count} = details;
    if(prevProps.rack.details.isSubmit!==details.isSubmit&&!details.isSubmit){
      if(this.state._formSubmit){
        if(!details.error&&details.status_code==200){
          const { _success } = this.state;
          _success.push({key:'default',message:details.message});
          this.setState({_formSubmit:false, _errors:[], _success, _form:{...data, user_sells_count}});
          this.props.history.replace(`/racks/${data.id}`)
        }
        else{
          const { _errors } = this.state;
          _errors.push({key:'default', ...details.error});
          this.setState({_formSubmit:false, _errors, _success:[]});
        }
      } else {
        if(!details.error&&details.status_code==200){
          this.setState({_form:{...data}});
        }
      }
    }
  }

  handleChange(e){
    const newState={...this.state._form};
    newState[e.target.name]=e.target.value.toUpperCase();
    this.setState({_form:newState});
  }

  _validateForm(){
    const {name, max_capacity}= this.state._form;
    let errors={};
    if(!name)
      errors['name']=['The rack name field is required.'];
    else if(!max_capacity)
      errors['max_capacity']=['The max capacity field is required.'];
    if(Object.keys(errors).length){
      this.setState({
        _errors:[{key:'default',errors:{...errors}, status_code:422,message:'Please full fill form'}]
      })
    }
    return errors;
  }

  onSubmit(e){
    e.preventDefault();
    const valid = this._validateForm();
    if (Object.keys(valid).length===0) {
      this.setState({
        _formSubmit:true,
        _form:{
          ...this.state._form,
        },
        _errors:[],
        _success:[]
      },()=>{
        const {id, name, max_capacity}= this.state._form;
        this.props.save({id, name, max_capacity});
      });
    }
  }
  
  validateError(name){
    if(this.hasError(name))
    {
      let errorList=this.state._errors[0].errors;
      return <ValidationMessage message={errorList[name][0]}/>
    }
    return '';
  }

  hasError(name){
    const { _errors } = this.state;
    if(_errors.length)
    {
      if(_errors[0].errors)
      {
        let errorList=_errors[0].errors;
        let errList=Object.keys(errorList).filter(key=> key==name);
        if(errList.length)
          return true
      }
    }
    return false
  }

  renderInfo(type='default'){
    let infoMessage=[];
    const {_errors,_success}=this.state;
    if(type=='default'){
      if(this.state._formSubmit)
        infoMessage.push(
          <Alert color='info' key="info"><strong>Heads up!</strong> Submitting...</Alert>
        );
    }
    else{
      if(this.state._changeFormSubmit)
        infoMessage.push(
          <Alert color='info' key="info"><strong>Heads up!</strong> Submitting...</Alert>
        );
    }
    if(_success.filter(item=>item.key==type).length)
      infoMessage.push(
        <Alert color='success' key="success"isOpen={true}
          toggle={()=>{
            this.setState({_success: _success.filter(item=>item.key!=type)})
        }}><strong>Well done!</strong> { _success.filter(item=>item.key==type)[0].message }</Alert>
      );
    if(_errors.filter(item=>item.key==type).length)
    {
      const {status_code,message} = _errors.filter(item=>item.key==type)[0];
      switch(status_code)
      {
        case 422:
          infoMessage.push(
            <Alert color='danger' 
            key="error422"   
            isOpen={true} 
            toggle={()=>{
              this.setState({_errors: _errors.filter(item=>item.key!=type)})
            }}>
              <strong>Oh Snap!</strong> Please fullfill your form. 
            </Alert>
          );
          break;
        case 404:
          infoMessage.push(
            <Alert color='danger' key="error404"  isOpen={true}
            toggle={()=>{
              this.setState({_errors: _errors.filter(item=>item.key!=type)})
            }}><strong>Oh Snap!</strong> Resource not found. </Alert>
          );
          break;
        default:
          infoMessage.push(
            <Alert color='danger' key={`error${status_code}`} isOpen={true}
            toggle={()=>{
              this.setState({_errors: _errors.filter(item=>item.key!=type)})
            }}><strong>Oh Snap!</strong> We've got something errors </Alert>
          );
          break;
      }
    }
    return(
      <div className="row">
        <div className="col-sm-12">
          {infoMessage}   
        </div>
      </div>
    )
  }

  
  renderForm(){
    if(this.props.rack.list.isFetch)
      return <Loading/>
    return(
      <Form onSubmit={this.onSubmit}>
        <Row>
          <Col xs={12} md={this.state._edit?12:6}>
            <FormGroup className={hasError('name')?' has-danger has-feedback':''}>
              <Label for="formName">Rack Name</Label>
              <Input
                  type="text"
                  id="formName"
                  name="name"
                  value={textValue('name',this.state._form)}
                  onChange={this.handleChange}
              />
              {this.validateError('name')}
            </FormGroup>
          </Col>
          <Col xs={6} md={6} className={!this.state._edit ? "d-none" : ""}>
            <FormGroup className={hasError('user_sells_count')?' has-danger has-feedback':''}>
              <Label for="formCurrentCapacity">Current Capacity</Label>
              <Input
                  type="number"
                  id="formCurrentCapacity"
                  disabled={true}
                  name="user_sells_count"
                  value={textValue('user_sells_count',this.state._form)}
                  onChange={this.handleChange}
              />
              {this.validateError('user_sells_count')}
            </FormGroup>
          </Col>
          <Col xs={6} md={6}>
            <FormGroup className={hasError('max_capacity')?' has-danger has-feedback':''}>
              <Label for="formMaxCapacity">Maximum Capacity</Label>
              <Input
                  type="number"
                  id="formMaxCapacity"
                  name="max_capacity"
                  placeholder="Enter a max capacity"
                  value={textValue('max_capacity',this.state._form)}
                  onChange={this.handleChange}
              />
              {this.validateError('max_capacity')}
            </FormGroup>
          </Col>
          <Col xs={12} md={6} className={!this.state._edit ? "d-none" : ""}>
            <FormGroup>
              <Label>Created</Label>
              <Input
                readOnly
                name="created_at"
                type="text"
                value={textValue('created_at', this.state._form)}
              />
            </FormGroup>
          </Col>
          <Col xs={12} md={6} className={!this.state._edit ? "d-none" : ""}>
            <FormGroup>
              <Label>Updated</Label>
              <Input
                readOnly
                name="updated_at"
                type="text"
                value={textValue('updated_at', this.state._form)}
              />
            </FormGroup>
          </Col>
        </Row>
        <FormGroup>
          <ButtonGroup>
              <ButtonLoading isLoading={this.state._formSubmit}>Submit</ButtonLoading>
          </ButtonGroup>
        </FormGroup>
      </Form>
    )
  }

  render(){
    return(
      <Row>
        <Col xs={6} md={{size:6, offset:3}}>
          <Card>
            <CardHeader>
                <i className="fa fa-align-justify"></i> {this.props.title}
            </CardHeader>
            <CardBody>
                { this.renderInfo() }
                { this.renderForm() }
            </CardBody>
          </Card>
        </Col>
      </Row>
    )
  }
}

const mapStateToProps= ({rack}) => {
	return {
		rack,
	}
};
const mapDispatchToProps = (dispatch) => {
	return{
    save:(payload) => dispatch(saveRack(payload)),
    detail:(id) => dispatch(getRackById(id)),
	}

};
export default connect(mapStateToProps,mapDispatchToProps)(RackForm);