import React, { Component } from "react";
import { connect } from "react-redux";
import { Container } from "reactstrap";
import PushNotificationForm from "./PushNotificationForm";
import privateView from "../../components/hocs/privateView";

class PushNotificationDetail extends Component {
  render() {
    return (
      <Container fluid>
        <PushNotificationForm edit title="Push Notification Detail" {...this.props} />
      </Container>
    );
  }
}

const mapStateToProps = ({
  auth: { isFetch, roles, isLogged, id, email },
}) => ({
  guardData: { isFetch, roles, isLogged, id, email },
});

const enhance = connect(mapStateToProps);

export default enhance(privateView(PushNotificationDetail));
