import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { 
    Col, 
    Row,
    CardText,
    Card,
    CardBody
} from 'reactstrap';

import DateToHuman from '../../components/DateToHuman';
import Paginations from '../../components/Paginations';
import PaginationSummaries from '../../components/Paginations/PaginationSummaries';
import { Tbl, TblHead, TblBody, Th } from '../../components/Tbl';
import FilterPanel from './Filters';
import FilterContainer from '../../components/Container/FilterContainer/FilterContainer';
import { CardHeaderWithToolbars,Title } from '../../components/Card/CardHeaderWithToolbars';
import Toolbars from './Filters/Toolbars';
import CheckReadOnly from '../../components/CheckReadOnly';
import privateView from '../../components/hocs/privateView';

import { generateNumber } from '../../utils';
import { DEFAULT_PER_PAGE } from '../../constants/settings';
import { getPointShopList } from '../../actions/pointShopAction';
import PointShopTypeButton from './Filters/PointShopTypeButton';
import { TitleColumn } from './pointShopIndex/Columns';
import Analytics from "../../services/Analytics";

const defaultParams = {
  sort_by : 'createdAt_desc',
  per_page : DEFAULT_PER_PAGE
}

class PointShopIndex extends Component{
  constructor(){
    super();
    this.state={
      _params:{
        ...defaultParams
      },
      _filterOpen: false
    }
    this._handleToggleFilterPanel = this._handleToggleFilterPanel.bind(this);
    this._handleParamsChange = this._handleParamsChange.bind(this);
    this._handleParamsChangeAndSubmit = this._handleParamsChangeAndSubmit.bind(this);
    this._handleGoto = this._handleGoto.bind(this);
    this._handleRefresh = this._handleRefresh.bind(this);
    this._handleResetFilter = this._handleResetFilter.bind(this);
  }

  componentDidMount(){
    const { email } = this.props.injectedProps;
    this._getList(this.state._params);
    Analytics.recordPageView('point_shop_list', email, {
      url: window.location.href,
      location: this.props.location,
      match: this.props.match
    });
  }

  _toggleFilterPanel(){
    this.setState({_filterOpen:!this.state._filterOpen})
  }

  _handleToggleFilterPanel(e){
    e.preventDefault();
    this._toggleFilterPanel();
  }

    /**
 * Function to handle rendering table's items or rows
 * @author sopyan
 */
  _onRenderRowItem(){
    if(this.props.pointShop.list.status_code===200 && this.props.pointShop.list.data.length>0)
		{
			const { current_page,per_page } =  this.props.pointShop.list;
			return this.props.pointShop.list.data.map((item,idx)=>
				(
					<tr key={parseInt((per_page*(current_page-1))+idx+1)}>
						<th className="v-center" scope="row">{ parseInt((per_page*(current_page-1))+idx+1) }</th>
            <td className="v-center">
              <TitleColumn
                id={item.id}
                slug={item.slug}
                name={item.name}
                createdAt={item.created_at}
                pointType={item.point_type}
                itemCount={item.point_type==='product_variants'? item.point_variants_count:(item.point_type==='vouchers'? item.point_vouchers_count:0)}
              />
            </td>
            <td className="v-center">
              <CheckReadOnly check={item.is_active}/>
            </td>
            <td className="v-center">
              <DateToHuman  value={item.updated_at} id={`tooltip_updated_at_${generateNumber(per_page,current_page,idx)}`}/>
            </td>
            <td className="v-center">
							<Link to={`/point_shop/${item.id}`} className="btn btn-link">Details</Link>
						</td>
					</tr>
				)
			)	
		}
		return null;
  }

  /**
  * Function to handle rendering paginations
  * @author sopyan
  */
	_onRenderPagination(){
    const {status_code, data} = this.props.pointShop.list;
    const hasData = (status_code===200 && data.length)||null;
    return hasData && (
      <Paginations 
        size="sm" 
        lastPage={this.props.pointShop.list.last_page} 
        perPage={this.props.pointShop.list.per_page}
        total={this.props.pointShop.list.total}
        maxPage={8}
        path={this.props.pointShop.list.path}
        currentPage={this.props.pointShop.list.current_page} 
        prevPageUrl={this.props.pointShop.list.prev_page_url} 
        nextPageUrl={this.props.pointShop.list.next_page_url} 
        handleGotoCallback={this._handleGoto}
      />
    )
  }

	/**
	 * Handle callback when user click pagination items
	 * @author sopyan
	 * @param {integer} page 
	 */
	_handleGoto(page){
    const _params=this.state._params;
    _params.page=page;
    this.setState({_params},()=>this._getList(this.state._params));
  }

  _getList(rawParams = {}){
    let params = {};
    Object.keys(rawParams)
    .filter(key => rawParams[key]!='')
    .forEach(key=> params[key] = rawParams[key]);
    this.props.getList({params});
  }

  _handleParamsChange(e){
    const { _params } = this.state;
    _params[e.target.name] = e.target.value;
    this.setState({_params});
  }

  _handleParamsChangeAndSubmit(e){
    // Exclude page...
    const { page , ..._params } = this.state._params;
    _params[e.target.name] = e.target.value;
    this.setState({_params},()=>{
      this._getList(this.state._params);
    });    
  }

  _handleRefresh(e){
    e.preventDefault();
    this._refreshList();
  }

  _refreshList(){
    const {page, ..._params} = this.state._params;
    this.setState({
      _params
    },()=>this._getList(this.state._params));
  }
  
  _handleResetFilter(e){
    e.preventDefault();
    const newState = this.state._filterOpen?{_filterOpen:!this.state._filterOpen}:{};
    this.setState({
      ...newState,
      _params:{
        ...defaultParams
      }
    },()=>this._getList(this.state._params));
  }
    
  render(){
    return(
      <div className="animated fadeIn">
        <Row>
          <Col xs={12}>
            <Card>
              <CardHeaderWithToolbars>
                <Title>
                  <i className="fa fa-align-justify"></i>
                  <span>Point Shop List</span>
                </Title>
                <Toolbars
                  isOpen={this.state._filterOpen}
                  onToggleFilter={this._handleToggleFilterPanel}
                  onRefreshList={this._handleRefresh}
                  onResetFilter={this._handleResetFilter}
                />
              </CardHeaderWithToolbars>
              <FilterContainer>
                <FilterPanel 
                  {...this.state._params} 
                  onHandleChange={this._handleParamsChange}
                  onHandleChangeAndSubmit={this._handleParamsChangeAndSubmit}
                  onHandleSearch={this._handleRefresh}
                  isOpen={this.state._filterOpen}
                />
              </FilterContainer>
              <CardBody>
                <CardText>
                  In this section will display our point shop.
                </CardText>
                <div className="table-responsive">
                  <PaginationSummaries
                    from={this.props.pointShop.list.from}
                    to={this.props.pointShop.list.to}
                    total={this.props.pointShop.list.total}
                  />
                  <Tbl>
                    <TblHead
                      renderChildren={()=>(
                        <tr>
                          <Th>No.</Th>
                          <Th>
                            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'flex-end'}}>
                              <p style={{marginRight: 10, marginBottom: 0}}>
                                Name
                                {
                                  this.state._params.point_type !== null?
                                  <span style={{display: 'block', fontSize: '.5rem'}}>
                                    {this.state._params.point_type === 'variants'? 'variants': this.state._params.point_type}
                                  </span>
                                  : null
                                }
                              </p>
                              <PointShopTypeButton
                                value={this.state._params.point_type}
                                onClick={async value => {
                                  await this.setState({
                                    _params: {
                                      ...this.state._params,
                                      point_type: value
                                    }
                                  })
                                  this._getList(this.state._params)
                                }}
                              />
                            </div>
                          </Th>
                          <Th>Active</Th>
                          <Th>Updated</Th>
                          <Th>Action</Th>
                        </tr>
                      )}
                    />
                    <TblBody
                      isLoad={this.props.pointShop.isFetch}
                      hasRow={this.props.pointShop.list.status_code===200&&this.props.pointShop.list.data.length>0}
                      columnCount={6}
                    >
                    { this._onRenderRowItem() }
                    </TblBody>
                  </Tbl>
                </div>
                { this._onRenderPagination() }
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    )
  }
}

const mapStateToProps= ({ pointShop, auth: { isFetch, roles, isLogged, id, email } }) => {
	return {
    guardData: { isFetch, roles, isLogged, id, email },
		pointShop
	}
};

const mapDispatchToProps = (dispatch) => {
	return{
		getList:(query) => dispatch(getPointShopList(query))
	}

};

const enhance = connect(mapStateToProps, mapDispatchToProps);

export default enhance(privateView(PointShopIndex));