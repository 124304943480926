import React, { Component } from 'react';
import {
  Form,
  Label,
  Col,
  Row,
  Input,
  FormGroup,
  Card,
  CardBody,
  CardHeader,
  ButtonGroup,
  Alert
} from 'reactstrap';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { Radio } from '../../../components/Form/Radios';
import { errorMessage, hasError, textChange, textValue, dateChange, checkChange } from '../../../utils/form';
import { ButtonLoading } from '../../../components/Button';
import AddonList from './AddonList';
import CollectionList from './CollectionList';
import ProductVariantList from './ProductVariantList';
import { getSettingList, updateSetting } from "../../../actions/settingAction";
import { DEFAULT_SORT_BY, DEFAULT_PER_PAGE } from "../../../constants/settings";

const defaultParams = {
  name : 'ka_fair_tickets',
  sort_by : DEFAULT_SORT_BY,
  per_page : DEFAULT_PER_PAGE
};

class FormCard extends Component{
  constructor(props){
    super(props);
    this.state={
      _params: {
        ...defaultParams,
      },
      _form: {
        id: null,
        name: '',
        value_type:'',
        value: '',
        active: false,
        created_at:'',
        updated_at:'',
      },
      submitToApi:false,
      _successMessage: null,
      _errorMessage: null,
      _alertSuccess: false,
      _alertError: false,
      _formSubmit: false,
      _error: null,
    }
    this.getList=this.getList.bind(this);
    this.onSubmit=this.onSubmit.bind(this);
    this.onDelete=this.onDelete.bind(this);
    this.handleChange=this.handleChange.bind(this);
    this.handleCheckedChange=this.handleCheckedChange.bind(this);
  }

  componentDidMount(){
    this.getList(this.state._params);
  }

  getList(rawParams = {}) {
    const params = Object.keys(rawParams)
      .filter((key) => rawParams[key] != "")
      .reduce(
        (obj, current) => ({ ...obj, [current]: rawParams[current] }),
        []
      );
    this.props.getList({ params });
  }

  componentDidUpdate(prevProps){
    const { setting } = this.props;
    if(prevProps.setting.isFetch !== setting.isFetch && !setting.isFetch) {
      if(setting.list.data.length) {
        const detail = setting.list.data[0];
        const _form = { ...this.state._form }
        this.setState({ _form }, () => {
          const _newForm = { ...this.state._form, ...detail }
          this.setState({ _form: _newForm })
        })
      }
    }
    if(prevProps.setting.isSubmit
      && prevProps.setting.isSubmit !== this.props.setting.isSubmit
      && this.state._formSubmit === true
    ){
      if(!this.props.setting.error){
        const { detail, success } = this.props.setting;
        this.setState({
          ...this.state,
          _alertSuccess:true,
          _successMessage: success,
          _formSubmit:false,
          _form : {
            ...detail
          }
        });
      }
      else {
        if(this.props.setting.error&&this.props.setting.error!==null){
          const { error } = this.props.setting;
          this.setState({
            _error:{...error},
            _formSubmit: false,
            _errorMessage : error.status_code===422?'Please fulfill your form.' : error.message,
            _alertError : true
          })
        }
      }
    }
  }

  handleChange(e){
    const _form=textChange(e,this.state);
    this.setState({..._form});
  }

  onDelete(item){
    this.removeAlerts();
    const {
      _formSubmit,
      _error,
      ...rest } = this.state;
      this.setState({ _formSubmit:true, _error:null },()=>{
        const {
          id,
          name,
          value_type,
          active,
        } = rest._form;
        this.props.update(id, {
          id,
          name,
          value:JSON.stringify(item),
          value_type,
          active
        });
      })
  }

  removeAlerts(){
    this.setState({
      _alertError:false,
      _alertSuccess:false
    });
  }
  onSubmit(e){
    e.preventDefault();
    this.removeAlerts();
    const {
      _formSubmit,
      _error,
      ...rest } = this.state;
      this.setState({ _formSubmit:true, _error:null },()=>{
        const {
          id,
          name,
          value,
          value_type,
          active,
        } = rest._form;
        this.props.update(id, {
          id,
          name,
          value,
          value_type,
          active
        });
      })
  }

  handleCheckedChange(e){
    this.setState({ ...checkChange(e, this.state._form) });
  }

  handleDatePickerChange(value, name){
    const _form=dateChange(value,name,this.state);
    this.setState({..._form});
  }

  toggleAlert(key){
    const lastState = {...this.state};
    lastState[key] = !lastState[key];
    this.setState({...lastState});
  }

  _renderAlerts(){
    return(
      <div>
        <Alert color="success" isOpen={this.state._alertSuccess} toggle={()=>this.toggleAlert('_alertSuccess')}>
          <strong>Well done!</strong> { this.state._successMessage }
        </Alert>
        <Alert color="danger" isOpen={this.state._alertError} toggle={()=>this.toggleAlert('_alertError')}>
          <strong>Oh Snap!</strong> { this.state._errorMessage }
        </Alert>
      </div>
    )
  }

  render(){
    return(
      <Row>
        <Col xs={12} md={6} sm={6}>
          <Card>
            <CardHeader>
              <i className="fa fa-align-justify"></i> Raffle Tickets
            </CardHeader>
            <CardBody>
              {this._renderAlerts()}
              <Form onSubmit={this.onSubmit}>
                <Row>
                  <Col xs={12} md={6}>
                    <FormGroup className={classNames({'has-danger has-feedback':hasError('name',this.state._error)})}>
                      <Label for="formName">Name</Label>
                      <Input
                        type="text"
                        readOnly
                        value={this.state._form.name}
                      />   
                    </FormGroup>
                  </Col>
                  <Col xs={12} md={6}>
                    <FormGroup className={classNames({'has-danger has-feedback':hasError('value_type',this.state._error)})}>
                      <Label for="formValueType">Value Type</Label>
                      <Input
                        type="text"
                        readOnly
                        value={this.state._form.value_type}
                      />   
                    </FormGroup>
                  </Col>
                  <Col xs={12}>
                    <FormGroup className={classNames({'has-danger has-feedback':hasError('value',this.state._error)})}>
                      <Label for="formValue">Value</Label>  
                      <Input
                        type="textarea"
                        id="value"
                        name="value"
                        rows="10"
                        autoComplete="off"
                        readOnly
                        value={this.state._form.value}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={6}>
                    <FormGroup className={classNames({'has-danger has-feedback':hasError('active',this.state._error)})}>
                      <Label for="formActive">Set to Active &nbsp;</Label>
                      <Radio
                        name="active"
                        id="formActive"
                        value="true" 
                        checked={textValue('active', this.state._form)}
                        onChange={this.handleCheckedChange}
                      />
                      {errorMessage('active',this.state._error)}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={6}>
                    <FormGroup className={classNames({'has-danger has-feedback':hasError('started_at',this.state._error)})}>
                      <Label for="formStartedAt">Created At</Label>
                      <Input
                        type="text"
                        readOnly
                        value={this.state._form.created_at}
                      />    
                    </FormGroup>
                  </Col>
                  <Col xs={12} md={6}>
                    <FormGroup className={classNames({'has-danger has-feedback':hasError('ended_at', this.state._error)})}>
                      <Label for="formEndedAt">Updated At</Label>
                      <Input
                        type="text"
                        readOnly
                        value={this.state._form.updated_at}
                      />             
                    </FormGroup>
                  </Col>
                </Row>
                <FormGroup>
                  <ButtonGroup>
                    <ButtonLoading isLoading={this.state._formSubmit}>Submit</ButtonLoading>                    
                  </ButtonGroup>
                </FormGroup>
              </Form>
            </CardBody>
          </Card>
        </Col>
        <Col xs={12} sm={6} md={6}>
          <ProductVariantList
            id={this.state._form.id}
            onGetList={this.props.getList}
            onDelete={(item) => this.onDelete(item)}
            dataRaffleTickets={this.props.setting}
            dataAuth={this.props.auth}
            saveSku={this.props.update}
          />
          <CollectionList
            id={this.state._form.id}
            onGetList={this.props.getList}
            onDelete={(item) => this.onDelete(item)}
            dataRaffleTickets={this.props.setting}
            dataAuth={this.props.auth}
            saveCollection={this.props.update}
          />
          <AddonList
            id={this.state._form.id}
            onGetList={this.props.getList}
            onDelete={(item) => this.onDelete(item)}
            dataRaffleTickets={this.props.setting}
            dataAuth={this.props.auth}
            saveAddon={this.props.update}
          />
        </Col>
      </Row>
    )
  }
}

const mapStateToProps= ({auth, voucher: {...voucher}, user, setting}) => {
	return {
    voucher,
    user,
    auth,
    setting,
	}
};
const mapDispatchToProps = (dispatch) => {
	return{
		update:(id, payload) => dispatch(updateSetting(id, payload)),
    getList: (filter) => dispatch(getSettingList(filter)),
	}
};

export default connect(mapStateToProps,mapDispatchToProps)(FormCard);

