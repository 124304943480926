import React from 'react';
import { connect } from 'react-redux';
import {
  Row,
  Col,
  Card,
  CardBody,
  CardText
} from 'reactstrap';
import { CardHeaderWithToolbars, Title } from '../../components/Card/CardHeaderWithToolbars';
import FilterContainer from '../../components/Container/FilterContainer/FilterContainer';
import FilterPanel from './midtransIndex/FilterPanel';
import { DEFAULT_SORT_BY, DEFAULT_PER_PAGE } from '../../constants/settings';
import Toolbars from './midtransIndex/Toolbars';
import List from './midtransIndex/List';
import privateView from '../../components/hocs/privateView';
import { getMidtransList } from '../../actions/midtransAction';
import Analytics from "../../services/Analytics";

const defaultParams = {
  page: 1,
  sort_by : DEFAULT_SORT_BY,
  per_page : DEFAULT_PER_PAGE
}

const DEFAULT_ROUTE = '/midtrans';

class MidtransIndex extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      _params:{
        ...defaultParams
      },
      _filterOpen: false,
      _isResetFilter: false
    }
    this._handleToggleFilterPanel = this._handleToggleFilterPanel.bind(this);
    this._handleRefresh = this._handleRefresh.bind(this);
    this._handleResetFilter = this._handleResetFilter.bind(this);
    this._handleGoto = this._handleGoto.bind(this);
    this._handleApplyFilter = this._handleApplyFilter.bind(this);
  }

  componentDidMount(){
    const { email } = this.props.injectedProps;
    this._getList(this.state._params);
    Analytics.recordPageView('midtrans_list', email, {
      url: window.location.href,
      location: this.props.location,
      match: this.props.match
    });
  }

  _getList(rawParams = {}){
    let params = {};
    Object.keys(rawParams)
    .filter(key => rawParams[key]!='')
    .forEach(key=> params[key] = rawParams[key]);
    this.props.getList({params});
  }

  _handleToggleFilterPanel(){
    this.setState({ _filterOpen: !this.state._filterOpen })
  }

  _handleRefresh(e){
    e.preventDefault();
    this._refreshList();
  }

  _refreshList(){
    const { _params } = this.state;
    this.setState({
      _params:{ ..._params, page: 1 },
    },()=>this._getList(this.state._params));
  }

  _handleResetFilter(e){
    e.preventDefault();
    this.setState({
      _params:{
        ...defaultParams
      },
      _isResetFilter: true,
    },()=>this._getList(this.state._params));
  }

  _handleGoto(page){
    const _params = this.state._params;
    _params.page = page;
    this.setState({ _params },()=>this._getList(this.state._params));
  }

  _handleApplyFilter(payloadFilter){
    this.setState({
      _params: {...payloadFilter},
    },()=>this._getList(this.state._params));
  }

  render(){
    return(
      <div className="animated fadeIn">
        <Row>
          <Col xs={12}>
            <Card>
              <CardHeaderWithToolbars>
                <Title>
                  <i className="fa fa-align-justify"></i>
                  <span>Midtrans List</span>
                </Title>
                <Toolbars
                  isOpen={this.state._filterOpen}
                  onToggleFilter={this._handleToggleFilterPanel}
                  onRefreshList={this._handleRefresh}
                  onResetFilter={this._handleResetFilter}
                />
              </CardHeaderWithToolbars>
              <FilterContainer>
                <FilterPanel
                  {...this.state._params}
                  isOpen={this.state._filterOpen}
                  onApplyFilter={this._handleApplyFilter}
                  isReset={this.state._isResetFilter}
                  onResetFilterCallback={()=>this.setState({_isResetFilter: false, _filterOpen: false})}
                />
              </FilterContainer>
              <CardBody>
                <CardText>
                  In this section will display our midtrans data.
                </CardText>
                <List
                  defaultRoute={DEFAULT_ROUTE}
                  currentPage={this.state._params.page}
                  isLoading={this.props.dataMidtrans.isFetch}
                  data={this.props.dataMidtrans}
                  onGotoPageList={this._handleGoto}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    )
  }
}
const mapStateToProps= ({midtrans: { list }, auth: { isFetch, roles, isLogged, id, email }}) => {
	return {
    dataMidtrans: list,
    guardData: { isFetch, roles, isLogged, id, email }
	}
};
const mapDispatchToProps = (dispatch) => {
	return{
		getList:(query) => dispatch(getMidtransList(query)),
	}
};
const enhance = connect(mapStateToProps,mapDispatchToProps);
export default enhance(privateView(MidtransIndex));