import AWS from 'aws-sdk';
import mime from 'mime-types';
import uuidv4  from 'uuid/v4';
import{
  AWS_BUCKET,
  AWS_BUCKET_URL
} from '../config/storageBucket';
import {
  AUTH_TOKEN_KEY,
  BULK_STOCK_ROUTE_API,
  BULK_STOCK_SUBMIT,
  BULK_STOCK_ERROR,
  BULK_STOCK,
  BULK_STOCK_UPLOAD_SUBMIT,
	BULK_STOCK_UPLOAD,
} from '../constants';
import { s3 } from '../utils/AWS';
import api from '../utils/Api';
import slugify from '../utils/slugify';
import { manipulateErrors, checkAuthorized } from '../utils';
import { logout } from './authAction';
const onSuccess=(res,type)=>(
	{
		type:type,
		payload:res		
	}
)
const onError=(err)=>{    
	if(!checkAuthorized(manipulateErrors(err)))
      return logout();
    return {
      type:BULK_STOCK_ERROR,
      payload:manipulateErrors(err)
    }
}

export const doUploadBulkStock=(file,folder='')=>{
	const uuid=uuidv4();
	const filename=`${slugify((file.name.substr(0, file.name.lastIndexOf('.'))||file.name))}-${uuid.substr(0,uuid.indexOf('-'))}`;

	const key=`${folder}/${filename}.${mime.extension(file.type)}`;
  return dispatch => (
		dispatch({type:BULK_STOCK_UPLOAD_SUBMIT,payload:true}),
    s3().putObject({
      Bucket: AWS_BUCKET,
      Key: key, 
      Body: file,
      ContentType:mime.lookup(file.name),
      ACL: 'public-read', // your permisions
    },(err)=>{
      if(err===null)
      {
        const result={
          identifier: uuid,
          prefix: AWS_BUCKET_URL,
          folder: folder,
          url: `${AWS_BUCKET_URL}${key}`
        }
        dispatch({type:BULK_STOCK_UPLOAD,payload:result});
      }
      else{
        dispatch(onError(err));
      }
    dispatch({type:BULK_STOCK_UPLOAD_SUBMIT,payload:false});
  })
  )
}

export const doBulkStock=(payload)=>{
  return dispatch => (
    dispatch({type:BULK_STOCK_SUBMIT,payload:true}),
    api({
      Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
    }).post(`${BULK_STOCK_ROUTE_API}`,payload)
    .then(res=>{
      dispatch(onSuccess(res.data,BULK_STOCK))
      dispatch({type:BULK_STOCK_SUBMIT,payload:false})
    })
    .catch(err => {
      dispatch(onError(err));
      dispatch({type:BULK_STOCK_SUBMIT,payload:false});
    })
  )
}