import {
    getCategoryName,
    truncateWithEllipsis,
} from "../../../../../helpers/formatter";
import { getAskingPriceWithProcessingFee } from "../../../../../helpers/misc";
import { PRINT_TYPE_OPTIONS } from "../PrintTypeOptions.constants";
import { imageToZ64 } from "./toZ64";
import QRCode from "qrcode";

const generateQrCode = async (url) => {
    // const targetSize = url.length > 100 ? 194 : 200; // Use this if content length is more than 100
    const targetSize = 220;

    try {
        const tempImage = document.createElement("img");
        await QRCode.toDataURL(
            url,
            {
                type: "image/png",
            },
            (err, url) => {
                if (err) {
                    console.log(err);
                    return;
                }
                tempImage.src = url;
            }
        );

        const res = imageToZ64(tempImage, {
            targetSize,
            notrim: false,
            // notrim: true, // Use this if content length is more than 100
        });

        // const position = "0,0"; // Use this if content length is more than 100
        const position = "21,26";
        const qrZpl = `^GFA,${res.length},${res.length},${res.rowlen},${res.z64}`;

        const zpl = `
^FO${position}
${qrZpl}
^FS
`;
        return zpl;
    } catch (err) {
        console.error(err);
    }
};

const getTagContent = (data) => {
    const { 
        printType = '', 
        price = '', 
        currency = '',
        productNameLine1 = '',
        productNameLine2 = '',
        sku = '',
        rackLine = '',
        size = '',
        consignmentId = '',
    } = data;

    if (printType === PRINT_TYPE_OPTIONS.NO_PRICE) {
        return {
            priceTag:            '',
            currencyTag:         '',
            feeTag:              '',
            downloadAndScanTag:  '^FT204,46^A0N,27,28^FH^CI28^FDDownload & Scan^FS^CI27',
            usingOurAppTag:      '^FT204,80^A0N,27,28^FH^CI28^FDUsing Our App^FS^CI27',
            productNameLine1Tag: `^FT204,112^A0N,17,18^FH^CI28^FD${productNameLine1}^FS^CI27`,
            productNameLine2Tag: `^FT204,${productNameLine1 ? 135 : 125}^A0N,17,18^FH^CI28^FD${productNameLine2}^FS^CI27`,
            skuTag:              `^FT204,${productNameLine1 ? 163 : 148}^A0N,17,18^FH^CI28^FD${sku}^FS^CI27`,
            rackLineTag:         `^FT204,184^A0N,17,18^FH^CI28^FD${rackLine}^FS^CI27`,
            sizeTag:             `^FT204,220^A0N,27,28^FH^CI28^FD${size}^FS^CI27`,
            consignmentIdTag:    `^FT22,220^A0N,17,18^FH\^CI28^FD${consignmentId}^FS^CI27`,
        };
    }

    return {
        downloadAndScanTag:  '',
        usingOurAppTag:      '',
        productNameLine1Tag: `^FT204,39^A0N,17,18^FH\^CI28^FD${productNameLine1}^FS^CI27`,
        productNameLine2Tag: `^FT204,${productNameLine1 ? 61 : 42}^A0N,17,18^FH\^CI28^FD${productNameLine2}^FS^CI27`,
        skuTag:              `^FT204,${productNameLine1 ? 83 : 65}^A0N,17,18^FH\^CI28^FD${sku}^FS^CI27`,
        rackLineTag:         `^FT204,${productNameLine1 ? 105 : 95}^A0N,17,18^FH^CI28^FD${rackLine}^FS^CI27`,
        sizeTag:             `^FT204,${productNameLine1 ? 151 : 141}^A0N,37,38^FH\^CI28^FD${size}^FS^CI27`,
        currencyTag:         `^FT205,${productNameLine1 ? 177 : 167}^A0N,17,18^FH\^CI28^FD${currency}^FS^CI27`,
        priceTag:            `^FT241,${productNameLine1 ? 197 : 187}^A0N,37,38^FH\^CI28^FD${price}^FS^CI27`,
        feeTag:              `^FT204,232^A0N,17,18^FH^CI28^FD(ALL FEES INCLUDED)^FS^CI27`,
        consignmentIdTag:    `^FT22,232^A0N,17,18^FH\^CI28^FD${consignmentId}^FS^CI27`,
    };
}

const getZplScript = async (obj) => {
    const {
      consignmentId,
      rack,
    } = obj;

    const qr = await generateQrCode(consignmentId);
    const productNameLine1 = !obj.productNameLine2 ? "" : obj.productNameLine1;
    const productNameLine2 = obj.productNameLine2 || obj.productNameLine1;
    const maxRackLineLength = 32; // hardcode based on text start position and font size
    const rackLine = rack
        ? truncateWithEllipsis(`RACK : ${rack.trim()}`, maxRackLineLength)
        : "";
    const { 
        priceTag, 
        currencyTag,
        feeTag,
        downloadAndScanTag,
        usingOurAppTag,
        productNameLine1Tag,
        productNameLine2Tag,
        skuTag,
        rackLineTag,
        sizeTag,
        consignmentIdTag
    } = getTagContent({
        ...obj,
        productNameLine1,
        productNameLine2,
        rackLine,
    });

    return `^XA
          ~TA000
          ~JSN
          ^LT0
          ^MNW
          ^MTT
          ^PON
          ^PMN
          ^LH0,0
          ^JMA
          ^PR4,4
          ~SD25
          ^JUS
          ^LRN
          ^CI27
          ^PA0,1,1,0
          ^XZ
          ^XA
          ^MMT
          ^PW480
          ^LL240
          ^LS0
          ${qr}
          ${consignmentIdTag}
          ${downloadAndScanTag}
          ${usingOurAppTag}
          ${productNameLine1Tag}
          ${productNameLine2Tag}
          ${skuTag}
          ${rackLineTag}
          ${sizeTag}
          ${priceTag}
          ${currencyTag}
          ${feeTag}
          ^PQ1,0,1,Y
          ^XZ`;
};

const getProductName = (displayName) => {
    displayName = displayName.toUpperCase();
    const result = {
        productNameLine1: "",
        productNameLine2: "",
    };
    const length = displayName.length;

    if (length < 25) {
        result.productNameLine1 = displayName;
        return result;
    }

    const splitted = displayName.split(" ");
    const totalIndex = splitted.length - 1;
    let tempLength = 0;
    let index = 0;

    while (index <= totalIndex && tempLength <= 25) {
        const str = splitted[index];
        tempLength += str.length;
        if (tempLength <= 25) {
            result.productNameLine1 += str + " ";
            index += 1;
        }
    }

    result.productNameLine1 = result.productNameLine1.trim();
    const isMore = splitted.length > index;

    if (isMore) {
        tempLength = 0;

        while (index <= totalIndex && tempLength <= 25) {
            const str = splitted[index];
            tempLength += str.length;
            if (tempLength <= 25) {
                result.productNameLine2 += str + " ";
                index += 1;
            }
        }
        result.productNameLine2 = result.productNameLine2.trim();

        const isMore2 = splitted.length > index;
        if (isMore2) {
            result.productNameLine2 += "...";
        }
    }

    return result;
};

const getPrintData = (data) => {
    const productVariant = data.product_variant;
    const product = productVariant?.product;
    const brand = product?.brand;
    const { productNameLine1, productNameLine2 } = getProductName(
        productVariant.display_name
    );

    const includeProcessingFee = getAskingPriceWithProcessingFee(
        data.asking_price, 
        data.processingFeeOfflineTx
    );
    const price = Number(includeProcessingFee).toLocaleString("id");
    let size = data.size?.US;
    const categoryName = productVariant.product?.category?.name;
    const categoryNameOnWebsite = getCategoryName(categoryName);
    const isShowingUSText = ["sneakers"].includes(categoryName);
    const rack = data?.sell_racks?.rack?.name || data.rack;

    if (isShowingUSText) {
        size = `US ${size}`;
    }

    const url = `https://kickavenue.com/${categoryNameOnWebsite}/${brand?.slug}/${productVariant.slug}`;

    const result = {
        productNameLine1,
        productNameLine2,
        size,
        price,
        sku: productVariant.SKU,
        currency: data.currency,
        consignmentId: data.consignment_id || "",
        url,
        rack,
        asking_price: data.asking_price,
    };

    return result;
};

export { generateQrCode, getZplScript, getProductName, getPrintData };
