export const getCategoryName = (category) => {
    switch (category) {
        case "apparels":
        case "fashion":
        case "apparel":
            return "apparel";
        case "lifestyles":
        case "toys":
            return "toys";
        case "handbags":
        case "luxury":
            return "luxury";
        case "all_category":
            return "";
        case "madeindonesia":
            return "madeindonesia";
        default:
            return "sneakers";
    }
};

export const getSystemCategory = (category) => {
    switch (category) {
        case "apparels":
        case "fashion":
        case "apparel":
            return "apparels";
        case "lifestyles":
        case "lifestyle":
        case "toys":
            return "lifestyle";
        case "handbags":
        case "luxury":
            return "handbags";
        case "madeindonesia":
            return "madeindonesia";
        default:
            return "sneakers";
    }
};

export const capitalizeFirstLetter = (param) => {
    if (!param) {
        return "";
    }

    return param.charAt(0).toUpperCase() + param.slice(1);
};

export const formatRupiah = (param) => {
    try {
        const rupiahFormat = new Intl.NumberFormat("id-ID", {
            style: "currency",
            currency: "IDR",
            maximumFractionDigits: 0,
        }).format(param);

        return rupiahFormat;
    } catch {
        return "";
    }
};

export const joinClassNames = (...args) => {
    return args.join(" ");
}

export const truncateWithEllipsis = (str, len) => {
    return str.length > len ? str.slice(0, len) + "..." : str;
};
