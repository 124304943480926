import api from "../../../utils/Api";
import { AUTH_TOKEN_KEY } from "../../../constants";
import { useState } from "react";
import { manipulateErrors } from "../../../utils";

export const usePostVoucher = () => {
    const [isFetch, setIsFetch] = useState(false);
    const [error, setError] = useState({ error: { message: 'I got something undefined errors', status_code: 500 } });
    const [data, setData] = useState({});

    const postVoucher = (payload) => {
        setIsFetch(true);
        return api({
            Authorization: 'Bearer ' + localStorage.getItem(AUTH_TOKEN_KEY)
        })
            .post(`/admins/vouchers/apply`, payload)
            .then((res) => {
                setIsFetch(false);
                setData(res.data.data);
                return res.data.data;
            })
            .catch((err) => {
                setIsFetch(false);
                setError(manipulateErrors(err));
                return Promise.reject(err);
            })
    };

    return { isFetch, error, postVoucher, data };
}
