import React, { } from 'react';
import { Button, Col, Container, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { SellerFilterForm } from "../";
import { shippingMethodList } from "../../../../../constants/kickavenue";
import InputDatetime from "../../../../../components/Form/InputDatetime";
import { parsedDate } from "../../../../../utils/form";
import { UserSelectAsync } from "../../../../Components/UserSelectAsync";

const filterShippingMethodOptions = [{ name: 'All', value: '' }, ...shippingMethodList];
const categoryOptions = [
    {
        name: 'All',
        value: '',
    },
    {
        name: 'Sneakers',
        value: 'sneakers',
    },
    {
        name: 'Apparel',
        value: 'apparel',
    },
    {
        name: 'Luxury',
        value: 'handbags',
    },
    {
        name: 'Electronic & Collectibles',
        value: 'lifestyles',
    },
];

export default function ExportForm(props) {
    const {
        startAt,
        endAt,
        keyword,
        onInputChange,
        seller,
        onSellerChange,
        category,
        shippingMethod,
        onSubmit,
    } = props;

    const handleDatePickerChange = (value, name) => {
        const val = parsedDate(value, 'YYYY-MM-DD');
        onInputChange(val, name);
    }

    return (
        <Form inline onSubmit={onSubmit}>
            <Container style={{ padding: 0 }}>
                <Row className="mb-1">
                    <Col xs={12} sm={3}>
                        <FormGroup className="mb-3">
                            <Col xs={12} sm={12}>
                                <Label className="justify-content-start mb-1">
                                    Started At
                                </Label>
                            </Col>
                            <Col xs={12} sm={12}>
                                <InputDatetime
                                    name="started_at"
                                    inputProps={{ placeholder: 'Pick a Start At', id: 'formStartedAt' }}
                                    value={startAt}
                                    dateFormat={('YYYY-MM-DD')}
                                    timeFormat={false}
                                    onChange={(date) => handleDatePickerChange(date, 'startedAt')}
                                />
                            </Col>
                        </FormGroup>
                    </Col>
                    <Col xs={12} sm={3}>
                        <FormGroup row className="mb-3">
                            <Col xs={12} sm={12}>
                                <Label className="justify-content-start mb-1">
                                    End At
                                </Label>
                            </Col>
                            <Col xs={12} sm={12}>
                                <InputDatetime
                                    name="end_at"
                                    inputProps={{ placeholder: 'Pick a End At', id: 'formEndedAt' }}
                                    value={endAt}
                                    dateFormat={('YYYY-MM-DD')}
                                    timeFormat={false}
                                    onChange={(date) => handleDatePickerChange(date, 'endedAt')}
                                />
                            </Col>
                        </FormGroup>
                    </Col>
                </Row>
                <Row className="mb-1">
                    <Col xs={12} sm={3}>
                        <FormGroup className="mb-3">
                            <Col xs={12} sm={12}>
                                <Label className="justify-content-start mb-1">
                                    Keyword
                                </Label>
                            </Col>
                            <Col xs={12} sm={12}>
                                <Input
                                    className="w-100"
                                    type="text"
                                    placeholder="Search by CG ID, SKU, Product Name..."
                                    name="keyword"
                                    value={keyword}
                                    onChange={(e) => onInputChange(e.nativeEvent.target.value, 'keyword')}
                                />
                            </Col>
                        </FormGroup>
                    </Col>
                    <Col xs={12} sm={3}>
                        <FormGroup row className="mb-3">
                            <Col xs={12} sm={12}>
                                <Label className="justify-content-start mb-1">
                                    Verified by
                                </Label>
                            </Col>
                            <Col xs={12} sm={12}>
                                <UserSelectAsync
                                    value={seller}
                                    onChange={onSellerChange}
                                />
                            </Col>
                        </FormGroup>
                    </Col>
                </Row>
                <Row className="mb-1">
                    <Col xs={12} sm={3}>
                        <FormGroup className="mb-3">
                            <Col xs={12}>
                                <Label className="justify-content-start mb-1">
                                    Category
                                </Label>
                            </Col>
                            <Col xs={12}>
                                <Input
                                    className="w-100"
                                    type="select"
                                    name="category"
                                    onChange={(e) => onInputChange(e.nativeEvent.target.value, 'category')}
                                    value={category}
                                >
                                    {categoryOptions.map((option, i) => <option value={option.value} key={i}>{option.name}</option>)}
                                </Input>
                            </Col>
                        </FormGroup>
                    </Col>
                    <Col xs={12} sm={3}>
                        <FormGroup row className="mb-3">
                            <Col xs={12}>
                                <Label className="justify-content-start mb-1">
                                    Shipping Method
                                </Label>
                            </Col>
                            <Col xs={12}>
                                <Input
                                    className="w-100"
                                    type="select"
                                    name="shippingMethod"
                                    onChange={(e) => onInputChange(e.nativeEvent.target.value, 'shippingMethod')}
                                    value={shippingMethod}
                                >
                                    {filterShippingMethodOptions.map((option, i) => <option value={option.value} key={i}>{option.name}</option>)}
                                </Input>
                            </Col>
                        </FormGroup>
                    </Col>
                </Row>
                <Row className="mb-1">
                    <Col xs={12}>
                        <FormGroup>
                            <Col xs={12}>
                                <Button color="primary">Export</Button>
                            </Col>
                        </FormGroup>
                    </Col>
                </Row>
            </Container>
        </Form>
    )
}
