import React, { Component } from 'react';
import { connect } from 'react-redux';
import{
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Label,
  Input,
  Form,
  Row,
  Col,
  Alert
} from 'reactstrap';
import Loading from 'react-loading-animation';
import classNames from 'classnames';

import { ButtonLoading } from '../../components/Button';
import { errorMessage, hasError, textValue } from '../../utils/form';
import { doUpdateProfile } from '../../actions/authAction';

class MyProfiles extends Component{
  constructor(props){
    super(props)
    this.state = {
      _isSubmit: false,
      _isSuccessAlert: false,
      _isErrorAlert: false,
      _error: null,
      email: '',
      username: '',
      first_name: '',
      family_name: '',
      gender: ''
    }
    this._handleChange = this._handleChange.bind(this);
    this._onSubmit = this._onSubmit.bind(this);
    this._handleDismissErrorAlert = this._handleDismissErrorAlert.bind(this);
    this._handleDismissSuccessAlert = this._handleDismissSuccessAlert.bind(this);
  }

  componentDidMount(){
    if(this.props.auth.isFetch === false && this.props.auth.id !== undefined){
      const { email, username, sex, first_name, family_name } = this.props.auth;
      this.setState({
        email, username, gender: sex, first_name, family_name
      })
    }
  }

  componentWillReceiveProps(nextProps){
    if(!nextProps.auth.isFetch && nextProps.auth.isFetch !== this.props.auth.isFetch){
      if(nextProps.auth.id !== undefined){
        const { email, username, sex, first_name, family_name } = nextProps.auth;
        this.setState({
          email, username, gender: sex, first_name, family_name
        })
      }
    }
    if(!nextProps.auth.updateProfile.isSubmit
      &&nextProps.auth.updateProfile.isSubmit!==this.props.auth.updateProfile.isSubmit
      &&this.state._isSubmit){
      if(nextProps.auth.updateProfile.error!==null){
        const {message, status_code, errors} = nextProps.auth.updateProfile.error;
        let errs = {}
        if(errors){
          Object.keys(errors).forEach(key=>{
            if(key==='sex'){
              errs['gender'] = errors[key];
            }
            else
              errs[key] = errors[key];
          })
        }
        this.setState({
          _isSubmit:false,
          _error:{
            errors: errs,
            message,
            statusCode: status_code
          },
          _isErrorAlert:true
        })
      }
      else{
        const { message } = nextProps.auth.updateProfile;
        this.setState({_isSubmit:false,_isSuccessAlert:true, successMsg: message})
      }
    }
  }

  _handleDismissErrorAlert(){
    this.setState({_isErrorAlert:false})
  }

  _handleDismissSuccessAlert(){
    this.setState({_isSuccessAlert:false})
  }

  _handleChange(e){
    const lastState = {...this.state};
    lastState[e.target.name] = e.target.value;
    this.setState({...lastState})
  }

  _onSubmit(e){
    e.preventDefault();
    const { first_name, family_name, gender } = this.state;
    this.setState({
      _isSubmit: true,
      _isErrorAlert: false,
      _isSuccessAlert:false,
      _error:null
    },()=>{
      this.props.submitUpdateProfile({
        first_name,
        family_name,
        sex: gender
      });
    })
  }

  _handleDisplayErrorMsg(){
    const defMsg = "We've got something errors.";
    if(this.state._error!==null){
      const { message, statusCode } = this.state._error;
      switch(statusCode){
        case 422:
          return "Please fullfill your form.";
        case 404:
          return "Resource not found.";
        default:
          return `(${statusCode}) ${message}`;
      }
    }
    return defMsg
  }

  render(){
    return (
      <Col xs={12} md={{size:6, offset:3}}>
        <Card>
          <CardHeader>
            <i className="fa fa-align-justify"></i> Update My Profile
          </CardHeader>
          <CardBody>
            <Alert color="success" isOpen={this.state._isSuccessAlert} toggle={this._handleDismissSuccessAlert}>
              <strong>Well done!</strong> Update profile have been successfully.
            </Alert>
            <Alert color="danger" isOpen={this.state._isErrorAlert} toggle={this._handleDismissErrorAlert}>
              <strong>Oh Snap!</strong> {this._handleDisplayErrorMsg()}
            </Alert>
            <Loading isLoading={this.props.auth.isFetch}>
              <Form onSubmit={this._onSubmit}>
                <Row>
                  <Col xs={12}>
                    <FormGroup className={classNames({'has-danger has-feedback': hasError('email',this.state._error)})}>
                      <Label>
                        Email
                      </Label>
                      <Input
                        name="email" 
                        value={textValue('email', this.state)} 
                        type="text" 
                        placeholder="Email"
                        readOnly
                      />
                      { errorMessage('email',this.state._error) }
                    </FormGroup>
                  </Col>
                  <Col xs={12}>
                    <FormGroup className={classNames({'has-danger has-feedback': hasError('username',this.state._error)})}>
                      <Label>
                        Username
                      </Label>
                      <Input
                        name="username" 
                        value={textValue('username', this.state)} 
                        type="text" 
                        placeholder="Username"
                        readOnly
                      />
                      { errorMessage('username',this.state._error) }
                    </FormGroup>              
                  </Col>
                  <Col xs={12} md={6}>
                    <FormGroup className={classNames({'has-danger has-feedback': hasError('first_name',this.state._error)})}>
                      <Label>
                        First Name
                      </Label>
                      <Input
                        name="first_name" 
                        value={textValue('first_name', this.state)} 
                        type="text" 
                        placeholder="Your firstname"
                        onChange={this._handleChange}
                      />
                      { errorMessage('first_name',this.state._error) }
                    </FormGroup>
                  </Col>
                  <Col xs={12} md={6}>
                    <FormGroup className={classNames({'has-danger has-feedback': hasError('family_name',this.state._error)})}>
                      <Label>
                        Family Name
                      </Label>
                      <Input
                        name="family_name"
                        value={textValue('family_name', this.state)} 
                        type="text" 
                        placeholder="Your family name"
                        onChange={this._handleChange}
                      />
                      { errorMessage('family_name',this.state._error) }
                    </FormGroup>
                  </Col>
                  <Col xs={12} md={6}>
                    <FormGroup className={classNames({'has-danger has-feedback': hasError('gender',this.state._error)})}>
                      <Label>
                        Gender
                      </Label>
                      <Input
                        name="gender"
                        value={textValue('gender', this.state)}
                        type="select"
                        onChange={this._handleChange}
                      >
                        <option value="">Pilih Gender</option>
                        <option value="M">Male</option>
                        <option value="O">Female</option>
                      </Input>
                      { errorMessage('gender',this.state._error) }
                    </FormGroup>
                  </Col>
                </Row>
                <FormGroup>
                  <ButtonLoading isLoading={this.state._isSubmit}> Submit </ButtonLoading>
                </FormGroup>
              </Form>
            </Loading>
          </CardBody>
        </Card>
      </Col>
    )
  }
}

const mapStateToProps = ({ auth }) => ({
  auth
})

const mapDispatchToProps = dispatch => ({
  submitUpdateProfile: payload => dispatch(doUpdateProfile(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(MyProfiles);