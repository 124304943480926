import React, { Component } from "react";
import { connect } from "react-redux";
import "react-select/dist/react-select.css";
import classNames from "classnames";
import InputDatetime from "../../../components/Form/InputDatetime/InputDatetime";
import Select, { Creatable } from "react-select";
import {
  Row,
  Card,
  CardHeader,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  Alert,
  Button,
  ButtonGroup,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import uuidv4 from 'uuid/v4';
import {
  hasError,
  textValue,
  dateChange,
  dateValue,
  checkChange,
} from "../../../utils/form";
import { ImagesCompressor, readFile, createUploadFileForm } from '../../../utils/imageHelper';
import { Link } from "react-router-dom";
import privateView from "../../../components/hocs/privateView";
import {
  createPopUpBanner,
  deletePopUpBanner,
  updatePopUpBanner,
} from "../../../actions/popUpBannerAction";
import { ValidationMessage } from "../../../components/Form/ValidationMessage";
import { ImageFile } from '../../../components/Images';
import { DropzonePlusButton } from '../../../components/Form/DropzoneFile';
import { uploadImage, uploadImageNoRedux } from '../../../actions/imageUploadAction';

const page_urls = [
  {
    label: "homepage",
    value: "home"
  },
  {
    label: "homepage sneaker",
    value: "sneaker"
  },
  {
    label: "homepage luxury",
    value: "luxury"
  },
  {
    label: "homepage madeindonesia",
    value: "madeindo"
  },
  {
    label: "homepage streetwear",
    value: "apparel"
  },
  {
    label: "homepage lifestyle",
    value: "electronics-collectibles"
  },
  // {
  //   label: "homepage toys",
  //   value: "toys"
  // },
  {
    label: "product details sneakers",
    value: "details-sneakers"
  },
  {
    label: "product details luxury",
    value: "details-luxury"
  },
  {
    label: "product details madeindonesia",
    value: "details-madeindo"
  },
  {
    label: "product details streetwear",
    value: "details-apparel"
  },
  {
    label: "product details lifestyle",
    value: "details-electronics-collectibles"
  },
  // {
  //   label: "product details toys",
  //   value: "details-toys"
  // },
]

const addImageRawObject = file => {
  return {
    status: false,
    identifier: uuidv4(),
    fileInput: file,
    is_uploading: false
  }
}

const DEFAULT_IMAGE_ASPECT_LANDSCAPE = 16/5;
const DEFAULT_IMAGE_ASPECT_PORTRAIT = 1/1;


const sliderPlatformOptions = [{
  value: "", label: "Web & App"
},{
  value: "APP", label: "Only App"
},{
  value: "WEB", label: "Only Web"
}];

class FormCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      _form: {
        _id: this.props.edit ? this.props.match.params.id : null,
        title: "",
        img_url: "",
        app_image: "",
        started_at: "",
        ended_at: "",
        page_url: "",
        redirect_url: "",
        platform: "",
        active: false,
        binding: false,
      },
      _errors:[],
      _formSubmit: false,
      _modalDelete: false,
      isDeleting: false,
      oldImageUrl: null,
      isEditImageOpen: false,
      editImageRatio: DEFAULT_IMAGE_ASPECT_LANDSCAPE,
      imageSourceToEdit: null,
      imageSourceToEdits: [],
      imageSourceReferer: null, //'default' or 'childs'
      _initFetch: true,
      _rawImage: null,
      _rawImages: [],
      _uploadCounter: 0,
      _submitFormStatus: null,
      _isSuccessSubmit: false,
      _isSuccessUploadDefaultImgAlertOpen: false,
      _isModalLoadingOpen: false,
      _isModalDeleteOpen: false,
      _validateRedirectUrlInput: false,
      submitImage: false,
      payloadForCreateOrUpdate: null,
    };

    this.handleCheckedChange = this.handleCheckedChange.bind(this);
    this.toggleModalDelete = this.toggleModalDelete.bind(this);
    this.onDelete = this.onDelete.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    const { detail, isFetch, isSubmit, error } = this.props.popUpBanner;
    if (this.props.edit && detail.status_code === 200) {
      if (prevProps.popUpBanner.isFetch && !isFetch && !this.state._form.binding) {
        try {
          const _form = { ...this.state._form };
          _form.binding = true;
          this.setState({ _form }, () => {
            const urls =  detail?.page_url?.split(",").map(url => {
              return { label: page_urls.filter(u => u.value==url)[0].label, url, value: url }
            });
            const _newForm = { ...detail, page_url:urls, _id:detail.id};
            this.setState({
              _isSuccessSubmit: false,
              _formSubmit:false,
              _form: _newForm,
              img_url: detail?.images?.[0]?.URL,
              _appImage: {
                imgUrl: detail?.images?.[1]?.URL
              },
              platform: detail.platform
            });
          });
        } catch (error) {
          console.log(error)
        }
      }
    }

    if (this.state._formSubmit &&
      prevProps.popUpBanner.isSubmit && !isSubmit
    ) {
      if (this.state.isDeleting) {
        this.setState({ isDeleting: false });
        this.toggleModalDelete();
        this.props.history.replace('/pop_up_banner')
        return
      }
      if (error === null) {
        if (!this.state._form._id && detail.id) {
          this.props.history.push(`/pop_up_banner/${detail.id}`)
        }
        this.setState({
          _formSubmit:!this.state._formSubmit,
          _isSuccessSubmit:true
        })
      } else {
        const {_errors}=this.state;
        this.setState({
          _errors: [..._errors, {key:'default',...error}],
          _formSubmit: false,
        });
      }
    }

    // Submit form step 3
    if (prevState.submitImage != this.state.submitImage && !this.state.submitImage && this.state.payloadForCreateOrUpdate) {
      const { forms, uploadedImages } = this.state.payloadForCreateOrUpdate;
      const payload = {
        ...forms,
        images: [
          {
            url: uploadedImages?.[0]?.url,
            orientation: "landscape",
            platform: "DESKTOP"
          },
          {
            url: uploadedImages?.[1]?.url,
            orientation: "portrait",
            platform: "MOBILE"
          },
        ],
      }

      if (this.props.edit) {

        if(detail.images.length) {
          const orientationImage = detail.images.filter(img => img.orientation == "landscape")
          const portraitImage = detail.images.filter(img => img.orientation == "portrait")
          
          payload.images = [
            {
              ...payload.images[0],
              id: orientationImage.length ? orientationImage[0].id : null
            },
            {
              ...payload.images[1],
              id: portraitImage.length ? portraitImage[0].id : null
            }
          ]
        }
        this.props.update(this.state._form._id, payload);
      } else {
        this.props.new(payload);
      }
    }
  }

  renderLoading() {
    return (
      <div className="row">
        <div className="col-sm-12">
          <Alert color="info" className="text-center">
            Getting all data...
          </Alert>
        </div>
      </div>
    );
  }

  renderInfo() {
    let infoMessage = [];
    if (this.state._formSubmit)
      infoMessage.push(
        <Alert color="info" key="info">
          <strong>Heads up!</strong> Submitting...
        </Alert>
      );
    if (this.state._isSuccessSubmit)
      infoMessage.push(
        <Alert color="success" key="success">
          <strong>Well done!</strong> {this.props.popUpBanner.success}
        </Alert>
      );
    if (this.state._errors.length>0) {
      const { status_code, message } = this.state._errors[0];
      switch (status_code) {
        case 422:
          infoMessage.push(
            <Alert color="danger" key="error">
              <strong>Oh Snap!</strong> Please fullfill your form.{" "}
            </Alert>
          );
          break;
        case 404:
          infoMessage.push(
            <Alert color="danger" key="error">
              <strong>Oh Snap!</strong> Resource not found.{" "}
            </Alert>
          );
          break;
        default:
          infoMessage.push(
            <Alert color="danger" key="error">
              <strong>Oh Snap!</strong> {message}
            </Alert>
          );
          break;
      }
    }
    return (
      <div className="row">
        <div className="col-sm-12">{infoMessage}</div>
      </div>
    );
  }

  handleChange(e) {
    const newState = { ...this.state._form };
    newState[e.target.name] = e.target.value;
    this.setState({ _form: newState });
  }

  handleCheckedChange(e) {
    const _form = checkChange(e, this.state._form);
    this.setState({ _form: { ..._form } });
  }

  validateError(name){
    if(this.hasError(name))
    {
      let errorList=this.state._errors[0].errors;
      return <ValidationMessage message={errorList[name][0]}/>
    }
    return '';
  }

  handleDatePickerChange(value, name) {
    const _form = dateChange(value, name, this.state._form);
    this.setState({ _form: { ..._form } });
  }


  hasError(name){
    const { _errors } = this.state;
    if(_errors.length)
    {
      if(_errors[0].errors)
      {
        let errorList=_errors[0].errors;
        let errList=Object.keys(errorList).filter(key=> key==name);
        if(errList.length)
          return true
      }
    }
    return false
  }

  toggleModalDelete() {
    const { _modalDelete } = this.state
    this.setState({ _modalDelete: !_modalDelete })
  }

  validateForm() {
    const {
      title,
      page_url,
      redirect_url,
    } = this.state._form;
    let errors={};
    if(!title)
      errors['title']=['Please fill in title.'];
    if(!page_url)
      errors['page_url']=['Please fill in page url.'];
    if(!redirect_url)
      errors['redirect_url']=['Please fill in redirect url.'];
    if(!this.state.img_url)
      errors['img_url']=['Please fill in img url.'];
    if(!this.state._appImage)
      errors['app_image']=['Please fill in app image.'];
    if(Object.keys(errors).length){
      this.setState({
        _isSuccessSubmit:false,
        _errors:[{key:'default',errors:{...errors}, status_code:422,message:'Please full fill form'}]
      })
    }
  return errors;
  }

  onSubmit(e) {
    e.preventDefault();
    this.setState({_errors:[]})
    const isValid = this.validateForm()
    if (Object.keys(isValid).length===0) {
      this.setState({ _formSubmit: true}, async () => {
        const {
          _id,
          title,
          started_at,
          ended_at,
          redirect_url,
          active,
          page_url
        } = this.state._form;

        const {
          _rawImage,
          _appImage: {
            raw,
          } = {},
          platform
        } = this.state

        const payload = {
          title,
          platform,
          started_at,
          ended_at,
          page_url: page_url?.length ? page_url.map(u => u.value).join(",") : "",
          redirect_url,
          active
        }

        if (!started_at) {
          delete payload.started_at
        }
        if (!ended_at) {
          delete payload.ended_at
        }

        this.setState({
          submitImage: true,
        }, async () => {
          // Submit form step 1
          const rawImages = [_rawImage, raw]
          await Promise.all(
            rawImages.map(async (_raw) => {
              let result = null
              if(!_raw?.fileInput) return result
              const fileInput = await this._compressImage(_raw?.fileInput)
              const name = `slider-images`
              const tempPayload = createUploadFileForm(fileInput, false, { name, bucket_type: "assets" });


              await uploadImageNoRedux(tempPayload)
                .then(res => res?.data)
                .then(res => (result = res?.data))

              return result
            })
          )
            .then(res => {
              // Submit form step 2
              //Prepare payload -> check componentDidUpdate for next action
              this.setState({
                payloadForCreateOrUpdate: {
                  uploadedImages: res,
                  forms: payload
                }
              })
            })
            .finally(() => {
              this.setState({
                submitImage: false
              })
            })

        })
      });
    }
  }

  _compressImage = async (image) => {
    let fileInput = image;
    if (fileInput.type === "image/gif") {
      return fileInput;
    }

    try {
      //compress file
      fileInput = await ImagesCompressor(image);
    }
    catch (e) {
      alert('error while compress the images')
      fileInput = image
    }
    return fileInput;
  }

  onDelete(e) {
    e.preventDefault()
    const { _id } = this.state._form
    if (this.props.edit && _id) {
      this.setState({ _formSubmit: true, isDeleting: true }, () => {
        this.props.delete(_id)
      })
    }
  }

  _handleDropzoneChange = async (files, type) => {
    if (files.length) {
      if (type === 'default') {
        const [file] = files;
        const imageDataUrl = file.preview;
        const isGif = file.type === "image/gif";
        this.setState({
          _rawImage: addImageRawObject(file),
          img_url: imageDataUrl,
          ...!isGif && {
            isEditImageOpen: true,
            imageSourceToEdit: imageDataUrl,
            imageSourceReferer: 'default',
            editImageRatio: DEFAULT_IMAGE_ASPECT_LANDSCAPE
          }
        });
      }
      else if (type === 'app_image') {
        const [file] = files;
        const imageDataUrl = file.preview;
        const obj = addImageRawObject(file)
        const isGif = file.type === "image/gif";
        const { _appImage } = this.state;
        if (isGif) {
          obj.orientation = "portrait";
        }
        this.setState({
          _appImage: _appImage ? {
            ..._appImage,
            imgUrl: imageDataUrl,
            raw: { ...obj }
          } : {
            id: 0,
            imgUrl: imageDataUrl,
            raw: { ...obj }
          },
          _rawImages: [obj],
          ...!isGif && {
            isEditImageOpen: true,
            imageSourceToEdits: [{ identifier: obj.identifier, imageDataUrl }],
            imageSourceToEdit: imageDataUrl,
            imageSourceReferer: 'app_image',
            editImageRatio: DEFAULT_IMAGE_ASPECT_PORTRAIT
          }
        });
      }
      else if (type === 'multiple') {
        let fileList = [];
        let rawFiles = [];
        for (let i = 0; i < files.length; i++) {
          fileList.push(files[i])
        }
        for (let i = 0; i < fileList.length; i++) {
          const file = fileList[i];
          const imageDataUrl = await readFile(file)
          rawFiles.push({
            ...addImageRawObject(file),
            imageDataUrl
          })
        }
        if (rawFiles.length) {
          const { _rawImages } = this.state;
          this.setState({
            _rawImages: [..._rawImages, ...rawFiles],
            isEditImageOpen: true,
            imageSourceToEdits: rawFiles.map(({ identifier, imageDataUrl }) => ({ identifier, imageDataUrl })),
            imageSourceToEdit: rawFiles[0].imageDataUrl,
            imageSourceReferer: 'childs',
            editImageRatio: DEFAULT_IMAGE_ASPECT_PORTRAIT
          });
        }
      }
    }
  }

  _handleInputChange = ({ target: { name, value } }) => {
    const { _form } = this.state;
    this.setState({
      _form: {
        ..._form,
        [name]: value
      }
    })
  }

  _handleSelectChange = (name, val) => {
    this.setState({ [name]: val ? val.value : '' })
  }

  _handleOnChange(value) {
    this.setState({ _form: {...this.state._form, page_url:value}});
  }

  render() {
    return (
      <Row>
        <Modal
          isOpen={this.state._modalDelete}
          toggle={this.toggleModalDelete}
          className="modal-dialog modal-sm"
        >
          <ModalHeader toggle={this.toggleModalDelete}>
            Confirmation
          </ModalHeader>
          <ModalBody>
            {this.state.isDeleting &&
              this.props.popUpBanner.isSubmit ? (
              <div className="row">
                <div className="col-sm-12">
                  <Alert color="info" className="text-center">
                    Deleting...
                  </Alert>
                </div>
              </div>
            ) : (
              <span>Are you sure to delete this?</span>
            )}
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.onDelete}>
              Yes
            </Button>{" "}
            <Button color="secondary" onClick={this.toggleModalDelete}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>

        <div className="col-md-6 offset-md-3">
          <Card>
            <CardHeader>
              <i className="fa fa-align-justify"></i>{" "}
              <span className="mr-2">{this.props.formTitle}</span>
              {this.props.edit ? (
                <Link
                  to="/pop_up_banner/create"
                  title="Create New Pop Up Banner"
                  className="text-right"
                >
                  Create New
                </Link>
              ) : null}
            </CardHeader>
            <CardBody>
              {this.renderInfo()}
              {this.props.edit && this.props.popUpBanner.isFetch ? (
                this.renderLoading()
              ) : (
                <Form onSubmit={this.onSubmit.bind(this)}>
                  <FormGroup
                    className={
                      this.hasError("title") ? " has-danger has-feedback" : ""
                    }
                  >
                    <Label for="title">Title</Label>
                    <Input
                      type="text"
                      id="title"
                      name="title"
                      autoComplete="off"
                      placeholder="Enter a Title*"
                      value={this.state._form.title}
                      onChange={this.handleChange.bind(this)}
                    />
                    {this.validateError("title")}
                  </FormGroup>
                  <FormGroup className={classNames({ 'has-danger has-feedback': this.hasError('platform') })}>
                    <Label>Visible Only</Label>
                    <Select
                      name="platform"
                      value={textValue('platform', this.state)}
                      options={sliderPlatformOptions}
                      onChange={val => this._handleSelectChange('platform', val)}
                    />
                    {this.validateError('platform')}
                  </FormGroup>
                  <FormGroup
                    className={classNames({ 'has-danger has-feedback': this.hasError('img_url') })}
                  >
                    <Label>Image (Desktop ver.)</Label>
                    <div className="d-flex">
                      <ImageFile
                        style={{ padding: 0 }}
                        alt="Slider's default image"
                        src={this.state.img_url ? this.state.img_url : 'https://via.placeholder.com/300x150?text=1280x400'}
                        showDelete={this.state._rawImage !== null}
                        onDelete={() => {
                          this.setState({
                            img_url: this.state.oldImageUrl,
                            _rawImage: null
                          })
                        }}
                      />
                      <div className="ml-3">
                        <DropzonePlusButton accept={[".jpg", ".jpeg", ".png", ".gif"]} multiple={false} onDrop={files => this._handleDropzoneChange(files, 'default')} />
                        <ul className="image-guides" style={{ fontSize: '.75rem', marginTop: '.75rem', paddingInlineStart: '1rem' }}>
                          <li className="d-none">You can click the image for preview the image.</li>
                          <li>It will be display on Web only.</li>
                          <li>Use 1280x400 (16:5),  1280x720 (16:9) or  1280x465,45 (11:4) resolution of your image as our recommended. (In pixels).</li>
                          <li>Or any resolution that you want, but make sure its <strong>landscape</strong> orientation.</li>
                          <li className="d-none">If you not provide/upload any images in “Images Form”. By default, this image will be display on our platforms (Web or App).</li>
                          <li className="d-none">By changing the image, click Submit button for save your changes.</li>
                        </ul>
                      </div>
                    </div>
                    {this.validateError('img_url')}
                  </FormGroup>
                  <FormGroup
                    className={classNames({ 'has-danger has-feedback': this.hasError('app_image') })}
                  >
                    <Label>Image (Mobile Web ver.)</Label>
                    <div className="d-flex">
                      <ImageFile
                        style={{ padding: 0 }}
                        alt="Slider's app image"
                        src={this.state._appImage ? this.state._appImage.imgUrl : 'https://via.placeholder.com/300?text=700x700'}
                        showDelete={this.state._rawImages.length > 0}
                        onDelete={() => {
                          const { _appImage } = this.state;
                          this.setState({
                            _appImage: _appImage.id > 0 ? {
                              ..._appImage,
                              imgUrl: _appImage.URL
                            } : null,
                            _rawImages: []
                          })
                        }}
                      />
                      <div className="ml-3">
                        <DropzonePlusButton accept={[".jpg", ".jpeg", ".png", ".gif"]} multiple={false} onDrop={files => this._handleDropzoneChange(files, 'app_image')} />
                        <ul className="image-guides" style={{ fontSize: '.75rem', marginTop: '.75rem', paddingInlineStart: '1rem' }}>
                          <li className="d-none">You can click the image for preview the image.</li>
                          <li>It will be display on Mobile Web only.</li>
                          <li>Use 700x700 (1:1),  700x933,33 (3:4) or  700x1050 (4:6) resolution of your image as our recommended for our app display. (In pixels). </li>
                          <li> Or any resolution that you want, but make sure its <strong>portrait</strong> orientation.</li>
                        </ul>
                      </div>
                    </div>
                    {this.validateError('app_image')}
                  </FormGroup>
                  <FormGroup
                    className={classNames({
                      "has-danger has-feedback": hasError(
                        "started_at",
                        this.state._error
                      ),
                    })}
                  >
                    <Label for="formStartedAt">Started At</Label>
                    <InputDatetime
                      inputProps={{
                        placeholder: "Pick a Start At",
                        id: "formStartedAt",
                      }}
                      value={dateValue("started_at", this.state._form)}
                      onChange={(date) =>
                        this.handleDatePickerChange(date, "started_at")
                      }
                      timeFormat="HH:mm"
                    />
                    {this.validateError("started_at")}
                  </FormGroup>
                  <FormGroup
                    className={classNames({
                      "has-danger has-feedback": hasError(
                        "ended_at",
                        this.state._error
                      ),
                    })}
                  >
                    <Label for="formEndedAt">Ended At</Label>
                    <InputDatetime
                      inputProps={{
                        placeholder: "Pick a Ended At",
                        id: "formEndedAt",
                      }}
                      value={dateValue("ended_at", this.state._form)}
                      onChange={(date) =>
                        this.handleDatePickerChange(date, "ended_at")
                      }
                      timeFormat="HH:mm"
                    />
                    {this.validateError("ended_at")}
                  </FormGroup>
                  <FormGroup className={classNames({ 'has-danger has-feedback': this.hasError('page_url') })}>
                    <Label>Page Url</Label>
                    <Creatable
                      multi
                      options={page_urls}
                      value={textValue('page_url', this.state._form)}
                      placeholder="Enter page urls"
                      onChange={(value) => this._handleOnChange(value)}
                    />

                    {this.validateError('page_url')}
                  </FormGroup>
                  <FormGroup className={classNames({ 'has-danger has-feedback': this.hasError('redirect_url') })}>
                    <Label>Redirect Url</Label>
                    <Input
                      name="redirect_url"
                      placeholder="Example: https://www.kickavenue.com/sneakers/search/yeezy"
                      type="text"
                      value={textValue('redirect_url', this.state._form)}
                      onChange={this._handleInputChange}
                    />
                    {this.validateError('redirect_url')}
                  </FormGroup>
                  <FormGroup
                    className={classNames({
                      "has-danger has-feedback": hasError(
                        "active",
                        this.state._error
                      ),
                    })}
                  >
                    <Label for="formPopUpBannerActive">
                      Set to Active &nbsp;
                    </Label>
                    <label className="switch switch-text switch-success-outline-alt">
                      <input
                        type="checkbox"
                        className="switch-input"
                        name="active"
                        id="formPopUpBannerActive"
                        value="true"
                        checked={textValue("active", this.state._form)}
                        onChange={this.handleCheckedChange}
                      />
                      <span
                        className="switch-label"
                        data-on="On"
                        data-off="Off"
                      ></span>
                      <span className="switch-handle"></span>
                    </label>
                    {this.validateError("active")}
                  </FormGroup>
                  <FormGroup>
                    <ButtonGroup>
                      {this.props.edit && this.state._form._id && (
                        <Button
                          className="mr-2"
                          color="danger"
                          onClick={this.toggleModalDelete}
                          disabled={this.state._formSubmit || this.state.submitImage}
                        >
                          Delete
                        </Button>
                      )}
                      <Button color="primary">Submit</Button>
                    </ButtonGroup>
                  </FormGroup>
                </Form>
              )}
            </CardBody>
          </Card>
        </div>
      </Row>
    );
  }
}

const mapStateToProps = ({
  user,
  auth: { isFetch, isLogged, roles, id, email },
  popUpBanner,
  imageUpload
}) => {
  return {
    user,
    email,
    guardData: { isFetch, isLogged, roles, id, email },
    popUpBanner,
    imageUpload
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    update: (id, payload) => dispatch(updatePopUpBanner(id, payload)),
    new: (payload) => dispatch(createPopUpBanner(payload)),
    delete: (id) => dispatch(deletePopUpBanner(id)),
    uploadImage: (payload, rawImages, callback) => dispatch(uploadImage(payload, rawImages, callback)),
  };
};

const enhance = connect(mapStateToProps, mapDispatchToProps);
export default enhance(privateView(FormCard));
