import React from 'react';
import { connect } from 'react-redux';
import {
  Row,
  Col,
  Card,
  CardBody,
  CardText
}from 'reactstrap';
import { FontAwesomeIcon } from '../../components/Icons';
import { CardHeaderWithToolbars,Title } from '../../components/Card/CardHeaderWithToolbars';
import FilterContainer from '../../components/Container/FilterContainer/FilterContainer';
import FiltersPanel from './skuRequests/FilterPanel';
import Toolbars from './skuRequests/Toolbars';
import { Th } from '../../components/Tbl';
import DropdownProductCategories from '../../components/Dropdowns/DropdownProductCategories';
import {
  getSkuRequestList
} from '../../actions/skuRequestAction';
import privateView from '../../components/hocs/privateView';
import { DEFAULT_SORT_BY, DEFAULT_PER_PAGE } from '../../constants/settings';
import List from './skuRequests/List';
import StatusFilter from './skuRequests/StatusFilter';
import Analytics from "../../services/Analytics";

const defaultParams = {
  page: 1,
  sort_by : DEFAULT_SORT_BY,
  status: "PENDING",
  per_page : DEFAULT_PER_PAGE
}

class SkuRequests extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      _filterOpen: false,
      _isResetFilter: false,
      _selectedCategory: null,
      _params:{
        ...defaultParams
      }
    }
    this._handleToggleFilterPanel = this._handleToggleFilterPanel.bind(this);
    this._handleRefresh = this._handleRefresh.bind(this);
    this._handleResetFilter = this._handleResetFilter.bind(this);
    this._handleApplyFilter = this._handleApplyFilter.bind(this);
    this._handleGoto = this._handleGoto.bind(this);
    this._handleCategoryChanged = this._handleCategoryChanged.bind(this);
    this._refreshList = this._refreshList.bind(this);
  }

  componentDidMount(){
    const { email } = this.props.injectedProps;
    this._refreshList();
    Analytics.recordPageView('sku_request_list', email, {
      url: window.location.href,
      location: this.props.location,
      match: this.props.match
    });
  }

  _getList(rawParams = {}){
    let params = {};
    Object.keys(rawParams)
    .filter(key => rawParams[key]!='')
    .forEach(key=> params[key] = rawParams[key]);
    this.props.getList({params});
  }

  async _refreshList(){
    const { _params } = this.state;
    await this.setState({
      _params:{ ..._params, page: 1 }
    });
    this._getList(this.state._params)
  }

  _handleRefresh(e){
    e.preventDefault();
    this._refreshList();
  }

  _handleToggleFilterPanel(e){
    e.preventDefault();
    this._toggleFilterPanel();
  }

  _toggleFilterPanel(){
    this.setState({_filterOpen:!this.state._filterOpen})
  }

  async _handleResetFilter(e){
    e.preventDefault();
    await this.setState({
      _params:{
        ...defaultParams
      },
      _isResetFilter: true,
      _selectedCategory: null
    });
    this._getList(this.state._params)
  }

  _toggleFilterPanel(){
    this.setState({_filterOpen:!this.state._filterOpen})
  }

  async _handleApplyFilter(payloadFilter){
    await this.setState({
      _params: {
        ...this.state._params,
        ...payloadFilter,
      }
    });
    this._getList(this.state._params)
  }

  async _handleGoto(page) {
		await this.setState({
      _params:{ ...this.state._params, page }
    });
    this._getList(this.state._params)
  }

  async _handleCategoryChanged(category){
    await this.setState({
      _selectedCategory: category === ''? null: category.label,
      _params: {
        ...this.state._params,
        category_id: category === ''? category: category.value,
        page: 1,
      },
      _isFetchData: true
    });
    const { _params } = this.state;
    this._getList(_params);
  }

  render(){
    return(
      <div className="animated fadeIn">
        <Row>
          <Col xs={12}>
            <Card>
              <CardHeaderWithToolbars>
                <Title>
                  <FontAwesomeIcon iconType="align-justify"/>
                  <span>SKU Request List</span>
                </Title>
                <Toolbars
                  isOpen={this.state._filterOpen}
                  onToggleFilter={this._handleToggleFilterPanel}
                  onRefreshList={this._handleRefresh}
                  onResetFilter={this._handleResetFilter}
                />
              </CardHeaderWithToolbars>
              <FilterContainer>
                <FiltersPanel
                  {...this.state._params}
                  isOpen={this.state._filterOpen}
                  isReset={this.state._isResetFilter}
                  onApplyFilter={this._handleApplyFilter}
                  onResetFilterCallback={()=>this.setState({_isResetFilter: false, _filterOpen: false})}
                />
              </FilterContainer>
              <CardBody>
                <CardText>
                  In this section, displaying our customer's new SKU requests.
                  New SKUs doesnt already added in our database.
                  While you approved the item also make new product variant record, also it will be appear to our website.
                </CardText>
                <StatusFilter
                  status={this.state._params.status}
                  onClick={(status) => this._handleApplyFilter({ status })}
                />
                <List
                  page={this.state._params.page}
                  perPage={this.state._params.per_page}
                  onGotoPageList={this._handleGoto}
                  onRenderHeader={()=>(
                    <tr>
                      <Th>No.</Th>
                      <Th>Brands</Th>
                      <Th>
                        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'flex-end'}}>
                          <p style={{marginRight: 10, marginBottom: 0}}>
                            SKU
                            {
                              this.state._selectedCategory !== null?
                              <span style={{display: 'block', fontSize: '.5rem'}}>{this.state._selectedCategory}</span>
                              : null
                            }
                          </p>
                          <DropdownProductCategories
                            value={this.state._params.category_id}
                            onCategoryChanged={this._handleCategoryChanged}
                          />
                        </div>
                      </Th>
                      <Th>Status</Th>
                      <Th>Updated</Th>
                      <Th>Action</Th>
                    </tr>
                  )}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    )
  }
}

const mapStateToProps= ({auth: { isFetch, roles, isLogged, id, email }}) => ({
  email,
	guardData: { isFetch, roles, isLogged, id, email }
});
const mapDispatchToProps = (dispatch) => ({
	getList:(query) => dispatch(getSkuRequestList(query))
});
const enhance = connect(mapStateToProps,mapDispatchToProps);
export default enhance(privateView(SkuRequests,['supervisor','sale_supervisor','inventory_admin']));