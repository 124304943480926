import React, { useState } from 'react';
import propTypes from 'prop-types';
import {
  Col,
  Card,
  CardHeader,
  CardBody,
  Row
} from 'reactstrap';
import Loading from 'react-loading-animation';
import CardThumbnail from './CardThumbnail';
import DropzoneWithButton from './DropzoneWithButton';
import { FontAwesomeIcon } from '../../../components/Icons';
import { SortableContainer, SortableElement, arrayMove } from 'react-sortable-hoc';

const SortableList = SortableContainer(({items, onDrop, onUploadImages, onRemoveImage, onSetThumbnail}) => {
  const [copied, setCopied] = useState(false);
  const [selectedId, setSelectedId] = useState(0);

  const _copyImageUrl = (id, url) => {
    setCopied(true);
    setSelectedId(id);
    navigator.clipboard.writeText(url);
  };

	return (
    <Row className="d-flex align-items-center">
      <Col xs={12} sm={6} md={3}>
        <DropzoneWithButton
          onDrop={onDrop}
          onUploadImages={onUploadImages}
          totalUpload={items.filter(img=> img.status===false).length}
        />
      </Col>
      {
        items.map((image, index) => (
          <SortableItem image={image} key={index} index={index}>
            <CardThumbnail
              id={image.id}
              url={image.url}
              isThumbnail={image.thumbnail}
              position={image.position}
              positionEdit={image.positionEdit}
              updatedAt={image.updated_at}
              storageName={image.storageName}
              fileInput={image.fileInput}
              isCopied={copied}
              copiedId={selectedId}
              copyImageUrl={_copyImageUrl}
              isUploading={image.is_uploading && !image.status}
              isDeleting={image.deleted}
              onRemoveImage={e => onRemoveImage(e, image.identifier, image.id)}
              onSetThumbnail={e => onSetThumbnail(e, image.identifier, image.id)}
            />
          </SortableItem>
        ))
      }
    </Row>
  )
});


const SortableItem = SortableElement(({children}) =>
  <Col xs={12} sm={6} md={3}>
    {children}
  </Col>
);

const ArticleImages = ({ isLoading, isShow, images, onDrop, onRemoveImage, onUploadImages, onSortEnd, onSetThumbnail })=>{
  return (
    <Col xs={12} className={!isShow?'d-none':''}>
      <Card>
        <CardHeader>
          <FontAwesomeIcon iconType="align-justify"/> Images
        </CardHeader>
        <CardBody>
          <Loading isLoading={isLoading}>
            <SortableList onDrop={onDrop} onUploadImages={onUploadImages} items={images} onRemoveImage={onRemoveImage} onSetThumbnail={onSetThumbnail} onSortEnd={({oldIndex, newIndex, ...inputs}) => {
              if(oldIndex !== newIndex){
                let data = [ ...images ]
                const id = images[oldIndex].id;
                const afterId = newIndex>0? images[newIndex].id: null;
                data = arrayMove(data, oldIndex, newIndex).map((item, key) => ({
                  ...item,
                  positionEdit: key + 1
                }))
                onSortEnd(data, id, afterId)
              }
            }} axis="xy"/>
          </Loading>
        </CardBody>
      </Card>
    </Col>
  )
}

ArticleImages.propTypes = {
  isLoading: propTypes.bool,
  isShow: propTypes.bool,
  images: propTypes.arrayOf(propTypes.shape({
    identifier: propTypes.string.isRequired,
    id: propTypes.number,
    url: propTypes.string,
    updated_at: propTypes.string,
    fileInput: propTypes.object,
    is_uploading: propTypes.bool,
    status: propTypes.bool,
    deleted: propTypes.bool
  })),
  onDrop: propTypes.func,
  onUploadImages: propTypes.func,
  onRemoveImage: propTypes.func,
  onSetThumbnail: propTypes.func
}

ArticleImages.defaultProps = {
  isLoading: false,
  isShow: false
}

export default ArticleImages;