import {
  SHIPPING_LIST,
  SHIPPING_ERROR,
  FETCHING_SHIPPING,
  FETCHING_PROVINCE,
  PROVINCE_LIST,
  PROVINCE_ERROR,
  CITY_LIST,
	FETCHING_CITY,
  CITY_ERROR,
  COURIERS_COST_POST,
  FETCHING_COURIERS,
  COURIERS_ERROR,
  APPLY_VOUCHER,
	APPLY_VOUCHER_ERROR,
	FETCHING_APPLY_VOUCHER,
} from '../constants';

const initialState = {
  list:{
    data: [],
  },
  detail: {
    isFetch: false,
    isSubmit: false,
    error: null,
    data: {
      deleted: false
    }
  },
  provinces:{
    data:[],
    isFetch:false,
    error:null
  },
  cities:{
    data:[],
    isFetch:false,
    error:null
  },
  couriers:{
    cost:0,
    isFetch:false,
    error:null
  },
  voucher:{
    data:null,
    isFetch:false,
    error:null
  },
  isFetch: false,
  isSubmit:false,
  error: null,
  success:null,
}

const onGetShipping = (lastState, payload) => {
  const { list } = lastState;
  const { data, ...otherPayload } = payload;
  return { ...lastState, success:otherPayload.message, list:{ ...list, ...data, ...otherPayload }, error:null}
}

const onFetching = (lastState,payload) => {
  if(payload.type==='fetch')
      return { ...lastState, isFetch:payload.status}
  else if(payload.type===FETCHING_PROVINCE)
    return { ...lastState, provinces:{...lastState.provinces,isFetch:payload.status}}
  else if(payload.type===FETCHING_CITY)
    return { ...lastState, cities:{...lastState.cities,isFetch:payload.status}}
  else if(payload.type===FETCHING_COURIERS)
    return { ...lastState, couriers:{...lastState.couriers,isFetch:payload.status}}
  else if(payload.type===FETCHING_APPLY_VOUCHER)
    return { ...lastState, voucher:{...lastState.voucher,isFetch:payload.status}}
  else if(payload.type==='submit')
      return { ...lastState, isSubmit:payload.status}
  return { ...lastState, isFetch:false,isSubmit:false }
}

const onShippingError = (lastState,error) => {
	return { ...lastState, ...error, success:null };		
}

const reducer = (state = initialState, action)=>{
  switch(action.type){
    case SHIPPING_ERROR:return onShippingError(state,action.payload);
    case FETCHING_SHIPPING: return onFetching(state,action.payload);
    case SHIPPING_LIST: return onGetShipping(state,action.payload);
    case PROVINCE_LIST: {
      const { data, status_code, message } = action.payload;
      return {...state, provinces:{...state.provinces, data, status_code, message,isFetch:false, error:null}}
    };
    case PROVINCE_ERROR: return {...state, provinces:{...state.provinces, ...action.payload, isFetch:false}};
    case FETCHING_PROVINCE: return onFetching(state,action.payload);
    case CITY_LIST: {
      const { data, status_code, message } = action.payload;
      return {...state, cities:{...state.cities, data, status_code, message,isFetch:false, error:null}}
    };
    case CITY_ERROR: return {...state, cities:{...state.cities, ...action.payload, isFetch:false}};
    case FETCHING_CITY: return onFetching(state,action.payload);
    case COURIERS_COST_POST: {
      const { data, status_code, message } = action.payload;
      return {...state, couriers:{...state.couriers, cost:data, status_code, message,isFetch:false, error:null}}
    };
    case COURIERS_ERROR: return {...state, couriers:{...state.couriers, ...action.payload, isFetch:false}};
    case FETCHING_COURIERS: return onFetching(state,action.payload);
    case APPLY_VOUCHER: {
      const { data, status_code, message } = action.payload;
      return {...state, voucher:{...state.voucher, data, status_code, message,isFetch:false, error:null}}
    };
    case APPLY_VOUCHER_ERROR: return {...state, voucher:{...state.voucher, ...action.payload, isFetch:false}};
    case FETCHING_APPLY_VOUCHER: return onFetching(state,action.payload);
    default: return state;
  }
}
export default reducer;