import {
	GET_LIST_COLLECTION,
	GET_DETAIL_COLLECTION,
	ADD_COLLECTION,
	UPDATE_COLLECTION,
	DELETE_COLLECTION,
	COLLECTION_ERROR,
	// SUBMITING_COLLECTION,
	NEW_COLLECTION,
	FETCHING_COLLECTION,

	COLLECTION_CHILDS_REQUEST,
	COLLECTION_CHILDS_ERROR,
	SUBMITING_COLLECTION_CATEGORIES,
	GET_LIST_COLLECTION_CATEGORIES,

	SUBMITING_COLLECTION_ITEMS,
	GET_LIST_COLLECTION_ITEMS,
	SEARCH_COLLECTION_ITEMS,

	GET_LIST_COLLECTION_SELLER,
	CREATE_COLLECTION_SELLER,
	DELETE_COLLECTION_SELLER,

	CREATE_COLLECTION_IMAGE,
  UPDATE_COLLECTION_IMAGE,
  DELETE_COLLECTION_IMAGE,
  COLLECTIONS_S3
} from '../constants';

/**
 * Initial State
 */
const initialState={
	list:[],
	detail:{},
	isFetch:false,
	isSubmit:false,
	isImageDataSubmit: false,
	error:null,
	success:null,
	collectionSeller: {
		data: [],
		isFetch:false,
		details: {
			isSubmit: false,
			isDeleting: false,
			error: null,
		},
		success: null
	},
	collectionCategories: {
		data: [],
		isSubmit:false,
		error: null
	},
  uploadedImage: {
    isPending: false,
    results: null,
    error: null
  },
	collectionItems: {
		dataSearch: [],
		data: [],
		isSearchFetch: false,
		isFetch: false,
		isSubmit: false,
		error: null
	}
}

/**
 * Function to manipulate payload when success get offer list
 * @author pewe
 * @param {object} lastState 
 * @param {object} payload 
 */
const onGetCollectionList = (lastState,payload) => {
	const {data, message, status_code}=payload;
	return { ...lastState, error:null, success:null,detail:{}, list:{ ...data, message, status_code } };	
}

/**
 * Function to manipulate payload when success get offer details
 * @author pewe
 * @param {object} lastState 
 * @param {object} payload 
 */
const onGetDetailCollection = (lastState,payload) => {
	const {data, message, status_code}=payload;
	return { ...lastState, error:null, success:null, detail:{ ...data, message, status_code } };	
}

/**
 * Function to handle when start or end fetching offer api
 * @author pewe
 * @param {object} lastState 
 * @param {objec} bool 
 */
const onFetchingCollection = (lastState,payload) => {
    if(payload.type==='fetch')
        return { ...lastState, isFetch:payload.status }
    else if(payload.type==='submit')
        return { ...lastState, isSubmit:payload.status }

    return { ...lastState, isFetch:false,isSubmit:false }
}

/**
 * Function to handle when fetching offer error triggered
 * @author pewe
 * @param {object} lastState 
 * @param {object} error 
 */
const onCollectionError = (lastState,error) => {
	return { ...lastState, ...error, success:null };		
}

const onAddCollection = (lastState,payload) => {
	const {data, message, status_code}=payload;
    return { ...lastState, detail:{ ...data, message, status_code }, success:'Insert has been successfully!',error:null }
}

const onUpdateCollection = (lastState,payload) => {
	const {data, message, status_code}=payload;
    return { ...lastState, detail:{ ...data, message, status_code }, success:'Update has been successfully!',error:null }
	
}
const onDeleteCollection = (lastState,payload) => {
	const {data, message, status_code}=payload;
    return { ...lastState, detail:{ ...data, message, status_code, delete:true }, success:'Delete has been successfully!',error:null }
}

const onValidateChildRequest = (lastState, { requestType, value }) => {
	switch(requestType){
		case SUBMITING_COLLECTION_CATEGORIES: return {
			...lastState,
			collectionCategories: {
				...lastState.collectionCategories,
				isSubmit: value
			}
		};
		case GET_LIST_COLLECTION_ITEMS: return {
			...lastState,
			collectionItems: {
				...lastState.collectionItems,
				isFetch: value
			}
		}
		case GET_LIST_COLLECTION_SELLER: return {
			...lastState,
			collectionSeller: {
				...lastState.collectionSeller,
				isFetch: value
			}
		}
		case SEARCH_COLLECTION_ITEMS: return {
			...lastState,
			collectionItems: {
				...lastState.collectionItems,
				isSearchFetch: value
			}
		}
		case SUBMITING_COLLECTION_ITEMS: return {
			...lastState,
			collectionItems: {
				...lastState.collectionItems,
				isSubmit: value
			}
		}
    case CREATE_COLLECTION_IMAGE:
    case UPDATE_COLLECTION_IMAGE:
    case DELETE_COLLECTION_IMAGE:
			return {...lastState, isImageDataSubmit: value}

		case CREATE_COLLECTION_SELLER:
			return {
				...lastState,
				collectionSeller: {
					...lastState.collectionSeller,
					details: {
						...lastState.collectionSeller.details,
						isSubmit: value
					}
				}
			}
		case DELETE_COLLECTION_SELLER:
			return {
				...lastState,
				collectionSeller: {
					...lastState.collectionSeller,
					details: {
						...lastState.collectionSeller.details,
						isSubmit: value,
						isDeleting: value
					}
				}
			}
		default: return lastState;
	}
}

const onErrorChildRequest = (lastState, { requestType, error, ...rest }) => {
	switch(requestType){
		case SUBMITING_COLLECTION_CATEGORIES: return {
			...lastState,
			collectionCategories: {
				...lastState.collectionCategories,
				error: { ...error, ...rest }
			}
		};
		case SUBMITING_COLLECTION_ITEMS: return {
			...lastState,
			collectionItems: {
				...lastState.collectionItems,
				error: { ...error, ...rest }
			}
		}
		case CREATE_COLLECTION_IMAGE:
    case UPDATE_COLLECTION_IMAGE:
    case DELETE_COLLECTION_IMAGE: return {
			...lastState, details: { ...lastState.details, error: { ...error, ...rest } }
		}

		case CREATE_COLLECTION_SELLER:
		case DELETE_COLLECTION_SELLER:
			return { ...lastState, collectionSeller: { ...lastState.collectionSeller, details: { ...lastState.collectionSeller.details, error: { ...error, ...rest } } } }

    case COLLECTIONS_S3: return { ...lastState, uploadedImage: { ...lastState.uploadedImage, isPending: false, error }}
		default: return lastState;
	}
}

const onCollectionCategorySucceeded = (lastState, { type, payload }) => {
	const { data, message, status_code } = payload
	switch(type){
		case SUBMITING_COLLECTION_CATEGORIES: return {
			...lastState,
			collectionCategories: {
				...lastState.collectionCategories,
				error: null,
				message,
				status_code
			}
		};
		case GET_LIST_COLLECTION_CATEGORIES: return {
			...lastState,
			detail:{
				...lastState.detail,
				categories: [...data.categories]
			},
			collectionCategories: {
				...lastState.collectionCategories,
				error: null,
				collectionId: data.id,
				data: [...data.categories],
				message,
				status_code
			}
		};
		default: return lastState;
	}
}

const onCollectionSellerSucceeded = (lastState, { type, payload }) => {
	const { data, message, status_code } = payload
	switch(type) {
		case GET_LIST_COLLECTION_SELLER:
			return {
				...lastState,
				collectionSeller: {
					...lastState.collectionSeller,
					error: null,
					data,
					message,
					status_code
				}
			}
		default: return lastState;
	}
}

const onCollectionItemsSucceeded = (lastState, { type, payload }) => {
	const { data, message, status_code } = payload
	switch(type){
		case SUBMITING_COLLECTION_ITEMS: return {
			...lastState,
			collectionItems: {
				...lastState.collectionItems,
				error: null,
				message,
				status_code
			}
		};
		case SEARCH_COLLECTION_ITEMS: return {
			...lastState,
			collectionItems: {
				...lastState.collectionItems,
				error: null,
				dataSearch: data,
				message,
				status_code
			}
		}
		case GET_LIST_COLLECTION_ITEMS: return {
			...lastState,
			collectionItems: {
				...lastState.collectionItems,
				error: null,
				data,
				message,
				status_code
			}
		};
		default: return lastState;
	}
}

/**
 * Offer Reducer
 * @author pewe
 * @param {object} state 
 * @param {object} action 
 */
const collectionReducer= (state=initialState, action) => {
	switch(action.type)
	{
		case GET_LIST_COLLECTION: return onGetCollectionList(state,action.payload);
		case GET_DETAIL_COLLECTION: return onGetDetailCollection(state,action.payload);
		case FETCHING_COLLECTION: return onFetchingCollection(state,action.payload);
		case COLLECTION_ERROR:return onCollectionError(state,action.payload);
		case UPDATE_COLLECTION:return onUpdateCollection(state,action.payload);
		case ADD_COLLECTION:return onAddCollection(state,action.payload);
		case DELETE_COLLECTION:return onDeleteCollection(state,action.payload);
		case COLLECTION_CHILDS_REQUEST: return onValidateChildRequest(state, action.payload);
		case COLLECTION_CHILDS_ERROR: return onErrorChildRequest(state, action.payload);
		case SUBMITING_COLLECTION_CATEGORIES:
		case GET_LIST_COLLECTION_CATEGORIES: return onCollectionCategorySucceeded(state, action);
		case SUBMITING_COLLECTION_ITEMS:
		case SEARCH_COLLECTION_ITEMS:
		case GET_LIST_COLLECTION_ITEMS: return onCollectionItemsSucceeded(state, action);
		case GET_LIST_COLLECTION_SELLER: return onCollectionSellerSucceeded(state, action);

		case CREATE_COLLECTION_SELLER:
		case DELETE_COLLECTION_SELLER:
			const { status_code, message } = action.payload;
      return {
        ...state,
        collectionSeller: {
          ...state.collectionSeller,
          details: {
						...state.collectionSeller.details,
						status_code,
						message,
						error: null
					}
        }
      }

		case CREATE_COLLECTION_IMAGE:
    case UPDATE_COLLECTION_IMAGE:
    case DELETE_COLLECTION_IMAGE: {
      const { status_code, message } = action.payload;
      return {
        ...state,
        details: {
          ...state.details,
          status_code,
          message,
          error: null
        }
      }
		}

		case COLLECTIONS_S3: {
      return {
        ...state,
        uploadedImage: {
          ...state.uploadedImage,
          error: null,
          ...action.payload,
        }
      }
    }

		case NEW_COLLECTION:return initialState;
		default:return state;
	}
}

export default collectionReducer;