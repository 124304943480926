import React from 'react';
import propTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import styled from 'styled-components';
import { SimpleLineIcon } from '../../Icons';

const styles={
  dropzoneContent:{
    width: '100%',
    height: 'auto',
    // borderWidth: 2,
    borderColor: 'rgb(102, 102, 102)',
    borderRadius:0,
    borderStyle: 'dashed',
    cursor: 'pointer'
  },
  p:{
    padding:15
  },
  h1:{
    fontSize:70
  }
}

export const DropzoneButton = props =>(
  <Dropzone
    accept={props.accept}
    onDrop={props.onDrop}
    disabled={props.disabled}
    style={{...styles.dropzoneContent}}
    multiple={props.multiple}
    inputProps={props.inputProps}
    title="Browse files..."
  >
    {props.children}
  </Dropzone>
)

DropzoneButton.propTypes = {
  onDrop: propTypes.func,
  accept: propTypes.string,
  disabled: propTypes.bool,
  multiple: propTypes.bool,
  inputProps: propTypes.object
}

DropzoneButton.defaultProps = {
  style: {},
  accept: [".jpg", ".jpeg", ".png"],
  disabled: false,
  multiple: true
}

const DropzoneWrapper = styled.div`
  width: 60px;
`;

const ContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  > h2{
    margin: 0;
    line-height: 0px;
    padding: 10px 0px;
  }
`;

export const DropzonePlusButton = props => (
  <DropzoneWrapper>
    <DropzoneButton
      accept={props.accept}
      onDrop={props.onDrop}
      disabled={props.disabled}
    >
      <ContentWrapper>
        <h2>
          <SimpleLineIcon iconType="plus"/>
        </h2>
      </ContentWrapper>
    </DropzoneButton>
  </DropzoneWrapper>

)