import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Alert,
  Form,
  Label,
  Row,
  Col,
  Input,
  FormGroup,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import classNames from "classnames";
import { hasError, textValue } from "../../../../utils/form";

import {
  createOption,
  deleteOption,
  getDetailOption,
  updateOption,
} from "../../../../actions/optionAction";

import { ValidationMessage } from "../../../../components/Form/ValidationMessage";
import SelectOptionable from "../../../../components/Form/Select/Async/SelectOptionable";

const initialState = {
  _edit: false,
  _hasId: false,
  _form: {
    binding: false,
    id: "",
    value: "",
    label_group: "",
    optionable_id: null,
    optionable_type: null,
    optionable: null,
    created_at: "",
    updated_at: "",
  },
  _formSubmit: false,
  _modalDelete: false,
};

const typeOptions = [
  {
    value: "",
    label: "Select optionable type...",
  },
  {
    value: "category",
    label: "Category",
  },
  {
    value: "brand",
    label: "Brand",
  },
  {
    value: "size",
    label: "Size",
  },
];

class ModalOptionForm extends Component {
  constructor(props) {
    super(props);
    console.log(this.props.edit);
    this.state = { ...initialState };

    this.onCloseModal = this.onCloseModal.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this._handleToggleModal = this._handleToggleModal.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { detail, isSubmit, error } = this.props.option;

    if (this.props.optionId && this.props.edit && !this.state._hasId) {
      this.setState(
        {
          _edit: true,
          _hasId: true,
        },
        () => {
          this.props.find(this.props.optionId);
        }
      );
    }

    // Edit Form
    if (this.state._hasId) {
      // Checking if already ok after get detail from api
      if (
        prevProps.option.detail.isFetch !== detail.isFetch &&
        !detail.isFetch &&
        detail.status_code === 200 &&
        error === null
      ) {
        // Binding data when load page and after get detail from api
        if (!detail.isFetch && !this.state._form.binding) {
          const _form = { ...this.state._form };
          _form.binding = true;
          this.setState({ _form }, () => {
            let _newForm = { ...this.state._form, ...detail };
            if (detail.optionable) {
              let type = detail.optionable_type.split("\\");
              if (type) {
                type = type[type.length - 1];
                _newForm = {
                  ..._newForm,
                  optionable_type: type ? type.toString().toLowerCase() : null,
                }
              }
              _newForm = {
                ..._newForm,
                optionable: {
                  id: detail.optionable.id,
                  name: detail.optionable.name ? detail.optionable.name : detail.optionable.US,
                },
              };
            }
            this.setState({ _form: _newForm });
          });
        }
        // Binding data again when after success update in api
        else if (!isSubmit && this.state._formSubmit) {
          if (this.state._modalDelete) {
            this.toggle();
          }
          const _form = { ...this.state._form };
          _form.binding = true;
          const _newForm = { ...this.state._form, ...detail };
          this.setState(
            { _formSubmit: !this.state._formSubmit, _form: _newForm },
            () => {
              this._handleToggleModal(true);
            }
          );
        }
      }
    }
    // Create Form
    else {
      // Checking if already ok after submit
      if (
        detail.status_code === 200 &&
        error === null &&
        this.state._formSubmit &&
        !isSubmit
      ) {
        this.setState({
          _form: { name: "", created_at: "", updated_at: "" },
          _formSubmit: !this.state._formSubmit,
        });

        this._handleToggleModal(true);
      }
    }
  }

  /**
   * Handle to change local state from input uset like textfield
   * @author haikal
   * @param {event} e
   */
  handleChange(e) {
    const newState = { ...this.state._form };
    newState[e.target.name] = e.target.value;
    if (e.target.name === 'optionable_type') {
      newState['optionable'] = null
    }
    this.setState({ _form: newState });
  }
  onSubmit(e) {
    e.preventDefault();
    this.setState({ _formSubmit: true }, () => {
      const { id, value, label_group, optionable_id, optionable_type } =
        this.state._form;
      if (this.props.edit && this.state._hasId) {
        this.props.update(id, {
          value,
          label_group,
          optionable_id,
          optionable_type,
        });
      } else {
        this.props.create({
          value,
          label_group,
          optionable_id,
          optionable_type,
        });
      }
    });
  }
  renderLoading() {
    return (
      <div className="row">
        <div className="col-sm-12">
          <Alert color="info" className="text-center">
            Submitting...
          </Alert>
        </div>
      </div>
    );
  }
  renderInfo() {
    let infoMessage = [];
    if (this.props.option.isSubmit)
      infoMessage.push(
        <Alert color="info" key="info">
          <strong>Heads up!</strong> Submitting...
        </Alert>
      );
    if (this.props.option.success)
      infoMessage.push(
        <Alert color="success" key="success">
          <strong>Well done!</strong> {this.props.option.success}
        </Alert>
      );
    if (this.props.option.error !== null) {
      const { status_code } = this.props.option.error;
      switch (status_code) {
        case 422:
          infoMessage.push(
            <Alert color="danger" key="error">
              <strong>Oh Snap!</strong> Please fullfill your form.{" "}
            </Alert>
          );
          break;
        case 404:
          infoMessage.push(
            <Alert color="danger" key="error">
              <strong>Oh Snap!</strong> Resource not found.{" "}
            </Alert>
          );
          break;
        default:
          infoMessage.push(
            <Alert color="danger" key="error">
              <strong>Oh Snap!</strong> We've got something errors{" "}
            </Alert>
          );
          break;
      }
    }
    return (
      <div className="row">
        <div className="col-sm-12">{infoMessage}</div>
      </div>
    );
  }

  validateError(name) {
    if (this.hasError(name)) {
      let errorList = this.props.option.error.errors;
      return <ValidationMessage message={errorList[name][0]} />;
    }
    return "";
  }

  hasError(name) {
    if (this.props.option.error) {
      if (this.props.option.error.errors) {
        let errorList = this.props.option.error.errors;
        let errList = Object.keys(errorList).filter((key) => key == name);
        if (errList.length) return true;
      }
    }
    return false;
  }

  toggle() {
    this.setState({ _modalDelete: !this.state._modalDelete });
  }

  onCloseModal() {
    this.setState({ ...initialState });
  }

  onDelete() {
    this.setState({ _formSubmit: true, _modalDelete: false }, function () {
      this.props.delete(this.state._form.id);
    });
  }

  _handleToggleModal(update = false) {
    this.props.toggle(update);
  }

  onSelectChange(val, attributeForm) {
    const { _form } = this.state;
    if (val && val.id) {
      _form[attributeForm] = val;
      _form[`${attributeForm}_id`] = val.id;
      this.setState({ _form });
    }
  }

  render() {
    return (
      <Modal
        isOpen={this.props.isOpen}
        toggle={() => this._handleToggleModal(false)}
        onClosed={this.onCloseModal}
      >
        <ModalHeader toggle={() => this._handleToggleModal(false)}>
          {this.props.edit ? "Edit option" : "Create option"}
        </ModalHeader>
        <Modal
          isOpen={this.state._modalDelete}
          toggle={this.toggle.bind(this)}
          className="modal-dialog modal-sm"
        >
          <ModalHeader toggle={this.toggle.bind(this)}>
            Confirmation
          </ModalHeader>
          <ModalBody>
            {this.state._modalDelete && this.props.option.isSubmit ? (
              <div className="row">
                <div className="col-sm-12">
                  <Alert color="info" className="text-center">
                    Deleting...
                  </Alert>
                </div>
              </div>
            ) : (
              <span>Are you sure to delete this?</span>
            )}
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.onDelete.bind(this)}>
              Yes
            </Button>{" "}
            <Button color="secondary" onClick={this.toggle.bind(this)}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
        <Form onSubmit={this.onSubmit.bind(this)}>
          <ModalBody>
            {this.renderInfo()}
            {this.props.option.detail.isFetch ? (
              this.renderLoading()
            ) : (
              <Row>
                <Col xs={12}>
                  <FormGroup
                    className={
                      this.hasError("label_group")
                        ? " has-danger has-feedback"
                        : ""
                    }
                  >
                    <Label for="formLabelGroup">Label Group</Label>
                    <Input
                      type="text"
                      id="formLabelGroup"
                      name="label_group"
                      placeholder="Enter a label group*"
                      value={this.state._form.label_group}
                      onChange={this.handleChange.bind(this)}
                    />
                    {this.validateError("label_group")}
                  </FormGroup>
                  <FormGroup row>
                    <Col>
                      <FormGroup>
                        <Label>Optionable type</Label>
                        <Input
                          type="select"
                          name="optionable_type"
                          onChange={this.handleChange.bind(this)}
                          value={this.state._form.optionable_type ? this.state._form.optionable_type : ""}
                        >
                          {typeOptions.map((option, i) => (
                            <option value={option.value} key={i}>
                              {option.label}
                            </option>
                          ))}
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label>Optionable data</Label>
                        <SelectOptionable
                          id="formOptionable"
                          name="optionable_id"
                          type={this.state._form.optionable_type}
                          defaultOptions
                          ref="selectOptionable"
                          paramsApi={{
                            sort_by: "createdAt_desc"
                          }}
                          placeholder="Select optionable..."
                          noResultsText="Cannot find optinable data."
                          value={this.state._form.optionable || null}
                          onSelectChange={(val) => {
                            this.onSelectChange(val, "optionable");
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </FormGroup>
                  {!this.state._form.optionable_type && (
                    <FormGroup
                      className={
                        this.hasError("value") ? " has-danger has-feedback" : ""
                      }
                    >
                      <Label for="formValue">Value</Label>
                      <Input
                        type="text"
                        id="formValue"
                        name="value"
                        placeholder="Enter a default value*"
                        value={this.state._form.value}
                        onChange={this.handleChange.bind(this)}
                      />
                      {this.validateError("value")}
                    </FormGroup>
                  )}
                  <FormGroup>
                    <Label>Created At</Label>
                    <Input
                      type="text"
                      readOnly
                      value={this.state._form.created_at}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>Updated At</Label>
                    <Input
                      type="text"
                      readOnly
                      value={this.state._form.updated_at}
                    />
                  </FormGroup>
                </Col>
              </Row>
            )}
          </ModalBody>
          <ModalFooter>
            {this.props.edit && this.state._form.id && (
              <Button
                color="danger"
                onClick={this.toggle.bind(this)}
                disabled={this.props.option.detail.isFetch}
              >
                Delete
              </Button>
            )}
            <Button
              type="submit"
              color="primary"
              disabled={this.props.option.detail.isFetch}
              onClick={this.onSubmit}
            >
              {this.state.isSubmit ? "Submitting..." : "Submit"}
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    option: state.option,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    find: (id) => dispatch(getDetailOption(id)),
    delete: (id) => dispatch(deleteOption(id)),
    update: (id, payload) => dispatch(updateOption(id, payload)),
    create: (payload) => dispatch(createOption(payload)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ModalOptionForm);
