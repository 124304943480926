import React, {useMemo, useState} from 'react';
import {AUTH_TOKEN_KEY} from "../../../constants";
import api from "../../../utils/Api";
import debounce from "debounce-promise";
import Select from "react-select";

export default function (props) {
    const {
        value,
        onChange,
        creatable = false,
        additionalQuery,
        ...rest
    } = props;
    const myToken = useMemo(() => localStorage.getItem(AUTH_TOKEN_KEY), []);
    const headers = useMemo(() => ({
        'Authorization': `Bearer ${myToken}`
    }), [myToken]);

    const [isLoadingUser, setIsLoadingUser] = useState(false);

    const promptTextCreator = (label) => {
        if (isLoadingUser) return 'Loading...';
        return 'Cannot find email. Input "' + label + '".';
    };

    const _getUserOptions = (input) => {
        const params = {
            keyword: input,
            roles: 'user,seller,unsuspended_seller,administrator',
            role_query: 'or',
            scope: 'all',
            page: 1,
            per_page: 5,
            ...additionalQuery,
        };
        setIsLoadingUser(true);
        return api(headers).get('/admins/users', { params })
            .then(({ data }) => {
                setIsLoadingUser(false);
                return {
                    options: data.data.data.map(item => ({
                        id: item.id,
                        email: item.email,
                    }))
                };
            });
    };

    const getUserOptions = debounce(async (inputValue) => {
        return _getUserOptions(inputValue);
    }, 800);

    if (creatable) return <Select.AsyncCreatable
        valueKey="id"
        labelKey="email"
        loadOptions={getUserOptions}
        onChange={onChange}
        value={value}
        placeholder="Type and select a seller..."
        promptTextCreator={promptTextCreator}
        {...rest}
    />

    return (
        <Select.Async
            valueKey="id"
            labelKey="email"
            loadOptions={getUserOptions}
            onChange={onChange}
            value={value}
            placeholder="Type and select a seller..."
            noResultsText="Cannot find seller."
            {...rest}
        />
    );
}
