import React, {Component} from 'react';
import { Row, Col } from 'reactstrap';
import DetailCard from './sellerVerificationContainer/DetailCard';
import CardThumbnail from './sellerVerificationContainer/sellerVerificationImages/CardThumbnail';
import propTypes from 'prop-types';

export default class SellerVerificationContainer extends Component{
  render(){
    return(
      <Row>
        <Col xs={12} sm={6} md={6}>
          <DetailCard
            {...this.props.sellerVerification}
            dataSellerVerification={this.props.dataSellerVerification}
            user={this.props.user}
            idCard={this.props.idCard}
            address={this.props.address}
            mobileNumber={this.props.mobileNumber}
            sellerVerificationImages={this.props.sellerVerificationImages}
            status={this.props.status}
            type={this.props.type}
            notes={this.props.notes}
            onUpdateSellerVerification={this.props.handleUpdateSellerVerification}
            isLoading={this.props.isLoading}
            userSetting={this.props.userSetting}
          />
        </Col>
        <Col xs={12} sm={6} md={6}>
          <CardThumbnail
            images={this.props.sellerVerificationImages}
          />
        </Col>
      </Row>
    )
  }
}

SellerVerificationContainer.propTypes = {
  isLoading: propTypes.bool,
  user: propTypes.object,
  userSetting: propTypes.object,
  dataSellerVerification: propTypes.shape({
    detail: propTypes.object,
    isSubmit: propTypes.bool,
    isFetch: propTypes.bool,
    error: propTypes.object
  }),
  sellerVerification: propTypes.object,
  images: propTypes.arrayOf(propTypes.object),
  handleUpdateSellerVerification: propTypes.func.isRequired,
}

SellerVerificationContainer.defaultProps = {
  isLoading: false,
  images: []
}