import React from 'react';
import propTypes from 'prop-types';
import { TextRupiah } from '../../../../components/Text';
import DateToHuman from '../../../../components/DateToHuman';
import { Th } from '../../../../components/Tbl';
import DisbursementColumn from './DisbursementColumn';
import BadgeStatus from './Badges/BadgeStatus';
import { DISBURSEMENT_BALANCE_IN, DISBURSEMENT_BALANCE_OUT } from './helpers';

const RowItem = (props) => (
  <tr>
    <Th className="v-center" scope="row">{ props.number }</Th>
    <td className="v-center">
      <DisbursementColumn
        id={props.id}
        items={props.disbursementItems}
        disbursementNumber={props.disbursementNumber}
        disbursementType={props.disbursementType}
        createdAt={props.createdAt}
      />
    </td>
    <td className="v-center">
      <TextRupiah prefix="Rp. " value={props.totalAmount}/>
      <div className="text-uppercase">
        <BadgeStatus status={props.status}/>
      </div>
    </td>
    <td className="v-center">{ <DateToHuman id={`completedList_disbursements_updatedAt_${props.id}`} value={props.updatedAt}/> }</td>
  </tr>
);

export default RowItem;

RowItem.defaultProps = {
  disbursementItemsCount: 0,
  disbursementType: DISBURSEMENT_BALANCE_IN
}

RowItem.propTypes = {
  number: propTypes.number.isRequired,
  id: propTypes.number.isRequired,
  disbursementNumber: propTypes.string,
  disbursementType: propTypes.oneOf([DISBURSEMENT_BALANCE_IN, DISBURSEMENT_BALANCE_OUT]),
  createdAt: propTypes.string,
  updatedAt: propTypes.string,
  totalAmount: propTypes.oneOfType([propTypes.number, propTypes.string])
}
