import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {
  Row,Col, Alert, Card, CardHeader, CardBody
} from 'reactstrap';
import ProductVariantImage from './ProductVariantImage';
import CardHeaderText from '../../Card/CardHeaderText';
export default class ProductVariantImages extends PureComponent{
  render(){
    return(
      <Card>
        <CardHeader>
        <CardHeaderText iconClassName="fa fa-image" text={this.props.headerText}/>
        </CardHeader>
        <CardBody>
          <Row>
            {this.props.data.length?
              this.props.data.map((image,index)=>(
                <Col xs={12} sm={6} md={3} key={index}>
                  <ProductVariantImage {...image}/>
                </Col>
              ))
            :
            <Col>
              <Alert color="danger" className="text-center">
                <strong>Oh,</strong> no images found!
              </Alert>
            </Col>
            }
          </Row>
        </CardBody>
      </Card>
    )
  }
}
ProductVariantImages.propTypes={
  headerText: PropTypes.oneOfType([PropTypes.node,PropTypes.string]),
  data:PropTypes.array,
}
ProductVariantImages.defaultProps={
  headerText: "Sneaker Images",
  data:[]
}
