import { useMemo, useState } from "react";
import { AUTH_TOKEN_KEY } from "../../../constants";
import api from "../../../utils/Api";
import { uploadImageNoRedux } from "../../../actions/imageUploadAction";
import { createUploadFileForm } from "../../../utils/imageHelper";

const usePostSellRequest = () => {
    const [isPostingImage, setIsPostingImage] = useState(false);
    const [isPosting, setIsPosting] = useState(false);

    const myToken = useMemo(() => localStorage.getItem(AUTH_TOKEN_KEY), []);
    const headers = useMemo(
        () => ({
            Authorization: `Bearer ${myToken}`,
        }),
        [myToken]
    );

    const postImage = async (payload) => {
        return api(headers).post("/admins/sells/images", payload);
    };

    const post = async (payload, payloadImages = []) => {
        setIsPosting(true);
        let isSuccess = true;
        let message = "Success create data sell request";
        const detail = {
            userSell: {},
            uploadToS3: {},
            uploadToApi: {},
        };

        const userSell = await api(headers)
            .post("/admins/sells", payload)
            .then((res) => {
                return {
                    isSuccess: true,
                    isError: false,
                    message,
                    data: res.data.data,
                };
            })
            .catch((error) => {
                setIsPosting(false);
                isSuccess = false;
                message = "Error! Cannot create sell request.";
                const errorMessageFromApi =
                    error?.response?.data?.error?.message;
                    
                if (errorMessageFromApi) {
                    message += ` ${errorMessageFromApi}`;
                }
                return {
                    isSuccess: false,
                    isError: true,
                    message,
                    data: error?.response?.data,
                    error,
                };
            });

        Object.assign(detail, { userSell: userSell });

        if (userSell.isError) {
            return {
                isSuccess,
                message,
                detail,
            };
        }

        const user_sell_id = userSell.data.id;

        if (payloadImages.length) {
            const responseUploadToS3 = await Promise.all(
                payloadImages.map((payloadImage, index) => {
                    const imageParam = createUploadFileForm(
                        payloadImage.fileInput,
                        false,
                        {
                            name: `sell_request/${user_sell_id}/${index + 1}`,
                            bucket_type: "assets",
                        }
                    );

                    return uploadImageNoRedux(imageParam)
                        .then((res) => {
                            return {
                                isSuccess: true,
                                isError: false,
                                message: `Success upload index ${index} image to S3`,
                                data: res.data.data,
                            };
                        })
                        .catch((error) => {
                            setIsPosting(false);
                            return {
                                isSuccess: false,
                                isError: true,
                                message,
                                data: error?.response?.data,
                                error,
                            };
                        });
                })
            );

            Object.assign(detail, { uploadToS3: responseUploadToS3 });

            const responseUploadToApi = await Promise.all(
                responseUploadToS3.map((response, index) => {
                    const payloadImage = response.data;
                    return postImage({
                        user_sell_id,
                        URL: payloadImage.url,
                    })
                        .then((res) => {
                            return {
                                isSuccess: true,
                                isError: false,
                                message: `Success upload index ${index} image to API`,
                                data: res.data.data,
                            };
                        })
                        .catch((error) => {
                            setIsPosting(false);
                            return {
                                isSuccess: false,
                                isError: true,
                                message,
                                data: error?.response?.data,
                                error,
                            };
                        });
                })
            );

            Object.assign(detail, { uploadToApi: responseUploadToApi });
        }

        setIsPosting(false);

        return {
            isSuccess,
            message,
            detail,
        };
    };

    return {
        isPosting,
        post,
    };
};

export default usePostSellRequest;
