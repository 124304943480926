// RackAssignment.js
import React, { Component } from 'react';
import ReactDOMServer from 'react-dom/server';
import { connect } from 'react-redux';
import {
  Row,
  Col,
  Card,
  CardBody,
  CardText,
} from 'reactstrap';
import { Tbl, TblHead, TblBody } from '../../../components/Tbl';
import Paginations from '../../../components/Paginations';
import PaginationSummaries from './Paginations/PaginationSummaries';
import { CardHeaderWithToolbars, Title } from '../../../components/Card/CardHeaderWithToolbars';
import Toolbars from './Filters/Toolbars';
import FiltersPanel from '../Filters';
import FilterContainer from '../../../components/Container/FilterContainer/FilterContainer';
import { ModalInfoCreditCard } from '../../../components/Modals';
import { generateNumber } from '../../../utils';
import { DEFAULT_SORT_BY, DEFAULT_PER_PAGE } from '../../../constants/settings';
import {
  NEW_STATUS,
  SELLER_SENT_STATUS,
  CANCELLED_STATUS,
  KA_RECEIVED_STATUS,
  VERIFICATION_PASSED_STATUS,
  VERIFICATION_FAILED_STATUS,
  DELIVERING_STATUS,
  REFUNDED_STATUS
} from '../../../constants/kickavenue';
import { getSaleList, reportSale } from '../../../actions/saleAction';
import { getConsignmentList } from '../../../actions/consignmentAction';
import RowItem from './rowItem';
import RowHeader from './rowItem/RowHeader';
import privateView from '../../../components/hocs/privateView';
import qs from 'query-string';
import { getOptionList as getCategoryOptionList } from '../../../actions/categoriesAction';
import ModalPurchasePrice from '../ModalPurchasePrice';
import Analytics from "../../../services/Analytics";
import Invoice from './Invoice/Invoice';
import api from '../../../utils/Api';
import { AUTH_TOKEN_KEY } from '../../../constants';

const generateDescStatus = item => {
  if (item.status === NEW_STATUS) {
    if (item.seller_responded)
      return 'Waiting Seller Delivering';
    return 'Waiting Seller Confirmation';
  }
  // else if(item.status===SELLER_SENT_STATUS && item.seller_sent!=null){
  else if (item.status === SELLER_SENT_STATUS) {
    return 'Sneaker On The Way';
  }
  else if (item.status === KA_RECEIVED_STATUS && item.ka_received != null) {
    return 'Verification Progress';
  }
  else if (item.status === VERIFICATION_PASSED_STATUS && item.ka_verified != null) {
    return 'Verification Passed';
  }
  else if (item.status === VERIFICATION_FAILED_STATUS) {
    return 'Verification Failed';
  }
  else if (item.status === DELIVERING_STATUS && item.ka_sent === null) {
    return 'Awaiting AWB Number';
  }
  else if (item.status === DELIVERING_STATUS && item.ka_sent != null) {
    return 'On Delivering';
  }
  else if (item.status === CANCELLED_STATUS) {
    return item.force_cancelled_at != null ? 'Cancelled (Forced)' : 'Cancelled';
  }
  else if (item.status === REFUNDED_STATUS && item.money_disbursed != null) {
    return 'Refunded';
  }
  else if ((/^DONE_\w+/).test(item.status) || item.completed)
    return 'Done';
  return 'Undefined';
}

const defaultParams = {
  type: "rack_assignment",
  sort_by: DEFAULT_SORT_BY,
  per_page: DEFAULT_PER_PAGE,
}

class RackAssignmentConsignment extends Component {
  constructor(props) {
    super(props);
    const params = qs.parse(props.location.search);
    this.state = {
      _filterOpen: false,
      _exportOpen: false,
      _printOpen: false,
      _allSelected: false,
      _modalCreditCard: false,
      _invoiceNumber: null,
      _dataModalPurchasePrice: null,
      _isModalPurchasePrice: false,
      _selectedItems: [],
      _params: {
        ...defaultParams,
        keyword: params.keyword || ''
      },
      rackOptions: [],
      isDataLoaded: false
    }
    this._handleApplyFilter = this._handleApplyFilter.bind(this);
    this._handleToggleFilterPanel = this._handleToggleFilterPanel.bind(this);
    this._handleToggleExportPanel = this._handleToggleExportPanel.bind(this);
    this._handleTogglePrintPanel = this._handleTogglePrintPanel.bind(this);
    this._handleGoto = this._handleGoto.bind(this);
    this._refreshList = this._refreshList.bind(this);
    this._handleRefresh = this._handleRefresh.bind(this);
    this._handleResetFilter = this._handleResetFilter.bind(this);
    this._handleExport = this._handleExport.bind(this);
    this._handlePrintInvoice = this._handlePrintInvoice.bind(this);
    this._handleCheckboxChange = this._handleCheckboxChange.bind(this);
    this._handleCategoryChanged = this._handleCategoryChanged.bind(this);
    this._handleToggleModalCreditCard = this._handleToggleModalCreditCard.bind(this);
    this._handlePrintSelectedItem = this._handlePrintSelectedItem.bind(this);
    this._isMounted = false;
  }

  componentDidMount() {
    const { email } = this.props.injectedProps;
    this._getList(this.state._params).then(() => {
      this.setState({
        isDataLoaded: true
      })
    });
    this._isMounted = true;
    this._loadRackOption();
    Analytics.recordPageView('rack_assignment_consignment', email, {
      url: window.location.href,
      location: this.props.location,
      match: this.props.match
    });
  }

  _getList(rawParams = {}) {
    let p = { ...rawParams }
    if (p.voucher) {
      const { voucher, ...other } = p;
      if (Number(voucher.id)) {
        p = { ...other, voucher_id: voucher.id }
      }
      else {
        p = { ...other, voucher_code: voucher.code }
      }
    }
    if (p.seller && p.seller.id) {
      const { seller, ...other } = p;
      p = { ...other, seller_id: seller.id }
    }
    const params = Object.keys(p)
      .filter(key => p[key] != '')
      .reduce((obj, current) => ({ ...obj, [current]: p[current] }), {});
    return this.props.getList({ params });
  }

  _toggleFilterPanel() {
    const exportState = this.state._exportOpen ? { _exportOpen: !this.state._exportOpen } : {};
    this.setState({
      ...exportState,
      _filterOpen: !this.state._filterOpen
    });
  }

  _toggleExportPanel() {
    const filterState = this.state._filterOpen ? { _filterOpen: !this.state._filterOpen } : {};
    this.setState({
      ...filterState,
      _exportOpen: !this.state._exportOpen
    });
  }

  _handleRefresh(e) {
    e.preventDefault();
    this._refreshList();
  }

  _handleExport(payload) {
    this.props.reportSale(payload);
  }

  _handlePrintInvoice() {
    const { _selectedItems } = this.state;
    const newTab = window.open('', '_blank');
    const invoiceContent = ReactDOMServer.renderToString(
      <Invoice selectedItems={_selectedItems} />
    );
    newTab.document.write(`
      <!DOCTYPE html>
      <html lang="en">
      <head>
        <meta charset="UTF-8">
        <title>Print Invoice</title>
      </head>
      <body>
        ${invoiceContent}
        <script>
          window.onload = function() {
            window.print();
          };
        </script>
      </body>
      </html>
    `);
    newTab.document.close();
  }

  _handleCheckboxChange(event) {
    const { name, checked } = event.target;
    const { _selectedItems } = this.state;
    const selectedArray = this.props.consignment.list.data ? this.props.consignment.list.data : [];
    const selectedItems = [..._selectedItems, ...selectedArray];
    const uniqueItems = selectedItems.reduce((unique, item) => {
      const exists = unique.find(existingItem => existingItem.id === item.id);
      if (!exists) {
        unique.push(item);
      }

      return unique;
    }, []);
    let updatedSelectedItems = uniqueItems;
    if (!checked)
      updatedSelectedItems = [];
    this.setState({
      [name]: checked,
      _selectedItems: updatedSelectedItems
    })
  }

  _refreshList() {
    // Exclude page...
    const { page, ..._params } = this.state._params;
    this.setState({
      _params: { ..._params },
      _selectedItems: [],
      _allSelected: false,
    }, () => this._getList(this.state._params));
  }

  _handleResetFilter(e) {
    e.preventDefault();
    const newState = this.state._filterOpen ? { _filterOpen: !this.state._filterOpen } : {};
    const exportState = this.state._exportOpen ? { _exportOpen: !this.state._exportOpen } : {};
    this.setState({
      ...newState,
      ...exportState,
      _selectedItems: [],
      _allSelected: false,
      _params: { ...defaultParams },
      _isResetFilter: true
    }, () => this._getList(this.state._params));
  }

  _handleToggleFilterPanel(e) {
    e.preventDefault();
    this._toggleFilterPanel();
  }

  _handleToggleExportPanel(e) {
    e.preventDefault();
    this._toggleExportPanel();
  }

  _handleTogglePrintPanel(e) {
    const filterState = this.state._filterOpen ? { _filterOpen: !this.state._filterOpen } : {};
    this.setState({
      ...filterState,
      _printOpen: !this.state._printOpen
    });
  }

  _handleApplyFilter(payloadFilter) {
    this.setState({
      _params: {
        ...this.state._params,
        ...payloadFilter,
        page: 1
      }
    }, () => this._getList(this.state._params));
  }

  _handleGoto(page) {
    const _params = this.state._params;
    _params.page = page;
    this.setState({ _params }, () => this._getList(this.state._params));
  }

  _onOpenCreditCardModal(item) {
    this.setState({ _invoiceNumber: item.invoice_number }, () => this._handleToggleModalCreditCard());
  }

  _handleToggleModalCreditCard() {
    this.setState({ _modalCreditCard: !this.state._modalCreditCard });
  }

  _handlePrintSelectedItem(payload) {
    const { _selectedItems } = this.state;
    const selectedArray = [payload];
    const selectedItems = [..._selectedItems, ...selectedArray];
    const uniqueItems = selectedItems.reduce((unique, item) => {
      const exists = unique.find(existingItem => existingItem.id === item.id);
      if (!exists) {
        unique.push(item);
      }

      return unique;
    }, []);
    let updatedSelectedItems = uniqueItems;
    if (!payload.selected)
      updatedSelectedItems = uniqueItems.filter(item => item.id !== payload.id);
    this.setState({
      _selectedItems: updatedSelectedItems
    })
  }

  _togglePurchasePriceModal(value, payloads = null) {
    this.setState({
      _isModalPurchasePrice: value,
      _dataModalPurchasePrice: payloads ? { ...payloads } : null
    })
  }

  _loadRackOption() {
    const myToken = localStorage.getItem(AUTH_TOKEN_KEY);
    const headers = {
      'Authorization': `Bearer ${myToken}`,
    };
    const params = {
      keyword: '',
      no_limit: true,
      availables: true
    };

    api(headers).get('/admins/sell_racks', { params })
      .then(({ data }) => {
        if (this._isMounted) {  
          let filteredData = data.data;
          const racks = filteredData.map(item => ({
            id: item.id,
            name: item.name,
            max: item.max_capacity,
            count: item.user_sells_count,
          }));

          this.setState({ rackOptions: racks });
        }
      });
  }

  _onRenderRowItem(){
    if(this.props.consignment.list.status_code===200&&this.props.consignment.list.data.length) {
        const { current_page,per_page } =  this.props.consignment.list;
        return this.props.consignment.list.data.map((item,index)=>{
          const rowId = generateNumber(per_page,current_page,index);
          return (
            <RowItem
              key={rowId}
              rowId={rowId}
              data={item}
              id={item.id}
              quantity={item.user_sell.quantity}
              buyerEmail={item.buyer_email}
              sellerEmail={item.seller_email}
              consignmentId={item.user_sell.consignment_id}
              orderDate={item.created_at}
              updatedAt={item.updated_at}
              approvedAt={item?.user_sell?.consignment_approved_at}
              rack={item.user_sell.rack}
              size={item.user_sell.size.US}
              displayName={item.user_sell.product_variant.display_name}
              SKU={item.user_sell.product_variant.SKU}
              colour={item.user_sell.product_variant.colour}
              stockStatus={item.user_sell.stock_status}
              category={item.product_variant.product.category.name}
              status={item.status}
              totalAmount={item.user_sell.asking_price}
              sneakersCondition={item.user_sell.sneakers_condition}
              preVerified={item.user_sell.pre_verified}
              preOrder={item.user_sell.pre_order}
              autoCancel={item.auto_cancelled_at}
              invoiceNumber={item.invoice_number}
              orderStatus={generateDescStatus(item)}
              autoDisburse={item.autodisburse}
              marked={item.marked}
              notes={item.sale_notes}
              loginId={this.props.injectedProps.id}
              legitCheckLogs={item.legit_check_logs}
              markedAt={item.open_marked_at}
              markedBy={item.open_marked_by}
              closeMarkedAt={item.close_marked_at}
              unreadNoteCount={item.unread_notes_count}
              onOpenCreditCard={()=>this._onOpenCreditCardModal(item)}
              purchasePrice={item.purchase_price}
              followUpStatus={item.follow_up_status}
              isOpenPrint={this.state._printOpen}
              allSelected={this.state._allSelected}
              selectedItems={this.state._selectedItems}
              voucherCode={item.voucher_code? item.voucher_code: item.voucherable? item.voucherable.code: item.voucherable_id}
              openPurchasePriceModal={(payloads) => this._togglePurchasePriceModal(true, payloads)}
              printSelectedItems={(selected) => this._handlePrintSelectedItem(selected)}
              onRefreshList={this._refreshList}
              rackOptions={this.state.rackOptions}
            />
          )}
        )
    }
    return null;
  }

  _onRenderPagination() {
    const { status_code, data } = this.props.consignment.list;
    const hasData = (status_code === 200 && data.length) || null;
    return hasData && (
      <Paginations
        size="sm"
        lastPage={this.props.consignment.list.last_page}
        perPage={this.props.consignment.list.per_page}
        total={this.props.consignment.list.total}
        maxPage={8}
        path={this.props.consignment.list.path}
        currentPage={this.props.consignment.list.current_page}
        prevPageUrl={this.props.consignment.list.prev_page_url}
        nextPageUrl={this.props.consignment.list.next_page_url}
        handleGotoCallback={this._handleGoto}
      />
    );
  }

  async _handleCategoryChanged(category) {
    await this.setState({
      _params: {
        ...this.state._params,
        category: category === '' ? category : category.value,
        page: 1,
      },
    });
    const { _params } = this.state;
    this._getList(_params);
  }

  onMarkedClick = async (value) => {
    await this.setState({
      _params: {
        ...this.state._params,
        marked: value ? 1 : 0,
        page: 1,
      },
    });
    const { _params } = this.state;
    this._getList(_params);
  }

  render() {
    return (
      <div className="animated fadeIn">
        <Row>
          <ModalPurchasePrice
            data={this.state._dataModalPurchasePrice}
            isOpen={this.state._isModalPurchasePrice}
            toggle={() => this._togglePurchasePriceModal(false)}
          />
          <ModalInfoCreditCard
            isOpen={this.state._modalCreditCard}
            toggle={this._handleToggleModalCreditCard}
            invoiceNumber={this.state._invoiceNumber}
          />
          <Col xs={12} md={12}>
            <Card>
              <CardHeaderWithToolbars>
                <Title>
                  <i className="fa fa-align-justify"></i>
                  <span>All Rack Assignment List</span>
                </Title>
                {this.state.isDataLoaded && <Toolbars
                  isOpen={this.state._filterOpen}
                  isOpenExport={this.state._exportOpen}
                  isOpenPrint={this.state._printOpen}
                  onToggleFilter={this._handleToggleFilterPanel}
                  onToggleExport={this._handleToggleExportPanel}
                  onTogglePrint={this._handleTogglePrintPanel}
                  onRefreshList={this._handleRefresh}
                  onResetFilter={this._handleResetFilter}
                />}
              </CardHeaderWithToolbars>
              <FilterContainer>
                <FiltersPanel
                  isStockFilterForm
                  {...this.state._params}
                  onApplyFilter={this._handleApplyFilter}
                  isReset={this.state._isResetFilter}
                  onResetFilterCallback={() => this.setState({ _isResetFilter: false, _filterOpen: false })}
                  isOpen={this.state._filterOpen}
                />
              </FilterContainer>
              <CardBody>
                <CardText>
                  In this section we have to assign rack number for the product
                </CardText>
                <div className="table-responsive">
                  <PaginationSummaries
                    isOpenPrint={this.state._printOpen}
                    allSelected={this.props._allSelected}
                    isSelected={this.state._selectedItems.length}
                    printInvoice={this._handlePrintInvoice}
                    handleCheckboxChange={this._handleCheckboxChange}
                    from={this.props.consignment.list.from}
                    to={this.props.consignment.list.to}
                    total={this.props.consignment.list.total}
                  />
                  <Tbl>
                    <TblHead>
                      <RowHeader
                        marked={this.state._params.marked}
                        onMarkedClick={this.onMarkedClick}
                        categoryName={this.state._params.category}
                        categoryValue={this.state._params.category}
                        onCategoryChanged={this._handleCategoryChanged}
                      />
                    </TblHead>
                    <TblBody
                      isLoad={this.props.consignment.isFetch}
                      hasRow={this.props.consignment.list.status_code === 200 && this.props.consignment.list.data.length > 0}
                      columnCount={8}
                    >
                      {this._onRenderRowItem()}
                    </TblBody>
                  </Tbl>
                </div>
                {this._onRenderPagination()}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    )
  }
}
const mapStateToProps = ({ consignment, sale, masterCategories, auth: { id: authId, email, isFetch, isLogged, roles } }) => {
  return {
    consignment,
    sale,
    guardData: { isFetch, isLogged, roles, id: authId, email },
    categoryOptions: masterCategories
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    getList: (query) => dispatch(getConsignmentList(query)),
    reportSale: (payload) => dispatch(reportSale(payload)),
    bindCategoryOption: () => dispatch(getCategoryOptionList())
  }
};

const enhance = connect(mapStateToProps, mapDispatchToProps);
export default enhance(privateView(RackAssignmentConsignment, ['supervisor', 'sale_supervisor']));