import React from 'react';
import propTypes from 'prop-types';
import PaginationSummaries from '../../../components/Paginations/PaginationSummaries';
import Paginations from '../../../components/Paginations';
import { Tbl, TblHead, TblBody } from '../../../components/Tbl';
import { generateNumber } from '../../../utils';
import RowItem from './RowItem';

const dataHeader=[
  'No.','Item','Gross Amount','Payment Type', 'Updated', 'Action'
];

const List = props =>(
  <div>
    <div className="table-responsive">
      <PaginationSummaries
        from={props.data.from}
        to={props.data.to}
        total={props.data.total}
      />
      <Tbl>
        <TblHead dataHeader={dataHeader}/>
        <TblBody
          isLoad={props.isLoading}
          hasRow={props.data.status_code===200&&props.data.data.length>0}
          columnCount={dataHeader.length}
          renderChildren={()=>{
            const { current_page, per_page } =  props.data;
            return props.data.data.map((item, index) => {
              const rowNumber = generateNumber(per_page,current_page,index);
              return (
                <RowItem
                  key={item.id}
                  id={item.id}
                  rowNumber={rowNumber}
                  externalId={item.external_id}
                  transactionId={item.transaction_id}
                  paymentType={item.payment_type}
                  grossAmount={item.gross_amount}
                  status={item.status}
                  createdAt={item.created_at}
                  updatedAt={item.updated_at}
                  expiredAt={item.expired_at}
                  vaType={item.va_type}
                  isExpired={item.is_expired}
                  defaultRoute={props.defaultRoute}
                />
              )
            })
          }}
        />
      </Tbl>
    </div>
    <Paginations
      size="sm"
      maxPage={8}
      total={props.data.total}
      currentPage={props.currentPage}
      lastPage={props.data.last_page}
      perPage={props.data.per_page}
      handleGotoCallback={props.onGotoPageList}
    />
  </div>
)

export default List;