import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Row, Col, Card, CardBody, CardText, Badge } from "reactstrap";
import moment from "moment-timezone";
import privateView from "../../components/hocs/privateView";
import PaginationSummaries from "../../components/Paginations/PaginationSummaries";
import DateToHuman from "../../components/DateToHuman";
import { Tbl, TblHead, TblBody } from "../../components/Tbl";
import Paginations from "../../components/Paginations";
import { generateNumber } from "../../utils";
import FilterPanel from './Filters';
import FilterContainer from '../../components/Container/FilterContainer/FilterContainer';
import { CardHeaderWithToolbars,Title } from '../../components/Card/CardHeaderWithToolbars';
import Toolbars from './Filters/Toolbars'
import Analytics from "../../services/Analytics";
import { DEFAULT_SORT_BY, DEFAULT_PER_PAGE } from "../../constants/settings";
import { getPopUpBannerList } from "../../actions/popUpBannerAction";
import CheckReadOnly from "../../components/CheckReadOnly";

const dataHeader = [
  "No.",
  "Title",
  "Active",
  "Duration",
  "Updated At",
  "Action",
];

const defaultParams = {
  active : '',
  duration: '',
  sort_by : DEFAULT_SORT_BY,
  per_page : DEFAULT_PER_PAGE
};

class PopUpBannerIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      _params: {
        ...defaultParams,
      },
      _filterOpen: false,
    };

    this._handleGoto = this._handleGoto.bind(this);
    this._handleToggleFilterPanel = this._handleToggleFilterPanel.bind(this);
    this._handleChange = this._handleChange.bind(this);
    this._handleParamsChangeAndSubmit = this._handleParamsChangeAndSubmit.bind(
      this
    );
    this._handleRefresh = this._handleRefresh.bind(this);
    this._handleResetFilter = this._handleResetFilter.bind(this);
    this._handleRegisteredAtChange = this._handleRegisteredAtChange.bind(this);
  }

  componentDidMount() {
    const { email } = this.props.injectedProps;
    this._getList(this.state._params);
    Analytics.recordPageView("pop_up_banner_list", email, {
      url: window.location.href,
      location: this.props.location,
      match: this.props.match,
    });
  }

  _getList(rawParams = {}) {
    const params = Object.keys(rawParams)
      .filter((key) => rawParams[key] != "")
      .reduce(
        (obj, current) => ({ ...obj, [current]: rawParams[current] }),
        []
      );
    this.props.getList({ params });
  }

  _handleRefresh = (e) => {
    e.preventDefault();
    this._refreshList();
  };

  _refreshList = () => {
    const { _page, ..._params } = this.state._params;
    this.setState(
      {
        _params,
      },
      () => this._getList(this.state._params)
    );
  };

  _handleChange(e) {
    const { _params } = this.state;
    _params[e.target.name] = e.target.value;
    this.setState({ _params });
  }

  _handleParamsChangeAndSubmit(e) {
    // Exclude page...
    const { page, ..._params } = this.state._params;
    _params[e.target.name] = e.target.value;
    this.setState({ _params }, () => {
      this._getList(this.state._params);
    });
  }

  _handleToggleFilterPanel = () => {
    this.setState({ _filterOpen: !this.state._filterOpen });
  };

  _handleApplyFilter = (p) => {
    const { _params } = this.state;
    this.setState(
      {
        _params: {
          ..._params,
          ...p,
          page: 1,
        },
      },
      () => {
        this._getList(this.state._params);
      }
    );
  };

  _handleRegisteredAtChange(rangeDates) {
    this.setState({
      _params: {
        ...this.state._params,
        start_at:
          rangeDates.start_at != null && moment.isMoment(rangeDates.start_at)
            ? rangeDates.start_at.format("YYYY-MM-DD")
            : rangeDates.start_at,
        end_at:
          rangeDates.end_at != null && moment.isMoment(rangeDates.end_at)
            ? rangeDates.end_at.format("YYYY-MM-DD")
            : rangeDates.end_at,
      },
    });
  }

  _handleResetFilter = (e) => {
    e.preventDefault();
    this.setState(
      {
        _params: {
          ...defaultParams,
        },
        _filterOpen: false,
      },
      () => {
        this._getList(this.state._params);
      }
    );
  };

  _handleGoto = (page) => {
    this.setState(
      {
        _params: {
          ...this.state._params,
          page,
        },
      },
      () => this._getList(this.state._params)
    );
  };

  _onRenderPagination() {
    const { status_code, data } = this.props.popUpBanner.list;
    const hasData = (status_code === 200 && data.length) || null;
    return (
      hasData && (
        <Paginations
          size="sm"
          lastPage={this.props.popUpBanner.list.last_page}
          perPage={this.props.popUpBanner.list.per_page}
          total={this.props.popUpBanner.list.total}
          maxPage={8}
          path={this.props.popUpBanner.list.path}
          currentPage={this.props.popUpBanner.list.current_page}
          prevPageUrl={this.props.popUpBanner.list.prev_page_url}
          nextPageUrl={this.props.popUpBanner.list.next_page_url}
          handleGotoCallback={this._handleGoto}
        />
      )
    );
  }

  _onRenderRowItem() {
    if (
      this.props.popUpBanner.list.status_code === 200 &&
      this.props.popUpBanner.list.data.length > 0
    ) {
      const { current_page, per_page } = this.props.popUpBanner.list;
      return this.props.popUpBanner.list.data.map((item, index) => (
        <tr key={generateNumber(per_page, current_page, index)}>
          <th className="v-center" scope="row">
            {generateNumber(per_page, current_page, index)}
          </th>
          <td className="v-center" style={{ maxWidth: 250 }}>
            {item.title}
          </td>
          <td className="v-center"><CheckReadOnly check={item.active}/></td>
          <td className="v-center">
            <div><b>Start: </b>{item.started_at}</div>
            <div><b>End: </b>{item.ended_at}</div>
          </td>
          <td className="v-center">
            {
              <DateToHuman
                value={item.updated_at}
                id={`tooltip_updatedAt_${generateNumber(
                  per_page,
                  current_page,
                  index
                )}`}
              />
            }
          </td>
          <td>
            <Link to={`/pop_up_banner/${item.id}`} className="btn btn-link">
              Details
            </Link>
          </td>
        </tr>
      ));
    }
    return null;
  }

  render() {
    return (
      <div className="animated fadeIn">
        <Row>
          <Col xs={12}>
            <Card>
              <CardHeaderWithToolbars>
                <Title>
                  <i className="fa fa-align-justify"></i>
                  <span>Pop Up Banner List</span>
                </Title>
                <Toolbars
                  isOpen={this.state._filterOpen}
                  onToggleFilter={this._handleToggleFilterPanel}
                  onRefreshList={this._handleRefresh}
                  onResetFilter={this._handleResetFilter}
                />
              </CardHeaderWithToolbars>
              <FilterContainer>
                <FilterPanel                  
                  {...this.state._params}
                  onHandleChange={this._handleChange}
                  onHandleChangeAndSubmit={this._handleParamsChangeAndSubmit}
                  onHandleSearch={this._handleRefresh}
                  onHandleRegisteredAtChange={this._handleRegisteredAtChange}
                  isOpen={this.state._filterOpen}
                />
              </FilterContainer>
              <CardBody>
                <CardText>
                  In this section will display our Pop Up Banner.
                </CardText>
                <div className="table-responsive">
                  <PaginationSummaries
                    from={this.props.popUpBanner.list.from}
                    to={this.props.popUpBanner.list.to}
                    total={this.props.popUpBanner.list.total}
                  />
                  <Tbl>
                    <TblHead dataHeader={dataHeader} />
                    <TblBody
                      isLoad={this.props.popUpBanner.isFetch}
                      hasRow={
                        this.props.popUpBanner.list.status_code === 200 &&
                        this.props.popUpBanner.list.data.length > 0
                      }
                      columnCount={dataHeader.length}
                    >
                      {this._onRenderRowItem()}
                    </TblBody>
                  </Tbl>
                </div>
                {this._onRenderPagination()}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = ({
  user,
  auth: { isFetch, isLogged, roles, id, email },
  popUpBanner,
}) => {
  return {
    user,
    email,
    guardData: { isFetch, isLogged, roles, id, email },
    popUpBanner,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getList: (filter) => dispatch(getPopUpBannerList(filter)),
  };
};

const enhance = connect(mapStateToProps, mapDispatchToProps);
export default enhance(privateView(PopUpBannerIndex));
