import React, { useEffect, useState } from 'react';
import { useFetchConsignment } from "../Hooks/fetch/useFetchConsignment";
import { useFetchConsignmentNotes } from "../Hooks/fetch/useFetchConsignmentNotes";
import { generateNumber, manipulateErrors } from "../../../utils";
import { Filters, RowHeader, RowItem, ModalAccept, ModalReject, ModalMarked } from "./Components";
import { Card, CardBody, CardText, Col, Row } from "reactstrap";
import { CardHeaderWithToolbars, Title } from "../../../components/Card/CardHeaderWithToolbars";
import { Toolbars } from "../Components";
import FilterContainer from "../../../components/Container/FilterContainer/FilterContainer";
import PaginationSummaries from "../../Sales/Monitoring/Paginations/PaginationSummaries";
import { Tbl, TblBody, TblHead } from "../../../components/Tbl";
import Paginations from "../../../components/Paginations";
import { usePutConsignmentMarked } from "../Hooks/put/usePutConsignmentMarked";
import { usePutConsignment } from "../Hooks/put/usePutConsignment";
import { VERIFICATION_FAILED_STATUS, VERIFICATION_PASSED_STATUS } from "../../../constants/kickavenue";
import Analytics from "../../../services/Analytics";
import { useFetchProfile } from "../../../reusables/hooks/fetch/useFetchProfile";
import { ModalMarkedConsignment } from "../../../components/Modals";
import { useReadNotes } from '../Hooks/put/useReadNotes';

function QualityControlConsignment({ }) {
    const { fetchConsignment, data, setData, loading, isFetching } = useFetchConsignment();
    const { fetchConsignmentNotes } = useFetchConsignmentNotes();
    const { putConsignmentMarked } = usePutConsignmentMarked();
    const { putConsignment } = usePutConsignment();
    const { readNotes } = useReadNotes();
    const { roles, email, id } = useFetchProfile();

    const [filterOpen, setFilterOpen] = useState(false);
    const [modalMarked, setModalMarked] = useState(false);
    const [sale, setSale] = useState(null);
    const [isResetFilter, setIsResetFilter] = useState(false);
    const [modalAccept, setModalAccept] = useState(false);
    const [modalReject, setModalReject] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [keyword, setKeyword] = useState('');
    const [seller, setSeller] = useState(null);
    const [shippingMethod, setShippingMethod] = useState('');

    const [perPage, setPerPage] = useState(50);
    const [sortBy, setSortBy] = useState('updatedAt_desc');
    const [errorMessage, setErrorMessage] = useState('');

    const [getParams, setGetParams] = useState({
        page: 1,
        type: 'quality_control',
        sort_by: sortBy,
        per_page: perPage
    });

    // marked consignment
    const [isConfirmation, setIsConfirmation] = useState(false);
    const [marked, setMarked] = useState('');
    const [markedAt, setMarkedAt] = useState('');
    const [markedBy, setMarkedBy] = useState('');
    const [closeMarkedAt, setCloseMarkedAt] = useState('');
    const [markedNotes, setMarkedNotes] = useState('');
    const [notes, setNotes] = useState([]);
    const [unreadNoteCount, setUnreadNoteCount] = useState('');
    const [noteNextPage, setNoteNextPage] = useState(null);
    const [notesPage, setNotesPage] = useState(1);
    const [perPageAll, setPerPageAll] = useState(50);
    const [getParamsAll, setGetParamsAll] = useState({
        page: 1,
    });

    useEffect(() => {
        const params = {
            sort_by: sortBy,
            per_page: perPage,
            ...getParams,
        };
        fetchConsignment(params).then(() => { });
    }, [getParams, perPage, sortBy]);

    useEffect(() => {
        // when opening the modal marked
        if (modalMarked) {
            // if there are notes list available
            if (notes.length) {
                // if there are unread notes
                const ids = notes.filter((item) => !item.read).map((item) => item.id);
                if (ids.length) {
                    // read the notes
                    readNotes(sale.id, { ids }).then(() => {
                        // then update the unread notes count
                        const newData = [...data.data];
                        const index = newData.findIndex((item) => item.id === sale.id);
                        newData[index].unread_notes_count = 0;
                        setData({
                            ...data,
                            data: newData,
                        });
                    });
                }
            }
        }
    }, [modalMarked, notes]);

    useEffect(() => {
        if (errorMessage.length) {
            setTimeout(() => {
                setErrorMessage('');
            }, 3000);
        }
    }, [errorMessage]);

    useEffect(() => {
        if (keyword === '') {
            setGetParams({
                ...getParams,
                keyword: keyword,
            });
        }
    }, [keyword]);

    const handleToggleFilterPanel = () => {
        setFilterOpen(!filterOpen);
    }

    const handleRefresh = async () => {
        const params = {
            sort_by: sortBy,
            per_page: perPage,
            ...getParams,
        };
        await fetchConsignment(params);
    };

    const handleResetFilter = () => {
        setKeyword('');
        setSeller(null);
        setShippingMethod('');
        setFilterOpen(false);
        setGetParams({
            page: 1,
            type: 'quality_control',
        });
    };

    const handleApplyFilter = () => {
        const newParams = {};

        if (seller !== null) {
            newParams['seller_id'] = seller.id;
        } else {
            delete newParams['seller_id'];
            delete getParams['seller_id'];
        }

        if (shippingMethod !== '') {
            newParams['seller_courier'] = shippingMethod;
        } else {
            delete newParams['seller_courier'];
            delete getParams['seller_courier'];
        }

        setGetParams({
            ...getParams,
            ...newParams,
        });
    };

    const handleSearch = () => {
        setGetParams({
            ...getParams,
            keyword,
        });
    };

    const handleCategoryChanged = (category) => {
        if (category === '') {
            setGetParams({
                ...getParams,
                category,
            });
        } else {
            setGetParams({
                ...getParams,
                category: category.value,
            });
        }
    };

    const handleGoto = (page) => {
        setGetParams({
            ...getParams,
            page,
        });
    };

    const openMarkedModal = (sale, confirmation = false) => {
        setSale(sale);
        setModalMarked(true);
        setMarked(sale.marked);
        setMarkedAt(sale.open_marked_at);
        setMarkedBy(sale.open_marked_by)
        setCloseMarkedAt(sale.close_marked_at)
        setMarkedNotes(sale.marked_notes)
        setIsConfirmation(confirmation);
        setUnreadNoteCount(sale.unread_notes_count)
        setNoteNextPage(sale.notes.next_page_url)
        const params = {
            per_page: perPageAll,
            ...getParamsAll,
        }
        fetchConsignmentNotes(sale.id, params)
            .then((res) => {
                setNotes(res.data)
            })
    }

    const onSubmitMarked = ({ marked_notes, marked = null }) => {
        const newData = [...data.data];

        if (marked) {
            const payload = { marked };
            if (marked_notes) {
                payload['marked_notes'] = marked_notes;
            }
            putConsignmentMarked(sale.id, payload).then(() => {
                // manually update the data on the list instead of fetching again
                const index = newData.findIndex((item) => item.id === sale.id);
                newData[index].marked = marked;
                if (marked_notes) {
                    newData[index].marked_notes = marked_notes;
                    newData[index].notes_count += 1;
                }
                setData({
                    ...data,
                    data: newData,
                });

                setSale(null);
                setModalMarked(false);
            });
            return;
        }

        const payload = {
            marked: isConfirmation ? false : marked || sale.marked,
            marked_notes
        }

        putConsignmentMarked(sale.id, payload).then(() => {
            // manually update the data on the list instead of fetching again
            const index = newData.findIndex((item) => item.id === sale.id);
            newData[index].marked = isConfirmation ? false : marked || sale.marked;
            if (marked_notes) {
                newData[index].marked_notes = marked_notes;
                newData[index].notes_count += 1;
            }
            setData({
                ...data,
                data: newData,
            });

            setSale(null);
            setModalMarked(false);
        });
    };

    const onAccept = (consignment) => {
        setSale(consignment);
        setModalAccept(true);
    };

    const onReject = (consignment) => {
        setSale(consignment);
        setModalReject(true);
    };

    const onSubmitAccept = () => {
        setIsSubmitting(true);
        const payload = {
            status: VERIFICATION_PASSED_STATUS,
            ka_verified: true,
        };
        putConsignment(sale.id, payload).then(() => {
            // Analytics.recordLegitCheckPassed(
            //     email,
            //     sale.notes,
            //     sale.invoice_number,
            //     sale.buyer_email,
            //     sale.price,
            // );
            setSale(null);
            setIsSubmitting(false);
            setModalAccept(false);
            fetchConsignment(getParams).then(() => { });
        }).catch((error) => {
            const { error: { message } } = manipulateErrors(error);
            setIsSubmitting(false);
            setErrorMessage(message || 'Something went wrong');
        });
    };

    const onSubmitReject = (type) => {
        setIsSubmitting(true);
        const payload = {
            status: VERIFICATION_FAILED_STATUS,
            type,
        };
        putConsignment(sale.id, payload).then(() => {
            setSale(null);
            setIsSubmitting(false);
            setModalReject(false);
            fetchConsignment(getParams).then(() => { });
        }).catch((error) => {
            const { error: { message } } = manipulateErrors(error);
            setIsSubmitting(false);
            setErrorMessage(message || 'Something went wrong');
        });
    };

    const renderTableRow = () => {
        return data?.data?.map((item, index) => {
            const rowId = generateNumber(data?.per_page, data?.current_page, index);
            return (
                <RowItem
                    item={item}
                    key={rowId}
                    rowId={rowId}
                    isMarked={item.marked}
                    onOpenMarkedModal={(confirmation) => openMarkedModal(item, confirmation)}
                    onAccept={() => onAccept(item)}
                    onReject={() => onReject(item)}
                    adminRoles={roles}
                    email={email}
                />
            )
        })
    };

    return (
        <>
            <ModalMarkedConsignment
                isOpen={modalMarked}
                isConfirmation={isConfirmation}
                toggle={() => setModalMarked(!modalMarked)}
                invoiceNumber={sale && sale.consignment_number}
                marked={marked}
                markedAt={markedAt}
                markedBy={markedBy}
                closeMarkedAt={closeMarkedAt}
                markedNotes={markedNotes}
                notes={notes}
                unreadNoteCount={unreadNoteCount}
                hasNextPage={noteNextPage}
                loginId={id}
                onSubmit={onSubmitMarked}
                onLoadMoreNotes={onSubmitMarked}
            />
            <ModalAccept
                isOpen={modalAccept}
                onToggle={() => setModalAccept(!modalAccept)}
                consignment={sale}
                onSubmit={onSubmitAccept}
                isSubmit={isSubmitting}
                email={email}
                errorMessage={errorMessage}
            />
            <ModalReject
                isOpen={modalReject}
                onToggle={() => setModalReject(!modalReject)}
                consignment={sale}
                onSubmit={onSubmitReject}
                isSubmit={isSubmitting}
                email={email}
                errorMessage={errorMessage}
            />
            <Row className="animated fadeIn">
                <Col xs={12}>
                    <Card>
                        <CardHeaderWithToolbars>
                            <Title>
                                <i className="fa fa-align-justify"></i>
                                <span>Quality Control Available List</span>
                            </Title>
                            {isFetching && <Toolbars
                                isOpen={filterOpen}
                                noExport
                                onToggleFilter={handleToggleFilterPanel}
                                onRefreshList={handleRefresh}
                                onResetFilter={handleResetFilter}
                            />}
                        </CardHeaderWithToolbars>
                        <FilterContainer>
                            <Filters
                                onApplyFilter={handleApplyFilter}
                                onHandleSearch={handleSearch}
                                isReset={isResetFilter}
                                onResetFilterCallback={() => {
                                    setIsResetFilter(false);
                                    setFilterOpen(false);
                                }}
                                isOpen={filterOpen}
                                keyword={keyword}
                                seller={seller}
                                shippingMethod={shippingMethod}
                                setKeyword={setKeyword}
                                setSeller={setSeller}
                                setShippingMethod={setShippingMethod}
                                perPage={perPage}
                                sortBy={sortBy}
                                setPerPage={setPerPage}
                                setSortBy={setSortBy}
                            />
                        </FilterContainer>
                        <CardBody>
                            <CardText>
                                After consignment accepted and seller send their product to our office, we must identify the order must be legit or authentic.
                            </CardText>
                            <div className="table-responsive">
                                <PaginationSummaries
                                    isOpenPrint={false}
                                    from={data?.from ?? 0}
                                    to={data?.to ?? 0}
                                    total={data?.total ?? 0}
                                />
                                <Tbl>
                                    <TblHead>
                                        <RowHeader
                                            categoryName={getParams.category}
                                            categoryValue={getParams.category}
                                            onCategoryChanged={handleCategoryChanged}
                                        />
                                    </TblHead>
                                    <TblBody
                                        isLoad={loading}
                                        hasRow={data?.data?.length > 0}
                                        columnCount={9}
                                    >
                                        {renderTableRow()}
                                    </TblBody>
                                </Tbl>
                            </div>
                            <Paginations
                                size="sm"
                                lastPage={data.last_page}
                                perPage={data.per_page}
                                total={data.total}
                                maxPage={8}
                                path={data.path}
                                currentPage={data.current_page}
                                prevPageUrl={data.prev_page_url}
                                nextPageUrl={data.next_page_url}
                                handleGotoCallback={handleGoto}
                            />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    );
}

export default QualityControlConsignment;
