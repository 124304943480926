import React from "react";
import propTypes from "prop-types";
import { Card, CardHeader, CardBody, Row, Col } from "reactstrap";
import ImageDropzone from "../ImageDropzone/ImageDropzone";
import ImageThumbnail from "../ImageThumbnail/ImageThumbnail";
import uuidv4 from "uuid/v4";

const ImagesUploader = (props) => {
    const onDrop = (files, fileRejections) => {
        if (fileRejections.length) {
            const fileText = fileRejections.length > 1 ? "files" : "file";
            
            alert(
                `${fileRejections.length} ${fileText} cannot be selected because invalid format or ${fileText} size are to large`
            );
        }

        if (files.length) {
            const droppedImages = files.map((file) => {
                const identifier = uuidv4();
                return {
                    id: null,
                    url: null,
                    storageName: "in-local",
                    status: false,
                    isUploading: false,
                    fileInput: file,
                    updatedAt: null,
                    identifier,
                };
            });
            const clonedImages = [...props.images, ...droppedImages];
            props.setImages(clonedImages);
        }
    };

    const onRemoveImage = (e, imageIdentifier) => {
        e.preventDefault();
        const clonedImages = props.images.filter(
            ({ identifier }) => identifier !== imageIdentifier
        );
        props.setImages(clonedImages);
    };

    return (
        <Card>
            <CardHeader>{props.title || "UPLOAD IMAGES"}</CardHeader>
            <CardBody>
                <Row>
                    <Col xs={12} style={{ marginBottom: "1rem" }}>
                        <ImageDropzone
                            onDrop={onDrop}
                            maxSize={props.maxSize}
                        />
                    </Col>
                    {props.images.map((image, index) => {
                        const key = `image-${index}`;

                        return (
                            <Col xs={12} sm={6} md={6} key={key}>
                                <ImageThumbnail
                                    id={image.id}
                                    url={image.url}
                                    updatedAt={image.updatedAt}
                                    storageName={image.storageName}
                                    fileInput={image.fileInput}
                                    isUploading={
                                        image.isUploading && !image.status
                                    }
                                    isDeleting={image.deleted}
                                    onRemoveImage={(e) =>
                                        onRemoveImage(e, image.identifier)
                                    }
                                />
                            </Col>
                        );
                    })}
                </Row>
            </CardBody>
        </Card>
    );
};

ImagesUploader.propTypes = {
    images: propTypes.arrayOf(propTypes.object),
    setImages: propTypes.func,
};

ImagesUploader.defaultProps = {
    images: [],
    setImages: (images) => {},
};

export default ImagesUploader;
