import React, { PureComponent as Component } from 'react';
import classNames from 'classnames';
import propTypes from 'prop-types';
export default class CheckReadOnly extends Component{
  
  render(){
    return(
      <i className={classNames("fa",{"fa-check":this.props.check,"fa-times":!this.props.check})}></i>
    )
  }
}
CheckReadOnly.propTypes = {
  check: propTypes.oneOfType([propTypes.oneOf([0,1]),propTypes.bool])
}
CheckReadOnly.defaultProps = {
  check: false
}