import React, { useEffect, useState } from "react";
import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import cx from "classnames";
import get from "lodash/get";
import SelectBankPromoTag from "../../components/Form/Select/Async/SelectBankPromoTag";
import { ImageFile } from "../../components/Images";
import { DropzonePlusButton } from "../../components/Form/DropzoneFile";
import SelectVoucherAsync from "../../components/Form/Select/Async/SelectVoucher";
import InputDatetime from "../../components/Form/InputDatetime/InputDatetime";
import { Radio, RadioText, FormGroupRadio } from "../../components/Form/Radios";
import { ButtonLoading } from "../../components/Button";
import { ValidationMessage } from "../../components/Form/ValidationMessage";
import { BANK_PROMO_SUBTITLE_MAX_CHAR, bankPromoFormField } from "../../constants/kickavenue";
import { dateValue, parsedDate } from "../../utils/form";
import Style from "./BankPromoForm.module.css";
import {
  getValidSubtitleText,
  hasError,
  mapStringToSelectOption,
  removeHashTagFromSelectedTag,
} from "./bankPromo.utils";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  bankPromoDeleteSuccessSelector,
  bankPromoDetailSelector,
  bankPromoErrorSelector,
  bankPromoIsDeleteModalOpenSelector,
  bankPromoIsDeleteSelector,
  bankPromoIsFetchSelector,
  bankPromoIsSubmitSelector,
  bankPromoSubmitSuccessSelector,
} from "../../store/selectors/bankPromoSelector";
import {
  addBankPromo,
  deleteBankPromo,
  getBankPromoById,
  setBankPromoDeleteModalOpen,
  updateBankPromo,
} from "../../actions/bankPromoAction";
import SubmissionStatus from "../../components/Form/SubmissionStatus";
import ConfirmDelete from "../../components/Form/ConfirmDelete/ConfirmDelete";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import BankPromoTextEditor from "./Editor/BankPromoTextEditor";

const tagsExample = ["BANKPROMO", "BANKBCA", "BANKMANDIRI"];

const BankPromoForm = () => {
  const {
    title,
    subtitle,
    description,
    ribbon_tag,
    voucher,
    started_at,
    ended_at,
    active,
    image_url,
    image_file,
    bank_promotion,
    image,
  } = bankPromoFormField;

  const dispatch = useDispatch();
  const history = useHistory();
  const [subtitleCharLeft, setSubtitleCharLeft] = useState(BANK_PROMO_SUBTITLE_MAX_CHAR);
  const { id: bankPromoIdParam } = useParams();
  const [imagePreview, setImagePreview] = useState("");

  const bankPromoIsSubmit = useSelector(bankPromoIsSubmitSelector);
  const bankPromoIsFetch = useSelector(bankPromoIsFetchSelector);
  const bankPromoIsDelete = useSelector(bankPromoIsDeleteSelector);
  const bankPromoSubmitSuccess = useSelector(bankPromoSubmitSuccessSelector);
  const bankPromoDeleteSuccess = useSelector(bankPromoDeleteSuccessSelector);
  const bankPromoError = useSelector(bankPromoErrorSelector);
  const isDeleteModalOpen = useSelector(bankPromoIsDeleteModalOpenSelector);
  const data = useSelector(bankPromoDetailSelector);

  const errorMessage = ({ field, touched, errors }) =>
    hasError({ field, touched, errors }) && (
      <ValidationMessage message={errors[field]} />
    );

  const formGroupClasses = ({ field, touched, errors }) =>
    cx({
      "has-danger has-feedback": hasError({
        field,
        touched,
        errors,
      }),
    });

  const {
    handleSubmit,
    handleChange,
    setFieldValue,
    setValues,
    touched,
    errors,
    values,
  } = useFormik({
    initialValues: {
      [title]: "",
      [subtitle]: "",
      [description]: "",
      [ribbon_tag]: [],
      [voucher]: null,
      [started_at]: "",
      [ended_at]: "",
      [active]: 0,
      [image_file]: null,
    },
    onSubmit: (values) => {
      // optional fields
      const rt = get(values[ribbon_tag], "length")
        ? values[ribbon_tag].map((tag) => tag.name).join(",")
        : undefined;
      const vch = get(values[voucher], "id")
        ? { id: values[voucher].id }
        : undefined;
      const endedAt = get(values, ended_at);

      const payload = {
        // required fields
        [title]: values[title],
        [subtitle]: values[subtitle],
        [started_at]: values[started_at],
        [description]: values[description].toString("html"),
        [active]: values[active] ? 1 : 0,
      };

      // optional fields
      payload[ribbon_tag] = rt || "";
      payload[ended_at] = endedAt || null;
      payload[voucher] = vch || null;

      // add id for edit
      if (bankPromoIdParam) payload.id = bankPromoIdParam;

      // convert to form data
      const formData = new FormData();
      formData.append(bank_promotion, JSON.stringify(payload));
      formData.append(image, get(values, image_file) || null);

      if (bankPromoIdParam) {
        dispatch(updateBankPromo(formData, bankPromoIdParam));
        return;
      }

      dispatch(addBankPromo(formData));
    },
    validationSchema: Yup.object().shape({
      [title]: Yup.string().required(`The ${title} field is required`),
      [subtitle]: Yup.string()
        .max(BANK_PROMO_SUBTITLE_MAX_CHAR, `Can't exceed ${BANK_PROMO_SUBTITLE_MAX_CHAR} characters`)
        .required(`The ${subtitle} field is required`),
      [started_at]: Yup.string().required(`The started at field is required`),
    }),
    validate: (values) => {
      const errors = {};

      const desc = values[description].toString("html");
      if (desc === "<p><br></p>" || !desc) {
        errors[description] = "The description field is required";
      }

      const vch = values[voucher];
      if (!vch) {
        errors[voucher] = "Voucher field is required";
      }

      return errors;
    },
  });

  const handleSelectTagChange = (selectedTags) => {
    if (selectedTags?.length <= 3) {
      setFieldValue(ribbon_tag, removeHashTagFromSelectedTag(selectedTags));
    }
  };

  const handleSubtitleChange = (e) => {
    const val = e.target.value;
    const finalVal = getValidSubtitleText(val);

    setSubtitleCharLeft(BANK_PROMO_SUBTITLE_MAX_CHAR - finalVal.length);
    setFieldValue(subtitle, finalVal)
  };


  // populate form value after create / update and get detail
  useEffect(() => {
    const subtitleVal = getValidSubtitleText(get(data, subtitle) || "");
    if (subtitleVal?.length) {
      setSubtitleCharLeft(BANK_PROMO_SUBTITLE_MAX_CHAR - subtitleVal?.length);
    }

    setValues({
      [title]: get(data, title) || "",
      [subtitle]: subtitleVal,
      [description]: get(data, description) || "",
      [ribbon_tag]: get(data, ribbon_tag)
        ? mapStringToSelectOption(get(data, ribbon_tag) || "")
        : [],
      [voucher]: get(data, voucher) || null,
      [started_at]: get(data, started_at) || "",
      [ended_at]: get(data, ended_at) || "",
      [active]: get(data, active) || 0,
    });
    setImagePreview(get(data, image_url) || "");
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    data,
    title,
    subtitle,
    description,
    ribbon_tag,
    voucher,
    started_at,
    ended_at,
    active,
    image_url,
    setValues,
  ]);

  // populate form on see detail
  useEffect(() => {
    if (bankPromoIdParam) {
      dispatch(getBankPromoById(bankPromoIdParam));
    }
  }, [bankPromoIdParam, dispatch]);

  // redirect to bank promo list on delete success
  useEffect(() => {
    if (bankPromoDeleteSuccess || bankPromoSubmitSuccess) {
      history.replace("/bank_promo");
    }
  }, [bankPromoDeleteSuccess, bankPromoSubmitSuccess, history]);

  return (
    <Row className="d-flex justify-content-center">
      <ConfirmDelete
        isOpen={isDeleteModalOpen}
        onToggleModal={() =>
          dispatch(setBankPromoDeleteModalOpen(!isDeleteModalOpen))
        }
        onDelete={() => dispatch(deleteBankPromo(bankPromoIdParam))}
        isDeleting={bankPromoIsDelete}
      />
      <Col md={5}>
        <Card>
          <CardHeader>
            <i className="fa fa-align-justify"></i>{" "}
            {bankPromoIdParam ? "Bank Promo Detail" : "Create Bank Promo"}
          </CardHeader>
          <CardBody>
            <SubmissionStatus
              success={bankPromoSubmitSuccess}
              error={bankPromoError}
              isFetch={bankPromoIsFetch}
            />
            {!bankPromoIsFetch && (
              <Form onSubmit={handleSubmit}>
                <FormGroup
                  className={formGroupClasses({
                    field: "title",
                    touched,
                    errors,
                  })}
                >
                  <Label>Title*</Label>
                  <Input
                    type="text"
                    placeholder="Enter a title"
                    value={values.title}
                    onChange={handleChange}
                    name={title}
                  />
                  {errorMessage({
                    field: title,
                    touched,
                    errors,
                  })}
                </FormGroup>
                <FormGroup>
                  <Label>Tags (only three tags allowed)</Label>
                  <SelectBankPromoTag
                    id="bankPromoForm"
                    paramsApi={{ sort_by: "createdAt_desc" }}
                    placeholder="Enter a tags"
                    value={values[ribbon_tag]}
                    onSelectChange={handleSelectTagChange}
                    options={tagsExample.map((tag) => ({
                      id: tag,
                      name: tag,
                    }))}
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Bank Promo Image</Label>
                  <div className="d-flex">
                    <ImageFile
                      style={{ padding: 0 }}
                      alt="Bank Promo Image"
                      src={
                        imagePreview ||
                        "https://via.placeholder.com/300x150?text=1280x720"
                      }
                      showDelete={false}
                    />
                    <div className="ml-3">
                      <DropzonePlusButton
                        accept={[".jpg", ".jpeg", ".png", ".gif"]}
                        multiple={false}
                        onDrop={(files) => {
                          const file = get(files, "[0]");
                          setImagePreview(get(file, "preview") || "");
                          setFieldValue(image_file, file);
                        }}
                      />
                      <ul
                        className="image-guides"
                        style={{
                          fontSize: ".75rem",
                          marginTop: ".75rem",
                          paddingInlineStart: "1rem",
                        }}
                      >
                        <li>It will be display on Web and App.</li>
                        <li>
                          Use 1280x720 (16:9) resolution of your image as our
                          recommended. (In pixels).
                        </li>
                      </ul>
                    </div>
                  </div>
                </FormGroup>
                <FormGroup
                  className={formGroupClasses({
                    field: subtitle,
                    touched,
                    errors,
                  })}
                >
                  <Label>
                    Subtitle* (max. 150 characters) left: {subtitleCharLeft}
                  </Label>
                  <Input
                    rows="4"
                    type="textarea"
                    placeholder="Enter a subtitle"
                    name={subtitle}
                    value={values[subtitle]}
                    onChange={handleSubtitleChange}
                  />
                  {errorMessage({
                    field: subtitle,
                    touched,
                    errors,
                  })}
                </FormGroup>
                <FormGroup
                  className={cx(
                    formGroupClasses({
                      field: description,
                      touched,
                      errors,
                    }),
                    {
                      [Style["description-container-error"]]: hasError({
                        field: description,
                        errors,
                        touched,
                      }),
                    }
                  )}
                >
                  <Label>Description*</Label>
                  <BankPromoTextEditor
                    value={values[description]}
                    handleChange={(val) =>
                      setFieldValue(description, val.editor.getData())
                    }
                  />
                  {errorMessage({
                    field: description,
                    touched,
                    errors,
                  })}
                </FormGroup>
                <FormGroup
                  className={formGroupClasses({
                    field: voucher,
                    touched,
                    errors,
                  })}
                >
                  <Label>Voucher*</Label>
                  <SelectVoucherAsync
                    id="selectVoucher"
                    placeholder="Type and select a voucher..."
                    noResultsText="Cannot find code."
                    value={values[voucher]}
                    onSelectChange={(val) => setFieldValue(voucher, val)}
                  />
                  {errorMessage({
                    field: voucher,
                    touched,
                    errors,
                  })}
                </FormGroup>
                <Row>
                  <Col xs={12} md={6}>
                    <FormGroup
                      className={formGroupClasses({
                        field: started_at,
                        touched,
                        errors,
                      })}
                    >
                      <Label>Started At*</Label>
                      <InputDatetime
                        inputProps={{
                          placeholder: "Pick a Start At",
                        }}
                        value={dateValue(started_at, values)}
                        onChange={(val) =>
                          setFieldValue(started_at, parsedDate(val))
                        }
                      />
                      {errorMessage({
                        field: started_at,
                        touched,
                        errors,
                      })}
                    </FormGroup>
                  </Col>
                  <Col xs={12} md={6}>
                    <FormGroup>
                      <Label>End At</Label>
                      <InputDatetime
                        inputProps={{
                          placeholder: "Pick a End At",
                        }}
                        value={dateValue(ended_at, values)}
                        onChange={(val) =>
                          setFieldValue(ended_at, parsedDate(val))
                        }
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <FormGroupRadio className="d-flex align-items-center">
                  <RadioText text="Set to Active" className="mr-2" />
                  <Radio
                    type="checkbox"
                    name="active"
                    value={true}
                    checked={values[active]}
                    dataOnText="On"
                    dataOffText="Off"
                    style={{ marginBottom: 0 }}
                    onChange={(e) => setFieldValue(active, e.target.checked)}
                  />
                </FormGroupRadio>
                <p style={{ fontSize: ".75rem", fontStyle: "italic" }}>
                  (*) this field is required.
                </p>
                <ButtonGroup>
                  <ButtonLoading
                    type="submit"
                    isLoading={bankPromoIsSubmit}
                    color="primary"
                    style={{
                      pointerEvents: bankPromoIsSubmit ? "none" : "auto",
                    }}
                  >
                    Submit
                  </ButtonLoading>
                  {bankPromoIdParam && (
                    <Button
                      className="ml-2"
                      color="danger"
                      type="button"
                      disabled={bankPromoIsSubmit}
                      onClick={() =>
                        dispatch(setBankPromoDeleteModalOpen(true))
                      }
                    >
                      Delete
                    </Button>
                  )}
                </ButtonGroup>
              </Form>
            )}
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default BankPromoForm;
