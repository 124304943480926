import React, { Component } from 'react';
import {
  Form,
  Label,
  Col,
  Row,
  Input,
  FormGroup,
  Button,
  Card,
  CardBody,
  CardHeader,
  ButtonGroup,
  Alert,
  Collapse,
  UncontrolledTooltip,
  CardText
} from 'reactstrap';
import uuidv4 from 'uuid/v4';
import ReactLoading from 'react-loading';
// import Loading from 'react-loading-animation';
import classNames from 'classnames';
import { connect } from 'react-redux';
import {
  getDetailVoucher,
  saveVoucher,
  clearFormVoucher,
  getTotalUsagesVoucher,
  getUserVoucherList,
  deleteUserVoucher,
  saveUserVoucher,
  saveVoucherImage,
  deleteVoucherImage,
  getVoucherCategories,
  saveVoucherCategory,
  saveVoucherPaymentMethod,
} from '../../../actions/voucherAction';
import { getSettingPaymentMethod } from '../../../actions/settingAction'
import { getOptionList as getCategories } from '../../../actions/categoriesAction';
import InputDatetime from '../../../components/Form/InputDatetime/InputDatetime';
import { Radio, RadioText, FormGroupRadio } from '../../../components/Form/Radios';
import { errorMessage, hasError, textChange, textValue, dateChange, dateValue, checkChange } from '../../../utils/form';
import { ModalEditImage, ModalBlockLoading as ModalLoading, ModalInfo } from '../../../components/Modals';
import { ButtonLoading } from '../../../components/Button';
import UserVoucherList from './UserVoucherList';
import RichTextEditor from "react-rte";
import { ImagesCompressor, createUploadFileForm } from '../../../utils/imageHelper';
import { getPathS3 } from '../../../utils/AWS';
import { ImageVoucher } from './ImageVoucher';
import { FontAwesomeIcon, SimpleLineIcon } from '../../../components/Icons';
import { uploadImage, deleteImage } from '../../../actions/imageUploadAction';
import ProductVariantVouchers from './ProductVariantVouchers';
import BrandVouchers from './BrandVouchers';
import VoucherPaymentMethods from "./VoucherPaymentMethods";
import SelectVoucherAsync from '../../../components/Form/Select/Async/SelectVoucher';
import VoucherAuditList from './VoucherAuditList';
import { Creatable } from 'react-select';
import { formatDailyStartTime } from '../../../utils';

const DEFAULT_IMAGE_ASPECT_LANDSCAPE = 16/5;
const DEFAULT_IMAGE_ASPECT_PORTRAIT = 1/1;

const addImageRawObject = file => {
  return {
    identifier: uuidv4(),
    fileInputOrigin: file, //for rollback.
    fileInput: file,
    URL: file.preview,
    status: false,
    isUploading: false,
    compressed: false
  }
}


const toolbarConfig = {
  display: [
    "INLINE_STYLE_BUTTONS",
    "BLOCK_TYPE_BUTTONS",
    "BLOCK_TYPE_DROPDOWN",
    "HISTORY_BUTTONS"
  ],
  INLINE_STYLE_BUTTONS: [
    { label: "Bold", style: "BOLD", className: "custom-css-class" },
    { label: "Italic", style: "ITALIC" },
    { label: "Underline", style: "UNDERLINE" }
  ],
  BLOCK_TYPE_DROPDOWN: [
    { label: "Normal", style: "unstyled" },
    { label: "Heading Large", style: "header-one" },
    { label: "Heading Medium", style: "header-two" },
    { label: "Heading Small", style: "header-three" }
  ],
  BLOCK_TYPE_BUTTONS: [
    { label: "UL", style: "unordered-list-item" },
    { label: "OL", style: "ordered-list-item" }
  ]
};


const LoadingBar = props => (
  <div className="loading-wrapper">
    <ReactLoading type="bars" color="green" height={20} width={30}  className="loading-center"/>
  </div>
)

class FormCard extends Component{
  constructor(props){
    super(props);
    this.state={
      id: this.props.params.id && parseInt(this.props.params.id),
      code:'',
      name:'',
      domain: [],
      descriptionValue: RichTextEditor.createEmptyValue(),
      description:'',
      disposable_voucher: false,
      limit_per_user: null,
      active:false,
      listing_pre_order:false,
      listing_pre_verified:false,
      new_user_only: false,
      group_name:[],
      started_at:null,
      ended_at:null,
      max_amount: '',
      minimum_purchase: '',
      platform_specifications: '',
      created_at:'',
      updated_at:'',
      limit: 1,
      daily_limit: null,
      termsValue: RichTextEditor.createEmptyValue(),
      terms:'',
      instructionsValue: RichTextEditor.createEmptyValue(),
      instructions: '',
      user:[],
      images:[],
      _rawImages: [],
      categories: [],
      _paymentMethods: [],
      modalEditImage: {
        isOpen: false,
        defaultImageRatio: DEFAULT_IMAGE_ASPECT_LANDSCAPE,
        imageSourceEdits: [],
        purpose: "default"
      },
      _selected:null,
      _isModalLoadingOpen: false,
      _isErrorModalOpen: false,
      _uploadSubmit:false,
      submitToApi:false,
      _isUsageLimitation: false,
      _successMessage: null,
      _errorMessage: null,
      _alertSuccess: false,
      _alertError: false,
      _formSubmit: false,
      _error: null,
      isPaymentMethodSubmit: false
    }
    this.onSubmit=this.onSubmit.bind(this);
    this.handleChange=this.handleChange.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
    this.handleChangeEditor=this.handleChangeEditor.bind(this);
    this.handleCheckedChange=this.handleCheckedChange.bind(this);
    this._handleOnSelectVoucherChange=this._handleOnSelectVoucherChange.bind(this);
    this._handleOnSelectVoucherOpen = this._handleOnSelectVoucherOpen.bind(this);
  }

  async componentDidMount(){
    if(this.state.id){
      this.props.find(this.state.id);
    } else {
      this.props.new();
    }
    if(!this.props.dataCategories.length){
      this.props.getCategories()
    } else{
      const data = this._bindCategoryData()
      this.setState({
        categories: [...data]
      })
    }
    await this.props.getSettingPaymentMethod();
  }

  componentDidUpdate(prevProps){
    if(prevProps.dataCategories.length !== this.props.dataCategories.length){
      let data = null;
      const { id, categories } = this.props.voucher.detail
      if(id && id == this.state.id){
        data = categories;
      }
      this.setState({
        categories: this._bindCategoryData(data)
      })
    }
    if(prevProps.voucher.isFetch &&prevProps.voucher.isFetch !== this.props.voucher.isFetch){
      const { error } = this.props.voucher;
      if(!error){
        const { amount, minimum_purchase, max_amount, platform_specifications, description, terms, instructions, categories, started_at, ended_at, new_user_only, new_user_started_at, new_user_ended_at, group_name, domain } = this.props.voucher.detail;
        const group_voucher =
        group_name !== null
          ? group_name.split(",").map(group => ({ id: group, code: group }))
          : [];
        const domain_list = domain ? domain.split(",").map(tag => ({ label: tag, value: tag })) : []
        this.setState({
          ...this.props.voucher.detail,
          domain: domain_list,
          group_name:group_voucher,
          started_at: new_user_only ? new_user_started_at : started_at,
          ended_at: new_user_only ? new_user_ended_at : ended_at,
          categories: this._bindCategoryData(categories),
          _rawImages: [],
          _isUsageLimitation: this.props.voucher.detail.limit!==null,
          amount: parseInt(amount),
          max_amount: max_amount !== null ? parseInt(max_amount): '',
          minimum_purchase: minimum_purchase !== null ? parseInt(minimum_purchase): '',
          platform_specifications: platform_specifications? platform_specifications: '',
          daily_start_time: formatDailyStartTime(this.props?.voucher?.detail?.daily_start_time),
          
          descriptionValue: description
            ? RichTextEditor.createValueFromString(
                description,
                "html"
              )
            : RichTextEditor.createEmptyValue(),
          termsValue: terms
            ? RichTextEditor.createValueFromString(
                terms,
                "html"
              )
            : RichTextEditor.createEmptyValue(),
          instructionsValue: instructions
            ? RichTextEditor.createValueFromString(
                instructions,
                "html"
              )
            : RichTextEditor.createEmptyValue(),
        },()=>{
          this._bindVoucherPaymentMethods();
          this.props.getTotalUsagesVoucher(this.state.id);
        })
      }
    }
    if(prevProps.dataVoucherCategories.isSubmit && !this.props.dataVoucherCategories.isSubmit){
      const { error, data } = this.props.dataVoucherCategories;
      if(!error){
        this.setState({
          categories: this._bindCategoryData(data)
        })
      }
      else{
        const { categories } = this.props.voucher.detail;
        this.setState({
          categories: this._bindCategoryData(categories)
        })
      }
    }
    if(prevProps.voucher.isSubmit
      && prevProps.voucher.isSubmit !== this.props.voucher.isSubmit
      && this.state.isPaymentMethodSubmit){
      this._bindVoucherPaymentMethods();
      this.setState({ isPaymentMethodSubmit: false });
    }
    if(prevProps.voucher.isSubmit
      && prevProps.voucher.isSubmit !== this.props.voucher.isSubmit
      && this.state._formSubmit === true
    ){
      if(this.props.isNew){
        const { id } = this.props.voucher.detail;
        this.props.history.replace(`/vouchers/${id}`);
      }
      else{
        const { amount, minimum_purchase, max_amount, platform_specifications, description, terms, instructions,started_at, ended_at, new_user_only, new_user_started_at, new_user_ended_at, group_name, domain } = this.props.voucher.detail;
        const group_voucher =
        group_name !== null
          ? group_name.split(",").map(group => ({ id: group, code: group }))
          : [];
        const domain_list = domain ? domain.split(",").map(tag => ({ label: tag, value: tag })) : []
        this.setState({
          ...this.props.voucher.detail,
          domain: domain_list,
          group_name:group_voucher,
          started_at: new_user_only ? new_user_started_at : started_at,
          ended_at: new_user_only ? new_user_ended_at : ended_at,
          categories: this.state.categories, //exclude bind categories.
          amount : parseInt(amount),
          max_amount: max_amount !== null ? parseInt(max_amount): '',
          minimum_purchase: minimum_purchase !== null ? parseInt(minimum_purchase): '',
          platform_specifications: platform_specifications? platform_specifications: '',
          daily_start_time: formatDailyStartTime(this.props?.voucher?.detail?.daily_start_time),
          descriptionValue: description
            ? RichTextEditor.createValueFromString(
                description,
                "html"
              )
            : RichTextEditor.createEmptyValue(),
          termsValue: terms
            ? RichTextEditor.createValueFromString(
                terms,
                "html"
              )
            : RichTextEditor.createEmptyValue(),
          instructionsValue: instructions
            ? RichTextEditor.createValueFromString(
                instructions,
                "html"
              )
            : RichTextEditor.createEmptyValue(),
          _formSubmit :!this.state._formSubmit,
          _successMessage : this.props.voucher.success,
          _alertSuccess : true
        },() => {
          this._bindVoucherPaymentMethods();
        })
      }
    }
    if(prevProps.voucher.error!==this.props.voucher.error && this.props.voucher.error!==null){
      const { error } = this.props.voucher;
      this.setState({
        _error:{...error},
        _formSubmit: false,
        _errorMessage : error.status_code===422?'Please fulfill your form.' : error.message,
        _alertError : true
      })
    }
    if(prevProps.voucher.detail.isImageDataSubmit && prevProps.voucher.detail.isImageDataSubmit !== this.props.voucher.detail.isImageDataSubmit){
      const { error } = this.props.voucher.detail;
      if(error){
        this.setState({
          _uploadSubmit: false,
          _isModalLoadingOpen: false,
          _isErrorModalOpen: true,
        })
      }
      if(!error){
        this.setState({
          _uploadSubmit: false,
          _isErrorModalOpen: false,
        }, () => {
          this.props.find(this.state.id)
        })
      }
    }
    if(prevProps.dataUploaded.isUpload && prevProps.dataUploaded.isUpload !== this.props.dataUploaded.isUpload) {
      const { success, error } = this.props.dataUploaded;
      if(error){
        this.setState({
          _uploadSubmit: false,
          _isModalLoadingOpen: false,
          _isErrorModalOpen: true
        })
      }
      if(!error && success.data.success){
        const {id, _rawImages } = this.state;
        const { data } = success.data;
        this.setState({
          _uploadSubmit: false,
          _isModalLoadingOpen: false,
        },() => {
          const payloads = [];
          _rawImages.map((res, index) => {
            const findImage = data[index];
            if(findImage)
              payloads.push({url:findImage.url, orientation:res.orientation}); 
          })
          this.props.saveVoucherImage(id, { images: [...payloads] })
        });
      }
    }
    if(prevProps.dataUploaded.isDeleteProgress && prevProps.dataUploaded.isDeleteProgress !== this.props.dataUploaded.isDeleteProgress) {
      const { deleted, error } = this.props.dataUploaded;
      if(error){
        this.setState({
          _uploadSubmit: false,
          _selected:null
        })
      }
      if(!error && deleted.data){
        const { id, _selected } = this.state;
        this.setState({
          _uploadSubmit: false,
          _selected: null,
        },() => {
          this.props.deleteVoucherImage(id, _selected.id);
        });
      }
    }

    if(prevProps.paymentMethods.isFetch !== this.props.paymentMethods.isFetch) {
      if (!this.props.paymentMethods.isFetch) {
        this._bindVoucherPaymentMethods()
      }
    }
  }

  _bindCategoryData = (dataCategories = null) => {
    const { dataCategories: dataCategoriesProps  } = this.props;
    const { categories } = this.state;
    const data = dataCategories? dataCategories: categories;
    const ids = data.map(item => Number(item.id));
    return dataCategoriesProps.filter(c => c.label.toLowerCase() !== 'kafair')
    .map(item => {
      return {
        id: item.value,
        name: item.label.toLowerCase(),
        active: data.length === 0 || ids.includes(item.value)
      }
    })
  }

  _bindVoucherPaymentMethods=() =>{
    const { detail } = this.props.voucher;
    const { paymentMethods: { data } } = this.props;
    const dataPayments = data.map(payments =>{
      var vPaymentMtd = Object.assign({}, payments);
      const get = detail?.voucher_payment_methods?.find(item=>{
        return item.payment_method === payments.value;
      });
      if(get) {
        vPaymentMtd.id = get.id;
        vPaymentMtd.active = true;
      }
      if(!vPaymentMtd.id && detail?.voucher_payment_methods?.length > 0) {
        vPaymentMtd.active = false;
      }
      return vPaymentMtd;
    })
    this.setState({
      _paymentMethods: dataPayments
    })
  }

  handleChange(e){
    const _form=textChange(e,this.state);
    this.setState({..._form});
  }

  handleOnChange(value, name) {
    const state = this.state;
    state[name] = value;
    this.setState({ state });
  }

  handleChangeEditor(value, name) {
    let newState = {...this.state};
    switch (name) {
      case "description":
        newState = {...this.state, descriptionValue: value, description: this.state.descriptionValue.toString("html")}
        break;
      case "terms" :
        newState = {...this.state, termsValue: value, terms: this.state.termsValue.toString("html")}
        break;
      case "instructions" :
        newState = {...this.state, instructionsValue: value, instructions: this.state.instructionsValue.toString("html")}
        break;
      default:
        break;
    }
    this.setState(newState);
  }

  _compressImage = async (image) => {
    let fileInput = image
    try{
      fileInput = await ImagesCompressor(image);
    }
    catch(e){
      alert('error while compress the images')
      fileInput = image
    }
    return fileInput;
  }
  
  _compressMultipleImages = async (images) => {
    const imgs = images.filter(img => !img.commpressed)
    if(imgs.length){
      const compressedPromises = images.map(async(img) =>{
        const compressed = await this._compressImage(img.fileInput)
        return {
          ...img,
          fileInput: compressed,
          compressed: true
        }
      })
      const results = await Promise.all(compressedPromises)
      let temp = [];
      if(results.length){
        temp = images.map(draft => {
          const data = results.find(r => r.identifier === draft.identifier);
          if(data){
            return data
          }
          return draft
        })
      }
      return temp;
    }
    return images;
  }
  
  _handleDropzoneItemChange = (files) =>{
    if(files.length){
      const fileInputs = files.map(c => ({
        ...addImageRawObject(c),
      }));
      this.setState({
        _rawImages: [
          ...this.state._rawImages,
          ...fileInputs
        ],
        modalEditImage: {
          ...this.state.modalEditImage,
          isOpen: true,
          imageSourceEdits: [
            ...this.state.modalEditImage.imageSourceEdits,
            ...fileInputs.map(f => ({
              imgId: f.identifier,
              src: f.URL
            }))
          ],
          purpose: 'child',
          defaultImageRatio: DEFAULT_IMAGE_ASPECT_PORTRAIT
        }
      });
    }
  }

  onCropSucceeded = async (blob, ratio) => {
    const blobUrl = URL.createObjectURL(blob);
    let fileName = blobUrl.substr(blobUrl.lastIndexOf('/')+1)
    if(fileName == '') fileName = 'local'
    const { modalEditImage, _rawImages } = this.state;
    let identifier = 0;
    if(modalEditImage.imageSourceEdits.length)
      identifier = modalEditImage.imageSourceEdits[0].imgId;

    this.setState({
      _rawImages: _rawImages.map(item => {
        if(item.identifier === identifier){
          return {
            ...item,
            fileInput: new File([blob], `${fileName}.jpeg`, {
              type: 'image/jpeg'
            }),
            URL: blobUrl,
            orientation: ratio.ratio <=1? 'portrait': 'landscape'
          }
        }
        return item;
      }),
      modalEditImage: {
        ...modalEditImage,
        isOpen: false,
        imageSourceEdits: modalEditImage.imageSourceEdits
          .filter(item => item.imgId !== identifier)
      }
    }, () => {
      const { modalEditImage, modalEditImage: {imageSourceEdits} } = this.state;
      if(imageSourceEdits.length){
        setTimeout(() => {
          this.setState({
            modalEditImage: {
              ...modalEditImage,
              isOpen: true
            }
          })
        }, 1500)
      }
    })
  }

  _handleOnSelectVoucherChange(selected){
    let { group_name } = this.state;
    group_name = selected;
    this.setState({
      group_name,
      _recentVoucherSearch: selected && this.state._recentVoucherSearch
    })
  }

  _handleOnSelectVoucherOpen(){
    if(this.state.group_name){
      return this.state._recentVoucherSearch&&this.refs.selectVoucher.loadSelectOptions(this.state._recentVoucherSearch);
    }
    return this.refs.selectVoucher.loadSelectOptions('');
  }

  removeAlerts(){
    this.setState({
      _alertError:false,
      _alertSuccess:false
    });
  }
  onSubmit(e){
      e.preventDefault();
      this.removeAlerts();
      const {
        _formSubmit,
        _error,
        _alertError,
        _alertSuccess,
        _successMessage,
        _errorMessage,
        _isUsageLimitation,
        ...rest } = this.state;
      let canSubmit = true;
      if(_isUsageLimitation===true){
        if(rest.limit === null || rest.limit === ''){
          this.setState({
            _error:{
              errors:{
                limit: ['Limit is Required.']
              },
              status_code: 422
            }
          })
          canSubmit = false;
        }
      }
      if(canSubmit)
        this.setState({ _formSubmit:true, _error:null },()=>{
          const {
            id,
            terms,
            active,
            listing_pre_order,
            listing_pre_verified,
            new_user_only,
            amount,
            currency,
            deduct_type,
            description,
            instructions,
            is_cashback,
            disposable_voucher,
            limit_per_user,
            daily_limit,
            type,
            name,
            voucher_type,
            domain
          } = rest;
          this.props.save({
            id,
            terms,
            active,
            listing_pre_order,
            listing_pre_verified,
            new_user_only,
            amount,
            currency,
            deduct_type,
            description,
            instructions,
            is_cashback,
            disposable_voucher,
            limit_per_user,
            type,
            voucher_type,
            name,
            code: rest.code.toUpperCase().trim(),
            domain: domain.length ? domain.map(tag => tag.value).reduce((concat, tag) => concat + "," + tag) : '',
            limit: _isUsageLimitation? rest.limit : null,
            daily_limit: daily_limit > 0 ? daily_limit : null,
            started_at: rest.started_at=== ''?null:rest.started_at,
            ended_at: rest.ended_at=== ''?null:rest.ended_at,
            max_amount: rest.max_amount === '' || rest.max_amount == 0? null: rest.max_amount,
            minimum_purchase: rest.minimum_purchase == ''? 0: rest.minimum_purchase,
            platform_specifications: rest.platform_specifications !== ''? rest.platform_specifications: null,
            group_name: rest.group_name && typeof rest.group_name === typeof [] && rest.group_name.length ? rest.group_name.map(group => group.code).reduce((concat, code) => concat + "," + code): null,
            daily_start_time: rest?.daily_start_time ? `${rest?.daily_start_time}:00` : '',
          });
        })
  }

  handleCheckedChange(e){
    this.setState({ ...checkChange(e, this.state) });
  }

  handleDatePickerChange(value, name){
    const _form=dateChange(value,name,this.state);
    this.setState({..._form});
  }

  toggleAlert(key){
    const lastState = {...this.state};
    lastState[key] = !lastState[key];
    this.setState({...lastState});
  }

  _renderAlerts(){
    return(
      <div>
        <Alert color="success" isOpen={this.state._alertSuccess} toggle={()=>this.toggleAlert('_alertSuccess')}>
          <strong>Well done!</strong> { this.state._successMessage }
        </Alert>
        <Alert color="danger" isOpen={this.state._alertError} toggle={()=>this.toggleAlert('_alertError')}>
          <strong>Oh Snap!</strong> { this.state._errorMessage }
        </Alert>
      </div>
    )
  }

  _renderTotalUsagesVoucher(){
    if(this.props.isEdit){
      const { totalUsages, isFetch } = this.props.voucher
      return(
        <Row>
          <div style={{display:'flex', flex: 1, padding: '0 1rem', flexDirection:'row-reverse', marginBottom: '0.75rem'}}>
          { totalUsages.isFetch || isFetch? <LoadingBar/>:
            <div style={{padding: '0.75rem 1rem', backgroundColor:'#ddd'}}>
              <div>
                Total Usages: <strong>{ totalUsages.data.total_usages }</strong>
              </div>
            </div>
          }
          </div>
        </Row>
      )
    }
    return null;
  }

  render(){
    const allowUpdateCode = !this.props.isEdit || (
      this.props.isEdit && this.props.dataUser.roles.includes("superadministrator")
    );
    return(
      <Row>
        <ModalEditImage
          isOpen={this.state.modalEditImage.isOpen}
          imageSource={this.state.modalEditImage.imageSourceEdits.length? this.state.modalEditImage.imageSourceEdits[0].src: null}
          showGrid
          ratio={this.state.modalEditImage.defaultImageRatio}
          onCropSucceeded={this.onCropSucceeded}
        />
        <Col xs={12} md={6} sm={6}>
          <Card>
            <CardHeader>
              <i className="fa fa-align-justify"></i> {this.props.title}
            </CardHeader>
            <CardBody>
              {this._renderAlerts()}
              <ModalInfo
                modalTitle="Oh snap."
                isOpen={this.state._isErrorModalOpen}
                toggle={() => this.setState({_isErrorModalOpen: !this.state._isErrorModalOpen, _errorModalMessage: null})}
                renderModalBody={() => (<p className="text-center">{this.state._errorModalMessage?this.state._errorModalMessage: 'An error occurred.'}</p>)}
              />
              <ModalLoading isOpen={this.state._isModalLoadingOpen}/>
              <Form onSubmit={this.onSubmit}>
                { this._renderTotalUsagesVoucher() }
                <Row>
                  <Col xs={12} md={6}>
                    <FormGroup className={classNames({'has-danger has-feedback':hasError('code',this.state._error)})}>
                      <Label for="formCode">Code*</Label>
                      <Input
                        type="text"
                        id="formCode"
                        name="code"
                        disabled={!allowUpdateCode}
                        value={textValue('code',this.state)}
                        onChange={this.handleChange}
                        placeholder="Enter a voucher code*"
                      />
                      {errorMessage('code',this.state._error)}
                    </FormGroup>
                  </Col>
                  <Col xs={12} md={6}>
                    <FormGroup className={classNames({'has-danger has-feedback':hasError('platform_specifications',this.state._error)})}>
                      <Label for="formPlatform">Only use for</Label>
                        <Input
                          type="select"
                          id="formPlatform"
                          name="platform_specifications"
                          value={textValue('platform_specifications',this.state)}
                          onChange={this.handleChange}
                        >
                          <option value="">All</option>
                          <option value="APP">App</option>
                          <option value="WEB">Web</option>
                        </Input>
                      {errorMessage('platform_specifications',this.state._error)}
                    </FormGroup>
                  </Col>
                  <Col xs={12}>
                    <FormGroup className={classNames({'has-danger has-feedback':hasError('name',this.state._error)})}>
                      <Label for="formName">Name</Label>
                        <Input
                          type="text"
                          id="formName"
                          name="name"
                          placeholder="Enter a name"
                          value={textValue('name',this.state)}
                          onChange={this.handleChange}
                        />
                      {errorMessage('name',this.state._error)}
                    </FormGroup>
                    <FormGroup>
                      <Label for="formDescription">Description</Label>
                      <RichTextEditor
                        name="description"
                        value={this.state.descriptionValue}
                        onChange={(value) => this.handleChangeEditor(value, "description")}
                        toolbarConfig={toolbarConfig}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={12}>
                    <FormGroup>
                      <Label for="formTerms">Terms</Label>
                      <RichTextEditor
                        value={this.state.termsValue}
                        onChange={(value) => this.handleChangeEditor(value, "terms")}
                        toolbarConfig={toolbarConfig}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={12}>
                    <FormGroup>
                      <Label for="formInstructions">Instructions</Label>
                      <RichTextEditor
                        value={this.state.instructionsValue}
                        onChange={(value) => this.handleChangeEditor(value, "instructions")}
                        toolbarConfig={toolbarConfig}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    <FormGroup className={classNames({'has-danger has-feedback':hasError('domain',this.state._error)})}>
                      <Label for="formDomain">Domain</Label>
                        <Creatable
                          multi
                          options={[]}
                          value={this.state.domain}
                          onChange={val => this.handleOnChange(val, 'domain')}
                          placeholder="Enter a domain"
                        />
                      {errorMessage('name',this.state._error)}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={6}>
                    <FormGroup className={classNames({'has-danger has-feedback':hasError('type',this.state._error)})}>
                      <Label for="formType">Type*</Label>
                        <Input
                          type="select"
                          id="formType"
                          name="type"
                          value={textValue('type',this.state)}
                          onChange={this.handleChange}
                        >
                          <option value="">Select Type</option>
                          <option value="fixed">Fixed</option>
                          <option value="percentage">Percentage</option>
                        </Input>
                      {errorMessage('type',this.state._error)}
                    </FormGroup>
                  </Col>
                  <Col xs={12} md={6}>
                    <FormGroup className={classNames({'has-danger has-feedback':hasError('deduct_type',this.state._error)})}>
                      <Label for="formDeductType">Deduct Type*</Label>
                      <Input
                        type="select"
                        id="formDeductType"
                        name="deduct_type"
                        value={textValue('deduct_type',this.state)}
                        onChange={this.handleChange}
                      >
                        <option value="">Select Deduct Type</option>
                        <option value="courier_price">Courier Price</option>
                        <option value="product_price">Product Price</option>
                      </Input>
                      {errorMessage('type',this.state._error)}
                    </FormGroup>
                  </Col>
                  <Col xs={12} md={6}>
                    <FormGroup className={classNames({'has-danger has-feedback':hasError('amount',this.state._error)})}>
                      <Label for="formAmount">Amount*</Label>
                        <Input
                          type="text"
                          id="formAmount"
                          name="amount"
                          placeholder="Enter a amount*"
                          value={textValue('amount',this.state)}
                          onChange={this.handleChange}
                        />
                      {errorMessage('amount',this.state._error)}
                    </FormGroup>
                  </Col>
                  <Col xs={12} md={6}>
                    <FormGroup className={classNames({'has-danger has-feedback':hasError('minimum_purchase',this.state._error)})}>
                      <Label for="formMinimumPurchase">Minimum Purchase</Label>
                        <Input
                          type="text"
                          id="formMinimumPurchase"
                          name="minimum_purchase"
                          placeholder="Enter a minimum purchase"
                          value={textValue('minimum_purchase',this.state)}
                          onChange={this.handleChange}
                        />
                      {errorMessage('minimum_purchase',this.state._error)}
                    </FormGroup>
                  </Col>
                  <Col xs={12}>
                    <FormGroup className={classNames({'has-danger has-feedback':hasError('max_amount',this.state._error)})}>
                      <Label for="formMaxAmount">Max. Amount Disc.</Label>
                        <Input
                          type="text"
                          id="formMaxAmount"
                          name="max_amount"
                          placeholder="Enter a max amount of discount"
                          value={textValue('max_amount',this.state)}
                          onChange={this.handleChange}
                        />
                      {errorMessage('max_amount',this.state._error)}
                    </FormGroup>
                  </Col>
                  <Col xs={12} md={6}>
                    <FormGroup className={classNames({'has-danger has-feedback':hasError('voucher_type',this.state._error)})}>
                      <Label for="formType">Voucher Type*</Label>
                        <Input
                          type="select"
                          id="formVoucherType"
                          name="voucher_type"
                          value={textValue('voucher_type',this.state)}
                          onChange={this.handleChange}
                        >
                          <option value="">Select Type</option>
                          <option value="public">Public</option>
                          <option value="private">Private</option>
                          <option value="public_open">Public Open</option>
                          <option value="in_store">In Store</option>
                        </Input>
                      {errorMessage('type',this.state._error)}
                    </FormGroup>
                  </Col>
                  <Col xs={12} md={6}>
                    <FormGroup className={classNames({'has-danger has-feedback':hasError('active',this.state._error)})}>
                      <Label for="formActive">Set to Active &nbsp;</Label>
                      <Radio
                        name="active"
                        id="formActive"
                        value="true" checked={textValue('active', this.state)}
                        onChange={this.handleCheckedChange}
                      />
                      {errorMessage('active',this.state._error)}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={6}>
                    <FormGroup className={classNames({'has-danger has-feedback':hasError('listing_pre_order',this.state._error)})}>
                      <Label for="formPreOrder">Khusus Pre-order &nbsp;</Label>
                      <Radio
                        name="listing_pre_order"
                        id="formPreOrder"
                        value="true" checked={textValue('listing_pre_order', this.state)}
                        onChange={this.handleCheckedChange}
                      />
                      {errorMessage('listing_pre_order',this.state._error)}
                    </FormGroup>
                  </Col>
                  <Col xs={12} md={6}>
                    <FormGroup className={classNames({'has-danger has-feedback':hasError('listing_pre_verified',this.state._error)})}>
                      <Label for="formPreVerified">Khusus Pre-verified &nbsp;</Label>
                      <Radio
                        name="listing_pre_verified"
                        id="formPreVerified"
                        value="true" checked={textValue('listing_pre_verified', this.state)}
                        onChange={this.handleCheckedChange}
                      />
                      {errorMessage('listing_pre_verified',this.state._error)}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={6}>
                    {/* <FormGroup className={classNames({'has-danger has-feedback':hasError('disposable_voucher',this.state._error)})}>
                      <Label for="formDisposableVoucher">Set to One Per-user &nbsp;</Label>
                      <Radio
                        name="disposable_voucher"
                        id="formDisposableVoucher"
                        value="true" checked={textValue('disposable_voucher', this.state)}
                        onChange={this.handleCheckedChange}
                      />
                      {errorMessage('disposable_voucher',this.state._error)}
                    </FormGroup> */}
                    <FormGroup className={classNames({'has-danger has-feedback':hasError('limit_per_user',this.state._error)})}>
                      <Label for="formLimit">Set Limit Per-User</Label>
                      <Input
                        type="number"
                        id="formLimit"
                        name="limit_per_user"
                        placeholder="Enter a limit per user"
                        value={textValue('limit_per_user',this.state) || ''}
                        onChange={this.handleChange}
                      />
                      {errorMessage('limit_per_user',this.state._error)}
                    </FormGroup>
                  </Col>
                  <Col xs={12} md={6}>
                    <FormGroup className={classNames({'has-danger has-feedback':hasError('active',this.state._error)})}>
                      <Label for="formUsageLimitationCheck">Usages Limitation &nbsp;</Label>
                      <Radio
                        name="_isUsageLimitation"
                        id="formUsageLimitationCheck"
                        value="true" checked={textValue('_isUsageLimitation', this.state)}
                        onChange={this.handleCheckedChange}
                      />
                      {errorMessage('_isUsageLimitation',this.state._error)}
                    </FormGroup>
                  </Col>
                </Row>
                <Collapse isOpen={this.state._isUsageLimitation}>
                  <Row>
                    <Col xs={12} md={6}>
                      <FormGroup className={classNames({'has-danger has-feedback':hasError('limit',this.state._error)})}>
                        <Label for="formLimit">Limit</Label>
                        <Input
                          type="number"
                          id="formLimit"
                          name="limit"
                          placeholder="Enter a limit usage*"
                          value={textValue('limit',this.state)}
                          onChange={this.handleChange}
                          onWheel={event => { event.target.blur(); }}
                        />
                        {errorMessage('limit',this.state._error)}
                      </FormGroup>
                    </Col>
                    <Col xs={12} md={6}>
                      <FormGroup className={classNames({'has-danger has-feedback':hasError('daily_limit',this.state._error)})}>
                        <Label for="formLimit">Daily Limit</Label>
                        <Input
                          type="number"
                          id="formLimit"
                          name="daily_limit"
                          placeholder="Enter a daily limit usage*"
                          value={textValue('daily_limit',this.state)}
                          onChange={this.handleChange}
                          onWheel={event => { event.target.blur(); }}
                        />
                        {errorMessage('daily_limit',this.state._error)}
                      </FormGroup>
                    </Col>
                  </Row>
                </Collapse>
                <Row>
                  <Col xs={12} md={6}>
                    <FormGroup className={classNames({'has-danger has-feedback':hasError('is_cashback',this.state._error)})}>
                      <Label for="formDisposableVoucher">Khusus Cashback &nbsp;</Label>
                      <Radio
                        name="is_cashback"
                        id="formDisposableVoucher"
                        value="true" checked={textValue('is_cashback', this.state)}
                        onChange={this.handleCheckedChange}
                      />
                      {errorMessage('is_cashback',this.state._error)}
                    </FormGroup>
                  </Col>
                  <Col xs={12} md={6}>
                    <FormGroup className={classNames({'has-danger has-feedback':hasError('new_user_only',this.state._error)})}>
                      <Label for="formDisposableVoucher">Khusus New User &nbsp;</Label>
                      <Radio
                        name="new_user_only"
                        id="formDisposableVoucher"
                        value="true" checked={textValue('new_user_only', this.state)}
                        onChange={this.handleCheckedChange}
                      />
                      {errorMessage('new_user_only',this.state._error)}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    <FormGroup className={classNames({'has-danger has-feedback':hasError('daily_start_time',this.state._error)})}>
                      <Label for="dailyStartTime">*Daily Start Time (*24 hour format, ex: 14:00)</Label>
                      <Input
                        type="text"
                        id="daily_start_time"
                        name="daily_start_time"
                        placeholder="__:__"
                        value={textValue('daily_start_time',this.state)}
                        onChange={this.handleChange}
                      />
                      {errorMessage('daily_start_time',this.state._error)}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={12}>
                    <FormGroup
                      className={classNames({
                        "has-danger has-feedback": hasError(
                          "nickname",
                          this.state._errorForm
                        )
                      })}
                    >
                      <Label for="formProductNickName">Group name</Label>
                      <SelectVoucherAsync
                        isMulti
                        defaultOptions
                        id="_formSelectVoucher"
                        ref="selectVoucher"
                        value={textValue('group_name',this.state)}
                        placeholder="Type and select a code..."
                        noResultsText="Cannot find code."
                        loadOptions={this._getVoucherOptions}
                        onSelectSearch={(input)=>this.setState({_recentVoucherSearch: input})}
                        onSelectChange={this._handleOnSelectVoucherChange}
                        onSelectOpen={this._handleOnSelectVoucherOpen}
                      />
                      {errorMessage("group_name", this.state._error)}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={6}>
                    <FormGroup className={classNames({'has-danger has-feedback':hasError('started_at',this.state._error)})}>
                      <Label for="formStartedAt">Started At</Label>
                      <InputDatetime
                        inputProps={{placeholder:'Pick a Start At', id:'formStartedAt'}}
                        value={dateValue('started_at', this.state)}
                        onChange={(date)=>this.handleDatePickerChange(date,'started_at')}
                        timeFormat="HH:mm"/>
                      {errorMessage('started_at', this.state._error)}
                    </FormGroup>
                  </Col>
                  <Col xs={12} md={6}>
                    <FormGroup className={classNames({'has-danger has-feedback':hasError('ended_at', this.state._error)})}>
                      <Label for="formEndedAt">End At</Label>
                      <InputDatetime
                        inputProps={{placeholder:'Pick a End At', id:'formEndedAt'}}
                        value={dateValue('ended_at',this.state)}
                        onChange={(date)=>this.handleDatePickerChange(date,'ended_at')}
                        timeFormat="HH:mm"/>
                      {errorMessage('ended_at', this.state._error)}
                    </FormGroup>
                  </Col>
                </Row>
                {
                  !this.props.isNew && (
                    <div>
                      <FormGroup>
                        <Label>Created At</Label>
                        <Input
                          type="text"
                          readOnly
                          value={this.state.created_at}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label>Updated At</Label>
                        <Input
                          type="text"
                          readOnly
                          value={this.state.updated_at}
                        />
                      </FormGroup>
                    </div>
                  )
                }
                <p style={{fontSize: '.75rem', fontStyle: 'italic'}}>(*) this field is required.</p>
                <FormGroup>
                  <ButtonGroup>
                    <ButtonLoading isLoading={this.state._formSubmit}>Submit</ButtonLoading>
                    {
                      !this.props.isNew?
                      <Button  color="danger" type="button" onClick={this.props.onDelete}>Delete</Button>
                      :
                      ''
                    }
                  </ButtonGroup>
                </FormGroup>
              </Form>
            </CardBody>
          </Card>
        </Col>
        {
          this.props.isEdit &&
          <Col xs={12} sm={6} md={6}>
            <Card>
              <CardHeader>
                <FontAwesomeIcon iconType="align-justify"/> Spesific Category
              </CardHeader>
              <CardBody>
                <CardText>
                  At least one active category.
                </CardText>
                {this.state.categories.map(category => (
                  <FormGroup key={category.id} row sm={3}>
                    <Label sm={3} className="text-uppercase" style={{fontWeight: 700, fontSize: '.75rem'}}>
                      {category.name}
                    </Label>
                    <Col sm={9} style={{display: "flex", alignItems: "center"}}>
                      <FormGroupRadio
                      >
                        <Radio
                          type="checkbox"
                          value={true}
                          checked={category.active}
                          dataOnText="Yes"
                          dataOffText="No"
                          onChange={(e) => {
                            const valid = !e.target.checked? this.state.categories
                              .filter(item => item.id !== category.id && item.active).length > 0: true;
                            if(!valid){
                              alert("Oops. At least one active category!");
                              return;
                            }
                            this.setState({
                              categories: this.state.categories.map(item => {
                                if(item.id === category.id){
                                  return { ...item, active: e.target.checked }
                                }
                                return item;
                              })
                            })
                          }}
                        />
                        <RadioText text="Set to Active"/>
                      </FormGroupRadio>
                    </Col>
                  </FormGroup>
                ))}
                <FormGroup>
                  <ButtonLoading
                    isLoading={this.props.dataVoucherCategories.isSubmit}
                    loadingMessage="Submitting..."
                    color="primary"
                    onClick={async () => {
                      const { categories, id } = this.state;
                      const ids = categories.filter(item => item.active)
                      .map(item => Number(item.id))
                      const payloads = {
                        sync: true,
                        categories: ids
                      }
                      this.props.saveVoucherCategory(id, payloads)
                    }}
                  >
                    Save
                  </ButtonLoading>
                </FormGroup>
              </CardBody>
            </Card>
            <VoucherPaymentMethods
              isLoading={this.props.voucher.isFetch || this.props.paymentMethods.isFetch}
              voucherId={this.props.params.id}
              isSubmit={this.state.isPaymentMethodSubmit}
              paymentMethods={this.state._paymentMethods}
              onTogglePaymentMethod={(payloads) => this.setState({
                _paymentMethods:payloads
              })}
              saveVoucherPaymentMethod={(id, payloads) => {
                if(this.state.isPaymentMethodSubmit) return;
                this.setState({isPaymentMethodSubmit: true})
                this.props.saveVoucherPaymentMethod(id, payloads)
              }}
            />
            <ProductVariantVouchers id={this.props.params.id}/>
            <BrandVouchers id={this.props.params.id}/>
            <Card>
              <CardHeader>
                <FontAwesomeIcon className="mr-2" iconType="image"/>
                <span className="mr-1">Voucher Images</span>
                <SimpleLineIcon id="appSectionImageTitleTip" iconType="question"/>
                <UncontrolledTooltip innerClassName="text-left" placement="right" target="appSectionImageTitleTip">
                  Please provide at least one image voucher!
                </UncontrolledTooltip>
              </CardHeader>
              <CardBody>
                <ul className="image-guides" style={{fontSize: '.75rem', paddingInlineStart: '1rem'}}>
                  <li className="d-none">You can click the image for preview the image.</li>
                  <li>It will be display on Web and App.</li>
                  <li>Use 1280x400 (16:5),  1280x720 (16:9) or  1280x465,45 (11:4) resolution of your image as our recommended for our web. (In pixels). Or any resolution that you want, but make sure its <strong>landscape</strong> orientation.</li>
                  <li>Use 700x700 (1:1),  700x933,33 (3:4) or  700x1050 (4:6) resolution of your image as our recommended for our app display. (In pixels). Or any resolution that you want, but make sure its <strong>portrait</strong> orientation.</li>
                  <li>If you not provide/upload any images in this form. By default, <strong>Default image</strong> will be display on our platforms (Web or App).</li>
                  <li>You can upload multiple images, it will be sort by latest uploaded. (By default).</li>
                  <li>By changing the images, click Upload button below this form or Submit button in "General Form" for save your changes.</li>
                </ul>
                <div>
                  <ImageVoucher
                    key={this.props.params.id}
                    showItems={!(!this.props.params.id)}
                    isLoading={this.state._uploadSubmit}
                    onClearImageFiles={() => {
                      this.setState({
                        _rawImages: []
                      })
                    }}
                    onDeleteFile={index => {
                      const { _rawImages } = this.state;
                      this.setState({
                        _rawImages: _rawImages.filter((_img, idx) => idx !== index)
                      })
                    }}
                    onSaveImage={(data) => {
                      this.setState({_uploadSubmit: true}, () => {
                        if(data.deleted){
                          this.setState({
                            _selected: data
                          }, () => {
                            // const httpExpression = /(^https:\/\/kickavenue-assets.s3.amazonaws.com\/)/;
                            let parsedUrl = getPathS3(data.url);
                            if(parsedUrl){
                              const payload = {
                                bucket_type : 'assets',
                                name : parsedUrl
                              };
                              this.props.deleteImage(payload);
                              return
                            }
                            this.setState({
                              _uploadSubmit: false,
                              _errorModalMessage : "The image's url unrecognized. Cannot delete this image!",
                              _isErrorModalOpen : true
                            })
                          });
                        }
                        else{
                          this.props.saveVoucherImage(this.state.id, data)
                        }
                      })
                    }}
                    onDropFiles={files => this._handleDropzoneItemChange(files)}
                    imageFiles={this.state._rawImages}
                    images={this.state.images}
                  />
                </div>
                <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                  {this.props.isEdit && this.state.id && this.state._rawImages.length?
                    <ButtonLoading
                      isLoading={this.state._uploadSubmit}
                      onClick={async () => {
                        const { _rawImages, id } = this.state;
                        const compressedImages = await this._compressMultipleImages(_rawImages);
                        if(compressedImages.length){
                          this.setState({
                            _uploadSubmit: true,
                            _rawImages: [...compressedImages.map((img, key) => ({
                              ...img,
                              isUploading: key===0
                            }))],
                            _isModalLoadingOpen: true
                          })
                          const imgs = compressedImages.map(img => img.fileInput)
                          const formData = createUploadFileForm(imgs, true, {name: `vouchers/${id}`, bucket_type: "assets"});
                          this.props.uploadImage(formData);
                        }
                      }}
                    >
                      Upload
                    </ButtonLoading>
                  :null}
                </div>
              </CardBody>
            </Card>
          </Col>
        }
        {
          this.props.isEdit &&
          <>
            <Col xs={12} md={12}>
              <UserVoucherList
                id={this.state.id}
                onGetList={this.props.getUserVoucherList}
                onDelete={this.props.deleteUserVoucher}
                dataUserVouchers={this.props.voucher.userVouchers}
                dataAuth={this.props.auth}
                saveUserVoucher={this.props.saveUserVoucher}
                voucherType={this.state.voucher_type}
              />
            </Col>
            <Col xs={12} md={12}>
              <VoucherAuditList
                id={this.state.id}
                type="voucher"
                refresh={!this.props.voucher.isSubmit}
              />
            </Col>
          </>
        }
      </Row>
    )
  }
}

const mapStateToProps= ({auth, voucher: { voucherCategories: dataVoucherCategories, ...voucher}, user, imageUpload, masterCategories, setting: { paymentMethods }}) => {
	return {
    voucher,
    user,
    auth,
    dataVoucherCategories,
    dataUploaded: imageUpload,
    dataCategories: masterCategories.options,
    paymentMethods
	}
};
const mapDispatchToProps = (dispatch) => {
	return{
		find:(id) => dispatch(getDetailVoucher(id)),
		save:(payload) => dispatch(saveVoucher(payload)),
		saveUserVoucher:(payload) => dispatch(saveUserVoucher(payload)),
    new:() => dispatch(clearFormVoucher()),
    getTotalUsagesVoucher: id => dispatch(getTotalUsagesVoucher(id)),
    getUserVoucherList: (id, query) => dispatch(getUserVoucherList(id, query)),
    deleteUserVoucher: (id, voucherId) => dispatch(deleteUserVoucher(id, voucherId)),
    uploadImage: (payload) => dispatch(uploadImage(payload)),
    deleteImage: (payload) => dispatch(deleteImage(payload)),
    saveVoucherImage: (voucherId, payload) => dispatch(saveVoucherImage(voucherId, payload)),
    deleteVoucherImage: (voucherId, id) => dispatch(deleteVoucherImage(voucherId, id)),
    getCategories: () => dispatch(getCategories()),
    getVoucherCategories: (voucherId) => dispatch(getVoucherCategories(voucherId)),
    saveVoucherCategory: (voucherId, payload) => dispatch(saveVoucherCategory(voucherId, payload)),
    saveVoucherPaymentMethod: (id, payload) => dispatch(saveVoucherPaymentMethod(id, payload)),
    getSettingPaymentMethod: () => dispatch(getSettingPaymentMethod())
	}
};

export default connect(mapStateToProps,mapDispatchToProps)(FormCard);

