import {
	POINT_SHOP_ROUTE_API,
	POINT_SHOP_LIST,
	FETCHING_POINT_SHOP,
	GET_DETAIL_POINT_SHOP,
	POINT_SHOP_ERROR,
	AUTH_TOKEN_KEY,
	SEARCH_POINT_SHOP_ITEMS,
	POINT_SHOP_CHILDS_REQUEST,
	GET_LIST_POINT_SHOP_ITEMS,
	POINT_SHOP_CHILDS_ERROR,
	ADD_POINT_SHOP,
	UPDATE_POINT_SHOP,
	NEW_POINT_SHOP,
	DELETE_POINT_SHOP,
	SUBMITING_POINT_SHOP_ITEMS,
} from '../constants';
import { logout } from './authAction';
import api from '../utils/Api';
import { manipulateErrors, checkAuthorized } from '../utils';
import { onRequest, onFailedRequest, onSuccessRequest } from './helpers';


export const newPointShop=()=>{
	return {
		type:NEW_POINT_SHOP
	}
}

export const getPointShopList=(query)=>{
	return dispatch =>(
		dispatch(isFetch({type:'fetch',status:true})),
		api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).get(POINT_SHOP_ROUTE_API,query)
		.then(res=>{
			dispatch(onSuccess(res.data,POINT_SHOP_LIST))
			dispatch(isFetch({type:'fetch',status:false}))
		})
		.catch(err => {
			dispatch(onError(err));
			dispatch(isFetch({type:'fetch',status:false}));
		})
	)
}

export const getDetailPointShop=(id)=>{
	return dispatch => (
		dispatch(isFetch({type:'fetch',status:true})),
		api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).get(`${POINT_SHOP_ROUTE_API}/${id}`)
		.then(res=>{
			dispatch(onSuccess(res.data,GET_DETAIL_POINT_SHOP))
			dispatch(isFetch({type:'fetch',status:false}))
		})
		.catch(err => {
			dispatch(onError(err));
			dispatch(isFetch({type:'fetch',status:false}));
		})
	)
}

export const getPointShopItems = (id, pointShopType, query) => (
	dispatch => {
		let actionType = GET_LIST_POINT_SHOP_ITEMS;
		if(query.params.exclude === true){
			actionType = 	SEARCH_POINT_SHOP_ITEMS;
		}
		dispatch(onRequest(
			POINT_SHOP_CHILDS_REQUEST,
			actionType,
			true
		))
		api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).get(`${POINT_SHOP_ROUTE_API}/${id}/${pointShopType}`, query)
		.then(res=>{
			dispatch(onSuccess(res.data, actionType))
			dispatch(onRequest(
				POINT_SHOP_CHILDS_REQUEST,
				actionType,
				false
			))
		})
		.catch(err => {
			dispatch(onFailedRequest(POINT_SHOP_CHILDS_ERROR, actionType, err, { id, query }))
			dispatch(onRequest(
				POINT_SHOP_CHILDS_REQUEST,
				actionType,
				false
			))
		})
	}
)

export const savePointShop = (payload) =>{
	const { id, ...body }=payload;
	return dispatch =>{
		if(payload.id===undefined)
			dispatch(createPointShop(body));
		else 
			dispatch(updatePointShop(id, body));
	}
}

export const createPointShop=(payload)=>{
	return dispatch => (
		dispatch(isFetch({type:'submit',status:true})),
        api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).post(`${POINT_SHOP_ROUTE_API}`,payload)
		.then(res=>{
			dispatch(onSuccess(res.data,ADD_POINT_SHOP))
			dispatch(isFetch({type:'submit',status:false}))
		})
		.catch(err => {
			dispatch(onError(err));
			dispatch(isFetch({type:'submit',status:false}));
		})
	)
}

export const updatePointShop = (id,payload) =>{
	return dispatch => (
		dispatch(isFetch({type:'submit',status:true})),
		api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).put(`${POINT_SHOP_ROUTE_API}/${id}`,payload)
		.then(res=>{
			dispatch(onSuccess(res.data,UPDATE_POINT_SHOP))
			dispatch(isFetch({type:'submit',status:false}))
		})
		.catch(err => {
			dispatch(onError(err));
			dispatch(isFetch({type:'submit',status:false}));
		})
	)
}

export const deletePointShop = (id) =>{
	return dispatch => (
		dispatch(isFetch({type:'submit',status:true})),
		api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).delete(`${POINT_SHOP_ROUTE_API}/${id}`)
		.then(res=>{
			dispatch(onSuccess(res.data,DELETE_POINT_SHOP))
			dispatch(isFetch({type:'submit',status:false}))
		})
		.catch(err => {
			dispatch(onError(err));
			dispatch(isFetch({type:'submit',status:false}));
		})
	)
}

export const savePointShopItems = (operation, id, pointShopType, payload) => (
	dispatch => {
		dispatch(onRequest(
			POINT_SHOP_CHILDS_REQUEST,
			SUBMITING_POINT_SHOP_ITEMS,
			true
		))
		let request = null;
		if(operation === 'attach'){
			request = api({
				Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
			}).post(`${POINT_SHOP_ROUTE_API}/${id}/${pointShopType}`, payload)
		} else{ //detach
			request = api({
				Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
			}).put(`${POINT_SHOP_ROUTE_API}/${id}/${pointShopType}`, payload)
		}
		request
		.then(res=>{
			dispatch(onSuccess(res.data, SUBMITING_POINT_SHOP_ITEMS))
			dispatch(onRequest(
				POINT_SHOP_CHILDS_REQUEST,
				SUBMITING_POINT_SHOP_ITEMS,
				false
			))
		})
		.catch(err => {
			dispatch(onFailedRequest(POINT_SHOP_CHILDS_ERROR, SUBMITING_POINT_SHOP_ITEMS, err, id));
			dispatch(onRequest(
				POINT_SHOP_CHILDS_REQUEST,
				SUBMITING_POINT_SHOP_ITEMS,
				false
			))
		})
	}
)

export const isFetch=(payload)=>(
	{
		type:FETCHING_POINT_SHOP,
		payload:payload		
	}
)

export const onSuccess=(res,type)=>
(
	{
		type:type,
		payload:res		
	}
)

export const onError=(err)=>{
	if(!checkAuthorized(manipulateErrors(err)))
		return logout();
	return {
		type:POINT_SHOP_ERROR,
		payload:manipulateErrors(err)
	}
}