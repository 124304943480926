import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom'
import { connect } from 'react-redux';

import Header from '../../components/Header/KickHeader';
import Sidebar from '../../components/Sidebar/';
import Breadcrumb from '../../components/Breadcrumb/';
import Aside from '../../components/Aside/';
import Footer from '../../components/Footer/';

import Dashboard from '../../views/Dashboard/KickDashboard'
import Charts from '../../views/Charts/'
import Widgets from '../../views/Widgets/'
import Buttons from '../../views/Components/Buttons/'
import Cards from '../../views/Components/Cards/'
import Forms from '../../views/Components/Forms/'
import Modals from '../../views/Components/Modals/'
import SocialButtons from '../../views/Components/SocialButtons/'
import Switches from '../../views/Components/Switches/'
import Tables from '../../views/Components/Tables/'
import Tabs from '../../views/Components/Tabs/'
import FontAwesome from '../../views/Icons/FontAwesome/'
import SimpleLineIcons from '../../views/Icons/SimpleLineIcons/'

import { SellRequests, SellRequestDetail, SellRequestCreate } from '../../views/SellRequests';
import { SellerVerification, SellerVerificationDetail } from '../../views/SellerVerification';
import { Payments, PaymentCreate, PaymentDetail, PaymentDisbursement } from '../../views/Payments';
import { CollectionIndex, CollectionDetail, CollectionCreate } from '../../views/Collections'
import { BrandIndex, BrandDetail, BrandCreate } from '../../views/Brands'
import { CategoriesIndex, CategoriesDetail, CategoriesCreate } from '../../views/Categories'
import { ProductIndex, ProductDetail, ProductCreate } from '../../views/Products'
import { ProductVariantIndex, ProductVariantDetail, ProductVariantCreate, SkuRequests } from '../../views/ProductVariants'
import { OutstandingComponent, ShoeVerification, QualityControl, Delivering, Processed, FailedSales, SaleDetails, Monitoring, SaleHijacked, FollowUp, JntDelivering, InstantDelivering, WarehouseDelivering, OtherDelivering } from '../../views/Sales'
import { AllConsignment, OutstandingConsignment, QualityControlConsignment, LegitCheckConsignment, FollowUpConsignment, RackAssignmentConsignment, PreDeliveringConsignment, DeliveringConsignment, ProcessedConsignment, FailedConsignment, JntDeliveringConsignment, InstantDeliveringConsignment, OtherDeliveringConsignment, WarehouseDeliveringConsignment, ConsignmentDetails } from '../../views/Consignment'
import { StockProductVariants, StockProductVariantForm, StockDetail } from '../../views/ProductStocks';
import { UserIndex, UserCreate, UserDetail } from '../../views/Users'
import { AccountBankIndex, AccountBankCreate, AccountBankDetail } from '../../views/AccountBanks'
import { RoleIndex, RoleCreate, RoleDetail } from '../../views/Roles'
import { VoucherIndex, VoucherCreate, VoucherDetail } from '../../views/Vouchers'
import { SliderIndex, SliderCreate, SliderDetail } from '../../views/Sliders'
import { VideoCreate, VideoDetail, VideoIndex } from '../../views/Videos'
import { TopUp, TopUpDetail } from '../../views/TopUp'
import { RewardIndex, RewardCreate, RewardDetail } from '../../views/Rewards'
import { MenuIndex, MenuCreate, MenuDetail } from '../../views/Menus';
import PendingDisbursementList from '../../views/Disbursements/PendingDisbursementList'
import DisbursementList from '../../views/Disbursements/DisbursementList'
import CompletedDisbursementList from '../../views/Disbursements/CompletedDisbursementList'
import DisbursementCreate from '../../views/Disbursements/DisbursementCreate'
import DisbursementDetails from '../../views/Disbursements/DisbursementDetails'
import MyProfiles from '../../views/MyProfiles';
import { BidIndex, BidDetail } from '../../views/Bids';
import { MidtransIndex, MidtransDetail } from '../../views/Midtrans';
import withAuthorized from '../../components/hocs/withAuthorized';
import ButtonBackToTop from '../../components/Button/ButtonBackToTop';
import SeekStock from '../../views/SeekStock';
import { Warehouse } from '../../views/Warehouse';
import { RackIndex, RackCreate, RackDetail } from '../../views/Rack';

import { AppSectionIndex, AppSectionCreate, AppSectionDetail } from '../../views/AppSections'
import { RaffleGroupIndex, RaffleGroupCreate, RaffleGroupDetail } from '../../views/RaffleGroup'
import { RaffleIndex, RaffleCreate, RaffleDetail, RaffleRequestIndex, RaffleRequestDetail, RaffleTicketDetail } from '../../views/Raffles'
import { PointShopIndex, PointShopCreate, PointShopDetail } from '../../views/PointShops'
import { MarketingBudgetIndex, MarketingBudgetCreate, MarketingBudgetDetail } from '../../views/MarketingBudgets'
import { ProductAddonIndex, ProductAddonCreate, ProductAddonDetail } from '../../views/ProductAddons'
import { Concierge, ConciergeCreate, ConciergeDetail, ConciergePending, ConciergeProcessed, ConciergeCompleted, ConciergeFailed } from '../../views/Concierge';
import { ConnectParticipant } from 'aws-sdk';
import { SpinningWheelIndex, SpinningWheelDetail, SpinningWheelCreate } from '../../views/SpinningWheels';
import { PopUpBannerIndex, PopUpBannerDetail, PopUpBannerCreate } from '../../views/PopUpBanner';
import { FaqCreate, FaqDetail, FaqIndex } from '../../views/FAQ';
import { TimerIndex, TimerDetail, TimerCreate } from '../../views/Timer';
import { SurveyCreate, SurveyDetail, SurveyIndex } from '../../views/Survey';
import { SurveyOptionCreate, SurveyOptionDetail, SurveyOptionIndex } from '../../views/Survey/SurveyOption';
import { SettingCreate, SettingDetail, SettingIndex } from '../../views/Settings';
import { CountryIndex, UpdateBatchCountry } from '../../views/Countries';
import { PushNotificationCreate, PushNotificationDetail, PushNotification } from '../../views/PushNotification';
import { ArticleCreate, ArticleIndex, ArticleDetail } from '../../views/Article';
import { MetaIndex, MetaDetail, MetaCreate } from '../../views/Meta';
import { BankPromoIndex, BankPromoForm } from '../../views/BankPromo';

class App extends Component {

  render() {
    return (
      <div className="app">
        <Header />
        <div className="app-body">
          <Sidebar {...this.props} />
          <main className="main">
            <Breadcrumb />

            <div className="container-fluid">
              <Switch>

                {/* app_sections  */}
                <Route path="/app_sections/create" name="Create App Section" component={AppSectionCreate} />
                <Route path="/app_sections/:id" name="App Section Detail" component={AppSectionDetail} />
                <Route path="/app_sections" name="App Section List" component={AppSectionIndex} />

                {/* collections  */}
                <Route path="/collections/create" name="Create Collection" component={CollectionCreate} />
                <Route path="/collections/:id" name="Collection Detail" component={CollectionDetail} />
                <Route path="/collections" name="Collection List" component={CollectionIndex} />

                {/* sell_request  */}
                <Route path="/sell_request/create" name="Create Sell & Consignment Requests" component={SellRequestCreate} />
                <Route path="/sell_request/:id" name="Sell Request Detail" component={SellRequestDetail} />
                <Route path="/sell_request" name="Sell & Consignment Requests" component={SellRequests} />

                {/* seller_verification  */}
                <Route path="/seller_verification/:id" name="Seller Verification Detail" component={SellerVerificationDetail} />
                <Route path="/seller_verification" name="Seller Verification" component={SellerVerification} />

                {/* concierge  */}
                <Route path="/concierge/create" name="Concierge Create" component={ConciergeCreate} />
                <Route path="/concierge/pending" name="Concierge Pending" component={ConciergePending} />
                <Route path="/concierge/processed" name="Concierge Process" component={ConciergeProcessed} />
                <Route path="/concierge/completed" name="Concierge Completed" component={ConciergeCompleted} />
                <Route path="/concierge/failed" name="Concierge Failed" component={ConciergeFailed} />
                <Route path="/concierge/:id" name="Concierge Details" component={ConciergeDetail} />
                <Route path="/concierge" name="All Concierge List" component={Concierge} />

                {/* payments  */}
                <Route path="/payments/refund-to-balance" name="Refund to Balance" component={PaymentDisbursement} />
                <Route path="/payments/create" name="Payment Create" component={PaymentCreate} />
                <Route path="/payments/:id" name="Payment Details" component={PaymentDetail} />
                <Route path="/payments" name="Payment" component={Payments} />

                {/* bank_accounts  */}
                <Route path="/bank_accounts/create" name="Bank Account Create" component={AccountBankCreate} />
                <Route path="/bank_accounts/:id" name="Bank Account Detail" component={AccountBankDetail} />
                <Route path="/bank_accounts" name="Bank Account List" component={AccountBankIndex} />

                {/* users  */}
                <Route path="/users/create" name="Create User" component={UserCreate} />
                <Route path="/users/:id" name="User Detail" component={UserDetail} />
                <Route path="/users" name="User List" component={UserIndex} />

                {/* roles  */}
                <Route path="/roles/create" name="Role Create" component={RoleCreate} />
                <Route path="/roles/:id" name="Role Detail" component={RoleDetail} />
                <Route path="/roles" name="Role List" component={RoleIndex} />


                {/* vouchers  */}
                <Route path="/vouchers/create" exact={true} name="Voucher Create" component={VoucherCreate} />
                <Route path="/vouchers/:id" exact={true} name="Voucher Detail" component={VoucherDetail} />
                <Route path="/vouchers" exact={true} name="Voucher List" component={VoucherIndex} />

                {/* raffle group  */}
                <Route path="/raffle_group/create" exact={true} name="Raffle Group Create" component={RaffleGroupCreate} />
                <Route path="/raffle_group/:id" exact={true} name="Raffle Detail" component={RaffleGroupDetail} />
                <Route path="/raffle_group" exact={true} name="Raffle List" component={RaffleGroupIndex} />

                {/* raffles  */}
                <Route path="/raffles/create" exact={true} name="Raffle Create" component={RaffleCreate} />
                <Route path="/raffles/:id" exact={true} name="Raffle Detail" component={RaffleDetail} />
                <Route path="/raffles" exact={true} name="Raffle List" component={RaffleIndex} />
                <Route path="/raffle_requests" exact={true} name="Raffle Request List" component={RaffleRequestIndex} />
                <Route path="/raffle_requests/:id" exact={true} name="Raffle Request Detail" component={RaffleRequestDetail} />
                <Route path="/raffle_tickets" exact={true} name="Raffle Ticket Detail" component={RaffleTicketDetail} />

                {/* vouchers  */}
                <Route path="/brands/create" name="Create Brand" component={BrandCreate} />
                <Route path="/brands/:id" name="Brand Detail" component={BrandDetail} />
                <Route path="/brands" name="Brand List" component={BrandIndex} />


                {/* categories  */}
                <Route path="/categories/create" name="Create Categories" component={CategoriesCreate} />
                <Route path="/categories/:id" name="Categories Detail" component={CategoriesDetail} />
                <Route path="/categories" name="Categories List" component={CategoriesIndex} />


                {/* products  */}
                <Route path="/products/create" name="Create Products" component={ProductCreate} />
                <Route path="/products/:id" name="Products Detail" component={ProductDetail} />
                <Route path="/products" name="Products List" component={ProductIndex} />

                {/* products  */}
                <Route path="/product_addons/create" name="Create Product Addon" component={ProductAddonCreate} />
                <Route path="/product_addons/:id" name="Product Addon Detail" component={ProductAddonDetail} />
                <Route path="/product_addons" name="Product Addon List" component={ProductAddonIndex} />


                {/* product_variants  */}
                <Route path="/product_variants/create" name="Create Product Variant" component={ProductVariantCreate} />
                <Route path="/product_variants/sku_requests" name="SKU Requests" component={SkuRequests} />
                <Route path="/product_variants/:id" name="Product Variant Detail" component={ProductVariantDetail} />
                <Route path="/product_variants" name="Product Variant List" component={ProductVariantIndex} />


                {/* stock  */}
                <Route path="/stock/:id/add" name="Add Product Stock" component={StockProductVariantForm} />
                <Route path="/stock/:id" name="Product Stock Details" component={StockDetail} />
                <Route path="/stock" name="Product Stocks" component={StockProductVariants} />


                {/* product_variants  */}
                <Route path="/sales/all" name="All Sales" component={Monitoring} />
                <Route path="/sales/outstanding" name="Outstanding Sales" component={OutstandingComponent} />
                <Route path="/sales/quality_control" name="Quality Control Sales" component={QualityControl} />
                <Route path="/sales/legit_check" name="Legit Check Sales" component={ShoeVerification} />
                <Route path="/sales/follow-up" name="Delivering Sales" component={FollowUp} />
                <Route path="/sales/pre-delivering" name="Delivering Sales" component={Delivering} />
                <Route path="/sales/jnt-delivering" name="JNT Delivering Sales" component={JntDelivering} />
                <Route path="/sales/instant-delivering" name="Instant Delivering Sales" component={InstantDelivering} />
                <Route path="/sales/warehouse-delivering" name="Instant Delivering Sales" component={WarehouseDelivering} />
                <Route path="/sales/other-delivering" name="Instant Delivering Sales" component={OtherDelivering} />
                <Route path="/sales/processed" name="Processed Sales" component={Processed} />
                <Route path="/sales/failed" name="Failed Sales" component={FailedSales} />
                <Route path="/sales/:id/change_seller" name="Sale Hijacked" component={SaleHijacked} />
                <Route path="/sales/:id" name="Sale Details" component={SaleDetails} />
                <Redirect from="/sales" to="/sales/all" />

                {/* consignment  */}
                <Route path="/consignment/all" name="All Consignment" component={AllConsignment} />
                <Route path="/consignment/outstanding" name="Outstanding Consignment" component={OutstandingConsignment} />
                <Route path="/consignment/quality_control" name="Quality Control Consignment" component={QualityControlConsignment} />
                <Route path="/consignment/legit_check" name="Legit Check Consignment" component={LegitCheckConsignment} />
                {/* <Route path="/consignment/follow-up" name="Delivering Consignment" component={FollowUpConsignment}/> */}
                <Route path="/consignment/rack-assignment" name="Rack Assignment" component={RackAssignmentConsignment} />
                {/* <Route path="/consignment/delivering" name="Delivering Consignment" component={DeliveringConsignment}/> */}
                <Route path="/consignment/pre-delivering" name="Pre Delivering Consignment" component={PreDeliveringConsignment} />
                <Route path="/consignment/jnt-delivering" name="JNT Delivering Consignment" component={JntDeliveringConsignment} />
                <Route path="/consignment/instant-delivering" name="Gosend/Grab Delivering Consignment" component={InstantDeliveringConsignment} />
                <Route path="/consignment/other-delivering" name="Other Delivering Consignment" component={OtherDeliveringConsignment} />
                <Route path="/consignment/warehouse-delivering" name="Warehouse Delivering Consignment" component={WarehouseDeliveringConsignment} />
                {/* <Route path="/consignment/processed" name="Processed Consignment" component={ProcessedConsignment}/> */}
                <Route path="/consignment/failed" name="Failed Consignment" component={FailedConsignment} />
                {/*<Route path="/consignment/:id/change_seller" name="Consignment Hijacked" component={SaleHijacked}/>*/}
                <Route path="/consignment/:id" name="Consignment Details" component={ConsignmentDetails} />
                <Redirect from="/consignment" to="/consignment/all" />


                {/* slider  */}
                <Route path="/sliders/create" name="Create Slider" component={SliderCreate} />
                <Route path="/sliders/:id" name="Slider Detail" component={SliderDetail} />
                <Route path="/sliders" name="Slider List" component={SliderIndex} />

                <Route path="/disbursements/pending" name="Pending Disbursement List" component={PendingDisbursementList} />
                <Route path="/disbursements/completed" name="Completed Disbursement List" component={CompletedDisbursementList} />
                <Route path="/disbursements/create" name="Create a Disbursement" component={DisbursementCreate} />
                <Route path="/disbursements/:id" name="Disbursement Details" component={DisbursementDetails} />
                <Route path="/disbursements" name="Disbursement List" component={DisbursementList} />

                {/* <Route path="/stock/:id/sizes/:size_id" name="Product Stock Seller" component={StockSells}/> */}

                {/* topup  */}
                <Route path="/top_up/:id" name="Top Up Detail" component={TopUpDetail} />
                <Route path="/top_up" name="Top Up List" component={TopUp} />

                {/* midtrans  */}
                <Route path="/midtrans/:id" name="Midtrans Detail" component={MidtransDetail} />
                <Route path="/midtrans" name="Midtrans List" component={MidtransIndex} />

                {/* rewards  */}
                {/* <Route path="/rewards/create" exact={true} name="Reward Create" component={RewardCreate}/> */}
                <Route path="/rewards/:id" exact={true} name="Reward Detail" component={RewardDetail} />
                <Route path="/rewards" exact={true} name="Reward List" component={RewardIndex} />

                {/* menus  */}
                <Route path="/menus/create" name="Create Menu" component={MenuCreate} />
                <Route path="/menus/:id" name="Menu Detail" component={MenuDetail} />
                <Route path="/menus" name="Menu List" component={MenuIndex} />

                <Route path="/offers/:id" exact={true} name="User Offer Detail" component={BidDetail} />
                <Route path="/offers" exact={true} name="User Offer List" component={BidIndex} />

                <Route path="/3rdparty_stock" exact={true} name="Seek Stock" component={SeekStock} />

                {/* rack  */}
                <Route path="/racks" exact={true} name="Rack" component={RackIndex} />
                <Route path="/racks/create" exact={true} name="Rack" component={RackCreate} />
                <Route path="/racks/:id" exact={true} name="Rack Detail" component={RackDetail} />

                {/* warehouse  */}
                <Route path="/warehouse" name="Warehouse" component={Warehouse} />

                {/* point shop  */}
                <Route path="/point_shop/create" exact={true} name="Point Shop Create" component={PointShopCreate} />
                <Route path="/point_shop/:id" exact={true} name="Point Shop Detail" component={PointShopDetail} />
                <Route path="/point_shop" exact={true} name="Point Shop List" component={PointShopIndex} />

                <Route path="/marketing_budgets/create" exact={true} name="Marketing Budget Create" component={MarketingBudgetCreate} />
                <Route path="/marketing_budgets/:id" exact={true} name="Marketing Budget Detail" component={MarketingBudgetDetail} />
                <Route path="/marketing_budgets" exact={true} name="Marketing Budget List" component={MarketingBudgetIndex} />

                {/* spinning wheel */}
                <Route path="/spinning_wheel/create" exact={true} name="Spinning Wheel Create" component={SpinningWheelCreate} />
                <Route path="/spinning_wheel" exact={true} name="Spinning Wheel List" component={SpinningWheelIndex} />
                <Route path="/spinning_wheel/:id" exact={true} name="Spinning Wheel Detail" component={SpinningWheelDetail} />

                {/* pop up banner */}
                <Route path="/pop_up_banner/create" exact={true} name="Pop Up Banner Create" component={PopUpBannerCreate} />
                <Route path="/pop_up_banner" exact={true} name="Pop Up Banner List" component={PopUpBannerIndex} />
                <Route path="/pop_up_banner/:id" exact={true} name="Pop Up Banner Detail" component={PopUpBannerDetail} />

                {/* faq */}
                <Route path="/faqs/create" exact={true} name="FAQ Create" component={FaqCreate} />
                <Route path="/faqs" exact={true} name="FAQ List" component={FaqIndex} />
                <Route path="/faqs/:id" exact={true} name="FAQ Detail" component={FaqDetail} />

                {/* timer */}
                <Route path="/timer/create" exact={true} name="Timer Create" component={TimerCreate} />
                <Route path="/timer" exact={true} name="Timer List" component={TimerIndex} />
                <Route path="/timer/:id" exact={true} name="Timer Detail" component={TimerDetail} />

                {/* videos  */}
                <Route path="/videos/create" exact={true} name="Create Video" component={VideoCreate} />
                <Route path="/videos" exact={true} name="Video List" component={VideoIndex} />
                <Route path="/videos/:id" exact={true} name="Video Detail" component={VideoDetail} />

                {/* survey */}
                <Route path="/survey/create" exact={true} name="Survey Create" component={SurveyCreate} />
                <Route path="/survey" exact={true} name="Survey List" component={SurveyIndex} />
                <Route path="/survey/:id" exact={true} name="Survey Detail" component={SurveyDetail} />

                <Route path="/survey_option/create" exact={true} name="Survey Option Create" component={SurveyOptionCreate} />
                <Route path="/survey_option" exact={true} name="Survey Option List" component={SurveyOptionIndex} />
                <Route path="/survey_option/:id" exact={true} name="Survey Option Detail" component={SurveyOptionDetail} />

                {/* setting */}
                <Route path="/setting/create" exact={true} name="Setting Create" component={SettingCreate} />
                <Route path="/setting" exact={true} name="Setting List" component={SettingIndex} />
                <Route path="/setting/:id" exact={true} name="Setting Detail" component={SettingDetail} />

                {/* countries  */}
                <Route path="/country" name="Country" exact={true} component={CountryIndex} />
                <Route path="/country/update-batch" name="Update Batch Country" exact={true} component={UpdateBatchCountry} />

                {/* push_notification */}
                <Route path="/push_notification/create" exact={true} name="Push Notification Create" component={PushNotificationCreate} />
                <Route path="/push_notification" exact={true} name="Push Notification List" component={PushNotification} />
                <Route path="/push_notification/:id" exact={true} name="Push Notification Detail" component={PushNotificationDetail} />

                {/* article */}
                <Route path="/articles/create" exact={true} name="Article Create" component={ArticleCreate} />
                <Route path="/articles" exact={true} name="Article List" component={ArticleIndex} />
                <Route path="/articles/:id" exact={true} name="Article Detail" component={ArticleDetail} />

                {/* article */}
                <Route path="/metas/create" exact={true} name="Meta Create" component={MetaCreate} />
                <Route path="/metas" exact={true} name="Meta List" component={MetaIndex} />
                <Route path="/metas/:id" exact={true} name="Meta Detail" component={MetaDetail} />

                {/* bank promo */}
                <Route path="/bank_promo" exact={true} name="Bank Promo List" component={BankPromoIndex} />
                <Route path="/bank_promo/create" exact={true} name="Bank Promo Create" component={BankPromoForm} />
                <Route path="/bank_promo/:id" exact={true} name="Bank Promo Detail" component={BankPromoForm} />

                <Route path="/me" name="My Profiles" component={MyProfiles} />

                <Route path="/dashboard" name="Dashboard" component={Dashboard} />
                <Route path="/components/buttons" name="Buttons" component={Buttons} />
                <Route path="/components/cards" name="Cards" component={Cards} />
                <Route path="/components/forms" name="Forms" component={Forms} />
                <Route path="/components/modals" name="Modals" component={Modals} />
                <Route path="/components/social-buttons" name="Social Buttons" component={SocialButtons} />
                <Route path="/components/switches" name="Swithces" component={Switches} />
                <Route path="/components/tables" name="Tables" component={Tables} />
                <Route path="/components/tabs" name="Tabs" component={Tabs} />
                <Route path="/icons/font-awesome" name="Font Awesome" component={FontAwesome} />
                <Route path="/icons/simple-line-icons" name="Simple Line Icons" component={SimpleLineIcons} />
                <Route path="/widgets" name="Widgets" component={Widgets} />
                <Route path="/charts" name="Charts" component={Charts} />
                <Redirect from="/" to="/dashboard" />
              </Switch>
            </div>
          </main>
          <Aside />
        </div>
        <Footer />
        <ButtonBackToTop scrollStepInPx="50" delayInMs="16.66" />
      </div>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  return {
    authData: auth
  }
}
export default connect(mapStateToProps, null)(withAuthorized(App));
