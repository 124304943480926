import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import {
    Row,
    Col,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Card,
    CardHeader,
    CardBody,
    Form,
    FormGroup,
    Label,
    Input,
    Button,
    ButtonGroup,
    Alert
} from 'reactstrap';

import { 
    getDetailProduct,
    saveProduct,
    deleteProduct,
    newProduct 
} from '../../actions/productAction';

import {
    getBrandList
}
from '../../actions/brandAction';
import {
    getOptionList as getCategoryOptionList
}
from '../../actions/categoriesAction';

import { ValidationMessage } from '../../components/Form/ValidationMessage';

import Select from 'react-select';
import 'react-select/dist/react-select.css';
import ProductAuditList from './productForm/ProductAuditList';

class ProductForm extends Component {
    constructor(props){
        super(props);
        this.state={
            _parentBrandOptions:[],
            _childBrandOptions:[],
            _edit:this.props.edit,
            _readOnly:this.props.readOnly,
            _hasId:this.props.match.params.id?true:false,
            _form:{
                binding:false,
                active:false,
                id:this.props.match.params.id,
                audits: []
            },
            _params: {
				active : 1,
			},
            _formSubmit:false,
            _modalDelete:false
        }
        this.handleCheckedChange = this.handleCheckedChange.bind(this)
    }

    componentDidMount(){
        if(this.state._hasId)
            this.props.find(this.state._form.id);
        else
        {
            this.props.new();            
            this.props.bindBrandOption({params:{...this.state._params}});
        }
        this.props.bindCategoryOption();
    }

    componentWillReceiveProps(nextProps){
        const {detail,isFetch,isSubmit,error} = nextProps.product;
        if(!nextProps.masterBrand.isFetch&&this.props.masterBrand.isFetch){
            if(nextProps.masterBrand.list.status_code===200&&nextProps.masterBrand.error===null){
                const {_parentBrandOptions,_childBrandOptions} = this.state;
                const parents=nextProps.masterBrand.list.data.map(item=>{
                    return {value:item.id,label:item.name,item}
                });
                parents.unshift({value:'',label:'Select a brand',item:null});
                const childs=[{value:'',label:'Select a brand',item:null}];
                if(this.state._form.parent_brand_id!==null){
                    const filtered=parents.filter(item => item.value==this.state._form.parent_brand_id);
                    if(filtered.length){
                        if(filtered[0].item.children.length){
                            const childFromParent=filtered[0].item.children.forEach(item=>{
                                childs.push({value:item.id,label:item.name,item})
                            })
                        }
                    }
                }
                this.setState({_parentBrandOptions:parents,_childBrandOptions:childs})
            }
        }
        if(this.state._edit)
        {
            // After get detail product
            if(detail.status_code===200&&!isFetch&&!this.state._form.binding)
            {
                const { _form }=this.state;
                _form.binding=!_form.binding;
                _form.audits = detail.audits;
                this.setState({_form},()=>{
                    const {detail} = nextProps.product;
                    var parent_brand_id,child_brand_id='';
                    if(detail.brand_id!==null){
                        if(detail.brand.parent_id!==null){
                            parent_brand_id=detail.brand.parent_id;
                            child_brand_id=detail.brand_id;
                        }
                        else{
                            parent_brand_id=detail.brand_id;
                        }
                    }
                    this.props.bindBrandOption({params:{...this.state._params}});
                    this.setState({_form:{...this.state._form,...nextProps.product.detail,parent_brand_id,child_brand_id}});
                });
            }
        }
        else{
            // Checking if already ok after submit 
            if(detail.status_code===200 
                &&error===null
                &&this.state._formSubmit
                && !isSubmit)
            {
                this.setState({_form:{...this.state._form,...this.clearForm()},_formSubmit:!this.state._formSubmit});
            }
        }
        
    }

    clearForm(){
        return {name:'',category_id:'',brand_id:'', active:false};
    }

    /**
	 * Handle to change local state from input uset like textfield
	 * @author pewe
	 * @param {event} e 
	 */
	handleChange(e){
		const newState={...this.state._form};
        newState[e.target.name]=e.target.value;
		this.setState({_form:newState});
    }

    handleCheckedChange(e) {
        const newState = { ...this.state._form }
        newState[e.target.name] = e.target.checked
        this.setState({ _form: newState })
      }

    validateError(name){
        if(this.hasError(name))
        {
            let errorList=this.props.product.error.errors;
            return <ValidationMessage message={errorList[name][0]}/>
        }
        return '';
    }
    
    hasError(name){
        if(this.props.product.error)
        {
            if(this.props.product.error.errors)
            {
                let errorList=this.props.product.error.errors;
                let errList=Object.keys(errorList).filter(key=> key==name);
                if(errList.length)
                return true
            }
        }
        return false
    }

    toggle(){
        this.setState({_modalDelete:!this.state._modalDelete});        
    }

    onDelete(){
        this.props.delete(this.state._form.id);
        this.toggle();

    }

    onSubmit(e){
        e.preventDefault();
        this.setState({_formSubmit:true},()=>{
            const {id,parent_brand_id,child_brand_id,category_id,name, active}= this.state._form;
            this.props.save({parent_brand_id,child_brand_id,category_id,name, active},id);
        })
    }

    renderInfo(){
        let infoMessage=[];
        if(this.props.product.isSubmit)
            infoMessage.push(
                <Alert color='info' key="info"><strong>Heads up!</strong> Submitting...</Alert>
            );
        if(this.props.product.success)
            infoMessage.push(
                <Alert color='success' key="success"><strong>Well done!</strong> { this.props.product.success }</Alert>
            );
        if(this.props.product.error!==null)
        {
            const {status_code,message} = this.props.product.error;
            switch(status_code)
            {
                case 422:
                    infoMessage.push(
                        <Alert color='danger' key="error"><strong>Oh Snap!</strong> Please fullfill your form. </Alert>
                    );
                    break;
                case 404:
                    infoMessage.push(
                        <Alert color='danger' key="error"><strong>Oh Snap!</strong> Resource not found. </Alert>
                    );
                    break;
                default:
                    infoMessage.push(
                        <Alert color='danger' key="error"><strong>Oh Snap!</strong> We've got something errors </Alert>
                    );
                    break;
            }
        }
        return(
            <div className="row">
                <div className="col-sm-12">
                    {infoMessage}   
                </div>
            </div>
        )
    }

    renderLoading(){
        return (
			<div className="row">
				<div className="col-sm-12">
                    <Alert color='info' className="text-center">Getting all data...</Alert>
				</div>
			</div>
		)
    }

    onSelectParentChange(val, attributeForm){
        console.log('Parent Change',val);
        if(val===null){
            const { _form } =this.state;
            _form.child_brand_id='';
            this.setState({_form,_childBrandOptions:[{value:'',label:'Select a brand',item:null}]})
        }
        else{
            if(val.val===''){
                const { _form } =this.state;
                _form.child_brand_id='';
                this.setState({_form,_childBrandOptions:[{value:'',label:'Select a brand',item:null}]})
            }
            else{
                const { _form } =this.state;
                _form.child_brand_id='';
                const childs=[{value:'',label:'Select a brand',item:null}];
                if(val.item!==null){
                    if(val.item.children.length){
                        val.item.children.forEach(item=>{
                            childs.push({value:item.id,label:item.name,item})
                        });                        
                    }
                }
                this.setState({_form,_childBrandOptions:childs})
            }
        }
        this.onSelectChange(val, attributeForm);
    }

    onSelectChildChange(val, attributeForm){
        this.onSelectChange(val, attributeForm);        
    }

    onSelectChange(val, attributeForm){
        const { _form } =this.state;
        _form[attributeForm]=val===null?val:val.value;
        this.setState({_form});
    }

    render(){
        if(this.props.product.detail.delete===true)
            return <Redirect to='/products'/>
        return (
            <Row>
                <Modal isOpen={this.state._modalDelete} toggle={this.toggle.bind(this)} className="modal-dialog modal-sm">
                    <ModalHeader toggle={this.toggle.bind(this)}>Confirmation</ModalHeader>
                    <ModalBody>
                        Are you sure to delete this?
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.onDelete.bind(this)}>Yes</Button>{' '}
                        <Button color="secondary" onClick={this.toggle.bind(this)}>Cancel</Button>
                    </ModalFooter>
                </Modal>
                <div className="col-md-6 offset-md-3">
                    <Card>
                        <CardHeader>
                            <i className="fa fa-align-justify"></i> {this.props.title}
                        </CardHeader>
                        <CardBody>
                            { this.renderInfo() }
                            {
                                this.props.product.isFetch?
                                    this.renderLoading()
                                :
                                <Form onSubmit={this.onSubmit.bind(this)}>
                                    <Row>
                                        <Col xs={12} md={6}>
                                            <FormGroup className={this.hasError('parent_brand_id')?' has-danger has-feedback':''}>
                                                <Label for="formBrandName">Brand</Label>
                                                <Select
                                                    id="formBrandName"
                                                    name="parent_brand_id"
                                                    value={this.state._form.parent_brand_id||''}
                                                    options={this.state._parentBrandOptions}
                                                    onChange={(val)=>{this.onSelectParentChange(val,'parent_brand_id')}}
                                                />
                                                {this.validateError('parent_brand_id')}
                                            </FormGroup>
                                        </Col>
                                        <Col xs={12} md={6}>
                                            <FormGroup className={this.hasError('child_brand_id')?' has-danger has-feedback':''}>
                                                <Label for="formBrandName">Child Brand</Label>
                                                <Select
                                                    id="formBrandName"
                                                    name="child_brand_id"
                                                    value={this.state._form.child_brand_id||''}
                                                    options={this.state._childBrandOptions}
                                                    onChange={(val)=>{this.onSelectChildChange(val,'child_brand_id')}}
                                                />
                                                {this.validateError('child_brand_id')}
                                            </FormGroup>
                                        </Col>
                                        <Col xs={12} md={6}>
                                            <FormGroup className={this.hasError('category_id')?' has-danger has-feedback':''}>
                                                <Label for="formCategoryName">Category</Label>
                                                <Select
                                                    id="formCategoryName"
                                                    name="category_id"
                                                    value={this.state._form.category_id||''}
                                                    options={this.props.categoryOptions.length?this.props.categoryOptions:[{value:'',label:'Loading...'}]}
                                                    onChange={(val)=>{this.onSelectChange(val,'category_id')}}
                                                />                                        
                                                {this.validateError('category_id')}
                                            </FormGroup>
                                        </Col>
                                    </Row>                                    
                                    <FormGroup className={this.hasError('name')?' has-danger has-feedback':''}>
                                        <Label for="formProductName">Product Name</Label>
                                        <Input
                                            type="text"
                                            id="formProductName"
                                            name="name"
                                            placeholder="Enter a name*"
                                            value={this.state._form.name||''}
                                            onChange={this.handleChange.bind(this)}
                                        />
                                        {this.validateError('name')}
                                    </FormGroup>
                                    <FormGroup className={this.hasError('active')?' has-danger has-feedback':''}>
                                        <Label for="formProductActive">Set to Active &nbsp;</Label>
                                        <label className="switch switch-text switch-success-outline-alt">
                                            <input
                                            type="checkbox"
                                            className="switch-input"
                                            name="active"
                                            id="formProductActive"
                                            value="true"
                                            checked={this.state._form.active}
                                            onChange={this.handleCheckedChange}
                                            />
                                            <span
                                            className="switch-label"
                                            data-on="Yes"
                                            data-off="No"
                                            ></span>
                                            <span className="switch-handle"></span>
                                        </label>
                                        {this.validateError('active')}
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>Created At</Label>
                                        <Input
                                            type="text"
                                            readOnly
                                            value={this.state._form.created_at||''}
                                        />                                    
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>Updated At</Label>
                                        <Input
                                            type="text"
                                            readOnly
                                            value={this.state._form.updated_at||''}
                                        />                                    
                                    </FormGroup>
                                    <FormGroup>
                                        <ButtonGroup>
                                            <Button  color="primary">Submit</Button>
                                            {/* {
                                                this.state._hasId?
                                                <Button  color="danger" onClick={this.toggle.bind(this)}>Delete</Button>
                                                :
                                                ''
                                            } */}
                                        </ButtonGroup>
                                    </FormGroup>
                                </Form>
                            }
                        </CardBody>
                    </Card>
                </div>
                {this.state._edit && (
                    <div className='col-12'>
                    <ProductAuditList
                        id={this.state._form.id}
                        type="product"
                        refresh={!this.props.product.isSubmit}
                    />
                    </div>
                )}
            </Row>
        )
    }
}
const mapStateToProps= (state) => {
	return {
        product:state.product,
        masterBrand:state.masterBrand,
        categoryOptions:state.masterCategories.options
	}
};
const mapDispatchToProps = (dispatch) => {
	return{
		find:(id) => dispatch(getDetailProduct(id)),
		delete:(id) => dispatch(deleteProduct(id)),
		save:(payload,id) => dispatch(saveProduct(payload,id)),
        new:() => dispatch(newProduct()),
        bindBrandOption:(query)=>dispatch(getBrandList(query)),
        bindCategoryOption:()=>dispatch(getCategoryOptionList())
	}

};
export default connect(mapStateToProps,mapDispatchToProps)(ProductForm);