import React, { Component } from "react";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { 
    Row,
    Col,
    Card,
    CardBody,
    CardText,
    Button
} from 'reactstrap';

import {Tbl, TblHead, TblBody} from '../../components/Tbl';
import Paginations from '../../components/Paginations';
import PaginationSummaries from '../../components/Paginations/PaginationSummaries';
import FilterPanel from './Filters';
import FilterContainer from '../../components/Container/FilterContainer/FilterContainer';
import { CardHeaderWithToolbars,Title } from '../../components/Card/CardHeaderWithToolbars';
import Toolbars from './Filters/Toolbars'
import DateToHuman from '../../components/DateToHuman';

import { generateNumber } from '../../utils';
import privateView from '../../components/hocs/privateView';
import Analytics from "../../services/Analytics";
import CheckReadOnly from "../../components/CheckReadOnly";
import { getCountryList, updateInternationalShipping } from "../../actions/countryAction";
import InternationalShippingModal from "./Modal/InternationalShippingModal";

const dataHeader=[
  'No.', 'Name','Code','Phone Code', 'Active','Updated At'
];

const defaultParams = {
  sort_by : "zoneId_asc"
}

class CountryIndex extends Component {
  constructor() {
    super();
    this.state = {
      _params: {
        ...defaultParams,
      },
      _filterOpen: false,
      _countryModal: false
    };
    this._handleToggleFilterPanel = this._handleToggleFilterPanel.bind(this);
    this._handleParamsChange = this._handleParamsChange.bind(this);
    this._handleParamsChangeAndSubmit = this._handleParamsChangeAndSubmit.bind(
      this
    );
    this._handleGoto = this._handleGoto.bind(this);
    this._handleRefresh = this._handleRefresh.bind(this);
    this._handleResetFilter = this._handleResetFilter.bind(this);
    this._handleApplyFilter = this._handleApplyFilter.bind(this);
    this._countryModalToggle = this._countryModalToggle.bind(this);
    this._setInternationalShipping = this._setInternationalShipping.bind(this);
  }

  componentDidUpdate(prevProps){
    if (prevProps.country.isSubmit != this.props.country.isSubmit && this.props.country.success) {
      this._countryModalToggle()
      this._refreshList()
    }
  }

  componentDidMount() {
    const { email } = this.props.injectedProps;
    this._getList(this.state._params);
    Analytics.recordPageView("country_list", email, {
      url: window.location.href,
      location: this.props.location,
      match: this.props.match,
    });
  }

  _countryModalToggle() {
    this.setState({ _countryModal: !this.state._countryModal })
  }

  _setInternationalShipping(bool = true) {
    this.props.updateActive({
      active: bool
    });
  }

  _toggleFilterPanel() {
    this.setState({ _filterOpen: !this.state._filterOpen });
  }

  _handleToggleFilterPanel(e) {
    e.preventDefault();
    this._toggleFilterPanel();
  }

  _handleApplyFilter(payloadFilter){
    this.setState({
      _params: {
        ...this.state._params,
        ...payloadFilter,
        page: 1
      }
    },()=>this._getList(this.state._params));
  }

  _onRenderRowItem(){
    if(this.props.country.list.status_code===200 && this.props.country.list.data?.length>0){
      const { current_page,per_page } =  this.props.country.list;
      return this.props.country.list.data.map((item,index)=>
        (
          <tr key={generateNumber(per_page,current_page,index)}>
            <th scope="row">{ generateNumber(per_page,current_page,index) }</th>	
            <td>{ item.name }</td>
            <td>{ item.code3 || item.code }</td>
            <td>{ item.phone_code }</td>
            <td className="v-center"><CheckReadOnly check={item.active}/></td>
            <td>{ <DateToHuman value={item.updated_at} id={`tooltip_updatedAt_${generateNumber(per_page,current_page,index)}`}/> }</td>
          </tr>
        )
      )	
    }
    return null;
  }

   /**
	 * Function to handle rendering paginations
	 * @author haikal
	 */
  _onRenderPagination(){
    const { list } = this.props.country;
    const hasData = (list.status_code===200&&list.data?.length)||null;
    return hasData && (
      <Paginations 
        size="sm" 
        lastPage={this.props.country.list.last_page} 
        perPage={this.props.country.list.per_page}
        total={this.props.country.list.total}
        maxPage={8}
        path={this.props.country.list.path}
        currentPage={this.props.country.list.current_page} 
        prevPageUrl={this.props.country.list.prev_page_url} 
        nextPageUrl={this.props.country.list.next_page_url} 
        handleGotoCallback={this._handleGoto}
      />
    )
  }

	/**
	 * Handle callback when user click pagination items
	 * @author haikal
	 * @param {integer} page 
	 */
	_handleGoto(page){
    const _params=this.state._params;
    _params.page=page;
    this.setState({_params},()=>this._getList(this.state._params));
  }

  _getList(rawParams = {}){
    let params = {};
    Object.keys(rawParams)
    .filter(key => rawParams[key]!='')
    .forEach(key=> params[key] = rawParams[key]);
    this.props.getList({params});
  }

  _refreshList(){
    const {page, ..._params} = this.state._params;
    this.setState({
      _params
    },()=>this._getList(this.state._params));
  }

  _handleParamsChange(e){
    const { _params } = this.state;
    _params[e.target.name] = e.target.value;
    this.setState({_params});
  }

  _handleParamsChangeAndSubmit(e){
    // Exclude page...
    const { page , ..._params } = this.state._params;
    _params[e.target.name] = e.target.value;
    this.setState({_params},()=>{
      this._getList(this.state._params);
    });    
  }

  _handleDisplayChange(e){
    const { _params } = this.state;
    _params[e.target.name] = e.target.value;
    _params.page = 1;
    this.setState({_params},()=>{
      this._getList(this.state._params);
    });    
  }

  _handleRefresh(e){
    e.preventDefault();
    this._refreshList();
  }

  _handleResetFilter(e){
    e.preventDefault();
    const newState = this.state._filterOpen?{_filterOpen:!this.state._filterOpen, _isResetFilter: true}:{};
    this.setState({
      ...newState,
      _params:{
        ...defaultParams
      }
    },()=>this._getList(this.state._params));
  }
    
  render(){
    const { country } = this.props
    const totalActive = country.list.status_code===200 && country.list.total_active || 0
    const totalCountry = country.list.status_code===200 && country.list.total
    return(
      <div className="animated fadeIn">
        <Row>
          <Col xs={12}>
            <Card>
              <CardHeaderWithToolbars>
                <Title>
                  <i className="fa fa-align-justify"></i>
                  <span>Country List</span>
                </Title>
                <Toolbars
                  isOpen={this.state._filterOpen}
                  onToggleFilter={this._handleToggleFilterPanel}
                  onRefreshList={this._handleRefresh}
                  onResetFilter={this._handleResetFilter}
                />
              </CardHeaderWithToolbars>
              <FilterContainer>
                <FilterPanel 
                  {...this.state._params}
                  onApplyFilter={this._handleApplyFilter} 
                  onHandleChange={this._handleParamsChange}
                  onHandleChangeAndSubmit={this._handleParamsChangeAndSubmit}
                  onHandleSearch={this._handleRefresh}
                  isReset={this.state._isResetFilter}
                  isOpen={this.state._filterOpen}
                />
              </FilterContainer>
              <CardBody>
                <CardText>
                  <div>
                  In this section will display Country list.
                  </div>
                  <div className="text-right">
                    <Link to={'/country/update-batch'}><Button color="info" className="mr-2">Update</Button></Link>
                    <Button color="success" onClick={this._countryModalToggle}>{totalActive === totalCountry ? 'Disable All' : 'Enable All'}<i className="fa fa-globe ml-1"></i></Button>
                  </div>
                </CardText>
                <div className="table-responsive">
                  <PaginationSummaries
                    from={country.list.from}
                    to={country.list.to}
                    total={country.list.total}
                  />
                  <Tbl>
                    <TblHead
                      dataHeader={dataHeader}
                    />
                    <TblBody
                      isLoad={country.isFetch}
                      hasRow={country.list.status_code===200 && country.list.data?.length>0}
                      columnCount={dataHeader.length}
                    >
                    { this._onRenderRowItem() }
                    </TblBody>
                  </Tbl>
                </div>
                { this._onRenderPagination() }
              </CardBody>
            </Card>
          </Col>
        </Row>

        <InternationalShippingModal 
          size="sm"
          isOpen={this.state._countryModal}
          toggle={this._countryModalToggle}
          toggleActive={this._setInternationalShipping}
          totalActive={totalActive}
          totalCountry={totalCountry}
          country={this.props.country}
        />
      </div>
    )
  }
}

const mapStateToProps= ({ country, auth: { isFetch, roles, isLogged, id, email } }) => {
	return {
    country,
    guardData: { isFetch, roles, isLogged, id, email }
	}
};
const mapDispatchToProps = (dispatch) => {
	return{
		getList:(query) => dispatch(getCountryList(query)),
    updateActive:(payload) => dispatch(updateInternationalShipping(payload))
	}

};
const enhance = connect(mapStateToProps,mapDispatchToProps);

export default enhance(privateView(CountryIndex));
