import React from 'react';

import {
    Table
} from 'reactstrap';

const Tbl = ({ children, data, emptyRowText, isLoad })=>{
    return (
        <Table striped>
            {children}
        </Table>
    )
}
export default Tbl;
