import {
  RACK_ROUTE_API,
  RACK_REQUEST,
  RACK_ERROR,
  GET_LIST_RACK,
  UPDATE_RACK,
  GET_DETAIL_RACK,
  CREATE_RACK
} from '../constants';
import {
  onRequest,
  onSuccessRequest,
  onFailedRequest
} from './helpers';
import { apiClient } from '../utils/Api';


export const getRackList = (q) => (
  dispatch => {
    dispatch(onRequest(RACK_REQUEST, GET_LIST_RACK, true));
    apiClient()
    .get(RACK_ROUTE_API, q)
    .then(res => {
      dispatch(onSuccessRequest(GET_LIST_RACK, res.data));
      dispatch(onRequest(RACK_REQUEST, GET_LIST_RACK, false));
    })
    .catch(err => {
      dispatch(onFailedRequest(RACK_ERROR, GET_LIST_RACK, err));
      dispatch(onRequest(RACK_REQUEST, GET_LIST_RACK, false));
    })
  }
)

export const getRackById = id => (
  dispatch => {
    dispatch(onRequest(RACK_REQUEST, GET_DETAIL_RACK, true));
    apiClient()
    .get(`${RACK_ROUTE_API}/${id}`)
    .then(res => {
      dispatch(onSuccessRequest(GET_DETAIL_RACK, res.data));
      dispatch(onRequest(RACK_REQUEST, GET_DETAIL_RACK, false));
    })
    .catch(err => {
      dispatch(onFailedRequest(RACK_ERROR, GET_DETAIL_RACK, err, id));
      dispatch(onRequest(RACK_REQUEST, GET_DETAIL_RACK, false));
    });
  }
)

export const saveRack = payload => (
  dispatch => {
    const { id, ...body } = payload;
    return id? dispatch(updateRack(id, body)): dispatch(createRack(body))
  }
)

export const updateRack = (id, p) =>{
	return dispatch => {
    dispatch(onRequest(RACK_REQUEST, UPDATE_RACK, true));
    apiClient()
		.put(`${RACK_ROUTE_API}/${id}`, p)
		.then(res=>{
			dispatch(onSuccessRequest(UPDATE_RACK, res.data));
			dispatch(onRequest(RACK_REQUEST, UPDATE_RACK, false));
		})
		.catch(err => {
			dispatch(onFailedRequest(RACK_ERROR, UPDATE_RACK, err));
			dispatch(onRequest(RACK_REQUEST, UPDATE_RACK, false));
		})
	}
}

export const createRack = p => (
  dispatch => {
    dispatch(onRequest(RACK_REQUEST, CREATE_RACK, true));
    apiClient()
    .post(RACK_ROUTE_API, p)
    .then(res => {
      dispatch(onSuccessRequest(CREATE_RACK, res.data));
      dispatch(onRequest(RACK_REQUEST, CREATE_RACK, false));
    })
    .catch(err => {
      dispatch(onFailedRequest(RACK_ERROR, CREATE_RACK, err));
      dispatch(onRequest(RACK_REQUEST, CREATE_RACK, false));
    });
  }
)

export const attachRack = (id,p) => (
  dispatch => {
    dispatch(onRequest(RACK_REQUEST, CREATE_RACK, true));
    apiClient()
    .post(`${RACK_ROUTE_API}/${id}/sells`, p)
    .then(res => {
      dispatch(onSuccessRequest(CREATE_RACK, res.data));
      dispatch(onRequest(RACK_REQUEST, CREATE_RACK, false));
    })
    .catch(err => {
      dispatch(onFailedRequest(RACK_ERROR, CREATE_RACK, err));
      dispatch(onRequest(RACK_REQUEST, CREATE_RACK, false));
    });
  }
)

export const detachRack = (id,p) => (
  dispatch => {
    dispatch(onRequest(RACK_REQUEST, CREATE_RACK, true));
    apiClient()
    .put(`${RACK_ROUTE_API}/${id}/sells`, p)
    .then(res => {
      dispatch(onSuccessRequest(CREATE_RACK, res.data));
      dispatch(onRequest(RACK_REQUEST, CREATE_RACK, false));
    })
    .catch(err => {
      dispatch(onFailedRequest(RACK_ERROR, CREATE_RACK, err));
      dispatch(onRequest(RACK_REQUEST, CREATE_RACK, false));
    });
  }
)

