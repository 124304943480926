import {
  OFFER_REPORT_ROUTE_API,
  OFFER_REPORT_ERROR,  
  OFFER_REPORT_CLEAR,  
  OFFER_REPORT,
  OFFER_ROUTE_API,
	GET_LIST_OFFER,
	FETCHING_OFFER,
	GET_DETAIL_OFFER,
	UPDATE_OFFER,
	CREATE_OFFER,
	DISBURSEMENT_OFFER,
	OFFER_ERROR,
	AUTH_TOKEN_KEY,
	GET_PAYMENTS_MIDTRANS_DETAIL,
	FETCHING_PAYMENTS_MIDTRANS_DETAIL,
	PAYMENTS_MIDTRANS_DETAIL_ERROR
} from '../constants';

import { logout } from './authAction';

import api from '../utils/Api';
import { manipulateErrors, checkAuthorized } from '../utils';

/**
 * Get list of offers
 * @author pewe
 * @guards jwt-token 
 * @param {object?} query 
 */
export const getOfferList=(query)=>{
	return dispatch =>(
		dispatch(isFetch({type:'fetch',status:true})),
		api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).get(OFFER_ROUTE_API,query)
		.then(res=>{
			dispatch(onSuccess(res.data,GET_LIST_OFFER))
			dispatch(isFetch({type:'fetch',status:false}))
		})
		.catch(err => {
			dispatch(onError(err));
			dispatch(isFetch({type:'fetch',status:false}));
		})
	)
}

/**
 * Get detail of Offer
 * @guards jwt-token
 * @param {integer} id 
 */
export const getDetailOffer=(id)=>{
	return dispatch =>(
		dispatch(isFetch({type:'fetch',status:true})),
		api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).get(`${OFFER_ROUTE_API}/${id}`)
		.then(res=>{
			dispatch(onSuccess(res.data,GET_DETAIL_OFFER))
			dispatch(isFetch({type:'fetch',status:false}))
		})
		.catch(err => {
			dispatch(onError(err));
			dispatch(isFetch({type:'fetch',status:false}));
		})
	)
}

export const createOffer = (payload) =>{
	return dispatch => (
		dispatch(isFetch({type:'submit',status:true})),
		api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).post(`${OFFER_ROUTE_API}`,payload)
		.then(res=>{
			dispatch(onSuccess(res.data,CREATE_OFFER))
			dispatch(isFetch({type:'submit',status:false}))
		})
		.catch(err => {
			dispatch(onError(err));
			dispatch(isFetch({type:'submit',status:false}));
		})
	)
}

/**
 * Update an offer
 * @author pewe
 * @guards jwt-token
 * @param {integer} id 
 * @param {object} payload 
 */
export const updateOffer = (id,payload) =>{
	return dispatch => (
		dispatch(isFetch({type:'submit',status:true})),
		api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).put(`${OFFER_ROUTE_API}/${id}`,payload)
		.then(res=>{
			dispatch(onSuccess(res.data,UPDATE_OFFER))
			dispatch(isFetch({type:'submit',status:false}))
		})
		.catch(err => {
			dispatch(onError(err));
			dispatch(isFetch({type:'submit',status:false}));
		})
	)
}

export const disburshOffer = (id,payload) =>{
	return dispatch => (
		dispatch(isFetch({type:'submit',status:true})),
		api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).post(`${OFFER_ROUTE_API}/${id}/disbursement`,payload)
		.then(res=>{
			dispatch(onSuccess(res.data,DISBURSEMENT_OFFER))
			dispatch(isFetch({type:'submit',status:false}))
		})
		.catch(err => {
			dispatch(onError(err));
			dispatch(isFetch({type:'submit',status:false}));
		})
	)
}

/**
 * Post an report offer
 * @author sopyan
 * @guards jwt-token
 * @param {object} payload
 */
export const reportOffer = (payload) =>{
	return dispatch => (
		dispatch({type:OFFER_REPORT_CLEAR,payload:true}),
		dispatch(isFetch({type:'submit',status:true})),
		api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).post(`${OFFER_REPORT_ROUTE_API}`,payload)
		.then(res=>{
			dispatch(onSuccess(res.data,OFFER_REPORT))
			dispatch(isFetch({type:'submit',status:false}))
		})
		.catch(err => {
			dispatch(onError(err,OFFER_REPORT_ERROR));
			dispatch(isFetch({type:'submit',status:false}));
		})
	)
}

export const doGetMidtransPaymentDetail = (invoice) =>{
	return dispatch => (
		dispatch(isFetch({type:FETCHING_PAYMENTS_MIDTRANS_DETAIL,status:true})),
		api().get(`/midtrans/${invoice}/status`)
		.then(res=>{
			dispatch(onSuccess(res.data,GET_PAYMENTS_MIDTRANS_DETAIL))
			dispatch(isFetch({type:FETCHING_PAYMENTS_MIDTRANS_DETAIL,status:false}))
		})
		.catch(err => {
			dispatch(onError(err,PAYMENTS_MIDTRANS_DETAIL_ERROR));
			dispatch(isFetch({type:FETCHING_PAYMENTS_MIDTRANS_DETAIL,status:false}));
		})
	)
}

/**
 * Indicates when start fetch api or end fetch api
 * @author pewe
 * @param {boolean} bool 
 */
export const isFetch=(bool)=>(
	{
		type:FETCHING_OFFER,
		payload:bool		
	}
)

/**
 * When onSuccess triggered
 * @author pewe
 * @param {object} res 
 * @param {string} type 
 */
export const onSuccess=(res,type)=>
(
	{
		type:type,
		payload:res		
	}
)
/**
 * When error fetch or connect to api
 * @author pewe
 * @param {object} err 
 */
// On Error fetching api
export const onError=(err, type = OFFER_ERROR)=>{
	if(!checkAuthorized(manipulateErrors(err)))
		return logout();
	return {
		type,
		payload:manipulateErrors(err)
	}
}