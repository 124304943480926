import React, {useState} from 'react';
import {CardBody, Collapse} from "reactstrap";
import {ExportForm} from "../";
import {usePostReport} from "../../../Hooks/post/usePostReport";

export default function Exports(props) {
    const {
        isOpen,
    } = props;

    const {postReport} = usePostReport();

    const [startedAt, setStartedAt] = useState('');
    const [endedAt, setEndedAt] = useState('');
    const [keyword, setKeyword] = useState('');
    const [seller, setSeller] = useState({});
    const [category, setCategory] = useState('');
    const [shippingMethod, setShippingMethod] = useState('');

    const handleOnChangeInput = (val, key) => {
        switch (key) {
            case 'startedAt':
                setStartedAt(val);
                break;
            case 'endedAt':
                setEndedAt(val);
                break;
            case 'keyword':
                setKeyword(val);
                break;
            case 'category':
                setCategory(val);
                break;
            case 'shippingMethod':
                setShippingMethod(val);
                break;
        }
    };

    const handleSearchButtonClick = (e) => {
        e.preventDefault();

        const payload = {
            started_at: startedAt,
            ended_at: endedAt,
            keyword: keyword,
            seller_id: seller.id,
            category,
            seller_courier: shippingMethod,
        }

        postReport(payload).then(res => {
            console.log(res);
        })
    };

    return (
        <Collapse isOpen={isOpen}>
            <CardBody className="filter-container__card-body">
                <ExportForm
                    startAt={startedAt}
                    endAt={endedAt}
                    keyword = {keyword}
                    seller={seller}
                    category={category}
                    shippingMethod={shippingMethod}
                    onSellerChange={setSeller}
                    onInputChange = {handleOnChangeInput}
                    onSubmit = {handleSearchButtonClick}
                />
            </CardBody>
        </Collapse>
    );
}
