import {
	GET_LIST_SALE,
	GET_LIST_PROCESSED_SALE,
	FETCHING_SALE,
	GET_DETAIL_SALE,
	UPDATE_SALE,
	REPORT_CLEAR,
	REPORT_SALE,
	REPORT_SALE_ERROR,
	SALE_ERROR,

	GET_LIST_PENDING_PROCESSED_SALE,
	FETCHING_PENDING_PROCESSED_SALE,
	PENDING_PROCESSED_SALE_ERROR,

	GET_LIST_COMPLETED_SALE,
	FETCHING_COMPLETED_SALE,
	COMPLETED_SALE_ERROR,


	SELLER_ACCEPT_SALE,
	SELLER_DECLINE_SALE,
	ADMIN_RECEIVED_SNEAKER_SALE,
	LEGIT_PASSED_SALE,
	LEGIT_FAILED_SALE,
	ADMIN_DELIVERING_SNEAKER_SALE,
	COMPLETION_BY_ADMIN_SALE,

	DISBURSEMENT_TO_SELLER_SALE,
	AUTODISBURSEMENT_TO_SELLER_SALE,
	DISBURSEMENT_TO_BUYER_SALE,
	UPDATE_AWB_NUMBER_SALE,
	UPDATE_HIJACKED_SALE,
  UPDATE_FORCE_CANCELLED_SALE,
  GET_LIST_OUTSTANDING_CONFIRMATION,
  FETCHING_OUTSTANDING_CONFIRMATION,
  OUTSTANDING_CONFIRMATION_ERROR,
  GET_LIST_OUTSTANDING_DELIVERY,
  FETCHING_OUTSTANDING_DELIVERY,
  OUTSTANDING_DELIVERY_ERROR,
  FETCHING_DETAILS_BIN,
  GET_DETAILS_BIN,
	BIN_ERROR,

	SALE_IMAGE_S3,
	SALE_IMAGES_REQUEST,
	GET_SALE_IMAGES,
	SALE_IMAGES_ERROR,
	CREATE_SALE_IMAGES,
	DELETE_SALE_IMAGES,

	FETCHING_SALE_NOTES,
	GET_SALE_NOTES,
	SALE_NOTES_SUBMIT,
	READ_SALE_NOTES,

	FETCHING_DETAILS_SALE,
	GET_DETAIL_SALE_2,

	GET_FOLLOWUP_SALES,
	UPDATE_FOLLOWUP_SALES,
	FETCH_FOLLOWUP_SALES,
	GET_FOLLOWUP_APPROVAL_SALES,
	FETCH_FOLLOWUP_APPROVAL_SALES,
	GET_TRACKING_SHIPMENT,
	TRACKING_SHIPMENT_ERROR,
	ROLLBACK_SALE
} from '../constants';

/**
 * Initial State
 */
const initialState={
	list:[],
	processed_list:[],
	processed:{
		pending:{
			isFetch:false,
			error:null,
			list:[]
		},
		completed:{
			isFetch:false,
			error:null,
			list:[]
		}
	},
	detail:{},
	saleDetail: {
		data: null,
		error: null,
		isFetch: false
	},
	shippingUpdate: {
		data: null,
		error: null,
		isFetch: false
	},
	detailImages: {
		isFetch: false,
		isSubmit: false,
		data: [],
		error: null,
	},
	notes: {
		saleId: null,
		readNotes:{
			data: null,
			error: null,
		},
		list: {
			data: null,
			error: null,
		},
		isFetch: false,
		isSubmit: false
	},
	detailImageS3: {
    isPending: false,
    results: null,
    error: null
	},
	report_sale: {
		status_code: 0,
		message: '',
		error: null
	},
    isFetch:false,
		isSubmit:false,
	error:null,
  success:null,
  outstanding_confirmation:{
    isFetch:false,
    error:null,
    list:[]
  },
  outstanding_delivery:{
    isFetch:false,
    error:null,
    list:[]
	},
	followUpSales: {
		list: [],
		status_code: null,
		isFetch: false,
		isSubmit: false,
		followUp: { id: 0 },
		error: null
	},
	followUpApprovalSales: {
		list: [],
		status_code: null,
		isFetch: false,
		error: null
	},
  credit_card:{
    isFetch:false,
    error:null,
    detail:null,
    message: '',
    status_code: 0,
  }
}

/**
 * Function to manipulate payload when success get offer list
 * @author pewe
 * @param {object} lastState
 * @param {object} payload
 */
const onGetSaleList = (lastState,payload) => {
	const {data, message, status_code}=payload;
	return { ...lastState, error:null, success:null,detail:{}, list:{ ...data, message, status_code } };
}
const onGetProcessedSaleList = (lastState,payload) => {
	const {data, message, status_code}=payload;
	return { ...lastState, error:null, success:null,detail:{}, processed_list:{ ...data, message, status_code } };
}

const onGetPendingProcessedSaleList = (lastState, { payload }) => {
	const { pending } = lastState.processed;
	const {data, message, status_code}=payload;
	return { ...lastState, processed:{...lastState.processed, pending:{...pending, list: { ...data, message, status_code } , error:null} } }
}

const onGetCompletedSaleList = (lastState, { payload }) => {
	const { completed } = lastState.processed;
	const {data, message, status_code}=payload;
	return { ...lastState, processed:{...lastState.processed, completed:{...completed, list: { ...data, message, status_code } , error:null} } }
}

const onReportSale = (lastState,payload) => {
	const {message, status_code}=payload;
	return { ...lastState, report_sale:{ message, status_code, error: null } };
}

const onReportSaleError = (lastState,error) => {
	const {message, status_code}=error.error;
	return { ...lastState, report_sale:{ message, status_code, ...error } };
}

const onGetOutstandingConfirmation = (lastState,payload) => {
  const {data, message, status_code}=payload;
  return {...lastState, outstanding_confirmation:{...lastState.outstanding_confirmation, error:null, list:{ ...data, message, status_code}}};
}

const onOutstandingConfirmationError = (lastState,error) => {
  const {message, status_code}=error.error;
  return {...lastState, outstanding_confirmation: {...lastState.outstanding_confirmation, error: {message, status_code, ...error}}};
}

const onGetOutstandingDelivery = (lastState,payload) => {
  const {data, message, status_code}=payload;
  return {...lastState, outstanding_delivery:{...lastState.outstanding_delivery, error:null, list:{ ...data, message, status_code}}};
}

const onOutstandingDeliveryError = (lastState,error) => {
  const {message, status_code}=error.error;
  return {...lastState, outstanding_delivery: {...lastState.outstanding_delivery, error: {...error}, message, status_code }};
}

const onGetDetailsBin = (lastState,payload) => {
  const { data, message, status_code } = payload;
  return {...lastState, credit_card: { ...lastState.credit_card, error: null, detail: { ...data}, message, status_code }};
}

const onBinError = (lastState,error) => {
  const { message, status_code }=error.error;
  return { ...lastState, credit_card: { ...lastState.credit_card, error: { message, status_code }, message, status_code }};
}

/**
 * Function to manipulate payload when success get offer details
 * @author pewe
 * @param {object} lastState
 * @param {object} payload
 */
const onGetDetailSale = (lastState,payload) => {
	const {data, message, status_code}=payload;
	return { ...lastState, error:null, success:null, detail:{ ...data, message, status_code } };
}

/**
 * Function to handle when start or end fetching offer api
 * @author pewe
 * @param {object} lastState
 * @param {objec} bool
 */
const onFetchingSale = (lastState,payload) => {
    if(payload.type==='fetch')
        return { ...lastState, isFetch:payload.status }
    else if(payload.type==='submit')
				return { ...lastState, isSubmit:payload.status }
		else if (payload.type === 'fetch_tracking')
				return { ...lastState, shippingUpdate: {...lastState.shippingUpdate, isFetch: payload.status } }

    return { ...lastState, isFetch:false,isSubmit:false }
}

/**
 * Function to handle when fetching offer error triggered
 * @author pewe
 * @param {object} lastState
 * @param {object} error
 */
const onSaleError = (lastState,payload) => {
	const { error, id } = payload
	return { ...lastState, error: { ...error, id }, success:null };
}


const onUpdateSale = (lastState,payload) => {
	const {data, message, status_code}=payload;
	const { detail } = lastState
    return { ...lastState, detail:{ ...detail, ...data, message, status_code }, success:'Update has been successfully!',error:null }

}

const onSaleImageRequested = (lastState, { requestType, value }) => {
	switch(requestType){
		case GET_SALE_IMAGES: return {
			...lastState,
			detailImages: {
				...lastState.detailImages,
				isFetch: value
			}
		}
		case DELETE_SALE_IMAGES:
		case CREATE_SALE_IMAGES: return {
			...lastState,
			detailImages: {
				...lastState.detailImages,
				isSubmit: value
			}
		}
    default: return lastState;
  }
}

const onSaleImageError = (lastState, { requestType, error, ...rest }) => {
	switch(requestType){
    case SALE_IMAGE_S3: return { ...lastState, detailImageS3: { ...lastState.detailImageS3, isPending: false, error }}
		case GET_SALE_IMAGES:
		case DELETE_SALE_IMAGES:
		case CREATE_SALE_IMAGES: return {
			...lastState,
			detailImages: {
				...lastState.detailImages,
				error: { ...error, ...rest }
			}
		}
    default: return lastState;
  }
}

/**
 * Offer Reducer
 * @author pewe
 * @param {object} state
 * @param {object} action
 */
const saleReducer= (state=initialState, action) => {
	switch(action.type)
	{
		case GET_LIST_SALE : return onGetSaleList(state,action.payload);
		case GET_LIST_PROCESSED_SALE : return onGetProcessedSaleList(state,action.payload);

		case REPORT_CLEAR : return {
			...state,report_sale:{
				...state.report_sale,status_code:0,message:'',error: null
			}
		}
		case REPORT_SALE : return onReportSale(state,action.payload);
		case REPORT_SALE_ERROR: return onReportSaleError(state,action.payload);

		case GET_LIST_PENDING_PROCESSED_SALE: return onGetPendingProcessedSaleList(state, action);
		case FETCHING_PENDING_PROCESSED_SALE: return { ...state, processed:{ ...state.processed, pending:{...state.processed.pending, isFetch:action.payload}}}
		case PENDING_PROCESSED_SALE_ERROR: return { ...state, processed:{ ...state.processed, pending:{...state.processed.pending, error:{...action.payload}}}}

		case GET_LIST_COMPLETED_SALE: return onGetCompletedSaleList(state, action);
		case FETCHING_COMPLETED_SALE: return { ...state, processed:{ ...state.processed, completed:{...state.processed.completed, isFetch:action.payload}}}
		case COMPLETED_SALE_ERROR: return { ...state, processed:{ ...state.processed, completed:{...state.processed.completed, error:{...action.payload}}}}

		case GET_DETAIL_SALE : return onGetDetailSale(state,action.payload);
		case FETCHING_SALE : return onFetchingSale(state,action.payload);
    case SALE_ERROR : return onSaleError(state,action.payload);

    case GET_LIST_OUTSTANDING_CONFIRMATION : return onGetOutstandingConfirmation(state,action.payload);
    case FETCHING_OUTSTANDING_CONFIRMATION : return { ...state, outstanding_confirmation:{ ...state.outstanding_confirmation, isFetch:action.status}}
    case OUTSTANDING_CONFIRMATION_ERROR : return onOutstandingConfirmationError(state,action.payload);
    case GET_LIST_OUTSTANDING_DELIVERY : return onGetOutstandingDelivery(state,action.payload);
    case FETCHING_OUTSTANDING_DELIVERY :  return { ...state, outstanding_delivery:{ ...state.outstanding_delivery, isFetch:action.status}}
    case OUTSTANDING_DELIVERY_ERROR : return onOutstandingDeliveryError(state,action.payload);

    case FETCHING_DETAILS_BIN : return { ...state, credit_card:{ ...state.credit_card, isFetch:action.status}};
    case GET_DETAILS_BIN : return onGetDetailsBin(state,action.payload);
    case BIN_ERROR : return onBinError(state,action.payload);

    case SELLER_ACCEPT_SALE:
    case SELLER_DECLINE_SALE:
		case ADMIN_RECEIVED_SNEAKER_SALE:
		case LEGIT_PASSED_SALE:
		case LEGIT_FAILED_SALE:
		case ADMIN_DELIVERING_SNEAKER_SALE:
		case COMPLETION_BY_ADMIN_SALE:
		case UPDATE_AWB_NUMBER_SALE:
		case UPDATE_HIJACKED_SALE:
		case ROLLBACK_SALE:
		case UPDATE_FORCE_CANCELLED_SALE:
		case UPDATE_SALE : return onUpdateSale(state,action.payload);

		case AUTODISBURSEMENT_TO_SELLER_SALE:
		case DISBURSEMENT_TO_SELLER_SALE:
		case DISBURSEMENT_TO_BUYER_SALE : return onUpdateSale(state,action.payload);

		//SALE-IMAGES
		case SALE_IMAGES_REQUEST: return onSaleImageRequested(state, action.payload);
		case SALE_IMAGES_ERROR: return onSaleImageError(state, action.payload);
		case GET_SALE_IMAGES: {
      const { status_code, message, data } = action.payload;
			return {
        ...state,
        detailImages: {
					...state.detailImages,
					data: [ ...data ],
          status_code,
          message,
          error: null
        }
      }
		}
    case CREATE_SALE_IMAGES:
    case DELETE_SALE_IMAGES: {
      const { status_code, message } = action.payload;
      return {
        ...state,
        detailImages: {
          ...state.detailImages,
          status_code,
          message,
          error: null
        }
      }
    }
		case SALE_IMAGE_S3: {
      return {
        ...state,
        detailImageS3: {
          ...state.detailImageS3,
          error: null,
          ...action.payload,
        }
      }
		}
		case FETCHING_SALE_NOTES:{
			let payload = {};
			let value = action.payload;
			if(typeof action.payload === 'object'){
				const { error, errorKey, id } = action.payload;
				value = action.payload.value;
				if(errorKey){
					payload = {
						[errorKey]: {
							...state.notes[errorKey],
							error: { id, ...error }
						}
					}
				} else if(error){
					payload = { error: { id, ...error } }
				}
			}
			return {
				...state,
				notes: {
					...state.notes,
					...payload,
					isFetch: value
				}
			}
		}
		case SALE_NOTES_SUBMIT:{
			let payload = {};
			let value = action.payload;
			if(typeof action.payload === 'object'){
				const { error, errorKey, id } = action.payload;
				value = action.payload.value;
				if(errorKey){
					payload = {
						[errorKey]: {
							...state.notes[errorKey],
							error: { id, ...error }
						}
					}
				} else if(error){
					payload = { error: { id, ...error } }
				}
			}
			return {
				...state,
				notes: {
					...state.notes,
					...payload,
					isSubmit: value
				}
			}
		}
		case GET_SALE_NOTES:{
			const { notes } = state;
			const { data: { data: list, ...pagination }, saleId } = action.payload;
			return {
				...state,
				notes: {
					...notes,
					saleId,
					list: {
						...action.payload,
						...pagination,
						data: list.map(item => item).reverse(),
						error: null,
					},
					isFetch: false
				}
			}
		}
		case READ_SALE_NOTES:{
			const { notes } = state;
			return {
				...state,
				notes:{
					...notes,
					readNotes:{
						...action.payload,
						error: null
					},
					isSubmit: false
				}
			}
		}
		case FETCHING_DETAILS_SALE:{
			let payload = {};
			let value = action.payload;
			if(typeof action.payload === 'object'){
				const { error, errorKey, id } = action.payload;
				value = action.payload.value;
				if(errorKey){
					payload = {
						[errorKey]: {
							...state.notes[errorKey],
							error: { id, ...error }
						}
					}
				} else if(error){
					payload = { error: { id, ...error } }
				}
			}
			return {
				...state,
				saleDetail: {
					...state.saleDetail,
					...payload,
					isFetch: value
				}
			}
		}
		case GET_DETAIL_SALE_2:{
			const { saleDetail } = state;
			return {
				...state,
				saleDetail:{
					...saleDetail,
					...action.payload,
					error: null,
					isFetch: false
				}
			}
		}
		case FETCH_FOLLOWUP_SALES: {
			const { type: payloadType, value, error } = action.payload;
			const { followUpSales } = state;
			return {
				...state,
				followUpSales: {
					...followUpSales,
					isFetch: payloadType === 'GET'? value: followUpSales.isFetch,
					isSubmit: payloadType !== 'GET'? value: followUpSales.isSubmit,
					error: error? error: null,
				}
			}
		}
		case GET_FOLLOWUP_SALES:{
			const { followUpSales } = state;
			const { data, ...otherPayloads } = action.payload
			return {
				...state,
				followUpSales:{
					...followUpSales,
					list: { ...data },
					...otherPayloads,
					error: null,
					isFetch: false
				}
			}
		}
		case FETCH_FOLLOWUP_APPROVAL_SALES: {
			const { value, error } = action.payload;
			const { followUpApprovalSales } = state;
			return {
				...state,
				followUpApprovalSales: {
					...followUpApprovalSales,
					isFetch: value,
					error: error? error: null,
				}
			}
		}
		case UPDATE_FOLLOWUP_SALES: {
			const { followUpSales } = state;
			const { data, ...otherPayloads } = action.payload
			return {
				...state,
				followUpSales:{
					...followUpSales,
					followUp: { ...followUpSales.followUp, ...data },
					...otherPayloads,
					error: null,
					isSubmit: false
				}
			}
		}
		case GET_FOLLOWUP_APPROVAL_SALES:{
			const { followUpApprovalSales } = state;
			const { data, ...otherPayloads } = action.payload
			return {
				...state,
				followUpApprovalSales:{
					...followUpApprovalSales,
					list: { ...data },
					...otherPayloads,
					error: null,
					isFetch: false
				}
			}
		}
		case GET_TRACKING_SHIPMENT: {
			const { shippingUpdate } = state;
			const { data, message, status_code } = action.payload
			return {
				...state,
				shippingUpdate: {
					...shippingUpdate,
					data: data,
					error: null,
				}
			}
		}
		case TRACKING_SHIPMENT_ERROR: {
			const { shippingUpdate } = state;
			const { error } = action.payload
			return {
				...state,
				shippingUpdate: {
					...shippingUpdate,
					data: null,
					error,
				}
			}
		}
    default : return state;
	}
}

export default saleReducer;