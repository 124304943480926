import {
  DISBURSEMENT_USER_ROUTE_API,
  DISBURSEMENT_USER_LIST,
  DISBURSEMENT_USER_LIST_FETCH,
  DISBURSEMENT_USER_LIST_ERROR,
  PENDING_DISBURSEMENT_USER_LIST,
  PENDING_DISBURSEMENT_USER_LIST_FETCH,
  PENDING_DISBURSEMENT_USER_LIST_ERROR,
  COMPLETED_DISBURSEMENT_USER_LIST,
  COMPLETED_DISBURSEMENT_USER_LIST_FETCH,
  COMPLETED_DISBURSEMENT_USER_LIST_ERROR,
  GET_DETAIL_DISBURSEMENT_USER,
  GET_DETAIL_DISBURSEMENT_USER_FETCH,
  GET_DETAIL_DISBURSEMENT_USER_ERROR,
  ADD_DETAIL_DISBURSEMENT_USER,
  ADD_DETAIL_DISBURSEMENT_USER_SUBMIT,
  ADD_DETAIL_DISBURSEMENT_USER_ERROR,
  UPDATE_DETAIL_DISBURSEMENT_USER,
  UPDATE_DETAIL_DISBURSEMENT_USER_SUBMIT,
  UPDATE_DETAIL_DISBURSEMENT_USER_ERROR,
  DELETE_DETAIL_DISBURSEMENT_USER,
  DELETE_DETAIL_DISBURSEMENT_USER_SUMBIT,
  DELETE_DETAIL_DISBURSEMENT_USER_ERROR,
  MIDTRANS_BANK_ACCOUNT_ROUTE_API,
  MIDTRANS_BANK_ACCOUNT_FETCH,
  MIDTRANS_BANK_ACCOUNT_LIST,
  MIDTRANS_BANK_ACCOUNT_ERROR,
  CASHOUT_FEE_FETCH,
  CASHOUT_FEE_ERROR,
  CASHOUT_FEE_LIST,
  CASHOUT_FEE_ROUTE_API,
  AUTH_TOKEN_KEY,
  API_BASE_URL,
  CASHOUT_FEE_SETTING_FETCH,
  CASHOUT_FEE_SETTING_ERROR,
  CASHOUT_FEE_SETTING,
  DISBURSEMENT_REPORT,
  DISBURSEMENT_REPORT_FETCH,
  DISBURSEMENT_REPORT_CLEAR,
  DISBURSEMENT_REPORT_ERROR,
  DISBURSEMENT_REPORT_ROUTE_API
} from '../constants';
import { logout } from './authAction';
import api from '../utils/Api';
import { manipulateErrors, checkAuthorized } from '../utils';

const isFetch = (payload, type) => ({
  type, payload
})

const onSuccess = (payload, type) => ({
  type, payload
})

const onError=(err, type)=>{
	if(!checkAuthorized(manipulateErrors(err)))
		return logout();
	return {
		type,
		payload: manipulateErrors(err)
  }
}
export const doGetDisbursementList = (query) => {
  return dispatch => (
    dispatch(isFetch({ isFetch: true }, DISBURSEMENT_USER_LIST_FETCH)),
    api({
      Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
    }).get(DISBURSEMENT_USER_ROUTE_API, query)
    .then(res=>{
      dispatch(onSuccess(res.data, DISBURSEMENT_USER_LIST))
      dispatch(isFetch({ isFetch: false }, DISBURSEMENT_USER_LIST_FETCH))
    })
    .catch(err => {
			dispatch(onError(err, DISBURSEMENT_USER_LIST_ERROR));
			dispatch(isFetch({ isFetch: false }, DISBURSEMENT_USER_LIST_FETCH));
		})
  )
}

export const doGetPendingDisbursementList = (query) => {
  return dispatch => (
    dispatch(isFetch({ isFetch: true }, PENDING_DISBURSEMENT_USER_LIST_FETCH)),
    api({
      Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
    }).get(`${DISBURSEMENT_USER_ROUTE_API}/pending`, query)
    .then(res=>{
      dispatch(onSuccess(res.data, PENDING_DISBURSEMENT_USER_LIST))
      dispatch(isFetch({ isFetch: false }, PENDING_DISBURSEMENT_USER_LIST_FETCH))
    })
    .catch(err => {
			dispatch(onError(err, PENDING_DISBURSEMENT_USER_LIST_ERROR));
			dispatch(isFetch({ isFetch: false }, PENDING_DISBURSEMENT_USER_LIST_FETCH));
		})
  )
}

export const doGetCompletedDisbursementList = (query) => {
  return dispatch => (
    dispatch(isFetch({ isFetch: true }, COMPLETED_DISBURSEMENT_USER_LIST_FETCH)),
    api({
      Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
    }).get(`${DISBURSEMENT_USER_ROUTE_API}/completed`, query)
    .then(res=>{
      dispatch(onSuccess(res.data, COMPLETED_DISBURSEMENT_USER_LIST))
      dispatch(isFetch({ isFetch: false }, COMPLETED_DISBURSEMENT_USER_LIST_FETCH))
    })
    .catch(err => {
			dispatch(onError(err, COMPLETED_DISBURSEMENT_USER_LIST_ERROR));
			dispatch(isFetch({ isFetch: false }, COMPLETED_DISBURSEMENT_USER_LIST_FETCH));
		})
  )
}

export const doGetDetailDisbursement = (id) => {
  return dispatch => (
    dispatch(isFetch({ isFetch: true }, GET_DETAIL_DISBURSEMENT_USER_FETCH)),
    api({
      Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
    }).get(`${DISBURSEMENT_USER_ROUTE_API}/${id}`)
    .then(res=>{
      dispatch(onSuccess(res.data, GET_DETAIL_DISBURSEMENT_USER))
      dispatch(isFetch({ isFetch: false }, GET_DETAIL_DISBURSEMENT_USER_FETCH))
    })
    .catch(err => {
			dispatch(onError(err, GET_DETAIL_DISBURSEMENT_USER_ERROR));
			dispatch(isFetch({ isFetch: false }, GET_DETAIL_DISBURSEMENT_USER_FETCH));
		})
  )
}

export const doDeleteDisbursement = (id) => {
  return dispatch => (
    dispatch(isFetch({ isSubmit: true }, DELETE_DETAIL_DISBURSEMENT_USER_SUMBIT)),
    api({
      Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
    }).delete(`${DISBURSEMENT_USER_ROUTE_API}/${id}`)
    .then(res=>{
      dispatch(onSuccess(res.data, DELETE_DETAIL_DISBURSEMENT_USER))
      dispatch(isFetch({ isSubmit: false }, DELETE_DETAIL_DISBURSEMENT_USER_SUMBIT))
    })
    .catch(err => {
			dispatch(onError(err, DELETE_DETAIL_DISBURSEMENT_USER_ERROR));
			dispatch(isFetch({ isSubmit: false }, DELETE_DETAIL_DISBURSEMENT_USER_SUMBIT));
		})
  )
}

export const doCreateDisbursement = (payloads) => {
  return dispatch => (
    dispatch(isFetch({ isSubmit: true }, ADD_DETAIL_DISBURSEMENT_USER_SUBMIT)),
    api({
      Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
    }).post(`${DISBURSEMENT_USER_ROUTE_API}`, payloads)
    .then(res=>{
      dispatch(onSuccess(res.data, ADD_DETAIL_DISBURSEMENT_USER))
      dispatch(isFetch({ isSubmit: false }, ADD_DETAIL_DISBURSEMENT_USER_SUBMIT))
    })
    .catch(err => {
			dispatch(onError(err, ADD_DETAIL_DISBURSEMENT_USER_ERROR));
			dispatch(isFetch({ isSubmit: false }, ADD_DETAIL_DISBURSEMENT_USER_SUBMIT));
		})
  )
}

export const doUpdateDisbursement = (id, payloads) => {
  return dispatch => (
    dispatch(isFetch({ isSubmit: true }, UPDATE_DETAIL_DISBURSEMENT_USER_SUBMIT)),
    api({
      Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
    }).put(`${DISBURSEMENT_USER_ROUTE_API}/${id}`, payloads)
    .then(res=>{
      dispatch(onSuccess(res.data, UPDATE_DETAIL_DISBURSEMENT_USER))
      dispatch(isFetch({ isSubmit: false }, UPDATE_DETAIL_DISBURSEMENT_USER_SUBMIT))
    })
    .catch(err => {
			dispatch(onError(err, UPDATE_DETAIL_DISBURSEMENT_USER_ERROR));
			dispatch(isFetch({ isSubmit: false }, UPDATE_DETAIL_DISBURSEMENT_USER_SUBMIT));
		})
  )
}

export const doGetBankAccountList = () => {
  return dispatch => (
    dispatch(isFetch({ isFetch: true }, MIDTRANS_BANK_ACCOUNT_FETCH)),
    api({
      // Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
    }).get(MIDTRANS_BANK_ACCOUNT_ROUTE_API)
    .then(res=>{
      dispatch(onSuccess(res.data, MIDTRANS_BANK_ACCOUNT_LIST))
      dispatch(isFetch({ isFetch: false }, MIDTRANS_BANK_ACCOUNT_FETCH))
    })
    .catch(err=>{
      dispatch(onError(err, MIDTRANS_BANK_ACCOUNT_ERROR))
      dispatch(isFetch({ isFetch: false }, MIDTRANS_BANK_ACCOUNT_FETCH))
    })
  )
}

export const doGetCashoutFee = (params) => {
  return dispatch => (
    dispatch(isFetch({isFetch: true}, CASHOUT_FEE_FETCH)),
    api({
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
    }).get(CASHOUT_FEE_ROUTE_API, params)
    .then(res => {
      dispatch(onSuccess(res.data, CASHOUT_FEE_LIST))
      dispatch(isFetch({isFetch: false}, CASHOUT_FEE_FETCH))
    })
    .catch(err =>{
      dispatch(onError(err, CASHOUT_FEE_ERROR))
      dispatch(isFetch({isFetch: false}, CASHOUT_FEE_FETCH))
    })
  )
}

export const doGetCashoutSetting = () => {
  return dispatch => (
    dispatch(isFetch({isFetchSetting: true}, CASHOUT_FEE_SETTING_FETCH)),
    api().get(`${API_BASE_URL}/disbursement-setting`)
    .then(res => {
      dispatch(onSuccess(res.data, CASHOUT_FEE_SETTING))
      dispatch(isFetch({isFetchSetting: false}, CASHOUT_FEE_SETTING_FETCH))
    })
    .catch(err =>{
      dispatch(onError(err, CASHOUT_FEE_SETTING_ERROR))
      dispatch(isFetch({isFetchSetting: false}, CASHOUT_FEE_SETTING_FETCH))
    })
  )
}


 export const doReportDisbursement = (payload) =>{
	return dispatch => (
		dispatch({type:DISBURSEMENT_REPORT_CLEAR,payload:true}),
		dispatch(isFetch({isSubmit:true}, DISBURSEMENT_REPORT_FETCH)),
		api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).post(`${DISBURSEMENT_REPORT_ROUTE_API}`,payload)
		.then(res=>{
			dispatch(onSuccess(res.data,DISBURSEMENT_REPORT))
			dispatch(isFetch({isSubmit:false}, DISBURSEMENT_REPORT_FETCH))
		})
		.catch(err => {
			dispatch(onError(err,DISBURSEMENT_REPORT_ERROR));
			dispatch(isFetch({isSubmit:false}, DISBURSEMENT_REPORT_FETCH));
		})
	)
}

export const doSaveDisbursement = (payloads, id) => (
  dispatch => (id === undefined? dispatch(doCreateDisbursement(payloads)): dispatch(doUpdateDisbursement(id, payloads)))
)