import React from 'react';
import propTypes from 'prop-types';
import { Badge } from 'reactstrap';
import FontAwesomeIcon from '../../../components/Icons/FontAwesomeIcon';

import {
  DISBURSEMENT_MIDTRANS,
  DISBURSEMENT_MANUAL,
  getPayoutTypeStr
} from '../helpers';

const BadgePayoutType = (props) => {
  return (
    <Badge color={props.type===DISBURSEMENT_MANUAL?"primary"
    :props.type===DISBURSEMENT_MIDTRANS?"success"
    :"default"}
    style={{marginRight: ".5rem"}}
    >
      <FontAwesomeIcon iconType="fa fa-feed"/> {getPayoutTypeStr(props.type)}
    </Badge>
  );
}

export default BadgePayoutType;

BadgePayoutType.propTypes = {
  type: propTypes.string
}

BadgePayoutType.defaultProps = {
  type: DISBURSEMENT_MANUAL
}