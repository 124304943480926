import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { getOptionList } from '../../../actions/categoriesAction';

import {
  ButtonDropdown,
  DropdownToggle as DropdownToggleBs,
  DropdownMenu,
  DropdownItem as DropdownItemBs
} from 'reactstrap';

import { SimpleLineIcon } from '../../../components/Icons';

const DropdownToggle = styled(DropdownToggleBs)`
  border: none;
  padding: 0px;
  &:active,&:focus,&:hover{
    border: none;
    padding: 0px;
    background: transparent;
    outline: none;
    box-shadow: none;
  }
  ~ .dropdown-menu{
    background-color: rgba(255,255,255,.8);
  }
`;
const DropdownItem = styled(DropdownItemBs)`
  text-transform: capitalize;
`;

class DropdownCategories extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      dropdownOpen: false
    }
    this._handleToggle = this._handleToggle.bind(this);
  }

  componentDidMount(){
    if(!this.props.dataCategories.length)
      this.props.getCategoriesOptions();
  }
  
  componentDidUpdate(_p, prevState){
    if(!prevState.dropdownOpen && this.state.dropdownOpen && !this.props.dataCategories.length){
      this.props.getCategoriesOptions();
    }
  }

  _handleToggle(){
    this.setState({ dropdownOpen: !this.state.dropdownOpen })
  }

  _active(value){
    return this.props.value?(this.props.value === value): (value === '');
  }

  render(){
    return(
      <ButtonDropdown isOpen={this.state.dropdownOpen} toggle={this._handleToggle}>
        <DropdownToggle size="sm">
          <SimpleLineIcon iconType="options-vertical"/>
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem header>Show Only: </DropdownItem>
          <DropdownItem
            onClick={() => this.props.onCategoryChanged('')}
            active={this._active('')}
          >
            { this._active('')? <SimpleLineIcon iconType="check"/>: null }All
          </DropdownItem>
          {
            this.props.dataCategories.map(item => ({label: item.label, value: item.label}))
            .map((item, idx) => (
              <DropdownItem
                onClick={() => this.props.onCategoryChanged(item)}
                active={this._active(item.value)}
                key={idx}
              >
                { this._active(item.value)? <SimpleLineIcon iconType="check"/>: null }{item.value}
              </DropdownItem>
            ))
          }
        </DropdownMenu>
      </ButtonDropdown>
    )
  }
}
const mapStateToProps= ({ masterCategories: { options } }) => ({
  dataCategories: options,
});
const mapDispatchToProps = (dispatch) => ({
  getCategoriesOptions: () => dispatch(getOptionList())
});
export default connect(mapStateToProps,mapDispatchToProps)(DropdownCategories);
DropdownCategories.defaultProps = {
  value: null,
  dataCategories: []
}

DropdownCategories.propTypes = {
  dataCategories: propTypes.arrayOf(propTypes.shape({
    value: propTypes.number.isRequired,
    label: propTypes.string
  })),
  value: propTypes.oneOfType([propTypes.number, propTypes.string]),
  onCategoryChanged: propTypes.func,
}