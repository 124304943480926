import React, { PureComponent as Component } from 'react';
import propTypes from 'prop-types';
import {
  Card,
  CardHeader,
  CardBody
} from 'reactstrap';

import { Tbl, TblHead, TblBody } from  '../../Tbl';
import DateToHuman from '../../DateToHuman';
import CheckReadOnly from '../../CheckReadOnly';

export default class SellerVerificationList extends Component{
  render(){
    return(
      <Card>
        <CardHeader>Seller Verification Info</CardHeader>
        <CardBody>
          <div className="table-responsive">
            <Tbl>
              <TblHead dataHeader={['No', 'Mobile Number', 'No. KTP', 'KTP URL', 'Active', 'Updated']}/>
              <TblBody
                hasRow={this.props.data.length}
                columnCount={6}
              >
                {
                  this.props.data.map((item, index) => (
                    <tr key={item.id}>
                      <th scope="row">{ index+1 }</th>
                      <td>{ item.mobile_number }</td>
                      <td>{ item.KTP }</td>
                      <td>{ item.KTP_URL }</td>
                      <td>{ <CheckReadOnly check={item.active}/> }</td>
                      <td>{ <DateToHuman value={item.updated_at} id={`tooltip_sale_seller_details_seller_verification_info_${item.id}`}/> }</td>
                    </tr>
                  ))
                }
              </TblBody>
            </Tbl>
          </div>
        </CardBody>
      </Card>
    )
  }
}

SellerVerificationList.propTypes = {
  data: propTypes.arrayOf(propTypes.object)
}

SellerVerificationList.defaultProps = {
  data: []
}