import React from 'react';
import propTypes from 'prop-types';
import {
  TabPane,
  Card,
  Row,
  Col
} from 'reactstrap';
import ChangeSellerForm from './tabPaneChangeSeller/ChangeSellerForm';
import Description from './tabPaneChangeSeller/Description';

class TabPaneChangeSeller extends React.Component {
  render(){
    return(
      <TabPane className="tab-content--custom__tab-pane" tabId={this.props.tabId}>
        <Card className="tab-content--custom__card" body>
          <Description/>
          <Row>
            <Col xs={12} md={6}>
              <ChangeSellerForm
                isFetching={this.props.isLoading}
                isSubmit={this.props.isSubmit}
                dataError={this.props.dataError}
                dataProduct={this.props.dataProduct}
                dataSale={this.props.dataSale}
                dataUserSell={this.props.dataUserSell}
                dataBrand={this.props.dataBrand}
                dataVariant={this.props.dataVariant}
                onChangeSellerSubmit={this.props.onChangeSellerSubmit}
              />
            </Col>
          </Row>
        </Card>
      </TabPane>
    )
  }
}

export default TabPaneChangeSeller;

TabPaneChangeSeller.defaultProps = {
  isLoading: false,
  isSubmit: false,
  dataError: null,
  dataSale: {},
  dataUserSell: {},
  dataProduct: {}
}

TabPaneChangeSeller.propTypes = {
  tabId: propTypes.string.isRequired,
  isLoading: propTypes.bool,
  isSubmit: propTypes.bool,
  dataError: propTypes.object,
  dataSale: propTypes.object,
  dataUserSell: propTypes.object,
  dataProduct: propTypes.object,
  onChangeSellerSubmit: propTypes.func
}