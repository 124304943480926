import React, { PureComponent as Component } from 'react';
import { Button } from 'reactstrap';
import classNames from 'classnames';
import propTypes from 'prop-types';
import { Toolbars as ToolBarContainer } from '../../../../components/Card/CardHeaderWithToolbars';


export default class Toolbars extends Component {
  render(){
    return(
      <ToolBarContainer>
        <Button 
          type="button"
          title="Refresh"
          color="info"
          size="sm"
          className="with-toolbars__toolbars__btn"
          onClick={this.props.onRefreshList}
        >            
          <i className="fa fa-refresh"></i>
        </Button>
        <Button 
          type="button"
          title="Reset filter"
          color="warning"
          size="sm"
          className="with-toolbars__toolbars__btn"
          onClick={this.props.onResetFilter}
        >            
          <i className="fa fa-ban"></i>
        </Button>
        <Button 
          type="button"
          title="Search and Filter Data"
          size="sm"
          className={classNames('with-toolbars__toolbars__btn',{'btn-danger':this.props.isOpen})}
          onClick={this.props.onToggleFilter}
        >            
          <i className={classNames('fa', {'fa-sliders':!this.props.isOpen,'fa-minus':this.props.isOpen})}></i>
        </Button>
      </ToolBarContainer>
    )
  }
}

Toolbars.propTypes = {
  isOpen: propTypes.bool,
  onToggleFilter: propTypes.func,
  onResetFilter: propTypes.func,
  onRefreshList: propTypes.func
}
Toolbars.defaultProps = {
  isOpen: false
}