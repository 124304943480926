import React from 'react';
import propTypes from 'prop-types';
import { Card, CardHeader, CardBody, Alert } from 'reactstrap';
import Loading from 'react-loading-animation';
import CardHeaderText from '../CardHeaderText';

const AlertNotFound = props => (
  <Alert className="text-center" color="danger">
    <strong><i className="fa fa-exclamation-circle"></i> Ooops. </strong>
    No Images Found !
  </Alert>
)

const ImageList = props =>(
  <Card>
    <CardHeader>
      { props.renderHeaderText? props.renderHeaderText(): props.headerText }
    </CardHeader>
    <CardBody>
      { 
        props.isLoading?
        <Loading/>:
        (
          props.isEmpty?
          <AlertNotFound/> :
          (
            props.onRenderItems? props.onRenderItems(props.data): props.children
          )
        )
      }
    </CardBody>
  </Card>
)

export default ImageList;

ImageList.propTypes = {
  renderHeaderText: propTypes.func,
  headerText: propTypes.oneOfType([propTypes.node, propTypes.string]),
  data: propTypes.array,
  isEmpty: propTypes.bool,
  isLoading: propTypes.bool,
}

ImageList.defaultProps = {
  headerText: <CardHeaderText iconClassName="fa fa-images" text="Image List"/>,
  isLoading: false,
  isEmpty: false
}