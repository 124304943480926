import React, { useState, useRef } from 'react'
import styled from 'styled-components';
import Modal from "react-modal"
import { Row } from "reactstrap"

import { getCacheImage } from "../../../../utils/imageHelper";
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { FontAwesomeIcon } from '../../../../components/Icons';

const SortableItem = SortableElement(({ value }) => {
  const getImage = (images) => {
    if (!images?.length) return ""

    return images?.[0]?.signed_url || images?.[0]?.URL
  }

  const thumb = getImage(value?.product_variant_images ?? [])
  return <li className={`card`} style={{
    zIndex: 100000,
    border: "none",
    width: "65px",
    height: "65px",
  }}>
    <img src={getCacheImage(thumb)} alt={value.display_name} />
  </li>;
});

const SortableList = SortableContainer(({ items }) => {
  return (
    <div className='grid-container'>
      {items.map((value, index) => (
        <SortableItem helperClass="ondrag" key={`item-${index}`} index={index} value={value} />
      ))}
    </div>
  );
});

const ModalContainer = styled(Modal)`
  position: relative;
  .drag-container{
    width: 100vw;
    height: 100vh;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .drag-inner-wrapper{
    max-width: 993px;
    background: #fff;
  }
  .drag-header{
    padding: 24px;
    border-bottom: solid 1px #ebebeb;
    display: flex;
    justify-content: space-between;
    p{
      font-size: 20px;
      font-weight: bold;
      margin: 0;
    }
  }
  .grid-container{
    width: 993px;
    background: #fff;
    display: grid;
    grid-template-columns: repeat(10, 9%);
    grid-gap: 1%;
    max-height: 437px;
    overflow-y: auto;
    padding: 24px;
    .card{
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0;
      border: none;
      flex-direction: column;
      justify-content: center;
      img{
        max-width: 63px;
        height: 45px;
        object-fit: contain;
      }
      &.hover{
        background: #ebebeb;
      }
    }
  }
`

const ModalProductVariantOrder = ({ data = [], isOpen, onClose, updatePosition }) => {
  const [productData, setProductData] = useState([...data])

  const arrayMove = (array, from, to) => {
    array = array.slice();
    array.splice(to < 0 ? array.length + to : to, 0, array.splice(from, 1)[0]);

    return array;
  }

  const onSortEnd = ({ oldIndex, newIndex }) => {
    updatePosition({ ...productData[oldIndex], currentIdx: newIndex })
    setProductData(arrayMove(productData, oldIndex, newIndex))
  };

  return (
    <ModalContainer
      isOpen={isOpen}
      onClose={onClose}
      center
      style={{
        overlay: {
          background: "rgba(0, 0, 0, 0.5)",
          zIndex: 9999
        }
      }}
      size="xl"
    >
      <div className='drag-container'>
        <div className='drag-inner-wrapper'>
          <div className='drag-header'>
            <p>Special Article Preview</p>
            <FontAwesomeIcon onClick={onClose} style={{ cursor: "pointer" }} iconType="close" />

          </div>
          <SortableList
            axis="xy"
            items={productData}
            onSortEnd={onSortEnd}
            helperClass='sortableHelper'
          />
        </div>
      </div>
    </ModalContainer>
  )
}

export { ModalProductVariantOrder }