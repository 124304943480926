import React, { Component } from 'react';
import {
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Button,
} from 'reactstrap';
import propTypes from 'prop-types';

export default class SearchForm extends Component{
  render(){
    return(
      <Form onSubmit={this.props.onSubmit}>
        <FormGroup row>
          <Col xs={12} sm={12} md={6}>
            <Input type="text" placeholder="Search by video name " name="keyword" value={this.props.keyword} onChange={this.props.onInputChange}/>
          </Col>
        </FormGroup>
        <Row>
          <Col sm={6}>
            <Row form>
              <Col sm={6}>
                <FormGroup row>
                  <Label sm={4}>Category</Label>
                    <Col sm={8}>
                      <Input type="select" value={this.props.category_id} name="category_id" onChange={this.props.onInputChange}>
                        <option value="">All</option>
                        {
                          this.props.categoryOptions.map(item => (
                            <option key={item.value} value={item.value}>{item.label.toUpperCase()}</option>
                          ))
                        }
                      </Input>
                    </Col>
                </FormGroup>
              </Col>
              <Col sm={6}>
                <FormGroup row>
                  <Label sm={4}>Visibility</Label>
                    <Col sm={8}>
                      <Input type="select" value={this.props.platform} name="platform" onChange={this.props.onInputChange}>
                        <option value="">All</option>
                        <option value="web">Web</option>
                        <option value="app">App</option>
                      </Input>
                    </Col>
                </FormGroup>
              </Col>
            </Row>
          </Col>
        </Row>
        <FormGroup row>
          <Col sm={6} className="text-right">
            <Button color="primary">Apply Filter</Button>
          </Col>
        </FormGroup>
      </Form>
    )
  }
}

SearchForm.propTypes= {
  categoryOptions: propTypes.array,
  type: propTypes.string,
  // category_id: propTypes.number,
  platform: propTypes.string,
  keyword: propTypes.string,
  onInputChange: propTypes.func,
  onSubmit: propTypes.func.isRequired
}

SearchForm.defaultProps= {
  categoryOptions: [],
  category_id: '',
  platform: '',
  keyword: ''
}