import {
    USER_FETCHING,
    USER_DETAIL_FETCH,
    USER_LIST_FETCH,
    USER_ERROR,
    USER_FETCHING_ROLES,
    USER_ROLES_FETCH,
    USER_ADD,
    USER_UPDATE,
    USER_DELETE,
    USER_FETCHING_SELLER_VERIFICATIONS,
    USER_SELLER_VERIFICATIONS_FETCH,
    USER_CLEAR_FORM,
    USER_DETAIL_SIZES_LIST_FETCHING,
    USER_DETAIL_SIZES_LIST_FETCHED,
    USER_DETAIL_SIZES_LIST_ADDED,
    USER_DETAIL_SIZES_LIST_UPDATED,
    USER_DETAIL_SIZES_LIST_ERROR,
    USER_BALANCE_LOG_FETCH,
    USER_BALANCE_LOG_LIST,
    USER_BALANCE_LOG_ERROR,
    SELLER_POINT_HISTORY_FETCH,
    SELLER_POINT_HISTORY_LIST,
    SELLER_POINT_HISTORY_ERROR,
    FETCHING_HOLIDAY_MODE,
    FORCE_HOLIDAY_MODE,
    HOLIDAY_MODE_ERROR,
    USER_COMMISSION_RATE_SUBMIT,
    USER_COMMISSION_RATE,
    USER_COMMISSION_RATE_ERROR,
    USER_STOCK_EXPORT,
    USER_STOCK_EXPORT_SUBMIT,
    USER_STOCK_EXPORT_ERROR,
    USER_SHIPPING_LIST,
    USER_SHIPPING_FETCH,
    USER_SHIPPING_ERROR,
    USER_SHIPPING_SAVE_SUBMIT,
    USER_SHIPPING_SAVE,
    USER_SHIPPING_SAVE_ERROR,
    USER_SHIPPING_UPDATE_SUBMIT,
    USER_SHIPPING_UPDATE,
    USER_SHIPPING_UPDATE_ERROR,
} from '../constants';
const initialState={
	list:[],
	detail:{
    isRoleFetch:false,
    isSellerVerificationFetch:false,
    seller_verifications:{},
    user_sizes:{
        data:[]
    },
    roles:{},
    user_balance_log:{},
    seller_point_history:{},
    user_shipping:{},
  },
	isFetch:false,
	isSubmit:false,
  error:null,
  status_code:0,
  user_setting:{
    isSubmit: false,
    data: {},
    error: null
  },
  userCommissions: {
      data: null,
      isSubmit: false,
      error: null
  },
  userStockExport: {
      isSubmit: false,
      error: null
  }
}

const onFetching = (lastState,payload) => {
    if(payload.type==='fetch')
        return { ...lastState, isFetch:payload.status }
    else if(payload.type==='submit')
        return { ...lastState, isSubmit:payload.status }

    return { ...lastState, isFetch:false,isSubmit:false }
}
const reducer=(state=initialState, action)=>{
    switch(action.type){
        case USER_LIST_FETCH:{
            const {data, status_code, message}=action.payload;
            return {...state, list:{...data, message, status_code}, error:null}
        }
        case USER_DETAIL_FETCH:{
            const {data, status_code, message}=action.payload;
            return { ...state, detail:{...state.detail, ...data, message, status_code,deleted:false}, error:null }
        }
        case USER_FETCHING_ROLES : return {...state, detail:{...state.detail, isRoleFetch:action.payload}}
        case USER_ROLES_FETCH : {
            const {data, status_code, message}=action.payload;
            return { ...state, detail:{...state.detail, roles: {data:[...data], message, status_code} } }
        }
        case USER_FETCHING_SELLER_VERIFICATIONS : return {...state, detail:{...state.detail, isSellerVerificationFetch:action.payload}}
        case USER_SELLER_VERIFICATIONS_FETCH : {
            const {data, status_code, message}=action.payload;
            return { ...state, detail:{...state.detail, seller_verifications: {data:[...data], message, status_code} } }
        }
        case USER_FETCHING:  return onFetching(state, action.payload); 
        case USER_ADD : {
            const {data, status_code, message}=action.payload;
            return {...state, detail:{...state.detail,...data, message, status_code}, error:null}
        }
        case USER_UPDATE : {
            const {data, status_code, message}=action.payload;
            return {...state, detail:{...state.detail,...data, message, status_code}, error:null}
        }
        case USER_DELETE : {
            const {data, status_code, message}=action.payload;
            return {...state, detail:{...state.detail,...data,deleted:true, message, status_code}, error:null}
        }
        case USER_CLEAR_FORM: return {...state, detail:{...initialState.detail}}
        case USER_ERROR:
            return { ...state, ...action.payload }
        case USER_DETAIL_SIZES_LIST_FETCHING:{
            const { detail } = state;
            return { ...state, detail: { ...detail, user_sizes:{ ...detail.user_sizes, isFetch: action.payload } } }
        }
        case USER_DETAIL_SIZES_LIST_FETCHED:{
            const { detail } = state;
            const { data, status_code, message } = action.payload;
            return { ...state, detail:{...detail, user_sizes:{ ...detail.user_sizes, data, status_code, message, error:null }}}
        }
        case USER_DETAIL_SIZES_LIST_ADDED:{
            const { detail } = state;
            const { data, status_code, message } = action.payload;
            const newData = [...detail.user_sizes.data, data];
            return { ...state, detail:{...detail, user_sizes:{ ...detail.user_sizes, data: newData, status_code, message, error:null }}}
        }
        case USER_DETAIL_SIZES_LIST_UPDATED:{
            const { detail } = state;
            const { data, status_code, message } = action.payload;
            const newData = detail.user_sizes.data.map(d=>{
                if(d.id==data.id)
                    return data;
                return d;
            });
            return { ...state, detail:{...detail, user_sizes:{ ...detail.user_sizes, data: newData, status_code, message, error:null }}}
        }

        case USER_DETAIL_SIZES_LIST_ERROR:{
            const { detail } = state;
            return { ...state, detail:{...detail, user_sizes:{ ...detail.user_sizes, error:{...action.payload} }}}
        }

        case USER_BALANCE_LOG_FETCH:{
          const { detail } = state;
          return { ...state, detail: { ...detail, user_balance_log:{ ...detail.user_balance_log, isFetch: action.payload } } }
        }

        case USER_BALANCE_LOG_LIST:{
          const { detail } = state;
          const { data, status_code, message } = action.payload;
          return { ...state, detail: {...detail, user_balance_log:{...detail.user_balance_log, list: { ...data }, status_code, message, error:null }}}
        }

        case USER_BALANCE_LOG_ERROR:{
          const { detail } = state;
          return { ...state, detail: {...detail, user_balance_log:{...detail.user_balance_log, error:{...action.payload} }}}
        }

        case SELLER_POINT_HISTORY_FETCH:{
            const { detail } = state;
            return { ...state, detail: { ...detail, seller_point_history:{ ...detail.seller_point_history, isFetch: action.payload } } }
        }

        case SELLER_POINT_HISTORY_LIST:{
            const { detail } = state;
            const { data, status_code, message } = action.payload;
            return { ...state, detail: {...detail, seller_point_history:{...detail.seller_point_history, list: { ...data }, status_code, message, error:null }}}
        }

        case SELLER_POINT_HISTORY_ERROR:{
            const { detail } = state;
            return { ...state, detail: {...detail, seller_point_history:{...detail.seller_point_history, error:{...action.payload} }}}
        }

        case FETCHING_HOLIDAY_MODE: {
          const { user_setting } = state;
          return { ...state, user_setting: {...user_setting, isSubmit: action.payload.status}}
        }

        case FORCE_HOLIDAY_MODE: {
          const { user_setting } = state;
          const { data, status_code, message } = action.payload
          return { ...state, user_setting: {...user_setting, data: { ...data }, status_code, message, error: null}}
        }

        case HOLIDAY_MODE_ERROR: {
          const { user_setting } = state;
          return { ...state, user_setting: {...user_setting, error: {...action.payload}}}
        }
        
        case USER_COMMISSION_RATE_SUBMIT: {
            const { userCommissions } = state;
            return { ...state, userCommissions: { ...userCommissions, isSubmit: action.payload } }
        }
        case USER_COMMISSION_RATE_ERROR:{
            const { userCommissions } = state;
            return { ...state, userCommissions: { ...userCommissions, error: { ...action.payload }, isSubmit: false } }
        }
        case USER_COMMISSION_RATE: {
            const { userCommissions, detail } = state;
            const { data, status_code, message } = action.payload
            return { ...state, detail: { ...detail, commissions: [...data] }, userCommissions: { ...userCommissions, data, status_code, message, error: null, isSubmit: false } }
        }


        case USER_STOCK_EXPORT_SUBMIT: {
            const { userStockExport } = state;
            return { ...state, userStockExport: { ...userStockExport, isSubmit: action.payload } }
        }
        case USER_STOCK_EXPORT_ERROR:{
            const { userStockExport } = state;
            return { ...state, userStockExport: { ...userStockExport, error: { ...action.payload.error }, isSubmit: false } }
        }
        case USER_STOCK_EXPORT: {
            const { userStockExport } = state;
            const { status_code, message, ...data } = action.payload
            return { ...state, userStockExport: { ...userStockExport, ...data, status_code, message, error: null, isSubmit: false } }
        }

        case USER_SHIPPING_FETCH:{
            const { detail } = state;
            return { ...state, detail: { ...detail, user_shipping:{ ...detail.user_shipping, isFetch: action.payload } } }
        }

        case USER_SHIPPING_LIST:{
            const { detail } = state;
            const { data, status_code, message } = action.payload;
            return { ...state, detail: {...detail, user_shipping:{...detail.user_shipping, list: { ...data }, status_code, message, error:null }}}
        }

        case USER_SHIPPING_ERROR:{
            const { detail } = state;
            return { ...state, detail: {...detail, user_shipping:{...detail.user_shipping, error:{...action.payload} }}}
        }

        case USER_SHIPPING_SAVE_SUBMIT: {
          const { detail } = state;
          return { 
            ...state, 
            detail: { 
              ...detail, 
              user_shipping: { 
                ...detail.user_shipping 
              } 
            }, 
            isSubmit: action.payload,
          }
        }
        case USER_SHIPPING_SAVE_ERROR:{
          const { detail } = state;
          return { 
            ...state, 
            detail: { 
              ...detail, 
              user_shipping: { 
                ...detail.user_shipping 
              } 
            }, 
            error: { 
              ...action.payload 
            },
            isSubmit: action.payload, 
          }
        }
        case USER_SHIPPING_SAVE: {
          const { detail } = state;
          const { data, status_code, message } = action.payload
          return { 
            ...state, 
            detail: { 
              ...detail, 
              user_shipping: { 
                ...detail.user_shipping, 
                list: { 
                  ...data 
                } 
              } 
            }, 
            status_code, 
            message, 
            error: null,
            isSubmit: action.payload, 
          }
        }

        case USER_SHIPPING_UPDATE_SUBMIT: {
          const { detail } = state;
          return { 
            ...state, 
            detail: { 
              ...detail, 
              user_shipping: { 
                ...detail.user_shipping 
              } 
            }, 
            isSubmit: true,
          }
        }
        case USER_SHIPPING_UPDATE_ERROR:{
          const { detail } = state;
          return { 
            ...state, 
            detail: { 
              ...detail, 
              user_shipping: { 
                ...detail.user_shipping 
              } 
            }, 
            error: { 
              ...action.payload 
            },
            isSubmit: false, 
          }
        }
        case USER_SHIPPING_UPDATE: {
          const { detail } = state;
          const { data, status_code, message } = action.payload
          return { 
            ...state, 
            detail: { 
              ...detail, 
              user_shipping: { 
                ...detail.user_shipping, 
                data 
              } 
            }, 
            status_code, 
            message, 
            error: null,
            isSubmit: false, 
          }
        }
        default:return state;
    }
}
export default reducer;