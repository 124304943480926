import React from 'react';
import {
  Form, 
  FormGroup,
  Label,
  Input } from 'reactstrap';
import propTypes from 'prop-types';

import { perPageOptions } from '../../../../constants/kickavenue'

const sortOptions = [{
    value:"" , label:"Default"
  },
  {
    value:"updatedAt_desc" , label:"Last Updated"
  },
  {
    value:"createdAt_desc" , label:"Newest"
  },
  {
    value:"createdAt_asc" , label:"Older"
  },
  {
    value:"name_asc" , label:"Invoice A-Z"
  },
  {
    value:"name_desc" , label:"Invoice Z-A"
  },
  {
    value:"grossAmount_desc" , label:"Termahal"
  },
  {
    value:"grossAmount_asc" , label:"Termurah"
  }];

const SortForm = props => (
  <Form inline className="form-filter">
    <FormGroup className="mb-2 mr-3">
      <Label className="mr-sm-3">
        Show
      </Label>
      <Input
        type="select"
        name="per_page"
        onChange={props.onInputChange}
        value={props.per_page}
      >
        {perPageOptions.map((option,i)=><option value={option.value} key={i}>{option.label}</option>)}
      </Input>
    </FormGroup>
    <FormGroup className="mb-2 mr-3">
      <Label className="mr-sm-3">
        Sort By
      </Label>
      <Input
        type="select"
        name="sort_by"
        onChange={props.onInputChange}
        value={props.sort_by}
      >
        {sortOptions.map((option,i)=><option value={option.value} key={i}>{option.label}</option>)}
      </Input>
    </FormGroup>
  </Form>

)
export default SortForm;

SortForm.propTypes= {
  per_page: propTypes.oneOfType([propTypes.number, propTypes.string]),
  sort_by: propTypes.string,
  sortOptions: propTypes.array,
  onInputChange: propTypes.func.isRequired
}

SortForm.defaultProps= {
  per_page: 15,
  sort_by: '',
  sortOptions: sortOptions
}