import React from 'react';
import propTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Button,
  ButtonGroup
} from 'reactstrap';
import { Link } from 'react-router-dom';
import DateToHuman from '../../../components/DateToHuman';
import { TextRupiah } from '../../../components/Text';
import { checkChange } from '../../../utils/form';
import { updateSale, doMarkedSale, getSaleNotes, setReadSaleNotes, getDetailSale2, getTrackingShipmentDetail } from '../../../actions/saleAction';
import { doMarkedConsignment, updateConsignment, getDetailConsignment, getConsignmentNotes, setReadConsignmentNotes } from '../../../actions/consignmentAction';
import OrderSummariesColumn from '../RowItem/OrderSummariesColumn';
import ReactLoading from 'react-loading';
import { getPaymentType, getPaymentVia } from '../../../constants/kickavenue';
import { ModalMarkedConsignment, ModalTrackingShipment } from '../../../components/Modals';
import ButtonStarred from '../RowItem/ButtonStarred';

class CourierRowItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      autoDisburse: props.autoDisburse,
      _autoDisburseEdit: props.autoDisburse,
      updatedAt: props.updatedAt,
      marked: props.marked,
      _markedEdit: props.marked,
      _isModalMarked: false,
      markedAt: props.markedAt || '',
      markedBy: props.markedBy || '',
      closeMarkedAt: props.closeMarkedAt || '',
      closeMarkedBy: props.closeMarkedBy || '',
      notes: props.notes || [],
      notesPage: 1,
      noteNextPage: null,
      unreadNoteCount: props.unreadNoteCount || 0,
      isConfirmation: false,
      purchasePrice: props.purchasePrice,
      compensation: props.compensation,
      _isModalTracking: false,
      shippingUpdate: null
    }
    this._handleCheckedChange = this._handleCheckedChange.bind(this);
    this._handleMarkedChange = this._handleMarkedChange.bind(this);
    this._handleTrackingChange = this._handleTrackingChange.bind(this);
    this._handleModalMarkedOnSubmit = this._handleModalMarkedOnSubmit.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.consignment.isSubmit !== this.props.consignment.isSubmit && prevProps.consignment.isSubmit) {
      if (prevProps.consignment.error === null) {
        const { id, autodisburse, updated_at, marked, open_marked_at, open_marked_by, close_marked_at, close_marked_by, purchase_price } = this.props.consignment.detail;
        if (prevProps.id === id) {
          this.setState({
            autoDisburse: autodisburse,
            _autoDisburseEdit: autodisburse,
            marked: marked,
            _markedEdit: marked,
            // markedNotes: marked_notes || '',
            markedAt: open_marked_at || '',
            markedBy: open_marked_by || '',
            closeMarkedAt: close_marked_at || '',
            closeMarkedBy: close_marked_by || '',
            // notes: sale_notes,
            // unreadNoteCount: unread_notes_count,
            updatedAt: updated_at,
            purchasePrice: purchase_price
          })
        }
      }
      else {
        const { id, message } = this.props.consignment.error;
        if (id && this.props.id === id) {
          alert(message)
          this.setState({
            _autoDisburseEdit: this.state.autoDisburse,
            _markedEdit: this.state.marked,
          })
        }
      }
    }

    if (prevProps.consignment.notes.isFetch && !this.props.consignment.notes.isFetch) {
      const { list: { data, next_page_url, error }, sell_consignment_id } = this.props.consignment.notes;
      if (this.props.id === sell_consignment_id) {
        if (error) {
          alert('Error while fetching notes');
        } else {
          const { loginId } = this.props;
          const ids = data.filter(item => !item.read && item.user_id !== loginId).map(({ id }) => id);
          if (ids.length) {
            this.props.setReadConsignmentNotes(this.props.id, { ids })
          }
          this.setState({
            notes: this.state.notesPage === 1 ? [...data] : [...data, ...this.state.notes],
            noteNextPage: next_page_url ? this.state.notesPage + 1 : null
          })
        }
      }
    }

    if (prevProps.consignment.notes.isSubmit && !this.props.consignment.notes.isSubmit) {
      const { sell_consignment_id } = this.props.consignment.notes;
      this.setState({
        unreadNoteCount: sell_consignment_id === this.props.id ? 0 : this.state.unreadNoteCount,
      });
    }

    // if(prevProps.consignment.notes.isSubmit && !this.props.consignment.notes.isSubmit){
    //   const { readNotes: { data }, sell_consignment_id, readNotes: { error } } = this.props.consignment.notes;
    //   if(this.props.id === sell_consignment_id && !error){
    //     if(data.total > 0) {
    //       this.props.getDetailConsignment(this.props.id);
    //     }
    //   }
    // }

    if (!prevState._isModalMarked && this.state._isModalMarked) {
      this.setState({
        notesPage: 1,
        noteNextPage: null
      }, () => {
        this.props.getConsignmentNotes(this.props.id, {
          params: { per_page: 3, page: this.state.notesPage }
        });
      })
    }

    if (prevProps.sale.shippingUpdate.isFetch && !this.props.sale.shippingUpdate.isFetch) {
      const { data, error } = this.props.sale.shippingUpdate;
      if (!error) {
        this.setState({
          shippingUpdate: data
        })
      }
    }
  }

  shouldComponentUpdate(nextProps) {
    if (nextProps.consignment.isSubmit) {
      return false;
    }
    if (!nextProps.consignment.isSubmit && nextProps.consignment.isSubmit !== this.props.consignment.isSubmit) {
      if (nextProps.consignment.error) {
        const { error: { id } } = nextProps.consignment;
        if (id && id != this.props.id) {
          return false;
        }
      }
      else {
        if (nextProps.consignment.detail.id && nextProps.consignment.detail.id != this.props.id) {
          return false;
        }
      }
    }
    return true;
  }

  _handleTrackingChange() {
    this.setState({
      _isModalTracking: !this.state._isModalTracking,
    });
  }

  _handleCheckedChange(e) {
    this.setState({
      ...checkChange(e, this.state)
    }, () => {
      const payload = {
        autodisburse: this.state._autoDisburseEdit
      }
      this.props.update(this.props.id, payload)
    });
  }

  _handleModalMarkedOnSubmit({ marked_notes, marked = null }) {
    if (marked === false) {
      const payload = { marked };
      if (marked_notes) {
        payload['marked_notes'] = marked_notes;
      }
      this.setState({
        _markedEdit: marked,
        _isModalMarked: false,
        // isConfirmation: false
      });
      this.props.doMarkedConsignment(this.props.id, payload)
      return;
    }
    this.setState({
      _markedEdit: this.state.closeMarkedAt ? (marked !== null ? marked : this.state._markedEdit) : true,
      _isModalMarked: false,
      // isConfirmation: false,
      markedNotes: marked_notes
    }, () => {
      const payload = {
        // status : param.status,
        marked: this.state._markedEdit,
        marked_notes
      }
      this.props.doMarkedConsignment(this.props.id, payload)
    })
  }

  _handleMarkedChange() {
    this.setState({
      // isConfirmation: false,
      _isModalMarked: !this.state._isModalMarked,
      _markedEdit: this.state.marked //cancel toggle
    });
  }

  _openPurchasePriceModal = e => {
    e.preventDefault();
    this.props.openPurchasePriceModal({
      id: this.props.id,
      invoiceNumber: this.props.invoiceNumber,
      purchasePrice: this.state.purchasePrice
    })
  }

  render() {
    const {
      id,
      rowId,
      quantity,
      sellerEmail,
      invoiceNumber,
      totalAmount,
      orderDate,
      displayName,
      SKU,
      size,
      colour,
      preOrder,
      preVerified,
      updatedAt,
      isSelected,
      onOpenDeliverModal,
      consignmentId,
      category,
      sneakersCondition,
      rack,
      legitCheckLogs,
      stockStatus,
      createdAt,
      price,
    } = this.props;
    const actionButtons = (isSelected === true ?
      <ReactLoading
        type="bars"
        color="green"
        height={20}
        width={30}
      /> :
      <div>
        <ButtonGroup>
          {!this.props.kaSent ?
            <Button
              color="success"
              size="sm"
              title="Deliver Now!"
              onClick={onOpenDeliverModal}
            >
              <i className="fa fa-truck"></i>
            </Button>
            : null}
          <Link
            to={`/consignment/${id}`}
            className="btn btn-sm btn-secondary"
            target="_blank"
            title="More Detail..."
          >
            <i className="fa fa-external-link"></i>
          </Link>
        </ButtonGroup>
      </div>);
    return (
      <tr>
        <ModalMarkedConsignment
          isOpen={this.state._isModalMarked}
          isConfirmation={this.state.isConfirmation}
          toggle={this._handleMarkedChange}
          invoiceNumber={invoiceNumber}
          marked={this.state.marked}
          markedAt={this.state.markedAt}
          markedBy={this.state.markedBy}
          closeMarkedAt={this.state.closeMarkedAt}
          markedNotes={this.state.markedNotes}
          notes={this.state.notes}
          unreadNoteCount={this.state.unreadNoteCount}
          hasNextPage={this.state.noteNextPage}
          loginId={this.props.loginId}
          onSubmit={this._handleModalMarkedOnSubmit}
          onLoadMoreNotes={() => {
            const { notesPage, noteNextPage } = this.state;
            if (!noteNextPage) return;
            this.setState({
              noteNextPage: null,
              notesPage: notesPage + 1
            }, () => {
              this.props.getConsignmentNotes(this.props.id, {
                params: { per_page: 3, page: this.state.notesPage }
              });
            })
          }}
        />
        <ModalTrackingShipment
          shippingUpdate={this.state.shippingUpdate}
          isLoading={this.props.sale.shippingUpdate.isFetch}
          isOpen={this.state._isModalTracking}
          toggle={this._handleTrackingChange}
        />
        <th className="v-center" scope="row">{rowId}</th>
        <td className="v-center">
          <OrderSummariesColumn
            isDelivering
            id={id}
            quantity={quantity}
            sellerEmail={sellerEmail}
            SKU={SKU}
            orderDate={orderDate}
            displayName={displayName}
            size={size}
            colour={colour}
            preOrder={preOrder}
            preVerified={preVerified}
            category={category}
            sneakersCondition={sneakersCondition}
            unreadNoteCount={this.state.unreadNoteCount}
            legitCheckLogs={legitCheckLogs}
            stockStatus={stockStatus}
            consignmentId={consignmentId}
            createdAt={createdAt}
          />
        </td>
        <td className="v-center">
          <span className="text-uppercase rack-span" style={{ fontSize: '.75rem' }}>{rack ? rack : '-'}</span>
        </td>
        <td className="v-center">
          <div>
            <TextRupiah
              prefix="Rp. "
              value={price}
            />
          </div>
          <div>
            <a
              href="#"
              className="mr-1"
              style={{ textDecoration: 'underline', color: '#4a4a4a', fontSize: '.75rem' }}
              onClick={e => {
                e.preventDefault();
                this.setState({ _isModalMarked: true, isConfirmation: false })
              }}
            >
              <i className="fa fa-pencil"></i> {this.state.markedAt ? 'view' : 'add'} notes
              {/* <i className="fa fa-pencil"></i> add notes */}
            </a>
            <ButtonStarred
              title={this.state._markedEdit ? "Remove mark!" : "Mark!"}
              value={this.state._markedEdit}
              onClick={() => {
                const value = !this.state._markedEdit;
                this.setState({
                  _isModalMarked: true,
                  isConfirmation: !value,
                  _markedEdit: value
                })
              }}
            />
          </div>
        </td>
        <td className="v-center">
          <div style={{ fontSize: '.75rem' }}>
            <strong>{this.props.courier}</strong>
          </div>
          <a
            href="#"
            className="mr-1"
            style={{ textDecoration: 'underline', color: '#4a4a4a', fontSize: '.75rem' }}
            onClick={e => {
              e.preventDefault()
              this.setState({
                _isModalTracking: true,
                shippingUpdate: null
              })
            }}
          >Tracking Shipment</a><br />
        </td>
        <td className="v-center"><DateToHuman value={updatedAt} id={`tooltip_${rowId}`} /></td>
        <td className="v-center">
          {actionButtons}
        </td>
      </tr>
    )
  }
}
const mapStateToProps = ({ consignment, sale }) => ({
  consignment, sale
})
const mapDispatchToProps = (dispatch) => ({
  // getDetailSale2: (id) => dispatch(getDetailSale2(id)),
  getConsignmentNotes: (id, query) => dispatch(getConsignmentNotes(id, query)),
  setReadConsignmentNotes: (id, body) => dispatch(setReadConsignmentNotes(id, body)),
  update: (id, payload) => dispatch(updateConsignment(id, payload)),
  doMarkedConsignment: (id, payload) => dispatch(doMarkedConsignment(id, payload)),
  getDetailConsignment: (id, query) => dispatch(getDetailConsignment(id, query)),
})

export default connect(mapStateToProps, mapDispatchToProps)(CourierRowItem);

CourierRowItem.defaultProps = {
  totalAmount: 0,
  isSelected: false,
  hijacked: false,
  status: 'NEW'
}

CourierRowItem.propTypes = {
  rowId: propTypes.number.isRequired,
  id: propTypes.number.isRequired,
  sellerEmail: propTypes.string,
  autoDisburse: propTypes.number,
  totalAmount: propTypes.oneOfType([propTypes.number, propTypes.string]),
  orderDate: propTypes.string,
  invoiceNumber: propTypes.string,
  size: propTypes.string,
  displayName: propTypes.string,
  SKU: propTypes.string,
  colour: propTypes.string,
  preOrder: propTypes.oneOfType([propTypes.bool, propTypes.string]),
  preVerified: propTypes.oneOfType([propTypes.bool, propTypes.string]),
  updatedAt: propTypes.string,
  hijacked: propTypes.bool,
  status: propTypes.string,
  onOpenDeliverModal: propTypes.func.isRequired,
  marked: propTypes.bool,
  status: propTypes.string,
  markedNotes: propTypes.string,
  markedAt: propTypes.string,
  rack: propTypes.string,
}
