import {
  AUTH_TOKEN_KEY,
  GET_LIST_BID,
  FETCHING_BID,
  SUBMITING_BID,
  GET_DETAIL_BID,
  DELETE_BID,
  BID_ERROR,
  BID_ROUTE_API
} from '../constants';
import api from '../utils/Api';
import { manipulateErrors, checkAuthorized } from '../utils';
import { logout } from './authAction';

export const FETCH_LIST_REQ = "FETCH_LIST_REQ";
export const FETCH_DETAILS_REQ = "FETCH_DETAILS_REQ";
export const DELETE_BID_REQ = "DELETE_BID_REQ";

const onErrorRequest = ({ requestType, error, id }) =>{
  if(!checkAuthorized(manipulateErrors(error))) return logout();
  return {
    type: BID_ERROR,
    payload: {
      id,
      requestType,
      ...manipulateErrors(error)
    }
  }
}

const onSuccessRequest = (type, payload) =>({
  type,
  payload
})

const onFetchedRequest = (payload) => ({
  type: FETCHING_BID,
  payload
})

const onSubmitedRequest = (payload) => ({
  type: SUBMITING_BID,
  payload
})


export const getBidList = query => {
  return dispatch => {
    dispatch(onFetchedRequest({ requestType: FETCH_LIST_REQ, bool: true }));
    api({
      Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
    }).get(BID_ROUTE_API, query)
    .then(res => {
			dispatch(onSuccessRequest(GET_LIST_BID, res.data));
      dispatch(onFetchedRequest({ requestType:FETCH_LIST_REQ, bool: false }));
    })
    .catch(err => {
      dispatch(onErrorRequest({ requestType: FETCH_LIST_REQ, error: err }));
      dispatch(onFetchedRequest({ requestType: FETCH_LIST_REQ, bool: false }));
    })
  }
}

export const getBidDetail = id => {
  return dispatch => {
    dispatch(onFetchedRequest({ requestType: FETCH_DETAILS_REQ, bool: true }));
    api({
      Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
    }).get(`${BID_ROUTE_API}/${id}`)
    .then(res => {
			dispatch(onSuccessRequest(GET_DETAIL_BID, res.data));
      dispatch(onFetchedRequest({ requestType: FETCH_DETAILS_REQ, bool: false }));
    })
    .catch(err => {
      dispatch(onErrorRequest({ requestType: FETCH_DETAILS_REQ, error: err, id }));
      dispatch(onFetchedRequest({ requestType: FETCH_DETAILS_REQ, bool: false }));
    })
  }
}

export const deleteBid = id => {
  return dispatch => {
    dispatch(onSubmitedRequest({ requestType: DELETE_BID_REQ, bool: true }));
    api({
      Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
    }).delete(`${BID_ROUTE_API}/${id}`)
    .then(res => {
			dispatch(onSuccessRequest(DELETE_BID, res.data));
      dispatch(onSubmitedRequest({ requestType: DELETE_BID_REQ, bool: false }));
    })
    .catch(err => {
      dispatch(onErrorRequest({ requestType: DELETE_BID_REQ, error: err, id }));
      dispatch(onSubmitedRequest({ requestType: DELETE_BID_REQ, bool: false }));
    })
  }
}