import React from 'react';
import propTypes from 'prop-types';
import { Link } from 'react-router-dom';
import DateToHuman from '../../../../components/DateToHuman';
import { Button, ButtonGroup } from 'reactstrap';
import { getPaymentType, getConciergeStatus, getPaymentVia } from '../../../../constants/kickavenue';
import OrderSummariesColumn from '../../RowItem/OrderSummariesColumn';

const RowItem = props => {
  const {
    id,
    rowId,
    userId,
    userEmail,
    condition,
    condition2,
    paymentMethod,
    paymentBankTransfer,
    status,
    bank,
    onOpenPaymentConciergeModal,
    onOpenFailedConciergeModal,
    paymentVia,
    onOpenCreditCard,
    name,
    productName,
    phoneNumber,
    createdAt,
    updatedAt,
  } = props;
  const via = getPaymentVia(paymentVia);
  return (
    <tr>
      <th className="v-center" scope="row">{ rowId }</th>
      <td className="v-center">
        <OrderSummariesColumn
          id={id}
          name={name}
          productName={productName}
          phoneNumber={phoneNumber}
          condition={condition}
          condition2={condition2}
          userId={userId}
          userEmail={userEmail}
          paymentMethod={paymentMethod}
          paymentBankTransfer={paymentBankTransfer}
          createdAt={createdAt}
        />
      </td>
      <td className="v-center">
        <div>
          {
            bank && paymentMethod === 'virtual_account'?
            <span style={{fontSize: '.75rem'}}>{bank+ '-'+ getPaymentType(paymentMethod)}</span>
            : 
            (paymentMethod === null? 'Not Set' : getPaymentType(paymentMethod))
          }
        </div>
        { via !== null? <div><span style={{fontSize: '.75rem'}}>via <i>{via}</i></span></div>: null}
        {
          paymentVia !== null && paymentVia === 'vt' && paymentMethod=== "credit_card" ? 
          <button className="btn btn-sm btn-secondary" onClick={onOpenCreditCard} target="_blank" title="Show More Info"><i className="fa fa-info-circle"></i> More Info</button>
          :
          null
        }
      </td>
      <td className="v-center"><DateToHuman  value={updatedAt} id={`tooltip_${rowId}`}/></td>
      <td className="v-center">
        { status && getConciergeStatus(status) }
      </td>
      <td className="v-center">
        <ButtonGroup>
          <Button
            color="primary"
            size="sm"
            title={"Processed"}
            onClick={onOpenPaymentConciergeModal}
          >
            <i className="fa fa-check"></i>
          </Button>
          <Button
            color="danger"
            size="sm"
            title={"Failed"}
            onClick={onOpenFailedConciergeModal}
          >
            <i className="fa fa-times"></i>
          </Button>
          <Link to={`/concierge/${id}`} className="btn btn-sm btn-secondary" target="_blank" title="More Detail..."><i className="fa fa-external-link"></i></Link>
        </ButtonGroup>
      </td>
    </tr>
  )
}

export default RowItem;

RowItem.defaultProps = {
  paymentMethod: 'bank_transfer',
  status: 'NEW',
}

RowItem.propTypes={
  rowId: propTypes.number.isRequired,
  id: propTypes.number.isRequired,
  updatedAt: propTypes.string,
  paymentMethod: propTypes.string,
  paymentVia: propTypes.string,
  bank: propTypes.string,
  status: propTypes.string,
  name: propTypes.string,
  productName: propTypes.string,
  phoneNumber: propTypes.string,
  onOpenCreditCard: propTypes.func.isRequired,
}
