import {
	AUTH_TOKEN_KEY,
	REWARD_ROUTE_API,
	REWARD_FETCHING,
	REWARD_DETAIL_FETCH,
	REWARD_LIST_FETCH,
	REWARD_ITEM_LIST,
	REWARD_ITEM_FETCH,
	REWARD_ITEM_ERROR,
	REWARD_ERROR,
	REWARD_ADD,
	REWARD_UPDATE,
	REWARD_DELETE,
	REWARD_CLEAR_FORM
} from '../constants';
import api from '../utils/Api';
import { manipulateErrors, checkAuthorized } from '../utils';
import { logout } from './authAction';

const onSuccess=(res,type)=>(
	{
		type:type,
		payload:res		
	}
)

const onError=(err, type=REWARD_ERROR)=>{    
	if(!checkAuthorized(manipulateErrors(err)))
    return logout();
  return {
    type,
    payload:manipulateErrors(err)
  }
}

const isFetch=(payload)=>(
  {
    type:REWARD_FETCHING,
    payload
  }
)

const isRewardFetch=(payload)=>(
	{
		type:REWARD_ITEM_FETCH,
		payload
	}
)

export const getListReward=(query)=>{
	return dispatch=>(
		dispatch(isFetch({type:'fetch',status:true})),
		api({
			Authorization:'Bearer '+localStorage.getItem(AUTH_TOKEN_KEY)
		}).get(REWARD_ROUTE_API,query)
		.then(res => {
			dispatch(onSuccess(res.data,REWARD_LIST_FETCH));
			dispatch(isFetch({type:'fetch',status:false}));
		})
		.catch(err => {
			dispatch(onError(err));
			dispatch(isFetch({type:'fetch',status:false}));
		})		
	)
}

export const getDetailReward=(id)=>{
	return dispatch=>(
		dispatch(isFetch({type:'fetch',status:true})),
		api({
			Authorization:'Bearer '+localStorage.getItem(AUTH_TOKEN_KEY)
		}).get(`${REWARD_ROUTE_API}/${id}`)
		.then(res => {
			dispatch(onSuccess(res.data, REWARD_DETAIL_FETCH));
			dispatch(isFetch({type:'fetch',status:false}));
		})
		.catch(err => {
			dispatch(onError(err));
			dispatch(isFetch({type:'fetch',status:false}));
		})
	)
}

export const getListRewardItem=(id, query)=>{
	return dispatch=>(
		dispatch(isRewardFetch({type:'fetch',status:true})),
		api({
			Authorization:'Bearer '+localStorage.getItem(AUTH_TOKEN_KEY)
		}).get(`${REWARD_ROUTE_API}/${id}/rewarditems`, query)
		.then(res => {
			dispatch(onSuccess(res.data, REWARD_ITEM_LIST));
			dispatch(isRewardFetch({type:'fetch',status:false}));
		})
		.catch(err => {
			dispatch(onError(err,REWARD_ITEM_ERROR));
			dispatch(isRewardFetch({type:'fetch',status:false}));
		})
	)
}

export const clearFormReward =()=>dispatch=>dispatch({type:REWARD_CLEAR_FORM})

export const deleteReward = (id) =>{
	return dispatch => (
		dispatch(isFetch({type:'submit',status:true})),
		api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).delete(`${REWARD_ROUTE_API}/${id}`)
		.then(res => {
			dispatch(onSuccess(res.data, REWARD_DELETE));
			dispatch(isFetch({type:'submit',status:false}));
		})
		.catch(err => {
			dispatch(onError(err));
			dispatch(isFetch({type:'submit',status:false}));
		})
	)
}

export const saveReward = (payload) => {
	const {id,...rest}=payload;
  return dispatch =>{
    if(id===undefined||id===null)
			dispatch(createReward({...rest}));
		else 
			dispatch(updateReward(id,{...rest}));
	}
}


const createReward=(payload)=>{
	return dispatch => (
		dispatch(isFetch({type:'submit',status:true})),
		api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).post(`${REWARD_ROUTE_API}`,payload)
		.then(res=>{
			dispatch(onSuccess(res.data,REWARD_ADD));
			dispatch(isFetch({type:'submit',status:false}));
		})
		.catch(err => {
			dispatch(onError(err));
			dispatch(isFetch({type:'submit',status:false}));
		})
	)
}

const updateReward=(id, payload)=>{
	return dispatch => (
		dispatch(isFetch({type:'submit',status:true})),
		api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).put(`${REWARD_ROUTE_API}/${id}`,payload)
		.then(res=>{
			dispatch(onSuccess(res.data,REWARD_UPDATE))
			dispatch(isFetch({type:'submit',status:false}));
		})
		.catch(err => {
			dispatch(onError(err));
			dispatch(isFetch({type:'submit',status:false}));
		})
	)
}