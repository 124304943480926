export const getProductCondition = (product) => {
    if (!product) {
        return "";
    }
    
    const isNew = product?.sneakers_condition === "BARU";

    if (!isNew) {
        return "Used";
    }

    const hasDefect = product?.defects.some((defect) => defect.value === true);
    const hasOtherDefect =
        product?.box_condition != "SEMPURNA" ||
        product?.missing_accessories ||
        product?.yellowing ||
        product?.display_item ||
        product?.sneakers_defect;
    const isDefect = hasDefect || hasOtherDefect;

    if (isDefect) {
        return "Standard 99%";
    }

    return "Brand New";
};

export const getProductStatus = (product) => {
    if (!product) {
        return "";
    }

    if (product?.pre_verified === true && product?.pre_order === false) {
        return "Consignment";
    }

    if (product?.pre_order === true && product?.pre_verified === false) {
        return "Pre-Order";
    }

    return "Standard";
};
