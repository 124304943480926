export const PENDING_STATUS = 'NEW';
export const COMPLETED_STATUS = 'COMPLETED';
export const REJECTED_STATUS = 'REJECTED';
export const IN_PROGRESS_STATUS = 'IN_PROGRESS';
export const DISBURSEMENT_BALANCE_OUT = 'BALANCE_OUT';
export const DISBURSEMENT_BALANCE_IN = 'BALANCE_IN';
export const MODAL_CONFIRM_DELETE_ACTION = 'DELETE_DISBURSEMENT';
export const MODAL_CONFIRM_ACCEPT_ACTION = 'ACCEPT_DISBURSEMENT';
export const MODAL_CONFIRM_REJECT_ACTION = 'REJECT_DISBURSEMENT';

export const INCREASE_TYPE = 'INCREASE';
export const DECREASE_TYPE = 'DECREASE';

export const getDisbursementTypeStr = disbursementType => {
  switch(disbursementType){
    case DISBURSEMENT_BALANCE_OUT: return 'BALANCE OUT';
    case DISBURSEMENT_BALANCE_IN: return 'BALANCE IN';
    default: return 'UNDEFINED';
  }
}

export const getDisbursementStatusStr = disbursementStatus => {
  switch(disbursementStatus){
    case REJECTED_STATUS: return 'Rejected';
    case COMPLETED_STATUS: return 'Completed';
    case IN_PROGRESS_STATUS: return 'In Progress';
    default: return 'Pending';
  }
}