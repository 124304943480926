export const userSellApparelConditions = [
  {
    name: "no_tags",
    en: "No Tags",
    label: "No Tags"
  },
  {
    name: "missing_original",
    en: "Missing Original Packaging",
    label: "Missing Original Packaging"
  },
  {
    name: "manufacture_defect",
    en: "Manufacture Defect",
    label: "Manufacture Defect"
  }
];

export const userSellDefaultConditions = [
  {
    name: "missing_accessories",
    en: "Missing Accessories",
    label: "Missing Accessories"
  },
  {
    name: "yellowing",
    en: "Yellowing",
    label: "Yellowing"
  },
  {
    name: "display_item",
    en: "Display Item",
    label: "Display Item"
  },
  {
    name: "sneakers_defect",
    en: "Defect",
    label: "Defect"
  }
];

export const userSellSneakerConditions = [
  {
    name: "no_extra_laces",
    en: "No Extra Laces",
    label: "No Extra Laces"
  },
  {
    name: "no_wrap_paper",
    en: "No Wrap Paper",
    label: "No Wrap Paper"
  },
  {
    name: "no_tags",
    en: "No Tags",
    label: "No Tags"
  },
]

export const userSellHandbagConditions = [
  {
    name: "missing_accessories",
    en: "Missing Accessories",
    label: "Missing Accessories"
  },
  {
    name: "discoloration",
    en: "Discoloration",
    label: "Discoloration"
  },
  {
    name: "scratches",
    en: "Scratches",
    label: "Scratches"
  },
  {
    name: "transferred_color",
    en: "Transferred Color",
    label: "Transferred Color"
  },
  {
    name: "sign_of_wear",
    en: "Sign of Wear",
    label: "Sign of Wear"
  },
  {
    name: "box",
    en: "Box",
    label: "Box",
    inclusions: true
  },
  {
    name: "dust_bag",
    en: "Dust Bag",
    label: "Dust Bag",
    inclusions: true
  },
  {
    name: "tag",
    en: "Tag",
    label: "Tag",
    inclusions: true
  },
  {
    name: "mirror",
    en: "Mirror",
    label: "Mirror",
    inclusions: true
  },
  {
    name: "straps",
    en: "Straps",
    label: "Straps",
    inclusions: true
  },
  {
    name: "authentication_card",
    en: "Authentication Card",
    label: "Authentication Card",
    inclusions: true
  }
];

export const userSellLifestyleConditions = [
  {
    name: "no_tags",
    en: "No Tags",
    label: "No Tags"
  },
  {
    name : "missing_original",
    en: "Missing Original Packaging",
    label: "Missing Original Packaging"
  },
  {
    name: "manufacture_defect",
    en: "Manufacture Defect",
    label: "Manufacture Defect"
  }
];

export const sneakersConditionOption = [{
  value: 'BARU',
  label: 'Brand New',
  categories: ['sneakers', 'apparels', 'handbags','lifestyles'],
  en: {
    handbags: 'Brand New',
  }
},{
  value: 'BEKAS',
  label: 'Preowned',
  categories: ['sneakers', 'apparels','lifestyles']
},{
  value: 'PRISTINE',
  label: 'Pristine',
  categories: ['handbags']
},{
  value: 'GOOD',
  label: 'Good',
  categories: ['handbags']
},{
  value: 'WELL_USED',
  label: 'Well Used',
  categories: ['handbags']
},{
  value: 'LIKE_NEW',
  label: 'Like New',
  categories: ['handbags']
},{
  value: 'VINTAGE',
  label: 'Vintage',
  categories: ['handbags']
}];

export const brandConditions = [{
  slug: "bike",
  conditions: ['manufacture_defect', 'unassambled', 'missing_accessories', 'scratches', 'repainted']
},{
  slug: "games-consoles",
  conditions: ['manufacture_defect', 'reconditioned', 'missing_accessories', 'scratches', 'no_warranty']
},{
  slug: "collectibles",
  conditions: ['manufacture_defect', 'display_item', 'missing_accessories', 'scratches', 'discoloration']
}]
