import React, {PureComponent as Component} from 'react';
import propTypes from 'prop-types';

export default class PaginationSummaries extends Component{
  render(){
    return (this.props.total||null) && (
      <p className="pagination-summaries text-right">
        {`Showing ${this.props.from} to ${this.props.to} of ${this.props.total} items.`}
      </p>
    )
  }
}
PaginationSummaries.propTypes={
  from:propTypes.number,
  to:propTypes.number,
  total:propTypes.number
}
PaginationSummaries.defaultProps={
  from:0,
  to:0,
  total:0
}