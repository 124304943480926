import React from 'react';
import propTypes from 'prop-types';
import Select from 'react-select';

import api from '../../../../utils/Api';
import { AUTH_TOKEN_KEY } from '../../../../constants';

class SelectMetaAsync extends React.Component{
  constructor(props){
    super(props);
    this.loadSelectOptions = this.loadSelectOptions.bind(this);
    this._loadOptions = this._loadOptions.bind(this);
  }

  loadSelectOptions(input){
    return this.refs[this.props.id].loadOptions(input);
  }

  _loadOptions(input){
    const myToken = localStorage.getItem(AUTH_TOKEN_KEY);
    if(input.length < this.props.typedLength)
      return new Promise((resolve) => resolve([]));
    const headers = {
      'Authorization': `Bearer ${myToken}`
    };
    const params = {
      keyword: input,
      ...this.props.paramsApi
    }
    return api(headers).get('/admins/meta', { params })
    .then(({ data }) => {
      const products = data.data.data.map(item=>{
        return {
          id: item.id,
          name: item.name
        }
      })
      if(this.props.onSelectSearch){
        this.props.onSelectSearch(input)
      }
      return { options: products };
    });
  }

  render(){
    return(
      <div>
        {
          this.props.create?(
            <Select.AsyncCreatable
              valueKey="id"
              labelKey="name"
              ref={this.props.id}
              defaultOptions
              loadOptions={this._loadOptions}
              cacheOptions={this.props.cacheOptions}
              id={this.props.id}
              name={this.props.name}
              value={this.props.value}
              placeholder={this.props.placeholder}
              noResultsText={this.props.noResultsText}
              onChange={this.props.onSelectChange}
              // onOpen={this.props.onSelectOpen}
              disabled={this.props.isDisabled}
              multi={this.props.isMulti}
            />

          ):(
            <Select.Async
              valueKey="id"
              labelKey="name"
              ref={this.props.id}
              defaultOptions
              loadOptions={this._loadOptions}
              cacheOptions={this.props.cacheOptions}
              id={this.props.id}
              name={this.props.name}
              value={this.props.value}
              placeholder={this.props.placeholder}
              noResultsText={this.props.noResultsText}
              onChange={this.props.onSelectChange}
              onOpen={this.props.onSelectOpen}
              disabled={this.props.isDisabled}
              multi={this.props.isMulti}
            />
          )
        }

        
      </div>
    )
  }
}

export default SelectMetaAsync;

SelectMetaAsync.defaultProps = {
  cacheOptions: false,
  value: null,
  placeholder: 'Select a meta...',
  noResultsText: 'No Result!',
  paramsApi: {},
  typedLength: 0
}

SelectMetaAsync.propTypes = {
  id: propTypes.string.isRequired,
  name: propTypes.string,
  value: propTypes.object,
  placeholder: propTypes.string,
  typedLength: propTypes.number,
  noResultsText: propTypes.string,
  cacheOptions: propTypes.bool,
  paramsApi: propTypes.object,
  onSelectSearch: propTypes.func,
  onSelectChange: propTypes.func,
  onSelectOpen: propTypes.func,
  isMulti: propTypes.bool,
  isDisabled: propTypes.bool,
}