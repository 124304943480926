import {
  OPTION_ADD,
  OPTION_DELETE,
  OPTION_DETAIL_FETCH,
  OPTION_ERROR,
  OPTION_FETCHING,
  OPTION_LIST_FETCH,
  OPTION_UPDATE,
} from "../constants";

const initialState = {
  list: [],
  detail: {},
  isFetch: false,
  isSubmit: false,
  success: null,
  delete: false,
  error: null,
};

const onFetching = (lastState, payload) => {
  if (payload.type === "fetch")
    return {
      ...lastState,
      isFetch: payload.status,
      success: null,
      error: null,
    };
  else if (payload.type === "submit")
    return { ...lastState, isSubmit: payload.status };
  else if (payload.type === "detail")
    return {
      ...lastState,
      detail: { ...lastState.detail, isFetch: payload.status },
    };
  return { ...lastState, isFetch: false, isSubmit: false };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case OPTION_LIST_FETCH: {
      const { data, status_code, message } = action.payload;
      return {
        ...state,
        list: { ...data, message, status_code },
        detail: {},
        error: null,
      };
    }
    case OPTION_DETAIL_FETCH: {
      const { data, status_code, message } = action.payload;
      return {
        ...state,
        detail: {
          ...state.detail,
          ...data,
          delete: false,
          status_code,
        },
        message,
        error: null,
      };
    }
    case OPTION_ADD: {
      const { data, status_code, message } = action.payload;
      return {
        ...state,
        detail: { ...state.detail, ...data, message, status_code },
        success: message,
        error: null,
      };
    }
    case OPTION_UPDATE: {
      const { data, status_code, message } = action.payload;
      return {
        ...state,
        detail: { ...state.detail, ...data, message, status_code },
        success: message,
        error: null,
      };
    }
    case OPTION_DELETE: {
      const { data, status_code, message } = action.payload;
      return {
        ...state,
        detail: {
          ...state.detail,
          ...data,
          message,
          status_code,
        },
        delete: true,
        success: message,
        error: null,
      };
    }
    case OPTION_ERROR:
      return { ...state, ...action.payload, success: null };

    case OPTION_FETCHING:
      return onFetching(state, action.payload);
    default:
      return state;
  }
};

export default reducer;
