import { useEffect, useMemo, useState } from "react";
import api from "../../../../utils/Api";
import { AUTH_TOKEN_KEY } from "../../../../constants";

const useFetchSizeLists = () => {
    const [isError, setIsError] = useState(false);
    const [isFetching, setIsFetching] = useState(false);
    const [sizes, setSizes] = useState([]);

    const usSizes = useMemo(() => {
        if (sizes && sizes.length > 0) {
            return sizes.map((size) => size.US);
        }

        return [];
    }, [sizes]);

    const fetch = async () => {
        setIsFetching(true);
        const myToken = localStorage.getItem(AUTH_TOKEN_KEY);
        const headers = {
            Authorization: `Bearer ${myToken}`,
        };

        const data = await api(headers)
            .get("/sizes?category=sneakers")
            .then((response) => {
                setIsFetching(false);
                return response.data.data;
            })
            .catch((_err) => {
                setIsError(true);
                setIsFetching(false);
                return [];
            });

        setSizes(data);
    };

    useEffect(() => {
        fetch();

        return () => {
            setIsError(false);
            setIsFetching(false);
            setSizes([]);
        };
    }, []);

    return {
        isError,
        isFetching,
        sizes,
        usSizes,
    };
};

export default useFetchSizeLists;
