import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Alert,
	Badge,
  Col,
  Row,
	Card,
	CardTitle,
	CardImgOverlay,
  CardBody,
  CardHeader,
	ListGroup,
	ListGroupItem
} from 'reactstrap';
import {SortableContainer, SortableElement, arrayMove, SortableHandle} from 'react-sortable-hoc';
import Loading from 'react-loading-animation';
import ReactLoading from 'react-loading';

import Paginations from '../../components/Paginations';

import { SimpleLineIcon } from '../../components/Icons';
import { ButtonXs } from '../../components/Button';
import { ImageCentered } from '../../components/Images';
import noImage from '../../assets/img/no-image.png';
import { getBrandList,moveBrand, updatePopularBrands, getPopularBrands, fixBrands } from '../../actions/brandAction';
import privateView from '../../components/hocs/privateView';
// import Select from 'react-select';
import { getOptionList as getCategoryOptionList } from '../../actions/categoriesAction';

import ModalEditPopular from './ModalEditPopular'
import Analytics from "../../services/Analytics";

const DragHandle = SortableHandle(() => <i className="fa fa-bars" style={{cursor:'move'}}></i>);

const SortableItem = SortableElement(({item}) =>
	<ListGroupItem  style={{WebkitUserSelect: 'none'}}>
		<DragHandle /> {item.title? item.title: item.name}
		{' '}
		{item.popular_brand?
			<span className="text-danger" style={{fontSize: '.75rem'}}><SimpleLineIcon iconType="fire"/> populars</span>
		:null}
		{' '}{ !item.active? <Badge color="danger">inactive</Badge>: null}
		<Link to={`/brands/${item.id}`} className="pull-right text-gray-dark"><i className="fa fa-pencil"></i></Link>
	</ListGroupItem>
);

const RootItem=({item,key})=>{
	return (
		<ListGroupItem key={key} style={{WebkitUserSelect: 'none', background:'#ddd'}}>
			<i className="fa fa-tags"></i> <strong>{item.name}</strong>
			{' '}
			{item.popular_brand?
				<span className="text-danger" style={{fontSize: '.75rem'}}><SimpleLineIcon iconType="fire"/> populars</span>
			:null}
			{' '}{ !item.active? <Badge color="danger">inactive</Badge>: null}
			<Link to={`/brands/${item.id}`} className="pull-right text-gray-dark"><i className="fa fa-pencil"></i></Link>
		</ListGroupItem>
	)
}

const SortableList = SortableContainer(({items}) => {
	return (
		<ListGroup>
			{items.map((item, index) => (
				<div key={`collection-${index}`}>
					<RootItem item={item}/>
					{
						item.children.map((child,indexChild)=><SortableItem key={`item-${indexChild}`} index={indexChild} item={child} collection={index} />)
					}
				</div>
			))}
		</ListGroup>
	);
});

const getPublicImageUrl = image => {
  return image.signed_url || image.img_url
}

class BrandIndex extends Component{
	constructor(){
		super();
		this.state={
			_params: {
				category_id : '',
			},
			_columnCount:5,
			items:[],
			isModalPopularOpen: false
		}
	}

	componentDidMount(){
		// this.props.getList();
		this.props.bindCategoryOption();
    const { email } = this.props.injectedProps;
    Analytics.recordPageView('brand_list', email, {
      url: window.location.href,
      location: this.props.location,
      match: this.props.match
    });
	}

	componentDidUpdate(prevProps){
		if(prevProps.masterBrand.isFetch&&!this.props.masterBrand.isFetch&&this.props.masterBrand.list.status_code===200){
			const { data } = this.props.masterBrand.list;
			const { _params: { category_id: category } } = this.state;
			this.setState({items:data});
			const query = { params: { category } };
			this.props.getPopularBrands(query)
		}
		if(prevProps.masterBrand.fixBrands.isSubmit && prevProps.masterBrand.fixBrands.isSubmit !== this.props.masterBrand.fixBrands.isSubmit){
			const { _params } =this.state;
			const query={ params: {..._params} };
			this.props.getList(query);
			const { error } = this.props.masterBrand.fixBrands;
			if(error){
				alert("Error: fix-brands");
			}
		}
    if(prevProps.categoryOptions.isFetch&&!this.props.categoryOptions.isFetch&&this.props.categoryOptions.options.length){
      const { options } = this.props.categoryOptions;
      // const defaultCategory = {value:'', label:'All Categories'}
      // options.push(defaultCategory);
      this.setState({
        _params: {
          ...this.state._params,
          category_id: options[0].value
        }
      }, () => {
        const { _params } =this.state;
        const query={ params: {..._params} };
        this.props.getList(query);
      });
		}
		if(prevProps.masterBrand.popularBrands.isSubmit && !this.props.masterBrand.popularBrands.isSubmit){
			const { _params } =this.state;
			const query={ params: {..._params} };
			this.props.getList(query);
		}
	}

    /**
	 * Function to handle rendering table's items or rows
	 * @author pewe
	 */
	onRenderRowItem(){
		if(this.props.masterBrand.isFetch)
		{
			return(
				<tr>
					<td colSpan={this.state._columnCount}>
						<Alert color="info" className="text-center">
					      Getting all data...
				     	</Alert>
					</td>
				</tr>
			)
		}
		else if(this.props.masterBrand.list.status_code===200 && this.props.masterBrand.list.data.length>0)
		{
			const { current_page,per_page } =  this.props.masterBrand.list;
			return this.props.masterBrand.list.data.map((item,idx)=>
				(
					<tr key={parseInt((per_page*(current_page-1))+idx+1)}>
						<td>{ parseInt((per_page*(current_page-1))+idx+1) }</td>
						<td>{ item.name }</td>
						<td>{ item.created_at }</td>
						<td>{ item.updated_at }</td>
						<td>
							<Link to={`/brands/${item.id}`} className="btn btn-link">Details</Link>
						</td>
					</tr>
				)
			)
		}
		return(
			<tr>
				<td colSpan={this.state._columnCount}>
					<Alert color="info" className="text-center">
				      No Record Found...
			     	</Alert>
				</td>
			</tr>
		)
	}

    /**
	 * Function to handle rendering paginations
	 * @author pewe
	 */
	onRenderPagination(){
		if(this.props.masterBrand.list.status_code===200&&this.props.masterBrand.list.data.length)
		{
			return (<Paginations
						size="sm"
						lastPage={this.props.masterBrand.list.last_page}
						perPage={this.props.masterBrand.list.per_page}
						total={this.props.masterBrand.list.total}
              			maxPage={8}
						path={this.props.masterBrand.list.path}
						currentPage={this.props.masterBrand.list.current_page}
						prevPageUrl={this.props.masterBrand.list.prev_page_url}
						nextPageUrl={this.props.masterBrand.list.next_page_url}
						handleGotoCallback={this.handleGoto.bind(this)}
					/>)
		}
		return '';
    }

	/**
	 * Handle callback when user click pagination items
	 * @author pewe
	 * @param {integer} page
	 */
	handleGoto(page){
		const _params=this.state._params;
		_params.page=page;
		this.setState({_params});
		const query={ params: { ..._params } };
		this.props.getList(query);
	}

    onSortEnd({oldIndex, newIndex, collection}) {
		const {items} = this.state;
		const rowId=items[collection].children[oldIndex].id;
		const toRowId=items[collection].children[newIndex].id;
		items[collection]={...items[collection],children:arrayMove(items[collection].children, oldIndex, newIndex)}
		this.setState({
		  items
		}, ()=>this.props.move(rowId,toRowId,newIndex>oldIndex));
  }

  onSelectChange(val, attributeForm){
    const { _params } =this.state;
    _params[attributeForm]=val===null?val:val.value;
    this.setState({_params});
    const query={ params: {..._params} };
    this.props.getList(query);
  }

    render(){
      return(
        <div className="animated fadeIn">
					<ModalEditPopular
						items={this.state.items}
						isOpen={this.state.isModalPopularOpen}
						toggle={() => this.setState({isModalPopularOpen: !this.state.isModalPopularOpen})}
						onSubmit={brandList => {
							const { _params: { category_id }} = this.state;
							this.props.updatePopularBrands({
								category_id,
								brands: [...brandList]
							});
							this.setState({isModalPopularOpen: !this.state.isModalPopularOpen})
						}}
					/>
          <Row>
            <Col xs={12} md={{size:6, offset:3}}>
              <Card>
                <CardHeader>
                  <i className="fa fa-align-justify"></i>
                    Brand List
									<a style={{fontSize: '.75rem'}} href="#" className="ml-3 text-danger" onClick={e => {
										e.preventDefault()
										this.props.fixBrands()
									}}>
										<i className="fa fa-cogs"></i> Fix Brand
									</a>
                </CardHeader>
                <CardBody>
								<div className="mb-3">
									{this.props.categoryOptions.options.map(item => (
										<ButtonXs
											key={item.value}
											active={this.state._params.category_id === item.value}
											color="gray"
											onClick={() => {
												const { _params, _params: { category_id } } = this.state;
												if(category_id !== item.value)
													this.setState({
														_params: {
															..._params,
															category_id: item.value
														},
													}, () => {
														const { _params } =this.state;
														const query={ params: {..._params} };
														this.props.getList(query);
													})
											}}
										>
											{item.label}
										</ButtonXs>
									))}
									<a
										href="#"
										onClick={e => {
											e.preventDefault();
											this.setState({
												isModalPopularOpen: !this.state.isModalPopularOpen
											})
										}}
										style={{textDecoration: 'underline'}}
										className="text-danger ml-2"
									>
										<SimpleLineIcon iconType="fire"/> edit populars
									</a>
								</div>
                {/* <Row style={{paddingBottom: '1.25rem', textAlign: 'center'}}>
                  <Col xs={{size:4, order: 2, offset: 4}} md={{size:4, order: 2, offset: 4}}>
                    <Label for="SelectCategoryName">Select Category</Label>
                    <Select
                      id="formCategoryName"
                      name="category_id"
                      value={this.state._params.category_id}
                      options={this.props.categoryOptions.options.length?this.props.categoryOptions.options:[{value:'',label:'Loading...'}]}
                      onChange={(val)=>{this.onSelectChange(val,'category_id')}}
                    />
                  </Col>
                </Row> */}
								<div className="mb-3">
									<p className="mb-1">Our populars:</p>
									<div style={{display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', overflow: 'auto'}}>
										{this.props.masterBrand.popularBrands.data.length == 0?
											<p className="mb-1" style={{fontStyle: 'italic'}}>No data.</p>
										:null}
										{this.props.masterBrand.popularBrands.data.map(brand => {
											const imgUrl = getPublicImageUrl(brand)
											const src = imgUrl? imgUrl: noImage;
											return(
												<div key={brand.id} style={{marginRight: '.5rem'}}>
													<Link style={{color: "#4a4a4a", textDecoration: 'none', display: 'block', border: '1px solid #ddd'}} to={`/brands/${brand.id}`} title="Go to details...">
														<Card className="mb-0">
															<ImageCentered
																size={100}
																title={brand.title? brand.title: brand.name}
																alt={brand.title? brand.title: brand.name}
																src={src}
															/>
															<CardImgOverlay style={{background: 'rgba(255,255,255,.75)', display: 'flex', justifyContent: 'center', alignItems: 'flex-end', padding: '.5rem', paddingBottom: 0}}>
																<CardTitle style={{fontStyle: 'italic', fontSize: '.5rem', fontWeight: 700}}>
																	{brand.title? brand.title: brand.name}
																</CardTitle>
															</CardImgOverlay>
														</Card>
													</Link>
												</div>
											)
										})}
									</div>
								</div>

                {
                  this.props.masterBrand.isSubmit?
                  <div className="loading-wrapper">
                    <ReactLoading type="bars" color="#444" className="loading-center" width={30} height={30}/>
                    <p className="text-center">Updating...</p>
                  </div>
                  :''
                }
                {
                  this.props.masterBrand.isFetch || this.props.masterBrand.list.status_code!==200?
                  <Loading/>
                  :
                  <SortableList items={this.state.items} onSortEnd={this.onSortEnd.bind(this)} lockAxis="y"  useDragHandle={true} />
                }
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      )
    }
}
const mapStateToProps= ({masterBrand,auth: { isFetch, roles, isLogged, id, email }, masterCategories}) => {
	return {
		masterBrand,
    guardData: { isFetch, roles, isLogged, id, email },
    categoryOptions : masterCategories
	}
};
const mapDispatchToProps = (dispatch) => {
	return{
		move: (id, to, after) => dispatch(moveBrand(id, to, after)),
    getList:(query) => dispatch(getBrandList(query)),
		bindCategoryOption:()=>dispatch(getCategoryOptionList()),
		updatePopularBrands: payloads => dispatch(updatePopularBrands(payloads)),
		getPopularBrands: query => dispatch(getPopularBrands(query)),
		fixBrands: () => dispatch(fixBrands())
	}

};
const enhance = connect(mapStateToProps,mapDispatchToProps)
export default enhance(privateView(BrandIndex));