import React from "react";
import CKEditor from "ckeditor4-react";
import propTypes from "prop-types";

const config = {
  toolbar: [
    { name: "clipboard", items: ["Undo", "Redo"] },
    { name: "styles", items: ["Styles", "Format"] },
    {
      name: "basicstyles",
      items: ["Bold", "Italic", "Strike", "-", "RemoveFormat"],
    },
    {
      name: "paragraph",
      items: [
        "NumberedList",
        "BulletedList",
        "-",
        "Outdent",
        "Indent",
        "-",
        "Blockquote",
      ],
    },
    { name: "links", items: ["Link", "Unlink"] },
    { name: "insert", items: ["Image", "EmbedSemantic", "Table"] },
    { name: "tools", items: ["Maximize"] },
    { name: "editing", items: ["Scayt"] },
  ],
  extraPlugins: 'autoembed,embedsemantic,image2,autogrow',
  removePlugins: 'image',
  autoGrow_onStartup: true,
  autoGrow_minHeight: 200,
  autoGrow_maxHeight: 600,
  bodyClass: 'article-editor',
  removeDialogTabs: 'image:advanced;link:advanced',
};

const MetaTextEditor = (props) => {
  return (
    <>
      <CKEditor
        config={config}
        data={props.value}
        onBeforeLoad={(CKEDITOR) => {
          CKEDITOR.on("dialogDefinition", (ev) => {
            var dialogName = ev.data.name;
            var dialogDefinition = ev.data.definition;

            if (dialogName == "table") {
              var info = dialogDefinition.getContents("info");

              info.get("txtWidth")["default"] = "100%"; // Set default width to 100%
              info.get("txtBorder")["default"] = "1"; // Set default border to 0
              info.get("cmbAlign")["default"] = "center"; // Set default alignment to center
            }
          });
        }}
        onChange={props.handleChange}
      />
    </>
  );
};

MetaTextEditor.propTypes = {
  value: propTypes.string,
  handleChange: propTypes.func,
};

export default MetaTextEditor;
