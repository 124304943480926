import FireAnalytics from "./Firebase"
import moment from 'moment-timezone'

const IS_LIVE_SITE = ~document.location.hostname.indexOf("dapur.kickavenue.com")
// const IS_LIVE_SITE = true;

class Analytics {
  constructor(){
    moment.tz('Asia/Jakarta');
  }

  recordPageView(pageName, email, routeProps){
    const prefix = 'page_view';
    let route = routeProps
    if(typeof route  === "object"){
      const { url, location: { pathname, search }, match: { params }} = routeProps;
      route = { url, pathname, search, params }
    }
    FireAnalytics.logEvent(`${prefix}_${pageName}`, {
      email,
      route,
      date: moment().format()
    })
  }

  recordSignIn(method, email){
    if(!IS_LIVE_SITE) return;

    FireAnalytics.logEvent("login", {
      method,
      email,
      date: moment().format()
    })
  }

  recordRejectOutstandingSale(email, notes, invoice_number, buyer_email, price) {
    if(!IS_LIVE_SITE) return;

    FireAnalytics.logEvent("reject_outstanding_sale", {
      email,
      notes,
      invoice_number,
      buyer_email,
      price,
      date: moment().format()
    })
  }

  recordAcceptOutstandingConfirmation(email, notes, invoice_number, buyer_email, price) {
    if(!IS_LIVE_SITE) return;

    FireAnalytics.logEvent("accept_outstanding_confirmation", {
      email,
      notes,
      invoice_number,
      buyer_email,
      price,
      date: moment().format()
    })
  }

  recordLegitCheckPassed(email, notes, invoice_number, buyer_email, price) {
    if(!IS_LIVE_SITE) return;

    FireAnalytics.logEvent("legit_check_passed", {
      email,
      notes,
      invoice_number,
      buyer_email,
      price,
      date: moment().format()
    })
  }

  recordDeliverOrder(email, notes, invoice_number, buyer_email, price) {
    if(!IS_LIVE_SITE) return;

    FireAnalytics.logEvent("deliver_order", {
      email,
      notes,
      invoice_number,
      buyer_email,
      price,
      date: moment().format()
    })
  }

  recordProcessedOrder(email, notes, invoice_number, price, points) {
    if(!IS_LIVE_SITE) return;

    FireAnalytics.logEvent("processed_order", {
      email,
      notes,
      invoice_number,
      price,
      points,
      date: moment().format()
    })
  }

  recordChangeStatusPayment(status, remarks, invoice_number, email) {
    if(!IS_LIVE_SITE) return;

    FireAnalytics.logEvent("change_status_payment", {
      status,
      remarks,
      invoice_number,
      adminEmail: email,
      date: moment().format()
    })
  }

  recordRefundToBalance(invoiceNumber, offerId, amount, remarks, with_balance_out, email) {
    if(!IS_LIVE_SITE) return;

    FireAnalytics.logEvent("refund_to_balance", {
      invoiceNumber,
      offerId,
      amount,
      remarks,
      with_balance_out,
      adminEmail: email,
      date: moment().format()
    })
  }

  recordCreateDisbursement(payloads, dirsbursementBank, email) {
    if(!IS_LIVE_SITE) return;

    FireAnalytics.logEvent("create_disbursement", {
      ...payloads,
      ...dirsbursementBank,
      adminEmail: email,
      date: moment().format()
    })
  }

  recordUpdateDisbursement(type, payloads, dirsbursementBank, email) {
    const actionType = type === "accept" ? "accept_disbursement" : (type === "reject" ? "reject_disbursement" : "update_disbursement");

    if(!IS_LIVE_SITE) return;

    FireAnalytics.logEvent(actionType, {
      ...payloads,
      ...dirsbursementBank,
      adminEmail: email,
      date: moment().format()
    })
  }

  recordDeleteDisbursement(id, email) {
    if(!IS_LIVE_SITE) return;

    FireAnalytics.logEvent("delete_disbursement", {
      disbursementId: id,
      adminEmail: email,
      date: moment().format()
    })
  }

  recordActionUser(type, payloads, email) {
    const actionType = type === "update" ? "update_user" : "create_user";
    if(!IS_LIVE_SITE) return;

    FireAnalytics.logEvent(actionType, {
      ...payloads,
      adminEmail: email,
      date: moment().format()
    })
  }

  recordDeleteUser(payloads, email) {
    if(!IS_LIVE_SITE) return;

    FireAnalytics.logEvent("delete_user", {
      ...payloads,
      adminEmail: email,
      date: moment().format()
    })
  }

  recordUpdateUserSize(userId, userSize, email) {
    if(!IS_LIVE_SITE) return;

    FireAnalytics.logEvent("update_size_user", {
      userId, 
      userSize, 
      adminEmail: email,
      date: moment().format()
    })
  }

  recordAddBackAccount(userId, accountName, accountNumber, bankName, email) {
    if(!IS_LIVE_SITE) return;

    FireAnalytics.logEvent("add_bank_account", {
      userId, 
      accountName, 
      accountNumber, 
      bankName, 
      adminEmail: email,
      date: moment().format()
    })
  }

  recordDeleteBackAccount(userId, bankAccountId, email) {
    if(!IS_LIVE_SITE) return;

    FireAnalytics.logEvent("delete_bank_account", {
      userId, 
      bankAccountId, 
      adminEmail: email,
      date: moment().format()
    })
  }

  recordCreatePayment(payloads, email) {
    if(!IS_LIVE_SITE) return;

    FireAnalytics.logEvent("create_payment", {
      ...payloads, 
      adminEmail: email,
      date: moment().format()
    })
  }

  recordChangeStatusTopUpPayment(status, remarks, invoice_number, email) {
    if(!IS_LIVE_SITE) return;

    FireAnalytics.logEvent("change_status_topup_payment", {
      status,
      remarks,
      invoice_number,
      adminEmail: email,
      date: moment().format()
    })
  }

  recordCreateConcierge(payloads, email) {
    if(!IS_LIVE_SITE) return;

    FireAnalytics.logEvent("create_concierge", {
      ...payloads, 
      adminEmail: email,
      date: moment().format()
    })
  }

  recordChangeStatusConcierge(id, status, amount, email) {
    if(!IS_LIVE_SITE) return;

    FireAnalytics.logEvent("change_status_concierge", {
      id,
      status,
      amount,
      adminEmail: email,
      date: moment().format()
    })
  }


}

const AnalyticsWrapper = new Analytics();
export default AnalyticsWrapper;