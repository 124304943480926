import {
	AUTH_TOKEN_KEY,
	FORCE_HOLIDAY_MODE,
	FETCHING_HOLIDAY_MODE,
	HOLIDAY_MODE_ERROR,
	HOLIDAY_MODE_ROUTE_API,
 }from '../constants';
import { manipulateErrors, checkAuthorized } from '../utils';
import api from '../utils/Api';
import { logout } from './authAction';

export const forceHolidayMode=(payload)=>{
	return dispatch=>(
		dispatch(isFetch({type:'submit',status:true})),
		api({
			Authorization:'Bearer '+localStorage.getItem(AUTH_TOKEN_KEY)
		}).put(HOLIDAY_MODE_ROUTE_API,payload)
		.then(res => {
				dispatch(onSuccess(res.data,FORCE_HOLIDAY_MODE));
				dispatch(isFetch({type:'submit',status:false}));
		})
		.catch(err => {
			dispatch(onError(err));
			dispatch(isFetch({type:'submit',status:false}));
		})
	)
}

export const isFetch=(bool)=>(
	{
		type:FETCHING_HOLIDAY_MODE,
		payload:bool
	}
)

export const onSuccess=(res,type)=>
(
	{
		type:type,
		payload:res
	}
)

export const onError=(err)=>{
	if(!checkAuthorized(manipulateErrors(err)))
		return logout();
	return {
		type:HOLIDAY_MODE_ERROR,
		payload:manipulateErrors(err)
	}
}