import React, { Component } from 'react';
import { connect } from 'react-redux';
import CollectionForm from './CollectionForm'
import privateView from '../../components/hocs/privateView';
import Analytics from "../../services/Analytics";

class CollectionDetail extends Component{
	componentDidMount(){
    const { email } = this.props.injectedProps;
    Analytics.recordPageView('collection_detail', email, {
      url: window.location.href,
      location: this.props.location,
      match: this.props.match
    });
  }
  render(){
    return(
      <div className="animate fadeIn">
        <CollectionForm
          title="Collection Detail"
          edit
          {...this.props}
        />
      </div>
    )
  }
}

const mapStateToProps = ({auth: { isFetch, roles, isLogged, id, email } })=>({
  guardData: { isFetch, roles, isLogged, id, email }
})

const enhance = connect(mapStateToProps);

export default enhance(privateView(CollectionDetail, ['supervisor', 'sale_supervisor', 'inventory_admin']));