import React from 'react';
import { CardBody, Collapse } from 'reactstrap';
import propTypes from 'prop-types';
import SearchForm from './filterPanel/SearchForm';
import SortForm from './filterPanel/SortForm';

class FilterPanel extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      keyword: props.keyword,
      per_page: props.per_page,
      sort_by: props.sort_by,
    }
    this._handleOnChangeInput = this._handleOnChangeInput.bind(this);
    this._handleApplyFilterButtonClick = this._handleApplyFilterButtonClick.bind(this);
    this._handleOnChangeInputAndApply = this._handleOnChangeInputAndApply.bind(this);
    this._handleSearchButtonClick = this._handleApplyFilterButtonClick.bind(this);
    this._handleSelectRack = this._handleSelectRack.bind(this);
  }

  _handleSelectRack(option){
    this.setState({ rack: option });
  }

  _handleOnChangeInput(e){
    const lastState = {...this.state};
    lastState[e.target.name] = e.target.value;
    this.setState({...lastState});
  }

  _handleOnChangeInputAndApply(e){
    const lastState = {...this.state};
    lastState[e.target.name] = e.target.value;
    this.setState({...lastState}, ()=> this._handleSubmitFilter());
  }

  _handleApplyFilterButtonClick(e){
    e.preventDefault();
    this._handleSubmitFilter();
  }

  _handleSubmitFilter(){
    const {
      keyword,
      per_page,
      sort_by
    } = this.state;
    this.props.onApplyFilter({
      keyword,
      per_page,
      sort_by
    });
  }

  componentDidUpdate(prevProps){
    if(this.props.isReset && this.props.isReset!==prevProps.isReset){
      const {
        keyword,
        per_page,
        sort_by
      } = this.props;
      this.setState({
        keyword,
        per_page,
        sort_by
      })
      this.props.onResetFilterCallback();
    }
  }

  render(){
    return(
      <Collapse isOpen={this.props.isOpen}>
        <CardBody className="filter-container__card-body">
          <SearchForm
            keyword = {this.state.keyword}
            onInputChange = {this._handleOnChangeInput}
            onSubmit = {this._handleSearchButtonClick}
          />
          <hr/>
          <SortForm
            per_page={this.state.per_page}
            sort_by={this.state.sort_by}
            onInputChange={this._handleOnChangeInputAndApply}
          />
        </CardBody>
      </Collapse>
    )
  }
}


export default FilterPanel;

FilterPanel.propTypes= {
  per_page: propTypes.oneOfType([propTypes.number, propTypes.string]),
  sort_by: propTypes.string,
  isOpen: propTypes.bool.isRequired,
  onResetFilterCallback: propTypes.func,
  isReset: propTypes.bool
}

FilterPanel.defaultProps= {
  per_page: 15,
  sort_by: '',
}