import React from 'react';
import {
  Card,
  CardHeader,
  CardBody,
  Col,
  Row,
  Alert,
} from 'reactstrap';
// import propTypes from 'prop-types';
import ImageCard from './ImageCard';
import Icon from '../../../components/Icons/FontAwesomeIcon';

const ImageListCard = props => {
  const content = props.images.length?
    props.images.map((item)=>
      <Col key={item.id} xs={12} sm={6} md={3}>
        <ImageCard
          {...item}
          alt={item.URL}
        />
      </Col>
    ):
    (
      <Col>
        <Alert color="danger" style={{flex:1, textAlign:'center'}} className="mb-0"><strong>Ooops.. </strong> No Images Found!</Alert>
      </Col>
    )

  return (
    <Card>
      <CardHeader>
        <Icon iconType="picture-o"/> {props.title}
      </CardHeader>
      <CardBody>
        <Row>
          { content }
        </Row>
      </CardBody>
    </Card>
  )
}

export default ImageListCard;