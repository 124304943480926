import React from 'react';
import propTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import DateToHuman from '../../../../components/DateToHuman';
import { TextRupiah } from '../../../../components/Text';
import { getPaymentType, getPaymentStatus, getPaymentVia } from '../../../../constants/kickavenue';
import FontAwesomeIcon from '../../../../components/Icons/FontAwesomeIcon';
import OrderSummariesColumn from '../../RowItem/OrderSummariesColumn';
import SmallInline from '../../../../styled/Typograph/Small/SmallInline';
import Paragraph from '../../../../styled/Typograph/Paragraph';

const TotalAmount = styled.div`
  font-size: .75rem;
`

const RowItem = props => {
  const {
    id,
    category,
    quantity,
    paymentId,
    transactionNumber,
    referenceNumber,
    rowId,
    userId,
    userEmail,
    invoiceNumber,
    amount,
    adjustedAmount,
    paymentMethod,
    paymentStatus,
    paymentBankTransfer,
    displayName,
    SKU,
    size,
    colour,
    updatedAt,
    preOrder,
    expired,
    bank,
    paymentVia,
    consignmentId,
    onOpenCreditCard,
    rack,
    points,
    voucherCode,
    createdSource
  } = props;
  const via = getPaymentVia(paymentVia);
  return (
    <tr>
      <th className="v-center" scope="row">{ rowId }</th>
      <td className="v-center" style={{width:"15%"}}>
        <Paragraph>
          <SmallInline>#{ paymentId ? paymentId : id } </SmallInline><br />
          <SmallInline>{ transactionNumber ? transactionNumber: '-' }</SmallInline> <br />
          <SmallInline>REF: { referenceNumber ? referenceNumber: '-' }</SmallInline>
        </Paragraph>
      </td>
      <td className="v-center">
        <OrderSummariesColumn
          id={id}
          quantity={quantity}
          category={category}
          invoiceNumber={invoiceNumber}
          displayName={displayName}
          size={size}
          SKU={SKU}
          colour={colour}
          preOrder={preOrder}
          userId={userId}
          userEmail={userEmail}
          paymentMethod={paymentMethod}
          paymentBankTransfer={paymentBankTransfer}
          createdSource={createdSource}
        />
      </td>
      <td className="v-center">
        {points? (
          <div style={{fontSize: '.75rem'}}>
            <FontAwesomeIcon className="mr-1" iconType="product-hunt"/>
            {Number(points)}
          </div>
        ): null}
        <TextRupiah bold prefix="Rp. " value={adjustedAmount}/>
        {
          amount !== adjustedAmount?
          <TotalAmount>
            <TextRupiah isStroke prefix="Rp. " value={amount}/>
          </TotalAmount>
          :null
        }
        {
          consignmentId && (
            <div style={{fontSize: '.65rem'}}>
              <i className="fa fa-barcode mr-1"></i>
              {consignmentId}
            </div>
          )
        }
        {
          rack && (
            <div className="text-uppercase" style={{fontSize: '.65rem'}}>
              RACK: {rack}
            </div>
          )
        }
        {
          voucherCode && (
            <div className="text-uppercase" style={{fontSize: '.65rem'}}>
              <FontAwesomeIcon iconType="ticket"/> {voucherCode}
            </div>
          )
        }
      </td>
      <td className="v-center">
        <div>
          {
            bank && paymentMethod === 'virtual_account'?
            <span style={{fontSize: '.75rem'}}>{bank+ '-'+ getPaymentType(paymentMethod)}</span>
            : 
            getPaymentType(paymentMethod)
          }
        </div>
        { via !== null? <div><span style={{fontSize: '.75rem'}}>via <i>{via}</i></span></div>: null}
        {
          paymentVia !== null && paymentVia === 'vt' && paymentMethod=== "credit_card" ? 
          <button className="btn btn-sm btn-secondary" onClick={onOpenCreditCard} target="_blank" title="Show More Info"><i className="fa fa-info-circle"></i> More Info</button>
          :
          null
        }
      </td>
      <td className="v-center"><DateToHuman  value={updatedAt} id={`tooltip_${rowId}`}/></td>
      <td className="v-center">
        { expired && (paymentStatus==='PENDING' || paymentStatus==='VERIFYING_BID') ?'Reach Limit Time':getPaymentStatus(paymentStatus) }
      </td>
      <td className="v-center">
        <Link to={`/payments/${id}`} className="btn btn-link">Details</Link>
      </td>
    </tr>
  )
}

export default RowItem;

RowItem.defaultProps = {
  amount: 0,
  paymentMethod: 'bank_transfer',
  paymentStatus: 'PENDING',
  expired: false
}

RowItem.propTypes={
  rowId: propTypes.number.isRequired,
  expired: propTypes.bool,
  id: propTypes.number.isRequired,
  amount: propTypes.oneOfType([propTypes.number,propTypes.string]),
  adjustedAmount: propTypes.oneOfType([propTypes.number,propTypes.string]),
  updatedAt: propTypes.string,
  paymentMethod: propTypes.string,
  paymentVia: propTypes.string,
  bank: propTypes.string,
  paymentStatus: propTypes.string,
  invoiceNumber: propTypes.string,
  size: propTypes.string,
  displayName: propTypes.string,
  SKU: propTypes.string,
  colour: propTypes.string,
  preOrder: propTypes.oneOfType([propTypes.number,propTypes.bool]),
  onOpenCreditCard: propTypes.func.isRequired,
  rack: propTypes.string
}
