import {
  DISBURSEMENT_USER_LIST,
  DISBURSEMENT_USER_LIST_FETCH,
  DISBURSEMENT_USER_LIST_ERROR,

  PENDING_DISBURSEMENT_USER_LIST,
  PENDING_DISBURSEMENT_USER_LIST_FETCH,
  PENDING_DISBURSEMENT_USER_LIST_ERROR,

  COMPLETED_DISBURSEMENT_USER_LIST,
  COMPLETED_DISBURSEMENT_USER_LIST_FETCH,
  COMPLETED_DISBURSEMENT_USER_LIST_ERROR,

  GET_DETAIL_DISBURSEMENT_USER,
  GET_DETAIL_DISBURSEMENT_USER_FETCH,
  GET_DETAIL_DISBURSEMENT_USER_ERROR,

  ADD_DETAIL_DISBURSEMENT_USER,
  ADD_DETAIL_DISBURSEMENT_USER_SUBMIT,
  ADD_DETAIL_DISBURSEMENT_USER_ERROR,

  UPDATE_DETAIL_DISBURSEMENT_USER,
  UPDATE_DETAIL_DISBURSEMENT_USER_SUBMIT,
  UPDATE_DETAIL_DISBURSEMENT_USER_ERROR,

  DELETE_DETAIL_DISBURSEMENT_USER,
  DELETE_DETAIL_DISBURSEMENT_USER_SUMBIT,
  DELETE_DETAIL_DISBURSEMENT_USER_ERROR,

  MIDTRANS_BANK_ACCOUNT_FETCH,
  MIDTRANS_BANK_ACCOUNT_LIST,
  MIDTRANS_BANK_ACCOUNT_ERROR,

  CASHOUT_FEE_FETCH,
  CASHOUT_FEE_ERROR,
  CASHOUT_FEE_LIST,

  CASHOUT_FEE_SETTING_FETCH,
  CASHOUT_FEE_SETTING_ERROR,
  CASHOUT_FEE_SETTING,
  DISBURSEMENT_REPORT,
  DISBURSEMENT_REPORT_CLEAR,
  DISBURSEMENT_REPORT_FETCH,
  DISBURSEMENT_REPORT_ERROR
} from '../constants';

const initialState = {
  list:{
    isFetch: false,
    data: [],
    error: null
  },
  pendingList:{
    isFetch: false,
    data: [],
    error: null
  },
  completedList:{
    isFetch: false,
    data: [],
    error: null
  },
  detail: {
    isFetch: false,
    isSubmit: false,
    error: null,
    data: {
      deleted: false
    }
  },
  midtrans:{
    bankAccount:[],
    isFetch:false,
    error:null
  },
  cashoutFee:{
    isFetch: false,
    isFetchSetting: false,
    data: null,
    settings: {},
    error: null
  },
  disbursementReport: {
		status_code: 0,
		message: '',
		error: null
	},
}

const onDisbursementListSuccess = (lastState, payloads)=>{
  const { data, status_code, message } = payloads;
  return {...lastState, list:{ ...lastState.list, ...data, status_code, message, error: null }}
}

const onDisbursementListFetch = (lastState, payloads)=>{
  return {...lastState, list:{ ...lastState.list, ...payloads }}
}

const onDisbursementListError = (lastState, payloads)=>{
  return {...lastState, list:{ ...lastState.list, ...payloads }}
}

const onPendingListSuccess = (lastState, payloads)=>{
  const { data, status_code, message } = payloads;
  return {...lastState, pendingList:{ ...lastState.pendingList, ...data, status_code, message, error: null }}
}

const onPendingListFetch = (lastState, payloads)=>{
  return {...lastState, pendingList:{ ...lastState.pendingList, ...payloads }}
}

const onPendingListError = (lastState, payloads)=>{
  return {...lastState, pendingList:{ ...lastState.pendingList, ...payloads }}
}

const onCompletedListFetch = (lastState, payloads)=>{
  return {...lastState, completedList:{ ...lastState.completedList, ...payloads }}
}

const onCompletedListError = (lastState, payloads)=>{
  return {...lastState, completedList:{ ...lastState.completedList, ...payloads }}
}

const onCompletedListSuccess = (lastState, payloads)=>{
  const { data, status_code, message } = payloads;
  return {...lastState, completedList:{ ...lastState.completedList, ...data, status_code, message, error: null }}
}

const onGetDetailDisbursementSuccess = (lastState, payloads) =>{
  const { data, status_code, message } = payloads;
  return {...lastState, detail:{ ...lastState.detail, data, status_code, message, error: null }}
}

const onDeleteDisbursementSuccess = (lastState, payloads) =>{
  const { status_code, message } = payloads;
  return {...lastState, detail:{ ...lastState.detail, data:{ ...lastState.detail.data, deleted: true }, status_code, message, error: null }}
}

const onDetailFetchOrSubmit = (lastState, payloads) =>{
  const { data } =  lastState.detail;
  if(payloads.isSubmit === true || payloads.isFetch === true){
    data.deleted = false;
  }
  return {...lastState, detail:{ ...lastState.detail, data, ...payloads }}
}

const onDetailError = (lastState, error) => {
  return { ...lastState, detail: { ...lastState.detail, ...error }};
}

const onMidtransBankAccountListSuccess = (lastState, payloads)=>{
  const { data, status_code, message } = payloads;
  return {...lastState, midtrans:{ ...lastState.midtrans, bankAccount:[...data], status_code, message, error: null }}
}

const onMidtransBankAccountListFetch = (lastState, payloads)=>{
  return {...lastState, midtrans:{ ...lastState.midtrans, ...payloads }}
}

const onMidtransBankAccountListError = (lastState, payloads)=>{
  return {...lastState, midtrans:{ ...lastState.midtrans, ...payloads }}
}

const onGetCashoutFeeFetch = (lastState, payloads)=>{
  return {...lastState, cashoutFee:{ ...lastState.cashoutFee, ...payloads }}
}

const onGetCashoutFeeSuccess = (lastState, payloads)=>{
  const { data, status_code, message } = payloads;
  return {...lastState, cashoutFee:{...lastState.cashoutFee, data, status_code, message, error:null}};
}

const onGetCashoutFeeError = (lastState, error)=>{
  return {...lastState, cashoutFee:{...lastState.cashoutFee, ...error}};
}

const onGetCashoutFeeSettingFetch = (lastState, payloads)=>{
  return {...lastState, cashoutFee:{ ...lastState.cashoutFee, ...payloads }}
}

const onGetCashoutFeeSettingSuccess = (lastState, payloads)=>{
  const { data, status_code, message } = payloads;
  const setting = { ...data }
  return {...lastState, cashoutFee:{...lastState.cashoutFee, settings: { 'BALANCE_WITH_FEE': setting }, status_code, message, error:null}};
}

const onGetCashoutFeeSettingError = (lastState, error)=>{
  return {...lastState, cashoutFee:{...lastState.cashoutFee, ...error}};
}

const onDisbursementReportFetch = (lastState, payloads)=>{
  return {...lastState, disbursementReport:{ ...lastState.disbursementReport, ...payloads }}
}

const onDisbursementReport = (lastState,payload) => {
	const {message, status_code}=payload;
	return { ...lastState, disbursementReport:{ message, status_code, error: null } };	
}

const onDisbursementReportError = (lastState,error) => {
	const {message, status_code}=error.error;
	return { ...lastState, disbursementReport:{ message, status_code, ...error } };
}

const onDisbursemetClear = (lastState) => {
  return {...lastState, disbursementReport:{...lastState.disbursementReport,status_code:0,message:'',error: null}};
}

const reducer = ( state = initialState, action) => {
  switch(action.type){
    case DISBURSEMENT_USER_LIST: return onDisbursementListSuccess(state, action.payload);
    case DISBURSEMENT_USER_LIST_FETCH: return onDisbursementListFetch(state, action.payload);
    case DISBURSEMENT_USER_LIST_ERROR: return onDisbursementListError(state, action.payload);

    case PENDING_DISBURSEMENT_USER_LIST: return onPendingListSuccess(state, action.payload);
    case PENDING_DISBURSEMENT_USER_LIST_FETCH: return onPendingListFetch(state, action.payload);
    case PENDING_DISBURSEMENT_USER_LIST_ERROR: return onPendingListError(state, action.payload);

    case COMPLETED_DISBURSEMENT_USER_LIST: return onCompletedListSuccess(state, action.payload);
    case COMPLETED_DISBURSEMENT_USER_LIST_FETCH: return onCompletedListFetch(state, action.payload);
    case COMPLETED_DISBURSEMENT_USER_LIST_ERROR: return onCompletedListError(state, action.payload);

    case DELETE_DETAIL_DISBURSEMENT_USER:
      return onDeleteDisbursementSuccess(state, action.payload);
    case ADD_DETAIL_DISBURSEMENT_USER:
    case UPDATE_DETAIL_DISBURSEMENT_USER:
    case GET_DETAIL_DISBURSEMENT_USER:
      return onGetDetailDisbursementSuccess(state, action.payload);

    case ADD_DETAIL_DISBURSEMENT_USER_SUBMIT:
    case UPDATE_DETAIL_DISBURSEMENT_USER_SUBMIT:
    case DELETE_DETAIL_DISBURSEMENT_USER_SUMBIT:
    case GET_DETAIL_DISBURSEMENT_USER_FETCH:
      return onDetailFetchOrSubmit(state, action.payload);

    case ADD_DETAIL_DISBURSEMENT_USER_ERROR:
    case UPDATE_DETAIL_DISBURSEMENT_USER_ERROR:
    case DELETE_DETAIL_DISBURSEMENT_USER_ERROR:
    case GET_DETAIL_DISBURSEMENT_USER_ERROR:
      return onDetailError(state, action.payload);

    case CASHOUT_FEE_FETCH: return onGetCashoutFeeFetch(state, action.payload)
    case CASHOUT_FEE_ERROR: return onGetCashoutFeeError(state, action.payload)
    case CASHOUT_FEE_LIST: return onGetCashoutFeeSuccess(state, action.payload)

    case CASHOUT_FEE_SETTING_FETCH: return onGetCashoutFeeSettingFetch(state, action.payload)
    case CASHOUT_FEE_SETTING_ERROR: return onGetCashoutFeeSettingError(state, action.payload)
    case CASHOUT_FEE_SETTING: return onGetCashoutFeeSettingSuccess(state, action.payload)

    case MIDTRANS_BANK_ACCOUNT_LIST: return onMidtransBankAccountListSuccess(state, action.payload);
    case MIDTRANS_BANK_ACCOUNT_FETCH: return onMidtransBankAccountListFetch(state, action.payload);
    case MIDTRANS_BANK_ACCOUNT_ERROR: return onMidtransBankAccountListError(state, action.payload);

    case DISBURSEMENT_REPORT_CLEAR : return onDisbursemetClear(state)
    case DISBURSEMENT_REPORT_FETCH : return onDisbursementReportFetch(state, action.payload)
		case DISBURSEMENT_REPORT : return onDisbursementReport(state,action.payload);		
		case DISBURSEMENT_REPORT_ERROR : return onDisbursementReportError(state,action.payload);

    default: return state;
  }
}

export default reducer;