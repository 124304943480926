import React from 'react';
import propTypes from 'prop-types';

import SelectUserAsync from '../../../../../components/Form/Select/Async/SelectUser';

class SellerFilterForm extends React.Component{
    constructor(props){
        super(props);
        this._handleOnSelectUserChange = this._handleOnSelectUserChange.bind(this);
        this._handleOnSelectUserOpen = this._handleOnSelectUserOpen.bind(this);
    }
    _handleOnSelectUserChange(seller){
        const { _recentUserSearch } = this.state;
        this.setState({
            _recentUserSearch: seller && _recentUserSearch
        })
        this.props.onSellerChange(seller);
    }
    _handleOnSelectUserOpen(){
        if(this.props.seller){
            return this.state._recentUserSearch&&this.refs.selectSeller.loadSelectOptions(this.state._recentUserSearch);
        }
        return this.refs.selectSeller.loadSelectOptions('');
    }

    render(){
        return(
            <SelectUserAsync
                defaultOptions
                id="formSelectSeller"
                ref="selectSeller"
                value={this.props.seller}
                paramsApi={{ roles: 'user,seller,unsuspended_seller,administrator', role_query: 'or', scope: 'all' }}
                placeholder="Type and select a seller..."
                noResultsText="Cannot find seller."
                onSelectSearch={(input)=>this.setState({_recentUserSearch: input})}
                onSelectChange={this._handleOnSelectUserChange}
                onSelectOpen={this._handleOnSelectUserOpen}
            />
        )
    }
}

export default SellerFilterForm;

SellerFilterForm.defaultProps = {
}

SellerFilterForm.propTypes = {
    seller: propTypes.object,
    onSellerChange: propTypes.func.isRequired
}
