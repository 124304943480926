import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Style from './RowItem.module.css';
import Badge from "../../../../../components/Badges/BadgeThin";
import FontAwesomeIcon from "../../../../../components/Icons/FontAwesomeIcon";
import { TextRupiah } from "../../../../../components/Text";
import { Link } from "react-router-dom";
import DateToHuman from "../../../../../components/DateToHuman";
import { Button, ButtonGroup } from "reactstrap";
import { joinClassNames } from "../../../../../helpers/formatter";
import ButtonStarred from "../../../../Sales/RowItem/ButtonStarred";
import moment from 'moment';
import Timer from '../../../../Sales/Outstanding/Timer';

export default function RowItem(props) {
    const {
        rowId,
        item,
        onOpenReceivedModal,
        timer,
        onOpenMarkedModal,
        onOpenRejectModal,
    } = props;

    const [hour, setHour] = useState(0);
    const [min, setMin] = useState(0);
    const [sec, setSec] = useState(0);
    const [isCancel, setIsCancel] = useState(false);

    const sneakersCondition = useMemo(() => {
        if (item?.user_sell?.sneakers_condition === 'BARU') {
            if (
                item?.user_sell?.missing_accessories ||
                item?.user_sell?.yellowing ||
                item?.user_sell?.display_item ||
                item?.user_sell?.sneakers_defect
            ) {
                return 'Standard 99%';
            }
            return 'Brand New';
        }

        return item?.user_sell?.sneakers_condition;
    }, [item?.user_sell]);

    useEffect(() => {
        if (!timer) return;
        setTimer();
        setInterval(setTimer, 1000);
    }, [item?.updated_at, item?.created_at, timer]);

    const setTimer = useCallback(() => {
        const then = item?.updated_at || item?.created_at;
        let hours = moment().diff(then, 'hours');
        let mins = moment().diff(then, 'minutes') % 60;
        let secs = moment().diff(then, 'seconds') % 60;
        let cancel = false;
        if (hours >= 96) {
            hours = 96; mins = 0; secs = 0; cancel = true;
        }
        setHour(hours);
        setMin(mins);
        setSec(secs);
        setIsCancel(cancel);
    }, [item?.updated_at, item?.created_at]);

    return (
        <tr>
            <th className="v-center" scope="row">
                {rowId}
            </th>
            {
                timer ?
                    <Timer
                        hours={hour}
                        minutes={min}
                        seconds={sec}
                    />
                    :
                    null
            }
            <td className={Style['consignment-detail']}>
                <div className={Style['consignment-detail-upper-row']}>
                    {item.unread_notes_count > 0 && <span style={{ fontSize: '.65rem' }} className="mr-1 text-danger">
                        <FontAwesomeIcon iconType="circle" />
                    </span>}
                    <p className={Style['consignment-detail-upper-row__id']}>#{item.id}</p>
                    <p className={Style['consignment-detail-upper-row__consignment-id']}>{item.consignment_number}</p>
                </div>
                <div className={Style['consignment-detail-mid-upper-row']}>
                    <p className={Style['consignment-detail-mid-upper-row__display-name']}>{item?.user_sell?.product_variant?.display_name}</p>
                    <div className={Style['consignment-detail-mid-upper-row-info']}>
                        <p className={Style['consignment-detail-mid-upper-row-info__sku']}>({item?.user_sell?.product_variant?.SKU}) Quantity: {item?.user_sell?.quantity}</p>
                        <p className={Style['consignment-detail-mid-upper-row-info__color']}>{item?.user_sell?.product_variant?.colour}</p>
                    </div>
                </div>
                <div className={Style['consignment-detail-mid-lower-row']}>
                    <Badge
                        color="primary"
                        className="text-uppercase mr-1"
                    >
                        <FontAwesomeIcon iconType="tags" /> {item?.product_variant?.product?.category?.name}
                    </Badge>
                    <Badge
                        color="success"
                        className="text-uppercase mr-1"
                    >
                        <FontAwesomeIcon iconType="tag" /> {sneakersCondition}
                    </Badge>
                    <Badge
                        color="success"
                        className="text-uppercase mr-1"
                    >
                        Size: {item?.user_sell?.size?.US}
                    </Badge>
                </div>
                <div className={Style['consignment-detail-lower-row']}>
                    <span className={joinClassNames(Style['consignment-detail-lower-row__text'], 'mr-1')}>
                        <FontAwesomeIcon iconType="calendar" className="mr-1" />{item.created_at} - {item.user?.email ?? ''}
                    </span>
                    <span className={joinClassNames(Style['consignment-detail-lower-row__text'], 'mr-1')}> (<b>Seller</b>)</span>
                </div>
            </td>
            <td className="v-center">
                <TextRupiah tag="span" prefix="Rp. " value={item.price} />
                <div className="issued-wrapper" style={{ minWidth: 95 }}>
                    <a
                        href="#"
                        className="mr-1"
                        style={{ textDecoration: 'underline', color: '#4a4a4a', fontSize: '.75rem' }}
                        onClick={e => {
                            e.preventDefault();
                            onOpenMarkedModal();
                        }}
                    >
                        <i className="fa fa-pencil"></i> {item.notes_count > 0 ? 'view' : 'add'} notes
                    </a>
                    <ButtonStarred
                        title={item.marked ? "Remove mark!" : "Mark!"}
                        value={item.marked}
                        onClick={() => { onOpenMarkedModal(item.marked) }}
                    />
                </div>
            </td>
            <td className={Style['shipping-method']}>
                <p className={Style['shipping-method-title']}>{item?.seller_courier ?? '-'}</p>
                {/*<Link to={'#'} className={Style['shipping-method-info']}>Tracking Shipment</Link>*/}
            </td>
            <td className="v-center">
                <DateToHuman value={item.updated_at} id={`tooltip_${rowId}`} />
            </td>
            <td className="v-center">
                {/*{(item.status === 'NEW' && item.seller_responded !== null) || item.status === 'SELLER_SENT' ? (*/}
                <ButtonGroup>
                    <Button
                        color="success"
                        size="sm"
                        title="Received From Seller"
                        onClick={onOpenReceivedModal}
                    >
                        Received From Seller
                    </Button>
                    {isCancel && <Button
                        color="danger"
                        size="sm"
                        title="Reject"
                        onClick={onOpenRejectModal}
                    >
                        <i className="fa fa-times"></i>
                    </Button>}
                    <Link to={`/consignment/${item.id}`} className="btn btn-sm btn-secondary" target="_blank" title="More Detail..."><i className="fa fa-external-link"></i></Link>
                </ButtonGroup>
                {/*) : (*/}
                {/*    <ButtonGroup>*/}
                {/*        <Button*/}
                {/*            color="success"*/}
                {/*            size="sm"*/}
                {/*            title="Accept"*/}
                {/*            onClick={onOpenAcceptModal}*/}
                {/*        >*/}
                {/*            <i className="fa fa-check"></i>*/}
                {/*        </Button>*/}
                {/*        <Button*/}
                {/*            color="danger"*/}
                {/*            size="sm"*/}
                {/*            title="Reject"*/}
                {/*            onClick={onOpenRejectModal}*/}
                {/*        >*/}
                {/*            <i className="fa fa-times"></i>*/}
                {/*        </Button>*/}
                {/*        <Link to={`/consignment/${item.id}`} className="btn btn-sm btn-secondary" target="_blank" title="More Detail..."><i className="fa fa-external-link"></i></Link>*/}
                {/*    </ButtonGroup>*/}
                {/*)}*/}
            </td>
        </tr>
    );
}
