import React from 'react';
import propTypes from 'prop-types';
import { Card, CardImg, CardBody, CardText, CardLink } from 'reactstrap';
import { FontAwesomeIcon } from '../../../components/Icons';
import BadgeStorage from './cardThumbnail/BadgeStorage';
import LoadingPlaceholder from './cardThumbnail/LoadingPlaceholder';
import { getCacheImage } from '../../../utils/imageHelper'

import styled from 'styled-components';

const CardCustomImgOverlay = styled.div`
  cursor: move;
  position: relative;
  background: none;
  > img {
    height: 250px;
    object-fit: cover;
  }
  &:after{
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    content: 'Geser gambar untuk mengurutkan';
    color: #FFF;
  }
  &:hover:after{
    opacity: 1;
  }
  &:hover > img {
    opacity: .3;
  }
  &:hover {
    background: #4a4a4a;
  }
`;

const CardThumbnail = ({id, url, updatedAt, storageName, fileInput, isUploading, isDeleting, onRemoveImage, position, positionEdit, debug}) => (
  <Card style={{marginBottom:'1rem'}}>
    <LoadingPlaceholder
      isVisible={isUploading||isDeleting}
      loadingColor={isDeleting ? "#f86c6b":"green"}
      loadingText={isDeleting ? "Deleting...": "Uploading..."}
    />
    <BadgeStorage storageName={storageName}/>
    <CardCustomImgOverlay>
      <CardImg top src={id ? getCacheImage(url) : fileInput.preview} alt={id?url:fileInput.preview}/>
    </CardCustomImgOverlay>
    <CardBody>
      <CardText>
        <small className="text-muted">{id? `Last updated ${updatedAt}`:'Not uploaded yet...'}</small>
      </CardText>
      {
        id?
        <CardLink href={getCacheImage(url)} className="text-default" target="_blank">
          <FontAwesomeIcon iconType="external-link" /> Open
        </CardLink>
        :null
      }
      <button type="button" className="btn btn-link text-danger card-link" style={{padding: 0}} onClick={onRemoveImage}>
        <FontAwesomeIcon iconType="remove" /> Delete
      </button>
      {
        debug?(
          <div>
            <span>position: {position}</span> {' '}
            <span>positionEdit: {positionEdit}</span>
          </div>
        ):null
      }
    </CardBody>
  </Card>
) 

export default CardThumbnail;

CardThumbnail.propTypes = {
  id: propTypes.number,
  url: propTypes.string,
  updatedAt: propTypes.string,
  storageName: propTypes.string,
  fileInput: propTypes.object,
  isUploading: propTypes.bool,
  isDeleting: propTypes.bool,
  onRemoveImage: propTypes.func
}
CardThumbnail.defaultProps = {
  isUploading: false,
  isDeleting: false,
  fileInput: {
    preview: ''
  }
}