import React from 'react';
import propTypes from 'prop-types';
import {
  Card,
  CardText,
  Row,
  Col,
} from 'reactstrap';
import { getCacheImage } from '../../../../utils/imageHelper';
import noImage from  '../../../../assets/img/no-image.png';
import RaffleRequestDetailCard from './tabRaffleRequestDetail/RaffleRequestDetailCard';
import RaffleRequestSummaryCard from './tabRaffleRequestDetail/RaffleRequestSummaryCard';

const TabRaffleRequestDetail = props => {
  const { product_variant } = props.dataRaffle;
  const featuredImage = product_variant && product_variant.product_variant_images.length>0?
    getCacheImage(product_variant.product_variant_images[0].URL) : noImage;
  return (
    <Card body className="tab-content--custom__card">
      <CardText>About their's raffle request.</CardText>
      <Row>
        <Col xs={12} md={6}>
          <RaffleRequestDetailCard
            id={props.dataRaffle.id}
            isLoading={props.isLoading}
            code={props.dataRaffle.code}
            raffleCode={props.dataRaffle.raffle_code}
            payment={props.dataRaffle.offer}
            status={props.dataRaffle.status}
            currency={props.dataRaffle.currency}
            raffle_entry_id={props.dataRaffle.raffle_entry_id}
            paymentMethod={props.dataRaffle.payment_method}
            amount={props.dataRaffle.amount}
            points={props.dataRaffle.points}
            isSubmit={props.isSubmit}
            administrationFee={props.dataRaffle.administration_fee}
            kaCourier={props.dataRaffle.ka_courier}
            kaCourierOption={props.dataRaffle.ka_courier_option}
            kaCourierPrice={props.dataRaffle.ka_courier_price}
            raffleEntries={props.dataRaffle.raffle_entries}
            expiredAt={props.dataRaffle.expired_at}
            updatedAt={props.dataRaffle.updated_at}
            handleTextChange={props.handleTextChange}
            disabledDropdownStatus={props.disabledDropdownStatus}
            error={props.error}
            errorMsg={props.errorMsg}
            successMsg={props.successMsg}
            isErrorAlertDisplayed={props.isErrorAlertDisplayed}
            isSuccessAlertDisplayed={props.isSuccessAlertDisplayed}
            onSubmit={props.onSubmit}
          />
        </Col>
        <Col xs={12} md={6}>
          <RaffleRequestSummaryCard
            id={props.dataRaffle.id}
            isLoading={props.isLoading}
            productVariant={product_variant}
            amount={props.dataRaffle.amount}
            kaCourier={props.dataRaffle.ka_courier}
            kaCourierOption={props.dataRaffle.ka_courier_option}
            kaCourierPrice={props.dataRaffle.ka_courier_price}
            administrationFee={props.dataRaffle.administration_fee}
            totalAmount={props.dataRaffle.total_amount}
            paymentMethod={props.dataRaffle.payment_method}
            size={props.dataRaffle.size}
          />
        </Col>
      </Row>
    </Card>
  )
}

export default TabRaffleRequestDetail;
TabRaffleRequestDetail.defaultProps = {
  isSubmit: false,
  isLoading: false,
  isErrorAlertDisplayed: false,
  isSuccessAlertDisplayed: false,
  disabledDropdownStatus: false,
  errorMsg: '',
  successMsg:'',
  dataRaffle: {
    id: null,
    product_variant: {
      id: null,
      display_name: '',
      product:{
        brand_id: null,
        brand: {
          id: null
        },
        category_id: null,
        category: {
          id: null
        }
      },
      product_variant_images: []
    },
    user_sell: {
      id: null,
      product_variant_images: []
    },
    offer: {
      id: null,
      invoice_number: ''
    }
  }
}

TabRaffleRequestDetail.propTypes = {
  isLoading: propTypes.bool,
  isSubmit: propTypes.bool,
  isErrorAlertDisplayed: propTypes.bool,
  isSuccessAlertDisplayed: propTypes.bool,
  disabledDropdownStatus: propTypes.bool,
  errorMsg: propTypes.string,
  successMsg: propTypes.string,
  dataRaffle: propTypes.object,
}