import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import propTypes from 'prop-types';
import DateToHuman from '../../../components/DateToHuman';
import { updateStatusUserSell } from '../../../actions/sellRequestAction';

class RowItem extends React.Component {
  constructor(props){
    super(props);
  }

  render(){
    const {
      id,
      rowId,
      name,
      maxCapacity,
      currentCapacity,
      updatedAt
    } = this.props;
    const isWarningCapacity = (currentCapacity / maxCapacity) * 100 >= 90;
    return (
      <tr>
        <th className="v-center" scope="row">{ rowId }</th>
        <td className="v-center">{ name }</td>
        <td className="v-center" style={{ color: isWarningCapacity?'red':'normal' }}>{currentCapacity}/{maxCapacity}</td>
        <td className="v-center"><DateToHuman  value={ updatedAt } id={`tooltip_sellRequest_rowItem_updatedAt_${rowId}`}/></td>
        <td className="v-center">
          <div className="d-flex" style={{flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start'}}>
            <Link to={`/racks/${id}`} className="mb-2"><i className="fa fa-pencil"></i> Edit</Link>
          </div>
        </td>
      </tr>
    )
  }
}

const mapStateToProps = ({ sellRequest: { updateStatus, isSubmit } }) => ({
  sellRequest: { ...updateStatus, isSubmit }
})
const mapDispatchToProps = (dispatch) => ({
  updateSellRequest: (id, payload) => dispatch(updateStatusUserSell(id, payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(RowItem);

RowItem.propTypes = {
  rowId: propTypes.number.isRequired,
  id: propTypes.number.isRequired,
  name: propTypes.string,
  maxCapacity: propTypes.number,
  defaultRoute: propTypes.string
}

RowItem.defaultProps = {
  name: '-',
  maxCapacity: '-',
}