import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import classNames from 'classnames';

const Button = styled.a`
  padding: 0;
  margin-top: .5rem;
  margin-bottom: .5rem;
  font-size: 0.75rem;
  text-decoration: underline;
  color: #4a4a4a;
  > i.fa{
    text-decoration: none;
    margin-right: .25rem;
    font-size: .65rem;
  }
`
const handleOnCopied = (e, value, cb) => {
  e.preventDefault();
  if (value !== "") {
    let selection = null, mark = null;

    mark = document.createElement('span');
    mark.textContent = value;
    // reset user styles for span element
    mark.style.all = 'unset';
    // prevents scrolling to the end of the page
    mark.style.position = 'fixed';
    mark.style.top = 0;
    mark.style.clip = 'rect(0, 0, 0, 0)';
    // used to preserve spaces and line breaks
    mark.style.whiteSpace = 'pre';
    // do not inherit user-select (it may be `none`)
    mark.style.webkitUserSelect = 'text';
    mark.style.MozUserSelect = 'text';
    mark.style.msUserSelect = 'text';
    mark.style.userSelect = 'text';
    const range = document.createRange();
    document.body.appendChild(mark);
    range.selectNode(mark);
    selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);
    const success = document.execCommand("copy");
    if(success){
      if(selection) selection.removeAllRanges();
      if(mark) document.body.removeChild(mark);
      if(cb) {
        cb();
      } else
        alert('Copied!');
    }
  }
}

const ButtonCopy = ({ hide, value, children, className, ...props }) => (
  <Button
    href="#"
    className={classNames("btn-copy-clipboard", className, {'d-none': hide})}
    onClick={e=> handleOnCopied(e, value, props.onSuccessCopied)}
  >
    { children }
  </Button>
)

export default ButtonCopy;

ButtonCopy.defaultProps = {
  hide: false,
  value: ''
}

ButtonCopy.propTypes = {
  hide: propTypes.bool,
  children: propTypes.node,
  value: propTypes.string,
  className: propTypes.string,
}