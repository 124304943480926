import React from 'react';
import propTypes from 'prop-types';
import MenuPlaceholder from './authorizedMenu/MenuPlaceholder';

const isAutorhized = (routesArray, routeName) =>{
  let bool = false;
  // console.log("TEST ", )
  if(typeof routeName === 'string') return routesArray.includes(routeName)

  for (let i = 0; i < routeName.length; i++) {
    const r = routeName[i];
    if(routesArray.includes(r)){
      bool = true;
      break;
    }
  }
  return bool
}

const AuthorizedMenu = props => {
  const { isLoading, render, routes, routeName } = props;
  return(
    !isLoading? (isAutorhized(routes, routeName) ? render(): null): <MenuPlaceholder/>
  )
}
export default AuthorizedMenu;

AuthorizedMenu.propTypes = {
  isLoading: propTypes.bool,
  render: propTypes.func,
  routes: propTypes.arrayOf(propTypes.string),
  routeName: propTypes.oneOfType([propTypes.string, propTypes.arrayOf(propTypes.string)]).isRequired
}

AuthorizedMenu.defaultProps = {
  isLoading: false,
  routes: []
}