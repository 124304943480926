import React, { PureComponent as Component } from 'react';
import { CardBody, Collapse } from 'reactstrap';
import propTypes from 'prop-types';

import SearchForm from './SearchForm';
import SortForm from './SortForm';

export default class Filters extends Component {
  render(){
    return(
      <Collapse isOpen={this.props.isOpen}>
        <CardBody className="filter-container__card-body">
          <SearchForm
            keyword = {this.props.keyword}
            start_at = {this.props.start_at}
            end_at = {this.props.end_at}
            onInputChange = {this.props.onHandleChange}
            onSubmit = {this.props.onHandleSearch}
            onRegisteredAtChange = {this.props.onHandleRegisteredAtChange}
          />
          <hr/>
          <SortForm
            per_page={this.props.per_page}
            sort_by={this.props.sort_by}
            onInputChange={this.props.onHandleChangeAndSubmit}
          />
        </CardBody>
      </Collapse>
    )
  }
}

Filters.propTypes= {
  keyword: propTypes.string,
  per_page: propTypes.oneOfType([propTypes.number, propTypes.string]),
  sort_by: propTypes.string,
  isOpen: propTypes.bool.isRequired,
  onHandleChangeAndSubmit:propTypes.func,
  onHandleChange: propTypes.func,
  onHandleSearch: propTypes.func
}

Filters.defaultProps= {
  keyword: '',
  per_page: 15,
  sort_by: ''
}