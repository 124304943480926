import React, { Component } from 'react';
import propTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  Card,
  CardBody
} from 'reactstrap';
import { Tbl, TblHead, TblBody } from '../../../components/Tbl';
import Paginations from '../../../components/Paginations';
import { CardHeaderWithToolbars, Title } from '../../../components/Card/CardHeaderWithToolbars';
import { generateNumber } from '../../../utils';

const dataHeader = ['No. ', 'Invoice', 'Created At', 'Updated At'];
export default class RewardItemList extends Component{
  constructor(props){
    super(props);
    this.state={
      _params:{

      }
    }
    this._handleGoto = this._handleGoto.bind(this);
  }

  componentDidMount(){
    this.props.onGetList(this.props.id, {params: this.state._params});
  }
 
  _onRenderRowItem(){
    if(this.props.dataRewardItem.list.status_code===200 && this.props.dataRewardItem.list.data.length>0)
    {
      const { current_page, per_page, data } =  this.props.dataRewardItem.list;
      return data.map((item,index)=>(
          <tr key={generateNumber(per_page,current_page,index)}>
            <th scope="row">{ generateNumber(per_page,current_page,index) }</th>	
            <td><Link title="Sales Details..." target="_blank" to={`/sales/${item.rewardable_id}`}>{ item.rewardable.invoice_number }</Link></td>
            <td>{ item.created_at }</td>
            <td>{ item.updated_at }</td>
          </tr>
        )
      )	
    }
    return null;
  }

  _handleGoto(page){
    console.log('page', page);
    const _params=this.state._params;
    _params.page=page;
    this.setState({_params},()=>this.props.onGetList(this.props.id, {params: this.state._params}));
  }

  _onRenderPagination(){
    const { status_code, data, ...paginations } = this.props.dataRewardItem.list;
    const hasData = (status_code===200 && data.length) || null;
    return hasData && (
      <Paginations 
        size="sm" 
        lastPage={paginations.last_page} 
        perPage={paginations.per_page}
        total={paginations.total}
        maxPage={8}
        path={paginations.path}
        currentPage={paginations.current_page} 
        prevPageUrl={paginations.prev_page_url} 
        nextPageUrl={paginations.next_page_url} 
        handleGotoCallback={this._handleGoto}
      />
    )
  }

  render(){
    return(
      <Card>
        <CardHeaderWithToolbars>
          <Title>
            <i className="fa fa-align-justify"></i>
            <span>Reward Item</span>
          </Title>
        </CardHeaderWithToolbars>
        <CardBody>
          <div className="table-responsive">
            <Tbl>
              <TblHead
                  dataHeader={dataHeader}
              />
              <TblBody
                isLoad={this.props.dataRewardItem.isFetch}
                hasRow={this.props.dataRewardItem.list.status_code===200&&this.props.dataRewardItem.list.data.length>0}
                columnCount={dataHeader.length}
              >
                { this._onRenderRowItem() }
              </TblBody>
            </Tbl>
          </div>
          { this._onRenderPagination() }
        </CardBody>
      </Card>
    )
  }
}
RewardItemList.propTypes = {
  id: propTypes.number.isRequired,
  onGetList: propTypes.func.isRequired,
  dataRewardItem: propTypes.object,
}
RewardItemList.defaultProps = {
  
}