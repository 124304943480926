import {
  AUTH_TOKEN_KEY,
	CONSIGNMENT_ROUTE_API,
	GET_LIST_CONSIGNMENT,
  FETCHING_CONSIGNMENT,
  CONSIGNMENT_ERROR,
  GET_DETAIL_CONSIGNMENT,
  RACK_REQUEST_CONSIGNMENT,
  CREATE_RACK_CONSIGNMENT,
  RACK_ERROR_CONSIGNMENT,
  UPDATE_FORCE_CANCELLED_CONSIGNMENT,
  ADMIN_DELIVERING_SNEAKER_CONSIGNMENT,
  UPDATE_CONSIGNMENT,
  ROLLBACK_CONSIGNMENT,
  FETCHING_CONSIGNMENT_NOTES,
  GET_CONSIGNMENT_NOTES,
  READ_CONSIGNMENT_NOTES,
  CONSIGNMENT_NOTES_SUBMIT,
} from '../constants';
import {
  onRequest,
  onSuccessRequest,
  onFailedRequest
} from './helpers';

import { logout } from './authAction';

import api from '../utils/Api';
import { apiClient } from '../utils/Api';
import { manipulateErrors, checkAuthorized } from '../utils';

/**
 * Get list of offers
 * @author sarah
 * @guards jwt-token
 * @param {object?} query
 */
export const getConsignmentList=(query)=>{
	return dispatch =>(
		dispatch(isFetch({type:'fetch',status:true})),
		api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).get(`${CONSIGNMENT_ROUTE_API}`,query)
		.then(res=>{
			dispatch(onSuccess(res.data,GET_LIST_CONSIGNMENT))
			dispatch(isFetch({type:'fetch',status:false}))
		})
		.catch(err => {
			dispatch(onError(err, CONSIGNMENT_ERROR));
			dispatch(isFetch({type:'fetch',status:false}));
		})
	)
}

/**
 * Get detail of Offer
 * @guards jwt-token
 * @param {integer} id
 */
export const getDetailConsignment=(id)=>{
	return dispatch => (
		dispatch(isFetch({type:'fetch',status:true})),
		api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).get(`${CONSIGNMENT_ROUTE_API}/${id}`)
		.then(res=>{
			dispatch(onSuccess(res.data,GET_DETAIL_CONSIGNMENT))
			dispatch(isFetch({type:'fetch',status:false}))
		})
		.catch(err => {
			dispatch(onError(err, CONSIGNMENT_ERROR));
			dispatch(isFetch({type:'fetch',status:false}));
		})
	)
}

export const attachRackConsignment = (id, payload) => (
  dispatch => {
    dispatch(onRequest(RACK_REQUEST_CONSIGNMENT, CREATE_RACK_CONSIGNMENT, true));
    apiClient()
    .post(`${CONSIGNMENT_ROUTE_API}/${id}/racks`, payload)
    .then(res => {
      dispatch(onSuccessRequest(CREATE_RACK_CONSIGNMENT, res.data));
      dispatch(onRequest(RACK_REQUEST_CONSIGNMENT, CREATE_RACK_CONSIGNMENT, false));
    })
    .catch(err => {
      dispatch(onFailedRequest(RACK_ERROR_CONSIGNMENT, CREATE_RACK_CONSIGNMENT, err));
      dispatch(onRequest(RACK_REQUEST_CONSIGNMENT, CREATE_RACK_CONSIGNMENT, false));
    });
  }
)

export const attachRackConsignmentV2 = (id, payload) => (
	dispatch => {
	  dispatch(onRequest(RACK_REQUEST_CONSIGNMENT, CREATE_RACK_CONSIGNMENT, true));
	  return apiClient()
		.post(`${CONSIGNMENT_ROUTE_API}/${id}/racks`, payload)
		.then(res => {
		  dispatch(onSuccessRequest(CREATE_RACK_CONSIGNMENT, res.data));
		  dispatch(onRequest(RACK_REQUEST_CONSIGNMENT, CREATE_RACK_CONSIGNMENT, false));
		  return res.data; // Mengembalikan data jika diperlukan
		})
		.catch(err => {
		  dispatch(onFailedRequest(RACK_ERROR_CONSIGNMENT, CREATE_RACK_CONSIGNMENT, err));
		  dispatch(onRequest(RACK_REQUEST_CONSIGNMENT, CREATE_RACK_CONSIGNMENT, false));
		  throw err; // Lempar error untuk ditangani di .catch
		});
	}
  );
  

export const doForceCancelledConsignment = (id,payload) =>{
	return dispatch => (
		dispatch(isFetch({type:'submit',status:true})),
		api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).put(`${CONSIGNMENT_ROUTE_API}/${id}/force_cancelled`,{...payload})
		.then(res=>{
			dispatch(onSuccess(res.data,UPDATE_FORCE_CANCELLED_CONSIGNMENT))
			dispatch(isFetch({type:'submit',status:false}))
		})
		.catch(err => {
			dispatch(onError(err, CONSIGNMENT_ERROR));
			dispatch(isFetch({type:'submit',status:false}));
		})
	)
}

export const doDeliverSneakerConsignment = (id, payload)=>(
	dispatch => (
		dispatch(isFetch({type:'submit',status:true})),
		api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).put(`${CONSIGNMENT_ROUTE_API}/${id}`,{...payload, status: 'PENDING_DELIVERING'})
		.then(res=>{
			dispatch(onSuccess(res.data, ADMIN_DELIVERING_SNEAKER_CONSIGNMENT))
			dispatch(isFetch({type:'submit',status:false}))
		})
		.catch(err => {
			dispatch(onError(err, CONSIGNMENT_ERROR));
			dispatch(isFetch({type:'submit',status:false}));
		})
	)
)

export const doMarkedConsignment = (id,payload) =>{
	return dispatch => {
		api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).put(`${CONSIGNMENT_ROUTE_API}/${id}/marked`,payload)
		.then(res=>{
			dispatch(onSuccess(res.data,UPDATE_CONSIGNMENT));
		})
		.catch(err => {
			dispatch(onError(err, CONSIGNMENT_ERROR, id));
		})
	}
}

export const updateConsignment = (id,payload) =>{
	return dispatch => (
		dispatch(isFetch({type:'submit',status:true})),
		api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).put(`${CONSIGNMENT_ROUTE_API}/${id}`,payload)
		.then(res=>{
			dispatch(onSuccess(res.data,UPDATE_CONSIGNMENT))
			dispatch(isFetch({type:'submit',status:false}))
		})
		.catch(err => {
			dispatch(onError(err, CONSIGNMENT_ERROR, id));
			dispatch(isFetch({type:'submit',status:false}));
		})
	)
}

export const doUpdateAwbNumber = (id, payload)=>(
	dispatch => (
		dispatch(isFetch({type:'submit',status:true})),
		api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).put(`${CONSIGNMENT_ROUTE_API}/${id}/awb_number`,{...payload})
		.then(res=>{
			dispatch(onSuccess(res.data, ADMIN_DELIVERING_SNEAKER_CONSIGNMENT))
			dispatch(isFetch({type:'submit',status:false}))
		})
		.catch(err => {
			dispatch(onError(err, CONSIGNMENT_ERROR));
			dispatch(isFetch({type:'submit',status:false}));
		})
	)
)

export const doRollbackConsignment = (id,payload) =>{
	return dispatch => (
		dispatch(isFetch({type:'submit',status:true})),
		api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).put(`${CONSIGNMENT_ROUTE_API}/${id}/rollback`,{...payload})
		.then(res=>{
			dispatch(onSuccess(res.data,ROLLBACK_CONSIGNMENT))
			dispatch(isFetch({type:'submit',status:false}))
		})
		.catch(err => {
			dispatch(onError(err, CONSIGNMENT_ERROR));
			dispatch(isFetch({type:'submit',status:false}));
		})
	)
}

export const getConsignmentNotes = (id, query) => (
	dispatch => {
		dispatch({
			type: FETCHING_CONSIGNMENT_NOTES,
			payload: true
		});
		api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).get(`${CONSIGNMENT_ROUTE_API}/${id}/notes`, query)
    .then(res => {
			dispatch({
				type: GET_CONSIGNMENT_NOTES,
				payload: { id, ...res.data }
			});
    })
    .catch(err => {
			dispatch({
				type: FETCHING_CONSIGNMENT_NOTES,
				payload: {
					id: id, value: false, error: err, errorKey: 'list'
				}
			});
    });
	}
)

export const setReadConsignmentNotes = (id, body) => (
	dispatch => {
		dispatch({
			type: CONSIGNMENT_NOTES_SUBMIT,
			payload: true
		});
		api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).put(`${CONSIGNMENT_ROUTE_API}/${id}/notes/reads`, body)
    .then(res => {
      dispatch({
				type: READ_CONSIGNMENT_NOTES,
				payload: { id, ...res.data }
			});
    })
    .catch(err => {
			dispatch({
				type: CONSIGNMENT_NOTES_SUBMIT,
				payload: {
					id: id, value: false, error: err, errorKey: 'readNotes'
				}
			});
    });
	}
)

/**
 * Indicates when start fetch api or end fetch api
 * @author sarah
 * @param {boolean} bool
 */
export const isFetch=(payload)=>(
	{
		type:FETCHING_CONSIGNMENT,
		payload:payload
	}
)

/**
 * When onSuccess triggered
 * @author sarah
 * @param {object} res
 * @param {string} type
 */
export const onSuccess=(res,type)=>
(
	{
		type:type,
		payload:res
	}
)
/**
 * When error fetch or connect to api
 * @author sarah
 * @param {object} err
 */
// On Error fetching api
export const onError=(err, type = CONSIGNMENT_ERROR, id)=>{
	if(!checkAuthorized(manipulateErrors(err)))
		return logout();
	return {
		type,
		payload: { ...manipulateErrors(err), id}
	}
}
