import React from 'react';
import propTypes from 'prop-types';
import MenuPlaceholder from './authorizedMenu/MenuPlaceholder';

const UnAuthorizedMenu = props => {
    const { isLoading, render } = props;
    return(
        !isLoading? (render()): <MenuPlaceholder/>
    )
}
export default UnAuthorizedMenu;

UnAuthorizedMenu.propTypes = {
    isLoading: propTypes.bool,
    render: propTypes.func
}

UnAuthorizedMenu.defaultProps = {
    isLoading: false,
}