import { useFormik } from 'formik';
import { object, string, array } from 'yup';

let formSchema = object({
    product: array().length(0, 'Product Required'),
    email: string().email('Invalid Email').required('Email Required'),
    phoneNumber: string().required('Phone Number Required'),
    shippingMethod: string().required('Shipping Method Required'),
    paymentMethod: string().required('Payment Method Required'),
});

export const usePaymentForm = () => {
    const formik = useFormik({
        initialValues: {
            product: [],
            email: '',
            phoneNumber: '',
            shippingMethod: '',
            alias: '',
            recipientName: '',
            mobileNumber: '',
            address: '',
            province: null,
            city: null,
            postalCode: '',
            shippingNote: '',
            paymentMethod: '',
            referenceNumber: '',
            amountReceived: '',
            voucher: null,
            remarks: '',
            shippingId: '',
        },
        validationSchema: formSchema,
        onSubmit: (values) => {
            console.log(values);
        },
        key: 'paymentForm',
    });

    return {
        formik,
    };
}
