import api from "../../../../utils/Api";
import {AUTH_TOKEN_KEY, CONSIGNMENT_ROUTE_API} from "../../../../constants";
import {useState} from "react";

export function usePutConsignmentMarked() {
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const putConsignmentMarked = async (id, payload) => {
        setLoading(true);

        return api({
            Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
        }).put(`${CONSIGNMENT_ROUTE_API}/${id}/marked`, payload)
            .then(res=> {
                setLoading(false);
                setData(res.data.data);
                return res.data.data;
            })
            .catch(err => {
                setLoading(false);
                setError(err);
                throw err;
            })
    };

    return {
        data,
        loading,
        error,
        putConsignmentMarked,
    }
}