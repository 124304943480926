import React from 'react';
import propTypes from 'prop-types';
import { Badge } from 'reactstrap';

import {
  isExpired,
  EXPIRED_STATUS,
  UNVERIFIED_STATUS,
  PENDING_STATUS,
  COMPLETED_STATUS,
  REJECTED_STATUS,
  IN_PROGRESS_STATUS,
  getDisbursementStatusStr
} from '../helpers';

const BadgeDisbursementType = (props) => {
  const isTokenExpired = props.expiredAt ? isExpired(props.expiredAt) : false;
  const disbursementStatus = isTokenExpired && props.status === UNVERIFIED_STATUS? EXPIRED_STATUS : props.status;
  return (
    <Badge color={props.status===REJECTED_STATUS?"danger"
    :props.status===COMPLETED_STATUS?"success"
    :(props.status===UNVERIFIED_STATUS && isTokenExpired)?"danger"
    :props.status===IN_PROGRESS_STATUS? "warning": "default"}
    className="text-uppercase"
    >
      {getDisbursementStatusStr(disbursementStatus)}
    </Badge>
  );
}

export default BadgeDisbursementType;

BadgeDisbursementType.defaultProps = {
  status: PENDING_STATUS
}

BadgeDisbursementType.propTypes = {
  status: propTypes.string
}