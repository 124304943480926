import React, { PureComponent as  Component } from 'react';
import {
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Row,
  Container
} from 'reactstrap';
import propTypes from 'prop-types';
import { paymentMethods, paymentStatusOptions, paymentTypeOptions, paymentViaOptions, createdSourceList } from '../../../constants/kickavenue'
import SelectRack from '../../../components/Form/Select/Async/SelectRack';
import SelectVoucher from '../../../components/Form/Select/Async/SelectVoucher';

const filterStatusOptions = [{name:'All',value:''},...paymentStatusOptions]
const filterPaymentViaOptions = [{name:'All',value:''},...paymentViaOptions]
const filterPaymentMethodOptions = [{name:'All',value:''},...paymentTypeOptions]
const filterCreatedSourceOptions = [{name:'All',value:''},...createdSourceList]

export default class FilterForm extends Component{
  render(){
    return(
      <Form inline onSubmit={this.props.onSubmit}>
        <Container style={{padding: 0}}>
          <Row className="mb-1">
            <Col xs={12} sm={3}>
              <FormGroup row className="mb-3">
                <Col xs={12} sm={4} md={3}>
                  <Label className="justify-content-start">
                    Status
                  </Label>
                </Col>
                <Col xs={12} sm={8} md={9}>
                  <Input
                    className="w-100"
                    type="select"
                    name="status"
                    onChange={this.props.onInputChange}
                    value={this.props.status}
                  >
                    {filterStatusOptions.map((option,i)=><option value={option.value} key={i}>{option.name}</option>)}
                  </Input>
                </Col>
              </FormGroup>
            </Col>
            <Col xs={12} sm={3}>
              <FormGroup row className="mb-3">
                <Col xs={12} sm={4} md={3}>
                  <Label className="justify-content-start">
                    Payment Method
                  </Label>
                </Col>
                <Col xs={12} sm={8} md={9}>
                  <Input
                    className="w-100"
                    type="select"
                    name="paymentMethod"
                    onChange={this.props.onInputChange}
                    value={this.props.paymentMethod}
                  >
                    {filterPaymentMethodOptions.map((option,i)=><option value={option.value} key={i}>{option.name}</option>)}
                  </Input>
                </Col>
              </FormGroup>
            </Col>
            <div className="w-100"></div>
            <Col xs={12} sm={3}>
              <FormGroup row className="mb-3">
                <Col xs={12} sm={4} md={3}>
                  <Label className="justify-content-start">
                    Rack
                  </Label>
                </Col>
                <Col xs={12} sm={8} md={9}>
                  <SelectRack
                    defaultOptions
                    id={this.props.filterRackId}
                    ref={this.props.filterRackRef}
                    value={this.props.rack}
                    paramsApi={{ limit: 50 }}
                    placeholder="Type and select a rack..."
                    noResultsText="Cannot find rack."
                    // loadOptions={this.props.getRackOptions}
                    onSelectSearch={this.props.onSelectSearch}
                    onSelectChange={this.props.handleSelectRack}
                    onSelectOpen={this.props.handleOnSelectRackOpen}
                  />
                </Col>
              </FormGroup>
            </Col>
            <Col xs={12} sm={3}>
              <FormGroup row className="mb-3">
                <Col xs={12} sm={4} md={3}>
                  <Label className="justify-content-start">
                    Voucher
                  </Label>
                </Col>
                <Col xs={12} sm={8} md={9}>
                  <SelectVoucher
                    create
                    defaultOptions
                    id={this.props.filterVoucherId}
                    ref={this.props.filterVoucherRef}
                    value={this.props.voucher}
                    paramsApi={{ sort_by: 'createdAt_desc' }}
                    placeholder="Select a voucher..."
                    noResultsText="Cannot find voucher."
                    onSelectChange={this.props.onSelectVoucher}
                  />
                </Col>
              </FormGroup>
            </Col>
            <div className="w-100"></div>
            <Col xs={12} sm={3}>
              <FormGroup row className="mb-3">
                <Col xs={12} sm={4} md={3}>
                  <Label className="justify-content-start">
                    Via
                  </Label>
                </Col>
                <Col xs={12} sm={8} md={9}>
                  <Input
                    className="w-100"
                    type="select"
                    name="paymentVia"
                    onChange={this.props.onInputChange}
                    value={this.props.paymentVia}
                  >
                    {filterPaymentViaOptions.map((option,i)=><option value={option.value} key={i}>{option.name}</option>)}
                  </Input>
                </Col>
              </FormGroup>
            </Col>
            <Col xs={12} sm={3}>
              <FormGroup row className="mb-3">
                <Col xs={12} sm={4} md={3}>
                  <Label className="justify-content-start">
                    Created Source
                  </Label>
                </Col>
                <Col xs={12} sm={8} md={9}>
                  <Input
                    className="w-100"
                    type="select"
                    name="createdSource"
                    onChange={this.props.onInputChange}
                    value={this.props.createdSource}
                  >
                    {filterCreatedSourceOptions.map((option,i)=><option value={option.value} key={i}>{option.name}</option>)}
                  </Input>
                </Col>
              </FormGroup>
            </Col>
            <div className="w-100"></div>
            <Col xs={12} sm={6} className="d-flex justify-content-end">
              <Button color="primary">Apply</Button>
            </Col>
          </Row>
        </Container>
      </Form>
    )
  }
}

FilterForm.propTypes= {
  status: propTypes.string,
  paymentMethod: propTypes.string,
  onInputChange: propTypes.func.isRequired,
  onSubmit: propTypes.func.isRequired,
  rack: propTypes.object,
  voucher: propTypes.object,
  paymentVia: propTypes.string,
  createdSource: propTypes.string,
}

FilterForm.defaultProps= {
  status: '',
  paymentMethod: '',
  rack: null,
  voucher: null,
  paymentVia: '',
  createdSource: '',
}