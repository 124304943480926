import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import {
  Button,
  Form,
  Input,
  InputGroup,
  Row,
  Col,
  FormGroup,
  Label
} from 'reactstrap';

import { perPageOptions } from '../../../../constants/kickavenue';

const Div = styled.div`
  margin-bottom: .75rem
`;

class FilterPanel extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      keyword: props.keyword,
      sortBy: props.sortBy,
      perPage: props.perPage
    }
    this._handleOnChange = this._handleOnChange.bind(this);
    this._handleOnSubmit = this._handleOnSubmit.bind(this);
    this._handleOnChangeAndSubmit = this._handleOnChangeAndSubmit.bind(this);
  }

  _handleOnChange(e){
    const lastState = {...this.state}
    lastState[e.target.name] = e.target.value
    this.setState({...lastState})
  }

  _handleOnChangeAndSubmit(e){
    const lastState = {...this.state}
    lastState[e.target.name] = e.target.value
    this.setState({...lastState}, ()=>this.props.onApplyFilter({
      keyword: this.state.keyword,
      per_page: this.state.perPage,
      sort_by: this.state.sortBy,
    }))
  }

  _handleOnSubmit(e){
    e.preventDefault();
    this.props.onApplyFilter({
      keyword: this.state.keyword,
      per_page: this.state.perPage,
      sort_by: this.state.sortBy,
    })
  }

  render(){
    return (
      <Div>
        <Form
          onSubmit={this._handleOnSubmit}
        >
          <Row>
            <Col xs={12} md={6}>
              <FormGroup>
                <InputGroup>
                  <Input
                    placeholder="Search email seller, rack..."
                    value={this.state.keyword}
                    name="keyword"
                    onChange={this._handleOnChange}
                  />
                  <Button color="primary">Search</Button>
                </InputGroup>
              </FormGroup>
            </Col>
          </Row>
        </Form>
        <Form inline className="form-filter">
          <FormGroup className="mb-2 mr-3">
              <Label className="mr-sm-3">
                Show
              </Label>
              <Input
                  type="select"
                  value={this.state.perPage}
                  name="perPage"
                  onChange={this._handleOnChangeAndSubmit}
              >
                {perPageOptions.map((option,i)=><option value={option.value} key={i}>{option.label}</option>)}
              </Input>
          </FormGroup>
          <FormGroup className="mb-2 mr-3">
              <Label className="mr-sm-3">
                Sort By
              </Label>
              <Input
                type="select"
                value={this.state.sortBy}
                name="sortBy"
                onChange={this._handleOnChangeAndSubmit}
              >
                <option value="">Default</option>
                <option value="createdAt_desc">Newest</option>
                <option value="updatedAt_desc">Last Updated</option>
                <option value="updatedAt_asc">Older</option>
                <option value="askingPrice_desc">Highest Price</option>
                <option value="askingPrice_asc">Lowest Price</option>
                <option value="approvedAt_desc">Latest Approved</option>
              </Input>
          </FormGroup>
        </Form>
      </Div>
    )
  }
}

export default FilterPanel;

FilterPanel.defaultProps = {
  keyword: '',
  perPage: 15
}

FilterPanel.propTypes = {
  keyword: propTypes.string,
  perPage: propTypes.oneOfType([propTypes.string,propTypes.number]),
  sortBy: propTypes.string,
  onApplyFilter: propTypes.func
}