import React from 'react';
import { Button } from 'reactstrap';
import classNames from 'classnames';
import propTypes from 'prop-types';
import { Toolbars as ToolBarContainer } from '../../../components/Card/CardHeaderWithToolbars'

const Toolbars = props => (
  <ToolBarContainer>
    <Button
      title="Refresh"
      className="with-toolbars__toolbars__btn"
      size="sm"
      color="info"
      onClick={props.onRefreshList}>
      <i className="fa fa-refresh"></i>
    </Button>
    <Button
      title="Reset filter"
      className="with-toolbars__toolbars__btn"
      size="sm"
      color="warning"
      onClick={props.onResetFilter}>
      <i className="fa fa-ban"></i>
    </Button>
    <Button
      title="Search and Filter Data"
      size="sm"
      className={classNames("with-toolbars__toolbars__btn",{"btn-danger":props.isOpen})}
      onClick={props.onToggleFilter}>
      <i className={classNames("fa",{"fa-sliders":!props.isOpen, "fa-minus":props.isOpen})}></i>
    </Button>
  </ToolBarContainer>
)

export default Toolbars;

Toolbars.propTypes = {
  isOpen: propTypes.bool,
  onToggleModalBulkUpload: propTypes.func,
  onToggleFilter: propTypes.func,
  onResetFilter: propTypes.func,
  onRefreshList: propTypes.func
}
Toolbars.defaultProps = {
  isOpen: false
}