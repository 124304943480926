import React, { Component } from 'react';
import classNames from 'classnames';
import propTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import {
  Card,
  CardHeader,
  CardBody,
  Col,
  Alert,
  Form,
  FormGroup,
  Input,
  Label,
  Button,
  Tooltip,
  Badge
} from 'reactstrap';
import moment from 'moment-timezone';
import Loading from 'react-loading-animation';
import styled from 'styled-components';
import { errorMessage, hasError, _getValue, dateChange, dateValue, checkChange, textChange } from '../../../../utils/form';
import { sellRequestConstantStatus, sellRequestBoxConditionOptions, sellRequestStatusOptions } from '../../../../constants/kickavenue';
import {
  FieldRow,
  FieldColumn,
  FieldLabel,
  FieldValue
} from '../../../../styled/Fields';
import { USER_SELL_EXPIRY_DAYS } from '../../../../constants/kickavenue';
import { DATE_FORMAT_DB, DATE_TIMEZONE } from '../../../../config/date';
import { ModalDelete } from '../../../../components/Modals'
import ButtonLoading from '../../../../components/Button/ButtonLoading';
import FontAwesomeIcon from '../../../../components/Icons/FontAwesomeIcon';
import { Radio, RadioText, FormGroupRadio } from '../../../../components/Form/Radios';
import InputDatetime from '../../../../components/Form/InputDatetime/InputDatetime';
import { userSellDefaultConditions, userSellApparelConditions, userSellHandbagConditions, sneakersConditionOption, userSellLifestyleConditions, brandConditions, userSellSneakerConditions } from '../../../../utils/userSell';
import SelectRack from '../../../../components/Form/Select/Async/SelectRack';
import { STOCK_STATUS_OPTIONS } from '../../Components/RequestForm/RequestForm.data';
const ButtonWrapperRight = styled.div`
  display: flex;
  flex:1;
  flex-direction: column;
  justify-content: center;
  padding:0 1rem;
`
const ALERT_ERROR_COLOR = 'danger';
const ALERT_SUCCESS_COLOR = 'success';
const ALERT_ERROR_BOLD_MESSAGE = 'Ooops.';
const ALERT_SUCCESS_BOLD_MESSAGE = 'Success.';
const ALERT_SUCCESS_UPDATE_MESSAGE = 'Your sell request updated successfully';
class DetailCard extends Component {
  constructor(props){
    super(props);
    this.state = {
      isDeleted: false,
      _error: null,
      isModalDeleteOpen: false,
      isAlertOpen: false,
      alert: null,
      isSubmit: false,
      id: 0,
      product_variant_id: 0,
      quantity: 1,
      expirySellRequestTooltipOpen: false,
      listingConditions: [],
      sneakersConditionOpt: this._getSneakerConditionsOpt('sneakers'),
      boxConditionOpt: sellRequestBoxConditionOptions,
      disabled_box:false,
      rack_option:"CUSTOM",
    }
		moment.tz(DATE_TIMEZONE);
    this._handleTextChange = this._handleTextChange.bind(this);
    this._handleCheckedChange = this._handleCheckedChange.bind(this);
    this._onSubmit = this._onSubmit.bind(this);
    this._toggleAlert = this._toggleAlert.bind(this);
    this._onDeleteBtnClick = this._onDeleteBtnClick.bind(this);
    this._toggleModalDelete = this._toggleModalDelete.bind(this);
    this._onDeleteSubmit = this._onDeleteSubmit.bind(this);
    this._handlePreOrderCheckedChange = this._handlePreOrderCheckedChange.bind(this);
    this._handlePreVerifiedCheckedChange = this._handlePreVerifiedCheckedChange.bind(this);
    this._toggleExpiryToolTip = this._toggleExpiryToolTip.bind(this);
    this._handleGenerateExpiryClick = this._handleGenerateExpiryClick.bind(this);
    this._handleHolidayModeCheckedChange = this._handleHolidayModeCheckedChange.bind(this);
    this._handleSelectRack = this._handleSelectRack.bind(this);
    this._handleOnSelectRackOpen = this._handleOnSelectRackOpen.bind(this);
    this._handleConsignmentCheckedChange = this._handleConsignmentCheckedChange.bind(this);
    this._handleHypequarterDisplayCheckedChange = this._handleHypequarterDisplayCheckedChange.bind(this);
  }

  componentDidMount(){
    // setTimeout(() => {
    //   this.setState({
    //     expirySellRequestTooltipOpen: true
    //   }, () => setTimeout(this._toggleExpiryToolTip, 2000))
    // }, 1000);
  }

  _toggleExpiryToolTip(){
    this.setState({expirySellRequestTooltipOpen: !this.state.expirySellRequestTooltipOpen})
  }

  _getSneakerConditionsOpt = category => {
    return sneakersConditionOption
      .filter(item => item.categories.includes(category))
      .map(item => {
        let label = item.label;
        if(item.en && item .en[category]){
          label = item .en[category];
        }
        return { value: item.value, label }
      })
  }

  shouldComponentUpdate(nextProps){
    if(nextProps.userSetting.isSubmit===true){
      return false;
    }
    return true;
  }

  componentDidUpdate(prevProps) {
    let boxConditions=null;
    let sneakersConditions=null;
    const { sell_racks } = this.props.dataSellRequest.detail;
    const hide_box_info = this.props.productVariant && this.props.productVariant.hide_box_info;
    const parentBrand = this.props.brand && this.props.brand.ancestors.length? this.props.brand.ancestors[0].slug:  null;
    if(prevProps.isLoading !== this.props.isLoading && !this.props.isLoading){
      const {
        id,
        product_variant_id,
        asking_price,
        purchase_price,
        status,
        sneakers_condition,
        box_condition,
        pre_order,
        pre_verified,
        // missing_accessories,
        // yellowing,
        // display_item,
        // sneakers_defect,
        consignment_id,
        rack,
        is_seller_holiday,
        updated_at,
        note,
        quantity,
        expiry,
        categoryListing,
        stock_status,
        consignment,
        request_type,
        hypequarter_display
      } = this.props;
      if(categoryListing.toUpperCase()==='LIFESTYLES'){
        switch (parentBrand) {
          case 'bike':
            sneakersConditions=[{value:'BARU', label:'Brand New'}];
            boxConditions=[{value:'NO_BOX',label:'Missing Box'}];
            break;
          case 'games-consoles':
            boxConditions=[{value:'SEMPURNA',label:'Sempurna'},{value:'NO_BOX',label:'Missing Box'}];
            break;
          default:
            break;
        }
      }
      this.setState({
        id,
        product_variant_id,
        asking_price: parseFloat(asking_price),
        purchase_price: purchase_price? parseFloat(purchase_price): '',
        status,
        sneakers_condition,
        box_condition,
        pre_order,
        pre_verified,
        // missing_accessories,
        // yellowing,
        // display_item,
        // sneakers_defect,
        consignment_id,
        rack:sell_racks?sell_racks.rack:rack,
        rack_custom: rack,
        holiday_mode: is_seller_holiday,
        updated_at,
        note,
        quantity,
        expiry,
        disabled_box:hide_box_info,
        listingConditions: this._getSellConditions(),
        boxConditionOpt: boxConditions ?  boxConditions : sellRequestBoxConditionOptions,
        sneakersConditionOpt: sneakersConditions ? sneakersConditions : this._getSneakerConditionsOpt(categoryListing.toLowerCase()),
        stock_status,
        consignment,
        request_type,
        hypequarter_display
      })
    }
    if(prevProps.dataSellRequest.isSubmit!==this.props.dataSellRequest.isSubmit && !this.props.dataSellRequest.isSubmit){
      let { _error } = this.state;
      let payload = {};
      let color = ALERT_SUCCESS_COLOR, header = ALERT_SUCCESS_BOLD_MESSAGE, message = ALERT_SUCCESS_UPDATE_MESSAGE;
      if(this.props.dataSellRequest.error === null){
        if(this.state.isDeleted){
          const deleted = this.props.dataSellRequest.detail;
          if(deleted){
            this.props.history.replace('/sell_request');
          }
        }
        else{
          const {
            product_variant_id,
            asking_price,
            purchase_price,
            status,
            sneakers_condition,
            box_condition,
            pre_order,
            pre_verified,
            // missing_accessories,
            // yellowing,
            // display_item,
            // sneakers_defect,
            // serial_number,
            updated_at,
            note,
            quantity,
            expiry,
            rack,
            stock_status,
            consignment,
            request_type,
            hypequarter_display
          } = this.props;
          payload = {
            product_variant_id,
            asking_price: parseFloat(asking_price),
            purchase_price: purchase_price? parseFloat(purchase_price): '',
            status,
            sneakers_condition,
            box_condition,
            pre_order,
            pre_verified,
            // missing_accessories,
            // yellowing,
            // display_item,
            // sneakers_defect,
            // serial_number,
            // rack:sell_racks?sell_racks.rack:rack,
            rack_custom: rack,
            note,
            quantity,
            expiry,
            updated_at,
            listingConditions: this._getSellConditions(),
            disabled_box:hide_box_info,
            stock_status,
            consignment,
            request_type,
            hypequarter_display
          }
        }
      }
      else{
        const { error } = this.props.dataSellRequest;
        _error = error;
        color = ALERT_ERROR_COLOR;
        header = ALERT_ERROR_BOLD_MESSAGE;
        switch(error.status_code){
          case 422:
            message = 'Please fulfill your form.'; break;
          default:
            message = error.message;
        }
      }
      this.setState({
        ...payload,
        isSubmit: false,
        isDeleted: false,
        _error,
        isAlertOpen: true,
        alert:{
          color,
          header,
          message
        }
      })
    }
    if(!this.props.userSetting.isSubmit&&this.props.userSetting.isSubmit!==prevProps.userSetting.isSubmit){
      if(this.props.userSetting.error){
        this.setState({
          holiday_mode: !this.state.holiday_mode,
        })
      }
      else{
        if(this.props.userSetting.data && !this.props.userSetting.isSubmit 
          && this.props.userSetting.status_code===200){
          const { holiday_mode } = this.props.userSetting.data;
          this.setState({holiday_mode})
        }
      }
    }
    if(prevProps.rackDetail.isSubmit!==this.props.rackDetail.isSubmit && !this.props.rackDetail.isSubmit){
      const {error, data, status_code} = this.props.rackDetail;
      if(!error && data && this.props.dataSellRequest.detail.rack && status_code===200){
        // this.setState({
        //   rack:{...data}
        // })
      }
    }
  }

  _getSellConditions(){
    const {
      missing_accessories,
      yellowing,
      display_item,
      sneakers_defect,
      brand,
      categoryListing,
      defects
    } = this.props;
    let listingConditions = [];
    switch(categoryListing){
      case 'APPARELS': {
        listingConditions = userSellApparelConditions.map(item => {
          const getValue = defects.find(i => i.name === item.name.toUpperCase());
          return {
            ...item,
            value: getValue ? getValue.value: false
          }
        })
        break;
      }
      case 'HANDBAGS': {
        const tambahan = [{
          name: 'missing_accessories',
          value: missing_accessories === null? false: missing_accessories
        }];
        const allDefects = [
          ...tambahan,
          ...defects.map(item=>({...item, name: item.name.toLowerCase()}))
        ];
        listingConditions = userSellHandbagConditions.map(item => {
          const getValue = allDefects.find(i => i.name === item.name);
          return {
            ...item,
            value: getValue ? getValue.value: false
          }
        })
        break;
      }
      case 'LIFESTYLES': {
        const slug = brand && brand.ancestors.length? brand.ancestors[0].slug:  null;
        // const slug = null
        const selected = brandConditions.find(item => item.slug === slug);
        const tambahan = [{
          name: 'missing_accessories',
          value: missing_accessories === null? false: missing_accessories
        }, {
          name: 'display_item',
          value: display_item === null? false: display_item
        }];
        const allDefects = [
          ...tambahan,
          ...defects.map(item=>({...item, name: item.name.toLowerCase()}))
        ];
        listingConditions = userSellLifestyleConditions
        .filter(item => !selected || (selected.conditions.includes(item.name)))
        .map(item => {
          const getValue = allDefects.find(i => i.name === item.name);
          return {
            ...item,
            value: getValue ? getValue.value: false
          }
        })
        break;
      }
      default: {
        const myConditions = [{
          name: 'missing_accessories',
          value: missing_accessories === null? false: missing_accessories
        },{
          name: 'yellowing',
          value: yellowing === null? false: yellowing
        },{
          name: 'display_item',
          value: display_item === null? false: display_item
        },{
          name: 'sneakers_defect',
          value: sneakers_defect === null? false: sneakers_defect
        }];
        listingConditions = userSellDefaultConditions.map(item => {
          const getValue = myConditions.find(i => i.name === item.name);
          return {
            ...item,
            value: getValue ? getValue.value: false
          }
        })
        if(categoryListing === "SNEAKERS"){
          const missingAccessories = userSellSneakerConditions.map(item => {
            const getValue = defects.find(i => i.name.toLowerCase() === item.name.toLowerCase());
            return {
              ...item,
              value: getValue ? getValue.value: false,
              missingAccessories: true
            }
          })
          listingConditions = [ ...listingConditions, ...missingAccessories ]
        }
        break;
      }
    }
    return listingConditions;
  }

  _toggleAlert(){
    this.setState({
      isAlertOpen: false
    })
  }

  _renderAlert(){
    return this.state.alert !== null?
      <Alert
        color={this.state.alert.color}
        toggle={this._toggleAlert}
        isOpen={this.state.isAlertOpen}
      >
        <strong>{this.state.alert.header}</strong> &nbsp;
        {this.state.alert.message}
      </Alert>
      :null
  }

  _handleSelectRack(option){
    this.setState({rack : option});
  }

  _handleOnSelectRackOpen(){
    if(this.state.rack){
      if(this.state._recentSearchRack){
        this.refs.selectRack.loadSelectOptions(this.state._recentSearchRack)
      }
      else{
        this.refs.selectRack.loadSelectOptions(this.state.rack.name)
      }
    }
    else{
      this.refs.selectRack.loadSelectOptions('')
    }
  }


  _handleTextChange(e){
    const lastState = {...this.state};

    if(this.state.consignment){
      const isQuantity = e.target.name === "quantity";
      if(isQuantity){
        const isMoreThanOne = parseInt(e.target.value) > 1;
        if(isMoreThanOne){
          this.setState({
            ...lastState,
            quantity: 1
          });
          alert("You cannot input quantity more than 1");
          return;
        }
      }
    }
    
    this.setState({
      ...textChange(e, lastState)
    })
  }

  _handleDatePickerChange(value, name){
    const lastState = dateChange(value,name,this.state, DATE_FORMAT_DB);
    this.setState({...lastState});
  }

  _handleCheckedChange(e){
    const lastState = {...this.state};
    this.setState({
      ...checkChange(e, lastState)
    })
  }

  _handlePreOrderCheckedChange(e){
    const newState={...this.state};
    newState[e.target.name]=e.target.checked;
    if(e.target.checked){
      newState['pre_verified'] = !e.target.checked;
    }
    this.setState({ ...newState });
  }

  _handlePreVerifiedCheckedChange(e){
    const { pre_order } = {...this.state};
    const checked = pre_order===true ? !pre_order : e.target.checked;
    this.setState({ pre_verified: checked });
    if(!checked && !this.state.consignment){
      this.setState({hypequarter_display: false});
    }
  }

  _handleConsignmentCheckedChange(e){
    const { consignment } = {...this.state};
    const checked = consignment===true ? !consignment : e.target.checked;
    this.setState({ consignment: checked });
    if(!checked && !this.state.pre_verified){
      this.setState({hypequarter_display: false});
    }
  }

  _handleHypequarterDisplayCheckedChange(e){
    const { hypequarter_display } = {...this.state};
    const checked = hypequarter_display===true ? !hypequarter_display : e.target.checked;
    this.setState({ hypequarter_display: checked });
  }

  _handleHolidayModeCheckedChange(e){
    const { holiday_mode } = {...this.state};
    const { user } = this.props;
    const checked = holiday_mode===true ? !holiday_mode : e.target.checked;
    this.setState({ holiday_mode: checked }, 
      () => {
        this.props.onForceHolidayMode({
          'active' : checked,
          'user_id' : user.id
        });
      }
    );
  }

  _handleGenerateExpiryClick(){
    this.setState({
      expiry: moment()
      .add(USER_SELL_EXPIRY_DAYS, 'days')
      .endOf('day')
      .format(DATE_FORMAT_DB)
    })
  }

  _toggleModalDelete(){
    this.setState({
      isModalDeleteOpen: !this.state.isModalDeleteOpen
    })
  }

  _onDeleteBtnClick(){
    this._toggleModalDelete();
  }

  _onDeleteSubmit(){
    this.setState({
      isDeleted: true,
      isModalDeleteOpen: false
    },()=>{
      this.props.onDeleteSellRequest(this.state.id);
    })
  }

  _onSubmit(e){
    e.preventDefault();
    const { categoryListing } = this.props;
    const { listingConditions } = this.state;
    let payloadConditions = {};
    // search for missing_accessories key.
    const missing_accessories = listingConditions.find(item => item.name.toLowerCase() === 'missing_accessories');
    if(categoryListing === 'SNEAKERS'){
      payloadConditions = listingConditions.filter(item => !item.missingAccessories).reduce((obj, item)=>{
        obj[item.name] = item.value
        return { ...obj }
      }, Object.assign({}))
      const missingAccessoriesList = listingConditions.filter(item => item.missingAccessories).reduce((obj, item)=>{
        obj[item.name] = missing_accessories && missing_accessories.value? item.value: false
        return { ...obj }
      }, Object.assign({}))
      if(Object.keys(missingAccessoriesList).length){
        payloadConditions.missing_accessories_list = missingAccessoriesList
      }
    }
    // else if(categoryListing === 'APPARELS'){
    else{
      //ignore keys karena udah di user_sell table.
      const ignores = ['missing_accessories', 'display_item'];
      const _listConditions =  listingConditions.filter(item => !ignores.includes(item.name.toLowerCase()));
      // search for display_item key.
      const display_item = listingConditions.find(item => item.name.toLowerCase() === 'display_item');
      const inclusions = _listConditions.filter(item => item.inclusions).reduce((obj, item) =>{
        obj[item.name.toLowerCase()] = item.value
        return { ...obj }
      }, Object.assign({}));
      payloadConditions = {
        display_item: display_item? display_item.value: false,
        yellowing: false,
        missing_accessories: missing_accessories? missing_accessories.value: false,
        sneakers_defect: _listConditions.filter(item => item.value === true && !item.inclusions).length > 0,
        defects: _listConditions.filter(item => !item.inclusions).map(item => ({
          name: item.name.toLowerCase(),
          value: item.value
        }))
      }
      if(Object.keys(inclusions).length){
        payloadConditions.inclusions = inclusions;
      }
    }
    this.setState({
      isSubmit: true,
      _error: null,
      isAlertOpen: false,
      alert: null
    },()=>{
      const {
        product_variant_id,
        asking_price,
        purchase_price,
        status,
        quantity,
        box_condition,
        sneakers_condition,
        pre_verified,
        pre_order,
        // missing_accessories,
        // display_item,
        // yellowing,
        // sneakers_defect,
        // serial_number,
        rack,
        rack_option,
        rack_custom,
        note,
        expiry,
        disabled_box,
        stock_status,
        consignment,
        request_type,
        hypequarter_display
      } = this.state;
      const payload = {
        product_variant_id,
        asking_price,
        purchase_price: purchase_price === ''? null: purchase_price,
        status,
        quantity,
        box_condition: categoryListing === 'HANDBAGS' || disabled_box? 'SEMPURNA': box_condition,
        sneakers_condition,
        pre_verified: pre_verified === null? false:pre_verified,
        pre_order: pre_order === null? false:pre_order,
        // missing_accessories: missing_accessories === null? false:missing_accessories,
        // display_item: display_item === null? false:display_item,
        // yellowing: yellowing === null? false:yellowing,
        // sneakers_defect: sneakers_defect === null? false:sneakers_defect,
        // serial_number,
        // rack : rack_option === "CUSTOM"? rack_custom: rack,
        rack : rack && rack.name?rack.name:rack,
        note,
        expiry: expiry !== null && expiry.length>0? expiry: null,
        stock_status,
        consignment,
        request_type,
        hypequarter_display,
        ...payloadConditions,
      }
      this.props.onUpdateSellRequest(payload);
      const p = {sell_id:this.props.id};
      if(rack && rack.id)
        this.props.attachRack(rack.id, p);
      else{
        if(!rack && this.props.sell_racks){
          const rackId = this.props.sell_racks.rack.id;
          this.props.detachRack(rackId, p);
        }
      }
    })
  }

  _handleConditionsCheckedChange(e, index){
    const { listingConditions } = this.state;
    const item = listingConditions[index];
    const arr = listingConditions.filter(item => item.name !== e.target.name);
    arr.splice(index, 0, {
      ...item,
      value: e.target.checked
    })
    this.setState({
      listingConditions: arr
    });
  }

  render(){
    const defectCount = this.state.listingConditions.filter(condition => !condition.inclusions && !condition.missingAccessories).length;
    const missing = this.state.listingConditions.find(condition => condition.name.toLowerCase() === "missing_accessories" && condition.value)
    const missingCount = this.state.listingConditions.filter(condition => condition.missingAccessories).length
    return (
      <Card>
        <CardHeader>
          <i className="fa fa-align-justify"></i> {this.state.consignment ? "Consignment" : "Sell"} Request Detail
        </CardHeader>
        <CardBody>
          {
            this.props.isLoading?
            <Loading/>
            :
            <Form onSubmit={this._onSubmit}>
              {this._renderAlert()}
              <ModalDelete
                isOpen={this.state.isModalDeleteOpen}
                toggle={this._toggleModalDelete}
                onDelete={this._onDeleteSubmit}
              />
              <FieldRow>
                <FieldColumn className="col-xs-6 col-6">
                  <FieldLabel>ID</FieldLabel>
                  <FieldValue>
                    <span>{_getValue(this.props.id)}</span>
                  </FieldValue>
                </FieldColumn>
                <ButtonWrapperRight>
                  <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                    <ButtonLoading
                      type="button"
                      isLoading={this.state.isDeleted}
                      loadingMessage="Deleting..."
                      color="danger"
                      onClick={this._onDeleteBtnClick}
                    >
                      Delete
                    </ButtonLoading>
                    <Button onClick={this.props.onViewLogsToggle}>View Logs</Button>
                  </div>
                </ButtonWrapperRight>
              </FieldRow>
              <FieldRow>
                <FieldColumn className="col-xs-6 col-6">
                  <FieldLabel>Consignment ID</FieldLabel>
                    <FieldValue>
                      <span>{_getValue(this.props.consignment_id)}</span>
                    </FieldValue>
                </FieldColumn>
                <Col xs={12} md={6}>
                  <FormGroup className={classNames({'has-danger has-feedback':hasError('rack',this.state._error)})}>
                    <Label for="formRack" className="d-block">
                      <div className="d-flex" style={{justifyContent: 'space-between'}}>
                        <div>Rack</div>
                        {
                          // this.state.rack_option !== "CUSTOM" ?
                          // <div style={{marginLeft: "0.5rem"}}>
                          //   {/* <Badge>CUSTOM</Badge> */}
                          //   <span style={{marginLeft: "0.5rem", textDecoration: 'underline', cursor: "pointer", color: 'red', fontSize: '.75rem'}} onClick={() => this.setState({rack_option: "CUSTOM"})}>Make a new rack</span>
                          // </div>
                          // :
                          // <div style={{marginLeft: "0.5rem"}}>
                          //   {/* <Badge>SELECT</Badge> */}
                          //   <span style={{marginLeft: "0.5rem", textDecoration: 'underline', cursor: "pointer", color: 'red', fontSize: '.75rem'}} onClick={() => this.setState({rack_option: "SELECT"})}>Select existing rack</span>
                          // </div>
                        }
                      </div>
                    </Label>
                    {
                      // this.state.rack_option !== "CUSTOM"?
                      <SelectRack
                        defaultOptions
                        isAvailable
                        id="_formSelectRack"
                        ref="selectRack"
                        value={_getValue(this.state.rack)}
                        sellQty={this.state.quantity}
                        paramsApi={{ limit: 50 }}
                        placeholder="Type and select a rack..."
                        noResultsText="Cannot find rack."
                        loadOptions={this._getRackOptions}
                        onSelectSearch={(input)=>this.setState({_recentSearchRack: input})}
                        onSelectChange={this._handleSelectRack}
                        onSelectOpen={this._handleOnSelectRackOpen}
                      />
                      // :
                      // <Input
                      //   type="text"
                      //   id="_formCustomRack"
                      //   name="rack_custom"
                      //   placeholder="Rack example: RACK-01"
                      //   value={_getValue(this.state.rack_custom)}
                      //   onChange={this._handleTextChange}
                      // />
                    }
                    {errorMessage('rack',this.state._error)}
                  </FormGroup>
                </Col>
              </FieldRow>
              <FieldRow>
                <FieldColumn className="col-xs-6 col-6">
                  <FieldLabel>Email</FieldLabel>
                  <FieldValue>
                    <span>{_getValue(this.props.user.email)}</span>
                  </FieldValue>
                </FieldColumn>
                <Col xs={6} md={6}>
                  <FormGroup className={classNames({'has-danger has-feedback':hasError('holiday_mode', this.state._error)})}>
                    <label className="switch switch-text switch-success-outline-alt">
                      <input
                        type="checkbox"
                        className="switch-input"
                        name="holiday_mode"
                        value="true" checked={_getValue(this.state.holiday_mode)}
                        onChange={this._handleHolidayModeCheckedChange}
                      />
                      <span className="switch-label" data-on="On" data-off="Off"></span>
                      <span className="switch-handle"></span>
                    </label>
                    <Label style={{marginLeft:15, marginTop:3}}>Holiday-mode</Label>
                    {errorMessage('holiday_mode', this.state._error)}
                  </FormGroup>
                </Col>
              </FieldRow>
              <FieldRow>
                <FieldColumn className="col-6">
                  <FieldLabel>Country</FieldLabel>
                  <FieldValue>
                    <span>{_getValue(this.props.origin_country)}</span>
                  </FieldValue>
                </FieldColumn>
                <FieldColumn className="col-6">
                  <FieldLabel>Currency</FieldLabel>
                  <FieldValue>
                    <span>{_getValue(this.props.currency)}</span>
                  </FieldValue>
                </FieldColumn>
              </FieldRow>
              <FieldRow>
                <FieldColumn className="col-6">
                  <FieldLabel>Request Type</FieldLabel>
                  <FieldValue>
                    <span>{_getValue(this.props.request_type) === "CONSIGNMENT_REQUEST" ? "Consignment Request" : "Sell Request"}</span>
                  </FieldValue>
                </FieldColumn>
              </FieldRow>
              <FieldRow>
                <Col xs={12} md={6}>
                  <FormGroup className={classNames({'has-danger has-feedback':hasError('asking_price',this.state._error)})}>
                    <Label for="formSellAskingPrice">Asking Price*</Label>
                    <Input
                      id="formSellAskingPrice"
                      name="asking_price"
                      className="text-right"
                      placeholder="Enter asking price, example: 1100000"
                      value={_getValue(this.state.asking_price)}
                      onChange={this._handleTextChange}
                    />
                    {errorMessage('asking_price',this.state._error)}
                  </FormGroup>
                </Col>
                <Col xs={12} md={6}>
                  <FormGroup className={classNames({'has-danger has-feedback':hasError('purchase_price',this.state._error)})}>
                    <Label for="formSellPurchasePrice">Purchase Price*</Label>
                    <Input
                      id="formSellPurchasePrice"
                      name="purchase_price"
                      className="text-right"
                      placeholder="Enter purchase price, example: 1100000"
                      value={_getValue(this.state.purchase_price)}
                      onChange={this._handleTextChange}
                    />
                    {errorMessage('purchase_price',this.state._error)}
                  </FormGroup>
                </Col>
                <Col xs={12} md={6}>
                  <FormGroup className={classNames({'has-danger has-feedback':hasError('status',this.state._error)})}>
                    <Label for="formSellStatus">Status</Label>
                    <Input
                      id="formSellStatus"
                      type="select"
                      name="status"
                      value={_getValue(this.state.status)}
                      onChange={this._handleTextChange}
                      disabled={sellRequestConstantStatus.includes(this.props.status)}
                    >
                      {
                        sellRequestStatusOptions.map((status, index)=><option value={status.value} key={index}>{status.label}</option>)
                      }
                    </Input>
                    {errorMessage('status',this.state._error)}
                  </FormGroup>
                </Col>
                <Col xs={12} md={6}>
                  {
                    this.state.consignment && 
                    <FormGroup className={classNames({'has-danger has-feedback':hasError('stock_status',this.state._error)})}>
                      <Label for="formStockStatus">Stock Label</Label>
                      <Input
                        id="formStockStatus"
                        type="select"
                        name="stock_status"
                        value={_getValue(this.state.stock_status)}
                        onChange={this._handleTextChange}
                      >
                        {
                          STOCK_STATUS_OPTIONS.map((status)=>{
                            return <option value={status.value} key={`status-option-${status}`}>{status.label}</option>
                          })
                        }
                      </Input>
                      {errorMessage('stock_status',this.state._error)}
                    </FormGroup>
                  }
                </Col>
                <Col xs={12} md={6}>
                  <FormGroup className={classNames({'has-danger has-feedback':hasError('sneakers_condition',this.state._error)})}>
                    <Label for="formSellSneakerCondition">Listing Condition</Label>
                    <Input
                      id="formSellSneakerCondition"
                      type="select"
                      name="sneakers_condition"
                      value={_getValue(this.state.sneakers_condition)}
                      onChange={this._handleTextChange}
                    >
                      {this.state.sneakersConditionOpt.map((item,index)=> <option value={item.value} key={index}>{item.label}</option>)}
                    </Input>
                    {errorMessage('sneakers_condition',this.state._error)}
                  </FormGroup>
                </Col>
                <Col xs={12} md={6}>
                  <FormGroup className={classNames({'has-danger has-feedback':hasError('box_condition',this.state._error)})}>
                    <Label for="formSellBoxCondition">
                      Box Condition
                      {
                        this.props.categoryListing === 'HANDBAGS' || this.state.disabled_box?
                          <span style={{fontSize: '.65rem', marginLeft: '5px'}}>(disabled)</span>: null
                      }
                    </Label>
                    {
                      this.props.categoryListing !== 'HANDBAGS' && !this.state.disabled_box?(
                        <Input
                          id="formSellBoxCondition"
                          type="select"
                          name="box_condition"
                          value={_getValue(this.state.box_condition)}
                          onChange={this._handleTextChange}
                        >
                          {
                            this.state.boxConditionOpt.map((status, index)=><option value={status.value} key={index}>{status.label}</option>)
                          }
                        </Input>
                      ): <p>-</p>
                    }
                    {errorMessage('box_condition',this.state._error)}
                  </FormGroup>
                </Col>
                <Col xs={12} md={6}>
                  <FormGroup className={classNames({'has-danger has-feedback':hasError('quantity',this.state._error)})}>
                    <Label for="formSellQuantity">Quantity*</Label>
                    <Input
                      id="formSellQuantity"
                      name="quantity"
                      type="number"
                      placeholder="Enter quantity"
                      value={_getValue(this.state.quantity)}
                      onChange={this._handleTextChange}
                    />
                    {errorMessage('quantity',this.state._error)}
                  </FormGroup>
                </Col>
                <Col xs={12} md={6}>
                  <FormGroup className={classNames({'has-danger has-feedback':hasError('expiry', this.state._errorForm)})}>
                    <Label for="formExpiryDate">
                      Expiry Date
                      <FontAwesomeIcon style={{margin: '0 .75rem 0 .5rem', cursor: 'pointer'}} iconType="question-circle" id="expirySellRequestToolTip"/>
                      <Tooltip placement="right" isOpen={this.state.expirySellRequestTooltipOpen} target="expirySellRequestToolTip" toggle={this._toggleExpiryToolTip}>
                        Kosongkan <i>Expiry</i> khusus sell request yang diinput melalui CMS atau khusus seller seller yang telah bekerja sama dengan <strong>KickAvenue</strong>.
                      </Tooltip>
                    </Label>
                    <InputDatetime
                      inputProps={{placeholder:'Pick a Expiry Date'}}
                      value={dateValue('expiry', this.state)}
                      onChange={(date)=>this._handleDatePickerChange(date,'expiry')}
                      timeFormat="HH:mm"
                    />
                    <div>
                      <Button
                        onClick={()=>this.setState({expiry: null})}
                        type="button"
                        style={{padding: 0, fontSize:'.75rem', textDecoration:'underline'}}
                        className="btn-link"
                        size="sm"
                      >
                        Kosongkan
                      </Button>
                      <Button
                        onClick={this._handleGenerateExpiryClick}
                        type="button"
                        style={{padding: 0, fontSize:'.75rem', textDecoration:'underline', marginLeft: ".5rem"}}
                        className="btn-link"
                        size="sm"
                      >
                        Generate Expiry
                      </Button>
                    </div>
                    {errorMessage('expiry', this.state._errorForm)}
                  </FormGroup>
                </Col>
              </FieldRow>
                
              {/* <FieldRow>
                <Col xs={12} md={6}>
                  <FormGroup className={classNames({'has-danger has-feedback':hasError('serial_number',this.state._error)})}>
                    <Label for="formSellSerialNumber">Serial Number</Label>
                    <Input
                      id="formSellSerialNumber"
                      name="serial_number"
                      type="text"
                      placeholder="Enter serial number"
                      value={_getValue(this.state.serial_number)}
                      onChange={this._handleTextChange}
                    />
                    {errorMessage('serial_number',this.state._error)}
                  </FormGroup>
                </Col>
              </FieldRow> */}
              <FieldRow>
                <Col xs={12} md={6} className="d-flex align-items-center">
                  <FormGroupRadio className={classNames({'has-danger has-feedback':hasError('pre_verified',this.state._error)})}>
                    <Radio
                      type="checkbox"
                      value={true}
                      checked={_getValue(this.state.pre_verified)}
                      name="pre_verified"
                      dataOnText="Yes"
                      dataOffText="No"
                      onChange={this._handlePreVerifiedCheckedChange}
                    />
                    <RadioText text="Pre-verified"/>
                    {errorMessage('pre_verified',this.state._error)}
                  </FormGroupRadio>
                </Col>
                <Col xs={12} md={6} className="d-flex align-items-center">
                  <FormGroupRadio className={classNames({'has-danger has-feedback':hasError('pre_order',this.state._error)})}>
                    <Radio
                      type="checkbox"
                      value={true}
                      checked={_getValue(this.state.pre_order)}
                      name="pre_order"
                      dataOnText="Yes"
                      dataOffText="No"
                      onChange={this._handlePreOrderCheckedChange}
                    />
                    <RadioText text="Pre-order"/>
                    {errorMessage('pre_order',this.state._error)}
                  </FormGroupRadio>
                </Col>
                <Col xs={12} md={6} className="d-flex align-items-center">
                  <FormGroupRadio className={classNames({'has-danger has-feedback':hasError('consignment',this.state._error)})}>
                    <Radio
                      type="checkbox"
                      value={true}
                      checked={_getValue(this.state.consignment)}
                      name="consignment"
                      dataOnText="Yes"
                      dataOffText="No"
                      onChange={this._handleConsignmentCheckedChange}
                      disabled={true}
                    />
                    <RadioText text="Consignment"/>
                    {errorMessage('consignment',this.state._error)}
                  </FormGroupRadio>
                </Col>
                <Col xs={12} md={6} className="d-flex align-items-center">
                  <FormGroupRadio className={classNames({'has-danger has-feedback':hasError('hypequarter_display',this.state._error)})}>
                    <Radio
                      type="checkbox"
                      value={true}
                      checked={_getValue(this.state.hypequarter_display)}
                      name="hypequarter_display"
                      dataOnText="Yes"
                      dataOffText="No"
                      onChange={this._handleHypequarterDisplayCheckedChange}
                      disabled={!this.state.consignment && !this.state.pre_verified}
                    />
                    <RadioText text="Hypequarter Display"/>
                    {errorMessage('hypequarter_display',this.state._error)}
                  </FormGroupRadio>
                </Col>
              </FieldRow>
              {
                defectCount?(
                  <FieldRow>
                    <Col xs={12}><p>Defects:</p></Col>
                    {
                      this.state.listingConditions.filter(condition => !condition.inclusions && !condition.missingAccessories).map((condition, index) => (
                        <Col xs={12} md={6} key={index} className="d-flex align-items-center">
                          <FormGroupRadio>
                            <Radio
                              type="checkbox"
                              name={condition.name}
                              value={true}
                              checked={condition.value}
                              dataOnText="Yes"
                              dataOffText="No"
                              onChange={e => this._handleConditionsCheckedChange(e, index)}
                            />
                            <RadioText text={condition.en}/>
                          </FormGroupRadio>
                        </Col>
                      ))
                    }
                  </FieldRow>
                ): null
              }
              {
                missing && missingCount?(
                  <FieldRow>
                    <Col xs={12}><p>Missing Accessories:</p></Col>
                    {
                      this.state.listingConditions.filter(condition => condition.missingAccessories).map((condition, index) => (
                        <Col xs={12} md={6} key={index} className="d-flex align-items-center">
                          <FormGroupRadio>
                            <Radio
                              type="checkbox"
                              name={condition.name}
                              value={true}
                              checked={condition.value}
                              dataOnText="Yes"
                              dataOffText="No"
                              onChange={e => this._handleConditionsCheckedChange(e, defectCount + index)}
                            />
                            <RadioText text={condition.en}/>
                          </FormGroupRadio>
                        </Col>
                      ))
                    }
                  </FieldRow>
                ): null
              }
              {
                this.state.listingConditions.filter(condition => condition.inclusions).length?(
                  <FieldRow>
                    <Col xs={12}><p>Inclusions:</p></Col>
                    {
                      this.state.listingConditions.filter(condition => condition.inclusions).map((condition, index) => (
                        <Col xs={12} md={6} key={index} className="d-flex align-items-center">
                          <FormGroupRadio>
                            <Radio
                              type="checkbox"
                              name={condition.name}
                              value={true}
                              checked={condition.value}
                              dataOnText="Yes"
                              dataOffText="No"
                              onChange={e => this._handleConditionsCheckedChange(e, defectCount + missingCount + index)}
                            />
                            <RadioText text={condition.en}/>
                          </FormGroupRadio>
                        </Col>
                      ))
                    }
                  </FieldRow>
                ): null
              }
              <FieldRow>
                <Col xs={12} md={6}>
                  <FormGroup className={classNames({'has-danger has-feedback':hasError('note',this.state._error)})}>
                    <Label for="formSellNote">Note</Label>
                    <Input
                      id="formSellNote"
                      type="textarea"
                      name="note"
                      placeholder="Enter your note"
                      value={_getValue(this.state.note)}
                      onChange={this._handleTextChange}
                    />
                    {errorMessage('note',this.state._error)}
                  </FormGroup>
                </Col>
              </FieldRow>
              <FieldRow>
                <FieldColumn className="col-xs-12 col-md-6">
                  <FieldLabel>Last Updated</FieldLabel>
                  <FieldValue>
                    <span>{_getValue(this.state.updated_at)}</span>
                  </FieldValue>
                </FieldColumn>
              </FieldRow>
              <FieldRow>
                <FieldColumn className="col-xs-12 col-md-6">
                  <ButtonLoading
                    isLoading={this.state.isSubmit}
                    loadingMessage="Updating..."
                    color="primary"
                  >
                    Update
                  </ButtonLoading>
                </FieldColumn>
              </FieldRow>
            </Form>
          }
        </CardBody>
      </Card>
    )
  }
}

export default withRouter(DetailCard);

DetailCard.propTypes = {
  isLoading: propTypes.bool,
  id: propTypes.number.isRequired,
  user: propTypes.object,
  userSetting: propTypes.object,
  productVariant: propTypes.object,
  dataSellRequest: propTypes.shape({
    detail: propTypes.object,
    isSubmit: propTypes.bool,
    isFetch: propTypes.bool,
    error: propTypes.object,
  }),
  onUpdateSellRequest: propTypes.func.isRequired,
  onForceHolidayMode: propTypes.func.isRequired,
  onDeleteSellRequest: propTypes.func.isRequired
}

DetailCard.defaultProps = {
  id: 0,
  isLoading: false
}
