import React from 'react';
import { connect } from 'react-redux';
import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  Label,
  Input,
  FormGroup,
  // Alert
} from 'reactstrap';
import propTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment-timezone';
import RichTextEditor from 'react-rte';
import { toolbarConfig, parseRteValue } from '../../config/rte';
import { addErrorMessage, errorMessage, hasError, textValue } from '../../utils/form';
import { isRequired } from '../../helpers/regex';
import { getRaffleById, saveRaffle, getRaffleGroups } from '../../actions/raffleAction';
import { ButtonLoading } from '../../components/Button';
import { Radio, RadioText, FormGroupRadio } from '../../components/Form/Radios';
import InputDatetime from '../../components/Form/InputDatetime/InputDatetime';
import CardLoadingPlaceholder from '../../components/Card/CardLoadingPlaceholder';

const isEmptyRteValue = rteHtml => {
  return rteHtml === '<p><br></p>'
}

class ModalUpdateRaffle extends React.Component{
  constructor(props){
    super(props)
    moment.tz('Asia/Jakarta');
    this.state = {
      form: {
        name: '',
        group_name: '',
        note: '',
        termsRte: parseRteValue(null),
        descriptionRte: parseRteValue(null),
        description:'',
        instructionsRte: parseRteValue(null),
        instructions: '',
        terms: '',
        active: false,
        started_at: '',
        expiry:  '',
        golden: false
      },
      formSubmit: false,
      error: null
    }
  }
  componentDidMount(){
    this.props.getRaffleGroups()
  }

  componentDidUpdate(prevProps){
    if(prevProps.isOpen !== this.props.isOpen && this.props.isOpen){
      const { id } = this.props;
      if(id){
        this.props.getRaffleById(id)
        this.setState({
          error: null,
          formSubmit: false
        })
      }
      else{
        alert("Please provide id!")
      }
    }
    if(this.props.isOpen){
      if(prevProps.dataRaffle.isFetch && !this.props.dataRaffle.isFetch){
        const { error, status_code } = this.props.dataRaffle;
        if(error){
          alert("Error! "+ error.status_code);
          return;
        }
        if(status_code === 200){
          this.setState({
            ...this._bindRafflePropsToState(),
            error: null
          })
        }
      }
      if(prevProps.dataRaffle.isSubmit && !this.props.dataRaffle.isSubmit){
        const { data, error, status_code, message } = this.props.dataRaffle;
        if(error){
          this.setState({
            formSubmit: false,
            error: {
              ...error
            }
            // events: null,
            // _alertSuccessOpen: false,
            // _alertErrorOpen: true,
            // _errorMessage: this.translateMessage(error.status_code, error.message)
          })
          return;
        }
        if(status_code === 200){
          const { form: dataForm } = this._bindRafflePropsToState(data);
          this.setState({
            form: {
              ...dataForm
            },
            formSubmit: false,
            error: null
          }, () => this._handleToggle())
        }
      }
    }
  }

  _bindRafflePropsToState = (data = null) => {
    const { data: dataProps } = this.props.dataRaffle;
    const dataApi = data? data: dataProps;
    const startAtParsed = moment(dataApi.started_at);
    const endedAtParsed = moment(dataApi.expiry);
    const termsRte = parseRteValue(dataApi.terms);
    const descriptionRte = parseRteValue(dataApi.description);
    const instructionsRte = parseRteValue(dataApi.instructions);
    const body = {
      name: dataApi.name,
      group_name: dataApi.group_name,
      note: dataApi.note,
      termsRte,
      descriptionRte,
      instructionsRte,
      terms: dataApi.terms,
      description: dataApi.description,
      instructions: dataApi.instructions,
      active: dataApi.active,
      started_at: startAtParsed.isValid()? startAtParsed: '',
      expiry: endedAtParsed.isValid()? endedAtParsed: '',
      golden: dataApi.golden,
      raffle_group_id: dataApi.raffle_group_id? dataApi.raffle_group_id: (dataApi.golden? 'gold': '')
    }
    return {
      form:{
        ...this.state.form,
        ...body
      }
    }
  }

  _handleChange = ({ target: { name, value, checked, type } }) => {
    this.setState({
      form:{
        ...this.state.form,
        [name]: type === 'checkbox'? checked: value
      }
    })
  }

  _validateForm = () => {
    const { form: dataForm } = this.state;
    let errors={};
    if(!isRequired(dataForm.name)){
      errors['name'] = addErrorMessage(errors, 'name', 'The name field is required.');
    }
    if(!moment.isMoment(dataForm.expiry) && dataForm.expiry !== ''){
      errors['expiry'] = addErrorMessage(errors, 'expiry', 'Invalid date.');
    }
    if(!moment.isMoment(dataForm.started_at) && dataForm.started_at !== ''){
      errors['started_at'] = addErrorMessage(errors, 'started_at', 'Invalid date.');
    }
    if(dataForm.active && dataForm.expiry === ''){
      errors['expiry'] = addErrorMessage(errors, 'expiry', 'Please select an expiry date.');
    }
    if(moment.isMoment(dataForm.started_at) && moment.isMoment(dataForm.expiry)){
      // console.log("Validate: ", dataForm.started_at.isBefore(dataForm.expiry))
      if(!dataForm.started_at.isBefore(dataForm.expiry)){
        errors['started_at'] = addErrorMessage(errors, 'started_at', 'Invalid date. Started date < expiry date.');
      }
    }
    let errorState = null;
    if(Object.keys(errors).length){
      errorState = {
        errors: { ...errors },
        status_code: 422,
        message: 'Please full fill form'
      }
      this.setState({
        error: errorState? {...errorState}: null,
        // errorMessage: errorState? errorState.message: null,
        // alertErrorOpen: true,
        // alertSuccessOpen: false,
        // errorMessage: 'Please full fill form'
      })
    }
    // console.log("Error: ", errors);
    return Object.keys(errors).length>0;
  }

  _onSubmit = e => {
    e.preventDefault();
    if(this.state.formSubmit) return;
    if(!this._validateForm()){
      this._submitData()
    }
  }

  _submitData = async() => {
    const { data } = this.props.dataRaffle;
    const { id } = this.props;
    const { form: dataForm } = this.state;
    let expiry = dataForm.expiry === ''? null:(
      moment.isMoment(dataForm.expiry)? dataForm.expiry.format("YYYY-MM-DD HH:mm:00"):
      dataForm.expiry);

    //prevent error expiry required and active == false
    if(!dataForm.active && expiry === null){
      expiry = data.expiry? data.expiry: data.updated_at;
    }

    const payloads = {
      id,
      name: dataForm.name,
      group_name: dataForm.group_name,
      promo_code: data.promo_code,
      voucher_id: data.voucher_id,
      img_url: data.img_url,
      note: dataForm.note,
      terms: isEmptyRteValue(dataForm.terms)?null: dataForm.terms,
      description: isEmptyRteValue(dataForm.description)?null: dataForm.description,
      instructions: isEmptyRteValue(dataForm.instructions)?null: dataForm.instructions,
      active: dataForm.active,
      started_at: dataForm.started_at === ''? null:(
        moment.isMoment(dataForm.started_at)? dataForm.started_at.format("YYYY-MM-DD HH:mm:00"):
        dataForm.started_at),
      expiry,
      golden: dataForm.golden,
      group_id: dataForm.raffle_group_id === '' || dataForm.raffle_group_id === 'gold'? null: Number(dataForm.raffle_group_id)
    }
    this.props.saveRaffle(payloads)
  }

  _handleDatePickerChange = (date, name) => {
    this.setState({
      form:{
        ...this.state.form,
        [name]: date
      }
    })
  }

  _handleChangeEditor(value, name) {
    const { form } = this.state;
    let newState = {};
    switch (name) {
      case "description":
        newState = {descriptionRte: value, description:value.toString("html")}
        break;
      case "terms" :
        newState = {termsRte: value, terms:value.toString("html")}
        break;
      case "instructions" :
        newState = {instructionsRte: value, instructions:value.toString("html")}
        break;
      default:
        break;
    }
    this.setState({ form:{...form, ...newState}})
  }

  _handleAdjustedDate = (e, fieldName, type) => {
    e.preventDefault();
    const { form } = this.state;
    const dateValue = form[fieldName];
    if(dateValue && dateValue !== '' && moment.isMoment(dateValue)){
      this.setState({
        form:{
          ...form,
          [fieldName]: type === 'start'? moment(dateValue).startOf('day'): moment(dateValue).endOf('day')
        }
      })
    }
  }

  _handleToggle = () => {
    if(this.state.formSubmit) return;
    this.props.onToggle()
  }

  render(){
    return(
      <Modal size="md" isOpen={this.props.isOpen} toggle={this._handleToggle}>
        <CardLoadingPlaceholder
          isVisible={this.props.dataRaffle.isFetch}
          loadingText="Fetching data..."
        />
        <ModalHeader toggle={this._handleToggle}>
          Update Raffle
        </ModalHeader>
        <Form onSubmit={this._onSubmit}>
          <ModalBody>
            <FormGroup className={classNames({'has-danger has-feedback': hasError('name', this.state.error)})}>
              <Label>Raffle Name</Label>
              <Input
                type="text"
                name="name"
                value={textValue('name', this.state.form)}
                onChange={this._handleChange}
              />
              { errorMessage('name', this.state.error) }
            </FormGroup>
            <Row>
              <Col xs={12} sm={6}>
                <FormGroup
                  className={classNames({ 'has-danger has-feedback': hasError('group_id', this.state.error) })}
                >
                  <Label>Group - optional</Label>
                  <Input
                    type="select"
                    name="raffle_group_id"
                    value={textValue('raffle_group_id', this.state.form)}
                    onChange={(e) =>{
                      this.setState({
                        form: {
                          ...this.state.form,
                          golden: e.target.value === 'gold'? true: false,
                          raffle_group_id: e.target.value
                        }
                      })
                    }}
                  >
                    <option value="">Default</option>
                    <option value="gold">Gold</option>
                    {this.props.dataRaffleGroup.data && this.props.dataRaffleGroup.data.data?(
                      this.props.dataRaffleGroup.data.data.map(item => (
                        <option key={item.id} value={item.id}>{item.name}</option>
                      ))
                    ):null}
                  </Input>
                  { errorMessage('group_id', this.state.error) }
                </FormGroup>
              </Col>
            </Row>
            <FormGroup className={classNames({'has-danger has-feedback': hasError('group_name', this.state.error)})}>
              <Label>Group Header</Label>
              <Input
                type="text"
                name="group_name"
                value={textValue('group_name', this.state.form)}
                onChange={this._handleChange}
              />
              { errorMessage('group_name', this.state.error) }
            </FormGroup>
            <Row>
              <Col xs={12} md={6}>
                <FormGroup
                  className={classNames({ 'has-danger has-feedback': hasError('started_at', this.state.error) })}
                >
                  <Label>Started At</Label>
                  <InputDatetime
                    readOnly={this.state.isReadOnly}
                    inputProps={{placeholder:'Pick a Start Date', name: "started_at", autoComplete: "off"}}
                    value={textValue('started_at', this.state.form)}
                    onChange={ date => this._handleDatePickerChange(date, 'started_at') }
                    timeFormat="HH:mm"
                  />
                  <p className="mb-0">
                    <a href="#" onClick={(e) => this._handleAdjustedDate(e, 'started_at', 'start')} style={{fontSize: '.75rem', textDecoration: 'underline'}} className="mr-3">start of day</a>
                    <a href="#" onClick={(e) => this._handleAdjustedDate(e, 'started_at', 'end')} style={{fontSize: '.75rem', textDecoration: 'underline'}} className="mr-3">end of day</a>
                  </p>
                  { errorMessage('started_at', this.state.error) }
                </FormGroup>
              </Col>
              <Col xs={12} md={6}>
                <FormGroup
                  className={classNames({ 'has-danger has-feedback': hasError('expiry', this.state.error) })}
                >
                  <Label>Ended At</Label>
                  <InputDatetime
                    inputProps={{placeholder:'Pick an Expiry', name: "expiry", autoComplete: "off", readOnly: this.state.isReadOnly}}
                    value={textValue('expiry', this.state.form)}
                    onChange={ date => this._handleDatePickerChange(date, 'expiry') }
                    timeFormat="HH:mm"
                  />
                  <p className="mb-0">
                    <a href="#" onClick={(e) => this._handleAdjustedDate(e, 'expiry', 'start')} style={{fontSize: '.75rem', textDecoration: 'underline'}} className="mr-3">start of day</a>
                    <a href="#" onClick={(e) => this._handleAdjustedDate(e, 'expiry', 'end')} style={{fontSize: '.75rem', textDecoration: 'underline'}} className="mr-3">end of day</a>
                  </p>
                  { errorMessage('expiry', this.state.error) }
                </FormGroup>
              </Col>
            </Row>
            <Row>
              {/* <Col xs={12} md={6}>
                <FormGroupRadio
                  className={classNames({ 'has-danger has-feedback': hasError('golden', this.state.error) })}
                >
                  <Radio
                    type="checkbox"
                    name="golden"
                    value={true}
                    checked={textValue('golden', this.state.form)}
                    dataOnText="Yes"
                    dataOffText="No"
                    onChange={this._handleChange}
                  />
                  <RadioText text="Set to Golden"/>
                  { errorMessage('golden', this.state.error) }
                </FormGroupRadio>
              </Col> */}
              <Col xs={12} md={6}>
                <FormGroupRadio
                  className={classNames({ 'has-danger has-feedback': hasError('active', this.state.error) })}
                >
                  <Radio
                    type="checkbox"
                    name="active"
                    value={true}
                    checked={textValue('active', this.state.form)}
                    dataOnText="Yes"
                    dataOffText="No"
                    onChange={this._handleChange}
                  />
                  <RadioText text="Set to Active"/>
                  { errorMessage('active', this.state.error) }
                </FormGroupRadio>
              </Col>
            </Row>
            <FormGroup
              className={classNames({ 'has-danger has-feedback': hasError('description', this.state.error) })}
            >
              <Label>Description - optional</Label>
              <RichTextEditor
                value={this.state.form.descriptionRte}
                onChange={(value) => this._handleChangeEditor(value, "description")}
                toolbarConfig={toolbarConfig}
              />
              { errorMessage('description', this.state.error) }
            </FormGroup>
            <FormGroup
              className={classNames({ 'has-danger has-feedback': hasError('terms', this.state.error) })}
            >
              <Label>Terms - optional</Label>
              <RichTextEditor
                value={this.state.form.termsRte}
                onChange={(value) => this._handleChangeEditor(value, "terms")}
                toolbarConfig={toolbarConfig}
              />
              { errorMessage('terms', this.state.error) }
            </FormGroup>
            <FormGroup
              className={classNames({ 'has-danger has-feedback': hasError('instructions', this.state.error) })}
            >
              <Label>Instructions - optional</Label>
              <RichTextEditor
                value={this.state.form.instructionsRte}
                onChange={(value) => this._handleChangeEditor(value, "instructions")}
                toolbarConfig={toolbarConfig}
              />
              { errorMessage('instructions', this.state.error) }
            </FormGroup>
            <FormGroup
              className={classNames({ 'has-danger has-feedback': hasError('note', this.state.error) })}
            >
              <Label>Note - optional</Label>
              <Input
                placeholder="Enter a note"
                name="note"
                type="textarea"
                value={textValue('note', this.state.form)}
                onChange={this._handleChange}
              />
              { errorMessage('note', this.state.error) }
            </FormGroup>
            {this.state.error?(
              <p className="text-danger mb-0">
                ERROR: {this.state.error.message}
              </p>
            ): null}
          </ModalBody>
          <ModalFooter>
            <ButtonLoading
              color={this.state.error? "danger": "primary"}
              disabled={this.state.formSubmit}
              isLoading={this.state.formSubmit}
              // isLoading={this.state.formSubmit && this.state._submitFormStatus === SUBMIT_SLIDERFORM_STATUS}
              loadingMessage="Submitting..."
            >
              Submit {this.state.error? <i className="fa fa-exclamation-circle"></i>: null}
            </ButtonLoading>
          </ModalFooter>
        </Form>
      </Modal>
    )
  }
}

const mapStateToProps=({ raffle })=>({
  dataRaffle: raffle.details,
  dataRaffleGroup: raffle.raffleGroups
})
const mapDispatchToProps=(dispatch)=>({
  saveRaffle: (payloads) => dispatch(saveRaffle(payloads)),
  getRaffleById: id => dispatch(getRaffleById(id)),
  getRaffleGroups: q => dispatch(getRaffleGroups(q))
})
export default connect(mapStateToProps, mapDispatchToProps)(ModalUpdateRaffle);

ModalUpdateRaffle.propTypes = {
  id: propTypes.number,
  isOpen: propTypes.bool
}

ModalUpdateRaffle.defaultProps = {
  isOpen: false,
}