import React, { useState } from "react";
import { Tooltip as RCTooltip } from "reactstrap";
import propTypes from "prop-types";

const Tooltip = (props) => {
    const { children, ...otherProps } = props;
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);

    if (!otherProps.target) {
        return null;
    }

    return (
        <RCTooltip isOpen={isOpen} toggle={toggle} {...otherProps}>
            {children}
        </RCTooltip>
    );
};

Tooltip.propTypes = {
    target: propTypes.string.isRequired,
};

export default Tooltip;
