import mime from 'mime-types';
import {
	BRAND_ROUTE_API,
	GET_LIST_BRAND,
	MOVE_POSITION_BRAND,
	FETCHING_BRAND,
	GET_DETAIL_BRAND,
	UPDATE_BRAND,
	BRAND_ERROR,
	ADD_BRAND,
	NEW_BRAND,
	DELETE_BRAND,
	GET_OPTION_LIST_BRAND,
	AUTH_TOKEN_KEY,
	GET_LIST_ROOT_BRAND,
	BRAND_IMAGE_S3,
  CREATE_BRAND_IMAGE,
  DELETE_BRAND_IMAGE,
  UPDATE_BRAND_IMAGE,
	BRAND_IMAGE_ERROR,
	BRAND_IMAGE_REQUEST,
	UPDATE_POPULAR_BRANDS,
	UPDATE_POPULAR_BRANDS_ERROR,
	UPDATE_POPULAR_BRANDS_SUBMIT,
	GET_POPULAR_BRANDS,
	GET_POPULAR_BRANDS_ERROR,
	GET_POPULAR_BRANDS_FETCH,
	FIX_BRAND,
	FIX_BRAND_ERROR,
	FIX_BRAND_SUBMIT,
	ATTACH_VOUCHER_BRANDS,
	DETACH_VOUCHER_BRANDS
} from '../constants';

import { logout } from './authAction';

import api from '../utils/Api';
import { manipulateErrors, checkAuthorized } from '../utils';
import { clientPutObject } from '../utils/AWS';
import {
  AWS_BUCKET,
  AWS_BUCKET_URL
} from '../config/storageBucket';
import { onRequest, onFailedRequest, onSuccessRequest } from './helpers';

export const S3_FOLDER_PATH = 'brands/';
/**
 * Get list of offers
 * @author pewe
 * @guards jwt-token 
 * @param {object?} query 
 */
export const getBrandList=(query)=>{
	return dispatch =>(
		dispatch(isFetch({type:'fetch',status:true})),
		api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).get(BRAND_ROUTE_API,query)
		.then(res=>{
			dispatch(onSuccess(res.data,GET_LIST_BRAND))
			dispatch(isFetch({type:'fetch',status:false}))
		})
		.catch(err => {
			dispatch(onError(err));
			dispatch(isFetch({type:'fetch',status:false}));
		})
	)
}
export const getRootBrandList=(query)=>{
	return dispatch =>(
		dispatch(isFetch({type:'fetch',status:true})),
		api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).get(`${BRAND_ROUTE_API}/roots`)
		.then(res=>{
			dispatch(onSuccess(res.data,GET_LIST_ROOT_BRAND))
			dispatch(isFetch({type:'fetch',status:false}))
		})
		.catch(err => {
			dispatch(onError(err));
			dispatch(isFetch({type:'fetch',status:false}));
		})
	)
}
export const getOptionList=()=>{
	return dispatch => (
		dispatch(isFetch({type:'fetch',status:true})),
		api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).get(BRAND_ROUTE_API,{params:{no_limit:true}})
		.then(res=>{
			dispatch(onSuccess(res.data,GET_OPTION_LIST_BRAND))
			dispatch(isFetch({type:'fetch',status:false}))
		})
		.catch(err => {
			dispatch(onError(err));
			dispatch(isFetch({type:'fetch',status:false}));
		})
	)
}

/**
 * Get detail of Offer
 * @guards jwt-token
 * @param {integer} id 
 */
export const getDetailBrand=(id)=>{
	return dispatch =>(
		dispatch(isFetch({type:'fetch',status:true})),
		api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).get(`${BRAND_ROUTE_API}/${id}`)
		.then(res=>{
			dispatch(onSuccess(res.data,GET_DETAIL_BRAND))
			dispatch(isFetch({type:'fetch',status:false}))
		})
		.catch(err => {
			dispatch(onError(err));
			dispatch(isFetch({type:'fetch',status:false}));
		})
	)
}
export const saveBrand = (payload) =>{
	const {id, ...body}=payload;
	return dispatch =>{
		if(payload.id===undefined)
			dispatch(createBrand(body));
		else 
			dispatch(updateBrand(id, body));

	}
}
export const createBrand=(payload)=>{
	return dispatch =>(
		dispatch(isFetch({type:'submit',status:true})),
        api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).post(`${BRAND_ROUTE_API}`,payload)
		.then(res=>{
			dispatch(onSuccess(res.data,ADD_BRAND))
			dispatch(isFetch({type:'submit',status:false}))
		})
		.catch(err => {
			dispatch(onError(err));
			dispatch(isFetch({type:'submit',status:false}));
		})
	)
}

/**
 * Update an offer
 * @author pewe
 * @guards jwt-token
 * @param {integer} id 
 * @param {object} payload 
 */
export const updateBrand = (id,payload) =>{
	return dispatch => (
		dispatch(isFetch({type:'submit',status:true})),
		api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).put(`${BRAND_ROUTE_API}/${id}`,payload)
		.then(res=>{
			dispatch(onSuccess(res.data,UPDATE_BRAND))
			dispatch(isFetch({type:'submit',status:false}))
		})
		.catch(err => {
			dispatch(onError(err));
			dispatch(isFetch({type:'submit',status:false}));
		})
	)
}
export const moveBrand = (id,sibling_id,after=true) =>{
	let payload={after,sibling_id};
	return dispatch => (
		dispatch(isFetch({type:'submit',status:true})),
		api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).put(`${BRAND_ROUTE_API}/${id}`,payload)
		.then(res=>{
			dispatch(onSuccess(res.data,MOVE_POSITION_BRAND))
			dispatch(isFetch({type:'submit',status:false}))
		})
		.catch(err => {
			dispatch(onError(err));
			dispatch(isFetch({type:'submit',status:false}));
		})
	)
}

export const deleteBrand = (id) =>{
	return dispatch => (
		dispatch(isFetch({type:'submit',status:true})),
		api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).delete(`${BRAND_ROUTE_API}/${id}`)
		.then(res=>{
			dispatch(onSuccess(res.data,DELETE_BRAND))
			dispatch(isFetch({type:'submit',status:false}))
		})
		.catch(err => {
			dispatch(onError(err));
			dispatch(isFetch({type:'submit',status:false}));
		})
	)
}

/**
 * Indicates when start fetch api or end fetch api
 * @author pewe
 * @param {boolean} bool 
 */
export const isFetch=(payload)=>(
	{
		type:FETCHING_BRAND,
		payload:payload		
	}
)

/**
 * When onSuccess triggered
 * @author pewe
 * @param {object} res 
 * @param {string} type 
 */
export const onSuccess=(res,type)=>
(
	{
		type:type,
		payload:res		
	}
)
/**
 * When error fetch or connect to api
 * @author pewe
 * @param {object} err 
 */
// On Error fetching api
export const onError=(err)=>{
	if(!checkAuthorized(manipulateErrors(err)))
		return logout();
	return {
		type:BRAND_ERROR,
		payload:manipulateErrors(err)
	}
}

export const newBrand=()=>{
    return {
        type:NEW_BRAND
    }
}

export const uploadBrandImage = (file, folder = null, customFileName = null) => (
  dispatch => {
		let defaultFolder = S3_FOLDER_PATH;
		if(folder) defaultFolder = folder
    let key = `${defaultFolder}${file.identifier}.${mime.extension(file.fileInput.type)}`;
    if(customFileName){
      key = `${defaultFolder}${customFileName}`;
    }
    dispatch({
      type: BRAND_IMAGE_S3,
      payload: { isPending: true }
    })
    clientPutObject({
      Bucket: AWS_BUCKET,
      Key: key,
      Body: file.fileInput,
      ContentType:'image/jpeg',
      ACL: 'public-read', // your permisions
    }).then(res => {
      const results={
				identifier:file.identifier,
				prefix:AWS_BUCKET_URL,
				folder: defaultFolder,
				url:`${AWS_BUCKET_URL}${res.key}`
      }
      dispatch({
				type: BRAND_IMAGE_S3,
				payload: { results, isPending: false }
      });
    }).catch(err => {
			dispatch(onFailedRequest(BRAND_IMAGE_REQUEST,
				BRAND_IMAGE_S3, {
					type: BRAND_IMAGE_S3,
					payload: { error: err }
				})
			);
    })
  }
)

export const deleteBrandImageById = (brandId, id) => (
  dispatch => {
    dispatch(onRequest(BRAND_IMAGE_REQUEST, DELETE_BRAND_IMAGE, true));
    api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		})
    .delete(`${BRAND_ROUTE_API}/${brandId}/images/${id}`)
    .then(res => {
      dispatch(onSuccessRequest(DELETE_BRAND_IMAGE, res.data));
      dispatch(onRequest(BRAND_IMAGE_REQUEST, DELETE_BRAND_IMAGE, false));
    })
    .catch(err => {
      dispatch(onFailedRequest(BRAND_IMAGE_ERROR, DELETE_BRAND_IMAGE, err, id));
      dispatch(onRequest(BRAND_IMAGE_REQUEST, DELETE_BRAND_IMAGE, false));
    });
  }
)

export const saveBrandImage = (brandId, payload) => {
  const { id, ...body } = payload;
  return id? updateBrandImage(brandId, id, body): createBrandImage(brandId, body)
}

const updateBrandImage = (brandId, id, payload) => (
  dispatch => {
    dispatch(onRequest(BRAND_IMAGE_REQUEST, UPDATE_BRAND_IMAGE, true));
    api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		})
    .put(`${BRAND_ROUTE_API}/${brandId}/images/${id}`, payload)
    .then(res => {
      dispatch(onSuccessRequest(UPDATE_BRAND_IMAGE, res.data));
      dispatch(onRequest(BRAND_IMAGE_REQUEST, UPDATE_BRAND_IMAGE, false));
    })
    .catch(err => {
      dispatch(onFailedRequest(BRAND_IMAGE_ERROR, UPDATE_BRAND_IMAGE, err, id));
      dispatch(onRequest(BRAND_IMAGE_REQUEST, UPDATE_BRAND_IMAGE, false));
    });
  }
)

const createBrandImage = (brandId, payload) => (
  dispatch => {
    dispatch(onRequest(BRAND_IMAGE_REQUEST, CREATE_BRAND_IMAGE, true));
    api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		})
    .post(`${BRAND_ROUTE_API}/${brandId}/images`, payload)
    .then(res => {
      dispatch(onSuccessRequest(CREATE_BRAND_IMAGE, res.data));
      dispatch(onRequest(BRAND_IMAGE_REQUEST, CREATE_BRAND_IMAGE, false));
    })
    .catch(err => {
      dispatch(onFailedRequest(BRAND_IMAGE_ERROR, CREATE_BRAND_IMAGE, err));
      dispatch(onRequest(BRAND_IMAGE_REQUEST, CREATE_BRAND_IMAGE, false));
    });
  }
)

export const updatePopularBrands = (payloads) => (
	dispatch => {
		dispatch({
			type: UPDATE_POPULAR_BRANDS_SUBMIT,
			payload: true
		});
		api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		})
    .post(`${BRAND_ROUTE_API}/populars`, payloads)
    .then(res => {
			dispatch({
				type: UPDATE_POPULAR_BRANDS,
				payload: res.data
			});
			dispatch({
				type: UPDATE_POPULAR_BRANDS_SUBMIT,
				payload: false
			});
    })
    .catch(err => {
      dispatch({
				type: UPDATE_POPULAR_BRANDS_ERROR,
				payload: manipulateErrors(err)
			});
			dispatch({
				type: UPDATE_POPULAR_BRANDS_SUBMIT,
				payload: false
			});
    });
	}
)

export const getPopularBrands = (query) => (
	dispatch => {
		dispatch({
			type: GET_POPULAR_BRANDS_FETCH,
			payload: true
		});
		api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		})
    .get(`/brands/populars`, query)
    .then(res => {
			dispatch({
				type: GET_POPULAR_BRANDS,
				payload: res.data
			});
			dispatch({
				type: GET_POPULAR_BRANDS_FETCH,
				payload: false
			});
    })
    .catch(err => {
      dispatch({
				type: GET_POPULAR_BRANDS_ERROR,
				payload: manipulateErrors(err)
			});
			dispatch({
				type: GET_POPULAR_BRANDS_FETCH,
				payload: false
			});
    });
	}
)

export const fixBrands = () => (
	dispatch => {
		dispatch({
			type: FIX_BRAND_SUBMIT,
			payload: true
		});
		api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		})
    .post(`${BRAND_ROUTE_API}/fix-tree`)
    .then(res => {
			dispatch({
				type: FIX_BRAND,
				payload: res.data
			});
			dispatch({
				type: FIX_BRAND_SUBMIT,
				payload: false
			});
    })
    .catch(err => {
      dispatch({
				type: FIX_BRAND_ERROR,
				payload: manipulateErrors(err)
			});
			dispatch({
				type: FIX_BRAND_SUBMIT,
				payload: false
			});
    });
	}
)

export const attachVoucher=(id,payload)=>{
	return dispatch => (
		dispatch(isFetch({type:'submit',status:true})),
		api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).post(`${BRAND_ROUTE_API}/${id}/vouchers`,payload)
		.then(res=>{
			dispatch(onSuccess(res.data,ATTACH_VOUCHER_BRANDS))
			dispatch(isFetch({type:'submit',status:false}))
		})
		.catch(err => {
			dispatch(onError(err));
			dispatch(isFetch({type:'submit',status:false}));
		})
	)
}

export const detachVoucher=(id,payload)=>{
	return dispatch => (
		dispatch(isFetch({type:'submit',status:true})),
		api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).put(`${BRAND_ROUTE_API}/${id}/vouchers`,payload)
		.then(res=>{
			dispatch(onSuccess(res.data,DETACH_VOUCHER_BRANDS))
			dispatch(isFetch({type:'submit',status:false}))
		})
		.catch(err => {
			dispatch(onError(err));
			dispatch(isFetch({type:'submit',status:false}));
		})
	)
}