import React from 'react';
import propTypes from 'prop-types';
import { Table, Badge, UncontrolledTooltip } from 'reactstrap';
import { paymentTypeOptions, paymentStatusOptions } from '../../../../constants/kickavenue';
import { TextRupiah } from '../../../../components/Text';
import DateToHuman from '../../../../components/DateToHuman';
import styled from 'styled-components';
import ReactLoading from 'react-loading';
import TitleHeader from './TitleHeader';

const StatusDiv = styled.div`
  justify-content: center;
  align-items: flex-end;
  display: flex;
  flex-direction: column;
`;

const TopDiv = styled.div`
  display: flex;
  margin-bottom: 1rem;
  justify-content: center;
`;

const HeaderDiv = styled.div`
  display: flex;
  flex-direction: column;
`;
const _getPaymentType = _paymentType => {
  const getPaymentTypeFilter = paymentTypeOptions.filter(paymentType=>paymentType.value===_paymentType)
  if(getPaymentTypeFilter.length)
    return getPaymentTypeFilter[0].name;
}
const _getPaymentStatus = (status, expired) => {
  if(status==='PENDING'&&expired){
    return 'Reach Limit Time';
  }
  const getFilterStatus = paymentStatusOptions.filter(paymentStatus => paymentStatus.value===status);
  if(getFilterStatus.length){
    return getFilterStatus[0].name;
  }
  return 'UNDEFINED STATUS';
}

const Header = props => (
  <HeaderDiv>
    {
      props.isLoading?
      <TopDiv>
        <div className="loading-wrapper">
          <ReactLoading type="bars" color="#444" className="loading-center" width={30} height={30}/>
          <p className="text-center">Fetching payment details...</p>
        </div>
      </TopDiv>
      :
      <TopDiv>
        <TitleHeader
          createdAt={props.createdAt}
          category={props.userSell.product_variant.product.category&&props.userSell.product_variant.product.category.name}
          displayName={props.userSell.product_variant.display_name}
          SKU={props.userSell.product_variant.SKU}
          email={props.email}
          sneakersCondition={props.userSell.sneakers_condition}
          preOrder={props.userSell.pre_order}
          usSize={props.userSell.size.US}
        />
        <StatusDiv>
          <Badge color={props.statusColor} style={{fontSize: 14}}>{_getPaymentStatus(props.status, props.expired)}</Badge>
          <div style={{marginTop: '0.75rem'}}>
            <p>Pembayaran melalui <strong>{_getPaymentType(props.paymentType)}</strong></p>
          </div>
        </StatusDiv>
      </TopDiv>
    }
    <Table>
      <thead>
        <tr>
          <th>No. Invoice</th>
          <th>Full Name</th>
          <th>Total Amount</th>
          <th>Updated</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th scope="row">{ props.invoiceNumber }</th>
          <td>{ props.fullName }</td>
          <td>
            <TextRupiah value={props.totalAmount} prefix="Rp. "/>
            {
              props.walletUsage?
              <div style={{fontSize: '.65rem'}}>
                (<TextRupiah tag="span" value={props.walletUsage} prefix="Rp. "/>)
                {' '}
                <Badge id="wallet-credit-badge" style={{cursor:"pointer"}}>CREDIT</Badge>
                <UncontrolledTooltip target="wallet-credit-badge">Kick Credit + Seller Credit + Kick Point</UncontrolledTooltip>
              </div>
              :null
            }
          </td>
          <td><DateToHuman value={props.updatedAt} id={`header_Payment_updatedAt_${props.paymentId}`}/></td>
        </tr>
      </tbody>
    </Table>
  </HeaderDiv>
)
export default Header;
Header.propTypes = {
  paymentId: propTypes.number.isRequired,
  invoiceNumber: propTypes.string,
  shipping: propTypes.object,
  totalAmount: propTypes.number,
  updatedAt: propTypes.string,
  createdAt: propTypes.string,
  userSell: propTypes.object,
  walletUsage: propTypes.oneOfType([propTypes.number, propTypes.string])
}

Header.defaultProps = {
  paymentId: 0,
  userSell: {
    id: 0,
    size: {
      US: '-'
    },
    sneakers_condition: 'BARU',
    product_variant: {
      display_name: '-',
      SKU: '-',
      product: {
        id: 0,
        category: {
          id: 0,
          name: '-'
        }
      }
    },
    pre_order: false
  }
}