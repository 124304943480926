import React, { Component } from "react";
import { Row, Col, Card, CardBody, CardText, Button, Badge } from "reactstrap";
import propTypes from "prop-types";
import {
  CardHeaderWithToolbars,
  Title,
} from "../../../components/Card/CardHeaderWithToolbars";
import DateToHuman from "../../../components/DateToHuman";
import { Tbl, TblHead, TblBody } from "../../../components/Tbl";
import Analytics from "../../../services/Analytics";
import ModalPrize from "./CreatePrize/ModalPrize";
import CheckReadOnly from "../../../components/CheckReadOnly";

const dataHeader = [
  "No.",
  "Name",
  "Active",
  "Probability",
  "Quantity",
  "Used",
  "Updated At",
  "Action",
];

class SpinningWheelPrizeIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      prize_id: null,
      edit: false,
      _modalPrize: false,
      spinningWheel: {
        id: this.props.match.params.id,
        prizes: [],
        binding: false,
      },
    };

    this._toggle = this._toggle.bind(this);
  }

  _toggle(update = false) {
    if (update) {
      this.setState({
        spinningWheel: {
          ...this.state.spinningWheel,
          binding: false,
          prizes: [],
        },
        prize_id: null,
      });
      this.props.refreshDetail();
    }
    this.setState({
      _modalPrize: !this.state._modalPrize,
    });
  }

  componentDidMount() {
    const { email } = this.props.injectedProps;
    Analytics.recordPageView("spinning_wheel_prize_list", email, {
      url: window.location.href,
      location: this.props.location,
      match: this.props.match,
    });
  }

  componentDidUpdate(prevProps) {
    const { detail, isFetch, error, prize } = this.props.spinningWheel;

    if (!isFetch && detail.status_code === 200 && !error) {
      if (
        detail.spinning_wheel_prizes.length &&
        !this.state.spinningWheel.binding
      ) {
        this.setState({
          spinningWheel: {
            id: detail.id,
            binding: true,
            prizes: [...detail.spinning_wheel_prizes],
          },
        });
      }

      if (
        prevProps.spinningWheel.prize.isSubmit !== prize.isSubmit &&
        prize.delete
      ) {
        this._toggle(true);
      }
    }
  }

  _onRenderRowItem() {
    const { prizes } = this.state.spinningWheel;
    if (prizes && prizes.length) {
      return prizes.map((item, index) => (
        <tr key={index}>
          <th className="v-center" scope="row">
            {index + 1}
          </th>
          <td className="v-center" style={{ maxWidth: 250 }}>
            {item.name}
          </td>
          <td className="v-center"><CheckReadOnly check={item.active}/></td>
          <td className="v-center">{item.probability || 0} %</td>
          <td className="v-center">{item.limit || 0}</td>
          <td className="v-center">{item.used_count || 0}</td>
          <td className="v-center">
            {
              <DateToHuman
                value={item.updated_at}
                id={`tooltip_updatedAt_${index}`}
              />
            }
          </td>
          <td>
            <Button
              color="success"
              onClick={() =>
                this.setState({
                  edit: true,
                  prize_id: item.id,
                  _modalPrize: true,
                })
              }
            >
              <i className="icon-pencil"></i> Edit
            </Button>
          </td>
        </tr>
      ));
    }
    return null;
  }

  render() {
    return (
      <div className="animated fadeIn">
        <Row>
          <Col xs={12}>
            <Card>
              <ModalPrize
                edit={this.state.edit}
                isOpen={this.state._modalPrize}
                onCancel={this._toggle}
                prizeId={this.state.prize_id}
                spinWheelId={this.state.spinningWheel.id}
              />
              <CardHeaderWithToolbars>
                <Title>
                  <i className="fa fa-align-justify"></i>
                  <span>Spinning Wheel Prize List</span>
                </Title>
              </CardHeaderWithToolbars>
              <CardBody>
                <CardText>
                  In this section will display our Spinning Wheel Prizes.
                </CardText>
                <div className="mb-3">
                  <Button
                    title="New Prize"
                    onClick={() =>
                      this.setState({
                        edit: false,
                        _modalPrize: true,
                        prize_id: null,
                      })
                    }
                  >
                    <i className="fa fa-plus"></i> Create New
                  </Button>
                </div>
                <div className="table-responsive">
                  <Tbl>
                    <TblHead dataHeader={dataHeader} />
                    <TblBody
                      isLoad={this.props.spinningWheel.isFetch}
                      hasRow={
                        this.props.spinningWheel.detail.status_code === 200 &&
                        this.props.spinningWheel.detail.spinning_wheel_prizes
                          .length > 0
                      }
                      columnCount={dataHeader.length}
                    >
                      {this._onRenderRowItem()}
                    </TblBody>
                  </Tbl>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default SpinningWheelPrizeIndex;

SpinningWheelPrizeIndex.propTypes = {
  spinningWheel: propTypes.object,
  refreshDetail: propTypes.func.isRequired,
};

SpinningWheelPrizeIndex.defaultProps = {
  spinningWheel: {},
};
