import React, { Component } from 'react';
import { connect } from 'react-redux';
import RewardForm from './RewardForm';
import privateView from '../../components/hocs/privateView';
import Analytics from "../../services/Analytics";

class RewardCreate extends Component{
	componentDidMount(){
    const { email } = this.props.injectedProps;
    Analytics.recordPageView('reward_detail', email, {
      url: window.location.href,
      location: this.props.location,
      match: this.props.match
    });
  }
  render() {
    return (
      <RewardForm
        title="Create New Reward"
        {...this.props}
      />
    )
  }
}

const mapStateToProps = ({auth: { isFetch, roles, isLogged, id, email }}) =>({
  guardData: { isFetch, roles, isLogged, id, email }
})

const enhance = connect(mapStateToProps);
export default enhance(privateView(RewardCreate));