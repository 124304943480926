import React from 'react';
import _ from 'lodash';
import propTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Card, CardHeader, CardBody, Input
} from 'reactstrap';

import { getSellerPointHistory } from '../../../actions/userAction';
import { Tbl, TblHead, TblBody } from '../../../components/Tbl';
import { generateNumber } from '../../../utils';
import RowItem from '../UserForm/sellerPointLogListCard/RowItem';
import Paginations from '../../../components/Paginations';
import { DEFAULT_SORT_BY } from '../../../constants/settings';
import classnames from 'classnames';

const dataHeader = ['No.', 'Ref. ID', 'Deduction', 'Updated'];

const defaultParams = {
  sort_by : DEFAULT_SORT_BY,
  per_page : 3
}

class SellerPointLogListCard extends React.Component{
  constructor(props){
    super(props);
    this.state={
      _params:{ ...defaultParams },
      _filterOpen:false
    }
    this._handleGoto = this._handleGoto.bind(this);
    this._throttleGetList = _.debounce(this._getList.bind(this), 500)
  }

  componentDidMount(){
    this._getList(this.state._params);
  }

  _getList(rawParams = {} ){
    let params = {};
    Object.keys(rawParams)
    .filter(key => rawParams[key]!='')
    .forEach(key=> params[key] = rawParams[key]);
    this.props.getList(this.props.userId, {params});
  }

  _handleGoto(page){
		const _params=this.state._params;
		_params.page=page;
		this.setState({_params},()=>this._getList(this.state._params));
	}

  _onRenderPagination(){
    const { list } = this.props.dataSellerPoint;
    const hasData = (this.props.dataSellerPoint.status_code===200 && list.data.length) || null;
    return hasData && (
      <Paginations
        size="sm"
        lastPage={list.last_page}
        perPage={list.per_page}
        total={list.total}
        maxPage={8}
        path={list.path}
        currentPage={list.current_page}
        prevPageUrl={list.prev_page_url}
        nextPageUrl={list.next_page_url}
        handleGotoCallback={this._handleGoto}
      />
    )
  }

  _renderRowItem(){
    if(this.props.dataSellerPoint.isFetch === false && this.props.dataSellerPoint.status_code===200){
      const { data, current_page, per_page } = this.props.dataSellerPoint.list;
      return data.map((item, index)=>{
        return (
          <RowItem
            key={item.id}
            id={item.id}
            number={generateNumber(per_page, current_page, index)}
            refID={item.reference_id}
            type={item.type}
            amount={item.amount}
            sale={item?.sale}
            updatedAt={item.updated_at}
            createdAt={item.created_at}
          />
        )
      })
    }
    return null;
  }

  render(){
    const { dataSellerPoint } = this.props;
    return(
      <Card className={classnames(this.props.cardClassname)}>
        { this.props.showHeader?
          <CardHeader>
            <i className="fa fa-align-justify mr-2"></i>
            {this.props.headerText? this.props.headerText: "Seller Point Log"}
          </CardHeader>
        :null }
        <CardBody className={classnames("pb-0", this.props.filterBodyClassname)}>
          <div className="d-flex mb-3">
            <Input
              type="text"
              placeholder="Search any keyword..."
              name="keyword"
              value={this.state._params.keyword || ""}
              onChange={async(e) => {
                await this.setState({_params: { ...this.state._params, [e.target.name]: e.target.value, page: 1 }})
                this._throttleGetList(this.state._params)
              }}
            />
          </div>
          <div style={{fontSize: '.75rem', justifyContent: 'flex-end'}} className="d-flex mb-1 align-items-center">
            <p className="mb-0 mr-3">Per Page</p>
            <Input
              style={{width: '80px'}}
              size="sm"
              type="select"
              name="per_page"
              value={this.state._params.per_page}
              onChange={async (e) => {
                await this.setState({_params: { ...this.state._params, [e.target.name]: e.target.value, page: 1 }})
                this._getList(this.state._params)
              }}
            >
              <option value="3">Default</option>
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="15">15</option>
            </Input>
          </div>
        </CardBody>
        <CardBody className={classnames(this.props.contentBodyClassname)}>
          <div className="table-responsive" style={{maxHeight: 500, overflow: 'auto'}}>
            <Tbl>
              <TblHead dataHeader={dataHeader}/>
              <TblBody
                isLoad={dataSellerPoint.isFetch}
                hasRow={dataSellerPoint.status_code===200 && dataSellerPoint.list.data.length}
                columnCount={dataHeader.length}
              >
              { this._renderRowItem() }
              </TblBody>
            </Tbl>
          </div>
        </CardBody>
        <CardBody className={classnames("py-0", this.props.footerBodyClassname)}>
          { this._onRenderPagination() }
        </CardBody>
      </Card>
    )
  }
}

const mapStateToProps = ({user:{ detail }}) => ({
  dataUser: detail,
  dataSellerPoint: detail.seller_point_history
})
const mapDispatchToProps = dispatch => ({
  getList: (userId, params) => dispatch(getSellerPointHistory(userId, params)),
})
export default connect(mapStateToProps,mapDispatchToProps)(SellerPointLogListCard);
SellerPointLogListCard.propTypes={
  userId: propTypes.oneOfType([propTypes.number, propTypes.string]).isRequired
}