import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Container } from 'reactstrap';
import ArticleForm from './ArticleForm';
import privateView from '../../components/hocs/privateView';
import Analytics from "../../services/Analytics";

class ArticleCreate extends Component{
	componentDidMount(){
    const { email } = this.props.injectedProps;
    Analytics.recordPageView('article_create', email, {
      url: window.location.href,
      location: this.props.location,
      match: this.props.match
    });
  }
  render(){
    return (
      <Container fluid>
        <ArticleForm 
          title="Create New Article" 
          {...this.props}
        />
      </Container>
    )
  }
}

const mapStateToProps = ({auth: { isFetch, roles, isLogged, id, email } })=>({
  guardData: { isFetch, roles, isLogged, id, email }
})

const enhance = connect(mapStateToProps);
export default enhance(privateView(ArticleCreate, ['blog_admin']));
