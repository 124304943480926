import {
    AUTH_TOKEN_KEY,
    TIMER_ROUTE_API,
    TIMER_LIST_FETCH,
    TIMER_FETCHING,
    TIMER_DETAIL_FETCH,
    TIMER_ADD,
    TIMER_UPDATE,
    TIMER_DELETE,
    TIMER_ERROR,
} from '../constants';
import api from '../utils/Api';
import { manipulateErrors, checkAuthorized } from '../utils';
import { logout } from './authAction';

/**
 * Indicates when start fetch api or end fetch api
 * @author Russell
 * @param {boolean} bool
 */
export const isFetch = (payload) => (
    {
        type: TIMER_FETCHING,
        payload: payload
    }
)

/**
 * When onSuccess triggered
 * @author Russell
 * @param {object} res
 * @param {string} type
 */
export const onSuccess = (res, type) => (
    {
        type: type,
        payload: res
    }
)

/**
 * When error fetch or connect to api
 * @author Russell
 * @param {object} err
 */
export const onError = (err) => {
    if (!checkAuthorized(manipulateErrors(err))) return logout();
    return {
        type: TIMER_ERROR,
        payload: manipulateErrors(err)
    }
}

/**
 * Timer CRUD
 * @author russell
 * @guards jwt-token 
 * @param {object?} query 
 */
export const getTimerList=(query)=>{
	return dispatch =>(
		dispatch(isFetch({type:'fetch',status:true})),
		api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).get(TIMER_ROUTE_API,query)
		.then(res=>{
			dispatch(onSuccess(res.data,TIMER_LIST_FETCH))
			dispatch(isFetch({type:'fetch',status:false}))
		})
		.catch(err => {
			dispatch(onError(err));
			dispatch(isFetch({type:'fetch',status:false}));
		})
	)
}

export const getDetailTimer=(id)=>{
	return dispatch=>(
		dispatch(isFetch({type:'fetch',status:true})),
		api({
			Authorization:'Bearer '+localStorage.getItem(AUTH_TOKEN_KEY)
		}).get(`${TIMER_ROUTE_API}/${id}`)
		.then(res => {
			dispatch(onSuccess(res.data,TIMER_DETAIL_FETCH));
			dispatch(isFetch({type:'fetch',status:false}));
		})
		.catch(err => {
			dispatch(onError(err));
			dispatch(isFetch({type:'fetch',status:false}));
		})		
	)
}

export const createTimer=(payload)=>{
	return dispatch =>(
		dispatch(isFetch({type:'submit',status:true})),
				api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).post(`${TIMER_ROUTE_API}`,payload)
		.then(res=>{
			dispatch(onSuccess(res.data,TIMER_ADD))
			dispatch(isFetch({type:'submit',status:false}))
		})
		.catch(err => {
			dispatch(onError(err));
			dispatch(isFetch({type:'submit',status:false}));
		})
	)
}

export const updateTimer=(id, payload)=>{
	return dispatch => (
		dispatch(isFetch({type:'submit',status:true})),
		api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).put(`${TIMER_ROUTE_API}/${id}`,payload)
		.then(res=>{
			dispatch(onSuccess(res.data,TIMER_UPDATE))
			dispatch(isFetch({type:'submit',status:false}))
		})
		.catch(err => {
			dispatch(onError(err));
			dispatch(isFetch({type:'submit',status:false}));
		})
	)
}

export const deleteTimer=(id)=>{
	return dispatch =>(
		dispatch(isFetch({type:'submit',status:true})),
				api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).delete(`${TIMER_ROUTE_API}/${id}`)
		.then(res=>{
			dispatch(onSuccess(res.data,TIMER_DELETE))
			dispatch(isFetch({type:'submit',status:false}))
		})
		.catch(err => {
			dispatch(onError(err));
			dispatch(isFetch({type:'submit',status:false}));
		})
	)
}