import React from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  ModalFooter
} from 'reactstrap';
import propTypes from 'prop-types'
export default class ModalInfo extends React.PureComponent{
  render(){
    return(
      <Modal size="sm" className="modal-confirmation" isOpen={this.props.isOpen} toggle={this.props.toggle}>
        <ModalHeader toggle={this.props.toggle} className="modal-header--center">
          {this.props.modalTitle}
        </ModalHeader>
        <ModalBody>
          { this.props.renderModalBody?this.props.renderModalBody(): <p className="text-center">{ this.props.modalBody }</p> }
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={this.props.toggle}>Oke</Button>
        </ModalFooter>
      </Modal>
    )
  }
}
ModalInfo.propTypes = {
  modalTitle: propTypes.string,
  modalBody: propTypes.string,
  isOpen: propTypes.bool,
  toggle: propTypes.func,
}
ModalInfo.defaultProps = {
  modalTitle: 'Info',
  modalBody: 'Your info body',
  isOpen: false
}