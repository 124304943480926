import {
  AUTH_TOKEN_KEY,
  FAQ_ROUTE_API,
  FAQ_ADD,
  FAQ_DELETE,
  FAQ_DETAIL_FETCH,
  FAQ_ERROR,
  FAQ_FETCHING,
  FAQ_LIST_FETCH,
  FAQ_UPDATE,
  FAQ_CATEGORY_ADD,
  FAQ_CATEGORY_DELETE,
  FAQ_CATEGORY_ERROR,
  FAQ_CATEGORY_FETCHING,
  FAQ_CATEGORY_LIST_FETCH,
  FAQ_CATEGORY_UPDATE,
  FAQ_CATEGORY_DETAIL_FETCH,
  ADD_IMAGE_FAQ,
  DELETE_IMAGE_FAQ,
} from "../constants";
import api from "../utils/Api";
import { manipulateErrors, checkAuthorized } from "../utils";
import { logout } from "./authAction";

/**
 * Indicates when start fetch api or end fetch api
 * @author haikal
 * @param {boolean} bool
 */
export const isFetch = (payload) => ({
  type: FAQ_FETCHING,
  payload: payload,
});

/**
 * Indicates when start fetch api or end fetch api
 * @author haikal
 * @param {boolean} bool
 */
export const isFetchCategory = (payload) => ({
  type: FAQ_CATEGORY_FETCHING,
  payload: payload,
});

/**
 * When onSuccess triggered
 * @author haikal
 * @param {object} res
 * @param {string} type
 */
export const onSuccess = (res, type) => ({
  type: type,
  payload: res,
});
/**
 * When error fetch or connect to api
 * @author haikal
 * @param {object} err
 */
// On Error fetching api
export const onError = (err) => {
  if (!checkAuthorized(manipulateErrors(err))) return logout();
  return {
    type: FAQ_ERROR,
    payload: manipulateErrors(err),
  };
};

/**
 * When error fetch or connect to api
 * @author haikal
 * @param {object} err
 */
// On Error fetching api
export const onErrorCategory = (err) => {
  if (!checkAuthorized(manipulateErrors(err))) return logout();
  return {
    type: FAQ_CATEGORY_ERROR,
    payload: manipulateErrors(err),
  };
};

export const getFaqlList = (query) => {
  return (dispatch) => (
    dispatch(isFetch({ type: "fetch", status: true })),
    api({
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`,
    })
      .get(FAQ_ROUTE_API, query)
      .then((res) => {
        dispatch(onSuccess(res.data, FAQ_LIST_FETCH));
        dispatch(isFetch({ type: "fetch", status: false }));
      })
      .catch((err) => {
        dispatch(onError(err));
        dispatch(isFetch({ type: "fetch", status: false }));
      })
  );
};

export const getDetailFaq = (id) => {
  return (dispatch) => (
    dispatch(isFetch({ type: "fetch", status: true })),
    api({
      Authorization: "Bearer " + localStorage.getItem(AUTH_TOKEN_KEY),
    })
      .get(`${FAQ_ROUTE_API}/${id}`)
      .then((res) => {
        dispatch(onSuccess(res.data, FAQ_DETAIL_FETCH));
        dispatch(isFetch({ type: "fetch", status: false }));
      })
      .catch((err) => {
        dispatch(onError(err));
        dispatch(isFetch({ type: "fetch", status: false }));
      })
  );
};

export const saveFaq = (payload,id) =>{
	return dispatch =>{
		if(id === undefined)
			dispatch(createFaq(payload));
		else 
			dispatch(updateFaq(id,payload));
	}
}

export const addImage=(identifier,payload)=>{
	return dispatch => (
		dispatch(isFetch({type:'submit_image',status:true})),
		api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).post(`${FAQ_ROUTE_API}/images`,payload)
		.then(res=>{
			dispatch(onSuccess({ ...res.data, identifier },ADD_IMAGE_FAQ))
			dispatch(isFetch({type:'submit_image',status:false}))
		})
		.catch(err => {
			dispatch(onError(err));
			dispatch(isFetch({type:'submit_image',status:false}));
		})
	)
}

export const deleteImage=(identifier,id)=>{
	return dispatch => (
		dispatch(isFetch({type:'delete_image',status:true})),
		api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).delete(`${FAQ_ROUTE_API}/images/${id}`)
		.then(res => {
			dispatch(onSuccess({ identifier },DELETE_IMAGE_FAQ))
			dispatch(isFetch({type:'delete_image',status:false}))
		})
		.catch(err => {
			dispatch(onError(err));
			dispatch(isFetch({type:'delete_image',status:false}));
		})
	)
}

export const createFaq = (payload) => {
  return (dispatch) => (
    dispatch(isFetch({ type: "submit", status: true })),
    api({
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`,
    })
      .post(`${FAQ_ROUTE_API}`, payload)
      .then((res) => {
        dispatch(onSuccess(res.data, FAQ_ADD));
        dispatch(isFetch({ type: "submit", status: false }));
      })
      .catch((err) => {
        dispatch(onError(err));
        dispatch(isFetch({ type: "submit", status: false }));
      })
  );
};

export const updateFaq = (id, payload) => {
  return (dispatch) => (
    dispatch(isFetch({ type: "submit", status: true })),
    api({
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`,
    })
      .put(`${FAQ_ROUTE_API}/${id}`, payload)
      .then((res) => {
        dispatch(onSuccess(res.data, FAQ_UPDATE));
        dispatch(isFetch({ type: "submit", status: false }));
      })
      .catch((err) => {
        dispatch(onError(err));
        dispatch(isFetch({ type: "submit", status: false }));
      })
  );
};

export const deleteFaq = (id) => {
  return (dispatch) => (
    dispatch(isFetch({ type: "submit", status: true })),
    api({
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`,
    })
      .delete(`${FAQ_ROUTE_API}/${id}`)
      .then((res) => {
        dispatch(onSuccess(res.data, FAQ_DELETE));
        dispatch(isFetch({ type: "submit", status: false }));
      })
      .catch((err) => {
        dispatch(onError(err));
        dispatch(isFetch({ type: "submit", status: false }));
      })
  );
};

export const getFaqCategorylList = (query) => {
  return (dispatch) => (
    dispatch(isFetchCategory({ type: "fetch", status: true })),
    api({
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`,
    })
      .get(`${FAQ_ROUTE_API}/categories`, query)
      .then((res) => {
        dispatch(onSuccess(res.data, FAQ_CATEGORY_LIST_FETCH));
        dispatch(isFetchCategory({ type: "fetch", status: false }));
      })
      .catch((err) => {
        dispatch(onErrorCategory(err));
        dispatch(isFetchCategory({ type: "fetch", status: false }));
      })
  );
};

export const getDetailFaqCategory = (id) => {
  return (dispatch) => (
    dispatch(isFetchCategory({ type: "detail", status: true })),
    api({
      Authorization: "Bearer " + localStorage.getItem(AUTH_TOKEN_KEY),
    })
      .get(`${FAQ_ROUTE_API}/categories/${id}`)
      .then((res) => {
        dispatch(onSuccess(res.data, FAQ_CATEGORY_DETAIL_FETCH));
        dispatch(isFetchCategory({ type: "detail", status: false }));
      })
      .catch((err) => {
        dispatch(onErrorCategory(err));
        dispatch(isFetchCategory({ type: "detail", status: false }));
      })
  );
};

export const createFaqCategory = (payload) => {
  return (dispatch) => (
    dispatch(isFetchCategory({ type: "submit", status: true })),
    api({
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`,
    })
      .post(`${FAQ_ROUTE_API}/categories`, payload)
      .then((res) => {
        dispatch(onSuccess(res.data, FAQ_CATEGORY_ADD));
        dispatch(isFetchCategory({ type: "submit", status: false }));
      })
      .catch((err) => {
        dispatch(onErrorCategory(err));
        dispatch(isFetchCategory({ type: "submit", status: false }));
      })
  );
};

export const updateFaqCategory = (id, payload) => {
  return (dispatch) => (
    dispatch(isFetchCategory({ type: "detail", status: true })),
    api({
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`,
    })
      .put(`${FAQ_ROUTE_API}/categories/${id}`, payload)
      .then((res) => {
        dispatch(onSuccess(res.data, FAQ_CATEGORY_UPDATE));
        dispatch(isFetchCategory({ type: "detail", status: false }));
      })
      .catch((err) => {
        dispatch(onErrorCategory(err));
        dispatch(isFetchCategory({ type: "detail", status: false }));
      })
  );
};

export const deleteFaqCategory = (id) => {
  return (dispatch) => (
    dispatch(isFetchCategory({ type: "detail", status: true })),
    api({
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`,
    })
      .delete(`${FAQ_ROUTE_API}/categories/${id}`)
      .then((res) => {
        dispatch(onSuccess(res.data, FAQ_CATEGORY_DELETE));
        dispatch(isFetchCategory({ type: "detail", status: false }));
      })
      .catch((err) => {
        dispatch(onErrorCategory(err));
        dispatch(isFetchCategory({ type: "detail", status: false }));
      })
  );
};
