import {
  ADD_BANK_PROMO,
  BANK_PROMO_ERROR,
  BANK_PROMO_SET_DELETE_MODAL_OPEN,
  DELETE_BANK_PROMO,
  DELETTING_BANK_PROMO,
  FETCHING_BANK_PROMOTION,
  GET_BANK_PROMO_BY_ID,
  GET_LIST_BANK_PROMO,
  SUBMITTING_BANK_PROMOTION,
  UPDATE_BANK_PROMO,
} from "../constants";

const initialState = {
  list: {},
  detail: {},
  isFetch: false,
  isSubmit: false,
  isDelete: false,
  isDeleteModalOpen: false,
  error: null,
  submitSuccess: "",
  deleteSuccess: "",
};

const onGetBankPromoList = (lastState, payload) => {
  const { data, message, status_code } = payload;
  return {
    ...lastState,
    error: null,
    submitSuccess: "",
    deleteSuccess: "",
    isDeleteModalOpen: false,
    detail: {},
    list: { ...data, message, status_code },
  };
};

const onAddBankPromo = (lastState, payload) => {
  const { data, message, status_code } = payload;
  return {
    ...lastState,
    detail: {
      ...data,
      message,
      status_code,
    },
    submitSuccess: "Insert has been successfully!",
    error: null,
  };
};

const onGetBankPromoById = (lastState, payload) => {
  const { data, message, status_code } = payload;
  return {
    ...lastState,
    detail: {
      ...data,
      message,
      status_code,
    },
    error: null,
  };
};

const onUpdateBankPromo = (lastState, payload) => {
  const { data, message, status_code } = payload;
  return {
    ...lastState,
    detail: {
      ...data,
      message,
      status_code,
    },
    submitSuccess: "Update has been successfully!",
    error: null,
  };
};

const onDeleteBankPromo = (lastState, payload) => {
  const { data, message, status_code } = payload;
  return {
    ...lastState,
    detail: {
      ...data,
      message,
      status_code,
    },
    deleteSuccess: "Delete has been successfully!",
    isDeleteModalOpen: false,
    error: null,
  };
};

const onFetchingBankPromo = (lastState, payload) => {
  return {
    ...lastState,
    isFetch: payload.status,
    submitSuccess: "",
  };
};

const onSubmittingBankPromo = (lastState, payload) => {
  return {
    ...lastState,
    isSubmit: payload.status,
  };
};

const onDelettingBankPromo = (lastState, payload) => {
  return {
    ...lastState,
    isDelete: payload.status,
  };
};

const onBankPromoError = (lastState, error) => {
  return {
    ...lastState,
    ...error,
    submitSuccess: "",
    deleteSuccess: "",
    isDeleteModalOpen: false,
  };
};

const onSetDeleteModalOpen = (lastState, action) => {
  return {
    ...lastState,
    isDeleteModalOpen: action.value
  }
}

const bankPromoReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_LIST_BANK_PROMO:
      return onGetBankPromoList(state, action.payload);
    case ADD_BANK_PROMO:
      return onAddBankPromo(state, action.payload);
    case UPDATE_BANK_PROMO:
      return onUpdateBankPromo(state, action.payload);
    case DELETE_BANK_PROMO:
      return onDeleteBankPromo(state, action.payload);
    case GET_BANK_PROMO_BY_ID:
      return onGetBankPromoById(state, action.payload);
    case FETCHING_BANK_PROMOTION:
      return onFetchingBankPromo(state, action.payload);
    case SUBMITTING_BANK_PROMOTION:
      return onSubmittingBankPromo(state, action.payload);
    case DELETTING_BANK_PROMO:
      return onDelettingBankPromo(state, action.payload);
    case BANK_PROMO_ERROR:
      return onBankPromoError(state, action.payload);
    case BANK_PROMO_SET_DELETE_MODAL_OPEN:
      return onSetDeleteModalOpen(state, action)
    default:
      return state;
  }
};

export default bankPromoReducer;
