import {
  AUTH_TOKEN_KEY,
  GET_DOLLAR_RATE_SETTING,
  DOLLAR_RATE_SETTING_REQUEST,
  DOLLAR_RATE_SETTING_ERROR,
  UPDATE_DOLLAR_RATE_SETTING,
  SETTING_ROUTE_API,
  SETTING_FETCHING,
  SETTING_ERROR,
  SETTING_LIST_FETCH,
  SETTING_DETAIL_FETCH,
  SETTING_ADD,
  SETTING_UPDATE,
  SETTING_DELETE,
  PAYMENT_METHOD_FETCH,
  PAYMENT_METHOD_FETCHING,
  SETTING_GET_PROCESSING_FEE,
} from '../constants';

import {
  onRequest,
  onSuccessRequest,
  onFailedRequest
} from './helpers';
import api, { apiClient } from '../utils/Api';
import { checkAuthorized, manipulateErrors } from '../utils';
import { logout } from './authAction';

const DOLLAR_ROUTE_API = '/admins/settings/dollars';

/**
 * Indicates when start fetch api or end fetch api
 * @author Haikal
 * @param {boolean} bool
 */
 export const isFetch = (payload) => (
  {
      type: SETTING_FETCHING,
      payload: payload
  }
)

/**
* When onSuccess triggered
* @author Haikal
* @param {object} res
* @param {string} type
*/
export const onSuccess = (res, type) => (
  {
      type: type,
      payload: res
  }
)

/**
* When error fetch or connect to api
* @author Haikal
* @param {object} err
*/
export const onError = (err) => {
  if (!checkAuthorized(manipulateErrors(err))) return logout();
  return {
      type: SETTING_ERROR,
      payload: manipulateErrors(err)
  }
}

export const getSettingList = (query) => (
  dispatch => {
    dispatch(isFetch({type:'fetch',status:true}));
    apiClient()
    .get(SETTING_ROUTE_API, query)
    .then(res => {
      dispatch(onSuccess(res.data, SETTING_LIST_FETCH));
      dispatch(isFetch({type:'fetch',status:false}))
    })
    .catch(err => {
      dispatch(onError(err));
			dispatch(isFetch({type:'fetch',status:false}));
    });
  }
)

export const getDetailSetting=(id)=>{
	return dispatch=>(
		dispatch(isFetch({type:'fetch',status:true})),
		api({
			Authorization:'Bearer '+localStorage.getItem(AUTH_TOKEN_KEY)
		}).get(`${SETTING_ROUTE_API}/${id}`)
		.then(res => {
			dispatch(onSuccess(res.data,SETTING_DETAIL_FETCH));
			dispatch(isFetch({type:'fetch',status:false}));
		})
		.catch(err => {
			dispatch(onError(err));
			dispatch(isFetch({type:'fetch',status:false}));
		})		
	)
}

export const createSetting=(payload)=>{
	return dispatch =>(
		dispatch(isFetch({type:'submit',status:true})),
				api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).post(`${SETTING_ROUTE_API}`,payload)
		.then(res=>{
			dispatch(onSuccess(res.data,SETTING_ADD))
			dispatch(isFetch({type:'submit',status:false}))
		})
		.catch(err => {
			dispatch(onError(err));
			dispatch(isFetch({type:'submit',status:false}));
		})
	)
}

export const updateSetting=(id, payload)=>{
	return dispatch => (
		dispatch(isFetch({type:'submit',status:true})),
		api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).put(`${SETTING_ROUTE_API}/${id}`,payload)
		.then(res=>{
			dispatch(onSuccess(res.data,SETTING_UPDATE))
			dispatch(isFetch({type:'submit',status:false}))
		})
		.catch(err => {
			dispatch(onError(err));
			dispatch(isFetch({type:'submit',status:false}));
		})
	)
}

export const deleteSetting=(id)=>{
	return dispatch =>(
		dispatch(isFetch({type:'submit',status:true})),
				api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).delete(`${SETTING_ROUTE_API}/${id}`)
		.then(res=>{
			dispatch(onSuccess(res.data,SETTING_DELETE))
			dispatch(isFetch({type:'submit',status:false}))
		})
		.catch(err => {
			dispatch(onError(err));
			dispatch(isFetch({type:'submit',status:false}));
		})
	)
}

export const getDollarSettingData = () => (
  dispatch => {
    dispatch(onRequest(DOLLAR_RATE_SETTING_REQUEST, GET_DOLLAR_RATE_SETTING, true));
    apiClient()
    .get(DOLLAR_ROUTE_API)
    .then(res => {
      dispatch(onSuccessRequest(GET_DOLLAR_RATE_SETTING, res.data));
    })
    .catch(err => {
      dispatch(onFailedRequest(DOLLAR_RATE_SETTING_ERROR, GET_DOLLAR_RATE_SETTING, err));
    });
  }
)

export const updateDollarSettingData = payloads => (
  dispatch => {
    dispatch(onRequest(DOLLAR_RATE_SETTING_REQUEST, UPDATE_DOLLAR_RATE_SETTING, true));
    apiClient()
    .post(DOLLAR_ROUTE_API, payloads)
    .then(res => {
      dispatch(onSuccessRequest(UPDATE_DOLLAR_RATE_SETTING, res.data));
    })
    .catch(err => {
      dispatch(onFailedRequest(DOLLAR_RATE_SETTING_ERROR, UPDATE_DOLLAR_RATE_SETTING, err));
    });
  }
)

export const getSettingPaymentMethod = () => {
  return dispatch => (
    dispatch({
			type: PAYMENT_METHOD_FETCHING,
			payload: true
		}),
    api({
			Authorization:'Bearer '+localStorage.getItem(AUTH_TOKEN_KEY)
    }).get(`${SETTING_ROUTE_API}?name=payment_methods`)
    .then(res => {
      const payload = JSON.parse(res.data.data.data[0].value);
      dispatch(onSuccess(payload,PAYMENT_METHOD_FETCH));
    })
    .catch(err => {
      dispatch(onError(err));
    })
  )
}

export const getProcessingFeeSetting = () => (
  dispatch => {
    dispatch({ type: `${SETTING_GET_PROCESSING_FEE}_PENDING` });
    apiClient()
    .get(`${SETTING_ROUTE_API}?name=processing_fee`)
    .then(res => {
      dispatch({ type: `${SETTING_GET_PROCESSING_FEE}_FULFILLED`, payload: res.data });
    })
    .catch(err => {
      dispatch({ type: `${SETTING_GET_PROCESSING_FEE}_REJECTED`, payload: err });
    });
  }
)