import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import Loading from 'react-loading-animation';

import {
  deleteReward,
} from '../../../actions/rewardAction';
import { ModalDelete } from '../../../components/Modals';

import FormCard from './FormCard';

class RewardForm extends Component{
  constructor(props){
    super(props);
    this.state={
      _edit:this.props.edit,
      _readOnly:this.props.readOnly,
      _hasId:this.props.match.params.id?true:false,
      _redirect:'/rewards',
      _deleteProcess:false,
      _errors:[],
      _success:[],
      _formSubmit:false,
      _modalDelete:false
    }
    this.onDelete=this.onDelete.bind(this);
    this.toggle=this.toggle.bind(this);
  }

  componentDidUpdate(prevProps){
    if(prevProps.reward.isSubmit
      && prevProps.reward.isSubmit !== this.props.reward.isSubmit
      && this.state._deleteProcess === true
    ){
      if(prevProps.reward.detail.delete === true){
        this.props.history.replace(this.state._redirect);
      }
    }
  }

  toggle(){
    this.setState({_modalDelete:!this.state._modalDelete});
  }

  onDelete(){
    this.setState({_deleteProcess:true}, ()=>this.props.delete(this.props.match.params.id));
  }

  render(){
    const { match : { params }, history, title } = this.props;
    return(
      <div>
        <ModalDelete
          isOpen={this.state._modalDelete} 
          toggle={this.toggle}
          onDelete={this.onDelete}
        />
        <FormCard 
          params={params} 
          history={history} 
          title={title} 
          isEdit={this.state._edit} 
          isNew={!this.state._hasId} 
          onDelete={this.toggle}
          onToggleModal={this.toggle}
          isDelete={this.state._deleteProcess}
        />
      </div>
    )
  }
}
const mapStateToProps= ({reward}) => {
	return {
    reward
	}
};
const mapDispatchToProps = (dispatch) => {
	return{
		delete:(id) => dispatch(deleteReward(id)),
	}
};

export default connect(mapStateToProps,mapDispatchToProps)(RewardForm);