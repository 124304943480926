export const transPaymentType = paymentType => {
  switch(paymentType){
    case 'bank_transfer': return 'Bank Transfer';
    case 'credit_card': return 'Credit Card';
    default: return paymentType;
  }
}

export const transStatus = (status, trxId, isExpired) => {
  const alterStatus = status === 'pending' && isExpired ? 'reach_limit_time': (
    status === 'pending' && !trxId? 'not_confirmed': status);
  return alterStatus.replace(/(_)+/g, ' ')
  .split(' ')
  .reduce((acc, str, index) => {
    return acc += ' '+ str.split('')
      .map((c, i)=> i===0 ? c.toUpperCase(): c)
      .join('');
  },"").trim()
}