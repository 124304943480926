import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';

import FontAwesomeIcon from '../../../components/Icons/FontAwesomeIcon';
import { TextRupiah } from '../../../components/Text';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const IconWrapper = styled.div`
  padding: 20px;
`;

class UserOption extends React.Component{
  constructor(props){
    super(props);
    this.handleMouseDown = this.handleMouseDown.bind(this);
    this.handleMouseEnter = this.handleMouseEnter.bind(this);
    this.handleMouseMove = this.handleMouseMove.bind(this);
  }
  handleMouseDown (event) {
		event.preventDefault();
		event.stopPropagation();
		this.props.onSelect(this.props.option, event);
  }
  handleMouseEnter (event) {
		this.props.onFocus(this.props.option, event);
  }
  handleMouseMove (event) {
		if (this.props.isFocused) return;
		this.props.onFocus(this.props.option, event);
  }
  render () {
		return (
			<div className={this.props.className}
				onMouseDown={this.handleMouseDown}
				onMouseEnter={this.handleMouseEnter}
				onMouseMove={this.handleMouseMove}
				title={this.props.option.email}>
        <Wrapper>
          <IconWrapper>
            <FontAwesomeIcon iconSize="2x" iconType="user"/>
          </IconWrapper>
          <div style={{ flex: 1}}>
            { this.props.option.email }
            <div style={{fontSize: ".5rem", display: 'flex', justifyContent: 'space-between'}}>
              <p>KickCr<strong><TextRupiah prefix="Rp. " value={this.props.option.balance||0}/></strong></p>
              <p>SellerCr<strong><TextRupiah prefix="Rp. " value={this.props.option.seller_balance||0}/></strong></p>
              <p>KickPt<strong><TextRupiah prefix="Rp. " value={this.props.option.kick_point||0}/></strong></p>
            </div>
          </div>
        </Wrapper>
			</div>
		);
	}
}

UserOption.propTypes = {
  option: propTypes.object,
  onFocus: propTypes.func,
  onSelect: propTypes.func,
  className: propTypes.string
}

UserOption.defaultProps = {};

export default UserOption;