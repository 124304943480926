export const APP_STAGED = process.env.REACT_APP_ENV_STAGE || "local";
export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "http://localhost";
export const FRONTEND_WEB =
    process.env.REACT_APP_FRONTEND_WEB || "http://localhost";

// Constant Variables for Auth
export const AUTH_TOKEN_KEY = Buffer.from("_token").toString("base64");
export const AUTH_USER_KEY = Buffer.from("_user").toString("base64");
export const COURIER_PRICE_KEY =
    Buffer.from("_courierPrice").toString("base64");
export const AUTH_LOGIN = "AUTH_LOGIN";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const AUTH_PROFILES = "AUTH_PROFILES";
export const AUTH_LOGIN_ERROR = "AUTH_LOGIN_ERROR";
export const AUTH_FETCH = "AUTH_FETCH";
export const AUTH_CHANGE_PASSWORD = "AUTH_CHANGE_PASSWORD";
export const AUTH_CHANGE_PASSWORD_ERROR = "AUTH_CHANGE_PASSWORD_ERROR";
export const AUTH_CHANGE_PASSWORD_SUBMIT = "AUTH_CHANGE_PASSWORD_SUBMIT";

export const AUTH_UPDATE_PROFILE = "AUTH_UPDATE_PROFILE";
export const AUTH_UPDATE_PROFILE_ERROR = "AUTH_UPDATE_PROFILE_ERROR";
export const AUTH_UPDATE_PROFILE_SUBMIT = "AUTH_UPDATE_PROFILE_SUBMIT";

export const AUTH_EXTEND_TKN = "AUTH_EXTEND_TKN";
export const AUTH_EXTEND_TKN_ERROR = "AUTH_EXTEND_TKN_ERROR";
export const AUTH_EXTEND_TKN_SUBMIT = "AUTH_EXTEND_TKN_SUBMIT";

export const AUTH_ACCESS_VALIDATED = "AUTH_ACCESS_VALIDATED";

export const TOKEN_EXPIRED = {
    message: "Token has expired",
    status_code: 401,
};

// Constant Variables for Sell Request
export const GET_LIST_USER_SELL = "GET_LIST_USER_SELL";
export const ADD_USER_SELL = "ADD_USER_SELL";
export const GET_LIST_USER_SELL_WITH_SIZE = "GET_LIST_USER_SELL_WITH_SIZE";
export const GET_DETAIL_USER_SELL = "GET_DETAIL_USER_SELL";
export const UPDATE_STATUS_USER_SELL = "UPDATE_STATUS_USER_SELL";
export const UPDATE_STATUS_USER_SELL_ERROR = "UPDATE_STATUS_USER_SELL_ERROR";
export const FETCHING_USER_SELL = "FETCHING_USER_SELL";
export const USER_SELL_ERROR = "USER_SELL_ERROR";
export const UPDATE_SELL_REQUEST_PRICE = "UPDATE_SELL_REQUEST_PRICE";
export const DELETE_USER_SELL = "DELETE_USER_SELL";
export const GET_LIST_USER_SELL_JOURNAL = "GET_LIST_USER_SELL_JOURNAL";
export const FETCHING_USER_SELL_JOURNAL = "FETCHING_USER_SELL_JOURNAL";
export const USER_SELL_JOURNAL_ERROR = "USER_SELL_JOURNAL_ERROR";
export const GET_EXPRESS_LOWEST_ASK_LOADING = "GET_EXPRESS_LOWEST_ASK_LOADING";
export const GET_EXPRESS_LOWEST_ASK_SUCCESS = "GET_EXPRESS_LOWEST_ASK_SUCCESS";
export const GET_EXPRESS_LOWEST_ASK_ERROR = "GET_EXPRESS_LOWEST_ASK_ERROR";

// Constant Variables for Seller Verification
export const SELLER_VERIFICATION_API = "/admins/seller-verifications";
export const GET_LIST_SELLER_VERIFICATION = "GET_LIST_SELLER_VERIFICATION";
export const FETCHING_SELLER_VERIFICATION = "FETCHING_SELLER_VERIFICATION";
export const SELLER_VERIFICATION_ERROR = "SELLER_VERIFICATION_ERROR";
export const GET_DETAIL_SELLER_VERIFICATION = "GET_DETAIL_SELLER_VERIFICATION";
export const UPDATE_STATUS_SELLER_VERIFICATION = "UPDATE_STATUS_SELLER_VERIFICATION";
export const UPDATE_STATUS_SELLER_VERIFICATION_ERROR = "UPDATE_STATUS_SELLER_VERIFICATION_ERROR";

// Constant Variables for Offer
export const GET_LIST_OFFER = "GET_LIST_OFFER";
export const FETCH_OFFER_ERROR = "FETCH_OFFER_ERROR";
export const GET_DETAIL_OFFER = "GET_DETAIL_OFFER";
export const OFFER_ERROR = "OFFER_ERROR";
export const FETCHING_OFFER = "FETCHING_OFFER";
export const UPDATE_OFFER = "UPDATE_OFFER";
export const CREATE_OFFER = "CREATE_OFFER";
export const DISBURSEMENT_OFFER = "DISBURSEMENT_OFFER";
export const OFFER_ROUTE_API = "/admins/offers";
export const FETCHING_PAYMENTS_MIDTRANS_DETAIL =
    "FETCHING_PAYMENTS_MIDTRANS_DETAIL";
export const PAYMENTS_MIDTRANS_DETAIL_ERROR = "PAYMENTS_MIDTRANS_DETAIL_ERROR";
export const GET_PAYMENTS_MIDTRANS_DETAIL = "GET_PAYMENTS_MIDTRANS_DETAIL";

// Constant Variables for Offer Report
export const OFFER_REPORT_ROUTE_API = "/admins/offers/report";
export const OFFER_REPORT_CLEAR = "OFFER_REPORT_CLEAR";
export const OFFER_REPORT = "OFFER_REPORT";
export const OFFER_REPORT_ERROR = "OFFER_REPORT_ERROR";

// Constant Variables for Collection
export const UPDATE_POSITION = "UPDATE_POSITION";
export const GET_LIST_COLLECTION = "GET_LIST_COLLECTION";
export const GET_DETAIL_COLLECTION = "GET_DETAIL_COLLECTION";
export const ADD_COLLECTION = "ADD_COLLECTION";
export const NEW_COLLECTION = "NEW_COLLECTION";
export const UPDATE_COLLECTION = "UPDATE_COLLECTION";
export const DELETE_COLLECTION = "DELETE_COLLECTION";
export const COLLECTION_ROUTE_API = "/admins/collections";
export const COLLECTION_ERROR = "COLLECTION_ERROR";
export const FETCHING_COLLECTION = "FETCHING_COLLECTION";
export const SUBMITING_COLLECTION = "SUBMITING_COLLECTION";
export const COLLECTION_CHILDS_REQUEST = "COLLECTION_CHILDS_REQUEST ";
export const COLLECTION_CHILDS_ERROR = "COLLECTION_CHILDS_ERROR";
export const SUBMITING_COLLECTION_CATEGORIES =
    "SUBMITING_COLLECTION_CATEGORIES";
export const GET_LIST_COLLECTION_CATEGORIES = "GET_LIST_COLLECTION_CATEGORIES";
export const SUBMITING_COLLECTION_ITEMS = "SUBMITING_COLLECTION_ITEMS";
export const GET_LIST_COLLECTION_ITEMS = "GET_LIST_COLLECTION_ITEMS";
export const SEARCH_COLLECTION_ITEMS = "SEARCH_COLLECTION_ITEMS";
export const CREATE_COLLECTION_IMAGE = "CREATE_COLLECTION_IMAGE";
export const UPDATE_COLLECTION_IMAGE = "UPDATE_COLLECTION_IMAGE";
export const DELETE_COLLECTION_IMAGE = "DELETE_COLLECTION_IMAGE";
export const GET_LIST_COLLECTION_SELLER = "GET_LIST_COLLECTION_SELLER";
export const CREATE_COLLECTION_SELLER = "CREATE_COLLECTION_SELLER";
export const DELETE_COLLECTION_SELLER = "DELETE_COLLECTION_SELLER";
export const COLLECTIONS_S3 = "COLLECTIONS_S3";

// Constant Variables for Brands
export const GET_LIST_BRAND = "GET_LIST_BRAND";
export const GET_LIST_ROOT_BRAND = "GET_LIST_ROOT_BRAND";
export const GET_DETAIL_BRAND = "GET_DETAIL_BRAND";
export const MOVE_POSITION_BRAND = "MOVE_POSITION_BRAND";
export const ADD_BRAND = "ADD_BRAND";
export const NEW_BRAND = "NEW_BRAND";
export const UPDATE_BRAND = "UPDATE_BRAND";
export const DELETE_BRAND = "DELETE_BRAND";
export const BRAND_ROUTE_API = "/admins/brands";
export const BRAND_ERROR = "BRAND_ERROR";
export const FETCHING_BRAND = "FETCHING_BRAND";
export const SUBMITING_BRAND = "SUBMITING_BRAND";
export const GET_OPTION_LIST_BRAND = "GET_OPTION_LIST_BRAND";
export const BRAND_IMAGE_S3 = "BRAND_IMAGE_S3";
export const CREATE_BRAND_IMAGE = "CREATE_BRAND_IMAGE";
export const UPDATE_BRAND_IMAGE = "UPDATE_BRAND_IMAGE";
export const DELETE_BRAND_IMAGE = "DELETE_BRAND_IMAGE";
export const BRAND_IMAGE_REQUEST = "BRAND_IMAGE_REQUEST";
export const BRAND_IMAGE_ERROR = "BRAND_IMAGE_ERROR";
export const UPDATE_POPULAR_BRANDS = "UPDATE_POPULAR_BRANDS";
export const UPDATE_POPULAR_BRANDS_ERROR = "UPDATE_POPULAR_BRANDS_ERROR";
export const UPDATE_POPULAR_BRANDS_SUBMIT = "UPDATE_POPULAR_BRANDS_SUBMIT";
export const GET_POPULAR_BRANDS = "GET_POPULAR_BRANDS";
export const GET_POPULAR_BRANDS_ERROR = "GET_POPULAR_BRANDS_ERROR";
export const GET_POPULAR_BRANDS_FETCH = "GET_POPULAR_BRANDS_FETCH";
export const FIX_BRAND = "FIX_BRAND";
export const FIX_BRAND_ERROR = "FIX_BRAND_ERROR";
export const FIX_BRAND_SUBMIT = "FIX_BRAND_SUBMIT";
export const ATTACH_VOUCHER_BRANDS = "ATTACH_VOUCHER_BRANDS";
export const DETACH_VOUCHER_BRANDS = "DETACH_VOUCHER_BRANDS";

// Constant Variables for Categories
export const GET_LIST_CATEGORIES = "GET_LIST_CATEGORIES";
export const GET_DETAIL_CATEGORIES = "GET_DETAIL_CATEGORIES";
export const ADD_CATEGORIES = "ADD_CATEGORIES";
export const NEW_CATEGORIES = "NEW_CATEGORIES";
export const UPDATE_CATEGORIES = "UPDATE_CATEGORIES";
export const DELETE_CATEGORIES = "DELETE_CATEGORIES";
export const CATEGORIES_ROUTE_API = "/admins/categories";
export const CATEGORIES_ERROR = "CATEGORIES_ERROR";
export const FETCHING_CATEGORIES = "FETCHING_CATEGORIES";
export const SUBMITING_CATEGORIES = "SUBMITING_CATEGORIES";
export const GET_OPTION_LIST_CATEGORIES = "GET_OPTION_LIST_CATEGORIES";

// Constant Variables for Bank Promo
export const GET_LIST_BANK_PROMO = "GET_LIST_BANK_PROMO";
export const ADD_BANK_PROMO = "ADD_BANK_PROMO";
export const UPDATE_BANK_PROMO = "UPDATE_BANK_PROMO";
export const DELETE_BANK_PROMO = "DELETE_BANK_PROMO";
export const GET_BANK_PROMO_BY_ID = "GET_BANK_PROMO_BY_ID";
export const FETCHING_BANK_PROMOTION = "FETCHING_BANK_PROMOTION";
export const SUBMITTING_BANK_PROMOTION = "SUBMITTING_BANK_PROMOTION";
export const BANK_PROMO_ERROR = "BANK_PROMO_ERROR";
export const DELETTING_BANK_PROMO = "DELETTING_BANK_PROMO"
export const BANK_PROMO_SET_DELETE_MODAL_OPEN = "BANK_PROMO_SET_DELETE_MODAL_OPEN"
export const BANK_PROMO_ROUTE_API = '/admins/bank-promotions'

// Constant Variables for Meta
export const META_ROUTE_API = "/admins/meta";
export const GET_LIST_META = "GET_LIST_META";
export const GET_DETAIL_META = "GET_DETAIL_META";
export const ADD_META = "ADD_META";
export const NEW_META = "NEW_META";
export const UPDATE_META = "UPDATE_META";
export const DELETE_META = "DELETE_META";
export const META_ERROR = "META_ERROR";
export const FETCHING_META = "FETCHING_META";
export const SUBMITING_META = "SUBMITING_META";
export const GET_META_OPTION_LIST = "GET_META_OPTION_LIST";

// Constant Variables for UPLOAD IMG TO S3
export const UPLOAD_IMAGES = "UPLOAD_IMAGES";
export const UPLOADING_IMAGES = "UPLOADING_IMAGES";
export const UPLOADING_IMAGES_ERROR = "UPLOADING_IMAGES_ERROR";
export const UPLOAD_IMAGES_API = "UPLOAD_IMAGES_API";

export const DELETING_OBJECT_S3_PROCESS = "DELETING_OBJECT_S3_PROCESS";
export const DELETING_OBJECT_S3_DONE = "DELETING_OBJECT_S3_DONE";
export const DELETING_OBJECT_S3_ERROR = "DELETING_OBJECT_S3_ERROR";
export const CLEAR_IMAGE_UPLOAD = "CLEAR_IMAGE_UPLOAD";
export const DELETING_IMAGE_REQUEST = "DELETING_IMAGE_REQUEST";
export const DELETING_IMAGE_API = "DELETING_IMAGE_API";
export const DELETING_IMAGE_ERROR = "DELETING_IMAGE_ERROR";

// Constant Variables for Products
export const GET_LIST_PRODUCT = "GET_LIST_PRODUCT";
export const GET_DETAIL_PRODUCT = "GET_DETAIL_PRODUCT";
export const ADD_PRODUCT = "ADD_PRODUCT";
export const NEW_PRODUCT = "NEW_PRODUCT";
export const UPDATE_PRODUCT = "UPDATE_PRODUCT";
export const DELETE_PRODUCT = "DELETE_PRODUCT";
export const PRODUCT_ROUTE_API = "/admins/products";
export const PRODUCT_ERROR = "PRODUCT_ERROR";
export const FETCHING_PRODUCT = "FETCHING_PRODUCT";
export const SUBMITING_PRODUCT = "SUBMITING_PRODUCT";
export const GET_OPTION_LIST_PRODUCT = "GET_OPTION_LIST_PRODUCT";

// Constant Variables for Product Variants
export const GET_LIST_PRODUCT_VARIANT = "GET_LIST_PRODUCT_VARIANT";
export const GET_DETAIL_PRODUCT_VARIANT = "GET_DETAIL_PRODUCT_VARIANT";
export const ADD_PRODUCT_VARIANT = "ADD_PRODUCT_VARIANT";
export const NEW_PRODUCT_VARIANT = "NEW_PRODUCT_VARIANT";
export const UPDATE_PRODUCT_VARIANT = "UPDATE_PRODUCT_VARIANT";
export const DELETE_PRODUCT_VARIANT = "DELETE_PRODUCT_VARIANT";
export const PRODUCT_VARIANT_ROUTE_API = "/admins/productvariants";
export const PRODUCT_VARIANT_ERROR = "PRODUCT_VARIANT_ERROR";
export const FETCHING_PRODUCT_VARIANT = "FETCHING_PRODUCT_VARIANT";
export const SUBMITING_PRODUCT_VARIANT = "SUBMITING_PRODUCT_VARIANT";
export const ATTACH_COLLECTION_PRODUCT_VARIANT =
    "ATTACH_COLLECTION_PRODUCT_VARIANT";
export const DETACH_COLLECTION_PRODUCT_VARIANT =
    "DETACH_COLLECTION_PRODUCT_VARIANT";
export const ATTACH_SIZE_PRODUCT_VARIANT = "ATTACH_SIZE_PRODUCT_VARIANT";
export const DETACH_SIZE_PRODUCT_VARIANT = "DETACH_SIZE_PRODUCT_VARIANT";
export const ADD_IMAGE_PRODUCT_VARIANT = "ADD_IMAGE_PRODUCT_VARIANT";
export const DELETE_IMAGE_PRODUCT_VARIANT = "DELETE_IMAGE_PRODUCT_VARIANT";
export const GET_DETAIL_PRODUCT_VARIANT_STOCK_SIZE =
    "GET_DETAIL_PRODUCT_VARIANT_STOCK_SIZE";
export const SAVE_VARIANT_COURIER = "SAVE_VARIANT_COURIER";
export const SAVE_VARIANT_PAYMENT_METHOD = "SAVE_VARIANT_PAYMENT_METHOD";
export const ATTACH_VOUCHER_PRODUCT_VARIANT = "ATTACH_VOUCHER_PRODUCT_VARIANT";
export const DETACH_VOUCHER_PRODUCT_VARIANT = "DETACH_VOUCHER_PRODUCT_VARIANT";

// Constant Variables for Sale
export const REPORT_ROUTE_API = "/admins/sales/report";
export const REPORT_CLEAR = "REPORT_CLEAR";
export const REPORT_SALE = "REPORT_SALE";
export const REPORT_SALE_ERROR = "REPORT_SALE_ERROR";

// Constant Variables for Sale
export const GET_LIST_SALE = "GET_LIST_SALE";
export const GET_LIST_PROCESSED_SALE = "GET_LIST_PROCESSED_SALE";

export const GET_LIST_PENDING_PROCESSED_SALE =
    "GET_LIST_PENDING_PROCESSED_SALE";
export const FETCHING_PENDING_PROCESSED_SALE =
    "FETCHING_PENDING_PROCESSED_SALE";
export const PENDING_PROCESSED_SALE_ERROR = "PENDING_PROCESSED_SALE_ERROR";

export const GET_LIST_COMPLETED_SALE = "GET_LIST_COMPLETED_SALE";
export const FETCHING_COMPLETED_SALE = "FETCHING_COMPLETED_SALE";
export const COMPLETED_SALE_ERROR = "COMPLETED_SALE_ERROR";

export const GET_LIST_DISBURSEMENT_SALE = "GET_LIST_DISBURSEMENT_SALE";
export const FETCHING_DISBURSEMENT_SALE = "FETCHING_DISBURSEMENT_SALE";
export const DISBURSEMENT_SALE_ERROR = "DISBURSEMENT_SALE_ERROR";

export const GET_FOLLOWUP_SALES = "GET_FOLLOWUP_SALES";
export const UPDATE_FOLLOWUP_SALES = "UPDATE_FOLLOWUP_SALES";
export const FETCH_FOLLOWUP_SALES = "FETCH_FOLLOWUP_SALES";
export const GET_FOLLOWUP_APPROVAL_SALES = "GET_FOLLOWUP_APPROVAL_SALES";
export const FETCH_FOLLOWUP_APPROVAL_SALES = "FETCH_FOLLOWUP_APPROVAL_SALES";

export const GET_DETAIL_SALE = "GET_DETAIL_SALE";
export const ADD_SALE = "ADD_SALE";
export const NEW_SALE = "NEW_SALE";
export const UPDATE_SALE = "UPDATE_SALE";
export const SELLER_ACCEPT_SALE = "SELLER_ACCEPT_SALE";
export const SELLER_DECLINE_SALE = "SELLER_DECLINE_SALE";
export const ADMIN_RECEIVED_SNEAKER_SALE = "ADMIN_RECEIVED_SNEAKER_SALE";
export const ADMIN_DELIVERING_SNEAKER_SALE = "ADMIN_DELIVERING_SNEAKER_SALE";
export const LEGIT_PASSED_SALE = "LEGIT_PASSED_SALE";
export const LEGIT_FAILED_SALE = "LEGIT_FAILED_SALE";
export const COMPLETION_BY_ADMIN_SALE = "COMPLETION_BY_ADMIN_SALE";
export const DELETE_SALE = "DELETE_SALE";
export const SALE_ROUTE_API = "/admins/sales";
export const CONSIGNMENT_REPORT_ROUTE_API = "/admins/sell-consignments/report";
export const SALE_ERROR = "SALE_ERROR";
export const FETCHING_SALE = "FETCHING_SALE";
export const SUBMITING_SALE = "SUBMITING_SALE";
export const DISBURSEMENT_TO_BUYER_SALE = "DISBURSEMENT_TO_BUYER_SALE";
export const DISBURSEMENT_TO_SELLER_SALE = "DISBURSEMENT_TO_SELLER_SALE";
export const AUTODISBURSEMENT_TO_SELLER_SALE =
    "AUTODISBURSEMENT_TO_SELLER_SALE";
export const UPDATE_AWB_NUMBER_SALE = "UPDATE_AWB_NUMBER_SALE";
export const UPDATE_HIJACKED_SALE = "UPDATE_HIJACKED_SALE";
export const UPDATE_FORCE_CANCELLED_SALE = "UPDATE_FORCE_CANCELLED_SALE";
export const ROLLBACK_SALE = "ROLLBACK_SALE";
export const GET_LIST_OUTSTANDING_CONFIRMATION =
    "GET_LIST_OUTSTANDING_CONFIRMATION";
export const FETCHING_OUTSTANDING_CONFIRMATION =
    "FETCHING_OUTSTANDING_CONFIRMATION";
export const OUTSTANDING_CONFIRMATION_ERROR = "OUTSTANDING_CONFIRMATION_ERROR";
export const GET_LIST_OUTSTANDING_DELIVERY = "GET_LIST_OUTSTANDING_DELIVERY";
export const FETCHING_OUTSTANDING_DELIVERY = "FETCHING_OUTSTANDING_DELIVERY";
export const OUTSTANDING_DELIVERY_ERROR = "OUTSTANDING_DELIVERY_ERROR";
export const SALE_IMAGE_S3 = "SALE_IMAGE_S3";
export const SALE_IMAGES_ERROR = "SALE_IMAGES_ERROR";
export const SALE_IMAGES_REQUEST = "SALE_IMAGES_REQUEST";
export const GET_SALE_IMAGES = "GET_SALE_IMAGES";
export const CREATE_SALE_IMAGES = "CREATE_SALE_IMAGES";
export const DELETE_SALE_IMAGES = "DELETE_SALE_IMAGES";
export const FETCHING_SALE_NOTES = "FETCHING_SALE_NOTES";
export const SALE_NOTES_SUBMIT = "SALE_NOTES_SUBMIT";
export const GET_SALE_NOTES = "GET_SALE_NOTES";
export const READ_SALE_NOTES = "READ_SALE_NOTES";
export const FETCHING_DETAILS_SALE = "FETCHING_DETAILS_SALE";
export const GET_DETAIL_SALE_2 = "GET_DETAIL_SALE_2";

// Constant Variables for Consignment
export const CONSIGNMENT_ROUTE_API = "/admins/sell-consignments";
export const GET_LIST_CONSIGNMENT = "GET_LIST_CONSIGNMENT";
export const FETCHING_CONSIGNMENT = "FETCHING_CONSIGNMENT";
export const CONSIGNMENT_ERROR = "CONSIGNMENT_ERROR";
export const GET_DETAIL_CONSIGNMENT = "GET_DETAIL_CONSIGNMENT";
export const RACK_REQUEST_CONSIGNMENT = "RACK_REQUEST_CONSIGNMENT";
export const CREATE_RACK_CONSIGNMENT = "CREATE_RACK_CONSIGNMENT";
export const RACK_ERROR_CONSIGNMENT = "RACK_ERROR_CONSIGNMENT";
export const UPDATE_FORCE_CANCELLED_CONSIGNMENT = "UPDATE_FORCE_CANCELLED_CONSIGNMENT";
export const ADMIN_DELIVERING_SNEAKER_CONSIGNMENT = "ADMIN_DELIVERING_SNEAKER_CONSIGNMENT";
export const UPDATE_CONSIGNMENT = "UPDATE_CONSIGNMENT";
export const ROLLBACK_CONSIGNMENT = "ROLLBACK_CONSIGNMENT";
export const FETCHING_CONSIGNMENT_NOTES = "FETCHING_CONSIGNMENT_NOTES";
export const GET_CONSIGNMENT_NOTES = "GET_CONSIGNMENT_NOTES";
export const READ_CONSIGNMENT_NOTES = "READ_CONSIGNMENT_NOTES";
export const CONSIGNMENT_NOTES_SUBMIT = "CONSIGNMENT_NOTES_SUBMIT";

export const GET_LIST_SIZE = "GET_LIST_SIZE";
export const GET_DETAIL_SIZE = "GET_DETAIL_SIZE";
export const SIZE_ROUTE_API = "/admins/sizes";
export const SIZE_ERROR = "SIZE_ERROR";
export const FETCHING_SIZE = "FETCHING_SIZE";
export const GET_LIST_BRAND_SIZE = "GET_LIST_BRAND_SIZE";
export const BRAND_SIZE_ROUTE_API = "/admins/brand_sizes";

export const GET_GENERAL_SIZE_LIST = "GET_GENERAL_SIZE_LIST";
export const GENERAL_SIZE_LIST_ERROR = "GENERAL_SIZE_LIST_ERROR";

export const PROCESS_CHANGED_IMAGE_SELL = "PROCESS_CHANGED_IMAGE_SELL";
export const PROCESS_IMAGE_SELL_MODIFIED_STATE = "ADD_OR_MODIFIED_PROCESSED";
export const PROCESS_IMAGE_SELL_DELETED_STATE = "DELETE_PROCESSED";
export const UPLOADING_IMAGE_SELL = "UPLOADING_IMAGE_SELL";
export const CHANGING_IMAGE_SELL = "CHANGING_IMAGE_SELL";
export const DELETE_IMAGES_SELL = "DELETE_IMAGES_SELL";
export const IMAGE_SELL_S3_ERROR = "IMAGE_SELL_S3_ERROR";
export const IMAGE_SELL_ERROR = "IMAGE_SELL_ERROR";

export const DISBURSEMENT_USER_ROUTE_API = "/admins/disbursements";
export const DISBURSEMENT_USER_LIST = "DISBURSEMENT_USER_LIST";
export const DISBURSEMENT_USER_LIST_FETCH = "DISBURSEMENT_USER_LIST_FETCH";
export const DISBURSEMENT_USER_LIST_ERROR = "DISBURSEMENT_USER_LIST_ERROR";
export const PENDING_DISBURSEMENT_USER_LIST = "PENDING_DISBURSEMENT_USER_LIST";
export const PENDING_DISBURSEMENT_USER_LIST_FETCH =
    "PENDING_DISBURSEMENT_USER_LIST_FETCH";
export const PENDING_DISBURSEMENT_USER_LIST_ERROR =
    "PENDING_DISBURSEMENT_USER_LIST_ERROR";
export const COMPLETED_DISBURSEMENT_USER_LIST =
    "COMPLETED_DISBURSEMENT_USER_LIST";
export const COMPLETED_DISBURSEMENT_USER_LIST_FETCH =
    "COMPLETED_DISBURSEMENT_USER_LIST_FETCH";
export const COMPLETED_DISBURSEMENT_USER_LIST_ERROR =
    "COMPLETED_DISBURSEMENT_USER_LIST_ERROR";
export const GET_DETAIL_DISBURSEMENT_USER = "GET_DETAIL_DISBURSEMENT_USER";
export const GET_DETAIL_DISBURSEMENT_USER_FETCH =
    "GET_DETAIL_DISBURSEMENT_USER_FETCH";
export const GET_DETAIL_DISBURSEMENT_USER_ERROR =
    "GET_DETAIL_DISBURSEMENT_USER_ERROR";
export const DELETE_DETAIL_DISBURSEMENT_USER =
    "DELETE_DETAIL_DISBURSEMENT_USER";
export const DELETE_DETAIL_DISBURSEMENT_USER_SUMBIT =
    "DELETE_DETAIL_DISBURSEMENT_USER_SUMBIT";
export const DELETE_DETAIL_DISBURSEMENT_USER_ERROR =
    "DELETE_DETAIL_DISBURSEMENT_USER_ERROR";
export const ADD_DETAIL_DISBURSEMENT_USER = "ADD_DETAIL_DISBURSEMENT_USER";
export const ADD_DETAIL_DISBURSEMENT_USER_SUBMIT =
    "ADD_DETAIL_DISBURSEMENT_USER_SUBMIT";
export const ADD_DETAIL_DISBURSEMENT_USER_ERROR =
    "ADD_DETAIL_DISBURSEMENT_USER_ERROR";
export const UPDATE_DETAIL_DISBURSEMENT_USER =
    "UPDATE_DETAIL_DISBURSEMENT_USER";
export const UPDATE_DETAIL_DISBURSEMENT_USER_SUBMIT =
    "UPDATE_DETAIL_DISBURSEMENT_USER_SUBMIT";
export const UPDATE_DETAIL_DISBURSEMENT_USER_ERROR =
    "UPDATE_DETAIL_DISBURSEMENT_USER_ERROR";
export const MIDTRANS_BANK_ACCOUNT_ROUTE_API = "/beneficiary-banks";
export const MIDTRANS_BANK_ACCOUNT_LIST = "MIDTRANS_BANK_ACCOUNT_LIST";
export const MIDTRANS_BANK_ACCOUNT_FETCH = "MIDTRANS_BANK_ACCOUNT_FETCH";
export const MIDTRANS_BANK_ACCOUNT_ERROR = "MIDTRANS_BANK_ACCOUNT_ERROR";
export const CASHOUT_FEE_FETCH = "CASHOUT_FEE_FETCH";
export const CASHOUT_FEE_ERROR = "CASHOUT_FEE_ERROR";
export const CASHOUT_FEE_LIST = "CASHOUT_FEE_LIST";
export const CASHOUT_FEE_SETTING_FETCH = "CASHOUT_FEE_SETTING_FETCH";
export const CASHOUT_FEE_SETTING_ERROR = "CASHOUT_FEE_SETTING_ERROR";
export const CASHOUT_FEE_SETTING = "CASHOUT_FEE_SETTING";
export const CASHOUT_FEE_ROUTE_API = "/admins/disbursements/generate-fees";
export const DISBURSEMENT_REPORT_CLEAR = "DISBURSEMENT_REPORT_CLEAR";
export const DISBURSEMENT_REPORT = "DISBURSEMENT_REPORT";
export const DISBURSEMENT_REPORT_ERROR = "DISBURSEMENT_REPORT_ERROR";
export const DISBURSEMENT_REPORT_FETCH = "DISBURSEMENT_REPORT_FETCH";
export const DISBURSEMENT_REPORT_ROUTE_API = "/admins/disbursements/report";

// Constant Variables for User
export const USER_LIST_FETCH = "USER_LIST_FETCH";
export const USER_DETAIL_FETCH = "USER_DETAIL_FETCH";
export const USER_ADD = "USER_ADD";
export const USER_UPDATE = "USER_UPDATE";
export const USER_DELETE = "USER_DELETE";
export const USER_ROUTE_API = "/admins/users";
export const USER_ERROR = "USER_ERROR";
export const USER_FETCHING = "USER_FETCHING";
export const USER_SUBMITING = "USER_SUBMITING";
export const USER_FETCHING_ROLES = "USER_FETCHING_ROLES";
export const USER_ROLES_FETCH = "USER_ROLES_FETCH";
export const USER_SELLER_VERIFICATIONS_FETCH =
    "USER_SELLER_VERIFICATIONS_FETCH";
export const USER_FETCHING_SELLER_VERIFICATIONS =
    "USER_FETCHING_SELLER_VERIFICATIONS";
export const USER_CLEAR_FORM = "USER_CLEAR_FORM";
export const USER_BALANCE_LOG_FETCH = "USER_BALANCE_LOG_FETCH";
export const USER_BALANCE_LOG_LIST = "USER_BALANCE_LOG_LIST";
export const USER_BALANCE_LOG_ERROR = "USER_BALANCE_LOG_ERROR";
export const SELLER_POINT_HISTORY_FETCH = "SELLER_POINT_HISTORY_FETCH";
export const SELLER_POINT_HISTORY_LIST = "SELLER_POINT_HISTORY_LIST";
export const SELLER_POINT_HISTORY_ERROR = "SELLER_POINT_HISTORY_ERROR";
export const USER_SHIPPING_FETCH = "USER_SHIPPING_FETCH";
export const USER_SHIPPING_LIST = "USER_SHIPPING_LIST";
export const USER_SHIPPING_ERROR = "USER_SHIPPING_ERROR";
export const USER_SHIPPING_SAVE_SUBMIT = "USER_SHIPPING_SAVE_SUBMIT";
export const USER_SHIPPING_SAVE = "USER_SHIPPING_SAVE";
export const USER_SHIPPING_SAVE_ERROR = "USER_SHIPPING_SAVE_ERROR";
export const USER_SHIPPING_UPDATE_SUBMIT = "USER_SHIPPING_UPDATE_SUBMIT";
export const USER_SHIPPING_UPDATE = "USER_SHIPPING_UPDATE";
export const USER_SHIPPING_UPDATE_ERROR = "USER_SHIPPING_UPDATE_ERROR";

export const USER_COMMISSION_RATE = "USER_COMMISSION_RATE";
export const USER_COMMISSION_RATE_SUBMIT = "USER_COMMISSION_RATE_SUBMIT";
export const USER_COMMISSION_RATE_ERROR = "USER_COMMISSION_RATE_ERROR";

export const USER_STOCK_EXPORT = "USER_STOCK_EXPORT";
export const USER_STOCK_EXPORT_SUBMIT = "USER_STOCK_EXPORT_SUBMIT";
export const USER_STOCK_EXPORT_ERROR = "USER_STOCK_EXPORT_ERROR";

export const USER_DETAIL_SIZES_LIST_FETCHING =
    "USER_DETAIL_SIZES_LIST_FETCHING";
export const USER_DETAIL_SIZES_LIST_FETCHED = "USER_DETAIL_SIZES_LIST_FETCHED";
export const USER_DETAIL_SIZES_LIST_ADDED = "USER_DETAIL_SIZES_LIST_ADDED";
export const USER_DETAIL_SIZES_LIST_UPDATED = "USER_DETAIL_SIZES_LIST_UPDATED";
export const USER_DETAIL_SIZES_LIST_ERROR = "USER_DETAIL_SIZES_LIST_ERROR";

// Constant Variables for Account Bank
export const ACCOUNT_BANK_LIST_FETCH = "ACCOUNT_BANK_LIST_FETCH";
export const ACCOUNT_BANK_DETAIL_FETCH = "ACCOUNT_BANK_DETAIL_FETCH";
export const ACCOUNT_BANK_ADD = "ACCOUNT_BANK_ADD";
export const ACCOUNT_BANK_UPDATE = "ACCOUNT_BANK_UPDATE";
export const ACCOUNT_BANK_DELETE = "ACCOUNT_BANK_DELETE";
export const ACCOUNT_BANK_ROUTE_API = "/admins/bank_accounts";
export const ACCOUNT_BANK_ERROR = "ACCOUNT_BANK_ERROR";
export const ACCOUNT_BANK_FETCHING = "ACCOUNT_BANK_FETCHING";
export const ACCOUNT_BANK_SUBMITING = "ACCOUNT_BANK_SUBMITING";
export const ACCOUNT_BANK_CLEAR_FORM = "ACCOUNT_BANK_CLEAR_FORM";

// Constant Variables for Role
export const ROLE_LIST_FETCH = "ROLE_LIST_FETCH";
export const ROLE_DETAIL_FETCH = "ROLE_DETAIL_FETCH";
export const ROLE_ADD = "ROLE_ADD";
export const ROLE_UPDATE = "ROLE_UPDATE";
export const ROLE_DELETE = "ROLE_DELETE";
export const ROLE_ROUTE_API = "/admins/roles";
export const ROLE_ERROR = "ROLE_ERROR";
export const ROLE_FETCHING = "ROLE_FETCHING";
export const ROLE_SUBMITING = "ROLE_SUBMITING";
export const ROLE_CLEAR_FORM = "ROLE_CLEAR_FORM";

//Constant Variables for Bulk Upload
export const BULK_PRODUCT_SUBMIT = "BULK_PRODUCT_SUBMIT";
export const BULK_PRODUCT_ERROR = "BULK_PRODUCT_ERROR";
export const BULK_PRODUCT = "BULK_PRODUCT";
export const BULK_PRODUCT_UPLOAD_SUBMIT = "BULK_PRODUCT_UPLOAD_SUBMIT";
export const BULK_PRODUCT_UPLOAD = "BULK_PRODUCT_UPLOAD";
export const BULK_PRODUCT_ROUTE_API = "/admins/bulk/";

//Constant Variables for Bulk Stock Upload
export const BULK_STOCK_SUBMIT = "BULK_STOCK_SUBMIT";
export const BULK_STOCK_ERROR = "BULK_STOCK_ERROR";
export const BULK_STOCK = "BULK_STOCK";
export const BULK_STOCK_UPLOAD_SUBMIT = "BULK_STOCK_UPLOAD_SUBMIT";
export const BULK_STOCK_UPLOAD = "BULK_STOCK_UPLOAD";
export const BULK_STOCK_ROUTE_API = "/admins/bulk/prices";

//Constant Variables for Bulk Upload
export const VOUCHER_LIST_FETCH = "VOUCHER_LIST_FETCH";
export const VOUCHER_DETAIL_FETCH = "VOUCHER_DETAIL_FETCH";
export const VOUCHER_ADD = "VOUCHER_ADD";
export const VOUCHER_DELETE = "VOUCHER_DELETE";
export const VOUCHER_UPDATE = "VOUCHER_UPDATE";
export const VOUCHER_ERROR = "VOUCHER_ERROR";
export const VOUCHER_FETCHING = "VOUCHER_FETCHING";
export const VOUCHER_CLEAR_FORM = "VOUCHER_CLEAR_FORM";
export const TOTAL_USAGE_VOUCHER_FETCH = "TOTAL_USAGE_VOUCHER_FETCH";
export const TOTAL_USAGE_VOUCHER_DONE = "TOTAL_USAGE_VOUCHER_DONE";
export const TOTAL_USAGE_VOUCHER_FETCH_ERROR =
    "TOTAL_USAGE_VOUCHER_FETCH_ERROR";
export const VOUCHER_USERS_FETCH = "VOUCHER_USERS_FETCH";
export const VOUCHER_USERS_DONE = "VOUCHER_USERS_DONE";
export const VOUCHER_USERS_FETCH_ERROR = "VOUCHER_USERS_FETCH_ERROR";
export const VOUCHER_ROUTE_API = "/admins/vouchers";
export const VOUCHER_USERS_POST = "VOUCHER_USERS_POST";
export const VOUCHER_USERS_UPDATE = "VOUCHER_USERS_UPDATE";
export const VOUCHER_USERS_DELETE = "VOUCHER_USERS_DELETE";
export const VOUCHER_USERS_ERROR = "VOUCHER_USERS_ERROR";
export const VOUCHER_S3 = "VOUCHER_S3";
export const VOUCHER_S3_ERROR = "VOUCHER_S3_ERROR";
export const VOUCHER_IMAGE_REQUEST = "VOUCHER_IMAGE_REQUEST";
export const CREATE_VOUCHER_IMAGE = "CREATE_VOUCHER_IMAGE";
export const UPDATE_VOUCHER_IMAGE = "UPDATE_VOUCHER_IMAGE";
export const VOUCHER_IMAGE_ERROR = "VOUCHER_IMAGE_ERROR";
export const DELETE_VOUCHER_IMAGE = "DELETE_VOUCHER_IMAGE";

export const VOUCHER_CATEGORY_REQUEST = "VOUCHER_CATEGORY_REQUEST";
export const VOUCHER_CATEGORY_LIST = "VOUCHER_CATEGORY_LIST";
export const CREATE_VOUCHER_CATEGORY = "CREATE_VOUCHER_CATEGORY";
export const VOUCHER_CATEGORY_ERROR = "VOUCHER_CATEGORY_ERROR";
export const SEARCH_VOUCHER_ITEMS = "SEARCH_VOUCHER_ITEMS";
export const GET_LIST_VOUCHER_ITEMS = "GET_LIST_VOUCHER_ITEMS";
export const GET_LIST_VOUCHER_BRANDS = "GET_LIST_VOUCHER_BRANDS";
export const VOUCHER_CHILDS_REQUEST = "VOUCHER_CHILDS_REQUEST";
export const VOUCHER_CHILDS_ERROR = "VOUCHER_CHILDS_ERROR";
export const SUBMITING_VOUCHER_ITEMS = "SUBMITING_VOUCHER_ITEMS";
export const SUBMITING_VOUCHER_BRANDS = "SUBMITING_VOUCHER_BRANDS";
export const SAVE_VOUCHER_PAYMENT_METHOD = "SAVE_VOUCHER_PAYMENT_METHOD";

//Constant Variables for reward
export const REWARD_LIST_FETCH = "REWARD_LIST_FETCH";
export const REWARD_ITEM_FETCH = "REWARD_ITEM_FETCH";
export const REWARD_ITEM_LIST = "REWARD_ITEM_LIST";
export const REWARD_ITEM_ERROR = "REWARD_ITEM_ERROR";
export const REWARD_DETAIL_FETCH = "REWARD_DETAIL_FETCH";
export const REWARD_ADD = "REWARD_ADD";
export const REWARD_UPDATE = "REWARD_UPDATE";
export const REWARD_DELETE = "REWARD_DELETE";
export const REWARD_ERROR = "REWARD_ERROR";
export const REWARD_FETCHING = "REWARD_FETCHING";
export const REWARD_CLEAR_FORM = "REWARD_CLEAR_FORM";
export const REWARD_ROUTE_API = "/admins/rewards";

export const USER_BANK_ACCOUNT_LIST_FETCH = "USER_BANK_ACCOUNT_LIST_FETCH";
export const USER_BANK_ACCOUNT_LIST_DONE = "USER_BANK_ACCOUNT_LIST_DONE";
export const USER_BANK_ACCOUNT_LIST_FETCH_ERROR =
    "USER_BANK_ACCOUNT_LIST_FETCH_ERROR";
export const USER_BANK_ACCOUNT_DETAIL_FETCH = "USER_BANK_ACCOUNT_DETAIL_FETCH";
export const USER_BANK_ACCOUNT_DETAIL_DONE = "USER_BANK_ACCOUNT_DETAIL_DONE";
export const USER_BANK_ACCOUNT_DETAIL_FETCH_ERROR =
    "USER_BANK_ACCOUNT_DETAIL_FETCH_ERROR";
export const USER_BANK_ACCOUNT_DETAIL_SUBMIT =
    "USER_BANK_ACCOUNT_DETAIL_SUBMIT";
export const USER_BANK_ACCOUNT_DETAIL_UPDATED =
    "USER_BANK_ACCOUNT_DETAIL_UPDATED";
export const USER_BANK_ACCOUNT_DETAIL_DELETED =
    "USER_BANK_ACCOUNT_DETAIL_DELETED";
export const USER_BANK_ACCOUNT_DETAIL_SUBMIT_ERROR =
    "USER_BANK_ACCOUNT_DETAIL_SUBMIT_ERROR";

// Constant Variables for Sliders
export const GET_LIST_SLIDER = "GET_LIST_SLIDER";
export const GET_LIST_ROOT_SLIDER = "GET_LIST_ROOT_SLIDER";
export const GET_DETAIL_SLIDER = "GET_DETAIL_SLIDER";
export const ADD_SLIDER = "ADD_SLIDER";
export const NEW_SLIDER = "NEW_SLIDER";
export const UPDATE_SLIDER = "UPDATE_SLIDER";
export const UPLOAD_SLIDER_S3 = "UPLOAD_SLIDER_S3";
export const DELETE_SLIDER = "DELETE_SLIDER";
export const DELETE_SLIDER_S3 = "DELETE_SLIDER_S3";
export const SLIDER_ROUTE_API = "/admins/sliders";
export const SLIDER_ERROR = "SLIDER_ERROR";
export const FETCHING_SLIDER = "FETCHING_SLIDER";
export const SUBMITING_SLIDER = "SUBMITING_SLIDER";
export const GET_OPTION_LIST_SLIDER = "GET_OPTION_LIST_SLIDER";
export const SLIDE_ERROR = "SLIDE_ERROR";
export const SLIDER_S3_ERROR = "SLIDER_S3_ERROR";

export const GET_LIST_SLIDER_IMGS = "GET_LIST_SLIDER_IMGS";
export const SLIDER_IMGS_FETCH = "SLIDER_IMGS_FETCH";
export const SLIDER_IMGS_SUBMIT = "SLIDER_IMGS_SUBMIT";
export const SLIDER_IMGS_ERROR = "SLIDER_IMGS_ERROR";
export const ADD_SLIDER_IMGS = "ADD_SLIDER_IMGS";
export const DELETE_SLIDER_IMGS = "DELETE_SLIDER_IMGS";

// Constant Variables for TopUp
export const GET_LIST_TOP_UP = "GET_LIST_TOP_UP";
export const FETCH_TOP_UP_ERROR = "FETCH_TOP_UP_ERROR";
export const GET_DETAIL_TOP_UP = "GET_DETAIL_TOP_UP";
export const FETCHING_TOP_UP = "FETCHING_TOP_UP";
export const UPDATE_TOP_UP = "UPDATE_TOP_UP";
export const TOP_UP_ERROR = "TOP_UP_ERROR";
export const TOP_UP_ROUTE_API = "/admins/top-up";

// Constant Variables for Stock
export const GET_LIST_STOCK = "GET_LIST_STOCK";
export const STOCK_ROUTE_API = "/admins/stock";
export const STOCK_KEYWORD_PARAMS = "STOCK_KEYWORD_PARAMS";
export const GET_LIST_STOCK_ERROR = "GET_LIST_STOCK_ERROR";
export const GET_LIST_STOCK_FETCHING = "GET_LIST_STOCK_FETCHING";
export const GET_LIST_STOCK_SIZES = "GET_LIST_STOCK_SIZES";
export const GET_LIST_STOCK_SIZES_ERROR = "GET_LIST_STOCK_SIZES_ERROR";
export const GET_LIST_STOCK_SIZES_FETCHING = "GET_LIST_STOCK_SIZES_FETCHING";
export const GET_LIST_STOCK_USER_SELL = "GET_LIST_STOCK_USER_SELL";
export const GET_LIST_STOCK_USER_SELL_ERROR = "GET_LIST_STOCK_USER_SELL_ERROR";
export const GET_LIST_STOCK_USER_SELL_FETCHING =
    "GET_LIST_STOCK_USER_SELL_FETCHING";
export const REPORT_STOCK_API = "/admins/stocks/report";
export const SUBMIT_STOCK = "SUBMIT_STOCK";
export const REPORT_STOCK = "REPORT_STOCK";
export const REPORT_STOCK_ERROR = "REPORT_STOCK_ERROR";
export const SCRAPE_PRODUCT_STOCK = "SCRAPE_PRODUCT_STOCK";
export const SCRAPE_PRODUCT_STOCK_SUBMIT = "SCRAPE_PRODUCT_STOCK_SUBMIT";
export const SCRAPE_PRODUCT_STOCK_ERROR = "SCRAPE_PRODUCT_STOCK_ERROR";

// Constant Variables for Bids
export const GET_LIST_BID = "GET_LIST_BID";
export const FETCH_BID_ERROR = "FETCH_BID_ERROR";
export const GET_DETAIL_BID = "GET_DETAIL_BID";
export const FETCHING_BID = "FETCHING_BID";
export const SUBMITING_BID = "SUBMITING_BID";
export const UPDATE_BID = "UPDATE_BID";
export const DELETE_BID = "DELETE_BID";
export const BID_ERROR = "BID_ERROR";
export const BID_ROUTE_API = "/admins/bids";

// Constant Variables for Menus
export const GET_LIST_MENU = "GET_LIST_MENU";
export const GET_LIST_ROOT_MENU = "GET_LIST_ROOT_MENU";
export const GET_OPTION_LIST_MENU = "GET_OPTION_LIST_MENU";
export const FETCH_MENU_ERROR = "FETCH_MENU_ERROR";
export const GET_DETAIL_MENU = "GET_DETAIL_MENU";
export const FETCHING_MENU = "FETCHING_MENU";
export const SUBMITING_MENU = "SUBMITING_MENU";
export const UPDATE_MENU = "UPDATE_MENU";
export const ADD_MENU = "ADD_MENU";
export const NEW_MENU = "NEW_MENU";
export const MOVE_POSITION_MENU = "MOVE_POSITION_MENU";
export const DELETE_MENU = "DELETE_MENU";
export const MENU_ERROR = "MENU_ERROR";
export const MENU_ROUTE_API = "/admins/menus";
export const FIX_MENU = "FIX_MENU";
export const FIX_MENU_SUBMIT = "FIX_MENU_SUBMIT";
export const FIX_MENU_ERROR = "FIX_MENU_ERROR";

// Constant Variables for Bids
export const FORCE_HOLIDAY_MODE = "FORCE_HOLIDAY_MODE";
export const FETCHING_HOLIDAY_MODE = "FETCHING_HOLIDAY_MODE";
export const HOLIDAY_MODE_ERROR = "HOLIDAY_MODE_ERROR";
export const HOLIDAY_MODE_ROUTE_API = "/admins/sellers/force_holiday_mode";

export const GET_LIST_MIDTRANS = "GET_LIST_MIDTRANS";
export const GET_DETAIL_MIDTRANS = "GET_DETAIL_MIDTRANS";
export const FETCH_MIDTRANS_ERROR = "FETCH_MIDTRANS_ERROR";
export const FETCHING_MIDTRANS = "FETCHING_MIDTRANS";
export const SUBMITING_MIDTRANS = "SUBMITING_MIDTRANS";
export const UPDATE_MIDTRANS = "UPDATE_MIDTRANS";
export const DELETE_MIDTRANS = "DELETE_MIDTRANS";
export const MIDTRANS_ERROR = "MIDTRANS_ERROR";
export const MIDTRANS_ROUTE_API = "/admins/midtrans";

export const SEEK_STOCK_ROUTE_API = "/admins/seeks";
export const GET_LIST_SEEK_STOCK = "GET_LIST_SEEK_STOCK";
export const GET_DETAIL_SEEK_STOCK = "GET_DETAIL_SEEK_STOCK";
export const GET_DETAIL_SEEK_STOCK_VARIANTS = "GET_DETAIL_SEEK_STOCK_VARIANTS";
export const SYNC_BY_ID_SEEK_STOCK = "SYNC_BY_ID_SEEK_STOCK";
export const SYNC_ALL_SEEK_STOCK = "SYNC_ALL_SEEK_STOCK";
export const SEEK_STOCK_ERROR = "SEEK_STOCK_ERROR";
export const SEEK_STOCK_REQUEST = "SEEK_STOCK_REQUEST";

// Constant Variables for bin details of cc
export const BIN_ROUTE_API = "/admins/midtrans/bins/invoice_number";
export const BIN_ERROR = "BIN_ERROR";
export const GET_DETAILS_BIN = "GET_DETAILS_BIN";
export const FETCHING_DETAILS_BIN = "FETCHING_DETAILS_BIN";

export const SKU_REQUEST_ROUTE_API = "/admins/productvariants/sku_requests";
export const GET_LIST_SKU_REQUEST = "GET_LIST_SKU_REQUEST";
export const GET_DETAIL_SKU_REQUEST = "GET_DETAIL_SKU_REQUEST";
export const UPDATE_SKU_REQUEST = "UPDATE_SKU_REQUEST";
export const DELETE_SKU_REQUEST = "DELETE_SKU_REQUEST";
export const CREATE_SKU_REQUEST = "CREATE_SKU_REQUEST";
export const SKU_REQUEST_ERROR = "SKU_REQUEST_ERROR";
export const SKU_REQUEST_REQUEST = "SKU_REQUEST_REQUEST";

export const APP_SECTION_ROUTE_API = "/admins/app_sections";
export const GET_LIST_APP_SECTION = "GET_LIST_APP_SECTION";
export const GET_DETAIL_APP_SECTION = "GET_DETAIL_APP_SECTION";
export const GET_DETAIL_APP_SECTION_IMAGES = "GET_DETAIL_APP_SECTION_IMAGES";
export const CREATE_APP_SECTION = "CREATE_APP_SECTION";
export const UPDATE_APP_SECTION = "UPDATE_APP_SECTION";
export const DELETE_APP_SECTION = "DELETE_APP_SECTION";
export const CREATE_APP_SECTION_IMAGE = "CREATE_APP_SECTION_IMAGE";
export const UPDATE_APP_SECTION_IMAGE = "UPDATE_APP_SECTION_IMAGE";
export const DELETE_APP_SECTION_IMAGE = "DELETE_APP_SECTION_IMAGE";
export const APP_SECTION_ERROR = "APP_SECTION_ERROR";
export const APP_SECTION_REQUEST = "APP_SECTION_REQUEST";
export const APP_SECTION_S3 = "APP_SECTION_S3";

export const RACK_ROUTE_API = "/admins/sell_racks";
export const GET_LIST_RACK = "GET_LIST_RACK";
export const RACK_REQUEST = "RACK_REQUEST";
export const RACK_ERROR = "RACK_ERROR";
export const UPDATE_RACK = "UPDATE_RACK";
export const GET_DETAIL_RACK = "GET_DETAIL_RACK";
export const CREATE_RACK = "CREATE_RACK";
export const BULK_RACK_ROUTE_API = "/admins/bulk/racks";

export const RAFFLE_ROUTE_API = "/admins/raffles";
export const GET_LIST_RAFFLE = "GET_LIST_RAFFLE";
export const GET_DETAIL_RAFFLE = "GET_DETAIL_RAFFLE";
export const CREATE_RAFFLE = "CREATE_RAFFLE";
export const UPDATE_RAFFLE = "UPDATE_RAFFLE";
export const DELETE_RAFFLE = "DELETE_RAFFLE";
export const RAFFLE_ERROR = "RAFFLE_ERROR";
export const RAFFLE_REQUEST = "RAFFLE_REQUEST";
export const CREATE_RAFFLE_ENTRIES = "CREATE_RAFFLE_ENTRIES";
export const UPDATE_RAFFLE_ENTRIES = "UPDATE_RAFFLE_ENTRIES";
export const GET_LIST_RAFFLE_ENTRIES = "GET_LIST_RAFFLE_ENTRIES";
export const DELETE_RAFFLE_ENTRIES = "DELETE_RAFFLE_ENTRIES";
export const GET_LIST_RAFFLE_COUPONS = "GET_LIST_RAFFLE_COUPONS";
export const CREATE_RAFFLE_COUPONS = "CREATE_RAFFLE_COUPONS";
export const UPDATE_RAFFLE_COUPONS = "UPDATE_RAFFLE_COUPONS";
export const REDEEM_RAFFLE_COUPONS = "REDEEM_RAFFLE_COUPONS";
export const RAFFLE_S3 = "RAFFLE_S3";
export const RAFFLE_REQUEST_ROUTE_API = "/admins/raffle_requests";
export const GET_LIST_RAFFLE_REQUEST = "GET_LIST_RAFFLE_REQUEST";
export const GET_DETAIL_RAFFLE_REQUEST = "GET_DETAIL_RAFFLE_REQUEST";
export const UPDATE_RAFFLE_REQUEST = "UPDATE_RAFFLE_REQUEST";
export const SUBMITTING_RAFFLE_ITEMS = "SUBMITTING_RAFFLE_ITEMS";
export const SEARCH_RAFFLE_ITEMS = "SEARCH_RAFFLE_ITEMS";
export const GET_LIST_RAFFLE_ITEMS = "GET_LIST_RAFFLE_ITEMS";
export const SEARCH_RAFFLE_TIERS = "SEARCH_RAFFLE_TIERS";
export const GET_LIST_RAFFLE_TIERS = "GET_LIST_RAFFLE_TIERS";
export const SUBMITTING_RAFFLE_TIERS = "SUBMITTING_RAFFLE_TIERS";
export const GET_LIST_RAFFLE_GROUP = "GET_LIST_RAFFLE_GROUP";
export const FETCHING_RAFFLE_GROUP = "FETCHING_RAFFLE_GROUP";
export const CREATE_RAFFLE_GROUP = "CREATE_RAFFLE_GROUP";
export const UPDATE_RAFFLE_GROUP = "UPDATE_RAFFLE_GROUP";
export const DELETE_RAFFLE_GROUP = "DELETE_RAFFLE_GROUP";
export const GET_DETAIL_RAFFLE_GROUP = "GET_DETAIL_RAFFLE_GROUP";

// Constant Variables for Shipping
export const SHIPPING_LIST = "SHIPPING_LIST";
export const FETCHING_PROVINCE = "FETCHING_PROVINCE";
export const PROVINCE_ERROR = "PROVINCE_ERROR";
export const PROVINCE_LIST = "PROVINCE_LIST";
export const PROVINCE_ROUTE_API = "/postalcode/province";
export const FETCHING_CITY = "FETCHING_CITY";
export const CITY_ERROR = "CITY_ERROR";
export const CITY_LIST = "CITY_LIST";
export const CITY_ROUTE_API = "/postalcode/city";
export const SHIPPING_ERROR = "SHIPPING_ERROR";
export const FETCHING_SHIPPING = "FETCHING_SHIPPING";
export const COURIERS_ROUTE_API = "/couriers";
export const COURIERS_COST_POST = "COURIERS_COST_POST";
export const FETCHING_COURIERS = "FETCHING_COURIERS";
export const COURIERS_ERROR = "COURIERS_ERROR";
export const GET_TRACKING_SHIPMENT = "GET_TRACKING_SHIPMENT";
export const TRACKING_SHIPMENT_ERROR = "TRACKING_SHIPMENT_ERROR";
export const APPLY_VOUCHER_ROUTE_API = "/admins/vouchers/apply";
export const APPLY_VOUCHER = "APPLY_VOUCHER";
export const APPLY_VOUCHER_ERROR = "APPLY_VOUCHER_ERROR";
export const FETCHING_APPLY_VOUCHER = "FETCHING_APPLY_VOUCHER";
export const VOUCHER_PRODUCT_TYPE = "product_price";
export const VOUCHER_COURIER_TYPE = "courier_price";

export const GET_DOLLAR_RATE_SETTING = "GET_DOLLAR_RATE_SETTING";
export const DOLLAR_RATE_SETTING_REQUEST = "DOLLAR_RATE_SETTING_REQUEST";
export const DOLLAR_RATE_SETTING_ERROR = "DOLLAR_RATE_SETTING_ERROR";
export const UPDATE_DOLLAR_RATE_SETTING = "UPDATE_DOLLAR_RATE_SETTING";

export const POINT_SHOP_ROUTE_API = "/admins/point_shop";
export const POINT_SHOP_LIST = "POINT_SHOP_LIST";
export const GET_DETAIL_POINT_SHOP = "GET_DETAIL_POINT_SHOP";
export const POINT_SHOP_ERROR = "POINT_SHOP_ERROR";
export const FETCHING_POINT_SHOP = "FETCHING_POINT_SHOP";
export const SUBMITING_POINT_SHOP = "SUBMITING_POINT_SHOP";
export const GET_LIST_POINT_SHOP_ITEMS = "GET_LIST_POINT_SHOP_ITEMS";
export const SEARCH_POINT_SHOP_ITEMS = "SEARCH_POINT_SHOP_ITEMS";
export const POINT_SHOP_CHILDS_REQUEST = "POINT_SHOP_CHILDS_REQUEST";
export const POINT_SHOP_CHILDS_ERROR = "POINT_SHOP_CHILDS_ERROR";
export const ADD_POINT_SHOP = "ADD_POINT_SHOP";
export const UPDATE_POINT_SHOP = "UPDATE_POINT_SHOP";
export const NEW_POINT_SHOP = "NEW_POINT_SHOP";
export const DELETE_POINT_SHOP = "DELETE_POINT_SHOP";
export const SUBMITING_POINT_SHOP_ITEMS = "SUBMITING_POINT_SHOP_ITEMS";

export const POINT_TIERS_ROUTE_API = "/admins/point_tiers";
export const GET_LIST_POINT_TIERS = "GET_LIST_POINT_TIERS";
export const GET_DETAIL_POINT_TIERS = "GET_DETAIL_POINT_TIERS";
export const POINT_TIERS_REQUEST = "POINT_TIERS_REQUEST";
export const POINT_TIERS_ERROR = "POINT_TIERS_ERROR";

export const MARKETING_BUDGET_ROUTE_API = "/admins/marketing-budgets";
export const LIST_MARKETING_BUDGET = "LIST_MARKETING_BUDGET";
export const DETAIL_MARKETING_BUDGET = "DETAIL_MARKETING_BUDGET";
export const MARKETING_BUDGET_REQUEST = "MARKETING_BUDGET_REQUEST";
export const MARKETING_BUDGET_ERROR = "MARKETING_BUDGET_ERROR";
export const CREATE_MARKETING_BUDGET = "CREATE_MARKETING_BUDGET";
export const UPDATE_MARKETING_BUDGET = "UPDATE_MARKETING_BUDGET";
export const DELETE_MARKETING_BUDGET = "DELETE_MARKETING_BUDGET";

export const PRODUCT_ADDON_ROUTE_API = "/admins/products/addons";
export const LIST_PRODUCT_ADDON = "LIST_PRODUCT_ADDON";
export const DETAIL_PRODUCT_ADDON = "DETAIL_PRODUCT_ADDON";
export const PRODUCT_ADDON_REQUEST = "PRODUCT_ADDON_REQUEST";
export const PRODUCT_ADDON_ERROR = "PRODUCT_ADDON_ERROR";
export const CREATE_PRODUCT_ADDON = "CREATE_PRODUCT_ADDON";
export const UPDATE_PRODUCT_ADDON = "UPDATE_PRODUCT_ADDON";
export const DELETE_PRODUCT_ADDON = "DELETE_PRODUCT_ADDON";
export const PRODUCT_ADDON_CHILDS_REQUEST = "PRODUCT_ADDON_CHILDS_REQUEST";
export const GET_LIST_PRODUCT_ADDON_ITEMS = "GET_LIST_PRODUCT_ADDON_ITEMS";
export const SEARCH_PRODUCT_ADDON_ITEMS = "SEARCH_PRODUCT_ADDON_ITEMS";
export const PRODUCT_ADDON_CHILDS_ERROR = "PRODUCT_ADDON_CHILDS_ERROR";
export const SUBMITING_PRODUCT_ADDON_ITEMS = "SUBMITING_PRODUCT_ADDON_ITEMS";

export const SUBSIDY_ROUTE_API = "/admins/subsidies";
export const ADD_SUBSIDY = "ADD_SUBSIDY";
export const UPDATE_SUBSIDY = "UPDATE_SUBSIDY";
export const DELETE_SUBSIDY = "DELETE_SUBSIDY";
export const GET_SUBSIDY = "GET_SUBSIDY";
export const SUBSIDY_ERROR = "SUBSIDY_ERROR";

export const COMMISSION_ROUTE_API = "/admins/productvariants/commissions";
export const ADD_COMMISSION = "ADD_COMMISSION";
export const UPDATE_COMMISSION = "UPDATE_COMMISSION";
export const DELETE_COMMISSION = "DELETE_COMMISSION";
export const COMMISSION_ERROR = "COMMISSION_ERROR";

export const REORDER_VARIANT_IMG_SUBMIT = "REORDER_VARIANT_IMG_SUBMIT";
export const REORDER_VARIANT_IMG = "REORDER_VARIANT_IMG";

export const GET_LIST_CONCIERGE = "GET_LIST_CONCIERGE";
export const FETCH_CONCIERGE_ERROR = "FETCH_CONCIERGE_ERROR";
export const GET_DETAIL_CONCIERGE = "GET_DETAIL_CONCIERGE";
export const CONCIERGE_ERROR = "CONCIERGE_ERROR";
export const FETCHING_CONCIERGE = "FETCHING_CONCIERGE";
export const UPDATE_CONCIERGE = "UPDATE_CONCIERGE";
export const CREATE_CONCIERGE = "CREATE_CONCIERGE";
export const CONCIERGE_ROUTE_API = "/admins/concierge";

// Spinning Wheel & Prize
export const SPIN_WHEEL_ROUTE_API = "/admins/spinning-wheel";
export const SPIN_WHEEL_LIST_FETCH = "SPIN_WHEEL_LIST_FETCH";
export const SPIN_WHEEL_FETCHING = "SPIN_WHEEL_FETCHING";
export const SPIN_WHEEL_DETAIL_FETCH = "SPIN_WHEEL_DETAIL_FETCH";
export const SPIN_WHEEL_PRIZE_DETAIL_FETCH = "SPIN_WHEEL_PRIZE_DETAIL_FETCH";
export const SPIN_WHEEL_ADD = "SPIN_WHEEL_ADD";
export const SPIN_WHEEL_UPDATE = "SPIN_WHEEL_UPDATE";
export const SPIN_WHEEL_DELETE = "SPIN_WHEEL_DELETE";
export const SPIN_WHEEL_PRIZE_FETCHING = "SPIN_WHEEL_PRIZE_FETCHING";
export const SPIN_WHEEL_PRIZE_ADD = "SPIN_WHEEL_PRIZE_ADD";
export const SPIN_WHEEL_PRIZE_UPDATE = "SPIN_WHEEL_PRIZE_UPDATE";
export const SPIN_WHEEL_PRIZE_DELETE = "SPIN_WHEEL_PRIZE_DELETE";
export const GET_LIST_SPIN_WHEEL = "GET_LIST_SPIN_WHEEL";
export const ADD_SPIN_WHEEL = "ADD_SPIN_WHEEL";
export const UPDATE_SPIN_WHEEL = "UPDATE_SPIN_WHEEL";
export const DELETE_SPIN_WHEEL = "DELETE_SPIN_WHEEL";
export const ADD_SPIN_WHEEL_PRIZE = "ADD_SPIN_WHEEL_PRIZE";
export const UPDATE_SPIN_WHEEL_PRIZE = "UPDATE_SPIN_WHEEL_PRIZE";
export const DELETE_SPIN_WHEEL_PRIZE = "DELETE_SPIN_WHEEL_PRIZE";
export const SPIN_WHEEL_ERROR = "SPIN_WHEEL_ERROR";
export const SPIN_WHEEL_PRIZE_ERROR = "SPIN_WHEEL_PRIZE_ERROR";

// Pop Up Banner
export const POP_UP_BANNER_ROUTE_API = "/admins/popup_banners";
export const POP_UP_BANNER_ADD = "POP_UP_BANNER_ADD";
export const POP_UP_BANNER_FETCHING = "POP_UP_BANNER_FETCHING";
export const POP_UP_BANNER_ERROR = "POP_UP_BANNER_ERROR";
export const POP_UP_BANNER_LIST_FETCH = "POP_UP_BANNER_LIST_FETCH";
export const POP_UP_BANNER_DETAIL_FETCH = "POP_UP_BANNER_DETAIL_FETCH";
export const POP_UP_BANNER_UPDATE = "POP_UP_BANNER_UPDATE";
export const POP_UP_BANNER_DELETE = "POP_UP_BANNER_DELETE";

// FAQ
export const FAQ_ROUTE_API = "/admins/faqs";
export const FAQ_LIST_FETCH = "FAQ_LIST_FETCH";
export const FAQ_FETCHING = "FAQ_FETCHING";
export const FAQ_DETAIL_FETCH = "FAQ_DETAIL_FETCH";
export const FAQ_ADD = "FAQ_ADD";
export const FAQ_UPDATE = "FAQ_UPDATE";
export const FAQ_DELETE = "FAQ_DELETE";
export const FAQ_ERROR = "FAQ_ERROR";
export const ADD_IMAGE_FAQ = "ADD_IMAGE_FAQ";
export const DELETE_IMAGE_FAQ = "DELETE_IMAGE_FAQ";

export const FAQ_CATEGORY_LIST_FETCH = "FAQ_CATEGORY_LIST_FETCH";
export const FAQ_CATEGORY_FETCHING = "FAQ_CATEGORY_FETCHING";
export const FAQ_CATEGORY_DETAIL_FETCH = "FAQ_CATEGORY_DETAIL_FETCH";
export const FAQ_CATEGORY_ADD = "FAQ_CATEGORY_ADD";
export const FAQ_CATEGORY_UPDATE = "FAQ_CATEGORY_UPDATE";
export const FAQ_CATEGORY_DELETE = "FAQ_CATEGORY_DELETE";
export const FAQ_CATEGORY_ERROR = "FAQ_CATEGORY_ERROR";

// Artile
export const ARTICLE_ROUTE_API = "/admins/articles";
export const ARTICLE_LIST_FETCH = "ARTICLE_LIST_FETCH";
export const ARTICLE_FETCHING = "ARTICLE_FETCHING";
export const ARTICLE_DETAIL_FETCH = "ARTICLE_DETAIL_FETCH";
export const ARTICLE_ADD = "ARTICLE_ADD";
export const ARTICLE_UPDATE = "ARTICLE_UPDATE";
export const ARTICLE_DELETE = "ARTICLE_DELETE";
export const ARTICLE_ERROR = "ARTICLE_ERROR";
export const ADD_IMAGE_ARTICLE = "ADD_IMAGE_ARTICLE";
export const UPDATE_IMAGE_ARTICLE = "UPDATE_IMAGE_ARTICLE";
export const DELETE_IMAGE_ARTICLE = "DELETE_IMAGE_ARTICLE";

export const ARTICLE_CATEGORY_LIST_FETCH = "ARTICLE_CATEGORY_LIST_FETCH";
export const ARTICLE_CATEGORY_FETCHING = "ARTICLE_CATEGORY_FETCHING";
export const ARTICLE_CATEGORY_DETAIL_FETCH = "ARTICLE_CATEGORY_DETAIL_FETCH";
export const ARTICLE_CATEGORY_ADD = "ARTICLE_CATEGORY_ADD";
export const ARTICLE_CATEGORY_UPDATE = "ARTICLE_CATEGORY_UPDATE";
export const ARTICLE_CATEGORY_DELETE = "ARTICLE_CATEGORY_DELETE";
export const ARTICLE_CATEGORY_ERROR = "ARTICLE_CATEGORY_ERROR";

export const ARTICLE_META_LIST_FETCH = "ARTICLE_META_LIST_FETCH";
export const ARTICLE_META_FETCHING = "ARTICLE_META_FETCHING";
export const ARTICLE_META_DETAIL_FETCH = "ARTICLE_META_DETAIL_FETCH";
export const ARTICLE_META_ADD = "ARTICLE_META_ADD";
export const ARTICLE_META_UPDATE = "ARTICLE_META_UPDATE";
export const ARTICLE_META_DELETE = "ARTICLE_META_DELETE";
export const ARTICLE_META_ERROR = "ARTICLE_META_ERROR";

export const ARTICLE_CHILDS_REQUEST = "ARTICLE_CHILDS_REQUEST ";
export const ARTICLE_CHILDS_ERROR = "ARTICLE_CHILDS_ERROR";
export const SUBMITING_ARTICLE_VARIANTS = "SUBMITING_ARTICLE_VARIANTS";
export const GET_LIST_ARTICLE_VARIANTS = "GET_LIST_ARTICLE_VARIANTS";
export const SEARCH_ARTICLE_VARIANTS = "SEARCH_ARTICLE_VARIANTS";

// TIMER
export const TIMER_ROUTE_API = "/admins/timer";
export const TIMER_LIST_FETCH = "TIMER_LIST_FETCH";
export const TIMER_FETCHING = "TIMER_FETCHING";
export const TIMER_DETAIL_FETCH = "TIMER_DETAIL_FETCH";
export const TIMER_ADD = "TIMER_ADD";
export const TIMER_UPDATE = "TIMER_UPDATE";
export const TIMER_DELETE = "TIMER_DELETE";
export const TIMER_ERROR = "TIMER_ERROR";

// SETTING
export const SETTING_ROUTE_API = "/admins/settings";
export const SETTING_LIST_FETCH = "SETTING_LIST_FETCH";
export const SETTING_FETCHING = "SETTING_FETCHING";
export const SETTING_DETAIL_FETCH = "SETTING_DETAIL_FETCH";
export const SETTING_ADD = "SETTING_ADD";
export const SETTING_UPDATE = "SETTING_UPDATE";
export const SETTING_DELETE = "SETTING_DELETE";
export const SETTING_ERROR = "SETTING_ERROR";
export const SETTING_GET_PROCESSING_FEE = "SETTING_GET_PROCESSING_FEE";
export const PAYMENT_METHOD_FETCH = "PAYMENT_METHOD_FETCH";
export const PAYMENT_METHOD_FETCHING = "PAYMENT_METHOD_FETCHING";

// Survey & Survey Option
export const SURVEY_ROUTE_API = "/admins/surveys";
export const SURVEY_LIST_FETCH = "SURVEY_LIST_FETCH";
export const SURVEY_FETCHING = "SURVEY_FETCHING";
export const SURVEY_DETAIL_FETCH = "SURVEY_DETAIL_FETCH";
export const SURVEY_ADD = "SURVEY_ADD";
export const SURVEY_UPDATE = "SURVEY_UPDATE";
export const SURVEY_DELETE = "SURVEY_DELETE";
export const SURVEY_ERROR = "SURVEY_ERROR";
export const ADD_IMAGE_SURVEY = "ADD_IMAGE_SURVEY";
export const DELETE_IMAGE_SURVEY = "DELETE_IMAGE_SURVEY";

export const SURVEY_OPTION_LIST_FETCH = "SURVEY_OPTION_LIST_FETCH";
export const SURVEY_OPTION_FETCHING = "SURVEY_OPTION_FETCHING";
export const SURVEY_OPTION_DETAIL_FETCH = "SURVEY_OPTION_DETAIL_FETCH";
export const SURVEY_OPTION_ADD = "SURVEY_OPTION_ADD";
export const SURVEY_OPTION_UPDATE = "SURVEY_OPTION_UPDATE";
export const SURVEY_OPTION_DELETE = "SURVEY_OPTION_DELETE";
export const SURVEY_OPTION_ERROR = "SURVEY_OPTION_ERROR";
export const ADD_IMAGE_SURVEY_OPTION = "ADD_IMAGE_SURVEY_OPTION";
export const DELETE_IMAGE_SURVEY_OPTION = "DELETE_IMAGE_SURVEY_OPTION";

export const OPTION_ROUTE_API = "/admins/options";
export const OPTION_LIST_FETCH = "OPTION_LIST_FETCH";
export const OPTION_FETCHING = "OPTION_FETCHING";
export const OPTION_DETAIL_FETCH = "OPTION_DETAIL_FETCH";
export const OPTION_ADD = "OPTION_ADD";
export const OPTION_UPDATE = "OPTION_UPDATE";
export const OPTION_DELETE = "OPTION_DELETE";
export const OPTION_ERROR = "OPTION_ERROR";

// SETTING
export const COUNTRY_ROUTE_API = "/admins/country";
export const COUNTRY_LIST_FETCH = "COUNTRY_LIST_FETCH";
export const COUNTRY_ALL_FETCH = "COUNTRY_ALL_FETCH";
export const COUNTRY_FETCHING = "COUNTRY_FETCHING";
export const COUNTRY_BATCH_UPDATE = "COUNTRY_BATCH_UPDATE";
export const COUNTRY_ERROR = "COUNTRY_ERROR";
export const INTERNATIONAL_SHIPPING_UPDATE = "INTERNATIONAL_SHIPPING_UPDATE";

// PUSH NOTIFICATION
export const PUSH_NOTIFICATION_ROUTE_API = "/admins/push_notif_managers";
export const PUSH_NOTIFICATION_LIST_FETCH = "PUSH_NOTIFICATION_LIST_FETCH";
export const PUSH_NOTIFICATION_FETCHING = "PUSH_NOTIFICATION_FETCHING";
export const PUSH_NOTIFICATION_DETAIL_FETCH = "PUSH_NOTIFICATION_DETAIL_FETCH";
export const PUSH_NOTIFICATION_ADD = "PUSH_NOTIFICATION_ADD";
export const PUSH_NOTIFICATION_UPDATE = "PUSH_NOTIFICATION_UPDATE";
export const PUSH_NOTIFICATION_DELETE = "PUSH_NOTIFICATION_DELETE";
export const PUSH_NOTIFICATION_ERROR = "PUSH_NOTIFICATION_ERROR";
export const ADD_IMAGE_PUSH_NOTIFICATION = "ADD_IMAGE_PUSH_NOTIFICATION";
export const DELETE_IMAGE_PUSH_NOTIFICATION = "DELETE_IMAGE_PUSH_NOTIFICATION";

// Metadata
export const METADATA_ROUTE_API = "/admins/metadatas";
export const METADATA_ADD = "METADATA_ADD";
export const METADATA_FETCHING = "METADATA_FETCHING";
export const METADATA_ERROR = "METADATA_ERROR";
export const METADATA_LIST_FETCH = "METADATA_LIST_FETCH";
export const METADATA_DETAIL_FETCH = "METADATA_DETAIL_FETCH";
export const METADATA_UPDATE = "METADATA_UPDATE";
export const METADATA_DELETE = "METADATA_DELETE";

// Audit
export const AUDIT_ROUTE_API = "/admins/audits";
export const AUDIT_FETCHING = "AUDIT_FETCHING";
export const AUDIT_LIST_FETCH = "AUDIT_LIST_FETCH";
export const AUDIT_ERROR = "AUDIT_ERROR";

// Bulk AWB
export const BULK_AWB_ROUTE_API = "/admins/bulk/awbs";
export const BULK_AWB = "BULK_AWB";
export const BULK_AWB_SUBMIT = "BULK_AWB_SUBMIT";
export const BULK_AWB_ERROR = "BULK_AWB_ERROR";
