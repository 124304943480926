import React from 'react';
import { Link } from 'react-router-dom';
import { Alert } from 'reactstrap';
import { SimpleLineIcon } from '../../../../components/Icons';

const AlertApprovedSuccess = props => (
  <Alert color="success" isOpen={props.isOpen}>
    <h5 className="alert-heading"><SimpleLineIcon iconType="check"/> Approved Successfully!</h5>
    <p>
      Well done your SKU has been approved successfully.
      Review your product variant, like add/attach more sizes, add product's thumbnails and more
      by clicking this url {' '}
      {
        props.productUrl && (
          <Link target="blank" to={props.productUrl}>Go to Product.</Link>
        )
      }
    </p>
  </Alert>
)

export default AlertApprovedSuccess;