import React from 'react';
import DateToHuman from '../../../../components/DateToHuman';
import { Th } from '../../../../components/Tbl';
import BadgeStatus from './BadgeStatus';
import { INCREASE_TYPE } from "../userBalanceLogListCard/helpers";
import { Link } from 'react-router-dom';

const RowItem = (props) => (
  <tr>
    <Th className="v-center" scope="row">{ props.number }</Th>
    <td className="v-center">
      <p className="text-uppercase font-weight-bold mb-0">{props.refID}</p>
      {props?.sale && (
        <Link className="link-secondary" to={`/sales/${props.sale.id}`}>{`(${props.sale.invoice_number})`}</Link>
      )}
    </td>
    <td className="v-center">
      <div className="text-uppercase">
        <p className="mb-0">
          <span className="mr-1">
            {props.type === INCREASE_TYPE? "+": "-"}
          </span>
          <span className="text-bold">{Number(props.amount)}</span>
        </p>
        <div>
          <BadgeStatus status={props.type}/>
        </div>
      </div>
    </td>
    <td className="v-center">{ <DateToHuman id={`completedList_sellerPointHistory_updatedAt_${props.id}`} value={props.updatedAt}/> }</td>
  </tr>
);

export default RowItem;