import React from 'react';
import propTypes from 'prop-types';

import BadgeThin from '../../../../../components/Badges/BadgeThin';
import FontAwesomeIcon from '../../../../../components/Icons/FontAwesomeIcon';

import Paragraph from '../../../../../styled/Typograph/Paragraph';
import SmallInline from '../../../../../styled/Typograph/Small/SmallInline';
// import ButtonStarred from '../../../RowItem/ButtonStarred';

const OrderColumn = props => (
  <Paragraph>
    {props.unreadNoteCount? (
      <span style={{fontSize: '.65rem'}} className="mr-1 text-danger">
        <FontAwesomeIcon iconType="circle"/>
      </span>
    ):null}
    <span className="text-muted">{ `#${props.id}` }</span> &nbsp;{ props.invoiceNumber }
    { props.preOrder? <BadgeThin color="primary" className="ml-1">PREORDER</BadgeThin>:''}
    { props.preVerified? <BadgeThin color="warning" className="text-uppercase ml-1"><FontAwesomeIcon iconType='bolt' /> Express Shipping</BadgeThin>: null}
    <br/>
    { props.displayName }<br/>
    <SmallInline>
      ({ props.SKU })
      {
        props.status==='KA_SENT_BUYER'?
          <span className="ml-1">
            -{' '}
            <FontAwesomeIcon className="ml-1" iconType="truck"/>
          </span>:
        (props.status==='DONE_BY_BUYER'?
          <span className="ml-1">
            -{' '}
            <FontAwesomeIcon
              iconType="check-circle-o"
              className="text-success mr-1"
            />
            (user)
          </span>
          :
          <span className="ml-1">
            -{' '}
            <FontAwesomeIcon
              iconType="check-circle"
              className="text-success mr-1"
            />
            (admin)
          </span>
        )
      }
    </SmallInline>
    <SmallInline>(Quantity: { props.quantity })</SmallInline><br/>
    <BadgeThin
      color="primary"
      className="text-uppercase mr-1"
    >
      <FontAwesomeIcon iconType="tags"/> {props.category}
    </BadgeThin>
    <BadgeThin
      color="success"
      className="text-uppercase mr-1"
    >
      <FontAwesomeIcon iconType="tag" className="mr-1"/>
      { props.sneakersCondition }
    </BadgeThin>
    <BadgeThin color="success" className="mr-1">SIZE: { props.size }</BadgeThin>
    { props.consignmentId? <BadgeThin color="warning" className="mr-1">CG-ID: {props.consignmentId}</BadgeThin>:''}
    <br/>
    <SmallInline>
      {/* <ButtonStarred title={props.marked? "Remove mark!": "Mark!"} value={props.marked} onClick={() => props.onMarkToggle(!props.marked)}/> */}
      <span className="mr-1">
        <FontAwesomeIcon
          iconType="calendar"
          className="mr-1"
        />
        { props.orderDate }
      </span>
      - { props.buyerEmail }  <b>(Buyer)</b>
    </SmallInline>
    <br/>
    {
      props.legitCheckLogs.map((item, index) => {
        return(
          <>
            <SmallInline key={index}>
              <span className="mr-1">
                <FontAwesomeIcon iconType="calendar" className="mr-1"/>{ item.user.created_at }
              </span>
              - {item.user.email} <b>(LC Approval)</b>
              
            </SmallInline>
            <br />
          </>
        )
      })
    }
  </Paragraph>
)

export default OrderColumn;

OrderColumn.propTypes = {
  id: propTypes.number.isRequired,
  buyerEmail: propTypes.string,
  invoiceNumber: propTypes.string.isRequired,
  orderDate: propTypes.string,
  displayName: propTypes.string,
  SKU: propTypes.string,
  size: propTypes.string,
  colour: propTypes.string,
  preOrder: propTypes.oneOfType([propTypes.number,  propTypes.bool]),
  sneakersCondition: propTypes.string,
  consignmentId: propTypes.string,
  status: propTypes.string
}

OrderColumn.defaultProps ={
  preOrder: false,
  sneakersCondition: 'BARU'
}
