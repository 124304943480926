import React, { PureComponent as Component } from 'react';
import propTypes from 'prop-types';

import Order from '../../../components/ConsignmentOrder/Details';

export default class ConsignmentOrderDetail extends Component {
  render() {
    return (
      <Order className="tab-content--custom__card"
        {...this.props}
      />
    )
  }
}

ConsignmentOrderDetail.propTypes={
  isLoading: propTypes.bool,
  order: propTypes.object,
  payment: propTypes.object,
  seller: propTypes.object,
  shipping: propTypes.object,
  product: propTypes.object
}
ConsignmentOrderDetail.defaultProps = {
  isLoading: false
}