import {
  AUTH_TOKEN_KEY,
  SEEK_STOCK_ROUTE_API,
  GET_LIST_SEEK_STOCK,
  GET_DETAIL_SEEK_STOCK,
  GET_DETAIL_SEEK_STOCK_VARIANTS,
  SYNC_ALL_SEEK_STOCK,
  SYNC_BY_ID_SEEK_STOCK,
  SEEK_STOCK_ERROR,
  SEEK_STOCK_REQUEST,
} from '../constants';
import api, { addHeaderAuthorization } from '../utils/Api';
import { manipulateErrors, checkAuthorized } from '../utils';
import { logout } from './authAction';


const onRequest = (requestType, value) => ({
  type: SEEK_STOCK_REQUEST,
  payload: {
    requestType,
    value
  }
})

const onSuccessRequest = (type, payload) => ({
  type, payload
})

const onFailedRequest = (requestType, error, id) => {
  const translateError = manipulateErrors(error);
  if(!checkAuthorized(translateError)) return logout();
  return {
    type: SEEK_STOCK_ERROR,
    payload: {
      id,
      requestType,
      ...translateError
    }
  }
}

export const getSeekStockList = q => (
  dispatch => {
    dispatch(onRequest(GET_LIST_SEEK_STOCK, true));
    api({
      ...addHeaderAuthorization(localStorage.getItem(AUTH_TOKEN_KEY))
    }).get(SEEK_STOCK_ROUTE_API, q)
    .then(res => {
      dispatch(onSuccessRequest(GET_LIST_SEEK_STOCK, res.data));
      dispatch(onRequest(GET_LIST_SEEK_STOCK, false));
    })
    .catch(err => {
      dispatch(onFailedRequest(GET_LIST_SEEK_STOCK, err));
      dispatch(onRequest(GET_LIST_SEEK_STOCK, false));
    })
  }
)

export const getSeekStockById = id => (
  dispatch => {
    dispatch(onRequest(GET_DETAIL_SEEK_STOCK, true));
    api({
      ...addHeaderAuthorization(localStorage.getItem(AUTH_TOKEN_KEY))
    }).get(`${SEEK_STOCK_ROUTE_API}/${id}`)
    .then(res => {
      dispatch(onSuccessRequest(GET_DETAIL_SEEK_STOCK, res.data));
      dispatch(onRequest(GET_DETAIL_SEEK_STOCK, false));
    })
    .catch(err => {
      dispatch(onFailedRequest(GET_DETAIL_SEEK_STOCK, err));
      dispatch(onRequest(GET_DETAIL_SEEK_STOCK, false));
    })
  }
)

export const getSeekStockByIdVariants = id => (
  dispatch => {
    dispatch(onRequest(GET_DETAIL_SEEK_STOCK_VARIANTS, true));
    api({
      ...addHeaderAuthorization(localStorage.getItem(AUTH_TOKEN_KEY))
    }).get(`${SEEK_STOCK_ROUTE_API}/${id}/variants`)
    .then(res => {
      dispatch(onSuccessRequest(GET_DETAIL_SEEK_STOCK_VARIANTS, res.data));
      dispatch(onRequest(GET_DETAIL_SEEK_STOCK_VARIANTS, false));
    })
    .catch(err => {
      dispatch(onFailedRequest(GET_DETAIL_SEEK_STOCK_VARIANTS, err));
      dispatch(onRequest(GET_DETAIL_SEEK_STOCK_VARIANTS, false));
    })
  }
)


export const syncAllSeekStock = () => (
  dispatch => {
    dispatch(onRequest(SYNC_ALL_SEEK_STOCK, true));
    api({
      ...addHeaderAuthorization(localStorage.getItem(AUTH_TOKEN_KEY))
    }).post(`${SEEK_STOCK_ROUTE_API}/sync`)
    .then(res => {
      dispatch(onSuccessRequest(SYNC_ALL_SEEK_STOCK, res.data));
      dispatch(onRequest(SYNC_ALL_SEEK_STOCK, false));
    })
    .catch(err => {
      dispatch(onFailedRequest(SYNC_ALL_SEEK_STOCK, err));
      dispatch(onRequest(SYNC_ALL_SEEK_STOCK, false));
    })
  }
)

export const syncSeekStockById = id => (
  dispatch => {
    dispatch(onRequest(SYNC_BY_ID_SEEK_STOCK, true));
    api({
      ...addHeaderAuthorization(localStorage.getItem(AUTH_TOKEN_KEY))
    }).post(`${SEEK_STOCK_ROUTE_API}/${id}/sync`)
    .then(res => {
      dispatch(onSuccessRequest(SYNC_BY_ID_SEEK_STOCK, res.data));
      dispatch(onRequest(SYNC_BY_ID_SEEK_STOCK, false));
    })
    .catch(err => {
      dispatch(onFailedRequest(SYNC_BY_ID_SEEK_STOCK, err));
      dispatch(onRequest(SYNC_BY_ID_SEEK_STOCK, false));
    })
  }
)
