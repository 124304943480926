import {
  AUDIT_FETCHING,
  AUDIT_ERROR,
  AUDIT_LIST_FETCH
} from '../constants';

const initialState={
	list:[],
	detail: {},
  isFetch:false,
  isSubmit:false,
  success:null,
  error:null,
  delete: false
}


const onFetching = (lastState,payload) => {
  if(payload.type==='fetch')
    return { ...lastState, isFetch:payload.status, success: null, error: null }
  else if(payload.type==='submit')
    return { ...lastState, isSubmit:payload.status }

  return { ...lastState, isFetch:false,isSubmit:false }
}

const reducer=(state=initialState, action)=>{
  switch(action.type){
    case AUDIT_LIST_FETCH: {
      const {data, status_code, message}=action.payload;
      return {...state, list:{...data, message, status_code}, detail: {}, error:null}
    }
    case AUDIT_ERROR:
      return { ...state, ...action.payload, success: null }

    case AUDIT_FETCHING: 
      return onFetching(state, action.payload);

    default: return state;
  }

}

export default reducer;