import React from 'react';
import classNames from 'classnames';
import propTypes from 'prop-types';
import Badge from '../../../../components/Badges/BadgeThin';
import FontAwesomeIcon from '../../../../components/Icons/FontAwesomeIcon';

import Paragraph from '../../../../styled/Typograph/Paragraph';
import SmallInline from '../../../../styled/Typograph/Small/SmallInline';

const ProductColumn = props =>{
  const {
    userSellId,
    displayName,
    SKU,
    colour,
    userEmail,
    qty,
    size,
    preOrder,
    preVerified,
    sneakersCondition,
    isExpired,
    isSellerHoliday,
    defectsCount
  } = props;
  return (
    <Paragraph>
      <span className="text-muted">#{userSellId}</span> &nbsp;
      {displayName} <br/>
      <SmallInline>({SKU})</SmallInline>
      <SmallInline className="text-muted">{colour} { isSellerHoliday? <Badge color="warning">HOLIDAY!</Badge>: (isExpired? <Badge color="danger">EXP!</Badge>:'') }</SmallInline> <br/>  
      {props.category && (
        <Badge
          color="primary"
          className="text-uppercase mr-1"
        >
          <FontAwesomeIcon iconType="tags"/> {props.category}
        </Badge>
      )}
      <Badge color="success"><FontAwesomeIcon iconType="tag"/> { sneakersCondition }</Badge>
      {' '}
      <Badge color="success"><i className={classNames("fa", {"fa-check": preVerified, "fa-times": !preVerified })}></i> PREVERIFIED</Badge>
      {' '}
      {preOrder?<Badge color="primary">PREORDER</Badge>:''}
      <br/>
      <Badge>{userEmail}</Badge>
      {' '}
      <Badge color="success">QTY: {qty}</Badge>
      {' '}
      <Badge color="success">SIZE: {size.US}</Badge>
      {' '}
      {defectsCount > 0 && (
        <Badge
          color="warning"
          className="text-uppercase mr-1"
        >
          <FontAwesomeIcon iconType="exclamation-triangle"/> {defectsCount} defects
        </Badge>
      )}
    </Paragraph>
  )
}
export default ProductColumn;

ProductColumn.propTypes = {
  userSellId: propTypes.number.isRequired,
  displayName: propTypes.string,
  SKU: propTypes.string,
  colour: propTypes.string,
  userEmail: propTypes.string,
  qty: propTypes.number,
  size: propTypes.object,
  preOrder: propTypes.oneOfType([propTypes.bool,propTypes.number]),
  preVerified: propTypes.oneOfType([propTypes.bool,propTypes.number]),
  isExpired: propTypes.oneOfType([propTypes.bool,propTypes.number]),
  isSellerHoliday: propTypes.oneOfType([propTypes.bool,propTypes.number]),
}

ProductColumn.defaultProps = {
  sneakersCondition: 'BARU',
  SKU: '-',
  colour: '-',
  displayName: '-',
  userEmail: '-',
  size: {
    US: 'Not Set'
  },
  qty: 0,
  preOrder: false,
  preVerified: false,
  isExpired: true,
  isSellerHoliday: false,
  defectsCount: 0
}