import { useState } from "react";

export const useToggleValue = (initialValue = false) => {
    const [value, setValue] = useState(initialValue);

    const toggle = (forceValue = undefined) =>
        setValue(forceValue === undefined ? !value : forceValue);

    return [value, toggle];
};
