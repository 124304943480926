import React, {PureComponent as Component} from 'react';
import { Row,Col,Card, CardText } from 'reactstrap';
import propTypes from 'prop-types';

import { UserDetail } from '../../../components/Users';

export default class TabUserDetail extends Component{
  render(){
    const { user } = this.props;
    return(
      <Card block className="tab-content--custom__card">
        <CardText>About user detail.</CardText>
          <Row>
            <Col xs={12} md={6}>
              <UserDetail displayRole isLoading={this.props.isLoading} user={{...user}}/>
            </Col>
          </Row>
      </Card>
    );
  }
}
TabUserDetail.propTypes={
    user:propTypes.shape({
      id:propTypes.number.isRequired,
      email:propTypes.string,
      roles: propTypes.array
    })
}
TabUserDetail.defaultProps={
    user:{
      id:0,
      email:'',
      roles: []
    },
}
