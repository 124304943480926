import {
	GET_LIST_PRODUCT,
	GET_DETAIL_PRODUCT,
	ADD_PRODUCT,
	UPDATE_PRODUCT,
    DELETE_PRODUCT,
    PRODUCT_ERROR,
	NEW_PRODUCT,
	GET_OPTION_LIST_PRODUCT,
    FETCHING_PRODUCT
} from '../constants';

/**
 * Initial State
 */
const initialState={
	list:[],
	options:[],
	detail:{},
	isFetch:false,
	isSubmit:false,
	error:null,
	success:null,
}

/**
 * Function to manipulate payload when success get offer list
 * @author pewe
 * @param {object} lastState 
 * @param {object} payload 
 */
const onGetProductList = (lastState,payload) => {
	const {data, message, status_code}=payload;
	return { ...lastState, error:null, success:null,detail:{}, list:{ ...data, message, status_code } };	
}

/**
 * Function to manipulate payload when success get offer details
 * @author pewe
 * @param {object} lastState 
 * @param {object} payload 
 */
const onGetDetailProduct = (lastState,payload) => {
	const {data, message, status_code}=payload;
	return { ...lastState, error:null, success:null, detail:{ ...data, message, status_code } };	
}

/**
 * Function to handle when start or end fetching offer api
 * @author pewe
 * @param {object} lastState 
 * @param {objec} bool 
 */
const onFetchingProduct = (lastState,payload) => {
    if(payload.type==='fetch')
        return { ...lastState, isFetch:payload.status }
    else if(payload.type==='submit')
        return { ...lastState, isSubmit:payload.status }

    return { ...lastState, isFetch:false,isSubmit:false }
}

/**
 * Function to handle when fetching offer error triggered
 * @author pewe
 * @param {object} lastState 
 * @param {object} error 
 */
const onProductError = (lastState,error) => {
	return { ...lastState, ...error, success:null };		
}

const onAddProduct = (lastState,payload) => {
	const {data, message, status_code}=payload;
    return { ...lastState, detail:{ ...data, message, status_code }, success:'Insert has been successfully!',error:null }
}

const onUpdateProduct = (lastState,payload) => {
	const {data, message, status_code}=payload;
    return { ...lastState, detail:{ ...data, message, status_code }, success:'Update has been successfully!',error:null }
	
}
const onDeleteProduct = (lastState,payload) => {
	const {data, message, status_code}=payload;
    return { ...lastState, detail:{ ...data, message, status_code, delete:true }, success:'Delete has been successfully!',error:null }
}
const onGetOption = (lastState,payload) => {
	const {data, message, status_code}=payload;
	const options = data.map((item)=>{
		return {value:item.id,label:item.name}
	});
    return { ...lastState, options, success:null,error:null }

}

/**
 * Offer Reducer
 * @author pewe
 * @param {object} state 
 * @param {object} action 
 */
const productReducer= (state=initialState, action) => {
	switch(action.type)
	{
		case GET_LIST_PRODUCT: return onGetProductList(state,action.payload);
		case GET_DETAIL_PRODUCT: return onGetDetailProduct(state,action.payload);
		case FETCHING_PRODUCT: return onFetchingProduct(state,action.payload);
		case PRODUCT_ERROR:return onProductError(state,action.payload);
		case UPDATE_PRODUCT:return onUpdateProduct(state,action.payload);
		case ADD_PRODUCT:return onAddProduct(state,action.payload);
		case DELETE_PRODUCT:return onDeleteProduct(state,action.payload);
		case GET_OPTION_LIST_PRODUCT:return onGetOption(state,action.payload)
		case NEW_PRODUCT:return initialState;
		default:return state;
	}
}

export default productReducer;