import React from 'react';
import { CardBody, Collapse } from "reactstrap";
import { SearchForm, SortForm } from "../";
import { consignmentStatusOptions } from "../../../../../constants/kickavenue";

export default function Filters(props) {
    const {
        isOpen,
        onApplyFilter,
        onHandleSearch,

        keyword,
        setKeyword,
        shippingMethod,
        setShippingMethod,
        status,
        setStatus,
        setAllStatus,
        seller,
        setSeller,
        rack,
        setRack,
        perPage,
        sortBy,
        setPerPage,
        setSortBy,
    } = props;

    const handleOnChangeInput = (e, type) => {
        switch (type) {
            case 'keyword':
                setKeyword(e.target.value);
                break;
            case 'shippingMethod':
                setShippingMethod(e.target.value);
                break;
        }
    };

    const isCheckedStatus = (val) => {
        if (val === 'all') {
            return status.split(',').length === consignmentStatusOptions.length;
        } else {
            return val === 'default' ? status === '' : status.includes(val);
        }
    };

    const handleStatusesChangeInput = (e) => {
        let currentStatus = e.target.value
        let statuses = status.split(',')
        let arrStatus = [];
        if (isCheckedStatus(currentStatus)) {
            setStatus(statuses.filter(itm => itm !== currentStatus).join(','));
            setAllStatus(false);
        } else if (currentStatus === 'default' || currentStatus === 'all') {
            if (currentStatus === 'default') {
                setStatus('');
                setAllStatus(false);
            } else {
                arrStatus = [...consignmentStatusOptions.map(v => v.value)]
                setStatus(arrStatus.join(','));
                setAllStatus(true);
            }
        } else {
            if (isCheckedStatus('default')) {
                setStatus(currentStatus);
            } else if (currentStatus !== 'all') {
                arrStatus = [currentStatus, ...statuses]
                setStatus(arrStatus.join(','));
            }
            setAllStatus(false);
        }
    };

    return (
        <Collapse isOpen={isOpen}>
            <CardBody className="filter-container__card-body">
                <SearchForm
                    keyword={keyword}
                    seller={seller}
                    shippingMethod={shippingMethod}
                    rack={rack}
                    onRackChange={setRack}
                    onSellerChange={setSeller}
                    onInputChange={handleOnChangeInput}
                    onSearch={onHandleSearch}
                    onSubmit={onApplyFilter}
                    isCheckedStatus={isCheckedStatus}
                    onStatusesInputChange={handleStatusesChangeInput}
                />
                <hr />
                <SortForm
                    perPage={perPage}
                    sortBy={sortBy}
                    setPerPage={setPerPage}
                    setSortBy={setSortBy}
                />
            </CardBody>
        </Collapse>
    );
}
