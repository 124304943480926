import {
  ADD_MENU,
  NEW_MENU,
  MENU_ERROR,
  UPDATE_MENU,
  DELETE_MENU,  
  GET_LIST_MENU,
  FETCHING_MENU,
  GET_DETAIL_MENU,
  MOVE_POSITION_MENU,
  GET_LIST_ROOT_MENU,
	GET_OPTION_LIST_MENU,
	FIX_MENU,
	FIX_MENU_SUBMIT,
	FIX_MENU_ERROR,
} from '../constants';

const initialState={
	list:[],
	options:[],
	roots:[],
	detail:{},
	isFetch:false,
	isSubmit:false,
	error:null,
	success:null,
	fixMenu: {
		isSubmit: false,
		error: null,
		status_code: 0
	}
}

const onGetMenuList = (lastState,payload) => {
	const {data, message, status_code}=payload;
	return { ...lastState, error:null, success:null, list:{ data, message, status_code } };	
}

const onGetDetailMenu = (lastState,payload) => {
	const {data, message, status_code}=payload;
	return { ...lastState, error:null, success:null, detail:{ ...data, message, status_code } };	
}

const onFetchingMenu = (lastState,payload) => {
    if(payload.type==='fetch')
        return { ...lastState, isFetch:payload.status }
    else if(payload.type==='submit')
				return { ...lastState, isSubmit:payload.status }
		else if(payload.type===FIX_MENU_SUBMIT)
			return  {...lastState, fixMenu: { ...lastState.fixMenu, isSubmit: payload.status }}
    return { ...lastState, isFetch:false,isSubmit:false }
}

const onMenuError = (lastState,error) => {
	return { ...lastState, ...error, success:null };		
}

const onAddMenu = (lastState,payload) => {
	const {data, message, status_code}=payload;
    return { ...lastState, detail:{ ...data, message, status_code }, success:'Insert has been successfully!',error:null }
}

const onUpdateMenu = (lastState,payload) => {
	const {data, message, status_code}=payload;
    return { ...lastState, detail:{ ...data, message, status_code }, success:'Update has been successfully!',error:null }
	
}
const onDeleteMenu = (lastState,payload) => {
	const {data, message, status_code}=payload;
    return { ...lastState, detail:{ ...data, message, status_code, delete:true }, success:'Delete has been successfully!',error:null }
}

const onGetOption=(lastState,payload)=>{
	const {data, message, status_code}=payload;
	const options = data.map((item)=>{
		return {value:item.id,label:item.name}
	});
    return { ...lastState, options, success:null,error:null }

}

const onGetRootMenu=(lastState,payload)=>{
	const {data, message, status_code}=payload;
    return { ...lastState, roots:[...data], success:null,error:null }

}

const onFixMenu=(lastState, payload) => {
	return  {...lastState, fixMenu: { ...lastState.fixMenu, error: null, ...payload }}
}

const onFixMenuError=(lastState, payload) => {
	return  {...lastState, fixMenu: { ...lastState.fixMenu, error: { ...payload } }}
}

const menuReducer = (state=initialState, action) => {
	switch(action.type)
	{
		case GET_LIST_MENU: return onGetMenuList(state,action.payload);
		case GET_DETAIL_MENU: return onGetDetailMenu(state,action.payload);
		case FETCHING_MENU: return onFetchingMenu(state,action.payload);
		case MENU_ERROR:return onMenuError(state,action.payload);
		case UPDATE_MENU:return onUpdateMenu(state,action.payload);
		case MOVE_POSITION_MENU:return onUpdateMenu(state,action.payload);
		case ADD_MENU:return onAddMenu(state,action.payload);
		case DELETE_MENU:return onDeleteMenu(state,action.payload);
		case GET_OPTION_LIST_MENU:return onGetOption(state,action.payload)
		case GET_LIST_ROOT_MENU:return onGetRootMenu(state,action.payload)
		case FIX_MENU_SUBMIT:return onFetchingMenu(state, action)
		case FIX_MENU_ERROR:return onFixMenuError(state, action.payload)
		case FIX_MENU:return onFixMenu(state, action.payload);
    case NEW_MENU:return initialState;
		default:return state;
	}
}

export default menuReducer;