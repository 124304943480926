import React, { PureComponent as  Component } from 'react';
import {
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  InputGroup,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
  InputGroupAddon,
  InputGroupText,
  InputGroupButtonDropdown
} from 'reactstrap';
import propTypes from 'prop-types';

export default class SearchForm extends Component{
  render(){
    return(
      <Form onSubmit={this.props.onSubmit}>
        <Row>
          <Col xs={12} sm={12} md={6}>
            <FormGroup>
              <InputGroup>
                <Input
                  type="text"
                  placeholder={this.props.keywordFor === 'users'?"Search email seller, rack...":"Search sneaker name, colourway or SKU..."}
                  name="keyword"
                  value={this.props.keyword}
                  onChange={this.props.onInputChange}
                />
                <InputGroupAddon addonType="append">
                  <InputGroupText addonType="append">Search For { this.props.keywordFor === 'users'?'Seller / Rack':'Products' }</InputGroupText>
                  <InputGroupButtonDropdown addonType="append" isOpen={this.props.isOpen} toggle={this.props.onSearchOptionToggle}>
                    <DropdownToggle split color="primary"/>
                    <DropdownMenu>
                      <DropdownItem header>Search Options</DropdownItem>
                      <DropdownItem onClick={()=>this.props.onHandleSearchForClick('variants')}>For Product</DropdownItem>
                      <DropdownItem onClick={()=>this.props.onHandleSearchForClick('users')}>For Seller / Rack</DropdownItem>
                    </DropdownMenu>
                  </InputGroupButtonDropdown>
                </InputGroupAddon>
              </InputGroup>
            </FormGroup>
          </Col>
        </Row>
      </Form>
    )
  }
}

SearchForm.propTypes= {
  keyword: propTypes.string,
  keywordFor: propTypes.string,
  isSearchOptionOpen: propTypes.bool,
  onInputChange: propTypes.func,
  onSearchOptionToggle: propTypes.func.isRequired,
  onSubmit: propTypes.func.isRequired
}

SearchForm.defaultProps= {
  keyword: '',
  isOpen: false,
  isSearchOptionOpen: false,
  keywordFor: 'variants'
}