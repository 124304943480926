import React, { Component } from 'react';
import { ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { connect } from 'react-redux';
import { logout,getProfile, changePassword, validateToken, extendToken } from '../../actions/authAction';
// import { AUTH_USER_KEY } from '../../constants'
// import { jwtDecode } from '../../utils'
import icon from '../../images/app-icon.png';
import {Link, withRouter} from 'react-router-dom';

import ModalChangePassword from '../Modals/ModalChangePassword';

const ERROR_BLACKLISTED_TOKEN = 'The token has been blacklisted';

class Header extends Component {

  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    // let user=jwtDecode(localStorage.getItem(AUTH_USER_KEY));
    this.state = {
      dropdownOpen: false,
      initialName: 'PW',
      submitChangePassword: false,
      changePasswordResult:null,
      changePasswordError: null
    };
    this.handleRedirectProfile = this.handleRedirectProfile.bind(this);
    this.handleModalChangePasswordToogle = this.handleModalChangePasswordToogle.bind(this);
    this.handleChangePassword = this.handleChangePassword.bind(this);
  }
  
  componentDidMount(){
    if(this.props.auth.isLogged===true){
      // Validate the token.
      this.props.validateToken()
    }
  }

  componentWillReceiveProps(nextProps){
    if((nextProps.auth.isFetch===false&&nextProps.auth.isFetch!==this.props.auth.isFetch)
    ||(nextProps.auth.updateProfile.isSubmit===false&&nextProps.auth.updateProfile.isSubmit!==this.props.auth.updateProfile.isSubmit)){
      if(nextProps.auth.id){
        const { username, first_name, family_name, email } = nextProps.auth;
        let firstWord='', lastWord='';
        if(username){
          const arr = username.split('_');
          if(arr.length>=2){
            firstWord=arr[0][0];
            lastWord=arr[1][0];
          }
          else{
            firstWord=arr[0][0];
            lastWord=arr[0][1];
          }
        }
        else if(first_name || family_name){
          let fullname = (first_name||'');
          if(family_name){
            if(fullname.length)
              fullname+=' ';
            fullname+=family_name;
          }
          const arr = fullname.split(' ');
          if(arr.length>=2){
            firstWord=arr[0][0];
            lastWord=arr[1][0];
          }
          else{
            firstWord=arr[0][0];
            lastWord=arr[0][1];
          }
        }
        else{
          const arr = email.substr(0, email.indexOf('@')).split(/[\.\-\_]/);
          if(arr.length>=2){
            firstWord=arr[0][0];
            lastWord=arr[1][0];
          }
          else{
            firstWord=arr[0][0];
            lastWord=arr[0][1];
          }
        }
        this.setState({initialName:`${firstWord}${lastWord}`.toUpperCase()});
      }
    }
    if(!nextProps.auth.changePassword.isSubmit
      &&nextProps.auth.changePassword.isSubmit!==this.props.auth.changePassword.isSubmit
      &&this.state.submitChangePassword) {
      if(nextProps.auth.changePassword.error !== null){
        const { error: { errors, message, status_code} } = nextProps.auth.changePassword;
        this.setState({
          submitChangePassword: !this.state.submitChangePassword,
          changePasswordError: { errors, message, statusCode: status_code },
          changePasswordResult: null
        })
      }
      else{
        const { message, status_code } = nextProps.auth.changePassword;
        this.setState({
          submitChangePassword: !this.state.submitChangePassword,
          changePasswordError: null,
          changePasswordResult: {
            message,
            statusCode: status_code
          }
        })

      }
    }
    if(nextProps.auth.validateTkn.status_code && nextProps.auth.validateTkn.status_code !== this.props.auth.validateTkn.status_code ){
      const { isValid, status_code } = nextProps.auth.validateTkn;
      if(!isValid){
        switch(status_code){
          case 403:
            // this.props.extendAuth();
            break;
          default:
            this.props.logout();
        }
      }
      else{
        this.props.profile();
      }
    }
  }

  componentDidUpdate(prevProps) {
    if(prevProps.appStatus !== this.props.appStatus && this.props.appStatus === 'offline'){
      this.props.history.push('/503')
    }
    if(prevProps.auth.isFetch && prevProps.auth.isFetch !== this.props.auth.isFetch){
      const { error } = this.props.auth;
      if(error){
        const { status_code, message } = error;
        // console.log("error: ", message, status_code)
        switch(status_code){
          case 401:{
            if(message === ERROR_BLACKLISTED_TOKEN)
              // this.props.extendAuth();
              this.props.logout();
            break;
          }
          default: {
            // console.log("error: ", message, status_code)
            alert("Error: " + message)
            break;
          }
        }
      }
    }
    if(prevProps.auth.refreshTkn.isSubmit && prevProps.auth.refreshTkn.isSubmit !== this.props.auth.refreshTkn.isSubmit){
      const { status_code } = this.props.auth.refreshTkn;
      console.log("TCL: Header -> componentDidUpdate -> this.props.auth.refreshTkn", this.props.auth.refreshTkn)
      if(status_code && status_code === 200) this.props.profile();
      else{
        const { error } = this.props.auth;
        if(error && error.status_code){
          switch(error.status_code){
            case 500: alert("Error: "+ error.message);
            case 401: this.props.logout();
              break;
            default: break;
          }

        }
      }
    }
  }

  toggle() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  }

  sidebarToggle(e) {
    e.preventDefault();
    document.body.classList.toggle('sidebar-hidden');
  }

  sidebarMinimize(e) {
    e.preventDefault();
    document.body.classList.toggle('sidebar-minimized');
  }

  mobileSidebarToggle(e) {
    e.preventDefault();
    document.body.classList.toggle('sidebar-mobile-show');
  }

  asideToggle(e) {
    e.preventDefault();
    document.body.classList.toggle('aside-menu-hidden');
  }

  handleModalChangePasswordToogle(){
    if(this.state.submitChangePassword===false)
      this.setState({
        isOpen: !this.state.isOpen
      })
  }

  handleChangePassword(payload){
    this.setState({
      submitChangePassword:true
    }, ()=> this.props.changePassword(payload))
  }

  handleRedirectProfile(){
    this.props.history.push('/me');
  }

  render() {
    return (
      <header className="app-header navbar">
        <ModalChangePassword
          isOpen={this.state.isOpen}
          onToggle={this.handleModalChangePasswordToogle}
          onSubmit={this.handleChangePassword}
          isLoading={this.state.submitChangePassword}
          result={this.state.changePasswordResult}
          error={this.state.changePasswordError}
        />
        <button className="navbar-toggler mobile-sidebar-toggler d-lg-none" type="button" onClick={this.mobileSidebarToggle}>&#9776;</button>
        <a className="navbar-brand" href="/dashboard"><img src={icon}/></a>
        <ul className="nav navbar-nav d-md-down-none">
          <li className="nav-item">
            <button className="nav-link navbar-toggler sidebar-toggler" type="button" onClick={this.sidebarToggle}>&#9776;</button>
          </li>
          <li className="nav-item px-3">
            <Link to="/dashboard" className="nav-link">Dashboard</Link>
          </li>
          <li className="nav-item px-3">
            <Link to="/users" className="nav-link">Users</Link>
          </li>
          <li className="nav-item px-3">
            <Link to="/sales/all" className="nav-link">Sales</Link>
          </li>
          <li className="nav-item px-3 d-none">
            <a className="nav-link" href="#">Settings</a>
          </li>
        </ul>
        <ul className="nav navbar-nav ml-auto">
          <li className="nav-item d-md-down-none">
            <a className="nav-link" href="#"><i className="icon-bell"></i><span className="badge badge-pill badge-danger">5</span></a>
          </li>
          <li className="nav-item d-md-down-none">
            <a className="nav-link" href="#"><i className="icon-list"></i></a>
          </li>
          <li className="nav-item d-md-down-none">
            <a className="nav-link" href="#"><i className="icon-location-pin"></i></a>
          </li>
          <li className="nav-item">
            <ButtonDropdown id="loginAs" isOpen={this.state.dropdownOpen} toggle={this.toggle}>
              <DropdownToggle caret>
                <div className="initialName">
                  <strong>{this.state.initialName}</strong>
                </div>
              </DropdownToggle>

              <DropdownMenu className="dropdown-menu-right">
                <DropdownItem header className="text-center"><strong>Settings</strong></DropdownItem>

                <DropdownItem onClick={this.handleRedirectProfile}><i className="fa fa-user"></i> Profile</DropdownItem>
                <DropdownItem  onClick={this.handleModalChangePasswordToogle}><i className="fa fa-wrench"></i> Change Password</DropdownItem>
                <DropdownItem onClick={this.props.logout}><i className="fa fa-lock"></i> Logout</DropdownItem>

              </DropdownMenu>
            </ButtonDropdown>
          </li>
          <li className="nav-item d-md-down-none d-none">
            <button className="nav-link navbar-toggler aside-menu-toggler" type="button" onClick={this.asideToggle}>&#9776;</button>
          </li>
        </ul>
      </header>
    )
  }
}

const mapStateToProps= (state)=>{
  return{
    auth:state.auth,
    appStatus: state.setting.appStatus
  }
}
const mapDispatchToProps= (dispatch)=>{
  return{
    extendAuth: () => dispatch(extendToken()),
    validateToken: () => dispatch(validateToken()),
    changePassword: payload => dispatch(changePassword(payload)),
    logout:()=> dispatch(logout()),
    profile:()=> dispatch(getProfile())
  }
}
export default connect(mapStateToProps,mapDispatchToProps)(withRouter(Header));
