import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import DateToHuman from '../../../components/DateToHuman';
import { Radio } from '../../../components/Form/Radios';
import { saveRaffleGroup } from '../../../actions/raffleAction';

class RowItem extends React.Component{
  constructor(props){
    super(props)
    this.state = {
      name: props.name,
      ticket_name: props.ticket_name,
      color: props.color,
      active: props.active,
      activeEdit: props.active,
      updatedAt:props.updated_at,
      body: {
        ...this.obtainOtherData(props)
      }
    }
  }

  obtainOtherData = data => {
    return {
      name: data.name,
      ticket_name: data.ticket_name,
      color: data.color,
      active: data.active,
    }
  }

  shouldComponentUpdate(nextProps){
    const { dataRaffleGroup } = this.props;
    const { dataRaffleGroup: nextRaffle } = nextProps;
    if(nextRaffle.isSubmit && !dataRaffleGroup.isSubmit){
      return false;
    }
    if(nextRaffle.isSubmit !== dataRaffleGroup.isSubmit && nextRaffle.data && nextRaffle.data.id !== this.props.id){
      return false;
    }
    return true;
  }

  componentDidUpdate(prevProps){
    if(prevProps.dataRaffleGroup.isSubmit && !this.props.dataRaffleGroup.isSubmit){
      const { data: {id, ...data}, error } = this.props.dataRaffleGroup;
      if(!error && id === this.props.id){
        this.setState({
          activeEdit: data.active,
          active: data.active,
          updatedAt: data.updated_at,
          body: {
            ...this.obtainOtherData(data)
          }
        })
      }
      else if(error){
        this.setState({
          activeEdit: this.state.active
        })
      }
    }
  }

  toggleMenu = () => {
    this.setState({ dropdownMenu: !this.state.dropdownMenu });
  }

  handleChange = async (e) => {
    await this.setState({
      [e.target.name]: e.target.type === 'checkbox'? e.target.checked: e.target.value
    })
    const { id } = this.props;
    const { activeEdit, body } = this.state;
    const payloads = {
      id,
      ...body,
      active: activeEdit
    }
    this.props.saveRaffleGroup(payloads)
  }

  render(){
    return(
      <tr>
        <th className="v-center" scope="row">{ this.props.rowNumber }</th>
        <td className="v-center">
          {this.state.name}<br/>
          <div style={{fontSize: '.65rem'}}>
            <span>Ticket name: {this.state.ticket_name?this.state.ticket_name : '-'}</span>
          </div>
        </td>
        <td className="v-center">
          <Radio
            type="checkbox"
            name="activeEdit"
            value={true}
            checked={this.state.activeEdit}
            dataOnText="Yes"
            dataOffText="No"
            onChange={this.handleChange}
          />
        </td>
        <td className="v-center">
          <DateToHuman  value={this.state.updatedAt} id={`tooltip_updated_at_${this.props.rowNumber}`}/>
        </td>
        <td className="v-center">
          <Link to={`/raffle_group/${this.props.id}`} className="btn btn-link">Details</Link>
        </td>
      </tr>
    )
  }
}

const mapStateToProps=({ raffle })=>({
  dataRaffleGroup: raffle.detailsGroup,
})
const mapDispatchToProps=(dispatch)=>({
  saveRaffleGroup: (payloads) => dispatch(saveRaffleGroup(payloads)),
})
export default connect(mapStateToProps, mapDispatchToProps)(RowItem);
