import React, { useEffect, useMemo, useState } from "react";
import { AUTH_TOKEN_KEY } from "../../../constants";
import api from "../../../utils/Api";
import debounce from "debounce-promise";
import Select from "react-select";

const SelectSizeAsync = (props) => {
    const { value, onChange, productId, additionalQuery = {}, ...rest } = props;
    const myToken = useMemo(() => localStorage.getItem(AUTH_TOKEN_KEY), []);
    const headers = useMemo(
        () => ({
            Authorization: `Bearer ${myToken}`,
        }),
        [myToken]
    );

    const [triggerUpdate, setTriggerUpdate] = useState(1);
    const [_isFetching, setIsFetching] = useState(false);

    useEffect(() => {
        setTriggerUpdate(triggerUpdate + 1);
    }, [productId]);

    const _getSizeOptions = (_input) => {
        if (!productId) {
            return Promise.resolve([]);
        }

        setIsFetching(true);
        return api(headers)
            .get(`/sizes/variants/${productId}`)
            .then((response) => {
                setIsFetching(false);
                const options = response.data.data.map((item) => ({
                    id: item.id,
                    US: item.US,
                }));

                return {
                    options,
                };
            });
    };

    const getSizeOptions = debounce(async (inputValue) => {
        return _getSizeOptions(inputValue);
    }, 800);

    return (
        <Select.Async
            key={`select-size-${triggerUpdate}`}
            valueKey="id"
            labelKey="US"
            defaultOptions={true}
            loadOptions={getSizeOptions}
            onChange={onChange}
            value={value}
            placeholder="Select size..."
            searchable={false}
            searchPromptText={productId ? "" : "Please select a product first"}
            clearable={false}
            {...rest}
        />
    );
};

export default SelectSizeAsync;
