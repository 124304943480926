import React, { PureComponent as  Component } from 'react';
import {
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Row,
  Container
} from 'reactstrap';
import propTypes from 'prop-types';

const filterStatusOptions = [
  {name:'All',value:''},
  {name:'Pending', value:'pending'},
  {name:'Processed', value:'processed'},
  {name:'Completed', value:'completed'},
  {name:'Failed', value:'failed'},
];
export default class FilterForm extends Component{
  render(){
    return(
      <Form inline onSubmit={this.props.onSubmit}>
        <Container style={{padding: 0}}>
          <Row className="mb-1">
            <Col xs={12} sm={3}>
              <FormGroup row className="mb-3">
                <Col xs={12} sm={4} md={3}>
                  <Label className="justify-content-start">
                    Status
                  </Label>
                </Col>
                <Col xs={12} sm={8} md={9}>
                  <Input
                    className="w-100"
                    type="select"
                    name="status"
                    onChange={this.props.onInputChange}
                    value={this.props.status}
                  >
                    {filterStatusOptions.map((option,i)=><option value={option.value} key={i}>{option.name}</option>)}
                  </Input>
                </Col>
              </FormGroup>
            </Col>
            <div className="w-100"></div>
            <Col xs={12} sm={6} className="d-flex justify-content-end">
              <Button color="primary">Apply</Button>
            </Col>
          </Row>
        </Container>
      </Form>
    )
  }
}

FilterForm.propTypes= {
  status: propTypes.string,
  onInputChange: propTypes.func.isRequired,
  onSubmit: propTypes.func.isRequired
}

FilterForm.defaultProps= {
  status: '',
}