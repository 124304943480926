import React from 'react';
import { FontAwesomeIcon } from '../../../components/Icons';
import Paragraph from '../../../styled/Typograph/Paragraph';
import SmallInline from '../../../styled/Typograph/Small/SmallInline';
import BadgeThin from '../../../components/Badges/BadgeThin';

export const TitleColumn = props => {
  const { id, slug, name, categories, peoples, createdAt, collectionType, itemCount, parentId, parent, uiTemplate } = props;
  return(
    <div>
      <SmallInline className="text-muted mr-1">#{id} {`${parent? `${parent.slug} / `: ""}${slug}`}</SmallInline>
      <Paragraph>
        {uiTemplate? (
          <BadgeThin color="success mr-1">{uiTemplate}</BadgeThin>
        ):null}
        {parentId? (
          <BadgeThin className="mr-1">PARENT (#{parentId})</BadgeThin>
        ):null}
        {collectionType? (
          <BadgeThin className="text-uppercase">
            {uiTemplate === 'PARENT'? "COLLECTIONS": collectionType==='product_variants'? 'variants': collectionType}
          </BadgeThin>)
        :null}
      </Paragraph>
      <Paragraph>
        {name}
      </Paragraph>
      {categories.filter(item => item.pivot.active).length?(
        <Paragraph>
          {categories.filter(item => item.pivot.active).map((item) => (
            <BadgeThin className="text-uppercase mr-1" color="primary" key={item.id}>
              <FontAwesomeIcon iconType="tags" className="mr-1"/>{ item.name }
            </BadgeThin>
          ))}
        </Paragraph>
      ):null}
      <SmallInline>
        {peoples?(
          <BadgeThin className="mr-1" color="success">
            <FontAwesomeIcon iconType="user"/> peoples
          </BadgeThin>
        ):null}
        <span className="mr-1">
          <FontAwesomeIcon iconType="calendar" className="mr-1"/>{ createdAt }
        </span>
        ({itemCount} items)
      </SmallInline>
    </div>
  )
}
