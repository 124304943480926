import React from 'react';
import propTypes from 'prop-types';
import {
  Form,
  FormGroup,
  Button,
  Row,
  Col,
  Input,
  Label
} from 'reactstrap';

import { sellRequestStatusOptions } from '../../../../constants/kickavenue';

const FilterForm = props => {

  return (
    <Form className="form-filter" onSubmit={props.onSubmit}>
      <Row>
        <Col xs={12} md={8}>
          <div className="d-flex" style={{flexDirection: 'column'}}>
            <div className="d-flex" style={{padding: "0px 1rem"}}>
              <FormGroup className="d-flex" style={{flexDirection: 'column', alignItems: 'flex-start'}} check>
                <Label className="mr-sm-3" style={{fontWeight: 700}}>
                  Status
                </Label>
                <div className="mr-2">
                  <Label check className="mr-4">
                    <Input
                      type="checkbox"
                      name="status"
                      value="default"
                      checked={props.isCheckedStatus('default')}
                      onChange={(e) => props.onStatusesInputChange(e)}
                    /> Default
                  </Label>
                  <Label check className="mr-4">
                    <Input
                      type="checkbox"
                      name="status"
                      value="all"
                      checked={props.isCheckedStatus('all')}
                      onChange={(e) => props.onStatusesInputChange(e)}
                    /> All
                  </Label>
                  <div className="w-100 mb-2"></div>
                  {sellRequestStatusOptions.map((option, i) => (
                    <Label check className="mr-4 mb-2" key={i}>
                      <Input
                        type="checkbox"
                        name="status"
                        value={option.value}
                        checked={props.isCheckedStatus(option.value)}
                        onChange={(e) => props.onStatusesInputChange(e)}
                      /> {option.label}
                    </Label>
                  ))}
                </div>
              </FormGroup>
            </div>
            <div className="d-flex" style={{padding: "0px 1rem"}}>
              <FormGroup className="d-flex" style={{flexDirection: 'column', alignItems: 'flex-start'}} check>
                  <Label className="mb-2" style={{fontWeight: 700}}>
                    Sneakers Condition
                  </Label>
                  <div className="d-flex mr-2 mb-2">
                      <Label check className="mr-4">
                        <Input type="checkbox" name="sneakers_condition" value="default" checked={props.isCheckedCondition("default")} onChange={(e) => props.onConditionsInputChange(e)} /> Default
                      </Label>
                      <Label check className="mr-4">
                        <Input type="checkbox" name="sneakers_condition" value="all" checked={props.isCheckedCondition("all")} onChange={(e) => props.onConditionsInputChange(e)} /> All
                      </Label>
                  </div>
                  <div className="d-flex mr-2 mb-2">
                      <Label check className="mr-4">
                        <Input type="checkbox" name="sneakers_condition" value="BARU" checked={props.isCheckedCondition("BARU")} onChange={(e) => props.onConditionsInputChange(e)} /> Brand New
                      </Label>
                      <Label check className="mr-4">
                        <Input type="checkbox" name="sneakers_condition" value="STANDARD_99" checked={props.isCheckedCondition("STANDARD_99")} onChange={(e) => props.onConditionsInputChange(e)} /> Standard 99%
                      </Label>
                      <Label check className="mr-4">
                        <Input type="checkbox" name="sneakers_condition" value="BEKAS" checked={props.isCheckedCondition("BEKAS")} onChange={(e) => props.onConditionsInputChange(e)} /> Used
                      </Label>
                  </div>
              </FormGroup>
            </div>
            <div className="d-flex" style={{padding: "0px 1rem"}}>
              <FormGroup className="d-flex" style={{flexDirection: 'column', alignItems: 'flex-start'}} check>
                  <Label className="mb-2" style={{fontWeight: 700}}>
                    Availability
                  </Label>
                  <div className="d-flex mr-2">
                    <FormGroup check className="mr-4">
                      <Label check>
                        <Input type="radio" name="pre_order" value="" checked={props.pre_order === ''} onChange={props.onCheckedChange} /> All
                      </Label>
                    </FormGroup>
                    <FormGroup check className="mr-4">
                      <Label check>
                        <Input type="radio" name="pre_order" value="0" checked={props.pre_order === '0'} onChange={props.onCheckedChange} /> Only Available
                      </Label>
                    </FormGroup>
                    <FormGroup check className="mr-4">
                      <Label check>
                        <Input type="radio" name="pre_order" value="1" checked={props.pre_order === '1'} onChange={props.onCheckedChange} /> Only Preorder
                      </Label>
                    </FormGroup>
                  </div>
              </FormGroup>
            </div>
            
            <div className="d-flex" style={{padding: "0px 1rem"}}>
              <FormGroup className="d-flex" style={{flexDirection: 'column', alignItems: 'flex-start'}} check>
                  <Label className="mb-2" style={{fontWeight: 700}}>
                    Pre-Verified
                  </Label>
                  <div className="d-flex mr-2">
                    <FormGroup check className="mr-4">
                      <Label check>
                        <Input type="radio" name="pre_verified" value="" checked={props.pre_verified === ''} onChange={props.onCheckedChange} /> All
                      </Label>
                    </FormGroup>
                    <FormGroup check className="mr-4">
                      <Label check>
                        <Input type="radio" name="pre_verified" value="1" checked={props.pre_verified === '1'} onChange={props.onCheckedChange} /> Only Pre verified
                      </Label>
                    </FormGroup>
                    <FormGroup check className="mr-4">
                      <Label check>
                        <Input type="radio" name="pre_verified" value="0" checked={props.pre_verified === '0'} onChange={props.onCheckedChange} /> Only Not Pre verified
                      </Label>
                    </FormGroup>
                  </div>
              </FormGroup>
            </div>

            <div className="d-flex" style={{padding: "0px 1rem"}}>
              <FormGroup className="d-flex" style={{flexDirection: 'column', alignItems: 'flex-start'}} check>
                  <Label className="mb-2" style={{fontWeight: 700}}>
                    Consignment
                  </Label>
                  <div className="d-flex mr-2">
                    <FormGroup check className="mr-4">
                      <Label check>
                        <Input type="radio" name="consignment" value="" checked={props.consignment === ''} onChange={props.onCheckedChange} /> All
                      </Label>
                    </FormGroup>
                    <FormGroup check className="mr-4">
                      <Label check>
                        <Input type="radio" name="consignment" value="1" checked={props.consignment === '1'} onChange={props.onCheckedChange} /> Only Consignment
                      </Label>
                    </FormGroup>
                    <FormGroup check className="mr-4">
                      <Label check>
                        <Input type="radio" name="consignment" value="0" checked={props.consignment === '0'} onChange={props.onCheckedChange} /> Only Not Consignment
                      </Label>
                    </FormGroup>
                  </div>
              </FormGroup>
            </div>
            <div className="d-flex" style={{padding: "0px 1rem"}}>
              <FormGroup className="mb-2" style={{alignSelf: 'flex-start'}}>
                <Button color="primary" >Apply Filter</Button>
              </FormGroup>
            </div>
          </div>
        </Col>
      </Row>
    </Form>
  )
}
export default FilterForm;

FilterForm.propTypes= {
  sneakers_condition: propTypes.oneOf(['','BARU', 'BEKAS']),
  status: propTypes.string,
  pre_order: propTypes.string,
  onInputChange: propTypes.func.isRequired,
  onStatusesInputChange: propTypes.func.isRequired,
  isCheckedStatus: propTypes.func.isRequired,
  onSubmit: propTypes.func.isRequired
}

FilterForm.defaultProps= {
  sneakers_condition: '',
  status: '',
  pre_order: '',
  consignment: ''
}