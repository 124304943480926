import React, { Component } from 'react';
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Form,
    FormGroup,
    Input,
    Label,
    Collapse,
    Button
} from 'reactstrap';
import { errorMessage, hasError } from '../../../utils/form';
import classnames from 'classnames';
import propTypes from 'prop-types';
import ButtonLoading from '../../../components/Button/ButtonLoading';
import Loading from 'react-loading-animation';
import { bulkStockFormats } from '../../../config';
import axios from 'axios';
import { API_BASE_URL, AUTH_TOKEN_KEY, BULK_STOCK_ROUTE_API } from '../../../constants';

export default class ModalBulkUpload extends Component{
  constructor(props){
    super(props);
    this.state = {
      _bulkFormat: '',
      _submitProgress: false,
      _completed: false,
      _taskDone: 0,
      _taskAvailable: 0,
      _error: null,
      _file: null,
      _link: '',
      _bulkOptionSelected: 'link',
      _feedbackFile: null,
      _hasFileError: false
    }
    this._toggleModal = this._toggleModal.bind(this);
    this._handleCollapse = this._handleCollapse.bind(this);
    this._handleSubmit = this._handleSubmit.bind(this);
    this._onSubmit = this._onSubmit.bind(this);
    this._handleChange=this._handleChange.bind(this);
    this._downloadFeedbackFile=this._downloadFeedbackFile.bind(this);
  }

  componentDidMount(){
    this.setState({
      _bulkFormat: bulkStockFormats.reduce((prev,current)=>`${prev}, ${current}`)
    })
  }

  componentWillReceiveProps(nextProps){
    // Set Default whe modal closing.
    if(nextProps.isOpen!==this.props.isOpen&&nextProps.isOpen===false){
      this.setState({
        _file: null,
        _link: '',
        _error: null,
        _hasFileError:false,
        _feedbackFile:null
      })
    }
    // Receiving props when _submitProgress is true
    else if(this.state._submitProgress){
      const {_bulkOptionSelected} = this.state;
      // When post into api
      if(!nextProps.dataBulk.isSubmit&&nextProps.dataBulk.isSubmit!==this.props.dataBulk.isSubmit){
        // Check if its error or not
        if(nextProps.dataBulk.error!==null){
          const {errors, status_code, message}=nextProps.dataBulk.error;
          const _errors={};
          if(status_code==422){
            Object.keys(errors).forEach(key=>{
              const newKey=`_${key}`;
              _errors[newKey]=[ errors[key][0] ];
            })
          }
          else{
            if(_bulkOptionSelected==='link'){
              _errors['_link']=[ message ];
            }
            else
              _errors['_file']=[ message ];
          }
          this.setState({
            _submitProgress:false,
            _completed: false,
            _error:{...nextProps.dataBulk.error,errors:_errors},
            _taskDone:0,
            _taskAvailable:0
          });
        }
        else if(this.props.dataBulk.success.status_code===200){
          let _taskDone = this.state._taskDone+1;
          this.setState({
            _submitProgress:!(this.state._taskAvailable==_taskDone),
            _error:null,
            _taskDone,
            _completed:this.state._taskAvailable==_taskDone
          });
        }
      }
      else if(!nextProps.dataBulk.isUpload&&nextProps.dataBulk.isUpload!==this.props.dataBulk.isUpload){
        // Check if its error or not
        if(nextProps.dataBulk.error!==null){
          this.setState({
            _submitProgress:false,
            _taskAvailable:0,
            _taskDone:0,
            _completed:false,
            _error:{
              errors:{
                _file:['Error when uploading.']
              }
            }
          });

        }
        else if(nextProps.dataBulk.success.url!==undefined){
          let _taskDone = this.state._taskDone+1;
          this.setState({_taskDone,_linkUpload:nextProps.dataBulk.success.url},()=>{
            this.props.onSubmit({link:nextProps.dataBulk.success.url});
          });
        }
      }
    }
  }

  componentDidUpdate(prevProps, prevState){
    if(prevState._completed!==this.state._completed&&this.state._completed===true){
      // Clearing or set default bulkUpload state in redux.
      this.props.onClear();
      setTimeout(()=>{
        // Refresh list when its completed.
        if(this.props.refreshAfterDone)
          this.props.onRefresh();
        this.setState({
          _completed:false,
          _taskAvailable:0,
          _taskDone:0,
          _link:'',
          _file:null}, ()=>{
          if(this.props.isOpen)
            this._toggleModal();
        })
      },3000)
    }
  }

  _toggleModal(){
    return this.state._submitProgress || this.props.onToggle();
  }

  _handleCollapse(e){
    this.setState({
      _bulkOptionSelected: e.target.value 
    })
  }

  _handleChange(e){
    const newState={...this.state};
    newState[e.target.name]=e.target.value;
    this.setState({...newState});
  }

  _handleSubmit(e){
    e.preventDefault();
    let _taskAvailable = 1;
    const _submitProgress = true, _taskDone = 0;
    if(this.state._bulkOptionSelected=='link'){
      const regexLink=/((([A-Za-z]{3,9}:(?:\/\/)?)(?:[\-;:&=\+\$,\w]+@)?[A-Za-z0-9\.\-]+|(?:www\.|[\-;:&=\+\$,\w]+@)[A-Za-z0-9\.\-]+)((?:\/[\+~%\/\.\w\-_]*)?\??(?:[\-\+=&;%@\.\w_]*)#?(?:[\.\!\/\\\w]*))?)/;
      let errors={_link:[]};
      if(this.state._link===''){
        errors._link.push('The link is required.');
      }
      if(this.state._link.match(regexLink)===null){
        errors._link.push('The link is invalid url.');
      }
      if(errors._link.length==0)
        this.setState({_submitProgress, _taskDone, _taskAvailable},()=>{
          const {_link}=this.state;
          // this.props.onSubmit({link:_link});
          this._onSubmit(_link)

        })
      else{
        const {_error}=this.state;
        this.setState({_error:{..._error,errors}})
      }
    }
    else{
      let errors={_file:[]};
      if(this.state._file!==null){
        if(this.state._file.name.match(/.+(\.csv)$/)===null){
          errors._file.push('The file must be in .csv extension');
          this.setState({_error:{...this.state._error,errors}});
        }
        else {
          _taskAvailable = 2;
          this.setState({_submitProgress, _taskDone, _taskAvailable},()=>{
            this.props.onUpload(this.state._file,'data');
          })
        }
      }
      else{
        errors._file.push('The file is required');
        this.setState({_error:{...this.state._error,errors}});
      }

    }
  }

  async _onSubmit(link) {
    this.setState({ _hasFileError: false, _error: null })
    const url = API_BASE_URL + BULK_STOCK_ROUTE_API;
    const body = new FormData();
    body.append('link', link);

    try {
      const response = await axios
      .post(url, body, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem(AUTH_TOKEN_KEY),
          'Content-Type': 'multipart/form-data',
        },
        responseType: 'blob',
      })
      .then(async (res) => {
        if(res.status === 200 && res.data?.type === "application/json"){
          const resJSON = JSON.parse(await res.data.text());

          return resJSON;
        }

        return res.data;
      });

      this.setState({ _feedbackFile: response })
      if (response.status_code === 200 && response.message) {
        let _taskDone = this.state._taskDone+1;
        this.setState({
          _hasFileError: false,
          _submitProgress:!(this.state._taskAvailable==_taskDone),
          _error:null,
          _taskDone,
          _completed:this.state._taskAvailable==_taskDone
        });
      } else {
        const _errors = {}
        _errors['_link'] = ["There’s an issue with the file you uploaded. Review the file below and try uploading it again."]
        this.setState({
          _hasFileError:true,
          _submitProgress:false,
          _completed: false,
          _error: { errors: _errors }
        });
      }
    } catch (error) {
      const _errors = {}
      _errors['_link'] = ["Internal Server Error."]
      this.setState({
        _submitProgress:false,
        _completed: false,
        _error: { errors: _errors }
      });
    }
  }

  _downloadFeedbackFile(e) {
    e.preventDefault()
    const file = this.state._feedbackFile
    let fileName = 'kick-feedback-products.xlsx'
    const link = document.createElement('a');
    const blobUrl = URL.createObjectURL(file);
    link.href = blobUrl;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    URL.revokeObjectURL(blobUrl);
    link.parentElement.removeChild(link);
  };

  render(){
    return(
      <Modal
        isOpen={this.props.isOpen}
        toggle={this._toggleModal}
      >
        <ModalHeader toggle={this._toggleModal}>
          <i className="fa fa-upload"></i> Bulk Upload (.csv)
        </ModalHeader>
        <ModalBody>
          <p>
            For Mac user with Microsoft Excel, please choose Windows comma separated when saving or export the csv file.
            <br/>
            The columns for CSV are:<br/>
            <small>
              {this.state._bulkFormat}
            </small>
          </p>
          <Loading isLoading={(this.state._submitProgress||this.state._completed)}>
            <Form onSubmit={this._handleBulkSubmit}  encType="multipart/form-data">
              <FormGroup check>
                {/* <Label check>
                  <Input type="radio" name="radio1" value="upload" onChange={this._handleCollapse} checked={this.state._bulkOptionSelected==='upload'} />{' '}
                  Upload a .csv file
                </Label>
                &nbsp;
                &nbsp; */}
                <Label check>
                  <Input type="radio" name="radio1" value="link" onChange={this._handleCollapse}  checked={this.state._bulkOptionSelected==='link'}/>{' '}
                  Using a link
                </Label>
              </FormGroup>
              <Collapse isOpen={this.state._bulkOptionSelected==='upload'}>
                <FormGroup className={classnames({'has-danger has-feedback':hasError('_file',{...this.state._error})})}>
                  <Label>Upload a selected file (.csv)</Label>
                  <Input type="file" accept=".csv" onChange={(e)=>this.setState({_file:e.target.files[0]})}/>
                  {errorMessage('_file',{...this.state._error})}
                </FormGroup>
              </Collapse>
              <Collapse isOpen={this.state._bulkOptionSelected==='link'}>
                <FormGroup className={classnames({'has-danger has-feedback':hasError('_link',{...this.state._error})})}>
                  <Input type="text" name="_link" onChange={this._handleChange} placeholder="Input a link." value={this.state._link} />
                  {errorMessage('_link',{...this.state._error})}
                </FormGroup>
              </Collapse>
              {this.state._hasFileError ? (
                <FormGroup>
                  <Button color="warning" onClick={this._downloadFeedbackFile}>Download Feedback File</Button>
                </FormGroup>
              ) : null}
            </Form>
          </Loading>
          <p className={classnames("text-center",{"d-none":(!this.state._submitProgress&&!this.state._completed)})}>
            Tasks: {`${this.state._taskDone}/${this.state._taskAvailable}`} completed.
          </p>
        </ModalBody>
        <ModalFooter>
          <ButtonLoading color="primary" isLoading={(this.state._submitProgress||this.state._completed)} onClick={this._handleSubmit}>Submit</ButtonLoading>{' '}
          <ButtonLoading color="secondary" isLoading={(this.state._submitProgress||this.state._completed)} onClick={this._toggleModal} loadingMessage="Cancel">Cancel</ButtonLoading>
        </ModalFooter>
      </Modal>
    )
  }
}

ModalBulkUpload.propTypes = {
  isOpen: propTypes.bool.isRequired,
  refreshAfterDone: propTypes.bool,
  dataBulk: propTypes.object.isRequired,
  onToggle: propTypes.func,
  onRefresh: propTypes.func,
  onUpload: propTypes.func,
  onSubmit: propTypes.func.isRequired,
  onClear: propTypes.func
}
ModalBulkUpload.defaultProps = {
  refreshAfterDone: false
}
