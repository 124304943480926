import React from 'react';
import propTypes from 'prop-types';
import Select from 'react-select';
import api from '../../../../utils/Api';

class SelectBeneficiaryBank extends React.Component{
  constructor(props){
    super(props);
    this.loadSelectOptions = this.loadSelectOptions.bind(this);
    this._loadBankOption = this._loadBankOption.bind(this);
  }

  loadSelectOptions(input){
    return this.refs[this.props.id].loadOptions(input);
  }

  _loadBankOption(input){
    const params = {
      keyword: input,
    }
    return api().get('/beneficiary-banks', { params })
    .then(({ data }) => {
      const banks = data.data.map(item=>{
        return {
          name: item.name.toUpperCase() + ' - ' + item.code.toUpperCase(),
          value: item.name.toUpperCase(),
        }
      })
      if(this.props.onSelectSearch){
        this.props.onSelectSearch(input)
      }
      return { options: banks };
    });
  }

  render(){
    return(
      <Select.Async
        valueKey="value"
        labelKey="name"
        defaultOptions
        ref={this.props.id}
        loadOptions={this._loadBankOption}
        cacheOptions={this.props.cacheOptions}
        name={this.props.name}
        value={this.props.value}
        placeholder={this.props.placeholder}
        noResultsText={this.props.noResultsText}
        onChange={this.props.onSelectChange}
        onOpen={this.props.onSelectOpen}
      />
    )
  }
}

export default SelectBeneficiaryBank;

SelectBeneficiaryBank.defaultProps = {
  cacheOptions: false,
  value: null,
  placeholder: 'Select a bank...',
  noResultsText: 'No Result!',
}

SelectBeneficiaryBank.propTypes = {
  name: propTypes.string,
  value: propTypes.oneOfType([propTypes.object, propTypes.array, propTypes.string]),
  placeholder: propTypes.string,
  noResultsText: propTypes.string,
  cacheOptions: propTypes.bool,
  onSelectSearch: propTypes.func,
  onSelectChange: propTypes.func,
  onSelectOpen: propTypes.func,
}