import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { 
    Row,
    Col,
    Card,
    CardBody,
    CardText
} from 'reactstrap';

import {Tbl, TblHead, TblBody} from '../../components/Tbl';
import Paginations from '../../components/Paginations';
import PaginationSummaries from '../../components/Paginations/PaginationSummaries';
import FilterPanel from './Filters';
import FilterContainer from '../../components/Container/FilterContainer/FilterContainer';
import { CardHeaderWithToolbars,Title } from '../../components/Card/CardHeaderWithToolbars';
import Toolbars from './Filters/Toolbars'
import DateToHuman from '../../components/DateToHuman';

import { generateNumber } from '../../utils';
import { DEFAULT_SORT_BY, DEFAULT_PER_PAGE } from '../../constants/settings';
import { getCategoriesList } from '../../actions/categoriesAction';
import privateView from '../../components/hocs/privateView';
import Analytics from "../../services/Analytics";

const dataHeader=[
  'No.', 'Name', 'Created','Updated', 'Action'
];

const defaultParams = {
  sort_by : DEFAULT_SORT_BY,
  per_page : DEFAULT_PER_PAGE
}

class CategoriesIndex extends Component{
  constructor(){
    super();
    this.state={
      _params:{
        ...defaultParams
      },
      _filterOpen: false
    }
    this._handleToggleFilterPanel = this._handleToggleFilterPanel.bind(this);
    this._handleParamsChange = this._handleParamsChange.bind(this);
    this._handleParamsChangeAndSubmit = this._handleParamsChangeAndSubmit.bind(this);
    this._handleGoto = this._handleGoto.bind(this);
    this._handleRefresh = this._handleRefresh.bind(this);
    this._handleResetFilter = this._handleResetFilter.bind(this);
  }

  componentDidMount(){
    const { email } = this.props.injectedProps;
    this._getList(this.state._params);
    Analytics.recordPageView('category_list', email, {
      url: window.location.href,
      location: this.props.location,
      match: this.props.match
    });
  }

  _toggleFilterPanel(){
    this.setState({_filterOpen:!this.state._filterOpen})
  }

  _handleToggleFilterPanel(e){
    e.preventDefault();
    this._toggleFilterPanel();
  }

  _onRenderRowItem(){
    if(this.props.masterCategories.list.status_code===200 && this.props.masterCategories.list.data.length>0){
      const { current_page,per_page } =  this.props.masterCategories.list;
      return this.props.masterCategories.list.data.map((item,index)=>
        (
          <tr key={generateNumber(per_page,current_page,index)}>
            <th scope="row">{ generateNumber(per_page,current_page,index) }</th>	
            <td>{ item.name }</td>
            <td>{ <DateToHuman value={item.created_at} id={`tooltip_createdAt_${generateNumber(per_page,current_page,index)}`}/> }</td>
            <td>{ <DateToHuman value={item.updated_at} id={`tooltip_updatedAt_${generateNumber(per_page,current_page,index)}`}/> }</td>
            <td>
              <Link to={`/categories/${item.id}`} className="btn btn-link">Details</Link>
            </td>
          </tr>
        )
      )	
    }
    return null;
  }

    /**
	 * Function to handle rendering paginations
	 * @author pewe
	 */
  _onRenderPagination(){
    const { status_code, data } = this.props.masterCategories.list;
    const hasData = (status_code===200&&data.length)||null;
    return hasData && (
      <Paginations 
        size="sm" 
        lastPage={this.props.masterCategories.list.last_page} 
        perPage={this.props.masterCategories.list.per_page}
        total={this.props.masterCategories.list.total}
        maxPage={8}
        path={this.props.masterCategories.list.path}
        currentPage={this.props.masterCategories.list.current_page} 
        prevPageUrl={this.props.masterCategories.list.prev_page_url} 
        nextPageUrl={this.props.masterCategories.list.next_page_url} 
        handleGotoCallback={this._handleGoto}
      />
    )
  }

	/**
	 * Handle callback when user click pagination items
	 * @author pewe
	 * @param {integer} page 
	 */
	_handleGoto(page){
    const _params=this.state._params;
    _params.page=page;
    this.setState({_params},()=>this._getList(this.state._params));
  }

  _getList(rawParams = {}){
    let params = {};
    Object.keys(rawParams)
    .filter(key => rawParams[key]!='')
    .forEach(key=> params[key] = rawParams[key]);
    this.props.getList({params});
  }

  _refreshList(){
    const {page, ..._params} = this.state._params;
    this.setState({
      _params
    },()=>this._getList(this.state._params));
  }

  _handleParamsChange(e){
    const { _params } = this.state;
    _params[e.target.name] = e.target.value;
    this.setState({_params});
  }

  _handleParamsChangeAndSubmit(e){
    // Exclude page...
    const { page , ..._params } = this.state._params;
    _params[e.target.name] = e.target.value;
    this.setState({_params},()=>{
      this._getList(this.state._params);
    });    
  }

  _handleDisplayChange(e){
    const { _params } = this.state;
    _params[e.target.name] = e.target.value;
    _params.page = 1;
    this.setState({_params},()=>{
      this._getList(this.state._params);
    });    
  }

  _handleRefresh(e){
    e.preventDefault();
    this._refreshList();
  }

  _handleResetFilter(e){
    e.preventDefault();
    const newState = this.state._filterOpen?{_filterOpen:!this.state._filterOpen}:{};
    this.setState({
      ...newState,
      _params:{
        ...defaultParams
      }
    },()=>this._getList(this.state._params));
  }
    
  render(){
    return(
      <div className="animated fadeIn">
        <Row>
          <Col xs={12}>
            <Card>
              <CardHeaderWithToolbars>
                <Title>
                  <i className="fa fa-align-justify"></i>
                  <span>Category List</span>
                </Title>
                <Toolbars
                  isOpen={this.state._filterOpen}
                  onToggleFilter={this._handleToggleFilterPanel}
                  onRefreshList={this._handleRefresh}
                  onResetFilter={this._handleResetFilter}
                />
              </CardHeaderWithToolbars>
              <FilterContainer>
                <FilterPanel 
                  {...this.state._params} 
                  onHandleChange={this._handleParamsChange}
                  onHandleChangeAndSubmit={this._handleParamsChangeAndSubmit}
                  onHandleSearch={this._handleRefresh}
                  isOpen={this.state._filterOpen}
                />
              </FilterContainer>
              <CardBody>
                <CardText>
                  In this section will display our categories like sneakers, apparels or etc.
                </CardText>
                <div className="table-responsive">
                  <PaginationSummaries
                    from={this.props.masterCategories.list.from}
                    to={this.props.masterCategories.list.to}
                    total={this.props.masterCategories.list.total}
                  />
                  <Tbl>
                    <TblHead
                      dataHeader={dataHeader}
                    />
                    <TblBody
                      isLoad={this.props.masterCategories.isFetch}
                      hasRow={this.props.masterCategories.list.status_code===200&&this.props.masterCategories.list.data.length>0}
                      columnCount={dataHeader.length}
                    >
                    { this._onRenderRowItem() }
                    </TblBody>
                  </Tbl>
                </div>
                { this._onRenderPagination() }
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    )
  }
}
const mapStateToProps= ({ masterCategories, auth: { isFetch, roles, isLogged, id, email } }) => {
	return {
    masterCategories,
    guardData: { isFetch, roles, isLogged, id, email }
	}
};
const mapDispatchToProps = (dispatch) => {
	return{
		getList:(query) => dispatch(getCategoriesList(query))
	}

};
const enhance = connect(mapStateToProps,mapDispatchToProps);

export default enhance(privateView(CategoriesIndex));