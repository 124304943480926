import React, { PureComponent as Component } from 'react';
import { Link } from 'react-router-dom';
import propTypes from 'prop-types';
import {
  Card,
  CardHeader,
  CardBody,
  Table
} from 'reactstrap'
import Loading from 'react-loading-animation';
import { TextRupiah } from '../../Text';
import { _getValue, _getFloatValue } from '../../../utils/form';

export default class PaymentSummaries extends Component {
  render(){
    const estimatedProfit = !this.props.isLoading && _getFloatValue(this.props.order.price)-_getFloatValue(this.props.order.seller_price)
    const cashOutFee = !this.props.isLoading && parseInt(this.props.order.seller_price * 3 / 100)
    const content = !this.props.isLoading && (
      <div>
        <Table style={styles.table}>
          <thead>
            <tr>
              <th colSpan={2} className="text-center">
                Estimated Profit
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Consignment Price</td>
              <td className="v-center"><TextRupiah prefix="Rp. " value={_getFloatValue(this.props.order.price)} align="right" block /></td>
            </tr>
            <tr>
              <td>Consignment Fee</td>
              <td className="v-center"><TextRupiah prefix="Rp. " value={estimatedProfit} align="right" block /></td>
            </tr>
            <tr>
              <td>
                <b>Sub Total</b>
              </td>
              <td className="v-center"><TextRupiah prefix="Rp. " value={_getFloatValue(this.props.order.price)} align="right" block bold/></td>
            </tr>
            <tr>
              <td>
                <b>Total Estimated Profit ({ parseFloat(estimatedProfit / this.props.order.price * 100).toFixed(2) }% commission fee + 3% cash out fee)</b>
              </td>
              <td className="v-center"><TextRupiah prefix="Rp. " value={estimatedProfit + cashOutFee} align="right" block bold/></td>
            </tr>
            <tr>
              <td>
                <b>Seller Revenue</b>
              </td>
              <td className="v-center"><TextRupiah prefix="Rp. " value={_getFloatValue(this.props.order.seller_price)} align="right" block bold/></td>
            </tr>
          </tbody>
        </Table>
      </div>
    ) || <Loading />;
    return (
      <Card>
        <CardHeader>
          {this.props.headerText}
        </CardHeader>
        <CardBody>
          {content}
        </CardBody>
      </Card>
    )
  }
}
const styles = {
  table:{
    marginTop:25
  },
  description:{
    fontSize:9,
    marginBottom:0
  }
}
PaymentSummaries.propTypes = {
  headerText: propTypes.oneOfType([propTypes.string, propTypes.node]),
  isLoading: propTypes.bool,
  order: propTypes.object,
  product: propTypes.object,
}
PaymentSummaries.defaultProps = {
  isLoading: false,
  headerText: "Estimated Profit"
}