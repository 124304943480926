import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Alert
} from 'reactstrap';

import { doScrapeProductStock } from '../../../actions/productStockAction'

class ModalScrape extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      isErrorAlertOpen: false,
      errorAlertMessage: "",
      isSuccessAlertOpen: false,
      successAlertMessage: ""
    }
  }

  componentDidUpdate(prevProps){
    if(!prevProps.isOpen && prevProps.isOpen !== this.props.isOpen){
      this.setState({
        isErrorAlertOpen: false,
        isSuccessAlertOpen: false,
        errorAlertMessage: null,
        successAlertMessage: null,
      })
    }
    if(prevProps.dataProductScrape.isSubmit && prevProps.dataProductScrape.isSubmit !== this.props.dataProductScrape.isSubmit){
      const { error, status_code } = this.props.dataProductScrape;
      if(error){
        let message = "Scraper server still busy now. Please try again later, or contact to our team.";
        if(error.status_code === 500){
          message = "Internal Server Error.";
        } else if(error.status_code === 404){
          message = 'Product Variant Not Found.'
        }
        this.setState({
          isErrorAlertOpen: true,
          isSuccessAlertOpen: false,
          errorAlertMessage: message
        })
      } else if(status_code === 200){
        this.setState({
          isErrorAlertOpen: false,
          isSuccessAlertOpen: true,
          successAlertMessage: "Scraper server processing your data in background."
        }, () => {
          this.props.toggle();
        })
      }
    }
  }

  onSubmit = async (e) =>{
    e.preventDefault();
    await this.setState({
      isErrorAlertOpen: false,
      isSuccessAlertOpen: false,
    })
    const { priceSource } = this.props;
    if(!priceSource || priceSource === ''){
      this.setState({
        isErrorAlertOpen: true,
        errorAlertMessage: "Price source is required, please update your product with a valid price source link. (e.g: https://stockx.com/adidas-ultra-boost-og-black-gold-purple)"
      })
      return;
    }
    this.props.doScrapeProductStock({ id: this.props.id, price_source: this.props.priceSource })
  }

  render() {
    return(
      <Modal isOpen={this.props.isOpen} toggle={this.props.toggle} size={this.props.size}>
        <ModalHeader toggle={this.props.toggle}>Scrape Products</ModalHeader>
        <ModalBody>
          <Alert color="danger" isOpen={this.state.isErrorAlertOpen} toggle={() => this.setState({isErrorAlertOpen: !this.state.isErrorAlertOpen})}>
            <strong>Oops.</strong> {this.state.errorAlertMessage}
          </Alert>
          <Alert color="success" isOpen={this.state.isSuccessAlertOpen} toggle={() => this.setState({isSuccessAlertOpen: !this.state.isSuccessAlertOpen})}>
            <strong>Succeeded.</strong> {this.state.successAlertMessage}
          </Alert>
          <p className="mb-0">ID #{this.props.id} {this.props.displayName}. <Link style={{fontSize: '.75rem', fontStyle: 'italic'}} to={`/productvariants/${this.props.id || 0}`}>Click for details</Link></p>
          <p className="mb-0">Price Source: {this.props.priceSource && this.props.priceSource !== ''?<a href={this.props.priceSource}>{this.props.priceSource}</a>: '-'}</p>
        </ModalBody>
        <ModalFooter>
          <Button disabled={this.props.dataProductScrape.isSubmit} color="primary" onClick={this.onSubmit}>Submit</Button>{' '}
          <Button color="secondary" onClick={this.props.toggle}>Cancel</Button>
        </ModalFooter>
      </Modal>
    )
  }
}

const mapStateToProps = state => ({
  dataProductScrape: state.productStock.productScrape
})

const mapDispatchToProps = dispatch => ({
  doScrapeProductStock: (payloads) => dispatch(doScrapeProductStock(payloads))
})

export default connect(mapStateToProps, mapDispatchToProps)(ModalScrape);