import {
	POINT_SHOP_LIST,
	GET_DETAIL_POINT_SHOP,
  FETCHING_POINT_SHOP,
	POINT_SHOP_ERROR,
	GET_LIST_POINT_SHOP_ITEMS,
	SUBMITING_POINT_SHOP_ITEMS,
	SEARCH_POINT_SHOP_ITEMS,
	POINT_SHOP_CHILDS_REQUEST,
	POINT_SHOP_CHILDS_ERROR,
	ADD_POINT_SHOP,
	UPDATE_POINT_SHOP,
	DELETE_POINT_SHOP,
	NEW_POINT_SHOP,
} from '../constants';

/**
 * Initial State
 */
const initialState={
	list:[],
	detail:{},
	isFetch:false,
	isSubmit:false,
	error:null,
	success:null,
	pointShopItems: {
		dataSearch: [],
		data: [],
		isSearchFetch: false,
		isFetch: false,
		isSubmit: false,
		error: null
	}
}

const onGetPointShopList = (lastState,payload) => {
	const {data, message, status_code}=payload;
	return { ...lastState, error:null, success:null,detail:{}, list:{ ...data, message, status_code } };	
}

const onGetDetialPointShop = (lastState,payload) => {
	const {data, message, status_code}=payload;
	return { ...lastState, error:null, success:null, detail:{ ...data, message, status_code } };	
}

const onPointShopItemSucceeded = (lastState, { type, payload }) => {
	const { data, message, status_code } = payload
	switch(type){
		case SUBMITING_POINT_SHOP_ITEMS: return {
			...lastState,
			pointShopItems: {
				...lastState.pointShopItems,
				error: null,
				message,
				status_code
			}
		};
		case SEARCH_POINT_SHOP_ITEMS: return {
			...lastState,
			pointShopItems: {
				...lastState.pointShopItems,
				error: null,
				dataSearch: data,
				message,
				status_code
			}
		}
		case GET_LIST_POINT_SHOP_ITEMS: return {
			...lastState,
			pointShopItems: {
				...lastState.pointShopItems,
				error: null,
				data,
				message,
				status_code
			}
		};
		default: return lastState;
	}
}

const onErrorChildRequest = (lastState, { requestType, error, ...rest }) => {
	switch(requestType){
		case GET_LIST_POINT_SHOP_ITEMS:
		case SUBMITING_POINT_SHOP_ITEMS:
		case SEARCH_POINT_SHOP_ITEMS:
		return {
			...lastState,
			pointShopItems: {
				...lastState.pointShopItems,
				error: { ...error, ...rest }
			}
		}
		default: return lastState;
	}
}

const onAddPointShop = (lastState,payload) => {
	const {data, message, status_code}=payload;
    return { ...lastState, detail:{ ...data, message, status_code }, success:'Insert has been successfully!',error:null }
}

const onUpdatePointShop = (lastState,payload) => {
	const {data, message, status_code}=payload;
    return { ...lastState, detail:{ ...data, message, status_code }, success:'Update has been successfully!',error:null }
}

const onDeletePointShop = (lastState,payload) => {
	const {data, message, status_code}=payload;
    return { ...lastState, detail:{ ...data, message, status_code, delete:true }, success:'Delete has been successfully!',error:null }
}

const onFetchingPointShop = (lastState,payload) => {
	if(payload.type==='fetch')
			return { ...lastState, isFetch:payload.status }
	else if(payload.type==='submit')
			return { ...lastState, isSubmit:payload.status }

	return { ...lastState, isFetch:false,isSubmit:false }
}

const onValidateChildRequest = (lastState, { requestType, value }) => {
	switch(requestType){
		case GET_LIST_POINT_SHOP_ITEMS: return {
			...lastState,
			pointShopItems: {
				...lastState.pointShopItems,
				isFetch: value
			}
		}
		case SEARCH_POINT_SHOP_ITEMS: return {
			...lastState,
			pointShopItems: {
				...lastState.pointShopItems,
				isSearchFetch: value
			}
		}
		case SUBMITING_POINT_SHOP_ITEMS: return {
			...lastState,
			pointShopItems: {
				...lastState.pointShopItems,
				isSubmit: value
			}
		}
		default: return lastState;
	}
}


const onPointShopError = (lastState,error) => {
	return { ...lastState, ...error, success:null };		
}

const pointShopReducer= (state=initialState, action) => {
	switch(action.type)
	{
		case POINT_SHOP_LIST: return onGetPointShopList(state,action.payload);
		case GET_DETAIL_POINT_SHOP: return onGetDetialPointShop(state,action.payload);
		case FETCHING_POINT_SHOP: return onFetchingPointShop(state,action.payload);
		case POINT_SHOP_ERROR:return onPointShopError(state,action.payload);
		case POINT_SHOP_CHILDS_REQUEST: return onValidateChildRequest(state,action.payload);
		case GET_LIST_POINT_SHOP_ITEMS:
		case SUBMITING_POINT_SHOP_ITEMS:
		case SEARCH_POINT_SHOP_ITEMS:return onPointShopItemSucceeded(state,action);
		case ADD_POINT_SHOP:return onAddPointShop(state,action.payload);
		case UPDATE_POINT_SHOP:return onUpdatePointShop(state,action.payload);
		case POINT_SHOP_CHILDS_ERROR: return onErrorChildRequest(state, action.payload);
		case DELETE_POINT_SHOP:return onDeletePointShop(state,action.payload);
		case NEW_POINT_SHOP:return initialState;
		default:return state;
	}
}

export default pointShopReducer;