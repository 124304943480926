import React, { useState } from "react";
import propTypes from "prop-types";
import { Card, CardHeader, CardBody, Row, Col } from "reactstrap";
import { ImageDropzone } from "./Dropzone";
import CardThumbnail from "./CardThumbnail";

const DraggableUploader = (props) => {
    const [copied, setCopied] = useState(false);
    const [selectedId, setSelectedId] = useState(0);

    const _copyImageUrl = (id, url) => {
        setCopied(true);
        setSelectedId(id);
        navigator.clipboard.writeText(url);
    };
    return (
        <Card>
            <CardHeader>{props.label}</CardHeader>
            <CardBody>
                <Row>
                    {!props.hideUploader && (
                        <Col xs={12} style={{ marginBottom: "1rem" }}>
                            <ImageDropzone
                                onDrop={props.onDrop}
                                hintText={props.hintText}
                                multiple={props.multiple}
                            />
                        </Col>
                    )}

                    {props.images.map((image, index) => (
                        <Col xs={12} sm={6} md={6} key={index}>
                            <CardThumbnail
                                id={image.id}
                                url={image.url}
                                updatedAt={image.updatedAt}
                                storageName={image.storageName}
                                fileInput={image.fileInput}
                                isCopied={copied}
                                copiedId={selectedId}
                                copyImageUrl={_copyImageUrl}
                                isUploading={image.isUploading && !image.status}
                                isDeleting={image.deleted}
                                onRemoveImage={(e) =>
                                    props.onRemoveImage(e, image.identifier)
                                }
                            />
                        </Col>
                    ))}
                </Row>
            </CardBody>
        </Card>
    );
};

DraggableUploader.propTypes = {
    label: propTypes.string,
    hintText: propTypes.string,
    images: propTypes.arrayOf(propTypes.object),
    onDrop: propTypes.func,
    onRemoveImage: propTypes.func,
    multiple: propTypes.bool,
    hideUploader: propTypes.bool,
};

DraggableUploader.defaultProps = {
    label: "Upload",
    hintText:
        "Try dropping your images here, or click to select image to upload.",
    multiple: false,
    hideUploader: false,
};

export default DraggableUploader;
