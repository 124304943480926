import React, { Component } from "react";
import { connect } from "react-redux";
import { Container } from "reactstrap";
import SurveyOptionForm from "./SurveyOptionForm";
import privateView from "../../../components/hocs/privateView";
import Analytics from "../../../services/Analytics";

class SurveyOptionDetail extends Component {
  componentDidMount() {
    const { email } = this.props.injectedProps;
    Analytics.recordPageView("survey_option_detail", email, {
      url: window.location.href,
      location: this.props.location,
      match: this.props.match,
    });
  }
  render() {
    return (
      <Container fluid>
        <SurveyOptionForm edit title="Survey Option Detail" {...this.props} />
      </Container>
    );
  }
}

const mapStateToProps = ({
  auth: { isFetch, roles, isLogged, id, email },
}) => ({
  guardData: { isFetch, roles, isLogged, id, email },
});

const enhance = connect(mapStateToProps);
export default enhance(privateView(SurveyOptionDetail));
