import React from 'react';
import styled from 'styled-components';
import { Card, CardTitle, CardImgOverlay } from 'reactstrap';
import { ImageCentered } from './Image';
import { SimpleLineIcon } from '../Icons';
import noImage from '../../assets/img/no-image.png';

export const ImageFrame = styled.div`
  padding: 0px 15px;
  & > .controls{
    position: relative;
    .controls--delete {
      position: absolute;
      right: -6px;
      z-index: 5;
      top: -8px;
      cursor: pointer;
      border-radius: 15px;
      border: 1px solid #f86c6b;
      font-size: .5rem;
      display: flex;
      padding: 3px;
      background: #f86c6b;
      color: #fff;
      box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.65);
    }
  }
`;

const ListWrapper = styled.div`
  padding: .75rem 0px;
  margin: 0px -15px;
  display: flex;
  flex-wrap: nowrap;
  overflow: auto;
`;

export const ImageFileListContainer = props => (
  <ListWrapper>
    {props.children}
  </ListWrapper>
)

const EmptyImageText = props => (
  <CardImgOverlay style={{background: 'rgba(255,255,255,.5)', display: 'flex', justifyContent: 'center', alignItems: 'flex-end', padding: '.5rem', paddingBottom: 0}}>
    <CardTitle style={{fontStyle: 'italic', fontSize: '.5rem', fontWeight: 700}}>
      {props.children}
    </CardTitle>
  </CardImgOverlay>
)

export const ImageFile = ({onDelete, showDelete = false, style = {}, ...props}) => (
  <Card style={{border: 'none'}} className="mb-0">
    <ImageFrame style={{...style}}>
      {showDelete && onDelete? (
        <div className="controls">
          <div className="controls--delete" onClick={onDelete}>
            <SimpleLineIcon iconType="trash"/>
          </div>
        </div>
      ): null}
      <ImageCentered
        {...props}
        src={props.src?props.src: noImage}
      />
      {props.src === null?(
        <EmptyImageText>
          {props.renderEmptyImageText? props.renderEmptyImageText(): (
            props.emptyImageText? props.emptyImageText: "No Image"
          )}
        </EmptyImageText>
      ):null}
    </ImageFrame>
  </Card>
)