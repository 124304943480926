import React from 'react';
import propTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Card, CardHeader, CardBody, Alert, Input, Button, Form, FormGroup, Label, Row, Col
} from 'reactstrap';
import Loading from 'react-loading-animation';

import { getGeneralSizeList } from '../../../actions/sizeAction';
import { getListUserSizes, saveUserSize } from '../../../actions/userAction';
import Select from '../../../components/Form/Select';
import Analytics from "../../../services/Analytics";

class UserSizes extends React.Component{
  constructor(props){
    super(props);
    let options = [];
    if(props.dataSizes.status_code===200){
      options = this.getSizeOptions(props.dataSizes);
    }
    this.state={
      _isSubmit: false,
      _sizeOptions: options,
      _id: null,
      _sizeId: null,
    }
    this._onSubmit = this._onSubmit.bind(this);
    this._handleSelectChange = this._handleSelectChange.bind(this);
  }

  getSizeOptions({list}){
    return list.map(item=>({value: item.id, label: item.US}))
  }

  componentDidMount(){
    this.props.getUserSizes(this.props.userId);
    if(this.props.dataSizes.status_code!==200){
      this.props.getSizes();
    }
  }

  _handleSelectChange(selected){
    if(selected)
      this.setState({ _sizeId: selected.value });
  }

  componentWillReceiveProps(nextProps){
    if(nextProps.dataSizes.status_code!==this.props.dataSizes.status_code 
      && nextProps.dataSizes.status_code===200
      && nextProps.dataSizes.error===null      
    ){
      this.setState({
        _sizeOptions: this.getSizeOptions(nextProps.dataSizes)
      })
    }
    if(nextProps.dataUser.user_sizes.isFetch!==this.props.dataUser.user_sizes.isFetch
      &&nextProps.dataUser.user_sizes.isFetch===false 
      && nextProps.dataUser.user_sizes.status_code===200){
        const { data } = nextProps.dataUser.user_sizes;
        if(data.length){
          const pickOne = data[0];
          this.setState({_sizeId: pickOne.size_id, _id: pickOne.id})
        }
    }
  }

  _onSubmit(e){
    const email = this.props.guardData;
    const _sizeOptions = this.state;
    const sizeName = _sizeOptions ? _sizeOptions.find(item => item.value === this.state._sizeId) : {};

    e.preventDefault();
    if(this.state._sizeId){
      this.props.save(this.props.userId, {id: this.state._id, size_id: this.state._sizeId})
      Analytics.recordUpdateUserSize(
        this.props.userId,
        this.state._sizeId,
        email
      );
    }
  }

  render(){
    return(
      <Card>
        <CardHeader><i className="fa fa-align-justify"></i> User Size</CardHeader>
        <CardBody>
          <Loading isLoading={this.props.dataUser.user_sizes.isFetch}>
          <Form onSubmit={this._onSubmit}>
            <Row style={{alignItems:'flex-end'}}>
              <Col xs={12} md={6}>
                <FormGroup>
                  <Label>Pilih Size (US)</Label>
                  <Select onChange={this._handleSelectChange} placeholder="Select size..." value={this.state._sizeId} options={this.state._sizeOptions}/>
                </FormGroup>
              </Col>
              <Col xs={12} md={6}>
                <FormGroup>
                  <Button color="primary">Set</Button>
                </FormGroup>
              </Col>
            </Row>
          </Form>
          </Loading>
        </CardBody> 
      </Card>
    )
  }
}

const mapStateToProps = ({size:{ generalSizes }, user:{ detail }, auth:{ email }}) => ({
  dataSizes: generalSizes,
  dataUser: detail,
  guardData: { email }
})
const mapDispatchToProps = dispatch => ({
  getSizes: () => dispatch(getGeneralSizeList()),
  getUserSizes: userId => dispatch(getListUserSizes(userId)),
  save: (userId, payload) => dispatch(saveUserSize(userId, payload))
})
export default connect(mapStateToProps,mapDispatchToProps)(UserSizes);
UserSizes.propTypes={
  userId: propTypes.oneOfType([propTypes.number, propTypes.string]).isRequired
}