import React, {Component} from 'react';
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  CardBody,
  Row,
  Col
} from 'reactstrap';
import {connect} from 'react-redux';
import classnames from 'classnames';
import qs from 'query-string';

import { getDetailSale, doForceCancelledSale, getSaleImages, getTrackingShipmentDetail, doRollbackSale } from '../../../actions/saleAction';
import { getUserBankAccountList } from '../../../actions/userBankAccountAction';

import OrderDetail from './OrderDetail';
import UserShippingDetail from './UserShippingDetail';
import SellerDetail from './SellerDetail';
import SneakerDetail from './SneakerDetail';
import Header from './Header';
import privateView from '../../../components/hocs/privateView';
import ModalForceCancelled from './ModalForceCancelled';

import Analytics from "../../../services/Analytics";
import { ModalInfoCreditCard, ModalDelete as ModalRollback } from '../../../components/Modals';
import { KA_RECEIVED_STATUS, CANCELLED_STATUS } from '../../../constants/kickavenue';

class SaleDetails extends Component{
  constructor(props){
    super(props)
    const DEFAULT_STATE = {
      activeTab:'order',
      id: parseInt(this.props.match.params.id),
      sale: {},
      userSell: {},
      userSellImages:[],
      images: [],
      listingCategory: 'SNEAKERS',
      _modalCreditCard: false,
      _modalRollbackStatus:false,
      _isForceCancelledSubmit:false,
      shippingUpdate: null
    }
    const queries = Object.assign(DEFAULT_STATE,qs.parse(props.location.search));
    this.state={
      ...queries,
      _sellerBankAccountParams:{}
    };
    this._handleGotoPageSellerBankAccount = this._handleGotoPageSellerBankAccount.bind(this);
    this._handleOnForceCancelledClick = this._handleOnForceCancelledClick.bind(this);
    this._handleOnRollbackStatusClick = this._handleOnRollbackStatusClick.bind(this);
    this._toogleForceCancelledModal = this._toogleForceCancelledModal.bind(this);
    this. _handleOnRollbackSubmit = this._handleOnRollbackSubmit.bind(this);
    this. _handleOnSubmitForceCancelled = this._handleOnSubmitForceCancelled.bind(this);
  }

  isAfterLoaded(){
    return (!this.props.sale.isFetch&&this.state.sale.status_code===200);
  }

  componentDidUpdate(prevProps) {
    if(prevProps.sale.isFetch&&prevProps.sale.isFetch!==this.props.sale.isFetch){
      const { error } = this.props.sale;
      if(error)
        this.setState({
          _error: error
        })
      else {
        const { offer, sale_product, sale_shipping, user, ...sale } = this.props.sale.detail;
        this.setState({
          offer: offer,
          userSell: offer.user_sell,
          userSellImages:[...offer.user_sell.user_sell_images],
          seller: offer.user_sell.user,
          saleProduct: { ...sale_product, sale_product_defects: offer.user_sell.defects },
          saleShipping: sale_shipping,
          listingCategory: sale_product.category_name.toUpperCase(),
          user,
          sale
        })
        this.props.getUserBankAccountList(offer.user_sell.user_id)
      }
    }
    if(prevProps.sale.isSubmit && prevProps.sale.isSubmit !== this.props.sale.isSubmit){
      const { error } = this.props.sale;
      if(error)
        this.setState({
          _error: error,
          _isForceCancelledSubmit: false,
          _modalRollbackStatus:false,
        })
      else {
        const { offer, sale_product, sale_shipping, user, ...sale } = this.props.sale.detail;
        this.setState({
          offer: offer,
          userSell: offer.user_sell,
          userSellImages:[...offer.user_sell.user_sell_images],
          seller: offer.user_sell.user,
          saleProduct: { ...sale_product, sale_product_defects: offer.user_sell.defects },
          saleShipping: sale_shipping,
          user,
          sale,
          _modalRollbackStatus:false,
          _isForceCancelledModalOpen: false,
          _isForceCancelledSubmit: false
        })
      }
    }

    if(prevProps.sale.detailImages.isFetch && !this.props.sale.detailImages.isFetch){
      const { error, data } = this.props.sale.detailImages;
      if(!error){
        this.setState({
          images: [...data]
        })
      }
    }

    if (this.props.sale.shippingUpdate.isFetch !== prevProps.sale.shippingUpdate.isFetch && !this.props.sale.shippingUpdate.isFetch)
    {
      const { data, error } = this.props.sale.shippingUpdate;
      if (!error) {
        this.setState({
          shippingUpdate: data
        })
      }
    }
  }

  componentDidMount(){
    const { email } = this.props.injectedProps;
    this.props.find(this.state.id);
    this.props.getSaleImages(this.state.id);
    Analytics.recordPageView('sales_detail', email, {
      url: window.location.href,
      location: this.props.location,
      match: this.props.match
    });
    this.props.getTracking(this.state.id)
  }

  _handleOnForceCancelledClick(){
    this._toogleForceCancelledModal();
  }

  _handleOnRollbackStatusClick(){
    this.setState({_modalRollbackStatus: !this.state._modalRollbackStatus})
  }

  _toogleForceCancelledModal(){
    if(!this.state._isForceCancelledSubmit)
      this.setState({
        _isForceCancelledModalOpen: !this.state._isForceCancelledModalOpen
      })
  }

  _handleOnRollbackSubmit(){
    const {status} =  this.props.sale.detail;
    const payload = { 'status' : (status === KA_RECEIVED_STATUS)? 'sneakers_on_the_way' : 'verification_progress'}
    this.props.submitRollback(this.state.id, payload)
  }

  _handleGotoPageSellerBankAccount(page){
    const { offer:{ user_sell } } = this.props.sale.detail;
    const { _sellerBankAccountParams }=this.state;
    _sellerBankAccountParams.page=page;
    this.setState({
      _sellerBankAccountParams
    }, ()=>
      this.props.getUserBankAccountList(user_sell.user_id, { params: this.state._sellerBankAccountParams })
    );
  }

  _onActiveTabChange(id){
    this.setState({activeTab:id}, ()=>{
      const { activeTab } = this.state;
      const { pathname } = this.props.location;
      const search = qs.stringify({
        activeTab
      });
      this.props.history.push({ pathname, search })
    });
  }

  _handleOnSubmitForceCancelled(id, payloads) {
    this.setState({
      _isForceCancelledSubmit: true,
      _error: null
    }, ()=>{
      this.props.submitForceCancelled(id, payloads)
    })
  }

  onRenderSaleHeader(){
    if(!this.props.sale.isFetch && this.state.sale.status_code===200) {
      return (
        <Header
          id={this.state.id}
          sale={this.state.sale}
          roles={this.props.injectedProps.roles}
          invoiceNumber={this.state.sale.invoice_number}
          productName={this.state.saleProduct.display_name}
          category={this.state.saleProduct.category_name}
          status={this.state.sale.status}
          createdAt={this.state.sale.created_at}
          isHijacked={this.state.sale.hijacked}
          hijackedAt={this.state.sale.hijacked_at}
          forceCancelledAt={this.state.sale.force_cancelled_at}
          kaSent={this.state.sale.ka_sent}
          followUpStatus={this.state.sale.follow_up_status}
          onForceCancelledClick={this._handleOnForceCancelledClick}
          onRollbackStatusClick={this._handleOnRollbackStatusClick}
        />
      )
    }
    return null;
  }

  _handleToggleModalCreditCard = () => {
    this.setState({ _modalCreditCard: !this.state._modalCreditCard });
  }

  render(){
    // const { offer, sale_product, sale_shipping, user,...order} = this.props.sale.detail;
    return (
      <div className="animated fadeIn">
        <ModalInfoCreditCard
          isOpen={this.state._modalCreditCard}
          toggle={this._handleToggleModalCreditCard}
          invoiceNumber={this.state.sale.invoice_number||null}
        />
        <ModalForceCancelled
          id={this.state.id}
          offer={this.state.offer}
          saleStatus={this.state.sale.status}
          isOpen={this.state._isForceCancelledModalOpen}
          isSubmit={this.state._isForceCancelledSubmit}
          errorData={this.state._error}
          onCancel={this._toogleForceCancelledModal}
          onSubmit={this._handleOnSubmitForceCancelled}
        />
        <ModalRollback
          isOpen={this.state._modalRollbackStatus}
          modalTitle="Confirmation."
          modalBody="Are you sure to rollback this?"
          onDelete={this._handleOnRollbackSubmit}
          toggle={this._handleOnRollbackStatusClick}
        />
        <Row>
          <Col xs={12}>
            <Card>
              <CardBody>
                {this.onRenderSaleHeader()}
                <Nav className="nav-tabs--custom" tabs>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: this.state.activeTab === 'order' })}
                      onClick={() => this._onActiveTabChange('order')}
                    >
                      Order Detail
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: this.state.activeTab === 'product' })}
                      onClick={() => this._onActiveTabChange('product') }
                    >
                      Product Detail
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: this.state.activeTab === 'seller' })}
                      onClick={() => this._onActiveTabChange('seller') }
                    >
                      Seller Detail
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: this.state.activeTab === 'user-shipping' })}
                      onClick={() => this._onActiveTabChange('user-shipping') }
                    >
                      User & Shipping Detail
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent className="tab-content--custom" activeTab={this.state.activeTab}>
                    <TabPane className="tab-content--custom__tab-pane" tabId="order">
                      <OrderDetail
                        isLoading={!this.isAfterLoaded()}
                        order={this.state.sale}
                        payment={this.state.offer}
                        product={this.state.saleProduct}
                        shipping={this.state.saleShipping}
                        seller={this.state.seller}
                        onOpenCreditCard={this._handleToggleModalCreditCard}
                      />
                    </TabPane>
                    <TabPane className="tab-content--custom__tab-pane" tabId="product">
                      <SneakerDetail
                        isLoading={!this.isAfterLoaded()}
                        isLegit={this.state.sale.ka_verified}
                        product={this.state.saleProduct}
                        listingCategory={this.state.listingCategory}
                        userSellId={this.state.userSell.id}
                        legitCheckImages={this.state.images}
                        userSellImages={this.state.userSellImages}
                      />
                    </TabPane>
                    <TabPane className="tab-content--custom__tab-pane" tabId="user-shipping">
                      <UserShippingDetail
                        isLoading={!this.isAfterLoaded()}
                        shipping={this.state.saleShipping}
                        shippingUpdate={this.state.shippingUpdate}
                        user={this.state.user}
                      />
                    </TabPane>
                    <TabPane className="tab-content--custom__tab-pane" tabId="seller">
                      <SellerDetail
                        isLoading={!this.isAfterLoaded()}
                        seller={this.state.seller}
                        dataBankAccount={this.props.userBankAccount}
                        handleGotoPageBankAccountList={this._handleGotoPageSellerBankAccount}
                      />
                    </TabPane>
                </TabContent>

              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    )
  }
}

const mapStateToProps= ({sale, userBankAccount, auth:{ isFetch, roles, isLogged, id, email } }) => {
	return {
    guardData: { isFetch, roles, isLogged, id, email },
    sale,
    userBankAccount
	}
};

const mapDispatchToProps = (dispatch) => {
	return{
    getSaleImages: id => dispatch(getSaleImages(id)),
    find:(id) => dispatch(getDetailSale(id)),
    getUserBankAccountList: (userId, queries) => dispatch(getUserBankAccountList(userId, queries)),
    submitForceCancelled: (id, payloads) => dispatch(doForceCancelledSale(id, payloads)),
    submitRollback: (id, payloads) => dispatch(doRollbackSale(id, payloads)),
    getTracking: id => dispatch(getTrackingShipmentDetail(id))
	}
};

const enhance = connect(mapStateToProps,mapDispatchToProps);

export default enhance(privateView(SaleDetails, ['supervisor','sale_supervisor','courier','cashier']));