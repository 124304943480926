import React from 'react';
import { CardBody, Collapse } from 'reactstrap';
import propTypes from 'prop-types';
import SearchForm from './filterPanel/SearchForm';
import SortForm from './filterPanel/SortForm';
import FilterForm from './filterPanel/FilterForm';


export const warehouseStockStatusOptions = [
  { value: 'active_consignment', label: 'Active Consignment' },
  { value: 'rejected_consignment', label: 'Rejected Consignment' },
  { value: 'on_loan', label: 'On Loan' },
  { value: 'pre_verified_approved', label: 'Pre-verified Approved' },
  { value: 'draft', label: 'Draft' },
]
class FilterPanel extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      keyword: props.keyword,
      seller: props.seller,
      rack: props.rack,
      status: props.status,
      pre_verified: props.pre_verified,
      stock_label: props.stock_label,
      sneakers_condition: props.sneakers_condition,
      per_page: props.per_page,
      sort_by: props.sort_by,
    }
    this._handleOnChangeInput = this._handleOnChangeInput.bind(this);
    this._handleApplyFilterButtonClick = this._handleApplyFilterButtonClick.bind(this);
    this._handleOnChangeInputAndApply = this._handleOnChangeInputAndApply.bind(this);
    this._handleSearchButtonClick = this._handleApplyFilterButtonClick.bind(this);
    this._handleSelectRack = this._handleSelectRack.bind(this);
    this._handleOnSellerChange = this._handleOnSellerChange.bind(this);
    this._handleStatusesChangeInput = this._handleStatusesChangeInput.bind(this);
    this._isCheckedStatus = this._isCheckedStatus.bind(this);
  }

  _handleSelectRack(option){
    this.setState({ rack: option });
  }

  _handleOnChangeInput(e){
    const lastState = {...this.state};
    lastState[e.target.name] = e.target.value;
    this.setState({...lastState});
  }

  _handleOnChangeInputAndApply(e){
    const lastState = {...this.state};
    lastState[e.target.name] = e.target.value;
    this.setState({...lastState}, ()=> this._handleSubmitFilter());
  }

  _handleOnSellerChange(seller){
    this.setState({
      seller
    })
  }

  _handleApplyFilterButtonClick(e){
    e.preventDefault();
    this._handleSubmitFilter();
  }

  _isCheckedStatus(val) { 
    if (val === 'all') {
      return this.state.status.split(',').length === warehouseStockStatusOptions.length
    } else {
      return val === 'default'
        ? this.state.status === '' || this.state.status === 'pre_verified_approved'
        : this.state.status.includes(val);
    }
  }

  _handleStatusesChangeInput(e) {
    const lastState = {...this.state};
    let status = e.target.value
    let statuses = this.state.status.split(',')
    let arrStatus = [];
    if (this._isCheckedStatus(status)) {
      lastState['status'] = statuses.filter(itm => itm !== status).join(',')
    } else if (status === 'default' || status === 'all') {
      if (status === 'default') {
        lastState['status'] = 'pre_verified_approved';
      } else {
        arrStatus = [...warehouseStockStatusOptions.map(v => v.value)]
        lastState['status'] = arrStatus.join(',')
      } 
    } else {
      if (this._isCheckedStatus('default')) {
        lastState['status'] = status
      } else if (status !== 'all') {
        arrStatus = [status, ...statuses]
        lastState['status'] = arrStatus.join(',')
      }
    }
    this.setState({...lastState});
  }

  _handleSubmitFilter(){
    const {
      keyword,
      seller,
      rack,
      status,
      pre_verified,
      stock_label,
      sneakers_condition,
      per_page,
      sort_by
    } = this.state;
    let rack_names = ''
    if (rack.length) {
      const names = rack.map(obj => obj.name)
      rack_names = names.join(', ')
    }
    this.props.onApplyFilter({
      keyword,
      seller_id: seller.id,
      rack: rack_names,
      status,
      pre_verified,
      stock_label,
      sneakers_condition,
      per_page,
      sort_by
    });
  }

  componentDidUpdate(prevProps){
    if(this.props.isReset && this.props.isReset!==prevProps.isReset){
      const {
        keyword,
        rack,
        status,
        pre_verified,
        sneakers_condition,
        per_page,
        sort_by
      } = this.props;
      this.setState({
        keyword,
        rack,
        status,
        pre_verified,
        sneakers_condition,
        per_page,
        sort_by
      })
      this.props.onResetFilterCallback();
    }
  }

  render(){
    return(
      <Collapse isOpen={this.props.isOpen}>
        <CardBody className="filter-container__card-body">
          <SearchForm
            keyword = {this.state.keyword}
            onInputChange = {this._handleOnChangeInput}
            onSubmit = {this._handleSearchButtonClick}
          />
          <FilterForm
            filterRackRef="selectRack"
            filterRackId="_formSelectRack"
            rack={this.state.rack}
            seller={this.state.seller}
            onSellerChange={this._handleOnSellerChange}
            status={this.state.status}
            pre_verified={this.state.pre_verified}
            pre_order={this.state.pre_order}
            stock_label={this.state.stock_label}
            sneakers_condition={this.state.sneakers_condition}
            onInputChange = {this._handleOnChangeInput}
            onCheckedChange = {this._handleOnChangeInput}
            onSubmit={this._handleApplyFilterButtonClick}
            getRackOptions={this._getRackOptions}
            onSelectSearch={(input)=>this.setState({_recentSearchRack: input})}
            handleSelectRack={this._handleSelectRack}
            onStatusesInputChange = {this._handleStatusesChangeInput}
            isCheckedStatus = {this._isCheckedStatus}
          />
          <hr/>
          <SortForm
            per_page={this.state.per_page}
            sort_by={this.state.sort_by}
            onInputChange={this._handleOnChangeInputAndApply}
          />
        </CardBody>
      </Collapse>
    )
  }
}


export default FilterPanel;

FilterPanel.propTypes= {
  rack: propTypes.array,
  status: propTypes.string,
  per_page: propTypes.oneOfType([propTypes.number, propTypes.string]),
  sort_by: propTypes.string,
  isOpen: propTypes.bool.isRequired,
  pre_verified: propTypes.number,
  onResetFilterCallback: propTypes.func,
  isReset: propTypes.bool
}

FilterPanel.defaultProps= {
  rack: [],
  per_page: 15,
  sort_by: '',
  status: '',
  pre_verified: true
}