import React from 'react';
import propTypes from 'prop-types';
import classNames from 'classnames';
import { IndicatorContainer } from './styled';

const Indicators = ({type, isOpen, toggle}) =>{
  const color = type === 'FETCH_PENDING'? 'default':
    type === 'SUBMIT_PENDING'? 'warning':
    type === 'SUCCEEDED'? 'success' :
    type === 'ERROR'? 'danger': 'default';
  const title = type === 'FETCH_PENDING'? 'Please wait,':
    type === 'SUBMIT_PENDING'? 'Please wait,':
    type === 'SUCCEEDED'? 'Done.' :
    type === 'ERROR'? 'Oh snap,': 'Please wait,';
  const message = type === 'FETCH_PENDING'? 'fetching your data...':
    type === 'SUBMIT_PENDING'? 'submitting your request...':
    type === 'SUCCEEDED'? '' :
    type === 'ERROR'? 'an error occurred.': 'fetching your data...';
  return(
    <div style={{position: 'relative'}} className="indicators">
      <IndicatorContainer
        color={color}
        isOpen={isOpen}
      >
        <div className="indicators__content">
          <div className="indicators__content__text">
            <i className={classNames({
              "icon-clock": color === 'default',
              "icon-check": color === 'success',
              "icon-info": color === 'danger' || color === 'warning'
            })}></i>
            <span>{title}</span>
            {message}
          </div>
          {
            type === 'SUCCEEDED' ||  type === 'ERROR'? (
              <div className="indicators__content__toggle" onClick={() => toggle(!isOpen)}>
                <i className="icon-close"></i>
              </div>
            )
            :null
          }
        </div>
      </IndicatorContainer>
    </div>
  )
}

export default Indicators;

Indicators.propTypes = {
  isOpen: propTypes.bool,
  type: propTypes.oneOf(['FETCH_PENDING', 'SUBMIT_PENDING', 'SUCCEEDED', 'ERROR']),
  toggle: propTypes.func
}

Indicators.defaultProps = {
  isOpen: false,
  type: 'FETCH_PENDING'
}