import React, { useReducer, useCallback, useEffect } from 'react';
import {
  CardBody,
  Collapse,
  Col,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  Button,
  Label
} from 'reactstrap';
import propTypes from 'prop-types';
import FilterContainer from '../../../components/Container/FilterContainer/FilterContainer';
import { perPageOptions } from '../../../constants/kickavenue'

export const SearchForm = props => (
  <Form onSubmit={props.onSubmit}>
    <FormGroup row>
      <Col xs={12} sm={props.block?12:6}>
        <InputGroup>
          <Input type="text" placeholder={props.title} name="keyword" value={props.keyword} onChange={e => props.onInputChange({[e.target.name]: e.target.value})}/>
          <InputGroupAddon addonType="append">
            <Button color="primary">Search</Button>
          </InputGroupAddon>
        </InputGroup>
      </Col>
    </FormGroup>
  </Form>
)

export const SortForm = props => (
  <Form inline className="form-filter">
    <FormGroup className="mb-2 mr-3">
      <Label className="mr-sm-3">
        Show
      </Label>
      <Input
        type="select"
        name="perPage"
        onChange={e => props.onInputChange('per_page', e.target.value, {[e.target.name]: e.target.value})}
        value={props.perPage}
      >
        {perPageOptions.map((option,i)=><option value={option.value} key={i}>{option.label}</option>)}
      </Input>
    </FormGroup>
    {
      props.sortOptions?(
        <FormGroup className="mb-2 mr-3">
          <Label className="mr-sm-3">
            Sort By
          </Label>
          <Input
            type="select"
            name="sortBy"
            onChange={e => props.onInputChange('sort_by', e.target.value, {[e.target.name]: e.target.value})}
            value={props.sortBy}
          >
            {props.sortOptions.map((option,i)=><option value={option.value} key={i}>{option.label}</option>)}
          </Input>
        </FormGroup>
      ): null
    }
  </Form>
)

const reducer = (state, action) => {
  switch(action.type){
    case 'INPUT_CHANGED': return {...state, ...action.payload}
    default: return state
  }
}


export const Filters = props => {
  const [state, dispatch] = useReducer(reducer, {
    keyword: props.keyword,
    perPage: props.per_page,
    sortBy: props.sort_by
  })

  useEffect(()=>{
    if(props.isOpen){
      dispatch({
        type: 'INPUT_CHANGED',
        payload: {
          keyword: props.keyword,
          perPage: props.per_page,
          sortBy: props.sort_by
        }
      })
    }
  }, [props.isOpen])

  const setInputChange = useCallback(payloads => {
    dispatch({
      type: 'INPUT_CHANGED',
      payload: payloads
    })
  }, [])
  
  const onApplyFilter = useCallback((payloads = {}) => {
    props.onApplyFilter({
      keyword: state.keyword,
      per_page: state.perPage,
      sort_by: state.sortBy,
      ...payloads
    })
  }, [state])

  return (
    <FilterContainer>
      <Collapse isOpen={props.isOpen}>
        <CardBody className="filter-container__card-body">
          <SearchForm
            title={props.title}
            keyword = {state.keyword}
            onInputChange = {setInputChange}
            onSubmit = {e => {
              e.preventDefault()
              onApplyFilter()
            }}
          />
          <hr/>
          <SortForm
            sortOptions={props.sortOptions}
            perPage={state.perPage}
            sortBy={state.sortBy}
            onInputChange={ (name, value, payloads) =>{
              setInputChange(payloads)
              onApplyFilter({[name]: value})
            }}
          />
        </CardBody>
      </Collapse>
    </FilterContainer>
  )
}

Filters.propTypes= {
  keyword: propTypes.string,
  per_page: propTypes.oneOfType([propTypes.number, propTypes.string]),
  sort_by: propTypes.string,
  isOpen: propTypes.bool.isRequired,
  onHandleChange: propTypes.func,
  onHandleSearch: propTypes.func,
  onHandleChangeAndSubmit: propTypes.func
}

Filters.defaultProps= {
  keyword: '',
  per_page: 15,
  sort_by: '',
  sortOptions: null
}