import React, { PureComponent as Component } from "react";
import { Card, CardHeader, CardBody, Badge } from "reactstrap";
import classNames from "classnames";
import Loading from "react-loading-animation";
import propTypes from "prop-types";
import { _getValue } from "../../../utils/form";
import {
  userSellDefaultConditions,
  userSellApparelConditions,
  userSellHandbagConditions,
  userSellSneakerConditions,
  userSellLifestyleConditions
} from "../../../utils/userSell";
import SneakerSize from "./SneakerSize";
import { BadgeFlexContainer, BadgeMedium } from "../../../styled/Badge";
import {
  FieldRow,
  FieldColumn,
  FieldLabel,
  FieldValue
} from "../../../styled/Fields";

const getListingConditions = (listingCategory, listingDefects) => {
  const {
    missing_accessories,
    yellowing,
    display_item,
    sneakers_defect,
    defects
  } = listingDefects;
  let listingConditionList = [];
  const myConditions = [
    {
      name: "missing_accessories",
      value: missing_accessories === null ? false : missing_accessories
    },
    {
      name: "yellowing",
      value: yellowing === null ? false : yellowing
    },
    {
      name: "display_item",
      value: display_item === null ? false : display_item
    },
    {
      name: "sneakers_defect",
      value: sneakers_defect === null ? false : sneakers_defect
    }
  ];
  const defaultListingConditions = userSellDefaultConditions.map(item => {
    const getValue = myConditions.find(i => i.name === item.name);
    return {
      ...item,
      value: getValue ? getValue.value : false
    };
  });

  switch (listingCategory) {
    case "HANDBAGS": {
      listingConditionList = userSellHandbagConditions.map(item => {
        const getValue = defects.find(i => i.name === item.name.toUpperCase());
        return {
          ...item,
          value: getValue ? getValue.value : false
        };
      });
      const onlyTrueDefect = defaultListingConditions.filter(
        ({ value }) => value === true
      );
      if (onlyTrueDefect.length)
        listingConditionList.splice(0, 0, ...onlyTrueDefect);
      break;
    }
    case "APPARELS": {
      listingConditionList = userSellApparelConditions.map(item => {
        const getValue = defects.find(i => i.name === item.name.toUpperCase());
        return {
          ...item,
          value: getValue ? getValue.value : false
        };
      });
      const onlyTrueDefect = defaultListingConditions.filter(
        ({ value }) => value === true
      );
      if (onlyTrueDefect.length)
        listingConditionList.splice(0, 0, ...onlyTrueDefect);
      break;
    }
    case "LIFESTYLES": {
      listingConditionList = userSellLifestyleConditions.map(item => {
        const getValue = defects.find(i => i.name === item.name.toUpperCase());
        return {
          ...item,
          value: getValue ? getValue.value : false
        };
      });
      const onlyTrueDefect = defaultListingConditions.filter(
        ({ value }) => value === true
      );
      if (onlyTrueDefect.length)
        listingConditionList.splice(0, 0, ...onlyTrueDefect);
      break;
    }
    default:
      listingConditionList = userSellSneakerConditions.map(item => {
        const getValue = defects.find(i => i.name === item.name.toUpperCase());
        return {
          ...item,
          value: getValue ? getValue.value : false
        };
      });
      const onlyTrueDefect = defaultListingConditions.filter(
        ({ value }) => value === true
      );
      if (onlyTrueDefect.length)
        listingConditionList.splice(0, 0, ...onlyTrueDefect);
      break;
  }
  return listingConditionList;
};

export default class SneakerCondition extends Component {
  _renderContent() {
    const {
      product: {
        size,
        missing_accessories,
        yellowing,
        display_item,
        sneakers_defect,
        sale_product_defects
      },
      listingCategory
    } = this.props;
    const listingConditionList = getListingConditions(listingCategory, {
      defects: sale_product_defects
    });
    return (
      <div>
        <FieldRow>
          <FieldColumn className="col-xs-12 col-md-6">
            <FieldLabel>Sell ID</FieldLabel>
            <FieldValue>
              <span>{this.props.userSellId}</span>
            </FieldValue>
          </FieldColumn>
          {this.props.product.consignment_id ? (
            <FieldColumn className="col-xs-12 col-md-6">
              <FieldLabel>Consignment ID</FieldLabel>
              <FieldValue>
                <span>{this.props.product.consignment_id}</span>
              </FieldValue>
            </FieldColumn>
          ) : null}
        </FieldRow>
        <SneakerSize {...size} />
        <FieldRow>
          <FieldColumn className="col-xs-12 col-md-6">
            <FieldLabel>Box Condition</FieldLabel>
            <FieldValue>
              <span>{_getValue(this.props.product.box_condition)}</span>
            </FieldValue>
          </FieldColumn>
          <FieldColumn className="col-xs-12 col-md-6">
            <FieldLabel>Sneaker Condition</FieldLabel>
            <FieldValue>
              <span>{_getValue(this.props.product.sneakers_condition)}</span>
            </FieldValue>
          </FieldColumn>
          <FieldColumn className="col-12">
            <BadgeFlexContainer>
              <BadgeMedium>
                <Badge
                  color={this.props.product.pre_order ? "success" : "default"}
                >
                  <i
                    className={classNames("fa", {
                      "fa-times": !this.props.product.pre_order,
                      "fa-check": this.props.product.pre_order
                    })}
                  ></i>
                  &nbsp; PRE-ORDER
                </Badge>
              </BadgeMedium>
              <BadgeMedium>
                <Badge
                  color={
                    this.props.product.pre_verified ? "success" : "default"
                  }
                >
                  <i
                    className={classNames("fa", {
                      "fa-times": !this.props.product.pre_verified,
                      "fa-check": this.props.product.pre_verified
                    })}
                  ></i>
                  &nbsp; PRE-VERIFIED
                </Badge>
              </BadgeMedium>
              <BadgeMedium>
                <Badge color={(missing_accessories?"success":"default")}>
                  <i
                    className={
                      classNames("fa",{
                        "fa-times":!missing_accessories,
                        "fa-check":missing_accessories})
                      }
                  >
                  </i>
                  &nbsp; MISSING ACCESSORIES
                </Badge>
              </BadgeMedium>
              <BadgeMedium>
                <Badge color={(yellowing?"success":"default")}>
                  <i
                    className={
                      classNames("fa",{
                        "fa-times":!yellowing,
                        "fa-check":yellowing})
                      }
                  >
                  </i>
                  &nbsp; YELLOWING
                </Badge>
              </BadgeMedium>
              <BadgeMedium>
                <Badge color={(display_item?"success":"default")}>
                  <i
                    className={
                      classNames("fa",{
                        "fa-times":!display_item,
                        "fa-check":display_item})
                      }
                  >
                  </i>
                  &nbsp; DISPLAY ITEM
                </Badge>
              </BadgeMedium>
              <BadgeMedium>
                <Badge color={(sneakers_defect?"success":"default")}>
                  <i
                    className={
                      classNames("fa",{
                        "fa-times":!sneakers_defect,
                        "fa-check":sneakers_defect})
                      }
                  >
                  </i>
                  &nbsp; {listingCategory.toLowerCase() !== 'sneakers' ? "DEFECT" : "SNEAKER DEFECT"}
                </Badge>
              </BadgeMedium>
              
            </BadgeFlexContainer>
          </FieldColumn>
          
          <FieldColumn className="col-12">
            <FieldLabel>Inclusions</FieldLabel>
            <BadgeFlexContainer>
              {listingConditionList.filter(item => item).map((item, i) => (
                <BadgeMedium key={i}>
                  <Badge
                    className="text-uppercase"
                    color={item.value ? "success" : "default"}
                  >
                    <i
                      className={classNames("mr-1 fa", {
                        "fa-times": !item.value,
                        "fa-check": item.value
                      })}
                    ></i>
                    {item.en}
                  </Badge>
                </BadgeMedium>
              ))}
            </BadgeFlexContainer>
          </FieldColumn>
          <FieldColumn className="col-12">
            <FieldLabel>Note</FieldLabel>
            <FieldValue>
              <span>{_getValue(this.props.product.note)}</span>
            </FieldValue>
          </FieldColumn>
        </FieldRow>
      </div>
    );
  }
  render() {
    return (
      <Card>
        <CardHeader>
          {this.props.headerText}
          &nbsp;
          <Badge color={this.props.isLegit ? "success" : "default"}>
            <i
              className={classNames("fa", {
                "fa-times": !this.props.isLegit,
                "fa-check": this.props.isLegit
              })}
            ></i>
            &nbsp;{this.props.isLegit ? "LEGIT" : "NOT-LEGIT"}
          </Badge>
        </CardHeader>
        <CardBody>
          {(!this.props.isLoading && this._renderContent()) || <Loading />}
        </CardBody>
      </Card>
    );
  }
}

SneakerCondition.propTypes = {
  headerText: propTypes.string,
  isLoading: propTypes.bool,
  isLegit: propTypes.oneOfType([propTypes.bool, propTypes.number]),
  product: propTypes.shape({
    sale_id: propTypes.number.isRequired,
    display_name: propTypes.string,
    quantity: propTypes.number,
    image_url: propTypes.string,
    pre_verified: propTypes.oneOfType([propTypes.number, propTypes.bool]),
    pre_order: propTypes.oneOfType([propTypes.number, propTypes.bool])
  })
};
SneakerCondition.defaultProps = {
  headerText: "Sneaker Condition",
  isLoading: false,
  isLegit: false,
  listingCategory: "SNEAKERS",
  product: {
    sale_id: 0,
    display_name: "",
    quantity: 0,
    image_url: null,
    pre_verified: false,
    pre_order: false,
    missing_accessories: false,
    yellowing: false,
    display_item: false,
    sneakers_defect: false,
    sale_product_defects: []
  }
};
