import React from 'react';
import { connect } from 'react-redux';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ListGroup,
  ListGroupItem,
  Label,
  Input
} from 'reactstrap';
import { getListAccountBank } from '../../../actions/accountBankAction';
import { getUserBankAccountList } from '../../../actions/userBankAccountAction';

class ModalAccountBankOffice extends React.Component{
  constructor(props){
    super(props);
    this.state={
      list: [],
      userId: null
    }
  }

  componentDidMount(){
    this.props.getList();
  }

  componentDidUpdate(lastProps){
    if(lastProps.accountBank.isFetch&&lastProps.accountBank.isFetch!==this.props.accountBank.isFetch){
      if(this.props.accountBank.list.status_code===200){
        const { data } = this.props.accountBank.list;
        // console.log(this.props.accountBank);
        this.setState({list: data, userId: null});
      }
    }
    if(lastProps.userBankAccount.list.isFetch&&lastProps.userBankAccount.list.isFetch!==this.props.userBankAccount.list.isFetch){
      if(this.props.userBankAccount.list.status_code===200){
        const { data } = this.props.userBankAccount.list;
        this.setState({list: data});
      }
    }
    if(lastProps.isOpen===false&&lastProps.isOpen!==this.props.isOpen){
      // console.log('masuk coeg', lastProps.isOpen)
      if(this.props.isAccountBankUser){
        if(this.state.userId!=this.props.userId){
          this.setState({
            userId: this.props.userId,
            list: []
          }, ()=>this.props.getListByUser(this.props.userId));
        }
      }
      else{
        if(this.state.userId!=null){
          this.props.getList();
        }
      }
    }
  }

  render(){
    return(
      <Modal isOpen={this.props.isOpen} toggle={this.props.onCancel} className="modal-dialog modal-md">
        <ModalHeader toggle={this.props.onCancel}>Please choose one:</ModalHeader>
        <ModalBody>
        <ListGroup style={{maxHeight:300, overflow:'auto'}}>
          {
            this.state.list
            .map((item, index)=>
              <ListGroupItem key={`${item.id}__${index+1}`}>
                <Label check style={{width: "100%", cursor: "pointer"}}>
                  <Input type="radio" value="true" onChange={()=>this.props.onSelectChanged(item)}/>
                  <div style={{display: 'flex', alignItems: 'center'}}>
                    <div style={{fontSize: "1.5rem", padding: '0.75rem 1rem'}}>
                      <i className="fa fa-bank"></i>
                    </div>
                    <div>
                      <p style={{marginBottom: 0}}>
                        <span style={{fontSize: "1.25rem"}}>
                          {item.bank_name}<br/>
                          {item.account_number}
                        </span>
                        <br/>
                        <i>{item.account_name}{item.branch_name? ` - ${item.branch_name}`: '' }</i>
                      </p>
                    </div>
                  </div>
                </Label>
              </ListGroupItem>
            )
          }
          </ListGroup>
        </ModalBody>
      </Modal>
    )
  }
}
const mapStateToProps = ({accountBank, userBankAccount}) => ({
  accountBank,
  userBankAccount
})
const mapDispatchToProps = dispatch => ({
  getList: () => dispatch(getListAccountBank()),
  getListByUser: userId => dispatch(getUserBankAccountList(userId))
})

export default connect(mapStateToProps, mapDispatchToProps)(ModalAccountBankOffice);