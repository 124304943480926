import React from 'react';
import propTypes from 'prop-types';
import BadgeCondition from './badgeConditions/BadgeCondition';

const BadgeConditions = props => (
  <div id={`badgeConditions-${props.id}`}>
    <BadgeCondition>#{props.id}</BadgeCondition>
    { props.sneakersCondition=='BEKAS'?
      <BadgeCondition color="danger">Used</BadgeCondition>:
      <BadgeCondition color="success">New</BadgeCondition>
    }
    { props.boxCondition=='CACAT'?
      <BadgeCondition color="danger">DAMAGED</BadgeCondition>:
      (props.boxCondition=='NO_BOX'?<BadgeCondition color="danger">MISSING BOX</BadgeCondition>:null)
    }
    { props.preOrder?
      <BadgeCondition color="info">PRE-ORDER</BadgeCondition>:
      null
    }
    { props.isExpired?
      <BadgeCondition color="danger">EXPIRED</BadgeCondition>:
      null
    }
  </div>
)

export default BadgeConditions;

BadgeConditions.defaultProps = {

}

BadgeConditions.propTypes = {
  id: propTypes.number.isRequired,
  preOrder: propTypes.oneOfType([propTypes.bool, propTypes.number]),
  sneakersCondition: propTypes.string,
  boxCondition: propTypes.string,
  isExpired: propTypes.oneOfType([propTypes.bool, propTypes.number])
}