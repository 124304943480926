import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import{
  Card,
  Badge,
  Button
} from 'reactstrap';

import TextRupiah from '../../../../components/Text/TextRupiah';
import { getStatusSales, getPaymentStatus} from '../../../../constants/kickavenue';

const CardItemHeader = styled.div`
  display: flex;
  margin-bottom: .5rem;
  align-items: center;
`
const InvoiceNumber = styled.div`
  flex: 1
`
const Amount = styled.div`
  flex: 1,;
  text-align: right;
`
const DisplayDate = styled.p`
  font-size: .75rem;
`
const validateItemType = itemType =>{
  switch(itemType){
    case 'OFFER': return 'From Payments';
    case 'SALE': return 'From Sales';
    case 'TOPUP': return 'From Topup';
    case 'BID': return 'From Offer';
    default: return itemType;
  }
}

const getColorBadgeItemType = itemType => {
  switch(itemType){
    case 'OFFER':
    case 'SALE':
    case 'BID': return 'primary';
    default: return 'default';
  }
}

const getStatusStr = (itemType, status) => {
  switch(itemType){
    case 'OFFER': return getPaymentStatus(status);
    case 'SALE': return getStatusSales(status);
  }
  return status;
}

const isVisibleMoreDetailBtn = itemType => {
  switch(itemType){
    case 'OFFER':
    case 'SALE':
    case 'TOPUP':
    case 'BID': return true;
    default : return false;
  }
}

const getRedirectLink = (itemType, id) => {
  switch(itemType){
    case 'OFFER': return `/payments/${id}`;
    case 'SALE': return `/sales/${id}`;
    case 'TOPUP': return `/top_up/${id}`;
    case 'BID': return `/offers/${id}`;
    default : return '#';
  }
}

const CardItem = props => (
  <Card body className="mb-0">
    <CardItemHeader>
      <InvoiceNumber>
        (#{props.itemId}) {props.invoiceNumber}
        <div>
          <Badge color={getColorBadgeItemType(props.itemType)}>{validateItemType(props.itemType)}</Badge>{' '}
          <Badge color="success">{getStatusStr(props.itemType, props.status)}</Badge>
        </div>
      </InvoiceNumber>
      <Amount>
        <TextRupiah bold value={props.amount} prefix="Rp. "/>
      </Amount>
    </CardItemHeader>
    <DisplayDate>
      <strong>created</strong> <i>{props.createdAt}</i> {' '} <br/>
      <strong>updated</strong> <i>{props.updatedAt}</i>
    </DisplayDate>
    {
      isVisibleMoreDetailBtn(props.itemType)?
      <Button
        title="More Details"
        onClick={()=>props.onMoreDetailClick(getRedirectLink(props.itemType, props.itemId))}
      >
        More Details
      </Button>:
      null
    }
  </Card>
)

export default CardItem;

CardItem.defaultProps = {
  itemType: 'OFFER',
  amount: 0,
  createdAt: '-',
  updatedAt: '-',
  invoiceNumber: '-'
}

CardItem.propTypes = {
  itemId: propTypes.number.isRequired,
  status: propTypes.string,
  invoiceNumber: propTypes.string,
  itemType: propTypes.string,
  amount: propTypes.number,
  createdAt: propTypes.string,
  updatedAt: propTypes.string,
  onMoreDetailClick: propTypes.func
}