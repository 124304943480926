import React from "react";
import PropTypes from "prop-types";
import { Alert } from "reactstrap";
import get from "lodash/get";
import { HTTP_STATUS } from "../../constants/kickavenue";
import { getBeErrorMessages } from "./SubmissionStatus.utils";

const SubmissionStatus = (props) => {
  const renderErrorMessage = () => {
    const error = get(props, "error");
    if (!error) {
      return <div />;
    }

    const statusCode = get(error, "status_code");
    const errorMessages = getBeErrorMessages(error);
    const invalidInputError = get(errorMessages, "length") ? (
      <Alert color="danger" key="error">
        <strong>Oh Snap!</strong> Please correct the input field, based on below
        error:
        <ul>
          {errorMessages.map((err, idx) => (
            <li key={idx}>{err}</li>
          ))}
        </ul>
      </Alert>
    ) : (
      <Alert color="danger" key="error">
        <strong>Oh Snap!</strong> Please fullfill your form.
      </Alert>
    );

    switch (statusCode) {
      case HTTP_STATUS.INVALID_INPUT:
        return invalidInputError;
      case HTTP_STATUS.NOT_FOUND:
        return (
          <Alert color="danger" key="error">
            <strong>Oh Snap!</strong> Resource not found.
          </Alert>
        );
      default:
        return (
          <Alert color="danger" key="error">
            <strong>Oh Snap!</strong> We've got something errors.
          </Alert>
        );
    }
  };

  return (
    <>
      {get(props, "isFetch") && (
        <Alert color="info" className="text-center">
          Getting all data...
        </Alert>
      )}
      {get(props, "success") && (
        <Alert color="success" key="success">
          <strong>Well done!</strong> {get(props, "success")}
        </Alert>
      )}
      {renderErrorMessage()}
    </>
  );
};

SubmissionStatus.propTypes = {
  success: PropTypes.string,
  error: PropTypes.shape({
    status_code: PropTypes.number,
    message: PropTypes.string,
  }),
};

SubmissionStatus.defaultProps = {
  success: false,
  error: {
    status_code: 0,
    message: "",
  },
};

export default SubmissionStatus;
