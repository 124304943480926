import React from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Row,
  Col,
  Card,
  CardText,
  CardSubtitle
} from 'reactstrap';
import styled from 'styled-components';

import Paginations from '../../../components/Paginations';
import PaginationSummaries from '../../../components/Paginations/PaginationSummaries';
import Loading from 'react-loading-animation';
import AlertNotFound from '../../../components/Card/AlertNotFound';

const CardTitleStyled = styled.p`
  font-size: .75rem;
  font-weight: 700;
  margin-bottom: .5rem;
`

const translateType = type => {
  switch(type){
    case 'CREATED': return 'Sell Created.';
    case 'UPDATED': return 'Sell Updated.';
    case 'APPROVED': return 'Sell Approved.';
    case 'PRICE_CHANGED': return 'Price Changed.';
    case 'STATUS_CHANGED': return 'Status Changed.';
    case 'EXPIRY_CHANGED': return 'Sell Extended.';
    default: return 'Undefined'
  }
}
export default class ModalViewLogs extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      page: 1
    }
    this.handleNextPage = this.handleNextPage.bind(this);
  }

  componentDidUpdate(prevProps){
    if(prevProps.isOpen && prevProps.isOpen !== this.props.isOpen)
      this.setState({ page: 1 });
  }

  handleNextPage(page){
    this.props.onNextPage({ params: {
        page
      }
    });
    this.setState({
      page
    })
  }

  render(){
    return (
      <Modal isOpen={this.props.isOpen} toggle={this.props.toggle}>
        <ModalHeader toggle={this.props.toggle}>
          Sell Request Journals
        </ModalHeader>
        <ModalBody>
          <CardText>
            Showing you up your sell's logs.
          </CardText>
          <PaginationSummaries
            from={this.props.from}
            to={this.props.to}
            total={this.props.total}
          />
          <Row style={{maxHeight: 340, overflow: 'auto'}}>
            {
              !this.props.isLoading&&this.props.statusCode===200?
              (
                this.props.data.length?
                this.props.data.map(item=>
                  <Col xs={12} key={item.id}>
                    <Card body style={{marginBottom: '.75rem', padding: '.75rem 1rem'}}>
                      <CardTitleStyled>{translateType(item.type)}</CardTitleStyled>
                      <CardSubtitle style={{fontSize: '.75rem', marginBottom: '.5rem'}}>by <span style={{fontStyle: 'italic'}}>{item.user_id? item.user.email : 'System'}</span></CardSubtitle>
                      <div style={{display: 'flex', flexDirection: 'column'}}>
                        <CardText style={{fontWeight: 700, marginBottom: '.5rem'}}>From - To</CardText>
                        <CardText>{item.before? item.before: 'Empty'} - {item.after? item.after: 'Empty'}</CardText>
                      </div>
                      <CardText style={{marginBottom: 0, fontSize: '.65rem', marginTop: '.5rem'}}>
                        <strong>created</strong>{' '}{item.created_at} <br/>
                        <strong>updated</strong>{' '}{item.updated_at}
                      </CardText>
                    </Card>
                  </Col>
                )
                :
                <Col xs={12}>
                  <AlertNotFound/>
                </Col>
              )
              :
              (this.props.isLoading?
                <Col xs={12}>
                  <Loading/>
                </Col>
              :null)
            }
          </Row>
          <div style={{marginTop: '.5rem', display: 'flex', justifyContent: 'flex-end'}}>
            <Paginations
              size="sm"
              currentPage={this.state.page}
              lastPage={this.props.lastPage}
              perPage={this.props.perPage}
              total={this.props.total}
              maxPage={8}
              handleGotoCallback={this.handleNextPage}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={this.props.toggle}>Close</Button>
        </ModalFooter>
      </Modal>
    )
  }
}
