import React from 'react';
import { connect } from 'react-redux';
import propTypes from 'prop-types';
// import classNames from 'classnames';
import styled from 'styled-components';
import {
  ButtonDropdown as ButtonDropdownBs,
  DropdownToggle as DropdownToggleBs,
  DropdownMenu,
  DropdownItem as DropdownItemBs
} from 'reactstrap';

import { SimpleLineIcon } from '../Icons';
import { getOptionList } from '../../actions/categoriesAction';

const DropdownToggle = styled(DropdownToggleBs)`
  border: none;
  padding: 0px;
  &:active,&:focus,&:hover{
    border: none;
    padding: 0px;
    background: transparent;
    outline: none;
    box-shadow: none;
  }
  ~ .dropdown-menu{
    background-color: rgba(255,255,255,.8);
  }
`;
export const DropdownItem = styled(DropdownItemBs)`
  text-transform: capitalize;
`;

export const ButtonDropdown = props => (
  <ButtonDropdownBs isOpen={props.isOpen} toggle={props.toggle}>
    <DropdownToggle size="sm">
      <SimpleLineIcon iconType="options-vertical"/>
    </DropdownToggle>
    {props.renderChildren()}
  </ButtonDropdownBs>
)
