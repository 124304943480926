import React from "react";
import propTypes from "prop-types";
import Dropzone from "react-dropzone";
import { SimpleLineIcon } from "../../../../components/Icons";

const styles = {
    dropzoneContent: {
        width: "100%",
        height: "auto",
        // borderWidth: 2,
        borderColor: "rgb(102, 102, 102)",
        borderRadius: 0,
        borderStyle: "dashed",
        cursor: "pointer",
    },
    p: {
        padding: 15,
        userSelect: "none",
    },
    h1: {
        fontSize: 70,
    },
};

const maxSize = 1048576 * 2 // 2 mb

const ImageDropzone = (props) => (
    <Dropzone
        accept="image/jpeg, image/png"
        onDrop={props.onDrop}
        style={styles.dropzoneContent}
        maxSize={props.maxSize || maxSize}
        
    >
        <p style={styles.p}>
            Try dropping your images here, or click to select image to upload.
        </p>
        <h1 className="text-center" style={styles.h1}>
            <SimpleLineIcon iconType="plus" />
        </h1>
    </Dropzone>
);

ImageDropzone.propTypes = {
    onDrop: propTypes.func,
    maxSize: propTypes.number
};

ImageDropzone.defaultProps = {
    maxSize
};

export default ImageDropzone;
