import { DISBURSEMENT_STATUS, DISBURSEMENT_TYPE } from "../../constants/disbursement";

export const isDisableImmediateApproveCheckbox = (disbursement) =>
  disbursement.disbursement_type !== DISBURSEMENT_TYPE.BALANCE_IN;

export const isDisableImmediatePendingCheckbox = (disbursement) =>
  disbursement.disbursement_type !== DISBURSEMENT_TYPE.BALANCE_OUT;

export const determineDisbursementStatus = (disbursement) => {
  const isBalanceIn = disbursement.disbursement_type === DISBURSEMENT_TYPE.BALANCE_IN;
  const isBalanceOut = disbursement.disbursement_type === DISBURSEMENT_TYPE.BALANCE_OUT;
  const status = disbursement.status;
  const statusPending = disbursement.statusPending;
  
  if (isBalanceIn) {
    return !!status ? DISBURSEMENT_STATUS.COMPLETED : DISBURSEMENT_STATUS.PENDING;
  }

  if (isBalanceOut && statusPending) {
    return DISBURSEMENT_STATUS.PENDING;
  }

  return DISBURSEMENT_STATUS.UNVERIFIED;
}
