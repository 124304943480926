import React, { Component } from 'react';
import { createBrowserHistory } from 'history';
import { Router, Route, Switch } from 'react-router-dom'

// Containers
import App from './containers/Layouts/App';

// Views
import Login from './views/Pages/Login/'
import Register from './views/Pages/Register/'
import Page404 from './views/Pages/Page404/'
import Page403 from './views/Pages/Page403/'
import Page500 from './views/Pages/Page500/'
import Page503 from './views/Pages/Page503'

import 'react-dates/lib/css/_datepicker.css';
import './index.css'

const history = createBrowserHistory();
history.listen(function (location, action) {
	if (['PUSH', 'REPLACE'].includes(action)) {
		try {
			window.scrollTo({
				top: 0,
				behavior: 'smooth'
			})
		} catch (e) {
			console.log("Error: ", e);
		}
	}
})
export default class AppRoute extends Component {
	render() {
		return (
			<Router history={history}>
				<Switch>
					<Route exact path="/login" name="Login Page" component={Login} />
					<Route exact path="/register" name="Register Page" component={Register} />
					<Route exact path="/404" name="Page 404" component={Page404} />
					<Route exact path="/403" name="Page 403" component={Page403} />
					<Route exact path="/500" name="Page 500" component={Page500} />
					<Route exact path="/503" name="Page 503" component={Page503} />
					<Route path="/" name="Home" component={App} />
				</Switch>
			</Router>
		)

	}
}