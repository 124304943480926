import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Input } from 'reactstrap';
import propTypes from 'prop-types';

import { sellRequestConstantStatus, sellRequestStatusOptions } from '../../../constants/kickavenue';
import { TextRupiah } from '../../../components/Text';
import DateToHuman from '../../../components/DateToHuman';
import ProductColumn from './rowItem/ProductColumn';
import { updateStatusUserSell } from '../../../actions/sellRequestAction';
import ExpressLowestAsk from './ExpressLowestAsk';

class RowItem extends React.Component {
  constructor(props){
    super(props);
    this.state={
      preVerified: props.preVerified,
      _preVerifiedEdit: props.preVerified,
      status: props.status,
      _statusEdit: props.status,
      updatedAt: props.updatedAt,
      approvedAt: props.approvedAt
    }
    this._handleStatusChange = this._handleStatusChange.bind(this);
  }

  shouldComponentUpdate(nextProps){
    if(nextProps.sellRequest.isSubmit===true){
      return false;
    }
    if(!nextProps.sellRequest.isSubmit&&nextProps.sellRequest.isSubmit!==this.props.sellRequest.isSubmit){
      if(nextProps.sellRequest.error){
        const { error: { id } } = nextProps.sellRequest;
        if(id && id != this.props.id){
          return false;
        }
      }
      else{
        if(nextProps.sellRequest.id && nextProps.sellRequest.id != this.props.id){
          return false;
        }
      }
    }
    return true;
  }

  componentWillReceiveProps(nextProps){
    if(!nextProps.sellRequest.isSubmit&&nextProps.sellRequest.isSubmit!==this.props.sellRequest.isSubmit){
      if(nextProps.sellRequest.error){
        const { error: { id } } = nextProps.sellRequest;
        if(id && id === this.props.id){
          this.setState({
            _statusEdit: this.state.status,
          });

          alert(nextProps.sellRequest?.error?.message || "Opps, we got undefined error. Please check your internet connection or report this problem");
        }
      }
      else{
        if(nextProps.sellRequest.id && nextProps.sellRequest.id === this.props.id){
          const { status, updated_at, approved_at } = nextProps.sellRequest;
          // console.log('APPROVED AT', approved_at)
          this.setState({
            status: status,
            _statusEdit: status,
            updatedAt: updated_at,
            approvedAt: approved_at,
          })
        }
      }
    }
  }

  _handleStatusChange(e){
    this.setState({_statusEdit: e.target.value}, ()=>{
      this.props.updateSellRequest(this.props.id, { status: this.state._statusEdit })
    });
  }

  render(){
    const {
      id,
      rowId,
      category,
      displayName,
      SKU,
      colour,
      sneakersCondition,
      userEmail,
      askingPrice,
      preOrder,
      preVerified,
      consignmentId,
      rack,
      qty,
      size,
      isExpired,
      isSellerHoliday,
      defectsCount,
      inclusionsCount,
      requestType,
      consignment,
      boxCondition,
      status,
      isNewNoDefect,
    } = this.props;
    const isConsignment = consignment || requestType === "CONSIGNMENT_REQUEST";

    return (
      <tr>
        <th className="v-center" scope="row">{ rowId }</th>
        <td className="v-center">
          <ProductColumn
            userSellId={id}
            displayName={displayName}
            userEmail={userEmail}
            size={size}
            preOrder={preOrder}
            preVerified={preVerified}
            SKU={SKU}
            colour={colour}
            qty={qty}
            sneakersCondition={sneakersCondition}
            isExpired={isExpired}
            isSellerHoliday={isSellerHoliday}
            category={category}
            defectsCount={defectsCount}
            inclusionsCount={inclusionsCount}
            boxCondition={boxCondition}
          />
        </td>
        <td className="v-center">{isConsignment ? "Consignment Request" : "Sell Request"}</td>
        <td className="v-center">
          <ExpressLowestAsk 
            sellId={id} 
            isConsignment={isConsignment} 
            isNewNoDefect={isNewNoDefect}
            preVerified={preVerified}
            preOrder={preOrder}
            status={status}
            qty={qty}
          />
        </td>
        <td className="v-center">
          <TextRupiah prefix="Rp. " bold value={askingPrice}/>
          {
              (consignmentId || rack) && (
                <div style={{fontSize: '.65rem'}}>
                  {consignmentId? <i className="fa fa-barcode mr-1"></i>:null}
                  {consignmentId? consignmentId: '-'}<br />
                  {rack? <i className="fa fa-barcode mr-1"></i>:null}
                  {rack? rack + ' (RACK)':null}
                </div>
              )
            }
          {
            this.state.approvedAt && (<div style={{fontSize: '.65rem'}}>
              Approved:<br/>
              {this.state.approvedAt}
            </div>)
          }
        </td>
        <td className="v-center"><DateToHuman  value={this.state.updatedAt} id={`tooltip_sellRequest_rowItem_updatedAt_${rowId}`}/></td>
        <td className="v-center">
          <Input
            type="select"
            name="_statusEdit"
            onChange={this._handleStatusChange}
            value={this.state._statusEdit}
            disabled={sellRequestConstantStatus.includes(this.state._statusEdit)}
          >
          { sellRequestStatusOptions.map((i,indx)=> {
              return <option value={i.value} key={indx}>{i.label}</option>
            }) }
          </Input>
        </td>
        <td className="v-center">
          <Link to={`${this.props.defaultRoute}/${id}`} className="btn btn-link">Details</Link>
        </td>
      </tr>
    )
  }
}

const mapStateToProps = ({ sellRequest: { updateStatus, isSubmit } }) => ({
  sellRequest: { ...updateStatus, isSubmit }
})
const mapDispatchToProps = (dispatch) => ({
  updateSellRequest: (id, payload) => dispatch(updateStatusUserSell(id, payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(RowItem);

RowItem.propTypes = {
  rowId: propTypes.number.isRequired,
  id: propTypes.number.isRequired,
  productVariantId: propTypes.number.isRequired,
  displayName: propTypes.string,
  SKU: propTypes.string,
  colour: propTypes.string,
  userEmail: propTypes.string,
  askingPrice: propTypes.string,
  status: propTypes.string,
  qty: propTypes.number,
  updatedAt: propTypes.string,
  size: propTypes.object,
  consignmentId: propTypes.string,
  rack: propTypes.string,
  // onStatusChange: propTypes.func,
  preVerified: propTypes.oneOfType([propTypes.bool,propTypes.number]),
  preOrder: propTypes.oneOfType([propTypes.bool,propTypes.number]),
  isExpired: propTypes.oneOfType([propTypes.bool,propTypes.number]),
  isSellerHoliday: propTypes.bool,
  defaultRoute: propTypes.string,
  requestType: propTypes.string,
  consignment: propTypes.bool,
  isNewNoDefect: propTypes.bool,
}

RowItem.defaultProps = {
  SKU: '-',
  colour: '-',
  status: 'draft',
  size: {
    US: 'Not Set'
  },
  isNewNoDefect: false,
}