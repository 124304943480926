import {
  APP_SECTION_ROUTE_API,
  GET_LIST_APP_SECTION,
  GET_DETAIL_APP_SECTION,
  DELETE_APP_SECTION,
  UPDATE_APP_SECTION,
  CREATE_APP_SECTION,
  APP_SECTION_ERROR,
  APP_SECTION_REQUEST,

  APP_SECTION_S3,
  CREATE_APP_SECTION_IMAGE,
  UPDATE_APP_SECTION_IMAGE,
  DELETE_APP_SECTION_IMAGE,
  GET_DETAIL_APP_SECTION_IMAGES
} from '../constants';
import {
  onRequest,
  onSuccessRequest,
  onFailedRequest
} from './helpers';
import { apiClient } from '../utils/Api';
import { clientPutObject } from '../utils/AWS';
import {
  AWS_BUCKET,
  AWS_BUCKET_URL
} from '../config/storageBucket';
import mime from 'mime-types';



export const getAppSectionList = q => (
  dispatch => {
    dispatch(onRequest(APP_SECTION_REQUEST, GET_LIST_APP_SECTION, true));
    apiClient()
    .get(APP_SECTION_ROUTE_API, q)
    .then(res => {
      dispatch(onSuccessRequest(GET_LIST_APP_SECTION, res.data));
      dispatch(onRequest(APP_SECTION_REQUEST, GET_LIST_APP_SECTION, false));
    })
    .catch(err => {
      dispatch(onFailedRequest(APP_SECTION_ERROR, GET_LIST_APP_SECTION, err));
      dispatch(onRequest(APP_SECTION_REQUEST, GET_LIST_APP_SECTION, false));
    });
  }
)

export const getAppSectionById = id => (
  dispatch => {
    dispatch(onRequest(APP_SECTION_REQUEST, GET_DETAIL_APP_SECTION, true));
    apiClient()
    .get(`${APP_SECTION_ROUTE_API}/${id}`)
    .then(res => {
      dispatch(onSuccessRequest(GET_DETAIL_APP_SECTION, res.data));
      dispatch(onRequest(APP_SECTION_REQUEST, GET_DETAIL_APP_SECTION, false));
    })
    .catch(err => {
      dispatch(onFailedRequest(APP_SECTION_ERROR, GET_DETAIL_APP_SECTION, err, id));
      dispatch(onRequest(APP_SECTION_REQUEST, GET_DETAIL_APP_SECTION, false));
    });
  }
)

export const deleteAppSectionById = id => (
  dispatch => {
    dispatch(onRequest(APP_SECTION_REQUEST, DELETE_APP_SECTION, true));
    apiClient()
    .delete(`${APP_SECTION_ROUTE_API}/${id}`)
    .then(res => {
      dispatch(onSuccessRequest(DELETE_APP_SECTION, res.data));
      dispatch(onRequest(APP_SECTION_REQUEST, DELETE_APP_SECTION, false));
    })
    .catch(err => {
      dispatch(onFailedRequest(APP_SECTION_ERROR, DELETE_APP_SECTION, err, id));
      dispatch(onRequest(APP_SECTION_REQUEST, DELETE_APP_SECTION, false));
    });
  }
)

export const saveAppSection = (payload) => {
  const { id, ...body } = payload;
  return id? updateAppSection(id, body): createAppSection(body)
}

const updateAppSection = (id, payload) => (
  dispatch => {
    dispatch(onRequest(APP_SECTION_REQUEST, UPDATE_APP_SECTION, true));
    apiClient()
    .put(`${APP_SECTION_ROUTE_API}/${id}`, payload)
    .then(res => {
      dispatch(onSuccessRequest(UPDATE_APP_SECTION, res.data));
      dispatch(onRequest(APP_SECTION_REQUEST, UPDATE_APP_SECTION, false));
    })
    .catch(err => {
      dispatch(onFailedRequest(APP_SECTION_ERROR, UPDATE_APP_SECTION, err, id));
      dispatch(onRequest(APP_SECTION_REQUEST, UPDATE_APP_SECTION, false));
    });
  }
)

const createAppSection = (payload) => (
  dispatch => {
    dispatch(onRequest(APP_SECTION_REQUEST, CREATE_APP_SECTION, true));
    apiClient()
    .post(APP_SECTION_ROUTE_API, payload)
    .then(res => {
      dispatch(onSuccessRequest(CREATE_APP_SECTION, res.data));
      dispatch(onRequest(APP_SECTION_REQUEST, CREATE_APP_SECTION, false));
    })
    .catch(err => {
      dispatch(onFailedRequest(APP_SECTION_ERROR, CREATE_APP_SECTION, err));
      dispatch(onRequest(APP_SECTION_REQUEST, CREATE_APP_SECTION, false));
    });
  }
)

export const getAppSectionImageList = id => (
  dispatch => {
    dispatch(onRequest(APP_SECTION_REQUEST, GET_DETAIL_APP_SECTION_IMAGES, true));
    apiClient()
    .get(`${APP_SECTION_ROUTE_API}/${id}`)
    .then(res => {
      dispatch(onSuccessRequest(GET_DETAIL_APP_SECTION_IMAGES, res.data));
      dispatch(onRequest(APP_SECTION_REQUEST, GET_DETAIL_APP_SECTION_IMAGES, false));
    })
    .catch(err => {
      dispatch(onFailedRequest(APP_SECTION_ERROR, GET_DETAIL_APP_SECTION_IMAGES, err, id));
      dispatch(onRequest(APP_SECTION_REQUEST, GET_DETAIL_APP_SECTION_IMAGES, false));
    });
  }
)

export const deleteAppSectionImageById = (sectionId, id) => (
  dispatch => {
    dispatch(onRequest(APP_SECTION_REQUEST, DELETE_APP_SECTION_IMAGE, true));
    apiClient()
    .delete(`${APP_SECTION_ROUTE_API}/${sectionId}/images/${id}`)
    .then(res => {
      dispatch(onSuccessRequest(DELETE_APP_SECTION_IMAGE, res.data));
      dispatch(onRequest(APP_SECTION_REQUEST, DELETE_APP_SECTION_IMAGE, false));
    })
    .catch(err => {
      dispatch(onFailedRequest(APP_SECTION_ERROR, DELETE_APP_SECTION_IMAGE, err, id));
      dispatch(onRequest(APP_SECTION_REQUEST, DELETE_APP_SECTION_IMAGE, false));
    });
  }
)

export const saveAppSectionImage = (sectionId, payload) => {
  const { id, ...body } = payload;
  return id? updateAppSectionImage(sectionId, id, body): createAppSectionImage(sectionId, body)
}

const updateAppSectionImage = (sectionId, id, payload) => (
  dispatch => {
    dispatch(onRequest(APP_SECTION_REQUEST, UPDATE_APP_SECTION_IMAGE, true));
    apiClient()
    .put(`${APP_SECTION_ROUTE_API}/${sectionId}/images/${id}`, payload)
    .then(res => {
      dispatch(onSuccessRequest(UPDATE_APP_SECTION_IMAGE, res.data));
      dispatch(onRequest(APP_SECTION_REQUEST, UPDATE_APP_SECTION_IMAGE, false));
    })
    .catch(err => {
      dispatch(onFailedRequest(APP_SECTION_ERROR, UPDATE_APP_SECTION_IMAGE, err, id));
      dispatch(onRequest(APP_SECTION_REQUEST, UPDATE_APP_SECTION_IMAGE, false));
    });
  }
)

const createAppSectionImage = (sectionId, payload) => (
  dispatch => {
    dispatch(onRequest(APP_SECTION_REQUEST, CREATE_APP_SECTION_IMAGE, true));
    apiClient()
    .post(`${APP_SECTION_ROUTE_API}/${sectionId}/images`, payload)
    .then(res => {
      dispatch(onSuccessRequest(CREATE_APP_SECTION_IMAGE, res.data));
      dispatch(onRequest(APP_SECTION_REQUEST, CREATE_APP_SECTION_IMAGE, false));
    })
    .catch(err => {
      dispatch(onFailedRequest(APP_SECTION_ERROR, CREATE_APP_SECTION_IMAGE, err));
      dispatch(onRequest(APP_SECTION_REQUEST, CREATE_APP_SECTION_IMAGE, false));
    });
  }
)

export const uploadAppSectionImage = (folder, file, customFileName = null) => (
  dispatch => {
    let key = `${folder}${file.identifier}.${mime.extension(file.fileInput.type)}`;
    if(customFileName){
      key = `${folder}${customFileName}`;
    }
    dispatch({
      type: APP_SECTION_S3,
      payload: { isPending: true }
    })
    clientPutObject({
      Bucket: AWS_BUCKET,
      Key: key,
      Body: file.fileInput,
      ContentType:'image/jpeg',
      ACL: 'public-read', // your permisions
    }).then(res => {
      const results={
        identifier:file.identifier,
        prefix:AWS_BUCKET_URL,
        folder,
        url:`${AWS_BUCKET_URL}${res.key}`
      }
      dispatch({
        type: APP_SECTION_S3,
        payload: { results, isPending: false }
      });
    }).catch(err => {
      dispatch(onFailedRequest(APP_SECTION_ERROR,
        APP_SECTION_S3, {
          type: APP_SECTION_S3,
          payload: { error: err }
        })
      );
    })
  }
)