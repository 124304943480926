import React from 'react';
import styled from 'styled-components';
import BadgeThin from '../../../../components/Badges/BadgeThin';
import { FontAwesomeIcon } from '../../../../components/Icons';

const TitleDiv = styled.div`
  flex: 1;
`
const ParagraphOneDiv = styled.p`
  font-size: 18px;
  margin-bottom: 0;
`
const ParagraphTwoDiv = styled.p`
  font-size: 10px;
  margin-bottom: 0;
  display: flex;
  align-items: center;
`

const TitleHeader = props =>(
  <TitleDiv>
    <ParagraphOneDiv>
      {props.displayName}
    </ParagraphOneDiv>
    <ParagraphTwoDiv>
      {props.category&&(
        <BadgeThin color="primary" className="text-uppercase mr-1">
          <FontAwesomeIcon className="mr-1" iconType="tags"/>{ props.category }
        </BadgeThin>
      )}
      {props.SKU} / {props.email} / {props.createdAt}
    </ParagraphTwoDiv>
    <ParagraphTwoDiv>
      { `${props.usSize}(US)` }&nbsp;
      { props.sneakersCondition }&nbsp;
      { props.preOrder?'PREORDER':'' }
    </ParagraphTwoDiv>
  </TitleDiv>
)
export default TitleHeader;