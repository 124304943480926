import React from 'react';
import { Button } from 'reactstrap';
import classNames from 'classnames';

export default class ButtonBackToTop extends React.Component{
  constructor(props){
    super(props)
    this.state={
      intervalId: null,
      isVisible: false
    }
    this._scrollToTop = this._scrollToTop.bind(this);
    this._handleScrolling = this._handleScrolling.bind(this);
  }

  componentDidMount(){
    document.addEventListener('scroll', this._handleScrolling)
  }

  _handleScrolling(){
    const { scrollY, innerHeight } = window
    // console.log(innerHeight);
    if(scrollY>=innerHeight/3 && !this.state.isVisible){
      this.setState({
        isVisible: !this.state.isVisible
      })
    }
    else if(scrollY<innerHeight/3 && this.state.isVisible){
      this.setState({
        isVisible: !this.state.isVisible
      })
    }
  }
  
  _scrollStep() {
    if (window.pageYOffset === 0 && this.state.intervalId) {
        const { intervalId } = this.state;
        this.setState({
          intervalId:null
        },()=>{
          clearInterval(intervalId);
        })
    }
    // console.log(window.pageYOffset);
    window.scroll(0, window.pageYOffset - this.props.scrollStepInPx);
  }
  
  _scrollToTop() {
    const intervalId = setInterval(this._scrollStep.bind(this), this.props.delayInMs);
    this.setState({ intervalId: intervalId });
  }

  componentWillUnmount(){
    if(this.state.intervalId){
      clearInterval(this.state.intervalId);
    }
		document.removeEventListener('scroll', this._handleScrolling);
  }
  render(){
    return(
      <Button color="secondary" style={this.state.styles} className={classNames({visible:this.state.isVisible})} onClick={this._scrollToTop} id="backToTopButton" title="Back To Top">
        <i className="fa fa-long-arrow-up"></i>
      </Button>
    )
  }
}