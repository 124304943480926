import React, {Component} from 'react';
import {Card, CardHeader, CardBody, CardText} from 'reactstrap';
import {Switch,Route, Link} from 'react-router-dom';
import {connect} from 'react-redux';
import {getDetailProductVariant} from '../../../actions/productVariantAction'
import ProductCard from './ProductCard';
import StockSizes from './StockSizes';
import StockSells from './StockSells';
import Loading from 'react-loading-animation';
import privateView from '../../../components/hocs/privateView';
import Analytics from "../../../services/Analytics";

class StockDetail extends Component{
    constructor(props){
        super(props);
        this.state={
            _id:this.props.match.params.id
        }
    }
    componentDidMount(){
        const { email } = this.props.injectedProps;
        this.props.findById(this.state._id);
        Analytics.recordPageView('stock_detail', email, {
            url: window.location.href,
            location: this.props.location,
            match: this.props.match
        });
    }

    isLoading(){
        return !this.props.productVariant.isFetch&&this.props.productVariant.detail.status_code===200;
    }

    render(){
        return(
            <Card>
                <CardHeader>
                    <i className="fa fa-check"></i>
                    {
                        this.isLoading()?
                            <Link className="link-secondary" to={`/stock/${this.state._id}`}>{this.props.productVariant.detail.display_name}</Link>
                        :'Loading...'
                    }
                </CardHeader>
                <Loading style={{marginTop:15}} isLoading={!this.isLoading()}>
                    <ProductCard {...this.props.productVariant.detail}/>
                </Loading>
                <Switch>
                    <Route path="/stock/:id/sizes/:size_id" detail={this.props.productVariant.detail} component={StockSells}/>
                    <Route detail={this.props.productVariant.detail} component={StockSizes}/>
                </Switch>
            </Card>
        )
    }
}
const mapStateToProps= ({ productVariant, auth:{isFetch,isLogged,roles, id, email} }) => {
	return {
		productVariant,
        guardData: { 
          isFetch, 
          isLogged,
          roles,
          id,
          email
        }
	}
};

const mapDispatchToProps = (dispatch) => {
	return{
		findById:(id) => dispatch(getDetailProductVariant(id))
	}

};
const enhance = connect(mapStateToProps,mapDispatchToProps);
export default enhance(privateView(StockDetail, ['supervisor','sale_supervisor','inventory_admin','cashier']));
