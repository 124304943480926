import React, {PureComponent} from 'react';
import { Row, Col, Card, CardHeader, CardBody, CardText } from 'reactstrap';
import PropTypes from 'prop-types';
import { UserDetail } from '../../../components/Users';
import { Link } from 'react-router-dom';
import SellerVerificationList from '../../../components/Order/sellerDetail/SellerVerificationList';

export default class UserContainer extends PureComponent{
  render(){
    return(
      <Card body className="tab-content--custom__card">
        <Row>
          <Col xs={12} md={6}>
            <CardText>Your seller details. {!this.props.isLoading?<Link to={`/users/${this.props.user.id}`} title="Go to User Details." target="blank">See more details.</Link>:null}</CardText>
            <UserDetail
              headerText="Seller Details"
              user={this.props.user}
              displayRole
              isLoading={this.props.isLoading}
            />   
          </Col>
          <Col xs={12}>
            <SellerVerificationList
              data={this.props.sellerVerificationList}
            />
          </Col>
        </Row>       
      </Card>
    )
  }
}
UserContainer.propTypes={
  user:PropTypes.object,
  sellerVerificationList: PropTypes.array,
  isLoading: PropTypes.bool
}
UserContainer.defaultProps = {
  isLoading: false,
  sellerVerificationList: []
}