import AWS from 'aws-sdk';
import mime from 'mime-types';
import uuidv4 from 'uuid/v4';
import {
  AWS_BUCKET,
  AWS_BUCKET_URL,
  AWS_BUCKET_USER,
  AWS_BUCKET_USER_URL
} from '../config/storageBucket';
import {
  AUTH_TOKEN_KEY,
  UPLOAD_IMAGES,
  UPLOADING_IMAGES,
  UPLOADING_IMAGES_ERROR,
  DELETING_OBJECT_S3_PROCESS,
  DELETING_OBJECT_S3_DONE,
  DELETING_OBJECT_S3_ERROR,
  CLEAR_IMAGE_UPLOAD,
  UPLOAD_IMAGES_API,
  DELETING_IMAGE_API,
  DELETING_IMAGE_REQUEST,
  DELETING_IMAGE_ERROR,
  API_BASE_URL
} from '../constants';
import api from '../utils/Api';
import { s3, clientPutObject, clientDeleteObject } from '../utils/AWS';
import { rest } from 'lodash';
import axios from 'axios'

const headersWithToken = () => ({
  headers: {
    Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`,
    'x-kickavenue-platform': 'cms'
  }
})

export const upload = (file, folder = '', isUserAsset = false) => {
  const key = `${folder}${file.identifier}.${mime.extension(file.fileInput.type)}`;
  const _BUCKET_NAME = isUserAsset ? AWS_BUCKET_USER : AWS_BUCKET;
  const _RESOURCE_URL = isUserAsset ? AWS_BUCKET_USER_URL : AWS_BUCKET_URL;
  return dispatch => {
    dispatch(uploadingProcess(true));
    clientPutObject({
      Bucket: _BUCKET_NAME,
      Key: key,
      Body: file.fileInput,
      ContentType: 'image/jpeg',
      ACL: 'public-read', // your permisions
    }).then(res => {
      const result = {
        identifier: file.identifier,
        prefix: _RESOURCE_URL,
        folder,
        url: `${_RESOURCE_URL}${res.key}`
      }
      dispatch(onSuccess(result, UPLOAD_IMAGES));
      dispatch(uploadingProcess(false));
    }).catch(err => {
      dispatch(onError(err));
      dispatch(uploadingProcess(false));
    })
  }
}

export const deleteObjectS3 = (identifier, url, isUserAsset = false) => {
  return dispatch => {
    dispatch({
      type: DELETING_OBJECT_S3_PROCESS,
      payload: true
    });
    const _BUCKET_NAME = isUserAsset ? AWS_BUCKET_USER : AWS_BUCKET;
    clientDeleteObject(_BUCKET_NAME, url).then(res => {
      dispatch({
        type: DELETING_OBJECT_S3_DONE,
        payload: { url: res.key, identifier }
      });
      dispatch({
        type: DELETING_OBJECT_S3_PROCESS,
        payload: false
      });
    }).catch(err => {
      dispatch({
        type: DELETING_OBJECT_S3_ERROR,
        payload: err
      });
      dispatch({
        type: DELETING_OBJECT_S3_PROCESS,
        payload: false
      });
    })
    // s3().deleteObject({
    //   Bucket: _BUCKET_NAME,
    //   Key: url,
    // }, (err) => {
    //   if (err) {
    //     dispatch({
    //       type: DELETING_OBJECT_S3_ERROR,
    //       payload: err
    //     });
    //   } else {
    //     dispatch({
    //       type: DELETING_OBJECT_S3_DONE,
    //       payload: { url, identifier }
    //     });
    //   }
    //   dispatch({
    //     type: DELETING_OBJECT_S3_PROCESS,
    //     payload: false
    //   });
    // })
  }
}

export const uploads = (files, folder, isUserAsset = false) => {
  return dispatch => {
    Object.keys(files).map(index => {
      const key = `${folder}${index}.${mime.extension(files[index].fileInput.type)}`;
      const _BUCKET_NAME = isUserAsset ? AWS_BUCKET_USER : AWS_BUCKET;
      const _RESOURCE_URL = isUserAsset ? AWS_BUCKET_USER_URL : AWS_BUCKET_URL;
      s3().putObject({
        Bucket: _BUCKET_NAME,
        Key: key,
        Body: files[index].fileInput,
        ContentType: 'image/jpeg',
        ACL: 'public-read', // your permisions
      }, (err) => {
        if (err === null) {
          const result = {
            identifier: index,
            prefix: _RESOURCE_URL,
            folder: folder,
            url: `${_RESOURCE_URL}${key}`
          }
          dispatch(onSuccess(result, UPLOAD_IMAGES));
        }
        else {
          dispatch(onError(err));
        }
        dispatch(uploadingProcess(false));
      })
    })
  }
}

export const uploadImage = (payload, rawImages = false, callback) => {
  return dispatch => {
    dispatch(uploadingProcess(true));
    api({
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`,
      // 'content-type': 'multipart/form-data'
    }).post(`admins/assets`, payload)
      .then(res => {
        let data = null;
        let result = res.data;
        const dataApi = res.data.data;
        if (rawImages) {
          if (rawImages.identifier) {//as object
            //transfrom response as object if its only given as array from api and get first element.
            //because our input just one image.
            const dataApiResult = dataApi.data && dataApi.data.length ? dataApi.data[0] : dataApi
            result = { ...result, data: { ...dataApiResult, identifier: rawImages.identifier, orientation: rawImages.orientation ? rawImages.orientation : null } }
          }
          else {//as array
            //transfrom response as array if its only given as object from api
            //because our input as array.
            const list = dataApi.data ? dataApi.data : [dataApi]
            data = list.map((r, key) => {
              if (key < rawImages.length) {
                return {
                  ...r,
                  identifier: rawImages[key].identifier,
                  orientation: rawImages[key].orientation ? rawImages[key].orientation : null,
                }
              }
              return r;
            });
            result = { ...res.data, data: { ...res.data.data, data } }
          }
        }

        callback && callback(result)
        dispatch(onSuccess(result, UPLOAD_IMAGES_API));
        dispatch(uploadingProcess(false));
      })
      .catch(err => {
        dispatch(onError(err));
        dispatch(uploadingProcess(false));
      })
  }
}

//No redut
export const uploadImageNoRedux = async (payload) => {
  return await axios.post(`${API_BASE_URL}/admins/assets`, payload, headersWithToken())
}

export const deleteImage = payload => {
  return dispatch => {
    const { identifier, ...data } = payload
    dispatch({ type: DELETING_IMAGE_REQUEST, payload: true });
    api({
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`,
    }).delete(`admins/assets`, { data })
      .then(res => {
        const { name: key } = payload;
        const result = { identifier: null, key, ...res.data }
        if (identifier) result.identifier = identifier;
        dispatch(onSuccess(result, DELETING_IMAGE_API));
        dispatch({ type: DELETING_IMAGE_REQUEST, payload: false });
      })
      .catch(err => {
        dispatch({ type: DELETING_IMAGE_ERROR, payload: err });
        dispatch({ type: DELETING_IMAGE_REQUEST, payload: false });
      })
  }
}

export const uploadingProcess = (bool) => {
  return {
    type: UPLOADING_IMAGES,
    payload: bool
  }
}

export const clearImageUpload = () => ({
  type: CLEAR_IMAGE_UPLOAD
})

export const onSuccess = (payload, type) => {
  return {
    type: type,
    payload: payload
  }
}
export const onError = (error) => {
  return {
    type: UPLOADING_IMAGES_ERROR,
    payload: error
  }
}
