import classNames from 'classnames';
import propTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Badge, Card, CardBody, CardHeader } from 'reactstrap';
import { getAuditList } from '../../actions/auditAction';
import { DEFAULT_PER_PAGE, DEFAULT_SORT_BY } from '../../constants/settings';
import DateToHuman from '../DateToHuman';
import Paginations from '../Paginations';
import PaginationSummaries from '../Paginations/PaginationSummaries';
import { Tbl, TblBody, TblHead } from '../Tbl';
import { dataHeader } from './Audit.constant';
import Style from './Audit.module.css';

const Audit = ({ id, type, per_page, sort_by, audit, getList, refresh, onSetRefresh }) => {
  const defaultGetListParams = {
    params: {
      id,
      type,
      per_page: per_page || DEFAULT_PER_PAGE,
      sort_by: sort_by || DEFAULT_SORT_BY,
    },
  };

  const hasRow = audit.list?.status_code === 200 && audit.list?.data?.length;

  useEffect(() => {
    if (!refresh) return;

    getList(defaultGetListParams);
    
  }, [refresh]);

  useEffect(() => {
    if (!audit.list?.data || !refresh) return;

    onSetRefresh(false);
  }, [audit.list?.data, refresh]);

  const getEventBadge = (event) => {
    switch (event) {
      case 'created':
        return <Badge color='primary'>CREATE</Badge>;
      case 'attached':
        return <Badge color='primary'>ATTACH</Badge>;
      case 'updated':
        return <Badge color='success'>UPDATE</Badge>;
      case 'deleted':
        return <Badge color='danger'>DELETE</Badge>;
      case 'detached':
        return <Badge color='danger'>DETACH</Badge>;
      default:
        return null;
    }
  };

  const onLoopObjectValue = (object) => {
    if (!object) return null;

    return Object.entries(object).map(([key, value]) => {
      return (
        <p className='mb-1' key={key}>
          <b>{key}</b>: {`${JSON.stringify(value)}`}
        </p>
      );
    });
  };

  const renderRowItem = () => {
    if (!audit.isFetch && audit.list?.status_code === 200) {
      const { data } = audit.list;
      return data?.map((item, index) => {
        return (
          <tr key={index}>
            <th scope='row'>{index + 1}</th>
            <td>{getEventBadge(item.event)}</td>
            <td>{item.email}</td>
            <td className={classNames(Style['column-values'])}>
              {onLoopObjectValue(item.old_values)}
            </td>
            <td className={classNames(Style['column-values'])}>
              {onLoopObjectValue(item.new_values)}
            </td>
            <td>
              <DateToHuman value={item.created_at} id={`tooltip_${index}`} />
            </td>
          </tr>
        );
      });
    }
    return (
      <tr>
        <td colSpan={7} className='text-center'>
          <b>Loading...</b>
        </td>
      </tr>
    );
  };

  const handleGotoCallback = (page) => {
    getList({ ...defaultGetListParams, page });
  };

  return (
    <Card>
      <CardHeader>
        <i className='fa fa-align-justify'></i>
        Change Log
      </CardHeader>
      <CardBody>
        <div className='table-responsive'>
          <PaginationSummaries
            from={audit.list?.from}
            to={audit.list?.to}
            total={audit.list?.total}
          />
          <Tbl>
            <TblHead dataHeader={dataHeader} />
            <TblBody
              isLoad={audit.list?.isFetch}
              hasRow={hasRow}
              columnCount={dataHeader.length}
            >
              {renderRowItem()}
            </TblBody>
          </Tbl>
        </div>
        {hasRow ? (
          <Paginations
            size='sm'
            lastPage={audit.list?.last_page}
            perPage={audit.list?.per_page}
            total={audit.list?.total}
            maxPage={8}
            path={audit.list?.path}
            currentPage={audit.list?.current_page}
            prevPageUrl={audit.list?.prev_page_url}
            nextPageUrl={audit.list?.next_page_url}
            handleGotoCallback={handleGotoCallback}
          />
        ) : null}
      </CardBody>
    </Card>
  );
};

Audit.propTypes = {
  id: propTypes.number.isRequired,
  type: propTypes.string.isRequired,
  per_page: propTypes.number,
  sort_by: propTypes.string,
  audit: propTypes.object,
  getList: propTypes.func,
  refresh: propTypes.bool,
};

Audit.defaultProps = {
  refresh: false,
  onSetRefresh: () => {},
}

const mapStateToProps = ({ audit, auth: { email } }) => ({
  audit: audit,
  guardData: { email },
});

const mapDispatchToProps = (dispatch) => ({
  getList: (queries) => dispatch(getAuditList(queries)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Audit);
