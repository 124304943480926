import {
  FAQ_ADD,
  FAQ_DELETE,
  FAQ_DETAIL_FETCH,
  FAQ_ERROR,
  FAQ_FETCHING,
  FAQ_LIST_FETCH,
  FAQ_UPDATE,
  FAQ_CATEGORY_ADD,
  FAQ_CATEGORY_DELETE,
  FAQ_CATEGORY_ERROR,
  FAQ_CATEGORY_FETCHING,
  FAQ_CATEGORY_LIST_FETCH,
  FAQ_CATEGORY_UPDATE,
  FAQ_CATEGORY_DETAIL_FETCH,
  ADD_IMAGE_FAQ,
  DELETE_IMAGE_FAQ,
} from "../constants";

const initialState = {
  list: [],
  detail: {
    delete: false,
  },
  isFetch: false,
  isSubmit: false,
  success: null,
  error: null,
  category: {
    list: [],
    detail: {},
    isFetch: false,
    isSubmit: false,
    success: null,
    error: null,
    delete: false,
  },
  addedImages: {},
  isSubmittingImage: false,
  deletedImages: [],
  isDeletingImage: false,
};

const onFetching = (lastState, payload) => {
  if (payload.type === "fetch")
    return {
      ...lastState,
      isFetch: payload.status,
      success: null,
      error: null,
    };
  else if (payload.type === "submit")
    return { ...lastState, isSubmit: payload.status };
  else if (payload.type === "submit_image")
    return { ...lastState, isSubmittingImage: payload.status };
  else if (payload.type === "delete_image")
    return { ...lastState, isDeletingImage: payload.status };

  return { ...lastState, isFetch: false, isSubmit: false };
};

const onFetchingCategory = (lastState, payload) => {
  if (payload.type === "fetch")
    return {
      ...lastState,
      category: {
        ...lastState.category,
        isFetch: payload.status,
        success: null,
        error: null,
      },
    };
  else if (payload.type === "submit")
    return {
      ...lastState,
      category: { ...lastState.category, isSubmit: payload.status },
    };
  else if (payload.type === "detail")
    return {
      ...lastState,
      category: { ...lastState.category, detail: { ...lastState.category.detail, isFetch: payload.status } },
    };
  return {
    ...lastState,
    category: { ...lastState.category, isFetch: false, isSubmit: false },
  };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FAQ_LIST_FETCH: {
      const { data, status_code, message } = action.payload;
      return {
        ...state,
        list: { ...data, message, status_code },
        detail: {},
        error: null,
      };
    }
    case FAQ_DETAIL_FETCH: {
      const { data, status_code, message } = action.payload;
      return {
        ...state,
        detail: {
          ...state.detail,
          ...data,
          message,
          delete: false,
          status_code
        },
        error: null,
      };
    }
    case FAQ_ADD: {
      const { data, status_code, message } = action.payload;
      return {
        ...state,
        detail: { ...state.detail, ...data, message, status_code },
        success: message,
        error: null,
      };
    }
    case FAQ_UPDATE: {
      const { data, status_code, message } = action.payload;
      return {
        ...state,
        detail: { ...state.detail, ...data, message, status_code },
        success: message,
        error: null,
      };
    }
    case FAQ_DELETE: {
      const { data, status_code, message } = action.payload;
      return {
        ...state,
        detail: { ...state.detail, ...data, message, delete: true, status_code },
        success: message,
        error: null,
      };
    }
    case FAQ_ERROR:
      return { ...state, ...action.payload, success: null };

    case FAQ_CATEGORY_LIST_FETCH: {
      const { data, status_code, message } = action.payload;
      return {
        ...state,
        category: {
          ...state.category,
          list: { ...data, message, status_code },
          detail: {},
          error: null,
        },
      };
    }
    case FAQ_CATEGORY_DETAIL_FETCH: {
      const { data, status_code, message } = action.payload;
      return {
        ...state,
        category: {
          ...state.category,
          detail: {
            ...state.category.detail,
            ...data,
            status_code
          },
          message,
          error: null,
        },
      };
    }

    case FAQ_CATEGORY_ADD: {
      const { data, status_code, message } = action.payload;
      return {
        ...state,
        category: {
          ...state.category,
          detail: { ...state.detail, ...data, message, status_code },
          success: message,
          error: null,
        },
      };
    }

    case FAQ_CATEGORY_UPDATE: {
      const { data, status_code, message } = action.payload;
      return {
        ...state,
        category: {
          ...state.category,
          detail: { ...state.detail, ...data, message, status_code },
          success: message,
          error: null,
        },
      };
    }
    case FAQ_CATEGORY_DELETE: {
      const { data, status_code, message } = action.payload;
      return {
        ...state,
        category: {
          ...state.category,
          detail: { ...state.detail, ...data, message, status_code },
          success: message,
          delete: true,
          error: null,
        },
      };
    }
    case ADD_IMAGE_FAQ: {
      const { data, message, status_code } = action.payload;
      const addedImages = { ...state.addedImages };
      addedImages[action.payload.identifier] = {
        ...data,
        message,

      };
      return { ...state, addedImages };
    }
    case DELETE_IMAGE_FAQ: {
      const { deletedImages } = { ...state };
      deletedImages.push(action.payload.identifier);
      return { ...state, deletedImages };
    }
    case FAQ_CATEGORY_ERROR:
      return { ...state, category: { ...state.category, ...action.payload } };

    case FAQ_FETCHING:
      return onFetching(state, action.payload);
    case FAQ_CATEGORY_FETCHING:
      return onFetchingCategory(state, action.payload);
    default:
      return state;
  }
};

export default reducer;
