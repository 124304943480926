import React from 'react';
import {
  Form,
  FormGroup,
  Label,
  Input } from 'reactstrap';
import propTypes from 'prop-types';

import { perPageOptions } from '../../../../constants/kickavenue'

const SortForm = props =>{
  return(
    <Form inline className="form-filter">
        <FormGroup className="mb-2 mr-3">
            <Label className="mr-sm-3">
              Show
            </Label>
            <Input
                type="select"
                name="per_page"
                onChange={props.onInputChange}
                value={props.per_page}
            >
              {perPageOptions.map((option,i)=><option value={option.value} key={i}>{option.label}</option>)}
            </Input>
        </FormGroup>
        <FormGroup className="mb-2 mr-3">
            <Label className="mr-sm-3">
              Sort By
            </Label>
            <Input
              type="select"
              name="sort_by"
              onChange={props.onInputChange}
              value={props.sort_by}
            >
              <option value="">Default</option>
              <option value="createdAt_desc">Newest</option>
              <option value="updatedAt_desc">Last Updated</option>
              <option value="updatedAt_asc">Older</option>
              <option value="askingPrice_desc">Highest Price</option>
              <option value="askingPrice_asc">Lowest Price</option>
              <option value="approvedAt_desc">Latest Approved</option>
            </Input>
        </FormGroup>
    </Form>
  )
}
export default SortForm;
SortForm.propTypes= {
  per_page: propTypes.oneOfType([propTypes.number, propTypes.string]),
  sort_by: propTypes.string,
  onInputChange: propTypes.func.isRequired
}

SortForm.defaultProps= {
  per_page: 15,
  sort_by: ''
}