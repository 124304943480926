import React from 'react';
import {
  Row,
  Col,
  Card,
  CardText
} from 'reactstrap';
import { Link } from 'react-router-dom';
import propTypes from 'prop-types';

import { UserDetail, ShippingDetail } from '../../../../components/Users';

const TabUserDetail = props => {
  const { user, ...shipping } = props.shipping;
  return(
    <Card body className="tab-content--custom__card">
      <CardText>About their's detail and shipping detail. <Link to={`/users/${props.shipping.user.id}`}>See References.</Link></CardText>
      <Row>
        <Col xs={12} md={6}>
          <UserDetail displayRole isLoading={props.isLoading} user={{...user}}/>
        </Col>
        <Col xs={12} md={6}>
          <ShippingDetail isLoading={props.isLoading} shipping={{...shipping}}/>
        </Col>
      </Row>
    </Card>
  );
}

export default TabUserDetail;

TabUserDetail.propTypes={
  isLoading: propTypes.bool,
  shipping:propTypes.shape({
    id:propTypes.number.isRequired,
    full_name:propTypes.string,
    phone_number:propTypes.any,
    unit_apartment_number:propTypes.string,
    street_address:propTypes.string,
    country:propTypes.string,
    province:propTypes.string,
    district:propTypes.string,
    sub_district:propTypes.string,
    postal_code:propTypes.string,
    note:propTypes.string,
    updated_at:propTypes.string,
    user:propTypes.shape({
      id:propTypes.number.isRequired,
      email:propTypes.string,
      roles: propTypes.array
    }),
  })
}
TabUserDetail.defaultProps={
  isLoading: false,
  shipping:{
    id:0,
    full_name:'',
    unit_apartment_number:'',
    street_address:'',
    country:'',
    province:'',
    district:'',
    sub_district:'',
    postal_code:'',
    note:'',
    updated_at:'',
    user:{
      id:0,
      email:'',
      roles: []
    },
  }
}
