import React, { PureComponent as  Component } from 'react';
import {
  Col,
  Row,
  Form,
  FormGroup,
  Input,
  Label,
  // InputGroup,
  // InputGroupAddon,
  Button,
  Container} from 'reactstrap';
import propTypes from 'prop-types';
import SellerFilterForm from './SellerFilterForm';
import { paymentTypeOptions, shippingMethodList, createdSourceList } from '../../../constants/kickavenue';
import SelectRack from '../../../components/Form/Select/Async/SelectRack';
import SelectVoucher from '../../../components/Form/Select/Async/SelectVoucher';

const filterPaymentMethodOptions = [{name:'All',value:''},...paymentTypeOptions]
const filterShippingMethodOptions = [{name:'All',value:''},...shippingMethodList]
const filterCreatedSourceOptions = [{name:'All',value:''},...createdSourceList]
export default class SearchForm extends Component{
  render(){
    return(
      <Form inline onSubmit={this.props.onSubmit}>
        <Container style={{padding: 0}}>
          <Row className="mb-1">
            <Col xs={12} sm={6}>
              <FormGroup className="mb-3">
                <Input className="w-100" type="text" placeholder="Search invoice number, buyer email, display name or SKU..." name="keyword" value={this.props.keyword} onChange={this.props.onInputChange}/>
              </FormGroup>
            </Col>
          </Row>
          <Row className="mb-1">
            <Col xs={12} sm={6}>
              <FormGroup row className="mb-3">
                <Col xs={12} sm={12}>
                  <Label className="justify-content-start">
                    Seller
                  </Label>
                </Col>
                <Col xs={12} sm={12}>
                  <SellerFilterForm
                    seller={this.props.seller}
                    onSellerChange={this.props.onSellerChange}
                  />
                </Col>
              </FormGroup>
            </Col>
            <div className="w-100"></div>
            <Col xs={12} sm={3}>
              <FormGroup row className="mb-3">
                <Col xs={12} sm={4} md={3}>
                  <Label className="justify-content-start">
                    Payment Method
                  </Label>
                </Col>
                <Col xs={12} sm={8} md={9}>
                  <Input
                    className="w-100"
                    type="select"
                    name="paymentMethod"
                    onChange={this.props.onInputChange}
                    value={this.props.paymentMethod}
                  >
                    {filterPaymentMethodOptions.map((option,i)=><option value={option.value} key={i}>{option.name}</option>)}
                  </Input>
                </Col>
              </FormGroup>
            </Col>
            <Col xs={12} sm={3}>
              <FormGroup row className="mb-3">
                <Col xs={12} sm={4} md={3}>
                  <Label className="justify-content-start">
                    Rack
                  </Label>
                </Col>
                <Col xs={12} sm={8} md={9}>
                  <SelectRack
                    defaultOptions
                    id={this.props.filterRackId}
                    ref={this.props.filterRackRef}
                    value={this.props.rack}
                    paramsApi={{ limit: 50 }}
                    placeholder="Type and select a rack..."
                    noResultsText="Cannot find rack."
                    loadOptions={this.props.getRackOptions}
                    onSelectSearch={this.props.onSelectSearch}
                    onSelectChange={this.props.handleSelectRack}
                    onSelectOpen={this.props.handleOnSelectRackOpen}
                  />
                </Col>
              </FormGroup>
            </Col>
            <div className="w-100"></div>
            <Col xs={12} sm={3}>
              <FormGroup row className="mb-3">
                <Col xs={12} sm={4} md={3}>
                  <Label className="justify-content-start">
                    Voucher
                  </Label>
                </Col>
                <Col xs={12} sm={8} md={9}>
                  <SelectVoucher
                    create
                    defaultOptions
                    id={this.props.filterVoucherId}
                    ref={this.props.filterVoucherRef}
                    value={this.props.voucher}
                    paramsApi={{ sort_by: 'createdAt_desc' }}
                    placeholder="Select a voucher..."
                    noResultsText="Cannot find voucher."
                    onSelectChange={this.props.onSelectVoucher}
                  />
                </Col>
              </FormGroup>
            </Col>
            <Col xs={12} sm={3}>
              <FormGroup row className="mb-3">
                <Col xs={12} sm={4} md={3}>
                  <Label className="justify-content-start">
                    Shipping Method
                  </Label>
                </Col>
                <Col xs={12} sm={8} md={9}>
                  <Input
                    className="w-100"
                    type="select"
                    name="shippingMethod"
                    onChange={this.props.onInputChange}
                    value={this.props.shippingMethod}
                  >
                    {filterShippingMethodOptions.map((option,i)=><option value={option.value} key={i}>{option.name}</option>)}
                  </Input>
                </Col>
              </FormGroup>
            </Col>
            <div className="w-100"></div>
            <Col xs={12} sm={3}>
              <FormGroup row className="mb-3">
                <Col xs={12} sm={4} md={3}>
                  <Label className="justify-content-start">
                    Created Source
                  </Label>
                </Col>
                <Col xs={12} sm={8} md={9}>
                  <Input
                    className="w-100"
                    type="select"
                    name="createdSource"
                    onChange={this.props.onInputChange}
                    value={this.props.createdSource}
                  >
                    {filterCreatedSourceOptions.map((option,i)=><option value={option.value} key={i}>{option.name}</option>)}
                  </Input>
                </Col>
              </FormGroup>
            </Col>
            <div className="w-100"></div>
            <Col xs={12} sm={6} className="d-flex justify-content-end">
              <Button color="primary">Search</Button>
            </Col>
          </Row>
        </Container>
      </Form>
    )
  }
}

SearchForm.propTypes= {
  keyword: propTypes.string,
  seller: propTypes.object,
  voucher: propTypes.object,
  onSellerChange: propTypes.func.isRequired,
  onInputChange: propTypes.func,
  onSubmit: propTypes.func.isRequired,
  paymentMethod: propTypes.string,
  rack: propTypes.object,
  shippingMethod: propTypes.string,
  createdSource: propTypes.string,
}

SearchForm.defaultProps= {
  keyword: '',
  seller: null,
  voucher: null,
  paymentMethod: '',
  rack: null,
  shippingMethod: '',
  createdSource: '',
}