import {
  MARKETING_BUDGET_ROUTE_API,
  LIST_MARKETING_BUDGET,
  DETAIL_MARKETING_BUDGET,
  DELETE_MARKETING_BUDGET,
  UPDATE_MARKETING_BUDGET,
  CREATE_MARKETING_BUDGET,
  MARKETING_BUDGET_ERROR,
  MARKETING_BUDGET_REQUEST,
} from '../constants';
import {
  onRequest,
  onSuccessRequest,
  onFailedRequest
} from './helpers';
import { apiClient } from '../utils/Api';

const requestAction = (reqAction, payloads) => onRequest(MARKETING_BUDGET_REQUEST, reqAction, payloads)

const errorAction = (reqAction, payloads, id = null) => onFailedRequest(MARKETING_BUDGET_ERROR, reqAction, payloads, id)

export const getMarketingBudgetList = q => (
  dispatch => {
    dispatch(requestAction(LIST_MARKETING_BUDGET, true));
    apiClient()
    .get(MARKETING_BUDGET_ROUTE_API, q)
    .then(res => {
      dispatch(onSuccessRequest(LIST_MARKETING_BUDGET, res.data));
      dispatch(requestAction(LIST_MARKETING_BUDGET, false));
    })
    .catch(err => {
      dispatch(errorAction(LIST_MARKETING_BUDGET, err));
      dispatch(requestAction(LIST_MARKETING_BUDGET, false));
    });
  }
)

export const getMarketingBudgetById = id => (
  dispatch => {
    dispatch(requestAction(DETAIL_MARKETING_BUDGET, true));
    apiClient()
    .get(`${MARKETING_BUDGET_ROUTE_API}/${id}`)
    .then(res => {
      dispatch(onSuccessRequest(DETAIL_MARKETING_BUDGET, res.data));
      dispatch(requestAction(DETAIL_MARKETING_BUDGET, false));
    })
    .catch(err => {
      dispatch(errorAction(DETAIL_MARKETING_BUDGET, err, id));
      dispatch(requestAction(DETAIL_MARKETING_BUDGET, false));
    });
  }
)

export const deleteMarketingBudgetById = id => (
  dispatch => {
    dispatch(requestAction(DELETE_MARKETING_BUDGET, true));
    apiClient()
    .delete(`${MARKETING_BUDGET_ROUTE_API}/${id}`)
    .then(res => {
      dispatch(onSuccessRequest(DELETE_MARKETING_BUDGET, res.data));
      dispatch(requestAction(DELETE_MARKETING_BUDGET, false));
    })
    .catch(err => {
      dispatch(errorAction(DELETE_MARKETING_BUDGET, err, id));
      dispatch(requestAction(DELETE_MARKETING_BUDGET, false));
    });
  }
)

export const saveMarketingBudget = (payload) => {
  const { id, ...body } = payload;
  return id? updateMarketingBudget(id, body): createMarketingBudget(body)
}

const updateMarketingBudget = (id, payload) => (
  dispatch => {
    dispatch(requestAction(UPDATE_MARKETING_BUDGET, true));
    apiClient()
    .put(`${MARKETING_BUDGET_ROUTE_API}/${id}`, payload)
    .then(res => {
      dispatch(onSuccessRequest(UPDATE_MARKETING_BUDGET, res.data));
      dispatch(requestAction(UPDATE_MARKETING_BUDGET, false));
    })
    .catch(err => {
      dispatch(errorAction(UPDATE_MARKETING_BUDGET, err, id));
      dispatch(requestAction(UPDATE_MARKETING_BUDGET, false));
    });
  }
)

const createMarketingBudget = (payload) => (
  dispatch => {
    dispatch(requestAction(CREATE_MARKETING_BUDGET, true));
    apiClient()
    .post(MARKETING_BUDGET_ROUTE_API, payload)
    .then(res => {
      dispatch(onSuccessRequest(CREATE_MARKETING_BUDGET, res.data));
      dispatch(requestAction(CREATE_MARKETING_BUDGET, false));
    })
    .catch(err => {
      dispatch(errorAction(CREATE_MARKETING_BUDGET, err));
      dispatch(requestAction(CREATE_MARKETING_BUDGET, false));
    });
  }
)