import React from 'react';
import { Th } from '../../../components/Tbl';
import DropdownCategories from '../Filters/DropdownCategories';

const RowHeader = props => (
  <tr>
    <Th>No.</Th>
    <Th>
      <div style={{display: 'flex', flexDirection: 'row', alignItems: 'flex-end'}}>
        <p style={{marginRight: 10, marginBottom: 0}}>
          Consignment Detail
          {
            props.categoryName !== null?
            <span className="text-capitalize" style={{display: 'block', fontSize: '.5rem'}}>{props.categoryName}</span>
            : null
          }
        </p>
        <DropdownCategories
          value={props.categoryValue}
          onCategoryChanged={props.onCategoryChanged}
        />
      </div>
    </Th>
    <Th>Rack</Th>
    <Th>Consignment Price</Th>
    <Th>Updated</Th>
    <Th>Action</Th>
  </tr>
)

export default RowHeader;