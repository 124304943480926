import React from "react";
import propTypes from "prop-types";
import { Card, CardHeader, CardBody, Button, Alert, Badge } from "reactstrap";
import { connect } from "react-redux";
import { getAuditList } from "../../../../actions/auditAction";
import PaginationSummaries from "../../../../components/Paginations/PaginationSummaries";
import { Tbl, TblBody, TblHead } from "../../../../components/Tbl";
import Paginations from "../../../../components/Paginations/Paginations";
import { DEFAULT_SORT_BY } from "../../../../constants/settings";
import { generateNumber } from "../../../../utils";
import DateToHuman from "../../../../components/DateToHuman";

const dataHeader = [
    "No.",
    "Event",
    "Actor",
    "Before",
    "After",
    "Updated At",
    "Created At",
];

class SellRequestLogs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            _refreshCount: 0,
            _params: {
                id: props.id,
                type: props.type,
                sort_by: DEFAULT_SORT_BY,
                per_page: 10,
            },
        };
        this._handleGoto = this._handleGoto.bind(this);
        this.onLoopObjectValue = this.onLoopObjectValue.bind(this);
    }

    componentDidMount() {
        this._getList({ ...this.state._params });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.refresh !== this.props.refresh && this.props.refresh) {
            this._getList({ ...this.state._params });
        }
    }

    _getList(params) {
        this.props.getList({ params });
    }

    _handleGoto(page) {
        const _params = this.state._params;
        _params.page = page;
        this.setState({ _params }, () => this._getList(this.state._params));
    }

    getEventBadge(event) {
        switch (event) {
            case "created":
                return <Badge color="primary">CREATE</Badge>;
            case "attached":
                return <Badge color="primary">ATTACH</Badge>;
            case "updated":
                return <Badge color="success">UPDATE</Badge>;
            case "deleted":
                return <Badge color="danger">DELETE</Badge>;
            case "detached":
                return <Badge color="danger">DETACH</Badge>;
        }
    }

    onLoopObjectValue(object, indent = 0) {
        if (!object || Array.isArray(object)) {
            return <p>-</p>;
        }
        
        const render = (param) => {
            const isBool = typeof param === "boolean";

            if (isBool) {
                return `${param}`;
            }

            if(!param){
                return "null";
            }

            const isObj = typeof param === "object";

            if (isObj) {
                return this.onLoopObjectValue(param, 5);
            }

            return param;
        };

        const result = Object.entries(object).map(([key, value]) => {
            const renderText = render(value);

            return (
                <p
                    className="mb-1"
                    {...(indent && { style: { marginLeft: indent } })}
                >
                    <b>{key}</b>: {renderText}
                </p>
            );
        });

        return result;
    }

    _renderRowItem() {
        if (
            this.props.audit.isFetch === false &&
            this.props.audit.list.status_code === 200
        ) {
            const { data, current_page, per_page } = this.props.audit.list;
            return data.map((item, index) => {
                const rowNumber = generateNumber(per_page, current_page, index);
                return (
                    <tr key={index}>
                        <th scope="row">{rowNumber}</th>
                        <td>{this.getEventBadge(item.event)}</td>
                        <td>{item?.email}</td>
                        <td
                            style={{
                                maxWidth: "250px",
                                overflow: "auto",
                                whiteSpace: "normal",
                            }}
                        >
                            {this.onLoopObjectValue(item.old_values)}
                        </td>
                        <td
                            style={{
                                maxWidth: "250px",
                                overflow: "auto",
                                whiteSpace: "normal",
                            }}
                        >
                            {this.onLoopObjectValue(item.new_values)}
                        </td>
                        <td>
                            {
                                <DateToHuman
                                    value={item.updated_at}
                                    id={`tooltip_updated_at_${index}`}
                                />
                            }
                        </td>
                        <td>
                            {
                                <DateToHuman
                                    value={item.created_at}
                                    id={`tooltip_created_at_${index}`}
                                />
                            }
                        </td>
                    </tr>
                );
            });
        }

        return (
            <tr>
                <td colSpan={7} className="text-center">
                    <b>Loading...</b>
                </td>
            </tr>
        );
    }

    render() {
        const { audit } = this.props;
        const showPagination =
            (audit.list.status_code === 200 && audit.list.data.length) || null;
        return (
            <Card>
                <CardHeader>
                    <i className="fa fa-align-justify"></i>
                    Change Log
                </CardHeader>
                <CardBody>
                    <div className="table-responsive">
                        <PaginationSummaries
                            from={audit.list.from}
                            to={audit.list.to}
                            total={audit.list.total}
                        />
                        <Tbl>
                            <TblHead dataHeader={dataHeader} />
                            <TblBody
                                isLoad={audit.list.isFetch}
                                hasRow={
                                    audit.list.status_code === 200 &&
                                    audit.list.data.length
                                }
                                columnCount={dataHeader.length}
                            >
                                {this._renderRowItem()}
                            </TblBody>
                        </Tbl>
                    </div>
                    {showPagination && (
                        <Paginations
                            size="sm"
                            lastPage={audit.list.last_page}
                            perPage={audit.list.per_page}
                            total={audit.list.total}
                            maxPage={8}
                            path={audit.list.path}
                            currentPage={audit.list.current_page}
                            prevPageUrl={audit.list.prev_page_url}
                            nextPageUrl={audit.list.next_page_url}
                            handleGotoCallback={this._handleGoto}
                        />
                    )}
                </CardBody>
            </Card>
        );
    }
}
const mapStateToProps = ({ audit, auth: { email } }) => ({
    audit: audit,
    guardData: { email },
});

const mapDispatchToProps = (dispatch) => ({
    getList: (queries) => dispatch(getAuditList(queries)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SellRequestLogs);

SellRequestLogs.propTypes = {
    id: propTypes.string,
    type: propTypes.string,
    refresh: propTypes.bool,
};
