import { FormGroup } from 'reactstrap';
import styled from 'styled-components';
const FormGroupRadio = styled(FormGroup)`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  & > .switch ~ .form-control-label,
  & > .switch ~ .switch-label-span {
    flex: 1
  }
  & > div.validation-error-message{
    flex: 1 1 100%;
  }
`

export default FormGroupRadio;