import React from 'react';
import styled from 'styled-components';

const TitleDiv = styled.div`
  flex: 1;
`
const ParagraphOneDiv = styled.p`
  font-size: 18px;
  margin-bottom: 0;
`
const ParagraphTwoDiv = styled.p`
  font-size: 10px;
  margin-bottom: 0;
`

const TitleHeader = props =>(
  <TitleDiv>
    <ParagraphOneDiv>
      {props.invoiceNumber}
    </ParagraphOneDiv>
    <ParagraphTwoDiv>
      {props.email} / {props.createdAt}
    </ParagraphTwoDiv>
  </TitleDiv>
)
export default TitleHeader;