import React, {PureComponent} from 'react';
import {Button} from 'reactstrap';
import PropTypes from 'prop-types';
export default class ButtonLoading extends PureComponent{
    render(){
        const { isLoading, disabled, loadingMessage, children, ...props } = this.props;
        return(
            <Button 
            disabled={(isLoading||disabled)}
            {...props}
            >
                {
                    isLoading?
                    loadingMessage
                    :
                    children
                }
            </Button>
        )
    }
}

ButtonLoading.propTypes={
    isLoading:PropTypes.bool,
    active:PropTypes.bool,
    color:PropTypes.string,
    size:PropTypes.string,
    disabled:PropTypes.bool,
    onClick:PropTypes.func,
    loadingMessage:PropTypes.string
}

ButtonLoading.defaultProps = {
    color: 'primary',
    size: 'md',
    loadingMessage:'Loading...'
};
  