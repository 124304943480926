import React from "react";
import { Link } from "react-router-dom";
import { Card, CardHeader, CardBody, Badge } from "reactstrap";
import classNames from "classnames";
import Loading from "react-loading-animation";
import propTypes from "prop-types";
import { _getValue } from "../../utils/form";
import {
  userSellDefaultConditions,
  userSellApparelConditions,
  userSellHandbagConditions,
  userSellLifestyleConditions,
  userSellSneakerConditions
} from "../../utils/userSell";
import { TextRupiah } from "../Text";
import { BadgeFlexContainer, BadgeMedium } from "../../styled/Badge";
import {
  FieldRow,
  FieldColumn,
  FieldLabel,
  FieldValue
} from "../../styled/Fields";
const getListingConditions = (listingCategory, listingDefects) => {
  const {
    missing_accessories,
    yellowing,
    display_item,
    sneakers_defect,
    defects
  } = listingDefects;
  let listingConditionList = [];
  const myConditions = [
    {
      name: "missing_accessories",
      value: missing_accessories === null ? false : missing_accessories
    },
    {
      name: "yellowing",
      value: yellowing === null ? false : yellowing
    },
    {
      name: "display_item",
      value: display_item === null ? false : display_item
    },
    {
      name: "sneakers_defect",
      value: sneakers_defect === null ? false : sneakers_defect
    }
  ];
  const defaultListingConditions = userSellDefaultConditions.map(item => {
    const getValue = myConditions.find(i => i.name === item.name);
    return {
      ...item,
      value: getValue ? getValue.value : false
    };
  });
  switch (listingCategory) {
    case "HANDBAGS": {
      listingConditionList = userSellHandbagConditions.map(item => {
        const getValue = defects.find(i => i.name === item.name.toUpperCase());
        return {
          ...item,
          value: getValue ? getValue.value : false
        };
      });
      const onlyTrueDefect = defaultListingConditions.filter(
        ({ value }) => value === true
      );
      if (onlyTrueDefect.length)
        listingConditionList.splice(0, 0, ...onlyTrueDefect);
      break;
    }
    case "APPARELS": {
      listingConditionList = userSellApparelConditions.map(item => {
        const getValue = defects.find(i => i.name === item.name.toUpperCase());
        return {
          ...item,
          value: getValue ? getValue.value : false
        };
      });
      const onlyTrueDefect = defaultListingConditions.filter(
        ({ value }) => value === true
      );
      if (onlyTrueDefect.length)
        listingConditionList.splice(0, 0, ...onlyTrueDefect);
      break;
    }
    case "LIFESTYLES": {
      listingConditionList = userSellLifestyleConditions.map(item => {
        const getValue = defects.find(i => i.name === item.name.toUpperCase());
        return {
          ...item,
          value: getValue ? getValue.value : false
        };
      });
      const onlyTrueDefect = defaultListingConditions.filter(
        ({ value }) => value === true
      );
      if (onlyTrueDefect.length)
        listingConditionList.splice(0, 0, ...onlyTrueDefect);
      break;
    }
    default:
      listingConditionList = userSellSneakerConditions.map(item => {
        const getValue = defects.find(i => i.name === item.name.toUpperCase());
        return {
          ...item,
          value: getValue ? getValue.value : false
        };
      });
      const onlyTrueDefect = defaultListingConditions.filter(
        ({ value }) => value === true
      );
      if (onlyTrueDefect.length)
        listingConditionList.splice(0, 0, ...onlyTrueDefect);
      break;
  }
  return listingConditionList;
};
const SellDetail = props => {
  const {
    listingCategory,
    missing_accessories,
    yellowing,
    display_item,
    sneakers_defect,
    defects
  } = props;
  const listingConditionList = getListingConditions(listingCategory, {
    missing_accessories,
    yellowing,
    display_item,
    sneakers_defect,
    defects
  });
  return (
    <Card>
      <CardHeader>
        {props.renderHeaderText ? props.renderHeaderText() : props.headerText}
      </CardHeader>
      <CardBody>
        {props.isLoading ? (
          <Loading />
        ) : (
          <div>
            <FieldRow>
              <FieldColumn className="col-xs-12 col-md-6">
                <FieldLabel>
                  Sell ID{" "}
                  <Link
                    to={`/sell_request/${props.id}`}
                    title="Go to Sell & Consignment Request Details..."
                  >
                    See more...
                  </Link>
                </FieldLabel>
                <FieldValue>
                  <span>{props.id}</span>
                </FieldValue>
              </FieldColumn>
            </FieldRow>
            <FieldRow>
              <FieldColumn className="col-12">
                <FieldLabel>Selling By</FieldLabel>
                <FieldValue>
                  <span>{props.user.email}</span>
                </FieldValue>
              </FieldColumn>
              <FieldColumn className="col-xs-12 col-md-6">
                <FieldLabel>Asking Price</FieldLabel>
                <FieldValue>
                  <TextRupiah prefix="Rp. " value={props.asking_price} />
                </FieldValue>
              </FieldColumn>
              <FieldColumn className="col-xs-12 col-md-6">
                <FieldLabel>Currency</FieldLabel>
                <FieldValue>
                  <span>{props.currency}</span>
                </FieldValue>
              </FieldColumn>
              <FieldColumn className="col-xs-12 col-md-6">
                <FieldLabel>Box Condition</FieldLabel>
                <FieldValue>
                  <span>{_getValue(props.box_condition)}</span>
                </FieldValue>
              </FieldColumn>
              <FieldColumn className="col-xs-12 col-md-6">
                <FieldLabel>Sneaker Condition</FieldLabel>
                <FieldValue>
                  <span>{_getValue(props.sneakers_condition)}</span>
                </FieldValue>
              </FieldColumn>
              <FieldColumn className="col-12">
                <BadgeFlexContainer>
                  <BadgeMedium>
                    <Badge color={props.pre_order ? "success" : "default"}>
                      <i
                        className={classNames("fa", {
                          "fa-times": !props.pre_order,
                          "fa-check": props.pre_order
                        })}
                      ></i>
                      &nbsp; PRE-ORDER
                    </Badge>
                  </BadgeMedium>
                  <BadgeMedium>
                    <Badge color={props.pre_verified ? "success" : "default"}>
                      <i
                        className={classNames("fa", {
                          "fa-times": !props.pre_verified,
                          "fa-check": props.pre_verified
                        })}
                      ></i>
                      &nbsp; PRE-VERIFIED
                    </Badge>
                  </BadgeMedium>
                  {listingConditionList.filter(item => !item.inclusions).map((item, i) => (
                    <BadgeMedium key={i}>
                      <Badge
                        className="text-uppercase"
                        color={item.value ? "success" : "default"}
                      >
                        <i
                          className={classNames("mr-1 fa", {
                            "fa-times": !item.value,
                            "fa-check": item.value
                          })}
                        ></i>
                        {item.en}
                      </Badge>
                    </BadgeMedium>
                  ))}
                </BadgeFlexContainer>
              </FieldColumn>
              <FieldColumn className="col-12">
                <FieldLabel>Inclusions</FieldLabel>
                <BadgeFlexContainer>
                  {listingConditionList.filter(item => item.inclusions).length?
                    listingConditionList.filter(item => item.inclusions).map((item, i) => (
                      <BadgeMedium key={i}>
                        <Badge
                          className="text-uppercase"
                          color={item.value ? "success" : "default"}
                        >
                          <i
                            className={classNames("mr-1 fa", {
                              "fa-times": !item.value,
                              "fa-check": item.value
                            })}
                          ></i>
                          {item.en}
                        </Badge>
                      </BadgeMedium>
                    ))
                    :'-'
                  }
                </BadgeFlexContainer>
              </FieldColumn>
              <FieldColumn className="col-12">
                <FieldLabel>Note</FieldLabel>
                <FieldValue>
                  <span>{_getValue(props.note)}</span>
                </FieldValue>
              </FieldColumn>
              <FieldColumn className="col-xs-12 col-md-6">
                <FieldLabel>Status</FieldLabel>
                <FieldValue>
                  <span>{props.status}</span>
                </FieldValue>
              </FieldColumn>
              <FieldColumn className="col-xs-12 col-md-6">
                <FieldLabel>Last Updated</FieldLabel>
                <FieldValue>
                  <span>{props.updated_at}</span>
                </FieldValue>
              </FieldColumn>
            </FieldRow>
          </div>
        )}
      </CardBody>
    </Card>
  );
};

export default SellDetail;

SellDetail.propTypes = {
  renderHeaderText: propTypes.func,
  headerText: propTypes.oneOfType([propTypes.string, propTypes.node]),
  isLoading: propTypes.bool,
  id: propTypes.number,
  user: propTypes.object,
  asking_price: propTypes.oneOfType([propTypes.string, propTypes.number]),
  status: propTypes.string,
  box_condition: propTypes.string,
  sneakers_condition: propTypes.string,
  pre_order: propTypes.oneOfType([propTypes.bool, propTypes.number]),
  pre_verified: propTypes.oneOfType([propTypes.bool, propTypes.number]),
  updated_at: propTypes.string,
  sneakers_defect: propTypes.oneOfType([propTypes.bool, propTypes.number]),
  missing_accessories: propTypes.oneOfType([propTypes.bool, propTypes.number]),
  yellowing: propTypes.oneOfType([propTypes.bool, propTypes.number]),
  display_item: propTypes.oneOfType([propTypes.bool, propTypes.number]),
  listingCategory: propTypes.string
};
SellDetail.defaultProps = {
  headerText: "Sell Details",
  isLoading: false,
  id: 0,
  user: {
    id: 0,
    email: ""
  },
  asking_price: 0,
  status: "draft",
  sneakers_defect: false,
  missing_accessories: false,
  yellowing: false,
  display_item: false,
  defects: [],
  listingCategory: "SNEAKERS"
};
