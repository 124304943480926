import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import {
  Button,
  Form,
  Input,
  InputGroup,
  Row,
  Col,
  FormGroup,
} from 'reactstrap';
import Radio from '../../../../components/Form/Radios/Radio';
import RadioText from '../../../../components/Form/Radios/RadioText';

const Div = styled.div`
  margin-bottom: .75rem
`;

const FilterPanel = props => (
  <Div>
    <Form onSubmit={props.onHandleOnSubmit}>
      <Row>
        <Col xs={12} md={6}>
          <FormGroup>
            <InputGroup>
              <Input
                placeholder="Search email seller, rack..."
                value={props.keyword}
                name="keyword"
                onChange={props.onHandleTextChange}
              />
              <Button color="primary">Search</Button>
            </InputGroup>
          </FormGroup>
        </Col>
        <Col xs={12}>
          <FormGroup>
            <Radio
              value={true}
              checked={props.allSize===true}
              onChange={props.onHandleAllSizeCheckedChange}
              name="allSize"
              dataOnText="Yes"
              dataOffText="No"
            />
            <RadioText text="Show All Size."/>
          </FormGroup>
        </Col>
      </Row>
    </Form>
  </Div>
)

export default FilterPanel;

FilterPanel.defaultProps = {
  allSize: false,
  keyword: ''
}

FilterPanel.propTypes = {
  allSize: propTypes.bool,
  keyword: propTypes.string,
  onHandleOnSubmit: propTypes.func,
  onHandleTextChange: propTypes.func,
  onHandleAllSizeCheckedChange: propTypes.func,
}