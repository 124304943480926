import React from 'react';
import { connect } from 'react-redux';
import {
  Modal,
  ModalHeader,
  ModalBody,
	Button,
	Label,
  Input,
  Form,
	FormGroup,
  ModalFooter
} from 'reactstrap';
import classNames from 'classnames';
import { TextRupiah } from '../../../components/Text';
import { saveVariantCourier } from '../../../actions/productVariantAction';
import { isNumeric } from '../../../helpers/regex';
import { errorMessage } from '../../../utils/form';


export default class ModalShippingRate extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      id:null,
      price: '',
      error: null,
      isSubmit: false,
      location_group: null,
    }
  }

  componentDidUpdate(prevProps) {
    if(prevProps.isOpen !== this.props.isOpen && this.props.isOpen){
      const { data: { price, id, location_group } } = this.props;
      this.setState({
        id,
        error: null,
        isSubmit: false,
        location_group,
        price: price? Number(price): ''
      })
    }
  }

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  _validateForm = async() => {
    let errors = {};
    const { price } = this.state;
    if(!isNumeric(price)){
      errors = {
        ...errors,
        price: ['The shipping rate field must be a number.']
      }
    }
    let p = null;
    if(Object.keys(errors).length){
      p = { errors: { ...errors }}
    }
    await this.setState({
      error: p
    })
  }

  _renderErrorMessage = name => {
    const { error } = this.state;
    return errorMessage(name, error)
  }

  onSubmit = async (e) => {
    console.log()
    e.preventDefault();
    await this._validateForm();
    const { error, price, location_group, id } = this.state;
    if(error) return;
    const payloads = {
      id,
      price: price? Number(price): null,
      location_group: location_group
    }
    this.props.saveVariantCourier(this.props.id, payloads)
    this.props.toggle();
  }

  render(){
    return(
      <Modal size="sm" className="modal-confirmation" isOpen={this.props.isOpen} toggle={this.props.toggle}>
        <Form onSubmit={this.onSubmit}>
          <ModalHeader toggle={this.props.toggle} className="modal-header--left">
            Edit
          </ModalHeader>
          <ModalBody>
            {this.props.data?(
              <p>
                <b>{this.props.data.name}</b><br/>
                Shipping Rate: {this.props.data.price? <TextRupiah prefix={"Rp. "} tag="span" value={this.props.data.price}/>: '-'}.
              </p>
            ):null}
            <FormGroup className={classNames({'has-danger has-feedback': this.state.error !== null})}>
              <Label>Shipping Rate</Label>
              <Input
                type="text"
                placeholder="Shipping Rate..."
                name="price"
                value={this.state.price}
                onChange={this.handleChange}
              />
              { this._renderErrorMessage('price') }
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.props.toggle}>Cancel</Button>
            <Button color="primary" type="submit">Save</Button>
          </ModalFooter>
        </Form>
      </Modal>
    )
  }
}
