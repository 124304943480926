import React, { PureComponent as Component } from 'react';
import propTypes from 'prop-types';

import Order from '../../../components/Order/Details';

export default class OrderDetail extends Component {
  render() {
    return (
      <Order className="tab-content--custom__card"
        {...this.props}
      />
    )
  }
}

OrderDetail.propTypes={
  isLoading: propTypes.bool,
  order: propTypes.object,
  payment: propTypes.object,
  seller: propTypes.object,
  shipping: propTypes.object,
  product: propTypes.object
}
OrderDetail.defaultProps = {
  isLoading: false
}