import React, { Component } from 'react';
import { CardBody, Collapse } from 'reactstrap';
import propTypes from 'prop-types';

import SearchForm from './SearchForm';
import SortForm from './SortForm';

export default class Filters extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rack: props.rack,
      seller: props.seller,
      voucher: props.voucher,
      keyword: props.keyword,
      perPage: props.per_page,
      sortBy: props.sort_by,
      seller: props.seller,
      // type: props.type,
      paymentMethod: props.payment_method,
      shippingMethod: props.shipping_method,
      createdSource: props.created_source,
    }
    this._handleOnChangeInput = this._handleOnChangeInput.bind(this);
    this._handleApplyFilterButtonClick = this._handleApplyFilterButtonClick.bind(this);
    this._handleOnChangeInputAndApply = this._handleOnChangeInputAndApply.bind(this);
    this._handleSearchButtonClick = this._handleApplyFilterButtonClick.bind(this);
    this._handleOnSellerChange = this._handleOnSellerChange.bind(this);
    this._handleSelectRack = this._handleSelectRack.bind(this);
    this._handleSelectVoucher = this._handleSelectVoucher.bind(this);
  }

  _handleSelectRack(option){
    this.setState({ rack: option });
  }

  _handleSelectVoucher(option){
    this.setState({voucher: option});
  }

  _handleOnSellerChange(seller){
    this.setState({
      seller
    })
  }
  _handleOnChangeInput(e){
    const lastState = {...this.state};
    lastState[e.target.name] = e.target.value;
    this.setState({...lastState});
  }

  _handleOnChangeInputAndApply(e){
    const lastState = {...this.state};
    lastState[e.target.name] = e.target.value;
    this.setState({...lastState}, ()=> this._handleSubmitFilter());
  }

  _handleApplyFilterButtonClick(e){
    e.preventDefault();
    this._handleSubmitFilter();
  }

  _handleSubmitFilter(){
    const {
      keyword,
      seller,
      voucher,
      perPage,
      sortBy,
      // type,
      paymentMethod,
      shippingMethod,
      rack,
      createdSource,
    } = this.state;
    this.props.onApplyFilter({
      rack: rack? rack.name: null,
      keyword,
      // type,
      payment_method: paymentMethod,
      shipping_method: shippingMethod,
      // seller_id: seller && seller.id,
      seller: seller,
      voucher: voucher,
      per_page: perPage,
      sort_by: sortBy,
      created_source: createdSource,
    });
  }

  componentDidUpdate(prevProps) {
    if(!prevProps.isReset && prevProps.isReset !== this.props.isReset){
      const {
        keyword,
        seller,
        voucher,
        payment_method,
        rack,
        per_page,
        sort_by,
        shipping_method,
        created_source,
      } = this.props;
      this.setState({
        keyword,
        seller,
        voucher,
        rack,
        shippingMethod: shipping_method,
        createdSource: created_source,
        paymentMethod: payment_method,
        perPage: per_page,
        sortBy: sort_by
      })
      this.props.onResetFilterCallback();
    }
  }

  render(){
    return(
      <Collapse isOpen={this.props.isOpen}>
        <CardBody className="filter-container__card-body">
          <SearchForm
            filterRackRef="selectRack"
            filterRackId="_formSelectRack"
            filterVoucherRef="selectVoucher"
            filterVoucherId="_formSelectVoucher"
            rack={this.state.rack}
            keyword = {this.state.keyword}
            seller={this.state.seller}
            paymentMethod={this.state.paymentMethod}
            shippingMethod={this.state.shippingMethod}
            createdSource={this.state.createdSource}
            voucher={this.state.voucher}
            onSellerChange={this._handleOnSellerChange}
            onInputChange = {this._handleOnChangeInput}
            onSubmit = {this._handleSearchButtonClick}
            getRackOptions={this._getRackOptions}
            onSelectSearch={(input)=>this.setState({_recentSearchRack: input})}
            handleSelectRack={this._handleSelectRack}
            onSelectVoucher={this._handleSelectVoucher}
          />
          <hr/>
          <SortForm
            perPage={this.state.perPage}
            sortBy={this.state.sortBy}
            onInputChange={this._handleOnChangeInputAndApply}
          />
        </CardBody>
      </Collapse>
    )
  }
}

Filters.propTypes= {
  type: propTypes.string,
  keyword: propTypes.string,
  per_page: propTypes.oneOfType([propTypes.number, propTypes.string]),
  seller: propTypes.object,
  sort_by: propTypes.string,
  isOpen: propTypes.bool.isRequired,
  onApplyFilter: propTypes.func,
  onResetFilterCallback: propTypes.func.isRequired,
  isReset: propTypes.bool
}

Filters.defaultProps= {
  isReset: false,
  seller: null,
  voucher: null,
  payment_method: '',
  shipping_method: '',
  keyword: '',
  per_page: 15,
  sort_by: '',
  created_source: '',
}