import {
    AUTH_TOKEN_KEY,
    ROLE_ROUTE_API,
    ROLE_FETCHING,
    ROLE_DETAIL_FETCH,
    ROLE_LIST_FETCH,
	ROLE_ERROR,
	ROLE_ADD,
	ROLE_UPDATE,
	ROLE_DELETE,
	ROLE_CLEAR_FORM
} from '../constants';
import api from '../utils/Api';
import { manipulateErrors, checkAuthorized } from '../utils';
import { logout } from './authAction';
const onSuccess=(res,type)=>(
	{
		type:type,
		payload:res		
	}
)
const onError=(err)=>{    
	if(!checkAuthorized(manipulateErrors(err)))
        return logout();
    return {
        type:ROLE_ERROR,
        payload:manipulateErrors(err)
    }
}
const isFetch=(payload)=>(
    {
        type:ROLE_FETCHING,
        payload
    }
)

export const getListRole=(query)=>{
	return dispatch=>(
		dispatch(isFetch({type:'fetch',status:true})),
		api({
			Authorization:'Bearer '+localStorage.getItem(AUTH_TOKEN_KEY)
		}).get(ROLE_ROUTE_API,query)
		.then(res => {
			dispatch(onSuccess(res.data,ROLE_LIST_FETCH));
			dispatch(isFetch({type:'fetch',status:false}));
		})
		.catch(err => {
			dispatch(onError(err));
			dispatch(isFetch({type:'fetch',status:false}));
		})		
	)
}

export const getDetailRole=(id)=>{
	return dispatch=>(
		dispatch(isFetch({type:'fetch',status:true})),
		api({
			Authorization:'Bearer '+localStorage.getItem(AUTH_TOKEN_KEY)
		}).get(`${ROLE_ROUTE_API}/${id}`)
		.then(res => {
			dispatch(onSuccess(res.data,ROLE_DETAIL_FETCH));
			dispatch(isFetch({type:'fetch',status:false}));
		})
		.catch(err => {
			dispatch(onError(err));
			dispatch(isFetch({type:'fetch',status:false}));
		})		
	)
}

const createRole=(payload)=>{
	return dispatch => (
		dispatch(isFetch({type:'submit',status:true})),
        api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).post(`${ROLE_ROUTE_API}`,payload)
		.then(res=>{
			dispatch(onSuccess(res.data,ROLE_ADD))
			dispatch(isFetch({type:'submit',status:false}))
		})
		.catch(err => {
			dispatch(onError(err));
			dispatch(isFetch({type:'submit',status:false}));
		})
	)
}

const updateRole=(id, payload)=>{
	return dispatch => (
		dispatch(isFetch({type:'submit',status:true})),
		api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).put(`${ROLE_ROUTE_API}/${id}`,payload)
		.then(res=>{
			dispatch(onSuccess(res.data,ROLE_UPDATE))
			dispatch(isFetch({type:'submit',status:false}))
		})
		.catch(err => {
			dispatch(onError(err));
			dispatch(isFetch({type:'submit',status:false}));
		})
	)
}

export const saveRole = (payload) =>{
	const {id,name, display_name, description}=payload;
	return dispatch =>{
		if(payload.id===undefined)
			dispatch(createRole({name, display_name, description}));
		else 
			dispatch(updateRole(id,{name, display_name, description}));

	}
}

export const deleteRole = (id) =>{
	return dispatch => (
		dispatch(isFetch({type:'submit',status:true})),
		api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).delete(`${ROLE_ROUTE_API}/${id}`)
		.then(res=>{
			dispatch(onSuccess(res.data,ROLE_DELETE))
			dispatch(isFetch({type:'submit',status:false}))
		})
		.catch(err => {
			dispatch(onError(err));
			dispatch(isFetch({type:'submit',status:false}));
		})
	)
}
export const clearFormRole =()=>dispatch=>dispatch({type:ROLE_CLEAR_FORM})
