import React, {Component} from 'react';
import {
  Row,
  Col,
  Card,
  CardBody,
  CardText,
} from 'reactstrap';
import { connect } from 'react-redux';

import { doGetListStock, doChangeStockKeyword, reportStock } from '../../../actions/productStockAction';
import { CardHeaderWithToolbars,Title } from '../../../components/Card/CardHeaderWithToolbars';
import CardWrapper from '../../../components/Card/CardWrapper';
import Toolbars from './filterPanel/Toolbars';
import FiltersPanel from './filterPanel';
import ExportsPanel from './Exports';
import FilterContainer from '../../../components/Container/FilterContainer/FilterContainer';
import { Th } from '../../../components/Tbl';
import DropdownProductCategories from '../../../components/Dropdowns/DropdownProductCategories';
import { generateNumber } from '../../../utils';
import RowItem from './RowItem';
import privateView from '../../../components/hocs/privateView';
import StockList from './StockList';
import { DEFAULT_SORT_BY, DEFAULT_PER_PAGE } from '../../../constants/settings';
import Analytics from "../../../services/Analytics";

const defaultParams = {
  sort_by : DEFAULT_SORT_BY,
  // sort_by : 'qty_desc',
  per_page : DEFAULT_PER_PAGE
}

const sortOptions = [{
    value:"" , label:"Default"
  },
  {
    value:"updatedAt_desc" , label:"Last Updated"
  },
  {
    value:"createdAt_desc" , label:"Newest"
  },
  {
    value:"createdAt_asc" , label:"Older"
  },
  {
    value:"displayName_asc" , label:"A-Z"
  },
  {
    value:"displayName_desc" , label:"Z-A"
  },
  {
    value:"active_desc" , label:"Active"
  },
  {
    value:"active_asc" , label:"Inactive"
  },
  {
    value:"qty_desc" , label:"Qty"
}];

class StockProductVariants extends Component{
  constructor(props){
    super(props);
    this.state={
      _filterOpen: props.stockKeyword.keyword.length>0,
      _exportOpen: false,
      _params: {
        ...defaultParams,
        keyword: props.stockKeyword.keyword,
        keyword_for: props.stockKeyword.keywordFor
      },
      _selectedCategory: null
    }
    this._handleGoto = this._handleGoto.bind(this);
    this._handleToggleFilterPanel = this._handleToggleFilterPanel.bind(this);
    this._handleRefresh = this._handleRefresh.bind(this);
    this._handleResetFilter = this._handleResetFilter.bind(this);
    this._handleParamsChange = this._handleParamsChange.bind(this);
    this._handleParamsChangeAndSubmit = this._handleParamsChangeAndSubmit.bind(this);
    this._handleSearch = this._handleSearch.bind(this);
    this._handleRenderRowItem = this._handleRenderRowItem.bind(this);
    this._handleSearchForClick = this._handleSearchForClick.bind(this);
    this._handleCategoryChanged = this._handleCategoryChanged.bind(this);
    this._handleToggleExportPanel = this._handleToggleExportPanel.bind(this);
    this._handleExport = this._handleExport.bind(this);
  }

  componentDidMount(){
    const { email } = this.props.injectedProps;
    this._getList(this.state._params)
    Analytics.recordPageView('stock_product_list', email, {
      url: window.location.href,
      location: this.props.location,
      match: this.props.match
    });
  }

  _getList(rawParams = {}){
    let params = {};
    Object.keys(rawParams)
    .filter(key => rawParams[key]!='')
    .forEach(key=> params[key] = rawParams[key]);
    this.props.getList({params});
  }

    /**
	 * Handle callback when user click pagination items
	 * @author pewe
	 * @param {integer} page
	 */
  _handleGoto(page){
    const _params=this.state._params;
    _params.page=page;
    this.setState({_params},()=>this._getList(this.state._params));
  }

  _handleToggleFilterPanel(e){
    e.preventDefault();
    this._toggleFilterPanel();
  }

  _toggleFilterPanel(){
    const exportState = this.state._exportOpen?{_exportOpen:!this.state._exportOpen}:{};
    this.setState({
      ...exportState,
      _filterOpen:!this.state._filterOpen
    })
  }

  _handleRefresh(e){
    e.preventDefault();
    this._refreshList();
  }

  _refreshList(){
    // Exclude page...
    this.setState({
      _params:{ ...this.state._params, page: 1 }
    },()=>this._getList(this.state._params));
  }

  _handleExport(payload){
    this.props.reportStock(payload);
  }

  _handleResetFilter(e){
    e.preventDefault();
    const exportState = this.state._exportOpen?{_exportOpen:!this.state._exportOpen}:{};
    const newState = this.state._filterOpen?{_filterOpen:!this.state._filterOpen}:{};
    this.setState({
      ...newState,
      ...exportState,
      _params:{ ...defaultParams },
      _selectedCategory: null
    },()=>this._getList(this.state._params));
    this.props.changeKeyword("", null);
  }

  _handleParamsChange(e) {
    const { _params } = this.state;
    _params[e.target.name] = e.target.value;
    this.setState({_params});
  }

  _handleParamsChangeAndSubmit(e){
    // Exclude page...
    const { _params } = this.state;
    _params[e.target.name] = e.target.value;
    _params.page = 1;
    this.setState({ _params },()=>{
      this._getList(this.state._params);
    });
  }

  _handleSearch(e){
    e.preventDefault();
    this.setState({
      _params: {...this.state._params, page: 1}
    },()=>this._getList(this.state._params));
    this.props.changeKeyword(this.state._params.keyword, this.state._params.keyword_for);
  }

  _handleSearchForClick(keywordFor){
    this.setState({
      _params: { ...this.state._params, page: 1, keyword_for: keywordFor}
    }, ()=>this._getList(this.state._params))
    this.props.changeKeyword(this.state._params.keyword, keywordFor);
  }

  _toggleExportPanel(){
    const filterState = this.state._filterOpen?{_filterOpen:!this.state._filterOpen}:{};
    this.setState({
      ...filterState,
      _exportOpen:!this.state._exportOpen});
  }

  _handleToggleExportPanel(e){
    e.preventDefault();
    this._toggleExportPanel();
  }


  _handleRenderRowItem(data, { perPage, currentPage }){
    return data.map((item,index)=>{
      const number = generateNumber(perPage,currentPage,index);
      return (
        <RowItem
          key={number}
          rowId={number}
          id={item.id}
          brand={item.product.brand}
          category={item.product.category?item.product.category.name: '-'}
          displayName={item.display_name}
          editorsChoice={item.editors_choice}
          SKU={item.SKU}
          colour={item.colour}
          quantity={item.qty}
          active={item.active}
          updatedAt={item.updated_at}
        />
      )
    })
  }

  async _handleCategoryChanged(category){
    await this.setState({
      _selectedCategory: category === ''? null: category.label,
      _params: {
        ...this.state._params,
        category_id: category === ''? category: category.value,
        page: 1,
      }
    });
    const { _params } = this.state;
    this._getList(_params);
  }

  render(){
    return (
      <CardWrapper>
        <Row>
          <Col xs={12}>
            <Card>
              <CardHeaderWithToolbars>
                <Title>
                  <i className="fa fa-align-justify"></i>
                  <span>Your Product List</span>
                </Title>
                <Toolbars
                  isOpen={this.state._filterOpen}
                  isOpenExport={this.state._exportOpen}
                  onToggleFilter={this._handleToggleFilterPanel}
                  onRefreshList={this._handleRefresh}
                  onResetFilter={this._handleResetFilter}
                  onToggleExport={this._handleToggleExportPanel}
                />
              </CardHeaderWithToolbars>
              <FilterContainer>
                <FiltersPanel
                  {...this.state._params}
                  sortOptions={sortOptions}
                  onSearchOptionToggle={()=> this.setState({_isSearchOptionOpen: !this.state._isSearchOptionOpen})}
                  onHandleChange={this._handleParamsChange}
                  onHandleSearch={this._handleSearch}
                  onHandleSearchForClick={this._handleSearchForClick}
                  onHandleChangeAndSubmit={this._handleParamsChangeAndSubmit}
                  isOpen={this.state._filterOpen}
                  isSearchOptionOpen={this.state._isSearchOptionOpen}
                />
              </FilterContainer>
              <FilterContainer>
                <ExportsPanel
                  onHandleExport={this._handleExport}
                  isOpenExport={this.state._exportOpen}
                  data={this.props.stockReport}
                />
              </FilterContainer>
              <CardBody>
                <CardText>
                  Your products list in this sections.
                </CardText>
                <StockList
                  isLoading={this.props.stockList.isFetch}
                  statusCode={this.props.stockList.status_code}
                  data={this.props.stockList.data}
                  from={this.props.stockList.from}
                  to={this.props.stockList.to}
                  currentPage={this.props.stockList.current_page}
                  lastPage={this.props.stockList.last_page}
                  total={this.props.stockList.total}
                  perPage={this.props.stockList.per_page}
                  columnCount={6}
                  onRenderRowItem={this._handleRenderRowItem}
                  onRenderHeader={()=>(
                    <tr>
                      <Th>No.</Th>
                      <Th>Brands</Th>
                      <Th>
                        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'flex-end'}}>
                          <p style={{marginRight: 10, marginBottom: 0}}>
                            Product Title
                            {
                              this.state._selectedCategory !== null?
                              <span style={{display: 'block', fontSize: '.5rem'}}>{this.state._selectedCategory}</span>
                              : null
                            }
                          </p>
                          <DropdownProductCategories
                            value={this.state._params.category_id}
                            onCategoryChanged={this._handleCategoryChanged}
                          />
                        </div>
                      </Th>
                      <Th>Qty </Th>
                      <Th>Updated</Th>
                      <Th>Action</Th>
                    </tr>
                  )}
                  onGotoPageList={this._handleGoto}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </CardWrapper>
    )
  }
}

const mapStateToProps=({ productStock, auth:{isFetch,isLogged,roles, id, email} })=>{
	return {
    stockKeyword: { keyword: productStock.keyword, keywordFor: productStock.keywordFor },
    stockList: productStock.stockList,
    stockReport: productStock.reportStock,
    guardData: {
      isFetch,
      isLogged,
      roles,
      id,
      email
    }
	}
}

const mapDispatchToProps=(dispatch)=>{
	return{
    getList:(query) => dispatch(doGetListStock(query)),
    changeKeyword: (keyword, keywordFor) => dispatch(doChangeStockKeyword(keyword, keywordFor)),
    reportStock:(payload) => dispatch(reportStock(payload)),
	}
}
const enhance = connect(mapStateToProps,mapDispatchToProps);
export default enhance(privateView(StockProductVariants,['supervisor','sale_supervisor','inventory_admin','cashier']));