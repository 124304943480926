import React from 'react';
import { connect } from 'react-redux';
import propTypes from 'prop-types';
import { DropdownMenu } from 'reactstrap';

import { SimpleLineIcon } from '../Icons';
import { getOptionList } from '../../actions/categoriesAction';
import { ButtonDropdown, DropdownItem } from './ButtonDropdown';
class DropdownProductCategories extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      dropdownOpen: false
    }
    this._handleToggle = this._handleToggle.bind(this);
  }

  componentDidMount(){
    if(!this.props.dataCategories.length)
      this.props.getCategoriesOptions();
  }

  componentDidUpdate(prevProps, prevState){
    if(!prevState.dropdownOpen && this.state.dropdownOpen && !this.props.dataCategories.length){
      this.props.getCategoriesOptions();
    }
  }

  _handleToggle(){
    this.setState({ dropdownOpen: !this.state.dropdownOpen })
  }

  _active(value){
    return this.props.value?(this.props.value === value): (value === '');
  }

  render(){
    return(
      <ButtonDropdown
        isOpen={this.state.dropdownOpen}
        toggle={this._handleToggle}
        renderChildren={() => {
          return(
            <DropdownMenu style={{ backgroundColor: "#fff" }}>
              <DropdownItem header>Show Only: </DropdownItem>
              {
                this.props.dataCategories.map((item, idx) => (
                  <DropdownItem
                    onClick={() => this.props.onCategoryChanged(item)}
                    active={this._active(item.value)}
                    key={idx}
                  >
                    { this._active(item.value)? <SimpleLineIcon iconType="check"/>: null }{item.label}
                  </DropdownItem>
                ))
              }
            </DropdownMenu>
          )
        }}
      />
    )
  }
}
const mapStateToProps= ({ masterCategories: { options } }) => ({
  dataCategories: options,
});
const mapDispatchToProps = (dispatch) => ({
  getCategoriesOptions: () => dispatch(getOptionList())
});
export default connect(mapStateToProps,mapDispatchToProps)(DropdownProductCategories);
DropdownProductCategories.defaultProps = {
  value: null,
  dataCategories: []
}

DropdownProductCategories.propTypes = {
  dataCategories: propTypes.arrayOf(propTypes.shape({
    value: propTypes.number.isRequired,
    label: propTypes.string
  })),
  value: propTypes.oneOfType([propTypes.number, propTypes.string]),
  onCategoryChanged: propTypes.func,
  getCategoriesOptions: propTypes.func
}