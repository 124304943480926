import React, { Component } from 'react';
import { CardBody, Collapse } from 'reactstrap';
import propTypes from 'prop-types';

import SearchForm from '../Filters/SearchForm';
import SortForm from '../Filters/SortForm';
import FilterForm from '../Filters/FilterForm';

export default class FilterPanel extends Component {
  constructor(props){
    super(props);
    this.state = {
      rack: props.rack,
      voucher: props.voucher,
      keyword: props.keyword,
      perPage: props.per_page,
      sortBy: props.sort_by,
      paymentMethod: props.payment_method,
      status: props.status,
      isHideFilter: props.isHideFilter
    }
    this._handleOnChangeInput = this._handleOnChangeInput.bind(this);
    this._handleApplyFilterButtonClick = this._handleApplyFilterButtonClick.bind(this);
    this._handleOnChangeInputAndApply = this._handleOnChangeInputAndApply.bind(this);
    this._handleSearchButtonClick = this._handleApplyFilterButtonClick.bind(this);
    this._handleSelectRack = this._handleSelectRack.bind(this);
    this._handleSelectVoucher = this._handleSelectVoucher.bind(this);
  }

  _handleSelectRack(option){
    this.setState({ rack: option });
  }

  _handleSelectVoucher(option){
    this.setState({voucher: option});
  }

  _handleOnChangeInput(e){
    const lastState = {...this.state};
    lastState[e.target.name] = e.target.value;
    this.setState({...lastState});
  }

  _handleOnChangeInputAndApply(e){
    const lastState = {...this.state};
    lastState[e.target.name] = e.target.value;
    this.setState({...lastState}, ()=> this._handleSubmitFilter());
  }

  _handleApplyFilterButtonClick(e){
    e.preventDefault();
    this._handleSubmitFilter();
  }

  _handleSubmitFilter(){
    const { keyword, perPage, sortBy, paymentMethod, status, rack, voucher } = this.state;
    this.props.onApplyFilter({
      rack: rack? rack.name: null,
      keyword,
      voucher,
      per_page: perPage,
      sort_by: sortBy,
      payment_method: paymentMethod,
      status
    });
  }

  componentDidUpdate(nextProps){
    if(!nextProps.isReset && this.props.isReset!==nextProps.isReset){
      const { keyword, per_page, sort_by, payment_method, status, rack, voucher } = this.props;
      this.setState({
        keyword,
        rack,
        voucher,
        perPage: per_page,
        sortBy: sort_by,
        status,
        paymentMethod: payment_method
      })
      this.props.onResetFilterCallback();
    }
  }
  render(){
    return(
      <Collapse isOpen={this.props.isOpen}>
        <CardBody className="filter-container__card-body">
          <SearchForm
            keyword = {this.state.keyword}
            onInputChange = {this._handleOnChangeInput}
            onSubmit = {this._handleSearchButtonClick}
          />
          {
            !this.state.isHideFilter ?
            <>
              <FilterForm
                filterRackRef="selectRack"
                filterRackId="_formSelectRack"
                filterVoucherRef="selectVoucher"
                filterVoucherId="_formSelectVoucher"
                rack={this.state.rack}
                voucher={this.state.voucher}
                paymentMethod = {this.state.paymentMethod}
                status = {this.state.status}
                onInputChange = {this._handleOnChangeInput}
                onSubmit = {this._handleSearchButtonClick}
                onSelectSearch={(input)=>this.setState({_recentSearchRack: input})}
                handleSelectRack={this._handleSelectRack}
                onSelectVoucher={this._handleSelectVoucher}
              />
              <hr/>
            </>
            :
            null
          }
          <SortForm
            perPage={this.state.perPage}
            sortBy={this.state.sortBy}
            onInputChange={this._handleOnChangeInputAndApply}
          />
        </CardBody>
      </Collapse>
    )
  }
}

FilterPanel.propTypes= {
  keyword: propTypes.string,
  per_page: propTypes.oneOfType([propTypes.number, propTypes.string]),
  sort_by: propTypes.string,
  payment_method: propTypes.string,
  status: propTypes.string,
  isOpen: propTypes.bool.isRequired,
  isReset: propTypes.bool,
  isHideFilter: propTypes.bool,
  onApplyFilter: propTypes.func,
  onResetFilterCallback: propTypes.func
}

FilterPanel.defaultProps= {
  keyword: '',
  per_page: 15,
  sort_by: '',
  payment_method: '',
  status: '',
  voucher: null,
  isHideFilter:false
}