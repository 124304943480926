import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Container } from 'reactstrap';
import FormCard from './TimerForm/FormCard';
import privateView from '../../components/hocs/privateView';
import Analytics from '../../services/Analytics';

class TimerCreate extends Component {
    componentDidMount() {
        const { email } = this.props.injectedProps;
        Analytics.recordPageView('timer_create', email, {
            url: window.location.href,
            location: this.props.location,
            match: this.props.match
        });
    }

    render() {
        return (
        <Container>
            <FormCard
             formTitle="Create New Timer"
             {...this.props}
             />
        </Container>
        )
    }
}

const mapStateToProps = ({auth: { isFetch, roles, isLogged, id, email }}) => ({
    guardData: { isFetch, roles, isLogged, id, email }
})

const enhance = connect(mapStateToProps);
export default enhance(privateView(TimerCreate))