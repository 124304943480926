import React from "react";
import propTypes from "prop-types";
import { Form, FormGroup, Button, Row, Col, Label } from "reactstrap";
import SelectArticleCategory from "../../../components/Form/Select/Async/SelectArticleCategory";

const FilterForm = (props) => {
  return (
    <Form className="form-filter" onSubmit={props.onSubmit}>
      <Row>
        <Col xs={12} md={6} style={{paddingRight:0}}>
          <p>Filter By :</p>
          <FormGroup row>
            <Col xs={12} sm={4} md={2}>
              <Label className="justify-content-start">Category</Label>
            </Col>
            <Col xs={12} sm={8} md={8}>
              <SelectArticleCategory
                id="selectArticleCategory"
                typedLength={0}
                defaultOptions
                paramsApi={{ sort_by: "createdAt_desc" }}
                placeholder="Select a category..."
                noResultsText="Cannot find article category."
                value={props.category}
                onSelectChange={(val) => {
                  props.onCategoryInputChange(val);
                }}
              />
            </Col>
            <Col xs={12} sm={8} md={2}>
              <Button color="primary">Apply</Button>
            </Col>
          </FormGroup>
        </Col>
      </Row>
    </Form>
  );
};
export default FilterForm;

FilterForm.propTypes = {
  onCategoryInputChange: propTypes.func.isRequired,
  onSubmit: propTypes.func.isRequired,
};

FilterForm.defaultProps = {
  category: "",
};
