import React, { PureComponent as Component } from 'react';
import {
  Card,
  CardText,
  Row,
  Col
} from 'reactstrap';
import propTypes from 'prop-types';
import Sneaker from './sneakerDetail/Sneaker';
import SneakerCondition from './sneakerDetail/SneakerCondition';
import ImageListCard from './sneakerDetail/ImageListCard';

export default class SneakerDetail extends Component{
  render(){
    return(
      <Card
        body
        className={this.props.className}
      >
        <CardText>Your product's details.</CardText>
        <Row>
          <Col xs={12} md={6}>
            <Sneaker
              userSell={this.props.userSell}
              headerText="Product General Info"
              isLoading={this.props.isLoading}
              productConsignment={this.props.productConsignment}
              consignment={this.props.consignment}
            />
          </Col>
          <Col xs={12} md={6}>
            <SneakerCondition
              consignment={this.props.consignment}
              userSell={this.props.userSell}
              headerText="Product Conditions"
              isLegit={this.props.isLegit}
              isLoading={this.props.isLoading}
              productConsignment={this.props.productConsignment}
              listingCategory={this.props.listingCategory}
            />
          </Col>
          <div className="w-100"></div>
          <Col xs={12}>
            <ImageListCard 
              title="User Sell Images"
              images={this.props.userSellImagesConsignment}
            />
          </Col>
          <Col xs={12}>
            <ImageListCard 
              title="Legit Images"
              images={this.props.legitCheckImagesConsignment}
            />
          </Col>
        </Row>
      </Card>
    )
  }
}
SneakerDetail.propTypes={
  isLoading: propTypes.bool,
  isLegit: propTypes.oneOfType([propTypes.bool, propTypes.number]),
  userSellId: propTypes.number.isRequired,
  product: propTypes.shape({
    sale_id: propTypes.number.isRequired,
    display_name: propTypes.string,
    quantity: propTypes.number,
    image_url: propTypes.string
  }),
  legitCheckImagesConsignment: propTypes.array
}
SneakerDetail.defaultProps = {
  isLoading: false,
  isLegit: false,
  product:{
    sale_id: 0,
    display_name: "",
    quantity: 0,
    image_url: ""
  },
  legitCheckImagesConsignment: []
}