import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import {
  Card,
  CardHeader,
  CardBody,
  CardText,
  Button,
  Input,
  ListGroup,
  ListGroupItem,
  ListGroupItemText,
  ListGroupItemHeading
} from 'reactstrap';
import { getPointShopItems, savePointShopItems } from '../../../actions/pointShopAction';
import { FontAwesomeIcon, SimpleLineIcon } from '../../../components/Icons';
import { ModalVoucher } from './Modals';
import ItemList from './ItemList';
import { Link } from 'react-router-dom';

const defaultParams = {
  per_page: 15,
  page: 1,
}

const VoucherItem = styled(ListGroupItem)`
  display: flex;
  align-items: center;
  .control{
    padding: .5rem;
  }
`

const Title = styled(ListGroupItemHeading)`
  margin-bottom: 0px;
`

const Desc = styled(ListGroupItemText)`
  margin-bottom: 0px;
`

class VoucherList extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      _params:{
        ...defaultParams
      },
      paginations: {
        from: 0,
        to: 0,
        total: 0,
        currentPage: 0,
        lastPage: 0,
        perPage: 0
      },
      list: [],
      listSelected: [],
      error: null,
      message: '',
      statusCode: null,
      isBrowseProductsOpen: false
    }
  }

  componentDidMount(){
    const { _params } = this.state;
    this._getDataList(_params)
  }

  _sanitizeParams = rawParams => {
    return {
      params: Object.keys(rawParams)
      .filter(key => rawParams[key]!='')
      .reduce((obj, current)=> ({...obj, [current]: rawParams[current]}), [])
    }
  }

  _getDataList = (rawParams = {}) => {
    const { id } = this.props;
    this.props.getPointShopItems(
      id,
      this._sanitizeParams(rawParams)
    )
  }

  _resetDataList = () => {
    this.setState({
      _params: {
        ...defaultParams
      }
    }, () => this._getDataList(this.state._params))
  }

  _handleGoto = page => {
    const _params = this.state._params;
    _params.page = page;
    this.setState({ _params },
      ()=> this._getDataList(this.state._params)
    );
  }

  _handleBrowseProductToggle = () => {
    this.setState({
      isBrowseProductsOpen: !this.state.isBrowseProductsOpen
    })
  }

  componentDidUpdate(prevProps){
    if(!this.props.dataPointShopItems.isFetch && prevProps.dataPointShopItems.isFetch !== this.props.dataPointShopItems.isFetch){
      if(!this.props.dataPointShopItems.error&&this.props.dataPointShopItems.data){
        const { data, ...paginations } = this.props.dataPointShopItems.data;
        this.setState({
          list: [...data],
          paginations: {
            ...this.state.paginations,
            from: paginations.from,
            to: paginations.to,
            total: paginations.total,
            currentPage: paginations.current_page,
            lastPage: paginations.last_page,
            perPage: paginations.per_page
          }
        })
      }
    }

    if(!this.props.dataPointShopItems.isSubmit && prevProps.dataPointShopItems.isSubmit !== this.props.dataPointShopItems.isSubmit){
      const { error, message, status_code } = this.props.dataPointShopItems;
      if(error){
        this.setState({
          error: {...error},
        })
      }
      else{
        this.setState({
          error: null,
          message,
          statusCode: status_code,
          listSelected: []
        })
        if(status_code === 201)
          this._resetDataList();
      }
    }
  }

  _handleDetachAllItems = e => {
    e.preventDefault();
    const { listSelected } = this.state;
    const { id } = this.props;
    const payloads = {
      vouchers : listSelected.length !== 0?
        listSelected.map(i => i.id): ['*']
    }
    this.props.savePointShopItems('detach', id, payloads);
  }

  async _handdleDetachItem(e,item){
    e.preventDefault();
    const { id } = this.props;
    const payload = {vouchers:[item]};
    this.props.savePointShopItems('detach', id, payload);
  }

  _handleAttachItems = items => {
    const { id } = this.props;
    const payloads = {vouchers:items};
    this.props.savePointShopItems('attach', id, payloads);
  }

  _handleOpenBrowseModal = e => {
    e.preventDefault();
    this._handleBrowseProductToggle()
  }

  render(){
    return(
      <div>
        <ModalVoucher
          size="md"
          voucherId={this.props.id}
          isOpen={this.state.isBrowseProductsOpen}
          toggle={this._handleBrowseProductToggle}
          onSubmit={this._handleAttachItems}
        />
        <Card>
          <CardHeader>
            <FontAwesomeIcon iconType="align-justify"/> Vouchers
          </CardHeader>
          <CardBody>
            <CardText>Add / remove vouchers. Dont forget to upload the background image of your voucher in Voucher Page.</CardText>
            <div className="mb-1" style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
              <div>
                <a
                  href="#"
                  className="link text-primary mr-1"
                  onClick={this._handleOpenBrowseModal}
                >
                  <SimpleLineIcon iconType="plus" className="mr-1"/>
                  Browse
                </a>
                <a
                  href="#"
                  className="link text-danger mr-2"
                  onClick={this._handleDetachAllItems}
                >
                  <SimpleLineIcon iconType="trash" className="mr-1"/>
                  <span>
                    Remove {this.state.listSelected.length?`(${this.state.listSelected.length})`: 'All'}
                  </span>
                </a>
              </div>
              <div>
                {
                  this.state.listSelected.length > 0?(
                    <Button
                      outline
                      color="danger"
                      size="sm"
                      type="button"
                      className="mr-1"
                      style={{fontSize: '.5rem'}}
                      onClick={() => this.setState({listSelected: []})}
                    >
                      Clear Selected
                    </Button>
                  ): null
                }
                <Button
                  outline
                  title="Refresh list"
                  color="primary"
                  style={{fontSize: '.5rem'}}
                  size="sm"
                  type="button"
                  onClick={() => this._handleGoto(1)}
                >
                  <FontAwesomeIcon iconType="refresh"/>
                </Button>
              </div>
            </div>
            <ItemList
              isLoading={this.props.dataPointShopItems.isFetch||this.props.dataPointShopItems.isSubmit}
              paginations={this.state.paginations}
              list={this.state.list}
              renderItems={(item, index) => {
                const { listSelected } = this.state;
                return(
                  <ListGroup key={index} style={{maxHeight: 250, overflow: 'auto'}}>
                  {
                    <VoucherItem key={index}>
                      <div className="control">
                        <Input
                          type="checkbox"
                          value="true"
                          checked={listSelected.length>0&&listSelected.some(list => list.id === item.id)}
                          onChange={({target: { checked: value }}) => {
                            const { listSelected } = this.state;
                            this.setState({
                              listSelected: value? [...listSelected, {id : item.id}]: [...listSelected.filter(list => list.id !== item.id)]
                            })
                          }}
                        />
                      </div>
                      <div>
                        <Title>
                          (
                            <a
                              href="#"
                              className="link text-danger"
                              onClick={(e)=> this._handdleDetachItem(e,item.id)}
                            >
                              <SimpleLineIcon iconType="trash"/>
                            </a>
                          ) &nbsp; {item.code.toUpperCase()} {item.name}
                          <Link style={{fontSize: '.75rem'}} target="_blank" to={`/vouchers/${item.id}`}>
                            <FontAwesomeIcon iconType="external-link" className="mr-1"/>
                            More info
                          </Link>
                        </Title>
                        <Desc>
                          <small><b>points</b>: <i>{parseInt(item.pivot.points)}</i> <b>added</b>: <i>{item.pivot.created_at},</i> <b>active</b>: {item.active? <i className="fa fa-check"></i>:<i className="fa fa-times"></i> } </small>
                        </Desc>
                      </div>
                    </VoucherItem>
                  }
                </ListGroup>
              )}}
              onGotoPage={this._handleGoto}
              onSubmit={this._handleAttachItems}
            />
          </CardBody>
        </Card>
      </div>
    )
  }
}


const mapStateToProps= ({ pointShop: { pointShopItems }}) => ({
  dataPointShopItems: pointShopItems
});
const mapDispatchToProps = (dispatch) => ({
  getPointShopItems: (id, query) => dispatch(getPointShopItems(id, 'vouchers', query)),
  savePointShopItems: (operation, id, payload) => dispatch(savePointShopItems(operation, id, 'vouchers', payload)),
});
export default connect(mapStateToProps,mapDispatchToProps)(VoucherList);