import React from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input, 
  Button,
  Row,
  Col
} from 'reactstrap';
import moment from 'moment-timezone';
import classnames from 'classnames';
import InputDatetime from '../../../components/Form/InputDatetime/InputDatetime';

import { hasError, errorMessage, textChange, textValue, dateValue, dateChange } from '../../../utils/form';

export class ModalRejectOrder extends React.Component{
  constructor(props){
    super(props);
    this.state={
      id:null,
      seller_points:1,
      notes:'',
      isSetPenalty: false,
      seller_penalty: 50000,
      error:null
    }
    this.handleCheckedChange = this.handleCheckedChange.bind(this);
  }

  handleChange(e){
    this.setState(textChange(e,{...this.state}));
  }

  handleCheckedChange(e) {
    const newState = { ...this.state };
    newState[e.target.name] = e.target.checked;
    this.setState({ ...newState });
  }

  componentWillReceiveProps(nextProps){
    if(nextProps.isOpen===true&&nextProps.isOpen!==this.props.isOpen&&nextProps.sale!==null){
      this.setState({
        id:nextProps.sale.id,
        seller_penalty: this.props?.sale?.offer.penalty_amount || 50000,
        isSetPenalty: false,
        error:null,
        notes:'',
        seller_points:1});
    }
    if(nextProps.isSubmit!==this.props.isSubmit&&nextProps.isSubmit===false){
      this.setState({error:this.props.error});
    }
  }

  onSubmit(e){
    e.preventDefault();
    const { id, notes, seller_points, isSetPenalty, seller_penalty} = this.state;
    const payload = { notes, seller_points, with_penalty: isSetPenalty, penalty_amount: seller_penalty };
    this.props.onSubmit(id, payload);
  }

  render(){
    return (
      <Modal isOpen={this.props.isOpen} toggle={this.props.onCancel} className="modal-dialog modal-md">
        <ModalHeader toggle={this.props.onCancel}>Confirmation</ModalHeader>
        <ModalBody>
          { this.props.sale!==null?
            <p>
              <b>({this.props.sale.invoice_number})</b><br/>
              Reject order <i>{this.props.sale.sale_product.display_name}</i>.
            </p>
          :''
          }
          <p>
            You cannot undo this action, are you sure?
          </p>
          <Form>
            <FormGroup className={classnames({'has-danger has-feedback':hasError('seller_points',this.state.error)})}>
              <Label for="formRejectPoint">Decrease Point Seller?</Label>
              <Input
                type="number"
                id="formRejectPoint"
                name="seller_points"
                placeholder="Decrease Point Seller?"
                value={textValue('seller_points',this.state)}
                onChange={this.handleChange.bind(this)}
              />
              {errorMessage('seller_points',this.state.error)}
            </FormGroup>
            {this.props.sale?.seller_responded && (
              <>
                <FormGroup
                className={classnames({
                  "has-danger has-feedback": hasError(
                    "isSetPenalty",
                    this.state._error
                  ),
                })}
                >
                  <Label for="formSetPenaltyActive" className="mb-0">
                    Set Penalty Seller Credit&nbsp;
                  </Label>
                  <label className="switch switch-text switch-success-outline-alt">
                    <input
                      type="checkbox"
                      className="switch-input"
                      name="isSetPenalty"
                      id="formSetPenaltyActive"
                      value="true"
                      checked={textValue("isSetPenalty", this.state)}
                      onChange={this.handleCheckedChange}
                    />
                    <span
                      className="switch-label"
                      data-on="On"
                      data-off="Off"
                    ></span>
                    <span className="switch-handle"></span>
                  </label>
                  {errorMessage('isSetPenalty',this.state.error)}
                </FormGroup>
                {this.state.isSetPenalty && (
                  <FormGroup className={classnames({'has-danger has-feedback':hasError('seller_penalty',this.state.error)})}>
                    <Label for="formRejectPoint">Penalty Amount</Label>
                    <Input
                      type="number"
                      id="formRejectPoint"
                      name="seller_penalty"
                      placeholder="Rp 0"
                      value={textValue('seller_penalty',this.state)}
                      onChange={this.handleChange.bind(this)}
                    />
                    {errorMessage('seller_penalty',this.state.error)}
                  </FormGroup>
                )}
              </>
            )}
            <FormGroup className={classnames({'has-danger has-feedback':hasError('notes',this.state.error)})}>
              <Label for="formRejectNotes">Notes*</Label>
              <Input
                type="textarea"
                id="formRejectNotes"
                name="notes"
                placeholder="Put your notes here*"
                value={textValue('notes',this.state)}
                onChange={this.handleChange.bind(this)}
              />
              {errorMessage('notes',this.state.error)}
            </FormGroup>
          </Form>
          <span className="text-sm">Fields marked with * are required</span>                    
        </ModalBody>
        <ModalFooter>
          <Button color="primary" disabled={this.props.isSubmit} onClick={this.onSubmit.bind(this)}>{this.props.isSubmit?'Submitting...':'Accept'}</Button>{' '}
          <Button color="secondary" onClick={this.props.onCancel}>Cancel</Button>
        </ModalFooter>
      </Modal>
    )
  }
    
}
ModalRejectOrder.propTypes={
  sale:PropTypes.object,
  isSubmit:PropTypes.bool,
  error:PropTypes.object,
  isOpen:PropTypes.bool,
  onSubmit:PropTypes.func,
  onCancel:PropTypes.func
}
export class ModalAcceptOrderConfirmation extends React.Component{
  constructor(props){
    super(props);
    this.state={
      id:null,
      seller_responded:moment().format('YYYY-MM-DD HH:mm:00'),
      error:null
    }
    this.onSubmit=this.onSubmit.bind(this);
  }

  componentWillReceiveProps(nextProps){
    if(nextProps.isOpen===true&&nextProps.isOpen!==this.props.isOpen&&nextProps.sale!==null){
      const initalValue={
        status:'NEW',
        seller_responded:moment().format('YYYY-MM-DD HH:mm:00'),
        error:null
      }
      this.setState({id:nextProps.sale.id,...initalValue});
    }
    if(nextProps.isSubmit!==this.props.isSubmit&&nextProps.isSubmit===false){
      this.setState({error:this.props.error});
    }
  }

  onSubmit(e){
    e.preventDefault();
    const { id, seller_responded } = this.state;
    this.props.onSubmit(id, {seller_responded});
  }
  render(){
    return(
      <Modal isOpen={this.props.isOpen} toggle={this.props.onCancel} className="modal-dialog modal-md">
        <ModalHeader toggle={this.props.onCancel}>Confirmation</ModalHeader>
        <ModalBody>
          { this.props.sale!==null?
            <p>
              <b>({this.props.sale.invoice_number})</b><br/>
              Accept order <i>{this.props.sale.sale_product.display_name}</i>.
            </p>
          :''
          }
          <p>
            Are you sure confirm this order without seller confirmation?
          </p>                   
        </ModalBody>
        <ModalFooter>
          <Button color="primary" disabled={this.props.isSubmit} onClick={this.onSubmit}>{this.props.isSubmit?'Submitting...':'Accept'}</Button>{' '}
          <Button color="secondary" onClick={this.props.onCancel}>Cancel</Button>
        </ModalFooter>
      </Modal>
    )
  }
}
ModalAcceptOrderConfirmation.propTypes={
  sale:PropTypes.object,
  isSubmit:PropTypes.bool,
  error:PropTypes.object,
  isOpen:PropTypes.bool,
  onSubmit:PropTypes.func,
  onCancel:PropTypes.func
}

export class ModalAcceptOrder extends React.Component {
  constructor(props){
    super(props);
    moment.tz.setDefault("Asia/Jakarta");
    this.state={
      id:null,
      seller_courier:'',
      seller_courier_option:'',
      seller_courier_price:0,
      seller_price:0,
      offer_amount:0,
      seller_sent:moment().format('YYYY-MM-DD HH:mm:00'),
      received_at:moment().format('YYYY-MM-DD HH:mm:00'),
      seller_awb_number:'',
      received_by: '',
      notes:'',
      error:null
    }
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e){
    this.setState(textChange(e,{...this.state}));
  }

  handleDatePickerChange(date, name){
    this.setState(dateChange(date,name, {...this.state}));        
  }

  componentDidUpdate(prevProps){
    if(!prevProps.isOpen&&prevProps.isOpen!==this.props.isOpen&&this.props.sale!==null){
      const now=moment().format('YYYY-MM-DD HH:mm:00');
      const seller_price=parseFloat(this.props.sale.seller_price!==null?this.props.sale.seller_price:0);
      const initalValue={
        seller_courier:'',
        seller_courier_option:'',
        seller_courier_price:0,
        seller_price:0,
        offer_amount:0,
        seller_sent:now,
        received_at:now,
        seller_awb_number:'',
        received_by: this.props.receivedBy? this.props.receivedBy: '',
        notes:'',
        error:null
      }
      this.setState({
        id:this.props.sale.id,
        ...initalValue,
        seller_price:seller_price,
        offer_amount:parseFloat(this.props.sale.price),
        seller_sent:this.props.sale.seller_sent===null?now:moment(this.props.sale.seller_sent).format('YYYY-MM-DD HH:mm:ss'),
        seller_courier:this.props.sale.seller_courier,
        seller_courier_price:this.props.sale.seller_courier_price || 0,
        seller_awb_number:this.props.sale.seller_awb_number
      });
    }
    if(prevProps.isSubmit!==this.props.isSubmit&&!prevProps.isSubmit){
      this.setState({error:this.props.error});
    }
  }

  onSubmit(e){
    e.preventDefault();
    const {
      id,
      notes,
      seller_courier,
      seller_courier_option,
      seller_courier_price,
      seller_price,
      seller_sent,
      seller_awb_number,
      received_at,
      received_by
    } = this.state;
    const payload = {
      notes,
      seller_courier,
      seller_courier_option,
      seller_courier_price,
      seller_price,
      seller_sent,
      seller_awb_number,
      received_at
    }
    if(received_by && received_by !== ''){
      payload.received_by = received_by
    }
    this.props.onSubmit(id, payload);
  }

  render(){
    return (
      <Modal isOpen={this.props.isOpen} toggle={this.props.onCancel} className="modal-dialog modal-md">
        <ModalHeader toggle={this.props.onCancel}>Receive Sneakers Recap</ModalHeader>
        <ModalBody>
          { this.props.sale!==null?
            <p>
              <b>({this.props.sale.invoice_number})</b><br/>
              <i>{this.props.sale.sale_product.display_name}</i>
            </p>
          :''
          }
          <Form>
            <Row>
              <Col xs={12}>
                <FormGroup className={classnames({'has-danger has-feedback':hasError('received_by',this.state.error)})}>
                  <Label for="formAcceptReceivedBy">Receiver Name</Label>
                  <Input
                    type="text"
                    readOnly
                    id="formAcceptReceivedBy"
                    name="received_by"
                    placeholder="Receiver Name"
                    value={textValue('received_by',this.state)}
                    onChange={this.handleChange}
                  />
                  {errorMessage('received_by',this.state.error)}
                </FormGroup>
              </Col>
              <Col xs={12}>
                <FormGroup className={classnames({'has-danger has-feedback':hasError('seller_awb_number',this.state.error)})}>
                  <Label for="formAcceptAwbNumber">Awb Number</Label>
                  <Input
                    type="text"
                    id="formAcceptAwbNumber"
                    name="seller_awb_number"
                    placeholder="Nomor Resi Pengiriman dari Seller"
                    value={textValue('seller_awb_number',this.state)}
                    onChange={this.handleChange}
                  />
                  {errorMessage('seller_awb_number',this.state.error)}
                </FormGroup>
              </Col>
              <Col xs={12}>
                <FormGroup className={classnames({'has-danger has-feedback':hasError('seller_courier',this.state.error)})}>
                  <Label for="formAcceptCourier">Seller Courier</Label>
                  <Input
                    type="text"
                    id="formAcceptCourier"
                    name="seller_courier"
                    placeholder="Seller Courier example: JNE"
                    value={textValue('seller_courier',this.state)}
                    onChange={this.handleChange}
                  />
                  {errorMessage('seller_courier',this.state.error)}
                </FormGroup>
              </Col>
              <Col xs={12}>
                <FormGroup className={classnames({'has-danger has-feedback':hasError('seller_courier_option',this.state.error)})}>
                  <Label for="formAcceptCourierOption">Courier Option</Label>
                  <Input
                    type="text"
                    id="formAcceptCourierOption"
                    name="seller_courier_option"
                    placeholder="Seller Courier Option example: YES"
                    value={textValue('seller_courier_option',this.state)}
                    onChange={this.handleChange}
                  />
                  {errorMessage('seller_courier_option',this.state.error)}
                </FormGroup>
              </Col>
              <Col xs={6}>
                <FormGroup className={classnames({'has-danger has-feedback':hasError('seller_sent',this.state.error)})}>
                  <Label for="formAcceptSentDate">Seller Sent Date*</Label>
                  <InputDatetime 
                    id="formAcceptSentDate"
                    inputProps={{placeholder:'When seller sent?*'}}
                    value={dateValue('seller_sent',this.state)} 
                    onChange={(date)=>this.handleDatePickerChange(date,'seller_sent')}
                    timeFormat="HH:mm"
                  />
                  {errorMessage('seller_sent',this.state.error)}
                </FormGroup>
              </Col>
              <Col xs={6}>
                <FormGroup className={classnames({'has-danger has-feedback':hasError('received_at',this.state.error)})}>
                  <Label for="formAcceptReceivedAt">Received At*</Label>
                  <InputDatetime 
                    id="formAcceptReceivedAt"
                    inputProps={{placeholder:'When departed in kickavenue?*'}}
                    value={dateValue('received_at',this.state)} 
                    onChange={(date)=>this.handleDatePickerChange(date,'received_at')}
                    timeFormat="HH:mm"
                  />
                  {errorMessage('received_at',this.state.error)}
                </FormGroup>
              </Col>
              <Col xs={12}>
                <FormGroup className={classnames({'has-danger has-feedback':hasError('seller_courier_price',this.state.error)})}>
                  <Label for="formAcceptCourierPrice">Courier Price</Label>
                  <Input
                    type="text"
                    id="formAcceptCourierPrice"
                    name="seller_courier_price"
                    placeholder="Seller Courier Price*"
                    value={textValue('seller_courier_price',this.state)}
                    onChange={this.handleChange}
                  />
                  {errorMessage('seller_courier_price',this.state.error)}
                </FormGroup>
              </Col>
              <Col xs={12}>
                <FormGroup className={classnames({'has-danger has-feedback':hasError('seller_price',this.state.error)})}>
                  <Label for="formAcceptSellerPrice">Seller Price* (Actual Price: {this.state.offer_amount})</Label>
                  <Input
                    type="text"
                    id="formAcceptSellerPrice"
                    name="seller_price"
                    placeholder="Seller Price*"
                    value={textValue('seller_price',this.state)}
                    onChange={this.handleChange}
                  />
                  {errorMessage('seller_sent',this.state.error)}
                </FormGroup>
              </Col>
              <Col xs={6}>
                <FormGroup className={classnames({'has-danger has-feedback':hasError('notes',this.state.error)})}>
                  <Label for="formAcceptNotes">Notes</Label>
                  <Input
                    type="textarea"
                    id="formAcceptNotes"
                    name="notes"
                    placeholder="Put your notes here"
                    value={textValue('notes',this.state)}
                    onChange={this.handleChange}
                  />
                  {errorMessage('notes',this.state.error)}
                </FormGroup>
              </Col>
            </Row>
          </Form>
          <span className="text-sm">Fields marked with * are required</span>  
        </ModalBody>
        <ModalFooter>
          <Button color="primary" disabled={this.props.isSubmit} onClick={this.onSubmit.bind(this)}>{this.props.isSubmit?'Submitting...':'Accept'}</Button>{' '}
          <Button color="secondary" onClick={this.props.onCancel}>Cancel</Button>
        </ModalFooter>
      </Modal>
    )
  }
}
ModalAcceptOrder.propTypes={
  sale:PropTypes.object,
  isSubmit:PropTypes.bool,
  error:PropTypes.object,
  isOpen:PropTypes.bool,
  onSubmit:PropTypes.func,
  onCancel:PropTypes.func
}