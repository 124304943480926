import React from 'react';
import propTypes from 'prop-types';
import {
  Col,
  Row,
  Form,
  FormGroup,
  Label,
  Button,
} from 'reactstrap';
import SelectUserAsync from '../../../components/Form/Select/Async/SelectUser';
import SelectRack from '../../../components/Form/Select/Async/SelectRack';

class FilterForm extends React.Component{
  constructor(props){
    super(props);
    this._handleOnSelectUserChange = this._handleOnSelectUserChange.bind(this);
    this._handleOnSelectUserOpen = this._handleOnSelectUserOpen.bind(this);
  }
  _handleOnSelectUserChange(seller){
    const { _recentUserSearch } = this.state;
    this.setState({
      _recentUserSearch: seller && _recentUserSearch
    })
    this.props.onSellerChange(seller);
  }
  _handleOnSelectUserOpen(){
    if(this.props.seller){
      return this.state._recentUserSearch&&this.refs.selectSeller.loadSelectOptions(this.state._recentUserSearch);
    }
    return this.refs.selectSeller.loadSelectOptions('');
  }

  render(){
    return(
      <Form className="form-filter" onSubmit={this.props.onSubmit}>
        <Row>
          <Col xs={12}>
            <div className="d-flex" style={{flexDirection: 'column'}}>
              <div style={{padding: "0px 1rem"}}>
                <FormGroup row>
                  <Col xs={12} sm={6}>
                    <Label className="justify-content-start">
                      Seller
                    </Label>
                    <SelectUserAsync
                      defaultOptions
                      id="formSelectSeller"
                      ref="selectSeller"
                      value={this.props.seller}
                      paramsApi={{ roles: 'user,seller,unsuspended_seller,administrator', role_query: 'or', scope: 'all' }}
                      placeholder="Type and select a seller"
                      noResultsText="Cannot find seller."
                      onSelectSearch={(input)=>this.setState({_recentUserSearch: input})}
                      onSelectChange={this._handleOnSelectUserChange}
                      onSelectOpen={this._handleOnSelectUserOpen}
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col xs={12} sm={6}>
                    <Label className="justify-content-start">
                      Rack
                    </Label>
                    <SelectRack
                      isMulti
                      defaultOptions
                      id={this.props.filterRackId}
                      ref={this.props.filterRackRef}
                      value={this.props.rack}
                      paramsApi={{ limit: 50 }}
                      placeholder="Type and select a rack"
                      noResultsText="Cannot find rack."
                      loadOptions={this.props.getRackOptions}
                      onSelectSearch={this.props.onSelectSearch}
                      onSelectChange={this.props.handleSelectRack}
                      onSelectOpen={this.props.handleOnSelectRackOpen}
                    />
                  </Col>
                </FormGroup>
              </div>
              <FormGroup style={{alignSelf: 'flex-start', padding: "0px 1rem"}}>
                <Button color="primary">Apply Filter</Button>
              </FormGroup>
            </div>
          </Col>
        </Row>
      </Form>
    )
  }
}

export default FilterForm;
FilterForm.defaultProps = {
}

FilterForm.propTypes = {
  seller: propTypes.object,
  onSellerChange: propTypes.func.isRequired,
  onSubmit: propTypes.func.isRequired
}