import React, { Component } from "react"
import { connect } from "react-redux"
import "react-select/dist/react-select.css"
import InputDatetime from "../../../components/Form/InputDatetime/InputDatetime"

import {
  Row,
  Card,
  CardHeader,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  Alert,
  Button,
  ButtonGroup,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap"
import {
  textValue,
  dateChange,
  dateValue,
  checkChange,
} from "../../../utils/form"
import { Link } from "react-router-dom"
import privateView from "../../../components/hocs/privateView"
import { ValidationMessage } from "../../../components/Form/ValidationMessage"
import { settingTypeOptions } from "../../../constants/kickavenue"
import Select from "react-select"
import { createSetting, deleteSetting, updateSetting } from "../../../actions/settingAction"

class FormCard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      _form: {
        _id: this.props.edit ? this.props.match.params.id : null,
        name: "",
        value_type: "",
        value: null,
        active: false,
        binding: false
      },
      _formSubmit: false,
      _modalDelete: false,
      _errors: null,
      isDeleting: false,
    }

    this.handleCheckedChange = this.handleCheckedChange.bind(this)
    this.toggleModalDelete = this.toggleModalDelete.bind(this)
    this.onDelete = this.onDelete.bind(this)
  }

  componentDidUpdate(prevProps) {
    const { detail, isFetch, isSubmit, error } = this.props.setting

    if (this.props.edit && detail.status_code === 200) {
      if (prevProps.setting.isFetch && !isFetch && !this.state._form.binding) {
        const _form = { ...this.state._form }
        _form.binding = true
        this.setState({ _form }, () => {
          const _newForm = { ...this.state._form, ...detail }
          this.setState({ _form: _newForm })
        })
      }
    }

    if (this.state._formSubmit && prevProps.setting.isSubmit && !isSubmit) {
      if (this.state.isDeleting) {
        this.setState({ isDeleting: false })
        this.toggleModalDelete()
        this.props.history.replace("/setting")
        return
      }
      if (error === null) {
        if (!this.state._form._id && detail.id) {
          this.props.history.push(`/setting/${detail.id}`)
        }
        this.setState({
          _formSubmit: !this.state._formSubmit,
          _form: {
            ...this.state._form,
            name: detail.name,
            value_type: detail.value_type,
            value: detail.value,
            active: detail.active
          }
        })
      }
    }
  }

  renderLoading() {
    return (
      <div className="row">
        <div className="col-sm-12">
          <Alert color="info" className="text-center">
            Getting all data...
          </Alert>
        </div>
      </div>
    )
  }

  renderInfo() {
    let infoMessage = []
    if (this.props.setting.isSubmit)
      infoMessage.push(
        <Alert color="info" key="info">
          <strong>Heads up!</strong> Submitting...
        </Alert>
      )
    if (this.props.setting.success)
      infoMessage.push(
        <Alert color="success" key="success">
          <strong>Well done!</strong> {this.props.setting.success}
        </Alert>
      )
    if (this.props.setting.error !== null) {
      const { status_code, message } = this.props.setting.error
      switch (status_code) {
        case 422:
          infoMessage.push(
            <Alert color="danger" key="error">
              <strong>Oh Snap!</strong> Please fullfill your form.{" "}
            </Alert>
          )
          break
        case 404:
          infoMessage.push(
            <Alert color="danger" key="error">
              <strong>Oh Snap!</strong> Resource not found.{" "}
            </Alert>
          )
          break
        default:
          infoMessage.push(
            <Alert color="danger" key="error">
              <strong>Oh Snap!</strong> {message}
            </Alert>
          )
          break
      }
    }
    return (
      <div className="row">
        <div className="col-sm-12">{infoMessage}</div>
      </div>
    )
  }

  handleChange(e) {
    const newState = { ...this.state._form }
    newState[e.target.name] = e.target.value
    this.setState({ _form: newState })
  }

  handleCheckedChange(e) {
    const _form = checkChange(e, this.state._form)
    console.log(_form)
    this.setState({ _form: { ..._form } })
  }

  validateError(name) {
    if (this.hasError(name)) {
      let errorList = this.props.setting.error.errors
      return <ValidationMessage message={errorList[name][0]} />
    }
    return ""
  }

  handleDatePickerChange(value, name) {
    const _form = dateChange(value, name, this.state._form)
    this.setState({ ..._form })
  }

  hasError(name) {
    if ((this.props.setting.error && this.props.setting.error.errors) || this.state._errors) {
      let errorList = this.props.setting.error.errors || this.state._errors
      let errList = Object.keys(errorList).filter((key) => key == name)
      if (errList.length) return true
    }
    return false
  }

  toggleModalDelete() {
    const { _modalDelete } = this.state
    this.setState({ _modalDelete: !_modalDelete })
  }

  onSelectChange(val, attributeForm) {
    const { _form } = this.state
    _form[attributeForm] = val === null ? val : val.value
    if (val.value === 'bool') {
      _form['value'] = '1'
    } else {
      _form['value'] = ''
    }

    if (attributeForm == "parent" && val !== null && val.value)
      this.setState({ _form: { ..._form, value_type: null } })
    else this.setState({ _form })
  }

  parseJson(value) {
    try {
      return JSON.stringify(JSON.parse(value), null, 4)
    } catch (error) {
      return value
    }
  }

  onSubmit(e) {
    e.preventDefault()
    this.setState({ _formSubmit: true }, () => {
      const {
        _id,
        name,
        value_type,
        value,
        active,
      } = this.state._form

      let newValue = value
      if (value_type === 'json') {
        newValue = this.parseJson(value)
      }

      if (this.props.edit) {
        this.props.update(_id, {
          name,
          value_type,
          value: newValue,
          active,
        })
      } else {
        this.props.new({
          name,
          value_type,
          value: newValue,
          active,
        })
      }
    })
  }

  onDelete(e) {
    e.preventDefault()
    const { _id } = this.state._form
    if (this.props.edit && _id) {
      this.setState({ _formSubmit: true, isDeleting: true }, () => {
        this.props.delete(_id)
      })
    }
  }

  renderValueInput() {
    const { value_type } = this.state._form
    switch (value_type) {
      case 'numeric':
      case 'text':
        return (
          <FormGroup
            className={
              this.hasError("value") ? " has-danger has-feedback" : ""
            }
          >
            <Label for="value">Value</Label>
            <Input
              type="text"
              id="value"
              name="value"
              autoComplete="off"
              placeholder="Enter a Value"
              value={this.state._form.value}
              onChange={this.handleChange.bind(this)}
            />
            {this.validateError("value")}
          </FormGroup>
        )
      
      case 'date':
        return (
          <FormGroup
            className={
              this.hasError("value") ? " has-danger has-feedback" : ""
            }
          >
            <Label for="formDateValue">Value</Label>
            <InputDatetime
              inputProps={{
                placeholder: "Pick a Date",
                id: "formDateValue",
                autoComplete: "off",
              }}
              value={dateValue("value", this.state._form)}
              onChange={(date) =>
                this.handleDatePickerChange(date, "value")
              }
              timeFormat="HH:mm"
            />
            {this.validateError("value")}
          </FormGroup>
        )
      case 'bool':
        return (
          <FormGroup
              className="d-flex"
              style={{ flexDirection: "column", alignItems: "flex-start" }}>
            <Label className="mb-2">
              Set the value
            </Label>
            <div className="d-flex ml-2 mr-2">
              <FormGroup check className="mr-2">
                <Label check>
                  <Input
                    type="radio"
                    name="value"
                    value="1"
                    checked={this.state._form.value === '1'}
                    onChange={this.handleChange.bind(this)}
                  /> True
                </Label>
              </FormGroup>
              <FormGroup check className="mr-2">
                <Label check>
                  <Input
                    type="radio"
                    name="value"
                    value="0"
                    checked={this.state._form.value === '0'}
                    onChange={this.handleChange.bind(this)}
                  /> False
                </Label>
              </FormGroup>
            </div>
          </FormGroup>
        )
      case 'json':
        return (
          <FormGroup
            className={
              this.hasError("value") ? " has-danger has-feedback" : ""
            }
          >
            <Label for="value">Value</Label>
            <Input
              type="textarea"
              id="value"
              name="value"
              rows="10"
              autoComplete="off"
              placeholder="Enter a json value"
              value={this.state._form.value}
              onChange={this.handleChange.bind(this)}
            />
            {this.validateError("value")}
          </FormGroup>
        )

      default:
        return (
          <FormGroup
            className={
              this.hasError("value") ? " has-danger has-feedback" : ""
            }
          >
            <Label for="value">Value</Label>
            <Input
              type="text"
              id="value"
              name="value"
              autoComplete="off"
              placeholder="Enter a Value"
              value={this.state._form.value}
              onChange={this.handleChange.bind(this)}
            />
            {this.validateError("value")}
          </FormGroup>
        )
    }
  }

  render() {
    return (
      <Row>
        <Modal
          isOpen={this.state._modalDelete}
          toggle={this.toggleModalDelete}
          className="modal-dialog modal-sm"
        >
          <ModalHeader toggle={this.toggleModalDelete}>
            Confirmation
          </ModalHeader>
          <ModalBody>
            {this.state.isDeleting && this.props.setting.isSubmit ? (
              <div className="row">
                <div className="col-sm-12">
                  <Alert color="info" className="text-center">
                    Deleting...
                  </Alert>
                </div>
              </div>
            ) : (
              <span>Are you sure to delete this?</span>
            )}
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.onDelete}>
              Yes
            </Button>{" "}
            <Button color="secondary" onClick={this.toggleModalDelete}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>

        <div className="col-md-6 offset-md-3">
          <Card>
            <CardHeader>
              <i className="fa fa-align-justify"></i>{" "}
              <span className="mr-2">{this.props.formTitle}</span>
              {this.props.edit ? (
                <Link
                  to="/setting/create"
                  title="Create New Setting"
                  className="text-right"
                >
                  Create New
                </Link>
              ) : null}
            </CardHeader>
            <CardBody>
              {this.renderInfo()}
              {this.props.edit && this.props.setting.isFetch ? (
                this.renderLoading()
              ) : (
                <Form onSubmit={this.onSubmit.bind(this)}>
                  <FormGroup
                    className={
                      this.hasError("name") ? " has-danger has-feedback" : ""
                    }
                  >
                    <Label for="name">Name</Label>
                    <Input
                      type="text"
                      id="name"
                      name="name"
                      autoComplete="off"
                      placeholder="Enter a Name*"
                      value={this.state._form.name}
                      onChange={this.handleChange.bind(this)}
                    />
                    {this.validateError("name")}
                  </FormGroup>
                  <FormGroup
                    className={
                      this.hasError("value_type")
                        ? " has-danger has-feedback"
                        : ""
                    }
                  >
                    <Label for="formBrandTitle">Select Value Type</Label>
                    <Select
                      id="formCategoryName"
                      name="value_type"
                      value={this.state._form.value_type}
                      options={settingTypeOptions}
                      onChange={(val) => {
                        this.onSelectChange(val, "value_type")
                      }}
                    />
                    {this.validateError("value_type")}
                  </FormGroup>
                  {this.renderValueInput()}
                  <FormGroup
                    className={
                      this.hasError("value") ? " has-danger has-feedback" : ""
                    }
                  >
                    <Label for="formTimer">Set to Active &nbsp;</Label>
                    <label className="switch switch-text switch-success-outline-alt">
                      <input
                        type="checkbox"
                        className="switch-input"
                        name="active"
                        id="formTimer"
                        value="true"
                        checked={textValue("active", this.state._form)}
                        onChange={this.handleCheckedChange}
                      />
                      <span
                        className="switch-label"
                        data-on="On"
                        data-off="Off"
                      ></span>
                      <span className="switch-handle"></span>
                    </label>
                    {this.validateError("active")}
                  </FormGroup>
                  <FormGroup>
                    <ButtonGroup>
                      {this.props.edit && this.state._form._id && (
                        <Button
                          className="mr-2"
                          color="danger"
                          onClick={this.toggleModalDelete}
                          disabled={this.state._formSubmit}
                        >
                          Delete
                        </Button>
                      )}
                      <Button color="primary">Submit</Button>
                    </ButtonGroup>
                  </FormGroup>
                </Form>
              )}
            </CardBody>
          </Card>
        </div>
      </Row>
    )
  }
}

const mapStateToProps = ({
  user,
  auth: { isFetch, isLogged, roles, id, email },
  setting,
}) => {
  return {
    user,
    email,
    guardData: { isFetch, isLogged, roles, id, email },
    setting,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    update: (id, payload) => dispatch(updateSetting(id, payload)),
    new: (payload) => dispatch(createSetting(payload)),
    delete: (id) => dispatch(deleteSetting(id)),
  }
}

const enhance = connect(mapStateToProps, mapDispatchToProps)
export default enhance(privateView(FormCard))
