import React from 'react';
import {
  Button,
  ButtonGroup
} from 'reactstrap';
import { connect } from 'react-redux';
import PaginationSummaries from '../../../components/Paginations/PaginationSummaries';
import Paginations from '../../../components/Paginations/Paginations';
import DateToHuman from '../../../components/DateToHuman';
import { Tbl, TblHead, TblBody } from '../../../components/Tbl';
import { generateNumber } from '../../../utils';
import Paragraph from '../../../styled/Typograph/Paragraph';
import SmallInline from '../../../styled/Typograph/Small/SmallInline';
import BadgeThin from '../../../components/Badges/BadgeThin';
import { FontAwesomeIcon } from '../../../components/Icons';
import ModalSkuApproved from './ModalSkuApproved';
import ModalSkuDetails from './ModalSkuDetails';

const Content = props => {
  return(
    <div>
      <Paragraph>
        <BadgeThin
          color="primary"
          className="text-uppercase mr-1"
        >
          <FontAwesomeIcon iconType="tags"/> {props.categoryName}
        </BadgeThin>
        <BadgeThin
          className="text-uppercase"
        >
          {props.requestNumber}
        </BadgeThin>
      </Paragraph>
      <Paragraph>
        {props.displayName}<br/>
        <span style={{fontSize: '.75rem'}}>
          {props.SKU && props.SKU !== ''? props.SKU: <i>Unknown SKU</i>} - Requested by: {props.user.email}
        </span>
      </Paragraph>
      <Paragraph>
        <SmallInline className="text-muted">#{props.id} created: {props.createdAt}</SmallInline>
      </Paragraph>
    </div>
  )
}
class List extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      _modalDetail: {
        item: null,
        isOpen: false
      },
      _modalApprove: {
        item: null,
        isOpen: false
      }
    }
    this._toggleModalApprove = this._toggleModalApprove.bind(this);
    this._toggleModalDetail = this._toggleModalDetail.bind(this);
  }

  componentDidUpdate(prevProps){
    if(!this.props.list.isFetch && prevProps.list.isFetch !== this.props.list.isFetch){
      const { error, data } = this.props.list;
      if(!error){
        if(data.data.length === 0 && data.current_page > 1){
          const { prev_page_url } = data;
          if(prev_page_url) this.props.onGotoPageList(data.current_page - 1);
          else this.props.onGotoPageList(1);
        }
      }
    }
    if(!this.props.dataDetails.isSubmit && prevProps.dataDetails.isSubmit !== this.props.dataDetails.isSubmit){
      const { error, status_code } = this.props.dataDetails;
      if(!error && status_code === 200){
        this.props.onGotoPageList(this.props.page);
      }
    }
  }

  _toggleModalApprove(item = null){
    this.setState({
      _modalApprove:{
        ...this.state._modalApprove,
        isOpen: !this.state._modalApprove.isOpen,
        item: item && {
          id: item.id,
          requestNumber: item.request_number,
          categoryId: item.category_id,
          category: item.category,
          brandId: item.brand_id,
          brand: item.brand,
          imgUrl: item.img_url,
          otherBrand: item.other_brand,
          displayName: item.display_name,
          SKU: item.SKU,
          user: item.user,
          createdAt: item.created_at,
          status: item.status
        }
      }
    })
  }

  _toggleModalDetail(item = null){
    this.setState({
      _modalDetail:{
        ...this.state._modalDetail,
        isOpen: !this.state._modalDetail.isOpen,
        item: item && {
          id: item.id,
          requestNumber: item.request_number,
          categoryId: item.category_id,
          category: item.category,
          brandId: item.brand_id,
          brand: item.brand,
          imgUrl: item.img_url,
          otherBrand: item.other_brand,
          displayName: item.display_name,
          SKU: item.SKU,
          user: item.user,
          createdAt: item.created_at,
          status: item.status
        }
      }
    })
  }

  render(){
    return(
      <div className="animated fadeIn">
        <ModalSkuDetails
          isOpen={this.state._modalDetail.isOpen}
          data={this.state._modalDetail.item}
          toggle={this._toggleModalDetail}
        />
        <ModalSkuApproved
          isOpen={this.state._modalApprove.isOpen}
          data={this.state._modalApprove.item}
          toggle={this._toggleModalApprove}
        />
        <PaginationSummaries
          from={this.props.list.data.from}
          to={this.props.list.data.to}
          total={this.props.list.data.total}
        />
        <div className="table-responsive" ref={tableRef => this.tableRef = tableRef}>
          <Tbl>
            <TblHead
              renderChildren={this.props.onRenderHeader}
            />
            <TblBody
              isLoad={this.props.list.isFetch}
              hasRow={this.props.list.data.data.length>0}
              columnCount={6}
              renderChildren={()=> {
                if(!this.props.list.isFetch&&this.props.list.status_code===200){
                  const { page, perPage } = this.props;
                  return (
                    this.props.list.data.data.map((item,index)=>{
                      const number = generateNumber(perPage,page,index);
                      return (
                        <tr key={item.id}>
                          <th scope="row" className="v-center">{number}</th>
                          <td className="v-center">
                            {
                              item.brand_id?(
                                <Paragraph>
                                  {item.brand.flat.map((item, index)=><BadgeThin key={`brand_${item.id}_${index}`} className="mr-1">{item}</BadgeThin>)}
                                </Paragraph>
                              ): null
                            }
                            {
                              item.other_brand?(
                                <BadgeThin className="mr-1">OTH BRAND: {item.other_brand}</BadgeThin>
                              ): null
                            }
                          </td>
                          <td className="v-center">
                            <Content
                              id={item.id}
                              requestNumber={item.request_number}
                              categoryName={item.category && item.category.name}
                              displayName={item.display_name}
                              SKU={item.SKU}
                              createdAt={item.created_at}
                              user={item.user}
                            />
                          </td>
                          <td className="v-center">
                            {item.status}
                          </td>
                          <td className="v-center">
                            <DateToHuman value={item.updated_at} id={`tooltip_skuRequests_createdAt_item_${number}`}/>
                          </td>
                          <td className="v-center">
                            {
                              item.status === "PENDING" && item.deleted_at === null?(
                                <ButtonGroup>
                                  <Button
                                    color="primary"
                                    size="sm"
                                    title="Details"
                                    onClick={() => this._toggleModalDetail(item)}
                                  >
                                    <i className="icon-info"/>
                                  </Button>
                                  <Button
                                    color="success"
                                    size="sm"
                                    title="Approve"
                                    onClick={() => this._toggleModalApprove(item)}
                                  >
                                    <FontAwesomeIcon iconType="check"/>
                                  </Button>
                                </ButtonGroup>
                              ):(
                                <Button
                                  color="primary"
                                  size="sm"
                                  title="Details"
                                  onClick={() => this._toggleModalDetail(item)}
                                >
                                  <i className="icon-info"/> Details
                                </Button>
                              )
                            }
                          </td>
                        </tr>
                      )
                    })
                  )
                }
                return null;
              }}
            />
          </Tbl>
          <Paginations
            isLoading={this.props.list.isFetch}
            size="sm"
            maxPage={8}
            total={this.props.list.data.total}
            currentPage={this.props.page}
            lastPage={this.props.list.data.last_page}
            perPage={this.props.perPage}
            handleGotoCallback={this.props.onGotoPageList}
            onCurrentPageChanged={() => {
              window.scrollTo({
                top: this.tableRef.offsetTop+25,
                behavior: 'smooth'
              })
            }}
          />

        </div>
      </div>
    )
  }
}

const mapStateToProps= ({skuRequest: { list, details }}) => ({
  list,
  dataDetails: details
});

export default connect(mapStateToProps)(List);