import React from "react";
import propTypes from "prop-types";
import Dropzone from "react-dropzone";
import { SimpleLineIcon } from "../../../Icons";

const styles = {
    dropzoneContent: {
        width: "100%",
        height: "auto",
        borderColor: "rgb(102, 102, 102)",
        borderRadius: 0,
        borderStyle: "dashed",
    },
    p: {
        padding: 15,
        textAlign: "center",
    },
    h1: {
        fontSize: 70,
    },
};

const ImageDropzone = (props) => (
    <Dropzone
        accept="image/jpeg, image/png"
        onDrop={props.onDrop}
        style={styles.dropzoneContent}
        multiple={props.multiple}
    >
        <p style={styles.p}>{props.hintText}</p>
        <h1 className="text-center" style={styles.h1}>
            <SimpleLineIcon iconType="plus" />
        </h1>
    </Dropzone>
);

ImageDropzone.propTypes = {
    onDrop: propTypes.func,
    hintText: propTypes.string,
    multiple: propTypes.bool
};

ImageDropzone.defaultProps = {
    hintText:
        "Try dropping your images here, or click to select image to upload.",
    multiple: false
};

export default ImageDropzone;
