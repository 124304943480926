import React from 'react';
import propTypes from 'prop-types';
import classNames from 'classnames'

const SimpleLineIcon = props => {
  const { iconType, className, ...otherProps } = props;
  return (
    <i
      className={classNames(`icons icon-${iconType}`, className)}
      {...otherProps}
    ></i>
  )
}

export default SimpleLineIcon;

SimpleLineIcon.propTypes = {
  iconType: propTypes.string.isRequired,
  className: propTypes.string
}

SimpleLineIcon.defaultProps = {

}