import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import FormCard from './FormCard';
import { getDetailTimer } from '../../../actions/timerAction';


class TimerForm extends React.Component {
  constructor(props){
    super(props);
    this.state={
      _id:this.props.match.params.id,
      detail: null
    }

    this._refreshDetail = this._refreshDetail.bind(this)
  }

  componentDidMount(){
    if(this.state._id)
      this.props.find(this.state._id);
  }

  _refreshDetail(){
    if(this.state._id)
      this.props.find(this.state._id);
  }

  componentDidUpdate(prevProps) {
    if (this.props.timer.isFetch !== prevProps.timer.isFetch)
    {
      const { error } = this.props.timer;
      if (!error) {
        this.setState({
          _id: this.state._id,
          detail: this.props.timer
        })
      }
    }
  }

  render(){
    return(
      <Fragment>
        {this.state.detail && (
          <>
            <FormCard
              formTitle="Timer Details"
              edit={this.props.edit}
              timer={this.state.detail}
              {...this.props}
            />
          </>
        )}
      </Fragment>
    )
  }
}
const mapStateToProps= (state) => {
	return {
    timer: state.timer
	}
};
const mapDispatchToProps = (dispatch) => {
	return{
    find:(id) => dispatch(getDetailTimer(id)),
	}
};

export default connect(mapStateToProps,mapDispatchToProps)(TimerForm);
