import React from 'react';
import propTypes  from 'prop-types';
import { Card } from 'reactstrap';

const ContentWrapper = ({ children, ...props }) =>(
  <Card
    style={{border: 'none', borderTop: '#ddd 1px solid'}}
    {...props}
  >
    { children }
  </Card>
)

export default ContentWrapper;

ContentWrapper.defaultProps = {

}

ContentWrapper.propTypes = {
  children: propTypes.node
}