// This is only used to generate breadcrumbs.
// The real routes are in AppRoute.js
const routes = {
  '/stock': 'Stock List',
  '/stock/$d': 'Stock Detail',
  '/stock/$d/add': 'Add Stock',
  '/stock/$d/sizes/$d': 'Sell List',

  '/sell_request': 'Sell & Consignment Requests',
  '/sell_request/create': 'Create',
  '/sell_request/$d': 'Details',

  '/seller_verification': 'Seller Verification',
  '/seller_verification/$d': 'Seller Verification Details',

  '/payments/refund-to-balance': 'Refund to Balance',
  '/payments': 'User Payments',
  '/payments/create': 'Create',
  '/payments/$d': 'User Payment Details',

  '/collections': 'Collections',
  '/collections/create': 'Create',
  '/collections/$d': 'Collection Details',

  '/users': 'Users',
  '/users/create': 'Create',
  '/users/$d': 'Details',

  '/vouchers': 'Vouchers',
  '/vouchers/create': 'Create',
  '/vouchers/$d': 'Details',

  '/rewards': 'Rewards',
  '/rewards/create': 'Create',
  '/rewards/$d': 'Details',

  '/bank_accounts': 'Bank Accounts',
  '/bank_accounts/create': 'Create',
  '/bank_accounts/$d': 'Details',

  '/roles': 'Roles',
  '/roles/create': 'Create',
  '/roles/$d': 'Details',

  '/brands': 'Brands',
  '/brands/create': 'Create',
  '/brands/$d': 'Details',

  '/categories': 'Categories',
  '/categories/create': 'Create',
  '/categories/$d': 'Details',

  '/products': 'Products',
  '/products/create': 'Create',
  '/products/$d': 'Details',

  '/product_variants': 'Product Variants',
  '/product_variants/create': 'Create',
  '/product_variants/sku_requests': 'SKU Requests',
  '/product_variants/$d': 'Details',

  '/disbursements': 'Disbursements',
  '/disbursements/create': 'Create',
  '/disbursements/$d': 'Details',
  '/disbursements/completed': 'Completed List',
  '/disbursements/pending': 'Pending List',

  '/sales': 'Sales',
  '/sales/all': 'All Sales',
  '/sales/$d': 'Sale Detail',
  '/sales/$d/change_seller': 'Change Seller',
  '/sales/outstanding': 'Outstanding Sales',
  '/sales/quality_control': 'Quality Control Sales',
  '/sales/legit_check': 'Legit Check Sales',
  '/sales/follow-up': 'Follow Up Sales',
  '/sales/delivering': 'Delivering Sales',
  '/sales/jnt-delivering': 'JNT Delivering Sales',
  '/sales/instant-delivering': 'Gosend & Grab Delivering Sales',
  '/sales/warehouse-delivering': 'Warehouse Delivering Sales',
  '/sales/other-delivering': 'Other Delivering Sales',
  '/sales/processed': 'Processed Sales',
  '/sales/failed': 'Failed Sales',

  '/consignment': 'Consignment',
  '/consignment/$d': 'Consignment Detail',
  '/consignment/rack-assignment': 'Rack Assignment Consignment',
  '/consignment/pre-delivering': 'Pre-Delivering Consignment',
  '/consignment/delivering': 'Delivering Consignment',
  '/consignment/jnt-delivering': 'JNT Delivering Consignment',
  '/consignment/instant-delivering': 'Gosend & Grab Delivering Consignment',
  '/consignment/warehouse-delivering': 'Warehouse Delivering Consignment',
  '/consignment/other-delivering': 'Other Delivering Consignment',

  '/top_up': 'Top-up Payments',
  '/top_up/$d': 'Top-up Payment Details',

  '/offers': 'User Offers',
  '/offers/$d': 'User Offer Details',

  '/midtrans': 'Midtrans',
  '/midtrans/$d': 'Midtrans Details',

  '/menus': 'Menus',
  '/menus/create': 'Create',
  '/menus/$d': 'Details',

  '/sliders': 'Sliders',
  '/sliders/create': 'Create',
  '/sliders/$d': 'Details',

  '/videos': 'Videos',
  '/videos/create': 'Create',
  '/videos/$d': 'Details',

  '/3rdparty_stock': "3rd Party's Stock",
  // '/3rdparty_stock/$d': "Stock Detail",
  '/app_sections': 'App Sections',
  '/app_sections/create': 'Create',
  '/app_sections/$d': 'Details',

  '/warehouse': 'Warehouse',
  '/warehouse/$d': 'Warehouse Details',

  '/racks': 'Racks',
  '/racks/Create': 'Racks Create',
  '/racks/$s': 'Details',
  '/raffle_group': 'Raffle Group',
  '/raffle_group/create': 'Create',
  '/raffle_group/$d': 'Details',
  '/raffles': 'Raffles',
  '/raffles/create': 'Create',
  '/raffles/$d': 'Details',
  '/raffle_requests': 'Raffle Requests',
  '/raffle_requests/$d': 'Details',
  '/point_shop': 'Point Shop',
  '/point_shop/create': 'Create',
  '/point_shop/$d': 'Details',

  '/marketing_budgets': 'Marketing Budgets',
  '/marketing_budgets/create': 'Create',
  '/marketing_budgets/$d': 'Details',

  '/spinning_wheel': 'Spinning Wheel',
  '/spinning_wheel/create': 'Create',
  '/spinning_wheel/$d': 'Details',

  '/pop_up_banner': 'Pop Up Banner',
  '/pop_up_banner/create': 'Create',
  '/pop_up_banner/$d': 'Details',

  '/timer': 'Timer',
  '/timer/create': 'Create',
  '/timer/$d': 'Details',

  '/faqs': 'FAQ',
  '/faqs/create': 'Create',
  '/faqs/$d': 'Details',

  '/articles': 'Article',
  '/articles/create': 'Create',
  '/articles/$d': 'Details',

  '/metas': 'Meta',
  '/metas/create': 'Create',
  '/metas/$d': 'Details',

  '/bank_promo': 'Bank Promos',
  '/bank_promo/create': 'Create',
  '/bank_promo/$d': 'Details',

  '/survey': 'Survey',
  '/survey/create': 'Create Survey',
  'survey/$d': 'Survey Details',

  '/survey_option': 'Survey Option',
  '/survey_option/create': 'Create Survey Option',
  'survey_option/$d': 'Survey Option Details',

  '/option': 'Option',
  '/option/create': 'Create Option',
  'option/$d': 'Option Details',

  '/setting': 'Setting',
  '/setting/create': 'Create Setting',
  '/setting/$d': 'Setting Details',

  '/product_addons': 'Product Addons',
  '/product_addons/create': 'Create',
  '/product_addons/$d': 'Details',

  '/country': 'Country',
  '/country/update-batch': 'Update Batch Country',
  
  '/push_notification': 'Push Notification',
  '/push_notification/create': 'Create Push Notification',
  '/push_notification/$d': 'Push Notification Details',

  '/me': 'My Profile',

  '/': 'Home',
  '/dashboard': 'Dashboard',
  '/components': 'Components',
  '/charts': 'Charts',
  '/components/buttons': 'Buttons',
  '/components/social-buttons': 'Social Buttons',
  '/components/cards': 'Cards',
  '/components/forms': 'Forms',
  '/components/modals': 'Modals',
  '/components/switches': 'Switches',
  '/components/tables': 'Tables',
  '/components/tabs': 'Tabs',
  '/icons': 'Icons',
  '/icons/font-awesome': 'Font Awesome',
  '/icons/simple-line-icons': 'Simple Line Icons',
  '/widgets': 'Widgets',
};
export default routes;
