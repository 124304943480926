import React, { Component } from "react"
import {
  Button,
  Card,
  CardBody,
  CardText,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
  UncontrolledAlert,
} from "reactstrap"
import { connect } from "react-redux"
import {
  CardHeaderWithToolbars,
  Title,
} from "../../components/Card/CardHeaderWithToolbars"
import privateView from "../../components/hocs/privateView"
import { getCountryList, updateBatchCountry } from "../../actions/countryAction"
import Loading from "react-loading-animation"
import Analytics from "../../services/Analytics"

const defaultParams = {
  no_limit: true,
  sort_by: "zoneId_asc",
}

class UpdateBatchCountry extends Component {
  constructor() {
    super()
    this.state = {
      _params: {
        ...defaultParams,
      },
      countries: [],
      message: null,
    }

    this._onCheckedChanged = this._onCheckedChanged.bind(this)
    this._onSaveChanged = this._onSaveChanged.bind(this)
  }

  _getList(rawParams = {}) {
    let params = {}
    Object.keys(rawParams)
      .filter((key) => rawParams[key] != "")
      .forEach((key) => (params[key] = rawParams[key]))
    this.props.getList({ params })
  }

  componentDidMount() {
    const { email } = this.props.injectedProps
    this._getList(this.state._params)
    Analytics.recordPageView("update_batch_country", email, {
      url: window.location.href,
      location: this.props.location,
      match: this.props.match,
    })
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.country.isSubmit != this.props.country.isSubmit &&
      this.props.country.success
    ) {
      this.setState({
        message: this.props.country.detail.message,
      })
    }
  }

  _onCheckedChanged(index) {
    let newData = this.props.country.list
    if (newData[index].code !== "ID") {
      newData[index].active = !newData[index].active

      let data = this.state.countries
      let found = data.find((val) => val.id === newData[index].id)
      if (found) {
        data = data.filter((val) => val.id !== newData[index].id)
      } else {
        data.push(newData[index])
      }
      this.setState({ countries: data })
    }
  }

  _onSaveChanged() {
    const { countries } = this.state

    this.props.update({
      country: countries,
    })
  }

  _onRenderCountry() {
    if (
      this.props.country.status_code === 200 &&
      this.props.country.list.length > 0
    ) {
      return this.props.country.list.map((item, index) => (
        <Col md={4} key={index}>
          <FormGroup check inline>
            <Input
              type="checkbox"
              name={"country-" + index}
              onChange={() => this._onCheckedChanged(index)}
              checked={item.active}
              disabled={item.code === "ID"}
            />
            <Label check for={"country-" + index}>
              {item.name}
            </Label>
          </FormGroup>
        </Col>
      ))
    }
    return null
  }

  render() {
    return (
      <Row>
        <Col xs={12}>
          <Card>
            <CardHeaderWithToolbars>
              <Title>
                <i className="fa fa-align-justify"></i>
                <span>Update Country</span>
              </Title>
            </CardHeaderWithToolbars>
            <CardBody>
              {this.props.country.isFetch ? (
                <Loading />
              ) : (
                <>
                  <CardText>
                    In this section will display Country list.
                  </CardText>
                  {this.state.message && (
                    <UncontrolledAlert color="success">
                      {this.state.message}
                    </UncontrolledAlert>
                  )}
                  <Container fluid>
                    <Row>{this._onRenderCountry()}</Row>
                    <Row className="mt-4">
                      <Col className="text-right">
                        <Button
                          color="success"
                          onClick={this._onSaveChanged}
                          disabled={this.props.country.isSubmit}
                        >
                          Save Changes
                        </Button>
                      </Col>
                    </Row>
                  </Container>
                </>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    )
  }
}

const mapStateToProps = ({
  country,
  auth: { isFetch, roles, isLogged, id, email },
}) => {
  return {
    country,
    guardData: { isFetch, roles, isLogged, id, email },
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    getList: (query) => dispatch(getCountryList(query)),
    update: (payload) => dispatch(updateBatchCountry(payload)),
  }
}
const enhance = connect(mapStateToProps, mapDispatchToProps)

export default enhance(privateView(UpdateBatchCountry))
