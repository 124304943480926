import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  FormGroup,
  Input,
  Label
} from 'reactstrap';
import classnames from 'classnames';
import { hasError, errorMessage, textChange, textValue } from '../../../utils/form';
import { TextRupiah } from '../../../components/Text';
import Radio from '../../../components/Form/Radios/Radio';
import RadioText from '../../../components/Form/Radios/RadioText';
export class ModalDisburseCustomer extends Component{
  constructor(props){
    super(props);
    this.state={
      id:null,
      amount:0,
      status:'',
      with_balance_out: false,
      error:null
    }
    this.onSubmit = this.onSubmit.bind(this);
  }

  handleChange(e){
    this.setState(textChange(e,{...this.state}));
  }

  componentWillReceiveProps(nextProps){
    if(nextProps.isOpen===true&&nextProps.isOpen!==this.props.isOpen&&nextProps.sale!==null){
      // const amount = (parseInt(nextProps.sale.price)+parseInt(nextProps.sale.sale_shipping.ka_courier_price))-(parseInt(nextProps.sale.discount)+parseInt(nextProps.sale.sale_shipping.ka_courier_discount));
      // const amount = parseInt(nextProps.sale.sale_shipping.ka_courier_price);
      const amount = parseInt(nextProps.sale.total_price);
      this.setState({
        id: nextProps.sale.id,
        error: null,
        notes: '',
        amount,
        amountReadOnly: amount,
        with_balance_out: false,
        status: nextProps.sale.status
      });
    }
    if(nextProps.isSubmit!==this.props.isSubmit&&nextProps.isSubmit===false){
      this.setState({error:this.props.error});
    }
  }

  onSubmit(e){
    e.preventDefault();
    const { id, status, amount, with_balance_out } = this.state;
    const payload = { status, amount, with_balance_out };
    this.props.onSubmit(id, payload);
  }

  render(){
    return (
      <Modal isOpen={this.props.isOpen} toggle={this.props.onCancel} className="modal-dialog modal-md">
        <ModalHeader toggle={this.props.onCancel}>Confirmation</ModalHeader>
        <ModalBody>
          {
            this.props.sale!==null?
            <p>
              Invoice: <i>({this.props.sale.invoice_number})</i> <br/>
              Email: <i>({this.props.sale.buyer_email})</i> <br/>
              Total: <TextRupiah prefix="Rp. " value={this.state.amountReadOnly} bold/>
            </p>
            :''
          }
          <p>
            {/* Total amount to disbursement calculate from Product Price and Courier Price<br/> */}
            By <strong>Confirm</strong> this form, increasing their balance.
            So please validate carefully, you cannot undoing your changes.<br/>
            If you want to disburse their funds go to {' '}
            <Link to="/disbursements/create" title="Disbursements Page" className="link-secondary text-underline">Disbursements Page</Link>.
          </p>
          <Form>
            <FormGroup className={classnames({'has-danger has-feedback':hasError('amount',this.state.error)})}>
              <Label>Amount*</Label>
              <Input
                type="number"
                name="amount"
                value={textValue('amount',this.state)}
                onChange={(e)=>this.handleChange(e)}
              />
              {errorMessage('amount',this.state.error)}
            </FormGroup>
            <FormGroup className={classnames({'has-danger has-feedback':hasError('notes',this.state.error)||hasError('status',this.state.error)})}>
              <Label>Notes</Label>
              <Input
                type="textarea"
                name="notes"
                placeholder="Put your notes here"
                value={textValue('notes',this.state)}
                onChange={(e)=>this.handleChange(e)}
              />
              {errorMessage('notes',this.state.error)}
              {errorMessage('status',this.state.error)}
            </FormGroup>
            <FormGroup className={classnames({'has-danger has-feedback':hasError('with_balance_out',this.state.error)})}>
              <Radio value="true" checked={this.state.with_balance_out} onChange={e=>this.setState({ with_balance_out: e.target.checked })}/>
              <RadioText text="Also create a balance out disbursement?"/>
              {errorMessage('with_balance_out',this.state.error)}
            </FormGroup>
          </Form>
          <span className="text-sm">Fields marked with * are required</span>
        </ModalBody>
        <ModalFooter>
          <Button color="primary"
            disabled={this.props.isSubmit}
            onClick={this.onSubmit}
          >
            {this.props.isSubmit?'Submitting...':'Confirm'}
          </Button>{' '}
          <Button
            color="secondary"
            disabled={this.props.isSubmit}
            onClick={this.props.onCancel}
          >
              Cancel
          </Button>
        </ModalFooter>
      </Modal>
    )
  }
}
ModalDisburseCustomer.propTypes={
  sale:PropTypes.object,
  isSubmit:PropTypes.bool,
  error:PropTypes.object,
  isOpen:PropTypes.bool,
  onSubmit:PropTypes.func,
  onCancel:PropTypes.func
}
