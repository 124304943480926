import React, {Component} from 'react';
import { connect } from 'react-redux';
import {
  Row,
  Col,
  Badge,
  Card,
  CardHeader,
  CardBody,
  Form,
  FormGroup,
  Input,
  Label,
  Button,
  Alert,
  Tooltip
} from 'reactstrap';
import moment from 'moment-timezone';
import Select from 'react-select';
import 'react-select/dist/react-select.css';
import Loading from 'react-loading-animation';
import classnames from 'classnames';
import qs from 'query-string';
import { USER_SELL_EXPIRY_DAYS,  sellRequestBoxConditionOptions } from '../../constants/kickavenue';
import { DATE_FORMAT_DB, DATE_TIMEZONE } from '../../config/date';

import { getDetailProductVariant } from '../../actions/productVariantAction';
import { createUserSell } from '../../actions/sellRequestAction';

import { dateChange, dateValue } from '../../utils/form'
import { ValidationMessage } from '../../components/Form/ValidationMessage';

import StockImages from './stockProductVariantForm/StockImages';
import privateView from '../../components/hocs/privateView';
import SelectUserAsync from '../../components/Form/Select/Async/SelectUser';
import SelectVariant from '../../components/Form/Select/Async/SelectVariant';
import SelectRack from '../../components/Form/Select/Async/SelectRack';
import InputDatetime from '../../components/Form/InputDatetime/InputDatetime';
import FontAwesomeIcon from '../../components/Icons/FontAwesomeIcon';
import { Radio, RadioText, FormGroupRadio } from '../../components/Form/Radios';
import { sneakersConditionOption, userSellDefaultConditions, userSellApparelConditions, userSellHandbagConditions, userSellLifestyleConditions, userSellSneakerConditions, brandConditions } from '../../utils/userSell';
import Analytics from "../../services/Analytics";

class StockProductVariantForm extends Component{
  constructor(props){
    super(props);
    const params = qs.parse(props.location.search);
    this.state={
      _id:this.props.match.params.id,
      _fetching:false,
      _submit:false,
      _error:null,
      _info:null,
      _sizes:[{value:'',label:'Loading...'}],
      _listingCategory: 'SNEAKERS',
      _form:{
        product:null,
        size_id: params.size_id?parseInt(params.size_id):null,
        asking_price:0,
        purchase_price:0,
        quantity:1,
        box_condition:'SEMPURNA',
        sneakers_condition:'BARU',
        disabled_box:false,
        pre_verified:false,
        pre_order:false,
        status:'draft',
        rack: params.rack? params.rack.toUpperCase(): null,
        rack_custom: params.rack? params.rack.toUpperCase(): null,
        expiry: null,
        listingConditions: []
      },
      boxConditionOpt:sellRequestBoxConditionOptions,
      sneakersConditionOpt: this._getSneakerConditionsOpt('sneakers'),
      expirySellRequestTooltipOpen: false,
      rack_option:"CUSTOM",
      referrer: params._refLink? params._refLink.toLowerCase(): null
    }
		moment.tz(DATE_TIMEZONE);
    this._handleOnSelectUserChange = this._handleOnSelectUserChange.bind(this);
    this._onSubmit = this._onSubmit.bind(this);
    this._handleTextChange = this._handleTextChange.bind(this);
    this._handleCheckedChange = this._handleCheckedChange.bind(this);
    this._handlePreOrderCheckedChange = this._handlePreOrderCheckedChange.bind(this);
    this._handlePreVerifiedCheckedChange = this._handlePreVerifiedCheckedChange.bind(this);
    this._handleOnSelectUserOpen = this._handleOnSelectUserOpen.bind(this);
    this._toggleExpiryToolTip = this._toggleExpiryToolTip.bind(this);
    this._handleGenerateExpiryClick = this._handleGenerateExpiryClick.bind(this);
    this._handleOnSelectedProductChange = this._handleOnSelectedProductChange.bind(this);
    this._handleOnSelectProductOpen = this._handleOnSelectProductOpen.bind(this);
    this._handleSelectRack = this._handleSelectRack.bind(this);
    this._handleOnSelectRackOpen = this._handleOnSelectRackOpen.bind(this);
  }

  componentDidMount(){
    const { email } = this.props.injectedProps;
    this.props.find(this.state._id);
    Analytics.recordPageView('stock_create', email, {
      url: window.location.href,
      location: this.props.location,
      match: this.props.match
    });
    // setTimeout(() => {
    //   this.setState({
    //     expirySellRequestTooltipOpen: true
    //   }, () => setTimeout(this._toggleExpiryToolTip, 2000))
    // }, 1000);
  }

  _toggleExpiryToolTip(){
    this.setState({expirySellRequestTooltipOpen: !this.state.expirySellRequestTooltipOpen})
  }

  _getSneakerConditionsOpt = category => {
    return sneakersConditionOption
      .filter(item => item.categories.includes(category))
      .map(item => {
        let label = item.label;
        if(item.en && item .en[category]){
          label = item .en[category];
        }
        return { value: item.value, label }
      })
  }

  componentDidUpdate(prevProps){   
    if(!prevProps.productVariant.isFetch&&this.props.productVariant.isFetch){
      const isInit = this.state._form.product === null
      this.setState({_fetching: isInit && this.props.productVariant.isFetch});
    }
    else if(prevProps.productVariant.isFetch&&!this.props.productVariant.isFetch){
      const { error } = this.props.productVariant;
      if(error)
        this.setState({
          _fetching: this.props.productVariant.isFetch,
          _sizes: []
        })
      else{
        if(this.props.match.params.id !== this.props.productVariant.detail.id){
          const {  sizes, product: { brand, category }} = this.props.productVariant.detail;
          const { _form } = { ...this.state };
          _form.product =  this.props.productVariant.detail.id && { id: this.props.productVariant.detail.id, name: this.props.productVariant.detail.display_name}
          _form.disabled_box = this.props.productVariant.detail.id && this.props.productVariant.detail.hide_box_info 
          const _sizes = sizes.sort((a,b)=>a.US-b.US).map(size=>({
            value:size.id,
            label:size.US
          }))
          if(_sizes.length){
            const filter = _sizes.filter(item=> item.value===_form.size_id);
            if(!filter.length)
              _form.size_id = _sizes[0].value
          }
          let parentBrand = null;
          if(brand) {
            parentBrand = brand.ancestors.length? brand.ancestors[0].slug: null
          }
          let sneakersConditions=null;
          let boxConditions=null;
          if(category.name.toUpperCase()==='LIFESTYLES'){
            switch (parentBrand) {
              case 'bike':
                sneakersConditions=[{value:'BARU', label:'Brand New'}];
                boxConditions=[{value:'NO_BOX',label:'Missing Box'}];
                break;
              case 'games-consoles':
                boxConditions=[{value:'SEMPURNA',label:'Sempurna'},{value:'NO_BOX',label:'Missing Box'}];
                break;
              default:
                break;
            }
          }
          this.setState({
            _fetching: this.props.productVariant.isFetch,
            _listingCategory: category.name.toUpperCase(),
            _sizes,
            _form: {
              ..._form,
              sneakers_condition: 'BARU',
              box_condition: parentBrand === 'bike' ? 'NO_BOX' : this.state._form.box_condition,
              listingConditions: this._getSellConditions(category.name.toUpperCase(), parentBrand)
            },
            boxConditionOpt: boxConditions ?  boxConditions : sellRequestBoxConditionOptions,
            sneakersConditionOpt: sneakersConditions ? sneakersConditions : this._getSneakerConditionsOpt(category.name.toLowerCase())
          })
        }
        else{
          const {  sizes, product: { brand, category }} = this.props.productVariant.detail;
          const { _form } = { ...this.state };
          _form.product =  this.props.productVariant.detail.id && { id: this.props.productVariant.detail.id, name: this.props.productVariant.detail.display_name }
          const _sizes = sizes.sort((a,b)=>a.US-b.US).map(size=>({
            value:size.id,
            label:size.US
          }))
          if(_sizes.length){
            const filter = _sizes.filter(item=> item.value===_form.size_id);
            if(!filter.length)
              _form.size_id = _sizes[0].value
          }
          let parentBrand = null;
          if(brand) {
            parentBrand = brand.ancestors.length? brand.ancestors[0].slug: null
          }
          let sneakersConditions=null;
          let boxConditions=null;
          if(category.name.toUpperCase()==='LIFESTYLES'){
            switch (parentBrand) {
              case 'bike':
                sneakersConditions=[{value:'BARU', label:'Brand New'}];
                boxConditions=[{value:'NO_BOX',label:'Missing Box'}];
                break;
              case 'games-consoles':
                boxConditions=[{value:'SEMPURNA',label:'Sempurna'},{value:'NO_BOX',label:'Missing Box'}];
                break;
              default:
                break;
            }
          }
          this.setState({
            _fetching: this.props.productVariant.isFetch,
            _listingCategory: category.name.toUpperCase(),
            _sizes,
            _form: {
              ..._form,
              box_condition: parentBrand === 'bike' ? 'NO_BOX' : this.state._form.box_condition,
              listingConditions: this._getSellConditions(category.name.toUpperCase(), parentBrand)
            },
            boxConditionOpt: boxConditions ?  boxConditions : sellRequestBoxConditionOptions,
            sneakersConditionOpt: sneakersConditions ? sneakersConditions : this._getSneakerConditionsOpt(category.name.toLowerCase())
          })
        }
      }
    }
    if(this.state._submit&&prevProps.sellRequest.isSubmit&&!this.props.sellRequest.isSubmit){
      const { product } = this.state._form;
      const { error, detail, isSubmit } = this.props.sellRequest;
      if(error){
        this.setState({
          _submit:isSubmit,
          _info:null
          ,_error:{...error}
        });
      }
      else if(detail.status_code===200){
        this.setState({
          _submit:isSubmit,
          _info:null,
          _error:null
        },()=> {
          const { referrer } = this.state;
          const { SKU } = this.props.productVariant.detail;
          if(referrer === 'warehouse_list'){
            this.props.history.push({
              pathname: `/warehouse`,
              search: `keyword=${SKU}`
            })
          }
          else{
            this.props.history.push(`/stock/${product?product.id:this.state._id}`)
          }
        });
      }
    }
  }

  _getSellConditions(category, brand){
    let listingConditions = [];
    switch(category){
      case 'APPARELS': {
        listingConditions = userSellApparelConditions.map(item => {
          return {
            ...item,
            value: false
          }
        })
        break;
      }
      case 'HANDBAGS': {
        listingConditions = userSellHandbagConditions.map(item => {
          return {
            ...item,
            value: false
          }
        })
        break;
      }
      case 'LIFESTYLES': {
        const selected = brandConditions.find(item => item.slug === brand);
        listingConditions = userSellLifestyleConditions
        .filter(item => !selected || (selected.conditions.includes(item.name)))
        .map(item => {
          return {
            ...item,
            value: false
          }
        })
        break;
      }
      default: {
        listingConditions = userSellDefaultConditions.map(item => {
          return {
            ...item,
            value: false
          }
        })
        if(category === "SNEAKERS"){
          const missingAccessories = userSellSneakerConditions.map(item => {
            return {
              ...item,
              value: false,
              missingAccessories: true
            }
          })
          listingConditions = [ ...listingConditions, ...missingAccessories ]
        }
        break;
      }
    }
    return listingConditions;
  }

  _onSubmit(e){
    e.preventDefault();
    const { _form } = this.state;
    if(_form.product === null){
      alert("Please select your product...")
      return;
    }
    if(_form.size_id === null || _form.size_id === ''){
      alert("Please select your size...")
      return;
    }
    this.setState({_submit:true,_info:'Processing your request.'},()=>{
      const { _form: { listingConditions, ..._form }, _listingCategory } = this.state;
      let payloadConditions = {};
      // search for missing_accessories key.
      const missing_accessories = listingConditions.find(item => item.name.toLowerCase() === 'missing_accessories');
      if(_listingCategory === 'SNEAKERS'){
        payloadConditions.defects = listingConditions.filter(item => !item.missingAccessories).reduce((obj, item) => {
          obj[item.name.toLowerCase()] = item.value
          return { ...obj }
        }, Object.assign({}))
        const missingAccessoriesList = listingConditions.filter(item => item.missingAccessories).reduce((obj, item)=>{
          obj[item.name] = missing_accessories && missing_accessories.value? item.value: false
          return { ...obj }
        }, Object.assign({}))
        if(Object.keys(missingAccessoriesList).length){
          payloadConditions.missing_accessories_list = missingAccessoriesList
        }
      }
      // else if(_listingCategory === 'APPARELS'){
      else{
        //ignore keys karena udah di user_sell table.
        const ignores = ['display_item'];
        const _listConditions =  listingConditions.filter(item => !ignores.includes(item.name.toLowerCase()));
        // search for display_item key.
        const display_item = listingConditions.find(item => item.name.toLowerCase() === 'display_item');
        const inclusions = _listConditions.filter(item => item.inclusions).reduce((obj, item) =>{
          obj[item.name.toLowerCase()] = item.value
          return { ...obj }
        }, Object.assign({}));
        payloadConditions = {
          display_item: display_item? display_item.value: false,
          yellowing: false,
          missing_accessories: missing_accessories? missing_accessories.value: false,
          sneakers_defect: _listConditions.filter(item => item.value === true && !item.inclusions).length > 0,
          defects: _listConditions.filter(item => !item.inclusions).reduce((obj, item) => {
            obj[item.name.toLowerCase()] = item.value
            return { ...obj }
          }, Object.assign({}))
        }
        if(Object.keys(inclusions).length){
          payloadConditions.inclusions = inclusions;
        }
      }
      const payloads = {
        user_id : _form.user? parseInt(_form.user.id): _form.user,
        product_variant_id: _form.product? parseInt(_form.product.id): _form.product,
        size_id: _form.size_id,
        asking_price: _form.asking_price,
        purchase_price: _form.purchase_price.length?_form.purchase_price:undefined,
        quantity: _form.quantity,
        box_condition: _listingCategory === 'HANDBAGS' || _form.disabled_box?'SEMPURNA': _form.box_condition,
        sneakers_condition: _form.sneakers_condition,
        pre_order: _form.pre_order,
        pre_verified: _form.pre_verified,
        // yellowing: _form.yellowing,
        // sneakers_defect: _form.sneakers_defect,
        // display_item: _form.display_item,
        // missing_accessories: _form.missing_accessories,
        rack: this.state.rack_option === "CUSTOM"? _form.rack_custom: _form.rack,
        note: _form.note,
        status: _form.status,
        // serial_number: _form.serial_number,
        expiry: _form.expiry !== null&& _form.expiry.length>0?_form.expiry: null,
        ...payloadConditions
      }
      this.props.submit(payloads);
    });
  }

  getValue(stateName){
    const { _form } = this.state;
    if(_form[stateName]===null ||_form[stateName]===undefined)
        return '';
    return _form[stateName];
  }

  _handleSelectChange(val,stateName){
    this.setValue(val,stateName);
  }

  _handleTextChange(e){
    this.setValue(e.target.value,e.target.name);
  }

	_handleCheckedChange(e){
    this.setValue(e.target.checked,e.target.name);
  }

  _handlePreOrderCheckedChange(e){
    const newState={...this.state._form};
    newState[e.target.name]=e.target.checked;
    if(e.target.checked){
      newState['pre_verified'] = !e.target.checked;
    }
    this.setState({ _form: newState });
  }

  _handlePreVerifiedCheckedChange(e){
    const { pre_order }={...this.state._form};
    const checked = pre_order===true ? !pre_order : e.target.checked;
    this.setValue(checked, e.target.name);
  }

  _handleGenerateExpiryClick(){
    this.setState({
      expiry: moment()
      .add(USER_SELL_EXPIRY_DAYS, 'days')
      .endOf('day')
      .format(DATE_FORMAT_DB)
    })
  }

  setValue(val, stateName){
    const newState={...this.state._form};
    newState[stateName]=val;
    this.setState({ _form: newState });
  }

  validateError(name){
    if(this.hasError(name))
    {
      let errorList=this.props.sellRequest.error.errors;
      return <ValidationMessage message={errorList[name][0]}/>
    }
    return '';
  }

  hasError(name){
    if(this.props.sellRequest.error)
    {
      if(this.props.sellRequest.error.errors)
      {
        let errorList=this.props.sellRequest.error.errors;
        let errList=Object.keys(errorList).filter(key=> key==name);
        if(errList.length)
        return true
      }
    }
    return false
  }

  renderInfoAlert(){
    const alerts=[];
    if(this.state._error!==null){
      if(this.state._error.status_code===422)
        alerts.push(
          <Alert key="errors" color="danger" isOpen={true} toggle={()=>this.setState({_error:null})}>
            <strong>Oh snap,</strong> Please fulfill your form.
          </Alert>
        );
      else
        alerts.push(
          <Alert key="errors" color="danger" isOpen={true} toggle={()=>this.setState({_error:null})}>
            <strong>Oh snap,</strong> {this.state._error.message}
          </Alert>
        );
    }
    if(this.state._info!==null){
      alerts.push(
        <Alert key="infos" color="info" isOpen={true} toggle={()=>this.setState({_info:null})}>
          <strong>Heads up!</strong> {this.state._info}
        </Alert>
      );
    }
    return alerts;
  }

  _handleOnSelectUserChange(selected){
    const { _form } = this.state;
    _form.user = selected;
    this.setState({
      _form,
      _recentUserSearch: selected && this.state._recentUserSearch
    })
  }

  _handleOnSelectUserOpen(){
    if(this.state._form.user){
      return this.state._recentUserSearch&&this.refs.selectUser.loadSelectOptions(this.state._recentUserSearch);
    }
    return this.refs.selectUser.loadSelectOptions('');
  }

  _handleOnSelectedProductChange(option){
    const { _form } =this.state;
    _form.product = option;
    _form.disabled_box = option.hide_box_info;
    if(option){
      this.props.find(option.id)
    }
    this.setState({_form});
  }

  _handleOnSelectProductOpen(){
    if(this.state._form.product){
      if(this.state._recentSearchProduct){
        this.refs.selectProduct.loadSelectOptions(this.state._recentSearchProduct)
      }
      else{
        this.refs.selectProduct.loadSelectOptions(this.state._form.product.name)
      }
    }
    else{
      this.refs.selectProduct.loadSelectOptions('')
    }
  }

  
  _handleOnSelectRackOpen(){
    if(this.state._form.rack){
      if(this.state._recentSearchRack){
        this.refs.selectRack.loadSelectOptions(this.state._recentSearchRack)
      }
      else{
        this.refs.selectRack.loadSelectOptions(this.state._form.rack.name)
      }
    }
    else{
      this.refs.selectRack.loadSelectOptions('')
    }
  }

  _handleDatePickerChange(value, name){
    const _form = dateChange(value,name, this.state._form, DATE_FORMAT_DB);
    this.setState({_form});
  }

  _handleConditionsCheckedChange(e, index){
    const { listingConditions } = this.state._form;
    const item = listingConditions[index];
    const arr = listingConditions.filter(item => item.name !== e.target.name);
    arr.splice(index, 0, {
      ...item,
      value: e.target.checked
    })
    this.setState({
      _form:{
        ...this.state._form,
        listingConditions: arr
      }
    });
  }

  _handleSelectRack(option){
    const { _form } =this.state;
    _form.rack = option? option.name: option;
    this.setState({_form});
  }

  render(){
    const status=[{value:'draft',name:'Draft'},{value:'awaiting_review',name:'Awaiting Review'},{value:'approved',name:'Approved'}];
    const defectCount = this.state._form.listingConditions.filter(condition => !condition.inclusions && !condition.missingAccessories).length;
    const missing = this.state._form.listingConditions.find(condition => condition.name.toLowerCase() === "missing_accessories" && condition.value)
    const missingCount = this.state._form.listingConditions.filter(condition => condition.missingAccessories).length
    return (
      <div className="animated fadeIn">
        <Row>
          <Col xs={12} md={6}>
            <Card>
              <CardHeader>
                <i className="fa fa-align-justify"></i>
                <Badge color="success">Add Stock</Badge>
                <Badge color="primary" className="text-uppercase ml-2">
                  <FontAwesomeIcon iconType="tags" className="mr-1"/>
                  {this.state._listingCategory}
                </Badge>
                {this.state._form.product &&(
                  <h5 style={{marginTop:5}}>{this.state._form.product.name}</h5>
                )}
                {/* <h5 style={{marginTop:5}}>{this.props.productVariant.detail.display_name}</h5>
                <h6 style={{marginTop:5}} className="text-muted">{this.props.productVariant.detail.colour}</h6> */}
              </CardHeader>
              <CardBody>
                {this.renderInfoAlert()}
                <Loading isLoading={this.state._fetching}>
                  <Form onSubmit={this._onSubmit}>
                    <FormGroup className={classnames({'has-danger':this.hasError('product_variant_id'), 'has-feedback':this.hasError('product_variant_id')})}>
                      <Label for="_formSelectVariant">Product</Label>
                      <SelectVariant
                        defaultOptions
                        id="_formSelectVariant"
                        ref="selectProduct"
                        placeholder="Select a product..."
                        noResultsText="Cannot find product."
                        cacheOptions={false}
                        value={this.state._form.product}
                        onSelectSearch={(input)=>this.setState({_recentSearchProduct: input})}
                        onSelectChange={this._handleOnSelectedProductChange}
                        onSelectOpen={this._handleOnSelectProductOpen}
                      />
                    </FormGroup>
                    <FormGroup className={classnames({'has-danger':this.hasError('user_id'), 'has-feedback':this.hasError('user_id')})}>
                      <Label for="_formSelectUser">Email</Label>
                      <SelectUserAsync
                        defaultOptions
                        id="_formSelectUser"
                        userId={this.props.auth.id}
                        ref="selectUser"
                        value={this.state._form.user}
                        paramsApi={{ roles: 'seller,administrator', role_query: 'or', scope: 'all' }}
                        placeholder="Type and select a user..."
                        noResultsText="Cannot find user."
                        loadOptions={this._getUserOptions}
                        onSelectSearch={(input)=>this.setState({_recentUserSearch: input})}
                        onSelectChange={this._handleOnSelectUserChange}
                        onSelectOpen={this._handleOnSelectUserOpen}
                      />
                    </FormGroup>
                    <Row>
                      <Col xs={12} md={6}>
                        <FormGroup className={classnames({'has-danger':this.hasError('size_id'), 'has-feedback':this.hasError('size_id')})}>
                          <Label for="formChooseSize">Choose Size (US)</Label>
                          <Select
                            id="formChooseSize"
                            placeholder="Choose a size..."
                            noResultsText="Cannot find size, please add size on product variant page."
                            options={this.state._sizes}
                            value={this.getValue('size_id')}
                            onChange={(val)=>this._handleSelectChange(val.value,'size_id')}
                          />
                          {this.validateError('size_id')}
                        </FormGroup>
                      </Col>
                      <Col xs={12} md={6}>
                        <FormGroup className={classnames({'has-danger':this.hasError('rack'), 'has-feedback':this.hasError('rack')})}>
                          <Label for="formRack" className="d-block">
                            <div className="d-flex" style={{justifyContent: 'space-between'}}>
                              <div>Rack</div>
                              {
                                this.state.rack_option !== "CUSTOM" ?
                                <div style={{marginLeft: "0.5rem"}}>
                                  {/* <Badge>CUSTOM</Badge> */}
                                  <span style={{marginLeft: "0.5rem", textDecoration: 'underline', cursor: "pointer", color: 'red', fontSize: '.75rem'}} onClick={() => this.setState({rack_option: "CUSTOM"})}>Make a new rack</span>
                                </div>
                                :
                                <div style={{marginLeft: "0.5rem"}}>
                                  {/* <Badge>SELECT</Badge> */}
                                  <span style={{marginLeft: "0.5rem", textDecoration: 'underline', cursor: "pointer", color: 'red', fontSize: '.75rem'}} onClick={() => this.setState({rack_option: "SELECT"})}>Select existing rack</span>
                                </div>
                              }
                            </div>
                          </Label>
                          {
                            this.state.rack_option !== "CUSTOM"?
                            <SelectRack
                              defaultOptions
                              id="_formSelectRack"
                              ref="selectRack"
                              value={this.state._form.rack}
                              paramsApi={{ limit: 50 }}
                              placeholder="Type and select a rack..."
                              noResultsText="Cannot find rack."
                              loadOptions={this._getRackOptions}
                              onSelectSearch={(input)=>this.setState({_recentSearchRack: input})}
                              onSelectChange={this._handleSelectRack}
                              onSelectOpen={this._handleOnSelectRackOpen}
                            />
                            :
                            <Input
                              type="text"
                              id="_formCustomRack"
                              name="rack_custom"
                              placeholder="Rack example: RACK-01"
                              value={this.getValue('rack_custom')}
                              onChange={this._handleTextChange}
                            />
                          }
                          {this.validateError('rack')}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} md={6}>
                        <FormGroup className={classnames({'has-danger':this.hasError('asking_price'), 'has-feedback':this.hasError('asking_price')})}>
                          <Label for="formAskingPrice">Asking Price</Label>
                          <Input type="text" name="asking_price" id="formAskingPrice"
                            value={this.getValue('asking_price')}
                            placeholder="Asking price in Rupiah Currency"
                            className="text-right"
                            onChange={this._handleTextChange}
                          />
                          {this.validateError('asking_price')}
                        </FormGroup>
                      </Col>
                      <Col xs={12} md={6}>
                        <FormGroup className={classnames({'has-danger':this.hasError('purchase_price'), 'has-feedback':this.hasError('purchase_price')})}>
                          <Label for="formPurchasePrice">Purchase Price</Label>
                          <Input type="text" name="purchase_price" id="formPurchasePrice"
                            value={this.getValue('purchase_price')}
                            placeholder="Purchase price in Rupiah Currency"
                            className="text-right"
                            onChange={this._handleTextChange}
                          />
                          {this.validateError('purchase_price')}
                        </FormGroup>
                      </Col>
                      <Col xs={12} md={6}>
                        <FormGroup className={classnames({'has-danger':this.hasError('quantity'), 'has-feedback':this.hasError('quantity')})}>
                          <Label for="formQuantity">Quantity</Label>
                          <Input type="number" name="quantity" id="formQuantity"
                            value={this.getValue('quantity')}
                            placeholder="Quantity"
                            onChange={this._handleTextChange}
                          />
                          {this.validateError('quantity')}
                        </FormGroup>
                      </Col>
                      <Col xs={12} md={6}>
                        <FormGroup className={classnames({'has-danger has-feedback':this.hasError('expiry')})}>
                          <Label for="formExpiryDate">
                            Expiry Date
                            <FontAwesomeIcon style={{margin: '0 .75rem 0 .5rem', cursor: 'pointer'}} iconType="question-circle" id="expirySellRequestToolTip"/>
                            <Tooltip placement="right" isOpen={this.state.expirySellRequestTooltipOpen} target="expirySellRequestToolTip" toggle={this._toggleExpiryToolTip}>
                              Kosongkan <i>Expiry</i> khusus sell request yang diinput melalui CMS atau khusus seller seller yang telah bekerja sama dengan <strong>KickAvenue</strong>.
                            </Tooltip>
                          </Label>
                          <InputDatetime
                            inputProps={{placeholder:'Pick a Expiry Date'}}
                            value={dateValue('expiry', this.state)}
                            onChange={(date)=>this._handleDatePickerChange(date,'expiry')}
                            timeFormat="HH:mm"
                          />
                          <div>
                            <Button
                              onClick={()=>this.setState({expiry: null})}
                              type="button"
                              style={{padding: 0, fontSize:'.75rem', textDecoration:'underline'}}
                              className="btn-link"
                              size="sm"
                            >
                              Kosongkan
                            </Button>
                            <Button
                              onClick={this._handleGenerateExpiryClick}
                              type="button"
                              style={{padding: 0, fontSize:'.75rem', textDecoration:'underline', marginLeft: ".5rem"}}
                              className="btn-link"
                              size="sm"
                            >
                              Generate Expiry
                            </Button>
                          </div>
                          {this.validateError('expiry')}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} md={6}>
                        <FormGroup className={classnames({'has-danger':this.hasError('sneakers_condition'), 'has-feedback':this.hasError('sneakers_condition')})}>
                          <Label for="formSneakerCondition">Listing Condition</Label>
                          <Input type="select" name="sneakers_condition" id="formSneakerCondition"
                            value={this.getValue('sneakers_condition')}
                            placeholder="Listing condition..."
                            onChange={this._handleTextChange}
                          >
                            {this.state.sneakersConditionOpt.map((item,index)=> <option value={item.value} key={index}>{item.label}</option>)}
                          </Input>
                          {this.validateError('sneakers_condition')}
                        </FormGroup>
                      </Col>
                      <Col xs={12} md={6}>
                        <FormGroup className={classnames({'has-danger':this.hasError('box_condition'), 'has-feedback':this.hasError('box_condition')})}>
                          <Label for="formBoxCondition">
                            Box Condition
                            {
                              this.state._listingCategory==='HANDBAGS' || this.state._form.disabled_box?
                                <span style={{fontSize: '.65rem', marginLeft: '5px'}}>(disabled)</span>: null
                            }
                          </Label>
                          {
                            this.state._listingCategory!=='HANDBAGS' && !this.state._form.disabled_box?(
                              <Input type="select" name="box_condition" id="formBoxCondition"
                                value={this.getValue('box_condition')}
                                placeholder="Box condition..."
                                onChange={this._handleTextChange}
                              >
                                {this.state.boxConditionOpt.map((item,index)=> <option value={item.value} key={index}>{item.label}</option>)}
                              </Input>
                            ): <p>-</p>
                          }
                          {this.validateError('box_condition')}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} md={6} className="d-flex align-items-center">
                        <FormGroupRadio className={classnames({'has-danger':this.hasError('pre_verified'), 'has-feedback':this.hasError('pre_verified')})}>
                          <Radio
                            type="checkbox"
                            name="pre_verified"
                            value={true}
                            checked={this.getValue('pre_verified')}
                            dataOnText="Yes"
                            dataOffText="No"
                            onChange={this._handlePreVerifiedCheckedChange}
                          />
                          <RadioText text="Pre-verified"/>
                          {this.validateError('pre_verified')}
                        </FormGroupRadio>
                      </Col>
                      <Col xs={12} md={6} className="d-flex align-items-center">
                        <FormGroup className={classnames({'has-danger':this.hasError('pre_order'), 'has-feedback':this.hasError('pre_order')})}>
                          <Radio
                            type="checkbox"
                            name="pre_order"
                            value={true}
                            checked={this.getValue('pre_order')}
                            dataOnText="Yes"
                            dataOffText="No"
                            onChange={this._handlePreOrderCheckedChange}
                          />
                          <RadioText text="Pre-order"/>
                          {this.validateError('pre_order')}
                        </FormGroup>
                      </Col>
                    </Row>
                    {
                      defectCount?(
                        <Row>
                          <Col xs={12}><p>Defects:</p></Col>
                          {
                            this.state._form.listingConditions.filter(condition => !condition.inclusions && !condition.missingAccessories).map((condition, index) => (
                              <Col xs={12} md={6} key={index} className="d-flex align-items-center">
                                <FormGroupRadio>
                                  <Radio
                                    type="checkbox"
                                    name={condition.name}
                                    value={true}
                                    checked={condition.value}
                                    dataOnText="Yes"
                                    dataOffText="No"
                                    onChange={e => this._handleConditionsCheckedChange(e, index)}
                                  />
                                  <RadioText text={condition.en}/>
                                </FormGroupRadio>
                              </Col>
                            ))
                          }
                        </Row>
                      ): null
                    }
                    {
                      missing && missingCount?(
                        <Row>
                          <Col xs={12}><p>Missing Accessories:</p></Col>
                          {this.state._form.listingConditions.filter(condition => condition.missingAccessories).map((condition, index) => (
                            <Col xs={12} md={6} key={index} className="d-flex align-items-center">
                              <FormGroupRadio>
                                <Radio
                                  type="checkbox"
                                  name={condition.name}
                                  value={true}
                                  checked={condition.value}
                                  dataOnText="Yes"
                                  dataOffText="No"
                                  onChange={e => this._handleConditionsCheckedChange(e, defectCount + index)}
                                />
                                <RadioText text={condition.en}/>
                              </FormGroupRadio>
                            </Col>
                          ))}
                        </Row>
                      ):null
                    }
                    {
                      this.state._form.listingConditions.filter(condition => condition.inclusions).length?(
                        <Row>
                          <Col xs={12}><p>Inclusions:</p></Col>
                          {
                            this.state._form.listingConditions.filter(condition => condition.inclusions).map((condition, index) => (
                              <Col xs={12} md={6} key={index} className="d-flex align-items-center">
                                <FormGroupRadio>
                                  <Radio
                                    type="checkbox"
                                    name={condition.name}
                                    value={true}
                                    checked={condition.value}
                                    dataOnText="Yes"
                                    dataOffText="No"
                                    onChange={e => this._handleConditionsCheckedChange(e, defectCount + index)}
                                  />
                                  <RadioText text={condition.en}/>
                                </FormGroupRadio>
                              </Col>
                            ))
                          }
                        </Row>
                      ): null
                    }
                    <FormGroup className={classnames({'has-danger':this.hasError('note'), 'has-feedback':this.hasError('note')})}>
                      <Label>Note</Label>
                      <Input type="textarea"
                        value={this.getValue('note')}
                        name="note"
                        placeholder="Put your note in here."
                        onChange={this._handleTextChange}
                      />
                      {this.validateError('note')}
                    </FormGroup>
                    <Row>
                      <Col xs={12} md={6}>
                        <FormGroup className={classnames({'has-danger':this.hasError('status'), 'has-feedback':this.hasError('status')})}>
                          <Label for="formStatus">Status</Label>
                          <Input type="select" name="status" id="formStatus"
                            value={this.getValue('status')}
                            onChange={this._handleTextChange}
                          >
                            {status.map((item,index)=> <option value={item.value} key={index}>{item.name}</option>)}
                          </Input>
                          {this.validateError('status')}
                        </FormGroup>
                      </Col>
                      {/* <Col xs={12} md={6}>
                        <FormGroup className={classnames({'has-danger':this.hasError('serial_number'), 'has-feedback':this.hasError('serial_number')})}>
                          <Label for="formSerialNumber">Serial Number</Label>
                          <Input type="text" name="serial_number" id="formSerialNumber"
                            value={this.getValue('serial_number')}
                            placeholder="Serial number"
                            onChange={this._handleTextChange}
                          />
                          {this.validateError('serial_number')}
                        </FormGroup>
                      </Col> */}
                    </Row>
                    <FormGroup>
                      <Button block color="primary" disabled={this.state._submit}>Submit</Button>
                    </FormGroup>
                  </Form>
                </Loading>
              </CardBody>
            </Card>
          </Col>
          <Col xs={12} md={6}>
            <StockImages/>
          </Col>
        </Row>
      </div>
    )
  }
}
const mapStateToProps=(state)=>{
  return {
    auth:state.auth,
    productVariant:state.productVariant,
    sellRequest:state.sellRequest,
    guardData: {
      isFetch: state.auth.isFetch,
      isLogged: state.auth.isLogged,
      roles: state.auth.roles,
      id: state.auth.id,
      email: state.auth.email
    },
  }
}
const mapDispatchToProps=(dispatch)=>{
  return {
    find:(id)=>dispatch(getDetailProductVariant(id)),
    submit:(payload)=>dispatch(createUserSell(payload)),
  }
}
const enhance = connect(mapStateToProps,mapDispatchToProps);
export default enhance(privateView(StockProductVariantForm,['supervisor','sale_supervisor','inventory_admin','cashier']));