import React, { PureComponent as Component } from "react";
import { Card, CardHeader, CardBody, Badge } from "reactstrap";
import classNames from "classnames";
import Loading from "react-loading-animation";
import propTypes from "prop-types";
import { _getValue } from "../../../utils/form";
import SneakerSize from "./SneakerSize";
import { BadgeFlexContainer, BadgeMedium } from "../../../styled/Badge";
import {
  FieldRow,
  FieldColumn,
  FieldLabel,
  FieldValue
} from "../../../styled/Fields";

export default class SneakerCondition extends Component {
  _renderContent() {
    const {
      userSell: {
        pre_order,
        pre_verified,
        size,
        missing_accessories,
        yellowing,
        display_item,
        sneakers_defect,
        hypequarter_display,
        defects,
      }
    } = this.props;
    return (
      <div>
        <FieldRow>
          <FieldColumn className="col-xs-12 col-md-6">
            <FieldLabel>Sell ID</FieldLabel>
            <FieldValue>
              <span>{this.props.userSell.id}</span>
            </FieldValue>
          </FieldColumn>
          {this.props.consignment.consignment_number ? (
            <FieldColumn className="col-xs-12 col-md-6">
              <FieldLabel>Consignment ID</FieldLabel>
              <FieldValue>
                <span>{this.props.consignment.consignment_number}</span>
              </FieldValue>
            </FieldColumn>
          ) : null}
        </FieldRow>
        <SneakerSize {...size} />
        <FieldRow>
          <FieldColumn className="col-xs-12 col-md-6">
            <FieldLabel>Box Condition</FieldLabel>
            <FieldValue>
              <span>{_getValue(this.props.userSell.box_condition)}</span>
            </FieldValue>
          </FieldColumn>
          <FieldColumn className="col-xs-12 col-md-6">
            <FieldLabel>Sneaker Condition</FieldLabel>
            <FieldValue>
              <span>{_getValue(this.props.userSell.sneakers_condition)}</span>
            </FieldValue>
          </FieldColumn>
          <FieldColumn className="col-12">
            <BadgeFlexContainer>
              <BadgeMedium>
                <Badge
                  color={pre_order ? "success" : "default"}
                >
                  <i
                    className={classNames("fa", {
                      "fa-times": !pre_order,
                      "fa-check": pre_order
                    })}
                  ></i>
                  &nbsp; PRE-ORDER
                </Badge>
              </BadgeMedium>
              <BadgeMedium>
                <Badge
                  color={pre_verified ? "success" : "default"}
                >
                  <i
                    className={classNames("fa", {
                      "fa-times": !pre_verified,
                      "fa-check": pre_verified
                    })}
                  ></i>
                  &nbsp; PRE-VERIFIED
                </Badge>
              </BadgeMedium>
              <BadgeMedium>
                <Badge color={(hypequarter_display?"success":"default")}>
                  <i
                    className={
                      classNames("fa",{
                        "fa-times":!hypequarter_display,
                        "fa-check":hypequarter_display})
                      }
                  >
                  </i>
                  &nbsp; HYPEQUARTER DISPLAY
                </Badge>
              </BadgeMedium>
              <BadgeMedium>
                <Badge color={(missing_accessories?"success":"default")}>
                  <i
                    className={
                      classNames("fa",{
                        "fa-times":!missing_accessories,
                        "fa-check":missing_accessories})
                      }
                  >
                  </i>
                  &nbsp; MISSING ACCESSORIES
                </Badge>
              </BadgeMedium>
              <BadgeMedium>
                <Badge color={(yellowing?"success":"default")}>
                  <i
                    className={
                      classNames("fa",{
                        "fa-times":!yellowing,
                        "fa-check":yellowing})
                      }
                  >
                  </i>
                  &nbsp; YELLOWING
                </Badge>
              </BadgeMedium>
              <BadgeMedium>
                <Badge color={(display_item?"success":"default")}>
                  <i
                    className={
                      classNames("fa",{
                        "fa-times":!display_item,
                        "fa-check":display_item})
                      }
                  >
                  </i>
                  &nbsp; DISPLAY ITEM
                </Badge>
              </BadgeMedium>
              <BadgeMedium>
                <Badge color={(sneakers_defect?"success":"default")}>
                  <i
                    className={
                      classNames("fa",{
                        "fa-times":!sneakers_defect,
                        "fa-check":sneakers_defect})
                      }
                  >
                  </i>
                  &nbsp; SNEAKER DEFECT
                </Badge>
              </BadgeMedium>
            </BadgeFlexContainer>
          </FieldColumn>
          <FieldColumn className="col-12">
            <FieldLabel>Inclusions</FieldLabel>
            <BadgeFlexContainer>
              {defects.filter(item => item).length?
                defects.filter(item => item).map((item, i) => (
                  <BadgeMedium key={i}>
                    <Badge
                      className="text-uppercase"
                      color={item.value ? "success" : "default"}
                    >
                      <i
                        className={classNames("mr-1 fa", {
                          "fa-times": !item.value,
                          "fa-check": item.value
                        })}
                      ></i>
                      {item.display_name}
                    </Badge>
                  </BadgeMedium>
                ))
                :'-'
              }
            </BadgeFlexContainer>
          </FieldColumn>
          <FieldColumn className="col-12">
            <FieldLabel>Note</FieldLabel>
            <FieldValue>
              <span>{_getValue(this.props.userSell.note)}</span>
            </FieldValue>
          </FieldColumn>
        </FieldRow>
      </div>
    );
  }
  render() {
    return (
      <Card>
        <CardHeader>
          {this.props.headerText}
          &nbsp;
          <Badge color={this.props.isLegit ? "success" : "default"}>
            <i
              className={classNames("fa", {
                "fa-times": !this.props.isLegit,
                "fa-check": this.props.isLegit
              })}
            ></i>
            &nbsp;{this.props.isLegit ? "LEGIT" : "NOT-LEGIT"}
          </Badge>
        </CardHeader>
        <CardBody>
          {(!this.props.isLoading && this._renderContent()) || <Loading />}
        </CardBody>
      </Card>
    );
  }
}

SneakerCondition.propTypes = {
  headerText: propTypes.string,
  isLoading: propTypes.bool,
  isLegit: propTypes.oneOfType([propTypes.bool, propTypes.number]),
  product: propTypes.shape({
    display_name: propTypes.string,
    pre_verified: propTypes.oneOfType([propTypes.number, propTypes.bool]),
    pre_order: propTypes.oneOfType([propTypes.number, propTypes.bool])
  })
};
SneakerCondition.defaultProps = {
  headerText: "Sneaker Condition",
  isLoading: false,
  isLegit: false,
  product: {
    display_name: "",
    pre_verified: false,
    pre_order: false,
    hypequarter_display: false,
    missing_accessories: false,
    yellowing: false,
    display_item: false,
    sneakers_defect: false,
    defects: []
  }
};
