import React from 'react';
import{
  Card,
  CardHeader,
  CardBody,
  Badge
} from 'reactstrap';
class StockImages extends React.Component{
  render(){
    return(
      <Card>
        <CardHeader><i className="fa fa-picture-o"></i> Images <Badge color="warning">Coming Soon</Badge></CardHeader>
        <CardBody></CardBody>
      </Card>
    )
  }
}
export default StockImages;