import React from 'react';
import classNames from 'classnames';
import styled from 'styled-components';
import { Row, Col } from "reactstrap";
import Paginations from '../../../components/Paginations';
import PaginationSummaries from '../../../components/Paginations/PaginationSummaries';
import SeekProductDetail from '../../SeekStock/seekList/SeekProductDetail';
import AlertNotFound from '../../../components/Card/AlertNotFound';
import { Radio, RadioText, FormGroupRadio } from '../../../components/Form/Radios';
import { getCacheImage } from '../../../utils/imageHelper';
import noImage from '../../../assets/img/no-image.png';
import { TextRupiah } from '../../../components/Text';
import { _getFloatValue } from "../../../utils/form";

const Wrapper = styled.div`
  display: flex;
  padding: .5rem 0px;
  border-bottom: 1px solid #ddd;
  margin-bottom: .5rem;
`
const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  justify-content: center;
  > img {
    height: 80px;
  }
`
const Description = styled.div`
  display: flex;
  margin-left: .5rem;
  width: 100%;
  flex-direction: column;
  p{
    margin: 0;
  }
`
export const ProductVariantItem = props => (
  <div>
    <Row>
      <Col xs={12} md={12}>
        <FormGroupRadio style={{marginLeft: '2px', marginBottom: 0, marginTop: '.25rem'}}>
          <Radio
            wrapperClassName="switch-danger"
            radioType="3d"
            size="xs"
            value={true}
            checked={props.checked}
            usingLabelText={false}
            onChange={props.onSelectedChange}
          />
          <div className="switch-label-wrapper" style={{fontSize: '.75rem'}}>
            <RadioText className="text-muted" text={`#${props.data.id}`}/>
            <b className="mr-1" style={{marginTop: '.2rem', marginBottom: '.5rem'}}>Retail Price:</b> <TextRupiah style={{marginTop: '.2rem', marginBottom: '.5rem'}} tag="span" prefix="Rp. " value={_getFloatValue(props.data.pivot.retail_price)}/>
          </div>
        </FormGroupRadio>
      </Col>
    </Row>
    <SeekProductDetail
      id={props.data.id}
      displayName={props.data.display_name}
      SKU={props.data.SKU}
      colour={props.data.colour}
      images={props.data.product_variant_images}
    />
  </div>
)

export const VoucherItem = props => {
  const thumb = props.data.img_url? props.img_url: noImage;
  return (
    <div>
      <FormGroupRadio style={{marginLeft: '2px', marginBottom: 0, marginTop: '.25rem'}}>
        <Radio
          wrapperClassName="switch-danger"
          radioType="3d"
          size="xs"
          value={true}
          checked={props.checked}
          usingLabelText={false}
          onChange={props.onSelectedChange}
        />
        <RadioText style={{fontSize: '.75rem'}} className="text-muted" text={`#${props.data.id}`}/>
      </FormGroupRadio>
      <Wrapper>
        <Content>
          <img alt="Image Thumbnail" src={getCacheImage(thumb)}/>
        </Content>
        <Description>
          <Content>
            <p>
              <span style={{textDecoration: 'underline'}}>{props.data.code}</span>
              <span className={classNames("text-muted ml-1", {'d-none': props.data.name===null||props.data.name===''})}>/{props.data.name}</span>
            </p>
            <p>{props.data.description}</p>
          </Content>
        </Description>
      </Wrapper>
    </div>
  )
}

const ItemList = props => (
  <div>
    <PaginationSummaries
      from={props.paginations.from}
      to={props.paginations.to}
      total={props.paginations.total}
    />
    <div style={{paddingTop: '.5rem', borderTop: '1px solid #ddd', position: 'relative'}}>
      {
        props.isLoading?(
          <div style={{position: 'absolute', width: '100%', zIndex: 5}}>
            <div style={{padding: '3px 6px', background: 'rgba(255,255,255,.85)', width: 150, textAlign: 'center', margin: 'auto'}}>
              Loading...
            </div>
          </div>
        ): null
      }
      <div style={{maxHeight: 400, overflow: 'auto'}}>
        {props.list.length === 0? (
          <div style={{padding: '0px .5rem'}}>
            <AlertNotFound message="No Record Found!"/>
          </div>
        ):null}
        {props.list.map(props.renderItems)}
      </div>
    </div>
    <div style={{padding: '6px 0px', position: 'relative'}}>
      <div style={{position: 'absolute', top: '-2px', zIndex: 10, background: '#FFF', boxShadow: '0px -5px 5px -3px rgba(0,0,0,0.5)', height: 8, width: '100%'}}></div>
      <Paginations
        size="sm"
        lastPage={props.paginations.lastPage}
        perPage={props.paginations.perPage}
        total={props.paginations.total}
        maxPage={8}
        currentPage={props.paginations.currentPage}
        handleGotoCallback={props.onGotoPage}
      />
    </div>
  </div>
)
export default ItemList;