import api from "../../../utils/Api";
import {AUTH_TOKEN_KEY} from "../../../constants";
import {useState} from "react";
import {manipulateErrors} from "../../../utils";

export const usePostPayment = () => {
    const [isFetch, setIsFetch] = useState(false);
    const [error, setError] = useState({error:{message:'I got something undefined errors',status_code:500}});
    const [data, setData] = useState({});

    const postPayment = (payload) => {
        setIsFetch(true);
        return api({
            Authorization:'Bearer '+localStorage.getItem(AUTH_TOKEN_KEY)
        })
            .post(`/admins/payments`, payload)
            .then((res) => {
                setIsFetch(false);
                setData(res.data.data);
                return res.data.data;
            })
            .catch((err) => {
                setIsFetch(false);
                setError(manipulateErrors(err));
                throw manipulateErrors(err);
            })
    };

    return {isFetch, error, postPayment, data};
}
