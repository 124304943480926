import React from 'react';
import propTypes from 'prop-types';
import { Card, CardHeader, CardBody, Button, Alert, Badge } from 'reactstrap';
import { connect } from 'react-redux';

import { Tbl, TblHead, TblBody } from '../../../components/Tbl';
import Paginations from '../../../components/Paginations';
import PaginationSummaries from '../../../components/Paginations/PaginationSummaries';
import { DEFAULT_SORT_BY } from '../../../constants/settings';
import { generateNumber } from '../../../utils';
import Analytics from "../../../services/Analytics";
import moment from 'moment';
import { getAuditList } from '../../../actions/auditAction';
import DateToHuman from '../../../components/DateToHuman';

const dataHeader = ["No.", "Tags", "Event", "Actor", "Before", "After", "Created At"]
class ConsignmentAuditCard extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      _refreshCount: 0,
      _params:{
        id: props.id,
        type: props.type,
        sort_by: DEFAULT_SORT_BY,
        per_page: 10
      }
    }
    this._handleGoto = this._handleGoto.bind(this);
  }

  componentDidMount(){
    this._getList({...this.state._params});
  }

  componentDidUpdate(prevProps) {
    if (prevProps.refresh !== this.props.refresh && this.props.refresh) {
      this._getList({...this.state._params})
    }
  }

  _getList(params){
    this.props.getList({ params })
  }

  _handleGoto(page){
    const _params=this.state._params;
    _params.page=page;
    this.setState({_params}, ()=>this._getList(this.state._params));
  }

  getEventBadge(event) {
    switch (event) {
      case 'created':
        return <Badge color="primary">CREATE</Badge>
      case 'attached':
        return <Badge color="primary">ATTACH</Badge>
      case 'updated':
        return <Badge color="success">UPDATE</Badge>
      case 'deleted':
        return <Badge color="danger">DELETE</Badge>
      case 'detached':
        return <Badge color="danger">DETACH</Badge>
    }
  }

  getTagText(val) {
    switch (val) {
      case 'brand':
        return 'BRAND'
      case 'brand_image':
        return 'BRAND IMAGE'
      case 'brand_voucher':
        return 'BRAND VOUCHER'

      default:
        return 'BRAND'
    }
  }

  onLoopObjectValue(object) {
    return Object.entries(object).map(([key, value]) => {
      if(key === 'consignment_approved_at') {
        const datetime = moment(object?.consignment_approved_at?.date);
        const convertDate = datetime.format("YYYY-MM-DD HH:mm:ss");

        return <p className="mb-1" key={key}><b>{key}</b>: {object?.consignment_approved_at?.date ? convertDate : '-'}</p>;
      } else {
        return <p className="mb-1" key={key}><b>{key}</b>: {value}</p>;
      }
    });
  }
  
  _renderRowItem(){
    if(this.props.audit.isFetch === false && this.props.audit.list.status_code===200){
      const { data, current_page, per_page } = this.props.audit.list;
      return data.map((item, index)=>{
        const rowNumber = generateNumber(per_page,current_page,index);
        return (
          <tr key={index}>
            <th scope="row">{index + 1}</th>
            <th><Badge color="secondary">{this.getTagText(item.tags)}</Badge></th>
            <td>{this.getEventBadge(item.event)}</td>
            <td>{item?.email}</td>
            <td style={{ maxWidth: '250px', overflow: 'auto', whiteSpace: 'normal' }}>{this.onLoopObjectValue(item.old_values)}</td>
            <td style={{ maxWidth: '250px', overflow: 'auto', whiteSpace: 'normal' }}>{this.onLoopObjectValue(item.new_values)}</td>
            <td>
              {
                <DateToHuman
                  value={item.created_at}
                  id={`tooltip_${index}`}
                />
              }
            </td>
          </tr>
        )
      })
    }
    return (
      <tr>
        <td colSpan={7} className='text-center'><b>Loading...</b></td>
      </tr>
    );
  }

  render(){
    const { audit } = this.props;
    const showPagination = (audit.list.status_code === 200 && audit.list.data.length)||null;
    return(
      <Card>
        <CardHeader>
          <i className="fa fa-align-justify"></i>
          Change Log
        </CardHeader>
        <CardBody>
          <div className="table-responsive">
            <PaginationSummaries
              from={audit.list.from}
              to={audit.list.to}
              total={audit.list.total}
            />
            <Tbl>
              <TblHead dataHeader={dataHeader}/>
              <TblBody
                isLoad={audit.list.isFetch}
                hasRow={audit.list.status_code===200 && audit.list.data.length}
                columnCount={dataHeader.length}
              >
              { this._renderRowItem() }
              </TblBody>
            </Tbl>
          </div>
          {
            showPagination&&
            <Paginations 
              size="sm" 
              lastPage={audit.list.last_page} 
              perPage={audit.list.per_page}
              total={audit.list.total}
              maxPage={8}
              path={audit.list.path}
              currentPage={audit.list.current_page} 
              prevPageUrl={audit.list.prev_page_url} 
              nextPageUrl={audit.list.next_page_url} 
              handleGotoCallback={this._handleGoto}
            />
          }
        </CardBody>
      </Card>
    )
  }
}
const mapStateToProps = ( { audit, auth: { email } })=>({
  audit: audit,
  guardData: {email}
})

const mapDispatchToProps = (dispatch)=>({
  getList: ( queries ) => dispatch(getAuditList(queries))
})

export default connect(mapStateToProps, mapDispatchToProps)(ConsignmentAuditCard)

ConsignmentAuditCard.propTypes = {
  id: propTypes.number,
  type: propTypes.string,
  refresh: propTypes.bool
}