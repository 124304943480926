import React, {useMemo} from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import { Badge } from 'reactstrap';
import classNames from 'classnames';
import FontAwesomeIcon from '../../../components/Icons/FontAwesomeIcon';
import {
  CANCELLED_STATUS,
	KA_RECEIVED_STATUS,
	VERIFICATION_PASSED_STATUS,
	VERIFICATION_FAILED_STATUS,
  DELIVERING_STATUS,
  REFUNDED_STATUS,
  NEW_STATUS,
  SELLER_SENT_STATUS,
  KA_RETURNED_SELLER_STATUS,
  ACTIVE_CONSIGNMENT_STATUS,
  CONSIGNMENT_REJECTED_STATUS,
  DONE_STATUS,
} from '../../../constants/kickavenue';

import { isCompletedSale } from '../../../helpers/regex';

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  padding-bottom: 1rem;
`;

const ContentInvoice = styled.div`
  flex: 1;
  > p {
    font-size: 18px;
    margin-bottom: 0px;
    ~ p {
      font-size: 10px;
    }
  }
`;

const ContentStatus = styled.div`
  font-size: 18px;
  flex: 1;
  text-align: right;
`;

const getColorStatus = status => {
  let color = 'warning';
  switch (status) {
    case 'Take Out Request':
    case 'Cancelled':
    case 'Cancelled (Forced)':
    case 'Refunded':
    case 'Verification Failed':
    case 'Returned to Seller':
    case 'Rejected Consignment':
      color='danger';break;
    case 'Sneaker On The Way':
    case 'Verification Progress':
    case 'Verification Passed':
    case 'Done':
    case 'Active Consignment':
      color='success';break;
    default: color='warning'; break;
  }
  if(isCompletedSale(status))
    color = 'success';
  return color;
}

const getConsignmentStatus = item => {
  if (item.force_cancelled_at !== null && item.status !== KA_RETURNED_SELLER_STATUS) {
    return 'Take Out Request';
  }
  if (item.status === NEW_STATUS) {
    return 'Waiting Seller Delivering';
  }
  if (item.status === SELLER_SENT_STATUS) {
    return 'Sneaker On The Way';
  }
  if (item.status === KA_RECEIVED_STATUS && item.ka_received != null) {
    return 'Verification Progress';
  }
  if (item.status === VERIFICATION_PASSED_STATUS && item.ka_verified != null) {
    return 'Verification Passed';
  }
  if (item.status === VERIFICATION_FAILED_STATUS) {
    return 'Verification Failed';
  }
  if (item.status === KA_RETURNED_SELLER_STATUS && item.ka_sent === null) {
    return 'Awaiting AWB Number';
  }
  if (item.status === DONE_STATUS && item.ka_sent != null) {
    return 'Returned to Seller';
  }
  if (item.status === CANCELLED_STATUS) {
    return item.force_cancelled_at != null ? 'Cancelled (Forced)' : 'Cancelled';
  }
  if (item.status === REFUNDED_STATUS && item.money_disbursed != null) {
    return 'Refunded';
  }
  if ((/^DONE_\w+/).test(item.status) || item.completed) {
    return 'Done';
  }
  if (item.status === KA_RETURNED_SELLER_STATUS) {
    return 'Returned to Seller';
  }
  if (item.status === ACTIVE_CONSIGNMENT_STATUS) {
    return 'Active Consignment'
  }
  if (item.status === CONSIGNMENT_REJECTED_STATUS) {
    return 'Rejected Consignment';
  } 
  return 'Undefined';
};

const isVisibleForceCancelled = (status) => {
  if(status === ACTIVE_CONSIGNMENT_STATUS) {
    return true;
  } else {
    return false;
  }
}

const isVisibleButtonRollback = (consignment, roles=[]) => {
  const validRoles = roles.length && (roles.includes('superadministrator') || roles.includes('administrator')); 
  const isCanAccess = roles.length > 0 ? validRoles && !consignment.money_disbursed : false;
  const isForceCancelled = consignment.force_cancelled_at !== null;
  if (!isCanAccess || isForceCancelled) return false;
  return (
    consignment.status === KA_RECEIVED_STATUS ||
    consignment.status === CANCELLED_STATUS ||
    consignment.status === VERIFICATION_FAILED_STATUS ||
    consignment.status === ACTIVE_CONSIGNMENT_STATUS ||
    (consignment.status === VERIFICATION_PASSED_STATUS &&
      consignment.ka_verified != null)
  );
}

const rollbackStatus = (status) => {
  if(status === KA_RECEIVED_STATUS) return  'Rollback to Sneaker On The way';
  if(status === VERIFICATION_PASSED_STATUS || status === VERIFICATION_FAILED_STATUS || status === CANCELLED_STATUS) return 'Rollback to Verification Progress';
  if(status === ACTIVE_CONSIGNMENT_STATUS) return 'Rollback to Rack Assignment';
  return '';
}

const ButtonStyle = styled.button`
  cursor: pointer;
  color: #f86c6b;
  font-size: .75rem;
  background: none;
  text-decoration: underline;
  border: none;
  padding: 0;
`;

const Header = props => {
  const consignmentStatus = useMemo(() => getConsignmentStatus(props.consignment), [props.consignment.status]);

  const badgeColor = props.status === 'KA_SENT_BUYER' && !props.kaSent
    ? 'warning'
    : getColorStatus(consignmentStatus);

  const badgeText = props.status === 'KA_SENT_BUYER' && !props.kaSent
    ? 'Awaiting AWB Number'
    : consignmentStatus;

  return (
  <HeaderWrapper>
    <ContentInvoice>
      <p>
        <FontAwesomeIcon iconType="check-square-o"/> { props.consignmentNumber }
      </p>
      <p style={{display: 'flex', alignItems: 'center'}}>
        {props.category&&(
          <Badge color="primary" className="text-uppercase mr-1">
            <FontAwesomeIcon iconType="tags" className="mr-1"/>{ props.category }
          </Badge>
        )}
        { props.productName } ({ props.createdAt })
      </p>
    </ContentInvoice>
    <ContentStatus>
      <Badge tag="div" color={badgeColor}>
        {badgeText}
      </Badge>
      <div>
        <ButtonStyle
          onClick={props.onForceCancelledClick}
          className={classNames({"d-none": !isVisibleForceCancelled(props.status)})}
          disabled={!isVisibleForceCancelled(props.status)}
        >
          Force Cancelled
        </ButtonStyle>
      </div>
      <div>
        <ButtonStyle
          onClick={props.onRollbackStatusClick}
          className={classNames({"d-none": !isVisibleButtonRollback(props.consignment, props.roles)})}
        >
          {rollbackStatus(props.status)}
        </ButtonStyle>
      </div>
    </ContentStatus>
  </HeaderWrapper>
)}

export default Header;

Header.propTypes = {
  consignment: propTypes.object,
  consignmentNumber: propTypes.string,
  productName: propTypes.string,
  createdAt: propTypes.string,
  status: propTypes.string,
  showButtonChangeSeller: propTypes.bool,
  forceCancelledAt: propTypes.string,
  onForceCancelledClick: propTypes.func
}

Header.defaultProps = {
  consignmentNumber: '-',
  productName: '-',
  createdAt: '-',
  status: 'NEW',
  showButtonChangeSeller: true,
  forceCancelledAt: null
}