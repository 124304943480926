import React from 'react';
import propTypes from 'prop-types';
import { TabPane } from 'reactstrap';
import OrderDetail from '../SaleDetails/OrderDetail';

const TabPaneOrderDetail = props => (
  <TabPane className="tab-content--custom__tab-pane" tabId={props.tabId}>
    <OrderDetail
      isLoading={props.isLoading}
      order={props.order}
      payment={props.payment}
      product={props.product}
      shipping={props.shipping}
      seller={props.seller}
    />
  </TabPane>
)

export default TabPaneOrderDetail;

TabPaneOrderDetail.defaultProps = {
  isLoading: false
}

TabPaneOrderDetail.propTypes = {
  tabId: propTypes.string.isRequired,
  isLoading: propTypes.bool,
  order: propTypes.object,
  payment: propTypes.object,
  shipping: propTypes.object,
  product: propTypes.object,
  seller: propTypes.object
}