import api from "../../../utils/Api";
import {AUTH_TOKEN_KEY} from "../../../constants";
import {useState} from "react";
import {manipulateErrors} from "../../../utils";

export const useFetchSetting = () => {
    const [isFetch, setIsFetch] = useState(false);
    const [error, setError] = useState({error:{message:'I got something undefined errors',status_code:500}});
    const [data, setData] = useState('[]');

    const fetchSetting = (name) => {
        setIsFetch(true);
        api({
            Authorization:'Bearer '+localStorage.getItem(AUTH_TOKEN_KEY)
        })
            .get(`/admins/settings?keyword=${name}&sort_by=updatedAt_desc`)
            .then((res) => {
                setIsFetch(false);
                setData(res.data.data.data[0].value);
            })
            .catch((err) => {
                setIsFetch(false);
                setError(manipulateErrors(err));
            })
    };

    return {isFetch, error, fetchSetting, data};
}
