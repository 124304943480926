import React from "react";
import { Col, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import { Link } from "react-router-dom";
import Style from "./ModalCreateRequest.module.css";
import { SimpleLineIcon } from "../../../../components/Icons";

const ModalCreateRequest = (props) => {
    const { onClose, ...otherProps } = props;

    return (
        <Modal {...otherProps}>
            <ModalHeader toggle={onClose}>
                <Row>
                    <Col>Create Request</Col>
                </Row>
            </ModalHeader>
            <ModalBody>
                <div className={Style["body-wrapper"]}>
                    <Row>
                        <Col>
                            <div>Which request you want to make?</div>
                        </Col>
                    </Row>
                    <div style={{ height: 16 }} />
                    <Row>
                        <Col style={{ paddingRight: 0 }}>
                            <Link
                                to="/sell_request/create?type=sell"
                                className={Style["button-wrapper"]}
                            >
                                <div>
                                    <SimpleLineIcon
                                        iconType="cloud-upload"
                                        className={Style["icon"]}
                                    />
                                </div>
                                <div style={{ height: 8 }} />
                                <div>Sell Request</div>
                            </Link>
                        </Col>
                        <Col>
                            <Link
                                to="/sell_request/create?type=consignment"
                                className={Style["button-wrapper"]}
                            >
                                <div>
                                    <SimpleLineIcon
                                        iconType="home"
                                        className={Style["icon"]}
                                    />
                                </div>
                                <div style={{ height: 8 }} />
                                <div>Consignment Request</div>
                            </Link>
                        </Col>
                    </Row>
                </div>
            </ModalBody>
        </Modal>
    );
};

ModalCreateRequest.defaultProps = {
    isOpen: true,
    size: "lg",
    centered: true,
    onClose: () => {},
};

export default ModalCreateRequest;
