import React from 'react';
import propTypes from 'prop-types';

import Badge from '../../../components/Badges/BadgeThin';
import FontAwesomeIcon from '../../../components/Icons/FontAwesomeIcon';
import Paragraph from '../../../styled/Typograph/Paragraph';
import SmallInline from '../../../styled/Typograph/Small/SmallInline';
// import ButtonStarred from './ButtonStarred';

const RackAssignment = ({ id, sellerEmail, size, displayName, SKU, quantity, colour, sneakersCondition, category, stockStatus, unreadNoteCount }) => {
  return (
    <Paragraph>
      {unreadNoteCount > 0 && <span style={{ fontSize: '.65rem' }} className="mr-1 text-danger">
        <FontAwesomeIcon iconType="circle" />
      </span>}
      <span className="text-muted">{`#${id}`}</span>
      <span> {displayName}</span><br />
      <SmallInline>({SKU})</SmallInline>
      <SmallInline className="text-muted">{colour}</SmallInline><br />
      <Badge
        color="primary"
        className="text-uppercase mr-1"
      >
        <FontAwesomeIcon iconType="tags" /> {category}
      </Badge>
      <Badge
        color="success"
        className="text-uppercase mr-1"
      >
        <FontAwesomeIcon iconType="tag" /> {sneakersCondition}
      </Badge>
      {
        stockStatus &&
        <Badge
          color="success"
          className="text-uppercase mr-1"
        >
          <FontAwesomeIcon iconType="tag" /> {stockStatus}
        </Badge>
      }
      <br />
      <Badge
        color="primary"
        className="text-lowercase mr-1"
      >
        {sellerEmail}
      </Badge>
      <Badge
        color="success"
        className="text-uppercase mr-1"
      >
        QTY: {quantity}
      </Badge>
      <Badge
        color="success"
        className="text-uppercase mr-1"
      >
        SIZE: {size}
      </Badge>
    </Paragraph>
  )
}

const Delivering = ({ id, sellerEmail, size, displayName, SKU, quantity, colour, sneakersCondition, category, stockStatus, legitCheckLogs, createdAt, unreadNoteCount }) => {
  return (
    <Paragraph>
      {unreadNoteCount > 0 && <span style={{ fontSize: '.65rem' }} className="mr-1 text-danger">
        <FontAwesomeIcon iconType="circle" />
      </span>}
      <span className="text-muted">{`#${id}`}</span>
      <span> {displayName}</span><br />
      <SmallInline>({SKU})</SmallInline>
      <SmallInline>(Quantity: {quantity})</SmallInline>
      <SmallInline className="text-muted">{colour}</SmallInline><br />
      <Badge
        color="primary"
        className="text-uppercase mr-1"
      >
        <FontAwesomeIcon iconType="tags" /> {category}
      </Badge>
      <Badge
        color="success"
        className="text-uppercase mr-1"
      >
        <FontAwesomeIcon iconType="tag" /> {sneakersCondition}
      </Badge>
      {
        stockStatus &&
        <Badge
          color="success"
          className="text-uppercase mr-1"
        >
          <FontAwesomeIcon iconType="tag" /> {stockStatus}
        </Badge>
      }
      <Badge
        color="success"
        className="text-uppercase mr-1"
      >
        SIZE: {size}
      </Badge>
      <br />
      <SmallInline>
        <span className="mr-1">
          <FontAwesomeIcon iconType="calendar" className="mr-1" />{createdAt}
        </span>
        - {sellerEmail} <b>(Seller)</b>

      </SmallInline>
      <br />
      {
        legitCheckLogs.map((item, index) => {
          return (
            <>
              <SmallInline key={index}>
                <span className="mr-1">
                  <FontAwesomeIcon iconType="calendar" className="mr-1" />{item.created_at}
                </span>
                - {item.user.email} <b>(LC Approval)</b>

              </SmallInline>
              <br />
            </>
          )
        })
      }
    </Paragraph>
  )
}

const DetailComponent = ({ id, sellerEmail, size, displayName, SKU, quantity, colour, consignmentId, sneakersCondition, category, stockStatus, unreadNoteCount }) => {
  return (
    <Paragraph>
      {unreadNoteCount ? (
        <span style={{ fontSize: '.65rem' }} className="mr-1 text-danger">
          <FontAwesomeIcon iconType="circle" />
        </span>
      ) : null}
      <span className="text-muted">{`#${id}`}</span>
      <span> {consignmentId}</span> <br />
      <span>{displayName}</span><br />
      <SmallInline>({SKU})</SmallInline>
      <SmallInline className="text-muted">{colour}</SmallInline><br />
      <Badge
        color="primary"
        className="text-uppercase mr-1"
      >
        <FontAwesomeIcon iconType="tags" /> {category}
      </Badge>
      <Badge
        color="success"
        className="text-uppercase mr-1"
      >
        <FontAwesomeIcon iconType="tag" /> {sneakersCondition}
      </Badge>
      {
        stockStatus &&
        <Badge
          color="success"
          className="text-uppercase mr-1"
        >
          <FontAwesomeIcon iconType="tag" /> {stockStatus}
        </Badge>
      }
      <br />
      <Badge
        color="primary"
        className="text-lowercase mr-1"
      >
        {sellerEmail}
      </Badge>
      <Badge
        color="success"
        className="text-uppercase mr-1"
      >
        QTY: {quantity}
      </Badge>
      <Badge
        color="success"
        className="text-uppercase mr-1"
      >
        SIZE: {size}
      </Badge>
    </Paragraph>
  )
}

const OrderSummariesColumn = props => {
  const { id, sellerEmail, size, displayName, SKU, quantity, colour, consignmentId, sneakersCondition, category, stockStatus, isRackAssignment, legitCheckLogs, isDelivering, createdAt, unreadNoteCount } = props;
  return (
    <>
      {
        isRackAssignment ?
          <RackAssignment
            id={id}
            sellerEmail={sellerEmail}
            size={size}
            displayName={displayName}
            SKU={SKU}
            quantity={quantity}
            colour={colour}
            consignmentId={consignmentId}
            sneakersCondition={sneakersCondition}
            category={category}
            stockStatus={stockStatus}
            legitCheckLogs={legitCheckLogs}
            createdAt={createdAt}
            unreadNoteCount={unreadNoteCount}
          />
          :
          (
            isDelivering ?
              <Delivering
                id={id}
                sellerEmail={sellerEmail}
                size={size}
                displayName={displayName}
                SKU={SKU}
                quantity={quantity}
                colour={colour}
                consignmentId={consignmentId}
                sneakersCondition={sneakersCondition}
                category={category}
                stockStatus={stockStatus}
                legitCheckLogs={legitCheckLogs}
                createdAt={createdAt}
                unreadNoteCount={unreadNoteCount}
              />
              :
              <DetailComponent
                id={id}
                sellerEmail={sellerEmail}
                size={size}
                displayName={displayName}
                SKU={SKU}
                quantity={quantity}
                colour={colour}
                consignmentId={consignmentId}
                sneakersCondition={sneakersCondition}
                category={category}
                stockStatus={stockStatus}
                legitCheckLogs={legitCheckLogs}
                createdAt={createdAt}
                unreadNoteCount={unreadNoteCount}
              />
          )
      }
    </>
  )
}

export default OrderSummariesColumn;

OrderSummariesColumn.defaultProps = {
  size: '-',
  SKU: '-',
  orderDate: '-',
  preOrder: false
}

OrderSummariesColumn.propTypes = {
  id: propTypes.number.isRequired,
  invoiceNumber: propTypes.string,
  buyerEmail: propTypes.string,
  displayName: propTypes.string,
  SKU: propTypes.string,
  colour: propTypes.string,
  size: propTypes.string,
  orderDate: propTypes.string,
  preOrder: propTypes.oneOfType([propTypes.number, propTypes.bool]),
  consignmentId: propTypes.string,
}