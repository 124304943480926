import get from 'lodash/get'

export const bankPromoListSelector = (state) => get(state, 'bankPromo.list') || {}
export const bankPromoIsFetchSelector = (state) => get(state, 'bankPromo.isFetch')
export const bankPromoIsSubmitSelector = (state) => get(state, 'bankPromo.isSubmit')
export const bankPromoIsDeleteSelector = (state) => get(state, 'bankPromo.isDelete')
export const bankPromoSubmitSuccessSelector = (state) => get(state, 'bankPromo.submitSuccess')
export const bankPromoDeleteSuccessSelector = (state) => get(state, 'bankPromo.deleteSuccess')
export const bankPromoErrorSelector = (state) => get(state, 'bankPromo.error')
export const bankPromoDetailSelector = (state) => get(state, 'bankPromo.detail')
export const bankPromoIsDeleteModalOpenSelector = (state) => get(state, 'bankPromo.isDeleteModalOpen')