import React, { useContext, useMemo } from 'react';
import { Col, Form, FormGroup, Input, Label, Row, Alert } from "reactstrap";
import { Link } from "react-router-dom";
import { useFetchProfile } from "../../../../reusables/hooks/fetch/useFetchProfile";
import { usePaymentForm } from "../Hooks/usePaymentForm";
import Select from "react-select";
import { AUTH_TOKEN_KEY, VOUCHER_COURIER_TYPE, VOUCHER_PRODUCT_TYPE } from "../../../../constants";
import api from "../../../../utils/Api";
import { parseInt } from "lodash";
import '../Style/InStoreTab.css';
import ButtonLoading from "../../../../components/Button/ButtonLoading";
import { useFetchSetting } from "../../../../reusables/hooks/fetch/useFetchSetting";
import { checkAuthorized, convertToRupiah, manipulateErrors } from "../../../../utils";
import { usePostVoucher } from "../../../../reusables/hooks/post/usePostVoucher";
import SelectVoucherInstore from "../../../../components/Form/Select/Async/SelectVoucherInstore";
import { PaymentContext } from "../Context/PaymentContext";
import { useFetchProvince } from "../../../../reusables/hooks/fetch/useFetchProvince";
import { useFetchCity } from "../../../../reusables/hooks/fetch/useFetchCity";
import { usePostShipping } from "../../../../reusables/hooks/post/usePostShipping";
import { parsePhone } from "../../../../utils/form";
import ProductCard from "./ModalSelectProduct/Components/ProductCard";
import { usePostPayment } from "../../../../reusables/hooks/post/usePostPayment";
import ModalDeleteProductConfirmation from "./ModalDeleteProductConfirmation";
import debounce from 'debounce-promise';
import { logout } from '../../../../utils/authorization';
import ModalTokenExpired from './ModalTokenExpired/ModalTokenExpired';
import ReactDOMServer from 'react-dom/server';
import Invoice from '../../Invoice/Invoice';
import { ValidationMessage } from '../../../../components/Form/ValidationMessage';
import { getProcessingFeeFromTotal } from '../../../../helpers/misc';

export function InStoreTab({ openModalProduct, pathname, history }) {
    const allowedPaymentMethods = ['qris_bca_edc', 'cc_bca_edc', 'cc_mandiri_edc', 'qris_mandiri_edc', 'cc_bni_edc', 'qris_bni_edc', 'qris_bri_edc', 'cc_bri_edc',
    'qris_bca_edc_ev', 'cc_bca_edc_ev', 'cc_mandiri_edc_ev', 'qris_mandiri_edc_ev', 'cc_bni_edc_ev', 'qris_bni_edc_ev', 'qris_bri_edc_ev', 'cc_bri_edc_ev', 'kredivo_in_store'];
    const {
        setVoucher,
        shippingFee,
        setShippingFee,
        product,
        setProduct,
        walletAmount,
        setWalletAmount,
        total,
        setTotal,
        setOfferAmount,
        setPoint,
        setCredit,
        subsidy,
        setSubsidy,
        discount,
        setDiscount,
        offerAmount,
        voucherApplied,
        setVoucherApplied,
        setAmountReceived,
        totalAdjusted,
        isCashback,
        setIsCashback,
        processingFeeOfflineTx,
    } = useContext(PaymentContext);

    const { } = useFetchProfile();
    const { fetchSetting, data: settingData } = useFetchSetting();
    const { fetchSetting: fetchInstorePayments, data: instorePayments } = useFetchSetting();
    const { postVoucher } = usePostVoucher();
    const { fetchProvince, data: provinceData } = useFetchProvince();
    const { fetchCity, data: citiesData } = useFetchCity();
    const { postShipping } = usePostShipping();
    const { postPayment } = usePostPayment();

    const { formik } = usePaymentForm();

    const [isLoadingUser, setIsLoadingUser] = React.useState(false);
    const [isCheckingVoucher, setIsCheckingVoucher] = React.useState(false);
    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const [selectUserFieldKey, setSelectUserFieldKey] = React.useState(Math.random() * 1000);

    const [isEmailNew, setIsEmailNew] = React.useState(false);
    const [isDisabledPhoneNumber, setIsDisabledPhoneNumber] = React.useState(false);

    const [isExpress, setIsExpress] = React.useState(true);
    const [selectedWarehouse, setSelectedWarehouse] = React.useState(null);
    const [isStandard, setIsStandard] = React.useState(false);

    const [useCredit, setUseCredit] = React.useState(false);
    const [usePoint, setUsePoint] = React.useState(false);
    const [fullWallet, setFullWallet] = React.useState(false);

    const [selectedUserId, setSelectedUserId] = React.useState(0);
    const [errorForm, setErrorForm] = React.useState({});

    const [showAlert, setShowAlert] = React.useState(false);
    const [alertMessage, setAlertMessage] = React.useState('');
    const [showSuccess, setShowSuccess] = React.useState(false);
    const [successMessage, setSuccessMessage] = React.useState('');

    const [isOpen, setIsOpen] = React.useState(false);
    const [selectedProduct, setSelectedProduct] = React.useState({});
    const [showExpiredModal, setShowExpiredModal] = React.useState(false);

    React.useEffect(() => {
        fetchSetting('warehouse_address');
    }, []);

    React.useEffect(() => {
        fetchInstorePayments('instore_payment_methods');
    }, []);

    React.useEffect(() => {
        if (showAlert) {
            setTimeout(() => {
                setAlertMessage('');
                setShowAlert(false);
            }, 3000);
        }
    }, [showAlert]);

    React.useEffect(() => {
        if (showSuccess) {
            setTimeout(() => {
                setSuccessMessage('');
                setShowSuccess(false);
            }, 3000);
        }
    }, [showSuccess]);

    React.useEffect(() => {
        if (product && product.length > 0) {
            if (product.find(element => !element.pre_verified)) {
                onClickStandardShipping();
            } else {
                onClickExpressShipping();
            }
            formik.setFieldValue('product', product);
            const total = product.reduce((acc, item) => {
                return acc + parseInt(item.asking_price);
            }, 0);
            const subsidy = product.reduce((acc, item) => {
                if (item.subsidy_price) {
                    return acc + parseInt(item.subsidy_price);
                }
                return acc + 0;
            }, 0);
            setTotal(total);
            setOfferAmount(total);
            setSubsidy(subsidy);
        }
    }, [product]);

    React.useEffect(() => {
        if (!formik.values.voucher) {
            setVoucher(null);
            setDiscount(0);
            setIsCashback(false);
            return;
        }
        setVoucher(formik.values.voucher);
        setDiscount(getVoucherAmount(formik.values.voucher, total, shippingFee));
        setIsCashback(formik.values.voucher.is_cashback);
    }, [formik.values.voucher]);

    React.useEffect(() => {
        let walletUsed = 0;
        const amountBeforeDiscount = parseInt(total) + 
            parseInt(shippingFee) + 
            getProcessingFeeFromTotal(parseInt(total), processingFeeOfflineTx) - 
            parseInt(subsidy);
        const amount = isCashback ? amountBeforeDiscount : amountBeforeDiscount - parseInt(discount);

        if (!formik.values.user) return;
        if (usePoint) {
            if (parseInt(formik.values.user.kick_point) < amount) {
                setFullWallet(false);
                walletUsed += parseInt(formik.values.user.kick_point);
            } else {
                setFullWallet(true);
                walletUsed = amount;
            }
            setPoint(walletUsed);
        } else {
            setPoint(0);
        }

        if (useCredit) {
            const balance = parseInt(formik.values.user.balance) + parseInt(formik.values.user.seller_balance);
            if (walletUsed + balance <= amount) {
                setFullWallet(false);
                walletUsed += balance;
                setCredit(balance);
            } else if (walletUsed === amount) {
                setFullWallet(true);
                setCredit(0);
            } else {
                setFullWallet(true);
                walletUsed = parseInt(amount) - walletUsed;
                setCredit(walletUsed);
            }
        } else {
            setCredit(0);
        }

        if (!useCredit && !usePoint) {
            setPoint(0);
            setCredit(0);
            setFullWallet(false);
        }

        setWalletAmount(walletUsed);
    }, [useCredit, usePoint, discount, shippingFee, total, subsidy]);

    React.useEffect(() => {
        if (fullWallet) {
            formik.setFieldValue('paymentMethod', 'full_wallet');
        } else {
            if (formik.values.paymentMethod === 'full_wallet') {
                formik.setFieldValue('paymentMethod', '');
            }
        }
    }, [fullWallet]);

    const _getUserOptions = (input) => {
        const myToken = localStorage.getItem(AUTH_TOKEN_KEY);
        const headers = {
            'Authorization': `Bearer ${myToken}`
        };
        const params = {
            keyword: input,
            // sort_by: 'balanceUpdatedAt_desc',
            // roles: 'user,unverified_user',
            // role_query: 'or',
            // scope: 'all',
            page: 1,
            per_page: 5,
        };
        setIsLoadingUser(true);
        return api(headers).get('/admins/users/search', { params })
            .then(({ data }) => {
                setIsLoadingUser(false);
                let res = data.data || [];
                return {
                    options: res.map(item => ({
                        id: item.id,
                        email: item.email,
                        balance: parseInt(item.balance),
                        seller_balance: parseInt(item.balance_with_fee),
                        kick_point: parseInt(item.locked_balance),
                        full_name: `${item.first_name}${item.family_name ? ' ' + item.family_name : ''}`
                    }))
                };
            })
            .catch(_err => {
                if (!checkAuthorized(manipulateErrors(_err))) {
                    setShowExpiredModal(true);
                }
            });
    };

    const getUserOptions = debounce(async (inputValue) => {
        return _getUserOptions(inputValue);
    }, 800);

    const getUserDefaultShippingAddress = (userId) => {
        const myToken = localStorage.getItem(AUTH_TOKEN_KEY);
        const headers = {
            'Authorization': `Bearer ${myToken}`
        };
        return api(headers).get('/admins/users/' + userId + '/shippings')
            .then(async ({ data }) => {
                const defaultShippingAddress = data.data.data.find(item => item.is_default && item.instore_shipping === 0);
                const provinceList = await fetchProvince();
                if (defaultShippingAddress) {
                    formik.setFieldValue('phoneNumber', defaultShippingAddress.phone_number);
                    setIsDisabledPhoneNumber(true);
                    if (!isExpress) {
                        const provinceId = provinceList.find(item => item.name === defaultShippingAddress.province).id;
                        const cityList = await fetchCity(defaultShippingAddress.province);
                        formik.setFieldValue('alias', defaultShippingAddress.alias);
                        formik.setFieldValue('mobileNumber', defaultShippingAddress.phone_number);
                        formik.setFieldValue('recipientName', defaultShippingAddress.full_name);
                        formik.setFieldValue('address', defaultShippingAddress.street_address);
                        formik.setFieldValue('province', provinceList.find(item => item.name === defaultShippingAddress.province));
                        formik.setFieldValue('city', cityList.find(item => item.name === defaultShippingAddress.city));
                        formik.setFieldValue('postalCode', defaultShippingAddress.postal_code);
                        formik.setFieldValue('shippingNote', defaultShippingAddress.note);
                        formik.setFieldValue('shippingId', defaultShippingAddress.id);
                        if (formik.values.product.length > 0) {
                            fetchShippingFee(provinceId);
                        }
                    }
                }
                return data;
            })
            .catch(_err => {
                if (!checkAuthorized(manipulateErrors(_err))) {
                    setShowExpiredModal(true);
                }
            });
    };

    const fetchShippingFee = async (provinceId) => {
        setShippingFee(
            await postShipping({
                destination: provinceId,
                product_variant_ids: product.map(item => item.product_variant.id),
                product_price: product.reduce((acc, item) => {
                    return acc + parseInt(item.asking_price);
                }, 0),
            })
        );
    };

    const resetForm = () => {
        formik.setFieldValue('user', null);
        formik.setFieldValue('phoneNumber', '');
        formik.setFieldValue('email', '');
        formik.setFieldValue('shippingMethod', '');
        formik.setFieldValue('referenceNumber', '');
        formik.setFieldValue('paymentMethod', '');
        formik.setFieldValue('amountReceived', 0);
        formik.setFieldValue('remarks', '');
        formik.setFieldValue('voucher', null);
        resetAddress();
        setIsDisabledPhoneNumber(false);
        setIsEmailNew(false);
        setUseCredit(false);
        setUsePoint(false);
        setIsStandard(false);
        setIsExpress(true);
        setSelectedUserId(0);
        setProduct([]);
        setTotal(0);
        setOfferAmount(0);
        setAmountReceived(0);
        setSubsidy(0);
        setDiscount(0);
        setPoint(0);
        setCredit(0);
        setWalletAmount(0);
        setFullWallet(false);
        setShippingFee(0);
        setVoucher(null);
        setSelectUserFieldKey(Math.random() * 1000);
    }

    const userChangeResetForm = () => {
        formik.setFieldValue('email', '');
        formik.setFieldValue('shippingMethod', '');
        formik.setFieldValue('referenceNumber', '');
        formik.setFieldValue('paymentMethod', '');
        formik.setFieldValue('amountReceived', '');
        formik.setFieldValue('remarks', '');
        formik.setFieldValue('phoneNumber', '');
        formik.setFieldValue('voucher', '');
        setIsDisabledPhoneNumber(false);
        setAmountReceived(0);
        resetAddress();
        setUseCredit(false);
        setUsePoint(false);
        setTotal(0);
        setSubsidy(0);
        setDiscount(0);
        setPoint(0);
        setCredit(0);
        setWalletAmount(0);
        setFullWallet(false);
        setShippingFee(0);
        setVoucher('');
    }

    const onUserSelectChange = (selectedOption) => {
        formik.setFieldValue('user', selectedOption);

        // reset related field when clearing the text field
        if (!selectedOption) {
            userChangeResetForm();
            return;
        }

        // reset related field when user change
        if (formik.values.user && selectedOption.id !== formik.values.user.id) {
            userChangeResetForm();
        }

        // user does not exist on our system
        if (selectedOption.id === selectedOption.email) {
            setIsEmailNew(true);
            return;
        }

        getUserDefaultShippingAddress(selectedOption.id);
        setSelectedUserId(selectedOption.id);
    };

    const handleTextChange = (e, key) => {
        formik.setFieldValue(key, e.target.value);
    };

    const resetAddress = () => {
        formik.setFieldValue('alias', '');
        formik.setFieldValue('recipientName', '');
        formik.setFieldValue('mobileNumber', '');
        formik.setFieldValue('address', '');
        formik.setFieldValue('province', '');
        formik.setFieldValue('city', '');
        formik.setFieldValue('postalCode', '');
        formik.setFieldValue('shippingNote', '');
        setSelectedWarehouse(null);
    }

    const handleSelectVoucher = (option) => {
        if (!option) {
            formik.setFieldValue('voucher', null);
            setVoucherApplied(false);
            return;
        }
        formik.setFieldValue('voucher', option);
    }

    const applyVoucher = () => {
        const amount = product.reduce((acc, item) => {
            return acc + parseInt(item.asking_price);
        }, 0);
        if (formik.values.voucher) {
            setIsCheckingVoucher(true);
            setErrorForm({
                errors: {
                    voucher: null
                },
                statusCode: 200
            });

            postVoucher({
                code: formik.values.voucher ? formik.values.voucher.code : null,
                user_id: selectedUserId !== 0 ? selectedUserId : null,
                total_amount: amount,
            }).then(() => {
                setVoucherApplied(true);
                setDiscount(getVoucherAmount(formik.values.voucher, amount, shippingFee));
                setIsCheckingVoucher(false);
            }).catch((err) => {
                setVoucher(null);
                formik.setFieldValue('voucher', null);
                setVoucherApplied(false);
                setIsCheckingVoucher(false);
                
                const errorMessage = err?.response?.data?.error?.message;
                const statusCode = err?.response?.data?.error?.status_code;
                if (!errorMessage) {
                    return;
                }
                setErrorForm({
                    errors: {
                        voucher: [errorMessage]
                    },
                    statusCode
                });
            });
        }
    }

    const getVoucherAmount = (voucherObj, price, courierPrice) => {
        let discount = 0;
        if (voucherObj !== null) {
            if (voucherObj.deduct_type === VOUCHER_PRODUCT_TYPE) {
                if (voucherObj.type === 'percentage') {
                    discount = parseInt(Math.ceil((voucherObj.amount / 100) * price));
                }
                else {
                    discount = voucherObj.amount;
                }
                discount = voucherObj.max_amount && parseInt(discount) > parseInt(voucherObj.max_amount) ?
                    voucherObj.max_amount : discount;
                return discount > price ? price : discount;
            }
            else if (voucherObj.deduct_type === VOUCHER_COURIER_TYPE) {
                if (voucherObj.type === 'percentage') {
                    discount = parseInt(Math.ceil((voucherObj.amount / 100) * courierPrice));
                }
                else {
                    discount = voucherObj.amount;
                }
                discount = voucherObj.max_amount && parseInt(discount) > parseInt(voucherObj.max_amount) ?
                    voucherObj.max_amount : discount;
                return discount > courierPrice ? courierPrice : discount;
            }
        }

        return discount;
    }

    const validatePayloads = () => {
        let objErrors = {};
        if (!formik.values.user) {
            objErrors['user_id'] = ["Please select a buyer."];
        }
        if (formik.values.product.length === 0) {
            objErrors['products'] = ["Please select a product."];
        }
        if (formik.values.alias === '') {
            objErrors['alias'] = ["Please insert alias."];
        }
        if (formik.values.fullName === '') {
            objErrors['full_name'] = ["Please insert recipient name."];
        }
        if (formik.values.phoneNumber === '') {
            objErrors['phone_number'] = ["Please insert phone number."];
        }
        if (formik.values.address === '') {
            objErrors['street_address'] = ["Please insert address."];
        }
        if (!formik.values.province) {
            objErrors['province'] = ["Please insert province."];
        }
        if (!formik.values.city) {
            objErrors['city'] = ["Please insert city."];
        }
        if (formik.values.postalCode === '') {
            objErrors['postal_code'] = ["Please insert postal code."];
        }
        if (formik.values.paymentMethod === '') {
            objErrors['payment_method'] = ["Please select payment method."];
        }
        if (formik.values.paymentMethod === 'cash' && formik.values.amountReceived === '') {
            objErrors['amount_received'] = ["Please insert amount received."];
        }
        if (formik.values.paymentMethod === 'cash' && parseInt(formik.values.amountReceived) === 0) {
            objErrors['amount_received'] = ["Please insert amount received."];
        }
        if (formik.values.paymentMethod === 'cash' && parseInt(formik.values.amountReceived) !== parseInt(totalAdjusted)) {
            objErrors['amount_received'] = ["Your received cash amount is not the same as the total price needed."];
        }
        if (allowedPaymentMethods.includes(formik.values.paymentMethod) && formik.values.referenceNumber === '') {
            objErrors['reference_number'] = ["Please insert reference number."];
        }
        if (Object.keys(objErrors).length) {
            setErrorForm({
                errors: objErrors,
                statusCode: 422
            });
        }
        return objErrors;
    };

    const submitForm = () => {
        const error = validatePayloads();
        const shippingDetails = {
            alias: formik.values.alias,
            full_name: formik.values.recipientName,
            phone_number: isExpress ? parsePhone(formik.values.phoneNumber, true) : parsePhone(formik.values.mobileNumber, true),
            street_address: formik.values.address,
            note: formik.values.shippingNote,
            province: formik.values.province.name,
            province_id: formik.values.province.id,
            city: formik.values.city.name,
            city_id: formik.values.city.id,
            postal_code: formik.values.postalCode,
            country: 'IDN',
            id: formik.values.shippingId,
        }

        if (isExpress) {
            shippingDetails['instore_shipping'] = true;
            shippingDetails['province'] = formik.values.province;
            shippingDetails['city'] = formik.values.city;
            delete shippingDetails.id;
        }

        const dataPayload = {
            user_id: formik.values.user.id,
            user_sell: formik.values.product.map((item) => {
                return {
                    id: item.id,
                    price: parseInt(item.asking_price),
                };
            }),
            shipping: shippingDetails,
            quantity: formik.values.product.length,
            currency: 'IDR',
            payment_method: fullWallet ? 'full_wallet' : formik.values.paymentMethod,
            ka_courier_price: shippingFee,
            offer_amount: offerAmount,
            ka_courier_option: isExpress ? 'WAREHOUSE' : 'FLAT_RATE',
            wallet_amount: useCredit || usePoint ? walletAmount : 0,
            point_enabled: usePoint,
            subsidy_price: subsidy,
            remarks: formik.values.remarks,
        }

        const payloads = {
            userId: dataPayload.user_id,
            userSellId: dataPayload.user_sell_id,
            paymentMethod: dataPayload.payment_method,
            courierPrice: dataPayload.ka_courier_price,
            offerAmount: dataPayload.offer_amount,
            uniqueAmount: dataPayload.unique_amount,
            adminFee: dataPayload.administration_fee,
            walletAmount: dataPayload.wallet_amount,
        }

        if (allowedPaymentMethods.includes(formik.values.paymentMethod)) {
            dataPayload['reference_number'] = formik.values.referenceNumber;
        }

        if (formik.values.voucher && voucherApplied) {
            dataPayload['voucher_code'] = formik.values.voucher.code;
            switch (formik.values.voucher.deduct_type) {
                case VOUCHER_PRODUCT_TYPE:
                    if (formik.values.voucher.is_cashback) break;
                    dataPayload['discount'] = discount;
                    break;
                case VOUCHER_COURIER_TYPE:
                    dataPayload['ka_courier_discount'] = discount;
                    break;
            }
        }

        const errors = Object.keys(error);

        if (errors.length) {
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: "smooth"
            });
            setAlertMessage(error[errors[0]]);
            setShowAlert(true);
            return;
        }

        setIsSubmitting(true);
        postPayment(dataPayload).then((res) => {
            if (res.payment_via === 'vt') {
                // open new tab for redirect to payment gateway
                if (res.payment_gateway.redirect_url) {
                    window.open(res.payment_gateway.redirect_url, '_blank');
                }
            }

            window.scrollTo({
                top: 0,
                left: 0,
                behavior: "smooth"
            });
            setShowSuccess(true);
            setSuccessMessage('Your payment has been successfully submitted.');

            setIsSubmitting(false);

            let sale = res
            sale.orders.map((val, key) => {
                val.sale_product = product[key]
            })
            onPrintInvoice(sale)
            resetForm();

        }).catch(err => {
            setIsSubmitting(false);
            let errMessage = 'Something went wrong, please try again later.';
            let errs = err.error ? err.error.errors : null;
            if (err.error && err.error.message) {
                errMessage = err.error.message;
            }
            if (errs) {
                const keys = Object.keys(errs);
                if (keys.length) {
                    errMessage = errs[keys[0]][0];
                }
            }
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: "smooth"
            });
            setAlertMessage(errMessage);
            setShowAlert(true);
        });
    };

    const onConfirmDeleteProduct = () => {
        const newProduct = product.filter((item) => {
            return item.id !== selectedProduct.id;
        });
        setProduct(newProduct);
        onCloseDeleteProduct();
    };

    const onDeleteProduct = (productSelected) => {
        setSelectedProduct(productSelected);
        setIsOpen(true);
    };

    const onCloseDeleteProduct = () => {
        setSelectedProduct({});
        setIsOpen(false);
    };

    const onClickExpressShipping = () => {
        formik.setFieldValue('voucher', null);
        setVoucher(null);
        if (product.length) {
            if (product.find(element => !element.pre_verified)) {
                return;
            }
        }
        setIsExpress(true);
        setIsStandard(false);
        resetAddress();
    };

    const onClickStandardShipping = async () => {
        await formik.setFieldValue('voucher', null);
        setVoucher(null);
        if (selectedUserId !== 0) {
            await getUserDefaultShippingAddress(selectedUserId);
        }
        setIsExpress(false);
        setIsStandard(true);
    };

    const invalidForm = useMemo(() => {
        const error = validatePayloads();

        const errors = Object.keys(error);

        return !!errors.length;
    }, [formik.values]);

    const onPrintInvoice = (payment) => {
        const newTab = window.open('', '_blank');
        const invoiceContent = ReactDOMServer.renderToString(
            <Invoice payment={payment} />
        );
        newTab.document.write(`
          <!DOCTYPE html>
          <html lang="en">
          <head>
            <meta charset="UTF-8">
            <title>INVOICE-${payment.transaction_number}</title>
          </head>
          <body>
            ${invoiceContent}
            <script>
              window.onload = function() {
                window.print();
              };
            </script>
          </body>
          </html>
        `);
        newTab.document.close();
    }

    return (
        <div>
            {showAlert && <Alert color="danger">
                {alertMessage}
            </Alert>}
            {showSuccess && <Alert color="success">
                {successMessage}
            </Alert>}
            <Form>
                <Row>
                    <Col xs={12}>
                        <FormGroup>
                            <Row>
                                <Col xs={6}>
                                    <Label>Product*</Label>
                                </Col>
                                {product.length ? <Col xs={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <Link to={'#'} onClick={(e) => {
                                        e.preventDefault();
                                        openModalProduct();
                                    }}>Add Product</Link>
                                </Col> : <div />}
                            </Row>
                            <Col style={{ padding: 0 }}>
                                {product.length ? <>
                                    {product.map((item, _) => {
                                        return <ProductCard
                                            key={`product-${item.id}`}
                                            data={item}
                                            formMode
                                            onClickDelete={onDeleteProduct}
                                        />
                                    })}
                                </> : <Link to={'#'} onClick={(e) => {
                                    e.preventDefault();
                                    openModalProduct();
                                }}>Select Product</Link>}
                            </Col>
                        </FormGroup>
                    </Col>
                    <Col xs={12}>
                        <FormGroup>
                            <Label>Buyer Email*</Label>
                            <Select.Async
                                key={selectUserFieldKey}
                                valueKey="id"
                                labelKey="email"
                                loadOptions={getUserOptions}
                                onChange={onUserSelectChange}
                                value={formik.values.user}
                                placeholder="Enter Buyer Email"
                                noResultsText="Cannot find email"
                            // promptTextCreator={promptTextCreator}
                            />
                        </FormGroup>
                    </Col>
                    <Col xs={12}>
                        <FormGroup>
                            <Label>Buyer Phone Number</Label>
                            <Input
                                disabled={isDisabledPhoneNumber}
                                name="phoneNumber"
                                value={formik.values.phoneNumber}
                                placeholder="Enter Phone Number"
                                onChange={(e) => handleTextChange(e, 'phoneNumber')}
                            />
                        </FormGroup>
                    </Col>
                    <Col xs={12}>
                        <FormGroup>
                            <Label>Shipping Method*</Label>
                            <Row>
                                <div className={isExpress ? 'selected-button-shipping' : product.length && product.find(element => !element.pre_verified) ? 'disabled-button-shipping' : 'button-shipping'} onClick={onClickExpressShipping}>
                                    <p>Express</p>
                                </div>
                                <div className={isStandard ? 'selected-button-shipping' : 'button-shipping'} onClick={onClickStandardShipping}>
                                    <p>Standard</p>
                                </div>
                            </Row>
                        </FormGroup>
                    </Col>
                    {isExpress && <Col xs={12}>
                        <FormGroup>
                            <Label>Warehouse*</Label>
                            <Select
                                valueKey="id"
                                labelKey="name"
                                options={JSON.parse(settingData)}
                                onChange={(e) => {
                                    if (!e) {
                                        resetAddress();
                                        return;
                                    }
                                    formik.setFieldValue('alias', e.alias);
                                    formik.setFieldValue('recipientName', e.full_name);
                                    formik.setFieldValue('address', e.street_address);
                                    formik.setFieldValue('province', e.province);
                                    formik.setFieldValue('city', e.city);
                                    formik.setFieldValue('postalCode', e.postal_code);
                                    formik.setFieldValue('shippingNote', e.note);
                                    formik.setFieldValue('shippingId', '');
                                    setSelectedWarehouse(e);
                                    setShippingFee(0);
                                }}
                                placeholder="Select Warehouse"
                                value={selectedWarehouse}
                            />
                        </FormGroup>
                    </Col>}
                    {isStandard && <>
                        <Col xs={12}>
                            <FormGroup>
                                <Label>Alias*</Label>
                                <Input
                                    name="alias"
                                    value={formik.values.alias}
                                    placeholder="Enter Alias Name"
                                    onChange={(e) => handleTextChange(e, 'alias')}
                                />
                            </FormGroup>
                        </Col>
                        <Row>
                            <Col xs={6}>
                                <FormGroup style={{ marginLeft: 15 }}>
                                    <Label>Recipient Name*</Label>
                                    <Input
                                        name="recipientName"
                                        value={formik.values.recipientName}
                                        placeholder="Enter Recipient Name"
                                        onChange={(e) => handleTextChange(e, 'recipientName')}
                                    />
                                </FormGroup>
                            </Col>
                            <Col xs={6}>
                                <FormGroup style={{ marginRight: 15 }}>
                                    <Label>Mobile Number*</Label>
                                    <Input
                                        name="mobileNumber"
                                        value={formik.values.mobileNumber}
                                        placeholder="Enter Mobile Number"
                                        onChange={(e) => handleTextChange(e, 'mobileNumber')}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Col xs={12}>
                            <FormGroup>
                                <Label>Address*</Label>
                                <Input
                                    type="textarea"
                                    name="address"
                                    value={formik.values.address}
                                    placeholder="Enter Address"
                                    onChange={(e) => handleTextChange(e, 'address')}
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={12}>
                            <Row>
                                <Col xs={6}>
                                    <FormGroup>
                                        <Label>Province*</Label>
                                        <Select
                                            valueKey="id"
                                            labelKey="name"
                                            options={provinceData}
                                            value={formik.values.province}
                                            placeholder="Enter Province"
                                            onChange={(e) => {
                                                // reset city and postal code when province change
                                                formik.setFieldValue('city', '');
                                                formik.setFieldValue('postalCode', '');
                                                if (!e) {
                                                    formik.setFieldValue('province', '');
                                                    return;
                                                }
                                                formik.setFieldValue('province', e);

                                                // fetch city data based on province
                                                fetchCity(e.name);

                                                // fetch shipping fee based on province
                                                fetchShippingFee(e.id);
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col xs={6}>
                                    <FormGroup>
                                        <Label>City*</Label>
                                        <Select
                                            valueKey="id"
                                            labelKey="name"
                                            options={citiesData}
                                            value={formik.values.city}
                                            placeholder="Enter City"
                                            onChange={(e) => {
                                                if (!e) {
                                                    formik.setFieldValue('city', '');
                                                    return;
                                                }
                                                formik.setFieldValue('city', e);
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={6}>
                            <FormGroup>
                                <Label>Postal Code*</Label>
                                <Input
                                    name="postalCode"
                                    value={formik.values.postalCode}
                                    placeholder="Enter Postal Code"
                                    onChange={(e) => handleTextChange(e, 'postalCode')}
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={12}>
                            <FormGroup>
                                <Label>Shipping Note</Label>
                                <Input
                                    type="textarea"
                                    name="shippingNote"
                                    value={formik.values.shippingNote}
                                    placeholder="Enter Shipping Note"
                                    onChange={(e) => handleTextChange(e, 'shippingNote')}
                                />
                            </FormGroup>
                        </Col>
                    </>}
                    {(!isEmailNew && !!formik.values.user) && <Col xs={12}>
                        <Row>
                            <Col xs={6}>
                                <FormGroup>
                                    <Row style={{ whiteSpace: 'nowrap' }}>
                                        <Col xs={8}>
                                            <Label>Use Credit</Label>
                                            <p style={{ fontSize: 14 }}>Balance: <b style={{ fontSize: 12, textOverflow: 'ellipsis' }}>Rp {convertToRupiah(parseInt(formik.values.user.balance) + parseInt(formik.values.user.seller_balance))}</b></p>
                                        </Col>
                                        <Col xs={4}>
                                            <label className="switch switch-text switch-success-outline-alt">
                                                <input
                                                    type="checkbox"
                                                    className="switch-input"
                                                    name="credit"
                                                    id="use_credit"
                                                    checked={useCredit}
                                                    disabled={!formik.values.user || (formik.values.user.balance + formik.values.user.seller_balance) < 0}
                                                    onChange={(e) => {
                                                        if (!formik.values.user) return;
                                                        if ((formik.values.user.balance + formik.values.user.seller_balance) <= 0) return;
                                                        setUseCredit(e.target.checked);
                                                    }}
                                                />
                                                <span
                                                    className="switch-label"
                                                    data-on="On"
                                                    data-off="Off"
                                                ></span>
                                                <span className="switch-handle"></span>
                                            </label>
                                        </Col>
                                    </Row>
                                </FormGroup>
                            </Col>
                            <Col xs={6}>
                                <FormGroup>
                                    <Row style={{ whiteSpace: 'nowrap' }}>
                                        <Col xs={8}>
                                            <Label>Use Kick Points</Label>
                                            <p style={{ fontSize: 14 }}>Balance: <b style={{ fontSize: 12, textOverflow: 'ellipsis' }}>Rp {convertToRupiah(parseInt(formik.values.user.kick_point))}</b></p>
                                        </Col>
                                        <Col xs={4}>
                                            <label className="switch switch-text switch-success-outline-alt">
                                                <input
                                                    type="checkbox"
                                                    className="switch-input"
                                                    name="point"
                                                    id="use_point"
                                                    checked={usePoint}
                                                    disabled={!formik.values.user || formik.values.user.kick_point < 0}
                                                    onChange={(e) => {
                                                        if (!formik.values.user) return;
                                                        if (formik.values.user.kick_point <= 0) return;
                                                        setUsePoint(e.target.checked);
                                                    }}
                                                />
                                                <span
                                                    className="switch-label"
                                                    data-on="On"
                                                    data-off="Off"
                                                ></span>
                                                <span className="switch-handle"></span>
                                            </label>
                                        </Col>
                                    </Row>
                                </FormGroup>
                            </Col>
                        </Row>
                    </Col>}
                    <Col xs={12}>
                        <Row>
                            <Col md={6}>
                                <FormGroup>
                                    <Label>Payment Method*</Label>
                                    <Select
                                        disabled={fullWallet}
                                        valueKey="value"
                                        labelKey="name"
                                        options={JSON.parse(instorePayments)}
                                        value={formik.values.paymentMethod}
                                        onChange={(e) => {
                                            if (!e) {
                                                setAmountReceived(0);
                                                formik.setFieldValue('amountReceived', 0);
                                                formik.setFieldValue('paymentMethod', '');
                                                return;
                                            }
                                            setAmountReceived(0);
                                            formik.setFieldValue('paymentMethod', e.value);
                                            formik.setFieldValue('amountReceived', 0);
                                        }}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label>{formik.values.paymentMethod === 'cash' ? 'Amount Received*' : 'Reference Number'}</Label>
                                    {formik.values.paymentMethod !== 'cash' ? <Input
                                        disabled={!allowedPaymentMethods.includes(formik.values.paymentMethod)}
                                        name="referenceNumber"
                                        value={formik.values.referenceNumber}
                                        placeholder="Enter Reference Number"
                                        onChange={(e) => {
                                            handleTextChange(e, 'referenceNumber');
                                            setAmountReceived(0);
                                            formik.setFieldValue('amountReceived', 0);
                                        }}
                                    /> : <Input
                                        name="amountReceived"
                                        value={formik.values.amountReceived}
                                        placeholder="Enter Amount Received"
                                        onChange={(e) => {
                                            handleTextChange(e, 'amountReceived');
                                            setAmountReceived(parseInt(e.target.value));
                                        }}
                                    />}
                                </FormGroup>
                            </Col>
                        </Row>
                    </Col>
                    {(selectedUserId && !usePoint) ? <Col xs={12}>
                        <Row>
                            <Col xs={9}>
                                <FormGroup>
                                    <Label>Voucher</Label>
                                    <SelectVoucherInstore
                                        id="_formSelectVoucher"
                                        value={formik.values.voucher}
                                        paramsApi={{ sort_by: 'createdAt_desc' }}
                                        placeholder="Select a voucher and click check..."
                                        noResultsText="Cannot find voucher."
                                        onSelectChange={handleSelectVoucher}
                                        userId={selectedUserId}
                                    />
                                    {errorForm?.errors?.voucher?.[0] ? (
                                        <ValidationMessage message={errorForm?.errors?.voucher?.[0]} />
                                    ) : null}
                                </FormGroup>
                            </Col>
                            <Col xs={3} style={{ paddingTop: 29 }}>
                                <ButtonLoading
                                    isLoading={isCheckingVoucher}
                                    disabled={isCheckingVoucher || !formik.values.voucher}
                                    onClick={applyVoucher}
                                    loadingMessage="Checking..."
                                    color={isCheckingVoucher || !formik.values.voucher ? "secondary" : "primary"}
                                >
                                    Check
                                </ButtonLoading>
                            </Col>
                        </Row>
                    </Col> : <div />}
                    <Col xs={12}>
                        <FormGroup>
                            <Label>Remarks</Label>
                            <Input
                                type="textarea"
                                name="remarks"
                                value={formik.values.remarks}
                                placeholder="Enter Remarks"
                                onChange={(e) => handleTextChange(e, 'remarks')}
                            />
                        </FormGroup>
                    </Col>
                </Row>
            </Form>
            <p className={'required-remark'}>(*) this field is required.</p>
            <ButtonLoading
                isLoading={isSubmitting}
                disabled={isSubmitting || invalidForm}
                loadingMessage="Submitting..."
                color="primary"
                onClick={submitForm}
            >
                Submit
            </ButtonLoading>
            <ModalDeleteProductConfirmation
                isOpen={isOpen}
                onClose={() => { setIsOpen(false) }}
                onConfirm={onConfirmDeleteProduct}
                productName={selectedProduct.hasOwnProperty('product_variant') ? selectedProduct.product_variant.display_name : ""}
            />
            <ModalTokenExpired isOpen={showExpiredModal} toggle={() => setShowExpiredModal(value => !value)} onClosed={() => logout(pathname, history)} />
        </div>
    );
}
