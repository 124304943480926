import React from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Modal,
  ModalBody,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  FormGroup,
  Col
} from 'reactstrap';
import { getCollectionList } from '../../../actions/collectionAction';
import { FontAwesomeIcon } from '../../../components/Icons';
import Paginations from '../../../components/Paginations';
import PaginationSummaries from '../../../components/Paginations/PaginationSummaries';
import AlertNotFound from '../../../components/Card/AlertNotFound';
import Paragraph from '../../../styled/Typograph/Paragraph';
import SmallInline from '../../../styled/Typograph/Small/SmallInline';
import BadgeThin from '../../../components/Badges/BadgeThin';

const ItemList = props => (
  <div>
    <PaginationSummaries
      from={props.paginations.from}
      to={props.paginations.to}
      total={props.paginations.total}
    />
    <div style={{paddingTop: '.5rem', borderTop: '1px solid #ddd', position: 'relative'}}>
      {
        props.isLoading?(
          <div style={{position: 'absolute', width: '100%', zIndex: 5}}>
            <div style={{padding: '3px 6px', background: 'rgba(255,255,255,.85)', width: 150, textAlign: 'center', margin: 'auto'}}>
              Loading...
            </div>
          </div>
        ): null
      }
      <div style={{maxHeight: 400, overflow: 'auto'}}>
        {props.list.length === 0? (
          <div style={{padding: '0px .5rem'}}>
            <AlertNotFound message="No Record Found!"/>
          </div>
        ):null}
        {props.list.map(props.renderItems)}
      </div>
    </div>
    <div style={{padding: '6px 0px', position: 'relative'}}>
      <div style={{position: 'absolute', top: '-2px', zIndex: 10, background: '#FFF', boxShadow: '0px -5px 5px -3px rgba(0,0,0,0.5)', height: 8, width: '100%'}}></div>
      <Paginations
        size="sm"
        lastPage={props.paginations.lastPage}
        perPage={props.paginations.perPage}
        total={props.paginations.total}
        maxPage={8}
        currentPage={props.paginations.currentPage}
        handleGotoCallback={props.onGotoPage}
      />
    </div>
  </div>
)

const defaultParams = {
  per_page: 15,
  page: 1,
  sort_by: 'updatedAt_desc',
}

const defaultPaginations = {
  from: 0,
  to: 0,
  total: 0,
  currentPage: 0,
  lastPage: 0,
  perPage: 0
}

class ModalCollections extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      _params: {
        ...defaultParams
      },
      paginations: {
        ...defaultPaginations
      },
      list: []
    }
  }

  componentDidUpdate(prevProps){
    if(!prevProps.isOpen && this.props.isOpen){
      this._getDataList(this.state._params)
      // const { categoryId } = this.props;
      // this.setState({
      //   _params:{
      //     ...this.state._params,
      //     category_id: categoryId
      //   }
      // }, () => {
      //   this._getDataList(this.state._params)
      // })
    }
    if(prevProps.dataProductCollection.isFetch
      && !this.props.dataProductCollection.isFetch
      && this.props.isOpen){
      const { error } = this.props.dataProductCollection;
      if(!error){
        const { data, ...paginations } = this.props.dataProductCollection.data;
        if(data.length === 0 && paginations.current_page > 1){
          this._handleGoto(paginations.last_page? paginations.last_page: 1);
        }
        else{
          this.setState({
            list: [...data],
            paginations: {
              ...this.state.paginations,
              from: paginations.from,
              to: paginations.to,
              total: paginations.total,
              currentPage: paginations.current_page,
              lastPage: paginations.last_page,
              perPage: paginations.per_page
            }
          })
        }
      }
    }
  }

  _sanitizeParams = rawParams => {
    return {
      params: Object.keys(rawParams)
      .filter(key => rawParams[key]!='')
      .reduce((obj, current)=> ({...obj, [current]: rawParams[current]}), [])
    }
  }

  _getDataList = (rawParams = {}) => {
    this.props.getCollectionList(
      this._sanitizeParams(rawParams)
    )
  }

  _handleGoto = page => {
    const _params = this.state._params;
    _params.page = page;
    this.setState({ _params },
      ()=> this._getDataList(this.state._params)
    );
  }

  render(){
    return(
      <Modal isOpen={this.props.isOpen} toggle={this.props.toggle} size={this.props.size}>
        <ModalBody>
          <Form
            style={{marginBottom: '.5rem'}}
            onSubmit={e => {
              e.preventDefault()
              this._getDataList(this.state._params);
            }}
          >
            {/* <FormGroup row style={{marginBottom: '.5rem'}}>
              <Col sm={4}>
                <Input
                  bsSize="sm"
                  type="select"
                  name="category_id"
                  value={this.state._params.category_id||""}
                  onChange={e => {
                    this.setState({
                      _params:{
                        ...this.state._params,
                        [e.target.name]: e.target.value
                      }
                    })
                  }}
                >
                  <option value="">All Category</option>
                  {this.props.dataCategories.map(category => (
                    <option key={category.value} value={category.value}>{category.label}</option>
                  ))}
                </Input>
              </Col>
            </FormGroup> */}
            <InputGroup>
              <Input
                bsSize="sm"
                placeholder="Search collections..."
                value={this.state._params.keyword||''}
                name="keyword"
                onChange={e => {
                  this.setState({
                    _params:{
                      ...this.state._params,
                      [e.target.name]: e.target.value
                    }
                  })
                }}
              />
              <InputGroupAddon addonType="append">
                <Button color="primary">Search</Button>
              </InputGroupAddon>
            </InputGroup>
          </Form>
          <div style={{display: 'flex', justifyContent: 'flex-end', marginBottom: '1rem', alignItems: 'center'}}>
            <Button
              outline
              title="Refresh list"
              color="primary"
              style={{fontSize: '.5rem'}}
              size="sm"
              type="button"
              onClick={() => this._handleGoto(1)}
            >
              <FontAwesomeIcon iconType="refresh"/>
            </Button>
          </div>
          <ItemList
            isLoading={this.props.dataProductCollection.isFetch}
            paginations={this.state.paginations}
            list={this.state.list}
            renderItems={item => (
              <button title="Click to select..." key={item.id} style={{background: 'none', width: '100%', border: 'none', padding: '6px 12px', textAlign: 'left', borderBottom: '1px solid #ddd'}} className="mb-1" onClick={()=>this.props.onSelectedItem(item)}>
                <Paragraph>
                  <span className="text-muted">#{item.id}</span> {item.name} {item.collection_type? (
                  <BadgeThin>{item.collection_type==='product_variants'? 'variants': item.collection_type}</BadgeThin>)
                  :null}
                </Paragraph>
                {item.categories.filter(category => category.pivot.active).length?(
                  <Paragraph>
                    {item.categories.filter(category => category.pivot.active).map((category) => (
                      <BadgeThin className="text-uppercase mr-1" color="primary" key={category.id}>
                        <FontAwesomeIcon iconType="tags" className="mr-1"/>{ category.name }
                      </BadgeThin>
                    ))}
                  </Paragraph>
                ):null}
                <SmallInline>
                  {item.peoples?(
                    <BadgeThin className="mr-1" color="success">
                      <FontAwesomeIcon iconType="user"/> peoples
                    </BadgeThin>
                  ):null}
                  <span className="mr-1">
                    <FontAwesomeIcon iconType="calendar" className="mr-1"/>{ item.created_at }
                  </span>
                  ({item.collection_type==='product_variants'?item.collection_variants_count:(item.collection_type==='brands'? item.collection_brands_count:0)} items)
                </SmallInline>
              </button>
            )}
            onGotoPage={this._handleGoto}
          />
        </ModalBody>
      </Modal>
    )
  }
}

const mapStateToProps= ({ masterCollection }) => ({
  dataProductCollection: {
    isFetch: masterCollection.isFetch,
    data: masterCollection.list,
    error: masterCollection.error
  }
});
const mapDispatchToProps = (dispatch) => ({
  getCollectionList: (query) => dispatch(getCollectionList(query))
});
export default connect(mapStateToProps,mapDispatchToProps)(ModalCollections);