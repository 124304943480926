import {
  CONCIERGE_ROUTE_API,
	GET_LIST_CONCIERGE,
	FETCHING_CONCIERGE,
	GET_DETAIL_CONCIERGE,
	UPDATE_CONCIERGE,
	CREATE_CONCIERGE,
	CONCIERGE_ERROR,
	AUTH_TOKEN_KEY,
} from '../constants';
import { logout } from './authAction';
import api from '../utils/Api';
import { manipulateErrors, checkAuthorized } from '../utils';


export const getConciergeList=(query)=>{
	return dispatch =>(
		dispatch(isFetch({type:'fetch',status:true})),
		api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).get(CONCIERGE_ROUTE_API,query)
		.then(res=>{
			dispatch(onSuccess(res.data,GET_LIST_CONCIERGE))
			dispatch(isFetch({type:'fetch',status:false}))
		})
		.catch(err => {
			dispatch(onError(err));
			dispatch(isFetch({type:'fetch',status:false}));
		})
	)
}

export const getDetailConcierge=(id)=>{
	return dispatch =>(
		dispatch(isFetch({type:'fetch',status:true})),
		api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).get(`${CONCIERGE_ROUTE_API}/${id}`)
		.then(res=>{
			dispatch(onSuccess(res.data,GET_DETAIL_CONCIERGE))
			dispatch(isFetch({type:'fetch',status:false}))
		})
		.catch(err => {
			dispatch(onError(err));
			dispatch(isFetch({type:'fetch',status:false}));
		})
	)
}

export const createConcierge = (payload) =>{
	return dispatch => (
		dispatch(isFetch({type:'submit',status:true})),
		api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`,
			"Content-Type": "multipart/form-data"
		}).post(`${CONCIERGE_ROUTE_API}`,payload)
		.then(res=>{
			dispatch(onSuccess(res.data,CREATE_CONCIERGE))
			dispatch(isFetch({type:'submit',status:false}))
		})
		.catch(err => {
			dispatch(onError(err));
			dispatch(isFetch({type:'submit',status:false}));
		})
	)
}

export const updateConcierge = (id,payload) =>{
	return dispatch => (
		dispatch(isFetch({type:'submit',status:true})),
		api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).put(`${CONCIERGE_ROUTE_API}/${id}`,payload)
		.then(res=>{
			dispatch(onSuccess(res.data,UPDATE_CONCIERGE))
			dispatch(isFetch({type:'submit',status:false}))
		})
		.catch(err => {
			dispatch(onError(err));
			dispatch(isFetch({type:'submit',status:false}));
		})
	)
}

export const isFetch=(bool)=>(
	{
		type:FETCHING_CONCIERGE,
		payload:bool		
	}
)

export const onSuccess=(res,type)=>
(
	{
		type:type,
		payload:res		
	}
)

export const onError=(err, type = CONCIERGE_ERROR)=>{
	if(!checkAuthorized(manipulateErrors(err)))
		return logout();
	return {
		type,
		payload:manipulateErrors(err)
	}
}