import React from 'react';
import {
  Card,
  CardImg,
  CardText,
  CardLink,
  CardBody,
} from 'reactstrap';
import propTypes from 'prop-types';
import styled from 'styled-components';
import { getCacheImage } from '../../../../../../utils/imageHelper'

const CardFooter = styled.div`
  justify-content: center;
  display: flex;
  align-items: center;
`;

class ImageCard extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      id: props.id,
    }
  }

  render(){
    return(
      <Card body  style={{'padding': 0}}>
        <CardImg src={getCacheImage(this.props.imageUrl)} alt={this.props.alt}/>
        <CardBody style={{paddingTop: 0}}>
          <CardText>
            <small className="text-muted">Last updated {this.props.updated_at}</small>
          </CardText>
          <CardFooter>
            <CardLink href={getCacheImage(this.props.imageUrl)} className="text-default text-center" target="_blank"><i className="fa fa-external-link"></i> Open</CardLink>
          </CardFooter>
        </CardBody>
      </Card>
    )
  }
}

export default ImageCard;
ImageCard.defaultProps = {
  error: null,
  statusCode: null
}
ImageCard.propTypes = {
  id: propTypes.oneOfType([propTypes.number, propTypes.string]),
  URL: propTypes.string,
  imageUrl: propTypes.string,
  updated_at:propTypes.string,
  alt:propTypes.string,
  statusCode: propTypes.oneOfType([propTypes.number, propTypes.string]),
  error: propTypes.object,
}
