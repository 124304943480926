import React from "react";

import SearchForm from "./SearchForm";
import { CardBody, Collapse } from "reactstrap";
import propTypes from "prop-types";

const Filters = ({ isOpen, keyword, onChangeKeyword, onSubmitSearch }) => {
  return (
    <Collapse isOpen={isOpen}>
      <CardBody className="filter-container__card-body">
        <SearchForm
          keyword={keyword}
          onChangeKeyword={onChangeKeyword}
          onSubmitSearch={onSubmitSearch}
        />
        <hr />
      </CardBody>
    </Collapse>
  );
};

Filters.propTypes = {
  isOpen: propTypes.bool.isRequired,
};

Filters.defaultProps = {
  isOpen: false,
};

export default Filters;
