import React from 'react';
import { connect } from 'react-redux';
import { AppSectionForm } from './AppSectionForm';
import privateView from '../../components/hocs/privateView';
import Analytics from "../../services/Analytics";

class AppSectionDetail extends React.Component{
  componentDidMount(){
    const { email } = this.props.injectedProps;
    Analytics.recordPageView('app_section_detail', email, {
      url: window.location.href,
      location: this.props.location,
      match: this.props.match
    });
  }
  render(){
    return(
      <div className="animate fadeIn">
        <AppSectionForm
          title="App Section Details"
          edit
          {...this.props}
        />
      </div>
    )
  }
}

const mapStateToProps = ({auth: { isFetch, roles, isLogged, id, email } })=>({
  guardData: { isFetch, roles, isLogged, id, email }
})

const enhance = connect(mapStateToProps);

export default enhance(privateView(AppSectionDetail, ['supervisor', 'sale_supervisor']));