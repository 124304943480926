import { useEffect, useState } from "react";
import api from "../../../../utils/Api";
import { AUTH_TOKEN_KEY } from "../../../../constants";
import { checkAuthorized, manipulateErrors } from "../../../../utils";

const useFetchProducts = (props) => {
    const {
        keyword,
        status,
        condition,
        size,
        category_id,
        initialPage = 1,
        dataPerPage = 10,
        sort = "updatedAt_desc",
        showExpiredModal,
    } = props;
    const [page, setPage] = useState(initialPage);
    const [perPage, setPerPage] = useState(dataPerPage);
    const [isError, setIsError] = useState(false);
    const [isFetching, setIsFetching] = useState(false);
    const [products, setProducts] = useState([]);
    const [response, setResponse] = useState();
    const [statusCode, setStatusCode] = useState();

    const handleParamsStatus = (params) => {
        if (status === "consignment") {
            Object.assign(params, { pre_verified: 1, pre_order: false });
        }

        if (status === "pre_order") {
            Object.assign(params, { pre_order: 1, pre_verified: false });
        }

        if (status === "standard") {
            Object.assign(params, { pre_verified: false, pre_order: false });
        }
    };

    const handleResetPagination = (params) => {
        Object.assign(params, { page: 1 });
        setPage(1);
    };

    const handleCustomParams = (params, customParams) => {
        Object.assign(params, customParams);
    };

    const query = (headers, params) => {
        return api(headers)
            .get("/admins/sells", { params })
            .then((response) => {
                setIsError(false);
                setStatusCode(response.status);
                return {
                    isSuccess: true,
                    response: response.data.data,
                };
            })
            .catch((err) => {
                if (!checkAuthorized(manipulateErrors(err))) {
                    showExpiredModal();
                }
                const response = err.response;
                setStatusCode(response?.status || 500);
                setIsError(true);
                setIsFetching(false);
                return {
                    isSuccess: false,
                    response: response?.data || null,
                };
            });
    };

    const fetch = async (resetPagination = false, customParams = null) => {
        setIsFetching(true);
        const myToken = localStorage.getItem(AUTH_TOKEN_KEY);
        const headers = {
            Authorization: `Bearer ${myToken}`,
        };

        const availables = true;
        const sneakers_condition = condition;
        const per_page = perPage;
        const sort_by = sort;
        const without_append = true;

        const params = {
            availables,
            keyword,
            sneakers_condition,
            size,
            category_id,
            page,
            per_page,
            sort_by,
            without_append,
        };

        handleParamsStatus(params);

        if (resetPagination) {
            handleResetPagination(params);
        }

        if (customParams) {
            handleCustomParams(params, customParams);
        }

        const fetchQuery = await query(headers, params);

        if (!fetchQuery.isSuccess) {
            setResponse(fetchQuery.response);
            setProducts([]);
            return;
        }

        setResponse(fetchQuery.response);
        setProducts(fetchQuery.response.data);
        setIsFetching(false);
    };

    useEffect(() => {
        return () => {
            setIsError(false);
            setIsFetching(false);
            setProducts([]);
            setResponse(undefined);
        };
    }, []);

    useEffect(() => {
        if (page === initialPage && !products.length) {
            return;
        }

        fetch();
    }, [page, perPage]);

    return {
        isError,
        isFetching,
        products,
        response,
        page,
        perPage,
        setPage,
        setPerPage,
        reFetch: fetch,
        setProducts,
        setResponse,
    };
};

export default useFetchProducts;
