import styled from 'styled-components';

const FieldValue = styled.div`
  padding: .5rem 1rem;
  border:1px solid #ddd;
  background: #f5f5f5;
  min-height: 39px;
  width: 100%;
  > span {
    display: block;
    &.break-all{
      word-break: break-all;
    }
  }
  &:hover{
    border:1px solid #8ad4ee;
    cursor: text
  }
`
export default FieldValue;