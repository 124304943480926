import React, { Component } from 'react';
import { CardBody, Collapse } from 'reactstrap';
import propTypes from 'prop-types';

import SearchForm from './SearchForm';
import SortForm from './SortForm';
import FilterForm from './FilterForm';

export default class Filters extends Component {
  constructor(props){
    super(props);
    this.state = {
      keyword: props.keyword,
      perPage: props.per_page,
      sortBy: props.sort_by,
      paymentMethod: props.payment_method,
      status: props.status
    }
    this._handleOnChangeInput = this._handleOnChangeInput.bind(this);
    this._handleApplyFilterButtonClick = this._handleApplyFilterButtonClick.bind(this);
    this._handleOnChangeInputAndApply = this._handleOnChangeInputAndApply.bind(this);
    this._handleSearchButtonClick = this._handleApplyFilterButtonClick.bind(this);
  }

  _handleOnChangeInput(e){
    const lastState = {...this.state};
    lastState[e.target.name] = e.target.value;
    this.setState({...lastState});
  }

  _handleOnChangeInputAndApply(e){
    const lastState = {...this.state};
    lastState[e.target.name] = e.target.value;
    this.setState({...lastState}, ()=> this._handleSubmitFilter());
  }

  _handleApplyFilterButtonClick(e){
    e.preventDefault();
    this._handleSubmitFilter();
  }

  _handleSubmitFilter(){
    const { keyword, perPage, sortBy, paymentMethod, status } = this.state;
    this.props.onApplyFilter({
      keyword,
      per_page: perPage,
      sort_by: sortBy,
      payment_method: paymentMethod,
      status
    });
  }

  componentDidUpdate(nextProps){
    if(!nextProps.isReset && this.props.isReset!==nextProps.isReset){
      const { keyword, per_page, sort_by, payment_method, status } = this.props;
      this.setState({
        keyword,
        perPage: per_page,
        sortBy: sort_by,
        status,
        paymentMethod: payment_method
      })
      this.props.onResetFilterCallback();
    }
  }

  render(){
    return(
      <Collapse isOpen={this.props.isOpen}>
        <CardBody className="filter-container__card-body">
          <SearchForm
            keyword = {this.state.keyword}
            onInputChange = {this._handleOnChangeInput}
            onSubmit = {this._handleSearchButtonClick}
          />
          <FilterForm
            payment_method = {this.state.paymentMethod}
            status = {this.state.status}
            onInputChange = {this._handleOnChangeInput}
            onSubmit = {this._handleApplyFilterButtonClick}
          />
          <hr/>
          <SortForm
            per_page={this.state.perPage}
            sort_by={this.state.sortBy}
            onInputChange={this._handleOnChangeInputAndApply}
          />
        </CardBody>
      </Collapse>
    )
  }
}

Filters.propTypes= {
  keyword: propTypes.string,
  per_page: propTypes.oneOfType([propTypes.number, propTypes.string]),
  sort_by: propTypes.string,
  payment_method: propTypes.string,
  status: propTypes.string,
  isOpen: propTypes.bool.isRequired,
  isReset: propTypes.bool,
  onApplyFilter: propTypes.func,
  onResetFilterCallback: propTypes.func
}

Filters.defaultProps= {
  keyword: '',
  per_page: 15,
  sort_by: '',
  payment_method: '',
  status: '',
}