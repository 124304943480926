import React, { useState } from 'react';
import { Card, CardBody, CardHeader, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import FontAwesomeIcon from "../../../../components/Icons/FontAwesomeIcon";
import { InStoreTab } from "./InStoreTab";
import { OnlineTab } from "./OnlineTab";
import { ClientTab } from "./ClientTab";

export function PaymentForm({ openModalProduct, pathname, history }) {
    const [activeTab, setActiveTab] = useState('instore');

    return (
        <Card>
            <CardHeader>
                <FontAwesomeIcon iconType="align-justify" /> Create Payment
            </CardHeader>
            <CardBody>
                <Nav tabs>
                    <NavItem>
                        <NavLink
                            active={activeTab === 'instore'}
                            onClick={() => setActiveTab('instore')}
                        >
                            Instore
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            disabled={true}
                            active={activeTab === 'online'}
                            onClick={() => setActiveTab('online')}
                        >
                            Online
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            disabled={true}
                            active={activeTab === 'client'}
                            onClick={() => setActiveTab('client')}
                        >
                            Client
                        </NavLink>
                    </NavItem>
                </Nav>
                <TabContent activeTab={activeTab}>
                    <TabPane tabId="instore">
                        <InStoreTab openModalProduct={openModalProduct} pathname={pathname} history={history} />
                    </TabPane>
                    <TabPane tabId="online">
                        <OnlineTab />
                    </TabPane>
                    <TabPane tabId="client">
                        <ClientTab />
                    </TabPane>
                </TabContent>
            </CardBody>
        </Card>
    );
}
