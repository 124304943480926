import React from 'react';
import propTypes from 'prop-types';
import { TabPane } from 'reactstrap';
import SellerDetail from '../SaleDetails/SellerDetail';

const TabPaneSellerDetail = props => (
  <TabPane className="tab-content--custom__tab-pane" tabId={props.tabId}>
    <SellerDetail
      isLoading={props.isLoading}
      seller={props.seller}
      // dataBankAccount={this.props.userBankAccount}
      // handleGotoPageBankAccountList={this._handleGotoPageSellerBankAccount}
    />
  </TabPane>
)

export default TabPaneSellerDetail;

TabPaneSellerDetail.defaultProps = {
  isLoading: false
}

TabPaneSellerDetail.propTypes = {
  tabId: propTypes.string.isRequired,
  isLoading: propTypes.bool,
  seller: propTypes.object,
}