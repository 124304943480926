import React from 'react';
import propTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Badge } from 'reactstrap';

import Paragraph from '../../../styled/Typograph/Paragraph';
import SmallInline from '../../../styled/Typograph/Small/SmallInline';
import FontAwesomeIcon from '../../../components/Icons/FontAwesomeIcon';
import BadgeThin from '../../../components/Badges/BadgeThin';
import { DISBURSEMENT_BALANCE_IN, DISBURSEMENT_BALANCE_OUT } from '../helpers';

const DisbursementColumn = props => {
  console.log('items --->', props.items)
  const items = props.items
  .filter(item => ["SALE", "OFFER", "TOPUP", "BID"].includes(item.item_type))
  .map(item => ({ invoice_number: item.disbursementable ? item.disbursementable.ref_number : '-', item_type: item.item_type==='OFFER'? 'PAYMENT' : item.item_type }));
  return(
    <div>

      <Paragraph>
        <span style={{marginRight: 5}} className="text-muted">#{props.id}</span>
        {props.disbursementNumber}
      </Paragraph>
      <Paragraph>
        <Badge>
          <FontAwesomeIcon style={{marginRight: 5}} iconType="user"/>
          {props.userId? <Link style={{color: '#fff', textDecoration: 'underline'}} className="link-default" to={`/users/${props.userId}`}>{props.userEmail}</Link>: ' - '}
        </Badge>{' '}
        {items.length? <span style={{marginLeft: 5, fontSize: '.65rem'}}>({items[0].invoice_number})</span>: null}
      </Paragraph>
      <Paragraph>
        <Badge color={props.disbursementType==='BALANCE_OUT'?"success": "primary"}>
          <FontAwesomeIcon style={{marginRight: 5}} iconType="exchange"/>
          {props.disbursementType==='BALANCE_OUT'?'BALANCE OUT':'BALANCE IN'}
        </Badge>{' '}
        <SmallInline>
          <FontAwesomeIcon style={{marginRight: 5}} iconType="calendar"/>
          {props.createdAt}
        </SmallInline>{' '}
        <span className={props.itemCount?"":"d-none"} style={{fontSize: ".75rem"}}>{props.itemCount} items</span>
      </Paragraph>
      {
        props.disbursementType==='BALANCE_OUT'?(
          <Paragraph>
            <BadgeThin color={props.payoutType==='MIDTRANS'?"success": "primary"}>
              <FontAwesomeIcon style={{marginRight: 5}} iconType="fa fa-feed"/>
              {props.payoutType}
            </BadgeThin>{' '}
            <BadgeThin>
              <FontAwesomeIcon style={{marginRight: 5}} iconType="bookmark"/>
              REF. NO: {props.referenceNo? props.referenceNo: ' - '}
            </BadgeThin>
            <br/>
            <BadgeThin className="text-uppercase">
              bank: {props.bank && props.bank.bank_name_recipient? props.bank.bank_name_recipient: '-'}
            </BadgeThin>
          </Paragraph>
        )
        :null
      }
    </div>
  )
}
export default DisbursementColumn;

DisbursementColumn.defaultProps = {
  itemCount: 0,
  items: [],
  disbursementType: DISBURSEMENT_BALANCE_IN
}

DisbursementColumn.propTypes = {
  id: propTypes.number.isRequired,
  disbursementNumber: propTypes.string,
  userId: propTypes.number,
  userEmail: propTypes.string,
  itemCount: propTypes.number,
  items: propTypes.arrayOf(propTypes.object),
  createdAt: propTypes.string,
  disbursementType: propTypes.oneOf([DISBURSEMENT_BALANCE_IN, DISBURSEMENT_BALANCE_OUT])
}