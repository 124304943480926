import React from 'react';
import propTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Button,
  ButtonGroup
} from 'reactstrap';
import { Link } from 'react-router-dom';
import ReactLoading from 'react-loading';
import DateToHuman from '../../../components/DateToHuman';
import { TextRupiah } from '../../../components/Text';
import { updateSale, doMarkedSale, getSaleNotes, setReadSaleNotes, getDetailSale2, getTrackingShipmentDetail } from '../../../actions/saleAction';
import { textValue, checkChange } from '../../../utils/form';
import OrderSummariesColumn from '../RowItem/OrderSummariesColumn';
import ButtonChangeSeller from '../RowItem/ButtonChangeSeller';
import SmallInline from '../../../styled/Typograph/Small/SmallInline';
import Timer from './Timer';
import moment from 'moment';
import { getPaymentType, getPaymentVia } from '../../../constants/kickavenue';
import { ModalMarkedSale, ModalTrackingShipment } from '../../../components/Modals';
import ButtonStarred from '../RowItem/ButtonStarred';
import { FontAwesomeIcon } from '../../../components/Icons';
import { determineAutoCancelSwitchValue } from '../../../helpers/misc';

class RowItem extends React.Component{
  constructor(props){
    super(props);
    this.state={
      autoDisburse: props.autoDisburse,
      _autoDisburseEdit: props.autoDisburse,
      autoCancel: props.autoCancel?false:true,
      _autoCancelEdit: determineAutoCancelSwitchValue(props.autoCancel, props.turnOnAutoCancelEnabled),
      updatedAt: props.updatedAt,
      createdAt: props.orderDate,
      isCancel: false,
      hour: null,
      min: null,
      sec: null,
      marked: props.marked,
      _markedEdit: props.marked,
      _isModalMarked: false,
      markedAt: props.markedAt || '',
      markedBy: props.markedBy || '',
      closeMarkedAt: props.closeMarkedAt || '',
      closeMarkedBy: props.closeMarkedBy || '',
      notes: props.notes || [],
      notesPage: 1,
      noteNextPage: null,
      unreadNoteCount: props.unreadNoteCount || 0,
      isConfirmation: false,
      _isModalTracking: false,
      shippingUpdate: null
    }
    this._handleAutoCancelChange = this._handleAutoCancelChange.bind(this);
    this._handleCheckedChange = this._handleCheckedChange.bind(this);
    this._handleMarkedChange = this._handleMarkedChange.bind(this);
    this._handleModalMarkedOnSubmit = this._handleModalMarkedOnSubmit.bind(this);
    this._handleTrackingChange = this._handleTrackingChange.bind(this);
    this._timer = this._timer.bind(this);
  }

  componentDidMount() {
    this._countdown = setInterval(this._timer, 1000);
  }

  componentWillUnmount() {
    clearInterval(this._countdown);
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevProps.sale.isSubmit !== this.props.sale.isSubmit && prevProps.sale.isSubmit){
      if(prevProps.sale.error===null){
        const { id, autodisburse, auto_cancelled_at, turn_on_auto_cancel_enabled, updated_at, marked, open_marked_at, open_marked_by, sale_notes, unread_notes_count, close_marked_at, close_marked_by } = this.props.sale.detail;
        if(prevProps.id===id){
          let autoCancelEdit=determineAutoCancelSwitchValue(auto_cancelled_at, turn_on_auto_cancel_enabled);
          this.setState({
            autoDisburse: autodisburse,
            _autoDisburseEdit: autodisburse,
            autoCancel:autoCancelEdit,
            _autoCancelEdit:autoCancelEdit,
            marked: marked,
            _markedEdit: marked,
            // markedNotes: marked_notes || '',
            markedAt: open_marked_at || '',
            markedBy: open_marked_by || '',
            closeMarkedAt: close_marked_at || '',
            closeMarkedBy: close_marked_by || '',
            // notes: sale_notes,
            // unreadNoteCount: unread_notes_count,
            updatedAt: updated_at
          })
        }
      }
      else{
        const { id } = this.props.sale.error;
        if(id && this.props.id === id){
          this.setState({
            _autoDisburseEdit: this.state.autoDisburse,
            _autoCancelEdit:this.state.autoCancel,
            _markedEdit: this.state.marked,
          })
        }
      }
    }

    if(prevProps.sale.notes.isFetch && !this.props.sale.notes.isFetch){
      const { list: { data, next_page_url, error }, saleId } = this.props.sale.notes;
      if(this.props.id === saleId){
        if(error) {
          alert('Error while fetching notes');
        } else{
          const { loginId } = this.props;
          const ids = data.filter(item => !item.read && item.user_id !== loginId).map(({ id }) => id);
          if(ids.length){
            this.props.setReadSaleNotes(this.props.id, { ids })
          }
          this.setState({
            notes: this.state.notesPage === 1? [...data]: [...data, ...this.state.notes],
            noteNextPage: next_page_url? this.state.notesPage + 1: null
          })
        }
      }
    }

    if(prevProps.sale.saleDetail.isFetch && !this.props.sale.saleDetail.isFetch){
      const { error, data } = this.props.sale.saleDetail;
      if(!error && data.id === this.props.id){
        const { unread_notes_count } = data;
        this.setState({
          unreadNoteCount: unread_notes_count
        });
      }
    }

    if(prevProps.sale.notes.isSubmit && !this.props.sale.notes.isSubmit){
      const { readNotes: { data }, saleId, error } = this.props.sale.notes;
      if(this.props.id === saleId && !error){
        if(data.total > 0) {
          this.props.getDetailSale2(this.props.id);
        }
      }
    }
    if(!prevState._isModalMarked && this.state._isModalMarked){
      this.setState({
        notesPage: 1,
        noteNextPage: null
      }, () => {
        this.props.getSaleNotes(this.props.id, {
          params: { per_page: 3, page: this.state.notesPage }
        });
      })
    }
    if (!prevState._isModalTracking && this.state._isModalTracking) {
      this.props.getTracking(this.props.id)
    }
    if (prevProps.sale.shippingUpdate.isFetch && !this.props.sale.shippingUpdate.isFetch)
    {
      const { data, error } = this.props.sale.shippingUpdate;
      if (!error) {
        this.setState({
          shippingUpdate: data
        })
      }
    }
  }

  shouldComponentUpdate(nextProps){
    if(nextProps.sale.isSubmit){
      return false;
    }
    if(!nextProps.sale.isSubmit&&nextProps.sale.isSubmit!==this.props.sale.isSubmit){
      if(nextProps.sale.error){
        const { error: { id } } = nextProps.sale;
        if(id && id != this.props.id){
          return false;
        }
      }
      else{
        if(nextProps.sale.detail.id && nextProps.sale.detail.id != this.props.id){
          return false;
        }
      }
    }
    return true;
  }

  _handleTrackingChange(){
    this.setState({
      _isModalTracking: !this.state._isModalTracking,
    });
  }

  _handleModalMarkedOnSubmit({ marked_notes, marked = null }){
    if(marked === false){
      const payload = { marked };
      if(marked_notes){
        payload['marked_notes'] = marked_notes;
      }
      this.setState({
        _markedEdit : marked,
        _isModalMarked: false,
        // isConfirmation: false
      });
      this.props.doMarkedSale(this.props.id, payload)
      return;
    }
    this.setState({
      _markedEdit : this.state.closeMarkedAt? (marked !== null? marked: this.state._markedEdit): true,
      _isModalMarked: false,
      // isConfirmation: false,
      markedNotes: marked_notes
    }, () => {
      const payload = {
        // status : param.status,
        marked : this.state._markedEdit,
        marked_notes
      }
      this.props.doMarkedSale(this.props.id, payload)
    })
  }

  _handleMarkedChange(){
    this.setState({
      isConfirmation: false,
      _isModalMarked: !this.state._isModalMarked,
      _markedEdit : this.state.marked //cancel toggle
    });
  }


  _handleCheckedChange(e) {
    this.setState({
      ...checkChange(e, this.state)
    }, ()=>{
      const payload = {
      autodisburse : this.state._autoDisburseEdit
      }
      this.props.update(this.props.id, payload)
    });
  }

  _handleAutoCancelChange(e) {
    const turnOnAutoCancelEnabled = this.props?.turnOnAutoCancelEnabled;
    if (!turnOnAutoCancelEnabled) {
      return;
    }

    this.setState({
      ...checkChange(e, this.state)
    }, ()=>{
      const payload = {
        auto_cancelled_at : this.state._autoCancelEdit,
      }
      this.props.update(this.props.id, payload)
    });
  }

  _timer() {
    const then = this.state.updatedAt || this.state.createdAt;
    let hour = moment().diff(then, 'hours');
    let min = moment().diff(then, 'minutes') % 60;
    let sec = moment().diff(then, 'seconds') % 60;
    let isCancel = false;
    if(hour >= 96){
      hour = 96; min = 0; sec = 0; isCancel = true;
    }
    else if (hour >=72){
      hour = 72; min = 0; sec = 0;
    }
    this.setState({
      hour,
      min,
      sec,
      isCancel
    });
  }

  render() {
    const {
      id,
      data,
      timer,
      rowId,
      quantity,
      buyerEmail,
      invoiceNumber,
      totalAmount,
      orderDate,
      orderStatus,
      displayName,
      SKU,
      size,
      colour,
      preOrder,
      preVerified,
      updatedAt,
      isSelected,
      sellerResponded,
      consignmentId,
      sneakersCondition,
      category,
      onOpenReceivedModal,
      onOpenAcceptModal,
      onOpenRejectModal,
      bank,
      paymentVia,
      paymentMethod,
      rack,
      voucherCode
      // status,
      // markedAt,
      // markedNotes
    } = this.props;
    const via = getPaymentVia(paymentVia);
    const actionButtons = isSelected===true?
    <ReactLoading
      type="bars"
      color="green"
      height={20}
      width={30}
    />: ((orderStatus==='NEW' && sellerResponded!==null)||orderStatus==='SELLER_SENT'?
        <ButtonGroup>
          <Button
            color="success"
            size="sm"
            title="Received From Seller"
            onClick={onOpenReceivedModal}
          >
            Received From Seller
          </Button>
          {
            this.state.isCancel ?
            <Button
              color="danger"
              size="sm"
              title="Reject"
              onClick={onOpenRejectModal}
            >
              <i className="fa fa-times"></i>
            </Button>
            :
            null
          }
          <Link to={`/sales/${id}`} className="btn btn-sm btn-secondary" target="_blank" title="More Detail..."><i className="fa fa-external-link"></i></Link>
        </ButtonGroup>
      :
        <ButtonGroup>
          <Button
            color="success"
            size="sm"
            title="Accept"
            onClick={onOpenAcceptModal}
          >
            <i className="fa fa-check"></i>
          </Button>
          <Button
            color="danger"
            size="sm"
            title="Reject"
            onClick={onOpenRejectModal}
          >
            <i className="fa fa-times"></i>
          </Button>
          <Link to={`/sales/${id}`} className="btn btn-sm btn-secondary" target="_blank" title="More Detail..."><i className="fa fa-external-link"></i></Link>
        </ButtonGroup>
      )
    return (
      <tr>
        <ModalMarkedSale
          isOpen={this.state._isModalMarked}
          isConfirmation={this.state.isConfirmation}
          toggle={this._handleMarkedChange}
          invoiceNumber={invoiceNumber}
          marked={this.state.marked}
          markedAt={this.state.markedAt}
          markedBy={this.state.markedBy}
          closeMarkedAt={this.state.closeMarkedAt}
          markedNotes={this.state.markedNotes}
          notes={this.state.notes}
          unreadNoteCount={this.state.unreadNoteCount}
          hasNextPage={this.state.noteNextPage}
          loginId={this.props.loginId}
          onSubmit={this._handleModalMarkedOnSubmit}
          onLoadMoreNotes={() => {
            const { notesPage, noteNextPage } = this.state;
            if(!noteNextPage) return;
            this.setState({
              noteNextPage: null,
              notesPage: notesPage + 1
            }, () => {
              this.props.getSaleNotes(this.props.id, {
                params: { per_page: 3, page: this.state.notesPage }
              });
            })
          }}
        />
        <ModalTrackingShipment
          shippingUpdate={this.state.shippingUpdate}
          isLoading={this.props.sale.shippingUpdate.isFetch}
          isOpen={this.state._isModalTracking}
          toggle={this._handleTrackingChange}
        />
        <th className="v-center" scope="row">{ rowId }</th>
        {
          timer?
          <Timer 
            hours={this.state.hour}
            minutes={this.state.min}
            seconds={this.state.sec}
          />
          :
          null
        }
        <td className="v-center">
          <OrderSummariesColumn
            id={id}
            quantity={quantity}
            invoiceNumber={invoiceNumber}
            orderDate={orderDate}
            buyerEmail={buyerEmail}
            displayName={displayName}
            size={size}
            SKU={SKU}
            colour={colour}
            preOrder={preOrder}
            preVerified={preVerified}
            consignmentId={consignmentId}
            category={category}
            sneakersCondition={sneakersCondition}
            unreadNoteCount={this.state.unreadNoteCount}
            // marked={this.state._markedEdit}
            // onMarkToggle={value => {
            //   if(!value){
            //     this.setState({
            //       _markedEdit: value
            //     })
            //     this.props.doMarkedSale(this.props.id, { marked: value })

            //   }else {
            //     this.setState({
            //       _isModalMarked: true,
            //       _markedEdit: value
            //     })
            //   }
            // }}
          />
        </td>
        <td className="v-center">
          <span className="text-uppercase rack-span" style={{fontSize: '.75rem'}}>{ rack ? rack : '-' }</span>
        </td>
        <td className="v-center">
          <div>
            <TextRupiah prefix="Rp. " bold value={totalAmount}/>
          </div>
          {
            voucherCode && (
              <div className="text-uppercase voucher-wrapper" style={{fontSize: '.5rem'}}>
                <FontAwesomeIcon iconType="ticket"/> {voucherCode}
              </div>
            )
          }
          <div className="issued-wrapper" style={{minWidth: 95}}>
            <a
              href="#"
              className="mr-1"
              style={{textDecoration: 'underline', color: '#4a4a4a', fontSize: '.75rem'}}
              onClick={e => {
                e.preventDefault();
                this.setState({ _isModalMarked: true })
              }}
            >
              <i className="fa fa-pencil"></i> {this.state.markedAt? 'view': 'add'} notes
              {/* <i className="fa fa-pencil"></i> add notes */}
            </a>
            <ButtonStarred
              title={this.state._markedEdit? "Remove mark!": "Mark!"}
              value={this.state._markedEdit}
              onClick={() => {
                const value = !this.state._markedEdit;
                this.setState({
                  _isModalMarked: true,
                  isConfirmation: !value,
                  _markedEdit: value
                })
              }}
            />
          </div>
          <ButtonChangeSeller
            id={id}
            status={orderStatus}
          />
          <div>
            <SmallInline>Auto Disburse</SmallInline><br/>
            <label className="switch switch-text switch-success-outline-alt">
              <input type="checkbox"
                className="switch-input"
                name="_autoDisburseEdit"
                id="formSaleAutoDisburse"
                value="true"
                checked={textValue('_autoDisburseEdit', this.state)}
                onChange={this._handleCheckedChange}
              />
              <span className="switch-label" data-on="YES" data-off="NO"></span>
              <span className="switch-handle"></span>
            </label>
          </div>
        </td>
        <td className="v-center">
          <a 
            href="#"
            className="mr-1"
            style={{textDecoration: 'underline', color: '#4a4a4a', fontSize: '.75rem'}}
            onClick={e => {
              e.preventDefault()
              this.setState({
                _isModalTracking: true,
                shippingUpdate: null
              })
            }}
          >Tracking Shipment</a><br />
          {
            <div><span style={{fontSize: '.75rem'}}>{data.offer.payment_id?data.offer.payment_id:data.offer.id}</span></div>
          }
          {
            bank && paymentMethod === 'virtual_account'?
            <span style={{fontSize: '.75rem'}}>{bank+ '-'+ getPaymentType(paymentMethod)}</span>
            : 
            getPaymentType(paymentMethod)
          }
          <br/>
          { via !== null? <span style={{fontSize: '.75rem'}}>via <i>{via}</i></span>: null}
          <br/>
          { 
            paymentVia !== null && paymentVia === 'vt' && paymentMethod=== "credit_card" ? 
            <button className="btn btn-sm btn-secondary" onClick={this.props.onOpenCreditCard} target="_blank" title="Show More Info"><i className="fa fa-info-circle"></i> More Info</button>
            : 
            null
          }
        </td>
        <td className="v-center">
          <DateToHuman  value={updatedAt} id={`tooltip_${rowId}`}/>
          <div>
            <SmallInline>Auto Cancel</SmallInline><br/>
            <label className="switch switch-text switch-success-outline-alt">
              <input type="checkbox"
                className="switch-input"
                name="_autoCancelEdit"
                id="formSaleAutoCancel"
                value="true"
                checked={textValue('_autoCancelEdit', this.state)}
                onChange={this._handleAutoCancelChange}
                disabled={!this.props?.turnOnAutoCancelEnabled}
              />
              <span className="switch-label" data-on="YES" data-off="NO"></span>
              <span className="switch-handle"></span>
            </label>
          </div>
        </td>
        <td className="v-center">
          { actionButtons }
          <div>
            {/* <SmallInline>Marked Sale</SmallInline><br />
            <label className="switch switch-text switch-success-outline-alt">
              <input type="checkbox"
                className="switch-input"
                name="_markedEdit"
                id="formSaleMarked"
                value="true"
                checked={textValue('_markedEdit', this.state)}
                onChange={this._handleMarkedChange}
              />
              <span className="switch-label" data-on="YES" data-off="NO"></span>
              <span className="switch-handle"></span>
            </label> */}
          </div>
        </td>
      </tr>
    )
  }
}

const mapStateToProps = ({ sale }) => ({
  sale
})

const mapDispatchToProps = (dispatch) => ({
  getDetailSale2: (id) => dispatch(getDetailSale2(id)),
  getSaleNotes: (id, query) => dispatch(getSaleNotes(id, query)),
  setReadSaleNotes: (id, body) => dispatch(setReadSaleNotes(id, body)),
  update: (id, payload) => dispatch(updateSale(id, payload)),
  doMarkedSale: (id, payload) => dispatch(doMarkedSale(id, payload)),
  getTracking: id => dispatch(getTrackingShipmentDetail(id))
})

export default connect (mapStateToProps, mapDispatchToProps) (RowItem);

RowItem.defaultProps = {
  totalAmount: 0,
  isSelected: false,
  hijacked: false
}

RowItem.propTypes={
  rowId: propTypes.number.isRequired,
  id: propTypes.number.isRequired,
  buyerEmail: propTypes.string,
  autoDisburse: propTypes.number,
  totalAmount: propTypes.oneOfType([propTypes.number,propTypes.string]),
  orderDate: propTypes.string,
  orderStatus: propTypes.string,
  invoiceNumber: propTypes.string,
  size: propTypes.string,
  displayName: propTypes.string,
  SKU: propTypes.string,
  colour: propTypes.string,
  preOrder: propTypes.oneOfType([propTypes.number,propTypes.string]),
  preVerified: propTypes.oneOfType([propTypes.bool,propTypes.string]),
  updatedAt: propTypes.string,
  sellerResponded: propTypes.string,
  hijacked: propTypes.bool,
  consignmentId: propTypes.string,
  onOpenReceivedModal: propTypes.func.isRequired,
  onOpenAcceptModal: propTypes.func.isRequired,
  onOpenRejectModal: propTypes.func.isRequired,
  onOpenCreditCard: propTypes.func.isRequired,
  marked: propTypes.bool,
  status: propTypes.string,
  markedNotes: propTypes.string,
  markedAt: propTypes.string,
  rack: propTypes.string,
}