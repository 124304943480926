import React from 'react';
import propTypes from 'prop-types';
import {
  Card,
  CardText,
  Row,
  Col
} from 'reactstrap';
import PurchaseSummaryCard from '../../Payments/PaymentDetail/tabPaymentDetail/PurchaseSummaryCard';
import PaymentDetailCard from './tabPaymentDetail/PaymentDetailCard';

const TabPaymentDetail = props => {
  const { isLoading, paymentId, productVariant,  payment } = props;
  const paymentData = payment || {};
  return (
    <Card body className="tab-content--custom__card">
      <CardText>About their's payment.</CardText>
      <Row>
        <Col xs={12} md={6}>
          <PaymentDetailCard
            isLoading={isLoading}
            isEmptyData={paymentId===null}
            {...paymentData}
            voucher={paymentData.voucherable}
          />
        </Col>
        <Col xs={12} md={6}>
          <PurchaseSummaryCard
            isLoading={isLoading}
            isEmptyData={paymentId===null}
            id={paymentId}
            displayName={productVariant.display_name}
            totalAmount={paymentData.total_amount}
            totalAdjustedAmount={paymentData.total_adjusted_amount}
            offerAmount={paymentData.offer_amount}
            walletUsage={paymentData.wallet_usage}
            kickCredit={paymentData.wallet_amount}
            kickPoint={paymentData.lbalance_amount}
            sellerCredit={paymentData.scredit_amount}
            kaCourier={paymentData.ka_courier}
            kaCourierOption={paymentData.ka_courier_option}
            kaCourierPrice={paymentData.ka_courier_price}
            uniqueAmount={paymentData.unique_amount}
            administrationFee={paymentData.administration_fee}
            voucherId={paymentData.voucherable_id}
            voucher={paymentData.voucherable}
            quantity={paymentData.quantity}
            discount={paymentData.discount}
            adminFeeDiscount={paymentData.admin_fee_discount}
            kaCourierDiscount={paymentData.ka_courier_discount}
            subsidyPrice={paymentData.subsidy_price}
            processingFeeCalculated={paymentData.processing_fee_calculated}
          />
        </Col>
      </Row>
    </Card>
  )
}

export default TabPaymentDetail;
TabPaymentDetail.defaultProps = {
  isLoading: false,
  isEmptyData: false,
  paymentId: null,
  productVariant:{
    id: 0,
    display_name: '-'
  },
  payment:{
    id: 0,
    voucherable_id: null,
    voucher:{}
  }
}
TabPaymentDetail.propTypes = {
  isLoading: propTypes.bool,
  isEmptyData: propTypes.bool,
  paymentId: propTypes.number,
  payment: propTypes.object,
  productVariant: propTypes.object
}