import React from 'react';
import propTypes from 'prop-types';
import classNames from 'classnames';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap'


const ModalDeleteProgress = props => (
  <Modal size="sm" className="modal-confirmation" isOpen={props.isOpen}>
    <ModalHeader className="modal-header--center">
      Deleting Progress.
    </ModalHeader>
    <ModalBody>
      { props.renderModalBody?props.renderModalBody(): props.modalBody }
    </ModalBody>
    <ModalFooter>
      <Button className={classNames({'d-none': props.isPrimaryButtonHide })} color="primary" onClick={props.onPrimaryClick}>Gotchas</Button>
    </ModalFooter>
  </Modal>
)

ModalDeleteProgress.propTypes = {
  isOpen: propTypes.bool,
  renderModalBody: propTypes.func,
  modalBody: propTypes.string,
  isPrimaryButtonHide: propTypes.bool,
  onPrimaryClick: propTypes.func
}

ModalDeleteProgress.defaultProps = {
  isOpen: false,
  isPrimaryButtonHide: false
}

export default ModalDeleteProgress;