import React, {Component} from 'react'
import {connect} from 'react-redux';
import Loading from 'react-loading-animation';
import {
  Row,
  Col,
  Card,
  CardBody,
  CardText,
  CardImg,
  CardTitle,
  CardSubtitle,
  Container,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import BadgeThin from '../../components/Badges/BadgeThin';
import Toolbars from './Filters/Toolbars';
import FilterPanel from './Filters';
import FilterContainer from '../../components/Container/FilterContainer/FilterContainer';
import { CardHeaderWithToolbars,Title } from '../../components/Card/CardHeaderWithToolbars';
import privateView from '../../components/hocs/privateView';
import Radio from '../../components/Form/Radios/Radio';
import { ButtonXs } from '../../components/Button';
import { SimpleLineIcon } from '../../components/Icons'
import { getCacheImage } from '../../utils/imageHelper'
import InfiniteScroll from 'react-infinite-scroll-component';
import {
	saveSlider,
	getListSlider
} from '../../actions/sliderAction';
import { getOptionList } from '../../actions/categoriesAction';
import { DEFAULT_PER_PAGE, DEFAULT_SORT_BY } from '../../constants/settings';
import AlertNotFound from '../../components/Card/AlertNotFound';
import moment from 'moment';
import 'moment/locale/id';
import Analytics from "../../services/Analytics";

const defaultParams = {
  per_page : DEFAULT_PER_PAGE,
  sort_by: DEFAULT_SORT_BY,
  active: 1,
  type: "MAIN"
}

const getPublicImageUrl = image => {
  return image.signed_url || image.img_url
}

class SliderList extends Component {
  constructor(props){
    super(props);
    this.state = {
      params: {
        page: 1,
        ...defaultParams
      },
      hasMore: true,
      dataSlider: [],
      resetList: true,
      _filterOpen: false
    }
    this._onChangeCheckbox = this._onChangeCheckbox.bind(this);
    this._handleToggleFilterPanel = this._handleToggleFilterPanel.bind(this);
    this._handleRefresh = this._handleRefresh.bind(this);
    this._handleChange = this._handleChange.bind(this);
    this._handleParamsChangeAndSubmit = this._handleParamsChangeAndSubmit.bind(this);
    this._handleGoto = this._handleGoto.bind(this)
  }

  _handleToggleFilterPanel(e){
    e.preventDefault()
    this.setState({_filterOpen:!this.state._filterOpen})
  }

  _getList(params){
    const p = Object.keys(params)
    .filter(key => params[key]!='')
    .reduce((prev, current) => ({
      ...prev,
      [current]: params[current]
    }), {});
    console.log(p);
    this.props.getSliderList({ params: p })
  }

  _handleRefresh(e){
    e.preventDefault();
    const {params} = this.state;
    this.setState({
      params: {...params, page: 1},
      resetList: true,
      hasMore: true
    },() => {
      this._getList(this.state.params)
    })
  }

  _handleChange(e){
    const {params} = this.state;
    params[e.target.name] = e.target.value;
    this.setState({params})
  }

  _handleParamsChangeAndSubmit(e){
    e.preventDefault()
    const { params } = this.state
    params[e.target.name] = e.target.value;
    this.setState({
      params: {...params, page: 1},
      resetList: true,
      hasMore: true
    },() => {
      this._getList(this.state.params)
    })
  }

  _onChangeCheckbox(e){
    let onePartSlider
    let oldState = {...this.state}
    let newDataSlide = [...oldState.dataSlider].map((item)=>{
      if(item.id == e.target.value){
        let newItem = {...item}
        newItem.active = e.target.checked ? 1 : 0
        onePartSlider = newItem
        return newItem
      } else {
        return item
      }
    })
    this.setState({dataSlider: newDataSlide},()=>{
      this.props.saveSlider(onePartSlider)
    })
  }

  _handleGoto(page){
    const params = this.state.params
    params.page = page;
    this.setState({params},()=>{
      this._getList(this.state.params)
    })
  }

  _handleNextPage = async() =>  {
    const { isFetch } = this.props.slider;
    const { hasMore, params, params: { page }, dataSlider } = this.state;
    if(!isFetch && hasMore && dataSlider.length>0){
      await this.setState({
        params: {
          ...params,
          page: page + 1
        }
      });
      this._getList(this.state.params)
    }
  }

  _handleResetFilter = () => {
    this.setState({
      params: {...defaultParams},
      resetList: true,
      hasMore: true
    }, ()=> this._getList(this.state.params))
  }

  _renderEndMessage = () => {
    const { dataSlider } = this.state;
    return dataSlider.length > 0 ?(
      <p style={{textAlign: 'center'}}>
        <b>Yay! You have seen it all</b>
      </p>
    ): <AlertNotFound/>
  }

  _renderLoading = () => (
    <div style={{display: 'flex', padding: '1.25rem'}}>
      <Loading/>
    </div>
  )

  render(){
    return(
      <div className="animated fadeIn">
        <Row>
          <Col xs={12}>
            <Card>
              <CardHeaderWithToolbars>
                <Title>
                  <i className="fa fa-align-justify"></i>
                  <span>Slider List</span>
                </Title>
                <Toolbars
                  isOpen={this.state._filterOpen}
                  onRefreshList={this._handleRefresh}
                  onResetFilter={this._handleResetFilter}
                  onToggleFilter={this._handleToggleFilterPanel}
                />
              </CardHeaderWithToolbars>
              <FilterContainer>
                <FilterPanel
                  isOpen={this.state._filterOpen}
                  {...this.state.params}
                  categoryOptions={this.props.dataCategories}
                  onHandleChange={this._handleChange}
                  onHandleSearch={this._handleRefresh}
                  onHandleChangeAndSubmit={this._handleParamsChangeAndSubmit}
                />
              </FilterContainer>
              <CardBody>
                <CardText className="mb-0">
                  In this section will display slider for www.kickavenue.com.
                </CardText>
                <div className="mb-2" style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end', padding: '.75rem 1rem'}}>
                  <div>
                    {this.props.dataCategories.map(item => (
                      <ButtonXs
                        key={item.value}
                        active={this.state.params.category_id === item.value}
                        color="gray"
                        onClick={() => {
                          const { params, params: { category_id } } = this.state;
                          if(category_id !== item.value)
                            this.setState({
                              params: {
                                ...params,
                                page: 1,
                                category_id: item.value
                              },
                              resetList: true,
                              hasMore: true
                            }, () => this._handleGoto(1))
                        }}
                      >
                        {item.label}
                      </ButtonXs>
                    ))}
                  </div>
                  <div className="ml-2">
                    <ButtonXs
                      active={this.state.params.type === "MAIN"}
                      color="gray"
                      onClick={() => {
                        const { params, params: { type } } = this.state;
                        this.setState({
                          params: {
                            ...params,
                            page: 1,
                            type: type === "MAIN"? "": "MAIN"
                          },
                          resetList: true,
                          hasMore: true
                        }, () => this._handleGoto(1))
                      }}
                    >
                      Main
                    </ButtonXs>
                    <ButtonXs
                      active={this.state.params.type === "PROMO"}
                      color="gray"
                      onClick={() => {
                        const { params, params: { type } } = this.state;
                        this.setState({
                          params: {
                            ...params,
                            page: 1,
                            type: type === "PROMO"? "": "PROMO"
                          },
                          resetList: true,
                          hasMore: true
                        }, () => this._handleGoto(1))
                      }}
                    >
                      Promo
                    </ButtonXs>
                  </div>
                  <div className="ml-2">
                    <Radio 
                      radioType="3d" 
                      value={true} 
                      checked={this.state.params.active === 1} 
                      size="xs" 
                      onChange={e => {
                        this.setState({
                          params: {
                            ...this.state.params,
                            active: e.target.checked? 1: 0
                          },
                          resetList: true,
                          hasMore: true
                        }, () => this._handleGoto(1))
                      }}
                    />
                    <span className="ml-2">Only Active</span>
                  </div>
                </div>
                <InfiniteScroll
                  dataLength={this.state.resetList? 0 :this.state.dataSlider.length}
                  next={this._handleNextPage}
                  hasMore={this.state.hasMore}
                  scrollThreshold={.95}
                  loader={this._renderLoading()}
                  endMessage={this._renderEndMessage()}
                >
                  <Container>
                    <Row>
                      {
                        !this.state.resetList && this.state.dataSlider.map((item, index) => {
                          let labelActive = null;
                          if(item.active){
                            if(item.started_at || item.ended_at){
                              let bool = 0;
                              if(item.started_at && item.ended_at){
                                const parsedStartedAt = moment(item.started_at);
                                const parsedEndeddAt = moment(item.ended_at);
                                if(parsedStartedAt.diff(moment())<= 0 && parsedEndeddAt.diff(moment()) > 0){
                                  bool = 1;
                                }
                                else if(parsedStartedAt.diff(moment()) > 0) {
                                  bool = 2;
                                }
                              }
                              else if(item.started_at){
                                const parsedStartedAt = moment(item.started_at);
                                if(parsedStartedAt.diff(moment())<= 0)
                                  bool = 1;
                                else if(parsedStartedAt.diff(moment()) > 0) {
                                  bool = 2;
                                }
                              }
                              else{
                                const parsedEndeddAt = moment(item.ended_at);
                                if(parsedEndeddAt.diff(moment()) > 0)
                                  bool = 1;
                              }
                              labelActive = bool === 1? "Now Showing": (bool === 2? "On Scheduled": "Ended")
                            }
                            else{
                              labelActive = "Now Showing";
                            }
                          }
                          return (
                            <Col key={item.id} xs={12} md={4} style={{display: 'flex', justifyContent: 'center'}}>
                              <Card>
                                <Link to={`sliders/${item.id}`} className="card-link">
                                  <CardImg top width="100%" src={getCacheImage(getPublicImageUrl(item))} alt={item.name} />
                                </Link>
                                <CardBody style={{padding: '.85rem', display: 'flex', width: '100%', flexDirection: 'column'}}>
                                  <CardTitle>#{item.id}. {item.name}</CardTitle>
                                  <CardSubtitle>
                                    <BadgeThin className="text-uppercase"><SimpleLineIcon iconType="tag"/> {item.type}</BadgeThin>{' '}
                                    <BadgeThin color="primary" className="text-uppercase">{item.category.name}</BadgeThin>{' '}
                                    {item.platform? <BadgeThin className="text-uppercase">{item.platform}</BadgeThin>:(
                                      <BadgeThin color="success">Web & App</BadgeThin>
                                      )}{' '}
                                    <BadgeThin className="text-uppercase">{item.images.length} imgs</BadgeThin>
                                    <div>
                                      <small className="text-muted">
                                        Last updated {item.updated_at}.
                                      </small>
                                    </div>
                                  </CardSubtitle>
                                  <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                    {item.active?(
                                      <div style={{flex: 1}}>
                                        <div style={{display: "inline-block", padding: '3px 6px', fontSize: '.75rem', border: '1px solid #4a4a4a'}}>
                                          {labelActive}
                                        </div>
                                      </div>
                                    ): null}
                                    <div style={{flex: 1}}>
                                      <CardText className="mb-0">
                                        <small className="text-muted">
                                          Start at {item.started_at? item.started_at: '-'}.
                                        </small>
                                        <br/>
                                        <small className="text-muted">
                                          Until at {item.ended_at? item.ended_at: '-'}.
                                        </small>
                                      </CardText>
                                    </div>
                                  </div>
                                  <div style={{display: 'flex', alignItems: 'flex-end', flex: 1, width: '100%'}}>
                                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', flex: 1}}>
                                      <Link to={`sliders/${item.id}`} className="card-link mr-2">Details</Link>
                                      <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                        <a
                                          style={{fontSize: '.5rem'}}
                                          className="btn btn-outline-primary btn-sm mr-2"
                                          href={item.redirect_url}
                                          title="Try link"
                                          target="_blank"
                                        >
                                          Try Link
                                        </a>
                                        <Radio
                                          size="sm"
                                          style={{margin: 0}}
                                          name={`isActive_image_Slider_${index}`}
                                          checked={item.active}
                                          value={item.id}
                                          onChange={this._onChangeCheckbox}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </CardBody>
                              </Card>
                            </Col>
                          )
                        })
                      }
                    </Row>
                  </Container>
                </InfiniteScroll>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    )
  }

  componentDidMount(){
    const { email } = this.props.injectedProps;
    if(!this.props.dataCategories.length){
      this.props.getCategoriesOptions()
    }
    this._getList(this.state.params)
    Analytics.recordPageView('slider_list', email, {
      url: window.location.href,
      location: this.props.location,
      match: this.props.match
    });
  }

  componentDidUpdate(prevProps){
    if(prevProps.slider.isFetch !== this.props.slider.isFetch && !this.props.slider.isFetch && this.props.slider.status_code===200){
      const { data } = this.props.slider.data;
      const { resetList } = this.state;
      console.log(this.props.slider.data)
      this.setState({
        dataSlider: resetList? [...data]: [...this.state.dataSlider, ...data],
        resetList: false,
        hasMore: !(this.props.slider.data.next_page_url === null)
      })
    }
  }
}

const mapStateToProps=({slider, auth: { isFetch, isLogged, id, email, roles }, masterCategories: { options }}) => ({
  slider,
  dataCategories: options,
  guardData: { isFetch, isLogged, id, email, roles }
})

const mapDispatchToProps = (dispatch) => ({
  saveSlider:(payload)=>dispatch(saveSlider(payload)),
  getSliderList:(query)=>dispatch(getListSlider(query)),
  getCategoriesOptions: () => dispatch(getOptionList())
})

const enhance = connect(mapStateToProps, mapDispatchToProps);
export default enhance(privateView(SliderList))