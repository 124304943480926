import React from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  Label,
  Input,
  Button,
  FormGroup,
  Alert
} from 'reactstrap';
import propTypes from 'prop-types';
import classNames from 'classnames';
import { errorMessage, hasError, textValue } from '../../utils/form';
import { isRequired, isValidPassword, isConfirm } from '../../helpers/regex';
import { isRequiredMsg, isPasswordMsg, isConfirmMsg } from '../../helpers/validations';
import { ButtonLoading } from '../Button';

export default class ModalChangePassword extends React.Component{
  constructor(props){
    super(props)
    this.state = {
      _form:{
        old_password: '',
        new_password: '',
        confirm_password: ''
      },
      _isError: false,
      _isSuccess:false,
      _error: null
    }
    this.handleChange = this.handleChange.bind(this);
    this._onSubmit = this._onSubmit.bind(this);
    this._dismissSuccessAlert = this._dismissSuccessAlert.bind(this);
    this._dismissErrorAlert = this._dismissErrorAlert.bind(this);
  }

  handleChange(e){
    const { _form } = this.state;
    _form[e.target.name] = e.target.value;
    this.setState({_form});
  }

  componentWillReceiveProps(nextProps){
    // Onload cleaning the form
    if(nextProps.isOpen===true && nextProps.isOpen!==this.props.isOpen){
      this.setState({
        _form:{
          old_password: '',
          new_password: '',
          confirm_password: ''
        },
        _isError: false,
        _isSuccess: false,
        _error: null
      })
    }
    if(nextProps.isLoading === false && nextProps.isLoading!==this.props.isLoading){
      if(nextProps.error!==null){
        this.setState({
          _error:{ ...nextProps.error },
          _isError: true
        })
      }
      else{
        const { message, statusCode } = nextProps.result;
        this.setState({
          _form:{
            old_password: '',
            new_password: '',
            confirm_password: ''
          },
          _message: message,
          _statusCode: statusCode,
          _isSuccess: true,
          _error: null
        })
      }
    }
  }

  _onSubmit(e){
    e.preventDefault();
    const getError = { ...this._validateFrom() };
    if(Object.keys(getError).length){
      this.setState({
        _error:{
          errors: { ...getError },
          statusCode: 422
        },
        _isError: true
      })
    }
    else{
      const { _form } = this.state;
      this.setState({_error:null, _isError: false},()=>this.props.onSubmit({..._form}));
    }
  }

  _validateFrom(){
    const { _form } = this.state;
    let errors = {};
    if(!isRequired(_form.old_password)){
      const errorMsg = isRequiredMsg('old password');
      errors['old_password'] = [...(errors.old_password||[]),errorMsg]
    }
    if(!isRequired(_form.new_password)){
      const errorMsg = isRequiredMsg('new password');
      errors['new_password']=[...(errors.new_password||[]),errorMsg];
    }
    if(!isValidPassword(_form.new_password)){
      const errorMsg = isPasswordMsg('new password');
      errors['new_password']=[...(errors.new_password||[]),errorMsg];
    }
    if(!isRequired(_form.confirm_password)){
      const errorMsg = isRequiredMsg('confirm password');
      errors['confirm_password']=[...(errors.confirm_password||[]),errorMsg];
    }
    if(!isConfirm(_form.new_password, _form.confirm_password)){
      const errorMsg = isConfirmMsg('new password','confirm password');
      errors['confirm_password']=[...(errors.confirm_password||[]),errorMsg];
    }
    return errors;
  }

  _dismissSuccessAlert(){
    this.setState({
      _isSuccess: false
    })
  }

  _dismissErrorAlert(){
    this.setState({
      _isError: false
    })
  }

  _handleDisplayErrorMsg(){
    const defMsg = "We've got something errors.";
    if(this.state._error!==null){
      const { message, statusCode } = this.state._error;
      switch(statusCode){
        case 422:
          return "Please fullfill your form.";
        case 404:
          return "Resource not found.";
        default:
          return `(${statusCode}) ${message}`;
      }
    }
    return defMsg
  }

  render(){
    return(
      <Modal size="md" isOpen={this.props.isOpen} toggle={this.props.onToggle}>
        <ModalHeader>
          Change Your Password
        </ModalHeader>
        <Form onSubmit={this._onSubmit}>
        <ModalBody>
            <Alert color="success" isOpen={this.state._isSuccess} toggle={this._dismissSuccessAlert}>
              <strong>Well done!</strong> Change password have been successfully.
            </Alert>
            <Alert color="danger" isOpen={this.state._isError} toggle={this._dismissErrorAlert}>
              <strong>Oh Snap!</strong> {this._handleDisplayErrorMsg()}
            </Alert>
            <FormGroup className={classNames({'has-danger has-feedback': hasError('old_password',this.state._error)})}>
              <Label for="oldPassword">Old Password</Label>
              <Input
                type="password"
                id="oldPassword"
                name="old_password"
                value={textValue('old_password',this.state._form)}
                onChange={this.handleChange}
              />
              { errorMessage('old_password',this.state._error) }
            </FormGroup>
            <FormGroup className={classNames({'has-danger has-feedback': hasError('new_password',this.state._error)})}>
              <Label for="newPassword">New Password</Label>
              <Input
                type="password"
                id="newPassword"
                name="new_password"
                value={textValue('new_password',this.state._form)}
                onChange={this.handleChange}
              />
              { errorMessage('new_password',this.state._error) }
            </FormGroup>
            <FormGroup className={classNames({'has-danger has-feedback': hasError('confirm_password',this.state._error)})}>
              <Label for="confirmPassword">Confirm Password</Label>
              <Input
                type="password"
                id="confirmPassword"
                name="confirm_password"
                value={textValue('confirm_password',this.state._form)}
                onChange={this.handleChange}

              />
              { errorMessage('confirm_password',this.state._error) }
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <ButtonLoading 
              color="primary"
              isLoading={this.props.isLoading}
              loadingMessage="Submitting...."
              // onClick={this._onSubmit}
            >
              Submit
            </ButtonLoading>{' '}
          </ModalFooter>
        </Form>
      </Modal>
    )
  }
}

ModalChangePassword.propTypes = {
  isOpen: propTypes.bool,
  onSubmit: propTypes.func,
  onToggle: propTypes.func,
  error: propTypes.object,
  result: propTypes.object,
  isLoading: propTypes.bool
}
ModalChangePassword.defaultProps = {
  isOpen: false,
  isLoading: false,
  error: null,
  result: null
}