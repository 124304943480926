import React from 'react';
import propTypes from 'prop-types';
import { 
  Card, 
  CardHeader, 
  CardBody,
  Col,
  Row,
  Alert 
} from 'reactstrap';
import Icon from '../../../../../components/Icons/FontAwesomeIcon';
import ImageCard from './imageListCard/ImageCard';

const CardThumbnail = props => {
  const content = props.images && props.images.length?
      <Col xs={12} sm={12} md={12}>
        <ImageCard
          alt={props.images && props.images[0].image_url}
          imageUrl={props.images && props.images[0].image_url}
        />
      </Col>
    :
    (
      <Col>
        <Alert color="danger" style={{flex:1, textAlign:'center'}} className="mb-0"><strong>Ooops.. </strong> No Images Found!</Alert>
      </Col>
    )
    
  return (
    <Card>
      <CardHeader>
        <Icon iconType="picture-o"/> Seller Verification Images
      </CardHeader>
      <CardBody>
        <Row>
          { content }
        </Row>
      </CardBody>
    </Card>
  )
}

export default CardThumbnail;

CardThumbnail.propTypes = {
  id: propTypes.number,
  image_url: propTypes.string,
  updatedAt: propTypes.string,
}
CardThumbnail.defaultProps = {
}