import React, {Component} from 'react';
import {connect} from 'react-redux';
import {
    Row,
    Col,
    Card,
    CardHeader,
    CardBody
} from 'reactstrap';
import { Link } from 'react-router-dom';

import {Tbl, TblHead, TblBody} from '../../components/Tbl';
import Paginations from '../../components/Paginations';
import { getListRole } from '../../actions/roleAction';
import { generateNumber } from '../../utils';
import DateToHuman from '../../components/DateToHuman';
import privateView from '../../components/hocs/privateView';
import Analytics from "../../services/Analytics";

const dataHeader=[
    'No.','Display Name','Description', 'User Count','Updated At', 'Action'
];
class RoleIndex extends Component{
    constructor(props){
        super(props);        
        this.state={
            _params:{
                page:1
            }
        }
    }

    componentDidMount(){
        const { email } = this.props.injectedProps;
        this.props.getList();
        Analytics.recordPageView('role_list', email, {
            url: window.location.href,
            location: this.props.location,
            match: this.props.match
        });
    }

    onRenderRowItem(){
        if(this.props.role.list.status_code===200 && this.props.role.list.data.length>0)
		{
			const { current_page,per_page } =  this.props.role.list;
			return this.props.role.list.data.map((item,index)=>
				(
					<tr key={generateNumber(per_page,current_page,index)}>
						<th scope="row">{ generateNumber(per_page,current_page,index) }</th>	
						<td>{ item.display_name }</td>
						<td>{ item.description }</td>
						<td>{ item.users_count }</td>
						<td>{ <DateToHuman value={item.updated_at} id={`tooltip_${generateNumber(per_page,current_page,index)}`}/> }</td>
						<td>
							<Link to={`/roles/${item.id}`} className="btn btn-link">Details</Link>
						</td>
					</tr>
				)
			)	
		}
		return '';
    }

    onRenderPagination(){
        if(this.props.role.list.status_code===200&&this.props.role.list.data.length)
		{
			return (<Paginations 
						size="sm" 
						lastPage={this.props.role.list.last_page} 
						perPage={this.props.role.list.per_page}
						total={this.props.role.list.total}
              			maxPage={8}
						path={this.props.role.list.path}
						currentPage={this.props.role.list.current_page} 
						prevPageUrl={this.props.role.list.prev_page_url} 
						nextPageUrl={this.props.role.list.next_page_url} 
						handleGotoCallback={this.handleGoto.bind(this)}
					/>)
		}
        return '';
    }

    /**
	 * Handle callback when user click pagination items
	 * @author pewe
	 * @param {integer} page 
	 */
	handleGoto(page){
		const _params=this.state._params;
		_params.page=page;
		this.setState({_params});
		const query={ params: { ..._params } };
		this.props.getList(query);
	}

    render(){
        return(
            <div className="animated fadeIn">
                <Row>
                    <Col xs={12}>
                        <Card>                            
                            <CardHeader>
                                    <i className="fa fa-align-justify"></i>
                                    Role List
                            </CardHeader>
                            <CardBody>
                                <div className="table-responsive">
                                        <Tbl>
                                            <TblHead
                                                dataHeader={dataHeader}
                                            />
                                            <TblBody
                                                isLoad={this.props.role.isFetch}
                                                hasRow={this.props.role.list.status_code===200&&this.props.role.list.data.length>0}
                                                columnCount={dataHeader.length}
                                            >
                                            { this.onRenderRowItem() }
                                            </TblBody>
                                        </Tbl>
                                </div>
                                { this.onRenderPagination() }
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        )
    }
}
const mapStateToProps=({role, auth: {isFetch, roles, isLogged, id, email}})=>{
	return {
        role,
        email,
        guardData: {isFetch, roles, isLogged, id, email}
	}
}
const mapDispatchToProps=(dispatch)=>
{
	return {
		getList:(filter)=>dispatch(getListRole(filter)),
	}
}
const enhance = connect(mapStateToProps,mapDispatchToProps);
export default enhance(privateView(RoleIndex))