import React, { Component } from 'react';
import { connect } from 'react-redux';
import privateView from '../../components/hocs/privateView';
import Analytics from "../../services/Analytics";

import ProductVariantForm from './ProductVariantForm';
import {
  productVariantDetailIsSubmitSelector,
  productVariantImageIsDeletingSelector,
  productVariantImageIsSubmittingSelector,
} from "../../reducers/selectors/productVariantSelector";
import Audit from '../../components/Audit/Audit';

class ProductVariantDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      shouldRefreshLog: true,
    }
  }
  componentDidUpdate(prevProps) {
    const isAfterUpdatingProductVariant =
      prevProps.productVariantDetailIsSubmit !==
        this.props.productVariantDetailIsSubmit &&
      !this.props.productVariantDetailIsSubmit;

    if (isAfterUpdatingProductVariant) {
      this.setState({ shouldRefreshLog: true });
    }

    const isAfterDetachingImage =
      prevProps.productVariantImageIsDeleting !==
        this.props.productVariantImageIsDeleting &&
      !this.props.productVariantImageIsDeleting;
      
    if (isAfterDetachingImage) {
      this.setState({ shouldRefreshLog: true });
    }

    const isAfterAttachingImage = 
      prevProps.productVariantImageIsSubmitting !==
        this.props.productVariantImageIsSubmitting &&
      !this.props.productVariantImageIsSubmitting;

    if (isAfterAttachingImage) {
      this.setState({ shouldRefreshLog: true });
    }
  }
	componentDidMount(){
    const { email } = this.props.injectedProps;
    Analytics.recordPageView('product_variant_detail', email, {
      url: window.location.href,
      location: this.props.location,
      match: this.props.match
    });
  }
  setShouldRefreshLog = (value) => {
    this.setState({ shouldRefreshLog: value });
  }
  render(){
    return (
      <>
        <ProductVariantForm
          edit
          title="Product Variant Detail"
          { ...this.props }
        />
        <Audit 
          id={this.props.match.params?.id}
          type={"product_variant"}
          refresh={this.state.shouldRefreshLog}
          onSetRefresh={this.setShouldRefreshLog}
        />
      </>
    )
  }
}
const mapStateToProps = (state)=>{
  const {auth: { isFetch, roles, isLogged, id, email } } = state;
  return {
    guardData: { isFetch, roles, isLogged, id, email },
    productVariantDetailIsSubmit: productVariantDetailIsSubmitSelector(state),
    productVariantImageIsDeleting: productVariantImageIsDeletingSelector(state),
    productVariantImageIsSubmitting: productVariantImageIsSubmittingSelector(state),
  }
}

const enhance = connect(mapStateToProps);
export default enhance(privateView(ProductVariantDetail,['supervisor','sale_supervisor','inventory_admin']));