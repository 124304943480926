import {
  FETCHING_BID,
  SUBMITING_BID,
  BID_ERROR,
  GET_LIST_BID,
  GET_DETAIL_BID,
  DELETE_BID
} from '../constants';

import * as actionType from '../actions/bidAction';

const initialState = {
  list: {
    data: [],
    error: null,
    isFetch: false
  },
  detail: {
    data: {},
    isFetch: false,
    isSubmit: false,
    error: null
  }
}


const onFetched = (lastState, { requestType, bool }) => {
  switch(requestType){
    case actionType.FETCH_LIST_REQ:{
      const { list } = lastState;
      return { ...lastState, list: { ...list, isFetch: bool, error: bool? null: list.error }}
    }
    case actionType.FETCH_DETAILS_REQ:{
      const { detail } = lastState;
      return { ...lastState, detail: { ...detail, isFetch: bool, error: bool? null: detail.error }}
    }
    case actionType.DELETE_BID_REQ: {
      const { detail } = lastState;
      return { ...lastState, detail: { ...detail, isSubmit: bool, error: bool? null: detail.error }}
    }
    default: return { ...lastState }
  }
}

const onError = (lastState, { requestType, error, id }) => {
  switch(requestType){
    case actionType.FETCH_LIST_REQ:{
      const { list } = lastState;
      return { ...lastState, list: { ...list, error: { ...error } }}
    }
    case actionType.FETCH_DETAILS_REQ:{
      const { detail } = lastState;
      return { ...lastState, detail: { ...detail, error: { ...error, id } }}
    }
    case actionType.DELETE_BID_REQ: {
      const { detail } = lastState;
      return { ...lastState, detail: { ...detail, error: { ...error, id } }}
    }
    default: return { ...lastState }
  }

}

const onGetBidListSuccess = (lastState, payload) => {
  const { list } = lastState;
  const { data, message, status_code } = payload;
  return { ...lastState, list: { ...list, ...data, message, status_code, error: null }}
}

const onGetBidDetailSuccess = (lastState, payload) => {
  const { detail } = lastState;
  const { data, message, status_code } = payload;
  return { ...lastState, detail: { ...detail, data, message, status_code, error: null }}
}

const onDeleteBidDetailSuccess = (lastState, payload) => {
  const { detail } = lastState;
  const { data: { id, deleted_at }, message, status_code } = payload;
  return { ...lastState, detail: { ...detail, data: { ...detail.data, id, deleted_at }, message, status_code, error: null }}
}

const reducer = (state = initialState, action) => {
  switch(action.type){
    case SUBMITING_BID:
    case FETCHING_BID: return onFetched(state, action.payload);
    case BID_ERROR: return onError(state, action.payload);
    case GET_LIST_BID: return onGetBidListSuccess(state, action.payload);
    case GET_DETAIL_BID: return onGetBidDetailSuccess(state, action.payload);
    case DELETE_BID: return onDeleteBidDetailSuccess(state, action.payload);
    default: return { ...state };
  }
}

export default reducer;
