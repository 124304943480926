import React, {Component} from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  Button
} from 'reactstrap';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { 
  hasError, 
  errorMessage, 
  textChange, 
  textValue, 
  // dateValue, 
  // dateChange 
} from '../../../../utils/form';

export default class ModalAwbNumber extends Component{
  constructor(props){
    super(props);
    this.state={
      id:null,
      awb_number: '',
      invoice_number: '',
      error:null
    }
    this.handleChange=this.handleChange.bind(this);
    this.onSubmit=this.onSubmit.bind(this);
  }

  componentWillReceiveProps(nextProps){
    if(nextProps.isOpen===true&&nextProps.isOpen!==this.props.isOpen&&nextProps.sale!==null){
      const { sale } = nextProps;
      this.setState({ id: sale.id, invoice_number: sale.invoice_number, awb_number: sale.sale_shipping.awb_number, error: null });
    }
    if(nextProps.isSubmit !== this.props.isSubmit && nextProps.isSubmit===false && nextProps.isOpen){
      this.setState({ error: this.props.error });
    }
  }

  handleChange(e){
    this.setState(textChange(e,{...this.state}));        
  }

  onSubmit(e){
    e.preventDefault();
    const {
      awb_number
    } = this.state;
    const payload = { awb_number };
    this.props.onSubmit(payload);
  }

    render(){
      return(
        <Modal 
          isOpen={this.props.isOpen}
          toggle={this.props.onCancel} 
          className="modal-dialog modal-sm modal-confirmation"
        >
          <ModalHeader className="modal-header--center" toggle={this.props.onCancel} >
            Update AWB Number.
          </ModalHeader>
          <ModalBody>
            <p>
              Change AWB Number for <strong>{this.state.invoice_number}</strong>?<br/>
              We also notify the following user.
            </p>
            <Form onSubmit={this.onSubmit}>
              <FormGroup className={classnames({'has-danger has-feedback':hasError('awb_number',this.state.error)})}>
                <Label for="formAwbNumber">AWB Number*</Label>
                <Input
                  // type="number"
                  id="formAwbNumber"
                  name="awb_number"
                  placeholder="Input new AWB Number..."
                  value={textValue('awb_number',this.state)}
                  onChange={this.handleChange}
                />
                {errorMessage('awb_number',this.state.error)}
              </FormGroup>
            </Form>
            <span className="text-sm">Fields marked with * are required</span>  
          </ModalBody>
          <ModalFooter>
            <Button 
              color="primary" 
              disabled={this.props.isSubmit} 
              onClick={this.onSubmit}
            >
              {this.props.isSubmit?'Submitting...':'Confirm'}
            </Button>{' '}
            <Button 
              color="secondary"
              disabled={this.props.isSubmit}
              onClick={this.props.onCancel}
            >
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      )
    }
}

ModalAwbNumber.propTypes={
  sale:PropTypes.object,
  isSubmit:PropTypes.bool,
  error:PropTypes.object,
  isOpen:PropTypes.bool,
  onSubmit:PropTypes.func,
  onCancel:PropTypes.func
}