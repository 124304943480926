const apparelTemplates = [
  'XXS',
  'XS',
  'S',
  'M',
  'L',
  'XL',
  'XXL',
  'XXXL',
  'All Size',
  'MISC'
];

export const sortBySizeAsc = (a, b) => {
  if(/^\d+$/.test(a[0]) && /^\d+$/.test(b[0])){
    const strA = a.replace(/[A-Z\/]+/, '');
    const strB = b.replace(/[A-Z\/]+/, '');
    return parseFloat(strA) - parseFloat(strB);
  }
  else if(!/^\d+$/.test(a[0]) && !/^\d+$/.test(b[0])){
    const posA = ~apparelTemplates.indexOf(a) ? apparelTemplates.indexOf(a) : apparelTemplates.length + 1;
    const posB = ~apparelTemplates.indexOf(b) ? apparelTemplates.indexOf(b) : apparelTemplates.length + 1;
    return posA - posB
  }
  else{
    if(/^\d+$/.test(a[0])) return 1;
    else return -1;
  }
}

export const getItemBySKU = (keyword, arr) => {
  if(keyword.length <= 3) return arr;
  return arr.filter(item => ~item.name.toUpperCase().indexOf(keyword.toUpperCase()))
}