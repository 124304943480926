import React, { PureComponent as  Component } from 'react';
import {
  Form, 
  FormGroup,
  Label,
  Input, 
  Button } from 'reactstrap';
import propTypes from 'prop-types';

import { paymentStatusOptions, paymentTypeOptions } from '../../../constants/kickavenue'

const filterStatusOptions = [{name:'All',value:''},...paymentStatusOptions]
const filterPaymentMethodOptions = [{name:'All',value:''},...paymentTypeOptions]


export default class FilterForm extends Component{
  render(){
    return(
      <Form inline className="form-filter" onSubmit={this.props.onSubmit}>
          <FormGroup className="mb-2 mr-3">
              <Label className="mr-sm-3">
                Status
              </Label>
              <Input
                  type="select"
                  name="status"
                  onChange={this.props.onInputChange}
                  value={this.props.status}
              >
                {filterStatusOptions.map((option,i)=><option value={option.value} key={i}>{option.name}</option>)}
              </Input>
          </FormGroup>
          <FormGroup className="mb-2 mr-3">
              <Label className="mr-sm-3">
                Payment Method
              </Label>
              <Input
                type="select"
                name="paymentMethod"
                onChange={this.props.onInputChange}
                value={this.props.payment_method}
              >
                {filterPaymentMethodOptions.map((option,i)=><option value={option.value} key={i}>{option.name}</option>)}
              </Input>
          </FormGroup>
          <FormGroup className="mb-2 mr-3">
            <Button color="primary" >Apply Filter</Button>
          </FormGroup>
      </Form>
    )
  }
}

FilterForm.propTypes= {
  status: propTypes.string,
  payment_method: propTypes.string,
  onInputChange: propTypes.func.isRequired,
  onSubmit: propTypes.func.isRequired
}

FilterForm.defaultProps= {
  status: '',
  payment_method: ''
}