
// AWS Configs was here.

// export const AWS_ACCESS_KEY_ID = process.env.REACT_APP_AWS_ACCESS_KEY_ID;
// export const AWS_SECRET_ACCESS_KEY = process.env.REACT_APP_AWS_SECRET_ACCESS_KEY;
// export const AWS_BUCKET_REGION = process.env.REACT_APP_AWS_BUCKET_REGION;
// export const AWS_BUCKET = process.env.REACT_APP_AWS_BUCKET;
// export const AWS_BUCKET_URL = process.env.REACT_APP_AWS_BUCKET_URL;
// export const AWS_BUCKET_USER = process.env.REACT_APP_AWS_BUCKET_USER;
// export const AWS_BUCKET_USER_URL = process.env.REACT_APP_AWS_BUCKET_USER_URL;
// export const AWS_BUCKET_FAQ = process.env.REACT_APP_AWS_BUCKET_FAQ;
// export const AWS_BUCKET_FAQ_URL = process.env.REACT_APP_AWS_BUCKET_FAQ_URL;

export const AWS_ACCESS_KEY_ID = '';
export const AWS_SECRET_ACCESS_KEY = '';
export const AWS_BUCKET_REGION = process.env.REACT_APP_AWS_BUCKET_REGION;
export const AWS_BUCKET = process.env.REACT_APP_AWS_BUCKET;
export const AWS_BUCKET_URL = '';
export const AWS_BUCKET_USER = '';
export const AWS_BUCKET_USER_URL = '';
export const AWS_BUCKET_FAQ = process.env.REACT_APP_AWS_BUCKET_FAQ;
export const AWS_BUCKET_FAQ_URL = '';

// Image Caching URL
export const USER_UPLOAD_IMAGE = 'https://d1i34sodava61.cloudfront.net';
export const ASSET_IMAGE = 'https://d3jneea6mhvdzo.cloudfront.net';