import React from 'react';
import { CardBody, Collapse } from 'reactstrap';
import propTypes from 'prop-types';

import SearchForm from '../Filters/SearchForm';
import SortForm from '../Filters/SortForm';

export default class FilterPanel extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      keyword: props.keyword,
      perPage: props.per_page,
      sortBy: props.sort_by,
      disbursementType: props.type,
      payoutType: props.payout_type,
      balanceSource: props.balance_source
    }
    this._handleOnChangeInput = this._handleOnChangeInput.bind(this);
    this._handleSubmitFilter = this._handleSubmitFilter.bind(this);
    this._handleOnChangeInputAndApply = this._handleOnChangeInputAndApply.bind(this);
    this._handleSearchButtonClick = this._handleApplyFilterButtonClick.bind(this);
  }

  _handleOnChangeInput(e){
    const lastState = {...this.state};
    lastState[e.target.name] = e.target.value;
    this.setState({...lastState});
  }

  _handleOnChangeInputAndApply(e){
    const lastState = {...this.state};
    lastState[e.target.name] = e.target.value;
    this.setState({...lastState}, ()=> this._handleSubmitFilter());
  }

  _handleApplyFilterButtonClick(e){
    e.preventDefault();
    this._handleSubmitFilter();
  }

  _handleSubmitFilter(){
    const { keyword, perPage, sortBy, disbursementType, bankRecipient, payoutType, balanceSource} = this.state;
    this.props.onApplyFilter({
      keyword,
      per_page: perPage,
      sort_by: sortBy,
      payout_type: payoutType,
      type: disbursementType,
      bank_recipient: bankRecipient,
      balance_source: balanceSource
    });
  }

  componentDidUpdate(nextProps){
    if(!nextProps.isReset && this.props.isReset!==nextProps.isReset){
      const { keyword, per_page, sort_by, type, bank_recipient: bankRecipient, payout_type, balance_source } = this.props;
      this.setState({
        keyword,
        perPage: per_page,
        sortBy: sort_by,
        disbursementType: type,
        payoutType: payout_type,
        bankRecipient,
        balanceSource: balance_source
      })
      this.props.onResetFilterCallback();
    }
  }

  _handleSelectChange = value => {
    this.setState({ bankRecipient: value? value: null})
  }

  render(){
    return(
      <Collapse isOpen={this.props.isOpen}>
        <CardBody className="filter-container__card-body">
          <SearchForm
            keyword = {this.state.keyword}
            bankRecipient={this.state.bankRecipient}
            payoutType={this.state.payoutType}
            onInputChange = {this._handleOnChangeInput}
            onSelectChange={this._handleSelectChange}
            onSubmit = {this._handleSearchButtonClick}
          />
          <hr/>
          <SortForm
            perPage={this.state.perPage}
            sortBy={this.state.sortBy}
            disbursementType={this.state.disbursementType}
            onInputChange={this._handleOnChangeInputAndApply}
          />
        </CardBody>
      </Collapse>
    )
  }
}

FilterPanel.propTypes= {
  keyword: propTypes.string,
  per_page: propTypes.oneOfType([propTypes.number, propTypes.string]),
  sort_by: propTypes.string,
  isOpen: propTypes.bool.isRequired,
  isReset: propTypes.bool,
  onApplyFilter: propTypes.func,
  onResetFilterCallback: propTypes.func
}

FilterPanel.defaultProps= {
  keyword: '',
  per_page: 15,
  sort_by: '',
  type: '',
  payout_type: '',
}