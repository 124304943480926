import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Row,
  Col,
  Card,
  CardBody,
  CardText
} from 'reactstrap';
import classNames from 'classnames';
import DateToHuman from '../../components/DateToHuman';
import Paginations from '../../components/Paginations';
import PaginationSummaries from '../../components/Paginations/PaginationSummaries';
import { generateNumber } from '../../utils';
import { Tbl, TblHead, TblBody, Th } from '../../components/Tbl';
import BadgeThin from '../../components/Badges/BadgeThin';
import DropdownProductCategories from '../../components/Dropdowns/DropdownProductCategories';
import FilterPanel from './Filters';
import Toolbars from './Filters/Toolbars';
import FilterContainer from '../../components/Container/FilterContainer/FilterContainer';
import { CardHeaderWithToolbars,Title } from '../../components/Card/CardHeaderWithToolbars';
import { getProductList } from '../../actions/productAction';
import { DEFAULT_SORT_BY, DEFAULT_PER_PAGE } from '../../constants/settings';
import privateView from '../../components/hocs/privateView';
import Analytics from "../../services/Analytics";
import ModalBulkUpload from '../ProductVariants/Modals/ModalBulkUpload';
import { bulkProductFormats } from '../../config';
import { doBulkProducts } from '../../actions/bulkUploadAction';


const defaultParams = {
  sort_by : DEFAULT_SORT_BY,
  per_page : DEFAULT_PER_PAGE
}

class ProductIndex extends Component {
  constructor(){
    super();
    this.state={
      _params:{
        ...defaultParams
      },
      _modalBulkUploadOpen: false,
      _filterOpen:false,
      _selectedCategory: ''
    }
    this._handleToggleFilterPanel = this._handleToggleFilterPanel.bind(this);
    this._handleToggleModalBulkUpload = this._handleToggleModalBulkUpload.bind(this);
    this._handleParamsChange = this._handleParamsChange.bind(this);
    this._handleParamsChangeAndSubmit = this._handleParamsChangeAndSubmit.bind(this);
    this._handleGoto = this._handleGoto.bind(this);
    this._handleRefresh = this._handleRefresh.bind(this);
    this._handleResetFilter = this._handleResetFilter.bind(this);
    this._handleCategoryChanged = this._handleCategoryChanged.bind(this);
    this._refreshList = this._refreshList.bind(this);
  }

  componentDidMount(){
    const { email } = this.props.injectedProps;
    this._getList(this.state._params);
    Analytics.recordPageView('product_list', email, {
      url: window.location.href,
      location: this.props.location,
      match: this.props.match
    });
  }

  _toggleFilterPanel(){
    this.setState({_filterOpen:!this.state._filterOpen})
  }

  _handleToggleFilterPanel(e){
    e.preventDefault();
    this._toggleFilterPanel();
  }

  _handleToggleModalBulkUpload(){
    this.setState({
      _modalBulkUploadOpen: !this.state._modalBulkUploadOpen
    })
  }

  /**
 * Function to handle rendering table's items or rows
 * @author pewe
 */
  _onRenderRowItem(){
    if(this.props.product.list.status_code==200 && this.props.product.list.data.length>0) {
			const { current_page,per_page } =  this.props.product.list;
			return this.props.product.list.data.map((item,idx)=>
				(
					<tr key={parseInt((per_page*(current_page-1))+idx+1)}>
						<th scope="row">{ parseInt((per_page*(current_page-1))+idx+1) }</th>
            <td>{ item.category_id!==null?item.category.name:'-' }</td>
            <td>{ item.brand_id!==null?item.brand.name:'-' }</td>
            <td>
              { item.name } {' '}
              <BadgeThin 
                color={item.active?'success':'default'}
              >
                <i className={classNames('fa',{'fa-times':!item.active, 'fa-check': item.active})}></i> Active
              </BadgeThin>
            </td>
            <td>
              <DateToHuman  value={item.updated_at} id={`tooltip_${generateNumber(per_page,current_page,idx)}`}/>
            </td>
            <td>
							<Link to={`/products/${item.id}`} className="btn btn-link">Details</Link>
						</td>
					</tr>
				)
			)	
		}
		return null;
  }

    /**
   * Function to handle rendering paginations
   * @author pewe
   */
  _onRenderPagination(){
    const { status_code,data } = this.props.product.list;
    const hasData = (status_code===200 && data.length) || null;
    return hasData && (
      <Paginations 
        isLoading={this.props.product.isFetch}
        size="sm" 
        lastPage={this.props.product.list.last_page} 
        perPage={this.props.product.list.per_page}
        total={this.props.product.list.total}
        maxPage={8}
        path={this.props.product.list.path}
        currentPage={this.props.product.list.current_page} 
        prevPageUrl={this.props.product.list.prev_page_url} 
        nextPageUrl={this.props.product.list.next_page_url} 
        handleGotoCallback={this._handleGoto}
        onCurrentPageChanged={currentPage => {
          window.scrollTo({
            top: this.tableRef.offsetTop+25,
            behavior: 'smooth'
          })
        }}
      />
    )
  }

  /**
 * Handle callback when user click pagination items
 * @author pewe
 * @param {integer} page 
 */
  _handleGoto(page){
    const _params=this.state._params;
    _params.page=page;
    this.setState({_params},()=>this._getList(this.state._params));
  }

  _getList(rawParams = {}){
    let params = {};
    Object.keys(rawParams)
    .filter(key => rawParams[key]!='')
    .forEach(key=> params[key] = rawParams[key]);
    this.props.getList({params});
  }

  _refreshList(){
    const {page, ..._params} = this.state._params;
    this.setState({
      _params
    },()=>this._getList(this.state._params));
  }

  _handleParamsChange(e){
    const { _params } = this.state;
    _params[e.target.name] = e.target.value;
    this.setState({_params});
  }

  _handleParamsChangeAndSubmit(e){
    // Exclude page...
    const { page , ..._params } = this.state._params;
    _params[e.target.name] = e.target.value;
    this.setState({_params},()=>{
      this._getList(this.state._params);
    });    
  }

  _handleRefresh(e){
    e.preventDefault();
    this._refreshList();
  }
  
  _handleResetFilter(e){
    e.preventDefault();
    const newState = this.state._filterOpen?{_filterOpen:!this.state._filterOpen}:{};
    this.setState({
      ...newState,
      _params:{
        ...defaultParams
      },
      _selectedCategory: ''
    },()=>this._getList(this.state._params));
  }

  async _handleCategoryChanged(category){
    await this.setState({
      _params: {
        ...this.state._params,
        category_id: category === ''? category: category.value,
        page: 1
      },
      _selectedCategory: category === ''? category: category.label
    })
    this._getList(this.state._params);
  }

  render(){
      return (
        <div className="animated fadeIn">
          <ModalBulkUpload
            isOpen={this.state._modalBulkUploadOpen}
            onToggle={this._handleToggleModalBulkUpload}
            dataBulk={this.props.bulkUpload}
            refreshAfterDone={true}
            onRefresh={this._refreshList}
            onClear={this.props.clearBulkForm}
            onSubmit={this.props.postBulkUpload}
            bulkFormats={bulkProductFormats}
            bulkUrl={'products'}
          />
          <Row>
            <Col xs={12}>
              <Card>
                <CardHeaderWithToolbars>
                  <Title>
                    <i className="fa fa-align-justify"></i>
                    <span>Product List</span>
                  </Title>
                  <Toolbars
                    isOpen={this.state._filterOpen}
                    onToggleModalBulkUpload={this._handleToggleModalBulkUpload}
                    onToggleFilter={this._handleToggleFilterPanel}
                    onRefreshList={this._handleRefresh}
                    onResetFilter={this._handleResetFilter}
                  />
                </CardHeaderWithToolbars>
                <FilterContainer>
                  <FilterPanel 
                    {...this.state._params} 
                    onHandleChange={this._handleParamsChange}
                    onHandleChangeAndSubmit={this._handleParamsChangeAndSubmit}
                    onHandleSearch={this._handleRefresh}
                    isOpen={this.state._filterOpen}
                  />
                </FilterContainer>
                <CardBody>
                  <CardText>
                    In this section will display our products.
                  </CardText>
                  <div className="table-responsive"  ref={tableRef => this.tableRef = tableRef}>
                    <PaginationSummaries
                      from={this.props.product.list.from}
                      to={this.props.product.list.to}
                      total={this.props.product.list.total}
                    />
                    <Tbl>
                      <TblHead
                        renderChildren={()=>(
                          <tr>
                            <Th>No.</Th>
                            <Th>
                              <div style={{display: 'flex', flexDirection: 'row', alignItems: 'flex-end'}}>
                                <p style={{marginRight: 10, marginBottom: 0}}>
                                  Category
                                  {
                                    this.state._selectedCategory !== ''?
                                    <span style={{display: 'block', fontSize: '.5rem', textTransform: 'capitalize'}}>{this.state._selectedCategory}</span>
                                    : null
                                  }
                                </p>
                                <DropdownProductCategories
                                  value={this.state._params.category_id}
                                  onCategoryChanged={category => this._handleCategoryChanged(category)}
                                />
                              </div>
                            </Th>
                            <Th>Brand</Th>
                            <Th>Name</Th>
                            <Th>Updated</Th>
                            <Th>Action</Th>
                          </tr>
                        )}
                      />
                      <TblBody
                        isLoad={this.props.product.isFetch}
                        hasRow={this.props.product.list.status_code===200&&this.props.product.list.data.length>0}
                        columnCount={6}
                      >
                      { this._onRenderRowItem() }
                      </TblBody>
                    </Tbl>
                  </div>
                  { this._onRenderPagination() }
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      )
  }
}
const mapStateToProps= ({product, bulkUpload, auth: { isFetch, roles, isLogged, id, email }}) => {
  return {
    product,
    bulkUpload,
    guardData: { isFetch, roles, isLogged, id, email }
  }
};
const mapDispatchToProps = (dispatch) => {
  return{
    getList:(query) => dispatch(getProductList(query)),
    postBulkUpload:(payload) => dispatch(doBulkProducts(payload)),
    clearBulkForm:()=>dispatch({type:'CLEAR_BULK_PRODUCT'}),
  }
};
const enhance = connect(mapStateToProps,mapDispatchToProps);
export default enhance(privateView(ProductIndex));