import React from 'react';
import {CardBody, Collapse} from "reactstrap";
import {SearchForm, SortForm} from "../";
import {consignmentStatusOptions} from "../../../../../constants/kickavenue";

export default function Filters(props) {
    const {
        isOpen,
        onApplyFilter,
        onHandleSearch,

        keyword,
        setKeyword,
        shippingMethod,
        setShippingMethod,
        seller,
        setSeller,
        perPage,
        sortBy,
        setPerPage,
        setSortBy,
    } = props;

    const handleOnChangeInput = (e, type) => {
        switch (type) {
            case 'keyword':
                setKeyword(e.target.value);
                break;
            case 'shippingMethod':
                setShippingMethod(e.target.value);
                break;
        }
    };

    return (
        <Collapse isOpen={isOpen}>
            <CardBody className="filter-container__card-body">
                <SearchForm
                    keyword = {keyword}
                    seller={seller}
                    shippingMethod={shippingMethod}
                    onSellerChange={setSeller}
                    onInputChange = {handleOnChangeInput}
                    onSearch = {onHandleSearch}
                    onSubmit = {onApplyFilter}
                />
                <hr/>
                <SortForm
                    perPage={perPage}
                    sortBy={sortBy}
                    setPerPage={setPerPage}
                    setSortBy={setSortBy}
                />
            </CardBody>
        </Collapse>
    );
}
