import React, { Component } from "react";
import { connect } from "react-redux";
import "react-select/dist/react-select.css";
import MetaTextEditor from "../Editor/MetaTextEditor"
import Select, { Creatable } from "react-select";
import {
  Row,
  Card,
  CardHeader,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  Alert,
  Button,
  ButtonGroup,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import {
  hasError,
  textValue,
  checkChange,
} from "../../../utils/form";
import classNames from 'classnames';
import { Link } from "react-router-dom";
import privateView from "../../../components/hocs/privateView";
import {
  createMetaData,
  deleteMetaData,
  updateMetaData,
} from "../../../actions/metaDataAction";
import { ValidationMessage } from "../../../components/Form/ValidationMessage";
import SelectBrand from '../../../components/Form/Select/Async/SelectBrand';
import SelectVariant from '../../../components/Form/Select/Async/SelectVariant';
import SelectCollection from '../../../components/Form/Select/Async/SelectCollection';

const page_urls = [
  {
    label: "homepage",
    value: "home",
    type: "static"
  },
  {
    label: "homepage sneakers",
    value: "sneakers",
    type: "static"
  },
  {
    label: "homepage luxury",
    value: "luxury",
    type: "static"
  },
  {
    label: "homepage madeindonesia",
    value: "madeindo",
    type: "static"
  },
  {
    label: "homepage apparels",
    value: "apparels",
    type: "static"
  },
  {
    label: "homepage electronics & collectibles",
    value: "electronics-collectibles",
    type: "static"
  },
  {
    label: "trending",
    value: "trending",
    type: "static"
  },
  {
    label: "hot products",
    value: "hot-products",
    type: "static"
  },
  {
    label: "best selling",
    value: "best-selling",
    type: "static"
  },
  {
    label: "most popular",
    value: "most-popular",
    type: "static"
  },
  {
    label: "price drop",
    value: "price-drop",
    type: "static"
  },
  {
    label: "product detail",
    value: "product-detail",
    type: "dynamic"
  },
  {
    label: "brand",
    value: "brand",
    type: "dynamic"
  },
  {
    label: "collection",
    value: "collection",
    type: "dynamic"
  }
]


const metaDataTypeOptions = [{
  value: "static", label: "Static"
},{
  value: "dynamic", label: "Dynamic"
}];

class FormCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      _edit: this.props.edit,
      _form: {
        _id: this.props.edit ? this.props.match.params.id : null,
        title: "",
        selected: null,
        description: "",
        page_url: "",
        type: "static",
        active: false,
        metable_id:null,
        binding: false,
      },
      _errors:[],
      _formSubmit: false,
      _modalDelete: false,
      isDeleting: false,
      _isSuccessSubmit: false,
      _isModalLoadingOpen: false,
      _isModalDeleteOpen: false,
    };

    this.handleCheckedChange = this.handleCheckedChange.bind(this);
    this.handleChangeEditor = this.handleChangeEditor.bind(this);
    this.toggleModalDelete = this.toggleModalDelete.bind(this);
    this._handleOnSelectedChange = this._handleOnSelectedChange.bind(this);
    this._handleOnSelectOpen = this._handleOnSelectOpen.bind(this);
    this.onDelete = this.onDelete.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    const { detail, isFetch, isSubmit, error } = this.props.metaData;
    if (this.props.edit && detail.status_code === 200) {
      if (prevProps.metaData.isFetch && !isFetch && !this.state._form.binding) {
        try {
          const _form = { ...this.state._form };
          this.setState({ _form }, () => {
            const urls =  detail?.page_url?.split(",").map(url => {
             const page_url=page_urls.filter(u => u.value==url && u.type==detail.type);
             let label = null;
             if(page_url.length) label=page_url[0].label;
              return { label, url, value: url }
            });
            const selected =  detail.metable_id && { id: detail.metable_id, name: detail.metable.name?detail.metable.name:detail.metable.display_name}
            const _newForm = { ...detail, page_url:urls, _id:detail.id, binding:true, selected};

            this.setState({
              _isSuccessSubmit: false,
              _formSubmit:false,
              _form: _newForm,
              platform: detail.platform
            });
          });
        } catch (error) {
          console.log(error)
        }
      }
    }

    if (this.state._formSubmit &&
      prevProps.metaData.isSubmit && !isSubmit
    ) {
      if (this.state.isDeleting) {
        this.setState({ isDeleting: false });
        this.toggleModalDelete();
        this.props.history.replace('/metas')
        return
      }
      if (error === null) {
        if (!this.state._form._id && detail.id) {
          this.props.history.push(`/metas/${detail.id}`)
        }
        this.setState({
          _formSubmit:!this.state._formSubmit,
          _isSuccessSubmit:true
        })
      } else {
        const {_errors}=this.state;
        this.setState({
          _errors: [..._errors, {key:'default',...error}],
          _formSubmit: false,
        });
      }
    }
  }

  renderLoading() {
    return (
      <div className="row">
        <div className="col-sm-12">
          <Alert color="info" className="text-center">
            Getting all data...
          </Alert>
        </div>
      </div>
    );
  }

  renderInfo() {
    let infoMessage = [];
    if (this.state._formSubmit)
      infoMessage.push(
        <Alert color="info" key="info">
          <strong>Heads up!</strong> Submitting...
        </Alert>
      );
    if (this.state._isSuccessSubmit)
      infoMessage.push(
        <Alert color="success" key="success">
          <strong>Well done!</strong> {this.props.metaData.success}
        </Alert>
      );
    if (this.state._errors.length>0) {
      const { status_code, message } = this.state._errors[0];
      switch (status_code) {
        case 422:
          infoMessage.push(
            <Alert color="danger" key="error">
              <strong>Oh Snap!</strong> Please fullfill your form.{" "}
            </Alert>
          );
          break;
        case 404:
          infoMessage.push(
            <Alert color="danger" key="error">
              <strong>Oh Snap!</strong> Resource not found.{" "}
            </Alert>
          );
          break;
        default:
          infoMessage.push(
            <Alert color="danger" key="error">
              <strong>Oh Snap!</strong> {message}
            </Alert>
          );
          break;
      }
    }
    return (
      <div className="row">
        <div className="col-sm-12">{infoMessage}</div>
      </div>
    );
  }

  handleChange(e) {
    const newState = { ...this.state._form };
    newState[e.target.name] = e.target.value;
    this.setState({ _form: newState });
  }

  handleCheckedChange(e) {
    const _form = checkChange(e, this.state._form);
    this.setState({ _form: { ..._form } });
  }

  handleChangeEditor(value) {
    this.setState({
      _form: {
        ...this.state._form,
        description: value.editor.getData(),
      },
    })
  }

  validateError(name){
    if(this.hasError(name))
    {
      let errorList=this.state._errors[0].errors;
      return <ValidationMessage message={errorList[name][0]}/>
    }
    return '';
  }

  _handleOnSelectedChange(option){
    const { _form } =this.state;
    _form.selected = option;
    this.setState({_form});
  }


  _handleOnSelectOpen(){
    if(this.state._form.selected){
      if(this.state._recentSearch){
        this.refs._formSelect.loadSelectOptions(this.state._recentSearch)
      }
      else{
        this.refs._formSelect.loadSelectOptions(this.state._form.selected.name)
      }
    }
    else{
      this.refs._formSelect.loadSelectOptions('')
    }
  }


  hasError(name){
    const { _errors } = this.state;
    if(_errors.length)
    {
      if(_errors[0].errors)
      {
        let errorList=_errors[0].errors;
        let errList=Object.keys(errorList).filter(key=> key==name);
        if(errList.length)
          return true
      }
    }
    return false
  }

  toggleModalDelete() {
    const { _modalDelete } = this.state
    this.setState({ _modalDelete: !_modalDelete })
  }

  validateForm() {
    const {
      title,
      page_url,
      description,
    } = this.state._form;
    let errors={};
    if(!title)
      errors['title']=['Please fill in title.'];
    if(!page_url)
      errors['page_url']=['Please fill in page url.'];
    if(!description)
      errors['description']=['Please fill in description.'];
    if(Object.keys(errors).length){
      this.setState({
        _isSuccessSubmit:false,
        _errors:[{key:'default',errors:{...errors}, status_code:422,message:'Please full fill form'}]
      })
    }
  return errors;
  }

  onSubmit(e) {
    e.preventDefault();
    this.setState({_errors:[]})
    const isValid = this.validateForm()
    if (Object.keys(isValid).length===0) {
      this.setState({ _formSubmit: true}, async () => {
        const {
          _id,
          title,
          type,
          description,
          active,
          page_url,
          selected
        } = this.state._form;
        const payload = {
          title,
          type,
          description,
          page_url: page_url?.length ? page_url.map(u => u.value).join(",") : "",
          metable_id:selected?selected.id:null,
          active
        }
        if(!_id)
          this.props.new(payload);
        else
          this.props.update(_id, payload);
      });
    }
  }



  onDelete(e) {
    e.preventDefault()
    const { _id } = this.state._form
    if (this.props.edit && _id) {
      this.setState({ _formSubmit: true, isDeleting: true }, () => {
        this.props.delete(_id)
      })
    }
  }

  _handleInputChange = ({ target: { name, value } }) => {
    const { _form } = this.state;
    this.setState({
      _form: {
        ..._form,
        [name]: value
      }
    })
  }

  _handleSelectChange = (name, val) => {
    const { _form } = this.state;
    this.setState({ _form:{..._form, [name]: val ? val.value : '',  page_url:null}})
  }

  _handleOnChange(value) {
    this.setState({ _form: {...this.state._form, page_url:value, selected:null}});
  }

  render() {
    const pageUrls=page_urls.filter(u => u.type==this.state._form.type);
    return (
      <Row>
        <Modal
          isOpen={this.state._modalDelete}
          toggle={this.toggleModalDelete}
          className="modal-dialog modal-sm"
        >
          <ModalHeader toggle={this.toggleModalDelete}>
            Confirmation
          </ModalHeader>
          <ModalBody>
            {this.state.isDeleting &&
              this.props.metaData.isSubmit ? (
              <div className="row">
                <div className="col-sm-12">
                  <Alert color="info" className="text-center">
                    Deleting...
                  </Alert>
                </div>
              </div>
            ) : (
              <span>Are you sure to delete this?</span>
            )}
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.onDelete}>
              Yes
            </Button>{" "}
            <Button color="secondary" onClick={this.toggleModalDelete}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>

        <div className="col-md-6 offset-md-3">
          <Card>
            <CardHeader>
              <i className="fa fa-align-justify"></i>{" "}
              <span className="mr-2">{this.props.formTitle}</span>
              {this.props.edit ? (
                <Link
                  to="/metas/create"
                  title="Create New Meta"
                  className="text-right"
                >
                  Create New
                </Link>
              ) : null}
            </CardHeader>
            <CardBody>
              {this.renderInfo()}
              {this.props.edit && this.props.metaData.isFetch ? (
                this.renderLoading()
              ) : (
                <Form onSubmit={this.onSubmit.bind(this)}>
                  <FormGroup
                    className={
                      this.hasError("title") ? " has-danger has-feedback" : ""
                    }
                  >
                    <Label for="title">Title</Label>
                    <Input
                      type="text"
                      id="title"
                      name="title"
                      autoComplete="off"
                      placeholder="Enter a Title*"
                      value={this.state._form.title}
                      onChange={this.handleChange.bind(this)}
                    />
                    {this.validateError("title")}
                  </FormGroup>
                  <FormGroup
                    className={
                      this.hasError("description") ? " has-danger has-feedback" : ""
                    }
                  >
                    <Label for="formDescription">Description</Label>
                    {this.state._edit ? (
                      this.state._form.description !== "" ||
                      this.state._form.binding ? (
                        <MetaTextEditor
                          handleChange={this.handleChangeEditor}
                          value={this.state._form.description}
                        />
                      ) : null
                    ) : (
                      <MetaTextEditor
                        handleChange={this.handleChangeEditor}
                        value={this.state._form.description}
                      />
                    )}
                    {this.validateError("description")}
                  </FormGroup>
                  <FormGroup className={classNames({ 'has-danger has-feedback': this.hasError('platform') })}>
                    <Label>Type</Label>
                    <Select
                      name="type"
                      value={textValue('type', this.state._form)}
                      options={metaDataTypeOptions}
                      onChange={val => this._handleSelectChange('type', val)}
                    />
                    {this.validateError('type')}
                  </FormGroup>
                  <FormGroup className={classNames({ 'has-danger has-feedback': this.hasError('page_url') })}>
                    <Label>Page Url</Label>
                    <Creatable
                      multi
                      options={pageUrls}
                      value={textValue('page_url', this.state._form)}
                      placeholder="Enter page urls"
                      onChange={(value) => this._handleOnChange(value)}
                    />

                    {this.validateError('page_url')}
                  </FormGroup>
                  {(()=>{
                    const page_url = this.state._form.page_url && this.state._form.page_url.length?this.state._form.page_url[0].value:null;
                    switch(page_url){
                      case 'product-detail' : {
                        return(
                          <FormGroup className={classNames({'has-danger':this.hasError('product_variant_id'), 'has-feedback':this.hasError('product_variant_id')})}>
                            <Label for="_formSelectVariant">Choose product variant</Label>
                            <SelectVariant
                              defaultOptions
                              id="_formSelectVariant"
                              ref="_formSelect"
                              placeholder="Select a product..."
                              noResultsText="Cannot find product."
                              cacheOptions={false}
                              value={this.state._form.selected}
                              onSelectSearch={(input)=>this.setState({_recentSearch: input})}
                              onSelectChange={this._handleOnSelectedChange}
                              onSelectOpen={this._handleOnSelectOpen}
                            />
                            {this.validateError('page_url')}
                          </FormGroup>
                        );
                      }
                      case 'collection': {
                        return(
                          <FormGroup  className={classNames({'has-danger has-feedback':hasError('collection',this.state.error)})}>
                            <Label for="formCollection">Choose collection</Label>
                            <SelectCollection
                              defaultOptions
                              cacheOptions={false}
                              value={this.state._form.selected}        
                              id="_formSelectCollection"
                              ref="_formSelect"
                              placeholder="Select a collection..."
                              noResultsText="Cannot find collection."
                              onSelectSearch={(input)=>this.setState({_recentSearch: input})}
                              onSelectChange={this._handleOnSelectedChange}
                              onSelectOpen={this._handleOnSelectOpen}
                            />
                            {this.validateError('page_url')}
                          </FormGroup>
                        )
                      }
                      case 'brand':{
                        return(
                          <FormGroup  className={classNames({'has-danger has-feedback':hasError('collection',this.state.error)})}>
                            <Label for="formBrand">Choose brand</Label>
                            <SelectBrand
                              defaultOptions
                              cacheOptions={false}
                              value={this.state._form.selected}        
                              id="_formSelectBrand"
                              ref="_formSelect"
                              placeholder="Select a brand..."
                              noResultsText="Cannot find brand."
                              onSelectSearch={(input)=>this.setState({_recentSearch: input})}
                              onSelectChange={this._handleOnSelectedChange}
                              onSelectOpen={this._handleOnSelectOpen}
                            />
                            {this.validateError('page_url')}
                        </FormGroup>
                        )
                      }
                      default: return null;
                    }
                  })()}
                  <FormGroup
                    className={classNames({
                      "has-danger has-feedback": hasError(
                        "active",
                        this.state._error
                      ),
                    })}
                  >
                    <Label for="formActive">
                      Set to Active &nbsp;
                    </Label>
                    <label className="switch switch-text switch-success-outline-alt">
                      <input
                        type="checkbox"
                        className="switch-input"
                        name="active"
                        id="formActive"
                        value="true"
                        checked={textValue("active", this.state._form)}
                        onChange={this.handleCheckedChange}
                      />
                      <span
                        className="switch-label"
                        data-on="On"
                        data-off="Off"
                      ></span>
                      <span className="switch-handle"></span>
                    </label>
                    {this.validateError("active")}
                  </FormGroup>
                  <FormGroup>
                    <ButtonGroup>
                      {this.props.edit && this.state._form._id && (
                        <Button
                          className="mr-2"
                          color="danger"
                          onClick={this.toggleModalDelete}
                          disabled={this.state._formSubmit}
                        >
                          Delete
                        </Button>
                      )}
                      <Button color="primary">Submit</Button>
                    </ButtonGroup>
                  </FormGroup>
                </Form>
              )}
            </CardBody>
          </Card>
        </div>
      </Row>
    );
  }
}

const mapStateToProps = ({
  user,
  auth: { isFetch, isLogged, roles, id, email },
  metaData,
}) => {
  return {
    user,
    email,
    guardData: { isFetch, isLogged, roles, id, email },
    metaData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    update: (id, payload) => dispatch(updateMetaData(id, payload)),
    new: (payload) => dispatch(createMetaData(payload)),
    delete: (id) => dispatch(deleteMetaData(id)),
  };
};

const enhance = connect(mapStateToProps, mapDispatchToProps);
export default enhance(privateView(FormCard, ['blog_admin']));
