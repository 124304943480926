import React from 'react';
import propTypes from 'prop-types';
import classNames from 'classnames'

const FontAwesomeIcon = props => {
  const { iconType, className, iconSize, ...otherProps } = props;
  return (
    <i
      className={classNames(`fa fa-${iconType} fa-${iconSize}`, className)}
      {...otherProps}
    ></i>
  )
}

export default FontAwesomeIcon;

FontAwesomeIcon.propTypes = {
  iconType: propTypes.string.isRequired,
  iconSize: propTypes.oneOf(['lg', '1x', '2x', '3x', '4x', '5x']),
  className: propTypes.string
}

FontAwesomeIcon.defaultProps = {
  iconSize: '1x'
}