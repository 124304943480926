import React from 'react';
import { connect } from 'react-redux';
import { RaffleForm } from './RaffleForm';
import privateView from '../../components/hocs/privateView';
import Analytics from "../../services/Analytics";

class RaffleCreate extends React.Component{
	componentDidMount(){
    const { email } = this.props.injectedProps;
    Analytics.recordPageView('raffle_detail', email, {
      url: window.location.href,
      location: this.props.location,
      match: this.props.match
    });
  }
  render(){
    return(
      <div className="animate fadeIn">
        <RaffleForm
          useCrop
          title="Create New Raffle"
          {...this.props}
        />
      </div>
    )
  }
}

const mapStateToProps = ({auth: { isFetch, roles, isLogged, id, email } })=>({
  guardData: { isFetch, roles, isLogged, id, email }
})

const enhance = connect(mapStateToProps);

export default enhance(privateView(RaffleCreate));