import {
	GET_LIST_CONSIGNMENT,
	FETCHING_CONSIGNMENT,
	CONSIGNMENT_ERROR,
	GET_DETAIL_CONSIGNMENT,
	RACK_REQUEST_CONSIGNMENT,
	CREATE_RACK_CONSIGNMENT,
	RACK_ERROR_CONSIGNMENT,
	UPDATE_FORCE_CANCELLED_CONSIGNMENT,
	ADMIN_DELIVERING_SNEAKER_CONSIGNMENT,
	UPDATE_CONSIGNMENT,
	ROLLBACK_CONSIGNMENT,
	FETCHING_CONSIGNMENT_NOTES,
	GET_CONSIGNMENT_NOTES,
	READ_CONSIGNMENT_NOTES,
	CONSIGNMENT_NOTES_SUBMIT,
} from '../constants';

/**
 * Initial State
 */
const initialState = {
	list: [],
	isFetch: false,
	isSubmit: false,
	error: null,
	success: null,
	detail: {},
	rackDetails: {
		isFetch: false,
		isSubmit: false,
		user_sells_count: 0,
		error: null
	},
	notes: {
		sell_consignment_id: null,
		readNotes: {
			data: null,
			error: null,
		},
		list: {
			data: null,
			error: null,
		},
		isFetch: false,
		isSubmit: false
	},
}

/**
 * Function to manipulate payload when success get offer list
 * @author sarah
 * @param {object} lastState
 * @param {object} payload
 */
const onGetConsignmentList = (lastState, payload) => {
	const { data, message, status_code } = payload;
	return { ...lastState, error: null, success: null, detail: {}, list: { ...data, message, status_code } };
}

/**
 * Function to manipulate payload when success get offer details
 * @author sarah
 * @param {object} lastState
 * @param {object} payload
 */
const onGetDetailConsignment = (lastState, payload) => {
	const { data, message, status_code } = payload;
	return { ...lastState, error: null, success: null, detail: { ...data, message, status_code } };
}

/**
 * Function to handle when start or end fetching offer api
 * @author sarah
 * @param {object} lastState
 * @param {object} bool
 */
const onFetchingConsignment = (lastState, payload) => {
	if (payload.type === 'fetch')
		return { ...lastState, isFetch: payload.status }
	else if (payload.type === 'submit')
		return { ...lastState, isSubmit: payload.status }
	else if (payload.type === 'fetch_tracking')
		return { ...lastState, shippingUpdate: { ...lastState.shippingUpdate, isFetch: payload.status } }

	return { ...lastState, isFetch: false, isSubmit: false }
}

/**
 * Function to handle when fetching offer error triggered
 * @author sarah
 * @param {object} lastState
 * @param {object} error
 */
const onConsignmentError = (lastState, payload) => {
	const { error, id } = payload
	return { ...lastState, error: { ...error, id }, success: null };
}


const onUpdateConsignment = (lastState, payload) => {
	const { data, message, status_code } = payload;
	const { detail } = lastState

	const updatedConsignmentList = lastState?.list?.data?.map(item => {
		if (item.id === data.id) {
			return { ...item, ...data }
		}
		return item;
	})
	return {
		...lastState,
		detail: { ...detail, ...data, message, status_code },
		list: { ...lastState.list, data: updatedConsignmentList },
		success: "Update has been successfully!",
		error: null,
	};

}

/**
 * Function to handle when fetching rack list
 * @author sarah
 * @param {object} lastState
 * @param {object} error
 */

const onFetched = (lastState, { requestType, value }) => {
	switch (requestType) {
		case CREATE_RACK_CONSIGNMENT: {
			return { ...lastState, rackDetails: { ...lastState.rackDetails, isSubmit: value } }
		}
		default: return lastState;
	}
}

const onError = (lastState, { requestType, error, ...rest }) => {
	switch (requestType) {
		case CREATE_RACK_CONSIGNMENT: {
			return { ...lastState, rackDetails: { ...lastState.rackDetails, error: { ...error, ...rest } } }
		}
		default: return lastState;
	}
}

/**
 * Offer Reducer
 * @author sarah
 * @param {object} state
 * @param {object} action
 */
const consignmentReducer = (state = initialState, action) => {
	switch (action.type) {
		case FETCHING_CONSIGNMENT: return onFetchingConsignment(state, action.payload)
		case GET_LIST_CONSIGNMENT: return onGetConsignmentList(state, action.payload);
		case GET_DETAIL_CONSIGNMENT: return onGetDetailConsignment(state, action.payload);
		case CONSIGNMENT_ERROR: return onConsignmentError(state, action.payload);

		case ADMIN_DELIVERING_SNEAKER_CONSIGNMENT:
		case UPDATE_FORCE_CANCELLED_CONSIGNMENT:
		case ROLLBACK_CONSIGNMENT:
		case UPDATE_CONSIGNMENT: return onUpdateConsignment(state, action.payload);

		case RACK_REQUEST_CONSIGNMENT: return onFetched(state, action.payload);
		case RACK_ERROR_CONSIGNMENT: return onError(state, action.payload);
		case CREATE_RACK_CONSIGNMENT: {
			const { data: { user_sells_count } } = action.payload;

			return {
				...state,
				rackDetails: {
					...state.rackDetails,
					...action.payload,
					user_sells_count: user_sells_count,
					error: null
				}
			}
		}
		case FETCHING_CONSIGNMENT_NOTES: {
			let payload = {};
			let value = action.payload;
			if (typeof action.payload === 'object') {
				const { error, errorKey, id } = action.payload;
				value = action.payload.value;
				if (errorKey) {
					payload = {
						[errorKey]: {
							...state.notes[errorKey],
							error: { id, ...error }
						}
					}
				} else if (error) {
					payload = { error: { id, ...error } }
				}
			}
			return {
				...state,
				notes: {
					...state.notes,
					...payload,
					isFetch: value
				}
			}
		}
		case CONSIGNMENT_NOTES_SUBMIT: {
			let payload = {};
			let value = action.payload;
			if (typeof action.payload === 'object') {
				const { error, errorKey, id } = action.payload;
				value = action.payload.value;
				if (errorKey) {
					payload = {
						[errorKey]: {
							...state.notes[errorKey],
							error: { id, ...error }
						}
					}
				} else if (error) {
					payload = { error: { id, ...error } }
				}
			}
			return {
				...state,
				notes: {
					...state.notes,
					...payload,
					isSubmit: value
				}
			}
		}
		case GET_CONSIGNMENT_NOTES: {
			const { notes } = state;
			const { data: { data: list, ...pagination } } = action.payload;
			const sellConsignmentId = action.payload.data?.data?.length ? action.payload.data.data[0].sell_consignment_id : null;
			return {
				...state,
				notes: {
					...notes,
					sell_consignment_id: sellConsignmentId,
					list: {
						...action.payload,
						...pagination,
						data: list.map(item => item).reverse(),
						error: null,
					},
					isFetch: false
				}
			}
		}
		case READ_CONSIGNMENT_NOTES: {
			const { notes } = state;
			return {
				...state,
				notes: {
					...notes,
					readNotes: {
						...action.payload,
						error: null
					},
					isSubmit: false
				}
			}
		}

		default: return state;
	}
}

export default consignmentReducer;