import React from 'react';
import propTypes from 'prop-types';
import Loading from 'react-loading-animation';
import classNames from 'classnames';
import {
  Col,
  Row,
  Form,
  Card,
  Input,
  Label,
  Alert,
  FormGroup,
  CardHeader,
  CardBody,
} from 'reactstrap';
import {
  FieldRow,
  FieldColumn,
  FieldLabel,
  FieldValue
} from '../../../../../styled/Fields';
import { _getValue, _getFloatValue, errorMessage, hasError } from '../../../../../utils/form';
import { getPaymentType, kaRaffleRequestStatus } from '../../../../../constants/kickavenue';
import { TextRupiah } from '../../../../../components/Text';
import { ButtonLoading } from '../../../../../components/Button';

const RaffleRequestDetailCard = props => (
  <Card>
    <CardHeader>
      Raffle Request Details
    </CardHeader>
    <CardBody>
    {
      props.isLoading?
        <Loading/>
        :
        <Form
          onSubmit={props.onSubmit}
        >
        <FieldRow>
          <FieldColumn className="col-xs-12 col-md-6">
            <FieldLabel>Raffle Request ID</FieldLabel>
            <FieldValue>
              <span>{_getValue(props.id)}</span>
            </FieldValue>
          </FieldColumn>
        </FieldRow>
        <FieldRow>
          <FieldColumn className="col-12">
            <FieldLabel>Raffle Request Code</FieldLabel>
            <FieldValue>
              <span>{ _getValue(props.code) }</span>
            </FieldValue>
          </FieldColumn>
        </FieldRow>
        <FieldRow>
          <FieldColumn className="col-12">
            <FieldLabel>Raffle Code</FieldLabel>
            <FieldValue>
              <span>{ _getValue(props.raffleCode) }</span>
            </FieldValue>
          </FieldColumn>
        </FieldRow>
        <FieldRow>
            <FieldColumn className="col-12">
              <FieldLabel>Invoice Numb.</FieldLabel>
              <FieldValue>
                <span>{ _getValue(props.payment? props.payment.invoice_number: 'Not Set') }</span>
              </FieldValue>
            </FieldColumn>
          </FieldRow>
        <FieldRow>
          <FieldColumn className="col-xs-12 col-md-6">
            <FieldLabel>Currency</FieldLabel>
            <FieldValue>
              <span>{ _getValue(props.currency) }</span>
            </FieldValue>
          </FieldColumn>
          <FieldColumn className="col-xs-12 col-md-6">
            <FieldLabel>Payment Method</FieldLabel>
            <FieldValue>
              <span>{ getPaymentType(props.paymentMethod) }</span>
            </FieldValue>
          </FieldColumn>
          <FieldColumn className="col-xs-12 col-md-6">
            <FieldLabel>Amount</FieldLabel>
            <FieldValue>
              <TextRupiah
                prefix="Rp. "
                value={_getFloatValue(props.amount)}
              />
            </FieldValue>
          </FieldColumn>
          <FieldColumn className="col-xs-12 col-md-6">
            <FieldLabel>Points</FieldLabel>
            <FieldValue>
            <span>{ _getValue(props.points) }</span>
            </FieldValue>
          </FieldColumn>
          <FieldColumn className="col-xs-12 col-md-6">
            <FieldLabel>Adm. Fee</FieldLabel>
            <FieldValue>
              <TextRupiah
                prefix="Rp. "
                value={_getFloatValue(props.administrationFee)}
              />
            </FieldValue>
          </FieldColumn>
          <FieldColumn className="col-xs-12 col-md-6">
            <FieldLabel>Courier</FieldLabel>
            <FieldValue>
              <span>{ _getValue(props.kaCourier) }</span>
            </FieldValue>
          </FieldColumn>
          <FieldColumn className="col-xs-12 col-md-6">
            <FieldLabel>Courier Services</FieldLabel>
            <FieldValue>
              <span>{ _getValue(props.kaCourierOption) }</span>
            </FieldValue>
          </FieldColumn>
          <FieldColumn className="col-xs-12 col-md-6">
            <FieldLabel>Courier Price</FieldLabel>
            <FieldValue>
              <TextRupiah
                prefix="Rp. "
                value={_getFloatValue(props.kaCourierPrice)}
              />
            </FieldValue>
          </FieldColumn>
        </FieldRow>
        <FieldRow>
          <FieldColumn className="col-xs-12 col-md-6">
            <FieldLabel>Expired At</FieldLabel>
            <FieldValue>
              <span>{ _getValue(props.expiredAt) }</span>
            </FieldValue>
          </FieldColumn>
          <FieldColumn className="col-xs-12 col-md-6">
            <FieldLabel>Updated At</FieldLabel>
            <FieldValue>
              <span>{ _getValue(props.updatedAt) }</span>
            </FieldValue>
          </FieldColumn>
        </FieldRow>
        <Row>
          <Col xs={12}>
            <Alert
              color="success"
              isOpen={props.isSuccessAlertDisplayed}
              toggle={props.alertSuccessToggle}
            >
              <strong>Success. </strong> {props.successMsg}
            </Alert>
            <Alert
              color="danger"
              isOpen={props.isErrorAlertDisplayed}
              toggle={props.alertErrorToggle}
            >
              <strong>Opps. </strong> {props.errorMsg}
            </Alert>
          </Col>
          <Col xs={12} md={6}>
            <FormGroup
              className={classNames({'has-danger has-feedback':hasError('raffle_entry_id',props.error)})}
            >
              <Label for="_formRaffleEntries">
                Raffle Entry
              </Label>
              <Input
                id="_formRaffleEntries"
                type="select"
                name="raffle_entry_id"
                disabled={props.disabledDropdownStatus}
                value={_getValue(props.raffle_entry_id)}
                onChange={props.handleTextChange}
              >
                {
                  props.raffleEntries.map((item, index)=><option value={item.id} key={index}>{item.id}</option>)
                }
              </Input>
              { errorMessage('raffle_entry_id',props.error) }
            </FormGroup>
          </Col>
          <Col xs={12} md={6}>
            <FormGroup
              className={classNames({'has-danger has-feedback':hasError('status',props.error)})}
            >
              <Label for="_formRaffleRequestStatus">
                Status
              </Label>
              <Input
                id="_formRaffleRequestStatus"
                type="select"
                name="status"
                disabled={props.disabledDropdownStatus}
                value={_getValue(props.status)}
                onChange={props.handleTextChange}
              >
                {
                  kaRaffleRequestStatus.map((item, index)=><option value={item.value} key={index}>{item.name}</option>)
                }
              </Input>
              { errorMessage('status',props.error) }
            </FormGroup>
          </Col>
        </Row>
        {
          !props.disabledDropdownStatus ?
          <FormGroup>
            <ButtonLoading
              isLoading={props.isSubmit}
            >
              Update
            </ButtonLoading>
          </FormGroup>
          :
          null
        }
      </Form>
    }
    </CardBody>
  </Card>
)

export default RaffleRequestDetailCard;

RaffleRequestDetailCard.defaultProps = {
  id: 0,
  isLoading: false,
  payment: null,
  paymentMethod: 'bank_transfer',
  amount: 0,
  administrationFee: 0,
  kaCourierPrice: 0
}


RaffleRequestDetailCard.propTypes = {
  isLoading: propTypes.bool,
  id: propTypes.number,
  code: propTypes.string,
  payment: propTypes.object,
  paymentMethod: propTypes.string,
  currency: propTypes.string,
  amount: propTypes.oneOfType([propTypes.number, propTypes.string]),
  administrationFee: propTypes.oneOfType([propTypes.number, propTypes.string]),
  kaCourier: propTypes.string,
  kaCourierOption: propTypes.string,
  kaCourierPrice: propTypes.oneOfType([propTypes.number, propTypes.string]),
  expiredAt: propTypes.string,
  updatedAt: propTypes.string
}