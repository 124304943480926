import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Alert,
  Form,
  Label,
  Col,
  Row,
  Input,
  FormGroup,
  ButtonGroup,
  Button,
  Card,
  CardBody,
  CardHeader,
  // CardText,
  UncontrolledTooltip
} from 'reactstrap';
import uuidv4  from 'uuid/v4';
import Select from 'react-select';
import 'react-select/dist/react-select.css';
import classnames from 'classnames';
import { textValue, checkChange } from '../../utils/form';
import { ImageFile } from '../../components/Images';
import { ModalDelete, ModalBlockLoading as ModalLoading, ModalEditImage } from '../../components/Modals';
import { Radio, RadioText, FormGroupRadio } from '../../components/Form/Radios';
import { ButtonLoading } from '../../components/Button';
import { DropzonePlusButton } from '../../components/Form/DropzoneFile';
import {
  getDetailBrand,
  saveBrand,
  deleteBrand,
  newBrand,
  getBrandList,
  moveBrand,
  deleteBrandImageById,
  saveBrandImage
} from '../../actions/brandAction';
import {
  // uploads,
  uploadImage, 
  deleteImage,
  upload
} from '../../actions/imageUploadAction';
import { getOptionList as getCategoryOptionList } from '../../actions/categoriesAction';
import { ValidationMessage } from '../../components/Form/ValidationMessage';
import { SimpleLineIcon, FontAwesomeIcon } from '../../components/Icons';
import { ImagesForm, ImageList } from './brandForm/ImagesForm';
import { ImagesCompressor, createUploadFileForm } from '../../utils/imageHelper';
import { getOriginFromSignedUrl } from '../../utils/AWS';
import BrandVouchers from './BrandVouchers';
import BrandAuditListCard from './brandForm/BrandAuditListCard';
const moveToOptItem=(item,_lft)=>{
  return {
    value:item.id,
    label:`${item._lft>_lft?'After ':'Before '}${item.name}`,
    after:(item._lft>_lft)
  }
}

const DEFAULT_IMAGE_ASPECT_LANDSCAPE = 16/5;
const DEFAULT_IMAGE_ASPECT_PORTRAIT = 1/1;

const addImageRawObject = file => {
  return {
    identifier: uuidv4(),
    fileInputOrigin: file, //for rollback.
    fileInput: file,
    URL: file.preview,
    status: false,
    isUploading: false,
    compressed: false
  }
}

class BrandForm extends Component{
  constructor(props){
    super(props);
    this.state={
      _edit:this.props.edit,
      _readOnly:this.props.readOnly,
      _hasId:this.props.match.params.id?true:false,
      _parentBrand:[{'value':'',label:'As Root'}],
      _form:{
        binding:false,
        parent:'',
        category_id:null,
        id:this.props.match.params.id,
        img_url:'',
        oldImageUrl: null,
        description: '',
        image: null,
        images: [],
        name:'',
        title:'',
        active:false,
        check_title:false,
        created_at:'',
        updated_at:'',
        audits: []
      },
      _changeForm:{
        newPosition:0,
        after:false
      },
      _errors:[],
      _success:[],
      _images: [],
      _formSubmit:false,
      _changeFormSubmit:false,
      _fetchParentBrandsSelect:false,
      _isModalLoadingOpen: false,
      _modalDelete:false,
      _nextSubmit: null,
      _imagesReady: [],
      modalEditImage: {
        isOpen: false,
        defaultImageRatio: DEFAULT_IMAGE_ASPECT_LANDSCAPE,
        imageSourceEdits: [],
        purpose: "default"
      }
    }
    this.handCheckbox=this.handCheckbox.bind(this);
    this.handleCheckedChange=this.handleCheckedChange.bind(this);
  }

  componentDidMount()
  {
    if(this.state._hasId)
      this.props.find(this.state._form.id);
    else
    {
      this.props.new();
      this.props.rootList();
      this.setState({_fetchParentBrandsSelect:true});
    }
    if(!this.props.categoryOptions.length)
      this.props.bindCategoryOption();
  }

  componentDidUpdate(prevProps){
    const { detail, isFetch, isSubmit, error, list } = this.props.masterBrand;
    if(prevProps.imageUpload.isUpload && prevProps.imageUpload.isUpload !== this.props.imageUpload.isUpload) {
      const payloads = [];
      const { success, error } = this.props.imageUpload;
      const { _form: { id, image }, _images, _nextSubmit } = this.state;
      if(error){
        this.setState({
          _formSubmit: false,
          _isModalLoadingOpen: false,
          _errors: [
            ...this.state._errors,
            { key: 'default', status_code: 500, message: '!' }
          ],
        })
      }
      if(!error){
        const { data } = success.data;
        if(image && image.identifier === success.data.identifier && _nextSubmit === 'SUBMIT_DATA_FORM'){
          this.setState({
            _formSubmit: true,
            _form: {
              ...this.state._form,
              image: null,
              img_url: success.data.url,
              oldImageUrl: success.data.url
            },
            _nextSubmit: _images.length>0?'UPLOAD_IMAGES': null
          }, () => {
            this._submitBrandApi();
          })
        }
        else{
          if(data.length>0){
            data.map(item => {
              if( _images.length>0 && _nextSubmit === 'SUBMIT_IMAGE_DATA'){
                payloads.push({orientation: item.orientation, url: item.url});
              }
            });
          }
        }
        if(payloads.length>0){
          this.setState({
            _images:[],
          },()=>{
            this.props.saveBrandImage(id, { images: [...payloads] })
          })
        }
      }
    }

    if(prevProps.masterBrand.isImageDataSubmit && !this.props.masterBrand.isImageDataSubmit){
      const { error } = this.props.masterBrand;
      if(error){
        this.setState({
          _formSubmit: false,
          _isModalLoadingOpen: false,
          _errors: [
            ...this.state._errors,
            { key: 'default', status_code: 500, message: 'An error occured!' }
          ],
        })
      } else{
        const { _form: { id } } = this.state;
        this.setState({
          _nextSubmit: 'SYNC_IMAGES'
        })
        this.props.find(id)
      }
    }
    if((!isFetch&&prevProps.masterBrand.isFetch)||(!isSubmit&&prevProps.masterBrand.isSubmit)){
      // Edit Form
      if(this.state._nextSubmit === 'SYNC_IMAGES'){
        if(!error){
          if(this.props.match.params.id != detail.id){
            this.props.history.replace(`/brands/${detail.id}`)
          }
          this.setState({
            _form:{
              ...this.state._form,
              id: detail.id,
              img_url: detail.signed_url? detail.signed_url: detail.img_url,
              oldImageUrl: detail.signed_url? detail.signed_url: detail.img_url,
              images: [...detail.images],
            },
            _imagesReady: [],
            _nextSubmit: null,
            _isModalLoadingOpen: false
          })
        }
      }
      if(this.state._hasId){
        // Checking if already ok after get detail from api
        if(detail.status_code===200&&error===null){
          // Binding data when load page and after get detail from api

          if(!this.state._form.binding){
            const _form  ={ ...this.state._form };
            this.props.rootList();
            this.setState({
              _form: {
                ..._form,
                binding: true,
                ...detail,
                description: detail.description||'',
                img_url: detail.signed_url? detail.signed_url: detail.img_url,
                oldImageUrl: detail.signed_url? detail.signed_url: detail.img_url,
                parent: detail.ancestors.length?detail.ancestors[0].id:'',
                audits: detail.audits
              },
              _imagesReady: [],
              _images: [],
              _fetchParentBrandsSelect:true
            });
          }
          else if(list.status_code===200&&this.state._fetchParentBrandsSelect){
            this.setState({_fetchParentBrandsSelect:!this.state._fetchParentBrandsSelect},()=>{
              const getList=list.data.filter(item=>item.id!=this.state._form.id).map(item=>{return {value:item.id,label:item.name}})
              this.setState({_parentBrand:[{'value':'',label:'As Root'},...getList]});
            })
          }
          else if(this.state._formSubmit){
            if(detail.delete){
              this.props.history.replace('/brands')
            }
            this.setState({
              _formSubmit: false,
              _success: [
                ...this.state._success,
                {key:'default',message:this.props.masterBrand.success}
              ],
              _form: {
                ...this.state._form,
                ...detail,
                description: detail.description||'',
                img_url: detail.signed_url? detail.signed_url: detail.img_url,
                oldImageUrl: detail.signed_url? detail.signed_url: detail.img_url,
              },
              _isModalLoadingOpen: this.state._nextSubmit !== null
            }, () =>{
              if(this.state._nextSubmit)
                this._uploadManyImagesProgress()
            })
          }
          else if(this.state._changeFormSubmit){
            this.setState({_changeFormSubmit:!this.state._changeFormSubmit},()=>{
              const _newForm  ={ ...this.state._form,...detail };
              const {_success}=this.state;
              _success.push({key:'change_position',message:this.props.masterBrand.success});
              this.setState({_form:_newForm,_changeForm:{newPosition:0,after:true},_success});
            });
          }
        }
        else{
          const {_errors}=this.state;
          this.setState({
            _errors: [..._errors, {key:'default',...error}],
            _formSubmit: false,
            _changeFormSubmit: false,
            _isModalLoadingOpen: false
          });
        }
      }
      else{
        if((list.status_code===200||detail.status_code===200)&&error===null){
          if(this.state._formSubmit
            && !isSubmit){
            const { _nextSubmit }=this.state;
            if(!_nextSubmit){
              this.props.history.replace(`/brands/${detail.id}`)
            }
            else if(_nextSubmit === 'UPLOAD_ICON_IMAGE' || _nextSubmit ==='UPLOAD_IMAGES'){
              this.setState({
                _form: {
                  ...this.state._form,
                  id: detail.id
                },
                _formSubmit: _nextSubmit === 'UPLOAD_ICON_IMAGE'
              }, () => {
                this._uploadManyImagesProgress()
              })
            }
          }
          else if(this.state._fetchParentBrandsSelect){
            const getList=list.data.map(item=>{return {value:item.id,label:item.name}})
            this.setState({_parentBrand:[{'value':'',label:'As Root'},...getList],_fetchParentBrandsSelect:!this.state._fetchParentBrandsSelect});
          }
        }
        else{
          const {_errors}=this.state;
          this.setState({
            _errors: [..._errors, {key:'default',...error}],
            _formSubmit: false,
            _changeFormSubmit: false,
            _isModalLoadingOpen: false
          });
        }
      }
    }
  }

  _uploadManyImagesProgress = (s3Results = null) => {
    const { _images, _imagesReady, _nextSubmit, _form, _form: { id, image } } = this.state;
    if(_nextSubmit === 'UPLOAD_ICON_IMAGE'){
      this.setState({
        _form: { ..._form, image: { ...image, isUploading: true }},
        _isModalLoadingOpen: true,
        _nextSubmit: 'SUBMIT_DATA_FORM'
      })
      const tempPayload = createUploadFileForm(image.fileInput, false, {name: "brand-images/"+id, bucket_type: "assets"});
      this.props.uploadImage(tempPayload, image);
    } else{
      if(_images.length){
        this.setState({
          _isModalLoadingOpen: true,
          _nextSubmit: 'SUBMIT_IMAGE_DATA'
        },()=>{          
          const tempPayload = createUploadFileForm(
            _images.map(img => img.fileInput),
            true,
            { name: "brand-images/"+id, bucket_type: "assets" }
          );
          this.props.uploadImage(tempPayload, _images);
        })
      }
    }
  }

  clearForm(){
    return {name:'',title:'', description: '',img_url:'',parent:'','image':{},category_id:null};
  }
  /**
 * Handle to change local state from input uset like textfield
 * @author pewe
 * @param {event} e
 */
  handleChange(e){
    const newState={...this.state._form};
    newState[e.target.name]=e.target.value;
    this.setState({_form:newState});
  }

  handleCheckedChange(e){
    const _form=checkChange(e,this.state._form);
    this.setState({_form});
  }

  handCheckbox(e){
    const newState={...this.state._form};
    newState[e.target.name]=e.target.checked;
    newState.title=e.target.checked?newState.name:'';
    this.setState({_form:newState});
  }
  _validateForm(){
    const {name,parent,category_id}= this.state._form;
    let errors={};
    if(name==='')
      errors['name']=['Please fill in Brand Name.'];
    if(!category_id&&!parent)
      errors['category_id']=['Please fill in Category.'];
    if(Object.keys(errors).length){
      this.setState({
        _errors:[{key:'default',errors:{...errors}, status_code:422,message:'Please full fill form'}]
      })
    }
    return errors;
  }

  _submitBrandApi = () => {
    const {id, name, title, oldImageUrl, parent, active, category_id, description}= this.state._form;
    const payload = {
      name,
      title,
      // img_url: this.state._form.image? oldImageUrl: img_url,
      img_url: getOriginFromSignedUrl(oldImageUrl),
      parent,
      active,
      category_id,
      description
    };
    if(id){
      payload.id = id;
    }
    this.props.save(payload);
  }

  handleOnSubmit = async (e) => {
    e.preventDefault();
    const { _formSubmit, _form, _images } = this.state;
    if(!_formSubmit){
      const valid = this._validateForm();
      if (Object.keys(valid).length===0) {
        const hasIconImage = _form.image !== null;
        let compressed = await this._compressMultipleImages(
          hasIconImage? [{..._form.image}, ..._images ]: [ ..._images ]
        )
        let iconImage = null;
        if(hasIconImage){
          const [first, ...childs] = compressed;
          compressed = childs;
          iconImage = first;
        }
        this.setState({
          _formSubmit:true,
          _errors: [],
          _form: {
            ..._form,
            image: iconImage? {...iconImage}: iconImage
          },
          _images: compressed? [...compressed]: [],
          _nextSubmit: iconImage?'UPLOAD_ICON_IMAGE': (compressed && compressed.length? 'UPLOAD_IMAGES': null),
          _isModalLoadingOpen: hasIconImage || (compressed && compressed.length)? true: false
        },()=>{
          this._submitBrandApi();
        });
      }
    }
  }
  renderLoading(){
    return (
      <div className="row">
        <div className="col-sm-12">
          <Alert color='info' className="text-center">Getting all data...</Alert>
        </div>
      </div>
    )
  }
  renderInfo(type='default'){
    let infoMessage=[];
    const {_errors,_success}=this.state;
    if(type=='default'){
      if(this.state._formSubmit)
        infoMessage.push(
          <Alert color='info' key="info"><strong>Heads up!</strong> Submitting...</Alert>
        );
    }
    else{
      if(this.state._changeFormSubmit)
        infoMessage.push(
          <Alert color='info' key="info"><strong>Heads up!</strong> Submitting...</Alert>
        );
    }
    if(_success.filter(item=>item.key==type).length)
      infoMessage.push(
        <Alert color='success' key="success"isOpen={true}
        toggle={()=>{
          this.setState({_success: _success.filter(item=>item.key!=type)})
        }}><strong>Well done!</strong> { _success.filter(item=>item.key==type)[0].message }</Alert>
      );
    if(_errors.filter(item=>item.key==type).length)
    {
      const { status_code } = _errors.filter(item=>item.key==type)[0];
      switch(status_code)
      {
        case 422:
          infoMessage.push(
            <Alert color='danger'
              key="error422"
              isOpen={true}
              toggle={()=>{
                this.setState({_errors: _errors.filter(item=>item.key!=type)})
              }}
            >
              <strong>Oh Snap!</strong> Please fullfill your form.
            </Alert>
          );
          break;
        case 404:
          infoMessage.push(
            <Alert color='danger' key="error404"  isOpen={true}
            toggle={()=>{
                this.setState({_errors: _errors.filter(item=>item.key!=type)})
            }}><strong>Oh Snap!</strong> Resource not found. </Alert>
          );
          break;
        default:
          infoMessage.push(
            <Alert color='danger' key={`error${status_code}`} isOpen={true}
            toggle={()=>{
                this.setState({_errors: _errors.filter(item=>item.key!=type)})
            }}><strong>Oh Snap!</strong> We've got something errors </Alert>
          );
          break;
      }
    }
    return(
      <div className="row">
        <div className="col-sm-12">
          {infoMessage}
        </div>
      </div>
    )
  }

  validateError(name){
    if(this.hasError(name))
    {
      let errorList=this.state._errors[0].errors;
      return <ValidationMessage message={errorList[name][0]}/>
    }
    return '';
  }

  hasError(name){
    const { _errors } = this.state;
    if(_errors.length)
    {
      if(_errors[0].errors)
      {
        let errorList=_errors[0].errors;
        let errList=Object.keys(errorList).filter(key=> key==name);
        if(errList.length)
          return true
      }
    }
    return false
  }

  toggle = () => {
    this.setState({_modalDelete:!this.state._modalDelete});
  }

  onDelete = () => {
    this.setState({
      _formSubmit: true
    }, () => {
      this.props.delete(this.state._form.id);
      this.toggle();
    })
  }

  onSelectChange(val, attributeForm){
    const { _form } =this.state;
    _form[attributeForm]=val===null?val:val.value;
    if(attributeForm=='parent'&&val!==null&&val.value)
      this.setState({_form:{..._form, category_id:null}});
    else
      this.setState({_form});
  }


  _compressImage = async (image) => {
    let fileInput = image
    try{
      //compress file
      fileInput = await ImagesCompressor(image);
    }
    catch(e){
      alert('error while compress the images')
      fileInput = image
    }
    return fileInput;
  }

  _compressMultipleImages = async (images) => {
    const imgs = images.filter(img => !img.commpressed)
    if(imgs.length){
      const compressedPromises = images.map(async(img) =>{
        const compressed = await this._compressImage(img.fileInput)
        return {
          ...img,
          fileInput: compressed,
          compressed: true
        }
      })
      const results = await Promise.all(compressedPromises)
      let temp = [];
      if(results.length){
        temp = images.map(draft => {
          const data = results.find(r => r.identifier === draft.identifier);
          if(data){
            return data
          }
          return draft
        })
      }
      return temp;
    }
    return images;
  }

  _handleDropzoneChange = (files, type) => {
    if(files.length){
      if(type === 'default'){
        const [img] = files;
        const rawObj = addImageRawObject(img);
        this.setState({
          _form: {
            ...this.state._form,
            image: { ...rawObj },
            img_url: img.preview
          },
          modalEditImage: {
            ...this.state.modalEditImage,
            isOpen: true,
            imageSourceEdits: [
              ...this.state.modalEditImage.imageSourceEdits,
              {
                imgId: rawObj.identifier,
                src: rawObj.URL
              }
            ],
            purpose: 'default',
            defaultImageRatio: DEFAULT_IMAGE_ASPECT_LANDSCAPE
          }
        });
      }
      else{
        const fileInputs = files.map(c => ({
          ...addImageRawObject(c)
        }));
        if(fileInputs.length){
          const { _images } = this.state;
          this.setState({
            _images: [..._images, ...fileInputs],
            modalEditImage: {
              ...this.state.modalEditImage,
              isOpen: true,
              imageSourceEdits: [
                ...this.state.modalEditImage.imageSourceEdits,
                ...fileInputs.map(f => ({
                  imgId: f.identifier,
                  src: f.URL
                }))
              ],
              purpose: 'child',
              defaultImageRatio: DEFAULT_IMAGE_ASPECT_PORTRAIT
            }
          });
        }
      }
    }
  }

  onCropSucceeded = async (blob, ratio) => {
    const blobUrl = URL.createObjectURL(blob);
    let fileName = blobUrl.substr(blobUrl.lastIndexOf('/')+1)
    if(fileName == '') fileName = 'local'
    const { modalEditImage, _form, _images } = this.state;
    if(modalEditImage.purpose === 'default'){
      this.setState({
        _form: {
          ..._form,
          image: {
            ..._form.image,
            fileInput: new File([blob], `${fileName}.jpeg`, {
              type: 'image/jpeg'
            }),
            URL: blobUrl,
            orientation: ratio.ratio <=1? 'portrait': 'landscape'
          },
          img_url: blobUrl
        },
        modalEditImage: {
          ...modalEditImage,
          isOpen: false,
          imageSourceEdits: []
        }
      })
    }
    else{
      let identifier = 0;
      if(modalEditImage.imageSourceEdits.length)
        identifier = modalEditImage.imageSourceEdits[0].imgId;

      this.setState({
        _images: _images.map(item => {
          if(item.identifier === identifier){
            return {
              ...item,
              fileInput: new File([blob], `${fileName}.jpeg`, {
                type: 'image/jpeg'
              }),
              URL: blobUrl,
              orientation: ratio.ratio <=1? 'portrait': 'landscape'
            }
          }
          return item;
        }),
        modalEditImage: {
          ...modalEditImage,
          isOpen: false,
          imageSourceEdits: modalEditImage.imageSourceEdits
            .filter(item => item.imgId !== identifier)
        }
      }, () => {
        const { modalEditImage, modalEditImage: {imageSourceEdits} } = this.state;
        if(imageSourceEdits.length){
          setTimeout(() => {
            this.setState({
              modalEditImage: {
                ...modalEditImage,
                isOpen: true
              }
            })
          }, 1500)
        }
      })
    }
  }
  onDeleteImage = (data) => {
    if(data.deleted)
      this.props.deleteBrandImageById(this.state._form.id, data.id)
    else
      this.props.saveBrandImage(this.state._form.id, data)
  }
  render(){
    return(
      <Row>
        <ModalEditImage
          isOpen={this.state.modalEditImage.isOpen}
          imageSource={this.state.modalEditImage.imageSourceEdits.length? this.state.modalEditImage.imageSourceEdits[0].src: null}
          showGrid
          ratio={this.state.modalEditImage.defaultImageRatio}
          onCropSucceeded={this.onCropSucceeded}
        />
        <ModalDelete
          isOpen={this.state._modalDelete}
          modalTitle="Confirmation."
          modalBody="Are you sure to delete this?"
          onDelete={this.onDelete}
          toggle={this.toggle}
        />
        <ModalLoading isOpen={this.state._isModalLoadingOpen}/>
        <Col xs={12} md={6}>
          <Card>
            <CardHeader>
              <FontAwesomeIcon iconType="align-justify"/> {this.props.title}
              {this.props.edit?
                <div>
                  <Link style={{fontSize: '.65rem'}} to="/brands/create">Create new Brand</Link>
                </div>
              :null}
            </CardHeader>
            <CardBody>
            { this.renderInfo() }
            {
              this.props.masterBrand.isFetch && !this.state._form.binding?
                this.renderLoading()
              :
              <Form onSubmit={this.handleOnSubmit}>
                <FormGroup
                  className={classnames({ 'has-danger has-feedback': this.hasError('img_url') })}
                >
                  <Label>Icon - optional <SimpleLineIcon id="questionIcon" iconType="question"/></Label>
                  <UncontrolledTooltip
                    placement="right"
                    target="questionIcon"
                    className="tooltip-check-title"
                  >
                    An icon image for its brand
                  </UncontrolledTooltip >
                  <div className="d-flex">
                    <ImageFile
                      style={{padding: 0}}
                      alt="App section's default image"
                      src={this.state._form.img_url? this.state._form.img_url : 'http://via.placeholder.com/500x500'}
                      showDelete={this.state._form.image !== null}
                      onDelete={() => {
                        this.setState({
                          _form:{
                            ...this.state._form,
                            img_url: this.state._form.oldImageUrl,
                            image: null
                          }
                        })
                      }}
                    />
                    <div className="ml-3">
                      <DropzonePlusButton mulitple={false} onDrop={files => this._handleDropzoneChange(files, 'default')}/>
                      <ul className="image-guides" style={{fontSize: '.75rem', marginTop: '.75rem', paddingInlineStart: '1rem'}}>
                        <li className="d-none">You can click the image for preview the image.</li>
                        <li>Use 500x500 (1:1) resolution of your image as our recommended. (In pixels).</li>
                        <li>Or anything resolution, but make sure its <strong>squared</strong> dimensions.</li>
                        <li>By changing the image, click Submit button for save your changes.</li>
                      </ul>
                    </div>
                  </div>
                  { this.validateError('img_url') }
                </FormGroup>
                <Row>
                  <Col xs={12} md={6}>
                    <FormGroup className={this.hasError('parent')?' has-danger has-feedback':''}>
                      <Label for="formParentBrand">Parent Brand</Label>
                      <Select
                        id="formParentBrand"
                        name="parent"
                        value={this.state._form.parent}
                        options={this.state._parentBrand}
                        onChange={(val)=>{this.onSelectChange(val,'parent')}}
                      />
                      {this.validateError('parent')}
                    </FormGroup>
                  </Col>
                  {
                    !this.state._form.parent ?
                    <Col xs={12} md={6}>
                      <FormGroup className={this.hasError('category_id')?' has-danger has-feedback':''}>
                        <Label for="formBrandTitle">Select Category</Label>
                        <Select
                          id="formCategoryName"
                          name="category_id"
                          value={this.state._form.category_id}
                          options={this.props.categoryOptions.options.length?this.props.categoryOptions.options:[{value:'',label:'Loading...'}]}
                          onChange={(val)=>{this.onSelectChange(val,'category_id')}}
                        />
                        {this.validateError('category_id')}
                      </FormGroup>
                    </Col>
                    :
                    <Col xs={12} md={6}>
                      <p style={{fontSize:"9px"}}>Don't need category when you can't pick parent brand as root.</p>
                    </Col>
                  }
                </Row>
                <Row>
                  <Col xs={12} md={6}>
                    <FormGroup className={this.hasError('name')?' has-danger has-feedback':''}>
                      <Label for="formBrandName">Brand Name</Label>
                      <Input
                        type="text"
                        id="formBrandName"
                        name="name"
                        placeholder="Enter a name*"
                        value={this.state._form.name}
                        onChange={this.handleChange.bind(this)}
                      />
                      {this.validateError('name')}
                    </FormGroup>
                  </Col>
                  <Col xs={12} md={6}>
                    <FormGroup className={this.hasError('title')?' has-danger has-feedback':''}>
                      <Label for="formBrandTitle" id="formBrandTitle">
                        <Input
                          style={{marginLeft:0}}
                          type="checkbox"
                          name="check_title"
                          onChange={this.handCheckbox}
                          checked={this.state._form.check_title}
                        />
                        <span style={{marginLeft:"1rem"}}>Brand Title</span>
                        <UncontrolledTooltip
                          placement="right"
                          target="formBrandTitle"
                          className="tooltip-check-title"
                        >
                          Check for same with brand name
                        </UncontrolledTooltip>
                      </Label>
                      <Input
                        type="text"
                        id="formBrandTitle"
                        name="title"
                        placeholder="Enter a title"
                        value={this.state._form.title}
                        disabled={this.state._form.check_title}
                        onChange={this.handleChange.bind(this)}
                      />
                      {this.validateError('title')}
                    </FormGroup>
                  </Col>
                </Row>
                <FormGroup className={this.hasError('description')?' has-danger has-feedback':''}>
                  <Label>Description</Label>
                  <Input
                    type="textarea"
                    name="description"
                    placeholder="Enter a description"
                    value={this.state._form.description}
                    onChange={this.handleChange.bind(this)}
                  />
                  {this.validateError('description')}
                </FormGroup>
                <FormGroupRadio
                  className={classnames({ 'has-danger has-feedback': this.hasError('active') })}
                >
                  <Radio
                    type="checkbox"
                    name="active"
                    value={true}
                    checked={textValue('active', this.state._form)}
                    dataOnText="Yes"
                    dataOffText="No"
                    onChange={this.handleCheckedChange}
                  />
                  <RadioText text="Set to Active"/>
                  { this.validateError('active') }
                </FormGroupRadio>
                {
                  this.props.edit?(
                    <div>
                      <FormGroup>
                        <Label>Created At</Label>
                        <Input
                          type="text"
                          readOnly
                          value={this.state._form.created_at}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label>Updated At</Label>
                        <Input
                          type="text"
                          readOnly
                          value={this.state._form.updated_at}
                        />
                      </FormGroup>
                    </div>
                  ):null
                }
                <FormGroup>
                  <ButtonGroup>
                    <ButtonLoading
                      disabled={this.state._formSubmit}
                      isLoading={this.state._formSubmit}
                      loadingMessage="Submitting..."
                      color="primary"
                    >
                      Submit
                    </ButtonLoading>
                    {/* {
                      this.state._hasId?
                      <Button  color="danger" onClick={this.toggle.bind(this)}>Delete</Button>
                      :null
                    } */}
                  </ButtonGroup>
                </FormGroup>
              </Form>
            }
            </CardBody>
          </Card>
        </Col>
        <Col xs={12} md={6}>
          {this.state._hasId?(
            <Card>
              <CardHeader>
                <i className="fa fa-align-justify"></i> Change Position
              </CardHeader>
              <CardBody>
              { this.renderInfo('change_position') }
              {
                this.props.masterBrand.isFetch && !this.state._form.binding?
                  this.renderLoading()
                :
                <Form onSubmit={(e)=>{e.preventDefault();
                  const {_changeForm,_form}=this.state;
                  if(_changeForm.newPosition!==0)
                  {
                      this.props.move(_form.id,_changeForm.newPosition,_changeForm.after);
                      this.setState({_changeFormSubmit:true});
                  }}}>
                  <Row>
                    <Col xs={12} md={6}>
                      <FormGroup>
                        <Select
                          id="formMoveTo"
                          value={this.state._changeForm.newPosition}
                          options={this.state._form.siblings?this.state._form.siblings.map(item=>moveToOptItem(item,this.state._form._lft)):[]}
                          onChange={(val)=>{
                            this.setState({
                              _changeForm: {
                                after: val? val.after: false,
                                newPosition: val? val.value: 0,
                              }
                            });
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={12} md={6}>
                      <FormGroup>
                        <Button  color="primary">Change</Button>
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
              }
              </CardBody>
            </Card>
          ): null}
          {
            this.state._hasId?(
              <BrandVouchers
                brandIsFetch={this.props.masterBrand.isFetch}
                brandId={this.props.match.params.id}
                dataSource={
                  this.props.masterBrand.detail.status_code === 200 &&
                  this.props.masterBrand.detail.brand_vouchers !==
                    undefined
                    ? this.props.masterBrand.detail.brand_vouchers
                    : []
                }
              />
            )
            :
            null
          }
          <Card>
            <CardHeader>
              <FontAwesomeIcon className="mr-2" iconType="image"/>
              <span className="mr-1">Background Images</span>
              <SimpleLineIcon id="imagesCardTitleTip" iconType="question"/>
              <UncontrolledTooltip innerClassName="text-left" placement="right" target="imagesCardTitleTip">
                Please provide at least one image!
              </UncontrolledTooltip>
            </CardHeader>
            <CardBody>
              <ul className="image-guides" style={{fontSize: '.75rem', paddingInlineStart: '1rem'}}>
                <li className="d-none">You can click the image for preview the image.</li>
                <li>It will be display on Web and App.</li>
                <li>Use 1280x400 (16:5),  1280x720 (16:9) or  1280x465,45 (11:4) resolution of your image as our recommended for our web. (In pixels). Or any resolution that you want, but make sure its <strong>landscape</strong> orientation.</li>
                <li>Use 700x700 (1:1),  700x933,33 (3:4) or  700x1050 (4:6) resolution of your image as our recommended for our app display. (In pixels). Or any resolution that you want, but make sure its <strong>portrait</strong> orientation.</li>
                <li>You can upload multiple images, it will be sort by latest uploaded. (By default).</li>
                <li>By changing the images, click Submit button for save your changes in "General Form".</li>
              </ul>
              <ImagesForm
                showItems={!(!this.props.match.params.id)}
                images={this.state._form.images}
                onClearImageFiles={() => {
                  this.setState({
                    _images: []
                  })
                }}
                onDeleteFile={index => {
                  const { _images } = this.state;
                  this.setState({
                    _images: _images.filter((_img, idx) => idx !== index)
                  })
                }}
                onDropFiles={files => this._handleDropzoneChange(files, 'multiple')}
                imageFiles={this.state._images}
              >
                <ImageList
                  isLoading={this.props.masterBrand.isImageDataSubmit}
                  images={this.state._form.images}
                  onSaveImage={this.onDeleteImage}
                />
              </ImagesForm>
            </CardBody>
          </Card>
        </Col>
        {this.state._form.binding && (
          <Col xs={12}>
          <BrandAuditListCard 
            id={this.state._form.id}
            type="brand"
            refresh={!this.props.masterBrand.isSubmit}
          />
        </Col>
        )}
      </Row>
    )
  }
}

const mapStateToProps= (state) => {
	return {
		masterBrand:state.masterBrand,
    imageUpload:state.imageUpload,
    categoryOptions:state.masterCategories
	}
};
const mapDispatchToProps = (dispatch) => {
	return{
    rootList:()=>dispatch(getBrandList()),
		find:(id) => dispatch(getDetailBrand(id)),
		delete:(id) => dispatch(deleteBrand(id)),
		save:(payload) => dispatch(saveBrand(payload)),
    new:() => dispatch(newBrand()),
		move: (id, to, after) => dispatch(moveBrand(id, to, after)),
    upload: (file,folder)=>dispatch(upload(file,folder)),
    bindCategoryOption:()=>dispatch(getCategoryOptionList()),
    deleteBrandImageById: (brandId, id) => dispatch(deleteBrandImageById(brandId, id)),
    saveBrandImage: (brandId, payload) => dispatch(saveBrandImage(brandId, payload)),
    uploadImage: (payload, rawImages) => dispatch(uploadImage(payload, rawImages)),
    deleteImage: (payload) => dispatch(deleteImage(payload)),
	}

};
export default connect(mapStateToProps,mapDispatchToProps)(BrandForm);