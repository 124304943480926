import { Button } from 'reactstrap';
import styled from 'styled-components';


export const ButtonXs = styled(Button)`
  font-size: .5rem;
  border-radius: 0px;
  box-shadow: none;
  &.btn-gray{
    color: #4a4a4a;
    background: #fff;
    border: 1px solid #4a4a4a;
    
    &:hover, :focus, :active, :hover{
      background: #ddd;
    }
    &.active, &.active:focus, &.active:active, &.active:hover{
      color: #fff;
      background: #4a4a4a;
      &.disabled{
        opacity: 1;
      }
    }
  }
  &.btn-outline-gray {
    color: #4a4a4a;
    border-color: #4a4a4a;
    background-color: transparent;
    box-shadow: none;
    &:focus, &:hover, &:active{
      color: #fff;
      background-color: #4a4a4a;
    }
  }
`;