import React from 'react';
import {
  Card,
  CardImg,
  CardText,
  CardLink,
  CardBody,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';
import FontAwesomeIcon from '../../../../../components/Icons/FontAwesomeIcon';
import CardLoadingPlaceholder from '../../../../../components/Card/CardLoadingPlaceholder';
import propTypes from 'prop-types';
import UploadCard from './UploadCard';
import styled from 'styled-components';
import { getCacheImage } from '../../../../../utils/imageHelper'

const CardFooter = styled.div`
  justify-content: center;
  display: flex;
  align-items: center;
`;

const DropdownContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: flex-end;
  justify-content: flex-end;
`;

class ImageCard extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      id: props.id,
      isDeleting: false,
      isUploading: false,
      isEdit: false,
      dropdownOpen: false
    }
    this._onRemoveImage = this._onRemoveImage.bind(this);
    this._handleToggleMenu = this._handleToggleMenu.bind(this);
    this._onChangeImage = this._onChangeImage.bind(this);
  }

  componentDidUpdate(prevProps) {
    if(prevProps.isProcessed && prevProps.isProcessed !== this.props.isProcessed){
      this.setState({
        isUploading: false,
        isDeleting: false
      });
    }
  }

  _onRemoveImage(evt){
    this.setState({
      isDeleting: true
    });
    this.props.onRemoveImage(evt);
  }

  _onChangeImage(evt){
    if (evt.target.files && evt.target.files[0]){
      const { files } = evt.target;
      evt.persist();
      let reader = new FileReader();
      reader.onload = (e) => {
        files[0].preview = e.target.result;
        this.setState({
          isUploading: true,
          isEdit: false
        });
        this.props.onChangeImage(files, evt);
      };
      reader.readAsDataURL(files[0]);
    }
  }

  _handleToggleMenu(){
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    })
  }

  render(){
    return(
      <Card body  style={{'padding': 0}}>
        <CardLoadingPlaceholder
          isVisible={this.state.isUploading||this.state.isDeleting}
          loadingColor={this.state.isUploading? "green":'red'}
          loadingText="Submitting..."
        />
        <UploadCard
          id={this.props.id}
          error={this.props.error}
          isOpen={this.state.isEdit}
          onCancel={()=>this.setState({isEdit: !this.state.isEdit})}
          onChangeImage={this._onChangeImage}
        />
        <CardImg src={getCacheImage(this.props.previewURL)} alt={this.props.alt}/>
        <CardBody style={{paddingTop: 0}}>
          <CardText>
            <small className="text-muted">Last updated {this.props.updated_at}</small>
          </CardText>
          <CardFooter>
            <CardLink href={getCacheImage(this.props.previewURL)} className="text-default text-center" target="_blank"><i className="fa fa-external-link"></i> Open</CardLink>
            <DropdownContainer>
              <ButtonDropdown isOpen={this.state.dropdownOpen} toggle={this._handleToggleMenu}>
                <DropdownToggle>
                  <FontAwesomeIcon iconType="bars"/>
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem onClick={this._onRemoveImage}>
                    <FontAwesomeIcon iconType="trash"/> Delete
                  </DropdownItem>
                  <DropdownItem onClick={()=> this.setState({isEdit: !this.state.isEdit})}>
                    <FontAwesomeIcon iconType="pencil"/> Change Image
                  </DropdownItem>
                </DropdownMenu>
              </ButtonDropdown>
            </DropdownContainer>
          </CardFooter>
        </CardBody>
      </Card>
    )
  }
}

export default ImageCard;
ImageCard.defaultProps = {
  error: null,
  isProcessed: false,
  statusCode: null
}
ImageCard.propTypes = {
  id: propTypes.oneOfType([propTypes.number, propTypes.string]),
  URL: propTypes.string,
  previewURL: propTypes.string,
  updated_at:propTypes.string,
  alt:propTypes.string,
  isProcessed: propTypes.bool,
  statusCode: propTypes.oneOfType([propTypes.number, propTypes.string]),
  error: propTypes.object,
  onRemoveImage: propTypes.func,
  onChangeImage: propTypes.func
}
