import React from 'react';
import classNames from 'classnames';

import { ListGroup, ListGroupItem, ListGroupItemText, Button, ButtonGroup } from 'reactstrap';
import { ImageCentered } from '../../../components/Images';
import { SimpleLineIcon } from '../../../components/Icons';
import AlertNotFound from '../../../components/Card/AlertNotFound';
import { ButtonXs } from '../../../components/Button';

const getPublicImageUrl = image => {
  return image.signed_url || image.URL
}

export const ImageList = props => {
  const { isLoading } = props
  return (
    <ListGroup flush>
    {
      props.images.length===0?
      <AlertNotFound message={props.message ? props.message : "No Images for this category." }/>: null
    }
    {
      props.images.map((item, key) => (
        <ListGroupItem key={key} style={{display: 'flex', justifyContent: 'space-between', paddingLeft: 0, paddingRight: 0}}>
          <div style={{display: 'flex'}}>
            <ImageCentered size={80} src={(getPublicImageUrl(item))} style={{marginRight: '.5rem'}}/>
            <ListGroupItemText tag="div" style={{margin: 0, flex: 1}}>
            {
                isLoading?(
                  <div className="mb-2">...</div>
                ): (
                  <ButtonGroup className="mb-2">
                    <ButtonXs
                      color="gray"
                      disabled={item.orientation==='landscape'}
                      className={classNames({"active": item.orientation==='landscape'})}
                      onClick={() => props.onSaveImage({id: item.id, category_id: item.category_id, url: item.URL, orientation: 'landscape'})}
                      >
                      <SimpleLineIcon iconType="screen-desktop"/> landscape
                    </ButtonXs>
                    <ButtonXs
                      color="gray"
                      disabled={item.orientation==='portrait'}
                      className={classNames({"active": item.orientation==='portrait'})}
                      onClick={() => props.onSaveImage({id: item.id, category_id: item.category_id, url: item.URL, orientation: 'portrait'})}
                    >
                      <SimpleLineIcon iconType="screen-tablet"/> portrait
                    </ButtonXs>
                  </ButtonGroup>
                )
              }
              <div>
                created: {item.created_at}
              </div>
            </ListGroupItemText>
          </div>
          {
            isLoading?(
              <div style={{alignSelf: 'center'}}>
                <span>...</span>
              </div>
            ):(
          <div style={{alignSelf: 'center'}}>
            <a href={getPublicImageUrl(item)} target="_blank" title="Open with new tab">
              <SimpleLineIcon iconType="share-alt"/> Open
            </a>
            {' '}
            <Button
              color="link"
              onClick={() => props.onSaveImage({id: item.id, url: item.URL, deleted: true})}
              className="text-danger"
              title="Delete image"
            >
              <SimpleLineIcon iconType="trash"/>
            </Button>
          </div>
            )
          }
        </ListGroupItem>
      ))
    }
  </ListGroup>
  )
}

ImageList.defaultProps = {
  images: []
}
