import React, { Component } from 'react';
import propTypes from 'prop-types';
import {
  Col,
  Card,
  Button,
  Alert,
  ButtonGroup,
  CardBody,
} from 'reactstrap';
import { Tbl, TblHead, TblBody } from '../../../components/Tbl';
import Paginations from '../../../components/Paginations';
import { CardHeaderWithToolbars, Title } from '../../../components/Card/CardHeaderWithToolbars';
import { ModalVariant } from './Modals/index';
import { ModalDelete } from '../../../components/Modals';

const dataHeader = ['No.', 'SKU', 'Raffle Group', 'Total Ticket', 'Action'];
export default class ProductVariantList extends Component{
  constructor(props){
    super(props);
    this.state={
      _params:{
        keyword: ''
      },
      _list:[],
      _error:null,
      _selectedId:null,
      _modalDelete:false,
      _modalVariant:false,
      _productSelected:null,
      _selectedRow:null,
      _isAlertErrorOpen:false
    }
    this._toggle = this._toggle.bind(this);
    this._handleGoto = this._handleGoto.bind(this);
    this._handleSubmit=this._handleSubmit.bind(this);
    this._onRemoveSelected=this._onRemoveSelected.bind(this);
    this._handleModalDeleteToggle = this._handleModalDeleteToggle.bind(this);
    this._handleDeleteItem = this._handleDeleteItem.bind(this);
    this._handleAlertErrorToggle = this._handleAlertErrorToggle.bind(this);
  }

  componentDidUpdate(prevProps){
    const { dataRaffleTickets } = this.props;
    if(prevProps.dataRaffleTickets.isFetch!==dataRaffleTickets.isFetch&&!dataRaffleTickets.isFetch){
      if(dataRaffleTickets.list.data.length > 0){
        const { data } = dataRaffleTickets.list;
        const values = JSON.parse(data[0].value);
        const variants = values.filter(value => value.key==='product_variant');
        var newVariants = variants.map(function(item, index) {
          var o = Object.assign({}, item);
          if(!o.id) o.id = index+1;
          return o;
        })
        if(variants.length > 0) this.setState({_list:newVariants});
        else this.setState({_list:[]});
      }
    }
    if(prevProps.dataRaffleTickets.isSubmit!==this.props.dataRaffleTickets.isSubmit
      &&!this.props.dataRaffleTickets.isSubmit&&(this.state._modalDelete||this.state._modalVariant)){
        const { error } = dataRaffleTickets;
        if(error) {
          this.setState({
            _modalDelete:false, 
            _error:{...dataRaffleTickets.error}}, 
              ()=>{
                this._handleAlertErrorToggle()
              }
          );
        } else {
          this.setState({
            _modalDelete:false
          },() => {
            this.props.onGetList({params:{name : 'ka_fair_tickets'}});
          })
        }
    }
  }

  _onRemoveSelected(){
    this.setState({
      _isAlertErrorOpen:false,
      _productSelected:null,
      _selectedRow:null,
      _error:null
    });
  }

  _renderButtonAction(item){
    return(
      <ButtonGroup>
        <Button color="danger" onClick={()=>this._handleModalDeleteToggle(item)}><i className="icon-trash"></i></Button>
      </ButtonGroup>
    )
  }
  _onRenderRowItem(){
    if(this.state._list.length>0)
    {
      const { _list } =  this.state;
      return _list.map((item,index)=>(
          <tr key={index+1}>
            <th className="v-center" scope="row">{index+1}</th>
            <td className="v-center">
              { 
                Array.isArray(item.value) ? 
                item.value.map((val, index)=> {
                  return (<div key={index} style={{whiteSpace: 'pre-line'}}>{val}</div>);
                })
                :
                item.value
              }
            </td>
            <td className="v-center">{ item.raffle_group }</td>
            <td className="v-center">{ item.total_ticket ? item.total_ticket : '-' }</td>
            <td className="v-center">{ this._renderButtonAction(item) }</td>
          </tr>
        )
      )
    }
    return null;
  }

  async _handleGoto(page){
    const _params=this.state._params;
    _params.page=page;
    await this.setState({_params});
    this.props.onGetList(this.state._params)
  }

  _toggle() {
    this.setState({
      _modalVariant: !this.state._modalVariant
    });
  }

  _handleDeleteItem(){
    const { _list, _selectedId } = this.state;
    const { data } = this.props.dataRaffleTickets.list;
    const values = JSON.parse(data[0].value);
    const otherValues = values.filter(value => value.key!=='product_variant');
    const notSelected = _list.filter(value => value.id !== _selectedId);
    var newData = otherValues.concat(notSelected);
    console.log('other values -->', otherValues)
    this.props.onDelete(newData);
  }

  _handleModalDeleteToggle(item){
      this.setState({
        _isAlertErrorOpen:false,
        _productSelected: item? item: null,
        _selectedId:item? item.id: null,
        _modalDelete: !this.state._modalDelete
      });
  }

  _handleAlertErrorToggle(){
    this.setState({
      _isAlertErrorOpen: !this.state._isAlertErrorOpen
    })
  }

  _handleSubmit(id, payload) {
    this.props.saveSku(id, payload);
  }

  _onRenderPagination(){
    const { status_code, list, ...paginations } = this.props.dataRaffleTickets;
    const hasData = (status_code===200 && list.length) || null;
    return hasData && (
      <Paginations
        size="sm"
        lastPage={paginations.last_page}
        perPage={paginations.per_page}
        total={paginations.total}
        maxPage={8}
        path={paginations.path}
        currentPage={paginations.current_page}
        prevPageUrl={paginations.prev_page_url}
        nextPageUrl={paginations.next_page_url}
        handleGotoCallback={this._handleGoto}
      />
    )
  }

  _renderErrorMsg(){
    const { _isAlertErrorOpen, _error } = this.state;
    if(_isAlertErrorOpen && _error!==null){
      switch(_error.status_code){
        case 422:
          return <Alert color="danger" isOpen={_isAlertErrorOpen} toggle={this._handleAlertErrorToggle}>Please fulfill your form</Alert>
        default:
          return <Alert color="danger" isOpen={_isAlertErrorOpen} toggle={this._handleAlertErrorToggle}>Houston, we have a problem!. Please inform our friendly staff via LINE @kickavenue</Alert>
      }
    }
    return null;
  }

  render(){
    return(
      <Card>
        <Col xs={12}>
          <ModalDelete
            isOpen={this.state._modalDelete}
            toggle={this._handleModalDeleteToggle}
            onDelete={this._handleDeleteItem}
          />
          <ModalVariant
            isOpen={this.state._modalVariant}
            isModalDelete={this.state._modalDelete}
            onCancel={this._toggle}
            onRemoveSelected={this._onRemoveSelected}
            dataRaffleTickets={this.props.dataRaffleTickets}
            settingId={this.props.id}
            dataAuth={this.props.dataAuth}
            onSubmit={this._handleSubmit}
            productSelected={this.state._productSelected}
          />
        </Col>
        <CardHeaderWithToolbars>
          <Title>
            <i className="fa fa-align-justify"></i>
            <span>Product Variant</span>
          </Title>
        </CardHeaderWithToolbars>
        <CardBody>
          <div className="mb-3">
            <Button title="New Product Variant" onClick={this._toggle}><i className="fa fa-plus"></i> New Product Variant</Button>
          </div>
          { this._renderErrorMsg() }
          <div className="table-responsive">
            <Tbl>
              <TblHead
                  dataHeader={dataHeader}
              />
              <TblBody
                isLoad={this.props.dataRaffleTickets.isFetch}
                hasRow={this.state._list.length>0}
                columnCount={dataHeader.length}
              >
                { this._onRenderRowItem() }
              </TblBody>
            </Tbl>
          </div>
          { this._onRenderPagination() }
        </CardBody>
      </Card>
    )
  }
}
ProductVariantList.propTypes = {
  id: propTypes.number,
  onGetList: propTypes.func.isRequired,
  onDelete: propTypes.func.isRequired,
  dataRaffleTickets: propTypes.object,
  dataAuth: propTypes.object,
  saveSku: propTypes.func.isRequired,
  voucherType: propTypes.string,
}
ProductVariantList.defaultProps = {

}