import React from 'react';
import { connect } from 'react-redux';
import MarketingBudgetForm from './MarketingBudgetForm';
import privateView from '../../components/hocs/privateView';
import Analytics from "../../services/Analytics";

class MarketingBudgetDetail extends React.Component{
  componentDidMount(){
    const { email } = this.props.injectedProps;
    Analytics.recordPageView('marketing_budget_detail', email, {
      url: window.location.href,
      location: this.props.location,
      match: this.props.match
    });
  }
  render(){
    return(
      <div className="animate fadeIn">
        <MarketingBudgetForm
          title="Marketing Budget Details"
          edit
          {...this.props}
        />
      </div>
    )
  }
}

const mapStateToProps = ({auth: { isFetch, roles, isLogged, id, email } })=>({
  guardData: { isFetch, roles, isLogged, id, email }
})

const enhance = connect(mapStateToProps);

export default enhance(privateView(MarketingBudgetDetail, ['supervisor']));