import React from 'react';
import { Button, Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Alert } from "reactstrap";
import InputDatetime from "../../../../../components/Form/InputDatetime";
import moment from "moment";

export default function (props) {
    const {
        isOpen,
        onCancel,
        isSubmit,
        sale,
        onSubmit,
        receiveBy,
    } = props;

    const [receivedBy, setReceivedBy] = React.useState('');
    const [sellerCourier, setSellerCourier] = React.useState('');
    const [trackingNumber, setTrackingNumber] = React.useState('');
    const [courierOption, setCourierOption] = React.useState('');
    const [sellerSentDate, setSellerSentDate] = React.useState('');
    const [receivedAt, setReceivedAt] = React.useState('');
    const [sellerPrice, setSellerPrice] = React.useState('');
    const [notes, setNotes] = React.useState('');

    const [errors, setErrors] = React.useState({});
    const [invalid, setInvalid] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState('');

    React.useEffect(() => {
        setReceivedBy(receiveBy);
        setSellerCourier(sale?.seller_courier ?? '');
        setTrackingNumber(sale?.seller_awb_number ?? '');
        setCourierOption('');
        if (sale?.seller_sent) {
            setSellerSentDate(moment(sale?.seller_sent).format('YYYY-MM-DD HH:mm:ss'));
        } else {
            setSellerSentDate(moment().format('YYYY-MM-DD HH:mm:ss'));
        }
        setReceivedAt(moment().format('YYYY-MM-DD HH:mm:ss'));
        setSellerPrice(sale?.user_sell?.asking_price ?? '');

        if (!isOpen) {
            setNotes('');
            setInvalid(false);
            setErrorMessage('');
            setErrors({});
            setReceivedBy('');
            setSellerCourier('');
            setTrackingNumber('');
            setCourierOption('');
            setSellerSentDate('');
            setReceivedAt('');
            setSellerPrice('');
        }
    }, [isOpen, receiveBy, sale]);

    React.useEffect(() => {
        const errorMessages = Object.values(errors);
        if (errorMessages.length > 0) {
            setInvalid(true);
            setErrorMessage(errorMessages[0]);
            return;
        }
        setErrorMessage('');
        setInvalid(false);
    }, [errors]);

    React.useEffect(() => {
        if (invalid) {
            setTimeout(() => setInvalid(false), 5000);
        }
    }, [invalid]);

    const validate = () => {
        // validate state data and assign error message
        const error = {};
        // if (receivedBy === '') error.received_by = 'Receiver Name is required.';
        // if (sellerCourier === '') error.seller_courier = 'Seller Courier is required.';
        // if (trackingNumber === '') error.seller_awb_number = 'Tracking Number is required.';
        // if (courierOption === '') error.seller_courier_option = 'Courier Option is required.';
        if (sellerSentDate === '') error.seller_sent = 'Seller Sent Date is required.';
        if (receivedAt === '') error.received_at = 'Received At is required.';
        // if (sellerPrice === '') error.seller_price = 'Seller Price is required.';
        // if (notes === '') error.notes = 'Notes is required.';
        setErrors(error);
        return error;
    }

    const submit = async (e) => {
        e.preventDefault();
        const error = validate();
        if (Object.keys(error).length) return;

        const payload = {
            notes,
            seller_courier: sellerCourier,
            seller_courier_option: courierOption,
            // disable seller price change
            // seller_price: sellerPrice,
            seller_sent: moment(sellerSentDate).format('YYYY-MM-DD HH:mm:ss'),
            seller_awb_number: trackingNumber,
            received_at: moment(receivedAt).format('YYYY-MM-DD HH:mm:ss'),
        }
        if (receivedBy && receivedBy !== '') {
            payload.received_by = receivedBy
        }
        onSubmit(sale.id, payload);
    };

    return (
        <Modal isOpen={isOpen} toggle={onCancel} className="modal-dialog modal-md">
            <ModalHeader toggle={onCancel}>Receive Product Recap</ModalHeader>
            <ModalBody>
                {invalid && <Alert color="danger">{errorMessage}</Alert>}
                {sale !== null ?
                    <p>
                        (<b>{sale.consignment_number}</b>)<br />
                        <i>{sale.user_sell.product_variant.display_name}</i>
                    </p>
                    : ''
                }
                <Form>
                    <Row>
                        <Col xs={12}>
                            <FormGroup>
                                <Label for="formAcceptReceivedBy">Receiver Name</Label>
                                <Input
                                    type="text"
                                    readOnly
                                    id="formAcceptReceivedBy"
                                    name="received_by"
                                    placeholder="Receiver Name"
                                    value={receivedBy}
                                    onChange={(e) => setReceivedBy(e.target.value)}
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={6}>
                            <FormGroup>
                                <Label for="formAcceptCourier">Seller Courier</Label>
                                <Input
                                    type="text"
                                    id="formAcceptCourier"
                                    name="seller_courier"
                                    placeholder="Seller Courier example: JNE"
                                    value={sellerCourier}
                                    onChange={(e) => setSellerCourier(e.target.value)}
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={6}>
                            <FormGroup>
                                <Label for="formAcceptAwbNumber">Tracking Number</Label>
                                <Input
                                    type="text"
                                    id="formAcceptAwbNumber"
                                    name="seller_awb_number"
                                    placeholder="Nomor Resi Pengiriman dari Seller"
                                    value={trackingNumber}
                                    onChange={(e) => setTrackingNumber(e.target.value)}
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={12}>
                            <FormGroup>
                                <Label for="formAcceptCourierOption">Courier Option</Label>
                                <Input
                                    type="text"
                                    id="formAcceptCourierOption"
                                    name="seller_courier_option"
                                    placeholder="Seller Courier Option example: YES"
                                    value={courierOption}
                                    onChange={(e) => setCourierOption(e.target.value)}
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={6}>
                            <FormGroup>
                                <Label for="formAcceptSentDate">Seller Sent Date*</Label>
                                <InputDatetime
                                    id="formAcceptSentDate"
                                    inputProps={{ placeholder: 'When seller sent?*' }}
                                    value={sellerSentDate}
                                    dateFormat={'YYYY-MM-DD'}
                                    onChange={(date) => setSellerSentDate(moment(date))}
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={6}>
                            <FormGroup>
                                <Label for="formAcceptReceivedAt">Received At*</Label>
                                <InputDatetime
                                    id="formAcceptReceivedAt"
                                    inputProps={{ placeholder: 'When departed in kickavenue?*' }}
                                    value={receivedAt}
                                    dateFormat={'YYYY-MM-DD'}
                                    onChange={(date) => setReceivedAt(moment(date))}
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={12}>
                            <FormGroup>
                                <Label for="formAcceptCourierPrice">Seller Price</Label>
                                <Input
                                    type="text"
                                    id="formAcceptCourierPrice"
                                    name="seller_courier_price"
                                    placeholder="Seller Courier Price*"
                                    value={sellerPrice}
                                    disabled
                                    onChange={(e) => setSellerPrice(e.target.value)}
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={12}>
                            <FormGroup>
                                <Label for="formAcceptNotes">Notes</Label>
                                <Input
                                    type="textarea"
                                    id="formAcceptNotes"
                                    name="notes"
                                    placeholder="Put your notes here"
                                    value={notes}
                                    onChange={(e) => setNotes(e.target.value)}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </Form>
                <span className="text-sm">Fields marked with * are required</span>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" disabled={isSubmit} onClick={submit}>{isSubmit ? 'Submitting...' : 'Accept'}</Button>{' '}
                <Button color="secondary" onClick={onCancel}>Cancel</Button>
            </ModalFooter>
        </Modal>
    )
}
