import React from 'react';
import { getListUserSellerVerification } from '../../../actions/userAction';
import {Tbl, TblHead, TblBody} from '../../../components/Tbl';
import DateToHuman from '../../../components/DateToHuman';
import { Card, CardHeader, CardBody } from 'reactstrap';
import { connect } from 'react-redux';
import { getFileExt } from '../helpers';

const dataHeader=[
    'Mobile Number', 'Type', 'No. NPWP/KTP', 'NPWP/KTP Name', 'NPWP/KTP Url', 'Active','Updated At'
];
class SellerVerificationListCard extends React.Component{

    componentDidMount(){
        this.props.getList(this.props.userId);
    }


    onRenderRowItem(){
        if(this.props.user.detail.seller_verifications.status_code===200 && this.props.user.detail.seller_verifications.data.length>0)
		{
			return this.props.user.detail.seller_verifications.data.map((item,index)=> {
        const isUploadedImage = item.seller_verification_images.length > 0;
        const imageUrl = isUploadedImage ? item.seller_verification_images[0].image_url : item.KTP_URL;
        return (
					<tr key={ item.id }>
						<td>{ item.mobile_number }</td>
						<td>{ item.type }</td>
						<td>{ item.id_card }</td>
						<td>{ item.name }</td>
						<td>{ isUploadedImage ? <a href={imageUrl} target="_blank">{item.name.split(' ').join('_')}{"."}{getFileExt(imageUrl)}</a> : null }</td>
                        <td>{ <i className={`fa fa-${item.active?'check':'times'}`}></i> }</td>
						<td>{ <DateToHuman value={item.updated_at} id={`tooltip_seller_verification_${index}`}/> }</td>
					</tr>
				)
      }
			)	
		}
		return '';
    }

    render(){
        return(
            <Card>                            
                <CardHeader>
                        <i className="fa fa-align-justify"></i>
                        Seller Verification List
                </CardHeader>
                <CardBody>
                    <div className="table-responsive">
                            <Tbl>
                                <TblHead
                                    dataHeader={dataHeader}
                                />
                                <TblBody
                                    isLoad={this.props.user.detail.isSellerVerificationFetch}
                                    hasRow={this.props.user.detail.seller_verifications.status_code===200&&this.props.user.detail.seller_verifications.data.length>0}
                                    columnCount={dataHeader.length}
                                >
                                { this.onRenderRowItem() }
                                </TblBody>
                            </Tbl>
                    </div>
                </CardBody>
            </Card>
        )
    }
}

const mapStateToProps = ({user})=>{
    return {
        user
    }
}

const mapDispatchToProps = (dispatch)=>{
    return {
        getList : (id)=>dispatch(getListUserSellerVerification(id))
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(SellerVerificationListCard)