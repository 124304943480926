
import {
	SIZE_ROUTE_API,
	GET_LIST_SIZE,
	GET_DETAIL_SIZE,
	FETCHING_SIZE,
  SIZE_ERROR,
  GET_LIST_BRAND_SIZE,
  BRAND_SIZE_ROUTE_API,
	AUTH_TOKEN_KEY,
	GET_GENERAL_SIZE_LIST,
	GENERAL_SIZE_LIST_ERROR
} from '../constants';

import { logout } from './authAction';

import api from '../utils/Api';
import { manipulateErrors, checkAuthorized } from '../utils';

/**
 * Get list of offers
 * @author pewe
 * @guards jwt-token 
 * @param {object?} query 
 */
export const getSizeList=(query)=>{
	return dispatch => (
		dispatch(isFetch({type:'fetch',status:true})),
		api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).get(SIZE_ROUTE_API,query)
		.then(res=>{
			dispatch(onSuccess(res.data,GET_LIST_SIZE))
			dispatch(isFetch({type:'fetch',status:false}))
		})
		.catch(err => {
			dispatch(onError(err));
			dispatch(isFetch({type:'fetch',status:false}));
		})
	)
}

export const getBrandSizeList=(query)=>{
  return dispatch => (
    dispatch(isFetch({type:'fetch',status:true})),
    api({
      Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
    }).get(BRAND_SIZE_ROUTE_API,query)
    .then(res=>{
      dispatch(onSuccess(res.data,GET_LIST_BRAND_SIZE));
      dispatch(isFetch({type:'fetch',status:false}));
    })
    .catch(err=>{
      dispatch(onError(err));
      dispatch(isFetch({type:'fetch',status:false}));
    })
	)
}

export const getGeneralSizeList = () => (
	dispatch => {
		api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).get(SIZE_ROUTE_API,{params:{no_limit:true,brand_id: 0}})
		.then(res=>{
			dispatch(onSuccess(res.data,GET_GENERAL_SIZE_LIST))
		})
		.catch(err => {
			dispatch({
				type: GENERAL_SIZE_LIST_ERROR,
				payload: manipulateErrors(err)
			});
		})
	}
)

export const getDetailSize=(id)=>{
	return dispatch =>(
		dispatch(isFetch({type:'fetch',status:true})),
		api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).get(`${SIZE_ROUTE_API}/${id}`)
		.then(res=>{
			dispatch(onSuccess(res.data,GET_DETAIL_SIZE))
			dispatch(isFetch({type:'fetch',status:false}))
		})
		.catch(err => {
			dispatch(onError(err));
			dispatch(isFetch({type:'fetch',status:false}));
		})
	)
}
/**
 * Indicates when start fetch api or end fetch api
 * @author pewe
 * @param {boolean} bool 
 */
export const isFetch=(payload)=>(
	{
		type:FETCHING_SIZE,
		payload:payload		
	}
)

/**
 * When onSuccess triggered
 * @author pewe
 * @param {object} res 
 * @param {string} type 
 */
export const onSuccess=(res,type)=>
(
	{
		type:type,
		payload:res		
	}
)
/**
 * When error fetch or connect to api
 * @author pewe
 * @param {object} err 
 */
// On Error fetching api
export const onError=(err)=>{
	if(!checkAuthorized(manipulateErrors(err)))
		return logout();
	return {
		type:SIZE_ERROR,
		payload:manipulateErrors(err)
	}
}
