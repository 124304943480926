import React, {Component} from 'react'
import { connect } from 'react-redux'
import { getListUserSell } from '../../actions/sellRequestAction'
import { reportStock } from '../../actions/productStockAction';
import { Alert, Col, Row, Card, CardBody } from 'reactstrap';
import Paginations from '../../components/Paginations/Paginations';
import { CardHeaderWithToolbars,Title } from '../../components/Card/CardHeaderWithToolbars';
import FilterContainer from '../../components/Container/FilterContainer/FilterContainer';
import Toolbars from './Filters/Toolbars';
import { DEFAULT_PER_PAGE } from '../../constants/settings';
import FilterPanel from './warehouse/FilterPanel';
import ExportsPanel from './Exports';
import privateView from '../../components/hocs/privateView';
import List from './warehouse/List'
import DropdownProductCategories from '../../components/Dropdowns/DropdownProductCategories';
import { Th } from '../../components/Tbl';
import qs from 'query-string';
import { getOptionList as getCategoryOptionList } from '../../actions/categoriesAction';
import Analytics from "../../services/Analytics";

const defaultParams = {
  per_page: DEFAULT_PER_PAGE,
  status: 'pre_verified_approved',
  stock_label: '',
  pre_verified: 1,
  has_quantity: true,
  is_approved_online_listing: '0',
};

const DEFAULT_ROUTE = '/warehouse';

class Warehouse extends Component {
	constructor(props) {
    super(props)
    const params = qs.parse(props.location.search);
		this.state={
      _exportOpen: false,
			_params:{
        ...defaultParams,
        keyword: params.keyword || '',
        seller: ''
      },
      _list: [],
      _pagination: {},
      _filterOpen: params.keyword? true: false,
      _printOpen: false,
      _successAlertOpen: false,
      _selectedCategory: null,
      selectedData: [],
      isDataLoaded: false,
    }
    this._handleApplyFilter = this._handleApplyFilter.bind(this);
    this._handleGoto = this._handleGoto.bind(this);
    this._handleToggleFilterPanel = this._handleToggleFilterPanel.bind(this);
    this._handleRefresh = this._handleRefresh.bind(this);
    this._handleResetFilter = this._handleResetFilter.bind(this);
    this._handleParamsChange = this._handleParamsChange.bind(this);
    this._handleCheckedChange = this._handleCheckedChange.bind(this);
    this._handleParamsChangeAndSubmit = this._handleParamsChangeAndSubmit.bind(this);
    this._handleStatusChange = this._handleStatusChange.bind(this);
    this._handleRemoveSuccessAlert = this._handleRemoveSuccessAlert.bind(this);
    this._handleCategoryChanged = this._handleCategoryChanged.bind(this);
    this._handleToggleExportPanel = this._handleToggleExportPanel.bind(this);
    this._handleTogglePrintPanel = this._handleTogglePrintPanel.bind(this);
    this._handleExport = this._handleExport.bind(this);
    this.onSelectDataFromCheckbox = this.onSelectDataFromCheckbox.bind(this);
  }

  componentDidMount(){
    const { email } = this.props.injectedProps;
    this._refreshList();
    this.props.bindCategoryOption();
    Analytics.recordPageView('warehouse', email, {
      url: window.location.href,
      location: this.props.location,
      match: this.props.match
    });
  }

  componentWillReceiveProps(nextProps){
    const { updateStatus, isSubmit, isFetch } = nextProps.sellRequest;
    if(isFetch !== this.props.sellRequest.isFetch && !isFetch){
      if(nextProps.sellRequest.error===null){
        const { data, ...pagination  } = nextProps.sellRequest.list;
        this.setState({
          _list: data,
          _pagination: pagination
        })
      }
    }
  }

  shouldComponentUpdate(nextProps, nextState){
    if(nextProps.sellRequest.isSubmit&&nextProps.sellRequest.isSubmit!==this.props.sellRequest.isSubmit){
      return false;
    }
    return true;
  }

  _handleApplyFilter(payloadFilter){
    this.setState({
      _params: {
        ...this.state._params,
        ...payloadFilter,
        page: 1
      }
    },()=>this._getList(this.state._params));
  }

  _getList(rawParams = {}){
    let params = {};
    Object.keys(rawParams)
    .filter(key => rawParams[key]!='')
    .forEach(key=> params[key] = rawParams[key]);
    return this.props.getList({params});
  }

  _refreshList(){
    // Exclude page...
    const { page, ..._params } = this.state._params;
    this.setState({
      _params:{ ..._params }
    },() => {
      this._getList(this.state._params)
        .then(() => {
          this.setState({ isDataLoaded: true }); 
        })
    });
  }

  _handleResetFilter(e){
    e.preventDefault();
    const exportState = this.state._exportOpen?{_exportOpen:!this.state._exportOpen}:{};
    this.setState({
      _params:{ ...defaultParams },
      ...exportState,
      _isResetFilter: true,
      _selectedCategory: null
    },()=>this._getList(this.state._params));
  }

  _handleRefresh(e){
    e.preventDefault();
    this._refreshList();
  }

  _handleToggleFilterPanel(e){
    e.preventDefault();
    this._toggleFilterPanel();
  }

  _toggleFilterPanel(){
    const exportState = this.state._exportOpen?{_exportOpen:!this.state._exportOpen}:{};
    this.setState({
      ...exportState,
      _filterOpen:!this.state._filterOpen
    })
  }

  _handleParamsChange(e) {
    const { _params } = this.state;
    _params[e.target.name] = e.target.value;
    this.setState({_params});
  }

  _handleCheckedChange(e) {
    const { _params } = this.state;
    _params[e.target.name] = e.target.value;
    this.setState({_params});
  }

  _handleParamsChangeAndSubmit(e){
    // Exclude page...
    const { page , ..._params } = this.state._params;
    _params[e.target.name] = e.target.value;
    _params.page = 1;
    this.setState({_params},()=>{
      this._getList(this.state._params);
    });
  }

  _toggleExportPanel(){
    const filterState = this.state._filterOpen?{_filterOpen:!this.state._filterOpen}:{};
    this.setState({
      ...filterState,
      _exportOpen:!this.state._exportOpen});
  }

  _handleToggleExportPanel(e){
    e.preventDefault();
    this._toggleExportPanel();
  }

  _handleTogglePrintPanel(e){
    const filterState = this.state._filterOpen?{_filterOpen:!this.state._filterOpen}:{};
    this.setState({
      ...filterState,
      _printOpen:!this.state._printOpen});
  }

  _handleExport(payload){
    this.props.reportStock(payload);
  }

  _handleGoto(page){
    const _params=this.state._params;
    _params.page=page;
    this.setState({_params},()=>this._getList(this.state._params));
  }

  _handleStatusChange(e,{id, product_variant_id, status}){
    if(!this.props.sellRequest.isSubmit){
      const { _localUpdateStatus } = this.state;
      const items = _localUpdateStatus.filter(item=>item.id!==id);
      this.setState({
        _localUpdateStatus: [...items, { id, newStatus: e.target.value, oldStatus: status }]
      })
      this.props.updateStatus(id, {product_variant_id,status:e.target.value})
    }
  }

  _handleRemoveSuccessAlert(){
    this.setState({
      _successAlertOpen: !this.state._successAlertOpen
    })
  }

  _onRenderPagination(){
		if(this.props.sellRequest.list.status_code===200&&this.props.sellRequest.list.data.length) {
      return (
        <Paginations
          isLoading={this.props.sellRequest.isFetch}
          size="sm"
          lastPage={this.props.sellRequest.list.last_page}
          perPage={this.props.sellRequest.list.per_page}
          total={this.props.sellRequest.list.total}
          maxPage={8}
          path={this.props.sellRequest.list.path}
          currentPage={this.props.sellRequest.list.current_page}
          prevPageUrl={this.props.sellRequest.list.prev_page_url}
          nextPageUrl={this.props.sellRequest.list.next_page_url}
          handleGotoCallback={this._handleGoto}
          onCurrentPageChanged={currentPage => {
            window.scrollTo({
              top: this.tableRef.offsetTop+25,
              behavior: 'smooth'
            })
          }}
        />
      )
		}
		return null;
  }

  async _handleCategoryChanged(category){
    await this.setState({
      _selectedCategory: category === ''? null: category.label,
      _params: {
        ...this.state._params,
        category_id: category === ''? category: category.value,
        page: 1,
      }
    });
    const { _selectedCategory, _params } = this.state;
    this._getList(_params);
  }

  onSelectDataFromCheckbox(param){
    if(this.state.selectedData.length === 0){
      this.setState({selectedData: [param]});
      return;
    }

    const temp = [...this.state.selectedData];

    const isAlreadySelected = temp.some(el=>el.id === param.id);
    if(isAlreadySelected){
      const newData = temp.filter(el=>el.id !== param.id);
      this.setState({selectedData: newData});
      return;
    }

    temp.push(param);
    const newData = temp;
    this.setState({selectedData: newData});
  }

  render() {
    return (
      <div className="animated fadeIn">
        <Row>
          <Col xs="12">
            <Card>
              <CardHeaderWithToolbars>
                <Title>
                  <i className="fa fa-align-justify"></i>
                  <span>Warehouse List</span>
                </Title>
                {this.state.isDataLoaded && <Toolbars
                  isOpen={this.state._filterOpen}
                  isOpenExport={this.state._exportOpen}
                  isOpenPrint={this.state._printOpen}
                  onToggleFilter={this._handleToggleFilterPanel}
                  onRefreshList={this._handleRefresh}
                  onResetFilter={this._handleResetFilter}
                  onToggleExport={this._handleToggleExportPanel}
                  onTogglePrint={this._handleTogglePrintPanel}
                />}
              </CardHeaderWithToolbars>
              <FilterContainer>
                <FilterPanel
                  {...this.state._params}
                  isOpen={this.state._filterOpen}
                  onApplyFilter={this._handleApplyFilter}
                  isReset={this.state._isResetFilter}
                  onResetFilterCallback={()=>this.setState({_isResetFilter: false, _filterOpen: false})}
                />
              </FilterContainer>
              <FilterContainer>
                <ExportsPanel
                  onHandleExport={this._handleExport}
                  isOpenExport={this.state._exportOpen}
                  data={this.props.stockReport}
                  categoryOptions={this.props.categoryOptions}
                />
              </FilterContainer>
              <CardBody>
                <Alert color="info" isOpen={this.state._successAlertOpen} toggle={this._handleRemoveSuccessAlert}>
                  <strong>Your list has been updated,</strong> maybe it can affect with your filter. Click (<i className="fa fa-refresh"></i>) button to refresh list.
                </Alert>
                <List
                  innerRefs={tableRef => this.tableRef = tableRef}
                  data={this.state._list}
                  isLoading={this.props.sellRequest.isFetch}
                  from={this.state._pagination.from}
                  to={this.state._pagination.to}
                  perPage={this.state._pagination.per_page}
                  currentPage={this.state._pagination.current_page}
                  total={this.state._pagination.total}
                  statusCode={this.state._pagination.status_code}
                  defaultRoute={DEFAULT_ROUTE}
                  selectedData={this.state.selectedData}
                  setSelectedData={(selectedData)=>{
                    this.setState({selectedData});
                  }}
                  onSelectDataFromCheckbox={this.onSelectDataFromCheckbox}
                  isOpenPrint={this.state._printOpen}
                  onRenderHeader={()=>(
                    <tr>
                      <Th>No.</Th>
                      <Th>
                        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'flex-end'}}>
                          <p style={{marginRight: 10, marginBottom: 0}}>
                            Product
                            {
                              this.state._selectedCategory !== null?
                              <span style={{display: 'block', fontSize: '.5rem'}}>{this.state._selectedCategory}</span>
                              : null
                            }
                          </p>
                          <DropdownProductCategories
                            value={this.state._params.catgory_id}
                            onCategoryChanged={this._handleCategoryChanged}
                          />
                        </div>
                      </Th>
                      <Th>Rack</Th>
                      <Th>Price</Th>
                      <Th>Updated</Th>
                      <Th>Status</Th>
                      <Th>Action</Th>
                    </tr>
                  )}
                />
                {this._onRenderPagination()}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}
const mapStateToProps=({productStock, sellRequest, masterCategories, auth: { isFetch, isLogged, roles, id, email }})=> {
	return {
    sellRequest,
    stockReport: productStock.reportStock,
    guardData: { isFetch, isLogged, roles, id, email },
    categoryOptions:masterCategories
	}
}
const mapDispatchToProps=(dispatch)=> {
	return {
    getList:(filter)=>dispatch(getListUserSell(filter)),
    reportStock:(payload) => dispatch(reportStock(payload)),
    bindCategoryOption:()=>dispatch(getCategoryOptionList())
	}
}
const enchance = connect(mapStateToProps,mapDispatchToProps);
export default enchance(privateView(Warehouse, ['supervisor','sale_supervisor','cashier']))