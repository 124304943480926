import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import { getItemBySKU } from './selectors';
import SeekItemNotFound from './seekList/SeekItemNotFound';
import SeekItem from './seekList/SeekItem';

const Container = styled.div`
  max-height: 350px;
  overflow: auto;
`;

const SeekList = props => {
  const list = getItemBySKU(props.searchKeyword, props.data);
  return(
    <Container>
      {
        list.map(item => (
          <SeekItem
            {...item}
            key={item.id}
            onSyncClick={props.onSyncItemClick}
            disabledSyncBtn={props.isRequestPending}
            onOpenModal={props.onOpenModal}
          />
        ))
      }
      {
        list.length == 0?(
          <SeekItemNotFound/>
        ) : null
      }
    </Container>
  )
}
export default SeekList;

SeekList.propTypes = {
  searchKeyword: propTypes.string,
  data: propTypes.arrayOf(propTypes.object),
  onSyncItemClick: propTypes.func.isRequired,
  isRequestPending: propTypes.bool
}

SeekList.defaultProps = {
  searchKeyword: '',
  data: [],
  isRequestPending: false
}