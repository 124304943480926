import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Input } from 'reactstrap';
import propTypes from 'prop-types';

import { sellerVerificationStatusOptions } from '../../../constants/kickavenue';
import DateToHuman from '../../../components/DateToHuman';
import ProductColumn from './rowItem/ProductColumn';
import { updateStatusSellerVerification } from '../../../actions/sellerVerificationAction';

class RowItem extends React.Component {
  constructor(props){
    super(props);
    this.state={
      status: props.status,
      _statusEdit: props.status,
      updatedAt: props.updatedAt,
      approvedAt: props.approvedAt
    }
    this._handleStatusChange = this._handleStatusChange.bind(this);
  }

  shouldComponentUpdate(nextProps){
    if(nextProps.sellerVerification.isSubmit===true){
      return false;
    }
    if(!nextProps.sellerVerification.isSubmit&&nextProps.sellerVerification.isSubmit!==this.props.sellerVerification.isSubmit){
      if(nextProps.sellerVerification.error){
        const { error: { id } } = nextProps.sellerVerification;
        if(id && id != this.props.id){
          return false;
        }
      }
      else{
        if(nextProps.sellerVerification.id && nextProps.sellerVerification.id != this.props.id){
          return false;
        }
      }
    }
    return true;
  }

  componentWillReceiveProps(nextProps){
    if(!nextProps.sellerVerification.isSubmit&&nextProps.sellerVerification.isSubmit!==this.props.sellerVerification.isSubmit){
      if(nextProps.sellerVerification.error){
        const { error: { id } } = nextProps.sellerVerification;
        if(id && id === this.props.id){
          this.setState({
            _statusEdit: this.state.status,
          })
        }
      }
      else{
        if(nextProps.sellerVerification.id && nextProps.sellerVerification.id === this.props.id){
          const { status, updated_at, approved_at } = nextProps.sellerVerification;
          // console.log('APPROVED AT', approved_at)
          this.setState({
            status: status,
            _statusEdit: status,
            updatedAt: updated_at,
            approvedAt: approved_at,
          })
        }
      }
    }
  }

  _handleStatusChange(e){
    this.setState({_statusEdit: e.target.value}, ()=>{
      this.props.updateSellerVerification(this.props.id, { status: this.state._statusEdit })
    });
  }


  render(){
    const {
      id,
      rowId,
      userEmail,
      typedEmail,
      status
    } = this.props;
    return (
      <tr>
        <th className="v-center" scope="row">{ rowId }</th>
        <td className="v-center">
          <ProductColumn
            userEmail={userEmail}
            typedEmail={typedEmail}
          />
        </td>
        <td className="v-center"><DateToHuman  value={this.state.updatedAt} id={`tooltip_sellRequest_rowItem_updatedAt_${rowId}`}/></td>
        <td className="v-center">
          <Input
            type="select"
            name="_statusEdit"
            onChange={this._handleStatusChange}
            value={this.state._statusEdit}
          >
          { sellerVerificationStatusOptions.map((i,indx)=> {
              return <option value={i.value} key={indx}>{i.label}</option>
            }) }
          </Input>
        </td>
        <td className="v-center">
          <Link to={`${this.props.defaultRoute}/${id}`} className="btn btn-link">Details</Link>
        </td>
      </tr>
    )
  }
}

const mapStateToProps = ({ sellerVerification: { updateStatus, isSubmit } }) => ({
  sellerVerification: { ...updateStatus, isSubmit }
})
const mapDispatchToProps = (dispatch) => ({
  updateSellerVerification: (id, payload) => dispatch(updateStatusSellerVerification(id, payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(RowItem);

RowItem.propTypes = {
  rowId: propTypes.number.isRequired,
  id: propTypes.number.isRequired,
  userEmail: propTypes.string,
  status: propTypes.string,
  updatedAt: propTypes.string,
  defaultRoute: propTypes.string
}

RowItem.defaultProps = {
  status: 'draft',
}