import React from 'react';
import propTypes from 'prop-types';
import { Table, Card, CardHeader, CardBody } from 'reactstrap';
import Loading from 'react-loading-animation';

import { TextRupiah } from '../../../../components/Text';
import { _getFloatValue } from '../../../../utils/form';
import AlertNotFound from '../../../../components/Card/AlertNotFound'
import FontAwesomeIcon from '../../../../components/Icons/FontAwesomeIcon'
import { connect } from 'react-redux';
import { paymentDetailSelector } from '../../../../reducers/selectors/offerSelector';

const PurchaseSummaryCard = props =>(
  <Card>
    <CardHeader>
      Purchase Summaries
    </CardHeader>
    <CardBody>
      {
        props.isLoading?
        <Loading/>:(
          props.isEmptyData?
          <AlertNotFound message="Payment data not found!"/>:
          <Table>
            <thead>
              <tr>
                <th colSpan={2} className="text-center">
                  Purchase Summary
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{props.displayName} ({props.quantity}x)</td>
                <td><TextRupiah prefix="Rp. " value={_getFloatValue(props.offerAmount)} align="right" block/></td>
              </tr>
              <tr>
                <td>Processing Fee</td>
                <td><TextRupiah prefix="Rp. " value={_getFloatValue(props?.processingFeeCalculated)} align="right" block/></td>
              </tr>
              <tr>
                <td>{props.kaCourier} {props.kaCourierOption} (1x)</td>
                <td><TextRupiah prefix="Rp. " value={_getFloatValue(props.kaCourierPrice)} align="right" block/></td>
              </tr>
              <tr>
                <td>Biaya Tambahan</td>
                <td><TextRupiah  prefix="Rp. " value={(_getFloatValue(props.uniqueAmount)+_getFloatValue(props.administrationFee))} align="right" block/></td>
              </tr>
              <tr>
                <td>Voucher ({props.voucherId && props.voucher? props.voucher.code: props.voucherId? `REF: ${props.voucherId}` :'-'})</td>
                <td><TextRupiah prefix="Rp. " value={_getFloatValue(props.discount)+_getFloatValue(props.adminFeeDiscount)+_getFloatValue(props.kaCourierDiscount)} align="right" block/></td>
              </tr>
              <tr>
                <td>Subsidy Price</td>
                <td className="v-center"><TextRupiah prefix="-Rp." value={_getFloatValue(props.subsidyPrice)} align="right" block/></td>
              </tr>
              <tr>
                <td><b>Sub Total</b></td>
                <td>
                  <TextRupiah prefix="Rp. " value={_getFloatValue(props.totalAmount)} align="right" block bold/>
                </td>
              </tr>
              {Number(props.kickCredit)? (
              <tr>
                <td>Kick Credit</td>
                <td>
                  <TextRupiah prefix="Rp. " value={_getFloatValue(props.kickCredit)} align="right" block/>
                </td>
              </tr>
              ):null}
              {Number(props.kickPoint)? (
              <tr>
                <td>Kick Point</td>
                <td>
                  <TextRupiah prefix="Rp. " value={_getFloatValue(props.kickPoint)} align="right" block/>
                </td>
              </tr>
              ):null}
              {Number(props.sellerCredit)? (
              <tr>
                <td>Seller Credit</td>
                <td>
                  <TextRupiah prefix="Rp. " value={_getFloatValue(props.sellerCredit)} align="right" block/>
                </td>
              </tr>
              ):null}
              {Number(props.walletUsage)? (
              <tr>
                <td><b>Sub Total Credit Usage</b></td>
                <td>
                  <TextRupiah prefix="-Rp. " value={_getFloatValue(props.walletUsage)} align="right" block bold/>
                </td>
              </tr>
              ):null}
              <tr>
                <td><b>Total</b></td>
                <td>
                  <TextRupiah prefix="Rp. " value={_getFloatValue(props.totalAdjustedAmount)} align="right" block bold/>
                </td>
              </tr>
              {
                props.points?(
                  <tr>
                    <td>
                      <FontAwesomeIcon className="mr-1" iconType="product-hunt"/>
                      Kick Reward*<br/>
                      <span style={{fontStyle: 'italic', fontSize: '.65rem'}}>*not included from <b>Total</b>.</span>
                    </td>
                    <td>
                      <p style={{margin: 0}} className="text-right">{Number(props.points)}</p>
                    </td>
                  </tr>
                ): null
              }
            </tbody>
          </Table>
        )
      }
    </CardBody>
  </Card>
)

const mapStateToProps = state => ({
  paymentDetail: paymentDetailSelector(state)
})

export default connect(mapStateToProps)(PurchaseSummaryCard);
PurchaseSummaryCard.propTypes = {
  id: propTypes.number,
  isEmptyData: propTypes.bool,
  isLoading: propTypes.bool,
  displayName: propTypes.string,
  offerAmount: propTypes.oneOfType([propTypes.number, propTypes.string]),
  walletUsage: propTypes.oneOfType([propTypes.number, propTypes.string]),
  kickCredit: propTypes.oneOfType([propTypes.number, propTypes.string]),
  kickPoint: propTypes.oneOfType([propTypes.number, propTypes.string]),
  sellerCredit: propTypes.oneOfType([propTypes.number, propTypes.string]),
  kaCourier: propTypes.string,
  kaCourierOption: propTypes.string,
  kaCourierPrice: propTypes.oneOfType([propTypes.number, propTypes.string]),
  voucherId: propTypes.number,
  voucher: propTypes.object,
  discount: propTypes.oneOfType([propTypes.number, propTypes.string]),
  adminFeeDiscount: propTypes.oneOfType([propTypes.number, propTypes.string]),
  administrationFee: propTypes.oneOfType([propTypes.number, propTypes.string]),
  uniqueAmount: propTypes.oneOfType([propTypes.number, propTypes.string]),
  totalAmount: propTypes.oneOfType([propTypes.number, propTypes.string]),
  totalAdjustedAmount: propTypes.oneOfType([propTypes.number, propTypes.string]),
  kaCourierDiscount: propTypes.oneOfType([propTypes.number, propTypes.string])
}
PurchaseSummaryCard.defaultProps = {
  isLoading: false,
  isEmptyData: false,
  displayName: '-',
  voucherId: null,
  voucher: {
    code: '-'
  },
  offerAmount: 0,
  kaCourierPrice: 0,
  administrationFee: 0,
  uniqueAmount: 0,
  totalAmount: 0,
  totalAdjustedAmount: 0,
  walletAmount: 0,
  discount: 0,
  kaCourierDiscount: 0,
  adminFeeDiscount: 0
}