import React from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  Badge,
  Button,
  Row,
  Col
} from 'reactstrap';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { ShippingCard, courierOptions as couriers } from './Modals'

import { hasError, errorMessage, textChange, textValue, dateChange } from '../../../utils/form';
export default class ModalSubmitCourier extends React.Component{
  constructor(props){
    super(props);
    moment.tz.setDefault("Asia/Jakarta");
    this.state={
      id:null,
      ka_courier:'',
      ka_courier_custom:'',
      ka_courier_option:'',
      notes:'',
      error:null
    }
    this.handleChange=this.handleChange.bind(this);
    this.handleSelectCourierChange = this.handleSelectCourierChange.bind(this);
    this.onSubmit=this.onSubmit.bind(this);
  }

  handleChange(e){
    this.setState(textChange(e,{...this.state}));
  }

  handleSelectCourierChange(e){
    this.setState({...textChange(e,{...this.state}), ka_courier_custom: ""})
  }

  handleDatePickerChange(date, name){
    this.setState(dateChange(date,name, {...this.state}));
  }

  componentDidUpdate(prevProps){
    if(!prevProps.isOpen&&prevProps.isOpen!==this.props.isOpen&&this.props.consignment!==null){
      const initalValue={
        ka_courier: "JNT",
        ka_courier_option:this.props.consignment.ka_courier_option,
        notes: this.props.consignment.notes,
        error:null
      }
      this.setState({id:this.props.consignment.id,...initalValue});
    }
    if(prevProps.isSubmit!==this.props.isSubmit&&this.props.isSubmit===false){
      this.setState({error:this.props.error});
    }
  }

  onSubmit(e){
    e.preventDefault();
    const {
      id,
      notes,
      ka_courier,
      ka_courier_custom,
      ka_courier_option
    } = this.state;
    // const payload = { notes, ka_courier: ka_courier === "CUSTOM"? ka_courier_custom: ka_courier, ka_courier_option, ka_courier_price, awb_number, ka_sent };
    const payload = { notes, ka_courier: ka_courier === "CUSTOM"? ka_courier_custom: ka_courier, ka_courier_option };
    this.props.onSubmit(id, payload);
  }

  render(){
    return(
      <Modal isOpen={this.props.isOpen} toggle={this.props.onCancel} className="modal-dialog modal-md">
        <ModalHeader toggle={this.props.onCancel}>Return Product</ModalHeader>
        <ModalBody>
          <Form>
          {
            this.props.consignment &&
            <Row>
              <Col sm={12}>
                <ShippingCard
                  displayName={this.props.consignment.user_sell.product_variant?.display_name}
                  invoiceNumber={this.props.consignment.consignment_number}
                  shipping={this.props.consignment.shipping}
                  onOpenAddressModal={(data) => this.props.onOpenAddressModal(data)}
                  dataShipping={this.props.dataShipping}
                />
              </Col>
            </Row>
          }
            <Row>
              <Col xs={6}>
                <FormGroup  className={classnames({'has-danger has-feedback':hasError('ka_courier',this.state.error)})}>
                  <Label for="formDeliverCourier">
                    <div className="d-flex">
                      <div>Courier*</div>
                      {
                        this.state.ka_courier === "CUSTOM" ?
                        <div style={{marginLeft: "0.5rem"}}>
                          <Badge>CUSTOM</Badge>
                          <span style={{marginLeft: "0.5rem", textDecoration: 'underline', cursor: "pointer", color: 'red'}} onClick={() => this.setState({ka_courier: "DEFAULT"})}>select options...</span>
                        </div>
                        :null
                      }
                    </div>
                  </Label>
                  {
                    this.state.ka_courier !== "CUSTOM"?
                    <Input
                      type="select"
                      id="formDeliverCourier"
                      name="ka_courier"
                      placeholder="Courier example: JNE*"
                      value={textValue('ka_courier',this.state)}
                      onChange={this.handleSelectCourierChange}
                    >
                      {
                        couriers.map(({value, label}, index)=> <option value={value} key={index}>{label}</option>)
                      }
                    </Input>
                    :
                    <Input
                      type="text"
                      id="formDeliverCourier"
                      name="ka_courier_custom"
                      placeholder="Courier example: JNE*"
                      value={textValue('ka_courier_custom',this.state)}
                      onChange={this.handleChange}
                    />
                  }
                  {errorMessage('ka_courier',this.state.error)}
                </FormGroup>
              </Col>
              <Col xs={6}>
                <FormGroup  className={classnames({'has-danger has-feedback':hasError('ka_courier_option',this.state.error)})}>
                  <Label for="formDeliverCourierOption">Courier Option</Label>
                  <Input
                    type="text"
                    id="formDeliverCourierOption"
                    name="ka_courier_option"
                    placeholder="Courier Option example: YES"
                    value={textValue('ka_courier_option',this.state)}
                    onChange={this.handleChange}
                  />
                  {errorMessage('ka_courier_option',this.state.error)}
                </FormGroup>
              </Col>
              <div className="w-100"></div>
              <Col xs={12}>
                <FormGroup  className={classnames({'has-danger has-feedback':hasError('ka_courier',this.state.error)})}>
                  <Label for="formDeliverNotes">
                    Notes
                  </Label>
                  <Input
                    type="textarea"
                    id="formDeliverNotes"
                    name="notes"
                    placeholder="Notes..."
                    value={textValue('notes',this.state)}
                    onChange={this.handleChange}
                  />
                  {errorMessage('notes',this.state.error)}
                </FormGroup>
              </Col>
            </Row>
          </Form>
          <span className="text-sm">Fields marked with * are required</span>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" disabled={this.props.isSubmit} onClick={this.onSubmit}>{this.props.isSubmit?'Submitting...':'Submit'}</Button>{' '}
          <Button color="secondary" onClick={this.props.onCancel}>Cancel</Button>
        </ModalFooter>
      </Modal>
    )
  }
}
ModalSubmitCourier.propTypes={
  consignment:PropTypes.object,
  isSubmit:PropTypes.bool,
  error:PropTypes.object,
  isOpen:PropTypes.bool,
  onSubmit:PropTypes.func,
  onCancel:PropTypes.func
}