import axios from 'axios';
import { manipulateErrors } from '.';
import { API_BASE_URL, AUTH_TOKEN_KEY } from '../constants';
import store from '../store'
const api = (headers={}) => {
	const instance = axios.create({
		baseURL:API_BASE_URL,
		headers: { 'x-kickavenue-platform': 'cms', ...headers }
	})
	instance.interceptors.response.use(response => {
		let data = null
		if(response.data){
			data = response.data
		}
		if(response.status === 200 && data && data.error && data.error.status_code === 503){
			store.dispatch({ type: "ERROR_MAINTENANCE_MODE", payloads: manipulateErrors({ response }) })
			return Promise.reject({ response })
		}
		return response;
	}, error => {
		const data = error.response.data || {}
		if(error.response && (error.response.status === 503 || (data.error && data.error.status_code === 503))){
			store.dispatch({ type: "ERROR_MAINTENANCE_MODE", payloads: manipulateErrors(error) })
		}
		return Promise.reject(error);
	})
	return instance;
}
export default api;

export const addHeaderAuthorization = token => ({
	Authorization:`Bearer ${token}`
})

export const apiClient = () => api({
  ...addHeaderAuthorization(localStorage.getItem(AUTH_TOKEN_KEY))
})