import React, { PureComponent as Component } from 'react';
import { CardBody, Collapse } from 'reactstrap';
import propTypes from 'prop-types';

import SearchForm from './SearchForm';
import SortForm from './SortForm';

class Filters extends Component {
  constructor(props) {
    super(props)
    this.state = {
      keyword: props.keyword,
      category: props.category,
      per_page: props.per_page,
      sort_by: props.sort_by,
    }
    this._handleApplyFilterButtonClick = this._handleApplyFilterButtonClick.bind(this);
    this._handleOnChangeInputAndApply = this._handleOnChangeInputAndApply.bind(this);
    this._handleCategoryChangeInput = this._handleCategoryChangeInput.bind(this);
  }

  _handleCategoryChangeInput(val) {
    const lastState = {...this.state};
    let category = val
    lastState['category'] = category
    this.setState({...lastState});
  }

  _handleOnChangeInputAndApply(e){
    const lastState = {...this.state};
    lastState[e.target.name] = e.target.value;
    this.setState({...lastState}, ()=> this._handleSubmitFilter());
  }

  _handleApplyFilterButtonClick(e){
    e.preventDefault();
    this._handleSubmitFilter();
  }

  _handleSubmitFilter(){
    const {
      keyword,
      category,
      per_page,
      sort_by
    } = this.state;
    this.props.onApplyFilter({
      keyword,
      category: category ? category.id : null,
      per_page,
      sort_by
    });
  }

  componentWillReceiveProps(nextProps){
    if(nextProps.isReset && this.props.isReset!==nextProps.isReset){
      const {
        keyword,
        category,
        per_page,
        sort_by
      } = nextProps;
      this.setState({
        keyword,
        category,
        per_page,
        sort_by
      })
    }
  }

  render(){
    return(
      <Collapse isOpen={this.props.isOpen}>
        <CardBody className="filter-container__card-body">
          <SearchForm
            keyword = {this.props.keyword}
            onInputChange = {this.props.onHandleChange}
            onSubmit = {this.props.onHandleSearch}
          />
          <hr/>
          <SortForm
            per_page={this.props.per_page}
            sort_by={this.props.sort_by}
            onInputChange={this.props.onHandleChangeAndSubmit}
          />
        </CardBody>
      </Collapse>
    )
  }
}

export default Filters

Filters.propTypes= {
  keyword: propTypes.string,
  category: propTypes.oneOfType([propTypes.number, propTypes.object]),
  per_page: propTypes.oneOfType([propTypes.number, propTypes.string]),
  sort_by: propTypes.string,
  isOpen: propTypes.bool.isRequired,
  onHandleChange: propTypes.func,
  onHandleSearch: propTypes.func,
  onHandleChangeAndSubmit: propTypes.func,
  onApplyFilter: propTypes.func
}

Filters.defaultProps= {
  keyword: '',
  category: '',
  per_page: 15,
  sort_by: ''
}