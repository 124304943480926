import React, { useEffect, useState } from 'react';
import { Card, CardBody, CardText, Col, Row, } from 'reactstrap';
import { CardHeaderWithToolbars, Title } from "../../../components/Card/CardHeaderWithToolbars";
import { Toolbars } from "../Components";
import FilterContainer from "../../../components/Container/FilterContainer/FilterContainer";
import { Exports, Filters, RowHeader, RowItem } from "./Components";
import { useFetchConsignment } from "../Hooks/fetch/useFetchConsignment";
import { useFetchConsignmentNotes } from "../Hooks/fetch/useFetchConsignmentNotes";
import { useFetchProfile } from "../../../reusables/hooks/fetch/useFetchProfile";
import { usePutConsignmentMarked } from "../Hooks/put/usePutConsignmentMarked";
import PaginationSummaries from "../../Sales/Monitoring/Paginations/PaginationSummaries";
import { Tbl, TblBody, TblHead } from "../../../components/Tbl";
import { generateNumber } from "../../../utils";
import Paginations from "../../../components/Paginations";
import Style from "./All.module.css";
import { ModalMarkedConsignment } from "../../../components/Modals";
import { useReadNotes } from '../Hooks/put/useReadNotes';

function AllConsignment() {
    const { fetchConsignment, data, setData, loading, isFetching } = useFetchConsignment();
    const { fetchConsignmentNotes } = useFetchConsignmentNotes();
    const { putConsignmentMarked } = usePutConsignmentMarked();
    const { readNotes } = useReadNotes();
    const { id } = useFetchProfile();

    const [filterOpen, setFilterOpen] = useState(false);
    const [exportOpen, setExportOpen] = useState(false);
    const [isResetFilter, setIsResetFilter] = useState(false);

    const [keyword, setKeyword] = useState('');
    const [seller, setSeller] = useState(null);
    const [rack, setRack] = useState([]);
    const [shippingMethod, setShippingMethod] = useState('');
    const [status, setStatus] = useState('');
    const [isAllStatus, setAllStatus] = useState(false);

    const [perPage, setPerPage] = useState(50);
    const [sortBy, setSortBy] = useState('updatedAt_desc');

    const [getParams, setGetParams] = useState({
        page: 1,
    });

    // marked consignment
    const [sale, setSale] = useState(null);
    const [modalMarked, setModalMarked] = useState(false);
    const [isConfirmation, setIsConfirmation] = useState(false);
    const [marked, setMarked] = useState('');
    const [markedAt, setMarkedAt] = useState('');
    const [markedBy, setMarkedBy] = useState('');
    const [closeMarkedAt, setCloseMarkedAt] = useState('');
    const [markedNotes, setMarkedNotes] = useState('');
    const [notes, setNotes] = useState([]);
    const [unreadNoteCount, setUnreadNoteCount] = useState('');
    const [noteNextPage, setNoteNextPage] = useState(null);
    const [notesPage, setNotesPage] = useState(1);
    const [perPageAll, setPerPageAll] = useState(50);
    const [getParamsAll, setGetParamsAll] = useState({
        page: 1,
    });

    useEffect(() => {
        const params = {
            sort_by: sortBy,
            per_page: perPage,
            ...getParams,
        };
        fetchConsignment(params).then(() => { });
    }, [getParams, perPage, sortBy]);

    useEffect(() => {
        // when opening the modal marked
        if (modalMarked) {
            // if there are notes list available
            if (notes.length) {
                // if there are unread notes
                const ids = notes.filter((item) => !item.read).map((item) => item.id);
                if (ids.length) {
                    // read the notes
                    readNotes(sale.id, { ids }).then(() => {
                        // then update the unread notes count
                        const newData = [...data.data];
                        const index = newData.findIndex((item) => item.id === sale.id);
                        newData[index].unread_notes_count = 0;
                        setData({
                            ...data,
                            data: newData,
                        });
                    });
                }
            }
        }
    }, [modalMarked, notes]);

    const handleToggleFilterPanel = () => {
        setFilterOpen(!filterOpen);
    }

    const handleTogglePrintPanel = () => {
        setExportOpen(!exportOpen);
    };

    const handleRefresh = async () => {
        const params = {
            sort_by: sortBy,
            per_page: perPage,
            ...getParams,
        };
        await fetchConsignment(params);
    };

    const handleResetFilter = () => {
        setKeyword('');
        setSeller(null);
        setRack(null);
        setShippingMethod('');
        setStatus('');
        setFilterOpen(false);
        setGetParams({
            page: 1,
        });
    };

    const handleApplyFilter = () => {
        const newParams = {};

        if (seller !== null) {
            newParams['seller_id'] = seller.id;
        } else {
            delete newParams['seller_id'];
            delete getParams['seller_id'];
        }

        if (rack.length) {
            newParams['rack'] = rack.map((item) => item.name).join(',');
        } else {
            delete newParams['rack'];
            delete getParams['rack'];
        }

        if (status !== '') {
            if (isAllStatus) {
                newParams['status'] = '';
            } else {
                newParams['status'] = status;
            }
        } else {
            delete newParams['status'];
            delete getParams['status'];
        }

        if (shippingMethod !== '') {
            newParams['seller_courier'] = shippingMethod;
        } else {
            delete newParams['seller_courier'];
            delete getParams['seller_courier'];
        }

        setGetParams({
            ...getParams,
            ...newParams,
        });
    };

    const handleSearch = () => {
        setGetParams({
            ...getParams,
            keyword,
        });
    };

    const handleExport = () => { };

    const handleCategoryChanged = (category) => {
        if (category === '') {
            setGetParams({
                ...getParams,
                category,
            });
        } else {
            setGetParams({
                ...getParams,
                category: category.value,
            });
        }
    };

    const handleGoto = (page) => {
        setGetParams({
            ...getParams,
            page,
        });
    };

    const renderTableRow = () => {
        return data?.data?.map((item, index) => {
            const rowId = generateNumber(data?.per_page, data?.current_page, index);
            return (
                <RowItem item={item} key={rowId} rowId={rowId} onOpenMarkedModal={(confirmation) => openMarkedModal(item, confirmation)} isMarked={item.marked} />
            )
        })
    };

    const openMarkedModal = (item, confirmation = false) => {
        setSale(item);
        setModalMarked(true);
        setMarked(item.marked);
        setMarkedAt(item.open_marked_at);
        setMarkedBy(item.open_marked_by);
        setCloseMarkedAt(item.close_marked_at);
        setMarkedNotes(item.marked_notes);
        setUnreadNoteCount(item.unread_notes_count);
        setIsConfirmation(confirmation);
        const params = {
            per_page: perPageAll,
            ...getParamsAll,
        }
        fetchConsignmentNotes(item.id, params)
            .then((res) => {
                setNotes(res.data)
                setNoteNextPage(res.next_page_url)
            })
    }

    const onSubmitMarked = ({ marked_notes, marked = null }) => {
        const newData = [...data.data];

        if (marked) {
            const payload = { marked };
            if (marked_notes) {
                payload['marked_notes'] = marked_notes;
            }
            putConsignmentMarked(sale.id, payload).then(() => {
                // manually update the data on the list instead of fetching again
                const index = newData.findIndex((item) => item.id === sale.id);
                newData[index].marked = marked;
                if (marked_notes) {
                    newData[index].marked_notes = marked_notes;
                    newData[index].notes_count += 1;
                }
                setData({
                    ...data,
                    data: newData,
                });

                setSale(null);
                setModalMarked(false);
            });
            return;
        }

        const payload = {
            marked: isConfirmation ? false : marked || sale.marked,
            marked_notes
        };

        putConsignmentMarked(sale.id, payload).then(() => {
            // manually update the data on the list instead of fetching again
            const index = newData.findIndex((item) => item.id === sale.id);
            newData[index].marked = isConfirmation ? false : marked || sale.marked;
            if (marked_notes) {
                newData[index].marked_notes = marked_notes;
                newData[index].notes_count += 1;
            }
            setData({
                ...data,
                data: newData,
            });

            setSale(null);
            setModalMarked(false);
        });
    };

    return (
        <Row className="animated fadeIn">
            <Col xs={12}>
                <ModalMarkedConsignment
                    isOpen={modalMarked}
                    isConfirmation={isConfirmation}
                    toggle={() => setModalMarked(!modalMarked)}
                    invoiceNumber={sale && sale.consignment_number}
                    marked={marked}
                    markedAt={markedAt}
                    markedBy={markedBy}
                    closeMarkedAt={closeMarkedAt}
                    markedNotes={markedNotes}
                    notes={notes}
                    unreadNoteCount={unreadNoteCount}
                    hasNextPage={noteNextPage}
                    loginId={id}
                    onSubmit={onSubmitMarked}
                    onLoadMoreNotes={onSubmitMarked}
                />
                <Card>
                    <CardHeaderWithToolbars>
                        <Title>
                            <i className="fa fa-align-justify"></i>
                            <span>All Consignment List</span>
                        </Title>
                        {isFetching && <Toolbars
                            isOpen={filterOpen}
                            isOpenPrint={exportOpen}
                            onToggleFilter={handleToggleFilterPanel}
                            onTogglePrint={handleTogglePrintPanel}
                            onRefreshList={handleRefresh}
                            onResetFilter={handleResetFilter}
                        />}
                    </CardHeaderWithToolbars>
                    <FilterContainer>
                        <Filters
                            onApplyFilter={handleApplyFilter}
                            onHandleSearch={handleSearch}
                            isReset={isResetFilter}
                            onResetFilterCallback={() => {
                                setIsResetFilter(false);
                                setFilterOpen(false);
                            }}
                            isOpen={filterOpen}
                            keyword={keyword}
                            seller={seller}
                            rack={rack}
                            shippingMethod={shippingMethod}
                            status={status}
                            setKeyword={setKeyword}
                            setSeller={setSeller}
                            setRack={setRack}
                            setShippingMethod={setShippingMethod}
                            setStatus={setStatus}
                            perPage={perPage}
                            sortBy={sortBy}
                            setPerPage={setPerPage}
                            setSortBy={setSortBy}
                            setAllStatus={setAllStatus}
                        />
                    </FilterContainer>
                    <FilterContainer>
                        <Exports
                            onHandleExport={handleExport}
                            isOpen={exportOpen}
                            data={[]}
                            categoryOptions={{}}
                        />
                    </FilterContainer>
                    <CardBody>
                        <CardText>
                            In this section we can monitoring our consignment.
                        </CardText>
                        <div className="table-responsive">
                            <PaginationSummaries
                                isOpenPrint={false}
                                from={data?.from ?? 0}
                                to={data?.to ?? 0}
                                total={data?.total ?? 0}
                            />
                            <div className={Style['table-parent']}>
                                <Tbl>
                                    <TblHead>
                                        <RowHeader
                                            categoryName={getParams.category}
                                            categoryValue={getParams.category}
                                            onCategoryChanged={handleCategoryChanged}
                                        />
                                    </TblHead>
                                    <TblBody
                                        isLoad={loading}
                                        hasRow={data?.data?.length > 0}
                                        columnCount={9}
                                    >
                                        {renderTableRow()}
                                    </TblBody>
                                </Tbl>
                            </div>
                        </div>
                        <Paginations
                            size="sm"
                            lastPage={data.last_page}
                            perPage={data.per_page}
                            total={data.total}
                            maxPage={8}
                            path={data.path}
                            currentPage={data.current_page}
                            prevPageUrl={data.prev_page_url}
                            nextPageUrl={data.next_page_url}
                            handleGotoCallback={handleGoto}
                        />
                    </CardBody>
                </Card>
            </Col>
        </Row>
    );
}

export default AllConsignment;
