import {
  GET_LIST_POINT_TIERS,
  GET_DETAIL_POINT_TIERS,
  POINT_TIERS_ERROR,
  POINT_TIERS_REQUEST,
} from '../constants';

const initialState = {
	list:[],
	options:[],
	detail:{},
	isFetch:false,
	isSubmit:false,
	error:null,
	success:null,
}

const onFetched = (lastState, { requestType, value }) => {
  switch(requestType){
    case GET_LIST_POINT_TIERS: return {...lastState, isFetch: value }
    case GET_DETAIL_POINT_TIERS: return {...lastState, isFetch: value }
    default: return lastState;
  }
}

const onError = (lastState, { requestType, error, ...rest }) => {
  switch(requestType){
    case GET_LIST_POINT_TIERS: return {...lastState, error: { ...error, ...rest } }
    case GET_DETAIL_POINT_TIERS: return {...lastState, error: { ...error, ...rest } }
    default: return lastState;
  }
}

const reducer = (state = initialState, { type, payload }) => {
  switch(type){
    case POINT_TIERS_REQUEST: return onFetched(state, payload)
    case POINT_TIERS_ERROR: return onError(state, payload)
    case GET_LIST_POINT_TIERS: {
      const {data, message, status_code}=payload;
      return { ...state, error:null, success:null,detail:{}, list:{ ...data, message, status_code } };	
    }
    case GET_DETAIL_POINT_TIERS: {
      const {data, message, status_code}=payload;
      return { ...state, error:null, success:null, detail:{ ...data, message, status_code } };	
    }
    default: return { ...state }
  }
}

export default reducer;