import React from 'react';
import propTypes from 'prop-types';
import {
  Card,
  CardText,
  Row,
  Col,
} from 'reactstrap';


import { getCacheImage } from '../../../utils/imageHelper';
import noImage from  '../../../assets/img/no-image.png';

import OfferDetailCard from './tabBidDetail/OfferDetailCard';
import SneakerSummaryCard from './tabBidDetail/SneakerSummaryCard';

const getPublicImageUrl = image => {
  return image.signed_url || image.URL
}

const TabBidDetail = props => {
  const { product_variant } = props.dataBid;
  const featuredImage = product_variant && product_variant.product_variant_images.length>0?
    getCacheImage(getPublicImageUrl(product_variant.product_variant_images[0])) : noImage;
  return (
    <Card body className="tab-content--custom__card">
      <CardText>About their's offer.</CardText>
      <Row>
        <Col xs={12} md={6}>
          <OfferDetailCard
            id={props.dataBid.id}
            isLoading={props.isLoading}
            code={props.dataBid.code}
            payment={props.dataBid.offer}
            currency={props.dataBid.currency}
            paymentMethod={props.dataBid.payment_method}
            amount={props.dataBid.amount}
            originalPrice={props.dataBid.original_price}
            depositAmount={props.dataBid.deposit_amount}
            administrationFee={props.dataBid.administration_fee}
            kaCourier={props.dataBid.ka_courier}
            kaCourierOption={props.dataBid.ka_courier_option}
            kaCourierPrice={props.dataBid.ka_courier_price}
            expiredAt={props.dataBid.expired_at}
            updatedAt={props.dataBid.updated_at}
          />
        </Col>
        <Col xs={12} md={6}>
          <SneakerSummaryCard
            id={props.dataBid.id}
            isLoading={props.isLoading}
            featuredImage={featuredImage||noImage}
            productVariantId={props.dataBid.product_variant_id}
            productVariant={product_variant}
            size={props.dataBid.size}
            boxCondition={props.dataBid.box_condition}
            sneakersCondition={props.dataBid.sneakers_condition}
            preOrder={props.dataBid.pre_order}
            missingAccessories={props.dataBid.missing_accessories}
            yellowing={props.dataBid.yellowing}
            displayItem={props.dataBid.display_item}
            sneakersDefect={props.dataBid.sneaker_defect}
            amount={props.dataBid.amount}
            paymentMethod={props.dataBid.payment_method}
            status={props.dataBid.status}
            sellerCredit={props.dataBid.scredit_amount}
            depositAmount={props.dataBid.deposit_amount}
            totalAmount={props.dataBid.total_amount}
            originalPrice={props.dataBid.original_price}
            administrationFee={props.dataBid.administration_fee}
            kaCourier={props.dataBid.ka_courier}
            kaCourierOption={props.dataBid.ka_courier_option}
            kaCourierPrice={props.dataBid.ka_courier_price}
          />
        </Col>
      </Row>
    </Card>
  )
}

export default TabBidDetail;
TabBidDetail.defaultProps = {
  isLoading: false,
  dataBid: {
    id: null,
    product_variant: {
      id: null,
      display_name: '',
      product:{
        brand_id: null,
        brand: {
          id: null
        },
        category_id: null,
        category: {
          id: null
        }
      },
      product_variant_images: []
    },
    user_sell: {
      id: null,
      product_variant_images: []
    },
    offer: {
      id: null,
      invoice_number: ''
    }
  }
}

TabBidDetail.propTypes = {
  isLoading: propTypes.bool,
  dataBid: propTypes.object,
}