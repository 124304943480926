import React from 'react';
import { SeekDiv } from '../styled';
import AlertNotFound from '../../../components/Card/AlertNotFound';

const SeekItemNotFound = () => (
  <SeekDiv>
    <AlertNotFound/>
  </SeekDiv>
)

export default SeekItemNotFound;