import React from "react";
import propTypes from "prop-types";
import { Card, CardHeader, CardBody, Row, Col } from "reactstrap";
import ImageDropzone from "./ImageDropzone";
import CardThumbnail from "./CardThumbnail";

const UploadImages = (props) => (
  <Card>
    <CardHeader>Image</CardHeader>
    <CardBody>
      <Row>
        {!props.images ||
          (!props.images.length && (
            <Col xs={12} style={{ marginBottom: "1rem" }}>
              <ImageDropzone onDrop={props.onDrop} />
            </Col>
          ))}
        {props.images.map((image, index) => (
          <Col xs={12} sm={6} md={6} key={index}>
            <CardThumbnail
              id={image.id}
              url={image.url}
              updatedAt={image.updatedAt}
              storageName={image.storageName}
              fileInput={image.fileInput}
              isUploading={image.isUploading && !image.status}
              isDeleting={image.deleted}
              onRemoveImage={(e) => props.onRemoveImage(e, image.identifier)}
            />
          </Col>
        ))}
      </Row>
    </CardBody>
  </Card>
);

UploadImages.propTypes = {
  onDrop: propTypes.func,
  images: propTypes.arrayOf(propTypes.object),
};

UploadImages.defaultProps = {};

export default UploadImages;
