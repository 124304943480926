import React from 'react';
import propTypes from 'prop-types';
import classNames from 'classnames';

const InputFile = ({ inputProps, className, children, accept, disabled, multiple, onChange, ...props }) => (
  <div
    className={classNames("btn btn-secondary btn-upload", className)}
    { ...props }
  >
    <label className="label-upload">
      <span className="text-upload">
        { children }
      </span>
      <input
        className="input-upload"
        type="file"
        onInput={onChange}
        onClick={e=> e.currentTarget.value = null}
        multiple={multiple}
        disabled={disabled}
        accept={accept}
        {...inputProps}
      />
    </label>
  </div>
);

export default InputFile;

InputFile.defaultProps = {
  disabled: false,
  multiple: true,
  children: "Browse..."
}

InputFile.propTypes = {
  inputProps: propTypes.object,
  className: propTypes.string,
  children: propTypes.oneOfType([ propTypes.node, propTypes.string ]),
  accept: propTypes.string,
  multiple: propTypes.bool,
  disabled: propTypes.bool,
  onChange: propTypes.func
}