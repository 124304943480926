import React from 'react';
import { Col, Label, Input, Button } from 'reactstrap';
import styled from 'styled-components';

const SizeWrapper = styled(Col)`
  padding: 0px;
  position: relative;
  label.form-check-label {
    cursor: pointer;
    width: 100%;
    height: 100%;
    >input {
      margin-left: -.5rem;
      margin-top: .75rem;
      margin-right: 0rem;
    }
  }
  > label.form-check-label {
    position: absolute;
  }
`
const SizeItem = styled.div`
  border: 1px solid #ddd;
  padding: 6px 12px;
  height: 100%;
`
export const SizeList = styled.div`
  padding: .5rem 1rem;
  > p{
    margin-left: -1rem;
    margin-right: -1rem;
    margin-bottom: .5rem
  }
`
const AlreadyAdded = styled.div`
  display: block;
  text-align: right;
  font-style: italic;
  flex: 1;
`

const SizeHeader = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  font-size: .65rem;
  padding-left: 1rem;
  margin-bottom: .25rem;
  padding-top: .25rem;
`
const SizeAdditionalInfo = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`
const SizeFooter = styled.div`
  font-size: .75rem;
  margin-bottom: .5rem;
  > ${SizeAdditionalInfo} div {
    p{
      margin: 0px;
      text-align: center;
    }
    p:first-child{
      margin-bottom: .5rem;
    }
    p:last-child{
      font-size: .5rem;
    }
  }
`

const Size = props => (
  <SizeWrapper xs={6} md={4}>
    <div style={{position: "absolute", width: '100%', height: '100%', display: 'flex', alignItems: 'flex-start'}}>
      <Label disabled={props.disabled} check>
        <Input type="checkbox"
          onChange={props.onCheckedChange}
          checked={props.taken}
          value="true"
          disabled={props.disabled}
        />
      </Label>
      {
        props.withDelete && !props.disabled && (
          <Button
            size="sm"
            color="danger"
            onClick={props.onDeleteItem}
          >
            <i className="icon-trash"></i>
          </Button>
        )
      }
    </div>
    <SizeItem>
      <SizeHeader>
        <div style={{color: '#ddd'}}>ID: {props.id}</div>
        {
          props.taken && props.disabled?
          <AlreadyAdded
            className="text-success"
          >
            Added.
          </AlreadyAdded>
          : null
        }
      </SizeHeader>
      <div>Size: {props.US}</div>
      <SizeFooter>
        <div>Size for: {props.sex}</div>
        <SizeAdditionalInfo>
          <div>
            <p>UK</p>
            <p>{props.UK? props.UK: '-'}</p>
          </div>
          <div>
            <p>EUR</p>
            <p>{props.EUR? props.EUR: '-'}</p>
          </div>
          <div>
            <p>cm</p>
            <p>{props.cm? props.cm: '-'}</p>
          </div>
          <div>
            <p>inch</p>
            <p>{props.inch? props.inch: '-'}</p>
          </div>
        </SizeAdditionalInfo>
        </SizeFooter>
    </SizeItem>
  </SizeWrapper>
)
export default Size;