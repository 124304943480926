import React from 'react';
import { connect } from "react-redux";
import { Creatable } from "react-select";
import { Link } from 'react-router-dom';
import {
  Alert,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Form,
  FormGroup,
  ButtonGroup,
  Label,
  Input,
  UncontrolledTooltip,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  CardText,
  CardSubtitle,
  Collapse
} from 'reactstrap';
import classNames from 'classnames';
import uuidv4 from 'uuid/v4';
import moment from 'moment-timezone';

import {
  upload,
  newSlider,
  saveSlider,
  deleteSlider,
  getDetailSlider,
  storeSliderImages
} from '../../actions/sliderAction';
import {
  getOptionList as getCategoryOptionList
}
from '../../actions/categoriesAction';
import { getDetailProductVariant } from '../../actions/productVariantAction';
import { APP_STAGED, FRONTEND_WEB } from '../../constants';
import Select from 'react-select';
import 'react-select/dist/react-select.css';
import { FontAwesomeIcon, SimpleLineIcon } from '../../components/Icons';
import CardLoadingPlaceholder from '../../components/Card/CardLoadingPlaceholder';
import InputDatetime from '../../components/Form/InputDatetime/InputDatetime';
import { ButtonLoading } from '../../components/Button';
import { Radio, RadioText, FormGroupRadio } from '../../components/Form/Radios';
// import { ImageList, ImagesForm } from './Image'
import { ImageFile } from '../../components/Images';
import { DropzonePlusButton } from '../../components/Form/DropzoneFile';
import { ModalDelete, ModalEditImage, ModalBlockLoading as ModalLoading } from '../../components/Modals';
import { isRequired } from '../../helpers/regex';
import { getPathS3 } from '../../utils/AWS';
import { ImagesCompressor, readFile, createUploadFileForm } from '../../utils/imageHelper'
import { addErrorMessage, errorMessage, hasError, textValue, textChange } from '../../utils/form';
import ModalProductVariants from './sliderForm/ModalProductVariants';
import ModalCollections from './sliderForm/ModalCollections';
import { uploadImage, deleteImage } from '../../actions/imageUploadAction';
import { getOriginFromSignedUrl } from '../../utils/AWS';
import ModalRaffles from './sliderForm/ModalRaffles';
import Badge from 'reactstrap/lib/Badge';

const sliderTypeOptions = [{
  value: "", label: "Select type..."
},{
  value: "MAIN", label: "Main"
},{
  value: "PROMO", label: "Promotional"
}];

const sliderPlatformOptions = [{
  value: "", label: "Web & App"
},{
  value: "APP", label: "Only App"
},{
  value: "WEB", label: "Only Web"
}];

const sortOptions = [{
  value:1, label:1
},{
  value:2, label:2
},{
  value:3, label:3
},{
  value:4, label:4
},{
  value:5, label:5
}];


const addImageRawObject = file => {
  return {
    status: false,
    identifier: uuidv4(),
    fileInput: file,
    is_uploading: false
  }
}

const visibleOnApp = value =>
  value === null || value === '' || value === 'APP'

const SUBMIT_UPLOAD_DEFAULT_IMG_STATUS = 'SUBMIT_UPLOAD_DEFAULT_IMG_STATUS';
const SUBMIT_UPLOAD_IMAGES_STATUS = 'SUBMIT_UPLOAD_IMAGES_STATUS';
const SUBMIT_SLIDER_FORM_STATUS = 'SUBMIT_SLIDER_FORM_STATUS';
const DELETE_SLIDER_FORM_STATUS = 'DELETE_SLIDER_FORM_STATUS';
const DEFAULT_IMAGE_ASPECT_LANDSCAPE = 16/5;
const DEFAULT_IMAGE_ASPECT_PORTRAIT = 1/1;
const FIX_SLIDER_URL_TIMEOUT = 2500;
class SliderForm extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      id: 0,
      name: '',
      active: 0,
      category_id: '',
      img_url: '',
      tags:null,
      oldImageUrl: null,
      data: null,
      redirect_url: '',
      platform: '',
      type: '',
      order: 1,
      started_at: '',
      ended_at: '',
      created_at: '',
      updated_at: '',
      isEditImageOpen: false,
      editImageRatio: DEFAULT_IMAGE_ASPECT_LANDSCAPE,
      imageSourceToEdit: null,
      imageSourceToEdits: [],
      imageSourceReferer: null, //'default' or 'childs'
      _initFetch: true,
      _rawImage: null,
      _rawImages: [],
      _uploadCounter: 0,
      _formSubmit: false,
      _error: null,
      _submitFormStatus: null,
      _isSuccessSubmitAlertOpen: false,
      _isSuccessUploadDefaultImgAlertOpen: false,
      _isModalLoadingOpen: false,
      _isModalDeleteOpen: false,
      _modalProductVariant: {
        isOpen: false,
        purpose: null
      },
      _modalProductCollection: {
        isOpen: false,
        purpose: null
      },
      _modalRaffle: {
        isOpen: false,
        purpose: null
      },
      _validateRedirectUrlInput: false,
      _fixSliderUrlResolver: null,
      _sliderClickedSelected: null,
      _sliderClickedActions: [{
        type: "NONE",
        label: "None",
        values: null
      }, {
        type: "PRODUCT_DETAIL_SCREEN",
        label: "Product Detail Screen",
        values: {
          body: null
        }
      }, {
        type: "PRODUCT_SEARCH_SCREEN",
        label: "Product Search Screen",
        values: {
          body: null
        }
      }, {
        type: "PREVIEW_RAFFLE_SCREEN",
        label: "Preview Raffle Screen",
        values: {
          body: null
        }
      }, {
        type: "RAFFLE_DETAIL_SCREEN",
        label: "Raffle Detail Screen",
        values: {
          slug: null
        }
      }, {
        type: "COLLECTION_SCREEN",
        label: "Collection Screen",
        values: {
          body: null
        }
      }],
      _defaultTags: [
        {
          label: "nike",
          value: "nike"
        },
        {
          label: "adidas",
          value: "adidas"
        }
      ],
    };
    this._handleOnChange = this._handleOnChange.bind(this);
  }

  componentDidMount(){
    const { match } = this.props;
    if(match && match.params.id){
      this.props.find(match.params.id);
    }
    this.props.bindCategoryOption();
  }

  componentWillUnmount(){
    this.props.new();
    const { _fixSliderUrlResolver } = this.state;
    if(_fixSliderUrlResolver) clearTimeout(_fixSliderUrlResolver)
  }

  _getFileName = s3Link =>{
    const file = getPathS3(s3Link);
    const hasUploaded = file !== null;
    return hasUploaded? file.replace(/^\/slider-images\//, ''): null;
  }

  async componentDidUpdate(prevProps){
    const { match } = this.props;
    const { isFetch, isSubmit } = this.props.slider;
    const { isFetch: prevIsFetch, isSubmit: prevIsSubmit } = prevProps.slider;

    if(prevProps.productVariant.isFetch && !this.props.productVariant.isFetch){
      const { detail, error } = this.props.productVariant;
      if(!error && detail.id)
      this.setState({
        _sliderClickedSelected: "PRODUCT_DETAIL_SCREEN",
        _sliderClickedActions: this.state._sliderClickedActions.map(item => {
          return item.type === "PRODUCT_DETAIL_SCREEN"? {
            ...item,
            values: item.values? {
              ...item.values,
              payload: detail.id,
              body: {
                ...item.values.body,
                id: detail.id,
                display_name: detail.display_name,
                SKU: detail.SKU,
                slug: detail.slug,
                category: detail.product.category.name.toLowerCase()
              }
            }: null
          }: item
        })
      })
    }
    if(isFetch !== prevIsFetch && !isFetch){
      const { detail, error } = this.props.slider;
      if(!error && match && match.params.id){
        const { _submitFormStatus, _initFetch } = this.state;
        if(_initFetch){
          const appImage = detail.images.length ? {
            id: detail.images[0].id,
            URL: detail.images[0].signed_url ? detail.images[0].signed_url : detail.images[0].URL,
            imgUrl: detail.images[0].signed_url ? detail.images[0].signed_url : detail.images[0].URL,
            orientation: detail.images[0].orientation,
            raw: null
          } : null;
          
          this.setState({
            _formSubmit: false,
            _rawImage: null,
            _appImage: appImage,
            _initFetch: false,
            ...this._bindDataApi()
          })
        }
        else if(_submitFormStatus === 'SYNC_IMAGES'){
          const appImage = detail.images.length? {
            id: detail.images[0].id,
            URL: detail.images[0].signed_url? detail.images[0].signed_url: detail.images[0].URL,
            imgUrl: detail.images[0].signed_url?  detail.images[0].signed_url: detail.images[0].URL,
            orientation: detail.images[0].orientation,
            raw: null
          }: null;
          this.setState({
            id: detail.id,
            _appImage: appImage,
            _formSubmit: false,
            _rawImages: [],
            _isModalLoadingOpen: false,
            _submitFormStatus: null
          })
        }
      }
      else{
        this.setState({
          _formSubmit: false,
          _isModalLoadingOpen: false,
          _error: { ...error }
        });
      }
    }
    if(isSubmit !== prevIsSubmit && !isSubmit && this.state._formSubmit){
      const { detail, error } = this.props.slider;
      if(error){
        this.setState({
          _formSubmit: false,
          _error: { ...error }
        })
      }else{
        const { oldImageUrl, _rawImage, _submitFormStatus } = this.state;
        if(this.state.img_url !== oldImageUrl && _rawImage !== null && _submitFormStatus !== SUBMIT_UPLOAD_DEFAULT_IMG_STATUS){
          const fileInput =  await this._compressImage(_rawImage.fileInput)
          await this.setState({
            _rawImage: {
              ...this.state._rawImage,
              fileInput,
              is_uploading: true
            },
            _isModalLoadingOpen: true,
            _submitFormStatus: SUBMIT_UPLOAD_DEFAULT_IMG_STATUS
          },()=>{
            const { oldFileName } = this.state;
            const fileName = this._getFileName(this.state.img_url);
            const name = oldFileName === fileName ? oldFileName: `slider-images/${detail.id}`;
            const tempPayload = createUploadFileForm(fileInput, false, {name, bucket_type: "assets"});
            this.props.uploadImage(tempPayload, _rawImage);
          })
        }
        else{
          const rawImages = await this._compressImgsProgress()

          if(match.params.id != detail.id && !rawImages.length){
            this.props.history.replace(`/sliders/${detail.id}`)
          }
          else if(detail.delete === true){
            this.props.history.replace(`/sliders`)
          }
          else{
            await this.setState({
              _formSubmit: rawImages.length? true: false,
              _isModalLoadingOpen: rawImages.length? true: false,
              _submitFormStatus: rawImages.length? SUBMIT_UPLOAD_IMAGES_STATUS: null,
              _uploadCounter: 0,
              _rawImage: null,
              _isSuccessSubmitAlertOpen: true,
              _isSuccessUploadDefaultImgAlertOpen: false,
              ...this._bindDataApi()
            })
            if(rawImages.length){
              const tempPayload = createUploadFileForm(rawImages[0].fileInput, false, {name: "slider-images/"+detail.id, bucket_type: "assets"});
              this.props.uploadImage(tempPayload, rawImages);
            }
          }
        }
      }
    }
    if(prevProps.slider.images.isSubmit !== this.props.slider.images.isSubmit
      && !this.props.slider.images.isSubmit){
      const { error } = this.props.slider.images;
      const { id } = this.state;
      if(this.props.match.params.id != id){
        this.props.history.replace(`/sliders/${id}`)
      }
      if(error){
        this.setState({
          _error: {
            status_code: 500,
            message: "Failed to connect our api."
          },
          _formSubmit: false,
          _isModalLoadingOpen: false,
          _submitFormStatus: null
        });
      } else{
        this.setState({
          _rawImages: [],
          _submitFormStatus: 'SYNC_IMAGES'
        })
        this.props.find(id)
      }
    }
    if(this.state._submitFormStatus === SUBMIT_UPLOAD_DEFAULT_IMG_STATUS && prevProps.dataUploaded.isUpload 
      && prevProps.dataUploaded.isUpload !== this.props.dataUploaded.isUpload){
      const { success, error } = this.props.dataUploaded;
      const hasPendingUploads = this.state._uploadCounter + 1 < this.state._rawImages.length;
      const nextUpload = this.state._uploadCounter + 1;
      if(error){
        await this.setState({
          _formSubmit: false,
          _rawImages: this.state._rawImages.map((r, key) => {
            if(key === this.state._uploadCounter){
              return{
                ...r,
                is_uploading: false,
                is_error: true
              }
            } else if(hasPendingUploads && key === nextUpload){
              return {
                ...r,
                is_uploading: true
              }
            }
            return { ...r }
          }),
          _isModalLoadingOpen: hasPendingUploads,
          _submitFormStatus: hasPendingUploads? SUBMIT_UPLOAD_IMAGES_STATUS: null,
          _uploadCounter: hasPendingUploads? nextUpload : 0,
          _error: {
            status_code: 500,
            message: 'Failed connect to s3.'
          }
        });

        if(hasPendingUploads){
          const { id, _rawImages } = this.state;
          const tempPayload = createUploadFileForm(_rawImages[nextUpload], false, {name: "slider-images/"+id, bucket_type: "assets"});
          this.props.uploadImage(tempPayload, _rawImages);
        }
      }
      if(!error){
        const { detail } = this.props.slider;
          if(success.data){
          const { _rawImage } = this.state;
            if(_rawImage.identifier === success.data.identifier){
              this.setState({
                id: detail.id,
                img_url: success.data.url,
                _rawImage: null,
                oldImageUrl: success.data.url,
                oldFileName: this._getFileName(success.data.url),
                _isSuccessUploadDefaultImgAlertOpen: true
              }, ()=>{
                this._submitToApi();
              });
            }
        }
      }
    }
    if(this.state._submitFormStatus === SUBMIT_UPLOAD_IMAGES_STATUS && prevProps.dataUploaded.isUpload 
      && prevProps.dataUploaded.isUpload !== this.props.dataUploaded.isUpload){
        const { success, error } = this.props.dataUploaded;
        if(error){
          await this.setState({
            _formSubmit: false,
            _isModalLoadingOpen: false,
            _submitFormStatus: null,
            _uploadCounter:0,
            _error: {
              status_code: 500,
              message: 'Failed connect to s3.'
            }
          });
        }
        if(!error){
          const { data } = success.data;
          if(Object.keys(data).length){
            const { _rawImages, _appImage, id } = this.state;
            data.map(item => {
              if(_rawImages[0].identifier === item.identifier){
                const isAppImage = _appImage && _appImage.raw !== null && _appImage.raw.identifier === item.identifier;
                const payloads = isAppImage? {
                  id: _appImage.id,
                  orientation:item.orientation,
                  url:item.url
                }:{ images: [{url : item.url, orientation : item.orientation}]};
                this.props.storeSliderImages(id, payloads)
              }
            })
          }
          else{
            this.setState({ _formSubmit: false, _isModalLoadingOpen: false })
          }
        }
    }
    if(prevProps.dataUploaded.isDeleteProgress && prevProps.dataUploaded.isDeleteProgress !== this.props.dataUploaded.isDeleteProgress) {
      const { deleted, error } = this.props.dataUploaded;
      const { id, _formSubmit, _appImage } = this.state;
      this.props.delete(id)
      if(error){
        if(_formSubmit){
          if(_appImage && _appImage.imgUrl){
            const payload = {
              bucket_type : 'assets',
              name : getPathS3(getOriginFromSignedUrl(_appImage.imgUrl))
            };
            this.props.deleteImage(payload);
          }
        }
        else{
          this.setState({
            _submitFormStatus: false,
            _isModalLoadingOpen: false,
            _submitFormStatus: null,
            _uploadCounter:0,
            _error: {
              status_code: 500,
              message: 'Failed delete img.'
            }
          })
        }
      }
      if(_formSubmit && !error && deleted.data){
        if(_appImage && _appImage.imgUrl){
          const payload = {
            bucket_type : 'assets',
            name : getPathS3(getOriginFromSignedUrl(_appImage.imgUrl))
          };
          this.props.deleteImage(payload);
        }
      }
    }
  }

  _bindDataApi = () => {
    const { detail } = this.props.slider;
    const fileName = this._getFileName(detail.img_url||'');
    const startAtParsed = moment(detail.started_at, 'YYYY-MM-DD');
    const endedAtParsed = moment(detail.ended_at, 'YYYY-MM-DD');
    let _sliderClickedSelected = null;
    _sliderClickedSelected = detail.data? detail.data.type: "NONE";
    const dataTags =
          detail.tags !== null
            ? detail.tags.split(",").map(tag => ({ label: tag, value: tag }))
            : [];
    const payloads = {
      id: detail.id,
      name: detail.name,
      tags: dataTags,
      active: detail.active,
      category_id: detail.category_id,
      img_url: detail.signed_url? detail.signed_url: detail.img_url,
      oldImageUrl: detail.signed_url? detail.signed_url: detail.img_url,
      oldFileName: fileName,
      redirect_url: detail.redirect_url,
      platform: detail.platform||'',
      type: detail.type||'',
      order: detail.order,
      started_at: startAtParsed.isValid()? startAtParsed: '',
      ended_at: endedAtParsed.isValid()? endedAtParsed: '',
      created_at: detail.created_at,
      updated_at: detail.updated_at,
      _sliderClickedSelected,
      _sliderClickedActions: this.state._sliderClickedActions.map(item =>{
        if(item.type === _sliderClickedSelected){
          return {
            ...item,
            values: detail.data?{
              payload: detail.data.payload,
              body: detail.data.body,
            }: null
          }
        }
        return item
      })
    }
    return payloads;
  }

  _compressImgsProgress = async () => {
    const { _rawImages } = this.state;
    const rawImagesPromises = _rawImages.map(async(r, key) => {
      const fileInput =  await this._compressImage(r.fileInput)
      return {
        ...r,
        is_uploading: key === 0,
        fileInput
      }
    });
    return await Promise.all(rawImagesPromises)
  }

  _uploadImgsProgress = (id, commpressImages) => {
    if(commpressImages.length){
      this.setState({
        id: id,
        _isModalLoadingOpen: true,
        _rawImages: [...commpressImages],
        _formSubmit: true,
        _submitFormStatus: SUBMIT_UPLOAD_IMAGES_STATUS,
        _uploadCounter: 0
      })
      this.props.upload(commpressImages[0], `slider-images/${id}/`)
    }
  }

  _renderSelectedActionLabel = () => {
    const { _sliderClickedSelected, _sliderClickedActions } = this.state;
    const data = _sliderClickedActions.find(item => item.type === _sliderClickedSelected)
    if(data) return data.label
    return "Choose an action..."
  }

  _refixKickavenueUrl = url => {
    const urlRegexExpression = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,}|(http?:\/\/localhost(:[0-9]+)?))/gi
    const urlRegex = new RegExp(urlRegexExpression);
    const httpExpression = /(^https:\/\/)|(^http:\/\/)/
    let newUrl = url.trim();
    if(newUrl.match(urlRegex)){
      if(!httpExpression.test(newUrl))
        newUrl = `http${APP_STAGED === 'production'? 's': ''}://${newUrl}`;
    } else{
      if(/kickavenue.com/.test(newUrl) && !httpExpression.test(newUrl)){
        newUrl = `http${APP_STAGED === 'production'? 's': ''}://${newUrl}`;
      }
      else{
        newUrl = `${FRONTEND_WEB}${newUrl.indexOf('/') !== 0? `/${newUrl}`: newUrl}`;
        newUrl = !httpExpression.test(newUrl)? `http${APP_STAGED === 'production'? 's': ''}://${newUrl}`: newUrl
      }
    }
    return newUrl;
  }

  _fixSliderUrlHandler = () => {
    const { _fixSliderUrlResolver, redirect_url } = this.state;
    if(_fixSliderUrlResolver) clearTimeout(_fixSliderUrlResolver)
    const newResolver = redirect_url.length > 3? setTimeout(() => {
      const url = this._refixKickavenueUrl(redirect_url)
      this.setState({
        redirect_url: url,
        _validateRedirectUrlInput: false,
        _fixSliderUrlResolver: null,
      })
    }, FIX_SLIDER_URL_TIMEOUT): null
    this.setState({
      _fixSliderUrlResolver: newResolver,
      _validateRedirectUrlInput: newResolver !== null
    })
  }

  _getDataFromUrl = url => {
    const urlRegexExpression = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,}|(http?:\/\/localhost(:[0-9]+)?))/gi
    const urlRegex = new RegExp(urlRegexExpression);
    const httpExpression = /(^https:\/\/)|(^http:\/\/)/;
    const webExpression = /(kickavenue.com)|(^localhost(:[0-9]+)?)/
    if(url.match(urlRegex)){
      let parsedUrl = url.replace(httpExpression, '').trim();
      if(webExpression.test(parsedUrl)){
        parsedUrl = parsedUrl.substr(parsedUrl.indexOf('/')+1)
          .split('/')
          .filter(w => w !== '');
        if(parsedUrl.length === 3){
          const [category, id, keyword] = parsedUrl;
          if(id === 'search'){
            const type = "PRODUCT_SEARCH_SCREEN";
            return {
              type,
              values: {
                payload: keyword.replace(/\+/g, ' '),
                body: {
                  category,
                  keyword: keyword.replace(/\+/g, ' ')
                }
              }
            }
          }
          else if(!isNaN(Number(id))){
            const type = "PRODUCT_DETAIL_SCREEN";
            const productId = Number(id);
            const { detail: productDetail } = this.props.productVariant;
            let payload = { category, id, slug: keyword };
            if(productDetail.id === productId){
              payload = {
                ...payload,
                display_name: productDetail.display_name,
                category: productDetail.product.category.name.toLowerCase(),
                SKU: productDetail.SKU
              }
            } else{
              this.props.getDetailProductVariant(productId);
            }
            return {
              type,
              values: {
                payload: productId,
                body: { ...payload }
              }
            }
          }
        }
      }
    }
    return { type: "NONE", values: null };
  }

  _renderContentData() {
    const { _sliderClickedSelected, _sliderClickedActions } = this.state;
    const data = _sliderClickedActions.find(d => d.type === _sliderClickedSelected);
    if(data){
      switch(_sliderClickedSelected){
        case "NONE": return (
          <Alert isOpen color="info">
            <strong>Info: </strong>
            Do nothing, user will not redirect to any screen when they click this slider through app.
          </Alert>
        )
        case "PRODUCT_DETAIL_SCREEN": {
          const { values: { payload, body } } = data;
          return body? (
            <Card body>
              <div style={{fontSize: '.65rem'}} className="mb-3">
                PRODUCT (ID: {payload}){' '}
                <a
                  href="#"
                  style={{textDecoration: 'underline'}}
                  className="mt-2"
                  onClick={e => {
                    e.preventDefault();
                    this._handleOpenProductModal("GENERATE_APP_ACTION")
                  }}
                >
                  Choose a product
                </a>
              </div>
              <CardSubtitle>
                {body.display_name? body.display_name: '-'}
              </CardSubtitle>
              <CardText style={{fontSize: '.75rem'}} className="text-uppercase mb-0">
                {body.category} / {body.SKU? body.SKU: '-'}
              </CardText>
            </Card>
          ): (
            <Alert isOpen color="info">
              <strong>Info: </strong>
              You haven't selected a product. {' '}
              <a
                href="#"
                style={{textDecoration: 'underline'}}
                onClick={e => {
                  e.preventDefault();
                  this._handleOpenProductModal("GENERATE_APP_ACTION")
                }}
              >
                Choose a product
              </a>
            </Alert>
          )
        }
        case "RAFFLE_DETAIL_SCREEN": {
          const { values: { payload, body } } = data;
          return body? (
            <Card body>
              <div style={{fontSize: '.65rem'}} className="mb-3">
                RAFFLE (ID: {payload}){' '}
              <a
                href="#"
                style={{textDecoration: 'underline'}}
                onClick={e => {
                  e.preventDefault();
                  this._handleOpenRaffleModal("GENERATE_APP_ACTION")
                }}
              >
                Choose another raffle.
              </a>
              </div>
              <CardSubtitle>
                <strong>
                  {body.group? body.group: '-'}
                  {" "} 
                  <b>
                    <Badge
                      className="mr-1 text-uppercase"
                      style={{
                        backgroundColor: body.colour,
                      }}
                    >
                      {body.group}
                    </Badge>
                  </b>
                </strong>
              </CardSubtitle>
              <span>{body.name? body.name: '-'}</span>
              <CardText style={{fontSize: '.75rem'}} className="mb-0">
                slug: {body.slug? body.slug: '-'}
              </CardText>
            </Card>
          ): (
            <Alert isOpen color="info">
              <strong>Info: </strong>
              You haven't selected a raffle. {' '}
              <a
                href="#"
                style={{textDecoration: 'underline'}}
                onClick={e => {
                  e.preventDefault();
                  this._handleOpenRaffleModal("GENERATE_APP_ACTION")
                }}
              >
                Choose a raffle.
              </a>
            </Alert>
          )
        }
        case "COLLECTION_SCREEN": {
          const { values: { payload, body } } = data;
          return body? (
            <Card body>
              <div style={{fontSize: '.65rem'}} className="mb-3">
                COLLECTON (ID: {payload}){' '}
              <a
                href="#"
                style={{textDecoration: 'underline'}}
                onClick={e => {
                  e.preventDefault();
                  this._handleOpenProductCollectionModal("GENERATE_APP_ACTION")
                }}
              >
                Choose another collection.
              </a>
              </div>
              <CardSubtitle>
                <strong>{body.name? body.name: '-'}</strong>
              </CardSubtitle>
              <CardText style={{fontSize: '.75rem'}} className="mb-0">
                <span className="text-uppercase">{body.category}</span> / {body.slug? body.slug: '-'}
              </CardText>
            </Card>
          ): (
            <Alert isOpen color="info">
              <strong>Info: </strong>
              You haven't selected a collection. {' '}
              <a
                href="#"
                style={{textDecoration: 'underline'}}
                onClick={e => {
                  e.preventDefault();
                  this._handleOpenProductCollectionModal("GENERATE_APP_ACTION")
                }}
              >
                Choose a collection.
              </a>
            </Alert>
          )
        }
        default: return null;
      }
    }
    return null;
  }

  _handleOnChange(value) {
    this.setState({ tags:value });
  }

  _handleInputChange = ({ target: { name, value, checked, type } }) => {
    if(name === 'redirect_url'){
      this.setState({ [name]: value }, this._fixSliderUrlHandler)
    }
    else{
      this.setState({ [name]: type === 'checkbox'? checked: value })
    }
  }

  _handleSelectChange = (name, val) => {
    if(name === 'platform'){
      this.setState({ [name]: val? val.value: '' }, this._fixSliderUrlHandler)
      return;
    }
    this.setState({ [name]: val? val.value: null })
  }


  _hasError = name => {
    const { _error } = this.state;
    return _error && _error.errors && hasError(name, _error)
  }

  _renderErrorMessage = name => {
    const { _error } = this.state;
    return errorMessage(name, _error)
  }

  _validateForm = async () => {
    const {
      name,
      img_url,
      redirect_url,
      type,
      category_id,
    } = this.state;
    let errors={};
    if(!isRequired(name))
      errors['name'] = addErrorMessage(errors, 'name', 'The name field is required.');
    if(!isRequired(type))
      errors['type'] = addErrorMessage(errors, 'type', 'Please select type.');
    if(!isRequired(category_id))
      errors['category_id'] = addErrorMessage(errors, 'category_id', 'Please select category.');
    if(!isRequired(img_url))
      errors['img_url'] = addErrorMessage(errors, 'img_url', 'Please upload any image.');
    if(!isRequired(redirect_url))
      errors['redirect_url'] = addErrorMessage(errors, 'redirect_url', 'The redirect url field is required.');
    if(Object.keys(errors).length){
      await this.setState({
        _error: {
          errors: { ...errors },
          status_code: 422,
          message: 'Please full fill form'
        }
      });
    } else{
      await this.setState({ _error: null })
    }
    return Object.keys(errors).length? errors: null;
  }

  _submitToApi = () => {
    const { data, _sliderClickedSelected, _sliderClickedActions, tags } = this.state;
    const findData = _sliderClickedActions.find(s => s.type === _sliderClickedSelected)
    let dataApp = data

    if (findData) {
      if (findData.type === 'RAFFLE_DETAIL_SCREEN') {
        dataApp = (findData.values? {
          type: findData.type,
          slug: findData.values.body.slug,
          ...findData.values
        }: findData.values)
      } else {
        dataApp = (findData.values? {
          type: findData.type,
          ...findData.values
        }: findData.values)
      }
    }

    const dataTags = tags && typeof tags === typeof [] && tags.length
    ? tags
        .map(tag => tag.value)
        .reduce((concat, tag) => concat + "," + tag)
    : null;
    const payloads = {
      category_id: this.state.category_id,
      name: this.state.name,
      type: this.state.type,
      active: this.state.active,
      img_url: getOriginFromSignedUrl(this.state.oldImageUrl),
      redirect_url: this.state.redirect_url,
      order: Number(this.state.order),
      data: dataApp,
      tags: dataTags,
      platform: this.state.platform === ''? null: this.state.platform,
      started_at: this.state.started_at === ''? null: (
        moment.isMoment(this.state.started_at)? this.state.started_at.format("YYYY-MM-DD"): this.state.started_at),
      ended_at: this.state.ended_at === ''? null: (
        moment.isMoment(this.state.ended_at)? this.state.ended_at.format("YYYY-MM-DD"):this.state.ended_at),
    }
    this.setState({
      _submitFormStatus: SUBMIT_SLIDER_FORM_STATUS
    });
    if(this.state.id > 0){
      this.props.save({ ...payloads, id: this.state.id })
    } else{
      this.props.save({ ...payloads })
    }
  }

  _handleSubmit = async (e) => {
    e.preventDefault();
    const err = await this._validateForm()
    const { _validateRedirectUrlInput } = this.state;
    const { productVariant: { isFetch } } = this.props;
    if(_validateRedirectUrlInput || isFetch) return;
    if(!err){
      this.setState({ _formSubmit: true }, async() =>{
        const { oldImageUrl, oldFileName, _rawImage } = this.state;
        if(!this.props.match.params.id && this.state.img_url !== oldImageUrl && _rawImage !== null){
          const fileInput =  await this._compressImage(_rawImage.fileInput)
          await this.setState({
            _rawImage: {
              ...this.state._rawImage,
              fileInput,
              is_uploading: true
            },
            _isModalLoadingOpen: true,
            _submitFormStatus: SUBMIT_UPLOAD_DEFAULT_IMG_STATUS
          })
          const name = oldFileName? oldFileName: `slider-images`;
          const tempPayload = createUploadFileForm(fileInput, false, {name, bucket_type: "assets"});
          this.props.uploadImage(tempPayload, _rawImage);
        } else{
          this._submitToApi();
        }
      })
    }
  }
  
  _compressImage = async (image) => {
    let fileInput = image;
    if(fileInput.type === "image/gif"){
      return fileInput;
    }

    try{
      //compress file
      fileInput = await ImagesCompressor(image);
    }
    catch(e){
      alert('error while compress the images')
      fileInput = image
    }
    return fileInput;
  }

  _handleDropzoneChange = async (files, type) => {
    if(files.length){
      if(type === 'default'){
        const [file] = files;
        const imageDataUrl = file.preview;
        const isGif = file.type === "image/gif";
        this.setState({
          _rawImage: addImageRawObject(file),
          img_url: imageDataUrl,
          ...!isGif && {
            isEditImageOpen: true,
            imageSourceToEdit: imageDataUrl,
            imageSourceReferer: 'default',
            editImageRatio: DEFAULT_IMAGE_ASPECT_LANDSCAPE
          }
        });
      }
      else if(type === 'app_image'){
        const [file] = files;
        const imageDataUrl = file.preview;
        const obj = addImageRawObject(file)
        const isGif = file.type === "image/gif";
        const { _appImage } = this.state;
        if(isGif){
          obj.orientation = "portrait";
        }
        this.setState({
          _appImage: _appImage? {
            ..._appImage,
            imgUrl: imageDataUrl,
            raw: {...obj}
          }: {
            id: 0,
            imgUrl: imageDataUrl,
            raw: {...obj}
          },
          _rawImages: [obj],
          ...!isGif && {
            isEditImageOpen: true,
            imageSourceToEdits: [{identifier: obj.identifier, imageDataUrl}],
            imageSourceToEdit: imageDataUrl,
            imageSourceReferer: 'app_image',
            editImageRatio: DEFAULT_IMAGE_ASPECT_PORTRAIT
          }
        });
      }
      else if(type === 'multiple'){
        let fileList = [];
        let rawFiles = [];
        for(let i=0;i< files.length;i++){
          fileList.push(files[i])
        }
        for(let i=0;i<fileList.length;i++){
          const file = fileList[i];
          const imageDataUrl = await readFile(file)
          rawFiles.push({
            ...addImageRawObject(file),
            imageDataUrl
          })
        }
        if(rawFiles.length){
          const { _rawImages } = this.state;
          this.setState({
            _rawImages: [..._rawImages, ...rawFiles],
            isEditImageOpen: true,
            imageSourceToEdits: rawFiles.map(({identifier, imageDataUrl}) => ({identifier, imageDataUrl})),
            imageSourceToEdit: rawFiles[0].imageDataUrl,
            imageSourceReferer: 'childs',
            editImageRatio: DEFAULT_IMAGE_ASPECT_PORTRAIT
          });
        }
      }
    }
  }

  _handleDatePickerChange = (date, name) => {
    this.setState({ [name]: date })
  }

  _handleToggleEditImageCallback = () => {
    const { imageSourceToEdits, isEditImageOpen } = this.state;
    if(!isEditImageOpen){
      let slices = []
      if(imageSourceToEdits.length>1){
        slices = imageSourceToEdits.slice(1).map(({identifier, imageDataUrl}) => ({identifier, imageDataUrl}))
      }
      if(slices.length){
        setTimeout(() => {
          this.setState({
            isEditImageOpen: !this.state.isEditImageOpen,
            imageSourceToEdits: [...slices],
            imageSourceToEdit: slices.length? slices[0].imageDataUrl: null
          })
        }, 1500);
      } else{
        this.setState({
          imageSourceToEdits: [],

          imageSourceToEdit: null
        })
      }
    }
  }

  _renderLoading(){
    const { _submitFormStatus, _formSubmit } = this.state;
    if(!_submitFormStatus || !_formSubmit) return null;
    return (
      <Row>
        <Col xs={12}>
          <Alert color="info">
            <strong>Heads up!</strong> {
              _submitFormStatus === SUBMIT_UPLOAD_DEFAULT_IMG_STATUS? 'Uploading your image...':
              (_submitFormStatus === DELETE_SLIDER_FORM_STATUS? 'Deleting your data...': 'Submitting your data...')}
          </Alert>
        </Col>
      </Row>
    )
  }

  _renderError(){
    const { _error } = this.state;
    if(!_error) return null;
    const { status_code, message } = _error
    let messageStr = message;
    switch(status_code){
      case 422: messageStr = 'Please fullfill your form.'; break;
      case 404: messageStr = 'Resource not found.'; break;
      case 500: messageStr = "We've got something errors"; break;
    }
    return (
      <Row>
        <Col xs={12}>
          <Alert color="danger">
            <strong>Oh Snap!</strong> { messageStr }
          </Alert>
        </Col>
      </Row>
    )
  }
  
  _handleModalDeleteToggle = () => {
    this.setState({
      _isModalDeleteOpen: !this.state._isModalDeleteOpen
    })
  }

  _handleDeleteSlider = () =>{
    const { id, _formSubmit, img_url } = this.state;
    if(!_formSubmit){
      this.setState({
        _formSubmit: !_formSubmit,
        _submitFormStatus: DELETE_SLIDER_FORM_STATUS,
        _isModalLoadingOpen: img_url? true: false
      })
      if(img_url){
        const payload = {
          bucket_type : 'assets',
          name : getPathS3(getOriginFromSignedUrl(img_url))
        };
        this.props.deleteImage(payload);
      }
      else
        this.props.delete(id)
    }
    this._handleModalDeleteToggle();
  }

  _handleSelectProduct = (productVariant) => {
    const { _modalProductVariant } = this.state;
    switch(_modalProductVariant.purpose){
      case "GENERATE_REDI_URL":{
        const path = `${productVariant.product.category.name.toLowerCase()}/${productVariant.id}/${productVariant.slug}`;
        const url = this._refixKickavenueUrl(path);
        let dataApp = {};
        if(visibleOnApp(this.state.platform)){
          const data = this._getDataFromUrl(url);
          dataApp = {
            _sliderClickedSelected: data.type,
            _sliderClickedActions: this.state._sliderClickedActions.map(item => {
              if(data.type === item.type) return { ...item, ...data }
              return item
            })
          }
        }
        this.setState({
          redirect_url: url,
          ...dataApp
        });
      }
      case "GENERATE_APP_ACTION":{
        const data = {
          type: "PRODUCT_DETAIL_SCREEN",
          values: {
            payload: productVariant.id,
            body: {
              id: productVariant.id,
              display_name: productVariant.display_name,
              category: productVariant.product.category.name.toLowerCase(),
              SKU: productVariant.SKU
            }
          }
        }
        this.setState({
          _sliderClickedSelected: data.type,
          _sliderClickedActions: this.state._sliderClickedActions.map(item => {
            if(data.type === item.type) return { ...item, ...data }
            return item
          })
        });
      }
      default: break;
    }
    this.setState({
      _modalProductVariant: {
        ..._modalProductVariant,
        isOpen: !_modalProductVariant.isOpen
      }
    })
  }

  _handleSelectCollection = (collection) => {
    const { _modalProductCollection } = this.state;
    const { category_id } = this.state;
    const { categoryOptions } = this.props;
    const defaultCategory = categoryOptions.find(c => c.value == category_id)?.label?? "sneakers";
    let category = collection?.categories?.find((item) => item?.pivot?.active);
    category = category? category?.name?.toLowerCase() : defaultCategory;
    
    switch(_modalProductCollection.purpose){
      case "GENERATE_REDI_URL":{
        const path = `${category}/search/${collection.slug}`;
        const url = this._refixKickavenueUrl(path);
        let dataApp = {};
        if(visibleOnApp(this.state.platform)){
          const data = {
            type: "COLLECTION_SCREEN",
            values: {
              payload: collection.id,
              body: {
                id: collection.id,
                name: collection.name,
                slug: collection.slug,
                category
              }
            }
          };
          dataApp = {
            _sliderClickedSelected: data.type,
            _sliderClickedActions: this.state._sliderClickedActions.map(item => {
              if(data.type === item.type) return { ...item, ...data }
              return item
            })
          }
        }
        this.setState({
          redirect_url: url,
          ...dataApp
        });
      }
      case "GENERATE_APP_ACTION":{
        const data = {
          type: "COLLECTION_SCREEN",
          values: {
            payload: collection.id,
            body: {
              id: collection.id,
              name: collection.name,
              slug: collection.slug,
              category
            }
          }
        };
        this.setState({
          _sliderClickedSelected: data.type,
          _sliderClickedActions: this.state._sliderClickedActions.map(item => {
            if(data.type === item.type) return { ...item, ...data }
            return item
          })
        });
      }
      default: break;
    }
    this.setState({
      _modalProductCollection: {
        ..._modalProductCollection,
        isOpen: !_modalProductCollection.isOpen
      }
    })
  }

  _handleSelectRaffle = raffle => {
    const { _modalRaffle } = this.state
    let category = 'sneakers'
    const raffleSlug = raffle.group_name.toLowerCase()
      .replace(/ /g, '+')
      .replace(/[^\w-]:/g, '')
    switch(_modalRaffle.purpose){
      case "GENERATE_REDI_URL":{
        const path = `${category}/raffle/${raffleSlug}`;
        const url = this._refixKickavenueUrl(path);
        let dataApp = {};
        if(visibleOnApp(this.state.platform)){
          const data = this._getDataFromUrl(url);
          dataApp = {
            _sliderClickedSelected: data.type,
            _sliderClickedActions: this.state._sliderClickedActions.map(item => {
              if(data.type === item.type) return { ...item, ...data }
              return item
            })
          }
        }
        this.setState({
          redirect_url: url,
          ...dataApp
        });
      }
      case "GENERATE_APP_ACTION":{
        const data = {
          type: "RAFFLE_DETAIL_SCREEN",
          slug: raffleSlug,
          values: {
            payload: raffle.id,
            body: {
              id: raffle.id,
              group: raffle.group_name,
              name: raffle.name,
              colour: raffle.raffle_group ? raffle.raffle_group.color : null,
              slug: raffleSlug
            },
          }
        };
        this.setState({
          _sliderClickedSelected: data.type,
          _sliderClickedActions: this.state._sliderClickedActions.map(item => {
            if(data.type === item.type) return { ...item, ...data }
            return item
          })
        });
      }
      default: break;
    }
    this.setState({
      _modalRaffle: {
        ..._modalRaffle,
        isOpen: !_modalRaffle.isOpen
      }
    })
  }  

  _handleOpenProductModal = purpose => {
    this.setState({
      _modalProductVariant:{
        isOpen: true,
        purpose
      }
    })
  }

  _handleOpenProductCollectionModal = purpose => {
    this.setState({
      _modalProductCollection:{
        isOpen: true,
        purpose
      }
    })
  }

  _handleOpenRaffleModal = purpose => {
    this.setState({
      _modalRaffle:{
        isOpen: true,
        purpose
      }
    })
  }

  render(){
    const { isFetch: isLoading } = this.props.slider;
    return(
      <div className="animated fadeIn">
        <ModalLoading isOpen={this.state._isModalLoadingOpen}/>
        <ModalDelete
          isOpen={this.state._isModalDeleteOpen}
          modalTitle="Confirmation."
          modalBody="Are you sure to delete this?"
          onDelete={this._handleDeleteSlider}
          toggle={this._handleModalDeleteToggle}
        />
        <ModalEditImage
          isOpen={this.state.isEditImageOpen}
          imageSource={this.state.imageSourceToEdit}
          showGrid
          ratio={this.state.editImageRatio}
          onCropSucceeded={async(blob, ratio) => {
            const blobUrl = URL.createObjectURL(blob);
            let fileName = blobUrl.substr(blobUrl.lastIndexOf('/')+1)
            if(fileName == '') fileName = 'local'
            const { imageSourceReferer } = this.state;
            
            if(imageSourceReferer === 'default'){
              this.setState({
                _rawImage:{
                  ...this.state._rawImage,
                  fileInput: new File([blob], `${fileName}.jpeg`, {
                    type: 'image/jpeg'
                  })
                },
                isEditImageOpen: !this.state.isEditImageOpen,
                img_url: blobUrl,
                imageSourceToEdit: null
              })
            } else{
              const { _rawImages, imageSourceToEdits, _appImage } = this.state;
              let identifier = 0;
              if(imageSourceToEdits.length)
                identifier = imageSourceToEdits[0].identifier;
              const newRawImages = _rawImages.map(item => {
                if(item.identifier === identifier){
                  return {
                    ...item,
                    fileInput: new File([blob], `${fileName}.jpeg`, {
                      type: 'image/jpeg'
                    }),
                    imageDataUrl: blobUrl,
                    orientation: ratio.ratio <= 1 || imageSourceReferer === 'app_image' ? 'portrait': 'landscape'
                  }
                }
                return item;
              });
              const isAppImage = imageSourceReferer === 'app_image' && _appImage && _appImage.raw;
              this.setState({
                _appImage: isAppImage? {
                  ..._appImage,
                  imgUrl: blobUrl,
                  raw:{
                    ..._appImage.raw,
                    ...newRawImages[0]
                  }
                }: _appImage,
                _rawImages: [...newRawImages],
                isEditImageOpen: !this.state.isEditImageOpen
              }, () => this._handleToggleEditImageCallback())
            }
          }}
        />
        <ModalProductVariants
          isOpen={this.state._modalProductVariant.isOpen}
          toggle={() => {
            this.setState({
              _modalProductVariant: {
                ...this.state._modalProductVariant,
                isOpen: !this.state._modalProductVariant.isOpen
              }
            })
          }}
          size="md"
          dataCategories={this.props.categoryOptions}
          categoryId={this.state.category_id}
          onSelectedProduct={this._handleSelectProduct}
        />
        <ModalCollections
          isOpen={this.state._modalProductCollection.isOpen}
          toggle={() => {
            this.setState({
              _modalProductCollection: {
                ...this.state._modalProductCollection,
                isOpen: !this.state._modalProductCollection.isOpen
              }
            })
          }}
          size="md"
          dataCategories={this.props.categoryOptions}
          categoryId={this.state.category_id}
          onSelectedItem={this._handleSelectCollection}
        />
        <ModalRaffles
          isOpen={this.state._modalRaffle.isOpen}
          toggle={() => {
            this.setState({
              _modalRaffle: {
                ...this.state._modalRaffle,
                isOpen: !this.state._modalRaffle.isOpen
              }
            })
          }}
          size="md"
          dataCategories={this.props.categoryOptions}
          categoryId={this.state.category_id}
          onSelectedItem={this._handleSelectRaffle}
        />
        <Row>
          <Col xs={12} md={6}>
            <Card>
              <CardHeader>
                <FontAwesomeIcon iconType="align-justify"/> {this.props.title}
                {this.props.edit?
                <div>
                  <Link style={{fontSize: '.65rem'}} to="/sliders/create">Create new Slider</Link>
                </div>
              :null}
              </CardHeader>
              <CardLoadingPlaceholder
                isVisible={isLoading}
                loadingText="Loading..."
              />
              <CardBody>
                { this._renderLoading() }
                { this._renderError() }
                <Row>
                  <Col xs={12}>
                    <Alert
                      color="success"
                      isOpen={this.state._isSuccessUploadDefaultImgAlertOpen}
                      toggle={()=> this.setState({_isSuccessUploadDefaultImgAlertOpen: !this.state._isSuccessUploadDefaultImgAlertOpen})}
                    >
                      <strong>Done!</strong> Upload image successfully.
                    </Alert>
                  </Col>
                  <Col xs={12}>
                    <Alert
                      color="success"
                      isOpen={this.state._isSuccessSubmitAlertOpen}
                      toggle={()=> this.setState({_isSuccessSubmitAlertOpen: !this.state._isSuccessSubmitAlertOpen})}
                    >
                      <strong>Done!</strong> Submit data successfully.
                    </Alert>
                  </Col>
                </Row>
                <Form onSubmit={this._handleSubmit}>
                  <Row>
                    <Col xs={12}>
                      <FormGroup className={classNames({ 'has-danger has-feedback': this._hasError('name') })}>
                        <Label>Slider Name*</Label>
                        <Input
                          placeholder="Enter a name*"
                          name="name"
                          type="text"
                          value={textValue('name', this.state)}
                          onChange={this._handleInputChange}
                        />
                        { this._renderErrorMessage('name') }
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={6}>
                      <FormGroup className={classNames({ 'has-danger has-feedback': this._hasError('category_id') })}>
                        <Label>Category*</Label>
                        <Select
                          name="category_id"
                          value={textValue('category_id', this.state)}
                          options={this.props.categoryOptions.length?
                            this.props.categoryOptions.map(cat => ({ ...cat, label: cat.label.toLowerCase() }))
                            :[{value:'',label:'Loading...'}]}
                          onChange={val => this._handleSelectChange('category_id', val)}
                        />
                        { this._renderErrorMessage('category_id') }
                      </FormGroup>
                    </Col>
                    <Col xs={12} md={6}>
                      <FormGroup className={classNames({ 'has-danger has-feedback': this._hasError('platform') })}>
                        <Label>Visible Only</Label>
                        <Select
                          name="platform"
                          value={textValue('platform', this.state)}
                          options={sliderPlatformOptions}
                          onChange={val => this._handleSelectChange('platform', val)}
                        />
                        { this._renderErrorMessage('platform') }
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row style={{display: 'flex', alignItems: 'flex-end'}}>
                    <Col xs={12} md={6}>
                      <FormGroup className={classNames({ 'has-danger has-feedback': this._hasError('type') })}>
                        <Label>Slider Type*</Label>
                        <Select
                          name="type"
                          value={textValue('type', this.state)}
                          options={sliderTypeOptions}
                          onChange={val => this._handleSelectChange('type', val)}
                        />
                        { this._renderErrorMessage('type') }
                      </FormGroup>
                    </Col>
                    <Col xs={12} md={6}>
                      <FormGroupRadio className={classNames({ 'has-danger has-feedback': this._hasError('active') })}>
                        <Radio
                          type="checkbox"
                          name="active"
                          value={true}
                          checked={textValue('active', this.state)}
                          dataOnText="Yes"
                          dataOffText="No"
                          onChange={this._handleInputChange}
                        />
                        <RadioText text="Set to Active"/>
                        { this._renderErrorMessage('active') }
                      </FormGroupRadio>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12}>
                      <FormGroup className={classNames({ 'has-danger has-feedback': this._hasError('tags') })}>
                        <Label>Tags</Label>
                        <Creatable
                          multi
                          options={this.state._defaultTags}
                          value={textValue('tags', this.state)}
                          onChange={this._handleOnChange}
                          placeholder="Enter a nickname or tags"
                        />
                        { this._renderErrorMessage('tags') }
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12}>
                      <FormGroup
                        className={classNames({ 'has-danger has-feedback': this._hasError('img_url') })}
                      >
                        <Label>Default Image (Landscape ver.)</Label>
                        <div className="d-flex">
                          <ImageFile
                            style={{padding: 0}}
                            alt="Slider's default image"
                            src={this.state.img_url? this.state.img_url : 'https://via.placeholder.com/300x150?text=1280x400'}
                            showDelete={this.state._rawImage !== null}
                            onDelete={() => {
                              this.setState({
                                img_url: this.state.oldImageUrl,
                                _rawImage: null
                              })
                            }}
                          />
                          <div className="ml-3">
                            <DropzonePlusButton accept={[".jpg", ".jpeg", ".png", ".gif"]} multiple={false} onDrop={files => this._handleDropzoneChange(files, 'default')}/>
                            <ul className="image-guides" style={{fontSize: '.75rem', marginTop: '.75rem', paddingInlineStart: '1rem'}}>
                              <li className="d-none">You can click the image for preview the image.</li>
                              <li>It will be display on Web only.</li>
                              <li>Use 1280x400 (16:5),  1280x720 (16:9), 1280x465,45 (11:4) or 1280x90 (128:9) resolution of your image as our recommended. (In pixels).</li>
                              <li>Or any resolution that you want, but make sure its <strong>landscape</strong> orientation.</li>
                              <li className="d-none">If you not provide/upload any images in “Images Form”. By default, this image will be display on our platforms (Web or App).</li>
                              <li className="d-none">By changing the image, click Submit button for save your changes.</li>
                            </ul>
                          </div>
                        </div>
                        { this._renderErrorMessage('img_url') }
                      </FormGroup>
                    </Col>
                    <Col xs={12}>
                      <FormGroup className={classNames({ 'has-danger has-feedback': this._hasError('redirect_url') })}>
                        <Label>Redirect Url</Label>
                        <div className="mb-2">
                          <ButtonDropdown
                            size="sm"
                            isOpen={this.state.dropdownGenerateLinkOpen}
                            toggle={() => this.setState({dropdownGenerateLinkOpen: !this.state.dropdownGenerateLinkOpen})}
                          >
                            <DropdownToggle color="secondary" caret>
                              Generate link...
                            </DropdownToggle>
                            <DropdownMenu>
                              <DropdownItem onClick={() => this._handleOpenProductCollectionModal("GENERATE_REDI_URL")}>
                                From Collections
                              </DropdownItem>
                              <DropdownItem onClick={() => this._handleOpenProductModal("GENERATE_REDI_URL")}>
                                From Products
                              </DropdownItem>
                              <DropdownItem onClick={() => this._handleOpenRaffleModal("GENERATE_REDI_URL")}>
                                From Raffles
                              </DropdownItem>
                            </DropdownMenu>
                          </ButtonDropdown>
                        </div>
                        <Input
                          name="redirect_url"
                          placeholder="Example: https://www.kickavenue.com/sneakers/search/yeezy"
                          type="text"
                          value={textValue('redirect_url', this.state)}
                          onChange={this._handleInputChange}
                        />
                        { this._renderErrorMessage('redirect_url') }
                      </FormGroup>
                    </Col>
                  </Row>
                  <Collapse isOpen={visibleOnApp(this.state.platform)}>
                    <Row>
                      <Col xs={12}>
                        <FormGroup
                          className={classNames({ 'has-danger has-feedback': this._hasError('app_image') })}
                        >
                          <Label>App Image (Portrait ver.)</Label>
                          <div className="d-flex">
                            <ImageFile
                              style={{padding: 0}}
                              alt="Slider's app image"
                              src={this.state._appImage? this.state._appImage.imgUrl : 'https://via.placeholder.com/300?text=700x700'}
                              showDelete={this.state._rawImages.length > 0}
                              onDelete={() => {
                                const { _appImage } = this.state;
                                this.setState({
                                  _appImage: _appImage.id > 0?{
                                    ..._appImage,
                                    imgUrl: _appImage.URL
                                  }: null,
                                  _rawImages: []
                                })
                              }}
                            />
                            <div className="ml-3">
                              <DropzonePlusButton accept={[".jpg", ".jpeg", ".png", ".gif"]} multiple={false} onDrop={files => this._handleDropzoneChange(files, 'app_image')}/>
                              <ul className="image-guides" style={{fontSize: '.75rem', marginTop: '.75rem', paddingInlineStart: '1rem'}}>
                                <li className="d-none">You can click the image for preview the image.</li>
                                <li>It will be display on App only.</li>
                                <li>Use 700x700 (1:1),  700x933,33 (3:4) or  700x1050 (4:6) resolution of your image as our recommended for our app display. (In pixels). Or any resolution that you want, but make sure its <strong>portrait</strong> orientation.</li>
                                <li>If you not provide/upload an image. By default, <strong>Default image</strong> will be display on our platforms (Web or App).</li>
                              </ul>
                            </div>
                          </div>
                          { this._renderErrorMessage('app_image') }
                        </FormGroup>
                      </Col>
                      <Col xs={12}>
                        <FormGroup className={classNames({ 'has-danger has-feedback': this._hasError('data') })}>
                          <Label id="actionForAppLabel">
                            <span>Action for App (BETA)</span>
                            <SimpleLineIcon iconType="question" className="ml-2"/>
                          </Label>
                          <UncontrolledTooltip innerClassName="text-left" placement="right" target="actionForAppLabel">
                            Add action/behavior when user clicked the slider through our app.
                          </UncontrolledTooltip>
                          <div className="mb-2">
                            <ButtonDropdown size="sm" isOpen={this.state.dropdownOpen} toggle={() => this.setState({dropdownOpen: !this.state.dropdownOpen})}>
                              <DropdownToggle caret>
                                {this._renderSelectedActionLabel()}
                              </DropdownToggle>
                              <DropdownMenu>
                                {this.state._sliderClickedActions.map((item, key) => (
                                  <DropdownItem onClick={() => this.setState({_sliderClickedSelected: item.type})} key={key}>{item.label}</DropdownItem>
                                ))}
                              </DropdownMenu>
                            </ButtonDropdown>
                          </div>
                          { this._renderContentData() }
                          { this._renderErrorMessage('data') }
                        </FormGroup>
                      </Col>
                    </Row>
                  </Collapse>
                  <Row>
                    <Col xs={12} md={6}>
                      <FormGroup className={classNames({ 'has-danger has-feedback': this._hasError('started_at') })}>
                        <Label>Started At</Label>
                        <InputDatetime
                          dateFormat={'DD/MM/YYYY'}
                          inputProps={{placeholder:'Pick a Start Date', name: "started_at", autoComplete: "off"}}
                          value={textValue('started_at', this.state)}
                          onChange={ date => this._handleDatePickerChange(date, 'started_at') }
                          timeFormat={false}
                        />
                        { this._renderErrorMessage('started_at') }
                      </FormGroup>
                    </Col>
                    <Col xs={12} md={6}>
                      <FormGroup className={classNames({ 'has-danger has-feedback': this._hasError('ended_at') })}>
                        <Label>Ended At</Label>
                        <InputDatetime
                          dateFormat={'DD/MM/YYYY'}
                          inputProps={{placeholder:'Pick a End Date', name: "ended_at", autoComplete: "off"}}
                          value={textValue('ended_at', this.state)}
                          onChange={ date => this._handleDatePickerChange(date, 'ended_at') }
                          timeFormat={false}
                        />
                        { this._renderErrorMessage('ended_at') }
                      </FormGroup>
                    </Col>
                  </Row>
                  {/* <Row>
                    <Col xs={12} md={6}>
                      <FormGroup className={classNames({ 'has-danger has-feedback': this._hasError('order') })}>
                        <Label>Slider Position</Label>
                        <Select
                          name="order"
                          value={textValue('order', this.state)}
                          options={sortOptions}
                          onChange={val => this._handleSelectChange('order', val)}
                        />
                        { this._renderErrorMessage('order') }
                      </FormGroup>
                    </Col>
                  </Row> */}
                  <Row>
                    <Col xs={12} md={6}>
                      <FormGroup className={classNames({ 'has-danger has-feedback': this._hasError('order') })}>
                        <Label>Slider Position</Label>
                        <Input
                          type="number"
                          id="formOrder"
                          name="order"
                          placeholder="Enter a slider position*"
                          value={textValue('order',this.state)}
                          onChange={this._handleInputChange}
                        />
                        { this._renderErrorMessage('order') }
                      </FormGroup>
                    </Col>
                  </Row>
                  {
                    this.state.id > 0 ?(
                      <Row>
                        <Col xs={12} md={6}>
                          <FormGroup>
                            <Label>Created</Label>
                            <Input
                              readOnly
                              name="created_at"
                              type="text"
                              value={textValue('created_at', this.state)}
                            />
                          </FormGroup>
                        </Col>
                        <Col xs={12} md={6}>
                          <FormGroup>
                            <Label>Updated</Label>
                            <Input
                              readOnly
                              name="updated_at"
                              type="text"
                              value={textValue('updated_at', this.state)}
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                    ):null
                  }
                  <p style={{fontSize: '.75rem', fontStyle: 'italic'}}>(*) this field is required.</p>
                  <FormGroup row>
                    <Col>
                      <ButtonGroup>
                        <ButtonLoading
                          disabled={this.state._formSubmit}
                          isLoading={this.state._formSubmit && this.state._submitFormStatus === SUBMIT_SLIDER_FORM_STATUS}
                          loadingMessage="Submitting..."
                          color="primary"
                        >
                            Submit
                        </ButtonLoading>
                        {
                          this.state.id > 0?(
                              <ButtonLoading
                              disabled={this.state._formSubmit}
                              isLoading={this.state._formSubmit && this.state._submitFormStatus === DELETE_SLIDER_FORM_STATUS}
                              loadingMessage="Deleting..."
                              onClick={this._handleModalDeleteToggle}
                              color="danger"
                            >
                                Delete
                            </ButtonLoading>
                          )
                          :null
                        }
                      </ButtonGroup>
                    </Col>
                  </FormGroup>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    )
  }
}

const mapStateToProps= (state) => ({
  slider:state.slider,
  categoryOptions:state.masterCategories.options,
  productVariant: {
    isFetch: state.productVariant.isFetch,
    detail:state.productVariant.detail,
    error: state.productVariant.error
  },
  dataUploaded: state.imageUpload
});

const mapDispatchToProps = (dispatch) => ({
  save:(payload)=>dispatch(saveSlider(payload)),
  new:()=>dispatch(newSlider()),
  find:(id)=>dispatch(getDetailSlider(id)),
  delete:(id,img_url)=>dispatch(deleteSlider(id,img_url)),
  upload: (file, folder, oldFileName)=>dispatch(upload(file,folder, oldFileName)),
  bindCategoryOption:()=>dispatch(getCategoryOptionList()),
  storeSliderImages: (id, payloads) => dispatch(storeSliderImages(id, payloads)),
  getDetailProductVariant: id => dispatch(getDetailProductVariant(id)),
  uploadImage: (payload, rawImages) => dispatch(uploadImage(payload, rawImages)),
  deleteImage: (payload) => dispatch(deleteImage(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SliderForm);