import React from 'react';
import propTypes from 'prop-types';

import SelectUserAsync from '../../../components/Form/Select/Async/SelectUserV2';

class SellerFilterForm extends React.Component{
  constructor(props){
    super(props);
  }

  render(){
    return(
      <SelectUserAsync
        onChange={this.props.onSellerChange}
        value={this.props.seller}
      />
    )
  }
}

export default SellerFilterForm;
SellerFilterForm.defaultProps = {
}

SellerFilterForm.propTypes = {
  seller: propTypes.object,
  onSellerChange: propTypes.func.isRequired
}