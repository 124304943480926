import React from 'react';
import classNames from 'classnames';
import styled from 'styled-components';
import FontAwesomeIcon from '../../../components/Icons/FontAwesomeIcon';

const Button = styled.button`
  font-size: ${props => props.size === 'sm'?'.65rem':  props.size === 'lg'? '1rem': 'inherit'};
  color: #ddd;
  background: none;
  border: none;
  padding: 1px 3px;
  border-radius: 0px;
  box-shadow: none;
  &:hover{
    color: #4a4a4a;
    > .fa-star::before{
      content: "\f006"
    }
    > .fa-star-o::before{
      content: "\f005"
    }
  }
  &.active{
    color: #f86c6b;
  }
`;

const ButtonStarred = ({ className, onClick, value = false, style= {}, size= "lg", ...props }) => (
  <Button  size={size} style={{...style}} onClick={onClick} className={classNames(className, {"active": value})} {...props}>
    <FontAwesomeIcon iconType="exclamation-triangle"/>
  </Button>
)

export default ButtonStarred;