import React, { useState } from 'react';
import classNames from 'classnames';
import { Collapse, Button, ListGroup, ListGroupItem, ListGroupItemText, ButtonGroup } from 'reactstrap';

import { DropzonePlusButton } from '../../../components/Form/DropzoneFile';
import { Radio } from '../../../components/Form/Radios';
import { ImageFrame, ImageFileListContainer, ImageFile } from '../../../components/Images';

import { ImageCentered } from '../../../components/Images';
import { SimpleLineIcon } from '../../../components/Icons'
import AlertNotFound from '../../../components/Card/AlertNotFound';
import { ButtonXs } from '../../../components/Button';

const getPublicImageUrl = image => {
  return image.signed_url || image.URL
}

export const ImageList = props => {
  const { isLoading } = props
  return (
    <ListGroup flush>
    {
      props.images.length===0?
      <AlertNotFound message="No Images for this category."/>: null
    }
    {
      props.images.map((item, key) => (
        <ListGroupItem key={key} style={{display: 'flex', justifyContent: 'space-between', paddingLeft: 0, paddingRight: 0}}>
          <div style={{display: 'flex'}}>
            <ImageCentered size={80} src={getPublicImageUrl(item)} style={{marginRight: '.5rem'}}/>
            <ListGroupItemText tag="div" style={{margin: 0, flex: 1}}>
              {
                isLoading?(
                  <div className="mb-2">...</div>
                ): (
                  <ButtonGroup className="mb-2">
                    <ButtonXs
                      color="gray"
                      disabled={item.orientation==='landscape'}
                      className={classNames({"active": item.orientation==='landscape'})}
                      onClick={() => props.onSaveImage({...item, orientation: 'landscape'})}
                    >
                      <SimpleLineIcon iconType="screen-desktop"/> landscape
                    </ButtonXs>
                    <ButtonXs
                      color="gray"
                      disabled={item.orientation==='portrait'}
                      className={classNames({"active": item.orientation==='portrait'})}
                      onClick={() => props.onSaveImage({...item, orientation: 'portrait'})}
                    >
                      <SimpleLineIcon iconType="screen-tablet"/> portrait
                    </ButtonXs>
                  </ButtonGroup>
                )
              }
              {/* orientation: <strong>{item.orientation}</strong> */}
              <div>
                created: {item.created_at}
              </div>
            </ListGroupItemText>
          </div>
          {
            isLoading?(
              <div style={{alignSelf: 'center'}}>
                <span>...</span>
              </div>
            ):(
          <div style={{alignSelf: 'center'}}>
            <a href={getPublicImageUrl(item)} target="_blank" title="Open with new tab">
              <SimpleLineIcon iconType="share-alt"/> Open
            </a>
            {' '}
            <Button
              color="link"
              onClick={() => props.onSaveImage({id: item.id, deleted: true})}
              className="text-danger"
              title="Delete image"
            >
              <SimpleLineIcon iconType="trash"/>
            </Button>
          </div>
            )
          }
        </ListGroupItem>
      ))
    }
  </ListGroup>
  )
}

ImageList.defaultProps = {
  images: []
}

export const CategoryForm = props => {
  const [isOpen, toggle] = useState(!props.create)
  return (
    <div style={{marginBottom: '.75rem'}}>
      <div className="d-flex" style={{justifyContent: 'space-between', borderBottom: '1px solid #ddd'}}>
        <p className="text-uppercase">
          <strong>{props.category.name}:</strong>
        </p>
        <div>
          {!props.create?
            <span style={{fontSize: '.65rem'}} className="ml-3">images: {props.images.length}</span>
          :null}
          {
            !props.create?(
              <Radio
                wrapperClassName="switch-success-outline-alt mb-0 ml-3"
                type="checkbox"
                value={true}
                checked={props.category.active}
                dataOnText="Yes"
                dataOffText="NO"
                onChange={props.onActiveChange}
              />
            )
            :null
          }
          {props.imageFiles.length>0?
            <ButtonXs color="danger" outline className="ml-3" onClick={props.onClearImageFiles}>
              clear all
            </ButtonXs>
          :null}
          {!props.create?(
            <ButtonXs color="gray" outline className="ml-3" onClick={() => toggle(!isOpen)}>{isOpen? "hide": "open"}</ButtonXs>
          ):null}
        </div>
      </div>
      <ImageFileListContainer>
        <ImageFrame>
          <DropzonePlusButton onDrop={props.onDropFiles}/>
        </ImageFrame>
        {props.imageFiles.map((img, key)=>(
          <ImageFile
            showDelete
            key={key}
            size={60}
            src={img.URL}
            onDelete={() => props.onDeleteFile(key)}
          />
        ))}
      </ImageFileListContainer>
      <Collapse isOpen={isOpen}>
        <div style={{overflow: "auto", maxHeight: 350}}>
          <ImageList
            images={props.images}
            isLoading={props.isLoading}
            onSaveImage={props.onSaveImage}
          />
        </div>
      </Collapse>
    </div>
  )
}

