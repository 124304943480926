import React from 'react';
import {
  Alert,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Row,
  Col
} from 'reactstrap';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import InputDatetime from '../../../components/Form/InputDatetime/InputDatetime';
import { hasError, errorMessage, textChange, textValue, checkChange } from '../../../utils/form';
import { sneakersConditionOption } from '../../../utils/userSell';
import { isNumeric } from '../../../utils/regex';
moment.tz('Asia/Jakarta');
export class ModalSubsidy extends React.Component{
  constructor(props){
    super(props);
    this.state={
      id:null,
      price:null,
      type:'fixed',
      max_amount: null,
      sneakers_condition:'BARU',
      started_at:null,
      ended_at:null,
      sneakersConditionOpt: [ { value: 'PRE_ORDER', label: 'Pre-Order' }, ...sneakersConditionOption],
      typeOpt:[{value:'fixed', label:'Fixed'}, {value:'percentage', label:'Percentage'}],
      isSubmit:false,
      isAletError:false,
      error:null
    }
    this.handleCheckedChange=this.handleCheckedChange.bind(this);
    this.handleChange=this.handleChange.bind(this);
    this.onSubmit=this.onSubmit.bind(this);
    this.onDismiss = this.onDismiss.bind(this);
  }

  componentDidUpdate(preProps){
    const { dataSubsidy, subsidySelected } = this.props;
    if(this.props.isOpen===true&&preProps.isOpen!==this.props.isOpen){
      if(subsidySelected){
        this.setState({
          id:subsidySelected.id,
          price: parseInt(subsidySelected.price),
          type: subsidySelected.type,
          max_amount: subsidySelected.max_amount ? parseInt(subsidySelected.max_amount): subsidySelected.max_amount,
          sneakers_condition: subsidySelected.pre_order && subsidySelected.sneakers_condition === 'BARU'? 'PRE_ORDER': subsidySelected.sneakers_condition,
          started_at: moment(subsidySelected.started_at).isValid()? moment(subsidySelected.started_at): subsidySelected.started_at,
          ended_at: moment(subsidySelected.ended_at).isValid()? moment(subsidySelected.ended_at): subsidySelected.ended_at,
          error: null
        });
      }
    }
    else if(this.props.isOpen===false&&preProps.isOpen!==this.props.isOpen) {
      this.setState({
        id:null,
        price:null,
        type:'fixed',
        max_amount: null,
        sneakers_condition:'BARU',
        started_at:null,
        ended_at:null,
        // sneakersConditionOpt:sneakersConditionOption,
        isSubmit:false,
        isAletError:false,
        error:null
      },()=>this.props.onRemoveSelected());
    }
    if(dataSubsidy.isSubmit!==preProps.dataSubsidy.isSubmit
      &&dataSubsidy.isSubmit===false){
        const { error, isDelete } = dataSubsidy;
        if(error)
          this.setState({
            error:{...dataSubsidy.error},
            isSubmit:false, isAlertError:true},
          );
        else if(!isDelete){
          this.setState({
            isSubmit:false,
            started_at:null,
            ended_at:null,
          },()=>{this.props.onCancel()});
          this.props.onGetSubsidyList();
        }
    }
  }

  handleChange(e){
    this.setState(textChange(e,{...this.state}));
  }

  handleDatePickerChange(date, name){
    this.setState({[name]: date})
  }

  handleCheckedChange(e){
    const condition=e.target.value?'BARU':this.state.sneakers_condition;
    const _newState=checkChange(e,this.state);
    this.setState({..._newState, sneakers_condition:condition});
  }

  onDismiss() {
    this.setState({
      isAlertError: false,
      error: null
    }, ()=>{this.props.onRemoveSelected()});
  }

  _validateForm(){
    let errors={};
    if(!this.state.price)
      errors['price']=['The price field is required..'];
    else if(!isNumeric(this.state.price))
      errors['price']=['The price must be numeric..'];
    else{
      const { type, price } = this.state;
      const priceInt = parseInt(price)
      if(type === 'percentage' && priceInt > 100){
        errors['price']=['The price may not be greater than 100..'];
      }
    }
    if(this.state.max_amount && !isNumeric(this.state.max_amount))
      errors['max_amount']=['The max amount must be numeric..'];
    if(!this.state.sneakers_condition)
      errors['sneakers_condition']=['The condition field is required..'];
    if(this.state.sneakers_condition!=='BARU'&&this.state.pre_order)
      errors['sneakers_condition']=['Pick a brand new condition when pre-order true..'];
    if(this.state.ended_at && !moment.isMoment(this.state.ended_at) && this.state.ended_at !== '')
      errors['ended_at'] = ['Invalid date. format: DD/MM/YYYY HH:mm.'];
    if(this.state.started_at && !moment.isMoment(this.state.started_at) && this.state.started_at !== '')
      errors['started_at'] = ['Invalid date. format: DD/MM/YYYY HH:mm.'];
    if(Object.keys(errors).length){
      this.setState({
        error:{errors:{...errors}, status_code:422,message:'Please fulfill your form *'}
      })
    }
    return errors;
  }

  onSubmit(e){
    e.preventDefault();
    const valid= this._validateForm();
    if(Object.keys(valid).length===0){
      let payload = {
        variant_id:this.props.variantId,
        pre_order: this.state.sneakers_condition === 'PRE_ORDER',
        type: this.state.type,
        max_amount: this.state.max_amount,
        price:this.state.price,
        started_at: moment.isMoment(this.state.started_at)? this.state.started_at.format("YYYY-MM-DD HH:mm:00"): null,
        ended_at: moment.isMoment(this.state.ended_at)? this.state.ended_at.format("YYYY-MM-DD HH:mm:00"): null,
        sneakers_condition: this.state.sneakers_condition === 'PRE_ORDER'? 'BARU': this.state.sneakers_condition,
      }
      this.setState({
        isSubmit:true,
        error:null,
      },()=>{
        this.props.onSubmit(payload,this.state.id);
      });
    }
  }

  _renderError(){
    const { error, isAlertError } = this.state;
    if(error!==null){
      switch(error.status_code){
        case 422:
          return <Alert color="danger" isOpen={isAlertError} toggle={this.onDismiss}>Please fulfill your form</Alert>
        default:
          return <Alert color="danger" isOpen={isAlertError} toggle={this.onDismiss}>Houston, we have a problem!. Please inform our friendly staff via LINE @kickavenue</Alert>
      }
    }
    return null;
  }

  render(){
    return(
      <Modal isOpen={this.props.isOpen} toggle={this.props.onCancel} className="modal-dialog modal-md">
        <Form onSubmit={this.onSubmit}>
          <ModalHeader toggle={this.props.onCancel}>
            {this.state.id === null? 'Add Subsidy': `Edit Subsidy - ID: ${this.state.id}`}
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col xs={12} md={6}>
                <FormGroup
                  className={classnames({
                    "has-danger has-feedback": hasError(
                      "sneakers_condition",
                      this.state.error
                    )
                  })}
                >
                  <Label for="formSneakerCondition">
                    Condition
                  </Label>
                  <Input
                    type="select"
                    name="sneakers_condition"
                    id="formSneakerCondition"
                    value={textValue("sneakers_condition", this.state)}
                    placeholder="condition..."
                    onChange={this.handleChange}
                  >
                    {this.state.sneakersConditionOpt.map((item,index)=> <option value={item.value} key={index}>{item.label}</option>)}
                  </Input>
                  {errorMessage("sneakers_condition", this.state.error)}
                </FormGroup>
              </Col>
              <Col xs={12} md={6}>
                <FormGroup
                  className={classnames({
                    "has-danger has-feedback": hasError(
                      "price",
                      this.state.error
                    )
                  })}
                >
                <Label for="formSubsidyPrice">
                  Subsidy Price ({this.state.type === 'fixed'? 'Rp': '%'})
                </Label>
                <Input
                  type="text"
                  id="formSubsidyPrice"
                  name="price"
                  placeholder="Enter a subsidy price"
                  value={textValue("price", this.state)}
                  onChange={this.handleChange}
                />
                  {errorMessage("price", this.state.error)}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={6}>
                <FormGroup
                  className={classnames({
                    "has-danger has-feedback": hasError(
                      "type",
                      this.state.error
                    )
                  })}
                >
                  <Label for="formType">
                    Type
                  </Label>
                  <Input
                    type="select"
                    name="type"
                    id="formType"
                    value={textValue("type", this.state)}
                    placeholder="type..."
                    onChange={this.handleChange}
                  >
                    {this.state.typeOpt.map((item,index)=> <option value={item.value} key={index}>{item.label}</option>)}
                  </Input>
                  {errorMessage("type", this.state.error)}
                </FormGroup>
              </Col>
              <Col xs={12} md={6}>
                <FormGroup
                    className={classnames({
                      "has-danger has-feedback": hasError(
                        "max_amount",
                        this.state.error
                      )
                    })}
                >
                  <Label for="formMaxAmount">
                    Max Amount
                  </Label>
                  <Input
                    type="text"
                    id="formMaxAmount"
                    name="max_amount"
                    placeholder="Enter a max amount"
                    value={textValue("max_amount", this.state)}
                    onChange={this.handleChange}
                  />
                    {errorMessage("max_amount", this.state.error)}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={6}>
                <FormGroup  className={classnames({'has-danger has-feedback':hasError('started_at',this.state.error)})}>
                  <Label for="formStartedDate">Start Date - optional</Label>
                  <InputDatetime
                    name="started_at"
                    id="formStartedDate"
                    timeFormat="HH:mm"
                    value={textValue('started_at',this.state)}
                    onChange={(date)=>this.handleDatePickerChange(date,'started_at')}
                    inputProps={{placeholder:'Pick a Started At', id:'formStartedDate'}}
                  />
                  {errorMessage('started_at',this.state.error)}
                </FormGroup>
              </Col>
              <Col xs={12} md={6}>
                <FormGroup  className={classnames({'has-danger has-feedback':hasError('ended_at',this.state.error)})}>
                  <Label for="formEndedDate">End Date - optional</Label>
                  <InputDatetime
                    name="ended_at"
                    id="formEndedDate"
                    timeFormat="HH:mm"
                    value={textValue('ended_at',this.state)}
                    onChange={(date)=>this.handleDatePickerChange(date,'ended_at')}
                    inputProps={{placeholder:'Pick a Ended At', id:'formEndedDate'}}
                  />
                  {errorMessage('ended_at',this.state.error)}
                </FormGroup>
              </Col>
            </Row>
          <span>{this._renderError()}</span>
          </ModalBody>
          <ModalFooter>
            <Button type="button" color="secondary" onClick={this.props.onCancel}>Cancel</Button>
            <Button type="submit" color="primary" disabled={this.state.isSubmit} onClick={this.onSubmit}>{this.state.isSubmit?'Submitting...': 'Submit'}</Button>
          </ModalFooter>
        </Form>
      </Modal>
    )
  }
}
ModalSubsidy.propTypes={
  dataSubsidy: PropTypes.object,
  subsidySelected: PropTypes.object,
  onGetSubsidyList: PropTypes.func.isRequired,
  onRemoveSelected: PropTypes.func.isRequired,
  isOpen:PropTypes.bool,
  onSubmit:PropTypes.func,
  onCancel:PropTypes.func
}