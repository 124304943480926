import React from 'react';
import Style from './FollowUp.module.css';

function FollowUpConsignment({}) {
    return (
        <div className={Style['container']}>
            Follow Up
        </div>
    );
}

export default FollowUpConsignment;