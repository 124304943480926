import {
  FETCHING_MIDTRANS,
  SUBMITING_MIDTRANS,
  MIDTRANS_ERROR,
  GET_LIST_MIDTRANS,
  GET_DETAIL_MIDTRANS,
} from '../constants';

import * as actionType from '../actions/midtransAction';

const initialState = {
  list: {
    data: [],
    error: null,
    isFetch: false
  },
  detail: {
    data: {},
    isFetch: false,
    isSubmit: false,
    error: null
  }
}


const onFetched = (lastState, { requestType, bool }) => {
  switch(requestType){
    case actionType.FETCH_LIST_REQ:{
      const { list } = lastState;
      return { ...lastState, list: { ...list, isFetch: bool, error: bool? null: list.error }}
    }
    case actionType.FETCH_DETAILS_REQ:{
      const { detail } = lastState;
      return { ...lastState, detail: { ...detail, isFetch: bool, error: bool? null: detail.error }}
    }
    // case actionType.DELETE_REQ: {
    //   const { detail } = lastState;
    //   return { ...lastState, detail: { ...detail, isSubmit: bool, error: bool? null: detail.error }}
    // }
    default: return { ...lastState }
  }
}

const onError = (lastState, { requestType, error, id }) => {
  switch(requestType){
    case actionType.FETCH_LIST_REQ:{
      const { list } = lastState;
      return { ...lastState, list: { ...list, error: { ...error } }}
    }
    case actionType.FETCH_DETAILS_REQ:{
      const { detail } = lastState;
      return { ...lastState, detail: { ...detail, error: { ...error, id } }}
    }
    // case actionType.DELETE_REQ: {
    //   const { detail } = lastState;
    //   return { ...lastState, detail: { ...detail, error: { ...error, id } }}
    // }
    default: return { ...lastState }
  }

}

const onGetMidtransListSuccess = (lastState, payload) => {
  const { list } = lastState;
  const { data, message, status_code } = payload;
  return { ...lastState, list: { ...list, ...data, message, status_code, error: null }}
}

const onGetMidtransDetailSuccess = (lastState, payload) => {
  const { detail } = lastState;
  const { data, message, status_code } = payload;
  return { ...lastState, detail: { ...detail, data, message, status_code, error: null }}
}

const reducer = (state = initialState, action) => {
  switch(action.type){
    case SUBMITING_MIDTRANS:
    case FETCHING_MIDTRANS: return onFetched(state, action.payload);
    case MIDTRANS_ERROR: return onError(state, action.payload);
    case GET_LIST_MIDTRANS: return onGetMidtransListSuccess(state, action.payload);
    case GET_DETAIL_MIDTRANS: return onGetMidtransDetailSuccess(state, action.payload);
    default: return { ...state };
  }
}

export default reducer;
