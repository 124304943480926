import React from 'react';
import { Button } from 'reactstrap';
import classNames from 'classnames';
import propTypes from 'prop-types';
import { Toolbars as ToolBarContainer } from '../../../../components/Card/CardHeaderWithToolbars';

function Toolbars(props) {
    const {
        isOpen,
        isOpenPrint,
        onToggleFilter,
        onTogglePrint,
        onRefreshList,
        onResetFilter,
        noExport = false,
        collapsible = false,
        isCollapse,
        onToggleCollapse,
        total,
    } = props;
    return (
        <ToolBarContainer>
            <span style={{ marginRight: '0.25rem', fontWeight: 'bold', textTransform: 'uppercase' }}>{total && !isCollapse ? total + ` Items` : null}</span>
            <Button
                type="button"
                title="Refresh"
                color="info"
                size="sm"
                className="with-toolbars__toolbars__btn"
                onClick={onRefreshList}
            >
                <i className="fa fa-refresh"></i>
            </Button>
            <Button
                type="button"
                title="Reset filter"
                color="warning"
                size="sm"
                className="with-toolbars__toolbars__btn"
                onClick={onResetFilter}
            >
                <i className="fa fa-ban"></i>
            </Button>
            <Button
                type="button"
                title="Search and Filter Data"
                size="sm"
                className={classNames('with-toolbars__toolbars__btn', { 'btn-danger': isOpen })}
                onClick={onToggleFilter}
            >
                <i className={classNames('fa', { 'fa-sliders': !isOpen, 'fa-minus': isOpen })}></i>
            </Button>
            {collapsible ? <Button
                type="button"
                title="Expand"
                color="secondary"
                size="sm"
                className={classNames('with-toolbars__toolbars__btn', { 'btn-danger': isCollapse })}
                onClick={onToggleCollapse}
            >
                <i className={classNames({ 'icon-arrow-down': !isCollapse, 'icon-arrow-up': isCollapse })}></i>
            </Button> : noExport ? <div /> : <Button
                type="button"
                color="success"
                title="Print Multiple Order"
                size="sm"
                className={classNames('with-toolbars__toolbars__btn', { 'btn-success': isOpenPrint })}
                onClick={onTogglePrint}
            >
                <i className={classNames('fa', { 'fa-envelope': !isOpenPrint, 'fa-minus': isOpenPrint })}></i>
            </Button>}
        </ToolBarContainer>
    )
}

export default Toolbars;

Toolbars.propTypes = {
    isOpen: propTypes.bool,
    onToggleFilter: propTypes.func,
    onResetFilter: propTypes.func,
    onRefreshList: propTypes.func
}
Toolbars.defaultProps = {
    isOpen: false,
}
