import React from 'react';
import propTypes from 'prop-types';
import { Table, CardBody, CardHeader } from 'reactstrap';
import Loading from 'react-loading-animation';

import { TextRupiah } from '../../../../../components/Text';
import { _getFloatValue } from '../../../../../utils/form';

const RaffleRequestSummaryCard = props => {
  const { paymentMethod } = props;
  const kickCredit = paymentMethod === 'full_wallet' ? props.totalAmount : 0;
  return (
    <CardBody style={{padding: 0}}>
      <CardHeader>Raffle Request Summaries</CardHeader>
      {
        props.isLoading?
        <Loading/>:
        <Table>
          <thead>
            <tr>
              <th colSpan={2} className="text-center">
                Raffle Request Summaries
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <p style={{marginBottom: 0}}>{props.productVariant===null? '-': props.productVariant.display_name}</p>
                <p style={{marginBottom: ".2rem", fontSize: '.75rem'}}>
                  <span style={{marginRight: '.2rem'}}>{`SIZE: ${props.size.US} US`}</span>
                </p>
              </td>
              <td><TextRupiah prefix="Rp. " value={_getFloatValue(props.amount)} align="right" block/></td>
            </tr>
            <tr>
              <td>{props.kaCourier} {props.kaCourierOption} (1x)</td>
              <td><TextRupiah prefix="Rp. " value={_getFloatValue(props.kaCourierPrice)} align="right" block/></td>
            </tr>
            <tr>
              <td>Biaya Tambahan</td>
              <td><TextRupiah  prefix="Rp. " value={_getFloatValue(props.administrationFee)} align="right" block/></td>
            </tr>
            <tr>
              <td><b>Sub Total</b></td>
              <td>
                <TextRupiah prefix="Rp. " value={_getFloatValue(props.totalAmount)} align="right" block bold/>
              </td>
            </tr>
            <tr>
              <td>Kick Credit</td>
              <td>
                <TextRupiah prefix="-Rp. " value={_getFloatValue(kickCredit)} align="right" block/>
              </td>
            </tr>
            <tr>
              <td><b>Total</b></td>
              <td>
                <TextRupiah prefix="Rp. " value={_getFloatValue(props.totalAmount-kickCredit)} align="right" block bold/>
              </td>
            </tr>
          </tbody>
        </Table>
      }
    </CardBody>
  )
}

export default RaffleRequestSummaryCard;

RaffleRequestSummaryCard.propTypes = {
  id: propTypes.number,
  isLoading: propTypes.bool,
  productVariant: propTypes.object,
  amount: propTypes.oneOfType([propTypes.number, propTypes.string]),
  kaCourier: propTypes.string,
  kaCourierOption: propTypes.string,
  kaCourierPrice: propTypes.oneOfType([propTypes.number, propTypes.string]),
  administrationFee: propTypes.oneOfType([propTypes.number, propTypes.string]),
  totalAmount: propTypes.oneOfType([propTypes.number, propTypes.string]),
  paymentMethod: propTypes.string,
  size: propTypes.object,
}

RaffleRequestSummaryCard.defaultProps = {
  id: 0,
  isLoading: false,
  displayName: '-',
  amount: 0,
  kaCourierPrice: 0,
  administrationFee: 0,
  totalAmount: 0,
  paymentMethod: 'bank_tranfer',
}