import React from "react";
import propTypes from "prop-types";
import { Form, FormGroup, Button, Row, Col, Label, Input } from "reactstrap";

const sortCategory = [
  {
    value: "",
    label: "Default",
  },
  {
    value: true,
    label: "Parent",
  },
  {
    value: false,
    label: "Children",
  },
];

const FilterForm = (props) => {
  return (
    <Form className="form-filter" onSubmit={props.onSubmit}>
      <Row>
        <Col xs={12} md={6} style={{ paddingRight: 0 }}>
          <p>Filter By :</p>
          <FormGroup row>
            <Col xs={12} sm={4} md={2}>
              <Label className="justify-content-start">Category</Label>
            </Col>
            <Col xs={12} sm={8} md={8}>
              <Input
                type="select"
                name="is_parent"
                onChange={props.onCategoryInputChange}
                value={props.is_parent}
              >
                {sortCategory.map((option, i) => (
                  <option value={option.value} key={i}>
                    {option.label}
                  </option>
                ))}
              </Input>
            </Col>
            <Col xs={12} sm={8} md={2}>
              <Button color="primary">Apply</Button>
            </Col>
          </FormGroup>
        </Col>
      </Row>
    </Form>
  );
};
export default FilterForm;

FilterForm.propTypes = {
  onCategoryInputChange: propTypes.func.isRequired,
  onSubmit: propTypes.func.isRequired,
};

FilterForm.defaultProps = {
  category: "",
};
