import React from 'react';
import { connect } from 'react-redux';
import {
  Row,
  Col,
  Card,
  CardBody,
  CardText
} from 'reactstrap';
import { Th } from '../../components/Tbl';
import { getRaffleRequestList } from '../../actions/raffleAction';
import privateView from '../../components/hocs/privateView';
import { CardHeaderWithToolbars, Title } from '../../components/Card/CardHeaderWithToolbars';
import { FontAwesomeIcon } from '../../components/Icons';
import Paginations from '../../components/Paginations/Paginations';
import PaginationSummaries from '../../components/Paginations/PaginationSummaries';
import RowItem from './RaffleRequest/RowItem';
import { Tbl, TblHead, TblBody } from '../../components/Tbl';
import { generateNumber } from '../../utils';
import { Toolbars } from './Filters';
import FilterPanel from './RaffleRequest/FilterPanel';
import { DEFAULT_SORT_BY, DEFAULT_PER_PAGE } from '../../constants/settings';
import DropdownProductCategories from '../../components/Dropdowns/DropdownProductCategories';
import Analytics from "../../services/Analytics";

const defaultParams = {
  keyword: '',
  sort_by : DEFAULT_SORT_BY,
  per_page : DEFAULT_PER_PAGE
}

class RaffleRequestIndex extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      _params:{
        ...defaultParams
      },
      _filterOpen: false
    }
    this._handleToggleFilterPanel = this._handleToggleFilterPanel.bind(this);
    this._handleResetFilter = this._handleResetFilter.bind(this);
    this._handleGoto = this._handleGoto.bind(this);
    this._handleRefresh = this._handleRefresh.bind(this);
    this._handleApplyFilter = this._handleApplyFilter.bind(this);
    this._handleCategoryChanged = this._handleCategoryChanged.bind(this);
  }

  componentDidMount(){
    const { email } = this.props.injectedProps;
    this._getList(this.state._params);
    Analytics.recordPageView('raffle_req_list', email, {
      url: window.location.href,
      location: this.props.location,
      match: this.props.match
    });
  }

  _getList(rawParams = {}){
    const params = Object.keys(rawParams)
    .filter(key => rawParams[key]!='')
    .reduce((obj, current)=> ({...obj, [current]: rawParams[current]}), []);
    this.props.getList({params});
  }

  _handleRefresh = (e) => {
    e.preventDefault();
    this._refreshList();
  }

  _refreshList = () => {
    const { _page, ..._params } = this.state._params;
    this.setState({
      _params
    },()=>this._getList(this.state._params));
  }

  _handleToggleFilterPanel = () =>{
    this.setState({_filterOpen: !this.state._filterOpen})
  }

  _handleApplyFilter = p => {
    const { _params } = this.state;
    this.setState({
      _params: {
        ..._params,
        ...p,
        page: 1
      }
    }, () => {
      this._getList(this.state._params)
    })
  }

  async _handleCategoryChanged(category){
    await this.setState({
      _selectedCategory: category === ''? null: category.label,
      _params: {
        ...this.state._params,
        category_id: category === ''? category: category.value,
        page: 1,
      }
    });
    const { _params } = this.state;
    this._getList(_params);
  }

  _handleResetFilter = (e) =>{
    e.preventDefault();
    this.setState({
      _params:{
        ...defaultParams
      },
      _filterOpen: false
    }, () => {
      this._getList(this.state._params)
    })
  }

	_handleGoto = page =>{
    this.setState({
      _params: {
        ...this.state._params,
        page
      }
    }, ()=> this._getList(this.state._params) );
  }

  render(){
    return(
      <div className="animated fadeIn">
        <Row>
          <Col xs={12}>
            <Card>
              <CardHeaderWithToolbars>
                <Title>
                  <FontAwesomeIcon iconType="align-justify"/>{' '}
                  <span>Raffle Requests</span>
                </Title>
                <Toolbars
                  isOpen={this.state._filterOpen}
                  onToggleFilter={this._handleToggleFilterPanel}
                  onRefreshList={this._handleRefresh}
                  onResetFilter={this._handleResetFilter}
                />
              </CardHeaderWithToolbars>
              <FilterPanel
                {...this.state._params}
                isOpen={this.state._filterOpen}
                onApplyFilter={this._handleApplyFilter}
                isReset={this.state._isResetFilter}
                onResetFilterCallback={()=>this.setState({_isResetFilter: false, _filterOpen: false})}
              />
              <CardBody>
                <CardText>
                  In this section will display our raffle requests.
                </CardText>
                <PaginationSummaries
                  from={this.props.dataRaffle.data.from}
                  to={this.props.dataRaffle.data.to}
                  total={this.props.dataRaffle.data.total}
                />
                <div className="table-responsive" ref={tableRef => this.tableRef = tableRef}>
                  <Tbl>
                    <TblHead
                        renderChildren={()=>(
                          <tr>
                            <Th>No.</Th>
                            <Th>
                              <div style={{display: 'flex', flexDirection: 'row', alignItems: 'flex-end'}}>
                                <p style={{marginRight: 10, marginBottom: 0}}>
                                  Product
                                  {
                                    this.state._selectedCategory !== null?
                                    <span style={{display: 'block', fontSize: '.5rem'}}>{this.state._selectedCategory}</span>
                                    : null
                                  }
                                </p>
                                <DropdownProductCategories
                                  value={this.state._params.category_id}
                                  onCategoryChanged={this._handleCategoryChanged}
                                />
                              </div>
                            </Th>
                            <Th>Amount</Th>
                            <Th>Payment</Th>
                            <Th>Status</Th>
                            <Th>Updated</Th>
                            <Th>Action</Th>
                          </tr>
                        )}
                      />
                     <TblBody
                      isLoad={this.props.dataRaffle.isFetch}
                      hasRow={this.props.dataRaffle.data.data
                        &&this.props.dataRaffle.status_code===200
                        &&this.props.dataRaffle.data.data.length>0}
                      columnCount={7}
                      renderChildren={()=>{
                        const { current_page,per_page } =  this.props.dataRaffle.data;
                        return this.props.dataRaffle.data.data.map((item, index)=> {
                          const rowNumber = generateNumber(per_page,current_page,index);
                          return (
                            <RowItem
                              key={item.id}
                              id={item.id}
                              rowNumber={rowNumber}
                              category={item.product_variant.product.category&&item.product_variant.product.category.name}
                              code={item.code}
                              user={item.user}
                              seller={item.user_sell && item.user_sell.user}
                              amount={item.amount}
                              paymentMethod={item.payment_method}
                              updatedAt={item.updated_at}
                              createdAt={item.created_at}
                              expiredAt={item.expired_at}
                              status={item.status}
                              payment={item.offer}
                              size={item.size.US}
                              displayName={item.product_variant.display_name}
                              colour={item.product_variant.colour}
                              SKU={item.product_variant.SKU}
                            />
                          )
                        })
                      }}
                    >
                    </TblBody>
                  </Tbl>
                </div>
                { this.props.dataRaffle.status_code===200 && (
                  <Paginations
                    size="sm"
                    lastPage={this.props.dataRaffle.data.last_page}
                    perPage={this.props.dataRaffle.data.per_page}
                    total={this.props.dataRaffle.data.total}
                    maxPage={8}
                    path={this.props.dataRaffle.data.path}
                    currentPage={this.props.dataRaffle.data.current_page}
                    prevPageUrl={this.props.dataRaffle.data.prev_page_url}
                    nextPageUrl={this.props.dataRaffle.data.next_page_url}
                    handleGotoCallback={this._handleGoto}
                    onCurrentPageChanged={() => {
                      window.scrollTo({
                        top: this.tableRef.offsetTop+25,
                        behavior: 'smooth'
                      })
                    }}
                  />
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    )
  }
}

const mapStateToProps= ({ raffle: { requests}, auth: { isFetch, roles, isLogged, id, email } }) => ({
  guardData: { isFetch, roles, isLogged, id, email },
  dataRaffle: requests
});

const mapDispatchToProps = (dispatch) => ({
  getList:(query) => dispatch(getRaffleRequestList(query))
});

const enhance = connect(mapStateToProps, mapDispatchToProps);

export default enhance(privateView(RaffleRequestIndex, ['supervisor', 'customer_service']));