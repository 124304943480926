import React from 'react';
import{
  Card,
  CardHeader,
  CardBody,
  Badge,
  Alert
} from 'reactstrap';
import Loading from 'react-loading-animation';
import propTypes from 'prop-types';
import { _getValue } from '../../../utils/form';
import {
  FieldRow,
  FieldColumn,
  FieldValue,
  FieldLabel
} from '../../../styled/Fields';

const TrackingDetail = props => {
  const { headerText, isLoading, shippingUpdate } = props;
  const renderShippingStatus = (status = 'DELIVERED') => (
    <Badge color={status === 'ON PROCESS' ? 'warning' : 'success'}>{status}</Badge>
  )
  let sellerHistory = [];
  let buyerHistory = [];
  if(shippingUpdate && shippingUpdate.history && shippingUpdate.history.length){
    sellerHistory = shippingUpdate.history.filter(item => item.destination === 'warehouse')
    buyerHistory = shippingUpdate.history.filter(item => item.destination === 'buyer')
  }
  return (
    <Card>
      <CardHeader>
        {headerText}
      </CardHeader>
      <CardBody>
        <Loading isLoading={isLoading}>
          <FieldRow style={{ maxHeight: "375px", overflow:'auto'}}>
            <FieldColumn className="col-xs-12 col-md-6">
              <FieldLabel>Seller Courier</FieldLabel>
              <FieldValue>
                <span>{_getValue(shippingUpdate && shippingUpdate.seller_courier)}</span>
              </FieldValue>
            </FieldColumn>
            <FieldColumn className="col-xs-12 col-md-6">
              <FieldLabel>Seller AWB Numb.</FieldLabel>
              <FieldValue>
                <span>{_getValue(shippingUpdate && shippingUpdate.seller_awb)}</span>
              </FieldValue>
            </FieldColumn>
            <FieldColumn className="col-xs-12 col-md-6">
              <FieldLabel>KA Courier</FieldLabel>
              <FieldValue>
                <span>{_getValue(shippingUpdate && shippingUpdate.ka_courier)}</span>
              </FieldValue>
            </FieldColumn>
            <FieldColumn className="col-xs-12 col-md-6">
              <FieldLabel>KA AWB Numb.</FieldLabel>
              <FieldValue>
                <span>{_getValue(shippingUpdate && shippingUpdate.ka_awb)}</span>
              </FieldValue>
            </FieldColumn>
            <FieldColumn className="col-xs-12 col-md-6">
              <FieldLabel>Delivered at</FieldLabel>
              <FieldValue>
                <span>{_getValue(shippingUpdate && shippingUpdate.delivered_at)}</span>
              </FieldValue>
            </FieldColumn>
            <FieldColumn className="col-xs-12 col-md-6">
              <FieldLabel>ETA</FieldLabel>
              <FieldValue>
                <span>{_getValue(shippingUpdate && shippingUpdate.eta+' days')}</span>
              </FieldValue>
            </FieldColumn>
            <FieldColumn className="col-xs-12 col-md-12">
              <FieldLabel>Delivery Status  {renderShippingStatus(shippingUpdate && shippingUpdate.status)}</FieldLabel>
              <p>Buyer Shipping {shippingUpdate && shippingUpdate.buyer_receiver? (<span style={{fontSize: '.65rem'}}>(Penerima: {shippingUpdate && shippingUpdate.buyer_receiver})</span>):null}</p>
              {
                buyerHistory.length?
                <ul>
                  {buyerHistory && buyerHistory.map((item, i) => (
                    <li key={i}>
                      <span>{item.description.indexOf(' ') <= 2 ? item.location : item.description}</span>
                      <p>{item.logged_at}</p>
                    </li>
                  ))}
                </ul>
                :
                <Alert color="danger" className="text-center">No Tracking Data Found</Alert>
              }
              <hr/>
              <p>Seller Shipping</p>
              {
                sellerHistory.length? (
                  <ul>
                    {sellerHistory.map((item, i) => (
                      <li key={i}>
                        <span>{item.description.indexOf(' ') <= 2 ? item.location : item.description}</span>
                        <p>{item.logged_at}</p>
                      </li>
                    ))}
                  </ul>
                ):
                <Alert color="danger" className="text-center">No Tracking Data Found</Alert>
              }
            </FieldColumn>
          </FieldRow>
        </Loading>
      </CardBody>
    </Card>
  )
}

export default TrackingDetail

TrackingDetail.propTypes = {
  headerText: propTypes.string,
  isLoading: propTypes.bool,
  shippingUpdate: propTypes.object
}

TrackingDetail.defaultProps = {
  headerText: "Tracking Shipment Details",
  isLoading: false,
  shippingUpdate: {}
}