import React, { Component } from 'react';
import propTypes from 'prop-types';

import Seller from '../../../components/ConsignmentOrder/SellerDetail';

export default class SellerDetail extends Component{
  constructor(props){
    super(props);
    this.state={
      modalSellerInfoOpen: false
    }
    this._handleToggleModalSellerInfo = this._handleToggleModalSellerInfo.bind(this)
  }
  _handleToggleModalSellerInfo(){
    this.setState({
      modalSellerInfoOpen: !this.state.modalSellerInfoOpen
    })
  }

  render(){
    return(
      <Seller      
        className="tab-content--custom__card"
        modalSellerInfoOpen={this.state.modalSellerInfoOpen}
        toggleModalSellerInfo={this._handleToggleModalSellerInfo}
        {...this.props}
      />
    )
  }
}
SellerDetail.propTypes = {
  sellerConsignment:propTypes.shape({
    id: propTypes.number,
    email: propTypes.string,
    seller_verification_info: propTypes.arrayOf(propTypes.object),
    roles: propTypes.arrayOf(propTypes.shape({
      id: propTypes.number,
      name: propTypes.string
    }))
  }),
}
SellerDetail.defaultProps = {
  sellerConsignment:{
    id: null,
    email: null,
    seller_verification_info: [],
    roles: []
  }
}