import React from 'react';
import propTypes from 'prop-types';
import PaginationSummaries from '../../../components/Paginations/PaginationSummaries';
import Paginations from '../../../components/Paginations/Paginations';
import { Tbl, TblHead, TblBody, Th } from '../../../components/Tbl';
import RowItem from './RowItem';
import { generateNumber } from '../../../utils';

class List extends React.Component{

  shouldComponentUpdate(nextProps) {
    return nextProps.isLoading!==this.props.isLoading || nextProps.editorsChoiceTotal!==this.props.editorsChoiceTotal
  }

  render(){
    return(
      <div className="animated fadeIn">
        <PaginationSummaries
          from={this.props.from}
          to={this.props.to}
          total={this.props.total}
        />
        <div className="table-responsive" ref={tableRef => this.tableRef = tableRef}>
          <Tbl>
            <TblHead
              renderChildren={this.props.onRenderHeader}
            />
            <TblBody
              isLoad={this.props.isLoading}
              hasRow={this.props.data.length>0}
              columnCount={6}
              renderChildren={()=> {
                if(!this.props.isLoading&&this.props.statusCode===200){
                  const { currentPage, perPage } = this.props;
                  // console.log('Starting Render Item')
                  return (
                    this.props.data.map((item,index)=>{
                      const number = generateNumber(perPage,currentPage,index);
                      return (
                        <RowItem
                          key={number}
                          number={number}
                          id={item.id}
                          brand={item.product.brand}
                          displayName={item.display_name}
                          SKU={item.SKU}
                          category={item.product.category}
                          colour={item.colour}
                          active={item.active}
                          vintage={item.vintage}
                          editorsChoice={item.editors_choice}
                          editorsPosition={item.editors_position}
                          autoSync={item.auto_sync}
                          updatedAt={item.updated_at}
                          variantData={item}
                          defaultRoute={this.props.defaultRoute}
                          // editorsChoiceTotal={this.props.editorsChoiceTotal}
                          // maxEditorsChoice={this.props.maxEditorsChoice}
                          onEditorsChoiceChange={this.props.onEditorsChoiceChange}
                          onToggleModalInfo={this.props.onToggleModalInfo}
                          onToggleModalScrape={this.props.onToggleModalScrape}
                          onToggleModalPosition={this.props.onToggleModalPosition}
                          findEditorChoices={this.props.findEditorChoices}
                        />
                      )
                    })
                  )
                }
                return null;
              }}
            />
          </Tbl>
        </div>
        <Paginations
          isLoading={this.props.isLoading}
          size="sm"
          maxPage={8}
          total={this.props.total}
          currentPage={this.props.currentPage}
          lastPage={this.props.lastPage}
          perPage={this.props.perPage}
          handleGotoCallback={this.props.onGotoPageList}
          onCurrentPageChanged={currentPage => {
            window.scrollTo({
              top: this.tableRef.offsetTop+25,
              behavior: 'smooth'
            })
          }}
        />
      </div>
    )
  }
}
export default List;

List.propTypes = {
  from: propTypes.number,
  to: propTypes.number,
  total: propTypes.number,
  currentPage: propTypes.number,
  lastPage: propTypes.number,
  perPage: propTypes.oneOfType([propTypes.number, propTypes.string]),
  statusCode: propTypes.any,
  defaultRoute: propTypes.string.isRequired,
  isLoading: propTypes.bool,
  editorsChoiceTotal: propTypes.number,
  maxEditorsChoice: propTypes.number,
  onEditorsChoiceChange: propTypes.func,
  onGotoPageList: propTypes.func
}
List.defaultProps = {
  from: 0,
  to: 0,
  total: 0,
  currentPage: 1,
  lastPage: 0,
  perPage: 0,
  editorsChoiceTotal: 0,
  maxEditorsChoice: 0,
}