import React,{Component} from 'react';
import { connect } from 'react-redux';
import {
  Container,
  Row,
  Card
} from 'reactstrap';
import qs from 'query-string';

import { 
  getDetailSellerVerification,
  updateStatusSellerVerification
} from '../../actions/sellerVerificationAction';
import SellerVerificationContainer from './sellerVerificationDetail/SellerVerificationContainer';
import privateView from '../../components/hocs/privateView';
import Analytics from "../../services/Analytics";

class SellerVerificationDetail extends Component {
	constructor(props) {
		super(props);
    const DEFAULT_STATE = {
      id: parseInt(this.props.match.params.id)
    }
    const { id } = Object.assign(DEFAULT_STATE,qs.parse(props.location.search));
		this.state={
      id,
      fetchWhenLoad: true,
      _imageChanged:false,
      _existingImageQueue:[],
      _images:[],
      _indexImage: null,
		}
    this._handleUpdate = this._handleUpdate.bind(this);
  }

	componentDidMount() {
    const { email } = this.props.injectedProps;
		this.props.getDetail(this.state.id);
    Analytics.recordPageView('seller_verification_detail', email, {
      url: window.location.href,
      location: this.props.location,
      match: this.props.match
    });
  }

  componentDidUpdate(prevProps, prevState){
    if(!this.props.sellerVerification.isFetch && prevProps.sellerVerification.isFetch!==this.props.sellerVerification.isFetch){
      const { error } = this.props.sellerVerification;
      if(error){
        this.props.history.replace(`/${error.status_code}`);
      }
      else{
        this.setState({
          fetchWhenLoad: false
        })
      }
    }
  }
  _isLoading(){
    return this.state.fetchWhenLoad
  }

  _handleUpdate(payload){
    this.props.updateSellerVerification(this.state.id, payload);
  }

  _renderContent(){
    const { user, id_card, address, mobile_number, seller_verification_images, status, type, notes, ...sellerVerification } = this.props.sellerVerification.detail;
    return(
      <Container>
        <SellerVerificationContainer
          sellerVerification={{...sellerVerification}}
          dataSellerVerification={this.props.sellerVerification}
          user={user}
          idCard={id_card}
          address={address}
          mobileNumber={mobile_number}
          sellerVerificationImages={seller_verification_images}
          status={status}
          type={type}
          notes={notes}
          handleUpdateSellerVerification={this._handleUpdate}



          isLoading={this._isLoading()}
          userSetting={this.props.userSetting}
          images={this.state._images}
        />
      </Container>
    )
  }
	render(){
    return(
      <div className="animated fadeIn">
        <Row>
          <Card body>
            {this._renderContent()}
          </Card>
        </Row>
      </div>
    )
  }

}

const mapStateToProps=({sellerVerification, auth: { isFetch, isLogged, roles, id, email }, user})=> {
	return {
    sellerVerification,
    userSetting: user.user_setting,
    guardData: { isFetch, isLogged, roles, id, email }
	}
}

const mapDispatchToProps=(dispatch)=>{
	return {
    getDetail:(id)=>dispatch(getDetailSellerVerification(id)),
		updateSellerVerification: (id, payload) => dispatch(updateStatusSellerVerification(id, payload)),
	}
}

const enhance = connect(mapStateToProps,mapDispatchToProps);

export default enhance(privateView(SellerVerificationDetail, ['supervisor']));
