import {
  GET_LIST_APP_SECTION,
  GET_DETAIL_APP_SECTION,
  DELETE_APP_SECTION,
  UPDATE_APP_SECTION,
  CREATE_APP_SECTION,
  APP_SECTION_ERROR,
  APP_SECTION_REQUEST,

  CREATE_APP_SECTION_IMAGE,
  UPDATE_APP_SECTION_IMAGE,
  DELETE_APP_SECTION_IMAGE,
  GET_DETAIL_APP_SECTION_IMAGES,
  APP_SECTION_S3
} from '../constants';

const initialState = {
  list: {
    isFetch: false,
    data: [],
    error: null
  },
  uploadedImage: {
    isPending: false,
    results: null,
    error: null
  },
  details: {
    isFetch: false,
    isImageDataFetch: false,
    isSubmit: false,
    isImageDataSubmit: false,
    data: {
      id: null,
      deleted: false,
      images: []
    },
    error: null
  }
}

const onFetched = (lastState, { requestType, value }) => {
  switch(requestType){
    case GET_LIST_APP_SECTION: return {...lastState, list: { ...lastState.list, isFetch: value }}
    case GET_DETAIL_APP_SECTION: return {...lastState, details: { ...lastState.details, isFetch: value }}
    case GET_DETAIL_APP_SECTION_IMAGES: return {...lastState, details: { ...lastState.details, isImageDataFetch: value }}
    case CREATE_APP_SECTION:
    case UPDATE_APP_SECTION:
    case DELETE_APP_SECTION: return {...lastState, details: { ...lastState.details, isSubmit: value }}
    case CREATE_APP_SECTION_IMAGE:
    case UPDATE_APP_SECTION_IMAGE:
    case DELETE_APP_SECTION_IMAGE: return {...lastState, details: { ...lastState.details, isImageDataSubmit: value }}
    default: return lastState;
  }
}

const onError = (lastState, { requestType, error, ...rest }) => {
  switch(requestType){
    case APP_SECTION_S3: return { ...lastState, uploadedImage: { ...lastState.uploadedImage, isPending: false, error }}
    case GET_LIST_APP_SECTION: return {...lastState, list: { ...lastState.list, error: { ...error, ...rest } }}
    case GET_DETAIL_APP_SECTION:
    case GET_DETAIL_APP_SECTION_IMAGES:
    case CREATE_APP_SECTION:
    case UPDATE_APP_SECTION:
    case DELETE_APP_SECTION:
    case CREATE_APP_SECTION_IMAGE:
    case UPDATE_APP_SECTION_IMAGE:
    case DELETE_APP_SECTION_IMAGE: return {...lastState, details: { ...lastState.details, error: { ...error, ...rest } }}
    default: return lastState;
  }
}

const reducer = (state = initialState, { type, payload }) => {
  switch(type) {
    case APP_SECTION_REQUEST: return onFetched(state, payload)
    case APP_SECTION_ERROR: return onError(state, payload)
    case GET_LIST_APP_SECTION: {
      return {
        ...state,
        list: {
          ...state.list,
          ...payload,
          error: null
        }
      }
    }
    case CREATE_APP_SECTION:
    case UPDATE_APP_SECTION:
    case DELETE_APP_SECTION:
    case GET_DETAIL_APP_SECTION: {
      return {
        ...state,
        details: {
          ...state.details,
          ...payload,
          deleted: type === DELETE_APP_SECTION,
          error: null
        }
      }
    }
    case CREATE_APP_SECTION_IMAGE:
    case UPDATE_APP_SECTION_IMAGE:
    case DELETE_APP_SECTION_IMAGE: {
      const { status_code, message } = payload;
      return {
        ...state,
        details: {
          ...state.details,
          status_code,
          message,
          error: null
        }
      }
    }
    case APP_SECTION_S3: {
      return {
        ...state,
        uploadedImage: {
          ...state.uploadedImage,
          error: null,
          ...payload,
        }
      }
    }
    case GET_DETAIL_APP_SECTION_IMAGES: {
      const { data: { id, images }, status_code, message } = payload;
      return {
        ...state,
        details: {
          ...state.details,
          data: {
            ...state.details.data,
            id,
            images: [ ...images ]
          },
          status_code,
          message,
          error: null
        }
      }
    }
    default: return state;
  }
}

export default reducer;