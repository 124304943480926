import { HANDBAGS_CATEGORY } from "../../../../constants/kickavenue";

export function isAllLuxuryItem(categories = []) {
  for (const category of categories) {
    if (category !== HANDBAGS_CATEGORY) {
      return false;
    }
  }
  return true;
}
