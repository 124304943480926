import React, { Component } from "react";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";

export default class Paginations extends Component {
    constructor(props) {
        super(props);
        this.state = {
            initialCurrentPage: props.currentPage,
        };
    }

    async componentDidUpdate(prevProps) {
        if (
            prevProps.isLoading &&
            !this.props.isLoading &&
            this.props.currentPage !== this.state.initialCurrentPage &&
            this.props.onCurrentPageChanged
        ) {
            await this.setState({ initialCurrentPage: this.props.currentPage });
            const { initialCurrentPage } = this.state;
            this.props.onCurrentPageChanged(initialCurrentPage);
        }
    }

    // Calculate page count
    pageCount() {
        return parseInt(
            this.props.total / this.props.perPage +
                (this.props.total % this.props.perPage > 0 ? 1 : 0)
        );
    }

    // Calculate median from maxPage state
    median() {
        return (
            parseInt(this.props.maxPage / 2) -
            (this.props.maxPage % 2 > 1 ? 0 : 1)
        );
    }

    prevPageUrl() {
        return this.props.prevPageUrl === null;
    }

    nextPageUrl() {
        return this.props.nextPageUrl === null;
    }

    // Go to page handles
    goto(page) {
        // this.setState({'currentPage':page});
        this.props.handleGotoCallback(page);
    }

    // Next page handles
    next(e) {
        e.preventDefault();
        this.goto(this.props.currentPage - 1);
    }

    // Previous page handles
    previous(e) {
        e.preventDefault();
        this.goto(this.props.currentPage + 1);
    }

    // Checking to disable link based on action, example of action: next, previous, ... links
    disabled(action) {
        if (this.props.isFetching) {
            return true;
        }

        switch (action) {
            case "next":
                return this.props.currentPage + 1 > this.pageCount()
                    ? true
                    : false;
            case "previous":
                return this.props.currentPage - 1 <= 0 ? true : false;
            default:
                return true;
        }
    }

    // Calculate start page items to show
    startIndex() {
        let startIndex = 1;
        if (this.pageCount() < this.props.maxPage) startIndex = 1;
        else if (parseInt(this.props.currentPage / this.props.maxPage) > 0) {
            if (
                this.props.currentPage - this.median() + this.props.maxPage <=
                this.pageCount()
            )
                startIndex = this.props.currentPage - this.median();
            else startIndex = this.pageCount() - this.props.maxPage + 1;
        } else if (
            this.props.currentPage % this.props.maxPage >
            this.props.maxPage - 2
        )
            startIndex = this.props.currentPage - this.median();

        return startIndex;
    }

    // Calculate end page items to show
    endIndex() {
        let endIndex = 0;
        let startIndex = this.startIndex();
        if (this.pageCount() == this.props.currentPage)
            endIndex = this.pageCount();
        else if (startIndex + (this.props.maxPage - 1) <= this.pageCount())
            endIndex = startIndex + (this.props.maxPage - 1);
        else return this.pageCount();

        return endIndex;
    }

    // Generate first page links
    firstPage() {
        if (this.startIndex() > this.median()) {
            let items = [
                <PaginationItem key="firstOne">
                    <PaginationLink
                        href="#"
                        onClick={(e) => {
                            if (this.props.isFetching) {
                                return;
                            }

                            e.preventDefault();
                            this.goto(1);
                        }}
                    >
                        1
                    </PaginationLink>
                </PaginationItem>,
                <PaginationItem key="firstTwo">
                    <PaginationLink
                        href="#"
                        onClick={(e) => {
                            if (this.props.isFetching) {
                                return;
                            }

                            e.preventDefault();
                            this.goto(2);
                        }}
                    >
                        2
                    </PaginationLink>
                </PaginationItem>,
                <PaginationItem key="firstThree" disabled={this.disabled()}>
                    <PaginationLink href="#">...</PaginationLink>
                </PaginationItem>,
            ];
            return items;
        }
        return "";
    }

    // Generate last page links
    lastPage() {
        if (this.endIndex() + 2 < this.pageCount()) {
            let items = [
                <PaginationItem key="lastOne" disabled={this.disabled()}>
                    <PaginationLink href="#">...</PaginationLink>
                </PaginationItem>,
                <PaginationItem key="lastTwo" disabled={this.props.isFetching}>
                    <PaginationLink
                        href="#"
                        onClick={(e) => {
                            e.preventDefault();
                            this.goto(this.pageCount() - 1);
                        }}
                    >
                        {this.props.isFetching && "..."}
                        {!this.props.isFetching && this.pageCount() - 1}
                    </PaginationLink>
                </PaginationItem>,
                <PaginationItem
                    key="lastThree"
                    disabled={this.props.isFetching}
                >
                    <PaginationLink
                        href="#"
                        onClick={(e) => {
                            e.preventDefault();
                            this.goto(this.pageCount());
                        }}
                    >
                        {this.props.isFetching && "..."}
                        {!this.props.isFetching && this.pageCount()}
                    </PaginationLink>
                </PaginationItem>,
            ];
            return items;
        }
        return "";
    }

    // Loop items using startIndex and endIndex
    renderItem() {
        let items = [];
        // console.log('start',this.startIndex())
        // console.log('end',this.endIndex())
        for (let i = this.startIndex(); i <= this.endIndex(); i++) {
            let item = (
                <PaginationItem
                    active={i == this.props.currentPage}
                    key={i}
                    onClick={(e) => {
                        if (this.props.isFetching) {
                            return;
                        }
                        
                        e.preventDefault();
                        this.goto(i);
                    }}
                >
                    <PaginationLink href="#">{i}</PaginationLink>
                </PaginationItem>
            );
            items.push(item);
        }
        return items;
    }

    render() {
        if (this.props.total <= this.props.perPage) return <div></div>;
        return (
            <Pagination size={this.props.size}>
                <PaginationItem disabled={this.disabled("previous")}>
                    <PaginationLink
                        previous
                        href="#"
                        onClick={this.next.bind(this)}
                    />
                </PaginationItem>
                {this.firstPage()}
                {this.renderItem()}
                {this.lastPage()}
                <PaginationItem disabled={this.disabled("next")}>
                    <PaginationLink
                        next
                        href="#"
                        onClick={this.previous.bind(this)}
                    />
                </PaginationItem>
            </Pagination>
        );
    }
}
