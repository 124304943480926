import React from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Row,
  Col
} from 'reactstrap';
import Select from 'react-select';
import classNames from 'classnames';

import { hasError, errorMessage, textChange, textValue } from '../../../utils/form';

const defaultParams = {
  no_limit: true,
  sort_by: "zoneId_asc"
}

export default class ModalAddress extends React.Component{
  constructor(props){
    super(props);
    this.state={
      _params: {
        ...defaultParams,
      },
      _errorForm: null,
      _id: '',
      _alias: '',
      _fullName:'',
      _mobileNumber:'',
      _shippingAddress:'',
      _country:null,
      _countrySelected:null,
      _countryOption:[{value:"",label:'Select Country'}],
      _province:null,
      _provinceSelected:null,
      _provinceOption:[{value:"",label:'Select Province'}],
      _city:null,
      _citySelected:null,
      _cityOption:[{value:"",label:'Select City'}],
      _postalCode:'',
      _shippingNote:'',
      _userId: '',
    }
    this._handleTextChange = this._handleTextChange.bind(this);
    this._handleSelectCountryChange = this._handleSelectCountryChange.bind(this);
    this._handleSelectProvinceChange = this._handleSelectProvinceChange.bind(this);
    this._handleSelectCityChange = this._handleSelectCityChange.bind(this);
    this._getProvinceId = this._getProvinceId.bind(this);
    this._buildCountryOptions = this._buildCountryOptions.bind(this);
    this._buildProvinceOptions = this._buildProvinceOptions.bind(this);
    this._buildCityOptions = this._buildCityOptions.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }
  
  componentDidUpdate(prevProps){
    if(!prevProps.isOpen&&prevProps.isOpen!==this.props.isOpen&&this.props.data){
      const { id, alias, full_name, phone_number, street_address, country, province, city, postal_code, note, user_id } = this.props.data;
      this.setState({
        _id: id,
        _alias: alias,
        _fullName: full_name,
        _mobileNumber: phone_number,
        _shippingAddress: street_address,
        _country: country,
        _province: province,
        _city: city,
        _postalCode: postal_code,
        _shippingNote: note,
        _provinceSelected: null,
        _citySelected: null,
        _userId: user_id,
      }, () => this.props.getCountries({ params: this.state._params }))
    }
    if(!prevProps.isOpen&&prevProps.isOpen!==this.props.isOpen&&this.props.data===null){
      this.setState({
        _id: '',
        _alias: '',
        _fullName: '',
        _mobileNumber: '',
        _shippingAddress: '',
        _country: '',
        _province: '',
        _city: '',
        _postalCode: '',
        _shippingNote: '',
        _provinceSelected: null,
        _citySelected: null,
        _userId: '',
      }, () => this.props.getCountries({ params: this.state._params }))
    }

    if(this.props.dataCountries.isFetch === false && this.props.dataCountries.isFetch !== prevProps.dataCountries.isFetch) {
      if (this.props.dataCountries?.list?.length && this.props.dataCountries?.status_code === 200) {
        const def = { value: "", label: 'Select Country' };
        const { list: data } = this.props.dataCountries;
        let countryList = data.map((country, index)=>({
          value: country.id,
          label: country.name,
          code3: country.code3
        }));
        let { _countrySelected, _country } = this.state; 

        if (_countrySelected === null) {
          const getCountry = countryList.filter(c => c.code3 == _country);
          if (getCountry.length)
            _countrySelected = getCountry[0]
        }
        this.setState({
          _countryOption: [def, ...countryList],
          _countrySelected
        }, () => this.props.getProvinces(this.state._countrySelected?.code3));
      }
    }

    if(prevProps.dataProvinces.isFetch !== this.props.dataProvinces.isFetch && this.props.dataProvinces.status_code === 200) {
      const def = { value: "", label: 'Select Province' };
      const { data } = this.props.dataProvinces;
      let provinceList = data.map((province, index) => ({
        value: province.id,
        label: province.name
      }));
      let { _provinceSelected, _province } = this.state;
      if (_provinceSelected === null) {
        const getProvince = provinceList.filter(p => p.label == _province);
        if (getProvince.length)
          _provinceSelected = getProvince[0]
      }
      this.setState({ 
        _provinceOption: [def, ...provinceList],
        _provinceSelected 
      }, () => {
        const params = { province: _province };
        this.props.getCities({ params });
      });
    }

    if(prevProps.dataCities.isFetch !== this.props.dataCities.isFetch && this.props.dataCities.status_code === 200) {
      const def = { value: "", label: 'Select City' };
      const { data } = this.props.dataCities;
      let city = data.map((city, index) => ({
        value: city.id,
        label: city.name
      }));
      
      let { _citySelected, _city } = this.state;
      if (_citySelected === null) {
        const getCity = city.filter(c => c.label == _city);
        if (getCity.length)
          _citySelected = getCity[0]
      }
      this.setState({ 
        _cityOption: [def, ...city],
        _citySelected 
      });
    }
  }

  _handleTextChange(e){
    this.setState({
      ...textChange(e, this.state)
    })
  }

  _handleSelectCountryChange(selected) {
    this.setState({
      _countrySelected:selected?selected:'',
      _country:selected?selected.name:'',
      _province:'',
      _city:''
    }, ()=>{
      this._buildProvinceOptions();
    });
  }

  _buildCountryOptions(){
    if(this.props.dataCountries.status_code !== 200) {
      this.props.getCountries({ params: this.state._params });
    } else{
      const { list: data }=this.props.dataCountries;
      const countries = data.map((country,index)=>({
        index:index,
        value:country.id,
        label:country.name
      }));
      this.setState({_countryOption:[ {value:"",label:"Select Country"} , ...countries ]});
    }
  }

  _handleSelectProvinceChange(selected){
    this.setState({
      _provinceSelected:selected?selected.value:'',
      _province:selected?selected.label:'',
      _city:''
    },()=>{
      this._getProvinceId();
      this._buildCityOptions(this._getProvinceId());
    });
  }

  _getProvinceId(){
    const { _province } = this.state;
    const { data } = this.props.dataProvinces;
    if(_province !== "" && data.length) {
      const province = data.find(item => item.name === _province);
      return province.id;
    }
    return 0;
  }

  _buildProvinceOptions(){
    if(this.props.dataProvinces.status_code !== 200) {
      this.props.getProvinces(this.state._countrySelected?.code3);
    } else{
      const { data }=this.props.dataProvinces;
      const provinces = data.map((province,index)=>({
        value:province.id,
        label:province.name
      }));
      this.setState({_provinceOption:[ {value:"",label:"Select Province"} , ...provinces ]});
    }
  }

  _handleSelectCityChange(selected){
    this.setState({ 
      _citySelected:selected?selected.value:'',
      _city:selected?selected.label:'', 
    });
  }

  _buildCityOptions(provinceId){
    if(provinceId){
      const province = this.props.dataProvinces.data.find(p=>p.id===provinceId);
      const { data, status_code } = this.props.dataCities;
      const params = {province:province.name}
      this.props.getCities({params});
      if(status_code !== 200){
        this.props.getCities({params});
      } else if(data.length){
        if(province && data[0].province_id === province.id){
          const { _stillfetchCity, _city } = this.state;
          const cities = [];
          data.forEach(item =>{
            const duplicate=cities.filter(d => d.label===item.name).length>0;
            if(duplicate){
              cities.push({value:item.id,label:`${item.name} (${item.type})`})
            } else
              cities.push({value:item.id,label:`${item.name}`})
          })
          if(_city && _stillfetchCity){
            if(typeof _city === "string"){
              const getCity = cities.find(city=>city.label === _city);
              const cityId = getCity? getCity.value : null;
              this.setState({
                _stillfetchCity: false,
                _city: cityId
              })
            }
          }
          this.setState({ _cityOption: [ { value:"",label:"Select City" } , ...cities ] });
        }
        else {
          const params = {province:province.name}
          this.props.getCities({params});
        }
      }
    }
  }

  onSubmit() {
    const { 
      _id, 
      _alias,
      _fullName, 
      _mobileNumber, 
      _shippingAddress, 
      _country, 
      _province, 
      _city, 
      _postalCode, 
      _shippingNote,
      _userId, 
    } = this.state;
      
    const payload = {
      id: _id,
      alias: _alias,
      full_name: _fullName,
      phone_number: _mobileNumber,
      street_address: _shippingAddress,
      country: _country,
      province: _province,
      city: _city,
      postal_code: _postalCode,
      note: _shippingNote,
    }
    this.props.onSubmit(_userId, _id, payload);
  }

  render(){
    const {
      _errorForm,
      _countryOption,
      _provinceOption,
      _cityOption,
      _countrySelected,
      _provinceSelected,
      _citySelected,
    } = this.state;
    return(
      <Modal isOpen={this.props.isOpen} toggle={this.props.onCancel} className="modal-dialog modal-md">
        <ModalHeader toggle={this.props.onCancel}>Edit Address</ModalHeader>
        <ModalBody>
          <Form>
          
            <Row>
              <Col xs={12}>
                <FormGroup  className={classNames({'has-danger has-feedback':hasError('ka_courier',this.state.error)})}>
                  <Row>
                    <Col xs={12} md={12}>
                      <FormGroup className={classNames({'has-danger has-feedback':hasError('alias', _errorForm)})}>
                        <Label>Alias*</Label>
                        <Input
                          name="_alias"
                          onChange={this._handleTextChange}
                          value={textValue("_alias", this.state)}
                          placeholder="Enter Alias"
                        />
                        {errorMessage('alias', _errorForm)}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={12}>
                      <FormGroup className={classNames({'has-danger has-feedback':hasError('full_name', _errorForm)})}>
                        <Label>Recipient*</Label>
                        <Input
                          name="_fullName"
                          onChange={this._handleTextChange}
                          value={textValue("_fullName", this.state)}
                          placeholder="Enter Recipient Name"
                        />
                        {errorMessage('full_name', _errorForm)}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={12}>
                      <FormGroup className={classNames({'has-danger has-feedback':hasError('mobile_number', _errorForm)})}>
                        <Label>Phone Number*</Label>
                        <Input
                          name="_mobileNumber"
                          onChange={this._handleTextChange}
                          value={textValue("_mobileNumber", this.state)}
                          placeholder="Enter Phone Number"
                        />
                        {errorMessage('mobile_number', _errorForm)}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12}>
                      <FormGroup className={classNames({'has-danger has-feedback':hasError('street_address', _errorForm)})}>
                        <Label>Address*</Label>
                        <Input
                          name="_shippingAddress"
                          onChange={this._handleTextChange}
                          value={textValue("_shippingAddress", this.state)}
                          placeholder="Address"
                        />
                        {errorMessage('street_address', _errorForm)}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={6} md={6}>
                      <FormGroup className={classNames({'has-danger has-feedback':hasError('country', _errorForm)})}>
                        <Label for="_formSelectCountry">Country*</Label>
                        <Select
                          id="_formSelectCountry"
                          name="_country"
                          value={_countrySelected}
                          options={_countryOption.length?_countryOption:[{value:'',label:'Loading...'}]}
                          onChange={this._handleSelectCountryChange}
                        />
                        {errorMessage('country', _errorForm)}
                      </FormGroup>
                    </Col>
                    <Col xs={6} md={6}>
                      <FormGroup className={classNames({'has-danger has-feedback':hasError('province', _errorForm)})}>
                        <Label for="_formSelectProvince">Province*</Label>
                        <Select
                          id="_formSelectProvince"
                          name="_province"
                          value={_provinceSelected}
                          options={_provinceOption.length?_provinceOption:[{value:'',label:'Loading...'}]}
                          onChange={this._handleSelectProvinceChange}
                        />
                        {errorMessage('province', _errorForm)}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={6} md={6}>
                      <FormGroup className={classNames({'has-danger has-feedback':hasError('city', _errorForm)})}>
                        <Label for="_formSelectCity">City*</Label>
                        <Select
                          id="_formSelectCity"
                          name="_city"
                          value={_citySelected}
                          options={_cityOption.length?_cityOption:[{value:'',label:'Loading...'}]}
                          onChange={this._handleSelectCityChange}
                        />
                        {errorMessage('city', _errorForm)}
                      </FormGroup>
                    </Col>
                    <Col xs={6} md={6}>
                      <FormGroup className={classNames({'has-danger has-feedback':hasError('postal_code', _errorForm)})}>
                        <Label for="_formPostalCode">ZIP Code*</Label>
                        <Input
                          id="_formPostalCode"
                          name="_postalCode"
                          onChange={this._handleTextChange}
                          value={textValue("_postalCode", this.state)}
                          placeholder="Enter ZIP Code"
                        />
                        {errorMessage('postal_code', _errorForm)}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12}>
                      <FormGroup className={classNames({'has-danger has-feedback':hasError('note', _errorForm)})}>
                        <Label>Note</Label>
                        <Input
                          name="_shippingNote"
                          onChange={this._handleTextChange}
                          value={textValue("_shippingNote", this.state)}
                          type="textarea"
                          placeholder="Notes..."
                        />
                        {errorMessage('note', _errorForm)}
                      </FormGroup>
                    </Col>
                  </Row>

                  {errorMessage('ka_courier',this.state.error)}
                </FormGroup>
              </Col>

              <div className="w-100"></div>
            </Row>
          </Form>
          <span className="text-sm">Fields marked with * are required</span>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" disabled={this.props.isSubmit} onClick={this.onSubmit}>{this.props.isSubmit?'Submitting...':'Submit'}</Button>{' '}
          <Button color="secondary" onClick={this.props.onCancel}>Cancel</Button>
        </ModalFooter>
      </Modal>
    )
  }
}
ModalAddress.propTypes={
}