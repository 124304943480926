import React from 'react';
import { connect } from 'react-redux';
import Loading from 'react-loading-animation';
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button
} from 'reactstrap';
import classNames from 'classnames';
import { FontAwesomeIcon } from '../../components/Icons';
import AlertNotFound from '../../components/Card/AlertNotFound';
import privateView from '../../components/hocs/privateView';
import { getMidtransDetail } from '../../actions/midtransAction';
import {
  FieldRow,
  FieldColumn,
  FieldLabel,
  FieldValue
} from '../../styled/Fields';
import { _getValue, _getFloatValue } from '../../utils/form';
import { transPaymentType, transStatus } from './helpers';  
import Analytics from "../../services/Analytics";

const DEFAULT_ERROR_500_MSG = 'We got something errors. Please ask our staff!';
const DEFAULT_ERROR_404_MSG = 'Sorry, cannot find what you are looking for.';

const CardLoader = () => (
  <Card body>
    <Loading/>
    <p className="text-center">Please wait, fetching your data...</p>
  </Card>
)

const CardError = ({ title, message }) => (
  <Card body>
    <AlertNotFound
      title={title}
      message={message}
    />
    <Button onClick={()=>window.location.reload()}>Try to Refresh</Button>
  </Card>
)

class MidtransDetail extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      _id: parseInt(this.props.match.params.id),
      _isError: false,
      _isLoading: true
    }
  }

  componentDidMount(){
    const { email } = this.props.injectedProps;
    this.props.getDetail(this.state._id);
    Analytics.recordPageView('midtrans_detail', email, {
      url: window.location.href,
      location: this.props.location,
      match: this.props.match
    });
  }

  componentDidUpdate(prevProps){
    if(prevProps.dataMidtrans.isFetch !== this.props.dataMidtrans.isFetch && !this.props.dataMidtrans.isFetch){
      const { error } = this.props.dataMidtrans;
      if(error){
        this.setState({ 
          _isError: true, 
          _errorMessage: error.status_code === 404 ? DEFAULT_ERROR_404_MSG: DEFAULT_ERROR_500_MSG 
        });
      }
      else{
        this.setState({ _isLoading: false });
      }
    }
  }

  render(){
    return(
      <Row className="animated fadeIn">
        <Col xs={12} style={{padding: 0}}>
          {
            this.state._isLoading ?
              <CardLoader/>
            :(
              this.state._isError?
              <CardError
                message={this.state._errorMessage}
              />
              :(
                <Card body>
                  <Row>
                    <Col xs={12} md={6}>
                      <Card>
                        <CardHeader>
                          <FontAwesomeIcon iconType="align-justify"/> General
                        </CardHeader>
                        <CardBody>
                          <FieldRow>
                            <FieldColumn className="col-xs-12 col-md-6">
                              <FieldLabel>Midtrans ID</FieldLabel>
                              <FieldValue>
                                <span>{_getValue(this.props.dataMidtrans.data.id, '-')}</span>
                              </FieldValue>
                            </FieldColumn>
                          </FieldRow>
                          <FieldRow>
                            <FieldColumn className="col-12">
                              <FieldLabel>External ID</FieldLabel>
                              <FieldValue>
                                <span>{_getValue(this.props.dataMidtrans.data.external_id, '-')}</span>
                              </FieldValue>
                            </FieldColumn>
                            <FieldColumn className="col-12">
                              <FieldLabel>Trx ID</FieldLabel>
                              <FieldValue>
                                <span>{_getValue(this.props.dataMidtrans.data.transaction_id, '-')}</span>
                              </FieldValue>
                            </FieldColumn>
                            <FieldColumn className="col-12">
                              <FieldLabel>Trx Token</FieldLabel>
                              <FieldValue>
                                <span>{_getValue(this.props.dataMidtrans.data.transaction_token, '-')}</span>
                              </FieldValue>
                            </FieldColumn>
                            <FieldColumn className="col-xs-12 col-md-6">
                              <FieldLabel>Payment Type</FieldLabel>
                              <FieldValue>
                                <span>{_getValue(transPaymentType(this.props.dataMidtrans.data.payment_type), '-')}</span>
                              </FieldValue>
                            </FieldColumn>
                            <FieldColumn className="col-xs-12 col-md-6">
                              <FieldLabel>Gross Amount</FieldLabel>
                              <FieldValue>
                                <span>{_getFloatValue(this.props.dataMidtrans.data.gross_amount, 0)}</span>
                              </FieldValue>
                            </FieldColumn>
                            <FieldColumn className="col-12">
                              <FieldLabel>Redirect URL</FieldLabel>
                              <FieldValue>
                                <span className="break-all"> { _getValue(this.props.dataMidtrans.data.redirect_url, '-') }</span>
                              </FieldValue>
                            </FieldColumn>
                          </FieldRow>
                          <FieldRow>
                            <FieldColumn className="col-xs-12 col-md-6">
                              <FieldLabel>Status</FieldLabel>
                              <FieldValue>
                                <span>{_getValue(transStatus(this.props.dataMidtrans.data.status, this.props.dataMidtrans.data.transaction_id, this.props.dataMidtrans.data.is_expired), '-')}</span>
                              </FieldValue>
                            </FieldColumn>
                            <FieldColumn className="col-xs-12 col-md-6">
                              <FieldLabel>Status Code</FieldLabel>
                              <FieldValue>
                                <span>{_getValue(this.props.dataMidtrans.data.status_code, '-')}</span>
                              </FieldValue>
                            </FieldColumn>
                            <FieldColumn className="col-12">
                              <FieldLabel>Status Msg</FieldLabel>
                              <FieldValue>
                                <span className="break-all"> { _getValue(this.props.dataMidtrans.data.status_message, '-') }</span>
                              </FieldValue>
                            </FieldColumn>
                          </FieldRow>
                          <FieldRow>
                            <FieldColumn className="col-xs-12 col-md-6">
                              <FieldLabel>Trx Time</FieldLabel>
                              <FieldValue>
                                <span>{_getValue(this.props.dataMidtrans.data.transaction_time, '-')}</span>
                              </FieldValue>
                            </FieldColumn>
                            <FieldColumn className="col-xs-12 col-md-6">
                              <FieldLabel>Expired At</FieldLabel>
                              <FieldValue>
                                <span>{_getValue(this.props.dataMidtrans.data.expired_at, '-')}</span>
                              </FieldValue>
                            </FieldColumn>
                            <FieldColumn className="col-xs-12 col-md-6">
                              <FieldLabel>Created At</FieldLabel>
                              <FieldValue>
                                <span>{_getValue(this.props.dataMidtrans.data.created_at, '-')}</span>
                              </FieldValue>
                            </FieldColumn>
                            <FieldColumn className="col-xs-12 col-md-6">
                              <FieldLabel>Updated At</FieldLabel>
                              <FieldValue>
                                <span>{_getValue(this.props.dataMidtrans.data.updated_at, '-')}</span>
                              </FieldValue>
                            </FieldColumn>
                          </FieldRow>
    
                        </CardBody>
                      </Card>
                    </Col>
                    <Col xs={12} md={6}>
                      <Card>
                        <CardHeader>
                          <FontAwesomeIcon iconType="align-justify"/> Additional info
                        </CardHeader>
                        <CardBody>
                          <FieldRow>
                            <FieldColumn className="col-xs-12 col-md-6">
                              <FieldLabel>Fraud Status</FieldLabel>
                              <FieldValue>
                                <span>{_getValue(this.props.dataMidtrans.data.fraud_status, '-')}</span>
                              </FieldValue>
                            </FieldColumn>
                          </FieldRow>
                          <FieldRow className={classNames({'d-none': this.props.dataMidtrans.data.payment_type !== 'credit_card'})}>
                            <FieldColumn className="col-xs-12 col-md-6">
                              <FieldLabel>Approval Code</FieldLabel>
                              <FieldValue>
                                <span>{_getValue(this.props.dataMidtrans.data.approval_code, '-')}</span>
                              </FieldValue>
                            </FieldColumn>
                            <FieldColumn className="col-xs-12 col-md-6">
                              <FieldLabel>Acquiring bank</FieldLabel>
                              <FieldValue>
                                <span>{_getValue(this.props.dataMidtrans.data.bank, '-')}</span>
                              </FieldValue>
                            </FieldColumn>
                            <FieldColumn className="col-xs-12 col-md-6">
                              <FieldLabel>ECI</FieldLabel>
                              <FieldValue>
                                <span>{_getValue(this.props.dataMidtrans.data.eci, '-')}</span>
                              </FieldValue>
                            </FieldColumn>
                            <FieldColumn className="col-xs-12 col-md-6">
                              <FieldLabel>Card Type</FieldLabel>
                              <FieldValue>
                                <span>{_getValue(this.props.dataMidtrans.data.card_type, '-')}</span>
                              </FieldValue>
                            </FieldColumn>
                            <FieldColumn className="col-xs-12 col-md-6">
                              <FieldLabel>Masked Card</FieldLabel>
                              <FieldValue>
                                <span>{_getValue(this.props.dataMidtrans.data.masked_card, '-')}</span>
                              </FieldValue>
                            </FieldColumn>
                          </FieldRow>
                          <FieldRow className={classNames({'d-none': this.props.dataMidtrans.data.payment_type !== 'bank_transfer'})}>
                            <FieldColumn className="col-xs-12 col-md-6">
                              <FieldLabel>VA Type</FieldLabel>
                              <FieldValue>
                                <span>{_getValue(this.props.dataMidtrans.data.va_type, '-')}</span>
                              </FieldValue>
                            </FieldColumn>
                            <FieldColumn className="col-xs-12 col-md-6">
                              <FieldLabel>VA Number</FieldLabel>
                              <FieldValue>
                                <span>{_getValue(this.props.dataMidtrans.data.va_number, '-')}</span>
                              </FieldValue>
                            </FieldColumn>
                          </FieldRow>
                          <FieldRow>
                            <FieldColumn className="col-xs-12 col-md-6">
                              <FieldLabel>Bill Key</FieldLabel>
                              <FieldValue>
                                <span>{_getValue(this.props.dataMidtrans.data.bill_key, '-')}</span>
                              </FieldValue>
                            </FieldColumn>
                            <FieldColumn className="col-xs-12 col-md-6">
                              <FieldLabel>Bill Code</FieldLabel>
                              <FieldValue>
                                <span>{_getValue(this.props.dataMidtrans.data.bill_code, '-')}</span>
                              </FieldValue>
                            </FieldColumn>
                          </FieldRow>
                          <FieldRow>
                            <FieldColumn className="col-xs-12 col-md-6">
                              <FieldLabel>Ch. Response Code</FieldLabel>
                              <FieldValue>
                                <span>{_getValue(this.props.dataMidtrans.data.channel_response_code, '-')}</span>
                              </FieldValue>
                            </FieldColumn>
                            <FieldColumn className="col-12">
                              <FieldLabel>Ch. Response Msg</FieldLabel>
                              <FieldValue>
                                <span className="break-all">{_getValue(this.props.dataMidtrans.data.channel_response_message, '-')}</span>
                              </FieldValue>
                            </FieldColumn>
                          </FieldRow>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Card>
              )
            )
          }
        </Col>
      </Row>
    )
  }
}
const mapStateToProps= ({midtrans: { detail }, auth: { isFetch, roles, isLogged, id, email }}) => {
	return {
    dataMidtrans: detail,
    guardData: { isFetch, roles, isLogged, id, email }
	}
};
const mapDispatchToProps = (dispatch) => {
	return{
		getDetail:(id) => dispatch(getMidtransDetail(id)),
	}
};
const enhance = connect(mapStateToProps,mapDispatchToProps);
export default enhance(privateView(MidtransDetail));