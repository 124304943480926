import {
  PRODUCT_ADDON_ROUTE_API,
  LIST_PRODUCT_ADDON,
  DETAIL_PRODUCT_ADDON,
  DELETE_PRODUCT_ADDON,
  UPDATE_PRODUCT_ADDON,
  CREATE_PRODUCT_ADDON,
  PRODUCT_ADDON_ERROR,
  PRODUCT_ADDON_REQUEST,
  SEARCH_PRODUCT_ADDON_ITEMS,
  PRODUCT_ADDON_CHILDS_ERROR,
  PRODUCT_ADDON_CHILDS_REQUEST,
  GET_LIST_PRODUCT_ADDON_ITEMS,
  SUBMITING_PRODUCT_ADDON_ITEMS
} from '../constants';
import {
  onRequest,
  onSuccessRequest,
  onFailedRequest
} from './helpers';
import { apiClient } from '../utils/Api';

const requestAction = (reqAction, payloads) => onRequest(PRODUCT_ADDON_REQUEST, reqAction, payloads)

const errorAction = (reqAction, payloads, id = null) => onFailedRequest(PRODUCT_ADDON_ERROR, reqAction, payloads, id)

export const getProductAddonList = q => (
  dispatch => {
    dispatch(requestAction(LIST_PRODUCT_ADDON, true));
    apiClient()
    .get(PRODUCT_ADDON_ROUTE_API, q)
    .then(res => {
      dispatch(onSuccessRequest(LIST_PRODUCT_ADDON, res.data));
      dispatch(requestAction(LIST_PRODUCT_ADDON, false));
    })
    .catch(err => {
      dispatch(errorAction(LIST_PRODUCT_ADDON, err));
      dispatch(requestAction(LIST_PRODUCT_ADDON, false));
    });
  }
)

export const getProductAddonById = id => (
  dispatch => {
    dispatch(requestAction(DETAIL_PRODUCT_ADDON, true));
    apiClient()
    .get(`${PRODUCT_ADDON_ROUTE_API}/${id}`)
    .then(res => {
      dispatch(onSuccessRequest(DETAIL_PRODUCT_ADDON, res.data));
      dispatch(requestAction(DETAIL_PRODUCT_ADDON, false));
    })
    .catch(err => {
      dispatch(errorAction(DETAIL_PRODUCT_ADDON, err, id));
      dispatch(requestAction(DETAIL_PRODUCT_ADDON, false));
    });
  }
)

export const deleteProductAddonById = id => (
  dispatch => {
    dispatch(requestAction(DELETE_PRODUCT_ADDON, true));
    apiClient()
    .delete(`${PRODUCT_ADDON_ROUTE_API}/${id}`)
    .then(res => {
      dispatch(onSuccessRequest(DELETE_PRODUCT_ADDON, res.data));
      dispatch(requestAction(DELETE_PRODUCT_ADDON, false));
    })
    .catch(err => {
      dispatch(errorAction(DELETE_PRODUCT_ADDON, err, id));
      dispatch(requestAction(DELETE_PRODUCT_ADDON, false));
    });
  }
)

export const saveProductAddon = (payload) => {
  const { id, ...body } = payload;
  return id? updateProductAddon(id, body): createProductAddon(body)
}

const updateProductAddon = (id, payload) => (
  dispatch => {
    dispatch(requestAction(UPDATE_PRODUCT_ADDON, true));
    apiClient()
    .put(`${PRODUCT_ADDON_ROUTE_API}/${id}`, payload)
    .then(res => {
      dispatch(onSuccessRequest(UPDATE_PRODUCT_ADDON, res.data));
      dispatch(requestAction(UPDATE_PRODUCT_ADDON, false));
    })
    .catch(err => {
      dispatch(errorAction(UPDATE_PRODUCT_ADDON, err, id));
      dispatch(requestAction(UPDATE_PRODUCT_ADDON, false));
    });
  }
)

const createProductAddon = (payload) => (
  dispatch => {
    dispatch(requestAction(CREATE_PRODUCT_ADDON, true));
    apiClient()
    .post(PRODUCT_ADDON_ROUTE_API, payload)
    .then(res => {
      dispatch(onSuccessRequest(CREATE_PRODUCT_ADDON, res.data));
      dispatch(requestAction(CREATE_PRODUCT_ADDON, false));
    })
    .catch(err => {
      dispatch(errorAction(CREATE_PRODUCT_ADDON, err));
      dispatch(requestAction(CREATE_PRODUCT_ADDON, false));
    });
  }
)

export const getProductAddonItems = (id, query) => (
	dispatch => {
		let actionType = GET_LIST_PRODUCT_ADDON_ITEMS;
		if(query.params.exclude === true){
			actionType = 	SEARCH_PRODUCT_ADDON_ITEMS;
		}
		dispatch(onRequest(
			PRODUCT_ADDON_CHILDS_REQUEST,
			actionType,
			true
		))
    apiClient()
    .get(`${PRODUCT_ADDON_ROUTE_API}/${id}/categories`, query)
		.then(res=>{
			dispatch(onSuccessRequest(actionType, res.data))
			dispatch(onRequest(
				PRODUCT_ADDON_CHILDS_REQUEST,
				actionType,
				false
			))
		})
		.catch(err => {
			dispatch(onFailedRequest(PRODUCT_ADDON_CHILDS_ERROR, actionType, err, { id, query }))
			dispatch(onRequest(
				PRODUCT_ADDON_CHILDS_REQUEST,
				actionType,
				false
			))
		})
	}
)

export const saveProductAddonItems = (operation, id, payload) => (
	dispatch => {
		dispatch(onRequest(
			PRODUCT_ADDON_CHILDS_REQUEST,
			SUBMITING_PRODUCT_ADDON_ITEMS,
			true
		))
		let request = null;
		if(operation === 'attach'){
			request = apiClient().post(`${PRODUCT_ADDON_ROUTE_API}/${id}/categories`, payload)
		} else{ //detach
			request = apiClient().put(`${PRODUCT_ADDON_ROUTE_API}/${id}/categories`, payload)
		}
		request
		.then(res=>{
			dispatch(onSuccessRequest(SUBMITING_PRODUCT_ADDON_ITEMS, res.data))
			dispatch(onRequest(
				PRODUCT_ADDON_CHILDS_REQUEST,
				SUBMITING_PRODUCT_ADDON_ITEMS,
				false
			))
		})
		.catch(err => {
			dispatch(onFailedRequest(PRODUCT_ADDON_CHILDS_ERROR, SUBMITING_PRODUCT_ADDON_ITEMS, err, id));
			dispatch(onRequest(
				PRODUCT_ADDON_CHILDS_REQUEST,
				SUBMITING_PRODUCT_ADDON_ITEMS,
				false
			))
		})
	}
)
