import React, { Component } from 'react';
import {connect} from 'react-redux';
import {
  Row,
  Col,
  Card,
  CardBody,
  CardText
} from 'reactstrap';
import { Link } from 'react-router-dom';
import {Tbl, TblHead, TblBody} from '../../components/Tbl';
import Paginations from '../../components/Paginations';
import PaginationSummaries from '../../components/Paginations/PaginationSummaries';
import { getListReward } from '../../actions/rewardAction';
import { generateNumber } from '../../utils';
import { DEFAULT_SORT_BY, DEFAULT_PER_PAGE } from '../../constants/settings';
import DateToHuman from '../../components/DateToHuman';
import { TextRupiah } from '../../components/Text';
import CheckReadOnly from '../../components/CheckReadOnly';
import FilterContainer from '../../components/Container/FilterContainer/FilterContainer';
import FilterPanel from './Filters';
import { CardHeaderWithToolbars, Title } from '../../components/Card/CardHeaderWithToolbars';
import Toolbars from './Filters/Toolbars'
import privateView from '../../components/hocs/privateView';
import Analytics from "../../services/Analytics";

const dataHeader=[
  'No.','User', 'Voucher Code', 'Sales Count', 'Sales Value', 'Updated At', 'Action'
];

const defaultParams = {
  sort_by : DEFAULT_SORT_BY,
  per_page : DEFAULT_PER_PAGE
}

class RewardIndex extends Component {
  constructor(props){
    super(props);
    this.state={
      _params:{
        ...defaultParams
      },
      _filterOpen:false
    }
    this._handleGoto=this._handleGoto.bind(this);
    this._handleRefresh = this._handleRefresh.bind(this);
    this._handleToggleFilterPanel = this._handleToggleFilterPanel.bind(this);
    this._handleParamsChange = this._handleParamsChange.bind(this);
    this._handleParamsChangeAndSubmit = this._handleParamsChangeAndSubmit.bind(this);
    this._handleResetFilter = this._handleResetFilter.bind(this);
  }

  componentWillMount() {
    const { email } = this.props.injectedProps;
    this._getList(this.state._params);
    Analytics.recordPageView('reward_list', email, {
      url: window.location.href,
      location: this.props.location,
      match: this.props.match
    });
  }
  _onRenderRowItem(){
    if(this.props.reward.list.status_code===200 && this.props.reward.list.data.length>0)
    {
      const { current_page, per_page } = this.props.reward.list;
      return this.props.reward.list.data.map((item, index)=>
        (
          <tr key={generateNumber(per_page,current_page,index)}>
            <th className="v-center" scope="row">{ generateNumber(per_page,current_page,index) }</th>	
            <td className="v-center">{ item.user.email }</td>
            <td className="v-center">{ item.voucher.code }</td>
            <td className="v-center">{ item.quantity }</td>
            <td className="v-center"><TextRupiah prefix="Rp. " value={parseInt(item.value)}/></td>
            <td className="v-center">{ <DateToHuman value={item.updated_at} id={`tooltip_${generateNumber(per_page,current_page,index)}`}/> }</td>
            <td className="v-center">
              <Link to={`/rewards/${item.id}`} className="btn btn-link">Details</Link>
            </td>
          </tr>

        )
      )
    }
    return null;
  }

  _onRenderPagination(){
    const { status_code,data } = this.props.reward.list;
    const hasData = (status_code===200 && data.length) || null;
    return hasData && (
      <Paginations 
        size="sm" 
        lastPage={this.props.reward.list.last_page} 
        perPage={this.props.reward.list.per_page}
        total={this.props.reward.list.total}
        maxPage={8}
        path={this.props.reward.list.path}
        currentPage={this.props.reward.list.current_page} 
        prevPageUrl={this.props.reward.list.prev_page_url} 
        nextPageUrl={this.props.reward.list.next_page_url} 
        handleGotoCallback={this._handleGoto}
      />
    )
  }

  /**
   * Handle callback when user click pagination items
   * @author pewe
   * @param {integer} page 
   */
  _handleGoto(page){
    const _params=this.state._params;
    _params.page=page;
    this.setState({_params},()=>this._getList(this.state._params));
  }

  _toggleFilterPanel(){
    this.setState({_filterOpen:!this.state._filterOpen})
  }

  _handleToggleFilterPanel(e){
    e.preventDefault();
    this._toggleFilterPanel();
  }

  _handleRefresh(e){
    e.preventDefault();
    this._refreshList();
  }

  _getList(rawParams = {} ){
    let params = {};
    Object.keys(rawParams)
    .filter(key => rawParams[key]!='')
    .forEach(key=> params[key] = rawParams[key]);
    this.props.getList({params});
  }

  _refreshList(){
    const {page, ..._params} = this.state._params;
    this.setState({
      _params
    },()=>this._getList(this.state._params));
  }

  _handleParamsChange(e){
    const { _params } = this.state;
    _params[e.target.name] = e.target.value;
    this.setState({_params});
  }

  _handleParamsChangeAndSubmit(e){
    // Exclude page...
    const { page , ..._params } = this.state._params;
    _params[e.target.name] = e.target.value;
    this.setState({_params},()=>{
      this._getList(this.state._params);
    });    
  }

  _handleResetFilter(e){
    e.preventDefault();
    const newState = this.state._filterOpen?{_filterOpen:!this.state._filterOpen}:{};
    this.setState({
      ...newState,
      _params:{
        ...defaultParams
      }
    },()=>this._getList(this.state._params));
  }

  render() {
    return(
      <div className="animated fadeIn">
        <Row>
          <Col xs={12}>
            <Card>
              <CardHeaderWithToolbars>
                <Title>
                  <i className="fa fa-align-justify"></i>
                  <span>Reward List</span>
                </Title>
                <Toolbars                  
                  isOpen={this.state._filterOpen}
                  onToggleFilter={this._handleToggleFilterPanel}
                  onRefreshList={this._handleRefresh}
                  onResetFilter={this._handleResetFilter}
                />
              </CardHeaderWithToolbars>
              <FilterContainer>
                <FilterPanel                  
                  {...this.state._params}
                  onHandleChange={this._handleParamsChange}
                  onHandleSearch={this._handleRefresh}
                  onHandleChangeAndSubmit={this._handleParamsChangeAndSubmit}
                  isOpen={this.state._filterOpen}
                />
              </FilterContainer>
              <CardBody>
                <CardText>
                  In this section will display our reward.
                </CardText>
                <div className="table-responsive">
                  <PaginationSummaries
                    from={this.props.reward.list.from}
                    to={this.props.reward.list.to}
                    total={this.props.reward.list.total}
                  />
                  <Tbl>
                    <TblHead
                        dataHeader={dataHeader}
                    />
                    <TblBody
                      isLoad={this.props.reward.isFetch}
                      hasRow={this.props.reward.list.status_code===200&&this.props.reward.list.data.length>0}
                      columnCount={dataHeader.length}
                    >
                      { this._onRenderRowItem() }
                    </TblBody>
                  </Tbl>
                </div>
                { this._onRenderPagination() }
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    )
  }
}
const mapStateToProps=({reward, auth: { isFetch, roles, isLogged, id, email }})=>{
	return {
    reward,
    guardData: { isFetch, roles, isLogged, id, email }
	}
}
const mapDispatchToProps=(dispatch)=>
{
	return {
		getList:(filter)=>dispatch(getListReward(filter)),
	}
}
const enhance = connect(mapStateToProps,mapDispatchToProps);
export default enhance(privateView(RewardIndex))