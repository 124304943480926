import React, { PureComponent as  Component } from 'react';
import {
  Form, 
  FormGroup,
  Label,
  Input } from 'reactstrap';
import propTypes from 'prop-types';

import { perPageOptions } from '../../../constants/kickavenue'

const sortOptions = [{
    value:"" , label:"Default"
  },
  {
    value:"importants" , label:"Important"
  },
  {
    value:"createdAt_desc" , label:"Newest"
  },
  {
    value:"createdAt_asc" , label:"Older"
  },
  {
    value:"updatedAt_desc" , label:"Last Updated"
  },
  {
    value:"price_desc" , label:"Highest Price"
  },
  {
    value:"price_asc" , label:"Lowest Price"
  },
  {
    value:"product_asc" , label:"Product A-Z"
  },
  {
    value:"product_desc" , label:"Product Z-A"
  }];


export default class SortForm extends Component{
  render(){
    return(
      <Form inline className="form-filter">
          <FormGroup className="mb-2 mr-3">
              <Label className="mr-sm-3">
                Show
              </Label>
              <Input
                type="select"
                name="perPage"
                onChange={this.props.onInputChange}
                value={this.props.perPage}
              >
                {perPageOptions.map((option,i)=><option value={option.value} key={i}>{option.label}</option>)}
              </Input>
          </FormGroup>
          <FormGroup className="mb-2 mr-3">
              <Label className="mr-sm-3">
                Sort By
              </Label>
              <Input
                type="select"
                name="sortBy"
                onChange={this.props.onInputChange}
                value={this.props.sortBy}
              >
                {sortOptions.map((option,i)=><option value={option.value} key={i}>{option.label}</option>)}
              </Input>
          </FormGroup>
      </Form>
    )
  }
}

SortForm.propTypes= {
  perPage: propTypes.oneOfType([propTypes.number, propTypes.string]),
  sortBy: propTypes.string,
  onInputChange: propTypes.func.isRequired
}

SortForm.defaultProps= {
  perPage: 15,
  sortBy: ''
}