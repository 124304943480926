import { BANK_PROMO_SUBTITLE_MAX_CHAR } from "../../constants/kickavenue";

export function mapStringToSelectOption(strCommaSeparated = "") {
  return strCommaSeparated.split(",").map((i) => ({ id: i, name: i }));
}

export function hasError({ field, touched, errors }) {
  return touched[field] && errors[field];
}

export function removeHashTagFromSelectedTag(selectedTags) {
  return selectedTags?.map((tag) => ({
    id: tag?.id?.replace(/#/gm, ""),
    name: tag?.name?.replace(/#/gm, ""),
  }));
}

export function getValidSubtitleText(subtitle) {
  if (subtitle?.length > BANK_PROMO_SUBTITLE_MAX_CHAR) {
    return subtitle.substring(0, BANK_PROMO_SUBTITLE_MAX_CHAR);
  }

  return subtitle;
}
