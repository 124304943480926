import React from 'react';
import { connect } from 'react-redux';
import {
  Modal,
  ModalHeader,
  ModalBody,
	Button,
	Label,
  Input,
  Form,
	FormGroup,
  ModalFooter
} from 'reactstrap';
import classNames from 'classnames';
import { TextRupiah } from '../../components/Text';
import { setFollowUpSale } from '../../actions/saleAction';
import { isNumeric } from '../../helpers/regex';
import { errorMessage } from '../../utils/form';

const compensationAmountList = [
  { name: "Rp. 0", value: 0 },
  { name: "Rp. 50.000", value: 50000 },
  { name: "Rp. 100.000", value: 100000 },
  { name: "Rp. 150.000", value: 150000 },
  { name: "Rp. 200.000", value: 200000 },
  { name: "Rp. 250.000", value: 250000 },
  { name: "Rp. 300.000", value: 300000 },
  { name: "Rp. 350.000", value: 350000 },
  { name: "Rp. 400.000", value: 400000 },
  { name: "Rp. 450.000", value: 450000 },
  { name: "Rp. 500.000", value: 500000 },
  { name: "Rp. 550.000", value: 550000 },
  { name: "Rp. 600.000", value: 600000 },
  { name: "Rp. 650.000", value: 650000 },
  { name: "Rp. 700.000", value: 700000 },
  { name: "Rp. 750.000", value: 750000 },
  { name: "Rp. 800.000", value: 800000 },
  { name: "Rp. 850.000", value: 850000 },
  { name: "Rp. 900.000", value: 900000 },
  { name: "Rp. 950.000", value: 950000 },
  { name: "Rp. 1.000.000", value: 1000000 }
]


class ModalFollowUp extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      status: null,
      compensation: '',
      error: null,
      isSubmit: false
    }
  }

  componentDidUpdate(prevProps) {
    if(prevProps.isOpen !== this.props.isOpen && this.props.isOpen){
      const { data: { compensation, status } } = this.props;
      this.setState({
        compensation: compensation? Number(compensation): '',
        status: status? status: 'SET_FOLLOW',
        error: null,
        isSubmit: false
      })
    }
  }

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  _validateForm = async() => {
    let errors = {};
    const { compensation } = this.state;
    if(compensation !== '' && !isNumeric(compensation)){
      errors = {
        ...errors,
        compensation: ['The compensation amount field must be a number.']
      }
    }
    let p = null;
    if(Object.keys(errors).length){
      p = { errors: { ...errors }}
    }
    await this.setState({
      error: p
    })
  }

  _renderErrorMessage = name => {
    const { error } = this.state;
    return errorMessage(name, error)
  }

  onSubmit = async (e) => {
    e.preventDefault();
    await this._validateForm();
    const { error } = this.state;
    if(error) return;
    const payloads = {
      compensation_amount: this.state.compensation? Number(this.state.compensation): 0,
    }
    if(this.state.status && this.state.status !== 'SET_FOLLOW') payloads.status = this.state.status;
    const isUpdate = payloads.status? true: false;
    this.props.setFollowUpSale(this.props.data.id, payloads, isUpdate)
    this.props.toggle();
  }

  onDeleteClick = e => {
    e.preventDefault();
    const payloads = {
      status: 'DELETED',
    }
    this.props.setFollowUpSale(this.props.data.id, payloads, true)
    this.props.toggle();
  }

  render(){
    return(
      <Modal size="md" className="modal-confirmation" isOpen={this.props.isOpen} toggle={this.props.toggle}>
        <Form onSubmit={this.onSubmit}>
          <ModalHeader toggle={this.props.toggle} className="modal-header--left">
            Follow up {this.props.data && this.props.data.status !== 'SET_FOLLOW'? (
              <a
                href="#"
                className="text-danger"
                style={{textDecoration: 'underline', color: '#4a4a4a', fontSize: '.75rem'}}
                onClick={this.onDeleteClick}
              >
                <i className="fa fa-trash"></i> Delete
              </a>
            ): null}
          </ModalHeader>
          <ModalBody>
            {this.props.data?(
              <p>
                <b>{this.props.data.invoiceNumber}</b><br/>
                <span style={{fontSize: '.75rem'}}>
                  User: {this.props.data.user}
                </span><br/>
                <span style={{fontSize: '.75rem'}}>
                  Seller: {this.props.data.seller}
                </span><br/>
                Give buyer a compensation when seller goods not match perfect with seller upload. <br/>
                When approved this proposal it will be transfer from seller's balance into buyer's balance.
                {' '}<strong>Info</strong>:
                <ul>
                  <li>You can set "follow-up" the sale that not disbursed before.</li>
                  <li>When set follow-up first, it still on pending. You can set approved, rejected or deleted it.</li>
                  <li>Check in "Follow Up" page to monitor all follow up sales.</li>
                  <li>Approved before completed sales, it will be process when completing the sales.</li>
                  <li>Approved after completed sales, it will be process immediately.</li>
                </ul>
              </p>
            ):null}
            {/* <FormGroup className={classNames({'has-danger has-feedback': this.state.error !== null})}>
              <Label>Compensation</Label>
              <Input
                type="text"
                placeholder="Compensation amount..."
                name="compensation"
                value={this.state.compensation}
                onChange={this.handleChange}
              />
              { this._renderErrorMessage('compensation') }
            </FormGroup> */}
            <FormGroup className={classNames({'has-danger has-feedback': this.state.error !== null})}>
              <Label>Compensation</Label>
              <Input
                type="select"
                placeholder="Compensation amount..."
                name="compensation"
                value={this.state.compensation}
                onChange={this.handleChange}
              >
                <option selected value="">Pilih (optional)</option>
                {compensationAmountList.map(c => (
                  <option value={c.value}>{c.name}</option>
                ))}
              </Input>
              { this._renderErrorMessage('compensation') }
            </FormGroup>
            <FormGroup className={classNames({'has-danger has-feedback': this.state.error !== null, 'd-none': this.state.status === 'SET_FOLLOW'})}>
              <Label>Status</Label>
              <Input
                type="select"
                name="status"
                value={this.state.status}
                onChange={this.handleChange}
              >
                <option value="PENDING">Pending</option>
                <option value="APPROVED">Approved</option>
                <option value="REJECTED">Rejected</option>
              </Input>
              { this._renderErrorMessage('status') }
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.props.toggle}>Cancel</Button>
            <Button color="primary" type="submit">Save</Button>
          </ModalFooter>
        </Form>
      </Modal>
    )
  }
}
const mapStateToProps = ({ sale }) => ({
  sale
})

const mapDispatchToProps = (dispatch) => ({
  setFollowUpSale: (id, payload, isUpdate) => dispatch(setFollowUpSale(id, payload, isUpdate)),
})

export default connect (mapStateToProps, mapDispatchToProps) (ModalFollowUp);
