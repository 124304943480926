import React from 'react';
import propTypes from 'prop-types';
import Select from 'react-select';

import api from '../../../../utils/Api';
import { AUTH_TOKEN_KEY } from '../../../../constants';

class SelectVoucherInstoreAsync extends React.Component {
  constructor(props) {
    super(props);
    this._loadOptions = this._loadOptions.bind(this);
  }

  _loadOptions(input) {
    if (!this.props.userId) return Promise.resolve({ options: [] });
    const myToken = localStorage.getItem(AUTH_TOKEN_KEY);
    const headers = {
      'Authorization': `Bearer ${myToken}`
    };
    const params = {
      keyword: input,
      ...this.props.paramsApi
    }
    return api(headers).get(`/admins/vouchers/in_store/${this.props.userId}`, { params })
      .then(({ data }) => {
        const products = data.data.data.map(item => {
          return item.voucher
        })
        return { options: products };
      });
  }

  render() {
    return (
      <div>
        <Select.Async
          valueKey="id"
          labelKey="code"
          ref={this.props.id}
          loadOptions={this._loadOptions}
          defaultOptions
          cacheOptions
          id={this.props.id}
          name={this.props.name}
          value={this.props.value}
          placeholder={this.props.placeholder}
          noResultsText={this.props.noResultsText}
          onChange={this.props.onSelectChange}
        />
      </div>
    )
  }
}

export default SelectVoucherInstoreAsync;

SelectVoucherInstoreAsync.defaultProps = {
  value: null,
  placeholder: 'Select a voucher...',
  noResultsText: 'No Result!',
  paramsApi: {},
}

SelectVoucherInstoreAsync.propTypes = {
  id: propTypes.string.isRequired,
  name: propTypes.string,
  value: propTypes.oneOfType([
    propTypes.object,
    propTypes.array
  ]),
  userId: propTypes.number.isRequired,
  placeholder: propTypes.string,
  noResultsText: propTypes.string,
  paramsApi: propTypes.object,
  onSelectChange: propTypes.func,
  defaultOptions: propTypes.oneOfType([
    propTypes.object,
    propTypes.array
  ]),
}
