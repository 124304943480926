import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Alert,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Row,
  Col,
} from "reactstrap";
import privateView from "../../../../components/hocs/privateView";
import uuidv4 from "uuid/v4";
import classnames from "classnames";
import "react-select/dist/react-select.css";
import UploadImages from "./UploadImages";
import {
  hasError,
  textValue,
  checkChange,
} from "../../../../utils/form";
import { ValidationMessage } from "../../../../components/Form/ValidationMessage";
import {
  createSpinWheelPrize,
  deleteSpinWheelPrize,
  getDetailSpinWheelPrize,
  updateSpinWheelPrize,
} from "../../../../actions/spinningWheelAction";
import SelectVoucher from "../../../../components/Form/Select/Async/SelectVoucher";

const initialState = {
  id: "",
  _images: [],
  _form: {
    spin_id: "",
    name: "",
    probability: "",
    limit: "",
    color: "",
    voucher: null,
    image_url: "",
    image: "",
    binding: false,
    active: false,
    default: false,
  },
  bindDetail: false,
  formSubmit: false,
  error: null,
  success: null,
  _modalDelete: false,
  isDeleting: false,
};

class ModalPrize extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...initialState,
    };

    this.handleCheckedChange = this.handleCheckedChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeImage = this.handleChangeImage.bind(this);
    this._handleDropzoneOnDrop = this._handleDropzoneOnDrop.bind(this);
    this._handleRemoveImage = this._handleRemoveImage.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onCloseModal = this.onCloseModal.bind(this);
    this._resetForm = this._resetForm.bind(this);
    this.toggleModalDelete = this.toggleModalDelete.bind(this);
    this.onDelete = this.onDelete.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { edit, spinWheelId, prizeId, spinningWheel, isOpen } = this.props;
    const { prize } = spinningWheel;
    const { prize: prevPrize } = prevProps.spinningWheel;

    if (spinWheelId && !this.state._form.spin_id) {
      this.setState({
        _form: {
          ...this.state._form,
          spin_id: spinWheelId,
        },
      });
    }

    if(prevProps.isOpen && !isOpen){
      this.setState({
        bindDetail: false,
        _form: {
          ...this.state._form,
          binding: false
        }
      })
    }

    if (edit && !prevProps.isOpen && isOpen) {
      if (prizeId !== null && !this.state.bindDetail) {
        this.props.getDetail(spinWheelId, prizeId);
        this.setState({
          bindDetail: true,
          id: prizeId,
          _form: {
            ...this.state._form,
            binding: false,
          },
        });
      }
    }

    if (
      prevPrize.isFetch && !prize.isFetch &&
      prize.status_code === 200 &&
      prize.error === null &&
      !this.state._form.binding
    ) {
      const { voucher } = prize.detail
      this.setState({
        ...this.state,
        _form: {
          ...this.state._form,
          ...prize.detail,
          voucher: voucher ? {
            id: voucher.id, code: voucher.code
          } : null,
          binding: true,
        },
      });
    }

    if (prevPrize.isSubmit && !prize.isSubmit && this.state.formSubmit) {
      if (this.state.isDeleting) {
        this.setState({ isDeleting: false });
        this.toggleModalDelete();
      }
      this.setState({
        formSubmit: !this.state.formSubmit,
        success: prize.success,
        error: prize.error,
      });
    }
  }

  _handleRemoveImage(e, imageIdentifier) {
    e.preventDefault();
    const { _images } = this.state;
    this.setState({
      _images: _images.filter(
        ({ identifier }) => identifier !== imageIdentifier
      ),
      _form: {
        ...this.state._form,
        image: "",
      }
    });
  }

  handleChange(e) {
    const newState = { ...this.state._form };
    newState[e.target.name] = e.target.value;
    this.setState({ _form: newState });
  }

  handleChangeImage = (event) => {
    const newState = { ...this.state._form };
    if (event.target.files && event.target.files[0]) {
      let img = event.target.files[0];
      newState[event.target.name] = img;
      this.setState({ _form: newState });
    }
  };

  handleCheckedChange(e) {
    const _form = checkChange(e, this.state._form);
    this.setState({ _form: { ..._form } });
  }

  validateError(name) {
    if (this.state.error && this.hasError(name)) {
      let errorList = this.props.spinningWheel.prize.error.errors;
      return <ValidationMessage message={errorList[name][0]} />;
    }
    return "";
  }

  hasError(name) {
    if (this.state.error && this.props.spinningWheel.prize.error) {
      if (this.props.spinningWheel.prize.error.errors) {
        let errorList = this.props.spinningWheel.prize.error.errors;
        let errList = Object.keys(errorList).filter((key) => key == name);
        if (errList.length) return true;
      }
    }
    return false;
  }

  _handleDropzoneOnDrop(files) {
    if (files.length) {
      const { _images } = this.state;
      const droppedImages = files.map((file) => {
        const identifier = uuidv4();
        return {
          id: null,
          url: null,
          storageName: "in-local",
          status: false,
          isUploading: false,
          fileInput: file,
          updatedAt: null,
          identifier,
        };
      });
      this.setState({ _form: {...this.state._form, image: files[0]} })
      const clonedImages = [..._images, ...droppedImages];
      this.setState({ _images: clonedImages });
    }
  }

  handleCheckedChange(e) {
    const _form = checkChange(e, this.state._form);
    this.setState({ _form: { ..._form } });
  }

  onSubmit(e) {
    e.preventDefault();
    const { id } = this.state;
    this.setState({ formSubmit: true, success: null, error: null }, () => {
      const {
        spin_id,
        voucher,
        name,
        probability,
        limit,
        color,
        image_url,
        image,
        active,
      } = this.state._form;
      if (this.props.edit) {
        this.props.update(spin_id, id, {
          voucher_id: voucher !== null ? voucher.id : "",
          name,
          probability,
          limit,
          color,
          image_url,
          active,
          default: this.state._form.default,
        });
      } else {
        const form = new FormData();
        form.set("spinning_wheel_id", spin_id);
        form.set("voucher_id", voucher !== null ? voucher.id : "");
        form.set("name", name);
        form.set("probability", probability);
        form.set("limit", limit);
        form.set("color", color);
        form.set("image", image);
        this.props.create(form);
      }
    });
  }

  onDelete(e) {
    e.preventDefault();
    const { id, _form } = this.state;
    if (id && _form.spin_id) {
      this.setState({ formSubmit: true, isDeleting: true }, () => {
        this.props.delete(_form.spin_id, id);
      });
    }
  }

  toggleModalDelete() {
    const { _modalDelete } = this.state;
    this.setState({ _modalDelete: !_modalDelete });
  }

  onCloseModal() {
    this._resetForm();
    const { success } = this.state;
    this.props.onCancel(success !== null);
  }

  _resetForm() {
    this.setState({ ...initialState });
  }

  onSelectChange(val, attributeForm) {
    const { _form } = this.state;
    _form[attributeForm] = val;
    this.setState({ _form });
  }

  renderLoading() {
    return (
      <div className="row">
        <div className="col-sm-12">
          <Alert color="info" className="text-center">
            Getting all data...
          </Alert>
        </div>
      </div>
    );
  }

  renderInfo() {
    let infoMessage = [];
    const { success, error } = this.state;

    if (this.props.spinningWheel.prize.isSubmit)
      infoMessage.push(
        <Alert color="info" key="info">
          <strong>Heads up!</strong> Submitting...
        </Alert>
      );
    if (success)
      infoMessage.push(
        <Alert color="success" key="success">
          <strong>Well done!</strong> {success}
        </Alert>
      );
    if (error !== null) {
      const { status_code, message } = this.props.spinningWheel.prize.error;
      switch (status_code) {
        case 422:
          infoMessage.push(
            <Alert color="danger" key="error">
              <strong>Oh Snap!</strong> Please fullfill your form.{" "}
            </Alert>
          );
          break;
        case 404:
          infoMessage.push(
            <Alert color="danger" key="error">
              <strong>Oh Snap!</strong> Resource not found.{" "}
            </Alert>
          );
          break;
        default:
          infoMessage.push(
            <Alert color="danger" key="error">
              <strong>Oh Snap!</strong> {message}
            </Alert>
          );
          break;
      }
    }
    return (
      <div className="row">
        <div className="col-sm-12">{infoMessage}</div>
      </div>
    );
  }

  render() {
    return (
      <Modal
        isOpen={this.props.isOpen}
        toggle={this.onCloseModal}
        className="modal-dialog modal-md"
      >
        <Modal
          isOpen={this.state._modalDelete}
          toggle={this.toggleModalDelete}
          className="modal-dialog modal-sm"
        >
          <ModalHeader toggle={this.toggleModalDelete}>
            Confirmation
          </ModalHeader>
          <ModalBody>
            {this.state.isDeleting &&
            this.props.spinningWheel.prize.isSubmit ? (
              <div className="row">
                <div className="col-sm-12">
                  <Alert color="info" className="text-center">
                    Deleting...
                  </Alert>
                </div>
              </div>
            ) : (
              <span>Are you sure to delete this?</span>
            )}
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.onDelete}>
              Yes
            </Button>{" "}
            <Button color="secondary" onClick={this.toggleModalDelete}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
        <Form onSubmit={this.onSubmit} encType="multipart/form-data">
          <ModalHeader toggle={this.onCloseModal}>
            {this.props.edit ? "Edit Prize" : "Add Prize"}
          </ModalHeader>
          <ModalBody>
            {this.renderInfo()}
            {this.props.edit && this.props.spinningWheel.prize.isFetch ? (
              this.renderLoading()
            ) : (
              <>
                <Row>
                  <Col xs={12} md={6}>
                    <FormGroup
                      className={
                        this.hasError("voucher_id")
                          ? " has-danger has-feedback"
                          : ""
                      }
                    >
                      <Label for="formVoucher">Voucher</Label>
                      <SelectVoucher
                        id="formVoucher"
                        name="voucher_id"
                        defaultOptions
                        id='_formSelectVoucher'
                        ref='selectVoucher'
                        paramsApi={{ sort_by: 'createdAt_desc' }}
                        placeholder="Select a voucher..."
                        noResultsText="Cannot find voucher."
                        value={this.state._form.voucher || null}
                        onSelectChange={(val) => {
                          this.onSelectChange(val, "voucher");
                        }}
                      />
                      {this.validateError("voucher_id")}
                    </FormGroup>
                  </Col>
                  <Col xs={12} md={6}>
                    <FormGroup
                      className={
                        this.hasError("name") ? " has-danger has-feedback" : ""
                      }
                    >
                      <Label for="formName">Prize Name</Label>
                      <Input
                        type="text"
                        id="formName"
                        name="name"
                        placeholder="Enter a prize name"
                        value={textValue("name", this.state._form)}
                        onChange={this.handleChange}
                      />
                      {this.validateError("name")}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={6}>
                    <FormGroup
                      className={
                        this.hasError("probability")
                          ? " has-danger has-feedback"
                          : ""
                      }
                    >
                      <Label for="formProbability">
                        Probability (in percent)
                      </Label>
                      <Input
                        type="number"
                        id="formProbability"
                        name="probability"
                        placeholder="Enter probability"
                        value={textValue("probability", this.state._form)}
                        onChange={this.handleChange}
                      />
                      {this.validateError("probability")}
                    </FormGroup>
                  </Col>
                  <Col xs={12} md={6}>
                    <FormGroup
                      className={
                        this.hasError("limit") ? " has-danger has-feedback" : ""
                      }
                    >
                      <Label for="formLimit">Limit</Label>
                      <Input
                        type="number"
                        id="formLimit"
                        name="limit"
                        placeholder="Enter limit"
                        value={textValue("limit", this.state._form)}
                        onChange={this.handleChange}
                      />
                      {this.validateError("limit")}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  {this.props.edit && (
                    <Col xs={12} md={6}>
                      <FormGroup
                        className={
                          this.hasError("active")
                            ? " has-danger has-feedback"
                            : ""
                        }
                      >
                        <Label for="formPrizeActive">
                          Set to Active &nbsp;
                        </Label>
                        <label className="switch switch-text switch-success-outline-alt">
                          <input
                            type="checkbox"
                            className="switch-input"
                            name="active"
                            id="formPrizeActive"
                            value="true"
                            checked={textValue("active", this.state._form)}
                            onChange={this.handleCheckedChange}
                          />
                          <span
                            className="switch-label"
                            data-on="On"
                            data-off="Off"
                          ></span>
                          <span className="switch-handle"></span>
                        </label>
                        {this.validateError("active")}
                      </FormGroup>
                    </Col>
                  )}
                  <Col xs={12} md={6}>
                    <FormGroup
                      className={
                        this.hasError("color") ? " has-danger has-feedback" : ""
                      }
                    >
                      <Label for="formColor">Color</Label>
                      <Input
                        type="text"
                        id="formColor"
                        name="color"
                        placeholder="Enter a color"
                        value={textValue("color", this.state._form)}
                        onChange={this.handleChange}
                      />
                      {this.validateError("color")}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  {this.props.edit && (
                    <Col>
                      <FormGroup
                        className={
                          this.hasError("default")
                            ? " has-danger has-feedback"
                            : ""
                        }
                      >
                        <Label for="formPrizeDefault">
                          Set to Default &nbsp;
                        </Label>
                        <label className="switch switch-text switch-success-outline-alt">
                          <input
                            type="checkbox"
                            className="switch-input"
                            name="default"
                            id="formPrizeDefault"
                            value="true"
                            checked={textValue("default", this.state._form)}
                            onChange={this.handleCheckedChange}
                          />
                          <span
                            className="switch-label"
                            data-on="On"
                            data-off="Off"
                          ></span>
                          <span className="switch-handle"></span>
                        </label>
                        {this.validateError("default")}
                      </FormGroup>
                    </Col>
                  )}
                  <Col>
                    {this.props.edit ? (
                      <FormGroup
                        className={classnames({
                          "has-danger has-feedback": hasError(
                            "image_url",
                            this.state.error
                          ),
                        })}
                      >
                        <Label for="formImageUrl">Image Url</Label>
                        <Input
                          type="textarea"
                          id="formImageUrl"
                          name="image_url"
                          placeholder="Enter image url"
                          value={textValue("image_url", this.state._form)}
                          onChange={this.handleChange}
                        />
                        {this.validateError("image_url")}
                      </FormGroup>
                    ) : (
                      <>
                        <UploadImages
                          onDrop={this._handleDropzoneOnDrop}
                          images={this.state._images}
                          onRemoveImage={this._handleRemoveImage}
                        />
                      </>
                    )}
                  </Col>
                </Row>
              </>
            )}
          </ModalBody>

          <ModalFooter>
            {this.props.edit && this.state.id && (
              <Button
                color="danger"
                onClick={this.toggleModalDelete}
                disabled={this.props.spinningWheel.prize.isFetch}
              >
                Delete
              </Button>
            )}
            <Button
              type="submit"
              color="primary"
              disabled={this.props.spinningWheel.prize.isFetch}
              onClick={this.onSubmit}
            >
              {this.state.isSubmit ? "Submitting..." : "Submit"}
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    );
  }
}

const mapStateToProps = ({
  user,
  auth: { isFetch, isLogged, roles, id, email },
  voucher,
  spinningWheel,
}) => {
  return {
    user,
    email,
    guardData: { isFetch, isLogged, roles, id, email },
    voucher,
    spinningWheel,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getDetail: (spin_id, id) => dispatch(getDetailSpinWheelPrize(spin_id, id)),
    update: (spin_id, id, payload) =>
      dispatch(updateSpinWheelPrize(spin_id, id, payload)),
    create: (payload) => dispatch(createSpinWheelPrize(payload)),
    delete: (spin_id, id) => dispatch(deleteSpinWheelPrize(spin_id, id)),
  };
};

const enhance = connect(mapStateToProps, mapDispatchToProps);
export default enhance(privateView(ModalPrize));
