import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { 
  Alert,
  Form,
  Label,
  Col, 
  Row,
  Input,
  FormGroup,
  ButtonGroup,
  Button,
  Card,
  CardBody,
  CardHeader,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'reactstrap';
import Select from 'react-select';
import 'react-select/dist/react-select.css';
import classnames from 'classnames';
import { 
  getDetailMenu,
  saveMenu,
  deleteMenu,
  getMenuList,
  newMenu,
  moveMenu
} from '../../actions/menuAction';
import { textValue, checkChange } from '../../utils/form';
import { getOptionList as getCategoryOptionList } from '../../actions/categoriesAction';
import { ValidationMessage } from '../../components/Form/ValidationMessage';

const moveToOptItem=(item,_lft)=>{
  return {
    value:item.id,
    label:`${item._lft>_lft?'After ':'Before '}${item.title}`,
    after:(item._lft>_lft)
  }
}

class MenuForm extends Component{
  constructor(props){
    super(props);
    this.state={
      _edit:this.props.edit,
      _hasId:this.props.match.params.id?true:false,
      _parentMenu:[{'value':'',label:'As Root'}],
      _form:{
        binding:false,
        parent:'',
        category_id:null,
        id:this.props.match.params.id,
        title:'',
        url:'',
        is_active: false,
        created_at:'',
        updated_at:''
      },
      _changeForm:{
        newPosition:0,
        after:false
      },
      _errors:[],
      _success:[],
      _images:{},
      _formSubmit:false,
      _changeFormSubmit:false,
      _fetchParentMenusSelect:false,
      _modalDelete:false
    }
    this.onDelete=this.onDelete.bind(this);
    this.toggle=this.toggle.bind(this);
    this.onSubmit=this.onSubmit.bind(this);
    this.handleChange=this.handleChange.bind(this);
    this.handleCheckedChange=this.handleCheckedChange.bind(this);
  }

    componentDidMount()
    {
      if(this.state._hasId)
        this.props.find(this.state._form.id);
      else
      {
        this.props.new();          
        this.props.getMenuList();
        this.setState({_fetchParentMenusSelect:true});  
      }
      this.props.bindCategoryOption();
    }

    componentDidUpdate(prevProps)
    {
      const { isFetch, isSubmit, error, list, detail, success } = this.props.menu;
      if((isFetch!=prevProps.menu.isFetch&&!isFetch)||(isSubmit&&prevProps.menu.isSubmit)){
        if(this.state._hasId){
          if(detail.status_code===200&&error===null){
            if(!this.state._form.binding){
              const _form = { ...this.state._form };
              _form.binding=true;
              this.setState({_fetchParentMenusSelect:true});
              this.setState({_form}, ()=>{
                const _newForm = {
                  ...this.state._form,
                  ...detail,
                  url:detail.url?detail.url:'',
                  parent:detail.ancestors.length?detail.ancestors[0].id:''
                };
                this.setState({_form:_newForm}, ()=> {
                  const query={ params: {category_id: detail.category_id} };
                  this.props.getMenuList(query);
                });
              });
            }
            else if(list.status_code===200&&this.state._fetchParentMenusSelect&&list.data.length > 0){
              this.setState({_fetchParentMenusSelect:!this.state._fetchParentMenusSelect}, ()=>{
                const getList=list.data.filter(item=>item.id!=this.state._form.id).map(item=>{return {value: item.id, label: item.title}})
                this.setState({_parentMenu:[{'value':'', label:'As Root'}, ...getList]});
              });
            }
            else if(this.state._formSubmit){
              const _form = {...this.state._form};
              this.setState({_formSubmit:!this.state._formSubmit}, ()=>{
                const _newForm = { ...this.state._form, ...detail};
                const { _success } = this.state;
                _success.push({key:'default',message:success});
                this.setState({_form:_newForm,_success});
              });
            }
            else if(this.state._changeFormSubmit){
              const _form = { ...this.state._form };
              this.setState({_changeFormSubmit:!this.state._changeForm}, ()=>{
                const _newForm = { ...this.state._form, ...detail };
                const { _success } = this.state;
                _success.push({key: 'change_position', message:success});
                this.setState({_form:_newForm, _changeForm: { newPosition: 0, after:true}, _success});
              });
            }
          }
          else {
            if(error!=prevProps.menu.error && error){
              const { _errors } = this.state;
              _errors.push({key:'default', ...error});
              this.setState({_errors, _formSubmit:!this.state._formSubmit,_changeFormSubmit:!this.state._changeFormSubmit});
            }
          }
        }
        else {
          if((list.status_code===200||detail.status_code===200) && error===null){
            if(this.state._formSubmit && isSubmit && success){
              const {_success}=this.state;
              _success.push({key:'default',message:success});
              this.setState({_form:{...this.state._form,...this.clearForm()},_formSubmit:!this.state._formSubmit,_success});
            }
            else if(this.state._fetchParentMenusSelect && isFetch!=prevProps.menu.isFetch && !isFetch){
              const getList=list.data.map(item=>{return {value:item.id,label:item.title}})
              this.setState({_parentMenu:[{'value':'',label:'As Root'},...getList],_fetchParentMenusSelect:!this.state._fetchParentMenusSelect});
            }
          }
          else{
            if(error!=prevProps.menu.error && error) {
              const {_errors}=this.state;
              _errors.push({key:'default',...error});
              this.setState({_errors,_formSubmit:!this.state._formSubmit});
            }
          }
        }
      }
    }

    clearForm(){
      return {title:'',parent:'',category_id:null,is_active:false,url:''};
    }

	  handleChange(e){
      const newState={...this.state._form};
      newState[e.target.name]=e.target.value;
      this.setState({_form:newState});
    }

    handleCheckedChange(e){
      const _form=checkChange(e,this.state._form);
      this.setState({_form});
    }

    _validateForm(){
      const {_errors} = this.state;
      const {title,parent,category_id}= this.state._form;
      let errors={};
      if(title==='')
        errors['title']=['Please fill in menu title.'];
      if(!category_id&&!parent)
        errors['category_id']=['Please fill in category.'];
      if(Object.keys(errors).length){
        this.setState({
          _errors:[{key:'default',errors:{...errors}, status_code:422,message:'Please full fill form'}]
        });
      }
      return errors;
    }
  
    onSubmit(e){
      e.preventDefault();
      const valid = this._validateForm();
      if (Object.keys(valid).length===0) {
        this.setState({_formSubmit:true, _errors:[], _success:[]},()=>{
          const {id,title,parent,category_id,is_active,url}= this.state._form;
          this.props.save({id,title,parent,category_id,is_active,url});
        });
      }
    }

    renderLoading(){
      return (
        <div className="row">
          <div className="col-sm-12">
            <Alert color='info' className="text-center">Getting all data...</Alert>
          </div>
        </div>
      )
    }

    renderInfo(type='default'){
      let infoMessage=[];
      const {_errors,_success}=this.state;
      if(type=='default'){
        if(this.state._formSubmit)
          infoMessage.push(
            <Alert color='info' key="info"><strong>Heads up!</strong> Submitting...</Alert>
          );
      }
      else{
        if(this.state._changeFormSubmit)
          infoMessage.push(
            <Alert color='info' key="info"><strong>Heads up!</strong> Submitting...</Alert>
          );
      }
      if(_success.filter(item=>item.key==type).length)
        infoMessage.push(
          <Alert color='success' key="success"isOpen={true}
            toggle={()=>{
              this.setState({_success: _success.filter(item=>item.key!=type)})
          }}><strong>Well done!</strong> { _success.filter(item=>item.key==type)[0].message }</Alert>
        );
      if(_errors.filter(item=>item.key==type).length)
      {
        const {status_code,message} = _errors.filter(item=>item.key==type)[0];
        switch(status_code)
        {
          case 422:
            infoMessage.push(
              <Alert color='danger' 
              key="error422"   
              isOpen={true} 
              toggle={()=>{
                this.setState({_errors: _errors.filter(item=>item.key!=type)})
              }}>
                <strong>Oh Snap!</strong> Please fullfill your form. 
              </Alert>
            );
            break;
          case 404:
            infoMessage.push(
              <Alert color='danger' key="error404"  isOpen={true}
              toggle={()=>{
                this.setState({_errors: _errors.filter(item=>item.key!=type)})
              }}><strong>Oh Snap!</strong> Resource not found. </Alert>
            );
            break;
          default:
            infoMessage.push(
              <Alert color='danger' key={`error${status_code}`} isOpen={true}
              toggle={()=>{
                this.setState({_errors: _errors.filter(item=>item.key!=type)})
              }}><strong>Oh Snap!</strong> We've got something errors </Alert>
            );
            break;
        }
      }
      return(
        <div className="row">
          <div className="col-sm-12">
            {infoMessage}   
          </div>
        </div>
      )
    }

    validateError(name){
      if(this.hasError(name))
      {
        let errorList=this.state._errors[0].errors;
        return <ValidationMessage message={errorList[name][0]}/>
      }
      return '';
    }
  
    hasError(name){
      const { _errors } = this.state;
      if(_errors.length)
      {
        if(_errors[0].errors)
        {
          let errorList=_errors[0].errors;
          let errList=Object.keys(errorList).filter(key=> key==name);
          if(errList.length)
            return true
        }
      }
      return false
    }
    
    toggle(){
      this.setState({_modalDelete:!this.state._modalDelete});
    }

    onDelete(){
      this.props.delete(this.state._form.id);
      this.toggle();
    }

    onSelectChange(val, attributeForm){
      const { _form } =this.state;
      _form[attributeForm]=val===null?val:val.value;
      if(attributeForm=='parent'&&val!==null&&val.value)
        this.setState({_form:{..._form, category_id:null}});
      else
        this.setState({_form});
    }

    render(){
      if(this.props.menu.detail.delete===true)
        return <Redirect to='/menus'/>
      return(
        <Row>
          <Modal isOpen={this.state._modalDelete} toggle={this.toggle} className="modal-dialog modal-sm">
            <ModalHeader toggle={this.toggle}>Confirmation</ModalHeader>
            <ModalBody>
              Are you sure to delete this?
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={this.onDelete}>Yes</Button>{' '}
              <Button color="secondary" onClick={this.toggle}>Cancel</Button>
            </ModalFooter>
          </Modal>
          <div className={classnames({"col-md-6":true,"offset-md-3":!this.state._hasId})}>
            <Card>
              <CardHeader>
                <i className="fa fa-align-justify"></i> {this.props.title}
              </CardHeader>
              <CardBody>
              { this.renderInfo() }
              {
                this.props.menu.isFetch?
                this.renderLoading()
                :
                <Form onSubmit={this.onSubmit}>
                  <Row>
                    <Col xs={12} md={6}>
                      <FormGroup className={this.hasError('parent')?' has-danger has-feedback':''}>
                        <Label for="formParentMenu">Parent Menu</Label>
                        <Select
                          id="formParentMenu"
                          name="parent"
                          value={this.state._form.parent}
                          options={this.state._parentMenu}
                          onChange={(val)=>{this.onSelectChange(val,'parent')}}
                        />
                        {this.validateError('parent')}
                      </FormGroup>
                    </Col>
                      {
                        !this.state._form.parent ?
                        <Col xs={12} md={6}>
                          <FormGroup className={this.hasError('category_id')?' has-danger has-feedback':''}>
                            <Label for="formBrandTitle">Select Category</Label>
                            <Select
                              id="formCategoryName"
                              name="category_id"
                              value={this.state._form.category_id}
                              options={this.props.categoryOptions.options.length?this.props.categoryOptions.options:[{value:'',label:'Loading...'}]}
                              onChange={(val)=>{this.onSelectChange(val,'category_id')}}
                            />
                            {this.validateError('category_id')}
                          </FormGroup>
                        </Col>
                        :
                        <Col xs={12} md={6}>
                          <p style={{fontSize:"9px"}}>Don't need category when you can't pick parent menu as root.</p>
                        </Col>
                      }
                  </Row>
                  <Row>
                    <Col xs={12} md={6}>
                      <FormGroup>
                        <Label for="formMenuTitle">Menu Title</Label>
                        <Input
                          type="text"
                          id="formMenuTitle"
                          name="title"
                          placeholder="Enter a title"
                          value={this.state._form.title}
                          disabled={this.state._form.check_title}
                          onChange={this.handleChange}
                        />
                        {this.validateError('title')}
                      </FormGroup>
                    </Col>
                    <Col xs={12} md={6}>
                      <FormGroup>
                        <Label for="formMenuActive">Set to Active &nbsp;</Label>
                          <label className="switch switch-text switch-success-outline-alt">
                            <input
                              type="checkbox"
                              className="switch-input"
                              name="is_active"
                              id="formMenuActive"
                              value="true" 
                              checked={textValue('is_active', this.state._form)}
                              onChange={this.handleCheckedChange}
                            />
                            <span className="switch-label" data-on="On" data-off="Off"></span>
                            <span className="switch-handle"></span>
                          </label>
                          {this.validateError('is_active')}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={12}>
                      <FormGroup>
                        <Label for="formUrl">Url</Label>
                          <Input
                            type="text"
                            id="formUrl"
                            name="url"
                            placeholder="Example: /sneakers/search/yeezy"
                            value={this.state._form.url}
                            onChange={this.handleChange}
                          />
                          {this.validateError('url')}
                      </FormGroup>
                    </Col>
                  </Row>
                  <FormGroup>
                    <Label>Created At</Label>
                    <Input
                      type="text"
                      readOnly
                      value={this.state._form.created_at}
                    />                                    
                  </FormGroup>
                  <FormGroup>
                    <Label>Updated At</Label>
                    <Input
                      type="text"
                      readOnly
                      value={this.state._form.updated_at}
                    />                                    
                  </FormGroup>
                  <FormGroup>
                    <ButtonGroup>
                      <Button  color="primary">Submit</Button>
                      {
                        this.state._hasId?
                        <Button  color="danger" onClick={this.toggle}>Delete</Button>
                        :
                        ''
                      }
                    </ButtonGroup>
                  </FormGroup>
                </Form>
              }
              </CardBody>
            </Card>
          </div>
          <div className={classnames({"col-md-6":true,"d-none":!this.state._hasId})}>
            <Card>
              <CardHeader>
                  <i className="fa fa-align-justify"></i> Change Position
              </CardHeader>
              <CardBody>
              { this.renderInfo('change_position') }
              {
                this.props.menu.isFetch?
                this.renderLoading()
                :
                <Form onSubmit={(e)=>{e.preventDefault();
                  const {_changeForm,_form}=this.state;
                  if(_changeForm.newPosition!==0) 
                  {
                    this.props.move(_form.id,_changeForm.newPosition,_changeForm.after);
                    this.setState({_changeFormSubmit:true, _errors:[], _success:[]});
                  }}}>
                    <Row>
                      <Col xs={12} md={6}>
                        <FormGroup>
                          <Select
                            id="formMoveTo"
                            value={this.state._changeForm.newPosition}
                            options={this.state._form.siblings?this.state._form.siblings.map(item=>moveToOptItem(item,this.state._form._lft)):[]}
                            onChange={(val)=>{
                              const {_changeForm}=this.state;
                              _changeForm.after=val.after;
                              _changeForm.newPosition=val.value;
                              this.setState({_changeForm});
                            }}
                          />
                          </FormGroup>
                      </Col>
                      <Col xs={12} md={6}>
                        <FormGroup>
                          <Button  color="primary">Change</Button>
                        </FormGroup>
                      </Col>
                    </Row>
                  </Form>
              }
              </CardBody>
            </Card>
          </div>
        </Row>
      )
  }
}

const mapStateToProps= (state) => {
	return {
		menu:state.menu,
    imageUpload:state.imageUpload,
    categoryOptions:state.masterCategories
	}
};

const mapDispatchToProps = (dispatch) => {
	return{
    getMenuList:(query)=>dispatch(getMenuList(query)),
		find:(id) => dispatch(getDetailMenu(id)),
		delete:(id) => dispatch(deleteMenu(id)),
		save:(payload) => dispatch(saveMenu(payload)),
    new:() => dispatch(newMenu()),
		move: (id, to, after) => dispatch(moveMenu(id, to, after)),
    bindCategoryOption:()=>dispatch(getCategoryOptionList())
	}
};

export default connect(mapStateToProps,mapDispatchToProps)(MenuForm);