import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import FormCard from './FormCard';
import SpinningWheelPrize from '../Prizes/PrizeIndex'
import { getDetailSpinWheel } from '../../../actions/spinningWheelAction';


class SpinningWheelForm extends React.Component {
  constructor(props){
    super(props);
    this.state={
      _id:this.props.match.params.id,
      detail: null
    }

    this._refreshDetail = this._refreshDetail.bind(this)
  }

  componentDidMount(){
    if(this.state._id)
      this.props.find(this.state._id);
  }

  _refreshDetail(){
    if(this.state._id)
      this.props.find(this.state._id);
  }

  componentDidUpdate(prevProps) {
    if (this.props.spinningWheel.isFetch !== prevProps.spinningWheel.isFetch)
    {
      const { error } = this.props.spinningWheel;
      if (!error) {
        this.setState({
          _id: this.state._id,
          detail: this.props.spinningWheel
        })
      }
    }
  }

  render(){
    return(
      <Fragment>
        {this.state.detail && (
          <>
            <FormCard
              formTitle="Spinning Wheel Details"
              edit={this.props.edit}
              spinningWheel={this.state.detail}
              {...this.props}
            />
            {this.props.edit && (
              <SpinningWheelPrize
                refreshDetail={this._refreshDetail} 
                spinningWheel={this.state.detail} 
                {...this.props}
                />
            )}
          </>
        )}
      </Fragment>
    )
  }
}
const mapStateToProps= (state) => {
	return {
    spinningWheel: state.spinningWheel
	}
};
const mapDispatchToProps = (dispatch) => {
	return{
    find:(id) => dispatch(getDetailSpinWheel(id)),
	}
};

export default connect(mapStateToProps,mapDispatchToProps)(SpinningWheelForm);
