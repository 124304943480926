import React from 'react';
import propTypes from 'prop-types';
import {
  Nav as NavBS,
  NavItem,
  NavLink
}
from 'reactstrap';
import classNames from 'classnames';

const Nav = props => (
  <NavBS className="nav-tabs--custom" tabs>
    <NavItem>
      <NavLink
        className={classNames({ active: props.activeTab === 'change_seller' })}
        onClick={() => props.onActiveTabChange('change_seller')}
      >
        Change Seller
      </NavLink>
    </NavItem>
    <NavItem>
      <NavLink
        className={classNames({ active: props.activeTab === 'order' })}
        onClick={() => props.onActiveTabChange('order')}
      >
        Order Detail
      </NavLink>
    </NavItem>
    <NavItem>
      <NavLink
        className={classNames({ active: props.activeTab === 'sneaker' })}
        onClick={() => props.onActiveTabChange('sneaker') }
      >
        Product Detail
      </NavLink>
    </NavItem>
    <NavItem>
      <NavLink
        className={classNames({ active: props.activeTab === 'seller' })}
        onClick={() => props.onActiveTabChange('seller') }
      >
        Seller Detail
      </NavLink>
    </NavItem>
    <NavItem>
      <NavLink
        className={classNames({ active: props.activeTab === 'user-shipping' })}
        onClick={() => props.onActiveTabChange('user-shipping') }
      >
        User & Shipping Detail
      </NavLink>
    </NavItem>
  </NavBS>
)

export default Nav;

Nav.defaultProps = {

}

Nav.propTypes = {
  activeTab: propTypes.string.isRequired,
  onActiveTabChange: propTypes.func
}