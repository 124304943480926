import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import ReactLoading from 'react-loading';
import { Badge } from 'reactstrap';
import { paymentTypeOptions } from '../../../../constants/kickavenue';
import { TextRupiah } from '../../../../components/Text';
import { FontAwesomeIcon } from '../../../../components/Icons';
import { getStatusStr, isExpired } from '../../helpers';

const PageHeader = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  &::after{
    content: '';
    border-bottom: 1px solid #ddd;
    margin: 0px -10px;
  }
  margin-bottom: 1rem;
`

const PageHeaderLoading = styled.div`
  display: flex;
  flex: 1;
  background-color: #FF9800;
  color: #FFF;
  justify-content: center;
  align-items: center;
  padding: .2rem 0px;
  margin-bottom: .5rem;
  > p {
    margin: 0;
    text-align: center;
    margin-left: .5rem;
  }
`

const PageHeaderBody = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: center;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: ${({right}) => right? 'flex-end': 'flex-start'};
  justify-content: center;
  margin-bottom: .5rem;
`

const Par = styled.p`
  font-size: .75rem;
  margin-bottom: 0px;
  font-style: ${({ fontStyle }) => fontStyle? fontStyle: 'normal' };
  > span{
    &.badge{
      font-style: normal;
    }
    ::before{
      content: ' / ';
      font-style: normal;
    }
    :first-child{
      ::before{
        content: ''
      }
    }
  }
`

const ParLarge = styled(Par)`
  font-size: 1rem;
`
const ParSmall = styled(Par)`
  font-size: .65rem;
`

const getPaymentMethod = _paymentType => {
  const getPaymentTypeFilter = paymentTypeOptions.find(paymentType=>paymentType.value===_paymentType)
  return getPaymentTypeFilter? getPaymentTypeFilter.name: 'UNDEFINED PAYMENT METHOD';
}

const getRaffleRequestStatusColor = (status, expiredAt) => {
  if(status==='NEW'&& isExpired(expiredAt)){
    return 'danger';
  }
  switch(status){
    case 'NEW': return isExpired(expiredAt)? 'danger': 'warning';
    case 'ACCEPTED': return 'success';
    case 'REJECTED':
    case 'CANCELLED':
    case 'EXPIRED': return 'danger';
    default: return 'default';
  }
}

const Header = props => (
  <PageHeader>
    {
      props.isLoading?
      <PageHeaderLoading>
        <ReactLoading
          type="bars"
          color="#fff"
          className="loading-center"
          width={30}
          height={30}
        />
        <p>Fetching offer data...</p>
      </PageHeaderLoading>
      :
      <PageHeaderBody>
        <Content>
          <ParLarge>{ props.displayName }</ParLarge>
          <Par fontStyle="italic">
            {props.category&&(
              <Badge color="primary" className="text-uppercase">
                <FontAwesomeIcon className="mr-1" iconType="tags"/>{ props.category }
              </Badge>
            )}
            <span>{ props.SKU }</span>
            <span>{ props.colour }</span>
          </Par>
          <Par>
            <span>{ `${props.sizeUS} US` }</span>
            <span>{ props.sneakerCondition }</span>
            <span>{ props.boxCondition }</span>
            { props.preOrder? <span>PREORDER</span> : null }
          </Par>
          <ParSmall>
            <span>{ props.createdAt }</span>
            <span>{ props.email }</span>
          </ParSmall>
        </Content>
        <Content right>
          <Badge
            color={getRaffleRequestStatusColor(props.status, props.expiredAt)}
            style={{fontSize: 14}}
          >
            { getStatusStr(props.status, isExpired(props.expiredAt), props.payment) }
          </Badge>
          <div style={{marginTop: '0.75rem'}}>
            <ParLarge>Payment with <strong>{ getPaymentMethod(props.paymentMethod) }</strong></ParLarge>
            <Par>Total Payment: <TextRupiah prefix="Rp. " tag="span" value={props.totalAmount}/></Par>
          </div>
        </Content>
      </PageHeaderBody>
    }
  </PageHeader>
)

export default Header;

Header.defaultProps = {
  isLoading: false,
  preOrder: false,
  email: null,
  sneakerCondition: 'BARU',
  boxCondition: 'SEMPURNA',
  paymentMethod: 'bank_transfer',
  status: 'NEW',
  expiredAt: null
}

Header.propTypes = {
  isLoading: propTypes.bool,
  email: propTypes.string,
  createdAt: propTypes.string,
  displayName: propTypes.string,
  SKU: propTypes.string,
  colour: propTypes.string,
  sizeUS: propTypes.string,
  paymentMethod: propTypes.string,
  status: propTypes.string,
  sneakerCondition: propTypes.string,
  boxCondition: propTypes.string,
  preOrder: propTypes.bool,
  expiredAt: propTypes.string,
  totalAmount: propTypes.oneOfType([propTypes.string, propTypes.number])
}