import {
    BULK_PRODUCT_SUBMIT,
    BULK_PRODUCT_ERROR,
    BULK_PRODUCT,
    BULK_PRODUCT_UPLOAD_SUBMIT,
	BULK_PRODUCT_UPLOAD,
} from '../constants';
const initialState={
    success:{},
    isUpload:false,
	isSubmit:false,
	error:null
}

const reducer=(state=initialState, action)=>{
    switch(action.type){
        case BULK_PRODUCT_SUBMIT: return {...state, isSubmit:action.payload};
        case BULK_PRODUCT_UPLOAD_SUBMIT: return {...state, isUpload:action.payload};
        case BULK_PRODUCT_ERROR: return { ...state, ...action.payload,success:{} };
        case BULK_PRODUCT: return {...state, success:{...action.payload},error:null};
        case BULK_PRODUCT_UPLOAD: return {...state, success:{...action.payload},error:null};
        case 'CLEAR_BULK_PRODUCT': return initialState
        default:return state;
    }
}
export default reducer;