import React from 'react';
import propTypes from 'prop-types';
import _ from 'lodash';
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Form as BSForm,
  FormGroup,
  Label,
  Input,
  ButtonGroup,
  Button,
  Alert
} from 'reactstrap';
import classNames from 'classnames';
import { errorMessage, hasError, textValue, textChange, checkChange } from '../../../utils/form';
import { getBalanceSource } from '../helpers';
import FontAwesomeIcon from '../../../components/Icons/FontAwesomeIcon';
import ButtonLoading from '../../../components/Button/ButtonLoading';
import { ModalConfirmation } from '../../../components/Modals';
import ModalAccountBankOffice from '../Modals/ModalAccountBankOffice';
import DisbursementSummaries from '../DisbursementSummaries';
import BadgeDisbursementType from '../Badges/BadgeDisbursementType';
import Analytics from "../../../services/Analytics";
import {
  COMPLETED_STATUS,
  REJECTED_STATUS,
  DISBURSEMENT_BALANCE_IN,
  DISBURSEMENT_BALANCE_OUT,
  MODAL_CONFIRM_DELETE_ACTION,
  MODAL_CONFIRM_ACCEPT_ACTION,
  MODAL_CONFIRM_REJECT_ACTION,
  KICK_CREDIT,
  SELLER_CREDIT,
  KICK_POINT
} from '../helpers';
import SelectBeneficiaryBank from '../../../components/Form/Select/Async/SelectBeneficiaryBank';
import { TextRupiah } from '../../../components/Text';
import DisbursementAuditList from './DisbursementAuditList';

const recordType = (status) => {
  switch(status) {
    case 'COMPLETED_STATUS': return 'accept'
    case 'REJECTED_STATUS': return 'reject'
    case 'NEW': return 'update'

    default: return 'update'
  }
}

class Form extends React.Component{
  constructor(props){
    super(props);
    this.state={
      _id: props.id,
      _userId: null,
      _userEmail: '',
      _userBalance: 0,
      _accountNumberSender: '',
      _accountNameSender: '',
      _bankNameSender: '',
      _branchSender: '',
      _accountNumberRecipient: '',
      _payoutType: 'MANUAL',
      _balanceSource: 'BALANCE',
      _accountNameRecipient: '',
      _bankNameRecipient: '',
      _branchRecipient: '',
      _totalAmount: 0,
      _fee: 0,
      _note: '',
      _modalAccountBankOfficeOpen: false,
      _isAccountBankUser: false,
      _isSubmit: false,
      _errorMessage: '',
      _isAlertErrorOpen: false,
      _successMessage: null,
      _alertSuccess: false,
      _isModalConfirmOpen: false,
      _modalConfirmBody: 'Are you sure?',
      _bank_option:"CUSTOM",
      _modalConfirmAction: MODAL_CONFIRM_DELETE_ACTION,
      _audits: []
    }
    this._handleAccountBankOfficeChanged = this._handleAccountBankOfficeChanged.bind(this);
    this._handleOpenModalAccountBankForOffice = this._handleOpenModalAccountBankForOffice.bind(this);
    this._handleOpenModalAccountBankForUser = this._handleOpenModalAccountBankForUser.bind(this);
    this._handleTextChange = this._handleTextChange.bind(this);
    this._handleNumericTextChange = this._handleNumericTextChange.bind(this);
    this._handleSubmitForm = this._handleSubmitForm.bind(this);
    this._handleCheckedChange = this._handleCheckedChange.bind(this);
    this._handleAcceptDisbursement = this._handleAcceptDisbursement.bind(this);
    this._handleRejectDisbursement = this._handleRejectDisbursement.bind(this);
    this._handleToggleModalConfirmation = this._handleToggleModalConfirmation.bind(this);
    this._handleModalConfirmOnSubmit = this._handleModalConfirmOnSubmit.bind(this);
    this._handleDeleteDisbursement = this._handleDeleteDisbursement.bind(this);
    this._handleSelectBank = this._handleSelectBank.bind(this);
    this._handleOnSelectBankOpen = this._handleOnSelectBankOpen.bind(this);
    this._throttleGetDisbursementFee = _.debounce(this._getDisbursementFee.bind(this), 500)
    this._handleOnFocusFee = this._handleOnFocusFee.bind(this);
  }

  componentDidMount(){
    this.props.getList();
    this.bindDisbursementData();
  }

  bindDisbursementData(){
    const { dataDisbursementUser } = this.props;
    const newState = {};
    if(dataDisbursementUser.type=== DISBURSEMENT_BALANCE_OUT && dataDisbursementUser.disbursement_bank){
      newState._accountNumberSender = dataDisbursementUser.disbursement_bank.account_number_sender;
      newState._accountNameSender = dataDisbursementUser.disbursement_bank.account_name_sender;
      newState._bankNameSender = dataDisbursementUser.disbursement_bank.bank_name_sender;
      newState._branchSender = dataDisbursementUser.disbursement_bank.branch_sender;
      newState._accountNumberRecipient = dataDisbursementUser.disbursement_bank.account_number_recipient;
      newState._accountNameRecipient = dataDisbursementUser.disbursement_bank.account_name_recipient;
      newState._bankNameRecipient = dataDisbursementUser.disbursement_bank.bank_name_recipient;
      newState._branchRecipient = dataDisbursementUser.disbursement_bank.branch_recipient;
    }
    if(dataDisbursementUser.disburse_to){
      const { balance_source, disburse_to } = dataDisbursementUser;
      let balance = 0
      newState._userId = dataDisbursementUser.disburse_to.id;
      newState._userEmail = dataDisbursementUser.disburse_to.email;
      switch (balance_source){
        case SELLER_CREDIT:
          balance = parseInt(disburse_to.balance_with_fee);
          break;
        case KICK_POINT:
          balance = parseInt(disburse_to.locked_balance);
          break;
        case KICK_CREDIT:
        default: balance = parseInt(disburse_to.balance); break;
      }
      newState._userBalance = balance;
      newState._balanceSource = balance_source;
    }
    newState._disbursementNumber = dataDisbursementUser.disbursement_number;
    newState._status = dataDisbursementUser.status;
    newState._disbursementType = dataDisbursementUser.type;
    newState._payoutType = dataDisbursementUser.payout_type;
    newState._disabledPayout = dataDisbursementUser.disabled_payout;
    newState._balanceSource = dataDisbursementUser.balance_source;
    newState._referenceNo = dataDisbursementUser.reference_no;
    newState._note = dataDisbursementUser.note;
    newState._totalAmount = parseInt(dataDisbursementUser.total_amount);
    newState._fee = parseInt(dataDisbursementUser.fee);
    newState._createdAt = dataDisbursementUser.created_at;
    newState._updatedAt = dataDisbursementUser.updated_at;
    this.setState({...newState})
  }

  _handleNumericTextChange(e){
    let { value } = e.target;
    const lastState = {...this.state};
    const numb = value.trim().replace(/([a-zA-Z])+/, '');
    lastState[e.target.name] = numb===''? '0': parseInt(numb);
    if(e.target.name && e.target.name === '_totalAmount'
      && this.state._balanceSource === 'BALANCE_WITH_FEE' && this.state._disbursementType === 'BALANCE_OUT')
      this._throttleGetDisbursementFee(numb)
    this.setState({...lastState})
  }

  _getDisbursementFee = (totalAmount) => {
    if(totalAmount > 0){
      this.props.onGetCashoutFee({params: { total_amount: totalAmount }});
    }
  }


  _handleCheckedChange(e){
    this.setState({
      ...checkChange(e, this.state)
    })
  }

  componentDidUpdate(lastProps, lastStates){
    if(lastStates._balanceSource!==this.state._balanceSource){
      if(this.state._balanceSource==='BALANCE_WITH_FEE' && this.state._disbursementType === 'BALANCE_OUT'){
        const totalAmount = Number(this.state._totalAmount)
        this._throttleGetDisbursementFee(totalAmount);
      }
    }

    if(lastProps.isSubmit&&lastProps.isSubmit!==this.props.isSubmit){
      const { dataDisbursementUserError, dataDisbursementUser } = this.props;
      if(dataDisbursementUserError){
        const { errors, status_code, message } = dataDisbursementUserError;
        if(status_code===422){
          const getErrors = {};
          Object.keys(errors).map(index=>{
            if(index==='total_amount'){
              const lastMsgs = getErrors['_totalAmount']||[];
              getErrors['_totalAmount'] = [ ...lastMsgs, errors[index]];
            }
            else if(index==='disburse_to'){
              const lastMsgs = getErrors['_userId']||[];
              getErrors['_userId'] = [...lastMsgs, errors[index][0].replace('disburse to', 'user')];
            }
            else if(index==='fee'){
              const lastMsgs = getErrors['_fee']||[];
              getErrors['_fee'] = [...lastMsgs, errors[index]];
            }
            else if(index==='payout_type'){
              const lastMsgs = getErrors['_payoutType']||[];
              getErrors['_payoutType'] = [...lastMsgs, errors[index][0]];
            }
            else if(index==='balance_source'){
              const lastMsgs = getErrors['_balanceSource']||[];
              getErrors['_balanceSource'] = [...lastMsgs, errors[index][0]];
            }
            else if(index==='status'){
              const lastMsgs = getErrors['_status']||[];
              getErrors['_status'] = [...lastMsgs, errors[index]];
            }
            else if(index==='disbursement_bank.account_number_sender'){
              const lastMsgs = getErrors['_accountNumberSender']||[];
              getErrors['_accountNumberSender'] = [...lastMsgs, errors[index][0].replace('disbursement bank.account number', 'account number')];
            }
            else if(index==='disbursement_bank.account_name_sender'){
              const lastMsgs = getErrors['_accountNameSender']||[];
              getErrors['_accountNameSender'] = [...lastMsgs, errors[index][0].replace('disbursement bank.account name', 'account name')];
            }
            else if(index==='disbursement_bank.bank_name_sender'){
              const lastMsgs = getErrors['_bankNameSender']||[];
              getErrors['_bankNameSender'] = [...lastMsgs, errors[index][0].replace('disbursement bank.bank name', 'bank name')];
            }
            else if(index==='disbursement_bank.branch_sender'){
              const lastMsgs = getErrors['_branchSender']||[];
              getErrors['_branchSender'] = [...lastMsgs, errors[index][0].replace('disbursement bank.branch', 'branch')];
            }
            else if(index==='disbursement_bank.account_number_recipient'){
              const lastMsgs = getErrors['_accountNumberRecipient']||[];
              getErrors['_accountNumberRecipient'] = [...lastMsgs, errors[index][0].replace('disbursement bank.account number', 'account number')];
            }
            else if(index==='disbursement_bank.account_name_recipient'){
              const lastMsgs = getErrors['_accountNameRecipient']||[];
              getErrors['_accountNameRecipient'] = [...lastMsgs, errors[index][0].replace('disbursement bank.account name', 'account name')];
            }
            else if(index==='disbursement_bank.bank_name_recipient'){
              const lastMsgs = getErrors['_bankNameRecipient']||[];
              getErrors['_bankNameRecipient'] = [...lastMsgs, errors[index][0].replace('disbursement bank.bank name', 'bank name')];
            }
            else if(index==='disbursement_bank.branch_recipient'){
              const lastMsgs = getErrors['_branchRecipient']||[];
              getErrors['_branchRecipient'] = [...lastMsgs, errors[index][0].replace('disbursement bank.branch', 'branch')];
            }
          })
          this.setState({
            _errorForm:{
              errors: getErrors,
              statusCode: status_code,
              message
            },
            _isSubmit: false,
            _errorMessage: 'Please fulfill your form!',
            _isAlertErrorOpen: true,
            _isModalConfirmOpen: false
          })
        }
        else{
          this.setState({
            _errorForm:{
              statusCode: status_code,
              message
            },
            _isSubmit: false,
            _errorMessage: message,
            _isAlertErrorOpen: true
          })
        }
      }
      else{
        const { status, status_code, message, deleted } = dataDisbursementUser;
        this.bindDisbursementData();
        if(status_code === 200){
          this.setState({
            _isSubmit: false,
            _isModalConfirmOpen: false,
            _successMessage : message,
            _alertSuccess : true
          })
          if(deleted){
            this.props.onRedirect(`/disbursements/pending`)
          }
          else if(status === COMPLETED_STATUS)
            this.props.onRedirect(`/disbursements/completed`)
          else if(status === REJECTED_STATUS)
            this.props.onRedirect(`/disbursements/pending`)
        }
      }
    }
  }


  _handleAccountBankOfficeChanged(accountBank){
    if(accountBank.user_id){
      this.setState({
        _accountBankIdRecipient: accountBank.id,
        _accountBankUserIdRecipient: accountBank.user_id,
        _accountNumberRecipient: accountBank.account_number,
        _accountNameRecipient: accountBank.account_name,
        _bankNameRecipient: accountBank.bank_name,
        _branchRecipient: accountBank.branch,
        _modalAccountBankOfficeOpen: false
      }, ()=> {
        if(accountBank.account_number.toString().length >= 16){
          alert('Please re-check account number recipient');
        }
      });
    }
    else{
      this.setState({
        _accountBankIdSender: accountBank.id,
        _accountNumberSender: accountBank.account_number,
        _accountNameSender: accountBank.account_name,
        _bankNameSender: accountBank.bank_name,
        _branchSender: accountBank.branch,
        _modalAccountBankOfficeOpen: false
      });
    }
  }

  _handleOpenModalAccountBankForOffice(){
    this.setState({
      _modalAccountBankOfficeOpen: !this.state._modalAccountBankOfficeOpen,
      _isAccountBankUser: false
    })
  }

  _validatePayloads(isRejected){
    let objErrors = {};
    const {
      _balanceSource,
      _userBalance, 
      _totalAmount, 
      _disbursementType, 
      _payoutType, 
      _bankNameRecipient, 
      _accountNameRecipient, 
      _accountNumberRecipient 
    } = this.state;
    if(this.state._userId === null){
      objErrors['_userId'] = ["Please select a user to disburse."];
    }
    else{
      if(!isRejected){
        if(_userBalance - _totalAmount < 0 && _disbursementType===DISBURSEMENT_BALANCE_OUT){
          if(_balanceSource === SELLER_CREDIT){
            objErrors['_totalAmount'] = ["The total amount field must be less than seller balance."];
          } else{
            objErrors['_totalAmount'] = ["The total amount field must be less than user balance."];
          }
        }
      }
    }
    if(this.state._totalAmount <= 0&&!isRejected){
      objErrors['_totalAmount'] = ["The total amount field must be greater than 0."];
    }
    if(_payoutType==='MIDTRANS'){
      if(!_bankNameRecipient)
        objErrors['_bankNameRecipient'] = ["The bank name recipient field must is required."];
      if(!_accountNameRecipient)
        objErrors['_accountNameRecipient'] = ["The account name recipient field must is required."];
      if(!_accountNumberRecipient)
        objErrors['_accountNumberRecipient'] = ["The account number recipient field must is required."];
      if(_bankNameRecipient){
        const availableBankName = this.props.bankAccount.filter(item => item.code.toLocaleLowerCase() === _bankNameRecipient.toLocaleLowerCase()
        || !!~item.name.replace(/\./g, '').toLocaleLowerCase().indexOf(_bankNameRecipient.replace(/\./g, '').toLocaleLowerCase())).length
        if(availableBankName <= 0){
          objErrors['_bankNameRecipient'] = ["The bank name recipient field is not included in the midtrans bank list."];
        }
      }
    }
    return objErrors;
  }

  _submitForm(withStatus=false, status){
    const { email } = this.props;
    const getLocalErrors = this._validatePayloads(status === REJECTED_STATUS);
    if(Object.keys(getLocalErrors).length===0){
      const payloads = {
        disburse_to: this.state._userId,
        total_amount: this.state._totalAmount,
        fee: this.state._fee,
        note: this.state._note,
        disbursement_type: this.state._disbursementType,
        balance_source: this.state._balanceSource,
        payout_type: this.state._disbursementType === 'BALANCE_IN'? "MANUAL": this.state._payoutType, //forced to manual if selected BALANCE_IN
        status: withStatus===true? status: this.state._status
      }
      if(this.state._disbursementType === DISBURSEMENT_BALANCE_OUT){
        payloads.disbursement_bank = {
          account_number_sender: this.state._accountNumberSender,
          account_name_sender: this.state._accountNameSender,
          bank_name_sender: this.state._bankNameSender,
          branch_sender: this.state._branchSender,
          account_number_recipient: this.state._accountNumberRecipient,
          account_name_recipient: this.state._accountNameRecipient,
          bank_name_recipient: this.state._bankNameRecipient,
          branch_recipient: this.state._branchRecipient
        }
      }
      this.setState({
        _isSubmit: true,
        _isAlertErrorOpen: false,
        _alertSuccess: false,
        _errorForm: null
      }, ()=>this.props.onUpdateDisbursement(this.state._id, payloads))
      
      const dirsbursementBank = this.state._disbursementType === DISBURSEMENT_BALANCE_OUT? payloads.disbursement_bank : null;
      const type = recordType(payloads.status)
      Analytics.recordUpdateDisbursement(
        type,
        payloads,
        dirsbursementBank,
        email
      );
    }
    else{
      this.setState({
        _errorForm:{
          errors: getLocalErrors,
          statusCode: 422
        },
        _errorMessage: 'Please fulfill your form!',
        _isAlertErrorOpen: true,
        _isModalConfirmOpen: false
      })
    }
  }

  _handleAcceptDisbursement(e){
    e.preventDefault();
    this.setState({
      _isModalConfirmOpen: true,
      _modalConfirmAction: MODAL_CONFIRM_ACCEPT_ACTION,
      _modalConfirmBody: `Are you sure to accept this disbursement (${this.state._disbursementNumber})?`
    })
  }

  _handleRejectDisbursement(e){
    e.preventDefault();
    this.setState({
      _isModalConfirmOpen: true,
      _modalConfirmAction: MODAL_CONFIRM_REJECT_ACTION,
      _modalConfirmBody: `Are you sure to reject this disbursement (${this.state._disbursementNumber})?`
    })
  }

  _handleSubmitForm(e){
    e.preventDefault();
    this._submitForm();
  }

  _handleOpenModalAccountBankForUser(){
    if(!this.state._userId){
      alert('Please select user!');
    }
    else{
      this.setState({
        _modalAccountBankOfficeOpen: !this.state._modalAccountBankOfficeOpen,
        _isAccountBankUser: true
      })
    }
  }

  _handleTextChange(e){
    this.setState({
      ...textChange(e, this.state)
    })
  }

  _handleToggleModalConfirmation(){
    this.setState({
      _isModalConfirmOpen: !this.state._isModalConfirmOpen
    })
  }

  _handleModalConfirmOnSubmit(e){
    e.preventDefault();
    const { _modalConfirmAction } = this.state;
    switch(_modalConfirmAction){
      case MODAL_CONFIRM_DELETE_ACTION : this.props.onDeleteDisbursement(this.state._id); break;
      case MODAL_CONFIRM_ACCEPT_ACTION : this._submitForm(true, COMPLETED_STATUS); break;
      case MODAL_CONFIRM_REJECT_ACTION : this._submitForm(true, REJECTED_STATUS); break;
      default: break;
    }
  }

  _handleDeleteDisbursement(e){
    e.preventDefault();
    this.setState({
      _isModalConfirmOpen: true,
      _modalConfirmAction: MODAL_CONFIRM_DELETE_ACTION,
      _modalConfirmBody: `Are you sure to delete this disbursement (${this.state._disbursementNumber})?`
    })
  }
  
  _handleSelectBank(option){
    let { _bankNameRecipient } =this.state;
    _bankNameRecipient = option? option.value: option;
    this.setState({_bankNameRecipient});
  }

  _handleOnSelectBankOpen(){
    if(this.state._bankNameRecipient){
      if(this.state._recentSearchBank){
        this.refs.selectBeneficiaryBank.loadSelectOptions(this.state._recentSearchBank)
      }
      else{
        this.refs.selectBeneficiaryBank.loadSelectOptions(this.state._bankNameRecipient)
      }
    }
    else{
      this.refs.selectBeneficiaryBank.loadSelectOptions('')
    }
  };

  _handleOnFocusFee() {
    const { dataCashoutFee } = this.props;

    if (dataCashoutFee.isFetch) {
      return;
    }

    this.setState({
      _fee: dataCashoutFee.data?.fee || 0,
    });
  }

  render(){
    return(
      <Row>
        <Col xs={12} md={6}>
          <ModalAccountBankOffice
            isOpen={this.state._modalAccountBankOfficeOpen}
            userId={this.state._userId}
            isAccountBankUser={this.state._isAccountBankUser}
            onCancel={()=> this.setState({_modalAccountBankOfficeOpen: !this.state._modalAccountBankOfficeOpen})}
            onSelectChanged={this._handleAccountBankOfficeChanged}
          />
          <ModalConfirmation
            isOpen={this.state._isModalConfirmOpen}
            toggle={this._handleToggleModalConfirmation}
            modalBody={this.state._modalConfirmBody}
            onSubmit={this._handleModalConfirmOnSubmit}
          />
          <Card>
            <CardHeader>
              <FontAwesomeIcon iconType="align-justify"/>Disbursement Details
            </CardHeader>
            <CardBody>
              <BSForm onSubmit={this._handleSubmitForm}>
                <Row>
                  <Col xs={12} style={{marginBottom: 15, display: 'flex', justifyContent: 'flex-end'}}>
                    <Button onClick={this._handleAcceptDisbursement} disabled={this.state._payoutType==='MIDTRANS'} color="success" type="button"><i className="fa fa-thumbs-up"></i> Accept</Button>
                    <Button onClick={this._handleRejectDisbursement} color="danger" type="button"><i className="fa fa-thumbs-down"></i> Reject</Button>
                  </Col>
                  <Col xs={12}>
                    <Alert color="success" isOpen={this.state._alertSuccess} toggle={()=>this.setState({_alertSuccess: !this.state._alertSuccess})}>
                      <strong>Well done!</strong> { this.state._successMessage }
                    </Alert>
                    <Alert
                      color="danger"
                      isOpen={this.state._isAlertErrorOpen}
                      toggle={()=> this.setState({_isAlertErrorOpen: !this.state._isAlertErrorOpen})}
                    >
                      <strong>Oppps... </strong>{this.state._errorMessage}
                    </Alert>
                  </Col>
                  <Col xs={12}>
                    <FormGroup>
                      <Label>Disbursement Numb. <BadgeDisbursementType type={this.state._disbursementType}/></Label>
                      <Input
                        disabled
                        value={textValue("_disbursementNumber", this.state)}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={12} md={6}>
                    <FormGroup className={classNames({'has-danger has-feedback':hasError('_userId', this.state._errorForm)})}>
                      <Label>User</Label>
                      <Input
                        disabled
                        value={textValue("_userEmail", this.state)}
                      />
                      {errorMessage('_userId', this.state._errorForm)}
                    </FormGroup>
                  </Col>
                  <Col xs={12} md={6}>
                    <FormGroup className={classNames({'has-danger has-feedback':hasError('_balanceSource', this.state._errorForm)})}>
                      <Label>Balance Source</Label>
                      <Input
                        disabled
                        value={getBalanceSource(textValue("_balanceSource", this.state))}
                      />
                      {errorMessage('_balanceSource', this.state._errorForm)}
                    </FormGroup>
                  </Col>
                  {
                    this.state._disbursementType === "BALANCE_OUT" ?
                    <Col xs={12} md={6}>
                      <FormGroup className={classNames({'has-danger has-feedback':hasError('_payoutType', this.state._errorForm)})}>
                        <Label>Payout Type</Label>
                        <Input
                          type="select"
                          name="_payoutType"
                          onChange={this._handleTextChange}
                          value={this.state._payoutType}
                          disabled={this.state._disabledPayout}
                          placeholder="Payout type..."
                        >
                          <option value="MANUAL">Manual</option>
                          <option value="MIDTRANS">Midtrans</option>
                        </Input>
                        {errorMessage('_payoutType', this.state._errorForm)}
                      </FormGroup>
                    </Col>
                    :
                    null
                  }
                  <div className={classNames({'d-none': this.state._disbursementType===DISBURSEMENT_BALANCE_IN, 'd-flex flex-wrap': this.state._disbursementType===DISBURSEMENT_BALANCE_OUT})} style={{padding: '1rem 0', borderTop: '1px solid #ddd', borderBottom: '1px solid #ddd', margin: '15px 0'}}>
                    <Col xs={12}>
                      <FormGroup>
                        <Label className="font-weight-bold">Transfer From (KickAvenue)</Label>
                        <br/>
                        <Button
                          type="button"
                          onClick={this._handleOpenModalAccountBankForOffice}
                        >
                          Pilih Account Bank
                        </Button>
                      </FormGroup>
                    </Col>
                    <Col xs={12} md={12}>
                      <FormGroup className={classNames({'has-danger has-feedback':hasError('_bankNameSender', this.state._errorForm)})}>
                        <Label>Bank Name</Label>
                        <Input
                          name="_bankNameSender"
                          onChange={this._handleTextChange}
                          value={textValue("_bankNameSender", this.state)}
                          placeholder="Bank name..."
                        />
                        {errorMessage('_bankNameSender', this.state._errorForm)}
                      </FormGroup>
                    </Col>
                    <Col xs={12} md={6}>
                      <FormGroup className={classNames({'has-danger has-feedback':hasError('_accountNumberSender', this.state._errorForm)})}>
                        <Label>Account Number</Label>
                        <Input
                          name="_accountNumberSender"
                          onChange={this._handleTextChange}
                          value={this.state._accountNumberSender}
                          placeholder="Account number..."
                        />
                        {errorMessage('_accountNumberSender', this.state._errorForm)}
                      </FormGroup>
                    </Col>
                    <Col xs={12} md={6}>
                      <FormGroup className={classNames({'has-danger has-feedback':hasError('_branchSender', this.state._errorForm)})}>
                        <Label>Branch</Label>
                        <Input
                          name="_branchSender"
                          onChange={this._handleTextChange}
                          value={textValue("_branchSender", this.state)}
                          placeholder="Branch..."
                        />
                        {errorMessage('_branchSender', this.state._errorForm)}
                      </FormGroup>
                    </Col>
                    <Col xs={12} md={12}>
                      <FormGroup className={classNames({'has-danger has-feedback':hasError('_accountNameSender', this.state._errorForm)})}>
                        <Label>Account Name</Label>
                        <Input
                          name="_accountNameSender"
                          onChange={this._handleTextChange}
                          value={textValue("_accountNameSender", this.state)}
                          placeholder="Account name..."
                        />
                        {errorMessage('_accountNameSender', this.state._errorForm)}
                      </FormGroup>
                    </Col>
                    <Col xs={12}>
                      <hr/>
                    </Col>
                    <Col xs={12}>
                      <FormGroup>
                        <Label className="font-weight-bold">Transfer To</Label>
                        <br/>
                        <Button
                          className="d-none"
                          type="button"
                          onClick={this._handleOpenModalAccountBankForUser}
                        >
                          Pilih Account Bank
                        </Button>
                      </FormGroup>
                    </Col>
                    <Col xs={12} md={12}>
                      <FormGroup className={classNames({'has-danger has-feedback':hasError('_bankNameRecipient', this.state._errorForm)})}>
                        <Label for="formRack" className="d-block">
                          <div className="d-flex" style={{justifyContent: 'space-between'}}>
                            <div>Bank Name</div>
                            {
                              this.state._bank_option !== "CUSTOM" ?
                              <div style={{marginLeft: "0.5rem"}}>
                                <span style={{marginLeft: "0.5rem", textDecoration: 'underline', cursor: "pointer", color: 'red', fontSize: '.75rem'}} onClick={() => this.setState({_bank_option: "CUSTOM"})}>Make a new bank</span>
                              </div>
                              :
                              <div style={{marginLeft: "0.5rem"}}>
                                <span style={{marginLeft: "0.5rem", textDecoration: 'underline', cursor: "pointer", color: 'red', fontSize: '.75rem'}} onClick={() => this.setState({_bank_option: "SELECT"})}>Select existing bank</span>
                              </div>
                            }
                          </div>
                        </Label>
                        {
                          this.state._bank_option !== "CUSTOM"?
                          <SelectBeneficiaryBank
                            // readOnly={true}
                            defaultOptions
                            id="_formSelectBank"
                            ref="selectBeneficiaryBank"
                            value={this.state._bankNameRecipient? this.state._bankNameRecipient.toUpperCase(): null}
                            placeholder="Type and select a bank..."
                            noResultsText="Cannot find rack."
                            loadOptions={this._getBankOptions}
                            onSelectSearch={(input)=>this.setState({_recentSearchBank: input})}
                            onSelectChange={this._handleSelectBank}
                            onSelectOpen={this._handleOnSelectBankOpen}
                          />
                          :
                          <Input
                            // readOnly={true}
                            name="_bankNameRecipient"
                            onChange={this._handleTextChange}
                            value={textValue("_bankNameRecipient", this.state).toUpperCase()}
                            placeholder="Bank name..."
                          />
                        }
                        {errorMessage('_bankNameRecipient', this.state._errorForm)}
                      </FormGroup>
                    </Col>
                    <Col xs={12} md={6}>
                      <FormGroup className={classNames({'has-danger has-feedback':hasError('_accountNumberRecipient', this.state._errorForm)})}>
                        <Label>Account Number</Label>
                        <Input
                          readOnly={true}
                          name="_accountNumberRecipient"
                          onChange={this._handleTextChange}
                          value={textValue("_accountNumberRecipient", this.state)}
                          placeholder="Account number..."
                        />
                        {errorMessage('_accountNumberRecipient', this.state._errorForm)}
                      </FormGroup>
                    </Col>
                    <Col xs={12} md={6}>
                      <FormGroup className={classNames({'has-danger has-feedback':hasError('_branchRecipient', this.state._errorForm)})}>
                        <Label>Branch</Label>
                        <Input
                          readOnly={true}
                          name="_branchRecipient"
                          onChange={this._handleTextChange}
                          placeholder="Branch..."
                          value={textValue("_branchRecipient", this.state)}
                        />
                        {errorMessage('_branchRecipient', this.state._errorForm)}
                      </FormGroup>
                    </Col>
                    <Col xs={12} md={12}>
                      <FormGroup className={classNames({'has-danger has-feedback':hasError('_accountNameRecipient', this.state._errorForm)})}>
                        <Label>Account Name</Label>
                        <Input
                          readOnly={true}
                          name="_accountNameRecipient"
                          onChange={this._handleTextChange}
                          value={textValue("_accountNameRecipient", this.state)}
                          placeholder="Account name..."
                        />
                        {errorMessage('_accountNameRecipient', this.state._errorForm)}
                      </FormGroup>
                    </Col>
                  </div>
                  <Col xs={12} md={6}>
                    <FormGroup className={classNames({'has-danger has-feedback':hasError('_totalAmount', this.state._errorForm)})}>
                      <Label>Total Amount</Label>
                      <Input
                        name="_totalAmount"
                        onChange={this._handleNumericTextChange}
                        value={textValue("_totalAmount", this.state)}
                        placeholder="Total Amount"
                      />
                      {errorMessage('_totalAmount', this.state._errorForm)}
                    </FormGroup>
                  </Col>
                  <Col xs={12} md={6}>
                    <FormGroup className={classNames({'has-danger has-feedback':hasError('_fee', this.state._errorForm)})}>
                      <Label>Fee</Label>
                      <Input
                        name="_fee"
                        disabled={this.state._disbursementType===DISBURSEMENT_BALANCE_IN}
                        onChange={this._handleNumericTextChange}
                        value={textValue("_fee", this.state)}
                        placeholder="Fee"
                        onFocus={this._handleOnFocusFee}
                      />
                      {this.props.dataCashoutFee.settings && this.props.dataCashoutFee.settings[this.state._balanceSource]?(
                        <div style={{fontSize: '.65rem'}}>
                          <span>fee: {this.props.dataCashoutFee.settings[this.state._balanceSource].cashout_fee}%</span>
                          {this.props.dataCashoutFee.settings[this.state._balanceSource].admin_fee? (
                            <>
                              <span className="ml-1 mr-1">+</span>
                              <TextRupiah tag="span" prefix="" value={this.props.dataCashoutFee.settings[this.state._balanceSource].admin_fee}/>
                              {' '}
                            </>
                          ):null}
                          {this.props.dataCashoutFee.settings[this.state._balanceSource].max_fee? (
                            <> 
                            (
                              <span>max fee: </span>
                              <TextRupiah tag="span" prefix="" value={this.props.dataCashoutFee.settings[this.state._balanceSource].max_fee}/>
                            )
                            </>
                          ):null}
                        </div>
                      ):null}
                      {errorMessage('_fee', this.state._errorForm)}
                    </FormGroup>
                  </Col>
                  <Col xs={12}>
                    <FormGroup className={classNames({'has-danger has-feedback':hasError('_note', this.state._errorForm)})}>
                      <Label>Note</Label>
                      <Input
                        name="_note"
                        onChange={this._handleTextChange}
                        value={textValue("_note", this.state)}
                        type="textarea"
                        placeholder="Notes..."
                      />
                      {errorMessage('_note', this.state._errorForm)}
                    </FormGroup>
                  </Col>
                  <Col xs={12} md={6}>
                    <FormGroup className={classNames({'has-danger has-feedback':hasError('_referenceNo', this.state._errorForm)})}>
                      <Label>Reference No</Label>
                      <Input
                        name="_referenceNo"
                        disabled={true}
                        value={textValue("_referenceNo", this.state)}
                      />
                      {errorMessage('_referenceNo', this.state._errorForm)}
                    </FormGroup>
                  </Col>
                  <Col xs={12} md={6}>
                    <FormGroup>
                      <Label>Created</Label>
                      <Input
                        disabled
                        value={textValue("_createdAt", this.state)}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={12} md={6}>
                    <FormGroup>
                      <Label>Updated</Label>
                      <Input
                        disabled
                        value={textValue("_updatedAt", this.state)}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <ButtonGroup>
                  <ButtonLoading
                    isLoading={this.state._isSubmit}
                    disabled={this.state._isSubmit}
                    loadingMessage="Submitting..."
                    color="primary"
                  >
                    Update
                  </ButtonLoading> {' '}
                  <Button
                    color="danger"
                    type="button"
                    onClick={this._handleDeleteDisbursement}
                  >
                    Delete
                  </Button>
                </ButtonGroup>
              </BSForm>
            </CardBody>
          </Card>
        </Col>
        <Col xs={12} md={6}>
          <DisbursementSummaries
            disbursementType={this.state._disbursementType}
            type={this.state._balanceSource}
            balance={parseInt(this.state._userBalance)}
            totalAmount={parseInt(this.state._totalAmount)}
            fee={parseInt(this.state._fee)}
          />
        </Col>
        {this.state._id && (
          <Col xs={12}>
            <DisbursementAuditList 
              id={this.state._id}
              type="disbursement"
              refresh={!this.props.isSubmit}
            />
          </Col>
        )}
      </Row>
    )
  }
}
export default Form;

Form.defaultProps = {
  isSubmit: false
}

Form.propTypes = {
  id: propTypes.string.isRequired,
  isSubmit: propTypes.bool,
  dataDisbursementUser: propTypes.object,
  dataDisbursementUserError: propTypes.object,
  onDeleteDisbursement: propTypes.func,
  onUpdateDisbursement: propTypes.func,
  onRedirect: propTypes.func
}