import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Row,
  Col,
  Card,
  CardBody,
  CardText,
  Collapse
} from 'reactstrap';

import Paginations from '../../../components/Paginations/Paginations';
import PaginationSummaries from '../../../components/Paginations/PaginationSummaries';
import { CardHeaderWithToolbars,Title } from '../../../components/Card/CardHeaderWithToolbars';
import FiltersPanel from '../Filters';
import Toolbars from './Filters/Toolbars';
import FilterContainer from '../../../components/Container/FilterContainer/FilterContainer';
import {Tbl, TblHead, TblBody} from '../../../components/Tbl';
import { generateNumber } from '../../../utils';
import { SORT_BY_OLDER, DEFAULT_PER_PAGE } from '../../../constants/settings';
import { ModalRejectOrder, ModalAcceptOrder } from './Modals';
import { ModalInfoCreditCard } from '../../../components/Modals';
import { getOutstandingSaleDelivery, doAcceptSale, doDeclineSale, doReceiveSneakerSale } from '../../../actions/saleAction';
import RowItem from './RowItem';
import RowHeader from './RowHeader';

const defaultParams = {
  type:'outstanding_delivery',
  sort_by : SORT_BY_OLDER,
  per_page : DEFAULT_PER_PAGE
}

class OutstandingDelivery extends Component{
  constructor(props){
    super(props);
    this.state={
      _params:{
        ...defaultParams
      },
      _selectedRow: null,
      _modalReject:false,
      _modalReceived:false,
      _formRejectSelected:null,
      _formReceivedSelected:null,
      _rejectProcess:false,
      _receivedProcess:false,
      _filterOpen: false,
      _collapse: false,
      _modalCreditCard: false,
      _invoiceNumber: null,
    }
    this._handleApplyFilter = this._handleApplyFilter.bind(this);
    this._handleToggleFilterPanel = this._handleToggleFilterPanel.bind(this);
    this._handleGoto = this._handleGoto.bind(this);
    this._refreshList = this._refreshList.bind(this);
    this._handleRefresh = this._handleRefresh.bind(this);
    this._handleResetFilter = this._handleResetFilter.bind(this);
    this._onCloseRejectModal = this._onCloseRejectModal.bind(this);
    this._onCloseReceivedModal = this._onCloseReceivedModal.bind(this);
    this._handleCategoryChanged = this._handleCategoryChanged.bind(this);
    this._handleToggleCollapse = this._handleToggleCollapse.bind(this);
    this._handleToggleModalCreditCard = this._handleToggleModalCreditCard.bind(this);
  }

  componentDidMount(){
    this._getList(this.state._params);
  }

  _getList(rawParams = {}){
    let p = { ...rawParams }
    if(p.voucher){
      const { voucher, ...other } = p;
      if(Number(voucher.id)){
        p = { ...other, voucher_id: voucher.id }
      }
      else{
        p = { ...other, voucher_code: voucher.code }
      }
    }
    if(p.seller && p.seller.id){
      const { seller, ...other } = p;
      p = { ...other, seller_id: seller.id }
    }
    const params = Object.keys(p)
    .filter(key => p[key]!='')
    .reduce((obj, current)=> ({...obj, [current]: p[current]}), {});
    this.props.getList({params});
  }

  componentDidUpdate(prevProps){
    if(prevProps.sectionName !== this.props.sectionName){
      const { _collapse } = this.state;
      if(_collapse && this.props.sectionName !== 'outstanding_delivery'){
        this.setState({ _collapse: !_collapse });
      }
    }
    if(this.state._receivedProcess
      &&!this.props.sale.isSubmit
      &&prevProps.sale.isSubmit!==this.props.sale.isSubmit){
      if(this.props.sale.detail.status_code===200){
        this._getList(this.state._params);
        this.setState({
          _receivedProcess:!this.state._receivedProcess,
          _modalReceived:false,
          _selectedRow: null,
          _formRejectSelected: null
        });
      }
      else{
        this.setState({_receivedProcess:!this.state._receivedProcess})
      }
    }
   else if(this.state._rejectProcess
      &&!this.props.sale.isSubmit
      &&prevProps.sale.isSubmit!==this.props.sale.isSubmit) {
      if(this.props.sale.detail.status_code===200){
        this._getList(this.state._params);
        this.setState({
          _rejectProcess:!this.state._rejectProcess,
          _modalReject:false,
          _selectedRow: null,
          _formRejectSelected: null
        });
      }
      else{
        this.setState({_rejectProcess:!this.state._rejectProcess});
      }
    }
  }

  _refreshList(){
    // Exclude page...
    const { page, ..._params } = this.state._params;
    this.setState({
      _params:{ ..._params }
    },()=>this._getList(this.state._params));
  }

  _toggleFilterPanel(){
    this.setState({_filterOpen:!this.state._filterOpen})
  }

  _handleToggleFilterPanel(e){
    e.preventDefault();
    this._toggleFilterPanel();
    if(!this.state._collapse)
      this._handleToggleCollapse(e);
  }

  _handleRefresh(e){
    e.preventDefault();
    this._refreshList();
    if(!this.state._collapse)
      this._handleToggleCollapse(e);
  }

  _handleResetFilter(e){
    e.preventDefault();
    const newState = this.state._filterOpen?{_filterOpen:!this.state._filterOpen}:{};
    this.setState({
      ...newState,
      _params:{ ...defaultParams },
      _isResetFilter: true
    },()=>this._getList(this.state._params));
  }

  _handleApplyFilter(payloadFilter){
    this.setState({
      _params: {
        ...this.state._params,
        ...payloadFilter,
        page: 1
      }
    },()=>this._getList(this.state._params));
  }

  _handleToggleCollapse(e) {
    e.preventDefault();
    this.setState({_collapse: !this.state._collapse});
    if(!this.state._collapse)
      this.props.onChangeSection();
  }

  _onRenderRowItem(){
    if(this.props.sale.outstanding_delivery.list.status_code===200&&this.props.sale.outstanding_delivery.list.data.length>0) {
      const { current_page,per_page } =  this.props.sale.outstanding_delivery.list;
      return this.props.sale.outstanding_delivery.list.data.map((item,index)=>{
        const rowId = generateNumber(per_page,current_page,index);
        return(
          <RowItem
            key={rowId}
            rowId={rowId}
            data={item}
            id={item.id}
            deliveryTime={true}
            quantity={item.offer.quantity}
            buyerEmail={item.buyer_email}
            invoiceNumber={item.invoice_number}
            orderDate={item.created_at}
            updatedAt={item.updated_at}
            orderStatus={item.status}
            totalAmount={item.total_price}
            displayName={item.sale_product.display_name}
            SKU={item.sale_product.SKU}
            colour={item.sale_product.colour}
            preOrder={item.sale_product.pre_order}
            preVerified={item.sale_product.pre_verified}
            consignmentId={item.sale_product.consignment_id}
            sneakersCondition={item.sale_product.sneakers_condition}
            category={item.sale_product.category_name}
            size={item.sale_product.size.US}
            sellerResponded={item.seller_responded}
            hijacked={item.hijacked}
            autoDisburse={item.autodisburse}
            autoCancel={item.auto_cancelled_at}
            isSelected={this.state._selectedRow===item.id}
            onOpenReceivedModal={()=>this._onOpenReceivedModal(item)}
            onOpenAcceptModal={()=>this._onOpenAcceptModal(item)}
            onOpenRejectModal={()=>this._onOpenRejectModal(item)}
            bank={item.offer.bank_code}
            paymentVia={item.offer.payment_via}
            paymentMethod={item.offer.payment_method}
            onOpenCreditCard={()=>this._onOpenCreditCardModal(item)}
            status={item.status}
            marked={item.marked}
            notes={item.sale_notes}
            loginId={this.props.injectedProps.id}
            // markedNotes={item.marked_notes}
            markedAt={item.open_marked_at}
            markedBy={item.open_marked_by}
            closeMarkedAt={item.close_marked_at}
            unreadNoteCount={item.unread_notes_count}
            rack={item.offer.user_sell.rack}
            voucherCode={item.voucher_code? item.voucher_code: item.voucherable? item.voucherable.code: item.voucherable_id}
          />
        )
      })
    }
    return null;
  }

  _onReject(id, data){
    this.setState({_rejectProcess:true},()=>{
      this.props.declineSale(id, data);
    })
  }

  _onAccept(id, data){
    this.setState({_receivedProcess:true},()=>{
      this.props.receivedSneakerSale(id, data);
    })
  }

  _onOpenReceivedModal(item){
    this.setState({_formReceivedSelected: item, _selectedRow: item.id},()=>this._toggle('_modalReceived'));
  }

  _onOpenRejectModal(item){
    this.setState({_formRejectSelected: item, _selectedRow: item.id},()=>this._toggle('_modalReject'));
  }

  _onCloseReceivedModal(){
    this.setState({_formReceivedSelected: null, _selectedRow: null},()=>this._toggle('_modalReceived'));
  }

  _onCloseRejectModal(){
    this.setState({_formRejectSelected: null, _selectedRow: null},()=>this._toggle('_modalReject'));
  }

  _onOpenCreditCardModal(item){
    this.setState({_invoiceNumber: item.invoice_number},()=>this._handleToggleModalCreditCard());
  }

  _handleToggleModalCreditCard() {
    this._toggle('_modalCreditCard');
  }


  _toggle(_modalState){
    const lastState={...this.state};
    lastState[_modalState]=!lastState[_modalState];
    this.setState(lastState);
  }

  _handleGoto(page){
    const _params=this.state._params;
    _params.page=page;
    this.setState({_params}, ()=>this._getList(this.state._params));
  }

  _onRenderPagination(){
    const { status_code,data } = this.props.sale.outstanding_delivery.list;
    const hasData = (status_code===200 && data.length) || null;
    return hasData && (
      <Paginations
        size="sm"
        lastPage={this.props.sale.outstanding_delivery.list.last_page}
        perPage={this.props.sale.outstanding_delivery.list.per_page}
        total={this.props.sale.outstanding_delivery.list.total}
        maxPage={8}
        path={this.props.sale.outstanding_delivery.list.path}
        currentPage={this.props.sale.outstanding_delivery.list.current_page}
        prevPageUrl={this.props.sale.outstanding_delivery.list.prev_page_url}
        nextPageUrl={this.props.sale.outstanding_delivery.list.next_page_url}
        handleGotoCallback={this._handleGoto}
      />
    );
  }

  async _handleCategoryChanged(category){
    await this.setState({
      _params: {
        ...this.state._params,
        category: category === ''? category: category.value,
        page: 1,
      },
    });
    const { _params } = this.state;
    this._getList(_params);
  }

  render(){
    return (
      <div className="animated fadeIn" ref={this.props.childRef}>
        <Row>
          {/* Reject Modals */}
          <ModalRejectOrder
            isOpen={this.state._modalReject}
            onCancel={this._onCloseRejectModal}
            onSubmit={(id, form)=>this._onReject(id, form)}
            sale={this.state._formRejectSelected}
            error={this.props.sale.error}
            isSubmit={this.state._rejectProcess}
          />
          {/* Received Modals */}
          <ModalAcceptOrder
            receivedBy={this.props.email}
            isOpen={this.state._modalReceived}
            onCancel={this._onCloseReceivedModal}
            onSubmit={(id, form)=>this._onAccept(id, form)}
            sale={this.state._formReceivedSelected}
            error={this.props.sale.error}
            isSubmit={this.state._receivedProcess}
          />
          <ModalInfoCreditCard
            isOpen={this.state._modalCreditCard}
            toggle={this._handleToggleModalCreditCard}
            invoiceNumber={this.state._invoiceNumber}
          />
          <Col xs={12}>
            <Card>
              <CardHeaderWithToolbars>
                <Title>
                  <i className="fa fa-align-justify"></i>
                  <span>Outstanding Sale Deliveries To Kick Avenue</span>
                </Title>
                <Toolbars
                  isOpen={this.state._filterOpen}
                  isCollapse={this.state._collapse}
                  onToggleFilter={this._handleToggleFilterPanel}
                  onRefreshList={this._handleRefresh}
                  onResetFilter={this._handleResetFilter}
                  onToggleCollapse={this._handleToggleCollapse}
                  total={this.props.sale.outstanding_delivery.list.total}
                />
              </CardHeaderWithToolbars>
              <FilterContainer>
                <FiltersPanel
                  {...this.state._params}
                  onApplyFilter={this._handleApplyFilter}
                  isReset={this.state._isResetFilter}
                  onResetFilterCallback={()=>this.setState({_isResetFilter: false, _filterOpen: false})}
                  isOpen={this.state._filterOpen}
                />
              </FilterContainer>
              <CardBody>
                <CardText>
                    Outstanding sale deliveries to kickavenue means we waiting for seller delivery his sale. Please contact seller as soon as possible. Show button cancel after if reach time total of 96 hour since sales order is confirmed.
                </CardText>
                <Collapse isOpen={this.state._collapse && this.props.sectionName === 'outstanding_delivery'}>
                  <div className="table-responsive" >
                    <PaginationSummaries
                      from={this.props.sale.outstanding_delivery.list.from}
                      to={this.props.sale.outstanding_delivery.list.to}
                      total={this.props.sale.outstanding_delivery.list.total}
                    />
                    <Tbl>
                      <TblHead>
                        <RowHeader
                          categoryName={this.state._params.category}
                          categoryValue={this.state._params.category}
                          onCategoryChanged={this._handleCategoryChanged}
                        />
                      </TblHead>
                      <TblBody
                        isLoad={this.props.sale.outstanding_delivery.isFetch}
                        hasRow={this.props.sale.outstanding_delivery.list.status_code===200&&this.props.sale.outstanding_delivery.list.data.length>0}
                        columnCount={7}
                      >
                      { this._onRenderRowItem() }
                      </TblBody>
                    </Tbl>
                  </div>
                  {this._onRenderPagination()}
                </Collapse>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    )
  }
}

const mapStateToProps= ({sale, auth:{ email }}) => {
	return {
    sale,
    email
	}
};

const mapDispatchToProps = (dispatch) => {
	return{
		acceptSale:(id,payload) => dispatch(doAcceptSale(id,payload)),
		declineSale:(id,payload) => dispatch(doDeclineSale(id,payload)),
		receivedSneakerSale:(id,payload) => dispatch(doReceiveSneakerSale(id,payload)),
		getList:(query) => dispatch(getOutstandingSaleDelivery(query))
	}

};
const enhance = connect(mapStateToProps,mapDispatchToProps);
export default enhance(OutstandingDelivery);
