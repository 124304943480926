import React, { Component } from 'react';
import { connect } from 'react-redux';
import privateView from '../../../components/hocs/privateView';
import Analytics from "../../../services/Analytics";
import CourierDelivering from './CourierDelivering';

class OtherDeliveringConsignment extends Component{
  componentDidMount(){
    const { email } = this.props.injectedProps;
    Analytics.recordPageView('other_delivering_consignment', email, {
      url: window.location.href,
      location: this.props.location,
      match: this.props.match
    });
  }


  render(){
    return (
      <div className="animated fadeIn">
        <CourierDelivering
          courier="others"
          title="Other Delivering List"
          {...this.props}
        />
      </div>
    )
  }
}

const mapStateToProps= ({auth: {id: authId, isFetch, isLogged, roles, id, email}}) => {
	return {
    email,
    guardData: {isFetch, isLogged, roles, id: authId, id, email}
	}
};
const enhance = connect(mapStateToProps, null);
export default enhance(privateView(OtherDeliveringConsignment, ['supervisor', 'sale_supervisor', 'courier']));