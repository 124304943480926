import React from 'react';
import propTypes from 'prop-types';
import Paragraph from '../../../../styled/Typograph/Paragraph';
import SmallInline from '../../../../styled/Typograph/Small/SmallInline';

const ProductColumn = props =>{
  const {
    userEmail,
    typedEmail
  } = props;
  return (
    <Paragraph>
      {userEmail}
      <br/>
      <SmallInline>
        typed email: {' '} 
        <br/>
        {typedEmail}
      </SmallInline>
    </Paragraph>
  )
}
export default ProductColumn;

ProductColumn.propTypes = {
  userEmail: propTypes.string,
}

ProductColumn.defaultProps = {
  userEmail: '-',
}