import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import FormCard from './FormCard';
import { getDetailPopUpBanner } from '../../../actions/popUpBannerAction';


class PopUpBannerForm extends React.Component {
  constructor(props){
    super(props);
    this.state={
      _id:this.props.match.params.id,
      detail: null
    }

    this._refreshDetail = this._refreshDetail.bind(this)
  }

  componentDidMount(){
    if(this.state._id)
      this.props.find(this.state._id);
  }

  _refreshDetail(){
    if(this.state._id)
      this.props.find(this.state._id);
  }

  componentDidUpdate(prevProps) {
    if (this.props.popUpBanner.isFetch !== prevProps.popUpBanner.isFetch)
    {
      const { error } = this.props.popUpBanner;
      if (!error) {
        this.setState({
          _id: this.state._id,
          detail: this.props.popUpBanner
        })
      }
    }
  }

  render(){
    return(
      <Fragment>
        {this.state.detail && (
          <>
            <FormCard
              formTitle="Popup Banner Details"
              edit={this.props.edit}
              popUpBanner={this.state.detail}
              {...this.props}
            />
          </>
        )}
      </Fragment>
    )
  }
}
const mapStateToProps= (state) => {
	return {
    popUpBanner: state.popUpBanner
	}
};
const mapDispatchToProps = (dispatch) => {
	return{
    find:(id) => dispatch(getDetailPopUpBanner(id)),
	}
};

export default connect(mapStateToProps,mapDispatchToProps)(PopUpBannerForm);
