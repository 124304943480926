import React, { PureComponent as Component } from 'react';
import { Card, CardHeader, CardBody } from 'reactstrap';
import { _getValue } from '../../utils/form';
import propTypes from 'prop-types';
import Loading from 'react-loading-animation';
import {
	FieldRow,
	FieldColumn,
	FieldValue,
	FieldLabel
} from '../../styled/Fields'

export default class ShippingDetail extends Component{
  render(){
    return(
      <Card>
        <CardHeader>
          {this.props.headerText}
        </CardHeader>
        <CardBody>
          <Loading isLoading={this.props.isLoading}>
            <FieldRow>
              <FieldColumn className="col-12">
                <FieldLabel>Receiver Name</FieldLabel>
                <FieldValue>
                  <span>{_getValue(this.props.shipping.full_name)}</span>
                </FieldValue>
              </FieldColumn>
              <FieldColumn className="col-xs-12 col-md-6">
                <FieldLabel>Phone Number</FieldLabel>
                <FieldValue>
                  <span>{_getValue(this.props.shipping.phone_number)}</span>
                </FieldValue>
              </FieldColumn>
              <FieldColumn className="col-xs-12 col-md-6">
                <FieldLabel>Unit Apartment Number</FieldLabel>
                <FieldValue>
                  <span>{_getValue(this.props.shipping.unit_apartment_number)}</span>
                </FieldValue>
              </FieldColumn>
              <FieldColumn className="col-12">
                <FieldLabel>Address</FieldLabel>
                <FieldValue>
                  <span>{_getValue(this.props.shipping.street_address)}</span>
                </FieldValue>
              </FieldColumn>
              <FieldColumn className="col-xs-12 col-md-4 col-sm-4">
                <FieldLabel>Country</FieldLabel>
                <FieldValue>
                  <span>{_getValue(this.props.shipping.country)}</span>
                </FieldValue>
              </FieldColumn>
              <FieldColumn className="col-xs-12 col-md-4 col-sm-4">
                <FieldLabel>Province</FieldLabel>
                <FieldValue>
                  <span>{_getValue(this.props.shipping.province)}</span>
                </FieldValue>
              </FieldColumn>
              <FieldColumn className="col-xs-12 col-md-4 col-sm-4">
                <FieldLabel>District</FieldLabel>
                <FieldValue>
                  <span>{_getValue(this.props.shipping.district)}</span>
                </FieldValue>
              </FieldColumn>
              <FieldColumn className="col-xs-12 col-sm-6 col-md-6">
                <FieldLabel>Sub District</FieldLabel>
                <FieldValue>
                  <span>{_getValue(this.props.shipping.sub_district)}</span>
                </FieldValue>
              </FieldColumn>
              <FieldColumn className="col-xs-12 col-sm-6 col-md-6">
                <FieldLabel>Postal Code</FieldLabel>
                <FieldValue>
                  <span>{_getValue(this.props.shipping.postal_code)}</span>
                </FieldValue>
              </FieldColumn>
              <FieldColumn className="col-12">
                <FieldLabel>Note</FieldLabel>
                <FieldValue>
                  <span>{_getValue(this.props.shipping.note)}</span>
                </FieldValue>
              </FieldColumn>
            </FieldRow>
          </Loading>
        </CardBody>
      </Card>
    )
  }
}

ShippingDetail.propTypes={
  isLoading: propTypes.bool,
  headerText: propTypes.oneOfType([propTypes.string, propTypes.node]),
  shipping:propTypes.shape({
    id:propTypes.number,
    full_name:propTypes.string,
    phone_number:propTypes.any,
    unit_apartment_number:propTypes.string,
    street_address:propTypes.string,
    country:propTypes.string,
    province:propTypes.string,
    district:propTypes.string,
    sub_district:propTypes.string,
    postal_code:propTypes.string,
    note:propTypes.string,
    updated_at:propTypes.string,
  })
}
ShippingDetail.defaultProps={
  isLoading: false,  
  headerText: "Shipping Details", 
  shipping:{
    id:0,
    full_name:'',
    unit_apartment_number:'',
    street_address:'',
    country:'',
    province:'',
    district:'',
    sub_district:'',
    postal_code:'',
    note:'',
    updated_at:'',
  }
}