import React, { Component } from 'react';
import { connect } from 'react-redux';
import Outstanding from './Outstanding';
import OutstandingSaleConfirmation from './OutstandingSaleConfirmation';
import OutstandingDelivery from './OutstandingDelivery';
import privateView from '../../../components/hocs/privateView';
import Analytics from "../../../services/Analytics";

class OutstandingComponent extends Component {
  constructor(props){
    super(props);
    this.state = {
      sectionName: null
    }
  }
  componentDidMount(){
    const { email } = this.props.injectedProps;
    Analytics.recordPageView('outstanding_sales', email, {
      url: window.location.href,
      location: this.props.location,
      match: this.props.match
    });

  }
  componentDidUpdate(_p, prevState){
    if(prevState.sectionName !== this.state.sectionName){
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    }
  }
  render(){
    // console.log("test: ", this.outstandingConfirmationRef.current)
    return(
      <div>
        <OutstandingSaleConfirmation sectionName={this.state.sectionName} onChangeSection={() => this.setState({sectionName: 'outstanding_confirmation'})}  {...this.props}/>
        <OutstandingDelivery sectionName={this.state.sectionName} onChangeSection={() => this.setState({sectionName: 'outstanding_delivery'})} {...this.props}/>
        <Outstanding sectionName={this.state.sectionName} onChangeSection={() => this.setState({sectionName: 'outstanding'})} {...this.props}/>
      </div>
    )
  }
}

const mapStateToProps= ({auth:{id, email, isFetch, isLogged, roles}}) => {
	return {
    guardData: {id, email, isFetch, isLogged, roles}
	}
};

const enhance = connect(mapStateToProps);
export default enhance(privateView(OutstandingComponent, ['supervisor','sale_supervisor']));
