import api from "../../../../utils/Api";
import { AUTH_TOKEN_KEY, CONSIGNMENT_ROUTE_API } from "../../../../constants";
import { useState } from "react";

export function useFetchConsignment() {
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [isFetching, setIsFetching] = useState(false)

    const fetchConsignment = async (query) => {
        const queryString = Object.keys(query).map(key => key + '=' + query[key]).join('&');
        setLoading(true);

        return api({
            Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
        }).get(`${CONSIGNMENT_ROUTE_API}?${queryString}`)
            .then(res => {
                setLoading(false);
                setData(res.data.data);
                setIsFetching(true)
                return res.data.data;
            })
            .catch(err => {
                setLoading(false);
                setError(err);
                setIsFetching(true)
                throw err;
            })
    };

    return {
        data,
        setData,
        loading,
        error,
        fetchConsignment,
        isFetching
    }
}
