import {
  OFFER_REPORT_ERROR,  
  OFFER_REPORT_CLEAR,  
  OFFER_REPORT,
	GET_LIST_OFFER,
	GET_DETAIL_OFFER,
	OFFER_ERROR,
	UPDATE_OFFER,
	CREATE_OFFER,
	DISBURSEMENT_OFFER,
	FETCHING_OFFER,
	GET_PAYMENTS_MIDTRANS_DETAIL,
	FETCHING_PAYMENTS_MIDTRANS_DETAIL,
	PAYMENTS_MIDTRANS_DETAIL_ERROR
} from '../constants';

/**
 * Initial State
 */
const initialState={
	list:[],
	payments:{},
  detail:{},
  report_offer: {
		status_code: 0,
		message: '',
		error: null
	},
	midtrans:{
		details:{
			data: null,
			isFetch: false,
			error: null
		}
	},
	isFetch:false,
	isSubmit:false,
	error:null
}

/**
 * Function to manipulate payload when success get offer list
 * @author pewe
 * @param {object} lastState 
 * @param {object} payload 
 */
const onGetOfferList = (lastState,payload) => {
	const {data, message, status_code}=payload;
	return { ...lastState, error:null, list:{ ...data, message, status_code } };	
}

/**
 * Function to manipulate payload when success get offer details
 * @author pewe
 * @param {object} lastState 
 * @param {object} payload 
 */
const onGetDetailOffer = (lastState,payload) => {
	const {data, message, status_code}=payload;
	return { ...lastState, error:null, detail:{ ...data, message, status_code } };	
}

/**
 * Function to handle when start or end fetching offer api
 * @author pewe
 * @param {object} lastState 
 * @param {objec} bool 
 */
const onFetchingOffer = (lastState,payload) => {
    if(payload.type==='fetch')
      return { ...lastState, isFetch:payload.status }
    else if(payload.type==='submit')
			return { ...lastState, isSubmit:payload.status }
		else if(payload.type===FETCHING_PAYMENTS_MIDTRANS_DETAIL)
			return { ...lastState, midtrans:{...lastState.midtrans, details:{...lastState.details, isFetch:payload.status}}}
    return { ...lastState, isFetch:false,isSubmit:false }
}

const onReportOffer = (lastState,payload) => {
	const {message, status_code}=payload;
	return { ...lastState, report_offer:{ message, status_code, error: null } };	
}

const onReportOfferError = (lastState,error) => {
	const {message, status_code}=error.error;
	return { ...lastState, report_offer:{ message, status_code, ...error } };
}

/**
 * Function to handle when fetching offer error triggered
 * @author pewe
 * @param {object} lastState 
 * @param {object} error 
 */
const onOfferError = (lastState,error) => {
	return { ...lastState, ...error };		
}

/**
 * Offer Reducer
 * @author pewe
 * @param {object} state 
 * @param {object} action 
 */
const offerReducer= (state=initialState, action) => {
	switch(action.type)
	{
    case OFFER_REPORT_CLEAR : return {
			...state,report_offer:{
				...state.report_offer,status_code:0,message:'',error: null
			}
		}
		case OFFER_REPORT : return onReportOffer(state,action.payload);		
		case OFFER_REPORT_ERROR: return onReportOfferError(state,action.payload);
		case GET_LIST_OFFER: return onGetOfferList(state,action.payload);
		case GET_DETAIL_OFFER: return onGetDetailOffer(state,action.payload);
		case FETCHING_OFFER: return onFetchingOffer(state,action.payload);
		case OFFER_ERROR:return onOfferError(state,action.payload);
		case UPDATE_OFFER:return onGetDetailOffer(state,action.payload);
		case CREATE_OFFER:{
			const {data, message, status_code}=action.payload;
			return { ...state, error:null, payments:{ ...data, message, status_code } };
		};
		case DISBURSEMENT_OFFER:return onGetDetailOffer(state,action.payload);
		case FETCHING_PAYMENTS_MIDTRANS_DETAIL: return onFetchingOffer(state,action.payload);
		case GET_PAYMENTS_MIDTRANS_DETAIL:{
			return { ...state, error:null, midtrans:{ ...state.midtrans, details:{...state.details, error:null, isFetch:false, ...action.payload }}};
		};
		case PAYMENTS_MIDTRANS_DETAIL_ERROR:{
			return { ...state, error:null, midtrans:{ ...state.midtrans, details:{...state.details, isFetch:false, ...action.payload }}};
		};
		default:return state;
	}
}

export default offerReducer;