import React from 'react';
import propTypes from 'prop-types';
import DateToHuman from '../../../../components/DateToHuman';
import { Th } from '../../../../components/Tbl';
import { SimpleLineIcon } from '../../../../components/Icons'

const RowItem = (props) => (
  <tr>
    <Th className="v-center" scope="row">{ props.number }</Th>
    <td className="v-center">
      <p className="mb-0">
        {props.default? <SimpleLineIcon className="mr-1" iconType="star"/>: null}
        <span className="mr-1">ID:{props.id}</span>
        <strong>{props.alias}</strong>
      </p>
      <p className="mb-0">
        <span style={{fontSize: ".75rem"}}>{props.fullName}</span>
      </p>
      <p className="mb-0" style={{fontSize: ".75rem"}}>
        <span>
          <i>{props.address}{props.postalCode? ` - Phone: ${props.phoneNumber}`: '-' }</i>
        </span> <br/>
        <span>
          {
            [props.province, props.district, props.subDistrict, props.country]
            .filter(item => item && item.length > 0)
            .reduce((prev, curr) => {
              return `${prev}${curr}, `
            }, "").trim()
          }
        </span>
      </p>
      <p className="mb-0" style={{fontSize: ".75rem"}}>
        Note: {props.note? props.note: '-'}
      </p>
    </td>
    <td className="v-center">{ <DateToHuman id={`user_shipping_updatedAt_${props.id}`} value={props.updatedAt}/> }</td>
  </tr>
);

export default RowItem;

RowItem.defaultProps = {
  alias: '',
}

RowItem.propTypes = {
  number: propTypes.number.isRequired,
  id: propTypes.number.isRequired,
  alias: propTypes.string,
  address: propTypes.string,
  createdAt: propTypes.string,
  updatedAt: propTypes.string,
  totalAmount: propTypes.oneOfType([propTypes.number, propTypes.string])
}
