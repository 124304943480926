import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
	Alert,
	Col,
  Row,
	Card,
	TabContent,
	TabPane,
	Nav,
  NavItem,
	NavLink } from 'reactstrap';

import classnames from 'classnames';
import qs from 'query-string';

import { getDetailOffer,updateOffer } from '../../../actions/offerAction';
import Header from './paymentDetail/Header';
import TabPaymentContent from './TabPaymentDetail';
import TabUserContent from './TabUserDetail';
import TabSellerContent from './TabSellerDetail';
import TabProductContent from './TabProductDetail';
import TabPaymentConfirmationContent from './TabPaymentConfirmation';
import privateView from '../../../components/hocs/privateView';
import Analytics from "../../../services/Analytics";
import { ModalInfoCreditCard } from '../../../components/Modals';

const DEFAULT_STATUS_COLOR = 'default';
const DEFAULT_ERROR_500_MSG = 'We got something errors. Please ask our staff!';
const DEFAULT_ERROR_404_MSG = 'Sorry, cannot find what you are looking for.';

/**
 * Component for when user navigate to /offers/:id
 * @author pewe
 */
class PaymentDetail extends Component {
	constructor(props) {
		super(props);
    const DEFAULT_STATE = {
      _id: parseInt(this.props.match.params.id),
      activeTab:'paymentDetail'
    }
    const queries = Object.assign(DEFAULT_STATE,qs.parse(props.location.search));
		this.state={
      ...queries,
      _modalCreditCard: false,
      _statusColor: DEFAULT_STATUS_COLOR,
      _errorAfterFetched: false,
      _errorMsg: '',
      _error: null
		}
		this.toggleTab = this.toggleTab.bind(this);
	}

	componentDidMount(){
    const { email } = this.props.injectedProps;
		this.props.getDetail(this.state._id)
    Analytics.recordPageView('payment_detail', email, {
      url: window.location.href,
      location: this.props.location,
      match: this.props.match
    });
  }

	toggleTab(tab) {
		if (this.state.activeTab !== tab) {
			this.setState({
        activeTab: tab
      });
      const { pathname } = this.props.location;
      const search = qs.stringify({
        activeTab: tab
      });
      this.props.history.replace({ pathname, search })
		}
  }

  componentWillReceiveProps(nextProps){
    if(!nextProps.offer.isFetch && nextProps.offer.isFetch !== this.props.offer.isFetch){
      if(nextProps.offer.error){
        let msg = DEFAULT_ERROR_404_MSG;
        switch(nextProps.offer.error.status_code){
          case 500:
            msg = DEFAULT_ERROR_500_MSG; break;
          case 404:
            msg = DEFAULT_ERROR_404_MSG; break;
          default:
            msg = nextProps.offer.error.message;
        }
        this.setState({
          _error: nextProps.offer.error,
          _errorAfterFetched: true,
          _errorMsg: msg
        })
      }
      else{
        const { status, expired } = nextProps.offer.detail;
        let _statusColor = DEFAULT_STATUS_COLOR;
        switch(status){
          case 'PENDING':{
            if(expired)
              _statusColor = 'danger';
            break;
          }
          case 'VERIFYING_PAYMENT':
          case 'PENDING_REFUND':
            _statusColor = 'warning'; break;
          case 'COMPLETED':
            _statusColor = 'success'; break;
          case 'REJECTED':
          case 'EXPIRED':
          case 'CANCELLED':
          case 'REFUNDED':
            _statusColor = 'danger'; break;
        }
        this.setState({_statusColor});
      }
    }
  }

  _renderSneakerDetailTab(){
    const { isFetch, detail:{ user_sell } } = this.props.offer;
    return (
      <TabProductContent
        isLoading={isFetch}
        userSell={user_sell}
      />
    )
  }

  _renderPaymentDetailTab(){
    const { isFetch, isSubmit, list, ...paymentDetails } = this.props.offer;
    const email = this.props.guardData;
    return (
      <TabPaymentContent
        id={this.state._id}
        isLoading={isFetch}
        isSubmit={isSubmit}
        dataPayment={paymentDetails}
        onUpdatePayment={(id, dataForm)=>this.props.updateDetail(id, dataForm)}
        email={email}
        onOpenCreditCard={this._handleToggleModalCreditCard}
      />
    )

  }

  _handleToggleModalCreditCard = () => {
    this.setState({ _modalCreditCard: !this.state._modalCreditCard });
  }

	/**
	 * Render views
	 * @author pewe
	 */
	render(){
    const { shipping, user_sell, ...paymentDetails } = this.props.offer.detail;
    return (
      <Row className="animated fadeIn">
        <ModalInfoCreditCard
          isOpen={this.state._modalCreditCard}
          toggle={this._handleToggleModalCreditCard}
          invoiceNumber={paymentDetails.invoice_number||null}
        />
        <Col xs={12} style={{padding:0}}>
        {
          this.state._errorAfterFetched?
          <Card body>
            <Alert
              color="danger"
              className="text-center"
              isOpen={this.state._errorAfterFetched}
            >
              <strong>Oppps. </strong>{this.state._errorMsg}
            </Alert>
          </Card>
          :

          <Card body>
            <Header
              paymentId={paymentDetails.id}
              isLoading={this.props.offer.isFetch}
              fullName={shipping === null || shipping === undefined ? undefined : shipping.full_name }
              invoiceNumber={paymentDetails.invoice_number}
              email={shipping === null || shipping === undefined ? undefined : shipping.user.email}
              totalAmount={parseInt(paymentDetails.total_amount)}
              walletUsage={parseInt(paymentDetails.wallet_usage)}
              updatedAt={paymentDetails.updated_at}
              createdAt={paymentDetails.created_at}
              userSell={user_sell}
              statusColor={this.state._statusColor}
              status={paymentDetails.status}
              expired={paymentDetails.expired}
              paymentType={paymentDetails.payment_method}
            />
            <Nav className="nav-tabs--custom" tabs>
              <NavItem>
                <NavLink
                  className={classnames({ active: this.state.activeTab === 'paymentDetail' })}
                  onClick={() => { this.toggleTab('paymentDetail'); }}
                >
                  Payment Details
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: this.state.activeTab === 'paymentConfirmations' })}
                  onClick={() => { this.toggleTab('paymentConfirmations'); }}
                >
                  Payment Confirmations
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: this.state.activeTab === 'productDetail' })}
                  onClick={() => { this.toggleTab('productDetail'); }}
                >
                  Product Details
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: this.state.activeTab === 'userDetail' })}
                  onClick={() => { this.toggleTab('userDetail'); }}
                >
                  User & Shipping
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: this.state.activeTab === 'sellerDetail' })}
                  onClick={() => { this.toggleTab('sellerDetail'); }}
                >
                  Seller
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent className="tab-content--custom" activeTab={this.state.activeTab}>
              <TabPane className="tab-content--custom__tab-pane" tabId="paymentDetail">
                { this._renderPaymentDetailTab() }
              </TabPane>
              <TabPane className="tab-content--custom__tab-pane" tabId="paymentConfirmations">
                <TabPaymentConfirmationContent
                  isLoading={this.props.offer.isFetch}
                  data={paymentDetails&&paymentDetails.payment_bank_transfer}
                />
              </TabPane>
              <TabPane className="tab-content--custom__tab-pane" tabId="productDetail">
                { this._renderSneakerDetailTab() }
              </TabPane>
              <TabPane className="tab-content--custom__tab-pane" tabId="userDetail">
                <TabUserContent
                  isLoading={this.props.offer.isFetch}
                  shipping={shipping}
                />
              </TabPane>
              <TabPane className="tab-content--custom__tab-pane" tabId="sellerDetail">
                <TabSellerContent
                  isLoading={this.props.offer.isFetch}
                  seller={user_sell?user_sell.user:undefined}
                />
              </TabPane>
            </TabContent>
          </Card>
        }
        </Col>
      </Row>
    )
	}
}

const mapStateToProps = ({offer, auth: { isFetch, isLogged, roles, id, email }}) =>{
	return {
		offer,
		guardData: { isFetch, isLogged, roles, id, email }
	}
}

const mapDispatchToProps = (dispatch) =>{
	return {
		getDetail : (id) => dispatch(getDetailOffer(id)),
		updateDetail : (id,payload) => dispatch(updateOffer(id,payload)),
	}
}

const enhance = connect(mapStateToProps,mapDispatchToProps);
export default enhance(privateView(PaymentDetail, ['supervisor', 'customer_service', 'cashier']));
