import React from 'react';
import propTypes from 'prop-types';
import Paragraph from '../../../../styled/Typograph/Paragraph';
import BadgeThin from '../../../../components/Badges/BadgeThin';

const BrandColumn = props =>(
  <div>
    <Paragraph>
      {
        props.brand&&
        props.brand.flat.map((item, index)=><BadgeThin color="primary" key={`brand_${props.id}_${index}`} style={{marginRight:5}}>{item}</BadgeThin>)
      }
    </Paragraph>
  </div>
)
export default BrandColumn;

BrandColumn.propTypes = {
  id: propTypes.number.isRequired,
  brand: propTypes.shape({
    id: propTypes.number.isRequired,
    flat: propTypes.arrayOf(propTypes.string)
  })
}

BrandColumn.defaultProps = {
  brand: {
    flat: []
  }
}