import {
	PRODUCT_VARIANT_ROUTE_API,
	GET_LIST_PRODUCT_VARIANT,
	FETCHING_PRODUCT_VARIANT,
	GET_DETAIL_PRODUCT_VARIANT,
	UPDATE_PRODUCT_VARIANT,
    PRODUCT_VARIANT_ERROR,
    ADD_PRODUCT_VARIANT,
    NEW_PRODUCT_VARIANT,
    DELETE_PRODUCT_VARIANT,
	AUTH_TOKEN_KEY,
	ATTACH_COLLECTION_PRODUCT_VARIANT,
	DETACH_COLLECTION_PRODUCT_VARIANT,
	ADD_IMAGE_PRODUCT_VARIANT,
	DELETE_IMAGE_PRODUCT_VARIANT,
	ATTACH_SIZE_PRODUCT_VARIANT,
	DETACH_SIZE_PRODUCT_VARIANT,
	GET_DETAIL_PRODUCT_VARIANT_STOCK_SIZE,
	SAVE_VARIANT_COURIER,
	SAVE_VARIANT_PAYMENT_METHOD,
	ATTACH_VOUCHER_PRODUCT_VARIANT,
	DETACH_VOUCHER_PRODUCT_VARIANT,
	SUBSIDY_ROUTE_API,
	ADD_SUBSIDY,
	UPDATE_SUBSIDY,
	GET_SUBSIDY,
	DELETE_SUBSIDY,
	SUBSIDY_ERROR,
	REORDER_VARIANT_IMG_SUBMIT,
	REORDER_VARIANT_IMG,
	COMMISSION_ROUTE_API,
	ADD_COMMISSION,
	UPDATE_COMMISSION,
	DELETE_COMMISSION,
	COMMISSION_ERROR
} from '../constants';

import { logout } from './authAction';

import api from '../utils/Api';
import { manipulateErrors, checkAuthorized } from '../utils';

/**
 * Get list of offers
 * @author pewe
 * @guards jwt-token 
 * @param {object?} query 
 */
export const getProductVariantList=(query)=>{
	return dispatch =>(
		dispatch(isFetch({type:'fetch',status:true})),
		api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).get(PRODUCT_VARIANT_ROUTE_API,query)
		.then(res=>{
			dispatch(onSuccess(res.data,GET_LIST_PRODUCT_VARIANT))
			dispatch(isFetch({type:'fetch',status:false}))
		})
		.catch(err => {
			dispatch(onError(err));
			dispatch(isFetch({type:'fetch',status:false}));
		})
	)
}

/**
 * Get detail of Offer
 * @guards jwt-token
 * @param {integer} id 
 */
export const getDetailProductVariant=(id)=>{
	return dispatch => (
		dispatch(isFetch({type:'fetch',status:true})),
		api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).get(`${PRODUCT_VARIANT_ROUTE_API}/${id}`)
		.then(res=>{
			dispatch(onSuccess(res.data,GET_DETAIL_PRODUCT_VARIANT))
			dispatch(isFetch({type:'fetch',status:false}))
		})
		.catch(err => {
			dispatch(onError(err));
			dispatch(isFetch({type:'fetch',status:false}));
		})
	)
}

/**
 * Get detail of Offer
 * @guards jwt-token
 * @param {integer} id 
 */
export const getDetailProductVariantStock=(id)=>{
	return dispatch =>(
		dispatch(isFetch({type:'fetch',status:true})),
		api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).get(`${PRODUCT_VARIANT_ROUTE_API}/${id}/sizes`)
		.then(res=>{
			dispatch(onSuccess(res.data,GET_DETAIL_PRODUCT_VARIANT_STOCK_SIZE))
			dispatch(isFetch({type:'fetch',status:false}))
		})
		.catch(err => {
			dispatch(onError(err));
			dispatch(isFetch({type:'fetch',status:false}));
		})
	)
}
export const saveProductVariant = (payload,id) =>{
	return dispatch =>{
		if(id===undefined)
			dispatch(createProductVariant(payload));
		else 
			dispatch(updateProductVariant(id,payload));

	}
}
export const createProductVariant=(payload)=>{
	return dispatch =>(
		dispatch(isFetch({type:'submit',status:true})),
				api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).post(`${PRODUCT_VARIANT_ROUTE_API}`,payload)
		.then(res=>{
			dispatch(onSuccess(res.data,ADD_PRODUCT_VARIANT))
			dispatch(isFetch({type:'submit',status:false}))
		})
		.catch(err => {
			dispatch(onError(err));
			dispatch(isFetch({type:'submit',status:false}));
		})
	)
}

/**
 * Update an offer
 * @author pewe
 * @guards jwt-token
 * @param {integer} id 
 * @param {object} payload 
 */
export const updateProductVariant = (id,payload) =>{
	return dispatch => (
		dispatch(isFetch({type:'submit',status:true})),
		api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).put(`${PRODUCT_VARIANT_ROUTE_API}/${id}`,payload)
		.then(res=>{
			dispatch(onSuccess(res.data,UPDATE_PRODUCT_VARIANT))
			dispatch(isFetch({type:'submit',status:false}))
		})
		.catch(err => {
			dispatch(onError(err, id));
			dispatch(isFetch({type:'submit',status:false}));
		})
	)
}

export const deleteProductVariant = (id) =>{
	return dispatch => (
		dispatch(isFetch({type:'submit',status:true})),
		api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).delete(`${PRODUCT_VARIANT_ROUTE_API}/${id}`)
		.then(res=>{
			dispatch(onSuccess(res.data,DELETE_PRODUCT_VARIANT))
			dispatch(isFetch({type:'submit',status:false}))
		})
		.catch(err => {
			dispatch(onError(err));
			dispatch(isFetch({type:'submit',status:false}));
		})
	)
}

export const saveVariantCourier = (variantId, payload) =>{
	return dispatch => (
		dispatch(isFetch({type:'submit',status:true})),
		api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).post(`${PRODUCT_VARIANT_ROUTE_API}/couriers/${variantId}`,payload)
		.then(res=>{
			dispatch(onSuccess(res.data,SAVE_VARIANT_COURIER))
			dispatch(isFetch({type:'submit',status:false}))
		})
		.catch(err => {
			dispatch(onError(err));
			dispatch(isFetch({type:'submit',status:false}));
		})
	)
}

export const saveVariantPaymentMethod = (variantId, payload) =>{
	return dispatch => (
		dispatch(isFetch({type:'submit',status:true})),
		api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).post(`${PRODUCT_VARIANT_ROUTE_API}/payment_methods/${variantId}`,payload)
		.then(res=>{
			dispatch(onSuccess(res.data,SAVE_VARIANT_PAYMENT_METHOD))
			dispatch(isFetch({type:'submit',status:false}))
		})
		.catch(err => {
			dispatch(onError(err));
			dispatch(isFetch({type:'submit',status:false}));
		})
	)
}

/**
 * Indicates when start fetch api or end fetch api
 * @author pewe
 * @param {boolean} bool 
 */
export const isFetch=(payload)=>(
	{
		type:FETCHING_PRODUCT_VARIANT,
		payload:payload		
	}
)

/**
 * When onSuccess triggered
 * @author pewe
 * @param {object} res 
 * @param {string} type 
 */
export const onSuccess=(res,type)=>
(
	{
		type:type,
		payload:res		
	}
)
/**
 * When error fetch or connect to api
 * @author pewe
 * @param {object} err 
 */
// On Error fetching api
export const onError=(err, id, type=PRODUCT_VARIANT_ERROR)=>{
	if(!checkAuthorized(manipulateErrors(err)))
		return logout();
	return {
		type:type,
		payload:{ ...manipulateErrors(err), id}
	}
}

export const newProductVariant=()=>{
    return {
        type:NEW_PRODUCT_VARIANT
    }
}

export const attachCollection=(id,payload)=>{
	return dispatch => (
		dispatch(isFetch({type:'submit',status:true})),
		api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).post(`${PRODUCT_VARIANT_ROUTE_API}/collections/${id}`,payload)
		.then(res=>{
			dispatch(onSuccess(res.data,ATTACH_COLLECTION_PRODUCT_VARIANT))
			dispatch(isFetch({type:'submit',status:false}))
		})
		.catch(err => {
			dispatch(onError(err));
			dispatch(isFetch({type:'submit',status:false}));
		})
	)
}

export const detachCollection=(id,payload)=>{
	return dispatch => (
		dispatch(isFetch({type:'submit',status:true})),
		api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).put(`${PRODUCT_VARIANT_ROUTE_API}/collections/${id}`,payload)
		.then(res=>{
			dispatch(onSuccess(res.data,DETACH_COLLECTION_PRODUCT_VARIANT))
			dispatch(isFetch({type:'submit',status:false}))
		})
		.catch(err => {
			dispatch(onError(err));
			dispatch(isFetch({type:'submit',status:false}));
		})
	)
}

export const addImage=(identifier,payload)=>{
	return dispatch => (
		dispatch(isFetch({type:'submit_image',status:true})),
		api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).post(`${PRODUCT_VARIANT_ROUTE_API}/images`,payload)
		.then(res=>{
			dispatch(onSuccess({ ...res.data,identifier },ADD_IMAGE_PRODUCT_VARIANT))
			dispatch(isFetch({type:'submit_image',status:false}))
		})
		.catch(err => {
			dispatch(onError(err));
			dispatch(isFetch({type:'submit_image',status:false}));
		})
	)
}

export const deleteImage=(identifier,id)=>{
	return dispatch => (
		dispatch(isFetch({type:'delete_image',status:true})),
		api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).delete(`${PRODUCT_VARIANT_ROUTE_API}/images/${id}`)
		.then(res=>{
			dispatch(onSuccess({ identifier },DELETE_IMAGE_PRODUCT_VARIANT))
			dispatch(isFetch({type:'delete_image',status:false}))
		})
		.catch(err => {
			dispatch(onError(err));
			dispatch(isFetch({type:'delete_image',status:false}));
		})
	)
}

export const attachSize=(id,payload)=>{
	return dispatch => (
		dispatch(isFetch({type:'submit',status:true})),
		api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).post(`${PRODUCT_VARIANT_ROUTE_API}/sizes/${id}`,payload)
		.then(res=>{
			dispatch(onSuccess(res.data,ATTACH_SIZE_PRODUCT_VARIANT))
			dispatch(isFetch({type:'submit',status:false}))
		})
		.catch(err => {
			dispatch(onError(err));
			dispatch(isFetch({type:'submit',status:false}));
		})
	)
}

export const detachSize=(id,payload)=>{
	return dispatch => (
		dispatch(isFetch({type:'submit',status:true})),
		api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).put(`${PRODUCT_VARIANT_ROUTE_API}/sizes/${id}`,payload)
		.then(res=>{
			dispatch(onSuccess(res.data,DETACH_SIZE_PRODUCT_VARIANT))
			dispatch(isFetch({type:'submit',status:false}))
		})
		.catch(err => {
			dispatch(onError(err));
			dispatch(isFetch({type:'submit',status:false}));
		})
	)
}

export const attachVoucher=(id,payload)=>{
	return dispatch => (
		dispatch(isFetch({type:'submit',status:true})),
		api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).post(`${PRODUCT_VARIANT_ROUTE_API}/vouchers/${id}`,payload)
		.then(res=>{
			dispatch(onSuccess(res.data,ATTACH_VOUCHER_PRODUCT_VARIANT))
			dispatch(isFetch({type:'submit',status:false}))
		})
		.catch(err => {
			dispatch(onError(err));
			dispatch(isFetch({type:'submit',status:false}));
		})
	)
}

export const detachVoucher=(id,payload)=>{
	return dispatch => (
		dispatch(isFetch({type:'submit',status:true})),
		api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).put(`${PRODUCT_VARIANT_ROUTE_API}/vouchers/${id}`,payload)
		.then(res=>{
			dispatch(onSuccess(res.data,DETACH_VOUCHER_PRODUCT_VARIANT))
			dispatch(isFetch({type:'submit',status:false}))
		})
		.catch(err => {
			dispatch(onError(err));
			dispatch(isFetch({type:'submit',status:false}));
		})
	)
}

export const saveSubsidy = (payload,id) =>{
	return dispatch =>{
		if(id===undefined||id===null)
			dispatch(createSubsidy(payload));
		else 
			dispatch(updateSubsidy(id,payload));
	}
}

export const createSubsidy=(payload)=>{
	return dispatch =>(
		dispatch(isFetch({type:'submit_subsidy',status:true})),
		api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).post(`${SUBSIDY_ROUTE_API}`,payload)
		.then(res=>{
			dispatch(onSuccess(res.data,ADD_SUBSIDY))
			dispatch(isFetch({type:'submit_subsidy',status:false}))
		})
		.catch(err => {
			dispatch(onError(err, null, SUBSIDY_ERROR));
			dispatch(isFetch({type:'submit_subsidy',status:false}));
		})
	)
}

export const updateSubsidy = (id,payload) =>{
	return dispatch => (
		dispatch(isFetch({type:'submit_subsidy',status:true})),
		api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).put(`${SUBSIDY_ROUTE_API}/${id}`,payload)
		.then(res=>{
			dispatch(onSuccess(res.data,UPDATE_SUBSIDY))
			dispatch(isFetch({type:'submit_subsidy',status:false}))
		})
		.catch(err => {
			dispatch(onError(err, id, SUBSIDY_ERROR));
			dispatch(isFetch({type:'submit_subsidy',status:false}));
		})
	)
}

export const deleteSubsidy = (id) =>{
	return dispatch => (
		dispatch(isFetch({type:'submit_subsidy',status:true})),
		api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).delete(`${SUBSIDY_ROUTE_API}/${id}`)
		.then(res=>{
			dispatch(onSuccess(res.data,DELETE_SUBSIDY))
			dispatch(isFetch({type:'submit_subsidy',status:false}))
		})
		.catch(err => {
			dispatch(onError(err, id, SUBSIDY_ERROR));
			dispatch(isFetch({type:'submit_subsidy',status:false}));
		})
	)
}

export const getSubsidyList=(query)=>{
	return dispatch =>(
		dispatch(isFetch({type:'fetch_subsidy',status:true})),
		api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).get(SUBSIDY_ROUTE_API,query)
		.then(res=>{
			dispatch(onSuccess(res.data,GET_SUBSIDY))
			dispatch(isFetch({type:'fetch_subsidy',status:false}))
		})
		.catch(err => {
			dispatch(onError(err, null, SUBSIDY_ERROR));
			dispatch(isFetch({type:'fetch_subsidy',status:false}));
		})
	)
}
export const updateImagePositions=(payloads)=>{
	const { images } = payloads
	const manyImages = images && images.length? true: false
	return dispatch =>{
		dispatch({ type: REORDER_VARIANT_IMG_SUBMIT, payload: { isSubmit: true, manyImages } });
		api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).post(`${PRODUCT_VARIANT_ROUTE_API}/images/positions`, payloads)
		.then(res=>{
			dispatch(onSuccess(res.data, REORDER_VARIANT_IMG))
		})
		.catch(err => {
			dispatch(onError(err, null, REORDER_VARIANT_IMG));
		});
	}
}

export const saveCommission = (payload,id) =>{
	return dispatch =>{
		if(!payload.update)
			dispatch(createCommission(payload.variant_id,payload));
		else 
			dispatch(updateCommssion(id,payload));
	}
}

export const createCommission=(id, payload)=>{
	return dispatch =>(
		dispatch(isFetch({type:'submit_commission',status:true})),
		api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).post(`${COMMISSION_ROUTE_API}/${id}`,payload)
		.then(res=>{
			dispatch(onSuccess(res.data,ADD_COMMISSION))
			dispatch(isFetch({type:'submit_commission',status:false}))
		})
		.catch(err => {
			dispatch(onError(err, null, COMMISSION_ERROR));
			dispatch(isFetch({type:'submit_commission',status:false}));
		})
	)
}

export const updateCommssion = (id,payload) =>{
	return dispatch => (
		dispatch(isFetch({type:'submit_commission',status:true})),
		api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).put(`${COMMISSION_ROUTE_API}/${id}`,payload)
		.then(res=>{
			dispatch(onSuccess(res.data,UPDATE_COMMISSION))
			dispatch(isFetch({type:'submit_commission',status:false}))
		})
		.catch(err => {
			dispatch(onError(err, id, COMMISSION_ERROR));
			dispatch(isFetch({type:'submit_commission',status:false}));
		})
	)
}

export const deleteCommission = (id) =>{
	return dispatch => (
		dispatch(isFetch({type:'submit_commission',status:true})),
		api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).delete(`${COMMISSION_ROUTE_API}/${id}`)
		.then(res=>{
			dispatch(onSuccess(res.data,DELETE_COMMISSION))
			dispatch(isFetch({type:'submit_commission',status:false}))
		})
		.catch(err => {
			dispatch(onError(err, id, COMMISSION_ERROR));
			dispatch(isFetch({type:'submit_commission',status:false}));
		})
	)
}