import {
	SIZE_ROUTE_API,
	GET_LIST_SIZE,
	GET_DETAIL_SIZE,
	FETCHING_SIZE,
  SIZE_ERROR,
	AUTH_TOKEN_KEY,
	GET_GENERAL_SIZE_LIST,
  GENERAL_SIZE_LIST_ERROR,
  GET_LIST_BRAND_SIZE
} from '../constants';

/**
 * Initial State
 */
const initialState={
	generalSizes:{
		list: [],
		options: [],
		error: null
  },
  brandSizeList:[],  
  list:[],
	options:[],
	detail:{},
	isFetch:false,
	isSubmit:false,
	error:null,
	success:null,
}

/**
 * Function to manipulate payload when success get offer list
 * @author pewe
 * @param {object} lastState 
 * @param {object} payload 
 */
const onGetSizeList = (lastState,payload) => {
    const {data, message, status_code}=payload;
    var list={ ...data, message, status_code };
    if(data.data===null||data.data===undefined)
      list={ data, message, status_code };
	return { ...lastState, error:null, success:null,detail:{}, brandSizeList:[], list };	
}

const onGetBrandSizeList = (lastState,payload) => {
  const {data, message, status_code}=payload;
  const brandSizeList={ data, message, status_code};
	return { ...lastState, error:null, success:null,detail:{}, list:[], brandSizeList };	
}

const onGetDetailSize = (lastState,payload) => {
	const {data, message, status_code}=payload;
	return { ...lastState, error:null, success:null, detail:{ ...data, message, status_code } };	
}

/**
 * Function to handle when start or end fetching offer api
 * @author pewe
 * @param {object} lastState 
 * @param {objec} bool 
 */
const onFetchingSize = (lastState,payload) => {
    if(payload.type==='fetch')
        return { ...lastState, isFetch:payload.status }
    else if(payload.type==='submit')
        return { ...lastState, isSubmit:payload.status }

    return { ...lastState, isFetch:false,isSubmit:false }
}

/**
 * Function to handle when fetching offer error triggered
 * @author pewe
 * @param {object} lastState 
 * @param {object} error 
 */
const onSizeError = (lastState,error) => {
	return { ...lastState, ...error, success:null };		
}

const onGetGeneralSizeList = (lastState, payload)=>{
	const { data, message, status_code } = payload;
	const { generalSizes } = lastState;
	return { ...lastState, generalSizes:{...generalSizes, list: data, message, status_code, error:null} }
}
/**
 * Offer Reducer
 * @author pewe
 * @param {object} state 
 * @param {object} action 
 */
const sizeReducer= (state=initialState, action) => {
	switch(action.type)
	{
		case GET_LIST_SIZE: return onGetSizeList(state,action.payload);
		case GET_GENERAL_SIZE_LIST: return onGetGeneralSizeList(state, action.payload);
		case GENERAL_SIZE_LIST_ERROR: return {...state, generalSizes: {...state.generalSizes, error:{...action.payload}}}
		case GET_DETAIL_SIZE: return onGetDetailSize(state,action.payload);
    case FETCHING_SIZE: return onFetchingSize(state,action.payload);
    case GET_LIST_BRAND_SIZE : return onGetBrandSizeList(state,action.payload);
		case SIZE_ERROR:return onSizeError(state,action.payload);
		default:return state;
	}
}

export default sizeReducer;