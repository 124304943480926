import React, { PureComponent as Component } from 'react';
import { Button } from 'reactstrap';
import classNames from 'classnames';
import propTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Toolbars as ToolBarContainer } from '../../../../components/Card/CardHeaderWithToolbars'

export default class Toolbars extends Component {
  render(){
    return(
      <ToolBarContainer>
        <Link 
          to={'/collections/create'} 
          className="btn btn-sm btn-primary with-toolbars__toolbars__btn" 
          title="Create new collection"
          target="_blank"
        >
          <i className="fa fa-plus"></i>
        </Link>
        <Button 
          title="Refresh"
          className="with-toolbars__toolbars__btn"
          size="sm"
          color="info"
          onClick={this.props.onRefreshList}> 
          <i className="fa fa-refresh"></i>
        </Button>
        <Button 
          title="Reset filter"
          className="with-toolbars__toolbars__btn"
          size="sm"
          color="warning"
          onClick={this.props.onResetFilter}> 
          <i className="fa fa-ban"></i>
        </Button>
        <Button 
          title="Search and Filter Data"
          size="sm"
          className={classNames("with-toolbars__toolbars__btn",{"btn-danger":this.props.isOpen})} 
          onClick={this.props.onToggleFilter}> 
          <i className={classNames("fa",{"fa-sliders":!this.props.isOpen, "fa-minus":this.props.isOpen})}></i>
        </Button>
      </ToolBarContainer>
    )
  }
}

Toolbars.propTypes = {
  isOpen: propTypes.bool,
  onToggleFilter: propTypes.func,
  onResetFilter: propTypes.func,
  onRefreshList: propTypes.func
}
Toolbars.defaultProps = {
  isOpen: false
}