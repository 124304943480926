import {
	AUTH_TOKEN_KEY,
	VOUCHER_ROUTE_API,
	VOUCHER_FETCHING,
	VOUCHER_DETAIL_FETCH,
	VOUCHER_LIST_FETCH,
	VOUCHER_ERROR,
	VOUCHER_ADD,
	VOUCHER_UPDATE,
	VOUCHER_DELETE,
	VOUCHER_CLEAR_FORM,
	TOTAL_USAGE_VOUCHER_FETCH,
	TOTAL_USAGE_VOUCHER_DONE,
	TOTAL_USAGE_VOUCHER_FETCH_ERROR,
	VOUCHER_USERS_FETCH,
	VOUCHER_USERS_DONE,
	VOUCHER_USERS_POST,
	VOUCHER_USERS_UPDATE,
	VOUCHER_USERS_DELETE,
	VOUCHER_USERS_ERROR,
	VOUCHER_USERS_FETCH_ERROR,
	VOUCHER_S3,
	VOUCHER_S3_ERROR,
	VOUCHER_IMAGE_REQUEST,
	UPDATE_VOUCHER_IMAGE,
	CREATE_VOUCHER_IMAGE,
	VOUCHER_IMAGE_ERROR,
	DELETE_VOUCHER_IMAGE,
	VOUCHER_CATEGORY_REQUEST,
	VOUCHER_CATEGORY_LIST,
	CREATE_VOUCHER_CATEGORY,
	VOUCHER_CATEGORY_ERROR,
	SEARCH_VOUCHER_ITEMS,
  GET_LIST_VOUCHER_ITEMS,
  GET_LIST_VOUCHER_BRANDS,
  VOUCHER_CHILDS_REQUEST,
  VOUCHER_CHILDS_ERROR,
	SUBMITING_VOUCHER_ITEMS,
	SUBMITING_VOUCHER_BRANDS,
	SAVE_VOUCHER_PAYMENT_METHOD,
} from '../constants';
import api from '../utils/Api';
import { manipulateErrors, checkAuthorized } from '../utils';
import { logout } from './authAction';
import { clientPutObject } from '../utils/AWS';
import {
  onRequest,
  onSuccessRequest,
  onFailedRequest
} from './helpers';
import { apiClient } from '../utils/Api';
import {
  AWS_BUCKET,
  AWS_BUCKET_URL
} from '../config/storageBucket';
import mime from 'mime-types';

const onSuccess=(res,type)=>(
	{
		type:type,
		payload:res		
	}
)
const onError=(err, type=VOUCHER_ERROR)=>{    
	if(!checkAuthorized(manipulateErrors(err)))
        return logout();
    return {
        type,
        payload:manipulateErrors(err)
    }
}
const isFetch=(payload)=>(
    {
        type:VOUCHER_FETCHING,
        payload
    }
)
const isVoucherUserFetch=(payload)=>(
	{
		type:VOUCHER_USERS_FETCH,
		payload
	}
)

export const getListVoucher=(query)=>{
	return dispatch=>(
		dispatch(isFetch({type:'fetch',status:true})),
		api({
			Authorization:'Bearer '+localStorage.getItem(AUTH_TOKEN_KEY)
		}).get(VOUCHER_ROUTE_API,query)
		.then(res => {
			dispatch(onSuccess(res.data,VOUCHER_LIST_FETCH));
			dispatch(isFetch({type:'fetch',status:false}));
		})
		.catch(err => {
			dispatch(onError(err));
			dispatch(isFetch({type:'fetch',status:false}));
		})		
	)
}

export const getDetailVoucher=(id)=>{
	return dispatch=>(
		dispatch(isFetch({type:'fetch',status:true})),
		api({
			Authorization:'Bearer '+localStorage.getItem(AUTH_TOKEN_KEY)
		}).get(`${VOUCHER_ROUTE_API}/${id}`)
		.then(res => {
			dispatch(onSuccess(res.data,VOUCHER_DETAIL_FETCH));
			dispatch(isFetch({type:'fetch',status:false}));
		})
		.catch(err => {
			dispatch(onError(err));
			dispatch(isFetch({type:'fetch',status:false}));
		})		
	)
}

const createVoucher=(payload)=>{
	return dispatch => (
		dispatch(isFetch({type:'submit',status:true})),
        api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).post(`${VOUCHER_ROUTE_API}`,payload)
		.then(res=>{
			dispatch(onSuccess(res.data,VOUCHER_ADD))
			dispatch(isFetch({type:'submit',status:false}))
		})
		.catch(err => {
			dispatch(onError(err));
			dispatch(isFetch({type:'submit',status:false}));
		})
	)
}

const updateVoucher=(id, payload)=>{
	return dispatch => (
		dispatch(isFetch({type:'submit',status:true})),
		api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).put(`${VOUCHER_ROUTE_API}/${id}`,payload)
		.then(res=>{
			dispatch(onSuccess(res.data,VOUCHER_UPDATE))
			dispatch(isFetch({type:'submit',status:false}))
		})
		.catch(err => {
			dispatch(onError(err));
			dispatch(isFetch({type:'submit',status:false}));
		})
	)
}

export const saveUserVoucher = (payload) => {
	const {id,...rest}=payload;
  return dispatch =>{
    if(id===undefined||id===null)
			dispatch(createUserVoucher({...rest}));
		else 
			dispatch(updateUserVoucher(id,{...rest}));
	}
}

const createUserVoucher=(payload)=>{
	return dispatch => (
		dispatch(isVoucherUserFetch({type:'submit',status:true})),
		api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).post(`${VOUCHER_ROUTE_API}/uservouchers`,payload)
		.then(res=>{
			dispatch(onSuccess(res.data,VOUCHER_USERS_POST));
			dispatch(isVoucherUserFetch({type:'submit',status:false}));
		})
		.catch(err => {
			dispatch(onError(err, VOUCHER_USERS_ERROR));
			dispatch(isVoucherUserFetch({type:'submit',status:false}));
		})
	)
}

const updateUserVoucher=(id, payload)=>{
	return dispatch => (
		dispatch(isVoucherUserFetch({type:'submit',status:true})),
		api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).put(`${VOUCHER_ROUTE_API}/uservouchers/${id}`,payload)
		.then(res=>{
			dispatch(onSuccess(res.data,VOUCHER_USERS_UPDATE))
			dispatch(isVoucherUserFetch({type:'submit',status:false}));
		})
		.catch(err => {
			dispatch(onError(err, VOUCHER_USERS_ERROR));
			dispatch(isVoucherUserFetch({type:'submit',status:false}));
		})
	)
}

export const deleteUserVoucher = (id) =>{
	return dispatch => (
		dispatch(isVoucherUserFetch({type:'submit',status:true})),
		api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).delete(`${VOUCHER_ROUTE_API}/uservouchers/${id}`)
		.then(res=>{
			dispatch(onSuccess(res.data,VOUCHER_USERS_DELETE))
			dispatch(isVoucherUserFetch({type:'submit',status:false}))
		})
		.catch(err => {
			dispatch(onError(err, VOUCHER_USERS_ERROR));
			dispatch(isVoucherUserFetch({type:'submit',status:false}));
		})
	)
}

export const saveVoucher = (payload) =>{
	const {id,...rest}=payload;
	return dispatch =>{
		if(id===undefined)
			dispatch(createVoucher({...rest}));
		else 
			dispatch(updateVoucher(id,{...rest}));

	}
}

export const deleteVoucher = (id) =>{
	return dispatch => (
		dispatch(isFetch({type:'submit',status:true})),
		api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).delete(`${VOUCHER_ROUTE_API}/${id}`)
		.then(res=>{
			dispatch(onSuccess(res.data,VOUCHER_DELETE))
			dispatch(isFetch({type:'submit',status:false}))
		})
		.catch(err => {
			dispatch(onError(err));
			dispatch(isFetch({type:'submit',status:false}));
		})
	)
}
export const clearFormVoucher =()=>dispatch=>dispatch({type:VOUCHER_CLEAR_FORM})

export const getTotalUsagesVoucher = (id) => (
	dispatch => (
		dispatch({
			type: TOTAL_USAGE_VOUCHER_FETCH,
			payload: true
		}),
		api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).get(`${VOUCHER_ROUTE_API}/${id}/usages`)
		.then(res=>{
			dispatch(onSuccess(res.data, TOTAL_USAGE_VOUCHER_DONE))
			dispatch({
				type: TOTAL_USAGE_VOUCHER_FETCH,
				payload: false				
			})
		})
		.catch(err => {
			dispatch(onError(err, TOTAL_USAGE_VOUCHER_FETCH_ERROR));
			dispatch({
				type: TOTAL_USAGE_VOUCHER_FETCH,
				payload: false				
			});
		})
	)
)

export const getUserVoucherList = (id, query) =>(
	dispatch => (
		dispatch(isVoucherUserFetch({type:'fetch',status:true})),
		api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).get(`${VOUCHER_ROUTE_API}/${id}/users`, query)
		.then(res=>{
			dispatch(onSuccess({voucherId: id , ...res.data}, VOUCHER_USERS_DONE))
			dispatch(isVoucherUserFetch({type:'fetch',status:false}))
		})
		.catch(err => {
			dispatch(onError(err, VOUCHER_USERS_FETCH_ERROR));
			dispatch(isVoucherUserFetch({type:'fetch',status:false}))
		})
	)
)

export const uploadVoucherImage = (folder, file, customFileName = null) => (
  dispatch => {
    let key = `${folder}${file.identifier}.${mime.extension(file.fileInput.type)}`;
    if(customFileName){
      key = `${folder}${customFileName}`;
    }
    dispatch({
      type: VOUCHER_S3,
      payload: { isPending: true }
    })
    clientPutObject({
      Bucket: AWS_BUCKET,
      Key: key,
      Body: file.fileInput,
      ContentType:'image/jpeg',
      ACL: 'public-read', // your permisions
    }).then(res => {
      const results={
        identifier:file.identifier,
        prefix:AWS_BUCKET_URL,
        folder,
        url:`${AWS_BUCKET_URL}${res.key}`
      }
      dispatch({
        type: VOUCHER_S3,
        payload: { results, isPending: false }
      });
    }).catch(err => {
      dispatch(onError(err, VOUCHER_S3_ERROR));
    })
  }
)

export const saveVoucherImage = (voucherId, payload) => {
  const { id, ...body } = payload;
  return id? updateVoucherImage(voucherId, id, body): createVocuherImage(voucherId, body)
}

const updateVoucherImage = (voucherId, id, payload) => (
  dispatch => {
    dispatch(onRequest(VOUCHER_IMAGE_REQUEST, UPDATE_VOUCHER_IMAGE, true));
    apiClient()
    .put(`${VOUCHER_ROUTE_API}/${voucherId}/images/${id}`, payload)
    .then(res => {
      dispatch(onSuccessRequest(UPDATE_VOUCHER_IMAGE, res.data));
      dispatch(onRequest(VOUCHER_IMAGE_REQUEST, UPDATE_VOUCHER_IMAGE, false));
    })
    .catch(err => {
      dispatch(onFailedRequest(VOUCHER_IMAGE_ERROR, UPDATE_VOUCHER_IMAGE, err, id));
      dispatch(onRequest(VOUCHER_IMAGE_REQUEST, UPDATE_VOUCHER_IMAGE, false));
    });
  }
)

const createVocuherImage = (voucherId, payload) => (
  dispatch => {
    dispatch(onRequest(VOUCHER_IMAGE_REQUEST, CREATE_VOUCHER_IMAGE, true));
    apiClient()
    .post(`${VOUCHER_ROUTE_API}/${voucherId}/images`, payload)
    .then(res => {
      dispatch(onSuccessRequest(CREATE_VOUCHER_IMAGE, res.data));
      dispatch(onRequest(VOUCHER_IMAGE_REQUEST, CREATE_VOUCHER_IMAGE, false));
    })
    .catch(err => {
      dispatch(onFailedRequest(VOUCHER_IMAGE_ERROR, CREATE_VOUCHER_IMAGE, err));
      dispatch(onRequest(VOUCHER_IMAGE_REQUEST, CREATE_VOUCHER_IMAGE, false));
    });
  }
)

export const deleteVoucherImage = (voucherId, id) => (
  dispatch => {
    dispatch(onRequest(VOUCHER_IMAGE_REQUEST, DELETE_VOUCHER_IMAGE, true));
    apiClient()
    .delete(`${VOUCHER_ROUTE_API}/${voucherId}/images/${id}`)
    .then(res => {
      dispatch(onSuccessRequest(DELETE_VOUCHER_IMAGE, res.data));
      dispatch(onRequest(VOUCHER_IMAGE_REQUEST, DELETE_VOUCHER_IMAGE, false));
    })
    .catch(err => {
      dispatch(onFailedRequest(VOUCHER_IMAGE_ERROR, DELETE_VOUCHER_IMAGE, err, id));
      dispatch(onRequest(VOUCHER_IMAGE_REQUEST, DELETE_VOUCHER_IMAGE, false));
    });
  }
)


export const getVoucherCategories = (voucherId) => (
	dispatch => {
    dispatch(onRequest(VOUCHER_CATEGORY_REQUEST, VOUCHER_CATEGORY_LIST, true));
    apiClient()
    .get(`${VOUCHER_ROUTE_API}/${voucherId}/categories`)
    .then(res => {
      dispatch(onSuccessRequest(VOUCHER_CATEGORY_LIST, res.data));
      dispatch(onRequest(VOUCHER_CATEGORY_REQUEST, VOUCHER_CATEGORY_LIST, false));
    })
    .catch(err => {
      dispatch(onFailedRequest(VOUCHER_CATEGORY_ERROR, VOUCHER_CATEGORY_LIST, err, voucherId));
      dispatch(onRequest(VOUCHER_CATEGORY_REQUEST, VOUCHER_CATEGORY_LIST, false));
    });
  }
)

export const saveVoucherCategory = (voucherId, payload) => (
	dispatch => {
    dispatch(onRequest(VOUCHER_CATEGORY_REQUEST, CREATE_VOUCHER_CATEGORY, true));
    apiClient()
    .post(`${VOUCHER_ROUTE_API}/${voucherId}/categories`, payload)
    .then(res => {
      dispatch(onSuccessRequest(CREATE_VOUCHER_CATEGORY, { id: voucherId, ...res.data }));
      dispatch(onRequest(VOUCHER_CATEGORY_REQUEST, CREATE_VOUCHER_CATEGORY, false));
    })
    .catch(err => {
      dispatch(onFailedRequest(VOUCHER_CATEGORY_ERROR, CREATE_VOUCHER_CATEGORY, err, voucherId));
      dispatch(onRequest(VOUCHER_CATEGORY_REQUEST, CREATE_VOUCHER_CATEGORY, false));
    });
  }
)

export const getVoucherItems = (id, voucherType, query) => (
	dispatch => {
		let actionType = GET_LIST_VOUCHER_ITEMS;
		if(query.params.exclude === true){
			actionType = SEARCH_VOUCHER_ITEMS;
		}
		else{
			if(voucherType === 'voucher_brands')
				actionType = GET_LIST_VOUCHER_BRANDS
		}
		dispatch(onRequest(
			VOUCHER_CHILDS_REQUEST,
			actionType,
			true
		))
		api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).get(`${VOUCHER_ROUTE_API}/${id}/${voucherType}`, query)
		.then(res=>{
			dispatch(onSuccess(res.data, actionType))
			dispatch(onRequest(
				VOUCHER_CHILDS_REQUEST,
				actionType,
				false
			))
		})
		.catch(err => {
			dispatch(onFailedRequest(VOUCHER_CHILDS_ERROR, actionType, err, { id, query }));
			dispatch(onRequest(
				VOUCHER_CHILDS_REQUEST,
				actionType,
				false
			))
		})
	}
)

export const saveVoucherItems = (operation, id, voucherType, payload) => (
	dispatch => {
		let actionType=SUBMITING_VOUCHER_ITEMS;
		if(voucherType === 'voucher_brands')
			actionType=SUBMITING_VOUCHER_BRANDS;
		dispatch(onRequest(
			VOUCHER_CHILDS_REQUEST,
			actionType,
			true
		))
		let request = null;
		if(operation === 'attach'){
			request = api({
				Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
			}).post(`${VOUCHER_ROUTE_API}/${id}/${voucherType}`, payload)
		} else{ //detach
			request = api({
				Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
			}).put(`${VOUCHER_ROUTE_API}/${id}/${voucherType}`, payload)
		}
		request
		.then(res=>{
			dispatch(onSuccess(res.data, actionType))
			dispatch(onRequest(
				VOUCHER_CHILDS_REQUEST,
				actionType,
				false
			))
		})
		.catch(err => {
			dispatch(onFailedRequest(VOUCHER_CHILDS_ERROR, actionType, err, id));
			dispatch(onRequest(
				VOUCHER_CHILDS_REQUEST,
				actionType,
				false
			))
		})
	}
)

export const saveVoucherPaymentMethod = (voucherId, payload) =>{
	return dispatch => (
		dispatch(isFetch({type:'submit',status:true})),
		api({
			Authorization:`Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
		}).post(`${VOUCHER_ROUTE_API}/payment_methods/${voucherId}`,payload)
		.then(res=>{
			dispatch(onSuccess(res.data,SAVE_VOUCHER_PAYMENT_METHOD))
			dispatch(isFetch({type:'submit',status:false}))
		})
		.catch(err => {
			dispatch(onError(err));
			dispatch(isFetch({type:'submit',status:false}));
		})
	)
}
