import { balanceSourceEnum } from '../../constants/kickavenue';
import date, { compareDateNow } from '../../helpers/date';
export const PENDING_STATUS = 'NEW';
export const COMPLETED_STATUS = 'COMPLETED';
export const IN_PROGRESS_STATUS = 'IN_PROGRESS';
export const REJECTED_STATUS = 'REJECTED';
export const PAYOUT_MANUAL = 'MANUAL';
export const PAYOUT_MIDTRANS = 'MIDTRANS';
export const DISBURSEMENT_BALANCE_OUT = 'BALANCE_OUT';
export const DISBURSEMENT_BALANCE_IN = 'BALANCE_IN';
export const MODAL_CONFIRM_DELETE_ACTION = 'DELETE_DISBURSEMENT';
export const MODAL_CONFIRM_ACCEPT_ACTION = 'ACCEPT_DISBURSEMENT';
export const MODAL_CONFIRM_REJECT_ACTION = 'REJECT_DISBURSEMENT';
export const DISBURSEMENT_MANUAL = 'MANUAL';
export const DISBURSEMENT_MIDTRANS = 'MIDTRANS';
export const UNVERIFIED_STATUS = 'UNVERIFIED';
 export const EXPIRED_STATUS = 'EXPIRED';
export const KICK_CREDIT = "BALANCE";
export const SELLER_CREDIT = "BALANCE_WITH_FEE";
export const KICK_POINT = "LOCKED_BALANCE";

export const getDisbursementTypeStr = disbursementType => {
  switch(disbursementType){
    case DISBURSEMENT_BALANCE_OUT: return 'BALANCE OUT';
    case DISBURSEMENT_BALANCE_IN: return 'BALANCE IN';
    default: return 'UNDEFINED';
  }
}

export const getBalanceSource = value => {
  let str = 'Undefined'
  Object.keys(balanceSourceEnum).map(k => {
    if(k === value) str = balanceSourceEnum[k]
  })
  return str
}

export const getPayoutTypeStr = disbursementType => {
  switch(disbursementType){
    case DISBURSEMENT_MANUAL: return 'MANUAL';
    case DISBURSEMENT_MIDTRANS: return 'MIDTRANS';
    default: return 'UNDEFINED';
  }
}

export const getDisbursementStatusStr = disbursementStatus => {
  switch(disbursementStatus){
    case REJECTED_STATUS: return 'Rejected';
    case COMPLETED_STATUS: return 'Completed';
    case IN_PROGRESS_STATUS: return 'In Progress';
    case UNVERIFIED_STATUS: return 'Unverified';
    case EXPIRED_STATUS: return 'Expired';
    default: return 'Pending';
  }
}

export const bankOptions = [{
  label: 'BCA',
  value: 'BCA'
},{
  label: 'BNI',
  value: 'BNI'
},{
  label: 'BRI',
  value: 'BRI'
},{
  label: 'MANDIRI',
  value: 'MANDIRI'
}]

export const isExpired = (expiredAt) =>{
  return expiredAt && compareDateNow(date(expiredAt), 'seconds') < 0;
}