import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import FormCard from './FormCard';
import { getDetailMetaData } from '../../../actions/metaDataAction';
import privateView from "../../../components/hocs/privateView";


class MetaForm extends React.Component {
  constructor(props){
    super(props);
    this.state={
      _id:this.props.match.params.id,
      detail: null
    }

    this._refreshDetail = this._refreshDetail.bind(this)
  }

  componentDidMount(){
    if(this.state._id)
      this.props.find(this.state._id);
  }

  _refreshDetail(){
    if(this.state._id)
      this.props.find(this.state._id);
  }

  componentDidUpdate(prevProps) {
    if (this.props.metaData.isFetch !== prevProps.metaData.isFetch)
    {
      const { error } = this.props.metaData;
      if (!error) {
        this.setState({
          _id: this.state._id,
          detail: this.props.metaData
        })
      }
    }
  }

  render(){
    return(
      <Fragment>
        {this.state.detail && (
          <>
            <FormCard
              formTitle="Meta Details"
              edit={this.props.edit}
              metaData={this.state.detail}
              {...this.props}
            />
          </>
        )}
      </Fragment>
    )
  }
}
const mapStateToProps = ({
  user,
  auth: { isFetch, isLogged, roles, id, email },
  metaData,
}) => {
  return {
    user,
    email,
    guardData: { isFetch, isLogged, roles, id, email },
    metaData,
  };
};

const mapDispatchToProps = (dispatch) => {
	return{
    find:(id) => dispatch(getDetailMetaData(id)),
	}
};

const enhance = connect(mapStateToProps,mapDispatchToProps);
export default enhance(privateView(MetaForm, ['blog_admin']));
