import React from 'react';
import propTypes from 'prop-types';
import PaginationSummaries from './PaginationSummaries';
import Paginations from './Paginations';
import { Tbl, TblHead, TblBody } from '../Tbl';

class PaginationList extends React.Component {
  constructor(props){
    super(props);
    this.renderRowItem = this.renderRowItem.bind(this);
  }

  shouldComponentUpdate(nextProps) {
    return nextProps.isLoading !== this.props.isLoading || nextProps.selectedRow !== this.props.selectedRow
  }

  renderRowItem(){
    const { data, perPage, currentPage, selectedRow } = this.props;
    return this.props.renderRowItem(data, { perPage, currentPage }, selectedRow)
  }

  render(){
    return(
      <div className="animated fadeIn">
        <PaginationSummaries
          from={this.props.from}
          to={this.props.to}
          total={this.props.total}
        />
        <div className="table-responsive">
          <Tbl>
            <TblHead
              dataHeader={this.props.headerRow}
            />
            <TblBody
              isLoad={this.props.isLoading}
              hasRow={this.props.statusCode === 200 && this.props.data.length > 0}
              columnCount={this.props.headerRow.length}
              renderChildren={this.renderRowItem}
            >
            </TblBody>
          </Tbl>
        </div>
        <Paginations
          size="sm"
          maxPage={8}
          total={this.props.total}
          currentPage={this.props.currentPage}
          lastPage={this.props.lastPage}
          perPage={this.props.perPage}
          handleGotoCallback={this.props.handleGotoList}
        />
      </div>
    )
  }
}

export default PaginationList;

PaginationList.defaultProps = {
  selectedRow: null,
  data: [],
  from: 0,
  to: 0,
  total: 0,
  currentPage: 0,
  lastPage: 0,
  perPage: 0,
  isLoading: false
}

PaginationList.propTypes = {
  headerRow: propTypes.array.isRequired,
  data: propTypes.arrayOf(propTypes.object),
  from: propTypes.number,
  to: propTypes.number,
  total: propTypes.number,
  currentPage: propTypes.number,
  lastPage: propTypes.number,
  perPage: propTypes.oneOfType([propTypes.number, propTypes.string]),
  statusCode: propTypes.oneOfType([propTypes.number, propTypes.string]),
  isLoading: propTypes.bool,
  selectedRow: propTypes.oneOfType([propTypes.string, propTypes.number, propTypes.object]),
  renderRowItem: propTypes.func.isRequired,
  handleGotoList: propTypes.func.isRequired
}