import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Card,CardText} from 'reactstrap';

class KickDashboard extends Component{
    render(){
        return(
            <Card body>
                <CardText>Welcome back, {this.props.auth.isFetch?'Loading...': <strong>{this.props.auth.email}</strong>}</CardText>
            </Card>
        )
    }
}
const mapStateToProps=state=>{
    return {
        auth:state.auth
    }
}
export default connect(mapStateToProps,null)(KickDashboard)