import React, { Component } from "react"
import { connect } from "react-redux"
import uuidv4 from "uuid/v4"
import {
  Alert,
  Form,
  Label,
  Row,
  Input,
  FormGroup,
  ButtonGroup,
  Button,
  Card,
  CardBody,
  CardHeader,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Col
} from "reactstrap"
import classNames from "classnames"
import { hasError, textValue } from "../../utils/form"
import UploadImages from "./ArticleImages/UploadImages"

import {
  createArticle,
  deleteArticle,
  getDetailArticle,
  updateArticle,
  addImage,
  deleteImage,
  updateImage,
} from "../../actions/articleAction"
import {
  clearImageUpload,
  uploadImage,
  deleteImage as deleteImageS3,
} from "../../actions/imageUploadAction"
import { ImagesCompressor, createUploadFileForm } from "../../utils/imageHelper"
import { ValidationMessage } from "../../components/Form/ValidationMessage"
import { AWS_BUCKET, AWS_BUCKET_REGION } from "../../config/storageBucket"
import { getPathS3, getOriginFromSignedUrl } from "../../utils/AWS"
import Analytics from "../../services/Analytics"
import ArticleImages from "./ArticleImages"
import ArticleTextEditor from "./Editor/ArticleTextEditor"
import SelectArticleCategory from "../../components/Form/Select/Async/SelectArticleCategory"
import api from "../../utils/Api"
import { Creatable } from "react-select"
import { AUTH_TOKEN_KEY } from "../../constants"
import ProductVariantList from "./ArticleForm/ProductVariantList"
import { ArticleCategoryIndex } from "./Category"
import { ArticleMetaIndex } from "./Meta"
import privateView from "../../components/hocs/privateView"

const AWS_ENDPOINT = "amazonaws.com"

const getStorageName = (imgUrl) => {
  if (new RegExp(AWS_BUCKET).test(imgUrl)) return AWS_BUCKET
  else if (/kickavenue-assets/.test(imgUrl)) return "kickavenue-assets"
  return "external-link"
}

const getUrlKey = (imgUrl) => {
  const regexStorage = new RegExp(AWS_BUCKET)
  if (regexStorage.test(imgUrl)) {
    const firstPattern = new RegExp(
      `s3.${AWS_BUCKET_REGION}.${AWS_ENDPOINT}/${AWS_BUCKET}/`
    )
    const secondPattern = new RegExp(
      `${AWS_BUCKET}.s3.${AWS_BUCKET_REGION}.${AWS_ENDPOINT}/`
    )
    const thirdPattern = new RegExp(`${AWS_BUCKET}.s3.${AWS_ENDPOINT}/`)
    let parsedUrl = imgUrl.trim().replace(/^(https|http):\/\//, "")
    if (firstPattern.test(parsedUrl)) {
      return parsedUrl.replace(firstPattern, "")
    } else if (secondPattern.test(parsedUrl)) {
      return parsedUrl.replace(secondPattern, "")
    } else if (thirdPattern.test(parsedUrl)) {
      return parsedUrl.replace(thirdPattern, "")
    }
  }
  return ""
}

const ModalProgress = (props) => (
  <Modal size="sm" className="modal-confirmation" isOpen={props.isOpen}>
    <ModalHeader className="modal-header--center">
      {props.isDeleting ? "Deleting Progress" : "Submitting Progress."}
    </ModalHeader>
    <ModalBody>
      {props.renderModalBody ? props.renderModalBody() : props.modalBody}
    </ModalBody>
    <ModalFooter>
      <Button
        className={classNames({ "d-none": props.isPrimaryButtonHide })}
        color="primary"
        onClick={props.onPrimaryClick}
      >
        Done
      </Button>
    </ModalFooter>
  </Modal>
)

class ArticleForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      _edit: this.props.edit,
      _readOnly: this.props.readOnly,
      _hasId: this.props.match.params.id ? true : false,
      _images: [],
      _categories: [],
      _defaultTags: [],
      _form: {
        binding: false,
        id: this.props.match.params.id,
        title: "",
        subtitle: "",
        slug: "",
        body: "",
        article_category: null,
        categories: [],
        tags: [],
        details: "",
        active: false,
        created_at: "",
        updated_at: "",
      },
      _imageUploadList: [],
      _formSubmit: false,
      _isDeleting: false,
      _modalDelete: false,
      _modalDeleteProgressOpen: false,
      _articleDeleteProgress: false,
      _isModalProgress: false,
      _taskAvailable: 0,
      _taskDone: 0,
      _invalidSlug: false
    }

    this.onSubmit = this.onSubmit.bind(this)
    this.handleCheckedChange = this.handleCheckedChange.bind(this)
    this.handleChangeEditor = this.handleChangeEditor.bind(this)
    this._handleDropzoneOnDrop = this._handleDropzoneOnDrop.bind(this)
    this._handleSetAsThumbnail = this._handleSetAsThumbnail.bind(this)
    this._handleRemoveImage = this._handleRemoveImage.bind(this)
    this._handleUploadImages = this._handleUploadImages.bind(this)
    this._handleSelectChange = this._handleSelectChange.bind(this)
    this._handleOnSelectCategoryOpen =
      this._handleOnSelectCategoryOpen.bind(this)
    this._loadTagsOptions = this._loadTagsOptions.bind(this)
    this.handleOnChangeTags = this.handleOnChangeTags.bind(this)
    this._validateSlug = this._validateSlug.bind(this)
  }

  componentDidMount() {
    let pageSlug = "article_create"
    if (this.state._hasId) {
      this.props.find(this.state._form.id)
      pageSlug = "article_update"
    }
    const { email } = this.props.injectedProps
    Analytics.recordPageView(pageSlug, email, {
      url: window.location.href,
      location: this.props.location,
      match: this.props.match,
    })
    this._loadTagsOptions()
  }

  componentWillUnmount() {
    this.props.clearImageUpload()
  }

  componentDidUpdate = async (lastProps) => {
    const { detail, isFetch, isSubmit, error } = this.props.article
    // Edit Form
    if (this.state._edit && this.state._hasId) {
      if (
        lastProps.article.isSubmittingImage &&
        !this.props.article.isSubmittingImage
      ) {
        const _images = [...this.state._images]
        const { addedImages } = this.props.article
        Object.keys(addedImages).map((index) => {
          const filtered = _images.filter((item) => item.identifier === index)
          if (filtered.length) {
            filtered[0].id = addedImages[index].id
            filtered[0].url = addedImages[index].URL
            filtered[0].updated_at = addedImages[index].updated_at
            filtered[0].thumbnail = addedImages[index].thumbnail
          }
        })
        this.setState({ _images })
      }

      if (detail.status_code === 200 && error === null) {
        if (!isFetch && !this.state._form.binding) {
          const _images = this.bindArticleImageData(detail.images)
          const _tags = detail.tags.map((i) => {
            return {
              label: i.name,
              value: i.name,
            }
          })
          const _categories = detail.categories.map((i) => {
            return {
              id: i.id,
              name: i.name,
            }
          })
          const _newForm = {
            ...this.state._form,
            ...detail,
            tags: _tags,
            categories: _categories,
            // article_category: {
            //   id: detail.category.id,
            //   name: detail.category.name,
            // },
            binding: true,
          }
          this.setState({
            _images,
            _form: _newForm,
          })
        }
      }

      // After get detail Article
      if (isSubmit !== lastProps.article.isSubmit) {
        if (this.state._articleDeleteProgress) {
          if (error) {
            this.setState({
              _modalDeleteProgressOpen: false,
              _articleDeleteProgress: false,
            })
          } else if (detail.delete) {
            const { _taskAvailable, _taskDone } = this.state
            this.setState(
              {
                _delete: detail.delete,
                _articleDeleteProgress: _taskAvailable > _taskDone + 1,
                _taskDone: _taskDone + 1,
              },
              () => {
                const { _assetsToDelete, _articleDeleteProgress } = this.state
                if (_articleDeleteProgress && _assetsToDelete.length) {
                  const { identifier, url } = _assetsToDelete[0]
                  const payload = {
                    identifier,
                    bucket_type: "assets",
                    name: getPathS3(getOriginFromSignedUrl(url)),
                  }
                  this.props.deleteImageS3(payload)
                }
              }
            )
          }
        }
      }
      if (
        this.props.imageUpload.isDeleteProgress !==
        lastProps.imageUpload.isDeleteProgress
      ) {
        const { deleted } = this.props.imageUpload
        //&& this.state._delete === true
        if (this.state._articleDeleteProgress && deleted) {
          const _assetsToDelete = this.state._assetsToDelete.filter(
            ({ identifier }) => identifier !== deleted.identifier
          )
          let _taskDone = this.state._taskDone + 1
          this.setState(
            {
              _articleDeleteProgress: this.state._taskAvailable > _taskDone,
              _taskDone: _taskDone,
              _assetsToDelete,
            },
            () => {
              const { _assetsToDelete, _articleDeleteProgress } = this.state
              if (_articleDeleteProgress && _assetsToDelete.length) {
                const { identifier, url } = _assetsToDelete[0]
                const payload = {
                  identifier,
                  bucket_type: "assets",
                  name: getPathS3(getOriginFromSignedUrl(url)),
                }
                this.props.deleteImageS3(payload)
              }
            }
          )
        }
      }
      if (
        !this.props.imageUpload.isUpload &&
        this.props.imageUpload.isUpload !== lastProps.imageUpload.isUpload
      ) {
        const { success, error } = this.props.imageUpload
        if (!error) {
          const { _images, _imageUploadList } = this.state
          let myIdentifier = null
          const filtered = _images.filter(
            (img) =>
              img.identifier === success.data.identifier &&
              !img.status &&
              success.data.url !== null
          )
          if (filtered.length) {
            let { _imageUploadProgress } = this.state
            myIdentifier = filtered[0].identifier
            const imageUploadList = _imageUploadList.filter(
              (id) => id !== myIdentifier
            )
            let nextUploadId = null
            if (imageUploadList.length <= 0) {
              _imageUploadProgress = false
            } else {
              nextUploadId = imageUploadList[0]
            }
            this.setState(
              {
                _images: _images.map((item) => {
                  if (item.identifier == myIdentifier) {
                    return {
                      ...item,
                      status: true,
                      is_uploading: false,
                      storageName: getStorageName(success.data.url),
                      urlKey: getUrlKey(success.data.url),
                      url: success.data.url,
                    }
                  } else if (item.identifier == nextUploadId) {
                    return {
                      ...item,
                      is_uploading: true,
                    }
                  }
                  return item
                }),
                _imageUploadList: imageUploadList,
                _imageUploadProgress,
              },
              async () => {
                this.props.saveImage(myIdentifier, {
                  article_id: this.state._form.id,
                  URL: success.data.url,
                  position:
                    filtered[0].positionEdit !== undefined
                      ? filtered[0].positionEdit
                      : filtered[0].position,
                })
                const nextItemUpload = _images.filter(
                  (item) => item.identifier === nextUploadId
                )
                if (nextItemUpload.length && nextUploadId !== null) {
                  let { identifier, fileInput } = nextItemUpload[0]

                  try {
                    //compress file
                    fileInput = await ImagesCompressor(fileInput)
                  } catch (e) {}
                  const tempPayload = createUploadFileForm(fileInput, false, {
                    name: "articles/" + this.state._form.id,
                    bucket_type: "assets",
                  })
                  this.props.uploadImage(tempPayload, nextItemUpload[0])
                }
              }
            )
          }
        }
      }
      // After deleting image's url from kick api
      if (
        !this.props.article.isDeletingImage &&
        lastProps.article.isDeletingImage
      ) {
        const { deletedImages } = this.props.article
        if (deletedImages.length) {
          const _images = [...this.state._images]
          const lastUniqueID = deletedImages[deletedImages.length - 1]
          const lastImage = _images.filter(
            (item) => item.identifier === lastUniqueID
          )
          if (lastImage.length) {
            const { identifier, urlKey, url } = lastImage[0]
            if (urlKey !== "") {
              const payload = {
                identifier,
                bucket_type: "assets",
                name: getPathS3(getOriginFromSignedUrl(url)),
              }
              this.props.deleteImageS3(payload)
            }
            this.setState({
              _images: _images.filter(
                (item) => item.identifier !== lastUniqueID
              ),
            })
          }
        }
      }
    }
    // Create Form
    else {
      if (
        lastProps.article.isSubmit &&
        lastProps.article.isSubmit !== this.props.article.isSubmit
      ) {
        if (error === null && detail.status_code === 200) {
          if (!this.state._imageUploadList.length) {
            this.props.history.replace(`/articles`)
          } else {
            const { _imageUploadList } = this.state
            if (_imageUploadList.length) {
              let { identifier, fileInput } = _imageUploadList[0]
              //compress file
              try {
                fileInput = await ImagesCompressor(fileInput)
              } catch (e) {}

              const _images = this.state._images.map((item) => {
                if (item.identifier === identifier)
                  return { ...item, isUploading: true }
                return item
              })
              this.setState(
                {
                  _images,
                  _taskDone: this.state._taskDone + 1,
                  _form: {
                    ...this.state._form,
                    id: detail.id,
                  },
                },
                () => {
                  const tempPayload = createUploadFileForm(fileInput, false, {
                    name: "articles/" + this.state._form.id,
                    bucket_type: "assets",
                  })
                  this.props.uploadImage(tempPayload, _imageUploadList[0])
                }
              )
            }
          }
        } else {
          this.setState({
            _isModalProgress: false,
            _taskAvailable: 0,
            _taskDone: false,
          })
        }
      }
      if (
        lastProps.imageUpload.isUpload &&
        lastProps.imageUpload.isUpload !== this.props.imageUpload.isUpload
      ) {
        const { success, error } = this.props.imageUpload
        if (!error) {
          const { _images } = this.state
          let myIdentifier = null
          const filtered = _images.filter(
            (img) =>
              img.identifier === success.data.identifier &&
              !img.status &&
              success.data.url !== null
          )
          if (filtered.length) {
            myIdentifier = filtered[0].identifier
            this.setState(
              {
                _images: _images.map((item) => {
                  if (item.identifier == myIdentifier) {
                    return {
                      ...item,
                      storageName: getStorageName(success.data.url),
                      urlKey: getUrlKey(success.data.url),
                      url: success.data.url,
                      thumbnail: success.data.thumbnail
                    }
                  }
                  return item
                }),
              },
              () => {
                this.props.saveImage(myIdentifier, {
                  article_id: this.state._form.id,
                  URL: success.data.url,
                })
              }
            )
          }
        }
      }
      if (
        lastProps.article.isSubmittingImage &&
        !this.props.article.isSubmittingImage
      ) {
        const _images = [...this.state._images]
        const { addedImages } = this.props.article
        let getIdentifier = null
        let count = this.state._taskDone
        Object.keys(addedImages).map((index) => {
          const filtered = _images.filter(
            (item) => item.identifier === index && item.isUploading
          )
          if (filtered.length) {
            getIdentifier = index
            filtered[0].id = addedImages[index].id
            filtered[0].updatedAt = addedImages[index].updated_at
            filtered[0].status = true
            filtered[0].isUploading = false
            filtered[0].thumbnail = addedImages[index].thumbnail
            count++
          }
        })
        const nextImageUpload = this.state._imageUploadList.filter(
          (item) => item.identifier !== getIdentifier
        )
        if (nextImageUpload.length) {
          let { identifier, fileInput } = nextImageUpload[0]

          //compress file
          try {
            fileInput = await ImagesCompressor(fileInput)
          } catch (e) {}

          _images.map((item) => {
            if (item.identifier === identifier) {
              item.isUploading = true
            }
          })
          const tempPayload = createUploadFileForm(fileInput, false, {
            name: "articles/" + this.state._form.id,
            bucket_type: "assets",
          })
          this.props.uploadImage(tempPayload, nextImageUpload[0])
        }
        this.setState({
          _images,
          _imageUploadList: this.state._imageUploadList.filter(
            ({ identifier }) => identifier !== getIdentifier
          ),
          _taskDone: count,
        })
      }
    }
  }

  /**
   * Handle to change local state from input uset like textfield
   * @author haikal
   * @param {event} e
   */
  handleChange(e) {
    const newState = { ...this.state._form }
    newState[e.target.name] = e.target.value
    this.setState({ _form: newState })
  }
  handleOnChangeTags(value) {
    const { _form } = this.state
    _form.tags = value
    this.setState({ _form })
  }
  handleCheckedChange(e) {
    const newState = { ...this.state._form }
    newState[e.target.name] = e.target.checked
    this.setState({ _form: newState })
  }
  onSelectChange(val, attributeForm) {
    const { _form } = this.state
    if (val && val.id) {
      _form[attributeForm] = val
      _form["article_category_id"] = val.id
      this.setState({ _form })
    }
  }
  _handleOnSelectCategoryOpen() {
    if (this.state.roles) {
      return (
        this.state._recentCategorySearch &&
        this.refs.selectArticleCategory.loadSelectOptions(
          this.state._recentCategorySearch
        )
      )
    }
    return this.refs.selectArticleCategory.loadSelectOptions("")
  }

  _handleSelectChange(val) {
    this.setState({
      _form: {
        ...this.state._form,
        categories: val,
      },
      _recentCategorySearch: val && this.state._recentCategorySearch,
    })
  }

  _loadTagsOptions() {
    const myToken = localStorage.getItem(AUTH_TOKEN_KEY)
    const headers = {
      Authorization: `Bearer ${myToken}`,
    }
    api(headers)
      .get("/admins/articles/tags", {})
      .then(({ data }) => {
        const tags = data.data.map((item) => {
          return {
            label: item.name,
            value: item.name,
          }
        })
        this.setState({ _defaultTags: tags })
      })
  }

  _validateSlug(input) {
    var regex = /^[a-z0-9-]+$/;
    return regex.test(input);
  }

  onSubmit(e) {
    e.preventDefault()
    this.setState({ _formSubmit: true, _invalidSlug: false }, () => {
      const { id, title, subtitle, slug, body, active } = this.state._form

      let { tags, categories } = this.state._form
      categories = categories.map((i) => i.id)
      tags = tags.map((t) => t.value)
      if (!this._validateSlug(slug)) {
        this.setState({ _invalidSlug: true })
        return
      }
      if (this.props.edit && this.state._hasId) {
        this.props.update(id, { title, subtitle, slug, body, categories, tags, active })
      } else {
        if (
          this.state._images.filter((item) => item.storageName === "in-local")
            .length
        ) {
          const _imageUploadList = this.state._images
            .filter((item) => item.storageName === "in-local")
            .map(({ identifier, fileInput }) => ({ identifier, fileInput }))
          this.setState(
            {
              _imageUploadList,
              _isModalProgress: true,
              _taskAvailable: 1 + _imageUploadList.length,
              _taskDone: 0,
            },
            () => {
              this.props.create({ title, subtitle, slug, body, categories, tags, active })
            }
          )
        } else {
          this.props.create({ title, subtitle, slug, body, categories, tags, active })
        }
      }
    })
  }

  _handleDropzoneOnDrop(files) {
    if (files.length) {
      let maxSize = 1048576 * 2 // 2 mb
      const { _images } = this.state
      const droppedImages = files.map((file) => {
        if (file.size > maxSize) {
          alert("Image is too large!")
          return null
        } else {
          const identifier = uuidv4()
          return {
            id: null,
            url: null,
            storageName: "in-local",
            status: false,
            isUploading: false,
            fileInput: file,
            updatedAt: null,
            identifier,
          }
        }
      })
      if (droppedImages.length && droppedImages[0]) {
        const clonedImages = [..._images, ...droppedImages]
        this.setState({ _images: clonedImages })
      }
    }
  }

  _handleRemoveImage(e, imageIdentifier, imageId) {
    e.preventDefault()
    const { _images } = this.state
    if (imageId) {
      this.setState({
        _images: _images.map((item) =>
          item.identifier === imageIdentifier
            ? { ...item, deleted: true }
            : item
        ),
      })
      this.props.removeImage(imageIdentifier, imageId)
    } else {
      this.setState({
        _images: _images.filter(
          ({ identifier }) => identifier !== imageIdentifier
        ),
      })
    }
  }

  _handleSetAsThumbnail(e, imageIdentifier, imageId) {
    e.preventDefault()
    const { _images } = this.state
    let newImages = [..._images]
    const idx = _images.findIndex(item => item.identifier === imageIdentifier)
    const currentThumbnailIdx = _images.findIndex(item => item.thumbnail === true)
    const image = _images[idx]
    const payload = {
      article_id: this.state._form.id,
      URL: image.url,
      thumbnail: true
    }
    this.props.setImageThumbnail(imageIdentifier, imageId, payload)
    newImages[idx].thumbnail = true
    if (currentThumbnailIdx > -1) {
      newImages[currentThumbnailIdx].thumbnail = false
    }
    this.setState({
      _images: newImages
    })
  }

  _handleUploadImages = async () => {
    const { _images, _imageUploadProgress } = this.state
    const notUploadedImages = _images.filter((item) => item.id === null)
    if (!_imageUploadProgress && notUploadedImages.length) {
      const firstImage = notUploadedImages[0]
      let fileInput

      try {
        //compress file
        fileInput = await ImagesCompressor(firstImage.fileInput)
      } catch (e) {
        fileInput = firstImage.fileInput
      }

      const newImages = _images.map((item) => {
        if (item.identifier == firstImage.identifier) {
          return {
            ...item,
            is_uploading: true,
          }
        }
        return {
          ...item,
        }
      })
      this.setState({
        _images: newImages,
        _imageUploadProgress: !_imageUploadProgress,
        _imageUploadList: notUploadedImages.map((item) => item.identifier),
      })
      const tempPayload = createUploadFileForm(fileInput, false, {
        name: "articles/" + this.state._form.id,
        bucket_type: "assets",
      })
      this.props.uploadImage(tempPayload, firstImage)
    }
  }

  handleChangeEditor(value) {
    this.setState({
      _form: {
        ...this.state._form,
        body: value.editor.getData(),
      },
    })
  }

  bindArticleImageData = (imagesProps) => {
    return imagesProps.map((image) => ({
      identifier: uuidv4(),
      id: image.id,
      position: image.position,
      storageName: getStorageName(image.URL),
      urlKey: getUrlKey(image.URL),
      url: image.URL,
      updated_at: image.updated_at,
      status: true,
      is_uploading: false,
      thumbnail: image.thumbnail
    }))
  }

  renderLoading() {
    return (
      <div className="row">
        <div className="col-sm-12">
          <Alert color="info" className="text-center">
            Getting all data...
          </Alert>
        </div>
      </div>
    )
  }
  renderInfo() {
    let infoMessage = []
    if (this.props.article.isSubmit)
      infoMessage.push(
        <Alert color="info" key="info">
          <strong>Heads up!</strong> Submitting...
        </Alert>
      )
    if (this.props.article.success)
      infoMessage.push(
        <Alert color="success" key="success">
          <strong>Well done!</strong> {this.props.article.success}
        </Alert>
      )
    if (this.props.article.error !== null) {
      const { status_code } = this.props.article.error
      switch (status_code) {
        case 422:
          infoMessage.push(
            <Alert color="danger" key="error">
              <strong>Oh Snap!</strong> Please fullfill your form.{" "}
            </Alert>
          )
          break
        case 404:
          infoMessage.push(
            <Alert color="danger" key="error">
              <strong>Oh Snap!</strong> Resource not found.{" "}
            </Alert>
          )
          break
        default:
          infoMessage.push(
            <Alert color="danger" key="error">
              <strong>Oh Snap!</strong> We've got something errors{" "}
            </Alert>
          )
          break
      }
    }
    return (
      <div className="row">
        <div className="col-sm-12">{infoMessage}</div>
      </div>
    )
  }

  validateError(name) {
    if (this.hasError(name)) {
      let errorList = this.props.article.error.errors
      return <ValidationMessage message={errorList[name][0]} />
    }
    return ""
  }

  hasError(name) {
    if (this.props.article.error) {
      if (this.props.article.error.errors) {
        let errorList = this.props.article.error.errors
        let errList = Object.keys(errorList).filter((key) => key == name)
        if (errList.length) return true
      }
    }
    return false
  }

  toggle() {
    this.setState({ _modalDelete: !this.state._modalDelete })
  }

  onDelete() {
    const _assetsToDelete = this.state._images.filter(
      (item) => item.urlKey !== ""
    )
    this.setState(
      {
        _formSubmit: true,
        _isDeleting: true,
        _modalDelete: false,
        _modalDeleteProgressOpen: true,
        _articleDeleteProgress: true,
        _taskAvailable: 1 + _assetsToDelete.length,
        _taskDone: 0,
        _assetsToDelete,
      },
      () => {
        this.props.delete(this.state._form.id)
      }
    )
  }

  render() {
    return (
      <Row>
        <ModalProgress
          isDeleting={this.state._isDeleting}
          isOpen={
            this.state._isModalProgress || this.state._modalDeleteProgressOpen
          }
          renderModalBody={() =>
            this.state._isDeleting ? (
              <p className="text-center">
                {this.state._taskAvailable > this.state._taskDone
                  ? "Deleting..."
                  : this.state._taskAvailable > 0
                  ? "Delete successfully..."
                  : "Not available..."}{" "}
                ({this.state._taskDone}/{this.state._taskAvailable})
              </p>
            ) : (
              <p className="text-center">
                {this.state._taskAvailable > this.state._taskDone
                  ? "Please waiting..."
                  : this.state._taskAvailable > 0
                  ? "Submit successfully..."
                  : "Not available..."}{" "}
                ({this.state._taskDone}/{this.state._taskAvailable})
              </p>
            )
          }
          isPrimaryButtonHide={this.state._taskAvailable > this.state._taskDone}
          onPrimaryClick={() =>
            this.state._isDeleting
              ? this.props.history.replace(`/articles`)
              : this.props.history.replace(`/articles/${this.state._form.id}`)
          }
        />
        <Modal
          isOpen={this.state._modalDelete}
          toggle={this.toggle.bind(this)}
          className="modal-dialog modal-sm"
        >
          <ModalHeader toggle={this.toggle.bind(this)}>
            Confirmation
          </ModalHeader>
          <ModalBody>
            {this.state._modalDelete && this.props.article.isSubmit ? (
              <div className="row">
                <div className="col-sm-12">
                  <Alert color="info" className="text-center">
                    Deleting...
                  </Alert>
                </div>
              </div>
            ) : (
              <span>Are you sure to delete this?</span>
            )}
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.onDelete.bind(this)}>
              Yes
            </Button>{" "}
            <Button color="secondary" onClick={this.toggle.bind(this)}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
        <Col className="animated fadeIn" xs={12} md={{ size: 7 }}>
          <Card>
            <CardHeader>
              <i className="fa fa-align-justify"></i> {this.props.title}
            </CardHeader>
            <CardBody>
              {this.renderInfo()}
              {this.props.article.isFetch ? (
                this.renderLoading()
              ) : (
                <Form onSubmit={this.onSubmit}>
                  <FormGroup
                    className={
                      this.hasError("title") ? " has-danger has-feedback" : ""
                    }
                  >
                    <Label for="formTitle">Title</Label>
                    <Input
                      type="text"
                      id="formTitle"
                      name="title"
                      placeholder="Enter a title*"
                      value={this.state._form.title}
                      onChange={this.handleChange.bind(this)}
                    />
                    {this.validateError("title")}
                  </FormGroup>
                  <FormGroup
                    className={
                      this.state._invalidSlug ? " has-danger has-feedback" : ""
                    }
                  >
                    <Label for="formSlug">Slug</Label>
                    <Input
                      type="text"
                      id="formSlug"
                      name="slug"
                      placeholder="Enter a slug"
                      value={this.state._form.slug}
                      onChange={this.handleChange.bind(this)}
                    />
                    <small className={
                      classNames('form-text', {
                        "text-danger": this.state._invalidSlug
                      })
                    }>This field is optional, if you want to fill it anyway please input the lowercase character separated with a dash (-) for example <b>"this-is-a-slug"</b> </small>
                  </FormGroup>
                  <FormGroup
                    className={
                      this.hasError("subtitle") ? " has-danger has-feedback" : ""
                    }
                  >
                    <Label for="formTitle">Subtitle <small className={this.state._form.subtitle.length > 150 ? "text-danger" : ""}>(max. 150 characters) left: {150 - this.state._form.subtitle.length}</small></Label>
                    <textarea 
                      className="form-control" 
                      id="formTitle"
                      name="subtitle"
                      placeholder="Enter a subtitle"
                      value={this.state._form.subtitle}
                      onChange={this.handleChange.bind(this)}
                      rows={3}>
                    </textarea>
                    {this.validateError("subtitle")}
                  </FormGroup>
                  <FormGroup
                    className={
                      this.hasError("categories")
                        ? " has-danger has-feedback"
                        : ""
                    }
                  >
                    <Label for="formArticleCategory">Article Category</Label>
                    <SelectArticleCategory
                      isMulti
                      id="formArticleCategory"
                      // name="categories"
                      defaultOptions
                      ref="selectArticleCategory"
                      paramsApi={{ sort_by: "createdAt_desc" }}
                      placeholder="Select a category..."
                      noResultsText="Cannot find article category."
                      value={this.state._form.categories}
                      onSelectSearch={(input) =>
                        this.setState({ _recentCategorySearch: input })
                      }
                      onSelectChange={this._handleSelectChange}
                      onSelectOpen={this._handleOnSelectCategoryOpen}
                    />
                    {this.validateError("categories")}
                  </FormGroup>
                  <FormGroup
                    className={classNames({
                      "has-danger has-feedback": hasError(
                        "tags",
                        this.state._errorForm
                      ),
                    })}
                  >
                    <Label for="formArticleTags">Tags</Label>
                    <Creatable
                      multi
                      options={this.state._defaultTags}
                      value={this.state._form.tags}
                      onChange={this.handleOnChangeTags}
                      placeholder="Enter a tags"
                    />
                    {this.validateError("tags")}
                  </FormGroup>
                  <FormGroup
                    className={classNames({
                      "has-danger has-feedback": hasError(
                        "active",
                        this.state._error
                      ),
                    })}
                  >
                    <Label for="formArticleActive">Set to Active &nbsp;</Label>
                    <label className="switch switch-text switch-success-outline-alt">
                      <input
                        type="checkbox"
                        className="switch-input"
                        name="active"
                        id="formArticleActive"
                        value="true"
                        checked={textValue("active", this.state._form)}
                        onChange={this.handleCheckedChange}
                      />
                      <span
                        className="switch-label"
                        data-on="On"
                        data-off="Off"
                      ></span>
                      <span className="switch-handle"></span>
                    </label>
                    {this.validateError("active")}
                  </FormGroup>
                  <FormGroup
                    className={
                      this.hasError("body") ? " has-danger has-feedback" : ""
                    }
                  >
                    <Label for="formArticleBody">Body</Label>
                    {this.state._edit ? (
                      this.state._form.body !== "" ||
                      this.state._form.binding ? (
                        <ArticleTextEditor
                          handleChange={this.handleChangeEditor}
                          value={this.state._form.body}
                        />
                      ) : null
                    ) : (
                      <ArticleTextEditor
                        handleChange={this.handleChangeEditor}
                        value={this.state._form.body}
                      />
                    )}
                    {this.validateError("body")}
                  </FormGroup>
                  {!this.state._hasId && (
                    <FormGroup>
                      <UploadImages
                        onDrop={this._handleDropzoneOnDrop}
                        images={this.state._images}
                        onRemoveImage={this._handleRemoveImage}
                        onSetThumbnail={this._handleSetAsThumbnail}
                      />
                    </FormGroup>
                  )}
                  <FormGroup>
                    <ButtonGroup>
                      <Button
                        color="primary"
                        disabled={this.props.article.isSubmit}
                      >
                        Submit
                      </Button>
                      {this.state._hasId ? (
                        <Button
                          color="danger"
                          onClick={this.toggle.bind(this)}
                          disabled={this.props.article.isSubmit}
                        >
                          Delete
                        </Button>
                      ) : (
                        ""
                      )}
                    </ButtonGroup>
                  </FormGroup>
                </Form>
              )}
            </CardBody>
          </Card>
        </Col>
        <Col className="animated fadeIn" xs={12} md={5}>
          {this.props.edit && (
            <>
              <ProductVariantList id={this.props.match.params.id} />
              <ArticleMetaIndex articleId={this.props.match.params.id} {...this.props} />
            </>
          )}
          <ArticleCategoryIndex {...this.props} />
        </Col>
        {this.state._hasId && (
          <ArticleImages
            isLoading={
              this.props.article.isFetch ||
              this.props.article.detail.status_code !== 200
            }
            isShow={this.state._edit}
            images={this.state._images}
            onDrop={this._handleDropzoneOnDrop}
            onRemoveImage={this._handleRemoveImage}
            onSetThumbnail={this._handleSetAsThumbnail}
            onUploadImages={this._handleUploadImages}
          />
        )}
      </Row>
    )
  }
}
const mapStateToProps = ({auth: { isFetch, roles, isLogged, id, email }, article, imageUpload}) => {
  return {
    guardData: { isFetch, roles, isLogged, id, email },
    article: article,
    imageUpload: imageUpload,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    find: (id) => dispatch(getDetailArticle(id)),
    delete: (id) => dispatch(deleteArticle(id)),
    update: (id, payload) => dispatch(updateArticle(id, payload)),
    create: (payload) => dispatch(createArticle(payload)),
    clearImageUpload: () => dispatch(clearImageUpload()),
    uploadImage: (payload, rawImages) =>
      dispatch(uploadImage(payload, rawImages)),
    saveImage: (identifier, payload) => dispatch(addImage(identifier, payload)),
    setImageThumbnail: (identifierImg, id, payload) => dispatch(updateImage(identifierImg, id, payload)),
    removeImage: (identifierImg, id) => dispatch(deleteImage(identifierImg, id)),
    deleteImageS3: (payload) => dispatch(deleteImageS3(payload)),
  }
}
const enhance = connect(mapStateToProps, mapDispatchToProps)

export default enhance(privateView(ArticleForm, ['blog_admin']));
