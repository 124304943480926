import React, { Component } from 'react';
import { 
  Col, 
  Form, 
  FormGroup, 
  Input, 
  InputGroup, 
  InputGroupAddon, 
  Button } from 'reactstrap';
import propTypes from 'prop-types';
import 'react-dates/initialize';
import { DateRangePicker } from 'react-dates';
import moment from 'moment-timezone';

export default class SearchForm extends Component{
  constructor(props){
    super(props);
    this.state = {
      focusedInput: null,
      startDate: null,
      endDate: null
    }
  }
  componentDidUpdate(prevProps){
    const {start_at, end_at} = this.props;
    const {startDate, endDate} = this.state;
    if(prevProps.start_at !== start_at){
      const startDateStr = startDate? startDate.format("YYYY-MM-DD"): startDate;
      if(startDateStr!==start_at){
        this.setState({
          startDate: start_at? moment(start_at): start_at
        })
      }
    }
    if(prevProps.end_at !== end_at){
      const endDateStr = endDate? endDate.format("YYYY-MM-DD"): endDate;
      if(endDateStr!==end_at){
        this.setState({
          endDate: end_at? moment(end_at): end_at
        })
      }
    }
  }
  render(){
    return(
      <Form onSubmit={this.props.onSubmit}>
        <FormGroup>
          <Col xs={12} sm={12} md={6} style={{padding:0}}>
            <Input type="text" placeholder="Search email user" name="keyword" value={this.props.keyword} onChange={this.props.onInputChange}/>
          </Col>
        </FormGroup>
        <FormGroup>
        <DateRangePicker
          showClearDates
          reopenPickerOnClearDates
          showDefaultInputIcon
          initialVisibleMonth={() => moment().subtract(1,"months")}
          isOutsideRange={date => {
            return moment().diff(date, 'days') <= -7
          }}
          startDate={this.state.startDate} // momentPropTypes.momentObj or null,
          startDateId="registered_at_start_at_field" // PropTypes.string.isRequired,
          endDate={this.state.endDate} // momentPropTypes.momentObj or null,
          endDateId="registered_at_end_at_field" // PropTypes.string.isRequired,
          onDatesChange={async({ startDate, endDate }) => {
            await this.setState({
              startDate,
              endDate
            })
            this.props.onRegisteredAtChange({ start_at: startDate, end_at: endDate })
          }} // PropTypes.func.isRequired,
          focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
          onFocusChange={focusedInput => this.setState({ focusedInput })} // PropTypes.func.isRequired,
          renderCalendarInfo={()=>(<p className="text-center" style={{fontSize: "1rem", fontStyle: "italic"}}>Filter your user registered ranges.</p>)}
        />
        </FormGroup>
        <FormGroup>
          <Button color="primary">Apply Now</Button>
        </FormGroup>
      </Form>
    )
  }
}

SearchForm.propTypes= {
  keyword: propTypes.string,
  start_at: propTypes.any,
  end_at: propTypes.any,
  onInputChange: propTypes.func,
  onRegisteredAtChange: propTypes.func,
  onSubmit: propTypes.func.isRequired
}

SearchForm.defaultProps= {
  keyword: '',
  start_at: null,
  end_at: null
}