import React, { Component } from 'react';
import {
  Row,
  Col,
  Card
} from 'reactstrap';
import PaymentDetailCard from './tabPaymentDetail/PaymentDetailCard';
import PurchaseSummaryCard from './tabPaymentDetail/PurchaseSummaryCard';

export default class TabPaymentDetail extends Component{
  constructor(props){
    super(props);
    this.state={
      _id: props.id,
      paymentDetail:{
      }
    }
  }

  componentWillReceiveProps(nextProps){
    if(nextProps.isLoading!==this.props.isLoading && !nextProps.isLoading){
      if(nextProps.dataPayment.error===null){
        const { detail }=nextProps.dataPayment;
        this.setState({ error: null, paymentDetail:{ ...detail }, _id: detail.id })
      }
      else{
        const { error }=nextProps.dataPayment;
        this.setState({ error });
      }
    }
    if(nextProps.isSubmit===false
    &&nextProps.isSubmit!==this.props.isSubmit&& nextProps.dataPayment.detail.status_code===200){
      const { detail, error } = nextProps.dataPayment;
      if(error){
        this.setState({ error });
      }
      else{
        this.setState({ error: null,paymentDetail:{ ...detail }, _id: detail.id })
      }
    }
  }

  _renderPurchaseSummaryCard(){
    const { ...others } = this.state.paymentDetail;
    return(
      <PurchaseSummaryCard
        isLoading={this.props.isLoading}
        id={this.state._id}
        totalAmount={others.total_amount}
        amount={others.amount}
        uniqueAmount={others.unique_amount}
        administrationFee={others.administration_fee}
      />
    )
  }

  render(){
    return(
      <Card body className="tab-content--custom__card">
        <Row>
          <Col xs={12} md={6}>
            <PaymentDetailCard
              id={this.state._id}
              {...this.state.paymentDetail}
              isLoading={this.props.isLoading}
              isSubmit={this.props.isSubmit}
              dataError={this.state.error}
              onUpdatePayment={this.props.onUpdatePayment}
              email={this.props.email}
            />
          </Col>
          <Col xs={12} md={6}>
            { this._renderPurchaseSummaryCard() }
          </Col>
        </Row>
      </Card>
    )
  }
}
