import React,{Component} from 'react';
import {
  CardBody,
  Badge
} from 'reactstrap';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';

import {Tbl, TblHead, TblBody} from '../../../components/Tbl';
import { getDetailSize } from '../../../actions/sizeAction';
import { doGetListSellingOfStock } from '../../../actions/productStockAction';
import { TextRupiah } from '../../../components/Text';
import Paginations from '../../../components/Paginations';
import { FontAwesomeIcon } from '../../../components/Icons';
import { DEFAULT_PER_PAGE } from '../../../constants/settings';
import ContentWrapper from './ContentWrapper';
import FilterPanel from './stockSells/FilterPanel';
import SizeInfo from './stockSells/SizeInfo';
import BadgeConditions from './stockSells/BadgeConditions';

const defaultParams = {
  sort_by : 'askingPrice_asc',
  per_page : DEFAULT_PER_PAGE
}

const dataHeader=[
  'No.','User', 'Asking Price', 'Qty', 'Status', 'Action'
];

class StockSells extends Component{
  constructor(props){
    super(props);
    this.state={
      _variantId:this.props.match.params.id,
      _sizeId:this.props.match.params.size_id,
      _params:{
        ...defaultParams,
        keyword: this.props.stockKeyword.keywordFor==='users'?this.props.stockKeyword.keyword: ''
      }
    }
    this._handleGoto = this._handleGoto.bind(this);
    this._handleOnApplyFilter = this._handleOnApplyFilter.bind(this);
  }

  componentDidMount(){
    this.props.findSizeById(this.state._sizeId);
    this._refreshList();
  }

  _refreshList(){
    this.props.getList(this.state._variantId, this.state._sizeId,{params:{...this.state._params}})
  }

  onRenderRowItem(){
    if(this.props.userSell.status_code===200)
    {
      const { current_page, per_page } = this.props.userSell;
      return this.props.userSell.data.map((item,idx)=>(
        <tr key={parseInt((per_page*(current_page-1))+idx+1)}>
          <th className="v-center" scope="row">{ parseInt((per_page*(current_page-1))+idx+1) }</th>
          <td className="v-center">
            <div>{ item.user.email }</div>
            <BadgeConditions
              id={item.id}
              sneakersCondition={item.sneakers_condition}
              boxCondition={item.box_condition}
              preOrder={item.pre_order}
              isExpired={item.is_expired&&item.day_lefts<0}
            />
          </td>
          <td className="v-center">
            <TextRupiah prefix="Rp. " bold value={item.asking_price}/>
            {
              (item.pre_verified || item.rack) && (<div style={{fontSize: '.65rem'}}>
                {item.pre_verified? <i className="fa fa-barcode mr-1"></i>:null}
                {item.consignment_id? item.consignment_id: null}<br />
                {item.rack? <i className="fa fa-barcode mr-1"></i>:null}
                {item.rack? item.rack + ' (RACK)':null}
              </div>)
            }
          </td>
          <td className="v-center">{ item.quantity }</td>
          <td className="v-center">{ item.status }</td>
          <td className="v-center">
            <Link to={`/sell_request/${item.id}`} className="btn btn-secondary"><FontAwesomeIcon iconType="external-link"/> Details</Link>
          </td>
        </tr>
      ))
    }
    return '';
  }


    /**
	 * Handle callback when user click pagination items
	 * @author pewe
	 * @param {integer} page
	 */
	_handleGoto(page){
		const _params=this.state._params;
		_params.page=page;
		this.setState({_params});
		const query={ params: { ..._params } };
		this.props.getList(this.state._variantId, this.state._sizeId,query);
	}

  onRenderPagination(){
    if(this.props.userSell.status_code===200&&this.props.userSell.data.length) {
      return (
        <Paginations
          size="sm"
          lastPage={this.props.userSell.last_page}
          perPage={this.props.userSell.per_page}
          total={this.props.userSell.total}
          maxPage={8}
          currentPage={this.props.userSell.current_page}
          handleGotoCallback={this._handleGoto}
        />
      )
    }
    return '';
  }

  _handleOnApplyFilter(payloads){
    this.setState({
      _params:{
        ...this.state._params,
        ...payloads,
        page: 1
      }
    },
    ()=>
      this._refreshList()
    )
  }

  render(){
    const { size } = this.props
    return (
      <ContentWrapper>
        <SizeInfo { ...size.detail }/>
        <CardBody>
          <FilterPanel
            keyword={this.state._params.keyword}
            perPage={this.state._params.per_page}
            sortBy={this.state._params.sort_by}
            onApplyFilter={this._handleOnApplyFilter}
          />
          <div className="table-responsive">
            <Tbl>
              <TblHead
                dataHeader={dataHeader}
              />
              <TblBody
                isLoad={this.props.userSell.isFetch}
                hasRow={this.props.userSell.status_code===200&&this.props.userSell.data.length>0}
                columnCount={dataHeader.length}
              >
              { this.onRenderRowItem() }
              </TblBody>
            </Tbl>
          </div>
          {this.onRenderPagination()}
        </CardBody>
      </ContentWrapper>
    )
  }
}

const mapStateToProps= (state) => ({
  stockKeyword: {
    keyword: state.productStock.keyword,
    keywordFor: state.productStock.keywordFor,
  },
  size:state.size,
  userSell:state.productStock.stockUserSellList
});

const mapDispatchToProps = (dispatch) => ({
  findSizeById:(sizeId) => dispatch(getDetailSize(sizeId)),
  getList:(variantId, sizeId,query) => dispatch(doGetListSellingOfStock(variantId, sizeId, query))
});

export default connect(mapStateToProps,mapDispatchToProps)(StockSells);