import React from 'react';
import propTypes from 'prop-types';
import {
  Modal,
  ModalHeader,
  ModalBody,
  Table
} from 'reactstrap';
import TextRupiah from '../../../components/Text/TextRupiah';

const ModalSeekDetail = props => (
  <Modal size="md" isOpen={props.isOpen} toggle={props.toggle}>
    <ModalHeader toggle={props.toggle}>
      {props.data.name? `(${props.data.name}) - `: ''} More Details
    </ModalHeader>
    <ModalBody>
      <Table>
        <thead>
          <tr>
            <th>#</th>
            <th>Prod. Code</th>
            <th>Size</th>
            <th>Price (Rp.)</th>
          </tr>
        </thead>
        <tbody>
          {
            props.data.items.map((item, index) => (
              <tr key={item.id}>
                <th scope="row">{index+1}</th>
                <td>{item.default_code} ({item.quantity})</td>
                <td>{item.size}</td>
                <td>
                  <TextRupiah value={item.price} prefix=""/>
                </td>
              </tr>
            ))
          }
        </tbody>
      </Table>
    </ModalBody>
  </Modal>
)

export default ModalSeekDetail;

ModalSeekDetail.defaultProps = {
  items: []
}

ModalSeekDetail.propTypes = {
  id: propTypes.number,
  name: propTypes.string,
  items: propTypes.arrayOf(propTypes.object)
}